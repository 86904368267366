import { create } from 'zustand';

import type { WizardProps } from './Wizard';
import type { Step } from './step';

export type WizardStore<State> = {
  /**
   * index of current step, beware that skipped step indexes are included
   */
  currentStepIndex: number;
  getCurrentStepNo: () => number;
  canGoNext: () => boolean;
  canGoPrev: () => boolean;
  stepsCount: number;

  steps: Step<any, State>[];
  allowedStepIndexes: number[];
  state: Partial<State>;
  /**
   *
   * @param stateReducer - pass return value of `payloadReducer` from step definition
   */
  saveStep: (
    stateReducer: (prevState: Partial<State>) => Partial<State>,
  ) => void;
  clearState: () => void;
  next: () => void;
  prev: () => void;
  validate: () => boolean;
  /**
   * revalidate visible steps based on current state, call when recently updated state affects visibility of next steps
   */
  revalidateVisibleSteps: (
    props?: Pick<WizardProps<State>, 'visibleSteps'>,
  ) => void;
};

const isStep = <State>(
  entry: ReturnType<NonNullable<WizardProps<State>['visibleSteps']>>[number],
): entry is Step<any, State> => {
  const step = entry as Step<any, State>;
  return !!step.key && !!step.render;
};

const getAllowedIndexes = <State>({
  steps,
  visibleSteps,
  state,
}: WizardProps<State> & { state: Partial<State> }) => {
  if (!visibleSteps) return steps.map((_, i) => i);

  const visibleKeys = visibleSteps(state)
    .filter(isStep)
    .map((s) => s.key);

  return steps.reduce<number[]>(
    (allowed, step, i) =>
      visibleKeys.includes(step.key) ? [...allowed, i] : allowed,
    [],
  );
};

export const createWizardStore = <T>(initialProps: WizardProps<T>) => {
  const allowedStepIndexes = getAllowedIndexes({
    ...initialProps,
    state: initialProps.initialState || {},
  });

  return create<WizardStore<T>>((set, get) => ({
    currentStepIndex: allowedStepIndexes.at(0) ?? 0,
    stepsCount: allowedStepIndexes.length,
    steps: initialProps.steps,
    allowedStepIndexes,
    state: initialProps.initialState || {},

    getCurrentStepNo: () =>
      get().allowedStepIndexes.indexOf(get().currentStepIndex) + 1,

    canGoNext: () =>
      get().validate() &&
      get().currentStepIndex < (get().allowedStepIndexes.at(-1) ?? 0),

    canGoPrev: () =>
      get().currentStepIndex > (get().allowedStepIndexes.at(0) ?? 0),

    saveStep: (stateReducer) => {
      set((prev) => ({ state: stateReducer(prev.state) }));
      get().revalidateVisibleSteps();
    },

    clearState: () =>
      set({
        state: {},
        stepsCount: get().allowedStepIndexes.length,
        allowedStepIndexes: getAllowedIndexes({
          ...initialProps,
          state: initialProps.initialState ?? {},
        }),
      }),

    next: () => {
      const { canGoNext, allowedStepIndexes, currentStepIndex } = get();
      if (canGoNext()) {
        set({
          currentStepIndex:
            allowedStepIndexes[
              allowedStepIndexes.indexOf(currentStepIndex) + 1
            ],
        });
      }
    },

    prev: () => {
      const { canGoPrev, allowedStepIndexes, currentStepIndex } = get();
      if (canGoPrev()) {
        set({
          currentStepIndex:
            allowedStepIndexes[
              allowedStepIndexes.indexOf(currentStepIndex) - 1
            ],
        });
      }
    },

    validate: () => {
      const { steps, state, currentStepIndex: currentStep } = get();
      return steps[currentStep].validate(state);
    },

    revalidateVisibleSteps: (props) => {
      const newAllowedStepIndexes = getAllowedIndexes({
        ...initialProps,
        ...props,
        state: get().state,
      });

      const diff = newAllowedStepIndexes.filter(
        (i) => !get().allowedStepIndexes.includes(i),
      );

      if (diff.length === 0) return;

      if (diff.some((i) => i <= get().currentStepIndex)) {
        console.error(
          'revalidateVisibleSteps: cannot change visibility of previous or current steps',
        );
        return;
      }

      set({
        allowedStepIndexes: newAllowedStepIndexes,
        stepsCount: newAllowedStepIndexes.length,
      });
    },
  }));
};
