import { formatResponseToTime } from 'features/editSchedule/utils';
import { schema } from 'schema';
import { DayType, PeriodType, Period } from 'types';
import { PeriodBlockView } from 'types/openApi/PeriodBlockView';
import { BlockingError } from 'utils/errors';
import { z } from 'zod';

function isRegularPeriodResponse(
  period: PeriodBlockView,
): period is PeriodBlockView & {
  dayType: DayType;
  periodType: PeriodType;
} {
  // TODO: temporary code for handling invalid period states coming from backend
  if (
    (!period.dayType && period.periodType) ||
    (period.dayType && !period.periodType)
  ) {
    throw new BlockingError(
      'Period response is missing dayType or periodType. This is a invalid state.',
    );
  }

  return !!period.dayType?.id && !!period.periodType?.id;
}

function periodResponseToPeriod(response: PeriodBlockView): Period {
  const periodProps = isRegularPeriodResponse(response)
    ? {
        dayType: { id: response.dayType.id, name: response.dayType.name },
        periodType: {
          id: response.periodType.id,
          name: response.periodType.name,
        },
      }
    : {};

  return {
    id: response.id,
    name: response.name,
    startTime: formatResponseToTime(response.startTime),
    endTime: formatResponseToTime(response.endTime),
    ...periodProps,
  };
}

function isCustomPeriod(
  period: Period,
): period is z.infer<(typeof schema)['customPeriod']> {
  return !period.periodType?.id;
}

function isRegularPeriod(
  period: Period,
): period is z.infer<(typeof schema)['regularPeriod']> {
  return !isCustomPeriod(period);
}

export const periodUtils = {
  periodResponseToPeriod,
  isCustomPeriod,
  isRegularPeriod,
};
