import React, { Fragment, PureComponent } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Navbar from 'features/navbar';
import Footer from 'features/components/Footer';
import NoMatch from 'components/shared/NoMatch/NoMatch';
import { ROUTES, ROUTE_PATHS } from 'config/routes';
import { authActions } from 'store/actions/actions';
import { hasAccessTo } from 'store/selectors/auth';

import Logout from '../Logout/Logout';
import LoginContainer from '../login/LoginContainer';
import SchoolsGridContainer from '../schools/SchoolsGridContainer';
import SchoolsMapContainer from '../map/SchoolsMapContainer';

import './MainContainer.scss';
import './LegacyStylesMaybeDelete.scss';
import './LegacyStyles2.scss';
import './LegacyStyles3.mqueries.scss';
import './LegacyStyles4.scss';
import './LegacyStyles5.scss';
import './LegacyStyles6.scss';
import './ViewScheduleLegacyStyles.scss';

import School from '../../pages/school';
import SchoolsPage from '../../features/schools/page';
import MantineProvider from '../../providers/MantineProvider';

class MainContainer extends PureComponent {
  validateUserAuth(auth, dispatch) {
    if (!auth.userValidated && !auth.validatingUser) {
      dispatch(authActions.validateUserAuth());
    }
  }

  renderFragment = () => <Fragment />;

  getRedirectUrl(scopes) {
    if (hasAccessTo(scopes, ['owner'])) {
      return '/map';
    }
    if (hasAccessTo(scopes, ['school_management', 'schedule_management'])) {
      return '/network';
    }
    return '/schools';
  }

  renderWrapperContainer = (
    auth,
    basename,
    routeProps,
    saturnNav,
    saturnFooter,
  ) => {
    const { history } = this.props;
    return (
      <Router basename={basename} history={history}>
        {auth && !auth.authenticated ? (
          <Switch>
            <Route exact render={(props) => <LoginContainer {...props} />} />
            <Route
              render={(props) => (
                <NoMatch {...props} redirect={ROUTE_PATHS.root} />
              )}
            />
          </Switch>
        ) : (
          <Switch>
            {/* remove trailing slash from all routes */}
            <Redirect
              from="/:url*(/+)"
              to={window.location.pathname.slice(0, -1)}
            />
            <Route path="/">
              <>
                {saturnNav}
                <div className="main-container">
                  <Container fluid>
                    <Switch>
                      <Route path="/reload" component={null} key="reload" />
                      <Route
                        exact
                        path={ROUTE_PATHS.root}
                        render={() => {
                          const url = this.getRedirectUrl(auth.scopes);
                          return <Redirect to={url} />;
                        }}
                      />
                      <Route
                        exact
                        path={ROUTE_PATHS.schoolsGrid}
                        render={(props) => <SchoolsGridContainer {...props} />}
                      />
                      {/* TODO: remove after schools page is migrated to new UI */}
                      <Route
                        exact
                        path="/schools-new"
                        render={() => (
                          <MantineProvider>
                            <SchoolsPage />
                          </MantineProvider>
                        )}
                      />
                      <Route
                        exact
                        path={ROUTE_PATHS.schoolsMap}
                        render={(props) => <SchoolsMapContainer {...props} />}
                      />
                      {ROUTES.map((route) => (
                        <RouteWithSubRoutes
                          key={route.path}
                          routeProps={routeProps}
                          {...route}
                        />
                      ))}
                      <Route
                        exact
                        path={ROUTE_PATHS.logout}
                        render={(props) => <Logout {...props} />}
                      />
                      <Route render={(props) => <NoMatch {...props} />} />
                    </Switch>
                  </Container>
                </div>
                {saturnFooter}
              </>
            </Route>
          </Switch>
        )}
      </Router>
    );
  };

  render() {
    const { auth, dispatch } = this.props;
    if (!auth.authenticated) {
      this.validateUserAuth(auth, dispatch);
    }
    const routeProps = {
      handleLogout: this.handleLogout,
    };
    const basename = process.env.REACT_APP_BASENAME || '';
    const saturnNav = auth && auth.authenticated ? <Navbar /> : null;
    const saturnFooter = auth && auth.authenticated ? <Footer /> : null;
    const mainContent =
      auth && auth.isAuthenticating
        ? this.renderFragment()
        : this.renderWrapperContainer(
            auth,
            basename,
            routeProps,
            saturnNav,
            saturnFooter,
          );

    return (
      <div>
        <Fragment>{mainContent}</Fragment>
      </div>
    );
  }
}

const RouteWithSubRoutes = (route) => (
  <Fragment>
    <Route
      exact
      path={route.path}
      render={(props) => (
        <route.component {...props} title={route.name} {...route.props} />
      )}
    />
    {route.subroutes && (
      <Fragment>
        {route.subroutes.map((subroute) => (
          <Route
            key={Date.now()}
            path={`${route.path}${subroute.path}`}
            render={(routeProps) => {
              return <subroute.component {...routeProps} key={Date.now()} />;
            }}
          />
        ))}
      </Fragment>
    )}
  </Fragment>
);

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MainContainer);
