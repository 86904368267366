import React, { PureComponent, useState } from 'react';
import {
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
  Form,
} from 'react-bootstrap';
import miscService from 'services/misc.service';
import Select from 'react-select';
import moment from 'moment';
import { getDateFormat } from 'utils/utilities';

function DeletePopover() {
  const [banning, setBanning] = useState(false);
  const [value, setValue] = useState('');
  const [action, setAction] = useState('ban');
  const [reason, setReason] = useState(null);
  const [time, setTime] = useState(null);
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <div className="delete-confirm">
        <h5 className="text-center">
          User {action === 'ban' ? 'banned' : 'suspended'}
        </h5>
        <Button
          variant="danger"
          className="next-btn"
          onClick={() => {
            setSuccess(false);
            setValue('');
          }}
        >
          Ban/Suspend Another
        </Button>
      </div>
    );
  }

  const banUser = () => {
    setBanning(true);

    const body = {
      reason,
      deletion: action === 'ban',
    };

    const field_name = value.startsWith('+')
      ? 'phone_number'
      : isNaN(parseInt(value))
      ? 'email'
      : 'user_id';
    body[field_name] = value;

    if (action === 'suspend') {
      body['until'] = time;
    }

    miscService()
      .banUser(body)
      .then((response) => {
        setBanning(false);
        setSuccess(true);
      })
      .catch(() => {
        setBanning(false);
        setSuccess(true);
      });
  };

  return (
    <div className="delete-post-container">
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Email, Phone or User ID"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Check
            inline
            type="radio"
            name="action"
            value="ban"
            label="Ban"
            id="ban"
            checked={action === 'ban'}
            onChange={({ target }) => {
              setAction(target.value);
            }}
          />
          <Form.Check
            inline
            type="radio"
            name="action"
            value="suspend"
            label="Suspend"
            id="suspend"
            checked={action === 'suspend'}
            onChange={({ target }) => {
              setAction(target.value);
            }}
          />
        </Form.Group>

        <Select
          className="suspension-options-select"
          isSearchable={false}
          options={[
            {
              value: 'Abusive use of chat features',
              label: 'Abusive use of chat features',
            },
            {
              value: "Profile doesn't follow our community rules",
              label: "Profile doesn't follow our community rules",
            },
          ]}
          onChange={({ value }) => {
            setReason(value);
          }}
          placeholder="Select a reason..."
        />

        {action === 'suspend' && (
          <Select
            className="suspension-options-select"
            isSearchable={false}
            options={[
              { value: '1 day', label: '1 Day' },
              { value: '1 week', label: '1 Week' },
              { value: '1 month', label: '1 Month' },
            ]}
            onChange={({ value }) => {
              const [amount, unit] = value.split(' ');
              const date = moment().add(parseInt(amount), unit);
              setTime(getDateFormat(date, 'YYYY-MM-DD'));
            }}
            placeholder="Set the time..."
          />
        )}

        <Button
          variant="danger"
          type="submit"
          className="next-btn"
          disabled={
            value === '' ||
            reason === null ||
            (action === 'suspend' && time === null) ||
            banning
          }
          onClick={banUser}
        >
          {banning ? (
            <Spinner animation="grow" />
          ) : action === 'ban' ? (
            'Ban User'
          ) : (
            'Suspend User'
          )}
        </Button>
      </Form>
    </div>
  );
}

export default class QuickBan extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: true,
      uploading: true,
      finished: false,
    };

    this.renderTooltip = this.renderTooltip.bind(this);
  }

  renderTooltip = (props) => {
    return (
      <Popover id="suspension-popover" {...props} show={props.show.toString()}>
        <DeletePopover
          onDeleted={(url) => {
            this.setState({ url: url });
          }}
        />
      </Popover>
    );
  };

  render() {
    return (
      <OverlayTrigger
        placement="bottom"
        trigger="click"
        rootClose
        overlay={this.renderTooltip}
      >
        <Button variant="danger">Ban/Suspend User</Button>
      </OverlayTrigger>
    );
  }
}
