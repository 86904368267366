import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './BanUser.scss';
import Select from 'react-select';
import moment from 'moment';
import { getDateFormat } from 'utils/utilities';

export function SuspensionPopover({ displayReasonPicker = true, onConfirm }) {
  const [time, setTime] = useState(false);
  const [reason, setReason] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  return (
    <div>
      {displayReasonPicker && (
        <Select
          className="suspension-options-select"
          isSearchable={false}
          options={[
            {
              value: 'Abusive use of chat features',
              label: 'Abusive use of chat features',
            },
            {
              value: "Profile doesn't follow our community rules",
              label: "Profile doesn't follow our community rules",
            },
          ]}
          onChange={({ value }) => {
            setReason(value);
          }}
          placeholder="Select a reason..."
        />
      )}
      <Select
        className="suspension-options-select"
        isSearchable={false}
        options={[
          { value: '1 day', label: '1 Day' },
          { value: '1 week', label: '1 Week' },
          { value: '1 month', label: '1 Month' },
        ]}
        onChange={({ value }) => {
          const [amount, unit] = value.split(' ');
          const date = moment().add(parseInt(amount), unit);
          setTime(getDateFormat(date, 'YYYY-MM-DD'));
        }}
        placeholder="Set the time..."
      />
      <Button
        variant="success"
        onClick={() => {
          onConfirm(time, reason);
          setConfirmed(true);
        }}
        disabled={confirmed}
      >
        Confirm
      </Button>
    </div>
  );
}
