import React from 'react';
import { useDispatch } from 'react-redux';

import { specialSchedules } from 'constants/specialSchedules';

import IconScheduleSpecial from 'assets/icons/schedule_type.svg';
import IconChecked from 'assets/icons/checked.svg';

import { SchoolV1 } from 'types';

import {
  handleScheduleChange,
  regenerateRotationData,
  setDaySchedule,
} from './utils';

interface Props {
  dayInfo: any;
  day: any;
  school: SchoolV1;
  onRegenerate: (rotationData: any, buildFromStart: boolean) => void;
}

const VacationPicker = ({ dayInfo, day, school, onRegenerate }: Props) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="popover-schedule-types-title">Vacation</div>

      {specialSchedules.map((schedule) => {
        return (
          <div
            key={`schedule-${schedule.id}`}
            className="popover-schedule-types-content-option"
            onClick={() => {
              handleScheduleChange(
                school,
                dayInfo,
                day,
                () => {
                  setDaySchedule(school, dispatch, day, 0, schedule.id);
                },
                () => {
                  regenerateRotationData(
                    onRegenerate,
                    school,
                    schedule.id,
                    dayInfo.dateNow,
                    true,
                  );
                },
              );
            }}
          >
            <div className="popover-container-actions-item">
              <img src={IconScheduleSpecial} alt="icon_schedule" />
              <span className="popover-schedule-types-content-option-text-selected">
                {schedule.label}
              </span>
            </div>
            {dayInfo && dayInfo.dateLabel === schedule.id && (
              <img src={IconChecked} alt="icon_checked" />
            )}
          </div>
        );
      })}
    </>
  );
};

export default VacationPicker;
