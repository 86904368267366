import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import overlayFactory from 'react-bootstrap-table2-overlay';

import AdvancedTable from '../AdvancedTable/AdvancedTable';

const RemoteTable = ({
  data,
  getColumns,
  defaultSorted: { dataField, order, filter_by: defaultFilterBy },
  refreshHandler,
  options,
  loading,
  total,
  clickedFilterValue,
  setClickedFilterValue,
  rowClasses,
  keyField,
  itemName,
  suggestionsOptions,
}) => {
  const [sortBy, setSortBy] = useState(dataField);
  const [orderBy, setOrderBy] = useState(order);
  const [filterBy, setFilterBy] = useState(defaultFilterBy);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  useEffect(() => {
    const params = {
      paginate: true,
      limit: sizePerPage,
      page,
      order_by: orderBy,
      sort_by: sortBy,
      filter_by: filterBy,
      search,
    };

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });

    refreshHandler(params);
  }, [sizePerPage, page, orderBy, sortBy, filterBy, search, refreshHandler]);

  const onTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText },
  ) => {
    switch (type) {
      case 'search':
        setSearch(searchText);
        setPage(1);
        break;
      case 'sort':
        setSortBy(sortField);
        setOrderBy(sortOrder);
        break;
      case 'pagination':
        setPage(page);
        setSizePerPage(sizePerPage);
        break;
      default:
        break;
    }
  };

  const onFilterSelect = (value) =>
    setFilterBy(
      value
        ? `${options.dropdownFilterField}:eq:${value
            .replace(' ', '_')
            .toLowerCase()}`
        : null,
    );

  return (
    <AdvancedTable
      keyField={keyField}
      data={data}
      columns={getColumns()}
      paginationOptions={{ page, sizePerPage, totalSize: total }}
      loading={loading}
      clickedFilterValue={clickedFilterValue}
      setClickedFilterValue={setClickedFilterValue}
      rowClasses={rowClasses}
      onTableChange={onTableChange}
      suggestionsOptions={suggestionsOptions}
      onSelect={onFilterSelect}
      itemName={itemName}
      remoteOptions={{ pagination: true }}
      overlay={overlayFactory({
        spinner: <Spinner animation="grow" className="ripple" />,
        styles: { overlay: (base) => ({ ...base, background: '#fffb' }) },
      })}
      options={options}
    />
  );
};

export default RemoteTable;
