import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MM from '@mantine/modals';
import { useState } from 'react';
import type { CoreSchedule, DayType, LunchSettings, PeriodType } from 'types';

import { CUSTOM_PERIODS } from 'features/scheduleConfiguration/constants';
import { useGetParseResults } from 'features/scheduleConfiguration/hooks/queries';

import ScheduleInfoStep from './steps/ScheduleInfoStep';
import ScheduleLunchWavesStep from './steps/ScheduleLunchWavesStep';
import SchedulePeriodsStep from './steps/SchedulePeriodsStep';
import ScheduleReviewStep from './steps/ScheduleReviewStep';
import ScheduleSelectStep from './steps/ScheduleSelectStep';
import { ParsedSchedule } from 'features/scheduleConfiguration/utils';

interface Props {
  schoolId: string;
  initialSchedule?: Partial<CoreSchedule>;
  order: number;
  dayType: DayType;
  periodTypes: PeriodType[];
  lunchSettings: LunchSettings;
  isCopying: boolean;
  onClose: () => void;
  onSubmit: (schedule: Partial<CoreSchedule>) => void;
}

const EditScheduleModal = ({
  schoolId,
  initialSchedule,
  order,
  dayType,
  periodTypes,
  lunchSettings,
  isCopying,
  onClose,
  onSubmit,
}: Props) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [schedule, setSchedule] = useState<Partial<CoreSchedule>>({
    ...initialSchedule,
    order,
    variant: 'core',
    dayType,
  });

  const [selectedItem, setSelectedItem] = useState<ParsedSchedule>();

  const { data: parseResults, isLoading, error } = useGetParseResults(schoolId);

  const handlePrev = (update: Partial<CoreSchedule>) => {
    setSchedule((prev) => ({ ...prev, ...update }));
    if (stepIndex > 0) {
      setStepIndex((prev) => prev - 1);
    }
  };

  const handleNext = (update: Partial<CoreSchedule>) => {
    setSchedule((prev) => ({ ...prev, ...update }));
    if (stepIndex < steps.length - 1) {
      setStepIndex((prev) => prev + 1);
    }
  };

  const confirmClose = () =>
    MM.openConfirmModal({
      title: 'You have unsaved changes, are you sure you want to exit?',
      onConfirm: onClose,
      labels: {
        confirm: 'Exit anyways',
        cancel: 'Resume editing',
      },
      withCloseButton: false,
    });

  const shouldSkipScheduleSelectStep =
    isCopying || !!error || !parseResults?.length;

  const steps = [
    ...(!shouldSkipScheduleSelectStep
      ? [
          <ScheduleSelectStep
            key="schedule-select"
            enableRefetching={!!parseResults.length}
            schoolId={schoolId}
            dayType={dayType}
            periodTypes={periodTypes}
            lunchSettings={lunchSettings}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            onNext={handleNext}
          />,
        ]
      : []),

    <ScheduleInfoStep
      key="schedule-info"
      schedule={schedule}
      dayTypeName={dayType.name}
      onPrev={!shouldSkipScheduleSelectStep ? handlePrev : undefined}
      onNext={handleNext}
    />,

    <SchedulePeriodsStep
      key="schedule-periods"
      schedule={schedule}
      dayType={dayType}
      periodTypes={[
        ...periodTypes,
        ...(schedule.periods
          ?.filter((p) => !p.periodType)
          .map((p) => ({
            id: '',
            name: p.name,
          })) || []),
        ...(!lunchSettings.type || lunchSettings.type === 'unit'
          ? [{ id: '', name: CUSTOM_PERIODS.lunch }]
          : []),
      ]}
      onPrev={handlePrev}
      onNext={handleNext}
    />,

    ...(lunchSettings.type === 'waves'
      ? [
          <ScheduleLunchWavesStep
            key="schedule-lunch-waves"
            schedule={schedule}
            slots={lunchSettings.slots}
            isAlphabetic={lunchSettings.isAlphabetic}
            onPrev={handlePrev}
            onNext={handleNext}
          />,
        ]
      : []),

    <ScheduleReviewStep
      key="schedule-review"
      schedule={schedule}
      onPrev={handlePrev}
      onSubmit={onSubmit}
    />,
  ];

  return (
    <M.Modal
      opened
      size="900px"
      onClose={() => {
        if (schedule) {
          confirmClose();
        } else {
          onClose();
        }
      }}
      withCloseButton={false}
    >
      <Container>
        <Title order={3}>{dayType.name} (core schedule)</Title>
        {isLoading ? (
          <M.Flex w="100%" justify="center" p="3rem">
            <M.Loader />
          </M.Flex>
        ) : (
          steps[stepIndex]
        )}
      </Container>
    </M.Modal>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Title = styled(M.Title)`
  text-align: center;
`;

export default EditScheduleModal;
