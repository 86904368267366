import styled from '@emotion/styled';
import * as M from '@mantine/core';

const Modal = styled(M.Modal)`
  .mantine-Modal-modal {
    padding: 0;

    border-radius: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .mantine-Modal-header {
    margin: 0;
    padding: ${({ theme: t }) => t.spacing.xs} ${({ theme: t }) => t.spacing.md};

    font-size: ${({ theme }) => theme.fontSizes.xl};

    color: #fff;
    background: ${({ theme }) => theme.other.saturnGradient.primary};

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .mantine-Modal-title {
    width: 100%;
  }

  .mantine-Modal-close {
    color: #fff;

    transform: scale(1.5);

    &:hover {
      background-color: #fff1;
    }

    &:active {
      transform: scale(1.5) translateY(1px);
    }
  }

  .mantine-Modal-body {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

export default Modal;
