import { useSelector } from 'react-redux';

import type { SchoolV1 } from 'types';

export const useSchoolV1 = () => {
  const school: SchoolV1 | null = useSelector(
    (state) => (state as any).schools.currentSchool,
  );

  const isNotFound =
    useSelector(
      (state) => (state as any).schools.currentSchoolError?.response.status,
    ) === 404;

  return { school, isNotFound };
};
