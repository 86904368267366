import { combineReducers } from 'redux';
import schools from './schools';
import auth from './auth';
import fileupload from './fileupload';
import network from './network';
import reports from './reports';
import misc from './misc';

const reducers = combineReducers({
  schools,
  auth,
  fileupload,
  network,
  reports,
  misc,
});

const rootReducers = (state, action) => {
  if (action.type === 'AUTH_USER_LOGOUT_SUCCESS') {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducers;
