import { useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MD from '@mantine/dates';
import * as MN from '@mantine/notifications';

import { formatDate } from 'hooks/utils';
import type { SchoolYear } from 'types';

interface Props {
  schoolYears: SchoolYear[];
  selectedSchoolYear?: SchoolYear;
  createSchoolYear: (range: [Date, Date]) => void;
  updateSchoolYear: (schoolYear: SchoolYear) => void;
  onClose: () => void;
}

const SchoolYearModal = ({
  schoolYears,
  selectedSchoolYear,
  createSchoolYear,
  updateSchoolYear,
  onClose,
}: Props) => {
  const [[start, end], setDateRange] = useState<MD.DatesRangeValue>(
    selectedSchoolYear
      ? [selectedSchoolYear.startDate, selectedSchoolYear.endDate]
      : [null, null],
  );

  const handleSave = () => {
    if (!start || !end) return;

    const isOverlapping = schoolYears
      .filter((sy) => sy.id !== selectedSchoolYear?.id)
      .some(
        (sy) =>
          (sy.startDate <= start && start <= sy.endDate) ||
          (sy.startDate <= end && end <= sy.endDate),
      );

    if (isOverlapping) {
      MN.showNotification({
        message: 'School years cannot overlap',
        color: 'red',
      });
      return;
    }

    if (!selectedSchoolYear) {
      createSchoolYear([start, end]);
    } else {
      updateSchoolYear({
        ...selectedSchoolYear,
        startDate: start,
        endDate: end,
      });
    }

    onClose();
  };

  return (
    <M.Modal
      opened
      centered
      onClose={onClose}
      title={selectedSchoolYear ? 'Edit School Year' : 'Add School Year'}
      size="l"
    >
      <Container>
        <DateRow>
          <DatePicker
            label="Start date"
            defaultDate={start || undefined}
            value={start}
            onChange={(d) => setDateRange([d, end])}
            maxDate={end ? dayjs(end).subtract(1, 'day').toDate() : undefined}
          />
          <DatePicker
            label="End date"
            defaultDate={end || undefined}
            value={end}
            onChange={(d) => setDateRange([start, d])}
            minDate={start ? dayjs(start).add(1, 'day').toDate() : undefined}
          />
        </DateRow>

        <M.Text align="center">
          {formatDate(start || undefined)} - {formatDate(end || undefined)}
        </M.Text>

        <M.Button onClick={handleSave} disabled={!start || !end}>
          Save
        </M.Button>
      </Container>
    </M.Modal>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xl};
`;

const DateRow = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.xl};
`;

const DatePicker = ({
  label,
  ...datePickerProps
}: MD.DatePickerProps & { label: string }) => (
  <M.Flex direction="column" align="center">
    <M.Text weight="bold">{label}</M.Text>
    <MD.DatePicker
      excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
      firstDayOfWeek={0}
      {...datePickerProps}
    />
  </M.Flex>
);

export default SchoolYearModal;
