import styled from '@emotion/styled';

export const PeriodsBuilderHelp = ({
  hasCustomPeriods,
  hasOverlappingPeriods,
}: {
  hasCustomPeriods: boolean;
  hasOverlappingPeriods: boolean;
}) => (
  <div>
    {hasCustomPeriods && (
      <HelpSection>
        <ColorBox color="warn" />
        custom periods
      </HelpSection>
    )}
    {hasOverlappingPeriods && (
      <HelpSection>
        <ColorBox color="error" />
        overlapping periods
      </HelpSection>
    )}
  </div>
);

const HelpSection = styled.div`
  padding-bottom: ${(p) => p.theme.spacing.sm};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.sm};
`;

const ColorBox = styled.div<{ color: 'error' | 'warn' }>`
  width: 1rem;
  height: 1rem;
  background-color: ${(p) =>
    p.color === 'error' ? p.theme.colors.red[2] : p.theme.colors.yellow[1]};
  border: 1px solid ${(p) => p.theme.colors.border};
  border-radius: 2px;
`;
