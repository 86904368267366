import * as M from '@mantine/core';
import { DATE_DISPLAY_FORMAT } from 'constants/dates';
import { modificationTypes } from 'constants/scheduleInfo';
import dayjs from 'dayjs';
import { BROKEN_DAY_TYPE_ID, MISSING_MODIFICATION } from 'hooks/utils';
import { Schedule } from 'types';

type Props = {
  schedule: Schedule;
  hideDraft?: boolean;
};

export const ScheduleBadge = ({ schedule, hideDraft }: Props) => {
  return (
    <M.Flex align="center" gap="xs">
      {schedule.variant === 'core' && schedule.isInAcf && (
        <M.Badge color="green">ACF</M.Badge>
      )}

      {schedule.variant === 'modified' && (
        <M.Badge color="blue">
          {modificationTypes.find((t) => t.value === schedule.modification)
            ?.label || 'OTHER'}
        </M.Badge>
      )}

      {schedule.variant === 'special' && (
        <M.Badge color="orange">
          {dayjs(schedule.date).format(DATE_DISPLAY_FORMAT)}
        </M.Badge>
      )}

      {schedule.isDraft && !hideDraft && (
        <M.Badge color="yellow">Draft</M.Badge>
      )}

      {(schedule.variant === 'core' || schedule.variant === 'modified') &&
        schedule.dayType.id === BROKEN_DAY_TYPE_ID && (
          <M.Badge color="red">
            Missing day type: {schedule.dayType.name}
          </M.Badge>
        )}

      {schedule.variant === 'modified' &&
        schedule.otherModification === MISSING_MODIFICATION && (
          <M.Badge color="red">Missing modification</M.Badge>
        )}

      {schedule.variant === 'core' && schedule.brokenDayOfWeek && (
        <M.Badge color="red">Invalid day of week</M.Badge>
      )}

      {schedule.image && '📸'}
    </M.Flex>
  );
};
