import * as M from '@mantine/core';

type Props = {
  title: string;
  description?: string;
};

export const StepHeader = ({ title, description }: Props) => {
  return (
    <M.Box py="lg">
      <M.Title order={2}>{title}</M.Title>
      {description && <M.Text>{description}</M.Text>}
    </M.Box>
  );
};
