import moment from 'moment';
import { ScheduleType } from 'components/shared/ScheduleSettingsManager/constants';
import { weekDays } from 'components/wizards/CalendarWizard/Steps/OptionsStep';

import { updateApiClientAuth } from './apiClient';

export function secondsToAmPm(seconds) {
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString(10)
    .padStart(2, '0');
  let amPM = 'AM';
  let hours = Math.floor(seconds / 3600);
  if (hours > 12) {
    hours -= 12;
    amPM = 'PM';
  }
  return `${hours}:${minutes} ${amPM}`;
}

// https://stackoverflow.com/a/38552302
export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export function validateToken(token) {
  const tokenDecoded = parseJwt(token);
  return !!tokenDecoded;
}

export function saveTokenUser(token, refresh_token) {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  document.cookie = `wyz_token=${token}; expires=${expires}; path=/; samesite=none; secure`;
  if (refresh_token) {
    document.cookie = `wyz_ref=${refresh_token}; path=/; samesite=none; secure`;
  }
  updateApiClientAuth();
}

export function removeToken() {
  document.cookie = 'wyz_token=';
  document.cookie = 'wyz_ref=';
}

export function getToken() {
  const cookies = decodeURIComponent(document.cookie);
  const cookieSplit = cookies.split(';');
  let token;

  cookieSplit.forEach((cookie) => {
    if (cookie.includes('wyz_token')) {
      token = cookie.replace('wyz_token=', '').trim();
    }
  });

  return token || null;
}

export function getRefreshToken() {
  const cookies = decodeURIComponent(document.cookie);
  const cookieSplit = cookies.split(';');
  let token;

  cookieSplit.forEach((cookie) => {
    if (cookie.includes('wyz_ref')) {
      token = cookie.replace('wyz_ref=', '').trim();
    }
  });

  return token || null;
}

export function getDateFormat(day, format, original_format = 'YYYY-MM-DD') {
  const date = moment(day, original_format, true);
  return date.isValid() === true ? date.format(format) : '';
}

export function getCalendarMapping(calendar) {
  const mapping = [];

  for (const day of calendar) {
    const date = getDateFormat(day.date, 'YYYY-MM-DD');

    mapping.push({
      dateLabel: !!day.schedule ? day.schedule.name : day.raw_block_name,
      scheduleDisplay: !!day.schedule
        ? day.schedule.display_name
        : day.raw_block_name,
      dateNow: date,
      is_canceled: day.is_canceled,
      schedule_id: !!day.schedule ? day.schedule.id : null,
      modified: !day.schedule?.special && !day.schedule?.static,
      special: day.schedule?.special,
    });
  }

  calendar = {
    ...calendar,
    mapping,
  };

  return calendar;
}

export const number_comparators = {
  '=': 'eq',
  '!=': 'ne',
  '>': 'gt',
  '>=': 'ge',
  '<': 'lt',
  '<=': 'le',
};

export const arrayMove = (array, from, to) => {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  );
  return slicedArray;
};

export const isWeekday = (date) => {
  const day = moment(date).weekday();
  return day !== 0 && day !== 6;
};

export const extractCurrentCalData = (
  calendarData,
  scheduleCode,
  startDate,
  rotationPattern,
  schedule_type,
  buildFromStart = false,
) => {
  const weekdays = ['S', 'M', 'T', 'W', 'TH', 'F'];

  startDate = moment(startDate, 'YYYY-MM-DD');
  const endDate = moment(
    calendarData.at(-1)?.dateNow || moment(startDate).add(1, 'day'),
    'YYYY-MM-DD',
  );
  const scheduleName = scheduleCode.includes('!')
    ? scheduleCode.split('!')[0]
    : scheduleCode;
  const rotationStart = {
    value: rotationPattern.indexOf(scheduleName),
    label: scheduleName,
  };

  const lockedDaysFilter = (day) =>
    weekdays.includes(day.dateLabel.split('!')[1]);
  const lockedDaysMapper = (day) =>
    weekdays.indexOf(day.dateLabel.split('!')[1]);

  let lockedWeekdays = [];
  if (schedule_type !== ScheduleType.MONDAY_THROUGH_FRIDAY) {
    lockedWeekdays = [
      ...new Set(calendarData.filter(lockedDaysFilter).map(lockedDaysMapper)),
    ];
    lockedWeekdays = weekDays.filter((wd) => lockedWeekdays.includes(wd.value));
  }

  calendarData = calendarData.filter((x) =>
    moment(x.dateNow, 'YYYY-MM-DD').isSameOrAfter(startDate),
  );

  let fixedDates = calendarData.filter(
    (d) =>
      !moment(d.dateNow, 'YYYY-MM-DD').isSame(startDate) &&
      (d.dateLabel.includes('!') ||
        d.dateLabel.includes('#-') ||
        d.dateLabel === 'X'),
  );

  fixedDates = scheduleCode.includes('!')
    ? [{ dateNow: startDate, dateLabel: scheduleCode }, ...fixedDates]
    : fixedDates;
  fixedDates = fixedDates
    .filter((d) => !lockedDaysFilter(d))
    .map((d) => ({
      date: d.dateNow,
      schedule:
        d.dateLabel.includes('#-') || d.dateLabel === 'X'
          ? d.dateLabel
          : d.dateLabel.split('!')[1],
      special: d.dateLabel.includes('#-') || d.dateLabel === 'X',
    }));

  if (buildFromStart) {
    fixedDates = [
      {
        date: startDate,
        schedule: scheduleCode,
        special: scheduleCode.includes('#-') || scheduleCode === 'X',
      },
      ...fixedDates,
    ];
  }

  return {
    start_date: startDate.toDate(),
    end_date: endDate.toDate(),
    locked_weekdays: lockedWeekdays,
    rotation_start: rotationStart,
    fixed_dates: fixedDates,
  };
};

export const isProd = ['preview', 'production'].includes(
  process.env.REACT_APP_ENV,
);

export const getSortedSchedulesByType = (school, schedules, type) =>
  schedules
    .filter((schedule) => {
      switch (type) {
        case 'static':
          return schedule.static;
        case 'modified':
          return !schedule.static && !schedule.special;
        case 'special':
          return schedule.special;
      }
    })
    .sort((a, b) => {
      if (type === 'special') {
        const cal = Object.values(school.calendar).filter((c) => !!c.schedule);

        return (
          cal.findIndex((c) => c.schedule.id === b.id) -
          cal.findIndex((c) => c.schedule.id === a.id)
        );
      }

      const sortByDayType =
        school.day_types.findIndex((d) => d.id === a.day_type_id) -
        school.day_types.findIndex((d) => d.id === b.day_type_id);

      return sortByDayType || a.display_name.localeCompare(b.display_name);
    });
