import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import dayjs from 'dayjs';
import * as M from '@mantine/core';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconTrash } from '@tabler/icons-react';

import { Table } from 'features/components/Table';
import { DATE_SERVER_FORMAT } from 'constants/dates';
import type { UserViewDashboard } from 'types/openApi/UserViewDashboard';

import {
  useDeleteUser,
  useEditUser,
  useToggleAmbassador,
  useUsers,
} from './queries';
import { EditUserModal } from './components/EditUserModal';

const UsersPage = () => {
  const { data, isFetching, isInitialLoading } = useUsers();
  const toggleAmbassadorMutation = useToggleAmbassador();
  const editUserMutation = useEditUser();
  const deleteUserMutation = useDeleteUser();
  const location = useLocation();

  const [editedRow, setEditedRow] = useState<UserViewDashboard>();

  const openDeleteConfirmModal = (row: MRT_Row<UserViewDashboard>) =>
    modals.openConfirmModal({
      title: `Deleting user ${row.original.firstName} ${row.original.lastName}?`,
      children: <M.Text>This action cannot be undone.</M.Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteUserMutation.mutate(row.original.id),
    });

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'id',
          header: 'ID',
          enableEditing: false,
        },
        {
          accessorKey: 'name',
          header: 'Name',
        },
        {
          accessorKey: 'phoneNumber',
          header: 'Phone',
        },
        {
          accessorKey: 'email',
          header: 'email',
        },
        {
          accessorKey: 'isAmbassador',
          header: 'Ambassador',
          enableSorting: false,
          enableEditing: false,
          Cell: ({ row, cell }) => (
            <M.Switch
              checked={cell.getValue<boolean>()}
              onChange={({ currentTarget }) =>
                toggleAmbassadorMutation.mutate({
                  userId: row.original.id,
                  toggledOn: currentTarget.checked,
                })
              }
            />
          ),
        },
      ] satisfies MRT_ColumnDef<UserViewDashboard>[],
    [toggleAmbassadorMutation],
  );

  const [isModalOpen, modalControls] = useDisclosure();

  return (
    <>
      <Table
        columns={columns}
        data={data}
        tableOptions={{
          enableRowActions: true,
          positionActionsColumn: 'last',
          initialState: {
            sorting: [{ id: 'name', desc: false }],
            globalFilter: new URLSearchParams(location.search).get('id'),
          },
          state: { showProgressBars: isFetching && !isInitialLoading },
          renderRowActions: ({ row }) => (
            <M.Flex gap="xs">
              <M.ActionIcon
                onClick={() => {
                  setEditedRow(row.original);
                  modalControls.open();
                }}
              >
                <IconEdit />
              </M.ActionIcon>
              <M.ActionIcon onClick={() => openDeleteConfirmModal(row)}>
                <IconTrash />
              </M.ActionIcon>
            </M.Flex>
          ),
        }}
      />

      <EditUserModal
        isOpen={isModalOpen}
        rowData={editedRow}
        onClose={modalControls.close}
        isLoading={editUserMutation.isLoading}
        mutate={async (data) => {
          if (!editedRow?.id) return;

          await editUserMutation.mutateAsync({
            first_name: data.firstName,
            last_name: data.lastName,
            phone_number: data.phoneNumber,
            userId: editedRow.id,
            graduation_year: Number(data.graduationYear),
            birthday: dayjs(data.birthday).format(DATE_SERVER_FORMAT),
          });

          setEditedRow(undefined);
          modalControls.close();
        }}
      />
    </>
  );
};

export default UsersPage;
