import { useEffect } from 'react';

import { useWizard } from 'features/scheduleConfiguration/components/wizard/Wizard';
import CalendarSettings from 'features/calendarSettings';
import type { StepComponent } from 'features/scheduleConfiguration/types';

const CalendarSettingsStep: StepComponent = ({ school, stepIndex }) => {
  const wizard = useWizard();

  useEffect(() => {
    wizard.update(stepIndex, {
      type: 'calendarSettingsStep',
      payload: {
        rotationPattern: school.rotationPattern,
        schoolYearStart: school.schoolYears.at(-1)?.startDate,
        schoolYearEnd: school.schoolYears.at(-1)?.endDate,
      },
      isValid: !!school.rotationPattern.length && !!school.schoolYears.length,
    });
  }, [school.rotationPattern, school.schoolYears]);

  return <CalendarSettings school={school} />;
};

export default CalendarSettingsStep;
