import UploadImagesStep from './components/steps/uploadImages/UploadImagesStep';
import ScheduleSettingsStep from './components/steps/scheduleSettings/ScheduleSettingsStep';
import CalendarSettingsStep from './components/steps/calendarSettings/CalendarSettingsStep';
import CoreSchedulesStep from './components/steps/coreSchedules/CoreSchedulesStep';

import type { Step, StepComponent } from './types';

export const STEP_COMPONENTS: Readonly<Record<Step['type'], StepComponent>> = {
  uploadImagesStep: UploadImagesStep,
  scheduleSettingsStep: ScheduleSettingsStep,
  calendarSettingsStep: CalendarSettingsStep,
  coreSchedulesStep: CoreSchedulesStep,
};

export const CUSTOM_PERIODS = {
  lunch: 'Lunch',
} as const;
