import styled from '@emotion/styled';
import * as M from '@mantine/core';

import {
  useSetRotationPatternMutation,
  useCreateSchoolYearMutation,
  useUpdateSchoolYearMutation,
  useDeleteSchoolYearMutation,
} from '../hooks';
import type { School } from 'types';

import RotationPatternSetter from './RotationPatternSetter';
import SchoolYearManager from './SchoolYearManager';

interface Props {
  school: School;
}

const CalendarSettings = ({ school }: Props) => {
  const setRotationPattern = useSetRotationPatternMutation(school.schoolName);
  const createSchoolYear = useCreateSchoolYearMutation(school.schoolName);
  const updateSchoolYear = useUpdateSchoolYearMutation(school.schoolName);
  const deleteSchoolYear = useDeleteSchoolYearMutation(school.schoolName);

  const isLoading =
    setRotationPattern.isLoading ||
    createSchoolYear.isLoading ||
    updateSchoolYear.isLoading ||
    deleteSchoolYear.isLoading;

  return (
    <Container>
      <M.Title order={2}>Calendar Settings</M.Title>

      <RotationPatternSetter
        dayTypes={school.dayTypes.map((dt) => dt.name)}
        rotationPattern={school.rotationPattern}
        setRotationPattern={setRotationPattern.mutate}
      />

      <SchoolYearManager
        schoolYears={school.schoolYears}
        createSchoolYear={createSchoolYear.mutate}
        updateSchoolYear={updateSchoolYear.mutate}
        deleteSchoolYear={deleteSchoolYear.mutate}
      />

      <M.LoadingOverlay visible={isLoading} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

export default CalendarSettings;
