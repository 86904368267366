import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { useSchool } from 'hooks/useSchool';

import { useScheduleDetailParams } from '../hooks/useScheduleDetailParams';

const Header = () => {
  const { data: school } = useSchool();
  const { mode } = useScheduleDetailParams();

  return (
    <Container color={school?.primaryColor || ''}>
      {school?.logoUrl && <Avatar src={school.logoUrl} />}

      <DetailsContainer>
        <Title>{mode} schedule</Title>
        <Row>
          <Subtitle>{school?.schoolTitle || 'Loading...'}</Subtitle>
        </Row>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled(M.Flex)<{ color: string }>`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.md};
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};

  background: ${({ theme, color }) =>
    color
      ? `linear-gradient(to left, #0000, ${color} 50%)`
      : theme.other.saturnGradient.primary};
`;

const Avatar = styled.img`
  width: 72px;
  height: 72px;
  padding: 0.25rem;

  background: white;
  border: 2px solid ${({ theme }) => theme.colors.dark[1]};
  border-radius: 50%;
  object-fit: contain;
`;

const DetailsContainer = styled(M.Flex)`
  flex-direction: column;
`;

const Row = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.xs};
  align-items: center;
`;

const Title = styled(M.Title)`
  text-transform: capitalize;
  color: white;
`;

const Subtitle = styled(M.Text)`
  color: white;
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

export default Header;
