import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers/reducers';
import rootSaga from './sagas/sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const appliedMiddleware = applyMiddleware(...middleware);
const composedMiddleware = process.env.NODE_ENV
  ? composeWithDevTools(appliedMiddleware)
  : appliedMiddleware;
const store = createStore(reducers, composedMiddleware);

sagaMiddleware.run(rootSaga);

export default store;
