const ActionTypes = {
  GET_SCHOOLS: 'Schools.GET_SCHOOLS',
  GET_SCHOOLS_SUCCESS: 'Schools.GET_SCHOOLS_SUCCESS',
  GET_SCHOOLS_FAIL: 'Schools.GET_SCHOOLS_FAIL',
  GET_SCHOOL_BY_ID: 'Schools.GET_SCHOOL_BY_ID',
  GET_SCHOOL_BY_ID_SUCCESS: 'Schools.GET_SCHOOL_BY_ID_SUCCESS',
  GET_SCHOOL_BY_ID_FAIL: 'Schools.GET_SCHOOL_BY_ID_FAIL',
  GET_SCHOOL_RESOURCE: 'Schools.GET_SCHOOL_RESOURCE',
  GET_SCHOOL_RESOURCE_SUCCESS: 'Schools.GET_SCHOOL_RESOURCE_SUCCESS',
  GET_SCHOOL_RESOURCE_FAIL: 'Schools.GET_SCHOOL_RESOURCE_FAIL',
  CREATE_DAY_TYPE: 'Schools.CREATE_DAY_TYPE',
  UPDATE_DAY_TYPE: 'Schools.UPDATE_DAY_TYPE',
  DELETE_DAY_TYPE: 'Schools.DELETE_DAY_TYPE',
  CREATE_PERIOD_TYPE: 'Schools.CREATE_PERIOD_TYPE',
  UPDATE_PERIOD_TYPE: 'Schools.UPDATE_PERIOD_TYPE',
  DELETE_PERIOD_TYPE: 'Schools.DELETE_PERIOD_TYPE',
  CREATE_DAY_TYPE_SUCCESS: 'Schools.CREATE_DAY_TYPE_SUCCESS',
  UPDATE_DAY_TYPE_SUCCESS: 'Schools.UPDATE_DAY_TYPE_SUCCESS',
  DELETE_DAY_TYPE_SUCCESS: 'Schools.DELETE_DAY_TYPE_SUCCESS',
  CREATE_PERIOD_TYPE_SUCCESS: 'Schools.CREATE_PERIOD_TYPE_SUCCESS',
  UPDATE_PERIOD_TYPE_SUCCESS: 'Schools.UPDATE_PERIOD_TYPE_SUCCESS',
  DELETE_PERIOD_TYPE_SUCCESS: 'Schools.DELETE_PERIOD_TYPE_SUCCESS',
  DAY_WIZARD_CREATE: 'Schools.DAY_WIZARD_CREATE',
  DAY_WIZARD_CREATE_SUCCESS: 'Schools.DAY_WIZARD_CREATE_SUCCESS',
  PERIOD_WIZARD_CREATE: 'Schools.PERIOD_WIZARD_CREATE',
  PERIOD_WIZARD_CREATE_SUCCESS: 'Schools.PERIOD_WIZARD_CREATE_SUCCESS',
  UPDATE_SCHOOLS: 'Schools.UPDATE_SCHOOLS',
  UPDATE_SCHOOLS_SUCCESS: 'Schools.UPDATE_SCHOOLS_SUCCESS',
  UPDATE_SCHOOLS_FAIL: 'Schools.UPDATE_SCHOOLS_FAIL',
  SET_DAY_SCHEDULE: 'Schools.SET_DAY_SCHEDULE',
  SET_DAY_SCHEDULE_SUCCESS: 'Schools.SET_DAY_SCHEDULE_SUCCESS',
  SET_DAY_SCHEDULE_FAIL: 'Schools.SET_DAY_SCHEDULE_FAIL',
  DELETE_COURSE: 'Schools.DELETE_COURSE',
  DELETE_COURSE_SUCCESS: 'Schools.DELETE_COURSE_SUCCESS',
  DELETE_COURSE_FAIL: 'Schools.DELETE_COURSE_FAIL',
  DELETE_TEACHER: 'Schools.DELETE_TEACHER',
  DELETE_TEACHER_SUCCESS: 'Schools.DELETE_TEACHER_SUCCESS',
  DELETE_TEACHER_FAIL: 'Schools.DELETE_TEACHER_FAIL',
  SWITCH_FEATURE: 'Schools.SWITCH_FEATURE',
  SWITCH_FEATURE_SUCCESS: 'Schools.SWITCH_FEATURE_SUCCESS',
  SWITCH_FEATURE_FAIL: 'Schools.SWITCH_FEATURE_FAIL',
  REFRESH_ROTATION_SETTINGS: 'Schools.REFRESH_ROTATION_SETTINGS',
  REFRESH_ROTATION_SETTINGS_SUCCESS:
    'Schools.REFRESH_ROTATION_SETTINGS_SUCCESS',
  REFRESH_ROTATION_SETTINGS_FAIL: 'Schools.REFRESH_ROTATION_SETTINGS_FAIL',
  REFRESH_SCHEDULE_SETTINGS: 'Schools.REFRESH_SCHEDULE_SETTINGS',
};

const getSchools = (params) => ({
  type: ActionTypes.GET_SCHOOLS,
  params: params,
});

const getSchoolById = (schoolId) => ({
  type: ActionTypes.GET_SCHOOL_BY_ID,
  schoolId,
});

const getSchoolResource = (schoolId, school, resourceType, params = {}) => ({
  type: ActionTypes.GET_SCHOOL_RESOURCE,
  schoolId,
  school,
  resourceType,
  params,
});

const updateSchool = (schoolId, updateSchoolData) => ({
  type: ActionTypes.UPDATE_SCHOOLS,
  schoolId,
  updateSchoolData,
});

const switchFeature = (schoolId, feature, enable) => ({
  type: ActionTypes.SWITCH_FEATURE,
  schoolId,
  feature,
  enable,
});

const removeCourse = (schoolId, courseId) => ({
  type: ActionTypes.DELETE_COURSE,
  schoolId,
  courseId,
});

const removeTeacher = (schoolId, teacherId) => ({
  type: ActionTypes.DELETE_TEACHER,
  schoolId,
  teacherId,
});

const refreshScheduleSettings = (actionType, schedulingSettings) => ({
  type: ActionTypes.REFRESH_SCHEDULE_SETTINGS,
  actionType,
  schedulingSettings,
});

const refreshRotationSettings = (currentSchool) => ({
  type: ActionTypes.REFRESH_ROTATION_SETTINGS,
  currentSchool,
});

const actions = {
  getSchools,
  getSchoolById,
  getSchoolResource,
  updateSchool,
  removeCourse,
  removeTeacher,
  switchFeature,
  refreshRotationSettings,
  refreshScheduleSettings,
};

export { ActionTypes, actions };
