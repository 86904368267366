import { useRef } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const LunchWavesInput = ({ value, onChange }: Props) => {
  const handlers = useRef<M.NumberInputHandlers>();

  return (
    <Container>
      <M.ActionIcon
        size={36}
        variant="default"
        onClick={() => handlers.current?.decrement()}
      >
        –
      </M.ActionIcon>

      <Input
        hideControls
        value={value}
        onChange={(v) => onChange(v || 0)}
        handlersRef={handlers}
        max={10}
        min={1}
      />

      <M.ActionIcon
        size={36}
        variant="default"
        onClick={() => handlers.current?.increment()}
      >
        +
      </M.ActionIcon>
    </Container>
  );
};

const Container = styled(M.Flex)`
  gap: 5px;
`;

const Input = styled(M.NumberInput)`
  .mantine-NumberInput-input {
    width: 5rem;
    height: 34px;
    text-align: center;
  }
`;

export default LunchWavesInput;
