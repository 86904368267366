import React, { useState } from 'react';
import Select from 'react-select';
import { Container, Row, Dropdown, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
//  https://github.com/react-bootstrap-table/react-bootstrap-table2/pull/1506
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import SwitchField from '../Fields/SwitchField/SwitchField';
import SearchInput from '../SearchInput/SearchInput';

import './AdvancedTable.scss';

const AdvancedTable = ({
  keyField,
  data,
  columns,
  paginationOptions,
  loading,
  clickedFilterValue,
  setClickedFilterValue,
  rowClasses = () => '',
  onTableChange,
  suggestionsOptions,
  onSelect,
  itemName,
  remoteOptions,
  overlay,
  options: {
    shouldDisplayCount,
    counterComponent,
    hasToggle,
    toggleLabel,
    defaultToggleValue = true,
    onToggle,
    onFilterPick,
    hasFilterDropdown,
    filterPlaceholder,
    filterOptions,
    hasSearch,
    searchPlaceholder,
    hasSuggestions,
    suggestionsPlaceholder,
    onSuggestionPick,
    hasDropdown,
    dropdownOptions,
    dropdownSelectedOption = '',
    onCreateButtonClick,
    onFileUploadButtonClick,
    disableUpload, // TODO probably can get rid of this
    hasInlineEditing,
    rowUpdateHook,
    onRowUpdate,
    schoolId,
  } = {},
  noDataIndication,
}) => {
  const [dataSize, setDataSize] = useState(data.length);
  const [selectedValue, setSelectedValue] = useState(dropdownSelectedOption);

  const defaultPaginationOptions = {
    withFirstAndLast: false,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
  };

  const contentTable = ({ paginationTableProps }) => (
    <ToolkitProvider
      keyField="id"
      data={data || []}
      columns={columns}
      bootstrap4
      search
    >
      {(toolkitProps) => {
        const toolbar = (
          <Row className="advanced-table-head">
            {shouldDisplayCount && (
              <div className="advanced-table-head-title">
                {counterComponent()}
              </div>
            )}

            <div className="controls-row">
              {hasToggle && (
                <SwitchField
                  field={{
                    isShowLabel: true,
                    currentValue: defaultToggleValue,
                    name: toggleLabel || 'Only Active',
                  }}
                  updateParent={onToggle}
                />
              )}

              {hasFilterDropdown && (
                <Select
                  className="advanced-table-filter"
                  classNamePrefix="advanced-table-filter"
                  placeholder={filterPlaceholder || 'Select lite level...'}
                  options={filterOptions || []}
                  defaultValue={filterOptions ? filterOptions[0] : undefined}
                  onChange={onFilterPick}
                />
              )}

              {hasSearch && (
                <SearchInput
                  onSearch={toolkitProps.searchProps.onSearch}
                  searchText={
                    (!loading ? clickedFilterValue : '') ||
                    toolkitProps.searchProps.searchText
                  }
                  onClearClickedFilterValue={() => setClickedFilterValue?.('')}
                  placeholder={searchPlaceholder}
                  count={dataSize}
                />
              )}

              {hasSuggestions && (
                <Select
                  key={
                    suggestionsOptions &&
                    suggestionsOptions.length > 0 &&
                    suggestionsOptions[0].value
                  }
                  className="advanced-table-suggestions"
                  classNamePrefix="advanced-table-suggestions"
                  placeholder={suggestionsPlaceholder || 'Select...'}
                  options={suggestionsOptions || []}
                  onChange={onSuggestionPick}
                />
              )}

              {hasDropdown && (
                <Dropdown
                  bsPrefix="advanced-table-dropdown"
                  onSelect={(value) => {
                    onSelect(value);
                    setSelectedValue(value);
                  }}
                >
                  <Dropdown.Toggle bsPrefix="dropdown-toggle">
                    {selectedValue || 'All'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="advanced-table-dropdown-menu">
                    <Dropdown.Item
                      className="advanced-table-dropdown-item"
                      active={!selectedValue}
                    >
                      All
                    </Dropdown.Item>
                    {dropdownOptions.map((dropdownItem) => (
                      <Dropdown.Item
                        key={dropdownItem}
                        eventKey={dropdownItem}
                        className="advanced-table-dropdown-item"
                        active={selectedValue === dropdownItem}
                      >
                        {dropdownItem}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <div className="table-actions">
                {onCreateButtonClick && (
                  <Button
                    variant="danger"
                    href="#"
                    className="create-button"
                    onClick={onCreateButtonClick}
                  >
                    {`Create ${itemName}`}
                  </Button>
                )}

                {onFileUploadButtonClick && (
                  <Button
                    variant="secondary"
                    href="#"
                    className="upload-button"
                    onClick={onFileUploadButtonClick}
                    disabled={disableUpload}
                  >
                    File Upload
                  </Button>
                )}
              </div>
            </div>
          </Row>
        );

        return (
          <div className="advanced-table">
            {toolbar}

            <Row className="advanced-table-content">
              <BootstrapTable
                {...toolkitProps.baseProps}
                {...paginationTableProps}
                remote={remoteOptions}
                keyField={keyField}
                cellEdit={
                  hasInlineEditing &&
                  cellEditFactory({
                    mode: 'click',
                    blurToSave: false,
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      if (rowUpdateHook) {
                        rowUpdateHook(oldValue, newValue, row, column);
                      } else {
                        if (onRowUpdate) {
                          if (schoolId) {
                            onRowUpdate(schoolId, row.id, row, newValue);
                          } else {
                            onRowUpdate(row.id, row);
                          }
                        }
                      }
                    },
                  })
                }
                onTableChange={onTableChange}
                onDataSizeChange={({ dataSize: size }) => setDataSize(size)}
                noDataIndication={() => noDataIndication || <div>No Data</div>}
                rowClasses={(row) => `inner-table-row ${rowClasses(row)}`}
                pagination={paginationFactory({
                  ...defaultPaginationOptions,
                  ...paginationOptions,
                })}
                loading={loading}
                overlay={overlay}
              />
            </Row>
          </div>
        );
      }}
    </ToolkitProvider>
  );

  return (
    <Container fluid>
      <PaginationProvider
        pagination={paginationFactory(defaultPaginationOptions)}
      >
        {contentTable}
      </PaginationProvider>
    </Container>
  );
};

export default AdvancedTable;
