import {
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { type StoreApi, type UseBoundStore, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';

import { type WizardStore, createWizardStore } from './store';
import type { Step } from './step';

export type WizardProps<State> = {
  /**
   * full array of steps that will be used in wizard, won't react to changes after initial render
   */
  steps: Step<any, State>[];
  /**
   * function that returns an array of steps that should be visible, `false` and `undefined` values are ignored,
   * this prop is reactive
   */
  visibleSteps?: (
    state: Partial<State>,
  ) => (Step<any, State> | undefined | false)[];
  initialState?: Partial<State>;
};

const WizardContext = createContext<UseBoundStore<StoreApi<WizardStore<any>>>>(
  Object.create(null),
);

export const Wizard = <State,>(
  props: PropsWithChildren<WizardProps<State>>,
) => {
  const store = useRef(createWizardStore(props)).current;

  const [wizardSteps, currentStep, state, revalidateVisibleSteps] = useStore(
    store,
    (s) => [s.steps, s.currentStepIndex, s.state, s.revalidateVisibleSteps],
    shallow,
  );

  useEffect(() => {
    if (props.visibleSteps) {
      revalidateVisibleSteps(props);
    }
  }, [props.visibleSteps, revalidateVisibleSteps]);

  return (
    <WizardContext.Provider value={store}>
      {wizardSteps[currentStep].render(state)}
    </WizardContext.Provider>
  );
};

export const getUseWizard = <State,>() => {
  return <T,>(selector: (store: WizardStore<State>) => T) => {
    const store = useContext(WizardContext);

    if (!store) {
      throw new Error('WizardContext must be consumed within Wizard');
    }

    return useStore(store, selector, shallow);
  };
};
