import * as M from '@mantine/core';
import { useUploadedScheduleImages } from 'features/scheduleConfiguration/hooks/queries';
import { useSchoolId } from 'hooks/useSchoolId';

import type { Step } from 'features/scheduleConfiguration/types';

interface Props {
  step: Step;
}

const UploadImagesReview = ({ step }: Props) => {
  const schoolId = useSchoolId();
  const { data: images } = useUploadedScheduleImages(schoolId);

  if (step.type !== 'uploadImagesStep' || !images) {
    return <></>;
  }

  return (
    <div>
      <M.Title order={6}>Images:</M.Title>
      <M.Text>{images.length ?? 0} uploaded</M.Text>
    </div>
  );
};

export default UploadImagesReview;
