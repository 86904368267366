import { put, call, takeLatest } from 'redux-saga/effects';
import uploadsService from 'services/fileupload.service';
import { ActionTypes } from '../actions/fileupload';

function* uploadFiles() {
  try {
    const upload_response = yield call(uploadsService().uploadFiles);
    if (upload_response.status === 201) {
      yield put({
        type: ActionTypes.UPLOAD_FILES_SUCCESS,
        schools: upload_response.data,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPLOAD_FILES_FAIL, error });
  }
}

export default [takeLatest(ActionTypes.UPLOAD_FILES, uploadFiles)];
