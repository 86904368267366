import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import logo from 'assets/icons/saturn_gray_logo.svg';

type Props = {
  title: string;
  children?: React.ReactNode;
};

const PageHeader = ({ title, children }: Props) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.persist();

    const c = 0.01;

    setOffset((prev) => ({
      x: prev.x - e.movementX * c,
      y: prev.y - e.movementY * c * 2,
    }));
  };

  const parallax = (isReversed?: boolean): React.CSSProperties => ({
    transform: `translate(${offset.x * (isReversed ? -1 : 1)}px, ${
      offset.y * (isReversed ? -1 : 1)
    }px)`,
  });

  return (
    <Container
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setOffset({ x: 0, y: 0 })}
    >
      <M.Container>
        <M.Flex justify="space-between" align="center">
          <Logo src={logo} style={parallax(true)} />
          <Title style={parallax()}>{title}</Title>
          <M.Box style={parallax()}>{children}</M.Box>
        </M.Flex>
      </M.Container>
    </Container>
  );
};

const Container = styled(M.Container)`
  max-width: 100vw;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.xl};

  color: #fff;
  background: ${({ theme }) => theme.other.saturnGradient.primary};

  overflow: hidden;
  clip-path: inset(0);

  * {
    transition: all 0.2s;
  }
`;

const Logo = styled.img`
  width: 400px;
  position: absolute;
  top: 2.5%;
  left: 50%;

  filter: grayscale(1) opacity(0.25);
`;

const Title = styled(M.Title)`
  line-height: 1;
`;

export default PageHeader;
