import * as M from '@mantine/core';
import * as MM from '@mantine/modals';

interface Props {
  children: React.ReactNode;
}

const MantineProvider = ({ children }: Props) => {
  return (
    <M.MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: 'TT Norms',
        colors: {
          saturn: ['#157be7', '#6515e7'],
        },
        other: {
          saturnGradient: {
            primary:
              'linear-gradient(102.72deg, #157be7 -6.12%, #6515e7 76.82%)',
            secondary: 'linear-gradient(102.72deg, #f00 -6.12%, #f80 76.82%)',
          },
        },
      }}
    >
      <MM.ModalsProvider
        modalProps={{
          centered: true,
          zIndex: 1000, // Temporary v6 patch: https://github.com/mantinedev/mantine/issues/4082
        }}
        labels={{ confirm: 'Okay!', cancel: 'Close' }}
      >
        {children}
      </MM.ModalsProvider>
    </M.MantineProvider>
  );
};

export default MantineProvider;
