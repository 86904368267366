import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './ContentModerationGrid.scss';
import UserProfileReportsTable from './UserReportsTable/UserReportsTable';
import { Tab, Tabs } from 'react-bootstrap';
import QuickBan from 'components/shared/QuickBan/QuickBan';
import QuickContentDelete from 'components/shared/QuickContentDelete/QuickContentDelete';
import { isProd } from 'utils/utilities';

const ContentModerationGrid = () => {
  const { totalReports } = useSelector((state) => ({
    totalReports: state.reports.total_reports,
  }));
  const auth = useSelector((state) => state.auth);

  const renderTabCount = (title, count) => (
    <span>
      {title} {count > 0 && <span className="tab-count">{count}</span>}
    </span>
  );

  if (isProd && !auth.scopes.has('moderator')) {
    return <Redirect to="/schools" />;
  }

  return (
    <div className="moderation-grid-container">
      <div className="moderation-quick-actions">
        <QuickBan />
        <QuickContentDelete />
      </div>

      <Tabs className="sdp-container-tabs" defaultActiveKey="user-reports">
        <Tab
          eventKey="user-reports"
          title={renderTabCount('User Reports', totalReports)}
        >
          <div className="network-grid-table-container">
            <div>
              <UserProfileReportsTable />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContentModerationGrid;
