import { useQuery } from '@tanstack/react-query';

import { get } from 'hooks/utils';

interface UserSearchResult {
  id: number;
  schoolId: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email?: string;
}

export const useSearchUsers = (query: string) => {
  return useQuery({
    queryKey: ['userSearch', query],
    queryFn: async () => {
      const sanitizedQuery = query.replace('+1', '');

      const params = new URLSearchParams();
      params.append('query', sanitizedQuery);

      if (sanitizedQuery.length <= 3) return [];

      return await get<UserSearchResult[]>(
        `v2/admin/users/search?${decodeURIComponent(params.toString())}`,
        false,
      );
    },
  });
};

interface SchoolSearchResult {
  id: string;
  name: string;
  state: string;
  status: 'live' | 'waitlist' | 'unlaunched';
}

export const useSearchSchools = (query: string) => {
  return useQuery({
    queryKey: ['schoolSearch', query],
    queryFn: async () => {
      const requests = ['school_title', 'school_name'].map(
        async (filterKey) => {
          const params = new URLSearchParams();
          params.append('filter_by', `${filterKey}:ilike:${query}`);
          params.append('include_all', 'true');

          if (!isNaN(+query) || query.length <= 3) return [];

          const response = await get<{
            mapFeatures: { properties: SchoolSearchResult }[];
          }>(
            `v2/map/schools/geojson?${decodeURIComponent(params.toString())}`,
            false,
          );

          return response.mapFeatures.map((feature) => feature.properties);
        },
      );

      const [schoolsByTitle, schoolsByName] = await Promise.all(requests);

      return schoolsByTitle.concat(
        schoolsByName.filter((n) => !schoolsByTitle.find((t) => t.id === n.id)),
      );
    },
  });
};
