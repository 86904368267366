import React from 'react';
import StepModalContainer from 'components/shared/Modal/StepModalContainer/StepModalContainer';
import { DeleteStep } from './Steps/DeleteSteps';

const DeleteModal = ({
  hide,
  show,
  deletingObjectType,
  deletedObjectName,
  onConfirm,
}) => {
  const modalData = {
    steps: [DeleteStep(deletingObjectType, deletedObjectName)],
    isConfirmationDialog: true,
    onSubmit: onConfirm,
    getSuccessText: () => `Deleted ${deletingObjectType} ${deletedObjectName}`,
    getErrorText: () =>
      `An error occurred when deleting the ${deletingObjectType}`,
  };

  return <StepModalContainer hide={hide} show={show} modalData={modalData} />;
};

export default DeleteModal;
