import apiClient from 'utils/apiClient';

const baseUrl = 'v2/admin/upload';

const uploadFiles = (file_type, override = false, requestBody) =>
  apiClient.post(`${baseUrl}/${file_type}?override=${override}`, requestBody, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

const uploadLogo = (requestBody) =>
  apiClient.post(`v2/media/school_logo`, requestBody, {
    headers: { 'content-type': 'multipart/form-data' },
  });

const uploadsService = () => ({ uploadFiles, uploadLogo });

export default uploadsService;
