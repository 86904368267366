import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { ScheduleType, Entries } from 'types';

import { NAMING_FORMAT, SCHEDULE_TYPE_NAMING_FORMATS } from '../constants';
import type { NamingFormat } from '../types';

interface Props {
  isPeriod: boolean;
  scheduleType: ScheduleType | undefined;
  setIsAddingOther: React.Dispatch<React.SetStateAction<boolean>>;
  createTypes: (names: string[]) => void;
}

const DayAndPeriodSelect = ({
  isPeriod,
  scheduleType,
  setIsAddingOther,
  createTypes,
}: Props) => {
  const [namingFormat, setNamingFormat] = useState<NamingFormat>('alphabetic');
  const [namingFormats, setNamingFormats] = useState<
    { value: NamingFormat; label: string }[]
  >([]);
  const [count, setCount] = useState(NAMING_FORMAT['alphabetic'].options[0]);

  useEffect(() => {
    if (!scheduleType) {
      return;
    }

    setNamingFormats(
      (Object.entries(NAMING_FORMAT) as Entries<typeof NAMING_FORMAT>)
        .filter(([k]) =>
          isPeriod
            ? k === 'alphabetic' || k === 'numeric'
            : SCHEDULE_TYPE_NAMING_FORMATS[scheduleType].includes(k),
        )
        .map(([k, v]) => ({
          value: k,
          label: v.label,
        })),
    );
  }, [scheduleType]);

  useEffect(() => {
    if (namingFormats.length) {
      setNamingFormat(isPeriod ? 'numeric' : namingFormats[0].value);
    }
  }, [namingFormats]);

  useEffect(() => {
    const newNamingFormat = NAMING_FORMAT[namingFormat];
    const index = newNamingFormat.isDynamic ? 0 : -1;

    setCount(newNamingFormat.options.at(index)!);
    setIsAddingOther(namingFormat === 'other');
  }, [namingFormat]);

  const namingFormatOptions = NAMING_FORMAT[namingFormat].options;

  const typesToCreate = namingFormatOptions.slice(
    0,
    (namingFormatOptions as ReadonlyArray<string>).indexOf(count) + 1,
  );

  return (
    <SelectContainer>
      {namingFormats.length > 1 && (
        <M.Select
          label="Select a naming format:"
          value={namingFormat}
          onChange={(value) => value && setNamingFormat(value as NamingFormat)}
          data={namingFormats}
        />
      )}

      {NAMING_FORMAT[namingFormat].isDynamic && (
        <M.Select
          label="Select how many to create:"
          value={count}
          onChange={(value) => value && setCount(value)}
          data={namingFormatOptions}
          maxDropdownHeight={250}
        />
      )}

      {namingFormat !== 'other' ? (
        <M.Text>
          Types to create:
          <br />
          {typesToCreate.join(', ')}
        </M.Text>
      ) : (
        <M.Text>Manually add types for &quot;other&quot; below.</M.Text>
      )}

      {namingFormat !== 'other' && (
        <M.Button onClick={() => createTypes(typesToCreate)}>Create</M.Button>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
  padding: ${(p) => p.theme.spacing.md};
  background-color: ${(p) => p.theme.colors.gray[1]};
  border-radius: 8px;
`;

export default DayAndPeriodSelect;
