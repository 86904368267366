import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { useListState } from '@mantine/hooks';

import { useSchoolId } from 'hooks/useSchoolId';
import { useSchool } from 'hooks/useSchool';
import type { CoreSchedule, Schedule, SpecialSchedule } from 'types';

import ScheduleListTable from '../components/scheduleListTable/ScheduleListTable';
import { scheduleSubRoutes } from '../constants/subroutes';
import { useScheduleReorder } from '../hooks/useScheduleReorder';
import { useDeleteSchedule } from '../mutations';
import { TabErrorAlert } from 'features/school/components/TabErrorAlert';

const ScheduleListPage = () => {
  const { data: schedules, error } = useSchool((school) => school.schedules);

  const { pathname } = useLocation();
  const schoolId = useSchoolId();

  const coreSchedules = useMemo(
    () =>
      schedules
        ?.filter((s): s is CoreSchedule => s.variant === 'core')
        .sort((a, b) => (a.order || 0) - (b.order || 0)) || [],
    [schedules],
  );

  const [isReorderable, setIsReorderable] = useState(false);

  const [localScheduleOrder, orderHandlers] = useListState<Schedule>([]);

  const resetLocalOrder = useCallback(() => {
    orderHandlers.setState(coreSchedules.filter((s) => s.isInAcf));
  }, [coreSchedules, orderHandlers]);

  // fill local order state with core schedules if empty
  useEffect(() => {
    if (
      coreSchedules.filter((s) => s.isInAcf).length &&
      !localScheduleOrder.length
    ) {
      resetLocalOrder();
    }
  }, [coreSchedules, localScheduleOrder, resetLocalOrder]);

  const hasOrderChanged = useMemo(() => {
    if (!coreSchedules.length || !localScheduleOrder.length) return false;

    for (const [index, schedule] of localScheduleOrder.entries()) {
      if (schedule.id !== coreSchedules[index]?.id) {
        return true;
      }
    }

    return false;
  }, [coreSchedules, localScheduleOrder]);

  const {
    mutate: handleDelete,
    isLoading: isDeleting,
    variables: scheduleIdToDelete,
  } = useDeleteSchedule(schoolId, resetLocalOrder);

  const { saveOrder, isLoading: isReordering } = useScheduleReorder({
    onSettled: () => {
      setIsReorderable(false);
    },
  });

  const isLoading = isDeleting || isReordering;

  const scheduleIdBeingDeleted = isDeleting ? scheduleIdToDelete : undefined;

  if (error) {
    return <TabErrorAlert error={error} />;
  }

  if (!schedules) {
    return <M.LoadingOverlay visible />;
  }

  return (
    <M.Container>
      <ScheduleActionsContainer>
        <M.Flex gap="sm">
          <Link to={`${pathname}/${scheduleSubRoutes.create}`}>
            <M.Button>Create schedule</M.Button>
          </Link>
          <M.Button
            onClick={() => {
              if (isReorderable && hasOrderChanged) {
                saveOrder(localScheduleOrder.map((o) => o.id));
              } else if (isReorderable && !hasOrderChanged) {
                setIsReorderable(false);
              } else {
                setIsReorderable(true);
              }
            }}
            loading={isLoading}
          >
            {!isReorderable ? 'Reorder' : 'Save order'}
          </M.Button>
          {isReorderable && (
            <M.Button
              color="yellow"
              onClick={() => {
                resetLocalOrder();
                setIsReorderable(false);
              }}
              disabled={isLoading}
            >
              Cancel
            </M.Button>
          )}
        </M.Flex>
      </ScheduleActionsContainer>

      <ScheduleListTable
        schedules={
          hasOrderChanged && isReorderable
            ? [
                ...localScheduleOrder,
                ...coreSchedules.filter((s) => !s.isInAcf),
              ]
            : coreSchedules
        }
        variant="core"
        onDelete={handleDelete}
        loadingScheduleId={scheduleIdBeingDeleted}
        isReorderable={isReorderable}
        orderStateHandlers={orderHandlers}
      />
      <ScheduleListTable
        schedules={schedules.filter((s) => s.variant === 'modified')}
        variant="modified"
        onDelete={handleDelete}
        loadingScheduleId={scheduleIdBeingDeleted}
      />
      <ScheduleListTable
        schedules={schedules
          .filter((s): s is SpecialSchedule => s.variant === 'special')
          .sort((a, b) => b.date.getTime() - a.date.getTime())}
        variant="special"
        onDelete={handleDelete}
        loadingScheduleId={scheduleIdBeingDeleted}
      />
    </M.Container>
  );
};

const ScheduleActionsContainer = styled(M.Flex)`
  padding: 1rem 0;
  justify-content: flex-end;
  align-items: center;
`;

export default ScheduleListPage;
