import apiClient from 'utils/apiClient';

const baseUrl = 'v2/reports';

const getContentReports = (params = {}) => apiClient.get(baseUrl, params);

const solveReport = (report_id) =>
  apiClient.post(`${baseUrl}/${report_id}/solve`);

const reOpenReport = (report_id) =>
  apiClient.delete(`${baseUrl}/${report_id}/solve`);

const deleteChatMessage = (message_id) =>
  apiClient.delete(`v2/admin/delete_chat_message/${message_id}`);

const deleteChatMedia = (mediaId) =>
  apiClient.delete(`v2/admin/media/${mediaId}`);

const deleteChat = (chat_id) =>
  apiClient.delete(`v2/admin/delete_chat/${chat_id}`);

const moderationService = () => ({
  getContentReports,
  solveReport,
  reOpenReport,
  deleteChatMessage,
  deleteChat,
  deleteChatMedia,
});

export default moderationService;
