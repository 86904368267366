import React, { Component } from 'react';
import './NumericField.scss';
import FieldError from '../FieldError/FieldError';

export default class NumericField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberValue: props.field.currentValue,
    };
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.updateValue(this.state.numberValue);
  }

  validate(value) {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  }

  updateValue(newValue) {
    this.props.field.currentValue = newValue;
    this.props.field.blurred = true;
    this.validate(newValue);
    this.props.updateParent(newValue, this.props.field);
    this.setState({
      numberValue: newValue,
    });
  }

  increment = () => {
    const currentValue =
      this.props.field.currentValue !== '' ? this.props.field.currentValue : 0;
    const newValue = currentValue + 1;
    if (newValue > this.props.field.max) {
      return;
    }
    this.updateValue(newValue);
  };

  decrement = () => {
    const currentValue =
      this.props.field.currentValue !== '' ? this.props.field.currentValue : 0;
    const newValue = currentValue - 1;
    if (newValue < this.props.field.min) {
      return;
    }
    this.updateValue(newValue);
  };

  nullify = () => {
    const newValue = '';
    this.updateValue(newValue);
  };

  renderLabel = () => {
    return <div className="numeric-field-label">{this.props.field.name}</div>;
  };

  render() {
    const labelField = this.props.field.isShowLabel ? this.renderLabel() : null;

    return (
      <>
        <div className="numeric-field">
          {labelField}
          <div className="numeric-field-content def-number-input number-input">
            <div
              className="numeric-field-content-button numeric-field-content-minus minus"
              onClick={() => this.decrement()}
            />
            <input
              className="numeric-field-content-quantity quantity"
              type="number"
              value={this.props.field.currentValue}
              onChange={(event) => this.updateValue(event.target.value)}
            />
            {this.props.field.nullable && (
              <div
                className="numeric-field-content-button numeric-field-content-cross cross"
                onClick={() => this.nullify()}
              />
            )}
            <div
              className="numeric-field-content-button numeric-field-content-plus plus"
              onClick={() => this.increment()}
            />
          </div>
        </div>
        <FieldError field={this.props.field} />
      </>
    );
  }
}
