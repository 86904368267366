import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const districtFormatter = (cell, row) => {
  return cell ? (
    <div>
      <div className="school-id">
        <OverlayTrigger overlay={<Tooltip>{cell}</Tooltip>}>
          <span>
            {row.district_school_count < 2 ? (
              <>
                <FontAwesomeIcon icon={faDotCircle} color="green" />
                &nbsp;
              </>
            ) : (
              ''
            )}
            {cell}
          </span>
        </OverlayTrigger>
      </div>
    </div>
  ) : row.county_code ? (
    <span>{row.county_code}</span>
  ) : (
    <span>--</span>
  );
};

export default districtFormatter;
