import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import './LogoUploadField.scss';
import FieldError from '../FieldError/FieldError';
import ImageUploading from 'react-images-uploading';
import uploadsService from 'services/fileupload.service';

export default class LogoUploadField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  updateValue = (value) => {
    this.props.field.currentValue = value;
    this.props.field.valid = true;
    this.props.updateParent(value, this.props.field);
  };

  render() {
    return (
      <Form.Group>
        {this.props.field.isShowLabel && (
          <Form.Label>{this.props.field.name}</Form.Label>
        )}
        <ImageUploading
          value={this.state.images}
          onChange={(images) => {
            this.setState({ images });

            const formData = new FormData();
            formData.append('file', images[0].file);

            uploadsService()
              .uploadLogo(formData)
              .then((response) => {
                if (response.status === 200) {
                  const { id } = response.data;
                  this.updateValue(id);
                }
              });
          }}
          dataURLKey="data_url"
          acceptType={['png']}
        >
          {({ imageList, onImageUpload, isDragging, dragProps }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <div className="preview">
                {imageList.length > 0 ? (
                  imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" height="45" />
                    </div>
                  ))
                ) : this.props.field.currentLogo ? (
                  <img src={this.props.field.currentLogo} alt="" height="45" />
                ) : null}
              </div>
              <div
                className="drop-area"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                Click or Drop here
              </div>
            </div>
          )}
        </ImageUploading>
        <FieldError field={this.props.field} />
      </Form.Group>
    );
  }
}
