export const COLUMNS = {
  school_id: 'School',
  display_name: 'Display name',
  target_date: 'Target date',
} as const;

export const FILTER_OPS = {
  eq: '=',
  ne: '≠',
  lt: '<',
  gt: '>',
  ge: '≥',
  le: '≤',
} as const;
