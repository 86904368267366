import { useQuery } from '@tanstack/react-query';

import { get } from 'hooks/utils';

import type { SchoolResponse, ScheduleType, SchoolInfo } from 'types';

export const fetchSchoolData = async (schoolId: string) => {
  try {
    const schoolResponse = await get<SchoolResponse>(`v2/schools/${schoolId}`);

    const school: SchoolInfo = {
      schoolName: schoolResponse.schoolName,
      schoolTitle: schoolResponse.schoolTitle,
      timezone: schoolResponse.timezone!,

      primaryColor: schoolResponse.primaryColor,
      logoUrl: schoolResponse.logo?.resourceUrl,

      scheduleType: schoolResponse.scheduleType as ScheduleType | undefined,
    };

    return school;
  } catch (e) {
    if (e instanceof Error) {
      console.error('useSchool error:', e.message);
      throw e;
    }
    throw new Error("Couldn't fetch school data");
  }
};

export const useSchoolInfo = (schoolId: string) => {
  return useQuery({
    queryKey: ['schoolInfo', schoolId],
    queryFn: () => fetchSchoolData(schoolId),
  });
};
