import { ActionTypes } from '../actions/reports';

const initialState = {
  isLoadingProfileReports: false,
  profileReportsLoaded: false,
  profileReportsError: false,
  profile_reports: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_REPORTS:
      return {
        ...state,
        isLoadingReports: true,
        reportsLoaded: false,
        reportsError: false,
      };
    case ActionTypes.GET_USER_REPORTS_SUCCESS:
      return {
        ...state,
        isLoadingReports: false,
        user_reports: action.userReports,
        total_reports: action.total,
        reportsLoaded: true,
        reportsError: false,
      };
    case ActionTypes.GET_USER_REPORTS_FAIL:
      return {
        ...state,
        isLoadingReports: false,
        reportsLoaded: false,
        reportsError: action.error,
      };

    default:
      return state;
  }
};
