import styled from '@emotion/styled';
import * as M from '@mantine/core';

interface Props {
  dayTypes: string[];
  rotationPattern: string[];
  setRotationPattern: (rotationPattern: string[]) => void;
}

const RotationPatternSetter = ({
  dayTypes,
  rotationPattern,
  setRotationPattern,
}: Props) => {
  const add = (dayType: string) =>
    setRotationPattern([...rotationPattern, dayType]);

  const remove = (index: number) =>
    setRotationPattern(rotationPattern.filter((_, i) => i !== index));

  const autofill = () => setRotationPattern(dayTypes);

  return (
    <Container>
      <M.Title order={3}>Rotation Pattern</M.Title>

      <Row>
        {!rotationPattern.length ? (
          <M.Text>Not assigned</M.Text>
        ) : (
          rotationPattern.map((dayType, i) => (
            <RotationPatternItem key={i} onClick={() => remove(i)}>
              <M.Text>{dayType}</M.Text>
              <M.Divider orientation="vertical" />
              <X>❌</X>
            </RotationPatternItem>
          ))
        )}
      </Row>

      <Row>
        {dayTypes.map((dayType) => (
          <M.Button key={dayType} onClick={() => add(dayType)}>
            {dayType}
          </M.Button>
        ))}
        <M.Divider orientation="vertical" />
        <M.Button onClick={autofill}>Autofill</M.Button>
      </Row>
    </Container>
  );
};

const Container = styled(M.Flex)`
  padding: ${(p) => p.theme.spacing.md} 0;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Row = styled(M.Flex)`
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing.xs};
`;

const RotationPatternItem = styled(M.Flex)`
  padding: 0.25rem ${(p) => p.theme.spacing.xs};
  align-items: center;
  gap: ${(p) => p.theme.spacing.xs};

  border-radius: ${(p) => p.theme.radius.sm};
  background-color: ${(p) => p.theme.colors.gray[2]};

  &:hover {
    cursor: pointer;
  }
`;

const X = styled(M.Text)`
  font-size: 0.5rem;
  filter: grayscale(1);
`;

export default RotationPatternSetter;
