import { useSchoolId } from 'hooks/useSchoolId';
import { useReorderSchedules } from '../mutations';
import * as MN from '@mantine/notifications';

export const useScheduleReorder = ({
  onSettled,
}: {
  onSettled?: () => void;
} = {}) => {
  const schoolId = useSchoolId();

  const { isLoading, mutate } = useReorderSchedules(schoolId, {
    onSuccess: () => {
      MN.showNotification({
        color: 'green',
        message: 'Order updated successfully',
      });
    },
    onError: () => {
      MN.showNotification({
        color: 'red',
        message: 'Error updating order',
      });
    },
    onSettled,
  });

  return { isLoading, saveOrder: mutate };
};
