import { useSchool } from 'hooks/useSchool';
import { useEffect, useState } from 'react';
import { PeriodsBuilderProps } from './PeriodsBuilder';

export const useCustomPeriodNames = ({
  periods,
}: Pick<PeriodsBuilderProps, 'periods'>) => {
  const { data: school } = useSchool();

  const [customPeriodNames, setCustomPeriodNames] = useState<string[]>([]);

  // update customPeriodTypes with missing custom period types coming from periods
  useEffect(() => {
    if (!school) return;

    const customPeriods = periods.filter(
      (p) => !school.periodTypes.find((dpt) => dpt.name === p.name),
    );

    const diff = customPeriods
      .filter((cp) => !customPeriodNames.some((name) => name === cp.name))
      .map((p) => p.name);

    if (diff.length) {
      setCustomPeriodNames((prev) => [...prev, ...diff]);
    }
  }, [school, periods, customPeriodNames]);

  return {
    customPeriodNames,
    setCustomPeriodNames,
  };
};
