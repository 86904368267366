import React from 'react';

import StepModalContainer from 'components/shared/Modal/StepModalContainer/StepModalContainer';
import { CreateCourseStep } from './Steps/CreateCourseSteps';
import coursesService from 'services/courses.service';

const CreateCourseModal = ({ hide, show, currentSchoolPromise, schoolId }) => {
  const modalData = {
    name: 'Create New Course',
    steps: [CreateCourseStep()],
    dependencies: [
      {
        name: 'currentSchool',
        promise: currentSchoolPromise,
      },
    ],
    onSubmit: (form) =>
      coursesService().createCourse({
        name: form.courseName,
        school: schoolId,
      }),
    getSuccessText: (response) => `Created course: ${response.data.name}`,
    getErrorText: () => `An error occurred when creating the course`,
  };

  return <StepModalContainer hide={hide} show={show} modalData={modalData} />;
};

export default CreateCourseModal;
