import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { LunchSettings as LunchSettingsType } from 'types';

import LunchWavesInput from './LunchWavesInput';

import {
  useCreateLunchClassMutation,
  useDeleteLunchClassMutation,
  useUpdateNumWavesMutation,
  useUpdateIsAlphabeticMutation,
} from '../hooks/lunchHooks';
import { LUNCH_TYPES } from '../constants';
import type { LunchType } from '../types';

interface Props {
  schoolId: string;
  lunchClassId: number | undefined;
  lunchSettings: LunchSettingsType;
  // We have to derive lunch type since it is not a field on the BE yet, and
  // unit lunch is derived based off 'Lunch' being a period on any of the
  // schedules, and schedules potentially might not be built while looking at
  // settings, so we need to locally track unit lunch selection. Ugly, I know.
  isUnitLunchLocallySelected: boolean;
  setIsUnitLunchLocallySelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const LunchSettings = ({
  schoolId,
  lunchClassId,
  lunchSettings,
  isUnitLunchLocallySelected,
  setIsUnitLunchLocallySelected,
}: Props) => {
  const createLunchClass = useCreateLunchClassMutation(schoolId);
  const deleteLunchClass = useDeleteLunchClassMutation(schoolId, lunchClassId);
  const updateNumWaves = useUpdateNumWavesMutation(schoolId);
  const updateIsAlphabetic = useUpdateIsAlphabeticMutation(schoolId);

  const isLoading =
    createLunchClass.isLoading ||
    deleteLunchClass.isLoading ||
    updateNumWaves.isLoading ||
    updateIsAlphabetic.isLoading;

  const handleLunchTypeChange = (value: LunchType) => {
    switch (value) {
      case 'unit':
        if (lunchClassId) {
          deleteLunchClass.mutate();
        }
        if (lunchSettings.type === 'waves') {
          updateNumWaves.mutate(0);
        }
        setIsUnitLunchLocallySelected(true);
        break;
      case 'class':
        if (lunchSettings.type === 'waves') {
          updateNumWaves.mutate(0);
        }
        createLunchClass.mutate();
        setIsUnitLunchLocallySelected(false);
        break;
      case 'waves':
        if (lunchClassId) {
          deleteLunchClass.mutate();
        }
        updateNumWaves.mutate(1);
        updateIsAlphabetic.mutate(false);
        setIsUnitLunchLocallySelected(false);
        break;
    }
  };

  return (
    <Container>
      <M.Title order={3}>Lunch Settings</M.Title>

      <M.Radio.Group
        label="Pick a lunch type"
        value={
          lunchSettings.type ||
          (isUnitLunchLocallySelected ? 'unit' : undefined)
        }
        onChange={(value) => handleLunchTypeChange(value as LunchType)}
      >
        {Object.entries(LUNCH_TYPES).map(([value, label]) => (
          <Radio key={value} value={value} label={label} />
        ))}
      </M.Radio.Group>

      {lunchSettings.type === 'waves' && (
        <LunchWavesContainer>
          <M.Text align="center">
            How many lunch waves does this school have?
          </M.Text>
          <LunchWavesInput
            value={lunchSettings.slots.length}
            onChange={updateNumWaves.mutate}
          />
          <Switch
            checked={lunchSettings.isAlphabetic}
            onChange={(e) => updateIsAlphabetic.mutate(e.target.checked)}
            label="Alphabetic lunch waves"
          />
        </LunchWavesContainer>
      )}

      <M.LoadingOverlay visible={isLoading} />
    </Container>
  );
};

const Container = styled(M.Flex)`
  position: relative;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const LunchWavesContainer = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.sm};
  flex-direction: column;
  align-items: center;
`;

const Switch = styled(M.Switch)`
  margin-top: ${(p) => p.theme.spacing.sm};

  .mantine-Switch-track,
  .mantine-Switch-label {
    &:hover {
      cursor: pointer;
    }
  }
`;

const Radio = styled(M.Radio)`
  .mantine-Radio-radio:hover {
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.blue[2]};
  }
`;

export default LunchSettings;
