import { PropsWithChildren } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SchoolV1 } from 'types';

import { SchoolTabPath } from '../schoolTabsConfig';
import { TabLoader } from './TabLoader';

type Props = {
  school: SchoolV1 | null;
  isWizardVisible: boolean;
  activeTabKey?: SchoolTabPath;
};

export const SchoolTabsGuard = ({
  school,
  isWizardVisible,
  activeTabKey,
  children,
}: PropsWithChildren<Props>) => {
  const match = useRouteMatch<{ schoolId: string }>();
  const router = useHistory();

  if (!school) {
    return <TabLoader />;
  }

  // coming to root /school/:schoolId
  if (match.isExact) {
    if (isWizardVisible) {
      router.replace({ pathname: `${match.url}/settings` });
    } else {
      router.replace({ pathname: `${match.url}/calendar` });
    }
    return null;
  }

  const shouldRedirectToSettings =
    isWizardVisible &&
    activeTabKey &&
    (['schedules'] as SchoolTabPath[]).includes(activeTabKey);

  if (shouldRedirectToSettings) {
    router.replace({ pathname: `${match.url}/settings` });
    return null;
  }

  return <>{children}</>;
};
