import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { modals } from '@mantine/modals';
import type { UseListStateHandlers } from '@mantine/hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DraftSchedulesModal from 'features/draftSchedules/components/DraftSchedulesModal';
import type { Schedule } from 'types';

import { ScheduleRow } from './ScheduleRow';

type Props = {
  schedules: Schedule[];
  variant: Schedule['variant'];
  onDelete: (scheduleId: string) => void;
  loadingScheduleId?: string;
} & (
  | { isReorderable?: never; orderStateHandlers?: never }
  | {
      isReorderable: boolean;
      orderStateHandlers: UseListStateHandlers<Schedule>;
    }
);

const ScheduleListTable = ({
  schedules,
  variant,
  onDelete,
  loadingScheduleId,
  isReorderable,
  orderStateHandlers,
}: Props) => {
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();

  const handleDelete = (schedule: Schedule) =>
    modals.openConfirmModal({
      title: `Are you sure you want to delete schedule ${schedule.displayName}?`,
      onConfirm: () => onDelete(schedule.id),
      labels: {
        confirm: 'Delete',
        cancel: 'Cancel',
      },
      confirmProps: {
        color: 'red',
      },
      withCloseButton: false,
    });

  const draggableItems = schedules
    .filter((schedule) => schedule.variant === 'core' && schedule.isInAcf)
    .map((schedule, i) => (
      <Draggable
        key={schedule.id}
        index={i}
        draggableId={schedule.id}
        isDragDisabled={!isReorderable}
      >
        {(provided, snapshot) => (
          <ScheduleRow
            {...{
              key: schedule.id,
              schedule,
              provided,
              snapshot,
              isReorderable,
              loadingScheduleId,
              setSelectedSchedule,
              handleDelete,
            }}
          />
        )}
      </Draggable>
    ));

  const staticItems = schedules
    .filter((schedule) => schedule.variant !== 'core' || !schedule.isInAcf)
    .map((schedule) => (
      <ScheduleRow
        key={schedule.id}
        schedule={schedule}
        isReorderable={false}
        loadingScheduleId={loadingScheduleId}
        setSelectedSchedule={setSelectedSchedule}
        handleDelete={handleDelete}
      />
    ));

  return (
    <M.Box mb="lg">
      <M.Title order={3} sx={{ textTransform: 'capitalize' }}>
        {variant} schedules
      </M.Title>
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          isReorderable &&
          orderStateHandlers.reorder({
            from: source.index,
            to: destination?.index || 0,
          })
        }
      >
        <Droppable
          droppableId={`schedules-${variant}-list`}
          direction="vertical"
        >
          {(provided) => (
            <ListItems {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems}
              {provided.placeholder}
            </ListItems>
          )}
        </Droppable>
      </DragDropContext>

      {!!draggableItems.length && !!staticItems.length && (
        <M.Divider variant="dashed" pb="lg" />
      )}

      {staticItems.length
        ? staticItems
        : (variant !== 'core' || !draggableItems.length) && (
            <M.Text>No schedules</M.Text>
          )}

      {selectedSchedule && (
        <DraftSchedulesModal
          selectedSchedule={selectedSchedule}
          onClose={() => setSelectedSchedule(undefined)}
        />
      )}
    </M.Box>
  );
};

const ListItems = styled(M.Flex)`
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.xs} 0;
`;

export default ScheduleListTable;
