import React from 'react';

import { renderConfirm } from 'components/modals/ConfirmationModal/GenericConfirmModal';
import { extractCurrentCalData } from 'utils/utilities';
import { schedulesActions } from 'store/actions/actions';

import { ScheduleType } from '../ScheduleSettingsManager/constants';

export const checkCalendarConf = (school) => {
  return (
    school.day_types?.length > 0 &&
    school.rotation_pattern?.length > 0 &&
    !!school.schedule_type
  );
};

export const handleScheduleChange = (
  school,
  dayInfo,
  day,
  onCancel,
  onConfirm,
) => {
  if (!checkCalendarConf(school)) {
    renderConfirm({
      title: "Can't regenerate calendar data",
      body: (
        <>
          <p>
            You can&apos;t regenerate calendar data until you configure these
            options
          </p>
          <ul>
            <li>
              Schedule type and day types in the <b>Schedules</b> tab
            </li>
            <li>
              A rotation pattern in the <b>Rotation Logic</b> tab
            </li>
          </ul>
          <p>Only the selected day will be updated.</p>
        </>
      ),
      cancelText: 'Cancel',
      confirmText: 'OK',
      onConfirm: onCancel,
      onCancel: null,
    });
  } else {
    if (
      ![
        ScheduleType.SAME_EVERY_DAY,
        ScheduleType.SAME_EVERY_DAY_PLUS,
        ScheduleType.A_B_PLUS,
        ScheduleType.MONDAY_THROUGH_FRIDAY,
      ].includes(school.schedule_type) &&
      dayInfo
    ) {
      renderConfirm({
        title: 'Regenerate rotation?',
        body: (
          <span>
            Do you want to update the calendar rotation for the rest of the
            year?
          </span>
        ),
        cancelText: 'No, just change schedule',
        confirmText: 'Yes, regenerate',
        onCancel,
        onConfirm,
      });
    } else {
      onCancel();
    }
  }
};

export const regenerateRotationData = (
  onRegenerate,
  school,
  scheduleCode,
  startDate,
  buildFromStart = false,
) => {
  const rotationData = extractCurrentCalData(
    school?.calendar?.mapping || [],
    scheduleCode,
    startDate,
    school.rotation_pattern,
    school.schedule_type,
    buildFromStart,
  );
  onRegenerate(rotationData, buildFromStart);
};

export const setDaySchedule = (
  school,
  dispatch,
  day,
  scheduleId,
  scheduleName,
) => {
  dispatch(
    schedulesActions.setDaySchedule(
      school.school_name,
      day,
      scheduleId,
      scheduleName,
    ),
  );
  return day;
};
