import * as M from '@mantine/core';
import styled from '@emotion/styled';
import { useWizard } from '../stepUtils';

type Props = {
  /**
   * For adding custom validation logic to the step nav
   * @default true
   */
  isValid?: boolean;
  /**
   * For overriding the default next button behavior (running form submit action for example)
   */
  onNext?: () => void;

  isNextBtnLoading?: boolean;
  canSkip?: boolean;
};

export const StepNavigation = ({
  isValid = true,
  onNext,
  isNextBtnLoading,
  canSkip,
}: Props) => {
  const { canGoPrev, next, prev, stepsCount, getCurrentStepNo } = useWizard(
    (store) => ({
      next: store.next,
      prev: store.prev,
      canGoPrev: store.canGoPrev,
      stepsCount: store.stepsCount,
      getCurrentStepNo: store.getCurrentStepNo,
    }),
  );

  const isLastStep = getCurrentStepNo() === stepsCount;

  return (
    <Container>
      <M.Button onClick={prev} disabled={!canGoPrev()}>
        Back
      </M.Button>
      <M.Flex gap="md">
        {canSkip && <M.Button onClick={next}>Skip</M.Button>}
        <M.Button
          onClick={onNext ?? next}
          disabled={!isValid}
          loading={!!isNextBtnLoading}
        >
          {isLastStep ? 'Submit' : 'Next'}
        </M.Button>
      </M.Flex>
    </Container>
  );
};

const Container = styled(M.Flex)`
  padding-top: 2.5rem;
  justify-content: space-between;
`;
