import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './SelectField.scss';
import FieldError from '../FieldError/FieldError';

export default class SelectField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.field.currentValue,
    };

    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.initialize(this.state.selectedValue);
  }

  validate(value) {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  }

  initialize(initialValue) {
    this.validate(initialValue);
    this.props.updateParent(initialValue, this.props.field);
  }

  updateValue(changeEvt) {
    const newValue = changeEvt.target.value;
    this.props.field.currentValue = newValue;
    this.validate(newValue);
    this.props.updateParent(newValue, this.props.field);
    this.setState({ selectedValue: newValue });
  }

  render() {
    const Options = this.props.field.options.map((option, idx) => {
      return (
        <option key={idx} value={option.id}>
          {option.name}
        </option>
      );
    });
    return (
      <Form.Group controlId={this.props.field.name}>
        {this.props.field.isShowLabel && (
          <Form.Label>{this.props.field.name}</Form.Label>
        )}
        <div className="select-container">
          <Form.Control
            as="select"
            size="md"
            onChange={this.updateValue}
            className="select-field"
            value={this.state.selectedValue}
            disabled={this.props.field.disabled}
          >
            <option key={0} value={0} disabled>
              {this.props.field.name}
            </option>
            {Options}
          </Form.Control>
          <div className="select-button">
            <i className="select-button-arrow-down" />
          </div>
        </div>
        <FieldError field={this.props.field} />
      </Form.Group>
    );
  }
}
