import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { ParsedSchedule } from 'features/scheduleConfiguration/utils';

import type { DayType, PeriodType, LunchSettings, CoreSchedule } from 'types';
import { ParsedImagePicker } from '../parsedImagePicker/ParsedImagePicker';

import StepNavigator from '../StepNavigator';

interface Props {
  schoolId: string;
  dayType: DayType;
  periodTypes: PeriodType[];
  lunchSettings: LunchSettings;
  selectedItem?: ParsedSchedule;
  setSelectedItem: (schedule?: ParsedSchedule) => void;
  onNext: (update: Partial<CoreSchedule>) => void;
  enableRefetching: boolean;
}

const ScheduleSelectStep = ({
  dayType,
  periodTypes,
  lunchSettings,
  enableRefetching,
  onNext,
  selectedItem,
  setSelectedItem,
}: Props) => {
  const handleNext = () => {
    const periods = selectedItem?.periods.map((p) => {
      const foundPeriodType = periodTypes.find((pt) => pt.name === p.name);

      const periodProps = foundPeriodType
        ? {
            dayType,
            periodType: foundPeriodType,
          }
        : {};

      return {
        ...p,
        ...periodProps,
        id: '',
      };
    });

    const lunchWaves =
      lunchSettings.type !== 'waves'
        ? undefined
        : selectedItem?.lunches
            .slice(0, lunchSettings.slots.length)
            .map((l, i) => ({
              id: lunchSettings.slots[i].id,
              name: lunchSettings.isAlphabetic
                ? String.fromCharCode(65 + i)
                : String(i + 1),
              startTime: l.startTime,
              endTime: l.endTime,
              slot: lunchSettings.slots[i],
            }));

    onNext({ periods, lunchWaves });
  };

  return (
    <Container>
      <M.Text>Select a schedule to use:</M.Text>

      <OverflowContainer>
        <ParsedImagePicker
          selectedRowId={selectedItem?.id}
          onSelectRow={setSelectedItem}
          enableRefetching={enableRefetching}
        />
      </OverflowContainer>

      <StepNavigator
        skipText="Build manually"
        onSkip={() => onNext({})}
        canGoNext={!!selectedItem}
        onNext={handleNext}
      />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xl};
`;

const OverflowContainer = styled(M.Flex)`
  max-height: 50vh;
  overflow-y: scroll;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xs};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default ScheduleSelectStep;
