import React, { PureComponent } from 'react';
import './ReportDetail.scss';
import {
  BulletinPostCard,
  ChatDetailCard,
  ChatMessageCard,
  UserProfileCard,
} from './Widgets';

export class ReportDetail extends PureComponent {
  render() {
    const { type, report, comments } = this.props;

    switch (type) {
      case 'user_profile':
        return <UserProfileCard user={report} comments={comments} />;
      case 'chat':
        return <ChatDetailCard chat={report} comments={comments} />;
      case 'chat_message':
        if (report.chat_type === 'grade') {
          return <BulletinPostCard post={report} comments={comments} />;
        } else {
          return <ChatMessageCard message={report} comments={comments} />;
        }
      default:
        return null;
    }
  }
}
