import { DayType } from 'types';

export const rotationTypes = [
  { displayName: 'Static', value: 'static' },
  { displayName: 'Modified', value: 'modified' },
  { displayName: 'Special', value: 'special' },
];

export const staticModificationTypes = [
  { value: 'M', label: 'Mon' },
  { value: 'T', label: 'Tue' },
  { value: 'W', label: 'Wed' },
  { value: 'TH', label: 'Thu' },
  { value: 'F', label: 'Fri' },
] as const;

export const modificationTypes = [
  { value: 'ED', label: 'Early Dismissal' },
  { value: 'EXAM', label: 'Exam' },
  { value: 'LS60', label: 'Late Start (1hr)' },
  { value: 'LS90', label: 'Late Start (1.5hr)' },
  { value: 'LS105', label: 'Late Start (1hr45)' },
  { value: 'LS120', label: 'Late Start (2hr)' },
  { value: 'LS150', label: 'Late Start (2.5hr)' },
  { value: 'LS180', label: 'Late Start (3hr)' },
  { value: 'HR', label: 'Homeroom' },
  { value: 'ADVISORY', label: 'Advisory' },
  { value: 'ASSEMBLY', label: 'Assembly' },
  { value: 'ALL-PERIODS-MEET', label: 'All Periods Meet' },
  { value: 'ACTIVITY', label: 'Activity' },
  { value: 'PEP', label: 'Pep Rally' },
  { value: 'OTHER', label: 'Other' },
] as const;

type ModificationType = (typeof modificationTypes)[number]['value'];

export const modificationEmojis: Record<ModificationType, string> = {
  ED: 'confetti-face',
  EXAM: 'exam',
  LS60: 'moon-crescent',
  LS90: 'moon-crescent',
  LS105: 'moon-crescent',
  LS120: 'moon-crescent',
  LS150: 'moon-crescent',
  LS180: 'moon-crescent',
  HR: 'homeroom',
  ADVISORY: 'nerd',
  ASSEMBLY: 'megaphone',
  'ALL-PERIODS-MEET': 'book-stack',
  ACTIVITY: 'running',
  PEP: 'megaphone',
  OTHER: 'backpack',
};

export const NONE_DAY_TYPE = {
  id: 'NONE_DAY_TYPE',
  name: 'NONE',
} as const satisfies DayType;
