import { HAS_TEXT_VALIDATOR } from 'utils/hasTextValidator';

export const CreateCourseStep = () => ({
  name: 'Create Course',
  fields: [
    {
      type: 'SingleTextField',
      name: 'Course Name',
      prop: 'courseName',
      currentValue: '',
      defaultValue: '',
      validators: [HAS_TEXT_VALIDATOR],
      invalidText: 'Enter a class name.',
      valid: false,
    },
  ],
});
