import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import * as M from '@mantine/core';
import * as MN from '@mantine/notifications';

import { TimeInput } from 'features/components/TimeInput';

import { CUSTOM_DAY_TYPE, PeriodsBuilderProps } from './PeriodsBuilder';
import {
  filterPeriodTypesByDayTypeId,
  useFilteredPeriodTypes,
} from './useFilteredPeriodTypes';

import { getLength, formatToTime, parseTime, getHasOverlap } from '../../utils';
import { scheduleUtils } from 'features/schedules/utils/schedule';
import { useSchool } from 'hooks/useSchool';
import { useFilteredDayTypes } from './useFilteredDayTypes';

type Props = {
  customPeriodNames: string[];
  setCustomPeriodNames: React.Dispatch<React.SetStateAction<string[]>>;
};

const AddPeriodConfiguration = ({
  dayTypes,
  periodTypes: initialPeriodTypes,
  periods,
  setPeriods,
  setCustomPeriodNames,
  customPeriodNames,
  defaultDayType,
  schedule,
}: Omit<PeriodsBuilderProps, 'dayType'> & Props) => {
  const [selectedDayType, setSelectedDayType] = useState(defaultDayType);

  const { data: schedules } = useSchool((school) => school.schedules);

  const [selectedPeriodType, setSelectedPeriodType] = useState(
    selectedDayType && initialPeriodTypes[0],
  );

  const { filteredPeriodTypes: periodTypes } = useFilteredPeriodTypes({
    customPeriodNames,
    periodTypes: initialPeriodTypes,
    schedule,
    selectedDayType,
  });

  const fullDayTypes = useFilteredDayTypes({
    customPeriodNames,
    dayTypes,
    schedule,
  });

  const [timeRange, setTimeRange] = useState<[Date, Date]>([
    dayjs().set('hour', 8).set('minute', 0).set('second', 0).toDate(),
    dayjs().set('hour', 8).set('minute', 45).set('second', 0).toDate(),
  ]);

  const [gap, setGap] = useState(5);

  useEffect(() => {
    // Restricting to PM for 12, 2, 3, 4, 5
    // Not handling 1 PM because you might've been typing 10 or 11 AM
    // TODO: rewrite the TimeInput as a masked input similar to HourPicker so
    // we can handle the 1 PM case
    const pmHours = [0, 2, 3, 4, 5];

    const shiftToPM = (d: Date) => dayjs(d).add(12, 'hours').toDate();

    if (pmHours.includes(timeRange[0].getHours())) {
      setTimeRange((p) => [shiftToPM(p[0]), p[1]]);
    }

    if (pmHours.includes(timeRange[1].getHours())) {
      setTimeRange((p) => [p[0], shiftToPM(p[1])]);
    }
  }, [timeRange]);

  const handleAdd = () => {
    const hasOverlap = getHasOverlap(
      [
        ...periods.map((p) => ({
          start: dayjs(parseTime(p.startTime)),
          end: dayjs(parseTime(p.endTime)),
        })),
        {
          start: dayjs(timeRange[0]),
          end: dayjs(timeRange[1]),
        },
      ],
      true,
    );

    if (hasOverlap) {
      MN.showNotification({
        message: 'Periods cannot overlap',
        color: 'red',
      });

      return;
    }

    if (!selectedDayType) {
      MN.showNotification({
        message: 'Please select a day type',
        color: 'red',
      });

      return;
    }

    if (!selectedPeriodType) return;

    setPeriods((prev) =>
      [
        ...prev,
        {
          id: selectedPeriodType.id,
          name: selectedPeriodType.name,
          dayType: selectedDayType,
          periodType: selectedPeriodType,
          startTime: formatToTime(timeRange[0]),
          endTime: formatToTime(timeRange[1]),
        },
      ].sort(
        (a, b) =>
          parseTime(a.startTime).getTime() - parseTime(b.startTime).getTime(),
      ),
    );

    setSelectedPeriodType((prevPeriodType) => {
      const lastPeriodWithPeriodType = prevPeriodType?.id
        ? prevPeriodType
        : periods.filter((p) => p.periodType?.id).at(-1);

      if (!lastPeriodWithPeriodType) {
        return periodTypes[0];
      }

      if (selectedDayType.id === CUSTOM_DAY_TYPE.id) {
        return prevPeriodType;
      }

      let nextIndex = periodTypes.findIndex(
        (p) => p.id === lastPeriodWithPeriodType.id,
      );

      do {
        nextIndex = (nextIndex + 1) % periodTypes.length;
      } while (!periodTypes[nextIndex].id);

      return periodTypes[nextIndex];
    });

    const lastPeriod = periods.at(-1);

    const nextGap = lastPeriod
      ? getLength([parseTime(lastPeriod.endTime), timeRange[0]])
      : gap;

    setGap(nextGap);

    setTimeRange((prev) => [
      dayjs(prev[1]).add(nextGap, 'minutes').toDate(),
      dayjs(prev[1])
        .add(getLength(prev), 'minutes')
        .add(nextGap, 'minutes')
        .toDate(),
    ]);
  };

  const onDayTypeChange = (value: string | null) => {
    const nextDT = fullDayTypes.find((dt) => dt.name === value);
    setSelectedDayType(fullDayTypes.find((dt) => dt.name === value));

    if (!schedules || !nextDT) return;

    if (nextDT.id === CUSTOM_DAY_TYPE.id) {
      setSelectedPeriodType({ id: '', name: customPeriodNames[0] });
      return;
    }

    const periodTypesForNextDT = filterPeriodTypesByDayTypeId(
      schedules,
      nextDT.id,
    );

    const foundPT = periodTypesForNextDT.find(
      (pt) => pt.name === selectedPeriodType?.name,
    );

    if (!foundPT) {
      setSelectedPeriodType(periodTypesForNextDT[0]);
    }
  };

  const onPeriodTypeChange = (value: string | null) => {
    const nextPeriodType = periodTypes.find((pt) => pt.name === value);

    if (nextPeriodType) {
      setSelectedPeriodType(nextPeriodType);
    }

    if (nextPeriodType?.id === '') {
      setSelectedDayType(CUSTOM_DAY_TYPE);
      return;
    } else if (scheduleUtils.isAcfSchedule(schedule)) {
      setSelectedDayType(defaultDayType);
      return;
    }
  };

  const onPeriodTypeCreate = (value: string) => {
    const customPeriodType = {
      id: '',
      name: value,
    };

    setSelectedPeriodType(customPeriodType);
    setCustomPeriodNames((prev) => [...prev, value]);
    setSelectedDayType(CUSTOM_DAY_TYPE);

    return value;
  };

  return (
    <Container>
      <Row>
        <M.NumberInput
          label="Period length (minutes)"
          min={0}
          step={5}
          value={getLength(timeRange)}
          onChange={(value) =>
            value === ''
              ? setTimeRange((prev) => [prev[0], prev[0]])
              : setTimeRange((prev) => [
                  prev[0],
                  dayjs(prev[0]).add(value, 'minutes').toDate(),
                ])
          }
        />
        <M.NumberInput
          label="Gap (minutes)"
          min={0}
          value={gap}
          onChange={(value) => value !== '' && setGap(value)}
        />
      </Row>

      <Row>
        <M.Select
          disabled={scheduleUtils.isAcfSchedule(schedule)}
          label="Day type"
          data={fullDayTypes.map((dt) => dt.name)}
          value={selectedDayType?.name || ''}
          onChange={onDayTypeChange}
        />
        <M.Select
          label="Period type"
          data={periodTypes.map((p) => p.name)}
          value={selectedPeriodType?.name ?? ''}
          onChange={onPeriodTypeChange}
          maxDropdownHeight={360}
          searchable
          creatable
          getCreateLabel={(value) => `Create "${value}" period type`}
          onCreate={onPeriodTypeCreate}
        />
        <TimeInput
          label="Start time"
          value={timeRange[0]}
          onChange={(val) => setTimeRange((prev) => [val, prev[1]])}
        />
        <TimeInput
          label="End time"
          value={timeRange[1]}
          onChange={(val) => setTimeRange((prev) => [prev[0], val])}
        />
        <M.Button onClick={handleAdd} disabled={getLength(timeRange) < 1}>
          Add period
        </M.Button>
      </Row>
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Row = styled(M.Flex)`
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.md};
`;

export default AddPeriodConfiguration;
