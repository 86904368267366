import React from 'react';

export const WizardNavigation = ({
  step,
  totalSteps = undefined,
  dynamicSteps = false,
  nextStep,
  previousStep = undefined,
  onClose = undefined,
}) => {
  return (
    <div className="wizard-nav-buttons">
      <button type="button" className="btn btn-danger cancel" onClick={onClose}>
        Cancel
      </button>
      <div>
        {step > 1 && (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={previousStep}
          >
            Go Back
          </button>
        )}

        {step < totalSteps || dynamicSteps ? (
          <button className="btn btn-primary" onClick={nextStep}>
            Continue
          </button>
        ) : (
          <button className="btn btn-success" onClick={nextStep}>
            Finish
          </button>
        )}
      </div>
    </div>
  );
};
