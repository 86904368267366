import React from 'react';

const commas = (array) => {
  array = array.map((error) => error.replace('_', ' '));
  if (array.length > 2) {
    return array.slice(0, -1).join(', ') + ', and ' + array[array.length - 1];
  }
  return array.join(' and ');
};

export const LoadingError = ({ errors }) => {
  return (
    <div>
      There was a problem loading {commas(Array.from(errors))}. Try reloading.
    </div>
  );
};
