import { useForm, Controller } from 'react-hook-form';
import { TextInput } from 'react-hook-form-mantine';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import * as M from '@mantine/core';
import * as MD from '@mantine/dates';

import { StepHeader } from 'features/schedules/components/scheduleWizard/stepLayout/StepHeader';
import { useSchool } from 'hooks/useSchool';
import type { SpecialSchedule } from 'types';

import { useWizard } from '../stepUtils';
import infoStep from '../steps/InfoStep';
import { useScheduleDetailParams } from 'features/schedules/hooks/useScheduleDetailParams';
import { StepNavigation } from '../stepLayout/StepNavigation';

const schema = z.object({
  date: z.date(),
  displayName: z.string().min(1),
});

type FieldValues = z.infer<typeof schema>;

const SpecialInfoForm = () => {
  const { mode } = useScheduleDetailParams();
  const { data: school } = useSchool(({ dayTypes, schedules }) => ({
    dayTypes,
    schedules,
  }));

  const [schedule, saveStep, next] = useWizard((store) => [
    store.state.schedule as Partial<SpecialSchedule>,
    store.saveStep,
    store.next,
  ]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      date: schedule.date,
      displayName: schedule.displayName || '',
    },
    resolver: zodResolver(schema),
  });

  if (!school) return <M.Loader />;

  const onSubmit = (values: FieldValues) => {
    if (
      (mode !== 'edit' || !dayjs(values.date).isSame(dayjs(schedule.date))) &&
      school.schedules.find(
        (s) =>
          s.variant === 'special' && dayjs(s.date).isSame(dayjs(values.date)),
      )
    ) {
      setError('root', {
        message: 'A special schedule for this date already exists',
      });
      return;
    }

    saveStep(
      infoStep.payloadReducer({
        variant: 'special',
        ...values,
      }),
    );

    next();
  };

  return (
    <div>
      <StepHeader
        title="Special schedule info"
        description="Add basic information about your schedule"
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <M.Flex direction="column" gap="md">
          <Controller
            name="date"
            control={control}
            render={({ field }) => {
              return (
                <MD.DateInput
                  label="Date"
                  value={field.value}
                  onChange={(d) => (d ? field.onChange(d) : undefined)}
                  minDate={dayjs(Date.now()).toDate()}
                  required
                />
              );
            }}
          />

          <TextInput
            name="displayName"
            label="Display name"
            autoComplete="off"
            control={control}
            required
          />
          {errors.root && <M.Text color="red">{errors.root.message}</M.Text>}
        </M.Flex>

        <StepNavigation onNext={handleSubmit(onSubmit)} />
      </form>
    </div>
  );
};

export default SpecialInfoForm;
