import { useLocation, Link } from 'react-router-dom';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import {
  IconArrowsDownUp,
  IconEdit,
  IconCopy,
  IconTrash,
} from '@tabler/icons-react';

import { ScheduleBadge } from 'features/components/ScheduleBadge';
import { scheduleSubRoutes } from 'features/schedules/constants/subroutes';
import type { Schedule } from 'types';

type ScheduleRowPropsBase = {
  schedule: Schedule;
  loadingScheduleId?: string;
  isReorderable?: boolean;
  setSelectedSchedule: (schedule: Schedule) => void;
  handleDelete: (schedule: Schedule) => void;
};

type ScheduleRowPropsStatic = {
  provided?: never;
  snapshot?: never;
};

type ScheduleRowPropsDraggable = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
};

export const ScheduleRow = ({
  provided,
  schedule,
  snapshot,
  isReorderable,
  setSelectedSchedule,
  loadingScheduleId,
  handleDelete,
}: ScheduleRowPropsBase &
  (ScheduleRowPropsStatic | ScheduleRowPropsDraggable)) => {
  const { pathname } = useLocation();

  return (
    <Container
      isReorderable={!!isReorderable}
      isDragging={!!snapshot?.isDragging}
      ref={provided?.innerRef}
      {...(provided?.draggableProps ?? {})}
      {...(provided?.dragHandleProps ?? {})}
      onClick={() => setSelectedSchedule(schedule)}
      data-testid="schedule-row"
    >
      <M.Flex align="center" gap="xs">
        <M.Text>{schedule.displayName}</M.Text>
        <ScheduleBadge schedule={schedule} />
      </M.Flex>

      {isReorderable ? (
        <IconArrowsDownUp />
      ) : (
        <IconsContainer>
          <Link to={`${pathname}/${scheduleSubRoutes.edit}/${schedule.id}`}>
            <IconEdit />
          </Link>
          <Link to={`${pathname}/${scheduleSubRoutes.copy}/${schedule.id}`}>
            <IconCopy />
          </Link>
          <IconTrash
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(schedule);
            }}
          />
        </IconsContainer>
      )}
      <M.LoadingOverlay
        visible={loadingScheduleId === schedule.id}
        loaderProps={{ size: 's' }}
      />
    </Container>
  );
};

const Container = styled.div<{
  isReorderable: boolean;
  isDragging: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radius.md};
  border: 1px solid ${(p) => p.theme.colors.gray[2]};
  cursor: pointer;
  box-shadow: ${(p) => (p.isDragging ? p.theme.shadows.sm : 'none')};

  &:hover {
    background-color: ${(p) => !p.isReorderable && p.theme.colors.gray[0]};
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.sm};

  svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    color: black;

    &:hover {
      color: ${(p) => p.theme.colors.blue[6]};
    }
  }
`;
