import { isProd } from 'utils/utilities';

/*
    Determine if a user should have access to a feature.

    desiredScopes are OR'd together, so if a user has
    access to any of the desired scopes, it will return true.

    Owners always have access to everything (except content moderation).
*/
export const hasAccessTo = (
  grantedScopes?: Set<string>,
  desiredScopes?: string[],
) => {
  if (!grantedScopes) {
    return false;
  }
  if (desiredScopes?.includes('moderator')) {
    return isProd ? grantedScopes.has('moderator') : true;
  }
  if (grantedScopes.has('owner')) {
    return true;
  }
  if (!grantedScopes.has('dashboard')) {
    return false;
  }
  if (!desiredScopes) {
    return true;
  }
  for (const scope of desiredScopes) {
    if (grantedScopes.has(scope)) {
      return true;
    }
  }
  return false;
};
