import { ReactNode } from 'react';

import type { Schedule, ScheduleVariant } from 'types';

import { stepCreator, useWizard } from '../stepUtils';
import CoreInfoForm from '../infoVariants/CoreInfoForm';
import SpecialInfoForm from '../infoVariants/SpecialInfoForm';
import ModifiedInfoForm from '../infoVariants/ModifiedInfoForm';
import { StepContainer } from '../stepLayout/StepContainer';
import { periodUtils } from 'features/schedules/utils/period';
import { scheduleUtils } from 'features/schedules/utils/schedule';

type Payload = Pick<Schedule, 'displayName'> & ScheduleVariant;

const infoStep = stepCreator.create<Payload>()({
  key: 'info',
  validate: () => true,
  payloadReducer: (payload) => (state) => ({
    ...state,
    schedule: {
      ...state.schedule,
      ...payload,
      // TODO: figure out what if periods do not exist on some ACF schedule
      periods: state.schedule?.periods?.map((p) =>
        periodUtils.isRegularPeriod(p) && scheduleUtils.isAcfSchedule(payload)
          ? {
              ...p,
              dayType: payload.dayType,
            }
          : p,
      ),
    },
  }),
  render: () => <InfoStep />,
});

const VARIANTS_FORM_MAP: { [K in Schedule['variant']]: ReactNode } = {
  core: <CoreInfoForm />,
  modified: <ModifiedInfoForm />,
  special: <SpecialInfoForm />,
};

const InfoStep = () => {
  const variant = useWizard((store) => store.state.schedule?.variant);

  return <StepContainer>{variant && VARIANTS_FORM_MAP[variant]}</StepContainer>;
};

export default infoStep;
