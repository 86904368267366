const ActionTypes = {
  GET_SCHEDULING_SETTINGS: 'Schedules.GET_SCHEDULING_SETTINGS',
  GET_SCHEDULING_SETTINGS_SUCCESS: 'Schedules.GET_SCHEDULING_SETTINGS_SUCCESS',
  GET_SCHEDULING_SETTINGS_FAIL: 'Schedules.GET_SCHEDULING_SETTINGS_FAIL',
  SET_DAY_SCHEDULE: 'Schedules.SET_DAY_SCHEDULE',
};

const getSchedulingSettings = (schoolId, school) => {
  return {
    type: ActionTypes.GET_SCHEDULING_SETTINGS,
    schoolId,
    school,
  };
};

const setDaySchedule = (school_id, day, scheduleId, scheduleName) => ({
  type: ActionTypes.SET_DAY_SCHEDULE,
  school_id,
  day,
  scheduleId,
  scheduleName,
});

const actions = {
  getSchedulingSettings,
  setDaySchedule,
};

export { ActionTypes, actions };
