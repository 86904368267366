import * as M from '@mantine/core';

import type { Step } from 'features/scheduleConfiguration/types';

interface Props {
  step: Step;
}

const CoreSchedulesReview = ({ step }: Props) => {
  if (step.type !== 'coreSchedulesStep' || !step.payload) {
    return <></>;
  }

  return (
    <div>
      <M.Title order={6}>Created schedules for:</M.Title>
      <M.Text>
        {step.payload.map((s) => s.displayName).join(', ') || '-'}
      </M.Text>
    </div>
  );
};

export default CoreSchedulesReview;
