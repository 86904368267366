import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import CalendarWizard from 'components/wizards/CalendarWizard/CalendarWizard';
import { ModalResult } from 'components/shared/Modal/ModalSuccess/ModalSuccess';
import schedulesService from 'services/schedules.service';
import { schoolsActions } from 'store/actions/actions';

import './BuildCalendarModal.scss';

const BuildCalendarModal = ({
  step: initialStep = undefined,
  school,
  hide,
  regenerate,
  rotationData: {
    start_date,
    end_date,
    locked_weekdays,
    rotation_start,
    fixed_dates,
  },
}) => {
  const [step, setStep] = useState(initialStep || 1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [form, setForm] = useState({
    start_date: start_date,
    end_date: end_date,
    locked_weekdays: locked_weekdays || [],
    rotation_start: rotation_start || 4,
    fixed_dates: fixed_dates || [],
    regenerate,
    rotation_data: [],
  });

  const dispatch = useDispatch();

  const onSubmit = () => {
    const data = form.rotation_data;

    setIsSubmitting(true);
    return schedulesService()
      .bulkInsertCalendarData(school.school_name, data)
      .then(() => {
        setIsSuccess(true);
        setMessage('Calendar was updated successfully');
      })
      .catch(() => {
        setIsSuccess(false);
        setMessage('An error happened when trying to update the calendar');
      })
      .finally(() => {
        dispatch(schoolsActions.getSchoolById(school.school_name));
        setIsSubmitting(false);
        setHasSubmitted(true);
      });
  };

  return (
    <Modal
      show
      onHide={hide}
      className={step === 2 ? 'fixed-dates' : step === 3 ? 'review' : ''}
    >
      <Modal.Body>
        {!isSubmitting && !hasSubmitted ? (
          <CalendarWizard
            step={step}
            form={form}
            school={school}
            onUpdate={(data) => setForm((prev) => ({ ...prev, ...data }))}
            onStepChange={(stepChange) => setStep(stepChange.activeStep)}
            onClose={hide}
            onSubmit={onSubmit}
          />
        ) : (
          <ModalResult
            submitting={isSubmitting}
            submitted={hasSubmitted}
            success={isSuccess}
            message={message}
            onClose={hide}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BuildCalendarModal;
