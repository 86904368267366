import { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { SCHEDULE_TYPE } from 'constants/scheduleTypes';
import type { ScheduleType, DayType, PeriodType, Schedule } from 'types';

import DayAndPeriodList from './DayAndPeriodList';
import DayAndPeriodSelect from './DayAndPeriodSelect';

import {
  useCreateDayTypesMutation,
  useCreatePeriodTypesMutation,
  useCreateDayTypeMutation,
  useCreatePeriodTypeMutation,
  useUpdateDayTypeMutation,
  useUpdatePeriodTypeMutation,
  useDeleteDayTypeMutation,
  useDeletePeriodTypeMutation,
} from '../hooks/dayAndPeriodHooks';

interface Props {
  settingType: 'day' | 'period';
  schoolId: string;
  scheduleType: ScheduleType | undefined;
  schedules: Schedule[];
  types: DayType[] | PeriodType[];
}

/**
 * Map of schedule types to the number of day types they should have.
 * Empty array means any number of day types is valid.
 */
const ScheduleTypeDayTypeCountMap: Record<ScheduleType, number[]> = {
  same_every_day: [1],
  same_every_day_plus: [1],
  a_b: [2],
  a_b_plus: [2, 3],
  monday_through_friday: [5],
  multiple_day_rotating: [],
};

const DayAndPeriodSettings = ({
  settingType,
  schoolId,
  scheduleType,
  schedules,
  types,
}: Props) => {
  const [isAddingOther, setIsAddingOther] = useState(false);

  const createDayTypes = useCreateDayTypesMutation(schoolId);
  const createPeriodTypes = useCreatePeriodTypesMutation(schoolId);
  const createDayType = useCreateDayTypeMutation(schoolId);
  const createPeriodType = useCreatePeriodTypeMutation(schoolId);
  const updateDayType = useUpdateDayTypeMutation(schoolId);
  const updatePeriodType = useUpdatePeriodTypeMutation(schoolId);
  const deleteDayType = useDeleteDayTypeMutation(schoolId);
  const deletePeriodType = useDeletePeriodTypeMutation(schoolId);

  const isCountMismatch = useMemo(() => {
    if (settingType === 'period' || !types.length) {
      return false;
    }

    switch (scheduleType) {
      case 'same_every_day':
      case 'same_every_day_plus':
      case 'a_b':
      case 'a_b_plus':
      case 'monday_through_friday':
        return !ScheduleTypeDayTypeCountMap[scheduleType].includes(
          types.length,
        );
      default:
        return false;
    }
  }, [scheduleType, settingType, types]);

  const isPeriod = settingType === 'period';
  const isLoading =
    createDayTypes.isLoading ||
    createPeriodTypes.isLoading ||
    createDayType.isLoading ||
    createPeriodType.isLoading ||
    updateDayType.isLoading ||
    updatePeriodType.isLoading ||
    deleteDayType.isLoading ||
    deletePeriodType.isLoading;

  const batchCreateMutation = isPeriod ? createPeriodTypes : createDayTypes;
  const createMutation = isPeriod ? createPeriodType : createDayType;
  const updateMutation = isPeriod ? updatePeriodType : updateDayType;
  const deleteMutation = isPeriod ? deletePeriodType : deleteDayType;

  return (
    <Container>
      <Title order={3}>{settingType} types</Title>
      {!types.length && (
        <DayAndPeriodSelect
          isPeriod={isPeriod}
          scheduleType={scheduleType}
          setIsAddingOther={setIsAddingOther}
          createTypes={batchCreateMutation.mutate}
        />
      )}
      <DayAndPeriodList
        isPeriod={isPeriod}
        schedules={schedules}
        types={types}
        canAddType={isAddingOther || !!types.length}
        createType={createMutation.mutate}
        updateType={updateMutation.mutate}
        deleteType={deleteMutation.mutate}
      />
      {isCountMismatch && scheduleType && (
        <M.Text color="yellow" align="center">
          Warning: Count does not match schedule type.{' '}
          {SCHEDULE_TYPE[scheduleType]} should have{' '}
          {ScheduleTypeDayTypeCountMap[scheduleType].join(' or ')} {settingType}{' '}
          types.
        </M.Text>
      )}

      <M.LoadingOverlay visible={isLoading} />
    </Container>
  );
};

const Container = styled(M.Flex)`
  position: relative;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Title = styled(M.Title)`
  text-transform: capitalize;
`;

export default DayAndPeriodSettings;
