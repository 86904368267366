import { useGetMutation } from 'hooks/utils';
import type { SchoolResponse, Course } from 'types';

export const useCreateLunchClassMutation = (schoolId: string) =>
  useGetMutation<void, Course>({
    schoolId,
    method: 'post',
    expectedStatus: 201,
    getPath: () => 'v2/courses',
    getBody: () => ({
      name: 'Lunch',
      school: schoolId,
      emoji_id: 'burger',
    }),
    update: (school, responseData) => ({
      ...school,
      courses: [...school.courses, responseData],
    }),
  });

export const useDeleteLunchClassMutation = (
  schoolId: string,
  courseId?: number,
) =>
  useGetMutation<void, void>({
    schoolId,
    method: 'delete',
    expectedStatus: 204,
    getPath: () => `v2/courses/${courseId}`,
    getBody: () => undefined,
    update: (school) => ({
      ...school,
      courses: school.courses.filter((c) => c.id !== courseId),
    }),
  });

export const useUpdateNumWavesMutation = (schoolId: string) =>
  useGetMutation<number, { id: string; slot: number }[]>({
    schoolId,
    method: 'patch',
    expectedStatus: 200,
    getPath: () => 'v3/schedules/lunch_slots',
    getBody: (numWaves) => ({
      schoolId,
      slots: numWaves,
    }),
    update: (school, responseData) => ({
      ...school,
      lunchSettings: {
        ...school.lunchSettings,
        numWaves: responseData.length,
      },
    }),
  });

export const useUpdateIsAlphabeticMutation = (schoolId: string) =>
  useGetMutation<boolean, SchoolResponse>({
    schoolId,
    method: 'put',
    expectedStatus: 200,
    getPath: () => `v2/schools/${schoolId}`,
    getBody: (isAlphabetic) => ({ alphabeticLunchWaves: isAlphabetic }),
    update: (school, responseData) => ({
      ...school,
      lunchSettings: {
        ...school.lunchSettings,
        isAlphabetic: responseData.alphabeticLunchWaves!,
      },
    }),
  });
