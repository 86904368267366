import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { COLUMNS, FILTER_OPS } from '../constants';
import type { ColumnKey, Filter } from '../types';

interface Props {
  filterKey: ColumnKey;
  filter: Filter;
  onClick: () => void;
}

const FilterChip: React.FC<Props> = ({ filterKey, filter, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Chip value={filterKey} onClick={onClick}>
        {COLUMNS[filterKey]}
        <Operator>{FILTER_OPS[filter.op]}</Operator>
        {filter.value}
      </Chip>
      {isHovering && (
        <RemoveLabel>
          <IconX />
        </RemoveLabel>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Chip = styled(M.Chip)`
  text-align: center;

  &:hover {
    opacity: 0.25;
  }

  .mantine-Chip-label {
    height: fit-content;
    margin: 0;
    padding: 4px ${({ theme }) => theme.spacing.lg};

    border-radius: ${({ theme }) => theme.spacing.xs};
  }
`;

const Operator = styled(M.Text)`
  padding: 4px;
  display: inline;

  font-weight: bold;
  color: #6515e7;
`;

const RemoveLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;
`;

export default FilterChip;
