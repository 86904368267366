import axios from 'axios';

import apiClient from 'utils/apiClient';

const getSchoolsGeoJSON = (params = {}) =>
  apiClient.get(`v2/map/schools/geojson`, { params: params });

const getDistricts = (params = {}) =>
  apiClient.get(`v2/map/districts`, { params: params });

const banUser = (body) => apiClient.post(`v2/admin/suspend`, body);

const getEmojis = () =>
  axios.get('https://cdn.joinsaturn.com/cache/v2/emoji.production.json');

const miscService = () => ({
  getSchoolsGeoJSON,
  getDistricts,
  banUser,
  getEmojis,
});

export default miscService;
