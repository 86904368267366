import React from 'react';

export const DeleteStep = (objectType, objectName) => ({
  name: (
    <span>
      Deleting {objectType}&nbsp;
      <span className="text-capitalize">{objectName}</span>
    </span>
  ),
  fields: [
    {
      type: 'MessageField',
      prop: 'message',
      defaultValue: 'This cannot be undone. Are you sure?',
      valid: true,
    },
  ],
});
