import { useQuery } from '@tanstack/react-query';

import { get, getWithHeaders, parseScheduleResponse } from 'hooks/utils';

import type { DraftSchedulesResponse, ImagesResponse, Schedule } from 'types';

import type { FilterTypes } from '../types';

export const useGetDraftSchedules = ({
  page,
  filters,
}: {
  page: number;
  filters: FilterTypes;
}) => {
  const params = new URLSearchParams();

  params.append('paginate', 'true');
  params.append('limit', '10');
  params.append('page', `${page}`);
  if (filters.school_id) {
    params.append('school_id', filters.school_id.value);
  }

  return useQuery({
    queryKey: ['draftSchedules', page, filters],
    queryFn: async () => {
      const draftSchedulesResponse =
        await getWithHeaders<DraftSchedulesResponse>(
          `v2/network/reports/draft_schedules?${decodeURIComponent(
            params.toString(),
          )}`,
        );

      const imagesParams = draftSchedulesResponse.data
        .map((s) => `schedule_ids=${s.id}`)
        .join('&');

      const imagesResponse = await get<ImagesResponse>(
        `v3/schedules/images?${imagesParams}`,
      );

      const draftSchedules: Schedule[] = draftSchedulesResponse.data.map((s) =>
        parseScheduleResponse(s, imagesResponse, draftSchedulesResponse.data),
      );

      return {
        draftSchedules,
        pageCount: parseInt(draftSchedulesResponse.headers.xPaginationPages),
      };
    },
  });
};
