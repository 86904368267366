/* eslint @typescript-eslint/no-explicit-any: off */

import styled from '@emotion/styled';
import {
  MRT_ColumnDef,
  MRT_TableOptions,
  useMantineReactTable,
  MantineReactTable,
} from 'mantine-react-table';
import merge from 'ts-deepmerge';

type PartialTableOpts<T extends Record<string, any> = Record<string, any>> =
  Partial<MRT_TableOptions<T>>;

const TABLE_DEFAULT_OPTIONS = {
  positionGlobalFilter: 'left',
  mantinePaginationProps: {
    rowsPerPageOptions: ['10', '25', '50'],
    withEdges: false,
  },
  state: { density: 'xs', showGlobalFilter: true },
  enableColumnActions: false,
  enableToolbarInternalActions: false,
  paginationDisplayMode: 'default',
} satisfies PartialTableOpts;

export interface TableProps<T extends Record<string, any>> {
  data?: T[];
  columns: MRT_ColumnDef<T>[];
  tableOptions?: Partial<MRT_TableOptions<T>>;
}

export const Table = <T extends Record<string, any>>({
  columns,
  data,
  tableOptions,
}: TableProps<T>) => {
  const table = useMantineReactTable(
    merge(TABLE_DEFAULT_OPTIONS, tableOptions ?? {}, {
      data: data ?? [],
      columns,
      state: { isLoading: !data },
    } satisfies PartialTableOpts<T>) as MRT_TableOptions<T>,
  );

  return (
    <Container>
      <MantineReactTable table={table} />
    </Container>
  );
};

const Container = styled.div`
  padding-top: ${(p) => p.theme.spacing.lg};
`;
