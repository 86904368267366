import { useState } from 'react';
import * as M from '@mantine/core';
import styled from '@emotion/styled';

import { Schedule } from 'types';
import { StepContainer } from '../stepLayout/StepContainer';
import { StepHeader } from '../stepLayout/StepHeader';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { stepCreator, useWizard } from '../stepUtils';

type Payload = Schedule['variant'];

const VARIANTS: Record<Payload, string> = {
  core: 'Core',
  modified: 'Modified',
  special: 'Special',
};

const scheduleTypesStep = stepCreator.create<Payload>()({
  key: 'scheduleType',
  validate: (state) => !!state.schedule?.variant,
  payloadReducer: (payload) => (prev) => ({
    ...prev,
    schedule: { ...prev.schedule, variant: payload },
  }),
  render: () => <ScheduleTypeStep />,
});

const ScheduleTypeStep = () => {
  const [state, saveStep, next] = useWizard((store) => [
    store.state,
    store.saveStep,
    store.next,
  ]);

  const [value, setValue] = useState<Schedule['variant'] | undefined>(
    state.schedule?.variant,
  );

  const onChange = (variant: Payload) => {
    saveStep(scheduleTypesStep.payloadReducer(variant));
    next();
  };

  return (
    <StepContainer>
      <StepHeader
        title="Schedule type"
        description="Which type of schedule would you like to create?"
      />

      <Container>
        <RadioGroup
          value={value}
          onChange={(val) => {
            setValue(val as Schedule['variant']);
            // adding synthetic delay to account for radio button animation
            setTimeout(() => onChange(val as Schedule['variant']), 150);
          }}
        >
          {Object.entries(VARIANTS).map(([key, label]) => (
            <Radio key={key} value={key} label={label} size="md" />
          ))}
        </RadioGroup>
      </Container>

      {state.schedule?.variant && <StepNavigation />}
    </StepContainer>
  );
};

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const RadioGroup = styled(M.Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xs};
`;

const Radio = styled(M.Radio)`
  .mantine-Radio-radio:hover {
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.blue[2]};
  }
`;

export default scheduleTypesStep;
