import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import PlacesAutocomplete from 'react-places-autocomplete';
import './AddressAutoCompleteField.scss';
import FieldError from '../FieldError/FieldError';
import PlaceholderUp from '../PlaceholderUp/PlaceholderUp';

class AddressAutoCompleteField extends Component {
  constructor(props) {
    super(props);

    this.validate(this.props.field.currentValue);
  }

  isFieldInvalid = () => {
    return this.props.field.blurred === true && !this.props.field.valid;
  };

  updateBlur = () => {
    this.props.field.blurred = true;
    this.props.updateParent(this.props.field.currentValue, this.props.field);
  };

  validate = (value) => {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  };

  updateValue = (address) => {
    this.validate(address);
    this.props.updateParent(address, this.props.field);
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.field.currentValue}
        onChange={this.updateValue}
        onSelect={this.updateValue}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className="places-auto-complete-content">
            <Form.Group controlId={this.props.field.name}>
              {this.props.field.isShowLabel && (
                <Form.Label>{this.props.field.name}</Form.Label>
              )}
              <Form.Control
                type="text"
                size="md"
                placeholder={
                  this.props.field.placeholder || this.props.field.name
                }
                name={this.props.field.name}
                value={this.props.field.currentValue}
                isInvalid={this.isFieldInvalid()}
                {...getInputProps({
                  placeholder: this.props.field.name,
                  className: 'address-search-input',
                  onBlur: this.updateBlur,
                })}
              />
              {this.props.field.isPlaceholderUp &&
                this.props.field.currentValue && (
                  <PlaceholderUp label={this.props.field.name} />
                )}
              <FieldError field={this.props.field} />
            </Form.Group>
            {suggestions && suggestions.length > 0 && (
              <div className="address-suggestion">
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'address-suggestion-item--active'
                    : 'address-suggestion-item';

                  return (
                    <div
                      key={`suggestion-${index}`}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default AddressAutoCompleteField;
