import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import dayjs from 'dayjs';

import { ImagePreviewModal } from 'features/components/ImagePreviewModal';
import { DATE_DISPLAY_FORMAT } from 'constants/dates';
import { getScheduleCode } from 'hooks/utils';
import {
  modificationTypes,
  staticModificationTypes,
} from 'constants/scheduleInfo';
import type { Schedule } from 'types';

import { ScheduleBadge } from '../ScheduleBadge';
import LunchReviewTable from './LunchReviewTable';
import PeriodsReviewTable from './PeriodsReviewTable';

const getDate = (date: Date) => dayjs(date).format('MMM D, YYYY');
const getTime = (date: Date) => dayjs(date).format('h:mm a');
const getDateTime = (date: Date) => `${getDate(date)} at ${getTime(date)}`;

export const ScheduleView = ({ schedule }: { schedule: Schedule }) => {
  const [selectedImage, setSelectedImage] = useState<string>();

  const hasLunchWaves = !!schedule.lunchWaves.length;

  return (
    <Container>
      <M.Flex justify="space-between">
        <M.Title order={2}>{schedule.displayName}</M.Title>
        <ScheduleBadge schedule={schedule} />
      </M.Flex>

      <M.SimpleGrid cols={2}>
        <InfoContainer>
          <InfoItem label="Creator" value={schedule.author || 'Unknown'} />
          <InfoItem
            label="Schedule code"
            value={schedule.originalScheduleCode || getScheduleCode(schedule)}
          />
        </InfoContainer>

        <InfoContainer>
          <InfoItem
            label="Created on"
            value={getDateTime(schedule.createdAt)}
          />
          <InfoItem
            label="Updated on"
            value={getDateTime(schedule.updatedAt)}
          />
          {schedule.targetDate && (
            <InfoItem
              label="Target date"
              value={dayjs(schedule.targetDate).format(DATE_DISPLAY_FORMAT)}
            />
          )}
        </InfoContainer>
      </M.SimpleGrid>

      <M.Divider />

      <M.SimpleGrid cols={2}>
        <InfoContainer>
          {(schedule.variant === 'core' || schedule.variant === 'modified') && (
            <InfoItem label="Day type" value={schedule.dayType.name} />
          )}
          {schedule.variant === 'core' && (
            <InfoItem
              label="Day of week"
              value={
                staticModificationTypes.find(
                  (st) => st.value === schedule.dayOfWeek,
                )?.label || schedule.brokenDayOfWeek
              }
              isError={!!schedule.brokenDayOfWeek}
            />
          )}
          {schedule.variant === 'modified' && (
            <InfoItem
              label="Modification"
              value={
                schedule.modification === 'OTHER'
                  ? schedule.otherModification
                  : modificationTypes.find(
                      (mt) => mt.value === schedule.modification,
                    )?.label
              }
            />
          )}
          {schedule.variant === 'special' && (
            <InfoItem
              label="Date"
              value={dayjs(schedule.date).format(DATE_DISPLAY_FORMAT)}
            />
          )}
        </InfoContainer>

        <InfoContainer>
          <InfoItem
            label="Display in LSC"
            value={
              schedule.variant !== 'special' && schedule.isInLsc ? 'Yes' : 'No'
            }
          />
          <InfoItem
            label="Display in ACF"
            value={
              schedule.variant === 'core' && schedule.isInAcf ? 'Yes' : 'No'
            }
          />
        </InfoContainer>
      </M.SimpleGrid>

      <M.Divider />

      <M.SimpleGrid cols={hasLunchWaves ? 2 : 1}>
        <PeriodsReviewTable
          isExpanded={!hasLunchWaves}
          periods={schedule.periods}
        />
        {hasLunchWaves && <LunchReviewTable lunchWaves={schedule.lunchWaves} />}
      </M.SimpleGrid>

      {schedule.image && (
        <>
          <M.Divider />
          <InfoItem label="Image source" value={schedule.image.source} />
          <M.Image
            src={schedule.image.url}
            radius="sm"
            width={400}
            height={400}
            sx={{ alignSelf: 'center', ':hover': { cursor: 'pointer' } }}
            onClick={() => setSelectedImage(schedule.image!.url)}
          />
        </>
      )}
      {selectedImage && (
        <ImagePreviewModal
          imageSrc={selectedImage}
          onClose={() => setSelectedImage(undefined)}
        />
      )}
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xs};
  padding-bottom: ${(p) => p.theme.spacing.xl};
`;

const InfoContainer = styled(M.Flex)`
  flex-direction: column;
`;

const InfoItem = ({
  label,
  value = '',
  isError,
}: {
  label: string;
  value: string | undefined;
  isError?: boolean;
}) => (
  <M.Text {...(isError ? { color: 'red' } : {})}>
    <strong>{label}</strong>: {value}
  </M.Text>
);
