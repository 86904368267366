import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import cx from 'classnames';

const MapStats = ({ stats }) => {
  const [isVisible, setIsVisible] = useState(false);

  if (!stats) {
    return null;
  }

  return (
    <Row
      className={cx({
        'dash-stats': true,
        'dash-stats-hidden': !isVisible,
      })}
    >
      <div
        className="dash-stats-toggle"
        onClick={() => setIsVisible((p) => !p)}
      >
        {isVisible ? '▼' : '▲'}
      </div>
      {stats.map((stat) => (
        <div key={stat.name} className="stat-counter">
          <span className="schools-count">{stat.value}</span>
          {stat.name}
        </div>
      ))}
    </Row>
  );
};

export default MapStats;
