import * as M from '@mantine/core';
import React from 'react';
import { Dispatch } from 'redux';
import { SchoolV1 } from 'types';

import { Calendar, Settings } from 'features/school';
import LiteConfiguration from 'features/school/components/LiteConfiguration';

import CoursesTable from 'components/shared/SchoolDataTabs/CoursesTable';
import UsersPage from 'features/users/page';
import SchoolFeatures from 'components/shared/SchoolFeatures/SchoolFeatures';
import ScheduleListPage from 'features/schedules/pages/ScheduleListPage';
import TeachersPage from 'features/teachers/page';

export type SchoolTabProps = {
  school: SchoolV1 | null;
  mySchoolId: string;
  isWizardVisible: boolean;
  isOwner: boolean;
  /**
   * @deprecated after class components refactor replace with `useDispatch` hook
   */
  dispatch: Dispatch<any>;
};

export type SchoolTabPath =
  | 'calendar'
  | 'schedules'
  | 'classes'
  | 'teachers'
  | 'users'
  | 'features'
  | 'settings';

type SchoolTab = {
  isDisabled: (props: SchoolTabProps) => boolean;
  isAvailable: (props: SchoolTabProps) => boolean;
  render: (props: SchoolTabProps) => React.ReactNode;
};

export const schoolTabsConfig: {
  [Key in SchoolTabPath]: SchoolTab;
} = {
  calendar: {
    isDisabled: ({ school }) => !school,
    isAvailable: () => true,
    render: ({ school, isWizardVisible }) => (
      <Calendar school={school} isWizardVisible={isWizardVisible} />
    ),
  },
  schedules: {
    isDisabled: ({ school, isWizardVisible }) => !school || isWizardVisible,
    isAvailable: () => true,
    render: () => <ScheduleListPage />,
  },
  classes: {
    isDisabled: ({ school }) => !school,
    isAvailable: () => true,
    render: ({ school }) => (
      <M.Container size="xl">
        <CoursesTable currentSchool={school} />
      </M.Container>
    ),
  },
  teachers: {
    isDisabled: ({ school }) => !school,
    isAvailable: () => true,
    render: () => (
      <M.Container size="xl">
        <TeachersPage />
      </M.Container>
    ),
  },
  users: {
    isDisabled: ({ school }) => !school,
    isAvailable: () => true,
    render: () => (
      <M.Container size="xl">
        <UsersPage />
      </M.Container>
    ),
  },
  features: {
    isDisabled: ({ school }) => !school,
    isAvailable: ({ isOwner }) => isOwner,
    render: ({ school, dispatch }) => (
      <SchoolFeatures currentSchool={school} dispatch={dispatch} />
    ),
  },
  settings: {
    isDisabled: ({ school }) => !school,
    isAvailable: () => true,
    render: ({ school, isWizardVisible }) => {
      if (!school) return <M.LoadingOverlay visible />;

      return isWizardVisible ? <LiteConfiguration /> : <Settings />;
    },
  },
};
