import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { get, parseScheduleResponse } from 'hooks/utils';

import type {
  SchoolResponse,
  ScheduleSettingsResponse,
  SchoolYearsResponse,
  SchedulesResponse,
  DraftSchedulesResponse,
  School,
  ScheduleType,
  LunchSettings,
  Course,
  Schedule,
  DayType,
  PeriodType,
  SchoolYear,
  ImagesResponse,
} from 'types';

import { useSchoolId } from './useSchoolId';
import { BlockingError } from 'utils/errors';

export const fetchSchoolData = async (schoolId: string) => {
  try {
    const [
      schoolResponse,
      scheduleSettingsResponse,
      courses,
      schoolYearsResponse,
      schedulesResponse,
      draftSchedulesResponse,
    ] = await Promise.all([
      get<SchoolResponse>(`v2/schools/${schoolId}`),
      get<ScheduleSettingsResponse>(`v3/schedules/settings/${schoolId}`),
      get<Course[]>(
        `v2/schools/${schoolId}/courses?include_user_generated=true`,
      ),
      get<SchoolYearsResponse>(`v2/schools/${schoolId}/school_years`),
      get<SchedulesResponse>(`v3/schedules/${schoolId}`),
      get<DraftSchedulesResponse>(
        `v2/network/reports/draft_schedules?school_id=${schoolId}`,
      ),
    ]);

    const lunchSettings: LunchSettings =
      scheduleSettingsResponse.lunchType === 'waves'
        ? {
            type: scheduleSettingsResponse.lunchType,
            slots: scheduleSettingsResponse.lunchSlots.map((slot) => ({
              id: slot.id!,
              slot: slot.slot,
            })),
            isAlphabetic: schoolResponse.alphabeticLunchWaves || false,
          }
        : { type: scheduleSettingsResponse.lunchType };

    const dayTypes: DayType[] = scheduleSettingsResponse.dayTypes.map((dt) => ({
      name: dt.name,
      id: dt.id!,
    }));

    const periodTypes: PeriodType[] = scheduleSettingsResponse.periodTypes.map(
      (pt) => ({
        name: pt.name,
        id: pt.id!,
      }),
    );

    const schoolYears: SchoolYear[] = schoolYearsResponse.map((sy) => ({
      id: sy.id!,
      startDate: dayjs(sy.startDate).toDate(),
      endDate: dayjs(sy.endDate).toDate(),
      isCurrent: sy.current!,
    }));

    const imagesParams = schedulesResponse
      .map((s) => `schedule_ids=${s.id}`)
      .join('&');

    const imagesResponse = await get<ImagesResponse>(
      `v3/schedules/images?${imagesParams}`,
    );

    const schedules: Schedule[] = schedulesResponse.map((s) =>
      parseScheduleResponse(s, imagesResponse, draftSchedulesResponse),
    );

    const school: School = {
      schoolName: schoolResponse.schoolName,
      schoolTitle: schoolResponse.schoolTitle,
      timezone: schoolResponse.timezone!,

      primaryColor: schoolResponse.primaryColor,
      logoUrl: schoolResponse.logo?.resourceUrl,

      scheduleType: schoolResponse.scheduleType as ScheduleType | undefined,
      dayTypes,
      periodTypes,
      lunchSettings,

      rotationPattern:
        (schoolResponse.rotationPattern as unknown as string[] | null) || [],
      schoolYears,
      schedules,
      courses,
    };

    return school;
  } catch (e) {
    if (e instanceof Error) {
      console.error('useSchool error:', e.message);
      throw e;
    }
    throw new Error("Couldn't fetch school data");
  }
};

export const useSchool = <T = School>(select?: (data: School) => T) => {
  const schoolId = useSchoolId();

  return useQuery<School, Error, T>({
    queryKey: ['school', schoolId],
    queryFn: () => fetchSchoolData(schoolId),
    select,
    retry: (count, error) => {
      if (error instanceof BlockingError) {
        return false;
      }

      return count < 3;
    },
    refetchOnWindowFocus: (query) =>
      !(query.state.error instanceof BlockingError),
  });
};
