import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { CoreSchedule } from 'types';

import StepNavigator from '../StepNavigator';
import { getLength, parseTime } from '../../utils';

interface Props {
  schedule: Partial<CoreSchedule>;
  onPrev: (update: Partial<CoreSchedule>) => void;
  onSubmit: (schedule: Partial<CoreSchedule>) => void;
}

const ScheduleReviewStep = ({ schedule, onPrev, onSubmit }: Props) => {
  const dayType = schedule.variant === 'core' ? schedule.dayType : undefined;

  const isValid = !!(
    dayType &&
    schedule.displayName &&
    schedule.periods?.length
  );

  const handleSubmit = () => {
    if (isValid) {
      onSubmit(schedule as CoreSchedule);
    }
  };

  if (!dayType) {
    return <M.Text color="red">Error - missing day type</M.Text>;
  }

  return (
    <Container>
      <M.Text align="center">
        Awesome! The schedule will be created as follows:
      </M.Text>

      <InfoContainer>
        <M.Text>
          <strong>Day type:</strong> {dayType.name}
        </M.Text>

        <M.Text>
          <strong>Display name:</strong> {schedule.displayName}
        </M.Text>

        <M.Text>
          <strong>Display in LSC:</strong> {schedule.isInLsc ? 'Yes' : 'No'}
        </M.Text>
      </InfoContainer>

      <M.Flex justify="center" gap="xl">
        <ReviewTable
          title="Periods"
          items={
            schedule.periods?.map((p) => ({
              type: p.name,
              start: p.startTime,
              end: p.endTime,
            })) ?? []
          }
        />

        {!!schedule.lunchWaves?.length && (
          <ReviewTable
            title="Lunch waves"
            items={schedule.lunchWaves.map((w) => ({
              type: w.name,
              start: w.startTime,
              end: w.endTime,
            }))}
          />
        )}
      </M.Flex>

      <StepNavigator
        isSubmit
        onPrev={() => onPrev({})}
        canGoNext={isValid}
        onNext={handleSubmit}
      />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const InfoContainer = styled(M.Flex)`
  flex-direction: column;
`;

interface ReviewTableProps {
  title: string;
  items: { type: string; start: string; end: string }[];
}

const ReviewTable = ({ title, items }: ReviewTableProps) => (
  <M.Flex direction="column">
    <M.Text align="center">
      <strong>{title}</strong>
    </M.Text>
    <M.Table w="fit-content">
      <tbody>
        {items.map(({ type, start, end }, i) => (
          <tr key={i}>
            <td>
              <strong>{type}</strong>
            </td>
            <td>{start}</td>
            <td>{end}</td>
            <td>({getLength([parseTime(start), parseTime(end)])} min)</td>
          </tr>
        ))}
      </tbody>
    </M.Table>
  </M.Flex>
);

export default ScheduleReviewStep;
