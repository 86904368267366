import React, { PureComponent } from 'react';
import { reportsActions } from 'store/actions/actions';
import { getColumns } from './ColumnFormatters';
import { connect } from 'react-redux';
import RemoteTable from 'components/shared/RemoteTable/RemoteTable';
import './UserReportsTable.scss';
import { ReportDetail } from 'components/shared/ReportDetail/ReportDetail';
import moderationService from 'services/moderation.service';
import { Popover } from 'react-bootstrap';

const defaultSorted = {
  dataField: 'created_at',
  order: 'desc',
};

class UserReportsTable extends PureComponent {
  columns;
  tableOptions;

  constructor(props) {
    super(props);

    this.getColumns = getColumns.bind(this);
    this.solveReport = this.solveReport.bind(this);

    this.state = {
      enableFilters: false,
      totalSize: 0,
      total_user_count: 0,
      unsolved_only: true,
      query_params: null,
      expanded: [],
    };

    this.tableOptions = {
      shouldDisplayCount: true,
      counterComponent: this.getCounts,
      hasToggle: true,
      toggleLabel: 'Only unsolved',
      onToggle: (unsolved_only) => {
        this.setState(
          (state) => ({
            ...state,
            query_params: {
              ...state.query_params,
              unsolved_only: unsolved_only,
            },
          }),
          () => {
            this.loadReports();
          },
        );
      },
      hasDropdown: true,
      hasSpecialAction: true,
      onSpecialActionClick: false,
      dropdownOptions: ['Chat', 'Chat Message', 'User Profile'],
      dropdownFilterField: 'report_type',
      searchPlaceholder: 'Search for any field...',
    };
  }

  static getDerivedStateFromProps(props, currentState) {
    return {
      ...currentState,
      totalSize: props.total || 0,
    };
  }

  loadReports() {
    this.props.dispatch(
      reportsActions.getUserProfileReports({ ...this.state.query_params }),
    );
  }

  handleTableChange = (params) => {
    this.setState(
      (state) => ({
        ...state,
        query_params: {
          ...state.query_params,
          ...params,
          filter_by: params.filter_by,
        },
      }),
      () => {
        this.loadReports();
      },
    );
  };

  getCounts = () => {
    return (
      <h1>
        <span className="table-schools-count">{this.state.totalSize}</span>
        Reports
      </h1>
    );
  };

  solveReport(report) {
    if (report.solved) {
      moderationService()
        .reOpenReport(report.target_id)
        .then(() => {
          this.loadReports();
        });
    } else {
      moderationService()
        .solveReport(report.target_id)
        .then(() => {
          this.loadReports();
        });
    }
  }

  renderDetailPopover(type, report, comments) {
    return (props) => {
      return (
        <Popover
          id="report-popover"
          className={type !== 'user_profile' ? 'wide' : ''}
          {...props}
          show={props.show.toString()}
        >
          <ReportDetail type={type} report={report} comments={comments} />
        </Popover>
      );
    };
  }

  render() {
    return (
      <div>
        <RemoteTable
          keyField="target_id"
          data={this.props.reports || []}
          getColumns={this.getColumns}
          options={this.tableOptions}
          loading={this.props.isLoadingProfileReports}
          defaultSorted={defaultSorted}
          refreshHandler={this.handleTableChange}
          total={this.state.totalSize}
          localFilter={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports.user_reports,
  isLoadingReports: state.reports.isLoadingReports,
  total: state.reports.total_reports,
});

export default connect(mapStateToProps)(UserReportsTable);
