import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { getDateFormat, getSortedSchedulesByType } from 'utils/utilities';

import IconScheduleTypeSelected from 'assets/icons/schedule_type_selected.svg';
import IconScheduleModified from 'assets/icons/schedule_type_modified.svg';
import IconChecked from 'assets/icons/checked.svg';

import {
  handleScheduleChange,
  regenerateRotationData,
  setDaySchedule,
} from './utils';

const SchedulePicker = ({ dayInfo, day, school, onRegenerate }) => {
  if (!school) {
    return <div>Loading...</div>;
  }

  if (!school.schedules.length) {
    return (
      <>
        <div className="popover-schedule-types-title">Schedule</div>
        <div style={{ marginTop: 10 }}>
          This school has no schedules defined yet
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="popover-schedule-types-title">Schedule</div>
      <div className="popover-schedule-types-content">
        {['static', 'modified', 'special'].map((type) => (
          <ScheduleGroup
            key={type}
            type={type}
            school={school}
            dayInfo={dayInfo}
            day={day}
            onRegenerate={onRegenerate}
          />
        ))}
      </div>
    </div>
  );
};

const ScheduleGroup = ({ type, school, dayInfo, day, onRegenerate }) => {
  const sortedSchedules = getSortedSchedulesByType(
    school,
    school.schedules,
    type,
  );

  return !sortedSchedules.length ? null : (
    <>
      <strong style={{ textTransform: 'capitalize' }}>{type}</strong>
      {sortedSchedules.map((schedule) => (
        <ScheduleRow
          key={schedule.id}
          schedule={schedule}
          school={school}
          dayInfo={dayInfo}
          day={day}
          onRegenerate={onRegenerate}
        />
      ))}
    </>
  );
};

const ScheduleRow = ({ schedule, school, dayInfo, day, onRegenerate }) => {
  const dispatch = useDispatch();

  return (
    <OverlayTrigger
      placement="right"
      delay={500}
      overlay={(overlayProps) => (
        <SchedulePopover popoverProps={overlayProps} schedule={schedule} />
      )}
    >
      <div
        className="popover-schedule-types-content-option"
        onClick={() => {
          handleScheduleChange(
            school,
            dayInfo,
            day,
            () => {
              setDaySchedule(school, dispatch, day, schedule.id);
            },
            () => {
              regenerateRotationData(
                onRegenerate,
                school,
                schedule.name,
                dayInfo.dateNow,
                schedule.special,
              );
            },
          );
        }}
      >
        <div className="popover-container-actions-item">
          <img
            src={
              schedule.special ? IconScheduleModified : IconScheduleTypeSelected
            }
            alt="icon_schedule"
          />
          <span className="popover-schedule-types-content-option-text-selected">
            {schedule.display_name ? schedule.display_name : schedule.name}
            {schedule.special && (
              <>
                {getDateFormat(
                  schedule.name.replace('#-', ''),
                  'MM-DD-YYYY',
                  'MM-DD-YYYY',
                ) && (
                  <span className="schedule-tag date">
                    {' '}
                    {getDateFormat(
                      schedule.name.replace('#-', ''),
                      'MM-DD-YYYY',
                      'MM-DD-YYYY',
                    )}
                  </span>
                )}
              </>
            )}
          </span>
        </div>
        {dayInfo?.schedule_id === schedule.id && (
          <img src={IconChecked} alt="icon_checked" />
        )}
      </div>
    </OverlayTrigger>
  );
};

const SchedulePopover = ({ popoverProps, schedule }) => (
  <Popover className="schedule-preview" {...popoverProps}>
    {schedule.periods?.map((period) => (
      <div key={period.id} className="schedule-period">
        <h5>
          {period.name}&nbsp;
          {period.lunch && (
            <span role="img" aria-label="Lunch">
              &#x1F354;
            </span>
          )}
        </h5>
        <div>
          {moment(period.start_time, 'HH:mm:ss').format('hh:mma')}
          <br />
          {moment(period.end_time, 'HH:mm:ss').format('hh:mma')}
        </div>
      </div>
    ))}
  </Popover>
);

export default SchedulePicker;
