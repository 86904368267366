import { zodResolver } from '@hookform/resolvers/zod';
import * as M from '@mantine/core';
import { useForm } from 'react-hook-form';
import { TextInput } from 'react-hook-form-mantine';
import { z } from 'zod';

const createTeacherModalSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

export const CreateTeacherModal = ({
  isOpen,
  onClose,
  mutate,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  mutate: (data: z.infer<typeof createTeacherModalSchema>) => Promise<void>;
  isLoading: boolean;
}) => {
  const { control, handleSubmit, reset } = useForm<
    z.infer<typeof createTeacherModalSchema>
  >({
    resolver: zodResolver(createTeacherModalSchema),
  });

  return (
    <M.Modal opened={isOpen} onClose={onClose} withCloseButton={false}>
      <M.Stack>
        <M.Title order={4}>Create teacher</M.Title>
        <TextInput
          control={control}
          name="firstName"
          label="First name"
          required
        />
        <TextInput
          control={control}
          name="lastName"
          label="Last name"
          required
        />

        <M.Flex justify="flex-end" mt="xl" gap="sm">
          <M.Button onClick={onClose} variant="outline">
            Cancel
          </M.Button>
          <M.Button
            onClick={handleSubmit(async (data) => {
              await mutate(data);
              onClose();
              reset();
            })}
            type="submit"
            loading={isLoading}
          >
            Save
          </M.Button>
        </M.Flex>
      </M.Stack>
    </M.Modal>
  );
};
