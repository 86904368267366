import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { Step } from 'features/scheduleConfiguration/types';

import WizardReviewStep from './WizardReviewStep';

interface Props {
  steps: Step[];
  index: number;
}

const WizardReview = ({ steps, index }: Props) => {
  return (
    <BorderGradient>
      <Container>
        <Title order={2}>Review</Title>
        <M.Stepper active={index} orientation="vertical">
          {steps.map((step) => (
            <M.Stepper.Step
              key={step.type}
              label={REVIEW_TITLES[step.type]}
              description={<WizardReviewStep step={step} />}
            />
          ))}
        </M.Stepper>
      </Container>
    </BorderGradient>
  );
};

const REVIEW_TITLES: Record<Step['type'], string> = {
  uploadImagesStep: 'Upload images',
  scheduleSettingsStep: 'Schedule settings',
  calendarSettingsStep: 'Calendar settings',
  coreSchedulesStep: 'Core schedules',
};

const BorderGradient = styled.div`
  padding: 3px;
  background: ${(p) => p.theme.colors.gray[3]};
  border-radius: ${(p) => p.theme.radius.md};
`;

const Container = styled(M.Flex)`
  min-width: 320px;
  max-width: 320px;
  height: 100%;
  padding: ${(p) => p.theme.spacing.xs};

  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xl};

  background: #fff;
  border-radius: ${(p) => p.theme.radius.sm};
`;

const Title = styled(M.Title)`
  text-align: center;
`;

export default WizardReview;
