import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { useState } from 'react';
import type { CoreSchedule } from 'types';

import StepNavigator from '../StepNavigator';

interface Props {
  schedule: Partial<CoreSchedule>;
  dayTypeName: string;
  onPrev?: (update: Partial<CoreSchedule>) => void;
  onNext: (update: Partial<CoreSchedule>) => void;
}

const ScheduleInfoStep = ({ schedule, dayTypeName, onPrev, onNext }: Props) => {
  const [displayName, setDisplayName] = useState(
    schedule.displayName ||
      dayTypeName.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase()),
  );
  const [isInLsc, setIsInLsc] = useState(
    schedule.isInLsc === false ? false : true,
  );

  return (
    <Container>
      <M.Title order={5}>Set up schedule information</M.Title>

      <M.TextInput
        label="Display name"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      />

      <M.Checkbox
        label="Display in LSC"
        checked={isInLsc}
        onChange={(e) => setIsInLsc(e.target.checked)}
      />

      <StepNavigator
        onPrev={onPrev ? () => onPrev({ displayName, isInLsc }) : undefined}
        canGoNext={displayName.length > 0}
        onNext={() => onNext({ displayName, isInLsc })}
      />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

export default ScheduleInfoStep;
