import { useRouteMatch } from 'react-router-dom';

export type ScheduleDetailMode = 'create' | 'edit' | 'copy';

export function useScheduleDetailParams() {
  const {
    params: { mode, id },
  } = useRouteMatch<{ mode?: ScheduleDetailMode; id?: string }>();

  if (mode === 'create') {
    return { mode };
  }

  if (!id) {
    throw new Error('Missing params');
  }

  if (mode === 'copy' || mode === 'edit') {
    return { mode, id };
  }

  throw new Error('Schedule detail route mismatch');
}
