import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const getLength = (range: [Date, Date]) =>
  Math.round((range[1].getTime() - range[0].getTime()) / 1000 / 60);

export const formatToTime = (date: Date) => dayjs(date).format('hh:mm a');

export const parseTime = (time: string) => dayjs(time, 'hh:mm a').toDate();

export const formatResponseToTime = (time: string) =>
  dayjs(time, 'HH:mm:ss').format('hh:mm a');

interface DayJsInterval {
  start: Dayjs;
  end: Dayjs;
}
const compareDayJsInterval = (a: DayJsInterval, b: DayJsInterval) =>
  a.end.isSame(b.end) && a.start.isSame(b.start);

export const getHasOverlap = (
  times: { start: Dayjs; end: Dayjs }[],
  onlyCheckLast?: boolean,
) => {
  const lastItem = times.at(-1);

  return times
    .sort((a, b) => a.start.diff(b.start))
    .filter((_, i, arr) => {
      if (!onlyCheckLast || !lastItem) return true;
      return (
        arr.findIndex((x) => compareDayJsInterval(x, lastItem)) >= i - 1 &&
        arr.findIndex((x) => compareDayJsInterval(x, lastItem)) <= i + 1
      );
    })
    .map(({ start, end }) => ({
      start: start.get('hours') * 60 + start.get('minutes'),
      end: end.get('hours') * 60 + end.get('minutes'),
    }))
    .some(
      (time, i, arr) => i !== arr.length - 1 && time.end > arr[i + 1].start,
    );
};
