import { put, call, takeLatest } from 'redux-saga/effects';
import miscService from 'services/misc.service';
import { ActionTypes } from '../actions/misc';

function filterFeatures(features, status) {
  let filtered = [];
  if (features) {
    filtered = features.filter(
      (feature) => feature.properties.status === status,
    );
  }
  return {
    type: 'FeatureCollection',
    features: filtered,
  };
}

function* getSchoolsGeoJSON({ params }) {
  try {
    const geojson_response = yield call(
      miscService().getSchoolsGeoJSON,
      params,
    );
    if (geojson_response.status === 200) {
      const data = geojson_response.data;

      yield put({
        type: ActionTypes.GET_GEOJSON_SUCCESS,
        features: {
          unlaunched: filterFeatures(data.map_features, 'unlaunched'),
          suspended: filterFeatures(data.map_features, 'suspended'),
          waitlist: filterFeatures(data.map_features, 'waitlist'),
          live: filterFeatures(data.map_features, 'live'),
        },
        stats: [
          { name: 'Schools', value: data.total_schools },
          { name: 'Installs', value: data.total_installs },
          { name: 'Live', value: data.total_live },
          { name: 'Waitlist', value: data.total_waitlist },
          { name: 'Unlaunched', value: data.total_unlaunched },
          { name: 'Suspended', value: data.total_suspended },
        ],
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_GEOJSON_FAIL, error });
  }
}

function* getEmojis() {
  try {
    const emojis_response = yield call(miscService().getEmojis);
    if (emojis_response.status === 200) {
      const emojis = emojis_response.data;

      yield put({
        type: ActionTypes.GET_EMOJIS_SUCCESS,
        emojis: emojis,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMOJIS_FAIL, error });
  }
}

export default [
  takeLatest(ActionTypes.GET_GEOJSON, getSchoolsGeoJSON),
  takeLatest(ActionTypes.GET_EMOJIS, getEmojis),
];
