// https://www.regextester.com/94502
export const URL_VALIDATOR = (values) => {
  // eslint-disable-next-line
  return values.every((value) => {
    const regex =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    return regex.test(value);
  });
};

export const DOMAIN_VALIDATOR = (values) => {
  return values.every((value) => {
    const regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
    return regex.test(value);
  });
};
