import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './SchoolsGridTable.scss';
import { schoolsActions } from 'store/actions/actions';
import { hasAccessTo } from 'store/selectors/auth';
import { getColumns } from './ColumnFormatters';
import RemoteTable from 'components/shared/RemoteTable/RemoteTable';
import EditSchoolModal from 'components/modals/EditSchoolModal/EditSchoolModal';
import { statuses } from 'constants/statuses';

const SchoolsGridTable = ({
  onCreateNewSchoolClickHandler,
  onFileUploadClickHandler,
}) => {
  const [clickedFilterValue, setClickedFilterValue] = useState('');
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [queryParams, setQueryParams] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const {
    schools,
    total,
    totalUserCount,
    isLoadingSchools,
    isOwner,
    isSchoolManager,
  } = useSelector((state) => ({
    schools: state.schools.schools,
    tags: state.schools.tags,
    totalUserCount: state.schools.total_user_count,
    total: state.schools.total,
    isLoadingSchools: state.schools.isLoadingSchools,
    isOwner: hasAccessTo(state.auth.scopes, ['owner']),
    isSchoolManager: hasAccessTo(state.auth.scopes, ['school_management']),
  }));

  const dispatch = useDispatch();

  const tableOptions = {
    hasToggle: false,
    hasSearch: true,
    searchPlaceholder: 'Search for any field...',
    onCreateButtonClick: isSchoolManager && onCreateNewSchoolClickHandler,
    shouldDisplayCount: true,
    onFileUploadButtonClick: onFileUploadClickHandler,
    hasDropdown: true,
    dropdownOptions: statuses.map((st) => st.name),
    dropdownSelectedOption: 'Live',
    dropdownFilterField: 'status',
    counterComponent: () => {
      if (!isOwner) {
        return null;
      }
      return (
        <h1>
          <span className="table-schools-count">{total}</span>
          Schools &nbsp;
          <span className="table-schools-count">{totalUserCount}</span>
          Installs
        </h1>
      );
    },
  };

  useEffect(() => {
    if (queryParams) {
      loadSchools();
    }
  }, [queryParams]);

  const loadSchools = () =>
    dispatch(schoolsActions.getSchools({ ...queryParams }));

  return (
    <div>
      {selectedSchool && showEditModal && (
        <EditSchoolModal
          hide={() => {
            setShowEditModal(false);
            setSelectedSchool(null);
          }}
          school={selectedSchool}
        />
      )}

      <RemoteTable
        keyField="school_name"
        itemName="School"
        options={tableOptions}
        data={schools || []}
        clickedFilterValue={clickedFilterValue}
        setClickedFilterValue={setClickedFilterValue}
        getColumns={() =>
          getColumns({
            isOwner,
            isSchoolManager,
            onDistrictClick: setClickedFilterValue,
            onDomainClick: setClickedFilterValue,
            onActionsClick: (row) => {
              setSelectedSchool(row);
              setShowEditModal(true);
            },
          })
        }
        loading={isLoadingSchools}
        defaultSorted={{
          dataField: 'school_title',
          order: 'asc',
          filter_by: 'status:eq:live',
        }}
        refreshHandler={setQueryParams}
        total={total}
      />
    </div>
  );
};

export default SchoolsGridTable;
