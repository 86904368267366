import { useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { CoreSchedule, DayType, PeriodType, Period } from 'types';

import PeriodsBuilder from '../periodsBuilder/PeriodsBuilder';
import StepNavigator from '../StepNavigator';
import { parseTime, getHasOverlap } from '../../utils';

interface Props {
  schedule: Partial<CoreSchedule>;
  dayType: DayType;
  periodTypes: PeriodType[];
  onPrev: (update: Partial<CoreSchedule>) => void;
  onNext: (update: Partial<CoreSchedule>) => void;
}

const SchedulePeriodsStep = ({
  schedule,
  dayType,
  periodTypes,
  onPrev,
  onNext,
}: Props) => {
  const [periods, setPeriods] = useState<Period[]>(schedule.periods || []);
  const hasOverlap = getHasOverlap(
    periods.map((p) => ({
      start: dayjs(parseTime(p.startTime)),
      end: dayjs(parseTime(p.endTime)),
    })),
  );

  const hasStartAfterEnd = periods.some((p) =>
    dayjs(parseTime(p.startTime)).isAfter(dayjs(parseTime(p.endTime))),
  );

  return (
    <Container>
      <M.Title order={5}>Set up periods</M.Title>

      <PeriodsBuilder
        dayTypes={[dayType]}
        defaultDayType={dayType}
        schedule={{ ...schedule, isInAcf: true }}
        periodTypes={periodTypes}
        periods={periods}
        setPeriods={setPeriods}
      />

      <StepNavigator
        onPrev={() => onPrev({ periods })}
        canGoNext={!!periods.length && !hasOverlap && !hasStartAfterEnd}
        onNext={() => onNext({ periods })}
      />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

export default SchedulePeriodsStep;
