import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { Period, DayType, PeriodType, Schedule } from 'types';

import { periodUtils } from 'features/schedules/utils/period';
import { getLength, parseTime } from 'features/editSchedule/utils';

import AddPeriodConfiguration from './AddPeriodConfiguration';
import EditPeriodPopoverDropdown from './EditPeriodPopoverDropdown';
import { useCustomPeriodNames } from './useCustomPeriodTypes';

import EditableTimesTable from '../EditableTimesTable';
import { PeriodsBuilderHelp } from './Help';

const CUSTOM_DAY_TYPE_LABEL = 'CUSTOM';
export const CUSTOM_DAY_TYPE: DayType = {
  id: '',
  name: CUSTOM_DAY_TYPE_LABEL,
};

export type PeriodsBuilderProps = {
  defaultDayType?: DayType;
  dayTypes: DayType[];
  periodTypes: PeriodType[];
  periods: Period[];
  setPeriods: React.Dispatch<React.SetStateAction<Period[]>>;
  schedule: Partial<Schedule>;
};

const PeriodsBuilder = ({
  periodTypes,
  periods,
  setPeriods,
  defaultDayType,
  dayTypes,
  schedule,
}: PeriodsBuilderProps) => {
  const { customPeriodNames, setCustomPeriodNames } = useCustomPeriodNames({
    periods,
  });

  const [editIndex, setEditIndex] = useState(-1);

  const getIsOverlapping = (i: number) => {
    const isOverlappingBefore =
      periods[i - 1] &&
      parseTime(periods[i].startTime) < parseTime(periods[i - 1].endTime);
    const isOverlappingAfter =
      periods[i + 1] &&
      parseTime(periods[i].endTime) > parseTime(periods[i + 1].startTime);

    return isOverlappingBefore || isOverlappingAfter;
  };

  return (
    <Container>
      <AddPeriodConfiguration
        periodTypes={periodTypes}
        periods={periods}
        setPeriods={setPeriods}
        defaultDayType={defaultDayType}
        dayTypes={dayTypes}
        schedule={schedule}
        customPeriodNames={customPeriodNames}
        setCustomPeriodNames={setCustomPeriodNames}
      />

      <M.Divider />

      <EditableTimesTable
        descriptionHeaders={['Day type', 'Period type']}
        descriptionData={periods.map((p) => [
          periodUtils.isCustomPeriod(p)
            ? CUSTOM_DAY_TYPE_LABEL
            : p.dayType.name,
          p.name,
        ])}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        getStartTime={(i) => periods[i].startTime}
        getEndTime={(i) => periods[i].endTime}
        getIsCustom={(i) => !periods[i].periodType?.id}
        getDuration={(i) =>
          `${getLength([
            parseTime(periods[i].startTime),
            parseTime(periods[i].endTime),
          ])} min`
        }
        getIsOverlapping={getIsOverlapping}
        onRemove={(index) =>
          setPeriods((prev) => prev.filter((_, i) => i !== index))
        }
        popoverDropdown={
          <EditPeriodPopoverDropdown
            periodTypes={periodTypes}
            periods={periods}
            setPeriods={setPeriods}
            dayTypes={dayTypes}
            schedule={schedule}
            customPeriodNames={customPeriodNames}
            setCustomPeriodNames={setCustomPeriodNames}
            editIndex={editIndex}
          />
        }
      />
      <PeriodsBuilderHelp
        hasCustomPeriods={periods.some(periodUtils.isCustomPeriod)}
        hasOverlappingPeriods={periods.some((_, i) => getIsOverlapping(i))}
      />
      {editIndex !== -1 && <M.Overlay color="#000" />}
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

export default PeriodsBuilder;
