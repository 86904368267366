import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import FixedDatesBuilder from 'components/shared/Fields/FixedDatesBuilder/FixedDatesBuilder';
import { WizardNavigation } from '../../common/WizardNavigation';

export const FixedDatesStep = ({
  form,
  updateForm,
  previousStep = undefined,
  nextStep = undefined,
  onClose,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { fixed_dates: form.fixed_dates || [] },
  });

  return (
    <form
      onSubmit={handleSubmit(updateForm)}
      className="wizard-form"
      autoComplete="name"
    >
      <h5>Step 2: Fixed dates (modified/special)</h5>
      <hr />

      <Controller
        name="fixed_dates"
        control={control}
        defaultValue={[]}
        rules={{
          required: false,
        }}
        render={({ field }) => (
          <>
            <FixedDatesBuilder
              startDate={form.start_date}
              endDate={form.end_date}
              updateField={field.onChange}
              value={field.value}
            />
            {errors.fixed_dates && (
              <div className="invalid-feedback">
                {errors.fixed_dates.message}
              </div>
            )}
          </>
        )}
      />

      <hr />
      <WizardNavigation
        step={2}
        dynamicSteps
        previousStep={previousStep}
        nextStep={() => isValid && nextStep()}
        onClose={onClose}
      />
    </form>
  );
};
