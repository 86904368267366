import { put, call, takeLatest } from 'redux-saga/effects';

import authService from 'services/auth.service';
import {
  parseJwt,
  getToken,
  validateToken,
  saveTokenUser,
  removeToken,
} from 'utils/utilities';

import { ActionTypes } from '../actions/auth';
import { hasAccessTo } from '../selectors/auth';

function* verifyToken({ idToken, provider }) {
  try {
    const response = yield call(authService().verify, {
      token: idToken,
      provider,
    });

    if (response.status >= 200 && response.status < 202) {
      const token = response.data.access_token;
      const refresh_token = response.data.refresh_token;
      if (validateToken(token)) {
        const profile_response = yield call(authService().getProfile, {
          token,
        });

        if (profile_response.status === 200) {
          const scopes = new Set(parseJwt(token).scopes.split(' '));

          if (hasAccessTo(scopes, ['dashboard'])) {
            saveTokenUser(token, refresh_token);
            yield put({
              type: ActionTypes.AUTH_VERIFY_TOKEN_SUCCESS,
              token,
              scopes,
            });
            const user = profile_response.data;
          } else {
            alert('Access denied.');
            throw Error("You don't have permission to access the dashboard");
          }
        }
      } else {
        throw Error('Invalid token');
      }
    } else {
      alert(`You don't have access: ${response.data.code}`);
    }
  } catch (e) {
    const reason = e.response.data?.detail?.code || 'unknown reason';
    alert(
      `You don't have access. Reason: ${reason}\n\nAre you sure you enabled permissions via Retool?\n\nhttps://joinsaturn.retool.com/apps/c3424774-b52f-11ee-94b1-0bea85df5dcf/Grant%20dashboard%20access`,
    );
    yield put({ type: ActionTypes.AUTH_VERIFY_TOKEN_FAIL, error: e });
  }
}

function* validateUserAuth() {
  const token = getToken();
  if (validateToken(token)) {
    try {
      const response = yield call(authService().getProfile, { token });
      if (response.status === 200) {
        const decoded = parseJwt(token);
        yield put({
          type: ActionTypes.AUTH_VALIDATE_USER_AUTH_SUCCESS,
          token,
          scopes: new Set(decoded.scopes.split(' ')),
          schoolId: decoded.school_id,
        });
      }
    } catch (error) {
      yield put({
        type: ActionTypes.AUTH_VALIDATE_USER_AUTH_FAIL,
        error: "Can't validate user permissions",
      });
    }
  } else {
    yield put({
      type: ActionTypes.AUTH_VALIDATE_USER_AUTH_FAIL,
      error: 'token not present',
    });
  }
}

function* logout() {
  try {
    removeToken();
    yield put({ type: ActionTypes.AUTH_USER_LOGOUT_SUCCESS });
  } catch (e) {
    yield put({ type: ActionTypes.AUTH_USER_LOGOUT_FAIL, error: e });
  }
}

export default [
  takeLatest(ActionTypes.AUTH_VERIFY_TOKEN, verifyToken),
  takeLatest(ActionTypes.AUTH_VALIDATE_USER_AUTH, validateUserAuth),
  takeLatest(ActionTypes.AUTH_USER_LOGOUT, logout),
];
