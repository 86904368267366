import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { TimeInput } from 'features/components/TimeInput';

import { formatToTime, parseTime } from '../utils';

interface Time {
  startTime: string;
  endTime: string;
}

interface Props<T extends Time> {
  times: Time[];
  setTimes: React.Dispatch<React.SetStateAction<T[]>>;
  editIndex: number;
}

const getIsStartAfterEnd = (value: [Date, Date]) =>
  dayjs(value[0]).isAfter(dayjs(value[1]));

const EditTimeRange = <T extends Time>({
  times,
  setTimes,
  editIndex,
}: Props<T>) => {
  const [[localStartTime, localEndTime], setLocalTimes] = useState<
    [Date, Date]
  >([
    parseTime(times[editIndex].startTime),
    parseTime(times[editIndex].endTime),
  ]);

  useEffect(() => {
    return () => {
      setTimes((prev) =>
        prev.sort(
          (a, b) =>
            parseTime(a.startTime).getTime() - parseTime(b.startTime).getTime(),
        ),
      );
    };
  }, [setTimes]);

  const handleEdit = (value: [Date, Date]) => {
    setLocalTimes(value);

    if (getIsStartAfterEnd(value)) {
      return;
    }

    setTimes((prev) =>
      prev.map((p, i) =>
        i === editIndex
          ? {
              ...p,
              startTime: formatToTime(value[0]),
              endTime: formatToTime(value[1]),
            }
          : p,
      ),
    );
  };

  const isStartAfterEnd = getIsStartAfterEnd([localStartTime, localEndTime]);

  return (
    <>
      <TimeInput
        label="Start time"
        error={isStartAfterEnd}
        value={localStartTime}
        onChange={(val) => handleEdit([val, localEndTime])}
      />
      <TimeInput
        label="End time"
        error={isStartAfterEnd}
        value={localEndTime}
        onChange={(val) => handleEdit([localStartTime, val])}
      />

      {isStartAfterEnd && <Error>Start time must be before end time</Error>}
    </>
  );
};

const Error = styled(M.Text)`
  text-align: center;
  color: ${(p) => p.theme.colors.red[6]};
`;

export default EditTimeRange;
