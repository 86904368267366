import { useGetParseResults } from 'features/scheduleConfiguration/hooks/queries';
import { ParsedSchedule } from 'features/scheduleConfiguration/utils';
import { useSchool } from 'hooks/useSchool';
import { useSchoolId } from 'hooks/useSchoolId';
import ParsedResultsRow from '../ParsedResultsRow';
import * as MN from '@mantine/notifications';
import * as M from '@mantine/core';

type Props = {
  selectedRowId?: string;
  onSelectRow: (row?: ParsedSchedule) => void;
  enableRefetching: boolean;
};

const REFETCH_INTERVAL_MS = 3000;

export const ParsedImagePicker = ({
  onSelectRow,
  selectedRowId,
  enableRefetching,
}: Props) => {
  const { data: lunchSettings } = useSchool((school) => school.lunchSettings);

  const schoolId = useSchoolId();
  const { data: rawParseResults, isLoading } = useGetParseResults(schoolId, {
    // while the schedule parsing is in progress, refetch every 3 seconds
    refetchInterval: (data, { state: { error } }) =>
      !error && data?.some((d: ParsedSchedule) => !d.isComplete)
        ? REFETCH_INTERVAL_MS
        : false,
    enabled: enableRefetching,
  });

  const parseResults = rawParseResults?.map((parsedSchedule) =>
    lunchSettings?.type !== 'waves'
      ? parsedSchedule
      : {
          ...parsedSchedule,
          periods: parsedSchedule.periods.filter((p) => p.name !== 'Lunch'),
        },
  );

  if (isLoading) return <M.LoadingOverlay visible />;

  if (!parseResults?.length) return <M.Text>No schedules found</M.Text>;

  return (
    <>
      {parseResults.map((parsedSchedule, i) => (
        <ParsedResultsRow
          key={i}
          parsedSchedule={parsedSchedule}
          isSelected={parsedSchedule.id === selectedRowId}
          onClick={() => {
            if (parsedSchedule.isComplete) {
              onSelectRow(
                parsedSchedule.id === selectedRowId
                  ? undefined
                  : parsedSchedule,
              );
            } else {
              MN.showNotification({
                message:
                  'Please wait for the image to finish processing before selecting this schedule.',
              });
            }
          }}
        />
      ))}
    </>
  );
};
