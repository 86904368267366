import React, { Component } from 'react';
import Switch from 'react-switch';
import './SwitchField.scss';

export default class SwitchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.field.currentValue
        ? this.props.field.currentValue
        : false,
    };
    this.updateValue = this.updateValue.bind(this);

    const { initialize = true } = props;
    if (initialize) {
      this.updateValue(this.state.isChecked);
    }
  }

  updateValue(newValue) {
    this.props.field.currentValue = newValue;
    this.props.updateParent(newValue, this.props.field);
  }

  renderLabel = () => {
    return (
      <div className="switch-field-label">
        <b>{this.props.field.name}</b>
      </div>
    );
  };

  render() {
    const labelField = this.props.field.isShowLabel ? this.renderLabel() : null;
    const { visible = true } = this.props.field;

    return visible ? (
      <div className="switch-field">
        {labelField}
        <div className="switch-field-content">
          <Switch
            checked={this.state.isChecked}
            onChange={(value) => {
              this.updateValue(value);
              this.setState({
                isChecked: value,
              });
            }}
            onColor="#ea3f58"
            onHandleColor="#ea3f58"
            handleDiameter={this.props.small ? 20 : 30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={this.props.small ? 15 : 20}
            width={this.props.small ? 36 : 48}
            className="react-switch"
          />
        </div>
        <div className="switch-field-disclaimer">
          {this.props.field.disclaimer}
        </div>
      </div>
    ) : null;
  }
}
