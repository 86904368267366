import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import Modal from 'features/components/Modal';
import type {
  FilterTypes,
  ColumnKey,
  AddFilter,
  RemoveFilter,
} from 'features/draftSchedules/types';

import FilterChip from './FilterChip';

type Props = {
  filters: FilterTypes;
  addFilter: AddFilter;
  removeFilter: RemoveFilter;
};

const Filters = ({ filters, addFilter, removeFilter }: Props) => {
  const [filterValue, setFilterValue] = useState<string>();
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

  useEffect(() => {
    if (!isFilterModalVisible) {
      setFilterValue(undefined);
    }
  }, [isFilterModalVisible]);

  const submit = () => {
    if (!filterValue) return;

    addFilter({
      key: 'school_id',
      op: 'eq',
      value: filterValue,
    });
    setIsFilterModalVisible(false);
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && submit();

  return (
    <Container>
      {isFilterModalVisible && (
        <Modal
          opened
          sx={{ marginTop: '100px' }}
          title="Add a filter"
          onClose={() => setIsFilterModalVisible(false)}
        >
          <M.Flex direction="column" gap="md">
            <M.TextInput
              autoFocus
              label="School ID"
              placeholder="Enter a value"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              onKeyDown={submitOnEnter}
            />

            <M.Button disabled={!filterValue} onClick={submit}>
              Add
            </M.Button>
          </M.Flex>
        </Modal>
      )}
      <M.Flex gap="xs" wrap="wrap">
        {Object.entries(filters).map(([key, filter]) => (
          <FilterChip
            key={key as ColumnKey}
            filterKey={key as ColumnKey}
            filter={filter}
            onClick={() => removeFilter({ key: key as ColumnKey })}
          />
        ))}
      </M.Flex>
      <M.Button onClick={() => setIsFilterModalVisible(true)}>
        Add Filter...
      </M.Button>
    </Container>
  );
};

const Container = styled(M.Flex)`
  justify-content: space-between;
  align-items: center;
`;

export default Filters;
