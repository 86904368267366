import type { ScheduleType } from 'types';

import type { LunchType, NamingFormat } from './types';

export const NAMING_FORMAT = {
  alphabetic: {
    label: 'Alphabetic (A, B, C...)',
    options: new Array(10)
      .fill(null)
      .map((_, i) => String.fromCharCode(65 + i)),
    isDynamic: true,
  },
  numeric: {
    label: 'Numeric (1, 2, 3...)',
    options: new Array(10).fill(null).map((_, i) => `${i + 1}`),
    isDynamic: true,
  },
  weekdays: {
    label: 'Weekdays (M-F)',
    options: ['M', 'T', 'W', 'TH', 'F'],
    isDynamic: false,
  },
  a_b: { label: 'A/B', options: ['A', 'B'], isDynamic: false },
  regular: { label: 'Regular', options: ['REGULAR'], isDynamic: false },
  odd_even: { label: 'Odd/Even', options: ['ODD', 'EVEN'], isDynamic: false },
  one_two: { label: '1/2', options: ['1', '2'], isDynamic: false },
  other: { label: 'Other', options: [''], isDynamic: false },
} as const;

export const SCHEDULE_TYPE_NAMING_FORMATS: Readonly<
  Record<ScheduleType, NamingFormat[]>
> = {
  multiple_day_rotating: ['alphabetic', 'numeric', 'other'],
  same_every_day: ['regular'],
  same_every_day_plus: ['regular'],
  a_b: ['a_b', 'one_two', 'odd_even', 'other'],
  a_b_plus: ['a_b', 'one_two', 'odd_even', 'other'],
  monday_through_friday: ['weekdays'],
};

export const LUNCH_TYPES: { [Key in LunchType]: string } = {
  unit: 'Unit lunch',
  class: 'Lunch as a class',
  waves: 'Lunch waves',
};
