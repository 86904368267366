import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import {
  handleScheduleChange,
  regenerateRotationData,
  setDaySchedule,
} from './utils';
import { ScheduleNameBuilder } from '../ScheduleNameBuilder/ScheduleNameBuilder';

const ScheduleNamePicker = ({ dayInfo, day, school, onRegenerate }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="popover-schedule-types-title">
        Set custom schedule code
      </div>
      <ScheduleCodePicker
        date={day}
        dayTypes={school?.day_types}
        onAdd={({ schedule, isSpecial }) => {
          handleScheduleChange(
            school,
            dayInfo,
            day,
            () => {
              setDaySchedule(school, dispatch, day, 0, schedule);
            },
            () => {
              regenerateRotationData(
                onRegenerate,
                school,
                schedule,
                dayInfo.dateNow,
                isSpecial,
              );
            },
          );
        }}
      />
    </div>
  );
};

const ScheduleCodePicker = ({ dayTypes, date, onAdd }) => {
  const [schedule, setSchedule] = useState(undefined);
  const [isSpecial, setIsSpecial] = useState(false);

  return (
    <div className="multifield-row">
      <ScheduleNameBuilder
        allowStatic
        dayTypes={dayTypes}
        selectedDate={date}
        onChange={({ special, schedule }) => {
          setSchedule(schedule);
          setIsSpecial(special);
        }}
      />
      <div className="form-action">
        <Button
          onClick={() => onAdd({ schedule, isSpecial })}
          disabled={!schedule}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default ScheduleNamePicker;
