import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { LoadingError } from 'components/shared/LoadingError/LoadingError';
import BuildCalendarModal from 'components/modals/BuildCalendarModal/BuildCalendarModal';
import { extractCurrentCalData } from 'utils/utilities';

import CalendarNavBar from './CalendarNavBar/CalendarNavBar';
import CalendarDay from './CalendarDay';

import './SaturnCalendar.scss';

const SaturnCalendar = () => {
  const [month, setMonth] = useState(new Date());
  const [isBuilderVisible, setIsBuilderVisible] = useState(false);
  const [buildFromStart, setBuildFromStart] = useState(false);
  const [rotationData, setRotationData] = useState({
    start_date: undefined,
    end_date: undefined,
    locked_weekdays: undefined,
    rotation_start: undefined,
    fixed_dates: undefined,
  });

  const { school, isLoadingCurrentSchool, isUpdatingSchedule, updatingDay } =
    useSelector((state) => ({
      school: state.schools.currentSchool,
      isLoadingCurrentSchool: state.schools.isLoadingCurrentSchool,
      isUpdatingSchedule: state.schools.isUpdatingSchedule,
      updatingDay: state.schools.updatingDay,
    }));

  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const regenFrom = params.get('regenFrom');
    const desiredScheduleId = params.get('desiredScheduleId');

    if (school && regenFrom && desiredScheduleId) {
      const scheduleCode = school.schedules.find(
        (s) => s.id === desiredScheduleId,
      ).name;

      const newRotationData = extractCurrentCalData(
        school.calendar?.mapping || [],
        scheduleCode,
        regenFrom,
        school.rotation_pattern,
        school.schedule_type,
      );

      setRotationData(newRotationData);
      setIsBuilderVisible(true);
      setBuildFromStart(false);
    }
  }, [school, search]);

  if (school?.errors.has('calendar')) {
    return <LoadingError errors={school.errors} />;
  }

  return (
    <div className="saturn-calendar">
      {isBuilderVisible && (
        <BuildCalendarModal
          school={school}
          hide={() => {
            setIsBuilderVisible(false);
            history.replace({ search: '' });
          }}
          step={buildFromStart ? 1 : 3}
          rotationData={rotationData}
          regenerate
        />
      )}

      <DayPicker
        showOutsideDays
        modifiers={{ today: new Date() }}
        initialMonth={month}
        month={month}
        renderDay={(day) => (
          <CalendarDay
            school={school}
            day={day}
            loading={isLoadingCurrentSchool}
            updating={isUpdatingSchedule}
            updatingDay={updatingDay}
            onRegenerate={(newRotationData, buildFromStart) => {
              setRotationData(newRotationData);
              setIsBuilderVisible(true);
              setBuildFromStart(buildFromStart);
            }}
          />
        )}
        onMonthChange={setMonth}
        enableOutsideDaysClick={false}
        captionElement={({ date, localeUtils }) => (
          <CalendarNavBar
            date={date}
            localeUtils={localeUtils}
            onChange={setMonth}
            onTodayClick={() => setMonth(new Date())}
          />
        )}
      />
    </div>
  );
};

export default SaturnCalendar;
