import { confirmAlert } from 'react-confirm-alert';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import schoolsService from 'services/schools.service';
import Select from 'react-select';
import './GenericConfirmModal.scss';

export const renderConfirm = ({
  title,
  body,
  cancelText,
  confirmText,
  onConfirm,
  onCancel = () => {},
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <GenericConfirmModal
          title={title}
          body={body}
          cancelText={cancelText}
          confirmText={confirmText}
          onCancel={() => {
            onClose();
            if (onCancel) {
              onCancel();
            }
          }}
          onConfirm={() => {
            onClose();
            onConfirm();
          }}
          onClose={onClose}
        />
      );
    },
  });
};

export const GenericConfirmModal = ({
  title,
  body,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
}) => {
  return (
    <Modal
      className="confirmation-dialog"
      show
      backdrop="static"
      onHide={onClose}
      backdropClassName="confirmation-dialog-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {cancelText && (
          <Button variant="danger" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        <Button variant="success" className="success" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const renderApprove = ({
  title,
  selected_id,
  similar,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  entityType,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ApproveModal
          entityType={entityType}
          title={title}
          similar={similar}
          selected_id={selected_id}
          cancelText={cancelText}
          confirmText={confirmText}
          onCancel={() => {
            if (onCancel) {
              onCancel();
            }
            onClose();
          }}
          onConfirm={(id, override, override_ids) => {
            onConfirm(id, override, override_ids);
            onClose();
          }}
          onClose={onClose}
        />
      );
    },
  });
};

export const ApproveModal = ({
  title,
  selected_id,
  similar,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
  entityType,
}) => {
  const [override, setOverride] = useState(false);
  const [id, setId] = useState(selected_id);
  const [overrideIds, setOverrideIds] = useState(
    similar.filter((x) => x.id !== selected_id && x.suggested === true),
  );

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell) => {
        return <div>{cell}</div>;
      },
      headerStyle: () => {
        return { width: '50%' };
      },
    },
    {
      dataField: 'suggested',
      formatter: (cell, row) => (
        <span
          className={`suggested-badge ${
            row.suggested ? 'missing' : 'existing'
          }`}
        >
          {row.suggested ? 'Missing' : 'Existing'}
        </span>
      ),
      text: 'Status',
      headerStyle: () => {
        return { width: '20%', textAlign: 'center' };
      },
      align: 'center',
    },
    {
      dataField: 'score',
      formatter: (cell) => {
        return cell || '';
      },
      text: 'Match %',
      align: 'center',
      headerStyle: () => {
        return { width: '20%', textAlign: 'center' };
      },
    },
  ];

  return (
    <Modal show backdrop="static" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <p>
            There are {similar.length} entries with similar names. What do you
            want to do?
          </p>
        </div>
        <Form>
          <div className="mb-3">
            <Form.Check
              type="radio"
              label={`Ignore potential duplicates and add as a new ${entityType} (press confirm to finish)`}
              id="ignore-similar"
              checked={!override}
              onChange={() => {
                setOverride(false);
              }}
            />
            <div className="row divider">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto">or</div>
              <div className="col">
                <hr />
              </div>
            </div>
            <Form.Check
              type="radio"
              id="override-similar"
              label="Replace all user reports with:"
              checked={override}
              onChange={() => {
                setOverride(true);
              }}
            />
          </div>
          {override && (
            <div>
              <Form.Group controlId="choice-table">
                <BootstrapTable
                  hover
                  keyField="id"
                  data={similar}
                  columns={columns}
                  selectRow={{
                    mode: 'radio',
                    clickToSelect: true,
                    onSelect: (row) => {
                      setId(row.id);
                      setOverrideIds(
                        similar.filter(
                          (x) => x.id !== row.id && x.suggested === true,
                        ),
                      );
                    },
                    selected: [id],
                  }}
                />
              </Form.Group>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="success" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          variant="success"
          className="success"
          onClick={() => {
            onConfirm(
              id,
              override,
              override ? overrideIds.map((x) => x.id) : [],
            );
          }}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const renderReject = ({
  title,
  resource,
  resource_type,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <RejectModal
          title={title}
          resource={resource}
          resource_type={resource_type}
          cancelText={cancelText}
          confirmText={confirmText}
          onCancel={() => {
            if (onCancel) {
              onCancel();
            }
            onClose();
          }}
          onConfirm={(override_id) => {
            onConfirm(override_id);
            onClose();
          }}
          onClose={onClose}
        />
      );
    },
  });
};

export const RejectModal = ({
  title,
  resource,
  resource_type,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const [override, setOverride] = useState(false);
  const [overrideId, setOverrideId] = useState(null);

  return (
    <Modal show backdrop="static" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <p>What you want to do?</p>
        </div>
        <Form>
          <div className="mb-3">
            <Form.Check
              type="radio"
              label={`Delete report (will remove ${resource_type} from all classes)`}
              id="ignore-similar"
              checked={!override}
              onChange={() => {
                setOverride(false);
              }}
            />
            <div className="row divider">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto">or</div>
              <div className="col">
                <hr />
              </div>
            </div>
            <Form.Check
              type="radio"
              id="override-similar"
              label={`Replace with an existing ${resource_type}:`}
              checked={override}
              onChange={() => {
                setOverride(true);
              }}
            />
          </div>
          {override && (
            <div>
              <Form.Group controlId="choice-table">
                <ResourcePicker
                  resource_type={
                    resource_type === 'course' ? 'courses' : 'staff'
                  }
                  resource={resource}
                  onChange={(selected) => {
                    setOverrideId(selected.value);
                  }}
                />
              </Form.Group>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className="success" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          variant="success"
          className="success"
          onClick={() => {
            onConfirm(overrideId);
          }}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ResourcePicker = ({ resource, resource_type, onChange }) => {
  const [data, setData] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;

    async function fetchData() {
      const response = await schoolsService().getSchoolResource({
        schoolId: resource.school,
        type: resource_type,
      });
      const response_data = response.data || [];
      const mapped_data = response_data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setData(mapped_data);
    }
    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [resource.school, resource_type]);

  return (
    <div>
      <Select options={data} onChange={onChange} />
    </div>
  );
};
