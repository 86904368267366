/* eslint-disable */
// @ts-ignore
import * as geolocator from 'geolocator/dist/geolocator';

const getTimezone = (latitude: number, longitude: number) => {
  geolocator.config({
    language: 'en',
    google: { version: '3', key: process.env.REACT_APP_GOOGLE_GEO_API_KEY },
  });

  return new Promise((resolve) => {
    geolocator.getTimeZone(
      { latitude, longitude },
      (error: any, timezone: { id: string }) => {
        resolve(timezone);
      },
    );
  });
};

export default getTimezone;
