import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useSchoolId } from 'hooks/useSchoolId';
import { axiosClient, get } from 'hooks/utils';
import type { StaffView } from 'types/openApi/StaffView';

export const useTeachers = () => {
  const schoolId = useSchoolId();
  return useQuery({
    queryKey: ['teachers', schoolId],
    queryFn: () => get<StaffView[]>(`v2/schools/${schoolId}/staff`),
  });
};

export const useCreateTeacher = () => {
  const schoolId = useSchoolId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: { name: string }) =>
      axiosClient.post(`v2/schools/${schoolId}/staff`, body),
    onSuccess: () => {
      void queryClient.invalidateQueries(['teachers', schoolId]);
    },
  });
};

export const useDeleteTeacher = () => {
  const schoolId = useSchoolId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (staffId: number) =>
      axiosClient.delete(`v2/schools/${schoolId}/staff/${staffId}`),
    onSuccess: () => {
      void queryClient.invalidateQueries(['teachers', schoolId]);
    },
  });
};
