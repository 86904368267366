import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';

import { modificationTypes } from 'constants/scheduleInfo';
import { specialSchedules } from 'constants/specialSchedules';

const breakOptions = specialSchedules.map((o) => ({
  value: o.id,
  label: o.label,
}));
const scheduleTypes = [
  { value: 'static', label: 'Static' },
  { value: 'modified', label: 'Modified' },
  { value: 'special', label: 'Special' },
  { value: 'vacation', label: 'Vacation' },
  { value: 'no-school', label: 'No school' },
];

export const Input = (props) => {
  return (
    <components.Input
      {...props}
      onKeyUp={({ target }) => {
        const { value, selectionStart, selectionEnd } = target;
        target.value = value.toUpperCase().replace(' ', '-');
        target.setSelectionRange(selectionStart, selectionEnd);
      }}
    />
  );
};

export const ScheduleNameBuilder = ({
  allowStatic = false,
  dayTypes = undefined,
  selectedDate,
  onChange,
}) => {
  const special_name = `#-${moment(selectedDate).format('MM-DD-YYYY')}`;
  const options = allowStatic
    ? scheduleTypes.filter((z) => z.value !== 'vacation')
    : scheduleTypes.filter((z) => z.value !== 'static');

  const [state, setState] = useState({
    schedule_type: allowStatic ? 'static' : 'modified',
    modification: undefined,
    day_type: undefined,
    special: undefined,
  });

  useEffect(() => {
    const special = state.schedule_type === 'special';
    if (special) {
      onChange({ special: special, schedule: special_name });
    }
  }, [state.schedule_type, selectedDate]);

  const renderNamePicker = () => (
    <>
      {allowStatic && (
        <Form.Group className="extra-margin flex">
          <Form.Label>Day type</Form.Label>
          <Select
            options={dayTypes?.map((d) => ({
              value: d.name,
              label: d.name,
            }))}
            onChange={(selected) => {
              const schedule_name = state.modification
                ? `${selected.value}!${state.modification.value}`
                : null;
              setState({ ...state, day_type: selected });
              onChange({
                special: false,
                schedule:
                  state.schedule_type === 'modified'
                    ? schedule_name
                    : selected.value,
              });
            }}
            value={state.day_type}
            className="schedule-name-picker"
          />
        </Form.Group>
      )}
      {state.schedule_type === 'modified' && (
        <Form.Group className="extra-margin flex large">
          <Form.Label>Modification</Form.Label>
          <CreatableSelect
            classNamePrefix="modification-picker"
            options={modificationTypes}
            onChange={(selected) => {
              setState({ ...state, modification: selected });
              const schedule_name = state.day_type
                ? `${state.day_type.value}!${selected.value}`
                : null;
              onChange({
                special: false,
                schedule: allowStatic ? schedule_name : selected.value,
              });
            }}
            components={{ Input }}
            getNewOptionData={(inputValue, optionLabel) => {
              const value = inputValue.toUpperCase().replace(' ', '-');
              return {
                value,
                label: optionLabel.replace(inputValue, value),
                __isNew__: true,
              };
            }}
            formatCreateLabel={(label) =>
              `Create "${label.toUpperCase().replace(' ', '-')}"`
            }
            value={state.modification}
            className="schedule-name-picker"
          />
        </Form.Group>
      )}
    </>
  );

  const renderVacationPicker = () => (
    <Form.Group className="extra-margin flex">
      <Form.Label>Vacation type</Form.Label>
      <CreatableSelect
        options={breakOptions}
        onChange={(selected) => {
          onChange({ special: true, schedule: selected.value });
        }}
        className="schedule-name-picker"
      />
    </Form.Group>
  );

  const renderSpecialNameField = () => (
    <Form.Group className="extra-margin flex">
      <Form.Label>Schedule Name</Form.Label>
      <Form.Control type="text" value={special_name} disabled />
    </Form.Group>
  );

  const renderSpecialNameBuilder = () =>
    state.schedule_type === 'vacation'
      ? renderVacationPicker()
      : state.schedule_type === 'special'
      ? renderSpecialNameField()
      : null;

  return (
    <>
      <Form.Group className="extra-padding flex">
        <Form.Label>Type</Form.Label>
        <Select
          options={options}
          onChange={({ value }) => {
            const special = value === 'special';
            onChange({
              special: special,
              schedule: special
                ? special_name
                : value === 'no-school'
                ? 'X'
                : null,
            });
            setState({
              schedule_type: value,
              special: value === 'special' || value === 'vacation',
              modification: undefined,
              day_type: undefined,
            });
          }}
          defaultValue={options[0]}
        />
      </Form.Group>
      {['static', 'modified'].includes(state.schedule_type)
        ? renderNamePicker()
        : renderSpecialNameBuilder()}
    </>
  );
};
