const ActionTypes = {
  AUTH_VERIFY_TOKEN: 'Auth.AUTH_VERIFY_TOKEN',
  AUTH_VERIFY_TOKEN_SUCCESS: 'Auth.AUTH_VERIFY_TOKEN_SUCCESS',
  AUTH_VERIFY_TOKEN_FAIL: 'Auth.AUTH_VERIFY_TOKEN_FAIL',
  AUTH_VALIDATE_USER_AUTH: 'Auth.AUTH_VALIDATE_USER_AUTH',
  AUTH_VALIDATE_USER_AUTH_SUCCESS: 'Auth.AUTH_VALIDATE_USER_AUTH_SUCCESS',
  AUTH_VALIDATE_USER_AUTH_FAIL: 'Auth.AUTH_VALIDATE_USER_AUTH_FAIL',
  AUTH_USER_LOGOUT: 'Auth.AUTH_USER_LOGOUT',
  AUTH_USER_LOGOUT_SUCCESS: 'Auth.AUTH_USER_LOGOUT_SUCCESS',
  AUTH_USER_LOGOUT_FAIL: 'Auth.AUTH_USER_LOGOUT_FAIL',
};

const verifyToken = (idToken, provider) => ({
  type: ActionTypes.AUTH_VERIFY_TOKEN,
  idToken,
  provider,
});

const validateUserAuth = () => ({
  type: ActionTypes.AUTH_VALIDATE_USER_AUTH,
});

const logout = () => ({
  type: ActionTypes.AUTH_USER_LOGOUT,
});

const actions = {
  verifyToken,
  validateUserAuth,
  logout,
};

export { ActionTypes, actions };
