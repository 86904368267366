import { put, call, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../actions/reports';
import moderationService from 'services/moderation.service';

function* getUserReports(params) {
  try {
    const response = yield call(moderationService().getContentReports, params);
    if (response.status === 200) {
      const data = response.data;

      yield put({
        type: ActionTypes.GET_USER_REPORTS_SUCCESS,
        userReports: data,
        total: parseInt(response.headers['x-pagination-items']),
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_REPORTS_FAIL, error });
  }
}

export default [takeLatest(ActionTypes.GET_USER_REPORTS, getUserReports)];
