import { useState, useEffect } from 'react';
import * as M from '@mantine/core';
import styled from '@emotion/styled';

import { useWizard } from 'features/scheduleConfiguration/components/wizard/Wizard';
import ImageUploader from 'features/components/ImageUploader';
import type { StepComponent } from 'features/scheduleConfiguration/types';

const UploadImagesStep: StepComponent = ({ school, stepIndex }) => {
  const wizard = useWizard();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    wizard.update(stepIndex, {
      type: 'uploadImagesStep',
      isValid: !isLoading,
      canSkip: true,
    });
  }, [isLoading]);

  return (
    <Container>
      <M.Box>
        <M.Title order={2}>Upload images</M.Title>
        <M.Text>Upload schedule images to be parsed by AI</M.Text>
      </M.Box>

      <ImageUploader schoolId={school.schoolName} setIsLoading={setIsLoading} />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xl};
`;

export default UploadImagesStep;
