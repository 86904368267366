import React from 'react';
import Chip from '@material-ui/core/Chip/index';
import './DomainsList.scss';

const ChipList = (props) => {
  return (
    <div>
      {props.domains &&
        props.domains.map((domain, index) => (
          <Chip
            key={domain + index}
            label={domain.replace(/"/gi, '')}
            clickable
            onClick={() => {
              props.onDomainClick(domain);
            }}
            className={`single-domain-chip ${props.small ? 'small' : ''}`}
          />
        ))}

      {props.showAddButton && (
        <Chip
          key="plus"
          label="+"
          clickable
          onClick={() => {
            props.onAddButtonClick();
          }}
          className="single-domain-chip plus"
        />
      )}
    </div>
  );
};

export default ChipList;
