import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MN from '@mantine/notifications';
import { IconCopy, IconLogout } from '@tabler/icons-react';

import { ROUTES } from 'config/routes';
import { hasAccessTo } from 'store/selectors/auth';

import SaturnLogo from 'assets/icons/saturn_gray_logo.svg';
import GlobalSearchBar from 'features/globalSearchBar';
import { getToken } from 'hooks/utils';

import useMyInfo from './hooks';

const Navbar = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { data: myInfo } = useMyInfo();

  const infoFields = [
    ['ID', `${myInfo?.id}`],
    ['Name', `${myInfo?.firstName} ${myInfo?.lastName}`],
    ['School title', `${myInfo?.schoolTitle}`],
    ['School id', `${myInfo?.schoolId}`],
    ['Email', myInfo?.email],
    ['Phone number', myInfo?.phoneNumber],
    ['Birthday', myInfo?.birthday],
    ['Graduation year', myInfo?.graduationYear],
  ] as const;

  const env = process.env.REACT_APP_ENV;

  const badgeColor = (() => {
    switch (env) {
      case 'local':
        return 'gray';
      case 'staging':
        return 'yellow';
      case 'preview':
        return 'dark';
      case 'production':
        return 'violet';
    }
  })();

  const copyToken = () => {
    void navigator.clipboard.writeText(getToken());
    MN.showNotification({ message: 'Token copied to clipboard' });
  };

  return (
    <>
      <Container>
        <M.Menu
          shadow="xl"
          width={240}
          offset={15}
          onChange={setIsOpened}
          opened={isOpened}
        >
          <M.Menu.Target>
            <LogoImage src={SaturnLogo} $isOpened={isOpened} />
          </M.Menu.Target>
          <M.Menu.Dropdown>
            {infoFields.map(([label, value]) => (
              <div key={label}>
                <M.Menu.Label>{label}</M.Menu.Label>
                <MenuDetail>{value}</MenuDetail>
              </div>
            ))}
            <M.Menu.Divider />
            <M.Menu.Label>Actions</M.Menu.Label>
            <M.Menu.Item
              component="button"
              onClick={copyToken}
              icon={<IconCopy />}
            >
              Copy token
            </M.Menu.Item>
            <M.Menu.Item component="a" href="/logout" icon={<IconLogout />}>
              Log out
            </M.Menu.Item>
          </M.Menu.Dropdown>
        </M.Menu>

        <M.Divider orientation="vertical" />

        <RoutesContainer>
          {ROUTES.map(
            (route) =>
              hasAccessTo(
                new Set(myInfo?.grantedScopes),
                route.scopes as string[],
              ) && (
                <Route key={route.path} to={route.path} href={route.path}>
                  {route.name}
                </Route>
              ),
          )}
        </RoutesContainer>

        <Spacer />

        <GlobalSearchBar />

        <M.Divider orientation="vertical" />

        <M.Badge size="lg" variant="filled" color={badgeColor}>
          {env}
        </M.Badge>
      </Container>
      <M.Divider />
    </>
  );
};

const Container = styled(M.Flex)`
  padding: 16px 32px;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;

const LogoImage = styled.img<{ $isOpened: boolean }>`
  padding: 2px 0px;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    border-bottom: 2px solid #157be7;
  }

  border-bottom: 2px solid ${(p) => (p.$isOpened ? '#fd793c' : '#fff')};
`;

const MenuDetail = styled(M.Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0px 12px;
`;

const RoutesContainer = styled(M.Flex)`
  gap: 24px;
  align-items: center;
`;

const Route = styled(NavLink)`
  text-align: center;
  color: #666;

  &.active {
    color: #fd793c;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

export default Navbar;
