export const ScheduleType = {
  MULTIPLE_DAY_ROTATING: 'multiple_day_rotating',
  SAME_EVERY_DAY: 'same_every_day',
  SAME_EVERY_DAY_PLUS: 'same_every_day_plus',
  A_B: 'a_b',
  A_B_PLUS: 'a_b_plus',
  MONDAY_THROUGH_FRIDAY: 'monday_through_friday',
  OTHER: 'other',
};

export const SCHEDULE_TYPE = {
  [ScheduleType.MULTIPLE_DAY_ROTATING]: 'MDR',
  [ScheduleType.SAME_EVERY_DAY]: 'Same Every Day',
  [ScheduleType.SAME_EVERY_DAY_PLUS]: 'Same Every Day+',
  [ScheduleType.A_B]: 'A/B',
  [ScheduleType.A_B_PLUS]: 'A/B+',
  [ScheduleType.MONDAY_THROUGH_FRIDAY]: 'M-F',
  [ScheduleType.OTHER]: 'Other',
};

export const DayType = {
  ALPHABETIC: 'alphabetic',
  A_B: 'a_b',
  A_B_PLUS: 'a_b_plus',
  NUMERIC: 'numeric',
  WEEKDAYS: 'weekdays',
  REGULAR: 'regular',
  REGULAR_PLUS: 'regular_plus',
  OTHER: 'other',
  ODD_EVEN: 'odd_even',
  ODD_EVEN_PLUS: 'odd_even_plus',
  NUMERIC_PLUS: 'numeric_plus',
  ONE_TWO: 'one_two',
};

export const alphabetic = {
  label: 'Alphabetic (A, B, C, D...)',
  limit: true,
  subOptions: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ],
  value: DayType.ALPHABETIC,
};

const a_b = {
  label: 'A/B',
  limit: false,
  subOptions: ['A', 'B'],
  value: DayType.A_B,
};

const a_b_plus = {
  label: 'A/B+',
  limit: false,
  subOptions: ['A', 'B'],
  value: DayType.A_B_PLUS,
};

const numeric = {
  label: 'Numeric (1, 2 ,3 ,4...)',
  limit: true,
  subOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
  value: DayType.NUMERIC,
};

const one_two = {
  label: '1/2',
  limit: false,
  subOptions: ['1', '2'],
  value: DayType.ONE_TWO,
};

const numericPlus = {
  label: '1/2+',
  limit: false,
  subOptions: ['1', '2', 'OTHER'],
  value: DayType.NUMERIC_PLUS,
};

const weekdays = {
  label: 'Weekdays (M-F)',
  limit: false,
  subOptions: ['M', 'T', 'W', 'TH', 'F'],
  value: DayType.WEEKDAYS,
};

const regular = {
  label: 'Regular',
  limit: false,
  subOptions: ['REGULAR'],
  value: DayType.REGULAR,
};

const regularPlus = {
  label: 'Regular+',
  limit: false,
  subOptions: ['REGULAR', 'OTHER'],
  value: DayType.REGULAR_PLUS,
};

const other = {
  label: 'Other',
  limit: false,
  subOptions: ['OTHER'],
  value: DayType.OTHER,
};

const oddEven = {
  label: 'Odd/Even',
  limit: false,
  subOptions: ['ODD', 'EVEN'],
  value: DayType.ODD_EVEN,
};

const oddEvenPlus = {
  label: 'Odd/Even+',
  limit: false,
  subOptions: ['ODD', 'EVEN', 'OTHER'],
  value: DayType.ODD_EVEN_PLUS,
};

export const dayTypes = {
  [DayType.ALPHABETIC]: alphabetic,
  [DayType.A_B]: a_b,
  [DayType.A_B_PLUS]: a_b_plus,
  [DayType.NUMERIC]: numeric,
  [DayType.ONE_TWO]: one_two,
  [DayType.NUMERIC_PLUS]: numericPlus,
  [DayType.WEEKDAYS]: weekdays,
  [DayType.REGULAR]: regular,
  [DayType.REGULAR_PLUS]: regularPlus,
  [DayType.OTHER]: other,
  [DayType.ODD_EVEN]: oddEven,
  [DayType.ODD_EVEN_PLUS]: oddEvenPlus,
};

export const scheduleTypeDayOptions = {
  [ScheduleType.MULTIPLE_DAY_ROTATING]: [
    DayType.ALPHABETIC,
    DayType.NUMERIC,
    DayType.OTHER,
  ],
  [ScheduleType.SAME_EVERY_DAY]: [DayType.REGULAR],
  [ScheduleType.SAME_EVERY_DAY_PLUS]: [DayType.REGULAR_PLUS],
  [ScheduleType.A_B]: [
    DayType.A_B,
    DayType.ONE_TWO,
    DayType.ODD_EVEN,
    DayType.OTHER,
  ],
  [ScheduleType.A_B_PLUS]: [
    DayType.A_B_PLUS,
    DayType.NUMERIC_PLUS,
    DayType.ODD_EVEN_PLUS,
    DayType.OTHER,
  ],
  [ScheduleType.MONDAY_THROUGH_FRIDAY]: [DayType.WEEKDAYS],
  [ScheduleType.OTHER]: [
    DayType.ALPHABETIC,
    DayType.NUMERIC,
    DayType.WEEKDAYS,
    DayType.REGULAR,
    DayType.OTHER,
    DayType.ODD_EVEN,
  ],
};

export const periodTypeOptions = [alphabetic, numeric];

export const defaultDayTypeOptions = [
  DayType.ALPHABETIC,
  DayType.NUMERIC,
  DayType.WEEKDAYS,
];
