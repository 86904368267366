const ActionTypes = {
  GET_GEOJSON: 'Misc.GET_GEOJSON',
  GET_GEOJSON_SUCCESS: 'Misc.GET_GEOJSON_SUCCESS',
  GET_GEOJSON_FAIL: 'Misc.GET_GEOJSON_FAIL',
  GET_EMOJIS: 'Misc.GET_EMOJIS',
  GET_EMOJIS_SUCCESS: 'Misc.GET_EMOJIS_SUCCESS',
  GET_EMOJIS_FAIL: 'Misc.GET_EMOJIS_FAIL',
};

const getSchoolsGeoJSON = (params) => ({
  type: ActionTypes.GET_GEOJSON,
  params: params,
});

const getEmojis = () => ({
  type: ActionTypes.GET_EMOJIS,
});

const actions = {
  getSchoolsGeoJSON,
  getEmojis,
};

export { ActionTypes, actions };
