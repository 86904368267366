import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './XHRSelectField.scss';
import FieldError from '../FieldError/FieldError';
import AsyncSelect from 'react-select/async';

export default class XHRSelectField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.field.currentValue
        ? props.field.currentValue.value
        : null,
    };

    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.initialize(this.state.selectedValue);
  }

  validate(value) {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  }

  initialize(initialValue) {
    this.validate(initialValue);
    this.props.updateParent(initialValue, this.props.field);
  }

  updateValue(value) {
    const newValue = this.props.field.multi ? value : value.value;
    this.props.field.currentValue = newValue;
    this.validate(newValue);
    this.props.updateParent(newValue, this.props.field);
    this.setState({ selectedValue: newValue });
  }

  getOptions = (inputValue) => {
    const { searchable = true } = this.props.field;
    if (searchable) {
      if (inputValue.length >= 3) {
        return new Promise((resolve) => {
          this.props.field.fetcher(inputValue, resolve);
        });
      }
    } else {
      return new Promise((resolve) => {
        this.props.field.fetcher(inputValue, resolve);
      });
    }
  };

  render() {
    const {
      searchable = true,
      optionFormatter = null,
      multi = false,
    } = this.props.field;
    return (
      <Form.Group controlId={this.props.field.name}>
        <AsyncSelect
          formatOptionLabel={optionFormatter}
          className="xhr-select"
          cacheOptions
          defaultOptions={searchable ? [] : true}
          defaultValue={this.props.field.defaultValue}
          loadOptions={this.getOptions}
          noOptionsMessage={() =>
            this.props.field.empty_text || 'Start typing to search...'
          }
          placeholder={this.props.field.name}
          onChange={this.updateValue}
          isSearchable={searchable}
          isMulti={multi}
        />
        <FieldError field={this.props.field} />
      </Form.Group>
    );
  }
}
