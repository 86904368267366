import React, { useState, useEffect } from 'react';
import EdiText from 'react-editext';

import schoolsService from 'services/schools.service';

import './NotesEditor.scss';

const NotesEditor = ({ currentSchool }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (currentSchool?.notes) {
      setNote(currentSchool.notes);
    }
  }, [currentSchool]);

  const handleEditingStart = () => setIsEditing(true);

  const handleCancel = () => setIsEditing(false);

  const handleSave = async (value) => {
    setNote('Saving...');
    await schoolsService().updateSchool(currentSchool.school_name, {
      ...currentSchool,
      notes: value,
    });
    setIsEditing(false);
    setNote(value);
  };

  return (
    <div className="notes-container">
      <EdiText
        type="textarea"
        value={note || (!isEditing ? 'No notes yet...' : '')}
        onEditingStart={handleEditingStart}
        onCancel={handleCancel}
        onSave={handleSave}
        editButtonContent="Edit Notes"
        saveButtonContent="Save Notes"
        cancelButtonContent="Cancel"
        saveButtonClassName="btn btn-success"
        editButtonClassName="btn btn-primary"
        cancelButtonClassName="btn btn-danger"
        viewContainerClassName="notes-view"
        editContainerClassName="notes-editor"
      />
    </div>
  );
};

export default NotesEditor;
