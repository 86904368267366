import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconEdit } from '@tabler/icons-react';

import { checkCalendarConf } from 'components/shared/SaturnCalendar/utils';

import type { SchoolV1 } from 'types';

interface Props {
  school: SchoolV1;
  onClick: () => void;
}

const EditCalendarButton = ({ school, onClick }: Props) => {
  const canBuild = checkCalendarConf(school);

  const button = (
    <Button
      variant="primary"
      onClick={onClick}
      disabled={!canBuild}
      style={{ pointerEvents: canBuild ? 'all' : 'none' }}
    >
      <div>
        <IconEdit />
        Edit Calendar
      </div>
    </Button>
  );

  return canBuild ? (
    button
  ) : (
    <OverlayTrigger
      placement="bottom"
      trigger={['hover', 'focus']}
      rootClose
      delay={{ show: 250, hide: 250 }}
      overlay={
        <Tooltip id="blocked-builder" className="blocked-builder">
          A few configs are required first:
          <ul>
            <li>
              Configure schedule type and day types on the <b>Schedules</b>
              &nbsp; tab.
            </li>
            <li>
              Configure rotation pattern in the <b>Rotation Logic</b> tab.
            </li>
          </ul>
          Optionally, also set up school year dates on the&nbsp;
          <b>Rotation Logic</b> tab.
        </Tooltip>
      }
    >
      <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
        {button}
      </div>
    </OverlayTrigger>
  );
};

export default EditCalendarButton;
