import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MM from '@mantine/modals';
import { IconCheck, IconX, IconTrash } from '@tabler/icons-react';

import type { DayType, PeriodType, Schedule } from 'types';

interface Props {
  isPeriod: boolean;
  schedules: Schedule[];
  types: DayType[] | PeriodType[];
  canAddType: boolean;
  createType: (name: string) => void;
  updateType: (type: DayType | PeriodType) => void;
  deleteType: (type: DayType | PeriodType) => void;
}

const DayAndPeriodList = ({
  isPeriod,
  schedules,
  types: initialTypes,
  canAddType,
  createType,
  updateType,
  deleteType,
}: Props) => {
  const [types, setTypes] = useState(
    initialTypes.map((t) => ({ ...t, isEdited: false })),
  );
  const [isAdding, setIsAdding] = useState(false);
  const [typeToCreate, setTypeToCreate] = useState('');

  const addTypeInputRef = useRef<HTMLInputElement>(null);

  const clean = (value: string) =>
    value.toUpperCase().replaceAll(/[^A-Z0-9/]/g, '');

  const getErrors = (value: string) =>
    !value
      ? 'Name cannot be empty'
      : [...types, { id: '', name: typeToCreate }].filter(
          (t) => t.name === value,
        ).length > 1
      ? 'Names must be unique'
      : !isPeriod && value === 'X'
      ? 'X is not a valid day type, please rename to XDAY'
      : '';

  const handleUpdate = (type: DayType | PeriodType) => {
    if (!getErrors(type.name)) {
      updateType(type);
    }
  };

  const editType = (index: number, value: string) => {
    setTypes((prev) =>
      prev.map((t, i) =>
        i !== index ? t : { id: t.id, name: clean(value), isEdited: true },
      ),
    );
  };

  const cancelEdit = (index: number) => {
    setTypes((prev) =>
      prev.map((t, i) =>
        i !== index ? t : { ...initialTypes[i], isEdited: false },
      ),
    );
  };

  const handleDelete = (type: DayType | PeriodType) => {
    const schedulesWithDayType = schedules
      .filter((s) => s.variant !== 'special' && s.dayType.id === type.id)
      .map((s) => s.displayName)
      .join(', ');
    const schedulesWithPeriodType = schedules
      .filter((s) => s.periods.find((p) => p.periodType?.id === type.id))
      .map((s) => s.displayName)
      .join(', ');

    const brokenSchedules = schedulesWithDayType || schedulesWithPeriodType;

    if (!brokenSchedules) {
      deleteType(type);
      return;
    }

    MM.openConfirmModal({
      title: `Deleting ${type.name}`,
      children: (
        <>
          <M.Text>
            {schedulesWithDayType
              ? 'Deleting this day type will delete the following schedules:'
              : 'Deleting this period type will remove it from the following schedule(s):'}
          </M.Text>
          <br />
          <M.Text weight="bold">{brokenSchedules}</M.Text>
        </>
      ),
      onConfirm: () => deleteType(type),
      labels: {
        confirm: 'Delete',
        cancel: 'Cancel',
      },
      confirmProps: {
        color: 'red',
      },
      withCloseButton: false,
    });
  };

  useEffect(() => {
    setTypes(initialTypes.map((t) => ({ ...t, isEdited: false })));
  }, [initialTypes]);

  useEffect(() => {
    if (isAdding) {
      setTimeout(() => addTypeInputRef.current?.focus(), 1);
    } else {
      setTypeToCreate('');
    }
  }, [isAdding]);

  return (
    <Container>
      {types.map((type, i) => (
        <TextInput
          key={type.id}
          autoFocus
          placeholder="Enter a type"
          value={type.name}
          error={getErrors(type.name)}
          onChange={(e) => editType(i, e.currentTarget.value)}
          rightSection={
            type.isEdited ? (
              <M.Flex>
                <Action onClick={() => handleUpdate(type)} isPositive>
                  <IconCheck />
                </Action>
                <Action onClick={() => cancelEdit(i)}>
                  <IconX />
                </Action>
              </M.Flex>
            ) : (
              <Action onClick={() => handleDelete(type)}>
                <IconTrash />
              </Action>
            )
          }
        />
      ))}
      {canAddType && <M.Button onClick={() => setIsAdding(true)}>Add</M.Button>}

      <AddModal
        opened={isAdding}
        onClose={() => setIsAdding(false)}
        title="Add a type"
      >
        <M.TextInput
          autoFocus
          placeholder="Enter name"
          ref={addTypeInputRef}
          value={typeToCreate}
          error={getErrors(typeToCreate)}
          onChange={(e) => setTypeToCreate(clean(e.currentTarget.value))}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && getErrors(typeToCreate) === '') {
              setIsAdding(false);
              createType(typeToCreate);
            }
          }}
        />
        <M.Button
          disabled={getErrors(typeToCreate) !== ''}
          onClick={() => {
            setIsAdding(false);
            createType(typeToCreate);
          }}
        >
          Add
        </M.Button>
      </AddModal>
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const TextInput = styled(M.TextInput)`
  .mantine-TextInput-input {
    padding-right: 56px;
  }

  .mantine-Input-rightSection {
    width: 56px;
    padding: 4px;
    justify-content: flex-end;
  }
`;

const Action = styled.span<{ isPositive?: boolean }>`
  font-size: ${(p) => p.theme.fontSizes.xs};

  &:hover {
    cursor: pointer;

    svg {
      color: ${(p) =>
        p.isPositive ? p.theme.colors.green[4] : p.theme.colors.red[7]};
    }
  }
`;

const AddModal = styled(M.Modal)`
  .mantine-Modal-body {
    display: flex;
    flex-direction: column;
    gap: ${(p) => p.theme.spacing.md};
  }
`;

export default DayAndPeriodList;
