export default [{x:42.059766,y:-72.63304},
{x:42.28835,y:-72.406243},
{x:42.195435,y:-72.932539},
{x:42.194276,y:-72.934186},
{x:42.164688,y:-72.613644},
{x:42.166754,y:-72.5461},
{x:42.17515,y:-72.575861},
{x:42.197536,y:-72.574613},
{x:42.27988,y:-72.670284},
{x:42.358839,y:-72.54972},
{x:42.167825,y:-72.641323},
{x:42.205811,y:-72.610596},
{x:42.175091,y:-72.640727},
{x:42.186597,y:-72.629969},
{x:42.167703,y:-72.481007},
{x:42.169104,y:-72.454004},
{x:42.328892,y:-72.633195},
{x:42.174381,y:-72.323143},
{x:42.025836,y:-72.785437},
{x:42.256992,y:-72.249906},
{x:42.113693,y:-72.72693},
{x:42.134205,y:-72.741664},
{x:42.115792,y:-72.623996},
{x:42.095501,y:-72.603842},
{x:42.120846,y:-72.584841},
{x:42.139292,y:-72.581955},
{x:42.142832,y:-72.553449},
{x:42.111436,y:-72.565496},
{x:42.091392,y:-72.580214},
{x:42.111282,y:-72.604074},
{x:42.136741,y:-72.517735},
{x:42.094618,y:-72.512954},
{x:42.143127,y:-72.489609},
{x:42.442632,y:-73.274932},
{x:42.46852,y:-73.203972},
{x:42.619229,y:-73.120178},
{x:42.209996,y:-73.345374},
{x:42.299152,y:-73.240801},
{x:42.296908,y:-73.274697},
{x:42.298423,y:-73.274503},
{x:42.700757,y:-73.107984},
{x:42.599834,y:-72.594488},
{x:42.585459,y:-72.624779},
{x:42.581505,y:-72.254883},
{x:42.553749,y:-71.78373},
{x:42.579613,y:-71.774071},
{x:42.553761,y:-71.571747},
{x:42.563297,y:-71.99086},
{x:42.532749,y:-71.743767},
{x:42.542198,y:-71.760483},
{x:42.493179,y:-71.72628},
{x:42.649975,y:-71.66217},
{x:42.678806,y:-72.039146},
{x:42.208801,y:-71.835716},
{x:42.156404,y:-71.964109},
{x:42.145172,y:-71.987288},
{x:42.139797,y:-72.024897},
{x:42.432442,y:-71.687073},
{x:42.339478,y:-71.837723},
{x:42.117058,y:-71.859802},
{x:42.06863,y:-72.009109},
{x:42.233025,y:-72.012413},
{x:42.112263,y:-72.089508},
{x:42.024582,y:-71.602852},
{x:42.055595,y:-71.873451},
{x:42.283272,y:-71.654938},
{x:42.285606,y:-71.597549},
{x:42.125546,y:-71.646065},
{x:42.275879,y:-71.85363},
{x:42.247734,y:-71.826126},
{x:42.272633,y:-71.770973},
{x:42.244755,y:-71.758957},
{x:42.319057,y:-71.795265},
{x:42.211311,y:-71.795837},
{x:42.295776,y:-71.772675},
{x:42.312117,y:-71.428833},
{x:42.302929,y:-71.401031},
{x:42.298775,y:-71.448158},
{x:42.476017,y:-71.409515},
{x:42.245434,y:-71.431061},
{x:42.485127,y:-71.26021},
{x:42.382229,y:-71.56915},
{x:42.338985,y:-71.541656},
{x:42.339638,y:-71.58847},
{x:42.432533,y:-71.450134},
{x:42.126247,y:-71.517998},
{x:42.14761,y:-71.488678},
{x:42.301762,y:-71.337135},
{x:42.311397,y:-71.364681},
{x:42.30089,y:-71.383576},
{x:42.479218,y:-71.117287},
{x:42.46693,y:-71.178215},
{x:42.473961,y:-71.212448},
{x:42.663246,y:-71.14463},
{x:42.574699,y:-71.282127},
{x:42.537891,y:-71.243164},
{x:42.626106,y:-71.360703},
{x:42.666054,y:-71.303719},
{x:42.809448,y:-71.103256},
{x:42.772648,y:-71.115494},
{x:42.771297,y:-71.076439},
{x:42.690247,y:-71.172974},
{x:42.705044,y:-71.168724},
{x:42.72253,y:-71.160232},
{x:42.728252,y:-71.202927},
{x:42.706154,y:-71.199921},
{x:42.743771,y:-71.15107},
{x:42.700012,y:-71.118622},
{x:42.626266,y:-71.321671},
{x:42.629357,y:-71.276613},
{x:42.649998,y:-71.332291},
{x:42.569408,y:-71.108475},
{x:42.569384,y:-71.42093},
{x:42.520565,y:-71.102882},
{x:42.591011,y:-71.204445},
{x:42.642445,y:-71.227608},
{x:42.499073,y:-71.068779},
{x:42.557571,y:-71.181206},
{x:42.47699,y:-70.956024},
{x:42.448727,y:-70.960803},
{x:42.455513,y:-70.959557},
{x:42.473351,y:-71.023537},
{x:42.49572,y:-71.016747},
{x:42.846828,y:-70.913284},
{x:42.555943,y:-70.887718},
{x:42.586498,y:-70.88575},
{x:42.55687,y:-70.967377},
{x:42.547976,y:-70.941516},
{x:42.561226,y:-70.932739},
{x:42.617485,y:-70.666008},
{x:42.575791,y:-70.996709},
{x:42.821018,y:-70.905205},
{x:42.540615,y:-70.943892},
{x:42.523201,y:-70.921684},
{x:42.703808,y:-70.908859},
{x:42.502739,y:-70.923416},
{x:42.507423,y:-70.896416},
{x:42.115227,y:-71.461807},
{x:42.139503,y:-71.14843},
{x:42.102234,y:-71.25959},
{x:42.080692,y:-71.381989},
{x:42.092793,y:-70.715767},
{x:42.15258,y:-71.405418},
{x:42.167736,y:-71.355537},
{x:42.173557,y:-70.880646},
{x:42.158463,y:-71.200516},
{x:42.192688,y:-71.198692},
{x:42.211853,y:-71.183884},
{x:42.13451,y:-71.101074},
{x:42.156429,y:-71.24385},
{x:42.356899,y:-71.058975},
{x:42.351963,y:-71.055177},
{x:42.350294,y:-71.055822},
{x:42.355236,y:-71.063069},
{x:42.338614,y:-71.107206},
{x:42.331509,y:-71.070328},
{x:42.320358,y:-71.082184},
{x:42.315609,y:-71.097931},
{x:42.299141,y:-71.060883},
{x:42.290421,y:-71.071777},
{x:42.282402,y:-71.055159},
{x:42.374458,y:-71.040253},
{x:42.362396,y:-71.019543},
{x:42.363266,y:-71.017829},
{x:42.282864,y:-71.113602},
{x:42.264488,y:-71.16777},
{x:42.350683,y:-71.131012},
{x:42.358631,y:-71.154877},
{x:42.362797,y:-71.13694},
{x:42.252857,y:-71.124496},
{x:42.390369,y:-71.140773},
{x:42.363949,y:-71.100937},
{x:42.373703,y:-71.08284},
{x:42.427048,y:-71.041817},
{x:42.39843,y:-71.064201},
{x:42.403738,y:-71.023272},
{x:42.404182,y:-71.035538},
{x:42.392788,y:-71.034431},
{x:42.427674,y:-71.013832},
{x:42.313767,y:-71.212159},
{x:42.254364,y:-70.99633},
{x:42.276619,y:-71.029312},
{x:42.202579,y:-71.00444},
{x:42.204468,y:-70.955055},
{x:42.245094,y:-70.936623},
{x:42.348572,y:-71.096748},
{x:42.107845,y:-71.024803},
{x:42.069969,y:-71.046982},
{x:42.100193,y:-71.067253},
{x:42.081257,y:-70.991867},
{x:41.996223,y:-70.972534},
{x:41.921062,y:-70.8041},
{x:42.069491,y:-70.947697},
{x:42.143932,y:-70.845024},
{x:42.067776,y:-70.874329},
{x:41.88401,y:-70.917755},
{x:41.952969,y:-70.686387},
{x:41.93621,y:-70.661379},
{x:42.010986,y:-70.732735},
{x:42.165077,y:-71.046021},
{x:42.121838,y:-70.91497},
{x:42.440211,y:-71.25749},
{x:42.376633,y:-71.242096},
{x:42.365105,y:-71.196259},
{x:42.330185,y:-71.254808},
{x:42.274288,y:-71.237457},
{x:41.758331,y:-70.659058},
{x:41.716474,y:-70.584679},
{x:41.567291,y:-70.594702},
{x:41.781918,y:-70.541679},
{x:41.652179,y:-70.288351},
{x:41.667568,y:-70.291522},
{x:41.660179,y:-70.203476},
{x:41.900848,y:-71.353284},
{x:41.916698,y:-71.359578},
{x:41.952148,y:-71.269988},
{x:41.614596,y:-70.938132},
{x:41.641023,y:-70.885681},
{x:41.712123,y:-71.126628},
{x:41.677106,y:-71.161635},
{x:41.695548,y:-71.150685},
{x:41.682437,y:-71.143259},
{x:41.682674,y:-71.176791},
{x:41.636166,y:-70.930524},
{x:41.678127,y:-70.940695},
{x:41.656498,y:-70.921749},
{x:41.660031,y:-70.94724},
{x:41.651259,y:-70.986882},
{x:41.972145,y:-71.331519},
{x:42.010021,y:-71.304535},
{x:41.983098,y:-71.21022},
{x:41.953565,y:-71.069961},
{x:41.905501,y:-71.051797},
{x:41.79807,y:-71.331351},
{x:41.769411,y:-71.264202},
{x:41.748454,y:-71.208463},
{x:41.888792,y:-71.119472},
{x:41.678711,y:-71.579955},
{x:41.662494,y:-71.495662},
{x:41.660936,y:-71.544903},
{x:41.871108,y:-71.530444},
{x:41.519968,y:-71.291216},
{x:41.537915,y:-71.29118},
{x:41.874516,y:-71.399381},
{x:41.880192,y:-71.364674},
{x:41.943536,y:-71.436095},
{x:41.913793,y:-71.404511},
{x:41.936007,y:-71.474874},
{x:41.442477,y:-71.483158},
{x:41.716715,y:-71.267871},
{x:41.714851,y:-71.487599},
{x:41.754149,y:-71.413081},
{x:41.712439,y:-71.398408},
{x:41.370323,y:-71.820557},
{x:42.015464,y:-71.471365},
{x:41.982774,y:-71.514483},
{x:41.512337,y:-71.688253},
{x:41.85981,y:-71.46939},
{x:41.853289,y:-71.428567},
{x:41.838174,y:-71.408785},
{x:41.786434,y:-71.402529},
{x:41.813784,y:-71.421977},
{x:41.771831,y:-71.453279},
{x:41.801822,y:-71.445877},
{x:41.821608,y:-71.352726},
{x:41.856954,y:-71.35398},
{x:41.814521,y:-71.495198},
{x:41.789094,y:-71.500258},
{x:42.890594,y:-71.326591},
{x:43.027953,y:-71.074587},
{x:42.999947,y:-71.515762},
{x:42.773956,y:-71.443787},
{x:42.72617,y:-71.424652},
{x:42.864269,y:-71.34845},
{x:42.838373,y:-71.49758},
{x:42.823624,y:-71.622513},
{x:42.708313,y:-71.442116},
{x:42.784061,y:-71.507607},
{x:42.758816,y:-71.457245},
{x:42.732502,y:-71.47554},
{x:42.719288,y:-71.320358},
{x:43.035366,y:-71.163277},
{x:42.763611,y:-71.215561},
{x:42.806223,y:-71.262983},
{x:42.968472,y:-71.480164},
{x:42.971172,y:-71.454674},
{x:42.945374,y:-71.452042},
{x:43.046681,y:-71.437152},
{x:42.987175,y:-71.426811},
{x:43.22699,y:-71.359352},
{x:43.447759,y:-71.629383},
{x:43.112289,y:-71.918633},
{x:43.559601,y:-71.453071},
{x:44.037338,y:-71.675652},
{x:43.645267,y:-71.500732},
{x:43.771426,y:-71.722479},
{x:43.454235,y:-71.567848},
{x:43.289403,y:-71.832936},
{x:43.197121,y:-71.531143},
{x:43.213757,y:-71.512894},
{x:43.259258,y:-71.581062},
{x:42.925762,y:-72.289337},
{x:42.818123,y:-72.016541},
{x:44.307926,y:-71.801774},
{x:44.392629,y:-71.186234},
{x:44.498312,y:-71.575872},
{x:43.368908,y:-72.325554},
{x:43.359661,y:-72.17588},
{x:43.628526,y:-72.322424},
{x:44.143536,y:-72.030449},
{x:43.092213,y:-70.79953},
{x:43.049416,y:-70.771721},
{x:43.451801,y:-71.210266},
{x:44.026973,y:-71.119156},
{x:43.219787,y:-70.885818},
{x:42.99091,y:-70.930077},
{x:43.045315,y:-70.81945},
{x:42.94595,y:-70.832588},
{x:43.148785,y:-71.007935},
{x:43.310989,y:-70.992119},
{x:43.315048,y:-70.976814},
{x:42.895009,y:-70.870479},
{x:43.820294,y:-71.201452},
{x:43.114248,y:-70.731051},
{x:43.483762,y:-70.471737},
{x:44.044821,y:-70.70008},
{x:43.912843,y:-69.986412},
{x:43.906822,y:-69.915276},
{x:43.860346,y:-70.101674},
{x:43.886134,y:-70.32935},
{x:43.392901,y:-70.532845},
{x:43.838418,y:-70.442677},
{x:43.510161,y:-70.434643},
{x:43.432888,y:-70.770388},
{x:43.41013,y:-70.741437},
{x:43.591402,y:-70.332759},
{x:43.939697,y:-69.976419},
{x:43.303281,y:-70.58598},
{x:43.678298,y:-70.334866},
{x:43.654679,y:-70.280152},
{x:43.688462,y:-70.294364},
{x:43.720849,y:-70.232074},
{x:43.637608,y:-70.255978},
{x:43.637065,y:-70.333338},
{x:44.107138,y:-70.22635},
{x:44.080876,y:-70.261524},
{x:44.506447,y:-70.216895},
{x:44.080646,y:-70.196236},
{x:44.005425,y:-70.074046},
{x:44.0751,y:-70.415329},
{x:44.51717,y:-70.558561},
{x:44.216849,y:-70.517006},
{x:44.322331,y:-69.763904},
{x:44.31429,y:-69.799007},
{x:44.232237,y:-69.774042},
{x:44.817776,y:-68.810229},
{x:44.791325,y:-68.776961},
{x:44.83687,y:-68.741981},
{x:44.82224,y:-68.779246},
{x:44.780148,y:-68.743661},
{x:44.569828,y:-68.776642},
{x:45.182572,y:-69.255762},
{x:45.361889,y:-68.516564},
{x:45.656776,y:-68.688236},
{x:44.915274,y:-68.68384},
{x:43.90856,y:-69.827223},
{x:44.036902,y:-69.511216},
{x:43.972687,y:-69.712641},
{x:44.530956,y:-68.406897},
{x:45.184132,y:-67.283169},
{x:44.705322,y:-67.463048},
{x:46.135654,y:-67.839902},
{x:46.869259,y:-68.003203},
{x:47.256997,y:-68.589601},
{x:47.356326,y:-68.334533},
{x:46.687404,y:-68.012832},
{x:44.115722,y:-69.105064},
{x:44.093053,y:-69.142726},
{x:44.566164,y:-69.638094},
{x:44.545267,y:-69.675925},
{x:44.537529,y:-69.628194},
{x:44.423107,y:-69.020329},
{x:44.66447,y:-70.148707},
{x:44.835641,y:-69.287606},
{x:44.779801,y:-69.725312},
{x:43.646313,y:-72.335526},
{x:43.943937,y:-72.62595},
{x:43.304049,y:-72.492929},
{x:42.895475,y:-73.204756},
{x:43.17491,y:-73.054104},
{x:42.882069,y:-72.557579},
{x:44.467519,y:-73.17561},
{x:44.437714,y:-73.210819},
{x:44.50336,y:-73.18096},
{x:44.535276,y:-73.248101},
{x:44.913113,y:-72.796617},
{x:44.494921,y:-73.122299},
{x:44.507308,y:-73.086612},
{x:44.201347,y:-73.246979},
{x:44.622631,y:-73.123412},
{x:44.83765,y:-73.080765},
{x:44.922015,y:-73.110955},
{x:44.173954,y:-72.506675},
{x:44.227485,y:-72.551554},
{x:44.574316,y:-72.591088},
{x:43.618313,y:-72.957384},
{x:43.595704,y:-72.969509},
{x:43.604287,y:-73.275914},
{x:44.002359,y:-73.154379},
{x:44.416619,y:-72.016477},
{x:44.952,y:-72.159216},
{x:44.521148,y:-72.000233},
{x:41.817058,y:-72.712997},
{x:41.675093,y:-72.946179},
{x:41.690398,y:-72.922442},
{x:42.025151,y:-73.325632},
{x:41.826094,y:-72.8984},
{x:41.888586,y:-72.462541},
{x:41.723,y:-72.615462},
{x:41.643612,y:-72.76957},
{x:41.76667,y:-72.568308},
{x:41.797098,y:-72.556078},
{x:41.8131,y:-72.525692},
{x:41.77155,y:-72.537916},
{x:41.665976,y:-72.805923},
{x:41.671715,y:-72.776526},
{x:41.715673,y:-72.762565},
{x:41.675905,y:-72.841324},
{x:41.670999,y:-72.86107},
{x:41.828946,y:-72.496524},
{x:41.856265,y:-72.429796},
{x:41.673532,y:-72.64572},
{x:41.823784,y:-72.608536},
{x:41.969567,y:-72.330545},
{x:41.985977,y:-72.511777},
{x:42.024178,y:-72.585335},
{x:41.989307,y:-72.579974},
{x:41.998263,y:-72.583619},
{x:41.756731,y:-72.883847},
{x:41.817951,y:-72.868365},
{x:41.852521,y:-72.661525},
{x:41.929997,y:-72.685057},
{x:41.928487,y:-72.66972},
{x:41.919404,y:-73.058495},
{x:41.742128,y:-72.712526},
{x:41.757247,y:-72.681945},
{x:41.752493,y:-72.714552},
{x:41.786423,y:-72.748557},
{x:41.783343,y:-72.584642},
{x:41.687704,y:-72.708146},
{x:41.782209,y:-72.698288},
{x:41.738644,y:-72.659332},
{x:41.78767,y:-72.662174},
{x:41.717094,y:-72.23407},
{x:41.799622,y:-71.902709},
{x:41.840275,y:-71.882614},
{x:41.736077,y:-72.198295},
{x:41.925004,y:-71.881723},
{x:41.360428,y:-72.116538},
{x:41.367647,y:-72.209608},
{x:41.430457,y:-72.081423},
{x:41.352088,y:-72.059171},
{x:41.591865,y:-71.991636},
{x:41.372042,y:-71.959625},
{x:41.547932,y:-72.094358},
{x:41.511551,y:-72.115151},
{x:41.666989,y:-71.915667},
{x:41.405875,y:-71.850211},
{x:41.477939,y:-72.099179},
{x:41.440915,y:-72.106702},
{x:41.342656,y:-72.124888},
{x:41.370533,y:-72.155022},
{x:41.285164,y:-72.833928},
{x:41.481484,y:-72.904981},
{x:41.277443,y:-72.538205},
{x:41.567943,y:-72.325499},
{x:41.601279,y:-72.675764},
{x:41.604247,y:-72.707174},
{x:41.331193,y:-73.081516},
{x:41.311676,y:-73.051788},
{x:41.583785,y:-72.503708},
{x:41.147945,y:-73.256592},
{x:41.149143,y:-73.244774},
{x:41.289211,y:-72.668966},
{x:41.291849,y:-72.580126},
{x:41.539431,y:-72.817142},
{x:41.508577,y:-72.80803},
{x:41.280226,y:-72.84801},
{x:41.52881,y:-72.777368},
{x:41.554654,y:-72.683104},
{x:41.54041,y:-72.653586},
{x:41.240642,y:-73.034258},
{x:41.247049,y:-73.009972},
{x:41.245782,y:-73.008438},
{x:41.204921,y:-73.093139},
{x:41.31039,y:-73.220955},
{x:41.325134,y:-72.7718},
{x:41.396389,y:-72.855712},
{x:41.356576,y:-72.87142},
{x:41.28794,y:-72.393723},
{x:41.27862,y:-72.987816},
{x:41.562779,y:-72.87444},
{x:41.39732,y:-73.081989},
{x:41.478432,y:-73.2114},
{x:41.635104,y:-72.872751},
{x:41.470389,y:-72.817015},
{x:41.31564,y:-72.941321},
{x:41.308258,y:-72.895628},
{x:41.286056,y:-72.887251},
{x:41.32197,y:-72.878092},
{x:41.34796,y:-72.933656},
{x:41.373123,y:-72.917156},
{x:41.328502,y:-72.966584},
{x:41.270799,y:-72.975172},
{x:41.289525,y:-72.958359},
{x:41.284976,y:-72.936379},
{x:41.182745,y:-73.205646},
{x:41.16782,y:-73.216873},
{x:41.200294,y:-73.192848},
{x:41.217571,y:-73.215118},
{x:41.231286,y:-73.153732},
{x:41.229379,y:-73.226259},
{x:41.189678,y:-73.154495},
{x:41.198179,y:-73.122861},
{x:41.558694,y:-73.053008},
{x:41.580669,y:-73.031984},
{x:41.565588,y:-73.009057},
{x:41.539408,y:-72.99618},
{x:41.552555,y:-73.030761},
{x:41.474318,y:-73.043135},
{x:41.492481,y:-73.079275},
{x:41.567379,y:-73.414787},
{x:41.679227,y:-73.019948},
{x:41.81441,y:-73.121959},
{x:41.82062,y:-73.078732},
{x:41.57627,y:-73.100859},
{x:41.440918,y:-73.406586},
{x:41.386726,y:-73.477608},
{x:41.406063,y:-73.417641},
{x:41.3804,y:-73.480949},
{x:41.414116,y:-73.458183},
{x:41.067802,y:-73.504448},
{x:41.08083,y:-73.461075},
{x:41.146307,y:-73.256017},
{x:41.179787,y:-73.247101},
{x:41.024105,y:-73.635384},
{x:41.11839,y:-73.417128},
{x:41.127808,y:-73.384857},
{x:41.137642,y:-73.425034},
{x:41.093704,y:-73.45179},
{x:41.041808,y:-73.579461},
{x:41.138012,y:-73.341911},
{x:41.057156,y:-73.515327},
{x:41.05545,y:-73.538658},
{x:40.581509,y:-74.293015},
{x:40.665562,y:-74.11721},
{x:40.694359,y:-74.102867},
{x:40.836431,y:-74.181012},
{x:40.808098,y:-74.191978},
{x:40.884315,y:-74.285416},
{x:40.908764,y:-74.395988},
{x:40.590958,y:-74.229553},
{x:40.755833,y:-74.21701},
{x:40.813576,y:-73.983818},
{x:40.818687,y:-74.001562},
{x:40.85582,y:-73.966698},
{x:40.867629,y:-74.100798},
{x:40.654099,y:-74.321083},
{x:40.739906,y:-74.030396},
{x:40.921173,y:-74.301407},
{x:40.624821,y:-74.244339},
{x:40.756408,y:-74.044594},
{x:40.806927,y:-74.016922},
{x:40.770435,y:-74.224899},
{x:40.770161,y:-74.281754},
{x:40.862628,y:-74.387135},
{x:40.871025,y:-74.423294},
{x:40.863605,y:-74.127991},
{x:40.85947,y:-74.332397},
{x:40.617599,y:-74.425715},
{x:40.631771,y:-74.398499},
{x:40.607571,y:-74.261497},
{x:40.629002,y:-74.306473},
{x:40.656281,y:-74.401443},
{x:40.577618,y:-74.388939},
{x:40.555098,y:-74.415225},
{x:40.687053,y:-74.299892},
{x:40.689709,y:-74.295113},
{x:40.698372,y:-74.251917},
{x:40.77544,y:-74.031746},
{x:40.785122,y:-74.018883},
{x:40.792986,y:-74.042412},
{x:40.536312,y:-74.295822},
{x:40.566095,y:-74.287775},
{x:40.725586,y:-74.182365},
{x:40.726685,y:-74.173218},
{x:40.734896,y:-74.164231},
{x:40.735973,y:-74.171631},
{x:40.745361,y:-74.1903},
{x:40.734898,y:-74.187977},
{x:40.771854,y:-74.161499},
{x:40.72956,y:-74.158821},
{x:40.738201,y:-74.225128},
{x:40.803307,y:-74.146324},
{x:40.782503,y:-74.182995},
{x:40.728542,y:-74.218002},
{x:40.690105,y:-74.202286},
{x:40.663181,y:-74.214722},
{x:40.675579,y:-74.191902},
{x:40.641243,y:-74.261337},
{x:40.660908,y:-74.267235},
{x:40.69899,y:-74.236176},
{x:40.654102,y:-74.21151},
{x:40.719843,y:-74.043057},
{x:40.717419,y:-74.053314},
{x:40.722092,y:-74.084831},
{x:40.711435,y:-74.078182},
{x:40.731745,y:-74.056616},
{x:40.731762,y:-74.06469},
{x:40.743783,y:-74.05078},
{x:40.729858,y:-74.0426},
{x:40.726501,y:-74.0387},
{x:41.000448,y:-74.356426},
{x:40.926113,y:-74.099762},
{x:41.116673,y:-74.580307},
{x:40.872429,y:-74.19487},
{x:41.09766,y:-74.160469},
{x:41.036365,y:-74.505363},
{x:41.064142,y:-74.127019},
{x:40.95689,y:-74.223454},
{x:41.132137,y:-74.363922},
{x:41.011153,y:-74.167391},
{x:40.891125,y:-74.148262},
{x:40.916375,y:-74.171334},
{x:40.905056,y:-74.145996},
{x:40.936695,y:-74.142159},
{x:40.918793,y:-74.153328},
{x:40.879608,y:-74.052797},
{x:40.888802,y:-74.039131},
{x:40.861137,y:-74.066742},
{x:40.916799,y:-73.997433},
{x:40.895351,y:-73.976669},
{x:40.854877,y:-74.044548},
{x:41.011189,y:-73.946274},
{x:40.976532,y:-74.081642},
{x:40.916378,y:-74.075512},
{x:40.915424,y:-74.050766},
{x:40.912445,y:-74.034782},
{x:40.890358,y:-74.094254},
{x:40.263634,y:-74.042427},
{x:40.228401,y:-74.009163},
{x:40.411327,y:-74.047195},
{x:40.164921,y:-74.050102},
{x:40.330563,y:-74.07444},
{x:40.304138,y:-74.059914},
{x:40.350712,y:-74.306999},
{x:40.30275,y:-74.355804},
{x:40.243435,y:-74.281189},
{x:40.42041,y:-74.184189},
{x:40.131116,y:-74.226387},
{x:40.158871,y:-74.232628},
{x:40.438084,y:-74.168541},
{x:40.437958,y:-74.222969},
{x:40.331984,y:-74.118095},
{x:40.304554,y:-73.988441},
{x:40.397789,y:-74.226837},
{x:40.39592,y:-74.105042},
{x:40.20961,y:-74.028145},
{x:40.222743,y:-74.084186},
{x:40.284229,y:-74.01889},
{x:40.873722,y:-74.534988},
{x:41.350338,y:-74.686661},
{x:40.883858,y:-74.706843},
{x:40.932419,y:-75.098618},
{x:40.831188,y:-74.708542},
{x:40.840904,y:-74.824219},
{x:40.958969,y:-74.59684},
{x:40.879326,y:-74.648529},
{x:41.06805,y:-74.744499},
{x:40.891361,y:-74.513977},
{x:40.934494,y:-74.720139},
{x:40.870823,y:-74.644699},
{x:40.808561,y:-74.458365},
{x:40.788837,y:-74.392334},
{x:40.804516,y:-74.367485},
{x:40.696293,y:-74.423637},
{x:39.936084,y:-75.044145},
{x:39.941278,y:-75.007915},
{x:39.769352,y:-74.211834},
{x:39.789411,y:-74.927152},
{x:40.046543,y:-74.904387},
{x:39.729968,y:-75.033916},
{x:39.803563,y:-75.043816},
{x:39.761647,y:-75.048301},
{x:39.974656,y:-74.583914},
{x:40.071819,y:-74.853068},
{x:39.814419,y:-74.98836},
{x:39.717016,y:-75.112807},
{x:39.914805,y:-75.012768},
{x:39.644742,y:-74.78525},
{x:39.852096,y:-74.981092},
{x:40.013126,y:-74.874069},
{x:39.862339,y:-75.033952},
{x:39.708065,y:-74.277519},
{x:39.895096,y:-74.940164},
{x:39.971548,y:-74.87094},
{x:39.926642,y:-74.95555},
{x:39.873651,y:-74.812072},
{x:39.957971,y:-74.979486},
{x:39.875797,y:-75.084138},
{x:40.011239,y:-74.800377},
{x:39.981947,y:-74.780185},
{x:39.724714,y:-75.220885},
{x:39.820476,y:-75.236987},
{x:39.677303,y:-75.495394},
{x:40.015436,y:-74.956113},
{x:39.988769,y:-75.007545},
{x:39.562808,y:-75.458057},
{x:39.781898,y:-75.101518},
{x:39.737819,y:-75.001416},
{x:39.760072,y:-75.358257},
{x:39.601227,y:-74.355896},
{x:39.808496,y:-74.93165},
{x:39.689222,y:-74.991957},
{x:39.837403,y:-75.088599},
{x:39.842786,y:-75.149011},
{x:39.825172,y:-75.159437},
{x:39.647074,y:-75.334457},
{x:39.943826,y:-75.117366},
{x:39.910589,y:-75.098077},
{x:39.904398,y:-75.061101},
{x:39.936623,y:-75.077902},
{x:39.99106,y:-75.027176},
{x:39.431486,y:-74.511193},
{x:38.973774,y:-74.941948},
{x:39.087192,y:-74.819667},
{x:39.525711,y:-74.643984},
{x:39.374423,y:-74.553764},
{x:39.278949,y:-74.578023},
{x:39.209375,y:-74.702539},
{x:39.394608,y:-74.543333},
{x:39.409819,y:-74.566818},
{x:39.018589,y:-74.872693},
{x:39.327643,y:-74.598249},
{x:38.985948,y:-74.827639},
{x:39.42608,y:-75.232526},
{x:39.45952,y:-75.209247},
{x:39.570976,y:-75.054077},
{x:39.447574,y:-74.733932},
{x:39.452699,y:-74.649597},
{x:39.408076,y:-75.03901},
{x:39.491756,y:-75.043331},
{x:39.447636,y:-75.041536},
{x:39.497045,y:-74.995234},
{x:39.359721,y:-74.461928},
{x:39.359894,y:-74.437396},
{x:40.138778,y:-74.707527},
{x:40.158051,y:-74.428131},
{x:40.268568,y:-74.542982},
{x:40.08366,y:-74.265289},
{x:40.138977,y:-74.29554},
{x:40.336636,y:-74.593452},
{x:40.303086,y:-74.677053},
{x:40.035647,y:-74.613975},
{x:40.218549,y:-74.754556},
{x:40.217413,y:-74.743507},
{x:40.192218,y:-74.715737},
{x:40.205806,y:-74.754069},
{x:40.233051,y:-74.684163},
{x:40.250119,y:-74.779661},
{x:40.246207,y:-74.744362},
{x:40.273331,y:-74.707581},
{x:40.198376,y:-74.637592},
{x:40.05463,y:-74.163673},
{x:39.950527,y:-74.07473},
{x:39.915184,y:-74.154022},
{x:40.072704,y:-74.124805},
{x:40.061356,y:-74.141785},
{x:40.076824,y:-74.117424},
{x:40.014259,y:-74.311256},
{x:39.85368,y:-74.182014},
{x:40.124138,y:-74.068489},
{x:40.079874,y:-74.047909},
{x:40.009037,y:-74.148186},
{x:39.957306,y:-74.17115},
{x:39.974151,y:-74.240023},
{x:39.964218,y:-74.197411},
{x:39.970779,y:-74.133476},
{x:40.043941,y:-74.223091},
{x:40.567574,y:-74.559296},
{x:40.640285,y:-74.895591},
{x:40.610107,y:-74.464878},
{x:40.428017,y:-74.382339},
{x:40.451767,y:-74.399117},
{x:40.55724,y:-74.34285},
{x:40.519901,y:-74.395309},
{x:40.499882,y:-74.853806},
{x:40.720982,y:-74.971725},
{x:40.268066,y:-74.471941},
{x:40.54874,y:-74.585899},
{x:40.544189,y:-74.333478},
{x:40.523605,y:-74.361633},
{x:40.507072,y:-74.641678},
{x:40.586348,y:-74.476188},
{x:40.406368,y:-74.547981},
{x:40.554622,y:-74.441267},
{x:40.541862,y:-74.42334},
{x:40.44743,y:-74.299088},
{x:40.520782,y:-74.285851},
{x:40.678393,y:-75.142786},
{x:40.696537,y:-75.179884},
{x:40.515884,y:-74.48822},
{x:40.568226,y:-74.683228},
{x:40.580416,y:-74.610171},
{x:40.462521,y:-74.296227},
{x:40.618767,y:-74.758039},
{x:40.485802,y:-74.470398},
{x:40.466902,y:-74.453628},
{x:40.461916,y:-74.466373},
{x:40.750544,y:-73.990724},
{x:40.750779,y:-73.989538},
{x:40.745995,y:-73.990845},
{x:40.75251,y:-73.993004},
{x:40.747569,y:-73.997088},
{x:40.712839,y:-73.989226},
{x:40.71883,y:-73.988281},
{x:40.726555,y:-73.985855},
{x:40.736977,y:-73.990517},
{x:40.729197,y:-73.993408},
{x:40.715771,y:-74.005234},
{x:40.731014,y:-73.982277},
{x:40.736945,y:-73.979165},
{x:40.741928,y:-73.993286},
{x:40.740654,y:-73.987856},
{x:40.737864,y:-73.996726},
{x:40.730804,y:-74.001076},
{x:40.718587,y:-74.001052},
{x:40.716366,y:-74.010736},
{x:40.728794,y:-74.00509},
{x:40.743156,y:-73.98393},
{x:40.751494,y:-73.98044},
{x:40.744904,y:-73.978949},
{x:40.753042,y:-73.98028},
{x:40.756223,y:-73.977799},
{x:40.754673,y:-73.988188},
{x:40.750816,y:-73.986819},
{x:40.762244,y:-73.983757},
{x:40.765877,y:-73.983273},
{x:40.765888,y:-73.957161},
{x:40.756163,y:-73.970751},
{x:40.760838,y:-73.967323},
{x:40.777901,y:-73.982472},
{x:40.800014,y:-73.967846},
{x:40.794389,y:-73.972343},
{x:40.815941,y:-73.958916},
{x:40.81078,y:-73.953039},
{x:40.798618,y:-73.939789},
{x:40.777789,y:-73.954515},
{x:40.784657,y:-73.947028},
{x:40.808569,y:-73.947756},
{x:40.789984,y:-73.946077},
{x:40.817932,y:-73.94136},
{x:40.812233,y:-73.94178},
{x:40.826694,y:-73.95063},
{x:40.822008,y:-73.953332},
{x:40.835319,y:-73.944335},
{x:40.842594,y:-73.938972},
{x:40.850094,y:-73.936035},
{x:40.867396,y:-73.921301},
{x:40.80447,y:-73.937147},
{x:40.757629,y:-73.98185},
{x:40.756393,y:-73.988039},
{x:40.758118,y:-73.988945},
{x:40.758728,y:-73.984779},
{x:40.709438,y:-74.010086},
{x:40.708279,y:-74.004868},
{x:40.86508,y:-73.927202},
{x:40.750383,y:-73.992511},
{x:40.625507,y:-74.149345},
{x:40.629929,y:-74.076324},
{x:40.59465,y:-74.086105},
{x:40.577595,y:-74.102158},
{x:40.517726,y:-74.234712},
{x:40.629318,y:-74.115868},
{x:40.559681,y:-74.168177},
{x:40.607758,y:-74.162811},
{x:40.816261,y:-73.917053},
{x:40.819809,y:-73.927055},
{x:40.81743,y:-73.922245},
{x:40.827963,y:-73.92535},
{x:40.835136,y:-73.919899},
{x:40.844677,y:-73.914978},
{x:40.850258,y:-73.916202},
{x:40.854321,y:-73.907288},
{x:40.80632,y:-73.925667},
{x:40.819665,y:-73.901987},
{x:40.825195,y:-73.908607},
{x:40.839544,y:-73.915492},
{x:40.841805,y:-73.904701},
{x:40.846584,y:-73.893456},
{x:40.861685,y:-73.892471},
{x:40.856411,y:-73.881203},
{x:40.835464,y:-73.890648},
{x:40.840401,y:-73.876884},
{x:40.849461,y:-73.844551},
{x:40.844517,y:-73.846611},
{x:40.840374,y:-73.843681},
{x:40.855529,y:-73.867477},
{x:40.833219,y:-73.858545},
{x:40.879181,y:-73.871674},
{x:40.884045,y:-73.901711},
{x:40.827599,y:-73.823677},
{x:40.892779,y:-73.857473},
{x:40.864037,y:-73.867035},
{x:40.872894,y:-73.878677},
{x:40.872612,y:-73.852303},
{x:40.866985,y:-73.897368},
{x:40.86255,y:-73.902859},
{x:40.868726,y:-73.830562},
{x:40.902653,y:-73.849564},
{x:40.82851,y:-73.878052},
{x:40.826378,y:-73.861679},
{x:40.82338,y:-73.874687},
{x:40.820564,y:-73.890866},
{x:40.816971,y:-73.892563},
{x:40.885616,y:-73.826889},
{x:41.227966,y:-73.712227},
{x:41.428627,y:-73.576683},
{x:41.411858,y:-73.666519},
{x:41.031704,y:-73.788023},
{x:41.347942,y:-73.755479},
{x:40.938602,y:-73.744614},
{x:41.312363,y:-73.868095},
{x:40.912594,y:-73.837446},
{x:40.900917,y:-73.827263},
{x:41.144466,y:-73.862039},
{x:41.270588,y:-73.936668},
{x:41.290097,y:-73.835523},
{x:40.994591,y:-73.673454},
{x:41.079853,y:-73.864037},
{x:41.126369,y:-73.785355},
{x:41.03356,y:-73.770119},
{x:40.928322,y:-73.896629},
{x:40.964672,y:-73.871956},
{x:40.913582,y:-73.853874},
{x:40.953217,y:-73.857277},
{x:40.959564,y:-73.842476},
{x:40.983444,y:-73.826266},
{x:40.926161,y:-73.789119},
{x:40.913479,y:-73.773521},
{x:41.112892,y:-74.130547},
{x:41.316132,y:-74.125244},
{x:41.355118,y:-74.281898},
{x:41.203007,y:-73.983513},
{x:41.374901,y:-73.962646},
{x:41.430466,y:-74.423386},
{x:41.457428,y:-74.380234},
{x:41.319218,y:-74.17775},
{x:41.092709,y:-74.001213},
{x:41.154118,y:-73.986038},
{x:41.094337,y:-73.93718},
{x:41.153071,y:-74.189734},
{x:41.106968,y:-74.035484},
{x:41.097115,y:-73.956691},
{x:40.707767,y:-73.69352},
{x:40.745591,y:-73.715129},
{x:40.692504,y:-73.683627},
{x:40.736561,y:-73.673401},
{x:40.74366,y:-73.926903},
{x:40.759495,y:-73.918343},
{x:40.77512,y:-73.912183},
{x:40.760616,y:-73.926186},
{x:40.766312,y:-73.930984},
{x:40.690838,y:-73.986206},
{x:40.691383,y:-73.991692},
{x:40.688963,y:-73.98067},
{x:40.644669,y:-73.9217},
{x:40.656101,y:-73.930542},
{x:40.652718,y:-73.923203},
{x:40.612892,y:-73.982658},
{x:40.701332,y:-73.943489},
{x:40.677324,y:-73.922554},
{x:40.677548,y:-73.897746},
{x:40.657864,y:-73.889664},
{x:40.676235,y:-73.890678},
{x:40.670147,y:-73.860352},
{x:40.679871,y:-73.879116},
{x:40.622159,y:-74.027731},
{x:40.633682,y:-74.027039},
{x:40.632336,y:-73.947781},
{x:40.711849,y:-73.943154},
{x:40.70893,y:-73.959017},
{x:40.669307,y:-73.912406},
{x:40.669163,y:-73.930779},
{x:40.604786,y:-73.99826},
{x:40.599335,y:-73.989006},
{x:40.669625,y:-73.986854},
{x:40.680573,y:-73.95034},
{x:40.680096,y:-73.940102},
{x:40.684713,y:-73.977473},
{x:40.644569,y:-73.987686},
{x:40.635811,y:-73.968124},
{x:40.644005,y:-74.011246},
{x:40.689106,y:-73.921887},
{x:40.730389,y:-73.954109},
{x:40.72208,y:-73.940483},
{x:40.598076,y:-73.961494},
{x:40.577218,y:-73.981818},
{x:40.579582,y:-73.975105},
{x:40.654762,y:-73.96169},
{x:40.663731,y:-73.959984},
{x:40.615036,y:-73.92897},
{x:40.648705,y:-73.958168},
{x:40.597485,y:-73.941605},
{x:40.673519,y:-73.999161},
{x:40.653751,y:-74.005402},
{x:40.610328,y:-73.91924},
{x:40.63208,y:-73.92865},
{x:40.644558,y:-73.90126},
{x:40.633762,y:-73.915535},
{x:40.633153,y:-73.889877},
{x:40.699417,y:-73.919655},
{x:40.681351,y:-73.966927},
{x:40.759056,y:-73.830133},
{x:40.764923,y:-73.823349},
{x:40.770061,y:-73.834373},
{x:40.782024,y:-73.829987},
{x:40.760245,y:-73.827945},
{x:40.780651,y:-73.802307},
{x:40.757866,y:-73.779793},
{x:40.768887,y:-73.737518},
{x:40.727467,y:-73.812401},
{x:40.751065,y:-73.870372},
{x:40.749525,y:-73.863008},
{x:40.764374,y:-73.880943},
{x:40.748062,y:-73.883865},
{x:40.738327,y:-73.883179},
{x:40.734585,y:-73.870507},
{x:40.736828,y:-73.86558},
{x:40.711269,y:-73.854439},
{x:40.719994,y:-73.8451},
{x:40.75348,y:-73.908358},
{x:40.726299,y:-73.9067},
{x:40.701706,y:-73.880272},
{x:40.713337,y:-73.909698},
{x:40.699519,y:-73.910468},
{x:40.696632,y:-73.743118},
{x:40.66523,y:-73.756142},
{x:40.657803,y:-73.839958},
{x:40.713238,y:-73.829102},
{x:40.679531,y:-73.861717},
{x:40.686474,y:-73.823948},
{x:40.693196,y:-73.853741},
{x:40.673912,y:-73.728973},
{x:40.720814,y:-73.761147},
{x:40.729198,y:-73.744415},
{x:40.643004,y:-73.790371},
{x:40.643259,y:-73.781969},
{x:40.647365,y:-73.790557},
{x:40.706057,y:-73.792926},
{x:40.703579,y:-73.800079},
{x:40.713558,y:-73.781204},
{x:40.680161,y:-73.77726},
{x:40.660133,y:-73.773834},
{x:40.686165,y:-73.793312},
{x:40.701211,y:-73.807654},
{x:40.702316,y:-73.814293},
{x:40.750618,y:-73.637901},
{x:40.679699,y:-73.614296},
{x:40.749813,y:-73.616844},
{x:40.652676,y:-73.58728},
{x:40.73241,y:-73.610306},
{x:40.862324,y:-73.622795},
{x:40.704258,y:-73.625534},
{x:40.705452,y:-73.653259},
{x:40.686852,y:-73.66021},
{x:40.70937,y:-73.591148},
{x:40.717319,y:-73.593819},
{x:40.72541,y:-73.548363},
{x:40.627853,y:-73.737396},
{x:40.592777,y:-73.657494},
{x:40.658607,y:-73.662025},
{x:40.653885,y:-73.555611},
{x:40.656586,y:-73.628914},
{x:40.638874,y:-73.641319},
{x:40.679344,y:-73.590233},
{x:40.659283,y:-73.700867},
{x:40.747627,y:-73.590981},
{x:40.605076,y:-73.755112},
{x:40.588921,y:-73.816948},
{x:40.702637,y:-73.427681},
{x:40.738907,y:-73.320206},
{x:40.749355,y:-73.324959},
{x:40.750531,y:-73.263778},
{x:40.784519,y:-73.110709},
{x:40.780628,y:-73.247177},
{x:40.859695,y:-73.072678},
{x:40.863004,y:-73.081209},
{x:40.790497,y:-73.204437},
{x:40.816238,y:-73.292862},
{x:40.870403,y:-72.999023},
{x:40.838955,y:-73.327835},
{x:40.728249,y:-73.445992},
{x:40.827431,y:-73.020271},
{x:40.828472,y:-73.426201},
{x:40.8041,y:-73.411659},
{x:40.800597,y:-73.17858},
{x:40.728313,y:-73.225479},
{x:40.779629,y:-73.560005},
{x:40.72599,y:-73.494095},
{x:40.684116,y:-73.353348},
{x:40.704765,y:-73.372536},
{x:40.677364,y:-73.436722},
{x:40.799213,y:-73.006027},
{x:40.941151,y:-72.988792},
{x:40.898788,y:-73.33065},
{x:40.752884,y:-73.118912},
{x:40.859493,y:-73.535088},
{x:40.767925,y:-72.986687},
{x:40.776085,y:-73.038681},
{x:40.922535,y:-73.051102},
{x:40.946392,y:-72.935059},
{x:40.82626,y:-73.106514},
{x:40.859624,y:-73.157524},
{x:40.802547,y:-72.861626},
{x:40.828831,y:-73.198982},
{x:40.84861,y:-73.244034},
{x:40.875594,y:-73.120922},
{x:40.811539,y:-73.49543},
{x:40.943478,y:-72.842476},
{x:40.673237,y:-73.510643},
{x:40.71962,y:-73.30777},
{x:40.778133,y:-73.530716},
{x:40.749096,y:-73.502541},
{x:40.773811,y:-73.483299},
{x:40.917181,y:-72.709704},
{x:40.936522,y:-72.654632},
{x:40.91502,y:-72.661171},
{x:40.799816,y:-72.80217},
{x:40.873699,y:-72.538445},
{x:40.868832,y:-72.794441},
{x:40.979881,y:-72.543167},
{x:40.88656,y:-72.923967},
{x:40.89537,y:-72.398338},
{x:42.944134,y:-74.187536},
{x:42.914681,y:-74.162497},
{x:42.970423,y:-73.795891},
{x:42.992191,y:-73.850074},
{x:42.67886,y:-74.477795},
{x:42.629146,y:-73.80882},
{x:42.864149,y:-73.783959},
{x:42.82065,y:-73.764769},
{x:42.836878,y:-73.740605},
{x:42.948764,y:-74.361277},
{x:42.601501,y:-73.793216},
{x:43.05922,y:-74.323042},
{x:43.014785,y:-74.36051},
{x:42.766874,y:-73.816083},
{x:42.755162,y:-73.759391},
{x:42.903524,y:-73.688047},
{x:42.645272,y:-73.697511},
{x:42.747577,y:-73.637746},
{x:42.738732,y:-73.675398},
{x:42.426744,y:-73.688506},
{x:42.782615,y:-73.696252},
{x:42.727643,y:-73.700823},
{x:42.375429,y:-73.831646},
{x:42.696889,y:-73.64686},
{x:42.684186,y:-73.848524},
{x:42.717044,y:-73.810131},
{x:42.696566,y:-73.849295},
{x:42.683462,y:-73.794663},
{x:42.666937,y:-73.774536},
{x:42.729867,y:-73.847668},
{x:42.645961,y:-73.754302},
{x:42.647222,y:-73.772254},
{x:42.745588,y:-73.809384},
{x:42.706374,y:-73.775384},
{x:42.649995,y:-73.759686},
{x:42.86792,y:-73.933245},
{x:42.783005,y:-73.949989},
{x:42.743588,y:-73.918426},
{x:42.798053,y:-73.924456},
{x:42.768927,y:-73.891786},
{x:42.789633,y:-73.979797},
{x:42.80468,y:-73.903469},
{x:41.94445,y:-73.996811},
{x:41.967094,y:-73.988571},
{x:42.296662,y:-73.989773},
{x:42.217575,y:-73.875662},
{x:41.720715,y:-74.393127},
{x:42.110554,y:-73.956562},
{x:42.084244,y:-73.970894},
{x:41.735226,y:-73.580101},
{x:41.518574,y:-73.890228},
{x:41.530708,y:-73.897358},
{x:41.702763,y:-73.966667},
{x:42.254637,y:-73.767986},
{x:41.782637,y:-73.931972},
{x:41.672337,y:-73.801506},
{x:41.592225,y:-74.088626},
{x:41.513699,y:-74.208076},
{x:41.524986,y:-74.033455},
{x:41.497674,y:-74.074742},
{x:41.500452,y:-74.023819},
{x:41.518177,y:-74.069336},
{x:41.453362,y:-74.057732},
{x:41.604336,y:-74.293633},
{x:41.743664,y:-74.071083},
{x:41.74432,y:-73.822525},
{x:41.607248,y:-73.835601},
{x:41.595763,y:-73.911221},
{x:41.725918,y:-73.931435},
{x:41.697245,y:-73.90626},
{x:41.66722,y:-74.6797},
{x:41.666497,y:-74.674553},
{x:41.782478,y:-74.729301},
{x:43.299832,y:-73.635321},
{x:43.314727,y:-73.609414},
{x:43.297832,y:-73.680784},
{x:43.330758,y:-73.676002},
{x:43.284277,y:-73.586178},
{x:43.394513,y:-73.263345},
{x:43.098042,y:-73.510545},
{x:43.098323,y:-73.748355},
{x:43.074268,y:-73.78773},
{x:43.858151,y:-73.437328},
{x:43.487964,y:-73.758547},
{x:44.696791,y:-73.481549},
{x:44.71444,y:-73.446012},
{x:44.981514,y:-73.459859},
{x:44.293824,y:-73.995278},
{x:44.848343,y:-74.313609},
{x:44.576322,y:-73.516334},
{x:44.31292,y:-74.116055},
{x:44.228341,y:-74.462506},
{x:42.94791,y:-76.547604},
{x:42.919081,y:-76.593455},
{x:43.151069,y:-76.352027},
{x:43.232814,y:-76.140236},
{x:43.041786,y:-76.270515},
{x:43.088346,y:-75.751427},
{x:42.926132,y:-75.840573},
{x:43.286065,y:-76.126965},
{x:43.176157,y:-76.113037},
{x:43.152149,y:-76.125252},
{x:42.572853,y:-76.218771},
{x:42.607748,y:-76.167791},
{x:42.495936,y:-76.298605},
{x:43.029479,y:-76.021015},
{x:43.311281,y:-76.401406},
{x:42.893619,y:-76.111861},
{x:43.086918,y:-76.165218},
{x:43.132883,y:-76.221838},
{x:43.185896,y:-76.246246},
{x:43.460468,y:-76.237302},
{x:43.460727,y:-76.48328},
{x:43.453514,y:-76.527198},
{x:43.023766,y:-76.670854},
{x:43.556349,y:-76.11646},
{x:43.09208,y:-76.319377},
{x:42.903988,y:-76.837294},
{x:42.982939,y:-76.142401},
{x:43.091,y:-76.10091},
{x:43.072737,y:-76.122397},
{x:43.085721,y:-76.09068},
{x:43.11894,y:-76.14312},
{x:43.051901,y:-76.072652},
{x:43.048085,y:-76.228099},
{x:43.033673,y:-76.190518},
{x:43.053076,y:-76.104694},
{x:43.346017,y:-75.758992},
{x:43.043985,y:-75.385108},
{x:42.650661,y:-74.963887},
{x:43.076447,y:-75.103111},
{x:42.834359,y:-75.549619},
{x:43.022597,y:-74.993762},
{x:43.015658,y:-75.034267},
{x:43.041783,y:-74.861649},
{x:43.778026,y:-75.485157},
{x:43.079712,y:-75.318426},
{x:43.07896,y:-75.650897},
{x:42.912324,y:-74.570319},
{x:42.858979,y:-74.998699},
{x:43.22501,y:-75.485598},
{x:43.231107,y:-75.439755},
{x:43.052214,y:-75.271733},
{x:43.114444,y:-75.276821},
{x:43.089267,y:-75.2572},
{x:43.123132,y:-75.225636},
{x:43.10999,y:-75.219059},
{x:43.09103,y:-75.222336},
{x:43.966955,y:-75.878748},
{x:43.977152,y:-75.932863},
{x:43.81032,y:-76.033151},
{x:44.035236,y:-75.842283},
{x:43.96743,y:-75.623039},
{x:44.335609,y:-75.465593},
{x:44.950067,y:-74.845734},
{x:44.921286,y:-74.890282},
{x:44.691346,y:-75.475571},
{x:44.683057,y:-74.987456},
{x:42.059215,y:-76.141459},
{x:42.267543,y:-74.922149},
{x:42.107186,y:-76.020582},
{x:42.09602,y:-76.05462},
{x:41.956321,y:-75.29072},
{x:42.116223,y:-75.960612},
{x:42.123795,y:-75.970502},
{x:42.520043,y:-75.522286},
{x:42.447224,y:-75.050091},
{x:42.453741,y:-75.090836},
{x:42.095705,y:-76.231281},
{x:42.300021,y:-75.408226},
{x:42.095181,y:-76.001091},
{x:42.168508,y:-75.132882},
{x:42.337941,y:-75.975991},
{x:42.095983,y:-75.756503},
{x:42.108353,y:-75.907565},
{x:42.104209,y:-75.876978},
{x:42.126007,y:-75.907829},
{x:42.103332,y:-75.931001},
{x:42.998264,y:-78.503352},
{x:42.894223,y:-78.51153},
{x:42.635789,y:-78.989041},
{x:42.532222,y:-78.449347},
{x:42.995533,y:-78.165398},
{x:43.005608,y:-78.208138},
{x:42.883472,y:-78.69641},
{x:42.930126,y:-78.697692},
{x:42.699616,y:-78.984939},
{x:42.767718,y:-78.629887},
{x:42.450888,y:-79.308726},
{x:42.473286,y:-78.935226},
{x:43.022703,y:-78.962882},
{x:42.749196,y:-78.853387},
{x:42.730575,y:-78.826253},
{x:42.560596,y:-79.121958},
{x:42.901807,y:-78.683693},
{x:43.222747,y:-78.67637},
{x:43.139874,y:-78.696133},
{x:43.207285,y:-78.39888},
{x:43.052015,y:-78.87522},
{x:42.797079,y:-78.752365},
{x:42.502471,y:-78.68899},
{x:43.020561,y:-78.878935},
{x:43.002412,y:-78.857042},
{x:42.894436,y:-78.886367},
{x:42.884762,y:-78.854327},
{x:42.87191,y:-78.793704},
{x:42.943137,y:-78.907031},
{x:42.910112,y:-78.86694},
{x:42.912797,y:-78.814196},
{x:42.915046,y:-78.892545},
{x:42.949768,y:-78.785684},
{x:42.921673,y:-78.82962},
{x:42.953167,y:-78.878823},
{x:42.980402,y:-78.872724},
{x:42.830988,y:-78.804607},
{x:42.792065,y:-78.81166},
{x:42.857957,y:-78.825013},
{x:42.98334,y:-78.696239},
{x:42.961952,y:-78.755115},
{x:42.978969,y:-78.741478},
{x:42.831235,y:-78.754092},
{x:42.907959,y:-78.773054},
{x:42.931286,y:-78.730592},
{x:42.91829,y:-78.753654},
{x:42.958825,y:-78.817025},
{x:42.991835,y:-78.821843},
{x:42.980358,y:-78.80727},
{x:42.88279,y:-78.753482},
{x:43.03944,y:-78.82478},
{x:43.095345,y:-79.040382},
{x:43.090173,y:-78.96937},
{x:43.240582,y:-78.197473},
{x:42.910756,y:-77.735061},
{x:43.198737,y:-77.941929},
{x:42.877128,y:-77.267945},
{x:42.974795,y:-77.357544},
{x:42.56578,y:-77.712905},
{x:43.069625,y:-77.441046},
{x:42.795081,y:-77.794502},
{x:42.858215,y:-76.99588},
{x:43.056802,y:-77.652377},
{x:43.285064,y:-77.790812},
{x:42.833156,y:-77.695378},
{x:42.977155,y:-77.991433},
{x:43.030812,y:-77.907032},
{x:43.060276,y:-76.993774},
{x:43.065077,y:-77.318068},
{x:42.97548,y:-77.229209},
{x:42.728028,y:-77.86909},
{x:43.046838,y:-77.10985},
{x:43.226729,y:-77.322513},
{x:42.680318,y:-76.8195},
{x:43.13035,y:-77.440659},
{x:42.654734,y:-77.058359},
{x:43.182648,y:-77.805811},
{x:43.020025,y:-77.441464},
{x:42.766172,y:-78.126882},
{x:43.209936,y:-77.445184},
{x:43.190249,y:-77.502735},
{x:43.232208,y:-77.184777},
{x:43.208264,y:-76.813064},
{x:43.169806,y:-77.611139},
{x:43.165097,y:-77.662602},
{x:43.165087,y:-77.692631},
{x:43.147742,y:-77.595269},
{x:43.160083,y:-77.565792},
{x:43.145919,y:-77.558998},
{x:43.24909,y:-77.697116},
{x:43.17962,y:-77.631024},
{x:43.238878,y:-77.64625},
{x:43.104458,y:-77.539026},
{x:43.12175,y:-77.618607},
{x:43.195494,y:-77.600535},
{x:43.199076,y:-77.575076},
{x:43.088391,y:-77.630481},
{x:43.095329,y:-77.582785},
{x:43.076316,y:-77.606986},
{x:43.131643,y:-77.686264},
{x:43.102365,y:-77.754902},
{x:43.213622,y:-77.729927},
{x:43.208456,y:-77.690822},
{x:42.119166,y:-79.241826},
{x:42.113812,y:-79.207733},
{x:42.081531,y:-79.229997},
{x:42.097119,y:-79.291649},
{x:42.22267,y:-78.278719},
{x:42.07736,y:-78.459629},
{x:42.158974,y:-78.74409},
{x:42.323517,y:-79.575045},
{x:42.341797,y:-77.33646},
{x:42.142918,y:-77.057837},
{x:42.345283,y:-77.666655},
{x:42.160982,y:-76.878742},
{x:42.156142,y:-76.820668},
{x:42.484849,y:-76.486914},
{x:42.421943,y:-76.517675},
{x:42.159118,y:-77.089803},
{x:42.126904,y:-77.967227},
{x:42.092562,y:-76.809243},
{x:42.057302,y:-76.79519},
{x:40.634363,y:-80.317707},
{x:40.570474,y:-80.255992},
{x:40.633333,y:-80.229814},
{x:40.689194,y:-80.319984},
{x:40.772803,y:-80.372978},
{x:40.757638,y:-80.319712},
{x:40.134136,y:-79.861664},
{x:40.137239,y:-79.843432},
{x:40.353721,y:-80.11461},
{x:40.380948,y:-80.393553},
{x:40.13735,y:-79.896053},
{x:40.621498,y:-79.944107},
{x:40.680562,y:-80.301461},
{x:40.202971,y:-79.925433},
{x:40.626405,y:-79.72217},
{x:40.591032,y:-79.735504},
{x:40.551872,y:-79.756922},
{x:40.440902,y:-80.239349},
{x:40.564306,y:-79.804076},
{x:40.610166,y:-80.054025},
{x:40.590007,y:-79.947934},
{x:40.331891,y:-80.022367},
{x:40.3863,y:-80.093111},
{x:40.517893,y:-80.163059},
{x:40.516946,y:-80.219606},
{x:40.384225,y:-79.863302},
{x:40.513364,y:-79.95166},
{x:40.412093,y:-79.909881},
{x:40.341785,y:-79.946426},
{x:40.331689,y:-79.945348},
{x:40.347947,y:-79.945316},
{x:40.320988,y:-79.835764},
{x:40.473952,y:-80.095718},
{x:40.384128,y:-79.824052},
{x:40.434495,y:-79.751797},
{x:40.435395,y:-79.786862},
{x:40.496755,y:-79.844954},
{x:40.492163,y:-80.062165},
{x:40.448301,y:-80.155585},
{x:40.427424,y:-80.053412},
{x:40.458783,y:-79.918492},
{x:40.46308,y:-79.926919},
{x:40.410177,y:-79.990845},
{x:40.441228,y:-79.957259},
{x:40.440346,y:-79.998524},
{x:40.444645,y:-79.888658},
{x:40.449329,y:-79.986283},
{x:40.441242,y:-80.003619},
{x:40.442359,y:-79.999117},
{x:40.398794,y:-80.026418},
{x:40.364627,y:-79.9839},
{x:40.517558,y:-80.029286},
{x:40.496115,y:-80.246324},
{x:40.450829,y:-80.019001},
{x:40.368621,y:-80.037877},
{x:40.465128,y:-79.828945},
{x:40.477237,y:-79.788099},
{x:40.546367,y:-80.016366},
{x:40.528661,y:-80.008203},
{x:40.53923,y:-79.830173},
{x:40.486928,y:-79.889575},
{x:40.450605,y:-79.7142},
{x:40.338068,y:-80.050782},
{x:40.447246,y:-80.176392},
{x:40.449186,y:-80.173152},
{x:40.223689,y:-80.21048},
{x:40.165395,y:-80.270972},
{x:40.185441,y:-80.222025},
{x:40.181765,y:-80.264644},
{x:40.182814,y:-80.221955},
{x:40.130093,y:-80.001926},
{x:40.277908,y:-80.123986},
{x:40.263549,y:-80.172296},
{x:39.895985,y:-80.169968},
{x:39.899653,y:-80.147017},
{x:39.902759,y:-79.75294},
{x:39.883933,y:-79.734874},
{x:39.906987,y:-79.741024},
{x:40.024168,y:-79.591577},
{x:40.011865,y:-79.865486},
{x:40.062959,y:-79.765121},
{x:40.052096,y:-79.07064},
{x:40.042096,y:-79.074849},
{x:40.016904,y:-79.078694},
{x:40.053041,y:-78.511477},
{x:39.998588,y:-78.239981},
{x:40.025341,y:-78.446484},
{x:40.308908,y:-79.581345},
{x:40.305702,y:-79.591215},
{x:40.303522,y:-79.510756},
{x:40.284765,y:-79.548085},
{x:40.400993,y:-79.585086},
{x:40.107039,y:-79.378204},
{x:40.320951,y:-79.692043},
{x:40.332078,y:-79.742173},
{x:40.284666,y:-79.389437},
{x:40.307535,y:-79.371665},
{x:40.605322,y:-79.605027},
{x:40.126043,y:-79.552131},
{x:40.427439,y:-79.699094},
{x:40.21774,y:-79.610436},
{x:40.600876,y:-79.194721},
{x:40.615709,y:-79.170665},
{x:40.615144,y:-79.156281},
{x:40.443126,y:-79.229589},
{x:40.943874,y:-78.97094},
{x:41.124748,y:-78.737881},
{x:41.2454,y:-78.787119},
{x:41.17392,y:-79.098905},
{x:41.411149,y:-78.560161},
{x:40.280377,y:-78.861939},
{x:40.320586,y:-78.919325},
{x:40.297312,y:-78.834841},
{x:40.276759,y:-78.846054},
{x:40.323326,y:-78.955615},
{x:40.468237,y:-78.757869},
{x:40.394666,y:-78.671849},
{x:40.229494,y:-78.828574},
{x:40.89466,y:-79.906532},
{x:40.842953,y:-79.918668},
{x:40.878159,y:-79.948132},
{x:40.878198,y:-79.943136},
{x:40.671864,y:-80.098671},
{x:40.964803,y:-80.13995},
{x:41.06159,y:-80.054174},
{x:40.687773,y:-80.105564},
{x:40.686173,y:-80.08256},
{x:40.970142,y:-80.311786},
{x:41.01122,y:-80.394065},
{x:41.027644,y:-80.353377},
{x:40.861944,y:-80.286911},
{x:41.412282,y:-80.3589},
{x:41.143904,y:-80.158063},
{x:41.157927,y:-80.089403},
{x:41.232589,y:-80.510139},
{x:41.235456,y:-80.45031},
{x:41.23288,y:-80.472021},
{x:40.81247,y:-79.548174},
{x:41.190456,y:-79.395959},
{x:41.213489,y:-79.381783},
{x:41.432934,y:-79.707274},
{x:41.350345,y:-79.715211},
{x:41.399711,y:-79.830716},
{x:41.624118,y:-80.199633},
{x:41.634777,y:-80.154453},
{x:41.623478,y:-79.672897},
{x:41.843008,y:-79.145376},
{x:41.890768,y:-80.350976},
{x:41.934866,y:-79.652691},
{x:41.875718,y:-80.129197},
{x:42.009804,y:-80.301764},
{x:42.231782,y:-79.783128},
{x:41.900731,y:-79.845136},
{x:42.123057,y:-80.04312},
{x:42.130431,y:-80.086466},
{x:42.122705,y:-80.085192},
{x:42.102353,y:-80.146746},
{x:42.08933,y:-80.133073},
{x:42.08933,y:-80.085535},
{x:42.071143,y:-80.039903},
{x:42.053152,y:-80.086596},
{x:42.064011,y:-80.100905},
{x:42.146042,y:-80.004448},
{x:40.535334,y:-78.402081},
{x:40.479696,y:-78.402451},
{x:40.511585,y:-78.401743},
{x:40.455538,y:-78.40559},
{x:40.460006,y:-78.595458},
{x:40.435656,y:-78.41418},
{x:40.480238,y:-78.017185},
{x:40.335719,y:-78.391411},
{x:41.956293,y:-78.645833},
{x:40.83013,y:-77.804328},
{x:40.785216,y:-77.830027},
{x:40.79853,y:-77.855963},
{x:40.808525,y:-77.895766},
{x:41.038028,y:-78.398824},
{x:41.010827,y:-78.449548},
{x:41.033032,y:-77.516961},
{x:40.957659,y:-77.766788},
{x:40.903246,y:-78.232155},
{x:41.750745,y:-77.296578},
{x:41.77087,y:-78.021671},
{x:41.8104,y:-77.078887},
{x:41.789396,y:-76.78055},
{x:40.230351,y:-76.891527},
{x:40.231358,y:-76.953239},
{x:40.185962,y:-77.210809},
{x:40.200242,y:-77.177442},
{x:40.232627,y:-77.142506},
{x:40.189634,y:-77.194795},
{x:40.131475,y:-77.018457},
{x:40.139354,y:-76.589438},
{x:40.559013,y:-76.788959},
{x:40.300125,y:-76.928025},
{x:40.454811,y:-76.925804},
{x:40.26776,y:-76.689691},
{x:40.449619,y:-76.515324},
{x:40.343079,y:-76.397716},
{x:40.313299,y:-76.424998},
{x:40.33614,y:-76.484491},
{x:40.250511,y:-76.914481},
{x:40.582395,y:-77.613407},
{x:40.615078,y:-77.568334},
{x:40.350011,y:-76.432817},
{x:40.240814,y:-76.987064},
{x:40.247998,y:-77.023376},
{x:40.211265,y:-76.961301},
{x:40.176157,y:-76.985402},
{x:40.248773,y:-77.030012},
{x:40.201625,y:-76.770243},
{x:40.225946,y:-76.720511},
{x:40.581344,y:-77.370187},
{x:40.382384,y:-77.878357},
{x:40.37812,y:-76.310991},
{x:40.212456,y:-76.878904},
{x:40.492739,y:-77.114059},
{x:40.314099,y:-76.583017},
{x:40.278009,y:-76.821769},
{x:40.300867,y:-76.814717},
{x:40.325063,y:-76.898763},
{x:40.292099,y:-76.892619},
{x:40.271467,y:-76.844257},
{x:40.26399,y:-76.783967},
{x:40.261676,y:-76.772767},
{x:40.240526,y:-76.805128},
{x:40.255521,y:-76.838712},
{x:40.320075,y:-76.793509},
{x:40.352618,y:-76.725393},
{x:39.96235,y:-77.577523},
{x:39.930338,y:-77.638894},
{x:39.972258,y:-77.632735},
{x:39.786693,y:-77.711214},
{x:39.930017,y:-77.990181},
{x:39.835453,y:-77.906015},
{x:40.054245,y:-77.514379},
{x:39.752273,y:-77.572499},
{x:40.153867,y:-76.819676},
{x:39.84164,y:-77.206933},
{x:39.818002,y:-77.235913},
{x:39.770847,y:-76.959866},
{x:39.815911,y:-76.992822},
{x:39.825386,y:-76.983317},
{x:39.749745,y:-77.093772},
{x:40.046919,y:-76.723279},
{x:39.870723,y:-77.029691},
{x:39.896932,y:-76.622358},
{x:39.915653,y:-76.630864},
{x:39.770639,y:-76.670352},
{x:39.959895,y:-76.726888},
{x:39.992722,y:-76.636715},
{x:39.989845,y:-76.675278},
{x:39.978156,y:-76.681949},
{x:39.910693,y:-76.70441},
{x:39.937436,y:-76.692637},
{x:39.966056,y:-76.77593},
{x:39.968383,y:-76.772715},
{x:39.983698,y:-76.736592},
{x:39.983901,y:-76.822231},
{x:40.031273,y:-76.47388},
{x:40.165105,y:-76.156964},
{x:40.187572,y:-76.184955},
{x:39.991651,y:-76.020426},
{x:40.130241,y:-76.305578},
{x:40.112805,y:-76.450738},
{x:40.149239,y:-76.380455},
{x:40.092685,y:-76.116924},
{x:40.037244,y:-76.308628},
{x:40.070847,y:-76.333503},
{x:40.050879,y:-76.394325},
{x:40.051827,y:-76.255586},
{x:40.076214,y:-76.296029},
{x:40.048162,y:-76.293191},
{x:40.030381,y:-76.234969},
{x:39.992521,y:-76.284973},
{x:40.027261,y:-76.326144},
{x:40.038473,y:-76.351686},
{x:41.256062,y:-77.043196},
{x:41.249746,y:-76.970059},
{x:41.227005,y:-76.987102},
{x:41.230081,y:-76.73465},
{x:41.134896,y:-77.453732},
{x:41.153118,y:-77.354147},
{x:41.243982,y:-76.829763},
{x:41.0051,y:-76.436487},
{x:41.008674,y:-76.485313},
{x:40.993624,y:-76.64012},
{x:40.860479,y:-76.549965},
{x:40.991577,y:-76.879004},
{x:40.980375,y:-76.885258},
{x:40.921469,y:-77.035953},
{x:41.019422,y:-76.853699},
{x:40.790326,y:-76.538228},
{x:40.841678,y:-76.827094},
{x:40.704293,y:-76.186929},
{x:40.770304,y:-76.222504},
{x:40.534483,y:-76.42629},
{x:40.640927,y:-76.157296},
{x:40.609145,y:-75.385566},
{x:40.67552,y:-75.344847},
{x:40.643841,y:-75.345975},
{x:40.712415,y:-75.219599},
{x:40.688549,y:-75.252759},
{x:40.687109,y:-75.209282},
{x:40.715486,y:-75.285281},
{x:40.529028,y:-75.506629},
{x:40.645066,y:-75.487288},
{x:40.636127,y:-75.487068},
{x:40.64662,y:-75.495486},
{x:40.5673,y:-75.340139},
{x:40.520238,y:-75.56977},
{x:40.39307,y:-75.501937},
{x:40.678216,y:-75.614351},
{x:40.55127,y:-75.593937},
{x:40.757416,y:-75.594435},
{x:40.836097,y:-75.297208},
{x:40.60734,y:-75.491614},
{x:40.585989,y:-75.460678},
{x:40.559727,y:-75.487806},
{x:40.574503,y:-75.532775},
{x:40.598487,y:-75.527624},
{x:40.625051,y:-75.435443},
{x:40.981301,y:-76.017684},
{x:40.979787,y:-75.979536},
{x:40.986516,y:-76.012507},
{x:40.814799,y:-75.734335},
{x:40.86115,y:-75.818688},
{x:40.82351,y:-75.981512},
{x:41.055671,y:-75.092739},
{x:40.922096,y:-75.399965},
{x:41.35172,y:-74.733775},
{x:41.12259,y:-75.372718},
{x:40.988453,y:-75.189334},
{x:40.986749,y:-75.224836},
{x:41.566016,y:-75.51423},
{x:41.486469,y:-75.695099},
{x:41.356154,y:-75.05476},
{x:41.553394,y:-75.225179},
{x:41.502255,y:-75.580831},
{x:41.404432,y:-75.417505},
{x:41.307961,y:-75.514418},
{x:41.44534,y:-75.669671},
{x:41.471035,y:-75.637589},
{x:41.421243,y:-75.650567},
{x:41.435485,y:-75.62023},
{x:41.368547,y:-75.737854},
{x:41.399742,y:-75.673317},
{x:41.047317,y:-76.274064},
{x:41.026499,y:-76.317194},
{x:41.307042,y:-75.764104},
{x:41.301328,y:-75.854054},
{x:41.542176,y:-75.959456},
{x:41.071491,y:-75.702194},
{x:41.241025,y:-75.881075},
{x:41.224963,y:-75.878647},
{x:41.231926,y:-75.921241},
{x:41.250828,y:-75.844593},
{x:41.258652,y:-75.901574},
{x:41.261269,y:-75.867621},
{x:41.143554,y:-75.900468},
{x:41.322314,y:-75.942482},
{x:41.282393,y:-75.89664},
{x:41.835971,y:-75.865075},
{x:41.964809,y:-75.740725},
{x:41.822565,y:-75.682412},
{x:41.994183,y:-76.530016},
{x:41.768905,y:-76.422885},
{x:40.296413,y:-75.18908},
{x:40.322711,y:-75.130079},
{x:40.265876,y:-75.226722},
{x:40.260093,y:-75.08595},
{x:40.363557,y:-74.966217},
{x:40.364256,y:-75.297739},
{x:40.386278,y:-75.145927},
{x:40.444085,y:-75.359409},
{x:40.213555,y:-75.011158},
{x:40.3163,y:-75.308881},
{x:40.166557,y:-75.046737},
{x:40.218303,y:-75.138972},
{x:40.131169,y:-75.115847},
{x:40.009359,y:-75.293123},
{x:40.125193,y:-74.881372},
{x:40.1005,y:-74.869434},
{x:39.982434,y:-75.361434},
{x:40.026812,y:-75.328408},
{x:39.872876,y:-75.389796},
{x:39.926325,y:-75.306877},
{x:40.07164,y:-74.966169},
{x:40.09647,y:-74.94585},
{x:39.865832,y:-75.341757},
{x:39.920196,y:-75.26436},
{x:40.144085,y:-75.16762},
{x:39.955992,y:-75.321084},
{x:40.171549,y:-74.851537},
{x:40.110584,y:-75.213295},
{x:40.179979,y:-75.105904},
{x:39.900275,y:-75.30403},
{x:40.175038,y:-75.128715},
{x:40.146082,y:-74.99347},
{x:40.164285,y:-74.902364},
{x:39.940011,y:-75.264559},
{x:40.136816,y:-74.965131},
{x:40.132689,y:-74.829201},
{x:40.151127,y:-74.859362},
{x:39.849649,y:-75.449744},
{x:39.834319,y:-75.424938},
{x:39.914305,y:-75.433866},
{x:40.210447,y:-74.795079},
{x:39.881886,y:-75.306348},
{x:39.957409,y:-75.257655},
{x:39.959791,y:-75.287515},
{x:39.972507,y:-75.318433},
{x:39.878701,y:-75.344206},
{x:39.954608,y:-75.163381},
{x:39.949927,y:-75.169541},
{x:39.954342,y:-75.202337},
{x:40.074102,y:-75.084368},
{x:40.102193,y:-75.029522},
{x:40.068736,y:-75.199149},
{x:39.972099,y:-75.158826},
{x:39.985228,y:-75.155994},
{x:39.968354,y:-75.135705},
{x:40.012382,y:-75.118959},
{x:40.032346,y:-75.10653},
{x:40.006304,y:-75.094575},
{x:40.022613,y:-75.077622},
{x:40.030769,y:-75.085437},
{x:40.063817,y:-75.238855},
{x:39.999466,y:-75.2294},
{x:39.979957,y:-75.224318},
{x:39.995854,y:-75.166119},
{x:40.004814,y:-75.179781},
{x:40.001157,y:-75.15262},
{x:39.991105,y:-75.135355},
{x:40.022321,y:-75.050729},
{x:40.050367,y:-75.009526},
{x:39.99463,y:-75.093707},
{x:39.965999,y:-75.232234},
{x:39.95814,y:-75.225038},
{x:40.01737,y:-75.149889},
{x:40.055862,y:-75.157837},
{x:39.930819,y:-75.227616},
{x:40.036686,y:-75.174316},
{x:39.919394,y:-75.187246},
{x:39.939465,y:-75.191225},
{x:39.939428,y:-75.166777},
{x:39.924614,y:-75.169415},
{x:39.926222,y:-75.141272},
{x:39.923923,y:-75.142546},
{x:39.913805,y:-75.151145},
{x:40.044473,y:-75.053345},
{x:40.072964,y:-75.157665},
{x:39.980081,y:-75.268621},
{x:40.056397,y:-75.060584},
{x:39.903164,y:-75.239145},
{x:39.879349,y:-75.241332},
{x:40.088491,y:-74.965013},
{x:40.070722,y:-75.44271},
{x:39.988543,y:-75.789567},
{x:39.879156,y:-75.545939},
{x:40.007621,y:-75.700651},
{x:40.033592,y:-75.632916},
{x:40.049657,y:-75.662234},
{x:39.842364,y:-75.729778},
{x:40.042656,y:-75.529528},
{x:39.796094,y:-75.976494},
{x:39.983139,y:-75.933937},
{x:39.995692,y:-75.745329},
{x:39.965278,y:-75.595578},
{x:39.942395,y:-75.590869},
{x:39.823582,y:-75.872795},
{x:40.116429,y:-75.348007},
{x:40.143917,y:-75.311532},
{x:40.128063,y:-75.405195},
{x:40.152293,y:-75.347046},
{x:40.095668,y:-75.365097},
{x:40.185038,y:-75.451145},
{x:40.067948,y:-75.311567},
{x:40.094447,y:-75.29003},
{x:40.266803,y:-75.376321},
{x:40.288169,y:-75.265629},
{x:40.229786,y:-75.31334},
{x:40.263725,y:-75.317924},
{x:40.227288,y:-75.259117},
{x:40.209598,y:-75.224248},
{x:40.132316,y:-75.534345},
{x:40.242917,y:-75.619695},
{x:40.252745,y:-75.65977},
{x:40.194585,y:-75.53362},
{x:40.217226,y:-75.501968},
{x:40.259218,y:-75.728384},
{x:40.326546,y:-75.619884},
{x:40.517508,y:-76.111},
{x:40.562553,y:-76.005556},
{x:40.443088,y:-75.953881},
{x:40.158108,y:-75.891362},
{x:40.416003,y:-75.921919},
{x:40.369963,y:-76.190087},
{x:40.320728,y:-75.934301},
{x:40.350678,y:-75.919356},
{x:40.384911,y:-75.92639},
{x:40.327341,y:-75.881014},
{x:40.298659,y:-75.847656},
{x:40.325001,y:-76.016574},
{x:40.336586,y:-75.954262},
{x:40.356142,y:-75.982787},
{x:39.633093,y:-75.66112},
{x:39.605391,y:-75.744535},
{x:39.678595,y:-75.651351},
{x:39.794123,y:-75.467334},
{x:39.446326,y:-75.737879},
{x:39.450991,y:-75.72523},
{x:39.685636,y:-75.737484},
{x:39.685885,y:-75.682625},
{x:39.654952,y:-75.7506},
{x:39.665047,y:-75.605552},
{x:39.700374,y:-75.551561},
{x:39.685573,y:-75.584892},
{x:39.742851,y:-75.55812},
{x:39.761239,y:-75.521743},
{x:39.795385,y:-75.546905},
{x:39.715424,y:-75.630316},
{x:39.741454,y:-75.583418},
{x:39.722992,y:-75.66086},
{x:39.73593,y:-75.629333},
{x:39.198839,y:-75.555159},
{x:39.182956,y:-75.531019},
{x:39.158585,y:-75.558162},
{x:39.145759,y:-75.515897},
{x:39.129375,y:-75.494105},
{x:38.52108,y:-75.055672},
{x:38.721682,y:-75.589491},
{x:39.10272,y:-75.546217},
{x:38.456926,y:-75.55745},
{x:38.692585,y:-75.398376},
{x:38.929751,y:-75.566181},
{x:38.918849,y:-75.441217},
{x:38.571091,y:-75.283768},
{x:38.633944,y:-75.194071},
{x:38.731586,y:-75.134762},
{x:38.653074,y:-75.595114},
{x:38.459518,y:-75.232873},
{x:38.468439,y:-75.117534},
{x:39.306704,y:-75.607929},
{x:38.895975,y:-77.019471},
{x:38.920612,y:-77.022295},
{x:38.899242,y:-76.980218},
{x:38.897304,y:-77.005947},
{x:38.90793,y:-77.006302},
{x:38.920553,y:-76.999432},
{x:38.91771,y:-76.973433},
{x:38.878647,y:-76.982239},
{x:38.89632,y:-77.024749},
{x:38.898587,y:-77.021605},
{x:38.897163,y:-77.029569},
{x:38.900598,y:-77.029373},
{x:38.899637,y:-77.0396},
{x:38.924242,y:-77.052208},
{x:38.916853,y:-77.032147},
{x:38.922595,y:-77.042391},
{x:38.912005,y:-77.038232},
{x:38.962396,y:-77.028342},
{x:38.980646,y:-77.026208},
{x:38.960144,y:-77.086093},
{x:38.942967,y:-77.077411},
{x:38.949527,y:-76.993283},
{x:38.915887,y:-76.993363},
{x:38.893411,y:-76.949857},
{x:38.901179,y:-76.941614},
{x:38.860001,y:-76.968874},
{x:38.885898,y:-77.018009},
{x:38.90552,y:-77.044238},
{x:38.772839,y:-77.505369},
{x:38.77768,y:-77.505643},
{x:38.798926,y:-77.517636},
{x:38.752862,y:-77.448565},
{x:38.739609,y:-77.524718},
{x:38.759928,y:-77.461557},
{x:38.742675,y:-77.451224},
{x:38.732711,y:-77.47464},
{x:38.776363,y:-77.452004},
{x:38.862108,y:-77.848083},
{x:38.853195,y:-77.450062},
{x:38.834438,y:-77.432793},
{x:39.13716,y:-77.70572},
{x:39.042858,y:-77.473914},
{x:39.035754,y:-77.502119},
{x:39.008466,y:-77.507102},
{x:38.910918,y:-77.444164},
{x:38.886159,y:-77.44432},
{x:38.922755,y:-77.506639},
{x:38.795259,y:-77.618031},
{x:39.013961,y:-77.371759},
{x:38.999672,y:-77.405103},
{x:39.03544,y:-77.397734},
{x:39.037994,y:-77.414117},
{x:38.962557,y:-77.495786},
{x:39.024964,y:-77.424447},
{x:39.022768,y:-77.428776},
{x:38.81454,y:-77.641919},
{x:38.967968,y:-77.374127},
{x:38.966025,y:-77.396141},
{x:38.95054,y:-77.410714},
{x:39.113121,y:-77.538803},
{x:39.111182,y:-77.557488},
{x:38.621326,y:-77.801336},
{x:38.726315,y:-77.803167},
{x:38.951015,y:-77.334913},
{x:38.621904,y:-76.901758},
{x:38.655211,y:-76.878204},
{x:38.629281,y:-76.906642},
{x:38.615681,y:-76.922785},
{x:38.677266,y:-76.874546},
{x:38.628008,y:-77.072243},
{x:38.296549,y:-76.508177},
{x:38.493766,y:-76.774416},
{x:38.545758,y:-76.984391},
{x:38.536685,y:-76.984382},
{x:38.30508,y:-76.659387},
{x:38.260123,y:-76.465824},
{x:38.551287,y:-76.592209},
{x:38.345736,y:-76.461029},
{x:39.027919,y:-76.916841},
{x:39.047858,y:-76.937462},
{x:38.961321,y:-76.862056},
{x:38.989342,y:-76.823043},
{x:39.098654,y:-76.806402},
{x:39.094155,y:-76.855027},
{x:39.074506,y:-76.849073},
{x:38.972998,y:-76.756549},
{x:38.949673,y:-76.718817},
{x:38.900029,y:-76.795346},
{x:38.936233,y:-76.946826},
{x:39.110604,y:-76.836485},
{x:39.119311,y:-76.850562},
{x:39.0986,y:-76.802674},
{x:38.688587,y:-76.535592},
{x:38.772291,y:-76.889572},
{x:38.784713,y:-76.894072},
{x:38.766633,y:-76.886317},
{x:38.964256,y:-76.939496},
{x:38.959446,y:-76.919383},
{x:38.992171,y:-76.933008},
{x:38.996314,y:-76.905281},
{x:38.988251,y:-76.945091},
{x:38.866501,y:-76.850793},
{x:38.889256,y:-76.862078},
{x:38.9014,y:-76.900674},
{x:38.868422,y:-76.892449},
{x:38.873168,y:-76.931241},
{x:38.725709,y:-76.991092},
{x:38.792589,y:-76.928851},
{x:38.75713,y:-76.994357},
{x:38.789721,y:-77.013841},
{x:38.804601,y:-76.976252},
{x:38.813793,y:-76.964173},
{x:38.81489,y:-76.997762},
{x:38.850653,y:-76.922077},
{x:38.821794,y:-76.886498},
{x:38.844539,y:-76.890702},
{x:38.843727,y:-76.950472},
{x:38.725311,y:-76.659331},
{x:39.147921,y:-76.908322},
{x:38.760498,y:-76.807209},
{x:38.823591,y:-76.737532},
{x:38.902395,y:-76.83654},
{x:38.968118,y:-76.957531},
{x:38.984558,y:-76.970613},
{x:38.989475,y:-76.987094},
{x:38.940033,y:-76.90742},
{x:38.890853,y:-76.854547},
{x:38.927855,y:-76.881184},
{x:39.145282,y:-76.809598},
{x:39.155667,y:-76.73242},
{x:39.168282,y:-76.783607},
{x:38.964725,y:-77.104356},
{x:39.024035,y:-77.14591},
{x:39.151656,y:-77.067299},
{x:39.144242,y:-77.410931},
{x:39.074394,y:-77.115041},
{x:39.068187,y:-77.128655},
{x:39.04671,y:-77.113183},
{x:39.041813,y:-77.157868},
{x:39.129743,y:-77.167487},
{x:39.145532,y:-77.144546},
{x:39.114565,y:-77.163513},
{x:39.11026,y:-76.933469},
{x:39.290104,y:-77.204444},
{x:39.146726,y:-77.274318},
{x:39.180828,y:-77.269514},
{x:39.198746,y:-77.252421},
{x:39.203975,y:-77.244535},
{x:39.178398,y:-77.239563},
{x:39.153826,y:-77.204214},
{x:39.148956,y:-77.206922},
{x:39.140671,y:-77.22197},
{x:39.144935,y:-77.215538},
{x:39.117249,y:-77.251871},
{x:39.172054,y:-77.153435},
{x:39.173456,y:-77.204169},
{x:39.156299,y:-77.16463},
{x:39.184968,y:-77.188126},
{x:39.03647,y:-77.054962},
{x:39.039636,y:-77.057385},
{x:39.020694,y:-77.013892},
{x:39.038656,y:-76.990441},
{x:39.082833,y:-76.94723},
{x:39.053608,y:-76.954273},
{x:39.075985,y:-77.00092},
{x:39.090881,y:-77.046997},
{x:39.078719,y:-77.08018},
{x:39.056202,y:-77.081824},
{x:39.059175,y:-77.049642},
{x:38.995117,y:-77.030197},
{x:38.965806,y:-77.001895},
{x:39.523412,y:-76.184676},
{x:39.497476,y:-76.176817},
{x:39.462023,y:-76.317882},
{x:39.029326,y:-76.504959},
{x:39.502663,y:-76.327606},
{x:39.557372,y:-76.370504},
{x:39.566977,y:-76.354571},
{x:39.5264,y:-76.353552},
{x:39.554471,y:-76.287045},
{x:39.47925,y:-76.248023},
{x:39.210252,y:-76.937327},
{x:39.494335,y:-76.65025},
{x:38.930279,y:-76.566163},
{x:39.436053,y:-76.306096},
{x:39.277026,y:-76.832644},
{x:39.251907,y:-76.815992},
{x:39.282742,y:-76.82074},
{x:39.214924,y:-76.861789},
{x:39.221893,y:-76.8893},
{x:39.209583,y:-76.799655},
{x:39.196985,y:-76.819052},
{x:39.186232,y:-76.844888},
{x:39.187031,y:-76.7987},
{x:39.170717,y:-76.864231},
{x:39.491619,y:-76.395434},
{x:39.022964,y:-76.69392},
{x:39.040099,y:-76.672237},
{x:39.196146,y:-76.598308},
{x:39.165091,y:-76.621925},
{x:39.134542,y:-76.585372},
{x:39.186414,y:-76.613419},
{x:39.172075,y:-76.632203},
{x:39.595565,y:-76.845934},
{x:39.209126,y:-76.723835},
{x:39.153172,y:-76.725995},
{x:39.553384,y:-76.100717},
{x:39.191589,y:-76.682349},
{x:39.223993,y:-76.658575},
{x:39.441908,y:-76.62727},
{x:39.117822,y:-76.634082},
{x:39.081734,y:-76.688878},
{x:39.405066,y:-76.810952},
{x:39.130499,y:-76.557181},
{x:39.119468,y:-76.516762},
{x:39.157185,y:-76.516815},
{x:39.400986,y:-76.478111},
{x:39.516409,y:-76.55825},
{x:39.375055,y:-76.811088},
{x:39.449762,y:-76.818089},
{x:39.137342,y:-76.686749},
{x:39.07808,y:-76.545308},
{x:39.079321,y:-76.548666},
{x:39.568075,y:-76.978686},
{x:39.585445,y:-76.98407},
{x:39.378255,y:-76.459196},
{x:39.28911,y:-76.622133},
{x:39.310641,y:-76.617139},
{x:39.290334,y:-76.619251},
{x:39.408845,y:-76.608799},
{x:39.315155,y:-76.544693},
{x:39.342985,y:-76.544044},
{x:39.312452,y:-76.737698},
{x:39.336698,y:-76.712922},
{x:39.313418,y:-76.749116},
{x:39.381551,y:-76.731293},
{x:39.367451,y:-76.716683},
{x:39.335614,y:-76.636671},
{x:39.350388,y:-76.61021},
{x:39.313688,y:-76.596111},
{x:39.309395,y:-76.561964},
{x:39.342813,y:-76.568476},
{x:39.3477,y:-76.693723},
{x:39.33941,y:-76.667958},
{x:39.323284,y:-76.609934},
{x:39.340528,y:-76.590571},
{x:39.32906,y:-76.439994},
{x:39.333528,y:-76.445117},
{x:39.337771,y:-76.402523},
{x:39.31343,y:-76.464459},
{x:39.307576,y:-76.443192},
{x:39.262489,y:-76.526675},
{x:39.271945,y:-76.471023},
{x:39.269102,y:-76.50039},
{x:39.292978,y:-76.656737},
{x:39.29579,y:-76.56914},
{x:39.278814,y:-76.546617},
{x:39.288077,y:-76.536686},
{x:39.29155,y:-76.507939},
{x:39.238874,y:-76.611871},
{x:39.224198,y:-76.613124},
{x:39.288543,y:-76.727557},
{x:39.284708,y:-76.752486},
{x:39.272648,y:-76.727596},
{x:39.280542,y:-76.671423},
{x:39.270342,y:-76.680767},
{x:39.256965,y:-76.663815},
{x:39.271601,y:-76.602161},
{x:39.397685,y:-76.540369},
{x:39.372808,y:-76.566954},
{x:39.368603,y:-76.450982},
{x:39.373813,y:-76.508685},
{x:39.379152,y:-76.510243},
{x:39.336323,y:-76.489914},
{x:39.33732,y:-76.487882},
{x:39.313056,y:-76.525502},
{x:39.338755,y:-76.505608},
{x:39.180622,y:-76.665085},
{x:39.179615,y:-76.668343},
{x:39.355771,y:-76.765025},
{x:39.384705,y:-76.577897},
{x:39.396579,y:-76.565222},
{x:38.98049,y:-76.527708},
{x:38.987674,y:-76.54922},
{x:38.948851,y:-76.491062},
{x:39.0259,y:-76.444276},
{x:39.631199,y:-78.754575},
{x:39.636322,y:-78.837712},
{x:39.57911,y:-78.847968},
{x:39.652725,y:-78.759572},
{x:39.671818,y:-78.716293},
{x:39.624928,y:-78.835057},
{x:39.651016,y:-78.910497},
{x:39.694156,y:-79.246806},
{x:39.411014,y:-79.404822},
{x:39.501648,y:-79.328147},
{x:39.480642,y:-79.040132},
{x:38.781845,y:-76.08196},
{x:38.779531,y:-76.060755},
{x:38.564607,y:-76.065962},
{x:39.031769,y:-76.076241},
{x:38.976655,y:-76.289274},
{x:39.230415,y:-76.07186},
{x:38.894036,y:-75.821544},
{x:38.696872,y:-75.787237},
{x:38.975918,y:-76.311811},
{x:39.407569,y:-77.430143},
{x:39.408546,y:-77.391034},
{x:39.446229,y:-77.393731},
{x:39.439816,y:-77.438033},
{x:39.42167,y:-77.467203},
{x:39.43102,y:-77.420987},
{x:39.418889,y:-77.44947},
{x:39.373969,y:-77.410903},
{x:39.390053,y:-77.408821},
{x:39.325666,y:-77.348893},
{x:39.655655,y:-77.927545},
{x:39.703398,y:-77.316807},
{x:39.629616,y:-77.783012},
{x:39.639585,y:-77.713994},
{x:39.627684,y:-77.765697},
{x:39.653746,y:-77.757941},
{x:39.614081,y:-77.691882},
{x:39.602871,y:-77.728665},
{x:39.688345,y:-77.731235},
{x:39.662355,y:-77.70395},
{x:39.337431,y:-77.640508},
{x:39.363313,y:-77.164599},
{x:39.498944,y:-77.566957},
{x:39.384961,y:-77.256668},
{x:39.399656,y:-76.932826},
{x:39.648818,y:-77.157486},
{x:39.613511,y:-77.418723},
{x:39.48336,y:-77.346275},
{x:39.604519,y:-77.808117},
{x:39.349967,y:-77.067939},
{x:38.367104,y:-75.548384},
{x:38.330283,y:-75.611929},
{x:38.368397,y:-75.617954},
{x:38.417393,y:-75.569201},
{x:38.416432,y:-75.565266},
{x:38.377198,y:-75.165136},
{x:38.344639,y:-75.165786},
{x:37.991144,y:-75.844569},
{x:38.428695,y:-75.055911},
{x:38.361166,y:-75.073844},
{x:38.074761,y:-75.559087},
{x:38.214228,y:-75.701049},
{x:38.187717,y:-75.376911},
{x:39.607939,y:-75.947167},
{x:39.698322,y:-76.055751},
{x:39.600573,y:-75.827212},
{x:39.635821,y:-75.80794},
{x:39.60313,y:-75.79274},
{x:38.812311,y:-77.184997},
{x:38.808558,y:-77.254811},
{x:38.832968,y:-77.207788},
{x:38.777255,y:-77.27476},
{x:38.799769,y:-77.323346},
{x:38.576155,y:-77.316754},
{x:38.560541,y:-77.331124},
{x:38.843603,y:-77.272236},
{x:38.86375,y:-77.275367},
{x:38.854159,y:-77.333126},
{x:38.854789,y:-77.31566},
{x:38.870205,y:-77.262398},
{x:38.82564,y:-77.314993},
{x:38.86144,y:-77.387097},
{x:38.879825,y:-77.396025},
{x:38.84798,y:-77.12495},
{x:38.865657,y:-77.196678},
{x:38.869057,y:-77.226756},
{x:38.868838,y:-77.176824},
{x:38.867723,y:-77.146427},
{x:38.894636,y:-77.191032},
{x:38.704524,y:-77.207321},
{x:38.936742,y:-77.183761},
{x:38.9179,y:-77.221634},
{x:38.519196,y:-77.347341},
{x:38.782482,y:-77.185963},
{x:38.739413,y:-77.192496},
{x:38.800812,y:-77.185341},
{x:38.781669,y:-77.234225},
{x:38.742749,y:-77.211239},
{x:38.888403,y:-77.243644},
{x:38.893034,y:-77.274962},
{x:38.915724,y:-77.237917},
{x:38.927152,y:-77.238819},
{x:38.914353,y:-77.225584},
{x:38.635399,y:-77.269745},
{x:38.654505,y:-77.252067},
{x:38.673427,y:-77.261385},
{x:38.676916,y:-77.279454},
{x:38.651063,y:-77.297857},
{x:38.673282,y:-77.335592},
{x:38.652568,y:-77.315101},
{x:38.644689,y:-77.337568},
{x:38.658973,y:-77.369602},
{x:38.640228,y:-77.295268},
{x:38.626916,y:-77.302424},
{x:38.863194,y:-77.0607},
{x:38.850054,y:-77.053277},
{x:38.870976,y:-77.101725},
{x:38.878457,y:-77.121484},
{x:38.862649,y:-77.088562},
{x:38.85614,y:-77.113064},
{x:38.896324,y:-77.126247},
{x:38.894432,y:-77.070306},
{x:38.896168,y:-77.071216},
{x:38.843455,y:-77.112022},
{x:38.829041,y:-77.091126},
{x:38.781839,y:-77.077343},
{x:38.813176,y:-77.123454},
{x:38.808568,y:-77.09081},
{x:38.806916,y:-77.133932},
{x:38.836902,y:-77.060752},
{x:38.743724,y:-77.087481},
{x:38.751285,y:-77.083838},
{x:38.720081,y:-77.124882},
{x:38.78593,y:-77.121681},
{x:38.831055,y:-77.12553},
{x:38.769294,y:-77.136166},
{x:38.784419,y:-77.131528},
{x:38.819174,y:-77.144253},
{x:38.816249,y:-77.049721},
{x:38.753297,y:-77.141208},
{x:38.29582,y:-77.497919},
{x:38.250237,y:-77.498198},
{x:38.294497,y:-77.515867},
{x:38.232978,y:-77.502672},
{x:38.305161,y:-77.479876},
{x:38.305924,y:-77.509282},
{x:38.342899,y:-77.493193},
{x:38.29657,y:-77.446993},
{x:38.313566,y:-77.452085},
{x:38.370034,y:-77.526913},
{x:38.290698,y:-77.566157},
{x:38.289354,y:-77.535107},
{x:38.048402,y:-77.357478},
{x:38.254505,y:-76.975072},
{x:37.719546,y:-76.38366},
{x:38.34026,y:-77.073094},
{x:38.326541,y:-77.730052},
{x:37.939166,y:-77.463991},
{x:38.019154,y:-77.501454},
{x:38.245505,y:-77.52564},
{x:38.461462,y:-77.405769},
{x:38.468429,y:-77.46021},
{x:38.470894,y:-77.417992},
{x:38.471899,y:-77.414163},
{x:37.903411,y:-76.867489},
{x:38.132779,y:-77.514086},
{x:37.964543,y:-76.786333},
{x:39.190579,y:-78.197048},
{x:39.176563,y:-78.169421},
{x:39.165484,y:-78.165585},
{x:39.18555,y:-78.141309},
{x:39.167387,y:-78.120056},
{x:39.119655,y:-78.196369},
{x:39.21977,y:-78.133832},
{x:39.209488,y:-78.175673},
{x:39.082485,y:-78.08262},
{x:38.911897,y:-78.188396},
{x:38.959193,y:-78.195346},
{x:39.029024,y:-78.270417},
{x:39.081866,y:-78.213033},
{x:39.005924,y:-78.339115},
{x:38.870631,y:-78.525511},
{x:38.480995,y:-77.992475},
{x:38.581963,y:-77.766946},
{x:38.38002,y:-78.252563},
{x:38.428438,y:-78.840476},
{x:38.435262,y:-78.853056},
{x:38.418977,y:-78.869686},
{x:38.420445,y:-78.894202},
{x:38.443189,y:-78.857749},
{x:38.3876,y:-78.968979},
{x:38.407427,y:-78.621186},
{x:38.6616,y:-78.490299},
{x:38.356643,y:-78.923498},
{x:38.648006,y:-78.674443},
{x:38.076679,y:-78.479301},
{x:38.048547,y:-78.50438},
{x:38.032906,y:-78.484923},
{x:38.127819,y:-78.439329},
{x:38.034707,y:-78.455316},
{x:38.081827,y:-78.991482},
{x:37.973692,y:-78.215496},
{x:37.753812,y:-78.876682},
{x:38.247163,y:-78.111664},
{x:38.233916,y:-78.370802},
{x:38.073183,y:-78.902275},
{x:38.057748,y:-78.944803},
{x:37.360411,y:-77.95425},
{x:37.756515,y:-77.4708},
{x:37.758239,y:-77.462088},
{x:37.692149,y:-77.438695},
{x:37.683221,y:-77.589929},
{x:37.671893,y:-77.461404},
{x:37.64179,y:-77.564463},
{x:37.650546,y:-77.604682},
{x:37.393742,y:-76.527224},
{x:37.276374,y:-76.499339},
{x:38.019927,y:-77.994871},
{x:37.669688,y:-77.67921},
{x:37.611295,y:-77.340107},
{x:37.441796,y:-77.585015},
{x:37.420887,y:-77.635719},
{x:37.503064,y:-77.641253},
{x:37.64867,y:-77.406344},
{x:37.523096,y:-77.822591},
{x:37.511688,y:-77.192923},
{x:37.416444,y:-76.820827},
{x:37.536902,y:-76.797858},
{x:37.252373,y:-76.764165},
{x:37.292876,y:-76.724839},
{x:37.273076,y:-76.683198},
{x:37.247721,y:-76.658369},
{x:37.340389,y:-76.755312},
{x:37.56204,y:-77.468737},
{x:37.55514,y:-77.485552},
{x:37.562119,y:-77.442624},
{x:37.58529,y:-77.423036},
{x:37.558354,y:-77.409072},
{x:37.535583,y:-77.424779},
{x:37.546952,y:-77.364046},
{x:37.522838,y:-77.439976},
{x:37.501131,y:-77.476152},
{x:37.538458,y:-77.521091},
{x:37.502716,y:-77.52053},
{x:37.635961,y:-77.459946},
{x:37.639161,y:-77.513228},
{x:37.588731,y:-77.501499},
{x:37.528986,y:-77.357206},
{x:37.518075,y:-77.351334},
{x:37.598026,y:-77.621284},
{x:37.437734,y:-77.4724},
{x:37.464303,y:-77.428474},
{x:37.498345,y:-77.568105},
{x:37.536908,y:-77.564732},
{x:37.470772,y:-77.530003},
{x:37.505549,y:-77.603069},
{x:37.401632,y:-77.425629},
{x:37.61849,y:-77.498875},
{x:37.627642,y:-77.532454},
{x:37.610147,y:-77.56449},
{x:37.611896,y:-77.51957},
{x:37.269196,y:-75.973844},
{x:36.741662,y:-76.242515},
{x:36.768063,y:-76.230199},
{x:36.799907,y:-76.230154},
{x:36.824544,y:-76.404674},
{x:36.86417,y:-76.405036},
{x:36.655283,y:-76.231613},
{x:36.710205,y:-76.237712},
{x:36.718179,y:-76.284773},
{x:36.714143,y:-76.184745},
{x:36.771987,y:-76.339221},
{x:36.820252,y:-76.273468},
{x:37.92346,y:-75.353748},
{x:37.520552,y:-75.831056},
{x:37.695836,y:-75.718282},
{x:36.964133,y:-76.605686},
{x:36.725125,y:-76.633742},
{x:36.738071,y:-76.58314},
{x:36.877975,y:-76.42453},
{x:36.844665,y:-75.995032},
{x:36.911976,y:-76.070938},
{x:36.815394,y:-76.067419},
{x:36.83762,y:-76.135996},
{x:36.840365,y:-76.103002},
{x:36.769021,y:-76.013344},
{x:36.848827,y:-76.022313},
{x:36.875525,y:-76.13546},
{x:36.8808,y:-76.186357},
{x:36.753298,y:-76.055731},
{x:36.791431,y:-76.121289},
{x:36.848723,y:-75.976819},
{x:36.814047,y:-76.102489},
{x:36.820205,y:-76.222769},
{x:36.814214,y:-76.167951},
{x:36.798382,y:-76.177975},
{x:36.781519,y:-76.190255},
{x:36.850988,y:-76.179463},
{x:36.85503,y:-76.20709},
{x:36.953535,y:-76.252378},
{x:36.884948,y:-76.28186},
{x:36.854398,y:-76.262726},
{x:36.915178,y:-76.269205},
{x:36.92867,y:-76.317208},
{x:36.882348,y:-76.301862},
{x:36.849776,y:-76.284015},
{x:36.955141,y:-76.324439},
{x:36.888935,y:-76.23868},
{x:36.917204,y:-76.230106},
{x:36.925784,y:-76.252116},
{x:36.918566,y:-76.192293},
{x:36.830949,y:-76.260974},
{x:37.075299,y:-76.470188},
{x:37.134974,y:-76.518005},
{x:37.130182,y:-76.535835},
{x:37.156266,y:-76.555221},
{x:37.105452,y:-76.49545},
{x:36.982288,y:-76.422188},
{x:37.020477,y:-76.444182},
{x:37.075542,y:-76.495158},
{x:37.012478,y:-76.403601},
{x:37.123497,y:-76.392476},
{x:37.058775,y:-76.426744},
{x:37.02843,y:-76.425176},
{x:37.041402,y:-76.387288},
{x:37.093082,y:-76.394617},
{x:37.016992,y:-76.325486},
{x:37.025536,y:-76.363732},
{x:37.037409,y:-76.314491},
{x:37.056648,y:-76.328245},
{x:36.7481,y:-78.098706},
{x:37.190192,y:-76.48843},
{x:37.121524,y:-76.457908},
{x:36.811296,y:-76.363906},
{x:36.795574,y:-76.327799},
{x:36.836368,y:-76.307618},
{x:37.204618,y:-77.460833},
{x:37.200662,y:-77.332661},
{x:37.202737,y:-77.379502},
{x:37.083528,y:-77.996452},
{x:37.353586,y:-77.413303},
{x:37.372991,y:-77.502794},
{x:37.403235,y:-77.673379},
{x:37.256556,y:-77.39429},
{x:37.250284,y:-77.410417},
{x:37.309217,y:-77.40049},
{x:37.346732,y:-77.342233},
{x:36.700557,y:-77.544303},
{x:36.666725,y:-76.957532},
{x:37.278348,y:-77.309302},
{x:37.259803,y:-77.326146},
{x:36.605554,y:-77.5606},
{x:37.036585,y:-77.093164},
{x:37.298601,y:-78.393518},
{x:36.626427,y:-78.554541},
{x:37.541959,y:-78.475039},
{x:36.71726,y:-78.117557},
{x:37.315852,y:-79.957909},
{x:37.301801,y:-79.8941},
{x:37.246471,y:-79.960621},
{x:37.278909,y:-79.933781},
{x:37.245095,y:-80.031757},
{x:37.359904,y:-79.956938},
{x:36.747444,y:-79.938383},
{x:37.232784,y:-80.418896},
{x:37.136124,y:-80.360882},
{x:37.150735,y:-80.413913},
{x:36.717736,y:-79.911934},
{x:37.083421,y:-80.690812},
{x:36.652561,y:-79.874411},
{x:36.696379,y:-79.875481},
{x:37.327178,y:-80.706935},
{x:37.142246,y:-80.579793},
{x:37.007623,y:-79.875262},
{x:37.290455,y:-80.07637},
{x:37.297724,y:-80.027505},
{x:37.264987,y:-80.023413},
{x:37.384775,y:-79.903493},
{x:37.272712,y:-79.888965},
{x:36.634828,y:-82.124365},
{x:36.624729,y:-82.159377},
{x:36.696278,y:-82.009318},
{x:36.701436,y:-81.975106},
{x:36.712414,y:-81.930315},
{x:36.861307,y:-82.755741},
{x:36.90512,y:-82.318235},
{x:37.150863,y:-82.474042},
{x:36.711736,y:-82.791392},
{x:36.637346,y:-82.565925},
{x:36.90319,y:-82.059521},
{x:36.956056,y:-82.601539},
{x:36.762223,y:-83.017528},
{x:36.968686,y:-82.594043},
{x:37.048868,y:-80.762756},
{x:36.797614,y:-81.680892},
{x:36.679111,y:-80.896454},
{x:36.74394,y:-80.775986},
{x:36.579403,y:-80.740417},
{x:36.843805,y:-81.501302},
{x:36.947422,y:-80.948734},
{x:36.756621,y:-81.856028},
{x:37.064203,y:-81.746432},
{x:36.91457,y:-81.272958},
{x:36.957208,y:-81.095289},
{x:38.125653,y:-79.031511},
{x:38.123844,y:-79.064781},
{x:37.773518,y:-79.967255},
{x:37.884211,y:-79.283059},
{x:37.775819,y:-79.433747},
{x:38.195224,y:-79.001997},
{x:37.403432,y:-79.167888},
{x:37.414836,y:-79.208523},
{x:37.334421,y:-79.242987},
{x:37.372484,y:-79.184735},
{x:37.345973,y:-79.188474},
{x:37.436067,y:-79.122384},
{x:37.108267,y:-79.291578},
{x:37.586997,y:-79.048432},
{x:37.361791,y:-78.83319},
{x:37.323918,y:-79.496265},
{x:36.789899,y:-79.394592},
{x:36.581489,y:-79.337445},
{x:36.633021,y:-79.394716},
{x:36.587622,y:-79.42297},
{x:36.572037,y:-79.420039},
{x:37.376406,y:-79.248463},
{x:37.365656,y:-79.295535},
{x:36.947641,y:-79.36331},
{x:37.280473,y:-79.167352},
{x:36.738015,y:-78.920804},
{x:36.687563,y:-78.901253},
{x:37.240068,y:-81.250031},
{x:37.094383,y:-81.811869},
{x:37.129241,y:-81.526924},
{x:37.23538,y:-82.104041},
{x:37.266229,y:-81.231718},
{x:37.363095,y:-81.058614},
{x:37.360652,y:-81.101509},
{x:37.431664,y:-81.569834},
{x:37.69232,y:-81.628061},
{x:37.773015,y:-80.355424},
{x:37.769398,y:-80.465485},
{x:38.204779,y:-81.50466},
{x:38.358592,y:-81.736713},
{x:38.457007,y:-81.499174},
{x:38.073996,y:-81.822686},
{x:38.433652,y:-81.836209},
{x:38.387781,y:-81.824262},
{x:38.181599,y:-81.3067},
{x:38.530497,y:-81.897775},
{x:38.757907,y:-81.690184},
{x:38.820354,y:-81.724701},
{x:38.804502,y:-81.360148},
{x:38.327856,y:-81.601491},
{x:38.305319,y:-81.562835},
{x:38.320815,y:-81.716323},
{x:38.358358,y:-81.716565},
{x:38.339872,y:-81.614996},
{x:38.370112,y:-81.669411},
{x:38.476715,y:-81.670369},
{x:38.426944,y:-81.785183},
{x:39.442768,y:-77.987638},
{x:39.478672,y:-77.958022},
{x:39.544372,y:-77.908683},
{x:39.606519,y:-78.238403},
{x:39.291874,y:-77.850555},
{x:39.362968,y:-78.045397},
{x:39.320437,y:-77.851238},
{x:39.434842,y:-77.820754},
{x:38.421085,y:-82.262657},
{x:38.278305,y:-82.123764},
{x:38.453686,y:-81.936469},
{x:38.43876,y:-82.017417},
{x:38.397296,y:-82.56612},
{x:38.433576,y:-82.123223},
{x:38.844597,y:-82.136139},
{x:37.849643,y:-81.9977},
{x:37.861158,y:-82.046464},
{x:37.61237,y:-81.867005},
{x:38.417507,y:-82.456555},
{x:38.336952,y:-82.454005},
{x:38.407734,y:-82.428315},
{x:38.407103,y:-82.375293},
{x:38.42376,y:-82.420495},
{x:38.413367,y:-82.343896},
{x:37.791712,y:-81.213581},
{x:37.808358,y:-81.184847},
{x:37.776772,y:-81.193985},
{x:37.749472,y:-81.133973},
{x:38.033723,y:-81.122006},
{x:37.991205,y:-81.132575},
{x:37.728156,y:-81.243619},
{x:37.656944,y:-80.887948},
{x:37.967373,y:-80.773445},
{x:40.103836,y:-80.702005},
{x:40.043239,y:-80.657653},
{x:40.613129,y:-80.563522},
{x:40.338626,y:-80.596482},
{x:39.920725,y:-80.748394},
{x:40.060567,y:-80.600503},
{x:40.399927,y:-80.550331},
{x:39.283131,y:-81.548866},
{x:39.248744,y:-81.555593},
{x:39.267939,y:-81.513666},
{x:39.308599,y:-81.553204},
{x:39.189197,y:-81.527662},
{x:39.665227,y:-80.858546},
{x:38.95329,y:-81.764235},
{x:39.388416,y:-81.20614},
{x:38.985573,y:-80.225815},
{x:38.91713,y:-79.844327},
{x:39.096569,y:-79.679968},
{x:39.264899,y:-80.328539},
{x:39.282065,y:-80.348617},
{x:39.280871,y:-80.272177},
{x:39.26942,y:-80.285343},
{x:39.266446,y:-81.056291},
{x:38.941037,y:-80.818981},
{x:39.348017,y:-80.003041},
{x:39.144302,y:-80.036332},
{x:39.376901,y:-80.310428},
{x:39.019079,y:-80.420955},
{x:39.63043,y:-79.984343},
{x:39.657857,y:-80.001513},
{x:39.653961,y:-79.942274},
{x:39.622293,y:-79.923281},
{x:39.650034,y:-79.96426},
{x:39.649967,y:-79.90087},
{x:39.471845,y:-79.690426},
{x:39.477252,y:-80.120529},
{x:39.473058,y:-80.152586},
{x:39.424385,y:-80.187965},
{x:39.519298,y:-80.320844},
{x:38.699816,y:-80.66396},
{x:38.309935,y:-80.834773},
{x:39.429674,y:-78.989643},
{x:39.339361,y:-78.752179},
{x:39.076447,y:-78.96494},
{x:38.993946,y:-79.115501},
{x:36.005155,y:-80.440291},
{x:36.030996,y:-80.383349},
{x:36.394714,y:-80.716369},
{x:36.262105,y:-80.363079},
{x:36.396383,y:-79.96918},
{x:36.417689,y:-79.934129},
{x:35.919193,y:-80.58426},
{x:36.504061,y:-80.606621},
{x:36.483899,y:-80.613986},
{x:36.505506,y:-80.624703},
{x:36.377126,y:-80.485327},
{x:36.213104,y:-80.302272},
{x:36.166519,y:-80.134897},
{x:36.120665,y:-80.661624},
{x:36.103352,y:-80.230067},
{x:36.070494,y:-80.297617},
{x:36.091816,y:-80.282003},
{x:36.073764,y:-80.338064},
{x:36.188911,y:-80.277607},
{x:36.192038,y:-80.273711},
{x:36.1372,y:-80.243505},
{x:36.117298,y:-80.314503},
{x:36.151659,y:-80.282968},
{x:36.14452,y:-80.301333},
{x:36.121421,y:-80.368503},
{x:36.060324,y:-80.259598},
{x:36.027453,y:-80.163672},
{x:36.027199,y:-80.262487},
{x:35.995116,y:-80.267001},
{x:35.733699,y:-79.807624},
{x:35.699642,y:-79.787532},
{x:35.686508,y:-79.826419},
{x:35.358489,y:-79.797726},
{x:36.094794,y:-79.406785},
{x:36.067633,y:-79.519788},
{x:36.079926,y:-79.512773},
{x:36.072341,y:-79.493278},
{x:36.108541,y:-79.463948},
{x:36.062486,y:-79.400107},
{x:35.980671,y:-79.978101},
{x:35.937926,y:-80.035208},
{x:35.979622,y:-80.021757},
{x:35.989409,y:-80.002741},
{x:35.924725,y:-79.983865},
{x:35.903784,y:-79.955958},
{x:36.036156,y:-79.962294},
{x:36.005298,y:-80.037362},
{x:36.055716,y:-79.104849},
{x:36.055904,y:-79.085975},
{x:36.039821,y:-79.920203},
{x:36.111316,y:-80.089773},
{x:36.087187,y:-80.062676},
{x:36.4924,y:-79.73909},
{x:35.79459,y:-80.259408},
{x:35.777075,y:-80.259145},
{x:35.912794,y:-80.253216},
{x:35.836471,y:-80.254247},
{x:36.065999,y:-79.309749},
{x:36.073862,y:-79.272688},
{x:36.172107,y:-79.98889},
{x:35.744499,y:-79.167138},
{x:35.737881,y:-79.653131},
{x:35.822995,y:-79.822926},
{x:36.352986,y:-79.664039},
{x:35.442013,y:-79.216544},
{x:35.487139,y:-79.199032},
{x:35.459005,y:-79.145861},
{x:35.734052,y:-79.438422},
{x:35.867387,y:-80.072128},
{x:35.855675,y:-80.065398},
{x:35.356589,y:-79.899931},
{x:35.266381,y:-79.588302},
{x:36.063459,y:-79.608371},
{x:36.417308,y:-79.350065},
{x:36.10915,y:-79.757019},
{x:36.089103,y:-79.774628},
{x:36.113302,y:-79.753117},
{x:36.131584,y:-79.798922},
{x:36.055169,y:-79.901739},
{x:36.057077,y:-79.6599},
{x:36.040493,y:-79.800289},
{x:36.003054,y:-79.791286},
{x:36.002207,y:-79.799684},
{x:36.041645,y:-79.772326},
{x:36.039729,y:-79.849027},
{x:36.069489,y:-79.867967},
{x:36.014415,y:-79.896486},
{x:36.057929,y:-79.900904},
{x:36.08631,y:-79.82843},
{x:36.078738,y:-79.957271},
{x:36.109818,y:-79.883234},
{x:36.125586,y:-79.905986},
{x:36.120505,y:-79.843514},
{x:36.088609,y:-79.887878},
{x:36.108619,y:-79.827809},
{x:35.506514,y:-78.741461},
{x:35.716026,y:-78.840618},
{x:35.714836,y:-78.842788},
{x:35.516682,y:-78.55507},
{x:35.377822,y:-78.542917},
{x:35.829769,y:-78.769241},
{x:35.762257,y:-78.782849},
{x:35.789182,y:-78.829803},
{x:35.776461,y:-78.76474},
{x:35.759106,y:-78.743312},
{x:35.940678,y:-79.019116},
{x:35.910773,y:-79.061166},
{x:35.847227,y:-79.090062},
{x:35.704203,y:-78.795713},
{x:35.81925,y:-78.901914},
{x:35.65036,y:-78.470474},
{x:36.146698,y:-78.723219},
{x:35.744508,y:-78.821615},
{x:35.445823,y:-78.42388},
{x:35.593669,y:-78.771722},
{x:35.606552,y:-78.566201},
{x:35.607781,y:-78.555247},
{x:35.692591,y:-78.58276},
{x:35.724763,y:-78.649547},
{x:35.68577,y:-78.604464},
{x:35.329111,y:-78.035988},
{x:35.404542,y:-78.023268},
{x:35.397182,y:-77.96467},
{x:35.372845,y:-77.948386},
{x:35.341237,y:-77.918651},
{x:36.334437,y:-78.443367},
{x:36.333011,y:-78.436255},
{x:36.319093,y:-78.382138},
{x:35.636222,y:-78.831152},
{x:35.5935,y:-78.129084},
{x:35.798852,y:-78.488574},
{x:35.418642,y:-78.803756},
{x:36.089111,y:-78.300938},
{x:35.818893,y:-78.844908},
{x:36.299121,y:-78.583593},
{x:35.916993,y:-78.466904},
{x:36.376844,y:-78.984867},
{x:36.405233,y:-78.978733},
{x:35.521747,y:-78.29372},
{x:35.521769,y:-78.3585},
{x:35.519559,y:-78.313995},
{x:35.9421,y:-78.559624},
{x:35.944897,y:-78.506091},
{x:35.9789,y:-78.537763},
{x:35.792306,y:-78.379312},
{x:36.038862,y:-78.498409},
{x:35.833491,y:-78.317216},
{x:35.772413,y:-78.637982},
{x:35.665123,y:-78.698542},
{x:35.799103,y:-78.577288},
{x:35.820499,y:-78.591118},
{x:35.844135,y:-78.58066},
{x:35.800835,y:-78.560127},
{x:35.788241,y:-78.649157},
{x:35.785297,y:-78.688022},
{x:35.790036,y:-78.740696},
{x:35.837152,y:-78.608148},
{x:35.819152,y:-78.624798},
{x:35.867493,y:-78.621198},
{x:35.838777,y:-78.611515},
{x:35.72376,y:-78.546995},
{x:35.767507,y:-78.578263},
{x:35.790579,y:-78.662504},
{x:35.836049,y:-78.675723},
{x:35.840145,y:-78.679611},
{x:35.900313,y:-78.681467},
{x:35.909259,y:-78.727485},
{x:35.863398,y:-78.711939},
{x:35.906328,y:-78.601398},
{x:35.879929,y:-78.646359},
{x:35.855431,y:-78.556862},
{x:35.869752,y:-78.565021},
{x:35.870226,y:-78.5801},
{x:35.912336,y:-78.777677},
{x:35.910457,y:-78.782189},
{x:36.471091,y:-77.650197},
{x:35.816087,y:-78.694328},
{x:35.606046,y:-77.371787},
{x:35.998498,y:-78.899492},
{x:35.877574,y:-78.850242},
{x:35.993145,y:-78.869171},
{x:35.948017,y:-78.836847},
{x:36.051428,y:-78.904075},
{x:36.016672,y:-78.889662},
{x:36.021584,y:-78.952361},
{x:36.029709,y:-78.926033},
{x:35.970215,y:-78.958464},
{x:36.001206,y:-78.941368},
{x:36.076339,y:-78.910301},
{x:35.911425,y:-78.936518},
{x:35.948628,y:-78.923954},
{x:35.901162,y:-78.895298},
{x:35.944187,y:-77.775268},
{x:35.962554,y:-77.830628},
{x:35.936642,y:-77.829074},
{x:35.977954,y:-77.812343},
{x:35.970338,y:-77.81159},
{x:36.023507,y:-77.769498},
{x:35.617307,y:-77.604895},
{x:35.651688,y:-77.366896},
{x:35.592264,y:-77.396187},
{x:35.574844,y:-77.387858},
{x:35.608484,y:-77.395164},
{x:36.442003,y:-77.091713},
{x:35.978942,y:-77.974403},
{x:35.599477,y:-77.335266},
{x:35.581798,y:-77.308156},
{x:35.567169,y:-77.35134},
{x:36.432965,y:-77.636501},
{x:35.833347,y:-77.253245},
{x:35.902921,y:-77.562384},
{x:35.559744,y:-77.061324},
{x:35.850023,y:-77.047624},
{x:35.753227,y:-77.930374},
{x:35.737805,y:-77.952665},
{x:35.764214,y:-78.007764},
{x:35.722095,y:-77.937817},
{x:35.722054,y:-77.89261},
{x:36.295225,y:-76.240417},
{x:36.293832,y:-76.296817},
{x:36.329533,y:-76.226261},
{x:36.272946,y:-76.972683},
{x:36.450251,y:-76.018852},
{x:36.07323,y:-76.611313},
{x:36.242432,y:-75.877433},
{x:36.174381,y:-76.462605},
{x:35.998033,y:-75.650894},
{x:36.09852,y:-75.719853},
{x:35.899058,y:-75.668649},
{x:35.860087,y:-76.737884},
{x:35.348829,y:-80.166911},
{x:35.386526,y:-80.193189},
{x:35.251737,y:-81.043179},
{x:35.385616,y:-81.368529},
{x:35.439975,y:-80.607446},
{x:35.371365,y:-80.549323},
{x:35.373806,y:-80.732055},
{x:35.435405,y:-80.653349},
{x:35.366858,y:-80.712269},
{x:35.396749,y:-80.607731},
{x:35.420335,y:-80.675574},
{x:35.483789,y:-80.876478},
{x:35.316472,y:-81.190363},
{x:35.444439,y:-80.997677},
{x:35.316978,y:-81.858075},
{x:35.334189,y:-81.891825},
{x:35.260908,y:-81.226508},
{x:35.262747,y:-81.15351},
{x:35.240968,y:-81.194545},
{x:35.25927,y:-81.216847},
{x:35.233236,y:-81.170375},
{x:35.259623,y:-81.125185},
{x:35.226838,y:-81.133754},
{x:35.322953,y:-80.645915},
{x:35.40648,y:-80.865495},
{x:35.370466,y:-80.831151},
{x:35.44437,y:-80.866589},
{x:35.441695,y:-80.765012},
{x:35.049274,y:-80.647553},
{x:35.076836,y:-80.65188},
{x:35.490519,y:-80.611624},
{x:35.465796,y:-80.588946},
{x:35.462688,y:-80.594651},
{x:35.238857,y:-81.36366},
{x:35.228003,y:-81.332612},
{x:35.477093,y:-81.237872},
{x:35.480249,y:-81.209636},
{x:35.254851,y:-80.458437},
{x:35.141772,y:-80.623633},
{x:35.08567,y:-80.696077},
{x:35.001509,y:-80.700492},
{x:35.052387,y:-80.769156},
{x:35.136301,y:-80.71371},
{x:35.01383,y:-80.563887},
{x:34.992952,y:-80.539304},
{x:35.594225,y:-80.865532},
{x:35.55475,y:-80.855096},
{x:35.595503,y:-80.817316},
{x:35.569141,y:-80.901278},
{x:35.288907,y:-81.020463},
{x:35.472229,y:-80.266947},
{x:35.561655,y:-80.41523},
{x:35.662014,y:-80.46306},
{x:35.6628,y:-80.500624},
{x:35.608807,y:-80.517449},
{x:35.693594,y:-80.513369},
{x:35.268441,y:-81.485206},
{x:35.277426,y:-81.539415},
{x:35.246162,y:-81.665746},
{x:35.362685,y:-81.940289},
{x:35.670652,y:-80.853193},
{x:34.964558,y:-80.060452},
{x:34.956807,y:-80.756948},
{x:34.983005,y:-80.448385},
{x:35.220047,y:-80.802569},
{x:35.213288,y:-80.781693},
{x:35.228864,y:-80.923769},
{x:35.238241,y:-80.88444},
{x:35.172459,y:-80.850476},
{x:35.138592,y:-80.738083},
{x:35.201533,y:-80.86553},
{x:35.18451,y:-80.886798},
{x:35.115771,y:-80.856999},
{x:35.138107,y:-80.876026},
{x:35.148694,y:-80.833018},
{x:35.192908,y:-80.79575},
{x:35.20342,y:-80.738223},
{x:35.183265,y:-80.759386},
{x:35.276973,y:-80.794014},
{x:35.294535,y:-80.747959},
{x:35.236245,y:-80.73578},
{x:35.269224,y:-80.853472},
{x:35.346935,y:-80.86129},
{x:35.327246,y:-80.94444},
{x:35.16346,y:-80.887593},
{x:35.087242,y:-80.849597},
{x:35.097933,y:-80.783372},
{x:35.089453,y:-80.868683},
{x:35.186929,y:-80.689748},
{x:35.340211,y:-79.404268},
{x:35.295441,y:-80.758305},
{x:35.308545,y:-80.748236},
{x:35.333715,y:-80.797482},
{x:35.308588,y:-80.844116},
{x:35.138664,y:-80.731831},
{x:35.107229,y:-80.880371},
{x:35.103649,y:-80.98554},
{x:35.163114,y:-80.970072},
{x:35.143923,y:-80.929879},
{x:35.037248,y:-80.805605},
{x:35.05269,y:-78.865366},
{x:35.006179,y:-78.817448},
{x:35.095816,y:-78.916183},
{x:35.078578,y:-78.940804},
{x:35.037222,y:-79.006875},
{x:35.088988,y:-78.983203},
{x:35.029109,y:-79.056389},
{x:35.043145,y:-78.947803},
{x:35.003821,y:-78.915493},
{x:34.929256,y:-78.855315},
{x:34.981415,y:-79.012892},
{x:35.027916,y:-78.927157},
{x:35.15794,y:-78.869834},
{x:35.112757,y:-78.88007},
{x:35.061282,y:-79.016136},
{x:35.070651,y:-78.966195},
{x:35.154372,y:-79.416175},
{x:35.261683,y:-79.046332},
{x:34.991079,y:-78.351459},
{x:34.998163,y:-78.309863},
{x:35.317272,y:-78.626961},
{x:35.293122,y:-78.595699},
{x:34.624535,y:-78.608045},
{x:34.503888,y:-79.111664},
{x:34.98524,y:-78.965279},
{x:34.959775,y:-77.967412},
{x:34.760559,y:-79.469288},
{x:34.623038,y:-79.048477},
{x:34.643405,y:-79.00993},
{x:35.212031,y:-78.061784},
{x:35.248228,y:-78.379929},
{x:34.684384,y:-79.20279},
{x:35.204223,y:-79.48475},
{x:34.97779,y:-79.23599},
{x:34.811155,y:-79.173732},
{x:34.920816,y:-79.749238},
{x:34.913338,y:-79.738271},
{x:34.809256,y:-78.984467},
{x:35.256165,y:-78.967017},
{x:35.161082,y:-78.970788},
{x:34.99395,y:-78.136299},
{x:34.192003,y:-77.934086},
{x:34.227141,y:-77.830401},
{x:34.219148,y:-77.885451},
{x:34.242858,y:-77.891595},
{x:34.292414,y:-77.873464},
{x:34.288107,y:-77.921495},
{x:34.106366,y:-77.900316},
{x:34.261771,y:-77.829209},
{x:34.295888,y:-77.795651},
{x:34.197651,y:-77.889359},
{x:34.145296,y:-77.893146},
{x:34.559737,y:-77.918325},
{x:34.035361,y:-77.893223},
{x:34.370996,y:-77.707786},
{x:34.448864,y:-77.562918},
{x:34.212001,y:-78.024702},
{x:34.233994,y:-77.996516},
{x:34.440093,y:-77.878239},
{x:33.976899,y:-78.380972},
{x:34.55044,y:-77.436846},
{x:33.951585,y:-78.047839},
{x:34.01683,y:-78.275949},
{x:34.139901,y:-78.868213},
{x:34.73963,y:-77.976136},
{x:33.945752,y:-78.502855},
{x:34.308417,y:-78.712443},
{x:34.343302,y:-78.708418},
{x:35.238119,y:-77.573857},
{x:35.268363,y:-77.582224},
{x:35.261,y:-77.647985},
{x:34.700987,y:-76.742563},
{x:35.475181,y:-77.435923},
{x:34.725358,y:-76.644611},
{x:34.923703,y:-77.775163},
{x:35.142986,y:-76.830805},
{x:34.861043,y:-76.89695},
{x:34.896694,y:-76.906872},
{x:34.909065,y:-76.936975},
{x:34.743544,y:-77.379755},
{x:34.751513,y:-77.445774},
{x:34.760782,y:-77.414671},
{x:34.799927,y:-77.440063},
{x:34.781802,y:-77.397753},
{x:34.768495,y:-77.384474},
{x:34.738038,y:-76.808229},
{x:34.734757,y:-76.811598},
{x:35.081779,y:-77.030265},
{x:35.111374,y:-77.066072},
{x:35.124368,y:-77.086296},
{x:35.100024,y:-77.092053},
{x:34.886987,y:-77.548871},
{x:35.440747,y:-77.662968},
{x:34.688784,y:-77.068302},
{x:34.69767,y:-77.140743},
{x:35.550909,y:-77.404978},
{x:35.753315,y:-81.285674},
{x:35.715949,y:-81.336747},
{x:35.765397,y:-81.333129},
{x:35.734698,y:-81.364874},
{x:35.703322,y:-81.28686},
{x:35.704879,y:-81.300373},
{x:36.117995,y:-81.837769},
{x:36.206209,y:-81.671231},
{x:35.705227,y:-81.263382},
{x:35.695581,y:-81.230746},
{x:35.72733,y:-81.210974},
{x:36.274932,y:-80.843589},
{x:35.796088,y:-80.938887},
{x:35.811745,y:-81.433957},
{x:36.239631,y:-80.820957},
{x:35.914027,y:-81.528243},
{x:35.735033,y:-81.709992},
{x:35.72365,y:-81.656724},
{x:36.084221,y:-81.928129},
{x:36.177789,y:-81.144892},
{x:35.718494,y:-81.466319},
{x:35.791768,y:-80.855753},
{x:35.810318,y:-80.874208},
{x:35.921562,y:-81.185246},
{x:35.743753,y:-81.567525},
{x:36.382196,y:-81.487713},
{x:36.150671,y:-81.196245},
{x:35.482458,y:-82.553789},
{x:35.60984,y:-82.320051},
{x:35.246136,y:-82.723774},
{x:35.423632,y:-83.446442},
{x:35.915836,y:-82.302001},
{x:35.55307,y:-82.850119},
{x:35.532545,y:-82.86271},
{x:35.248497,y:-82.204219},
{x:35.296701,y:-82.409531},
{x:35.443826,y:-82.533673},
{x:35.186957,y:-83.372033},
{x:35.163435,y:-83.392484},
{x:35.337746,y:-82.445151},
{x:35.704032,y:-82.037732},
{x:35.67636,y:-82.01371},
{x:35.621367,y:-82.180815},
{x:35.326236,y:-83.80311},
{x:35.890695,y:-82.066785},
{x:35.378001,y:-83.213003},
{x:35.503784,y:-82.991225},
{x:35.703536,y:-82.57032},
{x:35.46183,y:-83.315271},
{x:35.395655,y:-82.506315},
{x:35.307467,y:-82.455841},
{x:35.34937,y:-82.437549},
{x:35.508699,y:-82.526593},
{x:35.569568,y:-82.50609},
{x:35.56754,y:-82.544459},
{x:35.624357,y:-82.554098},
{x:35.595474,y:-82.533182},
{x:35.587082,y:-82.488761},
{x:35.577148,y:-82.516419},
{x:35.529949,y:-82.60534},
{x:35.557776,y:-82.640237},
{x:35.586718,y:-82.5882},
{x:35.601527,y:-82.622849},
{x:35.202302,y:-83.837251},
{x:35.03536,y:-83.819568},
{x:35.080663,y:-84.03575},
{x:33.909601,y:-81.531731},
{x:34.197023,y:-80.276868},
{x:34.214585,y:-80.981093},
{x:33.417008,y:-80.666386},
{x:34.219671,y:-80.593266},
{x:34.246494,y:-80.600261},
{x:33.93083,y:-81.076224},
{x:34.176678,y:-81.324895},
{x:33.326173,y:-81.132632},
{x:34.085116,y:-81.183603},
{x:34.123192,y:-81.214429},
{x:33.924025,y:-81.290789},
{x:34.014453,y:-81.15354},
{x:33.956581,y:-81.231659},
{x:33.987098,y:-81.243765},
{x:33.974055,y:-81.15514},
{x:33.913364,y:-81.227052},
{x:34.226513,y:-80.67901},
{x:33.697831,y:-80.226072},
{x:34.296356,y:-81.543861},
{x:34.283827,y:-81.597613},
{x:33.521646,y:-80.882462},
{x:33.491434,y:-80.855772},
{x:33.554223,y:-80.827034},
{x:34.005819,y:-81.770371},
{x:33.486272,y:-80.474156},
{x:33.921946,y:-80.335552},
{x:33.950711,y:-80.49777},
{x:33.948838,y:-80.375865},
{x:33.960029,y:-80.389487},
{x:33.904821,y:-80.379839},
{x:33.868938,y:-80.355997},
{x:33.938855,y:-81.149326},
{x:33.976313,y:-81.075094},
{x:33.980412,y:-81.099411},
{x:34.002459,y:-81.107159},
{x:34.370639,y:-81.10019},
{x:33.95358,y:-80.99323},
{x:33.997286,y:-81.04517},
{x:34.012247,y:-81.04105},
{x:34.075295,y:-80.998274},
{x:34.137114,y:-80.959739},
{x:34.035581,y:-81.004447},
{x:34.022715,y:-80.950319},
{x:34.053709,y:-80.94618},
{x:33.964881,y:-80.943992},
{x:33.989183,y:-80.97253},
{x:34.044764,y:-81.125727},
{x:34.035234,y:-81.08993},
{x:34.073965,y:-81.155449},
{x:34.067042,y:-80.958048},
{x:34.09804,y:-80.855597},
{x:34.140333,y:-80.914521},
{x:34.935079,y:-82.001028},
{x:34.933441,y:-81.984828},
{x:34.923908,y:-81.976805},
{x:34.921107,y:-81.886207},
{x:35.022625,y:-81.894977},
{x:35.01677,y:-81.961632},
{x:34.991814,y:-82.022246},
{x:34.984953,y:-81.973637},
{x:34.966858,y:-81.929578},
{x:34.974822,y:-81.879079},
{x:35.047229,y:-81.982921},
{x:35.134477,y:-81.863219},
{x:34.472357,y:-81.880305},
{x:34.508242,y:-81.858687},
{x:34.916102,y:-82.110666},
{x:34.887693,y:-82.068357},
{x:35.057638,y:-81.640948},
{x:35.092215,y:-81.6632},
{x:35.041472,y:-82.089572},
{x:34.505218,y:-82.008347},
{x:34.729672,y:-81.639804},
{x:34.751602,y:-82.046288},
{x:32.787084,y:-79.954744},
{x:32.859154,y:-79.982275},
{x:32.903968,y:-80.019266},
{x:32.963457,y:-80.043269},
{x:32.790252,y:-80.030717},
{x:32.820033,y:-79.991972},
{x:32.759384,y:-79.974656},
{x:32.812641,y:-80.042876},
{x:32.82164,y:-80.081358},
{x:32.869081,y:-80.01298},
{x:32.934703,y:-80.050364},
{x:32.939062,y:-80.035524},
{x:32.913832,y:-80.10831},
{x:33.374524,y:-79.288391},
{x:32.998862,y:-80.038984},
{x:33.035736,y:-80.063793},
{x:33.03373,y:-80.064213},
{x:32.968878,y:-80.003031},
{x:32.720753,y:-80.080698},
{x:32.999385,y:-80.09159},
{x:32.950257,y:-80.155007},
{x:33.201194,y:-79.991445},
{x:32.812666,y:-79.854959},
{x:32.86451,y:-79.786022},
{x:33.194581,y:-80.601217},
{x:33.03891,y:-80.142254},
{x:33.034805,y:-80.153434},
{x:33.012381,y:-80.235977},
{x:33.025299,y:-80.170657},
{x:32.952286,y:-80.165652},
{x:32.88025,y:-80.713255},
{x:32.911044,y:-80.66857},
{x:34.186935,y:-79.808649},
{x:34.193238,y:-79.820854},
{x:34.157559,y:-79.868465},
{x:34.164767,y:-79.764314},
{x:34.165712,y:-79.827998},
{x:34.227644,y:-79.798438},
{x:34.155145,y:-79.764107},
{x:34.265771,y:-79.703034},
{x:34.196656,y:-79.655653},
{x:33.448602,y:-79.552869},
{x:33.999374,y:-79.205741},
{x:34.607861,y:-79.679573},
{x:34.693562,y:-79.910246},
{x:33.847451,y:-79.065487},
{x:33.778011,y:-79.020735},
{x:34.282049,y:-79.855905},
{x:34.418638,y:-79.369038},
{x:34.359661,y:-80.061849},
{x:33.810806,y:-79.442793},
{x:33.663565,y:-79.833102},
{x:33.877954,y:-79.759156},
{x:34.340734,y:-79.52962},
{x:33.86675,y:-78.636892},
{x:34.068612,y:-78.879168},
{x:34.183609,y:-79.366834},
{x:33.62647,y:-78.967059},
{x:33.584719,y:-79.013097},
{x:33.666395,y:-78.916087},
{x:33.828232,y:-78.679133},
{x:33.702815,y:-78.870698},
{x:34.96061,y:-82.435738},
{x:33.727053,y:-78.870386},
{x:33.683166,y:-78.96293},
{x:33.760353,y:-78.858397},
{x:33.759286,y:-78.967452},
{x:33.797356,y:-78.742359},
{x:33.777612,y:-78.794862},
{x:33.438571,y:-79.128619},
{x:33.652139,y:-78.985973},
{x:33.630582,y:-79.033842},
{x:34.794609,y:-82.418557},
{x:34.820063,y:-82.416107},
{x:34.823701,y:-82.393697},
{x:34.831981,y:-82.306642},
{x:34.885557,y:-82.354252},
{x:34.828346,y:-82.346336},
{x:34.820433,y:-82.256881},
{x:34.859872,y:-82.404964},
{x:34.852471,y:-82.45282},
{x:34.873553,y:-82.329761},
{x:34.859804,y:-82.269148},
{x:34.911909,y:-82.428912},
{x:34.532326,y:-82.633537},
{x:34.57954,y:-82.713982},
{x:34.630715,y:-82.599525},
{x:34.481038,y:-82.663217},
{x:34.537058,y:-82.666678},
{x:34.529305,y:-82.696181},
{x:34.516464,y:-82.485581},
{x:34.691821,y:-82.837},
{x:34.82638,y:-82.569254},
{x:34.685346,y:-82.197679},
{x:34.178852,y:-82.154158},
{x:34.213646,y:-82.150932},
{x:34.207358,y:-82.188722},
{x:34.892172,y:-82.292646},
{x:34.91449,y:-82.237768},
{x:34.949486,y:-82.234864},
{x:34.777962,y:-82.312116},
{x:34.884895,y:-82.704625},
{x:34.787288,y:-82.480815},
{x:34.710788,y:-82.497816},
{x:34.720273,y:-82.386988},
{x:34.692414,y:-82.984394},
{x:34.68987,y:-82.999038},
{x:34.706213,y:-82.258393},
{x:34.722252,y:-82.255469},
{x:34.754712,y:-82.266836},
{x:34.937172,y:-82.274521},
{x:34.752341,y:-83.045034},
{x:34.619084,y:-82.477767},
{x:35.142871,y:-81.507561},
{x:35.157093,y:-81.47857},
{x:34.695459,y:-81.192971},
{x:35.012617,y:-80.851275},
{x:35.060334,y:-80.992163},
{x:35.119923,y:-81.085818},
{x:35.107606,y:-81.211591},
{x:35.088688,y:-80.931408},
{x:34.733153,y:-80.769501},
{x:34.772446,y:-80.38132},
{x:34.746205,y:-81.039032},
{x:34.940455,y:-80.977331},
{x:34.972295,y:-80.98774},
{x:34.914908,y:-81.003688},
{x:34.979287,y:-81.089854},
{x:34.975931,y:-81.004656},
{x:34.942105,y:-81.035508},
{x:34.950759,y:-81.062045},
{x:34.985831,y:-81.211946},
{x:33.578365,y:-81.703069},
{x:33.563999,y:-81.768107},
{x:33.664495,y:-81.674694},
{x:33.523587,y:-81.719053},
{x:33.243722,y:-81.374743},
{x:33.77058,y:-81.912395},
{x:33.603682,y:-81.843094},
{x:33.503385,y:-81.900452},
{x:33.519854,y:-81.95556},
{x:33.427829,y:-81.888178},
{x:33.566881,y:-81.936836},
{x:32.44022,y:-80.705551},
{x:32.37887,y:-80.726269},
{x:32.425404,y:-80.734093},
{x:32.41299,y:-80.647518},
{x:32.241385,y:-80.823695},
{x:32.272501,y:-80.915597},
{x:32.873761,y:-81.116661},
{x:32.21429,y:-80.700964},
{x:32.3119,y:-81.054458},
{x:32.155789,y:-80.759722},
{x:32.476346,y:-80.969044},
{x:32.633123,y:-80.876795},
{x:34.142961,y:-84.251574},
{x:34.091993,y:-84.275934},
{x:34.082351,y:-84.358087},
{x:34.090602,y:-84.203944},
{x:34.010359,y:-83.851848},
{x:33.627376,y:-83.976963},
{x:33.602478,y:-83.853031},
{x:33.614456,y:-83.921118},
{x:33.610634,y:-83.826982},
{x:33.570428,y:-83.848253},
{x:33.579571,y:-83.963699},
{x:33.898403,y:-83.961369},
{x:34.07355,y:-83.91713},
{x:34.001634,y:-83.910404},
{x:34.067434,y:-84.262111},
{x:34.035378,y:-84.342995},
{x:33.989644,y:-84.274328},
{x:34.035238,y:-84.052897},
{x:34.048543,y:-84.093832},
{x:33.778214,y:-84.295807},
{x:33.767277,y:-84.251961},
{x:33.73228,y:-84.283143},
{x:33.810284,y:-84.274839},
{x:33.790254,y:-84.307307},
{x:33.704266,y:-84.271627},
{x:33.710712,y:-84.216934},
{x:33.719021,y:-84.215784},
{x:33.700529,y:-84.166999},
{x:33.696502,y:-84.088161},
{x:33.706161,y:-84.169913},
{x:34.23912,y:-84.194207},
{x:34.187035,y:-84.142448},
{x:34.160097,y:-84.1755},
{x:34.20881,y:-84.22029},
{x:34.164011,y:-84.117769},
{x:34.112771,y:-84.15319},
{x:34.036275,y:-83.988891},
{x:33.999962,y:-84.070101},
{x:33.939723,y:-84.120875},
{x:33.923483,y:-84.01685},
{x:33.948624,y:-83.987518},
{x:33.9673,y:-84.014595},
{x:33.986721,y:-83.985264},
{x:33.956078,y:-84.056383},
{x:33.886429,y:-84.060989},
{x:33.903988,y:-84.119125},
{x:33.834634,y:-83.890793},
{x:33.702026,y:-84.115608},
{x:34.022803,y:-84.426697},
{x:33.936544,y:-84.512181},
{x:33.979578,y:-84.463301},
{x:33.952435,y:-84.575333},
{x:33.9234,y:-84.575376},
{x:34.024632,y:-84.528221},
{x:34.017384,y:-84.491219},
{x:33.982471,y:-84.558487},
{x:33.92018,y:-84.466945},
{x:33.900566,y:-84.474586},
{x:33.909895,y:-84.488531},
{x:33.923191,y:-84.482705},
{x:33.96681,y:-84.410688},
{x:33.945576,y:-84.241531},
{x:33.944627,y:-84.204949},
{x:34.057458,y:-84.380857},
{x:34.122372,y:-84.52463},
{x:34.019781,y:-84.318421},
{x:34.045263,y:-84.333522},
{x:33.875414,y:-84.013928},
{x:33.883873,y:-84.476479},
{x:33.876029,y:-84.53204},
{x:33.827199,y:-84.492468},
{x:33.804006,y:-84.193154},
{x:33.843369,y:-84.257767},
{x:33.84085,y:-84.200359},
{x:33.849751,y:-84.216934},
{x:33.78546,y:-84.101565},
{x:33.785221,y:-84.161484},
{x:33.823986,y:-84.110992},
{x:33.760826,y:-84.196098},
{x:33.98595,y:-84.212918},
{x:33.868179,y:-84.187752},
{x:33.910393,y:-84.208199},
{x:33.923847,y:-84.172318},
{x:33.643127,y:-84.015007},
{x:33.664124,y:-84.031395},
{x:34.005349,y:-84.170935},
{x:33.976607,y:-84.092146},
{x:33.953613,y:-84.131516},
{x:34.009014,y:-84.129738},
{x:34.035102,y:-84.675858},
{x:34.062577,y:-84.724309},
{x:34.086578,y:-84.575737},
{x:34.000205,y:-84.70491},
{x:34.076725,y:-84.654015},
{x:34.376415,y:-84.914924},
{x:33.855584,y:-84.60163},
{x:33.865749,y:-84.626015},
{x:33.687691,y:-85.152336},
{x:34.198034,y:-84.509092},
{x:34.235829,y:-84.452264},
{x:34.251765,y:-84.473968},
{x:34.173432,y:-84.54949},
{x:34.175357,y:-84.41866},
{x:34.253833,y:-84.359024},
{x:33.595311,y:-85.037446},
{x:33.572155,y:-85.093285},
{x:33.599151,y:-85.079376},
{x:33.561091,y:-85.073855},
{x:34.207437,y:-84.81971},
{x:34.176216,y:-84.786964},
{x:34.272533,y:-84.807739},
{x:34.151222,y:-84.824462},
{x:34.119006,y:-84.743488},
{x:34.174236,y:-84.763329},
{x:34.209851,y:-84.759256},
{x:33.781591,y:-84.609331},
{x:33.733526,y:-84.668992},
{x:34.01865,y:-85.252808},
{x:34.045526,y:-85.235517},
{x:33.835681,y:-84.57679},
{x:33.806324,y:-84.53053},
{x:33.940251,y:-84.701912},
{x:33.858486,y:-84.671374},
{x:34.253461,y:-84.089304},
{x:33.867816,y:-84.842275},
{x:34.011247,y:-84.753871},
{x:33.91114,y:-84.8022},
{x:33.750694,y:-84.731331},
{x:33.724514,y:-84.762733},
{x:33.730942,y:-84.736538},
{x:33.89172,y:-84.756926},
{x:34.465047,y:-84.455515},
{x:34.002243,y:-84.599052},
{x:34.047922,y:-84.611369},
{x:34.013562,y:-84.56067},
{x:34.060282,y:-84.593244},
{x:34.009711,y:-85.04167},
{x:34.246075,y:-85.161697},
{x:34.304988,y:-85.175058},
{x:34.27103,y:-85.228645},
{x:34.069106,y:-84.168426},
{x:33.720803,y:-85.029324},
{x:33.733176,y:-84.900504},
{x:33.72629,y:-84.939074},
{x:34.086993,y:-84.48315},
{x:34.086483,y:-84.522444},
{x:34.10949,y:-84.55304},
{x:34.049413,y:-84.224408},
{x:33.054901,y:-84.153052},
{x:33.61277,y:-84.611539},
{x:33.543038,y:-84.576207},
{x:33.430471,y:-84.456993},
{x:33.457455,y:-84.453316},
{x:33.275249,y:-84.291908},
{x:33.197656,y:-84.283878},
{x:33.247684,y:-84.272675},
{x:33.446883,y:-84.325217},
{x:33.161003,y:-84.874436},
{x:33.294177,y:-83.960741},
{x:33.208253,y:-84.058667},
{x:33.572293,y:-84.371404},
{x:33.522522,y:-84.423673},
{x:33.539272,y:-84.36512},
{x:33.049004,y:-85.023689},
{x:33.029185,y:-85.070549},
{x:32.99113,y:-85.030187},
{x:33.04186,y:-84.981112},
{x:33.352974,y:-84.122476},
{x:33.366881,y:-84.180119},
{x:33.439655,y:-84.121654},
{x:33.428516,y:-84.179184},
{x:33.463013,y:-84.214106},
{x:33.452872,y:-84.165516},
{x:33.507158,y:-84.141585},
{x:33.589464,y:-84.340311},
{x:33.561986,y:-84.321108},
{x:33.613571,y:-84.348572},
{x:33.384846,y:-84.784729},
{x:33.325648,y:-84.779807},
{x:33.408193,y:-84.678489},
{x:33.376792,y:-84.753854},
{x:33.528004,y:-84.659637},
{x:33.372451,y:-84.568457},
{x:33.410113,y:-84.566835},
{x:33.401234,y:-84.599971},
{x:33.653809,y:-84.397957},
{x:33.620643,y:-84.382806},
{x:33.570952,y:-84.41323},
{x:33.562362,y:-84.410307},
{x:33.293462,y:-84.543361},
{x:33.855387,y:-84.025078},
{x:33.804929,y:-84.042769},
{x:33.61996,y:-84.235955},
{x:33.50376,y:-84.236292},
{x:33.518828,y:-84.19305},
{x:33.543469,y:-84.281524},
{x:33.554238,y:-84.266608},
{x:32.92623,y:-84.334571},
{x:33.568592,y:-84.538147},
{x:33.611751,y:-84.29998},
{x:33.553299,y:-84.465824},
{x:33.647865,y:-84.365763},
{x:33.753826,y:-84.392797},
{x:33.835169,y:-84.381729},
{x:33.774301,y:-84.369594},
{x:33.738166,y:-84.425756},
{x:33.722861,y:-84.507647},
{x:33.754277,y:-84.417269},
{x:33.698148,y:-84.408475},
{x:33.741796,y:-84.349587},
{x:33.784941,y:-84.406922},
{x:33.809216,y:-84.413184},
{x:33.894117,y:-84.328892},
{x:33.859733,y:-84.340733},
{x:33.640691,y:-84.425213},
{x:33.639233,y:-84.439089},
{x:33.819928,y:-84.351816},
{x:33.847015,y:-84.429665},
{x:33.91988,y:-84.378571},
{x:33.832473,y:-84.338185},
{x:33.856457,y:-84.312744},
{x:33.83021,y:-84.332562},
{x:33.757763,y:-84.503471},
{x:33.728949,y:-84.573866},
{x:33.750939,y:-84.467307},
{x:33.957855,y:-84.302963},
{x:33.921217,y:-84.313468},
{x:33.905064,y:-84.429848},
{x:33.908967,y:-84.26739},
{x:33.880838,y:-84.274825},
{x:33.897263,y:-84.302147},
{x:33.885994,y:-84.254662},
{x:33.873859,y:-84.381409},
{x:33.908859,y:-84.354092},
{x:33.680817,y:-84.416265},
{x:33.659396,y:-84.428756},
{x:33.654648,y:-84.494354},
{x:33.841824,y:-84.313264},
{x:33.926833,y:-84.337704},
{x:33.61418,y:-84.437404},
{x:33.615967,y:-84.47316},
{x:33.994807,y:-84.350626},
{x:32.585432,y:-82.323886},
{x:32.16273,y:-81.903466},
{x:31.931729,y:-81.931225},
{x:33.011056,y:-82.399311},
{x:32.375854,y:-82.06296},
{x:32.803411,y:-81.949763},
{x:32.087151,y:-82.1169},
{x:32.412003,y:-81.767048},
{x:32.438991,y:-81.762498},
{x:32.748504,y:-81.642612},
{x:32.213572,y:-82.4027},
{x:32.202071,y:-82.362257},
{x:34.298913,y:-83.857672},
{x:34.292545,y:-83.829895},
{x:34.331681,y:-83.841533},
{x:34.312679,y:-83.797077},
{x:34.22718,y:-83.865479},
{x:34.871365,y:-83.964501},
{x:34.874203,y:-84.321907},
{x:34.119612,y:-83.762014},
{x:34.094597,y:-84.012009},
{x:34.121851,y:-84.079111},
{x:34.063698,y:-83.991028},
{x:34.142629,y:-83.952442},
{x:34.604275,y:-83.519485},
{x:34.875579,y:-83.398982},
{x:34.594273,y:-83.762915},
{x:34.258873,y:-83.464432},
{x:34.511589,y:-83.545601},
{x:34.525673,y:-83.976959},
{x:34.363484,y:-84.037717},
{x:34.354985,y:-84.041684},
{x:34.678172,y:-84.478651},
{x:34.108447,y:-83.874455},
{x:34.955976,y:-83.759526},
{x:34.107952,y:-83.69891},
{x:34.151706,y:-83.642761},
{x:34.44629,y:-83.119651},
{x:34.569271,y:-83.303897},
{x:33.993707,y:-83.339071},
{x:33.962504,y:-83.395861},
{x:33.949856,y:-83.411278},
{x:33.937721,y:-83.324502},
{x:33.915535,y:-83.447115},
{x:33.944322,y:-83.75581},
{x:33.940245,y:-83.471697},
{x:34.10851,y:-82.860759},
{x:33.548312,y:-83.204364},
{x:34.352402,y:-82.929955},
{x:33.557919,y:-83.474609},
{x:33.795054,y:-83.743528},
{x:34.28628,y:-83.159777},
{x:33.726601,y:-82.716124},
{x:33.872473,y:-83.440342},
{x:33.991284,y:-83.723232},
{x:34.471798,y:-84.921829},
{x:34.784693,y:-84.771511},
{x:34.88228,y:-85.271142},
{x:34.791752,y:-84.961474},
{x:34.760363,y:-84.999618},
{x:34.893838,y:-84.946002},
{x:34.765892,y:-84.929105},
{x:34.655762,y:-84.980294},
{x:34.723566,y:-85.280517},
{x:34.911642,y:-85.124897},
{x:34.953032,y:-85.244466},
{x:34.493868,y:-85.33595},
{x:34.868951,y:-85.514245},
{x:33.572213,y:-82.193372},
{x:33.533195,y:-82.125914},
{x:33.48759,y:-82.200488},
{x:33.458786,y:-82.192762},
{x:33.376454,y:-82.073738},
{x:33.509079,y:-82.502116},
{x:33.101067,y:-82.022782},
{x:33.209858,y:-82.387627},
{x:33.474339,y:-81.986077},
{x:33.409793,y:-82.02643},
{x:33.395106,y:-82.004598},
{x:33.440483,y:-82.021358},
{x:33.41928,y:-82.078724},
{x:33.528822,y:-82.073215},
{x:33.506939,y:-82.142594},
{x:33.512441,y:-82.086722},
{x:33.480687,y:-82.12969},
{x:33.481873,y:-82.090135},
{x:33.468527,y:-82.077485},
{x:33.511032,y:-82.038583},
{x:33.471209,y:-81.988183},
{x:32.555046,y:-83.661733},
{x:32.661646,y:-83.740581},
{x:32.3869,y:-83.356154},
{x:31.96121,y:-83.756308},
{x:32.539962,y:-82.942906},
{x:32.194754,y:-83.17657},
{x:33.422294,y:-83.268611},
{x:33.32254,y:-83.386686},
{x:33.040501,y:-83.938828},
{x:32.557259,y:-83.883987},
{x:33.004285,y:-83.548891},
{x:32.281842,y:-83.465969},
{x:32.489855,y:-82.934633},
{x:32.067633,y:-82.897888},
{x:33.112936,y:-83.259388},
{x:33.073645,y:-83.225526},
{x:32.294041,y:-84.021367},
{x:32.473046,y:-83.704409},
{x:32.468492,y:-83.74138},
{x:32.971734,y:-82.80877},
{x:32.593453,y:-83.626248},
{x:32.58213,y:-83.694931},
{x:32.085915,y:-83.760155},
{x:32.621369,y:-83.673082},
{x:32.617842,y:-83.640741},
{x:32.615857,y:-83.695207},
{x:32.834007,y:-83.635422},
{x:32.849163,y:-83.626081},
{x:32.790703,y:-83.670381},
{x:32.812067,y:-83.723034},
{x:32.814973,y:-83.663289},
{x:32.879536,y:-83.765256},
{x:32.90038,y:-83.686913},
{x:32.932275,y:-83.727381},
{x:32.743624,y:-83.72428},
{x:32.806017,y:-83.567846},
{x:31.393684,y:-81.447341},
{x:31.845823,y:-81.593223},
{x:31.830964,y:-81.615739},
{x:31.826017,y:-81.601637},
{x:31.779697,y:-81.383211},
{x:32.136278,y:-81.62122},
{x:32.11126,y:-81.236326},
{x:32.141276,y:-81.247823},
{x:31.651582,y:-81.392879},
{x:31.960581,y:-81.331504},
{x:31.926322,y:-81.334755},
{x:31.911266,y:-81.278442},
{x:32.27711,y:-81.230427},
{x:32.079912,y:-81.095165},
{x:32.047615,y:-81.064985},
{x:32.024945,y:-81.225748},
{x:32.026115,y:-81.098525},
{x:32.053057,y:-81.106761},
{x:32.048655,y:-81.16619},
{x:31.989314,y:-81.081451},
{x:32.002431,y:-81.121158},
{x:32.006927,y:-81.100441},
{x:32.188749,y:-81.194943},
{x:32.143985,y:-81.24378},
{x:32.108218,y:-81.1555},
{x:32.015985,y:-80.978343},
{x:32.043208,y:-81.006124},
{x:31.98131,y:-81.168351},
{x:32.00468,y:-81.281445},
{x:31.20957,y:-82.383011},
{x:31.197686,y:-82.324873},
{x:31.538834,y:-82.462049},
{x:31.779767,y:-82.347747},
{x:31.30677,y:-82.241238},
{x:31.216552,y:-81.519247},
{x:31.1942,y:-81.48627},
{x:31.157587,y:-81.479696},
{x:31.133803,y:-81.568137},
{x:31.160287,y:-81.392181},
{x:31.232811,y:-81.498363},
{x:31.491928,y:-82.850528},
{x:30.828994,y:-82.005906},
{x:31.860199,y:-82.605539},
{x:31.594668,y:-81.871063},
{x:30.793101,y:-81.571534},
{x:30.788607,y:-81.655274},
{x:30.840176,y:-83.319945},
{x:30.82208,y:-83.273785},
{x:30.820599,y:-83.312904},
{x:30.896472,y:-83.330967},
{x:30.858098,y:-83.281726},
{x:30.91111,y:-83.263322},
{x:31.1353,y:-83.436792},
{x:31.041209,y:-83.077209},
{x:30.679914,y:-83.225334},
{x:31.199247,y:-83.25162},
{x:30.784935,y:-83.567398},
{x:31.562985,y:-84.177},
{x:31.611301,y:-84.175395},
{x:31.570493,y:-84.10811},
{x:31.585337,y:-84.220014},
{x:31.61674,y:-84.219808},
{x:32.071632,y:-84.216418},
{x:31.71,y:-83.640943},
{x:30.904309,y:-84.553421},
{x:32.063987,y:-84.253203},
{x:30.884025,y:-84.203102},
{x:31.2304,y:-84.19949},
{x:31.702458,y:-83.252723},
{x:31.718997,y:-84.173252},
{x:31.1785,y:-83.782307},
{x:31.530486,y:-83.826452},
{x:30.858186,y:-83.949703},
{x:31.444527,y:-83.544914},
{x:31.472399,y:-83.503583},
{x:31.45743,y:-83.526732},
{x:32.853581,y:-84.618394},
{x:32.544275,y:-84.872678},
{x:32.472139,y:-84.987072},
{x:32.425654,y:-84.949385},
{x:32.519709,y:-84.955595},
{x:32.506681,y:-84.971289},
{x:32.532761,y:-84.968207},
{x:32.482322,y:-84.941197},
{x:32.464455,y:-84.923693},
{x:32.521555,y:-84.888777},
{x:32.554223,y:-84.94672},
{x:30.102837,y:-81.711679},
{x:29.95621,y:-82.92362},
{x:30.563913,y:-81.830604},
{x:30.183721,y:-82.638858},
{x:30.642142,y:-81.458202},
{x:30.005074,y:-81.689186},
{x:30.180896,y:-82.683683},
{x:30.328793,y:-82.964755},
{x:30.265517,y:-82.122303},
{x:30.13245,y:-81.782321},
{x:30.137703,y:-81.768704},
{x:30.071969,y:-81.864438},
{x:30.099645,y:-81.775889},
{x:30.1838,y:-81.702335},
{x:30.166446,y:-81.748459},
{x:29.278287,y:-81.083709},
{x:30.192291,y:-81.380886},
{x:29.864094,y:-81.342807},
{x:29.861328,y:-81.280945},
{x:29.902844,y:-81.320066},
{x:29.917413,y:-81.408778},
{x:29.853488,y:-81.323489},
{x:29.937641,y:-82.111754},
{x:29.962529,y:-81.490367},
{x:30.057698,y:-81.536684},
{x:30.317937,y:-82.805511},
{x:30.627912,y:-81.551909},
{x:30.619995,y:-81.645355},
{x:29.19786,y:-81.055161},
{x:29.228316,y:-81.031539},
{x:29.267795,y:-81.028909},
{x:29.142543,y:-81.035825},
{x:29.173324,y:-81.032064},
{x:29.17252,y:-81.029995},
{x:29.168477,y:-81.087094},
{x:29.104431,y:-81.034067},
{x:29.126298,y:-81.006235},
{x:29.644963,y:-81.60551},
{x:29.476529,y:-81.181009},
{x:29.554329,y:-81.211953},
{x:29.550485,y:-81.237321},
{x:29.628262,y:-81.887636},
{x:28.930143,y:-81.929754},
{x:28.865289,y:-81.955349},
{x:28.927947,y:-82.031202},
{x:29.47617,y:-81.216762},
{x:29.00497,y:-80.915529},
{x:29.01343,y:-80.99264},
{x:29.013691,y:-80.943292},
{x:29.255791,y:-81.117973},
{x:29.333154,y:-81.128546},
{x:29.638591,y:-81.681048},
{x:29.655276,y:-81.65782},
{x:30.332685,y:-81.652685},
{x:30.301466,y:-81.758458},
{x:30.30521,y:-81.752898},
{x:30.293692,y:-81.604036},
{x:30.317354,y:-81.491443},
{x:30.371322,y:-81.664407},
{x:30.370077,y:-81.723272},
{x:30.346679,y:-81.667338},
{x:30.250499,y:-81.753025},
{x:30.270796,y:-81.736399},
{x:30.248643,y:-81.697322},
{x:30.248744,y:-81.767931},
{x:30.329419,y:-81.603126},
{x:30.342304,y:-81.54535},
{x:30.223648,y:-81.678988},
{x:30.283467,y:-81.562671},
{x:30.17941,y:-81.553616},
{x:30.261978,y:-81.624138},
{x:30.450232,y:-81.706926},
{x:30.461062,y:-81.634007},
{x:30.480178,y:-81.635695},
{x:30.430416,y:-81.660311},
{x:30.437641,y:-81.698043},
{x:30.288912,y:-81.632575},
{x:30.306437,y:-81.847938},
{x:30.292699,y:-81.782155},
{x:30.196345,y:-81.825657},
{x:30.176786,y:-81.625256},
{x:30.160532,y:-81.633075},
{x:30.284649,y:-81.46266},
{x:30.322699,y:-81.54376},
{x:30.320623,y:-81.46106},
{x:30.360598,y:-81.502281},
{x:30.324963,y:-81.40061},
{x:30.285994,y:-81.982161},
{x:30.205256,y:-81.74015},
{x:30.195256,y:-81.796157},
{x:30.26075,y:-81.528673},
{x:30.265013,y:-81.557143},
{x:30.286426,y:-81.527243},
{x:30.28762,y:-81.391185},
{x:30.287346,y:-81.441101},
{x:30.253415,y:-81.389035},
{x:30.321196,y:-81.731299},
{x:30.244781,y:-81.597404},
{x:30.215757,y:-81.510872},
{x:30.220363,y:-81.579741},
{x:30.172703,y:-81.60418},
{x:30.192716,y:-81.624223},
{x:30.142374,y:-81.545481},
{x:30.06578,y:-81.494338},
{x:30.117581,y:-81.62629},
{x:30.365679,y:-81.597418},
{x:30.352432,y:-81.576694},
{x:30.439388,y:-84.262316},
{x:30.40207,y:-84.236183},
{x:30.426608,y:-84.231647},
{x:30.412145,y:-84.281044},
{x:30.502311,y:-84.328582},
{x:30.46668,y:-84.285017},
{x:30.44104,y:-84.319943},
{x:30.458446,y:-84.363986},
{x:30.433657,y:-84.301518},
{x:30.445152,y:-84.293657},
{x:30.463498,y:-84.217052},
{x:30.500051,y:-84.248093},
{x:30.475717,y:-84.236367},
{x:30.507695,y:-84.25006},
{x:30.194321,y:-84.370565},
{x:30.399529,y:-83.3583},
{x:30.46895,y:-83.408872},
{x:30.471593,y:-83.89055},
{x:30.097647,y:-83.5818},
{x:30.587519,y:-84.595083},
{x:30.161386,y:-85.660565},
{x:30.161255,y:-85.753704},
{x:30.179866,y:-85.804864},
{x:30.181083,y:-85.708451},
{x:30.146441,y:-85.591644},
{x:30.161214,y:-85.592748},
{x:30.189396,y:-85.676796},
{x:30.183726,y:-85.650547},
{x:30.178418,y:-85.801986},
{x:30.221703,y:-85.887321},
{x:30.220156,y:-85.865976},
{x:30.442939,y:-85.048142},
{x:30.769754,y:-85.684073},
{x:30.764147,y:-85.541743},
{x:30.748781,y:-85.382498},
{x:30.687941,y:-86.123226},
{x:30.734317,y:-86.349258},
{x:30.232222,y:-85.649571},
{x:30.782659,y:-85.246607},
{x:30.71701,y:-85.184688},
{x:29.816115,y:-85.305504},
{x:30.422618,y:-87.207329},
{x:30.482355,y:-87.212761},
{x:30.471779,y:-87.208137},
{x:30.496453,y:-87.189204},
{x:30.495959,y:-87.262181},
{x:30.445776,y:-87.241351},
{x:30.402812,y:-87.343249},
{x:30.320856,y:-87.421245},
{x:30.405824,y:-87.275594},
{x:30.507725,y:-87.222091},
{x:30.519005,y:-87.313918},
{x:30.461474,y:-87.301715},
{x:30.578737,y:-87.294858},
{x:30.532437,y:-87.266955},
{x:30.79226,y:-86.558689},
{x:30.763057,y:-86.576791},
{x:30.728647,y:-86.567053},
{x:30.389302,y:-86.480804},
{x:30.456252,y:-86.637515},
{x:30.414264,y:-86.604853},
{x:30.385841,y:-86.384971},
{x:30.366951,y:-87.17207},
{x:30.412168,y:-86.830856},
{x:30.387606,y:-87.085669},
{x:30.402074,y:-86.861392},
{x:30.411128,y:-86.747533},
{x:30.419329,y:-86.653426},
{x:30.615089,y:-87.052205},
{x:30.591096,y:-87.175938},
{x:30.60381,y:-87.11493},
{x:30.517142,y:-86.468533},
{x:30.441962,y:-86.580299},
{x:30.537356,y:-87.087861},
{x:30.483041,y:-86.414823},
{x:29.652289,y:-82.312657},
{x:29.652479,y:-82.337366},
{x:29.65914,y:-82.407261},
{x:29.701175,y:-82.389627},
{x:29.689636,y:-82.441499},
{x:29.603995,y:-82.371589},
{x:29.618908,y:-82.384161},
{x:29.679398,y:-82.339552},
{x:29.801013,y:-82.512839},
{x:29.493025,y:-82.86456},
{x:29.636239,y:-83.13072},
{x:29.602397,y:-82.08658},
{x:29.663887,y:-82.301254},
{x:28.832831,y:-82.327446},
{x:29.791953,y:-82.042243},
{x:29.654321,y:-82.493945},
{x:29.613631,y:-82.816792},
{x:29.385774,y:-82.456351},
{x:28.673712,y:-81.506546},
{x:28.671108,y:-81.452977},
{x:28.635005,y:-81.323333},
{x:28.632852,y:-81.320172},
{x:28.64997,y:-81.269069},
{x:28.696988,y:-81.261957},
{x:28.687926,y:-81.541433},
{x:28.708288,y:-81.508694},
{x:28.661425,y:-81.391743},
{x:28.659786,y:-81.419696},
{x:28.981691,y:-81.298804},
{x:29.061687,y:-81.30227},
{x:29.087261,y:-81.324789},
{x:29.054178,y:-81.303003},
{x:28.872941,y:-81.280155},
{x:28.827153,y:-81.685855},
{x:28.663729,y:-81.3453},
{x:28.925222,y:-81.189842},
{x:28.872668,y:-81.164827},
{x:28.786838,y:-81.358649},
{x:28.756756,y:-81.351102},
{x:28.700479,y:-81.327561},
{x:28.697519,y:-81.360438},
{x:28.626615,y:-81.363154},
{x:28.665841,y:-80.868239},
{x:28.823569,y:-81.643271},
{x:28.780794,y:-81.623735},
{x:28.921265,y:-81.298236},
{x:28.654145,y:-81.227944},
{x:28.656619,y:-81.180815},
{x:28.619122,y:-81.260531},
{x:28.812437,y:-81.334623},
{x:28.802404,y:-81.328335},
{x:28.81108,y:-81.273652},
{x:28.757496,y:-81.286102},
{x:28.707041,y:-81.443712},
{x:28.568538,y:-80.797795},
{x:28.55267,y:-80.847325},
{x:28.931522,y:-81.66621},
{x:28.592665,y:-81.366653},
{x:28.601045,y:-81.319986},
{x:28.596753,y:-81.304948},
{x:28.619143,y:-80.842644},
{x:28.568811,y:-81.287366},
{x:28.553767,y:-81.348792},
{x:28.576735,y:-81.415653},
{x:28.508481,y:-81.422793},
{x:28.531839,y:-81.397699},
{x:28.514429,y:-81.37669},
{x:28.524702,y:-81.309824},
{x:28.55332,y:-81.415035},
{x:28.577491,y:-81.453128},
{x:28.552641,y:-81.464259},
{x:28.484031,y:-81.459479},
{x:28.473261,y:-81.397104},
{x:28.504066,y:-81.396234},
{x:28.452618,y:-81.364791},
{x:28.441261,y:-81.402852},
{x:28.618841,y:-81.441938},
{x:28.605507,y:-81.395156},
{x:28.511426,y:-81.459638},
{x:28.515442,y:-81.462805},
{x:28.45208,y:-81.336865},
{x:28.481361,y:-81.330358},
{x:28.56581,y:-81.218233},
{x:28.599912,y:-81.208303},
{x:28.570314,y:-81.234199},
{x:28.577974,y:-81.474716},
{x:28.450466,y:-81.471428},
{x:28.439873,y:-81.476551},
{x:28.449599,y:-81.480186},
{x:28.44753,y:-81.424506},
{x:28.423817,y:-81.461309},
{x:28.469297,y:-81.451068},
{x:28.559685,y:-81.129263},
{x:28.388908,y:-81.488983},
{x:28.407458,y:-81.450275},
{x:28.481604,y:-81.309352},
{x:28.50317,y:-81.310885},
{x:28.537369,y:-81.28521},
{x:28.371838,y:-81.366263},
{x:28.566176,y:-81.197},
{x:28.431169,y:-81.308055},
{x:28.435455,y:-81.301579},
{x:28.538107,y:-81.15005},
{x:28.546324,y:-81.202638},
{x:28.509515,y:-81.241792},
{x:28.462001,y:-81.272524},
{x:28.348866,y:-81.576837},
{x:28.349284,y:-81.233968},
{x:28.412532,y:-81.242338},
{x:28.524465,y:-81.470612},
{x:28.382358,y:-81.5048},
{x:28.399988,y:-81.405171},
{x:28.361105,y:-81.423117},
{x:28.446869,y:-81.426848},
{x:28.488136,y:-81.41685},
{x:28.491527,y:-81.429577},
{x:28.084596,y:-80.622757},
{x:28.079293,y:-80.66174},
{x:28.10017,y:-80.6721},
{x:28.036192,y:-80.664722},
{x:27.996698,y:-80.641804},
{x:28.03445,y:-80.621182},
{x:27.998667,y:-80.673163},
{x:27.999126,y:-80.633516},
{x:27.949828,y:-80.656868},
{x:28.396663,y:-80.611852},
{x:28.374597,y:-80.740937},
{x:28.139154,y:-80.67219},
{x:28.359351,y:-80.797751},
{x:28.397644,y:-80.764504},
{x:28.479592,y:-80.770492},
{x:28.355375,y:-80.608824},
{x:28.122112,y:-80.639082},
{x:28.138241,y:-80.582761},
{x:28.184612,y:-80.59299},
{x:28.230928,y:-80.715621},
{x:28.216064,y:-80.674479},
{x:28.233119,y:-80.724186},
{x:28.374607,y:-80.700537},
{x:28.328289,y:-80.719813},
{x:28.292565,y:-80.722716},
{x:28.268241,y:-80.737222},
{x:27.837554,y:-80.485655},
{x:27.636812,y:-80.389677},
{x:27.588802,y:-80.377768},
{x:27.638297,y:-80.448234},
{x:27.639883,y:-80.513949},
{x:27.688992,y:-80.411647},
{x:27.76699,y:-80.548726},
{x:26.035724,y:-80.143686},
{x:25.984582,y:-80.160216},
{x:25.987095,y:-80.142841},
{x:25.831946,y:-80.261126},
{x:25.866973,y:-80.3052},
{x:25.868685,y:-80.31678},
{x:25.861802,y:-80.27502},
{x:25.852897,y:-80.319695},
{x:25.859504,y:-80.314373},
{x:25.822662,y:-80.271915},
{x:25.883691,y:-80.316938},
{x:25.847729,y:-80.288598},
{x:25.910885,y:-80.319604},
{x:25.887343,y:-80.291105},
{x:25.942678,y:-80.340583},
{x:25.925483,y:-80.308507},
{x:25.935346,y:-80.294228},
{x:25.935846,y:-80.295902},
{x:25.896887,y:-80.350484},
{x:25.876749,y:-80.330895},
{x:26.033807,y:-80.165193},
{x:26.027423,y:-80.208854},
{x:26.010492,y:-80.204989},
{x:26.011253,y:-80.172951},
{x:25.984119,y:-80.21113},
{x:26.021688,y:-80.249064},
{x:26.045567,y:-80.231845},
{x:26.047751,y:-80.159686},
{x:25.987747,y:-80.278405},
{x:25.979326,y:-80.247605},
{x:26.022201,y:-80.298043},
{x:25.97919,y:-80.374193},
{x:25.979212,y:-80.336621},
{x:26.007037,y:-80.314387},
{x:26.008319,y:-80.394906},
{x:26.005706,y:-80.389516},
{x:25.485196,y:-80.459808},
{x:25.519399,y:-80.426093},
{x:25.550276,y:-80.371613},
{x:25.499222,y:-80.411901},
{x:25.477004,y:-80.434625},
{x:25.457704,y:-80.473083},
{x:25.442581,y:-80.474594},
{x:24.571897,y:-81.756906},
{x:24.716418,y:-81.073326},
{x:25.939415,y:-80.27658},
{x:25.952903,y:-80.243701},
{x:25.940175,y:-80.245681},
{x:26.260086,y:-80.127606},
{x:26.232016,y:-80.103647},
{x:26.235801,y:-80.199271},
{x:26.274442,y:-80.132356},
{x:26.290898,y:-80.094718},
{x:26.268923,y:-80.098918},
{x:26.272624,y:-80.229268},
{x:26.27343,y:-80.28194},
{x:26.29957,y:-80.278294},
{x:26.227825,y:-80.225576},
{x:26.207843,y:-80.221505},
{x:26.230478,y:-80.157156},
{x:26.244735,y:-80.164269},
{x:25.004612,y:-80.522609},
{x:26.238756,y:-80.25548},
{x:26.309157,y:-80.20149},
{x:26.273285,y:-80.182739},
{x:26.284873,y:-80.201248},
{x:26.286438,y:-80.247597},
{x:25.792718,y:-80.27542},
{x:25.777626,y:-80.286927},
{x:25.787029,y:-80.335287},
{x:25.779265,y:-80.256514},
{x:25.796169,y:-80.336426},
{x:25.766632,y:-80.198866},
{x:25.773374,y:-80.211831},
{x:25.778003,y:-80.190172},
{x:25.736552,y:-80.246245},
{x:25.763235,y:-80.26286},
{x:25.764843,y:-80.241236},
{x:25.765501,y:-80.218683},
{x:25.79198,y:-80.210788},
{x:25.849872,y:-80.185108},
{x:25.790433,y:-80.132081},
{x:25.789141,y:-80.140924},
{x:25.715959,y:-80.274593},
{x:25.809094,y:-80.232268},
{x:25.701277,y:-80.292742},
{x:25.770176,y:-80.326046},
{x:25.763141,y:-80.313},
{x:25.749746,y:-80.248185},
{x:25.8476,y:-80.242229},
{x:25.859774,y:-80.210077},
{x:25.83273,y:-80.206753},
{x:25.733056,y:-80.303266},
{x:25.733566,y:-80.323661},
{x:25.652864,y:-80.329566},
{x:25.685984,y:-80.382764},
{x:25.579132,y:-80.36734},
{x:25.592569,y:-80.379254},
{x:25.600886,y:-80.352328},
{x:25.627942,y:-80.376025},
{x:25.940145,y:-80.121811},
{x:25.926693,y:-80.155511},
{x:25.928253,y:-80.171337},
{x:25.927854,y:-80.195929},
{x:25.732613,y:-80.378134},
{x:25.716621,y:-80.345713},
{x:25.748622,y:-80.336076},
{x:25.825438,y:-80.326091},
{x:25.808362,y:-80.27729},
{x:25.809995,y:-80.31739},
{x:25.872286,y:-80.242256},
{x:25.897805,y:-80.231708},
{x:25.912657,y:-80.212162},
{x:25.883409,y:-80.209982},
{x:25.935788,y:-80.207722},
{x:25.958969,y:-80.205359},
{x:25.811162,y:-80.381169},
{x:25.783395,y:-80.332566},
{x:25.702003,y:-80.349437},
{x:25.761217,y:-80.354963},
{x:25.768213,y:-80.366668},
{x:25.714164,y:-80.415976},
{x:25.744692,y:-80.39174},
{x:25.729033,y:-80.431431},
{x:25.636053,y:-80.336198},
{x:25.625854,y:-80.415527},
{x:25.598176,y:-80.414039},
{x:25.812111,y:-80.351324},
{x:25.945026,y:-80.163891},
{x:25.895759,y:-80.162258},
{x:25.809965,y:-80.189163},
{x:25.889932,y:-80.186093},
{x:25.760479,y:-80.43208},
{x:25.76053,y:-80.390492},
{x:25.686063,y:-80.403456},
{x:25.664239,y:-80.407332},
{x:25.65558,y:-80.383886},
{x:25.841864,y:-80.36989},
{x:25.578119,y:-80.336189},
{x:25.684779,y:-80.446643},
{x:25.712198,y:-80.447512},
{x:25.670286,y:-80.443349},
{x:26.122705,y:-80.144285},
{x:26.136633,y:-80.140981},
{x:26.16126,y:-80.115819},
{x:26.177289,y:-80.098677},
{x:26.206575,y:-80.107533},
{x:26.188047,y:-80.156033},
{x:26.203597,y:-80.156757},
{x:26.179883,y:-80.203091},
{x:26.166799,y:-80.148806},
{x:26.165777,y:-80.185445},
{x:26.136185,y:-80.173807},
{x:26.121044,y:-80.177442},
{x:26.106043,y:-80.178156},
{x:26.164376,y:-80.224519},
{x:26.139536,y:-80.202142},
{x:26.052417,y:-80.206821},
{x:26.092066,y:-80.157219},
{x:26.101007,y:-80.136357},
{x:26.101859,y:-80.140834},
{x:26.086358,y:-80.229447},
{x:26.100238,y:-80.200787},
{x:26.030125,y:-80.359581},
{x:26.227867,y:-80.27021},
{x:26.194323,y:-80.25267},
{x:26.152414,y:-80.256624},
{x:26.153254,y:-80.283625},
{x:26.146375,y:-80.331458},
{x:26.103006,y:-80.250569},
{x:26.117301,y:-80.331098},
{x:26.109927,y:-80.298513},
{x:26.106874,y:-80.362638},
{x:26.118287,y:-80.393714},
{x:26.045591,y:-80.312393},
{x:26.063591,y:-80.283698},
{x:26.064214,y:-80.359969},
{x:26.188694,y:-80.128907},
{x:26.169387,y:-80.295706},
{x:26.193209,y:-80.273378},
{x:26.177102,y:-80.268358},
{x:26.706936,y:-80.089086},
{x:26.753706,y:-80.073623},
{x:26.723363,y:-80.084266},
{x:26.795066,y:-80.088261},
{x:26.783904,y:-80.101992},
{x:26.790993,y:-80.054739},
{x:26.690037,y:-80.058109},
{x:26.809221,y:-80.094413},
{x:26.651352,y:-80.089645},
{x:26.665319,y:-80.112026},
{x:26.852921,y:-80.058922},
{x:26.760095,y:-80.10463},
{x:26.703748,y:-80.111819},
{x:26.821607,y:-80.084757},
{x:26.689078,y:-80.199452},
{x:26.710021,y:-80.198202},
{x:26.709532,y:-80.228443},
{x:26.707282,y:-80.152843},
{x:26.646333,y:-80.208476},
{x:26.639313,y:-80.204858},
{x:26.660874,y:-80.267367},
{x:26.678279,y:-80.201726},
{x:26.638634,y:-80.113219},
{x:26.651647,y:-80.145854},
{x:26.332738,y:-80.202161},
{x:26.675723,y:-80.66998},
{x:26.393288,y:-80.103128},
{x:26.368989,y:-80.077051},
{x:26.349593,y:-80.155579},
{x:26.512358,y:-80.05871},
{x:26.544195,y:-80.091105},
{x:26.529131,y:-80.122337},
{x:26.512882,y:-80.089558},
{x:26.529543,y:-80.147972},
{x:26.75483,y:-80.945049},
{x:26.318057,y:-80.108209},
{x:26.301598,y:-80.132654},
{x:26.318256,y:-80.156924},
{x:26.459394,y:-80.123182},
{x:26.439176,y:-80.085477},
{x:27.062174,y:-80.137207},
{x:26.935504,y:-80.081092},
{x:26.883599,y:-80.104818},
{x:26.933924,y:-80.139167},
{x:26.643753,y:-80.056775},
{x:26.622422,y:-80.089547},
{x:26.58593,y:-80.06738},
{x:26.589189,y:-80.145193},
{x:26.618685,y:-80.138819},
{x:26.571189,y:-80.114499},
{x:26.618017,y:-80.167175},
{x:26.941555,y:-80.191923},
{x:26.779411,y:-80.292719},
{x:26.443914,y:-80.069134},
{x:26.364495,y:-80.122182},
{x:26.40853,y:-80.145762},
{x:26.370154,y:-80.202644},
{x:27.89328,y:-82.270407},
{x:27.925332,y:-82.325395},
{x:27.923815,y:-82.323329},
{x:27.938259,y:-82.291666},
{x:28.668141,y:-82.139993},
{x:28.347804,y:-82.186942},
{x:27.847249,y:-82.353655},
{x:28.246177,y:-82.189309},
{x:28.269352,y:-82.190895},
{x:28.238212,y:-82.35848},
{x:28.182435,y:-82.354355},
{x:28.219359,y:-82.247871},
{x:27.860113,y:-82.202679},
{x:28.126898,y:-82.501692},
{x:28.141908,y:-82.463355},
{x:28.187808,y:-82.546329},
{x:28.085459,y:-82.579599},
{x:28.193625,y:-82.391646},
{x:28.02523,y:-82.150616},
{x:27.993013,y:-82.122105},
{x:27.855736,y:-82.326122},
{x:27.795193,y:-82.334202},
{x:27.893216,y:-82.319846},
{x:27.713785,y:-82.392938},
{x:27.735534,y:-82.429501},
{x:27.712873,y:-82.336761},
{x:27.91241,y:-82.348808},
{x:27.981209,y:-82.285408},
{x:28.02105,y:-82.245165},
{x:27.93839,y:-82.253153},
{x:27.981131,y:-82.454908},
{x:28.025582,y:-82.484577},
{x:28.023042,y:-82.459846},
{x:27.96424,y:-82.435167},
{x:27.944246,y:-82.475405},
{x:27.937994,y:-82.458323},
{x:27.97475,y:-82.484476},
{x:27.958967,y:-82.504883},
{x:27.945169,y:-82.519888},
{x:27.996672,y:-82.438587},
{x:27.871825,y:-82.506862},
{x:27.894541,y:-82.518043},
{x:28.068535,y:-82.468639},
{x:28.068766,y:-82.433505},
{x:28.055029,y:-82.450095},
{x:28.032253,y:-82.422232},
{x:28.087678,y:-82.460441},
{x:27.996659,y:-82.517253},
{x:28.008415,y:-82.504538},
{x:27.997025,y:-82.569683},
{x:28.0115,y:-82.394082},
{x:28.055392,y:-82.393013},
{x:28.045112,y:-82.505389},
{x:27.981066,y:-82.386834},
{x:27.962125,y:-82.402387},
{x:27.940415,y:-82.368306},
{x:27.943759,y:-82.336503},
{x:28.082845,y:-82.543282},
{x:28.095809,y:-82.502885},
{x:28.061746,y:-82.552117},
{x:28.065494,y:-82.570694},
{x:28.056234,y:-82.626092},
{x:27.910894,y:-82.506659},
{x:28.025424,y:-82.561941},
{x:28.117979,y:-82.379296},
{x:28.14612,y:-82.353814},
{x:27.859399,y:-82.639339},
{x:27.828919,y:-82.647847},
{x:27.806436,y:-82.638061},
{x:27.753916,y:-82.638813},
{x:27.738669,y:-82.748744},
{x:27.770073,y:-82.769343},
{x:27.748596,y:-82.702462},
{x:27.808948,y:-82.793241},
{x:27.821375,y:-82.728968},
{x:27.809842,y:-82.699504},
{x:27.793856,y:-82.729676},
{x:27.736073,y:-82.682301},
{x:27.726845,y:-82.679877},
{x:27.782344,y:-82.679878},
{x:27.959784,y:-82.787078},
{x:27.960214,y:-82.718385},
{x:28.010527,y:-82.708455},
{x:27.91584,y:-82.726445},
{x:28.044529,y:-82.73917},
{x:27.990831,y:-82.755291},
{x:27.941768,y:-82.74639},
{x:27.960953,y:-82.7604},
{x:27.971964,y:-82.829262},
{x:27.931132,y:-82.787267},
{x:27.917263,y:-82.800228},
{x:27.91709,y:-82.763327},
{x:27.894734,y:-82.747222},
{x:27.836313,y:-82.786615},
{x:27.880831,y:-82.8307},
{x:27.839893,y:-82.762827},
{x:27.890754,y:-82.787622},
{x:27.835943,y:-82.680831},
{x:27.8452,y:-82.684913},
{x:27.838784,y:-82.703379},
{x:27.863865,y:-82.728297},
{x:28.054668,y:-81.950658},
{x:28.04838,y:-81.907513},
{x:28.022972,y:-81.924537},
{x:28.011547,y:-81.957398},
{x:28.026355,y:-81.97453},
{x:28.078633,y:-81.962883},
{x:28.086273,y:-81.972248},
{x:28.123464,y:-81.974159},
{x:28.113559,y:-81.946084},
{x:27.938878,y:-81.987159},
{x:27.968064,y:-81.879011},
{x:27.953321,y:-81.925448},
{x:27.9779,y:-81.95917},
{x:28.034135,y:-82.055466},
{x:28.05352,y:-81.776661},
{x:28.056507,y:-81.815898},
{x:27.592526,y:-81.513911},
{x:27.908117,y:-81.842888},
{x:28.23855,y:-81.654376},
{x:28.016732,y:-81.63263},
{x:27.75455,y:-81.801123},
{x:27.756293,y:-81.530961},
{x:28.126471,y:-81.6403},
{x:28.105106,y:-81.635102},
{x:27.293605,y:-81.357682},
{x:27.893637,y:-81.556428},
{x:27.900254,y:-81.598725},
{x:27.960057,y:-81.622879},
{x:27.933304,y:-81.970367},
{x:27.894545,y:-81.972366},
{x:28.155962,y:-81.802103},
{x:27.470484,y:-81.442413},
{x:27.5108,y:-81.493093},
{x:27.536831,y:-81.808963},
{x:28.025646,y:-81.733615},
{x:28.004422,y:-81.781876},
{x:28.003693,y:-81.714194},
{x:27.977864,y:-81.661283},
{x:28.259124,y:-81.593378},
{x:28.262183,y:-81.618614},
{x:26.609511,y:-81.871417},
{x:26.664521,y:-81.888238},
{x:26.679535,y:-81.898529},
{x:26.62074,y:-81.937068},
{x:26.619438,y:-81.940442},
{x:26.562311,y:-81.949204},
{x:26.667869,y:-81.826873},
{x:26.709507,y:-81.719872},
{x:26.618207,y:-81.769534},
{x:26.563788,y:-81.872698},
{x:26.494559,y:-81.943861},
{x:26.68261,y:-81.905556},
{x:26.652003,y:-81.972938},
{x:26.70002,y:-81.941232},
{x:26.598123,y:-81.852427},
{x:26.477951,y:-81.837313},
{x:26.525556,y:-81.870596},
{x:26.610816,y:-81.814031},
{x:26.546508,y:-81.800684},
{x:26.485232,y:-81.784863},
{x:26.556032,y:-81.903654},
{x:26.60732,y:-81.974799},
{x:26.562235,y:-82.007088},
{x:26.69989,y:-81.8508},
{x:26.596301,y:-81.884479},
{x:26.437543,y:-81.770409},
{x:26.761277,y:-81.438738},
{x:26.60687,y:-81.650474},
{x:27.005897,y:-82.132884},
{x:26.896231,y:-82.006595},
{x:27.016944,y:-82.054962},
{x:26.983596,y:-82.100082},
{x:26.066057,y:-81.70224},
{x:26.610173,y:-81.810504},
{x:26.614779,y:-81.667101},
{x:26.95969,y:-82.069137},
{x:26.933907,y:-82.225958},
{x:26.638105,y:-82.036307},
{x:26.197894,y:-81.800778},
{x:26.149417,y:-81.688622},
{x:26.155437,y:-81.688179},
{x:26.213836,y:-81.769094},
{x:26.273293,y:-81.802252},
{x:26.270357,y:-81.745647},
{x:26.273677,y:-81.746458},
{x:26.130056,y:-81.771608},
{x:26.1828,y:-81.701924},
{x:26.190825,y:-81.687666},
{x:26.272589,y:-81.691117},
{x:26.212114,y:-81.730039},
{x:26.327504,y:-81.804982},
{x:26.332049,y:-81.752986},
{x:26.4254,y:-81.433256},
{x:25.952874,y:-81.727494},
{x:27.445189,y:-82.46961},
{x:27.449716,y:-82.527316},
{x:27.447679,y:-82.521215},
{x:27.457322,y:-82.574918},
{x:27.495848,y:-82.482488},
{x:27.493673,y:-82.478332},
{x:27.496392,y:-82.628908},
{x:27.460235,y:-82.615312},
{x:27.463072,y:-82.634509},
{x:27.462064,y:-82.598545},
{x:27.484764,y:-82.431308},
{x:27.520835,y:-82.557989},
{x:27.530987,y:-82.500878},
{x:26.939976,y:-82.349289},
{x:27.261972,y:-82.52352},
{x:27.298311,y:-82.49304},
{x:27.33732,y:-82.458407},
{x:27.268746,y:-82.483459},
{x:27.298297,y:-82.450934},
{x:27.371737,y:-82.53154},
{x:27.328815,y:-82.530107},
{x:27.337941,y:-82.49893},
{x:27.299198,y:-82.530085},
{x:27.389668,y:-82.506135},
{x:27.39494,y:-82.563045},
{x:27.210885,y:-81.84566},
{x:27.136891,y:-82.418747},
{x:27.070776,y:-82.208007},
{x:27.039106,y:-82.220749},
{x:27.105602,y:-82.385298},
{x:27.107651,y:-82.438499},
{x:27.070933,y:-82.423277},
{x:27.05038,y:-82.39181},
{x:29.059982,y:-82.056823},
{x:28.899521,y:-82.587662},
{x:29.060341,y:-82.454959},
{x:28.80757,y:-82.577049},
{x:28.857618,y:-82.392787},
{x:28.85404,y:-82.387769},
{x:28.891805,y:-82.485416},
{x:29.195007,y:-82.091871},
{x:29.209029,y:-82.063855},
{x:29.163614,y:-82.094153},
{x:29.117663,y:-82.027418},
{x:29.025985,y:-82.160494},
{x:29.168522,y:-82.137839},
{x:29.14738,y:-82.187227},
{x:29.159932,y:-82.168344},
{x:29.186811,y:-82.180692},
{x:29.222684,y:-82.14665},
{x:29.079698,y:-82.255635},
{x:29.2663,y:-82.190529},
{x:29.21388,y:-82.198924},
{x:28.961703,y:-81.967857},
{x:28.546463,y:-82.399619},
{x:28.537042,y:-82.40741},
{x:28.522422,y:-82.233312},
{x:28.481071,y:-82.478885},
{x:28.467013,y:-82.62061},
{x:28.458311,y:-82.631307},
{x:28.461702,y:-82.541818},
{x:28.492125,y:-82.540485},
{x:28.434438,y:-82.55036},
{x:28.531062,y:-82.505602},
{x:28.533065,y:-82.509291},
{x:27.893233,y:-82.68101},
{x:28.273305,y:-82.480625},
{x:28.192821,y:-82.465013},
{x:28.236125,y:-82.72857},
{x:28.216666,y:-82.694547},
{x:28.290239,y:-82.676365},
{x:28.274113,y:-82.625175},
{x:28.194388,y:-82.636816},
{x:28.235276,y:-82.666477},
{x:28.330926,y:-82.699863},
{x:28.281591,y:-82.717366},
{x:28.331619,y:-82.658396},
{x:28.035551,y:-82.652316},
{x:28.079991,y:-82.738595},
{x:28.062516,y:-82.706143},
{x:28.142238,y:-82.74109},
{x:28.20105,y:-82.740409},
{x:28.050495,y:-82.780246},
{x:28.019029,y:-82.758639},
{x:28.547484,y:-81.744364},
{x:28.50077,y:-81.718434},
{x:28.54723,y:-81.705571},
{x:28.351693,y:-81.676267},
{x:28.591558,y:-81.751548},
{x:28.561903,y:-81.858959},
{x:28.341356,y:-81.431404},
{x:28.304957,y:-81.41157},
{x:28.31927,y:-81.340113},
{x:28.288152,y:-81.358695},
{x:28.3411,y:-81.388424},
{x:28.262627,y:-81.434171},
{x:28.336675,y:-81.591816},
{x:28.333526,y:-81.513709},
{x:28.18093,y:-81.439572},
{x:28.301731,y:-81.458823},
{x:28.320317,y:-81.471552},
{x:28.346173,y:-81.628787},
{x:28.746857,y:-81.875893},
{x:28.824311,y:-81.887029},
{x:28.552063,y:-81.544449},
{x:28.574185,y:-81.517932},
{x:28.602366,y:-81.551807},
{x:28.551365,y:-81.507462},
{x:28.246524,y:-81.300657},
{x:28.196496,y:-81.293286},
{x:28.872157,y:-82.090933},
{x:28.447877,y:-81.560998},
{x:28.550653,y:-81.583083},
{x:28.520433,y:-81.58646},
{x:28.822005,y:-81.784662},
{x:27.413346,y:-80.400075},
{x:27.41301,y:-80.395701},
{x:27.454204,y:-80.327501},
{x:27.443895,y:-80.340267},
{x:27.521593,y:-80.397739},
{x:27.271513,y:-80.318807},
{x:27.264145,y:-80.401137},
{x:27.262031,y:-80.404786},
{x:27.24977,y:-80.376296},
{x:27.018198,y:-80.46395},
{x:27.245049,y:-80.272764},
{x:27.244103,y:-80.826145},
{x:27.205925,y:-80.830602},
{x:27.422532,y:-80.325307},
{x:27.326492,y:-80.319852},
{x:27.354269,y:-80.356686},
{x:27.315178,y:-80.402294},
{x:27.310904,y:-80.405492},
{x:27.271577,y:-80.429657},
{x:27.17189,y:-80.29263},
{x:27.189291,y:-80.250259},
{x:27.132043,y:-80.205667},
{x:27.162194,y:-80.225557},
{x:27.156973,y:-80.218622},
{x:27.111397,y:-80.258349},
{x:33.576372,y:-86.477874},
{x:33.262701,y:-86.812164},
{x:33.225576,y:-86.802661},
{x:32.922835,y:-85.960973},
{x:34.326745,y:-86.5024},
{x:33.427591,y:-86.952409},
{x:33.395778,y:-86.969166},
{x:33.362789,y:-86.998221},
{x:33.337176,y:-86.918645},
{x:33.45095,y:-86.985855},
{x:33.147222,y:-86.749609},
{x:32.94509,y:-87.148665},
{x:33.348849,y:-86.632255},
{x:33.264543,y:-86.34991},
{x:32.800402,y:-86.575829},
{x:33.179605,y:-86.615788},
{x:34.160927,y:-86.835977},
{x:34.206626,y:-86.876561},
{x:33.758805,y:-87.066033},
{x:33.607706,y:-86.805387},
{x:33.653019,y:-86.809732},
{x:34.051653,y:-86.86875},
{x:34.071633,y:-86.776431},
{x:33.280243,y:-86.852064},
{x:32.959158,y:-86.743196},
{x:33.561363,y:-86.521901},
{x:33.587319,y:-86.1247},
{x:33.329767,y:-87.000144},
{x:33.278793,y:-87.098725},
{x:33.101126,y:-86.861327},
{x:33.949926,y:-86.470674},
{x:33.33481,y:-86.782633},
{x:33.594057,y:-86.282415},
{x:33.695345,y:-86.682431},
{x:33.769412,y:-86.468139},
{x:33.166988,y:-86.260598},
{x:33.439974,y:-86.082639},
{x:33.639865,y:-86.614287},
{x:33.810496,y:-86.823592},
{x:33.504807,y:-86.808039},
{x:33.541176,y:-86.82771},
{x:33.495117,y:-86.87847},
{x:33.460451,y:-86.818287},
{x:33.533232,y:-86.694829},
{x:33.524608,y:-86.722724},
{x:33.500148,y:-86.84734},
{x:33.480104,y:-86.840144},
{x:33.543862,y:-86.748478},
{x:33.561647,y:-86.892604},
{x:33.636819,y:-86.683393},
{x:33.445982,y:-86.790286},
{x:33.407291,y:-86.805837},
{x:33.589565,y:-86.766159},
{x:33.499353,y:-86.908181},
{x:33.356812,y:-86.8554},
{x:33.464274,y:-86.909931},
{x:33.509681,y:-86.787691},
{x:33.53381,y:-86.799447},
{x:33.58838,y:-86.69671},
{x:33.595974,y:-86.647334},
{x:33.445967,y:-86.827632},
{x:33.429036,y:-86.707079},
{x:33.410761,y:-86.761876},
{x:33.380452,y:-86.804758},
{x:33.384266,y:-86.739723},
{x:33.165292,y:-87.524781},
{x:33.197021,y:-87.529224},
{x:33.202969,y:-87.567622},
{x:33.197958,y:-87.479806},
{x:33.146675,y:-87.543419},
{x:33.261881,y:-87.516056},
{x:33.175727,y:-87.448211},
{x:32.625159,y:-88.206297},
{x:33.304155,y:-87.601478},
{x:33.240304,y:-87.578195},
{x:33.849139,y:-87.26902},
{x:33.79094,y:-87.242337},
{x:33.703996,y:-87.826674},
{x:34.227335,y:-87.621923},
{x:34.123882,y:-87.990296},
{x:34.437854,y:-88.136499},
{x:33.752759,y:-88.110127},
{x:33.93602,y:-87.835909},
{x:34.53617,y:-86.90812},
{x:34.589247,y:-86.980114},
{x:34.572454,y:-87.01399},
{x:34.619178,y:-87.050942},
{x:34.803721,y:-86.951807},
{x:34.782905,y:-86.937074},
{x:34.367351,y:-86.893545},
{x:34.848811,y:-87.69069},
{x:34.817026,y:-87.680785},
{x:34.820999,y:-87.655299},
{x:34.837537,y:-87.631279},
{x:34.440949,y:-86.942206},
{x:34.85655,y:-87.553783},
{x:34.485517,y:-87.274349},
{x:34.823717,y:-87.293979},
{x:34.512602,y:-87.716778},
{x:34.774173,y:-87.669782},
{x:34.74695,y:-87.667269},
{x:34.697123,y:-87.635839},
{x:34.71278,y:-87.668655},
{x:34.991565,y:-86.84056},
{x:34.84723,y:-86.712893},
{x:34.701447,y:-86.787186},
{x:34.75182,y:-86.749355},
{x:34.677215,y:-86.748785},
{x:34.858334,y:-86.571989},
{x:34.658234,y:-86.481895},
{x:34.648474,y:-86.030792},
{x:34.873561,y:-85.811901},
{x:34.720303,y:-86.584007},
{x:34.7089,y:-86.590584},
{x:34.692455,y:-86.568649},
{x:34.642936,y:-86.565927},
{x:34.757968,y:-86.5919},
{x:34.817456,y:-86.490904},
{x:34.773544,y:-86.526899},
{x:34.735478,y:-86.628711},
{x:34.744086,y:-86.674813},
{x:33.99818,y:-86.003786},
{x:34.020361,y:-86.020164},
{x:33.997605,y:-86.09301},
{x:33.967377,y:-85.943255},
{x:33.923723,y:-86.0232},
{x:34.27663,y:-86.207737},
{x:34.118741,y:-86.394984},
{x:34.018371,y:-86.076729},
{x:34.198927,y:-86.15115},
{x:34.159739,y:-85.668273},
{x:34.432931,y:-85.733468},
{x:34.340973,y:-86.308682},
{x:34.62459,y:-85.752761},
{x:34.494698,y:-85.847624},
{x:31.87712,y:-85.151222},
{x:31.84811,y:-86.638763},
{x:32.270503,y:-86.357269},
{x:31.704756,y:-86.264672},
{x:32.518002,y:-86.374089},
{x:32.461052,y:-86.391579},
{x:32.486484,y:-86.409894},
{x:32.459165,y:-86.450895},
{x:32.404224,y:-85.956367},
{x:32.538548,y:-85.899049},
{x:31.783434,y:-85.955488},
{x:32.42138,y:-85.693639},
{x:32.146833,y:-85.713147},
{x:32.519585,y:-86.210031},
{x:32.419864,y:-86.231455},
{x:32.325703,y:-86.328119},
{x:32.352007,y:-86.318726},
{x:32.325859,y:-86.23243},
{x:32.355858,y:-86.218295},
{x:32.380252,y:-86.275591},
{x:32.364482,y:-86.268218},
{x:32.369529,y:-86.345038},
{x:32.327772,y:-86.255072},
{x:32.339669,y:-86.180018},
{x:32.362699,y:-86.137648},
{x:32.382778,y:-86.200246},
{x:33.607049,y:-85.828266},
{x:33.669315,y:-85.826457},
{x:33.718806,y:-85.81872},
{x:33.628809,y:-85.786666},
{x:33.275605,y:-85.825595},
{x:33.62504,y:-85.595726},
{x:33.816242,y:-85.761979},
{x:33.932534,y:-85.612024},
{x:33.15227,y:-85.359167},
{x:31.217716,y:-85.36025},
{x:31.189304,y:-85.397898},
{x:31.247243,y:-85.425895},
{x:31.238352,y:-85.464003},
{x:31.224973,y:-85.400523},
{x:31.255348,y:-85.38899},
{x:31.270822,y:-85.449456},
{x:31.55032,y:-85.285607},
{x:31.314951,y:-85.711775},
{x:31.321508,y:-85.830248},
{x:31.047729,y:-85.891305},
{x:31.448807,y:-85.658081},
{x:31.455608,y:-86.964438},
{x:31.308273,y:-86.498833},
{x:31.100326,y:-87.07919},
{x:31.021191,y:-87.25147},
{x:31.685734,y:-87.781929},
{x:31.505349,y:-87.318006},
{x:31.106749,y:-87.477342},
{x:31.005691,y:-87.494016},
{x:30.878686,y:-87.78335},
{x:31.09478,y:-88.238686},
{x:30.649547,y:-87.911825},
{x:30.666329,y:-87.853232},
{x:30.526061,y:-87.886817},
{x:30.522224,y:-87.848923},
{x:30.373667,y:-87.653941},
{x:30.395254,y:-87.683768},
{x:30.498906,y:-88.335406},
{x:30.272843,y:-87.686504},
{x:30.428728,y:-88.245392},
{x:31.536281,y:-87.888885},
{x:30.646853,y:-87.761219},
{x:31.127191,y:-88.009123},
{x:30.290742,y:-87.581826},
{x:30.542813,y:-87.707926},
{x:30.799387,y:-88.097093},
{x:30.797236,y:-88.071457},
{x:30.87065,y:-88.047228},
{x:30.554496,y:-88.171251},
{x:30.561124,y:-88.20655},
{x:30.687731,y:-88.05015},
{x:30.607137,y:-88.075673},
{x:30.67585,y:-88.120804},
{x:30.666182,y:-88.106416},
{x:30.687996,y:-88.123877},
{x:30.701458,y:-88.114864},
{x:30.671294,y:-88.276719},
{x:30.684221,y:-88.225049},
{x:30.689128,y:-88.172019},
{x:30.670809,y:-88.149158},
{x:30.745234,y:-88.111779},
{x:30.71727,y:-88.083393},
{x:30.719893,y:-88.157627},
{x:30.760648,y:-88.224396},
{x:30.588319,y:-88.170209},
{x:30.64859,y:-88.159051},
{x:30.660835,y:-88.191121},
{x:30.640938,y:-88.224061},
{x:32.430743,y:-87.03184},
{x:32.503738,y:-87.837955},
{x:32.699038,y:-87.595798},
{x:31.925878,y:-87.739694},
{x:32.619619,y:-85.404797},
{x:32.644209,y:-85.349886},
{x:32.63788,y:-85.42185},
{x:32.624462,y:-85.445717},
{x:32.607073,y:-85.483844},
{x:32.561382,y:-85.505603},
{x:32.836484,y:-85.764137},
{x:32.469578,y:-85.02795},
{x:32.46956,y:-85.093097},
{x:32.497518,y:-85.044628},
{x:32.497722,y:-85.016818},
{x:32.84464,y:-85.181043},
{x:32.090195,y:-88.219458},
{x:36.060934,y:-86.629187},
{x:36.048729,y:-86.651974},
{x:36.273104,y:-87.062253},
{x:35.997575,y:-86.689994},
{x:36.035422,y:-86.791678},
{x:36.260838,y:-85.951869},
{x:35.834476,y:-87.433704},
{x:35.724352,y:-86.319229},
{x:36.624876,y:-87.317204},
{x:36.576725,y:-87.408928},
{x:36.514892,y:-87.300482},
{x:36.539805,y:-87.365181},
{x:36.596285,y:-87.287348},
{x:36.627779,y:-87.432944},
{x:36.523761,y:-87.225563},
{x:35.30937,y:-86.879597},
{x:36.022556,y:-87.338021},
{x:35.99179,y:-87.4883},
{x:36.076406,y:-87.375958},
{x:36.479749,y:-87.888301},
{x:35.972451,y:-87.128348},
{x:35.966174,y:-86.810918},
{x:35.925341,y:-86.874179},
{x:35.915952,y:-86.829192},
{x:36.359509,y:-86.498114},
{x:36.385619,y:-86.45516},
{x:35.945412,y:-86.82375},
{x:36.325693,y:-86.701685},
{x:36.300169,y:-86.700042},
{x:36.399679,y:-86.162067},
{x:36.321536,y:-86.623049},
{x:36.326571,y:-86.594261},
{x:36.30261,y:-86.627775},
{x:36.169258,y:-86.599811},
{x:36.215497,y:-86.593396},
{x:36.192208,y:-86.624955},
{x:35.883884,y:-87.798583},
{x:36.321687,y:-86.868133},
{x:36.5204,y:-86.035614},
{x:36.00004,y:-86.595715},
{x:36.020728,y:-86.576885},
{x:36.216338,y:-86.331644},
{x:36.187757,y:-86.295938},
{x:36.188583,y:-86.41028},
{x:35.446775,y:-86.775723},
{x:35.70247,y:-85.780284},
{x:36.251261,y:-86.718885},
{x:36.161292,y:-86.514653},
{x:36.231392,y:-86.514369},
{x:36.173358,y:-86.513918},
{x:35.80686,y:-86.396984},
{x:35.811231,y:-86.458605},
{x:35.865501,y:-86.446419},
{x:35.868596,y:-86.386139},
{x:35.847905,y:-86.429987},
{x:35.811719,y:-86.368039},
{x:35.844763,y:-86.444125},
{x:35.871699,y:-86.353194},
{x:35.909303,y:-86.384348},
{x:35.841513,y:-86.391134},
{x:36.257828,y:-86.657509},
{x:36.575838,y:-86.514752},
{x:35.48746,y:-86.442265},
{x:35.956337,y:-85.812195},
{x:35.933424,y:-86.532749},
{x:35.9748,y:-86.491961},
{x:35.992976,y:-86.527231},
{x:36.493251,y:-86.877321},
{x:36.483753,y:-86.888559},
{x:35.77677,y:-86.914705},
{x:35.744772,y:-86.939161},
{x:35.73721,y:-86.892989},
{x:36.0841,y:-87.800005},
{x:36.108582,y:-87.227905},
{x:36.469421,y:-86.677994},
{x:35.82724,y:-86.068427},
{x:36.145916,y:-86.812043},
{x:36.156145,y:-86.787588},
{x:36.120827,y:-86.774905},
{x:36.208412,y:-86.77736},
{x:36.235466,y:-86.758737},
{x:36.152409,y:-86.844063},
{x:36.138497,y:-86.882105},
{x:36.142965,y:-86.743719},
{x:36.040893,y:-86.745035},
{x:36.124782,y:-86.751526},
{x:36.086377,y:-86.694864},
{x:36.081972,y:-86.727361},
{x:36.046573,y:-86.712866},
{x:36.219859,y:-86.694515},
{x:36.170671,y:-86.680055},
{x:36.145563,y:-86.666165},
{x:36.101306,y:-86.817641},
{x:36.200644,y:-86.739419},
{x:36.121714,y:-86.705319},
{x:36.079458,y:-86.645302},
{x:36.045543,y:-86.953624},
{x:36.074784,y:-86.921428},
{x:36.1906,y:-86.799331},
{x:35.45017,y:-84.623452},
{x:35.2908,y:-84.817595},
{x:35.599205,y:-84.510459},
{x:35.189909,y:-84.880148},
{x:35.130294,y:-84.867424},
{x:35.213368,y:-84.883204},
{x:35.201147,y:-84.844862},
{x:35.475206,y:-85.02942},
{x:35.460384,y:-85.043877},
{x:35.516902,y:-84.790678},
{x:35.36216,y:-85.394264},
{x:35.33891,y:-84.520901},
{x:35.130135,y:-86.565785},
{x:35.20111,y:-85.158681},
{x:35.13879,y:-85.247152},
{x:35.03585,y:-85.582125},
{x:35.042893,y:-85.685998},
{x:35.152772,y:-84.952911},
{x:35.51117,y:-84.367407},
{x:35.459894,y:-86.057086},
{x:35.241572,y:-85.846026},
{x:35.127005,y:-84.720522},
{x:34.998877,y:-85.095465},
{x:35.084597,y:-85.062009},
{x:35.616049,y:-85.186332},
{x:35.140913,y:-85.327266},
{x:35.25335,y:-85.182283},
{x:35.692078,y:-84.859668},
{x:35.373313,y:-86.221118},
{x:35.199363,y:-86.096418},
{x:35.046745,y:-85.286977},
{x:34.991259,y:-85.28946},
{x:34.990016,y:-85.210022},
{x:35.115276,y:-85.254478},
{x:35.089798,y:-85.312555},
{x:35.084749,y:-85.19346},
{x:35.013541,y:-85.228456},
{x:35.025437,y:-85.363849},
{x:35.031686,y:-85.155316},
{x:35.030299,y:-85.184657},
{x:36.312794,y:-82.384407},
{x:36.340534,y:-82.375619},
{x:36.307756,y:-82.364317},
{x:36.3196,y:-82.345583},
{x:36.358561,y:-82.394308},
{x:36.388973,y:-82.411223},
{x:36.408376,y:-82.475265},
{x:36.534171,y:-82.389552},
{x:36.523595,y:-82.312027},
{x:36.545369,y:-82.235102},
{x:36.594896,y:-82.214112},
{x:36.590645,y:-82.261688},
{x:36.573059,y:-82.193573},
{x:36.527173,y:-82.686781},
{x:36.349239,y:-82.245385},
{x:36.350914,y:-82.221734},
{x:36.150392,y:-82.417033},
{x:36.289387,y:-82.174057},
{x:36.300734,y:-82.467166},
{x:36.505028,y:-82.553954},
{x:36.548642,y:-82.519492},
{x:36.552468,y:-82.624952},
{x:36.481284,y:-82.496518},
{x:36.525304,y:-82.520807},
{x:36.579128,y:-82.567509},
{x:36.464909,y:-81.8008},
{x:36.425601,y:-82.316175},
{x:35.81517,y:-83.980955},
{x:35.764842,y:-83.980414},
{x:36.224534,y:-83.045},
{x:36.164589,y:-84.086103},
{x:36.093971,y:-84.137192},
{x:36.040227,y:-83.442557},
{x:35.72672,y:-83.47167},
{x:35.712219,y:-83.517258},
{x:36.147469,y:-82.847765},
{x:36.170472,y:-82.819276},
{x:36.187695,y:-82.759398},
{x:36.179577,y:-82.774397},
{x:35.910903,y:-84.583132},
{x:36.334393,y:-84.177962},
{x:36.11461,y:-83.49351},
{x:36.581773,y:-84.114331},
{x:35.878121,y:-84.509851},
{x:35.986206,y:-83.606235},
{x:36.231377,y:-84.158517},
{x:35.802539,y:-84.261486},
{x:35.873002,y:-84.323289},
{x:35.730771,y:-84.390153},
{x:35.847249,y:-83.992084},
{x:35.751784,y:-83.998794},
{x:35.718157,y:-84.010815},
{x:35.802067,y:-83.943541},
{x:36.226613,y:-83.843308},
{x:36.178211,y:-83.392121},
{x:36.222465,y:-83.260063},
{x:36.206453,y:-83.323228},
{x:36.231255,y:-83.287879},
{x:36.143238,y:-83.277046},
{x:35.951206,y:-83.201046},
{x:36.004794,y:-84.251395},
{x:36.025708,y:-84.238672},
{x:36.515509,y:-84.506322},
{x:36.006643,y:-84.024559},
{x:36.00542,y:-84.026936},
{x:35.881505,y:-84.6601},
{x:36.397724,y:-83.015377},
{x:35.865085,y:-83.545666},
{x:35.865734,y:-83.568036},
{x:35.827708,y:-83.573947},
{x:35.798629,y:-83.588387},
{x:35.784438,y:-83.553372},
{x:35.870289,y:-83.755404},
{x:36.004721,y:-83.772648},
{x:36.450686,y:-83.570546},
{x:35.579325,y:-84.270433},
{x:36.108857,y:-83.333962},
{x:36.008258,y:-83.974992},
{x:35.955964,y:-83.933319},
{x:35.950546,y:-83.914622},
{x:36.000893,y:-83.925265},
{x:35.972065,y:-83.986144},
{x:36.031396,y:-83.870122},
{x:35.991068,y:-83.889623},
{x:36.031113,y:-83.929859},
{x:36.071819,y:-83.928009},
{x:35.944496,y:-84.069428},
{x:35.931447,y:-84.023464},
{x:35.947176,y:-84.159432},
{x:35.906919,y:-83.839259},
{x:35.861059,y:-84.068175},
{x:35.903237,y:-84.14212},
{x:35.910057,y:-84.099882},
{x:35.921112,y:-84.09191},
{x:35.925047,y:-84.065488},
{x:35.990007,y:-84.075797},
{x:35.88456,y:-84.154806},
{x:36.050083,y:-83.998971},
{x:35.206307,y:-89.731713},
{x:35.293786,y:-89.676154},
{x:35.449507,y:-89.783157},
{x:35.744402,y:-89.098054},
{x:35.256729,y:-88.99797},
{x:35.54057,y:-89.247412},
{x:35.048403,y:-89.733124},
{x:35.046564,y:-89.690743},
{x:35.158248,y:-89.726924},
{x:35.171897,y:-89.793567},
{x:35.140809,y:-89.798239},
{x:35.143282,y:-89.794706},
{x:35.5773,y:-89.644094},
{x:36.061347,y:-89.394863},
{x:35.340506,y:-89.911593},
{x:36.122125,y:-89.276345},
{x:35.228572,y:-89.515651},
{x:35.761877,y:-89.544862},
{x:35.243267,y:-89.351709},
{x:35.137951,y:-90.031524},
{x:35.143467,y:-90.014186},
{x:35.134062,y:-89.992695},
{x:35.096647,y:-90.056318},
{x:35.063701,y:-90.058735},
{x:35.133305,y:-89.961969},
{x:35.114704,y:-89.945605},
{x:35.083925,y:-89.931709},
{x:35.105232,y:-89.984598},
{x:35.050288,y:-89.861167},
{x:35.049522,y:-89.885764},
{x:35.037385,y:-90.024728},
{x:35.020536,y:-90.007943},
{x:35.004862,y:-90.027738},
{x:35.054124,y:-90.00856},
{x:35.091041,y:-89.90719},
{x:35.020224,y:-89.935656},
{x:35.064907,y:-89.905201},
{x:35.051927,y:-89.950741},
{x:35.107605,y:-89.88591},
{x:35.09539,y:-89.859168},
{x:35.165064,y:-89.953162},
{x:35.149388,y:-89.920304},
{x:35.151328,y:-89.891472},
{x:35.048562,y:-89.826692},
{x:35.044608,y:-89.796511},
{x:35.019974,y:-89.829416},
{x:35.220827,y:-89.97417},
{x:35.21154,y:-90.032672},
{x:35.217676,y:-89.913513},
{x:35.185351,y:-89.895877},
{x:35.205219,y:-89.793856},
{x:35.17889,y:-89.836807},
{x:35.169445,y:-89.864897},
{x:35.205257,y:-89.861745},
{x:35.098887,y:-89.840754},
{x:35.093484,y:-89.80834},
{x:36.129351,y:-88.508071},
{x:36.278166,y:-88.712702},
{x:36.348437,y:-88.873455},
{x:36.289822,y:-88.313584},
{x:36.414702,y:-89.071025},
{x:35.563458,y:-88.814752},
{x:35.689364,y:-88.856692},
{x:35.674037,y:-88.781869},
{x:35.65998,y:-88.85259},
{x:35.652982,y:-88.829128},
{x:36.057895,y:-88.115044},
{x:35.441098,y:-88.631708},
{x:35.836686,y:-88.915592},
{x:35.996094,y:-88.439387},
{x:35.657769,y:-88.414417},
{x:35.79441,y:-88.788838},
{x:35.905789,y:-88.751779},
{x:35.654845,y:-88.126236},
{x:35.224164,y:-88.246087},
{x:35.156281,y:-88.579338},
{x:35.967252,y:-88.931136},
{x:35.790532,y:-88.391086},
{x:35.643677,y:-86.897777},
{x:35.635171,y:-87.016889},
{x:35.605713,y:-87.065261},
{x:35.551104,y:-87.541535},
{x:35.255701,y:-87.324948},
{x:35.547058,y:-87.199362},
{x:35.2006,y:-87.042275},
{x:35.323743,y:-87.758968},
{x:36.154155,y:-85.515202},
{x:36.137562,y:-85.502536},
{x:36.182315,y:-85.460428},
{x:36.139849,y:-85.63029},
{x:35.962796,y:-85.037469},
{x:35.963939,y:-84.980937},
{x:36.432242,y:-84.933748},
{x:36.180251,y:-85.947097},
{x:36.379146,y:-85.333717},
{x:35.944403,y:-85.484912},
{x:34.351707,y:-89.915741},
{x:34.310868,y:-89.943294},
{x:34.189921,y:-90.575533},
{x:34.824404,y:-89.982223},
{x:34.750544,y:-89.452225},
{x:34.963064,y:-90.017816},
{x:34.259166,y:-90.274197},
{x:34.499483,y:-89.027515},
{x:34.961612,y:-89.856911},
{x:34.964901,y:-89.7948},
{x:34.364863,y:-89.555496},
{x:34.362188,y:-89.50903},
{x:34.730709,y:-88.94583},
{x:34.436717,y:-89.896382},
{x:34.623659,y:-89.953447},
{x:34.96221,y:-89.991321},
{x:34.933003,y:-89.998754},
{x:34.959583,y:-89.936119},
{x:34.689865,y:-90.374923},
{x:33.399235,y:-91.036601},
{x:33.373227,y:-91.040513},
{x:33.4006,y:-91.007053},
{x:33.757154,y:-90.712043},
{x:33.742626,y:-90.716397},
{x:33.45842,y:-90.637435},
{x:33.402453,y:-90.88147},
{x:34.316336,y:-88.791474},
{x:34.248697,y:-88.71721},
{x:34.306777,y:-88.707735},
{x:34.25513,y:-88.745742},
{x:34.236259,y:-88.703064},
{x:33.980329,y:-88.478259},
{x:34.524781,y:-88.653583},
{x:34.670124,y:-88.56399},
{x:34.922388,y:-88.516385},
{x:34.247378,y:-88.403153},
{x:33.899115,y:-88.999692},
{x:34.799106,y:-88.206416},
{x:34.252622,y:-89.018642},
{x:34.371207,y:-88.701321},
{x:33.776097,y:-89.825168},
{x:34.006545,y:-90.047838},
{x:33.527349,y:-90.213301},
{x:33.509714,y:-90.159528},
{x:33.487402,y:-89.757481},
{x:33.178541,y:-90.496365},
{x:32.281587,y:-90.037032},
{x:32.382416,y:-90.042036},
{x:32.606884,y:-90.065445},
{x:32.731248,y:-89.540236},
{x:32.329231,y:-90.326508},
{x:32.012644,y:-90.355403},
{x:32.367929,y:-89.479752},
{x:31.875467,y:-90.403058},
{x:33.066334,y:-89.591502},
{x:32.462058,y:-90.110475},
{x:31.867699,y:-89.738231},
{x:32.318123,y:-89.665565},
{x:31.564552,y:-91.351121},
{x:31.528023,y:-91.389005},
{x:31.971991,y:-90.97726},
{x:32.41812,y:-90.136803},
{x:32.399995,y:-90.12228},
{x:32.347204,y:-90.862483},
{x:32.304569,y:-90.890706},
{x:32.325428,y:-90.865209},
{x:32.863569,y:-90.394298},
{x:32.31249,y:-90.177428},
{x:32.27323,y:-90.192237},
{x:32.291364,y:-90.229131},
{x:32.385876,y:-90.148185},
{x:32.355976,y:-90.180344},
{x:32.26782,y:-90.135477},
{x:32.327877,y:-90.250905},
{x:32.286201,y:-90.27561},
{x:32.267732,y:-90.218159},
{x:32.326348,y:-90.193931},
{x:32.333643,y:-90.142177},
{x:32.328315,y:-90.172912},
{x:32.214847,y:-90.151814},
{x:32.341479,y:-90.06154},
{x:32.186698,y:-90.260784},
{x:32.354239,y:-88.683035},
{x:32.365785,y:-88.668384},
{x:32.356446,y:-88.687476},
{x:32.36388,y:-88.728043},
{x:32.409834,y:-88.700857},
{x:33.124854,y:-89.084399},
{x:33.142136,y:-88.551712},
{x:32.338156,y:-89.138797},
{x:32.758211,y:-89.148288},
{x:32.033944,y:-88.727571},
{x:31.677197,y:-88.64363},
{x:31.325127,y:-89.336841},
{x:31.311629,y:-89.312345},
{x:31.350971,y:-89.340659},
{x:31.322843,y:-89.363108},
{x:31.316675,y:-89.41018},
{x:31.6499,y:-89.570579},
{x:31.242806,y:-89.810983},
{x:31.608987,y:-89.202563},
{x:31.710288,y:-89.149426},
{x:31.688396,y:-89.135887},
{x:30.910263,y:-88.598777},
{x:31.350925,y:-89.243832},
{x:30.51825,y:-89.660432},
{x:30.546363,y:-89.669737},
{x:30.815209,y:-89.515094},
{x:31.145031,y:-89.404734},
{x:30.371577,y:-89.093451},
{x:30.419236,y:-89.19012},
{x:30.419957,y:-89.135838},
{x:30.453183,y:-89.100541},
{x:30.446911,y:-89.03213},
{x:30.426635,y:-89.091922},
{x:30.400046,y:-89.026359},
{x:30.391094,y:-89.043744},
{x:30.393211,y:-88.885336},
{x:30.389612,y:-88.992058},
{x:30.446323,y:-88.933708},
{x:30.44077,y:-88.897597},
{x:30.391638,y:-88.653937},
{x:30.347028,y:-89.143841},
{x:30.438864,y:-88.526609},
{x:30.449638,y:-88.841944},
{x:30.409505,y:-88.759071},
{x:30.416634,y:-88.821389},
{x:30.364649,y:-88.54798},
{x:30.306281,y:-89.384971},
{x:30.307535,y:-89.376126},
{x:30.851181,y:-89.152417},
{x:30.377997,y:-88.528051},
{x:31.579453,y:-90.470432},
{x:31.261021,y:-90.475358},
{x:31.190825,y:-90.485838},
{x:31.24424,y:-90.454413},
{x:31.554306,y:-90.108781},
{x:31.131113,y:-90.146047},
{x:33.517243,y:-88.434176},
{x:33.493815,y:-88.366135},
{x:33.829005,y:-88.561967},
{x:33.528269,y:-89.260041},
{x:33.447026,y:-88.841104},
{x:33.454891,y:-88.806619},
{x:33.605924,y:-88.659553},
{x:31.375067,y:-84.936573},
{x:31.768655,y:-84.447428},
{x:37.805831,y:-85.452042},
{x:38.329716,y:-85.465187},
{x:38.370342,y:-85.17952},
{x:38.400978,y:-85.376091},
{x:37.565523,y:-85.262578},
{x:38.051897,y:-85.538379},
{x:38.45751,y:-85.299392},
{x:38.339158,y:-85.620578},
{x:38.210014,y:-85.204562},
{x:38.201702,y:-85.268046},
{x:38.206092,y:-85.348744},
{x:37.691731,y:-85.233182},
{x:38.040062,y:-85.341049},
{x:38.151414,y:-85.069615},
{x:37.980781,y:-86.163307},
{x:37.762711,y:-86.441391},
{x:37.827319,y:-85.723472},
{x:37.849121,y:-85.941963},
{x:37.796888,y:-85.915116},
{x:37.839986,y:-85.937466},
{x:37.990848,y:-85.708162},
{x:38.065231,y:-85.693976},
{x:38.253542,y:-85.74525},
{x:38.246243,y:-85.755441},
{x:38.267331,y:-85.783496},
{x:38.247196,y:-85.772104},
{x:38.236689,y:-85.717166},
{x:38.223477,y:-85.684679},
{x:38.25811,y:-85.702759},
{x:38.258547,y:-85.639393},
{x:38.230934,y:-85.640607},
{x:38.250605,y:-85.621567},
{x:38.248955,y:-85.797974},
{x:38.197822,y:-85.732251},
{x:38.157593,y:-85.708375},
{x:38.174383,y:-85.766998},
{x:38.144809,y:-85.7772},
{x:38.200438,y:-85.783017},
{x:38.177351,y:-85.821008},
{x:38.195419,y:-85.841691},
{x:38.214444,y:-85.796634},
{x:38.21499,y:-85.754754},
{x:38.211422,y:-85.720918},
{x:38.206423,y:-85.667963},
{x:38.168409,y:-85.706865},
{x:38.136342,y:-85.712012},
{x:38.132909,y:-85.68425},
{x:38.186317,y:-85.63765},
{x:38.211618,y:-85.597549},
{x:38.219061,y:-85.628789},
{x:38.228318,y:-85.578271},
{x:38.282809,y:-85.62899},
{x:38.264042,y:-85.568377},
{x:38.158021,y:-85.660637},
{x:38.083325,y:-85.6684},
{x:38.285577,y:-85.578618},
{x:38.246836,y:-85.526312},
{x:38.290231,y:-85.511031},
{x:38.152515,y:-85.835601},
{x:38.144269,y:-85.871425},
{x:38.127988,y:-85.850455},
{x:38.103304,y:-85.868934},
{x:38.163287,y:-85.597376},
{x:38.188278,y:-85.516608},
{x:38.188963,y:-85.565686},
{x:38.216539,y:-85.539648},
{x:38.183698,y:-84.556205},
{x:38.276483,y:-84.559902},
{x:38.227479,y:-84.536083},
{x:37.751547,y:-84.844481},
{x:37.697001,y:-83.987632},
{x:38.068446,y:-84.918734},
{x:38.020217,y:-84.908318},
{x:38.157878,y:-84.676491},
{x:38.180363,y:-83.443943},
{x:38.18952,y:-83.476554},
{x:38.086025,y:-83.898922},
{x:38.074737,y:-83.949294},
{x:37.872153,y:-84.598161},
{x:37.95615,y:-84.538006},
{x:37.89751,y:-84.566505},
{x:38.527657,y:-84.831379},
{x:38.129141,y:-83.75325},
{x:38.192412,y:-84.27707},
{x:37.839817,y:-83.858025},
{x:38.052961,y:-84.715381},
{x:38.007276,y:-84.212367},
{x:37.567375,y:-84.311936},
{x:37.585491,y:-84.284532},
{x:37.647858,y:-84.803893},
{x:37.617134,y:-84.771295},
{x:37.62745,y:-84.579704},
{x:37.367225,y:-84.337123},
{x:37.776133,y:-84.320348},
{x:37.736914,y:-84.310946},
{x:37.731903,y:-84.276096},
{x:37.541516,y:-84.655103},
{x:38.016222,y:-84.461471},
{x:38.025645,y:-84.535563},
{x:38.001188,y:-84.520717},
{x:38.04736,y:-84.553138},
{x:38.034103,y:-84.454231},
{x:38.042555,y:-84.424088},
{x:37.96948,y:-84.500037},
{x:38.042378,y:-84.503094},
{x:38.040775,y:-84.476204},
{x:37.962316,y:-84.386907},
{x:38.01953,y:-84.415427},
{x:38.066943,y:-84.526651},
{x:38.070831,y:-84.477857},
{x:38.101612,y:-84.484445},
{x:38.005473,y:-84.572955},
{x:37.97749,y:-84.5301},
{x:37.985466,y:-84.460166},
{x:38.19804,y:-84.830089},
{x:38.170336,y:-84.898072},
{x:36.958015,y:-84.074357},
{x:36.975243,y:-84.107833},
{x:36.917473,y:-84.127853},
{x:37.139065,y:-84.097713},
{x:37.148478,y:-84.11406},
{x:37.10656,y:-84.072743},
{x:36.726024,y:-84.166156},
{x:36.817244,y:-83.316491},
{x:36.857727,y:-83.870406},
{x:37.139483,y:-83.768906},
{x:36.604739,y:-83.740488},
{x:36.612996,y:-83.703956},
{x:36.76434,y:-83.704607},
{x:38.9866,y:-84.402857},
{x:38.65089,y:-85.125071},
{x:39.08731,y:-84.519286},
{x:39.016934,y:-84.509332},
{x:39.045608,y:-84.504891},
{x:39.047684,y:-84.57697},
{x:39.024866,y:-84.535289},
{x:39.021708,y:-84.588579},
{x:38.786688,y:-84.604725},
{x:38.379594,y:-84.308638},
{x:38.683255,y:-84.600204},
{x:38.677761,y:-84.344023},
{x:38.420759,y:-83.754148},
{x:38.974555,y:-84.661386},
{x:39.015291,y:-84.674893},
{x:38.988276,y:-84.641766},
{x:39.004596,y:-84.621169},
{x:39.055409,y:-84.656274},
{x:39.066677,y:-84.7024},
{x:38.928885,y:-84.545327},
{x:38.643056,y:-83.778827},
{x:39.078608,y:-84.480626},
{x:39.097909,y:-84.4829},
{x:39.010487,y:-84.469424},
{x:38.693575,y:-84.938684},
{x:38.858595,y:-84.618628},
{x:38.916417,y:-84.63366},
{x:38.485597,y:-82.658182},
{x:38.435885,y:-82.691563},
{x:38.495628,y:-82.672361},
{x:38.472071,y:-82.627199},
{x:38.371836,y:-82.704238},
{x:38.402533,y:-82.601424},
{x:38.520125,y:-82.720626},
{x:38.340996,y:-82.943618},
{x:38.567736,y:-82.827589},
{x:38.304244,y:-83.161726},
{x:38.088042,y:-83.123893},
{x:38.59181,y:-83.3266},
{x:37.85441,y:-82.515235},
{x:38.125412,y:-82.627443},
{x:37.822147,y:-82.817703},
{x:37.823209,y:-82.825418},
{x:37.561352,y:-83.39865},
{x:37.7409,y:-83.047308},
{x:37.898252,y:-83.28398},
{x:37.504838,y:-82.535382},
{x:37.473133,y:-82.528176},
{x:37.517727,y:-82.566103},
{x:37.656102,y:-82.272906},
{x:37.562219,y:-82.762578},
{x:37.682066,y:-82.778051},
{x:37.656252,y:-82.783222},
{x:37.26112,y:-83.199841},
{x:37.288021,y:-83.210242},
{x:37.11064,y:-82.803947},
{x:37.080189,y:-88.677412},
{x:37.04913,y:-88.563807},
{x:37.045892,y:-88.655299},
{x:37.069073,y:-88.626101},
{x:36.925375,y:-88.342489},
{x:36.86502,y:-88.35576},
{x:37.003834,y:-88.328317},
{x:37.086754,y:-88.088867},
{x:36.513225,y:-88.890356},
{x:37.334166,y:-88.081555},
{x:36.728785,y:-88.63135},
{x:36.611615,y:-88.315739},
{x:36.942049,y:-86.420825},
{x:36.960559,y:-86.468417},
{x:37.007431,y:-86.375558},
{x:36.986792,y:-86.483954},
{x:36.978623,y:-86.469291},
{x:36.990165,y:-86.427954},
{x:36.9515,y:-86.438611},
{x:37.134544,y:-85.971374},
{x:36.98825,y:-85.638988},
{x:36.668593,y:-86.5586},
{x:36.734919,y:-86.577578},
{x:37.008219,y:-85.922157},
{x:37.020929,y:-85.937706},
{x:36.75124,y:-86.19313},
{x:36.703323,y:-85.689957},
{x:37.043333,y:-86.21043},
{x:36.880614,y:-87.738229},
{x:36.844338,y:-87.483934},
{x:36.871568,y:-87.49853},
{x:37.21215,y:-86.694926},
{x:36.678329,y:-87.404966},
{x:36.696497,y:-87.452488},
{x:36.851238,y:-86.89121},
{x:37.756941,y:-87.146638},
{x:37.748945,y:-87.11389},
{x:37.72371,y:-87.124509},
{x:37.779596,y:-87.082532},
{x:37.752284,y:-87.058213},
{x:37.433727,y:-86.885907},
{x:37.289507,y:-87.119365},
{x:37.227137,y:-87.167474},
{x:37.866313,y:-87.573385},
{x:37.835568,y:-87.590773},
{x:37.327533,y:-87.479996},
{x:37.348276,y:-87.497624},
{x:37.687502,y:-87.913749},
{x:37.113403,y:-87.900087},
{x:37.411179,y:-87.734261},
{x:37.092227,y:-84.618718},
{x:37.037339,y:-84.625919},
{x:37.323941,y:-84.92509},
{x:36.696596,y:-85.135748},
{x:36.849017,y:-84.835685},
{x:37.062248,y:-85.071399},
{x:36.740518,y:-84.472804},
{x:37.595544,y:-85.864878},
{x:37.732621,y:-85.882376},
{x:37.71388,y:-85.830806},
{x:37.701751,y:-85.867769},
{x:37.731374,y:-85.886322},
{x:37.344669,y:-85.34002},
{x:37.672694,y:-85.911038},
{x:37.093214,y:-85.304109},
{x:37.273235,y:-85.495644},
{x:37.55251,y:-85.734864},
{x:37.201323,y:-85.935417},
{x:37.484604,y:-86.265583},
{x:37.468843,y:-86.293906},
{x:37.28891,y:-85.903085},
{x:40.306794,y:-83.090714},
{x:40.289979,y:-83.067983},
{x:40.329905,y:-83.073503},
{x:40.076961,y:-83.130091},
{x:40.103746,y:-83.159305},
{x:40.098692,y:-83.123598},
{x:39.942605,y:-82.480794},
{x:39.968865,y:-82.48709},
{x:40.033034,y:-83.130978},
{x:40.026783,y:-83.161272},
{x:40.147062,y:-82.700835},
{x:40.162271,y:-83.01837},
{x:40.260171,y:-83.374463},
{x:40.236856,y:-83.350946},
{x:40.384198,y:-82.485628},
{x:40.401967,y:-82.450197},
{x:40.406079,y:-82.492595},
{x:40.094208,y:-82.823769},
{x:40.0842,y:-82.428273},
{x:40.058611,y:-82.445746},
{x:40.05333,y:-82.419366},
{x:40.031312,y:-82.443917},
{x:39.951529,y:-82.683956},
{x:40.002411,y:-82.676283},
{x:40.109673,y:-83.278729},
{x:40.15516,y:-83.091832},
{x:39.95436,y:-82.827773},
{x:39.937757,y:-82.791112},
{x:39.927769,y:-82.828023},
{x:40.248524,y:-82.868047},
{x:40.266098,y:-82.930378},
{x:40.107626,y:-83.737203},
{x:40.123767,y:-82.947781},
{x:40.10863,y:-82.924585},
{x:40.082845,y:-82.896563},
{x:40.149526,y:-82.922825},
{x:40.108398,y:-83.015796},
{x:40.118693,y:-82.988414},
{x:39.714952,y:-82.986466},
{x:39.854908,y:-82.828878},
{x:39.845738,y:-82.78044},
{x:39.586668,y:-82.951991},
{x:39.839588,y:-83.0755},
{x:39.89507,y:-83.081927},
{x:39.877661,y:-83.056185},
{x:39.897739,y:-82.881617},
{x:39.619021,y:-83.605205},
{x:39.729349,y:-82.61286},
{x:39.714487,y:-82.573664},
{x:39.540588,y:-82.438884},
{x:39.887058,y:-83.44817},
{x:39.951766,y:-83.379158},
{x:39.909428,y:-82.778923},
{x:39.523906,y:-83.458729},
{x:39.544978,y:-83.426238},
{x:39.944786,y:-83.260986},
{x:40.002656,y:-83.008253},
{x:40.018992,y:-83.024897},
{x:40.025344,y:-83.014642},
{x:39.972329,y:-83.098989},
{x:39.941676,y:-82.996197},
{x:39.914767,y:-82.966873},
{x:39.868551,y:-82.935593},
{x:39.888486,y:-82.997329},
{x:39.946425,y:-82.917002},
{x:39.947603,y:-82.944629},
{x:40.000528,y:-82.985715},
{x:39.988588,y:-83.030578},
{x:39.982741,y:-82.818155},
{x:39.973209,y:-82.906292},
{x:40.06353,y:-83.039496},
{x:39.956879,y:-82.989191},
{x:40.053817,y:-82.919496},
{x:40.000039,y:-82.930743},
{x:39.999196,y:-82.894131},
{x:40.065438,y:-83.091971},
{x:40.05483,y:-83.070314},
{x:40.029835,y:-83.091415},
{x:39.956552,y:-83.042401},
{x:39.945145,y:-83.036758},
{x:39.930398,y:-83.051564},
{x:40.037166,y:-82.963086},
{x:39.919948,y:-83.125838},
{x:39.952002,y:-83.124215},
{x:39.954292,y:-83.098026},
{x:39.935733,y:-83.113212},
{x:39.981705,y:-83.15174},
{x:40.087094,y:-82.997093},
{x:40.085934,y:-82.961429},
{x:40.059864,y:-82.974899},
{x:40.010647,y:-82.866626},
{x:40.056203,y:-82.863003},
{x:40.018981,y:-82.889875},
{x:40.100244,y:-82.950425},
{x:39.925161,y:-82.878906},
{x:40.094952,y:-83.086823},
{x:40.111609,y:-83.089898},
{x:39.955803,y:-82.874078},
{x:40.14149,y:-82.966085},
{x:40.148077,y:-82.988719},
{x:40.562538,y:-83.119998},
{x:40.596491,y:-83.129911},
{x:40.581598,y:-83.086682},
{x:40.377944,y:-83.756158},
{x:40.33278,y:-83.762042},
{x:40.942082,y:-83.395102},
{x:40.651664,y:-83.590926},
{x:40.493087,y:-82.711689},
{x:40.548377,y:-82.845271},
{x:40.469883,y:-83.889527},
{x:40.834383,y:-83.244344},
{x:41.374171,y:-83.630182},
{x:41.357881,y:-83.651012},
{x:41.314652,y:-82.990681},
{x:41.355255,y:-83.134039},
{x:41.373499,y:-83.117968},
{x:41.53307,y:-83.360733},
{x:41.507947,y:-83.144071},
{x:41.513932,y:-82.918963},
{x:41.524579,y:-82.859486},
{x:41.587281,y:-83.567076},
{x:41.455474,y:-83.374077},
{x:41.511447,y:-84.305851},
{x:41.460768,y:-84.550434},
{x:41.282992,y:-84.334071},
{x:41.30104,y:-84.360107},
{x:41.294482,y:-84.759662},
{x:41.611826,y:-83.69836},
{x:41.581508,y:-83.686432},
{x:41.56862,y:-83.656489},
{x:41.586039,y:-84.593689},
{x:41.40381,y:-84.132454},
{x:41.544708,y:-83.591495},
{x:41.538263,y:-83.63428},
{x:41.522693,y:-83.462233},
{x:41.580787,y:-83.880885},
{x:41.716115,y:-83.682943},
{x:41.522551,y:-83.716842},
{x:41.570717,y:-84.134994},
{x:41.652539,y:-83.555418},
{x:41.648567,y:-83.524197},
{x:41.665254,y:-83.57058},
{x:41.678694,y:-83.622177},
{x:41.686868,y:-83.53253},
{x:41.663977,y:-83.537227},
{x:41.631213,y:-83.556675},
{x:41.716852,y:-83.478502},
{x:41.692222,y:-83.570006},
{x:41.72254,y:-83.509191},
{x:41.721282,y:-83.562519},
{x:41.706475,y:-83.586811},
{x:41.614022,y:-83.62525},
{x:41.601028,y:-83.665326},
{x:41.675065,y:-83.68337},
{x:41.639658,y:-83.663943},
{x:41.636136,y:-83.479021},
{x:41.673163,y:-83.714879},
{x:41.615731,y:-83.485817},
{x:41.698168,y:-83.644272},
{x:39.972624,y:-82.011756},
{x:39.915191,y:-82.013223},
{x:39.949988,y:-82.048725},
{x:39.966354,y:-81.896523},
{x:39.990011,y:-81.15956},
{x:40.061408,y:-81.05422},
{x:39.969887,y:-81.535218},
{x:39.738516,y:-81.527456},
{x:40.004703,y:-81.576517},
{x:40.029194,y:-81.565575},
{x:39.658424,y:-81.863936},
{x:39.721053,y:-82.204798},
{x:39.76465,y:-81.115101},
{x:40.274967,y:-81.870064},
{x:40.123716,y:-82.020834},
{x:40.28574,y:-81.565056},
{x:40.011572,y:-80.745142},
{x:40.283763,y:-81.000921},
{x:40.673792,y:-80.577168},
{x:40.096201,y:-80.722706},
{x:40.07015,y:-80.865244},
{x:40.36928,y:-80.637699},
{x:40.370811,y:-80.686774},
{x:40.450998,y:-80.614477},
{x:40.613582,y:-80.641264},
{x:41.411896,y:-82.210144},
{x:41.606837,y:-80.565449},
{x:41.891828,y:-80.805092},
{x:41.875388,y:-80.763995},
{x:41.784728,y:-80.855533},
{x:41.465961,y:-82.059124},
{x:41.492542,y:-82.020088},
{x:41.372551,y:-81.866821},
{x:41.389851,y:-81.340103},
{x:41.581486,y:-81.211807},
{x:41.521633,y:-81.344467},
{x:41.913754,y:-80.570626},
{x:41.944279,y:-80.557838},
{x:41.399099,y:-82.107713},
{x:41.367987,y:-82.110006},
{x:41.425217,y:-82.077965},
{x:41.36475,y:-82.077454},
{x:41.384985,y:-81.978008},
{x:41.390815,y:-82.018039},
{x:41.772713,y:-80.947365},
{x:41.286639,y:-82.06398},
{x:41.740675,y:-80.769072},
{x:41.246041,y:-82.119156},
{x:41.464538,y:-82.156161},
{x:41.440908,y:-82.184834},
{x:41.413408,y:-82.165433},
{x:41.761819,y:-81.046781},
{x:41.802017,y:-81.051178},
{x:41.714342,y:-81.300349},
{x:41.705883,y:-81.360718},
{x:41.661076,y:-81.378952},
{x:41.673321,y:-81.326141},
{x:41.642408,y:-81.375899},
{x:41.46233,y:-81.086067},
{x:41.305887,y:-81.517858},
{x:41.341304,y:-81.528729},
{x:41.41548,y:-81.924893},
{x:41.284558,y:-82.216971},
{x:41.665637,y:-81.245202},
{x:41.699275,y:-81.278571},
{x:41.724663,y:-81.248024},
{x:41.311866,y:-81.447557},
{x:41.424315,y:-82.35112},
{x:41.181545,y:-82.216415},
{x:41.585033,y:-81.479156},
{x:41.627179,y:-81.428098},
{x:41.642861,y:-81.470061},
{x:41.677237,y:-81.427479},
{x:41.482754,y:-81.733696},
{x:41.503125,y:-81.630056},
{x:41.516754,y:-81.652481},
{x:41.480986,y:-81.604733},
{x:41.4727,y:-81.622652},
{x:41.462616,y:-81.646571},
{x:41.48505,y:-81.811733},
{x:41.482624,y:-81.769348},
{x:41.538932,y:-81.61616},
{x:41.447257,y:-81.712856},
{x:41.469532,y:-81.704819},
{x:41.451012,y:-81.702393},
{x:41.552813,y:-81.575886},
{x:41.464942,y:-81.760977},
{x:41.451523,y:-81.801926},
{x:41.499584,y:-81.665398},
{x:41.459167,y:-81.860199},
{x:41.573032,y:-81.52565},
{x:41.499092,y:-81.536804},
{x:41.525505,y:-81.587852},
{x:41.51941,y:-81.517962},
{x:41.461957,y:-81.484391},
{x:41.520552,y:-81.455373},
{x:41.399799,y:-81.597216},
{x:41.41906,y:-81.608131},
{x:41.439224,y:-81.869367},
{x:41.423869,y:-81.497566},
{x:41.446529,y:-81.564323},
{x:41.415995,y:-81.730639},
{x:41.379772,y:-81.736206},
{x:41.385994,y:-81.768163},
{x:41.368435,y:-81.803207},
{x:41.419002,y:-81.758967},
{x:41.372478,y:-81.827133},
{x:41.351181,y:-81.758504},
{x:41.393983,y:-81.640412},
{x:41.31448,y:-81.722771},
{x:41.405316,y:-81.697932},
{x:41.362053,y:-81.687325},
{x:41.433301,y:-81.793331},
{x:41.34264,y:-81.827854},
{x:41.308865,y:-81.834641},
{x:41.417506,y:-81.572029},
{x:41.421318,y:-81.527678},
{x:41.403654,y:-81.799744},
{x:41.552032,y:-81.496414},
{x:41.440218,y:-81.724975},
{x:41.426374,y:-81.734882},
{x:41.463791,y:-81.953911},
{x:41.47052,y:-81.903938},
{x:41.367693,y:-81.5103},
{x:41.314579,y:-81.653684},
{x:41.316841,y:-81.35231},
{x:41.3527,y:-81.385837},
{x:41.012411,y:-81.608115},
{x:41.026302,y:-81.639717},
{x:41.228346,y:-81.840861},
{x:41.23971,y:-81.802854},
{x:40.999701,y:-81.997038},
{x:41.131108,y:-81.507729},
{x:41.155919,y:-81.405046},
{x:41.180749,y:-81.483864},
{x:41.202162,y:-81.441079},
{x:41.162668,y:-81.475782},
{x:41.286587,y:-81.100402},
{x:41.239747,y:-81.449036},
{x:41.242161,y:-81.354015},
{x:41.142417,y:-81.358213},
{x:41.104838,y:-81.347732},
{x:41.281014,y:-81.22476},
{x:41.244503,y:-81.17604},
{x:41.242331,y:-81.177761},
{x:41.135705,y:-81.801145},
{x:41.114084,y:-81.863568},
{x:41.157545,y:-81.863081},
{x:41.050612,y:-81.395036},
{x:41.139664,y:-81.43968},
{x:41.157428,y:-81.218483},
{x:41.158241,y:-81.247993},
{x:41.110802,y:-81.241642},
{x:41.030331,y:-81.908143},
{x:41.100604,y:-81.439106},
{x:41.048042,y:-81.729324},
{x:41.221127,y:-81.629179},
{x:41.090289,y:-81.529845},
{x:41.07304,y:-81.514207},
{x:41.077661,y:-81.499643},
{x:41.061232,y:-81.454996},
{x:41.029162,y:-81.492869},
{x:41.118591,y:-81.477213},
{x:41.102449,y:-81.46595},
{x:41.062011,y:-81.518777},
{x:41.031895,y:-81.428758},
{x:40.983677,y:-81.492451},
{x:41.136425,y:-81.638749},
{x:41.134821,y:-81.554446},
{x:41.108227,y:-81.568793},
{x:41.010811,y:-81.553752},
{x:40.962365,y:-81.565394},
{x:41.064934,y:-81.570962},
{x:41.084147,y:-81.581367},
{x:41.025315,y:-80.743486},
{x:40.899695,y:-80.694533},
{x:41.317119,y:-80.732723},
{x:41.27685,y:-80.774767},
{x:40.831962,y:-80.539175},
{x:41.178271,y:-80.569229},
{x:40.78785,y:-80.775049},
{x:41.189202,y:-80.977819},
{x:41.142587,y:-80.72228},
{x:41.181788,y:-80.718408},
{x:41.186005,y:-80.765622},
{x:40.901315,y:-80.833841},
{x:41.23474,y:-80.810125},
{x:41.277012,y:-80.842896},
{x:41.214338,y:-80.741024},
{x:41.23626,y:-80.766122},
{x:41.251414,y:-80.855424},
{x:41.109114,y:-80.651647},
{x:41.148888,y:-80.665927},
{x:41.090013,y:-80.590319},
{x:41.083051,y:-80.731905},
{x:40.988419,y:-80.635156},
{x:41.024967,y:-80.664116},
{x:41.060423,y:-80.643928},
{x:41.024658,y:-80.631924},
{x:40.959019,y:-80.660503},
{x:41.128617,y:-80.769325},
{x:41.099909,y:-80.764939},
{x:40.901441,y:-81.155091},
{x:40.901246,y:-81.095699},
{x:40.641419,y:-81.444161},
{x:40.881961,y:-81.572734},
{x:40.582409,y:-81.091522},
{x:40.528122,y:-81.491698},
{x:40.967297,y:-81.345184},
{x:40.838492,y:-81.260039},
{x:40.861554,y:-81.484587},
{x:40.748154,y:-81.517588},
{x:40.796154,y:-81.525852},
{x:40.798234,y:-81.488641},
{x:40.5345,y:-81.917511},
{x:40.731873,y:-81.097477},
{x:40.509836,y:-81.452278},
{x:40.847311,y:-81.764904},
{x:40.592007,y:-81.525118},
{x:40.508342,y:-81.626756},
{x:40.410881,y:-81.348817},
{x:40.961568,y:-81.466023},
{x:40.675718,y:-81.260497},
{x:40.804581,y:-81.934937},
{x:40.796109,y:-81.906037},
{x:40.847317,y:-81.94427},
{x:40.799557,y:-81.393423},
{x:40.798319,y:-81.372263},
{x:40.818162,y:-81.329029},
{x:40.837894,y:-81.326638},
{x:40.751398,y:-81.403398},
{x:40.781363,y:-81.418438},
{x:40.789657,y:-81.320638},
{x:40.7637,y:-81.381656},
{x:40.795463,y:-81.435735},
{x:40.833107,y:-81.388836},
{x:40.856819,y:-81.435107},
{x:40.8373,y:-81.424432},
{x:40.891072,y:-81.406796},
{x:40.880226,y:-81.434887},
{x:40.873172,y:-81.36319},
{x:40.858734,y:-82.266933},
{x:40.85479,y:-82.336449},
{x:41.269827,y:-82.820885},
{x:40.648635,y:-82.542652},
{x:40.787962,y:-82.986452},
{x:40.787817,y:-82.738472},
{x:41.164734,y:-83.420582},
{x:40.735817,y:-82.802304},
{x:41.406765,y:-82.592438},
{x:40.629331,y:-82.233214},
{x:41.333034,y:-82.62543},
{x:41.084977,y:-82.400077},
{x:41.259296,y:-82.612541},
{x:41.228325,y:-82.603111},
{x:40.718879,y:-82.546611},
{x:41.420973,y:-82.757067},
{x:41.446903,y:-82.676186},
{x:41.432722,y:-82.713044},
{x:41.418461,y:-82.673588},
{x:40.871601,y:-82.646446},
{x:41.111413,y:-83.203557},
{x:41.043068,y:-82.722778},
{x:40.695458,y:-82.512668},
{x:40.776447,y:-82.474342},
{x:40.770039,y:-82.589844},
{x:40.773361,y:-82.551164},
{x:39.563889,y:-84.274429},
{x:39.541676,y:-84.290712},
{x:39.495675,y:-84.321278},
{x:39.40657,y:-84.609991},
{x:39.35199,y:-84.461925},
{x:39.418286,y:-84.47155},
{x:39.398266,y:-84.55829},
{x:39.378076,y:-84.550096},
{x:39.422754,y:-84.577933},
{x:39.347307,y:-84.540088},
{x:39.337162,y:-84.561284},
{x:39.25368,y:-84.795229},
{x:39.432723,y:-84.194151},
{x:39.308944,y:-84.276956},
{x:39.318372,y:-84.313665},
{x:39.357957,y:-84.259043},
{x:39.481464,y:-84.419343},
{x:39.526871,y:-84.385344},
{x:39.500732,y:-84.354913},
{x:39.440917,y:-84.339018},
{x:39.504862,y:-84.74878},
{x:39.561967,y:-84.259707},
{x:39.527155,y:-84.089431},
{x:39.372191,y:-84.384098},
{x:39.319955,y:-84.365698},
{x:39.354056,y:-84.368451},
{x:39.325297,y:-84.433053},
{x:39.04599,y:-84.244992},
{x:39.082673,y:-84.150052},
{x:38.964373,y:-84.091181},
{x:39.29206,y:-83.989773},
{x:38.862138,y:-83.886897},
{x:39.232688,y:-84.160312},
{x:39.352212,y:-83.381794},
{x:39.229487,y:-83.610402},
{x:39.262725,y:-84.271038},
{x:39.194922,y:-84.234432},
{x:39.173582,y:-84.27703},
{x:39.039658,y:-83.919527},
{x:38.967644,y:-84.286484},
{x:38.732001,y:-83.83653},
{x:39.44807,y:-83.806647},
{x:39.522645,y:-83.847182},
{x:39.112905,y:-84.574503},
{x:39.125951,y:-84.484895},
{x:39.147163,y:-84.427831},
{x:39.150495,y:-84.628312},
{x:39.159802,y:-84.455465},
{x:39.171089,y:-84.42678},
{x:39.319144,y:-84.498259},
{x:39.258199,y:-84.470408},
{x:39.222747,y:-84.440535},
{x:39.200988,y:-84.520412},
{x:39.217409,y:-84.548748},
{x:39.142506,y:-84.362796},
{x:39.134818,y:-84.500035},
{x:39.23506,y:-84.508884},
{x:39.16487,y:-84.512991},
{x:39.130524,y:-84.706797},
{x:39.226463,y:-84.396935},
{x:39.19985,y:-84.376142},
{x:39.189942,y:-84.462192},
{x:39.127015,y:-84.605964},
{x:39.095762,y:-84.609159},
{x:39.222756,y:-84.587591},
{x:39.188997,y:-84.601562},
{x:39.280959,y:-84.496206},
{x:39.28046,y:-84.563794},
{x:39.302838,y:-84.524349},
{x:39.270933,y:-84.378108},
{x:39.290433,y:-84.395799},
{x:39.258214,y:-84.340794},
{x:39.099193,y:-84.27066},
{x:39.284848,y:-84.444469},
{x:39.290066,y:-84.467328},
{x:39.195026,y:-84.660911},
{x:39.291997,y:-84.313457},
{x:39.24382,y:-84.595657},
{x:39.071171,y:-84.302994},
{x:39.072011,y:-84.344678},
{x:39.846005,y:-84.4263},
{x:39.760693,y:-84.637236},
{x:39.876598,y:-84.310317},
{x:39.866571,y:-83.956895},
{x:39.772542,y:-84.050216},
{x:39.828889,y:-84.024161},
{x:39.778013,y:-84.057142},
{x:39.637531,y:-84.348414},
{x:40.082316,y:-84.63361},
{x:40.121869,y:-84.62124},
{x:39.659091,y:-83.74564},
{x:39.640948,y:-84.265326},
{x:39.630223,y:-84.225259},
{x:39.889544,y:-84.048144},
{x:39.745082,y:-84.392479},
{x:40.150669,y:-84.222524},
{x:40.141986,y:-84.265816},
{x:40.287339,y:-84.188243},
{x:39.959775,y:-84.188154},
{x:40.050211,y:-84.228619},
{x:39.890848,y:-84.19021},
{x:39.964309,y:-84.328848},
{x:39.707746,y:-83.933413},
{x:39.806041,y:-84.030049},
{x:39.683831,y:-83.936989},
{x:39.75331,y:-84.18958},
{x:39.752518,y:-84.138599},
{x:39.785892,y:-84.189418},
{x:39.811602,y:-84.225917},
{x:39.731996,y:-84.207857},
{x:39.845122,y:-84.19821},
{x:39.819252,y:-84.187731},
{x:39.857404,y:-84.279548},
{x:39.797639,y:-84.253246},
{x:39.762102,y:-84.253871},
{x:39.704743,y:-84.142494},
{x:39.863224,y:-84.098282},
{x:39.866954,y:-84.137301},
{x:39.845305,y:-84.114154},
{x:39.797375,y:-84.129618},
{x:39.821658,y:-84.289304},
{x:39.688107,y:-84.132665},
{x:39.76814,y:-84.122669},
{x:39.743502,y:-84.114295},
{x:39.725823,y:-84.052529},
{x:39.708754,y:-84.203769},
{x:39.676201,y:-84.240961},
{x:39.609737,y:-84.159865},
{x:39.653764,y:-84.156426},
{x:39.673276,y:-84.218569},
{x:39.630242,y:-84.197578},
{x:39.648909,y:-84.109645},
{x:39.942913,y:-83.854245},
{x:39.965557,y:-83.784814},
{x:39.942019,y:-83.832518},
{x:39.923653,y:-83.773598},
{x:39.896308,y:-83.810903},
{x:39.357397,y:-82.975644},
{x:39.333707,y:-83.017309},
{x:39.335387,y:-82.975074},
{x:39.337735,y:-82.950056},
{x:38.82006,y:-82.17869},
{x:38.541181,y:-82.674985},
{x:39.045324,y:-82.62983},
{x:39.252484,y:-82.492009},
{x:38.900803,y:-82.569699},
{x:38.934309,y:-83.417288},
{x:38.788366,y:-82.984368},
{x:38.739691,y:-82.964496},
{x:38.438617,y:-82.387681},
{x:38.931122,y:-83.569616},
{x:38.419428,y:-82.506776},
{x:38.410879,y:-82.524498},
{x:38.888673,y:-82.382835},
{x:39.119947,y:-82.99316},
{x:39.112245,y:-82.534045},
{x:38.782937,y:-83.562452},
{x:38.717341,y:-82.856914},
{x:39.337199,y:-82.06769},
{x:39.313689,y:-82.104232},
{x:39.274388,y:-81.581379},
{x:39.548103,y:-81.637704},
{x:39.420972,y:-81.447941},
{x:39.425713,y:-81.474849},
{x:39.410124,y:-81.430787},
{x:39.404056,y:-81.418992},
{x:39.4579,y:-82.2287},
{x:39.019673,y:-82.041782},
{x:39.374914,y:-82.134504},
{x:40.764857,y:-84.154494},
{x:40.739697,y:-84.112815},
{x:40.767897,y:-84.109389},
{x:40.73028,y:-84.070041},
{x:40.752553,y:-84.147522},
{x:40.704892,y:-84.150781},
{x:40.833967,y:-83.964394},
{x:40.769686,y:-83.82283},
{x:40.893065,y:-83.870461},
{x:40.54947,y:-84.552258},
{x:40.480618,y:-84.619421},
{x:40.843066,y:-84.326819},
{x:41.058269,y:-83.66337},
{x:41.053195,y:-83.610204},
{x:40.403676,y:-84.37608},
{x:41.173281,y:-83.645599},
{x:41.025231,y:-84.048411},
{x:41.149684,y:-84.580142},
{x:40.553116,y:-84.407129},
{x:40.888799,y:-84.581576},
{x:40.856247,y:-84.597945},
{x:40.563608,y:-84.174662},
{x:40.276175,y:-85.671052},
{x:40.14835,y:-85.67413},
{x:40.096637,y:-85.65171},
{x:40.062192,y:-85.653726},
{x:40.101774,y:-85.680855},
{x:39.954796,y:-86.163078},
{x:40.007819,y:-86.126534},
{x:39.962448,y:-86.114367},
{x:40.123186,y:-86.012764},
{x:40.277662,y:-85.810587},
{x:39.957571,y:-86.010663},
{x:39.927669,y:-86.025688},
{x:39.959794,y:-86.039499},
{x:39.979491,y:-86.00488},
{x:39.92988,y:-85.918202},
{x:40.278639,y:-86.493255},
{x:40.047129,y:-86.486232},
{x:39.995185,y:-85.929591},
{x:40.045972,y:-86.000974},
{x:40.000783,y:-86.065519},
{x:40.043729,y:-86.075089},
{x:40.004086,y:-85.769988},
{x:39.992353,y:-85.84442},
{x:40.282318,y:-86.030839},
{x:40.043445,y:-86.141476},
{x:39.979601,y:-86.39572},
{x:39.949999,y:-86.340047},
{x:39.949694,y:-86.275388},
{x:39.837665,y:-86.383056},
{x:39.856265,y:-86.392012},
{x:39.643011,y:-86.332592},
{x:39.529955,y:-86.801437},
{x:39.757975,y:-86.508942},
{x:39.763295,y:-86.402478},
{x:39.763117,y:-86.331897},
{x:39.31105,y:-85.964623},
{x:39.483813,y:-86.018662},
{x:39.492135,y:-86.06376},
{x:39.645496,y:-86.84157},
{x:39.804046,y:-85.770431},
{x:39.816633,y:-85.915818},
{x:39.617664,y:-86.159143},
{x:39.578567,y:-86.15964},
{x:39.584025,y:-86.100651},
{x:39.614803,y:-86.079349},
{x:39.843607,y:-85.557612},
{x:39.408087,y:-86.437561},
{x:39.616153,y:-86.478616},
{x:39.608178,y:-86.375517},
{x:39.723216,y:-85.898228},
{x:39.716355,y:-86.359126},
{x:39.674667,y:-86.369172},
{x:39.69969,y:-86.410339},
{x:39.625615,y:-85.444402},
{x:39.548005,y:-85.776147},
{x:39.583635,y:-85.818309},
{x:39.519593,y:-85.751941},
{x:39.411174,y:-86.157299},
{x:39.551489,y:-86.046167},
{x:39.781201,y:-86.090064},
{x:39.788983,y:-86.15694},
{x:39.758913,y:-86.113194},
{x:39.736905,y:-86.102225},
{x:39.700797,y:-86.081573},
{x:39.84708,y:-86.122419},
{x:39.824508,y:-86.158496},
{x:39.693164,y:-86.187506},
{x:39.80497,y:-86.082523},
{x:39.824912,y:-86.120581},
{x:39.771941,y:-86.038819},
{x:39.794628,y:-86.046396},
{x:39.870209,y:-86.140782},
{x:39.881683,y:-86.070265},
{x:39.694692,y:-86.270211},
{x:39.787467,y:-86.217567},
{x:39.779501,y:-86.288264},
{x:39.799888,y:-86.26818},
{x:39.72501,y:-86.152023},
{x:39.82942,y:-86.042393},
{x:39.825517,y:-86.009443},
{x:39.680286,y:-86.13159},
{x:39.65151,y:-86.137428},
{x:39.665325,y:-86.083276},
{x:39.665826,y:-86.097923},
{x:39.71264,y:-86.120007},
{x:39.689065,y:-86.149345},
{x:39.635927,y:-86.118031},
{x:39.854462,y:-86.194366},
{x:39.774344,y:-85.993854},
{x:39.717218,y:-86.007591},
{x:39.751014,y:-86.029091},
{x:39.926411,y:-86.111161},
{x:39.887679,y:-86.121419},
{x:39.714874,y:-86.297554},
{x:39.754121,y:-86.247051},
{x:39.750262,y:-86.226261},
{x:39.744354,y:-86.279587},
{x:39.904799,y:-86.064141},
{x:39.823341,y:-86.236856},
{x:39.856491,y:-86.241017},
{x:39.824029,y:-86.280539},
{x:39.861611,y:-85.973145},
{x:39.899014,y:-85.994629},
{x:39.926635,y:-86.158555},
{x:39.882086,y:-86.266071},
{x:39.911254,y:-86.22},
{x:39.925732,y:-86.228626},
{x:41.376532,y:-87.470583},
{x:41.604469,y:-87.115331},
{x:41.616504,y:-87.044712},
{x:41.421755,y:-87.23856},
{x:41.419382,y:-87.364721},
{x:41.205935,y:-87.199341},
{x:41.49295,y:-87.505049},
{x:41.638751,y:-87.448917},
{x:41.627731,y:-87.480488},
{x:41.596072,y:-87.499761},
{x:41.562424,y:-87.515036},
{x:41.55138,y:-87.434583},
{x:41.525738,y:-87.47191},
{x:41.547837,y:-87.470661},
{x:41.57819,y:-87.462112},
{x:41.588528,y:-87.440476},
{x:41.629292,y:-87.508352},
{x:41.288791,y:-87.296625},
{x:41.550249,y:-87.242138},
{x:41.615632,y:-86.731986},
{x:41.622788,y:-86.69416},
{x:41.290405,y:-87.400558},
{x:41.667548,y:-86.893539},
{x:41.709305,y:-86.870524},
{x:41.690755,y:-86.894467},
{x:41.549936,y:-87.179912},
{x:41.606885,y:-87.17115},
{x:41.576412,y:-87.178381},
{x:41.441433,y:-87.469315},
{x:41.487891,y:-87.470394},
{x:41.462254,y:-87.086379},
{x:41.468017,y:-87.028993},
{x:41.486576,y:-87.049501},
{x:41.549802,y:-87.137101},
{x:41.547935,y:-86.896442},
{x:41.680817,y:-87.501346},
{x:41.601627,y:-87.356171},
{x:41.597286,y:-87.265996},
{x:41.554141,y:-87.356319},
{x:41.507823,y:-87.334218},
{x:41.506644,y:-87.31736},
{x:41.471767,y:-87.312553},
{x:41.471628,y:-87.373569},
{x:41.238573,y:-86.26022},
{x:41.446761,y:-86.167138},
{x:41.703079,y:-85.888113},
{x:41.725038,y:-85.972403},
{x:41.675234,y:-86.002713},
{x:41.688119,y:-85.973391},
{x:41.647781,y:-85.937359},
{x:41.593947,y:-85.85782},
{x:41.560511,y:-85.796386},
{x:41.668181,y:-85.820132},
{x:41.752813,y:-86.119376},
{x:41.283539,y:-86.621276},
{x:41.655621,y:-85.704979},
{x:41.712276,y:-86.186249},
{x:41.661692,y:-86.135432},
{x:41.628719,y:-86.176071},
{x:41.679919,y:-86.194758},
{x:41.443035,y:-85.984625},
{x:41.448797,y:-86.309725},
{x:41.358186,y:-86.310883},
{x:41.411182,y:-85.745589},
{x:41.536648,y:-86.000552},
{x:41.465524,y:-86.48084},
{x:41.240568,y:-85.853155},
{x:41.230133,y:-85.800587},
{x:41.268973,y:-85.853651},
{x:41.680982,y:-86.249984},
{x:41.653325,y:-86.25027},
{x:41.657386,y:-86.218355},
{x:41.626642,y:-86.2355},
{x:41.672606,y:-86.318558},
{x:41.724294,y:-86.286093},
{x:41.692045,y:-86.294589},
{x:41.696053,y:-86.21721},
{x:41.728004,y:-86.251383},
{x:41.653667,y:-84.998747},
{x:41.634471,y:-85.043885},
{x:41.366158,y:-85.076409},
{x:41.36438,y:-85.222826},
{x:40.663819,y:-84.956161},
{x:40.755827,y:-85.166708},
{x:41.429966,y:-84.886863},
{x:41.225289,y:-85.316812},
{x:41.170716,y:-85.48511},
{x:40.833029,y:-84.937208},
{x:41.741085,y:-85.000042},
{x:40.892635,y:-85.504056},
{x:41.451946,y:-85.269152},
{x:41.638361,y:-85.417137},
{x:41.453594,y:-85.586886},
{x:41.069272,y:-85.045275},
{x:40.723051,y:-85.436957},
{x:41.140738,y:-84.850121},
{x:41.074783,y:-85.145628},
{x:41.032571,y:-85.257186},
{x:41.06035,y:-85.213581},
{x:41.097166,y:-85.088966},
{x:41.118651,y:-85.139342},
{x:41.049693,y:-85.135817},
{x:41.118185,y:-85.183041},
{x:41.099924,y:-85.15682},
{x:41.019048,y:-85.169893},
{x:41.074765,y:-85.276473},
{x:41.133137,y:-85.101528},
{x:41.120923,y:-85.063152},
{x:41.0142,y:-85.112539},
{x:41.173602,y:-85.166687},
{x:41.177966,y:-85.125803},
{x:41.131968,y:-85.157258},
{x:41.128233,y:-85.006843},
{x:40.499086,y:-86.108465},
{x:40.487209,y:-86.164108},
{x:40.476312,y:-86.108368},
{x:40.454903,y:-86.129296},
{x:40.44036,y:-86.125645},
{x:40.584845,y:-86.678705},
{x:40.487512,y:-85.594854},
{x:40.757546,y:-86.326767},
{x:40.751409,y:-86.37783},
{x:40.554328,y:-85.548951},
{x:40.573743,y:-85.675876},
{x:40.543547,y:-85.674217},
{x:41.000491,y:-85.793764},
{x:40.778939,y:-86.12832},
{x:40.744481,y:-86.096058},
{x:41.05189,y:-86.217352},
{x:40.812873,y:-85.83657},
{x:41.06413,y:-86.602795},
{x:39.308994,y:-85.21494},
{x:39.42382,y:-85.013072},
{x:39.086007,y:-84.878016},
{x:39.219878,y:-84.855441},
{x:39.111711,y:-84.851837},
{x:39.069491,y:-85.254539},
{x:38.453099,y:-85.671264},
{x:38.23069,y:-86.132218},
{x:38.314964,y:-85.897123},
{x:38.289964,y:-85.914036},
{x:38.309233,y:-85.769586},
{x:38.357653,y:-85.701138},
{x:38.322936,y:-85.712075},
{x:38.490941,y:-85.770092},
{x:38.31618,y:-85.80502},
{x:38.302987,y:-85.838212},
{x:38.339388,y:-85.816244},
{x:38.599218,y:-86.101028},
{x:38.685588,y:-85.79215},
{x:38.391406,y:-85.765733},
{x:39.201083,y:-85.917949},
{x:39.217964,y:-85.885882},
{x:39.199828,y:-85.948896},
{x:38.88159,y:-86.039868},
{x:39.354942,y:-85.480167},
{x:38.7234,y:-85.467923},
{x:38.775361,y:-85.402792},
{x:39.012966,y:-85.638333},
{x:39.45035,y:-85.626446},
{x:38.958904,y:-85.83715},
{x:38.958409,y:-85.875947},
{x:40.21866,y:-85.376244},
{x:40.19185,y:-85.41938},
{x:40.219439,y:-85.431431},
{x:40.191292,y:-85.381274},
{x:40.300047,y:-85.24981},
{x:39.8505,y:-85.143211},
{x:39.671868,y:-85.135138},
{x:40.113444,y:-85.573575},
{x:40.467194,y:-85.370522},
{x:39.915558,y:-85.383476},
{x:40.43887,y:-84.978413},
{x:39.866645,y:-84.889416},
{x:39.833315,y:-84.824447},
{x:39.823116,y:-84.943653},
{x:39.829315,y:-84.878651},
{x:40.197521,y:-84.808176},
{x:40.172517,y:-84.965751},
{x:40.180789,y:-85.461113},
{x:39.164881,y:-86.495374},
{x:39.145859,y:-86.531708},
{x:39.187175,y:-86.533684},
{x:39.165085,y:-86.57163},
{x:39.214032,y:-86.592077},
{x:38.86057,y:-86.517934},
{x:39.038269,y:-87.146076},
{x:38.724724,y:-86.478688},
{x:39.201601,y:-86.23856},
{x:39.286789,y:-86.756885},
{x:38.562283,y:-86.613309},
{x:38.652544,y:-87.158076},
{x:38.779928,y:-87.314075},
{x:38.208689,y:-86.866578},
{x:38.428059,y:-86.937365},
{x:38.39004,y:-86.94049},
{x:38.674761,y:-86.912588},
{x:38.487455,y:-87.286785},
{x:37.937076,y:-86.759067},
{x:38.689097,y:-87.506719},
{x:38.66513,y:-87.511166},
{x:38.049627,y:-87.281889},
{x:37.934825,y:-87.883791},
{x:37.977023,y:-87.393083},
{x:38.354779,y:-87.592073},
{x:37.97794,y:-87.564366},
{x:38.011998,y:-87.574525},
{x:37.992734,y:-87.539151},
{x:37.986435,y:-87.600054},
{x:37.978148,y:-87.628548},
{x:37.963396,y:-87.543957},
{x:37.955469,y:-87.513955},
{x:37.969177,y:-87.49384},
{x:38.009015,y:-87.492767},
{x:37.98389,y:-87.492208},
{x:37.978009,y:-87.473318},
{x:38.158076,y:-87.551809},
{x:38.057281,y:-87.546009},
{x:39.423432,y:-87.415525},
{x:39.434808,y:-87.333566},
{x:39.47218,y:-87.372832},
{x:39.509265,y:-87.384482},
{x:39.463941,y:-87.413478},
{x:39.451725,y:-87.125002},
{x:39.525736,y:-87.114756},
{x:39.657554,y:-87.430306},
{x:39.102352,y:-87.424377},
{x:40.422205,y:-86.859002},
{x:40.418197,y:-86.826376},
{x:40.393934,y:-86.854551},
{x:40.422605,y:-86.904798},
{x:40.503574,y:-86.869177},
{x:40.452862,y:-86.917684},
{x:40.431055,y:-86.913147},
{x:40.367688,y:-86.863342},
{x:40.387455,y:-86.90512},
{x:40.292991,y:-87.252133},
{x:40.076209,y:-86.90444},
{x:40.023913,y:-86.902395},
{x:40.768612,y:-87.439088},
{x:40.745366,y:-86.773483},
{x:40.765342,y:-87.126973},
{x:40.941335,y:-87.216729},
{x:40.925958,y:-87.155532},
{x:42.614983,y:-82.541794},
{x:42.929111,y:-83.050133},
{x:42.989408,y:-82.928195},
{x:42.465603,y:-82.935982},
{x:42.461662,y:-83.105309},
{x:42.469036,y:-83.279076},
{x:42.499131,y:-83.30366},
{x:42.55574,y:-82.870965},
{x:42.553738,y:-82.962364},
{x:42.556358,y:-82.922852},
{x:42.628029,y:-82.893959},
{x:42.601807,y:-82.951828},
{x:42.613544,y:-82.972038},
{x:42.697453,y:-82.506439},
{x:42.916534,y:-82.483757},
{x:42.6357,y:-82.857246},
{x:42.577683,y:-82.886795},
{x:42.594563,y:-82.859795},
{x:42.602066,y:-82.899796},
{x:42.671558,y:-82.941711},
{x:42.628954,y:-82.941587},
{x:42.571785,y:-82.852219},
{x:42.678215,y:-82.741859},
{x:42.719846,y:-82.798042},
{x:42.673981,y:-82.824738},
{x:43.02932,y:-82.456772},
{x:43.000927,y:-82.439163},
{x:42.970722,y:-82.453274},
{x:42.799702,y:-82.741714},
{x:42.501617,y:-82.934555},
{x:42.493776,y:-82.940055},
{x:42.534031,y:-82.91198},
{x:42.509445,y:-82.963806},
{x:42.489456,y:-83.148857},
{x:42.533108,y:-83.120766},
{x:42.501095,y:-83.105125},
{x:42.532597,y:-83.107643},
{x:42.532097,y:-83.180445},
{x:42.519577,y:-83.138802},
{x:42.515656,y:-83.184921},
{x:42.985065,y:-82.538322},
{x:42.511042,y:-83.223862},
{x:42.820427,y:-82.504341},
{x:42.466953,y:-82.895935},
{x:42.526134,y:-82.89711},
{x:42.563551,y:-83.129444},
{x:42.562344,y:-83.089409},
{x:42.548823,y:-83.148302},
{x:42.463596,y:-83.025917},
{x:42.478355,y:-83.005714},
{x:42.448296,y:-83.055244},
{x:42.476017,y:-83.080254},
{x:42.505489,y:-83.047218},
{x:42.53511,y:-83.065338},
{x:42.531568,y:-83.029837},
{x:42.496181,y:-83.027161},
{x:42.52066,y:-82.988815},
{x:42.783089,y:-83.012383},
{x:43.131256,y:-82.79892},
{x:42.589504,y:-83.129791},
{x:42.258072,y:-83.204346},
{x:42.228413,y:-83.229309},
{x:42.293945,y:-83.839226},
{x:42.276642,y:-83.777718},
{x:42.256683,y:-83.693816},
{x:42.303082,y:-83.707802},
{x:42.233278,y:-83.740092},
{x:42.251545,y:-83.665504},
{x:42.242077,y:-83.765572},
{x:42.223904,y:-83.485542},
{x:42.213932,y:-83.542725},
{x:42.507147,y:-83.761089},
{x:42.543163,y:-83.787033},
{x:42.298737,y:-84.023994},
{x:42.281277,y:-83.188835},
{x:42.297031,y:-83.285141},
{x:42.279049,y:-83.270073},
{x:42.345676,y:-83.156944},
{x:42.330181,y:-83.172371},
{x:42.318966,y:-83.1884},
{x:42.334595,y:-83.272377},
{x:42.294945,y:-83.878571},
{x:41.95917,y:-83.668023},
{x:42.10709,y:-83.285881},
{x:42.32489,y:-83.331482},
{x:42.311256,y:-83.306778},
{x:41.749593,y:-83.627293},
{x:42.222561,y:-83.181763},
{x:42.254272,y:-83.172768},
{x:42.253712,y:-83.194778},
{x:42.361485,y:-83.415993},
{x:42.428192,y:-83.375725},
{x:42.37188,y:-83.332458},
{x:42.398914,y:-83.335129},
{x:42.095345,y:-83.677002},
{x:41.924881,y:-83.365288},
{x:41.942574,y:-83.404922},
{x:41.884338,y:-83.394516},
{x:41.906784,y:-83.404945},
{x:42.516305,y:-83.609935},
{x:42.410137,y:-83.434525},
{x:42.457306,y:-83.932457},
{x:42.391506,y:-83.509514},
{x:42.359341,y:-83.462486},
{x:42.396557,y:-83.435234},
{x:42.246231,y:-83.347023},
{x:42.166412,y:-83.266113},
{x:42.233105,y:-83.385193},
{x:42.237766,y:-83.326973},
{x:42.172192,y:-83.767563},
{x:42.442783,y:-83.650757},
{x:42.197144,y:-83.260262},
{x:42.256657,y:-83.245888},
{x:42.232105,y:-83.270233},
{x:42.197037,y:-83.299942},
{x:42.126472,y:-83.186554},
{x:42.139004,y:-83.248604},
{x:42.140526,y:-83.210083},
{x:42.094879,y:-83.236282},
{x:42.282002,y:-83.384442},
{x:42.353867,y:-83.350334},
{x:42.324303,y:-83.418823},
{x:42.339432,y:-83.384247},
{x:42.324188,y:-83.375038},
{x:42.295963,y:-83.387581},
{x:42.322559,y:-83.482132},
{x:42.272385,y:-83.487991},
{x:42.27914,y:-83.435394},
{x:42.441994,y:-83.75618},
{x:42.198521,y:-83.150742},
{x:42.212498,y:-83.225807},
{x:42.187725,y:-83.184685},
{x:42.198738,y:-83.204666},
{x:42.224957,y:-83.618729},
{x:42.234779,y:-83.679314},
{x:42.208542,y:-83.680946},
{x:42.240902,y:-83.599434},
{x:42.352063,y:-83.061505},
{x:42.367924,y:-83.080879},
{x:42.368423,y:-83.139336},
{x:42.434734,y:-82.978539},
{x:42.351326,y:-83.046471},
{x:42.303288,y:-83.120926},
{x:42.315891,y:-83.105934},
{x:42.331451,y:-83.129562},
{x:42.356712,y:-82.987854},
{x:42.384029,y:-82.968651},
{x:42.399277,y:-83.000519},
{x:42.442883,y:-83.259987},
{x:42.416267,y:-83.255989},
{x:42.44598,y:-83.120174},
{x:42.458549,y:-83.134041},
{x:42.417461,y:-83.141441},
{x:42.393543,y:-83.122047},
{x:42.402744,y:-83.160263},
{x:42.401741,y:-83.221764},
{x:42.409462,y:-82.914486},
{x:42.449764,y:-82.939995},
{x:42.385387,y:-83.179489},
{x:42.372248,y:-83.198121},
{x:42.357365,y:-83.217283},
{x:42.267941,y:-83.13541},
{x:42.440861,y:-83.023926},
{x:42.445362,y:-83.082897},
{x:42.431213,y:-83.185806},
{x:42.416332,y:-83.200356},
{x:42.483286,y:-83.20163},
{x:42.447498,y:-83.199951},
{x:42.371326,y:-83.295776},
{x:42.400659,y:-83.277437},
{x:42.36927,y:-83.275002},
{x:42.438656,y:-83.311302},
{x:42.225649,y:-83.34802},
{x:42.208109,y:-83.357057},
{x:42.329212,y:-83.040367},
{x:42.605999,y:-83.29496},
{x:42.635687,y:-83.13377},
{x:42.669312,y:-83.134125},
{x:42.68,y:-83.192772},
{x:42.641788,y:-83.171486},
{x:42.565605,y:-83.050056},
{x:42.592594,y:-83.088684},
{x:42.550652,y:-83.026291},
{x:42.618202,y:-82.990883},
{x:42.589684,y:-83.030037},
{x:42.62771,y:-83.007201},
{x:42.713155,y:-83.036176},
{x:42.669985,y:-83.037952},
{x:42.643978,y:-83.033607},
{x:42.612301,y:-83.328239},
{x:42.678322,y:-83.22242},
{x:42.663528,y:-83.246414},
{x:42.701603,y:-83.305588},
{x:42.707574,y:-83.243359},
{x:42.66053,y:-83.403183},
{x:42.615669,y:-83.434158},
{x:42.67062,y:-83.335251},
{x:42.638924,y:-83.349243},
{x:42.688934,y:-83.38324},
{x:42.497089,y:-83.418396},
{x:42.527325,y:-83.360718},
{x:42.468777,y:-83.425926},
{x:42.469641,y:-83.394892},
{x:42.468674,y:-83.356476},
{x:42.676943,y:-83.281338},
{x:42.619251,y:-83.279762},
{x:42.718872,y:-83.41732},
{x:42.721722,y:-83.379807},
{x:42.755142,y:-83.481026},
{x:42.633553,y:-83.743423},
{x:42.642597,y:-83.616997},
{x:42.777615,y:-83.238731},
{x:42.837318,y:-83.273404},
{x:42.440289,y:-83.435364},
{x:42.495114,y:-83.470921},
{x:42.581921,y:-83.611923},
{x:42.578571,y:-83.446953},
{x:42.649448,y:-83.525642},
{x:42.65514,y:-83.476906},
{x:42.537777,y:-83.475822},
{x:42.526321,y:-83.515938},
{x:42.534447,y:-83.438644},
{x:42.494816,y:-83.536789},
{x:43.816869,y:-83.001527},
{x:43.250577,y:-83.780231},
{x:43.176954,y:-83.765776},
{x:43.265125,y:-82.620216},
{x:43.010931,y:-83.556196},
{x:43.016801,y:-83.518084},
{x:42.924638,y:-83.993961},
{x:42.813083,y:-83.706876},
{x:42.863607,y:-83.738101},
{x:42.790224,y:-83.73477},
{x:43.060857,y:-83.810895},
{x:43.116333,y:-83.617085},
{x:42.893844,y:-83.631406},
{x:42.921469,y:-83.621394},
{x:42.809467,y:-83.627808},
{x:43.016197,y:-83.071215},
{x:43.051253,y:-83.29683},
{x:43.045901,y:-83.319877},
{x:42.812351,y:-83.789423},
{x:43.31945,y:-83.080292},
{x:42.942408,y:-83.312652},
{x:43.176567,y:-83.887678},
{x:43.120401,y:-83.675313},
{x:43.111822,y:-83.693766},
{x:43.20303,y:-83.313784},
{x:42.837761,y:-83.44651},
{x:43.167791,y:-83.524836},
{x:43.421429,y:-82.844969},
{x:42.965346,y:-83.810751},
{x:43.023073,y:-83.732073},
{x:43.008371,y:-83.653669},
{x:43.06017,y:-83.758417},
{x:43.045381,y:-83.73213},
{x:43.0536,y:-83.672421},
{x:43.052501,y:-83.615318},
{x:43.033498,y:-83.639603},
{x:42.915474,y:-83.72315},
{x:42.97428,y:-83.711144},
{x:42.992371,y:-83.734238},
{x:42.94361,y:-83.726363},
{x:42.944594,y:-83.691581},
{x:43.021873,y:-83.598631},
{x:42.991756,y:-83.633082},
{x:42.97817,y:-83.673029},
{x:42.998018,y:-83.771619},
{x:43.44976,y:-83.8873},
{x:43.398538,y:-83.911579},
{x:43.414352,y:-83.900751},
{x:43.428453,y:-83.950891},
{x:43.414355,y:-83.967547},
{x:43.445285,y:-83.976173},
{x:43.437273,y:-84.015157},
{x:43.480576,y:-83.973913},
{x:43.416461,y:-84.067278},
{x:43.599339,y:-84.088961},
{x:43.186165,y:-84.167332},
{x:43.839828,y:-84.767523},
{x:43.515748,y:-84.118437},
{x:43.980487,y:-84.500476},
{x:44.024934,y:-84.802138},
{x:43.414931,y:-84.230091},
{x:44.335424,y:-84.799527},
{x:43.667277,y:-83.950542},
{x:43.615011,y:-84.213873},
{x:43.642976,y:-84.247492},
{x:43.606072,y:-84.188096},
{x:44.656127,y:-84.130639},
{x:43.853197,y:-84.015034},
{x:44.298283,y:-84.67825},
{x:44.500223,y:-84.596769},
{x:44.4141,y:-84.115569},
{x:43.30381,y:-84.143404},
{x:43.690148,y:-84.367306},
{x:43.976539,y:-83.959151},
{x:44.251325,y:-84.226309},
{x:43.592482,y:-83.914837},
{x:43.592813,y:-83.991831},
{x:43.613397,y:-83.914098},
{x:43.62332,y:-83.900767},
{x:43.556384,y:-83.950631},
{x:43.580482,y:-83.856456},
{x:43.593717,y:-83.888093},
{x:43.565447,y:-83.894201},
{x:43.354355,y:-83.871016},
{x:43.483957,y:-83.403676},
{x:43.600184,y:-83.183078},
{x:44.27567,y:-83.505262},
{x:43.593837,y:-83.836439},
{x:43.341664,y:-83.738822},
{x:44.426574,y:-83.32964},
{x:43.725734,y:-83.445246},
{x:43.373671,y:-83.594137},
{x:43.394371,y:-84.667532},
{x:43.088403,y:-85.242784},
{x:43.176761,y:-84.853026},
{x:42.566861,y:-84.829421},
{x:42.824294,y:-84.543674},
{x:42.731273,y:-84.470181},
{x:42.494048,y:-84.657794},
{x:43.408468,y:-85.034688},
{x:42.648689,y:-84.071983},
{x:42.797256,y:-84.738457},
{x:42.740213,y:-84.742177},
{x:43.176682,y:-85.276119},
{x:43.189743,y:-85.252618},
{x:42.772504,y:-84.412589},
{x:42.651977,y:-84.529087},
{x:42.619492,y:-83.99173},
{x:42.614258,y:-83.959404},
{x:42.589066,y:-83.878708},
{x:42.94633,y:-85.075352},
{x:43.291129,y:-84.586091},
{x:42.778074,y:-85.13052},
{x:43.432071,y:-85.265556},
{x:42.568262,y:-84.445479},
{x:42.590539,y:-84.458884},
{x:43.61107,y:-84.737508},
{x:43.611343,y:-84.766546},
{x:43.597465,y:-84.808548},
{x:43.584622,y:-84.768181},
{x:42.722293,y:-84.425064},
{x:42.68227,y:-84.433373},
{x:42.997195,y:-84.178859},
{x:42.997952,y:-84.120091},
{x:42.83498,y:-84.221402},
{x:42.86352,y:-84.888876},
{x:42.623834,y:-84.739775},
{x:42.991125,y:-84.546086},
{x:43.407524,y:-84.608},
{x:43.523675,y:-84.679505},
{x:43.293066,y:-85.078036},
{x:42.663052,y:-84.197873},
{x:42.689263,y:-84.285534},
{x:42.757011,y:-84.543351},
{x:42.700217,y:-84.566711},
{x:42.686458,y:-84.54853},
{x:42.682444,y:-84.515586},
{x:42.663052,y:-84.591772},
{x:42.740466,y:-84.506085},
{x:42.769418,y:-84.601241},
{x:42.740447,y:-84.677686},
{x:42.741514,y:-84.656633},
{x:42.740715,y:-84.604604},
{x:42.727149,y:-84.641703},
{x:42.252117,y:-85.531446},
{x:42.323329,y:-85.521024},
{x:42.300201,y:-85.568686},
{x:42.237655,y:-85.560687},
{x:42.21657,y:-85.588612},
{x:42.296622,y:-85.656529},
{x:42.271062,y:-85.642262},
{x:42.294799,y:-85.585415},
{x:42.258108,y:-85.59016},
{x:42.296384,y:-85.683582},
{x:42.376702,y:-85.659275},
{x:42.240999,y:-85.678238},
{x:42.517569,y:-85.836278},
{x:42.312236,y:-86.110125},
{x:42.309894,y:-85.125391},
{x:42.302512,y:-85.082535},
{x:42.339241,y:-85.155619},
{x:42.298625,y:-85.214737},
{x:42.263491,y:-85.198047},
{x:42.346929,y:-85.240356},
{x:42.080144,y:-86.417816},
{x:41.87349,y:-85.184375},
{x:41.911948,y:-86.001901},
{x:41.921181,y:-85.511081},
{x:41.938696,y:-84.985438},
{x:42.178551,y:-86.303586},
{x:42.105378,y:-85.981713},
{x:41.984585,y:-86.122672},
{x:42.275128,y:-85.428185},
{x:42.26161,y:-85.17858},
{x:42.194529,y:-86.167374},
{x:42.649101,y:-85.303987},
{x:42.272223,y:-84.982789},
{x:42.209751,y:-85.890734},
{x:42.449411,y:-85.662895},
{x:42.200278,y:-85.56078},
{x:41.942228,y:-84.896645},
{x:42.379425,y:-85.454388},
{x:42.084627,y:-86.486133},
{x:42.1221,y:-85.636485},
{x:42.4065,y:-86.24741},
{x:42.390416,y:-86.268313},
{x:41.794087,y:-85.42896},
{x:42.105271,y:-84.991125},
{x:41.942147,y:-85.645637},
{x:41.796574,y:-85.661858},
{x:41.950389,y:-86.344961},
{x:41.932565,y:-86.570631},
{x:41.827616,y:-86.350078},
{x:41.798022,y:-86.07845},
{x:41.777758,y:-86.732282},
{x:41.815399,y:-86.247376},
{x:42.033188,y:-86.51362},
{x:42.292086,y:-84.412994},
{x:42.276758,y:-84.311298},
{x:42.272442,y:-84.452233},
{x:42.262908,y:-84.422858},
{x:42.257328,y:-84.359962},
{x:42.24718,y:-84.436994},
{x:42.24013,y:-84.352787},
{x:42.226937,y:-84.415859},
{x:41.87544,y:-84.041936},
{x:41.91106,y:-84.029263},
{x:42.262386,y:-84.757063},
{x:41.829282,y:-83.851303},
{x:42.099999,y:-84.248451},
{x:42.072256,y:-83.981589},
{x:42.006157,y:-83.963997},
{x:41.93075,y:-84.64217},
{x:41.854421,y:-84.3613},
{x:41.981953,y:-84.665369},
{x:42.452031,y:-84.439897},
{x:41.79864,y:-83.694382},
{x:42.205225,y:-84.552244},
{x:42.439809,y:-84.175507},
{x:42.958119,y:-85.493159},
{x:43.685862,y:-85.484248},
{x:42.811796,y:-85.728372},
{x:42.795304,y:-85.507634},
{x:43.220339,y:-85.567653},
{x:43.034838,y:-85.69027},
{x:43.039037,y:-85.66386},
{x:42.930962,y:-85.354259},
{x:43.40621,y:-85.803425},
{x:43.116827,y:-85.547403},
{x:43.160676,y:-85.721145},
{x:42.630306,y:-85.543568},
{x:42.674566,y:-85.656964},
{x:42.973099,y:-85.918471},
{x:43.058798,y:-85.957403},
{x:43.466697,y:-85.968951},
{x:43.132886,y:-86.154562},
{x:43.046559,y:-86.221061},
{x:42.86165,y:-85.7238},
{x:42.884165,y:-85.738159},
{x:43.689404,y:-86.384406},
{x:42.746758,y:-86.073412},
{x:42.790853,y:-86.079004},
{x:42.755534,y:-86.117137},
{x:42.804237,y:-86.113534},
{x:42.827178,y:-86.094991},
{x:42.852858,y:-85.860505},
{x:42.907523,y:-85.828501},
{x:42.903533,y:-85.78592},
{x:43.955578,y:-86.413641},
{x:43.191245,y:-86.261854},
{x:43.205295,y:-86.289701},
{x:43.234847,y:-86.155566},
{x:43.233888,y:-86.20696},
{x:43.154695,y:-86.203377},
{x:43.204801,y:-86.200196},
{x:43.267021,y:-86.249392},
{x:43.295894,y:-86.203155},
{x:43.076327,y:-86.202209},
{x:43.409702,y:-86.318431},
{x:42.809986,y:-85.992854},
{x:42.97057,y:-85.6591},
{x:42.985206,y:-85.695999},
{x:43.019519,y:-85.724582},
{x:43.012222,y:-85.688131},
{x:42.96349,y:-85.696067},
{x:42.984092,y:-85.637809},
{x:43.05968,y:-85.580653},
{x:43.031916,y:-85.62536},
{x:42.940308,y:-85.666804},
{x:42.911865,y:-85.597801},
{x:42.883138,y:-85.623409},
{x:42.853594,y:-85.625582},
{x:42.914288,y:-85.732527},
{x:42.91389,y:-85.6963},
{x:42.883714,y:-85.687174},
{x:42.913347,y:-85.542039},
{x:42.935777,y:-85.694556},
{x:42.972777,y:-85.774274},
{x:42.841323,y:-85.67689},
{x:42.913342,y:-85.655956},
{x:42.893765,y:-85.665721},
{x:44.280941,y:-85.405487},
{x:44.235489,y:-85.452193},
{x:44.973544,y:-85.199053},
{x:44.608154,y:-86.100107},
{x:43.900099,y:-85.272694},
{x:44.725347,y:-85.183713},
{x:44.339591,y:-85.21437},
{x:44.91298,y:-85.050292},
{x:44.225086,y:-86.311117},
{x:43.886644,y:-85.522106},
{x:44.733958,y:-85.624558},
{x:44.739061,y:-85.643415},
{x:44.673425,y:-85.655665},
{x:44.763851,y:-85.606961},
{x:44.772187,y:-85.500627},
{x:45.043409,y:-83.455975},
{x:45.062085,y:-83.479397},
{x:45.072561,y:-83.445276},
{x:45.204456,y:-84.997799},
{x:45.304351,y:-85.259447},
{x:45.627939,y:-84.479019},
{x:45.152863,y:-85.140423},
{x:45.027841,y:-84.682986},
{x:44.653264,y:-84.709071},
{x:45.402931,y:-84.612537},
{x:45.369839,y:-84.970222},
{x:45.389077,y:-84.91763},
{x:45.411445,y:-83.825009},
{x:45.856861,y:-84.738805},
{x:46.474431,y:-84.358625},
{x:45.811827,y:-88.060765},
{x:45.753092,y:-87.082642},
{x:45.751175,y:-87.079487},
{x:45.836608,y:-87.037165},
{x:46.500122,y:-87.680272},
{x:46.547483,y:-87.459916},
{x:46.487759,y:-87.34861},
{x:46.545495,y:-87.418521},
{x:45.108411,y:-87.618115},
{x:46.303042,y:-85.507968},
{x:45.787656,y:-87.915857},
{x:47.110366,y:-88.590754},
{x:46.092118,y:-88.632749},
{x:46.462837,y:-90.164947},
{x:41.643518,y:-93.507105},
{x:41.658879,y:-93.496762},
{x:42.021878,y:-93.610918},
{x:42.023342,y:-93.668627},
{x:41.703801,y:-93.621033},
{x:41.70427,y:-93.581865},
{x:41.404395,y:-94.990673},
{x:41.72805,y:-93.599553},
{x:42.042454,y:-93.878847},
{x:41.695303,y:-93.247825},
{x:41.687987,y:-93.779302},
{x:41.740987,y:-92.72694},
{x:41.372116,y:-93.558963},
{x:42.521097,y:-93.262308},
{x:41.660138,y:-93.737379},
{x:41.30743,y:-93.110151},
{x:42.005481,y:-92.911545},
{x:42.010626,y:-93.435618},
{x:41.680536,y:-92.998217},
{x:41.699772,y:-93.04468},
{x:41.496254,y:-93.67503},
{x:41.028638,y:-93.782581},
{x:41.397309,y:-92.902433},
{x:41.831073,y:-94.107479},
{x:42.186916,y:-93.574114},
{x:41.49554,y:-94.317481},
{x:41.614424,y:-93.868218},
{x:41.558467,y:-93.773823},
{x:41.58956,y:-93.809709},
{x:41.595345,y:-93.736714},
{x:41.546246,y:-93.644652},
{x:41.525829,y:-93.623975},
{x:41.628199,y:-93.698284},
{x:41.603643,y:-93.658786},
{x:41.583232,y:-93.700913},
{x:41.599117,y:-93.622978},
{x:41.601266,y:-93.626649},
{x:41.558774,y:-93.62524},
{x:41.594924,y:-93.59782},
{x:41.627324,y:-93.601428},
{x:41.600857,y:-93.569036},
{x:41.598559,y:-93.506183},
{x:41.627462,y:-93.552275},
{x:41.557744,y:-93.597541},
{x:41.648184,y:-93.696622},
{x:41.625477,y:-93.735916},
{x:41.645969,y:-93.770821},
{x:41.601013,y:-93.771741},
{x:43.132119,y:-93.201971},
{x:43.14785,y:-93.227002},
{x:43.146436,y:-93.35994},
{x:42.741601,y:-93.201873},
{x:42.486527,y:-94.207797},
{x:42.500807,y:-94.161548},
{x:43.080557,y:-94.236678},
{x:43.112508,y:-94.668387},
{x:42.647046,y:-95.197477},
{x:42.45342,y:-93.817044},
{x:42.484641,y:-92.442158},
{x:42.537312,y:-92.452509},
{x:42.514437,y:-92.446276},
{x:43.062215,y:-92.674821},
{x:42.467891,y:-91.917443},
{x:43.067473,y:-92.327534},
{x:42.677652,y:-91.91149},
{x:42.713566,y:-92.474982},
{x:42.50346,y:-92.399626},
{x:42.469863,y:-92.322077},
{x:42.45963,y:-92.357508},
{x:42.531059,y:-92.336844},
{x:42.459198,y:-92.250483},
{x:41.049498,y:-94.369236},
{x:42.768098,y:-95.552166},
{x:42.781245,y:-96.182938},
{x:42.02779,y:-96.126264},
{x:42.496925,y:-96.378737},
{x:42.504121,y:-96.418585},
{x:42.523697,y:-96.417769},
{x:42.452447,y:-96.333222},
{x:42.47104,y:-96.354279},
{x:42.531753,y:-96.37471},
{x:42.433914,y:-96.3725},
{x:43.185589,y:-95.816067},
{x:43.090816,y:-96.174637},
{x:43.127266,y:-95.1548},
{x:43.398936,y:-94.81045},
{x:43.387731,y:-95.126244},
{x:42.071492,y:-94.876722},
{x:42.021354,y:-95.368013},
{x:41.231261,y:-95.850246},
{x:41.261549,y:-95.868113},
{x:41.245986,y:-95.825931},
{x:41.037583,y:-95.749112},
{x:41.551214,y:-95.911035},
{x:41.018057,y:-95.2333},
{x:41.496409,y:-95.452182},
{x:40.761948,y:-95.385553},
{x:40.726708,y:-95.037104},
{x:42.499857,y:-90.664777},
{x:42.510133,y:-90.741918},
{x:42.504347,y:-90.721773},
{x:42.45903,y:-90.674188},
{x:42.470894,y:-91.115072},
{x:42.068482,y:-90.679053},
{x:43.291615,y:-91.753967},
{x:43.290721,y:-91.796785},
{x:42.104376,y:-91.266227},
{x:42.172763,y:-91.777465},
{x:41.650111,y:-91.539565},
{x:41.637052,y:-91.565039},
{x:41.69544,y:-91.609526},
{x:41.753469,y:-91.607013},
{x:41.677971,y:-91.566566},
{x:41.643516,y:-91.50895},
{x:42.035183,y:-91.552975},
{x:42.031629,y:-91.6104},
{x:42.168217,y:-92.036851},
{x:41.296957,y:-91.693544},
{x:41.662413,y:-91.3474},
{x:41.68937,y:-92.006601},
{x:42.035759,y:-91.655877},
{x:41.988977,y:-91.651901},
{x:41.891368,y:-91.674068},
{x:41.963981,y:-91.706931},
{x:41.943473,y:-91.677217},
{x:42.030877,y:-91.70081},
{x:41.049729,y:-92.411752},
{x:41.00505,y:-92.425787},
{x:40.742158,y:-92.86882},
{x:41.006894,y:-91.98284},
{x:41.29667,y:-92.652484},
{x:40.799954,y:-91.142242},
{x:40.82671,y:-91.141903},
{x:40.626246,y:-91.35581},
{x:40.403443,y:-91.390549},
{x:40.974659,y:-91.538638},
{x:41.574735,y:-90.501661},
{x:41.539167,y:-90.521046},
{x:41.52603,y:-90.499043},
{x:41.816608,y:-90.228279},
{x:41.852053,y:-90.186426},
{x:41.826159,y:-90.556014},
{x:41.654337,y:-90.575129},
{x:41.586978,y:-90.362931},
{x:41.451508,y:-91.031123},
{x:41.61357,y:-90.782528},
{x:41.556478,y:-90.549751},
{x:41.538646,y:-90.621625},
{x:41.571435,y:-90.572311},
{x:41.595756,y:-90.615376},
{x:41.560435,y:-90.607541},
{x:43.499252,y:-87.83446},
{x:43.033958,y:-88.106159},
{x:43.284912,y:-87.985838},
{x:44.019973,y:-88.153635},
{x:43.053261,y:-88.374843},
{x:43.470799,y:-87.941839},
{x:43.22137,y:-88.106898},
{x:43.193492,y:-88.12743},
{x:43.320717,y:-87.922466},
{x:43.309236,y:-87.9586},
{x:43.318092,y:-88.35375},
{x:43.323773,y:-88.184589},
{x:43.497398,y:-88.21683},
{x:43.91956,y:-88.023176},
{x:43.035701,y:-88.145113},
{x:43.589295,y:-88.432756},
{x:43.493559,y:-88.559647},
{x:43.118469,y:-88.103764},
{x:43.168407,y:-88.103175},
{x:43.107287,y:-88.483398},
{x:43.093571,y:-88.274575},
{x:43.042471,y:-88.25978},
{x:43.083926,y:-88.23065},
{x:43.746823,y:-87.955752},
{x:43.408473,y:-87.87082},
{x:43.282088,y:-88.197759},
{x:43.385195,y:-87.931121},
{x:43.716964,y:-87.759182},
{x:43.728326,y:-87.732324},
{x:43.757022,y:-87.753191},
{x:43.773585,y:-87.736832},
{x:43.747561,y:-87.820897},
{x:43.13635,y:-88.243323},
{x:43.222361,y:-87.923336},
{x:43.187309,y:-88.729013},
{x:43.090488,y:-88.759985},
{x:43.405406,y:-88.181755},
{x:43.427545,y:-88.20204},
{x:42.913558,y:-88.218066},
{x:42.679347,y:-88.274012},
{x:42.932281,y:-87.861549},
{x:42.627057,y:-88.626901},
{x:42.791554,y:-88.391173},
{x:42.69215,y:-88.542233},
{x:42.935566,y:-88.047668},
{x:42.915579,y:-88.007497},
{x:42.55688,y:-87.825703},
{x:42.568067,y:-87.955448},
{x:42.565352,y:-87.858351},
{x:42.57068,y:-87.887317},
{x:42.603957,y:-87.876357},
{x:42.588678,y:-87.856818},
{x:42.623514,y:-87.84659},
{x:42.591143,y:-88.411478},
{x:42.590651,y:-88.429896},
{x:42.875679,y:-88.338079},
{x:42.901849,y:-88.150871},
{x:42.993676,y:-88.127364},
{x:42.977932,y:-88.107903},
{x:42.872913,y:-87.932878},
{x:42.884607,y:-87.911497},
{x:42.522376,y:-87.946805},
{x:42.567657,y:-88.118026},
{x:42.903619,y:-87.865267},
{x:42.723872,y:-87.950672},
{x:42.68055,y:-88.050556},
{x:42.766165,y:-88.19821},
{x:43.032259,y:-88.178494},
{x:43.019769,y:-88.202026},
{x:42.987245,y:-88.236738},
{x:43.0228,y:-88.28512},
{x:42.834554,y:-88.749592},
{x:43.060327,y:-87.889795},
{x:43.009368,y:-87.929245},
{x:43.022595,y:-87.945844},
{x:43.023946,y:-87.911694},
{x:43.019821,y:-87.932614},
{x:43.060716,y:-87.923305},
{x:42.997549,y:-87.898492},
{x:42.958776,y:-87.913243},
{x:43.046527,y:-87.957215},
{x:43.183548,y:-87.963085},
{x:43.147914,y:-87.955954},
{x:43.111643,y:-87.950434},
{x:43.089645,y:-87.905311},
{x:43.060596,y:-87.995602},
{x:43.035841,y:-87.992572},
{x:43.016098,y:-88.015804},
{x:43.01387,y:-88.046179},
{x:43.020159,y:-87.969037},
{x:42.988038,y:-87.919835},
{x:43.090669,y:-88.005311},
{x:43.091859,y:-87.979374},
{x:43.0902,y:-87.947511},
{x:43.175945,y:-87.913377},
{x:43.114218,y:-87.91628},
{x:43.105249,y:-88.014009},
{x:43.118862,y:-87.984823},
{x:43.104381,y:-87.97473},
{x:42.987331,y:-88.00806},
{x:42.953402,y:-88.007777},
{x:42.938071,y:-87.948628},
{x:42.981475,y:-87.947341},
{x:42.967014,y:-87.949299},
{x:42.930967,y:-87.929039},
{x:43.086512,y:-88.065739},
{x:43.075236,y:-88.0484},
{x:43.147551,y:-88.004807},
{x:43.178346,y:-88.011099},
{x:43.116974,y:-88.053464},
{x:43.060392,y:-88.06597},
{x:42.992676,y:-88.047957},
{x:42.960686,y:-88.047018},
{x:43.038475,y:-87.944477},
{x:42.787031,y:-87.809663},
{x:42.756584,y:-87.799467},
{x:42.732113,y:-87.799332},
{x:42.703697,y:-87.826356},
{x:42.719796,y:-87.862552},
{x:42.702012,y:-87.853472},
{x:42.687811,y:-87.836239},
{x:42.525543,y:-88.982694},
{x:42.54301,y:-89.01114},
{x:42.517168,y:-89.063455},
{x:42.496523,y:-89.035639},
{x:42.609672,y:-89.376236},
{x:43.087251,y:-89.209249},
{x:42.667361,y:-90.127818},
{x:43.249911,y:-89.375437},
{x:42.972153,y:-90.132254},
{x:42.835858,y:-89.025694},
{x:42.779156,y:-89.271485},
{x:42.930411,y:-88.83745},
{x:42.679691,y:-89.052117},
{x:42.717687,y:-88.989082},
{x:42.704061,y:-89.006395},
{x:42.650133,y:-89.030075},
{x:42.990061,y:-88.809713},
{x:43.090212,y:-88.908613},
{x:43.017722,y:-89.29983},
{x:43.103935,y:-89.487308},
{x:43.090475,y:-89.525145},
{x:42.603876,y:-89.653421},
{x:43.009614,y:-89.722475},
{x:42.942337,y:-89.383748},
{x:43.320278,y:-90.374878},
{x:43.271693,y:-89.731013},
{x:42.917976,y:-89.237977},
{x:43.166587,y:-89.267058},
{x:43.181336,y:-89.206604},
{x:43.187946,y:-89.229947},
{x:43.192385,y:-89.466203},
{x:43.194084,y:-89.337458},
{x:43.102039,y:-89.346019},
{x:43.09898,y:-89.314838},
{x:43.125385,y:-89.324417},
{x:43.128479,y:-89.358829},
{x:43.128761,y:-89.308611},
{x:43.075914,y:-89.454808},
{x:43.032319,y:-89.457157},
{x:43.029856,y:-89.409507},
{x:43.046622,y:-89.34907},
{x:43.039516,y:-89.394539},
{x:43.068011,y:-89.404529},
{x:43.06676,y:-89.32652},
{x:42.993843,y:-89.523818},
{x:43.057109,y:-89.501722},
{x:42.835527,y:-90.709525},
{x:42.733475,y:-90.439711},
{x:43.045081,y:-91.140425},
{x:43.567802,y:-89.470661},
{x:43.952564,y:-89.818336},
{x:43.574759,y:-89.779485},
{x:43.4725,y:-89.768373},
{x:43.456973,y:-88.81682},
{x:43.475656,y:-88.823259},
{x:43.346443,y:-89.030587},
{x:43.796049,y:-90.061847},
{x:43.884834,y:-90.143692},
{x:43.393915,y:-89.462477},
{x:43.531942,y:-89.986894},
{x:43.633641,y:-88.717614},
{x:43.886633,y:-89.484506},
{x:43.587425,y:-89.813545},
{x:43.624329,y:-89.786215},
{x:45.297819,y:-92.366188},
{x:44.940416,y:-92.373608},
{x:44.731673,y:-92.477871},
{x:44.961536,y:-92.722217},
{x:45.124911,y:-92.538301},
{x:44.875923,y:-92.62429},
{x:44.96305,y:-92.560506},
{x:45.398234,y:-92.605323},
{x:44.765362,y:-88.048304},
{x:44.182939,y:-88.072988},
{x:45.225026,y:-87.997498},
{x:44.428873,y:-88.055788},
{x:44.452188,y:-88.086754},
{x:44.456787,y:-87.990613},
{x:44.283649,y:-88.26683},
{x:44.258967,y:-88.347442},
{x:44.296378,y:-88.31226},
{x:45.080501,y:-87.660944},
{x:44.893451,y:-87.884812},
{x:44.656774,y:-88.219998},
{x:44.500124,y:-88.329803},
{x:44.781681,y:-88.586659},
{x:44.347431,y:-87.838667},
{x:44.545734,y:-87.704015},
{x:44.083625,y:-87.691753},
{x:44.072588,y:-87.715232},
{x:44.847625,y:-87.363577},
{x:44.821788,y:-87.403652},
{x:44.144751,y:-87.567524},
{x:44.486037,y:-88.020084},
{x:44.525433,y:-87.991296},
{x:44.505019,y:-87.984672},
{x:44.487053,y:-87.965894},
{x:44.529595,y:-88.060622},
{x:44.523298,y:-88.100688},
{x:44.563153,y:-88.073556},
{x:44.488132,y:-88.066837},
{x:44.465661,y:-87.946825},
{x:44.49247,y:-88.106957},
{x:44.959318,y:-89.661179},
{x:44.987201,y:-89.651976},
{x:44.914109,y:-89.649838},
{x:44.95664,y:-89.626358},
{x:44.936701,y:-90.317296},
{x:45.160907,y:-89.14761},
{x:44.940677,y:-89.841397},
{x:44.677373,y:-90.165611},
{x:45.140187,y:-90.332939},
{x:45.178453,y:-89.651625},
{x:44.785827,y:-89.68707},
{x:44.316063,y:-89.889562},
{x:44.495316,y:-89.516639},
{x:44.453112,y:-89.543134},
{x:44.902521,y:-89.608245},
{x:44.535935,y:-89.574524},
{x:44.523014,y:-89.506684},
{x:45.488087,y:-89.730004},
{x:44.375716,y:-89.817667},
{x:44.832371,y:-89.169534},
{x:45.637041,y:-89.410804},
{x:45.914507,y:-89.253035},
{x:45.886455,y:-89.704777},
{x:45.921544,y:-90.452021},
{x:43.858422,y:-91.240701},
{x:43.782575,y:-91.219701},
{x:44.249572,y:-91.472601},
{x:44.293813,y:-90.829492},
{x:43.923899,y:-91.244957},
{x:43.872256,y:-91.195033},
{x:43.938201,y:-90.821412},
{x:44.019432,y:-90.504261},
{x:43.573043,y:-90.89},
{x:44.802458,y:-91.469387},
{x:44.789961,y:-91.519467},
{x:44.77742,y:-91.433057},
{x:44.842696,y:-91.595457},
{x:45.099327,y:-91.499521},
{x:44.885387,y:-91.418619},
{x:44.913985,y:-91.389702},
{x:44.633179,y:-91.951646},
{x:44.900372,y:-91.932472},
{x:44.578624,y:-91.208726},
{x:44.949724,y:-90.938483},
{x:44.953268,y:-90.800835},
{x:45.82307,y:-91.890216},
{x:46.588607,y:-90.891991},
{x:45.401101,y:-91.852951},
{x:46.012484,y:-91.483236},
{x:45.465671,y:-91.112794},
{x:45.480835,y:-91.733754},
{x:46.713741,y:-92.103281},
{x:46.707157,y:-92.047404},
{x:45.394492,y:-92.157355},
{x:44.040726,y:-88.542908},
{x:44.005211,y:-88.581439},
{x:44.031421,y:-88.588768},
{x:44.294284,y:-88.374509},
{x:44.287992,y:-88.416306},
{x:44.261151,y:-88.456323},
{x:44.243438,y:-88.358867},
{x:43.968786,y:-88.955227},
{x:44.610074,y:-88.763113},
{x:43.783922,y:-88.475078},
{x:43.763684,y:-88.466201},
{x:43.78432,y:-88.410941},
{x:44.286196,y:-88.506851},
{x:44.204396,y:-88.446643},
{x:44.236699,y:-88.423205},
{x:44.177263,y:-88.48573},
{x:44.157289,y:-88.463345},
{x:44.40431,y:-88.743153},
{x:44.039948,y:-88.721035},
{x:43.853294,y:-88.860209},
{x:44.356484,y:-89.10363},
{x:44.070992,y:-89.271449},
{x:45.57228,y:-93.213444},
{x:44.490876,y:-92.907765},
{x:45.377063,y:-92.881511},
{x:45.182597,y:-93.11052},
{x:45.140766,y:-93.163307},
{x:44.829548,y:-92.958733},
{x:44.294353,y:-93.277147},
{x:44.641365,y:-93.162142},
{x:45.278702,y:-93.001078},
{x:44.737717,y:-92.879013},
{x:46.011732,y:-92.92735},
{x:45.164706,y:-93.024794},
{x:45.492632,y:-93.238146},
{x:44.714332,y:-93.289248},
{x:44.692217,y:-93.216698},
{x:44.657222,y:-93.254212},
{x:44.644415,y:-93.292595},
{x:44.172072,y:-93.260965},
{x:45.871887,y:-93.289599},
{x:45.510194,y:-92.989878},
{x:44.448776,y:-93.170044},
{x:44.09586,y:-93.252144},
{x:45.845614,y:-92.973948},
{x:44.566833,y:-92.575394},
{x:44.731148,y:-93.132095},
{x:45.388375,y:-93.368703},
{x:44.834521,y:-93.042676},
{x:44.865124,y:-93.041855},
{x:45.042656,y:-92.839404},
{x:45.035175,y:-92.811516},
{x:45.336106,y:-93.00857},
{x:44.930988,y:-93.122826},
{x:44.955513,y:-93.109846},
{x:44.955451,y:-93.165322},
{x:44.977264,y:-93.042625},
{x:45.029664,y:-93.025833},
{x:45.010558,y:-93.024837},
{x:45.0499,y:-93.013759},
{x:45.091209,y:-93.006172},
{x:44.884718,y:-93.210306},
{x:45.050705,y:-93.155884},
{x:45.095806,y:-93.192871},
{x:45.067024,y:-93.219109},
{x:45.037228,y:-93.19725},
{x:45.003555,y:-93.167374},
{x:45.009571,y:-93.105295},
{x:44.961967,y:-93.191406},
{x:44.903873,y:-93.164169},
{x:44.987706,y:-93.106209},
{x:44.907799,y:-93.081352},
{x:44.96294,y:-92.985176},
{x:44.950268,y:-93.022446},
{x:44.882973,y:-93.124191},
{x:44.791851,y:-93.218506},
{x:44.846863,y:-93.124132},
{x:44.819523,y:-93.207298},
{x:44.829915,y:-93.165367},
{x:44.805071,y:-93.145336},
{x:44.732895,y:-93.220963},
{x:44.940925,y:-92.907238},
{x:44.924679,y:-92.971924},
{x:45.080032,y:-93.054837},
{x:44.997081,y:-92.948547},
{x:45.262588,y:-94.128097},
{x:45.207561,y:-93.406586},
{x:45.246877,y:-93.304803},
{x:45.219998,y:-93.356841},
{x:45.384453,y:-93.862587},
{x:45.332153,y:-93.744745},
{x:45.127623,y:-93.48906},
{x:45.178861,y:-93.871411},
{x:45.177395,y:-93.393044},
{x:44.841796,y:-93.53951},
{x:44.861148,y:-93.523631},
{x:44.834951,y:-93.600004},
{x:44.795586,y:-93.591438},
{x:45.036358,y:-93.767379},
{x:45.310227,y:-93.556415},
{x:44.902279,y:-93.558319},
{x:44.774736,y:-93.317054},
{x:44.745579,y:-93.280754},
{x:45.047102,y:-93.54308},
{x:44.91967,y:-93.426566},
{x:44.853519,y:-93.429887},
{x:44.875241,y:-94.376622},
{x:44.669981,y:-93.637494},
{x:45.121777,y:-94.505333},
{x:44.988696,y:-93.584953},
{x:45.296971,y:-93.7996},
{x:44.770431,y:-93.919092},
{x:45.105493,y:-93.452528},
{x:45.067196,y:-93.443802},
{x:45.557363,y:-93.583845},
{x:44.705126,y:-93.434547},
{x:45.19577,y:-93.549782},
{x:45.210898,y:-93.65689},
{x:44.747085,y:-93.329971},
{x:44.784515,y:-93.470307},
{x:44.799477,y:-93.508354},
{x:44.838666,y:-93.800745},
{x:44.970791,y:-93.494926},
{x:44.958971,y:-93.278318},
{x:44.948818,y:-93.226677},
{x:44.928337,y:-93.223999},
{x:44.949131,y:-93.298035},
{x:44.948551,y:-93.273651},
{x:44.99978,y:-93.292348},
{x:45.03642,y:-93.288298},
{x:44.999115,y:-93.263824},
{x:45.002743,y:-93.228333},
{x:44.980247,y:-93.234558},
{x:44.942429,y:-93.359924},
{x:44.931822,y:-93.347077},
{x:44.857845,y:-93.278999},
{x:44.830379,y:-93.287878},
{x:45.052124,y:-93.246895},
{x:45.031656,y:-93.338442},
{x:44.881997,y:-93.286824},
{x:45.007046,y:-93.39859},
{x:44.987469,y:-93.379654},
{x:45.092916,y:-93.377325},
{x:45.051296,y:-93.360741},
{x:45.033201,y:-93.380941},
{x:45.055946,y:-93.322456},
{x:44.859735,y:-93.308123},
{x:45.071268,y:-93.265443},
{x:45.115535,y:-93.264625},
{x:45.115513,y:-93.242256},
{x:45.199334,y:-93.346111},
{x:45.174244,y:-93.346214},
{x:45.195642,y:-93.235838},
{x:44.882198,y:-93.319618},
{x:44.810814,y:-93.33094},
{x:44.83585,y:-93.39695},
{x:44.988384,y:-93.423522},
{x:45.00977,y:-93.452425},
{x:45.13302,y:-93.354819},
{x:45.109202,y:-93.300162},
{x:45.109276,y:-93.396111},
{x:45.18411,y:-93.314442},
{x:45.175781,y:-93.277122},
{x:45.156712,y:-93.234117},
{x:47.025036,y:-91.682035},
{x:47.482197,y:-92.894396},
{x:46.711613,y:-92.461832},
{x:47.848815,y:-92.687123},
{x:47.21809,y:-93.52933},
{x:47.400019,y:-92.923678},
{x:46.435036,y:-92.748936},
{x:47.511301,y:-92.551568},
{x:46.740438,y:-92.165976},
{x:46.725079,y:-92.218835},
{x:46.822545,y:-92.184797},
{x:46.799939,y:-92.129755},
{x:46.80517,y:-92.068879},
{x:44.080288,y:-92.504994},
{x:44.046527,y:-92.519742},
{x:44.005312,y:-92.474869},
{x:43.954723,y:-92.470547},
{x:44.021908,y:-92.485556},
{x:44.03476,y:-92.462545},
{x:43.678434,y:-92.992517},
{x:43.666332,y:-92.986843},
{x:44.030236,y:-92.630156},
{x:44.051985,y:-91.637889},
{x:44.028217,y:-91.619909},
{x:44.051136,y:-91.68148},
{x:44.279627,y:-92.671434},
{x:44.17021,y:-93.947316},
{x:44.190093,y:-94.014772},
{x:44.166579,y:-93.971351},
{x:43.656843,y:-93.317394},
{x:43.686463,y:-93.358436},
{x:43.650281,y:-93.362167},
{x:44.622328,y:-93.745367},
{x:43.652787,y:-94.093059},
{x:43.677005,y:-94.448684},
{x:44.543066,y:-93.562282},
{x:44.296165,y:-94.443954},
{x:43.98123,y:-94.631499},
{x:44.339352,y:-93.949295},
{x:44.093284,y:-93.50618},
{x:43.862787,y:-95.114545},
{x:43.641296,y:-96.212329},
{x:43.994326,y:-96.306877},
{x:43.63261,y:-95.594379},
{x:45.116539,y:-95.042244},
{x:44.450108,y:-95.773636},
{x:44.949501,y:-95.705732},
{x:45.591782,y:-95.918913},
{x:44.541858,y:-95.09782},
{x:45.494502,y:-94.148656},
{x:45.552959,y:-94.183289},
{x:45.569405,y:-94.140198},
{x:45.90016,y:-95.365698},
{x:45.846783,y:-95.389473},
{x:45.605998,y:-94.451999},
{x:45.664506,y:-93.919535},
{x:45.974716,y:-94.338144},
{x:45.970535,y:-94.864803},
{x:45.374839,y:-94.731204},
{x:45.756685,y:-94.213163},
{x:45.567996,y:-94.301187},
{x:45.595139,y:-94.192464},
{x:45.723084,y:-94.950467},
{x:45.5494,y:-94.219421},
{x:45.561848,y:-94.228541},
{x:46.360511,y:-94.181805},
{x:46.356346,y:-94.2182},
{x:46.341928,y:-94.249456},
{x:46.534027,y:-93.697253},
{x:46.290683,y:-93.825999},
{x:46.92177,y:-95.058981},
{x:46.443105,y:-95.136742},
{x:46.815208,y:-95.837667},
{x:46.28906,y:-96.110192},
{x:46.875778,y:-96.730621},
{x:46.87209,y:-96.767348},
{x:46.584988,y:-95.575384},
{x:47.464501,y:-94.872898},
{x:47.492622,y:-94.898003},
{x:48.712485,y:-94.613425},
{x:48.587704,y:-93.403273},
{x:48.11417,y:-96.156119},
{x:47.78958,y:-96.612098},
{x:47.937083,y:-97.019222},
{x:43.60519,y:-96.571382},
{x:44.311841,y:-96.777973},
{x:44.0074,y:-97.117808},
{x:42.525474,y:-96.496951},
{x:42.787304,y:-96.929699},
{x:42.894701,y:-97.398286},
{x:43.546382,y:-96.714683},
{x:43.515245,y:-96.774868},
{x:43.528929,y:-96.687043},
{x:43.600945,y:-96.71232},
{x:43.544734,y:-96.759311},
{x:43.515547,y:-96.73074},
{x:43.498415,y:-96.771771},
{x:44.890445,y:-97.111078},
{x:44.891328,y:-97.059072},
{x:43.726072,y:-98.024923},
{x:43.695134,y:-98.016173},
{x:43.809138,y:-99.331829},
{x:44.344513,y:-98.214589},
{x:45.469987,y:-98.491434},
{x:45.45978,y:-98.463756},
{x:44.371435,y:-100.361031},
{x:43.377045,y:-99.849949},
{x:44.097987,y:-103.198178},
{x:44.061666,y:-103.231191},
{x:44.087953,y:-103.197848},
{x:44.081506,y:-103.256705},
{x:44.095735,y:-103.150143},
{x:44.500866,y:-103.860557},
{x:44.419555,y:-103.528731},
{x:46.265034,y:-96.617942},
{x:46.876227,y:-96.890249},
{x:46.904276,y:-96.797301},
{x:46.851607,y:-96.862735},
{x:46.843029,y:-96.798043},
{x:46.875026,y:-96.792908},
{x:46.831704,y:-96.844821},
{x:46.84242,y:-96.882505},
{x:46.86552,y:-96.845103},
{x:47.934309,y:-97.091917},
{x:47.910125,y:-97.044522},
{x:47.890163,y:-97.07042},
{x:48.10559,y:-98.866271},
{x:46.887832,y:-98.720958},
{x:46.829371,y:-100.726766},
{x:46.830609,y:-100.817243},
{x:46.835748,y:-100.771879},
{x:46.805091,y:-100.7821},
{x:46.795605,y:-100.792459},
{x:46.821028,y:-100.856991},
{x:46.894605,y:-102.78822},
{x:48.229759,y:-101.294876},
{x:48.201888,y:-101.294592},
{x:48.208678,y:-101.312762},
{x:48.166193,y:-103.625152},
{x:45.643975,y:-109.247617},
{x:45.741991,y:-107.611652},
{x:45.665677,y:-108.768258},
{x:45.642879,y:-110.573297},
{x:45.816492,y:-108.416438},
{x:45.754877,y:-108.536791},
{x:45.752371,y:-108.616475},
{x:45.78461,y:-108.590751},
{x:45.783746,y:-108.542685},
{x:45.770084,y:-108.575258},
{x:45.828848,y:-108.471795},
{x:45.805796,y:-108.475139},
{x:48.093997,y:-105.640918},
{x:48.196145,y:-106.635279},
{x:47.702066,y:-104.165845},
{x:46.397784,y:-105.822118},
{x:47.484992,y:-111.338705},
{x:47.522717,y:-111.307544},
{x:47.494479,y:-111.277213},
{x:48.639858,y:-112.339857},
{x:47.060839,y:-109.432474},
{x:48.555026,y:-109.689773},
{x:46.616011,y:-112.021316},
{x:46.590831,y:-112.00644},
{x:46.004973,y:-112.611178},
{x:45.980766,y:-112.50879},
{x:46.126547,y:-112.934124},
{x:45.772416,y:-111.184186},
{x:45.694677,y:-111.042187},
{x:45.698624,y:-111.049628},
{x:45.679823,y:-111.057551},
{x:46.412159,y:-112.72599},
{x:45.22445,y:-112.629562},
{x:44.657438,y:-111.100144},
{x:46.84006,y:-114.02964},
{x:46.869814,y:-113.984571},
{x:46.954773,y:-114.132817},
{x:46.878218,y:-114.020579},
{x:46.907262,y:-114.037141},
{x:46.253881,y:-114.157066},
{x:46.757812,y:-114.081539},
{x:47.692278,y:-114.128259},
{x:47.518848,y:-114.09623},
{x:48.217487,y:-114.27656},
{x:48.233617,y:-114.33128},
{x:48.199949,y:-114.332432},
{x:48.386698,y:-115.55174},
{x:48.395983,y:-114.334713},
{x:42.471287,y:-88.094099},
{x:42.138681,y:-87.981313},
{x:42.111584,y:-87.981093},
{x:42.050962,y:-87.978252},
{x:42.02176,y:-87.957224},
{x:42.007061,y:-88.045227},
{x:42.003974,y:-88.004602},
{x:42.004575,y:-87.960184},
{x:42.078584,y:-88.019197},
{x:42.056067,y:-88.001939},
{x:42.161183,y:-88.139444},
{x:42.248319,y:-88.347179},
{x:42.217447,y:-88.255791},
{x:42.247841,y:-88.287548},
{x:42.225547,y:-88.311407},
{x:42.199085,y:-87.86155},
{x:42.154182,y:-87.837123},
{x:42.047169,y:-87.937794},
{x:42.045303,y:-87.885418},
{x:42.023469,y:-87.897032},
{x:41.990426,y:-87.860457},
{x:41.998498,y:-87.884039},
{x:42.394061,y:-88.184402},
{x:42.194489,y:-88.210922},
{x:42.064776,y:-87.800235},
{x:42.104251,y:-87.847389},
{x:42.339101,y:-88.004078},
{x:42.367058,y:-88.043135},
{x:42.386051,y:-87.959565},
{x:42.377897,y:-87.904095},
{x:42.377928,y:-87.939638},
{x:42.409119,y:-88.613018},
{x:42.188675,y:-87.801888},
{x:42.215451,y:-87.819116},
{x:42.282548,y:-88.208209},
{x:42.304028,y:-87.876432},
{x:42.281953,y:-87.879644},
{x:42.253138,y:-87.901792},
{x:42.415139,y:-88.071543},
{x:42.407133,y:-88.02775},
{x:42.198725,y:-88.058688},
{x:42.187124,y:-88.10223},
{x:42.305104,y:-87.980919},
{x:42.297577,y:-87.955959},
{x:42.325822,y:-88.273719},
{x:42.346793,y:-88.284252},
{x:42.354431,y:-88.266938},
{x:42.040593,y:-87.783945},
{x:42.08171,y:-87.938508},
{x:42.255606,y:-88.021926},
{x:42.240558,y:-87.994897},
{x:42.265567,y:-88.003583},
{x:42.239564,y:-87.945101},
{x:42.242847,y:-87.949891},
{x:42.149181,y:-87.800335},
{x:42.319025,y:-87.865535},
{x:42.12236,y:-88.050235},
{x:42.137213,y:-88.01983},
{x:42.025906,y:-87.853257},
{x:42.198282,y:-87.932837},
{x:42.109156,y:-87.890836},
{x:42.480561,y:-88.306494},
{x:42.323592,y:-88.163721},
{x:42.379974,y:-88.064791},
{x:42.094828,y:-88.006031},
{x:42.041067,y:-87.748655},
{x:42.012492,y:-87.762205},
{x:42.436493,y:-88.245141},
{x:42.429592,y:-87.937395},
{x:42.258071,y:-88.15193},
{x:42.348228,y:-87.881804},
{x:42.345041,y:-87.898475},
{x:42.388076,y:-87.85483},
{x:42.340259,y:-87.89548},
{x:42.370008,y:-87.881872},
{x:42.364108,y:-87.830399},
{x:42.318852,y:-87.835305},
{x:42.173493,y:-87.985775},
{x:42.152611,y:-87.950563},
{x:42.140083,y:-87.9112},
{x:42.114866,y:-87.741713},
{x:42.313096,y:-88.435271},
{x:42.429313,y:-87.826335},
{x:42.458295,y:-87.825738},
{x:41.936994,y:-88.000856},
{x:41.905646,y:-87.998325},
{x:42.174477,y:-88.334654},
{x:42.164014,y:-88.283232},
{x:41.977741,y:-88.206575},
{x:42.007011,y:-88.144102},
{x:41.882957,y:-87.864113},
{x:41.972683,y:-87.959953},
{x:41.960618,y:-87.944926},
{x:42.013376,y:-88.185067},
{x:41.958284,y:-88.080554},
{x:41.951089,y:-88.119896},
{x:42.123511,y:-88.336218},
{x:42.113227,y:-88.25833},
{x:41.931531,y:-88.768113},
{x:41.900737,y:-88.739118},
{x:41.929065,y:-88.747632},
{x:42.094591,y:-88.28764},
{x:41.902504,y:-88.471612},
{x:42.045882,y:-88.275459},
{x:42.019713,y:-88.268928},
{x:42.061167,y:-88.314983},
{x:42.035018,y:-88.314138},
{x:42.026956,y:-88.33794},
{x:41.927692,y:-87.940201},
{x:41.869212,y:-87.934982},
{x:41.879081,y:-87.813542},
{x:41.926749,y:-87.883882},
{x:41.976123,y:-88.129546},
{x:41.868296,y:-88.341254},
{x:41.886955,y:-88.282474},
{x:42.095325,y:-88.683472},
{x:41.858888,y:-88.070553},
{x:41.829811,y:-88.054933},
{x:41.902398,y:-88.081465},
{x:41.937019,y:-88.0767},
{x:42.148807,y:-88.511246},
{x:42.172887,y:-88.428058},
{x:42.151096,y:-88.431841},
{x:41.966954,y:-87.997743},
{x:41.842105,y:-88.010884},
{x:41.9049,y:-88.031654},
{x:41.860686,y:-88.009554},
{x:42.248866,y:-88.610491},
{x:41.864591,y:-87.838843},
{x:41.888353,y:-87.833462},
{x:41.833983,y:-87.897846},
{x:41.850458,y:-87.851813},
{x:41.907925,y:-87.833628},
{x:41.863652,y:-87.883431},
{x:41.889077,y:-87.910952},
{x:41.906697,y:-87.902444},
{x:42.057339,y:-88.13653},
{x:42.047707,y:-88.080293},
{x:41.929703,y:-87.846797},
{x:41.981701,y:-88.07024},
{x:42.049133,y:-88.055655},
{x:42.072966,y:-88.059955},
{x:42.045844,y:-88.037199},
{x:41.912268,y:-88.337848},
{x:41.921891,y:-88.261846},
{x:41.937397,y:-88.399269},
{x:41.9505,y:-87.882932},
{x:41.954182,y:-87.863531},
{x:41.996693,y:-88.316714},
{x:41.981287,y:-88.341448},
{x:41.974392,y:-88.715976},
{x:41.89035,y:-87.969657},
{x:41.915822,y:-88.206803},
{x:41.881838,y:-88.193897},
{x:41.858135,y:-88.14205},
{x:41.832427,y:-88.103936},
{x:41.939538,y:-88.149807},
{x:41.894067,y:-88.102416},
{x:42.017519,y:-88.080439},
{x:42.024455,y:-88.143542},
{x:42.047102,y:-88.143358},
{x:42.019769,y:-87.687685},
{x:42.041391,y:-87.70008},
{x:41.879783,y:-87.777934},
{x:41.891692,y:-87.805432},
{x:41.348482,y:-87.620654},
{x:41.864685,y:-87.803293},
{x:41.825233,y:-87.799507},
{x:41.850084,y:-87.803186},
{x:41.850304,y:-87.784209},
{x:41.554287,y:-88.124034},
{x:41.533402,y:-88.199741},
{x:41.676854,y:-87.681036},
{x:41.265692,y:-88.208465},
{x:41.623109,y:-87.573838},
{x:41.600419,y:-87.54101},
{x:41.454414,y:-88.197394},
{x:41.418255,y:-88.264521},
{x:41.492216,y:-87.556799},
{x:41.528411,y:-87.635274},
{x:41.48389,y:-87.733551},
{x:41.480494,y:-87.639065},
{x:41.28743,y:-88.285855},
{x:41.469385,y:-87.57933},
{x:41.623019,y:-87.628481},
{x:41.112488,y:-88.412294},
{x:41.505579,y:-87.791791},
{x:41.508116,y:-87.848995},
{x:41.622205,y:-87.675127},
{x:41.601135,y:-87.690365},
{x:41.567314,y:-87.636884},
{x:41.557636,y:-87.693309},
{x:41.521346,y:-88.184549},
{x:41.495039,y:-88.167996},
{x:41.527558,y:-88.069392},
{x:41.562559,y:-88.16074},
{x:41.523169,y:-88.119486},
{x:41.57761,y:-88.158116},
{x:41.551829,y:-88.088333},
{x:41.571667,y:-87.559246},
{x:41.658203,y:-88.000448},
{x:41.726639,y:-88.032244},
{x:41.699063,y:-88.067898},
{x:41.596872,y:-88.021792},
{x:41.594154,y:-88.03817},
{x:41.506621,y:-87.714662},
{x:41.644267,y:-87.738599},
{x:41.626951,y:-87.717296},
{x:41.652649,y:-88.079851},
{x:41.597235,y:-88.123613},
{x:41.672127,y:-88.06808},
{x:41.651093,y:-88.124118},
{x:41.455436,y:-88.270712},
{x:41.42754,y:-87.756967},
{x:41.375705,y:-88.422587},
{x:41.484415,y:-87.921651},
{x:41.517831,y:-87.981776},
{x:41.602668,y:-87.75328},
{x:41.726831,y:-87.741474},
{x:41.72006,y:-87.771865},
{x:41.700833,y:-87.739532},
{x:41.734376,y:-87.798351},
{x:41.747994,y:-87.817401},
{x:41.751233,y:-87.79936},
{x:41.719094,y:-87.810009},
{x:41.748651,y:-87.75527},
{x:41.505805,y:-87.676211},
{x:41.626965,y:-87.853216},
{x:41.601941,y:-87.841185},
{x:41.661317,y:-87.793731},
{x:41.6904,y:-87.810008},
{x:41.600047,y:-87.893048},
{x:41.563987,y:-87.888915},
{x:41.324154,y:-87.815364},
{x:41.578756,y:-87.599296},
{x:41.600511,y:-87.597206},
{x:41.601773,y:-87.795949},
{x:41.578181,y:-87.792845},
{x:41.585672,y:-87.719966},
{x:41.297286,y:-88.140603},
{x:41.704364,y:-88.126546},
{x:41.629739,y:-87.93157},
{x:41.714459,y:-88.253968},
{x:41.760955,y:-88.242325},
{x:41.812658,y:-88.278631},
{x:41.758348,y:-88.212705},
{x:41.737502,y:-88.25175},
{x:41.753866,y:-88.300809},
{x:41.781697,y:-88.281165},
{x:41.760291,y:-88.321803},
{x:41.778619,y:-88.315176},
{x:41.766943,y:-88.366105},
{x:41.759522,y:-88.206929},
{x:41.850473,y:-88.309236},
{x:41.804622,y:-88.046794},
{x:41.809285,y:-87.992009},
{x:41.771497,y:-88.042093},
{x:41.751109,y:-88.039479},
{x:41.783574,y:-87.908329},
{x:41.818218,y:-87.926128},
{x:41.847594,y:-87.947569},
{x:41.84747,y:-87.971915},
{x:41.818487,y:-87.870278},
{x:41.786487,y:-87.868133},
{x:41.717484,y:-87.945518},
{x:41.786367,y:-88.071448},
{x:41.820257,y:-87.818355},
{x:41.747104,y:-88.163654},
{x:41.781475,y:-88.178729},
{x:41.749696,y:-88.115586},
{x:41.802884,y:-88.372924},
{x:41.686129,y:-88.381053},
{x:41.718747,y:-88.320115},
{x:41.695816,y:-88.321686},
{x:41.654836,y:-88.202823},
{x:41.561983,y:-88.19956},
{x:41.635171,y:-88.223061},
{x:41.594513,y:-88.202195},
{x:41.662092,y:-88.502866},
{x:41.847259,y:-87.810541},
{x:41.647105,y:-88.597103},
{x:41.774848,y:-88.441696},
{x:41.80157,y:-88.171749},
{x:41.830857,y:-88.201768},
{x:41.773406,y:-87.972777},
{x:41.617857,y:-88.447666},
{x:41.658242,y:-88.444504},
{x:41.752496,y:-87.964866},
{x:41.790738,y:-88.204931},
{x:41.798117,y:-88.11913},
{x:41.705594,y:-88.204846},
{x:41.887104,y:-87.623222},
{x:41.885816,y:-87.631667},
{x:41.884627,y:-87.62929},
{x:41.883662,y:-87.626041},
{x:41.879632,y:-87.626349},
{x:41.88095,y:-87.630609},
{x:41.879502,y:-87.633621},
{x:41.877966,y:-87.631736},
{x:41.878745,y:-87.639122},
{x:41.878,y:-87.635104},
{x:41.885668,y:-87.661942},
{x:41.858474,y:-87.660954},
{x:41.83897,y:-87.64679},
{x:41.817907,y:-87.665747},
{x:41.808959,y:-87.662271},
{x:41.910999,y:-87.634567},
{x:41.89287,y:-87.631553},
{x:41.891668,y:-87.608894},
{x:41.896974,y:-87.62766},
{x:41.893979,y:-87.617628},
{x:41.881901,y:-87.686023},
{x:41.925619,y:-87.649255},
{x:41.924743,y:-87.671935},
{x:41.930713,y:-87.643502},
{x:41.80088,y:-87.588089},
{x:41.795039,y:-87.622948},
{x:41.849998,y:-87.615184},
{x:41.847136,y:-87.616806},
{x:41.722103,y:-87.575781},
{x:41.727768,y:-87.550869},
{x:41.702458,y:-87.524928},
{x:41.953528,y:-87.718202},
{x:41.947034,y:-87.693627},
{x:41.750948,y:-87.61543},
{x:41.736593,y:-87.588902},
{x:41.736147,y:-87.622978},
{x:41.742465,y:-87.662777},
{x:41.755288,y:-87.632212},
{x:41.751294,y:-87.683395},
{x:41.768531,y:-87.626935},
{x:41.895658,y:-87.677568},
{x:41.852269,y:-87.687428},
{x:41.844364,y:-87.732257},
{x:41.843972,y:-87.704795},
{x:41.866664,y:-87.706172},
{x:41.964129,y:-87.708659},
{x:41.969949,y:-87.689674},
{x:41.976131,y:-87.708302},
{x:41.968026,y:-87.724866},
{x:42.004916,y:-87.67318},
{x:41.72193,y:-87.625662},
{x:41.720045,y:-87.643617},
{x:41.686614,y:-87.64194},
{x:41.6857,y:-87.607639},
{x:41.770089,y:-87.722199},
{x:41.970648,y:-87.763651},
{x:41.797153,y:-87.722534},
{x:41.823377,y:-87.688595},
{x:41.960526,y:-87.787429},
{x:41.953359,y:-87.769078},
{x:41.944451,y:-87.807282},
{x:41.77516,y:-87.68319},
{x:41.777973,y:-87.664521},
{x:41.775364,y:-87.586815},
{x:41.775856,y:-87.742643},
{x:41.807379,y:-87.743462},
{x:41.792351,y:-87.789879},
{x:41.93065,y:-87.786249},
{x:41.93124,y:-87.742226},
{x:41.788561,y:-87.703214},
{x:41.923615,y:-87.766337},
{x:41.922827,y:-87.74592},
{x:41.909217,y:-87.755165},
{x:41.9101,y:-87.735544},
{x:41.976187,y:-87.655324},
{x:41.965783,y:-87.655229},
{x:41.945831,y:-87.747285},
{x:41.909858,y:-87.708259},
{x:41.939252,y:-87.728169},
{x:41.720691,y:-87.66442},
{x:41.865823,y:-87.765356},
{x:41.880179,y:-87.750711},
{x:41.990025,y:-87.710477},
{x:42.012072,y:-87.708238},
{x:42.007147,y:-87.690484},
{x:41.99328,y:-87.78351},
{x:41.930408,y:-87.709352},
{x:41.91711,y:-87.687046},
{x:42.025813,y:-87.813292},
{x:42.040611,y:-87.837665},
{x:41.751366,y:-87.565351},
{x:41.959279,y:-87.7479},
{x:41.894652,y:-87.755379},
{x:41.74007,y:-87.721285},
{x:41.809867,y:-87.607124},
{x:41.830839,y:-87.621177},
{x:41.888592,y:-87.634744},
{x:41.70573,y:-87.70162},
{x:41.962472,y:-87.837072},
{x:41.971593,y:-87.807549},
{x:41.933576,y:-87.668848},
{x:41.99758,y:-87.676208},
{x:41.995575,y:-87.659997},
{x:41.983986,y:-87.669297},
{x:41.882211,y:-87.640697},
{x:41.98013,y:-87.906462},
{x:41.977647,y:-87.910641},
{x:41.975329,y:-87.900247},
{x:41.975317,y:-87.906178},
{x:41.973989,y:-87.900519},
{x:41.974478,y:-87.890465},
{x:41.977112,y:-87.898408},
{x:41.974841,y:-87.89852},
{x:41.953934,y:-87.808276},
{x:41.923933,y:-87.807611},
{x:42.059082,y:-87.841829},
{x:41.677105,y:-87.7202},
{x:41.840618,y:-87.743264},
{x:41.851363,y:-87.761406},
{x:41.725478,y:-87.682679},
{x:41.734486,y:-87.701889},
{x:41.662578,y:-87.661489},
{x:41.132442,y:-87.876141},
{x:41.118624,y:-87.84096},
{x:41.188033,y:-87.850129},
{x:41.159601,y:-87.886402},
{x:41.160351,y:-87.850281},
{x:40.45651,y:-88.376221},
{x:40.752096,y:-88.000753},
{x:40.45932,y:-87.686754},
{x:41.249975,y:-87.848088},
{x:40.779629,y:-87.746828},
{x:42.23986,y:-88.840569},
{x:42.269034,y:-88.853458},
{x:42.233931,y:-88.834575},
{x:42.125846,y:-89.255566},
{x:41.847964,y:-89.484974},
{x:42.302022,y:-89.645017},
{x:42.27979,y:-89.632136},
{x:42.424127,y:-90.443187},
{x:42.012291,y:-89.332315},
{x:41.934943,y:-89.068884},
{x:41.932588,y:-89.034932},
{x:41.772268,y:-89.688771},
{x:42.45584,y:-89.026787},
{x:42.428289,y:-89.015834},
{x:42.090214,y:-90.133374},
{x:42.489926,y:-88.989123},
{x:41.801191,y:-89.667112},
{x:42.3577,y:-89.999261},
{x:42.280262,y:-89.243077},
{x:42.287966,y:-89.115469},
{x:42.315362,y:-89.075037},
{x:42.267295,y:-89.080054},
{x:42.252412,y:-89.087389},
{x:42.269804,y:-89.000384},
{x:42.263095,y:-89.025482},
{x:42.259664,y:-89.054141},
{x:42.267564,y:-88.97932},
{x:42.237665,y:-89.031001},
{x:42.22562,y:-89.072801},
{x:42.209292,y:-89.073438},
{x:42.317491,y:-89.058442},
{x:42.318269,y:-88.9804},
{x:42.316211,y:-89.033474},
{x:42.366218,y:-89.032475},
{x:41.492858,y:-90.556638},
{x:41.479924,y:-90.583925},
{x:41.199,y:-90.733234},
{x:41.490613,y:-90.442483},
{x:41.439589,y:-90.153638},
{x:41.449719,y:-90.567786},
{x:41.455961,y:-90.4987},
{x:41.491834,y:-90.488028},
{x:41.476984,y:-90.505408},
{x:41.807599,y:-89.9562},
{x:41.553852,y:-89.067297},
{x:41.557199,y:-89.127402},
{x:41.296136,y:-89.078543},
{x:41.35589,y:-88.840067},
{x:41.33811,y:-89.117689},
{x:41.369074,y:-89.127},
{x:41.396479,y:-89.467654},
{x:41.328401,y:-89.211934},
{x:41.138059,y:-88.836342},
{x:41.37033,y:-89.00901},
{x:40.960569,y:-90.383862},
{x:40.947075,y:-90.35238},
{x:41.229349,y:-89.927644},
{x:40.919311,y:-90.296808},
{x:40.458207,y:-90.651932},
{x:40.458428,y:-90.680453},
{x:40.925013,y:-90.649068},
{x:40.56609,y:-90.034206},
{x:40.907169,y:-89.493892},
{x:40.697359,y:-89.989394},
{x:40.790879,y:-89.373104},
{x:40.617176,y:-89.464805},
{x:40.626419,y:-89.618428},
{x:40.56561,y:-89.63663},
{x:40.546444,y:-89.602434},
{x:40.704139,y:-89.429579},
{x:40.715808,y:-89.507619},
{x:40.714918,y:-89.59432},
{x:40.695624,y:-89.623351},
{x:40.731422,y:-89.612153},
{x:40.653264,y:-89.647845},
{x:40.645533,y:-89.673883},
{x:40.67009,y:-89.581567},
{x:40.676442,y:-89.535067},
{x:40.781935,y:-89.620886},
{x:40.744163,y:-89.630228},
{x:40.732743,y:-89.574598},
{x:40.818115,y:-89.614104},
{x:40.700086,y:-89.610004},
{x:40.770487,y:-89.65641},
{x:40.488834,y:-88.956287},
{x:40.449437,y:-88.991464},
{x:40.471963,y:-88.953475},
{x:40.50337,y:-88.994202},
{x:40.48337,y:-89.02327},
{x:40.488985,y:-88.918559},
{x:40.741882,y:-88.732723},
{x:40.147538,y:-88.975858},
{x:40.736611,y:-89.027251},
{x:40.342018,y:-88.762345},
{x:40.643678,y:-88.804845},
{x:40.312265,y:-89.167311},
{x:40.530248,y:-88.996582},
{x:40.509824,y:-88.955954},
{x:40.873148,y:-88.643025},
{x:40.11825,y:-88.204471},
{x:40.097377,y:-88.191191},
{x:40.178005,y:-88.240266},
{x:40.11043,y:-88.229836},
{x:40.097945,y:-88.293887},
{x:40.097526,y:-88.245203},
{x:40.137539,y:-88.243044},
{x:40.133496,y:-88.259559},
{x:40.120573,y:-88.277416},
{x:40.127228,y:-87.611299},
{x:40.174751,y:-87.629617},
{x:40.11941,y:-87.636623},
{x:39.979346,y:-87.636557},
{x:40.185468,y:-88.368323},
{x:40.023807,y:-88.574286},
{x:40.122658,y:-87.778862},
{x:40.3079,y:-88.172643},
{x:39.68069,y:-88.297699},
{x:39.484004,y:-88.18233},
{x:39.483537,y:-88.336777},
{x:39.433155,y:-88.377526},
{x:39.6042,y:-87.683789},
{x:39.600554,y:-88.607963},
{x:39.791262,y:-88.27231},
{x:38.889219,y:-90.174326},
{x:38.91618,y:-90.151543},
{x:38.901253,y:-90.057612},
{x:38.882791,y:-90.120674},
{x:38.787804,y:-89.980415},
{x:38.793083,y:-89.950714},
{x:38.946904,y:-90.190229},
{x:38.749863,y:-90.071674},
{x:38.723599,y:-90.121434},
{x:38.700857,y:-90.145529},
{x:38.890021,y:-89.834815},
{x:39.154069,y:-89.493821},
{x:39.110334,y:-90.31927},
{x:39.178494,y:-89.669117},
{x:38.864878,y:-90.07749},
{x:38.616074,y:-90.128191},
{x:38.562944,y:-90.177504},
{x:38.591174,y:-89.984255},
{x:38.502964,y:-89.982147},
{x:38.517544,y:-89.949066},
{x:38.569976,y:-89.929956},
{x:38.544727,y:-90.051891},
{x:38.540103,y:-90.023504},
{x:38.542084,y:-89.984309},
{x:38.620013,y:-89.52646},
{x:38.627392,y:-89.371908},
{x:38.613164,y:-90.045836},
{x:37.926511,y:-89.804888},
{x:38.679085,y:-90.011198},
{x:38.691357,y:-89.976936},
{x:38.446811,y:-90.218432},
{x:38.437312,y:-89.915088},
{x:38.878562,y:-89.390148},
{x:38.754122,y:-89.68074},
{x:38.596908,y:-89.808372},
{x:38.496948,y:-89.794093},
{x:38.385696,y:-89.371749},
{x:38.534877,y:-89.72094},
{x:38.584563,y:-89.909183},
{x:38.592985,y:-89.955191},
{x:38.079823,y:-89.382277},
{x:38.19895,y:-89.993409},
{x:38.138898,y:-89.70304},
{x:38.731879,y:-89.906081},
{x:38.354138,y:-90.153876},
{x:39.935958,y:-91.394709},
{x:39.935587,y:-91.364523},
{x:39.9354,y:-91.338787},
{x:39.983304,y:-90.757272},
{x:39.607194,y:-90.813634},
{x:39.091633,y:-88.548511},
{x:39.149167,y:-88.531139},
{x:39.139718,y:-88.565942},
{x:39.047598,y:-88.748542},
{x:39.31613,y:-87.983909},
{x:38.726941,y:-87.700372},
{x:39.418492,y:-87.692739},
{x:38.730898,y:-88.079318},
{x:39.006004,y:-87.754757},
{x:38.976784,y:-89.101144},
{x:39.907545,y:-88.895524},
{x:39.845876,y:-88.931652},
{x:39.819682,y:-88.878669},
{x:39.84588,y:-88.970046},
{x:39.877055,y:-88.955551},
{x:39.919195,y:-88.954619},
{x:39.390486,y:-89.080421},
{x:39.406166,y:-88.805466},
{x:39.552919,y:-89.298746},
{x:40.004639,y:-90.438306},
{x:39.279475,y:-89.890931},
{x:39.678442,y:-89.703962},
{x:40.294528,y:-90.05683},
{x:39.72348,y:-90.237419},
{x:40.159395,y:-89.387643},
{x:39.961035,y:-89.56228},
{x:39.75621,y:-89.645736},
{x:39.744814,y:-89.639391},
{x:39.830033,y:-89.604235},
{x:39.816645,y:-89.642761},
{x:39.802728,y:-89.661056},
{x:39.801374,y:-89.604754},
{x:39.714479,y:-89.640254},
{x:39.751372,y:-89.707336},
{x:39.787436,y:-89.648957},
{x:39.761235,y:-89.607799},
{x:39.796082,y:-89.694099},
{x:39.765076,y:-89.687501},
{x:39.757064,y:-89.732983},
{x:38.532098,y:-89.158943},
{x:38.526405,y:-89.12487},
{x:38.377347,y:-88.059757},
{x:37.998021,y:-88.937733},
{x:38.079551,y:-88.177966},
{x:37.998936,y:-89.236424},
{x:38.379374,y:-88.377109},
{x:38.679365,y:-88.490314},
{x:38.155516,y:-88.910872},
{x:38.0898,y:-88.543042},
{x:38.415741,y:-87.763553},
{x:38.314129,y:-88.956147},
{x:38.315523,y:-88.926468},
{x:38.625297,y:-88.9652},
{x:37.896837,y:-88.951395},
{x:37.69929,y:-89.219624},
{x:37.735383,y:-89.18727},
{x:37.459972,y:-89.234592},
{x:37.745853,y:-89.073678},
{x:37.806748,y:-88.440319},
{x:37.729322,y:-88.536431},
{x:37.786528,y:-89.026754},
{x:37.818468,y:-88.933019},
{x:37.735515,y:-88.932993},
{x:37.74047,y:-88.963403},
{x:37.15356,y:-88.712974},
{x:37.788783,y:-89.325173},
{x:37.414954,y:-88.874012},
{x:38.667596,y:-90.616912},
{x:38.418425,y:-90.39075},
{x:38.444979,y:-90.375865},
{x:38.592977,y:-90.586402},
{x:38.594661,y:-90.492291},
{x:38.592411,y:-90.553795},
{x:38.626068,y:-90.516479},
{x:38.68012,y:-90.501009},
{x:38.643544,y:-90.567013},
{x:38.141996,y:-90.576295},
{x:38.505955,y:-90.636354},
{x:38.489708,y:-90.486494},
{x:38.51571,y:-90.450104},
{x:38.540523,y:-90.470717},
{x:38.211429,y:-90.40387},
{x:38.215272,y:-90.388094},
{x:38.800558,y:-90.334519},
{x:38.8125,y:-90.349027},
{x:38.810829,y:-90.296881},
{x:38.796379,y:-90.231888},
{x:38.783662,y:-90.378734},
{x:38.773857,y:-90.335791},
{x:38.783391,y:-90.354951},
{x:38.714223,y:-90.456695},
{x:38.757378,y:-90.430077},
{x:38.761888,y:-90.454873},
{x:38.463335,y:-90.524168},
{x:38.248089,y:-90.558028},
{x:38.41267,y:-90.58325},
{x:38.484097,y:-90.759781},
{x:38.287601,y:-90.404482},
{x:38.73349,y:-90.390918},
{x:38.36088,y:-90.977182},
{x:38.218593,y:-91.162562},
{x:38.445311,y:-90.997225},
{x:38.537803,y:-90.494384},
{x:38.56686,y:-90.476976},
{x:38.538038,y:-91.001845},
{x:38.636908,y:-90.195483},
{x:38.612495,y:-90.221972},
{x:38.639053,y:-90.243767},
{x:38.586227,y:-90.276009},
{x:38.710151,y:-90.350102},
{x:38.686238,y:-90.357734},
{x:38.676173,y:-90.250699},
{x:38.565636,y:-90.292522},
{x:38.58311,y:-90.31905},
{x:38.606869,y:-90.374567},
{x:38.591175,y:-90.338371},
{x:38.723333,y:-90.30448},
{x:38.700575,y:-90.290743},
{x:38.577091,y:-90.406013},
{x:38.521984,y:-90.346413},
{x:38.496202,y:-90.299551},
{x:38.54598,y:-90.287693},
{x:38.55704,y:-90.393754},
{x:38.49399,y:-90.380927},
{x:38.500947,y:-90.333435},
{x:38.471569,y:-90.305475},
{x:38.673931,y:-90.347048},
{x:38.602058,y:-90.438545},
{x:38.691914,y:-90.389954},
{x:38.673648,y:-90.376421},
{x:38.767875,y:-90.280827},
{x:38.72775,y:-90.244077},
{x:38.73552,y:-90.278493},
{x:38.772799,y:-90.220695},
{x:38.624458,y:-90.287483},
{x:38.592967,y:-90.293806},
{x:38.671734,y:-90.437091},
{x:38.626693,y:-90.335484},
{x:38.700298,y:-90.443806},
{x:38.685236,y:-90.217882},
{x:38.506441,y:-90.678631},
{x:38.772582,y:-90.491476},
{x:38.819366,y:-90.513951},
{x:38.746498,y:-90.578537},
{x:38.779086,y:-90.530086},
{x:38.760122,y:-90.65332},
{x:38.739573,y:-90.634437},
{x:39.333519,y:-91.181923},
{x:38.815016,y:-90.952542},
{x:38.901852,y:-91.458733},
{x:38.805184,y:-90.769805},
{x:38.759045,y:-90.700872},
{x:38.822015,y:-90.70025},
{x:38.801729,y:-90.699228},
{x:38.76891,y:-90.782521},
{x:38.721029,y:-90.699666},
{x:38.794386,y:-90.623272},
{x:38.791718,y:-90.563393},
{x:38.787562,y:-90.661621},
{x:38.790996,y:-90.605894},
{x:38.987524,y:-90.971402},
{x:38.823141,y:-91.139001},
{x:38.80998,y:-90.86511},
{x:38.829406,y:-91.042214},
{x:39.720565,y:-91.392519},
{x:40.217198,y:-92.582101},
{x:40.184546,y:-92.571497},
{x:39.750255,y:-92.467515},
{x:38.005487,y:-90.225682},
{x:37.883235,y:-90.511481},
{x:37.770448,y:-90.446696},
{x:37.788289,y:-90.422333},
{x:37.559645,y:-90.315909},
{x:37.624748,y:-90.647143},
{x:37.928731,y:-90.776453},
{x:37.967709,y:-90.049355},
{x:37.302693,y:-89.583407},
{x:37.312606,y:-89.545738},
{x:37.105002,y:-89.90391},
{x:37.089603,y:-89.541702},
{x:37.369278,y:-89.642748},
{x:37.30567,y:-89.97632},
{x:37.71162,y:-89.89167},
{x:36.891026,y:-89.536096},
{x:36.90423,y:-89.354345},
{x:36.803355,y:-89.971671},
{x:36.229358,y:-89.726271},
{x:36.236088,y:-90.0334},
{x:36.572557,y:-89.970188},
{x:36.858516,y:-89.583752},
{x:36.423862,y:-89.689605},
{x:36.736194,y:-90.397992},
{x:36.7556,y:-90.411236},
{x:36.787517,y:-90.431569},
{x:36.631189,y:-90.825517},
{x:37.145145,y:-90.700053},
{x:37.0108,y:-90.995788},
{x:38.812905,y:-94.506855},
{x:38.988237,y:-94.272883},
{x:39.028114,y:-94.271066},
{x:39.033113,y:-94.305959},
{x:39.008768,y:-94.276599},
{x:38.992919,y:-93.568746},
{x:39.331832,y:-94.267904},
{x:39.019146,y:-94.19869},
{x:38.899357,y:-94.532275},
{x:39.003224,y:-93.734225},
{x:39.077661,y:-93.738068},
{x:39.079828,y:-94.446573},
{x:39.102465,y:-94.436517},
{x:39.077795,y:-94.413462},
{x:39.047545,y:-94.365434},
{x:39.04582,y:-94.441097},
{x:39.072479,y:-94.380074},
{x:39.043186,y:-94.415592},
{x:39.032138,y:-94.35653},
{x:39.117415,y:-94.382548},
{x:39.368577,y:-94.370716},
{x:38.903563,y:-94.338875},
{x:38.925216,y:-94.399098},
{x:39.185776,y:-93.880205},
{x:39.267838,y:-94.451056},
{x:39.236915,y:-94.433457},
{x:39.014092,y:-94.128184},
{x:39.006781,y:-93.953325},
{x:39.354102,y:-94.765288},
{x:38.790021,y:-94.261673},
{x:38.912672,y:-94.447874},
{x:38.852151,y:-94.377743},
{x:38.812471,y:-94.474088},
{x:39.270966,y:-93.967879},
{x:38.91797,y:-94.360936},
{x:39.361963,y:-94.583306},
{x:38.771439,y:-93.734173},
{x:39.041936,y:-94.571267},
{x:39.010975,y:-94.575242},
{x:39.066975,y:-94.584981},
{x:39.05897,y:-94.590256},
{x:38.97634,y:-94.595348},
{x:38.985949,y:-94.607678},
{x:38.942395,y:-94.607291},
{x:39.167392,y:-94.577147},
{x:39.146268,y:-94.557002},
{x:39.172803,y:-94.535856},
{x:39.208513,y:-94.576796},
{x:39.207438,y:-94.548064},
{x:39.200516,y:-94.499097},
{x:39.129649,y:-94.503605},
{x:39.104929,y:-94.519726},
{x:39.095454,y:-94.551121},
{x:39.069168,y:-94.519988},
{x:39.031325,y:-94.503621},
{x:38.921547,y:-94.584124},
{x:38.997127,y:-94.478914},
{x:39.026476,y:-94.442625},
{x:38.924784,y:-94.523723},
{x:38.966776,y:-94.498926},
{x:38.886104,y:-94.607369},
{x:39.209964,y:-94.642091},
{x:39.245598,y:-94.659765},
{x:39.246816,y:-94.582863},
{x:39.26547,y:-94.576423},
{x:39.243844,y:-94.462519},
{x:40.264675,y:-94.011287},
{x:39.752648,y:-94.216094},
{x:40.334493,y:-94.874333},
{x:40.133504,y:-95.234095},
{x:40.404845,y:-95.556424},
{x:39.725089,y:-94.789876},
{x:39.713086,y:-94.872776},
{x:39.801918,y:-94.816093},
{x:39.734198,y:-94.80056},
{x:39.771317,y:-94.803024},
{x:39.754094,y:-94.769126},
{x:39.774252,y:-93.553425},
{x:39.775132,y:-93.070432},
{x:39.370245,y:-93.485322},
{x:40.074794,y:-93.596406},
{x:38.636283,y:-94.355374},
{x:38.662598,y:-94.366379},
{x:38.256624,y:-94.351536},
{x:38.366684,y:-93.749858},
{x:37.862552,y:-94.017274},
{x:37.49323,y:-94.297661},
{x:37.836834,y:-94.358484},
{x:37.078412,y:-94.477043},
{x:37.084993,y:-94.530721},
{x:37.005445,y:-94.552221},
{x:37.060119,y:-94.51423},
{x:37.055515,y:-94.533469},
{x:37.051979,y:-94.47908},
{x:37.139789,y:-94.319054},
{x:37.179223,y:-94.324808},
{x:36.879593,y:-94.428909},
{x:36.860555,y:-94.383194},
{x:36.509178,y:-94.280388},
{x:37.140786,y:-94.475643},
{x:38.773519,y:-92.253974},
{x:38.630401,y:-92.579023},
{x:38.011197,y:-92.741065},
{x:38.34648,y:-92.581927},
{x:38.655451,y:-92.113771},
{x:38.194994,y:-92.643293},
{x:38.493499,y:-91.858463},
{x:38.112733,y:-92.688491},
{x:38.153946,y:-92.608988},
{x:38.344732,y:-91.493952},
{x:38.431174,y:-92.85828},
{x:38.549508,y:-92.149552},
{x:38.579066,y:-92.195242},
{x:38.580048,y:-92.219978},
{x:38.580698,y:-92.23513},
{x:38.553214,y:-92.199612},
{x:38.960894,y:-92.37196},
{x:38.965145,y:-92.334905},
{x:38.909548,y:-92.332665},
{x:38.992773,y:-92.324298},
{x:38.963253,y:-92.289888},
{x:38.937393,y:-92.744391},
{x:39.218371,y:-92.133267},
{x:38.840927,y:-91.950772},
{x:38.941294,y:-91.943631},
{x:39.14745,y:-91.889838},
{x:39.436003,y:-92.433379},
{x:38.708142,y:-93.265459},
{x:38.697295,y:-93.25021},
{x:38.771503,y:-93.566221},
{x:39.107973,y:-93.208995},
{x:38.264747,y:-93.3702},
{x:37.981526,y:-91.719311},
{x:37.944753,y:-91.785421},
{x:38.072039,y:-91.404473},
{x:37.313637,y:-91.959364},
{x:37.64459,y:-92.668532},
{x:37.67431,y:-92.65491},
{x:37.001521,y:-91.7061},
{x:38.006007,y:-91.620402},
{x:37.63711,y:-91.536004},
{x:37.808475,y:-92.224626},
{x:37.822438,y:-92.139393},
{x:36.956801,y:-93.71891},
{x:36.960732,y:-92.672216},
{x:37.605503,y:-93.407234},
{x:36.672812,y:-93.227736},
{x:36.640856,y:-93.261841},
{x:36.663377,y:-93.239852},
{x:36.644671,y:-93.222904},
{x:37.138199,y:-93.36245},
{x:37.639941,y:-93.103608},
{x:36.674145,y:-93.871013},
{x:36.694832,y:-93.1228},
{x:36.609121,y:-93.234022},
{x:37.340542,y:-92.926707},
{x:36.913636,y:-93.912719},
{x:37.145732,y:-92.262052},
{x:37.093671,y:-93.806626},
{x:37.04419,y:-93.300792},
{x:37.023633,y:-93.232761},
{x:36.696523,y:-93.365805},
{x:37.124196,y:-93.466368},
{x:37.109575,y:-93.057634},
{x:37.156073,y:-92.78539},
{x:37.272903,y:-93.109992},
{x:36.742259,y:-91.871356},
{x:36.714592,y:-91.874793},
{x:36.523208,y:-91.534486},
{x:36.986056,y:-91.976543},
{x:37.204142,y:-93.261414},
{x:37.208324,y:-93.275579},
{x:37.211678,y:-93.349551},
{x:37.247775,y:-93.311387},
{x:37.29207,y:-93.409542},
{x:37.240559,y:-93.304031},
{x:37.236661,y:-93.260582},
{x:37.209266,y:-93.228757},
{x:37.142715,y:-93.256128},
{x:37.180647,y:-93.246288},
{x:37.160049,y:-93.266744},
{x:37.157675,y:-93.227582},
{x:37.14021,y:-93.277426},
{x:37.160615,y:-93.315511},
{x:37.182774,y:-93.29677},
{x:37.139912,y:-93.296805},
{x:39.561128,y:-95.123143},
{x:39.08674,y:-94.882395},
{x:38.961939,y:-94.981982},
{x:38.813032,y:-94.889786},
{x:38.970916,y:-95.307647},
{x:38.97268,y:-95.250362},
{x:38.942486,y:-95.244698},
{x:39.019104,y:-95.13681},
{x:38.924983,y:-95.261278},
{x:39.285982,y:-94.904266},
{x:38.620248,y:-94.691943},
{x:38.883968,y:-94.806449},
{x:38.912265,y:-94.793657},
{x:38.883212,y:-94.835277},
{x:38.857762,y:-94.815065},
{x:38.854292,y:-94.760599},
{x:38.884074,y:-94.780067},
{x:38.909576,y:-94.772166},
{x:38.580294,y:-95.268654},
{x:38.570779,y:-94.857264},
{x:39.115802,y:-94.630666},
{x:39.116307,y:-94.672279},
{x:39.050611,y:-94.611578},
{x:39.087276,y:-94.625401},
{x:39.089112,y:-94.675966},
{x:39.052108,y:-94.680555},
{x:39.12807,y:-94.824552},
{x:39.116944,y:-94.754761},
{x:39.021797,y:-94.688922},
{x:39.02186,y:-94.663232},
{x:39.014115,y:-94.721995},
{x:38.9426,y:-94.701631},
{x:38.99262,y:-94.695695},
{x:38.989149,y:-94.668106},
{x:39.036038,y:-94.639317},
{x:38.957178,y:-94.630009},
{x:38.913476,y:-94.639159},
{x:38.88503,y:-94.723115},
{x:38.929631,y:-94.724216},
{x:38.929648,y:-94.667075},
{x:38.970839,y:-94.69776},
{x:38.957066,y:-94.671914},
{x:38.913816,y:-94.670142},
{x:38.957111,y:-94.720268},
{x:38.992551,y:-94.713026},
{x:38.971499,y:-94.772752},
{x:38.971483,y:-94.74041},
{x:39.00984,y:-94.774601},
{x:38.884981,y:-94.686985},
{x:38.855103,y:-94.679227},
{x:39.00833,y:-94.844864},
{x:39.842535,y:-95.526639},
{x:39.009117,y:-96.834461},
{x:39.028518,y:-96.836013},
{x:39.190305,y:-96.610494},
{x:39.187214,y:-96.56115},
{x:39.841842,y:-96.05714},
{x:39.209663,y:-96.304507},
{x:39.045398,y:-95.725125},
{x:39.028862,y:-95.749222},
{x:39.036936,y:-95.761126},
{x:39.015591,y:-95.651006},
{x:39.129988,y:-95.716446},
{x:39.085255,y:-95.665814},
{x:39.0113,y:-95.688306},
{x:39.043899,y:-95.676107},
{x:39.013578,y:-95.762738},
{x:37.813215,y:-94.705898},
{x:37.008079,y:-94.736392},
{x:37.6668,y:-95.453803},
{x:37.167418,y:-94.831173},
{x:37.921794,y:-95.40855},
{x:37.437856,y:-94.704263},
{x:37.396811,y:-94.704479},
{x:38.423746,y:-96.217657},
{x:38.470687,y:-96.18514},
{x:38.405344,y:-96.195878},
{x:39.56313,y:-97.657858},
{x:37.693558,y:-97.136163},
{x:37.152471,y:-98.039668},
{x:37.085057,y:-97.03967},
{x:37.679853,y:-96.984165},
{x:37.367372,y:-97.322312},
{x:37.549188,y:-97.243575},
{x:37.552134,y:-97.272175},
{x:37.817727,y:-96.876941},
{x:37.663631,y:-97.572344},
{x:37.566768,y:-97.351501},
{x:37.648218,y:-98.095816},
{x:38.057985,y:-97.345597},
{x:37.645195,y:-98.71549},
{x:37.27472,y:-97.375631},
{x:37.227612,y:-96.995585},
{x:37.681863,y:-97.335882},
{x:37.723672,y:-97.363672},
{x:37.782008,y:-97.373272},
{x:37.722629,y:-97.428233},
{x:37.647972,y:-97.332175},
{x:37.663553,y:-97.22678},
{x:37.678597,y:-97.199094},
{x:37.665946,y:-97.245036},
{x:37.684771,y:-97.352443},
{x:37.694356,y:-97.270952},
{x:37.673364,y:-97.415982},
{x:37.664004,y:-97.298349},
{x:37.70936,y:-97.390201},
{x:37.722052,y:-97.462083},
{x:37.691512,y:-97.4439},
{x:37.676953,y:-97.389001},
{x:37.694987,y:-97.299505},
{x:37.715974,y:-97.299363},
{x:37.702099,y:-97.335238},
{x:37.651214,y:-97.352176},
{x:37.63084,y:-97.316479},
{x:37.60753,y:-97.333487},
{x:37.650044,y:-97.389642},
{x:37.79227,y:-97.329119},
{x:37.723535,y:-97.243641},
{x:37.747533,y:-97.245139},
{x:37.723635,y:-97.208669},
{x:37.223986,y:-95.711284},
{x:37.033279,y:-95.619478},
{x:37.342827,y:-95.258812},
{x:38.878362,y:-97.610954},
{x:38.826728,y:-97.593588},
{x:38.82677,y:-97.624476},
{x:38.80204,y:-97.613654},
{x:38.937691,y:-97.213334},
{x:38.371385,y:-97.629284},
{x:38.06459,y:-97.901079},
{x:38.057581,y:-97.930707},
{x:38.086161,y:-97.918314},
{x:38.014951,y:-97.939241},
{x:38.361349,y:-98.784129},
{x:38.895544,y:-99.316691},
{x:38.870004,y:-99.31828},
{x:38.865429,y:-98.85357},
{x:39.012802,y:-99.890755},
{x:39.366543,y:-101.056343},
{x:39.331693,y:-101.72539},
{x:37.778941,y:-100.016656},
{x:37.752869,y:-100.023973},
{x:37.975437,y:-100.882998},
{x:37.975564,y:-100.853303},
{x:37.576103,y:-101.358468},
{x:37.058181,y:-100.922922},
{x:37.031928,y:-100.921463},
{x:37.170311,y:-101.337109},
{x:41.142949,y:-95.911601},
{x:41.543309,y:-96.125237},
{x:41.451117,y:-96.491449},
{x:41.09743,y:-96.251781},
{x:41.138768,y:-96.043832},
{x:41.221444,y:-95.975292},
{x:41.232764,y:-95.933799},
{x:41.298925,y:-95.957368},
{x:41.327098,y:-95.958293},
{x:41.259262,y:-96.033441},
{x:41.290684,y:-96.177824},
{x:41.158698,y:-95.94215},
{x:41.14007,y:-95.967783},
{x:41.225983,y:-96.043793},
{x:41.204943,y:-96.080825},
{x:41.179496,y:-96.042347},
{x:41.233467,y:-96.201523},
{x:41.268238,y:-95.947463},
{x:41.258895,y:-95.973627},
{x:41.277419,y:-96.023512},
{x:41.310584,y:-96.053503},
{x:41.301258,y:-96.023237},
{x:41.205507,y:-96.193063},
{x:41.214732,y:-96.113811},
{x:41.205593,y:-96.127218},
{x:41.165124,y:-96.137954},
{x:41.233396,y:-96.140533},
{x:41.263738,y:-96.090175},
{x:41.293284,y:-96.119099},
{x:41.290081,y:-96.081999},
{x:40.278773,y:-96.746028},
{x:40.147578,y:-97.17164},
{x:40.663444,y:-95.859662},
{x:40.886577,y:-97.102277},
{x:40.912935,y:-96.53295},
{x:40.824265,y:-97.598531},
{x:40.822176,y:-96.682831},
{x:40.778437,y:-96.705805},
{x:40.855294,y:-96.646757},
{x:40.818701,y:-96.654187},
{x:40.785175,y:-96.65301},
{x:40.809683,y:-96.689849},
{x:40.813917,y:-96.631755},
{x:40.740079,y:-96.681907},
{x:40.754306,y:-96.641951},
{x:40.73627,y:-96.599814},
{x:40.858632,y:-96.682659},
{x:40.836838,y:-96.706602},
{x:40.844109,y:-96.73854},
{x:40.813037,y:-96.724263},
{x:41.439123,y:-97.367114},
{x:42.046324,y:-97.416911},
{x:42.02317,y:-97.425877},
{x:42.45608,y:-98.632822},
{x:42.483547,y:-96.412448},
{x:42.235088,y:-97.004327},
{x:41.851005,y:-96.710083},
{x:40.919268,y:-98.352709},
{x:40.938313,y:-98.380106},
{x:40.872925,y:-97.997316},
{x:41.40208,y:-99.629685},
{x:40.728865,y:-99.081354},
{x:40.699867,y:-99.072479},
{x:40.681692,y:-99.083742},
{x:40.747391,y:-99.739254},
{x:40.568451,y:-98.39329},
{x:40.602001,y:-98.391404},
{x:40.438117,y:-99.385434},
{x:40.202071,y:-100.64424},
{x:41.122435,y:-100.762423},
{x:41.102867,y:-100.724007},
{x:40.922882,y:-100.166329},
{x:41.1163,y:-101.71123},
{x:41.117349,y:-102.950368},
{x:42.867333,y:-100.550013},
{x:42.096339,y:-102.893246},
{x:42.828802,y:-103.016092},
{x:41.827599,y:-103.66073},
{x:41.874326,y:-103.669487},
{x:29.980995,y:-90.178985},
{x:29.97378,y:-90.151404},
{x:30.005257,y:-90.172083},
{x:30.013547,y:-90.159532},
{x:29.977816,y:-90.203019},
{x:30.006868,y:-90.200764},
{x:30.00118,y:-90.125984},
{x:32.560053,y:-92.913898},
{x:29.862558,y:-89.996859},
{x:29.950421,y:-89.959127},
{x:29.953618,y:-89.978048},
{x:29.9867,y:-90.364055},
{x:30.075219,y:-90.625843},
{x:30.062018,y:-90.699258},
{x:29.881872,y:-90.023096},
{x:29.914609,y:-90.046356},
{x:29.910151,y:-90.057232},
{x:29.899907,y:-90.029817},
{x:29.868918,y:-90.076399},
{x:29.894572,y:-90.059691},
{x:30.006016,y:-90.238737},
{x:29.980175,y:-90.24379},
{x:30.009293,y:-90.266953},
{x:30.020078,y:-90.250551},
{x:30.073315,y:-90.497144},
{x:30.085689,y:-90.445388},
{x:29.908251,y:-90.361789},
{x:29.85393,y:-90.104048},
{x:29.879618,y:-90.098119},
{x:29.894555,y:-90.113473},
{x:29.934721,y:-89.92232},
{x:29.876455,y:-90.435852},
{x:29.909186,y:-90.204369},
{x:29.899965,y:-90.146608},
{x:29.926198,y:-90.022574},
{x:29.928793,y:-90.091101},
{x:29.969472,y:-90.049622},
{x:29.963281,y:-90.111819},
{x:29.983598,y:-90.070866},
{x:29.966839,y:-90.088206},
{x:29.961844,y:-90.183709},
{x:29.962518,y:-90.156317},
{x:29.98955,y:-90.05784},
{x:29.956277,y:-90.217034},
{x:29.941577,y:-90.095297},
{x:30.00419,y:-90.036387},
{x:30.038452,y:-89.975712},
{x:30.043728,y:-89.958336},
{x:29.953576,y:-90.069183},
{x:29.925146,y:-90.070152},
{x:29.933596,y:-90.007726},
{x:29.780739,y:-90.807952},
{x:29.802109,y:-90.849604},
{x:29.80367,y:-90.816245},
{x:29.548417,y:-90.34441},
{x:29.468103,y:-90.32372},
{x:30.099057,y:-90.990829},
{x:29.674347,y:-90.779642},
{x:29.578859,y:-90.723724},
{x:29.5717,y:-90.683646},
{x:29.584579,y:-90.692605},
{x:29.593373,y:-90.676582},
{x:29.610773,y:-90.754075},
{x:29.626689,y:-90.75162},
{x:29.699128,y:-91.197117},
{x:29.661528,y:-91.121267},
{x:29.683242,y:-91.262509},
{x:29.693301,y:-90.552641},
{x:30.518669,y:-90.483306},
{x:30.484942,y:-90.45732},
{x:30.503951,y:-90.496449},
{x:30.48244,y:-90.415109},
{x:30.726952,y:-90.529517},
{x:30.789751,y:-89.863645},
{x:30.443258,y:-90.07876},
{x:30.467335,y:-90.18385},
{x:30.47618,y:-90.086172},
{x:30.45106,y:-90.13788},
{x:30.500319,y:-90.108126},
{x:30.843787,y:-90.159261},
{x:30.364354,y:-90.062318},
{x:30.359293,y:-89.750081},
{x:30.438135,y:-90.45361},
{x:30.259008,y:-89.787697},
{x:30.311706,y:-89.768994},
{x:30.284304,y:-89.753683},
{x:30.30247,y:-89.825356},
{x:30.278423,y:-89.748978},
{x:30.291801,y:-89.720314},
{x:30.247523,y:-89.761658},
{x:30.395189,y:-90.090295},
{x:30.412093,y:-90.041647},
{x:30.243538,y:-92.010954},
{x:30.264405,y:-91.993171},
{x:30.189081,y:-92.062721},
{x:30.150045,y:-92.093704},
{x:30.214087,y:-92.022652},
{x:30.231387,y:-92.063088},
{x:30.253879,y:-92.038408},
{x:30.157009,y:-92.047177},
{x:30.177705,y:-92.003388},
{x:29.983085,y:-92.130626},
{x:29.986131,y:-91.815244},
{x:30.284115,y:-91.907116},
{x:30.313165,y:-91.831988},
{x:30.144096,y:-91.943443},
{x:30.153589,y:-91.958886},
{x:30.322563,y:-92.036565},
{x:30.229809,y:-92.381766},
{x:30.494485,y:-92.437841},
{x:29.807709,y:-91.508096},
{x:30.416692,y:-92.051972},
{x:30.241011,y:-92.662832},
{x:29.996376,y:-92.2804},
{x:29.990251,y:-91.832793},
{x:30.031162,y:-91.841337},
{x:29.998395,y:-91.804365},
{x:30.518738,y:-92.072205},
{x:30.545892,y:-91.964313},
{x:30.253426,y:-92.268858},
{x:30.143178,y:-91.823224},
{x:30.244396,y:-92.106142},
{x:30.687226,y:-92.264484},
{x:30.10884,y:-92.016937},
{x:30.198676,y:-93.212226},
{x:30.173857,y:-93.248849},
{x:30.180105,y:-93.218854},
{x:30.30018,y:-93.199612},
{x:30.227179,y:-93.179537},
{x:30.450558,y:-93.431098},
{x:30.85188,y:-93.289454},
{x:30.247175,y:-93.014632},
{x:30.486765,y:-92.849292},
{x:30.224553,y:-93.376741},
{x:30.221657,y:-93.324812},
{x:30.254117,y:-93.258584},
{x:30.482131,y:-90.578978},
{x:30.576185,y:-91.169817},
{x:30.384626,y:-91.245362},
{x:30.846111,y:-91.001341},
{x:30.527921,y:-90.956733},
{x:30.482249,y:-90.954673},
{x:30.456882,y:-90.954826},
{x:30.267796,y:-90.986101},
{x:30.239212,y:-90.915247},
{x:30.210233,y:-90.941969},
{x:30.687673,y:-91.466207},
{x:30.281362,y:-91.228603},
{x:30.534307,y:-91.408481},
{x:30.451643,y:-91.244944},
{x:30.321257,y:-90.977572},
{x:30.785584,y:-91.370334},
{x:30.169538,y:-90.877486},
{x:30.474119,y:-90.863874},
{x:30.647312,y:-91.148763},
{x:30.44309,y:-91.183174},
{x:30.412756,y:-91.177274},
{x:30.504744,y:-91.150806},
{x:30.488163,y:-91.121706},
{x:30.452514,y:-91.105312},
{x:30.45049,y:-91.135674},
{x:30.5201,y:-91.177262},
{x:30.391675,y:-91.161289},
{x:30.416443,y:-91.14212},
{x:30.417951,y:-91.090436},
{x:30.379075,y:-91.096292},
{x:30.399023,y:-91.107784},
{x:30.384059,y:-91.065759},
{x:30.354279,y:-91.108931},
{x:30.5584,y:-91.133876},
{x:30.485058,y:-91.082556},
{x:30.462558,y:-91.090857},
{x:30.45597,y:-91.073084},
{x:30.43614,y:-91.008101},
{x:30.41707,y:-91.065606},
{x:30.430262,y:-91.078847},
{x:30.43247,y:-91.057704},
{x:30.360007,y:-91.008344},
{x:30.408907,y:-91.015608},
{x:30.547396,y:-91.05445},
{x:30.46179,y:-91.03574},
{x:30.398811,y:-91.179073},
{x:32.55649,y:-93.613687},
{x:32.804317,y:-93.055159},
{x:32.037482,y:-93.697524},
{x:32.621932,y:-93.275208},
{x:32.992713,y:-93.461986},
{x:32.482301,y:-93.752596},
{x:32.430256,y:-93.714017},
{x:32.489099,y:-93.704421},
{x:32.451367,y:-93.7216},
{x:32.453547,y:-93.746514},
{x:32.397869,y:-93.762901},
{x:32.601016,y:-93.842704},
{x:32.53692,y:-93.771254},
{x:32.441507,y:-93.781798},
{x:32.475076,y:-93.7983},
{x:32.53417,y:-93.671934},
{x:32.555692,y:-93.710872},
{x:32.518692,y:-93.716166},
{x:32.527286,y:-93.700035},
{x:32.478663,y:-93.677057},
{x:32.386646,y:-93.815965},
{x:32.402846,y:-93.815064},
{x:32.447582,y:-93.863763},
{x:32.52381,y:-92.112324},
{x:32.489567,y:-92.08411},
{x:32.568079,y:-92.075086},
{x:32.534869,y:-92.078822},
{x:32.528278,y:-92.037618},
{x:32.778782,y:-91.889558},
{x:32.780855,y:-92.405871},
{x:32.241369,y:-92.711786},
{x:32.853534,y:-91.392302},
{x:32.457659,y:-91.757978},
{x:32.525514,y:-92.640033},
{x:32.541086,y:-92.634873},
{x:32.395986,y:-91.19873},
{x:32.503772,y:-92.12769},
{x:32.514127,y:-92.192418},
{x:32.512487,y:-92.157824},
{x:32.181568,y:-91.72725},
{x:31.296223,y:-92.478091},
{x:31.262111,y:-92.447771},
{x:31.344644,y:-92.519456},
{x:31.275895,y:-92.481295},
{x:30.959342,y:-92.189769},
{x:31.682954,y:-92.129247},
{x:31.10386,y:-92.061388},
{x:31.362043,y:-92.413852},
{x:31.333614,y:-92.407305},
{x:31.571107,y:-91.442327},
{x:32.087115,y:-92.089501},
{x:31.126093,y:-93.268009},
{x:31.567933,y:-93.4896},
{x:31.727887,y:-93.159746},
{x:31.753958,y:-93.081152},
{x:31.054406,y:-93.236894},
{x:30.814269,y:-92.66545},
{x:31.925521,y:-92.652356},
{x:34.229889,y:-92.042992},
{x:34.20848,y:-91.971662},
{x:34.259642,y:-92.100998},
{x:34.174048,y:-92.003143},
{x:34.192959,y:-92.061474},
{x:34.200215,y:-92.006587},
{x:33.136602,y:-91.95294},
{x:33.879802,y:-91.482183},
{x:33.323551,y:-91.292575},
{x:33.635582,y:-91.802215},
{x:33.94296,y:-91.844414},
{x:33.610249,y:-92.071248},
{x:33.568806,y:-92.839293},
{x:33.207274,y:-92.68654},
{x:33.23054,y:-92.666188},
{x:33.824974,y:-92.426179},
{x:33.267182,y:-93.22478},
{x:33.680367,y:-93.605773},
{x:33.659338,y:-94.116154},
{x:34.045103,y:-94.331546},
{x:33.929749,y:-93.851639},
{x:33.430554,y:-94.037144},
{x:34.503649,y:-93.046972},
{x:34.493622,y:-93.082414},
{x:34.47717,y:-93.059372},
{x:34.472479,y:-92.991284},
{x:34.654392,y:-93.056731},
{x:34.502945,y:-93.126266},
{x:34.4652,y:-93.143729},
{x:34.451791,y:-93.073727},
{x:34.182675,y:-93.071226},
{x:34.118508,y:-93.095407},
{x:34.322428,y:-93.550391},
{x:34.586199,y:-94.223873},
{x:35.317881,y:-91.562448},
{x:35.068506,y:-91.911796},
{x:34.542783,y:-92.632574},
{x:34.573032,y:-92.58046},
{x:34.606875,y:-92.533029},
{x:34.904735,y:-91.197681},
{x:34.616007,y:-92.497733},
{x:34.975723,y:-92.025986},
{x:34.976393,y:-92.040719},
{x:34.946323,y:-92.062271},
{x:35.595446,y:-92.457402},
{x:35.112868,y:-92.431436},
{x:35.092114,y:-92.426541},
{x:35.068578,y:-92.443208},
{x:35.087651,y:-92.472137},
{x:35.109982,y:-92.088834},
{x:35.228663,y:-92.388608},
{x:34.867527,y:-92.118161},
{x:34.884878,y:-92.10665},
{x:34.806619,y:-91.887441},
{x:34.392582,y:-92.829836},
{x:34.362968,y:-92.809556},
{x:35.170477,y:-92.719161},
{x:35.607487,y:-91.251411},
{x:34.82588,y:-92.383288},
{x:34.883732,y:-92.383246},
{x:34.755694,y:-92.261381},
{x:34.789757,y:-92.226858},
{x:34.775928,y:-92.199504},
{x:34.806617,y:-92.292016},
{x:34.820289,y:-92.234866},
{x:34.795252,y:-92.270764},
{x:34.83063,y:-92.182416},
{x:34.838648,y:-92.236831},
{x:34.854933,y:-92.203038},
{x:35.243316,y:-91.735798},
{x:35.249784,y:-91.686025},
{x:34.291321,y:-92.398351},
{x:34.479104,y:-91.553768},
{x:35.084484,y:-92.199509},
{x:34.742538,y:-92.276088},
{x:34.714205,y:-92.341995},
{x:34.751464,y:-92.341719},
{x:34.742447,y:-92.355156},
{x:34.717661,y:-92.189846},
{x:34.618965,y:-92.314505},
{x:34.676922,y:-92.343372},
{x:34.670564,y:-92.376906},
{x:34.655439,y:-92.42579},
{x:34.75434,y:-92.403635},
{x:34.765584,y:-92.456287},
{x:34.809099,y:-92.486107},
{x:34.766297,y:-92.38769},
{x:34.773429,y:-92.360991},
{x:35.16586,y:-90.185161},
{x:35.153138,y:-90.136709},
{x:35.888843,y:-89.917805},
{x:35.92825,y:-89.882036},
{x:35.030997,y:-90.790151},
{x:35.213227,y:-90.206069},
{x:35.528519,y:-90.431867},
{x:35.698541,y:-90.01222},
{x:34.554059,y:-90.659142},
{x:35.242225,y:-90.782123},
{x:35.819008,y:-90.685183},
{x:35.818609,y:-90.668489},
{x:35.845948,y:-90.681243},
{x:35.828059,y:-90.678235},
{x:35.821111,y:-90.704841},
{x:36.412708,y:-90.586853},
{x:35.570673,y:-90.723524},
{x:36.053247,y:-90.504546},
{x:36.059562,y:-90.547295},
{x:36.382352,y:-90.17727},
{x:36.250891,y:-90.961388},
{x:35.679372,y:-90.550108},
{x:36.073709,y:-90.954785},
{x:35.767181,y:-91.641699},
{x:36.241092,y:-91.596029},
{x:36.317943,y:-91.486663},
{x:35.508236,y:-92.028692},
{x:35.864333,y:-92.10916},
{x:36.267278,y:-93.137697},
{x:36.245126,y:-93.116853},
{x:36.378522,y:-93.587411},
{x:36.391258,y:-93.737883},
{x:36.288062,y:-92.480538},
{x:35.907264,y:-92.656561},
{x:36.355117,y:-92.34111},
{x:36.359876,y:-92.337191},
{x:36.350432,y:-92.369582},
{x:36.086298,y:-94.120819},
{x:36.083752,y:-94.1563},
{x:35.997794,y:-94.197758},
{x:36.055758,y:-94.188235},
{x:36.124199,y:-94.149307},
{x:36.079085,y:-94.203367},
{x:36.35567,y:-94.179787},
{x:36.335302,y:-94.221876},
{x:36.37141,y:-94.220936},
{x:36.358063,y:-94.284473},
{x:36.02782,y:-94.022519},
{x:36.04092,y:-94.249785},
{x:36.263406,y:-94.482545},
{x:36.113303,y:-93.708296},
{x:36.253881,y:-94.146076},
{x:36.443718,y:-94.116543},
{x:36.355616,y:-94.11696},
{x:36.335575,y:-94.181253},
{x:36.334008,y:-94.1273},
{x:36.282924,y:-94.152216},
{x:36.42101,y:-94.223772},
{x:36.175843,y:-94.559548},
{x:36.183455,y:-94.512463},
{x:36.193939,y:-94.182777},
{x:36.175804,y:-94.181461},
{x:36.167355,y:-94.118015},
{x:36.18034,y:-94.139369},
{x:36.175515,y:-94.217104},
{x:35.278718,y:-93.093229},
{x:35.28939,y:-93.1329},
{x:35.254738,y:-92.936737},
{x:35.45478,y:-93.465452},
{x:35.219805,y:-93.163842},
{x:35.292281,y:-93.721558},
{x:35.386404,y:-94.403621},
{x:35.329202,y:-94.406679},
{x:35.361043,y:-94.366842},
{x:35.337729,y:-94.423032},
{x:35.347747,y:-94.338716},
{x:35.335239,y:-94.390656},
{x:35.403189,y:-94.368178},
{x:35.295966,y:-94.388088},
{x:35.486987,y:-94.22663},
{x:35.139677,y:-93.919926},
{x:35.217138,y:-94.271876},
{x:35.511391,y:-93.82092},
{x:35.443271,y:-94.325416},
{x:35.46458,y:-94.356916},
{x:34.904332,y:-94.108053},
{x:35.639051,y:-97.513195},
{x:35.667051,y:-97.496543},
{x:35.057446,y:-98.254992},
{x:35.492961,y:-97.635775},
{x:35.623102,y:-97.484806},
{x:35.63765,y:-97.566488},
{x:35.082005,y:-97.889183},
{x:35.029893,y:-97.934381},
{x:35.49348,y:-97.26828},
{x:35.39538,y:-97.266335},
{x:35.60961,y:-97.460934},
{x:35.652231,y:-97.46256},
{x:35.511358,y:-97.973202},
{x:35.861135,y:-97.425858},
{x:35.43401,y:-97.159988},
{x:34.834981,y:-97.597432},
{x:35.392142,y:-97.721738},
{x:35.291479,y:-97.607594},
{x:35.203563,y:-97.466788},
{x:35.233264,y:-97.441557},
{x:35.221675,y:-97.481156},
{x:35.182737,y:-97.416749},
{x:35.217934,y:-97.424161},
{x:35.217865,y:-97.489441},
{x:35.261328,y:-97.493043},
{x:35.181949,y:-97.506466},
{x:34.740701,y:-97.261131},
{x:36.289138,y:-97.323895},
{x:34.984414,y:-97.371468},
{x:34.505569,y:-97.002686},
{x:35.526973,y:-98.694554},
{x:35.489934,y:-97.760945},
{x:35.466028,y:-97.724123},
{x:35.605543,y:-97.744728},
{x:35.508612,y:-97.743784},
{x:35.464544,y:-97.50321},
{x:35.493679,y:-97.514223},
{x:35.466102,y:-97.529574},
{x:35.493714,y:-97.548063},
{x:35.463263,y:-97.600472},
{x:35.435794,y:-97.565993},
{x:35.450181,y:-97.402481},
{x:35.4635,y:-97.387939},
{x:35.530677,y:-97.477241},
{x:35.51086,y:-97.584179},
{x:35.565018,y:-97.515217},
{x:35.43474,y:-97.43687},
{x:35.539415,y:-97.56533},
{x:35.478045,y:-97.463689},
{x:35.526319,y:-97.539865},
{x:35.522848,y:-97.538078},
{x:35.579844,y:-97.567549},
{x:35.605709,y:-97.546272},
{x:35.59423,y:-97.548844},
{x:35.510569,y:-97.618348},
{x:35.463335,y:-97.622876},
{x:35.461747,y:-97.687988},
{x:35.461822,y:-97.619497},
{x:35.46379,y:-97.655169},
{x:35.4212,y:-97.507233},
{x:35.435845,y:-97.485303},
{x:35.434764,y:-97.370246},
{x:35.593287,y:-97.436295},
{x:35.566518,y:-97.656699},
{x:35.562476,y:-97.653984},
{x:35.58122,y:-97.637852},
{x:35.548105,y:-97.617186},
{x:35.612896,y:-97.54999},
{x:35.388561,y:-97.422885},
{x:35.390674,y:-97.530537},
{x:35.493629,y:-97.370414},
{x:35.607369,y:-97.621479},
{x:35.388995,y:-97.510373},
{x:35.405107,y:-97.547449},
{x:35.376684,y:-97.548431},
{x:35.335661,y:-97.476604},
{x:35.348258,y:-97.498605},
{x:35.320736,y:-97.487811},
{x:35.334777,y:-97.531048},
{x:35.361907,y:-97.565945},
{x:34.188283,y:-97.169752},
{x:34.174893,y:-97.141623},
{x:34.085798,y:-96.769324},
{x:33.940058,y:-97.131428},
{x:34.607979,y:-98.421749},
{x:34.594942,y:-98.40428},
{x:34.622951,y:-98.434742},
{x:34.628782,y:-98.492798},
{x:34.609568,y:-98.474177},
{x:34.637508,y:-99.331689},
{x:34.52836,y:-97.967497},
{x:34.78302,y:-98.297147},
{x:35.501158,y:-98.988129},
{x:35.409094,y:-99.376523},
{x:35.392581,y:-99.405782},
{x:35.265952,y:-99.643586},
{x:36.395861,y:-97.890741},
{x:36.419366,y:-97.893003},
{x:36.390104,y:-97.928821},
{x:36.798008,y:-98.667417},
{x:35.841868,y:-97.933185},
{x:36.388104,y:-97.94609},
{x:36.434136,y:-99.415912},
{x:36.698557,y:-101.48381},
{x:36.746394,y:-95.934694},
{x:36.7139,y:-95.935844},
{x:35.987926,y:-95.885926},
{x:35.839376,y:-96.391521},
{x:35.991549,y:-95.800685},
{x:36.058068,y:-95.81766},
{x:36.075894,y:-95.778199},
{x:36.060468,y:-95.798302},
{x:36.015604,y:-95.797913},
{x:36.076705,y:-95.81462},
{x:36.0033,y:-95.680668},
{x:36.164772,y:-95.762158},
{x:36.291529,y:-95.631386},
{x:36.316809,y:-95.625088},
{x:36.306065,y:-96.46359},
{x:36.322705,y:-95.82457},
{x:35.986118,y:-96.737502},
{x:35.958735,y:-96.009591},
{x:36.023021,y:-95.976477},
{x:36.129015,y:-96.361396},
{x:36.295183,y:-95.83795},
{x:36.263207,y:-95.850409},
{x:36.668073,y:-96.315714},
{x:36.22493,y:-96.795364},
{x:35.984866,y:-97.03306},
{x:36.135834,y:-96.114655},
{x:36.134533,y:-96.119912},
{x:35.988131,y:-96.113181},
{x:35.98786,y:-96.015933},
{x:36.369049,y:-96.009823},
{x:36.11629,y:-97.070408},
{x:36.131128,y:-97.060122},
{x:35.754329,y:-96.6633},
{x:35.751979,y:-96.688952},
{x:36.15619,y:-95.832514},
{x:36.176594,y:-95.975081},
{x:36.158247,y:-95.96668},
{x:36.07846,y:-96.046855},
{x:36.130199,y:-96.006304},
{x:36.143878,y:-95.940069},
{x:36.159356,y:-95.883232},
{x:36.161195,y:-95.886174},
{x:36.140317,y:-95.975941},
{x:36.157697,y:-96.019835},
{x:36.13549,y:-95.850557},
{x:36.060481,y:-96.003564},
{x:36.047702,y:-95.961121},
{x:36.045962,y:-95.868983},
{x:36.132952,y:-95.905088},
{x:36.032234,y:-95.887037},
{x:36.118508,y:-95.887211},
{x:36.092802,y:-95.940682},
{x:36.101291,y:-95.92183},
{x:36.062155,y:-95.921667},
{x:36.060258,y:-95.9731},
{x:36.061383,y:-95.87001},
{x:36.091512,y:-95.885968},
{x:36.103384,y:-95.851635},
{x:36.638308,y:-95.154177},
{x:36.623188,y:-95.147877},
{x:36.169433,y:-95.341255},
{x:36.593777,y:-94.77282},
{x:36.425741,y:-94.785774},
{x:36.888327,y:-94.878113},
{x:36.293227,y:-95.32371},
{x:35.852697,y:-95.420964},
{x:35.754606,y:-95.403032},
{x:35.770989,y:-95.359352},
{x:35.740184,y:-95.339227},
{x:35.471719,y:-95.536538},
{x:35.280458,y:-95.583221},
{x:35.784993,y:-95.239758},
{x:35.43957,y:-95.975961},
{x:35.619593,y:-95.960778},
{x:35.254179,y:-95.10949},
{x:35.897013,y:-94.975881},
{x:35.956741,y:-95.3958},
{x:35.479683,y:-95.306904},
{x:34.907192,y:-95.825583},
{x:34.926412,y:-95.742479},
{x:34.375221,y:-96.135193},
{x:34.917998,y:-95.316598},
{x:36.729006,y:-97.067762},
{x:36.812117,y:-97.337548},
{x:33.997722,y:-96.405954},
{x:34.021861,y:-94.738987},
{x:34.009369,y:-95.493916},
{x:33.894115,y:-94.790895},
{x:35.382282,y:-96.929048},
{x:35.345734,y:-96.911411},
{x:35.386802,y:-96.921633},
{x:34.783074,y:-96.652046},
{x:35.710124,y:-96.860064},
{x:35.08716,y:-96.38231},
{x:35.423887,y:-96.300383},
{x:35.237491,y:-96.67185},
{x:35.273991,y:-96.931022},
{x:35.066032,y:-94.625145},
{x:35.405646,y:-94.522811},
{x:35.449299,y:-94.804345},
{x:35.808875,y:-94.629442},
{x:33.084261,y:-96.645571},
{x:33.129143,y:-96.650118},
{x:33.101004,y:-96.679574},
{x:32.974881,y:-96.890412},
{x:32.967344,y:-96.919996},
{x:33.024349,y:-96.88562},
{x:32.999079,y:-96.910947},
{x:33.31237,y:-96.779996},
{x:32.952348,y:-96.958564},
{x:32.968353,y:-96.994252},
{x:33.762922,y:-96.584221},
{x:33.014617,y:-97.071393},
{x:33.058416,y:-96.750973},
{x:33.042094,y:-96.771214},
{x:33.056092,y:-96.698205},
{x:33.072281,y:-96.771024},
{x:33.057653,y:-96.707108},
{x:33.096288,y:-96.803977},
{x:33.101494,y:-96.734615},
{x:33.005242,y:-96.977336},
{x:33.152048,y:-96.863502},
{x:33.150964,y:-96.803295},
{x:33.174409,y:-96.837403},
{x:33.115637,y:-96.839119},
{x:33.125304,y:-96.891795},
{x:33.019078,y:-96.73416},
{x:33.128358,y:-96.733779},
{x:33.17378,y:-96.734297},
{x:32.866778,y:-96.946446},
{x:32.864743,y:-96.991102},
{x:32.878399,y:-96.962571},
{x:32.962315,y:-96.642474},
{x:32.947335,y:-96.619379},
{x:32.840376,y:-96.597037},
{x:32.931217,y:-96.629703},
{x:32.877107,y:-96.662079},
{x:32.876046,y:-96.618945},
{x:32.857912,y:-96.645837},
{x:32.915757,y:-96.648749},
{x:32.979007,y:-96.663894},
{x:32.970283,y:-96.603941},
{x:32.785857,y:-97.060982},
{x:32.7457,y:-96.993916},
{x:32.709979,y:-97.00281},
{x:32.678717,y:-97.044931},
{x:32.673707,y:-97.00571},
{x:32.673397,y:-97.036385},
{x:32.64757,y:-97.058305},
{x:33.064326,y:-96.889668},
{x:32.813432,y:-96.949059},
{x:32.834827,y:-97.006316},
{x:32.836174,y:-96.929404},
{x:32.835611,y:-97.010334},
{x:32.839749,y:-96.976076},
{x:32.913865,y:-96.95735},
{x:32.920493,y:-96.96773},
{x:32.921411,y:-96.994337},
{x:33.129463,y:-97.040456},
{x:33.071762,y:-97.023149},
{x:33.000381,y:-96.990955},
{x:33.046528,y:-97.011427},
{x:33.007111,y:-97.020596},
{x:33.041775,y:-97.035595},
{x:33.013776,y:-96.993648},
{x:33.176751,y:-96.888193},
{x:33.18024,y:-96.620012},
{x:33.217073,y:-96.63385},
{x:33.168314,y:-96.666727},
{x:33.216619,y:-96.735553},
{x:33.156139,y:-96.682404},
{x:33.200884,y:-96.637729},
{x:33.199537,y:-96.706472},
{x:33.003654,y:-96.681461},
{x:33.020192,y:-96.707272},
{x:33.027523,y:-96.793488},
{x:33.000875,y:-96.767049},
{x:33.071508,y:-97.085237},
{x:32.937779,y:-96.719191},
{x:32.975974,y:-96.719344},
{x:32.950252,y:-96.735838},
{x:32.950642,y:-96.768398},
{x:32.960098,y:-96.683449},
{x:32.945751,y:-96.702757},
{x:32.908073,y:-96.56842},
{x:32.96369,y:-96.464144},
{x:32.898391,y:-96.468334},
{x:32.916895,y:-96.520102},
{x:33.662561,y:-96.597615},
{x:33.027004,y:-96.833948},
{x:33.045919,y:-96.830677},
{x:33.010989,y:-96.611588},
{x:33.008263,y:-96.544571},
{x:32.576314,y:-95.854646},
{x:32.597123,y:-96.940469},
{x:32.597409,y:-96.947002},
{x:32.066176,y:-96.505865},
{x:32.099291,y:-96.443204},
{x:32.602626,y:-96.856578},
{x:32.605138,y:-96.823923},
{x:32.664298,y:-96.907461},
{x:32.873233,y:-97.261619},
{x:32.334693,y:-96.614613},
{x:32.755861,y:-96.465845},
{x:33.043601,y:-96.177889},
{x:32.643852,y:-96.890827},
{x:32.649923,y:-96.706959},
{x:32.572789,y:-96.310321},
{x:32.604906,y:-96.787693},
{x:32.592582,y:-96.819251},
{x:32.372283,y:-96.097157},
{x:32.33123,y:-96.113477},
{x:32.171131,y:-96.012555},
{x:32.788788,y:-96.599068},
{x:32.746867,y:-96.596223},
{x:32.789227,y:-96.596417},
{x:32.766528,y:-96.629172},
{x:32.801321,y:-96.62795},
{x:32.811244,y:-96.61999},
{x:32.823811,y:-96.645427},
{x:32.531487,y:-96.819497},
{x:32.648135,y:-96.534641},
{x:32.738077,y:-96.303964},
{x:32.699396,y:-96.275618},
{x:32.417991,y:-96.841306},
{x:32.707332,y:-96.007876},
{x:32.731755,y:-96.61532},
{x:32.719841,y:-96.617489},
{x:32.969722,y:-96.332768},
{x:32.787505,y:-96.795892},
{x:32.75101,y:-96.6833},
{x:32.781315,y:-96.804976},
{x:32.778749,y:-96.803019},
{x:32.744502,y:-96.812283},
{x:32.815443,y:-96.790682},
{x:32.857591,y:-96.768179},
{x:32.810815,y:-96.771315},
{x:32.782949,y:-96.814271},
{x:32.743503,y:-96.834377},
{x:32.850816,y:-96.813986},
{x:32.776751,y:-96.765143},
{x:32.758166,y:-96.866106},
{x:32.765228,y:-96.896889},
{x:32.749009,y:-96.922325},
{x:32.720902,y:-96.889415},
{x:32.762605,y:-96.89721},
{x:32.779488,y:-96.856083},
{x:32.720438,y:-96.804448},
{x:32.733567,y:-96.645408},
{x:32.713074,y:-96.684853},
{x:32.848306,y:-96.685104},
{x:32.833177,y:-96.703625},
{x:32.818532,y:-96.813839},
{x:32.865263,y:-96.864051},
{x:32.791945,y:-96.749945},
{x:32.704847,y:-96.828076},
{x:32.865439,y:-96.901357},
{x:32.835288,y:-96.671344},
{x:32.793739,y:-96.689247},
{x:32.794921,y:-96.700975},
{x:32.909256,y:-96.854468},
{x:32.896582,y:-96.896274},
{x:32.894476,y:-96.803111},
{x:32.872801,y:-96.763065},
{x:32.857425,y:-96.748227},
{x:32.644603,y:-96.857766},
{x:32.662968,y:-96.824488},
{x:32.721585,y:-96.857683},
{x:32.924806,y:-96.882128},
{x:32.939425,y:-96.838427},
{x:32.670709,y:-96.860646},
{x:32.649556,y:-96.873048},
{x:32.864579,y:-96.700952},
{x:32.949479,y:-96.802948},
{x:32.931119,y:-96.819232},
{x:32.927082,y:-96.811669},
{x:32.92575,y:-96.768617},
{x:32.646125,y:-96.781219},
{x:32.895733,y:-96.722039},
{x:32.908298,y:-96.701035},
{x:32.910854,y:-96.7388},
{x:32.899333,y:-96.768329},
{x:32.909988,y:-96.735082},
{x:32.953528,y:-96.851861},
{x:32.792055,y:-96.778632},
{x:32.816494,y:-96.870769},
{x:32.82642,y:-96.87122},
{x:32.959249,y:-96.816185},
{x:32.647668,y:-96.943375},
{x:32.985847,y:-96.803177},
{x:32.676748,y:-96.600861},
{x:32.906156,y:-97.038019},
{x:32.9067,y:-97.0439},
{x:32.903264,y:-97.037378},
{x:32.8993,y:-97.0441},
{x:32.995106,y:-96.857864},
{x:32.987987,y:-96.841665},
{x:33.119018,y:-96.094934},
{x:33.173821,y:-96.499744},
{x:33.344106,y:-96.565916},
{x:33.603352,y:-96.175574},
{x:33.610342,y:-95.053124},
{x:33.247635,y:-95.910505},
{x:33.17656,y:-94.969977},
{x:33.137165,y:-94.967849},
{x:33.159691,y:-95.235759},
{x:33.662216,y:-95.506364},
{x:32.907109,y:-96.128614},
{x:33.115486,y:-95.593335},
{x:32.948092,y:-95.28966},
{x:33.421033,y:-96.590049},
{x:33.453062,y:-94.082543},
{x:33.463761,y:-94.204348},
{x:33.43461,y:-94.062023},
{x:33.418053,y:-94.098166},
{x:33.464466,y:-94.043591},
{x:33.466502,y:-94.068323},
{x:33.116352,y:-94.180868},
{x:33.472033,y:-94.408431},
{x:32.541287,y:-94.748593},
{x:32.524392,y:-94.78386},
{x:32.542416,y:-94.728035},
{x:32.500217,y:-94.734211},
{x:32.448865,y:-94.722587},
{x:32.548869,y:-94.727036},
{x:32.159009,y:-94.361513},
{x:33.028849,y:-94.72078},
{x:32.741684,y:-94.942202},
{x:32.533757,y:-94.930733},
{x:32.50776,y:-94.585651},
{x:32.13268,y:-94.804711},
{x:32.760604,y:-94.35546},
{x:32.393706,y:-94.865594},
{x:32.389726,y:-94.865119},
{x:32.547692,y:-94.347626},
{x:32.494052,y:-94.35771},
{x:32.996286,y:-94.959846},
{x:32.468948,y:-94.054841},
{x:32.518127,y:-94.85141},
{x:32.334311,y:-95.290546},
{x:32.331873,y:-95.265073},
{x:32.310571,y:-95.275663},
{x:32.306487,y:-95.300173},
{x:32.351685,y:-95.343171},
{x:32.375405,y:-95.331548},
{x:32.351144,y:-95.345685},
{x:32.284285,y:-95.330547},
{x:32.285711,y:-95.303661},
{x:32.451008,y:-95.2837},
{x:32.469579,y:-95.388903},
{x:32.206286,y:-95.836249},
{x:31.962576,y:-95.269608},
{x:32.675068,y:-95.4843},
{x:31.806787,y:-95.151825},
{x:32.235506,y:-95.228503},
{x:31.736549,y:-95.616564},
{x:31.733841,y:-95.610939},
{x:31.447787,y:-96.074954},
{x:31.323414,y:-95.436539},
{x:31.717866,y:-96.173929},
{x:30.937726,y:-95.375869},
{x:31.325273,y:-94.726592},
{x:31.28856,y:-94.729257},
{x:31.331893,y:-94.75944},
{x:31.299634,y:-94.731447},
{x:31.802557,y:-94.191891},
{x:30.909276,y:-93.995398},
{x:31.632721,y:-94.639683},
{x:31.650102,y:-94.652325},
{x:31.618323,y:-94.653078},
{x:31.534464,y:-94.126528},
{x:30.764514,y:-94.416485},
{x:32.64373,y:-97.115817},
{x:32.733753,y:-97.633668},
{x:32.416777,y:-97.225923},
{x:32.736032,y:-97.113758},
{x:32.706592,y:-97.064573},
{x:32.774433,y:-97.096626},
{x:32.750914,y:-97.095435},
{x:32.74985,y:-97.133949},
{x:32.707932,y:-97.115762},
{x:32.709148,y:-97.148086},
{x:32.677511,y:-97.135934},
{x:32.672558,y:-97.193951},
{x:32.668881,y:-97.131742},
{x:32.646815,y:-97.18424},
{x:32.65885,y:-97.135108},
{x:32.675589,y:-97.093365},
{x:32.899429,y:-97.545967},
{x:32.851503,y:-97.112838},
{x:32.833782,y:-97.132883},
{x:32.529613,y:-97.338835},
{x:32.562812,y:-97.317261},
{x:32.379644,y:-97.392453},
{x:32.340361,y:-97.407233},
{x:32.341298,y:-97.415138},
{x:32.890322,y:-97.148681},
{x:32.578002,y:-97.353667},
{x:32.838001,y:-97.083613},
{x:32.432178,y:-97.793633},
{x:32.457854,y:-97.726759},
{x:32.882227,y:-97.098026},
{x:32.924487,y:-97.077621},
{x:32.930877,y:-97.101837},
{x:32.966455,y:-97.41503},
{x:32.838716,y:-97.184448},
{x:32.865552,y:-97.174891},
{x:32.82326,y:-97.202391},
{x:32.847593,y:-97.183776},
{x:32.580885,y:-97.075634},
{x:32.580595,y:-97.132858},
{x:32.474395,y:-96.978657},
{x:32.810148,y:-98.083405},
{x:32.962723,y:-97.678341},
{x:32.731191,y:-97.789974},
{x:32.756449,y:-97.711126},
{x:32.94266,y:-97.115934},
{x:32.741295,y:-97.263209},
{x:32.727031,y:-97.32707},
{x:32.824861,y:-97.314566},
{x:32.840247,y:-97.343662},
{x:32.785604,y:-97.381843},
{x:32.797259,y:-97.349275},
{x:32.747045,y:-97.370641},
{x:32.72779,y:-97.388438},
{x:32.762601,y:-97.483061},
{x:32.706386,y:-97.361292},
{x:32.705233,y:-97.319829},
{x:32.775412,y:-97.288724},
{x:32.758281,y:-97.167873},
{x:32.746739,y:-97.223287},
{x:32.676023,y:-97.321161},
{x:32.725019,y:-97.422745},
{x:32.751742,y:-97.430873},
{x:32.802176,y:-97.263955},
{x:32.802381,y:-97.220392},
{x:32.84179,y:-97.238185},
{x:32.712894,y:-97.281494},
{x:32.764547,y:-97.169268},
{x:32.616825,y:-97.387117},
{x:32.681518,y:-97.462604},
{x:32.861493,y:-97.315695},
{x:32.662712,y:-97.42147},
{x:32.67944,y:-97.392618},
{x:32.63708,y:-97.367209},
{x:32.650301,y:-97.367525},
{x:32.812527,y:-97.426253},
{x:32.810771,y:-97.425182},
{x:32.860608,y:-97.29114},
{x:32.664858,y:-97.239738},
{x:32.628094,y:-97.320504},
{x:32.660602,y:-97.280517},
{x:32.824986,y:-97.060239},
{x:32.957843,y:-97.307534},
{x:32.879075,y:-97.381015},
{x:33.056767,y:-97.483511},
{x:32.883553,y:-97.204191},
{x:32.842698,y:-97.246293},
{x:32.909404,y:-97.190308},
{x:33.19111,y:-97.093654},
{x:33.233111,y:-97.168019},
{x:33.23126,y:-97.135038},
{x:33.190135,y:-97.103692},
{x:33.230512,y:-97.172379},
{x:33.144905,y:-97.105907},
{x:33.2252,y:-96.987943},
{x:33.557919,y:-97.845684},
{x:33.231808,y:-97.596504},
{x:33.624043,y:-97.149601},
{x:32.893984,y:-97.273121},
{x:32.913533,y:-97.309715},
{x:32.934646,y:-97.22977},
{x:32.954341,y:-97.280239},
{x:32.898577,y:-97.237969},
{x:33.382711,y:-96.960344},
{x:32.8971,y:-97.036},
{x:33.009202,y:-97.223017},
{x:32.899778,y:-97.037714},
{x:33.365513,y:-97.180762},
{x:33.670307,y:-96.905453},
{x:33.89998,y:-98.496177},
{x:33.853668,y:-98.500719},
{x:33.960186,y:-98.51475},
{x:33.857503,y:-98.547404},
{x:33.876108,y:-98.54229},
{x:33.881218,y:-98.528403},
{x:34.082057,y:-98.561262},
{x:33.965019,y:-98.671613},
{x:34.161738,y:-99.313636},
{x:32.203583,y:-98.241029},
{x:32.213702,y:-98.215027},
{x:32.75935,y:-98.944095},
{x:33.223992,y:-97.76329},
{x:32.402226,y:-98.791662},
{x:33.088176,y:-98.585835},
{x:31.067656,y:-97.36975},
{x:31.10214,y:-97.357804},
{x:31.116645,y:-97.414623},
{x:31.090868,y:-97.388819},
{x:31.066288,y:-97.367073},
{x:31.055526,y:-97.446715},
{x:31.119609,y:-97.889303},
{x:31.43451,y:-97.731285},
{x:30.810574,y:-97.611999},
{x:31.110377,y:-97.74809},
{x:31.126505,y:-97.727464},
{x:31.108719,y:-97.715021},
{x:31.07837,y:-97.757099},
{x:31.089557,y:-97.729451},
{x:31.079384,y:-97.699526},
{x:31.069955,y:-97.674414},
{x:31.056491,y:-97.657127},
{x:31.076975,y:-97.655105},
{x:31.101769,y:-97.803245},
{x:31.060354,y:-98.181087},
{x:30.647231,y:-97.018461},
{x:30.589795,y:-97.412477},
{x:31.19253,y:-97.310125},
{x:32.008483,y:-97.092346},
{x:32.195925,y:-96.898438},
{x:31.305895,y:-96.875625},
{x:31.685104,y:-96.47317},
{x:31.963188,y:-97.324364},
{x:31.549451,y:-97.124513},
{x:31.605193,y:-97.107574},
{x:31.457421,y:-97.171328},
{x:31.580233,y:-97.1721},
{x:31.628269,y:-97.240045},
{x:31.522854,y:-97.167729},
{x:31.531189,y:-97.188595},
{x:31.48866,y:-97.213165},
{x:31.72932,y:-98.978357},
{x:31.112763,y:-99.335948},
{x:30.509037,y:-99.772898},
{x:31.423283,y:-100.496181},
{x:31.487872,y:-100.460725},
{x:31.466646,y:-100.445864},
{x:29.759185,y:-95.366269},
{x:29.756257,y:-95.367723},
{x:29.748756,y:-95.37086},
{x:29.720278,y:-95.340836},
{x:29.727974,y:-95.34503},
{x:29.744208,y:-95.394916},
{x:29.77501,y:-95.389641},
{x:29.772808,y:-95.401267},
{x:29.775517,y:-95.427265},
{x:29.804617,y:-95.398864},
{x:29.785076,y:-95.361262},
{x:29.789994,y:-95.372427},
{x:29.751429,y:-95.322778},
{x:29.737079,y:-95.305424},
{x:29.800264,y:-95.2676},
{x:29.785627,y:-95.21934},
{x:29.806022,y:-95.165343},
{x:29.768044,y:-95.177074},
{x:29.790473,y:-95.187525},
{x:29.849012,y:-95.303014},
{x:29.814534,y:-95.430066},
{x:29.774933,y:-95.317069},
{x:29.679833,y:-95.3565},
{x:29.701859,y:-95.359848},
{x:29.83001,y:-95.377325},
{x:29.828021,y:-95.375655},
{x:29.716126,y:-95.31294},
{x:29.712924,y:-95.313462},
{x:29.783184,y:-95.520589},
{x:29.788451,y:-95.465565},
{x:29.78039,y:-95.540429},
{x:29.782967,y:-95.483314},
{x:29.678391,y:-95.425979},
{x:29.81031,y:-95.317781},
{x:29.774313,y:-95.246677},
{x:29.72209,y:-95.389771},
{x:29.705627,y:-95.411642},
{x:29.708008,y:-95.401688},
{x:29.64444,y:-95.537992},
{x:29.931016,y:-95.356358},
{x:29.630831,y:-95.23745},
{x:29.592613,y:-95.192327},
{x:29.640604,y:-95.244348},
{x:29.628996,y:-95.229044},
{x:29.654833,y:-95.508021},
{x:29.669988,y:-95.461482},
{x:29.690302,y:-95.534879},
{x:29.703992,y:-95.556008},
{x:29.717321,y:-95.539422},
{x:29.870692,y:-95.40742},
{x:29.917941,y:-95.41559},
{x:29.914761,y:-95.411233},
{x:29.89748,y:-95.429887},
{x:29.902143,y:-95.310931},
{x:29.853925,y:-95.509091},
{x:29.901271,y:-95.551794},
{x:29.852019,y:-95.508711},
{x:29.858508,y:-95.523914},
{x:29.863995,y:-95.60768},
{x:29.736284,y:-95.564531},
{x:29.793171,y:-95.564889},
{x:29.831433,y:-95.562574},
{x:29.931037,y:-95.17583},
{x:29.625586,y:-95.46563},
{x:29.746922,y:-95.572655},
{x:29.614852,y:-95.385648},
{x:29.809626,y:-95.162194},
{x:29.658811,y:-95.355821},
{x:29.613253,y:-95.42025},
{x:29.678332,y:-95.402559},
{x:29.801936,y:-95.484421},
{x:29.791288,y:-95.545123},
{x:29.739,y:-95.463257},
{x:29.752786,y:-95.459049},
{x:29.738049,y:-95.502541},
{x:29.731995,y:-95.484723},
{x:29.577628,y:-95.107264},
{x:29.551504,y:-95.126369},
{x:29.548889,y:-95.098794},
{x:29.938451,y:-95.397929},
{x:29.95055,y:-95.411749},
{x:29.671556,y:-95.276516},
{x:29.722028,y:-95.519887},
{x:29.735464,y:-95.511693},
{x:29.736902,y:-95.53552},
{x:29.958083,y:-95.542946},
{x:29.917048,y:-95.614034},
{x:29.909234,y:-95.584149},
{x:29.919884,y:-95.602276},
{x:29.959809,y:-95.477721},
{x:30.00575,y:-95.466916},
{x:29.998182,y:-95.484342},
{x:29.985043,y:-95.502625},
{x:29.999053,y:-95.582334},
{x:29.946223,y:-95.563362},
{x:29.965989,y:-95.58581},
{x:29.689042,y:-95.621203},
{x:29.688422,y:-95.567756},
{x:29.677449,y:-95.528089},
{x:29.853882,y:-95.399478},
{x:29.846324,y:-95.384031},
{x:29.735805,y:-95.606859},
{x:29.757444,y:-95.625733},
{x:29.739675,y:-95.643608},
{x:29.783468,y:-95.575246},
{x:29.770546,y:-95.601642},
{x:29.812958,y:-95.544483},
{x:29.706076,y:-95.478112},
{x:29.721226,y:-95.647391},
{x:29.733193,y:-95.591471},
{x:29.678136,y:-95.644237},
{x:29.703265,y:-95.587035},
{x:29.702868,y:-95.687218},
{x:29.702446,y:-95.643369},
{x:29.830146,y:-95.685854},
{x:29.842542,y:-95.645192},
{x:29.91244,y:-95.480895},
{x:29.936678,y:-95.497452},
{x:29.685637,y:-95.296115},
{x:29.702968,y:-95.294343},
{x:29.601835,y:-95.227933},
{x:29.614289,y:-95.218795},
{x:29.965057,y:-95.420187},
{x:30.019294,y:-95.433696},
{x:29.86237,y:-95.472952},
{x:29.832624,y:-95.481891},
{x:29.812414,y:-95.459841},
{x:29.847133,y:-95.343359},
{x:29.870469,y:-95.328935},
{x:29.880213,y:-95.682229},
{x:29.87696,y:-95.651795},
{x:29.884234,y:-95.644141},
{x:29.67684,y:-95.457411},
{x:29.688875,y:-95.458182},
{x:29.677394,y:-95.49407},
{x:29.727929,y:-95.419049},
{x:29.6741,y:-95.561339},
{x:29.67924,y:-95.561351},
{x:29.654568,y:-95.566789},
{x:30.331114,y:-95.479495},
{x:30.335622,y:-95.467964},
{x:30.313127,y:-95.468452},
{x:30.36727,y:-95.483788},
{x:30.32411,y:-95.499591},
{x:30.32293,y:-95.40168},
{x:30.388639,y:-95.694695},
{x:30.335637,y:-95.097611},
{x:29.979605,y:-95.248047},
{x:30.008997,y:-95.283549},
{x:30.009476,y:-95.270337},
{x:30.005413,y:-95.297983},
{x:30.002752,y:-95.26795},
{x:30.019671,y:-95.379391},
{x:30.070441,y:-95.244585},
{x:30.048997,y:-95.185486},
{x:30.067918,y:-95.215087},
{x:30.705983,y:-95.545887},
{x:30.71811,y:-95.567676},
{x:29.981024,y:-95.164603},
{x:29.997753,y:-95.173125},
{x:30.712082,y:-94.954537},
{x:30.220387,y:-95.569528},
{x:30.181601,y:-95.537151},
{x:30.21277,y:-95.753607},
{x:30.359923,y:-95.597612},
{x:30.384342,y:-95.650395},
{x:30.106981,y:-95.233703},
{x:30.104055,y:-95.238099},
{x:30.044246,y:-95.3465},
{x:30.064535,y:-95.43039},
{x:30.073419,y:-95.43494},
{x:30.112516,y:-95.553245},
{x:30.09016,y:-95.631505},
{x:30.09914,y:-95.593444},
{x:30.013757,y:-95.589533},
{x:30.036586,y:-95.605495},
{x:30.080467,y:-95.700844},
{x:30.014219,y:-95.595067},
{x:30.419842,y:-95.492957},
{x:30.035072,y:-95.554883},
{x:30.022328,y:-95.522403},
{x:30.041463,y:-95.488594},
{x:30.123508,y:-95.457692},
{x:30.209983,y:-95.459942},
{x:30.183437,y:-95.488272},
{x:30.166807,y:-95.504374},
{x:30.208453,y:-95.455005},
{x:30.128782,y:-95.442727},
{x:30.12107,y:-95.398355},
{x:30.073561,y:-95.509904},
{x:30.076994,y:-95.512253},
{x:30.070004,y:-95.449181},
{x:29.939707,y:-95.268964},
{x:29.936797,y:-95.252688},
{x:29.551002,y:-95.697739},
{x:29.690155,y:-95.774638},
{x:29.663982,y:-95.742143},
{x:28.983953,y:-95.934007},
{x:28.983572,y:-95.964951},
{x:29.777646,y:-95.952197},
{x:29.994682,y:-95.624287},
{x:29.975885,y:-95.69779},
{x:29.968497,y:-95.69039},
{x:29.964821,y:-95.649533},
{x:29.947079,y:-95.627986},
{x:29.942917,y:-95.715587},
{x:29.992009,y:-95.748224},
{x:29.919144,y:-95.686905},
{x:29.392143,y:-96.076928},
{x:29.206839,y:-96.277478},
{x:29.696103,y:-95.846967},
{x:30.108171,y:-96.046681},
{x:30.096933,y:-96.078783},
{x:29.78912,y:-95.722262},
{x:29.860692,y:-95.716435},
{x:29.862326,y:-95.719958},
{x:29.819721,y:-95.768648},
{x:29.793255,y:-95.719504},
{x:29.780602,y:-95.718467},
{x:29.783057,y:-95.753122},
{x:29.734545,y:-95.753754},
{x:29.568067,y:-95.565604},
{x:29.573652,y:-95.568084},
{x:29.534547,y:-95.516274},
{x:29.624076,y:-95.744407},
{x:29.543246,y:-95.751816},
{x:29.532016,y:-95.858412},
{x:29.533059,y:-95.807801},
{x:29.560025,y:-95.789467},
{x:29.766697,y:-96.153178},
{x:29.643987,y:-95.573029},
{x:29.620703,y:-95.569672},
{x:29.613427,y:-95.644528},
{x:29.634616,y:-95.619092},
{x:29.591637,y:-95.602206},
{x:29.601165,y:-95.682003},
{x:29.60144,y:-95.6322},
{x:29.136772,y:-95.653151},
{x:29.322617,y:-96.097786},
{x:29.583146,y:-95.527325},
{x:29.749788,y:-95.798871},
{x:29.783967,y:-95.804148},
{x:29.716436,y:-95.77805},
{x:29.776382,y:-95.831192},
{x:29.785563,y:-95.822533},
{x:29.715545,y:-95.813882},
{x:29.690287,y:-95.220145},
{x:29.684904,y:-95.155762},
{x:29.711186,y:-95.200891},
{x:29.665441,y:-95.194146},
{x:29.650138,y:-95.172066},
{x:29.63122,y:-95.168388},
{x:29.664131,y:-95.123825},
{x:29.646591,y:-95.161293},
{x:29.693458,y:-95.21033},
{x:29.373702,y:-95.090753},
{x:29.421904,y:-95.230702},
{x:29.400727,y:-95.246367},
{x:29.83931,y:-94.649619},
{x:29.181731,y:-95.43232},
{x:29.499685,y:-94.995081},
{x:29.770244,y:-94.978238},
{x:29.823495,y:-94.897822},
{x:29.740305,y:-94.980577},
{x:29.730849,y:-94.957406},
{x:29.776559,y:-94.974457},
{x:29.79828,y:-95.032912},
{x:29.803492,y:-94.981123},
{x:29.777412,y:-95.123948},
{x:29.011878,y:-95.420627},
{x:29.89428,y:-95.065689},
{x:29.88813,y:-95.062753},
{x:30.044394,y:-94.88996},
{x:29.707375,y:-95.09283},
{x:29.666851,y:-95.100102},
{x:29.699174,y:-95.123287},
{x:29.44803,y:-95.077011},
{x:28.969123,y:-95.371179},
{x:29.5518,y:-95.159381},
{x:29.509229,y:-95.189115},
{x:29.530573,y:-95.203046},
{x:29.732259,y:-95.239795},
{x:29.305098,y:-94.771562},
{x:29.290891,y:-94.825848},
{x:29.264949,y:-94.833143},
{x:29.267489,y:-94.826416},
{x:29.53481,y:-95.01834},
{x:29.04877,y:-95.461494},
{x:29.358876,y:-94.980858},
{x:29.402028,y:-95.037567},
{x:29.651848,y:-95.027669},
{x:29.5042,y:-95.043882},
{x:29.507362,y:-95.094387},
{x:29.542134,y:-95.062091},
{x:29.466389,y:-95.087924},
{x:29.503812,y:-95.115305},
{x:29.470211,y:-95.089744},
{x:30.075561,y:-94.775292},
{x:30.056949,y:-94.796116},
{x:29.54588,y:-95.23587},
{x:29.55959,y:-95.348173},
{x:29.567684,y:-95.286738},
{x:29.556912,y:-95.3909},
{x:29.558102,y:-95.382598},
{x:29.572059,y:-95.026747},
{x:29.663032,y:-95.236153},
{x:29.394889,y:-94.949705},
{x:29.560298,y:-95.142166},
{x:29.538302,y:-95.146206},
{x:30.023089,y:-93.84472},
{x:29.937217,y:-93.934466},
{x:29.95662,y:-94.006915},
{x:30.114132,y:-93.821445},
{x:30.10754,y:-93.74675},
{x:29.941936,y:-93.993716},
{x:29.942135,y:-93.9899},
{x:30.340449,y:-94.146915},
{x:30.344414,y:-94.181591},
{x:30.256623,y:-94.198762},
{x:30.132284,y:-94.016317},
{x:29.83391,y:-94.385063},
{x:30.09536,y:-94.11137},
{x:30.082047,y:-94.134954},
{x:30.057784,y:-94.086217},
{x:30.039696,y:-94.114835},
{x:30.078686,y:-94.168754},
{x:30.123155,y:-94.157242},
{x:30.066445,y:-94.190471},
{x:30.13385,y:-94.166085},
{x:30.64622,y:-96.349785},
{x:30.629831,y:-96.377687},
{x:30.665907,y:-96.327268},
{x:30.657411,y:-96.330405},
{x:30.695643,y:-96.361588},
{x:30.667403,y:-96.371358},
{x:30.165805,y:-96.427938},
{x:30.143422,y:-96.39737},
{x:30.536118,y:-96.693539},
{x:30.603945,y:-96.339326},
{x:30.622104,y:-96.341929},
{x:30.607388,y:-96.309816},
{x:30.583076,y:-96.287452},
{x:30.561297,y:-96.261855},
{x:30.875796,y:-96.591401},
{x:30.895405,y:-96.602756},
{x:30.963634,y:-95.886486},
{x:30.398185,y:-96.070743},
{x:28.767799,y:-96.978073},
{x:28.823979,y:-97.000354},
{x:28.810213,y:-96.977284},
{x:28.867955,y:-96.997082},
{x:29.093797,y:-97.288783},
{x:28.985132,y:-96.650768},
{x:28.619561,y:-96.639279},
{x:29.778901,y:-98.7244},
{x:29.353793,y:-98.880688},
{x:29.982266,y:-98.902595},
{x:28.45056,y:-99.239765},
{x:29.131921,y:-98.895546},
{x:28.336977,y:-98.023856},
{x:30.062794,y:-99.171041},
{x:30.069214,y:-99.113512},
{x:30.050211,y:-99.138002},
{x:27.530284,y:-99.475916},
{x:27.543587,y:-99.503871},
{x:27.506221,y:-99.472844},
{x:27.50935,y:-99.503839},
{x:27.558783,y:-99.447174},
{x:27.55243,y:-99.50657},
{x:27.518206,y:-99.451698},
{x:27.610509,y:-99.472378},
{x:27.590466,y:-99.512399},
{x:27.610843,y:-99.462383},
{x:27.567612,y:-99.501544},
{x:27.684176,y:-99.461373},
{x:27.47458,y:-99.47611},
{x:27.574761,y:-99.472852},
{x:27.453294,y:-99.474178},
{x:27.446776,y:-99.477365},
{x:29.228559,y:-98.789978},
{x:28.897549,y:-99.093915},
{x:28.944433,y:-98.505892},
{x:29.797789,y:-98.429194},
{x:29.85081,y:-98.409283},
{x:28.521457,y:-98.185201},
{x:26.894099,y:-99.263797},
{x:28.422186,y:-97.757269},
{x:29.624202,y:-98.240091},
{x:29.464254,y:-98.289402},
{x:29.512033,y:-98.308761},
{x:29.497856,y:-98.325083},
{x:29.535313,y:-98.331934},
{x:29.156108,y:-98.168397},
{x:28.814794,y:-97.859144},
{x:29.701118,y:-98.094193},
{x:29.676308,y:-98.155985},
{x:29.685171,y:-98.124908},
{x:29.717801,y:-98.162338},
{x:29.843947,y:-98.266796},
{x:29.548336,y:-98.292014},
{x:29.604404,y:-98.275331},
{x:29.598707,y:-98.277101},
{x:29.582128,y:-97.99434},
{x:29.569572,y:-97.942743},
{x:29.44488,y:-98.52684},
{x:29.483944,y:-98.545982},
{x:29.423837,y:-98.487644},
{x:29.41996,y:-98.527537},
{x:29.411839,y:-98.506525},
{x:29.427022,y:-98.501629},
{x:29.438467,y:-98.449802},
{x:29.492727,y:-98.434881},
{x:29.490175,y:-98.437377},
{x:29.469971,y:-98.463164},
{x:29.400902,y:-98.461611},
{x:29.357837,y:-98.550391},
{x:29.473907,y:-98.507585},
{x:29.440025,y:-98.499154},
{x:29.487848,y:-98.537726},
{x:29.323028,y:-98.477363},
{x:29.354962,y:-98.480584},
{x:29.352807,y:-98.479785},
{x:29.514772,y:-98.481972},
{x:29.547021,y:-98.508677},
{x:29.507011,y:-98.499871},
{x:29.544181,y:-98.410596},
{x:29.519821,y:-98.462128},
{x:29.568003,y:-98.385719},
{x:29.509879,y:-98.37294},
{x:29.484756,y:-98.401666},
{x:29.430837,y:-98.404958},
{x:29.480104,y:-98.362466},
{x:29.40046,y:-98.386205},
{x:29.220875,y:-98.478404},
{x:29.366129,y:-98.504529},
{x:29.342271,y:-98.504255},
{x:29.397798,y:-98.387424},
{x:29.348215,y:-98.43353},
{x:29.375079,y:-98.45639},
{x:29.357075,y:-98.527975},
{x:29.386084,y:-98.52461},
{x:29.397579,y:-98.557572},
{x:29.442657,y:-98.642433},
{x:29.41711,y:-98.650524},
{x:29.406651,y:-98.629639},
{x:29.381204,y:-98.638262},
{x:29.449819,y:-98.569864},
{x:29.457779,y:-98.555025},
{x:29.503991,y:-98.584983},
{x:29.529585,y:-98.561896},
{x:29.564501,y:-98.596069},
{x:29.574215,y:-98.545321},
{x:29.51422,y:-98.535593},
{x:29.612306,y:-98.470674},
{x:29.553331,y:-98.467961},
{x:29.607085,y:-98.507765},
{x:29.585626,y:-98.475965},
{x:29.545837,y:-98.366964},
{x:29.405691,y:-98.575855},
{x:29.429916,y:-98.549199},
{x:29.485495,y:-98.59433},
{x:29.466862,y:-98.604374},
{x:29.33976,y:-98.622428},
{x:29.435331,y:-98.70931},
{x:29.45181,y:-98.630066},
{x:29.433333,y:-98.714082},
{x:29.578984,y:-98.441603},
{x:29.599721,y:-98.419293},
{x:29.591773,y:-98.35584},
{x:29.54367,y:-98.61496},
{x:29.564127,y:-98.58954},
{x:29.550285,y:-98.664396},
{x:29.518673,y:-98.633381},
{x:29.51945,y:-98.638593},
{x:29.444305,y:-98.663432},
{x:29.484553,y:-98.667567},
{x:29.468812,y:-98.648896},
{x:29.461415,y:-98.692275},
{x:29.496827,y:-98.710743},
{x:29.500907,y:-98.704803},
{x:29.515957,y:-98.695732},
{x:29.588428,y:-98.629526},
{x:29.676004,y:-98.634366},
{x:29.615513,y:-98.604634},
{x:29.642402,y:-98.489879},
{x:29.635601,y:-98.456176},
{x:27.752441,y:-98.082144},
{x:27.769695,y:-98.042969},
{x:27.753267,y:-98.060293},
{x:27.923564,y:-97.175531},
{x:27.227062,y:-98.139989},
{x:27.490799,y:-97.845789},
{x:27.515425,y:-97.855659},
{x:28.112552,y:-97.819618},
{x:28.310755,y:-97.274538},
{x:27.785439,y:-97.655629},
{x:28.036766,y:-97.042023},
{x:27.882701,y:-97.318599},
{x:28.012437,y:-97.532587},
{x:27.76423,y:-97.403122},
{x:27.777941,y:-97.412167},
{x:27.796706,y:-97.440735},
{x:27.846607,y:-97.586501},
{x:27.727758,y:-97.38295},
{x:27.739541,y:-97.388412},
{x:27.708482,y:-97.370255},
{x:27.725017,y:-97.362124},
{x:27.692249,y:-97.34171},
{x:27.6654,y:-97.396077},
{x:27.678389,y:-97.368387},
{x:27.698509,y:-97.411427},
{x:27.727412,y:-97.412313},
{x:27.752263,y:-97.419465},
{x:27.742888,y:-97.440483},
{x:27.665974,y:-97.283411},
{x:26.22921,y:-98.225167},
{x:26.199642,y:-98.205812},
{x:25.948421,y:-97.504128},
{x:26.220043,y:-98.259821},
{x:26.185819,y:-98.235639},
{x:26.180673,y:-98.189874},
{x:26.188374,y:-98.209794},
{x:26.186557,y:-98.232393},
{x:26.2438,y:-98.245014},
{x:26.240568,y:-98.24648},
{x:26.268868,y:-98.218203},
{x:26.192024,y:-98.121404},
{x:26.191093,y:-98.131304},
{x:25.926565,y:-97.511905},
{x:25.975185,y:-97.520356},
{x:25.893775,y:-97.447317},
{x:25.901047,y:-97.490927},
{x:25.921255,y:-97.491405},
{x:25.925001,y:-97.477012},
{x:25.943663,y:-97.426725},
{x:25.919916,y:-97.46697},
{x:25.967408,y:-97.513509},
{x:25.952244,y:-97.486278},
{x:26.178912,y:-98.040966},
{x:26.26189,y:-98.168213},
{x:26.299655,y:-98.146131},
{x:26.304312,y:-98.18585},
{x:26.33685,y:-98.157317},
{x:26.293112,y:-97.992807},
{x:26.181292,y:-97.72039},
{x:26.171918,y:-97.6729},
{x:26.198574,y:-97.679922},
{x:26.16476,y:-97.822997},
{x:26.071901,y:-97.475018},
{x:26.194476,y:-98.289113},
{x:26.248177,y:-98.475368},
{x:26.288633,y:-98.314087},
{x:26.260551,y:-98.315727},
{x:26.257531,y:-98.315599},
{x:26.246687,y:-98.442757},
{x:26.233966,y:-98.179535},
{x:26.068968,y:-97.222525},
{x:26.482453,y:-97.768295},
{x:26.370392,y:-98.798329},
{x:26.402285,y:-99.011368},
{x:26.146543,y:-97.636999},
{x:26.125974,y:-97.63656},
{x:26.191385,y:-98.166789},
{x:26.153181,y:-97.957381},
{x:26.174567,y:-97.987661},
{x:26.172143,y:-97.99137},
{x:26.091091,y:-97.164315},
{x:30.10628,y:-97.334414},
{x:30.034962,y:-97.686982},
{x:30.08909,y:-97.817298},
{x:30.086286,y:-97.824495},
{x:30.759133,y:-98.229739},
{x:30.175705,y:-97.525998},
{x:30.482634,y:-97.831358},
{x:30.52131,y:-97.830957},
{x:30.192014,y:-98.084054},
{x:30.337473,y:-97.372494},
{x:30.267946,y:-98.862891},
{x:30.648607,y:-97.678085},
{x:30.645204,y:-97.692036},
{x:30.681897,y:-97.71675},
{x:29.516505,y:-97.460218},
{x:30.541517,y:-97.55941},
{x:30.011265,y:-97.862736},
{x:30.560246,y:-97.845728},
{x:29.869516,y:-97.668282},
{x:30.349796,y:-97.555648},
{x:30.582869,y:-98.280922},
{x:30.451458,y:-97.603902},
{x:30.447799,y:-97.639645},
{x:30.48144,y:-97.672498},
{x:30.533808,y:-97.611296},
{x:30.535262,y:-97.690628},
{x:30.518364,y:-97.65688},
{x:30.509461,y:-97.68717},
{x:30.559944,y:-97.684336},
{x:29.827162,y:-97.987476},
{x:29.867898,y:-97.938288},
{x:29.883509,y:-97.91227},
{x:29.884198,y:-97.922508},
{x:30.191227,y:-97.768758},
{x:30.221048,y:-97.754093},
{x:30.223619,y:-97.762868},
{x:30.196994,y:-97.786286},
{x:30.260309,y:-97.758118},
{x:30.237517,y:-97.752039},
{x:30.214501,y:-97.734332},
{x:30.496309,y:-97.775448},
{x:30.272319,y:-97.696916},
{x:30.313138,y:-97.707297},
{x:30.329107,y:-97.660598},
{x:30.407351,y:-97.851264},
{x:30.443666,y:-97.741432},
{x:30.439289,y:-97.697683},
{x:30.456591,y:-97.66788},
{x:30.338614,y:-97.755721},
{x:30.232133,y:-97.823183},
{x:30.306214,y:-97.937058},
{x:30.235731,y:-97.724067},
{x:30.273769,y:-97.79864},
{x:30.235249,y:-97.875813},
{x:30.165041,y:-97.79056},
{x:30.174307,y:-97.826591},
{x:30.459771,y:-97.792411},
{x:30.465483,y:-97.80319},
{x:30.323731,y:-97.728334},
{x:30.336873,y:-97.693462},
{x:26.201453,y:-98.324314},
{x:30.413617,y:-97.676629},
{x:30.388076,y:-97.649287},
{x:30.339586,y:-97.690017},
{x:30.422547,y:-97.67088},
{x:30.364144,y:-97.696916},
{x:30.391459,y:-97.720592},
{x:30.358184,y:-97.731012},
{x:30.412538,y:-97.690462},
{x:30.399167,y:-97.746896},
{x:29.235117,y:-99.754304},
{x:29.212629,y:-99.778155},
{x:28.523602,y:-99.855831},
{x:29.369649,y:-100.887024},
{x:29.39557,y:-100.900966},
{x:29.380093,y:-100.903769},
{x:28.68663,y:-100.473048},
{x:28.739268,y:-100.496806},
{x:28.701165,y:-100.480675},
{x:28.708499,y:-100.485504},
{x:29.348056,y:-99.123122},
{x:29.691252,y:-96.538396},
{x:29.695199,y:-97.103806},
{x:30.179076,y:-96.916178},
{x:29.904776,y:-96.910396},
{x:29.692953,y:-96.77712},
{x:35.663266,y:-101.399125},
{x:34.981629,y:-101.919694},
{x:36.060704,y:-102.514187},
{x:35.850686,y:-101.973901},
{x:34.81,y:-102.409463},
{x:35.545109,y:-100.972415},
{x:36.388509,y:-100.802299},
{x:34.195999,y:-101.75315},
{x:34.188708,y:-101.750931},
{x:35.228634,y:-100.249553},
{x:35.193229,y:-101.817873},
{x:35.191312,y:-101.794891},
{x:35.196345,y:-101.740985},
{x:35.192486,y:-101.884784},
{x:35.221651,y:-101.830011},
{x:35.176994,y:-101.920805},
{x:35.164117,y:-101.887311},
{x:35.170007,y:-101.863343},
{x:35.117297,y:-101.906445},
{x:35.180761,y:-101.938725},
{x:35.215368,y:-101.901811},
{x:34.434867,y:-100.228115},
{x:33.181822,y:-102.273683},
{x:32.964046,y:-102.834796},
{x:32.737467,y:-101.951091},
{x:33.587021,y:-102.367148},
{x:33.904538,y:-102.326758},
{x:34.231924,y:-102.734324},
{x:33.19136,y:-101.377813},
{x:32.719886,y:-102.656665},
{x:33.431862,y:-101.663554},
{x:33.590421,y:-101.858829},
{x:33.577474,y:-101.866355},
{x:33.54828,y:-101.84601},
{x:33.577176,y:-101.94707},
{x:33.598229,y:-101.869907},
{x:33.549202,y:-101.858967},
{x:33.549237,y:-101.905499},
{x:33.539124,y:-101.921767},
{x:33.591802,y:-101.922916},
{x:33.504901,y:-101.903096},
{x:33.528525,y:-101.86812},
{x:33.519179,y:-101.958239},
{x:33.520219,y:-101.921733},
{x:32.412774,y:-99.489263},
{x:32.707423,y:-100.918575},
{x:32.451147,y:-100.397326},
{x:32.46259,y:-99.705483},
{x:32.4525,y:-99.767587},
{x:32.399891,y:-99.759412},
{x:32.431735,y:-99.761484},
{x:31.974427,y:-102.074083},
{x:32.001256,y:-102.116486},
{x:31.91494,y:-102.215638},
{x:32.034132,y:-102.130486},
{x:32.305844,y:-102.541417},
{x:32.265724,y:-101.474682},
{x:32.228684,y:-101.471033},
{x:30.899437,y:-102.905605},
{x:31.576946,y:-102.892234},
{x:31.8601,y:-102.348817},
{x:31.864584,y:-102.301962},
{x:31.89494,y:-102.329022},
{x:31.881394,y:-102.38524},
{x:31.817514,y:-102.380939},
{x:31.864019,y:-102.39827},
{x:31.399328,y:-103.480478},
{x:31.998489,y:-106.581421},
{x:31.999742,y:-106.605561},
{x:30.360684,y:-103.65342},
{x:31.509605,y:-106.149595},
{x:31.034796,y:-104.8555},
{x:31.703998,y:-106.320048},
{x:31.755999,y:-106.486485},
{x:31.774944,y:-106.501849},
{x:31.779018,y:-106.461825},
{x:31.827666,y:-106.444654},
{x:31.769828,y:-106.426221},
{x:31.681237,y:-106.312576},
{x:31.729004,y:-106.346571},
{x:31.682848,y:-106.311586},
{x:31.700169,y:-106.339441},
{x:31.859843,y:-106.558665},
{x:31.836163,y:-106.565288},
{x:31.830677,y:-106.531309},
{x:31.741726,y:-106.378098},
{x:31.770448,y:-106.3768},
{x:31.813141,y:-106.556216},
{x:31.899224,y:-106.437969},
{x:31.896243,y:-106.43824},
{x:31.879889,y:-106.422099},
{x:31.775922,y:-106.379298},
{x:31.78321,y:-106.421145},
{x:31.777103,y:-106.384455},
{x:31.788923,y:-106.396127},
{x:31.753029,y:-106.344352},
{x:31.654482,y:-106.289929},
{x:31.657886,y:-106.242454},
{x:31.682304,y:-106.199363},
{x:31.909429,y:-106.584748},
{x:31.840587,y:-106.573544},
{x:31.938385,y:-106.408243},
{x:31.753147,y:-106.339953},
{x:31.728237,y:-106.303411},
{x:31.759808,y:-106.269304},
{x:31.768154,y:-106.319645},
{x:31.751182,y:-106.301374},
{x:31.80123,y:-106.302838},
{x:31.755713,y:-106.270849},
{x:31.797148,y:-106.229489},
{x:31.793723,y:-106.267235},
{x:31.804989,y:-106.262033},
{x:39.793261,y:-105.080799},
{x:39.802416,y:-105.107414},
{x:39.816206,y:-105.080719},
{x:39.842248,y:-105.082611},
{x:39.812939,y:-105.166713},
{x:39.740594,y:-104.859771},
{x:39.726277,y:-104.822458},
{x:39.761089,y:-104.791892},
{x:39.740776,y:-104.821967},
{x:39.690641,y:-104.865296},
{x:39.696113,y:-104.838278},
{x:39.653572,y:-104.776767},
{x:39.674167,y:-104.791611},
{x:39.66791,y:-104.863263},
{x:39.641805,y:-104.828416},
{x:39.651795,y:-104.87899},
{x:39.638054,y:-104.791808},
{x:39.613104,y:-104.756228},
{x:39.599167,y:-104.803868},
{x:39.606583,y:-104.704744},
{x:39.588202,y:-104.688457},
{x:39.697877,y:-104.809374},
{x:39.914764,y:-105.057226},
{x:39.877259,y:-105.096394},
{x:39.923566,y:-105.091329},
{x:39.929616,y:-105.129435},
{x:39.804557,y:-104.927997},
{x:39.885921,y:-104.810027},
{x:39.806484,y:-104.929661},
{x:40.001399,y:-105.103748},
{x:39.96126,y:-105.162707},
{x:39.867969,y:-105.056775},
{x:39.82531,y:-105.055687},
{x:39.856008,y:-105.059979},
{x:39.831251,y:-105.024407},
{x:39.827692,y:-105.052699},
{x:39.771843,y:-105.081006},
{x:39.741379,y:-104.434708},
{x:39.412331,y:-104.873021},
{x:39.380033,y:-104.866299},
{x:39.652873,y:-104.995481},
{x:39.651268,y:-105.026156},
{x:39.59578,y:-104.886779},
{x:39.623536,y:-105.021228},
{x:39.602029,y:-104.987548},
{x:39.610991,y:-105.020653},
{x:39.623736,y:-104.987261},
{x:39.570467,y:-104.989045},
{x:39.56694,y:-104.923642},
{x:39.566621,y:-105.081744},
{x:39.615994,y:-105.092012},
{x:39.538561,y:-104.88492},
{x:39.594592,y:-104.866191},
{x:39.548942,y:-104.995331},
{x:39.560137,y:-104.905424},
{x:39.580089,y:-105.134199},
{x:39.608325,y:-105.150291},
{x:39.622911,y:-105.111319},
{x:39.094833,y:-104.864277},
{x:39.53427,y:-104.794325},
{x:39.51755,y:-104.764227},
{x:39.550688,y:-104.773968},
{x:39.741836,y:-104.988097},
{x:39.740205,y:-104.980795},
{x:39.745212,y:-105.005811},
{x:39.73965,y:-105.004633},
{x:39.740108,y:-105.039751},
{x:39.763696,y:-104.940086},
{x:39.710908,y:-104.986929},
{x:39.677828,y:-104.941283},
{x:39.769114,y:-105.030666},
{x:39.742263,y:-105.079641},
{x:39.744367,y:-105.053779},
{x:39.739733,y:-105.109946},
{x:39.801588,y:-104.977435},
{x:39.777861,y:-104.978743},
{x:39.710688,y:-105.023508},
{x:39.681991,y:-105.025599},
{x:39.740413,y:-104.915737},
{x:39.789011,y:-105.025897},
{x:39.86314,y:-105.024602},
{x:39.691149,y:-104.992785},
{x:39.678853,y:-104.915127},
{x:39.7062,y:-104.920349},
{x:39.653472,y:-104.906839},
{x:39.683235,y:-105.080745},
{x:39.711543,y:-105.072598},
{x:39.695117,y:-104.941143},
{x:39.655341,y:-105.055381},
{x:39.709213,y:-105.134679},
{x:39.876073,y:-104.98587},
{x:39.884168,y:-104.944098},
{x:39.881106,y:-104.978631},
{x:39.854613,y:-104.977477},
{x:39.913422,y:-104.940945},
{x:39.929029,y:-105.012844},
{x:39.940265,y:-104.990292},
{x:39.885781,y:-104.994653},
{x:39.624482,y:-104.903287},
{x:39.769024,y:-104.902018},
{x:39.783349,y:-104.773885},
{x:39.777116,y:-104.847639},
{x:39.91461,y:-104.97965},
{x:39.862769,y:-104.673943},
{x:39.853966,y:-104.673379},
{x:39.859071,y:-104.673579},
{x:39.848574,y:-104.99562},
{x:39.884732,y:-105.02139},
{x:40.019305,y:-105.25792},
{x:39.999461,y:-105.255144},
{x:39.699863,y:-105.329575},
{x:39.757693,y:-105.142863},
{x:39.739444,y:-105.197302},
{x:40.06244,y:-105.927272},
{x:39.741507,y:-105.496917},
{x:40.46839,y:-106.825661},
{x:39.919016,y:-105.785168},
{x:39.62719,y:-106.064531},
{x:40.158333,y:-105.048488},
{x:40.14979,y:-105.131468},
{x:40.192544,y:-105.101713},
{x:40.153523,y:-105.103179},
{x:40.160083,y:-104.944607},
{x:40.159359,y:-104.980275},
{x:40.086986,y:-104.984938},
{x:40.378653,y:-105.516567},
{x:40.580259,y:-104.996428},
{x:40.538515,y:-105.038434},
{x:40.610204,y:-105.077269},
{x:40.523929,y:-105.07827},
{x:40.556045,y:-105.077633},
{x:40.575058,y:-105.100002},
{x:40.334174,y:-104.906751},
{x:40.407664,y:-105.102155},
{x:40.408859,y:-105.001582},
{x:40.422085,y:-105.075142},
{x:40.702372,y:-105.000021},
{x:40.479425,y:-104.915856},
{x:39.958102,y:-104.777543},
{x:39.972759,y:-104.828131},
{x:39.92967,y:-104.904038},
{x:40.524088,y:-104.71066},
{x:40.384372,y:-104.716427},
{x:40.398691,y:-104.690574},
{x:40.396351,y:-104.755601},
{x:40.422012,y:-104.790572},
{x:40.422443,y:-104.72766},
{x:39.886305,y:-104.88245},
{x:40.261686,y:-103.801994},
{x:40.265749,y:-103.621139},
{x:40.625802,y:-103.214184},
{x:39.296697,y:-102.278363},
{x:38.718846,y:-104.698171},
{x:39.270684,y:-103.708831},
{x:38.937492,y:-104.610176},
{x:38.989934,y:-105.047404},
{x:38.897486,y:-104.828698},
{x:38.836886,y:-104.817363},
{x:38.851993,y:-104.871252},
{x:38.795204,y:-104.789251},
{x:38.828556,y:-104.841348},
{x:38.807119,y:-104.823034},
{x:38.874631,y:-104.815928},
{x:38.837455,y:-104.77041},
{x:38.850752,y:-104.757837},
{x:38.795518,y:-104.756939},
{x:38.741973,y:-104.736266},
{x:38.830986,y:-104.704119},
{x:38.870855,y:-104.720812},
{x:38.840297,y:-104.748384},
{x:38.854422,y:-104.721723},
{x:38.824033,y:-104.727527},
{x:38.897428,y:-104.746409},
{x:38.90188,y:-104.768212},
{x:38.949742,y:-104.802986},
{x:38.942424,y:-104.725104},
{x:38.937941,y:-104.761201},
{x:39.056228,y:-104.843577},
{x:38.910143,y:-104.716648},
{x:38.288068,y:-104.597315},
{x:38.272161,y:-104.618195},
{x:38.233541,y:-104.662947},
{x:38.245091,y:-104.579911},
{x:38.324895,y:-104.69946},
{x:38.244007,y:-104.618193},
{x:38.308589,y:-104.617409},
{x:37.991418,y:-103.570852},
{x:38.099549,y:-102.619441},
{x:37.170551,y:-104.50971},
{x:37.475429,y:-105.894045},
{x:37.256661,y:-107.074283},
{x:38.524546,y:-105.992825},
{x:38.448444,y:-105.213705},
{x:38.544872,y:-106.917466},
{x:37.270055,y:-107.883028},
{x:37.349253,y:-108.567972},
{x:38.454739,y:-107.866885},
{x:38.484549,y:-107.869967},
{x:38.74849,y:-108.071402},
{x:39.075043,y:-108.51878},
{x:39.077998,y:-108.551495},
{x:39.089052,y:-108.600924},
{x:39.076241,y:-108.581762},
{x:39.089331,y:-108.455729},
{x:39.152298,y:-108.738785},
{x:39.519525,y:-107.320135},
{x:40.513739,y:-107.560678},
{x:39.568585,y:-107.51728},
{x:39.521341,y:-107.777239},
{x:39.628094,y:-106.500244},
{x:39.629813,y:-106.419162},
{x:41.138131,y:-104.786108},
{x:41.10021,y:-104.853713},
{x:41.160577,y:-104.767768},
{x:41.164013,y:-104.790958},
{x:41.171527,y:-104.8293},
{x:41.308706,y:-105.617967},
{x:41.308638,y:-105.556927},
{x:42.05886,y:-104.174591},
{x:41.793508,y:-107.212236},
{x:44.016507,y:-107.945311},
{x:44.516224,y:-109.054124},
{x:44.753349,y:-108.75276},
{x:43.638083,y:-108.212844},
{x:43.032001,y:-108.379929},
{x:42.831908,y:-108.72392},
{x:42.858238,y:-106.33168},
{x:42.81843,y:-106.368643},
{x:42.852295,y:-106.27166},
{x:42.76347,y:-105.401046},
{x:44.281376,y:-105.493291},
{x:44.291424,y:-105.530911},
{x:44.82696,y:-106.959461},
{x:44.773995,y:-106.94304},
{x:44.354579,y:-106.692067},
{x:41.610071,y:-109.230753},
{x:41.576904,y:-109.255796},
{x:41.263188,y:-110.957046},
{x:41.52122,y:-109.464569},
{x:43.47323,y:-110.786832},
{x:42.889931,y:-112.451916},
{x:42.859303,y:-112.435513},
{x:42.910992,y:-112.465672},
{x:43.195973,y:-112.360095},
{x:42.563894,y:-114.480603},
{x:42.547982,y:-114.420938},
{x:42.592355,y:-114.461173},
{x:42.567099,y:-114.46078},
{x:42.594109,y:-114.749767},
{x:42.559891,y:-113.7938},
{x:42.92274,y:-114.711762},
{x:43.526588,y:-114.319897},
{x:42.694754,y:-114.519277},
{x:43.510632,y:-112.017997},
{x:43.482214,y:-112.020592},
{x:43.496163,y:-112.057874},
{x:43.436657,y:-112.119809},
{x:43.480217,y:-111.984058},
{x:43.487155,y:-111.980721},
{x:43.8041,y:-111.808068},
{x:43.833695,y:-111.778588},
{x:46.390393,y:-116.989271},
{x:46.41317,y:-117.00143},
{x:43.631129,y:-116.645358},
{x:43.648652,y:-116.665949},
{x:43.693359,y:-116.353603},
{x:43.863313,y:-116.500629},
{x:43.500153,y:-116.394511},
{x:43.619827,y:-116.372984},
{x:43.60013,y:-116.355707},
{x:43.618959,y:-116.435863},
{x:43.597103,y:-116.392626},
{x:43.66338,y:-116.411111},
{x:43.633524,y:-116.353573},
{x:43.136983,y:-115.664387},
{x:43.126132,y:-115.700656},
{x:43.586641,y:-116.574762},
{x:43.55806,y:-116.572272},
{x:44.246083,y:-116.963431},
{x:43.595682,y:-116.517085},
{x:43.614078,y:-116.593972},
{x:43.621202,y:-116.221531},
{x:43.620263,y:-116.304779},
{x:43.619055,y:-116.279698},
{x:43.609385,y:-116.242896},
{x:43.593863,y:-116.214174},
{x:43.59621,y:-116.194206},
{x:43.590711,y:-116.272046},
{x:43.604682,y:-116.288671},
{x:43.592809,y:-116.284973},
{x:43.648824,y:-116.280325},
{x:43.542299,y:-116.154909},
{x:47.700518,y:-116.788235},
{x:47.693738,y:-116.8042},
{x:47.729581,y:-116.791867},
{x:47.547418,y:-116.133468},
{x:46.722348,y:-116.989017},
{x:46.732956,y:-117.021355},
{x:48.300865,y:-116.545522},
{x:47.711133,y:-116.922227},
{x:47.711409,y:-116.999341},
{x:47.816936,y:-116.882318},
{x:40.373917,y:-111.787621},
{x:40.88469,y:-111.892663},
{x:40.862033,y:-111.897494},
{x:40.923413,y:-111.890047},
{x:40.919125,y:-111.883834},
{x:41.140922,y:-112.06369},
{x:41.13728,y:-112.060834},
{x:41.123722,y:-112.026816},
{x:40.52721,y:-111.881274},
{x:40.503768,y:-111.897481},
{x:40.988539,y:-111.911405},
{x:40.499824,y:-111.413964},
{x:41.038698,y:-111.944531},
{x:41.061755,y:-111.94601},
{x:41.068872,y:-111.979588},
{x:41.076788,y:-111.974201},
{x:41.091079,y:-112.00251},
{x:40.387954,y:-111.914207},
{x:40.431969,y:-111.8862},
{x:40.387786,y:-111.836162},
{x:40.695506,y:-112.092536},
{x:40.620234,y:-111.902142},
{x:40.62269,y:-111.866497},
{x:40.312467,y:-111.722598},
{x:40.327091,y:-111.708986},
{x:40.297427,y:-111.695629},
{x:40.27426,y:-111.712726},
{x:40.724913,y:-111.545325},
{x:40.354858,y:-111.736152},
{x:40.416811,y:-111.773052},
{x:40.505424,y:-111.977888},
{x:40.522767,y:-111.944808},
{x:40.302619,y:-109.982122},
{x:41.189331,y:-112.049117},
{x:41.165025,y:-112.026452},
{x:40.581581,y:-111.8727},
{x:40.559834,y:-111.85379},
{x:40.688484,y:-112.266717},
{x:40.549472,y:-112.297694},
{x:41.088658,y:-112.042706},
{x:40.453951,y:-109.546505},
{x:40.609795,y:-112.026825},
{x:40.611371,y:-111.97794},
{x:40.609897,y:-111.940653},
{x:40.587396,y:-111.985709},
{x:40.58009,y:-111.837051},
{x:40.547733,y:-111.891834},
{x:40.585307,y:-111.859537},
{x:40.587231,y:-111.927909},
{x:40.544551,y:-111.981982},
{x:40.56267,y:-111.937931},
{x:40.508328,y:-112.010762},
{x:40.763817,y:-111.871491},
{x:40.733919,y:-111.93822},
{x:40.725755,y:-111.864096},
{x:40.759083,y:-111.897341},
{x:40.675179,y:-111.900213},
{x:40.643811,y:-111.888364},
{x:40.725596,y:-111.824048},
{x:40.700272,y:-111.800222},
{x:40.768332,y:-111.888832},
{x:40.700191,y:-111.890734},
{x:40.725884,y:-111.900238},
{x:40.687391,y:-111.860362},
{x:40.740698,y:-111.900718},
{x:40.736413,y:-111.887733},
{x:40.78426,y:-111.938369},
{x:40.786683,y:-111.981907},
{x:40.771879,y:-111.918543},
{x:40.666265,y:-111.837446},
{x:40.653412,y:-111.984314},
{x:40.635715,y:-112.026528},
{x:40.638024,y:-112.000493},
{x:40.654148,y:-112.025287},
{x:40.696966,y:-111.973979},
{x:40.696966,y:-112.024162},
{x:40.623494,y:-111.83478},
{x:40.679339,y:-111.938386},
{x:40.650513,y:-111.936874},
{x:40.651004,y:-111.938318},
{x:40.701762,y:-112.027278},
{x:41.492218,y:-112.016584},
{x:41.633236,y:-111.832071},
{x:41.746779,y:-111.83379},
{x:41.713244,y:-111.839274},
{x:41.840431,y:-111.831838},
{x:41.711295,y:-112.200992},
{x:41.229967,y:-112.006716},
{x:41.158727,y:-111.940102},
{x:41.192477,y:-111.971604},
{x:41.307158,y:-112.023242},
{x:41.269549,y:-111.973095},
{x:41.245583,y:-111.970455},
{x:41.177321,y:-112.001355},
{x:41.176037,y:-111.99852},
{x:41.306269,y:-111.969405},
{x:39.599961,y:-110.803101},
{x:38.563317,y:-109.549792},
{x:40.217404,y:-111.658431},
{x:40.250212,y:-111.662478},
{x:39.352129,y:-112.568431},
{x:39.350038,y:-111.587582},
{x:40.052585,y:-111.733137},
{x:40.120623,y:-111.639085},
{x:40.186266,y:-111.609999},
{x:40.162064,y:-111.651962},
{x:38.753709,y:-112.086648},
{x:38.292877,y:-112.648049},
{x:37.654428,y:-113.088353},
{x:37.681128,y:-113.076528},
{x:37.176806,y:-113.310645},
{x:37.043263,y:-112.525092},
{x:37.122378,y:-113.624195},
{x:37.109546,y:-113.566623},
{x:37.08737,y:-113.5844},
{x:37.127608,y:-113.521583},
{x:33.451167,y:-112.082117},
{x:33.46509,y:-112.065566},
{x:33.450994,y:-112.135001},
{x:33.494463,y:-112.083759},
{x:33.509799,y:-112.084833},
{x:33.4952,y:-112.06176},
{x:33.522001,y:-112.09298},
{x:33.5237,y:-112.108586},
{x:33.480186,y:-112.110857},
{x:33.50881,y:-112.046206},
{x:33.486675,y:-112.029725},
{x:33.526755,y:-112.132621},
{x:33.509245,y:-112.116706},
{x:33.494375,y:-112.128367},
{x:33.494954,y:-112.013276},
{x:33.478704,y:-112.000941},
{x:33.480701,y:-111.985696},
{x:33.523618,y:-112.134656},
{x:33.607089,y:-112.064334},
{x:33.565782,y:-112.06471},
{x:33.552728,y:-112.100553},
{x:33.640884,y:-112.066078},
{x:33.637212,y:-112.098098},
{x:33.639804,y:-112.099237},
{x:33.670068,y:-112.031676},
{x:33.70858,y:-112.113022},
{x:33.655056,y:-112.082489},
{x:33.683401,y:-112.108207},
{x:33.655112,y:-112.133486},
{x:33.579988,y:-112.013564},
{x:33.581546,y:-112.133587},
{x:33.495366,y:-112.167143},
{x:33.48093,y:-112.184268},
{x:33.62688,y:-112.012759},
{x:33.640193,y:-112.028928},
{x:33.599341,y:-111.984418},
{x:33.4947,y:-112.23736},
{x:33.437111,y:-111.994354},
{x:33.450139,y:-112.029766},
{x:33.429729,y:-112.065667},
{x:33.470551,y:-112.223378},
{x:33.476819,y:-112.220137},
{x:33.538022,y:-112.04715},
{x:33.480008,y:-112.256488},
{x:33.378502,y:-112.02912},
{x:33.407552,y:-112.01386},
{x:33.391919,y:-112.072738},
{x:33.378222,y:-112.098666},
{x:33.420392,y:-112.223191},
{x:33.451963,y:-112.203037},
{x:33.465237,y:-112.169735},
{x:33.331169,y:-111.981491},
{x:33.30464,y:-111.995094},
{x:33.30578,y:-112.056134},
{x:33.610293,y:-112.120252},
{x:33.798988,y:-112.127866},
{x:33.865495,y:-112.137428},
{x:33.358539,y:-111.467266},
{x:33.414737,y:-111.571574},
{x:32.779647,y:-111.62028},
{x:32.939557,y:-111.756041},
{x:32.877244,y:-111.712137},
{x:32.879252,y:-111.733709},
{x:32.986264,y:-111.524301},
{x:33.06334,y:-111.485577},
{x:33.038809,y:-111.378565},
{x:33.037128,y:-112.014887},
{x:33.246988,y:-111.562536},
{x:33.161762,y:-111.564326},
{x:33.422837,y:-111.823369},
{x:33.432722,y:-111.87108},
{x:33.43193,y:-111.865524},
{x:33.452086,y:-111.83927},
{x:33.415381,y:-111.857241},
{x:33.393416,y:-111.877947},
{x:33.393585,y:-111.863275},
{x:33.415702,y:-111.788635},
{x:33.380403,y:-111.802742},
{x:33.423154,y:-111.75311},
{x:33.436389,y:-111.719613},
{x:33.393473,y:-111.735914},
{x:33.390769,y:-111.685354},
{x:33.391517,y:-111.699135},
{x:33.414699,y:-111.633479},
{x:33.384928,y:-111.60051},
{x:33.379338,y:-111.835304},
{x:33.392351,y:-111.840709},
{x:33.366258,y:-111.858603},
{x:33.378209,y:-111.63551},
{x:33.32028,y:-111.686402},
{x:33.452314,y:-111.684871},
{x:33.449119,y:-111.733688},
{x:33.306504,y:-111.887846},
{x:33.335686,y:-111.875158},
{x:33.320237,y:-111.84243},
{x:33.292272,y:-111.821808},
{x:33.305794,y:-111.945084},
{x:33.281288,y:-111.961173},
{x:33.350859,y:-111.823646},
{x:33.334768,y:-111.80737},
{x:33.365103,y:-111.755503},
{x:33.378185,y:-111.722788},
{x:33.378528,y:-111.789302},
{x:33.267135,y:-111.687499},
{x:33.067106,y:-112.047617},
{x:33.039095,y:-112.014169},
{x:33.253759,y:-111.63601},
{x:33.259717,y:-111.857756},
{x:33.234614,y:-111.859451},
{x:33.218061,y:-111.84153},
{x:33.281521,y:-111.793388},
{x:33.523554,y:-111.900488},
{x:33.625365,y:-111.943711},
{x:33.584891,y:-111.925632},
{x:33.641069,y:-111.976219},
{x:33.651993,y:-111.886672},
{x:33.539235,y:-111.885344},
{x:33.465051,y:-111.909328},
{x:33.479918,y:-111.926588},
{x:33.494536,y:-111.908455},
{x:33.581928,y:-111.881705},
{x:33.5892,y:-111.835634},
{x:33.629201,y:-111.892082},
{x:33.574154,y:-111.714438},
{x:33.415847,y:-111.925918},
{x:33.421425,y:-111.910217},
{x:33.363297,y:-111.945152},
{x:33.349598,y:-111.912391},
{x:33.407133,y:-111.958688},
{x:33.393262,y:-111.977738},
{x:33.394541,y:-111.909527},
{x:33.377789,y:-111.931536},
{x:33.377772,y:-111.961841},
{x:33.348755,y:-111.963814},
{x:33.320435,y:-111.962697},
{x:33.289212,y:-111.837966},
{x:33.276776,y:-111.790938},
{x:33.336324,y:-111.756496},
{x:33.297262,y:-111.754742},
{x:33.235251,y:-111.72121},
{x:33.551114,y:-112.179884},
{x:33.524404,y:-112.186759},
{x:33.538086,y:-112.153843},
{x:33.568038,y:-112.185399},
{x:33.510194,y:-112.267345},
{x:33.509595,y:-112.205097},
{x:33.53698,y:-112.238924},
{x:33.636408,y:-112.184845},
{x:33.637815,y:-112.18675},
{x:33.596646,y:-112.168134},
{x:33.61119,y:-112.151727},
{x:33.537449,y:-112.357702},
{x:33.670214,y:-112.202431},
{x:33.650547,y:-112.234086},
{x:33.698853,y:-112.143118},
{x:33.71165,y:-112.200547},
{x:33.492983,y:-112.289675},
{x:33.465321,y:-112.274658},
{x:33.459224,y:-112.337413},
{x:33.441599,y:-112.555881},
{x:33.436921,y:-112.560331},
{x:33.753656,y:-111.990874},
{x:33.610549,y:-112.336423},
{x:32.944232,y:-112.732565},
{x:33.453806,y:-112.393216},
{x:33.3565,y:-112.429014},
{x:33.465173,y:-112.357502},
{x:33.456021,y:-112.341737},
{x:33.378559,y:-112.168835},
{x:33.459406,y:-112.395282},
{x:33.494429,y:-112.340151},
{x:34.154503,y:-114.277999},
{x:33.565122,y:-112.291512},
{x:33.565815,y:-112.254487},
{x:33.578521,y:-112.230351},
{x:33.583707,y:-112.203333},
{x:33.661629,y:-114.237441},
{x:32.488147,y:-114.782616},
{x:33.419935,y:-112.272},
{x:33.972116,y:-112.725169},
{x:32.68161,y:-114.647285},
{x:32.697885,y:-114.609279},
{x:32.680607,y:-114.625204},
{x:32.697804,y:-114.650896},
{x:32.668082,y:-114.493191},
{x:32.674512,y:-114.601543},
{x:32.671299,y:-114.440958},
{x:33.638601,y:-112.276869},
{x:33.657087,y:-112.375006},
{x:33.642697,y:-112.358135},
{x:33.610578,y:-112.358749},
{x:33.62367,y:-112.394432},
{x:33.61044,y:-112.240856},
{x:33.652122,y:-112.255576},
{x:33.637642,y:-112.220868},
{x:33.680971,y:-112.238454},
{x:33.708123,y:-112.273036},
{x:33.612459,y:-112.420668},
{x:33.63832,y:-112.414705},
{x:33.39323,y:-110.767656},
{x:34.240227,y:-111.322383},
{x:32.835591,y:-109.730418},
{x:31.963016,y:-110.346631},
{x:31.33956,y:-109.559911},
{x:31.852122,y:-110.995185},
{x:31.366912,y:-110.939724},
{x:31.360659,y:-110.932501},
{x:31.335263,y:-110.942771},
{x:31.957502,y:-110.981662},
{x:31.560615,y:-110.2604},
{x:31.55427,y:-110.278681},
{x:32.264716,y:-109.841108},
{x:31.504677,y:-110.256864},
{x:32.4593,y:-111.216725},
{x:32.3057,y:-111.012827},
{x:32.352264,y:-110.973939},
{x:32.287888,y:-110.978756},
{x:32.134498,y:-110.92577},
{x:32.134452,y:-110.976964},
{x:32.157222,y:-110.917003},
{x:32.205868,y:-110.84138},
{x:32.220516,y:-110.804305},
{x:32.222043,y:-110.861986},
{x:32.219318,y:-110.865365},
{x:32.193522,y:-110.874625},
{x:32.236738,y:-110.87666},
{x:32.267046,y:-110.893135},
{x:32.187547,y:-110.955853},
{x:32.177424,y:-111.002689},
{x:32.199401,y:-110.967953},
{x:32.206701,y:-110.921247},
{x:32.162648,y:-110.989124},
{x:32.248199,y:-110.850451},
{x:32.235722,y:-110.910214},
{x:32.309478,y:-110.892167},
{x:32.265743,y:-110.943329},
{x:32.250943,y:-110.959952},
{x:32.236405,y:-110.946083},
{x:32.191195,y:-110.790318},
{x:32.401414,y:-110.954808},
{x:32.494239,y:-110.926274},
{x:32.33806,y:-111.048392},
{x:32.381407,y:-111.047704},
{x:32.424595,y:-111.043049},
{x:32.358515,y:-111.091307},
{x:32.356292,y:-111.089338},
{x:32.251275,y:-111.015198},
{x:32.134225,y:-111.030982},
{x:32.136258,y:-111.001262},
{x:32.118593,y:-110.798084},
{x:32.220345,y:-110.773416},
{x:32.25848,y:-110.798467},
{x:32.171993,y:-111.071094},
{x:34.200578,y:-110.015679},
{x:34.251651,y:-110.028136},
{x:34.142163,y:-109.954518},
{x:34.504062,y:-110.079573},
{x:34.133239,y:-109.285487},
{x:35.226609,y:-111.579683},
{x:35.186842,y:-111.662048},
{x:35.19254,y:-111.627079},
{x:35.238485,y:-111.822016},
{x:35.972081,y:-112.126397},
{x:36.707947,y:-110.250396},
{x:36.905653,y:-111.484625},
{x:36.129523,y:-111.240271},
{x:35.257088,y:-112.192487},
{x:35.037032,y:-110.697582},
{x:34.918743,y:-110.15184},
{x:34.554745,y:-112.408537},
{x:34.550335,y:-112.47837},
{x:34.548785,y:-112.439481},
{x:34.598155,y:-112.457411},
{x:34.585356,y:-112.333591},
{x:34.575705,y:-111.883734},
{x:34.774305,y:-112.453364},
{x:34.712914,y:-112.000926},
{x:34.719472,y:-112.001072},
{x:34.326371,y:-112.119695},
{x:34.863507,y:-111.801727},
{x:35.222559,y:-114.033173},
{x:35.218357,y:-114.007267},
{x:34.470848,y:-114.343369},
{x:34.573364,y:-114.36573},
{x:35.223598,y:-114.036693},
{x:35.169442,y:-114.565421},
{x:35.018269,y:-114.597462},
{x:35.082077,y:-114.594121},
{x:35.106374,y:-114.597456},
{x:35.662021,y:-109.06175},
{x:34.665565,y:-106.77566},
{x:35.321857,y:-106.573676},
{x:35.318164,y:-106.541255},
{x:36.018814,y:-106.961171},
{x:35.062045,y:-106.190933},
{x:35.157599,y:-107.870599},
{x:34.817286,y:-106.763176},
{x:34.805944,y:-106.728354},
{x:35.076563,y:-107.557149},
{x:34.861695,y:-106.691422},
{x:35.089728,y:-106.642686},
{x:35.107865,y:-106.658266},
{x:35.070694,y:-106.65464},
{x:35.095732,y:-106.674221},
{x:35.025811,y:-106.711023},
{x:35.080678,y:-106.621846},
{x:35.202335,y:-106.647158},
{x:35.148469,y:-106.585827},
{x:35.134309,y:-106.612941},
{x:35.112001,y:-106.608323},
{x:35.113305,y:-106.604673},
{x:35.136962,y:-106.641039},
{x:35.076242,y:-106.587676},
{x:35.074514,y:-106.551176},
{x:35.086944,y:-106.577731},
{x:35.058789,y:-106.586204},
{x:35.159229,y:-106.55949},
{x:35.155353,y:-106.59299},
{x:35.131099,y:-106.552424},
{x:35.108677,y:-106.577919},
{x:35.131171,y:-106.497428},
{x:35.106837,y:-106.548604},
{x:35.116521,y:-106.534387},
{x:35.086385,y:-106.51607},
{x:35.175413,y:-106.577111},
{x:35.180188,y:-106.688142},
{x:35.211861,y:-106.696183},
{x:35.057682,y:-106.558886},
{x:35.154619,y:-106.681588},
{x:35.115652,y:-106.702277},
{x:35.100914,y:-106.707259},
{x:35.075931,y:-106.74284},
{x:35.050568,y:-106.708262},
{x:35.023565,y:-106.713767},
{x:35.079616,y:-106.529388},
{x:35.068913,y:-106.498919},
{x:35.238987,y:-106.655753},
{x:35.241976,y:-106.691466},
{x:35.243025,y:-106.699609},
{x:35.026149,y:-106.681171},
{x:35.532673,y:-108.758675},
{x:35.528947,y:-108.708564},
{x:35.528576,y:-108.769194},
{x:35.517204,y:-108.775612},
{x:36.731982,y:-108.231458},
{x:36.766624,y:-108.150263},
{x:36.738465,y:-108.177762},
{x:36.825359,y:-107.995546},
{x:36.77638,y:-108.696102},
{x:35.650204,y:-106.001049},
{x:35.660022,y:-105.958806},
{x:35.676692,y:-105.953079},
{x:35.884261,y:-106.015095},
{x:35.636409,y:-106.036703},
{x:35.618299,y:-106.029312},
{x:36.012738,y:-106.064005},
{x:35.879462,y:-106.295943},
{x:36.404656,y:-105.573863},
{x:35.605677,y:-105.223515},
{x:36.885512,y:-104.433594},
{x:34.072169,y:-106.892884},
{x:33.15533,y:-107.248535},
{x:32.293806,y:-106.76818},
{x:32.292151,y:-106.788991},
{x:32.288421,y:-106.785564},
{x:32.286827,y:-106.744735},
{x:32.312845,y:-106.750577},
{x:32.332585,y:-106.782682},
{x:32.360487,y:-106.745738},
{x:32.385267,y:-106.716841},
{x:32.267903,y:-107.767081},
{x:32.34359,y:-108.712901},
{x:32.784403,y:-108.264306},
{x:34.436363,y:-103.195792},
{x:34.411652,y:-103.20711},
{x:34.396084,y:-103.167954},
{x:34.181823,y:-103.34483},
{x:33.449835,y:-104.526802},
{x:33.446761,y:-104.523701},
{x:33.400426,y:-104.52329},
{x:33.370233,y:-104.523719},
{x:32.84041,y:-104.397921},
{x:32.438569,y:-104.255339},
{x:32.417047,y:-104.229415},
{x:32.696816,y:-103.128083},
{x:32.741341,y:-103.161121},
{x:32.717702,y:-103.140967},
{x:32.944525,y:-103.349051},
{x:32.887191,y:-105.957534},
{x:32.888362,y:-105.960704},
{x:32.839906,y:-106.080804},
{x:33.323813,y:-105.629241},
{x:35.15433,y:-103.724535},
{x:34.944317,y:-104.669558},
{x:35.971646,y:-114.849128},
{x:36.025037,y:-115.063052},
{x:36.064001,y:-115.046683},
{x:36.060284,y:-115.033554},
{x:36.053454,y:-115.08302},
{x:36.013086,y:-114.962863},
{x:36.042519,y:-114.98426},
{x:35.610122,y:-115.387905},
{x:35.614831,y:-115.384733},
{x:35.611674,y:-115.392133},
{x:36.803443,y:-114.099108},
{x:36.815205,y:-114.064869},
{x:35.163685,y:-114.571939},
{x:36.213447,y:-115.099487},
{x:36.188043,y:-115.115205},
{x:36.195247,y:-115.128444},
{x:36.217298,y:-115.128382},
{x:36.262304,y:-115.179414},
{x:36.239955,y:-115.160798},
{x:36.236827,y:-115.168776},
{x:36.538711,y:-114.442661},
{x:35.460918,y:-114.918338},
{x:36.209146,y:-115.986045},
{x:36.006667,y:-115.112948},
{x:36.264736,y:-115.116646},
{x:36.281425,y:-115.134532},
{x:36.169503,y:-115.142879},
{x:36.172162,y:-115.146667},
{x:36.14478,y:-115.177237},
{x:36.143978,y:-115.200181},
{x:36.156329,y:-115.202191},
{x:36.158525,y:-115.162624},
{x:36.145722,y:-115.242584},
{x:36.12523,y:-115.222387},
{x:36.122957,y:-115.246403},
{x:36.114913,y:-115.209057},
{x:36.114432,y:-115.195923},
{x:36.100384,y:-115.224055},
{x:36.147618,y:-115.156379},
{x:36.143994,y:-115.155041},
{x:36.158526,y:-115.119297},
{x:36.159477,y:-115.079754},
{x:36.065356,y:-115.013006},
{x:36.195286,y:-115.161168},
{x:36.180822,y:-115.179801},
{x:36.174937,y:-115.223553},
{x:36.16676,y:-115.205498},
{x:36.1725,y:-115.196406},
{x:36.196358,y:-115.240731},
{x:36.135565,y:-115.1633},
{x:36.119999,y:-115.138063},
{x:36.143564,y:-115.137393},
{x:36.108489,y:-115.172174},
{x:36.120085,y:-115.171921},
{x:36.137151,y:-115.164525},
{x:36.122028,y:-115.154446},
{x:36.173775,y:-115.097762},
{x:36.174877,y:-115.061658},
{x:36.071053,y:-115.278794},
{x:36.065036,y:-115.248941},
{x:36.236803,y:-115.061825},
{x:36.158616,y:-115.263073},
{x:36.115045,y:-115.261857},
{x:36.100511,y:-115.186314},
{x:36.061777,y:-115.241972},
{x:36.101743,y:-115.137484},
{x:36.114261,y:-115.119567},
{x:36.090052,y:-115.172226},
{x:36.080516,y:-115.122299},
{x:36.071926,y:-115.115373},
{x:36.108554,y:-115.06358},
{x:36.130033,y:-115.109418},
{x:36.113822,y:-115.066655},
{x:36.099634,y:-115.105034},
{x:36.137388,y:-115.064796},
{x:36.112779,y:-115.062298},
{x:36.056504,y:-115.171403},
{x:36.023,y:-115.124112},
{x:36.041879,y:-115.117908},
{x:36.012769,y:-115.135282},
{x:36.021602,y:-115.243848},
{x:36.181715,y:-115.256453},
{x:36.196608,y:-115.261787},
{x:36.218979,y:-115.281423},
{x:36.241028,y:-115.250261},
{x:36.262598,y:-115.249932},
{x:36.247126,y:-115.208019},
{x:36.216749,y:-115.212438},
{x:36.279917,y:-115.207138},
{x:36.301934,y:-115.281428},
{x:36.191859,y:-115.305963},
{x:36.159433,y:-115.320344},
{x:36.039296,y:-115.191449},
{x:35.99722,y:-115.206946},
{x:36.159053,y:-115.047034},
{x:36.161421,y:-115.291036},
{x:36.125491,y:-115.279707},
{x:36.159499,y:-115.233748},
{x:36.114724,y:-115.308197},
{x:36.099361,y:-115.2979},
{x:36.094154,y:-115.293885},
{x:36.277015,y:-115.279315},
{x:36.272042,y:-115.270582},
{x:36.196246,y:-115.027075},
{x:36.020868,y:-115.27792},
{x:36.012571,y:-115.171984},
{x:39.243296,y:-114.869511},
{x:39.475716,y:-118.806534},
{x:39.476044,y:-118.80066},
{x:39.608667,y:-119.221749},
{x:38.532903,y:-118.624122},
{x:40.178851,y:-118.472843},
{x:38.957947,y:-119.77458},
{x:39.556113,y:-119.754704},
{x:39.542284,y:-119.735835},
{x:39.577695,y:-119.78105},
{x:39.527713,y:-119.701135},
{x:39.584297,y:-119.746293},
{x:39.6396,y:-119.711747},
{x:40.959164,y:-117.74832},
{x:38.99408,y:-119.163587},
{x:39.530632,y:-119.814276},
{x:39.506074,y:-119.800247},
{x:39.505771,y:-119.774878},
{x:39.514503,y:-119.788653},
{x:39.481392,y:-119.790798},
{x:39.484662,y:-119.747632},
{x:39.527858,y:-119.827029},
{x:39.625129,y:-119.882569},
{x:39.612404,y:-119.849455},
{x:39.393886,y:-119.765405},
{x:39.528937,y:-119.781666},
{x:39.545793,y:-119.781123},
{x:39.439637,y:-119.756939},
{x:39.423561,y:-119.74194},
{x:39.535306,y:-119.867295},
{x:39.531688,y:-119.865252},
{x:39.130913,y:-119.769619},
{x:39.187479,y:-119.757781},
{x:39.179562,y:-119.766545},
{x:40.838591,y:-115.790445},
{x:40.847283,y:-115.748896},
{x:40.637717,y:-116.944623},
{x:40.739086,y:-114.06389},
{x:41.099118,y:-114.960064},
{x:33.95817,y:-118.231385},
{x:33.945194,y:-118.265526},
{x:33.96051,y:-118.274338},
{x:34.059959,y:-118.309494},
{x:34.043932,y:-118.290668},
{x:34.040379,y:-118.284389},
{x:34.041641,y:-118.30854},
{x:34.051064,y:-118.279221},
{x:34.03252,y:-118.266914},
{x:34.026051,y:-118.276817},
{x:34.005917,y:-118.333763},
{x:34.011072,y:-118.317968},
{x:33.989006,y:-118.255989},
{x:34.043062,y:-118.267227},
{x:34.030281,y:-118.334778},
{x:34.023961,y:-118.372717},
{x:34.021271,y:-118.35508},
{x:34.05596,y:-118.270819},
{x:34.052437,y:-118.344345},
{x:34.068111,y:-118.291931},
{x:34.035304,y:-118.238154},
{x:34.031433,y:-118.244026},
{x:34.033054,y:-118.153374},
{x:34.019382,y:-118.150833},
{x:34.018513,y:-118.17424},
{x:34.026688,y:-118.199295},
{x:34.027588,y:-118.219254},
{x:34.045952,y:-118.464745},
{x:34.056591,y:-118.425995},
{x:34.070595,y:-118.26815},
{x:34.080608,y:-118.260628},
{x:34.097851,y:-118.308899},
{x:34.096661,y:-118.327095},
{x:34.10138,y:-118.338264},
{x:34.083389,y:-118.291301},
{x:34.09531,y:-118.284004},
{x:34.088722,y:-118.30957},
{x:34.074116,y:-118.208717},
{x:34.087677,y:-118.175911},
{x:34.058479,y:-118.212257},
{x:34.04631,y:-118.208504},
{x:34.019348,y:-118.406952},
{x:34.042794,y:-118.376699},
{x:34.05172,y:-118.369026},
{x:34.010781,y:-118.281881},
{x:34.011219,y:-118.294044},
{x:34.091675,y:-118.344559},
{x:33.995846,y:-118.141243},
{x:34.137185,y:-118.188227},
{x:34.104948,y:-118.201527},
{x:34.119038,y:-118.192802},
{x:33.975204,y:-118.331146},
{x:33.988823,y:-118.291023},
{x:33.974981,y:-118.283102},
{x:33.931297,y:-118.282951},
{x:33.959622,y:-118.386113},
{x:33.975582,y:-118.373344},
{x:33.945786,y:-118.370857},
{x:34.097273,y:-118.366322},
{x:33.959759,y:-118.300648},
{x:34.063848,y:-118.366735},
{x:33.945728,y:-118.308594},
{x:33.987907,y:-118.365196},
{x:34.008423,y:-118.220238},
{x:34.010548,y:-118.256904},
{x:33.92564,y:-118.238625},
{x:33.902501,y:-118.254015},
{x:33.988877,y:-118.311896},
{x:34.033038,y:-118.183786},
{x:34.059719,y:-118.173431},
{x:34.04348,y:-118.422478},
{x:34.026005,y:-118.431541},
{x:34.083157,y:-118.222832},
{x:34.115196,y:-118.246658},
{x:33.979374,y:-118.202391},
{x:33.96809,y:-118.163788},
{x:33.961299,y:-118.186255},
{x:33.877613,y:-118.217945},
{x:33.896434,y:-118.225038},
{x:33.903895,y:-118.208983},
{x:33.88837,y:-118.207452},
{x:34.000343,y:-118.402885},
{x:33.947453,y:-118.117932},
{x:33.954441,y:-118.113213},
{x:33.931035,y:-118.113959},
{x:33.944892,y:-118.142643},
{x:33.904154,y:-118.143602},
{x:33.928738,y:-118.147865},
{x:33.915804,y:-118.396555},
{x:33.901595,y:-118.290919},
{x:33.891582,y:-118.301125},
{x:33.893238,y:-118.283485},
{x:33.856723,y:-118.300233},
{x:33.872597,y:-118.298531},
{x:33.916763,y:-118.308289},
{x:33.887127,y:-118.326218},
{x:33.901312,y:-118.372461},
{x:33.902268,y:-118.335011},
{x:33.916168,y:-118.347382},
{x:33.863236,y:-118.392885},
{x:33.973601,y:-118.216632},
{x:33.989025,y:-118.225685},
{x:33.975014,y:-118.231201},
{x:33.889439,y:-118.352066},
{x:33.930168,y:-118.210922},
{x:33.932606,y:-118.183135},
{x:34.0397,y:-118.67009},
{x:33.887108,y:-118.379048},
{x:33.873142,y:-118.393692},
{x:33.98679,y:-118.185936},
{x:33.844414,y:-118.38736},
{x:33.871353,y:-118.354999},
{x:33.945129,y:-118.211472},
{x:33.953527,y:-118.197145},
{x:33.948864,y:-118.165771},
{x:33.992996,y:-118.451073},
{x:33.981223,y:-118.439341},
{x:33.962199,y:-118.350102},
{x:33.945842,y:-118.344605},
{x:33.931308,y:-118.351978},
{x:33.930729,y:-118.325989},
{x:34.034073,y:-118.473},
{x:34.012092,y:-118.494355},
{x:34.024731,y:-118.458992},
{x:34.005661,y:-118.474022},
{x:33.831196,y:-118.303329},
{x:33.810891,y:-118.291199},
{x:33.857929,y:-118.354408},
{x:33.837914,y:-118.367485},
{x:33.865932,y:-118.326777},
{x:33.801244,y:-118.327572},
{x:33.805874,y:-118.354057},
{x:33.981205,y:-118.05067},
{x:33.957039,y:-118.038536},
{x:33.948303,y:-118.006203},
{x:33.93066,y:-117.994186},
{x:33.940305,y:-118.049405},
{x:33.971138,y:-118.065422},
{x:33.844556,y:-117.986614},
{x:33.857903,y:-118.010818},
{x:33.848087,y:-117.998833},
{x:33.87431,y:-117.997849},
{x:33.858364,y:-118.045227},
{x:33.815025,y:-118.02874},
{x:33.913475,y:-117.970972},
{x:33.939461,y:-117.937729},
{x:33.917441,y:-118.013846},
{x:34.038803,y:-118.079521},
{x:34.020676,y:-118.121056},
{x:33.887421,y:-118.063608},
{x:33.91748,y:-118.07225},
{x:33.917439,y:-118.10218},
{x:33.902481,y:-118.098366},
{x:34.002872,y:-118.086693},
{x:33.984982,y:-118.104921},
{x:33.984001,y:-118.097902},
{x:33.969341,y:-118.09845},
{x:33.941299,y:-118.048675},
{x:33.901695,y:-118.029564},
{x:33.801434,y:-117.993592},
{x:33.871426,y:-118.082954},
{x:33.875153,y:-118.151031},
{x:33.887348,y:-118.142357},
{x:33.83032,y:-118.158572},
{x:33.859795,y:-118.12548},
{x:33.832909,y:-118.159531},
{x:33.847781,y:-118.142104},
{x:33.846294,y:-118.115952},
{x:33.831886,y:-118.075287},
{x:33.789169,y:-118.30793},
{x:33.797634,y:-118.308525},
{x:33.80278,y:-118.070663},
{x:33.889444,y:-118.169251},
{x:33.902185,y:-118.145245},
{x:33.741211,y:-118.292656},
{x:33.751118,y:-118.308708},
{x:33.74548,y:-118.105118},
{x:33.791004,y:-118.260895},
{x:33.829094,y:-118.263359},
{x:33.872425,y:-118.249533},
{x:33.847108,y:-118.265222},
{x:33.807734,y:-118.273809},
{x:33.803891,y:-118.168335},
{x:33.782398,y:-118.153442},
{x:33.874104,y:-118.186545},
{x:33.847129,y:-118.193136},
{x:33.790121,y:-118.188915},
{x:33.804138,y:-118.189911},
{x:33.829313,y:-118.090659},
{x:33.810791,y:-118.107504},
{x:33.790272,y:-118.216011},
{x:33.775009,y:-118.189026},
{x:33.7715,y:-118.179681},
{x:33.798827,y:-118.124795},
{x:33.861256,y:-118.168167},
{x:34.182667,y:-118.16008},
{x:34.151459,y:-118.026726},
{x:34.135239,y:-118.049797},
{x:34.134071,y:-117.983921},
{x:34.139263,y:-117.980927},
{x:34.199051,y:-118.18763},
{x:34.139937,y:-118.009119},
{x:34.117439,y:-118.149803},
{x:34.259434,y:-118.306351},
{x:34.244872,y:-118.274215},
{x:34.159691,y:-118.132896},
{x:34.145614,y:-118.12308},
{x:34.168864,y:-118.097852},
{x:34.150162,y:-118.091362},
{x:34.170498,y:-118.289429},
{x:34.153187,y:-118.257566},
{x:34.142467,y:-118.236481},
{x:34.145576,y:-118.25946},
{x:34.147228,y:-118.760162},
{x:34.14431,y:-118.698608},
{x:34.200564,y:-118.61057},
{x:34.19346,y:-118.587881},
{x:34.219261,y:-118.607025},
{x:34.188068,y:-118.625305},
{x:34.18755,y:-118.643143},
{x:34.413827,y:-118.506147},
{x:34.238008,y:-118.589036},
{x:34.256931,y:-118.589569},
{x:34.162632,y:-118.517525},
{x:34.188038,y:-118.940605},
{x:34.392018,y:-118.470865},
{x:34.235893,y:-118.536476},
{x:34.228779,y:-118.554504},
{x:34.220686,y:-118.519071},
{x:34.250054,y:-118.502706},
{x:34.277672,y:-118.567082},
{x:34.258812,y:-118.535645},
{x:34.274486,y:-118.4121},
{x:34.25769,y:-118.43309},
{x:34.233094,y:-118.437713},
{x:34.186355,y:-118.536518},
{x:34.201477,y:-118.542305},
{x:34.298306,y:-118.437279},
{x:34.281951,y:-118.438864},
{x:34.29501,y:-118.417236},
{x:34.303516,y:-118.477879},
{x:34.228059,y:-118.467184},
{x:34.249748,y:-118.467038},
{x:34.228283,y:-118.500664},
{x:34.285278,y:-118.501663},
{x:34.270351,y:-118.467651},
{x:34.405066,y:-118.464636},
{x:34.415405,y:-118.464493},
{x:34.218559,y:-118.369904},
{x:34.446151,y:-118.552941},
{x:34.423065,y:-118.562952},
{x:34.422432,y:-118.541733},
{x:34.172795,y:-118.545126},
{x:34.200564,y:-118.867851},
{x:34.163555,y:-118.826759},
{x:34.210742,y:-118.843466},
{x:34.15935,y:-118.635593},
{x:34.183369,y:-118.604431},
{x:34.377968,y:-118.566956},
{x:34.383449,y:-118.569249},
{x:34.497082,y:-118.623611},
{x:34.215394,y:-118.449318},
{x:34.235802,y:-118.450699},
{x:34.224594,y:-118.44976},
{x:34.198868,y:-118.44915},
{x:34.200811,y:-118.431603},
{x:34.200745,y:-118.466614},
{x:34.194626,y:-118.500671},
{x:34.186427,y:-118.457038},
{x:34.149273,y:-118.43631},
{x:34.1549,y:-118.475037},
{x:34.188576,y:-118.319695},
{x:34.194629,y:-118.349237},
{x:34.183744,y:-118.32185},
{x:34.167419,y:-118.323448},
{x:34.172443,y:-118.372276},
{x:34.152517,y:-118.367862},
{x:34.187019,y:-118.414627},
{x:34.200989,y:-118.407985},
{x:34.216915,y:-118.388123},
{x:34.186219,y:-118.370855},
{x:34.185116,y:-118.387169},
{x:34.132659,y:-117.933217},
{x:34.119026,y:-117.907977},
{x:34.128277,y:-117.887505},
{x:34.108311,y:-117.934474},
{x:34.069955,y:-117.978882},
{x:34.071602,y:-117.959282},
{x:34.08427,y:-117.967842},
{x:33.962675,y:-117.695186},
{x:33.984807,y:-117.748017},
{x:34.001073,y:-117.72229},
{x:33.987691,y:-117.70598},
{x:34.023911,y:-117.688774},
{x:34.080327,y:-117.718556},
{x:34.096462,y:-117.907351},
{x:34.102519,y:-117.909104},
{x:34.086533,y:-117.872932},
{x:34.138052,y:-117.575377},
{x:34.121163,y:-117.615551},
{x:34.106182,y:-117.594818},
{x:34.10695,y:-117.559409},
{x:34.078935,y:-118.053328},
{x:34.093052,y:-118.026833},
{x:34.06683,y:-118.025665},
{x:34.063107,y:-118.063852},
{x:34.036358,y:-118.040207},
{x:34.106797,y:-117.536006},
{x:34.11641,y:-117.825912},
{x:34.107029,y:-117.864647},
{x:34.129073,y:-117.830145},
{x:34.051022,y:-117.942711},
{x:33.98077,y:-117.973327},
{x:34.011509,y:-117.965973},
{x:34.053674,y:-117.974115},
{x:34.043941,y:-117.988609},
{x:33.986889,y:-117.870361},
{x:33.994505,y:-117.926608},
{x:33.998383,y:-117.932712},
{x:33.990276,y:-117.912582},
{x:34.112797,y:-117.762009},
{x:33.974839,y:-117.555706},
{x:34.039829,y:-118.144348},
{x:34.182371,y:-117.438985},
{x:34.033448,y:-117.629837},
{x:34.028564,y:-117.650162},
{x:34.029043,y:-117.592722},
{x:34.07333,y:-117.669632},
{x:34.055537,y:-117.706591},
{x:34.087929,y:-117.689209},
{x:34.074251,y:-117.556861},
{x:34.078289,y:-117.631721},
{x:34.021614,y:-117.810959},
{x:34.062902,y:-117.743102},
{x:34.033176,y:-117.755386},
{x:34.088161,y:-117.749825},
{x:34.071949,y:-117.789429},
{x:34.080144,y:-118.082279},
{x:34.050335,y:-118.085443},
{x:34.063046,y:-118.105499},
{x:34.106937,y:-117.820419},
{x:34.123654,y:-118.072586},
{x:34.107838,y:-118.055527},
{x:34.106508,y:-117.680564},
{x:34.106468,y:-117.649212},
{x:33.999831,y:-117.845348},
{x:34.028526,y:-117.835897},
{x:34.070911,y:-117.933099},
{x:34.067008,y:-117.927712},
{x:34.031361,y:-117.91539},
{x:34.073376,y:-117.907181},
{x:34.071026,y:-117.879539},
{x:34.003757,y:-117.888954},
{x:34.099907,y:-118.117714},
{x:34.077328,y:-118.139427},
{x:32.834168,y:-116.75022},
{x:32.660292,y:-117.034444},
{x:32.651891,y:-117.094286},
{x:32.640441,y:-117.098568},
{x:32.626927,y:-117.087489},
{x:32.614779,y:-117.025474},
{x:32.613294,y:-117.06944},
{x:32.606718,y:-117.083015},
{x:32.603696,y:-117.079258},
{x:32.647168,y:-116.966709},
{x:32.583441,y:-117.109675},
{x:32.747503,y:-116.961277},
{x:32.773875,y:-117.026135},
{x:32.77756,y:-117.009409},
{x:32.652769,y:-117.08925},
{x:32.670381,y:-117.096049},
{x:32.706976,y:-117.008941},
{x:33.12264,y:-117.324179},
{x:32.628631,y:-117.038674},
{x:32.980291,y:-117.249506},
{x:32.744067,y:-116.932864},
{x:32.805438,y:-116.96723},
{x:32.808063,y:-116.973099},
{x:32.826621,y:-116.901921},
{x:32.811492,y:-116.918504},
{x:32.797354,y:-116.935189},
{x:33.045543,y:-117.261459},
{x:33.067246,y:-117.263967},
{x:33.128912,y:-117.091027},
{x:33.130532,y:-117.067708},
{x:33.116947,y:-117.097264},
{x:33.10416,y:-117.074868},
{x:33.371479,y:-117.252086},
{x:33.289737,y:-117.226501},
{x:33.07146,y:-117.070518},
{x:32.852883,y:-116.931483},
{x:32.742865,y:-117.043762},
{x:32.677288,y:-117.082885},
{x:33.182801,y:-117.293594},
{x:33.190414,y:-117.361108},
{x:33.18218,y:-117.340245},
{x:33.224276,y:-117.333385},
{x:33.203564,y:-117.363929},
{x:33.225186,y:-117.392262},
{x:33.346482,y:-117.411723},
{x:33.296691,y:-117.350908},
{x:33.172587,y:-117.253113},
{x:33.23201,y:-117.308252},
{x:33.24638,y:-117.291377},
{x:33.206299,y:-117.286866},
{x:32.958984,y:-117.037742},
{x:32.952676,y:-117.059672},
{x:33.036632,y:-116.877495},
{x:33.138991,y:-117.174056},
{x:33.135632,y:-117.123482},
{x:33.148258,y:-117.199724},
{x:33.133541,y:-117.121101},
{x:32.837892,y:-116.984106},
{x:32.544368,y:-117.02909},
{x:33.220722,y:-117.245195},
{x:33.19384,y:-117.235175},
{x:32.718727,y:-117.154057},
{x:32.748812,y:-117.15076},
{x:32.754825,y:-117.13924},
{x:32.748929,y:-117.100651},
{x:32.720593,y:-117.084575},
{x:32.72794,y:-117.226728},
{x:32.783538,y:-117.127988},
{x:32.806902,y:-117.218854},
{x:32.797611,y:-117.249496},
{x:32.753724,y:-117.218491},
{x:32.785727,y:-117.170161},
{x:32.832256,y:-117.165367},
{x:32.820675,y:-117.17802},
{x:32.693033,y:-117.134415},
{x:32.741764,y:-117.053517},
{x:32.770951,y:-117.071172},
{x:32.833157,y:-117.201938},
{x:32.801862,y:-117.011688},
{x:32.789433,y:-117.098132},
{x:32.892894,y:-117.203383},
{x:32.880434,y:-117.15957},
{x:32.866813,y:-117.215377},
{x:32.809383,y:-117.116075},
{x:32.831036,y:-117.13579},
{x:32.822516,y:-117.10107},
{x:32.915452,y:-117.130298},
{x:32.981939,y:-117.076232},
{x:33.013263,y:-117.0786},
{x:32.950016,y:-117.107671},
{x:32.954455,y:-117.231378},
{x:32.936691,y:-117.098124},
{x:32.724858,y:-117.146467},
{x:32.701829,y:-117.19142},
{x:32.679359,y:-117.119995},
{x:32.689339,y:-117.044747},
{x:32.8888,y:-117.131607},
{x:32.555469,y:-116.938487},
{x:32.584133,y:-117.039711},
{x:32.581433,y:-117.035518},
{x:32.557546,y:-117.058285},
{x:32.545021,y:-117.038692},
{x:33.708332,y:-116.241814},
{x:33.740234,y:-116.229195},
{x:33.740362,y:-116.216112},
{x:33.759981,y:-116.297899},
{x:33.92522,y:-116.899368},
{x:33.923604,y:-116.954688},
{x:33.928091,y:-116.977661},
{x:33.608684,y:-114.605914},
{x:32.978937,y:-115.542259},
{x:33.930892,y:-116.818879},
{x:32.672724,y:-115.498351},
{x:33.788019,y:-116.48258},
{x:33.819366,y:-116.458611},
{x:33.700041,y:-116.214179},
{x:33.683868,y:-116.180943},
{x:33.945549,y:-116.500847},
{x:32.776282,y:-115.552891},
{x:32.813492,y:-115.57737},
{x:32.795786,y:-115.569613},
{x:33.703708,y:-116.280546},
{x:33.709873,y:-116.288294},
{x:33.720989,y:-116.395447},
{x:33.844548,y:-116.526148},
{x:33.813137,y:-116.486939},
{x:33.80085,y:-116.520573},
{x:33.8014,y:-116.388588},
{x:33.817101,y:-116.402382},
{x:34.13662,y:-116.053978},
{x:34.129453,y:-116.399546},
{x:34.126534,y:-116.405807},
{x:34.471664,y:-117.24408},
{x:34.526431,y:-117.221825},
{x:34.891216,y:-116.9991},
{x:34.855179,y:-117.085938},
{x:34.033501,y:-117.319093},
{x:34.243885,y:-116.898605},
{x:34.247647,y:-117.207933},
{x:33.994579,y:-117.057259},
{x:34.067661,y:-117.32663},
{x:34.047878,y:-117.309402},
{x:34.243892,y:-117.277866},
{x:34.10555,y:-117.428183},
{x:34.107196,y:-117.48973},
{x:34.06963,y:-117.435135},
{x:34.106172,y:-117.453966},
{x:34.040996,y:-117.486057},
{x:34.427158,y:-117.34906},
{x:34.419708,y:-117.28627},
{x:34.121647,y:-117.198737},
{x:34.137752,y:-117.195024},
{x:34.251438,y:-117.188477},
{x:34.047739,y:-117.241594},
{x:34.839392,y:-114.610064},
{x:34.309134,y:-117.470473},
{x:34.42606,y:-117.57415},
{x:34.063255,y:-117.209465},
{x:34.06509,y:-117.222821},
{x:34.069729,y:-117.139182},
{x:34.054916,y:-117.175598},
{x:34.072114,y:-117.36988},
{x:34.121078,y:-117.370919},
{x:34.107075,y:-117.396997},
{x:34.138618,y:-117.433501},
{x:33.479451,y:-117.102852},
{x:34.507163,y:-117.397854},
{x:34.507012,y:-117.346229},
{x:34.469913,y:-117.366003},
{x:34.465173,y:-117.354417},
{x:34.520535,y:-117.317398},
{x:34.471143,y:-117.343262},
{x:34.034364,y:-117.071081},
{x:34.165207,y:-117.281181},
{x:34.13554,y:-117.252777},
{x:34.136398,y:-117.298981},
{x:34.166071,y:-117.332081},
{x:34.063374,y:-117.283531},
{x:34.091806,y:-117.314131},
{x:34.102371,y:-117.298164},
{x:34.121565,y:-117.314201},
{x:33.723536,y:-116.394656},
{x:33.935301,y:-117.454491},
{x:33.913937,y:-117.458794},
{x:33.908478,y:-117.436272},
{x:33.935442,y:-117.403175},
{x:33.946465,y:-117.421539},
{x:33.904705,y:-117.473159},
{x:33.93849,y:-117.286244},
{x:33.939026,y:-117.279533},
{x:33.990259,y:-117.339827},
{x:33.975335,y:-117.35923},
{x:33.915752,y:-117.327156},
{x:34.011326,y:-117.435677},
{x:33.97588,y:-117.476852},
{x:33.660469,y:-117.300346},
{x:33.690925,y:-117.341515},
{x:33.726372,y:-117.009918},
{x:33.747189,y:-116.952698},
{x:33.747046,y:-116.996214},
{x:33.917732,y:-117.236389},
{x:33.94141,y:-117.22673},
{x:33.938739,y:-117.247929},
{x:33.89198,y:-117.206107},
{x:33.573769,y:-117.202665},
{x:33.554518,y:-117.19674},
{x:33.571175,y:-117.18425},
{x:33.5525,y:-117.192653},
{x:33.550578,y:-117.140423},
{x:33.845151,y:-117.22476},
{x:33.802143,y:-117.225586},
{x:33.767925,y:-116.955612},
{x:33.795072,y:-116.971219},
{x:33.684648,y:-117.190644},
{x:33.714005,y:-117.190643},
{x:33.504002,y:-117.153625},
{x:33.508694,y:-117.126915},
{x:33.53096,y:-117.151925},
{x:33.480193,y:-117.094505},
{x:33.596716,y:-117.243126},
{x:33.694954,y:-117.799782},
{x:33.693336,y:-117.826736},
{x:33.680638,y:-117.665353},
{x:33.67083,y:-117.789314},
{x:33.68557,y:-117.856934},
{x:33.629515,y:-117.726378},
{x:33.734217,y:-117.761697},
{x:33.683784,y:-117.886199},
{x:33.66722,y:-117.882153},
{x:33.68504,y:-117.919823},
{x:33.657425,y:-117.918624},
{x:33.643192,y:-117.924782},
{x:33.465313,y:-117.689796},
{x:33.627804,y:-117.716232},
{x:33.65526,y:-117.702227},
{x:33.615974,y:-117.707878},
{x:33.666519,y:-117.987984},
{x:33.730476,y:-118.01432},
{x:33.717209,y:-117.988693},
{x:33.681474,y:-118.005756},
{x:33.715748,y:-118.039648},
{x:33.596693,y:-117.676123},
{x:33.566074,y:-117.708221},
{x:33.616325,y:-117.910553},
{x:33.439518,y:-117.620132},
{x:33.500713,y:-117.659386},
{x:33.523415,y:-117.686165},
{x:33.76762,y:-117.99355},
{x:33.758854,y:-118.005386},
{x:33.643234,y:-117.595652},
{x:33.62162,y:-117.660675},
{x:33.652828,y:-117.646973},
{x:33.562253,y:-117.629113},
{x:33.745777,y:-117.865181},
{x:33.726879,y:-117.888924},
{x:33.737123,y:-117.915347},
{x:33.700244,y:-117.905521},
{x:33.738503,y:-117.920479},
{x:33.69664,y:-117.885872},
{x:33.734083,y:-117.850914},
{x:33.757999,y:-117.884735},
{x:33.760323,y:-117.856537},
{x:33.715693,y:-117.868359},
{x:33.680482,y:-117.860708},
{x:33.678501,y:-117.862015},
{x:33.702247,y:-117.932222},
{x:33.687637,y:-117.953491},
{x:33.818335,y:-117.227746},
{x:33.746032,y:-117.821595},
{x:33.730649,y:-117.798286},
{x:33.856129,y:-117.919437},
{x:33.836157,y:-117.938031},
{x:33.831604,y:-118.002739},
{x:33.842751,y:-117.941254},
{x:33.81094,y:-117.914688},
{x:33.817852,y:-117.96846},
{x:33.802845,y:-117.942421},
{x:33.802883,y:-117.888611},
{x:33.818447,y:-117.909157},
{x:33.849266,y:-117.888794},
{x:33.859881,y:-117.792486},
{x:33.913516,y:-117.865295},
{x:33.911516,y:-117.866674},
{x:33.914036,y:-117.892624},
{x:33.874203,y:-117.923241},
{x:33.877457,y:-117.961958},
{x:33.858345,y:-117.959671},
{x:33.917708,y:-117.93008},
{x:33.774471,y:-117.93944},
{x:33.780876,y:-117.914421},
{x:33.788864,y:-117.961159},
{x:33.760235,y:-117.920792},
{x:33.759178,y:-117.957336},
{x:33.787468,y:-118.029282},
{x:33.897698,y:-117.561855},
{x:33.939205,y:-117.5546},
{x:33.828655,y:-117.835571},
{x:33.828038,y:-117.838514},
{x:33.809571,y:-117.852101},
{x:33.796955,y:-117.836197},
{x:33.788269,y:-117.872879},
{x:33.787453,y:-117.808586},
{x:33.8736,y:-117.878672},
{x:33.886599,y:-117.863492},
{x:33.888794,y:-117.520424},
{x:33.865322,y:-117.544205},
{x:33.846244,y:-117.539578},
{x:33.845783,y:-117.54094},
{x:33.879954,y:-117.614433},
{x:33.879749,y:-117.583328},
{x:33.888981,y:-117.810524},
{x:33.872307,y:-117.745018},
{x:34.266518,y:-119.272293},
{x:34.277855,y:-119.213669},
{x:34.262619,y:-119.237053},
{x:34.234982,y:-119.038551},
{x:34.221355,y:-119.070885},
{x:34.216034,y:-119.005859},
{x:34.396793,y:-119.512363},
{x:34.39518,y:-118.922897},
{x:34.295745,y:-118.843452},
{x:34.279391,y:-118.874196},
{x:34.424004,y:-119.288521},
{x:34.217205,y:-119.177925},
{x:34.199082,y:-119.195428},
{x:34.219807,y:-119.160955},
{x:34.222373,y:-119.158854},
{x:34.19606,y:-119.160392},
{x:34.180546,y:-119.165123},
{x:34.171129,y:-119.177047},
{x:34.233269,y:-119.172752},
{x:34.176456,y:-119.208878},
{x:34.346394,y:-119.070587},
{x:34.282501,y:-118.679832},
{x:34.278057,y:-118.709412},
{x:34.279495,y:-118.736145},
{x:34.272289,y:-118.770363},
{x:34.423608,y:-119.705139},
{x:34.422282,y:-119.680179},
{x:34.44074,y:-119.753046},
{x:34.401877,y:-119.722331},
{x:34.429038,y:-119.870084},
{x:34.436848,y:-119.830205},
{x:35.209422,y:-118.832948},
{x:35.403215,y:-119.400594},
{x:36.136847,y:-120.363407},
{x:36.253913,y:-120.249507},
{x:36.097738,y:-119.572428},
{x:35.746482,y:-119.246993},
{x:35.776318,y:-119.246638},
{x:35.892362,y:-119.271328},
{x:36.29771,y:-119.156275},
{x:36.325856,y:-119.209968},
{x:36.323174,y:-119.673378},
{x:36.321738,y:-119.676447},
{x:36.342969,y:-119.656574},
{x:36.327702,y:-119.650453},
{x:35.986911,y:-119.958823},
{x:35.261423,y:-118.914827},
{x:34.793966,y:-118.852473},
{x:34.98439,y:-118.947813},
{x:36.313003,y:-119.781325},
{x:36.263491,y:-119.90002},
{x:36.202981,y:-119.103064},
{x:35.615472,y:-119.660646},
{x:35.674094,y:-119.228132},
{x:36.079948,y:-119.047267},
{x:36.064889,y:-119.008622},
{x:35.499484,y:-119.278756},
{x:35.144691,y:-119.465628},
{x:36.180937,y:-119.329974},
{x:36.197049,y:-119.327373},
{x:36.226302,y:-119.337336},
{x:36.213331,y:-119.348558},
{x:36.32803,y:-119.331398},
{x:36.312534,y:-119.348595},
{x:36.297756,y:-119.313118},
{x:35.602008,y:-119.349737},
{x:36.357175,y:-119.296597},
{x:36.341362,y:-119.348959},
{x:36.325245,y:-119.276599},
{x:35.380624,y:-119.02309},
{x:35.35355,y:-119.019672},
{x:35.317215,y:-119.033245},
{x:35.375579,y:-119.002498},
{x:35.391518,y:-118.960843},
{x:35.375542,y:-118.950297},
{x:35.397187,y:-118.967193},
{x:35.295317,y:-119.020629},
{x:35.354711,y:-118.914759},
{x:35.298661,y:-119.028593},
{x:35.440858,y:-119.078925},
{x:35.408303,y:-119.021431},
{x:35.411886,y:-119.060917},
{x:35.354842,y:-119.061551},
{x:35.367118,y:-119.042917},
{x:35.339931,y:-119.05363},
{x:35.308748,y:-119.095443},
{x:35.349218,y:-119.126702},
{x:35.383138,y:-119.146589},
{x:35.386298,y:-119.095736},
{x:35.402082,y:-119.093022},
{x:35.295749,y:-119.077249},
{x:35.296432,y:-119.039689},
{x:35.317618,y:-119.089801},
{x:35.262918,y:-120.677356},
{x:35.129026,y:-120.597686},
{x:35.120764,y:-120.586459},
{x:35.484064,y:-120.665758},
{x:34.61408,y:-120.189094},
{x:35.120835,y:-120.612473},
{x:34.662957,y:-120.465174},
{x:34.651564,y:-120.458235},
{x:35.368139,y:-120.842956},
{x:35.037893,y:-120.484388},
{x:35.590898,y:-120.694834},
{x:35.642786,y:-120.686405},
{x:35.61462,y:-120.681836},
{x:35.13675,y:-120.62644},
{x:34.919937,y:-120.419083},
{x:34.935637,y:-120.419156},
{x:34.956193,y:-120.435077},
{x:34.864709,y:-120.416212},
{x:34.920274,y:-120.453499},
{x:34.929667,y:-120.43543},
{x:35.048187,y:-118.171875},
{x:35.125638,y:-117.958258},
{x:34.492779,y:-118.197212},
{x:37.365234,y:-118.39502},
{x:34.660606,y:-118.184097},
{x:34.704346,y:-118.146698},
{x:34.674469,y:-118.152596},
{x:34.689362,y:-118.095161},
{x:34.692445,y:-118.096998},
{x:34.676336,y:-118.11274},
{x:34.697137,y:-118.172411},
{x:34.693851,y:-118.174027},
{x:34.521455,y:-117.979013},
{x:36.60227,y:-118.061971},
{x:34.601292,y:-118.147027},
{x:34.556658,y:-118.042222},
{x:34.580273,y:-118.128014},
{x:34.558361,y:-118.085281},
{x:34.611568,y:-118.144516},
{x:34.6312,y:-118.220733},
{x:34.558806,y:-118.046097},
{x:35.62426,y:-117.669266},
{x:34.864477,y:-118.176391},
{x:35.125999,y:-118.408263},
{x:35.124584,y:-118.469591},
{x:37.125977,y:-120.255101},
{x:36.845503,y:-119.664115},
{x:36.839533,y:-119.695914},
{x:36.85188,y:-119.728935},
{x:36.808076,y:-119.700686},
{x:36.837912,y:-119.698531},
{x:36.808842,y:-119.718755},
{x:36.543685,y:-119.40193},
{x:36.545885,y:-119.376627},
{x:36.63905,y:-120.626091},
{x:36.626782,y:-119.682909},
{x:36.732736,y:-120.044114},
{x:36.735243,y:-120.061264},
{x:36.51753,y:-119.562045},
{x:37.053949,y:-120.879051},
{x:37.057552,y:-120.879001},
{x:37.056537,y:-120.834854},
{x:36.971902,y:-120.045045},
{x:36.974145,y:-120.083835},
{x:36.952501,y:-120.075568},
{x:36.750397,y:-120.387412},
{x:37.332005,y:-119.654974},
{x:36.83755,y:-119.802354},
{x:36.839146,y:-119.803277},
{x:36.603653,y:-119.458217},
{x:36.705587,y:-119.577721},
{x:36.71186,y:-119.557583},
{x:36.576711,y:-119.626045},
{x:36.743553,y:-119.789776},
{x:36.736211,y:-119.74939},
{x:36.758708,y:-119.790196},
{x:36.778893,y:-119.790847},
{x:36.76554,y:-119.736059},
{x:36.793486,y:-119.825997},
{x:36.706472,y:-119.771263},
{x:36.836756,y:-119.753712},
{x:36.820433,y:-119.789939},
{x:36.808978,y:-119.782453},
{x:36.808092,y:-119.756681},
{x:36.838329,y:-119.847932},
{x:36.807881,y:-119.849869},
{x:36.851696,y:-119.773007},
{x:36.835821,y:-119.912931},
{x:36.808602,y:-119.884193},
{x:36.779447,y:-119.861777},
{x:36.786488,y:-119.755479},
{x:36.738188,y:-119.721807},
{x:36.735556,y:-119.732212},
{x:36.736322,y:-119.697542},
{x:36.792975,y:-119.699551},
{x:36.757864,y:-119.832016},
{x:36.658198,y:-121.635863},
{x:36.656793,y:-121.659477},
{x:36.67286,y:-121.629259},
{x:36.704682,y:-121.653464},
{x:36.680134,y:-121.605601},
{x:36.680307,y:-121.641011},
{x:36.715266,y:-121.626022},
{x:36.715744,y:-121.657233},
{x:36.717808,y:-121.65037},
{x:36.792455,y:-121.666339},
{x:36.513998,y:-121.437034},
{x:36.326786,y:-121.249048},
{x:36.202574,y:-121.136129},
{x:36.685592,y:-121.794555},
{x:36.599783,y:-121.88805},
{x:36.61904,y:-121.844073},
{x:36.60836,y:-121.856101},
{x:36.418298,y:-121.321505},
{x:37.524906,y:-122.281845},
{x:37.706612,y:-122.415321},
{x:37.692028,y:-122.471275},
{x:37.668903,y:-122.469986},
{x:37.470356,y:-122.434441},
{x:37.453926,y:-122.183139},
{x:37.606554,y:-122.398421},
{x:37.400849,y:-122.10971},
{x:37.391403,y:-122.096405},
{x:37.41941,y:-122.093608},
{x:37.594349,y:-122.50216},
{x:37.65226,y:-122.491196},
{x:37.494091,y:-122.235619},
{x:37.467598,y:-122.22363},
{x:37.479259,y:-122.224258},
{x:37.511166,y:-122.265778},
{x:37.643961,y:-122.40362},
{x:37.648029,y:-122.429626},
{x:37.643787,y:-122.453667},
{x:37.362938,y:-122.027153},
{x:37.39238,y:-122.030152},
{x:37.385469,y:-121.995384},
{x:37.373863,y:-122.05484},
{x:37.779861,y:-122.431717},
{x:37.788946,y:-122.401488},
{x:37.789217,y:-122.407641},
{x:37.765404,y:-122.407924},
{x:37.713135,y:-122.444542},
{x:37.752127,y:-122.418215},
{x:37.79389,y:-122.399109},
{x:37.723492,y:-122.45546},
{x:37.76904,y:-122.453177},
{x:37.728005,y:-122.392781},
{x:37.742161,y:-122.405205},
{x:37.72658,y:-122.476528},
{x:37.808235,y:-122.41449},
{x:37.460234,y:-122.137714},
{x:37.471874,y:-122.140205},
{x:37.44293,y:-122.171203},
{x:37.421143,y:-122.139534},
{x:37.553954,y:-122.316364},
{x:37.563316,y:-122.271698},
{x:37.771774,y:-122.276077},
{x:37.755219,y:-122.252029},
{x:38.18301,y:-122.25267},
{x:37.966538,y:-121.782753},
{x:37.988757,y:-121.785987},
{x:38.005412,y:-121.752089},
{x:37.96346,y:-121.761807},
{x:38.003803,y:-121.834503},
{x:38.056243,y:-122.150711},
{x:38.08182,y:-122.193494},
{x:37.925691,y:-121.736362},
{x:37.947033,y:-121.696442},
{x:37.889664,y:-121.620674},
{x:37.96653,y:-122.060827},
{x:37.954807,y:-122.041954},
{x:37.962128,y:-121.986565},
{x:37.973266,y:-122.06438},
{x:37.953545,y:-122.0597},
{x:37.798991,y:-121.922941},
{x:37.928219,y:-122.320831},
{x:38.293484,y:-122.030807},
{x:38.249042,y:-122.066985},
{x:38.27856,y:-122.035259},
{x:38.222157,y:-122.126244},
{x:38.269241,y:-122.033711},
{x:38.258816,y:-122.067384},
{x:38.260567,y:-122.05197},
{x:37.491051,y:-121.949463},
{x:37.571052,y:-122.031701},
{x:37.556797,y:-122.014076},
{x:37.54932,y:-121.98912},
{x:37.52027,y:-121.985562},
{x:37.534321,y:-121.966749},
{x:37.539711,y:-121.923195},
{x:37.666771,y:-122.106169},
{x:37.670784,y:-122.120697},
{x:37.667244,y:-122.081131},
{x:37.64901,y:-122.06591},
{x:37.608723,y:-122.066391},
{x:37.631363,y:-122.096474},
{x:37.692089,y:-122.089638},
{x:37.68912,y:-122.063026},
{x:38.008762,y:-122.271294},
{x:37.892375,y:-122.113998},
{x:37.710847,y:-121.723693},
{x:37.699623,y:-121.742486},
{x:37.682648,y:-121.778091},
{x:37.996582,y:-122.109653},
{x:37.994213,y:-122.100967},
{x:38.281094,y:-122.275536},
{x:38.319935,y:-122.298515},
{x:37.549561,y:-122.04644},
{x:37.525716,y:-122.00552},
{x:37.996067,y:-122.304916},
{x:38.008973,y:-121.868109},
{x:38.021021,y:-121.94232},
{x:38.008003,y:-121.887398},
{x:37.669865,y:-121.858222},
{x:37.706203,y:-121.924286},
{x:38.16108,y:-121.690674},
{x:37.714534,y:-122.177842},
{x:37.724882,y:-122.157056},
{x:37.693394,y:-122.110744},
{x:37.6884,y:-122.138611},
{x:37.707821,y:-122.132957},
{x:37.724595,y:-121.943196},
{x:37.774364,y:-121.97654},
{x:38.244177,y:-122.019982},
{x:37.604668,y:-122.067257},
{x:37.590275,y:-122.020042},
{x:37.70066,y:-121.871405},
{x:37.698018,y:-121.887415},
{x:37.695206,y:-121.92878},
{x:38.154466,y:-122.25327},
{x:38.119171,y:-122.255508},
{x:38.132084,y:-122.223648},
{x:38.095298,y:-122.229331},
{x:38.10561,y:-122.209595},
{x:38.084423,y:-122.231815},
{x:37.768325,y:-122.223462},
{x:37.781475,y:-122.234238},
{x:37.744392,y:-122.171135},
{x:37.768189,y:-122.174145},
{x:37.803181,y:-122.282188},
{x:37.849432,y:-122.286114},
{x:37.833454,y:-122.262955},
{x:37.80175,y:-122.264999},
{x:37.745892,y:-122.195553},
{x:37.763855,y:-122.196098},
{x:37.872278,y:-122.268577},
{x:37.882496,y:-122.296951},
{x:37.936394,y:-122.347244},
{x:37.961576,y:-122.326587},
{x:37.983789,y:-122.318678},
{x:37.966892,y:-122.343895},
{x:37.975933,y:-122.547035},
{x:37.996441,y:-122.534585},
{x:38.36741,y:-122.716486},
{x:38.335677,y:-122.676689},
{x:38.344402,y:-122.711586},
{x:37.890682,y:-122.516022},
{x:38.106724,y:-122.569916},
{x:38.061424,y:-122.53213},
{x:38.249481,y:-122.627098},
{x:38.235634,y:-122.623001},
{x:36.975302,y:-121.963762},
{x:37.337227,y:-122.040985},
{x:37.023616,y:-121.562484},
{x:37.008257,y:-121.551889},
{x:37.01379,y:-121.586357},
{x:37.001671,y:-121.559998},
{x:36.837093,y:-121.389149},
{x:36.859117,y:-121.401039},
{x:37.243828,y:-121.959808},
{x:37.42716,y:-121.921689},
{x:37.412754,y:-121.897733},
{x:37.457391,y:-121.911057},
{x:37.434448,y:-121.901329},
{x:37.417843,y:-121.871582},
{x:37.133223,y:-121.631362},
{x:37.150625,y:-121.655269},
{x:37.11129,y:-121.641953},
{x:37.356895,y:-121.936514},
{x:37.352051,y:-121.958595},
{x:37.338303,y:-121.993866},
{x:37.323669,y:-121.96904},
{x:37.381836,y:-121.978859},
{x:36.983059,y:-122.022357},
{x:36.967133,y:-122.039064},
{x:37.047197,y:-122.032551},
{x:37.29185,y:-121.996793},
{x:36.916825,y:-121.775716},
{x:36.907686,y:-121.753602},
{x:36.932166,y:-121.769657},
{x:37.307709,y:-121.848877},
{x:37.367015,y:-121.848498},
{x:37.34875,y:-121.865073},
{x:37.245586,y:-121.888138},
{x:37.235984,y:-121.803392},
{x:37.231623,y:-121.773126},
{x:37.299934,y:-121.822433},
{x:37.330946,y:-121.860468},
{x:37.324743,y:-121.823029},
{x:37.349842,y:-121.82766},
{x:37.325158,y:-121.814191},
{x:37.250969,y:-121.831841},
{x:37.272849,y:-121.933524},
{x:37.26208,y:-121.922016},
{x:37.304719,y:-121.862853},
{x:37.289906,y:-121.880569},
{x:37.32322,y:-121.913297},
{x:37.310993,y:-121.931313},
{x:37.298073,y:-121.950347},
{x:37.322662,y:-121.992832},
{x:37.303898,y:-122.031822},
{x:37.383211,y:-121.895673},
{x:37.375099,y:-121.918892},
{x:37.387286,y:-121.860566},
{x:37.300922,y:-121.772301},
{x:37.275165,y:-121.865249},
{x:37.260862,y:-121.875885},
{x:37.266437,y:-121.832239},
{x:37.254614,y:-121.79978},
{x:37.315996,y:-121.793639},
{x:37.96684,y:-121.295466},
{x:37.949358,y:-121.289447},
{x:37.965936,y:-121.274603},
{x:37.937558,y:-121.295585},
{x:37.985294,y:-121.338432},
{x:37.989318,y:-121.3124},
{x:38.021563,y:-121.344566},
{x:38.002111,y:-121.292228},
{x:38.02314,y:-121.322448},
{x:38.023011,y:-121.27421},
{x:38.021788,y:-121.272395},
{x:37.987817,y:-121.246037},
{x:38.005394,y:-121.354063},
{x:38.053389,y:-121.375696},
{x:38.049681,y:-121.376089},
{x:38.082862,y:-120.559973},
{x:38.129945,y:-121.275876},
{x:38.116786,y:-121.256998},
{x:38.113161,y:-121.307076},
{x:38.114392,y:-121.394398},
{x:37.343365,y:-120.615094},
{x:37.360895,y:-120.59545},
{x:37.75152,y:-121.471968},
{x:37.608764,y:-120.938393},
{x:37.606778,y:-120.937379},
{x:37.259338,y:-120.999766},
{x:37.100352,y:-121.015258},
{x:37.40402,y:-120.849405},
{x:37.811403,y:-121.291176},
{x:37.388992,y:-120.737693},
{x:37.797174,y:-121.194344},
{x:37.796985,y:-121.235685},
{x:37.785542,y:-121.218556},
{x:37.826934,y:-121.234549},
{x:37.786,y:-121.251729},
{x:37.317954,y:-120.501994},
{x:37.288848,y:-120.45485},
{x:37.300042,y:-120.494352},
{x:37.320892,y:-120.484614},
{x:37.318696,y:-120.473458},
{x:37.673342,y:-121.037061},
{x:37.68909,y:-121.012182},
{x:37.672233,y:-121.030921},
{x:37.674223,y:-120.993638},
{x:37.627048,y:-121.013372},
{x:37.646463,y:-121.030666},
{x:37.608942,y:-120.973958},
{x:37.63915,y:-121.000082},
{x:37.655236,y:-120.95777},
{x:37.678395,y:-120.958164},
{x:37.69993,y:-121.049394},
{x:37.638614,y:-120.926279},
{x:37.594538,y:-120.975605},
{x:37.771355,y:-120.835898},
{x:37.464824,y:-121.141562},
{x:37.753736,y:-121.138683},
{x:37.733041,y:-120.959323},
{x:37.700806,y:-121.073973},
{x:37.97706,y:-120.316275},
{x:37.975187,y:-120.361185},
{x:37.739281,y:-121.421704},
{x:37.761772,y:-121.434521},
{x:37.738984,y:-121.450564},
{x:37.709571,y:-121.434069},
{x:37.52021,y:-120.880628},
{x:37.509091,y:-120.873482},
{x:37.492281,y:-120.872054},
{x:37.515145,y:-120.848486},
{x:37.540737,y:-121.266509},
{x:38.452928,y:-122.770523},
{x:38.452908,y:-122.716835},
{x:38.480387,y:-122.735923},
{x:38.457938,y:-122.67342},
{x:38.413494,y:-122.713009},
{x:38.929988,y:-122.617196},
{x:38.784095,y:-123.013211},
{x:39.423978,y:-123.806906},
{x:38.605525,y:-122.868411},
{x:39.032295,y:-122.924362},
{x:38.303112,y:-122.477066},
{x:39.167996,y:-123.211872},
{x:39.152005,y:-123.198693},
{x:39.394604,y:-123.349533},
{x:38.547535,y:-122.81158},
{x:40.804472,y:-124.152796},
{x:40.776316,y:-124.190687},
{x:40.932548,y:-124.101319},
{x:40.902796,y:-124.083884},
{x:41.755344,y:-124.193527},
{x:40.58245,y:-124.146309},
{x:38.941919,y:-121.095855},
{x:38.922321,y:-121.05602},
{x:38.591151,y:-121.549378},
{x:38.634508,y:-121.328524},
{x:38.595651,y:-121.345114},
{x:38.664898,y:-121.271832},
{x:38.664021,y:-121.291277},
{x:38.704544,y:-121.271462},
{x:38.550605,y:-121.697333},
{x:38.457749,y:-121.84161},
{x:38.679443,y:-121.319412},
{x:38.707835,y:-121.312424},
{x:38.438762,y:-121.396742},
{x:38.408584,y:-121.380301},
{x:38.662945,y:-121.226322},
{x:38.65493,y:-121.124396},
{x:38.643646,y:-121.189543},
{x:38.687149,y:-121.178614},
{x:38.671711,y:-121.161759},
{x:38.290808,y:-121.310678},
{x:38.252795,y:-121.294645},
{x:38.371031,y:-120.798287},
{x:36.543332,y:-119.286335},
{x:38.88897,y:-121.293043},
{x:38.875345,y:-121.29662},
{x:38.689891,y:-121.383389},
{x:38.654897,y:-121.383478},
{x:38.741562,y:-121.22679},
{x:38.705239,y:-120.827954},
{x:38.730916,y:-120.78807},
{x:38.615079,y:-121.269834},
{x:38.631454,y:-121.219479},
{x:38.596115,y:-121.29187},
{x:38.588379,y:-121.305276},
{x:38.588152,y:-121.288279},
{x:38.683386,y:-121.456217},
{x:38.807182,y:-121.204913},
{x:38.802598,y:-121.201923},
{x:38.782297,y:-121.266464},
{x:38.787873,y:-121.289738},
{x:38.773575,y:-121.269289},
{x:38.74642,y:-121.27139},
{x:38.657927,y:-120.970913},
{x:38.361996,y:-121.958986},
{x:38.342164,y:-121.994618},
{x:38.386125,y:-121.932958},
{x:38.351859,y:-121.985269},
{x:38.361329,y:-121.972339},
{x:38.55679,y:-121.540079},
{x:38.579028,y:-121.548305},
{x:38.580696,y:-121.531082},
{x:38.678576,y:-121.786691},
{x:38.676848,y:-121.750309},
{x:39.094108,y:-120.951311},
{x:38.589646,y:-121.264854},
{x:38.729053,y:-121.27251},
{x:38.750172,y:-121.309891},
{x:38.393132,y:-121.416927},
{x:38.423968,y:-121.414685},
{x:38.408883,y:-121.476629},
{x:38.422247,y:-121.400694},
{x:38.649742,y:-121.068161},
{x:38.804698,y:-121.2737},
{x:38.796179,y:-121.314687},
{x:38.596837,y:-121.501869},
{x:38.612657,y:-121.446978},
{x:38.571581,y:-121.466828},
{x:38.559212,y:-121.482089},
{x:38.550532,y:-121.488576},
{x:38.558876,y:-121.438512},
{x:38.525289,y:-121.441728},
{x:38.617097,y:-121.400848},
{x:38.611836,y:-121.383615},
{x:38.617889,y:-121.367011},
{x:38.4961,y:-121.484453},
{x:38.496597,y:-121.430567},
{x:38.495722,y:-121.457468},
{x:38.473783,y:-121.428538},
{x:38.474791,y:-121.447066},
{x:38.5246,y:-121.40968},
{x:38.59673,y:-121.41585},
{x:38.573795,y:-121.409557},
{x:38.555949,y:-121.409909},
{x:38.554914,y:-121.372339},
{x:38.556402,y:-121.336883},
{x:38.481414,y:-121.40719},
{x:38.455452,y:-121.404527},
{x:38.455896,y:-121.37212},
{x:38.480815,y:-121.507091},
{x:38.635869,y:-121.476501},
{x:38.641447,y:-121.508058},
{x:38.644693,y:-121.456349},
{x:38.659893,y:-121.347345},
{x:38.704194,y:-121.33194},
{x:38.686753,y:-121.341586},
{x:38.711499,y:-121.364582},
{x:38.595527,y:-121.384701},
{x:39.138651,y:-121.590779},
{x:39.759292,y:-121.856561},
{x:39.737891,y:-121.835308},
{x:39.716565,y:-121.795697},
{x:39.157288,y:-122.145951},
{x:39.237347,y:-121.037773},
{x:39.35992,y:-121.688357},
{x:39.497637,y:-121.571422},
{x:39.764872,y:-121.602484},
{x:39.761724,y:-121.821165},
{x:39.522497,y:-122.211693},
{x:39.141335,y:-121.62922},
{x:39.146744,y:-121.644243},
{x:39.144367,y:-121.654017},
{x:39.142159,y:-121.655029},
{x:40.583985,y:-122.343262},
{x:40.570813,y:-122.356935},
{x:40.587437,y:-122.350424},
{x:40.609384,y:-122.378946},
{x:40.455887,y:-122.294041},
{x:40.888032,y:-121.654015},
{x:40.680207,y:-122.350046},
{x:39.904824,y:-122.198137},
{x:40.182154,y:-122.219592},
{x:41.397601,y:-122.379596},
{x:41.70264,y:-122.644776},
{x:40.40529,y:-120.633054},
{x:38.949933,y:-119.955126},
{x:38.912423,y:-120.003335},
{x:39.320788,y:-120.20867},
{x:21.381949,y:-157.943709},
{x:21.378909,y:-157.930208},
{x:21.904751,y:-159.584882},
{x:21.326761,y:-158.022659},
{x:21.317707,y:-158.013887},
{x:21.339842,y:-158.077026},
{x:21.34195,y:-158.075231},
{x:21.328431,y:-158.08786},
{x:21.581169,y:-158.105528},
{x:19.691164,y:-155.066512},
{x:19.723204,y:-155.087408},
{x:20.87822,y:-156.455001},
{x:20.883133,y:-156.453627},
{x:20.887603,y:-156.465045},
{x:21.442464,y:-157.744955},
{x:21.395311,y:-157.742782},
{x:21.377948,y:-157.728776},
{x:19.639758,y:-155.990748},
{x:19.700173,y:-155.061947},
{x:19.649047,y:-156.003992},
{x:19.646235,y:-155.990828},
{x:20.022401,y:-155.666841},
{x:21.402491,y:-157.797779},
{x:21.435486,y:-157.826344},
{x:22.061149,y:-159.321851},
{x:19.622084,y:-155.039268},
{x:19.51759,y:-155.920444},
{x:20.750603,y:-156.456286},
{x:20.877909,y:-156.679397},
{x:20.971772,y:-156.67753},
{x:21.64239,y:-157.919927},
{x:21.982337,y:-159.36503},
{x:21.977832,y:-159.368738},
{x:20.836092,y:-156.337278},
{x:21.394489,y:-157.969645},
{x:21.397234,y:-157.974458},
{x:21.400147,y:-157.974135},
{x:21.494338,y:-158.028696},
{x:21.449894,y:-158.005546},
{x:21.456484,y:-158.015757},
{x:21.467467,y:-158.001668},
{x:21.447831,y:-158.188961},
{x:21.387552,y:-158.151428},
{x:20.872414,y:-156.500388},
{x:20.887568,y:-156.503724},
{x:20.906114,y:-156.487458},
{x:21.340635,y:-157.704103},
{x:21.378413,y:-158.024173},
{x:21.419327,y:-158.005198},
{x:21.400332,y:-158.007572},
{x:21.39137,y:-158.034436},
{x:21.30986,y:-157.860991},
{x:21.291344,y:-157.843688},
{x:21.302412,y:-157.846474},
{x:21.297285,y:-157.841009},
{x:21.280471,y:-157.827558},
{x:21.274599,y:-157.824149},
{x:21.285637,y:-157.838445},
{x:21.286847,y:-157.806451},
{x:21.275447,y:-157.78567},
{x:21.335327,y:-157.868494},
{x:21.321575,y:-157.859667},
{x:21.324232,y:-157.875919},
{x:21.367076,y:-157.930041},
{x:21.349632,y:-157.945114},
{x:21.34893,y:-157.930476},
{x:21.345912,y:-157.913406},
{x:21.344114,y:-157.928129},
{x:21.327114,y:-157.886055},
{x:21.336128,y:-157.914062},
{x:21.278297,y:-157.754181},
{x:21.309661,y:-157.809588},
{x:21.28524,y:-157.708106},
{x:21.293925,y:-157.8282},
{x:45.486866,y:-122.774213},
{x:45.494017,y:-122.810679},
{x:45.517031,y:-122.841423},
{x:45.495417,y:-122.878416},
{x:45.53723,y:-122.867108},
{x:45.479845,y:-122.858302},
{x:45.258832,y:-122.699324},
{x:45.430548,y:-122.540558},
{x:45.417277,y:-122.457801},
{x:45.409824,y:-122.525789},
{x:45.408089,y:-122.568372},
{x:45.383033,y:-122.582054},
{x:45.504034,y:-122.415851},
{x:45.709598,y:-121.534022},
{x:45.711291,y:-121.500129},
{x:45.409292,y:-122.720623},
{x:45.151528,y:-122.605474},
{x:45.368954,y:-122.60174},
{x:45.334827,y:-122.589672},
{x:45.848453,y:-122.832727},
{x:45.400972,y:-122.278637},
{x:45.740688,y:-122.877191},
{x:45.604322,y:-121.138615},
{x:45.607344,y:-121.201106},
{x:45.528603,y:-122.412535},
{x:45.543963,y:-122.397545},
{x:45.382445,y:-122.746527},
{x:45.380982,y:-122.763871},
{x:45.669451,y:-120.834737},
{x:45.346273,y:-122.650944},
{x:45.389909,y:-122.643714},
{x:45.305448,y:-122.766677},
{x:45.151109,y:-122.836343},
{x:45.147816,y:-122.880053},
{x:45.150706,y:-122.876839},
{x:45.489834,y:-122.475029},
{x:45.433797,y:-122.578433},
{x:46.190137,y:-123.835955},
{x:45.52051,y:-123.088946},
{x:45.519933,y:-122.989376},
{x:45.516434,y:-122.973413},
{x:45.566311,y:-122.894745},
{x:45.53386,y:-122.934544},
{x:45.201922,y:-123.167046},
{x:45.227023,y:-123.181755},
{x:45.306363,y:-122.948801},
{x:45.592038,y:-122.996817},
{x:45.992238,y:-123.920397},
{x:45.365601,y:-122.847698},
{x:45.469147,y:-123.843698},
{x:45.497723,y:-122.635882},
{x:45.586878,y:-122.738913},
{x:45.516646,y:-122.680115},
{x:45.523202,y:-122.68999},
{x:45.545702,y:-122.706105},
{x:45.581751,y:-122.661914},
{x:45.5373,y:-122.622445},
{x:45.519403,y:-122.579374},
{x:45.555647,y:-122.698697},
{x:45.610169,y:-122.680596},
{x:45.589565,y:-122.598465},
{x:45.452608,y:-122.722419},
{x:45.526722,y:-122.538215},
{x:45.53381,y:-122.556983},
{x:45.546939,y:-122.578378},
{x:45.453398,y:-122.579479},
{x:45.443344,y:-122.628548},
{x:45.439286,y:-122.751746},
{x:45.403715,y:-122.79658},
{x:45.443259,y:-122.803825},
{x:45.4323,y:-122.767904},
{x:45.527733,y:-122.813817},
{x:45.566887,y:-122.537191},
{x:45.538462,y:-122.477712},
{x:45.534126,y:-122.66043},
{x:45.518742,y:-122.474722},
{x:45.496723,y:-122.569995},
{x:45.48205,y:-122.579604},
{x:45.407941,y:-122.624909},
{x:44.915416,y:-122.980825},
{x:44.940332,y:-123.026978},
{x:44.943627,y:-122.98371},
{x:44.949282,y:-122.992171},
{x:44.898407,y:-123.037908},
{x:45.008228,y:-122.998368},
{x:44.9944,y:-123.026079},
{x:44.947242,y:-123.049857},
{x:44.965581,y:-122.983984},
{x:44.964702,y:-122.987321},
{x:44.990122,y:-122.986901},
{x:44.880984,y:-123.029185},
{x:44.969028,y:-123.028145},
{x:44.642444,y:-123.064202},
{x:44.623033,y:-123.110547},
{x:44.566697,y:-123.260052},
{x:44.590896,y:-123.254342},
{x:44.929329,y:-123.309613},
{x:44.850954,y:-123.205702},
{x:44.523933,y:-122.907498},
{x:44.646866,y:-124.052474},
{x:44.995131,y:-124.004716},
{x:44.539308,y:-123.365822},
{x:45.014142,y:-122.786261},
{x:44.809491,y:-122.814058},
{x:44.399578,y:-122.720247},
{x:44.026848,y:-123.090267},
{x:44.079693,y:-123.06927},
{x:44.048383,y:-123.1446},
{x:44.045926,y:-123.169551},
{x:44.085059,y:-123.167454},
{x:44.095994,y:-123.126537},
{x:44.05301,y:-123.121617},
{x:44.050179,y:-123.083049},
{x:44.136547,y:-123.056081},
{x:42.053332,y:-124.28712},
{x:43.389526,y:-124.252163},
{x:43.361385,y:-124.212791},
{x:43.803506,y:-123.044917},
{x:43.984574,y:-124.10095},
{x:44.222332,y:-123.203973},
{x:42.972925,y:-123.340583},
{x:43.392261,y:-124.238837},
{x:43.699863,y:-124.110501},
{x:43.237878,y:-123.369593},
{x:43.226461,y:-123.350006},
{x:43.145504,y:-123.38006},
{x:44.045384,y:-122.929165},
{x:44.082978,y:-123.041066},
{x:44.05926,y:-122.999386},
{x:43.386798,y:-123.335088},
{x:42.31049,y:-122.856449},
{x:42.28134,y:-122.817416},
{x:42.377781,y:-122.909648},
{x:42.424584,y:-122.851711},
{x:42.366328,y:-122.856608},
{x:42.316173,y:-122.861521},
{x:42.340808,y:-122.871317},
{x:42.434302,y:-123.301991},
{x:42.461272,y:-123.322221},
{x:42.436527,y:-123.308321},
{x:42.420628,y:-123.353337},
{x:42.211706,y:-121.754484},
{x:42.249606,y:-121.789978},
{x:42.193358,y:-121.760605},
{x:44.05523,y:-121.265968},
{x:44.070197,y:-121.301977},
{x:44.038001,y:-121.30497},
{x:44.0484,y:-121.331376},
{x:44.023739,y:-121.317456},
{x:43.573647,y:-119.074162},
{x:43.674047,y:-121.49987},
{x:44.639907,y:-121.129101},
{x:44.302554,y:-120.851692},
{x:44.268937,y:-121.19372},
{x:44.292025,y:-121.170572},
{x:44.253268,y:-121.182824},
{x:44.297825,y:-121.561872},
{x:45.643702,y:-118.683013},
{x:45.656635,y:-118.809732},
{x:44.782064,y:-117.815106},
{x:45.849787,y:-119.28952},
{x:45.3301,y:-118.075473},
{x:45.931299,y:-118.38751},
{x:45.76582,y:-119.20571},
{x:44.024536,y:-116.986489},
{x:44.024076,y:-116.940686},
{x:47.296216,y:-122.248467},
{x:47.273376,y:-122.228461},
{x:47.321623,y:-122.2221},
{x:47.301124,y:-122.224101},
{x:47.32175,y:-122.309706},
{x:47.315474,y:-122.303278},
{x:47.289332,y:-122.317554},
{x:47.354876,y:-122.311648},
{x:47.615472,y:-122.203771},
{x:47.622584,y:-122.132783},
{x:47.627637,y:-122.154566},
{x:47.57315,y:-122.168998},
{x:47.581241,y:-122.136119},
{x:47.75798,y:-122.244726},
{x:47.849983,y:-122.218353},
{x:47.877649,y:-122.187514},
{x:47.791032,y:-122.368853},
{x:47.797135,y:-122.212016},
{x:47.199065,y:-121.978557},
{x:47.804376,y:-122.328965},
{x:47.545425,y:-122.058611},
{x:47.552715,y:-122.043438},
{x:47.371993,y:-122.205064},
{x:47.386452,y:-122.197843},
{x:47.412649,y:-122.228618},
{x:47.380981,y:-122.248523},
{x:47.722784,y:-122.20729},
{x:47.679707,y:-122.176535},
{x:47.731595,y:-122.174217},
{x:47.712568,y:-122.186172},
{x:47.818983,y:-122.293026},
{x:47.820426,y:-122.268581},
{x:47.839551,y:-122.29827},
{x:47.862703,y:-122.282586},
{x:47.848604,y:-122.252448},
{x:47.393246,y:-122.046328},
{x:47.585426,y:-122.234588},
{x:47.35789,y:-122.108374},
{x:47.488375,y:-121.793973},
{x:47.359988,y:-122.020284},
{x:47.671515,y:-122.114188},
{x:47.69997,y:-122.025305},
{x:47.44624,y:-122.198096},
{x:47.537282,y:-122.165952},
{x:47.504227,y:-122.159714},
{x:47.487929,y:-122.175259},
{x:47.531989,y:-122.195173},
{x:47.484108,y:-122.217821},
{x:47.472142,y:-122.221038},
{x:47.471234,y:-122.212643},
{x:47.445217,y:-122.151299},
{x:47.752848,y:-122.153447},
{x:47.614917,y:-122.03596},
{x:47.243785,y:-122.211678},
{x:47.6145,y:-122.337938},
{x:47.610689,y:-122.338389},
{x:47.667733,y:-122.300355},
{x:47.668354,y:-122.37307},
{x:47.516667,y:-122.314499},
{x:47.620527,y:-122.347155},
{x:47.635428,y:-122.514822},
{x:47.576649,y:-122.387001},
{x:47.695071,y:-122.3745},
{x:47.545613,y:-122.285898},
{x:47.520192,y:-122.266902},
{x:47.521372,y:-122.366301},
{x:47.738646,y:-122.345756},
{x:47.708315,y:-122.33165},
{x:47.581513,y:-122.329522},
{x:47.543301,y:-122.386888},
{x:47.581633,y:-122.300053},
{x:47.501285,y:-122.354649},
{x:47.462651,y:-122.33336},
{x:47.77579,y:-122.312002},
{x:47.734201,y:-122.292977},
{x:47.443648,y:-122.302696},
{x:47.466338,y:-122.287806},
{x:47.454461,y:-122.262957},
{x:47.397982,y:-122.295337},
{x:47.982239,y:-122.202576},
{x:47.963696,y:-122.208497},
{x:47.925795,y:-122.225411},
{x:47.908213,y:-122.226905},
{x:47.88147,y:-122.241384},
{x:48.511301,y:-122.612157},
{x:48.181315,y:-122.127602},
{x:48.152988,y:-122.181788},
{x:48.734355,y:-122.470529},
{x:48.755522,y:-122.463039},
{x:48.788896,y:-122.443254},
{x:48.801543,y:-122.482058},
{x:48.78394,y:-122.487172},
{x:48.448479,y:-122.335995},
{x:48.46847,y:-122.34368},
{x:48.848559,y:-122.573189},
{x:48.082745,y:-121.975046},
{x:48.001764,y:-122.105307},
{x:48.936191,y:-122.484858},
{x:48.083806,y:-122.173466},
{x:48.090954,y:-122.186255},
{x:48.078987,y:-122.188538},
{x:48.052991,y:-122.186196},
{x:47.859169,y:-121.967939},
{x:48.435408,y:-122.312558},
{x:47.890429,y:-122.284668},
{x:48.305229,y:-122.644137},
{x:48.501653,y:-122.257746},
{x:47.923324,y:-122.097264},
{x:48.239217,y:-122.329571},
{x:47.868316,y:-121.777094},
{x:47.837723,y:-122.124521},
{x:47.597471,y:-122.62904},
{x:47.62588,y:-122.629375},
{x:47.571805,y:-122.662224},
{x:47.689046,y:-122.712184},
{x:47.090696,y:-122.645616},
{x:47.359216,y:-122.605652},
{x:47.307294,y:-122.576933},
{x:47.801287,y:-122.500859},
{x:47.252008,y:-122.294466},
{x:47.100357,y:-122.209835},
{x:48.107283,y:-123.403115},
{x:47.53434,y:-122.596289},
{x:47.502308,y:-122.64383},
{x:48.108273,y:-122.78042},
{x:47.749418,y:-122.655081},
{x:47.193672,y:-122.292419},
{x:47.16029,y:-122.303169},
{x:47.148484,y:-122.292545},
{x:47.161068,y:-122.288796},
{x:47.099445,y:-122.292774},
{x:48.079383,y:-123.119019},
{x:47.658087,y:-122.686078},
{x:47.072325,y:-122.419009},
{x:47.202526,y:-122.230189},
{x:47.167652,y:-122.171785},
{x:47.167343,y:-122.148892},
{x:47.255153,y:-122.445446},
{x:47.23991,y:-122.481751},
{x:47.255825,y:-122.521484},
{x:47.268062,y:-122.515281},
{x:47.191662,y:-122.43364},
{x:47.234082,y:-122.502423},
{x:47.222036,y:-122.474519},
{x:47.241522,y:-122.361066},
{x:47.156894,y:-122.435018},
{x:47.121583,y:-122.435414},
{x:47.157118,y:-122.401085},
{x:47.096748,y:-122.358176},
{x:47.24254,y:-122.526667},
{x:47.222105,y:-122.537484},
{x:47.18987,y:-122.511428},
{x:47.122401,y:-122.553094},
{x:47.180181,y:-122.546604},
{x:47.166382,y:-122.518366},
{x:47.163211,y:-122.483035},
{x:47.04243,y:-122.892635},
{x:46.997703,y:-122.908314},
{x:47.033262,y:-122.940353},
{x:47.044251,y:-122.833332},
{x:47.04587,y:-122.933688},
{x:47.037487,y:-122.803021},
{x:46.953405,y:-122.935059},
{x:46.996011,y:-122.826834},
{x:47.059089,y:-122.765095},
{x:46.97701,y:-123.80426},
{x:46.975326,y:-123.806605},
{x:47.455361,y:-122.818677},
{x:46.730036,y:-122.978241},
{x:46.678065,y:-122.962506},
{x:46.675921,y:-122.977571},
{x:46.6788,y:-122.979181},
{x:46.604819,y:-122.904252},
{x:46.975623,y:-123.867098},
{x:47.007879,y:-124.162087},
{x:46.680835,y:-123.738832},
{x:46.801363,y:-123.011156},
{x:47.23308,y:-123.125694},
{x:47.2263,y:-123.124255},
{x:46.944685,y:-122.612151},
{x:45.780433,y:-122.543503},
{x:45.58458,y:-122.369886},
{x:46.284413,y:-122.900574},
{x:45.812468,y:-120.808434},
{x:46.14291,y:-122.895824},
{x:46.342259,y:-124.054297},
{x:46.155055,y:-122.983097},
{x:46.145971,y:-122.9247},
{x:45.638879,y:-122.648459},
{x:45.64286,y:-122.601243},
{x:45.623909,y:-122.562243},
{x:45.715311,y:-122.652405},
{x:45.692794,y:-122.663851},
{x:45.673791,y:-122.663405},
{x:45.911167,y:-122.746794},
{x:45.671643,y:-122.509034},
{x:45.603553,y:-122.503447},
{x:45.662804,y:-122.558848},
{x:45.618777,y:-122.537447},
{x:47.436746,y:-120.324186},
{x:47.408086,y:-120.288307},
{x:48.101582,y:-119.785766},
{x:47.311876,y:-119.557363},
{x:47.598405,y:-120.656389},
{x:47.101077,y:-119.247147},
{x:47.140076,y:-119.277947},
{x:48.417106,y:-119.507485},
{x:47.233105,y:-119.854291},
{x:46.602444,y:-120.506622},
{x:46.601469,y:-120.480296},
{x:46.586542,y:-120.496953},
{x:46.603797,y:-120.530996},
{x:46.618395,y:-120.56168},
{x:46.567401,y:-120.485124},
{x:46.599656,y:-120.587764},
{x:47.195767,y:-120.952528},
{x:46.980471,y:-120.544617},
{x:46.647165,y:-120.529998},
{x:46.322464,y:-119.991068},
{x:46.375451,y:-120.322898},
{x:46.40418,y:-120.276259},
{x:47.643415,y:-117.577969},
{x:47.50206,y:-117.565473},
{x:47.944146,y:-117.477739},
{x:47.669728,y:-117.105118},
{x:47.785715,y:-117.353085},
{x:47.664952,y:-117.197445},
{x:48.533961,y:-117.904297},
{x:48.184191,y:-117.040146},
{x:46.739599,y:-117.171213},
{x:46.7148,y:-117.181668},
{x:47.117837,y:-118.367524},
{x:47.672434,y:-117.396933},
{x:47.65691,y:-117.346133},
{x:47.652288,y:-117.436322},
{x:47.610829,y:-117.367697},
{x:47.653196,y:-117.420565},
{x:47.700288,y:-117.44346},
{x:47.674703,y:-117.425851},
{x:47.656625,y:-117.263794},
{x:47.698552,y:-117.410481},
{x:47.715911,y:-117.425689},
{x:47.738988,y:-117.485232},
{x:47.741789,y:-117.410035},
{x:47.677586,y:-117.282127},
{x:47.689385,y:-117.196157},
{x:47.688407,y:-117.364768},
{x:47.773143,y:-117.401774},
{x:47.628537,y:-117.368076},
{x:47.589613,y:-117.563949},
{x:46.271356,y:-119.184873},
{x:46.241129,y:-119.120368},
{x:46.228093,y:-119.24345},
{x:46.209665,y:-119.222734},
{x:46.208939,y:-119.15821},
{x:46.2262,y:-119.208056},
{x:46.184302,y:-119.174362},
{x:46.826579,y:-119.168474},
{x:46.220337,y:-119.788736},
{x:46.257004,y:-119.311669},
{x:46.285475,y:-119.275013},
{x:46.260737,y:-119.285224},
{x:46.075631,y:-118.303047},
{x:46.055035,y:-118.346594},
{x:46.420188,y:-117.042226},
{x:61.219374,y:-149.823563},
{x:61.174275,y:-149.982613},
{x:61.137134,y:-149.950886},
{x:61.192297,y:-149.879576},
{x:61.18132,y:-149.832403},
{x:61.194617,y:-149.898632},
{x:61.188539,y:-149.870531},
{x:61.221444,y:-149.732514},
{x:61.229535,y:-149.742159},
{x:61.192947,y:-149.732712},
{x:61.209323,y:-149.775045},
{x:61.138219,y:-149.835083},
{x:61.139912,y:-149.863216},
{x:61.14016,y:-149.870107},
{x:61.108265,y:-149.857891},
{x:61.181581,y:-149.937022},
{x:61.327018,y:-149.571341},
{x:59.642583,y:-151.522907},
{x:60.559686,y:-151.235538},
{x:57.790794,y:-152.406555},
{x:61.598982,y:-149.12364},
{x:61.568692,y:-149.365273},
{x:61.581584,y:-149.430913},
{x:60.483175,y:-151.070798},
{x:64.859406,y:-147.696844},
{x:64.837255,y:-147.759486},
{x:64.7576,y:-147.350929},
{x:64.848734,y:-147.820013},
{x:58.364486,y:-134.576119},
{x:57.060111,y:-135.349288},
{x:55.34867,y:-131.672389}];