import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';

import { isWeekday } from 'utils/utilities';

import { ScheduleNameBuilder } from '../../ScheduleNameBuilder/ScheduleNameBuilder';
import { ScrollIntoView } from '../../ScrollIntoView';

import './FixedDatesBuilder.scss';

const FixedDatesBuilder = ({ value, startDate, endDate, updateField }) => {
  const [fixedDates, setFixedDates] = useState(value || []);

  useEffect(() => {
    updateField(fixedDates);
  }, [fixedDates]);

  const addRow = (newData) => {
    setFixedDates((prev) =>
      [
        ...prev.filter(
          (i) => i.date !== moment(newData.date).format('YYYY-MM-DD'),
        ),
        { ...newData, date: moment(newData.date).format('YYYY-MM-DD') },
      ].sort((a, b) =>
        moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD')),
      ),
    );
  };

  const deleteRow = (rowIndex) => {
    setFixedDates((prev) => prev.filter((d, i) => i !== rowIndex));
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      formatter: (cell) => moment(cell).format('MM-DD-YYYY'),
    },
    {
      dummy: true,
      dataField: 'weekday',
      text: 'Weekday',
      formatter: (cell, row) => moment(row.date).format('dddd'),
    },
    {
      dataField: 'schedule',
      text: 'Schedule/Modification',
      formatter: (cell) => (cell === 'X' ? 'No School' : cell),
    },
    {
      dataField: 'actions',
      text: '',
      dummy: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <Button variant="clear" onClick={() => deleteRow(rowIndex)}>
            <FontAwesomeIcon icon={faTrash} color="#787878" />
          </Button>
        );
      },
      headerStyle: () => {
        return { width: '8%' };
      },
    },
  ];

  return (
    <>
      <FixedDateForm minDate={startDate} maxDate={endDate} addRow={addRow} />
      <hr />
      <div className="rotation-dates-table">
        <BootstrapTable
          condensed
          striped
          bordered
          bootstrap4
          keyField="date"
          data={fixedDates}
          columns={columns}
        />
        <ScrollIntoView />
      </div>
    </>
  );
};

const FixedDateForm = ({ minDate, maxDate, addRow }) => {
  const [date, setDate] = useState(minDate);
  const [schedule, setSchedule] = useState(null);
  const [isSpecial, setIsSpecial] = useState(false);

  const onAdd = () => {
    addRow({ date, schedule, special: isSpecial });
    let newDate = moment(date);
    if (moment(maxDate).isAfter(newDate)) {
      newDate = newDate.add(newDate.weekday() === 5 ? 3 : 1, 'days');
    }

    setDate(newDate.toDate());
  };

  return (
    <div className="multifield-row wide-select">
      <Form.Group className="flex">
        <Form.Label>Date</Form.Label>
        <DatePicker
          selected={date}
          placeholderText="MM-DD-YYYY"
          onChange={setDate}
          dateFormat="MM-dd-yyyy"
          className="form-control"
          autoComplete="off"
          maxDate={maxDate}
          filterDate={isWeekday}
        />
      </Form.Group>
      <ScheduleNameBuilder
        onChange={({ special, schedule }) => {
          setIsSpecial(special);
          setSchedule(schedule);
        }}
        selectedDate={date}
      />
      <div className="form-action">
        <Button
          onClick={onAdd}
          disabled={
            !schedule || !date || moment(date).isAfter(moment(maxDate), 'day')
          }
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default FixedDatesBuilder;
