import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { School } from 'types';
import apiClient from 'utils/apiClient';

export const useDeleteSchedule = (schoolId: string, onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (scheduleId: string) => {
      const response = await apiClient.delete(`/v3/schedules/${scheduleId}`);

      if (response.status !== 204) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    },
    onSuccess: (responseData, scheduleId) => {
      void queryClient.invalidateQueries(['school', schoolId]);

      queryClient.setQueryData<School>(
        ['school', schoolId],
        (schoolData) =>
          schoolData && {
            ...schoolData,
            schedules: schoolData.schedules.filter((s) => s.id !== scheduleId),
          },
      );

      onSuccess();
    },
  });
};

export const useReorderSchedules = (
  schoolId: string,
  mutationOptions: UseMutationOptions<void, unknown, string[], unknown> = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (orderedIds: string[]) => {
      const response = await apiClient.patch(
        `/v3/schedules/${schoolId}/update_grid_order`,
        {
          schedule_ids: orderedIds,
        },
      );

      if (response.status !== 204) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    },
    ...mutationOptions,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries(['school', schoolId]);

      const [_, variables] = args;

      queryClient.setQueryData<School>(
        ['school', schoolId],
        (schoolData) =>
          schoolData && {
            ...schoolData,
            schedules: schoolData.schedules.map((schedule) =>
              variables.includes(schedule.id)
                ? { ...schedule, order: variables.indexOf(schedule.id) + 1 }
                : schedule,
            ),
          },
      );

      mutationOptions.onSuccess?.(...args);
    },
  });
};
