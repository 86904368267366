import styled from '@emotion/styled';
import * as M from '@mantine/core';
import MantineProvider from 'providers/MantineProvider';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { schoolsActions } from 'store/actions/actions';

import {
  Header,
  useIsOwner,
  useMySchoolId,
  useSchoolV1,
} from 'features/school';
import { SchoolTabsGuard } from 'features/school/components/SchoolTabsGuard';
import ScheduleDetailPage from 'features/schedules/pages/ScheduleDetailPage';

import {
  SchoolTabPath,
  SchoolTabProps,
  schoolTabsConfig,
} from '../features/school/schoolTabsConfig';

const School = () => {
  const { school: schoolV1, isNotFound } = useSchoolV1();

  const isOwner = useIsOwner();
  const mySchoolId = useMySchoolId();

  const router = useHistory();

  const dispatch = useDispatch();
  const match = useRouteMatch<{ schoolId: string }>();
  const location = useLocation();

  const areCoreSchedulesCreated =
    schoolV1?.day_types.length !== 0 &&
    schoolV1?.day_types.every((dt) =>
      schoolV1.schedules.some((s) => s.day_type_id === dt.id),
    );

  const isWizardVisible =
    schoolV1?.status !== 'live' && !areCoreSchedulesCreated;

  // this object is passed to all tabs
  const schoolTabProps: SchoolTabProps = useMemo(
    () => ({
      isOwner,
      isWizardVisible,
      mySchoolId,
      school: schoolV1,
      dispatch,
    }),
    [isOwner, isWizardVisible, mySchoolId, schoolV1, dispatch],
  );

  // fetch or refetch school data
  useEffect(() => {
    if (!match.params.schoolId) {
      return;
    }
    dispatch(schoolsActions.getSchoolById(match.params.schoolId));
  }, [match.params.schoolId]);

  const activeTabKey = !match.isExact
    ? (location.pathname.split('/').at(-1) as SchoolTabPath)
    : undefined;

  if (isNotFound) {
    return (
      <M.Container>
        <M.Title order={3} align="center" p="xl">
          School not found
        </M.Title>
      </M.Container>
    );
  }

  return (
    <MantineProvider>
      <SchoolTabsGuard
        isWizardVisible={isWizardVisible}
        school={schoolV1}
        activeTabKey={activeTabKey}
      >
        <Switch>
          {Object.entries(schoolTabsConfig)
            .filter(([_, { isAvailable }]) => isAvailable(schoolTabProps))
            .map(([key, { render }]) => (
              <Route key={`/${key}`} exact path={`${match.path}/${key}`}>
                <Header school={schoolV1} />
                <M.Tabs
                  value={activeTabKey}
                  onTabChange={(tabValue) =>
                    router.push({ pathname: `${match.url}/${tabValue}` })
                  }
                >
                  <M.Tabs.List>
                    {Object.entries(schoolTabsConfig).map(
                      ([key, { isAvailable, isDisabled }]) =>
                        isAvailable(schoolTabProps) && (
                          <Tab
                            key={key}
                            value={key}
                            disabled={isDisabled(schoolTabProps)}
                          >
                            {key}
                          </Tab>
                        ),
                    )}
                  </M.Tabs.List>
                </M.Tabs>
                {render(schoolTabProps)}
              </Route>
            ))}

          <Route path={`${match.path}/schedules/:mode/:id?`}>
            <ScheduleDetailPage />
          </Route>
        </Switch>
      </SchoolTabsGuard>
    </MantineProvider>
  );
};

const Tab = styled(M.Tabs.Tab)`
  .mantine-Tabs-tabLabel {
    padding: 0.125rem ${(p) => p.theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSizes.md};
    text-transform: capitalize;
  }
`;

export default School;
