import * as M from '@mantine/core';
import { getLength, parseTime } from 'features/editSchedule/utils';
import { LunchWave } from 'types';

interface Props {
  lunchWaves: LunchWave[];
}

const LunchReviewTable = ({ lunchWaves }: Props) => (
  <M.Flex direction="column">
    <M.Text align="center">
      <strong>Lunch waves</strong>
    </M.Text>
    <M.Table>
      <tbody>
        {lunchWaves.map(({ name, startTime, endTime }, i) => (
          <tr key={i}>
            <td>
              <strong>{name}</strong>
            </td>
            <td>{startTime}</td>
            <td>{endTime}</td>
            <td>
              ({getLength([parseTime(startTime), parseTime(endTime)])} min)
            </td>
          </tr>
        ))}
      </tbody>
    </M.Table>
  </M.Flex>
);

export default LunchReviewTable;
