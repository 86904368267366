import keyBy from 'lodash.keyby';
import { ActionTypes } from '../actions/misc';

const initialState = {
  isLoadingGeoJSON: false,
  geoJSONLoaded: false,
  geoJSONError: false,
  geoJSONData: null,
  emojis: {},
  emojiOrder: [],
  emojisError: null,
};

const getEmojisSuccessReducer = (state, action) => {
  const emojis = action.emojis
    .filter((e) => e.resources.png !== null)
    .map((e) => {
      // TODO this should be support unicode emojis as well
      return {
        name: e.name,
        short_names: ['saturn-' + e.name],
        imageUrl: e.resources.png.size_urls.large,
        // native: e.resources.unicode ? e.resources.unicode : null,
        customCategory: 'Saturn',
      };
    });
  const mapped = keyBy(emojis, 'name');
  const keys = emojis.map((e) => e.name);
  return {
    ...state,
    emojis: mapped,
    emojiOrder: keys,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_GEOJSON:
      return {
        ...state,
        isLoadingGeoJSON: true,
        geoJSONLoaded: false,
        geoJSONError: false,
      };
    case ActionTypes.GET_GEOJSON_SUCCESS:
      return {
        ...state,
        isLoadingGeoJSON: false,
        geoJSONData: action.features,
        stats: action.stats,
        geoJSONLoaded: true,
        geoJSONError: false,
      };
    case ActionTypes.GET_GEOJSON_FAIL:
      return {
        ...state,
        isLoadingGeoJSON: false,
        geoJSONLoaded: false,
        geoJSONError: action.error,
      };
    case ActionTypes.GET_EMOJIS:
      return {
        ...state,
      };
    case ActionTypes.GET_EMOJIS_SUCCESS:
      return getEmojisSuccessReducer(state, action);
    case ActionTypes.GET_EMOJIS_FAIL:
      return {
        ...state,
        emojisError: action.error,
      };
    default:
      return state;
  }
};
