import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MDZ from '@mantine/dropzone';
import * as MN from '@mantine/notifications';
import { IconPhoto } from '@tabler/icons-react';

import { useUploadScheduleImageMutation } from 'features/scheduleConfiguration/hooks/mutations';
import { useUploadedScheduleImages } from 'features/scheduleConfiguration/hooks/queries';

import ImageGallery from 'features/components/ImageGallery';

const REFETCH_INTERVAL_MS = 1500;

interface Props {
  schoolId: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageUploader = ({ schoolId, setIsLoading }: Props) => {
  const [uploadedImgCount, setUploadedImgCount] = useState(0);

  const { mutate: uploadScheduleImage, isLoading: isUploading } =
    useUploadScheduleImageMutation(schoolId, {
      onSuccess: () => {
        setUploadedImgCount((c) => c + 1);
      },
      onError: () => {
        MN.showNotification({
          message: 'Error uploading image',
          color: 'red',
        });
      },
    });

  const {
    data: images,
    isLoading: isParsing,
    error: imageLoadingError,
  } = useUploadedScheduleImages(schoolId, {
    // keep polling until all images are parsed
    refetchInterval: (data, { state }) => {
      return (data?.length ?? 0) < uploadedImgCount && !state.error
        ? REFETCH_INTERVAL_MS
        : false;
    },
  });

  const isUploadBeingProcessed =
    (images?.length ?? 0) < uploadedImgCount && !imageLoadingError;

  const isLoading = (isParsing || isUploadBeingProcessed) && !imageLoadingError;

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  // sync local images count with images count on server
  useEffect(() => {
    if (!images?.length) return;

    if (images.length > uploadedImgCount) {
      setUploadedImgCount(images.length);
    }
  }, [images, uploadedImgCount]);

  return (
    <Container>
      <MDZ.Dropzone
        onDrop={(files) => {
          if (files.length < 1) {
            return;
          }
          uploadScheduleImage(files[0]);
        }}
        accept={MDZ.IMAGE_MIME_TYPE}
        loading={isUploading}
      >
        <DropzoneContainer>
          <MDZ.Dropzone.Idle>
            <IconPhoto size={50} stroke={1.5} />
          </MDZ.Dropzone.Idle>

          <M.Text>Drag image here or click to select file</M.Text>
        </DropzoneContainer>
      </MDZ.Dropzone>

      {(isParsing || isUploadBeingProcessed) && (
        <M.Loader sx={{ alignSelf: 'center' }} />
      )}

      {!!images?.length && <ImageGallery images={images} />}

      {!isLoading && !images?.length && (
        <M.Text>No schedule images uploaded yet</M.Text>
      )}
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const DropzoneContainer = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
  align-items: center;
`;

export default ImageUploader;
