import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import {
  ContentActions,
  UserActions,
} from 'components/shared/ReportDetail/Widgets';
import moment from 'moment';

function actionsFormatter(cell, row) {
  return (
    <div className="report-actions">
      <OverlayTrigger
        placement={row.action_taken ? 'top' : 'left'}
        trigger={row.action_taken ? 'hover' : 'click'}
        rootClose
        overlay={
          row.action_taken ? (
            <Tooltip>Content was deleted</Tooltip>
          ) : (
            this.renderDetailPopover(
              row.report_type,
              row.context_report,
              row.comments,
            )
          )
        }
      >
        <FontAwesomeIcon
          className={`action-icon ${row.action_taken ? 'locked' : ''}`}
          icon={faEye}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        rootClose
        overlay={
          <Tooltip>{row.solved ? 'Re-open report' : 'Mark as solved'}</Tooltip>
        }
      >
        <FontAwesomeIcon
          className="action-icon"
          icon={faCheckCircle}
          color={row.solved ? 'green' : null}
          onClick={() => this.solveReport(row)}
        />
      </OverlayTrigger>
      <ContentActions
        content={row.context_report}
        type={row.report_type}
        action_taken={row.action_taken}
        onActionPerformed={() => {
          this.loadReports();
        }}
      />
      <UserActions
        user_id={
          row.report_type === 'chat_message'
            ? row.context_report.from_user
            : row.context_report.id
        }
        status={row.user_action_taken}
        type={row.report_type}
        onActionPerformed={() => this.loadReports()}
      />
    </div>
  );
}

export function getColumns() {
  this.formatters = {
    actionsFormatter: actionsFormatter.bind(this),
  };

  return [
    {
      dataField: 'solved',
      text: 'Status',
      textAlign: 'center',
      formatter: (cell) => {
        return (
          <div className="text-center">
            {cell ? (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            ) : (
              <FontAwesomeIcon icon={faExclamationCircle} color="orange" />
            )}
          </div>
        );
      },
      headerStyle: () => {
        return { width: '10%', textAlign: 'center' };
      },
    },
    {
      dataField: 'created_at',
      text: 'Reported on',
      formatter: (cell) => moment(cell).format('MM/DD/YYYY'),
      headerStyle: () => {
        return { width: '10%' };
      },
      sort: true,
    },
    {
      dataField: 'target_id',
      text: 'ID',
      formatter: (cell) => (
        <div className="trim-line" title={cell}>
          {cell}
        </div>
      ),
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'report_type',
      text: 'Report Type',
      sort: true,
      formatter: (cell, row) => {
        const { chat_type } = row.context_report;
        return (
          <span style={{ textTransform: 'capitalize' }}>
            {chat_type && chat_type === 'grade'
              ? 'Bulletin'
              : cell.replace('_', ' ')}
          </span>
        );
      },
      headerStyle: () => {
        return { width: '20%' };
      },
    },
    {
      dataField: 'report_count',
      text: 'Report Count',
      sort: true,
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: this.formatters.actionsFormatter,
      headerStyle: () => {
        return { width: '10%', textAlign: 'center' };
      },
    },
  ];
}
