import { useGetMutation } from 'hooks/utils';
import type { SchoolResponse, ScheduleType } from 'types';

export const useUpdateScheduleTypeMutation = (schoolId: string) =>
  useGetMutation<ScheduleType, SchoolResponse>({
    schoolId,
    method: 'put',
    expectedStatus: 200,
    getPath: () => `v2/schools/${schoolId}`,
    getBody: (scheduleType) => ({ scheduleType }),
    update: (school, responseData) => ({
      ...school,
      scheduleType: responseData.scheduleType as ScheduleType | undefined,
    }),
  });
