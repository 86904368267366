import React from 'react';
import StepWizard from 'react-step-wizard';

import { OptionsStep } from './Steps/OptionsStep';
import { FixedDatesStep } from './Steps/FixedDatesStep';
import { ReviewStep } from './Steps/ReviewStep';

const CalendarWizard = ({
  school,
  form,
  step,
  onStepChange,
  onUpdate,
  onClose,
  onSubmit,
}) => {
  return (
    <div className="container">
      <StepWizard
        onStepChange={onStepChange}
        transitions={{}}
        initialStep={step || 1}
      >
        <OptionsStep
          school={school}
          form={form}
          updateForm={onUpdate}
          onClose={onClose}
        />
        <FixedDatesStep form={form} updateForm={onUpdate} onClose={onClose} />
        <ReviewStep
          school={school}
          form={form}
          updateForm={onUpdate}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </StepWizard>
    </div>
  );
};

export default CalendarWizard;
