export default [{x:37.32235,y:-121.82505},
{x:33.72082,y:-116.39132},
{x:34.06926,y:-117.20916},
{x:33.71586,y:-117.8849},
{x:33.21936,y:-117.22576},
{x:36.83483,y:-119.87929},
{x:33.74725,y:-116.98011},
{x:32.56728,y:-116.96542},
{x:32.72716,y:-117.22752},
{x:36.6945,y:-121.596},
{x:41.50887,y:-72.80891},
{x:27.87045,y:-82.76155},
{x:27.62399,y:-80.38972},
{x:28.06931,y:-82.63325},
{x:33.96708,y:-84.01906},
{x:40.50658,y:-88.95513},
{x:41.56496,y:-87.63675},
{x:39.91123,y:-86.26189},
{x:41.4269,y:-87.36366},
{x:30.44311,y:-90.08438},
{x:43.66267,y:-84.24796},
{x:38.88909,y:-94.60739},
{x:35.08868,y:-80.8718},
{x:40.82471,y:-97.59668},
{x:43.45889,y:-71.56138},
{x:40.78715,y:-74.04626},
{x:40.01864,y:-74.94441},
{x:36.76206,y:-108.15493},
{x:36.29109,y:-115.28732},
{x:36.05682,y:-115.24227},
{x:40.71932,y:-73.98994},
{x:40.83935,y:-73.32387},
{x:40.86501,y:-73.13054},
{x:41.53071,y:-73.89339},
{x:40.76803,y:-81.52258},
{x:35.0722,y:-89.84294},
{x:32.5216,y:-97.34695},
{x:29.75559,y:-95.36653},
{x:40.72209,y:-111.54077},
{x:45.61714,y:-122.47688},
{x:42.6284,y:-88.61473},
{x:34.7417,y:-86.60142},
{x:28.93379,y:-81.93649},
{x:27.97819,y:-81.95757},
{x:27.89413,y:-82.50376},
{x:33.82564,y:-84.36557},
{x:32.54493,y:-84.95336},
{x:33.90024,y:-84.00605},
{x:40.41489,y:-86.83938},
{x:37.98136,y:-84.55125},
{x:42.50753,y:-83.04745},
{x:44.85506,y:-93.24333},
{x:40.5567,y:-75.48961},
{x:41.36394,y:-75.68071},
{x:41.23765,y:-75.85548},
{x:35.05067,y:-89.73306},
{x:29.50797,y:-95.09504},
{x:33.19843,y:-96.70686},
{x:38.80856,y:-77.0835},
{x:36.88168,y:-76.18715},
{x:37.1471,y:-76.45929},
{x:33.6384,y:-112.29025},
{x:35.39806,y:-118.94937},
{x:35.29562,y:-119.05498},
{x:33.832,y:-118.02977},
{x:47.60427,y:-122.33067},
{x:34.01998,y:-117.66721},
{x:38.42389,y:-121.41375},
{x:37.67911,y:-122.08335},
{x:36.30725,y:-119.78114},
{x:37.64554,y:-121.01849},
{x:34.29574,y:-118.84348},
{x:33.18424,y:-117.32789},
{x:34.55862,y:-118.04661},
{x:34.02842,y:-117.76077},
{x:37.32519,y:-121.81384},
{x:39.65998,y:-104.85022},
{x:41.72198,y:-88.03968},
{x:41.90553,y:-88.01017},
{x:35.09049,y:-106.64297},
{x:35.94161,y:-115.11662},
{x:29.65159,y:-95.56711},
{x:44.95962,y:-92.72044},
{x:32.71813,y:-117.13403},
{x:61.32546,y:-149.57078},
{x:33.51824,y:-112.06463},
{x:33.86773,y:-117.74412},
{x:35.5262,y:-120.70271},
{x:33.81644,y:-118.02927},
{x:34.04905,y:-117.43525},
{x:36.62776,y:-119.68332},
{x:36.79316,y:-119.87245},
{x:33.76714,y:-117.97272},
{x:36.95316,y:-120.0829},
{x:33.90265,y:-118.05499},
{x:38.57532,y:-121.38259},
{x:34.44501,y:-118.50729},
{x:36.31922,y:-119.3142},
{x:36.29806,y:-119.33465},
{x:39.98563,y:-104.82312},
{x:26.55626,y:-81.89705},
{x:26.61739,y:-80.05763},
{x:39.70017,y:-86.08199},
{x:42.359,y:-71.18531},
{x:44.93982,y:-93.14481},
{x:35.79866,y:-78.79508},
{x:35.03902,y:-78.93507},
{x:36.06713,y:-115.04222},
{x:38.96128,y:-119.77977},
{x:45.48608,y:-122.77273},
{x:44.92994,y:-123.30992},
{x:35.96009,y:-86.80275},
{x:35.95475,y:-83.93752},
{x:30.08197,y:-95.631},
{x:47.64384,y:-117.55622},
{x:45.62002,y:-122.54793},
{x:33.10144,y:-117.31112},
{x:32.71136,y:-117.15536},
{x:34.37204,y:-118.51632},
{x:37.90555,y:-121.22205},
{x:34.92341,y:-120.42694},
{x:33.78304,y:-116.35645},
{x:38.62626,y:-121.32764},
{x:33.51355,y:-117.10245},
{x:41.56894,y:-73.41481},
{x:28.53424,y:-82.50799},
{x:33.52585,y:-82.12007},
{x:32.87338,y:-83.70832},
{x:41.87956,y:-87.62786},
{x:41.62801,y:-85.90976},
{x:30.43013,y:-91.11648},
{x:42.0657,y:-72.51394},
{x:44.94817,y:-93.33298},
{x:39.55712,y:-119.78264},
{x:41.50816,y:-81.60587},
{x:40.07507,y:-76.32295},
{x:35.16217,y:-84.88265},
{x:32.72209,y:-97.11517},
{x:42.87206,y:-87.94293},
{x:43.14826,y:-88.00226},
{x:37.80449,y:-122.43375},
{x:37.8245,y:-121.28765},
{x:33.70004,y:-116.21551},
{x:34.13717,y:-117.37108},
{x:38.71016,y:-121.08486},
{x:33.89567,y:-117.47034},
{x:37.66453,y:-122.44549},
{x:34.0354,y:-117.91191},
{x:38.8004,y:-121.29417},
{x:39.98737,y:-105.09687},
{x:38.90512,y:-77.05182},
{x:28.62565,y:-81.31679},
{x:26.5976,y:-81.86329},
{x:27.89288,y:-82.3166},
{x:34.25629,y:-83.46421},
{x:34.08166,y:-84.35793},
{x:42.09181,y:-88.33611},
{x:39.08524,y:-77.15122},
{x:35.03483,y:-80.8057},
{x:36.12859,y:-115.31456},
{x:41.21749,y:-80.74126},
{x:40.14515,y:-82.9634},
{x:32.20397,y:-98.23991},
{x:43.07427,y:-88.46789},
{x:34.78404,y:-86.94581},
{x:30.66717,y:-87.85153},
{x:37.79306,y:-122.39976},
{x:37.94369,y:-122.05698},
{x:34.0482,y:-117.30534},
{x:37.34136,y:-121.91156},
{x:33.58136,y:-112.20239},
{x:32.83387,y:-116.74968},
{x:39.5546,y:-104.88397},
{x:41.14143,y:-73.26225},
{x:33.46376,y:-84.21871},
{x:43.66249,y:-116.35282},
{x:41.81576,y:-87.83238},
{x:32.44243,y:-93.71721},
{x:41.15817,y:-95.93471},
{x:41.11574,y:-100.76504},
{x:40.7637,y:-73.9773},
{x:40.7046,y:-74.00912},
{x:40.89457,y:-73.33653},
{x:40.74853,y:-73.04796},
{x:42.1363,y:-75.90444},
{x:39.87912,y:-83.04119},
{x:40.1076,y:-83.01585},
{x:45.45019,y:-122.78186},
{x:45.52944,y:-122.43327},
{x:39.94072,y:-77.63094},
{x:40.30609,y:-79.58293},
{x:30.64354,y:-96.35287},
{x:29.96654,y:-95.69134},
{x:29.75054,y:-95.37465},
{x:32.90811,y:-96.57568},
{x:44.46884,y:-73.17804},
{x:44.17791,y:-88.48274},
{x:44.07451,y:-87.70363},
{x:33.90302,y:-118.25505},
{x:37.27559,y:-121.92266},
{x:37.42183,y:-122.09639},
{x:37.31088,y:-121.80966},
{x:33.18183,y:-117.29486},
{x:39.5673,y:-105.08034},
{x:39.74207,y:-105.49759},
{x:39.20003,y:-85.95082},
{x:42.86032,y:-85.72266},
{x:38.9481,y:-92.3274},
{x:35.13685,y:-80.9042},
{x:43.09231,y:-70.79232},
{x:40.86383,y:-73.32113},
{x:45.32411,y:-122.57943},
{x:40.32171,y:-75.13093},
{x:32.75763,y:-96.99452},
{x:37.65578,y:-113.08442},
{x:38.32901,y:-122.69752},
{x:33.89834,y:-118.00671},
{x:37.2303,y:-121.97974},
{x:33.94436,y:-117.22603},
{x:34.06323,y:-117.28465},
{x:37.7029,y:-122.12594},
{x:38.5726,y:-121.57978},
{x:39.70737,y:-104.98737},
{x:26.40825,y:-80.20041},
{x:27.9372,y:-82.26347},
{x:29.66731,y:-82.33967},
{x:25.68478,y:-80.41523},
{x:26.24431,y:-81.77225},
{x:26.21119,y:-81.74981},
{x:28.59708,y:-81.28897},
{x:41.95765,y:-91.71805},
{x:42.03438,y:-91.67999},
{x:41.88292,y:-87.63262},
{x:42.30502,y:-88.97866},
{x:42.24028,y:-87.97104},
{x:41.86082,y:-87.98169},
{x:40.04724,y:-86.02604},
{x:37.99777,y:-84.521},
{x:32.53857,y:-92.65262},
{x:42.18097,y:-70.90172},
{x:42.14006,y:-71.21931},
{x:42.9133,y:-85.58409},
{x:38.7809,y:-90.69897},
{x:38.59067,y:-90.30506},
{x:35.15779,y:-79.41946},
{x:35.33361,y:-80.81168},
{x:35.10063,y:-80.99199},
{x:35.66649,y:-78.49774},
{x:35.36667,y:-80.70891},
{x:40.83143,y:-74.08904},
{x:36.07736,y:-115.29799},
{x:42.78364,y:-73.91361},
{x:40.58161,y:-74.16643},
{x:47.61715,y:-122.32979},
{x:35.63873,y:-97.46106},
{x:45.33342,y:-118.06801},
{x:45.65927,y:-118.80891},
{x:40.13982,y:-79.84359},
{x:40.27803,y:-78.8464},
{x:40.13804,y:-74.96269},
{x:39.94877,y:-75.16038},
{x:29.88608,y:-95.06362},
{x:29.27081,y:-94.82799},
{x:29.89143,y:-95.58482},
{x:27.88344,y:-97.31879},
{x:37.12658,y:-113.60196},
{x:39.18612,y:-78.13138},
{x:44.49695,y:-89.51308},
{x:36.33469,y:-94.18044},
{x:33.66032,y:-117.66868},
{x:34.50686,y:-117.39971},
{x:34.42722,y:-119.28834},
{x:33.59324,y:-117.24775},
{x:33.848,y:-118.02805},
{x:34.16523,y:-117.27906},
{x:39.78534,y:-104.89503},
{x:39.82874,y:-105.05384},
{x:39.76416,y:-104.77461},
{x:26.6437,y:-81.87078},
{x:25.09484,y:-80.44081},
{x:26.5457,y:-81.86864},
{x:27.38952,y:-82.51684},
{x:34.09498,y:-84.17441},
{x:41.59719,y:-93.71886},
{x:40.14824,y:-88.25723},
{x:39.89095,y:-88.95757},
{x:41.6321,y:-87.85357},
{x:41.52167,y:-88.14604},
{x:42.0798,y:-87.76264},
{x:42.07749,y:-87.71025},
{x:40.919,y:-98.38216},
{x:39.01614,y:-74.87407},
{x:40.95047,y:-73.82834},
{x:45.50202,y:-122.57834},
{x:45.45893,y:-122.78934},
{x:46.16073,y:-123.90036},
{x:34.18936,y:-79.80555},
{x:35.08068,y:-81.70509},
{x:29.43638,y:-98.6496},
{x:29.59766,y:-98.28264},
{x:29.56036,y:-98.67961},
{x:40.65104,y:-111.93932},
{x:36.63196,y:-82.12316},
{x:47.35775,y:-122.16587},
{x:46.22738,y:-119.26782},
{x:47.45303,y:-120.33392},
{x:47.11745,y:-118.36755},
{x:43.71724,y:-87.75453},
{x:33.92395,y:-84.34135},
{x:36.12273,y:-94.14584},
{x:34.75272,y:-92.34059},
{x:36.28369,y:-94.15343},
{x:33.83521,y:-117.91403},
{x:33.12749,y:-117.0937},
{x:34.47033,y:-117.29255},
{x:37.28874,y:-120.45417},
{x:33.80292,y:-116.9717},
{x:37.3731,y:-121.87294},
{x:39.76547,y:-104.99454},
{x:40.40706,y:-105.09881},
{x:41.8133,y:-72.52661},
{x:30.42944,y:-81.66282},
{x:30.47999,y:-81.63818},
{x:30.41741,y:-86.65399},
{x:41.92794,y:-87.68215},
{x:42.34522,y:-88.06641},
{x:38.15182,y:-85.83595},
{x:45.54951,y:-94.21752},
{x:36.28786,y:-115.17856},
{x:42.64633,y:-73.69734},
{x:40.84906,y:-73.63491},
{x:41.96268,y:-73.98899},
{x:39.77669,y:-84.07021},
{x:35.46364,y:-97.50992},
{x:36.112,y:-95.97561},
{x:40.45904,y:-79.9276},
{x:40.01791,y:-79.07846},
{x:34.8694,y:-82.35654},
{x:34.84566,y:-82.40187},
{x:34.85994,y:-82.26461},
{x:43.49934,y:-96.74702},
{x:29.77657,y:-95.41087},
{x:47.27231,y:-122.2293},
{x:47.846,y:-122.29418},
{x:47.87812,y:-122.16616},
{x:47.23405,y:-122.50176},
{x:44.03968,y:-88.54084},
{x:30.66158,y:-88.19028},
{x:30.70343,y:-88.12513},
{x:33.39402,y:-111.75418},
{x:33.99488,y:-117.90704},
{x:37.55633,y:-121.95235},
{x:37.25366,y:-121.95256},
{x:38.65643,y:-121.38407},
{x:37.96808,y:-122.52182},
{x:38.4998,y:-122.75008},
{x:38.39462,y:-122.81854},
{x:25.79664,y:-80.32482},
{x:34.05014,y:-84.59722},
{x:41.54419,y:-93.64484},
{x:41.37134,y:-89.12506},
{x:38.25304,y:-85.66061},
{x:42.27019,y:-84.42494},
{x:42.33777,y:-83.40092},
{x:38.53653,y:-90.49653},
{x:35.87933,y:-78.84729},
{x:36.144,y:-115.15427},
{x:36.07481,y:-95.92369},
{x:35.45737,y:-86.04914},
{x:32.79387,y:-96.77725},
{x:29.59498,y:-98.61525},
{x:30.02046,y:-95.52683},
{x:40.19132,y:-122.23693},
{x:34.05207,y:-118.26327},
{x:39.10043,y:-121.61621},
{x:30.41756,y:-86.60778},
{x:29.65362,y:-82.49365},
{x:26.6731,y:-80.24826},
{x:40.54305,y:-89.59916},
{x:41.69083,y:-88.12636},
{x:41.88079,y:-87.63214},
{x:38.1879,y:-85.51925},
{x:29.81157,y:-90.8154},
{x:42.48962,y:-71.27235},
{x:42.35317,y:-71.05761},
{x:38.76812,y:-76.888},
{x:44.75064,y:-93.37897},
{x:36.11188,y:-79.87931},
{x:36.17359,y:-115.11668},
{x:43.9766,y:-75.94653},
{x:44.69674,y:-73.4834},
{x:36.39147,y:-97.90764},
{x:39.97572,y:-75.44211},
{x:44.88943,y:-97.06852},
{x:36.13858,y:-85.50567},
{x:30.53205,y:-97.61603},
{x:30.16561,y:-97.78819},
{x:29.80518,y:-94.98295},
{x:38.29214,y:-77.56968},
{x:38.72877,y:-77.79611},
{x:45.65472,y:-122.58863},
{x:44.47075,y:-88.07548},
{x:39.77842,y:-104.84748},
{x:33.90174,y:-118.1256},
{x:34.011,y:-117.73597},
{x:39.57997,y:-104.72443},
{x:41.83087,y:-87.62674},
{x:41.65428,y:-87.99915},
{x:40.11294,y:-88.20462},
{x:37.93884,y:-84.54861},
{x:37.7353,y:-84.32549},
{x:39.17615,y:-76.83919},
{x:40.85526,y:-74.04341},
{x:39.99252,y:-83.02536},
{x:41.15045,y:-81.86381},
{x:36.37864,y:-86.46668},
{x:29.77839,y:-98.73748},
{x:32.81825,y:-96.87084},
{x:32.88306,y:-97.38418},
{x:29.67795,y:-95.64308},
{x:29.94778,y:-93.99316},
{x:29.6493,y:-95.16393},
{x:30.17765,y:-95.53538},
{x:47.70023,y:-122.02456},
{x:34.79754,y:-92.41542},
{x:33.96109,y:-118.11073},
{x:36.80829,y:-119.77137},
{x:37.71183,y:-120.95789},
{x:37.41575,y:-122.07769},
{x:33.84507,y:-117.22421},
{x:33.98286,y:-117.33961},
{x:37.73187,y:-122.13968},
{x:36.98682,y:-121.96456},
{x:38.19177,y:-120.82483},
{x:34.52111,y:-117.32565},
{x:39.66426,y:-104.98722},
{x:39.09575,y:-108.60882},
{x:40.19061,y:-105.10259},
{x:26.1079,y:-80.13659},
{x:33.42193,y:-84.18659},
{x:42.27979,y:-88.04252},
{x:41.61968,y:-87.84803},
{x:42.02015,y:-88.20232},
{x:41.91496,y:-88.20958},
{x:38.04619,y:-84.55452},
{x:35.40649,y:-80.86381},
{x:40.8138,y:-96.67238},
{x:35.54749,y:-97.56502},
{x:44.30227,y:-120.8272},
{x:40.22131,y:-75.24897},
{x:35.79093,y:-86.40041},
{x:32.7674,y:-97.09772},
{x:32.72166,y:-97.14964},
{x:30.61884,y:-96.34514},
{x:29.67972,y:-95.42646},
{x:40.92062,y:-111.88716},
{x:37.07973,y:-76.4609},
{x:38.64338,y:-77.29533},
{x:47.53025,y:-121.87168},
{x:32.28681,y:-110.96069},
{x:34.85069,y:-117.08496},
{x:38.1118,y:-122.56822},
{x:33.80741,y:-117.86994},
{x:36.06483,y:-119.01596},
{x:37.50294,y:-122.25718},
{x:38.03732,y:-121.26075},
{x:39.80217,y:-105.10935},
{x:39.76731,y:-105.23435},
{x:40.42498,y:-105.07809},
{x:39.94466,y:-104.99247},
{x:38.90181,y:-77.02559},
{x:26.13818,y:-81.74933},
{x:29.55647,y:-81.20961},
{x:27.99061,y:-82.68902},
{x:32.61791,y:-83.67373},
{x:43.6501,y:-116.28012},
{x:41.59699,y:-88.02252},
{x:41.71087,y:-88.20691},
{x:38.86126,y:-86.51465},
{x:41.0754,y:-85.14563},
{x:39.7711,y:-86.07001},
{x:38.88445,y:-94.64491},
{x:37.07825,y:-88.68386},
{x:42.26226,y:-85.17944},
{x:45.15023,y:-93.27619},
{x:38.55701,y:-90.38116},
{x:32.29091,y:-106.78446},
{x:35.99675,y:-115.12774},
{x:43.03214,y:-78.6972},
{x:40.75932,y:-73.95289},
{x:35.31927,y:-97.49602},
{x:35.43564,y:-97.39985},
{x:33.83335,y:-78.67437},
{x:30.22044,y:-97.75741},
{x:41.13961,y:-112.06138},
{x:47.72813,y:-121.98629},
{x:47.81039,y:-122.37739},
{x:47.66578,y:-122.16437},
{x:47.19998,y:-122.2971},
{x:44.53374,y:-89.57443},
{x:44.77338,y:-106.94309},
{x:39.7764,y:-105.02471},
{x:32.33805,y:-86.18225},
{x:32.38307,y:-86.18405},
{x:33.83286,y:-117.92844},
{x:37.82555,y:-121.2363},
{x:37.77134,y:-120.83539},
{x:42.10664,y:-87.80372},
{x:41.84552,y:-87.97738},
{x:39.19563,y:-76.6126},
{x:32.35646,y:-90.15003},
{x:40.13267,y:-74.72403},
{x:40.74913,y:-73.98382},
{x:41.37397,y:-83.62451},
{x:39.31358,y:-84.31405},
{x:39.61257,y:-84.2268},
{x:35.15749,y:-89.72809},
{x:29.72962,y:-95.43802},
{x:47.04783,y:-122.9423},
{x:33.21492,y:-86.82601},
{x:34.56348,y:-112.48778},
{x:33.5824,y:-111.928},
{x:34.19502,y:-118.35018},
{x:34.19864,y:-118.32709},
{x:39.74657,y:-121.85155},
{x:32.66114,y:-116.97066},
{x:33.69046,y:-117.34205},
{x:33.80333,y:-118.07075},
{x:32.77981,y:-117.1028},
{x:33.73735,y:-117.91964},
{x:37.96141,y:-121.26979},
{x:33.83311,y:-118.34786},
{x:29.70438,y:-82.38936},
{x:30.62823,y:-81.55362},
{x:41.81482,y:-88.28041},
{x:41.93174,y:-88.77219},
{x:39.14549,y:-86.53171},
{x:39.00926,y:-94.83993},
{x:30.37526,y:-90.08776},
{x:39.05125,y:-94.58603},
{x:38.7086,y:-93.26464},
{x:41.01545,y:-73.94619},
{x:39.57621,y:-119.74026},
{x:40.75944,y:-73.96997},
{x:39.85543,y:-82.82749},
{x:40.2367,y:-83.35037},
{x:39.93841,y:-76.69217},
{x:36.5093,y:-87.27456},
{x:31.6222,y:-94.65283},
{x:37.75863,y:-77.46972},
{x:46.21958,y:-119.79069},
{x:41.57835,y:-109.25176},
{x:33.13083,y:-117.26054},
{x:34.49539,y:-118.62199},
{x:39.69421,y:-104.79046},
{x:39.9999,y:-105.26312},
{x:39.72062,y:-104.90319},
{x:39.71378,y:-105.13294},
{x:39.59396,y:-104.92858},
{x:39.92845,y:-105.14772},
{x:40.60909,y:-79.17826},
{x:34.9652,y:-81.93145},
{x:31.83942,y:-106.57246},
{x:29.81237,y:-95.40555},
{x:40.50848,y:-111.9797},
{x:38.48589,y:-77.9687},
{x:33.98343,y:-117.71396},
{x:37.33717,y:-122.0667},
{x:37.66593,y:-122.11856},
{x:33.98732,y:-117.37275},
{x:32.81143,y:-117.20062},
{x:37.78413,y:-122.40736},
{x:37.80534,y:-122.41511},
{x:33.77371,y:-117.00627},
{x:37.29276,y:-121.91323},
{x:34.16857,y:-118.61594},
{x:39.08718,y:-108.46},
{x:38.90227,y:-104.76868},
{x:38.8762,y:-77.0036},
{x:39.45009,y:-75.69902},
{x:25.73011,y:-80.43206},
{x:33.80389,y:-84.41291},
{x:41.85162,y:-87.85288},
{x:41.7064,y:-87.67041},
{x:42.00496,y:-87.88423},
{x:40.73228,y:-89.57369},
{x:39.52065,y:-119.74072},
{x:39.98526,y:-75.39496},
{x:32.88038,y:-97.09805},
{x:32.74443,y:-96.44843},
{x:33.12638,y:-96.73393},
{x:28.86174,y:-96.99731},
{x:37.24947,y:-77.3895},
{x:36.74872,y:-76.58237},
{x:43.1371,y:-88.24264},
{x:33.48045,y:-112.11168},
{x:33.42263,y:-112.27194},
{x:35.3536,y:-118.91459},
{x:39.36574,y:-121.68822},
{x:32.5847,y:-117.06121},
{x:32.76472,y:-117.02579},
{x:34.04152,y:-118.26231},
{x:41.71249,y:-72.60688},
{x:41.61417,y:-93.77057},
{x:40.45999,y:-88.97149},
{x:41.88511,y:-87.6241},
{x:30.28403,y:-89.74853},
{x:42.30059,y:-71.29166},
{x:38.97984,y:-76.52407},
{x:39.15984,y:-77.27941},
{x:46.54881,y:-87.45629},
{x:35.7628,y:-78.78269},
{x:32.347,y:-106.76513},
{x:39.25249,y:-84.59879},
{x:40.06286,y:-83.04483},
{x:35.38017,y:-96.92934},
{x:40.27697,y:-76.82407},
{x:39.91046,y:-76.68113},
{x:33.17782,y:-96.88456},
{x:29.56676,y:-98.48527},
{x:41.76177,y:-111.83384},
{x:37.44229,y:-77.58375},
{x:37.5565,y:-77.47085},
{x:47.45318,y:-122.82408},
{x:47.76249,y:-122.34447},
{x:34.60527,y:-92.53294},
{x:43.78396,y:-88.47896},
{x:43.87496,y:-91.18994},
{x:37.79886,y:-120.99755},
{x:33.66843,y:-117.76347},
{x:33.94536,y:-117.45436},
{x:33.9111,y:-117.17459},
{x:34.07243,y:-117.88185},
{x:39.65342,y:-104.87292},
{x:28.68806,y:-81.39249},
{x:28.30488,y:-81.44097},
{x:30.47493,y:-87.2072},
{x:34.0445,y:-84.69564},
{x:33.84426,y:-84.48982},
{x:41.55724,y:-90.55794},
{x:41.88595,y:-87.64301},
{x:38.81838,y:-90.87833},
{x:36.00223,y:-79.7931},
{x:36.10828,y:-80.09789},
{x:35.24123,y:-106.6992},
{x:42.68946,y:-73.85047},
{x:44.28836,y:-73.98544},
{x:39.99312,y:-82.03102},
{x:40.0691,y:-75.00778},
{x:35.01107,y:-85.21494},
{x:32.88948,y:-97.15048},
{x:32.90973,y:-97.18867},
{x:37.35437,y:-77.41253},
{x:36.59464,y:-79.42456},
{x:37.29689,y:-80.02752},
{x:41.13795,y:-104.81697},
{x:33.44494,y:-86.82682},
{x:33.83837,y:-117.95865},
{x:37.36106,y:-120.59387},
{x:37.34401,y:-120.61568},
{x:33.66243,y:-117.89713},
{x:36.20356,y:-121.13696},
{x:38.87299,y:-121.30322},
{x:33.74429,y:-117.1914},
{x:32.8017,y:-117.15712},
{x:36.32645,y:-119.32932},
{x:39.76919,y:-105.04579},
{x:39.52608,y:-104.94215},
{x:38.26607,y:-104.62749},
{x:28.18808,y:-82.54576},
{x:27.63961,y:-80.45687},
{x:33.49005,y:-82.07927},
{x:34.03513,y:-84.57096},
{x:47.74367,y:-116.79331},
{x:42.90638,y:-112.46598},
{x:41.92505,y:-87.66034},
{x:42.16825,y:-72.64098},
{x:39.35638,y:-76.63445},
{x:39.0217,y:-76.97661},
{x:48.23704,y:-114.3313},
{x:34.18538,y:-97.16569},
{x:36.2891,y:-95.84171},
{x:35.48482,y:-97.75941},
{x:40.57045,y:-79.80258},
{x:33.18801,y:-97.10683},
{x:29.93603,y:-95.24988},
{x:38.96466,y:-78.18933},
{x:37.25489,y:-79.95516},
{x:38.77909,y:-77.18998},
{x:47.18011,y:-122.54729},
{x:48.1003,y:-122.18093},
{x:47.54588,y:-122.2851},
{x:46.99942,y:-122.90818},
{x:37.76822,y:-122.23245},
{x:33.81772,y:-117.91467},
{x:33.65218,y:-117.74541},
{x:40.58968,y:-122.3523},
{x:37.58833,y:-122.06645},
{x:40.40665,y:-104.98143},
{x:33.94006,y:-83.46446},
{x:34.002,y:-84.08238},
{x:42.46021,y:-92.3266},
{x:41.74651,y:-88.20093},
{x:38.23016,y:-84.53453},
{x:38.20442,y:-85.76371},
{x:42.8072,y:-71.10207},
{x:39.09101,y:-77.04658},
{x:38.56866,y:-90.47504},
{x:35.81609,y:-78.69375},
{x:35.10213,y:-106.56838},
{x:33.33279,y:-105.67778},
{x:39.42325,y:-119.73815},
{x:42.6784,y:-73.83035},
{x:36.06134,y:-95.8144},
{x:35.21805,y:-97.45543},
{x:47.60957,y:-122.32419},
{x:45.50951,y:-122.73493},
{x:44.08196,y:-123.04197},
{x:32.86739,y:-80.01697},
{x:35.99913,y:-86.69222},
{x:36.18771,y:-86.79783},
{x:30.02097,y:-95.38116},
{x:30.56354,y:-98.27419},
{x:29.43683,y:-98.71014},
{x:38.91838,y:-77.22038},
{x:37.28963,y:-80.08222},
{x:36.85255,y:-76.01737},
{x:47.88443,y:-122.28162},
{x:35.75115,y:-81.33583},
{x:34.88589,y:-92.10895},
{x:32.87902,y:-111.72323},
{x:35.2325,y:-114.03761},
{x:33.63811,y:-112.36953},
{x:34.07711,y:-118.13411},
{x:39.0421,y:-121.09356},
{x:33.97464,y:-117.83767},
{x:34.10696,y:-117.45184},
{x:33.77426,y:-117.90264},
{x:33.68781,y:-117.98845},
{x:34.04876,y:-118.25504},
{x:38.32068,y:-122.31065},
{x:33.94572,y:-117.41628},
{x:38.78415,y:-121.2714},
{x:38.60406,y:-121.40175},
{x:37.77019,y:-122.40346},
{x:37.49308,y:-120.87429},
{x:36.29219,y:-119.31429},
{x:37.87779,y:-122.07292},
{x:39.71167,y:-104.86271},
{x:27.44352,y:-82.46407},
{x:28.5461,y:-81.70874},
{x:30.37741,y:-86.34904},
{x:27.8938,y:-82.76116},
{x:26.84848,y:-80.08497},
{x:28.02554,y:-82.15118},
{x:26.68144,y:-80.22001},
{x:27.81746,y:-82.63884},
{x:33.91655,y:-83.44401},
{x:41.56198,y:-93.79574},
{x:48.30953,y:-116.54663},
{x:41.79721,y:-88.20413},
{x:43.72447,y:-70.23292},
{x:36.07082,y:-80.33913},
{x:40.58299,y:-74.61494},
{x:39.80283,y:-75.04194},
{x:40.99208,y:-74.03164},
{x:39.18976,y:-119.75812},
{x:42.65229,y:-73.77619},
{x:40.77864,y:-73.95603},
{x:39.29815,y:-84.52247},
{x:40.58153,y:-83.08209},
{x:40.84632,y:-81.94505},
{x:35.6094,y:-97.46143},
{x:36.0033,y:-95.88597},
{x:40.02844,y:-75.62214},
{x:40.29397,y:-76.64795},
{x:34.1334,y:-80.87884},
{x:35.81279,y:-83.98244},
{x:36.19383,y:-86.61691},
{x:32.90926,y:-96.8718},
{x:29.9801,y:-95.21718},
{x:30.22089,y:-95.56712},
{x:32.48295,y:-97.00889},
{x:30.5165,y:-97.68924},
{x:29.60959,y:-98.46616},
{x:29.61216,y:-98.4929},
{x:33.06672,y:-96.8902},
{x:40.72522,y:-111.88781},
{x:39.02302,y:-77.47744},
{x:39.03893,y:-77.41287},
{x:46.41985,y:-117.04192},
{x:47.41283,y:-122.25012},
{x:44.91346,y:-89.64985},
{x:44.28208,y:-105.49349},
{x:30.68069,y:-88.07338},
{x:33.65578,y:-112.37409},
{x:37.65076,y:-121.80446},
{x:38.13769,y:-121.28808},
{x:38.72978,y:-121.27239},
{x:38.66767,y:-121.33717},
{x:38.41244,y:-122.71292},
{x:41.64988,y:-72.72929},
{x:26.00687,y:-80.35577},
{x:30.40223,y:-84.23678},
{x:33.46757,y:-82.07743},
{x:42.02304,y:-93.61706},
{x:41.72348,y:-86.25101},
{x:41.43797,y:-87.46908},
{x:32.55946,y:-93.71338},
{x:42.54836,y:-83.1674},
{x:46.83227,y:-100.81165},
{x:40.46141,y:-74.45862},
{x:40.8343,y:-115.78638},
{x:41.09881,y:-74.01472},
{x:40.74343,y:-73.91833},
{x:39.92561,y:-83.1186},
{x:41.6169,y:-83.66515},
{x:40.63875,y:-75.43535},
{x:34.89035,y:-82.40039},
{x:29.75578,y:-95.62459},
{x:29.5604,y:-95.27513},
{x:39.08143,y:-77.49768},
{x:47.67585,y:-122.20651},
{x:28.66373,y:-81.38049},
{x:28.31765,y:-81.5413},
{x:28.08136,y:-80.65024},
{x:47.61015,y:-122.33307},
{x:28.55159,y:-81.34981},
{x:28.48582,y:-81.43141},
{x:39.98029,y:-74.1779},
{x:43.06995,y:-76.17305},
{x:61.18025,y:-149.88351},
{x:34.75364,y:-86.74018},
{x:34.74006,y:-92.27647},
{x:33.70765,y:-112.27323},
{x:33.48005,y:-112.0133},
{x:35.38323,y:-119.14888},
{x:37.29465,y:-121.96282},
{x:34.23436,y:-118.25596},
{x:37.7877,y:-122.251},
{x:33.69731,y:-117.74078},
{x:33.65312,y:-117.75041},
{x:33.88948,y:-118.14409},
{x:36.06556,y:-119.03835},
{x:33.88289,y:-117.36955},
{x:34.1024,y:-117.29677},
{x:34.47086,y:-117.34003},
{x:37.52311,y:-120.88114},
{x:34.50672,y:-117.32803},
{x:39.77341,y:-104.94017},
{x:39.65363,y:-104.91568},
{x:39.63884,y:-104.98744},
{x:39.0835,y:-108.58829},
{x:39.94412,y:-104.94093},
{x:30.63995,y:-81.45363},
{x:26.61568,y:-81.79657},
{x:28.16437,y:-80.67244},
{x:25.65406,y:-80.41527},
{x:34.26131,y:-85.16962},
{x:34.05131,y:-84.09081},
{x:42.21042,y:-88.2857},
{x:42.3363,y:-88.02998},
{x:42.22481,y:-87.87379},
{x:42.30555,y:-87.95781},
{x:41.78622,y:-88.14416},
{x:42.00147,y:-88.12555},
{x:42.041,y:-87.73097},
{x:41.88856,y:-88.10765},
{x:39.85812,y:-86.39192},
{x:41.11854,y:-85.13827},
{x:40.48082,y:-85.55065},
{x:39.47131,y:-87.37851},
{x:40.02081,y:-86.16393},
{x:38.99182,y:-84.65221},
{x:38.22664,y:-85.57717},
{x:32.45717,y:-93.74692},
{x:30.52729,y:-90.95661},
{x:39.32736,y:-76.61575},
{x:42.73095,y:-84.4673},
{x:42.50491,y:-83.11198},
{x:36.64411,y:-93.21574},
{x:38.94759,y:-92.30076},
{x:39.04552,y:-94.44019},
{x:41.13789,y:-96.02313},
{x:42.80174,y:-71.53758},
{x:40.05542,y:-74.16432},
{x:40.62637,y:-73.73607},
{x:43.2098,y:-77.46034},
{x:40.07101,y:-80.86581},
{x:45.5729,y:-122.55786},
{x:45.56466,y:-122.53381},
{x:40.65653,y:-75.41815},
{x:39.77048,y:-76.96019},
{x:40.56023,y:-75.411},
{x:41.24124,y:-76.99657},
{x:34.82449,y:-82.39372},
{x:34.21113,y:-82.17885},
{x:33.50375,y:-81.95962},
{x:35.15183,y:-89.79461},
{x:30.41888,y:-97.70271},
{x:33.09499,y:-96.11457},
{x:29.84515,y:-95.64613},
{x:29.73525,y:-95.64043},
{x:33.00105,y:-96.64525},
{x:29.43708,y:-98.49069},
{x:30.0737,y:-95.50824},
{x:29.58513,y:-95.63084},
{x:41.16173,y:-111.93996},
{x:36.68693,y:-76.22636},
{x:38.67795,y:-77.36301},
{x:38.43038,y:-78.87633},
{x:38.13381,y:-79.04125},
{x:33.56985,y:-116.09105},
{x:33.59963,y:-117.17575},
{x:33.90016,y:-117.55859},
{x:33.14249,y:-117.19152},
{x:37.97573,y:-120.34285},
{x:39.1324,y:-123.19912},
{x:34.15708,y:-118.6056},
{x:26.36567,y:-80.13325},
{x:26.60765,y:-82.02455},
{x:26.03382,y:-80.17581},
{x:26.61896,y:-80.14787},
{x:26.70059,y:-80.03534},
{x:30.47421,y:-87.2158},
{x:30.01388,y:-81.39223},
{x:34.65559,y:-84.49259},
{x:40.78385,y:-89.60311},
{x:41.75918,y:-87.94424},
{x:38.85521,y:-94.76237},
{x:38.96827,y:-94.66693},
{x:30.34936,y:-91.02695},
{x:42.15287,y:-70.84664},
{x:38.37341,y:-76.44114},
{x:42.7184,y:-83.41676},
{x:35.20591,y:-80.83884},
{x:36.18792,y:-80.27681},
{x:40.77841,y:-74.69736},
{x:32.28415,y:-106.75668},
{x:40.82338,y:-73.94261},
{x:39.25375,y:-84.42618},
{x:35.92597,y:-84.04231},
{x:36.04986,y:-83.99987},
{x:29.74329,y:-95.77604},
{x:36.71222,y:-76.27632},
{x:37.22598,y:-79.96924},
{x:48.15129,y:-122.19323},
{x:47.29468,y:-122.21365},
{x:47.09608,y:-122.35465},
{x:47.44096,y:-122.24416},
{x:47.53195,y:-122.19411},
{x:47.56428,y:-122.37704},
{x:45.66574,y:-122.55749},
{x:41.72296,y:-72.76265},
{x:27.49489,y:-82.56256},
{x:28.04994,y:-82.73777},
{x:39.30433,y:-76.61589},
{x:39.38519,y:-76.68708},
{x:38.95536,y:-76.82409},
{x:41.10884,y:-74.58081},
{x:39.07152,y:-84.31149},
{x:30.10817,y:-97.33489},
{x:32.34088,y:-97.4054},
{x:47.82946,y:-122.27223},
{x:34.84995,y:-92.40494},
{x:38.78977,y:-104.75862},
{x:25.82183,y:-80.28135},
{x:28.13875,y:-80.58598},
{x:41.8799,y:-87.64745},
{x:41.46899,y:-87.31605},
{x:30.00415,y:-90.10547},
{x:42.34427,y:-71.1014},
{x:43.61193,y:-84.73536},
{x:38.65909,y:-90.33728},
{x:36.06272,y:-115.22431},
{x:40.75782,y:-73.97588},
{x:40.75387,y:-73.97871},
{x:39.3787,y:-84.37858},
{x:47.79122,y:-122.29174},
{x:35.2824,y:-93.10711},
{x:37.15582,y:-121.65085},
{x:34.06421,y:-117.61133},
{x:25.68638,y:-80.36477},
{x:39.1865,y:-96.57572},
{x:30.17502,y:-92.07711},
{x:42.55554,y:-71.18109},
{x:38.55754,y:-90.26202},
{x:34.73918,y:-76.80854},
{x:39.92175,y:-75.03754},
{x:40.76476,y:-73.97709},
{x:34.69058,y:-82.83442},
{x:31.49549,y:-97.21835},
{x:38.49641,y:-121.4329},
{x:34.07041,y:-117.26161},
{x:37.28977,y:-121.84793},
{x:34.17292,y:-118.55706},
{x:39.88001,y:-105.09676},
{x:41.34605,y:-72.04863},
{x:25.72567,y:-80.26315},
{x:28.10389,y:-81.9507},
{x:33.86489,y:-84.47738},
{x:41.67947,y:-91.59027},
{x:41.68818,y:-88.06864},
{x:41.95518,y:-87.87153},
{x:41.91314,y:-88.33732},
{x:40.41782,y:-86.81643},
{x:38.48047,y:-82.64389},
{x:38.34469,y:-85.49058},
{x:38.21953,y:-85.62668},
{x:38.76831,y:-90.7843},
{x:33.40757,y:-104.52233},
{x:40.68622,y:-73.99099},
{x:40.62113,y:-74.02581},
{x:40.694,y:-73.98692},
{x:42.72662,y:-73.79539},
{x:40.84504,y:-73.2749},
{x:40.70827,y:-73.86904},
{x:35.04032,y:-85.15577},
{x:35.75002,y:-83.99214},
{x:32.60354,y:-96.82361},
{x:38.80261,y:-77.06193},
{x:38.8574,y:-77.38116},
{x:43.28675,y:-87.98604},
{x:40.43194,y:-122.28732},
{x:33.84518,y:-116.47773},
{x:37.79661,y:-121.23531},
{x:33.55056,y:-117.67108},
{x:37.59468,y:-120.99255},
{x:40.39575,y:-104.75488},
{x:43.49503,y:-112.05035},
{x:41.36626,y:-85.07426},
{x:42.55624,y:-70.8904},
{x:41.95405,y:-70.71497},
{x:42.27035,y:-85.64617},
{x:43.03175,y:-71.07322},
{x:41.13431,y:-73.79324},
{x:41.31116,y:-81.44064},
{x:33.54635,y:-101.95759},
{x:42.82031,y:-106.36968},
{x:38.69389,y:-121.29166},
{x:33.73989,y:-118.2921},
{x:38.88449,y:-77.02974},
{x:34.17212,y:-84.78119},
{x:33.90062,y:-84.47777},
{x:41.7872,y:-88.07339},
{x:30.00429,y:-90.18016},
{x:43.05777,y:-85.58032},
{x:42.473,y:-83.20075},
{x:46.37038,y:-94.24566},
{x:39.98111,y:-82.8341},
{x:35.21133,y:-97.44355},
{x:33.23449,y:-111.79089},
{x:33.46532,y:-112.34165},
{x:33.38062,y:-111.60077},
{x:33.50896,y:-112.34149},
{x:34.135,y:-118.25809},
{x:34.28539,y:-118.50126},
{x:37.36605,y:-121.82799},
{x:37.37093,y:-121.91719},
{x:25.61472,y:-80.30984},
{x:28.29485,y:-80.72255},
{x:26.15989,y:-80.12772},
{x:39.36781,y:-76.44981},
{x:43.68404,y:-70.29151},
{x:35.0781,y:-80.817},
{x:36.02261,y:-80.38147},
{x:36.14427,y:-80.30047},
{x:40.99405,y:-74.21333},
{x:40.98603,y:-74.13961},
{x:40.88577,y:-73.90997},
{x:40.71437,y:-73.83188},
{x:40.7189,y:-74.00251},
{x:36.04613,y:-95.86967},
{x:41.487,y:-75.6954},
{x:27.74058,y:-97.38805},
{x:32.94055,y:-97.06649},
{x:40.58741,y:-111.93574},
{x:33.90505,y:-118.39405},
{x:42.57065,y:-114.46083},
{x:42.13694,y:-87.79154},
{x:39.16974,y:-76.78547},
{x:39.36461,y:-77.15974},
{x:42.25221,y:-83.669},
{x:35.74616,y:-78.74186},
{x:35.0586,y:-80.81319},
{x:35.41935,y:-80.67523},
{x:36.27052,y:-115.20654},
{x:41.00306,y:-73.85688},
{x:40.9043,y:-73.07376},
{x:41.25034,y:-81.36274},
{x:45.38295,y:-122.74931},
{x:32.82277,y:-97.05932},
{x:29.54483,y:-95.7536},
{x:33.31983,y:-111.89142},
{x:37.97622,y:-121.80099},
{x:34.12959,y:-118.34854},
{x:39.54866,y:-105.03576},
{x:30.17982,y:-82.67322},
{x:29.18563,y:-82.14023},
{x:44.39816,y:-122.72597},
{x:61.10862,y:-149.85451},
{x:40.67814,y:-74.23374},
{x:39.08049,y:-76.54804},
{x:40.97041,y:-75.6314},
{x:34.75681,y:-86.71204},
{x:33.43552,y:-111.63133},
{x:41.25007,y:-85.82215},
{x:39.70797,y:-104.93297},
{x:37.97124,y:-122.0615},
{x:34.06265,y:-118.35389},
{x:39.72547,y:-84.05395},
{x:32.76772,y:-117.15074},
{x:33.35772,y:-112.42831},
{x:47.53747,y:-122.28307},
{x:33.76877,y:-118.18478},
{x:25.79077,y:-80.1344},
{x:47.61707,y:-122.20192},
{x:29.73851,y:-95.53714},
{x:34.0606,y:-118.20567},
{x:32.48348,y:-110.92038},
{x:40.73541,y:-73.87439},
{x:32.22759,y:-110.9972},
{x:34.60217,y:-83.76475},
{x:34.88024,y:-83.39654},
{x:34.60382,y:-83.51762},
{x:42.79668,y:-77.82189},
{x:38.79113,y:-121.2902},
{x:38.86836,y:-77.14179},
{x:47.718,y:-122.31198},
{x:35.89931,y:-78.89714},
{x:39.17185,y:-119.75277},
{x:41.46261,y:-81.84946},
{x:36.75013,y:-95.93477},
{x:33.22399,y:-86.80178},
{x:30.65477,y:-87.90665},
{x:33.4793,y:-111.98756},
{x:33.4344,y:-111.92601},
{x:38.05418,y:-122.15418},
{x:33.97143,y:-117.68919},
{x:34.70432,y:-118.16503},
{x:34.04915,y:-117.2257},
{x:34.04465,y:-118.26607},
{x:34.07198,y:-117.78833},
{x:38.45294,y:-122.77124},
{x:37.60842,y:-122.02292},
{x:39.9588,y:-104.99137},
{x:38.94449,y:-77.0642},
{x:27.49553,y:-82.61862},
{x:25.68443,y:-80.45671},
{x:27.49951,y:-81.48863},
{x:26.12118,y:-80.33066},
{x:31.21807,y:-81.52061},
{x:42.23815,y:-88.81995},
{x:39.52672,y:-85.73878},
{x:29.95151,y:-90.21401},
{x:36.09834,y:-75.72457},
{x:36.21868,y:-115.32462},
{x:40.73052,y:-73.95414},
{x:42.09237,y:-75.95015},
{x:41.15678,y:-81.40864},
{x:45.53375,y:-122.93533},
{x:39.8236,y:-75.87478},
{x:29.49422,y:-98.70694},
{x:40.58149,y:-111.86134},
{x:47.13669,y:-122.29238},
{x:34.12372,y:-118.21956},
{x:31.1601,y:-81.39108},
{x:39.6592,y:-77.74532},
{x:40.65024,y:-74.64139},
{x:40.7201,y:-74.56309},
{x:33.92359,y:-116.94926},
{x:33.88734,y:-117.52093},
{x:30.16289,y:-85.59051},
{x:33.78704,y:-84.38294},
{x:41.88223,y:-87.63405},
{x:45.498,y:-122.90511},
{x:44.64505,y:-123.11372},
{x:45.84864,y:-122.83078},
{x:41.40819,y:-75.66767},
{x:38.74801,y:-77.45116},
{x:38.96494,y:-104.75697},
{x:39.5386,y:-104.76841},
{x:41.88845,y:-87.79331},
{x:41.17834,y:-85.11387},
{x:39.92644,y:-86.04098},
{x:39.95066,y:-86.27562},
{x:45.03454,y:-92.84598},
{x:40.71947,y:-74.04161},
{x:42.87749,y:-77.26266},
{x:40.3995,y:-80.04377},
{x:29.51054,y:-98.39483},
{x:35.77628,y:-119.25146},
{x:34.20092,y:-118.40593},
{x:28.23855,y:-82.7282},
{x:33.42272,y:-84.55794},
{x:41.71916,y:-88.37416},
{x:41.6757,y:-87.79837},
{x:39.78453,y:-86.16146},
{x:42.51086,y:-83.2223},
{x:40.79739,y:-74.20121},
{x:40.03212,y:-75.21391},
{x:32.64885,y:-97.05962},
{x:40.04934,y:-75.5201},
{x:29.82857,y:-95.43048},
{x:40.75673,y:-111.89763},
{x:42.52489,y:-88.98332},
{x:42.56625,y:-87.92704},
{x:38.08204,y:-122.19358},
{x:37.78612,y:-121.25396},
{x:40.53022,y:-122.38129},
{x:38.6607,y:-121.3628},
{x:36.69119,y:-121.65267},
{x:37.99964,y:-121.28927},
{x:34.797,y:-84.96268},
{x:34.08656,y:-84.47877},
{x:42.96028,y:-71.43844},
{x:35.20509,y:-106.65049},
{x:35.07102,y:-106.53285},
{x:30.30579,y:-97.70799},
{x:30.09008,y:-97.81691},
{x:26.17199,y:-98.00841},
{x:35.554,y:-80.85422},
{x:48.93427,y:-122.48461},
{x:40.01578,y:-105.10238},
{x:30.32428,y:-81.39782},
{x:34.47349,y:-84.92524},
{x:32.50683,y:-84.96593},
{x:32.43724,y:-81.76151},
{x:41.7374,y:-88.25255},
{x:41.96896,y:-87.72903},
{x:42.02686,y:-87.89396},
{x:41.7521,y:-87.91291},
{x:39.85444,y:-86.15911},
{x:39.66539,y:-86.09902},
{x:30.40204,y:-88.97661},
{x:42.88212,y:-70.86808},
{x:40.72701,y:-73.71402},
{x:43.20636,y:-77.67073},
{x:42.75098,y:-73.76441},
{x:41.45321,y:-74.3825},
{x:40.86007,y:-81.48262},
{x:39.73148,y:-82.61449},
{x:47.72013,y:-122.20819},
{x:36.33571,y:-94.20019},
{x:38.51026,y:-122.78061},
{x:30.53171,y:-87.22652},
{x:41.899,y:-87.6281},
{x:39.83128,y:-84.84876},
{x:37.05242,y:-94.47894},
{x:35.04851,y:-80.64653},
{x:32.93636,y:-80.03999},
{x:29.66988,y:-95.26201},
{x:35.60556,y:-77.37402},
{x:33.19735,y:-87.52451},
{x:33.38405,y:-111.73697},
{x:39.41437,y:-104.86392},
{x:39.6237,y:-105.01922},
{x:38.32026,y:-104.61844},
{x:26.28414,y:-80.18722},
{x:28.18335,y:-82.35292},
{x:30.43732,y:-89.09652},
{x:34.07259,y:-81.15786},
{x:35.80905,y:-83.57735},
{x:47.32107,y:-122.22069},
{x:46.14675,y:-122.92614},
{x:45.58145,y:-122.3761},
{x:38.77735,y:-90.33555},
{x:30.14046,y:-81.53262},
{x:29.13866,y:-82.19589},
{x:43.61903,y:-116.27378},
{x:41.97584,y:-87.71012},
{x:42.37939,y:-87.94028},
{x:41.46212,y:-87.08717},
{x:42.23716,y:-83.68086},
{x:40.74697,y:-73.99332},
{x:40.74166,y:-73.99031},
{x:32.77143,y:-117.06974},
{x:40.59316,y:-75.54534},
{x:35.10401,y:-89.87265},
{x:31.0695,y:-97.66798},
{x:37.63238,y:-77.50933},
{x:38.58427,y:-121.28453},
{x:38.54175,y:-121.4084},
{x:32.7279,y:-117.21768},
{x:26.5461,y:-80.08794},
{x:26.91131,y:-80.10148},
{x:28.3339,y:-81.52875},
{x:30.54264,y:-84.22975},
{x:41.96277,y:-87.65654},
{x:43.59,y:-116.28515},
{x:40.6766,y:-73.55708},
{x:38.89014,y:-77.08601},
{x:38.78576,y:-77.51648},
{x:35.44073,y:-119.07585},
{x:37.35259,y:-122.00779},
{x:39.8844,y:-104.77235},
{x:30.26397,y:-82.10713},
{x:27.13237,y:-80.20614},
{x:33.82751,y:-84.33124},
{x:33.04163,y:-84.98269},
{x:39.48488,y:-88.18626},
{x:39.93557,y:-91.36556},
{x:41.61099,y:-88.12255},
{x:39.85604,y:-86.19523},
{x:30.45701,y:-90.95684},
{x:30.18446,y:-93.21829},
{x:38.52932,y:-76.98338},
{x:45.00763,y:-93.17636},
{x:35.74111,y:-77.96167},
{x:39.47362,y:-119.76481},
{x:40.77784,y:-73.03259},
{x:40.92752,y:-73.85324},
{x:36.03223,y:-95.92263},
{x:41.76164,y:-124.19819},
{x:38.45937,y:-121.41557},
{x:32.76355,y:-117.12159},
{x:38.90209,y:-77.06022},
{x:25.89257,y:-80.3506},
{x:28.36914,y:-81.50214},
{x:30.06448,y:-81.50009},
{x:38.89933,y:-94.68584},
{x:30.14503,y:-91.94699},
{x:38.81366,y:-94.51675},
{x:38.93297,y:-94.37924},
{x:37.20269,y:-93.26235},
{x:36.05782,y:-78.92941},
{x:35.26002,y:-106.63795},
{x:36.04175,y:-115.13544},
{x:29.46984,y:-95.09275},
{x:37.39757,y:-76.52707},
{x:41.73352,y:-71.40836},
{x:29.99643,y:-95.57291},
{x:29.71526,y:-95.39685},
{x:38.85366,y:-77.32092},
{x:37.51359,y:-77.69468},
{x:44.4516,y:-88.08612},
{x:34.13324,y:-117.61656},
{x:27.87975,y:-82.82743},
{x:43.60204,y:-116.59875},
{x:39.41556,y:-77.41064},
{x:41.23131,y:-80.44872},
{x:32.43245,y:-99.75978},
{x:31.89976,y:-106.44037},
{x:33.40374,y:-86.80682},
{x:34.55925,y:-87.0005},
{x:41.89425,y:-87.62947},
{x:29.88911,y:-90.03076},
{x:37.30318,y:-89.57746},
{x:44.01931,y:-71.1159},
{x:40.98584,y:-72.53791},
{x:32.82996,y:-96.82718},
{x:29.67773,y:-95.12277},
{x:32.92181,y:-96.99254},
{x:32.54605,y:-94.72763},
{x:32.89658,y:-96.47565},
{x:47.3848,y:-122.2964},
{x:47.48734,y:-121.79347},
{x:32.67212,y:-114.62385},
{x:25.75055,y:-80.23803},
{x:29.9849,y:-91.81896},
{x:33.45106,y:-111.78737},
{x:33.75809,y:-118.08202},
{x:33.85499,y:-84.21},
{x:42.0614,y:-88.14396},
{x:40.40128,y:-86.85797},
{x:36.96256,y:-86.46608},
{x:30.00607,y:-90.2307},
{x:39.28321,y:-76.75465},
{x:39.58398,y:-119.74046},
{x:44.06778,y:-103.23291},
{x:35.83225,y:-86.35165},
{x:37.65335,y:-77.61658},
{x:48.30305,y:-122.6472},
{x:46.93351,y:-122.59033},
{x:33.52675,y:-86.71946},
{x:38.69759,y:-121.00211},
{x:38.26857,y:-122.63931},
{x:41.39853,y:-73.08876},
{x:42.62535,y:-82.94572},
{x:33.00955,y:-96.54452},
{x:44.52396,y:-88.0638},
{x:32.52892,y:-92.11659},
{x:39.21439,y:-76.80292},
{x:38.60863,y:-90.36744},
{x:36.10296,y:-115.13657},
{x:36.12723,y:-97.05626},
{x:30.52688,y:-97.81443},
{x:33.17298,y:-96.7335},
{x:39.78699,y:-105.11012},
{x:25.80522,y:-80.18918},
{x:26.00786,y:-80.27492},
{x:27.79276,y:-82.33099},
{x:42.25574,y:-87.94821},
{x:40.2673,y:-82.93045},
{x:38.78879,y:-89.95188},
{x:32.39788,y:-93.7603},
{x:40.81424,y:-74.22007},
{x:41.11951,y:-81.47971},
{x:40.02293,y:-83.05963},
{x:34.71156,y:-82.25597},
{x:27.39334,y:-82.43559},
{x:30.53483,y:-87.31362},
{x:28.52139,y:-81.58797},
{x:33.97181,y:-84.55128},
{x:42.2707,y:-88.97085},
{x:39.75774,y:-89.6453},
{x:36.0857,y:-115.11945},
{x:45.53819,y:-122.90064},
{x:27.68505,y:-97.38192},
{x:32.83789,y:-96.9791},
{x:29.6511,y:-98.448},
{x:37.63401,y:-77.45615},
{x:46.2263,y:-119.21016},
{x:47.25545,y:-122.46372},
{x:45.91335,y:-122.74833},
{x:35.76054,y:-78.74101},
{x:35.15266,y:-106.49915},
{x:40.89293,y:-74.47847},
{x:32.66514,y:-97.40102},
{x:39.0319,y:-77.42396},
{x:47.62055,y:-117.40179},
{x:37.63852,y:-120.9248},
{x:37.30209,y:-121.86429},
{x:39.52685,y:-105.30617},
{x:25.44821,y:-80.47574},
{x:25.78183,y:-80.28822},
{x:28.47439,y:-81.45134},
{x:29.26442,y:-81.10477},
{x:34.07435,y:-83.98466},
{x:34.06251,y:-84.42063},
{x:38.67618,y:-90.0146},
{x:39.61075,y:-86.15811},
{x:39.88161,y:-86.06836},
{x:39.40732,y:-87.40845},
{x:38.91239,y:-94.77796},
{x:43.02305,y:-85.68968},
{x:41.30886,y:-81.81999},
{x:29.78504,y:-95.49508},
{x:28.35627,y:-81.67672},
{x:29.1972,y:-81.05647},
{x:41.57529,y:-90.52795},
{x:40.44094,y:-86.12551},
{x:40.03531,y:-86.47002},
{x:38.17666,y:-84.81379},
{x:42.48368,y:-71.01909},
{x:41.07613,y:-74.13891},
{x:40.60501,y:-73.9986},
{x:35.78013,y:-86.9145},
{x:30.19022,y:-95.58621},
{x:40.69623,y:-111.95839},
{x:43.75602,y:-87.72339},
{x:38.00189,y:-121.84015},
{x:34.15212,y:-84.17323},
{x:33.63414,y:-117.91658},
{x:33.68071,y:-117.90637},
{x:33.46635,y:-117.7036},
{x:33.67184,y:-117.84581},
{x:33.54318,y:-117.78603},
{x:33.52684,y:-117.717},
{x:34.03424,y:-118.69355},
{x:33.6011,y:-117.67383},
{x:33.65679,y:-117.86556},
{x:33.78763,y:-117.85364},
{x:45.53919,y:-122.86656},
{x:45.49361,y:-122.80986},
{x:45.50267,y:-122.41476},
{x:45.52084,y:-122.97413},
{x:45.49727,y:-122.62568},
{x:45.50437,y:-122.53355},
{x:45.52618,y:-122.4774},
{x:45.51923,y:-122.55804},
{x:45.527,y:-122.66093},
{x:45.56666,y:-122.66181},
{x:30.17974,y:-95.48043},
{x:40.214,y:-75.50161},
{x:30.35919,y:-95.59519},
{x:41.72,y:-87.7894},
{x:38.77216,y:-121.31},
{x:40.84049,y:-76.83067},
{x:37.65664,y:-121.90017},
{x:39.14199,y:-121.64457},
{x:39.67437,y:-104.86963},
{x:28.12868,y:-80.63058},
{x:27.85276,y:-82.63835},
{x:30.18807,y:-92.01328},
{x:38.95541,y:-92.38113},
{x:40.59982,y:-73.94197},
{x:30.45604,y:-97.60339},
{x:42.20312,y:-87.93522},
{x:39.56439,y:-76.97503},
{x:35.44199,y:-80.85696},
{x:40.98487,y:-75.19519},
{x:32.78589,y:-79.93649},
{x:36.95877,y:-81.09709},
{x:47.94277,y:-122.10825},
{x:33.67235,y:-111.90572},
{x:39.12698,y:-121.6361},
{x:28.54277,y:-81.15314},
{x:33.78026,y:-84.36881},
{x:31.20298,y:-81.48288},
{x:33.10958,y:-83.25594},
{x:42.39199,y:-71.01305},
{x:43.13168,y:-77.68862},
{x:32.92513,y:-96.77003},
{x:34.16337,y:-118.26478},
{x:33.89668,y:-118.3521},
{x:33.60823,y:-117.82739},
{x:41.38041,y:-73.48077},
{x:41.60196,y:-87.79359},
{x:39.15465,y:-76.74249},
{x:39.19952,y:-76.68319},
{x:33.84335,y:-112.13746},
{x:32.74708,y:-116.96144},
{x:40.94471,y:-74.20492},
{x:40.06685,y:-76.34006},
{x:35.19261,y:-101.79663},
{x:29.60792,y:-98.50778},
{x:30.05097,y:-95.4299},
{x:37.60711,y:-77.35619},
{x:40.69561,y:-80.30442},
{x:38.64625,y:-121.11351},
{x:33.95605,y:-118.39596},
{x:30.16037,y:-92.05333},
{x:42.62174,y:-83.12977},
{x:40.72689,y:-74.00026},
{x:26.18986,y:-97.71533},
{x:33.17917,y:-111.58493},
{x:37.80063,y:-121.91958},
{x:26.7059,y:-80.07987},
{x:41.98383,y:-88.07994},
{x:42.36311,y:-88.16742},
{x:41.60681,y:-87.17211},
{x:40.76439,y:-73.7262},
{x:47.56656,y:-122.32964},
{x:38.71579,y:-75.08626},
{x:41.91751,y:-87.68038},
{x:38.04095,y:-84.42448},
{x:38.57329,y:-89.93064},
{x:40.2534,y:-74.30019},
{x:35.10026,y:-106.5861},
{x:41.58102,y:-71.50206},
{x:33.52144,y:-101.86996},
{x:37.64134,y:-77.571},
{x:33.70692,y:-116.27147},
{x:34.17177,y:-84.54804},
{x:40.66065,y:-74.17145},
{x:40.86374,y:-73.97497},
{x:41.00774,y:-74.29292},
{x:40.84135,y:-73.93982},
{x:41.16339,y:-81.47702},
{x:43.23081,y:-123.37051},
{x:44.89979,y:-123.03852},
{x:29.63163,y:-95.23406},
{x:37.76706,y:-122.39366},
{x:27.01618,y:-82.05289},
{x:27.94448,y:-82.47597},
{x:40.74956,y:-73.88737},
{x:40.71017,y:-74.00793},
{x:42.36016,y:-122.86116},
{x:30.73407,y:-86.56654},
{x:42.08907,y:-88.02021},
{x:33.144,y:-96.67792},
{x:32.73364,y:-97.34414},
{x:30.01559,y:-97.8635},
{x:39.4585,y:-76.31798},
{x:40.92776,y:-73.84295},
{x:44.96739,y:-122.98316},
{x:45.38039,y:-122.75962},
{x:32.85835,y:-97.26456},
{x:38.89836,y:-77.119},
{x:33.40905,y:-111.68512},
{x:35.59177,y:-78.77511},
{x:40.09523,y:-75.01549},
{x:29.57828,y:-95.15697},
{x:37.78907,y:-122.40842},
{x:32.35434,y:-95.34265},
{x:36.54565,y:-119.40036},
{x:35.50738,y:-97.56536},
{x:43.06835,y:-88.04815},
{x:34.02677,y:-118.37179},
{x:41.15823,y:-87.88587},
{x:36.83805,y:-76.30663},
{x:36.77553,y:-76.08787},
{x:37.67132,y:-122.46421},
{x:35.2455,y:-120.67328},
{x:33.96879,y:-84.19521},
{x:41.8846,y:-87.65188},
{x:43.47956,y:-83.96872},
{x:36.14839,y:-79.86918},
{x:48.50531,y:-122.33684},
{x:40.80591,y:-73.95449},
{x:40.73798,y:-73.9883},
{x:31.78525,y:-106.41273},
{x:30.44902,y:-97.65544},
{x:34.13284,y:-118.14783},
{x:40.08706,y:-104.98548},
{x:43.0431,y:-83.32144},
{x:40.27956,y:-80.08457},
{x:40.03667,y:-75.35171},
{x:29.80715,y:-95.16753},
{x:38.25455,y:-122.06648},
{x:41.04394,y:-73.55832},
{x:32.04133,y:-81.06378},
{x:42.37073,y:-87.88332},
{x:41.75189,y:-70.59427},
{x:42.25398,y:-83.19098},
{x:40.62043,y:-74.76482},
{x:40.74072,y:-74.03012},
{x:38.81118,y:-77.0504},
{x:46.25765,y:-119.31017},
{x:33.54053,y:-117.14391},
{x:33.6847,y:-117.17923},
{x:32.83835,y:-116.99489},
{x:33.65443,y:-84.50322},
{x:41.02676,y:-74.23975},
{x:40.78844,y:-73.97607},
{x:30.23249,y:-97.87408},
{x:37.05588,y:-120.87456},
{x:42.38249,y:-88.06338},
{x:35.71567,y:-81.31399},
{x:40.16442,y:-75.48122},
{x:33.45089,y:-111.73488},
{x:38.95788,y:-76.86977},
{x:39.60639,y:-119.22274},
{x:37.25897,y:-77.32741},
{x:36.87545,y:-76.43951},
{x:37.79925,y:-122.44935},
{x:38.5484,y:-122.8109},
{x:41.41373,y:-73.37745},
{x:41.92545,y:-87.67233},
{x:35.39748,y:-77.96352},
{x:33.642,y:-112.06499},
{x:32.53166,y:-84.96945},
{x:47.16834,y:-122.14919},
{x:37.57889,y:-120.93918},
{x:33.37883,y:-112.10184},
{x:28.2356,y:-82.66681},
{x:37.50208,y:-122.24638},
{x:30.22076,y:-85.64995},
{x:33.92735,y:-118.38372},
{x:34.09777,y:-118.36522},
{x:34.00973,y:-84.75479},
{x:29.99671,y:-95.75708},
{x:30.2177,y:-97.68997},
{x:32.69807,y:-114.59935},
{x:25.97914,y:-80.3415},
{x:31.11132,y:-97.74736},
{x:42.69876,y:-87.85315},
{x:29.44081,y:-98.49944},
{x:29.35705,y:-98.5246},
{x:47.70208,y:-117.41167},
{x:37.53259,y:-122.0023},
{x:41.87446,y:-84.03261},
{x:41.2713,y:-72.97238},
{x:39.9593,y:-75.28662},
{x:40.73197,y:-73.61275},
{x:39.98423,y:-76.72626},
{x:41.14458,y:-73.99024},
{x:40.83332,y:-73.14673},
{x:47.70754,y:-116.78578},
{x:42.54367,y:-83.78745},
{x:45.98996,y:-112.51031},
{x:32.31581,y:-110.9772},
{x:30.55642,y:-97.84396},
{x:29.52417,y:-98.56862},
{x:38.61334,y:-121.27048},
{x:43.60414,y:-116.35413},
{x:33.14635,y:-117.24157},
{x:40.76199,y:-73.92507},
{x:40.71378,y:-74.00912},
{x:30.63219,y:-97.69297},
{x:47.60905,y:-122.3399},
{x:29.74872,y:-95.46579},
{x:40.72356,y:-111.54291},
{x:39.35328,y:-74.44573},
{x:40.56446,y:-105.07638},
{x:25.77446,y:-80.17633},
{x:33.89616,y:-118.39445},
{x:33.64073,y:-117.87597},
{x:35.1251,y:-80.70968},
{x:32.9118,y:-97.30929},
{x:31.84889,y:-106.3558},
{x:34.14686,y:-118.08501},
{x:42.64332,y:-71.33416},
{x:32.84434,y:-96.82233},
{x:45.51515,y:-122.67587},
{x:41.45817,y:-82.01646},
{x:37.29316,y:-121.96651},
{x:33.62971,y:-117.91003},
{x:42.30288,y:-83.70571},
{x:40.41961,y:-104.79255},
{x:34.00101,y:-83.90588},
{x:41.9773,y:-87.8986},
{x:33.60964,y:-111.92629},
{x:34.05605,y:-118.23577},
{x:33.39296,y:-84.7581},
{x:33.1255,y:-96.88845},
{x:37.9232,y:-122.31621},
{x:21.30179,y:-157.84191},
{x:29.64149,y:-82.34524},
{x:39.70807,y:-105.07903},
{x:42.34994,y:-71.50502},
{x:37.92681,y:-122.05496},
{x:33.03999,y:-97.09764},
{x:40.14559,y:-82.97999},
{x:42.70694,y:-73.82328},
{x:42.40184,y:-71.06993},
{x:42.3056,y:-71.39771},
{x:40.628,y:-74.30778},
{x:42.01963,y:-93.6072},
{x:44.93987,y:-92.90977},
{x:37.82892,y:-122.2899},
{x:40.7965,y:-74.48159},
{x:34.02167,y:-118.28218},
{x:47.58103,y:-122.33587},
{x:29.42629,y:-98.49118},
{x:61.598,y:-149.12686},
{x:39.65374,y:-104.9605},
{x:47.84967,y:-122.27309},
{x:47.62276,y:-122.33668},
{x:34.93683,y:-80.83803},
{x:41.64311,y:-88.00136},
{x:40.72636,y:-73.27673},
{x:39.94437,y:-105.01437},
{x:45.41285,y:-122.71751},
{x:41.8642,y:-87.93379},
{x:37.2313,y:-121.97981},
{x:40.75552,y:-73.98408},
{x:33.57348,y:-85.09777},
{x:40.73937,y:-74.0026},
{x:40.81781,y:-73.59582},
{x:40.61183,y:-105.07367},
{x:43.07523,y:-89.45127},
{x:42.00685,y:-87.88678},
{x:26.30158,y:-80.09333},
{x:42.51812,y:-71.13558},
{x:38.99887,y:-76.90658},
{x:33.94809,y:-118.33342},
{x:42.1544,y:-87.94988},
{x:45.52376,y:-122.68204},
{x:32.85519,y:-116.93149},
{x:43.03408,y:-88.17755},
{x:41.87019,y:-71.52453},
{x:46.8085,y:-92.16501},
{x:35.38365,y:-119.10317},
{x:46.36321,y:-94.24127},
{x:45.02568,y:-84.68438},
{x:47.70792,y:-122.1838},
{x:47.86442,y:-122.28324},
{x:34.14762,y:-118.10215},
{x:45.52212,y:-123.0447},
{x:34.17416,y:-118.46633},
{x:34.19165,y:-118.32995},
{x:38.09025,y:-122.55324},
{x:42.18719,y:-88.10395},
{x:42.13908,y:-88.02565},
{x:33.92531,y:-118.10728},
{x:32.33889,y:-111.04574},
{x:21.29855,y:-157.81893},
{x:33.9673,y:-84.2344},
{x:40.88559,y:-73.09917},
{x:45.30103,y:-122.7712},
{x:40.7255,y:-73.49598},
{x:40.83902,y:-73.28691},
{x:41.60341,y:-84.40778},
{x:41.60549,y:-84.40741},
{x:33.92198,y:-118.09789},
{x:40.04851,y:-75.41313},
{x:19.70059,y:-155.06103},
{x:39.15351,y:-77.06217},
{x:38.6958,y:-121.59389},
{x:37.71086,y:-121.43361},
{x:31.80013,y:-106.3937},
{x:41.72614,y:-71.47715},
{x:38.28199,y:-122.2765},
{x:42.2826,y:-71.65266},
{x:37.97685,y:-122.06677},
{x:30.42233,y:-95.49443},
{x:41.88265,y:-87.62495},
{x:40.76608,y:-73.95753},
{x:47.18992,y:-122.50981},
{x:33.02539,y:-96.88757},
{x:29.55589,y:-98.53546},
{x:41.66666,y:-70.29225},
{x:35.02233,y:-80.57905},
{x:27.30026,y:-82.45193},
{x:47.6073,y:-122.33589},
{x:33.76041,y:-117.8854},
{x:33.99568,y:-81.22308},
{x:47.62101,y:-122.18632},
{x:28.04048,y:-82.50538},
{x:46.14568,y:-122.89603},
{x:41.50559,y:-74.06824},
{x:33.31941,y:-111.92721},
{x:30.45313,y:-97.82707},
{x:42.14104,y:-83.22647},
{x:42.00385,y:-88.00746},
{x:40.71823,y:-74.00732},
{x:42.01177,y:-87.80056},
{x:39.11874,y:-77.20189},
{x:43.61801,y:-116.43433},
{x:19.70094,y:-155.06021},
{x:33.25013,y:-111.83814},
{x:40.46089,y:-79.92158},
{x:30.02012,y:-90.24679},
{x:34.2426,y:-119.17761},
{x:35.25204,y:-120.68433},
{x:41.74771,y:-71.2067},
{x:39.83009,y:-76.98924},
{x:34.23368,y:-116.06184},
{x:33.60637,y:-117.68863},
{x:41.8786,y:-71.06573},
{x:33.682,y:-117.91922},
{x:34.17116,y:-84.4163},
{x:32.55269,y:-83.65981},
{x:34.06923,y:-118.02146},
{x:41.96737,y:-87.99253},
{x:33.70297,y:-117.88735},
{x:40.65293,y:-73.87027},
{x:44.04417,y:-122.92761},
{x:38.74036,y:-104.79758},
{x:36.60115,y:-87.45119},
{x:37.81023,y:-121.99013},
{x:47.5359,y:-122.0217},
{x:35.60879,y:-82.3209},
{x:32.7493,y:-117.1681},
{x:38.87391,y:-99.34193},
{x:37.3742,y:-121.84491},
{x:47.57956,y:-122.38632},
{x:32.80646,y:-96.78913},
{x:31.54872,y:-97.11761},
{x:33.17003,y:-96.89121},
{x:32.5736,y:-97.08363},
{x:34.68701,y:-79.20376},
{x:40.39683,y:-80.55386},
{x:33.15333,y:-83.27214},
{x:33.95983,y:-117.59308},
{x:41.90725,y:-87.97724},
{x:36.98536,y:-86.45657},
{x:32.66782,y:-114.44077},
{x:39.19118,y:-96.61062},
{x:33.11375,y:-117.09976},
{x:41.87768,y:-87.65476},
{x:34.53094,y:-83.98412},
{x:39.7665,y:-86.16761},
{x:34.84004,y:-84.33732},
{x:34.36564,y:-89.53712},
{x:32.91124,y:-117.14587},
{x:32.98034,y:-117.0606},
{x:33.94827,y:-117.38245},
{x:32.75158,y:-117.20957},
{x:44.88145,y:-93.32644},
{x:33.69025,y:-117.88988},
{x:35.90091,y:-78.65438},
{x:43.22313,y:-87.92409},
{x:46.8585,y:-96.7515},
{x:33.77945,y:-111.92272},
{x:41.50878,y:-83.74991},
{x:38.70528,y:-77.14304},
{x:38.87619,y:-76.9998},
{x:41.70892,y:-87.71258},
{x:29.50689,y:-98.57538},
{x:39.40233,y:-76.94326},
{x:36.1238,y:-115.1709},
{x:40.79829,y:-77.86111},
{x:28.53872,y:-81.3786},
{x:35.57753,y:-77.38167},
{x:36.60081,y:-121.89431},
{x:42.30268,y:-71.33885},
{x:45.64876,y:-122.66791},
{x:42.5629,y:-83.1825},
{x:37.19929,y:-93.27854},
{x:42.88705,y:-85.85041},
{x:33.46326,y:-117.60672},
{x:39.22433,y:-84.39488},
{x:35.80723,y:-78.81585},
{x:37.70315,y:-121.85402},
{x:40.13238,y:-76.30738},
{x:35.26255,y:-81.13354},
{x:44.97391,y:-93.27577},
{x:40.61045,y:-73.91928},
{x:32.70488,y:-117.18847},
{x:47.8326,y:-122.26634},
{x:43.64724,y:-70.31157},
{x:37.57645,y:-122.34808},
{x:29.94971,y:-90.07721},
{x:37.92771,y:-122.51713},
{x:45.67937,y:-111.06023},
{x:38.57775,y:-121.49141},
{x:40.78046,y:-124.13903},
{x:31.12024,y:-97.8751},
{x:42.97111,y:-85.66648},
{x:38.44469,y:-90.37467},
{x:40.72453,y:-73.98769},
{x:34.07526,y:-118.37557},
{x:34.06279,y:-118.44371},
{x:34.04901,y:-118.26117},
{x:25.79416,y:-80.2786},
{x:33.53135,y:-112.0134},
{x:40.69489,y:-73.50702},
{x:41.87937,y:-87.62467},
{x:45.33429,y:-122.5911},
{x:36.20281,y:-81.66214},
{x:35.9143,y:-82.29071},
{x:33.36441,y:-111.87704},
{x:39.27126,y:-76.60121},
{x:33.74148,y:-116.21619},
{x:36.89903,y:-76.20184},
{x:33.06582,y:-97.08125},
{x:47.1803,y:-122.55199},
{x:33.74766,y:-117.45384},
{x:33.68052,y:-112.23961},
{x:33.67856,y:-117.86242},
{x:35.1033,y:-80.9909},
{x:40.73757,y:-73.32102},
{x:42.33627,y:-71.15013},
{x:34.41936,y:-119.70437},
{x:48.73371,y:-122.48582},
{x:34.70157,y:-82.79557},
{x:41.06305,y:-80.0451},
{x:37.49873,y:-77.52531},
{x:39.9882,y:-105.12396},
{x:47.19564,y:-120.95347},
{x:43.47268,y:-110.78895},
{x:32.91355,y:-97.30903},
{x:43.12561,y:-89.31271},
{x:33.06554,y:-117.29341},
{x:36.66249,y:-93.24147},
{x:43.59046,y:-84.77573},
{x:36.53256,y:-87.35272},
{x:43.68172,y:-114.36306},
{x:40.751,y:-73.98701},
{x:36.08619,y:-115.13751},
{x:36.08613,y:-115.1351},
{x:39.15034,y:-77.06461},
{x:40.69176,y:-73.98856},
{x:35.67893,y:-105.95349},
{x:25.77509,y:-80.17758},
{x:35.03346,y:-80.97047},
{x:36.53839,y:-121.90964},
{x:40.74383,y:-73.98888},
{x:39.84339,y:-105.08358},
{x:39.60686,y:-84.16304},
{x:33.87544,y:-84.53364},
{x:32.60343,y:-117.08375},
{x:27.71314,y:-97.32436},
{x:31.76806,y:-106.50229},
{x:34.24068,y:-119.17893},
{x:40.7567,y:-73.99096},
{x:41.91081,y:-87.64929},
{x:30.71468,y:-95.54626},
{x:34.23724,y:-118.58893},
{x:47.60534,y:-122.33041},
{x:42.43919,y:-123.32545},
{x:47.69197,y:-122.35869},
{x:42.34255,y:-71.05686},
{x:33.23033,y:-97.16215},
{x:37.65795,y:-121.89817},
{x:33.786,y:-118.02897},
{x:34.20527,y:-118.22782},
{x:33.92819,y:-118.19876},
{x:33.43469,y:-111.99718},
{x:39.28689,y:-76.61287},
{x:37.50211,y:-121.97081},
{x:21.39164,y:-157.73955},
{x:33.43649,y:-111.99408},
{x:44.83593,y:-93.08749},
{x:40.18648,y:-105.07558},
{x:38.93981,y:-104.7605},
{x:39.57267,y:-104.99094},
{x:39.46856,y:-104.87945},
{x:39.8111,y:-105.05188},
{x:40.37995,y:-105.09948},
{x:36.11526,y:-115.17533},
{x:45.5117,y:-122.63746},
{x:44.04813,y:-123.17186},
{x:33.7298,y:-117.99536},
{x:33.84465,y:-84.24549},
{x:26.60673,y:-81.97437},
{x:34.9489,y:-83.75741},
{x:29.77352,y:-95.38994},
{x:47.60837,y:-122.33844},
{x:39.03998,y:-76.67829},
{x:42.28642,y:-71.66827},
{x:38.99665,y:-77.28718},
{x:34.75105,y:-86.75542},
{x:39.0504,y:-95.72718},
{x:39.56843,y:-105.08165},
{x:42.37765,y:-71.0901},
{x:38.97183,y:-94.60765},
{x:40.81628,y:-96.65557},
{x:42.48377,y:-76.48737},
{x:36.16816,y:-80.27699},
{x:42.76438,y:-71.21668},
{x:30.38897,y:-97.88405},
{x:32.81391,y:-96.82923},
{x:47.56821,y:-122.66737},
{x:39.7566,y:-104.90138},
{x:36.80858,y:-119.82823},
{x:38.2157,y:-85.53803},
{x:25.74663,y:-80.33538},
{x:45.84997,y:-95.39047},
{x:42.72426,y:-84.4165},
{x:41.59931,y:-93.7532},
{x:44.46556,y:-87.98648},
{x:26.18779,y:-80.11558},
{x:41.14089,y:-73.25846},
{x:31.13819,y:-81.57992},
{x:33.25079,y:-117.30076},
{x:29.70039,y:-95.81149},
{x:39.04321,y:-77.11184},
{x:37.1576,y:-80.42404},
{x:37.99473,y:-122.10597},
{x:26.27294,y:-80.20566},
{x:36.03304,y:-83.93198},
{x:37.41737,y:-122.13061},
{x:42.39482,y:-71.04281},
{x:36.87469,y:-76.13513},
{x:38.84556,y:-77.06805},
{x:34.63012,y:-98.49221},
{x:35.6292,y:-78.82152},
{x:34.55243,y:-82.67827},
{x:29.82108,y:-95.54574},
{x:25.94558,y:-80.16742},
{x:42.51511,y:-71.03373},
{x:35.10087,y:-106.56788},
{x:29.57876,y:-95.39822},
{x:39.93242,y:-74.95924},
{x:33.43492,y:-111.99767},
{x:38.79952,y:-97.61474},
{x:36.0852,y:-115.15003},
{x:47.68253,y:-122.2894},
{x:25.79723,y:-80.2805},
{x:44.97461,y:-93.23362},
{x:35.14624,y:-119.46612},
{x:21.35994,y:-157.88968},
{x:40.20509,y:-77.28761},
{x:36.20164,y:-115.28374},
{x:40.51514,y:-111.41135},
{x:40.16586,y:-77.59719},
{x:35.91333,y:-84.08901},
{x:37.42412,y:-122.17086},
{x:37.40295,y:-77.67612},
{x:29.77478,y:-95.39835},
{x:40.78824,y:-73.7027},
{x:34.15209,y:-86.84353},
{x:40.60878,y:-73.95859},
{x:39.0336,y:-76.68271},
{x:33.57118,y:-117.70521},
{x:39.24876,y:-94.59559},
{x:41.96939,y:-73.98878},
{x:40.29711,y:-111.69953},
{x:43.70094,y:-72.28932},
{x:38.62837,y:-77.28779},
{x:33.70773,y:-78.86198},
{x:39.13652,y:-77.69461},
{x:39.20228,y:-119.78425},
{x:31.91116,y:-106.58384},
{x:34.04548,y:-118.26139},
{x:41.86947,y:-71.52899},
{x:34.14428,y:-118.14558},
{x:42.84871,y:-85.62577},
{x:33.03958,y:-116.87398},
{x:33.69763,y:-117.83472},
{x:42.22648,y:-88.31262},
{x:39.88742,y:-104.81182},
{x:40.69224,y:-112.04818},
{x:26.19117,y:-98.2066},
{x:33.67094,y:-111.90431},
{x:33.57037,y:-111.88909},
{x:43.1882,y:-76.24695},
{x:36.97507,y:-121.96875},
{x:47.49312,y:-94.91036},
{x:44.43456,y:-93.18797},
{x:43.03622,y:-88.07019},
{x:38.73935,y:-77.21172},
{x:34.00687,y:-118.41254},
{x:34.01858,y:-118.28176},
{x:29.65353,y:-95.03235},
{x:34.07697,y:-118.03983},
{x:33.77787,y:-117.95818},
{x:37.89471,y:-122.0576},
{x:42.97237,y:-85.73603},
{x:40.79523,y:-73.96552},
{x:40.74051,y:-73.98432},
{x:34.8655,y:-118.1767},
{x:38.27882,y:-122.68062},
{x:34.60065,y:-112.45818},
{x:36.05432,y:-79.90254},
{x:40.76307,y:-73.97234},
{x:44.96631,y:-93.16556},
{x:33.65226,y:-78.98405},
{x:37.78961,y:-122.39755},
{x:39.05346,y:-84.65597},
{x:33.92179,y:-84.33834},
{x:33.7736,y:-84.36588},
{x:41.22058,y:-95.83382},
{x:33.63984,y:-84.41967},
{x:33.65904,y:-84.49949},
{x:39.96011,y:-82.99777},
{x:33.63997,y:-84.43266},
{x:37.41979,y:-77.63555},
{x:34.18938,y:-79.83309},
{x:33.03682,y:-80.15776},
{x:32.81815,y:-115.56997},
{x:41.63064,y:-93.73926},
{x:35.86259,y:-84.07946},
{x:40.67044,y:-89.58646},
{x:32.71209,y:-117.15123},
{x:33.79637,y:-118.12446},
{x:39.73871,y:-104.99685},
{x:39.53671,y:-104.85613},
{x:45.1253,y:-93.49109},
{x:42.95668,y:-71.47819},
{x:32.95626,y:-97.28015},
{x:32.25796,y:-80.85086},
{x:38.23863,y:-122.63532},
{x:38.29922,y:-122.28569},
{x:37.31271,y:-89.53234},
{x:38.95192,y:-95.23524},
{x:33.76046,y:-118.3947},
{x:43.0224,y:-85.68907},
{x:39.35553,y:-84.36294},
{x:37.74905,y:-88.97999},
{x:39.75948,y:-84.05359},
{x:46.55135,y:-87.45988},
{x:42.831,y:-86.09369},
{x:34.80612,y:-87.67931},
{x:33.80794,y:-117.91934},
{x:30.68136,y:-97.71566},
{x:35.33435,y:-119.07358},
{x:34.00978,y:-117.8595},
{x:34.17879,y:-118.85814},
{x:45.81026,y:-108.47345},
{x:39.64403,y:-86.86442},
{x:40.40776,y:-105.00674},
{x:33.64182,y:-84.43255},
{x:39.23574,y:-84.59188},
{x:36.11026,y:-115.17035},
{x:41.85003,y:-87.61582},
{x:29.49673,y:-98.32219},
{x:33.916,y:-117.32524},
{x:29.71503,y:-95.7783},
{x:35.2471,y:-91.73049},
{x:33.06996,y:-117.06526},
{x:41.1409,y:-95.97033},
{x:38.85332,y:-94.68255},
{x:44.51863,y:-109.05407},
{x:33.25097,y:-111.84078},
{x:42.1387,y:-87.98576},
{x:39.88477,y:-104.99371},
{x:40.76179,y:-73.97954},
{x:39.77029,y:-105.05417},
{x:33.6405,y:-84.4189},
{x:41.99257,y:-87.69015},
{x:35.21031,y:-84.85906},
{x:45.61117,y:-122.68018},
{x:39.5956,y:-104.88034},
{x:40.31853,y:-79.68879},
{x:42.19078,y:-87.82741},
{x:42.2096,y:-71.55205},
{x:32.68122,y:-97.10976},
{x:45.42533,y:-122.51352},
{x:32.89887,y:-97.04449},
{x:39.94788,y:-75.17128},
{x:39.53041,y:-119.81469},
{x:40.73089,y:-73.98319},
{x:38.34773,y:-75.60184},
{x:33.37933,y:-112.17027},
{x:34.93545,y:-82.00536},
{x:35.17945,y:-111.65601},
{x:35.05287,y:-80.84908},
{x:35.38319,y:-119.02007},
{x:38.89421,y:-104.71747},
{x:33.89094,y:-117.20795},
{x:42.4911,y:-90.72286},
{x:38.90551,y:-77.0061},
{x:40.89024,y:-73.90224},
{x:38.67885,y:-121.26522},
{x:41.04952,y:-81.72956},
{x:33.85838,y:-118.0305},
{x:34.01912,y:-118.1536},
{x:37.67059,y:-120.95721},
{x:34.06145,y:-118.29952},
{x:21.29784,y:-157.86162},
{x:33.85061,y:-117.88885},
{x:33.65742,y:-117.98797},
{x:34.23873,y:-118.55605},
{x:42.98268,y:-87.94881},
{x:33.03896,y:-97.00385},
{x:38.74415,y:-121.22362},
{x:40.71604,y:-74.03322},
{x:37.88152,y:-122.26996},
{x:34.16204,y:-118.28915},
{x:45.78536,y:-108.59292},
{x:38.94316,y:-121.0956},
{x:43.12782,y:-77.44128},
{x:30.10211,y:-81.72356},
{x:40.75985,y:-96.64091},
{x:34.08401,y:-83.31277},
{x:34.51049,y:-83.54319},
{x:41.67657,y:-86.2496},
{x:35.32201,y:-81.88175},
{x:21.96883,y:-159.38661},
{x:46.21008,y:-119.22498},
{x:61.57646,y:-149.40542},
{x:44.04769,y:-123.13866},
{x:40.26716,y:-83.07089},
{x:42.22023,y:-71.03016},
{x:34.15726,y:-84.81888},
{x:35.30337,y:-120.66274},
{x:36.04852,y:-83.99597},
{x:36.29526,y:-82.4933},
{x:47.03769,y:-122.82286},
{x:45.63886,y:-122.60011},
{x:61.22871,y:-149.74249},
{x:26.57061,y:-80.08921},
{x:36.33448,y:-86.62545},
{x:38.84998,y:-94.81923},
{x:39.28802,y:-84.45395},
{x:32.93226,y:-80.03661},
{x:32.31437,y:-106.74617},
{x:45.77677,y:-111.18491},
{x:36.05531,y:-94.19013},
{x:40.68737,y:-111.86603},
{x:40.76742,y:-111.89142},
{x:39.16264,y:-84.27495},
{x:37.42925,y:-121.90933},
{x:39.04,y:-108.53927},
{x:45.6766,y:-111.06534},
{x:34.04596,y:-118.25122},
{x:38.95666,y:-94.62917},
{x:35.14753,y:-106.55553},
{x:37.63364,y:-122.11173},
{x:38.63274,y:-90.23399},
{x:40.74415,y:-73.60377},
{x:41.88434,y:-87.63369},
{x:41.75347,y:-88.1153},
{x:33.64041,y:-78.98994},
{x:42.16214,y:-88.33627},
{x:32.44465,y:-93.72193},
{x:41.75073,y:-83.62825},
{x:40.54571,y:-74.33496},
{x:34.96237,y:-90.00672},
{x:47.4931,y:-111.26942},
{x:37.78446,y:-122.40384},
{x:36.8436,y:-76.13319},
{x:42.88183,y:-85.74053},
{x:45.61046,y:-122.67748},
{x:42.86658,y:-73.93272},
{x:38.87299,y:-77.2298},
{x:29.48926,y:-98.44717},
{x:29.45301,y:-98.56292},
{x:45.77055,y:-108.52843},
{x:45.20117,y:-93.55413},
{x:33.00876,y:-96.57503},
{x:32.7487,y:-96.44999},
{x:30.5295,y:-87.22429},
{x:43.66552,y:-116.41153},
{x:42.8239,y:-71.50546},
{x:35.11647,y:-89.91078},
{x:37.67711,y:-97.13368},
{x:36.31255,y:-94.17886},
{x:40.03815,y:-75.15382},
{x:43.45871,y:-110.79578},
{x:34.68136,y:-82.84142},
{x:38.43419,y:-78.85823},
{x:34.21697,y:-77.90691},
{x:27.60901,y:-99.47581},
{x:29.51597,y:-98.59404},
{x:41.38428,y:-81.43647},
{x:35.23857,y:-97.48141},
{x:35.0634,y:-80.9944},
{x:34.15669,y:-118.4374},
{x:34.17155,y:-118.44736},
{x:29.42497,y:-95.05929},
{x:34.02715,y:-118.4737},
{x:34.03485,y:-118.44912},
{x:29.53479,y:-98.68164},
{x:21.32713,y:-158.02008},
{x:34.98261,y:-79.01397},
{x:32.57527,y:-97.08379},
{x:29.72019,y:-95.34063},
{x:28.00347,y:-81.72838},
{x:35.11413,y:-83.09856},
{x:32.86655,y:-117.23191},
{x:33.97979,y:-118.43895},
{x:33.86157,y:-117.73982},
{x:33.10107,y:-117.31018},
{x:41.80353,y:-88.12147},
{x:33.74529,y:-84.41147},
{x:40.7647,y:-73.96636},
{x:34.26341,y:-118.52414},
{x:38.97198,y:-95.30324},
{x:34.97598,y:-81.02783},
{x:32.78318,y:-79.99235},
{x:38.98585,y:-77.53537},
{x:39.94458,y:-104.93813},
{x:43.03601,y:-76.14082},
{x:36.10692,y:-115.17773},
{x:34.95403,y:-80.75927},
{x:44.86661,y:-93.32841},
{x:42.1517,y:-88.43391},
{x:30.45596,y:-88.90255},
{x:40.72474,y:-73.53608},
{x:42.82877,y:-86.1367},
{x:42.82758,y:-86.01675},
{x:42.75631,y:-86.11457},
{x:40.6643,y:-73.72016},
{x:40.92393,y:-72.70329},
{x:31.7588,y:-106.48908},
{x:47.84978,y:-122.21917},
{x:40.70011,y:-111.89376},
{x:40.64697,y:-73.79114},
{x:39.62509,y:-105.02401},
{x:45.69686,y:-111.06344},
{x:40.22015,y:-75.14051},
{x:38.81667,y:-77.64352},
{x:42.35662,y:-71.05042},
{x:33.5633,y:-86.75076},
{x:27.96009,y:-82.74663},
{x:33.46708,y:-111.91285},
{x:31.0545,y:-97.45391},
{x:32.72872,y:-117.22643},
{x:33.62942,y:-117.90686},
{x:33.24502,y:-84.29423},
{x:45.53384,y:-122.53735},
{x:34.2365,y:-84.45534},
{x:40.42493,y:-105.07557},
{x:38.72454,y:-77.15009},
{x:39.93374,y:-91.35683},
{x:40.91077,y:-73.12383},
{x:42.54603,y:-82.90454},
{x:37.21657,y:-93.27942},
{x:32.846,y:-96.84876},
{x:21.32754,y:-158.02131},
{x:32.67187,y:-114.44166},
{x:38.10046,y:-121.26601},
{x:35.26283,y:-81.1318},
{x:29.48526,y:-98.42973},
{x:39.83604,y:-74.92517},
{x:38.99703,y:-84.65616},
{x:40.71259,y:-73.95156},
{x:34.15786,y:-118.63477},
{x:32.83861,y:-97.18576},
{x:39.84243,y:-77.20326},
{x:32.72996,y:-97.41177},
{x:37.40278,y:-122.11228},
{x:33.38247,y:-111.92637},
{x:36.10878,y:-115.16962},
{x:43.57611,y:-89.78147},
{x:38.24486,y:-122.62656},
{x:33.47066,y:-86.77129},
{x:39.65447,y:-104.90013},
{x:42.70603,y:-83.29168},
{x:42.2351,y:-83.68017},
{x:42.54432,y:-83.18625},
{x:40.34375,y:-80.05698},
{x:41.88481,y:-87.6198},
{x:43.05063,y:-89.47171},
{x:38.33667,y:-81.71278},
{x:35.87475,y:-84.17811},
{x:35.44711,y:-84.61276},
{x:37.40187,y:-122.1119},
{x:35.40041,y:-82.57018},
{x:40.75959,y:-73.9736},
{x:40.98588,y:-111.90886},
{x:25.58984,y:-80.35857},
{x:42.05487,y:-80.09492},
{x:33.58386,y:-79.01279},
{x:37.60345,y:-122.39444},
{x:35.63992,y:-106.01324},
{x:27.49285,y:-82.47917},
{x:33.94249,y:-118.41051},
{x:25.88638,y:-80.12381},
{x:39.98946,y:-85.92148},
{x:30.18776,y:-81.62777},
{x:25.96522,y:-97.51232},
{x:33.4097,y:-111.92579},
{x:35.91035,y:-84.09155},
{x:47.84851,y:-122.21941},
{x:38.72037,y:-104.79649},
{x:35.90645,y:-79.01079},
{x:39.0578,y:-104.84304},
{x:35.81638,y:-90.66965},
{x:47.407,y:-120.29063},
{x:33.63179,y:-117.91246},
{x:33.68663,y:-116.26726},
{x:39.80693,y:-94.81567},
{x:34.55024,y:-112.43395},
{x:40.4309,y:-111.89618},
{x:35.61939,y:-106.02868},
{x:44.26253,y:-88.45673},
{x:40.52163,y:-74.23554},
{x:45.46571,y:-122.69764},
{x:29.98584,y:-95.34569},
{x:39.77288,y:-75.71388},
{x:33.51102,y:-112.08537},
{x:29.54169,y:-95.75094},
{x:20.88756,y:-156.4928},
{x:40.40498,y:-104.73324},
{x:40.07544,y:-80.87289},
{x:39.88312,y:-105.02763},
{x:39.02247,y:-96.81702},
{x:42.97115,y:-85.94258},
{x:33.37716,y:-111.79124},
{x:41.97893,y:-87.86131},
{x:38.94539,y:-104.79396},
{x:40.8213,y:-74.83684},
{x:47.71405,y:-122.33705},
{x:32.13796,y:-81.25183},
{x:42.06152,y:-71.86284},
{x:35.22025,y:-80.81068},
{x:29.74696,y:-95.37666},
{x:32.15363,y:-80.76314},
{x:41.35688,y:-72.21152},
{x:33.29756,y:-111.74372},
{x:41.99789,y:-87.66086},
{x:26.54786,y:-81.8059},
{x:32.02832,y:-102.14397},
{x:34.22098,y:-118.50214},
{x:36.19787,y:-83.35331},
{x:33.80856,y:-117.92186},
{x:36.1375,y:-95.92016},
{x:29.56833,y:-98.32717},
{x:42.34438,y:-71.09963},
{x:32.90852,y:-96.9607},
{x:35.65595,y:-78.3474},
{x:28.48037,y:-81.46221},
{x:30.18275,y:-97.84703},
{x:35.11786,y:-89.93713},
{x:34.66573,y:-79.00679},
{x:33.73913,y:-118.00662},
{x:47.54311,y:-122.01993},
{x:41.51154,y:-72.11208},
{x:32.80817,y:-116.9585},
{x:39.65503,y:-104.77616},
{x:40.75909,y:-111.83028},
{x:35.12578,y:-82.22339},
{x:42.39655,y:-71.08231},
{x:43.65634,y:-70.25209},
{x:42.48476,y:-71.1935},
{x:39.98428,y:-82.81634},
{x:32.73317,y:-117.20402},
{x:41.52435,y:-81.43433},
{x:30.50124,y:-90.48748},
{x:33.9461,y:-118.41076},
{x:35.66372,y:-78.84737},
{x:45.20114,y:-123.19962},
{x:28.41777,y:-81.58109},
{x:34.75781,y:-92.4498},
{x:40.52559,y:-74.43785},
{x:34.11782,y:-80.87681},
{x:41.45836,y:-82.01557},
{x:34.06754,y:-81.16702},
{x:47.68947,y:-122.34483},
{x:34.70009,y:-82.93755},
{x:36.84207,y:-76.0486},
{x:40.72678,y:-74.03796},
{x:40.98146,y:-74.08677},
{x:40.56962,y:-74.61619},
{x:29.72748,y:-95.43075},
{x:37.62816,y:-77.67204},
{x:36.01003,y:-78.92431},
{x:35.19528,y:-80.82662},
{x:39.03603,y:-84.23285},
{x:35.95653,y:-86.8141},
{x:35.2249,y:-80.84854},
{x:39.38764,y:-107.08311},
{x:41.89399,y:-87.62245},
{x:34.221,y:-77.82222},
{x:42.48835,y:-83.53533},
{x:42.71104,y:-83.03266},
{x:42.20449,y:-83.25638},
{x:33.94619,y:-84.62611},
{x:41.08785,y:-111.98048},
{x:42.52918,y:-83.1068},
{x:34.67516,y:-118.14755},
{x:34.14227,y:-118.07323},
{x:39.73506,y:-75.00732},
{x:37.29991,y:-107.87055},
{x:39.02438,y:-77.14688},
{x:40.54267,y:-80.00828},
{x:37.35021,y:-79.18203},
{x:35.10577,y:-79.01065},
{x:33.57457,y:-117.1785},
{x:37.44785,y:-122.12624},
{x:33.88146,y:-117.8851},
{x:41.78114,y:-87.86761},
{x:38.92872,y:-84.54795},
{x:32.87379,y:-80.01802},
{x:40.25386,y:-103.80973},
{x:40.57505,y:-74.16699},
{x:41.90443,y:-87.64401},
{x:33.55334,y:-85.07518},
{x:37.78207,y:-122.44708},
{x:32.64829,y:-117.05869},
{x:33.82868,y:-118.14297},
{x:47.05437,y:-122.29393},
{x:31.79324,y:-106.26716},
{x:34.67718,y:-82.83422},
{x:41.21398,y:-96.10958},
{x:47.23913,y:-122.48192},
{x:34.18353,y:-118.88736},
{x:30.58594,y:-96.28385},
{x:21.46814,y:-158.00176},
{x:34.168,y:-118.32194},
{x:41.65577,y:-88.44157},
{x:39.6528,y:-105.01295},
{x:36.81348,y:-76.11109},
{x:40.79272,y:-73.99846},
{x:35.12082,y:-78.88058},
{x:33.49543,y:-112.02114},
{x:41.54931,y:-83.59938},
{x:38.91595,y:-77.48768},
{x:41.45041,y:-91.03633},
{x:36.10402,y:-95.91145},
{x:33.1771,y:-96.73509},
{x:39.16436,y:-84.51484},
{x:36.33004,y:-86.59417},
{x:33.87783,y:-117.65991},
{x:29.78428,y:-95.70541},
{x:35.60677,y:-77.3683},
{x:42.53453,y:-83.11066},
{x:30.26587,y:-97.74644},
{x:32.83471,y:-117.18737},
{x:33.63736,y:-117.86206},
{x:33.95701,y:-80.38593},
{x:38.80044,y:-97.61191},
{x:41.61246,y:-88.20148},
{x:43.16638,y:-89.26959},
{x:42.91958,y:-88.10823},
{x:28.47187,y:-81.4692},
{x:25.72003,y:-80.2791},
{x:38.45826,y:-122.73049},
{x:40.54656,y:-80.01788},
{x:44.26371,y:-121.17769},
{x:33.81089,y:-117.91882},
{x:39.24844,y:-94.57235},
{x:32.75429,y:-117.22326},
{x:42.38642,y:-87.95704},
{x:42.23928,y:-89.02862},
{x:33.86015,y:-117.94213},
{x:42.18698,y:-71.30544},
{x:40.73922,y:-73.2455},
{x:33.81842,y:-118.06222},
{x:33.87736,y:-117.84176},
{x:48.21016,y:-101.31975},
{x:34.14996,y:-118.43928},
{x:30.07203,y:-95.24323},
{x:39.71525,y:-121.79483},
{x:42.59146,y:-71.11711},
{x:42.05709,y:-80.0889},
{x:47.82094,y:-122.31741},
{x:34.11487,y:-118.18333},
{x:43.03168,y:-82.45566},
{x:39.0336,y:-77.07458},
{x:41.80834,y:-88.0139},
{x:39.63209,y:-106.4163},
{x:37.95521,y:-122.4913},
{x:34.03525,y:-117.75606},
{x:36.2191,y:-115.24921},
{x:37.78908,y:-122.27975},
{x:33.4171,y:-111.73501},
{x:39.64674,y:-84.1099},
{x:43.55535,y:-116.57257},
{x:41.62929,y:-81.43007},
{x:28.35662,y:-80.69706},
{x:40.86063,y:-73.89027},
{x:41.56611,y:-85.86015},
{x:32.21607,y:-80.7316},
{x:31.46417,y:-100.44507},
{x:31.77199,y:-106.37555},
{x:25.73262,y:-80.23286},
{x:39.11513,y:-77.20706},
{x:38.08912,y:-97.89597},
{x:29.92546,y:-95.19711},
{x:32.91945,y:-97.11783},
{x:34.05879,y:-118.24034},
{x:45.40866,y:-122.62556},
{x:34.42569,y:-118.42518},
{x:39.95482,y:-75.18583},
{x:40.07189,y:-105.20095},
{x:39.58749,y:-104.6874},
{x:30.69746,y:-88.1788},
{x:45.55287,y:-94.19216},
{x:34.02426,y:-118.49168},
{x:32.96244,y:-115.54832},
{x:35.24337,y:-81.40527},
{x:25.92855,y:-80.19656},
{x:35.31475,y:-81.18544},
{x:43.08961,y:-78.98778},
{x:37.24639,y:-121.80272},
{x:42.03936,y:-88.04882},
{x:32.93652,y:-80.14221},
{x:35.71548,y:-78.6389},
{x:42.4797,y:-71.02533},
{x:32.79219,y:-80.031},
{x:34.9391,y:-80.98005},
{x:42.85605,y:-73.77069},
{x:42.29502,y:-71.77364},
{x:42.3582,y:-71.60795},
{x:43.10174,y:-73.74562},
{x:42.15973,y:-71.50121},
{x:41.94182,y:-71.34886},
{x:41.45289,y:-74.36889},
{x:29.87439,y:-81.32358},
{x:35.57564,y:-82.51961},
{x:41.58424,y:-83.66673},
{x:26.30513,y:-98.19181},
{x:32.23518,y:-110.8242},
{x:47.66689,y:-122.37657},
{x:28.37038,y:-81.51972},
{x:42.69818,y:-73.8917},
{x:19.67273,y:-156.01547},
{x:38.71826,y:-104.69949},
{x:41.2452,y:-111.97048},
{x:28.82455,y:-81.64087},
{x:34.12951,y:-117.97116},
{x:39.99277,y:-83.04359},
{x:28.06276,y:-82.7063},
{x:39.50739,y:-84.75107},
{x:30.32978,y:-81.54702},
{x:28.0887,y:-82.5036},
{x:41.42949,y:-82.20335},
{x:47.66166,y:-122.29936},
{x:32.71033,y:-117.17057},
{x:40.68966,y:-73.40633},
{x:45.16786,y:-93.23588},
{x:37.3363,y:-122.03466},
{x:26.03306,y:-80.14177},
{x:42.21716,y:-71.54125},
{x:39.92897,y:-82.78748},
{x:29.599,y:-98.56279},
{x:29.42514,y:-95.22637},
{x:32.62841,y:-97.32044},
{x:40.78204,y:-73.09795},
{x:29.73695,y:-95.5266},
{x:32.81495,y:-96.82641},
{x:34.45102,y:-118.62466},
{x:39.03872,y:-77.05081},
{x:29.59918,y:-95.26581},
{x:29.50451,y:-95.0437},
{x:40.70481,y:-74.00723},
{x:42.03915,y:-92.91091},
{x:43.14941,y:-93.23538},
{x:41.665,y:-93.69911},
{x:40.19757,y:-92.56986},
{x:47.73733,y:-122.63558},
{x:40.75052,y:-73.98902},
{x:34.11086,y:-117.53019},
{x:40.29187,y:-74.05331},
{x:41.47193,y:-71.96012},
{x:40.74645,y:-74.43066},
{x:29.5829,y:-95.38981},
{x:37.31988,y:-121.97308},
{x:30.34096,y:-97.96674},
{x:35.86157,y:-86.44439},
{x:33.96054,y:-117.59447},
{x:47.54146,y:-122.01968},
{x:37.34934,y:-108.57747},
{x:40.73654,y:-73.97837},
{x:37.31619,y:-76.73461},
{x:42.27295,y:-83.73758},
{x:40.46205,y:-74.29515},
{x:35.2384,y:-114.03584},
{x:33.71276,y:-112.14152},
{x:32.58298,y:-117.03445},
{x:38.92268,y:-121.05897},
{x:30.33255,y:-95.47966},
{x:34.41838,y:-118.50297},
{x:29.86361,y:-95.721},
{x:37.37884,y:-122.07074},
{x:32.86445,y:-96.66029},
{x:39.76797,y:-84.05322},
{x:40.71985,y:-74.00495},
{x:36.91448,y:-76.27066},
{x:34.83928,y:-87.63595},
{x:39.32577,y:-77.34971},
{x:38.65077,y:-121.12073},
{x:38.93848,y:-77.02497},
{x:39.61229,y:-104.98962},
{x:33.63812,y:-112.11985},
{x:32.39645,y:-110.99395},
{x:33.32171,y:-111.93005},
{x:26.6189,y:-80.13667},
{x:28.60333,y:-81.19892},
{x:39.96776,y:-75.29607},
{x:40.33128,y:-76.86219},
{x:41.88461,y:-87.63734},
{x:33.50913,y:-112.08249},
{x:21.42017,y:-157.80503},
{x:47.48886,y:-121.79408},
{x:40.88444,y:-111.89048},
{x:39.60193,y:-84.23067},
{x:44.82384,y:-87.40283},
{x:33.2956,y:-111.89977},
{x:33.5404,y:-111.88715},
{x:34.21267,y:-118.46007},
{x:42.95805,y:-88.1088},
{x:42.99133,y:-87.90956},
{x:45.05037,y:-93.06201},
{x:45.27457,y:-92.99883},
{x:33.57361,y:-111.7185},
{x:44.81673,y:-92.93031},
{x:38.99296,y:-94.70382},
{x:61.21112,y:-149.73684},
{x:40.18032,y:-75.13399},
{x:40.70652,y:-74.40438},
{x:33.30518,y:-111.86312},
{x:39.13639,y:-77.69323},
{x:41.40065,y:-73.96884},
{x:33.83171,y:-118.07409},
{x:33.46139,y:-94.04283},
{x:42.49733,y:-83.44113},
{x:41.93268,y:-87.64506},
{x:33.95828,y:-118.41842},
{x:33.78781,y:-117.83637},
{x:44.95103,y:-93.02734},
{x:33.72808,y:-117.83744},
{x:37.40301,y:-121.93313},
{x:33.6417,y:-112.01123},
{x:30.2424,y:-97.72741},
{x:40.65809,y:-74.29121},
{x:32.89621,y:-96.72063},
{x:33.51118,y:-112.02974},
{x:34.42666,y:-118.42525},
{x:29.81459,y:-95.41095},
{x:42.2517,y:-87.8399},
{x:32.95299,y:-117.23188},
{x:33.76029,y:-116.35803},
{x:33.72466,y:-117.83502},
{x:33.99908,y:-118.46149},
{x:34.829,y:-82.3046},
{x:47.2518,y:-122.29305},
{x:32.45931,y:-86.39107},
{x:36.9795,y:-122.0211},
{x:30.242,y:-97.75949},
{x:39.66236,y:-84.10291},
{x:41.92005,y:-87.63697},
{x:38.02201,y:-84.41713},
{x:39.2772,y:-76.56653},
{x:25.67279,y:-80.32266},
{x:32.96904,y:-96.60385},
{x:40.07389,y:-75.43208},
{x:41.30736,y:-73.87548},
{x:38.56316,y:-121.42373},
{x:38.80888,y:-77.08448},
{x:37.93585,y:-107.84783},
{x:34.12552,y:-93.05979},
{x:27.94634,y:-82.45786},
{x:33.3889,y:-111.68467},
{x:32.18582,y:-80.72023},
{x:37.37911,y:-122.11879},
{x:36.27985,y:-115.26579},
{x:34.16272,y:-118.52135},
{x:40.77946,y:-73.95369},
{x:33.79753,y:-118.30716},
{x:38.00572,y:-121.3532},
{x:34.2348,y:-118.53579},
{x:44.81481,y:-93.21436},
{x:45.3169,y:-93.56164},
{x:39.81383,y:-86.20243},
{x:41.97545,y:-87.86782},
{x:29.49123,y:-98.56947},
{x:29.64279,y:-98.48126},
{x:40.86572,y:-73.92728},
{x:38.48069,y:-121.51666},
{x:29.60022,y:-97.94935},
{x:40.82792,y:-73.40673},
{x:34.02761,y:-117.59374},
{x:34.04486,y:-118.5516},
{x:41.98864,y:-87.86134},
{x:35.18606,y:-106.66495},
{x:39.40771,y:-104.85598},
{x:34.19068,y:-118.62662},
{x:38.19988,y:-85.67023},
{x:44.08634,y:-93.25039},
{x:38.41409,y:-90.39546},
{x:38.77736,y:-90.69991},
{x:44.86223,y:-93.54292},
{x:42.65285,y:-71.32651},
{x:41.94794,y:-87.68858},
{x:39.77662,y:-86.18367},
{x:34.16441,y:-118.25638},
{x:21.27708,y:-157.78501},
{x:39.12895,y:-94.82562},
{x:47.34386,y:-122.31183},
{x:34.14899,y:-118.13148},
{x:30.37803,y:-86.3102},
{x:35.95834,y:-85.04561},
{x:40.3537,y:-94.88586},
{x:38.80133,y:-121.20422},
{x:33.87951,y:-117.96101},
{x:40.93087,y:-72.67919},
{x:40.03594,y:-74.82203},
{x:47.57418,y:-122.17289},
{x:32.66134,y:-97.42113},
{x:42.37937,y:-71.26122},
{x:34.17163,y:-118.3968},
{x:41.88608,y:-87.62407},
{x:33.68551,y:-117.81321},
{x:36.61199,y:-93.22888},
{x:37.75922,y:-121.95793},
{x:40.81391,y:-73.98209},
{x:40.89869,y:-74.04039},
{x:40.64177,y:-74.42185},
{x:40.67414,y:-75.13739},
{x:38.7757,y:-77.17364},
{x:39.1558,y:-78.16989},
{x:42.13437,y:-87.75955},
{x:21.27752,y:-157.7868},
{x:20.88169,y:-156.45514},
{x:21.3854,y:-157.94166},
{x:21.38366,y:-157.94492},
{x:21.45878,y:-158.01667},
{x:21.40179,y:-157.79915},
{x:19.64081,y:-155.99233},
{x:21.30994,y:-157.81034},
{x:21.27714,y:-157.7055},
{x:21.39335,y:-157.74092},
{x:21.30821,y:-157.86222},
{x:21.27993,y:-157.82695},
{x:21.27905,y:-157.81382},
{x:20.75222,y:-156.44873},
{x:20.73428,y:-156.45258},
{x:21.27166,y:-157.8227},
{x:20.88614,y:-156.68361},
{x:20.02055,y:-155.6673},
{x:21.29052,y:-157.8418},
{x:21.37833,y:-157.93025},
{x:19.7195,y:-155.08251},
{x:20.88808,y:-156.47492},
{x:21.3359,y:-157.91568},
{x:19.69892,y:-155.06163},
{x:20.8377,y:-156.34214},
{x:21.97059,y:-159.37957},
{x:21.30759,y:-157.8606},
{x:21.39951,y:-158.00685},
{x:21.29448,y:-157.84213},
{x:21.45186,y:-158.0059},
{x:21.42,y:-157.80265},
{x:21.28326,y:-157.71075},
{x:22.06284,y:-159.3215},
{x:19.64587,y:-155.98834},
{x:21.38754,y:-158.03385},
{x:21.36636,y:-157.93003},
{x:21.37836,y:-157.72884},
{x:21.33567,y:-157.86817},
{x:21.34472,y:-157.92876},
{x:21.427,y:-158.00165},
{x:21.32914,y:-158.08668},
{x:21.38907,y:-157.95272},
{x:21.27952,y:-157.83014},
{x:19.9142,y:-155.88011},
{x:21.87901,y:-159.45883},
{x:21.39931,y:-157.97416},
{x:21.43505,y:-158.18436},
{x:34.16682,y:-118.58851},
{x:41.88007,y:-87.635},
{x:33.53743,y:-112.09836},
{x:40.12538,y:-75.11725},
{x:40.13068,y:-75.45312},
{x:40.039,y:-75.62894},
{x:36.13704,y:-80.27946},
{x:33.79475,y:-79.01041},
{x:36.10941,y:-115.17736},
{x:36.10251,y:-115.17354},
{x:34.82559,y:-82.54469},
{x:38.50684,y:-90.3265},
{x:32.43412,y:-90.14926},
{x:34.00389,y:-117.65124},
{x:40.23139,y:-74.04494},
{x:40.79737,y:-76.87593},
{x:26.49183,y:-81.78672},
{x:41.65116,y:-91.50077},
{x:38.93539,y:-77.07306},
{x:39.46071,y:-76.63493},
{x:41.78003,y:-88.24014},
{x:39.27172,y:-84.37626},
{x:39.36153,y:-84.46144},
{x:42.49483,y:-71.22551},
{x:26.31841,y:-80.15168},
{x:25.8139,y:-80.1341},
{x:28.06703,y:-82.4266},
{x:35.85568,y:-78.58058},
{x:42.8563,y:-106.32539},
{x:39.63786,y:-104.82854},
{x:34.07455,y:-118.07},
{x:40.59096,y:-74.49861},
{x:32.78329,y:-79.99543},
{x:30.10276,y:-95.23794},
{x:43.59198,y:-70.33035},
{x:45.60592,y:-121.20054},
{x:33.57389,y:-81.76791},
{x:41.4074,y:-81.84213},
{x:42.95892,y:-87.91996},
{x:32.23877,y:-107.99265},
{x:42.94545,y:-85.5541},
{x:41.34772,y:-89.12722},
{x:40.03549,y:-75.62798},
{x:41.97523,y:-91.60958},
{x:42.48885,y:-96.36328},
{x:41.0117,y:-95.91024},
{x:39.24398,y:-77.27223},
{x:40.88993,y:-74.24935},
{x:44.93999,y:-92.90399},
{x:35.20325,y:-89.76207},
{x:25.75737,y:-80.37621},
{x:40.55148,y:-112.29746},
{x:41.67342,y:-83.71043},
{x:25.81223,y:-80.38239},
{x:33.43416,y:-112.55825},
{x:40.68931,y:-73.98088},
{x:44.09848,y:-103.18296},
{x:43.03563,y:-88.07139},
{x:42.10956,y:-87.87476},
{x:42.0526,y:-87.98237},
{x:32.23104,y:-110.9487},
{x:39.26712,y:-94.57649},
{x:38.91025,y:-92.33116},
{x:44.77588,y:-93.41428},
{x:44.16709,y:-93.95215},
{x:36.16265,y:-86.51455},
{x:39.37985,y:-84.22171},
{x:45.05709,y:-93.14787},
{x:41.83449,y:-88.02155},
{x:39.01521,y:-94.72724},
{x:41.85061,y:-87.80218},
{x:40.08908,y:-75.09242},
{x:40.64625,y:-73.77569},
{x:34.36469,y:-89.53123},
{x:34.47736,y:-114.32215},
{x:40.00719,y:-105.27511},
{x:39.27669,y:-74.57897},
{x:33.45035,y:-111.80334},
{x:33.80398,y:-118.01314},
{x:38.93734,y:-77.08899},
{x:33.30369,y:-111.99877},
{x:41.14491,y:-102.98939},
{x:41.412,y:-81.83846},
{x:39.03815,y:-77.05264},
{x:39.02694,y:-95.74457},
{x:34.08976,y:-118.34471},
{x:32.0659,y:-81.09266},
{x:30.61628,y:-96.34603},
{x:41.70502,y:-93.62361},
{x:41.83659,y:-90.19148},
{x:40.54304,y:-80.19252},
{x:38.79462,y:-77.00122},
{x:40.65459,y:-74.3027},
{x:40.70259,y:-73.81859},
{x:47.07134,y:-122.41787},
{x:41.4582,y:-81.95042},
{x:42.67349,y:-82.97728},
{x:26.26131,y:-98.16482},
{x:33.4541,y:-88.80697},
{x:45.13566,y:-93.37001},
{x:35.1301,y:-89.94784},
{x:37.90212,y:-122.06498},
{x:33.43961,y:-117.62772},
{x:40.4107,y:-79.91005},
{x:34.14781,y:-118.79363},
{x:38.4572,y:-122.72989},
{x:36.83418,y:-119.91256},
{x:38.80407,y:-121.20908},
{x:32.00629,y:-81.11339},
{x:20.88427,y:-156.68166},
{x:40.4382,y:-79.9984},
{x:35.87464,y:-78.78949},
{x:30.44496,y:-84.30018},
{x:33.59771,y:-112.15003},
{x:30.38911,y:-87.0687},
{x:36.87951,y:-76.04229},
{x:41.89331,y:-87.61757},
{x:28.44951,y:-81.4698},
{x:46.81699,y:-92.07773},
{x:37.25082,y:-121.80279},
{x:39.88377,y:-104.97596},
{x:42.9732,y:-73.795},
{x:43.07269,y:-73.79283},
{x:41.60858,y:-83.71013},
{x:34.19661,y:-79.75974},
{x:37.24864,y:-121.80313},
{x:33.33418,y:-111.91282},
{x:41.79849,y:-87.74322},
{x:39.75514,y:-75.04618},
{x:40.94311,y:-74.12925},
{x:38.36068,y:-122.72253},
{x:35.38389,y:-94.37575},
{x:35.92299,y:-84.04801},
{x:40.10835,y:-88.22914},
{x:34.31511,y:-83.79791},
{x:42.08973,y:-71.42192},
{x:42.75352,y:-73.75732},
{x:33.01934,y:-117.11268},
{x:32.90505,y:-97.0358},
{x:33.9843,y:-118.443},
{x:34.02044,y:-118.35481},
{x:40.341,y:-76.00166},
{x:41.80227,y:-72.2415},
{x:40.75762,y:-74.00213},
{x:39.9886,y:-104.75025},
{x:41.92197,y:-87.64886},
{x:33.12143,y:-117.31391},
{x:33.87684,y:-118.21794},
{x:38.95643,y:-94.68874},
{x:41.64467,y:-93.50112},
{x:41.89158,y:-87.627},
{x:41.07389,y:-85.26969},
{x:44.91009,y:-93.35487},
{x:38.95594,y:-119.94232},
{x:40.91654,y:-74.07691},
{x:38.904,y:-77.0452},
{x:40.85581,y:-73.98284},
{x:40.04574,y:-74.22438},
{x:38.68532,y:-121.33946},
{x:35.84403,y:-78.8852},
{x:41.70298,y:-122.64595},
{x:28.3713,y:-81.51527},
{x:33.98789,y:-118.25716},
{x:33.76097,y:-118.11602},
{x:26.44019,y:-80.07844},
{x:26.09537,y:-80.25196},
{x:32.36052,y:-86.16297},
{x:34.09852,y:-118.34843},
{x:30.22611,y:-93.36132},
{x:42.30906,y:-122.85409},
{x:29.04817,y:-95.45597},
{x:37.65184,y:-77.40203},
{x:36.0127,y:-84.26817},
{x:37.65296,y:-77.62552},
{x:34.07124,y:-117.43532},
{x:33.58226,y:-111.98095},
{x:30.06691,y:-95.18713},
{x:42.44611,y:-83.12077},
{x:40.82436,y:-73.83752},
{x:41.06039,y:-111.94354},
{x:40.87837,y:-79.95082},
{x:32.76958,y:-117.14745},
{x:26.56242,y:-81.95618},
{x:30.1665,y:-81.63168},
{x:33.49015,y:-117.69942},
{x:33.72903,y:-117.79017},
{x:47.63553,y:-122.51725},
{x:38.90121,y:-104.81818},
{x:40.81316,y:-73.4094},
{x:33.6248,y:-111.9446},
{x:33.3623,y:-111.90924},
{x:33.84446,y:-112.13374},
{x:33.06764,y:-112.04435},
{x:33.91551,y:-117.88626},
{x:37.25836,y:-79.94099},
{x:45.56851,y:-122.60036},
{x:40.68539,y:-75.15151},
{x:33.60066,y:-112.2888},
{x:33.7525,y:-111.99055},
{x:37.25343,y:-77.32642},
{x:39.44824,y:-77.98782},
{x:30.26067,y:-97.74693},
{x:33.6113,y:-112.36151},
{x:39.14749,y:-84.44306},
{x:33.27547,y:-111.86069},
{x:40.35426,y:-80.11423},
{x:38.90383,y:-77.02424},
{x:29.66395,y:-95.7453},
{x:28.46531,y:-81.47359},
{x:41.87658,y:-88.06583},
{x:39.10265,y:-84.51089},
{x:33.22619,y:-96.98653},
{x:43.4821,y:-112.01553},
{x:34.01207,y:-118.48304},
{x:35.87845,y:-106.29368},
{x:41.93982,y:-87.65078},
{x:35.01628,y:-80.80167},
{x:39.09999,y:-84.51283},
{x:45.01111,y:-93.17761},
{x:46.58515,y:-120.54344},
{x:32.97553,y:-80.06127},
{x:34.88235,y:-83.95764},
{x:32.7717,y:-117.13971},
{x:34.75221,y:-83.04468},
{x:44.94827,y:-93.16671},
{x:42.27731,y:-71.69752},
{x:40.24746,y:-77.03093},
{x:40.55434,y:-84.38465},
{x:42.35724,y:-72.55308},
{x:39.14549,y:-84.37662},
{x:32.81196,y:-96.73037},
{x:36.02164,y:-115.08925},
{x:34.96108,y:-89.9349},
{x:37.52573,y:-77.60839},
{x:47.38725,y:-122.20446},
{x:39.7532,y:-105.00291},
{x:32.74228,y:-103.15922},
{x:42.15405,y:-88.13629},
{x:32.46877,y:-99.70978},
{x:37.94412,y:-121.73941},
{x:45.58697,y:-122.39983},
{x:41.93289,y:-87.65416},
{x:34.25827,y:-119.20898},
{x:33.28154,y:-111.97001},
{x:26.2415,y:-80.24866},
{x:27.24158,y:-80.27482},
{x:29.1617,y:-82.17342},
{x:32.2998,y:-95.30076},
{x:42.06335,y:-87.93558},
{x:42.01217,y:-87.73653},
{x:41.94277,y:-87.67098},
{x:41.60381,y:-87.85343},
{x:41.89018,y:-87.63132},
{x:43.59713,y:-116.51367},
{x:42.34846,y:-71.1341},
{x:38.67667,y:-121.74626},
{x:38.3674,y:-121.96211},
{x:34.79108,y:-77.40508},
{x:34.34623,y:-119.06969},
{x:40.70204,y:-74.01203},
{x:42.59871,y:-73.71083},
{x:36.81598,y:-76.1098},
{x:36.11734,y:-115.17192},
{x:26.93467,y:-80.08825},
{x:25.73306,y:-80.30388},
{x:25.8872,y:-80.16502},
{x:27.03645,y:-82.21336},
{x:24.56293,y:-81.77605},
{x:40.00227,y:-75.27467},
{x:30.16442,y:-95.45669},
{x:34.21626,y:-119.05904},
{x:32.98155,y:-117.25125},
{x:34.93927,y:-81.99063},
{x:37.41758,y:-121.87578},
{x:44.98418,y:-93.3791},
{x:33.81032,y:-117.91801},
{x:32.78411,y:-79.93956},
{x:41.87796,y:-87.63395},
{x:36.51455,y:-121.43684},
{x:30.56123,y:-97.68066},
{x:41.89262,y:-87.62836},
{x:30.30153,y:-97.69994},
{x:30.49835,y:-97.72281},
{x:39.87658,y:-75.39564},
{x:29.97986,y:-95.51367},
{x:34.07038,y:-117.14059},
{x:35.4828,y:-82.55448},
{x:32.55059,y:-93.70736},
{x:41.02062,y:-80.64606},
{x:40.44589,y:-80.18331},
{x:36.29996,y:-82.38024},
{x:35.18658,y:-101.93064},
{x:41.93891,y:-88.11718},
{x:41.8795,y:-87.63396},
{x:33.26769,y:-116.95585},
{x:47.614,y:-122.32811},
{x:44.02797,y:-123.09106},
{x:35.22301,y:-80.93983},
{x:42.83777,y:-108.74636},
{x:32.77588,y:-117.15534},
{x:41.59768,y:-93.75353},
{x:41.20405,y:-96.06345},
{x:40.86128,y:-96.67805},
{x:47.60873,y:-122.32165},
{x:36.11774,y:-115.17543},
{x:38.85271,y:-94.75912},
{x:33.99663,y:-83.31605},
{x:28.35723,y:-81.56044},
{x:33.75993,y:-117.85097},
{x:42.04047,y:-87.79721},
{x:39.23293,y:-84.37642},
{x:32.79789,y:-96.79239},
{x:34.01065,y:-118.49155},
{x:32.77946,y:-96.7985},
{x:48.78886,y:-122.5111},
{x:33.30488,y:-111.78792},
{x:29.6641,y:-95.11439},
{x:42.0804,y:-87.79809},
{x:42.0865,y:-87.70033},
{x:41.75016,y:-88.01312},
{x:44.92505,y:-92.96069},
{x:33.66113,y:-112.35361},
{x:34.06288,y:-118.35093},
{x:37.50131,y:-77.52838},
{x:34.15887,y:-118.37125},
{x:32.66061,y:-117.1055},
{x:40.87887,y:-81.433},
{x:40.7523,y:-73.97522},
{x:41.90473,y:-87.63487},
{x:46.60483,y:-122.90793},
{x:41.30928,y:-105.55644},
{x:48.19893,y:-114.31421},
{x:46.8509,y:-114.01537},
{x:30.11333,y:-95.55491},
{x:45.15105,y:-122.8299},
{x:38.00572,y:-121.75278},
{x:42.59599,y:-114.45369},
{x:34.61123,y:-120.1869},
{x:43.03728,y:-88.13622},
{x:30.09187,y:-95.63431},
{x:40.72448,y:-111.86471},
{x:41.68208,y:-91.59162},
{x:41.8781,y:-87.62588},
{x:39.27715,y:-76.56356},
{x:40.51491,y:-107.55378},
{x:34.36471,y:-89.55377},
{x:34.0247,y:-118.2202},
{x:45.36666,y:-122.83577},
{x:33.16747,y:-96.89114},
{x:42.15234,y:-87.83574},
{x:45.50326,y:-122.67187},
{x:33.55635,y:-117.68562},
{x:39.79477,y:-75.97685},
{x:39.82935,y:-75.08996},
{x:42.01773,y:-88.14416},
{x:41.8859,y:-87.62886},
{x:42.56301,y:-83.18399},
{x:41.98985,y:-87.73387},
{x:32.67685,y:-117.03837},
{x:42.45857,y:-92.32787},
{x:38.94867,y:-77.44202},
{x:41.14881,y:-81.50802},
{x:29.97932,y:-95.56752},
{x:34.06151,y:-118.29124},
{x:40.95805,y:-81.46424},
{x:26.72185,y:-80.08759},
{x:27.38439,y:-82.45284},
{x:26.34383,y:-81.80648},
{x:33.01033,y:-96.57355},
{x:32.95049,y:-96.73566},
{x:38.67006,y:-121.16172},
{x:42.35345,y:-71.04652},
{x:43.11867,y:-87.90647},
{x:35.99272,y:-75.65155},
{x:47.47496,y:-122.33219},
{x:41.8101,y:-87.98635},
{x:37.63729,y:-77.51469},
{x:36.87203,y:-76.42026},
{x:36.82773,y:-76.3368},
{x:29.91908,y:-95.68965},
{x:33.08182,y:-117.2333},
{x:40.71794,y:-73.95817},
{x:32.90382,y:-117.11392},
{x:40.30176,y:-79.49573},
{x:36.05355,y:-115.16904},
{x:41.79975,y:-87.5876},
{x:42.06071,y:-87.74903},
{x:38.13195,y:-122.25552},
{x:34.08692,y:-118.33885},
{x:35.98797,y:-96.01375},
{x:36.13429,y:-96.11425},
{x:41.87903,y:-87.63638},
{x:42.08231,y:-87.98153},
{x:37.70581,y:-121.85211},
{x:33.75991,y:-117.95462},
{x:32.77219,y:-117.25098},
{x:33.668,y:-117.88332},
{x:40.77003,y:-73.9185},
{x:45.45872,y:-98.4422},
{x:41.88514,y:-87.62147},
{x:34.00324,y:-118.4337},
{x:42.32821,y:-122.87254},
{x:29.93601,y:-90.12058},
{x:41.50124,y:-81.5941},
{x:29.70248,y:-95.68721},
{x:40.24622,y:-77.01473},
{x:40.30372,y:-80.09719},
{x:38.86611,y:-77.36052},
{x:43.06502,y:-87.87829},
{x:41.94354,y:-87.64515},
{x:47.20061,y:-121.99047},
{x:33.56116,y:-86.75314},
{x:35.20507,y:-106.67736},
{x:26.07304,y:-80.13841},
{x:26.07351,y:-80.14062},
{x:26.07323,y:-80.14356},
{x:34.04489,y:-118.467},
{x:45.22039,y:-85.01506},
{x:40.73218,y:-73.86652},
{x:33.54426,y:-111.90527},
{x:30.27178,y:-89.7599},
{x:21.27889,y:-157.75299},
{x:47.49428,y:-111.25279},
{x:47.59619,y:-122.62997},
{x:45.05431,y:-93.36459},
{x:35.16874,y:-80.97049},
{x:38.88178,y:-77.22955},
{x:42.49998,y:-83.30817},
{x:42.56454,y:-83.10993},
{x:40.45525,y:-86.91748},
{x:36.19362,y:-86.29455},
{x:30.44499,y:-84.29132},
{x:42.30518,y:-83.24695},
{x:42.49387,y:-83.47684},
{x:41.93696,y:-88.08219},
{x:40.70098,y:-111.79935},
{x:36.28513,y:-115.17889},
{x:39.65582,y:-104.98907},
{x:38.85594,y:-77.04386},
{x:39.96286,y:-86.12565},
{x:34.22353,y:-118.87833},
{x:41.91799,y:-87.6535},
{x:39.89784,y:-85.99393},
{x:38.8295,y:-77.30726},
{x:42.33724,y:-71.10568},
{x:32.6058,y:-85.48874},
{x:41.23894,y:-85.82735},
{x:39.15372,y:-86.49561},
{x:29.5098,y:-98.55413},
{x:40.88752,y:-72.38842},
{x:42.2221,y:-71.02417},
{x:48.00405,y:-122.20018},
{x:39.73881,y:-105.19628},
{x:42.5469,y:-83.21483},
{x:29.39215,y:-100.90474},
{x:33.81018,y:-118.10552},
{x:37.96144,y:-122.32684},
{x:37.69905,y:-121.8729},
{x:36.67064,y:-121.64152},
{x:35.86644,y:-86.38861},
{x:42.71239,y:-71.443},
{x:42.59901,y:-83.16946},
{x:41.9669,y:-87.80684},
{x:34.94973,y:-82.27322},
{x:42.1388,y:-88.02425},
{x:41.01527,y:-92.43979},
{x:39.75558,y:-105.02427},
{x:34.19563,y:-84.79122},
{x:40.88213,y:-111.88388},
{x:36.85574,y:-75.98111},
{x:40.22454,y:-74.09429},
{x:42.52394,y:-83.19298},
{x:29.99913,y:-95.19381},
{x:33.97648,y:-118.41757},
{x:42.95425,y:-78.69647},
{x:28.47329,y:-81.46478},
{x:41.17939,y:-85.1332},
{x:37.23314,y:-80.42094},
{x:40.62084,y:-89.46855},
{x:41.88866,y:-87.63453},
{x:41.43143,y:-81.39225},
{x:42.39008,y:-82.91687},
{x:38.96958,y:-76.93685},
{x:41.0508,y:-74.06082},
{x:38.87037,y:-76.84969},
{x:39.6266,y:-77.76917},
{x:39.52754,y:-76.35281},
{x:42.20826,y:-71.15168},
{x:39.00355,y:-94.63009},
{x:40.11158,y:-75.21094},
{x:39.60804,y:-119.69717},
{x:38.57743,y:-90.40685},
{x:38.59329,y:-90.49739},
{x:41.41045,y:-73.45575},
{x:39.5308,y:-107.3257},
{x:38.38482,y:-121.93416},
{x:41.88593,y:-87.62481},
{x:42.48799,y:-83.14445},
{x:40.44133,y:-80.15149},
{x:40.96272,y:-81.46774},
{x:40.34713,y:-80.04694},
{x:41.79635,y:-72.71496},
{x:41.50178,y:-81.5384},
{x:41.7196,y:-87.7494},
{x:35.10543,y:-84.00962},
{x:43.07566,y:-89.38659},
{x:33.05203,y:-109.32949},
{x:42.70871,y:-73.7757},
{x:38.78653,y:-76.79446},
{x:41.23305,y:-96.05143},
{x:35.479,y:-81.21985},
{x:40.51346,y:-75.78417},
{x:39.10732,y:-76.73788},
{x:41.30176,y:-81.83519},
{x:41.51156,y:-87.84898},
{x:31.54789,y:-97.11318},
{x:32.44073,y:-80.68685},
{x:35.17021,y:-89.79367},
{x:40.21024,y:-74.25772},
{x:41.17845,y:-73.18166},
{x:34.04697,y:-117.24314},
{x:33.77524,y:-117.87068},
{x:41.50155,y:-81.62174},
{x:44.98136,y:-93.23532},
{x:42.51568,y:-83.18336},
{x:42.04761,y:-88.04631},
{x:34.15872,y:-118.13272},
{x:42.10444,y:-80.13118},
{x:33.04762,y:-96.83203},
{x:41.13654,y:-81.63973},
{x:35.363,y:-97.49395},
{x:41.46487,y:-81.51721},
{x:38.67086,y:-90.43508},
{x:37.82279,y:-92.1416},
{x:46.80124,y:-123.01262},
{x:33.51862,y:-88.4344},
{x:40.74916,y:-73.99059},
{x:45.78382,y:-108.53888},
{x:25.77509,y:-80.17758},
{x:43.0748,y:-89.39693},
{x:42.04136,y:-87.69954},
{x:41.94354,y:-87.66411},
{x:41.97814,y:-87.66854},
{x:38.65622,y:-90.3062},
{x:41.47911,y:-82.67971},
{x:39.97833,y:-83.00041},
{x:39.99638,y:-75.23442},
{x:33.57765,y:-101.93475},
{x:34.15276,y:-118.34139},
{x:39.93653,y:-75.04531},
{x:42.28596,y:-83.83924},
{x:43.42646,y:-88.20153},
{x:37.56413,y:-77.46907},
{x:38.91245,y:-94.66118},
{x:42.73078,y:-83.37898},
{x:40.78836,y:-74.46561},
{x:36.30297,y:-75.80659},
{x:39.02244,y:-94.66062},
{x:41.74697,y:-88.16227},
{x:41.41646,y:-81.92306},
{x:38.28905,y:-85.51107},
{x:43.62449,y:-83.89358},
{x:42.60662,y:-83.29839},
{x:39.19101,y:-84.65685},
{x:41.4872,y:-81.76826},
{x:45.15871,y:-93.39078},
{x:41.24631,y:-96.02345},
{x:39.6299,y:-84.19297},
{x:40.61186,y:-105.07563},
{x:38.35471,y:-77.51165},
{x:40.4906,y:-74.8614},
{x:38.77461,y:-77.17505},
{x:45.0996,y:-93.45016},
{x:35.69802,y:-88.83098},
{x:34.21986,y:-77.88587},
{x:40.4566,y:-79.9345},
{x:40.13795,y:-85.66273},
{x:33.18987,y:-117.2798},
{x:40.29304,y:-76.97554},
{x:34.98007,y:-101.91723},
{x:41.8085,y:-87.90211},
{x:41.2364,y:-81.43999},
{x:42.00417,y:-88.01741},
{x:42.28092,y:-87.87986},
{x:41.46266,y:-81.48051},
{x:29.77631,y:-95.83256},
{x:29.78363,y:-95.50656},
{x:34.04444,y:-117.94955},
{x:34.14258,y:-118.23509},
{x:33.17078,y:-117.21664},
{x:34.08645,y:-84.53054},
{x:39.765,y:-86.40195},
{x:40.87755,y:-73.90556},
{x:40.67102,y:-73.95769},
{x:40.59524,y:-74.00007},
{x:39.74678,y:-75.54944},
{x:41.81746,y:-87.91724},
{x:34.69134,y:-118.17214},
{x:35.73627,y:-78.77959},
{x:41.87828,y:-87.63102},
{x:45.01842,y:-93.4823},
{x:38.39305,y:-85.36761},
{x:28.61804,y:-81.26337},
{x:35.84631,y:-78.88736},
{x:38.88318,y:-94.76344},
{x:47.76705,y:-122.19133},
{x:33.48918,y:-111.9273},
{x:33.81199,y:-118.18111},
{x:33.85197,y:-117.92383},
{x:32.84763,y:-96.76976},
{x:43.42337,y:-95.11324},
{x:40.02331,y:-83.05906},
{x:35.71062,y:-83.51938},
{x:40.63175,y:-80.05542},
{x:41.48008,y:-81.839},
{x:48.77445,y:-122.4585},
{x:36.85224,y:-119.77317},
{x:43.17432,y:-73.05029},
{x:34.14076,y:-118.35675},
{x:35.84373,y:-78.88775},
{x:40.42754,y:-86.92042},
{x:26.1951,y:-98.27138},
{x:32.86373,y:-79.78624},
{x:30.8792,y:-83.29636},
{x:34.19502,y:-118.44916},
{x:41.88559,y:-87.63268},
{x:42.59206,y:-88.43328},
{x:25.91099,y:-80.14257},
{x:44.74689,y:-93.2955},
{x:35.65753,y:-88.82736},
{x:36.57922,y:-87.41012},
{x:42.6356,y:-83.13205},
{x:44.97831,y:-93.27273},
{x:30.61685,y:-96.33898},
{x:42.25568,y:-83.68804},
{x:41.61188,y:-83.7011},
{x:41.69219,y:-87.74022},
{x:42.55146,y:-71.47675},
{x:36.61616,y:-121.90415},
{x:39.95729,y:-82.93334},
{x:37.84949,y:-122.25229},
{x:37.78551,y:-122.28059},
{x:38.75374,y:-77.08283},
{x:40.08248,y:-75.4036},
{x:38.89964,y:-77.02678},
{x:41.94712,y:-88.20729},
{x:38.82778,y:-77.08969},
{x:28.53706,y:-81.2749},
{x:33.38193,y:-111.83969},
{x:36.56391,y:-87.31043},
{x:29.5352,y:-95.52321},
{x:30.16954,y:-95.47207},
{x:40.85548,y:-81.43315},
{x:39.59371,y:-105.07203},
{x:43.05067,y:-88.00785},
{x:25.77138,y:-80.36002},
{x:40.7661,y:-73.06774},
{x:37.34988,y:-121.96035},
{x:40.1641,y:-83.09062},
{x:40.04971,y:-82.91435},
{x:42.58264,y:-71.15827},
{x:43.0892,y:-88.06705},
{x:40.43822,y:-74.50411},
{x:30.50263,y:-84.32978},
{x:34.02432,y:-118.46083},
{x:33.72635,y:-117.78868},
{x:33.20293,y:-117.36711},
{x:41.86502,y:-88.10649},
{x:33.37937,y:-111.91071},
{x:33.39392,y:-111.92458},
{x:35.19518,y:-111.63514},
{x:45.7224,y:-122.66661},
{x:34.61301,y:-118.19965},
{x:40.14167,y:-82.98803},
{x:40.76916,y:-73.98828},
{x:32.8699,y:-96.76894},
{x:40.61128,y:-112.0274},
{x:33.29054,y:-111.71939},
{x:42.70788,y:-83.30764},
{x:34.67378,y:-77.33972},
{x:42.27947,y:-83.74097},
{x:40.82276,y:-74.10072},
{x:40.62263,y:-73.93666},
{x:40.29797,y:-74.72971},
{x:40.76075,y:-73.83053},
{x:37.31515,y:-121.87218},
{x:34.0679,y:-117.75173},
{x:39.91385,y:-86.10829},
{x:33.83404,y:-117.83658},
{x:40.96578,y:-73.85662},
{x:41.303,y:-111.96715},
{x:42.73427,y:-84.47969},
{x:41.91905,y:-87.94025},
{x:38.64448,y:-90.26124},
{x:39.90478,y:-86.08692},
{x:33.48862,y:-111.96135},
{x:43.03885,y:-87.93344},
{x:33.65047,y:-86.82392},
{x:33.40713,y:-111.96515},
{x:41.09806,y:-73.4447},
{x:33.5096,y:-86.80277},
{x:37.60328,y:-77.52528},
{x:34.03063,y:-118.00872},
{x:35.0797,y:-106.51477},
{x:39.41302,y:-104.87358},
{x:34.12475,y:-77.90247},
{x:35.77382,y:-78.76613},
{x:39.63277,y:-106.52464},
{x:33.22532,y:-117.38852},
{x:44.83563,y:-93.3992},
{x:32.99923,y:-80.18385},
{x:34.23616,y:-77.8312},
{x:44.86806,y:-94.37835},
{x:45.19606,y:-93.34702},
{x:39.96285,y:-82.99824},
{x:33.99571,y:-86.08271},
{x:41.94664,y:-73.90012},
{x:40.58634,y:-122.34853},
{x:35.84363,y:-86.4258},
{x:39.72594,y:-121.80243},
{x:45.15746,y:-122.87655},
{x:41.94308,y:-87.67946},
{x:33.92882,y:-116.81565},
{x:36.04784,y:-78.90365},
{x:39.72085,y:-104.95688},
{x:41.91129,y:-87.63488},
{x:29.77956,y:-95.71713},
{x:33.67442,y:-111.97467},
{x:38.13174,y:-122.2226},
{x:41.80736,y:-87.74275},
{x:41.89464,y:-87.62102},
{x:47.62448,y:-122.33857},
{x:32.86082,y:-96.85733},
{x:39.78441,y:-75.05198},
{x:36.71807,y:-121.65823},
{x:37.53136,y:-121.95936},
{x:40.81033,y:-73.15969},
{x:42.35139,y:-71.04502},
{x:39.09832,y:-84.27035},
{x:34.08707,y:-117.90815},
{x:39.93433,y:-76.68239},
{x:34.39197,y:-103.33114},
{x:27.38628,y:-82.45302},
{x:39.11753,y:-94.75655},
{x:45.01239,y:-93.17409},
{x:32.84602,y:-96.85099},
{x:34.13845,y:-117.44147},
{x:40.11191,y:-82.927},
{x:28.34128,y:-81.41207},
{x:42.41114,y:-71.23317},
{x:39.86985,y:-86.14314},
{x:41.96463,y:-87.68579},
{x:38.65032,y:-90.33789},
{x:41.87498,y:-88.33971},
{x:42.16709,y:-87.8445},
{x:36.14868,y:-115.33484},
{x:34.85328,y:-82.39732},
{x:38.55111,y:-90.33369},
{x:40.34201,y:-80.05603},
{x:39.63722,y:-84.21914},
{x:30.07222,y:-95.44072},
{x:29.50195,y:-95.11774},
{x:38.9001,y:-76.99745},
{x:39.70884,y:-84.17073},
{x:39.07872,y:-108.55562},
{x:41.71912,y:-86.19044},
{x:35.78347,y:-78.6707},
{x:43.68824,y:-85.48013},
{x:39.251,y:-84.29691},
{x:47.48981,y:-122.15491},
{x:36.15991,y:-115.28615},
{x:38.93436,y:-77.07291},
{x:41.85954,y:-88.01965},
{x:32.93419,y:-97.25401},
{x:39.16609,y:-86.52705},
{x:43.14895,y:-93.25525},
{x:42.37849,y:-83.33445},
{x:42.56353,y:-83.08691},
{x:37.69354,y:-97.4465},
{x:35.81949,y:-84.2672},
{x:42.24139,y:-83.76804},
{x:38.03093,y:-84.49303},
{x:41.03388,y:-85.25935},
{x:47.90863,y:-122.22688},
{x:42.22436,y:-83.48445},
{x:47.74674,y:-117.40458},
{x:45.71116,y:-111.07101},
{x:47.16514,y:-122.5101},
{x:35.70219,y:-81.30737},
{x:47.16142,y:-122.29307},
{x:47.99892,y:-122.10123},
{x:34.78774,y:-77.40264},
{x:39.46311,y:-119.78042},
{x:47.04294,y:-122.83598},
{x:29.51259,y:-98.53677},
{x:30.54039,y:-97.56489},
{x:39.6088,y:-104.95737},
{x:41.49907,y:-81.69834},
{x:29.55261,y:-98.57257},
{x:42.77146,y:-73.82331},
{x:33.61159,y:-112.09911},
{x:40.61757,y:-79.16067},
{x:32.70768,y:-117.15998},
{x:42.93149,y:-78.72704},
{x:33.76955,y:-118.19771},
{x:42.09065,y:-71.42433},
{x:39.90551,y:-86.05193},
{x:40.92645,y:-73.78622},
{x:29.64835,y:-82.34444},
{x:34.19731,y:-82.16527},
{x:41.71079,y:-72.76558},
{x:42.55302,y:-70.93727},
{x:47.04445,y:-122.9376},
{x:43.87236,y:-121.43501},
{x:38.87329,y:-77.07942},
{x:32.89986,y:-105.94621},
{x:33.64642,y:-117.83901},
{x:35.4085,y:-78.73853},
{x:45.52181,y:-123.10891},
{x:38.63592,y:-90.23483},
{x:47.60935,y:-117.36535},
{x:40.09231,y:-75.37508},
{x:42.3851,y:-87.97761},
{x:38.43084,y:-78.86048},
{x:42.19444,y:-88.21133},
{x:42.01838,y:-87.67387},
{x:41.90487,y:-87.63901},
{x:42.19178,y:-88.1084},
{x:42.10055,y:-87.79866},
{x:41.90621,y:-87.67},
{x:42.38008,y:-87.96578},
{x:41.38207,y:-81.43645},
{x:41.78966,y:-87.87751},
{x:41.68424,y:-87.70197},
{x:40.16613,y:-75.29042},
{x:42.43296,y:-83.43401},
{x:41.31726,y:-81.35021},
{x:34.02642,y:-118.2775},
{x:35.36785,y:-119.04491},
{x:41.89638,y:-87.63587},
{x:40.1832,y:-122.21859},
{x:37.97875,y:-122.06691},
{x:33.35644,y:-86.85479},
{x:41.69254,y:-86.23505},
{x:33.12222,y:-117.12177},
{x:40.42911,y:-74.38466},
{x:38.59077,y:-90.33935},
{x:41.62871,y:-81.42533},
{x:38.243,y:-104.64537},
{x:44.06017,y:-92.46969},
{x:44.08024,y:-103.22754},
{x:41.32117,y:-81.6263},
{x:41.57729,y:-90.51374},
{x:42.38813,y:-71.19145},
{x:42.34801,y:-71.07976},
{x:40.56589,y:-90.03408},
{x:40.9461,y:-90.33975},
{x:42.34353,y:-71.10081},
{x:42.37545,y:-71.14908},
{x:42.31879,y:-71.05074},
{x:42.319,y:-71.17515},
{x:43.54301,y:-116.15313},
{x:36.91759,y:-76.1937},
{x:37.64847,y:-122.49053},
{x:30.19512,y:-81.82934},
{x:35.60535,y:-77.3625},
{x:39.14799,y:-84.46629},
{x:41.99828,y:-72.58296},
{x:31.13014,y:-97.79798},
{x:39.19049,y:-94.54835},
{x:45.44235,y:-122.77765},
{x:34.04818,y:-118.25828},
{x:40.72981,y:-73.86275},
{x:45.51204,y:-122.68212},
{x:33.97292,y:-118.07365},
{x:38.91554,y:-94.36132},
{x:41.78983,y:-87.59715},
{x:32.74093,y:-117.05555},
{x:34.13778,y:-117.98308},
{x:33.01981,y:-117.12552},
{x:32.90059,y:-96.46072},
{x:32.63634,y:-97.36937},
{x:41.55396,y:-72.68165},
{x:40.09932,y:-83.11437},
{x:39.12803,y:-84.51701},
{x:30.20448,y:-93.26372},
{x:33.97946,y:-84.00444},
{x:38.62666,y:-90.48179},
{x:40.31256,y:-111.70101},
{x:39.69773,y:-104.82658},
{x:37.11371,y:-76.46698},
{x:39.61499,y:-104.75848},
{x:27.91459,y:-82.34943},
{x:41.52215,y:-87.47087},
{x:42.52588,y:-83.46548},
{x:43.074,y:-89.38282},
{x:27.98103,y:-82.48837},
{x:41.47027,y:-87.34597},
{x:38.29275,y:-85.56042},
{x:38.62189,y:-90.51915},
{x:42.31419,y:-88.44793},
{x:36.30805,y:-82.36572},
{x:41.4845,y:-82.68343},
{x:30.26447,y:-97.74377},
{x:42.04642,y:-88.03681},
{x:42.00465,y:-87.66119},
{x:38.7738,y:-77.1854},
{x:40.6729,y:-73.83281},
{x:47.56907,y:-122.28833},
{x:47.47019,y:-122.34641},
{x:33.94721,y:-118.08654},
{x:42.04965,y:-87.68202},
{x:42.66027,y:-83.38517},
{x:29.56959,y:-98.65008},
{x:29.50011,y:-98.62523},
{x:40.7023,y:-73.42655},
{x:40.02802,y:-83.11633},
{x:34.99012,y:-118.94126},
{x:39.2482,y:-94.65241},
{x:34.08452,y:-117.68915},
{x:39.11571,y:-84.43647},
{x:39.99878,y:-83.0075},
{x:42.32599,y:-83.30209},
{x:40.65049,y:-75.29582},
{x:32.7603,y:-97.79477},
{x:47.65489,y:-122.30538},
{x:35.836,y:-78.85598},
{x:35.17143,y:-80.66255},
{x:38.97139,y:-94.73847},
{x:39.09313,y:-76.85886},
{x:40.00244,y:-86.12413},
{x:42.58033,y:-83.61152},
{x:32.88821,y:-97.03745},
{x:42.0105,y:-87.82963},
{x:40.69496,y:-73.9831},
{x:32.8145,y:-79.99666},
{x:46.89497,y:-102.83661},
{x:38.83222,y:-77.05036},
{x:38.50818,y:-90.37537},
{x:32.18291,y:-110.94788},
{x:39.04846,y:-94.36002},
{x:34.01285,y:-81.15156},
{x:38.02001,y:-121.32392},
{x:34.86348,y:-111.79301},
{x:33.67076,y:-112.11787},
{x:33.50667,y:-86.80345},
{x:29.56203,y:-98.32928},
{x:31.52632,y:-97.13403},
{x:41.91031,y:-87.6777},
{x:42.08157,y:-88.06845},
{x:42.03412,y:-87.67885},
{x:40.10565,y:-75.29197},
{x:33.7929,y:-117.85215},
{x:29.86706,y:-97.94048},
{x:33.06768,y:-96.88127},
{x:32.45521,y:-97.72719},
{x:39.08003,y:-84.46699},
{x:33.08591,y:-96.6186},
{x:33.21902,y:-96.7355},
{x:35.08767,y:-106.59544},
{x:39.13126,y:-76.74054},
{x:38.86255,y:-77.0565},
{x:41.08452,y:-74.152},
{x:38.29917,y:-77.48392},
{x:42.1845,y:-88.0926},
{x:38.80468,y:-77.13327},
{x:42.44752,y:-70.96499},
{x:40.75737,y:-73.96909},
{x:40.02994,y:-83.03488},
{x:39.73638,y:-75.56021},
{x:41.95466,y:-87.67894},
{x:38.65268,y:-94.3598},
{x:37.38719,y:-121.86185},
{x:38.7841,y:-77.01696},
{x:31.11383,y:-97.79881},
{x:42.52594,y:-83.51497},
{x:38.86347,y:-77.08402},
{x:43.59699,y:-116.2134},
{x:44.86108,y:-93.52422},
{x:40.37361,y:-111.7858},
{x:27.9455,y:-82.46879},
{x:32.94991,y:-117.24714},
{x:36.6659,y:-121.8101},
{x:32.90013,y:-97.31856},
{x:44.96029,y:-93.29341},
{x:37.99856,y:-122.28655},
{x:37.70564,y:-121.88867},
{x:37.66074,y:-121.87602},
{x:39.8784,y:-83.06132},
{x:41.12315,y:-112.0209},
{x:25.73242,y:-80.38205},
{x:39.98807,y:-83.03527},
{x:42.95844,y:-88.0087},
{x:28.35829,y:-80.61087},
{x:29.91635,y:-81.40914},
{x:30.44029,y:-84.26224},
{x:39.29009,y:-84.46625},
{x:39.85674,y:-84.27717},
{x:36.07787,y:-94.21031},
{x:38.60828,y:-90.24098},
{x:36.23141,y:-86.51512},
{x:42.62961,y:-82.91382},
{x:27.70621,y:-97.37397},
{x:37.82851,y:-85.46465},
{x:36.82184,y:-119.78806},
{x:33.82469,y:-117.83462},
{x:32.80936,y:-116.9591},
{x:34.59968,y:-118.14686},
{x:33.84693,y:-118.06234},
{x:36.2923,y:-119.31685},
{x:37.68736,y:-120.99579},
{x:42.26995,y:-88.98818},
{x:39.97852,y:-86.15468},
{x:40.99689,y:-75.24499},
{x:34.92297,y:-120.43373},
{x:33.88809,y:-117.87726},
{x:33.85856,y:-117.92719},
{x:37.32274,y:-120.48478},
{x:34.03284,y:-117.6818},
{x:33.78768,y:-117.91336},
{x:40.01494,y:-105.25266},
{x:42.73893,y:-73.67736},
{x:42.01003,y:-87.82799},
{x:32.88006,y:-117.23644},
{x:44.94536,y:-93.09525},
{x:33.9756,y:-117.32104},
{x:38.34015,y:-85.61948},
{x:40.00649,y:-83.04575},
{x:38.55039,y:-121.69505},
{x:41.95046,y:-83.39914},
{x:33.21091,y:-97.14744},
{x:42.51467,y:-96.41726},
{x:37.97709,y:-84.52713},
{x:26.0717,y:-80.13839},
{x:44.8355,y:-93.16956},
{x:42.10596,y:-87.84763},
{x:42.08229,y:-87.93852},
{x:42.18561,y:-87.80235},
{x:39.75733,y:-104.99869},
{x:40.98121,y:-76.88452},
{x:32.96122,y:-117.19118},
{x:28.38656,y:-81.24482},
{x:27.17157,y:-80.29442},
{x:25.62636,y:-80.39418},
{x:25.7731,y:-80.26401},
{x:30.26969,y:-87.58778},
{x:43.01466,y:-88.04614},
{x:42.22355,y:-71.20487},
{x:38.66889,y:-90.5987},
{x:41.93555,y:-87.67016},
{x:34.93445,y:-81.89528},
{x:35.02642,y:-83.79564},
{x:41.14682,y:-81.50705},
{x:41.88796,y:-87.8133},
{x:40.42172,y:-86.90263},
{x:40.53235,y:-88.99475},
{x:42.62823,y:-82.97622},
{x:40.99687,y:-75.17363},
{x:33.78207,y:-117.86466},
{x:42.68472,y:-84.43325},
{x:42.20883,y:-121.75299},
{x:42.27489,y:-83.73412},
{x:39.65047,y:-78.93324},
{x:41.86942,y:-87.66299},
{x:47.56094,y:-122.38658},
{x:39.74944,y:-104.99499},
{x:41.89823,y:-87.62535},
{x:42.86487,y:-73.77881},
{x:43.10262,y:-73.74236},
{x:39.41096,y:-76.94891},
{x:33.08111,y:-117.2339},
{x:26.137,y:-80.1157},
{x:29.91187,y:-95.68574},
{x:42.77522,y:-83.23949},
{x:34.10765,y:-118.27311},
{x:42.31623,y:-122.83298},
{x:29.69827,y:-95.84672},
{x:28.66525,y:-81.41774},
{x:42.431,y:-83.48012},
{x:38.24001,y:-85.72438},
{x:35.09406,y:-89.80551},
{x:44.97395,y:-93.22993},
{x:34.24204,y:-111.32132},
{x:33.63893,y:-111.86442},
{x:41.05542,y:-83.60667},
{x:40.55144,y:-74.33236},
{x:40.56266,y:-74.55527},
{x:40.10512,y:-75.29626},
{x:45.5338,y:-122.44284},
{x:42.87313,y:-73.9284},
{x:44.72593,y:-85.63749},
{x:41.45857,y:-81.86428},
{x:42.60252,y:-73.79039},
{x:40.49766,y:-88.95024},
{x:40.22605,y:-75.23825},
{x:39.05715,y:-94.60518},
{x:40.69258,y:-73.98387},
{x:42.34757,y:-122.87745},
{x:41.96162,y:-87.67616},
{x:41.92793,y:-87.69735},
{x:34.09465,y:-118.3095},
{x:36.06711,y:-95.78025},
{x:41.86008,y:-87.97396},
{x:61.1282,y:-149.88502},
{x:45.44786,y:-122.57933},
{x:42.43697,y:-123.30255},
{x:41.92882,y:-87.65846},
{x:45.30421,y:-122.95533},
{x:44.86451,y:-93.35321},
{x:45.06464,y:-93.24877},
{x:34.77119,y:-92.39752},
{x:40.10864,y:-82.92818},
{x:39.78149,y:-86.17049},
{x:40.45006,y:-80.01561},
{x:44.02059,y:-121.31772},
{x:33.91814,y:-118.07523},
{x:37.79454,y:-121.19688},
{x:37.29126,y:-121.98957},
{x:40.56271,y:-75.34094},
{x:38.82512,y:-77.31544},
{x:40.96146,y:-76.89583},
{x:37.25691,y:-107.07628},
{x:39.70849,y:-105.13727},
{x:40.90233,y:-81.15445},
{x:40.69975,y:-73.91112},
{x:41.36251,y:-81.68466},
{x:34.97111,y:-120.43587},
{x:34.02458,y:-118.28436},
{x:42.9017,y:-87.92011},
{x:32.75561,y:-117.13535},
{x:42.5459,y:-83.26202},
{x:34.21929,y:-119.15947},
{x:33.90169,y:-118.30013},
{x:33.9618,y:-118.36637},
{x:20.87165,y:-156.49933},
{x:34.10647,y:-117.88988},
{x:35.14608,y:-80.8317},
{x:34.30867,y:-118.43138},
{x:34.8646,y:-120.41918},
{x:34.0367,y:-118.09976},
{x:42.18167,y:-87.96477},
{x:41.42537,y:-81.73526},
{x:39.9839,y:-83.15206},
{x:41.89878,y:-87.62367},
{x:37.68819,y:-120.9947},
{x:37.21521,y:-80.40098},
{x:39.76216,y:-105.14207},
{x:41.88965,y:-87.63725},
{x:44.63301,y:-123.09253},
{x:43.01626,y:-89.42912},
{x:44.07152,y:-123.04226},
{x:33.76052,y:-84.39479},
{x:35.69759,y:-78.58386},
{x:40.64274,y:-73.79132},
{x:40.78277,y:-73.94837},
{x:40.09162,y:-88.24626},
{x:42.54623,y:-83.2859},
{x:41.50668,y:-87.73296},
{x:40.10019,y:-83.09059},
{x:39.27255,y:-84.32796},
{x:34.07001,y:-118.3435},
{x:33.07492,y:-97.12991},
{x:34.57582,y:-118.04547},
{x:33.63805,y:-112.23075},
{x:32.16157,y:-110.99051},
{x:34.27844,y:-118.86704},
{x:48.23569,y:-114.32616},
{x:42.95046,y:-85.61483},
{x:29.55649,y:-95.14978},
{x:41.81048,y:-87.97542},
{x:33.53875,y:-84.57627},
{x:38.64223,y:-90.56625},
{x:42.89903,y:-73.2126},
{x:40.88967,y:-74.2716},
{x:43.05408,y:-76.10306},
{x:41.95699,y:-91.65865},
{x:41.67658,y:-91.51883},
{x:41.5547,y:-93.59471},
{x:41.97404,y:-91.69846},
{x:43.04837,y:-87.90527},
{x:36.0841,y:-80.29481},
{x:34.03475,y:-84.05257},
{x:42.56386,y:-87.88571},
{x:34.0647,y:-117.98657},
{x:42.27525,y:-71.74806},
{x:41.07528,y:-85.20283},
{x:41.63196,y:-87.93149},
{x:41.88154,y:-87.65216},
{x:40.71219,y:-74.01089},
{x:44.92053,y:-92.93306},
{x:39.71953,y:-104.95319},
{x:42.56799,y:-87.95048},
{x:41.39535,y:-81.64185},
{x:41.75324,y:-72.6809},
{x:40.92689,y:-73.8572},
{x:39.61987,y:-77.7731},
{x:34.89288,y:-82.71034},
{x:35.60107,y:-82.41869},
{x:41.66027,y:-81.36497},
{x:40.26456,y:-75.65117},
{x:39.19411,y:-75.54671},
{x:42.0038,y:-87.95897},
{x:41.37151,y:-81.82642},
{x:31.80038,y:-106.39676},
{x:40.10585,y:-83.16279},
{x:41.50056,y:-81.68142},
{x:61.1941,y:-149.91247},
{x:37.4863,y:-122.22992},
{x:39.91263,y:-86.18173},
{x:29.60778,y:-95.65953},
{x:39.94764,y:-75.19338},
{x:40.09352,y:-82.82346},
{x:42.60273,y:-83.26255},
{x:35.15389,y:-90.04306},
{x:38.63324,y:-90.30557},
{x:31.6178,y:-84.22326},
{x:39.56638,y:-104.90692},
{x:39.96921,y:-83.00488},
{x:38.24671,y:-85.57656},
{x:29.716,y:-95.55825},
{x:48.05082,y:-122.17912},
{x:41.24708,y:-96.2312},
{x:32.73339,y:-97.10942},
{x:37.77123,y:-122.38931},
{x:42.17131,y:-87.82455},
{x:39.88091,y:-86.27214},
{x:41.92374,y:-71.35613},
{x:40.8256,y:-73.91905},
{x:37.22673,y:-121.77565},
{x:39.77586,y:-75.59616},
{x:28.38653,y:-81.49353},
{x:28.91065,y:-81.29224},
{x:27.96023,y:-82.5051},
{x:35.10227,y:-77.08978},
{x:35.05785,y:-80.936},
{x:35.85691,y:-78.67963},
{x:39.74948,y:-105.22378},
{x:34.0042,y:-81.03461},
{x:39.77319,y:-86.15185},
{x:41.54619,y:-87.50964},
{x:41.55119,y:-87.18156},
{x:41.57106,y:-87.47627},
{x:45.07881,y:-93.05713},
{x:37.92835,y:-122.01624},
{x:42.67316,y:-83.22137},
{x:34.82005,y:-92.23376},
{x:35.68125,y:-88.85727},
{x:38.88591,y:-94.72314},
{x:45.20902,y:-93.35843},
{x:42.24234,y:-88.3186},
{x:47.61524,y:-122.33813},
{x:31.36733,y:-110.93689},
{x:43.08302,y:-88.22869},
{x:35.35126,y:-119.12008},
{x:32.58369,y:-117.11559},
{x:33.92415,y:-118.15277},
{x:33.6713,y:-112.22089},
{x:36.73469,y:-120.04278},
{x:33.91676,y:-118.12509},
{x:33.13164,y:-117.22963},
{x:33.37147,y:-117.25312},
{x:44.04474,y:-123.07413},
{x:44.22341,y:-72.5469},
{x:40.62582,y:-91.34333},
{x:39.66296,y:-75.6894},
{x:42.10175,y:-87.77033},
{x:40.08104,y:-82.81531},
{x:37.96459,y:-121.73728},
{x:33.47902,y:-117.13633},
{x:41.19734,y:-96.14175},
{x:41.72208,y:-91.60425},
{x:41.42365,y:-73.1058},
{x:42.15996,y:-71.73291},
{x:38.25076,y:-85.63446},
{x:32.9344,y:-117.06131},
{x:39.98229,y:-74.80496},
{x:40.51632,y:-74.37318},
{x:40.00269,y:-75.22265},
{x:38.30807,y:-85.57644},
{x:41.65129,y:-87.7375},
{x:41.56137,y:-87.66782},
{x:41.95334,y:-87.73789},
{x:40.16984,y:-74.89073},
{x:32.71811,y:-96.32192},
{x:40.70354,y:-73.80881},
{x:41.75861,y:-88.24529},
{x:30.65444,y:-88.117},
{x:35.17656,y:-111.66728},
{x:42.01099,y:-88.33552},
{x:34.86608,y:-111.79474},
{x:41.56669,y:-85.81525},
{x:36.75198,y:-76.00974},
{x:47.80328,y:-122.33566},
{x:44.01741,y:-90.50819},
{x:39.47604,y:-74.54086},
{x:36.16562,y:-115.15576},
{x:35.96992,y:-78.95991},
{x:29.93145,y:-95.62817},
{x:34.15024,y:-118.09398},
{x:42.96143,y:-85.88864},
{x:43.05883,y:-89.50234},
{x:40.05054,y:-82.91948},
{x:32.79163,y:-96.80431},
{x:38.8094,y:-90.29965},
{x:39.19972,y:-84.37667},
{x:44.88824,y:-92.94809},
{x:40.15781,y:-83.09222},
{x:35.86022,y:-90.64984},
{x:28.19052,y:-82.3897},
{x:40.87571,y:-81.36617},
{x:33.19007,y:-117.23888},
{x:45.25619,y:-122.70355},
{x:20.87431,y:-156.45572},
{x:42.02252,y:-93.64673},
{x:39.6577,y:-80.00208},
{x:40.73832,y:-89.60517},
{x:44.82717,y:-93.28917},
{x:44.3153,y:-69.80506},
{x:37.77417,y:-121.97856},
{x:47.66702,y:-117.39905},
{x:41.46289,y:-81.95506},
{x:34.61061,y:-92.53065},
{x:39.95707,y:-86.04375},
{x:36.10111,y:-115.11857},
{x:36.05948,y:-115.27877},
{x:33.25671,y:-111.63743},
{x:33.96175,y:-118.42007},
{x:36.29248,y:-115.17977},
{x:33.73778,y:-116.40777},
{x:41.02384,y:-80.63207},
{x:35.25006,y:-91.69365},
{x:39.16575,y:-78.16425},
{x:37.30029,y:-79.9586},
{x:30.54059,y:-87.19782},
{x:64.75755,y:-147.35393},
{x:33.50134,y:-86.80735},
{x:38.9213,y:-77.23331},
{x:42.50078,y:-70.895},
{x:40.7731,y:-73.98013},
{x:42.11008,y:-88.11402},
{x:41.74071,y:-74.08486},
{x:39.51028,y:-84.74175},
{x:38.97479,y:-77.01526},
{x:39.74229,y:-84.18226},
{x:41.90199,y:-87.94009},
{x:40.42107,y:-74.52852},
{x:44.91141,y:-93.35302},
{x:42.49981,y:-83.35907},
{x:40.64443,y:-73.63888},
{x:33.93065,y:-118.35314},
{x:33.68473,y:-117.1518},
{x:32.37434,y:-111.10216},
{x:33.37848,y:-111.85927},
{x:42.3846,y:-82.91423},
{x:36.07002,y:-79.27158},
{x:34.13598,y:-117.19727},
{x:34.03181,y:-118.29077},
{x:42.35842,y:-83.07095},
{x:35.01911,y:-114.59963},
{x:43.58927,y:-116.31561},
{x:39.87669,y:-105.09816},
{x:37.27464,y:-107.88225},
{x:47.47744,y:-122.21848},
{x:33.33418,y:-111.80782},
{x:45.49579,y:-122.40537},
{x:45.74229,y:-122.87819},
{x:47.92051,y:-122.23166},
{x:32.90293,y:-97.18787},
{x:32.88251,y:-97.39547},
{x:37.99683,y:-84.47863},
{x:33.75618,y:-84.34813},
{x:41.68006,y:-81.30302},
{x:33.8002,y:-116.52628},
{x:39.90607,y:-82.73484},
{x:35.60271,y:-87.06239},
{x:33.40681,y:-111.63085},
{x:33.06226,y:-96.88926},
{x:38.77618,y:-104.81186},
{x:32.45401,y:-97.72693},
{x:44.86414,y:-93.44081},
{x:37.71168,y:-85.83628},
{x:32.8954,y:-97.27199},
{x:38.21348,y:-85.58858},
{x:33.25122,y:-96.78408},
{x:39.16047,y:-84.42691},
{x:33.44934,y:-88.84257},
{x:42.15291,y:-87.94334},
{x:29.76288,y:-95.84372},
{x:36.99658,y:-86.42489},
{x:35.74099,y:-83.99404},
{x:29.89696,y:-95.6056},
{x:45.75555,y:-108.56702},
{x:44.48456,y:-87.97138},
{x:37.33667,y:-121.88011},
{x:38.93259,y:-76.99729},
{x:34.12274,y:-118.07253},
{x:47.11987,y:-122.55635},
{x:30.06873,y:-94.13351},
{x:30.34938,y:-97.55651},
{x:33.69342,y:-117.8926},
{x:42.16979,y:-72.47948},
{x:39.22812,y:-77.28583},
{x:38.97138,y:-95.23611},
{x:40.10953,y:-88.22696},
{x:32.53877,y:-84.86792},
{x:37.33155,y:-121.81071},
{x:36.3264,y:-86.69891},
{x:39.5785,y:-104.96128},
{x:33.94278,y:-118.4025},
{x:33.42022,y:-110.81661},
{x:33.94254,y:-118.40204},
{x:38.69081,y:-121.38369},
{x:40.73866,y:-74.17059},
{x:35.10942,y:-106.65815},
{x:40.60247,y:-75.47142},
{x:29.60768,y:-98.5223},
{x:42.28077,y:-89.63086},
{x:30.71629,y:-95.5713},
{x:36.14562,y:-80.29882},
{x:43.39717,y:-88.18179},
{x:35.32823,y:-80.94018},
{x:37.36749,y:-77.50172},
{x:36.04761,y:-96.00367},
{x:36.13638,y:-95.92183},
{x:41.51623,y:-81.56893},
{x:33.56171,y:-117.62905},
{x:44.78962,y:-93.21038},
{x:33.62994,y:-117.7179},
{x:34.515,y:-117.32014},
{x:40.84135,y:-74.46004},
{x:27.81422,y:-82.78076},
{x:44.76415,y:-85.59419},
{x:44.97724,y:-93.26872},
{x:40.52371,y:-104.98938},
{x:48.05214,y:-122.16718},
{x:45.2799,y:-92.99895},
{x:41.05232,y:-86.22075},
{x:39.63831,y:-86.0839},
{x:32.51664,y:-97.34917},
{x:33.37684,y:-84.56439},
{x:42.0531,y:-87.83607},
{x:41.76585,y:-87.5856},
{x:34.42575,y:-117.38579},
{x:44.89205,y:-93.41878},
{x:35.2051,y:-89.76233},
{x:42.16014,y:-88.27315},
{x:42.11498,y:-88.03409},
{x:40.54316,y:-111.9853},
{x:40.81508,y:-73.95918},
{x:41.26199,y:-96.09049},
{x:44.97622,y:-93.27159},
{x:43.19259,y:-88.13186},
{x:33.7893,y:-118.30867},
{x:34.02529,y:-118.23965},
{x:38.21438,y:-85.59141},
{x:32.83704,y:-116.96693},
{x:35.31807,y:-119.03039},
{x:33.9853,y:-118.05777},
{x:43.62099,y:-116.37227},
{x:32.03563,y:-102.13},
{x:39.61867,y:-86.15992},
{x:33.5715,y:-117.72545},
{x:40.14227,y:-82.98812},
{x:41.90311,y:-87.67012},
{x:29.75365,y:-95.40555},
{x:41.0404,y:-111.96652},
{x:42.61741,y:-82.99399},
{x:34.53097,y:-82.63439},
{x:31.89428,y:-102.33295},
{x:41.9373,y:-87.64438},
{x:41.80242,y:-87.92904},
{x:39.195,y:-84.23741},
{x:40.11777,y:-83.00992},
{x:40.05439,y:-83.06884},
{x:17.9934,y:-66.6116},
{x:38.76007,y:-93.74188},
{x:40.56592,y:-75.56141},
{x:33.40736,y:-82.13515},
{x:28.35776,y:-81.59168},
{x:42.18063,y:-88.33481},
{x:40.76718,y:-73.3112},
{x:41.79548,y:-88.12182},
{x:41.902,y:-88.34165},
{x:41.23358,y:-96.10344},
{x:34.02725,y:-118.42776},
{x:40.75311,y:-73.98511},
{x:33.94605,y:-118.3872},
{x:42.3697,y:-83.46988},
{x:38.59266,y:-90.54301},
{x:43.04739,y:-89.32409},
{x:39.71789,y:-86.35572},
{x:33.99872,y:-84.58943},
{x:44.96056,y:-89.6559},
{x:34.03125,y:-117.66811},
{x:33.52156,y:-81.71882},
{x:28.38662,y:-81.48744},
{x:39.63114,y:-86.15777},
{x:41.79597,y:-88.01062},
{x:43.00147,y:-85.59125},
{x:36.29056,y:-115.28582},
{x:32.36887,y:-84.92565},
{x:37.20917,y:-93.27419},
{x:35.41219,y:-80.66324},
{x:41.31414,y:-81.6569},
{x:34.28011,y:-118.88319},
{x:40.03568,y:-85.99601},
{x:46.61973,y:-120.56428},
{x:37.07395,y:-76.36276},
{x:39.65185,y:-79.94122},
{x:40.55685,y:-74.26325},
{x:35.79632,y:-78.51154},
{x:34.04655,y:-84.17959},
{x:35.50607,y:-78.32145},
{x:39.22319,y:-94.54653},
{x:37.24156,y:-93.35297},
{x:30.16623,y:-81.70067},
{x:47.35827,y:-122.09861},
{x:35.46727,y:-94.35544},
{x:36.96263,y:-121.98851},
{x:28.77887,y:-81.35772},
{x:28.65619,y:-81.20388},
{x:27.83921,y:-82.78745},
{x:41.23465,y:-96.18689},
{x:39.16844,y:-86.57308},
{x:41.98016,y:-87.8633},
{x:39.18509,y:-96.56016},
{x:39.07711,y:-94.39367},
{x:38.88525,y:-94.68841},
{x:40.78063,y:-89.67832},
{x:45.03466,y:-93.38401},
{x:44.71514,y:-93.17382},
{x:40.46024,y:-90.64834},
{x:40.81518,y:-91.14009},
{x:36.19376,y:-115.16117},
{x:41.60078,y:-93.75681},
{x:41.03423,y:-85.25764},
{x:47.05429,y:-122.77774},
{x:40.98019,y:-74.02444},
{x:47.80905,y:-122.53973},
{x:47.1842,y:-122.29319},
{x:37.7238,y:-97.46323},
{x:36.83795,y:-119.75336},
{x:40.73098,y:-74.27703},
{x:47.87978,y:-122.20924},
{x:37.69421,y:-97.24407},
{x:40.86366,y:-74.39802},
{x:37.01948,y:-120.13152},
{x:34.18178,y:-118.38451},
{x:38.96582,y:-94.6077},
{x:33.83751,y:-118.30881},
{x:43.62742,y:-72.32302},
{x:40.44427,y:-79.99416},
{x:27.44574,y:-82.50807},
{x:45.50486,y:-122.95547},
{x:41.58597,y:-93.62973},
{x:27.13985,y:-82.45988},
{x:37.83454,y:-122.25052},
{x:44.4377,y:-95.77863},
{x:35.48122,y:-80.85784},
{x:38.80964,y:-77.61725},
{x:32.39653,y:-99.76126},
{x:29.75157,y:-95.36012},
{x:40.15794,y:-83.01954},
{x:41.45634,y:-91.02255},
{x:41.76971,y:-88.06977},
{x:44.9638,y:-92.96257},
{x:42.08934,y:-87.82429},
{x:35.63792,y:-97.48409},
{x:42.58101,y:-83.2817},
{x:38.0307,y:-84.49034},
{x:39.74884,y:-122.2046},
{x:41.26435,y:-95.94943},
{x:33.81841,y:-117.88826},
{x:42.65999,y:-84.53683},
{x:42.49069,y:-83.51508},
{x:41.67986,y:-88.20387},
{x:42.13921,y:-87.92613},
{x:42.68343,y:-83.19335},
{x:39.76309,y:-86.32943},
{x:33.22791,y:-97.12916},
{x:41.50364,y:-81.502},
{x:38.86507,y:-77.05997},
{x:42.81436,y:-83.70593},
{x:41.1751,y:-96.04499},
{x:41.54241,y:-96.12412},
{x:26.06655,y:-81.69768},
{x:30.19787,y:-93.22523},
{x:21.29238,y:-157.84536},
{x:40.54002,y:-122.35004},
{x:34.19905,y:-118.60574},
{x:30.12689,y:-95.43472},
{x:39.63127,y:-106.07058},
{x:41.98652,y:-88.34143},
{x:42.97689,y:-88.10811},
{x:44.9337,y:-93.33998},
{x:41.69533,y:-72.75641},
{x:38.78863,y:-90.62634},
{x:26.27384,y:-81.68625},
{x:38.65086,y:-77.29531},
{x:38.35038,y:-77.0545},
{x:43.0905,y:-89.5256},
{x:40.42985,y:-111.8247},
{x:38.92085,y:-76.8455},
{x:39.64233,y:-86.33333},
{x:29.51243,y:-98.5697},
{x:43.14617,y:-95.14683},
{x:34.12004,y:-117.55841},
{x:40.24053,y:-83.3958},
{x:41.18543,y:-111.95084},
{x:34.18412,y:-118.33937},
{x:42.43487,y:-123.30568},
{x:39.72927,y:-104.94098},
{x:39.41519,y:-104.87953},
{x:41.14873,y:-88.83381},
{x:39.5424,y:-107.78264},
{x:38.18632,y:-84.5684},
{x:33.86698,y:-118.35356},
{x:38.44851,y:-105.22394},
{x:39.89017,y:-86.04683},
{x:41.31348,y:-81.82037},
{x:40.47055,y:-86.10863},
{x:38.93876,y:-121.09655},
{x:32.9262,y:-97.08709},
{x:32.86468,y:-96.71132},
{x:40.74964,y:-73.42128},
{x:45.58363,y:-122.72787},
{x:40.77386,y:-73.52877},
{x:39.15553,y:-122.14648},
{x:44.85455,y:-93.24063},
{x:39.4987,y:-121.56968},
{x:29.68245,y:-95.45796},
{x:43.06407,y:-88.04458},
{x:40.81248,y:-74.4695},
{x:36.94667,y:-86.42295},
{x:42.36012,y:-88.26653},
{x:38.58798,y:-121.30593},
{x:38.56957,y:-121.47125},
{x:48.47144,y:-122.33732},
{x:44.87763,y:-93.32364},
{x:41.26219,y:-96.16044},
{x:40.05123,y:-75.66247},
{x:40.72468,y:-73.55653},
{x:42.04049,y:-87.75188},
{x:40.44344,y:-79.95571},
{x:40.58846,y:-111.89268},
{x:42.98945,y:-87.95094},
{x:32.88235,y:-97.10135},
{x:42.93932,y:-88.0476},
{x:41.83976,y:-87.99723},
{x:35.31765,y:-80.67451},
{x:32.70471,y:-97.36096},
{x:46.82885,y:-100.77373},
{x:35.20551,y:-79.48429},
{x:32.60306,y:-96.85571},
{x:37.36476,y:-122.03071},
{x:39.06053,y:-77.12168},
{x:25.86834,y:-80.32039},
{x:40.84905,y:-73.05661},
{x:43.62476,y:-89.78744},
{x:36.10304,y:-79.81914},
{x:39.29777,y:-76.89702},
{x:32.90463,y:-97.04511},
{x:28.01906,y:-82.75666},
{x:28.98583,y:-81.2988},
{x:46.79763,y:-100.79406},
{x:41.92859,y:-87.64204},
{x:36.21248,y:-119.34906},
{x:34.043,y:-118.03246},
{x:37.60901,y:-97.33514},
{x:37.75848,y:-87.07452},
{x:30.19112,y:-98.08597},
{x:37.9603,y:-91.76677},
{x:41.13816,y:-85.16246},
{x:39.86143,y:-85.97583},
{x:41.94835,y:-88.02575},
{x:34.32145,y:-83.83354},
{x:34.15442,y:-118.44778},
{x:39.43316,y:-87.41416},
{x:29.72322,y:-95.46741},
{x:41.83647,y:-71.40916},
{x:34.22115,y:-118.36701},
{x:34.45202,y:-118.56615},
{x:35.55183,y:-77.40534},
{x:44.04586,y:-123.06468},
{x:46.59094,y:-112.01494},
{x:39.50077,y:-106.15363},
{x:36.77166,y:-119.83531},
{x:38.74257,y:-90.63493},
{x:34.67021,y:-98.40068},
{x:36.13798,y:-115.16536},
{x:42.51112,y:-92.43652},
{x:36.16668,y:-86.82888},
{x:41.4673,y:-87.03208},
{x:33.77391,y:-117.93832},
{x:42.89121,y:-112.4517},
{x:37.25889,y:-121.8735},
{x:46.8008,y:-92.14253},
{x:35.11561,y:-83.99302},
{x:39.65439,y:-79.95832},
{x:40.73507,y:-73.44291},
{x:41.49319,y:-87.50694},
{x:41.11378,y:-81.57841},
{x:41.60047,y:-87.55855},
{x:39.94899,y:-86.35101},
{x:42.15164,y:-87.91319},
{x:44.912,y:-93.33048},
{x:43.25047,y:-83.78242},
{x:42.07097,y:-88.19117},
{x:47.88883,y:-97.07928},
{x:42.36442,y:-83.27483},
{x:33.76555,y:-118.19266},
{x:35.66805,y:-97.49659},
{x:41.4818,y:-72.81084},
{x:41.01715,y:-73.79958},
{x:39.62408,y:-79.96429},
{x:40.73245,y:-74.06305},
{x:37.24182,y:-121.93164},
{x:41.83077,y:-72.49431},
{x:39.8146,y:-85.76908},
{x:41.41717,y:-81.92623},
{x:30.5433,y:-97.86533},
{x:37.97502,y:-84.4988},
{x:41.96482,y:-87.98957},
{x:41.36998,y:-72.91926},
{x:40.28558,y:-79.38874},
{x:34.28323,y:-118.76858},
{x:42.10692,y:-87.73534},
{x:26.14655,y:-80.33254},
{x:41.87903,y:-87.63902},
{x:41.91651,y:-87.6872},
{x:25.79136,y:-80.36846},
{x:41.95713,y:-88.07346},
{x:30.2002,y:-93.22643},
{x:39.63264,y:-84.21348},
{x:42.62736,y:-82.99658},
{x:46.14228,y:-122.90051},
{x:30.19322,y:-81.73854},
{x:36.99984,y:-121.5546},
{x:32.81694,y:-117.15163},
{x:47.36128,y:-122.60799},
{x:47.49298,y:-111.27168},
{x:41.79516,y:-71.32291},
{x:33.97063,y:-118.41919},
{x:38.89484,y:-77.02486},
{x:40.31904,y:-75.30653},
{x:39.01867,y:-84.63382},
{x:42.9114,y:-78.77218},
{x:41.89063,y:-87.61733},
{x:32.9016,y:-97.32343},
{x:41.64015,y:-70.99927},
{x:41.21622,y:-80.75289},
{x:29.65255,y:-95.27782},
{x:41.97159,y:-70.71693},
{x:42.16928,y:-72.64088},
{x:28.62704,y:-81.36383},
{x:38.5495,y:-121.42632},
{x:44.6304,y:-123.07797},
{x:47.52204,y:-122.3687},
{x:30.47071,y:-97.80394},
{x:33.66788,y:-112.11878},
{x:29.9858,y:-95.33999},
{x:43.63397,y:-70.3479},
{x:33.76212,y:-117.95627},
{x:41.80222,y:-88.1735},
{x:40.23651,y:-76.93607},
{x:43.66125,y:-116.3515},
{x:42.27979,y:-87.88258},
{x:29.98539,y:-95.34977},
{x:38.90506,y:-104.78049},
{x:36.08896,y:-80.26719},
{x:39.13393,y:-84.51567},
{x:39.95205,y:-86.1641},
{x:26.07264,y:-80.1396},
{x:41.69242,y:-72.76573},
{x:37.67038,y:-122.47089},
{x:37.41392,y:-121.87647},
{x:42.26671,y:-88.99488},
{x:44.90059,y:-93.56896},
{x:39.95614,y:-86.01084},
{x:39.67962,y:-86.14861},
{x:33.60312,y:-111.97891},
{x:40.66285,y:-74.41698},
{x:44.91757,y:-93.18786},
{x:39.8524,y:-86.2636},
{x:36.35759,y:-94.25287},
{x:33.79199,y:-84.28609},
{x:37.72171,y:-97.36264},
{x:39.09785,y:-76.80814},
{x:37.64882,y:-97.35299},
{x:39.37078,y:-84.37218},
{x:35.11486,y:-89.80123},
{x:37.99206,y:-85.7064},
{x:28.05409,y:-82.44256},
{x:38.1667,y:-84.89783},
{x:42.38608,y:-71.11418},
{x:34.06845,y:-84.28088},
{x:33.94319,y:-84.12305},
{x:31.44096,y:-100.46632},
{x:32.02442,y:-102.14437},
{x:33.36642,y:-111.47497},
{x:40.07886,y:-75.02529},
{x:39.18051,y:-76.66712},
{x:39.17912,y:-76.67014},
{x:40.69576,y:-74.17326},
{x:40.69488,y:-74.17647},
{x:30.04246,y:-94.07496},
{x:27.71288,y:-82.6856},
{x:33.83151,y:-118.23985},
{x:39.54679,y:-76.31527},
{x:38.35412,y:-81.64354},
{x:42.81153,y:-85.67373},
{x:34.34934,y:-84.04965},
{x:38.62744,y:-76.91675},
{x:35.41228,y:-119.11137},
{x:33.86443,y:-118.35208},
{x:39.74526,y:-105.0059},
{x:42.56121,y:-83.02889},
{x:34.02575,y:-118.30867},
{x:40.12664,y:-74.22177},
{x:25.80896,y:-80.31514},
{x:25.92601,y:-80.30877},
{x:32.31621,y:-106.74405},
{x:36.11504,y:-115.20214},
{x:36.14305,y:-115.06602},
{x:34.13798,y:-118.35513},
{x:40.66723,y:-111.98455},
{x:38.76702,y:-90.27998},
{x:39.87588,y:-75.54441},
{x:36.17822,y:-115.17725},
{x:36.25993,y:-115.15568},
{x:36.0414,y:-115.17168},
{x:28.55336,y:-80.84844},
{x:40.05409,y:-82.86312},
{x:47.59075,y:-117.56138},
{x:29.98795,y:-95.34975},
{x:33.91818,y:-84.35086},
{x:40.72635,y:-73.48282},
{x:47.32222,y:-122.58844},
{x:34.00379,y:-84.17067},
{x:40.80639,y:-73.28863},
{x:33.99565,y:-117.05864},
{x:39.7733,y:-104.81073},
{x:36.14406,y:-115.18585},
{x:40.70921,y:-74.01415},
{x:40.76044,y:-73.98719},
{x:36.06215,y:-115.17194},
{x:39.53531,y:-104.88024},
{x:21.29009,y:-157.83979},
{x:40.65605,y:-73.61314},
{x:30.38859,y:-97.65028},
{x:29.5914,y:-98.58831},
{x:34.00348,y:-81.10783},
{x:40.73458,y:-73.86916},
{x:39.33592,y:-76.63077},
{x:34.11113,y:-118.28719},
{x:35.33783,y:-82.44316},
{x:33.80715,y:-84.3667},
{x:33.75105,y:-78.81533},
{x:43.06942,y:-77.43898},
{x:38.60071,y:-90.43484},
{x:38.62919,y:-90.34024},
{x:34.10757,y:-118.03127},
{x:29.73571,y:-95.60455},
{x:40.99013,y:-73.8081},
{x:29.94975,y:-90.06988},
{x:28.25168,y:-81.31286},
{x:41.78013,y:-87.64511},
{x:39.33512,y:-84.52461},
{x:41.2388,y:-81.81001},
{x:36.01658,y:-79.897},
{x:35.96077,y:-78.95821},
{x:41.81938,y:-73.08136},
{x:41.24302,y:-75.88757},
{x:40.69397,y:-74.17261},
{x:34.15495,y:-118.32415},
{x:40.78786,y:-82.59242},
{x:45.52026,y:-123.0493},
{x:45.33512,y:-122.76507},
{x:40.3126,y:-76.80074},
{x:40.98106,y:-74.11696},
{x:40.15274,y:-75.00721},
{x:33.90144,y:-118.08231},
{x:25.91101,y:-80.3193},
{x:33.02073,y:-117.12535},
{x:41.44128,y:-71.48861},
{x:42.35176,y:-83.05632},
{x:40.7637,y:-111.84748},
{x:45.03831,y:-93.01861},
{x:43.54265,y:-96.73103},
{x:32.99917,y:-96.96153},
{x:32.93268,y:-96.80319},
{x:44.92658,y:-92.95929},
{x:40.02173,y:-86.16141},
{x:32.74958,y:-117.15457},
{x:43.03405,y:-82.45644},
{x:39.08152,y:-76.68942},
{x:39.68384,y:-77.71944},
{x:38.30509,y:-77.5087},
{x:21.33031,y:-157.91689},
{x:29.39959,y:-98.62233},
{x:21.31619,y:-157.85532},
{x:38.84864,y:-77.35297},
{x:34.04613,y:-118.37617},
{x:34.04757,y:-117.81086},
{x:38.97987,y:-94.63335},
{x:45.80629,y:-108.47568},
{x:37.37727,y:-77.51244},
{x:29.15001,y:-80.97106},
{x:46.97693,y:-120.54223},
{x:42.19464,y:-88.11043},
{x:47.62993,y:-122.34005},
{x:40.69026,y:-73.9832},
{x:40.54856,y:-75.59431},
{x:38.42596,y:-78.83995},
{x:40.14007,y:-76.5901},
{x:44.06623,y:-92.52627},
{x:47.62384,y:-117.53966},
{x:21.33379,y:-157.92347},
{x:39.21099,y:-78.17565},
{x:40.19612,y:-77.17993},
{x:40.19495,y:-76.98182},
{x:39.97022,y:-83.00124},
{x:26.18793,y:-98.21449},
{x:40.86183,y:-111.89796},
{x:39.27606,y:-80.28179},
{x:30.00785,y:-90.15559},
{x:38.77562,y:-76.06075},
{x:39.44351,y:-76.62709},
{x:44.04672,y:-121.33112},
{x:38.29032,y:-121.30996},
{x:26.02992,y:-80.36013},
{x:40.75705,y:-73.98114},
{x:39.75794,y:-104.90262},
{x:36.32154,y:-95.82304},
{x:38.32867,y:-85.76042},
{x:38.05135,y:-84.71339},
{x:39.01281,y:-95.76196},
{x:41.0253,y:-80.73589},
{x:41.43437,y:-81.88106},
{x:33.1757,y:-96.83686},
{x:38.55539,y:-90.41068},
{x:30.41205,y:-88.78228},
{x:41.87465,y:-87.62882},
{x:33.97558,y:-118.35791},
{x:32.70911,y:-117.08525},
{x:36.58996,y:-82.26188},
{x:36.03606,y:-115.04783},
{x:34.07189,y:-117.59379},
{x:36.74345,y:-119.77353},
{x:39.7042,y:-105.13799},
{x:33.84368,y:-117.99866},
{x:39.95394,y:-75.20032},
{x:28.43049,y:-81.30741},
{x:42.0998,y:-88.28649},
{x:40.49525,y:-80.24607},
{x:40.05864,y:-82.97309},
{x:44.03444,y:-121.30902},
{x:32.60691,y:-97.11802},
{x:41.16105,y:-104.76459},
{x:33.68413,y:-117.169},
{x:39.69658,y:-86.07763},
{x:40.79984,y:-89.61158},
{x:41.79521,y:-87.59677},
{x:38.06555,y:-84.52693},
{x:39.55974,y:-104.78276},
{x:45.72096,y:-122.66284},
{x:33.68524,y:-118.00801},
{x:46.19713,y:-119.16135},
{x:47.35647,y:-122.11841},
{x:47.82175,y:-122.2955},
{x:34.04753,y:-118.33957},
{x:33.13068,y:-117.16683},
{x:39.91368,y:-86.13911},
{x:32.71253,y:-117.16335},
{x:33.93621,y:-118.12713},
{x:47.76758,y:-117.40244},
{x:38.6598,y:-77.01605},
{x:43.70283,y:-70.28849},
{x:41.70152,y:-83.65318},
{x:32.01123,y:-97.09426},
{x:30.01672,y:-95.44286},
{x:42.46192,y:-83.56172},
{x:29.51853,y:-98.49586},
{x:33.62668,y:-111.99575},
{x:33.42167,y:-111.95195},
{x:32.35542,y:-111.09241},
{x:36.7,y:-121.62009},
{x:38.81001,y:-121.28332},
{x:38.46179,y:-122.71738},
{x:36.79161,y:-119.82616},
{x:34.15052,y:-117.47261},
{x:36.83775,y:-119.70046},
{x:33.75969,y:-116.29766},
{x:41.88807,y:-88.30759},
{x:41.67627,y:-72.83954},
{x:42.16072,y:-71.7368},
{x:33.64613,y:-117.74419},
{x:34.15085,y:-118.01827},
{x:41.46732,y:-73.4862},
{x:27.89431,y:-82.66855},
{x:28.09475,y:-80.56827},
{x:41.65827,y:-91.53439},
{x:40.45286,y:-86.91528},
{x:37.73832,y:-97.24408},
{x:39.39558,y:-76.60406},
{x:39.18845,y:-84.36353},
{x:37.14785,y:-93.29556},
{x:46.58624,y:-120.49782},
{x:35.52365,y:-82.52905},
{x:40.55199,y:-74.43507},
{x:40.63157,y:-74.02756},
{x:45.53462,y:-122.87683},
{x:40.18415,y:-80.21985},
{x:35.54397,y:-82.65929},
{x:43.51445,y:-96.76984},
{x:35.02999,y:-85.15606},
{x:38.33556,y:-75.10077},
{x:26.5297,y:-81.75496},
{x:38.97223,y:-76.95084},
{x:42.19838,y:-83.26035},
{x:38.80899,y:-76.89552},
{x:31.89215,y:-102.34736},
{x:42.67037,y:-83.01017},
{x:32.75476,y:-97.33205},
{x:38.23494,y:-104.66467},
{x:41.03557,y:-74.03071},
{x:38.9471,y:-76.48732},
{x:40.80731,y:-73.29398},
{x:33.65017,y:-84.01013},
{x:34.30337,y:-88.70641},
{x:41.71854,y:-111.83492},
{x:33.43459,y:-111.86929},
{x:41.88298,y:-87.64145},
{x:33.40681,y:-84.68157},
{x:32.75632,y:-117.03952},
{x:46.5985,y:-112.03081},
{x:28.43137,y:-81.30678},
{x:37.09455,y:-113.52417},
{x:31.13742,y:-97.76516},
{x:41.1441,y:-73.86187},
{x:42.33554,y:-71.03549},
{x:33.67779,y:-117.67709},
{x:44.90524,y:-93.28804},
{x:32.82735,y:-116.90099},
{x:37.18033,y:-93.23004},
{x:36.15651,y:-86.80741},
{x:29.51589,y:-98.49962},
{x:38.57448,y:-121.4164},
{x:36.91884,y:-121.78255},
{x:32.62493,y:-85.4466},
{x:33.81633,y:-118.30761},
{x:43.1763,y:-87.91208},
{x:32.79088,y:-79.93954},
{x:44.00855,y:-88.58122},
{x:33.7009,y:-117.88444},
{x:40.59198,y:-79.94836},
{x:40.06868,y:-76.31312},
{x:37.38835,y:-122.03036},
{x:42.23532,y:-71.17965},
{x:40.05534,y:-84.24138},
{x:37.65953,y:-77.63769},
{x:48.75631,y:-122.46393},
{x:34.03881,y:-84.58563},
{x:33.95301,y:-80.9903},
{x:25.78847,y:-80.38105},
{x:27.22978,y:-82.49523},
{x:41.88772,y:-87.62443},
{x:34.27206,y:-118.77768},
{x:43.08759,y:-77.62542},
{x:38.33498,y:-122.67769},
{x:37.00242,y:-121.55127},
{x:34.13338,y:-117.92637},
{x:34.11596,y:-117.8315},
{x:36.83758,y:-119.84709},
{x:33.73621,y:-118.29214},
{x:47.79757,y:-122.21178},
{x:38.66163,y:-121.35472},
{x:33.9465,y:-117.39699},
{x:40.89278,y:-73.01665},
{x:40.87671,y:-73.17041},
{x:39.97523,y:-76.76685},
{x:42.62925,y:-82.86078},
{x:36.34894,y:-92.3727},
{x:26.84622,y:-80.06009},
{x:41.70501,y:-87.73994},
{x:39.59968,y:-110.82403},
{x:41.75125,y:-87.74191},
{x:37.66433,y:-97.24394},
{x:37.67493,y:-97.38985},
{x:40.4446,y:-79.94854},
{x:29.9903,y:-95.73716},
{x:36.03205,y:-86.7876},
{x:42.43569,y:-83.48337},
{x:41.66248,y:-83.68408},
{x:29.5962,y:-95.62259},
{x:40.08015,y:-82.43075},
{x:38.95601,y:-92.36406},
{x:38.87554,y:-99.31654},
{x:38.16764,y:-85.82614},
{x:42.50256,y:-83.11923},
{x:35.9175,y:-86.83181},
{x:37.66898,y:-121.85738},
{x:48.2379,y:-122.32919},
{x:34.59107,y:-112.33974},
{x:44.24431,y:-88.35724},
{x:35.58427,y:-120.69696},
{x:31.11567,y:-97.41184},
{x:37.97807,y:-121.9929},
{x:36.13162,y:-79.79613},
{x:36.09018,y:-80.2745},
{x:38.70626,y:-121.32629},
{x:32.76193,y:-79.97656},
{x:42.36721,y:-71.0167},
{x:36.14763,y:-79.86947},
{x:36.92954,y:-93.90423},
{x:44.37942,y:-103.72604},
{x:38.78937,y:-121.28802},
{x:34.21938,y:-78.01925},
{x:37.33858,y:-76.75334},
{x:39.02542,y:-77.396},
{x:37.69398,y:-97.29938},
{x:36.17542,y:-94.17716},
{x:35.33915,y:-94.38259},
{x:38.94241,y:-95.25619},
{x:37.71342,y:-85.83132},
{x:38.67946,y:-90.49976},
{x:48.71473,y:-122.47784},
{x:26.80811,y:-80.0961},
{x:27.91611,y:-82.76183},
{x:27.3124,y:-80.40644},
{x:41.56633,y:-88.19817},
{x:33.07696,y:-97.13059},
{x:32.93807,y:-97.18424},
{x:33.11491,y:-95.59615},
{x:33.1148,y:-96.69774},
{x:33.50565,y:-101.92185},
{x:32.48025,y:-99.69489},
{x:32.75651,y:-97.71158},
{x:46.8613,y:-96.8682},
{x:38.9035,y:-94.34504},
{x:35.7465,y:-78.83024},
{x:45.49001,y:-122.7985},
{x:35.45364,y:-79.1373},
{x:38.99148,y:-104.80817},
{x:33.60244,y:-112.29015},
{x:37.91996,y:-122.3475},
{x:37.96993,y:-121.28836},
{x:45.301,y:-122.77401},
{x:33.00503,y:-83.54598},
{x:34.67588,y:-84.47837},
{x:39.1014,y:-76.56532},
{x:40.77484,y:-73.98413},
{x:33.23075,y:-97.59593},
{x:33.62416,y:-97.1491},
{x:29.46619,y:-98.52747},
{x:41.17611,y:-112.00753},
{x:39.65616,y:-79.94486},
{x:39.95382,y:-86.16394},
{x:35.86252,y:-84.06661},
{x:32.54197,y:-82.9515},
{x:47.90948,y:-122.22543},
{x:32.08122,y:-81.09307},
{x:33.94615,y:-83.40939},
{x:47.46498,y:-122.13735},
{x:42.09486,y:-71.04941},
{x:39.58347,y:-104.988},
{x:35.29516,y:-119.027},
{x:31.89327,y:-102.33508},
{x:41.61796,y:-87.04052},
{x:47.80728,y:-122.20636},
{x:40.00664,y:-105.27181},
{x:35.42136,y:-97.40461},
{x:27.96042,y:-82.71976},
{x:47.66995,y:-117.40893},
{x:45.52313,y:-122.69868},
{x:41.92186,y:-88.27109},
{x:42.21671,y:-88.25463},
{x:47.60259,y:-117.36586},
{x:36.001,y:-115.08367},
{x:32.65034,y:-117.08689},
{x:33.90957,y:-117.85276},
{x:37.16601,y:-93.26222},
{x:44.45887,y:-87.98837},
{x:41.99287,y:-87.711},
{x:41.69989,y:-86.21571},
{x:41.17418,y:-104.82898},
{x:41.25834,y:-96.02348},
{x:27.83964,y:-82.73902},
{x:29.59964,y:-95.56378},
{x:33.86119,y:-118.09343},
{x:43.379,y:-84.6664},
{x:32.91521,y:-96.81799},
{x:42.35082,y:-71.06987},
{x:45.55437,y:-122.60271},
{x:35.12556,y:-118.46933},
{x:37.11082,y:-113.55397},
{x:47.60486,y:-122.33971},
{x:34.17714,y:-119.19463},
{x:31.82249,y:-81.59993},
{x:34.06379,y:-118.28394},
{x:34.20025,y:-118.37},
{x:33.24666,y:-111.78909},
{x:45.6238,y:-122.5872},
{x:36.57683,y:-119.63213},
{x:33.03954,y:-80.14358},
{x:35.4456,y:-82.53276},
{x:40.46734,y:-106.8254},
{x:47.16643,y:-122.5093},
{x:40.70656,y:-73.66073},
{x:40.54209,y:-74.20693},
{x:33.65217,y:-111.92446},
{x:18.46075,y:-66.0807},
{x:18.39234,y:-65.97534},
{x:18.23437,y:-66.05814},
{x:18.39999,y:-66.1081},
{x:18.35771,y:-66.0601},
{x:18.39432,y:-65.99263},
{x:18.43838,y:-66.00313},
{x:18.46367,y:-66.08532},
{x:18.42692,y:-66.07156},
{x:18.45562,y:-66.07067},
{x:18.46537,y:-66.11237},
{x:18.36968,y:-66.07406},
{x:18.45158,y:-66.06398},
{x:18.42321,y:-66.16193},
{x:18.42095,y:-66.07392},
{x:18.46456,y:-66.28259},
{x:37.87443,y:-87.56758},
{x:39.71092,y:-105.02251},
{x:36.78007,y:-119.78673},
{x:37.97676,y:-87.63282},
{x:38.69892,y:-90.42062},
{x:38.40774,y:-121.44968},
{x:34.90056,y:-82.33739},
{x:39.03329,y:-76.91022},
{x:42.57991,y:-72.18776},
{x:39.61824,y:-83.60393},
{x:30.53435,y:-97.81782},
{x:39.74053,y:-104.95275},
{x:38.91601,y:-75.44165},
{x:42.2058,y:-121.71997},
{x:32.46185,y:-99.70511},
{x:33.58865,y:-102.36638},
{x:33.16773,y:-84.3031},
{x:39.60413,y:-75.74772},
{x:27.97655,y:-82.53221},
{x:36.10942,y:-115.17348},
{x:27.89314,y:-82.78456},
{x:44.87401,y:-93.22864},
{x:39.05208,y:-77.45255},
{x:35.05366,y:-80.76912},
{x:41.90922,y:-87.80259},
{x:42.0116,y:-87.7579},
{x:29.69829,y:-95.81376},
{x:33.09014,y:-96.807},
{x:26.61104,y:-81.94039},
{x:34.23576,y:-118.5539},
{x:35.64494,y:-120.66032},
{x:42.29214,y:-71.51992},
{x:42.46689,y:-73.19542},
{x:38.78564,y:-90.35313},
{x:42.0977,y:-79.29118},
{x:32.96687,y:-97.04281},
{x:33.66278,y:-95.50936},
{x:34.42019,y:-119.70407},
{x:42.59419,y:-83.60414},
{x:29.03843,y:-95.4211},
{x:27.98013,y:-82.53498},
{x:42.52605,y:-82.9053},
{x:39.74483,y:-105.05572},
{x:41.4673,y:-75.63691},
{x:33.95607,y:-118.39775},
{x:37.13652,y:-80.41377},
{x:34.14273,y:-80.95148},
{x:32.77949,y:-117.01258},
{x:33.4305,y:-111.93402},
{x:18.41416,y:-66.11927},
{x:26.267,y:-98.21971},
{x:39.66527,y:-75.60694},
{x:27.6994,y:-97.41106},
{x:26.07416,y:-97.22732},
{x:29.58826,y:-98.47518},
{x:27.85671,y:-97.64561},
{x:37.00578,y:-121.55061},
{x:26.18115,y:-98.18548},
{x:35.13182,y:-106.55147},
{x:42.86209,y:-71.35254},
{x:47.68139,y:-122.29063},
{x:42.71959,y:-87.84844},
{x:39.9727,y:-83.04615},
{x:40.55055,y:-105.05855},
{x:38.89233,y:-99.31703},
{x:40.71601,y:-99.0844},
{x:33.13545,y:-117.12107},
{x:30.36142,y:-91.01301},
{x:29.55766,y:-98.51703},
{x:41.5724,y:-72.33483},
{x:34.10732,y:-117.74019},
{x:26.19705,y:-81.80161},
{x:43.51199,y:-96.76879},
{x:34.00106,y:-118.08647},
{x:38.6008,y:-121.40239},
{x:40.87191,y:-74.005},
{x:33.82693,y:-118.3458},
{x:33.77434,y:-118.11958},
{x:28.23099,y:-80.72196},
{x:27.98355,y:-82.53244},
{x:30.16024,y:-95.45753},
{x:26.04712,y:-80.25104},
{x:39.86742,y:-105.05391},
{x:41.66252,y:-81.24082},
{x:28.0018,y:-81.97575},
{x:41.75082,y:-87.97334},
{x:47.51949,y:-111.30968},
{x:43.59164,y:-116.2454},
{x:31.73087,y:-98.97652},
{x:27.28194,y:-82.53126},
{x:40.83654,y:-81.38985},
{x:41.64249,y:-91.52944},
{x:24.94298,y:-80.60597},
{x:28.20194,y:-82.66594},
{x:27.97865,y:-82.82763},
{x:41.81024,y:-72.55001},
{x:35.02274,y:-106.71394},
{x:39.73238,y:-105.08194},
{x:35.95337,y:-78.52549},
{x:33.8561,y:-84.58213},
{x:30.17131,y:-93.24962},
{x:40.86192,y:-96.68258},
{x:41.60384,y:-87.73735},
{x:35.9428,y:-78.54094},
{x:33.90352,y:-118.15061},
{x:33.98816,y:-118.39269},
{x:35.12281,y:-114.58163},
{x:33.83219,y:-118.16443},
{x:25.80506,y:-80.12503},
{x:40.0042,y:-105.10348},
{x:40.98442,y:-73.77704},
{x:35.17127,y:-80.85069},
{x:26.20261,y:-80.15393},
{x:39.51856,y:-119.88746},
{x:44.05188,y:-121.31067},
{x:42.32217,y:-83.45398},
{x:45.679,y:-111.05264},
{x:40.67721,y:-73.98294},
{x:42.37534,y:-71.06384},
{x:40.8511,y:-73.84411},
{x:40.92033,y:-74.06643},
{x:44.09488,y:-123.09656},
{x:37.99177,y:-121.31515},
{x:33.79824,y:-118.12302},
{x:35.29636,y:-81.57228},
{x:42.38139,y:-88.06595},
{x:44.05788,y:-123.00046},
{x:35.82067,y:-90.70568},
{x:26.23578,y:-98.20651},
{x:33.56725,y:-112.15135},
{x:35.93937,y:-86.52869},
{x:43.03385,y:-70.78332},
{x:40.03631,y:-74.82537},
{x:39.22119,y:-76.87512},
{x:39.49563,y:-76.64986},
{x:39.81163,y:-75.16506},
{x:30.18944,y:-95.45408},
{x:31.76775,y:-106.31938},
{x:44.28065,y:-85.40416},
{x:44.98376,y:-93.26721},
{x:45.10487,y:-95.04211},
{x:38.90387,y:-94.52734},
{x:31.77442,y:-106.50049},
{x:39.89542,y:-74.94373},
{x:35.38455,y:-119.04351},
{x:39.14581,y:-84.42897},
{x:38.79568,y:-77.60914},
{x:29.80307,y:-95.75239},
{x:29.62022,y:-95.56844},
{x:42.27317,y:-84.98514},
{x:36.0987,y:-82.86412},
{x:38.23282,y:-77.50436},
{x:25.76296,y:-80.19241},
{x:41.43157,y:-82.69487},
{x:47.58192,y:-122.13495},
{x:29.79262,y:-94.98384},
{x:33.7259,y:-117.9857},
{x:33.78316,y:-118.06959},
{x:33.82672,y:-117.51634},
{x:43.14999,y:-93.25251},
{x:33.8655,y:-117.74725},
{x:47.62667,y:-122.62828},
{x:38.77775,y:-76.08547},
{x:44.56904,y:-92.58472},
{x:29.78265,y:-95.54156},
{x:43.03167,y:-88.04767},
{x:40.82432,y:-73.83676},
{x:40.66685,y:-73.98177},
{x:32.67717,y:-117.09219},
{x:35.33924,y:-119.04651},
{x:38.98853,y:-76.98839},
{x:33.77402,y:-117.99244},
{x:33.86046,y:-118.15031},
{x:34.0897,y:-118.05409},
{x:34.27235,y:-118.46785},
{x:36.32661,y:-119.60474},
{x:33.4222,y:-111.9369},
{x:32.74867,y:-117.12497},
{x:33.6796,y:-116.2953},
{x:33.87459,y:-118.08199},
{x:34.41643,y:-103.19647},
{x:32.72724,y:-103.14329},
{x:41.93269,y:-87.93797},
{x:35.55002,y:-100.97193},
{x:33.74523,y:-117.81282},
{x:40.74545,y:-73.98217},
{x:40.7492,y:-73.97533},
{x:28.82253,y:-81.70172},
{x:27.79153,y:-82.67993},
{x:47.48887,y:-122.16314},
{x:40.78013,y:-73.95956},
{x:40.7642,y:-73.96427},
{x:35.03396,y:-77.01272},
{x:35.70291,y:-78.79279},
{x:40.33356,y:-94.87216},
{x:37.60766,y:-122.06244},
{x:40.81403,y:-74.38076},
{x:34.07548,y:-117.37074},
{x:39.94542,y:-75.17865},
{x:40.93521,y:-74.07009},
{x:37.78342,y:-122.43288},
{x:37.19087,y:-76.48889},
{x:35.3092,y:-119.09252},
{x:39.67774,y:-75.6528},
{x:34.12855,y:-117.86641},
{x:32.80295,y:-117.00634},
{x:47.61004,y:-122.34259},
{x:33.59944,y:-117.17596},
{x:47.66268,y:-122.29974},
{x:47.61772,y:-122.20395},
{x:32.6884,y:-114.49731},
{x:35.90396,y:-79.04939},
{x:35.78454,y:-86.91526},
{x:47.67263,y:-122.11059},
{x:38.97041,y:-95.30407},
{x:29.78936,y:-95.4109},
{x:47.26917,y:-122.51535},
{x:47.19218,y:-122.45948},
{x:47.16155,y:-122.51846},
{x:47.66887,y:-122.385},
{x:38.626,y:-90.18829},
{x:33.62262,y:-112.42504},
{x:47.54463,y:-122.38752},
{x:47.62105,y:-122.35079},
{x:46.29866,y:-119.27477},
{x:47.69463,y:-116.78862},
{x:48.77232,y:-122.46087},
{x:47.38826,y:-122.04138},
{x:47.2229,y:-122.47114},
{x:48.00146,y:-122.1053},
{x:47.46096,y:-122.33352},
{x:47.44218,y:-122.19741},
{x:47.67055,y:-122.12001},
{x:47.62499,y:-122.15459},
{x:47.7119,y:-122.18703},
{x:47.24552,y:-122.43708},
{x:47.00172,y:-120.53471},
{x:47.75467,y:-122.15543},
{x:47.79029,y:-122.21835},
{x:47.68202,y:-117.28378},
{x:47.7907,y:-122.3647},
{x:43.62425,y:-116.21235},
{x:43.68736,y:-116.33407},
{x:47.60208,y:-122.28538},
{x:47.11873,y:-122.29243},
{x:47.09504,y:-122.6238},
{x:47.29933,y:-122.36031},
{x:43.60469,y:-116.28263},
{x:46.99906,y:-122.82435},
{x:47.58329,y:-122.03392},
{x:47.32264,y:-122.35691},
{x:47.5586,y:-122.1146},
{x:47.87794,y:-122.20332},
{x:47.16953,y:-122.17},
{x:47.36038,y:-122.02037},
{x:47.86234,y:-121.9807},
{x:47.51919,y:-122.62719},
{x:48.84495,y:-122.5839},
{x:43.59946,y:-116.19299},
{x:46.14426,y:-122.92666},
{x:47.65884,y:-117.42304},
{x:47.88146,y:-122.23837},
{x:47.61259,y:-122.03507},
{x:45.63656,y:-122.50647},
{x:47.82057,y:-122.28804},
{x:47.6106,y:-122.2007},
{x:48.50802,y:-122.6133},
{x:47.75669,y:-122.2368},
{x:47.66207,y:-117.19582},
{x:48.28575,y:-122.66058},
{x:46.06701,y:-118.33807},
{x:47.15642,y:-122.29247},
{x:48.73116,y:-122.47108},
{x:45.67786,y:-122.66248},
{x:47.80922,y:-122.20572},
{x:47.61255,y:-122.34277},
{x:47.73357,y:-122.31311},
{x:47.61223,y:-122.33377},
{x:48.42187,y:-122.30747},
{x:47.72433,y:-122.34549},
{x:48.76967,y:-122.44386},
{x:48.15269,y:-122.18053},
{x:47.57883,y:-122.41115},
{x:47.6091,y:-122.14256},
{x:47.45569,y:-122.25656},
{x:47.54163,y:-122.22577},
{x:47.61594,y:-122.19653},
{x:45.78141,y:-122.54874},
{x:47.54734,y:-122.03931},
{x:47.77598,y:-122.31035},
{x:48.07673,y:-122.18324},
{x:47.54592,y:-122.05956},
{x:47.60936,y:-122.31657},
{x:47.59685,y:-120.65941},
{x:46.7288,y:-122.97986},
{x:47.58639,y:-122.23525},
{x:47.66641,y:-122.19678},
{x:47.44252,y:-122.33479},
{x:47.57734,y:-122.16894},
{x:47.65078,y:-122.69251},
{x:47.79995,y:-122.33204},
{x:47.03609,y:-122.93929},
{x:47.74258,y:-117.4094},
{x:45.62736,y:-122.67402},
{x:47.60528,y:-122.33437},
{x:45.72333,y:-122.65273},
{x:47.73393,y:-122.63634},
{x:47.41067,y:-120.29063},
{x:47.35983,y:-122.60694},
{x:47.67078,y:-122.11398},
{x:46.40663,y:-117.00138},
{x:47.62293,y:-122.3569},
{x:47.76135,y:-122.17626},
{x:46.73308,y:-117.02812},
{x:46.73918,y:-117.17044},
{x:47.44074,y:-122.22214},
{x:47.70824,y:-122.3321},
{x:47.45879,y:-122.25899},
{x:45.42172,y:-122.78393},
{x:44.07056,y:-123.10448},
{x:47.0594,y:-122.76628},
{x:46.59324,y:-120.6048},
{x:46.99883,y:-122.9161},
{x:45.40199,y:-122.28153},
{x:45.44393,y:-122.62763},
{x:45.61412,y:-122.50507},
{x:47.52272,y:-122.36557},
{x:47.74046,y:-117.48869},
{x:47.67045,y:-117.10431},
{x:47.61432,y:-122.33586},
{x:46.67687,y:-122.97942},
{x:47.10143,y:-119.2471},
{x:47.24599,y:-122.20818},
{x:47.31545,y:-122.30704},
{x:45.43376,y:-122.56198},
{x:47.35841,y:-122.11726},
{x:47.3044,y:-122.57992},
{x:45.43725,y:-122.75747},
{x:47.58103,y:-122.38407},
{x:47.925,y:-122.09733},
{x:47.58701,y:-122.23689},
{x:47.6644,y:-122.29897},
{x:47.84943,y:-122.2522},
{x:47.38086,y:-122.25289},
{x:47.47412,y:-122.21612},
{x:47.0478,y:-122.83231},
{x:45.49823,y:-122.40956},
{x:45.59636,y:-122.47769},
{x:46.61923,y:-120.56134},
{x:47.54616,y:-122.32562},
{x:44.05243,y:-123.09964},
{x:44.63173,y:-123.07285},
{x:47.90612,y:-122.29405},
{x:47.20579,y:-122.48348},
{x:47.67902,y:-122.17795},
{x:44.59255,y:-123.25343},
{x:32.80418,y:-116.95082},
{x:40.13071,y:-76.30534},
{x:34.10657,y:-117.93835},
{x:47.22178,y:-122.53752},
{x:37.09901,y:-121.01556},
{x:40.74515,y:-73.99886},
{x:39.52633,y:-122.21253},
{x:38.56763,y:-121.43427},
{x:36.02607,y:-115.11923},
{x:33.9242,y:-118.32588},
{x:32.35543,y:-86.21685},
{x:28.01389,y:-81.95755},
{x:30.36466,y:-87.16922},
{x:26.14792,y:-80.26854},
{x:34.07,y:-83.91292},
{x:40.77897,y:-89.67634},
{x:42.17815,y:-71.19046},
{x:42.46855,y:-73.19691},
{x:45.76375,y:-108.57672},
{x:40.57786,y:-73.96113},
{x:36.06024,y:-95.88592},
{x:45.65814,y:-122.58465},
{x:31.09189,y:-97.73285},
{x:29.69776,y:-95.29769},
{x:32.96435,y:-96.6445},
{x:29.56348,y:-95.6849},
{x:37.23509,y:-80.43411},
{x:47.64102,y:-122.32566},
{x:47.68289,y:-117.41153},
{x:47.16326,y:-122.48416},
{x:38.35397,y:-81.63795},
{x:33.59663,y:-86.646},
{x:33.68147,y:-117.81175},
{x:38.47929,y:-122.73511},
{x:37.38535,y:-121.92791},
{x:37.38742,y:-121.96014},
{x:37.4903,y:-122.24017},
{x:32.83042,y:-117.1537},
{x:37.46592,y:-121.17472},
{x:32.86597,y:-96.76348},
{x:41.03212,y:-73.76745},
{x:40.44148,y:-79.99488},
{x:32.75092,y:-97.13211},
{x:38.42627,y:-122.66871},
{x:38.29922,y:-122.47574},
{x:37.47739,y:-122.22343},
{x:44.44875,y:-73.20812},
{x:38.78838,y:-121.20648},
{x:33.09104,y:-96.68019},
{x:39.11001,y:-119.77684},
{x:33.41897,y:-86.66984},
{x:47.63971,y:-122.3998},
{x:39.77481,y:-76.67006},
{x:28.14712,y:-82.74122},
{x:44.86266,y:-93.28913},
{x:27.46289,y:-82.63656},
{x:39.92691,y:-122.19816},
{x:36.15789,y:-115.3121},
{x:47.67492,y:-122.12887},
{x:33.17446,y:-96.68225},
{x:37.82932,y:-122.27989},
{x:32.27372,y:-110.94532},
{x:37.9737,y:-120.3622},
{x:39.25445,y:-76.71298},
{x:42.7459,y:-73.80929},
{x:38.02238,y:-84.55944},
{x:20.75276,y:-156.44847},
{x:35.80645,y:-83.99087},
{x:38.66995,y:-76.87601},
{x:61.20825,y:-149.77692},
{x:47.65654,y:-122.20666},
{x:47.14989,y:-122.29252},
{x:48.79804,y:-122.48657},
{x:47.27123,y:-122.48902},
{x:37.86783,y:-85.94446},
{x:39.34622,y:-76.62051},
{x:35.84658,y:-86.36359},
{x:41.41342,y:-82.07392},
{x:34.08372,y:-118.38662},
{x:20.88685,y:-156.684},
{x:47.73336,y:-122.31063},
{x:47.71572,y:-117.4366},
{x:47.77134,y:-117.40488},
{x:48.43606,y:-122.33567},
{x:47.63242,y:-122.14056},
{x:47.63409,y:-122.28082},
{x:47.65995,y:-122.68645},
{x:45.47138,y:-122.68579},
{x:47.81783,y:-122.31749},
{x:47.70663,y:-122.21173},
{x:47.75232,y:-122.28021},
{x:47.47148,y:-122.21146},
{x:47.54166,y:-122.04766},
{x:35.53568,y:-108.7571},
{x:38.72746,y:-77.7957},
{x:33.41428,y:-111.54389},
{x:46.21207,y:-119.15941},
{x:42.36421,y:-71.15699},
{x:61.12948,y:-149.88049},
{x:21.44369,y:-157.7443},
{x:47.68105,y:-122.1259},
{x:40.57414,y:-105.11666},
{x:36.9615,y:-122.04507},
{x:45.62124,y:-122.55428},
{x:48.39917,y:-114.33625},
{x:47.29966,y:-122.36186},
{x:39.68838,y:-84.16175},
{x:47.64695,y:-121.91579},
{x:48.27924,y:-116.55348},
{x:47.93042,y:-122.22128},
{x:47.04368,y:-122.83431},
{x:35.04707,y:-85.30027},
{x:37.65597,y:-122.05609},
{x:47.7658,y:-122.3647},
{x:47.17754,y:-122.28924},
{x:41.58216,y:-71.99741},
{x:40.71232,y:-75.28824},
{x:40.30875,y:-75.85949},
{x:40.26226,y:-75.65061},
{x:30.48109,y:-90.46183},
{x:28.47857,y:-82.47964},
{x:40.81963,y:-73.93038},
{x:28.0356,y:-80.665},
{x:42.98961,y:-88.25992},
{x:30.70668,y:-95.56653},
{x:42.4737,y:-92.4636},
{x:37.04174,y:-76.39185},
{x:43.10684,y:-77.74644},
{x:19.64949,y:-156.00382},
{x:33.0085,y:-96.59271},
{x:43.04916,y:-70.81861},
{x:40.07533,y:-75.15506},
{x:40.63833,y:-75.44026},
{x:33.13283,y:-117.2279},
{x:36.27585,y:-115.20711},
{x:42.49401,y:-83.08419},
{x:39.24922,y:-84.79147},
{x:39.02517,y:-94.24956},
{x:39.44271,y:-77.99219},
{x:29.53862,y:-98.28719},
{x:34.90086,y:-92.14962},
{x:31.81709,y:-106.42827},
{x:47.68,y:-122.32552},
{x:38.62794,y:-90.34271},
{x:26.16816,y:-80.11505},
{x:47.61726,y:-122.13072},
{x:47.43863,y:-120.32644},
{x:48.68935,y:-116.31624},
{x:47.7006,y:-117.4476},
{x:41.78742,y:-71.3908},
{x:35.58577,y:-82.48835},
{x:35.61411,y:-82.63145},
{x:31.97083,y:-110.30955},
{x:42.79311,y:-83.73798},
{x:43.03538,y:-83.53772},
{x:40.72251,y:-73.79613},
{x:47.85955,y:-121.96518},
{x:37.15653,y:-76.57732},
{x:39.09036,y:-84.47832},
{x:29.66361,y:-95.73829},
{x:39.07452,y:-77.05656},
{x:40.6128,y:-75.53557},
{x:47.77683,y:-122.34363},
{x:34.10872,y:-117.5743},
{x:34.14139,y:-118.22393},
{x:35.53899,y:-82.8446},
{x:32.78335,y:-97.09351},
{x:35.32734,y:-82.4674},
{x:47.716,y:-117.42457},
{x:37.54314,y:-77.43655},
{x:33.45046,y:-111.70283},
{x:33.9482,y:-118.16748},
{x:39.91511,y:-75.33509},
{x:39.28986,y:-77.20266},
{x:38.66749,y:-90.58528},
{x:36.11363,y:-115.30696},
{x:37.97607,y:-87.47061},
{x:40.10122,y:-75.00739},
{x:38.8976,y:-77.44511},
{x:34.1219,y:-84.07685},
{x:33.58103,y:-111.97896},
{x:39.01954,y:-94.66582},
{x:41.65006,y:-88.20579},
{x:42.44878,y:-96.3325},
{x:33.82711,y:-84.32709},
{x:31.53345,y:-97.19213},
{x:45.51909,y:-122.6795},
{x:33.06305,y:-117.26566},
{x:45.48766,y:-122.79526},
{x:45.47361,y:-122.64887},
{x:43.86228,y:-116.4911},
{x:35.65737,y:-105.95971},
{x:47.70906,y:-122.32542},
{x:61.58106,y:-149.43562},
{x:37.67328,y:-122.46443},
{x:39.12045,y:-76.51441},
{x:45.33125,y:-122.58392},
{x:45.46863,y:-122.71142},
{x:38.97766,y:-77.09997},
{x:38.18457,y:-83.43442},
{x:45.40192,y:-122.79753},
{x:35.34797,y:-82.43028},
{x:35.73772,y:-81.71565},
{x:41.79595,y:-69.98501},
{x:41.70666,y:-72.59473},
{x:41.13819,y:-73.30148},
{x:45.52305,y:-122.63709},
{x:44.56382,y:-123.26256},
{x:45.36649,y:-122.61284},
{x:45.53015,y:-122.69439},
{x:44.90979,y:-123.04507},
{x:45.47977,y:-122.67269},
{x:45.47813,y:-122.69483},
{x:45.41752,y:-122.63324},
{x:45.52626,y:-122.61603},
{x:45.54839,y:-122.61534},
{x:45.42897,y:-122.54081},
{x:45.41615,y:-122.66327},
{x:44.03209,y:-121.30856},
{x:45.44296,y:-122.8058},
{x:42.18567,y:-122.68786},
{x:44.06527,y:-123.07543},
{x:45.51496,y:-122.67995},
{x:45.46673,y:-122.75172},
{x:45.51826,y:-122.84309},
{x:45.3831,y:-122.76693},
{x:44.59041,y:-123.27575},
{x:45.43346,y:-122.70183},
{x:45.5542,y:-122.83647},
{x:45.51609,y:-122.75458},
{x:45.45387,y:-122.57874},
{x:45.51417,y:-122.68263},
{x:45.02805,y:-84.69285},
{x:21.577,y:-158.10521},
{x:40.71417,y:-74.01543},
{x:41.40731,y:-73.41633},
{x:33.16573,y:-87.54901},
{x:38.92024,y:-76.95277},
{x:26.14824,y:-80.25531},
{x:26.56862,y:-81.8714},
{x:26.00836,y:-80.29873},
{x:26.00695,y:-80.38921},
{x:33.80592,y:-84.39352},
{x:38.53051,y:-121.95623},
{x:37.79605,y:-122.27614},
{x:37.81896,y:-122.26236},
{x:36.09611,y:-94.15205},
{x:35.1041,y:-80.94032},
{x:40.19358,y:-75.52615},
{x:40.13311,y:-75.45218},
{x:39.31706,y:-84.49334},
{x:39.07374,y:-84.35006},
{x:39.78422,y:-83.99546},
{x:41.26235,y:-85.84053},
{x:45.49782,y:-122.68569},
{x:43.78413,y:-88.40538},
{x:27.89355,y:-82.78072},
{x:39.26796,y:-76.59953},
{x:38.34653,y:-75.07797},
{x:40.71588,y:-73.60132},
{x:26.18878,y:-80.13493},
{x:38.91913,y:-94.3594},
{x:44.94006,y:-93.1536},
{x:44.04356,y:-75.80713},
{x:38.92887,y:-77.21583},
{x:32.91385,y:-96.95887},
{x:34.06944,y:-117.97708},
{x:38.83437,y:-77.04942},
{x:35.06485,y:-82.00172},
{x:33.82178,y:-111.92298},
{x:38.83901,y:-104.79389},
{x:36.74319,y:-108.16802},
{x:44.9381,y:-123.03659},
{x:47.62208,y:-122.13197},
{x:32.789,y:-96.80682},
{x:38.18377,y:-85.74059},
{x:45.58936,y:-122.59036},
{x:42.31229,y:-71.42718},
{x:38.18569,y:-85.74145},
{x:42.17105,y:-72.45444},
{x:47.61564,y:-122.03377},
{x:34.06364,y:-117.70751},
{x:32.39343,y:-93.72974},
{x:32.27506,y:-81.23117},
{x:33.36571,y:-111.60351},
{x:32.61068,y:-85.46332},
{x:30.11449,y:-95.55289},
{x:33.43283,y:-84.4584},
{x:41.71974,y:-83.56329},
{x:36.48843,y:-86.8634},
{x:29.92472,y:-95.20363},
{x:34.18687,y:-118.62651},
{x:29.73655,y:-95.81006},
{x:32.94969,y:-96.46035},
{x:34.15113,y:-118.07547},
{x:33.6807,y:-84.22801},
{x:37.10851,y:-113.59325},
{x:29.48904,y:-95.11401},
{x:38.30561,y:-85.57546},
{x:39.7593,y:-104.86692},
{x:33.75332,y:-118.3089},
{x:39.95713,y:-85.99978},
{x:37.05779,y:-84.62672},
{x:34.43577,y:-119.80399},
{x:42.32925,y:-72.63603},
{x:43.04213,y:-88.25649},
{x:41.52846,y:-87.7094},
{x:47.22333,y:-122.44035},
{x:43.09019,y:-88.1489},
{x:37.2564,y:-121.79253},
{x:37.72248,y:-97.28543},
{x:41.98993,y:-87.67094},
{x:41.83783,y:-87.6465},
{x:40.42173,y:-74.1864},
{x:31.18976,y:-83.76375},
{x:39.9556,y:-105.16521},
{x:31.95569,y:-81.3229},
{x:33.50131,y:-82.08815},
{x:30.45377,y:-90.13664},
{x:36.19951,y:-81.65979},
{x:30.11024,y:-81.41726},
{x:38.88379,y:-77.1741},
{x:34.15766,y:-118.32833},
{x:37.97986,y:-121.30909},
{x:43.09312,y:-87.88776},
{x:39.50443,y:-84.73249},
{x:40.39805,y:-74.22616},
{x:34.14456,y:-84.51254},
{x:42.16911,y:-72.45524},
{x:43.64793,y:-116.66627},
{x:30.11274,y:-95.38674},
{x:27.97945,y:-82.53828},
{x:38.73653,y:-90.39765},
{x:33.03316,y:-117.08029},
{x:32.97916,y:-117.08372},
{x:33.9056,y:-84.42901},
{x:37.74122,y:-122.38775},
{x:41.45656,y:-81.95129},
{x:41.85425,y:-103.66124},
{x:24.57105,y:-81.75438},
{x:19.64714,y:-155.99002},
{x:27.9764,y:-82.53703},
{x:38.21688,y:-122.14115},
{x:45.58877,y:-122.59605},
{x:25.79213,y:-80.21104},
{x:41.47038,y:-87.30261},
{x:45.54837,y:-122.65118},
{x:44.83464,y:-93.17166},
{x:38.92616,y:-104.71621},
{x:40.259,y:-82.92285},
{x:41.88548,y:-87.63848},
{x:41.46366,y:-74.37458},
{x:34.27651,y:-119.22634},
{x:27.26482,y:-80.41644},
{x:33.50897,y:-112.35745},
{x:32.73627,y:-79.96708},
{x:47.30226,y:-122.57588},
{x:36.01418,y:-114.96399},
{x:34.42694,y:-118.53522},
{x:47.48981,y:-122.16164},
{x:34.10811,y:-117.61202},
{x:32.23816,y:-111.00399},
{x:34.24069,y:-118.26804},
{x:36.04202,y:-114.98213},
{x:41.99646,y:-87.80652},
{x:33.71171,y:-112.20569},
{x:47.92899,y:-122.21885},
{x:36.19332,y:-115.3061},
{x:32.21991,y:-110.77121},
{x:47.74103,y:-122.34383},
{x:47.51801,y:-122.62984},
{x:32.96084,y:-117.15368},
{x:47.11275,y:-122.43202},
{x:39.92254,y:-105.09135},
{x:31.13128,y:-97.77521},
{x:34.08631,y:-118.14993},
{x:35.21731,y:-81.15881},
{x:31.42171,y:-100.48077},
{x:40.65611,y:-73.59219},
{x:33.08954,y:-96.68471},
{x:36.03476,y:-115.20665},
{x:32.80344,y:-96.79184},
{x:39.75949,y:-74.24626},
{x:40.70813,y:-73.70396},
{x:35.06988,y:-92.45576},
{x:40.79249,y:-77.86192},
{x:41.7997,y:-87.59405},
{x:21.27296,y:-157.82313},
{x:33.9367,y:-117.40598},
{x:34.06292,y:-118.0423},
{x:21.27392,y:-157.82167},
{x:35.24246,y:-106.65588},
{x:36.60883,y:-121.92311},
{x:38.85766,y:-104.72212},
{x:42.34551,y:-122.88264},
{x:40.75014,y:-73.94017},
{x:42.03636,y:-71.23401},
{x:47.38585,y:-122.2046},
{x:34.003,y:-84.03841},
{x:33.72724,y:-117.78584},
{x:41.13481,y:-85.06509},
{x:40.04094,y:-86.10553},
{x:45.40408,y:-122.56998},
{x:32.61569,y:-117.02532},
{x:38.99338,y:-77.0967},
{x:33.46328,y:-112.34084},
{x:35.84488,y:-86.49158},
{x:33.74234,y:-84.3599},
{x:37.63607,y:-97.35371},
{x:40.15926,y:-111.63005},
{x:45.43822,y:-122.82666},
{x:35.96485,y:-83.91802},
{x:39.28664,y:-76.61911},
{x:39.3006,y:-76.592},
{x:33.58897,y:-111.83403},
{x:43.09436,y:-77.63339},
{x:41.72479,y:-86.17633},
{x:35.37159,y:-80.78505},
{x:33.57784,y:-79.02946},
{x:40.58835,y:-74.10294},
{x:33.4585,y:-112.08243},
{x:33.15157,y:-117.3371},
{x:42.98068,y:-78.81557},
{x:34.91218,y:-82.10455},
{x:35.04753,y:-81.98446},
{x:33.65602,y:-96.61074},
{x:32.80886,y:-116.92035},
{x:38.97811,y:-76.73358},
{x:40.97023,y:-73.95708},
{x:37.78823,y:-122.39362},
{x:36.62577,y:-87.31855},
{x:42.50338,y:-83.28595},
{x:40.7416,y:-74.0053},
{x:28.51432,y:-81.31115},
{x:39.09057,y:-108.55069},
{x:36.13294,y:-86.8949},
{x:35.8808,y:-84.16055},
{x:36.11671,y:-115.09002},
{x:33.63873,y:-112.05382},
{x:35.80181,y:-84.26286},
{x:33.92208,y:-118.39555},
{x:33.91052,y:-84.82286},
{x:41.11686,y:-112.06712},
{x:41.73997,y:-111.83061},
{x:30.17083,y:-93.25171},
{x:36.30768,y:-86.60061},
{x:39.13318,y:-82.97711},
{x:40.02583,y:-82.44497},
{x:40.50758,y:-88.99427},
{x:42.64826,y:-83.5029},
{x:41.50685,y:-112.01669},
{x:42.1936,y:-122.70629},
{x:30.00331,y:-90.18438},
{x:37.92593,y:-122.01533},
{x:45.52738,y:-122.61373},
{x:18.37694,y:-65.92613},
{x:32.47352,y:-83.7052},
{x:38.70396,y:-80.66588},
{x:39.54698,y:-104.79283},
{x:38.58014,y:-121.49817},
{x:26.52722,y:-80.18617},
{x:39.9443,y:-74.96887},
{x:29.89082,y:-95.71792},
{x:29.73399,y:-95.4048},
{x:29.74491,y:-95.79327},
{x:39.67139,y:-75.64897},
{x:38.00037,y:-87.49539},
{x:33.33039,y:-111.97858},
{x:36.83484,y:-119.91824},
{x:34.44357,y:-119.78884},
{x:38.5857,y:-95.26883},
{x:44.74713,y:-93.35456},
{x:45.12432,y:-93.35716},
{x:33.20598,y:-87.54724},
{x:38.83812,y:-94.6848},
{x:39.78652,y:-94.8019},
{x:31.07796,y:-97.75628},
{x:33.50389,y:-111.92928},
{x:40.75606,y:-73.96484},
{x:35.31932,y:-119.09011},
{x:39.27555,y:-74.56997},
{x:41.9468,y:-87.64634},
{x:41.23581,y:-96.14051},
{x:35.805,y:-78.78085},
{x:36.1587,y:-115.07657},
{x:34.06341,y:-117.43055},
{x:33.64086,y:-112.03094},
{x:33.96381,y:-117.69177},
{x:34.12172,y:-117.53835},
{x:34.1863,y:-118.53566},
{x:32.46713,y:-95.38747},
{x:36.29374,y:-76.29801},
{x:34.9431,y:-81.02977},
{x:37.8843,y:-122.29813},
{x:45.57288,y:-93.20624},
{x:41.83142,y:-88.10315},
{x:40.66941,y:-73.98988},
{x:42.40588,y:-82.91588},
{x:42.1532,y:-88.0357},
{x:42.59177,y:-88.41355},
{x:42.31776,y:-89.03501},
{x:40.21962,y:-74.63238},
{x:40.88771,y:-74.21615},
{x:40.75932,y:-74.16118},
{x:35.16005,y:-80.97131},
{x:39.9572,y:-82.93896},
{x:39.74054,y:-105.04192},
{x:33.75617,y:-116.39298},
{x:41.16515,y:-80.66498},
{x:42.92684,y:-85.60577},
{x:38.24386,y:-85.52204},
{x:45.30372,y:-122.76609},
{x:38.68431,y:-121.45573},
{x:43.20968,y:-77.69733},
{x:39.80054,y:-86.27146},
{x:38.46407,y:-121.49229},
{x:33.27651,y:-84.29207},
{x:33.81073,y:-117.22701},
{x:21.38144,y:-158.14481},
{x:41.76283,y:-88.18817},
{x:37.12742,y:-113.52441},
{x:30.40336,y:-97.72231},
{x:45.50472,y:-122.44088},
{x:47.48922,y:-122.33593},
{x:41.6037,y:-87.84659},
{x:41.58442,y:-93.62233},
{x:32.75146,y:-97.35111},
{x:35.90774,y:-83.83917},
{x:34.03914,y:-118.26443},
{x:40.71485,y:-73.38218},
{x:43.33236,y:-71.00779},
{x:38.57819,y:-121.49414},
{x:39.14125,y:-121.62539},
{x:38.3637,y:-122.71571},
{x:42.60611,y:-76.16797},
{x:28.238,y:-82.34154},
{x:32.5833,y:-85.49091},
{x:43.58782,y:-116.39313},
{x:45.36252,y:-122.64853},
{x:41.05263,y:-85.229},
{x:39.69758,y:-84.10542},
{x:43.67702,y:-70.33053},
{x:40.7543,y:-73.98719},
{x:35.58676,y:-82.58748},
{x:34.08136,y:-118.06437},
{x:34.50504,y:-114.35},
{x:38.11496,y:-121.27949},
{x:41.93075,y:-87.88527},
{x:39.73964,y:-75.69182},
{x:35.94482,y:-78.5035},
{x:35.32333,y:-106.57643},
{x:33.08127,y:-96.82565},
{x:43.48331,y:-70.47137},
{x:30.61354,y:-96.33743},
{x:46.83614,y:-114.01544},
{x:47.06537,y:-109.42283},
{x:40.52396,y:-74.43548},
{x:39.35067,y:-94.76798},
{x:37.61521,y:-122.38905},
{x:36.10418,y:-79.93685},
{x:35.0607,y:-80.81347},
{x:38.67136,y:-90.43583},
{x:36.06004,y:-115.16448},
{x:41.8963,y:-87.64121},
{x:34.24247,y:-119.01081},
{x:39.01355,y:-94.59084},
{x:41.13546,y:-81.79704},
{x:38.66356,y:-121.26249},
{x:35.00497,y:-85.15117},
{x:30.54499,y:-97.86474},
{x:40.46771,y:-86.95449},
{x:39.99601,y:-85.93489},
{x:38.95847,y:-85.85014},
{x:39.76319,y:-86.35097},
{x:33.88361,y:-117.92475},
{x:33.85832,y:-118.04583},
{x:33.30774,y:-111.75625},
{x:33.38124,y:-111.72141},
{x:18.40772,y:-66.1632},
{x:40.82713,y:-73.02314},
{x:41.95421,y:-87.66924},
{x:40.63826,y:-111.97793},
{x:33.01759,y:-117.06138},
{x:35.84279,y:-90.67858},
{x:36.10502,y:-79.93437},
{x:39.56312,y:-84.26262},
{x:40.90861,y:-74.39701},
{x:39.7425,y:-75.55014},
{x:34.25055,y:-118.50188},
{x:36.02281,y:-83.92627},
{x:38.59546,y:-121.42777},
{x:32.84433,y:-96.7835},
{x:33.42021,y:-111.93077},
{x:33.30646,y:-111.67791},
{x:30.35092,y:-81.60642},
{x:41.07683,y:-81.51047},
{x:33.42281,y:-111.94082},
{x:45.39709,y:-122.74353},
{x:38.21676,y:-85.76068},
{x:36.61295,y:-121.84336},
{x:38.55788,y:-121.42209},
{x:34.74847,y:-92.27132},
{x:47.0528,y:-122.8475},
{x:39.99804,y:-82.88352},
{x:38.73645,y:-75.14358},
{x:35.28312,y:-80.6696},
{x:39.96231,y:-83.00205},
{x:41.81924,y:-71.49768},
{x:41.61499,y:-86.72973},
{x:40.07631,y:-83.12738},
{x:42.68532,y:-73.82476},
{x:40.72092,y:-73.65148},
{x:45.03725,y:-93.21905},
{x:37.72344,y:-97.20764},
{x:40.47971,y:-104.92939},
{x:38.24847,y:-85.74909},
{x:38.9271,y:-94.72119},
{x:41.95672,y:-87.747},
{x:40.67434,y:-73.48918},
{x:40.53768,y:-105.07876},
{x:32.25264,y:-110.89262},
{x:32.25146,y:-110.96249},
{x:39.3344,y:-83.01639},
{x:33.50965,y:-112.35559},
{x:34.70828,y:-86.7487},
{x:45.41647,y:-122.631},
{x:34.17007,y:-118.57044},
{x:33.48446,y:-117.07988},
{x:42.09354,y:-83.23459},
{x:33.2458,y:-111.79163},
{x:47.60918,y:-122.62729},
{x:34.82598,y:-89.98218},
{x:39.00821,y:-77.50337},
{x:39.48929,y:-86.06433},
{x:36.04561,y:-84.00214},
{x:35.84342,y:-86.44178},
{x:39.22831,y:-85.90175},
{x:38.31169,y:-88.94331},
{x:38.76454,y:-112.08481},
{x:47.44309,y:-122.19885},
{x:40.53958,y:-112.02111},
{x:35.13513,y:-90.00314},
{x:34.1718,y:-84.77713},
{x:34.41156,y:-119.8481},
{x:45.42823,y:-122.48802},
{x:38.87334,y:-77.37286},
{x:39.53404,y:-83.44594},
{x:34.7351,y:-76.79247},
{x:34.45185,y:-77.60307},
{x:35.37114,y:-80.78352},
{x:37.29529,y:-107.87341},
{x:47.48403,y:-122.21699},
{x:29.77252,y:-95.42454},
{x:41.45189,y:-96.4825},
{x:40.91579,y:-73.12262},
{x:35.12827,y:-106.70196},
{x:37.61467,y:-122.08754},
{x:41.26567,y:-96.23529},
{x:36.77823,y:-76.25373},
{x:40.49268,y:-81.47216},
{x:40.00585,y:-83.00896},
{x:40.72683,y:-73.98316},
{x:33.46798,y:-111.68547},
{x:38.3629,y:-121.96555},
{x:38.78775,y:-89.97891},
{x:47.51385,y:-122.35493},
{x:38.40818,y:-121.41652},
{x:33.86549,y:-84.48146},
{x:44.00667,y:-92.47931},
{x:33.48932,y:-82.20166},
{x:37.1589,y:-93.31516},
{x:39.21141,y:-94.64148},
{x:41.26599,y:-96.11851},
{x:42.50474,y:-94.15624},
{x:26.8825,y:-80.11737},
{x:27.85362,y:-82.21121},
{x:29.47528,y:-81.22143},
{x:28.19095,y:-82.39525},
{x:38.00981,y:-78.50565},
{x:36.91572,y:-76.30753},
{x:42.34706,y:-71.07898},
{x:36.07199,y:-115.10049},
{x:43.48111,y:-112.02172},
{x:47.23796,y:-122.47876},
{x:38.9243,y:-77.21308},
{x:38.97829,y:-77.09058},
{x:38.73958,y:-77.45405},
{x:39.10979,y:-76.7699},
{x:44.0384,y:-92.4629},
{x:44.91779,y:-93.24754},
{x:41.61714,y:-87.04593},
{x:41.54181,y:-87.8512},
{x:32.75516,y:-97.42937},
{x:42.74058,y:-84.66256},
{x:18.33829,y:-66.06739},
{x:33.95121,y:-83.37603},
{x:39.81087,y:-75.54899},
{x:35.27069,y:-89.66742},
{x:38.22424,y:-91.15408},
{x:35.02545,y:-80.96204},
{x:40.74687,y:-73.88364},
{x:40.749,y:-73.75613},
{x:33.02573,y:-97.28062},
{x:32.64648,y:-97.18638},
{x:32.70572,y:-97.02376},
{x:44.99562,y:-123.02698},
{x:32.57622,y:-97.31797},
{x:44.5264,y:-122.90739},
{x:47.1062,y:-122.43538},
{x:39.75758,y:-121.62475},
{x:33.88379,y:-84.31609},
{x:33.82106,y:-116.40755},
{x:32.62046,y:-116.96771},
{x:37.61736,y:-84.78148},
{x:41.80489,y:-87.88907},
{x:35.36262,y:-97.56601},
{x:35.68076,y:-97.41915},
{x:40.75777,y:-73.97961},
{x:46.22599,y:-119.22797},
{x:25.9599,y:-80.13867},
{x:40.30665,y:-76.80269},
{x:36.0406,y:-115.18874},
{x:36.11432,y:-115.17348},
{x:35.66129,y:-109.06208},
{x:34.28072,y:-118.73949},
{x:27.84655,y:-82.64364},
{x:40.41181,y:-74.13513},
{x:45.41143,y:-122.74491},
{x:38.5593,y:-121.44114},
{x:37.79284,y:-122.40429},
{x:45.9909,y:-123.92193},
{x:32.41443,y:-97.22859},
{x:32.8696,y:-97.10237},
{x:32.33128,y:-96.13348},
{x:32.87737,y:-96.73444},
{x:29.45532,y:-98.47024},
{x:38.35204,y:-121.95278},
{x:40.1448,y:-82.94723},
{x:39.56226,y:-86.15589},
{x:29.81278,y:-95.77028},
{x:33.94678,y:-83.40915},
{x:45.50416,y:-122.5117},
{x:33.97893,y:-118.37158},
{x:33.71433,y:-112.26794},
{x:33.7814,y:-118.0697},
{x:33.95951,y:-118.30792},
{x:34.06851,y:-118.29044},
{x:41.17156,y:-85.48221},
{x:40.00197,y:-105.01011},
{x:30.13349,y:-95.23662},
{x:47.23015,y:-123.12363},
{x:39.17187,y:-84.7594},
{x:34.09772,y:-118.30973},
{x:38.79316,y:-104.80005},
{x:41.94185,y:-87.66954},
{x:33.64961,y:-117.83869},
{x:37.87389,y:-122.51102},
{x:30.12254,y:-95.53143},
{x:34.25838,y:-118.46635},
{x:47.54409,y:-122.16387},
{x:34.80658,y:-82.22449},
{x:47.52773,y:-121.87498},
{x:32.75545,y:-117.0139},
{x:32.86495,y:-79.90562},
{x:41.31439,y:-81.51939},
{x:33.98821,y:-118.18518},
{x:38.55833,y:-121.24339},
{x:20.87551,y:-156.45582},
{x:45.38906,y:-122.64314},
{x:41.60087,y:-93.71557},
{x:25.85326,y:-80.12098},
{x:36.09491,y:-80.27566},
{x:35.20634,y:-80.86225},
{x:39.02366,y:-94.19777},
{x:35.04234,y:-80.84968},
{x:28.60093,y:-81.36317},
{x:36.16591,y:-115.14751},
{x:46.24181,y:-119.12421},
{x:34.0145,y:-118.13034},
{x:33.80725,y:-118.35768},
{x:33.95349,y:-118.13048},
{x:33.97752,y:-118.36993},
{x:41.95266,y:-87.64945},
{x:34.24969,y:-118.58018},
{x:40.86739,y:-73.83026},
{x:29.93517,y:-90.11089},
{x:40.26905,y:-76.64936},
{x:44.74788,y:-93.3573},
{x:31.62209,y:-84.22576},
{x:44.93751,y:-93.34641},
{x:35.70491,y:-78.79541},
{x:34.01889,y:-118.42229},
{x:33.52091,y:-117.61872},
{x:32.13344,y:-111.00352},
{x:32.32248,y:-111.01289},
{x:29.55513,y:-95.4034},
{x:36.10045,y:-115.18739},
{x:40.75766,y:-73.98507},
{x:39.84055,y:-83.08591},
{x:37.60311,y:-77.56686},
{x:34.13116,y:-117.63766},
{x:33.47536,y:-101.88923},
{x:33.54768,y:-101.88909},
{x:35.53072,y:-82.60722},
{x:33.0064,y:-96.55214},
{x:33.87645,y:-98.55913},
{x:41.51703,y:-73.59298},
{x:32.47378,y:-99.73186},
{x:33.09335,y:-98.5885},
{x:33.52043,y:-101.94193},
{x:34.18539,y:-101.74675},
{x:44.78333,y:-117.81885},
{x:43.98936,y:-92.46185},
{x:44.8391,y:-93.79734},
{x:25.7773,y:-80.19558},
{x:45.55045,y:-122.90306},
{x:38.76725,y:-104.7843},
{x:40.74213,y:-73.1996},
{x:42.24022,y:-85.67689},
{x:40.92673,y:-73.04524},
{x:40.82931,y:-73.42158},
{x:33.78371,y:-84.39247},
{x:42.94765,y:-87.90054},
{x:39.44819,y:-75.73125},
{x:32.79115,y:-117.02078},
{x:29.546,y:-95.22764},
{x:28.50965,y:-81.23769},
{x:33.0085,y:-97.22412},
{x:30.26126,y:-97.75791},
{x:39.12416,y:-77.16427},
{x:38.88231,y:-77.11627},
{x:41.88206,y:-87.64054},
{x:45.36588,y:-122.85022},
{x:45.07951,y:-93.11985},
{x:41.99635,y:-72.58216},
{x:42.48994,y:-90.72187},
{x:36.80964,y:-119.84731},
{x:40.45882,y:-78.40898},
{x:47.65907,y:-122.69384},
{x:40.86332,y:-111.89788},
{x:33.69659,y:-117.74261},
{x:39.56042,y:-89.29403},
{x:33.82252,y:-84.36605},
{x:34.34994,y:-84.05316},
{x:32.1321,y:-111.0046},
{x:39.95973,y:-85.92201},
{x:42.71097,y:-83.03797},
{x:38.20262,y:-85.76146},
{x:32.8724,y:-97.34041},
{x:37.40206,y:-121.94018},
{x:41.23154,y:-73.04809},
{x:29.51509,y:-98.69376},
{x:29.48571,y:-98.72441},
{x:40.48709,y:-74.4157},
{x:30.26554,y:-97.73838},
{x:34.05421,y:-118.26304},
{x:42.22208,y:-85.58896},
{x:27.27426,y:-80.34804},
{x:45.36771,y:-122.60042},
{x:18.00949,y:-66.38419},
{x:41.96757,y:-87.76759},
{x:35.05052,y:-78.97503},
{x:37.02054,y:-76.44356},
{x:38.90171,y:-77.02316},
{x:25.95085,y:-80.24532},
{x:41.7558,y:-71.46108},
{x:41.48806,y:-90.47591},
{x:41.63993,y:-85.92902},
{x:41.59372,y:-93.7882},
{x:40.04514,y:-75.23162},
{x:32.60399,y:-117.07762},
{x:41.67055,y:-86.32368},
{x:30.2092,y:-95.4261},
{x:40.7536,y:-73.99943},
{x:44.73544,y:-93.21849},
{x:44.91065,y:-93.5242},
{x:34.46297,y:-117.35305},
{x:33.95202,y:-118.18643},
{x:32.25783,y:-110.94423},
{x:34.20078,y:-118.57052},
{x:33.77466,y:-84.38904},
{x:36.8826,y:-76.19912},
{x:32.24914,y:-95.30797},
{x:37.28279,y:-79.93225},
{x:39.82407,y:-76.99694},
{x:38.98431,y:-77.09466},
{x:40.72988,y:-73.59328},
{x:32.5,y:-92.07032},
{x:41.50205,y:-81.61756},
{x:45.09804,y:-95.04096},
{x:29.42612,y:-98.4211},
{x:42.48258,y:-83.28464},
{x:42.24391,y:-83.34798},
{x:42.18415,y:-83.19042},
{x:40.69059,y:-73.98609},
{x:18.385,y:-66.06633},
{x:42.34844,y:-71.2476},
{x:40.77236,y:-111.83487},
{x:37.06703,y:-76.49174},
{x:46.46538,y:-84.38836},
{x:40.6883,y:-80.08396},
{x:39.95011,y:-75.19392},
{x:42.96088,y:-87.98641},
{x:31.28036,y:-92.45852},
{x:33.44941,y:-112.07268},
{x:30.41723,y:-87.20387},
{x:43.03695,y:-78.82263},
{x:34.55429,y:-112.48272},
{x:35.30282,y:-120.66093},
{x:37.36761,y:-121.9941},
{x:26.04867,y:-80.15647},
{x:46.83946,y:-96.86102},
{x:40.33614,y:-79.94772},
{x:39.91731,y:-76.63067},
{x:47.16103,y:-122.43469},
{x:36.32467,y:-119.2778},
{x:32.9508,y:-96.85395},
{x:32.40569,y:-99.76771},
{x:32.92787,y:-96.81335},
{x:32.83929,y:-96.99063},
{x:35.22076,y:-80.94025},
{x:35.87604,y:-78.7951},
{x:32.75797,y:-97.17023},
{x:43.47754,y:-111.98358},
{x:32.8935,y:-96.46786},
{x:39.88541,y:-85.93479},
{x:27.99384,y:-81.6891},
{x:32.26244,y:-101.49076},
{x:34.04189,y:-117.15729},
{x:33.22847,y:-97.16282},
{x:41.42013,y:-88.27238},
{x:42.27725,y:-88.02062},
{x:41.37257,y:-88.42319},
{x:42.07868,y:-88.01722},
{x:43.54518,y:-116.31324},
{x:47.9277,y:-122.09681},
{x:41.52251,y:-88.20215},
{x:42.13641,y:-88.42924},
{x:32.74493,y:-83.7136},
{x:40.37458,y:-111.91897},
{x:40.58905,y:-111.94088},
{x:41.90135,y:-88.47425},
{x:42.43581,y:-88.24399},
{x:42.4194,y:-123.35307},
{x:39.91239,y:-104.98102},
{x:40.33207,y:-74.11904},
{x:41.51752,y:-87.98692},
{x:44.02329,y:-123.08096},
{x:33.86623,y:-112.13771},
{x:32.85142,y:-116.93261},
{x:34.67293,y:-118.16579},
{x:32.81531,y:-96.80081},
{x:41.95508,y:-70.6859},
{x:41.78743,y:-87.94999},
{x:40.20793,y:-75.01242},
{x:41.71773,y:-88.31794},
{x:33.7649,y:-118.30999},
{x:32.70726,y:-117.01095},
{x:42.01401,y:-88.67817},
{x:41.77329,y:-88.43934},
{x:41.48578,y:-87.92481},
{x:33.19903,y:-96.70626},
{x:40.75706,y:-86.76665},
{x:40.2192,y:-74.76586},
{x:40.56269,y:-76.00398},
{x:34.9384,y:-81.03189},
{x:43.14798,y:-85.71134},
{x:42.20845,y:-85.88944},
{x:35.02417,y:-85.15504},
{x:40.85608,y:-73.86743},
{x:35.9046,y:-78.94241},
{x:34.12113,y:-84.74541},
{x:33.66727,y:-117.26498},
{x:34.18122,y:-77.9191},
{x:30.21961,y:-85.86522},
{x:33.7125,y:-84.93597},
{x:37.15925,y:-93.28184},
{x:34.26428,y:-119.24508},
{x:35.07497,y:-106.74271},
{x:44.69583,y:-73.49148},
{x:39.81797,y:-104.77241},
{x:35.97554,y:-112.12474},
{x:26.92931,y:-82.04796},
{x:46.72832,y:-117.16602},
{x:42.47166,y:-75.06206},
{x:40.15738,y:-74.05388},
{x:39.39631,y:-74.55383},
{x:40.23043,y:-76.89224},
{x:34.01709,y:-118.40541},
{x:40.62337,y:-111.83381},
{x:33.6372,y:-112.20067},
{x:45.49719,y:-122.63607},
{x:33.14769,y:-97.10613},
{x:38.9964,y:-77.48133},
{x:43.54701,y:-96.68272},
{x:46.85985,y:-96.79883},
{x:47.44129,y:-122.30362},
{x:47.44313,y:-122.30301},
{x:47.44556,y:-122.30368},
{x:32.79435,y:-117.19367},
{x:32.81301,y:-117.153},
{x:18.42151,y:-66.07425},
{x:42.491,y:-90.70126},
{x:38.53846,y:-121.49026},
{x:41.49365,y:-81.98609},
{x:29.98918,y:-95.34157},
{x:40.87516,y:-81.43202},
{x:42.86986,y:-73.8178},
{x:41.1262,y:-81.60547},
{x:36.00704,y:-84.0219},
{x:35.57881,y:-77.38346},
{x:34.1725,y:-84.78587},
{x:33.97073,y:-84.01833},
{x:34.29022,y:-83.84683},
{x:39.35396,y:-74.43791},
{x:41.27743,y:-80.77433},
{x:37.39346,y:-79.23376},
{x:47.65561,y:-122.30859},
{x:47.57863,y:-122.16724},
{x:33.13137,y:-97.04335},
{x:35.99384,y:-80.00818},
{x:31.31318,y:-94.71955},
{x:41.26092,y:-95.92982},
{x:38.56116,y:-76.06392},
{x:40.89028,y:-81.40545},
{x:41.04772,y:-73.80813},
{x:41.91658,y:-87.74575},
{x:40.74712,y:-73.94181},
{x:37.72337,y:-121.94304},
{x:27.6292,y:-97.22554},
{x:33.91501,y:-117.58911},
{x:35.29072,y:-80.7653},
{x:33.00747,y:-80.04497},
{x:34.63873,y:-120.45384},
{x:42.32514,y:-71.06395},
{x:34.06304,y:-118.27233},
{x:34.07058,y:-118.40236},
{x:33.99887,y:-117.52292},
{x:34.03869,y:-118.707},
{x:47.16636,y:-122.15055},
{x:33.48745,y:-112.08358},
{x:34.38269,y:-117.41359},
{x:38.7015,y:-75.40231},
{x:34.69763,y:-118.14597},
{x:34.19877,y:-118.46577},
{x:39.92418,y:-75.14631},
{x:34.02575,y:-118.28431},
{x:40.81347,y:-77.9064},
{x:35.9126,y:-79.05714},
{x:39.99355,y:-83.0273},
{x:37.98965,y:-121.78446},
{x:33.79234,y:-116.49436},
{x:39.4838,y:-88.3331},
{x:34.19507,y:-77.88949},
{x:40.77276,y:-73.98986},
{x:40.04499,y:-76.36414},
{x:47.58101,y:-122.33531},
{x:40.70001,y:-73.94111},
{x:43.38682,y:-123.3406},
{x:30.44898,y:-91.18799},
{x:36.01367,y:-106.06512},
{x:29.78918,y:-95.68889},
{x:37.32312,y:-122.05465},
{x:40.0423,y:-86.14547},
{x:34.0749,y:-118.32346},
{x:38.4957,y:-121.45838},
{x:39.9418,y:-83.83197},
{x:40.74741,y:-73.99483},
{x:42.72853,y:-84.49455},
{x:48.41809,y:-119.51022},
{x:34.93814,y:-80.83653},
{x:42.11415,y:-87.98344},
{x:42.18197,y:-87.98886},
{x:39.79127,y:-105.02486},
{x:38.93892,y:-92.3321},
{x:47.61799,y:-122.30353},
{x:40.93443,y:-75.10161},
{x:33.64089,y:-84.44439},
{x:30.2836,y:-97.74162},
{x:42.30612,y:-83.48549},
{x:42.51982,y:-83.76486},
{x:44.83087,y:-92.95804},
{x:29.6582,y:-95.71016},
{x:42.2241,y:-83.34991},
{x:30.39309,y:-88.86554},
{x:33.88146,y:-117.88759},
{x:36.16366,y:-86.7785},
{x:33.33241,y:-111.97788},
{x:34.05372,y:-118.46612},
{x:36.05631,y:-79.89729},
{x:38.95623,y:-94.52106},
{x:39.1282,y:-84.50906},
{x:39.64053,y:-119.71103},
{x:29.92013,y:-90.01479},
{x:35.63778,y:-80.48746},
{x:33.50861,y:-117.12496},
{x:33.20639,y:-79.98407},
{x:33.49797,y:-86.87437},
{x:30.51492,y:-86.45845},
{x:34.16509,y:-118.52528},
{x:32.98802,y:-97.18136},
{x:40.89697,y:-85.51254},
{x:33.02069,y:-96.93188},
{x:32.63265,y:-97.40725},
{x:41.72708,y:-85.97373},
{x:32.28049,y:-110.95944},
{x:29.78242,y:-95.54455},
{x:41.39849,y:-122.38045},
{x:47.84746,y:-122.27277},
{x:64.8503,y:-147.70051},
{x:41.79338,y:-87.70327},
{x:38.52491,y:-121.4423},
{x:35.30702,y:-82.45595},
{x:42.88423,y:-85.52921},
{x:45.33274,y:-122.76697},
{x:33.91917,y:-117.88749},
{x:25.97265,y:-80.14274},
{x:30.23325,y:-97.79141},
{x:32.84247,y:-116.98225},
{x:41.1735,y:-112.00697},
{x:40.40948,y:-105.00341},
{x:29.6214,y:-82.38392},
{x:38.29772,y:-76.51386},
{x:37.79764,y:-122.4304},
{x:32.28017,y:-110.97792},
{x:34.23142,y:-118.56225},
{x:39.91369,y:-75.34959},
{x:26.7225,y:-80.08606},
{x:32.81366,y:-96.62078},
{x:29.77237,y:-95.57469},
{x:40.66757,y:-89.57918},
{x:39.56146,y:-76.37319},
{x:41.67162,y:-71.16034},
{x:35.31979,y:-97.49849},
{x:33.88692,y:-118.10042},
{x:33.9846,y:-118.39439},
{x:41.5021,y:-81.62065},
{x:39.38443,y:-74.42571},
{x:36.6764,y:-121.65569},
{x:30.44329,y:-84.295},
{x:42.73373,y:-84.53438},
{x:33.96628,y:-98.50698},
{x:41.82705,y:-89.4747},
{x:21.37739,y:-158.0259},
{x:36.13391,y:-115.08466},
{x:33.60062,y:-117.8727},
{x:38.55594,y:-121.69642},
{x:41.95196,y:-88.72609},
{x:30.4446,y:-88.8846},
{x:42.13725,y:-70.83896},
{x:38.59451,y:-121.28429},
{x:41.49296,y:-87.04682},
{x:34.05568,y:-118.20455},
{x:38.25807,y:-85.64275},
{x:42.62462,y:-71.31845},
{x:33.4022,y:-86.832},
{x:45.57586,y:-122.56538},
{x:37.3811,y:-121.9744},
{x:37.29268,y:-121.99621},
{x:36.01846,y:-95.88644},
{x:35.21236,y:-106.69734},
{x:36.00394,y:-115.08343},
{x:39.54986,y:-105.00572},
{x:43.08808,y:-88.06365},
{x:40.78594,y:-73.95066},
{x:30.01123,y:-97.86424},
{x:38.24653,y:-85.5148},
{x:35.35014,y:-119.12498},
{x:37.76283,y:-121.96017},
{x:47.25517,y:-122.52228},
{x:29.71569,y:-95.41689},
{x:34.96019,y:-89.93645},
{x:43.54,y:-96.652},
{x:34.20157,y:-118.43046},
{x:35.82473,y:-82.54318},
{x:42.07035,y:-94.87767},
{x:47.67063,y:-122.38727},
{x:34.12207,y:-117.68983},
{x:32.59445,y:-117.04158},
{x:35.1138,y:-89.90213},
{x:41.58075,y:-83.68141},
{x:45.34946,y:-84.9665},
{x:41.89131,y:-87.64807},
{x:47.66175,y:-122.33586},
{x:33.62602,y:-111.97877},
{x:47.56128,y:-122.38468},
{x:41.73793,y:-93.60093},
{x:38.85428,y:-94.68313},
{x:40.81374,y:-96.62356},
{x:41.47045,y:-90.52883},
{x:38.31282,y:-88.94579},
{x:36.76899,y:-90.41648},
{x:37.68027,y:-113.06079},
{x:33.78586,y:-112.11964},
{x:47.30691,y:-122.58096},
{x:41.85267,y:-87.62319},
{x:39.52892,y:-119.81428},
{x:38.96227,y:-84.67655},
{x:35.60126,y:-119.35045},
{x:40.02995,y:-74.61485},
{x:20.91588,y:-156.69608},
{x:44.65081,y:-84.70947},
{x:32.67844,y:-97.00983},
{x:41.44392,y:-90.57002},
{x:42.6472,y:-85.29993},
{x:44.77955,y:-93.53963},
{x:37.89713,y:-122.06162},
{x:40.97182,y:-73.8824},
{x:32.94881,y:-96.46259},
{x:40.92663,y:-73.78923},
{x:33.13318,y:-117.08712},
{x:37.83353,y:-122.2494},
{x:39.85312,y:-82.89917},
{x:41.61453,y:-93.85549},
{x:39.0286,y:-104.82865},
{x:28.49314,y:-81.50977},
{x:35.18152,y:-80.91567},
{x:41.6699,y:-84.99913},
{x:39.9579,y:-104.97715},
{x:43.43629,y:-84.01304},
{x:42.94372,y:-83.69031},
{x:33.70635,y:-117.78814},
{x:35.21471,y:-101.90026},
{x:35.18617,y:-101.8681},
{x:39.0424,y:-76.04873},
{x:35.37807,y:-86.22653},
{x:41.1277,y:-85.01315},
{x:33.69543,y:-117.76522},
{x:33.7598,y:-117.8383},
{x:33.18411,y:-117.32987},
{x:34.06355,y:-118.28587},
{x:40.22348,y:-75.13994},
{x:44.91046,y:-116.10224},
{x:42.36268,y:-71.13611},
{x:42.35272,y:-71.12352},
{x:39.96898,y:-75.13911},
{x:39.75578,y:-75.35405},
{x:42.38979,y:-71.11819},
{x:38.44654,y:-122.74162},
{x:43.60183,y:-116.20109},
{x:33.64459,y:-117.84589},
{x:34.22088,y:-77.87159},
{x:35.30997,y:-83.17745},
{x:38.21738,y:-85.75501},
{x:42.51178,y:-83.0312},
{x:37.29978,y:-78.39695},
{x:34.98586,y:-80.44406},
{x:40.86139,y:-73.88615},
{x:38.11441,y:-121.39228},
{x:37.68506,y:-77.58693},
{x:29.65337,y:-82.33895},
{x:37.65702,y:-77.52581},
{x:37.67523,y:-77.46304},
{x:37.62225,y:-77.62391},
{x:37.26887,y:-77.411},
{x:37.52571,y:-77.35206},
{x:37.41015,y:-77.65621},
{x:34.03709,y:-84.46209},
{x:42.4954,y:-71.10135},
{x:45.55561,y:-94.20902},
{x:44.85577,y:-93.42595},
{x:39.5231,y:-76.17888},
{x:39.97928,y:-75.15499},
{x:33.47911,y:-111.98438},
{x:33.60957,y:-117.72542},
{x:34.13455,y:-117.39392},
{x:33.56827,y:-85.0775},
{x:39.82269,y:-86.27978},
{x:39.73991,y:-104.84106},
{x:41.72148,y:-87.66305},
{x:33.88176,y:-118.32623},
{x:40.69341,y:-73.3964},
{x:32.987,y:-96.74893},
{x:39.9094,y:-86.19546},
{x:26.07337,y:-80.13602},
{x:41.87074,y:-87.6741},
{x:37.69882,y:-121.90303},
{x:34.78132,y:-84.7908},
{x:41.65645,y:-93.79126},
{x:41.41808,y:-72.89506},
{x:35.63671,y:-88.83182},
{x:39.73876,y:-75.55},
{x:40.03225,y:-83.01607},
{x:39.92045,y:-83.12144},
{x:27.9385,y:-82.4547},
{x:38.73773,y:-90.35675},
{x:47.03431,y:-122.94126},
{x:33.65641,y:-84.42409},
{x:33.58714,y:-101.88013},
{x:44.77595,y:-93.5018},
{x:42.10546,y:-87.81304},
{x:34.09023,y:-117.69098},
{x:37.52272,y:-122.00408},
{x:39.95729,y:-85.92002},
{x:47.49473,y:-94.90595},
{x:34.21631,y:-118.22624},
{x:32.81137,y:-115.56939},
{x:33.80304,y:-117.91418},
{x:42.33183,y:-83.05058},
{x:47.36744,y:-122.18599},
{x:41.80475,y:-72.25303},
{x:39.05615,y:-77.11481},
{x:41.8182,y:-89.64672},
{x:33.77481,y:-118.37603},
{x:39.96079,y:-82.47583},
{x:38.86046,y:-76.96695},
{x:40.49926,y:-74.44823},
{x:42.19616,y:-87.9249},
{x:42.52787,y:-83.35966},
{x:34.52125,y:-82.654},
{x:41.76301,y:-72.67227},
{x:28.0329,y:-82.64781},
{x:32.26191,y:-110.89284},
{x:33.66362,y:-117.82596},
{x:34.06367,y:-117.66107},
{x:28.78782,y:-81.35708},
{x:25.85851,y:-80.18392},
{x:25.79296,y:-80.18901},
{x:26.09783,y:-80.13057},
{x:27.39056,y:-82.44336},
{x:27.39066,y:-82.51144},
{x:26.17074,y:-81.80082},
{x:40.72854,y:-74.03476},
{x:32.79852,y:-79.95928},
{x:38.56526,y:-90.40399},
{x:38.50669,y:-90.33984},
{x:39.16188,y:-84.42551},
{x:42.32304,y:-83.47039},
{x:39.1694,y:-123.21201},
{x:38.31883,y:-81.7184},
{x:42.14852,y:-71.48866},
{x:32.98143,y:-117.07525},
{x:47.61585,y:-122.20372},
{x:38.90524,y:-77.06768},
{x:38.46897,y:-121.822},
{x:42.38026,y:-71.17496},
{x:42.8538,y:-88.32535},
{x:38.9152,y:-77.48909},
{x:29.95839,y:-95.67254},
{x:33.84761,y:-84.42986},
{x:36.47998,y:-80.61668},
{x:26.33075,y:-81.75366},
{x:42.32568,y:-85.51842},
{x:39.89094,y:-104.97809},
{x:35.10212,y:-106.72947},
{x:31.76915,y:-106.42554},
{x:30.52217,y:-97.83149},
{x:33.77197,y:-116.95824},
{x:29.99529,y:-95.62131},
{x:33.76739,y:-117.83537},
{x:34.02177,y:-117.33592},
{x:33.83245,y:-117.53139},
{x:42.34635,y:-88.28161},
{x:40.49869,y:-111.41306},
{x:40.68388,y:-112.02544},
{x:42.31431,y:-122.85603},
{x:34.00215,y:-118.39339},
{x:30.11844,y:-95.38167},
{x:33.83002,y:-118.31093},
{x:44.94858,y:-122.98636},
{x:33.33724,y:-111.75717},
{x:34.14698,y:-118.25478},
{x:33.86344,y:-118.39217},
{x:44.77886,y:-93.53822},
{x:40.28486,y:-76.65293},
{x:40.47043,y:-78.75453},
{x:44.07887,y:-103.25385},
{x:39.87465,y:-75.24089},
{x:38.53694,y:-89.86702},
{x:30.41746,y:-86.68223},
{x:45.01854,y:-93.32549},
{x:34.25015,y:-80.64429},
{x:32.65077,y:-116.96805},
{x:37.27129,y:-76.76138},
{x:32.55637,y:-116.93723},
{x:37.45985,y:-122.13576},
{x:37.72651,y:-122.47689},
{x:42.32914,y:-83.04054},
{x:43.0312,y:-76.02028},
{x:40.13828,y:-82.97898},
{x:40.14172,y:-88.25507},
{x:38.78525,y:-90.49712},
{x:34.12271,y:-118.22531},
{x:42.43454,y:-83.43107},
{x:38.57256,y:-89.92364},
{x:26.4399,y:-81.77193},
{x:25.73168,y:-80.25436},
{x:47.29137,y:-122.33601},
{x:33.658,y:-117.76675},
{x:37.70185,y:-121.93525},
{x:45.07833,y:-87.65929},
{x:33.1757,y:-96.51141},
{x:34.25661,y:-118.53706},
{x:34.15251,y:-118.34578},
{x:33.24071,y:-117.29347},
{x:42.00783,y:-88.08048},
{x:43.77461,y:-91.21877},
{x:41.97421,y:-88.2075},
{x:35.5938,y:-87.05444},
{x:38.5922,y:-89.95238},
{x:34.04034,y:-118.46332},
{x:26.12655,y:-81.76646},
{x:34.5102,y:-82.75576},
{x:34.56082,y:-82.62744},
{x:41.46657,y:-87.03157},
{x:44.96623,y:-92.72263},
{x:41.11224,y:-111.98174},
{x:35.15734,y:-83.39175},
{x:38.81707,y:-76.88236},
{x:33.08542,y:-96.82517},
{x:37.99258,y:-84.52536},
{x:36.14414,y:-115.20701},
{x:33.67139,y:-117.86464},
{x:40.97089,y:-90.38359},
{x:61.20935,y:-149.73428},
{x:30.43947,y:-91.00807},
{x:33.62468,y:-83.97657},
{x:32.24791,y:-110.85087},
{x:32.77145,y:-115.55186},
{x:30.38879,y:-86.43008},
{x:42.16731,y:-87.92226},
{x:37.32199,y:-122.03278},
{x:35.53446,y:-108.75822},
{x:32.74987,y:-117.1062},
{x:39.31897,y:-120.20772},
{x:34.18392,y:-118.8759},
{x:32.81998,y:-117.18176},
{x:34.16672,y:-118.83333},
{x:35.08935,y:-92.47285},
{x:32.77878,y:-117.00978},
{x:37.54881,y:-121.9871},
{x:36.29935,y:-95.62567},
{x:40.00027,y:-74.98732},
{x:37.56093,y:-122.2739},
{x:34.02412,y:-118.27818},
{x:34.09038,y:-118.32626},
{x:34.23136,y:-118.36767},
{x:36.01843,y:-115.26584},
{x:33.51011,y:-112.0465},
{x:39.38191,y:-77.39711},
{x:45.53916,y:-122.86404},
{x:41.07676,y:-85.13915},
{x:40.29128,y:-74.30186},
{x:40.30945,y:-76.79601},
{x:35.96616,y:-86.81247},
{x:39.3854,y:-76.73129},
{x:34.25634,y:-119.21085},
{x:39.60105,y:-75.82855},
{x:40.03986,y:-76.3055},
{x:33.56458,y:-101.94506},
{x:32.37248,y:-106.7413},
{x:32.43604,y:-104.24175},
{x:42.78939,y:-83.74205},
{x:34.09571,y:-117.7181},
{x:37.57939,y:-122.34612},
{x:36.14368,y:-115.27719},
{x:37.88431,y:-122.19178},
{x:39.31021,y:-85.96631},
{x:40.36762,y:-86.86427},
{x:34.08408,y:-118.38588},
{x:33.43594,y:-111.72116},
{x:40.44206,y:-74.42626},
{x:40.23481,y:-74.94513},
{x:41.14676,y:-81.34274},
{x:35.34965,y:-119.10135},
{x:32.90262,y:-97.04373},
{x:37.86974,y:-122.28379},
{x:41.50174,y:-81.69477},
{x:33.08616,y:-96.82855},
{x:38.54939,y:-122.80709},
{x:33.74251,y:-116.23349},
{x:28.81175,y:-81.33465},
{x:33.82339,y:-116.54717},
{x:18.16992,y:-67.1508},
{x:33.63846,y:-117.67834},
{x:32.23615,y:-110.96846},
{x:32.20794,y:-82.38373},
{x:47.65327,y:-117.4343},
{x:47.75848,y:-122.1541},
{x:29.56542,y:-98.25813},
{x:37.46795,y:-105.87654},
{x:34.14068,y:-118.00489},
{x:35.39129,y:-97.72285},
{x:29.81654,y:-95.7727},
{x:33.80439,y:-118.33023},
{x:37.29285,y:-121.98887},
{x:38.23466,y:-122.64075},
{x:43.18314,y:-76.24884},
{x:40.57725,y:-105.05946},
{x:35.91895,y:-78.78855},
{x:35.20079,y:-80.86597},
{x:38.33071,y:-85.70934},
{x:41.69127,y:-91.5984},
{x:33.2471,y:-117.29299},
{x:37.29461,y:-121.93093},
{x:41.74916,y:-87.94271},
{x:37.70672,y:-121.93158},
{x:37.54531,y:-121.98502},
{x:41.06534,y:-81.48131},
{x:27.95299,y:-82.8027},
{x:33.95672,y:-118.3969},
{x:37.95922,y:-122.09399},
{x:39.30371,y:-81.55022},
{x:39.85732,y:-105.16494},
{x:34.24114,y:-111.31973},
{x:39.63825,y:-86.12833},
{x:38.02515,y:-84.40713},
{x:41.70578,y:-86.62114},
{x:41.70689,y:-86.62347},
{x:33.91256,y:-117.88137},
{x:39.63807,y:-104.73215},
{x:28.598,y:-81.21241},
{x:30.21323,y:-97.65824},
{x:34.13585,y:-116.05465},
{x:41.15422,y:-81.34458},
{x:28.47167,y:-81.4531},
{x:30.08426,y:-95.52348},
{x:38.90414,y:-121.07391},
{x:39.966,y:-83.78537},
{x:37.79023,y:-122.43397},
{x:34.02713,y:-118.39276},
{x:39.54031,y:-119.73423},
{x:39.55397,y:-105.00733},
{x:39.77358,y:-104.88231},
{x:33.50619,y:-112.06459},
{x:33.94524,y:-118.34416},
{x:35.29369,y:-120.67091},
{x:40.29665,y:-111.73595},
{x:39.9874,y:-104.75074},
{x:39.72894,y:-121.8409},
{x:38.88658,y:-77.44484},
{x:41.60827,y:-81.44072},
{x:42.36044,y:-71.05427},
{x:32.64439,y:-117.00139},
{x:40.76875,y:-73.79201},
{x:34.7597,y:-92.30814},
{x:40.77975,y:-73.4511},
{x:32.90224,y:-97.03805},
{x:40.69047,y:-74.17649},
{x:40.68855,y:-74.17711},
{x:44.98768,y:-93.42114},
{x:33.75973,y:-118.11464},
{x:34.0646,y:-118.39884},
{x:37.09982,y:-113.53407},
{x:41.52542,y:-93.60246},
{x:40.81751,y:-96.70053},
{x:37.92876,y:-122.04001},
{x:42.25268,y:-87.86486},
{x:41.61519,y:-93.69822},
{x:41.55382,y:-90.52669},
{x:41.64475,y:-93.79367},
{x:30.62144,y:-96.34063},
{x:40.72564,y:-74.53357},
{x:32.95938,y:-117.26498},
{x:37.99138,y:-122.30247},
{x:37.2199,y:-121.86176},
{x:37.97651,y:-122.03376},
{x:38.54359,y:-121.74013},
{x:34.16244,y:-118.05283},
{x:33.64913,y:-117.58259},
{x:42.50366,y:-71.01534},
{x:33.65187,y:-117.64721},
{x:42.36351,y:-71.13005},
{x:34.07088,y:-84.27309},
{x:32.77631,y:-117.15462},
{x:38.75234,y:-121.30985},
{x:37.73968,y:-122.46732},
{x:37.69598,y:-122.07804},
{x:40.0256,y:-74.1434},
{x:40.94468,y:-74.27323},
{x:42.97128,y:-73.79774},
{x:33.17299,y:-117.25335},
{x:44.44891,y:-73.21097},
{x:38.94425,y:-95.28045},
{x:37.68912,y:-121.90232},
{x:38.90957,y:-94.39749},
{x:34.07982,y:-118.25985},
{x:33.97966,y:-118.46536},
{x:37.1125,y:-121.64359},
{x:47.73978,y:-122.34464},
{x:37.04265,y:-76.38595},
{x:34.0544,y:-84.55713},
{x:32.18218,y:-80.72265},
{x:25.84418,y:-80.12096},
{x:34.07175,y:-118.36077},
{x:36.12776,y:-115.16985},
{x:37.77991,y:-121.97229},
{x:46.60269,y:-120.53235},
{x:32.84877,y:-117.27329},
{x:37.36141,y:-122.09802},
{x:38.57714,y:-121.48093},
{x:35.25285,y:-81.0272},
{x:32.2768,y:-81.23024},
{x:40.50877,y:-88.98556},
{x:39.6115,y:-104.7531},
{x:27.98012,y:-82.53433},
{x:39.65703,y:-105.05342},
{x:39.33081,y:-80.23659},
{x:34.25448,y:-84.35794},
{x:40.75075,y:-111.86611},
{x:46.26225,y:-119.28631},
{x:34.1516,y:-118.02481},
{x:33.22009,y:-96.87749},
{x:39.93809,y:-75.16688},
{x:40.56539,y:-75.56486},
{x:37.55058,y:-122.04876},
{x:27.35882,y:-82.54903},
{x:37.78598,y:-122.43982},
{x:33.99456,y:-117.93116},
{x:34.05165,y:-118.25154},
{x:40.84143,y:-73.94293},
{x:39.72728,y:-89.61733},
{x:35.60562,y:-77.37104},
{x:32.83512,y:-96.96125},
{x:42.46006,y:-82.88337},
{x:34.02701,y:-118.50182},
{x:35.96101,y:-77.82184},
{x:39.69171,y:-104.8653},
{x:37.31396,y:-121.79401},
{x:33.65608,y:-112.3761},
{x:47.31474,y:-122.3164},
{x:39.38004,y:-104.86277},
{x:37.33341,y:-121.88734},
{x:33.91763,y:-117.96625},
{x:42.84833,y:-106.29222},
{x:42.83514,y:-106.33879},
{x:34.42817,y:-119.8766},
{x:35.11529,y:-106.51397},
{x:39.56264,y:-104.87602},
{x:47.57657,y:-122.13705},
{x:33.84768,y:-118.13438},
{x:46.88552,y:-114.03647},
{x:33.79175,y:-117.76349},
{x:33.59088,y:-101.95538},
{x:36.3926,y:-105.58289},
{x:40.23453,y:-74.94158},
{x:40.05487,y:-75.52892},
{x:32.71765,y:-117.15943},
{x:39.16417,y:-75.5165},
{x:38.42824,y:-75.05609},
{x:33.73493,y:-117.76069},
{x:41.90405,y:-87.63164},
{x:30.39032,y:-95.67787},
{x:39.66917,y:-86.02135},
{x:32.67768,y:-97.05502},
{x:45.24668,y:-93.66659},
{x:42.51858,y:-87.94963},
{x:40.7027,y:-99.09457},
{x:33.68956,y:-117.89354},
{x:30.59223,y:-81.45602},
{x:35.84619,y:-86.43977},
{x:32.91371,y:-117.14213},
{x:32.8394,y:-97.08306},
{x:40.15281,y:-105.1067},
{x:36.10193,y:-115.16895},
{x:34.06267,y:-118.20223},
{x:44.3009,y:-88.41827},
{x:32.80854,y:-117.11523},
{x:42.84276,y:-85.86406},
{x:42.47988,y:-82.98833},
{x:41.15863,y:-87.84766},
{x:36.76933,y:-76.58563},
{x:34.3892,y:-118.57258},
{x:33.72636,y:-117.98865},
{x:37.80064,y:-122.43854},
{x:37.67909,y:-121.77802},
{x:37.65219,y:-122.402},
{x:37.65344,y:-120.95713},
{x:34.44173,y:-119.83222},
{x:34.10701,y:-117.82648},
{x:34.25075,y:-118.60628},
{x:38.01109,y:-121.32213},
{x:37.79676,y:-122.42209},
{x:37.23564,y:-121.96449},
{x:37.33527,y:-121.89287},
{x:39.54908,y:-104.99922},
{x:39.07773,y:-108.54976},
{x:33.46416,y:-112.06538},
{x:35.08571,y:-85.06599},
{x:32.66152,y:-96.87959},
{x:37.71821,y:-97.29451},
{x:33.63009,y:-111.88855},
{x:40.73869,y:-111.8254},
{x:33.72177,y:-116.36304},
{x:41.89401,y:-87.6245},
{x:34.41335,y:-119.85521},
{x:38.86626,y:-104.6823},
{x:36.12243,y:-115.16829},
{x:37.75695,y:-122.25289},
{x:37.53781,y:-122.30018},
{x:38.57872,y:-92.18141},
{x:36.18133,y:-94.50755},
{x:37.29271,y:-121.93034},
{x:33.42562,y:-111.94014},
{x:34.25676,y:-118.5805},
{x:33.99534,y:-81.02819},
{x:33.46655,y:-112.50481},
{x:39.60612,y:-105.09045},
{x:33.63688,y:-117.92152},
{x:34.58,y:-118.09638},
{x:37.9664,y:-121.78238},
{x:36.80881,y:-119.80666},
{x:40.70689,y:-74.01301},
{x:40.76276,y:-73.96913},
{x:34.03906,y:-118.14342},
{x:32.70639,y:-97.35999},
{x:34.04229,y:-118.45877},
{x:33.90227,y:-118.38355},
{x:46.59962,y:-120.58712},
{x:34.13601,y:-118.13248},
{x:38.86624,y:-104.68032},
{x:39.78584,y:-83.9968},
{x:33.74202,y:-116.30226},
{x:34.19897,y:-118.86878},
{x:32.76867,y:-117.05454},
{x:34.02632,y:-118.4327},
{x:34.44532,y:-118.55329},
{x:45.38137,y:-122.75804},
{x:45.33159,y:-122.58307},
{x:33.13391,y:-96.66187},
{x:34.08482,y:-117.96422},
{x:41.85263,y:-87.63458},
{x:38.89431,y:-104.75137},
{x:39.39396,y:-119.76637},
{x:45.02874,y:-93.45686},
{x:28.29203,y:-82.71113},
{x:28.44598,y:-81.55975},
{x:28.69205,y:-81.38705},
{x:38.42704,y:-78.8748},
{x:36.60053,y:-121.86903},
{x:39.91238,y:-105.05239},
{x:38.62831,y:-90.33283},
{x:37.37135,y:-121.84234},
{x:40.40544,y:-74.64991},
{x:40.903,y:-74.0717},
{x:34.05942,y:-118.44472},
{x:37.30016,y:-121.95053},
{x:43.08475,y:-88.76159},
{x:44.95743,y:-93.29544},
{x:25.96732,y:-80.14263},
{x:33.72549,y:-117.00699},
{x:35.1069,y:-106.58758},
{x:32.56918,y:-97.11571},
{x:33.15913,y:-117.35143},
{x:36.32996,y:-121.24137},
{x:34.54135,y:-83.54005},
{x:37.01434,y:-121.58589},
{x:37.77723,y:-122.41932},
{x:34.07723,y:-118.26489},
{x:32.61961,y:-116.96081},
{x:42.47689,y:-88.10573},
{x:33.37777,y:-111.63366},
{x:39.92147,y:-75.14681},
{x:33.1757,y:-96.6954},
{x:42.19147,y:-71.19563},
{x:46.86785,y:-113.98209},
{x:34.12487,y:-118.05693},
{x:33.97592,y:-117.34024},
{x:32.02434,y:-81.22614},
{x:47.52762,y:-121.87391},
{x:37.99314,y:-122.11943},
{x:39.59058,y:-106.09502},
{x:34.07597,y:-118.34499},
{x:34.67983,y:-82.83069},
{x:42.1023,y:-72.59047},
{x:37.66768,y:-122.4684},
{x:33.67172,y:-112.20241},
{x:41.85859,y:-88.02524},
{x:34.45759,y:-118.61734},
{x:38.71284,y:-82.85788},
{x:33.86007,y:-118.0474},
{x:33.33603,y:-111.80757},
{x:30.0846,y:-95.44869},
{x:41.9691,y:-87.67508},
{x:41.98731,y:-87.83796},
{x:42.2022,y:-87.86258},
{x:35.1434,y:-89.79284},
{x:32.46085,y:-96.99154},
{x:33.67716,y:-117.83307},
{x:33.36289,y:-111.91067},
{x:33.88103,y:-117.9253},
{x:39.40036,y:-107.22039},
{x:34.09827,y:-118.31106},
{x:39.35872,y:-84.27616},
{x:37.5111,y:-122.29309},
{x:34.09475,y:-118.12817},
{x:34.07002,y:-117.35219},
{x:34.06444,y:-117.57391},
{x:33.33492,y:-111.96723},
{x:33.55414,y:-111.88039},
{x:36.96375,y:-122.04257},
{x:33.56739,y:-112.07104},
{x:30.60412,y:-87.12221},
{x:38.77279,y:-93.73572},
{x:35.13676,y:-90.01428},
{x:35.9238,y:-86.81656},
{x:41.58595,y:-87.71483},
{x:35.96619,y:-78.54338},
{x:39.74771,y:-104.88512},
{x:34.13627,y:-118.1502},
{x:40.65586,y:-74.00818},
{x:34.75507,y:-77.46359},
{x:37.74286,y:-88.97013},
{x:39.86186,y:-86.39313},
{x:35.8655,y:-86.46299},
{x:35.81304,y:-80.87651},
{x:35.44008,y:-84.59446},
{x:36.60918,y:-83.69941},
{x:33.99921,y:-81.02488},
{x:32.79236,y:-96.74689},
{x:30.35464,y:-97.75649},
{x:30.41204,y:-91.18687},
{x:33.39249,y:-111.78891},
{x:39.15291,y:-108.73876},
{x:39.00673,y:-94.27484},
{x:40.72341,y:-73.95144},
{x:40.49557,y:-74.44988},
{x:38.72802,y:-93.56585},
{x:38.67667,y:-76.87472},
{x:39.63411,y:-75.65641},
{x:25.78654,y:-80.33726},
{x:33.87486,y:-118.1843},
{x:37.77573,y:-122.44627},
{x:34.16052,y:-118.34428},
{x:34.44506,y:-118.47299},
{x:37.7391,y:-121.11921},
{x:33.90262,y:-118.32626},
{x:36.24047,y:-115.16377},
{x:41.00213,y:-75.27304},
{x:42.15021,y:-71.48785},
{x:39.19664,y:-76.61587},
{x:40.47409,y:-78.40304},
{x:40.14684,y:-75.11577},
{x:40.06751,y:-76.30675},
{x:40.35843,y:-74.97666},
{x:39.63234,y:-106.52117},
{x:34.69734,y:-86.63268},
{x:39.96644,y:-75.14036},
{x:32.29636,y:-110.97309},
{x:39.97483,y:-75.12106},
{x:44.97083,y:-93.49578},
{x:28.47796,y:-81.47101},
{x:39.79717,y:-105.0787},
{x:32.87071,y:-117.21043},
{x:36.33031,y:-119.29245},
{x:29.59286,y:-95.62562},
{x:34.06666,y:-118.41029},
{x:34.09815,y:-118.34353},
{x:39.96236,y:-75.16181},
{x:30.20486,y:-81.75933},
{x:41.14822,y:-81.34522},
{x:38.03969,y:-84.5027},
{x:33.43594,y:-79.13152},
{x:32.9285,y:-80.68721},
{x:34.83174,y:-82.34823},
{x:37.86886,y:-122.2682},
{x:40.76345,y:-74.3026},
{x:28.48735,y:-81.4335},
{x:41.65116,y:-83.53399},
{x:29.85843,y:-81.26666},
{x:25.83678,y:-80.32279},
{x:37.70076,y:-122.48321},
{x:32.68313,y:-114.63575},
{x:36.02971,y:-115.08603},
{x:33.88753,y:-117.86308},
{x:39.61282,y:-105.00577},
{x:32.47076,y:-90.14056},
{x:37.38912,y:-121.98344},
{x:38.49599,y:-121.50772},
{x:34.77771,y:-82.30965},
{x:33.37871,y:-111.78874},
{x:29.6585,y:-95.71328},
{x:33.67151,y:-117.7894},
{x:35.06994,y:-80.70107},
{x:36.0065,y:-78.52037},
{x:35.30699,y:-82.45549},
{x:36.01866,y:-80.40704},
{x:35.94747,y:-78.54278},
{x:35.22369,y:-81.03749},
{x:39.15008,y:-76.90784},
{x:32.80095,y:-80.01873},
{x:37.27966,y:-122.03114},
{x:40.46689,y:-79.95292},
{x:39.19584,y:-78.19296},
{x:40.64475,y:-74.07434},
{x:35.10605,y:-80.808},
{x:42.29213,y:-88.42167},
{x:37.27619,y:-121.84248},
{x:38.54944,y:-121.40777},
{x:38.34926,y:-122.7101},
{x:36.80992,y:-114.06236},
{x:43.04742,y:-87.99804},
{x:42.9965,y:-88.05013},
{x:34.80386,y:-92.42883},
{x:33.88518,y:-118.40989},
{x:37.52269,y:-122.25178},
{x:38.36002,y:-98.79387},
{x:34.75633,y:-92.44958},
{x:39.72978,y:-82.61523},
{x:38.00874,y:-84.21382},
{x:39.13143,y:-84.60909},
{x:39.29816,y:-77.84429},
{x:39.99649,y:-75.75473},
{x:33.70191,y:-111.92484},
{x:40.30791,y:-75.85835},
{x:29.99379,y:-90.25687},
{x:32.82774,y:-96.84604},
{x:41.88833,y:-87.80371},
{x:21.29238,y:-157.84197},
{x:41.71891,y:-87.6826},
{x:34.1362,y:-117.87074},
{x:39.9323,y:-75.5891},
{x:35.14514,y:-85.22046},
{x:38.63694,y:-90.26167},
{x:39.86293,y:-104.67341},
{x:32.85339,y:-97.03862},
{x:26.18607,y:-80.12022},
{x:33.64049,y:-112.11225},
{x:40.35317,y:-111.76581},
{x:21.32966,y:-158.0731},
{x:33.65429,y:-117.29751},
{x:33.80367,y:-117.90533},
{x:33.65471,y:-112.01404},
{x:34.42171,y:-119.65018},
{x:33.42601,y:-117.61138},
{x:40.73049,y:-73.88736},
{x:42.7826,y:-86.08184},
{x:33.78753,y:-117.82613},
{x:33.3217,y:-111.98095},
{x:34.08004,y:-118.09729},
{x:33.18165,y:-117.34353},
{x:36.19565,y:-115.25891},
{x:39.66756,y:-104.93913},
{x:33.50875,y:-112.04582},
{x:37.35039,y:-121.92209},
{x:33.53928,y:-111.92507},
{x:47.00336,y:-122.88122},
{x:45.49922,y:-122.80932},
{x:34.93551,y:-89.98934},
{x:38.88089,y:-94.83491},
{x:33.61225,y:-111.99545},
{x:34.16077,y:-118.51038},
{x:39.70707,y:-104.92051},
{x:38.81262,y:-94.46838},
{x:41.97852,y:-87.89721},
{x:37.38325,y:-121.8944},
{x:41.19318,y:-111.94321},
{x:47.44286,y:-122.30136},
{x:25.77517,y:-80.17765},
{x:47.44511,y:-122.30133},
{x:30.39091,y:-97.72224},
{x:32.53229,y:-96.82039},
{x:35.99038,y:-95.80995},
{x:28.55048,y:-81.58033},
{x:34.21461,y:-78.02002},
{x:45.85087,y:-95.38731},
{x:33.74429,y:-117.02028},
{x:33.28269,y:-111.89024},
{x:35.61479,y:-82.31857},
{x:26.6364,y:-80.20465},
{x:39.4239,y:-104.87984},
{x:45.74882,y:-87.08025},
{x:40.68879,y:-73.96287},
{x:38.06798,y:-84.52943},
{x:33.77347,y:-117.8528},
{x:34.15418,y:-118.82813},
{x:40.0243,y:-86.90199},
{x:44.83488,y:-93.60075},
{x:34.14468,y:-118.41237},
{x:42.02433,y:-71.11632},
{x:31.92037,y:-106.43832},
{x:33.2976,y:-111.87701},
{x:31.78095,y:-106.50391},
{x:31.83729,y:-106.55132},
{x:35.20043,y:-106.65058},
{x:47.66962,y:-117.10377},
{x:37.75134,y:-122.4317},
{x:33.8912,y:-117.77562},
{x:33.99016,y:-118.47639},
{x:34.06372,y:-118.29706},
{x:39.14503,y:-94.55843},
{x:39.19046,y:-96.60553},
{x:39.54429,y:-119.81598},
{x:34.27945,y:-118.8731},
{x:34.07519,y:-117.54652},
{x:33.12506,y:-117.32017},
{x:42.97189,y:-85.64022},
{x:39.91357,y:-104.92218},
{x:32.93696,y:-117.09953},
{x:40.72181,y:-111.85427},
{x:36.8089,y:-119.71264},
{x:33.13255,y:-94.96896},
{x:40.00058,y:-105.00634},
{x:39.3277,y:-82.10203},
{x:39.95217,y:-82.98774},
{x:33.90827,y:-84.20574},
{x:39.87104,y:-104.9791},
{x:34.09734,y:-118.32249},
{x:38.54059,y:-121.73188},
{x:48.99577,y:-122.75148},
{x:44.97796,y:-93.27029},
{x:35.34039,y:-119.10362},
{x:38.06597,y:-122.1652},
{x:27.91014,y:-81.84293},
{x:39.20448,y:-84.37746},
{x:33.0183,y:-96.70021},
{x:30.24322,y:-97.721},
{x:39.9537,y:-75.19496},
{x:33.61116,y:-112.18527},
{x:35.08064,y:-106.61745},
{x:30.16958,y:-95.45326},
{x:29.99949,y:-95.58397},
{x:35.75789,y:-78.73822},
{x:36.06765,y:-80.32026},
{x:35.15711,y:-106.59399},
{x:34.1434,y:-83.95604},
{x:32.82299,y:-79.8189},
{x:26.11753,y:-80.25851},
{x:34.83026,y:-82.30838},
{x:34.24811,y:-77.88152},
{x:33.94315,y:-83.44139},
{x:33.65371,y:-112.064},
{x:47.61058,y:-122.33351},
{x:33.55342,y:-112.1098},
{x:37.96951,y:-121.2989},
{x:34.81783,y:-86.49214},
{x:34.13981,y:-117.97775},
{x:36.12408,y:-115.16802},
{x:37.95349,y:-121.95506},
{x:39.92075,y:-86.22637},
{x:39.44139,y:-87.3324},
{x:41.36867,y:-93.55685},
{x:42.78803,y:-96.94066},
{x:40.73443,y:-74.16486},
{x:46.80369,y:-96.84477},
{x:37.67881,y:-97.20047},
{x:30.55535,y:-96.26418},
{x:37.69751,y:-121.83977},
{x:37.80841,y:-122.41284},
{x:39.91076,y:-105.09578},
{x:39.55534,y:-119.75282},
{x:46.85279,y:-100.77181},
{x:40.78032,y:-73.77675},
{x:33.57521,y:-84.37137},
{x:32.68066,y:-97.09432},
{x:33.57447,y:-111.90532},
{x:38.91131,y:-120.00402},
{x:35.22028,y:-80.84547},
{x:32.64405,y:-97.18634},
{x:33.85209,y:-84.36739},
{x:32.54803,y:-116.97437},
{x:40.14587,y:-75.39396},
{x:40.62651,y:-73.91693},
{x:37.24582,y:-121.88845},
{x:42.579,y:-71.0004},
{x:36.33371,y:-78.43523},
{x:37.65188,y:-122.09091},
{x:33.78436,y:-112.11822},
{x:33.2496,y:-111.5623},
{x:41.59534,y:-73.91083},
{x:35.35396,y:-119.05806},
{x:34.18778,y:-118.59929},
{x:34.97929,y:-92.0384},
{x:38.21266,y:-90.40281},
{x:34.17068,y:-118.56998},
{x:42.9162,y:-85.50091},
{x:39.85875,y:-104.67591},
{x:40.71979,y:-73.99921},
{x:34.9491,y:-81.93347},
{x:34.0619,y:-118.33889},
{x:35.06307,y:-85.30978},
{x:41.64259,y:-93.46635},
{x:42.90108,y:-78.87366},
{x:35.22047,y:-80.94639},
{x:41.65088,y:-93.50922},
{x:41.36821,y:-81.85305},
{x:29.39271,y:-98.62993},
{x:26.4475,y:-81.81776},
{x:34.21102,y:-118.84033},
{x:29.54822,y:-95.58425},
{x:34.01969,y:-118.45475},
{x:34.27611,y:-118.56341},
{x:34.0625,y:-118.44739},
{x:30.27658,y:-95.46501},
{x:32.89697,y:-117.19298},
{x:33.52627,y:-111.92464},
{x:43.04493,y:-87.90392},
{x:40.42932,y:-86.91239},
{x:34.13977,y:-118.02389},
{x:36.03153,y:-115.2082},
{x:34.41531,y:-118.47249},
{x:38.79492,y:-121.266},
{x:37.97094,y:-87.56761},
{x:42.87498,y:-88.33603},
{x:42.98385,y:-87.91338},
{x:39.03991,y:-94.64227},
{x:42.22536,y:-88.32407},
{x:37.39635,y:-121.97418},
{x:37.02294,y:-121.56387},
{x:33.69595,y:-78.87562},
{x:39.51155,y:-84.73414},
{x:33.92287,y:-118.39593},
{x:32.57182,y:-96.30631},
{x:36.77925,y:-76.25637},
{x:35.16182,y:-80.97099},
{x:30.36915,y:-91.07344},
{x:32.87025,y:-97.3413},
{x:36.01974,y:-115.26362},
{x:32.23154,y:-110.95906},
{x:30.60317,y:-87.89681},
{x:38.97621,y:-76.31275},
{x:33.97924,y:-118.03718},
{x:33.80301,y:-118.14227},
{x:40.9456,y:-72.9411},
{x:40.74901,y:-73.64066},
{x:41.26219,y:-95.88393},
{x:40.75034,y:-73.99299},
{x:32.33674,y:-110.97901},
{x:35.47014,y:-83.00425},
{x:35.80596,y:-80.87599},
{x:34.10762,y:-83.87543},
{x:37.78017,y:-122.47814},
{x:37.64611,y:-118.96432},
{x:34.04532,y:-118.25721},
{x:38.86366,y:-76.9904},
{x:39.75819,y:-94.78267},
{x:34.11575,y:-118.1506},
{x:43.01848,y:-85.72454},
{x:34.15904,y:-79.75497},
{x:27.43282,y:-82.42533},
{x:35.50213,y:-82.98866},
{x:29.94277,y:-90.095},
{x:31.88459,y:-106.57837},
{x:39.44706,y:-77.98747},
{x:34.82847,y:-82.39934},
{x:40.68919,y:-80.05735},
{x:43.08989,y:-78.97974},
{x:35.14414,y:-90.05282},
{x:37.79026,y:-122.42206},
{x:35.17926,y:-89.83673},
{x:38.57503,y:-75.28696},
{x:40.25694,y:-76.91665},
{x:33.74363,y:-117.81696},
{x:33.85318,y:-117.99836},
{x:37.50414,y:-82.5374},
{x:36.08111,y:-83.92511},
{x:36.32423,y:-82.39241},
{x:41.71528,y:-86.90428},
{x:38.63565,y:-90.28994},
{x:35.40116,y:-119.09034},
{x:34.08646,y:-118.21944},
{x:36.35666,y:-119.31402},
{x:41.10057,y:-81.38621},
{x:34.8511,y:-117.08202},
{x:38.65299,y:-75.59365},
{x:26.11956,y:-80.26375},
{x:33.87483,y:-117.89005},
{x:39.47081,y:-87.41089},
{x:42.11943,y:-88.25845},
{x:37.33231,y:-121.9126},
{x:32.95327,y:-117.23118},
{x:33.88567,y:-117.56515},
{x:33.65876,y:-117.80211},
{x:33.09797,y:-117.20057},
{x:34.25082,y:-84.09123},
{x:37.69735,y:-122.46296},
{x:25.77515,y:-80.17769},
{x:40.04325,y:-82.92235},
{x:38.0354,y:-78.50663},
{x:44.45357,y:-93.15901},
{x:37.04749,y:-122.03056},
{x:30.70447,y:-97.74877},
{x:37.77958,y:-122.27566},
{x:43.58686,y:-119.0569},
{x:47.24325,y:-122.20995},
{x:32.57207,y:-97.10843},
{x:45.30419,y:-122.76232},
{x:48.84336,y:-122.58022},
{x:30.20883,y:-92.0383},
{x:30.44518,y:-91.14056},
{x:30.35554,y:-91.11103},
{x:30.43205,y:-91.0829},
{x:38.61195,y:-121.49698},
{x:29.61613,y:-82.38962},
{x:39.48144,y:-119.7942},
{x:33.41087,y:-82.02595},
{x:36.18931,y:-86.74581},
{x:33.39318,y:-111.61563},
{x:34.54023,y:-117.26721},
{x:41.49063,y:-90.4808},
{x:34.26134,y:-119.23186},
{x:45.558,y:-122.86513},
{x:29.96785,y:-90.05635},
{x:33.67204,y:-117.91979},
{x:33.78132,y:-117.89185},
{x:37.42739,y:-122.14927},
{x:38.63652,y:-121.50478},
{x:37.84819,y:-122.03168},
{x:42.68775,y:-71.14644},
{x:43.03556,y:-88.01567},
{x:38.36755,y:-93.75292},
{x:42.2917,y:-85.58335},
{x:40.61202,y:-75.37187},
{x:37.71163,y:-122.16247},
{x:33.87247,y:-118.37857},
{x:34.25343,y:-110.02817},
{x:33.6393,y:-111.97824},
{x:34.1276,y:-118.44385},
{x:34.30149,y:-86.27565},
{x:37.48502,y:-122.2311},
{x:38.63724,y:-121.32918},
{x:33.80516,y:-118.35073},
{x:27.86257,y:-82.49615},
{x:42.36169,y:-71.02068},
{x:30.20234,y:-97.66529},
{x:42.36605,y:-71.01522},
{x:40.74715,y:-73.82535},
{x:34.15269,y:-118.46034},
{x:34.28395,y:-118.71892},
{x:38.53468,y:-121.49445},
{x:40.15144,y:-84.21612},
{x:30.17977,y:-96.91453},
{x:41.42275,y:-87.33471},
{x:37.94872,y:-122.05968},
{x:43.64912,y:-116.43415},
{x:32.95845,y:-117.12477},
{x:33.37867,y:-111.94707},
{x:41.47231,y:-87.01819},
{x:37.07295,y:-120.83463},
{x:39.16038,y:-84.27742},
{x:35.11594,y:-81.07464},
{x:28.69627,y:-81.26143},
{x:38.44478,y:-105.22972},
{x:35.74673,y:-78.87319},
{x:34.03112,y:-118.84311},
{x:33.12884,y:-96.66232},
{x:40.52677,y:-74.20153},
{x:41.48471,y:-81.80158},
{x:34.18094,y:-118.39438},
{x:35.04843,y:-80.64766},
{x:38.83363,y:-104.8264},
{x:33.61491,y:-117.8748},
{x:33.80363,y:-117.88717},
{x:34.04316,y:-118.43167},
{x:34.09797,y:-118.29267},
{x:34.0005,y:-117.55655},
{x:32.05006,y:-110.70689},
{x:39.059,y:-77.0489},
{x:39.28653,y:-77.20809},
{x:29.892,y:-81.3137},
{x:33.58693,y:-111.92552},
{x:37.60295,y:-122.39461},
{x:37.51988,y:-122.27559},
{x:33.99939,y:-83.90568},
{x:30.49375,y:-97.65703},
{x:35.82133,y:-78.62291},
{x:35.18216,y:-97.41641},
{x:41.66316,y:-86.10913},
{x:31.42754,y:-100.51187},
{x:38.18371,y:-84.56642},
{x:41.64681,y:-91.54131},
{x:41.55058,y:-90.48696},
{x:31.76736,y:-106.30171},
{x:42.27842,y:-71.16373},
{x:39.55472,y:-119.72233},
{x:39.59531,y:-104.80433},
{x:43.59754,y:-116.19457},
{x:39.94329,y:-86.23643},
{x:40.36804,y:-80.63942},
{x:43.61789,y:-116.35122},
{x:41.78026,y:-87.86009},
{x:32.53471,y:-84.97158},
{x:42.95552,y:-78.87394},
{x:37.49037,y:-122.22509},
{x:38.93058,y:-122.6181},
{x:34.05796,y:-118.36371},
{x:43.48332,y:-83.97087},
{x:37.24912,y:-121.85786},
{x:37.56784,y:-122.02531},
{x:39.73069,y:-82.62079},
{x:33.98655,y:-117.8718},
{x:34.14042,y:-80.95168},
{x:38.42407,y:-122.71384},
{x:34.07863,y:-117.89055},
{x:37.76371,y:-122.47746},
{x:33.99913,y:-84.70427},
{x:37.26506,y:-121.9183},
{x:35.90248,y:-78.78578},
{x:30.31376,y:-95.4739},
{x:37.10332,y:-113.55423},
{x:40.06844,y:-76.31134},
{x:39.86683,y:-74.92266},
{x:40.30331,y:-79.59634},
{x:41.69931,y:-83.65396},
{x:41.10463,y:-74.02262},
{x:38.64212,y:-90.50546},
{x:32.73256,y:-97.11685},
{x:39.60229,y:-104.89172},
{x:33.60892,y:-111.72948},
{x:35.131,y:-106.51285},
{x:35.90686,y:-78.72577},
{x:35.6401,y:-78.83787},
{x:33.43568,y:-112.00869},
{x:33.43772,y:-112.00862},
{x:33.43427,y:-112.00884},
{x:34.15167,y:-118.8255},
{x:35.1299,y:-106.58707},
{x:34.04777,y:-118.4914},
{x:33.70935,y:-96.59033},
{x:40.02198,y:-105.25897},
{x:33.81616,y:-116.45743},
{x:37.56092,y:-122.28033},
{x:39.86141,y:-105.16769},
{x:44.97728,y:-93.25795},
{x:32.2723,y:-80.91587},
{x:36.7044,y:-121.66724},
{x:34.16983,y:-84.1853},
{x:28.06577,y:-82.40988},
{x:44.93981,y:-93.08949},
{x:39.4464,y:-83.80718},
{x:38.99011,y:-94.60952},
{x:43.01339,y:-89.47988},
{x:45.01931,y:-93.32719},
{x:37.89168,y:-122.12003},
{x:33.65577,y:-117.70188},
{x:34.22136,y:-118.45102},
{x:34.39452,y:-118.46151},
{x:34.03474,y:-117.75937},
{x:34.03224,y:-118.15319},
{x:37.83661,y:-122.29506},
{x:37.57978,y:-122.33336},
{x:39.83103,y:-77.23058},
{x:39.94766,y:-104.98623},
{x:21.29978,y:-157.83316},
{x:44.88249,y:-93.20498},
{x:44.88373,y:-93.21112},
{x:34.09989,y:-81.17757},
{x:60.56015,y:-151.24055},
{x:36.32382,y:-86.70711},
{x:36.21309,y:-115.99159},
{x:40.46903,y:-78.40651},
{x:33.39825,y:-84.73999},
{x:32.33963,y:-90.06749},
{x:35.2236,y:-111.58361},
{x:38.25268,y:-121.29675},
{x:33.46848,y:-112.39301},
{x:34.5803,y:-82.71482},
{x:32.76357,y:-117.10661},
{x:34.28347,y:-81.59604},
{x:30.09577,y:-97.87583},
{x:35.26221,y:-77.64748},
{x:34.12293,y:-83.82852},
{x:34.05949,y:-84.5931},
{x:30.42091,y:-91.21821},
{x:36.86187,y:-76.46803},
{x:34.03495,y:-84.34295},
{x:30.22137,y:-81.56475},
{x:43.66336,y:-116.41149},
{x:35.692,y:-105.95096},
{x:35.47628,y:-81.23625},
{x:34.61695,y:-86.56682},
{x:41.47631,y:-71.96155},
{x:34.03519,y:-118.68508},
{x:33.37895,y:-111.68739},
{x:36.80886,y:-119.63725},
{x:38.22043,y:-77.50677},
{x:33.72959,y:-117.95497},
{x:38.42284,y:-121.44634},
{x:33.84536,y:-118.38705},
{x:39.06051,y:-77.47089},
{x:34.00333,y:-83.70726},
{x:45.47035,y:-122.69084},
{x:36.05434,y:-115.27745},
{x:40.50921,y:-112.02381},
{x:37.35281,y:-122.05133},
{x:42.51956,y:-83.76244},
{x:39.95266,y:-75.14894},
{x:39.59811,y:-82.93206},
{x:37.35214,y:-121.9366},
{x:28.21659,y:-80.67361},
{x:34.40452,y:-118.55111},
{x:35.78351,y:-115.33184},
{x:34.39487,y:-119.51318},
{x:33.63771,y:-112.23261},
{x:43.15359,y:-76.12307},
{x:38.29071,y:-122.03429},
{x:42.19211,y:-87.82438},
{x:43.21853,y:-71.48495},
{x:41.37689,y:-72.15012},
{x:40.00865,y:-86.12855},
{x:40.86272,y:-74.38875},
{x:37.89808,y:-122.06009},
{x:38.78262,y:-89.94923},
{x:33.93886,y:-117.2825},
{x:40.37889,y:-111.81542},
{x:42.10219,y:-87.96199},
{x:41.62462,y:-73.92054},
{x:30.46883,y:-87.20888},
{x:42.99503,y:-78.69905},
{x:36.10464,y:-79.82135},
{x:42.35722,y:-72.54692},
{x:42.64502,y:-73.70082},
{x:40.00116,y:-83.00782},
{x:46.62111,y:-112.01889},
{x:43.04533,y:-71.46049},
{x:34.01789,y:-118.1507},
{x:26.01299,y:-80.175},
{x:40.22195,y:-85.3811},
{x:33.50665,y:-117.14638},
{x:28.00002,y:-81.9727},
{x:30.19453,y:-81.54909},
{x:43.03416,y:-77.44771},
{x:42.36734,y:-89.02809},
{x:36.11664,y:-94.15583},
{x:31.81873,y:-106.54779},
{x:45.61687,y:-122.5061},
{x:36.78841,y:-76.10894},
{x:34.24225,y:-118.55966},
{x:37.3453,y:-79.19134},
{x:38.79854,y:-77.30125},
{x:34.93386,y:-82.00826},
{x:42.77474,y:-73.88989},
{x:39.17124,y:-96.60547},
{x:39.45155,y:-74.63464},
{x:45.01329,y:-93.00975},
{x:44.17358,y:-93.95122},
{x:32.86412,y:-96.8069},
{x:34.18338,y:-118.78579},
{x:33.83796,y:-117.88591},
{x:30.44779,y:-84.31353},
{x:42.4238,y:-71.09013},
{x:39.90069,y:-75.06231},
{x:39.95997,y:-75.148},
{x:34.20527,y:-118.19855},
{x:43.07773,y:-77.63216},
{x:32.79147,y:-96.59672},
{x:30.42043,y:-95.49303},
{x:34.18872,y:-118.6416},
{x:30.84227,y:-83.94391},
{x:29.7634,y:-96.15196},
{x:32.9336,y:-97.31348},
{x:47.90682,y:-97.06582},
{x:37.73305,y:-85.88604},
{x:33.94004,y:-117.96708},
{x:37.59542,y:-122.50377},
{x:36.10049,y:-115.20911},
{x:28.25932,y:-82.18785},
{x:41.4249,y:-82.07667},
{x:34.15511,y:-118.64303},
{x:32.74873,y:-117.1523},
{x:39.49297,y:-76.3938},
{x:38.81864,y:-77.13992},
{x:37.48018,y:-122.15112},
{x:41.06124,y:-73.83725},
{x:42.36067,y:-71.01844},
{x:39.09461,y:-94.58332},
{x:34.43897,y:-119.75237},
{x:39.74296,y:-104.98905},
{x:33.13172,y:-117.20651},
{x:34.16476,y:-118.37428},
{x:34.26521,y:-118.52386},
{x:33.89117,y:-118.3003},
{x:33.79926,y:-78.73589},
{x:31.90986,y:-110.9802},
{x:32.84193,y:-104.42061},
{x:40.75237,y:-73.99438},
{x:40.7437,y:-73.9738},
{x:39.91441,y:-105.00898},
{x:29.46517,y:-98.48313},
{x:40.74514,y:-74.17016},
{x:30.13817,y:-81.53458},
{x:39.94741,y:-76.72852},
{x:34.12001,y:-118.15009},
{x:41.65996,y:-83.615},
{x:37.31294,y:-79.88037},
{x:36.31576,y:-115.30809},
{x:33.3011,y:-111.89724},
{x:34.03583,y:-118.08204},
{x:33.64205,y:-112.22345},
{x:48.19965,y:-114.32923},
{x:37.44438,y:-122.16342},
{x:33.85404,y:-118.13644},
{x:25.95724,y:-80.14189},
{x:28.444,y:-81.39337},
{x:34.06387,y:-83.98604},
{x:33.10046,y:-96.8093},
{x:33.83262,y:-118.35044},
{x:34.23948,y:-118.55561},
{x:38.91821,y:-77.22276},
{x:33.85802,y:-118.30298},
{x:37.99066,y:-84.52736},
{x:42.54157,y:-70.94503},
{x:43.58121,y:-116.17214},
{x:26.18772,y:-98.23626},
{x:42.11724,y:-70.82347},
{x:42.09383,y:-71.22039},
{x:33.57576,y:-101.90655},
{x:35.05513,y:-80.77074},
{x:25.69074,y:-80.31104},
{x:37.59916,y:-122.06914},
{x:32.98851,y:-96.99139},
{x:32.88942,y:-97.14685},
{x:33.03716,y:-97.06812},
{x:33.026,y:-96.79925},
{x:34.1557,y:-118.34696},
{x:41.25518,y:-95.97826},
{x:36.91545,y:-121.76753},
{x:18.40919,y:-66.10497},
{x:32.78921,y:-96.78927},
{x:33.88843,y:-117.2745},
{x:33.38089,y:-111.80617},
{x:34.1503,y:-118.08038},
{x:40.76096,y:-111.8789},
{x:41.26194,y:-95.98129},
{x:39.70897,y:-104.94006},
{x:33.66401,y:-117.85699},
{x:33.43929,y:-117.62059},
{x:37.3875,y:-122.08305},
{x:32.86426,y:-97.18663},
{x:40.20466,y:-83.02931},
{x:28.22477,y:-81.6444},
{x:39.83884,y:-89.60411},
{x:33.58071,y:-111.88185},
{x:40.87416,y:-74.64984},
{x:34.00345,y:-118.47196},
{x:36.38533,y:-82.40834},
{x:36.48214,y:-82.49496},
{x:36.34952,y:-82.23824},
{x:40.7857,y:-77.83094},
{x:32.86955,y:-96.77227},
{x:29.78281,y:-95.53962},
{x:41.88391,y:-87.6288},
{x:37.7445,y:-122.45271},
{x:33.56983,y:-117.70717},
{x:35.4858,y:-120.66216},
{x:41.92839,y:-87.83548},
{x:40.55299,y:-105.09208},
{x:33.38078,y:-111.75342},
{x:28.82071,y:-81.78092},
{x:36.08436,y:-79.82955},
{x:37.2322,y:-121.77503},
{x:38.10178,y:-122.5699},
{x:28.47506,y:-81.62617},
{x:28.4653,y:-81.47122},
{x:34.09108,y:-80.92676},
{x:40.82141,y:-74.40875},
{x:37.30757,y:-79.88933},
{x:39.9377,y:-82.78855},
{x:32.99506,y:-117.25891},
{x:38.61753,y:-121.39817},
{x:33.94865,y:-118.00708},
{x:33.21091,y:-97.14627},
{x:34.00528,y:-117.82261},
{x:34.10407,y:-118.25901},
{x:38.6552,y:-121.17832},
{x:33.97748,y:-117.46014},
{x:45.62035,y:-122.64583},
{x:37.21692,y:-80.40263},
{x:47.76174,y:-122.34763},
{x:29.55109,y:-95.58422},
{x:37.35303,y:-121.99721},
{x:39.94017,y:-105.12701},
{x:36.11408,y:-115.24242},
{x:42.01211,y:-70.73306},
{x:31.25373,y:-85.43055},
{x:26.70779,y:-80.15875},
{x:28.1899,y:-82.61807},
{x:39.88559,y:-104.84422},
{x:38.74347,y:-121.30969},
{x:46.71427,y:-117.17746},
{x:27.53123,y:-82.50026},
{x:33.91664,y:-118.34896},
{x:34.13789,y:-77.89343},
{x:32.90596,y:-79.82385},
{x:35.96963,y:-79.06264},
{x:38.94027,y:-76.90651},
{x:33.91844,y:-117.22677},
{x:33.3919,y:-111.84055},
{x:34.69351,y:-82.97864},
{x:35.1303,y:-120.60436},
{x:37.0138,y:-121.5975},
{x:40.98119,y:-76.02946},
{x:34.16256,y:-118.31458},
{x:38.35334,y:-121.98599},
{x:35.09821,y:-85.32811},
{x:36.04619,y:-86.71359},
{x:33.33397,y:-111.75737},
{x:32.30665,y:-110.8919},
{x:44.83578,y:-93.40039},
{x:42.70299,y:-71.4375},
{x:32.24901,y:-110.85274},
{x:29.89021,y:-90.05365},
{x:40.1879,y:-80.22748},
{x:42.7983,y:-71.53621},
{x:34.67319,y:-118.14891},
{x:41.6156,y:-83.69517},
{x:37.55219,y:-122.31384},
{x:33.85286,y:-118.14002},
{x:33.4629,y:-112.35565},
{x:35.39386,y:-118.95516},
{x:44.246,y:-88.35714},
{x:33.85181,y:-84.35895},
{x:34.05941,y:-117.18611},
{x:40.27723,y:-74.54097},
{x:30.31659,y:-97.95412},
{x:40.13124,y:-74.22138},
{x:41.66074,y:-72.7248},
{x:36.80636,y:-119.69885},
{x:33.72334,y:-118.31205},
{x:29.99557,y:-90.25636},
{x:29.99389,y:-90.25379},
{x:40.34849,y:-79.95354},
{x:40.60876,y:-73.95897},
{x:38.50284,y:-90.44771},
{x:39.98739,y:-76.68053},
{x:33.52394,y:-117.16179},
{x:41.99942,y:-87.6607},
{x:44.46641,y:-73.18271},
{x:40.04574,y:-75.40892},
{x:33.56562,y:-117.20372},
{x:34.0517,y:-118.34392},
{x:32.21542,y:-80.70631},
{x:38.73079,y:-120.78686},
{x:42.69204,y:-88.26526},
{x:37.89146,y:-122.27995},
{x:35.19697,y:-106.66053},
{x:34.78282,y:-82.48667},
{x:36.73574,y:-119.78525},
{x:40.79845,y:-77.86549},
{x:42.35487,y:-71.06091},
{x:39.95192,y:-75.16379},
{x:33.87591,y:-117.91848},
{x:33.82319,y:-117.83571},
{x:38.56013,y:-121.42221},
{x:34.206,y:-118.2176},
{x:32.9577,y:-117.04003},
{x:34.17458,y:-118.3007},
{x:33.10417,y:-117.07661},
{x:37.3785,y:-122.11668},
{x:36.89555,y:-121.77808},
{x:32.89974,y:-97.03712},
{x:38.85054,y:-94.38158},
{x:32.89609,y:-97.0361},
{x:38.09118,y:-122.55497},
{x:36.4167,y:-82.47071},
{x:32.89748,y:-97.03555},
{x:41.83067,y:-88.03501},
{x:33.83715,y:-118.35538},
{x:33.85487,y:-117.56251},
{x:32.89562,y:-97.04323},
{x:40.73478,y:-73.86459},
{x:41.51374,y:-87.96513},
{x:28.49641,y:-82.59579},
{x:45.00599,y:-93.02468},
{x:33.91689,y:-118.01151},
{x:39.68247,y:-105.08183},
{x:39.36187,y:-74.4142},
{x:33.91702,y:-117.90068},
{x:33.85776,y:-118.36518},
{x:33.8053,y:-118.18978},
{x:40.11346,y:-88.27594},
{x:37.7935,y:-90.43819},
{x:32.70577,y:-97.39012},
{x:33.62517,y:-97.13255},
{x:39.62319,y:-104.8972},
{x:32.8224,y:-117.10011},
{x:44.23132,y:-88.42457},
{x:39.17048,y:-76.64298},
{x:41.87277,y:-87.6768},
{x:31.84962,y:-102.36761},
{x:33.77714,y:-117.86682},
{x:28.60213,y:-81.20023},
{x:18.42645,y:-66.05765},
{x:32.83234,y:-117.16604},
{x:37.80305,y:-122.40301},
{x:38.96749,y:-84.66959},
{x:38.45474,y:-121.37096},
{x:42.22882,y:-83.34629},
{x:32.75379,y:-117.20458},
{x:41.21678,y:-96.11183},
{x:39.29682,y:-77.83135},
{x:37.89445,y:-122.5307},
{x:33.58225,y:-111.89475},
{x:34.72154,y:-112.00287},
{x:30.31654,y:-81.66465},
{x:40.51152,y:-88.99203},
{x:37.62575,y:-122.426},
{x:34.1432,y:-118.39733},
{x:37.32833,y:-121.96611},
{x:45.63786,y:-122.64144},
{x:37.53486,y:-121.99829},
{x:37.69144,y:-121.77359},
{x:33.63924,y:-112.13598},
{x:35.74667,y:-119.24884},
{x:35.46788,y:-120.65391},
{x:45.6721,y:-122.52453},
{x:35.68729,y:-105.93984},
{x:34.14047,y:-118.36949},
{x:34.16898,y:-118.11255},
{x:34.1653,y:-118.28116},
{x:36.71495,y:-76.23761},
{x:37.29387,y:-76.51184},
{x:36.95577,y:-76.60344},
{x:36.71647,y:-76.18773},
{x:40.14196,y:-84.2651},
{x:39.54631,y:-82.43404},
{x:33.02182,y:-117.28206},
{x:40.03351,y:-83.14102},
{x:34.04755,y:-118.44522},
{x:38.67022,y:-121.72705},
{x:33.72185,y:-117.77099},
{x:30.78608,y:-81.6472},
{x:42.02813,y:-71.31051},
{x:32.87906,y:-117.1647},
{x:37.31962,y:-120.48},
{x:41.65865,y:-81.37714},
{x:34.27276,y:-77.81893},
{x:37.28719,y:-121.94505},
{x:34.12372,y:-118.2556},
{x:41.18059,y:-96.04231},
{x:39.24695,y:-94.5952},
{x:37.55333,y:-122.31389},
{x:33.75224,y:-96.53788},
{x:38.03473,y:-84.51032},
{x:38.45877,y:-121.41379},
{x:34.80446,y:-106.70386},
{x:33.17706,y:-96.84287},
{x:47.45408,y:-122.82224},
{x:33.2507,y:-111.56545},
{x:36.60045,y:-82.19368},
{x:40.62637,y:-122.3172},
{x:36.61771,y:-121.90358},
{x:36.00145,y:-115.10824},
{x:33.601,y:-117.69101},
{x:33.82503,y:-84.03317},
{x:32.78641,y:-116.83131},
{x:33.07452,y:-96.82191},
{x:33.65893,y:-118.00064},
{x:32.91021,y:-96.88136},
{x:37.77436,y:-122.23033},
{x:43.02305,y:-88.20157},
{x:37.78958,y:-122.40196},
{x:34.02554,y:-118.43125},
{x:31.13932,y:-97.32836},
{x:32.91537,y:-117.12083},
{x:37.68693,y:-122.1298},
{x:34.10642,y:-117.39597},
{x:47.56761,y:-122.63341},
{x:42.18456,y:-88.42138},
{x:39.16002,y:-77.22103},
{x:40.45889,y:-90.64091},
{x:33.64317,y:-117.59933},
{x:34.87556,y:-84.32241},
{x:47.99897,y:-122.11018},
{x:27.56961,y:-99.49978},
{x:48.7678,y:-122.48422},
{x:39.15997,y:-75.51045},
{x:40.86587,y:-73.05281},
{x:37.78935,y:-122.40682},
{x:34.06358,y:-118.37635},
{x:37.78589,y:-122.4211},
{x:45.79187,y:-108.51318},
{x:41.6965,y:-87.51864},
{x:40.96715,y:-81.34459},
{x:34.18673,y:-118.62424},
{x:40.6891,y:-73.98594},
{x:37.94591,y:-121.69555},
{x:29.53482,y:-98.39335},
{x:32.92318,y:-96.89953},
{x:37.34717,y:-79.18751},
{x:43.09392,y:-75.30763},
{x:30.19569,y:-85.81274},
{x:34.07902,y:-83.91886},
{x:31.92481,y:-106.44072},
{x:40.73667,y:-74.00085},
{x:40.58807,y:-111.97192},
{x:34.04596,y:-118.45108},
{x:32.81051,y:-96.62963},
{x:33.34787,y:-96.58505},
{x:34.13559,y:-117.24215},
{x:33.98251,y:-81.06089},
{x:34.03423,y:-118.13049},
{x:41.3042,y:-111.96838},
{x:40.52728,y:-111.8985},
{x:35.65904,y:-105.96288},
{x:37.50669,y:-77.66638},
{x:28.70107,y:-81.32757},
{x:35.60937,y:-117.66995},
{x:33.48054,y:-112.04231},
{x:39.05698,y:-104.84004},
{x:35.14239,y:-118.45061},
{x:37.36926,y:-121.92035},
{x:47.57629,y:-122.1394},
{x:38.59398,y:-121.28699},
{x:34.42954,y:-119.87241},
{x:47.0068,y:-123.38881},
{x:33.08584,y:-117.26829},
{x:33.17261,y:-86.28317},
{x:38.68165,y:-84.59843},
{x:37.92488,y:-122.08812},
{x:41.07605,y:-74.65935},
{x:39.08004,y:-78.21457},
{x:32.81104,y:-98.08116},
{x:41.70464,y:-87.79781},
{x:33.69364,y:-117.95465},
{x:34.15041,y:-118.07302},
{x:32.77992,y:-117.12956},
{x:34.02219,y:-118.39623},
{x:32.98099,y:-117.06119},
{x:33.83723,y:-118.18472},
{x:32.64741,y:-96.53157},
{x:34.1018,y:-118.3372},
{x:34.24544,y:-118.41923},
{x:34.99154,y:-85.20148},
{x:40.74713,y:-73.89038},
{x:33.62032,y:-117.92365},
{x:34.14772,y:-118.42814},
{x:40.74235,y:-73.94909},
{x:34.04254,y:-117.85028},
{x:38.45935,y:-122.67261},
{x:42.40973,y:-83.40984},
{x:40.75552,y:-73.97466},
{x:36.67399,y:-121.66536},
{x:45.01922,y:-93.493},
{x:40.73757,y:-92.86657},
{x:42.60521,y:-87.87885},
{x:34.96465,y:-81.89886},
{x:34.11918,y:-117.7813},
{x:36.14972,y:-81.20089},
{x:31.21585,y:-85.36279},
{x:32.88781,y:-97.03439},
{x:47.75527,y:-122.15259},
{x:33.87101,y:-118.05953},
{x:37.82586,y:-122.20939},
{x:25.68694,y:-80.34185},
{x:37.76316,y:-121.95067},
{x:44.90148,y:-93.17174},
{x:39.15782,y:-84.42405},
{x:34.79021,y:-82.48975},
{x:34.40228,y:-119.72341},
{x:33.6564,y:-117.91878},
{x:37.47057,y:-82.52206},
{x:36.70407,y:-81.97111},
{x:35.14034,y:-90.03047},
{x:40.74839,y:-73.98489},
{x:33.97777,y:-118.39175},
{x:39.24877,y:-84.59989},
{x:33.80411,y:-118.16993},
{x:27.41874,y:-80.38045},
{x:33.98698,y:-118.22552},
{x:35.14941,y:-101.91972},
{x:34.20867,y:-77.92565},
{x:40.06009,y:-80.59948},
{x:30.25797,y:-98.85192},
{x:41.45484,y:-74.05815},
{x:29.21186,y:-99.7779},
{x:41.59657,y:-93.80841},
{x:34.38002,y:-118.54209},
{x:40.1983,y:-74.09911},
{x:40.56036,y:-74.26445},
{x:42.3629,y:-71.01776},
{x:32.80955,y:-96.83792},
{x:31.77486,y:-106.37979},
{x:33.75998,y:-118.13368},
{x:36.15947,y:-115.28769},
{x:32.80675,y:-96.83601},
{x:35.10376,y:-106.67044},
{x:36.11456,y:-115.15741},
{x:34.12647,y:-118.26343},
{x:34.10194,y:-118.29146},
{x:38.3287,y:-81.60042},
{x:40.45634,y:-79.91501},
{x:33.78964,y:-118.13934},
{x:34.28237,y:-118.69185},
{x:39.15917,y:-76.89413},
{x:40.72087,y:-74.011},
{x:32.71588,y:-117.16905},
{x:35.72208,y:-78.65231},
{x:35.3758,y:-118.93745},
{x:37.43166,y:-122.20182},
{x:32.08213,y:-81.09267},
{x:25.80854,y:-80.19243},
{x:35.22825,y:-80.8442},
{x:34.4401,y:-103.1967},
{x:33.61364,y:-117.67949},
{x:40.31724,y:-76.4237},
{x:34.88781,y:-82.06952},
{x:34.82493,y:-82.56068},
{x:38.79532,y:-104.80237},
{x:33.21548,y:-96.68264},
{x:34.18004,y:-83.43898},
{x:34.57034,y:-83.30405},
{x:33.57405,y:-83.47877},
{x:39.58134,y:-105.1278},
{x:35.17582,y:-106.55839},
{x:33.68081,y:-118.00476},
{x:44.50447,y:-73.13843},
{x:37.89558,y:-122.0573},
{x:33.49525,y:-111.98829},
{x:38.85197,y:-77.04111},
{x:45.65126,y:-122.59189},
{x:39.52781,y:-119.86239},
{x:33.93523,y:-118.12381},
{x:41.26397,y:-73.13272},
{x:28.92778,y:-82.01017},
{x:29.44555,y:-95.08374},
{x:33.94682,y:-118.2292},
{x:35.18451,y:-80.75835},
{x:34.15751,y:-118.26451},
{x:41.01821,y:-76.48972},
{x:40.25983,y:-76.77604},
{x:30.47027,y:-90.91818},
{x:34.15579,y:-118.46706},
{x:34.95704,y:-81.0474},
{x:28.56577,y:-81.18287},
{x:28.5528,y:-81.36055},
{x:47.18538,y:-122.25731},
{x:33.72447,y:-84.762},
{x:33.80311,y:-118.01493},
{x:37.34494,y:-79.23384},
{x:43.63525,y:-70.25561},
{x:30.00655,y:-90.19788},
{x:34.14591,y:-118.1525},
{x:39.2031,y:-121.06346},
{x:33.86305,y:-118.40041},
{x:41.25539,y:-73.21643},
{x:27.95035,y:-82.44853},
{x:33.59852,y:-117.17013},
{x:39.6982,y:-76.05795},
{x:40.81547,y:-75.73139},
{x:41.15439,y:-87.87445},
{x:35.92376,y:-84.03228},
{x:34.85,y:-82.33318},
{x:39.63237,y:-86.12114},
{x:26.11422,y:-80.14328},
{x:33.86079,y:-117.9246},
{x:37.76616,y:-120.84695},
{x:40.07755,y:-75.68802},
{x:37.72951,y:-121.93138},
{x:43.04763,y:-76.23716},
{x:32.86408,y:-97.4265},
{x:34.02732,y:-118.47485},
{x:33.80282,y:-117.93835},
{x:30.03005,y:-97.85006},
{x:42.72506,y:-87.94992},
{x:39.18775,y:-119.77246},
{x:42.76048,y:-84.51517},
{x:42.90682,y:-85.78744},
{x:37.00762,y:-86.37594},
{x:36.77208,y:-119.7909},
{x:36.80793,y:-119.88393},
{x:37.46621,y:-121.14056},
{x:37.81144,y:-121.29862},
{x:40.58706,y:-73.95337},
{x:33.60713,y:-112.06554},
{x:28.94522,y:-98.50455},
{x:41.56437,y:-85.80105},
{x:33.81852,y:-118.38565},
{x:34.19186,y:-118.9381},
{x:33.56728,y:-112.18664},
{x:33.33573,y:-111.60228},
{x:33.63838,y:-112.4231},
{x:35.22628,y:-80.84506},
{x:33.80277,y:-118.04105},
{x:32.6157,y:-117.06955},
{x:41.13909,y:-111.91676},
{x:33.92535,y:-116.90183},
{x:40.57561,y:-73.97747},
{x:35.10967,y:-106.60468},
{x:33.20756,y:-117.28546},
{x:32.52517,y:-86.21029},
{x:40.71341,y:-74.01178},
{x:47.09193,y:-122.58843},
{x:34.25768,y:-118.46981},
{x:38.83621,y:-97.60909},
{x:36.76477,y:-76.20213},
{x:33.35264,y:-84.11928},
{x:21.34178,y:-158.12347},
{x:39.48432,y:-80.16129},
{x:25.83669,y:-80.32243},
{x:33.95215,y:-118.16392},
{x:39.79828,y:-105.48277},
{x:25.83689,y:-80.3221},
{x:25.83693,y:-80.32293},
{x:41.89596,y:-87.6192},
{x:25.77515,y:-80.17769},
{x:41.0048,y:-91.97851},
{x:44.69763,y:-93.21633},
{x:43.63258,y:-95.59601},
{x:33.19419,y:-117.25034},
{x:38.88392,y:-77.02441},
{x:39.12118,y:-76.51157},
{x:33.21853,y:-96.87762},
{x:35.08424,y:-80.6605},
{x:34.18645,y:-118.41353},
{x:38.54091,y:-121.48348},
{x:41.83056,y:-88.05688},
{x:39.78417,y:-104.77717},
{x:33.90125,y:-118.37418},
{x:40.3601,y:-75.98382},
{x:43.95606,y:-86.39272},
{x:42.74047,y:-84.62585},
{x:41.97496,y:-87.74539},
{x:44.72325,y:-85.63946},
{x:41.90775,y:-87.84147},
{x:40.67191,y:-74.10535},
{x:39.38207,y:-77.39966},
{x:33.88947,y:-118.16109},
{x:40.24944,y:-74.68786},
{x:27.9496,y:-81.97151},
{x:40.77,y:-74.06509},
{x:37.521,y:-122.33737},
{x:47.67745,y:-122.19947},
{x:36.86219,y:-76.46807},
{x:35.17888,y:-106.68815},
{x:38.44466,y:-81.978},
{x:37.05718,y:-76.32589},
{x:39.774,y:-86.16283},
{x:36.8755,y:-76.03773},
{x:40.76178,y:-73.99758},
{x:37.2381,y:-121.83093},
{x:35.2214,y:-89.73585},
{x:38.97036,y:-94.77402},
{x:38.43493,y:-122.68856},
{x:34.16922,y:-118.60548},
{x:34.04051,y:-118.05004},
{x:41.77466,y:-87.97503},
{x:35.98611,y:-115.14243},
{x:33.11626,y:-96.65444},
{x:30.47364,y:-97.97634},
{x:45.55783,y:-122.92558},
{x:30.0927,y:-81.52234},
{x:33.59208,y:-101.95569},
{x:33.2179,y:-96.68304},
{x:39.40772,y:-81.41898},
{x:40.683,y:-74.23304},
{x:41.82355,y:-87.80227},
{x:32.2986,y:-80.94278},
{x:38.60862,y:-122.87293},
{x:38.09478,y:-122.22888},
{x:42.42298,y:-122.85148},
{x:37.30055,y:-121.7719},
{x:34.14415,y:-118.70029},
{x:31.97904,y:-81.16312},
{x:33.8731,y:-118.35394},
{x:39.52917,y:-104.87142},
{x:40.75567,y:-73.97689},
{x:42.36263,y:-71.01942},
{x:39.69739,y:-75.65129},
{x:33.61774,y:-117.92939},
{x:34.15747,y:-118.151},
{x:40.0865,y:-75.09368},
{x:39.44844,y:-76.81547},
{x:34.2751,y:-118.56347},
{x:40.2496,y:-111.65824},
{x:34.07065,y:-118.29189},
{x:38.25035,y:-122.62881},
{x:33.26246,y:-111.72008},
{x:35.74539,y:-86.93637},
{x:36.35865,y:-119.65477},
{x:42.62378,y:-70.65609},
{x:33.62399,y:-111.9264},
{x:36.95307,y:-120.0588},
{x:37.91317,y:-121.29398},
{x:33.8726,y:-118.31679},
{x:34.09825,y:-118.36244},
{x:35.35572,y:-119.14501},
{x:34.86376,y:-118.1779},
{x:35.09944,y:-106.51503},
{x:34.29578,y:-118.41806},
{x:32.74848,y:-117.1007},
{x:40.19336,y:-76.98181},
{x:47.53425,y:-122.6085},
{x:42.37413,y:-71.08389},
{x:25.83676,y:-80.3223},
{x:36.49006,y:-86.88002},
{x:33.1387,y:-117.1747},
{x:36.15358,y:-85.5145},
{x:39.48377,y:-86.01663},
{x:38.27904,y:-85.74651},
{x:33.01816,y:-117.07346},
{x:34.19149,y:-118.32892},
{x:41.18826,y:-111.98303},
{x:40.60983,y:-112.0238},
{x:34.07699,y:-118.38354},
{x:40.68326,y:-111.93943},
{x:32.27972,y:-90.03722},
{x:37.6626,y:-122.39639},
{x:64.83727,y:-147.80299},
{x:33.72329,y:-118.07567},
{x:36.3282,y:-119.65421},
{x:33.55751,y:-117.17821},
{x:36.15954,y:-115.31945},
{x:37.73959,y:-121.4513},
{x:36.8378,y:-119.68201},
{x:38.43222,y:-78.84278},
{x:35.49344,y:-97.27837},
{x:39.71085,y:-104.82957},
{x:33.90144,y:-118.36676},
{x:33.05206,y:-117.26142},
{x:34.07122,y:-117.95894},
{x:34.14567,y:-118.14313},
{x:38.01211,y:-122.27414},
{x:41.54945,y:-88.12511},
{x:38.5716,y:-121.48787},
{x:29.92232,y:-90.12177},
{x:40.05863,y:-77.49989},
{x:39.99475,y:-81.57513},
{x:35.92034,y:-81.52431},
{x:33.48258,y:-117.0944},
{x:37.63596,y:-77.54753},
{x:40.76747,y:-73.96464},
{x:33.54841,y:-101.88594},
{x:38.49504,y:-121.52109},
{x:43.04416,y:-76.06763},
{x:40.33376,y:-76.84333},
{x:39.91496,y:-79.75025},
{x:30.28641,y:-81.52841},
{x:37.63872,y:-122.42096},
{x:32.80077,y:-117.00254},
{x:40.62103,y:-111.85644},
{x:35.24471,y:-82.72355},
{x:29.08747,y:-82.25008},
{x:37.52159,y:-120.88044},
{x:35.08495,y:-85.19876},
{x:38.04603,y:-84.49782},
{x:38.55869,y:-121.33459},
{x:43.39137,y:-124.25099},
{x:37.29189,y:-121.8789},
{x:41.46184,y:-81.95627},
{x:28.52352,y:-80.68256},
{x:39.15299,y:-77.06461},
{x:38.63069,y:-75.19539},
{x:38.95783,y:-94.62831},
{x:33.78738,y:-117.81475},
{x:44.31307,y:-96.77863},
{x:33.64996,y:-117.95314},
{x:33.97566,y:-117.59209},
{x:38.87523,y:-104.82604},
{x:34.85995,y:-82.40624},
{x:38.54754,y:-121.39456},
{x:34.85629,y:-82.25171},
{x:41.71983,y:-71.46437},
{x:36.79216,y:-121.66584},
{x:40.24615,y:-82.86818},
{x:37.27208,y:-79.88704},
{x:39.98945,y:-83.00592},
{x:34.0599,y:-118.30833},
{x:32.74229,y:-117.05603},
{x:47.66059,y:-122.36859},
{x:33.31738,y:-111.96822},
{x:18.36326,y:-66.11029},
{x:33.50447,y:-117.65381},
{x:39.63811,y:-86.08199},
{x:35.56145,y:-82.63347},
{x:38.65019,y:-121.16412},
{x:42.60073,y:-73.79318},
{x:37.04445,y:-122.02707},
{x:42.86966,y:-73.93171},
{x:39.29719,y:-84.39178},
{x:48.16774,y:-103.62287},
{x:36.28423,y:-115.31418},
{x:21.28519,y:-157.70678},
{x:20.87923,y:-156.44459},
{x:42.93385,y:-78.8801},
{x:35.5251,y:-97.54468},
{x:42.38913,y:-87.95537},
{x:34.16592,y:-118.2286},
{x:33.34929,y:-111.85862},
{x:28.46134,y:-81.46489},
{x:35.56555,y:-82.62946},
{x:34.72242,y:-92.3385},
{x:42.74819,y:-78.85399},
{x:34.17152,y:-118.95582},
{x:33.13145,y:-117.16504},
{x:33.94563,y:-118.37323},
{x:34.06143,y:-118.30632},
{x:32.8555,y:-116.97154},
{x:37.3259,y:-121.94378},
{x:39.95515,y:-82.79573},
{x:32.93745,y:-117.23033},
{x:42.36572,y:-71.06149},
{x:34.2846,y:-118.44165},
{x:31.87951,y:-106.42243},
{x:42.99608,y:-70.92423},
{x:34.12178,y:-117.57495},
{x:34.05409,y:-118.4403},
{x:41.05223,y:-94.37331},
{x:37.7155,y:-121.70684},
{x:34.91548,y:-120.43553},
{x:37.29919,y:-120.48121},
{x:36.10364,y:-115.17243},
{x:37.33842,y:-121.99486},
{x:36.837,y:-121.38986},
{x:42.32229,y:-88.16566},
{x:35.01851,y:-114.59846},
{x:33.8326,y:-117.97552},
{x:33.8736,y:-117.56634},
{x:33.46221,y:-112.35576},
{x:47.4008,y:-122.22885},
{x:41.53596,y:-83.63597},
{x:39.8298,y:-75.09128},
{x:35.68724,y:-78.5793},
{x:33.90628,y:-117.45945},
{x:28.55555,y:-81.34476},
{x:34.6012,y:-118.14341},
{x:39.63783,y:-106.07782},
{x:41.0324,y:-73.76595},
{x:38.77528,y:-77.0829},
{x:41.66692,y:-70.29493},
{x:25.77506,y:-80.13919},
{x:38.0151,y:-87.57642},
{x:33.7882,y:-116.48146},
{x:37.25262,y:-76.76458},
{x:38.13391,y:-122.22221},
{x:36.95529,y:-76.25268},
{x:43.75458,y:-87.75323},
{x:41.46115,y:-81.99145},
{x:38.86787,y:-77.15037},
{x:35.90074,y:-78.68109},
{x:43.46991,y:-85.98112},
{x:35.74567,y:-78.82214},
{x:32.22044,y:-110.86666},
{x:35.91094,y:-78.93575},
{x:36.01992,y:-78.9478},
{x:25.83667,y:-80.32202},
{x:45.51046,y:-122.77353},
{x:31.73354,y:-98.96673},
{x:33.84163,y:-118.26232},
{x:34.01587,y:-81.03434},
{x:33.9831,y:-81.09925},
{x:33.10568,y:-80.12732},
{x:41.6632,y:-71.49453},
{x:34.23269,y:-79.80408},
{x:35.76878,y:-95.40108},
{x:39.7264,y:-121.80117},
{x:33.72707,y:-117.85101},
{x:44.06306,y:-123.02671},
{x:37.13041,y:-121.63916},
{x:29.64745,y:-95.58253},
{x:42.09012,y:-71.27069},
{x:39.11831,y:-76.63448},
{x:35.11788,y:-101.90785},
{x:35.13596,y:-120.62521},
{x:34.22003,y:-118.60542},
{x:45.19724,y:-93.34713},
{x:37.5592,y:-122.2673},
{x:39.26731,y:-94.44971},
{x:36.01936,y:-95.97678},
{x:35.44731,y:-97.42415},
{x:38.45712,y:-121.84316},
{x:45.41017,y:-122.52419},
{x:42.81417,y:-85.7194},
{x:42.91186,y:-85.70328},
{x:42.95524,y:-85.48472},
{x:37.92743,y:-122.06112},
{x:42.95983,y:-87.96891},
{x:43.1413,y:-87.91344},
{x:42.14102,y:-72.49971},
{x:35.60936,y:-97.53209},
{x:36.1794,y:-115.24263},
{x:39.09065,y:-95.66977},
{x:39.99177,y:-76.28722},
{x:37.49118,y:-121.92982},
{x:39.49429,y:-104.75985},
{x:43.61918,y:-116.32222},
{x:38.48151,y:-121.50317},
{x:32.73306,y:-97.63019},
{x:39.27674,y:-76.61448},
{x:40.0275,y:-82.44616},
{x:37.72842,y:-89.2003},
{x:36.6183,y:-88.31532},
{x:34.45982,y:-118.53405},
{x:34.23434,y:-119.17945},
{x:33.35392,y:-84.12276},
{x:44.30646,y:-71.80228},
{x:37.92687,y:-121.69089},
{x:39.17726,y:-84.64443},
{x:43.08682,y:-88.07054},
{x:39.73752,y:-104.91745},
{x:41.89559,y:-87.6784},
{x:39.66113,y:-104.84455},
{x:38.48133,y:-121.40925},
{x:40.47732,y:-88.95506},
{x:33.87768,y:-117.9635},
{x:35.95712,y:-85.03777},
{x:43.5486,y:-96.72436},
{x:33.14926,y:-117.1982},
{x:42.94931,y:-87.90176},
{x:41.93554,y:-88.76623},
{x:33.51932,y:-117.16412},
{x:33.65024,y:-117.83123},
{x:37.68584,y:-97.3341},
{x:37.89412,y:-85.947},
{x:33.94567,y:-118.40385},
{x:32.72002,y:-117.16761},
{x:26.01968,y:-80.18032},
{x:33.68673,y:-117.85574},
{x:34.46548,y:-84.45492},
{x:37.52973,y:-122.03827},
{x:32.94434,y:-96.69898},
{x:40.74039,y:-74.00504},
{x:32.77673,y:-117.01601},
{x:34.60963,y:-120.18841},
{x:39.66781,y:-75.77558},
{x:36.86052,y:-76.39595},
{x:34.03419,y:-117.62818},
{x:33.95648,y:-117.55786},
{x:38.87718,y:-121.29544},
{x:33.85878,y:-118.09056},
{x:34.08949,y:-117.73727},
{x:41.18098,y:-96.10661},
{x:43.61094,y:-72.97302},
{x:33.33327,y:-86.78635},
{x:35.85722,y:-78.84226},
{x:34.18757,y:-118.55372},
{x:33.86478,y:-117.54511},
{x:33.80979,y:-118.29002},
{x:47.23514,y:-122.25002},
{x:33.7821,y:-118.07157},
{x:35.14591,y:-119.46543},
{x:36.25491,y:-120.25218},
{x:40.71964,y:-74.09293},
{x:33.98005,y:-81.30427},
{x:37.70052,y:-121.06065},
{x:39.80791,y:-104.78117},
{x:36.0123,y:-114.96389},
{x:33.34827,y:-111.75493},
{x:33.96599,y:-117.03645},
{x:37.90132,y:-122.2997},
{x:34.07554,y:-118.37817},
{x:39.9109,y:-77.64993},
{x:33.70395,y:-117.93411},
{x:33.43972,y:-84.12355},
{x:37.66054,y:-122.47745},
{x:27.98105,y:-82.34128},
{x:34.00362,y:-118.39329},
{x:42.03264,y:-91.58213},
{x:47.98261,y:-122.20036},
{x:36.15551,y:-86.77556},
{x:33.02119,y:-96.76634},
{x:38.27594,y:-122.6681},
{x:32.99349,y:-96.74832},
{x:29.88896,y:-97.94303},
{x:41.87474,y:-87.65008},
{x:25.83637,y:-80.32229},
{x:30.22379,y:-81.67576},
{x:27.97313,y:-82.82806},
{x:35.9045,y:-78.60099},
{x:39.40564,y:-76.79107},
{x:32.31367,y:-90.87591},
{x:35.78257,y:-80.25591},
{x:35.24406,y:-79.02311},
{x:34.43789,y:-79.37117},
{x:37.46163,y:-122.13728},
{x:36.61604,y:-121.90052},
{x:33.77169,y:-84.36367},
{x:44.52108,y:-89.55036},
{x:47.3639,y:-122.01909},
{x:38.81598,y:-94.51616},
{x:33.76939,y:-111.92504},
{x:40.50733,y:-88.9577},
{x:41.80392,y:-87.89134},
{x:26.43698,y:-80.08547},
{x:43.31924,y:-87.92201},
{x:41.16186,y:-104.80043},
{x:41.46749,y:-75.63909},
{x:43.87346,y:-91.19403},
{x:44.49062,y:-88.07178},
{x:38.30287,y:-104.60607},
{x:39.85071,y:-74.98394},
{x:33.44728,y:-112.07218},
{x:39.10669,y:-84.51252},
{x:40.91862,y:-111.88688},
{x:38.24676,y:-122.06898},
{x:37.36859,y:-122.03563},
{x:34.03286,y:-117.68994},
{x:26.11716,y:-80.25378},
{x:27.8949,y:-82.50543},
{x:30.01103,y:-95.26958},
{x:33.74768,y:-116.99614},
{x:35.10745,y:-106.57433},
{x:33.68163,y:-117.91899},
{x:37.67081,y:-120.99436},
{x:42.84693,y:-106.26436},
{x:37.67476,y:-122.47015},
{x:35.48681,y:-120.66379},
{x:35.19564,y:-111.62807},
{x:38.3239,y:-122.28695},
{x:36.2998,y:-115.28054},
{x:33.98889,y:-118.30987},
{x:34.15812,y:-118.4243},
{x:37.30472,y:-120.49275},
{x:36.60527,y:-119.54509},
{x:40.81028,y:-73.9527},
{x:32.07558,y:-96.4469},
{x:42.33377,y:-72.63526},
{x:30.11996,y:-93.74734},
{x:32.7909,y:-117.10032},
{x:36.01097,y:-115.13618},
{x:41.60181,y:-93.65687},
{x:38.41458,y:-96.1783},
{x:38.23001,y:-85.63987},
{x:36.2543,y:-94.13647},
{x:36.11058,y:-115.14124},
{x:30.54506,y:-92.8139},
{x:41.17872,y:-73.75457},
{x:39.71506,y:-86.29702},
{x:33.53857,y:-112.25636},
{x:33.39125,y:-111.66799},
{x:35.093,y:-80.93514},
{x:33.68805,y:-112.03194},
{x:32.65937,y:-96.80268},
{x:18.42126,y:-66.26836},
{x:36.58342,y:-121.897},
{x:32.96267,y:-96.3447},
{x:30.15142,y:-95.21884},
{x:30.06709,y:-95.24874},
{x:42.99857,y:-84.12115},
{x:42.55311,y:-82.96119},
{x:40.204,y:-83.02677},
{x:42.63469,y:-83.74407},
{x:36.31477,y:-115.30633},
{x:28.50171,y:-81.71865},
{x:32.79583,y:-97.34986},
{x:33.84539,y:-116.52859},
{x:33.80774,y:-84.39398},
{x:29.75858,y:-95.36343},
{x:37.28012,y:-83.21748},
{x:33.71259,y:-116.29133},
{x:36.82682,y:-76.16168},
{x:29.46137,y:-98.47372},
{x:32.68645,y:-117.18007},
{x:37.36921,y:-121.92923},
{x:32.22923,y:-110.99964},
{x:32.56027,y:-117.06285},
{x:32.71659,y:-117.17227},
{x:36.14259,y:-115.15631},
{x:38.85889,y:-77.10256},
{x:34.17041,y:-118.53283},
{x:40.64573,y:-75.49437},
{x:32.83519,y:-96.6736},
{x:40.7216,y:-73.84358},
{x:45.53361,y:-122.63515},
{x:42.577,y:-70.88839},
{x:32.83306,y:-116.75033},
{x:38.94128,y:-104.67488},
{x:39.62373,y:-104.98741},
{x:38.32313,y:-122.30192},
{x:40.71269,y:-75.21874},
{x:42.08751,y:-75.96576},
{x:40.72024,y:-74.00098},
{x:37.9279,y:-122.51387},
{x:35.20232,y:-89.78865},
{x:33.98643,y:-118.39352},
{x:40.7369,y:-73.61214},
{x:33.93502,y:-118.11955},
{x:42.08124,y:-71.38642},
{x:32.78019,y:-96.79673},
{x:33.72369,y:-116.39612},
{x:42.48119,y:-71.21289},
{x:39.21907,y:-78.13547},
{x:44.23085,y:-123.20458},
{x:45.69419,y:-122.55309},
{x:42.32487,y:-122.89953},
{x:33.52443,y:-117.15598},
{x:35.97439,y:-78.89844},
{x:38.00795,y:-122.54743},
{x:40.15871,y:-89.41595},
{x:39.95164,y:-75.15616},
{x:34.90395,y:-82.34884},
{x:35.02097,y:-81.963},
{x:35.02699,y:-81.89718},
{x:41.15733,y:-80.66542},
{x:39.39694,y:-84.54839},
{x:36.9725,y:-120.04421},
{x:45.4258,y:-122.48504},
{x:47.61056,y:-122.33562},
{x:35.03969,y:-79.00019},
{x:34.36168,y:-80.06344},
{x:39.21516,y:-76.93313},
{x:33.45683,y:-112.39176},
{x:32.69885,y:-114.62443},
{x:33.3778,y:-111.97468},
{x:32.9022,y:-97.54447},
{x:33.42393,y:-111.75192},
{x:36.4215,y:-94.22366},
{x:36.69509,y:-79.8742},
{x:33.69416,y:-117.33588},
{x:39.57941,y:-82.95224},
{x:44.90153,y:-89.60853},
{x:35.08563,y:-106.68683},
{x:27.49262,y:-97.84492},
{x:30.75973,y:-98.22946},
{x:38.41806,y:-77.41808},
{x:39.48704,y:-75.05146},
{x:39.90101,y:-74.84625},
{x:44.24429,y:-88.35022},
{x:44.03288,y:-88.57823},
{x:43.38459,y:-87.92344},
{x:38.87315,y:-78.53157},
{x:44.79166,y:-93.20593},
{x:38.5947,y:-90.54716},
{x:39.09989,y:-119.77825},
{x:45.50762,y:-122.95709},
{x:42.05234,y:-87.83968},
{x:42.22867,y:-88.30561},
{x:44.90645,y:-89.59163},
{x:38.66202,y:-121.34913},
{x:40.13548,y:-74.96248},
{x:34.03703,y:-118.14473},
{x:33.50314,y:-101.90295},
{x:43.1346,y:-115.69683},
{x:33.13183,y:-117.21101},
{x:39.69098,y:-104.86347},
{x:40.85171,y:-115.743},
{x:38.91641,y:-76.9817},
{x:34.58804,y:-112.34165},
{x:33.3793,y:-111.90776},
{x:33.90132,y:-118.35107},
{x:35.68028,y:-82.01157},
{x:34.18251,y:-118.31071},
{x:42.32742,y:-83.86991},
{x:33.68336,y:-116.18107},
{x:34.06837,y:-117.65141},
{x:33.11444,y:-96.82864},
{x:40.62386,y:-74.13907},
{x:37.50651,y:-122.26098},
{x:41.98679,y:-91.65408},
{x:39.2776,y:-76.56274},
{x:34.46227,y:-118.55662},
{x:33.33882,y:-111.60508},
{x:42.24478,y:-71.00477},
{x:34.42883,y:-119.86796},
{x:40.08024,y:-82.84698},
{x:32.42929,y:-104.22826},
{x:42.08954,y:-70.70763},
{x:32.84347,y:-96.46917},
{x:46.60391,y:-120.49788},
{x:38.84044,y:-104.74764},
{x:38.2386,y:-78.36752},
{x:42.46557,y:-96.42911},
{x:38.05865,y:-78.49494},
{x:34.04722,y:-118.52601},
{x:40.83023,y:-96.6674},
{x:40.28689,y:-76.65683},
{x:34.36982,y:-89.51621},
{x:40.78432,y:-111.98534},
{x:35.07476,y:-106.62891},
{x:40.78538,y:-111.99023},
{x:39.89933,y:-84.18414},
{x:41.36388,y:-83.1331},
{x:39.71784,y:-86.29658},
{x:40.78511,y:-111.98474},
{x:45.08247,y:-93.51804},
{x:37.68419,y:-82.78195},
{x:42.52341,y:-82.98711},
{x:28.46148,y:-81.46309},
{x:39.95651,y:-82.8222},
{x:42.59223,y:-83.08694},
{x:36.01701,y:-83.42596},
{x:35.73646,y:-83.99369},
{x:34.50178,y:-84.91849},
{x:37.1207,y:-80.53171},
{x:35.79135,y:-78.66192},
{x:32.82483,y:-80.08162},
{x:41.42421,y:-81.7599},
{x:42.02487,y:-88.31223},
{x:30.27816,y:-95.46114},
{x:33.39053,y:-112.13429},
{x:28.03127,y:-82.39315},
{x:29.70984,y:-98.16221},
{x:41.7559,y:-88.14203},
{x:36.11935,y:-95.85648},
{x:43.37673,y:-124.21436},
{x:47.70434,y:-116.80758},
{x:34.12235,y:-118.10579},
{x:32.76726,y:-97.21476},
{x:38.86545,y:-77.27111},
{x:41.55513,y:-90.54892},
{x:39.24532,y:-94.64542},
{x:39.08247,y:-94.58207},
{x:40.20685,y:-85.40853},
{x:42.00503,y:-83.96856},
{x:37.78406,y:-122.40398},
{x:45.01062,y:-122.78419},
{x:34.7202,y:-86.58403},
{x:36.10648,y:-115.17884},
{x:40.52197,y:-111.9456},
{x:40.22047,y:-111.65832},
{x:40.03537,y:-105.05458},
{x:40.52895,y:-105.07639},
{x:36.10447,y:-115.1654},
{x:36.08854,y:-115.17715},
{x:33.84628,y:-118.16713},
{x:28.26736,y:-80.73762},
{x:34.00018,y:-117.5766},
{x:44.5916,y:-88.07243},
{x:41.60054,y:-87.60618},
{x:44.92314,y:-123.01224},
{x:42.34186,y:-122.85943},
{x:27.9885,y:-82.12111},
{x:39.80525,y:-104.93214},
{x:32.6264,y:-117.07585},
{x:37.04461,y:-76.38329},
{x:31.04434,y:-93.2182},
{x:38.59586,y:-121.3641},
{x:41.33022,y:-73.08414},
{x:35.05884,y:-106.63474},
{x:40.45641,y:-88.95625},
{x:37.93581,y:-121.2991},
{x:38.99337,y:-77.02438},
{x:41.81684,y:-87.72411},
{x:32.3562,y:-86.13089},
{x:32.87761,y:-105.96098},
{x:40.06112,y:-76.33752},
{x:34.7558,y:-92.26328},
{x:40.70666,y:-89.59445},
{x:40.1882,y:-87.62932},
{x:39.35919,y:-85.47889},
{x:41.37397,y:-88.83536},
{x:39.75886,y:-89.60847},
{x:39.10286,y:-84.488},
{x:43.04124,y:-87.91479},
{x:41.07987,y:-81.50291},
{x:38.97221,y:-94.74136},
{x:36.30161,y:-82.36664},
{x:36.61547,y:-88.32085},
{x:35.00095,y:-82.00534},
{x:39.24598,y:-94.44726},
{x:35.98346,y:-84.0954},
{x:37.56289,y:-122.324},
{x:40.2458,y:-75.34088},
{x:35.30208,y:-78.60234},
{x:44.72481,y:-93.17763},
{x:41.29783,y:-96.02525},
{x:39.97337,y:-82.89776},
{x:40.73727,y:-73.24747},
{x:41.29193,y:-73.82438},
{x:42.15926,y:-76.85341},
{x:39.27118,y:-94.90027},
{x:40.4362,y:-104.98426},
{x:41.26273,y:-96.04808},
{x:41.21155,y:-96.02427},
{x:37.35179,y:-121.96775},
{x:40.75775,y:-73.83468},
{x:35.22864,y:-81.33261},
{x:35.4279,y:-119.0217},
{x:33.49922,y:-111.91784},
{x:41.72994,y:-71.16603},
{x:40.53741,y:-112.01946},
{x:43.51444,y:-96.74323},
{x:38.88416,y:-94.66965},
{x:43.19048,y:-77.50192},
{x:35.8779,y:-94.97523},
{x:35.49297,y:-97.51525},
{x:36.73737,y:-97.06792},
{x:46.22743,y:-119.22431},
{x:38.02287,y:-97.33759},
{x:34.62929,y:-98.49273},
{x:39.01477,y:-94.74425},
{x:38.71022,y:-120.84348},
{x:28.75449,y:-81.34832},
{x:42.48267,y:-83.27252},
{x:42.44614,y:-83.3233},
{x:39.73645,y:-75.62398},
{x:44.06209,y:-121.30295},
{x:43.17764,y:-85.28126},
{x:42.45011,y:-85.65902},
{x:41.93758,y:-85.65077},
{x:37.82892,y:-122.28238},
{x:42.0329,y:-86.51456},
{x:33.90912,y:-81.2274},
{x:39.94043,y:-82.99679},
{x:40.59389,y:-74.00124},
{x:37.2486,y:-76.68617},
{x:33.8484,y:-84.3623},
{x:32.82479,y:-80.08576},
{x:41.782,y:-70.74488},
{x:39.17192,y:-77.20428},
{x:37.59247,y:-77.49354},
{x:39.85971,y:-74.20539},
{x:37.52508,y:-120.85658},
{x:44.0671,y:-93.22843},
{x:33.98598,y:-118.44308},
{x:40.68514,y:-73.97595},
{x:36.05327,y:-119.02676},
{x:25.79094,y:-80.18662},
{x:33.67906,y:-78.96573},
{x:35.01055,y:-78.82936},
{x:33.96619,y:-84.00901},
{x:31.79599,y:-106.23202},
{x:34.09356,y:-118.37801},
{x:42.73426,y:-84.54301},
{x:42.01184,y:-87.68972},
{x:28.4449,y:-81.45778},
{x:37.79697,y:-122.39813},
{x:39.21772,y:-81.54629},
{x:38.49531,y:-121.09739},
{x:35.20421,y:-79.48408},
{x:36.34599,y:-86.52496},
{x:41.60592,y:-72.7081},
{x:38.87397,y:-75.82458},
{x:41.46007,y:-81.69162},
{x:36.9435,y:-86.42545},
{x:39.67411,y:-84.21966},
{x:31.29347,y:-92.47713},
{x:43.46731,y:-111.98229},
{x:36.19636,y:-119.31504},
{x:45.54351,y:-122.38987},
{x:35.09905,y:-106.70725},
{x:45.75018,y:-122.87781},
{x:38.8628,y:-121.95229},
{x:39.09618,y:-84.61265},
{x:35.79482,y:-83.5578},
{x:37.65806,y:-121.03167},
{x:37.27458,y:-78.40149},
{x:33.46505,y:-117.68834},
{x:34.79026,y:-96.66944},
{x:38.76865,y:-77.48544},
{x:34.86102,y:-82.38642},
{x:36.38084,y:-81.48861},
{x:33.72385,y:-85.02761},
{x:33.72076,y:-85.14548},
{x:37.39082,y:-122.09472},
{x:33.04392,y:-84.17027},
{x:47.31569,y:-122.22583},
{x:35.22626,y:-79.3955},
{x:36.03276,y:-79.96851},
{x:32.79779,y:-117.23932},
{x:34.01269,y:-117.9238},
{x:40.76763,y:-73.98134},
{x:40.14352,y:-82.92547},
{x:41.83123,y:-87.74408},
{x:41.34802,y:-81.82318},
{x:39.89724,y:-82.88171},
{x:41.35352,y:-81.38703},
{x:40.08172,y:-82.84918},
{x:39.91685,y:-105.05552},
{x:41.05275,y:-83.6126},
{x:36.97557,y:-120.07454},
{x:34.14965,y:-118.25819},
{x:30.06728,y:-99.11516},
{x:28.19434,y:-82.50651},
{x:25.70172,y:-80.38177},
{x:48.18112,y:-122.13049},
{x:43.06833,y:-77.60824},
{x:33.45655,y:-112.35757},
{x:33.36394,y:-111.82396},
{x:38.56814,y:-121.46706},
{x:46.36014,y:-94.18602},
{x:30.27229,y:-90.98465},
{x:39.92826,y:-104.90726},
{x:40.38711,y:-111.92015},
{x:34.05252,y:-83.99367},
{x:25.92697,y:-80.14091},
{x:32.76089,y:-117.06487},
{x:41.25718,y:-75.89202},
{x:42.94494,y:-76.54999},
{x:44.50687,y:-73.17995},
{x:47.66316,y:-122.28273},
{x:44.68353,y:-90.16112},
{x:39.86637,y:-84.13744},
{x:40.73048,y:-84.06673},
{x:42.4661,y:-88.06211},
{x:45.16493,y:-93.16386},
{x:44.85817,y:-93.14291},
{x:38.77985,y:-77.19011},
{x:25.68541,y:-80.31495},
{x:39.01247,y:-95.76408},
{x:30.71488,y:-95.54844},
{x:38.81252,y:-94.91358},
{x:36.67786,y:-80.89576},
{x:33.58265,y:-112.01279},
{x:33.22009,y:-111.63392},
{x:39.69537,y:-75.5571},
{x:35.27985,y:-120.66172},
{x:39.02919,y:-94.63348},
{x:28.98367,y:-95.95091},
{x:48.39333,y:-114.33537},
{x:32.72781,y:-97.41519},
{x:38.92871,y:-94.41329},
{x:38.54369,y:-121.33637},
{x:33.94575,y:-118.36162},
{x:30.39482,y:-97.75258},
{x:34.11692,y:-84.22363},
{x:42.73151,y:-71.42598},
{x:39.743,y:-104.98708},
{x:37.02541,y:-76.33362},
{x:41.98519,y:-88.71202},
{x:20.92034,y:-156.69537},
{x:42.298,y:-83.2276},
{x:29.5741,y:-98.59515},
{x:37.06385,y:-84.62178},
{x:29.47405,y:-98.61388},
{x:34.22275,y:-119.06852},
{x:33.37708,y:-112.18825},
{x:31.20295,y:-82.33359},
{x:32.72744,y:-97.36159},
{x:39.67992,y:-104.95888},
{x:32.80041,y:-96.80111},
{x:33.57019,y:-117.18299},
{x:28.54709,y:-81.38445},
{x:34.0862,y:-81.18229},
{x:33.54819,y:-80.83015},
{x:33.48515,y:-80.46921},
{x:34.72406,y:-82.29369},
{x:29.52412,y:-98.50527},
{x:32.62441,y:-96.90767},
{x:32.89132,y:-96.97225},
{x:32.83613,y:-97.00506},
{x:32.54514,y:-94.34977},
{x:32.34594,y:-95.26877},
{x:32.49944,y:-94.75051},
{x:30.28378,y:-90.9555},
{x:30.38973,y:-88.99114},
{x:47.67454,y:-117.39695},
{x:29.61065,y:-90.75722},
{x:33.64908,y:-117.83081},
{x:29.50606,y:-98.45866},
{x:38.85513,y:-77.39335},
{x:34.04393,y:-118.37921},
{x:29.74125,y:-95.40965},
{x:40.8242,y:-74.13527},
{x:47.61238,y:-122.18416},
{x:40.30631,y:-74.67694},
{x:26.51365,y:-80.05862},
{x:40.71104,y:-74.01538},
{x:32.03749,y:-102.08699},
{x:39.28706,y:-76.5483},
{x:35.26524,y:-80.88178},
{x:35.27266,y:-81.52386},
{x:30.30463,y:-97.76444},
{x:32.83288,y:-96.70268},
{x:32.8506,y:-96.82022},
{x:36.35059,y:-119.4255},
{x:38.74293,y:-121.25163},
{x:32.95346,y:-96.82447},
{x:40.26827,y:-74.47633},
{x:30.59755,y:-88.16087},
{x:47.01739,y:-122.89821},
{x:32.81118,y:-96.62316},
{x:29.77682,y:-95.75021},
{x:32.83691,y:-96.7546},
{x:29.77359,y:-95.5621},
{x:30.2924,y:-97.8251},
{x:34.08342,y:-118.35614},
{x:29.68885,y:-95.46205},
{x:29.74214,y:-95.39064},
{x:43.03122,y:-89.40957},
{x:37.63985,y:-121.00084},
{x:29.47685,y:-98.46305},
{x:39.50224,y:-121.53882},
{x:37.81117,y:-122.24725},
{x:36.83632,y:-119.78972},
{x:32.94967,y:-96.76935},
{x:33.7565,y:-111.99132},
{x:38.3004,y:-80.83418},
{x:29.58159,y:-95.58129},
{x:41.45856,y:-88.27213},
{x:29.7602,y:-95.48236},
{x:32.91145,y:-96.80423},
{x:32.86862,y:-96.7732},
{x:37.80443,y:-122.27391},
{x:33.04414,y:-96.82791},
{x:25.755,y:-80.25913},
{x:30.40192,y:-97.7469},
{x:40.15626,y:-75.86086},
{x:32.99922,y:-96.91032},
{x:35.5779,y:-84.27356},
{x:33.53751,y:-111.90627},
{x:33.9656,y:-118.22487},
{x:35.86338,y:-83.54971},
{x:39.28386,y:-75.59453},
{x:35.38288,y:-119.05112},
{x:36.89627,y:-119.74457},
{x:32.96259,y:-96.79024},
{x:33.87527,y:-118.15162},
{x:27.72309,y:-82.74031},
{x:35.72387,y:-81.6558},
{x:32.80693,y:-96.81058},
{x:40.61922,y:-74.25198},
{x:40.7521,y:-73.99831},
{x:40.52867,y:-74.85619},
{x:40.79471,y:-74.25437},
{x:41.06528,y:-74.74657},
{x:40.77416,y:-74.03784},
{x:40.7878,y:-73.81428},
{x:38.19077,y:-83.47623},
{x:27.76777,y:-82.39347},
{x:33.13616,y:-117.18149},
{x:40.74143,y:-73.63996},
{x:33.65622,y:-111.93005},
{x:38.6298,y:-90.21675},
{x:42.45942,y:-83.93148},
{x:33.16728,y:-96.66663},
{x:37.2485,y:-93.31115},
{x:32.89594,y:-96.46952},
{x:40.53871,y:-79.83197},
{x:25.47797,y:-80.4344},
{x:28.39771,y:-80.70449},
{x:37.42833,y:-121.92099},
{x:32.70664,y:-97.38965},
{x:36.00806,y:-78.92204},
{x:35.323,y:-106.56152},
{x:45.11628,y:-93.23911},
{x:44.33692,y:-93.95084},
{x:41.86461,y:-87.64705},
{x:30.69281,y:-88.04123},
{x:47.65437,y:-122.31179},
{x:21.34906,y:-157.93072},
{x:25.77537,y:-80.17817},
{x:30.0043,y:-95.29785},
{x:32.81387,y:-96.75241},
{x:29.76038,y:-95.36396},
{x:38.75619,y:-90.43105},
{x:41.78102,y:-88.31645},
{x:30.47687,y:-97.80509},
{x:44.88492,y:-93.08097},
{x:43.04988,y:-71.43635},
{x:33.85371,y:-98.4994},
{x:34.4929,y:-93.08248},
{x:38.62696,y:-121.26362},
{x:29.49712,y:-98.48014},
{x:47.6447,y:-122.13773},
{x:42.05077,y:-93.6214},
{x:32.78147,y:-96.80167},
{x:42.29759,y:-84.02595},
{x:30.0518,y:-95.18078},
{x:42.59703,y:-82.90076},
{x:33.15157,y:-96.7687},
{x:40.56735,y:-75.33934},
{x:39.36469,y:-76.7132},
{x:35.22209,y:-89.90865},
{x:36.76559,y:-76.01391},
{x:39.75927,y:-86.5111},
{x:41.26501,y:-95.94343},
{x:39.35874,y:-74.41987},
{x:47.57769,y:-122.16641},
{x:30.12725,y:-95.45528},
{x:31.10363,y:-87.48086},
{x:29.74764,y:-95.45486},
{x:35.98643,y:-84.04731},
{x:39.74922,y:-77.56807},
{x:41.38405,y:-88.42291},
{x:47.51817,y:-111.31017},
{x:29.75999,y:-95.36558},
{x:39.99141,y:-76.01783},
{x:35.4067,y:-118.86168},
{x:33.48643,y:-101.9045},
{x:33.51938,y:-101.9898},
{x:30.35137,y:-97.39286},
{x:30.28159,y:-86.01962},
{x:39.6432,y:-106.3818},
{x:33.72886,y:-116.19973},
{x:38.65424,y:-121.07172},
{x:43.29008,y:-91.79547},
{x:40.88057,y:-73.88346},
{x:27.96419,y:-82.51331},
{x:37.35254,y:-79.17564},
{x:21.42262,y:-157.74815},
{x:36.84801,y:-76.02227},
{x:32.33364,y:-106.783},
{x:30.28794,y:-97.74259},
{x:34.15325,y:-118.45661},
{x:38.88354,y:-76.98655},
{x:41.85557,y:-71.44781},
{x:44.12368,y:-70.22709},
{x:31.78344,y:-106.24471},
{x:29.94177,y:-90.1297},
{x:41.97762,y:-87.66042},
{x:39.42515,y:-119.73833},
{x:41.67309,y:-70.09321},
{x:38.01401,y:-122.27198},
{x:35.12571,y:-117.95748},
{x:41.70259,y:-93.61657},
{x:40.75143,y:-73.99458},
{x:38.00208,y:-84.57423},
{x:29.15917,y:-82.0883},
{x:36.69287,y:-119.7615},
{x:40.35106,y:-74.30692},
{x:46.26315,y:-114.15881},
{x:43.0687,y:-89.40035},
{x:37.52947,y:-77.35635},
{x:33.88385,y:-84.24752},
{x:33.37789,y:-111.72068},
{x:36.13223,y:-86.67002},
{x:36.77254,y:-76.08923},
{x:37.76416,y:-122.466},
{x:30.0399,y:-95.48855},
{x:41.95258,y:-70.6855},
{x:34.06812,y:-81.14124},
{x:33.98025,y:-78.37675},
{x:29.02843,y:-80.89962},
{x:40.93515,y:-73.90117},
{x:32.86057,y:-96.76831},
{x:36.03903,y:-94.25392},
{x:43.36642,y:-73.69904},
{x:32.08072,y:-81.28112},
{x:29.70726,y:-95.46821},
{x:33.07126,y:-96.77383},
{x:38.97072,y:-92.32607},
{x:38.79929,y:-90.73486},
{x:40.40292,y:-82.44186},
{x:41.51959,y:-81.46875},
{x:32.99453,y:-97.06552},
{x:30.38746,y:-95.68082},
{x:36.15884,y:-115.20536},
{x:29.72029,y:-95.41831},
{x:32.93321,y:-96.81889},
{x:34.10184,y:-118.34259},
{x:40.6461,y:-111.94014},
{x:37.77392,y:-100.03314},
{x:38.90954,y:-78.1845},
{x:42.62578,y:-71.36127},
{x:30.00108,y:-90.13061},
{x:40.63998,y:-73.92595},
{x:33.49864,y:-117.65934},
{x:41.975,y:-87.74418},
{x:28.39596,y:-81.48534},
{x:38.81197,y:-94.48495},
{x:35.40673,y:-99.38081},
{x:34.89856,y:-95.75486},
{x:37.97685,y:-100.84573},
{x:38.85214,y:-94.37874},
{x:29.96409,y:-90.18684},
{x:32.97783,y:-96.76525},
{x:34.09216,y:-84.33916},
{x:34.00168,y:-80.99779},
{x:29.42426,y:-98.48678},
{x:43.4909,y:-96.76769},
{x:36.28185,y:-115.13616},
{x:36.24013,y:-115.12805},
{x:36.18379,y:-115.30868},
{x:36.13031,y:-115.20586},
{x:36.0703,y:-115.20713},
{x:32.89194,y:-96.96047},
{x:37.1382,y:-93.31635},
{x:29.66523,y:-95.15638},
{x:29.76985,y:-95.60716},
{x:37.33681,y:-122.03639},
{x:39.67709,y:-86.3705},
{x:39.1461,y:-86.57724},
{x:39.21369,y:-86.59008},
{x:40.11434,y:-85.56666},
{x:38.69513,y:-121.27129},
{x:42.7773,y:-71.2288},
{x:40.54393,y:-111.93681},
{x:41.43907,y:-97.37108},
{x:40.61653,y:-98.3821},
{x:33.07118,y:-97.05896},
{x:42.13307,y:-72.62548},
{x:27.38571,y:-82.39742},
{x:32.94239,y:-97.13034},
{x:40.83371,y:-73.36332},
{x:40.66781,y:-73.52975},
{x:29.92624,y:-90.08272},
{x:32.67302,y:-97.00546},
{x:32.99879,y:-96.79437},
{x:33.96867,y:-118.04828},
{x:30.39322,y:-90.08509},
{x:44.89966,y:-91.93225},
{x:30.26978,y:-97.75391},
{x:35.14404,y:-85.24932},
{x:29.75322,y:-95.41033},
{x:33.10148,y:-96.68347},
{x:33.94819,y:-83.75341},
{x:41.12376,y:-75.37222},
{x:29.83114,y:-95.56478},
{x:29.73894,y:-95.46405},
{x:35.56325,y:-82.54165},
{x:37.31601,y:-79.95716},
{x:33.05836,y:-96.77114},
{x:39.72539,y:-104.98646},
{x:31.33646,y:-85.71245},
{x:37.41698,y:-121.95503},
{x:33.95995,y:-118.34517},
{x:30.80284,y:-88.11058},
{x:35.93304,y:-86.87782},
{x:35.38894,y:-97.42202},
{x:38.62785,y:-90.28722},
{x:40.06636,y:-83.02032},
{x:39.24607,y:-94.66013},
{x:36.11556,y:-97.09064},
{x:42.74394,y:-105.36254},
{x:41.68347,y:-88.38327},
{x:35.84895,y:-101.97516},
{x:41.57854,y:-87.79194},
{x:32.99792,y:-96.72979},
{x:30.31269,y:-97.73841},
{x:32.80834,y:-96.79808},
{x:38.23641,y:-86.12903},
{x:30.3598,y:-97.73793},
{x:35.14889,y:-89.90292},
{x:33.41605,y:-111.92826},
{x:30.42037,y:-91.14035},
{x:38.75767,y:-93.73982},
{x:39.86049,y:-75.35611},
{x:36.88366,y:-76.3015},
{x:37.54035,y:-77.43035},
{x:39.81321,y:-105.13731},
{x:34.80634,y:-106.70381},
{x:33.02848,y:-96.82769},
{x:33.04137,y:-96.71654},
{x:32.97117,y:-96.99266},
{x:32.82421,y:-97.20019},
{x:29.70548,y:-95.44039},
{x:39.54393,y:-104.94218},
{x:43.83956,y:-70.44258},
{x:32.96492,y:-97.41703},
{x:41.9831,y:-87.80738},
{x:37.79885,y:-121.92193},
{x:29.66347,y:-98.63165},
{x:35.3269,y:-80.94477},
{x:32.24355,y:-80.97993},
{x:28.43553,y:-82.54802},
{x:30.3369,y:-97.75687},
{x:33.22646,y:-96.79707},
{x:27.2628,y:-80.43191},
{x:39.75491,y:-105.0091},
{x:35.33969,y:-81.90007},
{x:28.34412,y:-81.48439},
{x:29.72817,y:-95.42762},
{x:37.70016,y:-121.77067},
{x:43.69686,y:-98.01746},
{x:41.38468,y:-81.97709},
{x:29.5374,y:-95.15226},
{x:38.8596,y:-84.61966},
{x:37.736,y:-122.2391},
{x:31.18396,y:-81.379},
{x:32.93389,y:-97.23083},
{x:35.5273,y:-98.69435},
{x:45.52,y:-123.08708},
{x:35.46393,y:-97.65167},
{x:41.88463,y:-87.62603},
{x:33.02358,y:-96.79573},
{x:34.15586,y:-118.75656},
{x:30.16899,y:-95.45743},
{x:38.6247,y:-90.19356},
{x:36.97594,y:-82.59226},
{x:24.70903,y:-81.10959},
{x:27.9518,y:-82.45973},
{x:30.16772,y:-95.50399},
{x:38.93811,y:-104.7607},
{x:42.98191,y:-78.87199},
{x:32.48012,y:-84.94311},
{x:35.60814,y:-97.56787},
{x:35.43591,y:-97.56821},
{x:33.09916,y:-96.81193},
{x:30.52801,y:-97.68976},
{x:34.01372,y:-81.01057},
{x:27.98113,y:-82.70968},
{x:26.69007,y:-80.05473},
{x:30.25224,y:-97.7636},
{x:34.35355,y:-82.91225},
{x:41.53665,y:-90.61232},
{x:41.56143,y:-90.60902},
{x:39.91255,y:-91.39323},
{x:33.77336,y:-118.37632},
{x:41.62004,y:-80.16785},
{x:32.81415,y:-96.8087},
{x:31.86082,y:-102.34801},
{x:33.49779,y:-117.66206},
{x:29.74119,y:-95.46063},
{x:41.8597,y:-88.10306},
{x:42.19872,y:-88.06103},
{x:47.79001,y:-122.21582},
{x:34.16948,y:-118.60373},
{x:32.85036,y:-97.08075},
{x:42.11982,y:-88.03186},
{x:41.50875,y:-87.8475},
{x:41.92063,y:-87.94043},
{x:42.04524,y:-88.14325},
{x:34.14024,y:-118.3681},
{x:32.99756,y:-96.85759},
{x:41.82422,y:-87.61562},
{x:41.96546,y:-87.81281},
{x:36.40412,y:-105.5755},
{x:33.30401,y:-111.70015},
{x:39.82051,y:-88.88184},
{x:39.4221,y:-84.45799},
{x:35.74125,y:-86.89207},
{x:30.48277,y:-97.65636},
{x:45.8152,y:-122.70418},
{x:30.34009,y:-97.96992},
{x:34.7559,y:-82.2677},
{x:32.88978,y:-97.25892},
{x:39.63941,y:-79.95578},
{x:33.10824,y:-96.80675},
{x:32.83709,y:-96.77645},
{x:30.2781,y:-97.74434},
{x:44.85107,y:-123.21757},
{x:33.36444,y:-86.99801},
{x:35.87771,y:-97.39634},
{x:36.16151,y:-84.0884},
{x:32.81975,y:-117.14943},
{x:35.41081,y:-82.19842},
{x:29.74049,y:-95.41036},
{x:34.99245,y:-82.46923},
{x:34.27816,y:-86.20393},
{x:36.13471,y:-115.16739},
{x:39.99516,y:-83.01444},
{x:30.44794,y:-97.79172},
{x:30.40973,y:-97.85152},
{x:38.453,y:-81.93709},
{x:32.59129,y:-96.93566},
{x:32.93902,y:-97.11098},
{x:40.77435,y:-73.98091},
{x:40.14626,y:-76.81376},
{x:46.83151,y:-96.88228},
{x:35.60569,y:-105.22163},
{x:41.28404,y:-96.05311},
{x:29.95939,y:-95.54707},
{x:35.18429,y:-106.59576},
{x:42.79669,y:-78.75013},
{x:30.10239,y:-95.50733},
{x:41.96114,y:-87.8075},
{x:41.94694,y:-87.80677},
{x:42.78384,y:-87.80815},
{x:39.59125,y:-104.6917},
{x:29.57022,y:-98.327},
{x:40.56104,y:-111.92395},
{x:32.95901,y:-96.6671},
{x:37.30379,y:-121.89726},
{x:30.22226,y:-97.83727},
{x:42.72755,y:-78.82364},
{x:42.76695,y:-78.6314},
{x:29.79058,y:-95.72059},
{x:40.33541,y:-75.61866},
{x:47.83879,y:-122.12489},
{x:42.09788,y:-102.86469},
{x:41.49483,y:-90.40753},
{x:41.68022,y:-87.66455},
{x:29.7593,y:-95.734},
{x:40.05995,y:-82.40113},
{x:38.81202,y:-94.51337},
{x:32.38015,y:-86.30987},
{x:33.88566,y:-84.46383},
{x:42.32865,y:-83.04866},
{x:30.66243,y:-97.90093},
{x:38.4525,y:-81.9353},
{x:33.49618,y:-112.48162},
{x:30.20791,y:-97.81539},
{x:37.50107,y:-120.85115},
{x:25.97937,y:-80.29579},
{x:35.32197,y:-80.6547},
{x:30.23767,y:-97.79254},
{x:32.85952,y:-97.29056},
{x:39.10222,y:-96.81724},
{x:34.19095,y:-118.60452},
{x:32.89514,y:-96.73936},
{x:32.59703,y:-83.58177},
{x:40.75289,y:-73.98109},
{x:29.14875,y:-98.15977},
{x:32.93283,y:-96.68222},
{x:38.89598,y:-104.8498},
{x:29.83119,y:-95.4823},
{x:34.2661,y:-84.09193},
{x:41.23009,y:-96.23345},
{x:28.33169,y:-81.58789},
{x:35.83786,y:-78.61342},
{x:35.22268,y:-80.84863},
{x:34.95824,y:-82.43627},
{x:39.08367,y:-77.56281},
{x:37.27344,y:-76.7119},
{x:35.21154,y:-80.69628},
{x:35.73825,y:-78.79825},
{x:30.28445,y:-89.77962},
{x:29.45051,y:-98.44775},
{x:30.30483,y:-97.93411},
{x:28.54812,y:-81.37657},
{x:29.548,y:-95.09784},
{x:28.60605,y:-81.38392},
{x:29.76655,y:-95.55033},
{x:30.41328,y:-97.6882},
{x:33.04043,y:-96.73192},
{x:32.85825,y:-96.64661},
{x:29.75761,y:-95.36915},
{x:37.69713,y:-121.74321},
{x:31.53382,y:-97.19051},
{x:33.07744,y:-96.82093},
{x:30.03603,y:-95.5533},
{x:33.21546,y:-96.63836},
{x:35.13378,y:-106.61215},
{x:30.02115,y:-90.25793},
{x:29.73434,y:-95.75271},
{x:30.20778,y:-95.46203},
{x:30.31632,y:-95.47633},
{x:30.50818,y:-97.68657},
{x:29.55178,y:-95.12614},
{x:33.61226,y:-117.7133},
{x:34.10731,y:-117.66572},
{x:38.78881,y:-121.22401},
{x:34.15772,y:-118.49166},
{x:33.76323,y:-118.31097},
{x:36.06615,y:-115.03661},
{x:29.90216,y:-90.06548},
{x:37.85877,y:-122.12438},
{x:35.38388,y:-119.10468},
{x:34.03425,y:-117.06083},
{x:37.97279,y:-120.36715},
{x:32.86592,y:-117.23219},
{x:30.4817,y:-97.8341},
{x:35.87941,y:-106.29719},
{x:32.95122,y:-117.06743},
{x:33.56628,y:-117.20454},
{x:34.1285,y:-117.89048},
{x:34.12165,y:-117.51074},
{x:38.59579,y:-121.41617},
{x:34.03105,y:-118.40097},
{x:33.01812,y:-117.06203},
{x:38.34312,y:-121.99581},
{x:34.24171,y:-118.53585},
{x:37.4522,y:-122.18388},
{x:33.80772,y:-118.27212},
{x:37.79377,y:-122.39662},
{x:34.04168,y:-117.79835},
{x:32.80622,y:-116.93616},
{x:33.98824,y:-117.9069},
{x:33.87141,y:-118.1249},
{x:40.25772,y:-74.72808},
{x:47.22954,y:-123.12674},
{x:47.50531,y:-122.63376},
{x:34.16793,y:-117.33176},
{x:33.74748,y:-116.93121},
{x:36.58198,y:-121.82849},
{x:46.87726,y:-96.72857},
{x:36.07187,y:-115.03333},
{x:33.53894,y:-112.13491},
{x:28.38612,y:-81.50515},
{x:32.44681,y:-97.38499},
{x:33.80959,y:-85.76176},
{x:40.7294,y:-74.005},
{x:40.15852,y:-75.15048},
{x:41.30703,y:-96.05301},
{x:38.16545,y:-122.25466},
{x:32.7292,y:-97.50265},
{x:43.31802,y:-88.35238},
{x:33.68992,y:-117.87755},
{x:41.79397,y:-87.77032},
{x:32.71787,y:-97.02455},
{x:48.2866,y:-122.6615},
{x:37.70305,y:-121.72194},
{x:35.04969,y:-118.17259},
{x:34.03604,y:-118.47769},
{x:32.29691,y:-95.30073},
{x:30.53865,y:-91.02799},
{x:37.97385,y:-122.04149},
{x:40.83327,y:-81.42439},
{x:41.58273,y:-81.21202},
{x:41.04933,y:-86.21827},
{x:36.18165,y:-94.13901},
{x:33.18909,y:-96.83987},
{x:35.02171,y:-81.89529},
{x:34.20282,y:-118.59743},
{x:40.59118,y:-73.80118},
{x:33.38322,y:-86.73969},
{x:33.73431,y:-117.81513},
{x:29.70215,y:-95.74116},
{x:35.5542,y:-100.97158},
{x:36.32617,y:-119.21062},
{x:32.38221,y:-86.2411},
{x:42.44698,y:-82.90671},
{x:37.43556,y:-121.88579},
{x:38.94013,y:-121.09592},
{x:37.76397,y:-122.24271},
{x:33.99921,y:-117.84523},
{x:34.13617,y:-117.27341},
{x:38.61369,y:-121.3826},
{x:29.78175,y:-95.56082},
{x:42.46513,y:-82.91071},
{x:36.47025,y:-86.66637},
{x:36.13361,y:-115.16527},
{x:37.96993,y:-122.5193},
{x:35.29596,y:-80.74165},
{x:36.8573,y:-121.40143},
{x:41.90942,y:-87.7496},
{x:29.73717,y:-95.58476},
{x:38.24641,y:-77.51512},
{x:38.33707,y:-85.70652},
{x:33.02574,y:-96.8418},
{x:32.28511,y:-95.32962},
{x:29.92766,y:-95.59502},
{x:39.14582,y:-121.59392},
{x:32.65889,y:-117.09089},
{x:37.5457,y:-77.456},
{x:42.37044,y:-71.07713},
{x:33.88822,y:-117.82946},
{x:38.11592,y:-121.3058},
{x:34.20054,y:-118.18902},
{x:35.53701,y:-97.53353},
{x:28.36454,y:-81.36584},
{x:37.66671,y:-121.03048},
{x:42.14114,y:-72.73284},
{x:43.51527,y:-96.66923},
{x:43.04256,y:-89.37711},
{x:38.79457,y:-121.32542},
{x:38.74082,y:-121.22651},
{x:44.55146,y:-123.30738},
{x:47.05825,y:-109.43728},
{x:47.20856,y:-93.52905},
{x:38.76915,y:-121.33054},
{x:43.32533,y:-73.66687},
{x:33.57236,y:-82.1918},
{x:60.4868,y:-151.05607},
{x:40.75188,y:-73.9877},
{x:43.06194,y:-89.30822},
{x:36.73715,y:-119.70044},
{x:34.3774,y:-118.55708},
{x:37.78213,y:-121.53158},
{x:38.67526,y:-121.02832},
{x:33.45691,y:-117.60036},
{x:37.37038,y:-121.88173},
{x:47.18518,y:-122.25889},
{x:34.66176,y:-120.45731},
{x:26.12116,y:-80.18275},
{x:37.97972,y:-78.2123},
{x:32.71338,y:-117.0121},
{x:47.69252,y:-114.14753},
{x:27.10201,y:-82.38367},
{x:33.46708,y:-111.68452},
{x:36.70337,y:-77.54252},
{x:26.93415,y:-82.31151},
{x:29.96404,y:-81.48984},
{x:29.7097,y:-95.40113},
{x:32.75635,y:-97.33119},
{x:29.52894,y:-95.07327},
{x:40.49647,y:-80.06862},
{x:39.62896,y:-79.95885},
{x:37.13593,y:-80.36229},
{x:38.5778,y:-121.48919},
{x:29.76111,y:-95.41735},
{x:32.35817,y:-111.04441},
{x:35.4503,y:-82.53101},
{x:39.75481,y:-104.99595},
{x:35.72674,y:-83.48078},
{x:36.53032,y:-82.52817},
{x:37.14822,y:-121.6589},
{x:41.61811,y:-72.89881},
{x:42.02572,y:-87.81423},
{x:25.83659,y:-80.32284},
{x:32.60644,y:-97.11658},
{x:33.57501,y:-81.93944},
{x:34.57008,y:-83.30625},
{x:40.37949,y:-105.5154},
{x:30.28502,y:-97.74472},
{x:40.04862,y:-75.05965},
{x:34.04339,y:-118.30924},
{x:41.53964,y:-72.99558},
{x:33.97059,y:-118.18838},
{x:31.60246,y:-97.10875},
{x:29.47707,y:-98.66259},
{x:40.21793,y:-80.20309},
{x:39.94082,y:-75.11576},
{x:36.02295,y:-115.04671},
{x:31.68309,y:-106.19938},
{x:38.03291,y:-78.49494},
{x:29.66449,y:-95.02846},
{x:41.71813,y:-87.81124},
{x:40.9059,y:-98.33942},
{x:39.59553,y:-104.89622},
{x:41.16055,y:-104.77912},
{x:29.82905,y:-94.84804},
{x:40.12898,y:-77.0201},
{x:33.36408,y:-111.78927},
{x:26.24191,y:-98.24851},
{x:28.70655,y:-100.48372},
{x:36.24013,y:-115.24946},
{x:32.76757,y:-96.62383},
{x:41.24264,y:-73.10363},
{x:29.90238,y:-95.55327},
{x:39.67871,y:-104.96387},
{x:36.93032,y:-76.31748},
{x:33.91673,y:-118.0494},
{x:42.40816,y:-71.1167},
{x:37.24864,y:-80.03501},
{x:36.7777,y:-119.68169},
{x:33.79632,y:-83.74117},
{x:41.99017,y:-72.578},
{x:43.14975,y:-77.55394},
{x:42.56161,y:-71.99092},
{x:39.63617,y:-78.84085},
{x:36.8241,y:-76.33737},
{x:34.10748,y:-80.83318},
{x:44.04679,y:-123.07001},
{x:33.11285,y:-117.10087},
{x:32.81392,y:-117.26997},
{x:38.40862,y:-121.39196},
{x:44.93253,y:-93.1667},
{x:47.61363,y:-122.20133},
{x:36.08017,y:-119.04366},
{x:37.55886,y:-77.4649},
{x:44.95578,y:-93.27835},
{x:32.89547,y:-97.2889},
{x:39.98648,y:-74.17903},
{x:32.15165,y:-80.76013},
{x:33.43643,y:-111.71852},
{x:38.05551,y:-121.25816},
{x:34.27207,y:-118.67702},
{x:37.68687,y:-120.95839},
{x:35.28103,y:-119.03216},
{x:35.32385,y:-119.12853},
{x:33.45843,y:-112.30595},
{x:32.39993,y:-90.14193},
{x:33.78759,y:-117.86768},
{x:39.48449,y:-119.85287},
{x:34.93873,y:-85.21563},
{x:33.68448,y:-117.36703},
{x:40.57828,y:-105.07786},
{x:40.13496,y:-75.20883},
{x:33.81545,y:-116.48728},
{x:30.07515,y:-95.55882},
{x:39.62315,y:-105.11039},
{x:33.71997,y:-116.38276},
{x:32.79511,y:-116.96275},
{x:42.31931,y:-88.97544},
{x:35.16292,y:-80.88644},
{x:34.02704,y:-117.61138},
{x:40.73436,y:-73.99236},
{x:36.16078,y:-86.78637},
{x:28.86692,y:-82.00779},
{x:37.7189,y:-121.72481},
{x:30.591,y:-96.33015},
{x:33.99953,y:-118.48087},
{x:38.0822,y:-120.55987},
{x:38.65039,y:-121.06864},
{x:47.65633,y:-122.67512},
{x:33.7521,y:-84.38237},
{x:33.95194,y:-96.41278},
{x:41.6903,y:-93.7768},
{x:42.41321,y:-70.99086},
{x:41.10559,y:-73.41827},
{x:34.44019,y:-119.72939},
{x:32.23568,y:-110.9571},
{x:44.37835,y:-89.81769},
{x:44.3569,y:-89.11222},
{x:32.26967,y:-86.35586},
{x:38.42299,y:-82.42126},
{x:37.33437,y:-121.92409},
{x:33.94863,y:-96.41491},
{x:31.48925,y:-82.85177},
{x:32.91559,y:-96.52252},
{x:47.60696,y:-122.3385},
{x:33.7133,y:-112.14193},
{x:39.67639,y:-104.91323},
{x:32.5848,y:-117.09176},
{x:33.63784,y:-117.5914},
{x:42.91864,y:-89.24162},
{x:40.75435,y:-74.00223},
{x:41.28982,y:-72.5291},
{x:42.02561,y:-71.14381},
{x:40.75844,y:-96.64392},
{x:40.74013,y:-96.60494},
{x:34.68964,y:-77.06856},
{x:35.55694,y:-97.63789},
{x:40.11058,y:-88.22408},
{x:27.92397,y:-97.17773},
{x:42.30125,y:-73.24279},
{x:42.07988,y:-71.3822},
{x:35.60282,y:-97.54894},
{x:34.66235,y:-120.45623},
{x:34.47634,y:-114.33451},
{x:45.47554,y:-122.86872},
{x:33.61489,y:-117.93132},
{x:33.98449,y:-118.10015},
{x:32.78635,y:-117.00524},
{x:33.49141,y:-101.92334},
{x:41.89616,y:-87.62084},
{x:34.35256,y:-82.92906},
{x:33.06853,y:-117.26528},
{x:28.42793,y:-81.61814},
{x:40.41345,y:-104.71101},
{x:38.09661,y:-78.46426},
{x:35.30639,y:-80.85075},
{x:41.56801,y:-87.55858},
{x:32.57955,y:-117.0347},
{x:38.01753,y:-122.13637},
{x:33.71631,y:-117.97159},
{x:38.44105,y:-122.71248},
{x:33.12416,y:-96.81451},
{x:40.75887,y:-73.9659},
{x:34.17623,y:-119.21384},
{x:37.99937,y:-121.31545},
{x:38.13424,y:-85.58013},
{x:37.51196,y:-121.94748},
{x:36.55876,y:-82.2129},
{x:32.22203,y:-110.87708},
{x:32.99827,y:-97.20575},
{x:32.80615,y:-117.2203},
{x:41.68851,y:-93.7692},
{x:37.31298,y:-121.94975},
{x:31.73383,y:-95.61531},
{x:37.95759,y:-122.037},
{x:33.2011,y:-117.38423},
{x:42.50809,y:-70.89648},
{x:33.59508,y:-117.2429},
{x:33.90954,y:-117.97764},
{x:33.9678,y:-84.49087},
{x:34.52434,y:-117.21699},
{x:34.05541,y:-118.23349},
{x:45.63165,y:-122.66801},
{x:35.78822,y:-78.66938},
{x:34.04799,y:-118.23902},
{x:36.30308,y:-82.46082},
{x:35.17476,y:-86.07469},
{x:45.01639,y:-93.02163},
{x:35.91475,y:-82.07506},
{x:35.86883,y:-83.75622},
{x:32.90532,y:-84.32843},
{x:27.94793,y:-82.45675},
{x:27.759,y:-98.05248},
{x:38.76128,y:-121.24986},
{x:34.68757,y:-82.1967},
{x:36.07969,y:-80.10626},
{x:36.00542,y:-80.03664},
{x:33.2624,y:-111.8571},
{x:33.85414,y:-117.8369},
{x:34.8469,y:-82.40059},
{x:32.54415,y:-117.04193},
{x:31.96275,y:-83.76289},
{x:40.74958,y:-89.63378},
{x:36.19353,y:-83.35632},
{x:41.97711,y:-88.12938},
{x:34.06193,y:-118.30865},
{x:34.15316,y:-118.25479},
{x:35.39539,y:-97.59596},
{x:30.10427,y:-81.82775},
{x:47.92137,y:-97.06988},
{x:39.56715,y:-105.11058},
{x:39.59539,y:-104.85338},
{x:32.25892,y:-110.94262},
{x:40.08862,y:-83.12357},
{x:32.81421,y:-96.94939},
{x:33.13265,y:-117.06184},
{x:21.29607,y:-157.81478},
{x:33.24357,y:-95.90742},
{x:36.21456,y:-86.33066},
{x:30.71507,y:-94.95632},
{x:36.27425,y:-115.26882},
{x:41.85953,y:-87.62974},
{x:40.58168,y:-111.83275},
{x:33.8189,y:-84.81251},
{x:39.6134,y:-119.85208},
{x:41.00278,y:-111.90713},
{x:41.94242,y:-87.68976},
{x:42.01251,y:-87.71412},
{x:36.053,y:-114.97246},
{x:40.58867,y:-105.07671},
{x:41.92136,y:-87.66948},
{x:43.0759,y:-88.46928},
{x:33.85802,y:-118.11569},
{x:34.42654,y:-118.53717},
{x:40.76616,y:-73.01894},
{x:29.52033,y:-98.63843},
{x:39.95096,y:-75.16189},
{x:36.42606,y:-82.31922},
{x:39.17636,y:-86.51974},
{x:41.94708,y:-87.65507},
{x:36.19308,y:-94.18288},
{x:32.78864,y:-96.80955},
{x:38.81086,y:-76.73835},
{x:33.48407,y:-112.07411},
{x:33.7894,y:-117.9933},
{x:34.04918,y:-118.26049},
{x:36.00636,y:-78.93813},
{x:33.30069,y:-117.31248},
{x:38.87014,y:-77.05494},
{x:34.1462,y:-118.25863},
{x:40.89052,y:-74.25827},
{x:35.26313,y:-120.67663},
{x:42.27344,y:-71.80539},
{x:33.05244,y:-96.79573},
{x:47.67501,y:-122.12508},
{x:37.79132,y:-122.39234},
{x:33.93201,y:-118.18334},
{x:37.96384,y:-122.06258},
{x:42.31745,y:-87.86506},
{x:41.79318,y:-107.21115},
{x:33.86667,y:-112.13689},
{x:40.04698,y:-75.05493},
{x:42.96808,y:-88.00877},
{x:32.97266,y:-97.31005},
{x:40.52802,y:-80.00622},
{x:35.25461,y:-80.45955},
{x:38.57786,y:-121.48362},
{x:33.79967,y:-111.96697},
{x:39.67084,y:-104.93902},
{x:34.06931,y:-118.26588},
{x:34.1615,y:-118.51264},
{x:33.13729,y:-96.80498},
{x:41.8934,y:-87.63296},
{x:42.08646,y:-75.97269},
{x:34.10636,y:-117.43482},
{x:37.47868,y:-122.1859},
{x:34.27199,y:-85.23224},
{x:36.01112,y:-115.17455},
{x:31.77978,y:-85.94614},
{x:39.14742,y:-121.64439},
{x:41.08864,y:-112.04693},
{x:40.33626,y:-105.09609},
{x:26.24576,y:-80.25264},
{x:43.13236,y:-77.44203},
{x:33.6037,y:-86.28118},
{x:35.6674,y:-78.69812},
{x:39.39939,y:-76.60501},
{x:40.43443,y:-79.75266},
{x:39.10437,y:-75.54101},
{x:41.22212,y:-73.21838},
{x:41.47908,y:-72.90551},
{x:40.62635,y:-73.7389},
{x:45.22662,y:-123.18328},
{x:29.40262,y:-95.0319},
{x:32.61473,y:-117.0262},
{x:26.11598,y:-80.10507},
{x:34.06448,y:-118.4694},
{x:37.33102,y:-121.81195},
{x:44.79587,y:-91.46629},
{x:25.94603,y:-80.12183},
{x:29.59926,y:-95.35558},
{x:33.22128,y:-111.63186},
{x:40.67382,y:-99.09049},
{x:38.94327,y:-104.61064},
{x:34.01915,y:-118.33476},
{x:38.70145,y:-90.44596},
{x:34.14885,y:-118.12158},
{x:34.27792,y:-119.21234},
{x:39.45357,y:-119.77813},
{x:39.24943,y:-119.95304},
{x:33.88884,y:-117.81109},
{x:44.86144,y:-93.48399},
{x:40.76786,y:-84.161},
{x:36.91877,y:-84.12706},
{x:34.02651,y:-117.68967},
{x:43.59792,y:-116.57274},
{x:43.60332,y:-116.434},
{x:34.1498,y:-80.91225},
{x:33.91493,y:-81.52498},
{x:33.98799,y:-81.00341},
{x:44.74795,y:-108.77151},
{x:37.69009,y:-122.06267},
{x:36.80527,y:-119.70101},
{x:33.56275,y:-117.64784},
{x:33.69961,y:-117.8653},
{x:37.76291,y:-122.41042},
{x:41.87397,y:-87.66928},
{x:42.95007,y:-88.00895},
{x:37.33832,y:-121.88636},
{x:42.96082,y:-85.67034},
{x:34.19199,y:-84.5078},
{x:45.09573,y:-93.43116},
{x:33.62137,y:-111.87375},
{x:34.21909,y:-118.58805},
{x:34.02119,y:-117.55878},
{x:34.07423,y:-117.43543},
{x:34.0438,y:-118.25452},
{x:28.3786,y:-81.61672},
{x:37.32094,y:-121.94912},
{x:41.7191,y:-91.60853},
{x:33.04216,y:-83.93908},
{x:40.05299,y:-82.91252},
{x:34.03985,y:-118.25335},
{x:34.27927,y:-118.73839},
{x:41.31152,y:-122.31465},
{x:37.7054,y:-121.04944},
{x:35.76332,y:-78.00768},
{x:39.08919,y:-74.82021},
{x:36.16172,y:-115.17319},
{x:34.15779,y:-118.39624},
{x:34.99386,y:-78.13883},
{x:39.95232,y:-75.15849},
{x:40.65783,y:-111.50694},
{x:33.9283,y:-81.07632},
{x:41.8249,y:-87.68523},
{x:26.45749,y:-80.12129},
{x:28.26037,y:-81.60714},
{x:40.32928,y:-94.87347},
{x:35.6537,y:-97.56836},
{x:32.22044,y:-110.77395},
{x:35.20343,y:-97.45964},
{x:37.0531,y:-100.92176},
{x:35.26376,y:-97.4754},
{x:39.95666,y:-75.1779},
{x:33.9393,y:-118.04449},
{x:48.36316,y:-114.15},
{x:38.66436,y:-121.27298},
{x:33.68121,y:-78.9235},
{x:39.64224,y:-106.5904},
{x:33.91136,y:-117.46149},
{x:38.77484,y:-121.26896},
{x:33.75544,y:-84.38207},
{x:39.69373,y:-104.99274},
{x:34.64905,y:-86.0298},
{x:32.80215,y:-86.58385},
{x:32.8443,y:-116.87965},
{x:40.41017,y:-74.04098},
{x:33.04192,y:-97.03763},
{x:33.81994,y:-117.52269},
{x:36.07331,y:-79.79648},
{x:28.57578,y:-81.30996},
{x:28.27817,y:-81.34366},
{x:28.19778,y:-81.43935},
{x:28.43647,y:-81.424},
{x:33.71513,y:-118.02243},
{x:29.78229,y:-95.52174},
{x:34.92356,y:-81.98028},
{x:28.59322,y:-81.72205},
{x:27.48591,y:-82.43526},
{x:40.75597,y:-73.99046},
{x:42.36608,y:-71.02821},
{x:42.93091,y:-85.3734},
{x:40.91736,y:-72.70951},
{x:40.76492,y:-73.30414},
{x:38.45541,y:-121.40356},
{x:37.61788,y:-122.06979},
{x:40.8371,y:-73.18531},
{x:32.78355,y:-96.56016},
{x:40.57137,y:-122.3509},
{x:32.20539,y:-95.83682},
{x:31.96025,y:-95.26843},
{x:32.52693,y:-94.78668},
{x:32.23394,y:-95.22715},
{x:32.44419,y:-94.7192},
{x:32.81318,y:-96.77061},
{x:38.57335,y:-121.4138},
{x:36.1669,y:-115.20649},
{x:33.43776,y:-112.42553},
{x:32.25022,y:-110.89226},
{x:38.30959,y:-104.62722},
{x:29.55185,y:-98.66434},
{x:42.06499,y:-93.87634},
{x:35.46986,y:-83.3049},
{x:35.46624,y:-97.51041},
{x:40.3358,y:-75.92941},
{x:40.76545,y:-83.82282},
{x:39.10717,y:-76.77157},
{x:36.65836,y:-121.66028},
{x:27.53101,y:-99.47383},
{x:26.65119,y:-80.11094},
{x:40.89741,y:-74.09722},
{x:40.11319,y:-76.45141},
{x:44.45353,y:-89.52585},
{x:40.89651,y:-74.23646},
{x:33.86113,y:-117.7913},
{x:34.17245,y:-118.44752},
{x:26.36824,y:-98.79317},
{x:38.58633,y:-92.25568},
{x:37.47009,y:-122.43495},
{x:18.48707,y:-66.79521},
{x:42.39134,y:-83.51089},
{x:32.63053,y:-117.08673},
{x:31.68399,y:-96.47836},
{x:35.18954,y:-101.86562},
{x:32.88934,y:-117.12436},
{x:33.32132,y:-111.91161},
{x:37.46575,y:-77.42728},
{x:32.84186,y:-116.98113},
{x:42.42637,y:-71.07335},
{x:42.82415,y:-71.62377},
{x:35.67465,y:-88.78147},
{x:36.6405,y:-93.25672},
{x:37.00408,y:-93.22713},
{x:38.46913,y:-90.30511},
{x:32.40282,y:-99.75793},
{x:41.78819,y:-87.68363},
{x:41.64242,y:-88.08109},
{x:33.76139,y:-96.58333},
{x:41.21294,y:-95.97585},
{x:40.14729,y:-105.13142},
{x:33.42215,y:-111.80561},
{x:26.17343,y:-97.9822},
{x:33.01898,y:-96.71012},
{x:34.67777,y:-92.3435},
{x:36.36072,y:-94.21606},
{x:40.1619,y:-122.22729},
{x:34.98177,y:-78.96204},
{x:37.78611,y:-122.45323},
{x:36.0528,y:-90.50521},
{x:36.31256,y:-119.34717},
{x:35.36144,y:-83.20222},
{x:34.10855,y:-117.30049},
{x:34.71095,y:-92.40798},
{x:38.58995,y:-77.77052},
{x:40.30964,y:-74.65991},
{x:31.72403,y:-106.30354},
{x:29.55544,y:-95.38212},
{x:37.67289,y:-122.08507},
{x:45.49304,y:-91.73358},
{x:34.69067,y:-82.86047},
{x:26.61685,y:-81.67023},
{x:34.76351,y:-79.4808},
{x:43.51511,y:-96.82828},
{x:40.68519,y:-99.08524},
{x:26.23327,y:-98.18111},
{x:30.00231,y:-95.55947},
{x:29.66449,y:-95.20371},
{x:31.75399,y:-93.08062},
{x:33.19624,y:-96.66729},
{x:39.94119,y:-75.02316},
{x:42.21009,y:-83.14828},
{x:42.34855,y:-71.07731},
{x:33.74096,y:-116.26418},
{x:37.46635,y:-122.2245},
{x:40.39856,y:-74.11102},
{x:38.53852,y:-90.98788},
{x:34.06302,y:-118.09011},
{x:37.3002,y:-121.82186},
{x:30.08631,y:-90.44432},
{x:39.86369,y:-74.96528},
{x:32.37364,y:-111.13731},
{x:36.67505,y:-121.61551},
{x:42.17218,y:-72.63973},
{x:40.86839,y:-73.35104},
{x:29.6239,y:-98.24092},
{x:29.42098,y:-98.77378},
{x:37.88962,y:-121.62181},
{x:29.98877,y:-97.87129},
{x:26.5084,y:-80.12269},
{x:44.0498,y:-92.52125},
{x:33.22274,y:-97.75711},
{x:33.77286,y:-118.18913},
{x:33.481,y:-112.25363},
{x:40.80871,y:-74.07094},
{x:32.52913,y:-92.07262},
{x:28.33681,y:-81.588},
{x:36.12272,y:-111.22404},
{x:35.22155,y:-114.00465},
{x:33.82361,y:-118.32823},
{x:42.4467,y:-75.05101},
{x:41.66383,y:-70.28513},
{x:41.90592,y:-71.04889},
{x:42.59276,y:-71.20832},
{x:42.24532,y:-71.75944},
{x:43.10783,y:-75.22141},
{x:41.81525,y:-73.09465},
{x:42.29691,y:-85.23258},
{x:42.97195,y:-85.77485},
{x:44.74624,y:-93.27898},
{x:38.36246,y:-122.69305},
{x:41.79534,y:-86.7447},
{x:42.20841,y:-83.36261},
{x:42.20483,y:-83.36065},
{x:42.2118,y:-83.35524},
{x:38.22141,y:-122.12891},
{x:33.07085,y:-117.07039},
{x:37.99029,y:-122.33487},
{x:38.58899,y:-121.26595},
{x:38.84074,y:-77.10703},
{x:35.15624,y:-79.41634},
{x:35.11654,y:-80.85829},
{x:34.17419,y:-77.92499},
{x:34.29798,y:-77.79026},
{x:41.62849,y:-93.56973},
{x:39.47644,y:-119.80626},
{x:33.88443,y:-118.39631},
{x:36.82447,y:-119.77194},
{x:34.07389,y:-117.7783},
{x:33.83147,y:-118.25957},
{x:33.92261,y:-81.29017},
{x:32.72832,y:-97.38837},
{x:30.61344,y:-96.31612},
{x:33.7889,y:-117.94088},
{x:36.97819,y:-121.96625},
{x:34.23634,y:-119.03858},
{x:33.57338,y:-101.90616},
{x:31.80554,y:-97.10061},
{x:38.35155,y:-85.79518},
{x:40.78746,y:-74.17517},
{x:33.91772,y:-117.93085},
{x:40.27883,y:-111.71461},
{x:36.55229,y:-82.56612},
{x:33.92775,y:-118.12932},
{x:34.13452,y:-117.53724},
{x:34.43833,y:-119.74823},
{x:41.57858,y:-87.85109},
{x:34.13591,y:-117.63324},
{x:31.79532,y:-106.51762},
{x:41.24107,y:-76.83359},
{x:41.85356,y:-88.33983},
{x:39.28374,y:-76.59308},
{x:33.66281,y:-117.29692},
{x:36.0058,y:-119.30106},
{x:33.20108,y:-117.24467},
{x:33.91066,y:-117.27265},
{x:38.87865,y:-77.02317},
{x:41.19227,y:-112.04608},
{x:36.67417,y:-121.63819},
{x:41.16855,y:-85.48978},
{x:40.30786,y:-88.17523},
{x:37.55334,y:-121.97382},
{x:39.80052,y:-89.64575},
{x:41.58089,y:-93.62732},
{x:37.82192,y:-121.99983},
{x:39.42029,y:-119.75474},
{x:36.13017,y:-115.11811},
{x:34.49454,y:-81.98328},
{x:29.75436,y:-95.45412},
{x:32.39941,y:-110.95699},
{x:41.81549,y:-90.23215},
{x:38.85155,y:-121.28941},
{x:47.70456,y:-122.32386},
{x:39.47978,y:-106.04613},
{x:38.87829,y:-104.84663},
{x:42.26249,y:-71.10713},
{x:42.34962,y:-71.50508},
{x:37.68823,y:-121.0548},
{x:33.62645,y:-111.89676},
{x:34.05573,y:-118.2703},
{x:34.98466,y:-118.94705},
{x:32.27139,y:-95.30746},
{x:34.09666,y:-82.84511},
{x:35.81311,y:-82.67088},
{x:35.27334,y:-81.52887},
{x:33.49315,y:-112.28739},
{x:34.80266,y:-82.60103},
{x:34.79107,y:-82.69015},
{x:40.25303,y:-111.66645},
{x:25.6851,y:-80.33731},
{x:25.83669,y:-80.32324},
{x:40.42481,y:-86.91155},
{x:32.01796,y:-102.1248},
{x:34.40811,y:-118.57929},
{x:39.00576,y:-84.42575},
{x:35.20107,y:-80.77368},
{x:33.47408,y:-86.70194},
{x:45.02128,y:-93.18641},
{x:41.57842,y:-87.79385},
{x:41.58541,y:-93.65149},
{x:37.26326,y:-121.9188},
{x:33.12958,y:-97.04108},
{x:33.01004,y:-96.96929},
{x:29.93436,y:-93.93061},
{x:33.45882,y:-117.65137},
{x:33.96131,y:-80.93958},
{x:41.80821,y:-88.37232},
{x:39.54097,y:-119.71605},
{x:37.3041,y:-121.91412},
{x:37.3917,y:-121.97713},
{x:33.80178,y:-117.22923},
{x:33.83944,y:-117.94198},
{x:33.84131,y:-118.28542},
{x:38.78584,y:-77.51956},
{x:40.11117,y:-74.9513},
{x:40.3339,y:-75.13249},
{x:35.90279,y:-79.95114},
{x:27.72606,y:-82.68009},
{x:42.66667,y:-83.13291},
{x:47.30796,y:-119.56041},
{x:36.6872,y:-121.79952},
{x:44.81767,y:-91.45634},
{x:39.85455,y:-86.39105},
{x:37.51381,y:-122.2543},
{x:41.98485,y:-88.70928},
{x:41.63785,y:-81.47516},
{x:31.77449,y:-106.33922},
{x:30.49744,y:-97.77462},
{x:28.4229,y:-97.7574},
{x:29.48137,y:-98.36266},
{x:38.66597,y:-94.36658},
{x:38.5807,y:-95.26832},
{x:39.11682,y:-94.75791},
{x:39.24872,y:-94.54056},
{x:39.59437,y:-104.82993},
{x:40.02997,y:-82.90988},
{x:31.68878,y:-106.26461},
{x:34.05947,y:-118.38959},
{x:38.72995,y:-121.28936},
{x:38.6428,y:-121.52059},
{x:38.74256,y:-121.17122},
{x:42.43091,y:-89.02001},
{x:32.36411,y:-88.67665},
{x:34.4061,y:-118.46607},
{x:32.35876,y:-111.08604},
{x:32.50644,y:-114.78631},
{x:33.99679,y:-117.71579},
{x:34.0716,y:-118.06805},
{x:38.6135,y:-121.51662},
{x:35.89834,y:-78.65292},
{x:32.75159,y:-117.20618},
{x:39.76079,y:-121.84493},
{x:37.54459,y:-122.29278},
{x:29.53607,y:-95.02006},
{x:29.47326,y:-95.37263},
{x:36.05215,y:-114.97076},
{x:39.25934,y:-84.79955},
{x:32.31309,y:-81.05658},
{x:33.71652,y:-118.00628},
{x:39.95166,y:-75.16917},
{x:41.57092,y:-93.80984},
{x:37.89862,y:-122.51508},
{x:27.70689,y:-97.37001},
{x:36.64968,y:-121.79419},
{x:30.61049,y:-96.34935},
{x:32.90887,y:-96.77172},
{x:39.7731,y:-86.16645},
{x:36.70958,y:-110.25112},
{x:37.1808,y:-93.25285},
{x:33.30498,y:-111.94709},
{x:30.25181,y:-94.21467},
{x:42.27002,y:-88.00454},
{x:38.59121,y:-121.55453},
{x:34.16866,y:-118.22903},
{x:43.1715,y:-79.03131},
{x:33.72472,y:-112.11493},
{x:38.6259,y:-83.7923},
{x:42.21552,y:-121.75582},
{x:42.41106,y:-87.85543},
{x:41.52007,y:-81.51742},
{x:41.91642,y:-87.80568},
{x:38.92157,y:-121.05696},
{x:33.89385,y:-117.94504},
{x:44.31125,y:-96.78163},
{x:30.40225,y:-86.87651},
{x:47.06741,y:-122.76805},
{x:45.67825,y:-122.55306},
{x:39.24556,y:-94.47426},
{x:34.09035,y:-118.34425},
{x:41.93274,y:-89.06898},
{x:41.579,y:-88.16181},
{x:37.75592,y:-121.46627},
{x:35.24915,y:-120.64314},
{x:39.56091,y:-84.25838},
{x:29.9856,y:-95.33825},
{x:30.43994,y:-84.29672},
{x:38.9359,y:-119.73875},
{x:39.52742,y:-119.81322},
{x:33.61039,y:-112.23482},
{x:38.59653,y:-121.40793},
{x:29.91582,y:-95.61644},
{x:32.90404,y:-97.54397},
{x:33.28267,y:-96.56446},
{x:34.82354,y:-89.97859},
{x:34.03054,y:-118.41574},
{x:29.5313,y:-95.81059},
{x:30.11611,y:-94.16925},
{x:32.99778,y:-96.66917},
{x:33.21551,y:-96.66212},
{x:37.82625,y:-121.22031},
{x:33.9423,y:-117.24176},
{x:33.66029,y:-117.87367},
{x:36.13239,y:-95.96494},
{x:35.50004,y:-119.27912},
{x:25.76584,y:-80.22279},
{x:29.65067,y:-95.13856},
{x:32.85116,y:-117.25387},
{x:32.75004,y:-117.17027},
{x:33.88766,y:-117.22666},
{x:33.92589,y:-116.97643},
{x:38.2586,y:-122.05391},
{x:38.8862,y:-104.7019},
{x:36.22539,y:-119.33125},
{x:35.15961,y:-101.90063},
{x:34.06834,y:-117.92723},
{x:34.1949,y:-118.53565},
{x:43.77431,y:-87.73536},
{x:41.78786,y:-85.4289},
{x:42.71349,y:-89.00011},
{x:41.10001,y:-80.7574},
{x:34.10373,y:-118.07413},
{x:37.84062,y:-85.93802},
{x:39.94781,y:-82.94036},
{x:39.95223,y:-82.68423},
{x:34.74896,y:-82.04616},
{x:41.48417,y:-87.73549},
{x:33.61563,y:-117.90007},
{x:34.06307,y:-118.06604},
{x:32.85496,y:-115.56967},
{x:36.73465,y:-78.91597},
{x:32.86655,y:-96.94524},
{x:32.83115,y:-117.13069},
{x:35.44796,y:-80.99901},
{x:29.85367,y:-95.18974},
{x:36.0625,y:-79.43248},
{x:35.2959,y:-119.11194},
{x:36.84511,y:-81.49989},
{x:33.49071,y:-117.69815},
{x:36.66607,y:-76.95697},
{x:40.90125,y:-80.82936},
{x:29.77241,y:-95.18685},
{x:34.01745,y:-118.10621},
{x:35.20359,y:-80.8449},
{x:33.69163,y:-117.88775},
{x:34.10614,y:-117.5686},
{x:29.79769,y:-95.4854},
{x:31.33429,y:-94.71994},
{x:29.73314,y:-95.34269},
{x:30.05706,y:-94.08517},
{x:29.70157,y:-95.29332},
{x:29.17821,y:-95.43333},
{x:33.77358,y:-117.88429},
{x:30.70674,y:-95.54593},
{x:39.40529,y:-76.77985},
{x:43.03995,y:-87.90153},
{x:38.7579,y:-76.99503},
{x:36.03648,y:-83.86909},
{x:39.95176,y:-75.15408},
{x:38.71389,y:-121.36644},
{x:36.06943,y:-94.17179},
{x:39.99558,y:-83.00696},
{x:33.3774,y:-111.63554},
{x:28.41187,y:-81.31199},
{x:28.41191,y:-81.3128},
{x:42.37235,y:-71.07151},
{x:34.70888,y:-82.49663},
{x:33.84627,y:-79.06491},
{x:32.53379,y:-92.07892},
{x:35.17847,y:-101.93868},
{x:32.1875,y:-90.26349},
{x:32.93153,y:-96.3813},
{x:32.85028,y:-96.99278},
{x:32.14735,y:-94.78973},
{x:31.696,y:-89.14645},
{x:37.45161,y:-121.92149},
{x:42.78636,y:-96.91957},
{x:43.54359,y:-96.77699},
{x:32.93721,y:-111.75761},
{x:37.14322,y:-80.58118},
{x:40.73599,y:-74.97506},
{x:39.0964,y:-104.86606},
{x:36.33208,y:-119.39154},
{x:37.36201,y:-120.61354},
{x:32.82357,y:-96.78955},
{x:34.44414,y:-118.5744},
{x:30.28696,y:-81.6325},
{x:30.3941,y:-97.86732},
{x:30.14211,y:-81.55029},
{x:38.34844,y:-122.72089},
{x:38.40761,y:-122.74122},
{x:37.01626,y:-79.86452},
{x:37.4799,y:-77.51244},
{x:36.71112,y:-78.10963},
{x:41.4529,y:-81.80106},
{x:37.35201,y:-77.48134},
{x:37.63401,y:-77.35607},
{x:36.00322,y:-75.65509},
{x:29.98837,y:-95.49652},
{x:37.79042,y:-122.40575},
{x:33.963,y:-118.18593},
{x:36.84241,y:-76.08208},
{x:41.0135,y:-80.66313},
{x:33.94584,y:-118.33111},
{x:40.01692,y:-75.30846},
{x:37.27485,y:-121.8575},
{x:36.64287,y:-93.28559},
{x:32.86527,y:-96.80501},
{x:36.51874,y:-119.55723},
{x:28.54313,y:-81.36864},
{x:34.3961,y:-118.91697},
{x:30.64963,y:-97.67782},
{x:41.65663,y:-70.92226},
{x:33.31008,y:-96.78227},
{x:34.36676,y:-96.13951},
{x:37.81332,y:-80.43009},
{x:27.07298,y:-82.20852},
{x:29.30829,y:-94.79328},
{x:33.52016,y:-101.90532},
{x:39.61005,y:-104.8894},
{x:40.55653,y:-74.29961},
{x:38.78692,y:-90.56263},
{x:26.16952,y:-81.79738},
{x:38.59982,y:-90.44723},
{x:40.95644,y:-74.06862},
{x:26.01055,y:-80.30463},
{x:33.70263,y:-78.92242},
{x:34.07721,y:-81.15518},
{x:33.46411,y:-112.28318},
{x:35.33844,y:-119.03345},
{x:36.31159,y:-119.31516},
{x:32.28883,y:-110.97482},
{x:29.65788,y:-82.41095},
{x:33.8803,y:-84.46952},
{x:41.82748,y:-71.41647},
{x:29.73713,y:-95.50396},
{x:33.07103,y:-117.06576},
{x:33.90988,y:-117.45685},
{x:33.52571,y:-117.15384},
{x:42.91621,y:-85.58819},
{x:39.40443,y:-76.59862},
{x:32.68028,y:-97.1294},
{x:27.5749,y:-99.4723},
{x:61.14321,y:-149.86864},
{x:32.36456,y:-88.70054},
{x:34.23534,y:-118.48596},
{x:41.75139,y:-72.71639},
{x:37.71964,y:-77.45162},
{x:39.08378,y:-76.88916},
{x:38.42352,y:-77.42971},
{x:35.48755,y:-79.19358},
{x:36.06333,y:-79.6067},
{x:32.67811,y:-117.08202},
{x:41.37139,y:-72.13646},
{x:26.52942,y:-81.84861},
{x:33.90348,y:-80.38179},
{x:45.643,y:-110.57458},
{x:33.2266,y:-117.33142},
{x:38.95362,y:-94.72018},
{x:39.50528,y:-119.81685},
{x:40.33987,y:-79.95039},
{x:33.96916,y:-118.16424},
{x:34.05748,y:-117.82142},
{x:30.26278,y:-81.44166},
{x:37.27478,y:-121.89321},
{x:42.84263,y:-85.53118},
{x:35.02699,y:-97.93591},
{x:34.98687,y:-97.36893},
{x:44.48504,y:-88.06836},
{x:39.9844,y:-83.02315},
{x:36.16374,y:-115.15811},
{x:33.55704,y:-83.47574},
{x:48.50091,y:-122.25872},
{x:45.55908,y:-94.15757},
{x:42.01181,y:-93.60985},
{x:35.02052,y:-90.02448},
{x:31.80871,y:-106.2471},
{x:40.82055,y:-74.1214},
{x:40.62965,y:-89.46385},
{x:40.00304,y:-75.28291},
{x:43.1212,y:-87.91277},
{x:40.58217,y:-105.07625},
{x:42.27626,y:-71.02932},
{x:46.73386,y:-117.03123},
{x:43.63357,y:-72.32196},
{x:21.96839,y:-159.37718},
{x:39.32519,y:-74.59533},
{x:32.74764,y:-116.9847},
{x:34.08042,y:-118.15212},
{x:38.02051,y:-121.29557},
{x:41.90512,y:-87.84363},
{x:35.45153,y:-97.72393},
{x:34.49424,y:-81.84709},
{x:33.11404,y:-96.83893},
{x:41.01445,y:-85.1151},
{x:36.08949,y:-95.93766},
{x:29.55648,y:-95.37293},
{x:45.06131,y:-83.4735},
{x:41.68065,y:-86.18835},
{x:42.68194,y:-83.15576},
{x:36.41637,y:-121.3209},
{x:42.64662,y:-85.32182},
{x:33.22081,y:-96.92623},
{x:32.89621,y:-96.80357},
{x:33.77203,y:-118.00701},
{x:39.18052,y:-96.55608},
{x:34.71688,y:-77.32338},
{x:40.62576,y:-103.21028},
{x:33.82638,y:-118.35956},
{x:39.10596,y:-108.54457},
{x:45.5967,y:-94.19283},
{x:27.89302,y:-82.51948},
{x:34.94427,y:-86.57221},
{x:34.02757,y:-117.8365},
{x:37.81717,y:-122.26736},
{x:29.6498,y:-82.32366},
{x:33.88449,y:-98.52874},
{x:33.48585,y:-117.72421},
{x:37.24277,y:-81.24103},
{x:33.66928,y:-96.61152},
{x:44.87994,y:-93.20697},
{x:30.44482,y:-84.29803},
{x:40.7703,y:-73.86365},
{x:40.77054,y:-73.86416},
{x:29.78101,y:-95.53954},
{x:33.2968,y:-111.87418},
{x:33.81687,y:-116.52856},
{x:38.48593,y:-90.35786},
{x:36.79442,y:-119.75647},
{x:33.67121,y:-117.65286},
{x:36.05547,y:-80.25896},
{x:29.4399,y:-98.45122},
{x:35.56517,y:-77.35769},
{x:36.00269,y:-79.90549},
{x:36.17209,y:-79.98768},
{x:34.92281,y:-79.75048},
{x:47.65989,y:-117.15308},
{x:37.96203,y:-121.7384},
{x:26.96589,y:-80.08615},
{x:30.09987,y:-95.4422},
{x:34.17174,y:-118.28945},
{x:41.68318,y:-81.34156},
{x:32.6481,y:-116.96665},
{x:37.05732,y:-120.83356},
{x:48.10659,y:-123.39877},
{x:37.78125,y:-121.53194},
{x:47.61874,y:-122.35529},
{x:33.75738,y:-116.39155},
{x:44.7763,y:-91.4316},
{x:33.74857,y:-117.81032},
{x:41.77344,y:-80.94583},
{x:38.14082,y:-122.23164},
{x:32.45725,y:-96.99518},
{x:29.96408,y:-95.54375},
{x:33.86806,y:-78.63528},
{x:34.89998,y:-82.25521},
{x:42.46504,y:-87.87995},
{x:35.50867,y:-97.97062},
{x:42.88817,y:-78.87451},
{x:34.26317,y:-119.26957},
{x:34.99814,y:-85.0968},
{x:37.78656,y:-122.4082},
{x:40.64062,y:-73.91841},
{x:43.47438,y:-96.72926},
{x:28.09082,y:-80.62219},
{x:34.28099,y:-119.29139},
{x:39.98333,y:-76.82334},
{x:28.2454,y:-81.25324},
{x:29.01321,y:-80.9475},
{x:28.66806,y:-82.14134},
{x:36.15913,y:-115.1304},
{x:34.49225,y:-81.98141},
{x:41.4668,y:-87.57587},
{x:33.75831,y:-118.02434},
{x:34.12681,y:-116.40458},
{x:36.15472,y:-86.79378},
{x:41.60005,y:-93.84383},
{x:27.56622,y:-99.50221},
{x:31.06645,y:-98.18353},
{x:30.59346,y:-97.41276},
{x:26.19254,y:-98.13707},
{x:29.74965,y:-95.49971},
{x:31.86529,y:-106.57654},
{x:32.9453,y:-97.11877},
{x:38.6772,y:-121.79689},
{x:38.77556,y:-104.81361},
{x:39.67277,y:-104.78843},
{x:40.65951,y:-111.88782},
{x:40.68467,y:-112.27127},
{x:40.68298,y:-112.02403},
{x:39.28946,y:-76.62218},
{x:39.95698,y:-74.38933},
{x:38.40749,y:-122.83868},
{x:39.44201,y:-77.43661},
{x:43.60348,y:-96.7866},
{x:43.56284,y:-96.79139},
{x:37.13145,y:-94.47565},
{x:32.66917,y:-117.02242},
{x:29.73899,y:-95.46297},
{x:33.80006,y:-116.3874},
{x:35.93964,y:-115.13081},
{x:39.81635,y:-85.91728},
{x:38.91739,y:-90.16393},
{x:40.76799,y:-73.86211},
{x:34.7551,y:-77.42376},
{x:61.10903,y:-149.86076},
{x:34.70167,y:-77.14964},
{x:33.75954,y:-117.83369},
{x:38.96428,y:-104.79587},
{x:32.73106,y:-117.16043},
{x:29.44015,y:-95.42423},
{x:40.50057,y:-86.86777},
{x:34.90927,y:-76.93566},
{x:41.16956,y:-73.21347},
{x:25.79215,y:-80.38673},
{x:41.87028,y:-87.93557},
{x:33.12087,y:-117.08607},
{x:33.78997,y:-118.32927},
{x:26.64737,y:-80.20905},
{x:45.15282,y:-122.6051},
{x:45.31099,y:-122.93419},
{x:38.65589,y:-90.55475},
{x:38.69088,y:-89.97251},
{x:27.55184,y:-99.50068},
{x:41.6058,y:-71.45404},
{x:43.5253,y:-96.7903},
{x:32.54136,y:-94.7562},
{x:37.69474,y:-122.08628},
{x:33.83176,y:-118.29759},
{x:38.98819,y:-76.54925},
{x:35.65307,y:-97.46658},
{x:43.6933,y:-116.50218},
{x:34.00306,y:-118.16717},
{x:32.99538,y:-117.25464},
{x:34.01579,y:-118.49647},
{x:37.39816,y:-121.9237},
{x:36.17384,y:-115.0958},
{x:36.06418,y:-115.02851},
{x:35.98289,y:-115.1903},
{x:32.90868,y:-96.44866},
{x:29.91195,y:-95.48187},
{x:28.63302,y:-96.62913},
{x:33.69623,y:-117.76261},
{x:36.19604,y:-115.22427},
{x:34.43376,y:-100.22507},
{x:25.77519,y:-80.17842},
{x:35.94858,y:-84.11263},
{x:33.88768,y:-117.3304},
{x:43.01577,y:-74.80354},
{x:42.95891,y:-76.91815},
{x:43.09615,y:-75.84163},
{x:36.41557,y:-82.99038},
{x:40.76791,y:-73.86273},
{x:36.1761,y:-85.46705},
{x:33.79568,y:-118.10854},
{x:40.82038,y:-81.39867},
{x:35.00151,y:-85.25954},
{x:40.69764,y:-74.17881},
{x:40.77326,y:-73.87222},
{x:35.11971,y:-85.29147},
{x:34.21908,y:-84.84214},
{x:34.10632,y:-84.51791},
{x:36.27832,y:-80.83365},
{x:42.13306,y:-87.82052},
{x:43.02912,y:-88.18266},
{x:44.29464,y:-88.31232},
{x:37.76588,y:-122.40348},
{x:29.5672,y:-95.56666},
{x:32.71594,y:-117.16461},
{x:42.98725,y:-89.55253},
{x:42.27853,y:-72.67161},
{x:32.66202,y:-97.27899},
{x:40.4339,y:-79.77246},
{x:33.99011,y:-118.21734},
{x:43.46964,y:-110.78943},
{x:33.68604,y:-117.99041},
{x:34.07027,y:-83.98226},
{x:45.52581,y:-122.80756},
{x:40.99159,y:-73.67331},
{x:38.97657,y:-76.31913},
{x:40.16541,y:-74.05116},
{x:40.98495,y:-73.85267},
{x:37.58585,y:-122.36508},
{x:37.04849,y:-122.03194},
{x:42.67403,y:-71.12645},
{x:36.08802,y:-79.83759},
{x:38.78065,y:-90.50804},
{x:33.63101,y:-78.96283},
{x:38.9307,y:-104.79388},
{x:33.65248,y:-112.18569},
{x:38.61,y:-76.92627},
{x:37.64522,y:-120.99116},
{x:39.08834,y:-94.88064},
{x:39.36739,y:-94.37103},
{x:39.02482,y:-94.1984},
{x:34.82353,y:-82.58075},
{x:48.02194,y:-122.10895},
{x:32.77974,y:-117.03979},
{x:38.78719,y:-121.25309},
{x:34.57984,y:-92.58189},
{x:33.81845,y:-117.95993},
{x:36.01233,y:-115.12242},
{x:43.03455,y:-71.16346},
{x:42.89203,y:-71.32747},
{x:43.20405,y:-71.53399},
{x:45.03545,y:-92.82822},
{x:39.7404,y:-104.81447},
{x:39.82997,y:-105.02463},
{x:32.5461,y:-117.03176},
{x:36.02495,y:-95.94924},
{x:42.36272,y:-71.15897},
{x:35.21666,y:-101.90062},
{x:39.6252,y:-104.79008},
{x:33.92606,y:-117.87765},
{x:31.08456,y:-97.78365},
{x:38.64074,y:-121.50964},
{x:20.88803,y:-156.46505},
{x:35.66055,y:-101.40573},
{x:36.70892,y:-119.55607},
{x:38.85369,y:-104.87221},
{x:29.46725,y:-98.4668},
{x:26.13468,y:-80.28376},
{x:34.98113,y:-79.01071},
{x:33.3355,y:-111.87813},
{x:36.7943,y:-119.68266},
{x:35.12034,y:-120.60116},
{x:36.84899,y:-119.72887},
{x:42.32748,y:-83.19899},
{x:32.33198,y:-96.61814},
{x:34.17375,y:-119.16153},
{x:29.75688,y:-95.37073},
{x:40.54902,y:-81.4956},
{x:42.02547,y:-97.42707},
{x:33.62367,y:-111.89201},
{x:38.36196,y:-98.78724},
{x:31.53388,y:-97.12931},
{x:32.47959,y:-93.7199},
{x:33.65203,y:-112.23352},
{x:38.40923,y:-121.3535},
{x:32.904,y:-117.10042},
{x:27.97621,y:-82.32952},
{x:30.48555,y:-90.45631},
{x:42.50153,y:-94.15682},
{x:34.74102,y:-97.26195},
{x:42.03316,y:-91.70154},
{x:34.00565,y:-118.15261},
{x:42.86351,y:-112.44051},
{x:34.87282,y:-82.69288},
{x:36.341,y:-119.34761},
{x:33.50425,y:-82.50285},
{x:30.47942,y:-97.66917},
{x:37.75283,y:-121.14104},
{x:32.84835,y:-96.78714},
{x:44.43105,y:-88.1002},
{x:44.82024,y:-87.39796},
{x:34.12536,y:-81.22428},
{x:33.01598,y:-117.11086},
{x:35.35359,y:-119.18321},
{x:34.13498,y:-117.45242},
{x:39.76104,y:-84.1923},
{x:34.43277,y:-118.59112},
{x:42.28306,y:-85.61361},
{x:25.83695,y:-80.32269},
{x:43.01706,y:-74.80288},
{x:35.40023,y:-119.3974},
{x:39.76909,y:-105.11001},
{x:45.47308,y:-123.84467},
{x:40.30053,y:-73.97893},
{x:28.37091,y:-81.27948},
{x:36.57833,y:-94.76958},
{x:40.42215,y:-104.75016},
{x:35.70351,y:-81.29611},
{x:37.96456,y:-121.99313},
{x:25.77708,y:-80.18845},
{x:26.77497,y:-81.75791},
{x:26.6827,y:-81.90296},
{x:38.43059,y:-86.93721},
{x:30.41909,y:-90.04014},
{x:41.32428,y:-96.07204},
{x:45.54178,y:-122.41876},
{x:35.7353,y:-81.36504},
{x:40.33942,y:-76.4512},
{x:41.0927,y:-81.53472},
{x:30.27589,y:-97.80017},
{x:40.52267,y:-105.04216},
{x:34.06923,y:-117.98043},
{x:40.79411,y:-77.85919},
{x:37.93585,y:-121.69899},
{x:33.93864,y:-117.96649},
{x:32.83278,y:-96.70093},
{x:38.0134,y:-121.86811},
{x:43.56314,y:-116.35449},
{x:37.51423,y:-77.79196},
{x:42.43925,y:-83.38211},
{x:34.25991,y:-118.31305},
{x:47.70144,y:-122.36413},
{x:42.53668,y:-113.78167},
{x:32.52675,y:-92.64913},
{x:30.38737,y:-86.47108},
{x:36.09925,y:-115.29675},
{x:38.37113,y:-97.63137},
{x:35.38087,y:-119.01616},
{x:30.04223,y:-94.89381},
{x:40.39453,y:-104.6965},
{x:32.83586,y:-109.73165},
{x:32.7827,y:-96.79648},
{x:32.23639,y:-110.92721},
{x:32.43367,y:-97.78329},
{x:31.44547,y:-100.47934},
{x:40.52922,y:-111.85458},
{x:33.2629,y:-96.78645},
{x:42.73353,y:-90.44369},
{x:44.42985,y:-88.05612},
{x:33.74882,y:-118.40714},
{x:30.69656,y:-86.1215},
{x:42.08364,y:-73.96868},
{x:44.2936,y:-121.1734},
{x:38.25583,y:-77.42824},
{x:43.49104,y:-96.70698},
{x:41.20198,y:-96.23474},
{x:36.24142,y:-119.34865},
{x:37.27468,y:-120.43304},
{x:36.82147,y:-119.87052},
{x:36.34168,y:-119.25115},
{x:37.52037,y:-120.84855},
{x:38.6585,y:-120.97406},
{x:33.18595,y:-96.97726},
{x:39.45813,y:-76.66406},
{x:33.61499,y:-84.6142},
{x:44.88412,y:-123.01188},
{x:35.90513,y:-78.78224},
{x:37.44335,y:-79.12027},
{x:37.27065,y:-121.94611},
{x:32.73127,y:-97.79142},
{x:39.11075,y:-76.75789},
{x:29.95998,y:-95.53896},
{x:33.47561,y:-81.98945},
{x:34.9369,y:-120.43643},
{x:43.23193,y:-75.43788},
{x:31.26161,y:-90.47685},
{x:39.05458,y:-76.9563},
{x:39.963,y:-75.16168},
{x:28.35838,y:-81.49305},
{x:39.09512,y:-120.95024},
{x:42.72016,y:-84.4574},
{x:35.29258,y:-80.74572},
{x:36.86981,y:-82.77255},
{x:37.77705,y:-122.41737},
{x:41.50195,y:-81.67548},
{x:36.21705,y:-83.28045},
{x:44.14659,y:-93.99989},
{x:36.34656,y:-84.15943},
{x:36.10168,y:-115.17504},
{x:40.40053,y:-104.69946},
{x:42.96783,y:-85.68155},
{x:42.86353,y:-112.43164},
{x:36.20836,y:-83.32479},
{x:38.81494,y:-121.25831},
{x:35.30551,y:-80.73263},
{x:21.27882,y:-157.83257},
{x:35.96676,y:-83.1819},
{x:33.96514,y:-98.67121},
{x:33.9577,y:-118.2321},
{x:34.03427,y:-117.3201},
{x:34.07559,y:-117.55827},
{x:32.45853,y:-111.21669},
{x:35.99865,y:-115.18519},
{x:28.94824,y:-81.25134},
{x:27.33909,y:-82.45133},
{x:34.9168,y:-88.50751},
{x:35.08784,y:-81.66145},
{x:29.68664,y:-98.1258},
{x:33.69892,y:-111.88884},
{x:40.76577,y:-73.93093},
{x:40.68443,y:-74.18727},
{x:44.12372,y:-70.22325},
{x:39.87084,y:-84.13346},
{x:40.92801,y:-73.85142},
{x:36.03735,y:-75.67302},
{x:33.6371,y:-112.35372},
{x:32.90704,y:-117.17239},
{x:40.6191,y:-74.2438},
{x:37.69922,y:-122.12634},
{x:33.97592,y:-117.48554},
{x:41.59227,y:-74.08837},
{x:42.33625,y:-71.10054},
{x:42.41757,y:-73.80793},
{x:38.97372,y:-105.03988},
{x:32.93032,y:-96.81978},
{x:29.54151,y:-95.14882},
{x:33.03587,y:-83.92851},
{x:33.90241,y:-118.09929},
{x:35.23176,y:-97.49613},
{x:31.88885,y:-102.35325},
{x:33.52344,y:-112.04715},
{x:33.39326,y:-111.87846},
{x:35.31125,y:-120.83113},
{x:33.36682,y:-97.18189},
{x:33.17029,y:-96.80352},
{x:34.55761,y:-118.08353},
{x:34.75999,y:-84.99173},
{x:41.51204,y:-71.68753},
{x:36.06252,y:-115.0467},
{x:35.64412,y:-117.67029},
{x:35.34406,y:-80.17641},
{x:35.75296,y:-81.28784},
{x:43.85891,y:-91.2418},
{x:47.82828,y:-122.26801},
{x:42.96597,y:-74.18749},
{x:42.37327,y:-71.1197},
{x:34.00773,y:-118.22029},
{x:42.55081,y:-70.93463},
{x:36.62186,y:-79.40512},
{x:34.2014,y:-118.46827},
{x:29.99786,y:-95.17796},
{x:33.37242,y:-86.81161},
{x:29.97735,y:-95.6753},
{x:42.7856,y:-78.81162},
{x:39.94742,y:-82.00368},
{x:33.42172,y:-86.69929},
{x:33.58441,y:-102.36795},
{x:34.18722,y:-101.74756},
{x:33.30441,y:-111.98359},
{x:42.18679,y:-85.60334},
{x:42.69405,y:-73.68782},
{x:33.48566,y:-111.9093},
{x:32.60146,y:-117.0641},
{x:37.60969,y:-120.97469},
{x:37.95786,y:-121.29088},
{x:37.99398,y:-122.0321},
{x:37.92867,y:-122.3213},
{x:30.05002,y:-95.2543},
{x:36.80889,y:-119.78352},
{x:29.54051,y:-95.12842},
{x:36.87346,y:-119.77667},
{x:36.81375,y:-119.79074},
{x:43.00851,y:-78.20714},
{x:33.57082,y:-117.23335},
{x:39.92772,y:-86.01236},
{x:42.50181,y:-83.08486},
{x:40.69094,y:-74.29549},
{x:33.946,y:-117.38638},
{x:41.43626,y:-75.61849},
{x:40.58541,y:-80.03658},
{x:26.1265,y:-97.63773},
{x:40.58604,y:-122.3982},
{x:33.84277,y:-117.53302},
{x:42.58431,y:-70.96527},
{x:36.10252,y:-79.78342},
{x:34.10429,y:-118.15286},
{x:44.25122,y:-84.22471},
{x:34.08107,y:-117.719},
{x:40.41266,y:-111.77312},
{x:34.72536,y:-81.63946},
{x:32.22948,y:-110.95739},
{x:34.67474,y:-118.16793},
{x:34.67529,y:-118.11435},
{x:38.55079,y:-121.53847},
{x:37.79604,y:-79.42223},
{x:38.12389,y:-79.06589},
{x:41.29107,y:-96.2043},
{x:41.57471,y:-90.50728},
{x:28.89695,y:-99.11212},
{x:33.57045,y:-117.71425},
{x:33.84699,y:-117.9896},
{x:33.75853,y:-117.98997},
{x:38.95486,y:-119.94452},
{x:40.74241,y:-74.24598},
{x:38.66384,y:-121.30766},
{x:43.93786,y:-90.82135},
{x:28.96869,y:-95.37139},
{x:29.96135,y:-95.65078},
{x:33.14039,y:-117.09382},
{x:33.15456,y:-96.94641},
{x:32.62911,y:-117.03975},
{x:37.2507,y:-121.86592},
{x:34.95323,y:-120.41616},
{x:34.22335,y:-118.23688},
{x:37.06812,y:-81.75693},
{x:39.71641,y:-105.08099},
{x:41.76668,y:-72.56992},
{x:35.88511,y:-77.54709},
{x:31.0865,y:-97.45774},
{x:26.12356,y:-98.21743},
{x:27.79635,y:-97.4276},
{x:32.78531,y:-96.80142},
{x:38.89912,y:-77.02774},
{x:25.7919,y:-80.27369},
{x:21.28215,y:-157.83653},
{x:33.53424,y:-111.96428},
{x:35.15761,y:-78.97399},
{x:34.72809,y:-92.22092},
{x:46.79029,y:-123.01056},
{x:42.34939,y:-71.10311},
{x:33.91654,y:-117.88479},
{x:41.21158,y:-79.37814},
{x:35.30012,y:-120.65878},
{x:30.49157,y:-81.68572},
{x:35.18927,y:-111.65586},
{x:35.95665,y:-83.92891},
{x:31.9703,y:-81.6428},
{x:37.78736,y:-122.40732},
{x:38.8338,y:-77.30718},
{x:35.08013,y:-92.45671},
{x:36.1204,y:-115.17358},
{x:37.63082,y:-77.67328},
{x:41.88014,y:-87.62687},
{x:36.08277,y:-115.13938},
{x:29.88917,y:-97.94438},
{x:35.20914,y:-97.44417},
{x:32.23295,y:-110.95242},
{x:27.57354,y:-99.43518},
{x:38.83637,y:-77.01961},
{x:36.04164,y:-115.18371},
{x:32.70374,y:-117.15841},
{x:33.90588,y:-84.38046},
{x:20.68738,y:-156.4436},
{x:33.45363,y:-112.07213},
{x:33.49815,y:-86.80688},
{x:33.87889,y:-117.88313},
{x:37.74612,y:-92.11317},
{x:39.73915,y:-105.19275},
{x:39.95495,y:-74.16127},
{x:42.96461,y:-74.19066},
{x:35.65484,y:-97.47141},
{x:30.49049,y:-81.68628},
{x:38.80477,y:-77.04569},
{x:38.91051,y:-77.04363},
{x:37.37382,y:-122.03224},
{x:42.12294,y:-88.33712},
{x:41.5922,y:-88.20082},
{x:42.31609,y:-88.27606},
{x:39.39148,y:-84.51402},
{x:40.45557,y:-80.00694},
{x:44.26166,y:-88.40945},
{x:39.9546,y:-75.16279},
{x:41.7058,y:-81.26669},
{x:41.04524,y:-81.69576},
{x:30.84741,y:-83.29011},
{x:40.19728,y:-85.40887},
{x:38.9102,y:-77.0747},
{x:37.54472,y:-77.4296},
{x:28.43518,y:-81.30115},
{x:39.06219,y:-96.82639},
{x:30.40625,y:-88.9097},
{x:38.00389,y:-84.44447},
{x:32.40071,y:-110.95787},
{x:33.31667,y:-112.00542},
{x:38.85623,y:-84.61703},
{x:32.99397,y:-111.52229},
{x:40.90571,y:-73.11507},
{x:36.10741,y:-80.09494},
{x:38.92988,y:-77.2278},
{x:33.50739,y:-112.23606},
{x:26.12612,y:-80.10394},
{x:37.61811,y:-122.38691},
{x:33.78925,y:-84.32681},
{x:39.71419,y:-86.29614},
{x:34.39466,y:-118.46476},
{x:39.09674,y:-108.60719},
{x:30.36257,y:-87.35936},
{x:36.17566,y:-85.50424},
{x:39.02998,y:-76.5041},
{x:45.53037,y:-122.68474},
{x:44.80425,y:-93.18662},
{x:47.63482,y:-122.20086},
{x:36.29935,y:-94.17265},
{x:28.3314,y:-81.6194},
{x:34.23746,y:-84.45792},
{x:33.94521,y:-83.75535},
{x:41.25355,y:-95.88095},
{x:41.86686,y:-87.883},
{x:36.28556,y:-115.13491},
{x:39.9358,y:-82.79239},
{x:39.3812,y:-84.21271},
{x:39.14496,y:-84.61993},
{x:36.0701,y:-95.7895},
{x:40.05116,y:-75.05932},
{x:40.05295,y:-75.66494},
{x:35.10249,y:-89.86349},
{x:33.12335,y:-96.66135},
{x:29.60233,y:-95.26487},
{x:33.06398,y:-96.88456},
{x:30.46704,y:-97.59819},
{x:29.84634,y:-97.97102},
{x:37.5031,y:-77.7191},
{x:43.17104,y:-89.26752},
{x:44.01078,y:-123.08865},
{x:48.54755,y:-117.90646},
{x:38.21563,y:-85.20628},
{x:39.06395,y:-84.70678},
{x:36.81207,y:-119.74915},
{x:37.73961,y:-84.30057},
{x:33.14572,y:-97.10216},
{x:64.8252,y:-147.63448},
{x:40.42453,y:-79.66196},
{x:40.03431,y:-83.13343},
{x:40.34095,y:-80.1184},
{x:39.01605,y:-76.69365},
{x:43.86954,y:-91.19538},
{x:44.48539,y:-88.06432},
{x:39.21429,y:-76.88572},
{x:42.71739,y:-88.99235},
{x:30.21376,y:-92.01695},
{x:30.21167,y:-85.84982},
{x:41.67239,y:-86.31717},
{x:42.39474,y:-71.59562},
{x:32.99254,y:-96.56912},
{x:38.99293,y:-77.03202},
{x:28.27503,y:-81.66271},
{x:28.26854,y:-82.28539},
{x:28.60417,y:-81.55091},
{x:33.62065,y:-117.73109},
{x:41.05927,y:-83.65846},
{x:41.86447,y:-71.44885},
{x:35.34742,y:-97.49656},
{x:29.34083,y:-98.55476},
{x:40.88096,y:-74.04136},
{x:39.69508,y:-86.07506},
{x:35.6154,y:-88.81979},
{x:39.09842,y:-94.58301},
{x:39.01659,y:-77.20902},
{x:35.77632,y:-78.43283},
{x:37.08444,y:-94.53157},
{x:33.98457,y:-118.16188},
{x:38.34009,y:-81.67769},
{x:39.94084,y:-104.90584},
{x:40.16082,y:-83.09332},
{x:40.12687,y:-84.62268},
{x:34.74458,y:-86.7848},
{x:33.69865,y:-112.41579},
{x:41.74966,y:-88.11276},
{x:41.77541,y:-87.97887},
{x:36.77634,y:-108.69923},
{x:43.08527,y:-89.27587},
{x:33.91962,y:-84.46826},
{x:18.44241,y:-66.01801},
{x:34.0452,y:-118.2321},
{x:43.17403,y:-89.4612},
{x:40.72879,y:-73.09441},
{x:41.39623,y:-74.33381},
{x:42.73156,y:-78.82499},
{x:34.13617,y:-117.23173},
{x:38.90658,y:-77.25942},
{x:28.37252,y:-81.54614},
{x:32.68471,y:-117.12853},
{x:41.93071,y:-87.74631},
{x:45.88616,y:-89.70372},
{x:45.63496,y:-89.37898},
{x:44.49833,y:-87.99181},
{x:43.25069,y:-89.37142},
{x:34.48292,y:-82.66469},
{x:47.23925,y:-122.35855},
{x:43.5375,y:-96.65348},
{x:44.26758,y:-105.51019},
{x:35.79285,y:-83.99002},
{x:38.83441,y:-77.413},
{x:40.15563,y:-74.42833},
{x:39.97103,y:-74.87639},
{x:27.52354,y:-97.88178},
{x:43.595,y:-83.89137},
{x:32.3975,y:-93.80941},
{x:33.24896,y:-95.91068},
{x:35.06297,y:-106.19195},
{x:33.71086,y:-112.20159},
{x:36.18409,y:-86.63767},
{x:40.5075,y:-112.01135},
{x:41.41854,y:-88.26353},
{x:38.9864,y:-74.82682},
{x:27.91671,y:-82.72706},
{x:34.13771,y:-117.45084},
{x:42.55493,y:-82.90002},
{x:40.44135,y:-80.00155},
{x:38.97602,y:-76.54469},
{x:32.79942,y:-79.94282},
{x:40.77308,y:-73.87142},
{x:25.77758,y:-80.18628},
{x:35.9638,y:-83.59233},
{x:42.91285,y:-78.76351},
{x:36.00094,y:-84.92183},
{x:38.24022,y:-121.98919},
{x:38.32072,y:-122.29877},
{x:42.19325,y:-121.75784},
{x:37.31944,y:-120.50396},
{x:36.2979,y:-119.29361},
{x:36.73425,y:-119.73596},
{x:36.72862,y:-108.21588},
{x:30.5666,y:-97.68949},
{x:30.25748,y:-97.80716},
{x:39.0629,y:-77.12754},
{x:39.15654,y:-76.72423},
{x:28.38725,y:-81.49202},
{x:40.08934,y:-75.38689},
{x:35.36877,y:-80.72045},
{x:30.25846,y:-81.52549},
{x:26.40403,y:-81.80871},
{x:36.2025,y:-86.69248},
{x:37.1628,y:-93.265},
{x:40.63106,y:-75.47969},
{x:36.06296,y:-95.88141},
{x:32.70982,y:-97.39935},
{x:40.54808,y:-74.33557},
{x:41.13953,y:-80.15662},
{x:29.82997,y:-97.97902},
{x:42.70054,y:-71.43646},
{x:26.16181,y:-97.88789},
{x:42.24053,y:-83.74639},
{x:39.3558,y:-74.43577},
{x:38.63289,y:-90.18487},
{x:42.71912,y:-71.11235},
{x:32.17312,y:-111.06338},
{x:30.53023,y:-97.8456},
{x:38.97975,y:-77.34964},
{x:41.6999,y:-81.27639},
{x:29.58416,y:-98.61791},
{x:46.21387,y:-119.19274},
{x:32.72485,y:-97.42076},
{x:45.98761,y:-112.5064},
{x:47.60086,y:-117.36735},
{x:32.79989,y:-96.97478},
{x:39.29787,y:-102.27879},
{x:29.75601,y:-95.36245},
{x:42.3763,y:-122.90431},
{x:42.97277,y:-77.17362},
{x:37.73223,y:-84.28084},
{x:38.08454,y:-84.48988},
{x:42.99935,y:-71.51403},
{x:39.64969,y:-79.90223},
{x:40.70568,y:-79.72432},
{x:33.95348,y:-78.05085},
{x:41.76454,y:-88.18507},
{x:41.89743,y:-87.62573},
{x:43.46173,y:-96.79926},
{x:33.8954,y:-117.47162},
{x:44.90836,y:-89.58883},
{x:32.79428,y:-117.25407},
{x:36.18441,y:-86.74701},
{x:40.71102,y:-74.00093},
{x:33.80462,y:-117.91147},
{x:30.45422,y:-91.09298},
{x:31.31992,y:-95.43717},
{x:30.10371,y:-81.54138},
{x:39.97668,y:-86.00304},
{x:34.17842,y:-82.15204},
{x:31.46203,y:-97.17114},
{x:42.6957,y:-88.54259},
{x:45.10118,y:-92.53945},
{x:44.1778,y:-94.03412},
{x:40.32103,y:-74.48819},
{x:39.60296,y:-87.68703},
{x:39.99979,y:-86.00492},
{x:36.38052,y:-79.66302},
{x:32.61642,y:-83.68584},
{x:33.45442,y:-111.68272},
{x:29.87292,y:-95.75863},
{x:33.96527,y:-118.35298},
{x:40.75995,y:-74.15867},
{x:34.18783,y:-118.39744},
{x:25.48025,y:-80.43813},
{x:21.42036,y:-157.80613},
{x:32.53534,y:-96.65833},
{x:33.99658,y:-96.40082},
{x:29.87962,y:-95.64139},
{x:38.88233,y:-77.29991},
{x:40.09001,y:-75.37074},
{x:32.66258,y:-83.74075},
{x:31.68012,y:-106.31495},
{x:34.81142,y:-102.40497},
{x:38.87969,y:-77.10791},
{x:40.94354,y:-72.84247},
{x:32.17976,y:-110.96854},
{x:41.53244,y:-93.59637},
{x:27.80825,y:-82.63831},
{x:47.68207,y:-122.31682},
{x:39.1985,y:-121.06051},
{x:33.2324,y:-111.72295},
{x:39.04873,y:-84.55263},
{x:26.23125,y:-98.32384},
{x:30.43033,y:-84.24297},
{x:30.23273,y:-92.38469},
{x:40.48678,y:-79.88716},
{x:37.01307,y:-121.58518},
{x:38.93354,y:-77.17847},
{x:42.40997,y:-71.00155},
{x:33.8324,y:-118.3493},
{x:37.4143,y:-121.89582},
{x:37.96773,y:-122.06077},
{x:37.69516,y:-121.92934},
{x:34.07294,y:-117.55439},
{x:27.79365,y:-82.73328},
{x:42.7672,y:-71.2317},
{x:38.64249,y:-77.29905},
{x:25.64463,y:-80.33889},
{x:32.96457,y:-96.46224},
{x:35.92569,y:-84.03745},
{x:30.8057,y:-97.6165},
{x:38.4227,y:-82.43054},
{x:32.45206,y:-93.8669},
{x:41.68407,y:-86.25055},
{x:37.66339,y:-97.55694},
{x:38.96324,y:-77.07488},
{x:34.68442,y:-86.71667},
{x:33.48462,y:-82.13296},
{x:41.36854,y:-71.81898},
{x:39.64363,y:-84.26621},
{x:47.46745,y:-121.7166},
{x:29.34818,y:-99.12233},
{x:42.24767,y:-71.72475},
{x:35.34019,y:-118.8425},
{x:35.20945,y:-118.82791},
{x:37.52074,y:-120.8886},
{x:36.93878,y:-120.05556},
{x:35.4274,y:-119.11061},
{x:37.68973,y:-121.01094},
{x:47.73967,y:-117.48822},
{x:42.03497,y:-91.65239},
{x:28.39264,y:-81.24382},
{x:35.41525,y:-80.66747},
{x:35.10881,y:-80.88115},
{x:39.03449,y:-77.39816},
{x:40.96607,y:-73.83875},
{x:33.89901,y:-117.91207},
{x:43.09237,y:-89.35987},
{x:43.14846,y:-77.57839},
{x:35.29746,y:-82.41028},
{x:33.74745,y:-117.83383},
{x:32.16478,y:-110.94298},
{x:33.64012,y:-86.62133},
{x:33.54211,y:-83.20788},
{x:33.89976,y:-83.96088},
{x:33.69933,y:-84.57938},
{x:27.01036,y:-82.14176},
{x:38.77994,y:-77.23545},
{x:32.76766,y:-117.16627},
{x:27.53428,y:-82.50722},
{x:25.78667,y:-80.36505},
{x:40.46778,y:-80.09769},
{x:34.07344,y:-117.55218},
{x:35.18063,y:-83.35583},
{x:44.97391,y:-93.26507},
{x:37.69865,y:-121.84555},
{x:30.36081,y:-87.35968},
{x:33.52283,y:-80.88543},
{x:40.75463,y:-74.00044},
{x:38.89414,y:-77.0217},
{x:40.44248,y:-79.96063},
{x:41.49805,y:-81.69256},
{x:40.3639,y:-74.65188},
{x:35.95964,y:-115.14219},
{x:39.75801,y:-84.12213},
{x:32.89842,y:-83.69129},
{x:40.31261,y:-76.42651},
{x:43.22278,y:-77.6617},
{x:45.02926,y:-93.33442},
{x:39.27122,y:-84.32593},
{x:36.65901,y:-76.23193},
{x:38.41457,y:-82.28872},
{x:41.69884,y:-72.89495},
{x:38.89655,y:-77.09657},
{x:40.83044,y:-73.68844},
{x:42.41475,y:-71.15099},
{x:40.56961,y:-74.10964},
{x:40.34988,y:-74.6594},
{x:40.94592,y:-73.79568},
{x:38.89449,y:-77.18833},
{x:40.94576,y:-73.0683},
{x:42.3495,y:-71.09994},
{x:36.12524,y:-115.17077},
{x:42.35115,y:-71.10896},
{x:30.46645,y:-86.5298},
{x:29.95202,y:-90.0679},
{x:33.21449,y:-87.54586},
{x:33.53815,y:-112.3557},
{x:41.92713,y:-87.67437},
{x:37.78844,y:-122.43464},
{x:41.8644,y:-87.63988},
{x:40.75959,y:-73.98524},
{x:39.83792,y:-86.17208},
{x:28.55221,y:-81.20179},
{x:40.72097,y:-73.84736},
{x:34.06367,y:-84.39717},
{x:40.41773,y:-86.85308},
{x:45.7837,y:-122.54942},
{x:47.20042,y:-122.52879},
{x:32.70802,y:-117.16345},
{x:33.10471,y:-96.81245},
{x:33.0305,y:-96.82697},
{x:32.88969,y:-97.2568},
{x:32.83666,y:-97.18383},
{x:41.7099,y:-93.57877},
{x:41.82755,y:-71.40074},
{x:42.07929,y:-87.81714},
{x:38.91503,y:-77.06752},
{x:40.81514,y:-73.4764},
{x:29.53632,y:-98.5753},
{x:36.17101,y:-115.1451},
{x:36.11049,y:-115.17173},
{x:47.04645,y:-122.87497},
{x:36.10237,y:-115.17088},
{x:45.53006,y:-122.42932},
{x:40.81308,y:-73.98469},
{x:44.65131,y:-124.05126},
{x:46.16013,y:-123.90262},
{x:45.44124,y:-122.7516},
{x:45.26644,y:-122.67746},
{x:29.73099,y:-95.43263},
{x:37.94564,y:-122.52445},
{x:37.52925,y:-122.29001},
{x:42.33856,y:-71.10623},
{x:40.14203,y:-82.99623},
{x:41.97525,y:-87.90003},
{x:40.99074,y:-73.82296},
{x:40.71712,y:-74.35767},
{x:42.29324,y:-71.23509},
{x:29.5807,y:-98.34282},
{x:42.22319,y:-71.22266},
{x:35.24705,y:-81.67136},
{x:41.48767,y:-71.3149},
{x:38.94466,y:-77.09565},
{x:41.07873,y:-73.46889},
{x:33.94545,y:-118.40334},
{x:38.99552,y:-77.07743},
{x:38.94547,y:-77.4435},
{x:41.97633,y:-87.9054},
{x:41.01456,y:-73.98193},
{x:41.97466,y:-87.90033},
{x:41.97905,y:-87.90682},
{x:41.97594,y:-87.90228},
{x:39.89797,y:-75.03164},
{x:28.43129,y:-81.30662},
{x:28.42744,y:-81.30128},
{x:40.78952,y:-73.53445},
{x:35.21853,y:-80.94238},
{x:38.74408,y:-90.36763},
{x:38.74273,y:-90.3662},
{x:39.05062,y:-84.65629},
{x:41.97772,y:-87.90701},
{x:21.3312,y:-157.91743},
{x:37.58231,y:-77.49865},
{x:35.2184,y:-80.94521},
{x:41.97473,y:-87.90828},
{x:40.03074,y:-78.49231},
{x:38.77342,y:-77.13726},
{x:39.55063,y:-74.73605},
{x:40.79559,y:-74.3157},
{x:26.68734,y:-80.09258},
{x:26.6869,y:-80.0894},
{x:40.70328,y:-74.01412},
{x:38.10102,y:-81.38308},
{x:40.79768,y:-73.70001},
{x:38.9456,y:-77.45118},
{x:28.36076,y:-81.50885},
{x:40.71881,y:-73.58968},
{x:25.79196,y:-80.2766},
{x:25.7973,y:-80.27831},
{x:41.61737,y:-70.49042},
{x:41.24547,y:-73.19913},
{x:33.92162,y:-83.34379},
{x:38.41538,y:-82.303},
{x:29.7968,y:-95.48644},
{x:37.35408,y:-79.22674},
{x:42.72623,y:-83.199},
{x:39.22979,y:-84.25396},
{x:39.3505,y:-84.22037},
{x:33.23498,y:-111.79144},
{x:40.35075,y:-74.06557},
{x:34.37337,y:-83.87037},
{x:34.11254,y:-84.55547},
{x:37.75104,y:-97.2638},
{x:37.8099,y:-92.22519},
{x:35.86262,y:-84.06925},
{x:40.74115,y:-74.00129},
{x:35.94257,y:-84.09361},
{x:33.50939,y:-82.10831},
{x:34.24871,y:-85.1635},
{x:32.68272,y:-97.19352},
{x:39.2271,y:-84.39751},
{x:34.06408,y:-84.07249},
{x:29.50775,y:-95.0414},
{x:40.86125,y:-73.97041},
{x:33.49382,y:-111.90748},
{x:42.90513,y:-85.79494},
{x:33.11591,y:-96.65003},
{x:33.23295,y:-111.68723},
{x:33.37768,y:-111.62075},
{x:42.31947,y:-83.49145},
{x:40.31289,y:-83.11043},
{x:40.77621,y:-82.47518},
{x:39.98268,y:-82.02144},
{x:37.50472,y:-77.66416},
{x:35.50011,y:-82.5237},
{x:41.17568,y:-73.22893},
{x:40.83774,y:-73.7017},
{x:47.79086,y:-122.29069},
{x:47.59672,y:-122.14877},
{x:47.44195,y:-122.33554},
{x:48.24065,y:-122.35943},
{x:47.8205,y:-122.3349},
{x:35.87271,y:-86.3525},
{x:42.45546,y:-71.06484},
{x:34.12223,y:-83.60641},
{x:37.69268,y:-97.46433},
{x:29.39842,y:-95.24976},
{x:29.56031,y:-95.34891},
{x:41.68696,y:-83.72452},
{x:47.38812,y:-122.04506},
{x:38.84872,y:-104.84732},
{x:38.45282,y:-107.86489},
{x:33.07112,y:-112.04653},
{x:33.66846,y:-112.27824},
{x:32.30673,y:-111.01027},
{x:33.50964,y:-112.33947},
{x:35.56734,y:-77.34977},
{x:39.08737,y:-108.46137},
{x:32.2343,y:-110.82572},
{x:33.5809,y:-111.88969},
{x:33.50705,y:-112.04125},
{x:41.29448,y:-96.11642},
{x:38.92575,y:-95.26365},
{x:41.16523,y:-96.04485},
{x:41.57301,y:-90.52639},
{x:40.62284,y:-111.84999},
{x:40.56727,y:-111.89317},
{x:38.91053,y:-94.76442},
{x:38.9081,y:-94.67466},
{x:39.01025,y:-94.76801},
{x:42.03533,y:-91.6495},
{x:39.95633,y:-105.17356},
{x:32.67866,y:-97.41249},
{x:41.94431,y:-91.71735},
{x:33.43177,y:-86.71308},
{x:33.60286,y:-86.63779},
{x:44.78107,y:-91.43338},
{x:32.98264,y:-96.76962},
{x:41.84902,y:-87.95425},
{x:41.88371,y:-87.62722},
{x:41.88433,y:-87.62645},
{x:47.88707,y:-97.07997},
{x:32.86571,y:-96.74512},
{x:29.54785,y:-98.66357},
{x:29.59633,y:-95.62747},
{x:34.08306,y:-84.54493},
{x:41.23597,y:-96.19348},
{x:29.69282,y:-95.41835},
{x:32.68383,y:-97.13608},
{x:34.74115,y:-86.68205},
{x:42.46479,y:-92.32528},
{x:32.43975,y:-93.71965},
{x:39.95967,y:-86.00988},
{x:44.06312,y:-92.50354},
{x:37.55548,y:-77.48888},
{x:29.61174,y:-98.51055},
{x:39.66769,y:-86.08486},
{x:28.36986,y:-81.4073},
{x:41.64741,y:-93.76658},
{x:45.05584,y:-93.14471},
{x:39.76064,y:-86.34226},
{x:39.91649,y:-104.97459},
{x:39.61114,y:-105.10121},
{x:44.82768,y:-93.60057},
{x:38.29375,y:-77.51828},
{x:44.91638,y:-93.50484},
{x:39.6034,y:-86.15774},
{x:39.93627,y:-86.23266},
{x:34.67782,y:-86.54818},
{x:30.38462,y:-91.06115},
{x:29.56597,y:-95.56779},
{x:32.88038,y:-97.1044},
{x:35.90011,y:-84.15168},
{x:30.67407,y:-88.22844},
{x:29.53892,y:-95.02248},
{x:28.17319,y:-82.35529},
{x:38.93131,y:-94.40912},
{x:34.18588,y:-84.13476},
{x:33.01214,y:-96.97098},
{x:35.65037,y:-97.46386},
{x:33.88543,y:-84.74474},
{x:33.19249,y:-87.51856},
{x:44.97491,y:-93.27429},
{x:35.61242,y:-97.54704},
{x:36.06239,y:-95.85676},
{x:42.53757,y:-83.11353},
{x:39.2475,y:-94.46377},
{x:41.71816,y:-86.17603},
{x:45.18299,y:-93.10546},
{x:45.19764,y:-93.55465},
{x:33.46739,y:-84.217},
{x:29.6504,y:-95.15222},
{x:29.55378,y:-95.3835},
{x:44.72995,y:-93.21431},
{x:41.9205,y:-88.26478},
{x:44.68469,y:-93.2894},
{x:45.03134,y:-93.45134},
{x:30.01888,y:-95.26804},
{x:30.16039,y:-92.04938},
{x:39.71514,y:-104.8213},
{x:42.47482,y:-71.02448},
{x:39.73385,y:-105.16048},
{x:32.93988,y:-96.69716},
{x:38.88839,y:-104.71805},
{x:30.05028,y:-95.43183},
{x:42.62353,y:-82.9856},
{x:45.51611,y:-122.56248},
{x:33.06751,y:-97.08317},
{x:32.90778,y:-97.18809},
{x:38.78698,y:-77.29681},
{x:42.3095,y:-71.21439},
{x:44.91811,y:-92.93289},
{x:41.70114,y:-86.21779},
{x:43.56836,y:-116.18713},
{x:47.54854,y:-122.03944},
{x:45.55147,y:-122.90292},
{x:64.83554,y:-147.81817},
{x:41.05709,y:-74.14255},
{x:46.99114,y:-120.55102},
{x:42.95177,y:-85.61431},
{x:43.11495,y:-85.54725},
{x:42.92996,y:-85.60848},
{x:42.2593,y:-85.61458},
{x:42.91412,y:-85.50913},
{x:45.07422,y:-93.01711},
{x:41.73702,y:-71.30527},
{x:47.65273,y:-117.36166},
{x:42.80336,y:-86.10885},
{x:46.9985,y:-122.91496},
{x:61.13601,y:-149.83801},
{x:43.60356,y:-116.24553},
{x:39.19494,y:-94.57994},
{x:44.04907,y:-123.14304},
{x:39.07683,y:-76.54309},
{x:41.82103,y:-86.24847},
{x:42.88327,y:-85.76122},
{x:47.67085,y:-122.10429},
{x:41.68897,y:-85.9649},
{x:41.75169,y:-86.11883},
{x:42.07871,y:-86.49397},
{x:45.52382,y:-122.69297},
{x:45.45856,y:-122.58067},
{x:47.04115,y:-122.83099},
{x:43.0436,y:-86.21424},
{x:41.62705,y:-86.23697},
{x:37.15709,y:-93.22892},
{x:37.04655,y:-93.3011},
{x:37.13967,y:-93.32138},
{x:40.72401,y:-74.30736},
{x:30.21053,y:-95.52809},
{x:29.60301,y:-95.21476},
{x:29.52128,y:-95.16002},
{x:29.7579,y:-95.77815},
{x:29.69585,y:-95.41522},
{x:29.90387,y:-95.55553},
{x:40.74016,y:-73.98697},
{x:34.24722,y:-117.20887},
{x:37.69853,y:-121.04816},
{x:61.34592,y:-149.55249},
{x:61.57946,y:-149.40895},
{x:47.20073,y:-122.29616},
{x:40.72094,y:-122.92698},
{x:30.18537,y:-95.49031},
{x:40.93218,y:-73.7596},
{x:40.49937,y:-79.84423},
{x:40.43257,y:-79.77228},
{x:46.14859,y:-122.96456},
{x:61.13618,y:-149.91969},
{x:47.20408,y:-122.22801},
{x:40.18635,y:-80.22377},
{x:61.19426,y:-149.8652},
{x:42.99664,y:-85.594},
{x:42.48637,y:-71.21213},
{x:33.23176,y:-97.13772},
{x:45.67665,y:-122.55102},
{x:45.60551,y:-122.50141},
{x:45.30575,y:-122.94415},
{x:44.74367,y:-85.64371},
{x:43.03887,y:-86.21737},
{x:38.90003,y:-77.03224},
{x:40.96069,y:-74.24015},
{x:40.08916,y:-82.82559},
{x:39.90679,y:-82.77913},
{x:38.90362,y:-77.04098},
{x:42.35726,y:-83.48149},
{x:42.76264,y:-84.50033},
{x:40.76075,y:-73.98369},
{x:40.72038,y:-82.54824},
{x:30.00287,y:-95.53183},
{x:29.64791,y:-95.6497},
{x:40.56353,y:-83.11883},
{x:42.25511,y:-84.35988},
{x:40.40061,y:-82.45905},
{x:42.91546,y:-85.53204},
{x:42.41726,y:-71.1264},
{x:39.97309,y:-86.15188},
{x:41.13359,y:-85.01308},
{x:37.69231,y:-97.24616},
{x:38.9422,y:-95.24849},
{x:38.97247,y:-95.27164},
{x:40.4295,y:-79.81011},
{x:39.35694,y:-84.3763},
{x:41.25117,y:-95.98694},
{x:29.44985,y:-95.07951},
{x:40.48114,y:-104.92773},
{x:41.50143,y:-81.49994},
{x:40.74184,y:-73.99323},
{x:40.06438,y:-83.09543},
{x:39.31756,y:-84.31547},
{x:39.52547,y:-104.94028},
{x:41.20701,y:-96.19413},
{x:39.76449,y:-105.14015},
{x:38.68532,y:-77.31367},
{x:41.39195,y:-83.64912},
{x:41.70485,y:-83.58691},
{x:38.05139,y:-78.50308},
{x:35.04721,y:-78.92299},
{x:41.54529,y:-83.59835},
{x:45.40317,y:-122.29247},
{x:36.08879,y:-79.89091},
{x:40.59274,y:-79.75017},
{x:40.1016,y:-83.15765},
{x:43.53066,y:-96.67254},
{x:40.10283,y:-83.16138},
{x:37.13614,y:-93.2766},
{x:30.18256,y:-95.53568},
{x:41.42961,y:-73.41681},
{x:41.59825,y:-93.80792},
{x:38.95373,y:-92.3836},
{x:48.74339,y:-122.46412},
{x:43.50816,y:-96.77355},
{x:40.14957,y:-82.92104},
{x:40.03074,y:-84.22231},
{x:40.33312,y:-74.06129},
{x:33.4394,y:-84.58859},
{x:40.00163,y:-105.10177},
{x:38.87719,y:-104.84856},
{x:42.02189,y:-93.66976},
{x:42.02026,y:-93.61963},
{x:39.05602,y:-77.11815},
{x:39.3586,y:-84.45852},
{x:39.63379,y:-106.51749},
{x:40.14324,y:-82.92379},
{x:37.66167,y:-121.00404},
{x:39.7043,y:-84.12473},
{x:47.62228,y:-122.3211},
{x:40.7461,y:-74.25984},
{x:45.57588,y:-122.68111},
{x:44.90591,y:-123.04138},
{x:44.57538,y:-123.27421},
{x:42.03716,y:-91.57243},
{x:43.11944,y:-85.60208},
{x:47.62964,y:-122.14419},
{x:35.92938,y:-79.02638},
{x:47.61383,y:-122.32119},
{x:41.4527,y:-96.48852},
{x:35.47901,y:-80.89432},
{x:40.06289,y:-105.92916},
{x:37.60478,y:-77.59136},
{x:42.51671,y:-90.74416},
{x:41.34518,y:-86.29784},
{x:40.15927,y:-104.93944},
{x:29.58556,y:-95.63313},
{x:43.09043,y:-77.51348},
{x:39.07422,y:-84.34811},
{x:29.73659,y:-95.54499},
{x:29.75729,y:-95.62732},
{x:39.22036,y:-84.51921},
{x:60.48982,y:-151.04993},
{x:42.05382,y:-124.29031},
{x:41.14233,y:-95.96877},
{x:40.0653,y:-83.02529},
{x:44.17159,y:-93.94508},
{x:39.18277,y:-96.55604},
{x:38.91514,y:-94.63773},
{x:40.7394,y:-73.78548},
{x:39.33586,y:-82.06921},
{x:40.08454,y:-82.89708},
{x:41.28974,y:-96.16051},
{x:39.14508,y:-84.43001},
{x:32.90842,y:-96.74545},
{x:33.19499,y:-97.09583},
{x:33.08294,y:-96.64494},
{x:43.52739,y:-96.78997},
{x:29.57779,y:-95.12063},
{x:41.665,y:-86.10763},
{x:64.85445,y:-147.69005},
{x:47.79125,y:-122.36754},
{x:37.22427,y:-80.01187},
{x:36.77303,y:-76.23401},
{x:61.60049,y:-149.1253},
{x:42.96984,y:-85.7786},
{x:42.90872,y:-82.49779},
{x:42.65128,y:-83.53168},
{x:39.18984,y:-84.59884},
{x:45.49975,y:-122.91495},
{x:35.47969,y:-82.54949},
{x:38.16196,y:-92.60427},
{x:41.73464,y:-93.60208},
{x:42.8459,y:-85.62006},
{x:35.73765,y:-78.86279},
{x:38.82322,y:-77.16197},
{x:41.7203,y:-72.62032},
{x:42.35923,y:-71.43002},
{x:40.65669,y:-73.54965},
{x:40.96958,y:-73.80645},
{x:26.60299,y:-81.81034},
{x:25.76691,y:-80.19232},
{x:38.82985,y:-77.09192},
{x:26.16,y:-97.89541},
{x:41.7604,y:-88.15085},
{x:41.76709,y:-72.67621},
{x:40.66698,y:-73.47486},
{x:41.9781,y:-73.9898},
{x:40.29778,y:-74.68173},
{x:39.37868,y:-76.60869},
{x:43.12282,y:-77.6181},
{x:43.64748,y:-116.35244},
{x:39.15107,y:-77.06415},
{x:31.27407,y:-92.46335},
{x:47.1559,y:-122.35888},
{x:47.49626,y:-122.65207},
{x:47.62624,y:-117.22246},
{x:33.68305,y:-112.23634},
{x:43.62492,y:-116.2095},
{x:43.63448,y:-116.3159},
{x:42.31709,y:-71.36403},
{x:47.80653,y:-122.29056},
{x:43.12688,y:-77.56455},
{x:47.81125,y:-122.54061},
{x:40.74899,y:-73.99237},
{x:39.71042,y:-104.98853},
{x:45.7815,y:-122.56002},
{x:40.77794,y:-73.97817},
{x:47.04314,y:-122.84522},
{x:39.88351,y:-104.94233},
{x:33.65295,y:-112.02972},
{x:32.29888,y:-110.99663},
{x:43.00178,y:-78.78535},
{x:32.56463,y:-97.32189},
{x:36.01221,y:-115.13805},
{x:44.04134,y:-123.11687},
{x:45.43154,y:-122.82375},
{x:47.88107,y:-122.28073},
{x:47.85097,y:-122.21973},
{x:39.11783,y:-77.2515},
{x:40.84959,y:-74.29443},
{x:34.26087,y:-118.79498},
{x:38.86647,y:-77.23011},
{x:33.84696,y:-117.54253},
{x:33.96178,y:-117.69556},
{x:38.96379,y:-77.39882},
{x:41.15918,y:-73.77263},
{x:43.00065,y:-78.82182},
{x:41.17263,y:-104.82965},
{x:42.24888,y:-70.90378},
{x:35.6409,y:-106.0132},
{x:33.38097,y:-104.52399},
{x:42.81113,y:-70.8695},
{x:33.68076,y:-118.00282},
{x:35.64583,y:-105.95512},
{x:39.40521,y:-76.78722},
{x:39.13793,y:-76.59953},
{x:34.86388,y:-120.41733},
{x:40.80948,y:-73.62759},
{x:46.42101,y:-117.04301},
{x:40.49806,y:-74.6456},
{x:44.27055,y:-105.4944},
{x:44.06615,y:-123.0717},
{x:36.26298,y:-115.24873},
{x:38.7714,y:-77.08357},
{x:47.53544,y:-122.61015},
{x:37.27171,y:-107.88519},
{x:33.5973,y:-112.16735},
{x:42.68333,y:-73.84021},
{x:36.15849,y:-115.04553},
{x:34.00111,y:-117.73438},
{x:36.16017,y:-115.24514},
{x:42.9891,y:-78.69764},
{x:41.14435,y:-104.76933},
{x:33.47513,y:-117.68498},
{x:45.48753,y:-122.73239},
{x:33.80331,y:-117.8377},
{x:42.96004,y:-78.75997},
{x:31.86449,y:-106.57496},
{x:45.51998,y:-122.4002},
{x:40.6594,y:-111.50701},
{x:39.0651,y:-108.59655},
{x:36.02042,y:-115.04893},
{x:36.18972,y:-115.25869},
{x:45.55889,y:-122.86626},
{x:33.56348,y:-117.64627},
{x:33.13178,y:-117.05972},
{x:40.6488,y:-111.86773},
{x:33.44286,y:-117.61416},
{x:39.04063,y:-77.15608},
{x:36.24011,y:-115.2089},
{x:33.92489,y:-116.94507},
{x:43.58934,y:-116.21525},
{x:45.53013,y:-122.95515},
{x:40.73664,y:-74.0302},
{x:33.62804,y:-117.6344},
{x:36.16086,y:-115.31954},
{x:47.36088,y:-122.60454},
{x:44.06874,y:-123.1794},
{x:36.11592,y:-115.28019},
{x:38.89871,y:-77.0404},
{x:38.92674,y:-77.10276},
{x:33.48191,y:-117.10051},
{x:33.89112,y:-117.87337},
{x:33.87851,y:-117.96561},
{x:33.67865,y:-111.97495},
{x:40.7867,y:-73.97224},
{x:34.12999,y:-117.86502},
{x:33.80653,y:-118.27269},
{x:32.7437,y:-117.04525},
{x:36.3007,y:-115.27919},
{x:33.16592,y:-117.24773},
{x:34.14502,y:-118.6996},
{x:36.23854,y:-115.25039},
{x:33.44746,y:-117.61004},
{x:33.67712,y:-117.98691},
{x:40.77764,y:-73.98247},
{x:33.3369,y:-111.80885},
{x:46.07673,y:-118.30304},
{x:33.91006,y:-117.86547},
{x:34.13534,y:-117.55719},
{x:33.37259,y:-117.25482},
{x:41.76388,y:-71.45803},
{x:35.69384,y:-105.95117},
{x:42.1865,y:-122.6707},
{x:33.59571,y:-117.24149},
{x:42.51895,y:-70.89487},
{x:33.5099,y:-117.12703},
{x:33.78696,y:-117.80419},
{x:39.72066,y:-104.90117},
{x:33.89698,y:-118.00792},
{x:43.52753,y:-114.32188},
{x:33.60922,y:-114.58723},
{x:36.21701,y:-115.99473},
{x:33.55123,y:-117.13881},
{x:33.21776,y:-111.79091},
{x:40.69244,y:-73.99126},
{x:33.84349,y:-117.57439},
{x:33.79971,y:-112.12716},
{x:34.10598,y:-117.8034},
{x:38.95884,y:-104.7667},
{x:40.72609,y:-111.82595},
{x:35.60577,y:-117.67437},
{x:45.4094,y:-122.72285},
{x:32.68497,y:-114.64846},
{x:34.44394,y:-118.51184},
{x:39.6046,y:-104.71517},
{x:47.27275,y:-122.2275},
{x:40.52156,y:-74.52132},
{x:35.61647,y:-120.68098},
{x:47.82257,y:-122.31642},
{x:40.58662,y:-74.16781},
{x:34.04897,y:-117.17147},
{x:35.3676,y:-120.84417},
{x:42.81573,y:-73.89091},
{x:38.9324,y:-104.7392},
{x:36.11664,y:-115.3137},
{x:33.17927,y:-117.29722},
{x:42.42529,y:-71.1885},
{x:33.84512,y:-116.52858},
{x:33.89015,y:-117.32311},
{x:34.91819,y:-120.43432},
{x:38.99218,y:-76.87824},
{x:40.75055,y:-111.57034},
{x:36.21953,y:-115.27783},
{x:36.26291,y:-115.18117},
{x:33.68612,y:-117.36668},
{x:41.06682,y:-73.54527},
{x:33.72914,y:-117.95637},
{x:35.38219,y:-119.14635},
{x:33.14037,y:-117.13875},
{x:41.46227,y:-73.23129},
{x:34.58634,y:-118.14884},
{x:34.10766,y:-118.023},
{x:33.03891,y:-116.87671},
{x:36.09889,y:-115.22389},
{x:38.83083,y:-77.1257},
{x:42.8625,y:-112.44269},
{x:46.22803,y:-119.26698},
{x:36.05472,y:-115.24165},
{x:47.87697,y:-122.18705},
{x:44.06765,y:-121.30252},
{x:33.10886,y:-117.10569},
{x:34.03135,y:-118.12228},
{x:37.12108,y:-113.62485},
{x:43.69264,y:-116.35223},
{x:33.09688,y:-117.19969},
{x:47.64531,y:-122.02623},
{x:36.16071,y:-115.35063},
{x:38.77526,y:-77.17105},
{x:40.75412,y:-73.97171},
{x:33.21744,y:-111.82514},
{x:33.16069,y:-111.56037},
{x:32.10518,y:-110.77386},
{x:39.49496,y:-84.32795},
{x:34.4593,y:-114.27903},
{x:40.70711,y:-73.85468},
{x:43.51756,y:-96.72849},
{x:34.08598,y:-81.18621},
{x:39.29558,y:-94.90747},
{x:40.00642,y:-82.6638},
{x:38.84418,y:-76.8841},
{x:32.26648,y:-110.89395},
{x:39.33054,y:-77.35203},
{x:38.96462,y:-104.75504},
{x:47.38755,y:-122.16349},
{x:38.35969,y:-77.52179},
{x:41.48102,y:-72.10308},
{x:37.72377,y:-97.4611},
{x:33.61102,y:-111.9943},
{x:35.09428,y:-77.13191},
{x:37.12614,y:-93.46522},
{x:33.69981,y:-84.7184},
{x:45.53056,y:-122.56088},
{x:47.50325,y:-122.62937},
{x:41.13551,y:-96.02639},
{x:44.26773,y:-121.17616},
{x:41.5536,y:-90.48459},
{x:40.7064,y:-73.65473},
{x:40.71094,y:-74.01035},
{x:39.69321,y:-105.35852},
{x:39.3916,y:-107.08761},
{x:41.36835,y:-72.06669},
{x:33.64027,y:-112.39532},
{x:39.88279,y:-83.06867},
{x:38.04922,y:-84.70815},
{x:37.06817,y:-76.49157},
{x:37.68489,y:-92.66832},
{x:42.35226,y:-71.05602},
{x:37.73107,y:-89.18302},
{x:38.73266,y:-77.55019},
{x:41.70693,y:-70.01911},
{x:40.73502,y:-73.98984},
{x:40.66871,y:-74.10708},
{x:47.94392,y:-122.1055},
{x:35.09652,y:-80.7846},
{x:39.73752,y:-104.99833},
{x:38.19916,y:-84.82658},
{x:36.96789,y:-93.71698},
{x:39.74306,y:-104.43555},
{x:37.64778,y:-77.58594},
{x:43.19852,y:-77.57746},
{x:38.992,y:-77.03171},
{x:35.41734,y:-80.74402},
{x:41.27187,y:-72.95685},
{x:35.99936,y:-115.10272},
{x:40.52506,y:-105.0732},
{x:39.66531,y:-86.18261},
{x:42.92259,y:-78.87681},
{x:41.44433,y:-85.98325},
{x:35.60227,y:-80.89621},
{x:42.59389,y:-83.44381},
{x:42.36642,y:-71.18108},
{x:39.86288,y:-84.31383},
{x:32.61868,y:-83.69541},
{x:39.63025,y:-106.05614},
{x:38.58786,y:-92.25455},
{x:40.72631,y:-74.03315},
{x:38.46995,y:-75.1141},
{x:39.47976,y:-104.76025},
{x:33.60824,y:-112.392},
{x:43.22832,y:-123.36842},
{x:42.35043,y:-71.20759},
{x:36.72583,y:-97.06597},
{x:47.3745,y:-122.2986},
{x:40.15061,y:-105.13368},
{x:41.63811,y:-83.46978},
{x:33.03883,y:-96.73481},
{x:33.79718,y:-111.97513},
{x:39.01824,y:-84.69212},
{x:33.34857,y:-111.72009},
{x:41.69707,y:-93.03389},
{x:40.85642,y:-73.96617},
{x:41.02884,y:-74.1271},
{x:40.67882,y:-74.28313},
{x:41.09897,y:-74.14667},
{x:31.55334,y:-110.25546},
{x:33.40964,y:-84.67977},
{x:31.96036,y:-110.98628},
{x:32.42536,y:-111.04495},
{x:39.01663,y:-77.37696},
{x:38.55324,y:-106.92557},
{x:35.80449,y:-83.58195},
{x:39.91475,y:-104.94372},
{x:30.12422,y:-95.40729},
{x:39.14286,y:-84.62272},
{x:35.50078,y:-80.86014},
{x:41.2528,y:-73.01794},
{x:39.8668,y:-84.14015},
{x:35.06165,y:-106.18958},
{x:35.59571,y:-82.53545},
{x:42.6447,y:-82.89863},
{x:35.74668,y:-78.74342},
{x:39.66234,y:-106.82782},
{x:36.79407,y:-76.16864},
{x:38.88142,y:-77.11142},
{x:40.15663,y:-83.09527},
{x:31.95639,y:-81.31997},
{x:34.56676,y:-111.86454},
{x:39.98352,y:-105.25167},
{x:38.87942,y:-77.3964},
{x:42.72814,y:-73.76106},
{x:33.51167,y:-82.02943},
{x:40.64067,y:-73.60813},
{x:32.30627,y:-110.84539},
{x:40.76085,y:-73.76647},
{x:42.21177,y:-83.62075},
{x:39.841,y:-83.07478},
{x:35.586,y:-80.87582},
{x:39.17023,y:-76.86435},
{x:35.02301,y:-80.8482},
{x:33.83132,y:-83.88434},
{x:40.38266,y:-74.30944},
{x:41.18975,y:-74.48532},
{x:35.50406,y:-82.98809},
{x:35.29032,y:-82.43097},
{x:35.91829,y:-78.95833},
{x:40.76217,y:-73.98746},
{x:35.70271,y:-82.56695},
{x:39.81084,y:-105.17202},
{x:38.86595,y:-77.19582},
{x:42.68842,y:-84.28445},
{x:40.58445,y:-73.65828},
{x:45.39104,y:-84.91826},
{x:41.69549,y:-70.15058},
{x:32.93907,y:-111.75573},
{x:40.64477,y:-73.7037},
{x:40.92201,y:-73.77356},
{x:40.75934,y:-111.8757},
{x:39.53869,y:-107.3231},
{x:39.0062,y:-84.42021},
{x:39.53568,y:-104.79059},
{x:39.97044,y:-104.81622},
{x:47.66247,y:-122.29676},
{x:39.04983,y:-77.10995},
{x:39.60022,y:-104.8029},
{x:38.89193,y:-104.76076},
{x:40.82704,y:-74.13983},
{x:33.21828,y:-111.56562},
{x:33.66968,y:-112.1888},
{x:35.88016,y:-84.15724},
{x:34.19651,y:-83.85791},
{x:40.28941,y:-84.18658},
{x:40.57335,y:-80.01431},
{x:33.96975,y:-112.72166},
{x:42.47025,y:-92.35657},
{x:42.29825,y:-71.42044},
{x:32.96589,y:-97.29725},
{x:33.58273,y:-117.63165},
{x:40.83865,y:-73.27702},
{x:39.38769,y:-76.73624},
{x:42.05682,y:-72.55431},
{x:39.14452,y:-77.21975},
{x:39.11441,y:-77.21624},
{x:40.75184,y:-73.99021},
{x:31.69886,y:-106.24261},
{x:41.41411,y:-82.23939},
{x:40.13875,y:-74.06123},
{x:38.99017,y:-76.5442},
{x:32.74123,y:-94.94214},
{x:30.22551,y:-93.32632},
{x:40.9376,y:-72.30177},
{x:38.45687,y:-75.55913},
{x:38.86939,y:-77.1516},
{x:33.30328,y:-111.9964},
{x:45.42848,y:-122.54008},
{x:38.71866,y:-76.66241},
{x:39.67756,y:-104.97233},
{x:39.6665,y:-104.91443},
{x:39.88363,y:-105.02299},
{x:45.61691,y:-122.53093},
{x:37.77332,y:-121.97636},
{x:39.18058,y:-77.26653},
{x:37.85031,y:-122.03384},
{x:38.8388,y:-77.05174},
{x:39.51628,y:-104.76633},
{x:37.92803,y:-122.03914},
{x:34.21007,y:-118.84096},
{x:39.28061,y:-76.57907},
{x:39.72521,y:-104.97345},
{x:37.54501,y:-121.98885},
{x:39.51548,y:-76.55773},
{x:40.65652,y:-73.68338},
{x:40.15347,y:-105.1097},
{x:45.67092,y:-122.50758},
{x:45.36438,y:-122.64793},
{x:39.25148,y:-76.81868},
{x:39.2801,y:-76.85568},
{x:39.09449,y:-77.11024},
{x:39.53758,y:-104.88334},
{x:39.59603,y:-104.90347},
{x:39.54815,y:-104.99415},
{x:38.86616,y:-77.36836},
{x:37.48446,y:-122.23046},
{x:38.99285,y:-76.87682},
{x:37.51864,y:-122.27576},
{x:37.76882,y:-122.42868},
{x:39.08334,y:-76.58345},
{x:38.96935,y:-77.02625},
{x:39.04026,y:-76.68011},
{x:38.3142,y:-104.7292},
{x:38.87399,y:-104.72214},
{x:38.84823,y:-104.77283},
{x:40.70516,y:-74.01129},
{x:47.67886,y:-122.17371},
{x:37.92807,y:-122.51811},
{x:38.8951,y:-77.07525},
{x:38.54124,y:-121.72496},
{x:39.11306,y:-77.17176},
{x:38.9979,y:-77.4088},
{x:37.55589,y:-122.27548},
{x:37.1288,y:-121.63683},
{x:47.66856,y:-122.37439},
{x:39.36221,y:-104.8607},
{x:38.45054,y:-122.69121},
{x:39.10847,y:-76.99638},
{x:39.41808,y:-76.77656},
{x:38.77836,y:-77.27518},
{x:47.44767,y:-122.15262},
{x:47.75625,y:-122.08096},
{x:37.37239,y:-122.08831},
{x:41.5555,y:-70.59939},
{x:38.97034,y:-76.52278},
{x:38.87825,y:-121.29432},
{x:45.64147,y:-122.55675},
{x:38.59447,y:-121.29242},
{x:38.64907,y:-121.16543},
{x:38.94425,y:-119.96707},
{x:41.83014,y:-71.38895},
{x:39.22334,y:-76.88943},
{x:45.49697,y:-122.62166},
{x:39.41358,y:-76.46084},
{x:37.98062,y:-122.56445},
{x:33.61958,y:-111.87529},
{x:33.48416,y:-112.36399},
{x:38.8127,y:-104.74698},
{x:40.74501,y:-73.98676},
{x:38.40457,y:-122.82689},
{x:38.93578,y:-77.36255},
{x:45.36984,y:-122.84522},
{x:37.79822,y:-121.91806},
{x:37.89281,y:-122.11941},
{x:33.75772,y:-111.9915},
{x:44.55078,y:-123.31158},
{x:39.58568,y:-77.01189},
{x:39.54182,y:-76.32439},
{x:38.83224,y:-77.20103},
{x:38.03546,y:-78.50088},
{x:45.71543,y:-122.6497},
{x:44.05789,y:-121.26472},
{x:38.60231,y:-76.90299},
{x:38.7996,y:-77.32122},
{x:47.72628,y:-121.98516},
{x:47.51668,y:-122.36708},
{x:38.79641,y:-121.26546},
{x:35.18976,y:-111.66306},
{x:33.67567,y:-111.88818},
{x:34.47424,y:-114.34212},
{x:39.10916,y:-77.55211},
{x:33.62422,y:-111.94164},
{x:39.53234,y:-119.86741},
{x:39.40024,y:-76.54407},
{x:39.99843,y:-105.23424},
{x:38.67454,y:-77.33458},
{x:44.07802,y:-123.07016},
{x:33.34889,y:-111.7568},
{x:40.1879,y:-105.12974},
{x:39.1571,y:-75.5064},
{x:47.41008,y:-122.29902},
{x:33.77557,y:-118.37776},
{x:37.76658,y:-122.40941},
{x:36.75142,y:-108.1608},
{x:39.18837,y:-77.18498},
{x:38.71653,y:-104.69928},
{x:38.77082,y:-77.13942},
{x:39.36015,y:-104.60964},
{x:39.36528,y:-77.16487},
{x:40.58877,y:-73.66714},
{x:48.06328,y:-122.1754},
{x:47.69412,y:-117.36683},
{x:47.67231,y:-117.39502},
{x:47.86362,y:-122.28404},
{x:39.68713,y:-104.94218},
{x:48.08066,y:-123.11722},
{x:48.11603,y:-123.43481},
{x:47.16386,y:-122.51863},
{x:37.70508,y:-121.9274},
{x:40.7563,y:-73.97816},
{x:47.69018,y:-122.37618},
{x:38.94129,y:-104.60149},
{x:45.32125,y:-118.08094},
{x:39.65286,y:-104.77617},
{x:61.32361,y:-149.57147},
{x:61.19639,y:-149.73606},
{x:48.10806,y:-122.78262},
{x:47.19844,y:-121.97987},
{x:47.65145,y:-122.6885},
{x:40.74426,y:-73.98374},
{x:46.99766,y:-122.82214},
{x:46.93385,y:-122.59382},
{x:38.94577,y:-76.73726},
{x:37.70676,y:-121.87386},
{x:47.63509,y:-122.51919},
{x:45.54783,y:-122.59233},
{x:45.39824,y:-122.74194},
{x:40.8567,y:-74.07031},
{x:38.10407,y:-122.57131},
{x:37.69619,y:-122.07417},
{x:39.74765,y:-104.97741},
{x:58.37096,y:-134.59481},
{x:33.64158,y:-112.06708},
{x:44.78098,y:-117.81901},
{x:37.69798,y:-121.74276},
{x:39.7358,y:-121.83569},
{x:41.11158,y:-74.11322},
{x:34.56159,y:-112.48425},
{x:39.84326,y:-105.08076},
{x:35.12151,y:-114.58852},
{x:33.40894,y:-111.9251},
{x:38.98714,y:-76.55518},
{x:39.73929,y:-105.09886},
{x:39.77606,y:-105.03693},
{x:38.75539,y:-121.28813},
{x:40.03526,y:-105.25976},
{x:37.34538,y:-121.93655},
{x:38.98122,y:-77.09328},
{x:41.70295,y:-71.49666},
{x:38.74197,y:-121.22858},
{x:38.73109,y:-121.27126},
{x:45.60437,y:-121.19317},
{x:45.53683,y:-122.86967},
{x:44.6273,y:-123.07956},
{x:40.07909,y:-104.80288},
{x:33.56606,y:-117.71319},
{x:45.70896,y:-121.53488},
{x:38.92385,y:-104.84411},
{x:43.22914,y:-123.34995},
{x:44.04059,y:-123.09045},
{x:47.62113,y:-122.63054},
{x:37.26688,y:-121.83305},
{x:45.15024,y:-122.83006},
{x:46.14135,y:-122.9021},
{x:39.09081,y:-108.5137},
{x:38.73039,y:-77.79713},
{x:40.93712,y:-73.1094},
{x:44.52725,y:-122.90606},
{x:47.35686,y:-122.16713},
{x:33.51118,y:-112.01455},
{x:44.87296,y:-123.02248},
{x:45.44231,y:-122.82663},
{x:37.74011,y:-121.45011},
{x:47.04978,y:-122.82895},
{x:39.9568,y:-105.03546},
{x:39.10396,y:-108.55107},
{x:31.90121,y:-110.9926},
{x:38.72442,y:-77.12108},
{x:39.0987,y:-104.86566},
{x:39.5752,y:-106.1019},
{x:37.39467,y:-121.94754},
{x:40.57648,y:-122.38523},
{x:37.48965,y:-121.92746},
{x:39.94534,y:-105.81108},
{x:40.4085,y:-105.10958},
{x:40.47817,y:-104.92781},
{x:38.98269,y:-105.04662},
{x:39.23899,y:-120.03595},
{x:44.58716,y:-123.25135},
{x:40.52465,y:-105.0518},
{x:40.01654,y:-105.10097},
{x:47.52075,y:-122.2682},
{x:37.37384,y:-118.39553},
{x:39.68614,y:-104.86804},
{x:61.14221,y:-149.84463},
{x:40.11899,y:-104.94018},
{x:48.00346,y:-122.10368},
{x:39.7518,y:-105.2154},
{x:46.59057,y:-112.0176},
{x:38.7247,y:-76.9992},
{x:44.26075,y:-121.17954},
{x:33.26485,y:-111.68374},
{x:45.99109,y:-123.92041},
{x:40.42142,y:-104.73604},
{x:44.04714,y:-121.32957},
{x:39.63095,y:-106.41796},
{x:38.57167,y:-121.46476},
{x:39.11225,y:-76.93327},
{x:39.77113,y:-105.08},
{x:39.6983,y:-105.14565},
{x:34.14243,y:-109.95521},
{x:39.55568,y:-119.70371},
{x:45.4799,y:-122.6162},
{x:34.27141,y:-119.25937},
{x:32.3727,y:-111.1368},
{x:40.40914,y:-74.23426},
{x:40.38024,y:-105.51648},
{x:47.45563,y:-122.28206},
{x:29.97969,y:-95.5092},
{x:31.55591,y:-110.27538},
{x:41.31697,y:-105.59461},
{x:38.85509,y:-104.8767},
{x:39.40812,y:-76.94916},
{x:40.5543,y:-105.11646},
{x:47.13542,y:-122.29468},
{x:45.50568,y:-122.4167},
{x:34.27728,y:-119.21272},
{x:38.66474,y:-121.30842},
{x:47.30634,y:-122.22696},
{x:38.86449,y:-104.81726},
{x:32.89542,y:-111.74114},
{x:39.4323,y:-76.62302},
{x:37.11387,y:-76.47066},
{x:34.58712,y:-112.32985},
{x:33.56416,y:-112.28844},
{x:34.24666,y:-110.0521},
{x:31.8522,y:-110.9988},
{x:46.71583,y:-117.17808},
{x:47.47996,y:-122.21476},
{x:47.04565,y:-122.94237},
{x:39.87241,y:-104.97602},
{x:47.22296,y:-122.45103},
{x:38.46346,y:-122.71724},
{x:40.67197,y:-73.97746},
{x:47.05404,y:-122.29685},
{x:34.72741,y:-112.00773},
{x:47.16631,y:-122.14522},
{x:47.75896,y:-122.24747},
{x:32.42694,y:-110.95933},
{x:40.39508,y:-104.75383},
{x:34.7611,y:-112.45205},
{x:44.94067,y:-123.02427},
{x:34.23987,y:-111.31745},
{x:38.5448,y:-76.59465},
{x:45.51547,y:-122.68478},
{x:37.89813,y:-122.05685},
{x:45.4207,y:-122.66843},
{x:45.5277,y:-122.81433},
{x:41.11099,y:-104.80247},
{x:44.08005,y:-103.25085},
{x:61.19427,y:-149.91587},
{x:34.86468,y:-111.80028},
{x:33.49579,y:-111.97924},
{x:38.74997,y:-77.57098},
{x:33.48839,y:-112.08187},
{x:40.7595,y:-73.98046},
{x:38.92255,y:-77.20181},
{x:38.79412,y:-121.3303},
{x:38.89691,y:-77.1394},
{x:46.1919,y:-123.80808},
{x:37.95375,y:-121.95373},
{x:33.4515,y:-112.39405},
{x:39.15188,y:-76.72253},
{x:45.40734,y:-122.7931},
{x:41.01109,y:-74.16732},
{x:33.66865,y:-112.20273},
{x:33.51044,y:-111.91041},
{x:64.85143,y:-147.70012},
{x:38.85906,y:-76.96683},
{x:37.92404,y:-121.72163},
{x:38.36278,y:-121.96271},
{x:45.58785,y:-122.75253},
{x:44.99647,y:-123.02434},
{x:36.69911,y:-121.62061},
{x:47.13015,y:-119.26862},
{x:38.00871,y:-121.32124},
{x:47.4309,y:-120.32409},
{x:45.41568,y:-122.59103},
{x:38.54224,y:-76.98211},
{x:47.53949,y:-122.16734},
{x:47.38016,y:-122.24645},
{x:57.81049,y:-152.36725},
{x:38.56788,y:-121.48586},
{x:37.11225,y:-121.64085},
{x:38.71435,y:-120.83961},
{x:38.65738,y:-121.5247},
{x:39.57925,y:-105.13475},
{x:42.89081,y:-78.87623},
{x:39.93014,y:-105.14868},
{x:36.97868,y:-121.90832},
{x:37.77678,y:-122.3939},
{x:33.34831,y:-111.9131},
{x:47.31399,y:-122.31739},
{x:47.62567,y:-122.15479},
{x:47.62042,y:-122.31308},
{x:38.76737,y:-104.81496},
{x:37.46958,y:-105.8765},
{x:38.32135,y:-122.30185},
{x:33.23371,y:-111.85984},
{x:45.68161,y:-122.66824},
{x:47.01883,y:-122.89788},
{x:37.01373,y:-121.58927},
{x:40.01331,y:-105.25955},
{x:45.48902,y:-122.47646},
{x:37.49713,y:-77.56879},
{x:42.58536,y:-70.88444},
{x:32.9387,y:-117.23172},
{x:32.76503,y:-117.02442},
{x:32.9357,y:-117.09827},
{x:35.34149,y:-119.10259},
{x:34.39112,y:-118.57227},
{x:35.12093,y:-120.60824},
{x:33.10339,y:-117.26856},
{x:33.05132,y:-117.26121},
{x:40.88847,y:-74.25988},
{x:34.15334,y:-118.75862},
{x:33.80515,y:-118.33022},
{x:37.63867,y:-118.96518},
{x:35.25028,y:-120.64257},
{x:32.84195,y:-117.27328},
{x:38.91978,y:-77.15037},
{x:34.2804,y:-118.76281},
{x:34.12515,y:-118.05768},
{x:34.13339,y:-117.61471},
{x:29.53913,y:-95.06332},
{x:32.85186,y:-96.7668},
{x:39.07524,y:-77.13761},
{x:36.0414,y:-115.10247},
{x:32.75139,y:-117.21716},
{x:33.60966,y:-111.72832},
{x:34.06982,y:-118.29075},
{x:41.48594,y:-93.67362},
{x:33.58574,y:-117.65783},
{x:33.17863,y:-117.32494},
{x:33.60696,y:-117.82761},
{x:33.63694,y:-117.59145},
{x:39.99862,y:-75.56754},
{x:29.62215,y:-95.74247},
{x:40.8078,y:-73.9451},
{x:29.83233,y:-95.68398},
{x:40.12129,y:-75.13586},
{x:39.60275,y:-75.74641},
{x:33.83312,y:-117.83766},
{x:33.8913,y:-117.51995},
{x:38.89419,y:-77.07394},
{x:34.1535,y:-118.24385},
{x:29.74919,y:-95.37465},
{x:34.18914,y:-118.64134},
{x:33.99683,y:-118.39421},
{x:33.79003,y:-118.02924},
{x:33.96025,y:-118.34916},
{x:32.91526,y:-117.14493},
{x:34.60327,y:-118.18418},
{x:34.08436,y:-118.32696},
{x:34.1078,y:-117.65303},
{x:43.07708,y:-70.75806},
{x:33.02073,y:-117.07263},
{x:32.64601,y:-116.96643},
{x:32.85988,y:-96.95734},
{x:32.802,y:-117.23895},
{x:33.65824,y:-117.97281},
{x:37.33044,y:-119.65862},
{x:35.03743,y:-120.4865},
{x:34.23001,y:-118.24884},
{x:39.36879,y:-76.64949},
{x:38.85374,y:-77.04915},
{x:29.26989,y:-94.82965},
{x:29.77171,y:-95.6074},
{x:33.81073,y:-118.11571},
{x:32.9579,y:-117.12522},
{x:34.22188,y:-119.16142},
{x:33.72307,y:-118.31087},
{x:41.30778,y:-72.93026},
{x:36.73466,y:-119.69842},
{x:32.86425,y:-96.77503},
{x:29.77368,y:-95.55934},
{x:33.71522,y:-116.29818},
{x:34.25092,y:-116.88412},
{x:36.04165,y:-115.15258},
{x:32.83748,y:-116.98696},
{x:40.75546,y:-73.98015},
{x:33.84102,y:-118.18276},
{x:34.25681,y:-118.57877},
{x:33.85127,y:-117.79226},
{x:34.66152,y:-118.20234},
{x:34.13623,y:-117.86082},
{x:34.16897,y:-118.5344},
{x:34.11065,y:-117.48866},
{x:32.93855,y:-96.85381},
{x:30.37135,y:-97.75835},
{x:33.14857,y:-117.10634},
{x:33.24083,y:-117.29461},
{x:32.8302,y:-117.20501},
{x:32.98027,y:-96.76697},
{x:36.05392,y:-115.28105},
{x:40.77426,y:-73.73294},
{x:55.34808,y:-131.67402},
{x:32.93913,y:-97.09749},
{x:29.67272,y:-95.45916},
{x:32.97077,y:-96.99215},
{x:33.62595,y:-117.85018},
{x:40.76944,y:-73.9849},
{x:30.27539,y:-97.79886},
{x:32.75341,y:-117.12954},
{x:32.72255,y:-97.15067},
{x:30.49501,y:-97.65437},
{x:34.12146,y:-117.70666},
{x:34.15011,y:-118.00174},
{x:40.71553,y:-74.00903},
{x:34.16142,y:-118.31373},
{x:32.86215,y:-97.18016},
{x:34.09704,y:-118.288},
{x:33.5543,y:-117.13853},
{x:40.18228,y:-74.90394},
{x:40.0011,y:-75.27223},
{x:34.28878,y:-118.71763},
{x:37.10259,y:-76.49185},
{x:33.97633,y:-117.55739},
{x:33.95502,y:-117.38936},
{x:40.2674,y:-75.22541},
{x:37.97837,y:-122.02855},
{x:48.43731,y:-122.33741},
{x:47.73248,y:-122.17442},
{x:44.93925,y:-123.06312},
{x:37.6751,y:-121.8747},
{x:33.86493,y:-118.39403},
{x:38.42401,y:-121.44615},
{x:33.72863,y:-118.02488},
{x:47.09889,y:-122.20928},
{x:37.98781,y:-121.78441},
{x:47.96254,y:-122.21188},
{x:60.48398,y:-151.07079},
{x:46.99609,y:-120.54349},
{x:39.48477,y:-105.07384},
{x:38.96703,y:-77.37653},
{x:38.97384,y:-76.27457},
{x:39.02415,y:-77.12376},
{x:40.75263,y:-73.9929},
{x:48.18205,y:-122.12789},
{x:34.14437,y:-118.39574},
{x:47.36053,y:-122.01863},
{x:39.73753,y:-104.91559},
{x:60.12895,y:-149.43355},
{x:47.72322,y:-122.20725},
{x:40.84951,y:-73.97076},
{x:38.62664,y:-76.91763},
{x:33.34807,y:-111.98187},
{x:33.53921,y:-112.06239},
{x:38.87131,y:-77.26105},
{x:33.61056,y:-112.35746},
{x:36.83596,y:-119.68505},
{x:34.02661,y:-118.42708},
{x:34.22527,y:-119.0371},
{x:32.33597,y:-110.97491},
{x:40.79848,y:-74.34067},
{x:38.85345,y:-77.44978},
{x:33.85684,y:-118.11563},
{x:34.13663,y:-118.15348},
{x:35.97873,y:-115.09992},
{x:45.91275,y:-122.74831},
{x:46.13671,y:-122.93863},
{x:33.99499,y:-117.96737},
{x:33.03925,y:-96.75233},
{x:47.47164,y:-122.33618},
{x:46.29961,y:-119.27554},
{x:48.5126,y:-122.61189},
{x:37.94903,y:-122.05796},
{x:37.26007,y:-121.87432},
{x:45.51999,y:-123.09535},
{x:34.27186,y:-118.49975},
{x:47.62932,y:-117.37446},
{x:44.93045,y:-123.31269},
{x:39.76212,y:-121.82274},
{x:36.84756,y:-76.29203},
{x:33.10289,y:-117.07352},
{x:37.95809,y:-122.09351},
{x:45.66671,y:-122.60364},
{x:47.70858,y:-116.78797},
{x:34.26778,y:-118.89917},
{x:37.25266,y:-121.9082},
{x:38.92164,y:-77.23627},
{x:32.80736,y:-117.11635},
{x:39.65182,y:-104.9843},
{x:33.37793,y:-111.72358},
{x:43.97299,y:-124.10309},
{x:37.04622,y:-122.03104},
{x:37.72368,y:-122.15479},
{x:34.02662,y:-118.48996},
{x:38.97774,y:-76.48851},
{x:39.15505,y:-77.16523},
{x:33.86742,y:-117.75144},
{x:33.39483,y:-111.75231},
{x:37.28044,y:-121.95006},
{x:45.22759,y:-123.17273},
{x:33.32175,y:-111.8226},
{x:37.83606,y:-122.12863},
{x:37.55226,y:-122.04901},
{x:38.27185,y:-122.0532},
{x:42.33371,y:-71.11871},
{x:33.44988,y:-111.68216},
{x:42.36275,y:-122.8583},
{x:37.74374,y:-122.43876},
{x:46.59906,y:-120.58473},
{x:46.1486,y:-122.96104},
{x:41.41787,y:-73.2929},
{x:34.22957,y:-118.9984},
{x:38.55132,y:-122.80638},
{x:33.69835,y:-117.88694},
{x:47.3592,y:-122.31087},
{x:47.19032,y:-122.40726},
{x:40.04014,y:-75.37057},
{x:40.68881,y:-74.30723},
{x:40.46596,y:-106.82449},
{x:48.15163,y:-122.18154},
{x:34.15488,y:-118.34644},
{x:41.39226,y:-73.52248},
{x:32.30746,y:-110.89134},
{x:32.95132,y:-117.24665},
{x:47.78433,y:-122.34424},
{x:41.27748,y:-72.82753},
{x:38.46591,y:-122.65291},
{x:37.3728,y:-122.05812},
{x:37.35735,y:-122.01779},
{x:39.14407,y:-123.20591},
{x:38.77523,y:-77.05981},
{x:42.78945,y:-78.8125},
{x:38.93933,y:-76.56285},
{x:38.71125,y:-121.08411},
{x:33.58427,y:-111.92444},
{x:44.80573,y:-122.79571},
{x:33.63751,y:-112.42531},
{x:38.90056,y:-76.97944},
{x:33.89283,y:-118.36224},
{x:37.24684,y:-76.65908},
{x:34.27306,y:-118.67801},
{x:34.4718,y:-117.29003},
{x:37.75761,y:-122.25096},
{x:39.64588,y:-105.33993},
{x:39.89118,y:-74.92501},
{x:37.89934,y:-122.51501},
{x:48.28924,y:-122.65901},
{x:36.60465,y:-121.92067},
{x:37.29343,y:-121.91064},
{x:46.57831,y:-120.48904},
{x:34.34415,y:-119.08069},
{x:34.25927,y:-118.30161},
{x:40.75118,y:-74.02538},
{x:38.84781,y:-77.12043},
{x:41.63142,y:-87.93161},
{x:38.81666,y:-77.27506},
{x:46.97253,y:-123.81852},
{x:44.05711,y:-121.30131},
{x:38.45348,y:-121.37015},
{x:38.86977,y:-77.14466},
{x:37.67175,y:-120.99551},
{x:38.5943,y:-121.38664},
{x:37.21981,y:-121.86112},
{x:47.6805,y:-117.4357},
{x:32.25192,y:-110.83872},
{x:45.57903,y:-122.33479},
{x:33.96097,y:-112.77081},
{x:59.64234,y:-151.53648},
{x:47.35677,y:-122.11444},
{x:33.02357,y:-96.88375},
{x:40.66143,y:-73.84015},
{x:33.94109,y:-117.99423},
{x:32.13379,y:-111.00869},
{x:33.83513,y:-118.11542},
{x:47.67138,y:-122.11},
{x:32.76396,y:-117.11453},
{x:36.00931,y:-114.99314},
{x:37.58762,y:-122.01938},
{x:37.59452,y:-122.50306},
{x:45.40096,y:-122.27684},
{x:40.70841,y:-74.0074},
{x:46.58407,y:-120.53939},
{x:46.60308,y:-120.51604},
{x:38.08457,y:-122.2102},
{x:29.70506,y:-95.42586},
{x:47.0582,y:-122.76653},
{x:46.04884,y:-118.34791},
{x:34.20157,y:-118.86697},
{x:33.30685,y:-112.05375},
{x:37.68618,y:-122.1383},
{x:45.62623,y:-122.6031},
{x:38.65647,y:-120.97102},
{x:37.40264,y:-122.07946},
{x:38.00875,y:-122.54848},
{x:38.45185,y:-122.75224},
{x:47.21419,y:-123.106},
{x:36.98622,y:-121.96342},
{x:39.04857,y:-122.92546},
{x:36.79317,y:-121.66417},
{x:35.35503,y:-119.065},
{x:45.45516,y:-123.8454},
{x:32.11806,y:-110.79845},
{x:37.69938,y:-122.48141},
{x:47.99428,y:-122.20057},
{x:40.26471,y:-74.04241},
{x:47.22164,y:-122.5391},
{x:40.45297,y:-122.28837},
{x:38.11766,y:-121.30588},
{x:32.25883,y:-110.79711},
{x:47.41532,y:-122.19561},
{x:46.72794,y:-122.98072},
{x:32.83036,y:-117.10404},
{x:40.72719,y:-73.99546},
{x:47.55983,y:-122.38489},
{x:35.01702,y:-114.59912},
{x:45.15084,y:-122.60375},
{x:43.4066,y:-124.23542},
{x:38.12553,y:-122.22711},
{x:47.85989,y:-121.96766},
{x:34.06016,y:-117.18145},
{x:38.05459,y:-122.15557},
{x:43.35878,y:-124.2104},
{x:44.99721,y:-124.00607},
{x:44.64368,y:-121.12767},
{x:41.74796,y:-74.08521},
{x:38.01613,y:-121.94326},
{x:32.8625,y:-117.22371},
{x:47.40489,y:-120.28086},
{x:38.45703,y:-121.83896},
{x:33.46617,y:-112.06723},
{x:34.15433,y:-114.27987},
{x:40.73741,y:-73.87744},
{x:39.62639,y:-104.88448},
{x:39.53674,y:-105.01354},
{x:39.73094,y:-104.97344},
{x:39.6548,y:-105.05139},
{x:39.60782,y:-104.92077},
{x:39.58186,y:-105.13066},
{x:39.69035,y:-105.11943},
{x:39.5589,y:-104.90747},
{x:40.76372,y:-73.98531},
{x:37.0637,y:-93.22321},
{x:45.18321,y:-93.11008},
{x:35.87949,y:-106.0094},
{x:42.39575,y:-71.12229},
{x:40.7548,y:-73.11612},
{x:40.90714,y:-74.55401},
{x:41.27483,y:-73.78046},
{x:34.96634,y:-90.00544},
{x:41.13843,y:-73.33057},
{x:33.99228,y:-81.03497},
{x:40.75086,y:-73.97527},
{x:40.36556,y:-79.98339},
{x:41.81618,y:-72.86236},
{x:27.33865,y:-82.4978},
{x:29.35306,y:-98.53377},
{x:40.43773,y:-79.91917},
{x:34.22106,y:-118.47072},
{x:36.13228,y:-115.15154},
{x:32.70584,y:-117.1601},
{x:35.95485,y:-83.93016},
{x:38.79208,y:-89.99729},
{x:41.70189,y:-86.23761},
{x:44.97312,y:-93.236},
{x:41.89192,y:-87.60676},
{x:41.85014,y:-87.61583},
{x:40.77172,y:-111.83668},
{x:29.53166,y:-98.57382},
{x:29.7096,y:-95.39775},
{x:33.77668,y:-84.38863},
{x:38.98812,y:-77.09622},
{x:39.94811,y:-75.12254},
{x:36.88707,y:-76.30627},
{x:32.77724,y:-117.07366},
{x:39.01531,y:-76.9283},
{x:29.54067,y:-98.57734},
{x:32.80304,y:-96.82505},
{x:41.76373,y:-72.66928},
{x:33.41658,y:-111.93422},
{x:41.31631,y:-74.1264},
{x:40.05084,y:-82.89847},
{x:39.36459,y:-101.0547},
{x:35.0481,y:-85.2909},
{x:38.03412,y:-84.50843},
{x:42.35647,y:-83.07192},
{x:46.55898,y:-87.40625},
{x:40.72958,y:-73.99624},
{x:32.77398,y:-117.07016},
{x:33.41818,y:-111.93473},
{x:40.07639,y:-75.2068},
{x:40.93998,y:-73.74396},
{x:28.05957,y:-82.41224},
{x:33.12874,y:-117.15947},
{x:41.88156,y:-87.63004},
{x:31.8462,y:-102.37478},
{x:29.7514,y:-95.35919},
{x:29.5466,y:-95.01767},
{x:31.59026,y:-84.15781},
{x:42.50263,y:-70.85604},
{x:39.13359,y:-84.51437},
{x:41.0757,y:-81.51257},
{x:41.51258,y:-81.60444},
{x:35.14447,y:-114.57706},
{x:32.7075,y:-117.16264},
{x:36.11802,y:-115.18787},
{x:36.11888,y:-115.17172},
{x:34.18229,y:-117.32393},
{x:36.1195,y:-115.17044},
{x:27.93802,y:-82.45901},
{x:42.51456,y:-83.19178},
{x:37.9613,y:-87.6764},
{x:34.8187,y:-82.41213},
{x:38.90104,y:-77.05043},
{x:37.71279,y:-89.21828},
{x:38.95972429,y:-119.94051631},
{x:38.95932,y:-119.9401},
{x:34.07474,y:-118.38037},
{x:29.76596,y:-95.35906},
{x:33.42257,y:-111.93228},
{x:40.76126,y:-73.9691},
{x:35.12849,y:-89.86125},
{x:36.14727,y:-115.15604},
{x:41.37756,y:-83.64061},
{x:38.92764,y:-77.37529},
{x:31.32761,y:-89.33444},
{x:40.4368,y:-79.99084},
{x:33.50875,y:-86.78962},
{x:31.62093,y:-94.6498},
{x:38.96616,y:-76.95368},
{x:40.75696,y:-74.00177},
{x:36.10599,y:-115.13849},
{x:36.81518,y:-114.06604},
{x:33.48208,y:-112.07981},
{x:36.8847,y:-119.80055},
{x:33.41821,y:-111.93189},
{x:35.08521,y:-106.6206},
{x:40.86301,y:-73.05505},
{x:26.36983,y:-80.10207},
{x:38.83609,y:-77.15648},
{x:33.7209,y:-116.19366},
{x:40.26429,y:-76.67601},
{x:36.06189,y:-115.03979},
{x:41.84625,y:-87.90385},
{x:40.74196,y:-74.17602},
{x:39.94964,y:-75.16742},
{x:36.65914,y:-121.64646},
{x:47.1326,y:-122.487},
{x:39.03338,y:-84.46632},
{x:41.84998,y:-87.61655},
{x:42.35561,y:-71.06105},
{x:35.2221,y:-80.84532},
{x:39.95247,y:-75.19936},
{x:34.01248,y:-80.93111},
{x:38.69744,y:-77.14316},
{x:61.2272,y:-149.7534},
{x:39.74392,y:-104.98692},
{x:32.78799,y:-96.79633},
{x:32.38113,y:-84.90229},
{x:30.71147,y:-95.54937},
{x:40.93295,y:-74.07126},
{x:36.09525,y:-115.17616},
{x:36.09588,y:-115.17549},
{x:42.61895,y:-83.09391},
{x:36.23692,y:-115.04589},
{x:36.09593,y:-115.17601},
{x:36.15563,y:-115.33224},
{x:36.652,y:-87.45062},
{x:40.8071,y:-73.96491},
{x:36.15593,y:-115.33406},
{x:36.09835,y:-115.17614},
{x:36.09041,y:-115.17783},
{x:36.093,y:-115.17583},
{x:39.39362,y:-76.60628},
{x:36.09867,y:-115.17505},
{x:36.1068,y:-115.16647},
{x:33.43108,y:-82.12581},
{x:38.03269,y:-84.50145},
{x:42.44868,y:-79.33924},
{x:32.42452,y:-81.78001},
{x:39.214,y:-76.88064},
{x:40.74897,y:-73.98229},
{x:32.18335,y:-110.87686},
{x:39.53344,y:-119.75798},
{x:42.33901,y:-71.08728},
{x:47.60719,y:-122.30991},
{x:34.84713,y:-90.33075},
{x:41.11933,y:-95.92465},
{x:33.6684,y:-117.91205},
{x:29.65121,y:-82.34289},
{x:30.26934,y:-81.50797},
{x:38.92832,y:-77.23927},
{x:32.73181,y:-97.1116},
{x:33.60733,y:-112.15995},
{x:34.15849,y:-118.32521},
{x:34.16061,y:-118.28604},
{x:39.16793,y:-86.52362},
{x:41.07886,y:-81.51926},
{x:40.75808,y:-73.82954},
{x:29.64817,y:-82.34538},
{x:42.66413,y:-73.78549},
{x:30.391,y:-88.86073},
{x:29.38159,y:-98.6257},
{x:33.64842,y:-117.84212},
{x:40.39228,y:-80.06665},
{x:39.90054,y:-86.04216},
{x:36.91024,y:-76.14201},
{x:36.94365,y:-76.30963},
{x:38.26968,y:-121.94847},
{x:38.95228,y:-77.14458},
{x:35.96883,y:-79.99417},
{x:37.54791,y:-77.45328},
{x:36.7995,y:-76.17787},
{x:38.81256,y:-104.67875},
{x:36.16958,y:-115.14592},
{x:38.93506,y:-76.99951},
{x:29.465,y:-98.42547},
{x:40.78027,y:-73.65025},
{x:40.06182,y:-77.52211},
{x:41.5991,y:-93.6233},
{x:34.97388,y:-85.35063},
{x:32.34461,y:-84.89968},
{x:42.34925,y:-71.08093},
{x:41.72015,y:-88.04106},
{x:35.30983,y:-83.18359},
{x:36.26028,y:-119.91083},
{x:25.75703,y:-80.37385},
{x:26.4636,y:-81.77246},
{x:39.95185,y:-75.59786},
{x:26.07817,y:-80.24325},
{x:40.61629,y:-74.49442},
{x:42.25193,y:-83.62723},
{x:38.91659,y:-77.42706},
{x:21.49391,y:-158.05999},
{x:38.94532,y:-92.32484},
{x:43.12897,y:-77.62971},
{x:36.10558,y:-86.79805},
{x:32.73026,y:-97.34511},
{x:39.5234,y:-119.77945},
{x:39.52651,y:-119.79569},
{x:40.27914,y:-74.7357},
{x:40.73318,y:-74.00284},
{x:42.49196,y:-83.47109},
{x:61.2621,y:-149.6772},
{x:29.64618,y:-82.34783},
{x:41.40679,y:-75.65598},
{x:34.06839,y:-118.169},
{x:32.68996,y:-117.12865},
{x:39.33988,y:-94.92229},
{x:41.14017,y:-77.46097},
{x:43.85652,y:-70.10432},
{x:37.14006,y:-80.55198},
{x:41.00898,y:-76.4453},
{x:36.06646,y:-94.17512},
{x:33.91708,y:-118.3803},
{x:40.09457,y:-75.36463},
{x:42.36397,y:-71.05082},
{x:39.74735,y:-104.99045},
{x:33.68399,y:-111.96627},
{x:42.34261,y:-71.07464},
{x:39.13743,y:-84.50394},
{x:40.69832,y:-73.97971},
{x:36.25005,y:-115.24461},
{x:40.74998,y:-74.39986},
{x:38.62517,y:-90.19057},
{x:36.14226,y:-115.17536},
{x:39.80032,y:-105.49007},
{x:41.03224,y:-81.90748},
{x:34.07002,y:-84.17057},
{x:42.45361,y:-71.23331},
{x:33.78795,y:-117.91697},
{x:32.79441,y:-96.80368},
{x:39.19691,y:-120.23502},
{x:33.75942,y:-84.38839},
{x:41.49177,y:-72.09107},
{x:33.75552,y:-116.36179},
{x:34.14918,y:-118.33995},
{x:40.75892,y:-111.89604},
{x:33.79015,y:-117.91702},
{x:40.77565,y:-73.91162},
{x:25.77063,y:-80.13259},
{x:36.10337,y:-115.1683},
{x:32.13541,y:-81.20957},
{x:28.40205,y:-81.42857},
{x:35.61194,y:-115.38758},
{x:41.37977,y:-82.22316},
{x:41.37755,y:-82.22483},
{x:40.69566,y:-74.17769},
{x:40.78713,y:-73.95448},
{x:38.63065,y:-90.19185},
{x:47.44396,y:-122.30242},
{x:40.75857,y:-73.98585},
{x:40.78202,y:-73.82986},
{x:33.70074,y:-78.87066},
{x:40.69645,y:-74.17765},
{x:26.10763,y:-80.10604},
{x:32.90764,y:-96.45011},
{x:33.80013,y:-117.91878},
{x:36.17629,y:-115.29189},
{x:39.37798,y:-74.43437},
{x:39.88529,y:-105.76247},
{x:20.89339,y:-156.43767},
{x:39.35224,y:-74.44434},
{x:29.98558,y:-95.33276},
{x:33.94648,y:-118.38451},
{x:36.11503,y:-115.16638},
{x:39.3301,y:-74.49949},
{x:40.73541,y:-114.04484},
{x:42.35225,y:-71.06443},
{x:29.94371,y:-90.06495},
{x:41.89323,y:-87.62289},
{x:35.0461,y:-85.31072},
{x:42.25822,y:-71.04009},
{x:34.17849,y:-118.6036},
{x:29.94709,y:-95.40702},
{x:21.32992,y:-157.92008},
{x:40.71485,y:-74.00593},
{x:28.43479,y:-81.31445},
{x:42.92732,y:-71.43831},
{x:47.44175,y:-122.30094},
{x:42.948,y:-87.90178},
{x:40.76022,y:-73.91822},
{x:41.84622,y:-87.95235},
{x:40.84887,y:-115.74761},
{x:42.32882,y:-83.03938},
{x:34.73004,y:-92.22001},
{x:38.89717,y:-77.03029},
{x:35.96265,y:-83.91998},
{x:36.08566,y:-115.14935},
{x:61.17376,y:-149.98295},
{x:42.7431,y:-71.15933},
{x:38.40056,y:-79.99583},
{x:41.29699,y:-72.75945},
{x:26.688,y:-80.08989},
{x:38.74224,y:-90.36425},
{x:38.74193,y:-90.36208},
{x:38.93357,y:-77.08564},
{x:40.66221,y:-73.71923},
{x:32.70846,y:-117.1655},
{x:38.69273,y:-121.5879},
{x:21.33153,y:-157.9204},
{x:32.21597,y:-111.04994},
{x:40.75158,y:-73.97359},
{x:28.35536,y:-80.60797},
{x:34.15116,y:-118.33619},
{x:36.08339,y:-115.15156},
{x:40.79073,y:-73.41592},
{x:36.08148,y:-115.1376},
{x:36.08085,y:-115.13935},
{x:26.52801,y:-81.75323},
{x:26.52866,y:-81.75722},
{x:26.52826,y:-81.7557},
{x:38.90256,y:-77.20389},
{x:38.88076,y:-77.11448},
{x:36.97752,y:-77.3953},
{x:37.84137,y:-77.44543},
{x:25.79851,y:-80.27283},
{x:38.89614,y:-77.13953},
{x:36.16543,y:-86.78049},
{x:21.33354,y:-157.92395},
{x:41.19031,y:-73.43131},
{x:38.86059,y:-77.05033},
{x:42.36357,y:-71.02497},
{x:36.08046,y:-115.14668},
{x:40.02751,y:-76.22321},
{x:33.94642,y:-118.39094},
{x:38.25349,y:-85.75492},
{x:28.47869,y:-81.45677},
{x:27.96839,y:-82.5657},
{x:47.61758,y:-122.19314},
{x:38.60715,y:-121.26915},
{x:40.97011,y:-73.95502},
{x:39.34401,y:-84.26723},
{x:26.52509,y:-82.19171},
{x:41.98322,y:-87.84101},
{x:43.13695,y:-76.2265},
{x:42.3479,y:-71.03941},
{x:38.95295,y:-77.44934},
{x:38.94539,y:-77.44694},
{x:33.48488,y:-79.09347},
{x:33.74115,y:-78.8183},
{x:40.85677,y:-82.26206},
{x:42.9668,y:-85.67219},
{x:39.87592,y:-74.81466},
{x:32.1356,y:-81.20691},
{x:43.03826,y:-87.91783},
{x:38.93087,y:-94.66949},
{x:38.88473,y:-77.17413},
{x:39.17746,y:-76.67036},
{x:38.80701,y:-77.05961},
{x:40.0299,y:-78.4929},
{x:28.44545,y:-81.39561},
{x:39.99779,y:-82.88177},
{x:25.7724,y:-80.18619},
{x:40.74357,y:-73.99202},
{x:40.08305,y:-75.44005},
{x:33.76158,y:-84.38509},
{x:40.43987,y:-79.99198},
{x:40.20624,y:-79.56623},
{x:30.26546,y:-97.74044},
{x:36.1049,y:-115.17538},
{x:42.96583,y:-85.67373},
{x:36.76975,y:-119.72007},
{x:40.80521,y:-74.07807},
{x:40.19495,y:-76.755},
{x:27.94224,y:-82.45506},
{x:21.33489,y:-157.92372},
{x:21.33229,y:-157.91968},
{x:39.72825,y:-75.64896},
{x:42.34624,y:-71.04264},
{x:40.75409,y:-73.68799},
{x:40.76748,y:-111.89357},
{x:43.08657,y:-79.05935},
{x:41.82286,y:-71.41846},
{x:35.139,y:-90.05219},
{x:47.62567,y:-117.53593},
{x:39.05022,y:-77.11746},
{x:47.6237,y:-117.53876},
{x:36.89744,y:-76.20332},
{x:36.89818,y:-76.20417},
{x:41.77423,y:-87.74466},
{x:35.77386,y:-78.63966},
{x:41.29158,y:-81.67572},
{x:40.52098,y:-79.81406},
{x:30.20651,y:-85.85884},
{x:41.29246,y:-81.67121},
{x:20.89427,y:-156.4374},
{x:39.76681,y:-86.16044},
{x:39.85808,y:-104.66752},
{x:21.33046,y:-157.92331},
{x:39.99681,y:-82.88477},
{x:39.99926,y:-82.88393},
{x:29.66701,y:-98.40181},
{x:21.28366,y:-157.83599},
{x:34.13639,y:-118.18934},
{x:37.33816,y:-121.97492},
{x:34.26441,y:-119.21411},
{x:27.38927,y:-82.55379},
{x:37.53601,y:-77.43512},
{x:41.89139,y:-87.62386},
{x:61.174,y:-149.98158},
{x:41.51744,y:-71.29659},
{x:40.33745,y:-80.02621},
{x:42.10671,y:-70.74897},
{x:37.5683,y:-77.53628},
{x:38.95878,y:-77.35916},
{x:38.90793,y:-77.40803},
{x:39.18022,y:-77.26628},
{x:40.75502,y:-73.97322},
{x:43.13007,y:-77.47691},
{x:38.88682,y:-77.00222},
{x:35.97441,y:-78.54199},
{x:35.59789,y:-80.87158},
{x:28.49292,y:-81.43005},
{x:28.5466,y:-81.6953},
{x:29.49079,y:-98.55161},
{x:32.96581,y:-96.64613},
{x:26.14558,y:-80.32274},
{x:42.16617,y:-88.33549},
{x:29.11009,y:-81.0282},
{x:30.4761,y:-97.79713},
{x:40.75033,y:-73.97704},
{x:32.58996,y:-97.14443},
{x:45.17315,y:-93.38844},
{x:27.95839,y:-82.72451},
{x:25.98021,y:-80.37186},
{x:45.16549,y:-93.23339},
{x:44.74374,y:-93.37764},
{x:38.87963,y:-94.60682},
{x:35.86614,y:-78.56796},
{x:40.76034,y:-73.96113},
{x:29.5368,y:-95.15164},
{x:32.60277,y:-96.93677},
{x:39.70839,y:-104.93701},
{x:39.56523,y:-104.96562},
{x:40.3937,y:-104.75008},
{x:39.24899,y:-76.81084},
{x:38.36093,y:-121.96212},
{x:35.10835,y:-80.87873},
{x:45.03708,y:-92.84064},
{x:41.94549,y:-87.70179},
{x:35.55584,y:-97.63958},
{x:45.09116,y:-93.37834},
{x:35.96658,y:-78.95849},
{x:39.46386,y:-76.31621},
{x:38.46905,y:-77.40802},
{x:40.75175,y:-73.41907},
{x:39.9642,y:-76.77835},
{x:42.32887,y:-71.06288},
{x:40.8575,y:-73.08095},
{x:29.56474,y:-98.48007},
{x:30.12622,y:-94.15319},
{x:34.70591,y:-82.25979},
{x:28.09246,y:-81.97073},
{x:30.4434,y:-90.14235},
{x:30.61055,y:-96.31491},
{x:29.35374,y:-98.43601},
{x:29.94632,y:-93.98903},
{x:35.68689,y:-88.85775},
{x:35.4432,y:-80.86172},
{x:38.94935,y:-77.11836},
{x:35.12249,y:-80.70608},
{x:27.9361,y:-82.32366},
{x:27.01311,y:-82.14242},
{x:30.43242,y:-84.24444},
{x:29.7826,y:-95.71638},
{x:29.74496,y:-95.45353},
{x:37.47409,y:-122.21832},
{x:48.46527,y:-122.33853},
{x:38.05169,y:-121.37297},
{x:34.0724,y:-117.20757},
{x:41.72776,y:-72.82669},
{x:45.48799,y:-122.78778},
{x:36.71452,y:-121.65286},
{x:37.69402,y:-121.06299},
{x:38.34874,y:-122.72053},
{x:39.09362,y:-108.60192},
{x:39.59324,y:-104.88184},
{x:40.02188,y:-105.25652},
{x:47.73485,y:-116.79149},
{x:47.36635,y:-122.20295},
{x:45.77075,y:-108.58278},
{x:40.41077,y:-74.13335},
{x:39.82161,y:-86.27969},
{x:33.86487,y:-98.53351},
{x:32.93085,y:-97.08962},
{x:33.03728,y:-96.70819},
{x:42.53424,y:-83.44136},
{x:42.38323,y:-87.96597},
{x:41.859,y:-88.14001},
{x:41.81246,y:-87.72243},
{x:41.53373,y:-88.20179},
{x:39.41644,y:-77.44231},
{x:39.16229,y:-86.49414},
{x:43.71487,y:-87.76407},
{x:41.60338,y:-87.85054},
{x:41.9296,y:-87.68436},
{x:41.17401,y:-87.85402},
{x:42.0255,y:-88.34149},
{x:42.24144,y:-87.95662},
{x:44.85994,y:-93.31142},
{x:45.05252,y:-93.36457},
{x:33.99198,y:-117.919},
{x:39.02023,y:-77.01191},
{x:32.20711,y:-110.78605},
{x:44.84165,y:-68.74104},
{x:33.68002,y:-96.61217},
{x:43.1909,y:-88.13288},
{x:33.70855,y:-116.29008},
{x:32.22306,y:-110.91464},
{x:33.62668,y:-111.88884},
{x:33.90096,y:-117.56271},
{x:34.14668,y:-118.13435},
{x:30.23552,y:-81.69476},
{x:40.54093,y:-80.18102},
{x:39.91157,y:-74.99546},
{x:41.56173,y:-93.77922},
{x:41.97502,y:-88.34166},
{x:41.80135,y:-88.16902},
{x:29.73595,y:-95.7761},
{x:39.11618,y:-77.53542},
{x:38.79485,y:-77.60714},
{x:45.12726,y:-93.26836},
{x:35.06482,y:-89.75579},
{x:37.76169,y:-121.9644},
{x:41.8505,y:-88.3402},
{x:37.70128,y:-121.74639},
{x:34.18824,y:-118.37135},
{x:33.72454,y:-116.39941},
{x:37.99066,y:-122.30719},
{x:28.08034,y:-80.66552},
{x:27.22821,y:-82.49248},
{x:26.20939,y:-81.77094},
{x:28.65678,y:-81.23734},
{x:28.91614,y:-81.29427},
{x:26.00914,y:-80.29909},
{x:40.73374,y:-73.98659},
{x:42.01269,y:-87.78042},
{x:41.52448,y:-87.46856},
{x:42.54525,y:-83.79277},
{x:45.56541,y:-94.14532},
{x:45.16851,y:-93.86024},
{x:35.0785,y:-92.3874},
{x:33.39201,y:-111.86472},
{x:41.95448,y:-87.80854},
{x:39.21897,y:-85.88524},
{x:41.56749,y:-87.50711},
{x:39.91485,y:-86.13878},
{x:41.62716,y:-86.2289},
{x:38.16142,y:-92.60404},
{x:32.39846,y:-90.14788},
{x:40.90586,y:-74.55941},
{x:40.80137,y:-74.02157},
{x:40.73236,y:-74.03589},
{x:40.87323,y:-73.90872},
{x:40.75715,y:-73.61934},
{x:40.68449,y:-73.97618},
{x:35.04977,y:-89.6906},
{x:30.09189,y:-95.63921},
{x:45.68623,y:-122.66834},
{x:43.01506,y:-87.97001},
{x:36.83545,y:-121.3896},
{x:39.88654,y:-105.07868},
{x:28.22795,y:-80.71927},
{x:26.71093,y:-80.20277},
{x:28.33214,y:-81.47178},
{x:40.45578,y:-79.93951},
{x:42.2576,y:-88.0224},
{x:41.93808,y:-88.0889},
{x:41.76943,y:-88.20015},
{x:37.72451,y:-97.2116},
{x:37.73239,y:-97.46487},
{x:35.7373,y:-78.8677},
{x:39.19719,y:-76.81363},
{x:39.19883,y:-77.24914},
{x:39.02621,y:-77.40045},
{x:47.5418,y:-122.05065},
{x:40.72577,y:-74.00557},
{x:36.32362,y:-119.67586},
{x:45.36643,y:-122.83794},
{x:33.37909,y:-112.03417},
{x:38.32255,y:-122.30787},
{x:40.80556,y:-124.14403},
{x:37.25221,y:-121.86046},
{x:33.7617,y:-117.88813},
{x:37.88353,y:-122.30775},
{x:41.35074,y:-72.87183},
{x:41.05508,y:-73.54154},
{x:40.72505,y:-73.63441},
{x:28.20026,y:-82.66624},
{x:31.984,y:-81.17495},
{x:34.04597,y:-84.28632},
{x:41.64157,y:-93.50432},
{x:41.93013,y:-87.78929},
{x:41.86765,y:-87.63072},
{x:41.12206,y:-85.06367},
{x:37.67806,y:-97.43118},
{x:39.19145,y:-76.61136},
{x:38.96849,y:-76.95492},
{x:39.06168,y:-77.15898},
{x:38.84902,y:-76.88658},
{x:32.34249,y:-90.06072},
{x:35.83727,y:-78.64233},
{x:39.96792,y:-74.91455},
{x:40.77206,y:-73.53296},
{x:40.90061,y:-73.81907},
{x:42.75656,y:-73.77059},
{x:39.65859,y:-84.10468},
{x:41.31169,y:-81.52648},
{x:39.38781,y:-84.50473},
{x:33.98156,y:-80.96841},
{x:34.93787,y:-82.27886},
{x:29.96829,y:-95.69389},
{x:32.52208,y:-97.34988},
{x:38.84479,y:-77.11863},
{x:47.17219,y:-122.17178},
{x:47.31314,y:-122.30502},
{x:34.06323,y:-117.43431},
{x:41.77075,y:-88.03876},
{x:40.68418,y:-80.1096},
{x:33.32368,y:-111.68996},
{x:33.27862,y:-111.78577},
{x:34.15258,y:-117.47248},
{x:34.13297,y:-117.64065},
{x:39.58121,y:-104.72198},
{x:41.23347,y:-73.15158},
{x:41.28006,y:-72.98755},
{x:28.79863,y:-81.33393},
{x:30.26105,y:-81.52478},
{x:38.95124,y:-77.4087},
{x:30.56092,y:-84.21538},
{x:33.75685,y:-84.34641},
{x:42.01629,y:-88.20276},
{x:39.89052,y:-88.95799},
{x:42.03382,y:-71.31062},
{x:42.40082,y:-71.00187},
{x:45.21944,y:-93.31616},
{x:39.81438,y:-94.81239},
{x:41.21631,y:-73.72258},
{x:39.97129,y:-82.89528},
{x:29.68685,y:-95.46225},
{x:29.35601,y:-98.54303},
{x:32.42183,y:-96.83793},
{x:37.53494,y:-77.52721},
{x:37.6001,y:-77.35123},
{x:42.88204,y:-87.91583},
{x:39.64954,y:-80.0011},
{x:38.21545,y:-85.59494},
{x:33.99916,y:-118.39341},
{x:34.16725,y:-118.58492},
{x:41.59839,y:-73.1139},
{x:33.88834,y:-118.39464},
{x:36.07928,y:-119.04095},
{x:39.14594,y:-121.63259},
{x:34.46829,y:-117.24308},
{x:36.84748,y:-119.78647},
{x:39.83076,y:-75.54028},
{x:29.18349,y:-81.07972},
{x:40.75025,y:-89.63525},
{x:38.297,y:-85.55718},
{x:35.52435,y:-97.56412},
{x:39.20336,y:-77.24856},
{x:33.71313,y:-78.89368},
{x:35.89297,y:-86.88079},
{x:29.87771,y:-95.64691},
{x:38.42667,y:-121.4156},
{x:38.11734,y:-121.30338},
{x:34.00176,y:-117.71872},
{x:33.67611,y:-117.66439},
{x:33.89827,y:-84.48285},
{x:42.03764,y:-88.04897},
{x:40.87642,y:-73.11898},
{x:39.5256,y:-76.35191},
{x:40.78341,y:-73.83328},
{x:35.20366,y:-89.79674},
{x:43.05105,y:-88.377},
{x:42.99699,y:-88.04498},
{x:34.21716,y:-119.07314},
{x:43.3269,y:-73.67655},
{x:25.69335,y:-80.30542},
{x:39.96092,y:-75.17144},
{x:33.4757,y:-112.21858},
{x:33.47721,y:-111.98431},
{x:34.42048,y:-118.56171},
{x:33.746,y:-117.00902},
{x:34.09138,y:-118.14698},
{x:37.75754,y:-121.45962},
{x:26.04779,y:-80.25147},
{x:28.49585,y:-82.59843},
{x:33.87451,y:-84.01581},
{x:41.63008,y:-93.69975},
{x:42.5905,y:-114.46302},
{x:42.01945,y:-87.68504},
{x:41.83957,y:-87.74168},
{x:37.98002,y:-100.8431},
{x:39.3712,y:-76.45213},
{x:38.62502,y:-76.91931},
{x:38.95234,y:-76.7225},
{x:42.13773,y:-83.22475},
{x:46.86052,y:-96.85284},
{x:41.31667,y:-81.82839},
{x:30.23399,y:-97.82831},
{x:38.29974,y:-77.51286},
{x:38.9552,y:-77.37031},
{x:38.65461,y:-77.28877},
{x:43.99856,y:-88.57786},
{x:42.72227,y:-88.99867},
{x:42.1391,y:-71.14859},
{x:35.05514,y:-78.9749},
{x:36.77143,y:-76.23185},
{x:30.25385,y:-81.39047},
{x:33.64169,y:-117.59812},
{x:32.61748,y:-85.4108},
{x:33.97799,y:-117.55405},
{x:33.94295,y:-117.99655},
{x:28.59512,y:-81.28778},
{x:40.1122,y:-75.28563},
{x:40.851,y:-73.93842},
{x:26.80484,y:-80.08527},
{x:27.95698,y:-82.50834},
{x:32.33608,y:-111.04886},
{x:42.52731,y:-71.71378},
{x:39.33472,y:-76.44873},
{x:42.90795,y:-85.54144},
{x:42.84596,y:-85.61586},
{x:42.63316,y:-83.75591},
{x:44.93638,y:-93.39244},
{x:35.44889,y:-82.5366},
{x:40.45266,y:-80.16444},
{x:41.31534,y:-96.02578},
{x:43.21133,y:-77.45064},
{x:40.82491,y:-72.98894},
{x:40.80086,y:-81.49697},
{x:40.03826,y:-83.12257},
{x:41.42017,y:-81.8952},
{x:40.06505,y:-83.02062},
{x:36.04279,y:-86.77857},
{x:29.77195,y:-94.97628},
{x:32.72802,y:-97.78535},
{x:40.69068,y:-73.68551},
{x:30.63104,y:-97.69872},
{x:32.8139,y:-97.42976},
{x:30.4029,y:-97.85032},
{x:38.12888,y:-78.44098},
{x:38.41733,y:-122.70929},
{x:38.8965,y:-76.82682},
{x:40.7419,y:-73.60025},
{x:42.67737,y:-82.82888},
{x:35.18434,y:-111.66142},
{x:37.33979,y:-121.84042},
{x:33.85729,y:-118.00143},
{x:33.86907,y:-118.3573},
{x:39.47694,y:-107.28752},
{x:30.38938,y:-86.48185},
{x:28.92868,y:-81.92668},
{x:34.09677,y:-84.17285},
{x:38.95049,y:-94.71893},
{x:30.44126,y:-91.02216},
{x:44.89376,y:-93.07845},
{x:45.00774,y:-93.16476},
{x:38.59242,y:-90.29504},
{x:39.98825,y:-82.78468},
{x:29.96788,y:-95.53082},
{x:32.75552,y:-97.35411},
{x:34.23458,y:-118.50315},
{x:33.67122,y:-117.95572},
{x:27.83667,y:-82.6843},
{x:26.54433,y:-81.87125},
{x:41.69104,y:-72.92272},
{x:25.90651,y:-80.15676},
{x:33.52708,y:-82.11849},
{x:38.3096,y:-85.7661},
{x:35.06022,y:-80.81346},
{x:35.02019,y:-80.58133},
{x:35.17264,y:-106.58111},
{x:40.61927,y:-74.03294},
{x:35.14815,y:-85.25038},
{x:28.67131,y:-81.34039},
{x:41.09753,y:-73.95312},
{x:36.06637,y:-79.5199},
{x:32.70489,y:-114.6059},
{x:33.84302,y:-118.26011},
{x:36.84026,y:-119.72832},
{x:39.78364,y:-104.89553},
{x:39.78821,y:-105.11243},
{x:39.53498,y:-104.88395},
{x:26.52631,y:-80.16557},
{x:26.48413,y:-81.7904},
{x:33.9304,y:-84.34155},
{x:41.85472,y:-87.85129},
{x:41.50983,y:-87.92385},
{x:41.54928,y:-87.79579},
{x:41.43842,y:-87.46982},
{x:39.19762,y:-76.81923},
{x:35.90415,y:-78.95305},
{x:42.94327,y:-71.4741},
{x:35.43739,y:-97.39656},
{x:40.02639,y:-76.20388},
{x:41.93579,y:-71.47635},
{x:38.59759,y:-77.33294},
{x:35.82308,y:-90.66649},
{x:34.27222,y:-118.50414},
{x:28.82212,y:-81.66387},
{x:42.28484,y:-83.20697},
{x:40.82319,y:-74.15609},
{x:31.32385,y:-89.38489},
{x:41.31751,y:-74.15308},
{x:36.28989,y:-95.84459},
{x:39.99103,y:-75.08928},
{x:29.43854,y:-98.64959},
{x:37.7187,y:-120.9435},
{x:38.43311,y:-78.8449},
{x:38.78421,y:-97.60919},
{x:26.18296,y:-97.72175},
{x:40.12596,y:-75.40724},
{x:31.55621,y:-110.25566},
{x:37.30746,y:-121.81299},
{x:33.88669,y:-118.31684},
{x:38.74603,y:-121.25494},
{x:36.62089,y:-121.84314},
{x:34.07303,y:-117.88537},
{x:40.72911,y:-73.21488},
{x:37.90457,y:-122.06436},
{x:38.67148,y:-121.15294},
{x:39.84011,y:-105.08496},
{x:29.17398,y:-82.15723},
{x:41.46932,y:-90.52506},
{x:41.70479,y:-88.12228},
{x:42.63768,y:-83.12945},
{x:43.57653,y:-84.76553},
{x:42.32821,y:-83.3024},
{x:42.45241,y:-83.34349},
{x:42.02001,y:-97.42987},
{x:35.12976,y:-106.54773},
{x:40.8616,y:-81.43147},
{x:45.43181,y:-122.57063},
{x:36.13071,y:-86.85428},
{x:40.54158,y:-111.98273},
{x:37.67305,y:-77.46328},
{x:47.6725,y:-122.10355},
{x:43.7908,y:-88.4845},
{x:37.32396,y:-122.03642},
{x:45.45388,y:-122.77939},
{x:37.08521,y:-94.47385},
{x:38.57936,y:-92.2143},
{x:41.37766,y:-81.73562},
{x:36.86547,y:-76.21244},
{x:42.50615,y:-94.15697},
{x:37.72874,y:-85.88003},
{x:37.71693,y:-87.12269},
{x:41.37213,y:-71.95519},
{x:30.4119,y:-86.71889},
{x:39.09722,y:-76.8099},
{x:40.32124,y:-79.6887},
{x:37.35085,y:-77.4101},
{x:41.23508,y:-73.03742},
{x:38.64104,y:-121.49848},
{x:37.33936,y:-121.90456},
{x:42.49527,y:-73.20637},
{x:43.9447,y:-69.9642},
{x:35.03744,y:-80.80817},
{x:42.92799,y:-72.30549},
{x:40.54862,y:-74.42669},
{x:40.01236,y:-74.95918},
{x:27.51607,y:-99.45188},
{x:40.52827,y:-74.23331},
{x:41.50543,y:-74.05861},
{x:41.04054,y:-81.69007},
{x:42.09451,y:-76.00631},
{x:45.01222,y:-122.99453},
{x:40.5359,y:-80.0684},
{x:40.68383,y:-80.30343},
{x:42.0665,y:-88.1928},
{x:41.83553,y:-87.67486},
{x:40.19568,y:-77.1572},
{x:27.64049,y:-80.44776},
{x:37.1518,y:-93.26314},
{x:35.10167,y:-77.09639},
{x:35.97197,y:-77.81309},
{x:43.11307,y:-77.54976},
{x:35.38087,y:-77.93877},
{x:41.15512,y:-81.86486},
{x:32.8562,y:-96.75479},
{x:36.67433,y:-93.22619},
{x:36.16884,y:-86.50838},
{x:42.24372,y:-83.76939},
{x:34.10973,y:-117.75968},
{x:43.06226,y:-89.52484},
{x:37.63654,y:-122.41688},
{x:42.62373,y:-71.36431},
{x:42.15313,y:-76.87226},
{x:25.96883,y:-97.51003},
{x:40.77273,y:-73.20139},
{x:42.78693,y:-83.73797},
{x:30.30318,y:-87.68296},
{x:29.77448,y:-95.38486},
{x:29.73469,y:-95.51785},
{x:33.64792,y:-117.74114},
{x:36.1097,y:-80.09933},
{x:35.7905,y:-78.5024},
{x:36.03685,y:-83.88874},
{x:38.95381,y:-76.8248},
{x:44.99684,y:-92.94595},
{x:38.76596,y:-90.76348},
{x:41.26132,y:-96.02427},
{x:33.44114,y:-84.59064},
{x:33.42359,y:-112.26939},
{x:41.23737,y:-81.35378},
{x:39.90804,y:-79.75591},
{x:30.629,y:-81.562},
{x:39.95983,y:-75.35429},
{x:40.51646,y:-80.21994},
{x:34.03981,y:-84.68395},
{x:35.10033,y:-80.98506},
{x:38.16913,y:-77.51889},
{x:41.3569,y:-81.38651},
{x:41.99091,y:-87.68337},
{x:41.42057,y:-82.67488},
{x:33.6088,y:-85.78493},
{x:29.84903,y:-95.50237},
{x:35.5838,y:-120.69859},
{x:41.84478,y:-88.00292},
{x:40.43061,y:-79.92318},
{x:33.60024,y:-111.98935},
{x:32.86233,y:-96.85426},
{x:34.00818,y:-84.57316},
{x:41.36209,y:-89.12902},
{x:28.86583,y:-96.99915},
{x:37.3271,y:-89.5636},
{x:25.68555,y:-80.43754},
{x:25.67754,y:-80.32068},
{x:38.79966,y:-77.31948},
{x:42.3369,y:-83.39074},
{x:44.03192,y:-91.62079},
{x:37.55908,y:-122.28368},
{x:45.06615,y:-93.25143},
{x:42.02321,y:-88.14587},
{x:45.45897,y:-98.44223},
{x:40.77911,y:-73.94747},
{x:44.10406,y:-121.30085},
{x:40.82105,y:-91.17233},
{x:33.80972,y:-118.28639},
{x:38.96587,y:-92.37556},
{x:42.86118,y:-73.77933},
{x:41.52616,y:-93.6006},
{x:44.87581,y:-93.32374},
{x:36.76875,y:-108.14763},
{x:40.97574,y:-90.38789},
{x:39.13306,y:-76.63456},
{x:47.20956,y:-93.52691},
{x:29.73533,y:-95.56789},
{x:36.34248,y:-82.37349},
{x:42.86847,y:-78.6975},
{x:40.72206,y:-99.08582},
{x:40.1404,y:-105.1308},
{x:44.6842,y:-90.16324},
{x:45.00479,y:-93.22993},
{x:33.63923,y:-112.06887},
{x:38.56276,y:-121.49852},
{x:34.10401,y:-117.82364},
{x:41.87021,y:-103.64288},
{x:38.9447,y:-76.7339},
{x:47.65639,y:-117.22055},
{x:27.05027,y:-82.39099},
{x:47.51084,y:-92.54967},
{x:36.91527,y:-121.77431},
{x:42.40797,y:-87.85519},
{x:39.74731,y:-74.99403},
{x:26.24445,y:-98.25408},
{x:48.14937,y:-122.19218},
{x:42.87889,y:-78.69881},
{x:40.24612,y:-76.80641},
{x:29.54815,y:-95.75119},
{x:43.19713,y:-77.57628},
{x:43.2329,y:-70.8818},
{x:40.05658,y:-80.59912},
{x:41.89107,y:-72.65216},
{x:41.18154,y:-73.25247},
{x:42.11079,y:-70.93055},
{x:43.47446,y:-70.51359},
{x:46.79901,y:-100.78357},
{x:34.60431,y:-92.53041},
{x:33.82369,y:-84.36306},
{x:36.65854,y:-76.23413},
{x:33.63295,y:-84.01643},
{x:34.00456,y:-117.82165},
{x:41.11174,y:-85.13624},
{x:36.11305,y:-79.87867},
{x:40.65244,y:-74.57662},
{x:37.66818,y:-122.11982},
{x:29.91906,y:-95.60519},
{x:33.68922,y:-117.34284},
{x:38.87002,y:-121.304},
{x:33.5515,y:-101.94664},
{x:39.42311,y:-75.04032},
{x:26.19752,y:-98.2862},
{x:43.5954,y:-116.5371},
{x:41.79764,y:-88.36047},
{x:40.00819,y:-75.28864},
{x:41.6384,y:-71.00249},
{x:30.2191,y:-85.86858},
{x:40.52655,y:-80.00768},
{x:40.21095,y:-75.49845},
{x:29.60643,y:-98.59696},
{x:41.58261,y:-73.0343},
{x:40.61019,y:-111.98381},
{x:35.74037,y:-77.96527},
{x:34.1927,y:-118.60254},
{x:45.04544,y:-93.5294},
{x:38.84594,y:-77.30347},
{x:39.95634,y:-104.78039},
{x:31.75879,y:-106.26521},
{x:33.12901,y:-96.73069},
{x:39.5133,y:-104.77312},
{x:26.59266,y:-80.20238},
{x:35.4303,y:-80.6981},
{x:39.95872,y:-104.99566},
{x:33.18959,y:-97.09886},
{x:35.23567,y:-97.48273},
{x:40.77612,y:-73.9502},
{x:27.3867,y:-82.4585},
{x:36.03959,y:-115.19723},
{x:25.8101,y:-80.19385},
{x:42.51334,y:-83.22508},
{x:39.56734,y:-76.97273},
{x:39.12741,y:-94.83083},
{x:30.2237,y:-81.8179},
{x:32.64311,y:-97.06055},
{x:30.28526,y:-81.45668},
{x:33.69999,y:-117.82735},
{x:40.7619,y:-73.9818},
{x:45.2937,y:-93.77295},
{x:33.70969,y:-112.27601},
{x:39.74977,y:-89.71014},
{x:42.29603,y:-85.66782},
{x:41.15639,y:-81.40988},
{x:40.17682,y:-74.87778},
{x:44.96963,y:-93.44732},
{x:35.08574,y:-106.5277},
{x:36.13444,y:-86.89218},
{x:39.94255,y:-77.63179},
{x:41.45833,y:-81.68912},
{x:38.4832,y:-77.96738},
{x:31.26729,y:-85.4487},
{x:41.72111,y:-87.72507},
{x:34.07568,y:-117.56142},
{x:40.45992,y:-75.36861},
{x:38.55111,y:-121.53524},
{x:42.56163,y:-83.08473},
{x:43.97161,y:-75.96028},
{x:26.5319,y:-80.0838},
{x:40.73697,y:-73.9964},
{x:33.17023,y:-117.21476},
{x:39.98792,y:-74.17413},
{x:40.05847,y:-74.14968},
{x:27.81217,y:-82.75599},
{x:28.02783,y:-82.54868},
{x:27.33777,y:-82.46269},
{x:41.12054,y:-81.4774},
{x:40.05663,y:-82.92084},
{x:40.72812,y:-96.66598},
{x:29.65528,y:-98.44679},
{x:38.90277,y:-77.03439},
{x:33.49018,y:-82.08137},
{x:46.88803,y:-114.03704},
{x:38.9546,y:-104.768},
{x:43.1595,y:-76.12123},
{x:41.46817,y:-81.77138},
{x:40.24987,y:-77.00987},
{x:40.00649,y:-75.21244},
{x:33.93764,y:-117.18905},
{x:26.27046,y:-81.73973},
{x:33.81664,y:-118.18194},
{x:38.64661,y:-77.3346},
{x:27.23378,y:-80.26815},
{x:44.95434,y:-93.15539},
{x:27.89547,y:-82.33342},
{x:43.0146,y:-89.48236},
{x:32.90876,y:-96.55811},
{x:34.52809,y:-117.22265},
{x:42.09412,y:-76.00172},
{x:43.11917,y:-89.30769},
{x:42.99843,y:-78.81754},
{x:40.08979,y:-83.08983},
{x:41.65677,y:-71.45301},
{x:41.34214,y:-73.07957},
{x:42.0243,y:-71.1297},
{x:39.74636,y:-105.0555},
{x:41.45827,y:-81.8507},
{x:34.14308,y:-118.25862},
{x:38.54302,y:-76.97923},
{x:42.3667,y:-83.33424},
{x:33.6101,y:-117.66689},
{x:37.15738,y:-121.65421},
{x:40.38243,y:-74.57419},
{x:27.2683,y:-80.4296},
{x:39.00914,y:-77.43934},
{x:28.55271,y:-80.84077},
{x:38.53475,y:-90.98167},
{x:33.228,y:-86.8139},
{x:32.4735,y:-86.4258},
{x:33.74567,y:-118.01206},
{x:31.20173,y:-81.48204},
{x:32.55906,y:-93.70742},
{x:34.00707,y:-81.19633},
{x:31.09198,y:-97.38571},
{x:36.63628,y:-82.11027},
{x:38.96355,y:-78.19202},
{x:30.60132,y:-87.12694},
{x:33.4296,y:-111.90523},
{x:28.17318,y:-82.46997},
{x:28.01603,y:-82.61284},
{x:45.13675,y:-93.47563},
{x:44.74886,y:-93.28951},
{x:41.4148,y:-73.66913},
{x:26.67047,y:-81.93522},
{x:30.52847,y:-97.81341},
{x:43.95416,y:-92.46544},
{x:36.22552,y:-119.31533},
{x:40.62301,y:-80.01423},
{x:44.88434,y:-93.24849},
{x:30.16377,y:-97.79319},
{x:33.21916,y:-96.63291},
{x:34.57403,y:-118.04812},
{x:28.52057,y:-81.58505},
{x:40.77121,y:-73.95682},
{x:41.29158,y:-96.18027},
{x:33.47938,y:-84.44382},
{x:36.84665,y:-76.02184},
{x:33.17525,y:-96.84386},
{x:33.52236,y:-112.09332},
{x:34.01446,y:-84.18906},
{x:42.86513,y:-85.68574},
{x:33.7932,y:-84.39936},
{x:33.87979,y:-118.21946},
{x:40.31496,y:-74.66039},
{x:39.88096,y:-75.52757},
{x:26.29695,y:-80.25233},
{x:34.59327,y:-86.95676},
{x:25.85367,y:-80.31756},
{x:33.98517,y:-84.42881},
{x:36.66776,y:-121.81083},
{x:40.99242,y:-74.31236},
{x:41.77727,y:-70.74724},
{x:41.41325,y:-82.2408},
{x:43.04639,y:-76.23612},
{x:35.21505,y:-80.83331},
{x:39.9549,y:-83.1261},
{x:38.65008,y:-121.0624},
{x:33.60715,y:-86.80676},
{x:40.984,y:-81.49321},
{x:36.55093,y:-82.49415},
{x:42.59869,y:-88.41343},
{x:43.15851,y:-86.20898},
{x:32.04162,y:-81.06814},
{x:38.91685,y:-77.03705},
{x:38.46904,y:-77.40811},
{x:41.82105,y:-73.07745},
{x:38.05806,y:-78.94642},
{x:33.5792,y:-112.15246},
{x:38.44564,y:-107.86765},
{x:42.74043,y:-71.16137},
{x:38.81949,y:-90.88071},
{x:39.87736,y:-83.03704},
{x:41.68641,y:-81.29315},
{x:47.49648,y:-122.20035},
{x:42.35886,y:-71.15435},
{x:38.5112,y:-90.0146},
{x:37.65203,y:-77.60595},
{x:42.78818,y:-71.11522},
{x:46.25903,y:-119.31311},
{x:37.63954,y:-77.51151},
{x:37.30505,y:-121.86293},
{x:32.53399,y:-94.72273},
{x:26.40659,y:-81.80604},
{x:29.46596,y:-95.08488},
{x:38.73972,y:-77.53108},
{x:41.21424,y:-96.10791},
{x:29.47706,y:-81.21922},
{x:33.25404,y:-111.64182},
{x:35.9766,y:-86.5626},
{x:35.74152,y:-86.93733},
{x:44.31014,y:-69.80653},
{x:40.63126,y:-73.94724},
{x:35.05173,y:-114.59151},
{x:42.26963,y:-83.5032},
{x:35.25597,y:-84.84224},
{x:38.92963,y:-77.03365},
{x:32.64985,y:-96.86234},
{x:38.78348,y:-76.08736},
{x:34.18088,y:-83.9105},
{x:40.53826,y:-79.83651},
{x:30.26182,y:-91.99457},
{x:28.23319,y:-81.64372},
{x:25.94029,y:-80.29101},
{x:36.05564,y:-96.00149},
{x:34.26992,y:-119.24904},
{x:36.7554,y:-76.00963},
{x:34.03337,y:-117.91201},
{x:30.21721,y:-95.56512},
{x:39.65307,y:-105.00906},
{x:44.72351,y:-93.1751},
{x:34.19091,y:-118.93454},
{x:32.27316,y:-95.30935},
{x:34.26137,y:-119.23641},
{x:40.22784,y:-74.93705},
{x:38.67289,y:-121.27414},
{x:39.2956,y:-84.30497},
{x:30.00058,y:-95.17267},
{x:35.34997,y:-80.85933},
{x:26.32001,y:-80.15119},
{x:26.28458,y:-80.20454},
{x:37.3397,y:-120.5946},
{x:40.52721,y:-105.07884},
{x:26.51047,y:-81.94371},
{x:34.10004,y:-84.27053},
{x:29.49293,y:-98.70997},
{x:41.6824,y:-88.4722},
{x:33.3302,y:-86.9308},
{x:26.6154,y:-81.8018},
{x:31.07171,y:-97.67012},
{x:37.05673,y:-120.87195},
{x:61.57792,y:-149.40297},
{x:39.22139,y:-78.13701},
{x:33.83086,y:-117.94199},
{x:41.79355,y:-71.3253},
{x:43.00996,y:-78.20955},
{x:30.66423,y:-96.32528},
{x:38.62032,y:-90.51782},
{x:36.60123,y:-79.41651},
{x:38.57134,y:-89.98516},
{x:42.90501,y:-88.03902},
{x:36.03853,y:-114.99464},
{x:39.86537,y:-86.11904},
{x:39.2609,y:-94.65854},
{x:33.91587,y:-117.95759},
{x:38.91566,y:-77.51164},
{x:37.80802,y:-121.29883},
{x:32.65386,y:-117.06548},
{x:29.73134,y:-98.07367},
{x:34.96335,y:-89.89813},
{x:37.93249,y:-122.32732},
{x:32.75018,y:-97.4334},
{x:39.71062,y:-74.28565},
{x:40.71615,y:-74.28538},
{x:41.72266,y:-71.47567},
{x:34.99955,y:-80.69679},
{x:39.41091,y:-76.76859},
{x:37.25898,y:-76.6381},
{x:41.2439,y:-76.83544},
{x:38.6701,y:-121.72424},
{x:61.22637,y:-149.74693},
{x:33.9221,y:-118.32144},
{x:28.51546,y:-81.38053},
{x:38.9911,y:-76.5475},
{x:39.07346,y:-84.31344},
{x:39.93476,y:-75.02558},
{x:34.0234,y:-118.37379},
{x:28.38623,y:-80.7048},
{x:37.10743,y:-76.49221},
{x:32.73849,y:-116.93859},
{x:37.50407,y:-77.60495},
{x:39.73318,y:-75.03979},
{x:42.60343,y:-83.29337},
{x:38.668,y:-76.87584},
{x:41.0769,y:-85.20235},
{x:41.8493,y:-71.9206},
{x:36.11656,y:-115.13919},
{x:34.75435,y:-92.41497},
{x:42.79201,y:-78.78371},
{x:44.70377,y:-73.49266},
{x:37.58647,y:-77.50109},
{x:32.82193,y:-117.17908},
{x:42.15247,y:-71.07304},
{x:39.39404,y:-76.58163},
{x:41.23071,y:-73.22702},
{x:33.45894,y:-112.39546},
{x:34.42647,y:-117.3851},
{x:33.68022,y:-117.17264},
{x:29.73259,y:-95.62745},
{x:44.94933,y:-93.23636},
{x:45.27796,y:-93.56334},
{x:37.53139,y:-77.3567},
{x:40.84504,y:-74.81129},
{x:37.5685,y:-97.24095},
{x:44.8498,y:-93.77374},
{x:44.084,y:-103.21329},
{x:39.59137,y:-104.80507},
{x:39.53547,y:-119.71739},
{x:33.87728,y:-118.16599},
{x:36.36054,y:-119.29684},
{x:33.76053,y:-118.2935},
{x:40.84154,y:-76.83648},
{x:30.39274,y:-97.74443},
{x:35.48208,y:-97.76038},
{x:29.51941,y:-98.50484},
{x:40.79386,y:-73.97274},
{x:41.61101,y:-72.90186},
{x:39.76671,y:-75.13367},
{x:47.11318,y:-122.29173},
{x:42.63082,y:-82.91212},
{x:35.33835,y:-94.38926},
{x:42.52383,y:-87.90787},
{x:40.39788,y:-75.92928},
{x:21.35582,y:-157.92866},
{x:21.33198,y:-158.09219},
{x:40.19799,y:-74.8637},
{x:40.9622,y:-74.13087},
{x:33.55507,y:-111.87749},
{x:41.27985,y:-72.59841},
{x:42.35055,y:-71.11455},
{x:38.9519,y:-77.34747},
{x:40.68467,y:-73.97763},
{x:41.72785,y:-73.93129},
{x:38.88844,y:-77.09082},
{x:41.77286,y:-88.14976},
{x:34.14002,y:-118.0184},
{x:40.40763,y:-79.91707},
{x:39.97636,y:-76.67673},
{x:43.02844,y:-77.43866},
{x:42.05802,y:-71.06959},
{x:39.27686,y:-76.83092},
{x:40.87864,y:-72.52141},
{x:40.56337,y:-74.29849},
{x:40.04207,y:-75.48767},
{x:40.82222,y:-73.40989},
{x:37.27617,y:-76.74708},
{x:41.87916,y:-71.06621},
{x:40.8099,y:-73.5014},
{x:38.93833,y:-77.33029},
{x:38.85923,y:-77.38826},
{x:40.61725,y:-80.09406},
{x:40.45446,y:-80.15704},
{x:38.83638,y:-77.43731},
{x:40.61974,y:-74.24888},
{x:41.7252,y:-72.75733},
{x:42.20129,y:-71.83984},
{x:42.28569,y:-71.60747},
{x:39.95252,y:-75.16668},
{x:39.37591,y:-77.41157},
{x:40.83539,y:-73.86151},
{x:40.46109,y:-79.94758},
{x:39.95306,y:-75.19221},
{x:40.10459,y:-75.21334},
{x:41.69818,y:-72.7244},
{x:41.39727,y:-72.85359},
{x:40.30854,y:-74.30362},
{x:40.94613,y:-74.07136},
{x:36.76224,y:-76.0105},
{x:37.50652,y:-77.60337},
{x:40.24051,y:-74.30535},
{x:40.53019,y:-80.01056},
{x:38.92959,y:-77.05532},
{x:37.04894,y:-76.39184},
{x:38.96255,y:-77.08686},
{x:26.42629,y:-80.14595},
{x:36.79309,y:-76.11965},
{x:41.43245,y:-82.68764},
{x:40.55703,y:-74.34318},
{x:39.16012,y:-84.41811},
{x:29.52815,y:-98.47264},
{x:40.10375,y:-75.12593},
{x:33.76995,y:-84.38538},
{x:33.64354,y:-84.44244},
{x:40.60435,y:-73.97192},
{x:42.05338,y:-87.67245},
{x:30.30102,y:-93.20279},
{x:42.51565,y:-71.10402},
{x:34.75027,y:-86.763},
{x:34.73973,y:-86.66647},
{x:42.66586,y:-71.11514},
{x:39.8018,y:-75.50474},
{x:40.6408,y:-79.93873},
{x:38.30335,y:-76.52178},
{x:38.03404,y:-79.03767},
{x:38.90708,y:-77.00101},
{x:39.46453,y:-74.49667},
{x:32.86367,y:-96.80215},
{x:40.45108,y:-79.93488},
{x:37.41164,y:-77.64663},
{x:41.0964,y:-73.95536},
{x:40.71573,y:-74.00311},
{x:38.89662,y:-77.0268},
{x:30.66455,y:-96.3717},
{x:30.24585,y:-92.66029},
{x:39.68327,y:-75.74726},
{x:25.8369,y:-80.32334},
{x:40.71376,y:-74.01338},
{x:40.82608,y:-73.95011},
{x:46.28117,y:-119.18787},
{x:44.08393,y:-70.20332},
{x:40.05267,y:-76.25381},
{x:40.74521,y:-73.99087},
{x:39.44683,y:-74.63398},
{x:38.07813,y:-78.478},
{x:42.32545,y:-71.2565},
{x:33.57547,y:-86.51701},
{x:36.0918,y:-84.1399},
{x:42.95797,y:-78.87002},
{x:40.75366,y:-73.97679},
{x:32.74876,y:-97.32845},
{x:33.68793,y:-78.88362},
{x:32.9424,y:-97.05996},
{x:40.75686,y:-73.97263},
{x:21.97773,y:-159.34888},
{x:39.74588,y:-104.99763},
{x:42.34896,y:-71.06415},
{x:37.60217,y:-122.37054},
{x:28.47717,y:-81.46901},
{x:38.87189,y:-77.26132},
{x:35.042,y:-89.98161},
{x:42.33771,y:-71.10471},
{x:40.20979,y:-76.74316},
{x:42.35592,y:-71.05282},
{x:43.51302,y:-83.95997},
{x:41.81932,y:-71.41288},
{x:32.78485,y:-96.80039},
{x:35.13149,y:-78.99621},
{x:32.60239,y:-85.48703},
{x:33.4549,y:-88.79014},
{x:35.9695,y:-79.92739},
{x:38.72086,y:-75.12049},
{x:41.25742,y:-95.96186},
{x:42.04744,y:-88.03591},
{x:32.49338,y:-93.6757},
{x:32.73715,y:-97.34094},
{x:41.04668,y:-73.70075},
{x:42.2872,y:-71.15257},
{x:35.1532,y:-90.04179},
{x:29.38274,y:-98.61351},
{x:40.74066,y:-84.11755},
{x:38.76326,y:-77.45822},
{x:35.30845,y:-80.73368},
{x:21.34222,y:-157.95147},
{x:29.18825,y:-81.04926},
{x:39.81057,y:-84.02889},
{x:35.47753,y:-97.52248},
{x:35.26013,y:-116.68439},
{x:34.92085,y:-117.92762},
{x:31.55499,y:-110.35982},
{x:40.02032,y:-75.31658},
{x:42.49818,y:-71.1259},
{x:40.82849,y:-73.19927},
{x:38.75282,y:-77.26502},
{x:38.91986,y:-77.02174},
{x:38.8967,y:-76.82348},
{x:40.24251,y:-74.83925},
{x:36.86089,y:-76.21074},
{x:40.3102,y:-75.13072},
{x:38.88302,y:-77.10365},
{x:40.79267,y:-77.86353},
{x:42.34874,y:-71.15985},
{x:39.40539,y:-76.60327},
{x:41.97504,y:-87.81083},
{x:41.8203,y:-71.35816},
{x:45.78462,y:-108.6513},
{x:39.65296,y:-104.98072},
{x:35.04076,y:-85.30961},
{x:39.11286,y:-77.53516},
{x:38.02898,y:-78.44385},
{x:33.539,y:-112.10097},
{x:40.30835,y:-75.8627},
{x:29.22855,y:-81.00877},
{x:42.34357,y:-71.54365},
{x:39.90577,y:-75.56796},
{x:39.42631,y:-77.41624},
{x:42.47491,y:-71.4516},
{x:33.72402,y:-117.79461},
{x:35.777,y:-119.2491},
{x:36.29708,y:-119.15565},
{x:41.64709,y:-72.67731},
{x:42.51855,y:-90.74208},
{x:41.4937,y:-90.53602},
{x:46.56286,y:-120.49465},
{x:39.91569,y:-75.3801},
{x:40.18714,y:-75.22792},
{x:39.91435,y:-75.3447},
{x:40.44024,y:-80.00223},
{x:39.95208,y:-75.14514},
{x:34.20853,y:-118.86798},
{x:40.3541,y:-74.3078},
{x:40.94211,y:-72.98731},
{x:38.90978,y:-77.03302},
{x:37.65605,y:-77.61922},
{x:33.71465,y:-78.87719},
{x:36.82739,y:-76.41051},
{x:33.98906,y:-81.02431},
{x:39.36077,y:-74.43707},
{x:40.00521,y:-75.23993},
{x:40.88384,y:-74.05882},
{x:30.3752,y:-87.65897},
{x:40.3011,y:-74.78318},
{x:40.22852,y:-74.04513},
{x:37.53818,y:-77.52363},
{x:37.52139,y:-77.6123},
{x:35.26584,y:-119.02506},
{x:40.73323,y:-73.98081},
{x:36.59623,y:-82.21534},
{x:36.16491,y:-86.51221},
{x:39.16263,y:-86.58042},
{x:39.93309,y:-105.08804},
{x:39.86279,y:-75.68331},
{x:35.0471,y:-89.68749},
{x:38.3802,y:-85.42999},
{x:39.63933,y:-104.79341},
{x:39.04124,y:-84.50917},
{x:40.13945,y:-75.31419},
{x:39.22471,y:-83.61951},
{x:42.20879,y:-83.35749},
{x:40.871,y:-72.79881},
{x:42.282,y:-71.80784},
{x:41.80316,y:-72.24215},
{x:41.12162,y:-73.40432},
{x:34.07156,y:-117.35335},
{x:38.71461,y:-77.26203},
{x:32.41312,y:-99.49153},
{x:33.60131,y:-96.19257},
{x:43.18638,y:-89.26523},
{x:42.94365,y:-83.73029},
{x:36.86833,y:-76.29173},
{x:28.83885,y:-82.35689},
{x:39.0553,y:-76.95481},
{x:36.80738,y:-76.35406},
{x:38.55567,y:-76.59564},
{x:41.13483,y:-81.81703},
{x:40.44093,y:-79.95762},
{x:39.3709,y:-76.46444},
{x:39.3789,y:-76.6268},
{x:40.60952,y:-73.92087},
{x:40.28974,y:-74.04725},
{x:34.07613,y:-117.61836},
{x:40.38144,y:-80.09566},
{x:30.13352,y:-95.2333},
{x:34.12121,y:-117.53591},
{x:35.46928,y:-83.29781},
{x:37.70674,y:-121.08455},
{x:31.40776,y:-103.4854},
{x:38.92271,y:-77.04299},
{x:40.56381,y:-74.1153},
{x:32.95871,y:-97.67281},
{x:45.51054,y:-93.00073},
{x:40.70617,y:-74.0057},
{x:31.21876,y:-85.3601},
{x:29.87098,y:-81.32391},
{x:34.80561,y:-86.95097},
{x:31.88345,y:-85.14507},
{x:34.84202,y:-87.63437},
{x:40.62259,y:-80.0534},
{x:40.86657,y:-74.50693},
{x:48.20706,y:-101.31397},
{x:35.48193,y:-97.50082},
{x:40.96327,y:-72.186},
{x:42.22162,y:-70.80249},
{x:41.41471,y:-73.40159},
{x:32.19766,y:-96.89748},
{x:40.4426,y:-80.00224},
{x:25.77518,y:-80.17708},
{x:40.63045,y:-74.10612},
{x:41.66085,y:-73.93105},
{x:38.88365,y:-76.99515},
{x:37.44683,y:-79.21762},
{x:42.97205,y:-78.63911},
{x:36.84146,y:-76.09386},
{x:39.93376,y:-75.03063},
{x:40.83636,y:-74.18093},
{x:40.69859,y:-74.25182},
{x:40.72626,y:-73.85277},
{x:39.52223,y:-119.79081},
{x:40.36449,y:-74.95138},
{x:40.98243,y:-73.8272},
{x:40.35677,y:-80.05219},
{x:39.93648,y:-91.35648},
{x:38.80409,y:-77.04012},
{x:42.09446,y:-79.29281},
{x:39.03833,y:-77.05697},
{x:43.11128,y:-70.73421},
{x:41.38872,y:-82.10917},
{x:40.75244,y:-73.9094},
{x:41.92999,y:-72.68461},
{x:36.82867,y:-76.13386},
{x:26.70456,y:-80.05232},
{x:41.95232,y:-72.78719},
{x:38.9963,y:-77.02623},
{x:39.02561,y:-77.50935},
{x:43.50887,y:-70.43474},
{x:38.47017,y:-77.41142},
{x:34.08355,y:-117.67087},
{x:37.66544,y:-77.45987},
{x:39.97261,y:-75.3192},
{x:40.70434,y:-74.01301},
{x:42.52054,y:-96.41904},
{x:42.89136,y:-97.39543},
{x:44.29809,y:-96.76978},
{x:47.72899,y:-122.24327},
{x:47.5851,y:-122.23288},
{x:38.08899,y:-78.47397},
{x:40.07424,y:-80.69986},
{x:40.61917,y:-73.98991},
{x:39.71581,y:-82.57934},
{x:45.03323,y:-92.84449},
{x:32.60695,y:-80.14809},
{x:33.52371,y:-81.7218},
{x:33.01524,y:-96.6555},
{x:33.03496,y:-97.07185},
{x:41.57103,y:-93.72978},
{x:35.24057,y:-82.72713},
{x:36.10535,y:-86.81852},
{x:47.49324,y:-121.78074},
{x:33.10996,y:-96.76653},
{x:40.61223,y:-74.13258},
{x:40.55207,y:-105.03833},
{x:47.69886,y:-122.02603},
{x:47.65845,y:-117.1936},
{x:48.0851,y:-122.16995},
{x:45.60672,y:-121.19954},
{x:38.93916,y:-77.54818},
{x:32.95126,y:-117.10727},
{x:47.1906,y:-122.43518},
{x:41.70479,y:-85.91598},
{x:35.1164,y:-89.89437},
{x:33.96765,y:-84.48825},
{x:33.84114,y:-84.13294},
{x:41.56937,y:-93.80539},
{x:34.14516,y:-84.24993},
{x:47.71237,y:-116.79471},
{x:39.544,y:-105.29171},
{x:39.37588,y:-104.82732},
{x:36.75486,y:-76.06488},
{x:33.50556,y:-117.14709},
{x:35.66496,y:-78.79653},
{x:40.7029,y:-80.111},
{x:40.46283,y:-106.82167},
{x:42.79374,y:-85.50812},
{x:33.96069,y:-84.14376},
{x:47.61839,y:-122.20494},
{x:32.02708,y:-81.22548},
{x:38.8185,y:-121.19034},
{x:39.54246,y:-104.94462},
{x:35.89174,y:-84.54979},
{x:38.30548,y:-85.83819},
{x:38.97226,y:-94.75946},
{x:47.70913,y:-122.31748},
{x:38.74493,y:-121.27457},
{x:47.5832,y:-122.03303},
{x:39.66654,y:-104.93719},
{x:34.23824,y:-84.19335},
{x:41.44042,y:-97.36382},
{x:48.46671,y:-122.33428},
{x:32.78442,y:-79.92863},
{x:35.06645,y:-80.77174},
{x:35.44206,y:-82.50582},
{x:34.04965,y:-84.2231},
{x:40.42871,y:-79.98403},
{x:41.71474,y:-83.69309},
{x:34.11492,y:-80.88291},
{x:47.89125,y:-122.2876},
{x:36.27538,y:-115.11921},
{x:47.09869,y:-122.29687},
{x:38.92858,y:-94.72571},
{x:35.63052,y:-82.55224},
{x:32.88885,y:-96.47095},
{x:38.00907,y:-84.45368},
{x:33.01949,y:-96.91345},
{x:47.76529,y:-122.21155},
{x:41.59578,y:-85.85636},
{x:33.79182,y:-84.27378},
{x:43.54767,y:-96.68793},
{x:39.94953,y:-83.00193},
{x:30.67429,y:-96.3366},
{x:37.73137,y:-84.274},
{x:40.75896,y:-86.32313},
{x:42.02971,y:-91.69865},
{x:58.35966,y:-134.56185},
{x:47.86258,y:-121.97914},
{x:47.5465,y:-122.06041},
{x:47.52223,y:-122.36566},
{x:37.41143,y:-122.09424},
{x:35.44362,y:-80.8958},
{x:41.56135,y:-93.77466},
{x:47.67562,y:-122.12889},
{x:43.51037,y:-112.02006},
{x:45.58612,y:-122.73811},
{x:31.29965,y:-94.72834},
{x:42.88923,y:-112.44965},
{x:42.48712,y:-90.6631},
{x:36.08216,y:-79.48425},
{x:39.7096,y:-105.08552},
{x:30.58176,y:-96.28591},
{x:38.24587,y:-85.5216},
{x:47.57908,y:-122.29977},
{x:47.54103,y:-122.22585},
{x:42.63893,y:-73.85666},
{x:32.77387,y:-117.15452},
{x:39.10416,y:-77.29169},
{x:37.72448,y:-97.24265},
{x:42.35215,y:-122.87151},
{x:33.54148,y:-82.13227},
{x:34.06335,y:-118.4439},
{x:38.83921,y:-94.7816},
{x:38.7726,y:-77.13705},
{x:39.20999,y:-76.77884},
{x:35.02247,y:-80.76123},
{x:41.69834,y:-73.88016},
{x:35.9844,y:-80.01928},
{x:47.71228,y:-122.19117},
{x:39.95185,y:-83.15634},
{x:39.21133,y:-76.93582},
{x:40.09807,y:-83.12253},
{x:47.29382,y:-122.38035},
{x:40.30296,y:-76.84502},
{x:29.56374,y:-95.56531},
{x:40.12174,y:-83.09254},
{x:29.99425,y:-95.62188},
{x:35.83088,y:-79.08666},
{x:40.80589,y:-72.9536},
{x:35.66343,y:-78.69967},
{x:42.97797,y:-78.73414},
{x:38.56689,y:-109.54943},
{x:33.76077,y:-78.96646},
{x:31.20691,y:-82.33921},
{x:45.55341,y:-122.83536},
{x:47.5388,y:-122.16454},
{x:47.5659,y:-122.00376},
{x:45.51569,y:-122.75475},
{x:36.98143,y:-120.08615},
{x:39.37718,y:-84.37604},
{x:33.99795,y:-84.08994},
{x:38.54559,y:-75.10744},
{x:35.79027,y:-78.85201},
{x:32.26856,y:-80.85932},
{x:40.41908,y:-80.04566},
{x:40.44707,y:-75.3612},
{x:34.05096,y:-83.92992},
{x:45.59475,y:-122.47648},
{x:34.46628,y:-84.46241},
{x:41.34254,y:-73.75793},
{x:47.24369,y:-122.49728},
{x:38.84475,y:-77.63874},
{x:47.65821,y:-122.31336},
{x:33.10219,y:-96.70034},
{x:40.84255,y:-74.46344},
{x:33.88442,y:-84.06058},
{x:45.52261,y:-122.60667},
{x:30.11667,y:-94.1705},
{x:35.29601,y:-80.7391},
{x:40.74135,y:-73.69843},
{x:44.09817,y:-123.12628},
{x:38.7748,y:-76.05556},
{x:41.72718,y:-86.17505},
{x:29.77402,y:-94.97813},
{x:39.35403,y:-82.97443},
{x:29.26893,y:-94.82361},
{x:48.03233,y:-122.75844},
{x:40.01179,y:-82.86499},
{x:30.00101,y:-95.16105},
{x:34.00527,y:-83.9863},
{x:32.01618,y:-80.97696},
{x:47.45329,y:-122.82886},
{x:39.03727,y:-94.41392},
{x:31.2825,y:-92.47659},
{x:30.07552,y:-95.51711},
{x:30.04017,y:-95.48624},
{x:37.99429,y:-84.21773},
{x:40.42959,y:-74.55871},
{x:38.59259,y:-90.27216},
{x:32.83816,y:-96.7709},
{x:41.24446,y:-95.82514},
{x:40.67808,y:-75.14584},
{x:41.29429,y:-96.12181},
{x:29.97096,y:-95.69},
{x:37.74262,y:-88.94549},
{x:40.71077,y:-73.36178},
{x:33.3769,y:-84.7519},
{x:40.66733,y:-73.40625},
{x:38.93257,y:-77.1804},
{x:34.75888,y:-84.99447},
{x:41.93018,y:-87.78614},
{x:32.74184,y:-79.93639},
{x:47.62472,y:-122.34726},
{x:36.04243,y:-115.24188},
{x:40.31606,y:-74.43965},
{x:39.08092,y:-77.49575},
{x:40.56406,y:-74.11466},
{x:42.90848,y:-78.77066},
{x:41.24228,y:-95.9945},
{x:39.01363,y:-95.78245},
{x:37.67699,y:-97.49693},
{x:45.47487,y:-123.84554},
{x:45.52727,y:-122.59593},
{x:42.12975,y:-83.15732},
{x:39.45415,y:-84.18199},
{x:40.43936,y:-79.76308},
{x:36.21797,y:-115.16014},
{x:38.73634,y:-82.99562},
{x:45.53476,y:-122.65454},
{x:45.56594,y:-122.66048},
{x:39.40347,y:-123.3499},
{x:33.0171,y:-96.68347},
{x:32.6605,y:-117.03394},
{x:34.28195,y:-119.30237},
{x:38.56096,y:-121.76601},
{x:37.67977,y:-122.0834},
{x:37.93422,y:-122.07241},
{x:47.65598,y:-117.21626},
{x:33.39049,y:-112.04914},
{x:33.11892,y:-96.80426},
{x:32.81835,y:-117.17903},
{x:33.73306,y:-118.29354},
{x:36.53824,y:-121.90778},
{x:35.24876,y:-112.19318},
{x:47.75459,y:-122.31281},
{x:37.53246,y:-121.95763},
{x:45.47532,y:-122.82535},
{x:32.77904,y:-116.95659},
{x:33.07186,y:-97.06375},
{x:39.52719,y:-105.30364},
{x:42.67414,y:-82.82898},
{x:37.75407,y:-122.48879},
{x:47.27238,y:-122.51526},
{x:39.47688,y:-118.78715},
{x:35.21975,y:-111.62197},
{x:36.83859,y:-121.39144},
{x:35.35826,y:-89.8942},
{x:45.49624,y:-122.53881},
{x:34.02783,y:-118.50001},
{x:43.7983,y:-123.05206},
{x:47.24912,y:-122.29544},
{x:29.50951,y:-95.18731},
{x:44.22915,y:-123.20636},
{x:33.65502,y:-112.35003},
{x:35.22583,y:-111.57857},
{x:42.33185,y:-122.8672},
{x:32.26326,y:-109.84228},
{x:34.18851,y:-119.2217},
{x:47.57,y:-122.6527},
{x:33.91908,y:-118.41336},
{x:47.15213,y:-122.35559},
{x:30.48296,y:-97.8299},
{x:37.68028,y:-121.77728},
{x:39.69599,y:-104.84565},
{x:37.98734,y:-121.24491},
{x:36.86516,y:-119.75848},
{x:38.34779,y:-122.70854},
{x:32.22044,y:-110.94304},
{x:32.29724,y:-110.97335},
{x:30.06964,y:-95.21632},
{x:35.21481,y:-114.03638},
{x:37.4331,y:-121.89733},
{x:38.37097,y:-120.79957},
{x:38.60911,y:-122.87364},
{x:48.93434,y:-122.4873},
{x:38.50193,y:-123.00038},
{x:38.68645,y:-121.33975},
{x:37.96607,y:-121.99105},
{x:47.75028,y:-117.39763},
{x:36.93817,y:-121.77717},
{x:39.23792,y:-121.03418},
{x:45.44711,y:-122.61843},
{x:47.87563,y:-122.15395},
{x:40.68674,y:-80.08259},
{x:42.76694,y:-71.49634},
{x:34.12938,y:-118.25865},
{x:30.18408,y:-97.84808},
{x:36.76429,y:-119.71872},
{x:32.91064,y:-96.85304},
{x:36.91597,y:-111.45852},
{x:45.84954,y:-122.83191},
{x:37.16734,y:-104.50944},
{x:39.68116,y:-105.12243},
{x:32.76668,y:-115.53288},
{x:45.38156,y:-122.5821},
{x:45.41846,y:-122.45605},
{x:45.66591,y:-118.80772},
{x:44.96875,y:-122.98224},
{x:38.63817,y:-121.32637},
{x:33.54666,y:-117.79353},
{x:34.03931,y:-118.55398},
{x:40.5726,y:-122.35134},
{x:38.94396,y:-121.09284},
{x:29.55461,y:-95.37967},
{x:36.71247,y:-121.65252},
{x:31.40093,y:-109.91764},
{x:32.92025,y:-96.51632},
{x:45.83289,y:-119.28574},
{x:38.2924,y:-122.46791},
{x:35.6177,y:-118.47757},
{x:32.79232,y:-117.09646},
{x:25.8623,y:-80.19357},
{x:34.53092,y:-112.4749},
{x:37.96247,y:-121.33796},
{x:38.59744,y:-77.33483},
{x:37.64672,y:-122.45188},
{x:39.32711,y:-120.20655},
{x:48.1057,y:-123.37998},
{x:36.59775,y:-121.85033},
{x:34.03518,y:-117.0702},
{x:32.97999,y:-115.54889},
{x:32.95252,y:-96.80542},
{x:47.50596,y:-117.56682},
{x:33.062,y:-111.48584},
{x:37.12959,y:-76.51372},
{x:37.77457,y:-121.92212},
{x:45.0033,y:-122.79363},
{x:46.72235,y:-116.98321},
{x:61.13725,y:-149.95603},
{x:35.40021,y:-119.09046},
{x:33.79055,y:-118.13744},
{x:31.36458,y:-110.94036},
{x:34.08575,y:-117.6697},
{x:47.20653,y:-122.44127},
{x:32.19005,y:-110.85696},
{x:48.18348,y:-117.04062},
{x:32.79888,y:-115.57157},
{x:37.30975,y:-122.01228},
{x:32.74243,y:-96.8582},
{x:38.80603,y:-104.82308},
{x:21.28373,y:-157.81443},
{x:34.02076,y:-118.81004},
{x:47.59695,y:-120.64726},
{x:32.93252,y:-97.22818},
{x:32.94308,y:-97.15202},
{x:46.37612,y:-120.3212},
{x:35.35925,y:-94.3633},
{x:32.73706,y:-117.21631},
{x:33.78113,y:-111.92253},
{x:38.96523,y:-76.78968},
{x:32.64821,y:-96.92381},
{x:35.8207,y:-78.90107},
{x:33.44215,y:-111.95559},
{x:37.9703,y:-122.52939},
{x:33.36315,y:-111.85984},
{x:32.19039,y:-110.79091},
{x:37.52111,y:-120.8832},
{x:46.07018,y:-118.33754},
{x:33.98519,y:-118.43939},
{x:33.7233,y:-112.261},
{x:39.43878,y:-77.52575},
{x:33.5244,y:-112.13103},
{x:39.57454,y:-104.99192},
{x:37.45083,y:-122.17886},
{x:33.00672,y:-97.01726},
{x:64.83547,y:-147.80865},
{x:33.6405,y:-112.1335},
{x:39.02787,y:-77.07531},
{x:39.31693,y:-76.61641},
{x:46.65486,y:-122.95375},
{x:33.04132,y:-97.03852},
{x:38.76234,y:-120.57692},
{x:46.32176,y:-120.01375},
{x:40.4063,y:-120.63436},
{x:30.22942,y:-97.79009},
{x:45.22147,y:-112.63263},
{x:47.68906,y:-114.12238},
{x:45.78153,y:-122.5466},
{x:34.11464,y:-117.77321},
{x:33.8989,y:-117.49464},
{x:33.74397,y:-118.10006},
{x:38.98619,y:-119.94376},
{x:37.80119,y:-122.21564},
{x:38.53603,y:-105.99453},
{x:33.75977,y:-118.14713},
{x:36.82513,y:-107.9946},
{x:34.03272,y:-118.47527},
{x:47.61309,y:-122.2013},
{x:44.49957,y:-103.8591},
{x:46.2555,y:-119.89255},
{x:32.93746,y:-96.60521},
{x:47.09595,y:-122.3547},
{x:32.83768,y:-109.73538},
{x:33.74745,y:-117.45353},
{x:33.41624,y:-111.87373},
{x:29.72129,y:-95.44129},
{x:37.7869,y:-121.2184},
{x:33.63876,y:-112.292},
{x:44.06317,y:-123.02385},
{x:37.12609,y:-120.25644},
{x:37.78299,y:-122.43155},
{x:38.06238,y:-122.53248},
{x:47.80996,y:-122.20592},
{x:47.30982,y:-119.55838},
{x:33.37629,y:-112.1663},
{x:32.74623,y:-96.58585},
{x:32.67097,y:-97.00353},
{x:38.90354,y:-77.01768},
{x:32.78351,y:-79.95108},
{x:37.65015,y:-122.49186},
{x:38.66509,y:-121.22398},
{x:36.80821,y:-119.74017},
{x:33.60872,y:-112.06698},
{x:47.2697,y:-122.48868},
{x:47.77868,y:-122.22027},
{x:32.58241,y:-97.12885},
{x:33.30423,y:-111.86316},
{x:40.24139,y:-75.24349},
{x:37.52057,y:-121.99094},
{x:37.33957,y:-121.9386},
{x:38.78131,y:-121.28655},
{x:41.29646,y:-72.37721},
{x:38.85243,y:-77.04251},
{x:29.71468,y:-98.06971},
{x:37.41242,y:-121.89997},
{x:33.2069,y:-87.53306},
{x:39.61394,y:-86.08204},
{x:39.88911,y:-86.12132},
{x:42.25276,y:-71.00483},
{x:28.31103,y:-81.66695},
{x:30.47426,y:-97.64129},
{x:39.76935,y:-94.80401},
{x:26.24346,y:-98.43866},
{x:38.58216,y:-90.62803},
{x:38.63251,y:-90.40471},
{x:42.72012,y:-84.49835},
{x:43.46191,y:-89.76828},
{x:35.39004,y:-97.54577},
{x:42.24935,y:-70.92227},
{x:39.96845,y:-75.57664},
{x:33.01109,y:-96.6135},
{x:42.59222,y:-114.47626},
{x:40.71966,y:-74.0105},
{x:34.68972,y:-118.16651},
{x:42.35634,y:-71.06931},
{x:18.25439,y:-66.03672},
{x:39.41075,y:-76.60937},
{x:32.76286,y:-97.16772},
{x:42.48284,y:-70.90312},
{x:40.06396,y:-86.13178},
{x:31.99375,y:-102.16825},
{x:31.12701,y:-97.72856},
{x:32.75125,y:-97.08255},
{x:40.62515,y:-73.96063},
{x:40.9396,y:-73.8344},
{x:25.77952,y:-80.25864},
{x:41.14134,y:-73.36203},
{x:35.7048,y:-114.47788},
{x:39.9809,y:-74.88802},
{x:34.72695,y:-114.31487},
{x:39.88591,y:-75.00283},
{x:34.15066,y:-114.28903},
{x:36.08824,y:-115.18587},
{x:29.75915,y:-95.36766},
{x:36.37896,y:-82.4093},
{x:35.10043,y:-89.84107},
{x:42.48477,y:-71.10157},
{x:36.62913,y:-82.55069},
{x:42.44531,y:-83.65189},
{x:35.04386,y:-85.68021},
{x:41.10806,y:-73.54699},
{x:35.19334,y:-84.85547},
{x:37.12491,y:-82.84281},
{x:33.99664,y:-86.00662},
{x:42.73086,y:-84.48306},
{x:34.78413,y:-84.7731},
{x:43.06528,y:-89.52469},
{x:32.21666,y:-98.21098},
{x:41.14811,y:-96.21359},
{x:32.95499,y:-117.0392},
{x:29.9853,y:-95.33503},
{x:40.44738,y:-80.02243},
{x:29.98421,y:-95.3354},
{x:29.98424,y:-95.33237},
{x:41.02475,y:-73.62531},
{x:39.29685,y:-76.59519},
{x:39.29601,y:-76.59289},
{x:36.15242,y:-95.9461},
{x:38.87748,y:-77.01692},
{x:34.18906,y:-83.85902},
{x:33.59963,y:-85.08095},
{x:33.47896,y:-111.92422},
{x:47.74588,y:-116.78889},
{x:47.89719,y:-122.20544},
{x:34.08674,y:-117.6936},
{x:30.51359,y:-90.46676},
{x:42.82322,y:-73.93709},
{x:42.37388,y:-71.11295},
{x:29.71663,y:-95.49829},
{x:36.10566,y:-95.9741},
{x:26.23532,y:-98.22511},
{x:33.34956,y:-111.96465},
{x:40.86389,y:-73.82735},
{x:32.20619,y:-110.84345},
{x:34.57552,y:-111.88198},
{x:34.89084,y:-116.9993},
{x:36.13632,y:-120.36462},
{x:36.87357,y:-119.74106},
{x:40.71093,y:-74.00636},
{x:42.31869,y:-71.17637},
{x:37.97988,y:-122.05606},
{x:37.69917,y:-121.81757},
{x:38.64386,y:-121.38287},
{x:40.40564,y:-120.63418},
{x:39.74034,y:-104.91976},
{x:25.67535,y:-80.3741},
{x:27.99636,y:-82.58093},
{x:33.51132,y:-84.22408},
{x:42.04846,y:-88.0921},
{x:39.80152,y:-89.6054},
{x:39.8004,y:-89.68595},
{x:39.97964,y:-86.07101},
{x:39.21594,y:-85.88253},
{x:39.04492,y:-95.7623},
{x:40.78292,y:-74.00706},
{x:32.27198,y:-95.34032},
{x:35.97472,y:-114.84562},
{x:39.47404,y:-119.78286},
{x:40.98337,y:-73.68487},
{x:40.58126,y:-73.98544},
{x:30.05026,y:-95.23371},
{x:41.73212,y:-111.83543},
{x:38.8016,y:-77.07203},
{x:47.1406,y:-122.43417},
{x:47.75707,y:-117.39538},
{x:39.03451,y:-94.58064},
{x:42.56655,y:-87.85566},
{x:41.20715,y:-73.72907},
{x:43.01877,y:-87.96735},
{x:42.71803,y:-88.98058},
{x:38.45698,y:-81.93139},
{x:33.03513,y:-85.06101},
{x:33.22133,y:-96.80446},
{x:40.12198,y:-111.63954},
{x:34.13665,y:-118.35256},
{x:32.50768,y:-95.64297},
{x:36.07431,y:-79.77468},
{x:41.04257,y:-73.57751},
{x:42.45566,y:-71.35585},
{x:32.412,y:-81.79375},
{x:33.85934,y:-118.08736},
{x:41.49108,y:-83.36796},
{x:41.48963,y:-83.36927},
{x:28.0172,y:-82.73367},
{x:36.12284,y:-115.17063},
{x:36.12212,y:-115.17031},
{x:40.77003,y:-73.95463},
{x:36.00708,y:-78.93771},
{x:37.41805,y:-121.95621},
{x:40.00441,y:-75.15268},
{x:34.13617,y:-117.90808},
{x:40.77421,y:-73.95464},
{x:42.99961,y:-85.58749},
{x:39.50964,y:-84.73554},
{x:39.6779,y:-75.64879},
{x:33.43743,y:-112.41132},
{x:37.74325,y:-119.59882},
{x:38.65736,y:-121.50999},
{x:39.25082,y:-119.94995},
{x:30.28511,y:-95.46779},
{x:28.06913,y:-82.57653},
{x:40.68527,y:-111.79431},
{x:33.95953,y:-84.13554},
{x:34.0218,y:-84.26803},
{x:17.9934,y:-66.61241},
{x:34.03961,y:-84.45985},
{x:34.01821,y:-84.312},
{x:47.21935,y:-122.46681},
{x:33.7569,y:-84.38857},
{x:40.76593,y:-73.96359},
{x:35.2021,y:-80.82453},
{x:35.21247,y:-80.8179},
{x:35.8666,y:-78.6202},
{x:37.60345,y:-122.37607},
{x:33.95005,y:-84.41183},
{x:35.15323,y:-80.82553},
{x:35.85774,y:-78.7036},
{x:35.00001,y:-80.70007},
{x:38.96385,y:-77.10765},
{x:35.79071,y:-78.6618},
{x:38.64854,y:-90.28551},
{x:42.34845,y:-71.07629},
{x:36.11473,y:-79.83562},
{x:33.99464,y:-84.35014},
{x:34.89284,y:-82.2894},
{x:26.13355,y:-80.28867},
{x:25.68798,y:-80.32001},
{x:25.74918,y:-80.26049},
{x:40.77352,y:-73.95979},
{x:33.47588,y:-84.4386},
{x:35.31301,y:-80.74292},
{x:36.11577,y:-115.17119},
{x:26.3496,y:-80.15783},
{x:26.11819,y:-80.39276},
{x:37.65391,y:-82.27028},
{x:25.69492,y:-80.30026},
{x:35.17703,y:-80.79936},
{x:33.84692,y:-84.2522},
{x:35.91342,y:-79.05543},
{x:28.49339,y:-81.46216},
{x:26.37129,y:-80.20257},
{x:40.73,y:-73.99165},
{x:25.79038,y:-80.137},
{x:34.10823,y:-84.55212},
{x:25.72797,y:-80.24258},
{x:43.12503,y:-75.4146},
{x:25.71646,y:-80.27408},
{x:26.3665,y:-80.07812},
{x:26.71317,y:-80.05173},
{x:33.97844,y:-84.41932},
{x:26.35337,y:-80.08491},
{x:26.40917,y:-80.12098},
{x:41.2456,y:-73.00936},
{x:28.45191,y:-81.48939},
{x:25.7021,y:-80.33358},
{x:40.73402,y:-111.86685},
{x:28.75561,y:-81.35283},
{x:42.3819,y:-71.11993},
{x:35.88779,y:-78.67874},
{x:25.78686,y:-80.12933},
{x:35.82485,y:-78.70722},
{x:33.58223,y:-112.42865},
{x:42.62272,y:-87.82066},
{x:33.85719,y:-84.34361},
{x:28.07841,y:-82.50745},
{x:26.03301,y:-80.19773},
{x:28.64639,y:-81.26778},
{x:26.26541,y:-80.25071},
{x:26.8399,y:-80.10623},
{x:26.70823,y:-80.05739},
{x:28.60318,y:-81.36463},
{x:35.48251,y:-80.87852},
{x:33.77805,y:-84.38419},
{x:35.20134,y:-80.84336},
{x:41.03286,y:-73.62423},
{x:25.93468,y:-80.12199},
{x:33.72276,y:-84.50706},
{x:33.94585,y:-84.62706},
{x:25.7733,y:-80.18905},
{x:27.93628,y:-82.48324},
{x:25.76162,y:-80.3331},
{x:38.05644,y:-85.53697},
{x:37.4757,y:-105.89166},
{x:35.13539,y:-89.99984},
{x:40.85805,y:-73.20628},
{x:40.76935,y:-73.07054},
{x:28.05489,y:-82.4005},
{x:36.1469,y:-86.79922},
{x:40.85728,y:-74.19836},
{x:26.16979,y:-80.25675},
{x:26.11907,y:-80.13963},
{x:42.30063,y:-71.3839},
{x:25.81303,y:-80.35385},
{x:26.36211,y:-80.12451},
{x:27.32173,y:-82.52922},
{x:34.0653,y:-83.98436},
{x:45.66668,y:-108.77031},
{x:40.74367,y:-73.97686},
{x:27.95657,y:-82.52458},
{x:33.84616,y:-84.37309},
{x:25.95257,y:-80.14618},
{x:36.1099,y:-86.81107},
{x:34.06811,y:-84.29417},
{x:34.0171,y:-84.19144},
{x:40.86598,y:-73.63113},
{x:30.37951,y:-91.0969},
{x:33.95771,y:-83.3752},
{x:26.13824,y:-80.112},
{x:42.54593,y:-70.94665},
{x:33.81615,y:-84.3346},
{x:26.3689,y:-80.16708},
{x:28.10012,y:-82.69118},
{x:28.51526,y:-81.45831},
{x:36.02989,y:-86.79133},
{x:26.01126,y:-80.17447},
{x:33.76805,y:-84.34944},
{x:32.15704,y:-80.76512},
{x:28.59459,y:-81.3505},
{x:36.30478,y:-86.61841},
{x:30.28641,y:-81.71989},
{x:35.94769,y:-86.82184},
{x:42.32642,y:-71.23021},
{x:35.11706,y:-80.82379},
{x:35.19931,y:-89.79112},
{x:27.77225,y:-82.63536},
{x:29.68896,y:-82.39442},
{x:36.14617,y:-86.80905},
{x:26.31882,y:-80.20324},
{x:33.79804,y:-84.37115},
{x:38.73748,y:-90.3557},
{x:32.07843,y:-81.09106},
{x:35.11205,y:-89.89363},
{x:35.04667,y:-89.69347},
{x:30.26104,y:-81.6459},
{x:30.25544,y:-81.46591},
{x:28.36607,y:-81.42421},
{x:26.12127,y:-80.24009},
{x:36.04171,y:-86.74579},
{x:33.88754,y:-84.47386},
{x:30.101,y:-81.71936},
{x:35.78843,y:-78.64787},
{x:36.08883,y:-79.88575},
{x:28.50904,y:-81.37607},
{x:30.18511,y:-93.22019},
{x:30.13986,y:-91.9487},
{x:34.09329,y:-84.23666},
{x:41.7494,y:-88.03819},
{x:27.96548,y:-82.80012},
{x:26.09701,y:-80.38145},
{x:32.80027,y:-79.89024},
{x:38.94908,y:-77.33883},
{x:33.39633,y:-84.59173},
{x:28.55722,y:-81.20651},
{x:40.7849,y:-73.97869},
{x:25.65705,y:-80.32748},
{x:35.22657,y:-80.84324},
{x:35.92412,y:-86.87155},
{x:30.3039,y:-81.65483},
{x:32.02837,y:-81.1102},
{x:30.32095,y:-81.45841},
{x:28.5694,y:-81.38912},
{x:36.12484,y:-86.84919},
{x:32.79306,y:-79.86774},
{x:34.96321,y:-89.98676},
{x:32.84089,y:-79.85652},
{x:32.25438,y:-80.84842},
{x:33.95752,y:-84.23209},
{x:33.97354,y:-84.48053},
{x:34.00493,y:-84.38989},
{x:36.16364,y:-86.78007},
{x:26.25474,y:-80.09989},
{x:33.48488,y:-86.77299},
{x:25.78704,y:-80.38078},
{x:40.78408,y:-73.50179},
{x:35.10117,y:-89.85751},
{x:30.20228,y:-81.6172},
{x:26.31551,y:-80.09062},
{x:27.94421,y:-82.52353},
{x:32.00473,y:-81.119},
{x:25.90028,y:-80.15997},
{x:26.68671,y:-80.20273},
{x:26.65069,y:-80.21218},
{x:27.29921,y:-82.49795},
{x:26.02609,y:-80.31361},
{x:40.69456,y:-73.99396},
{x:27.79016,y:-82.7265},
{x:25.63298,y:-80.33872},
{x:28.01419,y:-82.70945},
{x:36.11634,y:-80.31262},
{x:42.33882,y:-71.58862},
{x:35.04807,y:-89.79818},
{x:33.95521,y:-84.0524},
{x:26.14156,y:-81.79655},
{x:26.46178,y:-80.07084},
{x:28.13847,y:-82.35873},
{x:28.04345,y:-82.58102},
{x:35.83989,y:-78.643},
{x:40.89373,y:-73.97419},
{x:35.23367,y:-80.85051},
{x:35.82136,y:-78.59183},
{x:26.88406,y:-80.10719},
{x:29.29109,y:-81.04313},
{x:30.31147,y:-81.68084},
{x:30.26031,y:-81.55421},
{x:33.90594,y:-84.6026},
{x:25.68639,y:-80.31045},
{x:33.81854,y:-84.31252},
{x:26.22869,y:-80.10301},
{x:35.91391,y:-86.82907},
{x:41.78571,y:-72.74533},
{x:28.07261,y:-82.73945},
{x:29.82873,y:-81.27222},
{x:27.93101,y:-82.50553},
{x:36.03574,y:-79.96257},
{x:34.23578,y:-77.84625},
{x:40.71164,y:-74.00666},
{x:33.87863,y:-84.3802},
{x:26.306,y:-80.27803},
{x:35.9048,y:-78.60187},
{x:33.9209,y:-84.16836},
{x:44.44686,y:-73.11083},
{x:30.29394,y:-81.60501},
{x:26.19336,y:-80.25336},
{x:25.76506,y:-80.19297},
{x:25.83469,y:-80.18435},
{x:34.089,y:-84.27449},
{x:30.18813,y:-81.38027},
{x:41.80722,y:-72.55573},
{x:33.93661,y:-84.37769},
{x:28.0688,y:-82.37071},
{x:36.09042,y:-80.27502},
{x:34.23616,y:-77.87238},
{x:26.27479,y:-81.8004},
{x:25.69054,y:-80.31496},
{x:36.07355,y:-86.91905},
{x:35.67026,y:-88.85327},
{x:26.15621,y:-80.11816},
{x:41.76049,y:-72.74498},
{x:34.02985,y:-84.65545},
{x:27.93987,y:-82.32312},
{x:27.78142,y:-82.63869},
{x:35.94056,y:-83.98567},
{x:30.36008,y:-81.50482},
{x:27.91995,y:-82.49928},
{x:40.72253,y:-73.99795},
{x:36.062,y:-80.30405},
{x:27.44803,y:-82.57585},
{x:30.47267,y:-87.20954},
{x:34.24287,y:-77.82704},
{x:35.82747,y:-78.77041},
{x:27.92244,y:-82.3273},
{x:40.87126,y:-73.42755},
{x:25.9867,y:-80.12911},
{x:36.29981,y:-86.69573},
{x:30.47075,y:-84.29041},
{x:34.18,y:-84.13289},
{x:35.83154,y:-83.57277},
{x:28.48578,y:-81.43199},
{x:30.39201,y:-86.42417},
{x:33.44605,y:-86.79145},
{x:28.04957,y:-82.69579},
{x:27.38891,y:-82.4605},
{x:29.16481,y:-82.16966},
{x:30.27926,y:-81.38962},
{x:25.94398,y:-80.14715},
{x:34.06672,y:-84.16962},
{x:33.39602,y:-84.75347},
{x:25.95545,y:-81.72268},
{x:34.05963,y:-84.23539},
{x:35.13661,y:-80.93713},
{x:33.88821,y:-84.74652},
{x:26.06538,y:-80.363},
{x:34.00557,y:-84.57402},
{x:35.92141,y:-84.09299},
{x:34.77134,y:-92.35778},
{x:40.83967,y:-74.20804},
{x:29.65921,y:-82.41416},
{x:30.67562,y:-88.14481},
{x:35.58252,y:-77.37366},
{x:34.74286,y:-92.39384},
{x:29.6244,y:-82.37607},
{x:35.56651,y:-82.54467},
{x:28.60196,y:-81.31942},
{x:26.39274,y:-80.10163},
{x:35.84114,y:-78.6799},
{x:40.7891,y:-74.39332},
{x:35.90206,y:-84.15078},
{x:34.24852,y:-84.47857},
{x:27.93187,y:-82.32553},
{x:34.84924,y:-82.33864},
{x:27.26889,y:-82.48291},
{x:28.55116,y:-81.54029},
{x:35.60138,y:-82.54708},
{x:27.89394,y:-82.25129},
{x:36.33986,y:-82.37427},
{x:33.63899,y:-112.07042},
{x:33.98154,y:-118.40908},
{x:42.0518,y:-87.99628},
{x:38.0727,y:-97.9012},
{x:41.29136,y:-96.14521},
{x:36.15237,y:-115.24283},
{x:40.76755,y:-73.98294},
{x:40.76438,y:-73.98107},
{x:44.27262,y:-88.47076},
{x:34.13914,y:-118.21526},
{x:34.19789,y:-119.17943},
{x:35.41261,y:-119.05842},
{x:38.67683,y:-121.19812},
{x:38.71329,y:-120.83898},
{x:38.39365,y:-121.41817},
{x:38.55907,y:-121.46164},
{x:39.69911,y:-105.32927},
{x:28.60805,y:-81.20822},
{x:27.33738,y:-82.54259},
{x:32.89975,y:-83.68785},
{x:35.99814,y:-115.20641},
{x:30.23371,y:-97.7424},
{x:31.07268,y:-97.36953},
{x:29.82859,y:-97.98693},
{x:38.73336,y:-77.77133},
{x:33.95516,y:-118.21904},
{x:34.23462,y:-118.46726},
{x:33.65434,y:-117.77844},
{x:37.79701,y:-121.19215},
{x:37.47716,y:-120.84919},
{x:41.69253,y:-72.65524},
{x:41.63878,y:-72.8748},
{x:41.34018,y:-72.97777},
{x:25.91288,y:-80.33135},
{x:25.97995,y:-80.36019},
{x:27.3191,y:-82.57677},
{x:42.58163,y:-71.28934},
{x:35.05853,y:-106.58293},
{x:42.53171,y:-82.91513},
{x:33.64023,y:-112.01103},
{x:33.95992,y:-118.02663},
{x:34.02082,y:-117.95822},
{x:36.71969,y:-121.65553},
{x:40.18828,y:-105.07652},
{x:38.45648,y:-107.86549},
{x:38.89616,y:-77.04467},
{x:42.0643,y:-87.72682},
{x:42.82694,y:-86.0932},
{x:37.18267,y:-93.29629},
{x:40.81454,y:-96.7038},
{x:38.78356,y:-123.01116},
{x:38.63208,y:-121.22019},
{x:40.16269,y:-104.97746},
{x:26.43709,y:-81.77465},
{x:42.29657,y:-85.65573},
{x:42.29598,y:-71.29634},
{x:32.9184,y:-96.95919},
{x:47.69938,y:-117.44722},
{x:39.52303,y:-107.76974},
{x:30.18995,y:-81.55242},
{x:27.33932,y:-82.4632},
{x:40.48079,y:-88.95193},
{x:42.35504,y:-71.05726},
{x:39.93761,y:-86.23588},
{x:39.16578,y:-86.49852},
{x:37.97745,y:-87.39146},
{x:38.02117,y:-87.57431},
{x:30.18647,y:-92.0755},
{x:38.50795,y:-90.44278},
{x:36.08943,y:-79.81047},
{x:35.12803,y:-80.70285},
{x:48.22167,y:-101.29651},
{x:40.09365,y:-75.28624},
{x:32.83687,y:-97.09811},
{x:47.83877,y:-122.12377},
{x:47.23568,y:-122.52723},
{x:34.42085,y:-117.29131},
{x:33.67283,y:-117.95344},
{x:32.76828,y:-117.05334},
{x:33.8462,y:-118.08447},
{x:33.7147,y:-116.23414},
{x:35.36885,y:-119.01844},
{x:37.38886,y:-120.73574},
{x:39.15325,y:-76.72834},
{x:38.94218,y:-76.5612},
{x:35.93663,y:-79.02558},
{x:36.10066,y:-115.05242},
{x:36.19871,y:-115.28287},
{x:42.34811,y:-71.08715},
{x:39.9225,y:-75.16981},
{x:37.95449,y:-122.33205},
{x:30.6817,y:-88.22583},
{x:35.13129,y:-118.46693},
{x:38.02111,y:-122.2612},
{x:38.24643,y:-122.02006},
{x:40.80157,y:-124.16715},
{x:30.18898,y:-85.64944},
{x:39.77767,y:-86.28961},
{x:38.01717,y:-84.4185},
{x:45.15146,y:-122.88464},
{x:45.19707,y:-123.20679},
{x:45.45289,y:-122.72214},
{x:44.94457,y:-122.98315},
{x:42.29807,y:-71.38708},
{x:47.96928,y:-122.20085},
{x:42.33025,y:-71.19499},
{x:42.36391,y:-71.08809},
{x:39.12392,y:-77.23637},
{x:32.22049,y:-110.83847},
{x:32.23637,y:-110.8584},
{x:34.20006,y:-118.86752},
{x:34.09592,y:-117.87226},
{x:32.6807,y:-115.4995},
{x:34.06315,y:-118.01938},
{x:32.62859,y:-116.96574},
{x:32.79154,y:-115.57017},
{x:33.7296,y:-116.97209},
{x:37.82393,y:-122.00378},
{x:39.81681,y:-105.08094},
{x:39.7503,y:-105.05361},
{x:38.89943,y:-77.04859},
{x:42.45203,y:-71.13682},
{x:33.87351,y:-84.01344},
{x:34.29712,y:-83.85617},
{x:38.42204,y:-96.2186},
{x:30.41775,y:-91.05193},
{x:38.71687,y:-76.65929},
{x:35.5942,y:-80.86453},
{x:39.52239,y:-119.81131},
{x:39.43905,y:-119.76028},
{x:40.75664,y:-73.9859},
{x:41.14643,y:-73.49507},
{x:40.77235,y:-73.94992},
{x:44.05585,y:-121.26337},
{x:32.6784,y:-97.19734},
{x:38.653,y:-77.28603},
{x:36.76315,y:-76.25074},
{x:37.35141,y:-79.18384},
{x:47.29648,y:-122.38134},
{x:47.73295,y:-122.2919},
{x:42.8841,y:-87.91168},
{x:33.97536,y:-118.12433},
{x:33.98894,y:-118.21377},
{x:47.19189,y:-122.40691},
{x:34.45748,y:-93.06261},
{x:32.1625,y:-110.99028},
{x:32.72618,y:-117.16949},
{x:34.18652,y:-118.91061},
{x:30.28857,y:-81.4383},
{x:40.97919,y:-74.11559},
{x:35.17454,y:-106.57802},
{x:41.00264,y:-73.68133},
{x:41.52079,y:-81.43918},
{x:47.445,y:-122.15075},
{x:39.14501,y:-121.59165},
{x:33.9994,y:-118.07869},
{x:42.30325,y:-71.15206},
{x:38.5625,y:-121.76892},
{x:38.64202,y:-121.53256},
{x:26.15045,y:-80.32301},
{x:34.82105,y:-82.27998},
{x:30.44869,y:-84.30969},
{x:26.52816,y:-80.08986},
{x:26.10101,y:-80.13369},
{x:28.07849,y:-80.6446},
{x:42.17467,y:-72.57775},
{x:34.25526,y:-88.74485},
{x:42.93291,y:-72.28927},
{x:40.66923,y:-74.47991},
{x:40.7506,y:-73.97914},
{x:30.0651,y:-95.43507},
{x:33.15262,y:-96.86199},
{x:42.98849,y:-87.88344},
{x:33.98231,y:-118.24889},
{x:40.65199,y:-74.34699},
{x:33.67106,y:-117.97075},
{x:34.60721,y:-118.14726},
{x:33.61062,y:-114.60525},
{x:38.42527,y:-121.48231},
{x:35.03548,y:-120.48617},
{x:42.35946,y:-71.05937},
{x:38.91081,y:-104.71528},
{x:43.59956,y:-116.39335},
{x:43.61747,y:-116.35515},
{x:41.65255,y:-88.20447},
{x:41.78608,y:-88.37619},
{x:38.4054,y:-75.5687},
{x:39.44845,y:-77.38802},
{x:43.9062,y:-69.9157},
{x:40.78611,y:-73.726},
{x:40.6,y:-73.65413},
{x:45.43572,y:-122.57272},
{x:44.63739,y:-124.05255},
{x:44.98954,y:-124.00529},
{x:42.06479,y:-80.09952},
{x:39.999,y:-78.23745},
{x:39.95344,y:-75.17305},
{x:47.56345,y:-122.6252},
{x:47.85696,y:-122.2203},
{x:47.66457,y:-117.28244},
{x:42.65639,y:-71.14028},
{x:38.42218,y:-82.44373},
{x:37.96537,y:-122.34453},
{x:34.473,y:-114.34252},
{x:32.33719,y:-111.04996},
{x:34.13356,y:-117.63813},
{x:35.35579,y:-119.09264},
{x:36.60313,y:-119.45989},
{x:33.57977,y:-117.66892},
{x:39.88078,y:-104.98397},
{x:27.2364,y:-80.26825},
{x:28.18665,y:-82.4403},
{x:27.17666,y:-80.23615},
{x:25.86697,y:-80.29338},
{x:39.75184,y:-89.70741},
{x:39.77413,y:-85.99627},
{x:42.3888,y:-71.14245},
{x:42.98839,y:-83.7405},
{x:35.91271,y:-78.78077},
{x:40.73938,y:-96.67917},
{x:41.52786,y:-83.63986},
{x:39.92898,y:-82.83042},
{x:39.94786,y:-75.154},
{x:36.01434,y:-84.25522},
{x:30.53275,y:-97.78361},
{x:33.45782,y:-94.08899},
{x:30.16376,y:-95.46432},
{x:36.85895,y:-75.97904},
{x:47.82384,y:-122.27134},
{x:47.66227,y:-122.28093},
{x:39.03995,y:-83.9248},
{x:32.20679,y:-110.79102},
{x:33.97506,y:-117.5609},
{x:32.74022,y:-116.94096},
{x:34.07141,y:-117.20427},
{x:33.47528,y:-117.12766},
{x:35.64186,y:-120.68702},
{x:37.59517,y:-122.38611},
{x:37.71482,y:-122.14168},
{x:37.84661,y:-122.29167},
{x:37.28564,y:-121.98222},
{x:39.69088,y:-105.11717},
{x:39.69435,y:-104.941},
{x:41.54947,y:-88.19905},
{x:40.69948,y:-89.61277},
{x:39.95705,y:-86.11299},
{x:39.85469,y:-86.08549},
{x:44.08395,y:-93.2412},
{x:35.3183,y:-106.54317},
{x:35.46088,y:-97.62027},
{x:36.04629,y:-86.65529},
{x:35.97903,y:-86.56324},
{x:32.86061,y:-97.31601},
{x:40.70575,y:-112.02445},
{x:47.75548,y:-122.66104},
{x:47.65671,y:-117.2385},
{x:46.26299,y:-119.26641},
{x:33.74813,y:-117.86985},
{x:34.11056,y:-117.53384},
{x:32.78061,y:-116.95649},
{x:33.93996,y:-117.2784},
{x:37.37057,y:-118.39595},
{x:32.87292,y:-117.21751},
{x:28.90821,y:-81.9742},
{x:42.5124,y:-92.43578},
{x:41.80091,y:-88.27375},
{x:41.96452,y:-87.70884},
{x:37.9751,y:-87.47358},
{x:42.53507,y:-71.74165},
{x:43.15252,y:-86.20447},
{x:42.15271,y:-71.40472},
{x:44.71348,y:-93.28016},
{x:46.84984,y:-96.7669},
{x:35.98451,y:-80.02446},
{x:35.60592,y:-77.41193},
{x:40.20586,y:-74.69492},
{x:40.23868,y:-76.93473},
{x:43.31937,y:-87.92593},
{x:43.07494,y:-89.44756},
{x:39.71124,y:-104.97298},
{x:61.14487,y:-149.86312},
{x:33.71107,y:-112.11144},
{x:33.28527,y:-111.84099},
{x:32.32184,y:-110.92871},
{x:37.14397,y:-121.6645},
{x:36.93839,y:-121.77713},
{x:37.98386,y:-121.34666},
{x:37.67776,y:-120.97624},
{x:37.76223,y:-121.43332},
{x:39.47181,y:-104.87657},
{x:30.30875,y:-89.82617},
{x:30.21109,y:-90.94964},
{x:42.40432,y:-71.08127},
{x:39.46281,y:-76.63626},
{x:35.11461,y:-106.53367},
{x:36.11515,y:-115.11814},
{x:40.76368,y:-73.98235},
{x:40.26288,y:-80.17312},
{x:32.82975,y:-79.83436},
{x:36.33282,y:-86.61956},
{x:33.17295,y:-96.64158},
{x:31.49791,y:-97.16034},
{x:41.73998,y:-85.97516},
{x:34.74929,y:-92.3445},
{x:42.22222,y:-71.02321},
{x:33.72029,y:-112.11179},
{x:34.27292,y:-118.79961},
{x:39.08855,y:-84.47799},
{x:40.75687,y:-73.83544},
{x:35.33671,y:-119.03636},
{x:40.745,y:-111.89966},
{x:32.8395,y:-79.81688},
{x:37.36595,y:-121.9258},
{x:32.89815,y:-79.8201},
{x:39.56736,y:-104.92094},
{x:33.05229,y:-117.26645},
{x:39.65185,y:-104.9142},
{x:37.79355,y:-122.39489},
{x:37.76103,y:-122.43461},
{x:37.7946,y:-122.40269},
{x:37.76417,y:-122.45638},
{x:37.792,y:-122.3977},
{x:34.059,y:-118.41325},
{x:34.05612,y:-118.24698},
{x:34.06103,y:-118.41493},
{x:34.05019,y:-118.25364},
{x:40.7607,y:-73.97882},
{x:40.75896,y:-73.97375},
{x:33.61047,y:-112.1475},
{x:34.10647,y:-117.80645},
{x:33.75967,y:-117.85284},
{x:35.39306,y:-118.96696},
{x:37.50415,y:-121.97614},
{x:39.15201,y:-123.19554},
{x:37.06143,y:-122.00708},
{x:39.4349,y:-123.80579},
{x:40.94036,y:-124.10133},
{x:38.69934,y:-121.19185},
{x:41.19899,y:-73.13372},
{x:41.71202,y:-93.5806},
{x:41.47075,y:-90.4799},
{x:38.85494,y:-94.66924},
{x:42.51999,y:-71.09385},
{x:44.83494,y:-68.74489},
{x:40.8215,y:-74.32797},
{x:36.23939,y:-115.11645},
{x:36.12595,y:-115.1895},
{x:43.02858,y:-77.44264},
{x:40.03503,y:-83.01634},
{x:34.97635,y:-80.98966},
{x:38.81227,y:-77.184},
{x:42.99032,y:-88.0478},
{x:37.99816,y:-121.87355},
{x:38.05304,y:-121.37491},
{x:33.58291,y:-117.63246},
{x:33.7228,y:-118.04179},
{x:33.59259,y:-117.12378},
{x:36.83665,y:-119.80787},
{x:41.35933,y:-72.20961},
{x:30.31769,y:-81.55776},
{x:47.71448,y:-116.89556},
{x:43.57903,y:-116.17448},
{x:29.61236,y:-90.75631},
{x:43.93988,y:-69.97771},
{x:26.18889,y:-98.23292},
{x:47.28939,y:-122.31629},
{x:47.5842,y:-122.33393},
{x:47.09282,y:-122.64577},
{x:45.68591,y:-122.60096},
{x:47.91584,y:-97.04443},
{x:36.05625,y:-115.08449},
{x:40.72555,y:-73.51636},
{x:41.38005,y:-81.7352},
{x:29.73193,y:-95.4758},
{x:32.97362,y:-97.03755},
{x:32.67095,y:-97.13404},
{x:37.65224,y:-77.62083},
{x:38.86416,y:-77.06044},
{x:47.24276,y:-122.36514},
{x:47.48577,y:-122.27281},
{x:47.29609,y:-122.2455},
{x:34.19123,y:-117.35921},
{x:37.87169,y:-122.50974},
{x:38.11659,y:-121.25503},
{x:34.06467,y:-117.96761},
{x:33.88773,y:-118.02925},
{x:34.03362,y:-118.18421},
{x:32.7649,y:-117.1971},
{x:38.00243,y:-121.73181},
{x:25.69778,y:-80.16319},
{x:31.32443,y:-89.34524},
{x:38.25848,y:-122.03357},
{x:38.66358,y:-121.22717},
{x:32.79872,y:-117.2451},
{x:34.4175,y:-119.6968},
{x:33.93934,y:-117.26195},
{x:33.23154,y:-117.30766},
{x:32.70661,y:-117.00959},
{x:33.96689,y:-118.04811},
{x:41.53929,y:-90.51912},
{x:41.85075,y:-87.95379},
{x:40.07195,y:-85.65344},
{x:39.03113,y:-77.00387},
{x:43.06554,y:-86.21962},
{x:44.93333,y:-93.41882},
{x:35.90463,y:-78.93723},
{x:35.05526,y:-80.85138},
{x:35.79157,y:-80.86183},
{x:35.78966,y:-78.87007},
{x:40.93949,y:-74.26906},
{x:39.46538,y:-119.78378},
{x:40.70269,y:-73.9909},
{x:41.15185,y:-73.82909},
{x:45.41994,y:-122.73956},
{x:29.81977,y:-95.46734},
{x:32.84277,y:-97.23826},
{x:39.06145,y:-77.47191},
{x:37.68268,y:-77.58881},
{x:48.78937,y:-122.50877},
{x:44.28678,y:-88.41468},
{x:33.87498,y:-118.14236},
{x:33.7752,y:-118.15261},
{x:34.17896,y:-118.59595},
{x:34.07882,y:-118.11461},
{x:32.82031,y:-116.96196},
{x:33.94775,y:-116.97595},
{x:34.17147,y:-119.17713},
{x:35.31769,y:-119.05487},
{x:36.74293,y:-119.77975},
{x:38.88592,y:-77.0197},
{x:27.77119,y:-82.7281},
{x:41.90535,y:-87.96669},
{x:39.40568,y:-86.43759},
{x:41.09833,y:-85.0905},
{x:42.69731,y:-83.13539},
{x:34.81402,y:-106.75578},
{x:36.11488,y:-115.09164},
{x:39.98673,y:-82.78487},
{x:41.40953,y:-81.61611},
{x:32.68224,y:-97.46065},
{x:48.24024,y:-122.35875},
{x:47.20281,y:-122.22924},
{x:33.87183,y:-118.27044},
{x:33.28139,y:-111.78892},
{x:33.37867,y:-112.03068},
{x:33.4342,y:-112.30519},
{x:37.73231,y:-120.95754},
{x:38.37115,y:-120.79965},
{x:33.84686,y:-118.11607},
{x:34.2508,y:-116.8842},
{x:35.39761,y:-119.11099},
{x:41.8173,y:-72.74013},
{x:41.90835,y:-87.86608},
{x:41.89165,y:-87.65895},
{x:41.17329,y:-87.8508},
{x:41.70864,y:-86.18138},
{x:40.21876,y:-85.39377},
{x:40.18982,y:-85.41948},
{x:42.2783,y:-83.21541},
{x:35.3376,y:-80.75749},
{x:39.972,y:-74.91044},
{x:40.77749,y:-73.95513},
{x:41.40922,y:-82.66447},
{x:40.68153,y:-80.30445},
{x:32.95394,y:-96.83926},
{x:31.97342,y:-102.07482},
{x:31.77923,y:-106.39273},
{x:47.62982,y:-122.37209},
{x:46.05032,y:-118.34655},
{x:33.67801,y:-111.97782},
{x:33.50802,y:-112.27562},
{x:33.99901,y:-118.05553},
{x:37.89514,y:-122.10478},
{x:34.42689,y:-117.37648},
{x:34.20773,y:-118.5096},
{x:36.32565,y:-119.67325},
{x:28.55365,y:-81.34622},
{x:30.11765,y:-81.62499},
{x:41.50774,y:-87.91695},
{x:42.35912,y:-71.0556},
{x:35.65943,y:-80.46085},
{x:35.79874,y:-78.56078},
{x:40.75893,y:-73.99251},
{x:40.24771,y:-75.29427},
{x:40.20597,y:-75.0992},
{x:37.6089,y:-120.93921},
{x:37.33295,y:-119.65241},
{x:42.02664,y:-91.62151},
{x:41.74647,y:-87.79919},
{x:41.56168,y:-87.89079},
{x:42.32387,y:-83.16883},
{x:44.8331,y:-92.95707},
{x:40.60542,y:-74.68891},
{x:39.14557,y:-119.76811},
{x:39.5306,y:-119.8309},
{x:43.02888,y:-76.01654},
{x:46.18502,y:-119.17553},
{x:35.98771,y:-119.95927},
{x:38.2337,y:-122.59946},
{x:38.42295,y:-121.37362},
{x:40.61037,y:-122.37128},
{x:41.15631,y:-73.42331},
{x:28.09556,y:-81.97301},
{x:39.28075,y:-76.58032},
{x:36.07252,y:-115.2429},
{x:35.02885,y:-80.96706},
{x:30.06199,y:-99.17126},
{x:29.48411,y:-98.53433},
{x:31.82926,y:-106.52916},
{x:47.50512,y:-122.15696},
{x:46.97758,y:-123.80287},
{x:34.04015,y:-118.3305},
{x:34.04845,y:-118.33722},
{x:34.1077,y:-117.5365},
{x:35.61646,y:-120.65879},
{x:37.60673,y:-122.0638},
{x:37.33242,y:-120.46468},
{x:28.65967,y:-81.33884},
{x:28.48188,y:-81.33109},
{x:41.1749,y:-85.16541},
{x:36.82736,y:-87.47308},
{x:42.25626,y:-71.16863},
{x:38.98048,y:-76.9389},
{x:44.90182,y:-93.08025},
{x:38.16175,y:-92.60829},
{x:46.84852,y:-114.03887},
{x:43.32922,y:-73.67484},
{x:45.71171,y:-121.50046},
{x:44.26894,y:-121.19279},
{x:40.71628,y:-75.28396},
{x:39.94252,y:-75.15721},
{x:40.34005,y:-75.97787},
{x:32.70293,y:-96.27631},
{x:32.86218,y:-96.89137},
{x:47.37274,y:-122.20446},
{x:34.69155,y:-86.57453},
{x:32.61788,y:-85.40391},
{x:33.97871,y:-118.43781},
{x:33.91241,y:-118.08214},
{x:34.12853,y:-117.83023},
{x:33.9372,y:-117.55705},
{x:33.78947,y:-117.89278},
{x:37.62473,y:-122.41137},
{x:36.97647,y:-121.90033},
{x:39.9145,y:-104.98777},
{x:30.50402,y:-84.25064},
{x:33.51209,y:-82.04561},
{x:30.84388,y:-83.32809},
{x:41.65592,y:-93.73709},
{x:42.01305,y:-87.71209},
{x:39.50534,y:-86.06915},
{x:39.92587,y:-86.15742},
{x:39.0248,y:-84.57921},
{x:29.88004,y:-90.09824},
{x:37.08802,y:-94.47703},
{x:34.96243,y:-89.84554},
{x:47.49388,y:-111.27849},
{x:35.43958,y:-80.60592},
{x:39.61013,y:-84.1611},
{x:40.1975,y:-77.15567},
{x:40.16981,y:-75.07186},
{x:32.4172,y:-96.84038},
{x:32.81036,y:-97.43168},
{x:29.77528,y:-95.64347},
{x:30.71736,y:-95.57075},
{x:40.58007,y:-111.83546},
{x:43.05379,y:-88.37113},
{x:33.87426,y:-118.2477},
{x:33.98329,y:-80.96794},
{x:33.45626,y:-86.73565},
{x:33.32567,y:-111.68745},
{x:33.53811,y:-111.88631},
{x:33.51641,y:-112.08282},
{x:34.21599,y:-119.00443},
{x:33.94519,y:-116.50174},
{x:38.6429,y:-121.45635},
{x:38.30873,y:-122.29545},
{x:34.15788,y:-118.37873},
{x:39.84944,y:-104.97895},
{x:39.65223,y:-104.77307},
{x:41.32809,y:-73.26689},
{x:42.44626,y:-96.34437},
{x:42.36515,y:-89.03225},
{x:32.52709,y:-93.70135},
{x:45.05526,y:-93.32322},
{x:46.83191,y:-96.82063},
{x:44.95102,y:-123.05078},
{x:30.30249,y:-97.73851},
{x:29.78387,y:-95.58861},
{x:32.30878,y:-95.27792},
{x:30.20264,y:-97.87872},
{x:43.22147,y:-88.11448},
{x:42.8486,y:-106.26498},
{x:33.82351,y:-117.51576},
{x:61.18126,y:-149.83573},
{x:35.1874,y:-111.66184},
{x:34.10687,y:-117.59225},
{x:32.85168,y:-117.21576},
{x:34.04706,y:-117.54838},
{x:33.95717,y:-117.33038},
{x:33.55596,y:-117.18581},
{x:33.74405,y:-118.0153},
{x:33.52447,y:-117.68616},
{x:33.85207,y:-117.78822},
{x:34.4241,y:-119.70576},
{x:36.20207,y:-119.10294},
{x:38.59837,y:-121.45074},
{x:41.88003,y:-72.79985},
{x:41.27809,y:-73.12312},
{x:26.10225,y:-80.14095},
{x:42.1534,y:-88.12867},
{x:42.34564,y:-87.89631},
{x:39.13896,y:-88.56665},
{x:38.91248,y:-94.75602},
{x:43.01837,y:-83.63557},
{x:44.73254,y:-93.12775},
{x:39.07889,y:-94.3938},
{x:39.24508,y:-94.44992},
{x:32.32891,y:-90.32596},
{x:35.82163,y:-78.84756},
{x:40.82986,y:-74.13683},
{x:40.90732,y:-74.55849},
{x:40.7559,y:-73.92348},
{x:41.12135,y:-80.7677},
{x:45.54586,y:-122.70726},
{x:41.8252,y:-71.40987},
{x:32.84229,y:-96.59593},
{x:32.83623,y:-97.13228},
{x:29.92228,y:-95.41332},
{x:30.00416,y:-95.25201},
{x:30.142,y:-96.39242},
{x:29.3514,y:-98.43598},
{x:30.44486,y:-97.74366},
{x:38.29596,y:-77.49288},
{x:36.82442,y:-76.06778},
{x:46.56697,y:-120.48328},
{x:43.17053,y:-88.10645},
{x:42.94844,y:-88.00467},
{x:34.21456,y:-118.44847},
{x:38.01312,y:-121.89033},
{x:37.65527,y:-122.41094},
{x:33.19109,y:-117.35983},
{x:36.8446,y:-119.66458},
{x:39.88672,y:-105.07641},
{x:40.18893,y:-105.12999},
{x:26.15676,y:-81.72068},
{x:27.83807,y:-82.68355},
{x:30.24314,y:-81.59869},
{x:30.15006,y:-81.7528},
{x:25.65606,y:-80.39622},
{x:26.2119,y:-81.79085},
{x:26.30486,y:-80.18698},
{x:25.48092,y:-80.46353},
{x:33.73063,y:-84.73656},
{x:41.95601,y:-88.72269},
{x:41.70013,y:-88.31458},
{x:41.67922,y:-86.89455},
{x:44.12066,y:-70.2343},
{x:42.91194,y:-85.54312},
{x:46.78338,y:-92.10317},
{x:35.95433,y:-78.99395},
{x:43.22253,y:-71.49179},
{x:40.63234,y:-73.94857},
{x:40.74802,y:-73.98925},
{x:35.53059,y:-97.57491},
{x:45.84939,y:-119.29052},
{x:44.10141,y:-121.30119},
{x:34.93928,y:-82.26805},
{x:31.75955,y:-106.26867},
{x:40.38861,y:-111.82908},
{x:36.84289,y:-76.13734},
{x:38.12693,y:-78.44395},
{x:48.09335,y:-122.18864},
{x:32.69618,y:-117.13349},
{x:37.33939,y:-121.84229},
{x:27.9966,y:-82.45496},
{x:33.60749,y:-85.82818},
{x:34.57953,y:-118.12503},
{x:37.66993,y:-121.03146},
{x:38.66028,y:-121.32795},
{x:34.25277,y:-118.81164},
{x:33.74269,y:-116.30426},
{x:34.18684,y:-118.31787},
{x:38.69116,y:-121.1243},
{x:34.16094,y:-84.17489},
{x:40.74672,y:-89.61205},
{x:41.62887,y:-86.23019},
{x:37.98157,y:-87.4932},
{x:33.83539,y:-84.38269},
{x:37.43628,y:-94.70448},
{x:44.69241,y:-93.28321},
{x:36.07619,y:-79.48878},
{x:35.15152,y:-106.5862},
{x:39.61379,y:-119.84871},
{x:41.67701,y:-83.62333},
{x:40.80906,y:-77.90747},
{x:39.9794,y:-75.34703},
{x:33.03418,y:-80.15962},
{x:36.03691,y:-86.80126},
{x:36.08591,y:-86.64751},
{x:33.91493,y:-84.37953},
{x:36.01038,y:-83.97054},
{x:36.14354,y:-86.81379},
{x:29.51044,y:-98.57871},
{x:37.16725,y:-80.42166},
{x:46.27383,y:-119.18551},
{x:39.65522,y:-79.96373},
{x:33.84778,y:-118.26437},
{x:32.74369,y:-117.04308},
{x:38.21361,y:-122.14392},
{x:38.88475,y:-77.01536},
{x:33.43094,y:-84.45691},
{x:36.13084,y:-79.79897},
{x:34.04324,y:-84.34166},
{x:35.07477,y:-78.96252},
{x:34.76486,y:-77.38258},
{x:39.75003,y:-75.07666},
{x:36.21443,y:-86.59807},
{x:38.06406,y:-78.93632},
{x:43.02459,y:-88.24189},
{x:32.354,y:-86.26427},
{x:35.0911,y:-92.41856},
{x:35.11119,y:-92.43877},
{x:34.79988,y:-92.25486},
{x:32.22202,y:-110.91835},
{x:37.9476,y:-122.50942},
{x:33.55553,y:-117.19915},
{x:33.93226,y:-117.93261},
{x:33.81886,y:-84.38832},
{x:37.35749,y:-121.81803},
{x:38.35005,y:-121.97683},
{x:38.4737,y:-121.42996},
{x:36.83794,y:-119.72777},
{x:26.84155,y:-80.13257},
{x:34.11814,y:-84.27054},
{x:42.1148,y:-72.72771},
{x:39.13243,y:-77.07133},
{x:39.083,y:-76.86475},
{x:44.57064,y:-69.64232},
{x:35.37639,y:-77.94175},
{x:36.06816,y:-79.52042},
{x:33.84934,y:-84.42823},
{x:35.77148,y:-78.76259},
{x:39.33114,y:-74.59504},
{x:39.52891,y:-119.82787},
{x:40.68851,y:-73.34047},
{x:32.67204,y:-97.37586},
{x:32.75954,y:-97.47955},
{x:33.92145,y:-84.31485},
{x:32.76486,y:-96.89604},
{x:37.30231,y:-79.9585},
{x:47.14247,y:-119.27776},
{x:47.50705,y:-117.5668},
{x:47.35849,y:-122.1108},
{x:47.15053,y:-122.35667},
{x:44.7984,y:-91.46656},
{x:43.09341,y:-87.8871},
{x:33.41595,y:-111.57413},
{x:32.79412,y:-116.93487},
{x:37.4235,y:-122.1977},
{x:37.49938,y:-120.82559},
{x:32.92041,y:-117.21421},
{x:36.60789,y:-121.85565},
{x:33.9494,y:-84.33568},
{x:34.4237,y:-118.57926},
{x:34.47144,y:-117.24288},
{x:28.46795,y:-81.3093},
{x:26.93408,y:-80.13799},
{x:41.1159,y:-85.13806},
{x:43.65153,y:-116.25229},
{x:34.01281,y:-84.56429},
{x:40.53201,y:-85.67266},
{x:39.02489,y:-94.27144},
{x:39.40458,y:-119.75094},
{x:36.2738,y:-115.2519},
{x:36.23941,y:-115.2263},
{x:39.20219,y:-84.37766},
{x:39.32315,y:-84.42981},
{x:35.12492,y:-85.24012},
{x:26.17272,y:-97.67433},
{x:40.27341,y:-111.67869},
{x:33.93563,y:-117.17898},
{x:40.57889,y:-124.14634},
{x:38.18205,y:-122.25442},
{x:40.57439,y:-122.41676},
{x:36.84695,y:-119.78937},
{x:30.42983,y:-86.63884},
{x:28.65104,y:-81.46727},
{x:47.69443,y:-116.80582},
{x:41.87978,y:-87.80625},
{x:41.90155,y:-88.10146},
{x:41.859,y:-88.05802},
{x:42.21693,y:-87.8202},
{x:42.56682,y:-71.42169},
{x:38.7738,y:-90.49057},
{x:38.61962,y:-90.34807},
{x:36.11452,y:-115.30455},
{x:39.26257,y:-119.58214},
{x:36.05579,y:-115.04246},
{x:40.82568,y:-72.995},
{x:35.54876,y:-97.61854},
{x:44.96438,y:-123.03227},
{x:41.93582,y:-71.47741},
{x:35.87837,y:-83.57273},
{x:36.5896,y:-87.29331},
{x:33.05769,y:-96.69171},
{x:32.75157,y:-97.35309},
{x:30.63429,y:-96.32819},
{x:32.95666,y:-96.61621},
{x:29.79717,y:-98.42223},
{x:33.59037,y:-101.8704},
{x:32.59989,y:-97.14722},
{x:37.34316,y:-76.74072},
{x:47.7028,y:-122.0935},
{x:48.4518,y:-122.33698},
{x:48.07786,y:-123.12986},
{x:42.98267,y:-87.91162},
{x:41.73647,y:-87.62877},
{x:39.99961,y:-104.97692},
{x:41.98936,y:-88.14926},
{x:39.7952,y:-86.04493},
{x:37.75209,y:-87.1118},
{x:42.51041,y:-82.92725},
{x:42.51556,y:-83.61548},
{x:44.79241,y:-91.51319},
{x:40.45642,y:-74.40034},
{x:36.19557,y:-115.12046},
{x:43.18782,y:-77.70101},
{x:40.99285,y:-81.49275},
{x:35.60814,y:-97.61956},
{x:44.08127,y:-103.24631},
{x:35.094,y:-89.81055},
{x:33.22914,y:-97.14493},
{x:32.69366,y:-97.06155},
{x:43.10344,y:-89.48661},
{x:42.92538,y:-87.9502},
{x:30.67595,y:-88.12297},
{x:30.3761,y:-87.68202},
{x:34.02841,y:-117.11927},
{x:27.07861,y:-82.42542},
{x:26.23487,y:-80.21498},
{x:28.03468,y:-80.6487},
{x:33.6032,y:-83.86164},
{x:33.84078,y:-83.90634},
{x:38.20561,y:-85.66622},
{x:38.03913,y:-84.51431},
{x:42.51742,y:-71.13585},
{x:42.09593,y:-72.58503},
{x:42.26677,y:-71.79451},
{x:42.3239,y:-83.43481},
{x:38.71916,y:-90.70212},
{x:43.22138,y:-70.88826},
{x:40.26592,y:-80.12985},
{x:33.01715,y:-96.73342},
{x:37.80321,y:-81.17926},
{x:33.37793,y:-112.17083},
{x:33.39381,y:-111.90862},
{x:33.46603,y:-112.2213},
{x:34.06251,y:-118.34536},
{x:38.0842,y:-122.23427}];