import styled from '@emotion/styled';
import * as M from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import type { SchoolV1 } from 'types';
import { extractCurrentCalData } from 'utils/utilities';

import {
  SchoolBasicInfo,
  SchoolDataChecks,
} from 'pages/SchoolDetailContainer/Widgets';

import BuildCalendarModal from 'components/modals/BuildCalendarModal/BuildCalendarModal';
import NotesEditor from 'components/shared/NotesEditor/NotesEditor';
import SaturnCalendar from 'components/shared/SaturnCalendar/SaturnCalendar';

import EditCalendarButton from './EditCalendarButton';

interface Props {
  school: SchoolV1 | null;
  isWizardVisible: boolean;
}
const Calendar = ({ school, isWizardVisible }: Props) => {
  const [rotationData, setRotationData] = useState({
    start_date: undefined,
    end_date: undefined,
    locked_weekdays: undefined,
    rotation_start: undefined,
    fixed_dates: undefined,
  });
  const [shouldRegenerate, setShouldRegenerate] = useState(false);
  const [isBuilderVisible, setIsBuilderVisible] = useState(false);

  const calendarMapping = school?.calendar?.mapping || [];

  const toggleBuildModal = (isTogglingOn: boolean) => {
    if (isTogglingOn) {
      setRotationData(
        (extractCurrentCalData as any)(
          calendarMapping,
          school?.rotation_pattern[0],
          school?.school_years[0]?.start_date || dayjs().format('YYYY-MM-DD'),
          school?.rotation_pattern,
          school?.schedule_type,
        ),
      );
      setShouldRegenerate(true);
      setIsBuilderVisible(true);
    } else {
      setIsBuilderVisible(false);
      setRotationData({
        start_date: undefined,
        end_date: undefined,
        locked_weekdays: undefined,
        rotation_start: undefined,
        fixed_dates: undefined,
      });
    }
  };

  return (
    <Container>
      <M.Flex>
        {isWizardVisible ? (
          <AlertContainer>
            <M.Alert>
              <M.Title order={4}>
                Calendar will be unlocked after building the school in settings
                tab.
              </M.Title>
            </M.Alert>
          </AlertContainer>
        ) : (
          <CalendarContainer>
            <div className="calendar-build">
              {school && (
                <EditCalendarButton
                  school={school}
                  onClick={() => toggleBuildModal(true)}
                />
              )}
            </div>
            <SaturnCalendar />
            {school && isBuilderVisible && (
              <BuildCalendarModal
                school={school}
                hide={() => toggleBuildModal(false)}
                regenerate={shouldRegenerate}
                rotationData={{
                  ...rotationData,
                  start_date: undefined,
                  end_date: undefined,
                }}
              />
            )}
          </CalendarContainer>
        )}
        <TabsContainer>
          <InfoTabs school={school} />
        </TabsContainer>
      </M.Flex>
    </Container>
  );
};

const InfoTabs = ({ school }: { school: SchoolV1 | null }) => {
  const notesBadge = school?.notes ? ' (*)' : '';

  return (
    <div className="courses-teachers-users-table">
      <Tabs>
        <Tab eventKey="data" title="Data">
          <SchoolDataChecks school={school} />
        </Tab>
        <Tab eventKey="info" title="Info">
          <SchoolBasicInfo school={school} />
        </Tab>
        <Tab eventKey="notes" title={`Notes${notesBadge}`}>
          <NotesEditor currentSchool={school} />
        </Tab>
      </Tabs>
    </div>
  );
};

const AlertContainer = styled(M.Container)`
  width: 100%;
`;

const Container = styled(M.Container)`
  margin-top: ${(p) => p.theme.spacing.lg};
  max-width: 1600px;
`;

const CalendarContainer = styled(M.Container)`
  flex: 2;
`;

const TabsContainer = styled(M.Container)`
  flex: 1;
`;

export default Calendar;
