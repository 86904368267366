import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { ImagePreviewModal } from 'features/components/ImagePreviewModal';

import { useWizard } from '../stepUtils';

export const Sidebar = () => {
  const schedule = useWizard((store) => store.state.schedule);

  const [selectedImage, setSelectedImage] = useState<string>();

  if (!schedule?.image) return null;

  const url = schedule.image.url;

  return (
    <>
      <Wrapper>
        <M.Text size="lg" fw={500}>
          Selected schedule
        </M.Text>
        <M.Divider m="sm" />
        <Img
          src={url}
          alt="Parsed schedule"
          loading="lazy"
          onClick={() => setSelectedImage(url)}
        />
      </Wrapper>
      {selectedImage && (
        <ImagePreviewModal
          imageSrc={selectedImage}
          onClose={() => setSelectedImage(undefined)}
        />
      )}
    </>
  );
};

const Wrapper = styled(M.Paper)`
  position: sticky;
  top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 3px solid ${(p) => p.theme.colors.gray[2]};
  border-radius: ${(p) => p.theme.radius.md};
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: ${(p) => p.theme.radius.sm};
`;
