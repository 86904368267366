import React, { useState } from 'react';
import CreateSchoolModal from 'components/modals/CreateSchoolModal/CreateSchoolModal';
import './SchoolsGridContainer.scss';
import FileUploadModal from 'components/modals/FileUploadModal/FileUploadModal';
import SchoolsGridTable from './SchoolsGridTable/SchoolsGridTable';

const SchoolsGridContainer = () => {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  return (
    <div className="school-grid-container">
      <SchoolsGridTable
        onCreateNewSchoolClickHandler={() => setIsCreateModalVisible(true)}
        onFileUploadClickHandler={() => setIsUploadModalVisible(true)}
      />
      {isCreateModalVisible && (
        <CreateSchoolModal hide={() => setIsCreateModalVisible(false)} />
      )}
      {isUploadModalVisible && (
        <FileUploadModal hide={() => setIsUploadModalVisible(false)} />
      )}
    </div>
  );
};

export default SchoolsGridContainer;
