import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import './MapSearchBar.scss';
import { getSuggestions, getSuggestionValue } from './utils';
import Chip from '@material-ui/core/Chip';
function renderSuggestion(suggestion) {
  return (
    <div className="school-suggestion">
      <span>
        {suggestion.name} ({suggestion.state})
      </span>
      <Chip
        label={suggestion.status}
        className={`status-cell ${suggestion.status}`}
      />
    </div>
  );
}

export default class MapSearchBar extends Component {
  constructor(props) {
    super(props);

    let data = [];
    if (props.schools) {
      // eslint-disable-next-line guard-for-in
      for (const status in props.schools) {
        const features = props.schools[status].features.map(
          (feature) => feature.properties,
        );
        data = [...data, ...features];
      }
    }

    this.state = {
      value: '',
      suggestions: [],
      schools: data,
    };
  }

  onChange = (event, { newValue }) => {
    if (newValue === '') {
      this.props.onClear();
    }
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { schools } = this.state;

    this.setState({
      suggestions: getSuggestions(schools, value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.onChange(suggestion);
  };

  shouldRenderSuggestions = (value) => {
    return value.trim().length > 2;
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search schools',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        inputProps={inputProps}
      />
    );
  }
}
