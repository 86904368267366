import React from 'react';

import './FieldError.scss';

const FieldError = ({ field }) => {
  return !field.valid && field.blurred ? (
    <div className="invalid-feedback">{field.invalidText || 'Error'}</div>
  ) : (
    <div />
  );
};

export default FieldError;
