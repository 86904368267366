import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MM from '@mantine/modals';
import * as MN from '@mantine/notifications';

import { ScheduleView } from 'features/components/scheduleView/ScheduleView';
import { useSchoolInfo } from 'hooks/useSchoolInfo';
import { DATE_SERVER_FORMAT } from 'constants/dates';
import type { Schedule } from 'types';

import { useHandleDraftSchedule } from '../hooks/mutations';

interface Props {
  selectedSchedule: Schedule;
  onClose: () => void;
  shouldEditInNewTab?: boolean;
}

const DraftSchedulesModal = ({
  selectedSchedule,
  onClose,
  shouldEditInNewTab,
}: Props) => {
  const history = useHistory();
  const { data: school, isLoading: isSchoolInfoLoading } = useSchoolInfo(
    selectedSchedule.schoolId,
  );

  const { mutate, isLoading } = useHandleDraftSchedule({
    onSuccess: (isApproving) => {
      if (!school) return;

      const shouldRegen =
        school.scheduleType === 'a_b' ||
        school.scheduleType === 'multiple_day_rotating';

      if (isApproving && shouldRegen) {
        MM.openConfirmModal({
          title: (
            <>
              <strong>{school.schoolName}</strong> is an{' '}
              <strong>{school.scheduleType}</strong>. Would you like to
              regenerate its calendar?
            </>
          ),
          children: (
            <Link
              to={`/schools/${school.schoolName}/calendar`}
              target={shouldEditInNewTab ? '_blank' : '_self'}
            >
              Visit school page
            </Link>
          ),
          labels: { cancel: "Don't regenerate", confirm: 'Yes' },
          onConfirm: () => {
            if (!selectedSchedule.targetDate) {
              MN.showNotification({
                color: 'red',
                message: 'Missing target date',
              });
              return;
            }

            const regenFrom = dayjs(selectedSchedule.targetDate).format(
              DATE_SERVER_FORMAT,
            );

            const params = new URLSearchParams();
            params.append('regenFrom', regenFrom);
            params.append('desiredScheduleId', selectedSchedule.id);

            const url = `/schools/${
              selectedSchedule.schoolId
            }/calendar?${params.toString()}`;
            if (shouldEditInNewTab) {
              window.open(url, '_blank');
            } else {
              history.push(url);
            }
          },
        });
      }

      onClose();
    },
    onError: () => MN.showNotification({ color: 'red', message: 'Error' }),
  });

  const approve = () =>
    mutate({ action: 'approve', draftSchedule: selectedSchedule });
  const reject = () =>
    mutate({ action: 'reject', draftSchedule: selectedSchedule });

  return (
    <M.Modal opened size="lg" withCloseButton={false} onClose={onClose}>
      <ScheduleView schedule={selectedSchedule} />

      <ActionRow>
        <M.Button
          variant="outline"
          disabled={isLoading}
          color="dark"
          onClick={() => {
            const url = `/schools/${selectedSchedule.schoolId}/schedules/edit/${selectedSchedule.id}`;
            if (shouldEditInNewTab) {
              window.open(url, '_blank');
            } else {
              history.push(url);
            }
          }}
        >
          Edit
        </M.Button>

        {selectedSchedule.isDraft && (
          <>
            <M.Box />
            <M.Button
              variant="outline"
              color="red"
              disabled={isSchoolInfoLoading}
              onClick={reject}
            >
              Reject
            </M.Button>
            <M.Button
              variant="outline"
              color="green"
              disabled={isSchoolInfoLoading}
              onClick={approve}
            >
              Approve
            </M.Button>
          </>
        )}
      </ActionRow>

      <M.LoadingOverlay visible={isLoading} />
    </M.Modal>
  );
};

const ActionRow = styled(M.Flex)`
  justify-content: space-between;
  gap: ${(p) => p.theme.spacing.sm};

  & > * {
    flex: 1;
  }
`;

export default DraftSchedulesModal;
