export default [{x:42.19814662,y:-83.15204412},
{x:42.8216019,y:-70.9038643},
{x:38.9841632,y:-94.6078363},
{x:39.98746817,y:-105.0950204},
{x:30.26316945,y:-81.62263312},
{x:39.95562156,y:-74.16440964},
{x:39.72634852,y:-82.61003941},
{x:42.21423571,y:-83.54295731},
{x:32.26949985,y:-107.74880579},
{x:34.27138353,y:-85.2361849},
{x:33.640783,y:-84.434885},
{x:39.85020862,y:-84.3298591},
{x:27.13780387,y:-82.39960396},
{x:41.17571253,y:-73.13035369},
{x:41.8985905,y:-88.3396168},
{x:41.67799045,y:-72.84474269},
{x:27.50236071,y:-81.48973972},
{x:34.43596388,y:-119.82425451},
{x:35.47628034,y:-81.23898759},
{x:35.652922,y:-105.99643007},
{x:41.39784007,y:-81.99598081},
{x:37.0606148,y:-93.30178992},
{x:39.81469531,y:-84.12954417},
{x:26.185827,y:-80.1916822},
{x:41.88000112,y:-87.8043437},
{x:39.89278935,y:-82.882002},
{x:35.92021782,y:-80.58735497},
{x:33.52795432,y:-90.20292982},
{x:30.82170083,y:-83.31053509},
{x:25.72899786,y:-80.43315018},
{x:38.3140216,y:-88.9402475},
{x:30.16813235,y:-85.78917056},
{x:35.07907924,y:-84.04040673},
{x:43.07184646,y:-77.44303197},
{x:39.036995,y:-77.0517021},
{x:40.3689226,y:-80.6408165},
{x:36.33371615,y:-94.14993793},
{x:42.49428055,y:-90.71997733},
{x:42.40165644,y:-71.04432486},
{x:42.3179776,y:-89.00010697},
{x:42.35929311,y:-72.54883736},
{x:40.21865595,y:-85.38472831},
{x:35.99201748,y:-115.10224796},
{x:28.02484572,y:-82.45991616},
{x:39.7584759,y:-75.35635918},
{x:33.71506536,y:-84.27278212},
{x:30.24062969,y:-92.66399379},
{x:41.90283401,y:-88.06450725},
{x:42.97094529,y:-71.47756845},
{x:32.4270912,y:-93.70976857},
{x:28.06386911,y:-82.55224152},
{x:39.80404559,y:-75.03569641},
{x:34.46489855,y:-84.46173057},
{x:41.74775144,y:-71.20900691},
{x:41.58020724,y:-83.68528605},
{x:34.52010464,y:-85.31503362},
{x:40.84053687,y:-73.91778611},
{x:41.43907178,y:-97.36515418},
{x:32.54369298,y:-92.63906568},
{x:39.29973303,y:-76.62382192},
{x:42.9653997,y:-83.8121882},
{x:41.80233065,y:-81.05350524},
{x:41.39492613,y:-81.64239764},
{x:38.93889002,y:-77.02514247},
{x:26.2750395,y:-80.1536337},
{x:37.2836803,y:-107.8769951},
{x:40.8392178,y:-73.941455},
{x:28.55149839,y:-81.58480121},
{x:33.45757774,y:-90.63507468},
{x:40.67431381,y:-73.48888457},
{x:30.47678806,y:-87.21152685},
{x:43.93914589,y:-69.97691274},
{x:42.253835,y:-70.99410832},
{x:27.96412693,y:-81.62507173},
{x:31.31122789,y:-89.32134173},
{x:39.52648743,y:-84.3882367},
{x:26.78361183,y:-80.09464905},
{x:40.27875855,y:-74.73794101},
{x:40.80231541,y:-73.00494567},
{x:42.31217525,y:-83.31149868},
{x:30.56953867,y:-87.28967004},
{x:35.15404868,y:-80.82864628},
{x:27.98202358,y:-82.38082185},
{x:38.54858706,y:-121.40949368},
{x:37.24197933,y:-93.34441423},
{x:35.46766209,y:-80.59013134},
{x:39.76856423,y:-86.10868603},
{x:37.61990985,y:-84.77409728},
{x:37.52052827,y:-82.56739631},
{x:28.66886107,y:-81.44656688},
{x:33.65851575,y:-84.41754386},
{x:40.5242939,y:-74.6284952},
{x:39.8670833,y:-84.8891777},
{x:25.97486448,y:-80.24619386},
{x:42.01351916,y:-87.96031281},
{x:39.05209298,y:-84.65556304},
{x:29.60662376,y:-90.74596524},
{x:33.85951122,y:-84.59865901},
{x:43.087651,y:-78.9781874},
{x:35.08099244,y:-106.62616611},
{x:39.96649858,y:-81.89802385},
{x:25.62587202,y:-80.41241974},
{x:39.46413995,y:-87.41432749},
{x:38.83647655,y:-82.15392693},
{x:37.67332656,y:-97.41715811},
{x:35.3725632,y:-83.20769191},
{x:35.04483473,y:-78.92723903},
{x:36.05236697,y:-78.90440807},
{x:38.4996982,y:-76.77402198},
{x:25.89603782,y:-80.35178594},
{x:39.92766222,y:-86.02267966},
{x:33.72543369,y:-84.75876377},
{x:39.50031125,y:-84.35595597},
{x:36.02678523,y:-115.08473177},
{x:30.4558984,y:-90.95749277},
{x:38.44488432,y:-90.37400857},
{x:26.13761577,y:-80.12651731},
{x:42.2599177,y:-84.4279031},
{x:30.21127561,y:-90.95018998},
{x:31.32005114,y:-89.38770495},
{x:42.39810604,y:-83.35248962},
{x:30.51956328,y:-90.49974315},
{x:34.93682989,y:-85.207268},
{x:38.2332517,y:-85.64044007},
{x:39.33209419,y:-74.59398687},
{x:36.0778806,y:-76.61698024},
{x:36.23882495,y:-80.82074314},
{x:25.769425,y:-80.3524848},
{x:37.43287134,y:-94.70451951},
{x:28.55350163,y:-81.35368466},
{x:39.6515234,y:-83.5432616},
{x:44.16635877,y:-93.96544777},
{x:37.47164832,y:-82.53317664},
{x:28.10176895,y:-81.95149943},
{x:45.03648259,y:-92.81149399},
{x:30.23110435,y:-92.36398854},
{x:33.89554398,y:-84.53966215},
{x:30.14491838,y:-85.59076839},
{x:39.06104622,y:-94.47315919},
{x:43.1482657,y:-77.7054818},
{x:34.1865816,y:-84.1401298},
{x:38.81243543,y:-94.49544556},
{x:33.60954801,y:-88.65856491},
{x:42.37605885,y:-71.24257065},
{x:27.78343417,y:-82.67891854},
{x:33.99572125,y:-83.33785951},
{x:40.8882566,y:-74.2600202},
{x:38.06097238,y:-97.93150127},
{x:30.794458,y:-86.55653},
{x:32.47409891,y:-90.14302746},
{x:32.87949932,y:-83.76356229},
{x:35.26259481,y:-81.15302184},
{x:42.33869502,y:-71.58908397},
{x:35.82157357,y:-79.8239994},
{x:39.71102668,y:-104.93857652},
{x:40.50260593,y:-74.45171089},
{x:28.5980405,y:-81.2251708},
{x:42.997859,y:-84.17652346},
{x:25.82328872,y:-80.27024522},
{x:40.80476396,y:-73.52269784},
{x:26.69123357,y:-80.07400642},
{x:39.60732423,y:-75.94944992},
{x:40.701042,y:-74.013101},
{x:35.59302881,y:-80.8556356},
{x:31.2962551,y:-92.5108947},
{x:32.48214414,y:-93.73917773},
{x:38.79506242,y:-90.6226185},
{x:41.52548942,y:-88.0908224},
{x:41.05897583,y:-83.66354361},
{x:30.02167937,y:-81.70603976},
{x:39.16980204,y:-84.42448101},
{x:39.0082038,y:-95.68718702},
{x:40.28336536,y:-80.99969168},
{x:36.15959059,y:-115.28966869},
{x:27.85657247,y:-82.3287119},
{x:38.89779735,y:-77.0060882},
{x:40.76128768,y:-73.76114622},
{x:43.20534298,y:-86.19827181},
{x:32.33305856,y:-90.32376878},
{x:41.46410793,y:-81.53659523},
{x:25.99510293,y:-80.18185437},
{x:30.45961115,y:-89.10040454},
{x:35.89927665,y:-78.68128069},
{x:40.42157041,y:-86.90227121},
{x:39.47936847,y:-76.31115183},
{x:42.45471751,y:-83.20153557},
{x:39.0953358,y:-84.38676245},
{x:31.10416722,y:-92.06259887},
{x:35.85875047,y:-78.81735563},
{x:43.64715459,y:-116.2768507},
{x:26.03336177,y:-80.26524693},
{x:40.03585403,y:-74.61826086},
{x:40.73169965,y:-74.06292554},
{x:40.12427034,y:-82.97869563},
{x:38.3902002,y:-82.71466993},
{x:33.61220037,y:-84.4725293},
{x:38.65945623,y:-77.0143117},
{x:43.14778696,y:-76.11997863},
{x:33.69445948,y:-117.33555426},
{x:39.8859523,y:-104.8062218},
{x:39.63324197,y:-77.70202437},
{x:37.6075892,y:-97.3345469},
{x:37.95678153,y:-84.53983986},
{x:28.194233,y:-82.504276},
{x:38.07204398,y:-84.48323384},
{x:28.08000537,y:-81.99146301},
{x:39.72031578,y:-75.02451054},
{x:41.95916594,y:-71.11904108},
{x:39.99035015,y:-83.02642152},
{x:30.18748291,y:-92.07547354},
{x:33.94856549,y:-83.9853394},
{x:34.04466,y:-85.23743},
{x:38.46058604,y:-107.86685842},
{x:41.29033291,y:-87.29521064},
{x:35.31184296,y:-80.71303455},
{x:42.5342456,y:-71.74272224},
{x:36.17643034,y:-80.27475804},
{x:43.49559723,y:-112.05190566},
{x:37.18002142,y:-93.22726969},
{x:36.11705306,y:-115.27957454},
{x:28.54820222,y:-81.74331777},
{x:42.04757947,y:-71.08109169},
{x:41.45610776,y:-88.27177092},
{x:42.6187582,y:-83.2492752},
{x:33.96020805,y:-83.38433497},
{x:40.75385202,y:-74.21425246},
{x:32.61102718,y:-90.00630118},
{x:41.57475258,y:-87.24262208},
{x:30.4646734,y:-84.28339988},
{x:39.18075822,y:-77.27018616},
{x:39.8535001,y:-82.8289045},
{x:34.24476725,y:-85.16157503},
{x:39.80149309,y:-104.98331845},
{x:46.50048784,y:-94.29901779},
{x:40.2402804,y:-83.33906136},
{x:41.8532039,y:-90.1862107},
{x:35.72451153,y:-81.65882516},
{x:42.81412598,y:-85.51039399},
{x:45.75303139,y:-108.5290697},
{x:39.3800685,y:-97.12419368},
{x:30.28409438,y:-81.39126341},
{x:34.17433691,y:-84.78743859},
{x:39.67409709,y:-104.79052663},
{x:41.11974838,y:-80.76853209},
{x:26.03274776,y:-80.19997499},
{x:41.70926977,y:-88.20722491},
{x:35.27572003,y:-80.79315394},
{x:29.25633451,y:-81.1125606},
{x:37.98030709,y:-87.47470118},
{x:40.45285649,y:-74.42491747},
{x:34.12071024,y:-83.7626391},
{x:26.20271517,y:-80.15748382},
{x:35.44499754,y:-79.12950273},
{x:33.75957081,y:-84.19740021},
{x:28.6961189,y:-81.261172},
{x:38.60853954,y:-90.36666334},
{x:33.35424179,y:-84.12084192},
{x:32.56841187,y:-116.95277507},
{x:45.57319039,y:-93.19974475},
{x:36.07019337,y:-79.35114473},
{x:39.26623709,y:-84.37983267},
{x:40.6629397,y:-73.9616985},
{x:36.1574759,y:-115.24416904},
{x:36.13233874,y:-79.85605291},
{x:38.68704861,y:-90.3563945},
{x:30.02152989,y:-90.06154713},
{x:40.85467873,y:-84.59874451},
{x:34.3507991,y:-84.0490409},
{x:25.73187208,y:-80.36690375},
{x:41.93927371,y:-87.73122362},
{x:39.774105,y:-104.882868},
{x:42.66887894,y:-71.30279974},
{x:33.97450834,y:-78.40107411},
{x:45.665316,y:-108.77148},
{x:36.49085972,y:-79.7402662},
{x:31.2170193,y:-81.5179183},
{x:40.8541793,y:-74.12321114},
{x:42.1737936,y:-72.32247502},
{x:30.72598599,y:-90.52842162},
{x:30.38577304,y:-87.09072992},
{x:28.22013768,y:-82.24698365},
{x:40.19322611,y:-74.71815035},
{x:31.21328089,y:-81.48781255},
{x:28.8214332,y:-81.8877923},
{x:41.66595134,y:-70.2881375},
{x:41.69957566,y:-81.27779022},
{x:37.74026727,y:-83.04534494},
{x:38.89641195,y:-104.83605378},
{x:39.29173148,y:-84.39354614},
{x:39.98947121,y:-83.05229344},
{x:38.46946652,y:-100.9062025},
{x:34.71318415,y:-92.39931509},
{x:40.5211557,y:-74.2169681},
{x:41.88754643,y:-88.27808216},
{x:33.90143924,y:-84.29795274},
{x:41.94543005,y:-91.67189464},
{x:41.37024491,y:-72.91804612},
{x:30.6038894,y:-87.12409117},
{x:44.5349732,y:-68.4115317},
{x:35.329212,y:-106.571086},
{x:42.4031726,y:-86.24862224},
{x:40.03602442,y:-74.24381702},
{x:36.20514388,y:-81.66952014},
{x:42.39915863,y:-83.21369604},
{x:41.62293441,y:-87.58774594},
{x:39.994867,y:-83.018308},
{x:40.76641321,y:-74.04626973},
{x:40.72028597,y:-73.41797731},
{x:35.39762551,y:-77.96289235},
{x:33.81043923,y:-84.366834},
{x:39.529865,y:-107.783594},
{x:26.04159002,y:-80.16183398},
{x:41.04591536,y:-83.64101064},
{x:27.10965544,y:-80.25820144},
{x:41.15365301,y:-81.86350629},
{x:30.47600205,y:-90.86263951},
{x:30.41469562,y:-84.28029388},
{x:40.01858966,y:-82.87874169},
{x:40.82635445,y:-73.11010315},
{x:36.98976793,y:-86.42820954},
{x:26.36733115,y:-80.18874988},
{x:41.17077617,y:-83.42054933},
{x:36.24262259,y:-115.2347974},
{x:28.03102918,y:-81.73252255},
{x:39.39443033,y:-107.09050901},
{x:29.92124889,y:-81.41612522},
{x:30.39379111,y:-89.04631202},
{x:45.3702961,y:-84.9729698},
{x:38.72824477,y:-75.13028373},
{x:33.92377541,y:-84.21581183},
{x:27.99553349,y:-81.68819368},
{x:39.97211554,y:-74.90981958},
{x:37.28966769,y:-87.11986609},
{x:39.7406161,y:-105.0911635},
{x:39.08539679,y:-84.48075391},
{x:41.49947937,y:-95.33452006},
{x:42.45892208,y:-83.13411222},
{x:36.33852256,y:-79.69380252},
{x:39.55646301,y:-89.29710567},
{x:28.05766812,y:-81.81642205},
{x:40.93251285,y:-73.99610572},
{x:36.71785268,y:-86.52560718},
{x:38.22075625,y:-122.12619699},
{x:42.4573314,y:-70.9513646},
{x:42.4765618,y:-71.0240286},
{x:38.00510841,y:-84.45087767},
{x:32.82870962,y:-117.15377234},
{x:44.90588563,y:-68.68810074},
{x:41.71412028,y:-87.68154815},
{x:38.95945936,y:-95.24348387},
{x:39.02594771,y:-76.44378573},
{x:43.41557325,y:-84.07027557},
{x:30.14025205,y:-81.63305245},
{x:41.969321,y:-71.3012366},
{x:42.44435142,y:-83.24069917},
{x:24.57063812,y:-81.76380783},
{x:39.7287044,y:-84.12167189},
{x:38.7241012,y:-86.4779665},
{x:30.85611314,y:-83.95273447},
{x:33.6342498,y:-84.2923122},
{x:40.8428998,y:-73.84535953},
{x:42.91516338,y:-112.46683985},
{x:39.02053736,y:-84.58915007},
{x:41.44406752,y:-87.46909619},
{x:26.9335952,y:-80.10379906},
{x:43.83933011,y:-84.7687959},
{x:32.39998332,y:-90.1425299},
{x:39.70272182,y:-84.12978825},
{x:30.2613011,y:-82.1221731},
{x:46.40578016,y:-105.82203161},
{x:40.7690516,y:-73.8357382},
{x:41.12633119,y:-73.43035188},
{x:39.69415148,y:-104.86648142},
{x:29.9850095,y:-91.8527332},
{x:41.05820527,y:-80.6624727},
{x:40.65810084,y:-73.89077067},
{x:39.6248465,y:-104.898742},
{x:26.0280972,y:-80.34382018},
{x:35.56171841,y:-82.63279693},
{x:28.1307238,y:-82.5016766},
{x:42.30691632,y:-83.38793961},
{x:39.05182153,y:-94.61218044},
{x:42.10390713,y:-88.29125114},
{x:41.96146359,y:-87.72816016},
{x:42.63760172,y:-71.34390392},
{x:41.6427932,y:-81.4561194},
{x:32.26893868,y:-90.13443482},
{x:39.15978202,y:-77.15917379},
{x:36.16647463,y:-81.13636404},
{x:41.48558532,y:-81.76842995},
{x:35.84900023,y:-77.04851925},
{x:42.67698524,y:-83.27903062},
{x:41.63907913,y:-88.20234597},
{x:40.26608248,y:-82.93216467},
{x:28.59284649,y:-81.36721889},
{x:39.01528981,y:-94.71906513},
{x:41.10566716,y:-85.11539833},
{x:30.4838904,y:-90.4572992},
{x:39.0383225,y:-83.9209439},
{x:40.89285075,y:-83.87311265},
{x:37.05686491,y:-94.4054643},
{x:33.69751288,y:-84.40776049},
{x:43.17711335,y:-77.63138629},
{x:25.7531568,y:-80.3676326},
{x:28.5584965,y:-81.8250567},
{x:41.03022005,y:-85.25860816},
{x:43.46156151,y:-76.48315082},
{x:27.53182783,y:-82.50406802},
{x:34.68913434,y:-84.9985727},
{x:38.361478,y:-98.787435},
{x:30.40282807,y:-87.3213796},
{x:39.97302035,y:-83.09991137},
{x:42.19316886,y:-88.10976148},
{x:38.28376214,y:-85.62911041},
{x:42.44152295,y:-83.31871659},
{x:35.30855473,y:-80.73373089},
{x:34.78385333,y:-84.77127123},
{x:43.22898405,y:-71.36294156},
{x:41.58571837,y:-87.85168871},
{x:39.22071052,y:-84.58589769},
{x:42.40898933,y:-88.0046504},
{x:43.11481442,y:-77.49064813},
{x:42.45040578,y:-85.6599699},
{x:41.85892405,y:-88.07726383},
{x:27.05039747,y:-82.38831729},
{x:42.38500002,y:-87.96562895},
{x:36.60241218,y:-121.84833258},
{x:40.7034632,y:-73.8004804},
{x:34.42635345,y:-103.19594131},
{x:38.22398466,y:-85.80914095},
{x:32.35529038,y:-90.17949174},
{x:27.31894627,y:-82.52938942},
{x:42.52413624,y:-83.02947481},
{x:33.45055539,y:-84.32626024},
{x:41.74276137,y:-87.6635465},
{x:35.2324921,y:-81.1692744},
{x:39.4061491,y:-77.3885212},
{x:35.9005303,y:-78.89550515},
{x:28.79983221,y:-81.33970767},
{x:39.31542452,y:-76.4617503},
{x:48.20315721,y:-114.3071048},
{x:42.02126289,y:-88.31227519},
{x:38.93940863,y:-92.77395085},
{x:46.89028748,y:-114.03857276},
{x:39.92607462,y:-105.0914564},
{x:33.75278265,y:-84.46683079},
{x:43.59967492,y:-116.59383283},
{x:44.6592193,y:-85.76857656},
{x:43.46986325,y:-83.97602509},
{x:40.72067268,y:-74.33218718},
{x:40.91340001,y:-74.04649093},
{x:28.60365691,y:-81.54721141},
{x:39.91506967,y:-82.87967251},
{x:39.68261661,y:-105.08512974},
{x:43.49657,y:-116.39393},
{x:37.7300444,y:-122.1934979},
{x:36.0608861,y:-79.6623765},
{x:28.26182573,y:-81.43676743},
{x:32.53119687,y:-92.07949318},
{x:30.44093646,y:-84.31682289},
{x:30.30023106,y:-93.19585404},
{x:33.54471062,y:-84.28195809},
{x:41.10154933,y:-80.73178187},
{x:29.87639474,y:-90.04948676},
{x:41.46203709,y:-81.4859604},
{x:43.11357616,y:-76.14567697},
{x:40.38017041,y:-105.0974974},
{x:39.12723157,y:-84.49006945},
{x:34.0261008,y:-84.52893622},
{x:39.99017462,y:-81.1571297},
{x:35.64023452,y:-105.95954195},
{x:40.74504501,y:-86.09343565},
{x:35.70850965,y:-81.30860478},
{x:40.7375843,y:-73.1196249},
{x:27.75395761,y:-82.73757041},
{x:39.77805536,y:-76.96158886},
{x:26.23503343,y:-80.20520836},
{x:42.92922599,y:-83.63166831},
{x:35.07928803,y:-106.71166152},
{x:34.37387214,y:-83.87169651},
{x:41.5567073,y:-87.71211728},
{x:38.6204074,y:-76.9007074},
{x:30.28791022,y:-81.63242216},
{x:32.02803201,y:-81.09357879},
{x:32.6001345,y:-93.8387452},
{x:39.8691172,y:-84.29328114},
{x:28.03613213,y:-80.66857722},
{x:29.85833599,y:-81.28094316},
{x:30.3566133,y:-81.41296647},
{x:40.6518608,y:-82.5456914},
{x:30.24801552,y:-81.7438666},
{x:25.77873278,y:-80.25534118},
{x:43.0078549,y:-78.94865331},
{x:35.71877364,y:-78.78765553},
{x:29.96071396,y:-90.183447},
{x:33.95463284,y:-84.05400127},
{x:33.60260883,y:-83.85359333},
{x:44.88472651,y:-93.28639403},
{x:39.84603142,y:-84.42444183},
{x:27.26986981,y:-82.45505601},
{x:34.58068533,y:-92.57360705},
{x:35.25703091,y:-79.0397054},
{x:38.95659144,y:-76.87245875},
{x:42.16705649,y:-71.04654382},
{x:38.70486803,y:-80.66583125},
{x:42.05537707,y:-88.26836742},
{x:38.47812213,y:-82.64831619},
{x:28.85531253,y:-82.39177734},
{x:42.78466339,y:-83.01263765},
{x:39.70137773,y:-74.26894739},
{x:40.8164118,y:-73.9182492},
{x:40.15388052,y:-83.13200726},
{x:45.7608995,y:-111.1860707},
{x:39.61856364,y:-76.01065456},
{x:38.12720592,y:-82.62779295},
{x:43.31786793,y:-76.41255945},
{x:43.09411824,y:-77.58115835},
{x:29.12902697,y:-81.00233674},
{x:26.52917527,y:-80.07715568},
{x:40.72990928,y:-73.74382317},
{x:40.71526,y:-99.0834225},
{x:32.53623571,y:-93.77047509},
{x:25.94191453,y:-80.32336973},
{x:34.91408422,y:-76.94056571},
{x:41.60270559,y:-87.79875129},
{x:25.80981283,y:-80.31647868},
{x:28.56887443,y:-81.28383189},
{x:39.0879282,y:-108.4570135},
{x:33.65794021,y:-84.33381938},
{x:26.98440677,y:-82.10120478},
{x:29.02479824,y:-82.15898827},
{x:42.25572989,y:-83.249185},
{x:28.65518463,y:-81.20944083},
{x:38.50076864,y:-82.67762469},
{x:33.7237649,y:-84.5078164},
{x:42.72946753,y:-84.54517543},
{x:42.56988557,y:-71.10980523},
{x:38.66805663,y:-94.36715662},
{x:38.98337735,y:-84.39835131},
{x:26.09249475,y:-80.15474796},
{x:43.47619706,y:-70.49798205},
{x:42.8000788,y:-71.23973429},
{x:34.14638769,y:-77.89495658},
{x:43.14738749,y:-93.35962837},
{x:30.39766509,y:-83.35871938},
{x:34.11027224,y:-84.22011584},
{x:34.83126872,y:-90.28205305},
{x:40.39364294,y:-86.84841412},
{x:41.1567706,y:-81.22592282},
{x:35.4824768,y:-80.8764114},
{x:39.35453207,y:-76.70520455},
{x:41.5130253,y:-82.9385966},
{x:47.89001316,y:-97.0813778},
{x:35.12103797,y:-106.70109612},
{x:38.88589948,y:-104.75747042},
{x:30.43111489,y:-84.24791425},
{x:36.23704839,y:-90.03848033},
{x:30.30902497,y:-81.73021048},
{x:28.8659515,y:-82.0070343},
{x:41.64358321,y:-93.50519351},
{x:38.27281826,y:-104.60669321},
{x:30.01016133,y:-90.15441813},
{x:39.47113545,y:-87.37543599},
{x:35.778638,y:-80.25929354},
{x:42.25527631,y:-83.19216192},
{x:38.3144152,y:-85.76011397},
{x:41.2099796,y:-80.7482542},
{x:37.47761765,y:-122.22396658},
{x:35.06154272,y:-80.81561643},
{x:39.30306,y:-75.60353},
{x:42.57557213,y:-82.88770304},
{x:29.96185124,y:-90.11496034},
{x:42.28160123,y:-71.3960584},
{x:43.68895083,y:-116.35344587},
{x:36.86040951,y:-94.38226744},
{x:38.98470843,y:-76.55586996},
{x:40.7428258,y:-73.92001615},
{x:32.44742462,y:-93.74645054},
{x:38.76636255,y:-93.73600602},
{x:42.34861622,y:-87.87819757},
{x:42.59312187,y:-83.06999869},
{x:41.80456799,y:-71.33876413},
{x:40.4568672,y:-74.4787471},
{x:35.83540659,y:-76.76419169},
{x:38.86645138,y:-82.12665337},
{x:44.22746964,y:-86.31195267},
{x:34.99447883,y:-80.54207974},
{x:35.24859906,y:-82.20279828},
{x:25.89085176,y:-80.16281185},
{x:40.253504,y:-74.300663},
{x:35.6403664,y:-78.4494891},
{x:27.8939597,y:-82.7598776},
{x:28.56588503,y:-81.16532368},
{x:36.81091459,y:-114.08355545},
{x:45.1669414,y:-93.2332664},
{x:40.14704947,y:-82.70298332},
{x:41.80381222,y:-89.66534346},
{x:32.66200019,y:-83.73992224},
{x:37.77973564,y:-81.19151071},
{x:27.94501663,y:-82.47510821},
{x:36.00353758,y:-79.79279861},
{x:42.90307162,y:-78.86825323},
{x:39.12604187,y:-76.53191469},
{x:27.83996551,y:-82.78813004},
{x:28.40676959,y:-81.45481416},
{x:41.60087723,y:-93.78104434},
{x:41.45981349,y:-87.03357741},
{x:39.29503939,y:-84.52474587},
{x:39.91223447,y:-86.14012517},
{x:40.800808,y:-74.359965},
{x:42.4250018,y:-83.33640843},
{x:41.20170548,y:-81.43970813},
{x:35.91055987,y:-78.93950343},
{x:40.50483135,y:-86.11322918},
{x:33.7514254,y:-84.732481},
{x:39.67671987,y:-84.24206628},
{x:41.74864095,y:-83.62790957},
{x:42.02835208,y:-91.62878649},
{x:38.5599975,y:-76.0621185},
{x:45.10550045,y:-93.45338166},
{x:32.45949486,y:-91.75940958},
{x:42.82908484,y:-78.77703897},
{x:35.32166735,y:-80.65843275},
{x:35.97145127,y:-77.93541514},
{x:42.96614858,y:-82.45195538},
{x:41.50767691,y:-87.31368951},
{x:27.893235,y:-82.5179136},
{x:38.12672802,y:-85.77472284},
{x:40.10229089,y:-85.71163058},
{x:37.96989021,y:-87.49440871},
{x:41.41475846,y:-81.53794872},
{x:35.54901689,y:-77.39859007},
{x:30.391086,y:-91.164131},
{x:31.44839652,y:-83.53442252},
{x:33.76947112,y:-84.51965958},
{x:37.13300186,y:-94.47458714},
{x:35.14640614,y:-106.58596612},
{x:30.49462444,y:-92.43567071},
{x:41.0484739,y:-73.5616708},
{x:37.21360051,y:-93.38168889},
{x:38.72350349,y:-90.69783702},
{x:42.45766964,y:-88.9984252},
{x:30.5176005,y:-86.4713646},
{x:39.5888366,y:-106.0944137},
{x:28.19006421,y:-82.39041972},
{x:30.78727289,y:-89.86517049},
{x:40.8242781,y:-97.5968959},
{x:39.95700936,y:-86.11529231},
{x:39.24402695,y:-94.4468484},
{x:39.1167195,y:-76.63326866},
{x:34.92147446,y:-88.51498224},
{x:41.41861539,y:-81.91771013},
{x:28.44963209,y:-81.42481148},
{x:27.4466669,y:-82.50878},
{x:40.87944813,y:-73.84217575},
{x:42.50636948,y:-83.76153991},
{x:41.35823499,y:-89.12454039},
{x:27.24461929,y:-80.23376584},
{x:35.34996853,y:-80.85771739},
{x:39.11640686,y:-82.53348246},
{x:37.04093598,y:-100.90376684},
{x:35.24609166,y:-80.89324586},
{x:39.13258504,y:-75.52901432},
{x:37.71117395,y:-85.8330811},
{x:32.54728615,y:-84.94197619},
{x:28.67353479,y:-81.50831401},
{x:41.38436946,y:-81.77458785},
{x:30.28183955,y:-81.72161902},
{x:42.24301022,y:-71.81088656},
{x:40.84245179,y:-73.28622155},
{x:26.15487695,y:-80.25773908},
{x:39.83876122,y:-89.60381359},
{x:38.74438866,y:-90.59249327},
{x:41.90559685,y:-71.02729985},
{x:27.26485719,y:-80.41639739},
{x:34.49910425,y:-89.03020307},
{x:30.35926419,y:-87.16635852},
{x:25.70131365,y:-80.36840702},
{x:36.96133731,y:-86.48958385},
{x:39.9768928,y:-74.24710617},
{x:36.61669449,y:-88.31433646},
{x:32.84195396,y:-104.40956753},
{x:41.1115381,y:-81.2433041},
{x:44.6543495,y:-84.7076077},
{x:43.95538126,y:-86.40869474},
{x:39.3579582,y:-76.60954088},
{x:39.89392017,y:-83.08421776},
{x:43.2205759,y:-85.5717138},
{x:42.56577484,y:-83.04771893},
{x:42.01795649,y:-88.20241906},
{x:40.94136543,y:-72.9865292},
{x:42.376873,y:-77.362272},
{x:42.1363778,y:-72.51930624},
{x:41.8155921,y:-88.2804089},
{x:29.94000338,y:-90.10133027},
{x:41.35497379,y:-88.84057514},
{x:33.49735005,y:-88.38893787},
{x:40.7828175,y:-74.6979717},
{x:41.27634001,y:-81.62639156},
{x:34.735951,y:-76.799388},
{x:37.75173383,y:-97.42707952},
{x:35.66392554,y:-78.69876176},
{x:35.01925096,y:-78.8977468},
{x:39.7073741,y:-84.201503},
{x:43.14556854,y:-77.55463146},
{x:42.39315199,y:-83.48215163},
{x:42.99127122,y:-78.81304801},
{x:39.71335308,y:-86.00880995},
{x:42.2325291,y:-84.4236265},
{x:38.235787,y:-86.128228},
{x:36.3985116,y:-105.578007},
{x:30.53465507,y:-87.30897673},
{x:25.74723345,y:-80.34029894},
{x:41.27488359,y:-72.83240318},
{x:36.29862875,y:-78.58279914},
{x:44.69652684,y:-73.48710895},
{x:41.6306667,y:-93.7553449},
{x:43.11367451,y:-75.27723766},
{x:37.98843395,y:-121.24509163},
{x:29.8929521,y:-90.1249892},
{x:43.0232155,y:-83.5944406},
{x:43.22038107,y:-70.88499295},
{x:30.0056189,y:-90.2188813},
{x:46.547013,y:-87.43142545},
{x:41.17723569,y:-96.04377289},
{x:41.9222383,y:-87.695264},
{x:30.18818082,y:-82.63986021},
{x:40.1143697,y:-85.57112977},
{x:42.08282045,y:-86.43708095},
{x:30.46918152,y:-84.36187863},
{x:40.56888415,y:-122.35937543},
{x:41.43461297,y:-81.52705729},
{x:38.217647,y:-85.75610295},
{x:39.5206177,y:-85.75987713},
{x:36.11358622,y:-79.75419648},
{x:40.62230603,y:-74.02795896},
{x:30.84512522,y:-90.15955381},
{x:41.84631543,y:-87.80412242},
{x:39.95644131,y:-86.01174911},
{x:43.20288149,y:-77.94173375},
{x:33.965748,y:-84.011562},
{x:41.21333974,y:-96.11235358},
{x:37.8637792,y:-87.5746226},
{x:43.00780597,y:-77.44480207},
{x:38.6827448,y:-84.60012988},
{x:36.09019929,y:-80.06288097},
{x:35.83807136,y:-78.85665156},
{x:38.53001681,y:-89.14874899},
{x:42.25967151,y:-83.16839755},
{x:28.4278292,y:-81.3143875},
{x:37.23237,y:-96.997421},
{x:41.53805161,y:-81.49765591},
{x:32.51945395,y:-92.10910209},
{x:42.32482345,y:-85.51773973},
{x:34.37358715,y:-89.49692756},
{x:38.79187226,y:-90.69674939},
{x:42.8894789,y:-71.32740901},
{x:37.69922839,y:-85.22270679},
{x:41.45269942,y:-74.36795707},
{x:40.35185481,y:-83.76109064},
{x:42.0397471,y:-87.8604101},
{x:40.08580215,y:-80.73486418},
{x:33.4548263,y:-88.81416514},
{x:39.9398741,y:-75.00911169},
{x:42.52794716,y:-83.444248},
{x:39.70412439,y:-91.38856485},
{x:43.65984542,y:-93.31607594},
{x:25.94564321,y:-80.16316992},
{x:43.07823752,y:-73.78795624},
{x:41.6721004,y:-85.00036776},
{x:34.113809,y:-84.5529757},
{x:36.66904713,y:-76.94832802},
{x:28.3796088,y:-81.4051884},
{x:27.3518598,y:-82.5308455},
{x:34.04995704,y:-84.59749493},
{x:42.84052447,y:-85.67547135},
{x:36.7937508,y:-76.2385443},
{x:41.81797703,y:-87.72420016},
{x:30.19696264,y:-81.82388897},
{x:32.54130621,y:-82.94724808},
{x:35.72002531,y:-77.89234104},
{x:34.25383834,y:-89.01839964},
{x:42.43056694,y:-83.18832771},
{x:34.06286126,y:-83.9808628},
{x:35.753643,y:-81.7182312},
{x:38.80480136,y:-76.97569728},
{x:35.56159196,y:-77.06035048},
{x:29.9488221,y:-89.961326},
{x:42.91303399,y:-85.6092748},
{x:41.0164938,y:-73.9444607},
{x:30.4253029,y:-89.09294933},
{x:37.0764096,y:-88.6895726},
{x:34.27954837,y:-118.71991701},
{x:38.72745548,y:-90.27614933},
{x:43.46667465,y:-111.98345407},
{x:33.03843316,y:-85.03560796},
{x:40.9990628,y:-81.9972361},
{x:35.866552,y:-80.0714064},
{x:26.52741981,y:-80.16258001},
{x:30.01550171,y:-90.18131331},
{x:38.43465889,y:-82.12388143},
{x:40.8620688,y:-74.56915999},
{x:26.61656515,y:-80.2022016},
{x:42.78908646,y:-78.81045431},
{x:38.74561228,y:-108.071497},
{x:35.1602116,y:-83.3910358},
{x:41.8313659,y:-72.4948656},
{x:32.89951661,y:-83.68521563},
{x:41.45267015,y:-85.26834356},
{x:41.48224281,y:-87.0525486},
{x:43.07193038,y:-85.68881841},
{x:39.44612541,y:-76.8137645},
{x:38.1988413,y:-85.7831372},
{x:26.16640025,y:-80.15640825},
{x:40.53310591,y:-88.99491594},
{x:33.9071483,y:-84.8244844},
{x:38.04211006,y:-84.42625634},
{x:37.67397497,y:-92.65461327},
{x:40.77389572,y:-73.87440468},
{x:34.99297697,y:-78.1375102},
{x:37.9913834,y:-85.70696622},
{x:39.76332541,y:-75.13334692},
{x:42.25716719,y:-84.36079065},
{x:39.71872925,y:-75.11454992},
{x:35.33462364,y:-81.8897523},
{x:40.15732767,y:-89.40992609},
{x:32.07143,y:-81.101059},
{x:41.72653359,y:-72.76251353},
{x:34.79472405,y:-85.00339043},
{x:39.36536,y:-82.97608},
{x:41.5894538,y:-81.22194596},
{x:41.14761597,y:-88.836024},
{x:41.6841576,y:-81.3397512},
{x:38.91057851,y:-92.33381354},
{x:39.06870695,y:-84.09545481},
{x:39.17927589,y:-89.66915145},
{x:42.80319802,y:-71.54044479},
{x:34.86911093,y:-85.51858395},
{x:33.04290468,y:-84.97804843},
{x:36.86778085,y:-88.36075991},
{x:39.01825531,y:-84.6896553},
{x:33.86987433,y:-84.09127961},
{x:29.55549637,y:-81.21242699},
{x:33.7833928,y:-89.8309878},
{x:25.73385818,y:-80.2521041},
{x:40.6683161,y:-74.1068014},
{x:36.09371981,y:-79.40562509},
{x:39.282785,y:-84.48594546},
{x:36.80895614,y:-119.75472629},
{x:41.68315481,y:-72.65364468},
{x:43.66867969,y:-116.67945255},
{x:42.0995732,y:-79.30295042},
{x:44.0786311,y:-92.5042974},
{x:41.4189282,y:-81.60692811},
{x:39.612289,y:-76.00879609},
{x:33.39730983,y:-84.61919019},
{x:37.7038256,y:-121.8181089},
{x:27.90932908,y:-81.84296787},
{x:29.17520373,y:-81.00344449},
{x:41.68648904,y:-72.70710629},
{x:42.3792791,y:-87.90508562},
{x:42.2137466,y:-83.22662927},
{x:38.03817135,y:-84.55181724},
{x:32.3653409,y:-90.14886528},
{x:28.30489544,y:-81.41317689},
{x:25.65627856,y:-80.406309},
{x:39.92157806,y:-83.12104382},
{x:39.82896276,y:-84.02527309},
{x:28.55112443,y:-81.53379728},
{x:42.02190052,y:-91.66509256},
{x:38.31866619,y:-85.7143034},
{x:38.17457865,y:-85.71227263},
{x:26.60563819,y:-81.99040484},
{x:39.63729956,y:-78.83355349},
{x:41.98697489,y:-91.65494442},
{x:40.70846637,y:-73.7201976},
{x:36.3923983,y:-81.4821108},
{x:27.5870822,y:-80.37746787},
{x:33.65412868,y:-84.39975411},
{x:33.9207974,y:-84.4678146},
{x:38.9683127,y:-76.9841922},
{x:36.4058933,y:-78.97826292},
{x:41.90598112,y:-84.07215983},
{x:38.88312428,y:-94.75855932},
{x:28.33169886,y:-82.69984782},
{x:38.91190798,y:-104.71857705},
{x:38.23802949,y:-85.72100088},
{x:40.8338235,y:-73.8529678},
{x:27.52164154,y:-82.55854702},
{x:40.0437444,y:-84.21205203},
{x:43.979424,y:-75.952208},
{x:35.22961575,y:-81.33267224},
{x:40.14026012,y:-82.97710576},
{x:30.28111129,y:-91.22800332},
{x:41.09903086,y:-73.44320637},
{x:30.21957681,y:-93.37580144},
{x:42.09649092,y:-75.98098017},
{x:41.15681959,y:-81.40480585},
{x:34.95113872,y:-80.75636539},
{x:42.53082427,y:-83.10629368},
{x:30.51929363,y:-92.07084201},
{x:41.58663101,y:-87.71838561},
{x:40.65349802,y:-73.55824481},
{x:32.59432702,y:-83.62226046},
{x:34.243414,y:-84.4919031},
{x:33.6291953,y:-84.3845733},
{x:41.50103059,y:-81.56350046},
{x:34.79006899,y:-84.9615292},
{x:28.0253356,y:-81.9271182},
{x:34.0714709,y:-118.0248881},
{x:35.96936562,y:-77.81092267},
{x:40.0568746,y:-74.1478206},
{x:26.51932011,y:-81.9430545},
{x:45.27984087,y:-93.00952435},
{x:42.35437646,y:-83.33145038},
{x:39.77424733,y:-85.99466198},
{x:41.9515143,y:-70.7128529},
{x:30.11360996,y:-81.58939416},
{x:42.57198233,y:-71.2769967},
{x:39.94349863,y:-105.81210754},
{x:28.7605879,y:-81.284836},
{x:33.3677146,y:-91.04108602},
{x:39.3480998,y:-84.5411986},
{x:36.04070603,y:-79.84755054},
{x:33.43611,y:-84.04121},
{x:33.88595664,y:-84.24947986},
{x:39.042684,y:-94.341056},
{x:40.24783198,y:-74.76776719},
{x:44.81698782,y:-68.80914465},
{x:41.60219456,y:-72.68015363},
{x:26.56290658,y:-81.94597878},
{x:39.08704512,y:-84.71072636},
{x:41.47156243,y:-87.29565035},
{x:39.8608511,y:-105.1659358},
{x:39.03982898,y:-84.50798492},
{x:42.2807024,y:-89.6324835},
{x:34.25994632,y:-83.46609727},
{x:38.9629769,y:-94.666993},
{x:35.58185532,y:-78.14591187},
{x:40.92503356,y:-74.08806703},
{x:39.61492802,y:-86.08129027},
{x:41.15004741,y:-87.85156296},
{x:38.70140338,y:-93.25008631},
{x:42.37933161,y:-88.07716927},
{x:38.96430932,y:-84.09061946},
{x:35.32337804,y:-78.64691095},
{x:42.36623955,y:-88.17023188},
{x:33.99011864,y:-83.72243741},
{x:34.65931963,y:-84.48970601},
{x:34.12103,y:-117.4899888},
{x:34.36369957,y:-89.55631115},
{x:33.84945432,y:-84.20620322},
{x:30.41574918,y:-81.74891989},
{x:41.46826939,y:-82.01893904},
{x:41.74848181,y:-87.80135252},
{x:42.1636326,y:-71.1986839},
{x:26.006521,y:-80.2078035},
{x:31.33294495,y:-92.41153015},
{x:26.61402808,y:-81.66356027},
{x:41.94347,y:-88.7378082},
{x:42.45816818,y:-83.35696153},
{x:42.98846462,y:-84.54383969},
{x:39.70069198,y:-77.73355455},
{x:40.77931256,y:-73.26004015},
{x:35.16352722,y:-80.97233891},
{x:44.92022471,y:-93.41322094},
{x:42.44576898,y:-83.1428703},
{x:35.60691023,y:-105.22359575},
{x:34.99220745,y:-90.00547171},
{x:38.68859734,y:-87.50592507},
{x:40.70790669,y:-73.67828063},
{x:27.03851737,y:-82.21909672},
{x:45.82104246,y:-108.47138107},
{x:43.19071824,y:-85.25268853},
{x:34.08935564,y:-84.48511407},
{x:34.5586814,y:-84.93556291},
{x:28.75565806,y:-81.35975853},
{x:26.58754134,y:-80.11439413},
{x:29.05445748,y:-81.30474872},
{x:39.34429955,y:-84.39518385},
{x:39.66513879,y:-86.09282881},
{x:42.22611267,y:-88.30497731},
{x:39.7527211,y:-94.2146854},
{x:39.77914787,y:-86.26522645},
{x:26.70722005,y:-80.14908046},
{x:39.1149855,y:-77.1644298},
{x:33.71877799,y:-85.0296092},
{x:34.72388832,y:-77.45744474},
{x:43.13779199,y:-115.66393375},
{x:27.59131127,y:-81.51406445},
{x:39.15916017,y:-84.45666347},
{x:40.86966845,y:-82.64600113},
{x:33.69959712,y:-84.17327507},
{x:40.40754585,y:-105.09893104},
{x:39.9994294,y:-105.2626712},
{x:39.07514445,y:-77.00333937},
{x:36.96021302,y:-84.0752323},
{x:38.81174555,y:-104.82190446},
{x:34.96220785,y:-89.98232521},
{x:42.9613728,y:-74.1856665},
{x:41.30646336,y:-73.87648485},
{x:38.95840141,y:-85.87497309},
{x:44.74686763,y:-85.63614702},
{x:40.64910662,y:-74.40798908},
{x:26.08848926,y:-80.22778519},
{x:39.58221583,y:-82.9522322},
{x:29.47473625,y:-81.23981215},
{x:42.48112928,y:-83.00677635},
{x:30.25178315,y:-92.19483376},
{x:42.9129584,y:-85.5708196},
{x:40.0626527,y:-83.0397437},
{x:46.4013684,y:-117.0013307},
{x:27.49623668,y:-82.61587136},
{x:37.0165383,y:-84.6286223},
{x:30.1807609,y:-82.6827728},
{x:29.19661119,y:-81.05802707},
{x:30.43020484,y:-81.66533455},
{x:41.15388073,y:-95.91914445},
{x:43.14133547,y:-93.20072047},
{x:38.8178953,y:-76.73795298},
{x:40.87401188,y:-74.64882195},
{x:34.08932787,y:-84.58599061},
{x:39.54648164,y:-105.00312187},
{x:40.02728685,y:-81.56757206},
{x:39.20690449,y:-94.54801306},
{x:41.47301643,y:-72.8153254},
{x:41.02555604,y:-81.63975969},
{x:34.0187352,y:-84.31306913},
{x:41.29284815,y:-96.12372994},
{x:42.4722809,y:-83.24300051},
{x:42.94924229,y:-76.54699445},
{x:33.60734432,y:-83.82779383},
{x:33.684084,y:-83.999427},
{x:39.04684722,y:-77.10366069},
{x:40.00886012,y:-81.58067867},
{x:36.23694167,y:-90.04035684},
{x:40.77635944,y:-73.03197429},
{x:33.68517265,y:-84.31148795},
{x:39.80574507,y:-104.93265905},
{x:34.9636026,y:-89.7960556},
{x:41.5231892,y:-81.4392853},
{x:28.8919219,y:-82.4825668},
{x:39.28750614,y:-76.53862976},
{x:40.8741498,y:-88.66717145},
{x:38.59343447,y:-90.53634607},
{x:28.50967,y:-81.2355391},
{x:40.7557131,y:-73.9906592},
{x:40.34278758,y:-74.50080291},
{x:35.61923574,y:-82.55389439},
{x:36.8082221,y:-119.7040661},
{x:32.21294116,y:-82.40320585},
{x:43.56489743,y:-116.57255066},
{x:33.92346715,y:-84.01824206},
{x:27.44514997,y:-82.57500976},
{x:40.82293631,y:-96.65329323},
{x:36.02121991,y:-78.95155728},
{x:34.29157621,y:-83.84675838},
{x:41.78211202,y:-88.37613568},
{x:43.8421539,y:-70.4444491},
{x:33.73113677,y:-84.57376242},
{x:39.1829716,y:-76.763674},
{x:41.56767815,y:-83.65610093},
{x:25.77025727,y:-80.32513763},
{x:36.17748365,y:-115.06171346},
{x:40.9381779,y:-98.3842112},
{x:41.77282393,y:-88.31361555},
{x:39.82444847,y:-75.15999985},
{x:31.99840225,y:-81.25661016},
{x:40.71203338,y:-73.85911391},
{x:37.55783911,y:-85.27417436},
{x:27.20840018,y:-81.83002353},
{x:35.60903755,y:-78.56169552},
{x:37.97533112,y:-100.8566165},
{x:38.77625892,y:-76.05968402},
{x:38.94779688,y:-92.3274351},
{x:40.1072536,y:-83.7362611},
{x:39.91345793,y:-105.04867482},
{x:31.1354234,y:-93.26529671},
{x:39.5436221,y:-105.2902713},
{x:30.4584265,y:-88.8971319},
{x:28.19143513,y:-82.46188492},
{x:26.24932777,y:-80.25254332},
{x:43.31741519,y:-70.97714871},
{x:30.1936394,y:-93.1795804},
{x:35.47076744,y:-83.31062369},
{x:35.29220392,y:-81.18861281},
{x:32.40245224,y:-93.79822053},
{x:42.6081861,y:-76.1661304},
{x:40.7525843,y:-73.9733736},
{x:32.55326006,y:-93.70943018},
{x:40.3519008,y:-111.73307329},
{x:31.21158216,y:-82.35155559},
{x:28.49721038,y:-82.59302638},
{x:36.73775355,y:-108.18412252},
{x:41.68765677,y:-88.06734301},
{x:42.17274701,y:-83.76616269},
{x:39.56891361,y:-76.35468476},
{x:37.69902147,y:-83.99158895},
{x:40.66589292,y:-89.58100582},
{x:42.86321391,y:-85.72186589},
{x:40.64855716,y:-73.91459137},
{x:34.25527104,y:-88.74441353},
{x:39.63230588,y:-77.76062161},
{x:34.66970709,y:-92.37862587},
{x:41.71267368,y:-86.24991198},
{x:34.03450484,y:-84.6802099},
{x:42.56814533,y:-84.82701659},
{x:35.913365,y:-81.17839018},
{x:39.18985817,y:-96.61343426},
{x:38.96487302,y:-76.84642822},
{x:41.11144114,y:-74.09919825},
{x:40.62901321,y:-74.30797287},
{x:35.28589606,y:-77.58932501},
{x:42.76931695,y:-73.8200901},
{x:34.70094816,y:-83.7279462},
{x:41.92980047,y:-70.6589824},
{x:36.19642237,y:-115.24797276},
{x:42.30877347,y:-83.18837921},
{x:42.67295884,y:-82.83529736},
{x:25.811238,y:-80.23986438},
{x:42.19860757,y:-87.83251527},
{x:37.13884469,y:-83.76757957},
{x:34.89766297,y:-84.94752212},
{x:40.84280951,y:-73.89786512},
{x:42.89252795,y:-112.45180473},
{x:26.9326753,y:-82.2259142},
{x:32.39549464,y:-91.20002568},
{x:39.60398535,y:-119.83689465},
{x:39.07227929,y:-84.31411318},
{x:41.03063675,y:-81.4909061},
{x:42.8773675,y:-77.2695239},
{x:33.32052702,y:-84.77771952},
{x:42.72431115,y:-83.42853963},
{x:39.7278005,y:-75.6494682},
{x:36.82719035,y:-108.00842755},
{x:41.4547207,y:-81.79070301},
{x:30.7888134,y:-81.6558118},
{x:34.07177103,y:-83.91281649},
{x:39.64818473,y:-106.5873729},
{x:41.15604162,y:-85.16451627},
{x:42.87837388,y:-78.80174786},
{x:39.84832036,y:-74.18431021},
{x:40.16125935,y:-104.97611269},
{x:35.56962545,y:-82.54429527},
{x:26.54792418,y:-81.80109624},
{x:39.88329554,y:-105.0257168},
{x:25.79547535,y:-80.2113783},
{x:38.60305842,y:-121.26781672},
{x:26.23148466,y:-80.10518208},
{x:41.45598112,y:-74.05693956},
{x:31.34638827,y:-89.25305575},
{x:39.7509004,y:-122.2080554},
{x:35.06073533,y:-79.01286721},
{x:39.40846121,y:-75.0390575},
{x:42.35476297,y:-83.2749199},
{x:36.7548755,y:-76.0635691},
{x:32.51870482,y:-93.70766126},
{x:41.46522747,y:-82.15635046},
{x:39.97361293,y:-83.04677211},
{x:43.64016807,y:-116.2345355},
{x:41.6179296,y:-90.7811664},
{x:43.14997361,y:-71.00592881},
{x:39.9995435,y:-85.7328235},
{x:39.484863,y:-88.33045},
{x:40.0860894,y:-82.42787651},
{x:36.06930122,y:-79.27000523},
{x:43.2533536,y:-77.6984566},
{x:30.02239581,y:-90.23873158},
{x:38.97114176,y:-95.28338581},
{x:33.50929017,y:-82.02670991},
{x:42.70629296,y:-83.30234036},
{x:42.164573,y:-70.896973},
{x:35.91002546,y:-79.0726351},
{x:30.4127045,y:-91.1876401},
{x:21.3884097,y:-158.0341265},
{x:42.21836163,y:-88.25774968},
{x:39.69504616,y:-86.07915759},
{x:40.9013275,y:-80.8270973},
{x:37.72233709,y:-97.35990726},
{x:35.38384997,y:-119.10420053},
{x:26.68311796,y:-80.24754301},
{x:26.23056076,y:-80.15608907},
{x:40.6346178,y:-74.0757322},
{x:40.02567346,y:-76.20671633},
{x:42.81216024,y:-73.9474538},
{x:42.3167941,y:-87.86547393},
{x:40.38655697,y:-82.48544055},
{x:36.18059316,y:-119.33373474},
{x:35.17290502,y:-80.8507622},
{x:42.67626885,y:-82.76942365},
{x:41.97759594,y:-88.13043326},
{x:40.00114206,y:-82.92935238},
{x:35.87799194,y:-78.64518362},
{x:45.5500985,y:-94.2211968},
{x:40.09831065,y:-85.65313563},
{x:30.37119741,y:-81.66327695},
{x:41.10512771,y:-81.34656742},
{x:40.00144016,y:-83.11174661},
{x:41.68125679,y:-87.53794228},
{x:39.55355331,y:-85.77639654},
{x:25.82438363,y:-80.21446198},
{x:26.75992702,y:-80.09920276},
{x:36.62206138,y:-83.70483667},
{x:40.05389358,y:-83.06793733},
{x:39.09444211,y:-84.86736105},
{x:42.2809285,y:-83.4376431},
{x:40.53117656,y:-74.27989215},
{x:33.56165564,y:-84.32354987},
{x:42.08993419,y:-71.41834251},
{x:42.45735599,y:-83.65175843},
{x:42.47599822,y:-83.0657199},
{x:34.25195429,y:-84.08790574},
{x:29.00822428,y:-81.30220465},
{x:35.80534969,y:-78.81230155},
{x:40.73973144,y:-73.98579834},
{x:28.21755446,y:-82.68853614},
{x:31.69323798,y:-83.24621593},
{x:37.84071442,y:-85.93970678},
{x:41.6200983,y:-87.5254278},
{x:41.5844919,y:-87.5594133},
{x:40.40327902,y:-74.32169572},
{x:41.85027949,y:-88.33963484},
{x:32.0910144,y:-81.2749984},
{x:36.08984834,y:-80.27488075},
{x:25.48660723,y:-80.45953274},
{x:28.92421938,y:-81.18991277},
{x:40.356653,y:-75.981306},
{x:33.78036124,y:-84.23792034},
{x:41.73751773,y:-87.58486927},
{x:40.90233811,y:-81.13291606},
{x:39.3225267,y:-76.7586384},
{x:40.3995219,y:-104.69149813},
{x:32.8142729,y:-115.5703519},
{x:34.07659488,y:-84.65302266},
{x:41.47436198,y:-84.53430444},
{x:44.949089,y:-93.233655},
{x:25.73289653,y:-80.30624717},
{x:39.33707732,y:-82.06654876},
{x:34.88240395,y:-83.94990854},
{x:28.260679,y:-81.6156444},
{x:39.63820944,y:-86.11859143},
{x:33.54291345,y:-84.57759089},
{x:41.63262863,y:-87.93057584},
{x:40.687528,y:-74.182177},
{x:42.5084229,y:-83.08621734},
{x:42.8416249,y:-85.862869},
{x:40.11108027,y:-83.09002206},
{x:27.01779884,y:-82.05221504},
{x:37.81641849,y:-94.70587671},
{x:38.66889727,y:-90.61018244},
{x:43.4157663,y:-70.7488143},
{x:39.78453063,y:-83.99329618},
{x:34.69683712,y:-77.13958275},
{x:42.59715981,y:-83.93408105},
{x:39.03534549,y:-87.16164686},
{x:42.65882895,y:-83.24477611},
{x:30.3081646,y:-89.82624684},
{x:33.44638443,y:-84.13789943},
{x:40.88959742,y:-73.81994292},
{x:43.85457374,y:-111.7766887},
{x:27.56713517,y:-81.81469312},
{x:38.95075546,y:-92.39931269},
{x:38.4132742,y:-82.52244137},
{x:42.0983929,y:-76.2341101},
{x:41.31352656,y:-81.51485793},
{x:42.48531923,y:-96.3579458},
{x:26.69742808,y:-80.11098721},
{x:40.0653114,y:-86.1309509},
{x:35.78448586,y:-78.68745491},
{x:38.57888618,y:-95.26709296},
{x:32.85493066,y:-83.61747041},
{x:29.988668,y:-81.4539821},
{x:41.82315453,y:-87.69005974},
{x:39.55582917,y:-119.78311747},
{x:41.00218595,y:-74.0415024},
{x:42.24178507,y:-83.7351644},
{x:33.62682321,y:-83.97670703},
{x:39.6030491,y:-104.71546666},
{x:48.21922941,y:-101.29557073},
{x:43.0809309,y:-75.3195773},
{x:33.86269406,y:-84.67359558},
{x:40.3998546,y:-82.4555204},
{x:38.80927322,y:-97.59469092},
{x:41.637264,y:-83.47910464},
{x:41.66481824,y:-71.91240549},
{x:21.3365224,y:-157.9017388},
{x:29.86785073,y:-81.32257466},
{x:41.52300138,y:-81.65103436},
{x:40.98951963,y:-80.63303308},
{x:39.41365356,y:-86.4236983},
{x:32.18598704,y:-90.25799015},
{x:30.16112024,y:-91.99246384},
{x:39.27886329,y:-76.50531107},
{x:32.04203845,y:-81.06477723},
{x:35.51700273,y:-78.56022232},
{x:41.04815469,y:-81.72986448},
{x:39.8895201,y:-86.04560383},
{x:42.59567582,y:-83.44680683},
{x:37.93385486,y:-122.32469224},
{x:35.94383204,y:-79.01252483},
{x:28.49131735,y:-81.42876506},
{x:35.09112594,y:-78.98321003},
{x:30.49762052,y:-87.22144049},
{x:32.53793031,y:-84.86841247},
{x:42.00835605,y:-88.33550446},
{x:41.75366357,y:-87.73976147},
{x:28.3784399,y:-81.61859905},
{x:40.799719,y:-81.50188298},
{x:26.67272358,y:-80.6699869},
{x:29.91610126,y:-90.04856474},
{x:38.36653775,y:-93.74926285},
{x:33.4000491,y:-91.0314994},
{x:41.84909786,y:-87.62512444},
{x:38.05175,y:-85.53696},
{x:39.04325075,y:-95.7251966},
{x:40.51366248,y:-74.48607723},
{x:41.79694337,y:-87.7530957},
{x:40.71434002,y:-73.90061269},
{x:31.62334045,y:-84.17764},
{x:42.5122562,y:-90.6551755},
{x:40.59794678,y:-73.65565091},
{x:27.292209,y:-81.35917112},
{x:40.07408361,y:-74.12313044},
{x:35.59159037,y:-78.77583928},
{x:32.34449559,y:-90.83435476},
{x:42.47925045,y:-71.12077832},
{x:38.726677,y:-76.9908909},
{x:42.63296812,y:-83.03229824},
{x:46.59145743,y:-112.00689859},
{x:42.13644045,y:-71.10237569},
{x:40.85030056,y:-73.98534805},
{x:28.06850653,y:-82.63294406},
{x:38.8852253,y:-94.6813458},
{x:38.97156568,y:-94.71085739},
{x:30.4400739,y:-90.08127093},
{x:27.46299965,y:-82.61273462},
{x:39.35171347,y:-94.7649014},
{x:39.0499791,y:-76.93721511},
{x:27.98336284,y:-82.73032442},
{x:39.95470401,y:-82.81665392},
{x:41.82092678,y:-73.08542249},
{x:35.09319872,y:-106.66814222},
{x:36.83907875,y:-119.75497029},
{x:41.69526964,y:-91.61191509},
{x:42.36882255,y:-83.13825087},
{x:30.41278846,y:-91.05117381},
{x:32.55594252,y:-83.66274436},
{x:28.8232129,y:-81.78514295},
{x:28.65584719,y:-81.3330587},
{x:40.71201419,y:-74.22692102},
{x:40.0473656,y:-86.02108508},
{x:37.25345126,y:-83.19849649},
{x:38.92926768,y:-94.63903874},
{x:41.7948892,y:-87.63260915},
{x:40.7390615,y:-73.6126223},
{x:42.30555067,y:-88.0025737},
{x:40.3292104,y:-83.0732311},
{x:41.79554716,y:-88.20507109},
{x:39.76310269,y:-86.33585789},
{x:41.4709073,y:-90.48373103},
{x:35.94850221,y:-78.53256501},
{x:35.08912216,y:-106.51468009},
{x:42.74835336,y:-78.8528131},
{x:30.99034,y:-83.38321},
{x:41.77222086,y:-80.94610691},
{x:29.11443469,y:-82.02217808},
{x:32.27324546,y:-89.9931255},
{x:40.84586325,y:-81.94481134},
{x:28.94001318,y:-81.94583225},
{x:35.11285923,y:-83.10591057},
{x:40.91921496,y:-74.16905389},
{x:34.03674066,y:-84.46464613},
{x:42.80520133,y:-86.11542106},
{x:40.6830432,y:-99.084142},
{x:41.2959518,y:-92.6513385},
{x:33.54970394,y:-83.20268691},
{x:36.03332948,y:-115.04629076},
{x:38.97373481,y:-76.99543834},
{x:30.22994274,y:-93.21885831},
{x:41.37420814,y:-83.62893015},
{x:39.41614037,y:-84.47416213},
{x:37.34474695,y:-85.3384208},
{x:39.71425782,y:-82.98638843},
{x:44.74582591,y:-93.30700614},
{x:41.26576076,y:-82.21964753},
{x:42.92541344,y:-72.28969204},
{x:29.1038383,y:-81.0353988},
{x:42.1165031,y:-72.0659599},
{x:41.99679299,y:-87.69016394},
{x:41.4123124,y:-82.2098685},
{x:27.85460538,y:-82.63915814},
{x:40.01639082,y:-74.95428961},
{x:42.63445599,y:-83.13231915},
{x:32.515943,y:-92.183826},
{x:26.13192342,y:-81.77295014},
{x:39.90860465,y:-82.77873427},
{x:34.1531265,y:-83.6496301},
{x:39.54936782,y:-104.77370436},
{x:40.52423848,y:-104.98781345},
{x:37.1313938,y:-93.4591189},
{x:38.5361707,y:-82.685033},
{x:41.99684957,y:-72.58443258},
{x:36.11484839,y:-115.22635281},
{x:33.45280877,y:-111.68358301},
{x:41.27605074,y:-82.84784541},
{x:37.99967075,y:-84.52153378},
{x:28.83629998,y:-82.33572185},
{x:36.94915977,y:-86.42377451},
{x:42.43307735,y:-87.82599851},
{x:39.81297718,y:-105.16180605},
{x:39.57112135,y:-104.98791844},
{x:33.98014347,y:-88.47726434},
{x:27.27382785,y:-80.3540542},
{x:39.15491523,y:-77.20034838},
{x:40.0805343,y:-104.8186999},
{x:38.05074608,y:-84.71557885},
{x:28.3714565,y:-81.5039962},
{x:42.29743064,y:-85.21725819},
{x:39.75391267,y:-86.24518096},
{x:38.00057595,y:-103.59333436},
{x:30.4022789,y:-86.8762509},
{x:42.24331795,y:-85.67812006},
{x:40.08969984,y:-82.99218849},
{x:41.84977801,y:-87.97300369},
{x:41.7223231,y:-83.51381493},
{x:37.46913817,y:-86.29278213},
{x:30.58047467,y:-91.12835541},
{x:29.9098829,y:-90.0185827},
{x:42.9447578,y:-83.6957604},
{x:30.40027705,y:-91.10702679},
{x:36.2985795,y:-76.2314065},
{x:42.19771291,y:-85.64204432},
{x:38.94032602,y:-104.60271798},
{x:41.06920472,y:-85.05191982},
{x:42.10330515,y:-72.62452528},
{x:38.16649184,y:-85.83013895},
{x:42.44706777,y:-75.05072282},
{x:30.29159847,y:-91.91287802},
{x:42.62378262,y:-82.99374953},
{x:35.85034254,y:-78.57993857},
{x:40.7028275,y:-73.8140535},
{x:37.69361589,y:-97.44591683},
{x:43.0495803,y:-76.0721603},
{x:35.4230875,y:-80.6767481},
{x:42.46864151,y:-73.19567241},
{x:36.05485993,y:-115.24461748},
{x:40.83283366,y:-84.93830815},
{x:39.19631946,y:-84.381048},
{x:40.16522566,y:-83.01892072},
{x:35.99956339,y:-75.65208614},
{x:42.88454723,y:-85.54795782},
{x:33.83952646,y:-83.90645764},
{x:30.24447208,y:-81.5978361},
{x:37.37174737,y:-86.8484623},
{x:39.2345763,y:-76.83002718},
{x:27.81576845,y:-82.75445188},
{x:40.80822854,y:-74.1192238},
{x:45.01166515,y:-93.4620747},
{x:39.96574965,y:-82.97302411},
{x:34.22736397,y:-77.82764085},
{x:33.72781078,y:-84.93904039},
{x:42.74034773,y:-84.73282442},
{x:41.8790513,y:-87.6860127},
{x:27.27686217,y:-80.29102027},
{x:41.76628443,y:-72.57218331},
{x:30.0437505,y:-89.9597368},
{x:37.6851724,y:-97.2439719},
{x:32.44361059,y:-93.90050322},
{x:41.36718325,y:-85.07780582},
{x:40.88467417,y:-73.90121958},
{x:38.33660392,y:-85.79786856},
{x:27.9964645,y:-82.5013461},
{x:40.0563485,y:-82.4818147},
{x:39.47750826,y:-119.76543601},
{x:34.82347338,y:-89.97912169},
{x:40.45857142,y:-90.68488196},
{x:36.0625453,y:-79.81586963},
{x:25.88380564,y:-80.33119939},
{x:41.40628919,y:-81.69175632},
{x:38.05734408,y:-97.3462176},
{x:38.98073174,y:-76.52808942},
{x:36.03237561,y:-79.96726952},
{x:29.26789035,y:-82.19174355},
{x:30.47957366,y:-84.23746303},
{x:38.19993452,y:-85.83716295},
{x:41.6617573,y:-86.13507519},
{x:40.68618028,y:-74.09888499},
{x:40.89421833,y:-74.24131528},
{x:39.95100031,y:-83.14708606},
{x:32.4201286,y:-90.1498863},
{x:39.5312663,y:-107.32578629},
{x:38.85083416,y:-104.75695409},
{x:36.37509076,y:-80.4880996},
{x:38.20901867,y:-86.86463647},
{x:39.4565432,y:-75.21157514},
{x:37.09228608,y:-85.30454234},
{x:40.53618815,y:-105.07657484},
{x:42.88811424,y:-85.83709225},
{x:38.94919561,y:-76.71958722},
{x:40.58828248,y:-74.09104183},
{x:25.76504299,y:-80.23448694},
{x:40.8056989,y:-82.97588736},
{x:42.26714783,y:-89.07917485},
{x:39.7472405,y:-84.17219818},
{x:28.426469,y:-81.405348},
{x:34.01070973,y:-85.03180019},
{x:42.3520288,y:-83.0571582},
{x:42.49883342,y:-83.35665911},
{x:42.70748836,y:-71.16514139},
{x:39.92717863,y:-85.38128752},
{x:42.4504377,y:-82.9151145},
{x:46.46242333,y:-84.37475592},
{x:29.67739865,y:-82.33869212},
{x:44.77703,y:-93.41648},
{x:39.75849858,y:-86.43400803},
{x:42.26909811,y:-71.02393046},
{x:40.54440895,y:-89.59722228},
{x:39.08643984,y:-95.66539407},
{x:26.6466917,y:-80.0566064},
{x:40.96844421,y:-81.34719774},
{x:38.62450529,y:-88.9675714},
{x:39.0149784,y:-94.1312345},
{x:37.57006409,y:-84.31046472},
{x:35.44994,y:-80.99411},
{x:31.57046585,y:-84.10498709},
{x:40.86188242,y:-73.62350196},
{x:38.35511773,y:-87.58183971},
{x:40.15606836,y:-83.11829844},
{x:35.21592881,y:-80.78029007},
{x:42.4148422,y:-82.9108883},
{x:35.23584111,y:-80.73864435},
{x:28.36396892,y:-81.36653513},
{x:35.55368252,y:-82.85041612},
{x:41.55794265,y:-87.63559118},
{x:38.42261812,y:-83.7535204},
{x:25.78026405,y:-80.22099684},
{x:42.2124405,y:-71.7969872},
{x:38.24336038,y:-85.5126965},
{x:41.68571596,y:-86.89440534},
{x:38.38031076,y:-84.30651421},
{x:34.6750181,y:-77.3406681},
{x:30.50075656,y:-90.10682837},
{x:39.73412832,y:-105.16255975},
{x:39.1161886,y:-94.6709635},
{x:41.034931,y:-84.036508},
{x:40.24825465,y:-74.03917654},
{x:37.04571309,y:-86.21005676},
{x:40.1403048,y:-88.25840601},
{x:30.2944078,y:-92.02343467},
{x:39.6794695,y:-105.0244879},
{x:41.38234318,y:-88.42259824},
{x:48.308917,y:-116.546728},
{x:34.23454777,y:-77.87492587},
{x:37.3042448,y:-89.5528657},
{x:32.1142808,y:-81.24200284},
{x:33.77221574,y:-84.55873751},
{x:34.75382648,y:-79.47685793},
{x:32.4752889,y:-83.7063},
{x:39.66025445,y:-104.85337698},
{x:41.28910569,y:-73.11213076},
{x:32.84274063,y:-90.3874246},
{x:41.95095789,y:-91.71535045},
{x:27.94551046,y:-82.33768121},
{x:35.6154909,y:-120.68282944},
{x:37.65900874,y:-121.03146248},
{x:42.09485691,y:-72.5124076},
{x:33.5542271,y:-84.34753552},
{x:35.62655505,y:-77.32855797},
{x:43.1064897,y:-77.74854228},
{x:33.7973571,y:-84.2830756},
{x:31.28114058,y:-92.47796379},
{x:41.95038538,y:-88.12107213},
{x:26.04501005,y:-80.28307156},
{x:37.31101515,y:-121.80903286},
{x:26.0082437,y:-80.37806851},
{x:43.2844701,y:-86.22271135},
{x:33.9413172,y:-84.70453686},
{x:31.61292002,y:-84.21574116},
{x:28.27647794,y:-82.67388766},
{x:29.22399195,y:-81.09567053},
{x:41.66720364,y:-73.92917186},
{x:28.55331433,y:-80.84447533},
{x:31.68668463,y:-88.63207512},
{x:39.92809285,y:-83.05411265},
{x:39.1387297,y:-88.564891},
{x:33.55438468,y:-84.26560074},
{x:35.26016066,y:-77.65153348},
{x:34.30019994,y:-77.79042631},
{x:32.44767587,y:-93.8657847},
{x:40.437,y:-104.988},
{x:41.05745289,y:-73.81760538},
{x:41.37584697,y:-81.73519261},
{x:42.98522028,y:-71.41249194},
{x:40.73959,y:-96.68257},
{x:37.15913388,y:-93.27520884},
{x:33.68928587,y:-85.15203761},
{x:33.10858732,y:-83.25370751},
{x:42.34575079,y:-83.00611787},
{x:32.53140773,y:-93.67195384},
{x:40.11991184,y:-74.70757739},
{x:38.99224303,y:-105.05929838},
{x:41.57444499,y:-87.18268355},
{x:33.8038008,y:-84.4136037},
{x:38.46843239,y:-90.3048742},
{x:42.04810556,y:-71.01024583},
{x:40.82891163,y:-73.41689869},
{x:40.74551752,y:-86.78087711},
{x:44.24922472,y:-84.22474227},
{x:28.1976943,y:-81.2950701},
{x:31.17688507,y:-83.76451045},
{x:30.75981359,y:-81.65561095},
{x:38.8114854,y:-90.8791477},
{x:35.66226006,y:-78.84780369},
{x:35.32536704,y:-83.80365964},
{x:37.3483521,y:-108.5816862},
{x:42.29555813,y:-85.65700431},
{x:32.355551,y:-88.69369447},
{x:39.7811968,y:-94.80257839},
{x:35.11247526,y:-78.87900621},
{x:26.6507265,y:-80.1503675},
{x:37.69455505,y:-97.13468939},
{x:34.09594314,y:-84.01161366},
{x:27.9981339,y:-80.63560635},
{x:42.6615392,y:-84.59337205},
{x:42.0277815,y:-71.227943},
{x:46.73340172,y:-117.01529053},
{x:41.5815604,y:-83.88063937},
{x:34.23211406,y:-83.87136395},
{x:38.30824183,y:-76.66383139},
{x:34.04971486,y:-84.09076631},
{x:33.65371656,y:-84.00270269},
{x:30.1152312,y:-82.65849314},
{x:39.9867189,y:-82.98739538},
{x:39.08472224,y:-77.07675353},
{x:29.92443313,y:-90.0214456},
{x:44.7458445,y:-93.3781509},
{x:37.29587839,y:-83.21609889},
{x:40.62713895,y:-74.24393177},
{x:29.97871097,y:-90.28465029},
{x:33.58490209,y:-84.33938362},
{x:42.95506408,y:-78.82208169},
{x:37.00467219,y:-94.55245167},
{x:45.0016101,y:-93.2112347},
{x:35.25311909,y:-81.02690578},
{x:26.80937675,y:-80.10505468},
{x:41.66624589,y:-83.57297793},
{x:41.3854253,y:-81.4362156},
{x:43.8024524,y:-111.8093307},
{x:31.6039587,y:-81.9051227},
{x:39.60502572,y:-75.74651666},
{x:42.66117524,y:-84.53897348},
{x:28.0726406,y:-82.42673725},
{x:42.45491301,y:-83.27913254},
{x:39.7385639,y:-75.61115545},
{x:42.72107492,y:-82.74750383},
{x:28.69386402,y:-81.32812589},
{x:45.05971042,y:-93.24822485},
{x:36.14027203,y:-81.1772529},
{x:40.8121478,y:-74.0130608},
{x:29.9864551,y:-91.8190916},
{x:41.42787416,y:-74.42703813},
{x:40.5798106,y:-98.392809},
{x:40.56233861,y:-84.17350308},
{x:30.00640175,y:-90.03465012},
{x:39.6068628,y:-86.36354771},
{x:36.35828,y:-94.27686},
{x:39.7582098,y:-121.8443219},
{x:27.97965939,y:-81.88865106},
{x:38.54018627,y:-76.98478627},
{x:21.49560598,y:-158.02924364},
{x:37.55731329,y:-97.24492886},
{x:35.41416722,y:-78.80768492},
{x:45.05674809,y:-93.32257837},
{x:43.60542138,y:-116.28389016},
{x:25.78303678,y:-80.31880796},
{x:36.08963376,y:-78.30167547},
{x:42.64995215,y:-84.07328099},
{x:39.08974481,y:-84.2487511},
{x:38.6709722,y:-121.16466693},
{x:36.10596297,y:-79.8269847},
{x:33.57689453,y:-83.96231868},
{x:42.72229833,y:-84.41721395},
{x:32.36460507,y:-88.70986618},
{x:40.04242472,y:-86.10318742},
{x:38.82211083,y:-91.13810114},
{x:40.8801096,y:-81.4367239},
{x:28.3459006,y:-81.4206491},
{x:42.13681132,y:-88.43180014},
{x:30.1900782,y:-85.6691621},
{x:42.083952,y:-78.4303369},
{x:41.79014526,y:-87.8676539},
{x:42.94952795,y:-85.07525772},
{x:36.32675396,y:-76.22434174},
{x:37.24053182,y:-93.30693461},
{x:33.90230185,y:-84.12244051},
{x:35.53593733,y:-108.75944219},
{x:27.9368788,y:-81.97471098},
{x:36.64388562,y:-93.22276884},
{x:40.39467554,y:-84.1628231},
{x:30.13220464,y:-81.78461373},
{x:37.14894589,y:-93.29537004},
{x:39.94304866,y:-83.08033929},
{x:42.44963293,y:-83.002747},
{x:39.72281876,y:-90.24131934},
{x:28.32616,y:-81.46998122},
{x:30.44884941,y:-86.6260922},
{x:38.90728977,y:-94.52577174},
{x:25.84753851,y:-80.18436725},
{x:40.50429851,y:-74.85354905},
{x:38.7751728,y:-85.40051468},
{x:42.27039419,y:-84.98516232},
{x:43.002819,y:-83.7651062},
{x:36.14490135,y:-115.20020629},
{x:39.486333,y:-86.06495387},
{x:39.35432884,y:-84.37072955},
{x:25.84006377,y:-80.31865641},
{x:39.42655545,y:-75.23156941},
{x:26.70913208,y:-81.72177225},
{x:35.64365036,y:-80.48477999},
{x:40.68022918,y:-73.87792021},
{x:40.01907703,y:-83.05696785},
{x:35.88289042,y:-77.54831386},
{x:40.7095889,y:-74.1004734},
{x:36.07090714,y:-80.34002281},
{x:39.62461668,y:-105.09193813},
{x:30.22112497,y:-81.57898585},
{x:35.47963525,y:-80.61209142},
{x:42.153478,y:-77.079293},
{x:38.91719332,y:-90.16261421},
{x:38.85812081,y:-94.81360219},
{x:38.6296035,y:-76.9081287},
{x:38.21999991,y:-85.62204965},
{x:26.57119632,y:-80.1466383},
{x:44.99875042,y:-93.28466266},
{x:42.26807267,y:-88.99183296},
{x:33.77272414,y:-84.37164322},
{x:41.62223028,y:-88.12432147},
{x:43.63779448,y:-70.33411928},
{x:39.14794099,y:-84.40398395},
{x:42.99826409,y:-78.18569005},
{x:30.72346694,y:-86.56843208},
{x:42.61311532,y:-83.32727551},
{x:41.53690226,y:-88.19870219},
{x:41.68647844,y:-83.52728642},
{x:39.76940228,y:-84.12392981},
{x:40.75177444,y:-81.4019879},
{x:43.04085297,y:-78.82493533},
{x:42.742218,y:-73.649707},
{x:38.69187323,y:-90.39752466},
{x:46.79528355,y:-100.77823699},
{x:34.1928059,y:-77.92036719},
{x:25.4779221,y:-80.43306202},
{x:42.59444685,y:-84.4677119},
{x:42.17246566,y:-88.33451136},
{x:28.59338191,y:-81.30620152},
{x:32.78398,y:-96.81439},
{x:43.1105548,y:-75.2184368},
{x:30.33622248,y:-81.76317483},
{x:38.21036861,y:-85.34803025},
{x:41.93753502,y:-84.97933536},
{x:41.81668209,y:-72.70861633},
{x:24.55704786,y:-81.80358231},
{x:34.18949675,y:-90.5797977},
{x:41.71972867,y:-86.1871647},
{x:42.5803384,y:-72.1831809},
{x:34.9330215,y:-89.9980926},
{x:35.2186292,y:-80.6526865},
{x:42.43344655,y:-96.37766004},
{x:40.45287132,y:-86.91812441},
{x:35.25905683,y:-81.2240535},
{x:30.45155585,y:-81.70400747},
{x:39.64647832,y:-75.33982337},
{x:28.19062072,y:-82.60872241},
{x:32.6178772,y:-83.68568417},
{x:43.23452545,y:-86.19974618},
{x:40.19635363,y:-74.64383841},
{x:40.64366454,y:-73.78202181},
{x:27.9924617,y:-81.95733726},
{x:33.94641166,y:-83.75129789},
{x:37.77301755,y:-80.35075837},
{x:39.52353612,y:-83.84609386},
{x:29.0610369,y:-82.4538648},
{x:43.59980814,y:-116.39249414},
{x:41.36218585,y:-86.32951034},
{x:43.24786374,y:-83.78269315},
{x:37.66600988,y:-82.27475122},
{x:36.33295017,y:-78.43434557},
{x:35.70346,y:-82.038605},
{x:42.17127,y:-72.47869},
{x:36.14297363,y:-115.11934037},
{x:30.13940486,y:-81.54634337},
{x:34.37864886,y:-84.90857837},
{x:39.66590065,y:-104.94023344},
{x:40.95567115,y:-74.22107227},
{x:35.03690244,y:-106.71273306},
{x:43.19040144,y:-86.26405567},
{x:29.66007334,y:-82.4142462},
{x:39.66775781,y:-75.66996422},
{x:45.56998403,y:-94.14088938},
{x:41.49212285,y:-87.5076887},
{x:36.14221557,y:-80.29717058},
{x:40.68994826,y:-95.78880467},
{x:41.61654805,y:-87.0404251},
{x:39.07398674,y:-94.37982905},
{x:41.780383,y:-70.7439772},
{x:39.16049953,y:-86.49807148},
{x:32.2227051,y:-90.15620381},
{x:33.37169063,y:-84.56752092},
{x:30.19852149,y:-93.23197268},
{x:42.09013376,y:-86.41203046},
{x:37.64893952,y:-97.35223532},
{x:39.70942428,y:-111.82032471},
{x:38.37501043,y:-97.66658753},
{x:41.20083815,y:-73.98390586},
{x:43.5633067,y:-71.4441485},
{x:33.97170894,y:-84.48682195},
{x:45.81498207,y:-88.06402579},
{x:33.8899104,y:-117.52020948},
{x:39.36231654,y:-76.78003301},
{x:28.92844405,y:-81.29939407},
{x:26.19785548,y:-81.79963343},
{x:33.5679784,y:-85.0772848},
{x:34.96790009,y:-80.07380158},
{x:38.34113226,y:-82.94197215},
{x:40.51093715,y:-88.95506327},
{x:37.72605513,y:-89.21041399},
{x:42.77979523,y:-74.03003998},
{x:41.67336343,y:-83.71948775},
{x:30.35534443,y:-91.10897407},
{x:35.11944158,y:-106.64535962},
{x:28.08116967,y:-82.73978591},
{x:31.32500552,y:-89.31466475},
{x:40.83509117,y:-81.42449365},
{x:42.09032965,y:-88.25730846},
{x:39.67697882,y:-104.91329425},
{x:37.94613924,y:-91.77878335},
{x:36.07721252,y:-80.17991103},
{x:26.08252885,y:-80.25160638},
{x:40.99074156,y:-74.33713545},
{x:40.8702919,y:-72.7992501},
{x:34.00096917,y:-84.59114179},
{x:40.8701522,y:-74.41983908},
{x:30.289898,y:-89.723929},
{x:40.69264344,y:-73.39558326},
{x:32.31425338,y:-106.75203915},
{x:43.32779422,y:-73.6674685},
{x:40.0322048,y:-83.1296527},
{x:39.88931842,y:-82.99833611},
{x:41.9188119,y:-72.61031439},
{x:40.7804992,y:-73.55990075},
{x:26.2745046,y:-80.20811923},
{x:41.61444369,y:-93.83660093},
{x:30.41286595,y:-81.56627253},
{x:40.5543279,y:-85.55447592},
{x:39.654987,y:-77.75142},
{x:28.22115748,y:-82.73470178},
{x:33.50883825,y:-84.22932655},
{x:40.6336,y:-73.92812},
{x:32.77002965,y:-89.12784487},
{x:37.78332299,y:-84.84677769},
{x:39.79869879,y:-86.15672976},
{x:39.20975197,y:-84.70555679},
{x:30.38939867,y:-88.99272121},
{x:30.48087853,y:-87.20600612},
{x:40.93753154,y:-74.26870197},
{x:40.74583961,y:-74.18996371},
{x:28.14755143,y:-81.45029129},
{x:40.6579522,y:-74.2271351},
{x:40.563486,y:-74.2996158},
{x:45.08327287,y:-93.37605625},
{x:39.83508673,y:-75.08788214},
{x:47.73340424,y:-116.79056816},
{x:38.9000761,y:-76.7912176},
{x:40.11724548,y:-88.20509791},
{x:39.94338711,y:-104.93848935},
{x:28.68664877,y:-81.39798066},
{x:41.69702323,y:-73.90432119},
{x:33.73844189,y:-84.42134589},
{x:41.66537924,y:-88.44129004},
{x:35.68733825,y:-79.82299172},
{x:39.29497717,y:-84.3164286},
{x:42.96473147,y:-71.44720852},
{x:35.56761392,y:-77.35595405},
{x:42.56611165,y:-71.97822422},
{x:38.88081501,y:-82.99642906},
{x:41.65005887,y:-93.69697855},
{x:34.42800456,y:-118.53596338},
{x:39.14428768,y:-94.56573762},
{x:40.80053371,y:-72.86620286},
{x:35.13101281,y:-106.53488092},
{x:35.92580438,y:-79.98530544},
{x:42.7957189,y:-78.7509376},
{x:38.65018253,y:-85.1243704},
{x:42.97177626,y:-85.76422737},
{x:28.61905066,y:-81.26658962},
{x:41.369323,y:-81.5139578},
{x:38.94256089,y:-95.2425921},
{x:40.8624859,y:-74.37360987},
{x:28.4743017,y:-81.28941648},
{x:29.66810767,y:-82.32405566},
{x:41.02070999,y:-73.64101209},
{x:29.97627504,y:-90.18353517},
{x:35.34672352,y:-80.16737677},
{x:40.93034825,y:-72.67946899},
{x:38.15624555,y:-85.71380988},
{x:27.9847435,y:-82.1221238},
{x:36.17345482,y:-79.99071973},
{x:41.9280439,y:-87.88409374},
{x:36.08453676,y:-79.77746174},
{x:26.514268,y:-80.05805165},
{x:39.7726973,y:-82.6841302},
{x:32.4871896,y:-93.70485485},
{x:38.78256227,y:-97.61418924},
{x:27.99956908,y:-81.92451507},
{x:42.14705579,y:-88.5092859},
{x:43.5075455,y:-112.0229768},
{x:26.30208539,y:-80.13140969},
{x:43.21113657,y:-77.70803422},
{x:38.54175242,y:-76.58996612},
{x:28.82576476,y:-81.68502964},
{x:42.07059999,y:-70.94848223},
{x:26.56649316,y:-81.87257811},
{x:41.36260962,y:-83.13717127},
{x:39.0317298,y:-84.5342106},
{x:31.27650888,y:-92.45678372},
{x:41.1303406,y:-85.0098275},
{x:41.45090913,y:-96.48313142},
{x:43.03506908,y:-83.51849642},
{x:28.57848292,y:-81.41589264},
{x:29.55098056,y:-81.24423064},
{x:41.64014754,y:-71.00103512},
{x:39.8561174,y:-105.0810287},
{x:27.72802612,y:-82.6787442},
{x:38.20410005,y:-84.274216},
{x:28.786769,y:-81.9821807},
{x:28.89426819,y:-82.58525916},
{x:28.46331812,y:-81.39667265},
{x:39.04806792,y:-82.63161744},
{x:42.32147,y:-83.48454349},
{x:41.65851118,y:-88.12313229},
{x:39.34922765,y:-84.46093798},
{x:40.0029138,y:-82.6736436},
{x:39.71761467,y:-86.35905549},
{x:28.36718986,y:-81.68141239},
{x:40.22826904,y:-74.66775298},
{x:41.01391296,y:-81.55231662},
{x:26.09483428,y:-80.36889381},
{x:28.5340847,y:-82.5154677},
{x:39.4114758,y:-84.1506682},
{x:40.85626114,y:-74.04788375},
{x:26.16419632,y:-80.22955477},
{x:41.55579078,y:-73.41775293},
{x:39.07772335,y:-108.5415785},
{x:40.8079448,y:-81.3716604},
{x:35.13497877,y:-106.61231748},
{x:40.85816843,y:-74.34087333},
{x:38.96627129,y:-84.67754361},
{x:30.26954423,y:-81.76458338},
{x:41.74896554,y:-88.16844352},
{x:42.38790376,y:-82.97338553},
{x:37.6789807,y:-96.9860133},
{x:28.9274834,y:-82.0373039},
{x:44.27268631,y:-88.46430391},
{x:42.10379171,y:-75.93069658},
{x:40.03779261,y:-82.42904797},
{x:39.1897166,y:-84.5998062},
{x:44.82911978,y:-93.28929596},
{x:25.9392167,y:-80.27862665},
{x:39.1243143,y:-84.35483766},
{x:39.84901385,y:-74.98712897},
{x:38.88240852,y:-94.83400424},
{x:37.14304881,y:-93.25584166},
{x:33.5043032,y:-84.141959},
{x:44.10741477,y:-70.22543952},
{x:36.522773,y:-76.1769475},
{x:27.42487957,y:-80.32641605},
{x:39.60154677,y:-77.72998407},
{x:30.10283907,y:-81.71274662},
{x:30.3004479,y:-81.7662862},
{x:39.6486958,y:-78.76027622},
{x:42.52721934,y:-70.99361522},
{x:39.1858142,y:-94.5760779},
{x:30.44956688,y:-91.2458799},
{x:40.08193587,y:-74.04767185},
{x:31.58204123,y:-84.17664893},
{x:41.67707792,y:-71.16284859},
{x:42.43504888,y:-82.95328305},
{x:34.67488683,y:-77.33677248},
{x:42.88403375,y:-85.77299642},
{x:40.63986492,y:-81.44337036},
{x:31.24804954,y:-90.47316849},
{x:41.50566879,y:-87.67017344},
{x:40.86812491,y:-73.02534387},
{x:42.09484538,y:-83.23347666},
{x:31.47231277,y:-83.50422851},
{x:38.17345475,y:-87.55160107},
{x:32.9921348,y:-85.0300143},
{x:41.53431569,y:-73.89866923},
{x:33.3854455,y:-84.7621146},
{x:37.03362083,y:-88.66959885},
{x:34.03210975,y:-84.57961935},
{x:44.734738,y:-85.595975},
{x:35.26087245,y:-80.42399079},
{x:33.68989742,y:-84.48829382},
{x:39.9493914,y:-86.3512637},
{x:32.47098651,y:-83.74242362},
{x:34.26171884,y:-85.18106242},
{x:41.75162738,y:-88.017983},
{x:39.1314633,y:-76.8193475},
{x:37.99844569,y:-88.93761217},
{x:42.97034572,y:-85.64216271},
{x:41.78926891,y:-87.70321557},
{x:34.5323966,y:-83.97574246},
{x:28.06980289,y:-82.36195083},
{x:44.96246377,y:-93.27129319},
{x:39.6962583,y:-84.1049141},
{x:41.1629994,y:-87.87670635},
{x:39.92503798,y:-74.95211504},
{x:43.04003799,y:-76.27138674},
{x:45.22367848,y:-93.39862704},
{x:41.04231458,y:-82.72346243},
{x:40.85052272,y:-74.29777846},
{x:25.6844817,y:-80.4547344},
{x:30.90946346,y:-83.26402233},
{x:40.60396865,y:-74.69238856},
{x:38.3967663,y:-85.3705225},
{x:41.4245566,y:-87.36403362},
{x:38.61671895,y:-90.28650246},
{x:40.9034886,y:-98.3401237},
{x:36.08153282,y:-79.47667145},
{x:30.2164303,y:-81.5190454},
{x:26.0085393,y:-80.2485624},
{x:41.21043298,y:-73.08265731},
{x:38.8901633,y:-76.85406044},
{x:39.77196173,y:-105.0819017},
{x:42.1620813,y:-71.50892079},
{x:42.75931366,y:-71.45782319},
{x:39.73273389,y:-84.20658526},
{x:33.82038045,y:-84.45245146},
{x:41.8508589,y:-87.75343806},
{x:42.4747523,y:-70.9576044},
{x:30.84342911,y:-83.32734056},
{x:39.13757092,y:-84.53690886},
{x:39.16949691,y:-94.49536353},
{x:40.40260794,y:-74.12528473},
{x:37.55724688,y:-83.38085632},
{x:43.68139126,y:-111.90885186},
{x:26.604763,y:-81.810623},
{x:40.74306983,y:-74.15537998},
{x:38.7377632,y:-104.7325432},
{x:25.81118125,y:-80.36858708},
{x:35.14033572,y:-80.73627099},
{x:37.98175098,y:-87.60080628},
{x:36.14431762,y:-115.26910688},
{x:33.82455015,y:-84.12007845},
{x:35.71885748,y:-78.6552684},
{x:41.53800933,y:-93.59628574},
{x:41.78024092,y:-72.53494352},
{x:39.45362711,y:-108.04655269},
{x:36.74297435,y:-91.87364939},
{x:28.57838716,y:-81.47659135},
{x:41.02385829,y:-80.64676009},
{x:39.68812468,y:-84.13197392},
{x:39.71157347,y:-105.07415816},
{x:40.0009383,y:-104.9917248},
{x:35.42863483,y:-78.06375012},
{x:35.52897714,y:-108.70545034},
{x:43.97870899,y:-92.46155977},
{x:38.00969135,y:-84.21546221},
{x:26.35857608,y:-80.0854113},
{x:39.26310507,y:-84.41691756},
{x:26.0617905,y:-81.6979947},
{x:26.2261532,y:-80.183003},
{x:29.92484227,y:-81.32616386},
{x:44.73045486,y:-93.21676522},
{x:35.30799428,y:-80.84141949},
{x:42.04882453,y:-88.08097633},
{x:34.23394246,y:-119.17230226},
{x:39.71351505,y:-82.57853955},
{x:33.85917694,y:-84.01973251},
{x:42.81110067,y:-71.10252321},
{x:25.77735129,y:-80.28759375},
{x:39.8306162,y:-105.0254455},
{x:38.32209101,y:-104.70295787},
{x:33.5489521,y:-84.1364937},
{x:38.59195231,y:-90.24975774},
{x:41.13094264,y:-85.13655692},
{x:38.4052265,y:-96.204537},
{x:28.51338941,y:-81.46164894},
{x:28.56631377,y:-81.20827707},
{x:31.578524,y:-90.46578169},
{x:43.05439104,y:-85.57917706},
{x:39.07027501,y:-94.5857124},
{x:25.68477157,y:-80.42034264},
{x:36.73256881,y:-88.63372296},
{x:38.29722971,y:-85.55250241},
{x:33.52389944,y:-82.03375876},
{x:41.64853684,y:-87.73872802},
{x:38.8743011,y:-104.80642275},
{x:42.09924735,y:-76.04757518},
{x:40.81609091,y:-96.70156091},
{x:41.23873332,y:-81.80553027},
{x:39.15245227,y:-108.73758485},
{x:39.86014307,y:-85.97215891},
{x:26.27210118,y:-80.26009039},
{x:30.49668273,y:-87.26266112},
{x:36.64139792,y:-87.43574686},
{x:28.03217271,y:-82.05651462},
{x:33.98134785,y:-84.4254443},
{x:34.3919516,y:-77.6797893},
{x:43.97366965,y:-75.90432845},
{x:40.8088831,y:-73.6280298},
{x:43.21268775,y:-75.46544462},
{x:32.81046473,y:-83.72274307},
{x:41.6256724,y:-85.90732187},
{x:25.44394547,y:-80.47554669},
{x:44.3482125,y:-69.7962901},
{x:35.2244936,y:-111.58377161},
{x:44.97234013,y:-93.44324693},
{x:32.3635236,y:-106.7483563},
{x:29.22010267,y:-82.14572489},
{x:39.77668558,y:-84.06540334},
{x:42.5007131,y:-94.1656821},
{x:31.48720451,y:-82.85060272},
{x:39.80523768,y:-85.77027671},
{x:36.67215143,y:-93.22542237},
{x:25.88887008,y:-80.21048258},
{x:42.7739272,y:-73.8958688},
{x:44.94999529,y:-93.02314192},
{x:34.98586676,y:-118.94821823},
{x:28.22976566,y:-80.71338721},
{x:41.6083322,y:-81.52724755},
{x:35.736942,y:-77.95358777},
{x:31.36057753,y:-89.34939496},
{x:26.66531729,y:-81.88730881},
{x:35.77791115,y:-81.39455548},
{x:44.95521527,y:-93.12559694},
{x:40.45069349,y:-86.12755972},
{x:36.04508152,y:-80.26052758},
{x:31.86995331,y:-89.74493936},
{x:36.10041192,y:-115.18513128},
{x:34.90748783,y:-85.1303798},
{x:43.45186905,y:-83.89396948},
{x:40.83606542,y:-83.23721096},
{x:40.78003368,y:-74.18935664},
{x:42.27095629,y:-83.49321776},
{x:45.97781641,y:-112.50854284},
{x:39.95694853,y:-104.98389379},
{x:30.43925638,y:-90.45678387},
{x:32.46799093,y:-84.96780239},
{x:33.94514,y:-118.2120456},
{x:36.76889521,y:-119.79095757},
{x:39.59627046,y:-76.84626594},
{x:39.5438677,y:-104.94402},
{x:21.3999014,y:-157.9733207},
{x:39.415751,y:-87.692995},
{x:39.97151686,y:-104.82480877},
{x:26.6856221,y:-80.2010364},
{x:41.42542661,y:-82.34105408},
{x:43.00271842,y:-78.85595358},
{x:43.59882043,y:-116.19318523},
{x:40.7289893,y:-73.7034994},
{x:30.4029498,y:-84.2369299},
{x:39.02278425,y:-94.64331381},
{x:37.70885111,y:-85.8734268},
{x:38.42780027,y:-95.72714649},
{x:39.39049233,y:-84.50815089},
{x:39.97186008,y:-104.7560619},
{x:42.73954754,y:-71.19698659},
{x:33.79233647,y:-83.73289636},
{x:35.0512837,y:-80.7705107},
{x:44.03970487,y:-75.84023882},
{x:40.72953267,y:-73.46414052},
{x:40.56537786,y:-83.11987034},
{x:39.84640162,y:-75.1849511},
{x:28.094391,y:-81.274004},
{x:42.64226666,y:-71.23963571},
{x:32.6219986,y:-93.27335715},
{x:39.1280707,y:-94.7584982},
{x:30.84426342,y:-89.14584383},
{x:29.97954811,y:-92.10926868},
{x:41.34920495,y:-72.05538332},
{x:42.3983984,y:-70.9998382},
{x:36.73311789,y:-108.24306406},
{x:28.1943319,y:-82.39317897},
{x:39.19734523,y:-76.81958132},
{x:39.37938433,y:-104.8633448},
{x:27.91330604,y:-82.34731892},
{x:26.57286694,y:-80.0668265},
{x:29.99428685,y:-90.24101558},
{x:42.25093138,y:-85.53137238},
{x:36.21733976,y:-115.09480218},
{x:42.05049558,y:-87.74647045},
{x:30.44197802,y:-84.33742628},
{x:35.29827954,y:-79.72656714},
{x:43.14774571,y:-78.69622781},
{x:39.66244099,y:-105.3530784},
{x:33.55605552,y:-117.17859194},
{x:38.91539021,y:-84.63296271},
{x:28.03567661,y:-80.61950505},
{x:42.2825521,y:-71.6472662},
{x:30.48407379,y:-81.63605518},
{x:40.5440883,y:-74.1633741},
{x:30.14028313,y:-91.94693573},
{x:30.18078702,y:-81.70125738},
{x:36.64411482,y:-93.28591206},
{x:32.5881102,y:-82.3266704},
{x:37.82514569,y:-92.15562701},
{x:42.99485502,y:-85.59226386},
{x:41.92563696,y:-83.36625545},
{x:31.32616373,y:-89.35610582},
{x:33.78866747,y:-84.62329878},
{x:43.08759564,y:-77.62909144},
{x:41.49631883,y:-90.54798183},
{x:43.19757986,y:-112.35796362},
{x:33.03951521,y:-83.9380721},
{x:42.04686866,y:-88.12568843},
{x:35.7024202,y:-82.5683656},
{x:32.43465773,y:-81.78172901},
{x:33.46609868,y:-81.96168512},
{x:31.7848251,y:-106.5064565},
{x:40.02342033,y:-86.90359242},
{x:34.04082055,y:-84.31839198},
{x:42.23209814,y:-85.59011678},
{x:32.39797227,y:-93.74849841},
{x:34.51055146,y:-83.54545847},
{x:33.3807142,y:-84.3002898},
{x:37.81790993,y:-96.87159887},
{x:42.6313193,y:-71.2841554},
{x:41.40234584,y:-82.59077675},
{x:39.91096861,y:-82.01634452},
{x:41.31185885,y:-81.45450555},
{x:41.98035899,y:-87.80733801},
{x:42.17923899,y:-72.57774621},
{x:42.50920537,y:-82.9694768},
{x:41.31229343,y:-73.05466578},
{x:42.98328666,y:-82.53763586},
{x:33.4804024,y:-84.436873},
{x:35.67100532,y:-80.85497618},
{x:33.04767518,y:-84.17066932},
{x:39.02675288,y:-104.82343868},
{x:34.25952531,y:-84.46253277},
{x:26.15026402,y:-80.20328522},
{x:41.79714787,y:-69.98387598},
{x:40.62478051,y:-103.2306199},
{x:30.31352286,y:-81.68152938},
{x:38.24972617,y:-85.61296821},
{x:39.43516757,y:-74.61224757},
{x:26.20917906,y:-80.22158861},
{x:42.9735539,y:-83.6847771},
{x:39.85799973,y:-104.97727811},
{x:39.2528288,y:-84.2966359},
{x:40.73037977,y:-84.06810029},
{x:38.67911322,y:-90.25899504},
{x:41.7922115,y:-85.4289468},
{x:42.54791956,y:-70.94292281},
{x:40.58819693,y:-74.16690409},
{x:38.79394708,y:-90.44866661},
{x:42.77864932,y:-83.23980331},
{x:41.72063311,y:-88.32004935},
{x:29.07573619,y:-82.26007216},
{x:39.98885471,y:-85.92014765},
{x:42.576301,y:-114.459944},
{x:36.0690107,y:-79.87027422},
{x:30.43561069,y:-88.5271883},
{x:42.29441184,y:-71.77652326},
{x:40.06527491,y:-83.08848073},
{x:40.43750919,y:-84.97857596},
{x:38.94319403,y:-94.81530011},
{x:39.830145,y:-84.8783146},
{x:26.72325988,y:-80.08052013},
{x:28.38771227,y:-81.24497473},
{x:37.72154993,y:-97.26159675},
{x:42.36150496,y:-88.26820359},
{x:39.9570525,y:-74.3900451},
{x:33.46834513,y:-82.07176298},
{x:42.22740659,y:-83.64358134},
{x:40.95875466,y:-90.38360685},
{x:41.71895842,y:-87.81640694},
{x:41.8157585,y:-87.83428016},
{x:39.8679526,y:-82.9358954},
{x:41.31522151,y:-82.99303742},
{x:42.74219804,y:-73.76139656},
{x:40.88630828,y:-74.70875214},
{x:39.1094776,y:-94.7599296},
{x:41.1650624,y:-73.2195748},
{x:39.64031832,y:-85.13777889},
{x:43.51043676,y:-70.43416232},
{x:38.93869391,y:-104.75786672},
{x:37.00401353,y:-93.22648019},
{x:35.25121459,y:-80.65705866},
{x:44.9553908,y:-93.1755031},
{x:30.41954028,y:-86.6090937},
{x:38.30696062,y:-86.95498735},
{x:34.6632974,y:-79.00073156},
{x:41.7442073,y:-84.99975756},
{x:34.96360839,y:-89.84832242},
{x:39.12931745,y:-82.98156023},
{x:32.07390989,y:-84.21784401},
{x:40.75458813,y:-73.64950329},
{x:41.54797283,y:-90.49919263},
{x:42.01223242,y:-87.7214093},
{x:43.59360578,y:-116.52090088},
{x:40.7356129,y:-74.1712684},
{x:26.60745493,y:-81.87160514},
{x:27.60245679,y:-80.8232681},
{x:42.43854589,y:-71.43485963},
{x:39.89164617,y:-84.19269696},
{x:38.6007116,y:-86.10016283},
{x:26.71395995,y:-81.81486368},
{x:42.1158908,y:-88.03353141},
{x:41.2806338,y:-72.9847997},
{x:36.18080256,y:-115.17845492},
{x:41.6984074,y:-72.9022707},
{x:42.42225072,y:-71.0060361},
{x:41.97613833,y:-88.20762724},
{x:28.30759597,y:-81.66614173},
{x:27.75550759,y:-82.63804302},
{x:33.8803207,y:-83.9396369},
{x:37.18254957,y:-93.29352938},
{x:30.30593355,y:-81.84741999},
{x:39.07777375,y:-77.13948839},
{x:43.61708568,y:-116.35509678},
{x:35.25421425,y:-106.64738506},
{x:29.78717056,y:-82.03236021},
{x:36.03456582,y:-115.00147507},
{x:28.01782375,y:-82.13898262},
{x:30.51720428,y:-89.68018949},
{x:39.63720971,y:-84.22395676},
{x:37.12195218,y:-82.84236699},
{x:40.55443976,y:-74.43408824},
{x:41.09617685,y:-74.00774449},
{x:35.85979499,y:-78.70870091},
{x:35.72281093,y:-81.3614805},
{x:33.24798698,y:-84.28712279},
{x:39.12915412,y:-119.77118365},
{x:34.31030041,y:-83.81536506},
{x:39.086179,y:-84.519626},
{x:40.17186333,y:-85.38196161},
{x:35.09062764,y:-80.86956369},
{x:39.1126503,y:-108.5373353},
{x:28.4824026,y:-81.454777},
{x:31.87389051,y:-90.40378377},
{x:40.67286786,y:-74.21326943},
{x:32.29064657,y:-90.2273909},
{x:35.1365738,y:-80.9066519},
{x:41.62552404,y:-72.87377894},
{x:40.1471532,y:-82.9213328},
{x:43.00118499,y:-82.44034141},
{x:38.7808122,y:-83.56545188},
{x:39.72488986,y:-105.0527756},
{x:35.73833756,y:-78.86412799},
{x:41.21846451,y:-96.04258037},
{x:41.69234467,y:-88.12476955},
{x:33.1592571,y:-84.8726314},
{x:39.9435409,y:-82.0008235},
{x:43.24023108,y:-77.64740154},
{x:25.50006766,y:-80.41007109},
{x:32.838304,y:-116.98694542},
{x:31.13771347,y:-83.4433347},
{x:39.62944322,y:-106.07051692},
{x:33.81500658,y:-84.31032119},
{x:32.921434,y:-84.33439},
{x:38.94803415,y:-104.80382033},
{x:40.05802122,y:-85.65201983},
{x:39.72782864,y:-84.05515864},
{x:42.9088875,y:-78.7427326},
{x:41.47034356,y:-87.34196037},
{x:41.5209671,y:-81.53476886},
{x:37.04829657,y:-80.76779308},
{x:40.84327431,y:-81.25972094},
{x:40.08596484,y:-82.96192563},
{x:41.75511326,y:-72.71448925},
{x:36.72216825,y:-84.16793615},
{x:27.63963089,y:-80.45471281},
{x:32.1934619,y:-81.19482704},
{x:33.53881476,y:-85.25221981},
{x:46.35723016,y:-94.21823935},
{x:41.87930915,y:-71.06574073},
{x:34.33863463,y:-78.70757201},
{x:41.90048,y:-87.63668},
{x:34.8739719,y:-84.31539492},
{x:43.0663879,y:-86.22116128},
{x:38.13871046,y:-85.68094141},
{x:39.8633981,y:-75.07851452},
{x:40.8328923,y:-73.3630056},
{x:45.78436532,y:-108.54280964},
{x:41.84750111,y:-89.48659704},
{x:34.39049685,y:-83.2270655},
{x:30.17291147,y:-81.60224195},
{x:39.94573692,y:-82.99783923},
{x:42.22215954,y:-83.48599255},
{x:40.73720578,y:-73.32107104},
{x:38.78973147,y:-90.56519508},
{x:41.09659103,y:-73.95559359},
{x:39.7143321,y:-105.1338762},
{x:41.0994008,y:-85.06060481},
{x:41.4643419,y:-81.9505556},
{x:40.57957305,y:-74.50200991},
{x:39.2134302,y:-83.6082208},
{x:42.32241101,y:-83.45008783},
{x:36.23904669,y:-115.15934855},
{x:39.23289408,y:-81.54297888},
{x:41.76001528,y:-87.94565973},
{x:27.10733838,y:-82.38376901},
{x:41.11209089,y:-83.1993261},
{x:41.53926813,y:-83.63420204},
{x:42.97252089,y:-85.90334356},
{x:44.8614372,y:-93.5387193},
{x:30.15756907,y:-92.05038148},
{x:37.68007687,y:-97.335843},
{x:38.8025859,y:-76.9088709},
{x:39.10132503,y:-84.2750743},
{x:21.3307363,y:-158.085738},
{x:37.75778033,y:-77.46568673},
{x:37.78910581,y:-89.02850939},
{x:40.53534842,y:-81.91790844},
{x:32.4388813,y:-81.7639067},
{x:39.53580809,y:-104.79286283},
{x:42.06790652,y:-88.04129121},
{x:30.1004142,y:-81.5217666},
{x:38.91103747,y:-94.76781853},
{x:42.91261458,y:-85.70601001},
{x:27.31067579,y:-80.40765747},
{x:25.99284394,y:-80.29307887},
{x:42.9577868,y:-78.87888819},
{x:36.91891683,y:-84.12764535},
{x:45.071594,y:-83.4449819},
{x:38.92312878,y:-76.86906204},
{x:40.75411769,y:-73.89686986},
{x:30.86025402,y:-93.28762785},
{x:38.56478037,y:-86.46491156},
{x:32.60759243,y:-90.0663498},
{x:43.05971833,y:-83.81175614},
{x:39.15655564,y:-84.61075075},
{x:37.77688634,y:-84.31830781},
{x:43.20836838,y:-77.45924592},
{x:27.06221716,y:-80.13855107},
{x:32.29676664,y:-106.77025403},
{x:24.92222644,y:-80.62991088},
{x:37.98205479,y:-122.06679784},
{x:32.81085686,y:-96.7291173},
{x:42.27316382,y:-84.45237353},
{x:30.39132942,y:-88.65277067},
{x:42.55196787,y:-71.572733},
{x:33.90298306,y:-116.54618052},
{x:34.98473613,y:-78.96661989},
{x:38.06894524,y:-84.52984661},
{x:41.70045423,y:-71.14573829},
{x:28.48005326,y:-81.33116752},
{x:40.10849262,y:-83.18980694},
{x:39.92307309,y:-83.77303988},
{x:42.74152237,y:-84.60379779},
{x:28.34381714,y:-81.38620268},
{x:42.46161327,y:-92.40365194},
{x:36.83569293,y:-87.47634441},
{x:39.28676294,y:-76.76405735},
{x:35.17506349,y:-106.56833097},
{x:39.80162497,y:-105.10537505},
{x:35.66306912,y:-80.46543454},
{x:38.86554868,y:-91.94438688},
{x:29.68849314,y:-82.44154572},
{x:35.69829111,y:-81.26153015},
{x:36.05878218,y:-79.10323008},
{x:30.8518233,y:-83.28035229},
{x:26.62862626,y:-80.07962734},
{x:41.86358838,y:-80.7958244},
{x:28.29573596,y:-80.72487764},
{x:42.0870672,y:-87.8714843},
{x:42.51249495,y:-92.42791146},
{x:40.43690166,y:-74.22162116},
{x:28.54404786,y:-82.40232646},
{x:40.03824071,y:-82.96258293},
{x:33.63808548,y:-112.33400613},
{x:40.68635045,y:-73.34521875},
{x:39.23727814,y:-84.45263214},
{x:33.6574338,y:-84.5014121},
{x:41.68301555,y:-83.60981504},
{x:30.36485,y:-90.065224},
{x:37.96430195,y:-84.39040325},
{x:42.66047113,y:-83.39845352},
{x:40.57681793,y:-74.39116359},
{x:33.03634408,y:-116.8780931},
{x:34.28409645,y:-83.12559485},
{x:39.67180429,y:-104.95970563},
{x:44.94191761,y:-92.93638855},
{x:21.38544588,y:-157.75147491},
{x:38.16918583,y:-92.61091858},
{x:21.37759977,y:-157.93130037},
{x:33.50946013,y:-82.50206843},
{x:42.59249643,y:-72.59859196},
{x:35.70155952,y:-79.78455484},
{x:26.4289299,y:-81.8102631},
{x:28.4759897,y:-81.6269694},
{x:36.72897613,y:-86.57879002},
{x:37.8230196,y:-85.46268001},
{x:42.77191395,y:-71.07615338},
{x:28.012072,y:-82.3929101},
{x:33.96475071,y:-117.6900506},
{x:26.06309442,y:-80.16770926},
{x:40.01436425,y:-82.99199764},
{x:42.50771405,y:-83.53613913},
{x:40.080745,y:-82.851692},
{x:39.53807463,y:-119.73463454},
{x:41.58871,y:-85.84684841},
{x:39.77412158,y:-104.80916731},
{x:35.88064446,y:-78.85088593},
{x:38.036577,y:-84.518339},
{x:26.01093876,y:-80.17950073},
{x:26.65095633,y:-81.97500564},
{x:38.41636508,y:-75.56666315},
{x:40.70457061,y:-73.64047363},
{x:28.52255773,y:-82.23214366},
{x:41.56111556,y:-73.05393622},
{x:30.06959883,y:-81.86645061},
{x:35.10712305,y:-106.55132286},
{x:41.10671298,y:-80.64801733},
{x:40.83047138,y:-96.60645917},
{x:41.81171738,y:-86.24757886},
{x:28.0199034,y:-82.75533937},
{x:42.14263486,y:-72.57488832},
{x:33.95121876,y:-84.51905608},
{x:34.02677377,y:-118.39195624},
{x:41.3142003,y:-72.9347023},
{x:38.2947009,y:-76.5024606},
{x:34.0688749,y:-84.16729107},
{x:39.8651383,y:-105.0536452},
{x:41.68216324,y:-87.64165699},
{x:40.36755086,y:-86.87177278},
{x:41.40016546,y:-82.11581826},
{x:30.42637374,y:-91.13791779},
{x:29.65044444,y:-81.64846212},
{x:41.07109137,y:-85.20118207},
{x:37.006948,y:-86.37362927},
{x:41.95824832,y:-83.66845385},
{x:42.25323788,y:-71.82798288},
{x:32.49051301,y:-82.93241845},
{x:39.86928395,y:-75.04262924},
{x:27.9609165,y:-82.742507},
{x:35.74371924,y:-81.30288765},
{x:39.72598696,y:-104.98448886},
{x:32.28988414,y:-90.26912982},
{x:27.9167045,y:-82.7355454},
{x:42.86174725,y:-73.78149748},
{x:39.78939188,y:-86.04594648},
{x:40.58052847,y:-83.09837446},
{x:34.31798288,y:-85.17282866},
{x:29.88961109,y:-90.03145695},
{x:40.832724,y:-115.785526},
{x:41.64802918,y:-83.52334567},
{x:25.64079848,y:-80.33501084},
{x:41.94497062,y:-83.40207361},
{x:42.5631706,y:-113.7910654},
{x:42.14154697,y:-72.73402445},
{x:35.33376699,y:-80.79368904},
{x:36.27157139,y:-76.97063863},
{x:38.2151469,y:-84.5398746},
{x:39.0426247,y:-94.4149405},
{x:40.4474825,y:-74.395728},
{x:39.76407585,y:-104.77332779},
{x:38.96494896,y:-92.37177149},
{x:32.27228837,y:-90.03699251},
{x:27.8932032,y:-82.32038598},
{x:42.1107978,y:-70.9301478},
{x:44.56520614,y:-69.63666299},
{x:33.48553505,y:-82.13244259},
{x:41.54999923,y:-87.17559926},
{x:37.14778928,y:-94.30971175},
{x:30.2643423,y:-81.55698906},
{x:26.28843576,y:-80.2510728},
{x:41.70526595,y:-93.58060151},
{x:35.87519485,y:-78.54039558},
{x:36.67170456,y:-80.91768749},
{x:31.82654868,y:-81.59895927},
{x:42.54606068,y:-83.14732723},
{x:40.66824475,y:-73.39487784},
{x:39.74029895,y:-104.97667127},
{x:35.81114012,y:-80.87608248},
{x:43.15472656,y:-76.2281626},
{x:39.66296253,y:-75.72434753},
{x:38.75826236,y:-82.9905244},
{x:40.47702,y:-86.10505},
{x:30.31334109,y:-81.59976761},
{x:42.84087114,y:-73.74419466},
{x:35.52134447,y:-78.29298913},
{x:25.92840818,y:-80.1998089},
{x:38.3054962,y:-104.61494915},
{x:39.49079647,y:-84.32954729},
{x:32.45882265,y:-93.66169708},
{x:43.05829215,y:-77.65297115},
{x:33.92366291,y:-83.34084378},
{x:33.56790689,y:-84.53865498},
{x:40.86620303,y:-82.31983289},
{x:42.2826187,y:-83.36425907},
{x:40.1474995,y:-85.67610472},
{x:35.33085453,y:-82.44805411},
{x:40.7774012,y:-82.4744258},
{x:29.58050182,y:-90.69281727},
{x:25.79231316,y:-80.36943868},
{x:41.89191526,y:-88.10631752},
{x:42.01639891,y:-93.60970654},
{x:42.13165874,y:-85.63665479},
{x:34.71135107,y:-85.28096064},
{x:32.76807991,y:-96.79571059},
{x:38.8577066,y:-104.7195299},
{x:39.95513373,y:-82.87644438},
{x:42.63063167,y:-82.87764877},
{x:35.3070622,y:-82.4576188},
{x:41.1234976,y:-100.7642949},
{x:41.25989973,y:-95.97722329},
{x:39.64295898,y:-86.33108273},
{x:39.25852166,y:-84.34172183},
{x:37.07203096,y:-88.12613032},
{x:43.04662642,y:-83.31963122},
{x:41.65918761,y:-85.97052891},
{x:33.90064589,y:-84.47663946},
{x:40.95832828,y:-74.5976159},
{x:38.83741513,y:-104.81825598},
{x:39.2055404,y:-76.67977948},
{x:41.97083701,y:-73.98805842},
{x:36.69221622,y:-87.45185749},
{x:39.67138846,y:-75.59782371},
{x:38.40556649,y:-82.60119103},
{x:36.87890615,y:-87.7363953},
{x:42.84444167,y:-85.62410493},
{x:38.99844269,y:-94.77925658},
{x:43.48213814,y:-83.40486377},
{x:29.9159958,y:-90.1186637},
{x:40.40514188,y:-91.39255145},
{x:42.11357383,y:-79.21162392},
{x:32.33968557,y:-89.48262065},
{x:39.10633497,y:-84.53354537},
{x:33.51765712,y:-88.43559489},
{x:41.91067545,y:-71.36247017},
{x:34.43896022,y:-77.88048685},
{x:45.19906191,y:-93.55444193},
{x:45.7465063,y:-87.07931646},
{x:34.02841985,y:-84.04726356},
{x:42.55464992,y:-82.92426423},
{x:45.03616853,y:-93.01850806},
{x:41.55427842,y:-72.68468819},
{x:39.9828702,y:-82.8173293},
{x:42.28706412,y:-83.83778304},
{x:26.62037404,y:-80.14699773},
{x:40.72754537,y:-73.22283894},
{x:32.41712746,y:-104.22868967},
{x:38.63189407,y:-83.79641935},
{x:29.9636563,y:-90.16410737},
{x:41.50837744,y:-87.91869164},
{x:30.28855525,y:-81.43566872},
{x:32.22142531,y:-110.84355354},
{x:36.03047932,y:-79.80101556},
{x:29.94043807,y:-90.07491772},
{x:33.21054979,y:-84.05664108},
{x:40.78584009,y:-96.65441303},
{x:41.02417797,y:-80.72669231},
{x:32.53277981,y:-84.96703796},
{x:27.41270721,y:-80.39679855},
{x:37.72652682,y:-87.12258808},
{x:41.9092483,y:-87.74878405},
{x:38.79112064,y:-90.34453225},
{x:39.16110473,y:-86.53576031},
{x:42.26974855,y:-83.2710221},
{x:35.0778898,y:-106.59003407},
{x:35.2203078,y:-80.9476646},
{x:40.0284912,y:-82.452088},
{x:42.45776957,y:-83.93118024},
{x:44.82487219,y:-93.16794567},
{x:30.67884281,y:-83.22569557},
{x:39.39430389,y:-76.52324885},
{x:30.24213013,y:-92.06667423},
{x:32.350987,y:-81.297997},
{x:25.59071933,y:-80.35882209},
{x:39.1857146,y:-76.6133339},
{x:39.60454849,y:-87.67928436},
{x:28.68735354,y:-81.53987154},
{x:42.1842763,y:-71.8586778},
{x:40.6458128,y:-83.60968361},
{x:28.05572299,y:-82.39414818},
{x:40.65931252,y:-74.26700011},
{x:35.00525315,y:-78.31207305},
{x:35.37174523,y:-77.95124848},
{x:41.79960134,y:-72.55043998},
{x:41.90299413,y:-87.66835638},
{x:28.23687915,y:-82.3523482},
{x:40.40880292,y:-105.00280201},
{x:42.89151,y:-70.871639},
{x:41.98535999,y:-88.03044319},
{x:42.50359221,y:-83.20383421},
{x:25.92539895,y:-80.16891271},
{x:44.9784166,y:-93.0907282},
{x:26.00715055,y:-80.31775251},
{x:40.73215319,y:-74.19943392},
{x:42.48523418,y:-71.21770531},
{x:40.72585826,y:-73.50206226},
{x:39.38136352,y:-74.55553563},
{x:39.24891566,y:-76.67135775},
{x:42.10172505,y:-75.83575174},
{x:29.99567367,y:-82.598407},
{x:33.4090721,y:-84.6881311},
{x:40.06049172,y:-82.96321795},
{x:34.0438304,y:-117.8017989},
{x:39.0953777,y:-84.6117548},
{x:40.7555274,y:-73.9800893},
{x:33.06646981,y:-89.57877449},
{x:42.08518334,y:-86.48593739},
{x:37.32751452,y:-87.48234496},
{x:41.52033652,y:-88.1241408},
{x:25.984561,y:-80.16411692},
{x:40.72675674,y:-73.99546822},
{x:34.1598328,y:-84.23706695},
{x:28.5376176,y:-81.2760481},
{x:38.9313578,y:-84.5455278},
{x:40.16064543,y:-122.2262907},
{x:42.49739769,y:-83.28578442},
{x:39.22788466,y:-85.89684115},
{x:46.9036253,y:-102.7905214},
{x:37.97618319,y:-87.39383832},
{x:30.21783731,y:-85.65013054},
{x:38.34819329,y:-75.60179344},
{x:35.2298699,y:-80.92369571},
{x:38.78679091,y:-89.97777008},
{x:39.9259635,y:-83.8162716},
{x:28.2376903,y:-81.65370029},
{x:39.93288375,y:-75.06701149},
{x:42.96114637,y:-78.75773147},
{x:24.71574571,y:-81.0791736},
{x:40.76971829,y:-73.52650657},
{x:41.0494331,y:-86.21929765},
{x:35.36502943,y:-80.70918337},
{x:39.45971924,y:-76.63397916},
{x:34.5192107,y:-117.3159673},
{x:30.23819317,y:-90.9127517},
{x:42.12365791,y:-72.62636662},
{x:28.35656235,y:-82.66572878},
{x:36.07121775,y:-115.07601231},
{x:41.27305924,y:-80.7795471},
{x:42.0541762,y:-87.8334455},
{x:33.83457642,y:-84.57687904},
{x:39.0178202,y:-84.63473787},
{x:41.9058827,y:-87.9842636},
{x:36.88461408,y:-89.55851778},
{x:41.76573827,y:-87.68297026},
{x:41.11368229,y:-74.58014332},
{x:26.10673983,y:-80.17974615},
{x:43.58632647,y:-84.76673663},
{x:32.51453649,y:-92.1570915},
{x:28.14591782,y:-82.74116305},
{x:40.03545708,y:-83.01648595},
{x:36.1455955,y:-78.7237167},
{x:39.19765538,y:-75.55379794},
{x:39.98086121,y:-83.15131255},
{x:40.7354088,y:-73.9926988},
{x:41.45182284,y:-91.02920249},
{x:41.90772499,y:-83.40553479},
{x:28.63524334,y:-81.40427346},
{x:39.63849448,y:-84.26443666},
{x:41.3972531,y:-89.46939625},
{x:28.72279118,y:-82.55268999},
{x:27.49139371,y:-82.47880697},
{x:39.51854458,y:-119.78965402},
{x:32.81489612,y:-83.6638847},
{x:38.93169325,y:-79.86685172},
{x:40.37084958,y:-80.68580622},
{x:39.629213,y:-85.445001},
{x:41.94716113,y:-87.68857918},
{x:33.56873379,y:-84.41335693},
{x:37.89827896,y:-88.93133738},
{x:41.67938666,y:-70.94144212},
{x:40.58004682,y:-74.60724451},
{x:33.57074465,y:-117.70710884},
{x:25.80832835,y:-80.1891607},
{x:40.05338291,y:-82.86446563},
{x:38.90720715,y:-104.78108124},
{x:25.57198926,y:-80.37541598},
{x:41.28661546,y:-72.87868897},
{x:42.3540804,y:-71.05901254},
{x:39.10154151,y:-94.42701817},
{x:42.44829399,y:-83.04519496},
{x:40.77463514,y:-73.87201125},
{x:43.592902,y:-70.330424},
{x:28.3638454,y:-81.3104057},
{x:38.24344253,y:-104.64843549},
{x:28.2402495,y:-82.1849403},
{x:40.69892427,y:-74.23737876},
{x:42.61338024,y:-82.95245894},
{x:36.07370331,y:-79.95979562},
{x:37.00823792,y:-85.92085219},
{x:39.79123664,y:-84.24295735},
{x:25.92370005,y:-80.29260508},
{x:28.47889449,y:-82.48739138},
{x:33.74231279,y:-84.34965447},
{x:35.74319676,y:-78.82231772},
{x:35.94497749,y:-78.83856788},
{x:33.46424514,y:-84.21395212},
{x:36.01735338,y:-79.89505545},
{x:41.13676074,y:-81.79759294},
{x:42.37818288,y:-83.01445954},
{x:44.78967522,y:-93.21878042},
{x:40.66002157,y:-73.68299636},
{x:39.95688274,y:-82.9157509},
{x:42.5068087,y:-96.41712722},
{x:27.9379886,y:-82.2887376},
{x:36.76833012,y:-90.41492701},
{x:43.10807937,y:-78.99455425},
{x:33.95171297,y:-78.04339872},
{x:40.43731404,y:-74.30152108},
{x:37.48017741,y:-105.9054096},
{x:41.53718128,y:-87.46102932},
{x:41.11874603,y:-81.47886203},
{x:40.2026953,y:-100.64593032},
{x:40.27080601,y:-103.62081125},
{x:44.27385302,y:-85.40603846},
{x:41.57434236,y:-90.529834},
{x:39.18315144,y:-119.76807229},
{x:40.756912,y:-86.321567},
{x:27.80910455,y:-82.63812751},
{x:39.91447427,y:-104.99417871},
{x:36.81573182,y:-83.31454301},
{x:26.12149494,y:-80.19909158},
{x:37.60891103,y:-120.97206026},
{x:35.1256106,y:-77.086331},
{x:32.4887855,y:-92.0841378},
{x:40.064928,y:-83.0188711},
{x:39.01187611,y:-94.57395896},
{x:38.86017343,y:-86.52016103},
{x:25.86632426,y:-80.31685129},
{x:38.16893196,y:-85.60596563},
{x:40.3310181,y:-79.9451942},
{x:27.89412056,y:-82.24941909},
{x:33.88929481,y:-84.749844},
{x:43.61520489,y:-84.21593122},
{x:39.59456943,y:-104.8330507},
{x:38.17796443,y:-83.43750395},
{x:41.0732327,y:-74.14116644},
{x:29.28171066,y:-81.06613845},
{x:30.4811984,y:-84.1677223},
{x:43.69178548,y:-85.484308},
{x:41.6844655,y:-70.2091862},
{x:30.28282737,y:-97.73712324},
{x:33.91892447,y:-83.44296858},
{x:38.74700882,y:-90.42209387},
{x:29.52736277,y:-98.45940044},
{x:35.798096,y:-78.5773305},
{x:38.16827697,y:-84.90024894},
{x:40.78656346,y:-73.12471299},
{x:34.95561675,y:-85.25830507},
{x:42.15465097,y:-76.82189085},
{x:41.15361361,y:-80.66446937},
{x:35.51068498,y:-82.52588862},
{x:40.48904443,y:-86.1655435},
{x:41.97504803,y:-88.71690886},
{x:29.87455643,y:-90.09707339},
{x:28.3493595,y:-82.1879653},
{x:40.59250182,y:-74.29141514},
{x:36.02535788,y:-80.16202748},
{x:45.1296749,y:-93.2670709},
{x:38.52937479,y:-90.03557011},
{x:25.89405015,y:-80.29117815},
{x:26.25863033,y:-80.10584658},
{x:26.1886081,y:-80.13296825},
{x:39.03100885,y:-76.91360414},
{x:29.07067877,y:-82.07188367},
{x:39.93481552,y:-91.35858178},
{x:34.9631579,y:-90.0044253},
{x:29.44026659,y:-81.51167081},
{x:41.7403725,y:-72.1773068},
{x:36.16345709,y:-80.13813938},
{x:36.07447987,y:-80.38925015},
{x:30.245526,y:-89.761941},
{x:27.89379342,y:-81.55546993},
{x:29.00648778,y:-80.918292},
{x:39.44786317,y:-83.81062299},
{x:35.6892061,y:-78.5803679},
{x:32.72751885,y:-89.54780102},
{x:42.41584355,y:-82.93718376},
{x:39.29277891,y:-84.29709524},
{x:30.41915632,y:-86.65255085},
{x:42.52919162,y:-85.86757958},
{x:40.21748553,y:-92.58341166},
{x:40.3091418,y:-74.66587141},
{x:28.61720024,y:-80.84072522},
{x:30.35251866,y:-81.56981438},
{x:40.07932232,y:-74.08594295},
{x:43.03330666,y:-71.07167226},
{x:40.03557726,y:-86.4713049},
{x:40.52717956,y:-81.4898108},
{x:40.75482942,y:-73.94133003},
{x:38.78879781,y:-84.60871563},
{x:34.77453053,y:-84.92088093},
{x:41.81062081,y:-87.96767128},
{x:39.82424147,y:-85.91441057},
{x:34.0982115,y:-118.3453282},
{x:40.82757405,y:-73.19997933},
{x:40.57540418,y:-85.67499511},
{x:33.99900661,y:-84.09049407},
{x:38.42376847,y:-121.37317926},
{x:25.95497635,y:-80.20608587},
{x:33.98544462,y:-84.35011044},
{x:39.70703129,y:-104.98797141},
{x:40.07350687,y:-80.87305963},
{x:28.45058248,y:-81.3617219},
{x:39.87291011,y:-84.0556401},
{x:39.75364151,y:-94.80314568},
{x:28.13816969,y:-80.59479955},
{x:40.85779887,y:-82.2673206},
{x:34.12102449,y:-84.74524476},
{x:41.92945618,y:-87.94016062},
{x:37.69378356,y:-97.38989621},
{x:39.61873351,y:-83.60426244},
{x:41.4560575,y:-74.38412815},
{x:38.19979281,y:-85.70760861},
{x:30.72463876,y:-85.18492436},
{x:32.30374955,y:-90.17078161},
{x:39.75188768,y:-86.18660688},
{x:41.86346755,y:-87.88550391},
{x:40.51378165,y:-107.56192581},
{x:40.98363839,y:-74.13666688},
{x:41.5676621,y:-87.50857919},
{x:34.10370047,y:-83.97408426},
{x:41.2823256,y:-84.3318379},
{x:39.72304363,y:-86.21863681},
{x:40.80148398,y:-74.17013615},
{x:39.2118252,y:-76.93812065},
{x:39.35786159,y:-84.25764129},
{x:42.8814473,y:-78.69626128},
{x:40.84816642,y:-74.07678169},
{x:38.4628287,y:-90.5244218},
{x:39.31507169,y:-82.10449398},
{x:45.20639067,y:-93.3570362},
{x:37.67618601,y:-82.77823505},
{x:41.5643076,y:-72.9070713},
{x:41.3981578,y:-73.0788429},
{x:39.25920493,y:-84.60156888},
{x:35.50050895,y:-82.98811421},
{x:30.03389768,y:-89.97314841},
{x:40.39501369,y:-86.88203126},
{x:32.13920467,y:-81.23267396},
{x:39.91128853,y:-86.26051091},
{x:38.18457895,y:-86.98705986},
{x:34.1037696,y:-82.8518515},
{x:27.46864254,y:-80.33370227},
{x:42.22424793,y:-83.18184432},
{x:30.2509709,y:-89.79240105},
{x:41.96584737,y:-87.99612857},
{x:30.35003478,y:-87.26885741},
{x:38.85301071,y:-104.87135299},
{x:34.67214575,y:-88.56176846},
{x:28.66815975,y:-82.13867833},
{x:41.29081664,y:-73.83236266},
{x:39.69524326,y:-75.71949944},
{x:32.97323925,y:-82.8082674},
{x:29.79665881,y:-82.1680791},
{x:39.19952465,y:-85.94519936},
{x:40.8226716,y:-74.09964502},
{x:37.93988233,y:-86.76052988},
{x:39.00650617,y:-94.84666049},
{x:41.4825401,y:-81.7067053},
{x:33.11939337,y:-89.08391661},
{x:32.35624938,y:-90.01506723},
{x:39.19353956,y:-84.23706607},
{x:38.96329471,y:-92.29080163},
{x:26.45321167,y:-80.14572691},
{x:38.86222003,y:-121.95078359},
{x:34.6026057,y:-83.51729192},
{x:43.35387154,y:-83.87217969},
{x:41.9086776,y:-87.8453981},
{x:40.57430479,y:-105.10152207},
{x:43.04789757,y:-85.65478921},
{x:38.93389762,y:-95.09532204},
{x:34.1902107,y:-84.51039255},
{x:28.6633916,y:-81.3715348},
{x:33.80770363,y:-84.04408917},
{x:34.89064575,y:-77.54833854},
{x:32.47715949,y:-93.78047436},
{x:30.42213541,y:-89.13749546},
{x:42.64908906,y:-85.295569},
{x:40.27585364,y:-81.86864376},
{x:38.0931858,y:-87.55419016},
{x:36.08241463,y:-115.14799608},
{x:26.80702586,y:-80.05784896},
{x:25.73431625,y:-80.16481826},
{x:40.80198676,y:-81.93401865},
{x:35.35522649,y:-81.09187384},
{x:39.26483272,y:-103.67070873},
{x:31.92443278,y:-81.33484215},
{x:39.31368133,y:-76.59798861},
{x:34.87766045,y:-83.39610033},
{x:27.82366308,y:-80.47471033},
{x:41.07680445,y:-85.13691165},
{x:33.9193674,y:-84.30092731},
{x:42.05960143,y:-71.86591975},
{x:42.76654553,y:-71.49459761},
{x:39.87712035,y:-88.95633707},
{x:40.68949376,y:-73.50913793},
{x:38.99324281,y:-94.70224701},
{x:32.7866672,y:-96.79089342},
{x:36.06384668,y:-79.47066598},
{x:36.43368521,y:-77.63756722},
{x:30.19241822,y:-81.74119375},
{x:40.26911645,y:-74.54127677},
{x:31.3576888,y:-92.41639097},
{x:39.6459191,y:-86.83843962},
{x:41.64297289,y:-91.50268503},
{x:36.05600393,y:-115.11875935},
{x:40.11042294,y:-82.92626849},
{x:44.8163398,y:-92.93202863},
{x:32.40857438,y:-88.70618754},
{x:38.19923212,y:-85.66262394},
{x:32.5577737,y:-83.8846027},
{x:41.25153013,y:-87.84957647},
{x:39.3225045,y:-84.6375829},
{x:27.84553188,y:-82.68718243},
{x:40.661171,y:-73.9310654},
{x:40.02568173,y:-84.22679991},
{x:31.236437,y:-89.826759},
{x:38.9974515,y:-76.90659821},
{x:38.21027539,y:-85.26286103},
{x:42.34333613,y:-85.15372388},
{x:40.08876225,y:-82.82239392},
{x:41.63921582,y:-87.44528024},
{x:39.0210821,y:-94.27173816},
{x:37.6196784,y:-88.9857711},
{x:40.79768504,y:-73.93994227},
{x:36.01873047,y:-115.11909227},
{x:39.14037525,y:-76.60147011},
{x:28.05423815,y:-81.98513098},
{x:30.10973985,y:-81.41723961},
{x:42.78272045,y:-86.07242122},
{x:40.81027085,y:-91.14209935},
{x:30.67432949,y:-81.90309889},
{x:32.89285931,y:-97.1009264},
{x:43.3932948,y:-84.66741201},
{x:26.21008388,y:-80.25304824},
{x:42.47314744,y:-96.42707571},
{x:45.02709453,y:-84.68470395},
{x:30.5621863,y:-84.2192017},
{x:30.32005579,y:-81.46915476},
{x:39.02688586,y:-76.68903745},
{x:26.3112793,y:-80.20169936},
{x:38.32272384,y:-122.30237521},
{x:38.67557533,y:-90.01426802},
{x:39.74464809,y:-74.99361976},
{x:43.59068183,y:-116.28966906},
{x:35.83747232,y:-78.61362576},
{x:28.33460511,y:-81.52996749},
{x:34.14311805,y:-83.95517059},
{x:28.55288368,y:-81.45090737},
{x:30.50553031,y:-84.25002784},
{x:42.46787173,y:-83.42449553},
{x:41.5039172,y:-87.47176588},
{x:41.92544621,y:-71.87650681},
{x:39.15325069,y:-76.72769018},
{x:40.74697451,y:-73.89264174},
{x:32.50784249,y:-92.1929352},
{x:41.1240097,y:-73.40043184},
{x:40.84681383,y:-115.74764416},
{x:41.31869086,y:-74.14641067},
{x:40.06417361,y:-82.35649019},
{x:27.1250153,y:-82.45413311},
{x:34.31056021,y:-89.93495643},
{x:40.79357008,y:-81.44348853},
{x:42.85884772,y:-84.88307707},
{x:40.50993925,y:-74.38641269},
{x:39.65202753,y:-86.13909155},
{x:33.97021085,y:-84.22053382},
{x:39.49459883,y:-119.7973761},
{x:39.28891566,y:-81.53068882},
{x:39.71324475,y:-89.63686645},
{x:42.95693093,y:-78.69756013},
{x:40.27862019,y:-86.49440244},
{x:34.20774517,y:-84.75955702},
{x:39.02660034,y:-84.44889956},
{x:40.69199223,y:-82.51206502},
{x:25.7432133,y:-80.41547209},
{x:42.05570251,y:-75.41374908},
{x:32.03091988,y:-80.96538931},
{x:41.16870141,y:-85.49260811},
{x:38.98846385,y:-84.6406508},
{x:33.72921391,y:-118.00636053},
{x:40.83064997,y:-74.14340124},
{x:36.59817446,y:-82.21561119},
{x:41.83139128,y:-87.74429122},
{x:40.15803797,y:-83.09476957},
{x:42.57724074,y:-83.12911354},
{x:35.7599714,y:-78.7828498},
{x:29.57243827,y:-98.0170702},
{x:31.96150043,y:-83.75840441},
{x:28.0236137,y:-82.58261919},
{x:39.01267119,y:-85.63675135},
{x:25.09646185,y:-80.43923646},
{x:38.65266252,y:-87.17269957},
{x:42.01998856,y:-97.42699752},
{x:40.19264525,y:-105.10285914},
{x:41.28828945,y:-72.66716167},
{x:42.53723108,y:-83.48066953},
{x:33.42796908,y:-84.17978245},
{x:40.42323398,y:-104.73218523},
{x:41.96852314,y:-87.68120952},
{x:36.05572643,y:-79.40045543},
{x:38.0185499,y:-84.90916461},
{x:42.84994879,y:-73.94349166},
{x:41.23679762,y:-73.02212119},
{x:39.39259173,y:-76.48841232},
{x:30.45067841,y:-88.84255975},
{x:40.42168969,y:-74.52715245},
{x:42.86769821,y:-71.34672828},
{x:27.24338642,y:-80.8249864},
{x:39.25832941,y:-84.80182193},
{x:32.41443859,y:-90.10685216},
{x:42.06746735,y:-72.67669157},
{x:26.8943488,y:-82.00832874},
{x:39.43585827,y:-76.31416177},
{x:40.66302714,y:-73.79244546},
{x:36.85536083,y:-84.838862},
{x:42.52451676,y:-82.89851904},
{x:39.35446777,y:-85.48494101},
{x:38.56644775,y:-82.82796009},
{x:41.01556726,y:-85.11751324},
{x:40.30139456,y:-74.30412859},
{x:35.10886518,y:-106.59046284},
{x:35.4704556,y:-80.2659256},
{x:35.79808822,y:-78.49461347},
{x:33.95652374,y:-84.13231776},
{x:41.60137382,y:-87.62194276},
{x:40.66999698,y:-80.58609679},
{x:25.88334532,y:-80.24437631},
{x:44.87343941,y:-93.32174604},
{x:41.6900233,y:-87.74018794},
{x:41.94550282,y:-85.6505726},
{x:38.73959369,y:-82.96606243},
{x:35.91533034,y:-81.5243268},
{x:34.74155552,y:-77.96804856},
{x:28.51952889,y:-81.37610525},
{x:25.76599761,y:-80.19768745},
{x:33.8152741,y:-88.56333375},
{x:39.42215002,y:-76.37140326},
{x:39.48226636,y:-75.00060412},
{x:37.72183008,y:-97.46333376},
{x:38.87453515,y:-99.31733966},
{x:40.6633123,y:-73.7189084},
{x:35.24413391,y:-82.72458851},
{x:46.85239798,y:-96.86128311},
{x:35.84578141,y:-81.47449136},
{x:41.93881781,y:-84.64506588},
{x:34.61871251,y:-89.95220018},
{x:46.86206063,y:-96.82969943},
{x:40.27755662,y:-85.80884833},
{x:39.53717744,y:-82.40640995},
{x:36.04251047,y:-79.77282017},
{x:40.89910857,y:-85.509688},
{x:28.45196324,y:-81.47705957},
{x:29.69430895,y:-91.1872948},
{x:32.46146498,y:-90.11197244},
{x:30.00001293,y:-90.12603432},
{x:33.80542478,y:-84.52746481},
{x:37.0842466,y:-94.53074421},
{x:30.07128519,y:-94.1291669},
{x:42.54234258,y:-83.78667086},
{x:37.59120057,y:-93.40117089},
{x:40.92206454,y:-73.04970638},
{x:42.68920394,y:-73.850387},
{x:40.64054819,y:-73.66005724},
{x:28.00311904,y:-82.3029694},
{x:35.32396135,y:-80.94607338},
{x:26.2719655,y:-81.77066892},
{x:39.50808338,y:-87.38556604},
{x:42.4828368,y:-83.47625077},
{x:28.28039313,y:-81.34671763},
{x:41.3578481,y:-74.28399594},
{x:39.32181458,y:-84.43015963},
{x:37.08436434,y:-97.0397687},
{x:39.81004736,y:-105.05261332},
{x:28.82347379,y:-81.64424434},
{x:38.34830787,y:-97.19437927},
{x:40.16639323,y:-87.63013136},
{x:41.25477669,y:-96.02331877},
{x:28.80029877,y:-82.57567897},
{x:39.90167073,y:-83.43167782},
{x:35.05360681,y:-78.97389337},
{x:40.39428844,y:-104.75553244},
{x:46.84313025,y:-114.02682268},
{x:40.80261429,y:-72.80889564},
{x:30.53329901,y:-87.25786239},
{x:41.61697429,y:-83.664053},
{x:36.349,y:-119.297},
{x:30.6481119,y:-91.14321783},
{x:30.3275294,y:-82.96561549},
{x:42.1983382,y:-83.261882},
{x:34.20061532,y:-78.05075189},
{x:34.81397508,y:-106.75720811},
{x:41.12073549,y:-87.8891205},
{x:40.19321484,y:-85.41755319},
{x:41.71165499,y:-83.68021227},
{x:44.13945633,y:-93.25328887},
{x:40.42748976,y:-84.37829107},
{x:42.549739,y:-71.7632212},
{x:40.54723654,y:-85.67442157},
{x:42.79496372,y:-77.79539242},
{x:42.0069522,y:-83.9655325},
{x:27.23636811,y:-82.50035584},
{x:25.84947263,y:-80.24151064},
{x:34.4696398,y:-84.9174206},
{x:28.4985085,y:-81.3107824},
{x:41.45330688,y:-81.70109444},
{x:43.0117944,y:-74.35997218},
{x:41.5379301,y:-90.59835957},
{x:39.18982803,y:-96.55900955},
{x:43.06905526,y:-76.09118503},
{x:35.83048778,y:-78.76800835},
{x:37.75564856,y:-87.06697025},
{x:37.70332062,y:-121.72143574},
{x:42.03869764,y:-88.03674035},
{x:31.55095325,y:-91.37172997},
{x:34.0577894,y:-84.2335189},
{x:34.96199789,y:-90.06001793},
{x:33.93062344,y:-84.34810683},
{x:41.2497341,y:-81.3640215},
{x:40.73480148,y:-96.6026625},
{x:33.1033134,y:-82.0232467},
{x:40.7231771,y:-73.57188955},
{x:33.75444966,y:-84.23603974},
{x:38.59007047,y:-89.98440657},
{x:39.33600724,y:-84.34622951},
{x:42.86627733,y:-85.66857554},
{x:39.2109601,y:-84.47401471},
{x:40.48265311,y:-89.01674487},
{x:39.5281486,y:-119.7001266},
{x:39.43908142,y:-84.33257398},
{x:37.09059143,y:-93.80065864},
{x:25.93988141,y:-80.24476424},
{x:26.24485494,y:-80.15992329},
{x:39.87867884,y:-83.05440366},
{x:30.61424043,y:-87.05290741},
{x:33.7588773,y:-90.71632415},
{x:36.218575,y:-115.2437637},
{x:30.38053335,y:-88.52416702},
{x:28.87188591,y:-82.08837658},
{x:42.2872193,y:-71.1065795},
{x:40.62528034,y:-74.1474393},
{x:41.43276595,y:-73.41367386},
{x:36.03292866,y:-80.38422503},
{x:41.35346342,y:-72.87103906},
{x:28.11717648,y:-81.6391173},
{x:35.05310991,y:-78.86616781},
{x:35.11948011,y:-80.69755264},
{x:39.30984211,y:-85.21713316},
{x:39.48557408,y:-75.04354656},
{x:30.7510611,y:-85.55026004},
{x:42.4614912,y:-83.1078209},
{x:41.5676397,y:-72.3266816},
{x:35.17233597,y:-80.65925673},
{x:42.2333414,y:-83.32698449},
{x:35.21415272,y:-80.83537459},
{x:33.52163588,y:-84.3640597},
{x:38.9526238,y:-94.5277088},
{x:27.432595,y:-82.424827},
{x:39.43287014,y:-84.19629917},
{x:38.9931911,y:-76.8786741},
{x:42.6553382,y:-73.6926116},
{x:41.59806231,y:-73.91169727},
{x:37.6871393,y:-121.04941953},
{x:41.06156051,y:-81.45436272},
{x:36.158628,y:-115.16493256},
{x:37.07220329,y:-94.47730824},
{x:40.05837723,y:-82.91389481},
{x:42.69673175,y:-73.68889312},
{x:39.16909969,y:-84.4969597},
{x:41.52094442,y:-74.07086839},
{x:35.44035935,y:-80.60671226},
{x:47.7004447,y:-116.7833417},
{x:41.61202926,y:-83.69722456},
{x:39.62296616,y:-104.98842269},
{x:41.41407057,y:-73.66867304},
{x:39.52545397,y:-87.11652958},
{x:39.97257387,y:-86.00280528},
{x:38.54277453,y:-89.98330258},
{x:30.17656845,y:-93.21359171},
{x:41.17883062,y:-85.13194621},
{x:30.75049289,y:-81.57199059},
{x:36.03529352,y:-114.97622341},
{x:41.58651683,y:-83.55818957},
{x:26.4388074,y:-80.1208387},
{x:33.8429259,y:-84.328005},
{x:34.56596169,y:-83.3009381},
{x:39.8548612,y:-86.39267363},
{x:39.69695519,y:-104.81053054},
{x:28.3996162,y:-81.487503},
{x:38.86681585,y:-90.06922245},
{x:42.1472656,y:-83.2270655},
{x:40.83824994,y:-74.82293755},
{x:40.88061117,y:-96.68247566},
{x:30.50056147,y:-90.48621744},
{x:36.85288414,y:-83.86430204},
{x:39.95094394,y:-83.38009089},
{x:31.2228408,y:-90.4784984},
{x:43.40545711,y:-85.80130128},
{x:39.51647959,y:-84.75445747},
{x:36.085515,y:-115.149938},
{x:41.66163218,y:-87.66044133},
{x:43.45973149,y:-71.55791134},
{x:39.47172564,y:-104.8744183},
{x:28.07929423,y:-80.66408604},
{x:39.41895758,y:-77.45111614},
{x:42.15732737,y:-75.89643002},
{x:35.79074283,y:-80.8546298},
{x:42.51864157,y:-70.89294264},
{x:35.20292496,y:-106.6480083},
{x:35.15521523,y:-79.41530988},
{x:41.65533682,y:-74.68201327},
{x:39.33660225,y:-82.9752911},
{x:39.57500907,y:-76.9830291},
{x:37.54814255,y:-84.64664859},
{x:34.98657875,y:-80.36452509},
{x:38.72878185,y:-82.85885721},
{x:38.09237353,y:-85.66976197},
{x:33.3984507,y:-84.7394104},
{x:34.11054865,y:-83.87528016},
{x:39.09358567,y:-94.53732401},
{x:42.62796551,y:-71.32186436},
{x:38.36260101,y:-121.97089076},
{x:32.88683335,y:-105.95986784},
{x:28.0907386,y:-80.565934},
{x:38.58405569,y:-92.25338683},
{x:42.68907196,y:-71.14873633},
{x:38.0191626,y:-84.4160501},
{x:34.00937629,y:-84.75514814},
{x:38.26031418,y:-76.46698347},
{x:45.69700035,y:-111.06112846},
{x:32.18212453,y:-91.72745392},
{x:30.46302037,y:-91.1023356},
{x:40.89377937,y:-74.11579996},
{x:26.3488088,y:-80.2278129},
{x:39.83923285,y:-86.02215663},
{x:38.00952959,y:-87.57386513},
{x:30.0954352,y:-90.98421112},
{x:39.75340746,y:-89.70827222},
{x:38.85209207,y:-76.91305564},
{x:38.20324958,y:-84.83347535},
{x:27.976709,y:-82.53774},
{x:38.97793797,y:-89.10134322},
{x:39.56316913,y:-84.2635797},
{x:39.44097187,y:-119.77020766},
{x:39.93955902,y:-86.23545997},
{x:38.80450124,y:-104.75814835},
{x:39.93059617,y:-82.78985336},
{x:35.73424546,y:-79.42698135},
{x:32.38128752,y:-83.34804789},
{x:39.72065158,y:-82.20502183},
{x:39.71290225,y:-86.12122022},
{x:31.9826064,y:-81.1307614},
{x:38.927141,y:-76.5661659},
{x:40.4787616,y:-104.9299207},
{x:41.35883594,y:-72.11630434},
{x:39.70286143,y:-91.03798219},
{x:41.09449959,y:-73.81493113},
{x:33.47522285,y:-81.99664503},
{x:41.92375837,y:-87.6686678},
{x:40.6534716,y:-74.3222221},
{x:39.78462338,y:-104.77139042},
{x:35.27921254,y:-81.54201865},
{x:37.89549225,y:-84.56603967},
{x:41.70731798,y:-85.97170991},
{x:40.54878782,y:-84.56054246},
{x:36.13891663,y:-80.23756251},
{x:42.01242009,y:-71.05107106},
{x:33.73407637,y:-84.66965084},
{x:35.07961501,y:-106.53179183},
{x:28.91094033,y:-81.26685998},
{x:34.881539,y:-85.270597},
{x:39.78779553,y:-91.51638343},
{x:41.55801674,y:-87.79434846},
{x:34.201396,y:-118.5539123},
{x:38.92538033,y:-94.4000794},
{x:42.23094035,y:-83.26863974},
{x:37.90112118,y:-87.10864842},
{x:38.21845211,y:-85.5396133},
{x:40.72146548,y:-82.54397929},
{x:42.72007948,y:-83.37824234},
{x:39.9693585,y:-75.04500282},
{x:29.51628801,y:-98.49212735},
{x:29.82936,y:-81.37718},
{x:38.46947159,y:-121.82071523},
{x:40.770672,y:-82.59105474},
{x:30.38750701,y:-86.4410399},
{x:38.84012797,y:-104.77519043},
{x:40.57705214,y:-105.05832655},
{x:42.23016579,y:-83.68065387},
{x:41.95375355,y:-87.86013432},
{x:27.8790708,y:-82.8327393},
{x:42.02472876,y:-87.89382086},
{x:27.0904503,y:-82.15576024},
{x:35.79851353,y:-78.72683242},
{x:41.68000476,y:-86.18579283},
{x:30.78084276,y:-85.24112418},
{x:35.08241251,y:-80.65986189},
{x:38.47092407,y:-82.63393659},
{x:42.72940785,y:-73.84691857},
{x:26.328689,y:-81.80639304},
{x:39.27415166,y:-94.67202417},
{x:42.59734483,y:-73.70899804},
{x:34.23907141,y:-88.40014949},
{x:39.11673046,y:-77.25124329},
{x:39.6139475,y:-119.2663524},
{x:30.44545949,y:-87.23665264},
{x:41.60884907,y:-81.43922232},
{x:37.13376525,y:-104.5198448},
{x:41.05249348,y:-85.15628189},
{x:42.85615653,y:-77.00524583},
{x:33.93096616,y:-84.17840883},
{x:33.94228827,y:-84.12492712},
{x:42.46991661,y:-89.05742329},
{x:32.7783943,y:-91.88791573},
{x:41.54706131,y:-93.62536357},
{x:40.1464689,y:-105.1314476},
{x:37.6299885,y:-97.37139165},
{x:40.32890219,y:-74.07419354},
{x:35.72658,y:-81.2073},
{x:41.56548826,y:-73.00786775},
{x:44.83459612,y:-68.74519944},
{x:41.37565731,y:-82.08221018},
{x:40.79553759,y:-81.6925424},
{x:29.91063148,y:-90.20499843},
{x:28.013975,y:-82.469934},
{x:40.244218,y:-74.280129},
{x:37.8397165,y:-87.5876182},
{x:34.25306404,y:-88.71556637},
{x:34.153078,y:-84.8215366},
{x:40.50908736,y:-81.45206407},
{x:39.65268114,y:-104.76974621},
{x:43.61132927,y:-84.76451039},
{x:42.36866315,y:-83.38257193},
{x:38.58160529,y:-92.20615259},
{x:40.95894506,y:-73.84222001},
{x:28.37486393,y:-80.80195347},
{x:40.71005836,y:-74.00804356},
{x:37.74025814,y:-88.94851238},
{x:28.25080875,y:-81.3136004},
{x:33.51224211,y:-82.08745646},
{x:26.62249515,y:-81.94034882},
{x:38.76762614,y:-76.88747742},
{x:39.88451338,y:-104.96794202},
{x:39.33849626,y:-82.94975513},
{x:28.03484494,y:-82.6533001},
{x:35.049832,y:-80.642834},
{x:33.82497503,y:-117.5145118},
{x:45.03392811,y:-93.40533188},
{x:41.95199166,y:-87.80697055},
{x:26.43338,y:-81.4344485},
{x:28.3397326,y:-81.5952699},
{x:44.73099329,y:-93.14023472},
{x:26.39484412,y:-80.10396682},
{x:32.41987035,y:-84.94486348},
{x:34.92462766,y:-79.75493252},
{x:38.28746829,y:-104.59741563},
{x:34.75030864,y:-89.45425055},
{x:26.75386171,y:-80.94462633},
{x:27.33983899,y:-82.49779567},
{x:42.24928051,y:-83.65451422},
{x:38.43920535,y:-82.38511205},
{x:43.6276035,y:-72.3238756},
{x:33.9503098,y:-84.55098951},
{x:21.3350598,y:-158.054621},
{x:38.44374523,y:-105.23217425},
{x:39.28726062,y:-86.77335985},
{x:41.63159276,y:-86.25135273},
{x:42.68258525,y:-84.64617021},
{x:41.4610522,y:-81.855664},
{x:34.1646888,y:-118.405487},
{x:35.21219855,y:-78.07135027},
{x:35.98561904,y:-78.53525966},
{x:40.1420723,y:-74.2258914},
{x:41.72760051,y:-88.02642879},
{x:38.12832629,y:-85.84990382},
{x:41.45118752,y:-81.63094276},
{x:34.35499338,y:-82.90855452},
{x:42.74238724,y:-71.16101008},
{x:41.5459835,y:-83.58367994},
{x:38.9979226,y:-77.0945138},
{x:30.87627573,y:-83.29139024},
{x:25.7627076,y:-80.3042227},
{x:41.67693669,y:-83.67580712},
{x:38.52007994,y:-89.92376626},
{x:26.14626242,y:-80.32695115},
{x:40.81853168,y:-73.05795953},
{x:29.63376242,y:-90.75946212},
{x:42.857545,y:-78.82381401},
{x:33.88516129,y:-84.47257045},
{x:42.1065462,y:-70.7465233},
{x:40.15375451,y:-74.05628443},
{x:27.375556,y:-80.3253277},
{x:42.32941961,y:-83.21969211},
{x:42.39165085,y:-83.1401103},
{x:43.10599511,y:-73.73911379},
{x:39.34635387,y:-84.218418},
{x:41.26222181,y:-95.90320275},
{x:40.63504598,y:-74.39345554},
{x:40.81353716,y:-85.83597208},
{x:43.1199366,y:-77.6193998},
{x:31.71296339,y:-89.14839395},
{x:42.46553894,y:-82.93482409},
{x:25.79590421,y:-80.33751865},
{x:38.84201612,y:-76.94773525},
{x:36.05533545,y:-115.17154739},
{x:39.94788369,y:-82.93997728},
{x:45.1646567,y:-93.1571352},
{x:41.65830076,y:-87.79567078},
{x:27.16671431,y:-80.22827804},
{x:43.16053442,y:-77.59247258},
{x:38.88142574,y:-94.60447446},
{x:43.61123364,y:-83.91531259},
{x:41.3693033,y:-81.8045928},
{x:43.02270095,y:-85.63393964},
{x:46.80702774,y:-92.16314123},
{x:30.4460514,y:-82.9340147},
{x:41.48034544,y:-81.59883969},
{x:36.09781102,y:-75.72250911},
{x:37.09175241,y:-84.61829814},
{x:41.88972495,y:-84.04377931},
{x:26.063006,y:-80.207684},
{x:39.60878571,y:-84.16119372},
{x:27.01519244,y:-82.13396303},
{x:39.27415576,y:-81.58462488},
{x:41.527677,y:-72.77474996},
{x:39.91126796,y:-86.22088552},
{x:36.51315441,y:-88.88924628},
{x:39.39838512,y:-76.79277487},
{x:39.76372007,y:-84.63689248},
{x:39.2094267,y:-94.6426391},
{x:39.65972781,y:-106.82640709},
{x:25.86644976,y:-80.28191552},
{x:28.12275446,y:-80.67212731},
{x:40.15050714,y:-84.21454623},
{x:40.29260045,y:-83.06791357},
{x:42.38865021,y:-87.85496771},
{x:34.5834565,y:-83.7623082},
{x:43.04620647,y:-77.10982114},
{x:41.42233738,y:-82.07763657},
{x:42.42618186,y:-76.51242882},
{x:37.7970164,y:-97.32704786},
{x:33.40490852,y:-104.52249179},
{x:38.90186856,y:-76.9416479},
{x:42.35841987,y:-83.46827791},
{x:37.66426565,y:-97.29390934},
{x:33.41552243,y:-82.0246312},
{x:28.35740971,y:-80.70069283},
{x:42.70245145,y:-73.81488658},
{x:28.06239543,y:-82.70727716},
{x:42.1230402,y:-88.33603598},
{x:40.05332742,y:-74.89416831},
{x:34.14999103,y:-84.17426483},
{x:37.73711885,y:-84.31339407},
{x:26.19423768,y:-80.27010977},
{x:42.14128843,y:-70.84399603},
{x:40.74174843,y:-73.99077707},
{x:28.11826232,y:-81.97437465},
{x:39.08280883,y:-76.8637536},
{x:39.85316306,y:-86.12173364},
{x:38.4214886,y:-86.9384596},
{x:29.62200626,y:-82.37715519},
{x:36.00571636,y:-115.12859298},
{x:26.31887494,y:-80.10959796},
{x:40.4112659,y:-81.3505278},
{x:33.90463382,y:-84.60560426},
{x:40.75217328,y:-85.16802706},
{x:40.751976,y:-73.99029956},
{x:39.24759876,y:-94.65449631},
{x:40.85787084,y:-73.09112402},
{x:21.41139812,y:-157.99994767},
{x:37.14690844,y:-84.11451727},
{x:28.6982096,y:-81.3593334},
{x:40.7831396,y:-74.02092194},
{x:47.90603415,y:-97.04424396},
{x:41.31774204,y:-81.34864479},
{x:39.80291179,y:-86.0103378},
{x:39.60489928,y:-86.15846051},
{x:29.40839727,y:-82.24671945},
{x:40.28653368,y:-81.56780389},
{x:39.19220877,y:-84.65907506},
{x:39.12898559,y:-94.82473344},
{x:41.03054176,y:-81.42756209},
{x:40.63496516,y:-73.91465776},
{x:36.01618243,y:-106.06515643},
{x:37.05475644,y:-88.57000071},
{x:43.10617638,y:-77.54114583},
{x:38.95564198,y:-94.76144001},
{x:37.8251838,y:-82.8244257},
{x:38.80080373,y:-90.73457524},
{x:29.80134889,y:-82.51564003},
{x:39.68984716,y:-86.14928395},
{x:45.01227668,y:-93.1800887},
{x:42.41432747,y:-83.43344924},
{x:42.83742912,y:-71.66372022},
{x:40.80920719,y:-74.06812256},
{x:32.5165413,y:-90.0952788},
{x:42.0088831,y:-92.91181109},
{x:25.76049362,y:-80.42450945},
{x:26.6837515,y:-80.2943026},
{x:30.62034222,y:-81.64402441},
{x:41.00827204,y:-74.29432534},
{x:27.9957475,y:-82.4426711},
{x:39.12107759,y:-86.53079979},
{x:40.7513984,y:-73.42159419},
{x:45.78454489,y:-108.61960799},
{x:33.94725162,y:-84.62474585},
{x:41.79891891,y:-88.12133856},
{x:42.31580785,y:-88.43772046},
{x:36.7665807,y:-108.147972},
{x:43.04751241,y:-71.46042645},
{x:43.07858527,y:-75.64323485},
{x:36.0633164,y:-115.0356062},
{x:21.38157772,y:-158.1455435},
{x:43.68673151,y:-70.32467343},
{x:41.4325357,y:-82.7034178},
{x:39.28810194,y:-94.90673125},
{x:42.806868,y:-83.6250486},
{x:30.31423492,y:-89.35585678},
{x:42.41456377,y:-71.08003352},
{x:40.59547534,y:-74.00020212},
{x:37.77882614,y:-100.01583812},
{x:40.80806701,y:-73.24517748},
{x:39.44415298,y:-75.73891691},
{x:40.01425375,y:-105.22748747},
{x:27.7948834,y:-82.335436},
{x:44.69443047,y:-93.283225},
{x:43.21258553,y:-71.51387416},
{x:35.5847416,y:-80.8723483},
{x:32.33402968,y:-90.1024585},
{x:41.74315846,y:-72.63369332},
{x:42.29744978,y:-84.02883443},
{x:32.7114464,y:-103.1366739},
{x:41.13413849,y:-81.6431319},
{x:41.112875,y:-101.7164201},
{x:40.85903527,y:-73.15998203},
{x:36.1151766,y:-115.07008429},
{x:42.73913385,y:-84.50931296},
{x:44.85085165,y:-93.43261491},
{x:28.17458773,y:-80.67245588},
{x:36.48443779,y:-80.61348215},
{x:28.34589939,y:-81.48583212},
{x:33.55100209,y:-84.46219988},
{x:33.55869297,y:-83.47668561},
{x:41.23434288,y:-85.80794066},
{x:39.41457709,y:-81.44258112},
{x:40.10371554,y:-83.16066609},
{x:33.0489627,y:-117.28962248},
{x:35.20239626,y:-80.73435724},
{x:41.57014541,y:-87.46253371},
{x:39.8212756,y:-74.92927507},
{x:28.450827,y:-81.562441},
{x:34.13990089,y:-84.50339198},
{x:41.92018853,y:-72.67049531},
{x:40.49479826,y:-82.71493431},
{x:41.54171397,y:-87.84999557},
{x:35.94688818,y:-78.92150179},
{x:30.21986769,y:-93.32611889},
{x:20.8903449,y:-156.4625926},
{x:42.46864448,y:-92.32134461},
{x:40.00227729,y:-83.15307878},
{x:29.64627167,y:-82.34773922},
{x:32.74403538,y:-83.71539518},
{x:44.31124942,y:-69.78574365},
{x:30.35793548,y:-91.00960895},
{x:28.53037223,y:-81.39756408},
{x:42.63282256,y:-83.74722056},
{x:35.91254568,y:-78.78318809},
{x:41.57190525,y:-84.13404107},
{x:34.21906832,y:-118.58798437},
{x:39.16045893,y:-84.27554033},
{x:43.0727345,y:-76.1674319},
{x:38.51080706,y:-90.33378254},
{x:39.94061298,y:-82.47887671},
{x:42.27672323,y:-84.30830618},
{x:40.2882749,y:-84.1757245},
{x:34.65139203,y:-77.316987},
{x:41.93504534,y:-89.03501496},
{x:39.42611877,y:-87.41651482},
{x:29.1931097,y:-82.09469815},
{x:45.67996301,y:-111.0510546},
{x:40.87666668,y:-81.36434644},
{x:38.99189389,y:-80.22987664},
{x:26.43920943,y:-80.07510609},
{x:35.90219999,y:-79.95197564},
{x:27.30225553,y:-80.37287593},
{x:38.5243772,y:-81.9094494},
{x:40.79767895,y:-74.06578079},
{x:38.07540229,y:-83.94791208},
{x:40.76558336,y:-73.98336053},
{x:29.63991612,y:-82.34354437},
{x:42.12441984,y:-88.07232879},
{x:37.13709349,y:-85.97436659},
{x:39.85576263,y:-86.23972267},
{x:41.62800094,y:-93.60613481},
{x:42.0931858,y:-111.8764253},
{x:33.43355033,y:-84.45621534},
{x:39.44323093,y:-92.42872441},
{x:28.514688,y:-81.499507},
{x:37.13876553,y:-93.31722299},
{x:43.00793346,y:-83.07230934},
{x:30.44339554,y:-90.14231668},
{x:35.2009935,y:-89.7871884},
{x:40.58025669,y:-81.09288074},
{x:42.1541543,y:-76.879714},
{x:32.31593539,y:-90.88003278},
{x:40.05801095,y:-82.40042746},
{x:39.00886033,y:-94.46663305},
{x:43.59463191,y:-83.85130904},
{x:43.00574556,y:-85.68911076},
{x:40.26449305,y:-83.06974139},
{x:30.90413259,y:-84.55685087},
{x:31.72918577,y:-93.1515858},
{x:39.05096127,y:-95.6794998},
{x:47.49393777,y:-111.29314542},
{x:42.7563936,y:-86.1169496},
{x:43.19708072,y:-77.58560203},
{x:33.27408994,y:-84.29297805},
{x:29.18670423,y:-82.18243175},
{x:41.55053418,y:-87.42705077},
{x:36.26146882,y:-80.36340103},
{x:29.542366,y:-98.52878},
{x:42.82614556,y:-86.09031022},
{x:35.9813018,y:-80.0224788},
{x:34.77745364,y:-77.39364713},
{x:40.84379971,y:-74.46282417},
{x:40.0760991,y:-83.12923461},
{x:34.17785675,y:-84.4205875},
{x:37.10508365,y:-84.07059039},
{x:43.81353059,y:-83.00142311},
{x:41.73949015,y:-88.25046077},
{x:42.20991682,y:-85.8918149},
{x:37.37065705,y:-84.33443718},
{x:39.39727,y:-84.55247104},
{x:40.76780982,y:-74.22098547},
{x:40.96370784,y:-74.0775577},
{x:39.52458197,y:-76.18312329},
{x:41.15665903,y:-96.1371275},
{x:30.7541222,y:-83.27326655},
{x:33.70606149,y:-116.28169778},
{x:41.73506094,y:-87.62638396},
{x:47.71372659,y:-116.89521119},
{x:35.78450544,y:-78.8106367},
{x:41.64390587,y:-70.88558413},
{x:39.90713585,y:-82.73568884},
{x:40.74965311,y:-89.63516392},
{x:37.06338782,y:-85.07002339},
{x:35.0272452,y:-79.05308157},
{x:26.61013718,y:-80.08985023},
{x:42.24101263,y:-87.95913935},
{x:39.52903618,y:-119.82736788},
{x:42.41743998,y:-82.98885451},
{x:34.79889799,y:-77.43815839},
{x:42.92556455,y:-83.99621979},
{x:28.74814305,y:-81.87692446},
{x:33.72329674,y:-84.16606665},
{x:28.39275123,y:-80.60848318},
{x:43.08861227,y:-70.78989565},
{x:40.6559908,y:-73.6110125},
{x:36.87126452,y:-87.50015303},
{x:41.70648144,y:-83.58988255},
{x:28.05403711,y:-82.44198427},
{x:42.41798213,y:-83.26035373},
{x:39.26275827,y:-94.57500502},
{x:46.8402531,y:-100.77195257},
{x:39.1450426,y:-84.6243918},
{x:43.43640673,y:-84.00522396},
{x:41.59530827,y:-87.48356357},
{x:40.09907081,y:-83.10762294},
{x:38.76746044,y:-90.78368011},
{x:41.57754161,y:-88.15967202},
{x:35.40897745,y:-80.85534206},
{x:25.79727,y:-80.27439},
{x:39.836299,y:-104.72057381},
{x:34.30136543,y:-88.70670587},
{x:30.00502491,y:-90.17145849},
{x:39.78670718,y:-105.02481357},
{x:33.68000366,y:-84.22701813},
{x:42.40382619,y:-83.09640974},
{x:40.04971963,y:-74.83874783},
{x:29.46936063,y:-98.52626528},
{x:33.83000306,y:-84.49314192},
{x:39.21621577,y:-84.51915092},
{x:35.60938209,y:-77.39483267},
{x:39.80868667,y:-94.81589736},
{x:48.24112565,y:-114.27566459},
{x:36.00583325,y:-80.44091687},
{x:39.8136864,y:-84.22856576},
{x:39.08931644,y:-108.60223658},
{x:27.9562667,y:-82.7881302},
{x:39.73962512,y:-104.80862617},
{x:39.03412522,y:-95.76162464},
{x:40.68900458,y:-74.30579658},
{x:27.38946946,y:-82.45703414},
{x:42.3464101,y:-71.0875351},
{x:42.29744983,y:-71.48394674},
{x:42.450399,y:-79.3073046},
{x:39.16527963,y:-86.57541163},
{x:40.13600937,y:-83.01637061},
{x:45.05778244,y:-93.14540909},
{x:42.68200551,y:-83.15523225},
{x:35.750379,y:-81.336094},
{x:44.85817608,y:-93.30534495},
{x:43.17807124,y:-83.76468883},
{x:45.77003196,y:-108.57427865},
{x:35.9677887,y:-78.95465389},
{x:41.16366889,y:-81.47558844},
{x:39.3706109,y:-77.4128753},
{x:39.03625553,y:-84.22991625},
{x:40.50121235,y:-86.86750576},
{x:32.00533408,y:-81.11446783},
{x:39.7397792,y:-104.89438646},
{x:26.52857892,y:-81.86978057},
{x:38.24801576,y:-85.77200308},
{x:40.03210135,y:-85.99606124},
{x:33.43998886,y:-84.59128789},
{x:26.168556,y:-80.28267056},
{x:35.60818927,y:-82.31844306},
{x:40.74407879,y:-73.60773996},
{x:42.63977961,y:-83.34965167},
{x:42.61203951,y:-73.78845245},
{x:38.90141418,y:-99.3184916},
{x:41.60878649,y:-86.72533125},
{x:42.78302754,y:-73.94833088},
{x:38.27609306,y:-84.55336423},
{x:27.71156335,y:-82.37660408},
{x:35.2439121,y:-106.6974441},
{x:41.40644742,y:-84.13288218},
{x:39.99933745,y:-86.04349551},
{x:41.51543228,y:-72.10316804},
{x:32.52537149,y:-92.04368256},
{x:35.02954173,y:-80.85094616},
{x:40.2778547,y:-76.8245459},
{x:42.69686789,y:-87.86705709},
{x:38.0212665,y:-121.321522},
{x:33.39485327,y:-79.28880215},
{x:26.2688181,y:-98.201859},
{x:37.31314203,y:-121.93126649},
{x:33.36336138,y:-86.99959986},
{x:40.63770135,y:-79.93931293},
{x:37.06078938,y:-78.00655016},
{x:40.5582507,y:-80.2043712},
{x:35.60023682,y:-87.06275985},
{x:42.95962771,y:-87.91906811},
{x:41.01591705,y:-76.49018068},
{x:39.93678782,y:-77.68116304},
{x:29.9878945,y:-95.3410782},
{x:34.69634741,y:-79.92348657},
{x:37.18978272,y:-76.48666754},
{x:40.68165793,y:-80.1011856},
{x:34.28332548,y:-79.86016211},
{x:37.11084527,y:-121.01614572},
{x:36.25540312,y:-93.1225492},
{x:39.96162532,y:-84.19179105},
{x:41.5819092,y:-71.5006499},
{x:44.90182376,y:-91.93245649},
{x:40.01162602,y:-75.11547683},
{x:44.04813774,y:-123.13045226},
{x:40.445566,y:-75.35917},
{x:29.66326639,y:-95.12349379},
{x:40.12134237,y:-111.63829635},
{x:33.65230234,y:-112.23509967},
{x:34.65595394,y:-120.45725048},
{x:40.23218126,y:-75.13845116},
{x:41.25623748,y:-75.90387337},
{x:40.50302779,y:-111.88627321},
{x:32.68609166,y:-96.82528183},
{x:30.03354472,y:-95.55440962},
{x:38.8326619,y:-77.2060243},
{x:29.5917433,y:-95.6345931},
{x:38.4151029,y:-81.79394916},
{x:39.91108299,y:-77.65023199},
{x:32.80734409,y:-116.95169382},
{x:30.539012,y:-97.563637},
{x:33.3055767,y:-111.8620311},
{x:29.93790282,y:-95.3557273},
{x:29.65474271,y:-95.56947827},
{x:47.51685652,y:-122.63064712},
{x:33.52321126,y:-81.71988226},
{x:34.58599906,y:-94.22190718},
{x:29.80270814,y:-95.45912779},
{x:29.39492597,y:-94.93570715},
{x:35.16110531,y:-101.88351363},
{x:37.3086232,y:-121.9771318},
{x:35.2107196,y:-90.2053419},
{x:40.2879385,y:-75.2670025},
{x:40.13735207,y:-76.58403039},
{x:44.06613497,y:-121.30304955},
{x:33.35069055,y:-86.6193149},
{x:36.81340716,y:-76.09976954},
{x:35.11019645,y:-92.44097248},
{x:41.3537916,y:-74.72358853},
{x:37.16160977,y:-80.42199254},
{x:36.96923887,y:-82.59670883},
{x:35.14542181,y:-101.9374855},
{x:35.14705481,y:-90.04438117},
{x:40.04126816,y:-75.49323209},
{x:36.41406798,y:-89.05855},
{x:34.9131469,y:-91.194519},
{x:29.79057029,y:-95.54503396},
{x:38.41629967,y:-77.41807743},
{x:33.49113751,y:-80.85637517},
{x:35.03976662,y:-97.93656751},
{x:36.23283914,y:-86.75834187},
{x:33.9760474,y:-80.9568626},
{x:33.39384667,y:-111.59985065},
{x:29.99780154,y:-95.16841963},
{x:31.12025804,y:-97.86782429},
{x:36.37590055,y:-82.42705307},
{x:40.6396156,y:-111.83455333},
{x:31.45631782,y:-97.17162698},
{x:36.77081325,y:-76.25192129},
{x:30.04323229,y:-94.89145944},
{x:29.78154924,y:-95.71848265},
{x:34.0630077,y:-117.6435769},
{x:37.3158957,y:-121.8740506},
{x:47.74080155,y:-117.40958512},
{x:30.01220709,y:-97.85997076},
{x:29.4004944,y:-95.24677345},
{x:36.63222063,y:-79.3945199},
{x:35.23385623,y:-89.89471413},
{x:41.25689274,y:-82.61224286},
{x:33.27789085,y:-111.787844},
{x:37.12327386,y:-113.62291105},
{x:35.49175218,y:-97.76076525},
{x:37.8484799,y:-81.994773},
{x:33.54239062,y:-117.69227251},
{x:38.42384622,y:-82.41966695},
{x:36.0721442,y:-83.92716048},
{x:40.2333701,y:-111.694186},
{x:30.392229,y:-97.7068047},
{x:39.86188899,y:-75.68392932},
{x:41.88863166,y:-71.36727258},
{x:33.89030607,y:-98.48284141},
{x:34.65784125,y:-86.48337224},
{x:30.12633316,y:-95.44982515},
{x:34.61529445,y:-92.49789029},
{x:33.7477228,y:-117.0087251},
{x:34.12079239,y:-117.19638335},
{x:36.10879191,y:-83.33849712},
{x:41.2347794,y:-80.44917026},
{x:36.24269511,y:-90.95837206},
{x:33.99913911,y:-96.40690953},
{x:35.54526801,y:-84.56563935},
{x:33.57091613,y:-111.8875967},
{x:43.71621906,y:-87.7575767},
{x:33.56926569,y:-111.70946717},
{x:32.81882172,y:-86.6096579},
{x:33.94503287,y:-118.33299035},
{x:34.53803647,y:-86.91111714},
{x:36.84200615,y:-76.09038272},
{x:32.30685992,y:-95.33633396},
{x:38.88571323,y:-77.44458684},
{x:40.48073074,y:-78.40206596},
{x:33.33514287,y:-86.78470713},
{x:39.87105845,y:-75.42850658},
{x:35.06150984,y:-85.13697803},
{x:33.47623298,y:-112.22118728},
{x:47.2427593,y:-122.3611072},
{x:40.017435,y:-79.07757081},
{x:35.906968,y:-83.839772},
{x:39.65045019,y:-84.11078565},
{x:30.64503706,y:-97.68642515},
{x:29.56710292,y:-98.33037354},
{x:33.408528,y:-112.012161},
{x:40.27503194,y:-80.60904175},
{x:36.0868,y:-94.1237},
{x:48.0539821,y:-122.18660406},
{x:40.6099948,y:-75.3827753},
{x:34.6913122,y:-82.83913695},
{x:33.17934783,y:-96.88656628},
{x:37.40470084,y:-77.67094663},
{x:42.7270013,y:-87.9551087},
{x:40.48692596,y:-79.89063352},
{x:35.73267613,y:-83.99599676},
{x:32.38042695,y:-86.3004908},
{x:27.5309787,y:-99.4806218},
{x:30.46156378,y:-97.7921895},
{x:44.49521773,y:-73.12266916},
{x:40.03832448,y:-75.10910699},
{x:40.46372959,y:-79.82448623},
{x:44.78428806,y:-106.94210909},
{x:32.93201992,y:-97.284227},
{x:34.6476041,y:-86.03120841},
{x:36.4001945,y:-87.0304533},
{x:30.32115331,y:-97.71412008},
{x:47.92941533,y:-122.22172789},
{x:40.31463234,y:-75.9419205},
{x:32.89935114,y:-96.46808825},
{x:36.26324225,y:-95.84612668},
{x:43.08964501,y:-87.99045912},
{x:40.870447,y:-79.9297804},
{x:34.62287879,y:-98.4187194},
{x:38.6132929,y:-121.38232993},
{x:38.90170004,y:-77.45105807},
{x:44.07240364,y:-87.70585567},
{x:41.77522133,y:-71.44886159},
{x:36.62507511,y:-87.32309371},
{x:31.23857787,y:-85.46472366},
{x:36.17629661,y:-82.79963903},
{x:35.17688428,y:-101.91961348},
{x:32.86828651,y:-80.02502789},
{x:35.8651253,y:-101.9736593},
{x:36.86722942,y:-76.21159342},
{x:29.44788118,y:-95.0789331},
{x:43.02957798,y:-89.41114595},
{x:40.20696563,y:-74.80268567},
{x:33.94055401,y:-117.27833927},
{x:32.64089267,y:-117.09409565},
{x:37.63740063,y:-77.55099222},
{x:41.07617475,y:-111.97409257},
{x:37.27158758,y:-76.75567299},
{x:32.7653268,y:-96.8981056},
{x:36.11204338,y:-86.76744561},
{x:35.4349082,y:-97.43587362},
{x:34.07834879,y:-81.15020357},
{x:35.18213155,y:-97.41641396},
{x:41.35090778,y:-79.71864157},
{x:64.7561812,y:-147.350037},
{x:29.62497621,y:-98.49440843},
{x:29.9288178,y:-95.2039949},
{x:37.83600927,y:-122.25181554},
{x:38.83654539,y:-77.43120477},
{x:32.85677599,y:-96.64717108},
{x:33.17122895,y:-96.6387704},
{x:37.024895,y:-76.3332525},
{x:33.41502687,y:-111.73451163},
{x:35.42043745,y:-97.52988835},
{x:37.49898357,y:-77.54082665},
{x:36.85039339,y:-75.976616},
{x:33.71469853,y:-117.18651652},
{x:47.98403472,y:-122.20180042},
{x:43.19341292,y:-88.12848195},
{x:41.09374514,y:-112.00632428},
{x:33.6240678,y:-97.15031586},
{x:32.95876725,y:-87.17181878},
{x:45.40522783,y:-122.56845032},
{x:32.6484679,y:-96.5331588},
{x:32.63837881,y:-85.48119664},
{x:35.02043079,y:-85.32028422},
{x:32.43377537,y:-97.78208978},
{x:37.67469158,y:-122.12418504},
{x:34.832256,y:-92.2311356},
{x:38.93847816,y:-77.54692197},
{x:47.04395929,y:-122.8333916},
{x:38.42119324,y:-82.26320028},
{x:33.21709565,y:-96.63323566},
{x:44.08728063,y:-103.19968432},
{x:40.01587852,y:-79.60046545},
{x:38.41026068,y:-82.48722753},
{x:40.53809936,y:-79.83542293},
{x:37.63650535,y:-77.4598588},
{x:35.44099986,y:-88.63109942},
{x:29.69952207,y:-95.29714747},
{x:31.9250383,y:-106.4383189},
{x:37.69238807,y:-122.08990462},
{x:40.77836415,y:-80.38374022},
{x:38.87037481,y:-78.52486007},
{x:40.38695657,y:-111.91683277},
{x:39.32126057,y:-81.55284673},
{x:35.5039437,y:-97.9356065},
{x:38.23918124,y:-122.63470354},
{x:35.34817131,y:-94.34141278},
{x:40.14160273,y:-75.31333655},
{x:36.04409801,y:-86.64937109},
{x:38.43356845,y:-78.84433798},
{x:35.97815531,y:-83.60621788},
{x:47.38362658,y:-122.2278529},
{x:33.33435584,y:-111.7550084},
{x:34.92114082,y:-120.42074963},
{x:36.056383,y:-102.507695},
{x:40.46912796,y:-79.96232361},
{x:41.72843242,y:-71.43855438},
{x:35.39038599,y:-97.26596394},
{x:43.1171654,y:-88.0520141},
{x:39.98353796,y:-76.73725817},
{x:41.77064254,y:-76.41910498},
{x:40.1609044,y:-111.6419399},
{x:32.90821022,y:-96.5772841},
{x:42.9003671,y:-87.91090076},
{x:40.0303758,y:-75.21114841},
{x:31.96952517,y:-110.30507058},
{x:37.99168357,y:-122.30110444},
{x:34.24625872,y:-80.61710447},
{x:34.16206677,y:-85.71521284},
{x:47.6538308,y:-122.67851034},
{x:33.5813216,y:-112.1242122},
{x:31.87883883,y:-102.32872546},
{x:32.41274772,y:-80.64887041},
{x:38.75347354,y:-121.2597654},
{x:35.91531731,y:-86.82687163},
{x:38.23097054,y:-78.37019116},
{x:29.4910232,y:-98.43848161},
{x:38.92008413,y:-77.23388369},
{x:35.21417025,y:-84.87017557},
{x:36.19067207,y:-86.80006295},
{x:36.12778196,y:-86.90828338},
{x:47.48056522,y:-122.21682787},
{x:34.10697768,y:-117.60786742},
{x:40.98574998,y:-111.91112778},
{x:33.33485108,y:-86.91673301},
{x:35.38472164,y:-96.91984818},
{x:34.5864948,y:-112.34139696},
{x:33.60829213,y:-86.68971151},
{x:33.46605933,y:-112.03068402},
{x:29.66486064,y:-95.18205464},
{x:32.15812299,y:-94.32265527},
{x:34.19085235,y:-79.81024449},
{x:33.97451214,y:-117.55882658},
{x:41.8078249,y:-71.40756816},
{x:40.49412252,y:-80.06358855},
{x:39.7502099,y:-77.5673076},
{x:33.7862533,y:-78.7707201},
{x:41.30518185,y:-111.96828541},
{x:40.45026492,y:-80.01888201},
{x:40.58872496,y:-75.56072436},
{x:34.07950143,y:-118.11112475},
{x:29.5419992,y:-95.3905149},
{x:33.63985183,y:-86.61363736},
{x:33.6017974,y:-114.522753},
{x:32.59012124,y:-97.14281745},
{x:39.49759483,y:-121.57233216},
{x:34.43672247,y:-79.3696265},
{x:39.23610893,y:-121.03339656},
{x:41.35584001,y:-71.80511824},
{x:33.28366833,y:-96.56034628},
{x:40.2303416,y:-77.1442634},
{x:36.82265519,y:-76.41920798},
{x:42.45959896,y:-123.32230366},
{x:32.66166261,y:-96.89798672},
{x:40.62434029,y:-75.445498},
{x:42.88296115,y:-72.55740331},
{x:33.90817261,y:-81.21913345},
{x:48.3437543,y:-122.6717033},
{x:29.7939086,y:-95.8011707},
{x:40.2225065,y:-80.2101049},
{x:32.23521033,y:-110.94450098},
{x:39.97830913,y:-75.27245432},
{x:33.83632726,y:-81.09185413},
{x:39.85662227,y:-82.90096655},
{x:26.23049541,y:-98.36248379},
{x:33.89566415,y:-118.00912621},
{x:37.3442306,y:-79.9554061},
{x:35.50794818,y:-97.56607443},
{x:39.0625124,y:-77.4692943},
{x:35.45977641,y:-94.35614482},
{x:33.50989,y:-112.34592},
{x:47.41148328,y:-122.25558043},
{x:30.34041014,y:-97.96965569},
{x:33.22625474,y:-97.5944238},
{x:36.05917472,y:-88.11517417},
{x:33.57665924,y:-79.02836953},
{x:33.02707513,y:-96.83174117},
{x:41.00942973,y:-76.42121577},
{x:34.13571168,y:-117.53820263},
{x:40.1808646,y:-75.45187332},
{x:35.86868426,y:-83.5682559},
{x:34.05301433,y:-81.15965434},
{x:39.02640712,y:-77.39588476},
{x:29.71498076,y:-95.49654901},
{x:41.8433687,y:-79.1403586},
{x:31.81419683,y:-106.5521974},
{x:32.54196778,y:-94.73031834},
{x:35.86473976,y:-83.54407601},
{x:36.58057121,y:-87.40948163},
{x:32.8820672,y:-96.85572624},
{x:47.6655698,y:-117.41072774},
{x:34.18267135,y:-118.46653506},
{x:36.50789206,y:-87.27190755},
{x:28.81174827,y:-96.97380245},
{x:37.18474178,y:-77.31834278},
{x:36.32756886,y:-119.67769459},
{x:35.03491812,y:-85.15239885},
{x:40.72233959,y:-111.85422331},
{x:33.5529407,y:-80.82526535},
{x:41.31406946,y:-75.75513073},
{x:29.4109044,y:-98.50676805},
{x:37.07646494,y:-76.47002981},
{x:33.99589183,y:-81.03259005},
{x:31.32390801,y:-85.82547426},
{x:31.03608308,y:-104.8299735},
{x:45.57532892,y:-122.56392606},
{x:32.89637706,y:-96.89563569},
{x:33.99552,y:-117.71471724},
{x:36.31626906,y:-95.62573468},
{x:36.22282741,y:-83.26172769},
{x:38.07787047,y:-81.83232603},
{x:32.9112135,y:-96.95808806},
{x:36.24590938,y:-86.72130045},
{x:35.47680117,y:-120.65671244},
{x:40.40367748,y:-80.54870814},
{x:37.32689376,y:-79.19111138},
{x:35.49361237,y:-97.60166364},
{x:40.76222268,y:-84.14872981},
{x:35.13076928,y:-89.85709593},
{x:32.21273639,y:-80.73024809},
{x:46.58509666,y:-120.54597795},
{x:36.78533883,y:-76.10762715},
{x:47.75480306,y:-122.15842009},
{x:39.11048503,y:-77.55437046},
{x:36.5571859,y:-82.2131583},
{x:34.81999656,y:-82.27579594},
{x:33.6257216,y:-112.1332069},
{x:32.86384586,y:-96.89651009},
{x:29.72770604,y:-95.41823655},
{x:30.0620766,y:-99.16941851},
{x:30.07300518,y:-95.43462485},
{x:35.65847201,y:-101.409648},
{x:40.21737533,y:-76.79120115},
{x:40.47555074,y:-80.09948239},
{x:42.3465259,y:-122.88322806},
{x:40.6968416,y:-112.0827849},
{x:44.47590025,y:-88.0755301},
{x:35.21437647,y:-101.90234122},
{x:32.80345533,y:-80.01371701},
{x:32.22931475,y:-111.00033805},
{x:37.7617309,y:-121.43606365},
{x:43.47831474,y:-110.77106416},
{x:32.7128724,y:-97.28468388},
{x:36.0152381,y:-84.25119661},
{x:39.90803125,y:-79.75522832},
{x:33.46277923,y:-86.91161335},
{x:36.11589219,y:-97.06780411},
{x:38.32788074,y:-81.59723689},
{x:43.0435097,y:-88.25949848},
{x:38.48159095,y:-121.50252074},
{x:41.82002833,y:-111.83232173},
{x:32.38251535,y:-86.20272896},
{x:35.69413827,y:-85.74904606},
{x:25.9528456,y:-97.4862874},
{x:36.92833137,y:-76.31828517},
{x:36.0741044,y:-95.79681314},
{x:34.12829279,y:-117.87731822},
{x:37.51502566,y:-77.79055774},
{x:45.54951902,y:-122.90639329},
{x:40.4275064,y:-79.7057037},
{x:45.51550159,y:-122.90300667},
{x:33.102294,y:-96.1100722},
{x:47.37299125,y:-122.30113395},
{x:40.01721375,y:-75.30919072},
{x:35.03968537,y:-90.06814622},
{x:40.9140285,y:-77.7372873},
{x:34.18500223,y:-79.36540335},
{x:36.02261462,y:-87.34109},
{x:37.65219612,y:-122.40192309},
{x:30.209086,y:-95.426353},
{x:35.48625191,y:-86.43448657},
{x:29.93761987,y:-93.93526062},
{x:35.07205917,y:-89.93238353},
{x:38.65359249,y:-77.28772652},
{x:34.97909478,y:-101.91894376},
{x:33.80738047,y:-118.02886426},
{x:33.91008102,y:-117.88139641},
{x:37.35543148,y:-77.41172388},
{x:41.09818484,y:-104.84877483},
{x:33.91768248,y:-117.9627344},
{x:34.94286911,y:-86.57274192},
{x:37.31405022,y:-79.96124632},
{x:38.40673609,y:-78.8647817},
{x:37.3947032,y:-76.52622879},
{x:32.6038499,y:-96.8240936},
{x:36.20463527,y:-86.73820362},
{x:29.98909304,y:-95.73803164},
{x:33.13039237,y:-97.04114966},
{x:33.9867123,y:-118.10438186},
{x:32.3706788,y:-86.2703274},
{x:34.4184814,y:-117.2841731},
{x:40.61553787,y:-80.55903554},
{x:30.12473473,y:-94.15586829},
{x:37.65291814,y:-77.61062577},
{x:33.13809472,y:-96.80500835},
{x:32.74594731,y:-96.99265033},
{x:36.90216263,y:-82.06911958},
{x:35.1662477,y:-81.4513727},
{x:29.92076015,y:-95.413462},
{x:40.05249066,y:-78.50969732},
{x:32.90880588,y:-80.66709146},
{x:35.3419869,y:-89.9098751},
{x:33.53042954,y:-101.9565893},
{x:64.83597502,y:-147.75481913},
{x:34.26228,y:-119.2122931},
{x:35.03490722,y:-80.97262859},
{x:40.703432,y:-76.187089},
{x:34.06262462,y:-117.22158775},
{x:40.34881215,y:-76.38740569},
{x:35.94819663,y:-84.15876299},
{x:37.21094745,y:-80.39916426},
{x:32.50836097,y:-86.21361521},
{x:42.70464617,y:-88.25021535},
{x:40.9531631,y:-75.978405},
{x:33.0203859,y:-117.11166143},
{x:32.79773326,y:-117.23972216},
{x:35.36616295,y:-86.22025109},
{x:29.5582126,y:-95.7150671},
{x:36.18933962,y:-86.62700131},
{x:37.51420356,y:-120.84944576},
{x:32.86043276,y:-96.99147552},
{x:32.16374038,y:-110.91672185},
{x:61.1810862,y:-149.8173858},
{x:29.74764557,y:-95.75088546},
{x:35.02108368,y:-81.89544789},
{x:41.17680668,y:-112.00567156},
{x:35.2023343,y:-86.09457187},
{x:35.11113093,y:-89.94631313},
{x:40.33796955,y:-79.80862759},
{x:40.5576921,y:-111.8934072},
{x:33.33680065,y:-111.80631369},
{x:33.60294607,y:-117.69150741},
{x:37.69340259,y:-75.71998224},
{x:32.86467288,y:-96.67853951},
{x:38.67888332,y:-121.26886346},
{x:47.61904003,y:-122.20125362},
{x:42.1042334,y:-80.1319639},
{x:34.70722984,y:-81.19122729},
{x:32.99520337,y:-80.03779254},
{x:37.28469763,y:-79.93239766},
{x:40.23509096,y:-75.29061019},
{x:32.93987655,y:-96.82112038},
{x:41.09061795,y:-112.06515223},
{x:32.84504202,y:-85.18257022},
{x:31.08687906,y:-97.45755347},
{x:35.066241,y:-89.7566273},
{x:39.84357212,y:-77.20285892},
{x:29.89586034,y:-95.06387308},
{x:36.38683365,y:-86.45116203},
{x:38.26727373,y:-122.03457541},
{x:40.21960012,y:-111.65946409},
{x:34.58020897,y:-111.88629985},
{x:44.52578414,y:-109.05863062},
{x:42.99583886,y:-88.10767166},
{x:32.75560047,y:-101.9479438},
{x:47.44492545,y:-122.19851911},
{x:39.18560261,y:-78.14183593},
{x:36.87454634,y:-76.13399863},
{x:34.226001,y:-79.793966},
{x:37.57845914,y:-77.39749476},
{x:40.59754438,y:-75.3409727},
{x:47.90750475,y:-122.20777676},
{x:47.71503858,y:-122.29735851},
{x:35.5630767,y:-97.6512283},
{x:43.86841562,y:-91.20147206},
{x:47.29628567,y:-122.24039374},
{x:44.02954027,y:-121.3121637},
{x:40.0737831,y:-75.1569813},
{x:36.07925842,y:-86.95169359},
{x:35.92918828,y:-89.8822917},
{x:37.34540056,y:-77.34278306},
{x:34.69433399,y:-82.97190538},
{x:40.46777496,y:-78.75872456},
{x:29.54917574,y:-95.09484355},
{x:32.23171865,y:-101.47065997},
{x:36.0283699,y:-83.8738895},
{x:40.62692301,y:-74.91966556},
{x:32.57189969,y:-85.50030738},
{x:37.13639535,y:-80.36411047},
{x:32.84029161,y:-79.85891438},
{x:45.64556234,y:-122.59494558},
{x:29.0428356,y:-95.4448206},
{x:40.31638836,y:-76.89808738},
{x:40.81300535,y:-79.54459772},
{x:34.81626305,y:-82.44146258},
{x:37.76751741,y:-80.46637774},
{x:46.97748921,y:-123.80473891},
{x:35.42873313,y:-119.02072973},
{x:36.14566931,y:-86.81002736},
{x:29.54033364,y:-95.02467938},
{x:44.93838703,y:-122.98342333},
{x:35.4731824,y:-85.0329511},
{x:34.47890164,y:-91.5492288},
{x:38.78171338,y:-77.51219809},
{x:40.1290919,y:-76.3065867},
{x:29.7029415,y:-95.3575419},
{x:32.26816989,y:-80.85991636},
{x:30.51475925,y:-87.88560055},
{x:32.87856445,y:-96.71741796},
{x:34.76319286,y:-86.59154937},
{x:34.06956479,y:-117.98021972},
{x:37.13498837,y:-81.49244875},
{x:34.37881383,y:-118.56334202},
{x:31.77288517,y:-106.36781385},
{x:26.1962509,y:-98.2821612},
{x:36.07436281,y:-86.63808234},
{x:36.05640105,y:-94.18153367},
{x:32.66333357,y:-97.40352184},
{x:36.0079629,y:-83.975526},
{x:43.06288788,y:-89.30832527},
{x:41.0160677,y:-75.1824988},
{x:34.61281548,y:-86.56820878},
{x:45.52516542,y:-122.57880516},
{x:37.40122873,y:-79.16780636},
{x:40.66729823,y:-111.94054737},
{x:33.6714487,y:-112.1178812},
{x:37.7240201,y:-122.1620319},
{x:38.74699252,y:-77.45029045},
{x:34.10724196,y:-117.3951859},
{x:35.19224792,y:-89.79300455},
{x:37.52151322,y:-120.88886619},
{x:33.79622405,y:-117.99245752},
{x:46.27958671,y:-119.27335583},
{x:30.025101,y:-95.4214063},
{x:33.6117502,y:-112.185389},
{x:41.8788444,y:-80.1734098},
{x:35.81468711,y:-83.98146607},
{x:35.08423428,y:-85.19831377},
{x:31.308377,y:-85.713708},
{x:30.54481538,y:-87.70794961},
{x:34.0859341,y:-117.87058376},
{x:36.05020277,y:-83.99736397},
{x:35.49359314,y:-97.27670887},
{x:36.7350852,y:-119.6996543},
{x:32.84310179,y:-97.23920435},
{x:34.228715,y:-111.326876},
{x:32.79521474,y:-79.86067626},
{x:36.06378986,y:-86.71751663},
{x:31.19056219,y:-85.39793686},
{x:37.26504509,y:-80.0217133},
{x:36.801296,y:-76.010001},
{x:34.5154129,y:-82.4822546},
{x:32.29960524,y:-80.9460707},
{x:39.9189425,y:-75.14083564},
{x:37.07330181,y:-76.4931449},
{x:36.03906447,y:-94.25340877},
{x:34.8675484,y:-92.11718122},
{x:41.05604163,y:-75.96034266},
{x:33.30472127,y:-111.94429685},
{x:29.72339042,y:-95.60564387},
{x:40.11489071,y:-84.62181687},
{x:37.139915,y:-80.551058},
{x:40.23664487,y:-75.57054009},
{x:43.60955342,y:-72.97292119},
{x:34.1960744,y:-79.6833883},
{x:37.28956802,y:-80.08429572},
{x:34.14987474,y:-117.47497816},
{x:40.30355058,y:-79.50568959},
{x:32.64823734,y:-97.05703944},
{x:40.67461895,y:-80.31002775},
{x:38.6453037,y:-121.4752397},
{x:40.38426061,y:-75.92703805},
{x:40.43154051,y:-111.7898047},
{x:33.25349261,y:-111.6315946},
{x:36.8952091,y:-76.2233051},
{x:35.90364696,y:-84.14063662},
{x:33.50961221,y:-112.03752011},
{x:34.0737812,y:-117.558172},
{x:37.12419774,y:-113.52769107},
{x:31.78369249,y:-85.95965177},
{x:34.79060287,y:-92.22903103},
{x:33.75836822,y:-116.30293561},
{x:31.09024272,y:-97.72100009},
{x:29.80087703,y:-94.98087823},
{x:44.9185028,y:-123.0451973},
{x:32.74291918,y:-96.44662894},
{x:41.2332597,y:-77.02067524},
{x:35.14613637,y:-85.22073768},
{x:36.85013475,y:-76.17977817},
{x:29.8172359,y:-94.8998188},
{x:40.0808112,y:-74.99372435},
{x:32.92571603,y:-96.84003331},
{x:34.85450946,y:-92.40290925},
{x:34.20077414,y:-118.5183047},
{x:34.01484083,y:-117.52571948},
{x:45.68510494,y:-122.6015532},
{x:36.77110931,y:-76.58484712},
{x:30.67138644,y:-96.33963436},
{x:36.78944167,y:-75.99812351},
{x:38.64269,y:-121.52},
{x:35.75116077,y:-95.40296232},
{x:47.19601498,y:-120.958016},
{x:40.6241988,y:-79.72328342},
{x:41.1007836,y:-111.9413652},
{x:35.5265305,y:-98.6954273},
{x:30.56542693,y:-97.82192887},
{x:36.09354418,y:-95.88707964},
{x:31.9528352,y:-95.2647814},
{x:37.32518648,y:-79.49939065},
{x:35.08339912,y:-85.05859549},
{x:35.39300004,y:-118.95016126},
{x:38.95161633,y:-81.76250294},
{x:33.10522564,y:-96.8050459},
{x:34.14870261,y:-119.19586875},
{x:33.95405378,y:-81.23242162},
{x:39.86971008,y:-75.32020703},
{x:38.82623787,y:-77.31513045},
{x:46.2790696,y:-119.18754416},
{x:33.664326,y:-117.856645},
{x:40.55053861,y:-75.59608966},
{x:32.53295799,y:-94.78941679},
{x:34.91532339,y:-82.10801157},
{x:38.43254313,y:-81.83694072},
{x:33.68464171,y:-117.18446836},
{x:30.2001193,y:-97.8635083},
{x:38.84225477,y:-77.27515079},
{x:41.3092566,y:-105.5559514},
{x:31.4561138,y:-86.9634494},
{x:36.3492989,y:-82.3989536},
{x:61.21785658,y:-149.8373124},
{x:33.57303825,y:-81.93805885},
{x:29.57993592,y:-95.39705784},
{x:39.97918572,y:-75.15807956},
{x:40.062361,y:-75.08470774},
{x:40.65341158,y:-111.98624969},
{x:37.1196381,y:-79.72037494},
{x:47.45776591,y:-122.24338979},
{x:29.95268381,y:-93.98854606},
{x:35.4515374,y:-86.77652121},
{x:33.76772081,y:-116.95831954},
{x:37.46449188,y:-77.44235605},
{x:34.74423308,y:-86.67536438},
{x:32.75383938,y:-117.21748933},
{x:45.30708349,y:-122.94322371},
{x:35.61547133,y:-119.65874746},
{x:34.19057391,y:-118.32783468},
{x:33.4563171,y:-112.2736088},
{x:34.77587503,y:-80.38341089},
{x:33.98949559,y:-79.19898547},
{x:32.6697247,y:-114.4951901},
{x:34.70799386,y:-86.59032226},
{x:39.42128547,y:-84.58900523},
{x:39.77017848,y:-76.67516917},
{x:40.3381945,y:-80.02552673},
{x:37.52182062,y:-77.18311675},
{x:34.494282,y:-87.7269775},
{x:30.50671061,y:-97.71753297},
{x:35.159708,y:-89.726838},
{x:40.75815284,y:-80.31984217},
{x:34.56501505,y:-87.00933269},
{x:31.44322729,y:-85.6521821},
{x:36.5878876,y:-87.2944815},
{x:34.67459917,y:-86.54420422},
{x:33.82098541,y:-118.32564956},
{x:37.77915522,y:-79.43653891},
{x:34.93569968,y:-81.98982954},
{x:45.50516541,y:-122.95899945},
{x:32.28481976,y:-110.97865909},
{x:32.89537167,y:-96.64941341},
{x:37.25587056,y:-77.41072946},
{x:41.01330806,y:-80.39561637},
{x:32.82183135,y:-97.05850167},
{x:35.0711672,y:-89.84300628},
{x:38.85782945,y:-77.39326052},
{x:31.6987069,y:-106.2401547},
{x:31.48505518,y:-97.21064247},
{x:40.1663476,y:-74.8740457},
{x:36.1185069,y:-95.8629794},
{x:30.73826243,y:-95.59369862},
{x:36.82741578,y:-76.33841515},
{x:31.75948607,y:-106.26984403},
{x:27.75100526,y:-97.41823309},
{x:36.90379113,y:-76.13445729},
{x:31.653969,y:-106.270787},
{x:37.26196922,y:-77.3250901},
{x:40.79181463,y:-76.55648947},
{x:44.90639083,y:-89.58887905},
{x:29.928731,y:-95.72764722},
{x:33.9712678,y:-118.3781711},
{x:40.01074811,y:-75.17535836},
{x:30.68519931,y:-88.05604718},
{x:35.10349294,y:-114.59750286},
{x:40.26832047,y:-76.68998569},
{x:32.7824636,y:-96.6268786},
{x:40.45701136,y:-79.93749976},
{x:27.726849,y:-97.37985336},
{x:40.26139846,y:-76.77516653},
{x:35.2052311,y:-89.79853877},
{x:42.8982706,y:-97.3983787},
{x:36.70151433,y:-77.54455493},
{x:44.08195772,y:-103.25163491},
{x:47.45262611,y:-120.33372402},
{x:41.67138999,y:-71.49912135},
{x:36.13706394,y:-85.50274484},
{x:33.17558888,y:-96.50885247},
{x:34.98998365,y:-85.19711616},
{x:42.87188633,y:-87.93303032},
{x:39.28219231,y:-80.35070882},
{x:34.18999749,y:-97.14265828},
{x:33.37856481,y:-111.78626418},
{x:32.76466113,y:-117.15299763},
{x:40.6749408,y:-111.905076},
{x:40.25036531,y:-111.65678003},
{x:40.53805655,y:-80.01189083},
{x:38.13695951,y:-79.06789541},
{x:38.03510297,y:-81.12209727},
{x:39.97510627,y:-76.67235814},
{x:32.93999811,y:-85.97789615},
{x:32.881974,y:-97.3832142},
{x:40.03695561,y:-76.45446227},
{x:37.27646766,y:-78.40009309},
{x:30.2661945,y:-97.73362376},
{x:47.1055695,y:-119.2520376},
{x:47.4622261,y:-122.33341008},
{x:35.04638466,y:-89.68607024},
{x:40.10061555,y:-75.30520276},
{x:36.1669985,y:-94.14409541},
{x:40.2479993,y:-82.86740772},
{x:34.3919466,y:-92.82953069},
{x:45.53338564,y:-122.66114719},
{x:31.29927494,y:-94.72852014},
{x:36.10166996,y:-86.81928769},
{x:29.51006721,y:-98.58086005},
{x:37.52142175,y:-122.27534831},
{x:34.66066874,y:-118.14838082},
{x:41.04327145,y:-75.30738473},
{x:34.97857837,y:-92.03095485},
{x:33.79880742,y:-118.07151675},
{x:32.94661023,y:-96.62003517},
{x:41.41169639,y:-80.36169342},
{x:40.11720036,y:-77.03824908},
{x:34.557715,y:-112.482078},
{x:34.7035865,y:-118.1675949},
{x:30.388103,y:-95.675053},
{x:31.10116917,y:-97.80361623},
{x:32.5242282,y:-97.35125833},
{x:34.1354762,y:-117.2417974},
{x:40.80729364,y:-77.89293323},
{x:34.78061832,y:-111.76223427},
{x:33.98901856,y:-81.23501837},
{x:35.04476975,y:-85.27997464},
{x:36.91554703,y:-76.26993124},
{x:38.00900813,y:-121.87022557},
{x:35.0425769,y:-85.68711118},
{x:34.1219507,y:-117.691358},
{x:36.7691299,y:-81.77974812},
{x:34.0198406,y:-118.4921903},
{x:33.44267079,y:-94.09547567},
{x:32.77733213,y:-97.29008317},
{x:33.43557831,y:-111.83954279},
{x:32.05743267,y:-102.0835951},
{x:39.99650447,y:-75.23719739},
{x:37.06106425,y:-113.58670392},
{x:34.14014666,y:-80.91639139},
{x:33.17440666,y:-96.83796734},
{x:42.56578961,y:-87.85452464},
{x:35.34930451,y:-97.49551975},
{x:40.26378199,y:-80.17326482},
{x:39.91915845,y:-75.18863913},
{x:33.84801803,y:-117.88886733},
{x:34.74937591,y:-87.66682282},
{x:40.24419807,y:-76.99341595},
{x:32.20701064,y:-110.85510045},
{x:40.82893546,y:-76.84127368},
{x:36.04142002,y:-86.74424149},
{x:41.4528043,y:-75.64032935},
{x:34.91860175,y:-95.7443773},
{x:40.77102587,y:-111.94174983},
{x:33.3772542,y:-112.0312958},
{x:46.24179932,y:-119.12223727},
{x:39.64152661,y:-79.99904037},
{x:40.31254514,y:-76.5862479},
{x:32.9188292,y:-80.10263649},
{x:37.3275712,y:-80.7087758},
{x:29.77394879,y:-95.60677975},
{x:27.5742656,y:-99.4741322},
{x:37.14207058,y:-79.26664538},
{x:40.06031685,y:-77.49665431},
{x:32.93678354,y:-117.10868115},
{x:47.89016505,y:-122.25688295},
{x:39.371694,y:-111.586799},
{x:33.01335493,y:-97.21966311},
{x:31.48417445,y:-100.46109913},
{x:41.41724077,y:-82.67389767},
{x:38.2921681,y:-77.568385},
{x:34.9794197,y:-120.4348144},
{x:34.22031855,y:-119.15759884},
{x:43.18162917,y:-89.22586083},
{x:33.4774106,y:-94.40989187},
{x:29.40496944,y:-98.63000482},
{x:32.75142796,y:-97.3595497},
{x:34.0478103,y:-118.2612876},
{x:33.32207693,y:-111.68949008},
{x:35.80594137,y:-90.67703515},
{x:35.57335054,y:-89.64465157},
{x:29.5548995,y:-95.4004324},
{x:32.47044171,y:-85.08956578},
{x:31.99676219,y:-106.58601075},
{x:40.43041351,y:-111.88599899},
{x:40.3193814,y:-75.1311258},
{x:31.87998267,y:-102.38460459},
{x:40.58144801,y:-75.52274799},
{x:40.19737377,y:-75.52356474},
{x:32.67798702,y:-97.04299673},
{x:41.88057626,y:-71.38694733},
{x:34.19525578,y:-101.75080329},
{x:41.71501873,y:-111.83718286},
{x:36.16751815,y:-85.51280514},
{x:38.804394,y:-77.5232314},
{x:34.20218618,y:-92.0331037},
{x:32.216382,y:-98.21119},
{x:47.67661113,y:-117.28340788},
{x:42.98889826,y:-87.90898837},
{x:35.0957149,y:-80.93604594},
{x:42.71427099,y:-88.97940705},
{x:34.4700178,y:-117.3540667},
{x:36.22397142,y:-83.03553634},
{x:32.56534446,y:-97.31981948},
{x:41.26378601,y:-110.95444373},
{x:33.46734814,y:-94.04206083},
{x:35.9088236,y:-88.7521921},
{x:36.7199797,y:-76.3053568},
{x:30.5595443,y:-88.2057029},
{x:39.902624,y:-75.24041961},
{x:34.5083514,y:-117.3990145},
{x:40.83473722,y:-75.2966921},
{x:35.84541397,y:-86.42487153},
{x:30.6756644,y:-88.1183777},
{x:27.70507161,y:-97.36360856},
{x:37.79435544,y:-81.21853106},
{x:34.782614,y:-86.936025},
{x:35.6570014,y:-88.40815498},
{x:34.09278953,y:-117.67068074},
{x:38.5771955,y:-109.550362},
{x:39.90654611,y:-82.8238646},
{x:27.49208847,y:-97.85018474},
{x:37.68921477,y:-122.4655312},
{x:37.35124182,y:-79.22891009},
{x:33.85991219,y:-118.14165786},
{x:37.79693785,y:-121.19021055},
{x:33.37822101,y:-112.13553473},
{x:42.92725786,y:-88.11339129},
{x:42.34000168,y:-122.87197508},
{x:29.73186825,y:-95.48295826},
{x:33.87495079,y:-98.5306263},
{x:43.22859399,y:-123.36561934},
{x:43.54343312,y:-96.77375793},
{x:36.36314239,y:-94.21949767},
{x:37.22958413,y:-79.97537389},
{x:33.08611615,y:-96.6185408},
{x:41.63600711,y:-80.15308499},
{x:41.70851382,y:-81.36082806},
{x:35.58887298,y:-89.24142297},
{x:36.64097693,y:-80.24560249},
{x:29.88447639,y:-97.92358339},
{x:33.8912086,y:-117.5635986},
{x:40.97024565,y:-76.89340733},
{x:34.75463834,y:-86.74047367},
{x:29.18468629,y:-98.84637491},
{x:40.76639458,y:-81.5195608},
{x:34.35927576,y:-80.06143928},
{x:35.31210481,y:-119.09168646},
{x:34.84093389,y:-87.63698136},
{x:40.64414444,y:-75.34490146},
{x:29.43769143,y:-98.49708694},
{x:29.549523,y:-95.585069},
{x:35.45070493,y:-93.46593708},
{x:32.43118642,y:-94.86390908},
{x:29.61158617,y:-95.24943173},
{x:29.67754,y:-98.05971},
{x:37.13011717,y:-76.53769121},
{x:38.39586767,y:-82.41297333},
{x:38.7508043,y:-112.1025376},
{x:36.8133277,y:-76.06903545},
{x:37.396865,y:-79.90939826},
{x:41.33585473,y:-75.96105066},
{x:33.60941905,y:-86.28053079},
{x:37.61398708,y:-77.49764174},
{x:33.48753493,y:-80.47481462},
{x:40.25903545,y:-75.32584369},
{x:34.1167766,y:-86.86385505},
{x:40.4512416,y:-109.5498531},
{x:38.11662799,y:-121.25889669},
{x:28.4431697,y:-99.2483587},
{x:33.80904974,y:-118.27510111},
{x:34.49255206,y:-93.08721587},
{x:27.5911603,y:-99.5126963},
{x:42.56844357,y:-87.95426409},
{x:30.10459864,y:-95.23548755},
{x:40.09283231,y:-76.11623168},
{x:34.95360086,y:-81.06386125},
{x:38.64632113,y:-121.11818693},
{x:29.57558785,y:-95.28673992},
{x:42.92292993,y:-87.95032158},
{x:32.34693204,y:-95.30504667},
{x:35.19192201,y:-101.8176385},
{x:33.070651,y:-112.0458768},
{x:34.57795422,y:-82.71312207},
{x:39.53979933,y:-84.29106295},
{x:34.24059251,y:-81.45913303},
{x:32.76381984,y:-96.85390948},
{x:42.09470711,y:-80.00840224},
{x:30.41863816,y:-97.7035948},
{x:35.95556467,y:-85.03162221},
{x:39.29217069,y:-77.84991637},
{x:38.64609754,y:-77.33531445},
{x:34.40851403,y:-117.39860281},
{x:32.63353497,y:-80.87500557},
{x:29.971835,y:-95.6923087},
{x:37.98547493,y:-121.33781701},
{x:32.4962544,y:-85.0410998},
{x:31.10726087,y:-97.74865128},
{x:32.89896941,y:-97.31984764},
{x:34.33252447,y:-86.50386639},
{x:36.44752199,y:-83.57338659},
{x:32.0178896,y:-97.09443512},
{x:32.57891997,y:-97.07592225},
{x:42.58759958,y:-87.82327608},
{x:37.60228019,y:-77.45488465},
{x:40.14145655,y:-75.16649433},
{x:39.03635088,y:-80.44764653},
{x:34.0708384,y:-117.43419162},
{x:31.70647895,y:-106.31662444},
{x:29.5221647,y:-95.1587248},
{x:37.00198717,y:-121.56128794},
{x:33.20406519,y:-96.7663088},
{x:40.1698681,y:-74.89296466},
{x:36.18705803,y:-86.40920207},
{x:33.90339987,y:-118.1433604},
{x:36.84044185,y:-119.78987894},
{x:41.1588648,y:-111.9391045},
{x:35.95108233,y:-83.91502261},
{x:32.95402529,y:-96.88596361},
{x:36.12681681,y:-83.46888945},
{x:34.03046648,y:-81.09006711},
{x:33.57744374,y:-101.86769563},
{x:32.94141399,y:-97.1214138},
{x:37.59803742,y:-77.62064484},
{x:33.52995884,y:-101.8117241},
{x:39.39588645,y:-81.20120913},
{x:47.09820325,y:-122.29285701},
{x:32.9852434,y:-96.8426452},
{x:46.676498,y:-122.97894322},
{x:40.0985055,y:-111.6558358},
{x:29.9857037,y:-95.34952},
{x:40.19441644,y:-76.98048032},
{x:43.39814201,y:-88.18505779},
{x:39.99067702,y:-75.13585376},
{x:33.95400079,y:-117.39021019},
{x:29.57241781,y:-95.56992486},
{x:44.23119782,y:-88.4035331},
{x:36.092688,y:-84.13692798},
{x:40.05337059,y:-75.66436357},
{x:33.21792,y:-96.68125},
{x:39.83958709,y:-83.08828533},
{x:35.72570998,y:-97.41469999},
{x:34.77300987,y:-86.53530076},
{x:45.65953492,y:-118.8076812},
{x:36.80863559,y:-119.86886225},
{x:39.16112444,y:-78.17009232},
{x:45.55388397,y:-122.53773407},
{x:47.65967372,y:-117.19711527},
{x:30.22346432,y:-95.68218527},
{x:36.34594062,y:-84.16163832},
{x:36.50266384,y:-82.55927727},
{x:38.35358859,y:-81.64169852},
{x:29.56603856,y:-97.94162124},
{x:43.05106793,y:-89.50250286},
{x:36.04805694,y:-95.88548943},
{x:35.90496604,y:-86.38445866},
{x:34.42199062,y:-118.58371675},
{x:38.46967508,y:-121.4159402},
{x:44.79232927,y:-91.51393734},
{x:47.4683394,y:-122.2869443},
{x:40.92054159,y:-111.88830647},
{x:35.2365323,y:-85.19906044},
{x:31.43089047,y:-110.96214272},
{x:31.55035439,y:-97.12477595},
{x:29.985541,y:-95.332426},
{x:33.88871534,y:-118.02844482},
{x:45.66582868,y:-122.55658126},
{x:31.8375913,y:-106.5627323},
{x:41.0529688,y:-80.35642192},
{x:37.4134812,y:-81.61044379},
{x:33.26960591,y:-80.56730374},
{x:33.0806439,y:-80.2105928},
{x:32.41842646,y:-86.23269327},
{x:30.20803745,y:-95.46040088},
{x:33.22968305,y:-97.14722982},
{x:40.49778884,y:-111.41437783},
{x:35.03490841,y:-120.4837937},
{x:35.32753822,y:-94.4145593},
{x:32.60454326,y:-85.43263257},
{x:45.38305962,y:-122.75466284},
{x:32.81448341,y:-96.80812326},
{x:36.8135074,y:-76.16809573},
{x:38.0525503,y:-121.3748229},
{x:40.6446283,y:-75.49180463},
{x:47.22963859,y:-123.12691439},
{x:33.82684179,y:-78.68116803},
{x:33.58135882,y:-112.23591641},
{x:38.46933102,y:-77.41349631},
{x:36.1371144,y:-96.10162586},
{x:29.60162847,y:-98.27754378},
{x:35.45014514,y:-97.42112523},
{x:36.84109606,y:-81.50773622},
{x:32.50303284,y:-87.83202908},
{x:33.5919026,y:-101.9393324},
{x:35.8188854,y:-86.39515936},
{x:40.7341572,y:-82.79166251},
{x:47.71092023,y:-122.18701914},
{x:29.93316173,y:-95.58356255},
{x:32.73592461,y:-97.11189662},
{x:40.67394154,y:-111.84305459},
{x:32.5919207,y:-96.93746388},
{x:33.92811197,y:-116.94724739},
{x:37.4441374,y:-77.4737067},
{x:37.30575955,y:-79.88927118},
{x:32.48785363,y:-86.41135722},
{x:38.31919229,y:-81.717894},
{x:33.76023269,y:-117.84268834},
{x:40.50829119,y:-112.02408761},
{x:36.62727987,y:-79.8588369},
{x:36.65760238,y:-76.22649126},
{x:38.86130383,y:-77.09280885},
{x:37.93851158,y:-77.46798187},
{x:36.03856097,y:-86.78483554},
{x:33.91679959,y:-118.07341354},
{x:46.72528482,y:-122.97423172},
{x:40.3675143,y:-79.9829276},
{x:32.35878874,y:-111.08553171},
{x:37.43484888,y:-79.12295043},
{x:33.64005149,y:-111.98056768},
{x:43.76947459,y:-91.214692},
{x:32.83886402,y:-97.16011964},
{x:38.64733554,y:-77.26278835},
{x:40.22585361,y:-76.93914279},
{x:39.87119741,y:-75.38500383},
{x:37.1456249,y:-76.4587603},
{x:37.53462704,y:-121.99604049},
{x:40.270865,y:-111.684017},
{x:34.50822096,y:-81.0009259},
{x:39.79534496,y:-75.97574367},
{x:29.43071855,y:-98.5495533},
{x:27.6029823,y:-99.4772451},
{x:34.60146383,y:-118.14405643},
{x:39.99444989,y:-75.75384825},
{x:35.820294,y:-84.267936},
{x:38.94476969,y:-77.51995478},
{x:34.1510188,y:-118.00290652},
{x:38.76025351,y:-77.46124685},
{x:41.4954538,y:-112.01557108},
{x:35.92176981,y:-84.09316689},
{x:33.950545,y:-118.164457},
{x:35.4585189,y:-97.6195076},
{x:36.32207003,y:-95.82411995},
{x:29.70221893,y:-95.51950336},
{x:39.96491053,y:-75.59461139},
{x:39.93814413,y:-76.69136301},
{x:48.417489,y:-122.334003},
{x:41.1283399,y:-75.36167063},
{x:36.70639274,y:-119.7703582},
{x:34.75218622,y:-92.32830428},
{x:33.6346286,y:-91.80229117},
{x:33.21476207,y:-87.54571127},
{x:29.8794733,y:-95.68451085},
{x:34.27211097,y:-118.76722909},
{x:33.37782444,y:-111.63665061},
{x:33.3778275,y:-111.9607387},
{x:37.35982231,y:-81.10607654},
{x:42.06596218,y:-80.09259582},
{x:34.54141026,y:-90.62908128},
{x:40.58258916,y:-111.87277079},
{x:32.93675095,y:-80.0450023},
{x:40.13610943,y:-79.84267831},
{x:38.36489021,y:-81.64560556},
{x:33.10141835,y:-96.67601556},
{x:45.33608721,y:-122.58981802},
{x:36.16654868,y:-86.51375732},
{x:43.02798841,y:-88.18839848},
{x:41.71920567,y:-71.48400843},
{x:34.74052589,y:-92.27638565},
{x:45.22655564,y:-123.18074159},
{x:35.09342045,y:-89.81119394},
{x:35.0499476,y:-81.98617749},
{x:41.03771563,y:-111.94505326},
{x:32.96376117,y:-96.64524257},
{x:29.35699344,y:-98.53182256},
{x:40.31539519,y:-111.70359045},
{x:61.58208045,y:-149.46767547},
{x:37.54822659,y:-122.04561442},
{x:39.4372966,y:-81.4636267},
{x:37.7181009,y:-77.4512568},
{x:33.01110842,y:-96.99251622},
{x:33.91330199,y:-81.53315663},
{x:39.33282,y:-80.23725},
{x:32.24542843,y:-80.97798608},
{x:35.18278539,y:-89.83608715},
{x:41.13581731,y:-77.45152752},
{x:34.42717212,y:-117.37261127},
{x:39.8935644,y:-83.7225476},
{x:37.7199221,y:-76.3842545},
{x:34.8168136,y:-118.8867047},
{x:41.84473493,y:-71.41825944},
{x:37.3109266,y:-77.3916613},
{x:33.4393591,y:-112.5569947},
{x:39.6003889,y:-110.82260139},
{x:39.70981431,y:-111.8186016},
{x:40.12891498,y:-75.40260114},
{x:40.96467201,y:-76.61699322},
{x:33.93611015,y:-117.17900097},
{x:40.59046395,y:-79.75151807},
{x:37.15820881,y:-76.5561567},
{x:48.2863199,y:-122.66081125},
{x:41.08227226,y:-81.50877365},
{x:38.8660996,y:-77.2305978},
{x:26.1714363,y:-97.9913279},
{x:61.59386483,y:-149.1208349},
{x:32.13431249,y:-110.99761829},
{x:35.67023774,y:-88.85199539},
{x:39.47266032,y:-80.17449774},
{x:35.80292746,y:-84.26043078},
{x:30.05100055,y:-95.24605483},
{x:32.97588218,y:-80.06635845},
{x:40.83189809,y:-81.38481706},
{x:38.42045458,y:-122.71298558},
{x:35.50975686,y:-97.50268735},
{x:37.361572,y:-81.05451746},
{x:32.35851126,y:-86.16956335},
{x:39.9521365,y:-83.80370557},
{x:61.2092379,y:-149.73932941},
{x:34.8237721,y:-82.5793349},
{x:40.28564315,y:-76.84302546},
{x:40.45188245,y:-76.51261121},
{x:34.1032964,y:-80.9537724},
{x:29.81220902,y:-95.41124135},
{x:42.1237246,y:-80.07929697},
{x:37.29739266,y:-76.72624982},
{x:33.70173825,y:-80.23870111},
{x:40.80014899,y:-124.17473316},
{x:32.86547989,y:-97.10069441},
{x:30.11178052,y:-93.82187024},
{x:33.1173025,y:-95.59537634},
{x:38.4128744,y:-82.3499501},
{x:45.49284475,y:-122.80596726},
{x:42.00858514,y:-80.30356623},
{x:41.23648296,y:-75.85577108},
{x:34.50348295,y:-81.86039821},
{x:36.1673701,y:-86.8210798},
{x:40.4380964,y:-79.77183945},
{x:36.44210927,y:-94.11893208},
{x:29.21290415,y:-99.77739885},
{x:29.40110564,y:-95.0359832},
{x:45.53154526,y:-122.86805235},
{x:32.94581005,y:-96.70047864},
{x:35.20511714,y:-89.86319342},
{x:31.91638779,y:-102.28875482},
{x:34.06289937,y:-117.69011538},
{x:42.21433206,y:-121.76398918},
{x:29.96482793,y:-95.43592122},
{x:33.1816148,y:-117.2957221},
{x:40.69905879,y:-112.02431773},
{x:33.86411725,y:-112.13377923},
{x:40.61085648,y:-111.98059425},
{x:36.03629196,y:-83.43694203},
{x:36.13353197,y:-95.96433155},
{x:42.52424002,y:-88.98193002},
{x:29.52921857,y:-98.56362686},
{x:35.16347898,y:-89.8582533},
{x:43.24977828,y:-89.37279908},
{x:29.467012,y:-95.356431},
{x:36.95864628,y:-81.09796445},
{x:38.8759339,y:-77.1970328},
{x:29.9959126,y:-95.6155286},
{x:37.93202069,y:-79.232004},
{x:36.91360128,y:-76.14781976},
{x:35.23272247,y:-85.82576314},
{x:36.14508768,y:-86.66617781},
{x:34.9510159,y:-81.9199249},
{x:29.56541327,y:-98.58597602},
{x:40.60921223,y:-80.28398216},
{x:36.17721274,y:-95.97633676},
{x:35.59061686,y:-84.47448839},
{x:32.70904482,y:-96.80203646},
{x:37.47604374,y:-77.52106413},
{x:29.48726258,y:-98.6702916},
{x:32.31163373,y:-102.54400421},
{x:40.38471536,y:-79.82282996},
{x:37.0438217,y:-76.38096705},
{x:37.50599383,y:-77.48202686},
{x:36.00412018,y:-95.79689473},
{x:48.15211973,y:-122.1842013},
{x:28.70930848,y:-100.47939414},
{x:32.75694324,y:-97.16837928},
{x:48.73391581,y:-122.47040659},
{x:34.7795757,y:-82.31089629},
{x:36.75020542,y:-95.93883135},
{x:33.19760983,y:-87.47821592},
{x:44.52479185,y:-87.99264684},
{x:35.14898588,y:-89.93696541},
{x:41.04871096,y:-75.11107653},
{x:32.74458518,y:-96.68244792},
{x:31.49599759,y:-97.15444669},
{x:46.22632037,y:-119.21450928},
{x:32.87922001,y:-111.73855349},
{x:32.4319774,y:-87.0140094},
{x:36.50392233,y:-86.88079946},
{x:38.50035609,y:-82.65234456},
{x:33.50793265,y:-112.27338821},
{x:41.51877658,y:-71.29255734},
{x:34.27216032,y:-119.26105633},
{x:42.95888947,y:-88.00977639},
{x:33.56538245,y:-81.73263743},
{x:33.29064008,y:-111.8399612},
{x:40.29409494,y:-76.65171153},
{x:26.1906913,y:-98.208611},
{x:39.28708472,y:-84.56460536},
{x:29.652851,y:-95.276753},
{x:33.61381684,y:-85.78687944},
{x:40.57789516,y:-75.45475162},
{x:34.06852941,y:-117.61138608},
{x:40.28384211,y:-79.54865858},
{x:35.5453922,y:-100.9724534},
{x:34.98534375,y:-81.21448874},
{x:32.65403101,y:-97.134198},
{x:33.8998163,y:-118.35192293},
{x:33.93023181,y:-81.07785119},
{x:47.1597649,y:-122.5041942},
{x:45.4955908,y:-122.8801579},
{x:30.22172878,y:-97.76416339},
{x:35.98596177,y:-85.01205549},
{x:30.44059723,y:-97.66436917},
{x:34.09588323,y:-118.29216294},
{x:33.47167331,y:-86.82170436},
{x:37.66780078,y:-122.08049253},
{x:27.5112915,y:-99.5043308},
{x:38.78732767,y:-77.29980997},
{x:29.45250179,y:-98.6286167},
{x:29.80866394,y:-95.16571961},
{x:42.84858707,y:-106.27125777},
{x:39.30685774,y:-84.48725514},
{x:30.43732084,y:-95.49776293},
{x:41.86542751,y:-71.4432929},
{x:35.1271095,y:-118.4153213},
{x:37.2973014,y:-120.48173435},
{x:36.71500657,y:-76.18697226},
{x:35.8608137,y:-86.39090522},
{x:33.87503066,y:-118.18578336},
{x:29.73532513,y:-95.63701726},
{x:40.32640754,y:-76.05048269},
{x:35.51079311,y:-84.37178671},
{x:39.67786549,y:-80.85803524},
{x:34.93455736,y:-80.83657622},
{x:41.37713948,y:-75.6915106},
{x:33.19600121,y:-117.2540465},
{x:34.064595,y:-117.493058},
{x:34.97530203,y:-81.87807925},
{x:37.77916691,y:-79.99120088},
{x:45.45885321,y:-98.45976904},
{x:29.50300121,y:-95.04324496},
{x:35.4070176,y:-99.3800616},
{x:33.09590908,y:-80.03145134},
{x:38.33965657,y:-81.6154625},
{x:32.80376471,y:-79.89123054},
{x:36.05956881,y:-90.52337283},
{x:37.06431457,y:-81.74008258},
{x:29.72217894,y:-95.35205305},
{x:40.248541,y:-111.647168},
{x:38.78440425,y:-121.28797435},
{x:39.25565701,y:-112.11618097},
{x:32.73056159,y:-97.41191179},
{x:38.81069946,y:-77.13263721},
{x:30.59000334,y:-88.1688856},
{x:34.49248348,y:-85.84313861},
{x:47.406074,y:-120.291666},
{x:35.9763034,y:-86.5128701},
{x:37.14982562,y:-80.58234644},
{x:36.87897509,y:-76.04177338},
{x:40.5204399,y:-78.3973594},
{x:36.00692674,y:-88.40624138},
{x:33.436028,y:-111.997864},
{x:37.1248339,y:-76.391385},
{x:32.5166979,y:-96.820956},
{x:39.90709036,y:-75.28849125},
{x:38.03384274,y:-78.48480731},
{x:35.2051665,y:-89.73002493},
{x:32.97804472,y:-115.5075854},
{x:33.6381159,y:-112.0660662},
{x:29.81288721,y:-95.68637108},
{x:40.13397473,y:-75.45198262},
{x:31.30821175,y:-86.49987148},
{x:32.67240185,y:-97.4694378},
{x:32.69075089,y:-97.12953669},
{x:36.73409072,y:-78.91028345},
{x:35.21680482,y:-89.98941668},
{x:40.69633103,y:-111.96671516},
{x:43.1829425,y:-87.96428651},
{x:39.31981927,y:-84.31359239},
{x:30.3576259,y:-95.5955896},
{x:31.73467336,y:-95.61573659},
{x:36.16743496,y:-95.76096676},
{x:35.19087921,y:-114.06885266},
{x:41.7548678,y:-71.40494131},
{x:33.01568431,y:-97.06992745},
{x:33.53786,y:-112.22045},
{x:33.95581533,y:-80.92615128},
{x:37.97379928,y:-81.14540979},
{x:29.35707293,y:-98.50068708},
{x:40.5882269,y:-111.9888433},
{x:34.41537886,y:-118.46802132},
{x:42.70325159,y:-89.00684605},
{x:36.34275614,y:-88.85854192},
{x:38.00447769,y:-121.80566937},
{x:40.3986803,y:-79.5794826},
{x:32.98206188,y:-96.91052444},
{x:35.1698015,y:-92.72377387},
{x:40.04462727,y:-75.14288672},
{x:35.37747621,y:-97.54847802},
{x:31.53080824,y:-97.18557894},
{x:33.147482,y:-117.10601367},
{x:35.13569112,y:-90.00856392},
{x:34.7534276,y:-92.3924392},
{x:39.87144238,y:-77.02491045},
{x:37.36501977,y:-121.85138159},
{x:36.00739223,y:-84.02149044},
{x:40.30931306,y:-76.80252008},
{x:42.83361254,y:-106.33834653},
{x:32.83237001,y:-96.99434363},
{x:35.15774477,y:-90.1376617},
{x:31.6834494,y:-106.18820899},
{x:35.29623391,y:-119.02636014},
{x:36.75466907,y:-76.24733135},
{x:37.37116846,y:-77.50019387},
{x:45.68030782,y:-122.66656458},
{x:33.02431927,y:-96.88384183},
{x:33.2630115,y:-97.1793414},
{x:35.04908053,y:-89.79895122},
{x:38.07957058,y:-78.99064725},
{x:40.6273462,y:-111.9393942},
{x:35.75990418,y:-83.97636703},
{x:33.221345,y:-96.931212},
{x:34.11837768,y:-93.09720208},
{x:26.18290284,y:-98.05544718},
{x:34.0501457,y:-117.3085804},
{x:33.8911889,y:-79.75508673},
{x:40.13035728,y:-75.54632932},
{x:30.7043083,y:-88.12545014},
{x:32.75241143,y:-117.00975793},
{x:43.12181688,y:-89.31794912},
{x:34.70541349,y:-86.78714596},
{x:36.91657573,y:-76.23401937},
{x:40.38764098,y:-111.83484301},
{x:40.2796174,y:-111.7149201},
{x:45.77064001,y:-122.54801095},
{x:29.55307818,y:-98.6654985},
{x:40.21781158,y:-76.98347799},
{x:32.71186125,y:-97.06558997},
{x:38.9073559,y:-77.257151},
{x:32.86747211,y:-79.99127094},
{x:37.77917474,y:-79.99116138},
{x:40.61564471,y:-80.05481395},
{x:43.04178773,y:-87.9474499},
{x:33.85858247,y:-117.99990445},
{x:40.4474458,y:-80.153462},
{x:40.9809003,y:-76.0159285},
{x:37.2464496,y:-76.6573647},
{x:39.89883112,y:-80.14698029},
{x:33.33418777,y:-111.94672465},
{x:38.29318384,y:-112.65233874},
{x:41.21195961,y:-76.76636964},
{x:37.77305328,y:-120.83106861},
{x:30.23883494,y:-97.72546099},
{x:32.70582088,y:-97.02167425},
{x:40.1226504,y:-74.8809089},
{x:37.653547,y:-77.401459},
{x:35.99317067,y:-86.68490519},
{x:43.04206988,y:-108.38134468},
{x:40.98314376,y:-81.49264571},
{x:47.16083488,y:-122.48431519},
{x:40.3297418,y:-76.855306},
{x:29.6904311,y:-95.2143798},
{x:33.6113936,y:-112.1130081},
{x:41.66202539,y:-71.54506021},
{x:38.2339381,y:-77.50441834},
{x:35.22244215,y:-88.24236786},
{x:47.17520316,y:-122.18545116},
{x:30.18129712,y:-95.53613573},
{x:37.36017621,y:-120.60162038},
{x:37.09441611,y:-81.810202},
{x:32.766924,y:-97.0640366},
{x:37.05663152,y:-120.8527173},
{x:33.4593238,y:-112.358732},
{x:32.78871248,y:-96.59535416},
{x:32.27316722,y:-110.94434023},
{x:32.6422478,y:-96.7803525},
{x:37.23444785,y:-80.42026117},
{x:29.44225476,y:-98.66736083},
{x:33.638462,y:-112.421538},
{x:33.68420524,y:-112.10834116},
{x:35.37410773,y:-94.42166369},
{x:36.71016221,y:-97.08397552},
{x:33.20958487,y:-79.98250589},
{x:32.3454952,y:-111.0135378},
{x:30.37836689,y:-87.6825539},
{x:29.66015,y:-95.71784},
{x:41.16138093,y:-112.0270139},
{x:34.72843654,y:-114.31562935},
{x:36.32591876,y:-119.27521996},
{x:29.5353794,y:-95.51943161},
{x:33.78730321,y:-78.99508365},
{x:41.55393744,y:-75.22557467},
{x:41.13879981,y:-104.78664146},
{x:35.11660332,y:-89.90878141},
{x:36.76455061,y:-76.20320499},
{x:33.50718567,y:-111.89105541},
{x:32.90834538,y:-96.76805019},
{x:40.56216457,y:-76.00296333},
{x:34.45266524,y:-93.07378739},
{x:45.43850265,y:-122.57947594},
{x:36.9452177,y:-80.94568312},
{x:37.03322843,y:-95.61865627},
{x:34.18881182,y:-118.93548176},
{x:37.44185539,y:-77.58862899},
{x:37.50536724,y:-77.60506086},
{x:39.546293,y:-77.9138834},
{x:48.4556241,y:-122.33511999},
{x:33.92432848,y:-118.32528055},
{x:36.351538,y:-82.21767783},
{x:36.28877507,y:-88.3136829},
{x:40.0529748,y:-76.32312821},
{x:33.59533486,y:-101.8707949},
{x:32.99736357,y:-96.7987293},
{x:41.97382862,y:-76.54077684},
{x:34.21621267,y:-119.07107227},
{x:31.569834,y:-102.9077264},
{x:33.04032077,y:-96.73294544},
{x:47.66774636,y:-122.37667377},
{x:34.16773484,y:-118.61929054},
{x:32.5569202,y:-94.3505022},
{x:35.07128885,y:-92.49323533},
{x:29.76232956,y:-95.84143013},
{x:33.4245691,y:-86.70198262},
{x:33.17871121,y:-87.44660274},
{x:33.70580605,y:-117.78607875},
{x:34.21967707,y:-119.0396797},
{x:40.25367135,y:-75.66044188},
{x:45.61900526,y:-122.4765589},
{x:32.32368855,y:-111.04541391},
{x:31.42791374,y:-100.51074319},
{x:39.96448652,y:-76.76809898},
{x:33.98206174,y:-81.06181287},
{x:34.958854,y:-82.435542},
{x:33.392283,y:-111.9777918},
{x:36.74089915,y:-76.58302389},
{x:27.5439192,y:-99.5038678},
{x:45.3298418,y:-118.07592593},
{x:29.43317421,y:-98.40598216},
{x:35.36611323,y:-119.0437822},
{x:41.30691119,y:-81.83583051},
{x:40.81822562,y:-75.7289037},
{x:41.41784945,y:-75.6544852},
{x:34.15510863,y:-118.79347757},
{x:38.73796914,y:-77.18791634},
{x:29.57631002,y:-95.26584417},
{x:47.7896535,y:-122.219259},
{x:47.04576513,y:-122.93306847},
{x:35.12904264,y:-84.86591835},
{x:40.49906,y:-111.937971},
{x:34.44369397,y:-118.57469916},
{x:33.78831532,y:-117.95783155},
{x:33.33558385,y:-111.85599317},
{x:33.60263352,y:-85.83576514},
{x:40.98820371,y:-75.22040606},
{x:32.63455409,y:-97.35078098},
{x:36.30653258,y:-82.36867244},
{x:33.80141185,y:-116.39030546},
{x:36.37638292,y:-100.8017763},
{x:33.249085,y:-111.84066971},
{x:41.98081213,y:-71.514422},
{x:40.36802756,y:-80.1041159},
{x:30.56963816,y:-97.6916264},
{x:37.675938,y:-77.4604487},
{x:36.7157417,y:-76.2384004},
{x:31.07491703,y:-97.3688677},
{x:36.11904746,y:-95.93835392},
{x:36.70464449,y:-121.66716412},
{x:27.5188466,y:-99.449012},
{x:34.72681599,y:-81.63921197},
{x:29.73717615,y:-95.56667805},
{x:33.39006644,y:-111.68217669},
{x:39.9598568,y:-75.2334936},
{x:32.23653808,y:-110.87798573},
{x:33.9462848,y:-117.47294547},
{x:33.6473682,y:-78.9763689},
{x:39.62703398,y:-79.92882013},
{x:32.754805,y:-97.0969354},
{x:38.85552041,y:-77.33293227},
{x:32.44106177,y:-80.69665208},
{x:33.68170772,y:-96.6088973},
{x:32.33283425,y:-96.61798022},
{x:37.6225262,y:-122.412693},
{x:33.91353097,y:-117.46320744},
{x:39.64799423,y:-79.89809275},
{x:33.1484429,y:-97.1059477},
{x:32.84079964,y:-96.5963175},
{x:32.45166061,y:-100.39449736},
{x:33.129446,y:-96.662608},
{x:46.14747347,y:-123.91884693},
{x:33.41238524,y:-111.54395322},
{x:33.2756327,y:-93.2141061},
{x:30.39848869,y:-97.74528437},
{x:43.0755664,y:-88.06587195},
{x:40.52272891,y:-111.94330113},
{x:45.63608928,y:-122.50576848},
{x:40.580789,y:-111.8335003},
{x:35.194363,y:-101.743696},
{x:36.1459385,y:-82.847054},
{x:34.11528647,y:-117.82942759},
{x:32.64946139,y:-116.96643859},
{x:39.26712336,y:-81.53370917},
{x:35.26213706,y:-97.49173555},
{x:32.33938596,y:-97.41201573},
{x:41.15408971,y:-81.35073657},
{x:36.06040677,y:-95.85327812},
{x:35.04278021,y:-90.79065889},
{x:33.977318,y:-81.1259693},
{x:33.89044207,y:-117.32561454},
{x:38.38838693,y:-81.8305546},
{x:35.39217571,y:-97.70632446},
{x:32.47654403,y:-80.96977249},
{x:36.00664149,y:-83.77249807},
{x:29.53425759,y:-95.20574793},
{x:32.7927828,y:-117.0971777},
{x:47.11279479,y:-122.43426651},
{x:29.48722056,y:-98.588963},
{x:47.15273815,y:-122.29254089},
{x:38.42671659,y:-121.48091152},
{x:47.29221524,y:-122.31295393},
{x:32.4602622,y:-96.9949419},
{x:35.2489119,y:-87.3266557},
{x:40.28494291,y:-80.12071744},
{x:32.63152909,y:-97.40683186},
{x:31.1386641,y:-97.3314763},
{x:47.56998098,y:-122.66343608},
{x:35.56785173,y:-88.81438717},
{x:40.39272474,y:-80.06215207},
{x:36.56026913,y:-82.55878342},
{x:44.94164228,y:-72.2051121},
{x:35.60367534,y:-97.74452149},
{x:35.22930299,y:-89.52056261},
{x:41.35711561,y:-83.65102619},
{x:33.504851,y:-101.921086},
{x:35.0940179,y:-81.6660886},
{x:37.34635954,y:-76.7584259},
{x:26.1579865,y:-97.9062601},
{x:40.13552181,y:-74.96570505},
{x:35.18522804,y:-84.87608761},
{x:32.3432062,y:-86.2222508},
{x:37.02178781,y:-76.43851585},
{x:36.8479212,y:-76.0209384},
{x:36.86531683,y:-76.28514878},
{x:31.88473971,y:-106.57530206},
{x:40.34842683,y:-79.93976489},
{x:32.3432062,y:-86.22225076},
{x:32.68119623,y:-97.11429521},
{x:32.71998869,y:-96.85668197},
{x:33.37806758,y:-86.8029812},
{x:35.99971962,y:-83.92480731},
{x:36.57580303,y:-119.62485641},
{x:29.83822587,y:-95.48802562},
{x:45.5340914,y:-122.4777735},
{x:35.97348044,y:-83.98352623},
{x:48.004357,y:-122.107625},
{x:37.77750119,y:-122.22638015},
{x:38.30446541,y:-80.83271921},
{x:38.8681547,y:-77.1519991},
{x:29.49512578,y:-98.70882556},
{x:29.8166133,y:-95.7515674},
{x:36.71830135,y:-79.91208718},
{x:39.46971043,y:-77.95502928},
{x:46.9779356,y:-120.54304361},
{x:35.21812229,y:-97.46952523},
{x:31.75487232,y:-106.34292215},
{x:38.02064393,y:-121.26729771},
{x:36.21032604,y:-119.33047324},
{x:38.2551967,y:-82.43264012},
{x:48.79967859,y:-122.48661518},
{x:33.80509158,y:-117.22652972},
{x:43.08881019,y:-87.902648},
{x:38.29672984,y:-77.49862343},
{x:34.10654744,y:-117.93732102},
{x:37.9761514,y:-120.3598979},
{x:40.6887504,y:-80.31928003},
{x:29.7051637,y:-95.84533843},
{x:37.2396045,y:-81.2498026},
{x:32.67212931,y:-97.37627059},
{x:43.01595705,y:-88.04945335},
{x:33.65859612,y:-117.74217367},
{x:30.3486871,y:-97.5414551},
{x:33.48231393,y:-111.92654561},
{x:36.16321767,y:-84.08572511},
{x:29.95571771,y:-95.54536253},
{x:40.68658625,y:-75.24973668},
{x:34.71554101,y:-92.34202812},
{x:41.24968194,y:-75.85581735},
{x:47.61316292,y:-117.36776412},
{x:47.5582788,y:-122.6511048},
{x:43.48722913,y:-88.81006211},
{x:34.78529135,y:-82.47788735},
{x:38.80161102,y:-81.3494327},
{x:40.09662924,y:-75.01802534},
{x:34.02272948,y:-80.95405229},
{x:40.3391334,y:-76.4547734},
{x:29.59735858,y:-95.19894047},
{x:34.49215856,y:-81.97698712},
{x:30.51896614,y:-97.82835022},
{x:29.79748923,y:-95.36995896},
{x:29.774043,y:-95.815578},
{x:30.10784415,y:-97.33890474},
{x:36.46931354,y:-86.67372823},
{x:37.50401634,y:-77.65609123},
{x:35.28077901,y:-93.09277098},
{x:34.57467736,y:-118.04452343},
{x:35.14734367,y:-89.79606696},
{x:29.70184383,y:-98.09508517},
{x:31.7449131,y:-106.30232692},
{x:40.41634689,y:-79.92516696},
{x:34.20606468,y:-86.87220768},
{x:39.4767195,y:-80.12164012},
{x:34.80750715,y:-92.46452193},
{x:32.76513544,y:-97.21657455},
{x:37.17613508,y:-113.30940968},
{x:29.82673579,y:-97.98564166},
{x:40.19566934,y:-77.18965977},
{x:34.21466788,y:-80.97799256},
{x:33.00702826,y:-96.5545544},
{x:37.58060757,y:-77.49272325},
{x:39.82037226,y:-84.19112076},
{x:37.10930946,y:-76.49761453},
{x:34.67874225,y:-86.74816623},
{x:40.18011313,y:-75.13094366},
{x:38.35086569,y:-77.05385789},
{x:36.08715521,y:-86.69488013},
{x:61.19387326,y:-149.86727252},
{x:32.64823693,y:-85.38411437},
{x:35.78264827,y:-83.55166609},
{x:33.165797,y:-87.55131282},
{x:30.19171986,y:-97.7707053},
{x:37.04307448,y:-112.51386818},
{x:30.68172282,y:-88.22504618},
{x:32.50475819,y:-95.64233964},
{x:45.50735633,y:-122.42620781},
{x:29.50296386,y:-95.1159583},
{x:36.95860474,y:-76.60265794},
{x:35.03107463,y:-90.02474895},
{x:36.88176285,y:-76.42636746},
{x:36.50381732,y:-82.43129432},
{x:39.95128526,y:-75.16607523},
{x:34.86567176,y:-82.35802136},
{x:33.80836554,y:-117.83545285},
{x:47.6574862,y:-117.3294094},
{x:33.92112708,y:-80.33624913},
{x:30.45642868,y:-97.82560663},
{x:39.28063226,y:-80.27697355},
{x:36.17487904,y:-94.18165773},
{x:40.48110922,y:-78.01707528},
{x:35.451622,y:-97.7241363},
{x:29.62747448,y:-95.49444616},
{x:36.79461179,y:-76.32727325},
{x:34.03539172,y:-117.91253798},
{x:32.43669005,y:-85.04790488},
{x:37.35102119,y:-122.01464027},
{x:30.8381824,y:-97.5991439},
{x:38.06211488,y:-78.93713424},
{x:38.8431968,y:-77.1088353},
{x:37.7497775,y:-81.1379639},
{x:29.6820187,y:-95.42474896},
{x:32.02871325,y:-102.14729778},
{x:33.83713266,y:-118.37063134},
{x:33.07313994,y:-96.87523663},
{x:29.43824122,y:-98.45096764},
{x:32.89291229,y:-97.25838751},
{x:29.28953884,y:-94.78943549},
{x:40.43403909,y:-78.41426523},
{x:33.34955116,y:-111.96728513},
{x:33.10956369,y:-117.10457332},
{x:36.74383943,y:-80.77972873},
{x:36.18588362,y:-86.2968953},
{x:29.53122492,y:-95.80627173},
{x:34.91711549,y:-80.9983856},
{x:44.49581732,y:-89.5155115},
{x:33.01195844,y:-96.61628969},
{x:39.99390783,y:-75.09687223},
{x:39.9174305,y:-76.63022093},
{x:40.0310443,y:-75.17822564},
{x:38.64329506,y:-121.38275332},
{x:38.12208593,y:-122.25108067},
{x:40.89682873,y:-79.90743716},
{x:38.63761794,y:-77.29759102},
{x:38.72453618,y:-77.80406266},
{x:30.53471982,y:-97.78465435},
{x:33.5092112,y:-112.1079853},
{x:26.2414254,y:-98.245545},
{x:47.75556403,y:-122.6603204},
{x:35.91299402,y:-84.58296642},
{x:33.59474926,y:-112.39117633},
{x:36.58514974,y:-79.4262397},
{x:38.03415435,y:-78.45566913},
{x:34.02502845,y:-94.73945082},
{x:34.72836839,y:-82.38966241},
{x:35.16350335,y:-90.18432016},
{x:36.30144,y:-86.70180023},
{x:34.8103605,y:-102.40842277},
{x:47.57977987,y:-122.29971711},
{x:35.25020373,y:-91.71716362},
{x:35.98377201,y:-86.55492178},
{x:32.40934943,y:-99.78103978},
{x:40.04786334,y:-75.05940513},
{x:33.49450843,y:-112.0689556},
{x:40.16487721,y:-80.27453005},
{x:29.18136281,y:-95.43344468},
{x:33.4589753,y:-80.7260451},
{x:32.81037296,y:-97.42266208},
{x:37.70636031,y:-121.92327686},
{x:42.62806173,y:-88.62624593},
{x:33.49297845,y:-81.9550053},
{x:33.03366222,y:-80.06137989},
{x:29.7510018,y:-96.2660215},
{x:39.97890814,y:-82.01699007},
{x:34.471084,y:-117.258189},
{x:41.32716199,y:-105.61034248},
{x:44.27684795,y:-105.49330831},
{x:33.07132154,y:-97.05951092},
{x:32.74016965,y:-97.45136574},
{x:30.88627047,y:-87.78816256},
{x:37.3731712,y:-79.18503317},
{x:32.0729483,y:-96.44508139},
{x:34.34179723,y:-86.30781859},
{x:33.29881197,y:-111.74378604},
{x:29.67965399,y:-95.64295701},
{x:47.05432537,y:-122.77804524},
{x:40.42903788,y:-80.05322061},
{x:40.60780619,y:-79.61015627},
{x:36.21728246,y:-86.3321545},
{x:36.390567,y:-97.896604},
{x:26.1719081,y:-97.6748273},
{x:34.89907166,y:-82.33907193},
{x:38.9675345,y:-77.35650702},
{x:30.35463156,y:-97.75466166},
{x:47.21946792,y:-122.46804357},
{x:36.06092824,y:-89.395817},
{x:35.7326496,y:-84.39120188},
{x:36.70217844,y:-81.97467334},
{x:33.02868421,y:-80.16895947},
{x:44.03010587,y:-88.59164581},
{x:36.8223359,y:-76.15075707},
{x:34.9854049,y:-97.37009704},
{x:29.90119577,y:-95.63402832},
{x:32.459848,y:-86.4532614},
{x:39.13265058,y:-121.63529143},
{x:33.1257657,y:-96.8904904},
{x:33.528682,y:-101.871077},
{x:35.19209131,y:-101.88454859},
{x:29.48999072,y:-98.55183989},
{x:30.00552201,y:-95.27061984},
{x:36.88232388,y:-76.18588731},
{x:31.8099556,y:-106.24734921},
{x:41.3073654,y:-112.02130817},
{x:29.61359912,y:-98.50978679},
{x:33.8321216,y:-118.1620866},
{x:30.06822276,y:-94.13105744},
{x:39.88801483,y:-75.32780975},
{x:34.96843201,y:-80.99467367},
{x:36.7195191,y:-78.11854347},
{x:38.36005601,y:-81.73468844},
{x:35.2643869,y:-101.831009},
{x:39.97878963,y:-75.1191615},
{x:33.44741327,y:-86.82556272},
{x:39.95814057,y:-75.35424896},
{x:34.8592919,y:-82.2636173},
{x:41.7558879,y:-111.8348681},
{x:33.23996844,y:-87.5777505},
{x:39.0255143,y:-77.50869244},
{x:40.7389985,y:-75.2646659},
{x:39.03040982,y:-82.02168815},
{x:41.122621,y:-78.7274197},
{x:40.42543409,y:-79.88798125},
{x:36.3507158,y:-92.3677026},
{x:29.62802122,y:-95.23184851},
{x:34.94037433,y:-82.26224273},
{x:33.32037133,y:-111.97787851},
{x:36.30750313,y:-86.60772979},
{x:33.84118322,y:-117.94130854},
{x:32.9716191,y:-97.3104326},
{x:32.81028093,y:-96.61302713},
{x:34.73405758,y:-80.08904592},
{x:37.0862181,y:-76.3959701},
{x:41.16065136,y:-104.79638793},
{x:26.1874865,y:-97.7210404},
{x:36.18146942,y:-94.5103544},
{x:40.55357312,y:-75.49120552},
{x:33.16763874,y:-86.26346022},
{x:39.90828781,y:-79.74401325},
{x:39.9195329,y:-80.74782327},
{x:40.29669873,y:-111.70069233},
{x:33.19850601,y:-117.37581879},
{x:32.6980506,y:-114.6174841},
{x:34.0847301,y:-81.18764051},
{x:32.79222098,y:-96.70054345},
{x:34.0877046,y:-118.13500963},
{x:34.221861,y:-118.4511029},
{x:39.9158444,y:-75.38508564},
{x:40.07089076,y:-75.44089571},
{x:34.09200497,y:-80.85481994},
{x:36.8089388,y:-76.35673083},
{x:37.41737422,y:-77.50320671},
{x:47.32058872,y:-122.22579047},
{x:38.445971,y:-81.9621151},
{x:29.77821966,y:-95.41105896},
{x:34.26663175,y:-79.70180783},
{x:40.7247758,y:-111.5430929},
{x:30.60880579,y:-96.30690001},
{x:33.22119186,y:-92.66624227},
{x:33.52452106,y:-112.13211626},
{x:34.01611961,y:-117.74472901},
{x:38.67336107,y:-77.26069696},
{x:35.11878574,y:-81.08142637},
{x:32.81472931,y:-96.84014355},
{x:29.54893768,y:-98.40898777},
{x:33.00014505,y:-96.73023204},
{x:40.61211682,y:-79.17643267},
{x:43.06005789,y:-87.91949362},
{x:41.83363229,y:-71.46728618},
{x:43.5398,y:-96.65434},
{x:47.66810224,y:-117.15457432},
{x:35.608786,y:-97.638529},
{x:36.72998062,y:-119.79940921},
{x:34.54110205,y:-82.66774848},
{x:36.17739156,y:-85.94865932},
{x:40.09883324,y:-74.94666852},
{x:37.0188304,y:-120.13172552},
{x:39.83467189,y:-75.44220172},
{x:33.84184441,y:-87.24261231},
{x:40.16969948,y:-76.19806416},
{x:33.3787437,y:-111.924371},
{x:41.21181878,y:-79.38109763},
{x:34.00964806,y:-85.98725296},
{x:40.30923367,y:-79.59761427},
{x:34.88371803,y:-82.45612986},
{x:33.78934854,y:-117.89205819},
{x:43.35921803,y:-124.21356969},
{x:33.73369865,y:-117.81448026},
{x:41.43533931,y:-75.61913051},
{x:35.45983976,y:-86.05416074},
{x:32.9092128,y:-96.8524825},
{x:36.9436691,y:-76.32681079},
{x:32.95110619,y:-96.95854336},
{x:36.92973791,y:-121.76719442},
{x:33.51534505,y:-117.15927467},
{x:33.71760272,y:-112.27316345},
{x:37.96113098,y:-121.74100884},
{x:34.96368048,y:-82.26743071},
{x:31.88209802,y:-106.41959258},
{x:29.49918486,y:-98.31766494},
{x:40.3207656,y:-79.69382912},
{x:33.70261743,y:-117.95383751},
{x:32.32777216,y:-86.34122618},
{x:34.19502949,y:-86.14967801},
{x:31.7874784,y:-106.41076215},
{x:33.79784341,y:-111.97758749},
{x:42.1451686,y:-80.006376},
{x:36.06047353,y:-87.37563376},
{x:32.74158082,y:-96.82248294},
{x:33.62618791,y:-111.89152882},
{x:42.58882902,y:-87.86277831},
{x:40.7456388,y:-111.9001646},
{x:40.32023798,y:-75.30581027},
{x:38.9488759,y:-77.4495757},
{x:40.37430615,y:-111.78894371},
{x:34.7324308,y:-80.7776679},
{x:37.013565,y:-79.867056},
{x:38.47841095,y:-81.67093326},
{x:32.88449085,y:-96.96306221},
{x:42.18513005,y:-122.68645399},
{x:33.7143806,y:-78.9006934},
{x:44.1032193,y:-103.22231931},
{x:35.18488168,y:-89.89584714},
{x:31.55412462,y:-110.28736666},
{x:40.67379573,y:-75.61277011},
{x:33.83760589,y:-79.05955509},
{x:37.0062433,y:-76.4024267},
{x:34.94221245,y:-80.97482398},
{x:38.8960796,y:-77.1318595},
{x:37.66575828,y:-120.99437892},
{x:35.19771,y:-84.8417},
{x:40.27648072,y:-78.86006743},
{x:42.9274411,y:-87.86065104},
{x:36.95229144,y:-81.07372916},
{x:37.50550657,y:-121.97323769},
{x:32.88868668,y:-97.03661858},
{x:35.01781895,y:-85.37930119},
{x:40.63808095,y:-112.02641174},
{x:35.46260984,y:-84.64161055},
{x:45.61804034,y:-122.53012799},
{x:39.8248944,y:-76.9775532},
{x:38.82143228,y:-81.72151991},
{x:30.13384608,y:-94.01657225},
{x:32.97590919,y:-96.7095273},
{x:32.42125733,y:-96.84146494},
{x:30.36563926,y:-95.48178144},
{x:33.30478627,y:-111.97829694},
{x:35.05008458,y:-89.85452101},
{x:33.49463702,y:-112.17167102},
{x:40.54341342,y:-111.98593122},
{x:34.25758374,y:-118.53706223},
{x:32.76417,y:-97.482},
{x:32.734307,y:-96.61417969},
{x:37.51728398,y:-77.35206485},
{x:40.12788811,y:-79.5483166},
{x:37.97687139,y:-82.01689531},
{x:35.0199706,y:-89.83669125},
{x:36.3766186,y:-86.4705103},
{x:32.95276046,y:-80.16362607},
{x:38.929655,y:-78.1931702},
{x:40.64807994,y:-111.86628655},
{x:34.5543943,y:-82.628753},
{x:43.17733153,y:-88.0139821},
{x:40.6234951,y:-111.8655354},
{x:36.08091241,y:-119.03645486},
{x:32.67639866,y:-97.19395204},
{x:33.9584072,y:-86.03816539},
{x:35.6524734,y:-97.5336227},
{x:39.92370048,y:-77.61054584},
{x:29.602589,y:-98.41812527},
{x:41.14275506,y:-80.16054556},
{x:40.3055319,y:-76.9253623},
{x:36.6331934,y:-82.1222309},
{x:34.06418557,y:-78.88151944},
{x:35.38427292,y:-85.38095043},
{x:40.50601833,y:-80.22266109},
{x:38.35913078,y:-81.71761081},
{x:34.514019,y:-82.647631},
{x:37.92738383,y:-122.06243455},
{x:35.40430471,y:-94.52183962},
{x:45.30320866,y:-122.7635479},
{x:38.72147783,y:-77.1245116},
{x:45.401569,y:-122.280359},
{x:33.12713149,y:-96.73043512},
{x:42.71905187,y:-87.83851236},
{x:33.87192547,y:-118.30888554},
{x:39.6517952,y:-79.97026026},
{x:30.23218548,y:-97.81910308},
{x:27.68616072,y:-97.38293723},
{x:32.97138167,y:-96.99323104},
{x:37.81012452,y:-81.18616627},
{x:36.70357788,y:-121.65313751},
{x:35.94847507,y:-86.81929834},
{x:30.48238101,y:-97.65801519},
{x:30.670106,y:-87.85159864},
{x:32.8125506,y:-96.63438879},
{x:32.99468477,y:-96.85581921},
{x:33.73661842,y:-101.84353828},
{x:35.84225445,y:-88.91538277},
{x:26.36845578,y:-98.79495496},
{x:34.20174851,y:-110.01853355},
{x:29.43526678,y:-98.70554253},
{x:39.45434783,y:-77.98875958},
{x:41.16966,y:-79.04602},
{x:32.90332699,y:-97.18993719},
{x:29.55776256,y:-95.12358035},
{x:34.138566,y:-117.4330485},
{x:33.6807357,y:-93.60637218},
{x:47.35791291,y:-122.11896815},
{x:29.94999084,y:-95.41301526},
{x:37.20672894,y:-77.38336615},
{x:45.5774181,y:-122.6811182},
{x:29.77723606,y:-95.12536803},
{x:39.85991192,y:-84.10568706},
{x:41.24472596,y:-111.96949795},
{x:35.92858696,y:-89.87093807},
{x:36.86200299,y:-76.39941797},
{x:29.71501968,y:-95.45396425},
{x:34.43956209,y:-86.94050332},
{x:31.88013646,y:-85.14777623},
{x:29.6250753,y:-95.56431234},
{x:29.9032791,y:-95.55429697},
{x:33.0574209,y:-96.70772329},
{x:35.64358418,y:-97.48214501},
{x:29.393134,y:-100.90438005},
{x:47.19195767,y:-122.4098996},
{x:40.21925502,y:-79.6094424},
{x:40.22168202,y:-75.23235321},
{x:40.2299527,y:-74.9409621},
{x:37.73001733,y:-81.86942317},
{x:37.2330141,y:-77.26324565},
{x:36.83695735,y:-76.13654941},
{x:30.44187185,y:-97.74658553},
{x:31.8464586,y:-86.6401972},
{x:40.09189048,y:-75.36018088},
{x:40.76026679,y:-111.87472075},
{x:32.75495597,y:-117.13349462},
{x:38.24731221,y:-81.5688394},
{x:34.18687261,y:-79.76707605},
{x:35.77747491,y:-86.91463351},
{x:32.91895235,y:-96.5156005},
{x:38.28677913,y:-85.57552927},
{x:30.72809561,y:-88.17209535},
{x:34.1447229,y:-118.1390342},
{x:35.8785325,y:-83.77522789},
{x:29.98516409,y:-95.50398141},
{x:41.77760268,y:-77.07134485},
{x:33.85501642,y:-78.81468415},
{x:29.64980677,y:-95.67992515},
{x:29.69024771,y:-96.77700073},
{x:37.36467442,y:-78.84536326},
{x:41.57424375,y:-109.2467693},
{x:45.39827953,y:-122.61887558},
{x:61.18052048,y:-149.93679017},
{x:35.675064,y:-88.7816925},
{x:30.33369215,y:-97.68706032},
{x:33.56926122,y:-86.51986733},
{x:38.95924194,y:-77.40271166},
{x:36.06114678,y:-95.97351819},
{x:34.77147415,y:-92.39896439},
{x:30.2131218,y:-97.71247186},
{x:44.63362022,y:-123.08536313},
{x:41.79928641,y:-71.44104749},
{x:35.60805733,y:-97.54879698},
{x:43.79719296,y:-90.0510183},
{x:31.62639233,y:-96.27456729},
{x:37.61087098,y:-77.34277502},
{x:41.71126208,y:-112.20011719},
{x:37.7812629,y:-121.97826213},
{x:34.34475592,y:-79.5346048},
{x:40.28608198,y:-79.38506462},
{x:40.74133912,y:-111.82621993},
{x:34.51059,y:-82.68723},
{x:40.08175425,y:-76.76622346},
{x:35.98805,y:-96.01989},
{x:40.33569689,y:-75.95237978},
{x:40.85560324,y:-81.43757008},
{x:40.02940273,y:-80.72869845},
{x:40.7001674,y:-111.8017287},
{x:34.27972653,y:-86.21640317},
{x:34.28228007,y:-81.59072355},
{x:35.652773,y:-88.828302},
{x:44.96078115,y:-92.72760406},
{x:43.08948981,y:-87.94757098},
{x:40.1722994,y:-75.0719495},
{x:32.78809239,y:-116.92897953},
{x:32.64714247,y:-96.86680822},
{x:39.5327675,y:-83.4458634},
{x:35.9246515,y:-84.0549662},
{x:40.15586098,y:-75.86298187},
{x:41.2614417,y:-76.91647947},
{x:29.37505809,y:-98.45572025},
{x:45.45715999,y:-122.713136},
{x:38.3459762,y:-77.4986647},
{x:37.27387503,y:-121.93396546},
{x:40.03438653,y:-75.62664137},
{x:34.07883245,y:-117.35244737},
{x:29.67604843,y:-95.26668198},
{x:34.2041667,y:-82.1943963},
{x:35.09134412,y:-92.43005053},
{x:35.18477039,y:-111.66208826},
{x:33.3814972,y:-111.7399972},
{x:34.19234281,y:-80.28342962},
{x:32.75790409,y:-79.97409413},
{x:39.95621211,y:-75.25823824},
{x:40.08631566,y:-75.0932046},
{x:33.7116374,y:-78.8616047},
{x:36.3331236,y:-119.3936026},
{x:40.85988632,y:-111.89972296},
{x:34.82122635,y:-82.41517693},
{x:35.94363009,y:-83.20867547},
{x:35.28434375,y:-89.67164641},
{x:33.07056802,y:-96.77123911},
{x:32.80280994,y:-117.01201699},
{x:32.70055742,y:-96.27675287},
{x:37.46542967,y:-121.1649473},
{x:34.75568401,y:-92.26086746},
{x:35.28520748,y:-93.13383535},
{x:40.55005759,y:-112.29898435},
{x:32.13458724,y:-110.92481568},
{x:36.7620116,y:-76.0092655},
{x:39.18735536,y:-81.52774796},
{x:47.67449151,y:-122.12786704},
{x:35.97326221,y:-112.12711617},
{x:38.9427751,y:-121.0929677},
{x:37.90526029,y:-76.8662735},
{x:30.05420768,y:-95.18666378},
{x:33.3664848,y:-111.84130311},
{x:37.6804741,y:-113.0791426},
{x:61.11796602,y:-149.86447341},
{x:34.20951416,y:-91.97134048},
{x:41.14140761,y:-112.0649685},
{x:43.00257074,y:-87.96710245},
{x:32.77456097,y:-117.14650266},
{x:33.60107983,y:-111.97977483},
{x:36.32612189,y:-86.70131207},
{x:40.03209576,y:-111.75930881},
{x:37.9802927,y:-78.20907987},
{x:33.00518606,y:-96.97688162},
{x:35.88510149,y:-84.15146403},
{x:40.40024907,y:-80.02410293},
{x:35.08519348,y:-85.31615764},
{x:29.91509699,y:-95.48518181},
{x:36.976344,y:-77.394023},
{x:36.16114658,y:-95.89602113},
{x:35.80641667,y:-90.72146192},
{x:29.65630425,y:-95.51351525},
{x:33.95116202,y:-80.37649155},
{x:40.05990374,y:-80.60097769},
{x:40.44615578,y:-79.8931801},
{x:36.06230029,y:-95.76110736},
{x:33.4199372,y:-110.81216832},
{x:30.0938011,y:-93.7648762},
{x:40.9462558,y:-78.96739304},
{x:28.82755903,y:-97.00155124},
{x:30.16755842,y:-97.79433288},
{x:29.59157948,y:-98.35326639},
{x:46.14516128,y:-122.92635798},
{x:40.24436186,y:-75.6123063},
{x:43.06038908,y:-87.97156066},
{x:37.0835371,y:-80.6895936},
{x:36.01719021,y:-83.82633805},
{x:33.50794831,y:-86.79453224},
{x:38.48071223,y:-77.99376771},
{x:30.2985375,y:-97.7181315},
{x:30.80380581,y:-88.07235178},
{x:37.11030713,y:-113.56161431},
{x:34.893189,y:-82.7108306},
{x:31.6044423,y:-97.1042232},
{x:35.86212243,y:-90.64762253},
{x:36.58332272,y:-79.352556},
{x:38.43054595,y:-78.88369991},
{x:48.10652314,y:-123.4028028},
{x:36.146013,y:-97.051043},
{x:40.88449255,y:-111.88636221},
{x:40.04664798,y:-80.65305009},
{x:31.99981193,y:-102.11029537},
{x:30.07556122,y:-95.51436223},
{x:47.82146743,y:-122.30276483},
{x:33.970683,y:-118.194215},
{x:33.64330455,y:-117.60084502},
{x:41.40062217,y:-78.55895162},
{x:33.01749706,y:-80.08782685},
{x:40.084021,y:-74.965087},
{x:34.5330854,y:-97.966236},
{x:29.51882896,y:-98.63882117},
{x:32.72758213,y:-97.8245262},
{x:36.83653486,y:-76.30828116},
{x:37.24726055,y:-77.3897566},
{x:33.18800694,y:-97.10877499},
{x:29.7806955,y:-98.72936435},
{x:35.7653381,y:-91.6407153},
{x:34.71567114,y:-82.25642502}];