import React, { Component } from 'react';
import AdvancedTable from '../AdvancedTable/AdvancedTable';
import { Image, OverlayTrigger, Popover } from 'react-bootstrap';
import CreateCourseModal from 'components/modals/CreateCourseModal/CreateCourseModal';
import { LoadingError } from '../LoadingError/LoadingError';
import { miscActions, schoolsActions } from 'store/actions/actions';
import coursesService from 'services/courses.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from 'components/modals/ConfirmationModal/DeleteModal';
import { connect } from 'react-redux';
import EmojiPicker from '../EmojiPicker/EmojiPicker';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons';

const tableOptions = {
  hasSearch: true,
  hasInlineEditing: true,
  searchPlaceholder: 'Search Courses...',
};

class CoursesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createCourseModalVisible: false,
      deleteCourseModalVisible: false,
    };

    this.columns = [
      {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'emoji',
        text: 'Emoji',
        align: 'center',
        formatter: this.emojiFormatter,
        editable: false,
        headerStyle: () => {
          return { width: '10%', textAlign: 'center' };
        },
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerStyle: () => {
          return { width: '70%' };
        },
      },
      {
        dataField: 'actions',
        editable: false,
        text: 'Actions',
        formatter: this.getCourseActionFormat,
      },
    ];

    props.dispatch(miscActions.getEmojis());
    this.updateCourse = this.updateCourse.bind(this);
  }

  emojiFormatter = (cell, row) => {
    const popover = (
      <Popover id={0} className="emoji-picker-popover">
        <EmojiPicker
          onSelect={(selected) => {
            this.setEmoji(row, selected);
          }}
        />
      </Popover>
    );

    return (
      <OverlayTrigger
        placement="auto"
        trigger="click"
        rootClose
        delay={{ show: 250, hide: 250 }}
        overlay={popover}
      >
        {row.emoji ? (
          <Image
            src={row.emoji.resources.png.size_urls.large}
            alt={`${row.emoji.name}`}
            width={36}
          />
        ) : (
          <span>
            <FontAwesomeIcon
              className="site-header-nav-icon"
              icon={faSmileBeam}
            />
          </span>
        )}
      </OverlayTrigger>
    );
  };

  getCourseActionFormat = (cell, row) => {
    return (
      <span
        key={row.id}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          this.setState({ deletingCourse: row, deleteCourseModalVisible: true })
        }
      >
        <FontAwesomeIcon className="site-header-nav-icon" icon={faTrashAlt} />
      </span>
    );
  };

  setEmoji(row, selected) {
    const { id, name, school } = row;
    coursesService()
      .updateCourse(id, { name, school, emoji_id: selected.name })
      .then(() => {
        this.reloadResource();
      });
  }

  updateCourse(id, data) {
    coursesService()
      .updateCourse(id, data)
      .then(() => {
        this.reloadResource();
      });
  }

  onCreateNewCourseClickHandler = () => {
    this.setState((state) => ({ ...state, createCourseModalVisible: true }));
  };

  onCreateCourseClosed = () => {
    this.reloadResource();
    this.setState((state) => ({ ...state, createCourseModalVisible: false }));
  };

  onDeleteCourseConfirmation = () => {
    this.reloadResource();
    this.setState((state) => ({
      ...state,
      deleteCourseModalVisible: false,
      deletingCourse: null,
    }));
  };

  reloadResource() {
    const { dispatch, currentSchool } = this.props;
    dispatch(
      schoolsActions.getSchoolResource(
        currentSchool.school_name,
        currentSchool,
        'courses',
      ),
    );
  }

  render() {
    const { currentSchool, loading } = this.props;

    if (currentSchool && currentSchool.errors.has('courses')) {
      return <LoadingError errors={currentSchool.errors} />;
    }

    return (
      <div>
        <CreateCourseModal
          show={this.state.createCourseModalVisible}
          hide={this.onCreateCourseClosed}
          schoolId={currentSchool && currentSchool.school_name}
          currentSchoolPromise={this.props.currentSchoolPromise}
        />

        {this.state.deletingCourse && (
          <DeleteModal
            show={this.state.deleteCourseModalVisible}
            deletingObjectType="course"
            deletedObjectName={this.state.deletingCourse.name}
            hide={this.onDeleteCourseConfirmation}
            onConfirm={() => {
              return coursesService().removeCourse(
                this.state.deletingCourse.id,
              );
            }}
          />
        )}
        <AdvancedTable
          keyField="id"
          itemName="Class"
          options={{
            ...tableOptions,
            onCreateButtonClick: this.onCreateNewCourseClickHandler,
            onRowUpdate: this.updateCourse,
          }}
          data={currentSchool?.courses || []}
          columns={this.columns}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.schools.loadingResource === 'courses',
});

export default connect(mapStateToProps)(CoursesTable);
