import moment from 'moment';

export const HAS_TEXT_VALIDATOR = (text) => {
  return !!text && text.length >= 1;
};

export const LAT_LONG_VALIDATOR = (value) => {
  return !value || /^(-?\d+(\.\d+)?)$/.test(`${value}`);
};

export const SCHOOL_ID_VALIDATOR = (text) => {
  return /^([a-z0-9]+_)*[a-z0-9]+$/.test(text);
};

export const VALID_NUMBER = (number) => {
  return number === '' || number >= 0;
};

export const VALID_NUMBER_ARRAY = (values) => {
  return values.every((value) => value > 0);
};

export const VALID_FREQUENCY_ARRAY = (values) => {
  return values.every((value) => value > 0) || values.length === 0;
};

export const ARRAY_HAS_ELEMENTS_VALIDATOR = (values) => {
  return values.length > 0;
};

export const HAS_FILE = (file) => {
  return !!file;
};

export const VALID_OPTION = (option) => {
  return option !== 0;
};

export const IS_VALID_DATE = (value) => {
  return moment(value).isValid();
};

export const VALID_PHONE_NUMBER = (value) => {
  return /^(\+1){1}[0-9]{10}$/.test(value);
};

export const IS_UNIQUE = (items) => {
  return (value) => {
    return !items.includes(value.toUpperCase());
  };
};

export const LENGTH_VALIDATOR = (max_length) => {
  return (value) => {
    return value.length <= max_length;
  };
};

export const URL_VALIDATOR = (value) => {
  if (value) {
    const url_regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/gi;
    return url_regex.test(value) || value.length === 0;
  }
  return true;
};
