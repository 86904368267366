import { useHistory } from 'react-router-dom';
import * as MN from '@mantine/notifications';
import * as MM from '@mantine/modals';
import * as Sentry from '@sentry/react';

import {
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
} from 'features/scheduleConfiguration/hooks/mutations';
import { useSchool } from 'hooks/useSchool';
import { useSchoolId } from 'hooks/useSchoolId';
import { schema } from 'schema';
import type { ScheduleRequest } from 'types';

import { useScheduleDetailParams } from './useScheduleDetailParams';

import { useWizard } from '../components/scheduleWizard/stepUtils';

export const useScheduleSave = () => {
  const [schedule, clearStore] = useWizard((s) => [
    s.state.schedule,
    s.clearState,
  ]);
  const history = useHistory();
  const schoolId = useSchoolId();
  const routeParams = useScheduleDetailParams();

  const { data } = useSchool((school) => ({
    timezone: school.timezone,
    schedules: school.schedules,
  }));

  const redirect = () => {
    clearStore();
    history.push(`/schools/${schoolId}/schedules`);
  };

  const onSuccess = () => {
    if (!schedule) return;

    MN.showNotification({
      color: 'green',
      message: 'Schedule saved successfully',
    });

    if (!schedule.isUserDraft) {
      redirect();
      return;
    }

    MM.openConfirmModal({
      title: "Don't forget to approve this draft schedule!",
      cancelProps: { hidden: true },
      onConfirm: redirect,
      onClose: redirect,
    });
  };

  const onError = (e: Error) => {
    MN.showNotification({
      color: 'red',
      title: 'Schedule save failed',
      message: 'Server error',
    });
    console.error(e);
    Sentry.captureException(e);
  };

  const createScheduleMut = useCreateScheduleMutation(
    schoolId,
    onSuccess,
    onError,
  );
  const updateScheduleMut = useUpdateScheduleMutation(
    schoolId,
    onSuccess,
    onError,
  );

  const isLoading = createScheduleMut.isLoading || updateScheduleMut.isLoading;

  const save = () => {
    if (!data || !schedule) return;

    // validate
    const parsedScheduleRequest = schema.scheduleRequest.safeParse({
      ...schedule,
      lunchWaves: schedule.lunchWaves || [],
      timezone: data.timezone,
      schoolId,
      isDraft: schedule.isDraft || false,
      isUserDraft: schedule.isUserDraft || false,
    } satisfies Partial<ScheduleRequest>);

    if (!parsedScheduleRequest.success) {
      MN.showNotification({
        color: 'red',
        title: 'Schedule save failed',
        message: "Schedule doesn't match schema",
      });
      // sentry is not capturing whole error, leave this for debugging purposes
      console.error(parsedScheduleRequest.error);
      Sentry.captureException(parsedScheduleRequest.error);
      return;
    }

    const scheduleRequest = parsedScheduleRequest.data;

    // set order property
    if (
      scheduleRequest.variant === 'core' &&
      scheduleRequest.isInAcf &&
      (routeParams.mode === 'copy' || !scheduleRequest.order)
    ) {
      const lastOrder = data.schedules.reduce(
        (acc, curr) =>
          Math.max(acc, (curr.variant === 'core' && curr.order) || 0),
        0,
      );
      scheduleRequest.order = lastOrder + 1;
    } else if (scheduleRequest.variant === 'core' && !scheduleRequest.isInAcf) {
      scheduleRequest.order = undefined;
    }

    // save schedule
    if (routeParams.mode === 'edit') {
      updateScheduleMut.mutate({
        ...scheduleRequest,
        id: routeParams.id,
      });
    } else {
      createScheduleMut.mutate(scheduleRequest);
    }
  };

  return { saveSchedule: save, isLoading };
};
