import { useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import * as M from '@mantine/core';

import type { LunchWave, LunchSlot } from 'types';

import { getLength, formatToTime } from '../../utils';
import { TimeInput } from 'features/components/TimeInput';

interface Props {
  slots: LunchSlot[];
  isAlphabetic: boolean;
  setLunchWaves: React.Dispatch<React.SetStateAction<LunchWave[]>>;
}

const AddLunchWaveConfiguration = ({
  slots,
  isAlphabetic,
  setLunchWaves,
}: Props) => {
  const [selectedSlot, setSelectedSlot] = useState(slots[0]);
  const [timeRange, setTimeRange] = useState<[Date, Date]>([
    dayjs().set('hour', 11).set('minute', 0).toDate(),
    dayjs().set('hour', 11).set('minute', 30).toDate(),
  ]);

  const getSlotName = (slot: LunchSlot) =>
    isAlphabetic ? String.fromCharCode(65 + slot.slot - 1) : String(slot.slot);

  const handleAdd = () => {
    setLunchWaves((prev) => [
      ...prev,
      {
        id: '',
        slot: selectedSlot,
        name: getSlotName(selectedSlot),
        startTime: formatToTime(timeRange[0]),
        endTime: formatToTime(timeRange[1]),
      },
    ]);

    setSelectedSlot((prev) => slots[slots.indexOf(prev) + 1] || slots[0]);

    setTimeRange((prev) => [
      prev[1],
      dayjs(prev[1]).add(getLength(prev), 'minutes').toDate(),
    ]);
  };

  return (
    <Container>
      <Row>
        <M.Select
          label="Lunch wave"
          data={slots.map((s) => ({
            label: getSlotName(s),
            value: s.id,
          }))}
          value={selectedSlot.id}
          onChange={(value) =>
            value && setSelectedSlot(slots.find((s) => s.id === value)!)
          }
        />
        <TimeInput
          label="Start time"
          value={timeRange[0]}
          onChange={(val) => setTimeRange((prev) => [val, prev[1]])}
        />
        <TimeInput
          label="End time"
          value={timeRange[1]}
          onChange={(val) => setTimeRange((prev) => [prev[0], val])}
        />
        <M.Button onClick={handleAdd} disabled={getLength(timeRange) < 1}>
          Add wave
        </M.Button>
      </Row>
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Row = styled(M.Flex)`
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.md};
`;

export default AddLunchWaveConfiguration;
