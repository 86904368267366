export default [{x:26.166813,y:-80.146977},
{x:32.802008,y:-86.583063},
{x:41.788009,y:-72.660622},
{x:39.632538,y:-106.521748},
{x:42.107807,y:-87.974085},
{x:41.842079,y:-71.876108},
{x:44.00843,y:-92.441922},
{x:42.467374,y:-92.320611},
{x:31.23664,y:-89.825453},
{x:40.721162,y:-122.928302},
{x:32.531662,y:-84.97021},
{x:39.695723,y:-78.153784},
{x:38.972901,y:-95.248399},
{x:41.483444,y:-87.446017},
{x:33.475674,y:-81.994236},
{x:35.6748,y:-82.013612},
{x:38.28868,y:-85.72878},
{x:42.66805,y:-83.034134},
{x:41.261725,y:-95.896864},
{x:40.62776,y:-75.581767},
{x:42.475162,y:-70.957372},
{x:30.682947,y:-91.462353},
{x:33.812866,y:-84.633455},
{x:37.991649,y:-122.30183},
{x:41.58959,y:-93.808397},
{x:37.235976,y:-93.260513},
{x:39.248656,y:-76.639255},
{x:30.211172,y:-92.075796},
{x:43.177728,y:-85.279769},
{x:39.086845,y:-84.51974},
{x:42.692517,y:-114.519056},
{x:27.038658,y:-82.223116},
{x:38.740158,y:-104.786061},
{x:37.694962,y:-97.280102},
{x:42.409447,y:-87.854529},
{x:32.51728,y:-92.15756},
{x:34.161943,y:-86.836538},
{x:36.199933,y:-81.65895},
{x:28.51701,y:-81.290214},
{x:34.252885,y:-85.163056},
{x:33.848415,y:-87.266567},
{x:37.709746,y:-121.079334},
{x:33.431861,y:-112.59144},
{x:35.50674,y:-92.02884},
{x:37.965262,y:-121.779875},
{x:41.33285,y:-73.083877},
{x:37.705418,y:-122.128041},
{x:41.561585,y:-73.053232},
{x:33.176923,y:-87.471384},
{x:35.051521,y:-78.864308},
{x:42.007956,y:-88.079629},
{x:30.370893,y:-81.725003},
{x:39.712461,y:-86.121258},
{x:32.432198,y:-81.780061},
{x:46.829304,y:-100.871522},
{x:35.198565,y:-80.867751},
{x:46.591736,y:-111.989112},
{x:33.816658,y:-116.455703},
{x:41.758765,y:-88.243764},
{x:42.244088,y:-83.591223},
{x:30.354506,y:-81.653542},
{x:42.252894,y:-83.194349},
{x:42.548009,y:-70.942065},
{x:38.181256,y:-84.816753},
{x:41.781539,y:-87.868772},
{x:31.21283,y:-81.49208},
{x:42.247987,y:-71.761159},
{x:38.225312,y:-84.534792},
{x:35.720669,y:-81.15588},
{x:41.162366,y:-87.893655},
{x:39.37933,y:-76.502734},
{x:37.98801,y:-121.321957},
{x:37.735708,y:-84.32882},
{x:27.414426,y:-80.393656},
{x:42.54348,y:-83.787403},
{x:44.656712,y:-84.70846},
{x:39.051394,y:-95.762294},
{x:30.265208,y:-87.688991},
{x:30.68429,y:-88.221978},
{x:41.048817,y:-86.219567},
{x:34.496612,y:-85.852125},
{x:42.237982,y:-83.268882},
{x:41.549471,y:-87.24017},
{x:37.961211,y:-122.505201},
{x:34.250123,y:-88.404974},
{x:42.497297,y:-83.045476},
{x:41.289558,y:-87.403213},
{x:35.211586,y:-90.20984},
{x:42.311231,y:-83.389372},
{x:41.639133,y:-70.982078},
{x:39.781489,y:-86.12432},
{x:41.690167,y:-88.068863},
{x:32.554681,y:-93.709995},
{x:38.717652,y:-76.661925},
{x:37.689847,y:-120.95848},
{x:27.210693,y:-81.847543},
{x:35.608079,y:-77.39581},
{x:25.747773,y:-80.31518},
{x:26.52829,y:-81.75267},
{x:31.135522,y:-87.069063},
{x:40.410604,y:-91.398233},
{x:32.220512,y:-110.806286},
{x:31.338513,y:-109.561075},
{x:43.598909,y:-116.193685},
{x:37.678625,y:-97.20367},
{x:35.442865,y:-80.86631},
{x:39.27145,y:-76.47178},
{x:33.382427,y:-111.72134},
{x:27.300514,y:-80.304677},
{x:28.553138,y:-81.336478},
{x:41.543168,y:-87.85052},
{x:42.349155,y:-87.879608},
{x:36.457573,y:-77.65674},
{x:32.89258,y:-84.32648},
{x:39.801318,y:-104.980152},
{x:33.451803,y:-86.978527},
{x:42.60797,y:-83.248759},
{x:27.470578,y:-81.441051},
{x:41.865109,y:-87.791341},
{x:42.447793,y:-83.07728},
{x:41.681524,y:-86.893885},
{x:27.75189,y:-81.801521},
{x:28.326582,y:-82.325877},
{x:36.663719,y:-121.657052},
{x:42.561727,y:-113.793987},
{x:25.626728,y:-80.381141},
{x:37.629581,y:-122.047451},
{x:35.229099,y:-81.333206},
{x:34.773111,y:-86.585888},
{x:34.781191,y:-86.935455},
{x:31.305452,y:-82.243311},
{x:33.942993,y:-84.534044},
{x:36.761895,y:-121.75324},
{x:38.556214,y:-121.335772},
{x:41.628856,y:-87.47913},
{x:42.556898,y:-70.880357},
{x:41.719947,y:-87.735048},
{x:41.448515,y:-91.03095},
{x:34.88505,y:-92.10954},
{x:39.54639,y:-76.10634},
{x:34.224646,y:-77.944348},
{x:29.185794,y:-82.187564},
{x:42.253679,y:-83.26589},
{x:34.75303,y:-92.36726},
{x:31.247729,y:-90.471485},
{x:41.717243,y:-87.818323},
{x:27.938187,y:-82.286694},
{x:33.505114,y:-86.849528},
{x:33.667873,y:-112.099471},
{x:38.985056,y:-76.757782},
{x:38.356325,y:-87.605352},
{x:39.131803,y:-75.528667},
{x:30.004535,y:-90.037566},
{x:42.148422,y:-71.486472},
{x:38.349957,y:-75.601987},
{x:34.847237,y:-86.71156},
{x:64.820966,y:-147.634278},
{x:28.194353,y:-82.524925},
{x:42.825999,y:-84.54188},
{x:41.366237,y:-85.075752},
{x:38.770324,y:-90.21995},
{x:37.635806,y:-91.545187},
{x:45.66962,y:-94.809525},
{x:25.846963,y:-80.241513},
{x:37.707058,y:-89.891848},
{x:32.80837,y:-83.663116},
{x:34.029478,y:-117.628856},
{x:44.962753,y:-92.962509},
{x:33.723085,y:-85.028482},
{x:33.495053,y:-112.099946},
{x:43.665361,y:-116.279073},
{x:33.392698,y:-111.614889},
{x:22.069882,y:-159.319752},
{x:35.826801,y:-90.67801},
{x:38.990628,y:-76.855069},
{x:27.997559,y:-82.582169},
{x:32.207404,y:-110.90062},
{x:33.319717,y:-111.927344},
{x:26.616936,y:-81.769298},
{x:42.522991,y:-82.987462},
{x:35.797794,y:-78.491709},
{x:28.069573,y:-82.42745},
{x:33.158994,y:-84.864887},
{x:38.938922,y:-76.906586},
{x:39.119862,y:-77.18203},
{x:42.347067,y:-88.285304},
{x:41.791515,y:-87.80206},
{x:33.031909,y:-85.06839},
{x:39.37973,y:-94.582649},
{x:37.15079,y:-92.26212},
{x:31.882814,y:-85.145635},
{x:39.40937,y:-86.42372},
{x:34.264992,y:-84.089866},
{x:39.160228,y:-76.680891},
{x:41.549068,y:-95.920941},
{x:33.771793,y:-84.652665},
{x:40.450135,y:-84.97859},
{x:33.92362,y:-84.31411},
{x:34.237897,y:-111.32186},
{x:42.593108,y:-83.445511},
{x:35.809654,y:-90.66955},
{x:34.27498,y:-118.41139},
{x:40.873057,y:-88.661694},
{x:45.553376,y:-93.58724},
{x:45.003355,y:-93.228046},
{x:36.032114,y:-79.959928},
{x:39.796625,y:-75.46555},
{x:26.194329,y:-80.247572},
{x:43.045285,y:-86.219626},
{x:34.092303,y:-83.999801},
{x:39.951741,y:-87.453108},
{x:31.289448,y:-92.475377},
{x:33.260893,y:-84.097389},
{x:41.201852,y:-73.18559},
{x:40.094769,y:-87.635037},
{x:33.580419,y:-112.255399},
{x:34.731186,y:-88.94571},
{x:41.630004,y:-86.23391},
{x:30.517368,y:-89.67172},
{x:35.859536,y:-78.706423},
{x:35.568498,y:-77.351845},
{x:42.188594,y:-88.103009},
{x:41.097683,y:-85.137966},
{x:34.540804,y:-83.541923},
{x:40.203888,y:-87.628249},
{x:33.509809,y:-86.789221},
{x:35.035345,y:-79.015553},
{x:35.370171,y:-80.722789},
{x:30.407663,y:-91.015815},
{x:33.717683,y:-84.766684},
{x:25.858809,y:-80.183043},
{x:32.843288,y:-83.629417},
{x:34.126877,y:-83.219285},
{x:40.046609,y:-86.483759},
{x:33.111809,y:-83.258722},
{x:30.203853,y:-81.740209},
{x:28.033447,y:-80.622582},
{x:36.808574,y:-119.854611},
{x:30.438646,y:-90.0838},
{x:38.889347,y:-94.523707},
{x:38.986862,y:-90.976105},
{x:38.556373,y:-76.596073},
{x:38.589763,y:-90.596195},
{x:29.966168,y:-90.087877},
{x:41.727452,y:-88.319721},
{x:37.788833,y:-90.427279},
{x:29.221559,y:-81.047813},
{x:32.336034,y:-86.179723},
{x:35.399595,y:-80.609104},
{x:36.625115,y:-119.322852},
{x:36.327529,y:-119.649506},
{x:42.356326,y:-71.061924},
{x:40.574936,y:-85.677142},
{x:29.659168,y:-82.408501},
{x:38.034458,y:-84.911387},
{x:42.173804,y:-88.336002},
{x:36.056963,y:-94.185387},
{x:33.661277,y:-114.240788},
{x:37.672875,y:-85.910404},
{x:38.749954,y:-90.463513},
{x:48.847398,y:-95.769213},
{x:37.905398,y:-85.953391},
{x:27.922665,y:-82.317233},
{x:32.162787,y:-110.94163},
{x:38.235637,y:-86.126813},
{x:21.29965,y:-157.83859},
{x:26.165255,y:-80.20452},
{x:33.945168,y:-118.352014},
{x:37.14036,y:-84.100156},
{x:44.207766,y:-69.827873},
{x:26.46613,y:-81.83182},
{x:35.718368,y:-81.467977},
{x:38.474605,y:-90.854736},
{x:36.196245,y:-119.33952},
{x:43.197082,y:-112.358038},
{x:21.972251,y:-159.379229},
{x:45.16639,y:-93.265626},
{x:32.298327,y:-90.210198},
{x:35.24999,y:-91.692836},
{x:42.912429,y:-85.596159},
{x:43.543543,y:-116.163836},
{x:33.708149,y:-117.885866},
{x:38.536047,y:-91.006343},
{x:42.135172,y:-83.26901},
{x:38.68457,y:-85.78217},
{x:33.219467,y:-111.564698},
{x:35.340834,y:-79.402041},
{x:35.449368,y:-81.003691},
{x:36.017275,y:-78.912187},
{x:35.25817,y:-120.691508},
{x:29.230784,y:-81.010578},
{x:39.599812,y:-75.828303},
{x:19.642772,y:-155.994354},
{x:33.80479,y:-118.188911},
{x:27.298264,y:-82.481835},
{x:39.353252,y:-76.75572},
{x:26.212627,y:-80.204782},
{x:38.840628,y:-77.018524},
{x:27.315008,y:-80.392848},
{x:28.470439,y:-81.396135},
{x:41.017423,y:-92.438142},
{x:38.782236,y:-90.387579},
{x:35.504983,y:-78.325102},
{x:42.979223,y:-85.070245},
{x:42.473957,y:-71.023936},
{x:41.48896,y:-90.432448},
{x:27.838879,y:-82.786658},
{x:45.678735,y:-111.063616},
{x:33.407866,y:-82.027736},
{x:40.7579,y:-86.337998},
{x:42.015377,y:-92.912075},
{x:30.372267,y:-88.537772},
{x:42.08953,y:-71.420426},
{x:37.1106,y:-84.090806},
{x:38.141929,y:-85.77384},
{x:33.95793,y:-86.036994},
{x:41.74821,y:-83.625595},
{x:42.553086,y:-82.925431},
{x:44.744871,y:-93.279174},
{x:38.941362,y:-121.096185},
{x:33.507445,y:-82.503304},
{x:25.898498,y:-80.292856},
{x:41.267212,y:-82.609684},
{x:43.017753,y:-71.448311},
{x:37.702824,y:-121.933181},
{x:38.187766,y:-84.562991},
{x:38.556925,y:-90.261104},
{x:41.834321,y:-88.023458},
{x:30.425177,y:-89.091907},
{x:39.614659,y:-86.063681},
{x:41.815001,y:-90.240247},
{x:33.364502,y:-111.653694},
{x:30.441288,y:-88.899497},
{x:34.974218,y:-110.089794},
{x:33.932398,y:-84.222229},
{x:30.449133,y:-91.243429},
{x:26.233001,y:-80.102544},
{x:44.460468,y:-92.283324},
{x:35.245173,y:-90.781758},
{x:34.055404,y:-84.557228},
{x:43.016231,y:-82.44982},
{x:33.916475,y:-118.011374},
{x:34.78914,y:-92.21435},
{x:30.300097,y:-93.19938},
{x:33.588096,y:-85.05474},
{x:41.121728,y:-85.061121},
{x:43.641712,y:-94.993882},
{x:45.287939,y:-93.23157},
{x:26.193678,y:-80.275989},
{x:26.619289,y:-80.146256},
{x:37.365341,y:-121.892268},
{x:33.451949,y:-112.203831},
{x:39.228147,y:-76.814623},
{x:42.428633,y:-89.017341},
{x:38.777435,y:-90.50957},
{x:25.48479,y:-80.461292},
{x:42.949116,y:-85.667133},
{x:37.979927,y:-100.842},
{x:39.673755,y:-104.791698},
{x:31.752249,y:-93.068487},
{x:34.12122,y:-93.08469},
{x:39.491768,y:-76.393341},
{x:42.280872,y:-83.187977},
{x:44.780647,y:-68.741797},
{x:41.907829,y:-87.85301},
{x:33.940004,y:-117.230103},
{x:34.967738,y:-89.794923},
{x:38.972691,y:-84.391809},
{x:33.98805,y:-118.197188},
{x:44.264939,y:-86.314679},
{x:37.837921,y:-85.937281},
{x:41.942201,y:-85.646497},
{x:41.641983,y:-70.887219},
{x:25.759602,y:-80.368166},
{x:41.680828,y:-86.188936},
{x:25.985957,y:-80.206332},
{x:36.362606,y:-77.671677},
{x:39.624466,y:-105.015448},
{x:34.134721,y:-116.096738},
{x:38.652441,y:-94.358432},
{x:41.680895,y:-72.946214},
{x:42.911482,y:-82.497067},
{x:28.357533,y:-80.692777},
{x:34.852788,y:-117.0824},
{x:32.698428,y:-114.613903},
{x:35.84443,y:-77.055352},
{x:41.75596,y:-87.663826},
{x:34.116027,y:-118.184775},
{x:41.830023,y:-94.103129},
{x:34.20424,y:-118.222696},
{x:30.786329,y:-89.865162},
{x:39.73491,y:-75.636632},
{x:33.330413,y:-111.98018},
{x:42.325488,y:-83.061469},
{x:33.973184,y:-118.073037},
{x:30.483075,y:-90.456613},
{x:37.753608,y:-121.468074},
{x:38.833343,y:-76.942503},
{x:21.34241,y:-157.949174},
{x:42.35303,y:-71.13498},
{x:39.116233,y:-94.759396},
{x:40.581694,y:-124.146374},
{x:33.479911,y:-111.935588},
{x:35.254308,y:-80.45485},
{x:32.23516,y:-110.824691},
{x:35.303818,y:-80.751803},
{x:29.591788,y:-90.73483},
{x:35.156012,y:-79.413703},
{x:33.905897,y:-84.270942},
{x:30.243455,y:-92.066731},
{x:30.375444,y:-84.292384},
{x:30.768436,y:-85.684062},
{x:30.587889,y:-84.594485},
{x:33.408005,y:-111.73596},
{x:32.355279,y:-88.689027},
{x:33.434433,y:-112.55564},
{x:30.318488,y:-87.424926},
{x:35.451937,y:-82.525331},
{x:33.494813,y:-112.030205},
{x:38.417145,y:-75.56645},
{x:40.496415,y:-88.952412},
{x:34.24029,y:-84.774806},
{x:37.352731,y:-121.958476},
{x:28.036145,y:-80.666046},
{x:32.63629,y:-117.02099},
{x:38.861379,y:-86.507034},
{x:33.704115,y:-84.115581},
{x:37.249943,y:-121.863564},
{x:33.535833,y:-82.129846},
{x:37.155098,y:-121.64997},
{x:41.159227,y:-87.663612},
{x:44.972107,y:-93.364776},
{x:38.194425,y:-85.806597},
{x:41.722175,y:-88.373449},
{x:26.70731,y:-80.105776},
{x:36.32603,y:-119.331326},
{x:30.196356,y:-81.82494},
{x:36.051104,y:-119.025785},
{x:41.572879,y:-72.639213},
{x:33.378381,y:-111.971027},
{x:37.241104,y:-93.301319},
{x:42.446147,y:-83.165566},
{x:28.338963,y:-81.594361},
{x:39.812334,y:-105.140032},
{x:28.230937,y:-80.720668},
{x:42.998316,y:-84.145659},
{x:39.722838,y:-90.243475},
{x:42.355416,y:-71.612756},
{x:39.117111,y:-94.6697},
{x:38.553218,y:-90.407553},
{x:44.936905,y:-93.388591},
{x:41.652205,y:-70.289564},
{x:38.631878,y:-121.32851},
{x:41.933083,y:-87.64841},
{x:39.89305,y:-86.046638},
{x:25.925694,y:-80.277675},
{x:34.747833,y:-86.776855},
{x:41.360961,y:-72.115694},
{x:38.680882,y:-88.488862},
{x:42.21443,y:-83.544319},
{x:42.265585,y:-71.167672},
{x:34.954339,y:-85.250231},
{x:33.467922,y:-82.079357},
{x:35.659529,y:-81.963963},
{x:32.44564,y:-91.49267},
{x:28.028625,y:-82.393387},
{x:38.485367,y:-90.357724},
{x:42.965939,y:-85.679774},
{x:34.65694,y:-120.457975},
{x:33.113238,y:-84.339418},
{x:39.016562,y:-95.65191},
{x:39.56179,y:-86.158217},
{x:27.904395,y:-81.842073},
{x:44.083935,y:-93.244251},
{x:42.237341,y:-84.341298},
{x:43.613534,y:-84.212919},
{x:41.832132,y:-88.200639},
{x:33.813305,y:-116.48692},
{x:38.974918,y:-76.280245},
{x:42.210295,y:-71.184475},
{x:41.412721,y:-73.457835},
{x:34.176243,y:-84.418341},
{x:41.238319,y:-73.037144},
{x:37.655403,y:-82.270512},
{x:33.247836,y:-111.791998},
{x:37.925643,y:-122.063364},
{x:33.417644,y:-111.934529},
{x:39.014257,y:-94.739664},
{x:40.559934,y:-105.039825},
{x:42.101938,y:-71.067642},
{x:34.991925,y:-118.941608},
{x:34.300874,y:-78.721826},
{x:36.009281,y:-76.954856},
{x:39.17781,y:-76.84368},
{x:33.977981,y:-78.379134},
{x:41.859735,y:-88.063353},
{x:41.766109,y:-72.673225},
{x:36.837375,y:-119.871855},
{x:44.973688,y:-93.224895},
{x:42.18512,y:-112.238389},
{x:21.38769,y:-157.95442},
{x:34.217753,y:-119.039406},
{x:26.244953,y:-80.253875},
{x:38.888124,y:-94.835543},
{x:38.977737,y:-89.098609},
{x:42.741176,y:-84.507352},
{x:40.0423,y:-86.151646},
{x:35.297663,y:-82.437004},
{x:43.662489,y:-116.342149},
{x:41.930555,y:-87.68762},
{x:42.361671,y:-71.23917},
{x:30.68275,y:-92.261696},
{x:37.924955,y:-122.364497},
{x:35.004911,y:-80.556687},
{x:38.621524,y:-94.69229},
{x:39.28437,y:-76.50708},
{x:41.54269,y:-93.64548},
{x:28.034535,y:-82.103808},
{x:33.147916,y:-86.74968},
{x:25.663067,y:-80.416538},
{x:28.427562,y:-81.405351},
{x:33.391624,y:-112.167594},
{x:36.907114,y:-121.752941},
{x:43.548557,y:-116.570304},
{x:42.325171,y:-85.517551},
{x:27.06757,y:-82.157974},
{x:30.6394,y:-88.139458},
{x:39.856966,y:-86.393035},
{x:34.790393,y:-86.971296},
{x:43.220239,y:-85.56936},
{x:39.039843,y:-108.540819},
{x:27.543064,y:-81.810028},
{x:33.494323,y:-112.218612},
{x:42.545737,y:-83.284198},
{x:34.744409,y:-77.3795},
{x:39.317672,y:-76.654239},
{x:27.960898,y:-82.758778},
{x:34.752853,y:-86.637436},
{x:34.010735,y:-118.308616},
{x:30.304344,y:-81.754948},
{x:42.395342,y:-83.482721},
{x:41.296864,y:-72.378317},
{x:42.378022,y:-71.08971},
{x:42.664904,y:-71.30416},
{x:35.318537,y:-119.090867},
{x:39.276707,y:-76.829286},
{x:36.30315,y:-119.314239},
{x:34.475823,y:-114.344799},
{x:37.465794,y:-121.14017},
{x:30.305299,y:-89.826248},
{x:32.383035,y:-86.180644},
{x:43.681359,y:-70.439184},
{x:28.384104,y:-81.505468},
{x:33.45773,y:-90.636185},
{x:32.506191,y:-84.940394},
{x:39.546509,y:-76.313456},
{x:26.165847,y:-80.172754},
{x:35.653216,y:-77.366116},
{x:45.194902,y:-93.233715},
{x:43.235619,y:-86.244985},
{x:33.609882,y:-112.133406},
{x:39.691199,y:-79.103815},
{x:30.882383,y:-84.200683},
{x:41.645122,y:-91.528077},
{x:34.632249,y:-78.618259},
{x:34.50364,y:-93.05332},
{x:33.589769,y:-86.700543},
{x:33.643563,y:-117.743784},
{x:35.623772,y:-117.670348},
{x:34.537533,y:-86.913169},
{x:28.331485,y:-82.665861},
{x:39.080477,y:-94.440633},
{x:35.250571,y:-81.079512},
{x:32.54426,y:-92.63998},
{x:39.724118,y:-121.849842},
{x:30.153294,y:-85.617947},
{x:35.211678,y:-78.063071},
{x:27.243347,y:-80.823486},
{x:38.990825,y:-95.232597},
{x:33.435748,y:-86.0965},
{x:35.074234,y:-78.926893},
{x:37.186817,y:-104.497089},
{x:39.2755,y:-76.641374},
{x:37.093158,y:-84.619009},
{x:42.676891,y:-84.54822},
{x:36.276247,y:-76.977462},
{x:33.178949,y:-111.584542},
{x:32.364323,y:-88.727157},
{x:28.534139,y:-82.512789},
{x:26.958909,y:-82.352676},
{x:38.594471,y:-121.287702},
{x:40.550961,y:-89.607802},
{x:21.281858,y:-157.830317},
{x:34.761049,y:-112.453853},
{x:47.462719,y:-94.868152},
{x:21.435343,y:-158.184573},
{x:37.27166,y:-87.142242},
{x:38.781819,y:-95.186503},
{x:25.683499,y:-80.462869},
{x:42.288683,y:-89.102801},
{x:30.545458,y:-84.228252},
{x:39.652993,y:-104.998441},
{x:39.7632,y:-86.38103},
{x:30.85444,y:-83.951582},
{x:36.3169,y:-78.410338},
{x:39.088478,y:-95.663844},
{x:37.835845,y:-94.327256},
{x:39.678224,y:-104.986992},
{x:41.5551,y:-87.634887},
{x:30.907351,y:-88.597558},
{x:30.800754,y:-92.671844},
{x:39.845899,y:-88.942044},
{x:21.331662,y:-157.920038},
{x:33.7954,y:-111.965772},
{x:30.175015,y:-85.760731},
{x:35.525941,y:-82.954795},
{x:41.936628,y:-88.079772},
{x:33.595386,y:-112.01373},
{x:36.094254,y:-94.153249},
{x:28.472146,y:-80.767353},
{x:28.062328,y:-82.543503},
{x:38.678157,y:-121.264853},
{x:41.578723,y:-90.528204},
{x:41.956844,y:-71.025593},
{x:30.903462,y:-84.55834},
{x:41.408237,y:-85.746279},
{x:38.399583,y:-82.711678},
{x:33.407427,y:-111.995196},
{x:30.622331,y:-87.044166},
{x:44.160358,y:-94.018092},
{x:41.90686,y:-87.89968},
{x:40.07153,y:-88.249228},
{x:25.808663,y:-80.264934},
{x:38.963668,y:-92.287649},
{x:35.638924,y:-78.835123},
{x:29.609919,y:-90.75155},
{x:38.071948,y:-84.478263},
{x:32.46922,y:-90.13076},
{x:42.852324,y:-85.862026},
{x:34.037888,y:-84.304984},
{x:28.246196,y:-81.29633},
{x:37.963881,y:-121.991173},
{x:42.033836,y:-86.514747},
{x:41.944566,y:-91.672588},
{x:38.571544,y:-94.856464},
{x:42.458321,y:-71.13928},
{x:42.124037,y:-71.646018},
{x:41.956002,y:-87.750351},
{x:41.702884,y:-87.526772},
{x:33.801682,y:-84.041844},
{x:26.00733,y:-80.298081},
{x:37.935407,y:-122.356609},
{x:38.991829,y:-94.668064},
{x:30.186633,y:-81.724391},
{x:36.879633,y:-89.576638},
{x:39.176004,y:-89.674741},
{x:38.050092,y:-122.532126},
{x:34.04499,y:-84.695899},
{x:42.913823,y:-85.698277},
{x:42.22935,y:-88.330428},
{x:39.037869,y:-76.678311},
{x:30.413808,y:-86.654039},
{x:41.527968,y:-72.776152},
{x:38.98111,y:-76.528263},
{x:39.652852,y:-86.157651},
{x:39.800488,y:-86.27078},
{x:34.97705,y:-92.02706},
{x:34.574307,y:-111.88252},
{x:31.716294,y:-89.149579},
{x:46.80553,y:-92.066819},
{x:41.709593,y:-88.187755},
{x:31.169668,y:-84.732714},
{x:30.749342,y:-81.570691},
{x:27.30025,y:-81.358968},
{x:38.577486,y:-92.183047},
{x:42.041518,y:-87.698851},
{x:33.306888,y:-111.698321},
{x:34.675812,y:-92.342807},
{x:34.061975,y:-83.992858},
{x:41.050958,y:-85.162753},
{x:32.381738,y:-86.243447},
{x:41.925276,y:-87.663861},
{x:31.868301,y:-89.739867},
{x:42.179503,y:-86.261256},
{x:32.471649,y:-83.742814},
{x:35.411973,y:-94.391421},
{x:41.624935,y:-87.852974},
{x:32.024722,y:-93.339337},
{x:34.273539,y:-84.807689},
{x:26.108771,y:-80.202213},
{x:30.352144,y:-81.549484},
{x:41.753995,y:-88.012347},
{x:35.269853,y:-80.854808},
{x:30.351359,y:-81.597806},
{x:27.265478,y:-80.287642},
{x:30.252825,y:-93.014096},
{x:45.104394,y:-93.456398},
{x:42.136465,y:-83.387052},
{x:31.27062,y:-92.485538},
{x:35.795607,y:-80.937756},
{x:36.772748,y:-119.753956},
{x:36.599285,y:-121.849718},
{x:30.476,y:-90.747788},
{x:32.417086,y:-84.943374},
{x:32.13342,y:-111.000727},
{x:40.079694,y:-104.818575},
{x:39.298308,y:-85.957995},
{x:32.648495,y:-117.063824},
{x:32.133642,y:-110.93396},
{x:38.988284,y:-94.465251},
{x:26.236737,y:-80.204726},
{x:43.009143,y:-83.071075},
{x:30.232462,y:-93.326059},
{x:34.963127,y:-89.991726},
{x:32.187948,y:-110.863638},
{x:34.135727,y:-117.293885},
{x:38.822576,y:-91.138194},
{x:30.581592,y:-84.696709},
{x:44.744279,y:-85.640972},
{x:33.436571,y:-112.048805},
{x:38.810086,y:-90.298276},
{x:42.515761,y:-83.223915},
{x:34.443083,y:-84.915766},
{x:37.290371,y:-121.880687},
{x:34.377909,y:-118.563701},
{x:29.47535,y:-81.18224},
{x:25.79696,y:-80.287367},
{x:42.575154,y:-82.887798},
{x:38.011288,y:-84.457519},
{x:32.846728,y:-85.183956},
{x:42.309469,y:-83.35014},
{x:29.166343,y:-82.168556},
{x:38.330673,y:-85.47219},
{x:31.106784,y:-92.063246},
{x:38.213823,y:-85.589592},
{x:39.690175,y:-77.735417},
{x:37.732113,y:-88.534248},
{x:39.792081,y:-88.271338},
{x:34.425491,y:-117.378301},
{x:32.364775,y:-89.47573},
{x:36.714233,y:-91.875302},
{x:41.471437,y:-90.47692},
{x:46.832711,y:-92.205224},
{x:38.368473,y:-75.57272},
{x:42.811577,y:-86.089707},
{x:43.496396,y:-112.057088},
{x:34.506135,y:-117.400051},
{x:34.477852,y:-87.271584},
{x:34.115262,y:-118.245885},
{x:33.451473,y:-111.703536},
{x:26.60567,y:-81.64897},
{x:37.319844,y:-121.973655},
{x:38.101255,y:-85.871268},
{x:39.171622,y:-76.787465},
{x:39.49369,y:-76.65004},
{x:41.968455,y:-87.690763},
{x:28.79754,y:-82.576567},
{x:41.721999,y:-87.620074},
{x:27.99653,y:-82.371315},
{x:32.870367,y:-83.701096},
{x:40.26572,y:-82.922968},
{x:39.23604,y:-121.038665},
{x:26.753549,y:-80.948772},
{x:33.917037,y:-118.26427},
{x:33.504467,y:-86.806945},
{x:46.760271,y:-92.128725},
{x:26.720882,y:-80.11101},
{x:34.136966,y:-117.196276},
{x:31.963286,y:-83.764834},
{x:37.694393,y:-122.085431},
{x:34.961544,y:-89.856312},
{x:34.332268,y:-78.826227},
{x:29.991139,y:-91.832169},
{x:41.656296,y:-84.999023},
{x:28.56615,y:-81.207543},
{x:37.718178,y:-122.18201},
{x:32.78089,y:-91.90194},
{x:40.941328,y:-87.226375},
{x:36.141092,y:-81.176559},
{x:44.958202,y:-93.073224},
{x:31.562881,y:-91.356496},
{x:30.761293,y:-88.224862},
{x:33.990285,y:-83.722914},
{x:34.216421,y:-118.447844},
{x:41.695796,y:-72.909587},
{x:32.819034,y:-83.697657},
{x:32.292982,y:-90.228281},
{x:38.33945,y:-82.943204},
{x:33.941645,y:-117.454775},
{x:26.094936,y:-80.251899},
{x:41.57761,y:-87.239707},
{x:31.447285,y:-83.53783},
{x:33.809898,y:-118.125583},
{x:30.676787,y:-88.115187},
{x:38.863874,y:-90.079162},
{x:44.800459,y:-93.50857},
{x:26.603397,y:-81.973518},
{x:33.842447,y:-84.504387},
{x:35.838352,y:-78.612841},
{x:29.875035,y:-90.050497},
{x:34.872372,y:-83.964189},
{x:39.779322,y:-86.308368},
{x:21.317683,y:-158.013228},
{x:38.615147,y:-121.538961},
{x:42.241742,y:-87.994375},
{x:46.44959,y:-95.12886},
{x:37.905723,y:-121.221397},
{x:35.108449,y:-84.009843},
{x:33.567571,y:-117.206661},
{x:33.711038,y:-116.237328},
{x:33.465639,y:-112.169399},
{x:33.575237,y:-112.06561},
{x:34.621879,y:-92.499085},
{x:41.403659,y:-94.994219},
{x:35.870781,y:-78.579656},
{x:30.24267,y:-93.258522},
{x:44.237201,y:-85.453686},
{x:34.151014,y:-114.286729},
{x:42.386063,y:-71.009717},
{x:35.286555,y:-77.590177},
{x:34.215163,y:-118.387782},
{x:37.748624,y:-121.141788},
{x:42.267006,y:-89.078783},
{x:41.958581,y:-87.807383},
{x:29.950256,y:-90.179571},
{x:37.053838,y:-88.649062},
{x:38.818407,y:-76.883734},
{x:33.880114,y:-84.467443},
{x:30.473405,y:-91.167949},
{x:32.481786,y:-93.764149},
{x:33.943587,y:-118.243697},
{x:39.91676,y:-85.382949},
{x:34.71882,y:-86.578666},
{x:33.874632,y:-117.888922},
{x:30.219176,y:-93.376883},
{x:27.438181,y:-80.349276},
{x:42.274679,y:-89.631895},
{x:37.733842,y:-89.186285},
{x:33.583847,y:-86.774187},
{x:33.508858,y:-117.127853},
{x:42.701885,y:-83.303365},
{x:42.741971,y:-71.15576},
{x:39.289562,y:-76.72694},
{x:40.538926,y:-85.673207},
{x:38.614372,y:-86.08579},
{x:38.930159,y:-75.432044},
{x:40.072136,y:-86.473636},
{x:34.040342,y:-118.291929},
{x:29.211021,y:-81.022972},
{x:27.639276,y:-80.446017},
{x:38.155574,y:-121.154753},
{x:34.963188,y:-90.041884},
{x:38.873938,y:-104.815747},
{x:33.909321,y:-117.559779},
{x:44.724317,y:-85.635099},
{x:36.392462,y:-78.988841},
{x:33.810062,y:-118.290075},
{x:33.724914,y:-117.989798},
{x:42.599565,y:-83.910366},
{x:27.937122,y:-82.251075},
{x:34.000375,y:-118.084348},
{x:30.375967,y:-89.377769},
{x:35.270493,y:-77.609882},
{x:41.263283,y:-73.133856},
{x:34.073811,y:-117.552528},
{x:39.802855,y:-89.660493},
{x:33.378412,y:-112.101659},
{x:39.926572,y:-86.032591},
{x:39.624578,y:-105.109471},
{x:36.961292,y:-86.465746},
{x:41.773693,y:-72.539838},
{x:41.178536,y:-85.097581},
{x:30.4407,y:-81.762172},
{x:32.367011,y:-90.146944},
{x:38.960486,y:-76.863668},
{x:33.173909,y:-87.525324},
{x:39.02974,y:-76.914554},
{x:41.338908,y:-89.115351},
{x:41.620729,y:-85.89854},
{x:39.695207,y:-86.267898},
{x:42.199411,y:-83.208912},
{x:32.495682,y:-85.019077},
{x:41.889701,y:-87.991456},
{x:37.807962,y:-121.216576},
{x:33.945626,y:-84.624736},
{x:35.725179,y:-78.58214},
{x:25.647295,y:-80.331919},
{x:42.698306,y:-84.56684},
{x:42.004167,y:-88.296198},
{x:42.034843,y:-91.655655},
{x:39.769521,y:-104.809317},
{x:45.1118,y:-95.04213},
{x:28.462316,y:-81.455533},
{x:37.760971,y:-92.11684},
{x:38.285763,y:-104.573905},
{x:34.05332,y:-118.275484},
{x:28.235607,y:-82.350627},
{x:30.27378,y:-92.008525},
{x:30.728098,y:-85.185958},
{x:28.237599,y:-81.559135},
{x:41.661558,y:-86.1348},
{x:30.658986,y:-87.852824},
{x:39.326219,y:-77.345454},
{x:42.319054,y:-83.18781},
{x:30.428583,y:-84.213247},
{x:41.679462,y:-86.251305},
{x:37.592427,y:-120.938222},
{x:33.2371,y:-86.819171},
{x:41.358531,y:-86.313375},
{x:35.37241,y:-94.421887},
{x:37.562964,y:-97.27568},
{x:42.039635,y:-88.280759},
{x:39.159046,y:-108.735767},
{x:39.521794,y:-76.184203},
{x:34.446673,y:-86.945085},
{x:42.023328,y:-71.118702},
{x:39.20013,y:-85.95412},
{x:28.344637,y:-81.366195},
{x:34.592391,y:-83.761818},
{x:43.1705,y:-115.745561},
{x:28.613336,y:-81.434367},
{x:38.579627,y:-95.266522},
{x:44.863709,y:-93.353299},
{x:39.223722,y:-85.89091},
{x:32.473574,y:-93.798793},
{x:26.057198,y:-81.69699},
{x:35.353534,y:-81.089634},
{x:32.469208,y:-85.029584},
{x:38.775365,y:-85.398196},
{x:32.52442,y:-92.07623},
{x:28.89555,y:-82.584453},
{x:33.609411,y:-112.240469},
{x:38.854233,y:-76.909015},
{x:38.252023,y:-85.649707},
{x:41.37472,y:-73.487398},
{x:35.233166,y:-81.169466},
{x:30.005561,y:-90.232288},
{x:36.244803,y:-93.116332},
{x:34.600763,y:-118.148181},
{x:43.153781,y:-86.201172},
{x:41.72649,y:-72.616482},
{x:42.166723,y:-70.889062},
{x:42.395511,y:-86.269725},
{x:30.165044,y:-81.746822},
{x:43.177002,y:-83.765259},
{x:33.654955,y:-112.372071},
{x:36.350386,y:-119.425444},
{x:37.09765,y:-120.21791},
{x:41.576432,y:-87.182512},
{x:28.573166,y:-81.515235},
{x:42.226158,y:-89.027938},
{x:38.646813,y:-75.593962},
{x:37.823574,y:-121.28934},
{x:30.480863,y:-90.414182},
{x:36.107001,y:-80.10184},
{x:33.59601,y:-86.69483},
{x:26.708528,y:-80.150038},
{x:35.129596,y:-78.879382},
{x:41.880767,y:-88.194935},
{x:35.5854,y:-82.601624},
{x:33.607551,y:-112.341182},
{x:45.749857,y:-87.07941},
{x:25.866978,y:-80.301438},
{x:41.67148,y:-86.314058},
{x:25.760663,y:-80.399115},
{x:42.26208,y:-71.10998},
{x:36.493101,y:-79.743383},
{x:38.702154,y:-93.250036},
{x:41.235377,y:-95.879598},
{x:39.115527,y:-84.852141},
{x:34.213041,y:-77.925225},
{x:33.524648,y:-90.204209},
{x:32.191729,y:-110.77197},
{x:42.01167,y:-87.760313},
{x:33.041676,y:-84.975348},
{x:38.677481,y:-121.796525},
{x:33.623722,y:-112.393476},
{x:20.021553,y:-155.666977},
{x:42.972831,y:-82.453144},
{x:39.294658,y:-76.600261},
{x:26.759589,y:-80.089021},
{x:35.518608,y:-77.092275},
{x:42.377508,y:-87.934515},
{x:37.547715,y:-120.9016},
{x:32.618743,y:-83.647177},
{x:28.822667,y:-81.690762},
{x:28.218972,y:-80.603174},
{x:38.299759,y:-85.542911},
{x:26.033101,y:-80.195126},
{x:35.02918,y:-90.789003},
{x:30.155192,y:-92.046925},
{x:33.414356,y:-111.85721},
{x:33.38416,y:-111.684113},
{x:43.395587,y:-84.667277},
{x:41.237299,y:-85.817339},
{x:35.326203,y:-82.466297},
{x:38.729844,y:-93.56583},
{x:31.469324,y:-83.503585},
{x:47.239123,y:-88.453789},
{x:35.664933,y:-80.498768},
{x:42.138807,y:-87.989513},
{x:40.624532,y:-91.374158},
{x:42.51925,y:-71.10278},
{x:42.260694,y:-85.199704},
{x:34.324189,y:-86.502444},
{x:35.124963,y:-77.085663},
{x:42.565029,y:-71.991266},
{x:43.055546,y:-115.859692},
{x:39.603715,y:-75.751191},
{x:34.1686,y:-84.78899},
{x:32.92531,y:-83.734256},
{x:38.191955,y:-120.826186},
{x:38.870747,y:-99.31736},
{x:33.726996,y:-117.82255},
{x:42.033021,y:-91.586489},
{x:33.384492,y:-111.740206},
{x:37.003697,y:-85.924606},
{x:43.611163,y:-84.74731},
{x:33.451855,y:-84.325333},
{x:30.32667,y:-87.6825},
{x:39.077244,y:-84.705991},
{x:30.43391,y:-87.275163},
{x:43.637363,y:-70.332497},
{x:32.593395,y:-83.627783},
{x:44.892909,y:-93.03353},
{x:40.476343,y:-86.111149},
{x:34.61216,y:-86.566215},
{x:30.648003,y:-91.149779},
{x:38.540848,y:-89.871871},
{x:36.661674,y:-86.554482},
{x:43.408127,y:-86.31987},
{x:41.394078,y:-73.51297},
{x:30.675853,y:-88.144021},
{x:32.135585,y:-81.208236},
{x:34.163224,y:-118.44893},
{x:43.662335,y:-116.436453},
{x:34.338959,y:-86.311437},
{x:43.804756,y:-111.811579},
{x:35.224461,y:-80.89797},
{x:45.509655,y:-92.996902},
{x:34.200994,y:-118.384164},
{x:29.618169,y:-82.383844},
{x:39.726094,y:-75.588939},
{x:28.328913,y:-82.187041},
{x:30.438226,y:-90.454422},
{x:36.810089,y:-119.714022},
{x:40.940179,y:-124.101258},
{x:45.012201,y:-93.4638},
{x:39.047244,y:-84.578716},
{x:26.589937,y:-80.146035},
{x:41.785199,y:-80.855766},
{x:30.211708,y:-84.363864},
{x:41.221692,y:-89.925079},
{x:41.275209,y:-72.952882},
{x:33.952961,y:-84.057379},
{x:37.702889,y:-97.335304},
{x:33.56777,y:-112.168389},
{x:33.406803,y:-112.064491},
{x:41.614783,y:-93.646684},
{x:30.285224,y:-89.778058},
{x:34.676558,y:-86.750465},
{x:32.409211,y:-88.699454},
{x:41.715948,y:-71.152273},
{x:41.373516,y:-73.421571},
{x:33.778679,y:-86.431923},
{x:41.814165,y:-86.248303},
{x:39.417251,y:-77.440592},
{x:40.531622,y:-88.996482},
{x:39.2049,y:-122.003805},
{x:31.8756,y:-90.40046},
{x:42.07979,y:-70.946705},
{x:42.119971,y:-88.257314},
{x:39.307509,y:-85.218035},
{x:41.797269,y:-71.331088},
{x:35.465073,y:-94.356136},
{x:32.364701,y:-86.136364},
{x:34.759093,y:-79.470655},
{x:42.845474,y:-70.907143},
{x:36.775747,y:-119.790858},
{x:36.707516,y:-110.252484},
{x:39.266742,y:-93.960902},
{x:43.482416,y:-111.98817},
{x:30.406297,y:-91.229539},
{x:33.943524,y:-118.200916},
{x:41.140718,y:-87.877039},
{x:34.7514,y:-92.3177},
{x:43.980768,y:-84.494305},
{x:39.129555,y:-94.827065},
{x:32.901461,y:-83.687918},
{x:30.54394,y:-87.708908},
{x:44.977706,y:-93.065058},
{x:36.380676,y:-79.662456},
{x:26.008279,y:-80.374039},
{x:25.788546,y:-80.382027},
{x:26.207605,y:-80.23055},
{x:33.859293,y:-118.07203},
{x:44.084337,y:-70.204505},
{x:28.053835,y:-82.333346},
{x:33.26698,y:-93.22607},
{x:28.087642,y:-82.459154},
{x:33.563349,y:-117.647077},
{x:36.162004,y:-109.584463},
{x:41.53693,y:-87.448216},
{x:37.294425,y:-121.832882},
{x:27.687897,y:-80.412707},
{x:39.076648,y:-94.381409},
{x:37.945799,y:-91.781422},
{x:33.712442,y:-112.106624},
{x:33.524289,y:-112.116036},
{x:31.629386,y:-89.554335},
{x:40.175167,y:-85.381325},
{x:27.4952,y:-82.62138},
{x:21.422566,y:-157.747751},
{x:41.600651,y:-93.620822},
{x:35.612633,y:-120.68153},
{x:42.359367,y:-83.444503},
{x:30.416598,y:-91.068512},
{x:43.003759,y:-83.733412},
{x:35.173977,y:-78.998279},
{x:38.527997,y:-121.444673},
{x:42.964285,y:-83.812645},
{x:29.025178,y:-82.161874},
{x:38.705883,y:-121.328028},
{x:42.674444,y:-85.653553},
{x:41.261763,y:-95.86728},
{x:35.390823,y:-119.003418},
{x:41.017616,y:-95.232896},
{x:37.021201,y:-121.568046},
{x:37.744153,y:-122.404065},
{x:41.522814,y:-88.124517},
{x:42.07275,y:-71.042909},
{x:38.771476,y:-93.736189},
{x:43.586254,y:-116.572355},
{x:36.062812,y:-80.303802},
{x:27.019773,y:-80.466046},
{x:39.131482,y:-76.639166},
{x:44.926636,y:-92.971109},
{x:37.83705,y:-122.293553},
{x:30.52057,y:-86.48809},
{x:41.450699,y:-85.245822},
{x:33.124856,y:-89.084338},
{x:44.807533,y:-93.383596},
{x:35.136525,y:-119.446731},
{x:37.721648,y:-97.262645},
{x:26.265902,y:-80.20104},
{x:31.398406,y:-81.4514},
{x:39.855814,y:-86.086149},
{x:31.982251,y:-81.152648},
{x:33.954259,y:-118.1295},
{x:42.450934,y:-82.966398},
{x:32.653029,y:-85.376771},
{x:35.970498,y:-78.894418},
{x:38.860009,y:-84.620478},
{x:30.973673,y:-83.208284},
{x:25.891253,y:-80.211371},
{x:37.348569,y:-108.592703},
{x:34.191,y:-90.573279},
{x:42.121211,y:-88.045072},
{x:38.925112,y:-104.720342},
{x:34.486144,y:-117.28957},
{x:42.271029,y:-85.640645},
{x:34.297686,y:-118.438766},
{x:35.328415,y:-94.421598},
{x:35.500984,y:-82.988493},
{x:40.479787,y:-85.546752},
{x:29.989952,y:-90.0591},
{x:33.538421,y:-112.219836},
{x:37.337578,y:-121.937959},
{x:39.66992,y:-104.865559},
{x:38.215494,y:-85.539503},
{x:38.62525,y:-88.968636},
{x:42.316141,y:-89.075526},
{x:35.921178,y:-80.588903},
{x:39.635223,y:-86.124963},
{x:34.010979,y:-117.737455},
{x:26.725276,y:-81.905059},
{x:34.952288,y:-120.41616},
{x:33.8626,y:-84.597096},
{x:37.369084,y:-89.644101},
{x:32.771417,y:-89.12359},
{x:44.784722,y:-69.73096},
{x:34.882904,y:-116.99223},
{x:35.600362,y:-77.33573},
{x:42.534759,y:-83.105954},
{x:37.948884,y:-122.33248},
{x:34.465269,y:-84.450851},
{x:30.845447,y:-90.157874},
{x:42.74098,y:-84.545999},
{x:41.680034,y:-70.94026},
{x:40.976452,y:-90.363944},
{x:33.615289,y:-84.350291},
{x:33.4649,y:-112.386832},
{x:25.985411,y:-80.162981},
{x:31.566266,y:-84.175795},
{x:39.884615,y:-104.978712},
{x:39.774404,y:-85.991772},
{x:34.798299,y:-86.537108},
{x:34.095049,y:-83.809726},
{x:34.074957,y:-117.890346},
{x:44.67362,y:-85.657053},
{x:40.751298,y:-88.000019},
{x:40.418186,y:-86.827815},
{x:37.742213,y:-88.966014},
{x:42.463457,y:-83.027308},
{x:34.484999,y:-84.944502},
{x:33.827632,y:-84.329595},
{x:31.201474,y:-81.990463},
{x:39.786,y:-104.771996},
{x:34.6664,y:-79.001686},
{x:30.392429,y:-88.658779},
{x:38.074538,y:-83.947609},
{x:37.467587,y:-122.434347},
{x:25.733051,y:-80.351582},
{x:29.968551,y:-91.835229},
{x:33.631282,y:-83.978225},
{x:44.556604,y:-69.630113},
{x:28.053971,y:-81.776419},
{x:33.839346,y:-118.285526},
{x:31.687967,y:-89.135694},
{x:34.098126,y:-118.34423},
{x:39.679029,y:-105.024982},
{x:38.804359,y:-76.978669},
{x:38.300391,y:-88.902771},
{x:34.063057,y:-117.201227},
{x:32.662645,y:-83.740891},
{x:41.910474,y:-87.698501},
{x:43.017579,y:-83.518217},
{x:33.399265,y:-91.033098},
{x:34.064392,y:-84.211944},
{x:33.629748,y:-85.786111},
{x:42.568514,y:-114.459959},
{x:45.244558,y:-93.666608},
{x:30.78823,y:-81.654162},
{x:37.303616,y:-87.510379},
{x:30.281107,y:-82.986979},
{x:33.842284,y:-117.958821},
{x:42.338597,y:-85.180924},
{x:37.695095,y:-97.44407},
{x:40.181094,y:-85.468195},
{x:33.976069,y:-117.357941},
{x:33.69412,y:-84.261347},
{x:41.855256,y:-90.186164},
{x:38.612265,y:-76.927677},
{x:27.945213,y:-82.521904},
{x:42.841335,y:-85.674782},
{x:38.746799,y:-104.74065},
{x:35.375709,y:-118.935026},
{x:42.705823,y:-71.204789},
{x:37.677789,y:-97.389069},
{x:28.663236,y:-81.375256},
{x:41.773874,y:-87.975047},
{x:30.021256,y:-90.25481},
{x:41.997391,y:-87.95886},
{x:31.551835,y:-84.137446},
{x:42.274256,y:-71.752861},
{x:37.87255,y:-84.596353},
{x:38.335923,y:-121.969933},
{x:41.751234,y:-87.61403},
{x:34.798037,y:-84.959974},
{x:42.868973,y:-70.883896},
{x:37.7231,y:-97.366348},
{x:41.72272,y:-91.60741},
{x:40.558669,y:-90.034016},
{x:26.781715,y:-80.294075},
{x:35.257488,y:-79.038863},
{x:34.10022,y:-118.073664},
{x:28.475408,y:-82.486691},
{x:35.754745,y:-78.61288},
{x:34.28828,y:-83.840904},
{x:33.977353,y:-118.37154},
{x:31.190387,y:-85.403057},
{x:39.609966,y:-75.948842},
{x:38.01666,y:-121.942314},
{x:37.339721,y:-92.926055},
{x:31.555152,y:-110.357473},
{x:34.61362,y:-78.97972},
{x:35.767515,y:-78.577164},
{x:44.946315,y:-92.904191},
{x:32.516089,y:-86.21237},
{x:33.333137,y:-86.993316},
{x:45.079782,y:-93.050246},
{x:41.426698,y:-87.751991},
{x:42.335298,y:-72.636968},
{x:36.918053,y:-84.124525},
{x:33.495679,y:-86.878148},
{x:30.266701,y:-81.616773},
{x:42.707336,y:-71.169616},
{x:35.552518,y:-77.406347},
{x:34.022122,y:-118.260771},
{x:39.314537,y:-76.749631},
{x:38.36155,y:-121.971892},
{x:32.484896,y:-86.411951},
{x:41.073622,y:-85.27541},
{x:31.233224,y:-84.200604},
{x:37.77293,y:-120.82968},
{x:28.36333,y:-82.694683},
{x:42.271552,y:-84.4536},
{x:33.530438,y:-86.719102},
{x:30.347708,y:-89.142547},
{x:37.759252,y:-122.251477},
{x:35.132036,y:-118.457404},
{x:44.742383,y:-85.174142},
{x:38.419614,y:-96.216653},
{x:30.795797,y:-86.557736},
{x:33.798054,y:-83.74506},
{x:42.196488,y:-83.300521},
{x:41.734723,y:-87.779288},
{x:39.097529,y:-76.806762},
{x:30.284777,y:-81.982439},
{x:44.627719,y:-70.157546},
{x:30.726869,y:-90.523834},
{x:41.398144,y:-72.853895},
{x:35.069925,y:-92.45969},
{x:33.522737,y:-84.363194},
{x:34.631043,y:-86.048413},
{x:42.589203,y:-114.48051},
{x:41.942461,y:-83.403488},
{x:29.87454,y:-90.11254},
{x:39.106102,y:-76.73421},
{x:41.638606,y:-72.874568},
{x:32.606575,y:-90.066757},
{x:36.517216,y:-76.170315},
{x:40.421169,y:-104.789257},
{x:30.448681,y:-81.704303},
{x:34.025829,y:-118.156009},
{x:38.415114,y:-90.391483},
{x:30.001116,y:-90.132484},
{x:36.354568,y:-94.211758},
{x:38.048223,y:-87.28797},
{x:39.900674,y:-88.954589},
{x:38.760277,y:-90.068111},
{x:31.830054,y:-81.616574},
{x:34.795808,y:-84.996314},
{x:28.082525,y:-81.968369},
{x:41.731477,y:-72.714366},
{x:33.450547,y:-112.272982},
{x:44.86421,y:-93.589892},
{x:31.536074,y:-87.888119},
{x:34.034264,y:-117.974592},
{x:36.604573,y:-119.547575},
{x:39.245386,y:-94.460181},
{x:37.254446,y:-83.198853},
{x:34.103634,y:-117.53157},
{x:39.445372,y:-87.125616},
{x:44.038632,y:-92.461551},
{x:41.764027,y:-88.366781},
{x:33.784927,y:-112.118161},
{x:41.703793,y:-88.120533},
{x:38.495334,y:-121.429159},
{x:39.913643,y:-104.979232},
{x:36.18075,y:-94.514694},
{x:34.64574,y:-86.64536},
{x:39.714362,y:-121.795663},
{x:34.076597,y:-117.551452},
{x:25.685036,y:-80.3123},
{x:41.897901,y:-70.951366},
{x:38.308708,y:-85.844074},
{x:42.318595,y:-89.025618},
{x:42.142663,y:-72.553882},
{x:41.797339,y:-83.691996},
{x:39.994066,y:-86.003169},
{x:38.825975,y:-104.756847},
{x:28.865885,y:-82.009826},
{x:41.55822,y:-87.79205},
{x:26.273949,y:-80.154418},
{x:40.704416,y:-104.999388},
{x:37.370563,y:-121.844336},
{x:37.618301,y:-84.772593},
{x:39.626544,y:-104.793594},
{x:42.2238,y:-85.588546},
{x:25.732228,y:-80.382382},
{x:41.816458,y:-87.834386},
{x:26.072312,y:-80.232728},
{x:47.717966,y:-104.15547},
{x:41.228856,y:-95.852154},
{x:32.945687,y:-112.723902},
{x:38.680006,y:-90.47129},
{x:42.79479,y:-82.73633},
{x:39.339111,y:-76.668651},
{x:39.357375,y:-76.703478},
{x:34.225154,y:-118.535177},
{x:35.476523,y:-81.24102},
{x:41.733167,y:-88.258968},
{x:38.803552,y:-90.700401},
{x:30.43515,y:-88.52698},
{x:44.297484,y:-93.2908},
{x:43.147338,y:-85.709335},
{x:39.093028,y:-76.855351},
{x:40.831686,y:-84.937296},
{x:33.941729,y:-117.259309},
{x:36.284524,y:-94.151145},
{x:38.757214,y:-89.682817},
{x:38.01928,y:-87.57421},
{x:36.072473,y:-76.610307},
{x:34.224006,y:-119.149811},
{x:36.204041,y:-119.10437},
{x:28.473698,y:-81.465226},
{x:35.951162,y:-78.10482},
{x:33.619714,y:-84.382019},
{x:43.651619,y:-94.447955},
{x:30.252766,y:-93.180999},
{x:34.145156,y:-118.760021},
{x:30.454025,y:-91.091539},
{x:38.631618,y:-87.179533},
{x:42.154364,y:-87.80327},
{x:42.412369,y:-88.613441},
{x:42.590081,y:-71.204307},
{x:38.423835,y:-86.938348},
{x:28.938781,y:-81.944541},
{x:42.539332,y:-71.140764},
{x:33.920584,y:-83.515866},
{x:37.967788,y:-84.501796},
{x:30.402017,y:-86.863562},
{x:42.496535,y:-90.720081},
{x:26.687244,y:-80.092535},
{x:37.302535,y:-89.574871},
{x:33.940182,y:-83.982536},
{x:42.661083,y:-83.431817},
{x:26.5476,y:-81.87234},
{x:27.892079,y:-82.729534},
{x:38.243096,y:-122.019129},
{x:42.24982,y:-83.444897},
{x:26.514924,y:-80.076794},
{x:35.505562,y:-80.608463},
{x:34.684449,y:-79.199135},
{x:45.173702,y:-113.887382},
{x:44.78962,y:-93.205811},
{x:34.27765,y:-119.214094},
{x:35.862199,y:-76.732749},
{x:37.665038,y:-97.285875},
{x:41.600759,y:-87.846291},
{x:42.268642,y:-83.271},
{x:42.298193,y:-83.284828},
{x:38.86316,y:-121.9513},
{x:39.824933,y:-84.928092},
{x:34.87119,y:-84.325249},
{x:39.883003,y:-86.24472},
{x:37.550508,y:-122.050394},
{x:27.260914,y:-80.430778},
{x:35.982084,y:-78.539423},
{x:26.068434,y:-80.155357},
{x:35.234058,y:-92.387879},
{x:27.96557,y:-82.401344},
{x:36.056645,y:-79.081429},
{x:41.736458,y:-87.622876},
{x:36.838364,y:-119.753664},
{x:41.653947,y:-88.000089},
{x:37.759756,y:-121.434426},
{x:41.759746,y:-70.662969},
{x:30.103862,y:-81.542053},
{x:33.872521,y:-118.118262},
{x:37.954948,y:-87.541586},
{x:37.319722,y:-120.477172},
{x:34.810027,y:-78.983493},
{x:30.65174,y:-87.91317},
{x:34.0849,y:-117.965246},
{x:33.493599,y:-112.478745},
{x:45.85672,y:-84.739261},
{x:27.015856,y:-82.051343},
{x:36.09544,y:-79.407967},
{x:42.640565,y:-82.975367},
{x:30.834542,y:-82.006471},
{x:28.118996,y:-81.97713},
{x:34.622056,y:-79.048466},
{x:41.456192,y:-85.585951},
{x:30.789204,y:-91.376369},
{x:40.07125,y:-105.20167},
{x:39.851473,y:-86.241115},
{x:42.622706,y:-83.052968},
{x:38.346194,y:-76.460542},
{x:42.040409,y:-87.805322},
{x:39.402604,y:-76.947134},
{x:40.397205,y:-104.735586},
{x:43.446513,y:-93.354585},
{x:41.553143,y:-90.483277},
{x:31.680371,y:-88.645019},
{x:35.172782,y:-80.657264},
{x:33.908953,y:-117.46029},
{x:33.981511,y:-88.478595},
{x:29.688509,y:-82.442845},
{x:33.771796,y:-116.9573},
{x:41.750478,y:-87.702591},
{x:41.895857,y:-87.686149},
{x:25.928416,y:-80.196107},
{x:39.795527,y:-86.044906},
{x:43.467627,y:-85.966523},
{x:34.708883,y:-86.741457},
{x:37.429669,y:-86.880177},
{x:35.136202,y:-90.488098},
{x:35.385399,y:-80.192347},
{x:26.136812,y:-80.161424},
{x:32.566236,y:-92.911987},
{x:37.780216,y:-97.462582},
{x:42.178846,y:-71.872041},
{x:30.24789,y:-81.766954},
{x:38.91813,y:-90.162208},
{x:34.0507,y:-86.872606},
{x:30.420358,y:-91.177866},
{x:42.088331,y:-87.798581},
{x:39.1456,y:-94.55552},
{x:34.019572,y:-118.469721},
{x:39.116322,y:-121.389016},
{x:42.209111,y:-85.891675},
{x:30.456833,y:-90.955227},
{x:39.041884,y:-96.830432},
{x:42.007003,y:-88.046529},
{x:32.484795,y:-84.936507},
{x:35.09209,y:-92.42779},
{x:61.239739,y:-149.806069},
{x:30.419662,y:-86.696938},
{x:45.802305,y:-88.06961},
{x:31.323859,y:-85.832258},
{x:35.979924,y:-77.972803},
{x:43.619101,y:-116.288481},
{x:26.68216,y:-80.244121},
{x:21.331297,y:-157.920011},
{x:39.822975,y:-85.915844},
{x:32.597047,y:-114.703982},
{x:44.763944,y:-85.596073},
{x:32.8633,y:-90.39341},
{x:39.044486,y:-94.414992},
{x:34.58155,y:-92.57281},
{x:41.864768,y:-87.833951},
{x:37.387078,y:-120.737938},
{x:36.730842,y:-119.800167},
{x:42.677517,y:-82.743127},
{x:38.525545,y:-121.371078},
{x:33.856679,y:-118.168086},
{x:34.176479,y:-119.194765},
{x:35.121646,y:-114.587278},
{x:30.268349,y:-81.557845},
{x:26.457354,y:-80.123331},
{x:38.056597,y:-85.540005},
{x:34.062467,y:-117.28217},
{x:39.637216,y:-77.709549},
{x:27.791267,y:-82.732814},
{x:28.304468,y:-81.412426},
{x:37.638226,y:-122.420246},
{x:27.502924,y:-81.490943},
{x:41.639772,y:-88.080552},
{x:33.392513,y:-111.938886},
{x:39.15,y:-75.51},
{x:33.588437,y:-86.123986},
{x:33.39331,y:-112.03009},
{x:41.480251,y:-87.637343},
{x:38.526077,y:-122.786906},
{x:34.192552,y:-117.361971},
{x:34.627417,y:-89.969063},
{x:40.609076,y:-105.076149},
{x:26.272146,y:-81.739465},
{x:34.051167,y:-84.091802},
{x:33.92257,y:-84.17151},
{x:42.761052,y:-84.504761},
{x:30.629883,y:-84.414748},
{x:34.703537,y:-118.151852},
{x:28.23609,y:-81.651395},
{x:37.81709,y:-96.891939},
{x:42.489023,y:-90.664787},
{x:30.469817,y:-83.410825},
{x:28.871233,y:-81.287398},
{x:37.823229,y:-82.819072},
{x:33.436214,y:-112.09949},
{x:41.977119,y:-91.622912},
{x:30.448753,y:-84.30263},
{x:33.581793,y:-112.202294},
{x:35.858347,y:-81.485475},
{x:41.699081,y:-93.044894},
{x:26.617755,y:-80.203917},
{x:32.207371,y:-82.380879},
{x:27.26847,y:-82.45555},
{x:34.11601,y:-117.277436},
{x:45.059427,y:-93.321834},
{x:41.217739,y:-73.213561},
{x:33.578353,y:-84.374198},
{x:42.009582,y:-71.305558},
{x:42.915466,y:-112.466103},
{x:35.064808,y:-79.014421},
{x:41.851427,y:-87.751917},
{x:35.786047,y:-78.871784},
{x:34.010287,y:-117.436921},
{x:39.407998,y:-87.409127},
{x:30.535677,y:-83.870685},
{x:40.126377,y:-87.613032},
{x:35.599284,y:-82.404519},
{x:36.420684,y:-81.46654},
{x:42.140088,y:-72.581615},
{x:33.688165,y:-84.48921},
{x:36.844291,y:-86.91906},
{x:33.320696,y:-111.633986},
{x:42.629086,y:-82.934684},
{x:44.768362,y:-94.13198},
{x:30.621362,y:-81.644822},
{x:42.971987,y:-85.905416},
{x:41.674823,y:-91.566586},
{x:42.868171,y:-85.644652},
{x:39.769605,y:-105.006783},
{x:41.352613,y:-72.059821},
{x:41.506808,y:-87.72482},
{x:41.806949,y:-73.112103},
{x:39.726482,y:-104.847576},
{x:26.07169,y:-80.145253},
{x:32.357422,y:-111.09016},
{x:39.540676,y:-104.771317},
{x:33.565839,y:-112.324076},
{x:41.738323,y:-72.668119},
{x:41.605621,y:-72.70718},
{x:42.472837,y:-82.905208},
{x:35.177726,y:-80.880044},
{x:38.774233,y:-76.060666},
{x:41.64624,y:-88.60393},
{x:48.308682,y:-116.545567},
{x:39.696537,y:-104.824271},
{x:28.167896,y:-82.354273},
{x:35.165676,y:-83.40038},
{x:36.346694,y:-78.389712},
{x:29.697586,y:-91.196225},
{x:25.774045,y:-80.139681},
{x:31.336319,y:-85.710678},
{x:34.07146,y:-83.91408},
{x:32.880002,y:-117.235889},
{x:29.865267,y:-90.000617},
{x:31.710513,y:-83.639662},
{x:36.417821,y:-79.935551},
{x:39.445811,y:-76.813286},
{x:33.581504,y:-112.120724},
{x:36.262844,y:-80.36245},
{x:28.260449,y:-81.43801},
{x:42.097789,y:-72.587681},
{x:25.501772,y:-80.412744},
{x:42.47062,y:-88.095085},
{x:39.363383,y:-77.15936},
{x:34.098221,y:-118.311657},
{x:34.076996,y:-117.37233},
{x:27.838944,y:-82.727881},
{x:37.787937,y:-122.246188},
{x:34.270919,y:-118.777093},
{x:29.214803,y:-82.145459},
{x:33.778721,y:-84.607124},
{x:30.442993,y:-85.049558},
{x:34.965883,y:-80.0604},
{x:42.3197,y:-88.958351},
{x:30.473544,y:-90.863713},
{x:31.26243,y:-92.449248},
{x:33.422827,y:-111.684494},
{x:39.63343,y:-77.411214},
{x:35.412833,y:-119.0575},
{x:39.75336,y:-86.247578},
{x:29.187277,y:-82.106548},
{x:38.20527,y:-85.751333},
{x:41.521812,y:-88.198433},
{x:46.799995,y:-92.128823},
{x:28.141361,y:-82.74023},
{x:33.937065,y:-117.45554},
{x:41.338642,y:-72.934881},
{x:39.763207,y:-89.647351},
{x:41.716917,y:-72.233224},
{x:32.70015,y:-114.649912},
{x:38.761953,y:-90.6994},
{x:39.738095,y:-86.119493},
{x:28.810262,y:-81.272988},
{x:44.450834,y:-95.769431},
{x:42.041086,y:-87.725117},
{x:42.626335,y:-71.269682},
{x:37.826727,y:-85.463593},
{x:41.808784,y:-87.675234},
{x:33.56373,y:-117.71313},
{x:25.925738,y:-80.161649},
{x:33.436188,y:-112.410747},
{x:30.060007,y:-81.878556},
{x:39.849523,y:-104.991197},
{x:34.882321,y:-76.910324},
{x:25.830717,y:-80.266825},
{x:30.485104,y:-84.314041},
{x:26.52798,y:-80.092088},
{x:36.268437,y:-80.85389},
{x:28.124626,y:-81.640255},
{x:42.197116,y:-84.381374},
{x:43.511974,y:-112.014487},
{x:28.654426,y:-81.207902},
{x:41.683843,y:-72.70903},
{x:38.938925,y:-95.260534},
{x:40.808693,y:-91.140741},
{x:39.76232,y:-87.23585},
{x:28.807404,y:-81.334649},
{x:29.646546,y:-81.604009},
{x:41.976759,y:-91.716759},
{x:39.665353,y:-75.60455},
{x:28.335248,y:-81.479274},
{x:28.331786,y:-81.515576},
{x:42.503213,y:-70.921763},
{x:21.33767,y:-158.078893},
{x:41.699663,y:-72.658859},
{x:26.934401,y:-82.310356},
{x:25.961977,y:-80.186598},
{x:37.294549,y:-121.95023},
{x:35.865859,y:-80.071643},
{x:34.846594,y:-87.577285},
{x:32.103463,y:-81.150339},
{x:32.62795,y:-117.087927},
{x:45.316032,y:-95.60082},
{x:38.983153,y:-94.358348},
{x:26.761054,y:-81.439546},
{x:33.37704,y:-112.066621},
{x:42.092946,y:-72.499941},
{x:33.430885,y:-84.178912},
{x:36.857142,y:-120.456607},
{x:39.000839,y:-95.687231},
{x:41.961361,y:-87.942394},
{x:42.210315,y:-70.95958},
{x:33.464747,y:-84.214829},
{x:39.5094,y:-87.38541},
{x:38.892715,y:-104.752238},
{x:36.73539,y:-120.059291},
{x:36.974891,y:-120.07252},
{x:40.330389,y:-94.873281},
{x:42.255906,y:-83.240812},
{x:38.057842,y:-121.351241},
{x:32.555161,y:-83.667393},
{x:38.924056,y:-94.3594},
{x:46.549477,y:-87.47009},
{x:43.42753,y:-83.951545},
{x:38.005134,y:-121.835355},
{x:35.168946,y:-90.189407},
{x:41.315439,y:-72.938456},
{x:41.758715,y:-124.19768},
{x:33.639576,y:-112.16964},
{x:43.032596,y:-83.634409},
{x:33.365,y:-111.602407},
{x:38.014217,y:-121.890918},
{x:37.77321,y:-122.215554},
{x:27.771243,y:-82.681582},
{x:39.731961,y:-105.158344},
{x:36.070164,y:-79.296487},
{x:36.063415,y:-79.563917},
{x:33.945309,y:-118.278816},
{x:37.475601,y:-105.894818},
{x:43.982866,y:-92.461691},
{x:39.022581,y:-94.665386},
{x:31.046575,y:-93.203749},
{x:30.374527,y:-86.238767},
{x:34.353778,y:-82.918514},
{x:36.706467,y:-119.55571},
{x:37.785059,y:-122.407944},
{x:34.660593,y:-118.203987},
{x:41.549804,y:-87.139765},
{x:30.390341,y:-81.67842},
{x:34.93333,y:-90.007281},
{x:33.594539,y:-112.376802},
{x:30.328684,y:-81.730704},
{x:34.419693,y:-117.291587},
{x:41.559657,y:-90.574956},
{x:39.040392,y:-94.639997},
{x:41.939411,y:-87.69929},
{x:42.007373,y:-83.317308},
{x:35.317961,y:-119.030877},
{x:36.313151,y:-119.781789},
{x:35.687527,y:-79.82624},
{x:36.170757,y:-94.139703},
{x:38.793387,y:-104.757885},
{x:35.342509,y:-78.030444},
{x:39.192208,y:-77.244997},
{x:41.697767,y:-71.147289},
{x:31.17919,y:-83.774456},
{x:45.071894,y:-93.264502},
{x:39.783926,y:-94.850918},
{x:26.165941,y:-80.256088},
{x:33.638715,y:-112.332077},
{x:41.289747,y:-86.620196},
{x:37.868439,y:-122.29687},
{x:42.68036,y:-86.175069},
{x:39.24928,y:-94.541095},
{x:33.82353,y:-84.11303},
{x:41.705363,y:-87.797526},
{x:31.319356,y:-86.46253},
{x:38.113487,y:-85.67644},
{x:34.579798,y:-87.021278},
{x:28.053807,y:-82.395233},
{x:29.045939,y:-81.304385},
{x:37.532456,y:-121.958727},
{x:37.652498,y:-97.352868},
{x:33.4838,y:-82.132651},
{x:37.995562,y:-84.54856},
{x:30.45111,y:-91.139445},
{x:43.61758,y:-116.344039},
{x:35.14807,y:-80.830549},
{x:43.410363,y:-70.558418},
{x:39.337912,y:-94.92306},
{x:39.6908,y:-75.580055},
{x:39.037973,y:-87.132151},
{x:42.789882,y:-83.74175},
{x:34.679685,y:-88.560733},
{x:39.696813,y:-104.846703},
{x:33.825318,y:-117.941076},
{x:42.312884,y:-71.03715},
{x:34.009568,y:-85.987946},
{x:35.316951,y:-81.189504},
{x:39.02401,y:-94.198215},
{x:37.076091,y:-85.337523},
{x:39.084211,y:-84.482346},
{x:37.462849,y:-89.229084},
{x:42.380573,y:-87.85555},
{x:32.879228,y:-111.690942},
{x:34.923344,y:-110.145615},
{x:44.918776,y:-93.217152},
{x:28.552301,y:-81.507804},
{x:38.57138,y:-75.56054},
{x:33.858902,y:-117.925573},
{x:30.461599,y:-84.358591},
{x:38.432074,y:-90.972809},
{x:42.533728,y:-71.742943},
{x:41.88383,y:-87.64058},
{x:35.799532,y:-78.569587},
{x:39.726057,y:-91.392855},
{x:46.46249,y:-84.378575},
{x:41.77589,y:-88.314701},
{x:39.755306,y:-105.053692},
{x:41.965395,y:-87.989205},
{x:36.136154,y:-120.360906},
{x:33.10352,y:-117.076187},
{x:28.553369,y:-80.843686},
{x:33.22905,y:-92.66547},
{x:33.66362,y:-94.12069},
{x:34.72157,y:-92.34248},
{x:35.87535,y:-78.85145},
{x:43.437576,y:-112.114194},
{x:41.763579,y:-87.58658},
{x:41.658112,y:-93.500542},
{x:41.320753,y:-72.872746},
{x:41.513898,y:-72.10714},
{x:41.556647,y:-73.416428},
{x:34.893013,y:-79.700979},
{x:32.32911,y:-90.32568},
{x:33.451222,y:-112.135717},
{x:31.207068,y:-82.337812},
{x:37.415445,y:-121.8786},
{x:34.46915,y:-93.05858},
{x:28.748194,y:-81.877782},
{x:39.987382,y:-105.127318},
{x:29.941749,y:-89.962351},
{x:33.261937,y:-86.349687},
{x:35.591879,y:-78.778907},
{x:41.469955,y:-87.57999},
{x:36.92656,y:-121.76522},
{x:36.836846,y:-94.392852},
{x:38.840204,y:-97.6058},
{x:37.93756,y:-121.29618},
{x:45.13401,y:-93.35533},
{x:42.060159,y:-88.316081},
{x:25.738591,y:-80.237607},
{x:35.754998,y:-109.626147},
{x:30.396371,y:-87.684177},
{x:41.97845,y:-88.713234},
{x:41.600323,y:-93.509555},
{x:34.105967,y:-117.398348},
{x:45.279731,y:-92.997765},
{x:38.806226,y:-104.821855},
{x:35.12133,y:-120.609646},
{x:32.541377,y:-93.774637},
{x:37.279216,y:-121.83591},
{x:36.173026,y:-94.191736},
{x:34.779342,y:-92.127786},
{x:38.557158,y:-76.058577},
{x:32.322744,y:-90.876329},
{x:33.599552,y:-112.28045},
{x:39.435475,y:-87.33363},
{x:39.444919,y:-77.396566},
{x:42.488166,y:-71.100012},
{x:33.7235,y:-84.3492},
{x:34.022706,y:-85.25054},
{x:27.790175,y:-82.336626},
{x:25.795186,y:-80.217521},
{x:35.215255,y:-111.597662},
{x:34.142623,y:-118.239233},
{x:25.899389,y:-80.243364},
{x:36.978505,y:-121.965373},
{x:33.409092,y:-84.68982},
{x:34.001935,y:-83.911686},
{x:33.434984,y:-112.305644},
{x:32.937642,y:-111.757346},
{x:34.437349,y:-85.753239},
{x:30.433235,y:-91.057732},
{x:40.458582,y:-90.644088},
{x:36.067822,y:-79.513759},
{x:38.812712,y:-94.50123},
{x:33.771365,y:-84.374549},
{x:38.628309,y:-83.792538},
{x:47.49411,y:-94.907652},
{x:35.30265,y:-78.602449},
{x:41.144019,y:-88.835528},
{x:33.233752,y:-111.686382},
{x:42.806003,y:-86.01803},
{x:41.804124,y:-87.762435},
{x:33.058105,y:-85.028945},
{x:34.238598,y:-87.598303},
{x:38.602964,y:-90.292279},
{x:31.608825,y:-84.20912},
{x:33.748396,y:-116.993114},
{x:42.652209,y:-83.246651},
{x:41.652944,y:-88.125313},
{x:37.797668,y:-85.916278},
{x:26.149797,y:-80.324492},
{x:42.885714,y:-85.783471},
{x:33.993229,y:-117.05638},
{x:42.266338,y:-84.623273},
{x:41.756269,y:-88.281537},
{x:41.930749,y:-72.606413},
{x:43.013352,y:-85.688202},
{x:30.495312,y:-92.44068},
{x:39.630221,y:-78.746347},
{x:42.030469,y:-87.894479},
{x:30.232803,y:-85.580847},
{x:40.764403,y:-95.384878},
{x:39.145061,y:-121.64341},
{x:41.674439,y:-86.002779},
{x:33.512855,y:-82.050691},
{x:33.199937,y:-117.246428},
{x:33.246776,y:-84.265576},
{x:45.005894,y:-93.167105},
{x:41.539506,y:-72.997186},
{x:40.194168,y:-105.101891},
{x:29.937476,y:-90.076405},
{x:33.96008,y:-118.301687},
{x:41.614671,y:-70.928912},
{x:30.247183,y:-82.67696},
{x:61.144334,y:-149.865087},
{x:42.316433,y:-71.796292},
{x:42.674305,y:-71.126958},
{x:39.857675,y:-105.082451},
{x:34.736758,y:-120.535317},
{x:26.137745,y:-80.124738},
{x:41.856124,y:-72.430225},
{x:40.80434,y:-124.15227},
{x:32.697865,y:-117.17003},
{x:42.278836,y:-72.67129},
{x:37.951241,y:-121.9575},
{x:45.813919,y:-108.471576},
{x:30.648283,y:-87.761218},
{x:41.713026,y:-86.18821},
{x:25.592644,y:-80.378304},
{x:41.723307,y:-86.287556},
{x:28.024241,y:-82.148531},
{x:38.646414,y:-76.890535},
{x:27.945498,y:-82.340435},
{x:30.180123,y:-93.586691},
{x:28.12829,y:-82.504453},
{x:35.809886,y:-90.697212},
{x:30.856353,y:-93.289399},
{x:31.725397,y:-93.162173},
{x:28.55057,y:-81.583822},
{x:33.898828,y:-84.243336},
{x:45.013312,y:-93.281529},
{x:40.468449,y:-85.370971},
{x:35.561811,y:-77.060921},
{x:30.26711,y:-90.988127},
{x:44.873235,y:-93.000086},
{x:46.921821,y:-95.054413},
{x:45.595238,y:-94.227066},
{x:41.993166,y:-70.973787},
{x:43.659189,y:-84.246571},
{x:44.114386,y:-70.22369},
{x:34.44817,y:-83.124481},
{x:36.04147,y:-79.773134},
{x:39.77925,y:-104.951332},
{x:34.014094,y:-84.554883},
{x:28.522388,y:-82.239976},
{x:38.524591,y:-90.472684},
{x:45.020163,y:-93.188042},
{x:41.662678,y:-87.660617},
{x:26.89596,y:-82.00899},
{x:33.709006,y:-117.859189},
{x:35.55524,y:-82.850887},
{x:28.626321,y:-81.316923},
{x:41.096632,y:-73.445762},
{x:42.625124,y:-71.363366},
{x:28.354098,y:-81.676049},
{x:39.141825,y:-121.62127},
{x:43.63096,y:-95.594088},
{x:39.87087,y:-85.958007},
{x:34.059253,y:-84.384744},
{x:35.820572,y:-80.238524},
{x:46.601777,y:-112.053691},
{x:38.49565,y:-121.098578},
{x:37.988395,y:-121.243494},
{x:36.230072,y:-89.727393},
{x:37.756189,y:-87.150733},
{x:42.305766,y:-71.080183},
{x:41.430543,y:-87.364732},
{x:40.216205,y:-92.583428},
{x:41.304554,y:-88.159894},
{x:39.403285,y:-79.39904},
{x:46.889468,y:-114.04006},
{x:30.521137,y:-91.154976},
{x:43.409923,y:-70.5611},
{x:30.561012,y:-88.207122},
{x:42.718928,y:-83.415985},
{x:33.610567,y:-112.184922},
{x:40.798823,y:-89.632078},
{x:29.9825,y:-92.131745},
{x:40.726821,y:-89.613125},
{x:42.253292,y:-88.021688},
{x:29.210545,y:-82.065289},
{x:33.603566,y:-83.865764},
{x:35.490777,y:-94.22655},
{x:30.449755,y:-86.626287},
{x:37.745342,y:-122.195633},
{x:32.222301,y:-110.857302},
{x:38.424946,y:-121.390455},
{x:39.769642,y:-89.70739},
{x:37.480366,y:-122.218353},
{x:28.032168,y:-82.425496},
{x:29.998489,y:-81.68262},
{x:34.478771,y:-91.550536},
{x:44.733626,y:-85.59613},
{x:41.495353,y:-87.045653},
{x:39.622419,y:-77.767782},
{x:30.47064,y:-90.918131},
{x:26.43366,y:-81.433},
{x:37.798843,y:-120.998135},
{x:32.62326,y:-88.205711},
{x:20.892901,y:-156.436932},
{x:33.87699,y:-117.586452},
{x:38.529835,y:-90.035605},
{x:40.308646,y:-88.173492},
{x:37.056541,y:-120.851943},
{x:30.244363,y:-92.661907},
{x:38.684378,y:-87.514465},
{x:32.420285,y:-93.705502},
{x:38.13769,y:-85.638673},
{x:44.840131,y:-93.309574},
{x:32.599289,y:-83.58563},
{x:29.666808,y:-82.325341},
{x:38.137402,y:-121.260488},
{x:39.911166,y:-86.217548},
{x:42.491044,y:-96.405412},
{x:34.575673,y:-118.046263},
{x:41.600297,y:-93.778662},
{x:34.289803,y:-92.398402},
{x:27.992287,y:-82.120866},
{x:37.630893,y:-97.370321},
{x:33.974891,y:-118.234933},
{x:41.624448,y:-86.691106},
{x:33.414207,y:-82.135991},
{x:39.711426,y:-86.082112},
{x:41.884874,y:-87.62415},
{x:38.299442,y:-76.515169},
{x:61.577106,y:-149.411776},
{x:30.253378,y:-92.270621},
{x:30.728495,y:-86.568554},
{x:41.816812,y:-72.709551},
{x:32.449182,y:-93.78077},
{x:28.540584,y:-82.402872},
{x:28.105758,y:-81.63617},
{x:37.516286,y:-120.84854},
{x:37.979972,y:-86.160673},
{x:30.140809,y:-91.940212},
{x:27.726892,y:-82.678843},
{x:43.406476,y:-85.802179},
{x:32.32667,y:-86.267631},
{x:45.683529,y:-92.98748},
{x:26.205895,y:-80.155444},
{x:38.349958,y:-122.710139},
{x:33.897167,y:-83.958813},
{x:30.639676,y:-88.191729},
{x:43.407545,y:-85.032572},
{x:28.874855,y:-81.688571},
{x:43.590649,y:-116.285956},
{x:36.83691,y:-119.699583},
{x:37.333967,y:-121.853866},
{x:44.312919,y:-69.795369},
{x:41.195677,y:-73.140553},
{x:30.068082,y:-90.482274},
{x:38.971835,y:-95.302658},
{x:34.564079,y:-112.48649},
{x:32.284339,y:-90.022778},
{x:38.687136,y:-90.359549},
{x:42.475994,y:-83.064605},
{x:42.813201,y:-71.102926},
{x:34.92297,y:-120.43595},
{x:41.77681,y:-87.742451},
{x:41.594126,y:-85.856732},
{x:30.000951,y:-91.799817},
{x:41.76619,y:-89.689324},
{x:33.134477,y:-117.057491},
{x:33.878549,y:-83.118807},
{x:42.653609,y:-84.530691},
{x:42.884764,y:-85.687996},
{x:41.435111,y:-73.412825},
{x:35.736922,y:-77.951108},
{x:39.521888,y:-85.74778},
{x:42.403437,y:-71.234494},
{x:31.529953,y:-83.829917},
{x:41.70596,y:-86.62137},
{x:29.993055,y:-90.182033},
{x:35.739534,y:-78.778816},
{x:34.186949,y:-118.413532},
{x:34.72835,y:-92.22167},
{x:41.376943,y:-87.454928},
{x:42.550902,y:-83.049055},
{x:37.995177,y:-122.031191},
{x:28.927817,y:-82.018751},
{x:30.34271,y:-86.0702},
{x:34.676702,y:-84.478057},
{x:35.59446,y:-82.533871},
{x:33.747065,y:-90.716321},
{x:25.700229,y:-80.366433},
{x:42.286336,y:-71.668323},
{x:38.362499,y:-120.803048},
{x:35.657996,y:-81.229442},
{x:42.005882,y:-83.95972},
{x:36.69497,y:-85.135695},
{x:32.191468,y:-110.84302},
{x:28.482338,y:-81.457376},
{x:42.347547,y:-71.510676},
{x:40.11663,y:-88.22308},
{x:39.715917,y:-86.233987},
{x:40.432255,y:-86.126662},
{x:35.753656,y:-81.283128},
{x:26.06485,y:-81.70064},
{x:39.491282,y:-86.063693},
{x:35.39705,y:-118.970459},
{x:34.699094,y:-86.385112},
{x:37.756363,y:-87.072845},
{x:39.140683,y:-88.566628},
{x:40.142211,y:-88.257269},
{x:27.492025,y:-82.479614},
{x:42.133514,y:-71.10129},
{x:30.319723,y:-81.45918},
{x:34.271252,y:-85.22743},
{x:38.901801,y:-90.059508},
{x:32.380499,y:-86.301036},
{x:43.342228,y:-83.738821},
{x:39.406698,y:-77.387378},
{x:27.465579,y:-82.561924},
{x:37.607775,y:-97.334513},
{x:39.936413,y:-91.358882},
{x:31.333222,y:-92.401581},
{x:39.08873,y:-108.457772},
{x:39.884743,y:-105.023115},
{x:38.510176,y:-90.438801},
{x:39.157115,y:-76.725654},
{x:33.224444,y:-117.332858},
{x:42.441674,y:-83.02498},
{x:33.205289,y:-87.568591},
{x:34.210231,y:-78.027431},
{x:33.284563,y:-111.635617},
{x:44.990988,y:-84.67639},
{x:43.840093,y:-70.442106},
{x:40.149386,y:-85.673673},
{x:33.36455,y:-84.179056},
{x:39.987073,y:-104.803169},
{x:42.13015,y:-71.008499},
{x:30.498397,y:-91.154021},
{x:43.889398,y:-85.523574},
{x:34.753459,y:-77.463376},
{x:31.488509,y:-82.850643},
{x:40.366691,y:-86.86171},
{x:42.483612,y:-91.459597},
{x:37.638002,y:-122.101335},
{x:33.393047,y:-110.766992},
{x:37.987606,y:-87.473564},
{x:42.89865,y:-112.433879},
{x:36.225023,y:-119.335515},
{x:30.108254,y:-85.199907},
{x:45.107657,y:-93.214458},
{x:35.136871,y:-80.93684},
{x:42.9337,y:-85.3413},
{x:39.83352,y:-89.604151},
{x:31.776639,y:-82.349822},
{x:35.213523,y:-80.827836},
{x:42.255031,y:-71.79948},
{x:43.148849,y:-93.234181},
{x:26.271838,y:-80.265896},
{x:39.8422,y:-105.054264},
{x:32.457912,y:-93.66094},
{x:34.062122,y:-117.786966},
{x:36.576689,y:-119.632692},
{x:34.234959,y:-118.561536},
{x:26.121767,y:-80.150163},
{x:39.707573,y:-75.682854},
{x:43.178475,y:-85.553849},
{x:37.998455,y:-85.707507},
{x:39.7893,y:-89.60737},
{x:32.503562,y:-87.825248},
{x:30.402395,y:-88.935484},
{x:34.243584,y:-118.423076},
{x:35.267393,y:-118.914831},
{x:39.168803,y:-94.495487},
{x:46.80249,y:-100.787429},
{x:27.438402,y:-82.452527},
{x:33.45482,y:-88.808375},
{x:43.97262,y:-83.959152},
{x:42.316075,y:-87.865138},
{x:36.059379,y:-90.514672},
{x:42.58241,y:-71.775218},
{x:34.121793,y:-87.990259},
{x:36.907,y:-111.481752},
{x:45.63442,y:-84.483285},
{x:30.412555,y:-84.280962},
{x:33.940303,y:-117.970113},
{x:40.456941,y:-122.294056},
{x:36.913514,y:-93.904887},
{x:35.905725,y:-79.088088},
{x:28.206072,y:-82.666225},
{x:45.018425,y:-92.985175},
{x:43.619644,y:-116.388462},
{x:42.697342,y:-73.112693},
{x:28.757855,y:-81.286638},
{x:42.665695,y:-83.133189},
{x:34.228918,y:-83.868814},
{x:33.856022,y:-84.011531},
{x:39.798317,y:-75.548513},
{x:30.76748,y:-85.204154},
{x:39.580921,y:-104.959409},
{x:42.991677,y:-84.54648},
{x:37.355798,y:-121.818956},
{x:36.329004,y:-121.245405},
{x:38.544533,y:-89.984438},
{x:32.819965,y:-117.173065},
{x:33.56498,y:-86.8963},
{x:39.287326,y:-86.771663},
{x:35.223981,y:-80.940462},
{x:35.929162,y:-89.868748},
{x:37.416225,y:-121.897865},
{x:35.820317,y:-78.591908},
{x:41.842685,y:-87.705074},
{x:33.566138,y:-84.412681},
{x:42.563404,y:-83.086021},
{x:40.115231,y:-84.621934},
{x:33.104797,y:-82.023103},
{x:28.014275,y:-81.915214},
{x:41.056611,y:-85.224445},
{x:27.893926,y:-81.58902},
{x:39.91364,y:-104.922325},
{x:26.676869,y:-80.671319},
{x:40.554652,y:-105.096523},
{x:33.481842,y:-117.096941},
{x:38.149871,y:-85.837712},
{x:36.434691,y:-77.638107},
{x:39.602635,y:-77.72996},
{x:34.275608,y:-85.880744},
{x:46.2642,y:-114.15752},
{x:43.114683,y:-70.729931},
{x:42.629904,y:-71.321258},
{x:42.495719,y:-83.537396},
{x:31.345554,y:-92.518838},
{x:31.348575,y:-89.336691},
{x:34.18779,y:-84.14057},
{x:38.446246,y:-90.218104},
{x:38.80493,y:-90.85542},
{x:33.389191,y:-111.84017},
{x:34.121952,y:-117.574682},
{x:33.348093,y:-83.392753},
{x:45.588299,y:-94.16623},
{x:27.5912,y:-81.514796},
{x:33.962024,y:-84.208086},
{x:30.450642,y:-93.426565},
{x:39.47154,y:-87.37176},
{x:34.787507,y:-84.772714},
{x:44.992214,y:-93.10543},
{x:21.292864,y:-157.822806},
{x:19.699451,y:-155.061654},
{x:28.444164,y:-81.427324},
{x:40.42247,y:-104.725046},
{x:37.882764,y:-90.510961},
{x:42.633916,y:-82.858201},
{x:27.840249,y:-80.487037},
{x:31.780047,y:-81.381585},
{x:25.883911,y:-80.327819},
{x:25.752891,y:-80.262589},
{x:41.895121,y:-87.725475},
{x:34.207462,y:-86.874227},
{x:43.824063,y:-70.319969},
{x:41.165607,y:-85.487617},
{x:28.330319,y:-80.732079},
{x:42.247755,y:-71.004423},
{x:29.159376,y:-82.08728},
{x:26.061806,y:-80.312733},
{x:29.939821,y:-82.111708},
{x:33.465795,y:-112.238503},
{x:39.359802,y:-76.446268},
{x:39.681015,y:-105.119397},
{x:34.715581,y:-87.695999},
{x:38.113101,y:-82.611072},
{x:30.317361,y:-89.346574},
{x:40.58612,y:-122.40088},
{x:45.109985,y:-93.302989},
{x:39.102522,y:-77.076017},
{x:34.982421,y:-80.452256},
{x:28.39876,y:-80.614916},
{x:34.80143,y:-92.43964},
{x:38.801818,y:-90.621165},
{x:38.389935,y:-85.764974},
{x:27.734731,y:-82.430437},
{x:39.113452,y:-76.932232},
{x:38.977752,y:-84.656492},
{x:32.906974,y:-117.173018},
{x:34.04528,y:-94.32902},
{x:39.004672,y:-85.62687},
{x:28.416869,y:-81.242927},
{x:38.964595,y:-92.336131},
{x:41.731426,y:-71.17129},
{x:44.096149,y:-93.507267},
{x:34.02095,y:-84.62397},
{x:37.213081,y:-93.34914},
{x:33.568186,y:-84.539105},
{x:33.924482,y:-118.153013},
{x:48.213998,y:-114.278886},
{x:35.374826,y:-77.952774},
{x:43.088362,y:-83.694795},
{x:43.042892,y:-83.319672},
{x:30.094623,y:-90.982728},
{x:30.401504,y:-88.975755},
{x:41.563158,y:-72.909094},
{x:43.294668,y:-86.202962},
{x:40.453826,y:-86.12854},
{x:35.084871,y:-80.885487},
{x:38.361674,y:-98.792261},
{x:34.059247,y:-118.172444},
{x:34.602856,y:-118.184903},
{x:27.938242,y:-82.506244},
{x:30.002449,y:-82.597064},
{x:33.94579,y:-118.40254},
{x:31.354256,y:-85.328553},
{x:30.895389,y:-83.35856},
{x:39.751539,y:-86.031409},
{x:26.169144,y:-80.285134},
{x:33.129164,y:-117.089471},
{x:28.928294,y:-81.188937},
{x:29.915872,y:-81.407496},
{x:33.885498,y:-84.060846},
{x:38.589514,y:-121.551091},
{x:28.250331,y:-82.728299},
{x:33.954933,y:-86.466424},
{x:44.032311,y:-91.620054},
{x:29.89887,y:-90.14726},
{x:43.480184,y:-83.407473},
{x:27.445722,y:-82.504122},
{x:30.499901,y:-87.221197},
{x:35.704601,y:-82.568619},
{x:44.071183,y:-116.922858},
{x:41.678404,y:-83.598519},
{x:33.376218,y:-91.038862},
{x:32.26922,y:-90.26127},
{x:33.78555,y:-84.407381},
{x:39.707174,y:-104.823805},
{x:37.489321,y:-122.23788},
{x:33.543067,y:-83.207607},
{x:25.094379,y:-80.441479},
{x:29.97266,y:-90.04893},
{x:28.135598,y:-82.02379},
{x:25.82501,y:-80.187026},
{x:41.752332,y:-72.715233},
{x:33.306993,y:-111.807637},
{x:38.524173,y:-121.498324},
{x:39.764753,y:-86.291693},
{x:29.978938,y:-90.225243},
{x:41.869256,y:-87.935689},
{x:28.188753,y:-82.400783},
{x:38.651951,y:-90.264515},
{x:32.518784,y:-84.95616},
{x:34.946776,y:-92.061195},
{x:34.70988,y:-92.40912},
{x:28.518806,y:-81.311051},
{x:28.538859,y:-81.150284},
{x:41.90967,y:-87.749612},
{x:43.47941,y:-83.967988},
{x:30.579779,y:-87.295916},
{x:34.256577,y:-118.587844},
{x:42.025812,y:-88.338354},
{x:42.045543,y:-88.038346},
{x:39.576855,y:-76.345131},
{x:42.242298,y:-88.97621},
{x:42.426623,y:-83.334606},
{x:34.30944,y:-77.877759},
{x:34.259288,y:-92.099782},
{x:30.175585,y:-85.683651},
{x:41.837101,y:-87.66515},
{x:33.538624,y:-112.154268},
{x:36.962766,y:-122.043256},
{x:41.838688,y:-87.74337},
{x:41.851768,y:-87.688865},
{x:33.668046,y:-85.827136},
{x:42.325519,y:-83.32049},
{x:42.192899,y:-72.576693},
{x:33.439416,y:-82.013137},
{x:27.995626,y:-82.430465},
{x:34.15096,y:-84.171886},
{x:25.938109,y:-80.245098},
{x:41.442253,y:-85.987067},
{x:41.924029,y:-87.785745},
{x:34.086186,y:-84.519642},
{x:26.196077,y:-80.111509},
{x:30.319151,y:-90.977225},
{x:27.767597,y:-80.512146},
{x:30.498952,y:-90.107022},
{x:42.223832,y:-83.384808},
{x:38.214243,y:-90.401818},
{x:33.34759,y:-111.85942},
{x:32.270842,y:-86.356225},
{x:25.733218,y:-80.308381},
{x:34.760574,y:-84.995801},
{x:33.040382,y:-83.938202},
{x:38.725072,y:-90.122886},
{x:34.132468,y:-116.384054},
{x:46.568056,y:-96.08411},
{x:42.910746,y:-85.568012},
{x:34.070755,y:-117.370713},
{x:27.259319,y:-80.374999},
{x:48.183746,y:-117.03809},
{x:30.430116,y:-81.660943},
{x:42.110633,y:-111.876348},
{x:42.261333,y:-85.589534},
{x:27.520247,y:-82.560681},
{x:41.380614,y:-88.421631},
{x:36.500828,y:-81.114677},
{x:42.341779,y:-88.004152},
{x:39.948223,y:-86.349397},
{x:41.49202,y:-87.504699},
{x:35.423506,y:-83.445848},
{x:42.284855,y:-83.837736},
{x:34.179407,y:-118.319698},
{x:33.294073,y:-83.962064},
{x:34.805482,y:-91.891852},
{x:37.93661,y:-121.697113},
{x:33.624654,y:-111.89323},
{x:32.583957,y:-117.108777},
{x:34.515063,y:-87.71777},
{x:33.970907,y:-83.41295},
{x:39.498807,y:-77.568622},
{x:33.610301,y:-111.728745},
{x:29.829249,y:-81.376728},
{x:39.297534,y:-76.5629},
{x:35.581218,y:-77.591565},
{x:26.211111,y:-81.74082},
{x:34.675861,y:-118.156241},
{x:34.48838,y:-93.08904},
{x:39.398874,y:-123.349131},
{x:34.201463,y:-118.595134},
{x:42.261458,y:-87.947897},
{x:28.426937,y:-81.301367},
{x:44.836382,y:-69.289656},
{x:39.170956,y:-76.62091},
{x:33.90132,y:-118.309435},
{x:27.894223,y:-82.271103},
{x:39.630588,y:-75.66288},
{x:27.938042,y:-81.973202},
{x:35.47452,y:-120.654855},
{x:38.935689,y:-76.948312},
{x:42.368844,y:-71.039328},
{x:34.97718,y:-79.2356},
{x:34.922445,y:-88.515334},
{x:41.038801,y:-85.134448},
{x:37.162113,y:-83.766916},
{x:47.741633,y:-122.639922},
{x:41.518237,y:-87.982544},
{x:33.913444,y:-117.865016},
{x:41.883172,y:-71.065719},
{x:25.772123,y:-80.234764},
{x:38.727108,y:-88.094676},
{x:36.329302,y:-94.126267},
{x:37.902335,y:-122.303964},
{x:33.150807,y:-85.35836},
{x:41.775855,y:-87.703141},
{x:34.033387,y:-117.593782},
{x:38.115053,y:-121.281792},
{x:42.285837,y:-71.608129},
{x:36.626135,y:-88.314136},
{x:38.331752,y:-85.81923},
{x:37.279365,y:-107.878988},
{x:34.77236,y:-92.40059},
{x:30.519805,y:-90.500859},
{x:33.699996,y:-116.191146},
{x:41.762167,y:-87.945754},
{x:33.42901,y:-94.04293},
{x:47.715618,y:-116.908231},
{x:29.92007,y:-90.01176},
{x:39.144312,y:-76.809495},
{x:39.780005,y:-104.846671},
{x:37.999631,y:-97.333692},
{x:28.558598,y:-81.821174},
{x:33.95979,y:-118.379628},
{x:45.552892,y:-94.198713},
{x:41.794083,y:-87.62518},
{x:38.488852,y:-85.770572},
{x:25.622353,y:-80.34378},
{x:36.545636,y:-119.401357},
{x:39.161917,y:-75.512845},
{x:31.852569,y:-86.64167},
{x:42.169946,y:-83.189829},
{x:38.592944,y:-89.978855},
{x:26.148136,y:-80.31496},
{x:33.240032,y:-87.594379},
{x:27.97448,y:-82.055206},
{x:42.30256,y:-71.402406},
{x:35.06934,y:-91.911441},
{x:35.473964,y:-83.315303},
{x:35.336446,y:-82.446328},
{x:32.16516,y:-81.23137},
{x:39.244303,y:-76.676346},
{x:38.353561,y:-90.151257},
{x:33.679691,y:-112.27869},
{x:31.311385,y:-89.311527},
{x:34.920893,y:-117.925418},
{x:33.833864,y:-83.885489},
{x:43.679495,y:-92.980168},
{x:33.760151,y:-116.297166},
{x:26.301095,y:-80.133603},
{x:40.453205,y:-86.922533},
{x:35.12554,y:-78.984167},
{x:33.415471,y:-111.551843},
{x:33.930625,y:-84.26386},
{x:27.38881,y:-82.504883},
{x:45.31133,y:-93.559323},
{x:28.361808,y:-81.421888},
{x:37.665489,y:-122.117131},
{x:28.578791,y:-81.475846},
{x:41.826224,y:-72.491164},
{x:31.970429,y:-110.313621},
{x:33.337677,y:-111.755137},
{x:34.15321,y:-83.65172},
{x:35.914969,y:-81.524475},
{x:42.080793,y:-86.485086},
{x:45.403732,y:-84.609737},
{x:33.74932,y:-84.4048},
{x:45.109384,y:-93.38753},
{x:26.100016,y:-80.136962},
{x:41.749126,y:-71.211375},
{x:28.996497,y:-81.303792},
{x:46.814114,y:-95.83515},
{x:35.352232,y:-83.2012},
{x:29.810489,y:-85.302389},
{x:37.677063,y:-122.467569},
{x:36.354965,y:-92.355012},
{x:34.105822,y:-117.668815},
{x:61.215344,y:-149.813105},
{x:26.352024,y:-80.086976},
{x:34.107736,y:-83.703257},
{x:38.428394,y:-122.741982},
{x:33.8033,y:-84.19288},
{x:39.025752,y:-94.249188},
{x:33.803367,y:-117.226786},
{x:37.502309,y:-82.534967},
{x:42.130802,y:-72.625495},
{x:34.70373,y:-77.154852},
{x:40.763776,y:-86.075127},
{x:37.745639,y:-122.420267},
{x:42.262408,y:-84.423083},
{x:45.97523,y:-94.34411},
{x:27.862836,y:-82.499701},
{x:33.22024,y:-111.63355},
{x:37.342496,y:-85.365259},
{x:34.177528,y:-83.91301},
{x:38.49277,y:-76.774312},
{x:42.012143,y:-95.356018},
{x:42.345387,y:-85.23228},
{x:33.487054,y:-112.36324},
{x:33.693234,y:-117.336849},
{x:34.907633,y:-85.128063},
{x:34.427393,y:-117.573031},
{x:41.434595,y:-86.892827},
{x:34.235146,y:-118.5035},
{x:36.941547,y:-120.056399},
{x:39.151592,y:-123.193638},
{x:34.144445,y:-84.250931},
{x:25.813405,y:-80.134295},
{x:40.086357,y:-104.97719},
{x:40.813352,y:-85.835681},
{x:33.604948,y:-114.605638},
{x:37.05456,y:-88.569375},
{x:25.730226,y:-80.416012},
{x:35.209659,y:-80.757263},
{x:26.011676,y:-80.153411},
{x:30.235477,y:-90.910511},
{x:41.136139,y:-85.162303},
{x:26.032489,y:-80.142936},
{x:35.966167,y:-78.961484},
{x:26.060044,y:-80.207581},
{x:37.963412,y:-121.273228},
{x:44.855991,y:-93.242209},
{x:31.70256,y:-87.776729},
{x:28.190559,y:-82.61625},
{x:34.363161,y:-89.510743},
{x:38.732002,y:-89.90391},
{x:39.155552,y:-76.516913},
{x:34.089912,y:-117.925154},
{x:37.609705,y:-120.971672},
{x:33.75957,y:-117.837333},
{x:33.771922,y:-118.193306},
{x:25.446581,y:-80.475516},
{x:34.365841,y:-84.038701},
{x:41.601024,y:-87.791331},
{x:38.884322,y:-94.722035},
{x:36.057237,y:-79.888226},
{x:38.527944,y:-76.98215},
{x:41.579303,y:-88.162729},
{x:39.179695,y:-77.267195},
{x:33.451321,y:-112.358717},
{x:32.64381,y:-85.347194},
{x:29.714375,y:-85.00512},
{x:29.641194,y:-81.664909},
{x:38.627337,y:-77.073547},
{x:39.054858,y:-94.612289},
{x:40.219387,y:-85.377933},
{x:37.783037,y:-122.421149},
{x:42.764535,y:-83.240886},
{x:27.157194,y:-80.220821},
{x:37.699437,y:-121.904794},
{x:37.654196,y:-122.432753},
{x:33.375146,y:-117.251721},
{x:32.375795,y:-84.923351},
{x:37.638607,y:-120.930601},
{x:39.640892,y:-78.910336},
{x:38.93723,y:-104.759003},
{x:39.032127,y:-122.925839},
{x:33.480091,y:-112.047353},
{x:35.660547,y:-80.461951},
{x:39.320649,y:-76.626209},
{x:41.526459,y:-87.470556},
{x:41.520464,y:-87.648326},
{x:33.85096,y:-84.21448},
{x:39.778723,y:-94.80387},
{x:28.055315,y:-81.989567},
{x:39.556334,y:-79.351756},
{x:35.278238,y:-81.539232},
{x:42.390267,y:-71.150245},
{x:33.8929,y:-84.75739},
{x:38.469916,y:-90.305585},
{x:40.278553,y:-86.484889},
{x:42.379567,y:-88.088423},
{x:46.869235,y:-113.985344},
{x:39.740784,y:-105.067816},
{x:43.482445,y:-70.469953},
{x:38.273764,y:-122.034614},
{x:30.841421,y:-83.324404},
{x:25.779335,y:-80.255728},
{x:39.099987,y:-121.617897},
{x:38.221481,y:-122.126862},
{x:35.790634,y:-80.859651},
{x:39.808075,y:-85.77035},
{x:32.302752,y:-81.87312},
{x:39.285686,y:-75.59506},
{x:41.930445,y:-87.879455},
{x:41.600528,y:-87.694094},
{x:31.134166,y:-83.439074},
{x:32.199561,y:-81.19737},
{x:38.14341,y:-85.868354},
{x:33.782265,y:-118.029699},
{x:33.705554,y:-84.16935},
{x:32.742617,y:-117.047826},
{x:35.601554,y:-119.34924},
{x:30.401986,y:-87.318732},
{x:41.917924,y:-72.260848},
{x:39.07783,y:-108.544703},
{x:45.813288,y:-108.413114},
{x:41.693509,y:-70.15084},
{x:38.3666,y:-93.753788},
{x:38.946881,y:-91.940104},
{x:45.755466,y:-108.569399},
{x:31.33419,y:-110.943699},
{x:29.681773,y:-91.269292},
{x:32.25041,y:-110.9797},
{x:35.924987,y:-79.982512},
{x:32.495546,y:-93.745964},
{x:41.766737,y:-72.709497},
{x:33.946408,y:-116.498443},
{x:32.527859,y:-93.701802},
{x:39.226339,y:-94.767417},
{x:37.351861,y:-121.999765},
{x:30.181382,y:-92.070732},
{x:38.874769,y:-104.720693},
{x:35.861027,y:-90.649965},
{x:38.756452,y:-104.802919},
{x:26.048246,y:-80.160343},
{x:29.975912,y:-81.47449},
{x:30.234294,y:-92.384794},
{x:30.380897,y:-89.093234},
{x:45.181938,y:-93.318749},
{x:19.504033,y:-154.955491},
{x:42.40199,y:-71.09863},
{x:40.625324,y:-103.238164},
{x:34.073669,y:-118.023446},
{x:29.682041,y:-90.783107},
{x:33.974541,y:-118.282543},
{x:37.129033,y:-121.637299},
{x:35.915091,y:-82.299379},
{x:44.71567,y:-92.846844},
{x:40.144141,y:-105.127423},
{x:32.621603,y:-93.277696},
{x:34.999379,y:-78.898994},
{x:31.021658,y:-87.494346},
{x:32.921191,y:-85.958985},
{x:32.588985,y:-82.326437},
{x:46.38622,y:-116.983495},
{x:30.120027,y:-81.704014},
{x:42.251505,y:-84.383302},
{x:39.769617,y:-105.080891},
{x:33.40704,y:-111.908266},
{x:38.598037,y:-121.421251},
{x:38.235455,y:-104.660718},
{x:32.180569,y:-83.181407},
{x:25.811144,y:-80.381895},
{x:41.706005,y:-88.303399},
{x:34.47587,y:-110.08787},
{x:38.810436,y:-94.890652},
{x:34.495818,y:-89.032888},
{x:32.856956,y:-83.617613},
{x:35.14961,y:-80.89698},
{x:25.911889,y:-80.330642},
{x:39.360368,y:-121.687244},
{x:34.984489,y:-78.965044},
{x:33.394051,y:-111.78775},
{x:31.400628,y:-109.918922},
{x:35.777497,y:-78.678016},
{x:41.370528,y:-72.917944},
{x:32.22089,y:-90.15498},
{x:20.890877,y:-156.467522},
{x:36.47837,y:-80.618097},
{x:30.512183,y:-91.029175},
{x:44.784294,y:-93.259093},
{x:33.435577,y:-111.82267},
{x:35.784117,y:-80.259696},
{x:39.927237,y:-122.197917},
{x:39.64663,y:-86.82883},
{x:37.664864,y:-97.576042},
{x:30.785063,y:-83.571744},
{x:27.448176,y:-82.547032},
{x:37.753682,y:-100.025251},
{x:41.276314,y:-72.835503},
{x:38.269231,y:-121.947656},
{x:38.729042,y:-90.38463},
{x:31.228062,y:-85.431437},
{x:33.065554,y:-89.582381},
{x:33.682742,y:-84.000338},
{x:39.61706,y:-86.35507},
{x:44.817729,y:-92.933166},
{x:34.843536,y:-87.667571},
{x:46.356437,y:-94.222207},
{x:30.311606,y:-89.768385},
{x:28.900494,y:-82.445335},
{x:28.947889,y:-80.884499},
{x:38.3633,y:-90.981214},
{x:39.43625,y:-76.62515},
{x:33.270473,y:-84.28992},
{x:42.862737,y:-84.886328},
{x:33.959911,y:-118.253607},
{x:31.360944,y:-92.415107},
{x:33.668588,y:-112.1346},
{x:35.390881,y:-78.819169},
{x:38.409188,y:-121.384149},
{x:35.588256,y:-80.826989},
{x:42.018602,y:-87.675716},
{x:39.156227,y:-122.147057},
{x:34.945979,y:-86.572407},
{x:43.590174,y:-116.241354},
{x:28.304483,y:-81.458781},
{x:43.623426,y:-83.897841},
{x:33.902075,y:-118.046335},
{x:32.431111,y:-87.035091},
{x:38.494795,y:-121.523502},
{x:43.190198,y:-86.263347},
{x:27.999265,y:-81.974365},
{x:35.355704,y:-119.093768},
{x:26.618687,y:-80.057537},
{x:41.069625,y:-85.044023},
{x:32.35052,y:-90.17513},
{x:29.196823,y:-81.057464},
{x:37.538809,y:-84.652498},
{x:38.791459,y:-97.613483},
{x:33.79254,y:-84.27559},
{x:35.968995,y:-78.49316},
{x:41.848384,y:-87.724293},
{x:43.662292,y:-116.663421},
{x:28.450399,y:-81.471468},
{x:42.65426,y:-83.576481},
{x:28.066208,y:-82.738296},
{x:39.051662,y:-95.699544},
{x:35.018341,y:-114.598685},
{x:41.769791,y:-88.147702},
{x:27.916101,y:-82.749576},
{x:33.528705,y:-84.662463},
{x:38.707015,y:-121.27136},
{x:32.80596,y:-116.971509},
{x:38.020652,y:-121.301285},
{x:33.980581,y:-84.558357},
{x:36.029433,y:-80.261275},
{x:34.3775,y:-84.91474},
{x:33.41576,y:-111.633246},
{x:25.946518,y:-80.205739},
{x:44.250607,y:-84.225107},
{x:36.721765,y:-84.169249},
{x:33.479706,y:-111.987564},
{x:28.948193,y:-81.24627},
{x:30.60334,y:-87.15233},
{x:42.669861,y:-82.995296},
{x:30.325338,y:-81.547437},
{x:36.20257,y:-121.129726},
{x:36.649056,y:-87.43826},
{x:39.73962,y:-104.810259},
{x:36.806298,y:-89.970664},
{x:34.000742,y:-118.160317},
{x:34.74565,y:-86.684445},
{x:34.874111,y:-85.81185},
{x:42.612802,y:-82.97219},
{x:28.385835,y:-81.405351},
{x:29.152273,y:-80.969963},
{x:41.938561,y:-87.722786},
{x:29.802877,y:-82.517522},
{x:37.596299,y:-93.403333},
{x:44.121769,y:-70.202345},
{x:35.828736,y:-78.769986},
{x:37.434772,y:-94.705311},
{x:38.275558,y:-122.668243},
{x:33.750648,y:-117.867702},
{x:33.910545,y:-84.806848},
{x:33.39448,y:-84.76163},
{x:33.969801,y:-112.720728},
{x:32.371026,y:-86.270443},
{x:33.321222,y:-111.841194},
{x:33.362257,y:-86.999124},
{x:38.316234,y:-122.298382},
{x:33.638958,y:-112.09451},
{x:42.521012,y:-82.898391},
{x:34.07179,y:-117.395837},
{x:44.747091,y:-85.526212},
{x:41.555442,y:-87.356671},
{x:36.035942,y:-78.892789},
{x:34.173289,y:-92.006123},
{x:32.761989,y:-117.065667},
{x:34.727671,y:-76.771835},
{x:27.01094,y:-82.14009},
{x:32.86264,y:-116.922459},
{x:39.024576,y:-104.816079},
{x:42.504652,y:-84.655984},
{x:42.985651,y:-85.698636},
{x:42.763115,y:-71.040179},
{x:21.494104,y:-158.060757},
{x:39.787473,y:-86.216094},
{x:35.131452,y:-79.025258},
{x:39.705392,y:-104.79023},
{x:39.749261,y:-122.202941},
{x:46.89866,y:-102.790598},
{x:26.062091,y:-80.347216},
{x:39.790137,y:-104.904063},
{x:36.35822,y:-119.65484},
{x:32.48761,y:-92.08407},
{x:37.637928,y:-120.7735},
{x:39.019751,y:-84.628691},
{x:36.121034,y:-80.659614},
{x:41.459341,y:-87.067578},
{x:43.234259,y:-86.208318},
{x:39.78453,y:-105.026283},
{x:36.080886,y:-119.041459},
{x:44.27469,y:-83.507911},
{x:33.988507,y:-118.330704},
{x:33.941281,y:-84.249395},
{x:33.278726,y:-87.098904},
{x:41.107237,y:-73.548222},
{x:33.713437,y:-112.141762},
{x:37.648998,y:-121.030411},
{x:61.252153,y:-149.691912},
{x:33.422892,y:-111.772165},
{x:40.70834,y:-89.594574},
{x:33.666374,y:-112.202537},
{x:31.261142,y:-90.473608},
{x:42.562367,y:-83.129078},
{x:25.685545,y:-80.422665},
{x:36.980847,y:-122.007528},
{x:42.30917,y:-71.057966},
{x:34.258932,y:-83.465516},
{x:37.763847,y:-86.44145},
{x:38.000237,y:-87.493},
{x:32.404681,y:-93.802339},
{x:37.569417,y:-84.311027},
{x:42.455205,y:-87.824687},
{x:28.056667,y:-81.818039},
{x:38.643175,y:-121.190289},
{x:31.604841,y:-81.90727},
{x:25.698745,y:-80.437729},
{x:39.822514,y:-86.282434},
{x:25.954572,y:-80.261226},
{x:32.505759,y:-84.973278},
{x:36.69598,y:-121.59692},
{x:35.333534,y:-81.89098},
{x:39.309863,y:-76.637156},
{x:21.494018,y:-158.060645},
{x:29.890968,y:-90.409296},
{x:35.557726,y:-81.063663},
{x:34.139992,y:-118.0072},
{x:42.503322,y:-83.196597},
{x:30.211116,y:-90.942142},
{x:36.704956,y:-121.653328},
{x:34.741106,y:-86.599928},
{x:34.048798,y:-117.946572},
{x:41.720759,y:-87.66525},
{x:35.217937,y:-114.009299},
{x:42.268468,y:-71.09547},
{x:38.480356,y:-82.643399},
{x:25.75031,y:-80.243567},
{x:43.654394,y:-93.341869},
{x:33.608632,y:-85.827368},
{x:37.78526,y:-122.433273},
{x:30.336276,y:-81.75862},
{x:38.761444,y:-121.255481},
{x:37.997661,y:-121.731054},
{x:33.419195,y:-82.080248},
{x:33.486577,y:-89.75782},
{x:26.18043,y:-81.70771},
{x:34.39066,y:-92.829426},
{x:27.141922,y:-82.459851},
{x:26.634124,y:-80.174846},
{x:32.571418,y:-85.500058},
{x:39.332368,y:-77.63794},
{x:28.209171,y:-82.74013},
{x:31.049599,y:-85.899007},
{x:37.597867,y:-122.065118},
{x:42.187163,y:-72.630743},
{x:37.767595,y:-122.178484},
{x:25.809773,y:-80.208113},
{x:38.676668,y:-90.253529},
{x:32.357275,y:-90.012101},
{x:33.177345,y:-117.297775},
{x:33.99555,y:-117.71943},
{x:37.553512,y:-85.734567},
{x:35.725989,y:-81.207638},
{x:38.413242,y:-87.76721},
{x:28.146642,y:-81.447462},
{x:37.979545,y:-100.88416},
{x:36.330425,y:-78.447255},
{x:36.856062,y:-83.867652},
{x:40.676722,y:-89.56105},
{x:40.018659,y:-105.257799},
{x:33.585508,y:-88.658668},
{x:33.359374,y:-84.751068},
{x:28.873153,y:-82.090238},
{x:30.280461,y:-91.227236},
{x:30.312697,y:-81.695717},
{x:26.66564,y:-81.83104},
{x:38.452344,y:-122.770497},
{x:30.293314,y:-91.914453},
{x:41.076533,y:-85.134901},
{x:41.242949,y:-95.827019},
{x:42.450349,y:-85.661745},
{x:41.598155,y:-93.716535},
{x:33.78018,y:-84.239365},
{x:25.517574,y:-80.427878},
{x:39.808671,y:-86.218952},
{x:41.723098,y:-85.973526},
{x:38.458347,y:-121.840597},
{x:35.27812,y:-93.1156},
{x:41.68122,y:-87.501165},
{x:39.831487,y:-84.843867},
{x:35.919588,y:-78.95989},
{x:33.610379,y:-112.420052},
{x:33.895763,y:-118.220621},
{x:38.5302,y:-89.131682},
{x:41.958549,y:-83.669751},
{x:40.626913,y:-89.463899},
{x:39.103234,y:-96.818362},
{x:34.732431,y:-87.66744},
{x:42.338953,y:-71.051113},
{x:33.218309,y:-111.874916},
{x:41.90542,y:-87.963871},
{x:41.646394,y:-95.335543},
{x:39.651922,y:-104.809788},
{x:37.378103,y:-121.939964},
{x:38.728532,y:-90.276981},
{x:34.168846,y:-118.597214},
{x:33.020079,y:-111.388683},
{x:26.354202,y:-80.203866},
{x:43.818022,y:-70.319707},
{x:41.552216,y:-93.596321},
{x:36.299798,y:-78.582368},
{x:36.048765,y:-79.835131},
{x:37.15961,y:-93.296294},
{x:33.507196,y:-84.140512},
{x:41.568108,y:-73.006249},
{x:33.681702,y:-84.407953},
{x:33.507093,y:-112.324436},
{x:42.67416,y:-82.830993},
{x:41.825633,y:-87.68559},
{x:34.72375,y:-86.627079},
{x:35.820847,y:-78.329521},
{x:35.070545,y:-78.965182},
{x:31.861011,y:-82.603521},
{x:34.134904,y:-118.150315},
{x:33.559552,y:-83.476358},
{x:33.941348,y:-83.469195},
{x:41.58951,y:-93.5963},
{x:42.872589,y:-112.462498},
{x:30.46168,y:-87.299421},
{x:38.436382,y:-89.533756},
{x:35.91859,y:-80.057823},
{x:42.566554,y:-84.828706},
{x:33.277927,y:-111.85791},
{x:28.647395,y:-81.272117},
{x:37.313218,y:-89.551462},
{x:35.006522,y:-78.815528},
{x:44.120109,y:-70.236481},
{x:25.778159,y:-80.288995},
{x:39.104727,y:-90.315287},
{x:28.292804,y:-81.365523},
{x:39.406271,y:-104.856612},
{x:42.552467,y:-71.782557},
{x:38.937438,y:-97.214579},
{x:42.094678,y:-83.245822},
{x:26.23015,y:-80.154233},
{x:42.648087,y:-85.30579},
{x:30.014947,y:-81.393513},
{x:28.434335,y:-81.314016},
{x:30.321258,y:-92.035057},
{x:42.082565,y:-86.417717},
{x:40.924475,y:-90.648707},
{x:33.628345,y:-91.802064},
{x:42.491856,y:-82.939902},
{x:30.33214,y:-82.960526},
{x:33.137379,y:-117.179873},
{x:34.974107,y:-120.435175},
{x:26.136373,y:-80.232549},
{x:39.385555,y:-77.258048},
{x:37.588708,y:-122.021352},
{x:39.034267,y:-94.534438},
{x:26.046864,y:-80.252366},
{x:40.261206,y:-103.772302},
{x:30.096993,y:-83.581582},
{x:37.903282,y:-122.061484},
{x:34.878525,y:-83.396003},
{x:20.90438,y:-156.62352},
{x:30.256554,y:-81.465315},
{x:30.501231,y:-90.484681},
{x:37.619536,y:-122.387299},
{x:39.740485,y:-104.903186},
{x:41.833873,y:-87.898886},
{x:33.233458,y:-87.53836},
{x:27.807354,y:-82.63973},
{x:36.434342,y:-78.181635},
{x:28.593588,y:-81.365107},
{x:31.940696,y:-87.737486},
{x:38.95857,y:-85.85676},
{x:42.070295,y:-72.098586},
{x:33.495603,y:-112.152215},
{x:28.832088,y:-82.326195},
{x:34.00061,y:-118.394659},
{x:34.003457,y:-118.282382},
{x:32.187172,y:-90.262843},
{x:40.097473,y:-88.190336},
{x:30.835761,y:-89.532167},
{x:35.259207,y:-81.215993},
{x:39.16503,y:-86.58144},
{x:41.623045,y:-87.59064},
{x:27.858376,y:-82.326367},
{x:42.717675,y:-92.474997},
{x:34.141604,y:-83.952807},
{x:33.066721,y:-112.045341},
{x:41.600945,y:-93.557954},
{x:29.622868,y:-90.7489},
{x:39.708518,y:-104.8757},
{x:38.086006,y:-85.668664},
{x:32.188328,y:-110.96893},
{x:30.464738,y:-86.530614},
{x:41.721946,y:-87.582487},
{x:41.995466,y:-87.71547},
{x:32.464024,y:-84.923717},
{x:34.767696,y:-77.406276},
{x:40.057891,y:-85.6535},
{x:42.257406,y:-88.148735},
{x:37.97264,y:-122.063558},
{x:41.931927,y:-87.740096},
{x:37.832657,y:-122.253143},
{x:33.678489,y:-112.031486},
{x:34.018642,y:-118.111246},
{x:26.782834,y:-80.101653},
{x:38.320087,y:-104.614256},
{x:38.145426,y:-85.58592},
{x:30.253312,y:-92.036611},
{x:35.15713,y:-78.977146},
{x:33.91943,y:-84.38012},
{x:32.398788,y:-93.748555},
{x:41.707005,y:-86.62331},
{x:35.34615,y:-94.341619},
{x:29.059635,y:-82.056441},
{x:28.49467,y:-81.459048},
{x:32.535324,y:-82.929192},
{x:33.963091,y:-84.007564},
{x:26.653,y:-81.97104},
{x:40.759279,y:-86.766076},
{x:29.871739,y:-90.081355},
{x:31.516639,y:-82.849666},
{x:37.869458,y:-87.570515},
{x:43.84078,y:-84.768965},
{x:37.546888,y:-77.363472},
{x:36.890945,y:-89.537165},
{x:27.893187,y:-82.684569},
{x:38.395688,y:-121.95189},
{x:38.659251,y:-121.3481},
{x:41.864018,y:-87.686361},
{x:34.566191,y:-86.974027},
{x:30.558953,y:-91.132683},
{x:34.064773,y:-117.557042},
{x:27.982108,y:-82.310914},
{x:30.818574,y:-83.314682},
{x:41.692229,y:-87.701144},
{x:41.912127,y:-87.80617},
{x:25.769974,y:-80.32623},
{x:39.074833,y:-84.893141},
{x:30.230912,y:-85.888675},
{x:38.352057,y:-121.950638},
{x:28.50959,y:-81.376683},
{x:37.078721,y:-88.676951},
{x:41.590932,y:-88.042745},
{x:35.559869,y:-82.635761},
{x:39.034663,y:-84.533111},
{x:35.733327,y:-79.437662},
{x:41.794417,y:-87.723104},
{x:28.239063,y:-82.184257},
{x:41.913936,y:-71.360851},
{x:38.520487,y:-89.927729},
{x:39.18978,y:-96.551384},
{x:41.764359,y:-88.187389},
{x:39.118312,y:-94.378066},
{x:41.876482,y:-84.043774},
{x:35.739208,y:-81.680183},
{x:39.786346,y:-105.110095},
{x:25.809483,y:-80.239913},
{x:37.994113,y:-122.119334},
{x:30.023771,y:-90.061746},
{x:28.673337,y:-81.49995},
{x:34.140764,y:-77.893591},
{x:42.492911,y:-83.006005},
{x:36.06979,y:-79.869798},
{x:41.561804,y:-85.798178},
{x:26.162735,y:-81.766616},
{x:39.32353,y:-76.50297},
{x:27.273808,y:-80.349887},
{x:32.730998,y:-89.540681},
{x:39.057662,y:-77.08462},
{x:42.46901,y:-91.896147},
{x:42.059497,y:-71.865057},
{x:37.220824,y:-121.861737},
{x:33.690183,y:-86.683146},
{x:35.081943,y:-77.03196},
{x:25.576949,y:-80.338886},
{x:47.494377,y:-111.279734},
{x:40.282956,y:-86.029981},
{x:42.667161,y:-83.3296},
{x:35.972815,y:-77.812692},
{x:37.13871,y:-93.31999},
{x:42.303282,y:-85.199089},
{x:39.019545,y:-84.695763},
{x:28.033766,y:-82.459074},
{x:30.229592,y:-85.649715},
{x:27.969579,y:-81.881601},
{x:39.752813,y:-94.803138},
{x:26.603881,y:-81.872472},
{x:42.139086,y:-87.927526},
{x:41.878024,y:-87.641585},
{x:27.960329,y:-82.72379},
{x:33.28132,y:-111.68557},
{x:32.361966,y:-84.963054},
{x:39.458866,y:-75.723149},
{x:38.065412,y:-85.697779},
{x:42.167809,y:-72.481919},
{x:46.35601,y:-94.797104},
{x:36.771587,y:-119.864413},
{x:31.866977,y:-81.610452},
{x:41.76439,y:-72.613632},
{x:46.85074,y:-96.767378},
{x:33.74625,y:-117.920401},
{x:42.379534,y:-71.566777},
{x:39.13628,y:-86.53149},
{x:41.664393,y:-88.441374},
{x:42.202622,y:-70.999864},
{x:44.8159,y:-68.8076},
{x:34.221676,y:-119.177616},
{x:37.031353,y:-84.626137},
{x:42.676795,y:-83.296951},
{x:28.922647,y:-81.298077},
{x:36.759986,y:-120.386128},
{x:37.045154,y:-122.027782},
{x:35.679851,y:-90.550104},
{x:34.026821,y:-118.198453},
{x:43.557687,y:-83.95136},
{x:42.11225,y:-72.090988},
{x:41.358114,y:-72.210481},
{x:35.886626,y:-78.679079},
{x:46.75137,y:-88.450327},
{x:26.933754,y:-80.101238},
{x:38.361451,y:-75.073386},
{x:39.711059,y:-105.052797},
{x:32.071447,y:-84.221244},
{x:39.546789,y:-105.03575},
{x:39.682241,y:-85.138073},
{x:30.82965,y:-88.068553},
{x:42.728624,y:-84.602756},
{x:41.929888,y:-84.640869},
{x:41.406122,y:-73.419644},
{x:41.954109,y:-87.699914},
{x:34.817633,y:-87.658956},
{x:34.236187,y:-88.705232},
{x:27.998287,y:-80.633276},
{x:36.839429,y:-87.480283},
{x:38.066727,y:-122.165277},
{x:32.228889,y:-110.98342},
{x:34.060637,y:-118.244315},
{x:38.584185,y:-89.909514},
{x:34.8299,y:-92.39278},
{x:28.273289,y:-82.625879},
{x:35.763015,y:-78.007173},
{x:38.836754,y:-104.794642},
{x:35.395484,y:-77.970767},
{x:36.800473,y:-121.66462},
{x:34.281348,y:-119.301063},
{x:38.561975,y:-121.769177},
{x:39.498605,y:-76.325908},
{x:32.807768,y:-83.727693},
{x:47.112988,y:-88.58743},
{x:34.344088,y:-78.709418},
{x:30.145974,y:-85.59158},
{x:37.03479,y:-86.340056},
{x:41.860484,y:-88.007594},
{x:28.383561,y:-80.703292},
{x:27.956125,y:-82.456077},
{x:42.495921,y:-92.375656},
{x:34.033287,y:-117.686955},
{x:39.12505,y:-121.57726},
{x:41.563362,y:-70.59587},
{x:37.49048,y:-121.927955},
{x:38.105884,y:-122.23194},
{x:33.640072,y:-112.05274},
{x:39.935953,y:-91.405391},
{x:39.636701,y:-78.844971},
{x:41.975002,y:-87.89026},
{x:30.188796,y:-82.639076},
{x:33.95752,y:-118.039011},
{x:36.417439,y:-121.320327},
{x:33.916329,y:-118.342413},
{x:33.620234,y:-84.236052},
{x:42.065768,y:-87.918621},
{x:32.17838,y:-110.91822},
{x:32.070927,y:-81.101241},
{x:37.676212,y:-120.993645},
{x:41.76604,y:-87.722634},
{x:35.220702,y:-93.162998},
{x:37.054593,y:-100.921725},
{x:33.90155,y:-84.54188},
{x:41.924677,y:-71.877321},
{x:39.662404,y:-75.691232},
{x:44.215254,y:-70.519499},
{x:33.980651,y:-84.080944},
{x:38.001998,y:-121.28978},
{x:34.036585,y:-117.748675},
{x:36.236967,y:-90.03255},
{x:38.184262,y:-85.607158},
{x:29.084415,y:-82.250394},
{x:26.149915,y:-80.324966},
{x:28.668433,y:-82.141417},
{x:44.434756,y:-83.330491},
{x:45.157043,y:-93.165102},
{x:33.817702,y:-117.908395},
{x:33.466013,y:-112.013389},
{x:38.748359,y:-90.59043},
{x:42.278749,y:-71.407234},
{x:38.480898,y:-107.880329},
{x:34.103634,y:-117.53157},
{x:43.582844,y:-84.768122},
{x:38.398402,y:-85.371364},
{x:38.574117,y:-75.286485},
{x:42.244584,y:-71.837257},
{x:30.441503,y:-84.261406},
{x:33.947222,y:-84.125947},
{x:32.23457,y:-110.84024},
{x:28.021454,y:-82.244396},
{x:29.904185,y:-90.030244},
{x:40.479461,y:-104.930867},
{x:38.027137,y:-84.536254},
{x:39.842274,y:-85.55819},
{x:39.46392,y:-95.748206},
{x:33.859488,y:-117.976015},
{x:41.920812,y:-72.672074},
{x:36.837655,y:-121.390271},
{x:38.331005,y:-122.714701},
{x:25.944426,y:-80.121006},
{x:28.078289,y:-80.651999},
{x:26.562264,y:-82.009004},
{x:45.027891,y:-84.685827},
{x:34.924561,y:-79.752926},
{x:26.706659,y:-80.229725},
{x:34.154613,y:-78.85985},
{x:39.41557,y:-76.77521},
{x:43.907221,y:-69.917801},
{x:33.792187,y:-87.242154},
{x:37.781479,y:-122.461397},
{x:42.241568,y:-88.842457},
{x:42.50026,y:-83.305233},
{x:41.937638,y:-88.000386},
{x:32.813512,y:-111.671909},
{x:21.40125,y:-157.800209},
{x:35.497263,y:-79.198966},
{x:33.774575,y:-89.822185},
{x:38.311775,y:-88.958762},
{x:28.56845,y:-81.284985},
{x:40.022975,y:-86.902821},
{x:34.05677,y:-118.26815},
{x:31.903175,y:-110.986635},
{x:31.575075,y:-84.156879},
{x:42.322608,y:-83.48381},
{x:33.406026,y:-86.80583},
{x:31.949538,y:-92.654973},
{x:27.543325,y:-82.475167},
{x:34.018698,y:-118.421292},
{x:39.7525,y:-105.216742},
{x:32.87994,y:-111.743685},
{x:34.741352,y:-77.969388},
{x:38.990618,y:-104.797951},
{x:38.92547,y:-94.686707},
{x:39.586833,y:-77.012232},
{x:39.136277,y:-76.597236},
{x:39.31188,y:-76.46782},
{x:42.358187,y:-88.26634},
{x:42.3881,y:-87.955661},
{x:32.751997,y:-117.203764},
{x:33.56114,y:-86.753041},
{x:47.516782,y:-111.319226},
{x:38.45838,y:-122.67467},
{x:36.07416,y:-80.336963},
{x:36.94291,y:-86.42168},
{x:35.269577,y:-116.067559},
{x:34.104109,y:-82.853369},
{x:36.721906,y:-121.66367},
{x:29.577953,y:-90.689164},
{x:33.650826,y:-84.009359},
{x:25.841106,y:-80.313984},
{x:38.364449,y:-122.71417},
{x:64.67702,y:-147.089577},
{x:44.883865,y:-93.280841},
{x:34.579748,y:-118.082682},
{x:39.914819,y:-105.072252},
{x:32.484139,y:-110.92169},
{x:34.310684,y:-89.927301},
{x:31.916784,y:-81.349832},
{x:25.714133,y:-80.416613},
{x:37.214235,y:-89.542193},
{x:36.06988,y:-80.258563},
{x:33.839154,y:-117.886142},
{x:39.563144,y:-86.091251},
{x:38.315895,y:-85.759533},
{x:33.496689,y:-88.385446},
{x:42.372647,y:-87.882889},
{x:37.05468,y:-121.015542},
{x:41.027155,y:-89.048957},
{x:27.989731,y:-82.729963},
{x:41.600487,y:-86.731547},
{x:38.933147,y:-94.507671},
{x:25.925567,y:-80.309669},
{x:42.741539,y:-84.66019},
{x:33.851975,y:-118.140536},
{x:34.076446,y:-117.690295},
{x:33.35339,y:-84.121316},
{x:45.119297,y:-87.613978},
{x:39.203127,y:-94.548146},
{x:40.460111,y:-88.976087},
{x:34.277176,y:-86.206163},
{x:31.580089,y:-90.460617},
{x:30.473142,y:-81.630061},
{x:42.213856,y:-83.22973},
{x:40.57139,y:-122.357103},
{x:39.463566,y:-87.457053},
{x:33.988038,y:-83.984363},
{x:32.266129,y:-109.842383},
{x:41.615552,y:-93.856499},
{x:43.420487,y:-82.850679},
{x:35.11113,y:-92.43803},
{x:39.562768,y:-76.972438},
{x:30.527511,y:-90.955684},
{x:41.704166,y:-93.580693},
{x:42.532118,y:-85.871702},
{x:42.012974,y:-87.719628},
{x:37.541733,y:-122.302075},
{x:33.46522,y:-86.822599},
{x:27.964494,y:-82.43593},
{x:34.0102,y:-85.042327},
{x:36.009707,y:-79.838507},
{x:32.447989,y:-93.86444},
{x:42.225068,y:-83.180556},
{x:25.933338,y:-80.292954},
{x:42.425122,y:-71.041873},
{x:32.086393,y:-110.801239},
{x:34.257361,y:-89.018502},
{x:42.439658,y:-96.348209},
{x:43.437284,y:-84.01352},
{x:41.68831,y:-70.33826},
{x:37.865347,y:-82.788698},
{x:33.617193,y:-84.472322},
{x:37.726049,y:-89.228378},
{x:41.54924,y:-87.415146},
{x:29.499803,y:-82.86893},
{x:42.399425,y:-83.333987},
{x:41.939952,y:-88.148187},
{x:35.378092,y:-78.541141},
{x:34.494358,y:-85.336575},
{x:39.6031,y:-87.695668},
{x:27.966372,y:-82.504911},
{x:35.899824,y:-77.561075},
{x:42.821454,y:-82.487478},
{x:25.825586,y:-80.207903},
{x:41.140478,y:-73.425598},
{x:30.513333,y:-87.886961},
{x:30.180883,y:-92.004266},
{x:38.482234,y:-121.389562},
{x:31.130651,y:-93.266216},
{x:35.257175,y:-116.688263},
{x:39.499238,y:-121.565602},
{x:42.769573,y:-86.115657},
{x:33.508877,y:-112.266865},
{x:34.06112,y:-84.59245},
{x:33.604276,y:-86.283152},
{x:37.395448,y:-122.029322},
{x:30.175869,y:-93.219965},
{x:38.26009,y:-122.063958},
{x:32.717888,y:-117.154806},
{x:42.216707,y:-83.485803},
{x:35.363345,y:-94.373194},
{x:45.37046,y:-84.974609},
{x:32.385132,y:-90.147853},
{x:33.925049,y:-86.022891},
{x:32.516179,y:-92.193036},
{x:42.499614,y:-83.359282},
{x:43.666358,y:-70.276887},
{x:43.910362,y:-69.8227},
{x:35.224802,y:-80.840536},
{x:32.372892,y:-86.350954},
{x:41.366605,y:-93.557973},
{x:42.472456,y:-71.212196},
{x:33.957577,y:-84.134158},
{x:34.135702,y:-109.95057},
{x:41.588037,y:-87.461955},
{x:41.481823,y:-72.810097},
{x:30.256894,y:-82.123555},
{x:30.518043,y:-87.311931},
{x:41.59102,y:-73.107064},
{x:35.00555,y:-78.312319},
{x:33.56523,y:-85.07548},
{x:37.360666,y:-120.614309},
{x:38.051066,y:-84.710708},
{x:39.698421,y:-104.903824},
{x:25.900532,y:-80.177992},
{x:40.407557,y:-105.109905},
{x:41.617408,y:-87.042781},
{x:30.179813,y:-82.685437},
{x:41.655592,y:-88.559606},
{x:42.297078,y:-85.233428},
{x:33.82121,y:-109.98165},
{x:34.107279,y:-117.43612},
{x:39.383793,y:-76.574753},
{x:30.752123,y:-85.551524},
{x:32.608004,y:-117.066391},
{x:32.693372,y:-117.134117},
{x:42.080954,y:-86.436503},
{x:31.618915,y:-84.17763},
{x:32.38055,y:-82.061118},
{x:38.886772,y:-121.291895},
{x:33.67945,y:-93.60504},
{x:34.89987,y:-92.14672},
{x:36.735665,y:-119.744893},
{x:36.338505,y:-119.27894},
{x:35.183765,y:-111.661865},
{x:28.45443,y:-82.62896},
{x:32.667759,y:-114.443406},
{x:31.572648,y:-84.100335},
{x:33.553452,y:-112.109409},
{x:42.870027,y:-85.555482},
{x:27.805787,y:-82.679433},
{x:25.808324,y:-80.28951},
{x:33.9337,y:-85.610467},
{x:35.243991,y:-91.735423},
{x:41.928306,y:-83.354718},
{x:39.63715,y:-76.86707},
{x:42.509215,y:-96.416228},
{x:26.547626,y:-81.802544},
{x:34.29395,y:-83.815699},
{x:41.006211,y:-91.978214},
{x:42.255768,y:-71.033141},
{x:42.457822,y:-71.024306},
{x:42.056552,y:-88.003544},
{x:41.35596,y:-88.839332},
{x:41.115558,y:-87.865298},
{x:28.701022,y:-81.326466},
{x:43.681652,y:-70.329562},
{x:46.704161,y:-92.45593},
{x:29.79868,y:-90.817376},
{x:28.025842,y:-82.505526},
{x:40.160033,y:-104.970874},
{x:43.95534,y:-86.409931},
{x:26.61187,y:-81.81406},
{x:38.698483,y:-75.399902},
{x:40.451002,y:-105.077321},
{x:32.029449,y:-81.111705},
{x:33.50985,y:-112.185152},
{x:32.427452,y:-85.693441},
{x:35.731004,y:-78.650945},
{x:33.450331,y:-111.986895},
{x:37.069299,y:-94.477953},
{x:28.290563,y:-82.71059},
{x:26.105074,y:-80.282449},
{x:37.428824,y:-121.910408},
{x:40.183187,y:-122.220419},
{x:33.541181,y:-112.351006},
{x:42.534636,y:-82.913004},
{x:33.994339,y:-83.337076},
{x:35.203043,y:-80.722641},
{x:27.893438,y:-82.519116},
{x:35.640895,y:-78.45039},
{x:31.786716,y:-85.964713},
{x:61.195538,y:-149.909944},
{x:37.996369,y:-88.937544},
{x:46.617055,y:-112.015988},
{x:37.71125,y:-85.83367},
{x:40.392798,y:-104.696443},
{x:43.647728,y:-70.31024},
{x:39.478081,y:-76.248813},
{x:45.414227,y:-94.05633},
{x:30.133086,y:-81.783597},
{x:34.097706,y:-118.120496},
{x:39.251982,y:-94.652722},
{x:28.14783,y:-80.6722},
{x:36.032391,y:-80.383091},
{x:33.750326,y:-84.719337},
{x:42.395586,y:-71.040292},
{x:38.936768,y:-94.798167},
{x:41.75071,y:-88.114156},
{x:42.588938,y:-83.029856},
{x:35.441826,y:-119.07744},
{x:43.68955,y:-85.484427},
{x:30.356158,y:-91.108772},
{x:41.552419,y:-88.12545},
{x:38.454916,y:-121.406207},
{x:34.726053,y:-76.645362},
{x:34.75616,y:-92.26376},
{x:30.592367,y:-88.169952},
{x:29.870098,y:-81.323828},
{x:25.572654,y:-80.372425},
{x:36.670631,y:-121.627121},
{x:26.66436,y:-81.887737},
{x:30.744555,y:-88.110285},
{x:35.368198,y:-120.842555},
{x:39.775516,y:-93.550252},
{x:42.03432,y:-91.679766},
{x:30.687204,y:-88.05015},
{x:34.749685,y:-89.452826},
{x:42.13639,y:-72.52054},
{x:31.073797,y:-92.432198},
{x:42.01254,y:-88.179547},
{x:41.983692,y:-86.12308},
{x:39.355799,y:-94.765403},
{x:38.991486,y:-76.701277},
{x:28.687545,y:-81.39341},
{x:38.287058,y:-90.401316},
{x:42.329133,y:-83.040558},
{x:37.781633,y:-97.326618},
{x:30.40647,y:-87.276249},
{x:38.448119,y:-105.20769},
{x:35.385796,y:-81.364399},
{x:28.054065,y:-82.450717},
{x:46.463688,y:-90.145133},
{x:41.898716,y:-88.340981},
{x:40.112803,y:-88.282907},
{x:29.692551,y:-90.551605},
{x:33.363196,y:-111.92914},
{x:33.461656,y:-84.450567},
{x:34.314907,y:-88.705853},
{x:44.894195,y:-94.394733},
{x:41.33178,y:-72.973787},
{x:42.504715,y:-83.088755},
{x:41.56497,y:-87.559028},
{x:38.926146,y:-94.582906},
{x:41.955824,y:-71.02715},
{x:34.272328,y:-77.818274},
{x:36.610583,y:-83.701683},
{x:41.903958,y:-88.031876},
{x:37.304498,y:-121.86173},
{x:32.360273,y:-86.213702},
{x:32.711871,y:-117.115411},
{x:28.451861,y:-81.363961},
{x:30.290952,y:-87.62046},
{x:36.767564,y:-90.416396},
{x:33.929167,y:-116.947658},
{x:40.422623,y:-104.754232},
{x:35.739222,y:-79.649669},
{x:35.617881,y:-118.477251},
{x:42.929491,y:-83.632964},
{x:41.569805,y:-87.509123},
{x:33.81813,y:-84.57741},
{x:41.85107,y:-87.793053},
{x:37.827454,y:-92.143651},
{x:33.71878,y:-84.93758},
{x:33.351501,y:-86.612395},
{x:26.123907,y:-80.361431},
{x:33.113862,y:-117.101994},
{x:41.215283,y:-86.782762},
{x:26.35059,y:-81.807063},
{x:30.454222,y:-89.100788},
{x:27.713122,y:-82.370316},
{x:42.836675,y:-84.218803},
{x:44.272569,y:-85.40605},
{x:30.394459,y:-91.109539},
{x:32.552369,y:-84.947783},
{x:42.347537,y:-89.051035},
{x:34.571204,y:-83.307786},
{x:39.605117,y:-86.108351},
{x:41.673133,y:-87.720374},
{x:32.557946,y:-83.882937},
{x:26.009645,y:-80.244992},
{x:39.10341,y:-87.424429},
{x:39.40886,y:-76.60789},
{x:36.177778,y:-81.143606},
{x:33.786778,y:-84.491365},
{x:34.093952,y:-84.279565},
{x:36.637887,y:-87.454964},
{x:32.30576,y:-111.00928},
{x:40.220055,y:-85.432401},
{x:27.637309,y:-80.388765},
{x:37.662244,y:-121.780725},
{x:38.819614,y:-77.001217},
{x:41.507011,y:-87.337654},
{x:34.026523,y:-118.334747},
{x:38.00811,y:-84.214163},
{x:32.469191,y:-84.987025},
{x:29.942984,y:-90.094074},
{x:37.30938,y:-121.019303},
{x:35.464499,y:-79.15175},
{x:39.367257,y:-94.374376},
{x:39.525015,y:-122.212242},
{x:36.184572,y:-80.274364},
{x:38.056954,y:-97.928018},
{x:41.931093,y:-88.769967},
{x:42.639743,y:-83.354026},
{x:29.956247,y:-90.119737},
{x:26.664709,y:-80.088716},
{x:28.670957,y:-81.455174},
{x:42.435234,y:-96.373714},
{x:32.522976,y:-84.890257},
{x:45.206581,y:-93.359183},
{x:26.586847,y:-80.093473},
{x:45.771179,y:-111.185721},
{x:37.977676,y:-87.413704},
{x:33.896751,y:-84.13855},
{x:45.015004,y:-93.019269},
{x:40.044483,y:-85.995271},
{x:42.945495,y:-83.692771},
{x:25.748273,y:-80.335644},
{x:35.254844,y:-80.801369},
{x:34.097954,y:-118.297056},
{x:32.978783,y:-86.689671},
{x:35.146795,y:-90.195184},
{x:41.887464,y:-71.120871},
{x:39.304832,y:-84.522799},
{x:34.963121,y:-89.903933},
{x:25.88838,y:-80.163616},
{x:38.7693,y:-90.27956},
{x:37.309342,y:-121.993598},
{x:29.474206,y:-90.315211},
{x:33.92406,y:-118.12899},
{x:42.035742,y:-87.940201},
{x:38.32408,y:-85.712116},
{x:42.448735,y:-73.251195},
{x:39.02128,y:-84.5873},
{x:33.75492,y:-84.26859},
{x:42.006508,y:-88.146529},
{x:35.373371,y:-80.725094},
{x:44.832905,y:-93.160199},
{x:34.319572,y:-83.832423},
{x:41.953165,y:-87.786903},
{x:35.302997,y:-93.137131},
{x:42.642845,y:-83.170769},
{x:32.240131,y:-92.710966},
{x:41.668976,y:-88.204724},
{x:41.644493,y:-72.770988},
{x:38.272216,y:-121.98724},
{x:41.274305,y:-72.992012},
{x:40.416211,y:-120.650697},
{x:42.315343,y:-83.108947},
{x:30.884986,y:-87.787562},
{x:39.751903,y:-86.150725},
{x:30.49319,y:-87.192326},
{x:42.120719,y:-72.745459},
{x:31.568407,y:-93.487442},
{x:32.768906,y:-115.534298},
{x:30.519571,y:-92.074478},
{x:39.356357,y:-85.481328},
{x:35.043659,y:-78.957087},
{x:41.937585,y:-84.985402},
{x:29.203893,y:-82.180311},
{x:30.532705,y:-87.225277},
{x:40.431823,y:-86.871958},
{x:39.828416,y:-105.006519},
{x:41.700358,y:-86.216947},
{x:37.671188,y:-92.652183},
{x:30.466988,y:-87.241503},
{x:38.761647,y:-121.30982},
{x:37.288142,y:-120.454297},
{x:34.25756,y:-88.716682},
{x:34.232347,y:-92.044088},
{x:35.7692,y:-91.62753},
{x:32.677678,y:-117.098252},
{x:32.49116,y:-82.93349},
{x:40.395915,y:-86.868813},
{x:36.751531,y:-119.700862},
{x:36.090733,y:-79.773102},
{x:30.4601,y:-84.226579},
{x:35.85492,y:-111.42495},
{x:34.79357,y:-92.27293},
{x:34.099141,y:-117.872395},
{x:26.317944,y:-80.184907},
{x:42.199962,y:-83.700413},
{x:42.155043,y:-87.959975},
{x:32.178175,y:-110.994489},
{x:36.249101,y:-90.958223},
{x:42.783551,y:-86.078541},
{x:44.731299,y:-93.134779},
{x:38.003243,y:-91.618489},
{x:33.422504,y:-112.222872},
{x:43.087851,y:-85.261421},
{x:36.064692,y:-79.81644},
{x:38.676946,y:-121.736221},
{x:41.714676,y:-72.764367},
{x:39.379666,y:-104.862845},
{x:34.106437,y:-117.361401},
{x:34.204276,y:-84.765033},
{x:25.766286,y:-80.19369},
{x:41.527831,y:-88.072471},
{x:28.00447,y:-81.730332},
{x:25.794867,y:-80.278937},
{x:33.321079,y:-111.875854},
{x:32.836757,y:-109.735241},
{x:44.025326,y:-92.748005},
{x:31.692942,y:-83.244968},
{x:42.574534,y:-70.875389},
{x:41.6455,y:-87.540301},
{x:36.292541,y:-76.245658},
{x:26.57594,y:-80.054317},
{x:27.981058,y:-82.485718},
{x:34.585461,y:-112.33019},
{x:47.235577,y:-93.525801},
{x:33.514073,y:-88.426035},
{x:39.163988,y:-86.497823},
{x:31.998624,y:-81.253587},
{x:45.299605,y:-93.795343},
{x:40.036506,y:-105.054529},
{x:36.35686,y:-119.3122},
{x:38.918579,y:-84.626499},
{x:41.902707,y:-88.082664},
{x:39.399434,y:-76.547048},
{x:38.197366,y:-85.267841},
{x:38.021403,y:-122.261575},
{x:41.818717,y:-87.72418},
{x:38.531018,y:-121.955554},
{x:42.404906,y:-73.264603},
{x:38.101017,y:-121.26697},
{x:42.250843,y:-83.662923},
{x:37.701808,y:-122.484763},
{x:41.311435,y:-122.316965},
{x:38.256465,y:-94.356733},
{x:37.179262,y:-94.323452},
{x:21.324472,y:-157.877353},
{x:41.694906,y:-85.9647},
{x:47.713073,y:-116.808219},
{x:42.270964,y:-88.969834},
{x:39.674369,y:-86.37263},
{x:30.416427,y:-88.827441},
{x:39.638049,y:-105.082118},
{x:42.501648,y:-94.159239},
{x:33.977678,y:-84.08085},
{x:38.589183,y:-90.254849},
{x:33.882821,y:-84.47737},
{x:41.998497,y:-72.580882},
{x:33.831906,y:-118.065181},
{x:33.167643,y:-86.254492},
{x:42.0595,y:-88.143992},
{x:44.826774,y:-93.280057},
{x:42.503414,y:-83.14522},
{x:42.107365,y:-70.762291},
{x:33.640155,y:-84.444885},
{x:43.002751,y:-85.652893},
{x:41.673081,y:-71.160765},
{x:38.123762,y:-122.254708},
{x:30.04241,y:-89.9574},
{x:37.732462,y:-120.950121},
{x:33.751167,y:-84.46789},
{x:36.728574,y:-88.630246},
{x:33.729356,y:-117.788438},
{x:29.127265,y:-81.004549},
{x:43.99188,y:-84.779187},
{x:44.525613,y:-122.906483},
{x:43.067373,y:-87.985349},
{x:39.98153,y:-83.148983},
{x:40.728183,y:-73.746838},
{x:40.809151,y:-74.001816},
{x:32.451908,y:-99.770749},
{x:36.138965,y:-85.502156},
{x:37.081813,y:-80.69018},
{x:42.9332,y:-72.29573},
{x:37.24258,y:-77.391075},
{x:39.903322,y:-75.240621},
{x:40.781829,y:-73.03702},
{x:40.316902,y:-75.309837},
{x:34.699396,y:-79.93089},
{x:29.878505,y:-95.607876},
{x:36.047484,y:-86.655139},
{x:31.76878,y:-106.42438},
{x:43.049018,y:-76.123765},
{x:40.577175,y:-75.623099},
{x:45.53115,y:-122.960961},
{x:45.454792,y:-122.578888},
{x:41.369142,y:-82.956237},
{x:44.086842,y:-121.306736},
{x:38.771685,y:-77.136153},
{x:41.484717,y:-75.692342},
{x:29.82193,y:-95.34261},
{x:41.649627,y:-83.521057},
{x:41.005809,y:-76.829299},
{x:39.99959,y:-82.93008},
{x:37.377828,y:-79.247998},
{x:26.287348,y:-98.164891},
{x:46.286438,y:-122.900783},
{x:30.048551,y:-95.184302},
{x:34.007182,y:-81.141324},
{x:41.1115,y:-83.204313},
{x:40.857422,y:-74.050358},
{x:31.692101,y:-106.260936},
{x:39.993171,y:-75.144315},
{x:36.000256,y:-83.776482},
{x:33.226678,y:-97.593553},
{x:45.662495,y:-122.55875},
{x:36.854785,y:-76.274014},
{x:47.230969,y:-123.125657},
{x:40.238751,y:-83.349816},
{x:36.180123,y:-115.17766},
{x:33.517087,y:-81.716628},
{x:41.297644,y:-73.889151},
{x:46.280843,y:-119.274087},
{x:40.923107,y:-74.140044},
{x:33.375775,y:-79.286645},
{x:33.00176,y:-96.85719},
{x:41.137072,y:-81.354863},
{x:38.365378,y:-78.937409},
{x:41.309452,y:-105.556981},
{x:26.40175,y:-99.006267},
{x:36.897138,y:-82.05995},
{x:43.546655,y:-96.708578},
{x:33.01291,y:-96.88753},
{x:33.039215,y:-96.69812},
{x:43.308964,y:-70.990551},
{x:41.071592,y:-111.978617},
{x:36.744775,y:-95.934377},
{x:27.530465,y:-99.503503},
{x:39.683298,y:-83.94016},
{x:29.542788,y:-95.060029},
{x:39.151174,y:-84.627369},
{x:41.280462,y:-75.855635},
{x:42.046904,y:-97.413995},
{x:40.323567,y:-78.95639},
{x:40.276876,y:-76.814692},
{x:44.367502,y:-100.355607},
{x:41.189681,y:-96.042452},
{x:39.043238,y:-77.474587},
{x:35.657967,y:-88.412461},
{x:40.182952,y:-75.116814},
{x:40.66676,y:-73.752639},
{x:41.204501,y:-96.129875},
{x:31.296424,y:-94.729242},
{x:35.086446,y:-106.514568},
{x:29.938207,y:-95.356768},
{x:41.321497,y:-75.792195},
{x:43.028866,y:-77.445819},
{x:42.997968,y:-70.92263},
{x:30.036271,y:-97.846378},
{x:31.68226,y:-106.18795},
{x:40.077169,y:-80.916998},
{x:43.010524,y:-87.929762},
{x:25.92085,y:-97.473835},
{x:33.184887,y:-102.274016},
{x:43.427452,y:-88.20292},
{x:40.572537,y:-112.296048},
{x:39.075381,y:-78.96679},
{x:32.960223,y:-96.648074},
{x:36.703266,y:-77.54359},
{x:21.330961,y:-157.923459},
{x:30.035534,y:-95.552256},
{x:41.152091,y:-81.863773},
{x:35.76498,y:-83.971648},
{x:37.577097,y:-77.396438},
{x:41.723441,y:-83.510043},
{x:32.674264,y:-97.043741},
{x:40.148815,y:-82.978234},
{x:41.015495,y:-74.2515},
{x:39.847537,y:-74.186413},
{x:29.918681,y:-95.610555},
{x:29.777055,y:-95.95199},
{x:47.733331,y:-122.312319},
{x:33.025618,y:-97.276966},
{x:39.438363,y:-75.040632},
{x:26.166702,y:-98.18831},
{x:29.516008,y:-98.526193},
{x:46.842873,y:-96.88461},
{x:43.461655,y:-89.769053},
{x:41.692256,y:-83.563282},
{x:31.704494,y:-106.31841},
{x:39.900599,y:-82.882063},
{x:41.438705,y:-81.872118},
{x:29.701173,y:-95.292818},
{x:30.208113,y:-95.458911},
{x:35.219906,y:-97.422917},
{x:39.930173,y:-77.641018},
{x:35.407168,y:-99.379263},
{x:35.597608,y:-84.512708},
{x:40.874419,y:-73.425027},
{x:33.467241,y:-94.236398},
{x:43.00864,y:-77.443574},
{x:35.205088,y:-89.839936},
{x:29.67734,y:-95.599245},
{x:40.764543,y:-73.932567},
{x:32.468959,y:-94.05524},
{x:40.750239,y:-74.170617},
{x:43.138033,y:-77.670658},
{x:38.010275,y:-81.130328},
{x:35.192016,y:-101.760225},
{x:41.51591,y:-81.549376},
{x:37.60586,y:-77.52236},
{x:43.229363,y:-123.368025},
{x:29.488312,y:-98.586197},
{x:41.06268,y:-80.359604},
{x:40.88151,y:-81.428933},
{x:43.148778,y:-77.598122},
{x:40.747887,y:-73.5529},
{x:39.736636,y:-75.002874},
{x:36.159566,y:-86.795397},
{x:41.097094,y:-73.95535},
{x:43.060553,y:-76.146418},
{x:42.470293,y:-96.427459},
{x:41.240735,y:-95.994503},
{x:40.485784,y:-74.473009},
{x:30.397251,y:-97.733932},
{x:36.133439,y:-86.779616},
{x:29.417826,y:-98.6541},
{x:29.721453,y:-95.352223},
{x:41.04983,y:-76.252385},
{x:36.172735,y:-115.243732},
{x:36.143631,y:-115.281555},
{x:38.364468,y:-81.70872},
{x:29.58015,y:-95.39302},
{x:35.069649,y:-89.865113},
{x:41.292821,y:-96.127844},
{x:41.965654,y:-73.989748},
{x:45.438737,y:-122.578278},
{x:34.708352,y:-97.242087},
{x:35.81202,y:-83.53775},
{x:33.873691,y:-79.760571},
{x:40.621191,y:-111.871506},
{x:40.293131,y:-83.068636},
{x:34.74704,y:-81.038717},
{x:35.864462,y:-101.973574},
{x:42.937592,y:-78.890088},
{x:43.050605,y:-88.372814},
{x:40.999652,y:-80.34878},
{x:39.012754,y:-74.87367},
{x:41.212818,y:-95.988447},
{x:42.754042,y:-88.207881},
{x:35.349188,y:-97.528546},
{x:30.335885,y:-95.101029},
{x:39.930265,y:-83.050302},
{x:36.219125,y:-115.280992},
{x:35.392485,y:-97.529787},
{x:41.208087,y:-96.081135},
{x:34.993016,y:-86.848685},
{x:40.822903,y:-81.933946},
{x:46.219767,y:-119.788615},
{x:44.848112,y:-74.306698},
{x:41.259918,y:-95.955237},
{x:41.393708,y:-81.53685},
{x:31.785584,y:-106.413877},
{x:29.956621,y:-94.005479},
{x:40.780679,y:-81.418712},
{x:40.96564,y:-76.892243},
{x:29.644505,y:-95.540691},
{x:40.80936,y:-73.628664},
{x:41.83009,y:-71.426562},
{x:36.158582,y:-115.077891},
{x:29.699019,y:-95.51796},
{x:39.132386,y:-82.978216},
{x:39.696653,y:-84.101908},
{x:35.54343,y:-89.663773},
{x:36.594386,y:-79.410935},
{x:35.517669,y:-84.358971},
{x:43.164745,y:-77.646767},
{x:40.791429,y:-74.013953},
{x:35.13456,y:-89.991915},
{x:39.95204,y:-75.256551},
{x:39.679017,y:-111.840301},
{x:40.859819,y:-76.547138},
{x:31.119438,y:-97.892719},
{x:29.929249,y:-93.924685},
{x:40.009827,y:-80.744644},
{x:33.577481,y:-101.947222},
{x:41.603456,y:-84.4082},
{x:27.754969,y:-97.422447},
{x:42.588071,y:-76.132866},
{x:41.260153,y:-96.03248},
{x:40.795809,y:-75.968339},
{x:39.524367,y:-119.767938},
{x:33.067487,y:-96.889459},
{x:31.327796,y:-95.440045},
{x:40.705278,y:-73.651425},
{x:35.853898,y:-86.396466},
{x:39.392475,y:-74.535893},
{x:35.93378,y:-85.478927},
{x:41.208636,y:-96.622507},
{x:40.913477,y:-74.179426},
{x:40.518365,y:-78.373744},
{x:34.190075,y:-79.834945},
{x:40.726056,y:-73.480926},
{x:32.37135,y:-106.738709},
{x:41.640812,y:-80.151061},
{x:41.060323,y:-80.663398},
{x:41.038416,y:-74.640591},
{x:36.007226,y:-84.023955},
{x:40.769173,y:-84.109134},
{x:31.630561,y:-94.652921},
{x:36.296922,y:-82.474683},
{x:41.058443,y:-83.662534},
{x:37.973771,y:-78.21755},
{x:40.65247,y:-75.413968},
{x:47.188816,y:-122.461672},
{x:34.83442,y:-82.3685},
{x:37.611288,y:-77.34081},
{x:41.489164,y:-112.01557},
{x:48.080007,y:-123.118123},
{x:38.328807,y:-81.713641},
{x:40.7206,y:-74.267663},
{x:40.557709,y:-76.792098},
{x:36.284191,y:-115.287351},
{x:43.499989,y:-96.708125},
{x:44.537495,y:-89.57565},
{x:33.024946,y:-96.993366},
{x:46.803427,y:-123.013468},
{x:41.637628,y:-83.663903},
{x:42.770417,y:-71.411062},
{x:34.981384,y:-81.086494},
{x:35.020666,y:-89.849283},
{x:27.714977,y:-97.37185},
{x:26.190481,y:-98.109295},
{x:36.777459,y:-76.583744},
{x:43.098576,y:-73.750606},
{x:40.535475,y:-75.736573},
{x:39.491551,y:-75.042267},
{x:39.985324,y:-82.786935},
{x:40.74179,y:-73.66166},
{x:35.031917,y:-80.968043},
{x:40.060312,y:-111.668241},
{x:47.138752,y:-119.277735},
{x:28.707871,y:-100.484858},
{x:40.037714,y:-76.508708},
{x:32.243451,y:-97.746913},
{x:40.292428,y:-109.991335},
{x:40.725598,y:-111.93945},
{x:40.058733,y:-78.081873},
{x:37.778903,y:-81.338467},
{x:40.98789,y:-75.224425},
{x:44.828247,y:-91.543343},
{x:39.474663,y:-80.151507},
{x:36.270654,y:-115.207313},
{x:43.034459,y:-74.343618},
{x:29.4957,y:-98.7108},
{x:43.976319,y:-75.937863},
{x:41.158787,y:-95.943712},
{x:40.877983,y:-73.116576},
{x:38.064868,y:-78.488261},
{x:40.375102,y:-80.705061},
{x:29.32499,y:-96.095},
{x:41.458408,y:-82.024944},
{x:29.601068,y:-95.286505},
{x:40.686027,y:-75.25082},
{x:43.024483,y:-71.36758},
{x:39.73214,y:-82.616255},
{x:47.00649,y:-123.3881},
{x:41.032001,y:-81.907676},
{x:32.388892,y:-94.866822},
{x:32.932922,y:-97.309715},
{x:29.70547,y:-95.472956},
{x:29.845686,y:-95.646137},
{x:33.129938,y:-97.039386},
{x:42.795417,y:-77.7971},
{x:35.465936,y:-85.039586},
{x:39.674858,y:-84.221273},
{x:29.827181,y:-94.84717},
{x:35.10414,y:-106.67059},
{x:39.927957,y:-75.14527},
{x:26.180968,y:-98.053107},
{x:35.194459,y:-101.866828},
{x:29.010671,y:-95.420211},
{x:45.305382,y:-122.949234},
{x:33.188891,y:-79.99899},
{x:40.287754,y:-79.369538},
{x:30.130459,y:-94.015689},
{x:31.813601,y:-106.412003},
{x:41.879063,y:-80.753741},
{x:31.575692,y:-102.891792},
{x:36.15188,y:-86.852286},
{x:42.339272,y:-77.334551},
{x:33.551975,y:-80.828323},
{x:46.884091,y:-98.71817},
{x:33.656495,y:-101.82266},
{x:29.866331,y:-95.58955},
{x:40.826923,y:-73.103139},
{x:47.088666,y:-122.589602},
{x:44.844257,y:-91.593035},
{x:40.142554,y:-84.263608},
{x:44.522651,y:-89.517557},
{x:43.250859,y:-89.371014},
{x:34.901868,y:-81.031428},
{x:41.644416,y:-111.9149},
{x:39.670779,y:-79.852183},
{x:47.056458,y:-122.295135},
{x:39.306238,y:-84.487185},
{x:40.943405,y:-98.379279},
{x:26.378191,y:-98.810438},
{x:33.64713,y:-96.61163},
{x:36.716853,y:-79.91099},
{x:29.442161,y:-95.093149},
{x:29.736941,y:-95.532449},
{x:41.873701,y:-71.390326},
{x:43.328837,y:-73.673605},
{x:29.647478,y:-98.425497},
{x:42.753561,y:-71.460297},
{x:36.85875,y:-75.983717},
{x:42.839332,y:-71.496901},
{x:40.814987,y:-96.606236},
{x:29.380443,y:-98.640579},
{x:35.398552,y:-97.494341},
{x:43.780497,y:-91.224957},
{x:32.755201,y:-97.169791},
{x:39.82419,y:-75.161133},
{x:41.477604,y:-81.769387},
{x:40.58185,y:-74.16686},
{x:29.847408,y:-95.393252},
{x:40.724463,y:-73.545332},
{x:40.799171,y:-77.8602},
{x:47.486908,y:-122.195874},
{x:40.05849,y:-77.502733},
{x:40.834415,y:-73.337113},
{x:44.045223,y:-122.925398},
{x:48.00339,y:-122.107014},
{x:38.340401,y:-77.075242},
{x:29.688797,y:-95.644334},
{x:40.390882,y:-80.595343},
{x:39.756582,y:-84.636973},
{x:29.997856,y:-95.174285},
{x:44.902168,y:-89.607039},
{x:29.902518,y:-95.313078},
{x:41.421472,y:-73.57755},
{x:26.127309,y:-97.635479},
{x:40.71267,y:-74.193697},
{x:40.6012,y:-73.93446},
{x:46.976708,y:-123.811126},
{x:32.885089,y:-80.036662},
{x:40.538388,y:-79.833134},
{x:31.751861,y:-106.30229},
{x:41.367463,y:-82.110796},
{x:29.814887,y:-95.42887},
{x:31.86917,y:-106.42821},
{x:43.50967,y:-96.771797},
{x:46.978848,y:-120.543659},
{x:40.709934,y:-74.007328},
{x:40.960446,y:-74.236873},
{x:32.400816,y:-80.684792},
{x:38.412744,y:-81.80588},
{x:35.018594,y:-85.280437},
{x:40.900413,y:-80.697339},
{x:40.832255,y:-81.387825},
{x:34.013318,y:-81.153338},
{x:36.143352,y:-86.744765},
{x:44.024074,y:-116.94252},
{x:40.629203,y:-74.306518},
{x:32.658838,y:-96.890857},
{x:39.654397,y:-80.013919},
{x:31.481309,y:-97.208061},
{x:40.730107,y:-84.071481},
{x:41.379902,y:-82.222777},
{x:29.984066,y:-95.296433},
{x:45.534651,y:-122.866237},
{x:29.64874,y:-95.565555},
{x:46.996812,y:-122.822594},
{x:29.49655,y:-95.4395},
{x:30.176704,y:-96.40065},
{x:39.476831,y:-118.802937},
{x:27.45378,y:-99.47678},
{x:40.088728,y:-82.823827},
{x:39.21711,y:-84.545868},
{x:41.063797,y:-74.12642},
{x:40.838752,y:-73.703019},
{x:34.372088,y:-82.222767},
{x:45.458651,y:-98.473845},
{x:40.306072,y:-76.85193},
{x:43.997175,y:-124.101005},
{x:36.394008,y:-85.329734},
{x:35.863306,y:-83.566746},
{x:36.416024,y:-97.889744},
{x:28.738596,y:-100.49567},
{x:37.517714,y:-77.3411},
{x:41.145828,y:-95.913776},
{x:39.815417,y:-84.130616},
{x:30.153386,y:-95.215272},
{x:40.826068,y:-73.862483},
{x:41.277274,y:-82.854953},
{x:38.454662,y:-81.494452},
{x:32.3012,y:-80.935776},
{x:39.944976,y:-82.796208},
{x:41.070109,y:-74.741237},
{x:45.497278,y:-122.610417},
{x:44.512895,y:-87.964307},
{x:41.405738,y:-81.734982},
{x:39.982077,y:-76.744455},
{x:43.107557,y:-88.484103},
{x:32.763727,y:-96.682365},
{x:41.300784,y:-75.852955},
{x:37.030712,y:-76.421611},
{x:40.92602,y:-72.74618},
{x:35.221842,y:-88.241892},
{x:36.348529,y:-88.876637},
{x:30.594578,y:-96.292815},
{x:38.295497,y:-77.512339},
{x:32.200256,y:-98.248024},
{x:33.147856,y:-96.805064},
{x:39.005708,y:-78.337226},
{x:36.147659,y:-95.940621},
{x:35.659918,y:-88.828841},
{x:29.789807,y:-95.409565},
{x:29.802738,y:-95.36},
{x:33.911688,y:-81.224875},
{x:33.041781,y:-97.021197},
{x:41.233867,y:-80.471828},
{x:40.232937,y:-74.716077},
{x:29.679805,y:-95.402281},
{x:41.449365,y:-82.202733},
{x:35.189455,y:-84.88049},
{x:41.276412,y:-96.024146},
{x:38.267597,y:-80.841062},
{x:34.517686,y:-82.489646},
{x:44.781384,y:-88.581181},
{x:29.863635,y:-95.473901},
{x:32.30612,y:-95.33472},
{x:40.751207,y:-73.870856},
{x:41.22668,y:-96.024642},
{x:43.195395,y:-77.601206},
{x:45.532524,y:-92.031763},
{x:29.510766,y:-98.39031},
{x:38.675599,y:-77.27842},
{x:34.69191,y:-82.986249},
{x:29.793362,y:-95.339171},
{x:30.561987,y:-97.68111},
{x:42.949357,y:-76.547815},
{x:40.858461,y:-80.28971},
{x:40.696868,y:-111.939693},
{x:44.922415,y:-74.885053},
{x:29.547688,y:-95.749426},
{x:34.492791,y:-81.980702},
{x:42.097396,y:-79.290026},
{x:40.450387,y:-80.182201},
{x:40.767334,y:-82.589939},
{x:38.774298,y:-77.502621},
{x:31.970519,y:-102.072851},
{x:38.588037,y:-77.305007},
{x:40.622594,y:-74.246662},
{x:40.692825,y:-73.7571},
{x:25.978509,y:-97.519458},
{x:35.731826,y:-84.388811},
{x:30.104801,y:-95.238381},
{x:40.815098,y:-73.914334},
{x:31.141159,y:-97.331089},
{x:45.717681,y:-122.65304},
{x:31.731601,y:-98.982424},
{x:38.760564,y:-81.69163},
{x:40.68645,y:-73.823133},
{x:40.220163,y:-74.264759},
{x:29.398872,y:-98.404459},
{x:42.155545,y:-76.822033},
{x:40.513299,y:-74.402987},
{x:41.375395,y:-74.694016},
{x:29.702348,y:-95.376049},
{x:43.81661,y:-91.249359},
{x:32.034167,y:-102.129818},
{x:25.93623,y:-97.499155},
{x:43.72376,y:-87.73626},
{x:34.236509,y:-80.664555},
{x:42.025271,y:-97.426172},
{x:29.40117,y:-95.244896},
{x:34.989437,y:-85.210423},
{x:42.604693,y:-87.876376},
{x:30.658869,y:-96.347465},
{x:36.867317,y:-76.490312},
{x:40.21361,y:-77.17654},
{x:29.483719,y:-98.401189},
{x:37.113597,y:-76.47124},
{x:37.127249,y:-80.4111},
{x:42.996425,y:-88.046196},
{x:43.101338,y:-89.34751},
{x:42.090988,y:-80.084505},
{x:40.061022,y:-82.976069},
{x:40.6246,y:-73.99901},
{x:32.375677,y:-98.830682},
{x:29.948809,y:-95.420179},
{x:29.998829,y:-95.37046},
{x:34.187483,y:-97.14337},
{x:40.706198,y:-73.631017},
{x:40.854588,y:-74.121373},
{x:33.953585,y:-80.379173},
{x:40.521149,y:-79.814067},
{x:40.307681,y:-83.09702},
{x:40.796699,y:-81.484765},
{x:32.527113,y:-94.779531},
{x:41.170402,y:-83.421298},
{x:42.867747,y:-78.697407},
{x:28.421376,y:-97.756465},
{x:36.298895,y:-95.321541},
{x:44.63501,y:-88.04339},
{x:31.771334,y:-106.499269},
{x:39.979837,y:-74.783326},
{x:35.228257,y:-89.525197},
{x:40.658917,y:-74.281641},
{x:34.820371,y:-82.340682},
{x:40.965506,y:-76.62051},
{x:32.86372,y:-96.68289},
{x:40.660751,y:-74.171422},
{x:40.072308,y:-75.033516},
{x:40.602395,y:-74.479023},
{x:43.04637,y:-77.11247},
{x:36.347049,y:-84.162002},
{x:41.978043,y:-78.616792},
{x:36.47026,y:-86.67637},
{x:39.424687,y:-84.594367},
{x:40.528523,y:-75.506461},
{x:38.718573,y:-77.808093},
{x:39.254689,y:-84.423072},
{x:43.057624,y:-77.653413},
{x:41.119027,y:-74.58008},
{x:31.463381,y:-100.444694},
{x:29.946452,y:-95.676051},
{x:42.954225,y:-88.008039},
{x:42.109236,y:-75.940479},
{x:40.914288,y:-81.106009},
{x:40.342193,y:-76.398506},
{x:43.973828,y:-75.904053},
{x:40.734363,y:-82.805321},
{x:35.13039,y:-84.874295},
{x:34.030432,y:-81.04258},
{x:46.033469,y:-118.38018},
{x:35.022482,y:-82.503827},
{x:43.090583,y:-87.923029},
{x:32.783489,y:-96.56099},
{x:39.823144,y:-84.28859},
{x:39.610688,y:-84.159609},
{x:29.734094,y:-95.348258},
{x:40.687615,y:-73.850456},
{x:43.453621,y:-71.567174},
{x:41.982105,y:-71.513986},
{x:37.787141,y:-80.453482},
{x:40.507598,y:-74.637973},
{x:37.284525,y:-81.250997},
{x:39.783228,y:-75.101158},
{x:29.706567,y:-95.405975},
{x:47.047343,y:-122.823523},
{x:39.290811,y:-84.305484},
{x:42.295525,y:-85.656854},
{x:41.012925,y:-81.554189},
{x:41.383752,y:-81.978453},
{x:42.823337,y:-71.624626},
{x:46.805507,y:-96.835636},
{x:41.100027,y:-80.75841},
{x:29.988868,y:-95.498654},
{x:34.171918,y:-79.764734},
{x:28.940292,y:-98.512162},
{x:34.099732,y:-81.179028},
{x:37.536587,y:-77.532535},
{x:40.959207,y:-117.745395},
{x:41.705818,y:-71.416341},
{x:40.052405,y:-74.894267},
{x:41.4457,y:-75.667558},
{x:47.404944,y:-122.297929},
{x:40.473639,y:-78.749173},
{x:40.940566,y:-76.029768},
{x:45.163508,y:-89.148669},
{x:29.48021,y:-98.36175},
{x:40.626093,y:-75.419028},
{x:39.955262,y:-82.878385},
{x:29.458248,y:-98.415547},
{x:29.643014,y:-98.48107},
{x:29.521534,y:-98.476814},
{x:34.521773,y:-97.967194},
{x:44.502849,y:-103.859861},
{x:42.815812,y:-73.943464},
{x:32.76111,y:-97.47917},
{x:33.66468,y:-79.833628},
{x:39.601275,y:-110.823691},
{x:39.535951,y:-119.737395},
{x:33.959212,y:-81.091294},
{x:40.696821,y:-112.025433},
{x:31.852268,y:-102.393295},
{x:40.67246,y:-73.84292},
{x:40.627861,y:-74.168667},
{x:35.435057,y:-97.438659},
{x:46.720397,y:-92.088301},
{x:38.762051,y:-77.459577},
{x:35.529095,y:-108.707664},
{x:34.936979,y:-81.982578},
{x:48.180625,y:-122.126957},
{x:29.558857,y:-95.691075},
{x:39.462971,y:-77.988695},
{x:37.305484,y:-79.890649},
{x:29.382894,y:-98.594492},
{x:41.516754,y:-81.65157},
{x:33.901421,y:-98.49608},
{x:35.005095,y:-85.153281},
{x:40.406418,y:-74.545713},
{x:36.23913,y:-115.166254},
{x:35.807295,y:-86.398059},
{x:45.33359,y:-122.587243},
{x:40.675751,y:-73.866924},
{x:29.713525,y:-95.315229},
{x:40.271157,y:-74.540447},
{x:35.097649,y:-85.327157},
{x:35.216896,y:-89.990185},
{x:35.205539,y:-106.675747},
{x:30.441433,y:-97.663374},
{x:40.74619,y:-74.049094},
{x:43.366108,y:-72.319776},
{x:43.769927,y:-71.71937},
{x:36.060322,y:-95.975203},
{x:41.409639,y:-81.499311},
{x:35.205649,y:-89.728488},
{x:41.625723,y:-79.673487},
{x:46.405636,y:-120.27677},
{x:42.877384,y:-77.261663},
{x:39.835103,y:-84.626558},
{x:42.819989,y:-106.370932},
{x:36.153248,y:-85.475406},
{x:43.059041,y:-74.324545},
{x:40.303588,y:-75.856275},
{x:30.365287,y:-97.695461},
{x:31.733346,y:-95.62528},
{x:40.245844,y:-74.764595},
{x:40.869811,y:-74.643183},
{x:40.659188,y:-75.075144},
{x:39.504576,y:-84.354562},
{x:36.942,y:-76.31},
{x:43.360046,y:-124.212444},
{x:40.428645,y:-79.979541},
{x:41.355713,y:-83.134882},
{x:35.158107,y:-101.920794},
{x:38.715179,y:-82.857261},
{x:47.167163,y:-122.146288},
{x:43.001575,y:-78.194426},
{x:40.282387,y:-76.881155},
{x:40.849638,y:-99.986699},
{x:34.85783,y:-82.256705},
{x:38.643406,y:-77.29496},
{x:41.433107,y:-82.711202},
{x:32.713375,y:-97.280152},
{x:35.2037,y:-89.778174},
{x:35.060256,y:-89.997842},
{x:42.965514,y:-71.447103},
{x:29.23204,y:-98.78889},
{x:35.019694,y:-81.895473},
{x:40.769572,y:-73.916548},
{x:35.081071,y:-85.264996},
{x:40.392647,y:-74.101603},
{x:40.78903,y:-76.527424},
{x:37.404707,y:-77.661756},
{x:38.202246,y:-79.008951},
{x:40.145681,y:-82.706069},
{x:40.75571,y:-96.6648},
{x:39.900045,y:-75.302278},
{x:41.262734,y:-96.090015},
{x:36.935696,y:-80.993837},
{x:41.505655,y:-74.07207},
{x:37.359351,y:-81.097615},
{x:36.043449,y:-115.101059},
{x:34.840938,y:-81.969539},
{x:42.157236,y:-78.747933},
{x:39.998071,y:-82.883555},
{x:40.511165,y:-79.947466},
{x:35.694552,y:-85.748348},
{x:35.021311,y:-90.007039},
{x:40.475503,y:-78.404852},
{x:40.162993,y:-110.409499},
{x:41.451808,y:-96.497858},
{x:44.683438,y:-74.986456},
{x:29.773467,y:-95.307781},
{x:48.152513,y:-122.193354},
{x:41.234105,y:-96.100762},
{x:40.453431,y:-109.545989},
{x:32.499391,y:-94.750186},
{x:39.896917,y:-80.159786},
{x:39.970943,y:-74.236325},
{x:29.726661,y:-95.418336},
{x:41.800184,y:-71.406322},
{x:36.72019,y:-78.118822},
{x:35.058786,y:-106.584854},
{x:40.69204,y:-73.397514},
{x:33.49672,y:-81.959927},
{x:29.27762,y:-98.668297},
{x:35.049893,y:-89.886769},
{x:39.948456,y:-75.115755},
{x:42.871702,y:-71.339106},
{x:41.503715,y:-75.580056},
{x:36.08039,y:-115.149723},
{x:30.080081,y:-97.822485},
{x:30.127515,y:-95.453966},
{x:33.483791,y:-80.868773},
{x:43.136555,y:-88.005221},
{x:42.973895,y:-77.364172},
{x:36.764138,y:-108.154371},
{x:40.61026,y:-73.96204},
{x:35.959591,y:-96.008549},
{x:34.991955,y:-82.023895},
{x:36.306107,y:-82.341415},
{x:40.011412,y:-82.866902},
{x:45.4047,y:-122.291718},
{x:40.027476,y:-75.064378},
{x:40.272705,y:-111.710125},
{x:38.481262,y:-77.975359},
{x:32.836928,y:-97.082941},
{x:48.437251,y:-122.342767},
{x:29.443044,y:-98.663672},
{x:44.287852,y:-88.374233},
{x:42.088657,y:-80.137603},
{x:29.642748,y:-95.385577},
{x:36.202768,y:-86.692851},
{x:41.573836,y:-84.13726},
{x:30.911096,y:-93.996907},
{x:33.055732,y:-97.015506},
{x:38.857804,y:-77.39374},
{x:33.592838,y:-101.924318},
{x:35.498799,y:-86.457494},
{x:30.253647,y:-94.215319},
{x:45.441335,y:-122.749354},
{x:40.78441,y:-111.984766},
{x:39.893232,y:-83.083632},
{x:40.61662,y:-73.94478},
{x:40.920387,y:-75.404257},
{x:45.534076,y:-122.658961},
{x:43.233386,y:-77.185601},
{x:35.936442,y:-84.145254},
{x:35.998175,y:-83.923758},
{x:40.748624,y:-74.398993},
{x:33.14062,y:-96.69166},
{x:41.622591,y:-80.201632},
{x:40.223956,y:-76.937366},
{x:43.069915,y:-76.172555},
{x:42.424782,y:-123.329881},
{x:40.139777,y:-74.301512},
{x:41.010206,y:-74.119398},
{x:40.667825,y:-73.780843},
{x:30.694816,y:-96.362278},
{x:31.77894,y:-106.46236},
{x:39.950922,y:-83.144857},
{x:38.740348,y:-77.472427},
{x:41.1292,y:-77.466172},
{x:26.23926,y:-98.220682},
{x:26.679903,y:-80.174529},
{x:40.835302,y:-74.052657},
{x:40.543377,y:-74.164054},
{x:25.944732,y:-97.426797},
{x:40.148062,y:-82.926778},
{x:40.75915,y:-82.562502},
{x:38.695869,y:-77.145014},
{x:40.597505,y:-74.436098},
{x:35.162073,y:-89.858825},
{x:47.443925,y:-122.219065},
{x:39.547277,y:-77.91383},
{x:40.581175,y:-83.095377},
{x:40.843139,y:-73.836498},
{x:39.640486,y:-84.26767},
{x:45.537001,y:-100.444175},
{x:42.53593,y:-88.247462},
{x:34.211033,y:-82.148481},
{x:34.959529,y:-82.307127},
{x:29.68982,y:-95.556445},
{x:37.744848,y:-77.134051},
{x:35.127124,y:-85.247236},
{x:42.851501,y:-106.270865},
{x:40.553843,y:-74.433876},
{x:36.744933,y:-76.243689},
{x:40.674565,y:-76.226068},
{x:31.426754,y:-97.71714},
{x:40.646162,y:-75.493471},
{x:40.459587,y:-74.402799},
{x:42.734566,y:-90.440094},
{x:36.084886,y:-115.151657},
{x:32.767427,y:-96.624471},
{x:35.08745,y:-106.568592},
{x:36.031868,y:-114.972444},
{x:43.461642,y:-76.482747},
{x:38.352098,y:-77.505786},
{x:35.659852,y:-105.973397},
{x:36.058482,y:-89.395549},
{x:29.847526,y:-95.411868},
{x:41.318606,y:-96.066138},
{x:41.150629,y:-80.699643},
{x:35.464059,y:-84.646863},
{x:41.443138,y:-81.703515},
{x:42.102237,y:-76.804938},
{x:44.930517,y:-123.306561},
{x:35.109488,y:-106.585667},
{x:39.78783,y:-76.96972},
{x:47.154363,y:-122.357356},
{x:42.786375,y:-96.921036},
{x:37.071284,y:-76.360648},
{x:30.082997,y:-95.448729},
{x:39.336235,y:-82.06441},
{x:39.511521,y:-84.749408},
{x:30.48678,y:-97.677487},
{x:45.519344,y:-122.403297},
{x:43.18765,y:-77.700385},
{x:32.562111,y:-97.320213},
{x:40.67048,y:-80.585434},
{x:41.373807,y:-83.623859},
{x:39.936744,y:-75.044474},
{x:40.38635,y:-75.926979},
{x:33.176005,y:-94.970047},
{x:33.25865,y:-96.79842},
{x:39.513919,y:-74.924318},
{x:40.658046,y:-73.608551},
{x:28.899423,y:-99.115893},
{x:41.368374,y:-75.68737},
{x:40.319438,y:-76.568596},
{x:41.46056,y:-81.733855},
{x:44.647751,y:-124.0537},
{x:36.73258,y:-108.23698},
{x:40.549177,y:-84.569157},
{x:30.811676,y:-97.611631},
{x:37.057215,y:-76.326792},
{x:34.086332,y:-80.93574},
{x:29.691077,y:-95.194067},
{x:38.417723,y:-82.458739},
{x:40.026825,y:-76.327147},
{x:29.831777,y:-95.211015},
{x:39.41692,y:-84.471647},
{x:37.437037,y:-77.599596},
{x:40.652853,y:-83.589807},
{x:33.548939,y:-101.859994},
{x:47.907138,y:-97.045879},
{x:40.141723,y:-75.388873},
{x:40.77675,y:-111.98184},
{x:33.593109,y:-96.192774},
{x:39.042944,y:-82.628692},
{x:40.052978,y:-74.140654},
{x:40.587909,y:-111.935755},
{x:30.051499,y:-95.429162},
{x:38.424148,y:-78.839447},
{x:43.213317,y:-75.464891},
{x:29.953453,y:-95.54633},
{x:40.710953,y:-112.09305},
{x:35.198124,y:-87.035323},
{x:36.133511,y:-115.084906},
{x:33.578998,y:-102.368169},
{x:42.063583,y:-76.323784},
{x:36.762851,y:-80.735166},
{x:41.454709,y:-74.40015},
{x:26.166848,y:-97.823697},
{x:40.680189,y:-73.75281},
{x:40.746073,y:-73.599669},
{x:40.700885,y:-73.942545},
{x:40.919674,y:-98.353332},
{x:27.855933,y:-97.629017},
{x:35.833684,y:-86.375201},
{x:42.598629,y:-76.201724},
{x:31.422323,y:-100.4669},
{x:36.199274,y:-115.280744},
{x:40.818969,y:-73.926943},
{x:28.632592,y:-96.629552},
{x:35.877699,y:-97.396307},
{x:29.774811,y:-95.809567},
{x:40.970116,y:-73.954337},
{x:41.121819,y:-100.764303},
{x:40.640524,y:-74.261938},
{x:42.568366,y:-88.117471},
{x:34.180805,y:-103.345504},
{x:47.062087,y:-122.765555},
{x:33.520099,y:-101.901652},
{x:43.04794,y:-78.877151},
{x:41.111333,y:-111.9841},
{x:41.040184,y:-81.490748},
{x:39.29431,y:-77.843079},
{x:32.739396,y:-96.292062},
{x:40.705464,y:-74.011004},
{x:41.51243,y:-74.206679},
{x:29.732051,y:-95.483868},
{x:40.234144,y:-75.24083},
{x:41.165258,y:-79.097957},
{x:40.63774,y:-73.909829},
{x:42.104259,y:-75.879854},
{x:43.514337,y:-96.757015},
{x:36.691327,y:-79.853545},
{x:37.347582,y:-79.505205},
{x:44.383135,y:-89.817469},
{x:36.581238,y:-79.347664},
{x:44.27396,y:-88.508046},
{x:41.005825,y:-76.434237},
{x:37.036675,y:-78.476783},
{x:48.207104,y:-101.31227},
{x:41.753165,y:-71.450727},
{x:35.863438,y:-83.527179},
{x:38.474228,y:-77.418051},
{x:33.834641,y:-81.090816},
{x:42.992005,y:-78.696342},
{x:28.811727,y:-97.857291},
{x:34.006375,y:-81.771067},
{x:41.460156,y:-84.55155},
{x:40.158631,y:-79.777409},
{x:40.182059,y:-74.869949},
{x:36.074614,y:-86.92283},
{x:28.808388,y:-96.981638},
{x:40.287782,y:-74.054159},
{x:35.551702,y:-87.540997},
{x:46.999428,y:-122.908407},
{x:39.162691,y:-78.183512},
{x:33.11543,y:-94.1824},
{x:32.998288,y:-80.03884},
{x:37.083867,y:-76.4586},
{x:39.469729,y:-79.672252},
{x:47.191594,y:-122.408886},
{x:40.393746,y:-75.501085},
{x:38.387246,y:-82.456856},
{x:42.735543,y:-73.761921},
{x:36.411516,y:-89.078424},
{x:43.113959,y:-76.146114},
{x:40.837425,y:-115.789562},
{x:30.7452,y:-98.231901},
{x:40.703486,y:-73.8211},
{x:42.12031,y:-79.197402},
{x:40.881314,y:-73.881645},
{x:40.81185,y:-82.955077},
{x:41.180728,y:-77.326065},
{x:42.73461,y:-78.83839},
{x:41.463952,y:-81.845504},
{x:40.135684,y:-79.843576},
{x:27.515434,y:-97.856854},
{x:36.669524,y:-76.948758},
{x:42.92787,y:-71.43788},
{x:41.002293,y:-79.324776},
{x:37.166585,y:-80.422101},
{x:39.436384,y:-80.182434},
{x:35.19851,y:-106.65675},
{x:47.23252,y:-119.87337},
{x:38.374516,y:-81.776881},
{x:35.564741,y:-97.656481},
{x:44.274534,y:-105.49477},
{x:41.203226,y:-79.973742},
{x:35.108033,y:-89.930328},
{x:41.099777,y:-81.384189},
{x:30.050101,y:-99.137029},
{x:42.591251,y:-88.430564},
{x:26.232208,y:-98.362135},
{x:30.10039,y:-93.820281},
{x:39.338782,y:-84.56049},
{x:44.047279,y:-75.806399},
{x:40.858675,y:-73.417556},
{x:35.103477,y:-106.603647},
{x:42.913472,y:-78.763132},
{x:35.890451,y:-84.550187},
{x:26.105155,y:-98.266553},
{x:31.507729,y:-97.144837},
{x:47.860105,y:-121.972521},
{x:28.983312,y:-95.938409},
{x:44.087919,y:-103.195003},
{x:41.27904,y:-80.843909},
{x:35.254688,y:-85.181463},
{x:40.74743,y:-74.147973},
{x:39.973582,y:-82.896344},
{x:43.116497,y:-75.289522},
{x:36.045208,y:-86.711827},
{x:44.251781,y:-121.185355},
{x:29.897455,y:-95.430923},
{x:43.476952,y:-75.319294},
{x:38.935754,y:-119.738732},
{x:40.73426,y:-74.173454},
{x:41.291721,y:-81.675826},
{x:41.673351,y:-83.721862},
{x:40.133586,y:-74.884966},
{x:40.74074,y:-73.321481},
{x:44.454228,y:-89.522739},
{x:29.699041,y:-95.809861},
{x:40.56237,y:-111.97264},
{x:39.458555,y:-75.209147},
{x:40.110207,y:-76.480462},
{x:42.779623,y:-71.498756},
{x:43.251247,y:-77.647304},
{x:40.634486,y:-73.641106},
{x:43.385544,y:-123.336533},
{x:39.836927,y:-83.080671},
{x:39.329364,y:-78.703532},
{x:39.215968,y:-78.143225},
{x:43.39142,y:-124.25313},
{x:42.745689,y:-73.64244},
{x:41.396572,y:-74.335464},
{x:30.336236,y:-95.468693},
{x:43.21356,y:-70.995143},
{x:42.856501,y:-106.334517},
{x:47.616825,y:-122.18336},
{x:40.708254,y:-73.690961},
{x:32.925906,y:-80.685127},
{x:42.509075,y:-78.688728},
{x:40.417437,y:-74.371595},
{x:40.978539,y:-81.781044},
{x:41.118157,y:-104.804016},
{x:41.438827,y:-97.354304},
{x:40.599198,y:-74.401496},
{x:47.304168,y:-122.225664},
{x:36.144815,y:-115.117981},
{x:42.448398,y:-75.042363},
{x:33.187208,y:-97.106691},
{x:42.901949,y:-78.696732},
{x:33.008,y:-96.55145},
{x:30.044206,y:-94.890723},
{x:40.739311,y:-74.977164},
{x:29.69961,y:-95.123164},
{x:41.440345,y:-81.728662},
{x:43.198045,y:-77.937153},
{x:41.69628,y:-83.635669},
{x:30.90863,y:-102.883756},
{x:47.388636,y:-122.66099},
{x:43.950212,y:-91.26019},
{x:29.562397,y:-95.277734},
{x:31.806559,y:-106.264161},
{x:40.801392,y:-72.865382},
{x:40.83322,y:-81.424398},
{x:40.037235,y:-74.824697},
{x:41.063945,y:-81.571713},
{x:41.344736,y:-82.066449},
{x:41.030287,y:-81.426994},
{x:31.055194,y:-97.452849},
{x:29.6756,y:-95.23399},
{x:40.714185,y:-73.38196},
{x:40.249444,y:-74.737739},
{x:35.084574,y:-81.708654},
{x:33.449134,y:-79.554434},
{x:40.650604,y:-74.218974},
{x:30.447866,y:-97.791039},
{x:34.774184,y:-80.382366},
{x:45.633577,y:-89.407907},
{x:41.4628,y:-81.09194},
{x:33.953413,y:-96.417599},
{x:33.778119,y:-78.792243},
{x:42.725316,y:-87.95124},
{x:32.32817,y:-106.78338},
{x:36.261692,y:-115.18044},
{x:40.643202,y:-75.345956},
{x:29.812372,y:-95.40003},
{x:31.130545,y:-97.79858},
{x:39.604852,y:-119.836824},
{x:40.858597,y:-73.620134},
{x:43.023479,y:-71.075187},
{x:39.533995,y:-119.866442},
{x:39.24265,y:-84.594972},
{x:30.51779,y:-97.828798},
{x:29.538903,y:-98.286096},
{x:45.492248,y:-122.486422},
{x:36.014709,y:-86.618513},
{x:40.202367,y:-76.015586},
{x:36.116493,y:-83.488799},
{x:30.123447,y:-95.405941},
{x:40.244417,y:-75.614705},
{x:36.418905,y:-99.385976},
{x:40.837413,y:-73.860296},
{x:35.236048,y:-106.656792},
{x:42.084704,y:-78.429712},
{x:40.254564,y:-76.834103},
{x:31.13833,y:-81.57987},
{x:40.652668,y:-73.91972},
{x:42.95248,y:-78.60289},
{x:35.131213,y:-106.517144},
{x:41.406324,y:-84.131337},
{x:38.853708,y:-77.319297},
{x:43.576088,y:-89.779323},
{x:40.984929,y:-73.853002},
{x:26.200111,y:-98.203166},
{x:40.023057,y:-76.197295},
{x:32.417483,y:-99.527294},
{x:40.685788,y:-73.345292},
{x:47.375347,y:-122.201546},
{x:39.122041,y:-84.602098},
{x:45.770764,y:-122.54677},
{x:30.286248,y:-97.66403},
{x:42.911011,y:-78.866698},
{x:44.105606,y:-103.202939},
{x:27.686754,y:-99.463314},
{x:33.930903,y:-81.29122},
{x:47.296385,y:-122.251039},
{x:40.558992,y:-81.807386},
{x:26.269951,y:-98.235026},
{x:41.624194,y:-81.439743},
{x:38.449179,y:-78.865258},
{x:30.413105,y:-97.674215},
{x:41.879262,y:-79.151574},
{x:36.264532,y:-86.711946},
{x:36.211286,y:-115.98567},
{x:39.607105,y:-119.2224},
{x:32.93076,y:-96.8117},
{x:29.828651,y:-95.375225},
{x:40.767725,y:-81.521895},
{x:36.022256,y:-84.24593},
{x:47.321832,y:-122.587915},
{x:41.520557,y:-81.453899},
{x:39.06439,y:-77.460813},
{x:43.206589,y:-77.691011},
{x:41.298483,y:-96.005059},
{x:39.967744,y:-74.684462},
{x:41.733743,y:-71.435626},
{x:26.298732,y:-98.146423},
{x:40.833094,y:-83.245935},
{x:42.145832,y:-80.001039},
{x:45.854696,y:-119.28954},
{x:42.076673,y:-78.463632},
{x:32.910629,y:-97.544},
{x:41.543971,y:-96.125673},
{x:44.837482,y:-73.081773},
{x:32.531894,y:-96.818963},
{x:34.623894,y:-98.43334},
{x:32.329216,y:-96.625079},
{x:26.150155,y:-98.255044},
{x:35.463141,y:-86.784318},
{x:40.866497,y:-73.825773},
{x:33.46708,y:-94.04415},
{x:40.588511,y:-74.226632},
{x:27.50579,y:-99.508682},
{x:43.076078,y:-76.088546},
{x:41.711576,y:-71.489039},
{x:36.618376,y:-82.567823},
{x:40.847438,y:-73.900815},
{x:43.147154,y:-78.69746},
{x:42.848703,y:-78.80103},
{x:43.111108,y:-89.508949},
{x:29.448248,y:-98.450729},
{x:44.913991,y:-122.979195},
{x:40.044164,y:-75.101688},
{x:37.25991,y:-77.328291},
{x:46.264362,y:-96.61379},
{x:32.95109,y:-96.89003},
{x:40.510194,y:-81.452288},
{x:34.814137,y:-106.75462},
{x:41.320028,y:-75.941491},
{x:33.415201,y:-104.522485},
{x:40.238431,y:-76.980939},
{x:33.223662,y:-96.975315},
{x:36.249977,y:-95.92851},
{x:40.762049,y:-74.159623},
{x:40.340282,y:-75.980629},
{x:46.190768,y:-123.839256},
{x:35.435525,y:-97.567344},
{x:40.56301,y:-74.578244},
{x:29.572585,y:-95.7753},
{x:36.239605,y:-115.228619},
{x:39.431839,y:-84.192838},
{x:43.007465,y:-78.949774},
{x:35.527418,y:-98.693702},
{x:29.672138,y:-95.278398},
{x:40.817392,y:-76.200073},
{x:41.971366,y:-76.539399},
{x:41.141781,y:-73.990641},
{x:40.674536,y:-73.485711},
{x:39.877518,y:-84.306743},
{x:41.463984,y:-75.650992},
{x:36.821087,y:-76.068061},
{x:40.212166,y:-74.015502},
{x:41.177598,y:-80.56751},
{x:40.720554,y:-73.94426},
{x:40.592621,y:-73.961032},
{x:40.65739,y:-73.900547},
{x:41.216111,y:-73.719164},
{x:31.570463,y:-94.674766},
{x:41.13828,y:-112.063953},
{x:41.228664,y:-111.977906},
{x:40.672041,y:-74.221456},
{x:40.61997,y:-79.730521},
{x:32.83524,y:-96.67459},
{x:38.911911,y:-78.187986},
{x:45.494666,y:-122.578277},
{x:40.682363,y:-99.085998},
{x:34.894968,y:-82.162286},
{x:43.802785,y:-123.045632},
{x:41.320993,y:-80.730791},
{x:29.922416,y:-95.412114},
{x:47.044711,y:-122.940787},
{x:43.319809,y:-76.41385},
{x:46.30866,y:-119.977976},
{x:34.196548,y:-79.704001},
{x:38.411796,y:-82.387507},
{x:40.837215,y:-73.88483},
{x:41.310752,y:-81.447962},
{x:30.216892,y:-97.754939},
{x:32.831551,y:-96.839549},
{x:45.077015,y:-87.659453},
{x:39.966324,y:-75.527566},
{x:34.362315,y:-80.063649},
{x:30.230372,y:-95.568216},
{x:29.714895,y:-95.27858},
{x:35.068229,y:-89.944637},
{x:41.225735,y:-82.601103},
{x:35.048733,y:-89.834969},
{x:35.458386,y:-97.601606},
{x:38.860771,y:-77.094829},
{x:39.190508,y:-119.772688},
{x:40.322186,y:-76.788495},
{x:37.23919,y:-81.258446},
{x:39.082684,y:-78.14901},
{x:40.890879,y:-73.897869},
{x:32.793482,y:-96.61164},
{x:30.346201,y:-97.38243},
{x:41.967691,y:-75.74017},
{x:33.56408,y:-81.7665},
{x:41.427752,y:-75.623674},
{x:44.690358,y:-75.476075},
{x:41.024241,y:-80.707788},
{x:39.556779,y:-119.489536},
{x:41.212135,y:-80.756264},
{x:35.222359,y:-101.829437},
{x:47.362642,y:-122.02081},
{x:42.890815,y:-70.872058},
{x:40.769185,y:-73.5009},
{x:29.443555,y:-98.361272},
{x:44.933374,y:-89.662345},
{x:39.452135,y:-119.777082},
{x:42.986433,y:-87.947882},
{x:34.668964,y:-98.414775},
{x:35.463427,y:-97.72408},
{x:41.824049,y:-71.498256},
{x:40.636497,y:-74.075562},
{x:39.980548,y:-74.888566},
{x:40.656348,y:-80.237239},
{x:40.704433,y:-84.151886},
{x:39.095241,y:-84.266723},
{x:27.667618,y:-97.28358},
{x:36.715229,y:-81.93846},
{x:40.729927,y:-74.040944},
{x:36.197143,y:-115.126915},
{x:41.111293,y:-74.106966},
{x:40.507442,y:-111.980589},
{x:41.370393,y:-75.736261},
{x:36.084127,y:-87.799988},
{x:36.126334,y:-86.677446},
{x:43.104258,y:-79.052377},
{x:41.299846,y:-95.957008},
{x:41.118904,y:-81.482351},
{x:29.35698,y:-98.129535},
{x:41.035188,y:-74.032599},
{x:40.451242,y:-79.713701},
{x:41.161832,y:-75.885733},
{x:36.194687,y:-115.062415},
{x:36.95521,y:-76.253019},
{x:46.592429,y:-90.883219},
{x:33.847996,y:-79.067035},
{x:36.838006,y:-76.180245},
{x:40.652364,y:-74.216601},
{x:46.585676,y:-120.544668},
{x:39.712766,y:-75.111038},
{x:40.792188,y:-96.696991},
{x:41.23414,y:-96.13938},
{x:47.243307,y:-122.362848},
{x:33.954322,y:-80.924849},
{x:44.35681,y:-89.11336},
{x:40.699548,y:-111.850159},
{x:36.85984,y:-76.209261},
{x:26.173556,y:-97.674254},
{x:39.267067,y:-80.37341},
{x:32.283749,y:-106.759347},
{x:41.727418,y:-73.932255},
{x:40.689284,y:-73.981238},
{x:35.625347,y:-97.484733},
{x:37.462378,y:-77.442799},
{x:31.824734,y:-106.407476},
{x:29.675186,y:-95.546432},
{x:39.5264,y:-119.82803},
{x:41.612739,y:-83.699871},
{x:35.174265,y:-89.793742},
{x:30.54959,y:-95.500267},
{x:29.735607,y:-95.605423},
{x:27.676925,y:-97.365349},
{x:43.087309,y:-88.065862},
{x:29.693465,y:-96.540232},
{x:27.302675,y:-97.815714},
{x:40.572625,y:-74.336421},
{x:35.922257,y:-84.059332},
{x:36.784311,y:-76.000821},
{x:31.443841,y:-97.408408},
{x:40.72199,y:-73.803849},
{x:40.055963,y:-75.003637},
{x:34.688352,y:-82.810454},
{x:29.435696,y:-98.706063},
{x:35.012828,y:-85.228662},
{x:40.700243,y:-111.88439},
{x:35.641002,y:-106.015786},
{x:38.979375,y:-77.422961},
{x:40.369634,y:-80.011573},
{x:36.055223,y:-115.27666},
{x:45.494947,y:-122.873367},
{x:41.198359,y:-74.48245},
{x:29.770852,y:-94.976933},
{x:43.114238,y:-75.205464},
{x:43.935221,y:-90.821189},
{x:29.65251,y:-95.030533},
{x:40.332382,y:-83.76106},
{x:28.668078,y:-99.181593},
{x:34.963591,y:-82.431241},
{x:40.03196,y:-75.179001},
{x:40.654491,y:-73.838973},
{x:40.932158,y:-74.025994},
{x:41.46421,y:-81.763142},
{x:35.642826,y:-86.89716},
{x:29.558768,y:-95.347181},
{x:40.75701,y:-75.59371},
{x:32.990299,y:-80.101222},
{x:30.18185,y:-96.933675},
{x:46.241866,y:-119.123655},
{x:32.9117,y:-96.6303},
{x:27.598819,y:-99.456157},
{x:40.443809,y:-79.231234},
{x:39.083391,y:-84.152587},
{x:41.25911,y:-111.970853},
{x:42.623075,y:-88.61899},
{x:39.354108,y:-84.369084},
{x:34.034917,y:-81.004032},
{x:42.783661,y:-73.948341},
{x:31.728171,y:-96.182578},
{x:41.814948,y:-71.420209},
{x:40.789936,y:-77.034908},
{x:29.348797,y:-99.114307},
{x:41.873445,y:-103.671612},
{x:29.395456,y:-94.931401},
{x:29.980395,y:-95.163822},
{x:36.840883,y:-76.097652},
{x:48.100185,y:-98.83832},
{x:32.840227,y:-97.289514},
{x:41.017556,y:-74.300437},
{x:40.787905,y:-73.217664},
{x:41.105609,y:-74.029464},
{x:41.427071,y:-82.119056},
{x:44.883109,y:-88.138815},
{x:41.232942,y:-75.496409},
{x:40.87707,y:-73.84615},
{x:44.883582,y:-91.42794},
{x:35.955776,y:-95.394827},
{x:47.807373,y:-122.33625},
{x:39.906673,y:-82.017915},
{x:40.707961,y:-73.708181},
{x:39.892789,y:-82.995544},
{x:33.02813,y:-96.77066},
{x:34.173148,y:-97.162584},
{x:38.011087,y:-78.504963},
{x:44.77162,y:-91.463476},
{x:36.3144,y:-115.3083},
{x:40.876489,y:-74.086222},
{x:41.202584,y:-81.438934},
{x:47.888995,y:-97.073965},
{x:44.628406,y:-123.083035},
{x:40.44634,y:-75.360577},
{x:40.178729,y:-80.260389},
{x:29.891902,y:-95.063738},
{x:42.169763,y:-77.101305},
{x:37.680575,y:-113.078465},
{x:33.577371,y:-81.702349},
{x:40.296915,y:-111.695421},
{x:29.3532,y:-95.4939},
{x:39.99515,y:-81.575701},
{x:29.462466,y:-98.434661},
{x:42.770752,y:-78.811168},
{x:40.841554,y:-73.307954},
{x:41.313838,y:-110.509571},
{x:40.804364,y:-74.019096},
{x:35.657199,y:-88.852394},
{x:41.078546,y:-75.575665},
{x:32.34358,y:-95.340868},
{x:39.353325,y:-82.976954},
{x:40.629222,y:-75.485029},
{x:43.234863,y:-70.880479},
{x:38.421259,y:-78.8934},
{x:40.109441,y:-82.928032},
{x:34.866821,y:-82.464722},
{x:42.668378,y:-73.776051},
{x:32.297823,y:-106.811654},
{x:34.775746,y:-82.308279},
{x:36.738276,y:-97.067557},
{x:34.986861,y:-81.225751},
{x:40.271078,y:-96.747127},
{x:40.829985,y:-73.98004},
{x:37.195251,y:-77.467665},
{x:40.426557,y:-80.056329},
{x:36.509643,y:-87.275154},
{x:41.458553,y:-74.055381},
{x:36.833775,y:-76.340114},
{x:47.270877,y:-122.516855},
{x:43.886634,y:-89.48679},
{x:41.679478,y:-71.581897},
{x:43.7567,y:-87.721945},
{x:43.154697,y:-77.56888},
{x:39.352414,y:-83.380844},
{x:42.89729,y:-87.91253},
{x:43.868485,y:-91.210448},
{x:40.564266,y:-84.176006},
{x:35.144238,y:-90.016345},
{x:39.362771,y:-78.042925},
{x:40.178052,y:-74.631133},
{x:35.459336,y:-86.054152},
{x:43.055976,y:-89.501109},
{x:32.757125,y:-96.995021},
{x:46.91741,y:-98.022754},
{x:42.696278,y:-73.882187},
{x:35.381513,y:-96.930337},
{x:29.392536,y:-98.629814},
{x:32.720308,y:-96.904279},
{x:42.762549,y:-87.802324},
{x:42.916974,y:-87.930156},
{x:46.257263,y:-119.306595},
{x:35.153077,y:-88.578166},
{x:33.70293,y:-80.240151},
{x:36.410251,y:-86.78757},
{x:47.625226,y:-117.535648},
{x:31.125212,y:-97.723036},
{x:36.799185,y:-76.177402},
{x:45.608192,y:-121.203455},
{x:39.898199,y:-83.435706},
{x:39.262251,y:-80.286999},
{x:30.582212,y:-97.857951},
{x:44.697297,y:-73.476481},
{x:42.719854,y:-87.853455},
{x:39.44418,y:-82.213299},
{x:30.577896,y:-98.2733},
{x:30.036762,y:-95.48637},
{x:31.406826,y:-103.485495},
{x:39.871412,y:-75.081545},
{x:32.071047,y:-96.504056},
{x:26.193363,y:-98.23173},
{x:26.1888,y:-97.716564},
{x:38.639842,y:-77.299294},
{x:40.852446,y:-74.781093},
{x:32.47843,y:-80.97324},
{x:31.86495,y:-106.57649},
{x:36.160385,y:-95.886329},
{x:35.750076,y:-83.998157},
{x:39.907565,y:-79.741635},
{x:41.501644,y:-81.532069},
{x:42.057269,y:-104.170542},
{x:39.902519,y:-74.834243},
{x:44.730674,y:-88.446799},
{x:29.357102,y:-98.494982},
{x:34.821344,y:-82.282306},
{x:41.243671,y:-76.833284},
{x:40.970285,y:-75.631335},
{x:45.149575,y:-122.87797},
{x:34.191784,y:-79.809563},
{x:33.513579,y:-80.860474},
{x:29.642005,y:-95.241169},
{x:35.15671,y:-89.727847},
{x:32.90757,y:-96.44961},
{x:40.232777,y:-79.582465},
{x:41.023872,y:-80.637392},
{x:47.571066,y:-122.661455},
{x:41.234831,y:-96.191664},
{x:40.7721,y:-111.938198},
{x:35.887722,y:-94.97636},
{x:38.291634,y:-77.564865},
{x:31.869198,y:-106.335445},
{x:42.810281,y:-73.990646},
{x:40.667201,y:-111.985526},
{x:29.801078,y:-95.452089},
{x:41.122807,y:-78.729321},
{x:40.277229,y:-111.685911},
{x:41.81634,y:-71.4452},
{x:40.709482,y:-74.01164},
{x:41.22001,y:-112.0253},
{x:34.423742,y:-103.196953},
{x:34.981933,y:-81.970097},
{x:43.047038,y:-76.070306},
{x:38.705546,y:-77.228849},
{x:36.159496,y:-84.091354},
{x:41.140671,y:-95.975508},
{x:40.690417,y:-73.72361},
{x:43.00188,y:-75.979539},
{x:29.658891,y:-95.508875},
{x:35.60586,y:-105.21184},
{x:35.027469,y:-97.935728},
{x:38.753497,y:-112.08536},
{x:34.107035,y:-80.83252},
{x:39.896106,y:-83.811686},
{x:34.022228,y:-80.943122},
{x:39.542821,y:-82.425147},
{x:41.010749,y:-95.907529},
{x:29.592817,y:-98.35094},
{x:43.038051,y:-89.393153},
{x:41.608392,y:-81.525569},
{x:44.45112,y:-88.086788},
{x:35.187027,y:-86.110842},
{x:36.099714,y:-115.100978},
{x:37.344633,y:-79.954985},
{x:29.586697,y:-95.211983},
{x:48.286331,y:-122.65909},
{x:41.041836,y:-75.312478},
{x:30.089433,y:-95.63173},
{x:40.685351,y:-74.100002},
{x:42.123677,y:-80.044164},
{x:37.856948,y:-82.045396},
{x:42.007839,y:-71.507947},
{x:29.595077,y:-95.526273},
{x:38.73893,y:-82.972073},
{x:40.667044,y:-73.70411},
{x:40.010206,y:-79.109432},
{x:40.993067,y:-75.183875},
{x:41.176403,y:-112.063797},
{x:34.4786,y:-82.649276},
{x:41.290035,y:-96.080812},
{x:40.984328,y:-74.137535},
{x:41.22004,y:-75.88483},
{x:40.560338,y:-74.264526},
{x:38.286913,y:-77.437935},
{x:43.052966,y:-75.865552},
{x:37.741701,y:-79.358126},
{x:39.686727,y:-74.990825},
{x:34.313731,y:-82.66205},
{x:35.182108,y:-82.158764},
{x:40.630037,y:-74.40039},
{x:35.49344,y:-97.508501},
{x:39.743478,y:-84.119526},
{x:39.190544,y:-84.256561},
{x:46.267869,y:-119.18213},
{x:39.308946,y:-80.275194},
{x:30.018131,y:-95.438526},
{x:39.760802,y:-84.252891},
{x:41.78236,y:-76.450073},
{x:36.21788,y:-115.242114},
{x:37.632917,y:-77.458089},
{x:39.742844,y:-84.182792},
{x:42.250064,y:-121.789273},
{x:29.628783,y:-95.464489},
{x:43.106962,y:-78.984481},
{x:31.116726,y:-97.41458},
{x:29.430095,y:-98.550908},
{x:32.764398,y:-96.895319},
{x:40.766163,y:-73.724382},
{x:42.946927,y:-74.357969},
{x:43.543763,y:-96.759077},
{x:40.623318,y:-111.917},
{x:48.504381,y:-122.248873},
{x:43.024587,y:-74.986943},
{x:40.287272,y:-84.187421},
{x:29.664591,y:-95.463985},
{x:32.7923,y:-96.69884},
{x:47.166952,y:-122.515694},
{x:39.832787,y:-75.003889},
{x:34.368297,y:-81.100179},
{x:45.56771,y:-122.538557},
{x:41.743424,y:-74.072566},
{x:45.489819,y:-122.794369},
{x:32.608514,y:-97.117471},
{x:39.265381,y:-74.649355},
{x:35.38092,y:-86.22818},
{x:39.618571,y:-79.923125},
{x:39.584297,y:-82.953998},
{x:32.628532,y:-97.322354},
{x:40.737631,y:-73.795535},
{x:42.532006,y:-88.588707},
{x:40.779065,y:-74.175988},
{x:32.650162,y:-97.060362},
{x:37.374732,y:-77.504541},
{x:32.205202,y:-95.838378},
{x:36.128164,y:-115.137688},
{x:34.161522,y:-82.383399},
{x:41.925843,y:-73.992003},
{x:35.093827,y:-94.63125},
{x:44.598547,y:-75.147626},
{x:40.773913,y:-74.028114},
{x:34.633332,y:-82.784542},
{x:43.0191,y:-78.874735},
{x:36.173659,y:-115.116801},
{x:41.292497,y:-81.671273},
{x:34.60908,y:-79.678986},
{x:29.729614,y:-99.07729},
{x:32.332379,y:-95.265251},
{x:33.567933,y:-81.938609},
{x:42.985009,y:-78.822989},
{x:38.475537,y:-81.671031},
{x:40.090214,y:-76.346954},
{x:27.530952,y:-99.481956},
{x:36.069672,y:-95.797816},
{x:43.115223,y:-77.621983},
{x:36.084384,y:-86.704194},
{x:39.969215,y:-81.902152},
{x:40.83962,y:-73.917675},
{x:46.144094,y:-122.900216},
{x:47.102185,y:-119.248349},
{x:45.303333,y:-122.771116},
{x:32.870831,y:-97.342415},
{x:39.98355,y:-76.66517},
{x:32.720811,y:-96.835129},
{x:41.275159,y:-81.83686},
{x:36.687967,y:-78.899647},
{x:40.858525,y:-84.597622},
{x:33.909916,y:-82.29402},
{x:45.504138,y:-122.435352},
{x:39.376461,y:-84.378061},
{x:41.116899,y:-95.919835},
{x:37.290986,y:-80.071614},
{x:36.531702,y:-82.528754},
{x:42.837203,y:-72.56569},
{x:31.90421,y:-106.42302},
{x:35.184654,y:-106.663203},
{x:34.198767,y:-79.256935},
{x:38.422309,y:-82.263016},
{x:41.434392,y:-81.8001},
{x:34.728368,y:-81.63858},
{x:31.604212,y:-97.107826},
{x:39.428209,y:-74.505105},
{x:41.146963,y:-81.508059},
{x:31.754248,y:-106.344609},
{x:36.550839,y:-82.56397},
{x:41.634798,y:-83.562745},
{x:39.759137,y:-74.247493},
{x:40.49373,y:-77.113594},
{x:36.043007,y:-115.187621},
{x:46.26305,y:-119.083397},
{x:38.134662,y:-79.044068},
{x:34.142114,y:-80.942306},
{x:44.022754,y:-88.537246},
{x:33.45914,y:-94.08907},
{x:40.713223,y:-73.76555},
{x:39.28591,y:-84.44873},
{x:35.140711,y:-106.551703},
{x:39.78615,y:-77.710963},
{x:39.939072,y:-83.816878},
{x:34.968896,y:-80.993317},
{x:40.117381,y:-75.137625},
{x:37.273256,y:-79.89067},
{x:44.520387,y:-88.066239},
{x:47.400421,y:-122.227433},
{x:36.304119,y:-86.691144},
{x:35.39254,y:-97.565641},
{x:39.744227,y:-77.552093},
{x:42.254535,y:-73.766402},
{x:40.807241,y:-73.236296},
{x:32.743422,y:-96.822327},
{x:36.047648,y:-115.171822},
{x:39.904065,y:-75.061366},
{x:44.442077,y:-73.210457},
{x:29.9788,y:-95.26062},
{x:41.137667,y:-104.78963},
{x:39.47807,y:-75.000766},
{x:39.987915,y:-83.02825},
{x:40.024819,y:-75.148169},
{x:31.53031,y:-97.188919},
{x:38.132791,y:-79.062923},
{x:41.713458,y:-73.963351},
{x:36.140085,y:-85.266297},
{x:44.086166,y:-87.68596},
{x:41.913673,y:-80.568024},
{x:30.077532,y:-94.16969},
{x:42.327219,y:-77.66189},
{x:29.802498,y:-95.487356},
{x:41.518574,y:-71.299505},
{x:39.358202,y:-79.995232},
{x:33.282567,y:-96.563063},
{x:36.811594,y:-76.749994},
{x:45.610995,y:-122.676865},
{x:30.173441,y:-97.823229},
{x:41.10488,y:-80.88803},
{x:40.324956,y:-75.130758},
{x:43.627511,y:-72.322417},
{x:39.422398,y:-78.99743},
{x:35.741383,y:-86.893849},
{x:32.864728,y:-96.992236},
{x:42.210802,y:-121.756347},
{x:27.506143,y:-99.48159},
{x:35.196738,y:-101.816968},
{x:41.356383,y:-74.284022},
{x:43.186143,y:-76.233955},
{x:36.176153,y:-82.798647},
{x:42.681315,y:-73.839015},
{x:40.698657,y:-73.918875},
{x:40.858812,y:-73.078561},
{x:40.730299,y:-111.987889},
{x:40.716265,y:-74.004675},
{x:46.811953,y:-119.172723},
{x:26.22983,y:-98.323683},
{x:35.030628,y:-85.184104},
{x:42.512952,y:-75.522463},
{x:39.259716,y:-84.601907},
{x:41.048113,y:-73.807515},
{x:43.28693,y:-76.126727},
{x:31.997066,y:-106.584451},
{x:42.894145,y:-78.835012},
{x:32.66585,y:-97.40088},
{x:29.317892,y:-99.466681},
{x:35.787217,y:-86.368116},
{x:38.661448,y:-78.491605},
{x:43.017012,y:-87.996823},
{x:29.848504,y:-95.343746},
{x:29.562056,y:-98.329302},
{x:41.605976,y:-71.455888},
{x:39.851667,y:-82.827903},
{x:44.311929,y:-96.77367},
{x:40.074552,y:-80.872594},
{x:41.002986,y:-73.812827},
{x:40.223442,y:-80.207871},
{x:41.642981,y:-81.468782},
{x:40.028502,y:-82.441461},
{x:40.027293,y:-83.160686},
{x:42.974114,y:-71.475787},
{x:43.01188,y:-78.32903},
{x:40.763093,y:-73.877984},
{x:39.63897,y:-119.711093},
{x:32.719081,y:-96.617687},
{x:32.41202,y:-104.22913},
{x:41.887083,y:-71.402254},
{x:33.17801,y:-96.88878},
{x:47.380855,y:-122.249278},
{x:29.938712,y:-95.461487},
{x:31.964268,y:-95.268349},
{x:29.663966,y:-95.122002},
{x:46.125972,y:-122.941364},
{x:35.197268,y:-89.976097},
{x:40.984436,y:-81.493441},
{x:33.914742,y:-81.132877},
{x:34.849578,y:-82.338267},
{x:30.420315,y:-95.496933},
{x:39.906403,y:-74.977802},
{x:46.074797,y:-118.310365},
{x:40.090221,y:-75.370483},
{x:32.96933,y:-80.004485},
{x:35.041533,y:-82.089072},
{x:41.066666,y:-74.135296},
{x:40.838092,y:-96.705088},
{x:39.222963,y:-84.517859},
{x:36.43646,y:-82.305872},
{x:34.806202,y:-106.702641},
{x:40.590253,y:-73.975026},
{x:40.844547,y:-74.228594},
{x:42.731939,y:-71.425444},
{x:29.81337,y:-95.562503},
{x:35.764349,y:-89.542149},
{x:35.31927,y:-106.57488},
{x:34.510264,y:-82.7557},
{x:40.027632,y:-74.618105},
{x:29.487064,y:-98.665707},
{x:40.095109,y:-75.293108},
{x:30.010299,y:-95.799345},
{x:44.011779,y:-88.584157},
{x:40.578226,y:-75.455028},
{x:35.510851,y:-97.973077},
{x:41.160055,y:-104.521193},
{x:41.82343,y:-75.67869},
{x:44.048474,y:-123.149033},
{x:40.158542,y:-74.427374},
{x:46.605816,y:-122.904062},
{x:39.870807,y:-77.029319},
{x:41.187074,y:-111.948503},
{x:40.93593,y:-73.995159},
{x:45.520499,y:-123.043752},
{x:29.780006,y:-98.728601},
{x:35.652326,y:-97.458672},
{x:37.395267,y:-76.527389},
{x:30.213172,y:-97.71109},
{x:40.577368,y:-74.293941},
{x:44.504141,y:-73.181192},
{x:33.547278,y:-101.922844},
{x:37.047004,y:-76.391952},
{x:37.094513,y:-81.810781},
{x:41.565964,y:-83.656235},
{x:34.791598,y:-82.376673},
{x:29.3648,y:-98.693978},
{x:35.084199,y:-85.064536},
{x:31.112086,y:-97.746857},
{x:47.545546,y:-122.063609},
{x:39.878784,y:-75.239204},
{x:45.593875,y:-122.681538},
{x:46.226193,y:-119.220452},
{x:35.593472,y:-89.258124},
{x:41.418379,y:-82.200475},
{x:34.247132,y:-80.600036},
{x:47.054376,y:-122.398748},
{x:35.009847,y:-106.064963},
{x:45.539778,y:-122.477865},
{x:47.929898,y:-122.100865},
{x:29.351644,y:-98.430541},
{x:40.469502,y:-74.470336},
{x:36.074919,y:-87.377048},
{x:36.57694,y:-86.52258},
{x:40.240445,y:-74.277551},
{x:40.621722,y:-74.025869},
{x:34.658691,y:-83.086995},
{x:33.09776,y:-96.79633},
{x:28.316361,y:-97.27283},
{x:26.176855,y:-97.991347},
{x:41.57758,y:-74.08553},
{x:35.64741,y:-95.962833},
{x:32.406808,y:-99.768005},
{x:28.87224,y:-96.995829},
{x:32.330521,y:-96.116014},
{x:33.750495,y:-79.447026},
{x:39.035704,y:-77.410164},
{x:31.998854,y:-102.107587},
{x:35.961555,y:-85.035232},
{x:42.90056,y:-78.894601},
{x:35.13843,y:-106.640976},
{x:26.203641,y:-98.165791},
{x:43.137738,y:-76.226448},
{x:41.237826,y:-81.803848},
{x:32.813511,y:-96.947748},
{x:41.666115,y:-74.676132},
{x:32.733833,y:-96.650276},
{x:45.472599,y:-123.844568},
{x:31.333688,y:-94.719363},
{x:42.649514,y:-89.030709},
{x:40.335628,y:-80.596215},
{x:32.705231,y:-100.866003},
{x:41.348676,y:-81.823263},
{x:33.977207,y:-80.48658},
{x:42.126647,y:-77.968734},
{x:40.288773,y:-76.976944},
{x:40.750174,y:-73.862056},
{x:34.780484,y:-96.670294},
{x:32.268069,y:-95.30768},
{x:35.213701,y:-84.868799},
{x:41.504583,y:-81.626577},
{x:44.639581,y:-123.068388},
{x:34.141672,y:-81.23564},
{x:40.089201,y:-75.380001},
{x:39.828677,y:-75.126444},
{x:32.90395,y:-96.127346},
{x:40.862687,y:-82.282844},
{x:34.727946,y:-82.388519},
{x:38.39834,y:-82.569052},
{x:43.087268,y:-78.979183},
{x:39.687697,y:-84.13036},
{x:39.071418,y:-84.305236},
{x:35.181257,y:-97.500811},
{x:29.831241,y:-95.318579},
{x:39.666776,y:-86.095625},
{x:40.741257,y:-111.899259},
{x:40.643833,y:-74.01192},
{x:40.75765,y:-73.991238},
{x:32.798889,y:-97.322482},
{x:43.10202,y:-77.481893},
{x:40.093645,y:-74.941935},
{x:40.259033,y:-83.374187},
{x:44.072727,y:-123.04364},
{x:31.797857,y:-106.229609},
{x:41.576899,y:-109.251849},
{x:41.16604,y:-81.403088},
{x:36.467816,y:-81.802869},
{x:36.008217,y:-83.975871},
{x:40.778766,y:-73.563855},
{x:33.43498,y:-94.07526},
{x:41.089542,y:-112.002258},
{x:40.836133,y:-81.328626},
{x:29.564181,y:-98.687825},
{x:43.053252,y:-76.16996},
{x:43.025863,y:-88.197504},
{x:35.533722,y:-108.75935},
{x:36.736361,y:-108.187314},
{x:42.529616,y:-78.478935},
{x:40.241049,y:-76.888022},
{x:36.785759,y:-76.255078},
{x:36.288968,y:-88.314412},
{x:43.609629,y:-72.973894},
{x:31.737627,y:-98.957779},
{x:40.638143,y:-73.895112},
{x:36.059838,y:-88.116242},
{x:29.921092,y:-95.496467},
{x:41.537499,y:-73.899634},
{x:44.227235,y:-72.553002},
{x:31.072357,y:-97.709519},
{x:36.208799,y:-86.291163},
{x:40.711973,y:-73.429219},
{x:29.288998,y:-94.832873},
{x:41.040768,y:-82.714867},
{x:45.394348,y:-122.747231},
{x:39.62568,y:-79.991058},
{x:40.568341,y:-74.551716},
{x:43.624874,y:-89.795473},
{x:33.923912,y:-80.334942},
{x:26.303385,y:-98.179718},
{x:29.561419,y:-95.142104},
{x:29.980526,y:-95.216534},
{x:40.836294,y:-74.18105},
{x:44.230564,y:-88.42541},
{x:34.445583,y:-82.387293},
{x:34.733046,y:-80.776729},
{x:34.84467,y:-82.419876},
{x:44.309272,y:-71.793477},
{x:34.817496,y:-82.420451},
{x:36.045705,y:-95.855509},
{x:40.219143,y:-111.65936},
{x:35.4297,y:-97.402974},
{x:30.26014,y:-98.884155},
{x:45.52,y:-122.56},
{x:39.961066,y:-82.475696},
{x:36.95671,y:-76.603123},
{x:42.961186,y:-78.697572},
{x:41.450646,y:-96.470995},
{x:35.956963,y:-84.003217},
{x:38.312759,y:-81.567544},
{x:36.160342,y:-95.793952},
{x:39.332777,y:-83.015523},
{x:47.90826,y:-122.206237},
{x:40.76473,y:-73.820571},
{x:41.550264,y:-83.601503},
{x:43.206412,y:-78.398951},
{x:40.739682,y:-73.295725},
{x:40.192135,y:-74.712883},
{x:41.165704,y:-112.025532},
{x:32.81136,y:-96.62976},
{x:37.320651,y:-80.031758},
{x:40.896201,y:-74.038326},
{x:48.784289,y:-122.484964},
{x:32.6917,y:-96.85878},
{x:34.956419,y:-82.127996},
{x:32.415439,y:-97.230704},
{x:32.66366,y:-97.23986},
{x:41.463912,y:-81.687365},
{x:43.217021,y:-71.506171},
{x:34.54736,y:-82.631818},
{x:37.035397,y:-76.363521},
{x:40.86192,y:-76.789527},
{x:36.84162,y:-81.506485},
{x:41.226009,y:-80.451976},
{x:43.604031,y:-96.710834},
{x:41.261456,y:-96.178594},
{x:46.605486,y:-120.481273},
{x:41.75194,y:-111.833901},
{x:37.499398,y:-77.090319},
{x:44.613228,y:-121.135451},
{x:39.2744,y:-81.58529},
{x:39.864908,y:-84.138433},
{x:31.827226,y:-106.444783},
{x:40.17675,y:-74.628947},
{x:33.11594,y:-95.59173},
{x:40.69003,y:-112.262982},
{x:40.725913,y:-73.238096},
{x:38.406171,y:-78.651003},
{x:43.165836,y:-77.692351},
{x:36.000271,y:-106.063369},
{x:48.994771,y:-122.734158},
{x:42.938415,y:-89.383159},
{x:33.681457,y:-79.000473},
{x:40.932136,y:-73.899276},
{x:42.670036,y:-88.540156},
{x:29.836276,y:-94.382912},
{x:40.862569,y:-73.896334},
{x:29.770454,y:-95.602638},
{x:40.680531,y:-73.949369},
{x:41.265857,y:-96.235294},
{x:32.962941,y:-96.339756},
{x:40.285525,y:-79.547898},
{x:43.23052,y:-75.438637},
{x:37.20768,y:-76.574814},
{x:36.187412,y:-86.614492},
{x:42.560933,y:-79.120632},
{x:40.504044,y:-74.85168},
{x:44.876217,y:-91.923672},
{x:40.401624,y:-74.311599},
{x:32.853905,y:-80.020028},
{x:29.607389,y:-98.523852},
{x:39.841896,y:-84.196014},
{x:36.213779,y:-86.322364},
{x:41.715182,y:-81.299923},
{x:44.55216,y:-123.307089},
{x:29.745336,y:-94.94984},
{x:41.232167,y:-77.017862},
{x:31.058739,y:-97.660809},
{x:40.055418,y:-84.237989},
{x:40.804859,y:-80.13552},
{x:40.10447,y:-83.160057},
{x:42.941037,y:-123.286489},
{x:41.884988,y:-86.600295},
{x:40.171865,y:-74.835333},
{x:25.94272,y:-80.307978},
{x:42.143945,y:-77.05965},
{x:34.070824,y:-106.89275},
{x:36.231398,y:-86.518956},
{x:37.247318,y:-79.958207},
{x:41.192307,y:-79.39478},
{x:39.378907,y:-84.549223},
{x:30.33221,y:-97.703519},
{x:40.911297,y:-73.77991},
{x:36.020803,y:-115.121855},
{x:29.474488,y:-98.535546},
{x:46.197874,y:-119.116747},
{x:37.149187,y:-76.52895},
{x:40.138494,y:-76.303317},
{x:36.032238,y:-83.869982},
{x:29.644687,y:-95.651041},
{x:33.262243,y:-97.17684},
{x:41.166731,y:-80.106664},
{x:37.511998,y:-77.774963},
{x:40.653955,y:-73.558149},
{x:40.210736,y:-74.796128},
{x:42.714253,y:-88.999927},
{x:40.681276,y:-75.147784},
{x:36.239998,y:-115.095469},
{x:35.086487,y:-80.932249},
{x:41.053027,y:-83.617112},
{x:43.016766,y:-74.80224},
{x:47.574162,y:-122.329409},
{x:29.682813,y:-95.422862},
{x:40.26857,y:-76.749073},
{x:36.4099,y:-82.475191},
{x:35.119207,y:-106.702135},
{x:32.842427,y:-104.416421},
{x:40.150596,y:-75.307388},
{x:35.845434,y:-86.447121},
{x:41.783164,y:-74.72826},
{x:40.495441,y:-74.443573},
{x:38.739784,y:-77.523606},
{x:44.941239,y:-122.982672},
{x:45.640762,y:-122.619225},
{x:32.570387,y:-96.303806},
{x:41.260416,y:-95.981134},
{x:40.99644,y:-73.671075},
{x:32.277278,y:-81.080472},
{x:44.09925,y:-123.127699},
{x:26.63325,y:-81.9406},
{x:29.773616,y:-95.818181},
{x:30.313121,y:-95.468124},
{x:33.579387,y:-79.029354},
{x:39.921043,y:-77.595707},
{x:41.31723,y:-96.024276},
{x:36.285811,y:-115.134424},
{x:34.823907,y:-82.578048},
{x:40.563498,y:-76.004535},
{x:42.096925,y:-76.00465},
{x:42.156946,y:-75.89534},
{x:47.7247,y:-122.34552},
{x:34.809338,y:-102.409355},
{x:43.18779,y:-89.227216},
{x:40.59531,y:-77.571449},
{x:29.703877,y:-95.589195},
{x:34.062761,y:-80.957369},
{x:47.652395,y:-122.691451},
{x:39.785077,y:-84.084516},
{x:41.603712,y:-83.625819},
{x:33.681957,y:-78.894771},
{x:35.915755,y:-88.754172},
{x:35.24117,y:-106.699154},
{x:29.503399,y:-98.28794},
{x:35.441347,y:-95.969504},
{x:40.699626,y:-74.235709},
{x:31.108103,y:-97.80105},
{x:40.710475,y:-73.613189},
{x:32.660726,y:-97.279969},
{x:39.450323,y:-83.800787},
{x:40.85321,y:-111.930145},
{x:30.094997,y:-93.75808},
{x:45.86207,y:-122.818867},
{x:41.449996,y:-81.564595},
{x:35.18408,y:-106.58021},
{x:26.221388,y:-98.242603},
{x:40.675099,y:-111.904469},
{x:32.838261,y:-97.150805},
{x:40.795953,y:-81.427793},
{x:32.749826,y:-96.859097},
{x:40.463515,y:-74.296464},
{x:41.260021,y:-75.899578},
{x:42.316136,y:-122.862567},
{x:41.858657,y:-71.411305},
{x:47.088813,y:-122.602384},
{x:29.952618,y:-95.604668},
{x:47.30732,y:-119.560968},
{x:41.102797,y:-80.710487},
{x:38.892253,y:-79.845824},
{x:45.446875,y:-122.789796},
{x:40.817779,y:-75.729853},
{x:29.368345,y:-100.88917},
{x:40.12328,y:-111.642986},
{x:35.673155,y:-88.780568},
{x:35.341185,y:-89.908138},
{x:40.38207,y:-82.483871},
{x:40.234144,y:-75.290825},
{x:40.749709,y:-99.739959},
{x:43.07905,y:-75.645662},
{x:39.413176,y:-81.438316},
{x:40.861353,y:-82.324437},
{x:36.309279,y:-86.601235},
{x:29.049606,y:-96.503408},
{x:38.256379,y:-78.115063},
{x:36.161658,y:-85.511883},
{x:39.923144,y:-83.77622},
{x:30.227568,y:-95.524752},
{x:39.647015,y:-74.78721},
{x:40.151939,y:-75.001991},
{x:47.289282,y:-122.318028},
{x:40.676376,y:-73.889946},
{x:36.944793,y:-80.947079},
{x:45.41253,y:-122.629},
{x:35.709511,y:-86.97406},
{x:41.430946,y:-74.42312},
{x:40.871883,y:-72.997723},
{x:44.016573,y:-71.113323},
{x:43.38421,y:-87.930055},
{x:41.07341,y:-75.990525},
{x:29.48251,y:-95.398282},
{x:38.863422,y:-83.893638},
{x:40.12942,y:-80.001567},
{x:34.970338,y:-101.918953},
{x:29.880796,y:-95.683794},
{x:42.947452,y:-78.868006},
{x:44.963306,y:-122.983941},
{x:39.919701,y:-75.187849},
{x:29.601286,y:-95.565029},
{x:37.930498,y:-79.227972},
{x:40.822087,y:-73.89181},
{x:42.214445,y:-73.880543},
{x:35.486376,y:-95.148998},
{x:32.64526,y:-97.18558},
{x:39.439113,y:-84.331795},
{x:43.148784,y:-76.126335},
{x:33.973569,y:-81.155951},
{x:41.521045,y:-74.023512},
{x:42.697404,y:-87.844992},
{x:36.939603,y:-82.607652},
{x:31.935923,y:-106.425328},
{x:42.421099,y:-122.853365},
{x:33.954349,y:-80.991388},
{x:40.738222,y:-114.060319},
{x:36.019775,y:-115.26619},
{x:48.417124,y:-119.509514},
{x:26.233191,y:-98.178949},
{x:32.54373,y:-95.8543},
{x:42.74857,y:-73.80626},
{x:32.82232,y:-97.229906},
{x:46.571234,y:-120.487476},
{x:47.984697,y:-122.20158},
{x:36.124582,y:-97.05088},
{x:33.003411,y:-96.680956},
{x:29.870264,y:-95.403984},
{x:40.825027,y:-73.920953},
{x:40.4047,y:-74.232523},
{x:40.147412,y:-79.043299},
{x:40.577292,y:-74.632633},
{x:36.418845,y:-82.996408},
{x:40.071714,y:-80.73388},
{x:38.609473,y:-77.359152},
{x:40.206179,y:-79.566188},
{x:36.519597,y:-86.035643},
{x:42.451847,y:-79.308138},
{x:47.469776,y:-122.339183},
{x:42.799697,y:-76.123989},
{x:38.245788,y:-77.526652},
{x:43.04884,y:-76.228176},
{x:35.688839,y:-88.856542},
{x:32.71055,y:-97.068565},
{x:40.675172,y:-74.198437},
{x:30.010855,y:-95.588202},
{x:45.677093,y:-122.661887},
{x:35.104257,y:-106.72975},
{x:33.312046,y:-97.655136},
{x:40.523958,y:-74.296504},
{x:31.755556,y:-106.488394},
{x:31.115229,y:-99.335045},
{x:33.21909,y:-96.73387},
{x:40.644332,y:-73.70491},
{x:47.405629,y:-120.279978},
{x:40.86413,y:-73.926155},
{x:36.778819,y:-108.695038},
{x:34.709059,y:-82.49752},
{x:33.38866,y:-94.10787},
{x:41.069855,y:-75.70387},
{x:36.133152,y:-95.850703},
{x:36.184113,y:-115.308883},
{x:35.025522,y:-106.680175},
{x:31.07022,y:-98.18505},
{x:39.925792,y:-80.749909},
{x:36.711573,y:-107.988012},
{x:35.078731,y:-87.436305},
{x:40.829787,y:-73.874671},
{x:40.912302,y:-74.242135},
{x:40.69968,y:-79.72061},
{x:39.784379,y:-83.994217},
{x:37.653033,y:-77.611813},
{x:40.848402,y:-73.245569},
{x:43.324986,y:-88.270193},
{x:35.86132,y:-84.06967},
{x:37.126324,y:-113.526042},
{x:47.402017,y:-97.071885},
{x:29.514543,y:-98.308691},
{x:36.16012,y:-115.333457},
{x:30.598246,y:-97.416111},
{x:29.649915,y:-95.15855},
{x:41.255627,y:-74.360594},
{x:29.531024,y:-95.511445},
{x:40.851166,y:-115.744297},
{x:29.739564,y:-95.266808},
{x:37.513227,y:-77.697204},
{x:40.852604,y:-73.82805},
{x:32.68982,y:-97.11531},
{x:43.075068,y:-87.881334},
{x:41.406103,y:-79.799907},
{x:48.051929,y:-122.17939},
{x:47.106017,y:-122.434325},
{x:29.535746,y:-95.808954},
{x:41.2478,y:-76.992707},
{x:39.983949,y:-75.868875},
{x:36.070999,y:-83.927692},
{x:41.085275,y:-81.44196},
{x:39.557016,y:-119.792071},
{x:32.68513,y:-97.332855},
{x:38.281979,y:-82.090908},
{x:40.265463,y:-75.628402},
{x:32.784156,y:-96.816493},
{x:29.219114,y:-98.410935},
{x:40.730337,y:-73.88772},
{x:39.542336,y:-77.987411},
{x:36.085607,y:-86.648527},
{x:40.654685,y:-73.579189},
{x:40.690016,y:-73.408313},
{x:33.875621,y:-78.615326},
{x:44.681574,y:-73.448525},
{x:37.38082,y:-79.1342},
{x:36.536872,y:-87.367981},
{x:40.050549,y:-74.222035},
{x:47.92047,y:-122.22979},
{x:40.715453,y:-74.225149},
{x:40.6131,y:-73.75252},
{x:39.22078,y:-81.505264},
{x:35.091033,y:-106.645471},
{x:35.493421,y:-97.602579},
{x:36.104669,y:-95.887306},
{x:40.037964,y:-76.268233},
{x:33.790011,y:-78.997386},
{x:35.769371,y:-95.360248},
{x:36.805134,y:-76.383262},
{x:37.335483,y:-77.326876},
{x:40.633251,y:-73.739514},
{x:37.776021,y:-79.966533},
{x:40.086488,y:-82.971615},
{x:29.732926,y:-95.445097},
{x:36.558508,y:-82.211759},
{x:41.353876,y:-83.650424},
{x:47.489099,y:-121.793275},
{x:44.978475,y:-123.02728},
{x:29.647198,y:-95.59808},
{x:30.639237,y:-97.687842},
{x:47.574259,y:-122.169934},
{x:41.713734,y:-83.684525},
{x:40.601199,y:-73.994725},
{x:35.978389,y:-86.569067},
{x:40.437566,y:-74.163758},
{x:42.969516,y:-74.187595},
{x:32.6057,y:-96.82369},
{x:39.32237,y:-84.429571},
{x:39.9992,y:-79.046491},
{x:29.808556,y:-95.162206},
{x:43.128586,y:-77.44055},
{x:42.830968,y:-78.778498},
{x:41.791344,y:-107.209424},
{x:32.75825,y:-97.11488},
{x:39.254111,y:-81.554713},
{x:40.827599,y:-73.022046},
{x:41.328829,y:-95.958584},
{x:45.503853,y:-122.53918},
{x:40.082438,y:-82.428347},
{x:32.786216,y:-97.384497},
{x:40.810933,y:-74.259629},
{x:29.871377,y:-95.331797},
{x:41.100686,y:-114.956887},
{x:35.804967,y:-83.577188},
{x:40.650804,y:-73.94967},
{x:35.869865,y:-83.756573},
{x:34.715046,y:-98.403548},
{x:29.209857,y:-96.280137},
{x:32.213969,y:-80.729488},
{x:33.98359,y:-81.259756},
{x:41.942571,y:-71.434385},
{x:45.559571,y:-122.924772},
{x:29.717225,y:-95.538656},
{x:40.097689,y:-83.090355},
{x:38.949228,y:-112.346344},
{x:32.526435,y:-94.352456},
{x:43.251608,y:-77.694103},
{x:35.977658,y:-115.156714},
{x:32.88904,y:-105.95985},
{x:39.690628,y:-80.855482},
{x:39.114484,y:-77.539646},
{x:40.900726,y:-73.820404},
{x:40.40053,y:-74.650132},
{x:35.069281,y:-106.532188},
{x:41.301584,y:-84.361256},
{x:36.81634,y:-76.111549},
{x:40.749982,y:-73.987591},
{x:39.095059,y:-84.613513},
{x:30.002215,y:-95.270704},
{x:35.19208,y:-101.885262},
{x:46.678947,y:-122.975365},
{x:42.871409,y:-97.397723},
{x:40.826689,y:-77.808831},
{x:30.211773,y:-95.75319},
{x:36.235607,y:-115.049797},
{x:39.972286,y:-74.586052},
{x:40.612972,y:-74.13087},
{x:41.386276,y:-73.976657},
{x:40.64736,y:-73.958049},
{x:32.431609,y:-97.793888},
{x:40.690392,y:-74.291437},
{x:34.701055,y:-82.216326},
{x:29.400596,y:-95.248469},
{x:40.697552,y:-75.178354},
{x:37.791384,y:-81.21556},
{x:41.237629,y:-95.933107},
{x:40.248704,y:-74.685786},
{x:36.858933,y:-76.175572},
{x:36.626347,y:-87.318407},
{x:44.807985,y:-91.472106},
{x:47.201529,y:-122.303886},
{x:39.969628,y:-75.298563},
{x:41.605632,y:-84.407204},
{x:41.194773,y:-80.242093},
{x:32.880692,y:-97.392875},
{x:39.294495,y:-84.392006},
{x:40.027604,y:-81.565783},
{x:39.220598,y:-83.609325},
{x:41.377439,y:-83.117757},
{x:43.309248,y:-73.642637},
{x:41.027977,y:-81.510962},
{x:32.633034,y:-96.700836},
{x:32.837236,y:-96.980676},
{x:42.362096,y:-122.856385},
{x:32.941979,y:-97.116394},
{x:42.958729,y:-87.912447},
{x:43.344654,y:-89.028559},
{x:40.852788,y:-74.301642},
{x:43.083879,y:-96.792482},
{x:30.717237,y:-95.570387},
{x:37.344388,y:-76.739697},
{x:40.0328,y:-75.083433},
{x:42.374641,y:-76.870375},
{x:42.558363,y:-87.845344},
{x:37.334754,y:-79.241295},
{x:40.566704,y:-83.121649},
{x:33.24295,y:-81.377347},
{x:40.00756,y:-74.97089},
{x:36.748084,y:-76.583699},
{x:31.080029,y:-97.761781},
{x:33.640216,y:-97.15507},
{x:40.808416,y:-73.193032},
{x:41.025663,y:-76.317765},
{x:32.86665,y:-96.74368},
{x:34.890927,y:-82.401366},
{x:36.505981,y:-82.555668},
{x:37.128697,y:-76.536758},
{x:43.56361,y:-96.731875},
{x:39.049067,y:-84.247871},
{x:42.98766,y:-88.005863},
{x:40.629829,y:-77.568312},
{x:32.304631,y:-95.300543},
{x:39.312403,y:-81.481985},
{x:29.581489,y:-97.991211},
{x:32.834145,y:-97.008992},
{x:29.739259,y:-95.464466},
{x:32.315339,y:-106.745452},
{x:40.054455,y:-75.072092},
{x:32.91765,y:-96.7},
{x:35.090566,y:-81.661949},
{x:40.560811,y:-77.390128},
{x:40.807641,y:-73.160969},
{x:40.57863,y:-73.985664},
{x:42.98911,y:-88.25683},
{x:33.02319,y:-96.93517},
{x:37.807223,y:-81.184591},
{x:29.555121,y:-95.395979},
{x:29.933472,y:-95.250168},
{x:48.935943,y:-122.486016},
{x:41.40887,y:-75.50559},
{x:35.956165,y:-86.817722},
{x:25.923885,y:-97.511066},
{x:37.497004,y:-77.545366},
{x:39.986436,y:-82.988838},
{x:44.344092,y:-98.213903},
{x:37.80415,y:-79.409074},
{x:41.419824,y:-81.913587},
{x:39.513899,y:-119.789713},
{x:48.422215,y:-101.33125},
{x:39.98474,y:-76.280471},
{x:31.888498,y:-102.355164},
{x:39.930181,y:-75.082834},
{x:44.904121,y:-123.040422},
{x:39.964107,y:-74.195945},
{x:37.402429,y:-79.16734},
{x:33.592103,y:-101.856856},
{x:29.389223,y:-100.90594},
{x:40.087984,y:-74.967231},
{x:39.970116,y:-82.010234},
{x:43.474817,y:-96.72804},
{x:43.192993,y:-71.527846},
{x:43.783806,y:-88.476784},
{x:46.147261,y:-122.96247},
{x:42.377835,y:-122.900044},
{x:40.762372,y:-74.226523},
{x:40.839888,y:-79.918787},
{x:45.192812,y:-123.213255},
{x:39.463464,y:-74.495912},
{x:41.719045,y:-71.2694},
{x:40.822281,y:-74.099894},
{x:36.003862,y:-95.88715},
{x:37.958981,y:-82.019895},
{x:34.178433,y:-79.385658},
{x:32.58916,y:-96.94562},
{x:43.699823,y:-71.645227},
{x:42.6075,y:-89.650876},
{x:41.570165,y:-75.507182},
{x:36.094091,y:-115.296839},
{x:47.979479,y:-122.091201},
{x:43.571067,y:-89.470532},
{x:30.710491,y:-94.95163},
{x:41.050053,y:-81.729623},
{x:40.677945,y:-73.872018},
{x:42.567656,y:-77.716101},
{x:40.338431,y:-76.4478},
{x:40.37838,y:-83.755836},
{x:42.871296,y:-78.276322},
{x:25.782088,y:-80.132379},
{x:36.211425,y:-83.293121},
{x:43.48729,y:-88.808751},
{x:29.665279,y:-95.209447},
{x:43.125061,y:-75.415286},
{x:47.568166,y:-122.633155},
{x:46.184116,y:-119.173638},
{x:35.047141,y:-89.703889},
{x:31.829994,y:-106.528362},
{x:43.210413,y:-77.460472},
{x:45.227888,y:-123.175703},
{x:41.407393,y:-78.560842},
{x:42.938123,y:-88.85589},
{x:39.95739,y:-79.649313},
{x:37.087978,y:-113.583045},
{x:41.419975,y:-81.691877},
{x:35.934301,y:-84.003199},
{x:27.757217,y:-97.459253},
{x:36.185557,y:-86.747664},
{x:39.341843,y:-80.223434},
{x:29.212353,y:-99.777422},
{x:41.654053,y:-83.622652},
{x:38.760654,y:-78.630441},
{x:32.272607,y:-97.171428},
{x:40.152497,y:-76.819354},
{x:42.030143,y:-80.255165},
{x:40.49374,y:-81.472011},
{x:35.450565,y:-89.780122},
{x:42.903767,y:-76.830807},
{x:29.736952,y:-95.307364},
{x:29.66913,y:-95.356649},
{x:36.349505,y:-82.238748},
{x:43.603057,y:-96.767702},
{x:40.451015,y:-76.513227},
{x:40.658278,y:-111.507429},
{x:47.222727,y:-122.475016},
{x:41.179216,y:-96.103492},
{x:32.8814,y:-96.89577},
{x:32.9751,y:-96.71929},
{x:44.059902,y:-122.997553},
{x:26.25017,y:-98.25478},
{x:34.032297,y:-81.123954},
{x:41.234039,y:-75.921141},
{x:45.530259,y:-122.843215},
{x:40.691965,y:-73.98819},
{x:39.936881,y:-74.969735},
{x:47.097185,y:-122.296693},
{x:30.027354,y:-95.381492},
{x:29.612765,y:-95.222982},
{x:29.941437,y:-95.281877},
{x:35.816724,y:-84.264424},
{x:40.447512,y:-76.98253},
{x:36.736611,y:-78.919525},
{x:34.593751,y:-98.40489},
{x:34.292299,y:-79.888751},
{x:32.653442,y:-96.751418},
{x:36.863109,y:-76.402531},
{x:41.083333,y:-74.150613},
{x:41.251425,y:-75.84877},
{x:38.36642,y:-81.581318},
{x:40.68565,y:-80.101086},
{x:47.454873,y:-122.254952},
{x:40.897127,y:-72.40084},
{x:39.299057,y:-81.54816},
{x:34.16832,y:-79.844806},
{x:37.903008,y:-76.866361},
{x:35.074033,y:-106.743482},
{x:41.204652,y:-76.00524},
{x:44.454701,y:-87.994844},
{x:36.126162,y:-115.2434},
{x:42.053669,y:-124.287609},
{x:29.774382,y:-95.248232},
{x:43.032635,y:-76.189952},
{x:33.429292,y:-81.886609},
{x:42.862812,y:-73.768203},
{x:40.590977,y:-76.407909},
{x:38.102099,y:-81.382685},
{x:31.766639,y:-106.318615},
{x:35.873431,y:-84.237183},
{x:36.562748,y:-87.313485},
{x:44.257582,y:-88.347461},
{x:39.650506,y:-79.969386},
{x:26.195002,y:-98.2876},
{x:40.637722,y:-76.184797},
{x:35.93745,y:-86.52896},
{x:40.606266,y:-75.49676},
{x:42.919209,y:-78.736739},
{x:32.045405,y:-97.090136},
{x:36.916028,y:-76.252154},
{x:26.267003,y:-98.201138},
{x:41.780891,y:-71.440961},
{x:39.836776,y:-75.806669},
{x:41.015295,y:-76.48897},
{x:39.434895,y:-74.611384},
{x:36.108081,y:-86.74324},
{x:44.958968,y:-92.721969},
{x:27.801433,y:-97.393653},
{x:36.03024,y:-115.212},
{x:40.715056,y:-75.283901},
{x:38.31657,y:-80.6552},
{x:36.3788,y:-86.466056},
{x:39.875997,y:-75.393038},
{x:36.101225,y:-115.188222},
{x:36.625077,y:-79.403487},
{x:34.190169,y:-101.750853},
{x:34.630356,y:-82.469489},
{x:38.922748,y:-77.41689},
{x:29.816778,y:-95.769872},
{x:35.962429,y:-83.593793},
{x:45.662026,y:-118.808117},
{x:40.399837,y:-82.45501},
{x:29.867023,y:-97.93935},
{x:43.133108,y:-77.492901},
{x:40.8111,y:-74.164348},
{x:40.047342,y:-76.363165},
{x:32.83517,y:-96.63121},
{x:39.930643,y:-77.715034},
{x:41.367778,y:-82.958956},
{x:40.339673,y:-78.394472},
{x:44.890332,y:-97.097346},
{x:35.144673,y:-106.5861},
{x:25.910334,y:-97.475298},
{x:34.502708,y:-82.68447},
{x:32.460725,y:-96.995249},
{x:29.514711,y:-98.634578},
{x:36.804537,y:-76.322336},
{x:40.663893,y:-73.956935},
{x:33.229197,y:-97.159838},
{x:47.697351,y:-117.411086},
{x:38.791685,y:-77.610235},
{x:36.898137,y:-76.204316},
{x:41.696982,y:-73.890963},
{x:40.613192,y:-79.174757},
{x:40.820729,y:-74.121531},
{x:33.45237,y:-94.1302},
{x:39.957685,y:-76.682143},
{x:29.729666,y:-98.078838},
{x:34.177778,y:-82.15129},
{x:40.68054,y:-73.77538},
{x:41.372061,y:-81.892266},
{x:42.082518,y:-79.229427},
{x:35.680983,y:-97.496936},
{x:43.009672,y:-76.14268},
{x:29.735094,y:-95.642589},
{x:36.494617,y:-86.878122},
{x:39.953666,y:-75.061997},
{x:40.902245,y:-81.133606},
{x:36.020955,y:-86.57875},
{x:41.721901,y:-83.569013},
{x:32.451672,y:-94.708165},
{x:39.771265,y:-84.056219},
{x:45.006295,y:-122.999631},
{x:41.710481,y:-112.200007},
{x:41.822095,y:-71.358061},
{x:41.617173,y:-73.915774},
{x:43.169427,y:-88.102818},
{x:40.356857,y:-74.30491},
{x:40.72207,y:-73.794951},
{x:43.558651,y:-76.11866},
{x:39.918151,y:-76.631926},
{x:41.859844,y:-71.488037},
{x:41.442485,y:-71.483916},
{x:40.607441,y:-74.162726},
{x:40.9344,y:-72.671859},
{x:40.139378,y:-75.545401},
{x:44.933111,y:-91.394431},
{x:44.272675,y:-88.420115},
{x:41.525961,y:-75.950417},
{x:40.478303,y:-76.295264},
{x:38.835196,y:-77.431525},
{x:35.60918,y:-88.81676},
{x:40.746084,y:-73.42158},
{x:40.647552,y:-82.54427},
{x:37.29005,y:-79.988871},
{x:36.178493,y:-95.975037},
{x:35.639121,y:-87.015034},
{x:29.85042,y:-95.505269},
{x:41.419222,y:-81.832093},
{x:40.143301,y:-75.115722},
{x:41.287783,y:-96.053068},
{x:44.485809,y:-87.970124},
{x:40.26885,y:-78.857267},
{x:30.126662,y:-94.158423},
{x:42.213912,y:-79.840771},
{x:37.537823,y:-76.797295},
{x:29.93184,y:-95.41114},
{x:45.621527,y:-122.556633},
{x:34.775906,y:-82.464939},
{x:37.060152,y:-113.58184},
{x:41.432186,y:-81.525548},
{x:43.070719,y:-87.913885},
{x:40.900797,y:-80.8363},
{x:36.101477,y:-115.052273},
{x:44.951453,y:-123.051378},
{x:35.949816,y:-83.200972},
{x:35.689014,y:-105.954313},
{x:47.134573,y:-122.491446},
{x:40.54249,y:-80.192112},
{x:40.914312,y:-77.76183},
{x:41.190702,y:-111.981727},
{x:34.763151,y:-83.055849},
{x:40.625355,y:-96.957329},
{x:41.361092,y:-71.812404},
{x:32.905711,y:-80.020411},
{x:40.892889,y:-81.406549},
{x:45.743488,y:-122.877263},
{x:29.833121,y:-95.503438},
{x:40.668809,y:-74.481162},
{x:42.899472,y:-71.335817},
{x:40.929205,y:-73.857467},
{x:39.874304,y:-74.214586},
{x:40.761356,y:-73.75979},
{x:29.791465,y:-95.719523},
{x:44.676716,y:-90.166036},
{x:41.335807,y:-74.192835},
{x:27.542573,y:-99.503794},
{x:39.993869,y:-75.094302},
{x:40.632631,y:-73.948156},
{x:37.289983,y:-78.397324},
{x:29.78077,y:-95.125167},
{x:40.208284,y:-76.574349},
{x:40.76293,y:-73.313018},
{x:32.91033,y:-96.76784},
{x:33.530813,y:-101.870845},
{x:40.022725,y:-79.076507},
{x:39.895889,y:-76.175921},
{x:40.718515,y:-73.986759},
{x:40.897111,y:-98.339991},
{x:33.216645,y:-96.632178},
{x:35.812994,y:-86.446388},
{x:40.168755,y:-74.047306},
{x:44.790614,y:-91.518625},
{x:38.833435,y:-77.189689},
{x:30.388467,y:-95.680047},
{x:39.594407,y:-79.954351},
{x:35.872191,y:-86.354805},
{x:29.728082,y:-95.55857},
{x:29.690547,y:-98.108826},
{x:29.774218,y:-95.751299},
{x:31.134299,y:-97.767692},
{x:40.668092,y:-73.931344},
{x:26.158341,y:-97.957486},
{x:40.18333,y:-77.23654},
{x:44.961757,y:-124.014916},
{x:41.036126,y:-78.399509},
{x:40.382313,y:-79.976684},
{x:44.29322,y:-90.828763},
{x:38.231793,y:-77.503502},
{x:30.395921,y:-96.074036},
{x:39.926647,y:-75.306025},
{x:41.384821,y:-81.434596},
{x:29.356239,y:-98.54105},
{x:29.590964,y:-95.600067},
{x:40.101441,y:-74.792246},
{x:40.017198,y:-78.488894},
{x:29.170862,y:-95.418084},
{x:43.146585,y:-77.708767},
{x:43.015705,y:-78.767546},
{x:38.348182,y:-77.74736},
{x:33.715861,y:-78.890805},
{x:33.701107,y:-78.928066},
{x:29.845371,y:-95.718821},
{x:40.929437,y:-74.181617},
{x:41.244513,y:-81.356829},
{x:40.760914,y:-111.874501},
{x:47.497928,y:-122.653237},
{x:45.684208,y:-122.602353},
{x:30.104244,y:-97.32981},
{x:41.157381,y:-81.227248},
{x:40.846521,y:-76.822688},
{x:34.894672,y:-82.710504},
{x:37.59434,y:-77.565836},
{x:39.414762,y:-75.03953},
{x:32.470827,y:-95.388088},
{x:41.636425,y:-83.477182},
{x:27.572605,y:-99.472307},
{x:40.775536,y:-82.47647},
{x:43.192143,y:-88.129032},
{x:29.891199,y:-95.640541},
{x:45.497515,y:-122.408525},
{x:36.096055,y:-84.136027},
{x:42.518109,y:-89.038535},
{x:32.648024,y:-96.857834},
{x:40.608927,y:-74.028468},
{x:39.819804,y:-74.928453},
{x:47.153109,y:-122.293366},
{x:44.260896,y:-88.446313},
{x:43.514499,y:-96.791587},
{x:30.136205,y:-96.256182},
{x:43.008644,y:-88.10828},
{x:40.0365,y:-75.120593},
{x:42.050187,y:-80.083122},
{x:40.39146,y:-79.766499},
{x:32.93893,y:-96.7687},
{x:41.707278,y:-83.58979},
{x:40.131139,y:-76.564846},
{x:32.965118,y:-80.166138},
{x:31.782236,y:-97.576787},
{x:37.503715,y:-77.476085},
{x:42.978467,y:-78.005937},
{x:40.798858,y:-73.942517},
{x:41.314262,y:-75.757046},
{x:38.798565,y:-77.51958},
{x:35.031916,y:-90.024554},
{x:40.94594,y:-78.967685},
{x:45.537375,y:-122.900617},
{x:40.007179,y:-75.127733},
{x:40.717282,y:-73.405171},
{x:36.196658,y:-115.22352},
{x:45.616382,y:-122.478965},
{x:32.825968,y:-96.872906},
{x:41.075827,y:-81.49542},
{x:38.796475,y:-77.185236},
{x:39.714187,y:-82.574967},
{x:32.7267,y:-103.144301},
{x:36.552064,y:-82.621807},
{x:40.67367,y:-75.344659},
{x:40.1108,y:-74.853705},
{x:40.697377,y:-82.511853},
{x:36.817571,y:-76.440991},
{x:46.936816,y:-122.596609},
{x:40.773713,y:-80.374703},
{x:39.470944,y:-74.529025},
{x:36.557816,y:-87.241616},
{x:37.336606,y:-80.799037},
{x:40.803333,y:-73.935555},
{x:39.020742,y:-80.418957},
{x:40.672156,y:-78.237102},
{x:29.490223,y:-95.15371},
{x:40.166131,y:-80.27076},
{x:33.09535,y:-96.10721},
{x:36.169407,y:-86.684145},
{x:41.183341,y:-82.217878},
{x:42.106299,y:-76.020415},
{x:40.848134,y:-81.946203},
{x:38.451875,y:-81.939181},
{x:40.386155,y:-79.822122},
{x:41.399872,y:-83.65006},
{x:41.125805,y:-75.360245},
{x:31.653152,y:-106.269355},
{x:40.732081,y:-73.86633},
{x:40.869987,y:-79.92807},
{x:40.674572,y:-95.858752},
{x:39.550866,y:-74.735891},
{x:40.645011,y:-73.930019},
{x:36.535322,y:-82.320066},
{x:32.516845,y:-97.350718},
{x:34.282875,y:-81.582915},
{x:41.469597,y:-81.705815},
{x:32.877104,y:-96.620178},
{x:41.607415,y:-83.473239},
{x:33.210813,y:-97.147216},
{x:40.32516,y:-74.79874},
{x:37.505061,y:-77.607788},
{x:29.794001,y:-95.543528},
{x:35.137445,y:-90.027505},
{x:35.178456,y:-89.835866},
{x:38.232611,y:-78.370624},
{x:42.943773,y:-88.047218},
{x:44.41772,y:-103.533417},
{x:36.05375,y:-83.988925},
{x:34.935942,y:-82.277513},
{x:36.140197,y:-115.164621},
{x:35.462088,y:-97.38823},
{x:36.142805,y:-115.065922},
{x:41.876578,y:-80.667142},
{x:32.846547,y:-96.596145},
{x:39.549839,y:-83.424518},
{x:39.579323,y:-82.52182},
{x:42.680711,y:-74.472662},
{x:45.148707,y:-122.833866},
{x:43.22158,y:-77.65978},
{x:42.903388,y:-78.782685},
{x:42.928198,y:-78.753739},
{x:44.003034,y:-90.505745},
{x:35.104482,y:-89.98361},
{x:39.963517,y:-76.767976},
{x:40.895863,y:-74.486115},
{x:40.297027,y:-81.85029},
{x:42.206752,y:-121.721726},
{x:32.709795,y:-96.992925},
{x:39.771027,y:-76.66109},
{x:40.790382,y:-73.946109},
{x:48.219685,y:-101.295845},
{x:29.148803,y:-98.159884},
{x:29.564431,y:-98.586279},
{x:31.079665,y:-97.366106},
{x:35.349385,y:-97.477235},
{x:36.05639,y:-115.040558},
{x:43.044362,y:-108.381003},
{x:40.659805,y:-74.109907},
{x:42.898421,y:-73.209054},
{x:39.843064,y:-77.202422},
{x:43.241623,y:-78.193447},
{x:36.549156,y:-82.520954},
{x:35.233215,y:-89.894072},
{x:36.089936,y:-115.153439},
{x:32.26953,y:-107.75827},
{x:40.895564,y:-73.856581},
{x:41.102098,y:-81.50832},
{x:38.250916,y:-112.647013},
{x:32.448138,y:-97.384769},
{x:40.762493,y:-73.096349},
{x:40.49505,y:-80.066053},
{x:27.759529,y:-98.051419},
{x:37.194656,y:-77.376001},
{x:32.80865,y:-96.77377},
{x:36.159524,y:-115.21218},
{x:40.061032,y:-76.30761},
{x:41.406101,y:-75.678734},
{x:40.695537,y:-73.506741},
{x:40.647061,y:-73.981569},
{x:32.68752,y:-96.787465},
{x:37.848236,y:-77.445594},
{x:42.775398,y:-73.698138},
{x:47.715699,y:-117.438567},
{x:42.526059,y:-88.980501},
{x:41.377452,y:-82.224888},
{x:32.539814,y:-94.754758},
{x:35.910318,y:-83.850925},
{x:40.878178,y:-74.154704},
{x:32.890788,y:-97.258655},
{x:35.605388,y:-87.06571},
{x:40.067551,y:-76.340795},
{x:29.771489,y:-95.214382},
{x:40.982495,y:-75.980314},
{x:40.105949,y:-83.737835},
{x:40.521768,y:-80.218119},
{x:40.776195,y:-73.0088},
{x:41.024272,y:-80.588741},
{x:33.475966,y:-94.409048},
{x:27.589976,y:-99.512083},
{x:37.400566,y:-77.426557},
{x:44.080083,y:-103.249662},
{x:34.441156,y:-79.37249},
{x:41.216816,y:-95.946908},
{x:40.204961,y:-75.097436},
{x:29.421836,y:-98.77747},
{x:39.561649,y:-84.255956},
{x:40.624531,y:-74.144566},
{x:39.675336,y:-75.497275},
{x:40.490602,y:-78.044152},
{x:42.980942,y:-78.871979},
{x:40.295371,y:-78.836686},
{x:43.087761,y:-77.608416},
{x:36.847483,y:-76.021416},
{x:36.39767,y:-87.02916},
{x:41.857689,y:-71.354935},
{x:43.080642,y:-75.318719},
{x:37.56692,y:-77.44393},
{x:33.661017,y:-95.510128},
{x:47.656883,y:-117.259068},
{x:40.192203,y:-76.176932},
{x:45.983804,y:-112.510107},
{x:40.728009,y:-99.082824},
{x:40.615575,y:-73.913824},
{x:29.598765,y:-95.367404},
{x:35.057895,y:-106.635515},
{x:47.534439,y:-122.61095},
{x:38.826987,y:-82.168301},
{x:42.834712,y:-78.823954},
{x:34.738189,y:-82.035657},
{x:43.529061,y:-96.672145},
{x:38.072916,y:-78.901709},
{x:29.800207,y:-98.41964},
{x:36.115054,y:-115.20621},
{x:34.45685,y:-81.878899},
{x:36.988332,y:-76.424904},
{x:31.83985,y:-106.57495},
{x:38.997936,y:-80.232691},
{x:37.271058,y:-77.412023},
{x:45.48845,y:-91.734002},
{x:40.671218,y:-80.313142},
{x:34.574584,y:-82.688137},
{x:42.733478,y:-110.933775},
{x:32.811391,y:-97.432864},
{x:40.614001,y:-73.926396},
{x:36.25004,y:-87.03318},
{x:34.711016,y:-82.257218},
{x:29.047881,y:-95.460567},
{x:44.879603,y:-123.029327},
{x:42.704277,y:-73.715085},
{x:39.80355,y:-75.042178},
{x:33.761988,y:-96.583197},
{x:40.765078,y:-84.154779},
{x:30.23537,y:-97.723529},
{x:47.632711,y:-122.629061}];