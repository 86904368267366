export const statuses = [
  { id: 'live', name: 'Live' },
  { id: 'waitlist', name: 'Waitlist' },
  { id: 'suspended', name: 'Suspended' },
];

export const liteLevels = [
  { id: 'L1', name: 'Level 1' },
  { id: 'L2', name: 'Level 2' },
  { id: 'L3', name: 'Level 3' },
];
