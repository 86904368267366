import styled from '@emotion/styled';
import { IconX } from '@tabler/icons-react';

const RemoveIcon = styled(IconX)`
  width: 18px;

  &:hover {
    color: ${(p) => p.theme.colors.red[6]};
  }
`;

export default RemoveIcon;
