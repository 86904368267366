import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from 'hooks/utils';
import * as MN from '@mantine/notifications';
import type { ApiTypes } from 'types';
import type { CamelCasedPropertiesDeep } from 'type-fest';

export type SchoolQueryFilterKey = keyof (ApiTypes['SchoolGlobalSearchView'] &
  ApiTypes['SchoolView']);

type SchoolsQueryParams = {
  page: number;
  limit: number;
  order_by?: 'asc' | 'desc';
  sort_by?: SchoolQueryFilterKey;
  filter_by?: `${SchoolQueryFilterKey}:${
    | 'eq'
    | 'gt'
    | 'lt'
    | 'gte'
    | 'lte'
    | 'ne'}:${string | number}`;
  search?: string;
};

export const useSchoolsFiltered = (params: SchoolsQueryParams) => {
  return useQuery({
    queryKey: ['schools', params],
    keepPreviousData: true,
    staleTime: 30 * 1000,
    queryFn: async () => {
      const { data, headers } = await axiosClient.get<
        CamelCasedPropertiesDeep<ApiTypes['SchoolViewExtra']>[]
      >('v2/schools', {
        params: { ...params, paginate: true },
      });

      return {
        schools: data,
        schoolsTotal: parseInt(headers['xPaginationItems']),
        usersTotal: parseInt(headers['xUserCount']),
      };
    },
  });
};

const uploadSchoolLogo = async (file: File) => {
  const formData = new FormData();
  formData.set('file', file);

  const { data } = await axiosClient.post<
    CamelCasedPropertiesDeep<ApiTypes['ContentViewMeta']>
  >(`v2/media/school_logo`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

export const useUploadSchoolLogo = () => {
  return useMutation({
    mutationFn: uploadSchoolLogo,
  });
};

export const useCreateSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      school,
      logo,
    }: {
      school: CamelCasedPropertiesDeep<ApiTypes['SchoolCreate']>;
      logo?: File;
    }) => {
      const { data } = await axiosClient.post<
        CamelCasedPropertiesDeep<ApiTypes['SchoolView']>
      >(`v2/schools`, school);

      if (logo) {
        await uploadSchoolLogo(logo);
      }

      return data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['schools']);
      MN.showNotification({
        message: 'School created successfully',
        color: 'green',
      });
    },
    onError: () => {
      MN.showNotification({
        message: 'Something went wrong',
        color: 'red',
      });
    },
  });
};

export const useUpdateSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      school,
      logo,
    }: {
      school: CamelCasedPropertiesDeep<ApiTypes['SchoolUpdateView']>;
      logo?: File;
    }) => {
      const { data } = await axiosClient.put<
        CamelCasedPropertiesDeep<ApiTypes['SchoolView']>
      >(`v2/schools/${school.schoolName}`, school);

      if (logo) {
        await uploadSchoolLogo(logo);
      }

      return data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['schools']);
      MN.showNotification({
        message: 'School updated successfully',
        color: 'green',
      });
    },
    onError: () => {
      MN.showNotification({
        message: 'Something went wrong',
        color: 'red',
      });
    },
  });
};

// TODO: this should go somewhere else
export const useDistricts = () => {
  return useQuery({
    queryKey: ['districts'],
    queryFn: async () => {
      const { data } = await axiosClient.get<
        {
          districtName: string;
          districtCode: string;
        }[]
      >('v2/map/districts');
      return data;
    },
  });
};
