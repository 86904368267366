import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import StepWizard from 'react-step-wizard';

import uploadsService from 'services/fileupload.service';
import { ModalResult } from 'components/shared/Modal/ModalSuccess/ModalSuccess';

import FileUploadStep from './Steps/FileUploadStep';

const FileUploadModal = ({ hide }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [form, setForm] = useState({
    file_type: null,
    file: null,
    override: null,
  });

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('file', form.file);

    setIsSubmitting(true);
    return uploadsService()
      .uploadFiles(form['file_type'], form['override'], formData)
      .then(() => {
        setIsSuccess(true);
        setMessage(
          'File processing started. Please reach out to Anika or Waj if you actually use this.',
        );
      })
      .catch((e) => {
        setIsSuccess(false);
        setMessage(
          `An error happened when trying to upload the files. ${e.response?.data?.detail}`,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setHasSubmitted(true);
      });
  };

  return (
    <Modal show onHide={hide}>
      {!isSubmitting && !hasSubmitted && (
        <div className="container">
          <StepWizard transitions={{}} initialStep={1}>
            <FileUploadStep
              form={form}
              onSubmit={onSubmit}
              updateForm={(data) => setForm((prev) => ({ ...prev, ...data }))}
              hide={hide}
            />
          </StepWizard>
        </div>
      )}
      <ModalResult
        submitting={isSubmitting}
        submitted={hasSubmitted}
        success={isSuccess}
        message={message}
        onClose={hide}
      />
    </Modal>
  );
};

export default FileUploadModal;
