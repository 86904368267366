import apiClient from 'utils/apiClient';

const verify = ({ provider, token }) =>
  apiClient.post(`/v3/auth/email/${provider}?offline_mode=true`, {
    id_token: token,
  });

const getProfile = ({ token }) =>
  apiClient.get(`/v2/user/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });

const refreshToken = (access_token, refresh_token) =>
  apiClient.post(`/v3/auth/refresh`, {
    access_token,
    refresh_token,
  });

const authService = () => ({
  verify,
  getProfile,
  refreshToken,
});

export default authService;
