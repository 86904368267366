import * as M from '@mantine/core';

import { ScheduleView } from 'features/components/scheduleView/ScheduleView';
import { useScheduleSave } from 'features/schedules/hooks/useScheduleSave';
import type { Schedule } from 'types';

import { StepContainer } from '../stepLayout/StepContainer';
import { StepHeader } from '../stepLayout/StepHeader';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { stepCreator, useWizard } from '../stepUtils';

const reviewStep = stepCreator.create()({
  key: 'review',
  payloadReducer: () => (state) => state,
  render: () => <ReviewStep />,
  validate: () => true,
});

const ReviewStep = () => {
  // TODO: implement Schedule validation -> infer types that way
  const schedule = useWizard((store) => store.state.schedule);

  const { isLoading, saveSchedule } = useScheduleSave();

  if (!schedule) {
    return (
      <M.Alert color="red" title="Error" m="xl">
        Missing schedule.
      </M.Alert>
    );
  }

  // FIXME: this casting is not true
  const populatedSchedule: Schedule = {
    ...(schedule as Schedule),
    lunchWaves: schedule.lunchWaves || [],
  };

  return (
    <StepContainer>
      <StepHeader
        title="Review"
        description="The schedule will be saved as follows"
      />
      {<ScheduleView schedule={populatedSchedule} />}
      <StepNavigation onNext={saveSchedule} isNextBtnLoading={isLoading} />
    </StepContainer>
  );
};

export default reviewStep;
