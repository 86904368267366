import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { useSchool } from 'hooks/useSchool';

import CalendarSettings from 'features/calendarSettings';
import ScheduleSettings from 'features/scheduleSettings';

import { TabLoader } from './TabLoader';
import { TabErrorAlert } from './TabErrorAlert';

const Settings = () => {
  const { data: school, error } = useSchool();

  const [isUnitLunchLocallySelected, setIsUnitLunchLocallySelected] =
    useState(false);

  if (error) {
    return <TabErrorAlert error={error} />;
  }

  if (!school) {
    return <TabLoader />;
  }

  return (
    <Container>
      <M.Title order={2}>Schedule and Calendar Settings</M.Title>
      <SettingsContainer>
        <ScheduleSettings
          school={school}
          isUnitLunchLocallySelected={isUnitLunchLocallySelected}
          setIsUnitLunchLocallySelected={setIsUnitLunchLocallySelected}
        />
      </SettingsContainer>

      <SettingsContainer>
        <CalendarSettings school={school} />
      </SettingsContainer>
    </Container>
  );
};

const Container = styled(M.Container)`
  margin: ${(p) => p.theme.spacing.lg} auto;
`;

const SettingsContainer = styled(M.Container)`
  margin-top: ${(p) => p.theme.spacing.lg};
  padding: ${(p) => p.theme.spacing.lg};
  background-color: ${(p) => p.theme.colors.gray[0]};
  border-radius: ${(p) => p.theme.radius.md};
`;

export default Settings;
