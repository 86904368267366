import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { parseJwt } from 'utils/utilities';
import { authActions } from 'store/actions/actions';
import SaturnFullLogo from 'assets/images/logo_dash.png';

import './LoginContainer.scss';

const LoginContainer = () => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleSuccess = ({ credential: token }) => {
    if (parseJwt(token).email.includes('joinsaturn.com')) {
      dispatch(authActions.verifyToken(token, 'google'));
    } else {
      setError('Not a joinsaturn.com email');
    }
  };

  const handleError = (e) => setError(`${e?.details} (code: ${e?.error})`);

  return (
    <Container fluid className="login-container">
      <Row>
        <div className="login-container-header">
          <img src={SaturnFullLogo} alt="Saturn" />
          <div
            className={`env-badge ${process.env.REACT_APP_ENV}`}
            href="#"
            title={`Your are working on the ${process.env.REACT_APP_ENV} environment`}
          >
            {process.env.REACT_APP_ENV}
          </div>
        </div>
      </Row>
      <Row>
        {error ? (
          <Error error={error} />
        ) : (
          <Login handleSuccess={handleSuccess} handleError={handleError} />
        )}
      </Row>
    </Container>
  );
};

const Login = ({ handleSuccess, handleError }) => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <GoogleLogin
      auto_select
      useOneTap
      onSuccess={handleSuccess}
      onFailure={handleError}
    />
  </GoogleOAuthProvider>
);

const Error = ({ error }) => (
  <div className="error-access text-center">
    <p>Sorry you can&apos;t continue...</p>
    <p>{error}</p>
  </div>
);

export default LoginContainer;
