import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MN from '@mantine/notifications';
import { IconWorld, IconEdit, IconClipboardCopy } from '@tabler/icons-react';

import EditSchoolModal from 'components/modals/EditSchoolModal/EditSchoolModal';

import { SchoolV1 } from 'types';

interface Props {
  school: SchoolV1 | null;
}

const Header = ({ school }: Props) => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const copyIdToClipboard = () => {
    if (!school?.school_name) {
      return;
    }

    void navigator.clipboard.writeText(school.school_name);
    MN.showNotification({ message: 'School ID copied to clipboard' });
  };

  const subtitle = !school ? (
    '...'
  ) : (
    <M.Flex align="center">
      <span>ID: </span>
      <SchoolId onClick={copyIdToClipboard}>
        {school.school_name}
      </SchoolId> - {school.meta.user_count} users (
      {school.meta.reactivated_user_count} reactivated), status:
      <M.Badge
        ml="xs"
        color={
          school.status === 'live'
            ? 'green'
            : school.status === 'waitlist'
            ? 'yellow'
            : 'red'
        }
      >
        {school.status}
      </M.Badge>
    </M.Flex>
  );

  return (
    <Container color={school?.primary_color || ''}>
      {school && isEditModalVisible && (
        <EditSchoolModal
          hide={() => setIsEditModalVisible(false)}
          school={school}
        />
      )}

      {school?.logo?.resource_url && <Avatar src={school.logo.resource_url} />}

      <DetailsContainer>
        <Title>{school?.school_title || 'Loading...'}</Title>
        <Row>
          <Subtitle>{subtitle}</Subtitle>
          <ActionItems>
            {school?.website && (
              <a
                href={school.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWorld color="#fff" />
              </a>
            )}
            <span onClick={() => setIsEditModalVisible(true)}>
              <IconEdit color="#fff" />
            </span>
            <span onClick={copyIdToClipboard}>
              <IconClipboardCopy color="#fff" />
            </span>
          </ActionItems>
        </Row>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled(M.Flex)<{ color: string }>`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.md};
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};

  background: ${({ theme, color }) =>
    color
      ? `linear-gradient(to left, #0000, ${color} 50%)`
      : theme.other.saturnGradient.primary};
`;

const Avatar = styled.img`
  width: 72px;
  height: 72px;
  padding: 0.25rem;

  background: white;
  border: 2px solid ${({ theme }) => theme.colors.dark[1]};
  border-radius: 50%;
  object-fit: contain;
`;

const DetailsContainer = styled(M.Flex)`
  flex-direction: column;
`;

const Row = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.xs};
  align-items: center;
`;

const Title = styled(M.Title)`
  color: white;
`;

const Subtitle = styled(M.Text)`
  color: white;
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const SchoolId = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const ActionItems = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.xs};

  & > *:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export default Header;
