import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Select, TextInput, Checkbox } from 'react-hook-form-mantine';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import * as M from '@mantine/core';

import { StepHeader } from 'features/schedules/components/scheduleWizard/stepLayout/StepHeader';
import { modificationTypes } from 'constants/scheduleInfo';
import { useSchool } from 'hooks/useSchool';
import type { ModifiedSchedule } from 'types';

import { useWizard } from '../stepUtils';
import infoStep from '../steps/InfoStep';
import { useScheduleDetailParams } from 'features/schedules/hooks/useScheduleDetailParams';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { capitalizeWord } from './utils';
import { schema } from 'schema';

const sanitizeOther = (val?: string) =>
  val
    ?.toUpperCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]+/g, '');

const formSchema = z.object({
  dayType: z.string(),
  modification: schema.modification,
  otherModification: z.string().optional().transform(sanitizeOther),
  displayName: z.string().min(1),
  isInLsc: z.boolean(),
});

type FieldValues = z.infer<typeof formSchema>;

const ModifiedInfoForm = () => {
  const { id } = useScheduleDetailParams();
  const { data: school } = useSchool(({ dayTypes, schedules }) => ({
    dayTypes,
    schedules,
  }));

  const [schedule, saveStep, next] = useWizard((store) => [
    store.state.schedule as Partial<ModifiedSchedule>,
    store.saveStep,
    store.next,
  ]);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    register,
  } = useForm<FieldValues>({
    defaultValues: {
      dayType: schedule.dayType?.id,
      modification: schedule.modification,
      otherModification:
        (schedule.modification === 'OTHER' && schedule.otherModification) ||
        'OTHER',
      displayName: schedule.displayName || '',
      isInLsc: schedule.isInLsc ?? true,
    },
    resolver: zodResolver(formSchema),
  });

  const dayType = watch('dayType');
  const modification = watch('modification');

  useEffect(() => {
    const dayTypeName = school?.dayTypes.find((dt) => dt.id === dayType)?.name;
    const modificationLabel = modificationTypes.find(
      (m) => m.value === modification,
    )?.label;

    clearErrors('root');

    if (!dirtyFields.dayType && !dirtyFields.modification) return;

    if (dayTypeName && modificationLabel) {
      setValue(
        'displayName',
        `${capitalizeWord(dayTypeName)} - ${modificationLabel}`,
      );
    } else if (dayTypeName) {
      setValue('displayName', capitalizeWord(dayTypeName));
    }
  }, [dayType, modification, school, dirtyFields, setValue, clearErrors]);

  if (!school) return <M.Loader />;

  const onSubmit = (values: FieldValues) => {
    const dayType = school.dayTypes.find((dt) => dt.id === values.dayType);

    if (!dayType) return;

    if (
      school.schedules
        .filter((s) => s.id !== id)
        .find(
          (s) =>
            s.variant === 'modified' &&
            values.modification !== 'OTHER' &&
            s.dayType.id === values.dayType &&
            s.modification === values.modification,
        )
    ) {
      setError('root', {
        message:
          'A core schedule with this day type and modification already exists',
      });
      return;
    }

    const isOther = values.modification === 'OTHER';
    const otherModification = isOther ? values.otherModification : undefined;

    if (isOther && !otherModification) {
      setError('otherModification', {
        message: 'This field is required',
      });
      return;
    }

    if (
      isOther &&
      school.schedules.find(
        (s) =>
          s.id !== schedule.id &&
          s.variant === 'modified' &&
          s.dayType.id === values.dayType &&
          ((s.modification !== 'OTHER' &&
            s.modification === otherModification) ||
            (s.modification === 'OTHER' &&
              s.otherModification === otherModification)),
      )
    ) {
      setError('otherModification', {
        message: 'A schedule with this modification already exists',
      });
      return;
    }

    saveStep(
      infoStep.payloadReducer({
        variant: 'modified',
        dayType,
        displayName: values.displayName,
        isInLsc: values.isInLsc,
        modification,
        ...(isOther && { otherModification }),
      }),
    );

    next();
  };

  return (
    <div>
      <StepHeader
        title="Modified schedule info"
        description="Add basic information about your schedule"
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <M.Flex direction="column" gap="md">
          <Select
            control={control}
            name="dayType"
            label="Day type"
            data={school.dayTypes.map((dt) => ({
              label: dt.name,
              value: dt.id,
            }))}
            required
          />

          <Select
            control={control}
            name="modification"
            label="Modification"
            data={modificationTypes}
            required
          />

          {modification === 'OTHER' && (
            <M.TextInput
              {...register('otherModification')}
              label="Other type"
              onChange={(e) =>
                setValue('otherModification', sanitizeOther(e.target.value))
              }
              required
            />
          )}
          {errors.otherModification && (
            <M.Text color="red">{errors.otherModification.message}</M.Text>
          )}

          <TextInput
            name="displayName"
            label="Display name"
            autoComplete="off"
            control={control}
            required
          />

          {errors.root && <M.Text color="red">{errors.root.message}</M.Text>}

          <Checkbox name="isInLsc" label="Display in LSC" control={control} />
        </M.Flex>

        <StepNavigation onNext={handleSubmit(onSubmit)} />
      </form>
    </div>
  );
};

export default ModifiedInfoForm;
