import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import SwitchField from 'components/shared/Fields/SwitchField/SwitchField';
import { WizardNavigation } from 'components/wizards/common/WizardNavigation';
import FileBox from 'components/shared/FileBox/FileBox';

const resource_types = [
  { value: 'schools', label: 'Schools' },
  { value: 'courses', label: 'Courses' },
  { value: 'teachers', label: 'Teachers' },
  { value: 'blocks', label: 'Calendar' },
];

const resource_template_urls = {
  schools:
    'https://docs.google.com/spreadsheets/d/1ubs-rTD04gU9-LmFoG8fdbz4O35CDDqSGd7EJneMsvU/edit?usp=sharing',
  courses:
    'https://docs.google.com/spreadsheets/d/1tMEAGTHYqjJGfVwGExGWSs_xIuyfSjUs0iYHXvP7gfo/edit?usp=sharing',
  teachers:
    'https://docs.google.com/spreadsheets/d/1_fKEcAqk1Mk1l2PKQxi_aIsgnMy5muNTEBhLUdL2eQc/edit?usp=sharing',
  blocks:
    'https://docs.google.com/spreadsheets/d/1BjFXB0tHzloFURUdtr8I2TAChsu5XWLGlV61NIQ2n3A/edit?usp=sharing',
};

const FileUploadStep = ({ form, updateForm, onSubmit, hide }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues: { ...form },
  });

  const [state, setState] = useState({
    file_type: null,
  });

  const submit = () => {
    if (isValid) {
      onSubmit();
    }
  };

  const getOption = (value) =>
    resource_types.filter((x) => x.value === value)[0];

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="wizard-form"
      autoComplete="name"
    >
      <div className="wizard-form">
        <h5>Create resources from a CSV file</h5>
        <hr />
        <Form.Group>
          <Form.Label>Resource type</Form.Label>
          <Controller
            name="file_type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    options={resource_types}
                    onChange={({ value }) => {
                      setState({ ...state, file_type: value });
                      field.onChange(value);
                      setValue('file_type', value, { shouldValidate: true });
                      updateForm({ file_type: value });
                    }}
                    value={getOption(field.value)}
                  />
                </>
              );
            }}
          />
          {errors['file_type'] && (
            <div className="invalid-feedback">Select a resource type</div>
          )}
        </Form.Group>

        {state.file_type && (
          <Alert variant="info">
            Download the CSV template for this resource upload&nbsp;
            <Alert.Link
              target="_blank"
              href={resource_template_urls[state.file_type]}
            >
              here <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Alert.Link>
          </Alert>
        )}

        <Form.Group>
          <Controller
            name="file"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FileBox
                field={{
                  placeholder:
                    'Drag and drop a file (only CSV files are allowed)',
                }}
                updateParent={(value) => {
                  field.onChange(value);
                  updateForm({ file: value });
                  setValue('file', value, { shouldValidate: true });
                }}
              />
            )}
          />
          {errors['file'] && (
            <div className="invalid-feedback">Select a file</div>
          )}
        </Form.Group>

        {state.file_type !== 'schools' && (
          <Form.Group>
            <Controller
              name="override"
              control={control}
              render={({ field }) => (
                <>
                  <SwitchField
                    field={{
                      currentValue: field.value,
                      isShowLabel: true,
                      name: 'Clear and update existing data?',
                    }}
                    updateParent={(value) => {
                      field.onChange(value);
                      updateForm({ override: value });
                    }}
                  />
                </>
              )}
            />
          </Form.Group>
        )}
        <hr />
        <WizardNavigation step={1} nextStep={() => {}} onClose={hide} />
      </div>
    </form>
  );
};

export default FileUploadStep;
