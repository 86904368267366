import { useLocation } from 'react-router-dom';
import * as M from '@mantine/core';

const Footer = () => {
  const location = useLocation();
  if (location.pathname === '/map') {
    return null;
  }

  return (
    <>
      <M.Divider />
      <M.Text c="dimmed" size="sm" ta="center" p="md">
        Copyright © {new Date().getFullYear()} Saturn Technologies Inc.
      </M.Text>
    </>
  );
};

export default Footer;
