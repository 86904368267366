import { ActionTypes } from '../actions/schools';
import { ActionTypes as ScheduleActionTypes } from '../actions/schedules';

const initialState = {
  schools: null,
  isLoadingSchools: false,
  schoolsLoaded: false,
  schoolsError: false,
  currentSchool: null,
  isLoadingCurrentSchool: false,
  loadingScheduleSettings: false,
  currentSchoolError: null,
  isUpdatingSchool: false,
  isUpdatedSchool: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SCHOOLS:
      return {
        ...state,
        currentSchool: null,
        isLoadingSchools: true,
        schoolsLoaded: false,
        schoolsError: false,
      };
    case ActionTypes.GET_SCHOOLS_SUCCESS:
      return {
        ...state,
        isLoadingSchools: false,
        schools: action.schools,
        tags: action.tags,
        total_user_count: action.total_user_count,
        total: action.total,
        schoolsLoaded: true,
        schoolsError: false,
      };
    case ActionTypes.GET_SCHOOLS_FAIL:
      return {
        ...state,
        isLoadingSchools: false,
        schoolsLoaded: false,
        schoolsError: action.error,
      };
    case ScheduleActionTypes.SET_DAY_SCHEDULE:
    case ActionTypes.GET_SCHOOL_BY_ID:
      return {
        ...state,
        isLoadingCurrentSchool: true,
        currentSchoolError: null,
      };
    case ActionTypes.GET_SCHOOL_BY_ID_SUCCESS:
      return {
        ...state,
        currentSchool: action.school,
        isLoadingCurrentSchool: false,
        currentSchoolError: null,
      };
    case ActionTypes.GET_SCHOOL_BY_ID_FAIL:
      return {
        ...state,
        isLoadingCurrentSchool: false,
        currentSchoolError: action.error,
      };
    case ActionTypes.GET_SCHOOL_RESOURCE:
      return {
        ...state,
        loadingResource: action.resourceType,
        currentSchoolError: null,
      };

    case ActionTypes.GET_SCHOOL_RESOURCE_SUCCESS:
      return {
        ...state,
        currentSchool: action.school,
        loadingResource: null,
        currentSchoolError: null,
      };
    case ActionTypes.GET_SCHOOL_RESOURCE_FAIL:
      return {
        ...state,
        loadingResource: null,
        currentSchoolError: action.error,
      };
    case ActionTypes.UPDATE_SCHOOLS:
      return {
        ...state,
        isUpdatingSchool: true,
        isUpdatedSchool: false,
        currentSchoolError: null,
      };
    case ActionTypes.UPDATE_SCHOOLS_SUCCESS:
      return {
        ...state,
        isUpdatingSchool: false,
        isUpdatedSchool: true,
      };
    case ActionTypes.UPDATE_SCHOOLS_FAIL:
      return {
        ...state,
        isUpdatingSchool: false,
        isUpdatedSchool: false,
        currentSchoolError: action.error,
      };
    case ActionTypes.SET_DAY_SCHEDULE:
      return {
        ...state,
        updatingDay: action.day,
        isUpdatingSchedule: true,
        isUpdatedSchedule: false,
        updateScheduleError: null,
      };
    case ActionTypes.SET_DAY_SCHEDULE_SUCCESS:
      return {
        ...state,
        isUpdatingSchedule: false,
        isUpdatedSchedule: true,
      };
    case ActionTypes.SET_DAY_SCHEDULE_FAIL:
      return {
        ...state,
        isUpdatingSchedule: false,
        isUpdatedSchedule: false,
        updateScheduleError: action.error,
      };
    case ActionTypes.CREATE_DAY_TYPE:
    case ActionTypes.UPDATE_DAY_TYPE:
    case ActionTypes.DELETE_DAY_TYPE:
    case ActionTypes.CREATE_PERIOD_TYPE:
    case ActionTypes.UPDATE_PERIOD_TYPE:
    case ActionTypes.DELETE_PERIOD_TYPE:
    case ActionTypes.DAY_WIZARD_CREATE:
    case ActionTypes.PERIOD_WIZARD_CREATE:
      return {
        ...state,
        loadingScheduleSettings: true,
      };
    case ActionTypes.CREATE_DAY_TYPE_SUCCESS:
    case ActionTypes.UPDATE_DAY_TYPE_SUCCESS:
    case ActionTypes.DELETE_DAY_TYPE_SUCCESS:
    case ActionTypes.CREATE_PERIOD_TYPE_SUCCESS:
    case ActionTypes.UPDATE_PERIOD_TYPE_SUCCESS:
    case ActionTypes.DELETE_PERIOD_TYPE_SUCCESS:
    case ActionTypes.DAY_WIZARD_CREATE_SUCCESS:
    case ActionTypes.PERIOD_WIZARD_CREATE_SUCCESS:
      return {
        ...state,
        loadingScheduleSettings: false,
        currentSchool: {
          ...state.currentSchool,
          ...action.schedulingSettings.data,
        },
      };
    default:
      return state;
  }
};
