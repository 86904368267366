function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function getSuggestions(schools, value) {
  const escapedValue = escapeRegexCharacters(value.trim());
  if (escapedValue === '') {
    return [];
  }
  const regex = new RegExp('^' + escapedValue, 'i');
  return schools.filter((school) => regex.test(school.name));
}

export function getSuggestionValue(suggestion) {
  return `${suggestion.name} (${suggestion.state})`;
}
