import React from 'react';
import { Button } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CalendarNavBar.scss';
import './CalendarNavBar.mqueries.scss';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 5, 11);

const CalendarNavBar = ({ date, localeUtils, onChange, onTodayClick }) => {
  const months = localeUtils.getMonths();

  const years = [];
  for (
    let i = fromMonth.getFullYear() - 1;
    i <= toMonth.getFullYear();
    i += 1
  ) {
    years.push(i);
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <Button
        variant="danger"
        href="#"
        className="today-button"
        onClick={onTodayClick}
      >
        Today
      </Button>
      <div className="day-labels">
        <div>
          <FontAwesomeIcon icon={faCircle} color="#1abc9c" /> &nbsp;
          Today/Selected
        </div>
        <div>
          <FontAwesomeIcon icon={faCircle} color="#128fe7" /> &nbsp; Modified
        </div>
        <div>
          <FontAwesomeIcon icon={faCircle} color="#e83f57" /> &nbsp; Special
        </div>
        <div>
          <FontAwesomeIcon icon={faCircle} color="#adadad" /> &nbsp; Missing
        </div>
      </div>
    </form>
  );
};

export default CalendarNavBar;
