const ActionTypes = {
  UPLOAD_FILES: 'FileUpload.UPLOAD_FILES',
  UPLOAD_FILES_SUCCESS: 'FileUpload.UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAIL: 'FileUpload.UPLOAD_FILES_FAIL',
};

const uploadFiles = () => ({
  type: ActionTypes.UPLOAD_FILES,
});

const actions = {
  uploadFiles,
};

export { ActionTypes, actions };
