import apiClient from 'utils/apiClient';

const baseUrl = 'v2/courses';

const createCourse = (requestBody) => apiClient.post(baseUrl, requestBody);

const updateCourse = (courseId, requestBody) =>
  apiClient.put(`${baseUrl}/${courseId}`, requestBody);

const removeCourse = (courseId) => apiClient.delete(`${baseUrl}/${courseId}`);

const coursesService = () => ({
  createCourse,
  updateCourse,
  removeCourse,
});

export default coursesService;
