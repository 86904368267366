import React from 'react';
import Form from 'react-bootstrap/Form';

import SingleTextField from './SingleTextField/SingleTextField';
import SelectField from './SelectField/SelectField';
import ColorPickerField from './ColourPickerField/ColorPickerField';
import SwitchField from './SwitchField/SwitchField';
import NumericField from './NumericField/NumericField';
import AddressAutoCompleteField from './AddressAutoCompleteField/AddressAutoCompleteField';
import TagInputField from './TagInputField/TagInputField';
import FileBox from '../FileBox/FileBox';
import MessageField from './MessageField/MessageField';
import XHRSelectField from './XHRSelectField/XHRSelectField';
import LogoUploadField from './LogoUploadField/LogoUploadField';

const FieldsContainer = ({ fields, updateParent }) => {
  const getFieldComponent = (field) => {
    switch (field.type) {
      case 'SingleTextField':
        return SingleTextField;
      case 'TagInputField':
        return TagInputField;
      case 'SelectField':
        return SelectField;
      case 'XHRSelectField':
        return XHRSelectField;
      case 'ColorPickerField':
        return ColorPickerField;
      case 'GoogleAutoCompleteField':
        return AddressAutoCompleteField;
      case 'FileBox':
        return FileBox;
      case 'LogoUploadField':
        return LogoUploadField;
      case 'SwitchField':
        return SwitchField;
      case 'NumericField':
        return NumericField;
      case 'MessageField':
        return MessageField;
      default:
        return null;
    }
  };

  return (
    <Form>
      {fields.map((field, i) => {
        const Component = getFieldComponent(field);

        if (!Component) {
          console.warn('Encountered step type without a matching component.');
          return (
            <div style={{ color: '#f00', paddingLeft: '20px' }} key={i}>
              Missing field
            </div>
          );
        }

        return (
          <Component
            key={field.prop}
            field={field}
            updateParent={updateParent}
          />
        );
      })}
    </Form>
  );
};

export default FieldsContainer;
