import { scheduleUtils } from 'features/schedules/utils/schedule';
import { useSchool } from 'hooks/useSchool';
import { useMemo } from 'react';
import { PeriodType, DayType, Schedule } from 'types';
import { CUSTOM_DAY_TYPE, PeriodsBuilderProps } from './PeriodsBuilder';

export function useFilteredPeriodTypes({
  periodTypes,
  selectedDayType,
  customPeriodNames,
  schedule,
}: Pick<PeriodsBuilderProps, 'periodTypes' | 'schedule'> & {
  customPeriodNames: string[];
  selectedDayType?: DayType;
}) {
  const { data: schedules } = useSchool((school) => school.schedules);

  const filteredPeriodTypes = useMemo(() => {
    if (scheduleUtils.isAcfSchedule(schedule)) {
      return periodTypes;
    }

    if (!schedules || !selectedDayType) return [];

    const ACFperiodTypesForDayType = filterPeriodTypesByDayTypeId(
      schedules,
      selectedDayType.id,
    );

    return periodTypes.filter((pt) =>
      ACFperiodTypesForDayType.find((dpt) => dpt.name === pt.name),
    );
  }, [periodTypes, schedules, selectedDayType, schedule]);

  const filteredCustomPeriodTypes = useMemo(() => {
    if (
      scheduleUtils.isAcfSchedule(schedule) ||
      selectedDayType?.name === CUSTOM_DAY_TYPE.name
    ) {
      return customPeriodNames.map((name) => ({ name, id: '' }));
    }
    return [];
  }, [schedule, customPeriodNames, selectedDayType]);

  const result = useMemo(() => {
    return [...filteredPeriodTypes, ...filteredCustomPeriodTypes].filter(
      (item, index, array) => {
        if (index === 0) {
          return true;
        }
        const seenIds = new Set(array.slice(0, index).map((i) => i.name));
        return !seenIds.has(item.name);
      },
    );
  }, [filteredPeriodTypes, filteredCustomPeriodTypes]);

  return {
    filteredPeriodTypes: result,
  };
}

export function filterPeriodTypesByDayTypeId(
  schedules: Schedule[],
  dayTypeId: string,
) {
  return schedules
    .filter((s) => scheduleUtils.isAcfSchedule(s) && s.dayType.id === dayTypeId)
    .flatMap((s) =>
      s.periods.map((p) => p.periodType).filter((pt): pt is PeriodType => !!pt),
    );
}
