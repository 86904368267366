import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import dayjs from 'dayjs';

import { parseTime } from 'features/editSchedule/utils';
import {
  useGetMutation,
  axiosClient,
  getRequestConfig,
  getRequestPropsForScheduleVariant,
  parseScheduleResponse,
} from 'hooks/utils';
import type { ScheduleResponse, ScheduleRequest } from 'types';

const getScheduleRequestBody = (schoolId: string, data: ScheduleRequest) => ({
  ...(data.genId && { genId: data.genId }),
  ...getRequestPropsForScheduleVariant(data),
  displayName: data.displayName,
  draft: data.isDraft,
  userDraft: data.isUserDraft,
  schoolId,
  periods: data.periods.map((p) => ({
    name: p.name,
    periodTypeId: p.periodType?.id || null,
    dayTypeId: p.periodType?.id ? p.dayType.id : null,
    startTime: dayjs(parseTime(p.startTime)).format('HH:mm:ss'),
    endTime: dayjs(parseTime(p.endTime)).format('HH:mm:ss'),
    timezone: data.timezone,
  })),
  lunchWaves: data.lunchWaves.map((wave) => ({
    slotId: wave.slot.id,
    name: wave.name,
    startTime: wave.startTime,
    endTime: wave.endTime,
    timezone: data.timezone,
  })),
});

export const useCreateScheduleMutation = (
  schoolId: string,
  onSuccess?: () => void,
  onError?: (e: Error) => void,
) =>
  useGetMutation<ScheduleRequest, ScheduleResponse>({
    schoolId,
    method: 'post',
    expectedStatus: 200,
    getPath: () => 'v3/schedules',
    getBody: (data) => getScheduleRequestBody(schoolId, data),
    update: (school, responseData) => ({
      ...school,
      schedules: [...school.schedules, parseScheduleResponse(responseData)],
    }),
    onSuccess,
    onError,
  });

export const useUpdateScheduleMutation = (
  schoolId: string,
  onSuccess?: () => void,
  onError?: (e: Error) => void,
) =>
  useGetMutation<ScheduleRequest & { id: string }, ScheduleResponse>({
    schoolId,
    method: 'patch',
    expectedStatus: 200,
    getPath: ({ id }) => `v3/schedules/${id}`,
    getBody: (data) => getScheduleRequestBody(schoolId, data),
    update: (school, responseData) => ({
      ...school,
      schedules: [
        ...school.schedules.filter((s) => s.id !== responseData.id),
        parseScheduleResponse(responseData),
      ],
    }),
    onSuccess,
    onError,
  });

export const useDeleteScheduleMutation = (schoolId: string) =>
  useGetMutation<{ id: string }, void>({
    schoolId,
    method: 'delete',
    expectedStatus: 204,
    getPath: ({ id }) => `v3/schedules/${id}`,
    getBody: () => undefined,
    update: (school, _, { id }) => ({
      ...school,
      schedules: school.schedules.filter((s) => s.id !== id),
    }),
  });

export const useDeleteMultipleSchedulesMutation = (schoolId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: string[]) => {
      const res = await Promise.all(
        ids.map((id) =>
          axiosClient.delete(
            `${process.env.REACT_APP_URL_API}/v3/schedules/${id}`,
            {
              headers: getRequestConfig().headers,
            },
          ),
        ),
      );

      if (res.some((r) => r.status !== 204)) {
        throw new Error('Failed to delete schedules');
      }
    },
    onSuccess: () => queryClient.invalidateQueries(['school', schoolId]),
  });
};

export const useUploadScheduleImageMutation = (
  schoolId: string,
  {
    onSuccess,
    onError,
  }: Pick<
    UseMutationOptions<{ genId: string }, unknown, File, unknown>,
    'onSuccess' | 'onError'
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (file: File) => {
      const res = await axiosClient.request<{ genId: string }>({
        method: 'post',
        baseURL: `${process.env.REACT_APP_URL_API}/`,
        url: `v1/ai/schedules/${schoolId}/from_images`,
        data: (() => {
          const formData = new FormData();
          formData.append('files', file);
          return formData;
        })(),
        headers: {
          Authorization: getRequestConfig().headers.Authorization,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res.status !== 200) {
        throw new Error('Failed to upload schedule image');
      }

      return res.data;
    },
    onSuccess: (...args) => {
      onSuccess?.(...args);
      void queryClient.invalidateQueries(['uploadedScheduleImages', schoolId]);
    },
    onError,
  });
};
