import * as M from '@mantine/core';

type Props = {
  imageSrc: string;
  onClose: () => void;
};

export const ImagePreviewModal = ({ imageSrc, onClose }: Props) => {
  return (
    <M.Modal opened size="xxl" onClose={onClose} withCloseButton={false}>
      <M.Image src={imageSrc} onClick={onClose} />
    </M.Modal>
  );
};
