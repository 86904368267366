import React from 'react';
import { Modal } from 'react-bootstrap';

import StepContainer from '../StepContainer/StepContainer';

const StepModalContainer = ({ hide, show, modalData }) => {
  return (
    <Modal show={show} onHide={hide}>
      <StepContainer modalData={modalData} close={hide} />
    </Modal>
  );
};

export default StepModalContainer;
