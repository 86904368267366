import axios, { AxiosRequestConfig } from 'axios';
import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { getRequestConfig } from 'hooks/utils';
import { AiGenResults, ParsedSchedule, parseAiGenResults } from '../utils';

const BASE_URL = `${process.env.REACT_APP_URL_API}/`;

const get = async <T>(path: string, config?: AxiosRequestConfig) => {
  try {
    // Using axios directly here instead of axiosClient because the schedule titles
    // are dynamic keys that break with the camelCase middleware
    const response = await axios.get<T>(`${BASE_URL}${path}`, {
      ...getRequestConfig(),
      ...config,
    });

    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw new Error(`Error ${path} ${err.code}: ${err.message}`);
    }
    throw err;
  }
};

interface RevisionsResponse {
  revisions: {
    id: string;
    revision_id: number;
  }[];
}

interface RevisionResponse {
  id: string;
  data: AiGenResults;
  image_paths: [string];
}

const getUploadedImagesData = async (schoolId: string) => {
  const revisionsResponse = await get<RevisionsResponse>(
    `v1/admin/ai/${schoolId}`,
  );

  if (!revisionsResponse.revisions.length) {
    return { revisions: [], images: [] };
  }

  const revisionPromises = await Promise.allSettled(
    revisionsResponse.revisions.map((revision) =>
      get<RevisionResponse>(
        `v1/admin/ai/${schoolId}/${revision.id}/${revision.revision_id}`,
      ),
    ),
  );

  const revisions = revisionPromises
    .filter(
      (x): x is PromiseFulfilledResult<RevisionResponse> =>
        x.status === 'fulfilled',
    )
    .map(({ value }) => value);

  const images = await Promise.all(
    revisions.map(async ({ id, image_paths }) => ({
      url: (
        await get<{ url: string }>(`v1/ai/schedules/image`, {
          params: { path: image_paths[0] },
        })
      ).url,
      id,
    })),
  );

  return { revisions, images };
};

const getParseResults = async (schoolId: string) => {
  const { revisions, images } = await getUploadedImagesData(schoolId);

  return revisions.flatMap(({ data, id }) =>
    parseAiGenResults({
      genId: id,
      url: images.find((img) => img.id === id)?.url ?? '',
      aiGenResults: data,
    }),
  );
};

export const useUploadedScheduleImages = (
  schoolId: string,
  queryOptions: Pick<
    UseQueryOptions<string[], unknown, string[], string[]>,
    'refetchInterval'
  > = {},
) => {
  return useQuery({
    queryKey: ['uploadedScheduleImages', schoolId],
    refetchInterval: queryOptions.refetchInterval,
    queryFn: async () => {
      const { images } = await getUploadedImagesData(schoolId);
      return images.map((img) => img.url);
    },
  });
};

export const useGetParseResults = (
  schoolId: string,
  queryOptions: Pick<
    UseQueryOptions<ParsedSchedule[], unknown, ParsedSchedule[], string[]>,
    'refetchInterval' | 'enabled'
  > = {},
) => {
  return useQuery({
    queryKey: ['parsedScheduleImages', schoolId],
    refetchInterval: queryOptions.refetchInterval,
    queryFn: () => getParseResults(schoolId),
  });
};

export const usePrefetchParseResults = (schoolId: string) => {
  const queryClient = useQueryClient();
  return {
    prefetch: () =>
      queryClient.prefetchQuery(['parsedScheduleImages', schoolId], () =>
        getParseResults(schoolId),
      ),
  };
};
