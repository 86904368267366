import React from 'react';
import { Spinner } from 'react-bootstrap';

import './ModalLoading.scss';

const ModalLoading = () => (
  <div className="modal-loading">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

export const SavingSpinner = ({ display }) => {
  return display ? (
    <div className="submitting-step">
      <div>
        <Spinner animation="border" variant="success" className="submitting" />
      </div>
      <h2 className="text-success text-center">Saving</h2>
    </div>
  ) : null;
};

export default ModalLoading;
