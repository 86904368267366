import axios from 'axios';

import { attemptRefreshTokenOnError } from 'hooks/utils';

import { getToken } from './utilities';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}/`,
  headers: { Accept: 'application/json' },
});

apiClient.interceptors.response.use(
  (response) => response,
  attemptRefreshTokenOnError(apiClient),
);

export const updateApiClientAuth = () =>
  (apiClient.defaults.headers.common.Authorization = `Bearer ${getToken()}`);

updateApiClientAuth();

export default apiClient;
