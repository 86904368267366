import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Image, Row, Table } from 'react-bootstrap';
import Chip from '@material-ui/core/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';

import { SCHEDULE_TYPE } from 'components/shared/ScheduleSettingsManager/constants';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import { LoadingError } from 'components/shared/LoadingError/LoadingError';
import { states } from 'constants/states';
import { schoolsActions } from 'store/actions/actions';

export const SchoolBasicInfo = ({ school }) => {
  return (
    school && (
      <div className="school-basic-info">
        <Table bordered hover>
          <tbody>
            <tr>
              <td className="field-title">School Website</td>
              <td>
                <a
                  href={school.website}
                  title={school.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {school.website}
                </a>
              </td>
            </tr>
            <tr>
              <td className="field-title">Status</td>
              <td>
                <Chip
                  label={school.status}
                  className={`status-cell ${school.status} header-status`}
                />
              </td>
            </tr>
            {school && school.status !== 'live' && (
              <tr>
                <td className="field-title">Lite Level</td>
                <td>
                  <Chip
                    label={`Lite ${
                      school.lite_level && school.lite_level.replace('L', '')
                    }`}
                    className={`lite-status`}
                  />
                </td>
              </tr>
            )}
            <tr>
              <td className="field-title">School Name</td>
              <td>{school.school_title}</td>
            </tr>
            <tr>
              <td className="field-title">Nickname</td>
              <td>{school.nickname || 'Not set'}</td>
            </tr>
            <tr>
              <td className="field-title">Enrollment</td>
              <td>{school.students_count}</td>
            </tr>
            <tr>
              <td className="field-title">NCES Code</td>
              <td>{school.nces_code || 'Not set'}</td>
            </tr>
            <tr>
              <td className="field-title">State</td>
              <td>
                {states[school.state]} ({school.state})
              </td>
            </tr>
            <tr>
              <td className="field-title">District</td>
              <td>{school.district_name || 'No district'}</td>
            </tr>
            <tr>
              <td className="field-title">District Schools</td>
              <td>{school.district_school_count || 0} schools</td>
            </tr>
            <tr>
              <td className="field-title">Geolocation</td>
              <td>
                <a
                  href={`https://maps.google.com/?q=${school.lat},${school.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {school.lat}, {school.lng}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  );
};

export const SchoolDataChecks = ({ school }) => {
  if (!school) {
    return null;
  }

  const { errors } = school;

  if (errors.has('courses') || errors.has('staff')) {
    return <LoadingError errors={school.errors} />;
  }

  const renderCheck = (prop) => {
    return (
      <>
        {prop ? (
          <FontAwesomeIcon size="lg" icon={faCheckCircle} color="green" />
        ) : (
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            color="orange"
          />
        )}
        &nbsp;&nbsp;
      </>
    );
  };

  const { schedule_type } = school;

  return (
    school && (
      <div className="school-basic-info">
        <Table bordered hover>
          <tbody>
            <tr>
              <td className="field-title">
                {renderCheck(school.primary_color)}
                Primary Color
              </td>
              <td>
                {school.primary_color ? (
                  <span className="school-color-preview">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faSquare}
                      color={school.primary_color}
                    />
                    &nbsp;({school.primary_color})
                  </span>
                ) : (
                  'Not set'
                )}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(school.logo)}
                Mascot
              </td>
              <td>
                {school.logo ? (
                  <Image height={50} src={school.logo.resource_url} />
                ) : (
                  'Not set'
                )}
              </td>
            </tr>

            <tr>
              <td className="field-title">
                {renderCheck(school.courses.length > 1)}
                Courses
              </td>
              <td>
                {school.courses.length > 1
                  ? school.courses.length
                  : 'Missing Data'}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(school.staff.length > 0)}
                Teachers
              </td>
              <td>
                {school.staff.length > 0 ? school.staff.length : 'Missing Data'}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(
                  school.calendar && school.calendar.mapping.length > 0,
                )}
                Calendar
              </td>
              <td>
                {school.calendar && school.calendar.mapping.length > 0
                  ? school.calendar.mapping.length
                  : 'Missing Data'}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(school.schedules.length > 0)}
                Schedules
              </td>
              <td>
                {school.schedules.length > 0
                  ? school.schedules.length
                  : 'Missing Data'}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(school.domains.length > 0)}
                Domains
              </td>
              <td>
                {school.domains.length > 0 ? (
                  <ul>
                    {school.domains.map((d) => (
                      <li key={d}>{d}</li>
                    ))}
                  </ul>
                ) : (
                  <>
                    {school.crowdsourced_domains.length > 0 ? (
                      <>
                        <p>
                          <b>{school.crowdsourced_domains.length}</b>&nbsp;
                          crowdsourced emails:
                        </p>
                        <ul>
                          {school.crowdsourced_domains.map((d) => (
                            <li key={d.domain}>
                              {d.domain} ({d.users} users)
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      'No domains'
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(!!schedule_type)}
                Schedule Type
              </td>
              <td>
                {schedule_type
                  ? SCHEDULE_TYPE[schedule_type] || schedule_type
                  : 'Not set'}
              </td>
            </tr>
            <tr>
              <td className="field-title">
                {renderCheck(!!school.meta.upgraded_at)}
                Launched
              </td>
              <td>
                {school.status !== 'live'
                  ? 'Not launched yet'
                  : school.meta.upgraded_at
                  ? moment(school.meta.upgraded_at).format('MMM DD, YYYY')
                  : 'Launch date unknown'}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  );
};
