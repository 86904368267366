export default [{x:30.44533693,y:-97.60762669},
{x:30.4945242,y:-92.4350614},
{x:35.61226385,y:-86.70001455},
{x:33.17173511,y:-97.09095665},
{x:35.79608011,y:-84.25718381},
{x:33.4635413,y:-94.40671519},
{x:30.57549996,y:-100.6510395},
{x:35.37671755,y:-118.9156488},
{x:29.54719238,y:-95.13172969},
{x:35.08722808,y:-96.38891727},
{x:37.58496032,y:-84.28442717},
{x:29.76022367,y:-95.75208441},
{x:41.15801672,y:-95.93416818},
{x:39.48126792,y:-98.1081596},
{x:35.08917703,y:-114.597474},
{x:32.80741984,y:-96.61173977},
{x:37.16589559,y:-104.5105215},
{x:32.2541368,y:-90.40437683},
{x:33.9410746,y:-97.12798773},
{x:30.32434623,y:-90.92076615},
{x:35.01770713,y:-110.6831025},
{x:34.04353428,y:-98.91710311},
{x:38.97049562,y:-94.76901861},
{x:32.83042739,y:-96.99433677},
{x:37.66439957,y:-120.9933771},
{x:35.86394041,y:-86.41017639},
{x:35.213033,y:-114.0139105},
{x:29.17320028,y:-81.00343224},
{x:32.68190632,y:-97.00771697},
{x:35.36646772,y:-85.3922023},
{x:35.32718262,y:-94.40043747},
{x:33.65394568,y:-85.83009638},
{x:39.03516115,y:-94.53207119},
{x:36.31933114,y:-86.6245553},
{x:30.84919723,y:-91.00383508},
{x:33.17431262,y:-96.8327114},
{x:35.64819713,y:-88.80136237},
{x:34.64878072,y:-99.33430894},
{x:29.94780452,y:-95.62706262},
{x:30.43901593,y:-97.67089382},
{x:33.417511,y:-86.690465},
{x:43.053214,y:-76.075591},
{x:37.54682518,y:-84.64804351},
{x:39.04557704,y:-94.27166486},
{x:34.19486992,y:-92.05870137},
{x:39.92841508,y:-105.1401378},
{x:29.95290119,y:-82.10892481},
{x:39.04673812,y:-84.24429375},
{x:39.85407936,y:-95.52662998},
{x:29.664399,y:-95.20466},
{x:32.78348396,y:-97.09239095},
{x:44.07828378,y:-103.2505122},
{x:37.64625096,y:-98.72312643},
{x:43.03600357,y:-85.69034592},
{x:37.825369,y:-121.289209},
{x:40.74199989,y:-111.8887568},
{x:41.77450934,y:-111.834727},
{x:38.55426951,y:-92.15156347},
{x:35.64978467,y:-88.12531143},
{x:38.73969934,y:-82.99306691},
{x:36.02807173,y:-83.87417392},
{x:29.89527338,y:-90.13557494},
{x:29.66445053,y:-98.62955086},
{x:36.43402086,y:-99.40423049},
{x:29.69858181,y:-95.81137377},
{x:36.02967674,y:-94.02885228},
{x:32.93981698,y:-96.69930317},
{x:41.699662,y:-72.897846},
{x:43.60665466,y:-116.5139097},
{x:35.34917994,y:-97.490083},
{x:35.10966345,y:-92.44168192},
{x:39.25869404,y:-94.57666531},
{x:39.92803029,y:-105.0176802},
{x:41.26765032,y:-95.98255618},
{x:36.24003001,y:-91.59351312},
{x:32.8979615,y:-97.29031368},
{x:32.13621591,y:-101.8057348},
{x:35.90731973,y:-92.65913733},
{x:34.337,y:-78.7077},
{x:39.02666466,y:-94.3049039},
{x:33.5980505,y:-101.8709527},
{x:39.88556371,y:-104.9433515},
{x:36.57020458,y:-89.96990293},
{x:37.65026782,y:-77.60359786},
{x:36.59259378,y:-87.41762012},
{x:29.90245818,y:-95.55511864},
{x:29.54773031,y:-98.37115648},
{x:36.77979329,y:-76.19135164},
{x:35.23493749,y:-88.38465017},
{x:32.57475463,y:-92.07219825},
{x:30.53170952,y:-87.22568125},
{x:34.45089158,y:-93.07983444},
{x:29.69785284,y:-90.55755911},
{x:38.85390018,y:-94.76172634},
{x:39.08054784,y:-94.44297018},
{x:33.19558943,y:-97.14054399},
{x:34.44448575,y:-93.19773926},
{x:40.44625425,y:-99.37878903},
{x:40.9284,y:-72.6841},
{x:35.71022367,y:-96.87063932},
{x:29.97369953,y:-95.69661854},
{x:36.32147048,y:-95.82527117},
{x:33.9441122,y:-83.98482309},
{x:39.01579623,y:-95.61562836},
{x:35.375798,y:-96.91146121},
{x:29.55574738,y:-95.26192412},
{x:33.54922797,y:-101.9235067},
{x:33.13500992,y:-91.95725151},
{x:32.86311721,y:-97.34011441},
{x:32.72758436,y:-97.4172286},
{x:33.61029699,y:-95.0691323},
{x:29.34044072,y:-98.62301096},
{x:35.88386094,y:-84.6555926},
{x:30.39257549,y:-88.66038815},
{x:35.97981641,y:-84.11621377},
{x:35.25219924,y:-95.11905156},
{x:29.52391658,y:-95.1952252},
{x:39.44015568,y:-87.33357558},
{x:36.06045844,y:-95.76233514},
{x:38.93917111,y:-104.7299485},
{x:30.35326616,y:-95.5833976},
{x:32.22024823,y:-90.15617815},
{x:35.21724477,y:-94.27027908},
{x:32.00672709,y:-106.6074593},
{x:41.56959666,y:-90.5267597},
{x:33.0718994,y:-97.29608998},
{x:30.70031403,y:-86.12321958},
{x:36.162008,y:-84.087269},
{x:32.6442962,y:-96.8395461},
{x:33.41470757,y:-111.5557746},
{x:40.2167227,y:-75.503446},
{x:35.06414328,y:-94.24636737},
{x:35.45538617,y:-97.68963399},
{x:32.99807319,y:-96.91091253},
{x:38.87343163,y:-99.31721427},
{x:41.59895102,y:-87.55851255},
{x:30.51969762,y:-90.49837724},
{x:35.05414776,y:-93.37966932},
{x:32.85880041,y:-79.79671866},
{x:37.18271,y:-93.3015},
{x:28.83648686,y:-81.89045839},
{x:34.61327717,y:-92.49836238},
{x:39.46014427,y:-95.74858173},
{x:33.97915699,y:-80.96033416},
{x:33.47016985,y:-94.28876572},
{x:31.36485767,y:-92.41158333},
{x:30.26382568,y:-98.8565462},
{x:37.13890357,y:-93.33058276},
{x:34.25323701,y:-110.0358796},
{x:31.78313378,y:-85.95385446},
{x:41.4022399,y:-82.65715376},
{x:42.14673358,y:-75.90208746},
{x:30.45780002,y:-88.89263795},
{x:40.8592434,y:-81.48451612},
{x:32.86577577,y:-96.99324645},
{x:35.25520441,y:-92.93785975},
{x:37.70307063,y:-85.86934984},
{x:47.1671,y:-122.4347},
{x:33.15240154,y:-107.2468608},
{x:35.98320265,y:-87.11942823},
{x:36.33877002,y:-96.80110154},
{x:34.3617558,y:-84.0339058},
{x:35.98069112,y:-86.56256542},
{x:30.24575632,y:-97.78005823},
{x:36.72955918,y:-88.63102986},
{x:41.83926592,y:-87.744265},
{x:38.80799094,y:-94.45625961},
{x:36.28002721,y:-95.84537029},
{x:47.9040958,y:-122.2452323},
{x:29.8713175,y:-95.33224761},
{x:34.0240604,y:-80.9526048},
{x:32.07216491,y:-96.44298469},
{x:37.22657587,y:-95.70804508},
{x:39.57931452,y:-105.1378003},
{x:35.29701808,y:-94.38603173},
{x:36.95402759,y:-86.43206522},
{x:36.1151139,y:-97.89849334},
{x:32.41797428,y:-86.26918137},
{x:37.67820355,y:-97.49802478},
{x:30.06534401,y:-81.86981075},
{x:34.7618608,y:-112.4541659},
{x:36.81028542,y:-76.21950209},
{x:36.19693003,y:-93.04257118},
{x:33.51019608,y:-82.14314108},
{x:29.53391454,y:-98.52282912},
{x:32.75539809,y:-97.36078084},
{x:38.39029916,y:-81.83552072},
{x:38.81428609,y:-94.53297347},
{x:33.59184174,y:-101.9054689},
{x:30.50625549,y:-99.77796408},
{x:33.10089603,y:-96.65589698},
{x:33.37855586,y:-96.23903543},
{x:30.65867757,y:-98.44349205},
{x:33.9034956,y:-80.3805705},
{x:32.38163331,y:-88.7481098},
{x:32.78016463,y:-91.89428397},
{x:33.45069422,y:-112.2657218},
{x:39.03024947,y:-95.68798833},
{x:33.57729982,y:-101.9562109},
{x:41.02269474,y:-92.41317004},
{x:35.15717241,y:-89.72810003},
{x:34.76142345,y:-84.93043989},
{x:42.23,y:-83.194},
{x:35.42300368,y:-97.44175271},
{x:35.32234722,y:-97.42355514},
{x:41.5560109,y:-88.12671237},
{x:30.44496817,y:-91.13500625},
{x:30.54766189,y:-89.66936152},
{x:30.26236392,y:-91.25319242},
{x:34.03569528,y:-84.05187929},
{x:29.50738294,y:-95.03770998},
{x:29.73521163,y:-95.7775332},
{x:40.26479117,y:-94.01505099},
{x:37.34067089,y:-95.28378278},
{x:39.4693818,y:-119.7851935},
{x:32.55634719,y:-93.61262709},
{x:33.14204993,y:-117.1901791},
{x:38.62728441,y:-92.5683003},
{x:39.41254838,y:-104.8647702},
{x:39.4500958,y:-82.22104921},
{x:37.59042171,y:-77.50476636},
{x:29.55662582,y:-95.37669986},
{x:39.08708627,y:-108.458088},
{x:32.756006,y:-98.92784134},
{x:29.81543001,y:-94.38437915},
{x:34.01023452,y:-95.49865052},
{x:36.33446608,y:-94.17927191},
{x:39.73611686,y:-82.63979719},
{x:34.18293096,y:-79.81865913},
{x:36.44326,y:-94.11677149},
{x:34.97913785,y:-85.27646766},
{x:32.44350889,y:-93.98358331},
{x:30.44135972,y:-86.5818246},
{x:36.72710014,y:-76.29943595},
{x:32.51553515,y:-92.19072439},
{x:31.60687013,y:-89.19645041},
{x:35.48467176,y:-94.22571785},
{x:29.85713344,y:-90.10313027},
{x:32.56228151,y:-94.8013895},
{x:35.26869632,y:-91.19920608},
{x:43.0070911,y:-87.96722621},
{x:30.41563482,y:-88.830233},
{x:38.1919839,y:-84.27770957},
{x:35.93451577,y:-85.48236072},
{x:29.35011568,y:-98.42966759},
{x:34.74024597,y:-97.25736558},
{x:33.93959288,y:-81.14627079},
{x:40.74360748,y:-73.32272843},
{x:31.6645747,y:-96.2302709},
{x:39.08034908,y:-108.5851617},
{x:39.3329033,y:-101.725439},
{x:32.73704851,y:-96.28614023},
{x:35.69564348,y:-81.22646749},
{x:25.88198194,y:-80.35329849},
{x:36.29186896,y:-88.31758574},
{x:35.19124121,y:-111.6347757},
{x:32.54177999,y:-82.94549151},
{x:36.86641609,y:-88.34974676},
{x:32.72185185,y:-103.1428845},
{x:40.40424945,y:-74.12549816},
{x:42.206785,y:-121.722305},
{x:33.19393,y:-97.105769},
{x:32.547107,y:-94.731581},
{x:32.91552953,y:-97.32231983},
{x:37.75292623,y:-100.0247482},
{x:33.45420331,y:-88.81158695},
{x:31.781739,y:-106.2162707},
{x:34.43711982,y:-85.72584965},
{x:36.4914387,y:-82.50552982},
{x:35.22359706,y:-85.2180581},
{x:36.046,y:-86.9503},
{x:30.54822242,y:-91.05258246},
{x:33.85757245,y:-98.52023743},
{x:34.977061,y:-80.99909798},
{x:39.36802617,y:-93.48411575},
{x:31.63028871,y:-84.17619065},
{x:38.88418925,y:-94.69978944},
{x:35.11582019,y:-106.7033581},
{x:33.98637458,y:-85.86801143},
{x:35.60875406,y:-97.46156752},
{x:33.6760285,y:-96.61030278},
{x:39.36828428,y:-94.36791785},
{x:39.72259115,y:-90.26296647},
{x:36.12731301,y:-80.06793894},
{x:40.13956663,y:-74.2257759},
{x:34.5245554,y:-88.65589947},
{x:32.52939537,y:-93.69959907},
{x:36.91563503,y:-76.21600248},
{x:39.423,y:-75.041},
{x:36.26959586,y:-87.05893799},
{x:35.1901921,y:-101.7961454},
{x:29.59956468,y:-98.27500508},
{x:33.43796059,y:-111.6837642},
{x:37.68633567,y:-77.46166795},
{x:33.61907092,y:-86.61158144},
{x:33.22826641,y:-91.7971526},
{x:36.29520037,y:-76.23099134},
{x:46.40691124,y:-117.0024245},
{x:38.2212317,y:-91.158809},
{x:33.68600763,y:-87.82844738},
{x:30.01181236,y:-95.80073297},
{x:34.53100461,y:-92.64514424},
{x:31.30590284,y:-96.8907445},
{x:35.30474815,y:-96.9426548},
{x:35.52823185,y:-97.56953583},
{x:35.14861,y:-97.47721},
{x:37.13696642,y:-93.26578663},
{x:30.51225489,y:-97.69667571},
{x:31.30779224,y:-86.47824615},
{x:39.11524475,y:-94.76113205},
{x:33.66181325,y:-94.11870025},
{x:30.44307461,y:-91.17942087},
{x:31.55405042,y:-110.2606297},
{x:31.85021483,y:-86.64288685},
{x:32.5283459,y:-92.08139151},
{x:37.08686037,y:-76.48363652},
{x:33.19438057,y:-96.88559963},
{x:30.35382611,y:-97.73278311},
{x:42.8474,y:-106.21788},
{x:30.02271182,y:-95.51985876},
{x:37.80666146,y:-100.3387222},
{x:26.52847333,y:-80.09297803},
{x:36.27722509,y:-92.58784757},
{x:33.68329359,y:-112.1010851},
{x:32.96255164,y:-97.67788701},
{x:34.67641415,y:-92.34451153},
{x:33.0819436,y:-82.00989649},
{x:36.37098528,y:-94.22207057},
{x:38.2243714,y:-77.50520381},
{x:38.18863827,y:-99.09920848},
{x:36.08565633,y:-94.12177305},
{x:36.45113797,y:-77.64755535},
{x:35.38555564,y:-86.23370916},
{x:35.77235437,y:-91.64038791},
{x:38.28476387,y:-95.24876922},
{x:37.52940739,y:-95.80880307},
{x:30.49754319,y:-97.61126965},
{x:29.95931056,y:-95.67271248},
{x:36.55211504,y:-82.52826638},
{x:32.8669,y:-90.3938},
{x:34.81007063,y:-88.20747703},
{x:34.27557272,y:-91.34045676},
{x:41.45944169,y:-84.55024414},
{x:34.7733007,y:-84.77012694},
{x:29.91717323,y:-94.01960001},
{x:37.8096608,y:-89.02788431},
{x:38.50666686,y:-90.44487103},
{x:33.21406427,y:-97.09116998},
{x:31.43436427,y:-97.73657308},
{x:34.65783482,y:-97.95738861},
{x:34.75177052,y:-89.45368633},
{x:35.32437058,y:-87.75870591},
{x:32.26703137,y:-107.7695194},
{x:36.84313308,y:-76.14556513},
{x:30.26123336,y:-94.22042906},
{x:35.94501883,y:-95.88727914},
{x:32.6951,y:-95.8845},
{x:38.97848478,y:-94.59448672},
{x:31.59955556,y:-97.10755483},
{x:38.25874323,y:-104.6072097},
{x:33.46549694,y:-112.1679492},
{x:31.06166196,y:-98.18165012},
{x:32.85786752,y:-97.28937759},
{x:32.28307042,y:-90.10195278},
{x:38.69116564,y:-90.38939253},
{x:38.75230547,y:-89.6807246},
{x:33.89506325,y:-94.80779663},
{x:42.88814182,y:-112.4508116},
{x:34.53094606,y:-96.21849843},
{x:40.12136306,y:-88.27754974},
{x:34.46810393,y:-110.091733},
{x:39.17805659,y:-94.61161249},
{x:31.6068661,y:-84.22282809},
{x:41.20577905,y:-96.15691827},
{x:42.62971237,y:-82.97411936},
{x:32.33434553,y:-96.11092106},
{x:35.30380482,y:-98.98797445},
{x:30.05692991,y:-94.79795694},
{x:30.12073276,y:-81.62691861},
{x:33.64785809,y:-97.36834936},
{x:35.20358524,y:-97.46743619},
{x:39.10810374,y:-93.21079364},
{x:40.85239339,y:-96.68274187},
{x:37.81816973,y:-96.29458573},
{x:34.62402501,y:-98.46612626},
{x:36.72852758,y:-95.93446926},
{x:37.62811695,y:-84.7743644},
{x:33.52742353,y:-101.9063755},
{x:36.85911112,y:-76.21061756},
{x:37.34690614,y:-76.73849479},
{x:30.22562361,y:-97.70253919},
{x:32.97112092,y:-96.6820512},
{x:32.32779104,y:-90.30160569},
{x:36.69362264,y:-93.121717},
{x:28.97268564,y:-96.6588521},
{x:38.0121,y:-121.865},
{x:36.11741723,y:-115.1014786},
{x:41.9313,y:-88.7742},
{x:36.36693082,y:-91.82150636},
{x:40.52078695,y:-74.23565179},
{x:34.38498434,y:-99.01660569},
{x:46.83981733,y:-96.8609868},
{x:32.98097307,y:-96.84348062},
{x:38.989969,y:-104.8042},
{x:35.18795182,y:-106.6839215},
{x:31.07149894,y:-97.73764463},
{x:32.52055281,y:-92.363403},
{x:32.52524084,y:-97.34547697},
{x:37.00336466,y:-121.5468456},
{x:32.8047,y:-80.0133},
{x:37.66482634,y:-97.57179961},
{x:31.96368184,y:-83.76562985},
{x:41.63734433,y:-83.47834424},
{x:36.17013092,y:-115.0627475},
{x:30.38778737,y:-91.06360032},
{x:29.56091302,y:-98.39468616},
{x:27.63391397,y:-80.38848505},
{x:39.7820402,y:-93.55320733},
{x:28.97735991,y:-95.9694741},
{x:39.91342971,y:-104.9752137},
{x:31.34629329,y:-89.25385841},
{x:37.29275505,y:-87.12322739},
{x:35.87285312,y:-84.51520242},
{x:33.73182278,y:-96.5393322},
{x:35.29682404,y:-94.04347167},
{x:39.81178955,y:-105.0539692},
{x:31.72884821,y:-98.98112409},
{x:38.45548121,y:-107.8655452},
{x:32.44075278,y:-81.78373933},
{x:32.17227074,y:-91.72416822},
{x:30.6752448,y:-87.85277367},
{x:34.91826041,y:-95.31686245},
{x:33.66647563,y:-93.59690251},
{x:36.29849256,y:-95.32153487},
{x:29.52146235,y:-95.16350985},
{x:41.65778582,y:-93.69833373},
{x:30.14603533,y:-96.39683314},
{x:36.9850928,y:-86.4588534},
{x:35.77547508,y:-119.2294344},
{x:32.68946659,y:-97.11552166},
{x:33.08621355,y:-96.62245587},
{x:34.85495193,y:-106.6913056},
{x:34.004225,y:-95.103495},
{x:31.33439309,y:-89.34444327},
{x:38.28574708,y:-76.4780885},
{x:29.90145032,y:-90.06403453},
{x:43.61230811,y:-116.2440278},
{x:36.66915701,y:-96.3157265},
{x:35.92867579,y:-89.88270012},
{x:36.05656626,y:-115.2798086},
{x:35.81405157,y:-83.98169764},
{x:43.52093072,y:-96.77392558},
{x:32.91051853,y:-80.02467827},
{x:30.95730578,y:-97.53581333},
{x:29.84369417,y:-97.96910987},
{x:43.18514288,y:-89.26390127},
{x:34.77803046,y:-96.67024216},
{x:41.87801106,y:-103.6760795},
{x:39.35644529,y:-76.7646718},
{x:39.11693135,y:-95.08659952},
{x:40.92607873,y:-98.38426621},
{x:34.74458367,y:-87.67467097},
{x:34.89813012,y:-94.60338414},
{x:39.01522223,y:-95.72490559},
{x:35.6745614,y:-80.47979214},
{x:27.51148179,y:-97.85561621},
{x:35.2916463,y:-97.6093696},
{x:36.46801088,y:-86.66158453},
{x:32.69473995,y:-96.3977565},
{x:37.72259911,y:-97.20570839},
{x:36.07833293,y:-115.1015188},
{x:32.35313233,y:-97.38791388},
{x:33.37159328,y:-104.5237027},
{x:28.41956318,y:-97.75577483},
{x:39.7556,y:-99.3069},
{x:35.24117457,y:-85.84675357},
{x:37.75469292,y:-121.1400591},
{x:35.61304348,y:-90.33688144},
{x:30.52102445,y:-91.15185054},
{x:32.30944686,y:-96.01024301},
{x:33.10842503,y:-96.80290282},
{x:35.87466133,y:-86.35356635},
{x:39.94255426,y:-77.6346007},
{x:40.16735,y:-75.1251222},
{x:31.75591802,y:-106.3405632},
{x:36.19697324,y:-95.15967697},
{x:36.75560066,y:-90.40778697},
{x:30.44473594,y:-90.08026914},
{x:31.24183764,y:-89.80947247},
{x:33.35054279,y:-86.62148952},
{x:30.78680646,y:-86.55974374},
{x:35.32105291,y:-97.49490872},
{x:31.75328168,y:-93.10655997},
{x:29.80694965,y:-91.50832684},
{x:34.12857172,y:-116.394475},
{x:39.17003045,y:-91.87468842},
{x:48.19924219,y:-101.3183199},
{x:32.91922448,y:-96.97039806},
{x:32.3440143,y:-90.06514445},
{x:39.07837298,y:-94.3816942},
{x:34.25508155,y:-88.74963462},
{x:31.36606913,y:-89.35397655},
{x:30.597963,y:-96.298546},
{x:33.57590746,y:-92.85211623},
{x:35.73385706,y:-79.44342212},
{x:43.64942729,y:-116.4131552},
{x:37.01005387,y:-86.39090941},
{x:36.22605281,y:-92.6794544},
{x:35.79148105,y:-89.14010458},
{x:33.5176506,y:-81.71574935},
{x:38.05714138,y:-97.91834235},
{x:36.1761964,y:-94.25220682},
{x:43.66581498,y:-116.2800211},
{x:32.24278668,y:-101.4748938},
{x:37.51417252,y:-94.84622866},
{x:29.4636227,y:-98.64422303},
{x:42.91225,y:-78.753597},
{x:32.54990848,y:-92.61077332},
{x:32.90974938,y:-96.7663832},
{x:36.76140154,y:-76.00773982},
{x:35.87097532,y:-97.42576943},
{x:26.22050083,y:-98.26160342},
{x:29.32210226,y:-98.55332785},
{x:26.19330395,y:-80.25816711},
{x:36.55279,y:-86.238609},
{x:33.47087808,y:-86.82321177},
{x:35.78417025,y:-78.72131809},
{x:29.20853805,y:-96.27881863},
{x:30.23709015,y:-93.35657537},
{x:35.62121,y:-97.63912},
{x:34.49506462,y:-87.27967546},
{x:33.48654954,y:-89.75701869},
{x:34.66963701,y:-92.37969699},
{x:34.73143797,y:-80.80674469},
{x:35.07992576,y:-106.7083483},
{x:37.31731613,y:-79.9637082},
{x:36.74650598,y:-95.98072799},
{x:39.68776257,y:-83.96445372},
{x:29.53313867,y:-98.68216776},
{x:33.71171233,y:-112.2046501},
{x:32.74789293,y:-97.79800489},
{x:41.6799745,y:-86.89716974},
{x:37.1354929,y:-80.57325288},
{x:32.67105346,y:-97.37486511},
{x:29.45962574,y:-95.05125873},
{x:35.52898486,y:-108.7066745},
{x:32.32281931,y:-94.52292994},
{x:33.62239682,y:-117.6851539},
{x:38.44408563,y:-105.231535},
{x:41.93328845,y:-83.41003239},
{x:29.05871583,y:-82.45453819},
{x:37.27579336,y:-97.3939088},
{x:47.05473359,y:-122.7761853},
{x:32.49956675,y:-93.69317651},
{x:32.36060371,y:-86.16770939},
{x:30.48806017,y:-94.99617755},
{x:36.96177889,y:-84.07538586},
{x:32.76163704,y:-97.32447877},
{x:37.18111894,y:-93.23613207},
{x:39.34623357,y:-84.39507633},
{x:34.24688951,y:-88.40490907},
{x:38.56625373,y:-90.40627672},
{x:32.41167287,y:-100.8586724},
{x:37.79469684,y:-97.33529315},
{x:37.56399994,y:-90.28922931},
{x:39.04474863,y:-94.35095542},
{x:29.718,y:-95.847},
{x:34.62318107,y:-98.62184986},
{x:33.376388,y:-112.5712645},
{x:33.0739919,y:-112.0441312},
{x:29.71698062,y:-98.10403436},
{x:30.56742539,y:-97.84862907},
{x:35.66794376,y:-88.8580168},
{x:30.66578731,y:-97.69357842},
{x:30.28407272,y:-89.76699198},
{x:30.88914127,y:-84.56744381},
{x:35.07055482,y:-106.5162812},
{x:32.26297611,y:-80.85711658},
{x:38.29921381,y:-104.615654},
{x:33.0428497,y:-84.9797825},
{x:29.5262,y:-98.7178},
{x:27.7141019,y:-97.37135813},
{x:29.78682953,y:-95.52122533},
{x:35.45369345,y:-86.7774295},
{x:38.00940613,y:-87.49156442},
{x:34.60919513,y:-98.36088002},
{x:36.04675483,y:-86.67702065},
{x:45.80928847,y:-108.4721386},
{x:32.42490092,y:-80.73153554},
{x:30.06696304,y:-94.12983477},
{x:32.92852275,y:-84.33355277},
{x:35.74035677,y:-83.997095},
{x:35.64062878,y:-106.0143266},
{x:30.34116015,y:-94.18291837},
{x:35.40535207,y:-97.50848607},
{x:34.230673,y:-80.6720463},
{x:39.70569991,y:-84.1416572},
{x:33.02491518,y:-80.16513363},
{x:30.87133666,y:-83.27722416},
{x:35.69845896,y:-89.99385171},
{x:39.37908586,y:-97.14242287},
{x:32.52392258,y:-92.11347476},
{x:33.52094558,y:-101.8710735},
{x:32.73586726,y:-97.63183104},
{x:35.66113406,y:-78.83537807},
{x:36.13467088,y:-96.10076315},
{x:38.98537,y:-90.968977},
{x:33.03630613,y:-97.07520806},
{x:35.55988799,y:-80.41448639},
{x:35.4456309,y:-97.60053563},
{x:32.2393168,y:-92.71035478},
{x:36.02700766,y:-80.3817802},
{x:31.0744531,y:-97.6874642},
{x:38.78961857,y:-90.66207916},
{x:28.89851227,y:-99.09271069},
{x:28.97537473,y:-95.37362337},
{x:31.97357756,y:-90.97809434},
{x:40.028003,y:-76.221673},
{x:27.85590966,y:-82.20456451},
{x:35.96039131,y:-95.39010915},
{x:36.80780396,y:-119.8553099},
{x:29.90921969,y:-96.87040128},
{x:30.30025791,y:-93.20737616},
{x:35.22863319,y:-96.67147391},
{x:32.82967904,y:-97.21920043},
{x:34.19566177,y:-90.56506902},
{x:32.73736415,y:-94.94202107},
{x:37.56488865,y:-97.33459578},
{x:38.30008744,y:-85.55904911},
{x:32.99823746,y:-94.95802305},
{x:33.62598476,y:-111.9955442},
{x:33.61034167,y:-112.3597312},
{x:39.47700744,y:-88.37704764},
{x:34.5729831,y:-87.01277689},
{x:35.43496297,y:-97.4853728},
{x:27.4186979,y:-80.38326816},
{x:35.02714661,y:-79.0557583},
{x:43.5842905,y:-116.1943071},
{x:33.43449449,y:-101.6663486},
{x:37.69161884,y:-97.13573948},
{x:36.25301756,y:-94.13621026},
{x:30.22468722,y:-95.55829346},
{x:39.528125,y:-105.3059379},
{x:33.33438289,y:-86.79238246},
{x:32.65909748,y:-97.06842609},
{x:27.93831104,y:-82.26458029},
{x:30.1884623,y:-97.7668509},
{x:30.65225279,y:-93.89633842},
{x:40.1330133,y:-76.2152533},
{x:35.8237442,y:-95.67689405},
{x:40.25178298,y:-111.6675898},
{x:32.41311515,y:-99.49217677},
{x:38.68754489,y:-93.25150075},
{x:38.55232297,y:-90.03862649},
{x:36.12098341,y:-89.26172197},
{x:36.08704265,y:-93.73233994},
{x:34.68375231,y:-90.37538962},
{x:36.36824998,y:-96.01031408},
{x:36.73256466,y:-108.2364746},
{x:35.597066,y:-77.33837821},
{x:40.1099443,y:-76.4821812},
{x:39.53283934,y:-104.7957269},
{x:32.70505014,y:-96.27764943},
{x:37.943555,y:-91.776857},
{x:32.52041695,y:-94.86268401},
{x:35.027277,y:-90.790349},
{x:39.39467093,y:-77.40985439},
{x:36.08079638,y:-86.72217823},
{x:40.42073539,y:-104.7760816},
{x:33.76050085,y:-89.80941967},
{x:33.57037865,y:-84.5429197},
{x:30.71128781,y:-94.94333251},
{x:31.27927541,y:-94.5808442},
{x:36.31215237,y:-82.41218474},
{x:38.65563172,y:-76.87762298},
{x:35.2092331,y:-90.20442591},
{x:35.51823357,y:-108.77159},
{x:35.33246638,y:-96.91242726},
{x:29.8763611,y:-97.66851806},
{x:30.09776063,y:-97.87589371},
{x:36.35551058,y:-94.117679},
{x:29.37562498,y:-98.46174114},
{x:35.67894934,y:-90.5250655},
{x:32.59566864,y:-96.94190629},
{x:30.2503512,y:-92.26919778},
{x:35.03598774,y:-106.7131475},
{x:33.95509004,y:-98.68028551},
{x:33.72541992,y:-85.81550022},
{x:33.87479277,y:-117.8793184},
{x:36.57268629,y:-82.19226354},
{x:32.49851257,y:-92.06947446},
{x:30.10580009,y:-94.16789667},
{x:30.13265949,y:-94.18912099},
{x:36.57534008,y:-86.5146424},
{x:37.45892117,y:-89.23988505},
{x:34.94261269,y:-100.8965515},
{x:33.48476232,y:-82.10117743},
{x:30.56713646,y:-97.66323325},
{x:39.3802665,y:-84.549243},
{x:31.31918562,y:-95.43736875},
{x:36.20890433,y:-86.30386618},
{x:36.00239948,y:-95.6799531},
{x:40.54170332,y:-89.59427968},
{x:32.26942293,y:-90.26316494},
{x:39.77419021,y:-104.8107076},
{x:32.6786881,y:-97.14912303},
{x:40.43786161,y:-105.077937},
{x:33.61243807,y:-84.34902281},
{x:38.35779643,y:-77.51744316},
{x:32.79492507,y:-97.05557764},
{x:36.07402795,y:-83.92831341},
{x:35.38219383,y:-97.52962566},
{x:31.84863128,y:-102.4660917},
{x:30.08578478,y:-97.82152668},
{x:34.2466487,y:-80.598019},
{x:30.10735813,y:-96.04613614},
{x:32.43206573,y:-87.02499502},
{x:34.96488446,y:-89.98935096},
{x:32.56038703,y:-92.91194022},
{x:34.88685282,y:-98.36592257},
{x:47.03591137,y:-122.9407909},
{x:37.40356737,y:-77.66241789},
{x:42.18960783,y:-83.18371431},
{x:34.34085497,y:-89.03911539},
{x:39.53270488,y:-83.44386137},
{x:29.97765984,y:-93.95838261},
{x:35.60441449,y:-105.2230496},
{x:31.27181805,y:-92.48507403},
{x:31.11977785,y:-97.70312057},
{x:35.47759029,y:-97.7609525},
{x:39.77079092,y:-94.80393157},
{x:36.150602,y:-81.20071},
{x:35.33926122,y:-119.0533917},
{x:29.356014,y:-98.86013769},
{x:37.01931233,y:-95.92892714},
{x:32.80480125,y:-94.71832484},
{x:38.67674305,y:-121.7478718},
{x:31.62930211,y:-91.55571745},
{x:30.09418805,y:-93.76088619},
{x:33.52547591,y:-101.9563499},
{x:32.34066483,y:-106.7765529},
{x:39.8991503,y:-75.3049525},
{x:29.2940788,y:-97.16245025},
{x:40.58817664,y:-111.9350121},
{x:35.82051223,y:-90.44891864},
{x:32.02595482,y:-102.1453194},
{x:38.25740035,y:-94.35155637},
{x:45.30191061,y:-122.7737053},
{x:31.31599176,y:-89.41154572},
{x:36.41471229,y:-96.3886866},
{x:33.47951,y:-101.88835},
{x:36.49860831,y:-91.53739863},
{x:42.13881715,y:-88.01656707},
{x:35.46213704,y:-97.40675055},
{x:33.225813,y:-97.59359511},
{x:37.29817478,y:-89.5805689},
{x:32.79374085,y:-97.34864836},
{x:33.4000351,y:-91.03511341},
{x:36.15474523,y:-86.66583584},
{x:36.23038429,y:-84.15916935},
{x:33.88033046,y:-98.52944168},
{x:31.90090321,y:-102.3532174},
{x:35.09309674,y:-78.98466378},
{x:35.08628212,y:-92.19765991},
{x:35.43680188,y:-97.56509945},
{x:31.69876685,y:-106.2408077},
{x:32.57930887,y:-97.35373961},
{x:39.25484565,y:-81.55483894},
{x:33.94504897,y:-91.84293643},
{x:34.15802075,y:-97.99754732},
{x:32.74574557,y:-97.00795636},
{x:31.23385992,y:-85.45503169},
{x:36.24224855,y:-75.87922499},
{x:32.44172841,y:-93.86399165},
{x:35.52678234,y:-98.35159086},
{x:35.86255676,y:-90.64484864},
{x:32.86961236,y:-97.20781526},
{x:37.9761622,y:-87.63523714},
{x:39.72390745,y:-94.79136455},
{x:36.06096148,y:-90.54955628},
{x:35.60956366,y:-91.252757},
{x:61.57498572,y:-149.3995401},
{x:32.27597379,y:-110.9606172},
{x:33.37608981,y:-112.1678352},
{x:34.55645707,y:-112.4721446},
{x:35.28246843,y:-93.09847713},
{x:35.02034081,y:-89.95416172},
{x:36.74870644,y:-108.1870267},
{x:35.43857425,y:-88.64630478},
{x:33.87272496,y:-98.57646793},
{x:35.04791208,y:-89.88676786},
{x:42.53518257,y:-83.10615019},
{x:35.50667,y:-92.03017},
{x:32.13453054,y:-110.9716626},
{x:38.58813472,y:-89.92715188},
{x:25.47810985,y:-80.44308342},
{x:32.9356918,y:-97.18644261},
{x:35.83778777,y:-78.32294808},
{x:30.20500448,y:-93.87233011},
{x:36.37346798,y:-85.33955053},
{x:36.16046676,y:-95.92221425},
{x:30.34954175,y:-89.15154166},
{x:40.41526447,y:-86.83952346},
{x:36.11603285,y:-115.1973662},
{x:39.74702197,y:-94.23581269},
{x:36.95118019,y:-86.47510395},
{x:38.36203227,y:-97.21280203},
{x:32.24982917,y:-110.9880386},
{x:34.67965999,y:-86.75108985},
{x:35.54927506,y:-97.56522485},
{x:40.8286,y:-96.78827},
{x:38.96974988,y:-84.66561802},
{x:31.99147802,y:-90.36576346},
{x:31.70906791,y:-98.9760983},
{x:35.50577145,y:-86.45649747},
{x:26.61846309,y:-80.14280134},
{x:34.50047254,y:-88.20688695},
{x:26.79700687,y:-80.08579868},
{x:47.66982914,y:-117.4100934},
{x:32.545533,y:-93.51142211},
{x:31.79193902,y:-106.2593137},
{x:35.14605061,y:-101.8833208},
{x:32.5793745,y:-97.24373595},
{x:28.18451473,y:-82.35285971},
{x:32.872212,y:-97.31317163},
{x:29.46779574,y:-98.50826472},
{x:39.88409419,y:-104.7725034},
{x:35.22789162,y:-88.23510513},
{x:38.19656052,y:-92.83025757},
{x:29.58616176,y:-98.45193028},
{x:34.93098535,y:-86.57224283},
{x:38.463539,y:-121.491961},
{x:40.85463222,y:-74.07109425},
{x:35.01658565,y:-92.07173246},
{x:41.71782825,y:-88.28442693},
{x:35.75177356,y:-95.38116558},
{x:38.4101948,y:-82.3787029},
{x:33.57098,y:-81.9388388},
{x:39.16647839,y:-93.87949147},
{x:34.79076824,y:-84.96249681},
{x:39.781,y:-104.7727},
{x:38.01051359,y:-84.45479975},
{x:30.19101904,y:-93.18064273},
{x:38.5292981,y:-89.14854332},
{x:35.70544886,y:-82.57840338},
{x:34.32865438,y:-93.53306204},
{x:33.00427802,y:-96.98145839},
{x:30.46822336,y:-90.95707722},
{x:35.33193238,y:-97.54724399},
{x:35.97419893,y:-86.49187177},
{x:34.70736148,y:-82.25613182},
{x:36.26417884,y:-94.48071466},
{x:41.43888806,y:-97.35237323},
{x:41.18934066,y:-96.0242837},
{x:32.9688222,y:-97.35317945},
{x:32.08363,y:-96.48062},
{x:34.51426128,y:-87.71667018},
{x:34.07359628,y:-81.15787201},
{x:33.9893693,y:-81.0306302},
{x:39.86928917,y:-105.0244656},
{x:38.05623754,y:-97.34556424},
{x:38.09917424,y:-102.6194984},
{x:37.09379888,y:-93.8115263},
{x:37.68332043,y:-97.38882869},
{x:35.35389223,y:-119.1469348},
{x:38.83541421,y:-97.60986954},
{x:36.01055672,y:-90.53138268},
{x:32.77982843,y:-108.2742618},
{x:35.01888555,y:-85.37131526},
{x:32.5611198,y:-93.78416163},
{x:31.80524966,y:-95.15192204},
{x:30.07267546,y:-90.49127593},
{x:35.32071057,y:-106.5539463},
{x:29.88921693,y:-95.64170882},
{x:29.89253198,y:-97.91508213},
{x:34.99426336,y:-80.54183097},
{x:38.95515503,y:-85.89654543},
{x:30.33018953,y:-81.54816091},
{x:37.46778643,y:-105.8760323},
{x:36.08095391,y:-79.51087566},
{x:36.25700381,y:-86.65642999},
{x:30.19117629,y:-98.08416572},
{x:32.46973297,y:-85.08655613},
{x:33.38497046,y:-91.04040456},
{x:33.4641588,y:-82.49638416},
{x:39.22394541,y:-94.57653322},
{x:36.41602048,y:-94.45284382},
{x:32.82579909,y:-96.83175333},
{x:33.4642,y:-84.44875},
{x:36.391426,y:-97.940075},
{x:33.14157606,y:-96.80403338},
{x:35.40340035,y:-80.60878894},
{x:35.01443718,y:-85.16328916},
{x:31.99560451,y:-81.114323},
{x:32.67579706,y:-97.46783584},
{x:30.38865151,y:-86.4238067},
{x:28.87636289,y:-96.99781544},
{x:31.44879238,y:-85.65992296},
{x:37.81699922,y:-96.87552498},
{x:33.49421083,y:-112.2796752},
{x:29.915657,y:-98.215464},
{x:33.56621745,y:-81.73579351},
{x:34.53186196,y:-97.96753876},
{x:38.990673,y:-105.047816},
{x:35.69565645,y:-88.83368059},
{x:29.9819303,y:-92.12854073},
{x:33.21522099,y:-96.61893145},
{x:39.170999,y:-84.639528},
{x:20.879653,y:-156.447057},
{x:33.02959271,y:-96.84272155},
{x:32.67627569,y:-97.41585732},
{x:36.35014885,y:-92.37004801},
{x:35.44898769,y:-94.7980173},
{x:40.4055413,y:-105.0439505},
{x:29.6609,y:-95.47465},
{x:41.96571669,y:-87.65587933},
{x:45.61509376,y:-122.4764758},
{x:35.31752161,y:-119.0766118},
{x:38.93958805,y:-76.56554461},
{x:32.17093219,y:-96.01958781},
{x:34.13918151,y:-117.9821525},
{x:33.1892984,y:-79.9989035},
{x:36.980006,y:-86.46964},
{x:33.63819953,y:-112.3927613},
{x:34.72523603,y:-88.94748693},
{x:29.48037253,y:-95.3970974},
{x:32.47435961,y:-95.39028617},
{x:31.80703683,y:-94.18850408},
{x:31.52611128,y:-96.53340922},
{x:30.20959644,y:-97.78369949},
{x:30.53156752,y:-97.84519382},
{x:35.34882342,y:-94.42275212},
{x:29.52238552,y:-95.07528864},
{x:30.35875371,y:-103.6563432},
{x:37.49308795,y:-120.8728521},
{x:29.99887597,y:-92.2892017},
{x:27.9742,y:-81.8849},
{x:31.26470875,y:-85.44035599},
{x:38.80621657,y:-90.29748783},
{x:34.73844626,y:-92.27667592},
{x:32.32921438,y:-96.62293934},
{x:29.79017931,y:-95.16030662},
{x:29.60207384,y:-95.26688836},
{x:36.10404629,y:-94.14694794},
{x:29.51459213,y:-97.45913141},
{x:33.8086244,y:-93.37656052},
{x:32.14727739,y:-95.13892405},
{x:31.11539944,y:-90.13811164},
{x:36.5341724,y:-86.03123699},
{x:30.80216273,y:-92.67132193},
{x:35.44018816,y:-95.97628758},
{x:35.4872665,y:-93.83273654},
{x:29.67270319,y:-95.28966621},
{x:38.59268034,y:-92.2254476},
{x:26.16426872,y:-98.00751106},
{x:37.54556728,y:-97.2447525},
{x:30.47634538,y:-97.97416747},
{x:35.33779635,y:-81.89877123},
{x:32.79607829,y:-95.44905461},
{x:36.78563534,y:-89.38515249},
{x:35.93777884,y:-91.54822939},
{x:36.12014825,y:-95.83269375},
{x:33.8761546,y:-91.48269162},
{x:33.54340332,y:-82.14094251},
{x:32.54447982,y:-97.31258422},
{x:35.94040542,y:-84.54372394},
{x:30.34817263,y:-97.5552579},
{x:35.74576748,y:-89.53305901},
{x:33.69953031,y:-117.9072858},
{x:35.56646427,y:-97.65911625},
{x:32.23327118,y:-95.22815913},
{x:39.18598016,y:-78.13199674},
{x:35.95873812,y:-96.00830779},
{x:33.39364641,y:-111.8444453},
{x:31.33276788,y:-94.75677699},
{x:33.36645584,y:-97.17689798},
{x:41.49459708,y:-90.40491633},
{x:39.6989755,y:-104.8261319},
{x:33.5535131,y:-112.1520518},
{x:32.49525165,y:-94.77444135},
{x:29.80794326,y:-94.98174862},
{x:35.07441168,y:-91.9019866},
{x:35.43949002,y:-97.37011618},
{x:36.35877895,y:-94.28064332},
{x:32.03836205,y:-93.69501876},
{x:35.19777511,y:-84.8428242},
{x:35.56539182,y:-97.28859254},
{x:26.20558506,y:-97.6741802},
{x:33.393845,y:-111.613131},
{x:35.45031827,y:-82.52601318},
{x:30.24384499,y:-81.59861461},
{x:39.30706633,y:-84.27809447},
{x:32.52074497,y:-84.89276223},
{x:29.69629891,y:-98.08927283},
{x:36.04710758,y:-96.00556455},
{x:32.766119,y:-97.21073},
{x:33.30730158,y:-111.7541176},
{x:33.59288291,y:-101.9388946},
{x:48.72527,y:-116.3019},
{x:36.07027049,y:-80.26814238},
{x:36.36440664,y:-95.82541399},
{x:35.79888477,y:-86.39927121},
{x:39.26880122,y:-94.45355326},
{x:35.82917664,y:-88.9052041},
{x:40.57235217,y:-98.39333206},
{x:38.86668846,y:-91.94426358},
{x:32.64363804,y:-96.77902159},
{x:30.32319269,y:-95.40119559},
{x:35.08481605,y:-85.20278402},
{x:38.96273751,y:-92.25066372},
{x:36.15155061,y:-88.80096138},
{x:34.80032492,y:-92.21438735},
{x:40.246092,y:-74.7631947},
{x:33.5915872,y:-96.19305702},
{x:39.76662428,y:-104.9039767},
{x:35.18225989,y:-78.98126798},
{x:34.49188009,y:-81.98017426},
{x:35.04678999,y:-97.93732435},
{x:32.65835794,y:-117.0905907},
{x:29.99584789,y:-91.80224947},
{x:33.12952379,y:-97.04607658},
{x:32.76869899,y:-96.89558119},
{x:29.0538603,y:-95.57137125},
{x:35.87572737,y:-86.3846612},
{x:38.8220184,y:-90.51786482},
{x:28.05396215,y:-82.43705931},
{x:34.36426216,y:-89.51835816},
{x:28.81344273,y:-96.97261423},
{x:30.01959378,y:-95.10936995},
{x:40.38459796,y:-105.0741267},
{x:39.05760448,y:-94.41526189},
{x:32.64700005,y:-96.86789544},
{x:29.73792347,y:-95.51455292},
{x:32.00784519,y:-97.09912531},
{x:35.46509611,y:-95.52481883},
{x:29.58956614,y:-98.35708604},
{x:36.99455718,y:-93.63197313},
{x:33.5805689,y:-86.92062289},
{x:37.13788513,y:-76.51874726},
{x:38.93511809,y:-94.50760395},
{x:27.9897687,y:-99.3700543},
{x:39.00314848,y:-94.12886248},
{x:34.02023368,y:-86.08969801},
{x:39.00482274,y:-93.95289405},
{x:35.00783678,y:-97.36586228},
{x:43.64540964,y:-116.6626935},
{x:32.88523423,y:-105.9609803},
{x:32.147175,y:-95.313217},
{x:32.89013976,y:-96.46851987},
{x:29.93675057,y:-95.25014386},
{x:33.60040523,y:-83.83486815},
{x:33.6239,y:-112.4286},
{x:36.07265303,y:-114.9536225},
{x:36.72445257,y:-97.07843122},
{x:32.50751903,y:-84.96618569},
{x:34.58600458,y:-94.22103658},
{x:33.12903645,y:-96.63074658},
{x:41.26152941,y:-95.90171009},
{x:39.60017459,y:-75.82648319},
{x:29.97949315,y:-95.21629707},
{x:30.45817565,y:-89.10000873},
{x:34.44613,y:-86.94332},
{x:30.40130388,y:-88.97725364},
{x:41.25158876,y:-81.36450873},
{x:33.93260314,y:-93.85148988},
{x:32.41550416,y:-90.13749406},
{x:37.86276947,y:-94.02413559},
{x:29.90918507,y:-90.35856576},
{x:29.85905951,y:-95.71999159},
{x:34.8283867,y:-102.4059811},
{x:30.46060453,y:-87.29693548},
{x:31.24791648,y:-90.46546988},
{x:32.84028732,y:-97.08306626},
{x:29.99877948,y:-95.25049523},
{x:36.12110341,y:-111.2270903},
{x:36.00464978,y:-84.01957379},
{x:33.9285916,y:-81.0775138},
{x:34.03025048,y:-117.7593928},
{x:35.27958376,y:-81.54096571},
{x:34.71053629,y:-85.28157507},
{x:34.95923301,y:-92.42338639},
{x:36.09983436,y:-115.0619388},
{x:32.43507873,y:-97.77060173},
{x:40.31246046,y:-111.705979},
{x:32.46707169,y:-97.38512993},
{x:33.76884,y:-116.95664},
{x:35.08095411,y:-87.43367302},
{x:35.42833426,y:-97.53061242},
{x:27.53621838,y:-81.80866778},
{x:38.56871898,y:-90.52444922},
{x:39.27832982,y:-93.97122302},
{x:35.24110548,y:-106.6996513},
{x:31.12450283,y:-99.3351777},
{x:32.83790303,y:-97.16098175},
{x:43.86156132,y:-116.4906603},
{x:38.55366025,y:-91.00609459},
{x:39.8547465,y:-104.9787962},
{x:36.22137249,y:-86.51454227},
{x:37.50685126,y:-77.60247267},
{x:30.36578226,y:-94.30838674},
{x:36.90503404,y:-111.4833006},
{x:31.34084316,y:-94.71822786},
{x:39.02988372,y:-94.69266415},
{x:35.5325,y:-95.1146},
{x:29.54195262,y:-95.54920614},
{x:32.56449214,y:-97.13488414},
{x:36.74226942,y:-91.87632591},
{x:30.08563828,y:-98.41917767},
{x:35.47524727,y:-86.08082727},
{x:37.85360411,y:-85.95152467},
{x:38.97107712,y:-95.27727574},
{x:34.86905607,y:-92.19689951},
{x:38.79253079,y:-97.61192679},
{x:38.85876707,y:-94.52272208},
{x:39.98089428,y:-82.83594},
{x:35.35026,y:-82.4301733},
{x:36.3925664,y:-100.8031943},
{x:30.48775062,y:-90.45732067},
{x:32.70584685,y:-96.58560969},
{x:33.59627548,y:-99.26093616},
{x:30.4364457,y:-91.05700896},
{x:32.52361719,y:-92.64519789},
{x:32.76517142,y:-96.80351152},
{x:33.99892127,y:-96.70765289},
{x:29.16365303,y:-82.165381},
{x:29.42414948,y:-98.52714077},
{x:29.39874438,y:-98.39303166},
{x:36.14861926,y:-95.85156065},
{x:35.86518692,y:-86.46475908},
{x:36.08885238,y:-95.81455171},
{x:41.86088682,y:-87.98297815},
{x:32.51801748,y:-92.15178207},
{x:30.06140356,y:-99.17031304},
{x:33.07002925,y:-97.06117876},
{x:36.04682523,y:-95.84835224},
{x:40.6269191,y:-103.2094422},
{x:31.8594154,y:-102.3956888},
{x:31.20064977,y:-82.32908473},
{x:32.51114488,y:-95.64282194},
{x:42.13842533,y:-72.50854149},
{x:30.417487,y:-99.6723292},
{x:35.22282148,y:-89.89367322},
{x:38.62053382,y:-94.67838273},
{x:37.0835217,y:-94.53175022},
{x:30.18110716,y:-93.58689801},
{x:34.90095,y:-94.10747},
{x:39.05222153,y:-94.45185366},
{x:30.41001381,y:-88.53966594},
{x:34.13802044,y:-87.98918172},
{x:38.20571554,y:-95.73798872},
{x:31.48687305,y:-97.12099537},
{x:35.50830743,y:-97.75511049},
{x:37.00126116,y:-91.70678506},
{x:33.37733101,y:-82.07324357},
{x:35.25578037,y:-88.99188705},
{x:36.53778867,y:-87.36677483},
{x:32.75848505,y:-96.8630413},
{x:30.37603502,y:-91.09253749},
{x:30.17167228,y:-82.63972745},
{x:32.3999618,y:-90.11050567},
{x:36.84446082,y:-81.49972814},
{x:36.23613033,y:-90.04411324},
{x:35.17194487,y:-92.72047208},
{x:30.41486919,y:-97.69609433},
{x:34.4365878,y:-89.90110763},
{x:29.63343153,y:-90.75857078},
{x:40.43143041,y:-122.2871165},
{x:36.16100373,y:-95.76101952},
{x:40.58281738,y:-83.08334428},
{x:40.90063415,y:-98.33910316},
{x:31.73383167,y:-95.62203683},
{x:34.79632116,y:-96.96684078},
{x:39.37144239,y:-101.0539741},
{x:41.11692464,y:-100.7647663},
{x:36.289402,y:-97.289947},
{x:32.57080273,y:-96.31292845},
{x:30.67101882,y:-88.13303502},
{x:39.58385733,y:-119.7389301},
{x:34.96303358,y:-89.93529707},
{x:35.91642199,y:-88.75300196},
{x:35.41203063,y:-99.4135805},
{x:33.44291267,y:-88.84919153},
{x:36.41430855,y:-82.9898928},
{x:32.6748,y:-96.9679},
{x:35.59503447,y:-97.65676127},
{x:28.08911596,y:-97.82245606},
{x:30.28126063,y:-91.90270745},
{x:33.45757992,y:-90.64216996},
{x:34.82330306,y:-89.97337373},
{x:30.38399845,y:-91.24577679},
{x:33.43857283,y:-104.5236616},
{x:29.18092507,y:-95.43342457},
{x:35.22324648,y:-93.15802016},
{x:36.18496573,y:-85.45780241},
{x:29.58015426,y:-95.39472163},
{x:29.4255656,y:-98.7946311},
{x:35.56133465,y:-77.40453557},
{x:34.57445169,y:-92.98759394},
{x:31.44434293,y:-100.4810092},
{x:27.79117446,y:-82.34013366},
{x:34.21322727,y:-86.15524247},
{x:36.85308447,y:-119.7291014},
{x:31.40326989,y:-103.4837718},
{x:34.27238601,y:-85.23824199},
{x:30.43729306,y:-90.45294367},
{x:36.21459927,y:-115.9928249},
{x:34.98977385,y:-78.96713421},
{x:30.46252154,y:-91.10184864},
{x:32.83867931,y:-109.737147},
{x:31.10231758,y:-97.74976351},
{x:41.87063754,y:-71.52432338},
{x:31.62581682,y:-91.8187952},
{x:32.69310548,y:-97.06140206},
{x:29.72999687,y:-99.07779249},
{x:34.36233803,y:-98.31194915},
{x:35.14534949,y:-97.64368667},
{x:30.08472406,y:-94.14360322},
{x:29.43606342,y:-98.70522561},
{x:33.78518,y:-97.729981},
{x:33.62976458,y:-85.80449896},
{x:36.3060344,y:-96.46611258},
{x:39.11885757,y:-100.8125222},
{x:31.08495797,y:-97.4591884},
{x:35.05108737,y:-89.81460706},
{x:32.76366036,y:-96.58182442},
{x:32.16735738,y:-97.13388205},
{x:34.48874203,y:-84.94592295},
{x:35.18889559,y:-106.6151248},
{x:32.57316112,y:-97.08768503},
{x:35.016002,y:-99.094908},
{x:29.67577916,y:-95.58844096},
{x:32.5955308,y:-93.33850064},
{x:34.15969655,y:-79.76618574},
{x:33.23660003,y:-97.10474655},
{x:27.47901024,y:-99.47313031},
{x:35.62302752,y:-95.96147038},
{x:35.14967789,y:-89.93422108},
{x:41.13336489,y:-96.24750584},
{x:32.80827868,y:-97.20678985},
{x:31.17781464,y:-85.40105895},
{x:34.94117456,y:-82.25752904},
{x:32.07082013,y:-84.21660886},
{x:32.49154928,y:-92.08464503},
{x:30.43354078,y:-91.00821181},
{x:34.39622504,y:-103.1697442},
{x:39.63466734,y:-86.083412},
{x:41.708,y:-71.491},
{x:36.8380294,y:-119.6812066},
{x:26.1502345,y:-97.9042335},
{x:36.42679895,y:-84.92894523},
{x:35.84924047,y:-97.93223619},
{x:33.14530648,y:-86.748054},
{x:30.10557241,y:-97.32448502},
{x:36.24023452,y:-115.2069645},
{x:36.17478367,y:-86.30290373},
{x:32.75748355,y:-97.70322316},
{x:29.9483,y:-89.9613},
{x:35.59519788,y:-97.43505657},
{x:32.39315849,y:-97.33199537},
{x:32.61741941,y:-96.85656645},
{x:36.60643444,y:-88.31467621},
{x:32.86529823,y:-97.42663846},
{x:37.33394509,y:-89.57431467},
{x:40.44975557,y:-109.5516799},
{x:34.6364114,y:-97.1687304},
{x:31.60356586,y:-81.9024881},
{x:41.30888678,y:-105.560674},
{x:36.92899077,y:-93.91873377},
{x:30.2307894,y:-92.06302778},
{x:35.98812917,y:-96.59136102},
{x:33.580176,y:-86.4736097},
{x:40.81167189,y:-91.1406124},
{x:33.04348221,y:-96.76956163},
{x:42.55789343,y:-83.44063506},
{x:37.31630566,y:-87.5014658},
{x:30.43831408,y:-97.7588743},
{x:40.03144683,y:-82.90967703},
{x:29.94111684,y:-93.99793916},
{x:32.47833392,y:-100.010197},
{x:29.69230514,y:-95.16830564},
{x:37.65095176,y:-97.36896627},
{x:31.55195447,y:-90.10786973},
{x:30.22516464,y:-85.59153199},
{x:36.28032339,y:-98.48035313},
{x:37.0801822,y:-97.03967281},
{x:35.37966546,y:-119.017957},
{x:38.05106095,y:-103.7134065},
{x:32.4544221,y:-93.7487442},
{x:38.68694618,y:-89.97419582},
{x:29.91377664,y:-95.48313663},
{x:34.66839469,y:-106.7749174},
{x:30.44637513,y:-89.03015508},
{x:36.69475132,y:-85.13481395},
{x:39.28523211,y:-94.90337957},
{x:30.76280956,y:-88.22697536},
{x:33.02299933,y:-96.45473734},
{x:41.74327971,y:-93.59950438},
{x:36.77527859,y:-108.6977082},
{x:34.8462476,y:-95.56147055},
{x:37.6084617,y:-97.35277243},
{x:32.59289581,y:-97.14976072},
{x:32.23314139,y:-101.4623465},
{x:30.28586861,y:-89.74082351},
{x:34.80136163,y:-91.89019125},
{x:32.53555064,y:-94.34942387},
{x:33.45653417,y:-82.20095364},
{x:30.17738108,y:-95.47681467},
{x:29.65052486,y:-95.64972997},
{x:36.05631148,y:-94.17928465},
{x:34.29197795,y:-99.75552678},
{x:33.85685746,y:-79.08875343},
{x:35.19605233,y:-87.03185544},
{x:32.00125255,y:-102.11935},
{x:32.87774127,y:-96.70089555},
{x:35.97399489,y:-86.83178008},
{x:36.00173565,y:-83.92637239},
{x:35.25027,y:-91.71424},
{x:33.48078252,y:-112.2046472},
{x:37.14267532,y:-92.26339147},
{x:45.53731328,y:-122.9014725},
{x:42.02145027,y:-97.4360215},
{x:35.45015869,y:-97.35445887},
{x:35.13015778,y:-106.5307669},
{x:30.75991007,y:-98.68074552},
{x:32.59445162,y:-83.62507931},
{x:28.18917991,y:-82.43771755},
{x:30.88334984,y:-96.59704663},
{x:30.44442476,y:-88.93454745},
{x:31.71886341,y:-96.17468641},
{x:30.60074047,y:-87.15699122},
{x:38.90926,y:-92.32982},
{x:35.35210656,y:-97.53055977},
{x:29.14206705,y:-98.15298899},
{x:37.75780795,y:-121.4608634},
{x:32.82636377,y:-96.87065945},
{x:33.50756036,y:-96.61673831},
{x:36.07864076,y:-86.95297436},
{x:33.60601365,y:-86.68930985},
{x:37.72247505,y:-97.44320244},
{x:29.97683977,y:-95.48946329},
{x:35.66649271,y:-97.53225312},
{x:37.63706397,y:-93.10491443},
{x:35.49358222,y:-97.51066804},
{x:34.55957374,y:-92.33598224},
{x:33.16779479,y:-86.25812799},
{x:30.86565329,y:-93.28579671},
{x:29.51335502,y:-98.53486486},
{x:42.86360318,y:-85.68453223},
{x:34.2324591,y:-96.67399184},
{x:40.8616,y:-73.1328},
{x:30.21178099,y:-93.37680685},
{x:30.13387039,y:-95.65606133},
{x:40.79881886,y:-96.74893248},
{x:32.56299119,y:-97.32555128},
{x:32.93179172,y:-105.9621234},
{x:37.11038859,y:-87.90303156},
{x:41.45550376,y:-74.39057149},
{x:36.04034608,y:-94.25082611},
{x:38.75204915,y:-94.82605644},
{x:30.0981495,y:-81.71228126},
{x:33.56807906,y:-84.01628204},
{x:37.95558291,y:-87.52417155},
{x:32.09850875,y:-98.33691315},
{x:32.3241328,y:-90.87600678},
{x:33.9827708,y:-81.1004444},
{x:36.03388483,y:-79.86680862},
{x:37.99545669,y:-84.21596378},
{x:34.885017,y:-85.26935336},
{x:34.21461695,y:-82.15461038},
{x:34.19428969,y:-84.78988611},
{x:33.37873626,y:-111.6846009},
{x:33.62879209,y:-96.42049479},
{x:34.54475294,y:-83.5453761},
{x:32.791074,y:-95.634297},
{x:28.82260206,y:-81.70548119},
{x:32.87164431,y:-95.76467335},
{x:33.6022136,y:-91.38248232},
{x:31.11217306,y:-92.06400879},
{x:38.9176688,y:-104.7861989},
{x:30.42719247,y:-89.08744812},
{x:36.84956713,y:-76.02565684},
{x:32.6753139,y:-96.6025334},
{x:33.68242,y:-117.1759334},
{x:32.90827574,y:-96.55184453},
{x:35.98694435,y:-78.53334995},
{x:32.70797062,y:-96.0184794},
{x:33.8894457,y:-84.06151414},
{x:29.01225008,y:-95.42080544},
{x:29.76535528,y:-94.97700915},
{x:32.28225983,y:-95.33161864},
{x:39.91361845,y:-79.75383013},
{x:32.35875691,y:-86.28271206},
{x:29.83194,y:-95.79091},
{x:38.14905544,y:-90.5772315},
{x:30.62922744,y:-96.33598655},
{x:34.70748661,y:-86.78740456},
{x:37.61908418,y:-122.0354019},
{x:35.21285914,y:-101.9030422},
{x:33.15465954,y:-96.9459115},
{x:38.88050823,y:-94.76172969},
{x:39.09617839,y:-84.27818164},
{x:31.57960482,y:-90.46637714},
{x:33.38572252,y:-117.5803671},
{x:46.73852665,y:-117.0019625},
{x:34.882052,y:-83.947802},
{x:43.48251973,y:-111.9782366},
{x:32.96909673,y:-97.41737813},
{x:35.35616823,y:-96.92930326},
{x:34.94471672,y:-92.0598802},
{x:32.94066787,y:-97.07956848},
{x:35.26115925,y:-96.93579718},
{x:32.46090294,y:-86.41955229},
{x:39.891324,y:-75.08911624},
{x:29.6849,y:-98.4744},
{x:35.9158897,y:-81.53462112},
{x:34.2903628,y:-81.6020716},
{x:30.13922631,y:-94.39956844},
{x:36.80193587,y:-89.96988282},
{x:35.98499032,y:-94.5831695},
{x:30.38768883,y:-97.65095562},
{x:35.17310454,y:-89.86798435},
{x:32.32363885,y:-102.5476326},
{x:36.09304647,y:-96.01121329},
{x:29.57546683,y:-95.10713853},
{x:34.20134772,y:-97.14227669},
{x:32.54425832,y:-92.01533231},
{x:37.48508148,y:-86.27283052},
{x:41.58574159,y:-87.7169399},
{x:35.16297465,y:-83.39058638},
{x:41.7295614,y:-71.16773307},
{x:36.02226888,y:-95.97260338},
{x:31.140379,y:-87.06825},
{x:30.15552832,y:-95.21684222},
{x:38.92942328,y:-97.21354768},
{x:32.78807947,y:-108.2524417},
{x:29.62924185,y:-95.14322253},
{x:35.89472592,y:-84.1383264},
{x:32.65739491,y:-97.13399873},
{x:32.64692414,y:-97.18287485},
{x:33.69559268,y:-84.26211923},
{x:31.97946715,y:-89.28879802},
{x:33.40171219,y:-104.5232593},
{x:25.9550918,y:-80.244697},
{x:27.4898,y:-81.4824},
{x:33.16828441,y:-96.66366801},
{x:32.8964844,y:-97.25777731},
{x:39.14267925,y:-94.7077965},
{x:39.87870756,y:-105.0977279},
{x:46.678063,y:-122.974308},
{x:33.50075888,y:-101.9217774},
{x:28.66693357,y:-81.22504137},
{x:32.9352415,y:-96.3808557},
{x:29.65968293,y:-98.43799084},
{x:33.71085994,y:-78.89810823},
{x:37.071679,y:-88.700955},
{x:38.71759385,y:-104.6975754},
{x:34.98778313,y:-81.20180861},
{x:29.62427448,y:-98.49535458},
{x:31.3941478,y:-102.3488928},
{x:39.67529668,y:-104.8975642},
{x:29.97713957,y:-90.19393847},
{x:38.39177,y:-86.9406},
{x:38.9087133,y:-94.66829883},
{x:39.49752963,y:-84.33625817},
{x:38.03786503,y:-84.51609753},
{x:32.90653462,y:-97.54430298},
{x:31.10516528,y:-91.29352748},
{x:35.18962554,y:-101.8171679},
{x:30.51798065,y:-87.88555831},
{x:32.93784853,y:-96.72271733},
{x:32.71994722,y:-96.88032948},
{x:30.54325241,y:-87.70899319},
{x:28.33374231,y:-98.11915624},
{x:32.75632535,y:-97.42819683},
{x:30.83504361,y:-89.53090134},
{x:35.78516924,y:-95.24071322},
{x:35.98736523,y:-106.05814},
{x:30.58384974,y:-98.2725817},
{x:41.5782329,y:-109.2501464},
{x:32.458313,y:-96.835379},
{x:35.59115866,y:-84.47797403},
{x:43.08664378,y:-77.61425481},
{x:31.63484957,y:-89.55856617},
{x:34.30497494,y:-88.78070936},
{x:32.99652231,y:-96.85336706},
{x:35.21785402,y:-97.40712807},
{x:29.62610245,y:-95.5821886},
{x:34.30677837,y:-92.40770437},
{x:38.68494854,y:-85.78270807},
{x:28.82271251,y:-97.00016655},
{x:36.41924714,y:-97.88897753},
{x:32.17920966,y:-83.18035752},
{x:36.19913789,y:-89.66666007},
{x:39.11683867,y:-94.93977338},
{x:38.62165488,y:-83.78511183},
{x:36.69559738,y:-95.62913244},
{x:33.60944357,y:-92.06546172},
{x:35.22059846,y:-89.92984884},
{x:32.50362506,y:-94.70969148},
{x:31.19789187,y:-81.48555815},
{x:33.22913027,y:-97.1524074},
{x:28.03542347,y:-97.04349661},
{x:38.89712664,y:-104.8431234},
{x:35.65948981,y:-88.82865452},
{x:31.33341862,y:-92.39774667},
{x:38.94852512,y:-92.74152656},
{x:34.611438,y:-118.1473401},
{x:39.5756,y:-76.9834},
{x:28.4629061,y:-81.45212308},
{x:33.18850674,y:-101.3786433},
{x:35.87939485,y:-106.3001919},
{x:32.50484648,y:-94.57858175},
{x:37.75308332,y:-97.26187304},
{x:38.64889218,y:-94.35764686},
{x:38.55236508,y:-92.19534651},
{x:39.83897129,y:-99.89111111},
{x:29.69652832,y:-98.1157925},
{x:33.20604019,y:-96.73217565},
{x:35.2674134,y:-119.03856},
{x:41.653679,y:-88.080031},
{x:40.69463936,y:-111.9395551},
{x:32.72116896,y:-97.06107172},
{x:31.67739755,y:-106.3153169},
{x:36.81232347,y:-76.09721885},
{x:31.758689,y:-106.2723613},
{x:36.67485783,y:-93.87005612},
{x:29.76633568,y:-96.15284667},
{x:32.18906303,y:-95.435109},
{x:30.64823775,y:-91.14855074},
{x:35.18968464,y:-84.8797562},
{x:43.97783879,y:-75.94522746},
{x:35.11303374,y:-85.29280432},
{x:39.55600407,y:-119.7935741},
{x:34.65953049,y:-86.48655698},
{x:31.78914151,y:-106.3974474},
{x:31.86328252,y:-106.5584417},
{x:32.00310878,y:-81.28058644},
{x:31.09692626,y:-97.72104536},
{x:32.32776498,y:-90.25128745},
{x:29.9107335,y:-91.6633415},
{x:36.95827907,y:-82.60475145},
{x:35.22258895,y:-101.8216644},
{x:38.95752323,y:-84.62754055},
{x:32.5602664,y:-96.8232652},
{x:29.15800913,y:-82.08855294},
{x:30.20629779,y:-95.46315014},
{x:34.61619617,y:-86.56797141},
{x:30.91364845,y:-88.59643891},
{x:34.75269153,y:-86.74384117},
{x:33.68109538,y:-112.2390935},
{x:36.44035766,y:-94.23771265},
{x:31.5988,y:-96.1568},
{x:41.59507172,y:-88.02521423},
{x:35.39389323,y:-118.9517185},
{x:38.72727929,y:-90.2735449},
{x:29.56484991,y:-98.5872075},
{x:38.63719192,y:-95.82052097},
{x:36.29609967,y:-119.3320437},
{x:36.168187,y:-98.953872},
{x:36.33423721,y:-119.2782346},
{x:32.45248223,y:-97.5392713},
{x:34.81371953,y:-106.7524381},
{x:36.243873,y:-85.9400103},
{x:39.24648555,y:-94.66644422},
{x:32.7064649,y:-97.3462512},
{x:29.37487937,y:-100.9016587},
{x:33.75105207,y:-88.11059296},
{x:39.219176,y:-85.88718},
{x:32.24368607,y:-96.49722381},
{x:47.16804458,y:-122.1639159},
{x:35.99635177,y:-94.20111179},
{x:34.61087958,y:-92.53576937},
{x:34.12332717,y:-93.05756338},
{x:34.1653395,y:-81.34934084},
{x:32.96695115,y:-96.33451067},
{x:35.17613245,y:-101.8489011},
{x:32.40872223,y:-88.70484442},
{x:42.59461764,y:-82.90108055},
{x:36.03225363,y:-86.78443372},
{x:34.71205197,y:-86.61019608},
{x:34.71700398,y:-77.32208245},
{x:37.22539293,y:-87.16860183},
{x:34.9042954,y:-91.1990131},
{x:35.18343821,y:-97.50160602},
{x:36.85059085,y:-86.89239532},
{x:28.5579131,y:-80.80836207},
{x:37.13460123,y:-80.41277005},
{x:33.5045,y:-96.4016},
{x:32.36857364,y:-95.32835825},
{x:37.1078,y:-93.0569},
{x:36.18833866,y:-86.41089723},
{x:37.36012733,y:-77.41593228},
{x:39.19352821,y:-84.2396976},
{x:39.92806,y:-104.90563},
{x:32.3019336,y:-95.61114371},
{x:35.68126107,y:-97.48282537},
{x:34.43421973,y:-100.2267225},
{x:35.6647516,y:-101.3986708},
{x:33.00419746,y:-94.35965985},
{x:30.45951771,y:-84.28161621},
{x:36.95349611,y:-76.53060951},
{x:35.57098471,y:-89.6454522},
{x:42.34394098,y:-83.38584021},
{x:29.29071506,y:-94.82471024},
{x:33.13264424,y:-96.73230764},
{x:32.63432901,y:-97.37301506},
{x:35.66600661,y:-97.1938844},
{x:32.87496948,y:-96.61767081},
{x:35.86156569,y:-101.9728513},
{x:27.85610175,y:-97.63785303},
{x:33.3678489,y:-111.7893262},
{x:39.39675751,y:-76.5645},
{x:30.08356138,y:-92.6717417},
{x:33.523771,y:-80.8852305},
{x:27.81962,y:-97.07291},
{x:34.00651564,y:-83.91458197},
{x:32.67343427,y:-95.72151921},
{x:34.75977859,y:-84.99439918},
{x:34.72298844,y:-100.533702},
{x:34.99144183,y:-90.0105301},
{x:35.83617643,y:-90.72537728},
{x:36.16023497,y:-86.51485093},
{x:37.49781227,y:-91.86214641},
{x:35.35819949,y:-86.21754751},
{x:34.83734067,y:-97.60917217},
{x:27.75422838,y:-98.05913553},
{x:32.1560758,y:-94.7830157},
{x:40.6722172,y:-74.2002084},
{x:36.32316674,y:-86.70979723},
{x:32.35040875,y:-111.0461991},
{x:36.1347908,y:-95.88624405},
{x:29.52748509,y:-96.06959828},
{x:33.27848508,y:-96.56833157},
{x:31.62300429,y:-84.21572467},
{x:33.05215362,y:-96.56729758},
{x:36.17018722,y:-94.13962082},
{x:34.65692267,y:-93.06081221},
{x:41.29276769,y:-96.14151166},
{x:32.18877321,y:-90.2636624},
{x:32.39865588,y:-96.84365486},
{x:32.67091356,y:-114.5992597},
{x:39.0444,y:-95.72533},
{x:29.68889975,y:-82.35419825},
{x:35.87255979,y:-90.17682061},
{x:38.92757442,y:-95.26499391},
{x:36.49028656,y:-86.87804194},
{x:34.6637485,y:-86.03562884},
{x:31.48738883,y:-82.83964992},
{x:40.30664335,y:-79.57610697},
{x:35.84767845,y:-78.60122256},
{x:39.43738931,y:-76.62436679},
{x:32.83739805,y:-96.62887037},
{x:33.27543109,y:-111.8422271},
{x:31.45891259,y:-100.4468125},
{x:32.5007568,y:-87.8416701},
{x:35.16045953,y:-88.58230233},
{x:28.1478,y:-81.4444},
{x:35.32601514,y:-94.30342466},
{x:35.19555469,y:-101.8845},
{x:32.70060942,y:-100.9186956},
{x:36.0467635,y:-95.95838652},
{x:35.93183143,y:-86.82488412},
{x:36.06769701,y:-86.63219289},
{x:36.41336115,y:-86.79089598},
{x:36.01839326,y:-86.57178414},
{x:39.8183002,y:-88.8800058},
{x:35.3614472,y:-83.2013445},
{x:32.42006186,y:-99.74875286},
{x:33.49612115,y:-88.38224977},
{x:32.39873216,y:-93.76291864},
{x:35.65316528,y:-97.49492415},
{x:33.30582874,y:-111.9907149},
{x:42.36183013,y:-122.8601034},
{x:30.10182967,y:-83.58244747},
{x:35.61000248,y:-87.83695534},
{x:40.54624231,y:-74.33003068},
{x:35.93151435,y:-86.5332368},
{x:30.0116336,y:-97.890094},
{x:37.74204627,y:-88.96897026},
{x:37.05264495,y:-94.47770522},
{x:34.97251608,y:-81.88506515},
{x:36.20265166,y:-83.28335434},
{x:37.98421496,y:-87.47461438},
{x:30.19003109,y:-85.66362307},
{x:35.20436124,y:-89.83055279},
{x:33.70386006,y:-117.9895804},
{x:35.93394475,y:-89.9104558},
{x:29.79368593,y:-95.71952641},
{x:33.8558,y:-89.2978},
{x:30.570932,y:-96.3095023},
{x:36.23741234,y:-93.10756969},
{x:32.84862117,y:-97.19533088},
{x:35.83049229,y:-96.39267907},
{x:35.5963331,y:-97.62025639},
{x:32.37630315,y:-98.97210449},
{x:35.2311998,y:-89.80907097},
{x:37.66507256,y:-97.24758827},
{x:32.95612085,y:-96.81906496},
{x:30.37093489,y:-89.09334429},
{x:32.51499233,y:-86.37717449},
{x:34.28162082,y:-86.22205588},
{x:38.79247756,y:-94.26145815},
{x:31.8784186,y:-102.3284733},
{x:38.74953161,y:-108.0512358},
{x:35.33778322,y:-101.3828069},
{x:34.18420013,y:-101.7087865},
{x:42.575046,y:-114.297017},
{x:39.79805805,y:-94.81449143},
{x:34.58027964,y:-92.57298544},
{x:32.54729815,y:-93.70862737},
{x:31.44116898,y:-97.4157212},
{x:39.3633203,y:-85.5192377},
{x:37.7903533,y:-81.20644},
{x:34.04956,y:-81.1047599},
{x:35.62466061,y:-97.47867332},
{x:34.86664617,y:-82.35736728},
{x:32.19135783,y:-110.8221904},
{x:35.50049265,y:-119.2722074},
{x:40.72314794,y:-73.57242875},
{x:33.09468199,y:-98.58918005},
{x:42.5643199,y:-83.02872688},
{x:29.4393805,y:-98.46171633},
{x:35.5507961,y:-87.54218795},
{x:61.109051,y:-149.860826},
{x:34.38021848,y:-96.13020974},
{x:39.05111549,y:-94.59649019},
{x:30.52596377,y:-97.68631116},
{x:30.34918197,y:-91.02763676},
{x:32.699779,y:-97.958993},
{x:30.1655189,y:-85.75577036},
{x:34.69270774,y:-79.9062641},
{x:30.18047062,y:-96.92555177},
{x:36.86214131,y:-94.38274547},
{x:36.0578852,y:-115.1234867},
{x:42.63028183,y:-73.77561871},
{x:34.48035163,y:-93.06012355},
{x:39.63658908,y:-104.894758},
{x:34.84816634,y:-92.40604549},
{x:30.2769,y:-97.6369},
{x:33.11699,y:-96.09647},
{x:36.24016929,y:-115.1200507},
{x:36.06134526,y:-95.81641969},
{x:30.47661136,y:-90.86271644},
{x:32.93482978,y:-97.23722891},
{x:38.800415,y:-104.7582053},
{x:38.93453305,y:-95.09514169},
{x:42.42207266,y:-123.334605},
{x:35.97638031,y:-94.31398078},
{x:36.0661283,y:-87.38090806},
{x:30.6048612,y:-87.06540987},
{x:36.37380256,y:-84.13037449},
{x:30.38743987,y:-87.079369},
{x:37.72245611,y:-97.3019077},
{x:29.86700525,y:-81.32994846},
{x:31.82884362,y:-81.59835779},
{x:36.87291023,y:-76.13364271},
{x:33.49956275,y:-81.95925328},
{x:31.0626372,y:-97.3673442},
{x:36.0789149,y:-94.2060877},
{x:36.55192018,y:-87.39802592},
{x:32.67901865,y:-93.74480351},
{x:29.39505917,y:-94.94356602},
{x:32.855032,y:-96.56097},
{x:38.03208601,y:-97.33794935},
{x:32.67280569,y:-97.04489306},
{x:35.34504293,y:-80.17146216},
{x:35.01776966,y:-85.69888004},
{x:38.78280586,y:-121.2854831},
{x:27.7579478,y:-97.41299406},
{x:40.74011813,y:-89.63225507},
{x:40.8754517,y:-81.3651721},
{x:35.72305,y:-97.9772},
{x:32.63306861,y:-96.94379103},
{x:35.22234483,y:-114.2092585},
{x:33.84946991,y:-83.91933024},
{x:37.17853286,y:-94.325179},
{x:34.09735317,y:-96.77443184},
{x:37.14766314,y:-88.71391565},
{x:37.15944342,y:-93.29301976},
{x:35.84153826,y:-98.41420754},
{x:35.88015331,y:-97.39753246},
{x:37.89825137,y:-88.93379376},
{x:38.44114925,y:-91.00974809},
{x:36.07310179,y:-90.95547729},
{x:33.63812137,y:-112.0638822},
{x:37.68013002,y:-96.98593073},
{x:37.68077004,y:-97.33515349},
{x:32.71002317,y:-97.00608015},
{x:41.45081881,y:-96.45893768},
{x:34.52686922,y:-82.63602868},
{x:29.98843981,y:-91.82762187},
{x:45.52038142,y:-123.0499139},
{x:38.81137894,y:-94.91507292},
{x:35.51574274,y:-98.97814081},
{x:32.6348315,y:-97.3231481},
{x:29.68975251,y:-91.30881689},
{x:32.77202456,y:-89.11293783},
{x:33.37869685,y:-111.9796433},
{x:35.94970471,y:-78.53002601},
{x:33.07276804,y:-97.11130254},
{x:33.24608791,y:-95.91265575},
{x:36.71164388,y:-107.9944401},
{x:33.36227219,y:-111.8411274},
{x:38.7675673,y:-90.7845448},
{x:41.29129249,y:-96.05250791},
{x:29.56969773,y:-95.56712195},
{x:36.44102624,y:-95.70943594},
{x:30.8475265,y:-90.14501899},
{x:31.21813934,y:-85.35996348},
{x:33.46617492,y:-112.0632251},
{x:40.99929802,y:-75.26174927},
{x:31.74332677,y:-98.94434077},
{x:35.95868689,y:-89.96547989},
{x:30.06082776,y:-95.91154911},
{x:36.96133078,y:-92.67430081},
{x:34.77336679,y:-92.19851822},
{x:33.74212003,y:-87.03804062},
{x:29.03013626,y:-95.39684057},
{x:32.48172782,y:-96.98971868},
{x:35.13576918,y:-86.56642512},
{x:34.45613767,y:-81.87937685},
{x:33.93880498,y:-96.42262995},
{x:32.73594961,y:-101.9530238},
{x:34.37679593,y:-84.93091395},
{x:29.604339,y:-98.225779},
{x:38.89581665,y:-104.7482946},
{x:38.76227194,y:-90.53168491},
{x:33.29252803,y:-96.98606149},
{x:30.25468038,y:-93.25865664},
{x:40.75706993,y:-96.65448144},
{x:31.5501601,y:-97.12631553},
{x:34.75024989,y:-92.4095665},
{x:29.89374573,y:-90.01858034},
{x:32.40504478,y:-99.76456784},
{x:35.39218571,y:-97.72045837},
{x:31.81191237,y:-106.4450137},
{x:35.15295864,y:-85.19976318},
{x:39.11835988,y:-94.37716126},
{x:34.7598391,y:-92.37911269},
{x:32.3225746,y:-111.0359301},
{x:32.45792443,y:-93.66113351},
{x:31.72126358,y:-99.96155646},
{x:35.38036651,y:-92.22312883},
{x:36.25937094,y:-93.12758982},
{x:32.80080063,y:-96.9751811},
{x:39.5069,y:-87.386},
{x:29.62801431,y:-95.23993738},
{x:34.07539689,y:-84.65105161},
{x:35.96331022,y:-86.7998482},
{x:36.35371323,y:-82.38244041},
{x:29.34744214,y:-99.13645484},
{x:35.86495877,y:-92.10994967},
{x:32.04841462,y:-81.16356902},
{x:30.44649556,y:-97.64695712},
{x:33.07183198,y:-97.03110115},
{x:33.2256323,y:-98.16107154},
{x:35.80635842,y:-90.70216686},
{x:31.58748794,y:-102.8912611},
{x:37.34416601,y:-108.5955358},
{x:33.42126942,y:-84.15374281},
{x:31.85459827,y:-103.0917455},
{x:39.25080596,y:-76.64568499},
{x:40.7345901,y:-74.18753028},
{x:35.24131469,y:-90.78353562},
{x:36.17857699,y:-82.7857402},
{x:29.48545779,y:-98.66936624},
{x:34.24175012,y:-77.89318845},
{x:26.68158414,y:-81.89863846},
{x:37.61080014,y:-93.40865181},
{x:39.19059208,y:-119.7460773},
{x:32.5903434,y:-96.67973536},
{x:35.75256274,y:-96.66234031},
{x:29.81908463,y:-95.46582833},
{x:35.96274802,y:-77.83250884},
{x:33.91541319,y:-102.3265094},
{x:32.82964743,y:-117.1411223},
{x:36.03231003,y:-95.92095897},
{x:30.4846,y:-90.3367},
{x:26.28800076,y:-97.99317814},
{x:41.67932866,y:-83.62355232},
{x:34.77516687,y:-77.39097096},
{x:29.62168299,y:-95.74302218},
{x:33.15775383,y:-96.3713126},
{x:44.0888,y:-103.1892},
{x:38.31103016,y:-88.96313445},
{x:39.59449703,y:-104.8585249},
{x:35.65105654,y:-97.46100962},
{x:29.91410204,y:-95.58513768},
{x:32.44412668,y:-91.49179101},
{x:35.62861639,y:-87.02817008},
{x:37.21700747,y:-86.69520736},
{x:34.23225944,y:-95.61333843},
{x:38.40449232,y:-96.19429513},
{x:34.28111228,y:-79.85586588},
{x:30.23079271,y:-92.38329694},
{x:28.95663177,y:-98.49095643},
{x:31.53286683,y:-87.89118185},
{x:30.93708275,y:-90.52070805},
{x:35.93352335,y:-83.99807386},
{x:37.01670493,y:-94.73545276},
{x:35.48639053,y:-96.68896957},
{x:32.99614326,y:-93.4634661},
{x:33.11119744,y:-95.59632316},
{x:29.93067456,y:-95.35495788},
{x:35.8136918,y:-90.6686384},
{x:33.65320443,y:-112.2389346},
{x:32.952197,y:-99.802666},
{x:32.80658354,y:-91.17318884},
{x:35.9988118,y:-96.09951616},
{x:33.43694806,y:-86.08766245},
{x:34.74863109,y:-77.41402455},
{x:31.68466966,y:-96.47926077},
{x:33.58023938,y:-101.8178713},
{x:33.41859646,y:-94.1008468},
{x:33.8490337,y:-86.82315003},
{x:27.70084653,y:-97.41241806},
{x:26.19127623,y:-98.20473858},
{x:32.04297639,y:-81.00822352},
{x:36.05808588,y:-91.89530143},
{x:34.4187736,y:-103.1969988},
{x:31.98215853,y:-98.03258501},
{x:35.13716195,y:-106.5857097},
{x:34.55407,y:-90.65991},
{x:29.5077983,y:-98.30826312},
{x:36.03142404,y:-95.88529614},
{x:30.78096276,y:-85.24414704},
{x:37.9211022,y:-95.4083336},
{x:36.61749005,y:-90.8219006},
{x:34.55588475,y:-102.3131193},
{x:36.89249908,y:-94.87713174},
{x:33.46116494,y:-94.02773515},
{x:31.86009523,y:-102.3484825},
{x:37.68453743,y:-93.80192},
{x:40.66523724,y:-73.72726005},
{x:39.9444571,y:-94.8205345},
{x:33.67391887,y:-96.90650835},
{x:32.2723384,y:-90.13680675},
{x:30.15057544,y:-85.59068441},
{x:29.40185,y:-95.83556},
{x:32.97687343,y:-96.59368717},
{x:35.4919116,y:-97.4771592},
{x:29.49314019,y:-98.43666583},
{x:39.778772,y:-93.071479},
{x:33.62467718,y:-97.1472998},
{x:35.02095357,y:-85.24037451},
{x:33.02090542,y:-96.92888081},
{x:30.41947907,y:-86.65235572},
{x:36.69656947,y:-121.668216},
{x:34.25576009,y:-89.01860253},
{x:33.26048619,y:-97.22191081},
{x:32.5016446,y:-92.15673812},
{x:30.30116107,y:-81.76482354},
{x:29.46686749,y:-98.60488459},
{x:29.5525137,y:-98.26854364},
{x:29.8528391,y:-94.86273631},
{x:29.95662884,y:-95.58468287},
{x:32.83932722,y:-96.59497597},
{x:36.57677312,y:-94.76967434},
{x:32.93099808,y:-97.28707373},
{x:39.43999228,y:-94.22822759},
{x:30.23533363,y:-85.64916193},
{x:34.49229662,y:-91.55241593},
{x:31.50022532,y:-97.16371449},
{x:32.7630032,y:-97.8348052},
{x:32.6481,y:-97.0559},
{x:33.99122239,y:-89.3511158},
{x:30.24667409,y:-93.01418694},
{x:37.96424295,y:-87.49343191},
{x:33.74787851,y:-90.71716905},
{x:36.04695635,y:-89.40727472},
{x:33.48106199,y:-112.0099282},
{x:32.87590087,y:-96.76244505},
{x:33.45119096,y:-86.98080361},
{x:30.78551147,y:-91.36978403},
{x:36.03651139,y:-114.9774438},
{x:32.54434,y:-95.86406},
{x:38.0143,y:-97.9404},
{x:24.5614,y:-81.7781},
{x:32.85047026,y:-97.2392471},
{x:38.87023028,y:-94.83492315},
{x:33.5364126,y:-112.3555946},
{x:32.90714515,y:-96.41703755},
{x:37.943906,y:-75.540683},
{x:31.1791155,y:-83.77851679},
{x:32.47343513,y:-93.80081222},
{x:28.96313241,y:-81.96736954},
{x:36.66870306,y:-87.44518922},
{x:40.39421912,y:-95.83932307},
{x:40.56571808,y:-111.8901138},
{x:30.64041372,y:-88.19238492},
{x:41.81608154,y:-88.28045056},
{x:37.73724836,y:-84.27861952},
{x:33.01310585,y:-97.06852064},
{x:35.98590678,y:-97.03334445},
{x:40.72171966,y:-99.08439767},
{x:35.40519439,y:-94.58291604},
{x:30.72722591,y:-90.51824838},
{x:39.65401534,y:-79.94173632},
{x:35.874556,y:-80.07642948},
{x:41.4269,y:-87.336113},
{x:36.01779755,y:-95.79619183},
{x:27.88804816,y:-97.31533751},
{x:30.62502449,y:-81.54050857},
{x:33.76488192,y:-79.01934916},
{x:26.16457918,y:-97.67970153},
{x:35.31713114,y:-119.0255001},
{x:35.53669201,y:-108.7595522},
{x:31.53065049,y:-97.19182044},
{x:37.74279172,y:-87.11589843},
{x:35.28388083,y:-95.58208869},
{x:32.5891636,y:-96.89306072},
{x:35.06666476,y:-94.6243798},
{x:35.30282854,y:-99.64048507},
{x:38.06584223,y:-78.93440279},
{x:32.84872535,y:-96.38719981},
{x:31.87551349,y:-102.3821464},
{x:32.3330207,y:-89.15141718},
{x:36.3910972,y:-97.85553441},
{x:38.417529,y:-122.714025},
{x:32.20977598,y:-82.38862708},
{x:35.45219515,y:-84.6267917},
{x:35.5879455,y:-82.49493225},
{x:36.10706173,y:-95.93973108},
{x:31.29466935,y:-92.51375824},
{x:36.55606995,y:-82.59867154},
{x:36.12438934,y:-97.058286},
{x:32.20075474,y:-98.24682311},
{x:30.58391555,y:-97.4104178},
{x:39.76221409,y:-86.3273263},
{x:34.96223244,y:-89.82915335},
{x:35.24072986,y:-94.62551907},
{x:32.85782491,y:-93.98539715},
{x:35.43482833,y:-97.50936138},
{x:33.32110215,y:-111.8471061},
{x:35.44847203,y:-97.44182245},
{x:38.91023065,y:-92.33086042},
{x:35.25596646,y:-97.59967178},
{x:27.99843426,y:-80.64245218},
{x:36.81193909,y:-97.28816137},
{x:30.48373339,y:-87.20295941},
{x:34.00122505,y:-88.73726026},
{x:32.94199578,y:-96.88999347},
{x:33.11495842,y:-96.8424587},
{x:37.11144006,y:-93.29607145},
{x:35.01426243,y:-97.32533976},
{x:36.06144167,y:-95.92006177},
{x:35.05685028,y:-101.91877088},
{x:37.05238935,y:-88.5679665},
{x:32.5905593,y:-95.20547435},
{x:36.25933217,y:-90.28760389},
{x:26.23158571,y:-98.22466671},
{x:47.49457405,y:-111.2708536},
{x:28.28110293,y:-82.70011872},
{x:32.75085273,y:-99.89641786},
{x:43.58988381,y:-116.2913199},
{x:39.71577335,y:-94.8697228},
{x:32.415087,y:-80.65168},
{x:41.40546278,y:-81.69487976},
{x:34.96320503,y:-90.03131211},
{x:44.85813151,y:-93.30601563},
{x:29.9819163,y:-97.87105556},
{x:33.39380317,y:-96.94740549},
{x:34.25112205,y:-88.71688017},
{x:32.880578,y:-97.074086},
{x:35.10885621,y:-106.6015882},
{x:30.48019316,y:-97.83252239},
{x:35.0573758,y:-98.25831638},
{x:31.92551648,y:-92.64795812},
{x:36.08331654,y:-87.79833154},
{x:36.48174088,y:-87.88314327},
{x:34.23330842,y:-97.48259471},
{x:30.06677858,y:-95.18887349},
{x:35.7647364,y:-91.61949702},
{x:38.8258926,y:-104.8398927},
{x:36.24193271,y:-90.95845387},
{x:30.00383985,y:-95.30755051},
{x:29.57479491,y:-95.68234965},
{x:38.91221755,y:-94.7989999},
{x:37.73005303,y:-88.53529193},
{x:29.53563795,y:-98.32970738},
{x:36.35607437,y:-94.18312624},
{x:44.06289089,y:-103.232021},
{x:37.26407187,y:-107.873109},
{x:33.12092058,y:-89.05566797},
{x:32.67036724,y:-97.20184789},
{x:32.26999802,y:-81.22484074},
{x:33.36131914,y:-111.875905},
{x:32.82959235,y:-96.70794171},
{x:40.53823389,y:-105.037719},
{x:33.01857442,y:-94.721259},
{x:32.43099152,y:-99.76021998},
{x:35.75549812,y:-83.95055207},
{x:31.38969462,y:-97.21194909},
{x:30.05725956,y:-94.08555418},
{x:35.90637586,y:-83.84117202},
{x:29.65313912,y:-81.6721078},
{x:38.87387954,y:-104.8171149},
{x:33.66606145,y:-79.83378142},
{x:29.70166228,y:-95.36237554},
{x:34.9489667,y:-85.23513799},
{x:36.69764032,y:-97.06764221},
{x:35.08638926,y:-106.532975},
{x:38.09133985,y:-85.66800001},
{x:40.53953657,y:-74.85680179},
{x:35.5951041,y:-97.55044386},
{x:30.3594861,y:-87.36019955},
{x:32.22094783,y:-110.9385802},
{x:34.49612783,y:-93.03790279},
{x:36.73605315,y:-97.06663265},
{x:32.89492344,y:-96.71824909},
{x:31.63019058,y:-94.65300163},
{x:37.98447912,y:-84.59090873},
{x:33.9120161,y:-81.5350429},
{x:29.6913,y:-95.7676},
{x:39.71784582,y:-91.39278599},
{x:39.20842724,y:-96.30607456},
{x:33.11155707,y:-90.04484252},
{x:36.12849894,y:-88.51108684},
{x:31.985187,y:-81.21987},
{x:34.09140708,y:-98.56969469},
{x:35.14973374,y:-97.39759684},
{x:33.20116101,y:-117.2426738},
{x:40.21458319,y:-92.58303702},
{x:32.2111638,y:-95.82866751},
{x:35.43322775,y:-94.35308107},
{x:36.15773807,y:-86.80247896},
{x:29.23245269,y:-98.7889351},
{x:31.09403786,y:-97.80646406},
{x:34.84206274,y:-87.6694943},
{x:41.79914952,y:-72.55148136},
{x:35.5256275,y:-98.70563775},
{x:38.36135182,y:-98.79191302},
{x:39.02970036,y:-95.75963885},
{x:34.36660343,y:-81.09978207},
{x:39.03470297,y:-82.62706407},
{x:32.53088781,y:-97.6199491},
{x:32.96664406,y:-96.46417335},
{x:44.63926065,y:-121.130147},
{x:35.3028724,y:-80.98584787},
{x:43.55769128,y:-116.5735458},
{x:37.98562042,y:-103.5580527},
{x:29.44458344,y:-98.65853079},
{x:32.86454666,y:-96.67472891},
{x:30.33614725,y:-95.46949089},
{x:29.35678088,y:-98.12771694},
{x:30.13026994,y:-95.55429161},
{x:39.65344316,y:-104.7715956},
{x:40.3082428,y:-75.8627815},
{x:35.59327653,y:-89.25766782},
{x:35.38297323,y:-119.1005127},
{x:33.67441261,y:-101.9831121},
{x:35.24742749,y:-93.03425149},
{x:32.85418381,y:-85.19109361},
{x:32.84846486,y:-86.63409926},
{x:35.37879397,y:-96.92929119},
{x:35.46618831,y:-97.52773628},
{x:32.69178488,y:-96.85833671},
{x:30.20800864,y:-92.01178686},
{x:32.5997547,y:-88.1825456},
{x:40.62247704,y:-111.8675177},
{x:33.52156853,y:-84.4235242},
{x:47.25490898,y:-122.5065829},
{x:30.25637728,y:-94.19745489},
{x:36.32093669,y:-86.57456831},
{x:35.56821609,y:-92.45156586},
{x:30.30501677,y:-89.37970662},
{x:37.04906888,y:-80.76863654},
{x:39.70041002,y:-105.1409961},
{x:44.09014249,y:-121.3050512},
{x:35.0817903,y:-85.05935624},
{x:29.93619165,y:-93.93484824},
{x:43.24969148,y:-83.78155144},
{x:33.38874182,y:-94.10728544},
{x:32.15822051,y:-94.35073078},
{x:39.06646204,y:-93.73257414},
{x:33.76490223,y:-85.89272061},
{x:33.3923463,y:-111.7544108},
{x:34.97289277,y:-101.9190203},
{x:29.78534081,y:-95.81955932},
{x:30.07298902,y:-95.40965014},
{x:33.3496646,y:-111.9469973},
{x:30.2639266,y:-90.98916851},
{x:37.52149514,y:-120.8870879},
{x:35.43301803,y:-97.1589084},
{x:34.30949012,y:-89.92983609},
{x:32.76348789,y:-97.48408936},
{x:32.73377095,y:-96.68170946},
{x:38.34738554,y:-98.21164124},
{x:37.13425501,y:-81.49251514},
{x:35.15730993,y:-79.41797908},
{x:30.16676534,y:-97.79152215},
{x:40.25373596,y:-103.8032343},
{x:29.45817535,y:-98.55686769},
{x:32.463901,y:-97.26449981},
{x:38.99987169,y:-95.68842419},
{x:30.81036,y:-97.61222},
{x:39.07779318,y:-84.70816188},
{x:32.5852302,y:-82.32182264},
{x:32.84033907,y:-97.13295736},
{x:41.93087609,y:-87.88687375},
{x:34.53557406,y:-101.7760025},
{x:34.07057913,y:-93.68534647},
{x:35.65597555,y:-88.40758055},
{x:32.3472624,y:-90.31410545},
{x:34.99225257,y:-86.83898675},
{x:35.72562327,y:-97.47326531},
{x:36.2187333,y:-115.2567859},
{x:35.22009,y:-92.3889},
{x:38.08701743,y:-97.89691687},
{x:37.83048258,y:-97.37159508},
{x:38.4810696,y:-82.6426763},
{x:32.95050175,y:-80.15831832},
{x:34.96727924,y:-89.79559355},
{x:32.45836802,y:-94.66677681},
{x:32.33410182,y:-97.42376648},
{x:30.41856304,y:-91.14682138},
{x:34.15735059,y:-86.83539723},
{x:33.42206742,y:-96.5915823},
{x:35.26155357,y:-91.74532145},
{x:30.08985654,y:-95.64026781},
{x:36.41332222,y:-90.58688879},
{x:39.33727647,y:-94.24971879},
{x:37.3611038,y:-81.09995614},
{x:34.92096834,y:-85.1276748},
{x:37.22964072,y:-93.29764724},
{x:38.97187274,y:-94.68823783},
{x:34.47163099,y:-117.2381926},
{x:32.83552535,y:-97.18571775},
{x:30.603169,y:-87.89887816},
{x:35.56515501,y:-97.54004091},
{x:36.20748569,y:-86.61263623},
{x:38.91630172,y:-94.33768451},
{x:40.03277765,y:-82.44373977},
{x:37.42937633,y:-94.7053282},
{x:32.38731683,y:-90.14240878},
{x:33.23690011,y:-96.78854919},
{x:29.55828837,y:-98.67531405},
{x:36.04946553,y:-89.36733865},
{x:35.34980133,y:-97.56489401},
{x:40.33973045,y:-94.87283173},
{x:35.07637866,y:-106.5765439},
{x:37.74914915,y:-97.46198058},
{x:35.82350834,y:-86.41609341},
{x:41.49572521,y:-74.0741332},
{x:34.15861328,y:-85.70843875},
{x:44.27302,y:-105.49282},
{x:48.09979285,y:-122.1794827},
{x:37.57648761,y:-106.1322876},
{x:34.79567894,y:-91.75127327},
{x:35.16264825,y:-101.8884918},
{x:32.97323602,y:-96.99443936},
{x:36.63530155,y:-95.14310896},
{x:30.51392325,y:-91.02673487},
{x:34.14588029,y:-89.63512235},
{x:29.797947,y:-98.428592},
{x:36.6135941,y:-93.2303342},
{x:32.99850296,y:-94.61862177},
{x:35.67355149,y:-88.7817695},
{x:34.73397849,y:-86.62794786},
{x:32.38266167,y:-86.19878278},
{x:34.86816464,y:-92.12944865},
{x:37.22760758,y:-93.26081514},
{x:29.9668195,y:-95.42358354},
{x:64.8583049,y:-147.6996533},
{x:41.08966986,y:-111.9808504},
{x:36.06205319,y:-102.5161535},
{x:34.27741446,y:-92.09206738},
{x:32.80586768,y:-96.7770993},
{x:35.50693943,y:-84.99992922},
{x:33.44136603,y:-112.3491251},
{x:35.74612477,y:-86.93527713},
{x:32.72844292,y:-97.50287178},
{x:29.69115885,y:-95.41754254},
{x:29.80143446,y:-95.75276703},
{x:36.28721211,y:-92.47992621},
{x:31.70152967,y:-98.11509647},
{x:35.10524132,y:-98.59147825},
{x:31.93230585,y:-87.73882874},
{x:32.30935367,y:-95.27243369},
{x:32.91006576,y:-96.86027259},
{x:36.55200618,y:-87.32614591},
{x:32.61279134,y:-97.11839699},
{x:35.11859221,y:-106.5150797},
{x:32.31366309,y:-106.7410116},
{x:33.68403742,y:-95.55440368},
{x:31.1912006,y:-101.4598359},
{x:29.35441953,y:-98.48071575},
{x:31.75239447,y:-93.0697842},
{x:42.75643244,y:-73.77561702},
{x:25.9494411,y:-97.50062157},
{x:33.42188281,y:-111.838069},
{x:38.29232599,y:-77.57012844},
{x:35.69517287,y:-85.78003122},
{x:33.93295347,y:-83.3127299},
{x:30.04417479,y:-94.88907025},
{x:31.14342059,y:-89.40479368},
{x:35.87915361,y:-94.97554064},
{x:33.99869445,y:-96.41198494},
{x:30.89349152,y:-102.8967925},
{x:34.67743088,y:-88.56001265},
{x:35.96817294,y:-88.92985761},
{x:36.16724241,y:-94.11663429},
{x:38.977238,y:-94.85547625},
{x:36.17498745,y:-94.55344861},
{x:30.49477924,y:-92.84409936},
{x:29.96702764,y:-93.99595634},
{x:30.18615572,y:-92.06728377},
{x:43.59078461,y:-116.3665356},
{x:32.69938042,y:-97.60264434},
{x:33.87740157,y:-79.75943394},
{x:36.87033858,y:-87.49862231},
{x:31.96382773,y:-97.32357056},
{x:37.92995656,y:-90.7789182},
{x:37.84395469,y:-90.50354451},
{x:41.3990832,y:-73.4451102},
{x:36.07862775,y:-95.9584804},
{x:33.00951405,y:-96.54251666},
{x:29.44856106,y:-95.07950038},
{x:36.14560542,y:-85.50030269},
{x:40.3826908,y:-104.7150587},
{x:33.26516788,y:-86.81002938},
{x:35.06927903,y:-91.92277778},
{x:32.38301486,y:-90.04100025},
{x:30.1208082,y:-97.43136664},
{x:30.37983579,y:-87.68235483},
{x:39.73969952,y:-104.8525631},
{x:35.0455439,y:-89.73187767},
{x:32.45067281,y:-99.7430049},
{x:35.09044237,y:-89.93067008},
{x:30.127855,y:-94.01363343},
{x:30.47204126,y:-90.11196681},
{x:39.76389049,y:-89.675674},
{x:34.83140963,y:-87.62456328},
{x:30.39729854,y:-96.0690889},
{x:33.01286728,y:-97.2197476},
{x:29.26432226,y:-94.83099421},
{x:39.09147,y:-95.67250727},
{x:39.7130433,y:-86.3807613},
{x:35.34762656,y:-89.90420222},
{x:36.14487649,y:-115.1168704},
{x:32.66042865,y:-97.2716973},
{x:31.6827308,y:-92.128646},
{x:35.17604911,y:-101.9232032},
{x:39.75179076,y:-105.0536902},
{x:30.96450377,y:-95.88692107},
{x:33.32386089,y:-105.6302417},
{x:29.87262937,y:-97.94005819},
{x:38.85288391,y:-94.37082723},
{x:32.45256499,y:-94.70715576},
{x:33.66273461,y:-95.46412913},
{x:33.4469013,y:-112.3924312},
{x:29.76825177,y:-95.21387356},
{x:36.3521311,y:-88.8483974},
{x:35.98474447,y:-96.76499717},
{x:33.96052409,y:-86.03316643},
{x:35.38505504,y:-94.37859759},
{x:26.52122698,y:-81.89608701},
{x:38.93297449,y:-94.37806432},
{x:35.4475843,y:-89.783518},
{x:35.79949902,y:-87.43851051},
{x:38.05060031,y:-121.3738822},
{x:39.50391144,y:-76.32680856},
{x:33.45103207,y:-111.8163344},
{x:34.18026375,y:-97.1415843},
{x:34.78750148,y:-86.96244749},
{x:28.54107557,y:-81.31118171},
{x:38.94755498,y:-92.29554713},
{x:36.70424441,y:-119.555759},
{x:38.14421416,y:-97.42159083},
{x:38.79740478,y:-77.51941586},
{x:28.25336437,y:-81.31540284},
{x:35.62280178,y:-97.51427963},
{x:32.13292471,y:-94.80429903},
{x:46.80282,y:-96.8231},
{x:30.04856045,y:-95.47278464},
{x:33.84333715,y:-87.2434026},
{x:39.13458138,y:-94.58106883},
{x:35.0823116,y:-78.95356089},
{x:39.66906072,y:-104.9879245},
{x:35.12396169,y:-118.4662241},
{x:34.1137553,y:-80.8899525},
{x:30.173513,y:-93.248841},
{x:37.32751468,y:-79.50280354},
{x:33.47972641,y:-111.9095117},
{x:36.62651751,y:-87.31694476},
{x:27.79998893,y:-97.67682168},
{x:31.30816735,y:-89.31905198},
{x:32.9612295,y:-96.64655485},
{x:32.99945242,y:-80.04002904},
{x:30.16158931,y:-91.99162602},
{x:36.1049022,y:-95.85510001},
{x:35.01017312,y:-92.79998742},
{x:33.63139731,y:-91.80141852},
{x:32.89377722,y:-97.10244455},
{x:35.43567104,y:-97.09788748},
{x:30.52833995,y:-87.27465954},
{x:33.56301567,y:-101.9104752},
{x:33.63895877,y:-97.13375733},
{x:30.21187139,y:-95.75260945},
{x:35.23279274,y:-97.1775739},
{x:35.54729235,y:-82.65572011},
{x:40.0006,y:-105.0138},
{x:40.25630092,y:-80.1521127},
{x:29.70656147,y:-91.20074972},
{x:32.30873026,y:-95.47365301},
{x:34.38521589,y:-92.8273675},
{x:39.089302,y:-77.558942},
{x:34.54691132,y:-91.9585891},
{x:28.09940773,y:-80.62127598},
{x:36.25888119,y:-115.154184},
{x:38.78839125,y:-89.97856363},
{x:36.27648398,y:-88.70728876},
{x:36.10250222,y:-86.6660028},
{x:36.80908085,y:-119.7149639},
{x:37.39345233,y:-76.52922347},
{x:29.66485723,y:-95.08425929},
{x:29.04166024,y:-95.70553736},
{x:32.62514891,y:-93.25344157},
{x:30.84626688,y:-89.14968342},
{x:32.40106965,y:-98.79194364},
{x:37.6495,y:-98.0941},
{x:35.40574324,y:-94.51501876},
{x:43.63443284,y:-116.3128072},
{x:40.82737837,y:-96.60524815},
{x:38.86750614,y:-94.17263326},
{x:35.40670195,y:-97.56260701},
{x:38.87063556,y:-76.851625},
{x:35.59383646,y:-80.87023553},
{x:30.67193559,y:-97.91792192},
{x:30.0682201,y:-95.24295621},
{x:39.61872153,y:-104.7717827},
{x:37.30967755,y:-91.9609791},
{x:29.54517999,y:-95.74727789},
{x:39.01995056,y:-94.66392964},
{x:35.32256084,y:-91.56109862},
{x:37.82851425,y:-92.17595547},
{x:30.87985318,y:-87.78525912},
{x:30.35945371,y:-88.54801166},
{x:35.95522189,y:-85.81140139},
{x:30.75894433,y:-98.23861823},
{x:33.90685882,y:-98.53424326},
{x:35.18853593,y:-97.45073944},
{x:26.2082249,y:-98.1546376},
{x:38.42781779,y:-92.85268889},
{x:34.73840334,y:-97.2246936},
{x:30.3376751,y:-95.09240985},
{x:32.60209746,y:-93.84494261},
{x:29.55785548,y:-95.80950093},
{x:33.65871788,y:-83.99934389},
{x:31.86942143,y:-106.4305888},
{x:32.51056714,y:-95.40832178},
{x:40.37986086,y:-111.8140143},
{x:31.87021202,y:-89.7453417},
{x:36.05841054,y:-88.10236529},
{x:32.44277533,y:-93.71986449},
{x:31.85289632,y:-106.5837758},
{x:30.07458836,y:-95.51513002},
{x:35.13715983,y:-80.90475933},
{x:29.67958255,y:-95.12380914},
{x:35.22843076,y:-89.51989265},
{x:44.74585409,y:-93.33285794},
{x:30.00692552,y:-90.23591906},
{x:36.62640962,y:-82.12137296},
{x:44.94942676,y:-93.01758841},
{x:38.19609662,y:-85.60308427},
{x:32.44564325,y:-96.66558713},
{x:29.55525852,y:-98.31599675},
{x:35.11688319,y:-85.25106378},
{x:41.538399,y:-73.4213821},
{x:26.30440174,y:-98.1875302},
{x:30.24483265,y:-92.0159683},
{x:32.24032022,y:-93.82739964},
{x:30.66025121,y:-96.33034252},
{x:39.43100778,y:-92.4335021},
{x:36.38229545,y:-90.18713},
{x:30.18891101,y:-85.81683113},
{x:29.32572406,y:-96.09528032},
{x:33.922,y:-116.9494},
{x:32.92102032,y:-80.66895695},
{x:47.90246,y:-97.04587},
{x:39.13847285,y:-94.19621768},
{x:37.04792687,y:-88.65374968},
{x:39.01432069,y:-96.82947807},
{x:37.14312843,y:-94.50993061},
{x:33.0937615,y:-97.1913443},
{x:33.2130583,y:-96.7677445},
{x:36.00624876,y:-88.40757519},
{x:36.610139,y:-83.701448},
{x:39.87833114,y:-83.04463641},
{x:30.58202093,y:-91.12958148},
{x:37.08301405,y:-88.63997146},
{x:37.054475,y:-94.51207906},
{x:35.51220244,y:-97.63543636},
{x:30.13999658,y:-91.94598788},
{x:40.08062559,y:-104.8092711},
{x:33.82508886,y:-116.4588976},
{x:33.53223317,y:-89.26389834},
{x:36.05221092,y:-90.4907569},
{x:35.9149156,y:-94.95321845},
{x:35.53283074,y:-97.96629651},
{x:27.85463135,y:-82.31501772},
{x:35.49257229,y:-97.17383691},
{x:30.9393699,y:-95.37450314},
{x:32.51649987,y:-90.09642993},
{x:32.92082523,y:-96.45968467},
{x:35.79639164,y:-88.78550382},
{x:32.82195728,y:-96.96253315},
{x:37.49315377,y:-94.29466188},
{x:35.04473259,y:-85.16103611},
{x:33.16331109,y:-95.23368918},
{x:38.28507239,y:-104.5739953},
{x:38.60251141,y:-90.26925606},
{x:30.41441794,y:-91.05604901},
{x:31.72776123,y:-106.2988173},
{x:32.66387437,y:-95.49200535},
{x:32.54797983,y:-84.94048476},
{x:30.23142804,y:-95.164357},
{x:30.34331301,y:-97.96749451},
{x:33.7168951,y:-84.14446213},
{x:40.04224,y:-86.15661},
{x:30.40639705,y:-87.27507949},
{x:40.800028,y:-72.868602},
{x:36.70548537,y:-85.68904974},
{x:34.46519828,y:-117.3512171},
{x:32.64770548,y:-96.90890774},
{x:36.008914,y:-106.0645008},
{x:33.94001447,y:-83.4832909},
{x:37.9752794,y:-100.8848292},
{x:29.2127075,y:-99.77530003},
{x:31.74242774,y:-106.3777927},
{x:30.5873917,y:-84.5933833},
{x:34.83337136,y:-92.23219695},
{x:30.66426682,y:-96.37189999},
{x:35.46529717,y:-97.72513882},
{x:34.23525855,y:-102.7421397},
{x:33.5064724,y:-94.61038605},
{x:31.95383376,y:-95.26558466},
{x:32.35925782,y:-90.01394816},
{x:37.37796069,y:-89.66683477},
{x:37.72894999,y:-89.20189665},
{x:29.7349,y:-95.811},
{x:35.54990028,y:-100.971306},
{x:35.29140121,y:-97.80582905},
{x:36.17482924,y:-94.18264747},
{x:37.60837785,y:-97.31404737},
{x:29.58061918,y:-97.95412624},
{x:37.951079,y:-121.2916269},
{x:33.26709322,y:-93.22199621},
{x:34.71268484,y:-92.40600032},
{x:36.1390186,y:-95.9579225},
{x:35.39006574,y:-97.26417102},
{x:35.2408698,y:-81.12245842},
{x:33.2136265,y:-96.6799892},
{x:37.0441215,y:-93.30606334},
{x:35.20342525,y:-97.42164016},
{x:35.52984497,y:-97.5302522},
{x:29.99575396,y:-95.62222056},
{x:41.16053389,y:-104.7777211},
{x:27.67770181,y:-97.36704782},
{x:39.56419474,y:-97.65788108},
{x:36.7071295,y:-110.2561029},
{x:38.7819498,y:-95.18427686},
{x:38.66781161,y:-90.60038482},
{x:33.85755129,y:-98.56512018},
{x:36.9593667,y:-93.7186756},
{x:32.68146392,y:-96.6579415},
{x:35.65484461,y:-97.58542524},
{x:29.64390236,y:-95.57740085},
{x:30.82344926,y:-83.30594239},
{x:35.65980044,y:-105.9713866},
{x:30.7184595,y:-95.56654461},
{x:34.6201716,y:-89.9601638},
{x:30.38960921,y:-89.04363289},
{x:29.6163,y:-98.6051},
{x:33.08492135,y:-96.77239404},
{x:31.76458874,y:-106.3213867},
{x:31.4219663,y:-100.4684083},
{x:38.72202715,y:-94.45599943},
{x:39.82971522,y:-89.60392826},
{x:35.8434039,y:-86.4400059},
{x:36.285968,y:-95.634747},
{x:36.19542792,y:-115.230753},
{x:39.35173739,y:-94.76553532},
{x:31.93414046,y:-102.4003539},
{x:47.75328903,y:-117.3998394},
{x:31.69356272,y:-83.24519187},
{x:39.84086705,y:-96.07208927},
{x:39.21860608,y:-92.13860571},
{x:32.72382389,y:-103.127266},
{x:36.31719431,y:-95.62637223},
{x:32.965541,y:-97.043907},
{x:27.84196743,y:-82.69935028},
{x:30.24302116,y:-97.88227402},
{x:41.49353229,y:-72.81012994},
{x:38.21219216,y:-90.39257213},
{x:40.41773133,y:-91.40544697},
{x:38.02152276,y:-84.91084491},
{x:33.80137173,y:-85.76074183},
{x:35.51539981,y:-84.35959339},
{x:34.2197382,y:-83.86335077},
{x:42.52976498,y:-96.37381107},
{x:38.86814726,y:-98.8538039},
{x:35.4859035,y:-86.43065198},
{x:37.97543058,y:-100.8574674},
{x:32.41177935,y:-104.2293996},
{x:34.37251131,y:-97.96453335},
{x:29.94034411,y:-96.25192211},
{x:28.37418457,y:-80.75447381},
{x:27.84489261,y:-97.58552565},
{x:34.17329394,y:-97.26198167},
{x:33.24705391,y:-84.28206933},
{x:30.85288967,y:-83.95124719},
{x:32.43480487,y:-96.2205483},
{x:35.2324359,y:-114.0365205},
{x:35.15670712,y:-107.867948},
{x:37.89805649,y:-84.56470057},
{x:37.10989999,y:-84.07297657},
{x:35.34764305,y:-94.34110366},
{x:29.64081737,y:-98.37882945},
{x:31.57033625,y:-91.43724724},
{x:35.4641729,y:-93.4804901},
{x:34.82321144,y:-82.58303963},
{x:36.75075035,y:-86.19155221},
{x:26.14227753,y:-97.63124406},
{x:32.43830018,y:-104.2546973},
{x:35.20555779,y:-89.73891445},
{x:31.44801333,y:-96.07786216},
{x:33.0584932,y:-96.69848979},
{x:29.57542367,y:-90.68743073},
{x:32.27692276,y:-89.99763362},
{x:29.76904114,y:-95.38742737},
{x:36.84131616,y:-87.48254635},
{x:32.64909327,y:-96.54660702},
{x:39.64628098,y:-86.32848932},
{x:37.23667382,y:-79.96998215},
{x:34.07760065,y:-117.5566871},
{x:34.18857894,y:-101.7517937},
{x:37.97615797,y:-87.39501045},
{x:36.66581351,y:-94.44191247},
{x:29.5472778,y:-95.4448175},
{x:36.27009334,y:-86.75580651},
{x:36.11505981,y:-97.11762921},
{x:39.01434053,y:-94.74374682},
{x:29.40223101,y:-95.03449426},
{x:29.83667918,y:-95.16488314},
{x:33.30971762,y:-96.7826563},
{x:29.5797369,y:-95.76927469},
{x:33.46331067,y:-94.09413859},
{x:31.77034331,y:-95.63498586},
{x:30.02859926,y:-93.83894046},
{x:34.79989352,y:-86.53565615},
{x:30.75607239,y:-85.55002616},
{x:34.58623873,y:-86.9807949},
{x:34.40430396,y:-103.2069739},
{x:33.90370567,y:-89.00051371},
{x:39.01836896,y:-94.19867836},
{x:34.58583786,y:-112.3359346},
{x:32.35422092,y:-90.82813061},
{x:29.51750401,y:-98.59563903},
{x:40.2013463,y:-105.1032239},
{x:32.60462999,y:-96.81932422},
{x:33.53784001,y:-112.1993431},
{x:35.82845784,y:-86.07854136},
{x:35.35154702,y:-93.12482246},
{x:32.76282824,y:-97.07399235},
{x:29.21955056,y:-98.41631325},
{x:30.4440376,y:-93.96892235},
{x:33.57314757,y:-112.1001551},
{x:39.02748867,y:-94.25624125},
{x:35.92227808,y:-84.06295717},
{x:32.71885059,y:-96.82979599},
{x:36.75118025,y:-76.58245728},
{x:30.5229044,y:-92.0814897},
{x:33.58147406,y:-112.1701326},
{x:39.47722896,y:-118.8023155},
{x:35.24677576,y:-91.75712921},
{x:32.80965608,y:-98.09697211},
{x:32.67183015,y:-114.6495894},
{x:35.03409242,y:-89.67075884},
{x:30.19887058,y:-93.24870765},
{x:32.4143074,y:-94.70741965},
{x:35.07380802,y:-85.62176853},
{x:40.70096,y:-73.94259},
{x:39.10598539,y:-94.53266971},
{x:29.13890936,y:-95.65424547},
{x:40.507412,y:-112.013051},
{x:37.41712483,y:-95.69041707},
{x:30.31160237,y:-92.04982863},
{x:40.1558817,y:-75.8630442},
{x:36.59971095,y:-79.41708751},
{x:38.53511032,y:-93.51694331},
{x:36.1068472,y:-87.63230347},
{x:29.4043,y:-98.7045},
{x:32.80920078,y:-96.68438472},
{x:33.52448528,y:-112.130401},
{x:32.88814867,y:-96.96155808},
{x:32.35646444,y:-90.22889705},
{x:32.24737935,y:-97.73697942},
{x:30.42398242,y:-97.75589083},
{x:29.48150855,y:-98.35748032},
{x:41.72952937,y:-86.28973782},
{x:29.50427228,y:-95.10792643},
{x:36.5122194,y:-84.50966169},
{x:33.53553732,y:-101.9402792},
{x:39.8416148,y:-96.63547532},
{x:42.947277,y:-78.87841},
{x:35.14606795,y:-90.18705847},
{x:35.45829691,y:-79.14464555},
{x:34.625814,y:-98.421905},
{x:43.03636416,y:-83.32164019},
{x:31.89733598,y:-98.59002918},
{x:33.00809234,y:-96.68288872},
{x:35.139318,y:-89.978486},
{x:32.55510847,y:-83.66355614},
{x:32.29469683,y:-106.7678015},
{x:33.42148545,y:-112.2916027},
{x:35.29217738,y:-93.71854954},
{x:32.72075291,y:-96.61364482},
{x:37.67363716,y:-92.65553427},
{x:29.41684385,y:-98.6544411},
{x:29.62814829,y:-95.46432763},
{x:34.4641122,y:-92.97001842},
{x:34.36286934,y:-89.55874443},
{x:38.25409493,y:-93.36777516},
{x:36.6975576,y:-101.4823926},
{x:32.68198407,y:-92.04746283},
{x:33.68227918,y:-117.919831},
{x:38.35614931,y:-87.60689183},
{x:33.66168935,y:-95.530544},
{x:33.17256122,y:-94.97087091},
{x:38.41578925,y:-82.25733295},
{x:36.14551236,y:-86.87212185},
{x:35.99186832,y:-95.79789519},
{x:37.8600008,y:-87.56576322},
{x:36.1412366,y:-78.71908151},
{x:31.8483,y:-96.7973},
{x:31.08469261,y:-97.37951464},
{x:38.87021984,y:-104.7151753},
{x:32.49536468,y:-85.01468271},
{x:36.08499866,y:-79.77735226},
{x:30.53458479,y:-96.69087454},
{x:34.92210087,y:-88.5151906},
{x:30.409608,y:-88.759899},
{x:29.38220663,y:-98.63811985},
{x:29.56977838,y:-98.64928053},
{x:38.18026852,y:-84.89574107},
{x:31.62871061,y:-97.2355874},
{x:32.93952885,y:-96.75320752},
{x:41.23917763,y:-73.03423405},
{x:40.48017022,y:-104.9182006},
{x:34.97983891,y:-80.10114585},
{x:33.17357,y:-96.70115},
{x:35.47980343,y:-95.30920476},
{x:31.1124383,y:-97.39924305},
{x:30.69199849,y:-88.07173178},
{x:30.1358,y:-95.2883},
{x:43.19676644,y:-77.59055614},
{x:35.96918454,y:-85.03732324},
{x:32.47933177,y:-93.72100778},
{x:33.53029435,y:-90.19430526},
{x:32.72395,y:-97.46742},
{x:35.87991203,y:-83.77636529},
{x:36.1589158,y:-115.0493098},
{x:30.5195168,y:-97.6549083},
{x:39.69284912,y:-105.0527214},
{x:37.5446363,y:-77.4523754},
{x:29.73210168,y:-95.62431135},
{x:32.62297102,y:-85.40694936},
{x:35.53560615,y:-80.6057024},
{x:36.51665858,y:-76.1697979},
{x:35.18384588,y:-101.865626},
{x:38.17595057,y:-83.43559808},
{x:35.45917694,y:-94.35552018},
{x:39.33675377,y:-76.48121309},
{x:36.01399566,y:-87.33281069},
{x:38.94213312,y:-95.22214725},
{x:32.6075608,y:-97.07956731},
{x:29.61602609,y:-95.41911967},
{x:34.99529796,y:-85.23669183},
{x:35.53720392,y:-97.59975963},
{x:33.87107921,y:-98.4938693},
{x:32.74714182,y:-96.59761459},
{x:36.10253698,y:-84.58531068},
{x:33.62773572,y:-79.0359984},
{x:39.86633808,y:-84.10055705},
{x:33.72905553,y:-117.009252},
{x:38.01420307,y:-92.73902609},
{x:36.08690377,y:-87.10021555},
{x:35.20560819,y:-89.9126304},
{x:41.23339195,y:-96.20629496},
{x:34.37728432,y:-80.0756617},
{x:35.71308931,y:-91.63357618},
{x:32.75491988,y:-117.1329126},
{x:35.23034857,y:-97.48076133},
{x:30.48106576,y:-97.66392678},
{x:33.24378,y:-96.80941},
{x:44.05924217,y:-122.9957859},
{x:31.10660459,y:-87.47598583},
{x:30.68940118,y:-91.46906734},
{x:33.183863,y:-102.2747148},
{x:35.96077441,y:-86.67292893},
{x:40.57433873,y:-105.0990115},
{x:30.42127632,y:-95.48904017},
{x:30.1092433,y:-95.38599104},
{x:34.92520153,y:-79.75377812},
{x:33.23720912,y:-87.57734299},
{x:39.32726829,y:-94.91515323},
{x:29.99779582,y:-95.17518886},
{x:31.2778873,y:-92.45451026},
{x:40.63902637,y:-112.0049661},
{x:30.06783846,y:-94.18692206},
{x:33.09919223,y:-96.81256554},
{x:33.51909128,y:-101.992095},
{x:33.5547155,y:-97.84181625},
{x:39.935932,y:-91.391876},
{x:34.80296154,y:-92.42888242},
{x:42.02065329,y:-88.14524577},
{x:30.90993964,y:-83.2640294},
{x:33.697687,y:-80.225182},
{x:35.5520759,y:-82.47375906},
{x:32.37452235,y:-96.86504},
{x:36.76229342,y:-76.25164422},
{x:32.158072,y:-90.126152},
{x:40.05836191,y:-88.25139805},
{x:33.85863816,y:-117.9191914},
{x:39.07694268,y:-108.5279909},
{x:32.08913092,y:-92.08908324},
{x:29.90975203,y:-95.68548381},
{x:32.52774331,y:-94.78636645},
{x:31.19597598,y:-98.72938051},
{x:32.28821212,y:-95.3040965},
{x:34.88528298,y:-92.10764632},
{x:31.05440343,y:-97.45553121},
{x:33.166841,y:-87.524149},
{x:33.39349844,y:-104.5406386},
{x:29.57880533,y:-95.15728391},
{x:34.01875188,y:-94.73903686},
{x:34.1464014,y:-80.9105233},
{x:27.62911,y:-97.22499},
{x:38.855357,y:-104.8785608},
{x:30.91360981,y:-93.99572999},
{x:36.876,y:-97.0611},
{x:37.30620432,y:-120.4921951},
{x:30.20969612,y:-90.95100538},
{x:47.36761048,y:-122.1860667},
{x:38.7534223,y:-104.7412196},
{x:36.39821109,y:-86.16481662},
{x:33.02609191,y:-97.27019959},
{x:39.36110214,y:-104.6104431},
{x:30.64941805,y:-97.01567021},
{x:31.32468369,y:-85.83277506},
{x:32.32119083,y:-106.7719936},
{x:35.44316207,y:-87.27897942},
{x:30.15728395,y:-92.04690569},
{x:35.27457051,y:-93.12397756},
{x:35.06303975,y:-106.1920358},
{x:35.593786,y:-87.052497},
{x:37.03337674,y:-95.62784314},
{x:31.91290401,y:-106.5838331},
{x:29.13232592,y:-98.89575743},
{x:33.40833771,y:-111.9211927},
{x:35.9197986,y:-78.46382171},
{x:40.145649,y:-75.318522},
{x:28.9116486,y:-81.27790556},
{x:37.2874817,y:-80.091925},
{x:40.67099891,y:-111.9860244},
{x:29.76929563,y:-95.90102499},
{x:35.73274431,y:-77.95277774},
{x:37.07910888,y:-94.4768311},
{x:32.43657061,y:-97.10432947},
{x:28.69830506,y:-81.34504662},
{x:33.66591411,y:-101.6847298},
{x:35.09282469,y:-92.46788778},
{x:29.26152948,y:-81.0506896},
{x:32.44617081,y:-97.69495056},
{x:29.38253734,y:-95.11217237},
{x:35.07071828,y:-89.90604624},
{x:39.3627093,y:-94.58449602},
{x:37.36077216,y:-120.6122305},
{x:35.03587911,y:-89.84707855},
{x:35.49292397,y:-97.57648409},
{x:35.27461422,y:-94.47853267},
{x:42.1592759,y:-88.3365342},
{x:35.03477992,y:-106.6799504},
{x:33.81504027,y:-98.19198583},
{x:36.12434268,y:-86.7768243},
{x:33.22137378,y:-96.93348483},
{x:29.79130478,y:-95.56433362},
{x:36.33484853,y:-93.42933164},
{x:36.11599585,y:-83.48748521},
{x:33.2111301,y:-97.76349173},
{x:35.8613,y:-86.8452},
{x:29.89862308,y:-95.41305142},
{x:29.59954136,y:-95.35505579},
{x:32.61160737,y:-85.46403587},
{x:30.47467,y:-89.11256},
{x:36.29119851,y:-86.70269235},
{x:32.94436739,y:-103.3501004},
{x:31.87591559,y:-85.1506801},
{x:36.86486692,y:-76.41180173},
{x:35.24312881,y:-89.35764426},
{x:35.39543033,y:-97.42307246},
{x:32.40250629,y:-93.80183146},
{x:35.6508888,y:-97.74574288},
{x:32.87870988,y:-97.15914376},
{x:30.4003258,y:-91.17521659},
{x:28.08075436,y:-82.43325636},
{x:30.76725052,y:-89.85135361},
{x:33.826668,y:-84.09771843},
{x:30.8077542,y:-88.0721207},
{x:33.92254367,y:-84.21405003},
{x:37.22928629,y:-96.99784778},
{x:35.49244009,y:-97.36884356},
{x:35.72342181,y:-81.65858191},
{x:29.49820981,y:-98.49987679},
{x:36.20373716,y:-83.32696908},
{x:34.75995157,y:-92.30929144},
{x:35.89776124,y:-90.80063921},
{x:36.31101867,y:-94.1163379},
{x:30.70661125,y:-101.202944},
{x:37.0043756,y:-85.92579626},
{x:32.35308795,y:-89.64952016},
{x:33.88713349,y:-98.46768894},
{x:29.04797035,y:-95.46560035},
{x:36.7972429,y:-98.65809157},
{x:38.741947,y:-98.21594},
{x:34.1053526,y:-80.9544654},
{x:36.00696921,y:-84.2603805},
{x:33.44322481,y:-94.04349514},
{x:37.07463544,y:-84.61873502},
{x:39.74366,y:-104.43299},
{x:35.40119819,y:-94.40304861},
{x:36.03182098,y:-83.9303615},
{x:33.519355,y:-88.434565},
{x:33.44817038,y:-94.13021459},
{x:31.14709469,y:-93.26456219},
{x:33.9887,y:-81.23997},
{x:38.18152098,y:-122.2543748},
{x:37.99067838,y:-85.71026347},
{x:35.13595061,y:-101.9027242},
{x:37.63616141,y:-91.53191821},
{x:36.50984959,y:-87.27625161},
{x:38.93441595,y:-94.63904037},
{x:34.46964078,y:-93.13707545},
{x:36.41489792,y:-89.05515432},
{x:37.48603578,y:-97.24198296},
{x:29.9095,y:-95.7264},
{x:35.14610422,y:-96.49300978},
{x:38.59271965,y:-95.26839495},
{x:35.41198683,y:-119.0475693},
{x:28.70947974,y:-100.4777437},
{x:42.06764543,y:-80.09392052},
{x:36.12777959,y:-96.35531155},
{x:39.92267719,y:-83.74608972},
{x:36.34860208,y:-82.24221989},
{x:35.61046821,y:-97.58673952},
{x:40.47635763,y:-86.10053714},
{x:33.01515031,y:-80.08804546},
{x:29.56895574,y:-97.97976964},
{x:27.96273052,y:-82.50496321},
{x:34.12597839,y:-81.22270142},
{x:38.18910834,y:-84.56183984},
{x:37.34256024,y:-87.49787986},
{x:35.32001407,y:-80.66813018},
{x:39.61564087,y:-77.69137301},
{x:34.6563694,y:-92.42697629},
{x:37.29946679,y:-93.42432395},
{x:32.43720454,y:-94.944366},
{x:29.89710571,y:-95.06289348},
{x:40.51439066,y:-88.95153389},
{x:36.78772538,y:-95.934484},
{x:32.74676743,y:-96.44806065},
{x:36.22068429,y:-115.2160518},
{x:30.19787364,y:-93.20622399},
{x:32.41552884,y:-97.22387887},
{x:33.56278423,y:-101.8577696},
{x:33.58431391,y:-112.2369847},
{x:31.58211952,y:-97.17352309},
{x:29.58806383,y:-95.20871393},
{x:26.12217063,y:-81.76088557},
{x:33.22483241,y:-96.98544592},
{x:33.7387,y:-116.2655},
{x:36.11490844,y:-95.90444093},
{x:34.1910599,y:-103.3308437},
{x:40.28911996,y:-96.74555518},
{x:39.58488049,y:-82.95249313},
{x:29.71830966,y:-95.50110676},
{x:32.87841141,y:-97.38038095},
{x:38.52257408,y:-106.0213461},
{x:30.6460445,y:-97.67870978},
{x:34.00110156,y:-96.37326079},
{x:33.916429,y:-84.3788},
{x:36.13309504,y:-94.14768152},
{x:37.08334582,y:-94.50099885},
{x:33.36312234,y:-111.6351673},
{x:39.18728761,y:-96.55970156},
{x:35.36739074,y:-80.71060494},
{x:40.63602019,y:-75.43642648},
{x:37.6123298,y:-77.33486921},
{x:37.8213872,y:-85.46233066},
{x:32.81918246,y:-80.06515615},
{x:38.36907726,y:-97.65220553},
{x:34.88540069,y:-99.50184613},
{x:35.10959434,y:-106.7304263},
{x:32.22141292,y:-110.7905967},
{x:30.36023501,y:-89.75194529},
{x:36.3339422,y:-94.1283328},
{x:29.81807941,y:-95.05698391},
{x:32.76866505,y:-96.62666186},
{x:32.61177,y:-98.1095},
{x:36.37071424,y:-89.46744204},
{x:35.7420571,y:-95.34008905},
{x:33.18953255,y:-111.5992807},
{x:36.06042374,y:-95.87265037},
{x:38.90132447,y:-94.53325108},
{x:30.1755295,y:-97.82349207},
{x:38.71881151,y:-93.99304636},
{x:32.31397121,y:-106.7967358},
{x:34.85945189,y:-76.89630524},
{x:36.70829693,y:-78.10770062},
{x:35.17460856,y:-84.65110928},
{x:37.32308024,y:-79.87231035},
{x:35.46478153,y:-97.61955567},
{x:37.99643912,y:-88.92458942},
{x:37.21263606,y:-93.35048273},
{x:36.68126079,y:-97.29704442},
{x:36.74608664,y:-90.39665915},
{x:36.41700473,y:-94.80688468},
{x:37.34102589,y:-85.36146499},
{x:36.80287209,y:-119.7913009},
{x:38.7674584,y:-93.55585814},
{x:33.27652972,y:-111.7199466},
{x:33.9205104,y:-117.4469275},
{x:35.07539103,y:-80.65073402},
{x:39.77530561,y:-84.07801644},
{x:32.82332,y:-96.64725},
{x:29.77984922,y:-98.73006545},
{x:32.14006447,y:-81.24571033},
{x:35.05883806,y:-106.584553},
{x:30.01382959,y:-95.59226029},
{x:33.79002628,y:-84.62347247},
{x:28.84823318,y:-96.97749988},
{x:35.92847631,y:-86.875492},
{x:32.195319,y:-96.897515},
{x:31.45125006,y:-83.52768347},
{x:37.25333171,y:-77.38392681},
{x:35.70544727,y:-82.03498541},
{x:36.08942893,y:-95.9253484},
{x:34.93306438,y:-90.00593823},
{x:32.7356155,y:-89.53619226},
{x:30.52929431,y:-90.95653113},
{x:45.40899023,y:-122.7937549},
{x:30.76860357,y:-94.41548936},
{x:32.62997626,y:-96.44928288},
{x:36.1436333,y:-86.74554505},
{x:37.119882,y:-76.45597168},
{x:33.55311826,y:-80.82636663},
{x:36.86700636,y:-87.81313465},
{x:38.88289374,y:-90.12181341},
{x:34.4921288,y:-85.8444395},
{x:36.73118654,y:-108.192231},
{x:34.8880794,y:-82.06908748},
{x:38.0680214,y:-91.40557945},
{x:38.34906206,y:-121.9868224},
{x:32.81253358,y:-97.26386126},
{x:35.37328931,y:-77.9467079},
{x:29.8634999,y:-95.60991205},
{x:34.92081203,y:-95.77146336},
{x:40.07493758,y:-93.59543011},
{x:27.91609591,y:-97.1610716},
{x:42.12902307,y:-71.10153079},
{x:33.858519,y:-112.1119662},
{x:30.26010511,y:-89.7871989},
{x:29.37781046,y:-95.00343964},
{x:36.1552325,y:-85.51584408},
{x:35.1490031,y:-106.6389598},
{x:35.57005748,y:-88.81533265},
{x:32.902781,y:-97.195042},
{x:36.3734836,y:-105.5934517},
{x:35.65701789,y:-105.9893784},
{x:29.465375,y:-90.322299},
{x:32.97511377,y:-80.06327659},
{x:35.5339965,y:-97.47756001},
{x:33.45115084,y:-111.7661275},
{x:35.09033804,y:-81.66293532},
{x:39.18926681,y:-96.60639547},
{x:30.02319951,y:-95.41952804},
{x:33.54968829,y:-101.8883094},
{x:40.94113403,y:-76.02703214},
{x:31.78955205,y:-97.58138046},
{x:39.77071663,y:-89.71007131},
{x:35.2193616,y:-106.6627796},
{x:38.9926455,y:-94.70280516},
{x:34.32174666,y:-86.49645232},
{x:36.9293316,y:-86.41354322},
{x:36.1434855,y:-115.2330367},
{x:32.67453281,y:-97.09218242},
{x:43.22543318,y:-123.3476414},
{x:41.676082,y:-87.719302},
{x:39.002305,y:-94.272429},
{x:35.37664218,y:-97.56457642},
{x:45.05779207,y:-93.24827984},
{x:37.09146691,y:-85.30528866},
{x:33.7633839,y:-96.66686386},
{x:33.8105777,y:-86.8228005},
{x:34.78181565,y:-98.29991609},
{x:39.99786591,y:-74.99204709},
{x:39.97092822,y:-104.8280655},
{x:37.14635823,y:-90.70084887},
{x:29.34395,y:-95.03508},
{x:30.32017045,y:-97.69719154},
{x:33.76224565,y:-96.58708674},
{x:32.99679789,y:-111.5232061},
{x:39.52296421,y:-107.7744721},
{x:32.84181296,y:-104.416274},
{x:32.79198601,y:-96.59632176},
{x:38.36583884,y:-93.75676011},
{x:36.39092115,y:-86.43549376},
{x:42.73905469,y:-73.67704552},
{x:31.29461328,y:-92.47743108},
{x:33.54329945,y:-84.22730886},
{x:33.74823981,y:-97.1353814},
{x:39.06603096,y:-94.61505711},
{x:33.00321576,y:-96.7694141},
{x:29.485368,y:-98.733656},
{x:44.29420013,y:-121.1732353},
{x:34.49664298,y:-89.01880638},
{x:35.26146438,y:-97.49229061},
{x:26.119039,y:-98.217071},
{x:33.81447184,y:-88.54243398},
{x:39.24017269,y:-94.43815873},
{x:33.46255435,y:-94.06660974},
{x:34.73557334,y:-76.79729954},
{x:35.93197276,y:-83.90342072},
{x:33.9618055,y:-98.51290964},
{x:36.59434448,y:-82.21561818},
{x:39.73808707,y:-105.1935509},
{x:32.38178712,y:-94.86946679},
{x:33.37145776,y:-98.77447196},
{x:39.23899871,y:-94.90095629},
{x:29.5596869,y:-95.32913625},
{x:35.49180038,y:-97.65376792},
{x:33.793858,y:-84.425938},
{x:34.07812294,y:-88.6316037},
{x:41.21319639,y:-95.97892984},
{x:33.9515448,y:-80.3766302},
{x:32.52758848,y:-94.74315003},
{x:36.51845947,y:-119.5663998},
{x:35.9077466,y:-90.58703772},
{x:31.11915835,y:-97.8952767},
{x:42.4272856,y:-83.3412441},
{x:34.7758978,y:-90.77169195},
{x:29.41340103,y:-95.24328612},
{x:36.21650343,y:-86.72845602},
{x:42.56945507,y:-114.4609986},
{x:29.64918802,y:-95.16532034},
{x:33.60681004,y:-85.83805316},
{x:32.95415876,y:-96.61206636},
{x:34.15282037,y:-99.30966721},
{x:34.01940556,y:-84.62137767},
{x:36.23407287,y:-95.68894907},
{x:30.18591923,y:-93.21913831},
{x:30.31095625,y:-89.76572849},
{x:30.19296249,y:-97.73940548},
{x:34.12255669,y:-109.2919406},
{x:39.42296915,y:-84.59376232},
{x:37.03921991,y:-76.3979964},
{x:32.01834579,y:-102.0794691},
{x:36.73403384,y:-86.57865666},
{x:36.36945096,y:-93.57870922},
{x:31.48208459,y:-87.33031758},
{x:42.37835301,y:-122.8961603},
{x:35.90334726,y:-80.57869399},
{x:29.49563349,y:-98.70721571},
{x:33.73033139,y:-84.93468835},
{x:32.91064342,y:-96.59819059},
{x:36.82616119,y:-94.61106161},
{x:39.56118714,y:-95.12776405},
{x:37.129338,y:-113.518924},
{x:30.11231104,y:-93.82032664},
{x:32.87991717,y:-111.7274769},
{x:36.754274,y:-108.15755},
{x:34.63957028,y:-79.00528461},
{x:32.36452094,y:-89.47527812},
{x:30.34894587,y:-97.38525289},
{x:35.9139033,y:-86.82013088},
{x:36.11004788,y:-83.28435347},
{x:35.53256706,y:-82.86200078},
{x:33.23695595,y:-87.55403192},
{x:34.71439227,y:-98.4024085},
{x:33.86449588,y:-117.5438337},
{x:31.19088485,y:-94.78065603},
{x:37.6634625,y:-95.4528417},
{x:37.12212549,y:-93.46686848},
{x:35.49346009,y:-97.27185354},
{x:37.28633949,y:-85.90139871},
{x:32.75051887,y:-97.11027056},
{x:38.57078377,y:-94.85634462},
{x:39.14095226,y:-121.6296231},
{x:35.86821803,y:-83.55915114},
{x:40.42993576,y:-74.55700725},
{x:34.5939375,y:-98.4303421},
{x:33.7165873,y:-84.76532177},
{x:39.20898423,y:-94.68249589},
{x:33.61156355,y:-112.2031894},
{x:35.06528168,y:-80.99148922},
{x:29.98028285,y:-95.56819029},
{x:37.13234768,y:-94.47590814},
{x:28.71338025,y:-96.21479765},
{x:33.97878397,y:-81.31093984},
{x:38.9506069,y:-94.72305053},
{x:35.099,y:-77.1},
{x:30.68569447,y:-92.26209462},
{x:34.78894977,y:-92.27622651},
{x:34.91891633,y:-95.71711224},
{x:30.39991863,y:-90.1088588},
{x:35.21432381,y:-111.6130377},
{x:29.51001985,y:-98.39897879},
{x:37.26872515,y:-107.0517404},
{x:35.16120979,y:-89.79557009},
{x:34.04602362,y:-94.32756101},
{x:39.98147025,y:-74.81097691},
{x:34.08745135,y:-84.48769929},
{x:35.51088271,y:-97.59540774},
{x:33.6554036,y:-96.60609372},
{x:30.28618543,y:-81.53125989},
{x:32.21590216,y:-98.21089181},
{x:28.62346866,y:-96.63809852},
{x:30.14106805,y:-81.76978648},
{x:35.93624676,y:-75.61354122},
{x:37.9961417,y:-89.23431382},
{x:41.48598491,y:-112.0294266},
{x:42.490801,y:-90.699699},
{x:30.23486552,y:-90.91151655},
{x:32.91388352,y:-96.81788656},
{x:28.00700752,y:-81.72625154},
{x:32.96395037,y:-102.8230418},
{x:34.01079934,y:-85.98917735},
{x:39.068002,y:-96.287882},
{x:29.5621288,y:-98.47827227},
{x:39.49879231,y:-121.569183},
{x:34.49325624,y:-93.08743515},
{x:40.60053946,y:-74.4806783},
{x:35.56454307,y:-97.63800171},
{x:28.3943,y:-80.7041},
{x:30.15029372,y:-92.09513583},
{x:35.07044733,y:-106.6569458},
{x:30.46060906,y:-84.36170362},
{x:33.60299049,y:-88.65851443},
{x:32.73270071,y:-79.96980295},
{x:41.30626752,y:-95.95729284},
{x:38.70463534,y:-93.23086005},
{x:36.00622064,y:-95.88721501},
{x:29.81942679,y:-94.9003568},
{x:32.72416358,y:-97.14872387},
{x:35.972443,y:-83.983923},
{x:37.07547752,y:-94.64398071},
{x:31.80371443,y:-97.09929698},
{x:36.22682061,y:-119.3303043},
{x:29.10063327,y:-97.28707087},
{x:35.14853739,y:-84.83911738},
{x:32.2256117,y:-95.8824067},
{x:33.11826529,y:-94.18164299},
{x:32.35423549,y:-95.34397247},
{x:30.14221283,y:-91.82348036},
{x:33.039004,y:-111.378258},
{x:35.29238654,y:-89.67554306},
{x:32.7104149,y:-102.6452838},
{x:30.06238543,y:-99.12281447},
{x:37.6934985,y:-97.27296974},
{x:37.72569288,y:-89.88499736},
{x:43.11955382,y:-76.14300952},
{x:38.76695231,y:-93.73504311},
{x:32.50786058,y:-94.79876563},
{x:37.57679903,y:-97.13474371},
{x:37.02488536,y:-93.21463584},
{x:36.11719181,y:-97.05075838},
{x:40.02712988,y:-83.02701429},
{x:41.54524476,y:-83.58979002},
{x:35.4262078,y:-96.29915648},
{x:36.19279128,y:-86.83061063},
{x:36.20808726,y:-86.76808566},
{x:32.78001693,y:-92.40586169},
{x:35.56699227,y:-77.35206351},
{x:32.66399737,y:-85.36464422},
{x:35.15668998,y:-103.724362},
{x:32.45904454,y:-96.94201291},
{x:31.59348739,y:-97.096913},
{x:39.33189302,y:-82.08991014},
{x:29.69055439,y:-96.53936719},
{x:29.38002754,y:-98.51371991},
{x:36.09405314,y:-95.88602856},
{x:33.07821509,y:-97.56356791},
{x:35.27054348,y:-87.3196614},
{x:32.9038113,y:-96.12683125},
{x:36.95407598,y:-81.07598618},
{x:33.65410875,y:-112.1323885},
{x:35.93858352,y:-79.996617},
{x:37.33956642,y:-92.92685197},
{x:40.12246153,y:-111.6551726},
{x:35.81572525,y:-83.57877486},
{x:31.57370033,y:-94.67322801},
{x:35.63754786,y:-97.55007371},
{x:32.86137678,y:-91.37933135},
{x:33.36095157,y:-86.99926794},
{x:30.41554453,y:-90.04170835},
{x:40.74399341,y:-92.86847652},
{x:35.83232329,y:-86.37555913},
{x:41.2497988,y:-75.845079},
{x:30.70521718,y:-95.54504695},
{x:31.8193175,y:-106.5396957},
{x:36.01753505,y:-83.4244652},
{x:38.72104779,y:-75.58916152},
{x:35.56475791,y:-90.72401136},
{x:29.56861994,y:-98.23577508},
{x:33.54550581,y:-86.54699519},
{x:32.80976654,y:-97.42496878},
{x:37.67997816,y:-97.4428913},
{x:37.08697476,y:-76.39586806},
{x:33.17346149,y:-96.49535805},
{x:35.96840538,y:-83.19958494},
{x:34.76326999,y:-86.59276843},
{x:36.51428,y:-87.311375},
{x:30.00499069,y:-90.17325059},
{x:37.26916603,y:-95.56756318},
{x:31.81170251,y:-99.42357928},
{x:31.71530183,y:-89.14861456},
{x:36.69395616,y:-93.37103622},
{x:34.50627378,y:-97.11531403},
{x:34.66984394,y:-86.53980486},
{x:31.55148615,y:-91.37121768},
{x:30.10886295,y:-92.01123164},
{x:31.67775152,y:-88.64126717},
{x:32.7727315,y:-97.79777169},
{x:27.98408683,y:-82.12229276},
{x:38.97786883,y:-89.10066471},
{x:36.97916777,y:-120.0859838},
{x:34.10743526,y:-83.87293943},
{x:34.20477418,y:-92.00651857},
{x:37.17055915,y:-94.84461397},
{x:30.22659921,y:-93.32626641},
{x:35.42706667,y:-97.65452027},
{x:30.2372085,y:-92.662877},
{x:32.43351685,y:-81.75748654},
{x:34.50673021,y:-96.98445283},
{x:29.930796,y:-95.1759},
{x:42.21502742,y:-85.59248668},
{x:35.06007626,y:-78.97302904},
{x:31.97473568,y:-102.0743045},
{x:27.87837661,y:-97.20130608},
{x:36.37959877,y:-97.88956225},
{x:39.00420718,y:-94.46304023},
{x:38.23209045,y:-104.6618215},
{x:38.35564995,y:-90.15300326},
{x:30.18476578,y:-97.84975358},
{x:30.11849275,y:-93.74820337},
{x:33.34374714,y:-96.55141823},
{x:36.06338171,y:-79.82012721},
{x:33.7590201,y:-78.9647799},
{x:27.54286817,y:-82.47584442},
{x:47.71506835,y:-116.8936864},
{x:43.62001907,y:-116.3671462},
{x:33.06644867,y:-89.58269791},
{x:27.68286796,y:-97.38601909},
{x:30.24796344,y:-81.74676508},
{x:31.56792434,y:-93.48667927},
{x:39.280065,y:-76.861813},
{x:38.33337343,y:-104.7441023},
{x:35.57670928,y:-92.17647046},
{x:28.17287952,y:-80.6715585},
{x:37.57575085,y:-101.3533279},
{x:37.82080224,y:-94.70776661},
{x:32.47307987,y:-100.4008623},
{x:27.54935823,y:-99.48133127},
{x:38.49992805,y:-94.93851802},
{x:43.60887855,y:-116.5910997},
{x:28.8924167,y:-82.58526},
{x:27.24374421,y:-80.27110555},
{x:32.60686037,y:-90.06614327},
{x:35.0396904,y:-81.97571978},
{x:29.77994007,y:-95.6070292},
{x:32.43896344,y:-93.78266506},
{x:35.99740414,y:-86.8850474},
{x:27.433712,y:-82.575979},
{x:33.82310784,y:-92.42536983},
{x:31.07480685,y:-97.65292906},
{x:39.67685103,y:-84.23999906},
{x:40.56844439,y:-79.80304196},
{x:39.10176422,y:-94.43707108},
{x:30.31796508,y:-89.82649326},
{x:33.55626931,y:-117.159107},
{x:38.98775258,y:-92.32432925},
{x:35.77816,y:-90.7436},
{x:38.94247953,y:-104.6041748},
{x:33.02070093,y:-96.7342262},
{x:32.94716037,y:-95.29531625},
{x:35.13665745,y:-106.6255606},
{x:33.14150747,y:-94.96881031},
{x:35.51887003,y:-78.31675845},
{x:34.54883021,y:-83.28023561},
{x:28.81743083,y:-97.85816163},
{x:38.00773235,y:-91.62085556},
{x:37.1352216,y:-85.97405926},
{x:32.53460642,y:-94.93042521},
{x:42.52538067,y:-70.99451267},
{x:36.1085611,y:-96.11633225},
{x:33.83456384,y:-78.67322177},
{x:36.39751911,y:-87.03025565},
{x:34.82921031,y:-92.39126579},
{x:32.57240722,y:-85.49904071},
{x:33.419068,y:-110.81779},
{x:33.909239,y:-81.223693},
{x:33.10747467,y:-96.10899925},
{x:33.01227021,y:-96.61410573},
{x:33.4567174,y:-96.74055957},
{x:36.54554634,y:-119.3997172},
{x:36.82646252,y:-108.0125785},
{x:32.83795909,y:-116.9685726},
{x:39.11679487,y:-94.63335409},
{x:35.21022905,y:-86.09616615},
{x:34.83148685,y:-92.18504951},
{x:32.64934395,y:-97.34905019},
{x:34.07198427,y:-106.8939746},
{x:29.81463648,y:-90.81473575},
{x:33.15291025,y:-96.88905404},
{x:33.59112677,y:-102.3680078},
{x:30.98781896,y:-94.8262462},
{x:33.09881967,y:-83.24479654},
{x:39.82655849,y:-76.98785641},
{x:31.48373951,y:-97.20831134},
{x:35.8283946,y:-94.6315746},
{x:32.9191126,y:-96.51844876},
{x:41.70917874,y:-86.18227177},
{x:33.9619679,y:-86.46104716},
{x:33.96677865,y:-88.45720009},
{x:30.18,y:-97.6143},
{x:33.37826029,y:-112.0696686},
{x:36.37617595,y:-86.47940755},
{x:40.36544336,y:-80.1096291},
{x:36.87999177,y:-89.5795396},
{x:38.97114385,y:-92.30373591},
{x:30.01272745,y:-95.45207262},
{x:34.959877,y:-96.75926328},
{x:35.1466,y:-101.9366},
{x:31.48528346,y:-100.4627697},
{x:32.46536774,y:-91.75959014},
{x:35.61286669,y:-82.63327271},
{x:33.58114335,y:-86.27744392},
{x:33.06879564,y:-96.89016394},
{x:30.75608728,y:-81.57664043},
{x:28.44964356,y:-99.24144373},
{x:26.2734,y:-80.2059},
{x:34.97301907,y:-92.01868951},
{x:28.05824575,y:-81.7883467},
{x:35.22797479,y:-97.44073816},
{x:39.74391515,y:-92.46542082},
{x:29.63650567,y:-95.61826825},
{x:33.22145231,y:-92.66510372},
{x:35.60417897,y:-97.56665312},
{x:36.42405597,y:-89.69157682},
{x:35.06781503,y:-92.44358093},
{x:33.44827479,y:-79.12132665},
{x:38.85413211,y:-94.68401805},
{x:35.14128325,y:-93.90255511},
{x:30.41902,y:-91.17658},
{x:41.11616267,y:-104.8022397},
{x:36.48159649,y:-95.0598827},
{x:28.26608399,y:-82.18881458},
{x:33.17014875,y:-99.73325015},
{x:40.743813,y:-73.927734},
{x:36.32155568,y:-119.6736029},
{x:37.79201765,y:-90.43355294},
{x:40.14682291,y:-82.98914686},
{x:41.1792,y:-96.1018},
{x:33.1013028,y:-96.69958548},
{x:38.92649371,y:-94.58613888},
{x:38.84150444,y:-104.7669353},
{x:33.11939966,y:-97.02897397},
{x:38.44062037,y:-90.21248149},
{x:33.93745313,y:-87.84058332},
{x:30.40418667,y:-92.21650511},
{x:33.04313138,y:-97.02004709},
{x:32.44992572,y:-99.70664889},
{x:35.82876781,y:-90.66515432},
{x:36.81971655,y:-76.38278291},
{x:33.71330091,y:-84.35012654},
{x:30.31185468,y:-95.46204575},
{x:32.4111361,y:-91.18556321},
{x:30.12911949,y:-95.44275329},
{x:33.99165405,y:-83.72520939},
{x:32.97139215,y:-96.88922033},
{x:31.31488937,y:-94.72726331},
{x:45.30360106,y:-93.55674595},
{x:30.3539068,y:-96.53553502},
{x:35.2846842,y:-93.15734893},
{x:34.72907595,y:-112.0157749},
{x:30.0614499,y:-90.70075862},
{x:32.619204,y:-83.690475},
{x:29.67984121,y:-97.64376328},
{x:30.1841536,y:-84.37445685},
{x:36.88449716,y:-104.436805},
{x:37.83682074,y:-94.32973043},
{x:33.3675098,y:-81.99571431},
{x:30.54070778,y:-97.56417543},
{x:32.61429501,y:-97.41552719},
{x:32.53142375,y:-96.80519142},
{x:29.4302,y:-98.7479},
{x:35.09154951,y:-92.40159094},
{x:35.84477207,y:-90.69026564},
{x:40.058007,y:-95.60206578},
{x:33.20098614,y:-87.57705179},
{x:39.18762239,y:-94.51896631},
{x:34.134,y:-117.391},
{x:29.79341243,y:-95.7752654},
{x:30.81352946,y:-95.10919963},
{x:34.25812434,y:-83.46597242},
{x:33.92503079,y:-93.15130495},
{x:34.60990392,y:-111.8605359},
{x:30.61873944,y:-96.31395288},
{x:42.3298,y:-83.162},
{x:37.05542573,y:-100.9228376},
{x:36.1795579,y:-95.97517983},
{x:38.9814363,y:-94.36094262},
{x:47.13223537,y:-122.2924796},
{x:28.52432246,y:-99.85637851}];