import { ActionTypes } from '../actions/fileupload';

const initialState = {
  isUploadingFiles: false,
  filesUploaded: false,
  uploadError: false,
  uploadResponse: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_FILES:
      return {
        ...state,
        isUploadingFiles: true,
        filesUploaded: false,
        uploadError: false,
      };
    case ActionTypes.UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        isUploadingFiles: false,
        uploadResponse: action,
        filesUploaded: true,
        uploadError: false,
      };
    case ActionTypes.UPLOAD_FILES_FAIL:
      return {
        ...state,
        isUploadingFiles: false,
        filesUploaded: false,
        uploadError: action.error,
      };
    default:
      return state;
  }
};
