import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './SearchInput.scss';
import { useDebouncedCallback } from '../../../hooks/useDebounce';

const SearchInput = ({
  searchText,
  placeholder,
  count,
  onSearch,
  onClearClickedFilterValue,
}) => {
  const [query, setQuery] = useState(searchText);

  const onSearchDebounced = useDebouncedCallback(onSearch, 100);

  useEffect(() => {
    setQuery(searchText);
    onClearClickedFilterValue();
  }, [searchText]);

  useEffect(() => {
    onSearchDebounced(query);
  }, [onSearchDebounced, query]);

  return (
    <InputGroup className="search-box">
      <InputGroup.Prepend>
        <InputGroup.Text id="search-input">
          <FontAwesomeIcon className="search-icon" icon={faSearch} />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        placeholder={placeholder || 'Search...'}
        aria-label={placeholder || 'Search...'}
        aria-describedby="search-input"
        type="text"
        as="input"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <InputGroup.Append>
        <InputGroup.Text className="clear-button" onClick={() => setQuery('')}>
          <FontAwesomeIcon
            className="site-header-nav-icon"
            icon={faTimesCircle}
          />
        </InputGroup.Text>
      </InputGroup.Append>
      {searchText ? (
        <InputGroup.Append>
          <InputGroup.Text id="search-input-2" className="result-counter">
            <span>{count || 0}</span>&nbsp;
            {`Result${count !== 1 ? 's' : ''}`}
          </InputGroup.Text>
        </InputGroup.Append>
      ) : null}
    </InputGroup>
  );
};

export default SearchInput;
