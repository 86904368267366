import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MM from '@mantine/modals';
import { formatDate } from 'hooks/utils';
import { IconTrash } from '@tabler/icons-react';

import type { SchoolYear } from 'types';

interface Props {
  schoolYears: SchoolYear[];
  deleteSchoolYear: (id: string) => void;
  onEdit: (index: number) => void;
  onAdd: () => void;
}

const SchoolYearTable = ({
  schoolYears,
  deleteSchoolYear,
  onEdit,
  onAdd,
}: Props) => {
  const handleDelete = ({ id, startDate, endDate }: SchoolYear) =>
    MM.openConfirmModal({
      title: 'Are you sure you want to delete the following school year?',
      children: (
        <M.Text align="center">
          {formatDate(startDate)} - {formatDate(endDate)}
        </M.Text>
      ),
      labels: {
        confirm: 'Yes, delete it',
        cancel: 'Nevermind',
      },
      onConfirm: () => deleteSchoolYear(id),
    });

  return (
    <Table>
      <thead>
        <tr>
          <th>Start</th>
          <th>End</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {schoolYears.map((sy, i) => (
          <tr key={sy.id}>
            <td onClick={() => onEdit(i)}>{formatDate(sy.startDate)}</td>
            <td onClick={() => onEdit(i)}>{formatDate(sy.endDate)}</td>
            <td>
              <Action onClick={() => handleDelete(sy)}>
                <IconTrash />
              </Action>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3} style={{ textAlign: 'center' }}>
            <M.Button onClick={onAdd}>Add school year</M.Button>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

const Table = styled(M.Table)`
  width: fit-content;

  thead tr th {
    text-align: center;
  }

  tbody {
    tr td {
      padding: 0.5rem 3rem;
      text-align: center;
      cursor: pointer;

      &:last-child {
        cursor: unset;
      }
    }
  }

  tfoot tr td {
    padding-top: ${(p) => p.theme.spacing.xs};
  }
`;

const Action = styled.span`
  &:hover {
    cursor: pointer;

    svg {
      color: ${(p) => p.theme.colors.red[7]};
    }
  }
`;

export default SchoolYearTable;
