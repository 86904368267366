import styled from '@emotion/styled';
import * as M from '@mantine/core';

import EditTimeRange from '../EditTimeRange';
import { CUSTOM_DAY_TYPE, PeriodsBuilderProps } from './PeriodsBuilder';
import {
  filterPeriodTypesByDayTypeId,
  useFilteredPeriodTypes,
} from './useFilteredPeriodTypes';
import { scheduleUtils } from 'features/schedules/utils/schedule';
import { CoreSchedule } from 'types';
import { useSchool } from 'hooks/useSchool';
import { useFilteredDayTypes } from './useFilteredDayTypes';

type Props = {
  customPeriodNames: string[];
  setCustomPeriodNames: React.Dispatch<React.SetStateAction<string[]>>;
  editIndex: number;
};

const EditPeriodPopoverDropdown = ({
  dayTypes,
  periodTypes: initialPeriodTypes,
  periods,
  setPeriods,
  editIndex,
  customPeriodNames,
  schedule,
  setCustomPeriodNames,
}: Omit<PeriodsBuilderProps, 'defaultDayType'> & Props) => {
  const { data: schedules } = useSchool((school) => school.schedules);

  const { filteredPeriodTypes: periodTypes } = useFilteredPeriodTypes({
    customPeriodNames,
    periodTypes: initialPeriodTypes,
    schedule,
    selectedDayType: periods[editIndex].dayType ?? CUSTOM_DAY_TYPE,
  });

  const fullDayTypes = useFilteredDayTypes({
    customPeriodNames,
    dayTypes,
    schedule,
  });

  const onDayTypeChange = (value: string | null) => {
    const nextDT = fullDayTypes.find((dt) => dt.name === value);

    if (!nextDT || !schedules) return;

    setPeriods((prev) =>
      prev.map((p, i) => {
        if (i !== editIndex) return p;

        if (nextDT.id === CUSTOM_DAY_TYPE.id) {
          return {
            ...p,
            name: customPeriodNames[0],
            dayType: undefined,
            periodType: undefined,
          };
        }
        const periodTypesForNextDT = filterPeriodTypesByDayTypeId(
          schedules,
          nextDT.id,
        );

        const foundPT = periodTypesForNextDT.find((pt) => pt.name === p.name);

        if (!periodTypesForNextDT.length) {
          console.error(`no period types for next DT ${nextDT.name}`);
          return p;
        }

        return {
          ...p,
          dayType: nextDT,
          periodType: foundPT ?? periodTypesForNextDT[0],
          name: foundPT?.name ?? periodTypesForNextDT[0].name,
        };
      }),
    );
  };

  const onPeriodTypeChange = (value: string | null) => {
    const nextPT = periodTypes.find((pt) => pt.name === value);

    if (!nextPT || !schedules) return;

    setPeriods((prev) =>
      prev.map((p, i) => {
        if (i !== editIndex) return p;

        // CUSTOM -> CUSTOM || REGULAR -> CUSTOM
        if (nextPT.id === '') {
          return {
            ...p,
            name: nextPT.name,
            periodType: undefined,
            dayType: undefined,
          };
        }

        // If ACF schedule, leave DT as is
        if (scheduleUtils.isAcfSchedule(schedule)) {
          return {
            ...p,
            periodType: nextPT,
            name: nextPT.name,
            dayType: schedule.dayType,
          };
        }

        const foundPTinSelectedDT = p.dayType
          ? filterPeriodTypesByDayTypeId(schedules, p.dayType.id).find(
              (pt) => pt.name === nextPT.name,
            )
          : undefined;

        // leave DT if PT is found for currect DT
        if (foundPTinSelectedDT && p.dayType) {
          return {
            ...p,
            periodType: nextPT,
            name: nextPT.name,
            dayType: p.dayType,
          };
        }

        const firstMatchingDT = schedules.find(
          (s): s is CoreSchedule =>
            scheduleUtils.isAcfSchedule(s) &&
            s.periods.some((p) => p.periodType?.id === nextPT.id),
        )?.dayType;

        if (!firstMatchingDT) {
          console.error('no day type matches period');
          return p;
        }

        // change DT to first matching DT
        return {
          ...p,
          periodType: nextPT,
          name: nextPT.name,
          dayType: firstMatchingDT,
        };
      }),
    );
  };

  const onPeriodTypeCreate = (value: string) => {
    setCustomPeriodNames((prev) => [...prev, value]);

    setPeriods((prev) =>
      prev.map((p, i) =>
        i === editIndex
          ? {
              ...p,
              name: value,
              periodType: undefined,
              dayType: undefined,
            }
          : p,
      ),
    );

    return value;
  };

  return (
    <M.Popover.Dropdown>
      <Row>
        <M.Select
          disabled={scheduleUtils.isAcfSchedule(schedule)}
          label="Day type"
          data={fullDayTypes.map((dt) => dt.name)}
          value={periods[editIndex].dayType?.name ?? CUSTOM_DAY_TYPE.name}
          onChange={onDayTypeChange}
        />
        <M.Select
          label="Period type"
          data={periodTypes.map((p) => p.name)}
          value={periods[editIndex].name}
          onChange={onPeriodTypeChange}
          maxDropdownHeight={360}
          searchable
          creatable
          getCreateLabel={(value) => `Create "${value}" period type`}
          onCreate={onPeriodTypeCreate}
        />
        <EditTimeRange
          times={periods}
          setTimes={setPeriods}
          editIndex={editIndex}
        />
      </Row>
    </M.Popover.Dropdown>
  );
};

const Row = styled(M.Flex)`
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.md};
`;

export default EditPeriodPopoverDropdown;
