import { ActionTypes } from '../actions/auth';

const initialState = {
  token: null,
  authenticated: false,
  isAuthenticating: false,
  isLogout: false,
  authError: null,
  userValidated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_VERIFY_TOKEN:
      return {
        ...state,
        token: null,
      };
    case ActionTypes.AUTH_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        authenticated: true,
        scopes: action.scopes,
      };
    case ActionTypes.AUTH_VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        token: null,
        authenticated: false,
        authError: action.error,
      };
    case ActionTypes.AUTH_VALIDATE_USER_AUTH:
      return {
        ...state,
        userValidated: false,
        validatingUser: true,
        isAuthenticating: true,
      };
    case ActionTypes.AUTH_VALIDATE_USER_AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
        authenticated: true,
        userValidated: true,
        validatingUser: false,
        isAuthenticating: false,
        scopes: action.scopes,
        schoolId: action.schoolId,
      };
    case ActionTypes.AUTH_VALIDATE_USER_AUTH_FAIL:
      return {
        ...state,
        token: null,
        authenticated: false,
        userValidated: true,
        validatingUser: false,
        authError: action.error,
        isAuthenticating: false,
      };
    case ActionTypes.AUTH_USER_LOGOUT:
      return {
        ...state,
        isLogout: true,
      };
    case ActionTypes.AUTH_USER_LOGOUT_SUCCESS:
      return {};
    case ActionTypes.AUTH_USER_LOGOUT_FAIL:
      return {
        ...state,
        isLogout: false,
        logoutError: action.error,
      };
    default:
      return state;
  }
};
