import { all } from 'redux-saga/effects';
import schoolsSaga from './schools';
import fileUploadSaga from './fileupload';
import authSaga from './auth';
import networkSaga from './network';
import reportsSaga from './reports';
import miscSaga from './misc';
import schedulesSaga from './schedules';

export default function* rootSaga() {
  yield all([
    ...schoolsSaga,
    ...fileUploadSaga,
    ...authSaga,
    ...networkSaga,
    ...reportsSaga,
    ...miscSaga,
    ...schedulesSaga,
  ]);
}
