import { useMemo } from 'react';

type CallbackFn = (...args: unknown[]) => void | Promise<void>;

function debounce<T extends CallbackFn>(cb: T, wait = 200) {
  let timeoutRef: NodeJS.Timeout;
  const callable = (...args: unknown[]) => {
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => cb(...args), wait);
  };
  return callable;
}

export function useDebouncedCallback<T extends CallbackFn>(
  callback: T,
  wait?: number,
) {
  return useMemo(() => debounce(callback, wait), [callback, wait]);
}
