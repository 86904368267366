import apiClient from 'utils/apiClient';

const baseUrl = 'v3/schedules';

const getSchedulingSettings = (school_id) =>
  apiClient.get(`${baseUrl}/settings/${school_id}`);

const createDayType = (data) => apiClient.post(`${baseUrl}/day_types`, data);

const createPeriodType = (data) =>
  apiClient.post(`${baseUrl}/period_types`, data);

const updateDayType = ({ id, name }) =>
  apiClient.patch(`${baseUrl}/day_types/${id}`, { name });

const updatePeriodType = ({ id, name }) =>
  apiClient.patch(`${baseUrl}/period_types/${id}`, {
    name,
  });

const deleteDayType = ({ id }) =>
  apiClient.delete(`${baseUrl}/day_types/${id}`);

const deletePeriodType = ({ id }) =>
  apiClient.delete(`${baseUrl}/period_types/${id}`);

const getSchedules = (school_id) => apiClient.get(`${baseUrl}/${school_id}`);

const setSchedule = (school_id, date, schedule_id, schedule_name) =>
  apiClient.patch(
    `${baseUrl}/calendar/${school_id}/${date}/schedule/${schedule_id}`,
    { custom_schedule: schedule_name },
  );

const cancelSchool = (school_id, date) =>
  apiClient.delete(`${baseUrl}/calendar/${school_id}/${date}`);

const updateSchedule = (schedule_id, form_data) =>
  apiClient.patch(`${baseUrl}/${schedule_id}`, form_data);

const createSchoolYear = (data) =>
  apiClient.post(`v3/calendar/school_year`, data);

const updateSchoolYear = (schoolYearId, data) =>
  apiClient.patch(`v3/calendar/school_year/${schoolYearId}`, data);

const deleteSchoolYear = (schoolYearId) =>
  apiClient.delete(`v3/calendar/school_year/${schoolYearId}`);

const bulkInsertCalendarData = (schoolId, data) =>
  apiClient.put(`${baseUrl}/calendar/${schoolId}`, data);

const schedulesService = () => ({
  getSchedulingSettings,
  createDayType,
  createPeriodType,
  updateDayType,
  updatePeriodType,
  deleteDayType,
  deletePeriodType,
  getSchedules,
  setSchedule,
  cancelSchool,
  updateSchedule,
  createSchoolYear,
  updateSchoolYear,
  deleteSchoolYear,
  bulkInsertCalendarData,
});

export default schedulesService;
