import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { LunchSlot, LunchWave } from 'types';

import EditTimeRange from '../EditTimeRange';

interface Props {
  slots: LunchSlot[];
  isAlphabetic: boolean;
  lunchWaves: LunchWave[];
  setLunchWaves: React.Dispatch<React.SetStateAction<LunchWave[]>>;
  editIndex: number;
}

const EditLunchWavePopoverDropdown = ({
  slots,
  isAlphabetic,
  lunchWaves,
  setLunchWaves,
  editIndex,
}: Props) => {
  const getSlotName = (slot: LunchSlot) =>
    isAlphabetic ? String.fromCharCode(65 + slot.slot - 1) : String(slot.slot);

  return (
    <M.Popover.Dropdown>
      <Row>
        <M.Select
          label="Wave"
          data={slots.map(getSlotName)}
          value={getSlotName(lunchWaves[editIndex].slot)}
          onChange={(value) =>
            value &&
            setLunchWaves((prev) =>
              prev.map((w, i) =>
                i === editIndex
                  ? {
                      ...w,
                      name: value,
                      slot: slots.find((slot) => getSlotName(slot) === value)!,
                    }
                  : w,
              ),
            )
          }
        />
        <EditTimeRange
          times={lunchWaves}
          setTimes={setLunchWaves}
          editIndex={editIndex}
        />
      </Row>
    </M.Popover.Dropdown>
  );
};

const Row = styled(M.Flex)`
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.md};
`;

export default EditLunchWavePopoverDropdown;
