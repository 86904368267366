export const colors = {
  '#F82A60': 'Red',
  '#FD793C': 'Orange',
  '#9CD9FB': 'Light Blue',
  '#A61E3F': 'Maroon',
  '#FFC942': 'Yellow',
  '#D1A73A': 'Gold',
  '#13B87C': 'Green',
  '#397BFA': 'Royal Blue',
  '#2750AE': 'Navy',
  '#444444': 'Black',
  '#B7B4B4': 'Gray',
  '#8B46FF': 'Purple',
  '#AF6622': 'Brown',
};
