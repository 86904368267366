import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

import ChipList from 'components/shared/DomainsList/ChipList';
import StatusMenu from 'components/shared/StatusMenu/StatusMenu';
import { timezoneAbbreviations } from 'constants/timezoneAbbreviations';

export const schoolIdFormatter = (cell, row) => {
  return (
    <div className="school-id" key={row.id}>
      <Link to={`/schools/${row.school ?? row.school_name}`}>{cell}</Link>
    </div>
  );
};

export const nameFormatter = (cell, row) => {
  return (
    <Link
      to={`/schools/${row.school ?? row.school_name}`}
      className="school-grid-name"
    >
      {cell}
    </Link>
  );
};

export const statusFormatter = (cell, row) => {
  return <StatusMenu school={row} currentStatus={row.status} />;
};

const domainsFormatter = (cell, row, onClick) => {
  return <ChipList domains={cell} onDomainClick={onClick} />;
};

const actionsFormatter = (cell, row, onClick) => {
  return (
    <div
      key={row.school ?? row.school_name}
      className="actions-column"
      onClick={() => onClick(row)}
    >
      <FontAwesomeIcon className="site-header-nav-icon" icon={faEdit} />
    </div>
  );
};

const districtFormatter = (cell, row, onClick) => {
  return cell ? (
    <div>
      <div className="school-id" onClick={() => onClick(cell)}>
        <OverlayTrigger overlay={<Tooltip>{cell}</Tooltip>}>
          <span>
            {row.district_school_count < 2 ? (
              <>
                <FontAwesomeIcon icon={faDotCircle} color="green" />
                &nbsp;
              </>
            ) : (
              ''
            )}
            {cell}
          </span>
        </OverlayTrigger>
      </div>
    </div>
  ) : row.county_code ? (
    <span>{row.county_code}</span>
  ) : (
    <span>--</span>
  );
};

export const getColumns = ({
  isOwner,
  isSchoolManager,
  onDistrictClick,
  onDomainClick,
  onActionsClick,
}) => {
  const columns = [
    {
      dataField: 'school_name',
      text: 'School ID',
      formatter: schoolIdFormatter,
      headerStyle: () => ({ width: '15%' }),
    },
    {
      dataField: 'school_title',
      text: 'Name',
      sort: true,
      formatter: nameFormatter,
      headerStyle: () => ({ width: '25%' }),
    },
    {
      dataField: 'district_code',
      text: 'District ID',
      sort: true,
      formatter: (cell, row) => districtFormatter(cell, row, onDistrictClick),
      headerStyle: () => ({ width: '18%' }),
    },
  ];

  if (isOwner) {
    columns.push({
      dataField: 'user_count',
      text: 'Installs',
      formatter: (cell, row) => {
        return row.meta.user_count;
      },
      sort: true,
      headerStyle: () => ({ width: '10%' }),
    });
  }

  columns.push(
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: statusFormatter,
      headerStyle: () => ({ width: '12%' }),
    },
    {
      dataField: 'timezone',
      text: 'Timezone',
      formatter: (cell, row) => {
        return (
          <OverlayTrigger overlay={<Tooltip>{cell}</Tooltip>}>
            <span>
              {timezoneAbbreviations[cell] || cell} ({row.state})
            </span>
          </OverlayTrigger>
        );
      },
      headerStyle: () => ({ width: '10%' }),
    },
    {
      dataField: 'domains',
      text: 'Domains',
      formatter: (cell, row) => domainsFormatter(cell, row, onDomainClick),
      headerStyle: () => ({ width: '25%' }),
    },
  );

  if (isSchoolManager) {
    columns.push({
      dummy: true,
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => actionsFormatter(cell, row, onActionsClick),
      headerStyle: () => ({ width: '10%', textAlign: 'center' }),
    });
  }

  return columns;
};
