import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import getTimezone from 'utils/getTimezone';

import FieldsContainer from '../../Fields/FieldsContainer';
import ModalLoading from '../ModalLoading/ModalLoading';
import ModalSuccess from '../ModalSuccess/ModalSuccess';
import './StepContainer.scss';

const StepContainer = ({
  modalData: {
    steps: initialSteps,
    onSubmit,
    getSuccessText,
    getErrorText,
    isConfirmationDialog,
  },
  close,
}) => {
  const [index, setIndex] = useState(0);
  const [steps, setSteps] = useState(initialSteps);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [form, setForm] = useState({});

  useEffect(() => {
    setSteps((prevSteps) =>
      prevSteps.map((step, i) =>
        index !== i
          ? step
          : {
              ...step,
              fields: step.fields.map((field) => {
                const value = form[field.prop];
                if (!value) {
                  return field;
                }

                return {
                  ...field,
                  currentValue: value,
                  valid: field.validators
                    ? field.validators.every((validate) => validate(value))
                    : true,
                };
              }),
            },
      ),
    );
  }, [index, form]);

  const isValid = steps[index].fields.every((f) => f.valid);
  const isFirstStep = index === 0;
  const isLastStep = index === steps.length - 1;

  const onPrevious = () => (isFirstStep ? close() : setIndex((i) => i - 1));

  const onNext = () => {
    if (!isValid) {
      setSteps((prevSteps) =>
        prevSteps.map((step, i) =>
          index !== i
            ? step
            : {
                ...step,
                fields: step.fields.map(blurField),
              },
        ),
      );
    } else if (isLastStep) {
      submitForm();
    } else {
      setIndex((i) => i + 1);
    }
  };

  const blurField = (field) => ({
    ...field,
    blurred: true,
    ...(field.subFields && {
      subFields: field.subFields.map((sf) => ({
        ...sf,
        blurred: true,
      })),
    }),
  });

  const submitForm = () => {
    setIsLoading(true);

    onSubmit(form)
      .then((result) => {
        setIsLoading(false);
        setIsSuccess(true);
        setSubmissionMessage(getSuccessText(result));
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSuccess(false);
        setSubmissionMessage(getErrorText(error));
      });
  };

  const updateField = (value, field) => {
    switch (field.type) {
      case 'GoogleAutoCompleteField':
        setForm((f) => ({ ...f, [field.prop]: value }));

        if (field.blurred) {
          geocodeByAddress(value)
            .then((res) =>
              getLatLng(res[0]).then(({ lat, lng }) => {
                setForm((f) => ({ ...f, lat, lng }));

                getTimezone(parseFloat(lat), parseFloat(lng)).then((tz) => {
                  if (tz) {
                    setForm((f) => ({ ...f, timezone: `${tz.id}` }));
                  }
                });
              }),
            )
            .catch((e) =>
              console.error(
                'Something happened at calculating longitude and latitude, creating school without coordinates',
                e,
              ),
            );
        }
        break;
      default:
        setForm((f) => ({ ...f, [field.prop]: value }));
    }
  };

  if (isLoading) {
    return <ModalLoading />;
  }

  if (submissionMessage) {
    return (
      <ModalSuccess
        isSuccess={isSuccess}
        close={close}
        message={submissionMessage}
      />
    );
  }

  return (
    <div className="step-container">
      <Modal.Header closeButton>
        <Modal.Title>{steps[index].name}</Modal.Title>
      </Modal.Header>

      <div className="step">
        <div className="current-step">
          <FieldsContainer
            fields={steps[index].fields.filter((f) =>
              f.isVisible === undefined ? true : f.isVisible,
            )}
            updateParent={updateField}
          />
        </div>
      </div>

      <Modal.Footer>
        <Button className="cancel-btn" onClick={onPrevious}>
          {isFirstStep ? 'Cancel' : 'Previous'}
        </Button>

        <Button
          variant={!isValid ? 'secondary' : isLastStep ? 'success' : 'primary'}
          className={!isValid ? 'disabled' : isLastStep ? '' : 'next-btn'}
          onClick={onNext}
        >
          {isLastStep ? (isConfirmationDialog ? 'Confirm' : 'Save') : 'Next'}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default StepContainer;
