import dayjs from 'dayjs';

import { useGetMutation } from 'hooks/utils';
import { DATE_SERVER_FORMAT } from 'constants/dates';
import type { SchoolResponse, SchoolYearResponse, SchoolYear } from 'types';

export const useSetRotationPatternMutation = (schoolId: string) =>
  useGetMutation<string[], SchoolResponse>({
    schoolId,
    method: 'put',
    expectedStatus: 200,
    getPath: () => `v2/schools/${schoolId}`,
    getBody: (rotationPattern) => ({ rotationPattern }),
    update: (school, responseData) => ({
      ...school,
      rotationPattern: responseData.rotationPattern as unknown as string[],
    }),
  });

export const useCreateSchoolYearMutation = (schoolId: string) =>
  useGetMutation<[Date, Date], SchoolYearResponse>({
    schoolId,
    method: 'post',
    expectedStatus: 200,
    getPath: () => 'v3/calendar/school_year',
    getBody: ([start, end]) => ({
      schoolId,
      current: true,
      startDate: dayjs(start).format(DATE_SERVER_FORMAT),
      endDate: dayjs(end).format(DATE_SERVER_FORMAT),
    }),
    update: (school, responseData) => ({
      ...school,
      schoolYears: [
        ...school.schoolYears,
        {
          id: responseData.id!,
          startDate: dayjs(responseData.startDate).toDate(),
          endDate: dayjs(responseData.endDate).toDate(),
          isCurrent: responseData.current!,
        },
      ],
    }),
  });

export const useUpdateSchoolYearMutation = (schoolId: string) =>
  useGetMutation<SchoolYear, SchoolYearResponse>({
    schoolId,
    method: 'patch',
    expectedStatus: 200,
    getPath: ({ id }) => `v3/calendar/school_year/${id}`,
    getBody: ({ startDate, endDate, isCurrent }) => ({
      startDate: dayjs(startDate).format(DATE_SERVER_FORMAT),
      endDate: dayjs(endDate).format(DATE_SERVER_FORMAT),
      current: isCurrent,
    }),
    update: (school, responseData) => ({
      ...school,
      schoolYears: school.schoolYears.map((sy) =>
        sy.id !== responseData.id
          ? sy
          : {
              ...sy,
              startDate: dayjs(responseData.startDate).toDate(),
              endDate: dayjs(responseData.endDate).toDate(),
            },
      ),
    }),
  });

export const useDeleteSchoolYearMutation = (schoolId: string) =>
  useGetMutation<string, void>({
    schoolId,
    method: 'delete',
    expectedStatus: 204,
    getPath: (id) => `v3/calendar/school_year/${id}`,
    getBody: () => ({}),
    update: (school, responseData, id) => ({
      ...school,
      schoolYears: school.schoolYears.filter((sy) => sy.id !== id),
    }),
  });
