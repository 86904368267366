import { useSelector } from 'react-redux';
import { hasAccessTo } from 'store/selectors/auth';

export const useIsOwner = () => {
  const isOwner = useSelector((state) =>
    hasAccessTo((state as any).auth.scopes, ['owner']),
  );

  return isOwner;
};
