import { useState, useEffect } from 'react';

import { useWizard } from 'features/scheduleConfiguration/components/wizard/Wizard';
import ScheduleSettings from 'features/scheduleSettings';
import type { StepComponent } from 'features/scheduleConfiguration/types';

const ScheduleSettingsStep: StepComponent = ({ school, stepIndex }) => {
  const wizard = useWizard();

  const [isUnitLunchLocallySelected, setIsUnitLunchLocallySelected] =
    useState(false);

  useEffect(() => {
    if (!school.scheduleType) {
      return;
    }

    wizard.update(stepIndex, {
      type: 'scheduleSettingsStep',
      payload: {
        scheduleType: school.scheduleType,
        dayTypes: school.dayTypes,
        periodTypes: school.periodTypes,
        lunchSettings: school.lunchSettings,
      },
      isValid:
        !!school.dayTypes.length &&
        !!school.periodTypes.length &&
        (!!school.lunchSettings.type || isUnitLunchLocallySelected),
    });
  }, [
    school.scheduleType,
    school.dayTypes,
    school.periodTypes,
    school.lunchSettings,
    isUnitLunchLocallySelected,
  ]);

  return (
    <ScheduleSettings
      school={school}
      isUnitLunchLocallySelected={isUnitLunchLocallySelected}
      setIsUnitLunchLocallySelected={setIsUnitLunchLocallySelected}
    />
  );
};

export default ScheduleSettingsStep;
