import apiClient from 'utils/apiClient';

const createTeacher = (schoolId, teacherData) =>
  apiClient.post(`v2/schools/${schoolId}/staff`, teacherData);

const updateTeacher = (schoolId, teacherId, teacherData, newValue) =>
  apiClient.put(`v2/schools/${schoolId}/staff/${teacherId}`, {
    ...teacherData,
    name: newValue,
  });

const removeTeacher = (schoolId, teacherId) =>
  apiClient.delete(`v2/schools/${schoolId}/staff/${teacherId}`);

const teachersService = () => ({ createTeacher, updateTeacher, removeTeacher });

export default teachersService;
