export default [{x:41.2922,y:-72.9627},
{x:41.5544,y:-72.6765},
{x:36.1163,y:-115.1829},
{x:36.1438,y:-115.1183},
{x:38.6169,y:-83.8097},
{x:45.6577,y:-68.6893},
{x:44.7812,y:-68.7458},
{x:43.2655,y:-71.5867},
{x:29.6423,y:-95.2433},
{x:43.0409,y:-83.3212},
{x:32.864,y:-96.6452},
{x:42.104,y:-75.932},
{x:43.6422,y:-84.249},
{x:36.9183,y:-76.1965},
{x:43.561,y:-83.9456},
{x:26.2042,y:-80.1091},
{x:33.9802,y:-88.4763},
{x:38.4585,y:-81.4998},
{x:40.9534,y:-73.8577},
{x:21.2781,y:-157.7548},
{x:38.8226,y:-91.1368},
{x:37.3856,y:-79.2418},
{x:27.997,y:-80.635},
{x:39.1647,y:-86.578},
{x:33.2193,y:-97.5962},
{x:32.7206,y:-97.1144},
{x:39.7915,y:-88.2756},
{x:41.9684,y:-87.7612},
{x:41.799,y:-87.592},
{x:33.642,y:-97.136},
{x:42.3587,y:-83.4569},
{x:44.0848,y:-121.3066},
{x:42.4636,y:-96.4137},
{x:41.683,y:-87.642},
{x:27.5556,y:-99.5045},
{x:46.2636,y:-96.6022},
{x:41.44,y:-82.1858},
{x:44.3127,y:-96.7735},
{x:47.6575,y:-117.2335},
{x:41.0142,y:-93.3196},
{x:41.3097,y:-105.5746},
{x:37.9959,y:-89.2367},
{x:37.7906,y:-90.4378},
{x:32.804,y:-117.007},
{x:34.2536,y:-118.8107},
{x:26.4595,y:-80.0942},
{x:36.3424,y:-119.6564},
{x:32.8026,y:-97.2651},
{x:32.6735,y:-97.008},
{x:44.3154,y:-93.9651},
{x:38.6046,y:-95.2689},
{x:40.6407,y:-75.429},
{x:40.2199,y:-85.4283},
{x:44.5306,y:-88.0613},
{x:41.0181,y:-92.4093},
{x:33.97,y:-118.1658},
{x:32.357,y:-86.2804},
{x:31.5641,y:-84.1759},
{x:32.4541,y:-84.9393},
{x:47.122,y:-88.5653},
{x:39.0289,y:-95.7475},
{x:30.3343,y:-87.1406},
{x:30.501,y:-84.3291},
{x:39.4773,y:-119.784},
{x:46.8999,y:-98.6987},
{x:39.1781,y:-77.2722},
{x:36.0388,y:-78.8936},
{x:33.9617,y:-118.0337},
{x:34.6203,y:-89.9547},
{x:30.4179,y:-91.1773},
{x:35.2495,y:-82.2035},
{x:44.5391,y:-68.4162},
{x:41.9691,y:-72.3316},
{x:41.4902,y:-82.1087},
{x:43.2278,y:-71.3618},
{x:39.1679,y:-93.8789},
{x:36.0889,y:-94.1209},
{x:40.858,y:-84.5973},
{x:31.8758,y:-102.3472},
{x:33.6567,y:-117.8645},
{x:42.1748,y:-88.3352},
{x:40.0256,y:-88.5727},
{x:44.7874,y:-106.9439},
{x:44.4216,y:-69.019},
{x:44.8831,y:-68.6719},
{x:42.8289,y:-103.0172},
{x:39.9142,y:-86.2256},
{x:42.12,y:-87.7458},
{x:45.5526,y:-94.1915},
{x:29.7061,y:-95.4927},
{x:35.2627,y:-77.6475},
{x:39.9123,y:-86.1349},
{x:33.6792,y:-117.6621},
{x:39.5822,y:-104.9595},
{x:47.1549,y:-122.3592},
{x:37.8489,y:-81.9973},
{x:39.0177,y:-80.4209},
{x:29.2763,y:-94.8312},
{x:40.1138,y:-80.6991},
{x:44.3942,y:-89.8333},
{x:42.731,y:-84.469},
{x:41.2413,y:-95.9907},
{x:41.2129,y:-95.993},
{x:25.739,y:-80.2395},
{x:38.413,y:-82.4447},
{x:40.9416,y:-98.3794},
{x:26.6127,y:-81.8614},
{x:36.6,y:-121.895},
{x:35.8975,y:-77.5595},
{x:39.7239,y:-104.9872},
{x:43.818,y:-83.0018},
{x:43.0316,y:-83.0723},
{x:46.8774,y:-96.726},
{x:40.3232,y:-78.9538},
{x:41.7753,y:-87.7231},
{x:39.5879,y:-106.0949},
{x:41.7661,y:-87.5738},
{x:44.3702,y:-100.3592},
{x:37.1109,y:-120.2747},
{x:42.027,y:-87.9863},
{x:36.0224,y:-87.3385},
{x:44.8926,y:-93.0345},
{x:29.6576,y:-95.5838},
{x:45.6589,y:-118.809},
{x:32.9536,y:-96.8536},
{x:41.1351,y:-104.8157},
{x:33.9258,y:-118.2381},
{x:32.8342,y:-96.6713},
{x:40.5241,y:-97.5951},
{x:37.7542,y:-87.0627},
{x:28.824,y:-81.6915},
{x:43.9959,y:-88.5772},
{x:48.1193,y:-96.1833},
{x:45.6085,y:-121.2024},
{x:43.8502,y:-70.4497},
{x:43.4981,y:-70.4545},
{x:30.8845,y:-84.204},
{x:36.2175,y:-115.2404},
{x:41.4513,y:-96.4905},
{x:41.8752,y:-80.127},
{x:40.8132,y:-96.6468},
{x:38.6256,y:-88.9622},
{x:36.1194,y:-95.8556},
{x:30.1079,y:-96.0474},
{x:33.9316,y:-118.2039},
{x:38.3376,y:-121.97},
{x:38.6446,y:-121.4585},
{x:30.8367,y:-83.9792},
{x:26.232,y:-80.1192},
{x:40.5252,y:-79.9622},
{x:37.4701,y:-122.435},
{x:31.68,y:-88.6451},
{x:37.3213,y:-120.4687},
{x:43.5441,y:-96.7625},
{x:30.2872,y:-81.446},
{x:42.5886,y:-84.4558},
{x:36.8632,y:-82.7562},
{x:38.7022,y:-121.3832},
{x:39.1489,y:-80.0363},
{x:38.9736,y:-80.228},
{x:41.8377,y:-87.6657},
{x:38.0043,y:-84.5188},
{x:36.368,y:-93.5782},
{x:47.2895,y:-122.3191},
{x:38.6606,y:-121.3502},
{x:32.6629,y:-83.7403},
{x:39.8162,y:-89.6465},
{x:32.4324,y:-99.7563},
{x:34.4982,y:-85.8541},
{x:37.9513,y:-91.7528},
{x:33.8974,y:-118.0088},
{x:34.8319,y:-92.1894},
{x:36.1725,y:-94.5397},
{x:34.4795,y:-91.5551},
{x:37.3831,y:-89.6715},
{x:47.4131,y:-92.9274},
{x:37.2986,y:-89.5656},
{x:34.801,y:-92.2551},
{x:34.1876,y:-118.5539},
{x:37.2465,y:-93.3121},
{x:32.8051,y:-80.0127},
{x:33.4788,y:-117.1013},
{x:41.5034,y:-87.8893},
{x:40.5463,y:-90.0281},
{x:34.5432,y:-83.5431},
{x:35.0457,y:-85.6858},
{x:42.7253,y:-92.4775},
{x:34.7238,y:-81.6389},
{x:34.4984,y:-82.0457},
{x:34.9402,y:-86.5709},
{x:39.9743,y:-82.897},
{x:42.0218,y:-93.6681},
{x:45.1795,y:-89.6526},
{x:40.3456,y:-79.9463},
{x:42.9068,y:-73.9019},
{x:39.3915,y:-123.348},
{x:38.8196,y:-77.1413},
{x:39.8909,y:-84.1936},
{x:39.8973,y:-83.8109},
{x:47.1129,y:-119.2605},
{x:46.6043,y:-120.5313},
{x:36.8656,y:-119.7583},
{x:33.7925,y:-83.731},
{x:39.3671,y:-94.3748},
{x:44.6571,y:-103.8557},
{x:45.381,y:-122.6036},
{x:46.1414,y:-122.935},
{x:39.5026,y:-89.7673},
{x:28.4985,y:-81.3116},
{x:38.9378,y:-111.854},
{x:29.8745,y:-90.0994},
{x:35.761,y:-89.541},
{x:39.6249,y:-104.8833},
{x:42.7406,y:-84.5113},
{x:41.9394,y:-87.6681},
{x:39.3702,y:-94.5824},
{x:43.717,y:-98.0293},
{x:36.9879,y:-86.4292},
{x:39.9757,y:-87.6354},
{x:35.8735,y:-92.1027},
{x:47.6275,y:-117.4023},
{x:36.7486,y:-108.2018},
{x:39.1798,y:-119.7672},
{x:38.953,y:-119.9473},
{x:39.3588,y:-121.6884},
{x:33.7416,y:-117.8176},
{x:41.5382,y:-81.6169},
{x:33.9065,y:-84.2055},
{x:41.4837,y:-81.7325},
{x:45.5921,y:-94.1707},
{x:30.625,y:-84.415},
{x:30.46,y:-84.3616},
{x:42.9085,y:-88.1277},
{x:42.5319,y:-88.2483},
{x:32.5109,y:-86.2119},
{x:42.1308,y:-80.086},
{x:47.6151,y:-122.3478},
{x:41.7475,y:-83.6256},
{x:42.152,y:-87.836},
{x:41.0341,y:-81.8662},
{x:33.9535,y:-118.1306},
{x:41.4235,y:-82.0788},
{x:41.4236,y:-82.0789},
{x:45.9771,y:-112.5085},
{x:37.6386,y:-120.9367},
{x:46.2745,y:-119.2814},
{x:30.3259,y:-82.965},
{x:29.9973,y:-82.5969},
{x:44.9586,y:-89.6592},
{x:42.6814,y:-73.7307},
{x:33.4311,y:-104.5227},
{x:37.602,y:-77.4522},
{x:33.3784,y:-104.5224},
{x:44.6214,y:-123.1103},
{x:39.1361,y:-86.5242},
{x:47.6647,y:-117.1926},
{x:39.52,y:-105.306},
{x:44.9211,y:-93.4267},
{x:41.5093,y:-71.3181},
{x:33.9493,y:-84.5508},
{x:40.5167,y:-75.385},
{x:33.9027,y:-118.2518},
{x:37.2567,y:-79.9558},
{x:35.3842,y:-119.1109},
{x:40.7435,y:-92.8674},
{x:26.259,y:-80.2011},
{x:40.1639,y:-76.3952},
{x:26.2404,y:-80.1743},
{x:37.1382,y:-80.5535},
{x:39.152,y:-123.1993},
{x:32.55,y:-82.8779},
{x:30.561,y:-81.8253},
{x:38.0687,y:-91.4052},
{x:36.4338,y:-99.4002},
{x:30.1751,y:-92.0703},
{x:31.1276,y:-97.74},
{x:31.1096,y:-97.7503},
{x:32.7067,y:-97.0662},
{x:40.5922,y:-79.7513},
{x:42.9864,y:-90.6553},
{x:33.6399,y:-112.1288},
{x:33.661,y:-112.6155},
{x:41.5967,y:-87.2652},
{x:40.1296,y:-77.0194},
{x:40.26,y:-80.1815},
{x:36.844,y:-76.3049},
{x:32.7983,y:-116.935},
{x:33.9481,y:-118.006},
{x:40.3163,y:-76.0019},
{x:33.6983,y:-80.2255},
{x:39.7669,y:-86.1636},
{x:38.209,y:-86.8671},
{x:39.5065,y:-87.3936},
{x:37.354,y:-77.4112},
{x:41.792,y:-80.9492},
{x:39.825,y:-84.9267},
{x:44.9637,y:-93.2622},
{x:43.025,y:-108.4031},
{x:47.8747,y:-122.2752},
{x:39.8547,y:-75.0235},
{x:40.7011,y:-73.3798},
{x:37.9884,y:-85.7091},
{x:41.3967,y:-81.9962},
{x:39.8133,y:-105.0791},
{x:38.2002,y:-85.6581},
{x:39.7217,y:-86.1017},
{x:39.7769,y:-85.9566},
{x:40.0028,y:-86.1241},
{x:30.2259,y:-81.3878},
{x:43.6057,y:-84.7683},
{x:34.275,y:-118.4124},
{x:45.3902,y:-84.9191},
{x:29.8282,y:-81.376},
{x:40.3864,y:-86.9013},
{x:39.731,y:-82.6249},
{x:39.4062,y:-88.8082},
{x:42.4885,y:-71.0998},
{x:39.9839,y:-82.8174},
{x:43.1435,y:-93.3871},
{x:33.6372,y:-112.3352},
{x:33.6387,y:-111.9785},
{x:41.0489,y:-94.3681},
{x:41.7718,y:-93.7136},
{x:41.4015,y:-99.6298},
{x:41.1047,y:-100.7656},
{x:32.6272,y:-117.0877},
{x:41.3168,y:-80.7316},
{x:40.3357,y:-80.5969},
{x:45.6384,y:-122.642},
{x:39.3141,y:-81.5529},
{x:47.4167,y:-122.1955},
{x:33.8937,y:-84.2834},
{x:30.444,y:-87.2401},
{x:41.8524,y:-90.1866},
{x:42.091,y:-90.154},
{x:31.9097,y:-110.9837},
{x:30.5945,y:-97.4123},
{x:42.8548,y:-72.5601},
{x:39.1035,y:-94.5879},
{x:30.1756,y:-97.8258},
{x:38.1425,y:-90.5781},
{x:38.7706,y:-90.4927},
{x:39.0065,y:-93.9519},
{x:39.0137,y:-94.1293},
{x:32.0034,y:-102.0782},
{x:36.7494,y:-95.9766},
{x:36.5933,y:-94.775},
{x:35.755,y:-83.9516},
{x:34.102,y:-118.3219},
{x:30.0272,y:-90.0538},
{x:32.4497,y:-93.7807},
{x:32.482,y:-93.7603},
{x:35.3756,y:-96.9295},
{x:39.0342,y:-76.9076},
{x:30.108,y:-97.3326},
{x:41.4938,y:-90.5568},
{x:33.0144,y:-80.0876},
{x:35.6352,y:-87.0106},
{x:39.9353,y:-91.4003},
{x:46.9982,y:-122.9084},
{x:29.673,y:-82.3414},
{x:46.9969,y:-122.8205},
{x:32.4274,y:-93.7185},
{x:37.5044,y:-77.4752},
{x:37.6405,y:-121.0},
{x:38.9357,y:-94.5079},
{x:36.1608,y:-85.5135},
{x:34.5722,y:-94.2473},
{x:31.17,y:-81.475},
{x:31.8532,y:-81.6061},
{x:36.837,y:-119.6744},
{x:44.7813,y:-117.8162},
{x:38.6858,y:-85.7834},
{x:39.0128,y:-85.6393},
{x:40.1478,y:-82.9226},
{x:38.3663,y:-93.7491},
{x:35.3509,y:-97.5314},
{x:35.1353,y:-81.8629},
{x:32.976,y:-96.6992},
{x:28.9717,y:-80.8974},
{x:26.6172,y:-80.0725},
{x:32.5832,y:-97.1303},
{x:44.6544,y:-70.1501},
{x:38.8448,y:-91.9469},
{x:35.1056,y:-89.931},
{x:36.5124,y:-88.8876},
{x:46.7851,y:-92.1014},
{x:40.4114,y:-81.3464},
{x:40.5891,y:-81.092},
{x:41.1764,y:-96.0424},
{x:43.5142,y:-96.792},
{x:40.7855,y:-96.6509},
{x:40.6991,y:-99.0997},
{x:37.0847,y:-94.5301},
{x:40.8071,y:-73.4098},
{x:34.1862,y:-116.0538},
{x:39.8918,y:-88.938},
{x:39.8455,y:-88.9712},
{x:41.8521,y:-87.6848},
{x:41.468,y:-73.0244},
{x:43.5742,y:-90.8894},
{x:33.4789,y:-111.9257},
{x:32.7426,y:-97.4522},
{x:38.8923,y:-89.4125},
{x:27.7509,y:-97.4174},
{x:42.3124,y:-122.8548},
{x:35.5665,y:-97.5684},
{x:30.3895,y:-81.4014},
{x:39.1999,y:-85.9528},
{x:36.4131,y:-82.995},
{x:26.1278,y:-97.637},
{x:30.3306,y:-81.6038},
{x:29.4191,y:-98.6544},
{x:43.9004,y:-91.0907},
{x:37.6956,y:-97.4267},
{x:37.6934,y:-97.2608},
{x:37.7424,y:-97.2452},
{x:41.2627,y:-73.0955},
{x:41.9579,y:-78.644},
{x:36.1597,y:-82.3983},
{x:39.478,y:-106.045},
{x:34.7825,y:-96.652},
{x:35.9335,y:-86.8787},
{x:41.6616,y:-88.5363},
{x:41.849,y:-87.743},
{x:38.1936,y:-92.8243},
{x:34.7737,y:-87.6687},
{x:29.4179,y:-98.5519},
{x:31.7387,y:-95.6095},
{x:41.046,y:-85.2367},
{x:43.4163,y:-84.0562},
{x:41.0285,y:-81.6381},
{x:39.9165,y:-81.5256},
{x:41.7069,y:-83.5863},
{x:40.2188,y:-85.3779},
{x:33.5176,y:-112.0648},
{x:33.4954,y:-112.0307},
{x:34.7956,y:-84.9637},
{x:44.4921,y:-88.1045},
{x:40.9429,y:-72.943},
{x:39.7331,y:-80.0601},
{x:40.4886,y:-86.1656},
{x:32.8329,y:-117.2026},
{x:39.6897,y:-105.1188},
{x:40.5283,y:-111.8588},
{x:41.2935,y:-96.1289},
{x:38.7978,y:-121.2639},
{x:37.9658,y:-121.7831},
{x:38.0308,y:-121.335},
{x:36.0761,y:-95.9246},
{x:41.6003,y:-87.8465},
{x:45.5105,y:-92.989},
{x:32.3336,y:-106.7818},
{x:31.6933,y:-89.1466},
{x:38.846,y:-77.3035},
{x:35.9598,y:-95.6525},
{x:30.0436,y:-99.1443},
{x:30.3621,y:-97.6985},
{x:41.8307,y:-94.1062},
{x:43.0809,y:-94.2356},
{x:33.3213,y:-111.9798},
{x:37.0831,y:-97.0397},
{x:45.5732,y:-93.2212},
{x:35.2604,y:-87.3242},
{x:26.5268,y:-80.1635},
{x:34.596,y:-98.3342},
{x:36.1472,y:-95.948},
{x:33.0417,y:-80.1354},
{x:27.5133,y:-81.4921},
{x:46.6069,y:-112.0174},
{x:28.6978,y:-81.3631},
{x:26.0331,y:-80.1936},
{x:46.8001,y:-92.1282},
{x:42.566,y:-71.9804},
{x:33.9773,y:-78.3729},
{x:41.2838,y:-96.052},
{x:39.2037,y:-122.004},
{x:40.8661,y:-73.3218},
{x:41.2644,y:-95.842},
{x:43.9388,y:-90.821},
{x:40.9478,y:-90.3553},
{x:29.5602,y:-95.1419},
{x:40.874,y:-88.6432},
{x:39.0926,y:-108.6036},
{x:37.3484,y:-108.5695},
{x:28.558,y:-80.819},
{x:38.3595,y:-90.9796},
{x:38.4095,y:-121.3838},
{x:40.0944,y:-82.9241},
{x:40.0877,y:-82.9749},
{x:39.8936,y:-82.9952},
{x:37.6803,y:-97.3359},
{x:40.5325,y:-88.9939},
{x:40.0213,y:-82.4482},
{x:40.1524,y:-82.6865},
{x:40.2535,y:-82.8752},
{x:41.3489,y:-72.9322},
{x:39.903,y:-82.0203},
{x:47.5192,y:-122.6292},
{x:35.56,y:-97.6391},
{x:27.0174,y:-82.0551},
{x:38.7466,y:-108.0713},
{x:39.0701,y:-108.5717},
{x:46.6996,y:-92.1044},
{x:42.9878,y:-88.225},
{x:45.0322,y:-93.047},
{x:40.8038,y:-77.8872},
{x:35.5602,y:-100.9723},
{x:40.9295,y:-73.0524},
{x:43.4693,y:-110.791},
{x:35.9747,y:-78.9245},
{x:45.1218,y:-94.5038},
{x:45.4595,y:-98.46},
{x:43.3593,y:-124.2105},
{x:31.4962,y:-97.2191},
{x:29.3577,y:-98.8586},
{x:36.0786,y:-86.6433},
{x:36.7484,y:-76.5824},
{x:32.3098,y:-95.2757},
{x:32.3041,y:-95.3013},
{x:27.7968,y:-97.4329},
{x:32.9731,y:-97.3106},
{x:30.5583,y:-96.2608},
{x:30.2016,y:-93.2191},
{x:29.9698,y:-93.9934},
{x:47.5231,y:-92.5373},
{x:38.015,y:-92.7336},
{x:37.7783,y:-100.0158},
{x:37.7225,y:-97.3666},
{x:29.9761,y:-90.0995},
{x:32.0783,y:-81.0899},
{x:34.1454,y:-118.4106},
{x:41.6008,y:-87.5909},
{x:35.0349,y:-97.9352},
{x:41.4175,y:-81.7557},
{x:42.0637,y:-104.186},
{x:43.9745,y:-124.0991},
{x:43.6994,y:-124.1091},
{x:41.753,y:-86.1182},
{x:43.8623,y:-116.4922},
{x:38.8683,y:-82.1259},
{x:33.5097,y:-112.1345},
{x:37.5061,y:-77.5881},
{x:41.0022,y:-95.8945},
{x:37.2561,y:-77.3901},
{x:33.8468,y:-84.2562},
{x:32.3309,y:-96.6215},
{x:41.5536,y:-88.126},
{x:41.4177,y:-82.1516},
{x:29.7961,y:-82.5009},
{x:29.6295,y:-82.4375},
{x:40.7757,y:-87.7165},
{x:35.3063,y:-82.4542},
{x:36.0943,y:-80.4293},
{x:44.6216,y:-88.7611},
{x:40.6991,y:-89.6129},
{x:44.5608,y:-123.2623},
{x:43.0746,y:-89.5187},
{x:39.695,y:-86.0815},
{x:35.7943,y:-78.3799},
{x:26.6518,y:-80.0557},
{x:35.8811,y:-106.2981},
{x:33.7481,y:-78.8167},
{x:44.1824,y:-88.0699},
{x:32.9424,y:-97.1524},
{x:33.3993,y:-91.0321},
{x:33.5272,y:-90.202},
{x:35.1057,y:-114.598},
{x:35.3107,y:-83.1797},
{x:42.6038,y:-89.6529},
{x:41.2838,y:-80.8447},
{x:41.2066,y:-80.7415},
{x:39.1874,y:-86.5378},
{x:37.095,y:-84.6188},
{x:43.2714,y:-89.7368},
{x:47.2564,y:-122.5184},
{x:31.7353,y:-83.6531},
{x:34.1796,y:-97.1386},
{x:43.4141,y:-70.7473},
{x:47.6576,y:-117.3588},
{x:44.5711,y:-68.7884},
{x:44.8505,y:-123.2063},
{x:34.2371,y:-118.5354},
{x:61.1653,y:-149.8675},
{x:34.7715,y:-77.3544},
{x:47.5405,y:-122.1639},
{x:13.52,y:144.8169},
{x:33.6834,y:-112.2372},
{x:37.7746,y:-121.9777},
{x:35.3422,y:-84.5213},
{x:29.6519,y:-82.3354},
{x:36.0567,y:-79.4012},
{x:38.4136,y:-87.7668},
{x:35.2828,y:-93.1059},
{x:42.4683,y:-91.9152},
{x:42.7934,y:-96.1737},
{x:41.575,y:-88.1607},
{x:39.5266,y:-122.2129},
{x:42.6083,y:-76.1685},
{x:37.0428,y:-112.523},
{x:39.0684,y:-78.9689},
{x:45.1094,y:-93.3573},
{x:39.7399,y:-104.9392},
{x:39.0414,y:-77.0516},
{x:40.209,y:-75.2267},
{x:46.8941,y:-102.7915},
{x:41.6277,y:-79.6732},
{x:41.4773,y:-81.7694},
{x:41.939,y:-87.7115},
{x:40.4228,y:-86.9077},
{x:43.9124,y:-69.8161},
{x:35.5266,y:-82.9557},
{x:37.8177,y:-96.8745},
{x:44.0735,y:-89.2787},
{x:31.8632,y:-102.3962},
{x:42.5489,y:-114.4533},
{x:42.0768,y:-78.4643},
{x:36.0996,y:-115.1016},
{x:43.7472,y:-87.9579},
{x:36.797,y:-76.33},
{x:35.822,y:-119.259},
{x:33.8224,y:-118.3278},
{x:27.3219,y:-82.4971},
{x:46.5882,y:-90.894},
{x:30.3611,y:-81.5014},
{x:43.5713,y:-89.4691},
{x:34.1408,y:-117.9779},
{x:39.8833,y:-86.2088},
{x:43.6543,y:-94.4479},
{x:44.2332,y:-69.7734},
{x:34.6675,y:-92.4213},
{x:41.0284,y:-93.7842},
{x:41.3647,y:-93.5587},
{x:41.5612,y:-87.5079},
{x:42.0864,y:-76.0501},
{x:44.5198,y:-88.0221},
{x:39.9923,y:-76.0212},
{x:41.8311,y:-87.6156},
{x:38.9222,y:-90.1925},
{x:42.4205,y:-123.3342},
{x:35.1191,y:-120.5909},
{x:43.4042,y:-124.2342},
{x:25.9424,y:-80.3401},
{x:42.2369,y:-91.1843},
{x:36.6048,y:-95.1872},
{x:39.7182,y:-105.1317},
{x:42.7871,y:-96.9165},
{x:34.1434,y:-118.699},
{x:32.7209,y:-80.0841},
{x:39.9606,y:-84.1882},
{x:42.8854,y:-97.3962},
{x:39.5379,y:-104.8833},
{x:42.752,y:-95.5516},
{x:41.2049,y:-96.1367},
{x:47.9378,y:-97.0168},
{x:34.8676,y:-92.1193},
{x:39.13,y:-119.7697},
{x:43.2034,y:-77.5587},
{x:41.2584,y:-75.9},
{x:41.951,y:-87.6596},
{x:44.0619,y:-103.2327},
{x:42.9489,y:-78.7843},
{x:33.9019,y:-118.2939},
{x:44.9285,y:-90.3149},
{x:34.919,y:-85.1221},
{x:37.3085,y:-77.2936},
{x:44.5172,y:-109.0546},
{x:35.9941,y:-78.8688},
{x:38.0644,y:-84.521},
{x:39.3995,y:-76.6025},
{x:34.9817,y:-101.9191},
{x:41.2599,y:-95.9742},
{x:45.2185,y:-96.6431},
{x:29.6342,y:-95.619},
{x:39.4408,y:-78.9768},
{x:30.2137,y:-92.0478},
{x:33.7291,y:-116.4028},
{x:32.2448,y:-101.4754},
{x:44.2738,y:-83.5092},
{x:40.3765,y:-105.5218},
{x:42.4918,y:-83.0847},
{x:31.5382,y:-91.3967},
{x:29.2686,y:-81.1014},
{x:40.0541,y:-111.7315},
{x:44.8606,y:-93.5394},
{x:34.2004,y:-118.4321},
{x:40.4585,y:-87.6873},
{x:47.3931,y:-122.2965},
{x:35.7553,y:-77.9265},
{x:42.5972,y:-76.2057},
{x:34.0341,y:-118.6914},
{x:41.4838,y:-87.7025},
{x:44.5589,y:-69.6335},
{x:44.5443,y:-69.6725},
{x:38.4628,y:-81.6583},
{x:35.099,y:-89.8462},
{x:47.3176,y:-119.5544},
{x:47.7538,y:-122.2784},
{x:35.7261,y:-78.5818},
{x:36.1583,y:-115.2442},
{x:33.1349,y:-94.9692},
{x:26.5447,y:-81.8418},
{x:40.8351,y:-75.2979},
{x:44.6402,y:-93.1448},
{x:38.5333,y:-76.9837},
{x:35.4115,y:-99.4188},
{x:40.181,y:-111.6111},
{x:41.3972,y:-73.0771},
{x:35.798,y:-78.579},
{x:36.3968,y:-78.1523},
{x:39.4282,y:-80.1862},
{x:33.5668,y:-117.7088},
{x:44.7497,y:-92.8028},
{x:45.8705,y:-93.291},
{x:43.8556,y:-95.1142},
{x:43.6237,y:-94.9885},
{x:33.8897,y:-118.3072},
{x:32.7852,y:-79.9361},
{x:39.9103,y:-85.1571},
{x:44.7911,y:-93.179},
{x:31.7291,y:-98.9769},
{x:40.8656,y:-73.0363},
{x:48.5917,y:-93.4032},
{x:32.436,y:-97.7686},
{x:43.1043,y:-73.5837},
{x:36.1,y:-95.8861},
{x:40.3841,y:-75.9259},
{x:38.679,y:-88.4889},
{x:37.7806,y:-79.9903},
{x:39.8848,y:-83.4512},
{x:38.2536,y:-121.2955},
{x:27.6252,y:-97.2228},
{x:39.7444,y:-93.9983},
{x:31.1329,y:-93.2656},
{x:36.6036,y:-119.4349},
{x:39.2032,y:-86.2471},
{x:40.8418,y:-96.6799},
{x:39.0148,y:-95.6508},
{x:36.9545,y:-76.252},
{x:32.8456,y:-97.3435},
{x:42.8707,y:-73.9304},
{x:44.836,y:-69.2869},
{x:44.7845,y:-69.73},
{x:39.946,y:-91.3781},
{x:41.4039,y:-94.9885},
{x:30.6811,y:-83.2215},
{x:26.6815,y:-81.8056},
{x:46.0107,y:-91.4828},
{x:39.7139,y:-89.6346},
{x:38.9416,y:-91.9396},
{x:39.2724,y:-76.6721},
{x:44.9358,y:-68.6465},
{x:39.331,y:-101.7256},
{x:42.4613,y:-70.9496},
{x:42.2429,y:-84.7526},
{x:29.8409,y:-95.4748},
{x:57.0516,y:-135.3341},
{x:30.185,y:-85.8181},
{x:33.9162,y:-117.3269},
{x:28.8238,y:-81.7885},
{x:41.4386,y:-97.3652},
{x:42.015,y:-94.3772},
{x:33.8784,y:-79.7574},
{x:26.6736,y:-80.6713},
{x:40.5167,y:-74.2336},
{x:38.8297,y:-76.9426},
{x:35.2742,y:-93.1335},
{x:35.5933,y:-89.2529},
{x:41.2972,y:-91.6942},
{x:38.0088,y:-122.2705},
{x:37.5595,y:-85.2701},
{x:39.9137,y:-85.3851},
{x:40.7553,y:-111.8261},
{x:37.7594,y:-77.4687},
{x:36.4413,y:-77.1136},
{x:36.0711,y:-79.296},
{x:29.6887,y:-96.5382},
{x:32.2147,y:-98.2135},
{x:42.09,y:-76.8093},
{x:37.2458,y:-83.1888},
{x:29.648,y:-95.4602},
{x:39.8055,y:-89.6468},
{x:34.5956,y:-83.7635},
{x:38.2206,y:-91.16},
{x:42.2752,y:-83.7327},
{x:42.6901,y:-73.8665},
{x:38.4095,y:-96.2174},
{x:32.4313,y:-87.0191},
{x:38.9015,y:-76.8341},
{x:47.356,y:-68.3413},
{x:43.6809,y:-70.4384},
{x:42.6837,y:-84.5475},
{x:35.2561,y:-88.9964},
{x:32.354,y:-108.7352},
{x:33.7879,y:-118.0108},
{x:41.3364,y:-94.014},
{x:43.8521,y:-88.8573},
{x:34.6529,y:-86.0341},
{x:40.0473,y:-80.6512},
{x:40.5546,y:-84.383},
{x:36.128,y:-88.5121},
{x:43.4162,y:-83.9654},
{x:32.4329,y:-81.7576},
{x:21.3343,y:-158.0242},
{x:28.6065,y:-81.298},
{x:28.6545,y:-81.2092},
{x:34.0655,y:-118.412},
{x:29.211,y:-99.7807},
{x:34.1829,y:-79.3647},
{x:46.8765,y:-96.8992},
{x:35.5941,y:-97.6213},
{x:41.016,y:-95.2321},
{x:38.8555,y:-77.3359},
{x:42.3695,y:-83.3379},
{x:44.357,y:-98.2137},
{x:42.9186,y:-89.2357},
{x:34.0034,y:-96.3734},
{x:41.1203,y:-85.0646},
{x:41.2301,y:-95.9474},
{x:43.1833,y:-88.7299},
{x:35.1086,y:-81.2126},
{x:42.9975,y:-96.0568},
{x:40.3409,y:-75.9825},
{x:39.7141,y:-86.3676},
{x:39.7525,y:-92.4659},
{x:38.281,y:-122.2924},
{x:38.0368,y:-79.0263},
{x:39.0477,y:-84.5783},
{x:37.3981,y:-94.7048},
{x:39.6456,y:-86.8289},
{x:30.017,y:-97.8635},
{x:36.0567,y:-94.1779},
{x:36.3077,y:-82.363},
{x:32.6587,y:-97.2686},
{x:41.2001,y:-81.4405},
{x:21.4432,y:-157.7582},
{x:33.015,y:-96.6653},
{x:28.0429,y:-81.9899},
{x:39.1004,y:-76.8166},
{x:42.2818,y:-71.3913},
{x:33.4837,y:-80.4689},
{x:40.0923,y:-76.1175},
{x:38.2923,y:-112.6478},
{x:41.7117,y:-112.1614},
{x:34.7761,y:-82.3192},
{x:37.7264,y:-89.2322},
{x:34.8825,y:-82.4542},
{x:41.8018,y:-81.0477},
{x:32.4774,y:-96.9838},
{x:40.1376,y:-79.8968},
{x:32.8217,y:-97.0518},
{x:42.2354,y:-97.0154},
{x:41.9838,y:-86.1227},
{x:35.2121,y:-80.8337},
{x:41.4854,y:-93.6737},
{x:31.3042,y:-82.2454},
{x:33.9568,y:-117.3313},
{x:34.4273,y:-119.6869},
{x:26.5621,y:-82.0075},
{x:36.3121,y:-119.7833},
{x:34.7471,y:-92.2719},
{x:29.386,y:-82.4547},
{x:39.8096,y:-89.6854},
{x:28.8356,y:-82.3314},
{x:35.4395,y:-88.641},
{x:40.2358,y:-75.2893},
{x:41.6501,y:-85.4183},
{x:34.2073,y:-118.5081},
{x:31.5096,y:-82.8618},
{x:48.1074,y:-98.871},
{x:44.5561,y:-70.5407},
{x:29.2185,y:-96.287},
{x:27.192,y:-80.2541},
{x:34.1204,y:-93.0846},
{x:41.0603,y:-81.4549},
{x:30.1809,y:-81.7023},
{x:39.6071,y:-90.804},
{x:32.5979,y:-96.9394},
{x:42.8571,y:-77.0035},
{x:42.9222,y:-82.4719},
{x:35.3934,y:-97.7224},
{x:41.5383,y:-93.5946},
{x:41.5824,y:-93.7066},
{x:38.703,y:-90.1418},
{x:39.6856,y:-79.9193},
{x:41.6605,y:-93.6983},
{x:41.3847,y:-81.4359},
{x:44.1952,y:-70.5264},
{x:43.0154,y:-74.359},
{x:38.2832,y:-85.6298},
{x:35.0315,y:-90.7884},
{x:34.6228,y:-92.5022},
{x:34.802,y:-92.4306},
{x:38.6396,y:-77.3019},
{x:34.0056,y:-118.1528},
{x:35.5283,y:-98.6937},
{x:32.8673,y:-97.3705},
{x:41.6915,y:-92.0045},
{x:42.0219,y:-93.4519},
{x:30.4223,y:-87.3162},
{x:46.9814,y:-120.5444},
{x:48.163,y:-103.6255},
{x:40.8386,y:-73.2949},
{x:38.8342,y:-77.4137},
{x:33.8866,y:-118.3263},
{x:42.8638,y:-73.7684},
{x:21.3055,y:-157.8384},
{x:38.2,y:-85.6837},
{x:32.3885,y:-83.3521},
{x:34.869,y:-85.5137},
{x:33.2949,y:-83.9619},
{x:33.744,y:-118.1022},
{x:42.1228,y:-75.9635},
{x:32.478,y:-80.9738},
{x:42.0995,y:-76.2351},
{x:33.9081,y:-84.6028},
{x:31.8538,y:-102.3656},
{x:28.6331,y:-81.3213},
{x:33.9865,y:-118.2243},
{x:41.2421,y:-95.8257},
{x:34.2625,y:-79.7009},
{x:41.4485,y:-81.5626},
{x:41.7178,y:-87.8332},
{x:60.5637,y:-151.226},
{x:29.8019,y:-95.4322},
{x:30.4061,y:-91.0158},
{x:29.9498,y:-90.0711},
{x:41.3135,y:-81.7271},
{x:35.5293,y:-108.7054},
{x:44.1007,y:-123.1294},
{x:38.7302,y:-88.0945},
{x:41.5933,y:-72.9899},
{x:32.0024,y:-102.1364},
{x:41.9652,y:-87.6611},
{x:40.2646,y:-94.0102},
{x:38.0453,y:-84.4959},
{x:37.7836,y:-79.4432},
{x:25.9437,y:-80.3075},
{x:39.9465,y:-94.8223},
{x:30.7181,y:-95.5705},
{x:39.6837,y:-89.7045},
{x:42.4374,y:-88.2455},
{x:39.2712,y:-76.6028},
{x:41.6564,y:-88.4435},
{x:39.2659,y:-93.961},
{x:36.8855,y:-104.4289},
{x:33.5265,y:-117.7102},
{x:45.5541,y:-94.2135},
{x:36.7358,y:-78.9176},
{x:43.0175,y:-83.6918},
{x:38.7969,y:-104.7569},
{x:32.3685,y:-89.4787},
{x:38.4718,y:-77.3783},
{x:37.0069,y:-79.8765},
{x:44.7505,y:-108.7614},
{x:40.8733,y:-75.2342},
{x:40.6779,y:-75.6155},
{x:42.8522,y:-106.3255},
{x:43.2973,y:-73.6752},
{x:42.1112,y:-79.219},
{x:41.9515,y:-86.3461},
{x:35.4179,y:-78.8024},
{x:43.2635,y:-93.6383},
{x:35.8467,y:-79.0903},
{x:39.9986,y:-105.2621},
{x:36.5819,y:-86.5158},
{x:43.0145,y:-89.303},
{x:40.4754,y:-80.0986},
{x:43.3265,y:-90.3815},
{x:34.0907,y:-118.385},
{x:34.2727,y:-77.8178},
{x:39.0671,y:-77.1289},
{x:41.5841,y:-72.5015},
{x:39.5959,y:-81.6971},
{x:36.5382,y:-82.6669},
{x:39.0353,y:-87.1631},
{x:34.2589,y:-118.5794},
{x:34.1874,y:-118.4667},
{x:34.7547,y:-82.2676},
{x:41.4784,y:-75.6006},
{x:34.7388,y:-97.2568},
{x:39.1197,y:-76.5139},
{x:36.1128,y:-86.765},
{x:41.2862,y:-96.0816},
{x:33.862,y:-117.7387},
{x:41.7148,y:-83.6237},
{x:32.7884,y:-79.7864},
{x:43.4022,y:-94.8208},
{x:42.402,y:-83.2211},
{x:44.6417,y:-121.1289},
{x:30.6772,y:-88.0838},
{x:37.6054,y:-77.573},
{x:41.1107,y:-98.0093},
{x:45.3086,y:-92.3619},
{x:21.3099,y:-157.8616},
{x:43.921,y:-88.0249},
{x:42.4978,y:-83.1679},
{x:41.1511,y:-84.5807},
{x:33.5934,y:-86.2825},
{x:35.201,y:-87.0457},
{x:40.9511,y:-73.7356},
{x:35.7187,y:-79.1664},
{x:40.6689,y:-73.6088},
{x:36.1345,y:-94.146},
{x:42.6339,y:-83.2233},
{x:34.2507,y:-89.017},
{x:30.2154,y:-97.7449},
{x:41.464,y:-81.0904},
{x:38.3009,y:-85.7623},
{x:43.9372,y:-69.9713},
{x:36.2969,y:-87.0475},
{x:30.7897,y:-81.6579},
{x:34.6902,y:-80.7865},
{x:33.7255,y:-84.939},
{x:31.7028,y:-86.2648},
{x:34.0327,y:-117.9155},
{x:44.007,y:-97.1092},
{x:39.0152,y:-94.7168},
{x:47.2432,y:-122.3685},
{x:30.7047,y:-84.8474},
{x:35.6977,y:-89.9912},
{x:35.8138,y:-90.7133},
{x:34.663,y:-84.4866},
{x:32.0149,y:-80.9825},
{x:39.9552,y:-82.882},
{x:30.36,y:-103.6524},
{x:32.7376,y:-96.3048},
{x:34.3759,y:-77.7024},
{x:42.0639,y:-72.5134},
{x:44.0079,y:-92.4453},
{x:45.4652,y:-91.1021},
{x:36.1272,y:-86.7534},
{x:37.738,y:-89.8696},
{x:32.2337,y:-97.7659},
{x:39.6527,y:-86.1362},
{x:39.9454,y:-84.0249},
{x:40.2019,y:-100.6457},
{x:36.145,y:-86.752},
{x:43.9638,y:-88.9415},
{x:39.0119,y:-89.7887},
{x:42.9712,y:-74.1872},
{x:40.5795,y:-83.1269},
{x:38.9281,y:-75.4336},
{x:33.585,y:-101.8704},
{x:28.6146,y:-80.8389},
{x:40.7421,y:-73.9194},
{x:39.6085,y:-105.9533},
{x:40.9218,y:-111.8823},
{x:30.487,y:-86.419},
{x:40.3964,y:-86.8692},
{x:42.9199,y:-83.6215},
{x:43.0192,y:-83.6342},
{x:41.491,y:-90.482},
{x:36.0665,y:-79.4267},
{x:38.9277,y:-104.8308},
{x:43.0762,y:-75.6421},
{x:45.7097,y:-121.5318},
{x:39.1522,y:-77.0619},
{x:45.4038,y:-122.7965},
{x:36.1061,y:-96.1148},
{x:40.0599,y:-82.9862},
{x:39.8441,y:-79.619},
{x:40.0369,y:-79.583},
{x:41.2804,y:-82.063},
{x:33.9612,y:-118.4197},
{x:41.7074,y:-87.617},
{x:40.132,y:-86.0134},
{x:40.7356,y:-114.0428},
{x:44.4495,y:-92.2803},
{x:43.7198,y:-70.2322},
{x:39.6399,y:-85.1481},
{x:39.048,y:-122.924},
{x:36.8762,y:-76.1353},
{x:39.2868,y:-86.7606},
{x:41.7699,y:-76.4196},
{x:41.9658,y:-75.7407},
{x:34.1974,y:-86.1506},
{x:15.142,y:145.701},
{x:38.2736,y:-104.6088},
{x:39.9185,y:-105.784},
{x:41.4757,y:-87.4016},
{x:44.806,y:-95.547},
{x:44.9492,y:-95.6956},
{x:41.544,y:-96.1329},
{x:38.9747,y:-76.2805},
{x:41.2852,y:-81.0988},
{x:38.3792,y:-88.3647},
{x:45.7277,y:-94.9521},
{x:41.9104,y:-87.7375},
{x:44.4919,y:-92.9068},
{x:44.5412,y:-95.1143},
{x:38.9504,y:-92.2961},
{x:41.9474,y:-85.6346},
{x:43.7485,y:-87.8214},
{x:29.6179,y:-90.7461},
{x:33.834,y:-118.1851},
{x:26.1997,y:-97.6809},
{x:40.7248,y:-95.0372},
{x:40.7619,y:-95.3846},
{x:43.0238,y:-78.9643},
{x:26.7846,y:-80.0346},
{x:35.5475,y:-82.6552},
{x:31.5184,y:-87.318},
{x:31.31,y:-86.4998},
{x:35.9748,y:-78.7921},
{x:35.917,y:-78.465},
{x:34.7342,y:-80.0838},
{x:41.0454,y:-73.5239},
{x:40.1101,y:-76.4865},
{x:32.631,y:-85.4984},
{x:47.0258,y:-91.6853},
{x:43.6906,y:-85.5133},
{x:39.7502,y:-77.568},
{x:30.8431,y:-83.3241},
{x:40.6085,y:-75.4893},
{x:33.3723,y:-117.2524},
{x:33.1522,y:-96.8018},
{x:34.1082,y:-82.8579},
{x:42.097,y:-102.8866},
{x:38.896,y:-104.7513},
{x:41.036,y:-83.6504},
{x:43.0173,y:-83.5182},
{x:42.8553,y:-88.3268},
{x:41.6462,y:-95.3296},
{x:38.0483,y:-84.4546},
{x:41.3772,y:-87.4572},
{x:43.5961,y:-96.5738},
{x:38.8708,y:-99.3182},
{x:35.6003,y:-82.4405},
{x:36.6081,y:-121.9258},
{x:31.8583,y:-82.6064},
{x:46.2422,y:-119.1197},
{x:39.9085,y:-86.058},
{x:33.5796,y:-111.9266},
{x:42.1884,y:-93.5736},
{x:42.5318,y:-92.338},
{x:37.3478,y:-79.5058},
{x:44.0172,y:-90.5045},
{x:36.2363,y:-90.043},
{x:38.978,y:-89.1038},
{x:42.5259,y:-71.7604},
{x:38.7845,y:-90.5296},
{x:44.0051,y:-70.0755},
{x:31.1349,y:-83.4329},
{x:28.7554,y:-81.3443},
{x:47.5865,y:-122.2357},
{x:40.6731,y:-75.3417},
{x:44.9284,y:-123.3124},
{x:40.7203,y:-89.2724},
{x:30.3791,y:-89.0925},
{x:34.1659,y:-81.3583},
{x:29.9778,y:-90.204},
{x:45.2251,y:-93.3967},
{x:34.0261,y:-118.427},
{x:44.723,y:-67.4536},
{x:40.8624,y:-76.7939},
{x:33.636,y:-117.6828},
{x:34.9289,y:-82.1234},
{x:39.9939,y:-81.7347},
{x:40.0392,y:-81.576},
{x:38.7169,y:-75.0769},
{x:46.0782,y:-118.3049},
{x:40.2299,y:-78.8285},
{x:37.5519,y:-77.452},
{x:39.7872,y:-89.65},
{x:35.2247,y:-80.8452},
{x:40.9992,y:-85.7675},
{x:41.4519,y:-85.586},
{x:35.6382,y:-105.9806},
{x:34.8554,y:-82.329},
{x:38.7392,y:-77.2136},
{x:42.7003,y:-78.9816},
{x:41.9363,y:-79.6421},
{x:38.9083,y:-77.406},
{x:21.4367,y:-157.8263},
{x:39.9824,y:-76.2815},
{x:37.3892,y:-79.9008},
{x:45.8178,y:-108.4715},
{x:32.7333,y:-96.6144},
{x:44.5523,y:-90.5979},
{x:39.7628,y:-86.3888},
{x:33.8766,y:-98.4954},
{x:41.2128,y:-73.2115},
{x:34.2009,y:-118.4088},
{x:47.6158,y:-122.0365},
{x:35.5117,y:-97.973},
{x:36.7665,y:-119.735},
{x:35.2769,y:-82.7041},
{x:41.5068,y:-87.336},
{x:33.3059,y:-111.947},
{x:38.264,y:-93.3699},
{x:33.1172,y:-95.5955},
{x:32.3485,y:-95.3423},
{x:35.6378,y:-97.5144},
{x:45.9139,y:-89.2548},
{x:42.6066,y:-89.3773},
{x:33.1872,y:-79.9976},
{x:36.7882,y:-119.7534},
{x:41.5934,y:-88.0373},
{x:38.3552,y:-90.1546},
{x:45.1618,y:-89.1456},
{x:34.5214,y:-82.5031},
{x:28.458,y:-81.3654},
{x:43.0501,y:-88.3719},
{x:43.0586,y:-96.175},
{x:38.8029,y:-90.7671},
{x:37.9772,y:-87.6309},
{x:32.784,y:-96.7976},
{x:18.4004,y:-66.074},
{x:35.685,y:-78.6062},
{x:33.1135,y:-98.5891},
{x:34.2318,y:-77.9968},
{x:39.1951,y:-76.8838},
{x:44.2789,y:-73.9818},
{x:42.1678,y:-92.0374},
{x:42.9287,y:-83.0486},
{x:34.9443,y:-104.6407},
{x:40.2786,y:-96.7468},
{x:40.7738,y:-82.4793},
{x:38.9395,y:-92.7448},
{x:42.9131,y:-85.5142},
{x:32.8184,y:-79.8437},
{x:31.7697,y:-106.3187},
{x:33.7062,y:-84.4594},
{x:38.214,y:-85.2063},
{x:36.5176,y:-119.5572},
{x:42.7358,y:-78.8405},
{x:37.6813,y:-77.5902},
{x:40.5459,y:-74.1598},
{x:33.9179,y:-117.9042},
{x:43.1864,y:-95.8536},
{x:43.6248,y:-70.3493},
{x:44.0182,y:-107.9555},
{x:37.6345,y:-91.5353},
{x:41.6149,y:-94.0178},
{x:30.158,y:-85.65},
{x:40.6127,y:-89.4645},
{x:32.7338,y:-96.6822},
{x:37.7985,y:-121.9235},
{x:38.6808,y:-76.5341},
{x:40.638,y:-76.1793},
{x:37.6484,y:-122.09},
{x:28.5686,y:-81.2869},
{x:39.564,y:-76.9768},
{x:35.4521,y:-97.4064},
{x:30.4414,y:-88.8984},
{x:36.7435,y:-119.7717},
{x:47.7336,y:-121.9865},
{x:41.5424,y:-87.8501},
{x:40.0981,y:-83.1238},
{x:42.6449,y:-83.3251},
{x:41.989,y:-91.65},
{x:34.8042,y:-106.7164},
{x:39.0059,y:-87.7623},
{x:36.381,y:-105.5894},
{x:43.8539,y:-83.9659},
{x:47.6118,y:-122.338},
{x:40.744,y:-88.512},
{x:39.746,y:-89.5307},
{x:35.2983,y:-94.4095},
{x:36.7941,y:-119.8276},
{x:29.5288,y:-95.0731},
{x:40.5766,y:-111.8711},
{x:43.1159,y:-85.546},
{x:44.8362,y:-93.2491},
{x:39.481,y:-84.4728},
{x:40.9866,y:-75.1998},
{x:39.7405,y:-104.9026},
{x:39.727,y:-104.8495},
{x:39.451,y:-83.781},
{x:39.7013,y:-84.1247},
{x:37.2719,y:-97.4017},
{x:41.5049,y:-74.0613},
{x:36.7335,y:-120.0619},
{x:40.7045,y:-84.1486},
{x:32.8785,y:-85.178},
{x:35.4917,y:-92.0352},
{x:38.9117,y:-92.3328},
{x:31.7801,y:-82.3553},
{x:30.1902,y:-85.7086},
{x:43.1274,y:-89.3633},
{x:41.5226,y:-88.1234},
{x:30.4363,y:-91.0565},
{x:41.733,y:-72.7335},
{x:32.5556,y:-116.9373},
{x:35.9302,y:-85.4883},
{x:35.9292,y:-85.4893},
{x:39.3673,y:-76.5177},
{x:32.269,y:-81.2244},
{x:37.7904,y:-122.4045},
{x:38.1463,y:-85.7751},
{x:36.4698,y:-82.2829},
{x:21.3378,y:-158.0806},
{x:47.0556,y:-122.7662},
{x:44.0411,y:-88.5411},
{x:35.1655,y:-88.585},
{x:44.8603,y:-93.2682},
{x:32.7926,y:-80.0428},
{x:41.7919,y:-107.2142},
{x:40.8638,y:-82.321},
{x:33.1036,y:-96.1093},
{x:39.3553,y:-94.7648},
{x:36.7117,y:-107.9889},
{x:45.2889,y:-93.2321},
{x:36.3248,y:-119.6737},
{x:32.9524,y:-96.9591},
{x:40.0081,y:-89.8481},
{x:30.5188,y:-92.0717},
{x:32.0444,y:-81.0681},
{x:41.6605,y:-73.0796},
{x:47.2225,y:-93.5286},
{x:45.5329,y:-122.5593},
{x:43.2323,y:-76.1362},
{x:38.294,y:-77.523},
{x:46.2898,y:-96.1091},
{x:41.4531,y:-90.4633},
{x:36.1149,y:-115.2034},
{x:47.7192,y:-122.3122},
{x:64.8374,y:-147.8095},
{x:36.5152,y:-121.4353},
{x:41.3744,y:-83.6436},
{x:41.7644,y:-124.1971},
{x:33.8164,y:-118.3083},
{x:40.5822,y:-124.1451},
{x:45.5353,y:-122.6524},
{x:34.0937,y:-84.0107},
{x:33.78,y:-84.6058},
{x:45.5933,y:-95.9116},
{x:39.8063,y:-84.0298},
{x:42.8061,y:-83.6248},
{x:30.5805,y:-87.296},
{x:37.7375,y:-121.4526},
{x:30.864,y:-93.2874},
{x:41.041,y:-73.7895},
{x:33.9964,y:-81.2257},
{x:33.8964,y:-118.223},
{x:39.7789,y:-76.9623},
{x:40.4646,y:-83.891},
{x:37.6826,y:-122.4878},
{x:40.087,y:-76.1953},
{x:43.0399,y:-87.9054},
{x:38.0175,y:-122.1358},
{x:42.0999,y:-84.2474},
{x:41.772,y:-87.975},
{x:43.6323,y:-88.7167},
{x:41.5178,y:-109.4685},
{x:40.499,y:-111.4121},
{x:40.9667,y:-117.7431},
{x:34.842,y:-82.3623},
{x:30.2719,y:-97.6983},
{x:41.6977,y:-72.9041},
{x:42.4837,y:-91.4777},
{x:37.3184,y:-84.9291},
{x:29.5106,y:-98.3917},
{x:39.1323,y:-84.5167},
{x:45.9721,y:-94.8657},
{x:38.3154,y:-85.899},
{x:42.7192,y:-83.4169},
{x:40.8578,y:-74.3525},
{x:30.5197,y:-91.1905},
{x:38.9395,y:-92.3259},
{x:38.9388,y:-92.3329},
{x:40.6781,y:-74.2684},
{x:37.9322,y:-87.8964},
{x:42.4192,y:-76.5198},
{x:30.0019,y:-90.1467},
{x:41.4215,y:-78.7304},
{x:29.1063,y:-81.033},
{x:37.2576,y:-76.7397},
{x:30.354,y:-97.7317},
{x:32.8527,y:-80.0109},
{x:41.6883,y:-70.211},
{x:41.4043,y:-75.4121},
{x:32.4085,y:-88.7056},
{x:45.1836,y:-67.2837},
{x:33.9647,y:-84.4098},
{x:32.7472,y:-116.9863},
{x:37.5749,y:-106.1295},
{x:40.364,y:-75.297},
{x:34.0259,y:-118.4903},
{x:42.9379,y:-89.3838},
{x:46.9208,y:-98.0188},
{x:42.1768,y:-76.8362},
{x:33.4967,y:-81.9602},
{x:33.5373,y:-112.0467},
{x:21.3162,y:-157.8709},
{x:38.2205,y:-85.6264},
{x:48.0809,y:-123.1163},
{x:33.5672,y:-112.0656},
{x:32.4137,y:-80.6463},
{x:27.4162,y:-80.3257},
{x:37.9552,y:-87.5385},
{x:40.294,y:-90.065},
{x:36.47,y:-86.6737},
{x:39.4328,y:-91.0706},
{x:33.4148,y:-111.8364},
{x:32.8031,y:-86.5884},
{x:34.7132,y:-86.659},
{x:33.009,y:-96.5455},
{x:46.9223,y:-95.058},
{x:33.5964,y:-112.1667},
{x:36.152,y:-95.9903},
{x:21.3988,y:-158.0085},
{x:38.624,y:-77.0713},
{x:37.6467,y:-121.032},
{x:47.1685,y:-122.1687},
{x:32.8219,y:-96.8564},
{x:34.7188,y:-112.0049},
{x:45.4594,y:-98.4849},
{x:39.3762,y:-80.3113},
{x:42.6036,y:-82.9545},
{x:33.9635,y:-98.6903},
{x:34.9035,y:-91.1942},
{x:40.7175,y:-75.2823},
{x:37.6998,y:-121.067},
{x:31.4911,y:-82.8491},
{x:32.6154,y:-90.0347},
{x:32.8646,y:-90.3953},
{x:42.4733,y:-83.1802},
{x:40.1207,y:-90.5634},
{x:39.4442,y:-77.5448},
{x:34.5027,y:-82.6836},
{x:37.8976,y:-88.9356},
{x:33.7475,y:-84.2917},
{x:35.9105,y:-81.1767},
{x:35.971,y:-114.8504},
{x:29.7827,y:-90.8069},
{x:41.3438,y:-81.529},
{x:38.3162,y:-88.9041},
{x:33.7467,y:-116.9973},
{x:39.1281,y:-89.8178},
{x:36.0231,y:-78.954},
{x:32.3696,y:-86.3463},
{x:33.6831,y:-117.168},
{x:43.5245,y:-84.1232},
{x:42.2003,y:-85.5754},
{x:39.1511,y:-84.4342},
{x:35.2035,y:-97.4605},
{x:42.5063,y:-83.0278},
{x:38.7953,y:-77.522},
{x:44.055,y:-70.6947},
{x:39.6369,y:-76.8654},
{x:42.6228,y:-83.9259},
{x:36.3641,y:-95.8329},
{x:25.972,y:-80.2066},
{x:35.8276,y:-94.6278},
{x:37.6473,y:-77.6153},
{x:36.8147,y:-95.9344},
{x:32.5758,y:-96.3097},
{x:44.6433,y:-93.2912},
{x:40.1379,y:-88.2556},
{x:48.0006,y:-122.107},
{x:42.5449,y:-71.4692},
{x:33.886,y:-84.0614},
{x:41.6149,y:-90.7829},
{x:38.9176,y:-84.6326},
{x:46.7291,y:-122.9793},
{x:42.4482,y:-82.9054},
{x:33.8037,y:-118.0125},
{x:42.6886,y:-84.2875},
{x:38.2995,y:-86.9551},
{x:30.2816,y:-91.2289},
{x:36.0776,y:-78.9097},
{x:35.7889,y:-78.6493},
{x:42.3452,y:-122.8781},
{x:41.3204,y:-75.773},
{x:39.1183,y:-75.5054},
{x:45.8133,y:-91.8972},
{x:36.1853,y:-80.2717},
{x:41.0115,y:-81.5532},
{x:30.7168,y:-95.5513},
{x:43.3528,y:-83.8681},
{x:34.202,y:-118.5715},
{x:30.123,y:-93.7469},
{x:37.1587,y:-76.4619},
{x:47.2618,y:-101.785},
{x:47.3001,y:-101.6286},
{x:33.9015,y:-118.3658},
{x:33.9788,y:-118.3727},
{x:41.4155,y:-73.4613},
{x:28.017,y:-81.631},
{x:21.2824,y:-157.8003},
{x:21.383,y:-157.9399},
{x:37.6081,y:-120.968},
{x:40.7821,y:-73.0088},
{x:35.6891,y:-88.8578},
{x:38.7328,y:-89.8983},
{x:42.6291,y:-83.0526},
{x:33.3496,y:-111.9604},
{x:38.6725,y:-77.2617},
{x:42.9895,y:-89.5408},
{x:43.3339,y:-73.678},
{x:43.3442,y:-89.0285},
{x:41.4383,y:-81.6992},
{x:42.899,y:-112.4322},
{x:48.3882,y:-115.5549},
{x:42.3191,y:-88.9934},
{x:42.3081,y:-88.9956},
{x:46.4201,y:-117.0486},
{x:32.8102,y:-98.0632},
{x:34.197,y:-92.054},
{x:28.562,y:-81.1297},
{x:41.171,y:-104.8297},
{x:42.5646,y:-83.0907},
{x:32.0239,y:-102.1089},
{x:36.0662,y:-115.0466},
{x:37.8632,y:-94.0256},
{x:38.9648,y:-91.4947},
{x:40.9021,y:-80.8562},
{x:31.9535,y:-95.2652},
{x:42.3377,y:-88.2748},
{x:46.1268,y:-112.9332},
{x:42.6706,y:-88.5409},
{x:38.9382,y:-97.2133},
{x:45.4794,y:-122.6161},
{x:38.8268,y:-97.5945},
{x:40.8915,y:-74.5096},
{x:42.2968,y:-71.4958},
{x:32.7321,y:-97.3441},
{x:39.8822,y:-83.0701},
{x:37.5833,y:-84.2837},
{x:34.7731,y:-80.3826},
{x:31.9315,y:-81.3272},
{x:42.9637,y:-85.6945},
{x:33.7044,y:-78.869},
{x:27.4706,y:-81.4409},
{x:34.7327,y:-80.7975},
{x:45.5007,y:-122.6528},
{x:38.9049,y:-77.0416},
{x:45.9227,y:-90.4517},
{x:37.033,y:-95.6288},
{x:34.1609,y:-118.4482},
{x:32.3777,y:-82.0617},
{x:35.9853,y:-96.757},
{x:47.3583,y:-122.1128},
{x:37.0604,y:-78.0067},
{x:33.4666,y:-111.6841},
{x:34.3964,y:-119.5117},
{x:30.3211,y:-87.4183},
{x:47.6099,y:-122.2005},
{x:36.7609,y:-83.0213},
{x:36.684,y:-83.1275},
{x:44.502,y:-70.212},
{x:42.935,y:-76.5662},
{x:45.4933,y:-122.86},
{x:30.5545,y:-88.1712},
{x:34.9727,y:-82.4282},
{x:38.8047,y:-90.3052},
{x:42.7681,y:-78.6119},
{x:31.5823,y:-97.1739},
{x:34.6541,y:-99.3343},
{x:35.8546,y:-78.558},
{x:39.8673,y:-84.8879},
{x:39.3281,y:-76.6095},
{x:41.6815,y:-86.1959},
{x:40.0745,y:-93.6032},
{x:36.4112,y:-82.4739},
{x:32.5065,y:-84.954},
{x:40.7656,y:-73.9306},
{x:32.0695,y:-82.9046},
{x:40.0073,y:-83.0462},
{x:35.9589,y:-95.9996},
{x:36.4987,y:-80.6208},
{x:36.0798,y:-119.0457},
{x:43.5813,y:-116.1728},
{x:32.0496,y:-81.1641},
{x:31.5392,y:-87.8855},
{x:30.2703,y:-87.5888},
{x:32.3377,y:-86.2608},
{x:32.3106,y:-86.3496},
{x:38.9516,y:-76.9685},
{x:32.8429,y:-96.595},
{x:43.0766,y:-86.203},
{x:34.289,y:-77.9227},
{x:43.0477,y:-77.1145},
{x:39.7817,y:-93.5527},
{x:33.6036,y:-88.6586},
{x:33.8284,y:-88.5621},
{x:30.4473,y:-91.188},
{x:39.0983,y:-104.8647},
{x:37.5044,y:-77.642},
{x:40.7131,y:-73.8289},
{x:30.4237,y:-90.0408},
{x:41.9194,y:-83.4151},
{x:44.2727,y:-88.4187},
{x:30.2358,y:-92.6637},
{x:34.2129,y:-92.0033},
{x:40.8294,y:-73.6841},
{x:30.469,y:-83.4104},
{x:33.6534,y:-117.6444},
{x:58.3631,y:-134.578},
{x:42.2502,y:-85.5312},
{x:39.0234,y:-94.2715},
{x:42.8281,y:-106.3553},
{x:34.7181,y:-92.1889},
{x:38.1581,y:-79.0711},
{x:40.8528,y:-99.9856},
{x:40.3345,y:-104.9061},
{x:35.0818,y:-77.033},
{x:39.5595,y:-95.1195},
{x:40.5068,y:-98.9522},
{x:27.8578,y:-97.6315},
{x:37.3144,y:-82.943},
{x:30.3513,y:-81.5484},
{x:39.5667,y:-104.9076},
{x:40.4442,y:-75.9533},
{x:30.8938,y:-102.9001},
{x:44.915,y:-122.9788},
{x:44.5446,y:-93.5621},
{x:33.4542,y:-88.8088},
{x:35.2705,y:-80.8381},
{x:34.136,y:-118.2158},
{x:42.2713,y:-84.4528},
{x:35.9511,y:-78.8391},
{x:41.6522,y:-73.8744},
{x:37.9126,y:-89.8228},
{x:41.5313,y:-75.9479},
{x:34.7295,y:-88.945},
{x:41.5499,y:-87.0371},
{x:33.3653,y:-111.8602},
{x:34.0023,y:-117.8114},
{x:36.9583,y:-76.6025},
{x:45.8108,y:-108.4162},
{x:32.8612,y:-96.8816},
{x:37.773,y:-80.4657},
{x:40.6387,y:-112.0085},
{x:33.5839,y:-112.0121},
{x:45.586,y:-122.4014},
{x:39.3643,y:-76.7136},
{x:36.1002,y:-95.8681},
{x:36.1754,y:-94.183},
{x:41.1538,y:-81.3318},
{x:43.1202,y:-75.2938},
{x:34.6902,y:-82.9922},
{x:34.8034,y:-88.2028},
{x:29.636,y:-83.1291},
{x:31.1993,y:-83.2523},
{x:32.161,y:-81.9035},
{x:35.5492,y:-82.8498},
{x:42.0798,y:-79.2289},
{x:33.7257,y:-117.9201},
{x:40.4592,y:-90.6794},
{x:36.0067,y:-88.4047},
{x:43.3961,y:-72.6862},
{x:18.4418,y:-66.0206},
{x:40.1873,y:-105.1304},
{x:37.1885,y:-77.3699},
{x:40.5745,y:-105.0959},
{x:33.7321,y:-118.2921},
{x:48.4533,y:-122.3318},
{x:47.9025,y:-97.0671},
{x:43.1336,y:-88.1976},
{x:41.5566,y:-87.3561},
{x:35.7846,y:-78.6935},
{x:34.444,y:-118.5114},
{x:45.4018,y:-91.853},
{x:44.5008,y:-88.3304},
{x:39.9004,y:-88.9536},
{x:39.9529,y:-90.2074},
{x:45.4835,y:-89.7293},
{x:41.0276,y:-76.0795},
{x:25.8674,y:-80.2736},
{x:32.105,y:-81.226},
{x:46.4052,y:-105.8205},
{x:39.7444,y:-84.3768},
{x:32.2837,y:-90.1162},
{x:39.2637,y:-80.3268},
{x:39.7967,y:-84.3005},
{x:41.5858,y:-84.5853},
{x:39.6043,y:-78.2385},
{x:41.0714,y:-74.7413},
{x:40.5748,y:-105.0848},
{x:42.9964,y:-78.1749},
{x:39.3277,y:-94.9149},
{x:45.1081,y:-87.6192},
{x:42.8106,y:-86.0391},
{x:32.3297,y:-90.1057},
{x:21.2923,y:-157.8237},
{x:47.6929,y:-114.1515},
{x:30.7611,y:-85.3775},
{x:39.1359,y:-77.705},
{x:41.6071,y:-71.4534},
{x:38.8802,y:-77.3984},
{x:41.2067,y:-87.1983},
{x:33.5784,y:-102.3681},
{x:42.5847,y:-87.8196},
{x:34.8049,y:-82.3743},
{x:44.2972,y:-94.7279},
{x:38.5566,y:-86.6149},
{x:31.295,y:-92.4778},
{x:33.5877,y:-111.837},
{x:33.6105,y:-111.7279},
{x:32.676,y:-114.6007},
{x:32.7131,y:-114.6339},
{x:34.0545,y:-118.3753},
{x:40.4378,y:-99.3756},
{x:43.6421,y:-96.2126},
{x:29.9571,y:-90.0747},
{x:33.4717,y:-81.9883},
{x:40.6966,y:-111.9779},
{x:47.4657,y:-92.5242},
{x:34.1554,y:-87.4107},
{x:37.8506,y:-90.5203},
{x:35.4598,y:-94.3551},
{x:36.1008,y:-79.5072},
{x:34.0454,y:-94.3248},
{x:41.1848,y:-111.9486},
{x:35.9931,y:-80.0321},
{x:42.2799,y:-83.4392},
{x:37.936,y:-86.7568},
{x:40.6392,y:-111.888},
{x:35.8504,y:-101.9728},
{x:42.0712,y:-76.1519},
{x:44.2268,y:-72.5513},
{x:36.8202,y:-76.1205},
{x:42.1506,y:-72.4681},
{x:36.3682,y:-96.0184},
{x:43.0473,y:-76.1507},
{x:29.5392,y:-98.4114},
{x:44.2615,y:-88.2744},
{x:41.4416,y:-81.8009},
{x:47.8828,y:-122.2381},
{x:40.1594,y:-76.3078},
{x:33.8335,y:-79.0596},
{x:38.7231,y:-76.6572},
{x:39.7457,y:-86.272},
{x:40.0024,y:-83.1128},
{x:40.0263,y:-83.0904},
{x:39.9635,y:-82.999},
{x:39.6769,y:-111.841},
{x:46.4062,y:-120.2779},
{x:45.3287,y:-122.5848},
{x:26.7066,y:-80.2312},
{x:43.8197,y:-71.2013},
{x:33.7289,y:-82.7194},
{x:41.2264,y:-81.8408},
{x:27.4355,y:-82.5875},
{x:34.1602,y:-118.2371},
{x:30.5349,y:-83.8698},
{x:26.5719,y:-80.0872},
{x:37.6854,y:-122.3998},
{x:41.6069,y:-80.5681},
{x:29.8852,y:-97.9403},
{x:44.0242,y:-92.7487},
{x:41.7491,y:-81.2102},
{x:42.9731,y:-85.9386},
{x:33.5712,y:-86.5187},
{x:30.6395,y:-88.1873},
{x:40.5813,y:-79.5674},
{x:40.6456,y:-89.6686},
{x:42.193,y:-71.7566},
{x:45.1249,y:-93.4923},
{x:37.9286,y:-90.774},
{x:38.7903,y:-77.0769},
{x:33.6582,y:-117.9696},
{x:35.8648,y:-83.5476},
{x:41.2968,y:-89.078},
{x:41.6613,y:-72.9189},
{x:28.3849,y:-81.5072},
{x:39.6315,y:-104.9875},
{x:42.4759,y:-82.9032},
{x:42.3959,y:-83.4153},
{x:37.5854,y:-122.3654},
{x:35.7804,y:-89.1173},
{x:39.7081,y:-104.8088},
{x:34.2241,y:-119.0385},
{x:34.8654,y:-118.1773},
{x:34.6913,y:-90.3761},
{x:36.7037,y:-97.0853},
{x:36.2976,y:-82.4751},
{x:29.7605,y:-96.1528},
{x:32.4727,y:-100.4027},
{x:38.7922,y:-76.5462},
{x:35.241,y:-89.3388},
{x:42.2708,y:-84.9957},
{x:43.8133,y:-91.3061},
{x:29.5522,y:-81.2302},
{x:35.2126,y:-90.0316},
{x:42.4555,y:-98.634},
{x:42.9306,y:-85.3621},
{x:38.3898,y:-78.9665},
{x:39.948,y:-82.9391},
{x:34.0375,y:-118.146},
{x:41.0559,y:-95.7453},
{x:47.1654,y:-122.5202},
{x:33.5842,y:-92.8506},
{x:34.0278,y:-118.2199},
{x:44.7073,y:-93.4302},
{x:32.9901,y:-80.2244},
{x:32.703,y:-100.9188},
{x:37.556,y:-122.0147},
{x:30.0687,y:-90.4803},
{x:32.884,y:-105.9608},
{x:44.5303,y:-122.9074},
{x:35.448,y:-93.4588},
{x:37.216,y:-93.288},
{x:36.2328,y:-86.5134},
{x:39.5205,y:-76.1857},
{x:48.8477,y:-122.5922},
{x:36.3304,y:-119.2927},
{x:34.0308,y:-118.401},
{x:33.5971,y:-112.0355},
{x:34.1695,y:-118.6054},
{x:41.3136,y:-105.5936},
{x:29.326,y:-96.0974},
{x:41.2928,y:-84.7625},
{x:41.1016,y:-85.0588},
{x:47.6217,y:-122.3128},
{x:37.7316,y:-120.9574},
{x:42.0689,y:-90.6806},
{x:42.6769,y:-83.2897},
{x:42.827,y:-84.543},
{x:39.0789,y:-84.4484},
{x:37.2668,y:-107.0136},
{x:35.3381,y:-119.0605},
{x:41.1067,y:-74.0358},
{x:34.6682,y:-106.7743},
{x:37.0563,y:-120.8799},
{x:32.4569,y:-80.7211},
{x:29.5687,y:-98.3825},
{x:37.4,y:-77.4322},
{x:38.2577,y:-104.6193},
{x:29.5081,y:-98.3092},
{x:33.9488,y:-81.1174},
{x:33.9883,y:-80.9804},
{x:26.1316,y:-81.7742},
{x:39.8008,y:-105.103},
{x:43.0581,y:-85.9551},
{x:45.405,y:-122.7494},
{x:45.557,y:-122.7023},
{x:37.1228,y:-76.3941},
{x:32.7763,y:-97.2901},
{x:38.0317,y:-85.3415},
{x:43.5786,y:-83.8874},
{x:39.3105,y:-85.9655},
{x:34.5805,y:-118.0949},
{x:42.4352,y:-83.3058},
{x:40.0607,y:-82.3643},
{x:31.1176,y:-97.6945},
{x:42.341,y:-85.1546},
{x:31.4424,y:-83.5437},
{x:38.9908,y:-84.6429},
{x:34.0353,y:-84.6845},
{x:40.6574,y:-84.9565},
{x:43.1364,y:-115.6676},
{x:43.1119,y:-94.6895},
{x:26.808,y:-80.1018},
{x:36.877,y:-87.4987},
{x:43.3822,y:-87.9304},
{x:39.3686,y:-101.0558},
{x:15.1752,y:145.7491},
{x:34.2739,y:-118.5545},
{x:45.1893,y:-93.396},
{x:40.625,y:-74.1455},
{x:33.9288,y:-81.0786},
{x:39.6814,y:-75.4801},
{x:42.0699,y:-80.0379},
{x:35.1546,y:-90.1393},
{x:40.6596,y:-75.0726},
{x:32.2962,y:-106.81},
{x:41.5732,y:-87.4034},
{x:32.8119,y:-111.6717},
{x:35.1929,y:-114.0703},
{x:39.3651,y:-111.5862},
{x:32.4551,y:-97.385},
{x:40.7669,y:-111.8905},
{x:35.1316,y:-84.8801},
{x:34.8726,y:-84.3276},
{x:32.8792,y:-96.6665},
{x:38.6163,y:-89.3722},
{x:40.8074,y:-91.1137},
{x:44.7444,y:-85.1759},
{x:30.4043,y:-91.1086},
{x:28.263,y:-81.433},
{x:29.7359,y:-95.306},
{x:36.1801,y:-81.1485},
{x:44.0395,y:-88.7429},
{x:34.0468,y:-118.4482},
{x:45.3683,y:-122.5995},
{x:33.3251,y:-81.1345},
{x:44.7865,y:-69.3992},
{x:30.0834,y:-94.1429},
{x:33.9388,y:-81.1508},
{x:32.9439,y:-103.3601},
{x:37.4766,y:-120.8495},
{x:35.218,y:-94.2725},
{x:40.291,y:-109.988},
{x:33.64,y:-112.01},
{x:32.9584,y:-95.2944},
{x:32.6765,y:-95.4843},
{x:47.1108,y:-122.2932},
{x:33.6671,y:-84.0253},
{x:40.4427,y:-79.9964},
{x:30.1687,y:-85.7903},
{x:21.2864,y:-157.8337},
{x:34.0907,y:-98.5595},
{x:38.3824,y:-85.7696},
{x:32.6357,y:-97.3224},
{x:39.7696,y:-105.0075},
{x:42.5088,y:-82.9283},
{x:44.1128,y:-103.2732},
{x:41.9238,y:-71.882},
{x:40.4613,y:-85.3701},
{x:33.8842,y:-84.466},
{x:41.3362,y:-85.1346},
{x:39.8778,y:-105.0981},
{x:42.2447,y:-71.915},
{x:41.7924,y:-71.3259},
{x:45.397,y:-122.2625},
{x:37.3719,y:-95.2554},
{x:39.8264,y:-86.0129},
{x:34.2127,y:-82.1794},
{x:46.7112,y:-122.9741},
{x:38.8994,y:-77.2684},
{x:28.6745,y:-81.5138},
{x:29.9084,y:-90.3631},
{x:34.288,y:-77.8722},
{x:37.896,y:-122.1032},
{x:41.9241,y:-72.6696},
{x:45.8194,y:-92.9797},
{x:30.638,y:-88.1417},
{x:39.8451,y:-84.1933},
{x:48.5398,y:-117.9047},
{x:39.7574,y:-84.6371},
{x:28.016,y:-81.759},
{x:38.5555,y:-121.3358},
{x:33.9874,y:-80.989},
{x:33.4712,y:-94.4038},
{x:64.7562,y:-147.3541},
{x:35.2226,y:-89.736},
{x:37.7943,y:-89.0275},
{x:47.2555,y:-122.475},
{x:32.8288,y:-96.9761},
{x:43.9867,y:-92.4603},
{x:39.1967,y:-94.5881},
{x:42.2135,y:-73.8774},
{x:37.9289,y:-95.4087},
{x:43.3869,y:-123.3422},
{x:34.4754,y:-93.0616},
{x:38.551,y:-86.4702},
{x:38.4362,y:-82.3845},
{x:32.8116,y:-96.8057},
{x:36.6732,y:-93.8736},
{x:32.8184,y:-115.6743},
{x:37.5406,y:-84.654},
{x:27.893,y:-81.554},
{x:41.6878,y:-86.061},
{x:33.0032,y:-83.5474},
{x:41.3233,y:-72.1943},
{x:38.3453,y:-91.4944},
{x:37.4231,y:-81.0161},
{x:36.8601,y:-76.3963},
{x:39.2048,y:-88.1545},
{x:39.1033,y:-77.1567},
{x:40.3743,y:-111.7843},
{x:37.9453,y:-122.5223},
{x:46.1905,y:-123.8419},
{x:40.4054,y:-104.7346},
{x:41.1391,y:-81.4387},
{x:45.3304,y:-118.0749},
{x:38.252,y:-78.1137},
{x:29.8828,y:-97.6696},
{x:35.7328,y:-79.427},
{x:42.0122,y:-95.3585},
{x:27.8943,y:-82.7582},
{x:45.2018,y:-93.5548},
{x:33.8112,y:-118.1083},
{x:31.529,y:-83.8235},
{x:33.4614,y:-82.5046},
{x:39.5617,y:-97.657},
{x:43.148,y:-93.1711},
{x:39.2474,y:-76.6924},
{x:35.7954,y:-83.5443},
{x:43.1778,y:-83.7638},
{x:32.7206,y:-103.1416},
{x:35.0941,y:-106.7089},
{x:34.8818,y:-82.7032},
{x:37.955,y:-122.0415},
{x:38.1341,y:-122.2216},
{x:45.2296,y:-112.6272},
{x:34.9891,y:-85.2087},
{x:32.9603,y:-96.7127},
{x:33.6614,y:-96.6004},
{x:36.9229,y:-119.8732},
{x:36.3903,y:-86.4367},
{x:36.1358,y:-120.3655},
{x:41.827,y:-86.3568},
{x:36.9006,y:-94.8774},
{x:35.0841,y:-85.0589},
{x:33.9226,y:-84.3138},
{x:30.4271,y:-89.0917},
{x:41.0912,y:-73.5182},
{x:29.6604,y:-95.355},
{x:32.7257,y:-97.3798},
{x:40.7527,y:-73.9734},
{x:37.6846,y:-82.7767},
{x:36.0066,y:-84.2534},
{x:37.0578,y:-76.4266},
{x:37.0454,y:-93.3014},
{x:42.0513,y:-104.961},
{x:34.0456,y:-81.0999},
{x:34.8892,y:-76.9214},
{x:29.3739,y:-95.0914},
{x:43.8078,y:-99.3276},
{x:34.8843,y:-92.3833},
{x:29.4673,y:-98.6473},
{x:35.9995,y:-83.7772},
{x:38.9789,y:-92.2948},
{x:37.646,y:-118.9676},
{x:32.3028,y:-81.8733},
{x:42.9593,y:-87.9888},
{x:30.4156,y:-88.8154},
{x:46.701,y:-92.456},
{x:27.695,y:-97.3458},
{x:46.6467,y:-120.531},
{x:42.6057,y:-83.2683},
{x:38.8799,y:-77.3032},
{x:39.2166,y:-86.5936},
{x:41.9454,y:-91.6758},
{x:30.7853,y:-83.5726},
{x:39.889,y:-86.0451},
{x:41.8246,y:-71.4364},
{x:36.2271,y:-83.2647},
{x:34.1591,y:-85.672},
{x:36.0177,y:-95.9053},
{x:38.9818,y:-77.0927},
{x:42.8688,y:-100.55},
{x:33.5224,y:-112.1515},
{x:36.7104,y:-82.7972},
{x:35.9678,y:-88.9311},
{x:33.4248,y:-84.1837},
{x:40.7339,y:-82.803},
{x:39.366,y:-107.031},
{x:27.7571,y:-97.3915},
{x:44.2754,y:-88.3238},
{x:34.0204,y:-118.356},
{x:34.0354,y:-118.3783},
{x:34.0135,y:-118.3365},
{x:29.6469,y:-81.672},
{x:40.4778,y:-85.9668},
{x:33.6611,y:-114.2355},
{x:47.4507,y:-122.8276},
{x:40.9005,y:-74.1156},
{x:37.3163,y:-76.7378},
{x:33.7986,y:-84.2243},
{x:44.7857,y:-89.6843},
{x:43.2143,y:-75.4511},
{x:32.4669,y:-99.7317},
{x:33.8035,y:-118.1665},
{x:35.037,y:-120.4873},
{x:39.9627,y:-86.117},
{x:31.9461,y:-87.7403},
{x:35.4635,y:-84.6456},
{x:41.8224,y:-90.2126},
{x:42.9134,y:-88.0091},
{x:38.7424,y:-104.7378},
{x:25.7749,y:-80.2641},
{x:30.991,y:-83.3732},
{x:31.9363,y:-81.9288},
{x:39.6297,y:-77.7014},
{x:35.0215,y:-85.3659},
{x:30.3506,y:-89.1543},
{x:41.6911,y:-97.9978},
{x:46.9013,y:-102.7894},
{x:35.0795,y:-106.3879},
{x:35.5956,y:-92.4561},
{x:36.0375,y:-83.445},
{x:39.1303,y:-76.7364},
{x:35.4494,y:-89.7811},
{x:33.4954,y:-111.9883},
{x:38.0481,y:-87.2869},
{x:41.9563,y:-75.2916},
{x:38.1918,y:-78.9923},
{x:47.6084,y:-122.1416},
{x:39.7817,y:-86.1304},
{x:47.4599,y:-122.2905},
{x:44.4284,y:-83.3306},
{x:36.523,y:-84.4996},
{x:34.249,y:-88.4036},
{x:35.32,y:-106.5483},
{x:28.0564,y:-81.7795},
{x:40.8925,y:-83.8721},
{x:41.3199,y:-81.6254},
{x:30.3059,y:-89.3777},
{x:30.4478,y:-89.0303},
{x:32.8921,y:-84.3264},
{x:45.3009,y:-88.5248},
{x:33.9269,y:-118.3516},
{x:42.2607,y:-85.6118},
{x:34.3301,y:-78.8278},
{x:40.0435,y:-75.5296},
{x:45.6637,y:-122.5611},
{x:38.831,y:-104.8408},
{x:33.8283,y:-84.4915},
{x:32.338,y:-97.4115},
{x:43.4082,y:-84.6076},
{x:42.916,y:-112.4661},
{x:33.94,y:-83.386},
{x:29.5792,y:-98.4435},
{x:41.0064,y:-76.4344},
{x:43.195,y:-112.3612},
{x:41.4823,y:-75.6899},
{x:35.6771,y:-83.7515},
{x:25.7922,y:-80.2106},
{x:25.8669,y:-80.2983},
{x:32.8796,y:-96.7713},
{x:37.1142,y:-79.2828},
{x:43.1447,y:-123.3824},
{x:44.1988,y:-69.0795},
{x:37.7643,y:-81.1191},
{x:46.4503,y:-95.128},
{x:40.351,y:-83.7607},
{x:36.3416,y:-119.2785},
{x:33.5259,y:-80.8908},
{x:37.5079,y:-120.8752},
{x:35.0682,y:-90.059},
{x:32.1666,y:-90.2887},
{x:31.7108,y:-98.9785},
{x:34.5547,y:-90.656},
{x:35.1095,y:-106.6199},
{x:39.0495,y:-95.7253},
{x:34.8534,y:-87.563},
{x:42.1966,y:-84.401},
{x:43.6513,y:-116.6685},
{x:39.1287,y:-94.8314},
{x:44.1808,y:-94.0065},
{x:44.1794,y:-94.0376},
{x:29.8486,y:-95.2636},
{x:37.1465,y:-92.2595},
{x:38.7773,y:-104.8143},
{x:32.268,y:-107.7623},
{x:43.6208,y:-116.2044},
{x:41.5659,y:-83.6552},
{x:33.7564,y:-79.4475},
{x:34.8936,y:-82.289},
{x:46.0112,y:-92.9281},
{x:29.3875,y:-100.905},
{x:44.4153,y:-72.0168},
{x:41.6301,y:-83.6643},
{x:39.6311,y:-84.9313},
{x:29.8923,y:-97.9193},
{x:41.6588,y:-85.9705},
{x:36.6123,y:-93.2305},
{x:41.7219,y:-83.5465},
{x:41.2221,y:-85.3171},
{x:39.2352,y:-120.0203},
{x:36.3922,y:-81.4823},
{x:35.9783,y:-77.9763},
{x:34.7358,y:-77.3795},
{x:29.5505,y:-98.3456},
{x:60.5598,y:-151.2381},
{x:45.6505,y:-95.3919},
{x:41.2692,y:-96.2351},
{x:44.6487,y:-124.0525},
{x:61.5991,y:-149.1198},
{x:40.9226,y:-75.3999},
{x:42.7716,y:-83.0164},
{x:40.5084,y:-81.6281},
{x:36.1215,y:-80.6607},
{x:38.5504,y:-121.6932},
{x:35.7462,y:-95.4038},
{x:34.1994,y:-79.26},
{x:40.9587,y:-75.9924},
{x:34.3539,y:-82.9331},
{x:41.31,y:-81.5174},
{x:42.9734,y:-73.7953},
{x:36.7174,y:-79.9114},
{x:35.4273,y:-119.0211},
{x:33.9056,y:-80.3768},
{x:39.1064,y:-77.5634},
{x:40.7368,y:-89.0291},
{x:44.8417,y:-93.7889},
{x:42.6533,y:-83.5763},
{x:41.3246,y:-75.806},
{x:36.127,y:-96.3609},
{x:47.1016,y:-94.5846},
{x:32.2816,y:-83.4793},
{x:37.6808,y:-113.081},
{x:30.3079,y:-97.7494},
{x:41.8101,y:-87.6749},
{x:31.7808,y:-85.9395},
{x:40.26,y:-103.801},
{x:45.8347,y:-119.2878},
{x:40.0773,y:-80.9149},
{x:38.2326,y:-86.1337},
{x:37.4322,y:-86.8862},
{x:45.4756,y:-122.8202},
{x:34.9195,y:-82.0005},
{x:35.9993,y:-115.1043},
{x:34.0962,y:-96.7747},
{x:37.9178,y:-122.0377},
{x:33.891,y:-84.7574},
{x:41.4296,y:-84.878},
{x:36.8687,y:-88.3627},
{x:40.7179,y:-81.1179},
{x:44.2219,y:-123.2046},
{x:32.3856,y:-90.1417},
{x:31.4211,y:-94.806},
{x:34.1426,y:-109.9541},
{x:39.6052,y:-119.8408},
{x:40.369,y:-85.2146},
{x:38.9151,y:-76.8826},
{x:34.2956,y:-79.8844},
{x:34.518,y:-97.9673},
{x:42.7737,y:-73.6973},
{x:33.8862,y:-118.1607},
{x:38.3129,y:-77.4775},
{x:35.7395,y:-81.6783},
{x:40.7721,y:-111.9398},
{x:29.6756,y:-95.4772},
{x:40.4937,y:-77.1129},
{x:34.1373,y:-118.3637},
{x:36.2671,y:-90.2901},
{x:44.0251,y:-116.9871},
{x:44.0603,y:-122.9987},
{x:37.8991,y:-86.9276},
{x:36.0566,y:-79.8976},
{x:35.726,y:-78.6542},
{x:31.6324,y:-89.5598},
{x:34.4781,y:-85.349},
{x:37.2723,y:-79.8852},
{x:30.5096,y:-97.8241},
{x:34.0487,y:-84.2221},
{x:30.6546,y:-97.003},
{x:28.1253,y:-81.6392},
{x:30.1787,y:-96.9138},
{x:40.3806,y:-95.8376},
{x:34.8539,y:-87.6911},
{x:35.3103,y:-120.8288},
{x:40.7802,y:-96.7069},
{x:38.884,y:-77.0196},
{x:42.2457,y:-85.5897},
{x:34.5393,y:-80.5822},
{x:35.7348,y:-81.3661},
{x:47.5789,y:-122.3296},
{x:48.0677,y:-114.0887},
{x:36.3276,y:-82.836},
{x:42.2818,y:-85.6222},
{x:39.8252,y:-86.16},
{x:39.292,y:-80.2773},
{x:32.4766,y:-93.676},
{x:41.3363,y:-72.4176},
{x:35.7698,y:-84.3217},
{x:36.1653,y:-84.0848},
{x:40.4402,y:-80.0025},
{x:46.489,y:-87.6738},
{x:38.4728,y:-100.9074},
{x:37.1287,y:-94.4759},
{x:30.8127,y:-95.1105},
{x:46.832,y:-96.8228},
{x:34.771,y:-92.3982},
{x:43.9719,y:-92.0686},
{x:39.5557,y:-86.089},
{x:36.8099,y:-119.7253},
{x:31.0966,y:-97.721},
{x:40.778,y:-76.2286},
{x:34.1701,y:-79.7643},
{x:34.7585,y:-83.0522},
{x:36.2877,y:-76.25},
{x:34.1152,y:-118.2458},
{x:34.6941,y:-82.2018},
{x:33.2438,y:-81.3641},
{x:46.8276,y:-100.7737},
{x:35.7431,y:-79.6525},
{x:33.5227,y:-84.4227},
{x:40.0463,y:-86.012},
{x:35.4474,y:-86.7786},
{x:37.5396,y:-77.4366},
{x:48.2206,y:-101.2965},
{x:47.2024,y:-122.31},
{x:35.8009,y:-83.5848},
{x:35.2082,y:-81.1647},
{x:33.6152,y:-79.0481},
{x:35.5074,y:-78.3216},
{x:36.8205,y:-119.7913},
{x:33.7389,y:-84.4262},
{x:36.6227,y:-78.5654},
{x:40.788,y:-82.7342},
{x:35.2626,y:-77.589},
{x:33.9435,y:-118.2446},
{x:34.1608,y:-99.3133},
{x:33.8115,y:-84.2731},
{x:47.274,y:-122.2284},
{x:39.9865,y:-81.1779},
{x:48.5558,y:-113.0156},
{x:41.1564,y:-80.5798},
{x:42.7623,y:-105.4041},
{x:41.702,y:-85.8871},
{x:38.9261,y:-78.1944},
{x:43.5633,y:-116.5726},
{x:39.2927,y:-76.6155},
{x:46.8596,y:-96.8437},
{x:30.1907,y:-97.7679},
{x:44.309,y:-71.792},
{x:32.6827,y:-117.1788},
{x:40.9199,y:-90.6511},
{x:43.2142,y:-123.3602},
{x:46.9766,y:-123.8051},
{x:46.7648,y:-114.0798},
{x:46.0095,y:-112.5388},
{x:31.8884,y:-102.3558},
{x:32.9439,y:-96.6207},
{x:33.5412,y:-82.1318},
{x:39.8527,y:-79.912},
{x:38.3615,y:-98.7766},
{x:44.8635,y:-93.3541},
{x:28.1884,y:-82.6677},
{x:40.6825,y:-111.9876},
{x:40.3355,y:-75.926},
{x:33.5252,y:-112.2036},
{x:47.7311,y:-122.1728},
{x:44.9033,y:-116.0983},
{x:36.8127,y:-119.7484},
{x:30.2594,y:-94.1992},
{x:43.6486,y:-94.0936},
{x:35.6048,y:-85.1975},
{x:35.5939,y:-78.1285},
{x:34.9812,y:-79.2367},
{x:33.663,y:-112.0303},
{x:42.5826,y:-88.5411},
{x:40.005,y:-85.7699},
{x:37.3921,y:-76.5265},
{x:29.5889,y:-98.6234},
{x:32.8703,y:-97.2622},
{x:41.6931,y:-86.1866},
{x:25.713,y:-80.4143},
{x:33.9626,y:-83.3977},
{x:44.0315,y:-91.6219},
{x:36.3801,y:-80.4796},
{x:41.2443,y:-112.0178},
{x:28.2141,y:-80.674},
{x:29.5312,y:-98.574},
{x:33.3655,y:-97.1787},
{x:42.8361,y:-108.7442},
{x:44.8798,y:-93.0018},
{x:29.6647,y:-95.0874},
{x:32.3512,y:-81.2915},
{x:27.8008,y:-82.6792},
{x:39.5905,y:-89.6528},
{x:37.1204,y:-87.8954},
{x:48.1968,y:-106.6375},
{x:25.9137,y:-80.328},
{x:36.1887,y:-86.2972},
{x:42.2703,y:-83.27},
{x:42.3255,y:-83.3404},
{x:39.5425,y:-110.6863},
{x:37.093,y:-81.8208},
{x:47.7785,y:-96.6044},
{x:31.5728,y:-102.8927},
{x:33.9722,y:-112.7241},
{x:38.1414,y:-89.7038},
{x:43.1205,y:-124.3992},
{x:36.0359,y:-115.0991},
{x:35.6494,y:-88.1272},
{x:37.2319,y:-87.1623},
{x:47.7154,y:-116.9484},
{x:42.572,y:-82.8511},
{x:21.6467,y:-157.9218},
{x:41.3519,y:-74.7224},
{x:37.9947,y:-121.8071},
{x:33.6576,y:-84.4059},
{x:40.3891,y:-111.8352},
{x:42.5251,y:-113.7927},
{x:44.1715,y:-92.1655},
{x:41.4621,y:-74.052},
{x:31.3464,y:-89.2669},
{x:41.6786,y:-85.5807},
{x:47.203,y:-122.2303},
{x:42.2916,y:-85.5835},
{x:40.052,y:-74.843},
{x:43.0808,y:-75.2066},
{x:39.9323,y:-85.8466},
{x:35.2115,y:-101.9038},
{x:33.6231,y:-85.5945},
{x:39.8629,y:-105.0524},
{x:38.9727,y:-95.2893},
{x:38.0072,y:-91.6172},
{x:42.0424,y:-97.4128},
{x:31.6038,y:-97.0999},
{x:46.5577,y:-87.3961},
{x:43.5904,y:-116.2671},
{x:45.3759,y:-94.7297},
{x:40.0144,y:-86.9035},
{x:34.5414,y:-77.9092},
{x:40.6165,y:-75.4438},
{x:40.7637,y:-84.1523},
{x:39.7868,y:-105.1088},
{x:36.269,y:-80.8533},
{x:40.7293,y:-84.0702},
{x:37.6778,y:-121.785},
{x:40.1972,y:-84.8102},
{x:40.4761,y:-85.4942},
{x:37.6374,y:-120.7675},
{x:45.4096,y:-83.8254},
{x:32.5009,y:-94.7381},
{x:40.3865,y:-86.8324},
{x:33.9078,y:-98.5305},
{x:41.2302,y:-75.9245},
{x:27.879,y:-97.2039},
{x:29.6851,y:-95.5292},
{x:40.9599,y:-85.3713},
{x:30.4464,y:-86.5788},
{x:36.2772,y:-88.7068},
{x:36.0734,y:-79.9573},
{x:44.7537,y:-85.5726},
{x:31.5337,y:-82.4634},
{x:33.3213,y:-91.2925},
{x:39.8671,y:-77.0458},
{x:41.7637,y:-88.3681},
{x:32.8784,y:-81.1218},
{x:35.853,y:-79.5739},
{x:35.3347,y:-97.4746},
{x:43.8043,y:-123.0453},
{x:34.563,y:-92.337},
{x:42.6593,y:-83.4431},
{x:30.0666,y:-94.1889},
{x:36.5416,y:-119.8329},
{x:33.4587,y:-117.6514},
{x:26.1644,y:-80.2026},
{x:29.698,y:-91.1948},
{x:32.6433,y:-85.3514},
{x:36.7125,y:-81.9316},
{x:43.5282,y:-96.6706},
{x:35.0355,y:-110.6979},
{x:28.0952,y:-82.4009},
{x:34.3183,y:-83.8307},
{x:40.2913,y:-87.2454},
{x:43.4943,y:-88.5569},
{x:39.3452,y:-80.0026},
{x:36.8266,y:-107.9931},
{x:36.9486,y:-86.4264},
{x:39.2168,y:-84.5492},
{x:41.5152,y:-87.9733},
{x:38.6499,y:-90.3364},
{x:38.651,y:-90.3479},
{x:36.7866,y:-76.0016},
{x:36.7613,y:-83.696},
{x:35.2187,y:-92.3884},
{x:42.5244,y:-96.4915},
{x:40.9265,y:-73.1186},
{x:32.8551,y:-84.619},
{x:39.837,y:-86.3794},
{x:47.6433,y:-117.5889},
{x:41.6337,y:-111.8333},
{x:40.1602,y:-104.9817},
{x:39.7644,y:-86.2898},
{x:36.1864,y:-82.9341},
{x:43.2419,y:-78.1941},
{x:32.7437,y:-83.713},
{x:34.0127,y:-81.04},
{x:38.9735,y:-95.2543},
{x:39.8429,y:-105.11},
{x:37.4161,y:-121.8962},
{x:33.9095,y:-81.5353},
{x:43.3762,y:-99.8464},
{x:34.0713,y:-81.1756},
{x:36.6715,y:-80.9184},
{x:21.2983,y:-157.8484},
{x:41.0133,y:-75.2935},
{x:48.1497,y:-122.1836},
{x:33.0364,y:-97.0746},
{x:33.9611,y:-85.9376},
{x:41.2883,y:-72.6812},
{x:26.5284,y:-80.0766},
{x:43.2215,y:-88.1151},
{x:42.5333,y:-83.1352},
{x:29.9782,y:-92.1095},
{x:31.4706,y:-83.5035},
{x:35.2021,y:-106.5692},
{x:36.0654,y:-102.52},
{x:36.9701,y:-122.0253},
{x:35.1786,y:-101.8659},
{x:43.4828,y:-112.0145},
{x:28.1007,y:-82.6945},
{x:39.0459,y:-84.2441},
{x:37.2099,y:-86.694},
{x:37.8985,y:-87.1103},
{x:42.276,y:-71.7369},
{x:37.7418,y:-79.3579},
{x:39.272,y:-84.3271},
{x:37.4682,y:-86.2928},
{x:42.8146,y:-83.7808},
{x:36.4378,y:-103.1764},
{x:35.7789,y:-78.6397},
{x:35.1505,y:-79.4191},
{x:37.2979,y:-120.476},
{x:33.1382,y:-91.9535},
{x:42.7965,y:-82.7342},
{x:36.2981,y:-119.1562},
{x:27.6974,y:-97.3758},
{x:30.2315,y:-97.7352},
{x:34.2328,y:-79.8022},
{x:35.1422,y:-93.8992},
{x:61.1955,y:-149.9083},
{x:35.0158,y:-114.6419},
{x:35.1637,y:-106.4954},
{x:43.7694,y:-88.4308},
{x:39.0927,y:-77.0457},
{x:34.7775,y:-92.1282},
{x:32.8059,y:-96.6818},
{x:42.4681,y:-83.3948},
{x:32.7482,y:-81.6434},
{x:32.7216,y:-96.8339},
{x:39.3299,y:-82.1013},
{x:47.7562,y:-122.0818},
{x:41.1372,y:-102.9768},
{x:42.1255,y:-89.2633},
{x:30.4815,y:-81.6471},
{x:34.7623,y:-84.9327},
{x:42.7909,y:-88.3897},
{x:43.4245,y:-103.4657},
{x:26.9386,y:-80.0944},
{x:42.136,y:-98.0321},
{x:44.418,y:-103.5338},
{x:43.3296,y:-88.278},
{x:32.411,y:-99.7761},
{x:47.8384,y:-122.1256},
{x:44.2497,y:-84.2261},
{x:44.1412,y:-84.1268},
{x:33.5948,y:-96.1936},
{x:37.7739,y:-87.1562},
{x:33.3791,y:-112.0653},
{x:41.6165,y:-86.7326},
{x:47.9325,y:-97.3643},
{x:33.1934,y:-117.3578},
{x:42.1701,y:-88.4277},
{x:33.8478,y:-118.2638},
{x:39.8074,y:-104.929},
{x:32.4352,y:-94.945},
{x:33.9295,y:-118.3241},
{x:34.1769,y:-84.4174},
{x:33.1944,y:-80.5989},
{x:41.7646,y:-111.8332},
{x:40.432,y:-111.7838},
{x:37.6454,y:-98.7241},
{x:38.869,y:-77.4468},
{x:33.9458,y:-118.3335},
{x:43.207,y:-77.691},
{x:44.7322,y:-92.4839},
{x:36.133,y:-80.2785},
{x:40.4545,y:-76.9251},
{x:48.1153,y:-123.434},
{x:29.4065,y:-98.6287},
{x:29.7771,y:-96.1068},
{x:38.161,y:-121.6961},
{x:43.8871,y:-70.3298},
{x:47.0042,y:-123.4056},
{x:30.6739,y:-96.3735},
{x:35.1419,y:-106.586},
{x:46.6073,y:-122.9062},
{x:38.4183,y:-89.9097},
{x:43.6381,y:-91.5025},
{x:48.9369,y:-122.4849},
{x:32.7091,y:-97.1903},
{x:37.0947,y:-93.8183},
{x:46.9345,y:-122.5911},
{x:39.3533,y:-84.4599},
{x:39.1019,y:-94.4448},
{x:42.1272,y:-77.9639},
{x:37.1623,y:-83.3746},
{x:33.895,y:-94.7985},
{x:41.3704,y:-88.4216},
{x:26.5032,y:-81.8572},
{x:30.5541,y:-91.0388},
{x:42.2401,y:-84.4331},
{x:42.4801,y:-88.3067},
{x:39.484,y:-77.3397},
{x:29.3986,y:-98.3852},
{x:36.9737,y:-120.0422},
{x:37.8607,y:-84.6622},
{x:36.2487,y:-83.8047},
{x:39.9002,y:-82.8811},
{x:25.9285,y:-80.1955},
{x:39.1596,y:-89.4936},
{x:47.5816,y:-122.3008},
{x:43.2349,y:-123.3711},
{x:26.8999,y:-99.2654},
{x:35.0818,y:-82.0592},
{x:35.0644,y:-80.728},
{x:47.1991,y:-122.442},
{x:42.3141,y:-83.2719},
{x:30.4383,y:-97.6961},
{x:27.9609,y:-82.7223},
{x:36.0942,y:-79.4394},
{x:33.3238,y:-105.6296},
{x:36.1954,y:-115.0625},
{x:35.141,y:-90.0359},
{x:33.7738,y:-116.9592},
{x:36.3916,y:-97.89},
{x:41.3066,y:-112.0239},
{x:35.021,y:-89.9357},
{x:40.5536,y:-85.2761},
{x:41.4078,y:-85.4231},
{x:35.1712,y:-89.8362},
{x:35.4054,y:-97.5285},
{x:36.5826,y:-84.1043},
{x:41.7331,y:-71.1754},
{x:40.4538,y:-86.9192},
{x:28.782,y:-96.9587},
{x:32.4775,y:-99.6989},
{x:34.575,y:-111.8836},
{x:34.3275,y:-112.1176},
{x:40.417,y:-86.8575},
{x:40.8389,y:-75.6743},
{x:34.824,y:-89.9903},
{x:43.0233,y:-88.2408},
{x:38.9507,y:-77.4099},
{x:32.5593,y:-92.9138},
{x:41.5515,y:-95.9107},
{x:38.8504,y:-106.1379},
{x:38.6025,y:-90.4406},
{x:41.2551,y:-82.4048},
{x:35.3832,y:-119.1452},
{x:41.122,y:-80.7687},
{x:37.5575,y:-83.3897},
{x:37.6968,y:-83.9779},
{x:42.2658,y:-89.0379},
{x:38.2201,y:-84.5404},
{x:42.3102,y:-89.0273},
{x:39.2155,y:-84.517},
{x:34.8226,y:-102.4053},
{x:39.6593,y:-106.826},
{x:37.289,y:-85.9016},
{x:33.988,y:-118.1863},
{x:43.3958,y:-70.531},
{x:34.1708,y:-118.57},
{x:36.0241,y:-78.4804},
{x:31.5657,y:-93.4877},
{x:38.2121,y:-89.994},
{x:37.5768,y:-101.3649},
{x:36.7637,y:-86.5656},
{x:43.3735,y:-83.5938},
{x:42.5944,y:-83.0875},
{x:31.2179,y:-81.5188},
{x:30.3027,y:-89.8266},
{x:40.7782,y:-99.7481},
{x:41.6886,y:-93.0764},
{x:44.3543,y:-106.6935},
{x:41.1453,y:-95.9126},
{x:42.3218,y:-72.6321},
{x:34.0012,y:-118.3948},
{x:32.5456,y:-95.8621},
{x:42.4623,y:-83.1042},
{x:28.8507,y:-81.1628},
{x:33.4227,y:-111.8554},
{x:34.2179,y:-80.2485},
{x:43.3612,y:-72.1725},
{x:34.7538,y:-86.7489},
{x:40.2885,y:-78.8669},
{x:40.6546,y:-95.858},
{x:42.7657,y:-78.7437},
{x:44.9393,y:-122.9854},
{x:33.4795,y:-112.047},
{x:42.8483,y:-106.2992},
{x:47.181,y:-122.4617},
{x:39.0731,y:-84.3309},
{x:41.6321,y:-72.4592},
{x:33.5691,y:-97.8353},
{x:36.9628,y:-93.7181},
{x:39.1894,y:-96.6134},
{x:31.4793,y:-100.4555},
{x:36.5094,y:-87.2791},
{x:41.6405,y:-84.9995},
{x:33.6713,y:-79.8363},
{x:30.4568,y:-88.8947},
{x:43.6005,y:-83.1847},
{x:34.8143,y:-79.1818},
{x:31.3609,y:-92.4127},
{x:36.5064,y:-82.5537},
{x:38.172,y:-80.1345},
{x:35.0799,y:-84.0354},
{x:47.7534,y:-90.3265},
{x:42.3923,y:-83.056},
{x:35.3681,y:-94.3823},
{x:32.2648,y:-101.49},
{x:39.3997,y:-76.5428},
{x:39.6559,y:-77.1706},
{x:42.9697,y:-85.6401},
{x:36.6057,y:-119.5309},
{x:38.7224,y:-82.8591},
{x:34.6841,y:-92.344},
{x:41.4344,y:-90.1586},
{x:35.9937,y:-79.9371},
{x:35.8877,y:-78.6783},
{x:42.9248,y:-83.996},
{x:41.5417,y:-90.522},
{x:33.1461,y:-107.2471},
{x:40.9513,y:-83.3832},
{x:41.4622,y:-81.9562},
{x:46.97,y:-123.8028},
{x:40.181,y:-85.4638},
{x:40.6439,y:-75.4045},
{x:31.8978,y:-98.6083},
{x:43.886,y:-85.5228},
{x:38.4626,y:-78.0014},
{x:36.239,y:-115.1669},
{x:32.7689,y:-97.3025},
{x:42.0722,y:-83.9715},
{x:38.5442,y:-106.9269},
{x:40.7122,y:-81.5977},
{x:42.4893,y:-96.368},
{x:45.0353,y:-93.7794},
{x:35.16,y:-106.5565},
{x:36.1924,y:-86.7996},
{x:44.048,y:-83.7063},
{x:41.5779,y:-73.8124},
{x:30.4371,y:-88.5276},
{x:39.8843,y:-104.9398},
{x:30.1829,y:-82.611},
{x:35.0512,y:-78.9759},
{x:40.9656,y:-76.6004},
{x:31.4277,y:-83.5162},
{x:38.3213,y:-81.717},
{x:40.3954,y:-84.1642},
{x:43.9955,y:-96.3078},
{x:39.9591,y:-86.1542},
{x:30.6488,y:-91.1569},
{x:34.8706,y:-85.8144},
{x:37.9379,y:-77.4658},
{x:35.3686,y:-120.8447},
{x:39.476,y:-80.1515},
{x:32.1779,y:-110.9812},
{x:29.935,y:-90.0787},
{x:39.762,y:-81.1165},
{x:27.0185,y:-80.463},
{x:32.356,y:-90.1503},
{x:39.0432,y:-108.5537},
{x:33.0723,y:-96.7485},
{x:29.3096,y:-98.6153},
{x:32.2502,y:-83.7414},
{x:36.0082,y:-76.9556},
{x:36.5424,y:-82.3329},
{x:37.5837,y:-79.0477},
{x:40.8617,y:-111.8949},
{x:39.577,y:-76.9983},
{x:42.6657,y:-73.7733},
{x:34.026,y:-118.278},
{x:34.2937,y:-118.4155},
{x:30.4874,y:-90.8718},
{x:35.5221,y:-97.6193},
{x:38.4361,y:-82.0206},
{x:42.5005,y:-71.7343},
{x:38.7049,y:-91.4375},
{x:38.2989,y:-111.4021},
{x:45.4909,y:-122.4769},
{x:40.3291,y:-79.7339},
{x:39.7752,y:-85.991},
{x:33.5083,y:-84.1402},
{x:41.3424,y:-83.4178},
{x:30.4762,y:-90.0929},
{x:30.2403,y:-92.0118},
{x:38.6127,y:-76.9275},
{x:39.7105,y:-104.9879},
{x:42.3628,y:-83.2748},
{x:31.3742,y:-84.9381},
{x:34.9428,y:-80.979},
{x:43.5112,y:-88.2227},
{x:37.7895,y:-86.4901},
{x:38.8893,y:-76.9368},
{x:34.759,y:-92.4511},
{x:34.1826,y:-93.0693},
{x:40.5142,y:-107.5583},
{x:43.2841,y:-92.8106},
{x:30.045,y:-94.8876},
{x:39.1776,y:-76.843},
{x:41.5844,y:-93.789},
{x:32.2357,y:-110.8761},
{x:41.7764,y:-107.2241},
{x:32.9725,y:-82.8105},
{x:37.9909,y:-103.5695},
{x:32.3274,y:-111.2184},
{x:32.8317,y:-109.7165},
{x:37.8693,y:-122.2917},
{x:36.8431,y:-83.317},
{x:41.5307,y:-72.7831},
{x:42.6502,y:-83.4939},
{x:38.5074,y:-85.324},
{x:41.1359,y:-83.6604},
{x:33.4952,y:-112.9391},
{x:32.9499,y:-112.7055},
{x:41.5213,y:-87.647},
{x:29.8643,y:-95.6066},
{x:44.2591,y:-88.3605},
{x:35.3765,y:-85.3882},
{x:38.7301,y:-77.7925},
{x:41.6092,y:-83.5384},
{x:32.9603,y:-87.1695},
{x:38.8668,y:-98.8549},
{x:29.3799,y:-94.9718},
{x:42.0939,y:-111.8771},
{x:36.8227,y:-119.8712},
{x:34.4023,y:-119.7222},
{x:28.8279,y:-81.3235},
{x:38.4574,y:-121.8428},
{x:41.8173,y:-72.8693},
{x:21.3927,y:-157.9838},
{x:38.2879,y:-85.5752},
{x:37.6892,y:-87.9035},
{x:36.0408,y:-115.1171},
{x:43.0823,y:-98.3063},
{x:39.6671,y:-105.1506},
{x:41.1627,y:-80.6641},
{x:47.7921,y:-117.3517},
{x:40.1186,y:-82.1171},
{x:36.0186,y:-83.8252},
{x:36.0844,y:-83.9304},
{x:35.9112,y:-83.8444},
{x:41.3664,y:-85.073},
{x:32.8566,y:-116.9713},
{x:39.6203,y:-79.9902},
{x:41.0691,y:-85.0359},
{x:43.7749,y:-87.7369},
{x:26.0071,y:-80.3822},
{x:34.8552,y:-117.0895},
{x:41.0721,y:-85.1996},
{x:34.8709,y:-83.4004},
{x:40.3236,y:-111.7058},
{x:39.9595,y:-86.0385},
{x:40.4358,y:-80.2345},
{x:40.7723,y:-80.7672},
{x:35.3487,y:-94.3929},
{x:37.6878,y:-122.1383},
{x:34.2929,y:-92.3986},
{x:30.5724,y:-98.2761},
{x:30.3369,y:-97.3729},
{x:28.8223,y:-96.9493},
{x:38.199,y:-95.7383},
{x:33.4578,y:-90.6456},
{x:35.3038,y:-93.1388},
{x:33.9013,y:-91.4914},
{x:37.0961,y:-88.6933},
{x:38.0609,y:-85.7031},
{x:38.9193,y:-84.6256},
{x:36.6925,y:-87.4518},
{x:36.4854,y:-79.7371},
{x:42.8124,y:-85.7232},
{x:34.367,y:-96.1411},
{x:34.1994,y:-118.1898},
{x:33.8033,y:-84.0413},
{x:37.147,y:-84.1136},
{x:31.6083,y:-89.2015},
{x:39.9702,y:-83.0003},
{x:42.7704,y:-88.9368},
{x:43.2499,y:-89.3703},
{x:33.4194,y:-110.8126},
{x:34.1077,y:-117.8284},
{x:34.2472,y:-116.8836},
{x:24.572,y:-81.7597},
{x:34.1057,y:-118.3371},
{x:38.1444,y:-97.4224},
{x:29.802,y:-91.5004},
{x:31.6041,y:-83.2505},
{x:33.9388,y:-117.2474},
{x:43.1478,y:-89.2879},
{x:33.9888,y:-118.33},
{x:33.0825,y:-80.6478},
{x:37.9321,y:-122.0586},
{x:39.0826,y:-84.1529},
{x:43.5739,y:-89.7795},
{x:31.5789,y:-90.4721},
{x:42.9928,y:-85.075},
{x:33.4596,y:-112.7837},
{x:29.6832,y:-91.2653},
{x:30.4128,y:-91.0525},
{x:29.045,y:-95.4502},
{x:36.1428,y:-115.2797},
{x:38.9501,y:-122.6306},
{x:37.6901,y:-85.2346},
{x:37.1394,y:-93.3188},
{x:39.6513,y:-83.5463},
{x:40.0644,y:-85.6498},
{x:37.1245,y:-113.5297},
{x:40.6355,y:-82.2383},
{x:42.4866,y:-94.2076},
{x:39.0442,y:-77.4736},
{x:38.1396,y:-78.1948},
{x:39.0092,y:-96.8354},
{x:42.7315,y:-93.7356},
{x:35.8479,y:-90.6767},
{x:46.4109,y:-86.6536},
{x:39.6555,y:-105.048},
{x:42.3783,y:-89.8235},
{x:41.2479,y:-85.8808},
{x:35.1961,y:-83.821},
{x:37.5808,y:-81.3843},
{x:37.7697,y:-81.3353},
{x:30.2291,y:-93.3251},
{x:39.5542,y:-119.7828},
{x:42.1269,y:-83.2024},
{x:36.3562,y:-94.1805},
{x:41.1408,y:-85.1631},
{x:32.4494,y:-99.7469},
{x:28.9251,y:-81.1877},
{x:34.5382,y:-83.9863},
{x:34.1913,y:-77.9298},
{x:48.3438,y:-122.6712},
{x:43.6886,y:-86.3859},
{x:29.7854,y:-95.821},
{x:42.7272,y:-82.5104},
{x:39.7238,y:-94.785},
{x:39.2973,y:-76.5923},
{x:39.7164,y:-86.0052},
{x:43.0602,y:-83.7575},
{x:38.9313,y:-76.5563},
{x:43.8481,y:-104.1954},
{x:41.1117,y:-101.7146},
{x:33.5483,y:-83.2027},
{x:31.4318,y:-97.7296},
{x:48.2147,y:-114.2754},
{x:33.9139,y:-84.1626},
{x:30.4954,y:-92.4343},
{x:43.2924,y:-85.0848},
{x:46.5906,y:-95.5729},
{x:33.0004,y:-80.0406},
{x:46.2103,y:-119.2225},
{x:41.0543,y:-76.2346},
{x:28.34,y:-81.599},
{x:32.6907,y:-96.2439},
{x:46.865,y:-68.0057},
{x:44.084,y:-93.2415},
{x:36.0219,y:-86.5838},
{x:41.7416,y:-72.1774},
{x:39.962,y:-83.0013},
{x:40.2752,y:-85.6712},
{x:39.0458,y:-88.7489},
{x:34.5287,y:-101.7973},
{x:34.9479,y:-92.0604},
{x:35.2779,y:-81.2327},
{x:40.0191,y:-82.8775},
{x:35.3202,y:-87.7612},
{x:42.2943,y:-84.9646},
{x:34.6725,y:-92.268},
{x:26.588,y:-80.068},
{x:41.2132,y:-96.6228},
{x:41.2881,y:-88.2843},
{x:43.7246,y:-83.4463},
{x:37.7531,y:-100.0287},
{x:37.6924,y:-97.2983},
{x:43.4818,y:-83.4062},
{x:38.348,y:-97.1985},
{x:45.6419,y:-110.5724},
{x:43.8348,y:-84.8607},
{x:43.1772,y:-84.8516},
{x:43.612,y:-84.2918},
{x:47.057,y:-109.4395},
{x:35.4873,y:-79.1995},
{x:40.1295,y:-87.604},
{x:28.623,y:-96.6363},
{x:29.9454,y:-90.0783},
{x:35.1645,y:-79.4727},
{x:36.8708,y:-76.2967},
{x:35.0684,y:-78.9648},
{x:39.7674,y:-86.1602},
{x:33.0228,y:-96.8839},
{x:38.5853,y:-92.2542},
{x:37.1233,y:-82.6123},
{x:40.8306,y:-98.3807},
{x:30.3001,y:-93.1946},
{x:35.6379,y:-106.0255},
{x:41.1132,y:-88.4122},
{x:34.2034,y:-79.5338},
{x:30.4237,y:-87.207},
{x:29.1873,y:-81.4204},
{x:28.7333,y:-81.1157},
{x:38.2291,y:-90.5635},
{x:33.2299,y:-97.151},
{x:31.5317,y:-97.1931},
{x:38.7511,y:-77.4505},
{x:43.0121,y:-88.2294},
{x:35.2198,y:-111.621},
{x:29.0439,y:-95.7073},
{x:26.8242,y:-81.0826},
{x:43.97,y:-69.7164},
{x:37.9684,y:-90.0493},
{x:39.0099,y:-95.6881},
{x:34.9877,y:-81.2278},
{x:32.8426,y:-104.4186},
{x:30.2024,y:-97.6671},
{x:32.5916,y:-97.1442},
{x:35.3238,y:-80.6477},
{x:45.396,y:-92.1494},
{x:29.6648,y:-95.1953},
{x:41.3091,y:-122.3154},
{x:41.1446,y:-96.2392},
{x:40.147,y:-97.1724},
{x:30.517,y:-86.475},
{x:44.0525,y:-88.1432},
{x:30.2643,y:-90.989},
{x:34.0853,y:-84.3554},
{x:33.78,y:-84.1032},
{x:40.5362,y:-78.4002},
{x:34.3395,y:-79.5301},
{x:33.7913,y:-84.2756},
{x:35.498,y:-80.5617},
{x:34.2558,y:-88.7466},
{x:45.5504,y:-93.5852},
{x:36.5523,y:-82.5661},
{x:37.3719,y:-77.5007},
{x:26.7067,y:-80.1375},
{x:35.4577,y:-97.6886},
{x:36.1452,y:-82.8474},
{x:42.3453,y:-122.8732},
{x:29.6884,y:-82.4298},
{x:34.5035,y:-97.1234},
{x:41.6592,y:-93.4894},
{x:33.6706,y:-93.5979},
{x:35.5953,y:-97.5674},
{x:45.5308,y:-122.9602},
{x:39.7394,y:-104.8096},
{x:32.3486,y:-80.6784},
{x:38.8383,y:-104.3906},
{x:38.3468,y:-76.4595},
{x:37.227,y:-96.9955},
{x:34.3591,y:-81.097},
{x:33.1752,y:-96.6828},
{x:36.2028,y:-119.103},
{x:40.1134,y:-87.2489},
{x:38.3431,y:-89.3809},
{x:41.6916,y:-94.3644},
{x:35.3778,y:-97.4959},
{x:41.4487,y:-72.4743},
{x:44.4952,y:-71.575},
{x:33.5927,y:-101.9413},
{x:45.6236,y:-122.563},
{x:43.1574,y:-95.148},
{x:41.6923,y:-83.5645},
{x:42.2781,y:-83.7792},
{x:41.085,y:-81.4436},
{x:45.8456,y:-95.3789},
{x:33.7988,y:-111.9307},
{x:38.2898,y:-78.9352},
{x:41.0843,y:-85.6281},
{x:39.065,y:-76.8383},
{x:28.9567,y:-98.4916},
{x:33.1894,y:-101.378},
{x:41.4117,y:-85.8499},
{x:30.0231,y:-90.2662},
{x:42.5227,y:-88.9861},
{x:34.5656,y:-112.4861},
{x:42.9911,y:-88.8083},
{x:37.9225,y:-90.5374},
{x:42.8831,y:-73.1967},
{x:41.1448,y:-87.2714},
{x:43.2684,y:-91.4754},
{x:33.9025,y:-98.4979},
{x:39.0397,y:-90.1452},
{x:40.431,y:-86.1262},
{x:30.4824,y:-97.6725},
{x:41.6309,y:-83.5556},
{x:35.9359,y:-84.1434},
{x:41.5813,y:-83.6896},
{x:36.7973,y:-98.6561},
{x:45.3124,y:-95.6028},
{x:34.6084,y:-92.5337},
{x:45.4962,y:-122.8084},
{x:41.7331,y:-85.9736},
{x:47.7551,y:-116.7922},
{x:41.9116,y:-85.9924},
{x:36.0007,y:-83.9261},
{x:33.9435,y:-118.2005},
{x:30.5564,y:-91.1336},
{x:36.5815,y:-79.3433},
{x:39.9827,y:-83.1493},
{x:35.9304,y:-84.0307},
{x:29.998,y:-95.5821},
{x:46.145,y:-67.8401},
{x:25.9642,y:-80.1719},
{x:31.7426,y:-95.658},
{x:41.7509,y:-83.5664},
{x:40.9593,y:-77.764},
{x:35.9717,y:-83.9878},
{x:35.9565,y:-84.0066},
{x:30.2929,y:-89.7215},
{x:39.4208,y:-123.8075},
{x:33.6284,y:-111.8874},
{x:34.337,y:-86.3133},
{x:35.9345,y:-83.9049},
{x:30.2093,y:-91.9991},
{x:35.9575,y:-85.8107},
{x:34.2856,y:-83.1193},
{x:35.8864,y:-84.1545},
{x:32.1788,y:-82.5965},
{x:34.9451,y:-88.4971},
{x:39.1413,y:-121.6284},
{x:35.9248,y:-84.0934},
{x:42.2822,y:-83.2312},
{x:30.438,y:-90.082},
{x:35.9245,y:-84.0391},
{x:38.103,y:-84.4842},
{x:37.8605,y:-122.1258},
{x:32.4785,y:-100.0109},
{x:40.5514,y:-89.6113},
{x:35.686,y:-84.8652},
{x:41.4961,y:-94.3172},
{x:46.4821,y:-93.9573},
{x:46.5239,y:-114.1149},
{x:39.3231,y:-110.9638},
{x:40.4283,y:-84.379},
{x:40.7474,y:-92.4138},
{x:31.0793,y:-97.6558},
{x:29.5765,y:-98.6326},
{x:43.3277,y:-85.8107},
{x:42.8593,y:-85.5448},
{x:43.3952,y:-85.4763},
{x:39.7304,y:-121.8586},
{x:35.6713,y:-80.856},
{x:41.4999,y:-83.7194},
{x:43.1363,y:-86.1547},
{x:37.4939,y:-94.2869},
{x:41.707,y:-83.6986},
{x:39.7454,y:-105.006},
{x:37.7609,y:-121.4348},
{x:42.3048,y:-87.8763},
{x:39.3109,y:-76.6585},
{x:43.7338,y:-70.5465},
{x:34.8663,y:-120.4171},
{x:40.8965,y:-85.5076},
{x:30.0874,y:-95.9886},
{x:29.8161,y:-95.057},
{x:42.6814,y:-72.0487},
{x:34.3228,y:-93.5498},
{x:39.9659,y:-83.7853},
{x:39.9448,y:-83.2662},
{x:36.4848,y:-82.4103},
{x:29.7737,y:-95.1869},
{x:42.65,y:-73.7523},
{x:31.9674,y:-102.1076},
{x:30.5665,y:-100.6436},
{x:34.2371,y:-102.7457},
{x:38.4234,y:-78.8729},
{x:42.3383,y:-83.89},
{x:34.0235,y:-84.6247},
{x:39.0448,y:-83.9216},
{x:32.6025,y:-117.0815},
{x:42.5931,y:-88.4162},
{x:36.0691,y:-115.0823},
{x:41.121,y:-87.8866},
{x:37.9734,y:-87.5553},
{x:42.7032,y:-87.8259},
{x:40.3622,y:-111.9655},
{x:38.2602,y:-122.0537},
{x:41.1004,y:-81.3463},
{x:35.744,y:-78.6803},
{x:41.807,y:-89.6992},
{x:34.0624,y:-80.9531},
{x:43.0085,y:-88.3845},
{x:31.8271,y:-99.4229},
{x:32.4714,y:-95.3882},
{x:32.9858,y:-111.5236},
{x:39.117,y:-95.0842},
{x:38.5664,y:-82.8271},
{x:38.3876,y:-82.7172},
{x:41.837,y:-83.8747},
{x:37.9255,y:-122.3474},
{x:34.2506,y:-110.0402},
{x:33.9035,y:-78.4415},
{x:31.5741,y:-94.6731},
{x:43.2916,y:-84.5848},
{x:38.7136,y:-90.4536},
{x:41.9526,y:-91.7255},
{x:41.2758,y:-84.3753},
{x:42.3378,y:-77.3307},
{x:27.9526,y:-82.5245},
{x:38.6424,y:-90.262},
{x:40.9779,y:-81.4928},
{x:60.4838,y:-151.068},
{x:32.0892,y:-88.2245},
{x:43.432,y:-96.1752},
{x:44.874,y:-98.5246},
{x:36.4153,y:-86.7936},
{x:61.179,y:-149.9399},
{x:39.4014,y:-107.2195},
{x:44.3512,y:-103.7636},
{x:25.8909,y:-80.1791},
{x:41.2375,y:-82.1192},
{x:34.866,y:-92.1977},
{x:44.954,y:-93.0827},
{x:43.3204,y:-87.9251},
{x:26.5914,y:-80.1452},
{x:33.9142,y:-81.132},
{x:31.3493,y:-88.9368},
{x:41.6219,y:-86.6983},
{x:41.3622,y:-86.3289},
{x:27.2575,y:-80.1993},
{x:32.378,y:-80.7247},
{x:26.6195,y:-80.1399},
{x:34.396,y:-118.9153},
{x:37.1136,y:-84.0937},
{x:33.7123,y:-78.9},
{x:34.2581,y:-118.4699},
{x:38.7921,y:-77.63},
{x:30.4373,y:-90.4527},
{x:32.7351,y:-97.6312},
{x:42.5944,y:-86.0995},
{x:34.172,y:-82.1548},
{x:47.2888,y:-122.1837},
{x:33.8251,y:-84.1122},
{x:34.042,y:-118.3083},
{x:34.3622,y:-89.5077},
{x:35.0462,y:-83.824},
{x:43.9637,y:-111.6826},
{x:25.8093,y:-80.3151},
{x:48.8475,y:-95.7791},
{x:36.805,y:-76.7274},
{x:36.936,y:-80.9949},
{x:39.379,y:-97.143},
{x:33.5735,y:-82.1925},
{x:33.8835,y:-83.1227},
{x:43.3256,y:-88.19},
{x:39.7558,y:-99.3228},
{x:41.681,y:-83.6226},
{x:32.0095,y:-97.0936},
{x:32.4417,y:-98.6944},
{x:35.5115,y:-93.8224},
{x:41.0254,y:-80.7442},
{x:33.9399,y:-84.5089},
{x:34.036,y:-83.9897},
{x:44.7677,y:-94.1303},
{x:47.3238,y:-122.2203},
{x:42.1193,y:-85.6375},
{x:38.6008,y:-77.149},
{x:37.9871,y:-121.2456},
{x:31.9512,y:-83.4513},
{x:43.6192,y:-116.3162},
{x:43.371,y:-92.1168},
{x:39.9839,y:-83.0144},
{x:33.9662,y:-84.2567},
{x:37.2754,y:-121.8517},
{x:38.9,y:-90.0378},
{x:34.7142,y:-77.4514},
{x:34.685,y:-77.3307},
{x:40.3046,y:-82.6955},
{x:27.0876,y:-80.279},
{x:38.0614,y:-97.9322},
{x:35.2142,y:-100.2486},
{x:41.6533,y:-90.5721},
{x:44.0672,y:-103.1955},
{x:33.4093,y:-84.6798},
{x:34.8157,y:-82.6011},
{x:43.981,y:-83.9593},
{x:38.9222,y:-77.0423},
{x:39.7702,y:-87.2365},
{x:44.6673,y:-88.2376},
{x:39.8157,y:-86.2018},
{x:26.1917,y:-98.2479},
{x:28.8741,y:-96.9977},
{x:42.6717,y:-88.9781},
{x:35.0897,y:-111.6875},
{x:39.2122,y:-84.6747},
{x:41.2215,y:-73.1686},
{x:35.0295,y:-78.93},
{x:38.3624,y:-120.8028},
{x:43.841,y:-84.7668},
{x:40.5161,y:-101.6431},
{x:40.6121,y:-75.5324},
{x:26.1714,y:-97.6718},
{x:32.6133,y:-83.7406},
{x:26.2478,y:-80.2795},
{x:41.1756,y:-79.7383},
{x:39.5247,y:-79.3395},
{x:44.8142,y:-91.5375},
{x:31.5534,y:-90.1088},
{x:31.2293,y:-90.4536},
{x:42.0263,y:-88.3382},
{x:43.0078,y:-89.0229},
{x:32.7238,y:-94.9419},
{x:37.5332,y:-122.3273},
{x:43.6041,y:-96.712},
{x:33.985,y:-101.3414},
{x:37.2458,y:-81.2424},
{x:41.5034,y:-87.7336},
{x:40.5961,y:-73.7549},
{x:39.4197,y:-76.6157},
{x:34.1925,y:-80.6869},
{x:37.0198,y:-120.1332},
{x:36.0246,y:-79.9701},
{x:43.6954,y:-116.3515},
{x:35.2395,y:-96.6706},
{x:35.9252,y:-79.9847},
{x:25.7881,y:-80.2121},
{x:32.7756,y:-117.0447},
{x:43.6041,y:-116.2738},
{x:34.1938,y:-118.5002},
{x:27.3531,y:-82.5475},
{x:32.6741,y:-97.0451},
{x:36.3056,y:-96.4653},
{x:34.8566,y:-82.4544},
{x:35.4943,y:-97.5499},
{x:33.2304,y:-84.265},
{x:32.3511,y:-90.1755},
{x:42.7369,y:-110.9325},
{x:42.1843,y:-84.6436},
{x:27.9881,y:-82.122},
{x:35.2385,y:-111.8221},
{x:35.317,y:-81.1907},
{x:31.2394,y:-85.4814},
{x:41.1725,y:-87.6635},
{x:40.4893,y:-79.46},
{x:27.4904,y:-99.4595},
{x:26.2937,y:-97.9959},
{x:29.5408,y:-98.5772},
{x:37.55,y:-122.0505},
{x:30.1788,y:-82.6757},
{x:41.7194,y:-71.9038},
{x:33.5774,y:-101.9531},
{x:35.1506,y:-97.3967},
{x:28.9309,y:-81.6677},
{x:44.3297,y:-72.7481},
{x:41.1189,y:-78.7301},
{x:29.9872,y:-95.3364},
{x:41.0977,y:-83.9852},
{x:31.2864,y:-86.2553},
{x:37.9591,y:-122.5072},
{x:35.1862,y:-80.691},
{x:42.4845,y:-76.4877},
{x:26.1371,y:-80.1422},
{x:44.8913,y:-93.5131},
{x:40.265,y:-85.8173},
{x:36.3786,y:-85.3331},
{x:32.629,y:-117.0383},
{x:38.3477,y:-77.5},
{x:37.183,y:-77.3183},
{x:26.3867,y:-80.0798},
{x:29.4536,y:-98.562},
{x:40.5084,y:-88.9876},
{x:40.5121,y:-89.0008},
{x:40.5162,y:-88.9988},
{x:40.511,y:-88.9878},
{x:35.2105,y:-86.0953},
{x:39.7663,y:-105.6132},
{x:42.2418,y:-83.1797},
{x:40.5743,y:-85.6772},
{x:33.944,y:-83.755},
{x:36.8151,y:-97.3384},
{x:30.8269,y:-89.1358},
{x:34.4418,y:-83.1122},
{x:39.8565,y:-84.3339},
{x:38.7306,y:-86.4891},
{x:44.0964,y:-69.3736},
{x:34.6383,y:-99.3283},
{x:35.439,y:-79.219},
{x:34.4935,y:-81.9824},
{x:45.6294,y:-94.5747},
{x:35.6608,y:-101.406},
{x:34.0199,y:-118.4546},
{x:41.0574,y:-86.217},
{x:39.5965,y:-110.7887},
{x:35.5122,y:-120.6948},
{x:47.65,y:-122.3634},
{x:42.457,y:-83.945},
{x:38.9853,y:-84.4026},
{x:47.1997,y:-121.9811},
{x:47.661,y:-122.3175},
{x:32.8034,y:-81.9493},
{x:32.4199,y:-86.2687},
{x:43.2947,y:-86.2045},
{x:38.0211,y:-77.9911},
{x:33.9736,y:-118.0733},
{x:40.6666,y:-73.705},
{x:32.9626,y:-97.677},
{x:32.413,y:-100.8587},
{x:33.399,y:-112.0726},
{x:33.5259,y:-80.8533},
{x:38.6815,y:-84.5966},
{x:34.2841,y:-118.4402},
{x:33.3069,y:-112.0546},
{x:39.8357,y:-84.5401},
{x:37.1191,y:-79.7174},
{x:33.7607,y:-78.8602},
{x:32.9609,y:-99.8027},
{x:32.5712,y:-92.0825},
{x:44.3112,y:-74.115},
{x:39.5148,y:-86.8033},
{x:31.2151,y:-81.4837},
{x:39.4108,y:-86.1603},
{x:30.7587,y:-98.675},
{x:34.9115,y:-80.9767},
{x:41.2357,y:-85.8163},
{x:38.5158,y:-82.7237},
{x:45.9257,y:-116.1303},
{x:42.2481,y:-88.6082},
{x:39.4328,y:-76.3245},
{x:38.9038,y:-77.0401},
{x:33.8061,y:-118.3546},
{x:36.1472,y:-86.7989},
{x:36.722,y:-78.1187},
{x:46.761,y:-92.1324},
{x:36.7471,y:-80.7702},
{x:28.362,y:-81.424},
{x:38.4619,y:-75.7471},
{x:34.8408,y:-81.9683},
{x:45.1741,y:-113.8895},
{x:29.755,y:-95.3366},
{x:33.942,y:-84.1253},
{x:34.0335,y:-81.1249},
{x:39.5166,y:-104.7649},
{x:42.1167,y:-71.8652},
{x:39.013,y:-99.8899},
{x:35.2193,y:-114.0344},
{x:44.9077,y:-91.8537},
{x:40.4789,y:-104.988},
{x:33.9772,y:-81.0752},
{x:36.0098,y:-80.4421},
{x:30.6922,y:-88.0703},
{x:45.1632,y:-92.9929},
{x:30.0056,y:-98.1129},
{x:39.9257,y:-86.1004},
{x:31.3192,y:-95.4357},
{x:34.5342,y:-86.5906},
{x:40.5231,y:-105.0599},
{x:41.2978,y:-84.3606},
{x:34.8074,y:-82.2316},
{x:35.1795,y:-82.1609},
{x:34.9313,y:-81.9863},
{x:34.063,y:-81.0084},
{x:40.7511,y:-88.0105},
{x:36.1953,y:-115.0883},
{x:32.8099,y:-83.7238},
{x:33.2095,y:-84.0605},
{x:43.777,y:-91.2221},
{x:36.7418,y:-108.3533},
{x:39.8523,y:-86.27},
{x:33.3536,y:-81.2706},
{x:35.6808,y:-81.229},
{x:36.2563,y:-86.6544},
{x:33.7516,y:-84.7248},
{x:30.8514,y:-91.0056},
{x:36.9652,y:-81.0674},
{x:36.8785,y:-82.3039},
{x:38.6352,y:-87.1788},
{x:36.693,y:-79.8501},
{x:42.537,y:-85.662},
{x:38.4094,y:-78.6184},
{x:41.8642,y:-72.3615},
{x:41.6818,y:-71.1317},
{x:43.5651,y:-86.0393},
{x:31.48,y:-97.1193},
{x:34.8387,y:-90.3008},
{x:34.9189,y:-95.3124},
{x:39.0909,y:-108.4534},
{x:38.7229,y:-120.8316},
{x:37.795,y:-81.2172},
{x:33.7308,y:-117.7886},
{x:36.313,y:-82.3837},
{x:30.7264,y:-90.5199},
{x:42.5692,y:-77.7163},
{x:34.0904,y:-118.3098},
{x:43.2186,y:-70.884},
{x:39.154,y:-108.738},
{x:35.4422,y:-80.7619},
{x:25.7522,y:-80.2587},
{x:41.4673,y:-86.4839},
{x:31.4435,y:-100.4419},
{x:42.8752,y:-77.2451},
{x:34.4792,y:-93.1121},
{x:47.1161,y:-104.7026},
{x:41.982,y:-73.8817},
{x:44.7763,y:-95.0048},
{x:33.7746,y:-81.2368},
{x:34.1777,y:-80.9689},
{x:44.5797,y:-91.2111},
{x:35.9028,y:-75.6727},
{x:30.6753,y:-88.1369},
{x:33.9299,y:-93.8508},
{x:40.6753,y:-111.8664},
{x:43.411,y:-87.8745},
{x:32.4021,y:-98.7921},
{x:30.165,y:-92.0406},
{x:36.0747,y:-80.3357},
{x:46.1027,y:-123.1999},
{x:34.8884,y:-82.0667},
{x:45.5439,y:-122.3958},
{x:45.0657,y:-93.4451},
{x:35.3426,y:-82.5497},
{x:35.3876,y:-82.5672},
{x:31.0402,y:-84.8784},
{x:41.9291,y:-87.8845},
{x:40.0597,y:-95.6019},
{x:45.3362,y:-93.0009},
{x:29.1856,y:-82.216},
{x:34.8652,y:-82.3577},
{x:29.599,y:-82.0855},
{x:42.5932,y:-82.8597},
{x:33.9028,y:-102.3264},
{x:41.9426,y:-103.8063},
{x:40.4611,y:-78.5908},
{x:47.3716,y:-122.2031},
{x:38.6618,y:-77.44},
{x:40.7978,y:-81.8445},
{x:31.3561,y:-85.3259},
{x:47.5444,y:-122.3874},
{x:43.1858,y:-84.1286},
{x:33.518,y:-88.4355},
{x:43.419,y:-82.856},
{x:38.602,y:-86.1014},
{x:41.303,y:-94.4591},
{x:44.6207,y:-93.7554},
{x:47.6768,y:-122.2042},
{x:29.4073,y:-98.5752},
{x:37.8162,y:-87.6148},
{x:47.8896,y:-97.0946},
{x:35.358,y:-77.9611},
{x:44.8433,y:-91.5939},
{x:37.3154,y:-122.0326},
{x:31.7175,y:-96.1742},
{x:41.6733,y:-93.7739},
{x:47.9032,y:-91.8468},
{x:40.7847,y:-77.8405},
{x:31.0679,y:-98.1842},
{x:45.3792,y:-122.7596},
{x:36.2263,y:-92.6843},
{x:39.0089,y:-84.469},
{x:34.4346,y:-100.2258},
{x:38.9639,y:-84.0814},
{x:38.9032,y:-77.0342},
{x:32.846,y:-83.1946},
{x:33.7237,y:-84.5094},
{x:33.3189,y:-111.9259},
{x:33.5673,y:-112.1681},
{x:36.6427,y:-80.2676},
{x:45.3661,y:-122.8451},
{x:35.1366,y:-90.4898},
{x:39.0776,y:-84.7066},
{x:33.85,y:-79.0554},
{x:29.801,y:-90.8486},
{x:30.1423,y:-85.5908},
{x:32.9766,y:-80.0652},
{x:38.101,y:-78.9727},
{x:38.392,y:-86.9389},
{x:33.0237,y:-80.172},
{x:40.5502,y:-76.8128},
{x:40.556,y:-76.7917},
{x:32.6972,y:-103.1269},
{x:37.5396,y:-77.8856},
{x:40.8856,y:-98.3362},
{x:27.9462,y:-97.5857},
{x:30.4205,y:-86.6094},
{x:33.3958,y:-110.7733},
{x:32.88,y:-111.7747},
{x:39.2,y:-76.6554},
{x:40.792,y:-96.6972},
{x:29.8657,y:-90.0011},
{x:37.5138,y:-77.699},
{x:38.1038,y:-81.6165},
{x:42.9645,y:-91.8147},
{x:32.4558,y:-91.7585},
{x:32.5413,y:-92.639},
{x:30.0558,y:-95.5741},
{x:39.8541,y:-89.5395},
{x:35.0035,y:-78.9142},
{x:27.172,y:-80.2907},
{x:39.0076,y:-75.5687},
{x:34.1175,y:-83.5725},
{x:39.1519,y:-111.8179},
{x:45.7428,y:-107.6115},
{x:33.9823,y:-118.0505},
{x:45.9515,y:-86.2436},
{x:35.9249,y:-84.0667},
{x:41.5296,y:-88.6814},
{x:30.9192,y:-84.5997},
{x:34.9602,y:-77.9625},
{x:40.654,y:-84.1341},
{x:36.1278,y:-80.2615},
{x:30.3976,y:-83.361},
{x:41.4946,y:-85.377},
{x:26.725,y:-80.0835},
{x:45.0444,y:-92.8377},
{x:38.5236,y:-105.9894},
{x:42.7683,y:-84.5977},
{x:39.9793,y:-90.7594},
{x:37.5592,y:-90.294},
{x:38.9423,y:-93.5675},
{x:44.6263,y:-91.9662},
{x:33.4078,y:-96.9418},
{x:36.3469,y:-92.3774},
{x:45.6553,y:-92.4665},
{x:34.9516,y:-81.0837},
{x:41.6695,y:-73.8397},
{x:29.3388,y:-82.1132},
{x:28.7569,y:-81.5097},
{x:30.6359,y:-88.0886},
{x:34.9688,y:-81.9339},
{x:39.6946,y:-86.2682},
{x:46.5385,y:-87.4964},
{x:34.2597,y:-118.3141},
{x:40.7437,y:-73.9953},
{x:38.9668,y:-78.4385},
{x:38.3492,y:-77.7466},
{x:31.7127,y:-81.7462},
{x:40.6374,y:-74.9067},
{x:35.1282,y:-109.5415},
{x:36.2341,y:-89.7367},
{x:40.1237,y:-82.0224},
{x:34.0174,y:-78.2743},
{x:36.1573,y:-86.7706},
{x:35.3108,y:-94.3973},
{x:39.602,y:-76.4783},
{x:26.655,y:-81.8016},
{x:35.2632,y:-79.584},
{x:33.2961,y:-81.0329},
{x:31.6327,y:-84.1751},
{x:35.3092,y:-112.8568},
{x:25.5766,y:-80.3378},
{x:36.6012,y:-79.8628},
{x:38.1925,y:-120.6767},
{x:41.1762,y:-73.2256},
{x:39.1869,y:-96.5811},
{x:44.656,y:-90.182},
{x:35.0455,y:-78.928},
{x:35.71,y:-96.861},
{x:41.9156,y:-91.4223},
{x:41.1769,y:-104.0753},
{x:39.3524,y:-112.5708},
{x:32.3482,y:-90.8627},
{x:43.9701,y:-70.5927},
{x:39.5269,y:-85.7431},
{x:35.7555,y:-81.3041},
{x:46.3032,y:-85.5116},
{x:26.0609,y:-80.2514},
{x:42.0438,y:-84.7575},
{x:42.7109,y:-85.4696},
{x:41.8726,y:-85.1931},
{x:42.6054,y:-85.0936},
{x:41.7961,y:-85.6378},
{x:41.8649,y:-72.4732},
{x:33.9048,y:-89.0011},
{x:30.0846,y:-90.5027},
{x:42.9128,y:-85.6862},
{x:44.7447,y:-68.8385},
{x:32.8598,y:-79.9793},
{x:43.9698,y:-91.2649},
{x:36.1761,y:-85.4968},
{x:43.0448,y:-91.3898},
{x:39.7422,y:-77.0968},
{x:34.0109,y:-95.5014},
{x:42.9859,y:-85.7083},
{x:35.0791,y:-81.703},
{x:38.4413,y:-76.7378},
{x:34.0471,y:-118.3402},
{x:41.2052,y:-85.7045},
{x:37.6643,y:-120.9768},
{x:33.8704,y:-78.6717},
{x:33.7607,y:-117.8855},
{x:34.996,y:-86.8572},
{x:29.2893,y:-94.8342},
{x:36.0991,y:-78.4658},
{x:32.2982,y:-90.2287},
{x:41.4484,y:-84.7411},
{x:47.6215,y:-122.3491},
{x:42.4004,y:-96.3619},
{x:35.9628,y:-78.9773},
{x:29.7369,y:-95.6462},
{x:32.9032,y:-96.1081},
{x:40.8785,y:-85.1667},
{x:42.9129,y:-85.6087},
{x:30.0458,y:-95.3464},
{x:32.7855,y:-79.9469},
{x:42.2964,y:-85.8133},
{x:42.3265,y:-85.264},
{x:42.914,y:-85.718},
{x:29.2156,y:-82.1266},
{x:38.4617,y:-90.0929},
{x:43.0207,y:-74.9912},
{x:43.0159,y:-75.0349},
{x:41.213,y:-98.451},
{x:35.6393,y:-78.4492},
{x:39.5896,y:-76.066},
{x:36.2072,y:-86.7797},
{x:36.3748,y:-82.4285},
{x:38.0196,y:-77.5142},
{x:37.627,y:-104.7823},
{x:35.4067,y:-97.4243},
{x:42.2526,y:-84.3786},
{x:30.2212,y:-97.7568},
{x:42.2982,y:-85.0823},
{x:42.0769,y:-85.1347},
{x:31.7437,y:-106.3206},
{x:41.6731,y:-81.2827},
{x:35.8187,y:-82.5368},
{x:34.2829,y:-81.5929},
{x:43.0493,y:-78.8777},
{x:36.0255,y:-83.9273},
{x:42.3536,y:-83.4103},
{x:39.9346,y:-82.4796},
{x:44.2821,y:-92.6679},
{x:33.6493,y:-81.3589},
{x:45.5536,y:-122.7314},
{x:41.4529,y:-97.0601},
{x:41.6229,y:-73.9186},
{x:42.7259,y:-84.4774},
{x:31.742,y:-106.3023},
{x:43.2262,y:-75.4891},
{x:30.6817,y:-91.4621},
{x:32.5259,y:-94.7855},
{x:36.8002,y:-78.4589},
{x:44.0255,y:-90.0728},
{x:32.2941,y:-84.0217},
{x:33.9964,y:-81.0327},
{x:33.6698,y:-96.6116},
{x:38.7202,y:-77.8071},
{x:35.1133,y:-80.9201},
{x:42.2802,y:-89.2413},
{x:29.9049,y:-96.8758},
{x:35.1138,y:-83.0983},
{x:39.6066,y:-76.8502},
{x:28.3308,y:-82.6676},
{x:42.725,y:-85.7039},
{x:35.5668,y:-77.35},
{x:38.4763,y:-90.5215},
{x:36.6873,y:-85.1342},
{x:35.7209,y:-81.189},
{x:38.8389,y:-77.0516},
{x:35.3253,y:-83.8027},
{x:34.7449,y:-81.031},
{x:42.1662,y:-72.5453},
{x:42.1495,y:-72.5169},
{x:34.9231,y:-81.8885},
{x:30.1753,y:-84.3746},
{x:35.0378,y:-80.9771},
{x:27.2432,y:-80.853},
{x:40.9985,y:-76.6498},
{x:34.1594,y:-84.8096},
{x:37.5363,y:-76.7977},
{x:45.496,y:-122.6092},
{x:38.4125,y:-82.5222},
{x:29.7466,y:-95.36},
{x:29.0274,y:-80.8953},
{x:42.6708,y:-85.9992},
{x:36.9417,y:-82.4707},
{x:37.1498,y:-82.4673},
{x:47.9092,y:-122.2276},
{x:45.2571,y:-94.1112},
{x:40.0639,y:-83.0403},
{x:37.9635,y:-76.7834},
{x:34.0562,y:-84.5576},
{x:37.5686,y:-83.7072},
{x:35.0337,y:-81.9697},
{x:44.8844,y:-87.8837},
{x:31.3301,y:-85.814},
{x:35.2493,y:-78.38},
{x:34.7821,y:-82.679},
{x:41.3907,y:-72.7919},
{x:41.9868,y:-92.5803},
{x:38.1305,y:-85.7505},
{x:41.7859,y:-80.8535},
{x:40.7672,y:-87.1231},
{x:41.448,y:-87.011},
{x:39.4469,y:-87.1255},
{x:32.2704,y:-80.9187},
{x:35.9977,y:-86.5952},
{x:36.7067,y:-86.5214},
{x:38.6475,y:-90.3095},
{x:26.1876,y:-97.7586},
{x:33.5761,y:-84.4118},
{x:35.1986,y:-85.1592},
{x:42.3218,y:-111.2985},
{x:28.7135,y:-96.2141},
{x:41.6623,y:-86.1576},
{x:35.0078,y:-97.3668},
{x:33.4874,y:-89.757},
{x:25.5002,y:-80.4457},
{x:39.2641,y:-103.6425},
{x:43.9928,y:-95.755},
{x:41.0624,y:-80.7118},
{x:31.0404,y:-104.8192},
{x:38.2883,y:-90.3991},
{x:40.0527,y:-82.8647},
{x:35.4749,y:-83.317},
{x:29.6272,y:-81.886},
{x:45.4696,y:-123.844},
{x:32.7971,y:-83.5711},
{x:41.7261,y:-86.2523},
{x:45.3911,y:-93.8733},
{x:25.8741,y:-80.1704},
{x:30.3621,y:-91.0051},
{x:35.0679,y:-106.5014},
{x:47.628,y:-117.3666},
{x:42.1468,y:-84.8056},
{x:40.6149,y:-80.6188},
{x:41.6761,y:-86.2504},
{x:43.0116,y:-82.9284},
{x:44.062,y:-87.8875},
{x:35.9386,y:-83.9895},
{x:34.5126,y:-84.916},
{x:34.6225,y:-79.0453},
{x:35.3548,y:-79.9022},
{x:43.2883,y:-71.8335},
{x:41.6292,y:-86.2498},
{x:32.2506,y:-111.0126},
{x:44.6207,y:-86.0995},
{x:34.2395,y:-81.4586},
{x:33.9117,y:-79.4061},
{x:42.1141,y:-76.8271},
{x:41.1435,y:-80.159},
{x:35.9655,y:-83.9202},
{x:42.9435,y:-83.7294},
{x:36.6335,y:-82.1276},
{x:21.4421,y:-158.0221},
{x:26.064,y:-81.7011},
{x:32.8783,y:-83.7612},
{x:45.4136,y:-94.0571},
{x:34.7281,y:-82.3896},
{x:42.0276,y:-96.1259},
{x:37.9613,y:-121.982},
{x:45.793,y:-108.5177},
{x:35.9598,y:-78.2522},
{x:38.241,y:-81.5603},
{x:42.47,y:-91.1175},
{x:30.909,y:-88.5966},
{x:35.1189,y:-81.0854},
{x:36.9139,y:-76.1465},
{x:37.6554,y:-95.4523},
{x:30.1585,y:-85.7534},
{x:41.9313,y:-72.628},
{x:40.1135,y:-85.5751},
{x:33.1314,y:-117.2077},
{x:31.1374,y:-81.5718},
{x:31.0412,y:-83.0739},
{x:34.36,y:-80.1256},
{x:34.358,y:-80.0639},
{x:29.685,y:-98.1254},
{x:28.7482,y:-81.8776},
{x:37.6761,y:-80.8953},
{x:31.1568,y:-88.5607},
{x:35.0707,y:-89.8639},
{x:34.6034,y:-118.1836},
{x:33.4503,y:-84.3274},
{x:32.753,y:-117.2063},
{x:39.1026,y:-84.488},
{x:40.0272,y:-83.0143},
{x:28.7321,y:-80.8671},
{x:47.6704,y:-117.1042},
{x:32.2692,y:-109.8484},
{x:45.7846,y:-108.5914},
{x:40.7925,y:-77.8638},
{x:42.7778,y:-85.135},
{x:35.0083,y:-78.8265},
{x:33.8382,y:-117.9382},
{x:37.5246,y:-77.4489},
{x:38.1156,y:-92.6838},
{x:37.3258,y:-87.4807},
{x:40.1932,y:-85.2112},
{x:34.7935,y:-92.2718},
{x:29.5465,y:-98.6156},
{x:32.8391,y:-96.9525},
{x:38.3753,y:-75.1639},
{x:33.7003,y:-117.9067},
{x:35.2402,y:-81.3327},
{x:35.6915,y:-80.426},
{x:42.7808,y:-89.2938},
{x:28.0262,y:-82.5156},
{x:36.0424,y:-94.2489},
{x:38.1562,y:-92.6051},
{x:39.6379,y:-84.3485},
{x:33.3204,y:-80.4099},
{x:37.4648,y:-89.2223},
{x:35.473,y:-80.2673},
{x:33.8582,y:-84.673},
{x:28.4944,y:-81.4605},
{x:35.4723,y:-120.6514},
{x:37.6312,y:-97.3166},
{x:38.8957,y:-104.8508},
{x:40.0981,y:-85.6509},
{x:47.4448,y:-122.1957},
{x:39.1315,y:-77.168},
{x:37.4174,y:-95.6824},
{x:38.843,y:-104.748},
{x:39.63,y:-85.4455},
{x:32.3811,y:-82.5894},
{x:45.568,y:-94.3205},
{x:42.9638,y:-85.8949},
{x:35.3537,y:-75.5033},
{x:43.6412,y:-70.2422},
{x:39.8544,y:-95.5266},
{x:32.3822,y:-86.2417},
{x:40.9882,y:-94.7342},
{x:43.2074,y:-78.387},
{x:38.9022,y:-90.1489},
{x:36.7156,y:-76.2374},
{x:31.3956,y:-103.518},
{x:32.0428,y:-102.0855},
{x:42.45,y:-84.1798},
{x:43.0889,y:-77.6087},
{x:36.9264,y:-88.3434},
{x:37.3227,y:-122.0068},
{x:42.966,y:-85.6666},
{x:36.2983,y:-78.5813},
{x:32.4918,y:-82.933},
{x:60.1167,y:-149.4409},
{x:37.7227,y:-87.1305},
{x:35.7919,y:-78.7662},
{x:28.9019,y:-82.0371},
{x:40.1611,y:-79.0321},
{x:44.6619,y:-111.1002},
{x:47.6207,y:-117.5071},
{x:40.006,y:-105.2713},
{x:38.0743,y:-89.3823},
{x:43.1026,y:-77.4669},
{x:42.5091,y:-84.6557},
{x:34.0977,y:-118.3431},
{x:41.0151,y:-76.4927},
{x:47.5234,y:-114.0964},
{x:33.949,y:-86.4705},
{x:35.3933,y:-118.9487},
{x:34.5112,y:-82.3069},
{x:35.0027,y:-82.0053},
{x:47.5096,y:-122.3545},
{x:35.3182,y:-119.0733},
{x:37.8964,y:-84.5651},
{x:34.1274,y:-83.2188},
{x:29.9392,y:-93.9388},
{x:29.299,y:-94.7947},
{x:41.5343,y:-83.7839},
{x:48.9135,y:-95.3263},
{x:33.4491,y:-79.5536},
{x:42.4909,y:-90.7008},
{x:41.3954,y:-92.8988},
{x:42.854,y:-91.4038},
{x:39.8423,y:-96.6109},
{x:29.7784,y:-95.1242},
{x:36.0782,y:-94.2057},
{x:31.7693,y:-84.7898},
{x:31.1447,y:-90.4608},
{x:39.2413,y:-84.4711},
{x:33.8707,y:-83.4431},
{x:33.4669,y:-94.0677},
{x:40.305,y:-105.0787},
{x:42.8878,y:-85.8523},
{x:42.8446,y:-85.8614},
{x:36.1909,y:-93.0304},
{x:42.1197,y:-85.5307},
{x:33.9751,y:-81.155},
{x:33.9102,y:-82.294},
{x:28.2361,y:-82.1828},
{x:38.9939,y:-76.8779},
{x:29.7904,y:-82.0418},
{x:37.3351,y:-88.0808},
{x:33.0098,y:-81.3072},
{x:30.2758,y:-97.6689},
{x:36.877,y:-81.7666},
{x:36.9024,y:-81.2721},
{x:26.81,y:-80.056},
{x:39.1232,y:-84.136},
{x:33.6464,y:-78.979},
{x:40.7602,y:-98.5869},
{x:35.2627,y:-79.0457},
{x:35.03,y:-79.0612},
{x:42.719,y:-87.8535},
{x:18.4244,y:-66.0664},
{x:38.2389,y:-120.3645},
{x:35.8076,y:-86.398},
{x:35.223,y:-80.3213},
{x:41.5031,y:-81.662},
{x:29.8702,y:-95.3315},
{x:41.4523,y:-87.469},
{x:30.0398,y:-82.1654},
{x:28.8151,y:-97.8577},
{x:27.7575,y:-98.0544},
{x:46.85,y:-92.2711},
{x:40.4797,y:-84.6302},
{x:41.4525,y:-85.2649},
{x:41.3935,y:-72.0894},
{x:34.3947,y:-83.6895},
{x:34.2992,y:-84.1526},
{x:36.1439,y:-86.8123},
{x:38.0703,y:-84.4812},
{x:39.0018,y:-77.0944},
{x:25.9255,y:-97.5107},
{x:18.436,y:-66.5424},
{x:42.2722,y:-88.9611},
{x:42.0115,y:-88.1771},
{x:41.5739,y:-83.9912},
{x:30.6914,y:-81.9176},
{x:42.3937,y:-83.4831},
{x:39.6563,y:-87.4296},
{x:32.8344,y:-83.7457},
{x:39.9163,y:-77.6533},
{x:32.146,y:-85.7157},
{x:33.4951,y:-86.8778},
{x:31.9827,y:-102.0368},
{x:31.7277,y:-99.9572},
{x:30.1473,y:-82.6391},
{x:33.7428,y:-83.852},
{x:40.0446,y:-82.4417},
{x:45.8861,y:-106.6354},
{x:41.32,y:-88.6103},
{x:33.9644,y:-80.9393},
{x:28.0345,y:-82.1058},
{x:43.138,y:-90.7103},
{x:35.407,y:-82.514},
{x:40.045,y:-86.492},
{x:35.9064,y:-80.0587},
{x:30.5446,y:-87.7096},
{x:35.3755,y:-81.3956},
{x:41.6627,y:-78.8113},
{x:39.4241,y:-87.4148},
{x:40.0065,y:-83.0146},
{x:38.8413,y:-77.0881},
{x:33.8048,y:-78.7223},
{x:37.905,y:-78.3422},
{x:32.7492,y:-117.1219},
{x:33.9665,y:-81.0099},
{x:37.0224,y:-94.7352},
{x:34.2191,y:-87.6215},
{x:39.877,y:-82.9307},
{x:37.2725,y:-85.4962},
{x:40.0673,y:-86.4736},
{x:43.9021,y:-69.624},
{x:32.2874,y:-110.9658},
{x:34.8375,y:-82.2783},
{x:33.6299,y:-117.7244},
{x:42.9745,y:-77.3574},
{x:34.0621,y:-118.3135},
{x:42.7835,y:-86.0201},
{x:43.0862,y:-85.5543},
{x:36.0839,y:-87.7995},
{x:29.0272,y:-81.3478},
{x:39.5222,y:-107.776},
{x:35.7412,y:-81.5257},
{x:37.9824,y:-79.1672},
{x:36.5209,y:-86.0388},
{x:35.9518,y:-78.1057},
{x:32.3107,y:-106.759},
{x:28.589,y:-81.3648},
{x:30.5384,y:-96.696},
{x:25.5935,y:-80.479},
{x:35.5366,y:-97.4945},
{x:37.6636,y:-97.5694},
{x:30.8342,y:-89.5291},
{x:32.8792,y:-117.2352},
{x:44.2011,y:-92.6461},
{x:42.7584,y:-104.4516},
{x:43.6782,y:-92.9799},
{x:39.8536,y:-86.1216},
{x:64.8374,y:-147.7565},
{x:40.725,y:-111.5405},
{x:40.6235,y:-93.9329},
{x:40.7142,y:-116.1036},
{x:33.6373,y:-81.7393},
{x:38.249,y:-122.0558},
{x:33.8316,y:-118.0014},
{x:31.5496,y:-97.1225},
{x:33.4906,y:-117.6995},
{x:41.2356,y:-103.6619},
{x:28.8086,y:-81.5335},
{x:28.9823,y:-81.9233},
{x:44.0981,y:-87.7002},
{x:44.5236,y:-89.5467},
{x:39.4225,y:-94.901},
{x:43.0351,y:-85.6201},
{x:36.8587,y:-101.2093},
{x:35.6047,y:-78.5667},
{x:33.3951,y:-111.7187},
{x:29.4562,y:-98.5547},
{x:42.8845,y:-85.6095},
{x:33.1775,y:-94.97},
{x:28.8758,y:-82.0954},
{x:46.8881,y:-117.3631},
{x:39.3965,y:-76.5797},
{x:42.6818,y:-91.5351},
{x:47.944,y:-117.4758},
{x:32.5837,y:-85.4385},
{x:43.3096,y:-76.3995},
{x:44.3684,y:-91.3169},
{x:34.0101,y:-85.044},
{x:41.4549,y:-88.2729},
{x:30.1093,y:-85.2011},
{x:33.7122,y:-118.0626},
{x:39.2184,y:-76.6152},
{x:40.5457,y:-81.9173},
{x:47.9567,y:-124.3877},
{x:47.2232,y:-123.1199},
{x:36.2524,y:-94.1373},
{x:35.19,y:-80.9293},
{x:35.2547,y:-85.1829},
{x:43.1874,y:-90.0663},
{x:34.105,y:-117.5254},
{x:40.186,y:-85.4209},
{x:29.5363,y:-98.6809},
{x:39.3193,y:-76.6246},
{x:43.8539,y:-92.1932},
{x:38.5386,y:-76.5885},
{x:38.2468,y:-77.4937},
{x:29.9867,y:-81.4547},
{x:42.793,y:-83.7422},
{x:35.9771,y:-87.1259},
{x:35.5941,y:-97.4366},
{x:35.8414,y:-98.4158},
{x:35.8595,y:-97.9322},
{x:34.2723,y:-84.8079},
{x:33.8225,y:-117.8365},
{x:41.9524,y:-72.7843},
{x:41.2511,y:-87.8496},
{x:34.4935,y:-93.1035},
{x:39.7742,y:-85.9766},
{x:47.5327,y:-122.1943},
{x:39.436,y:-84.9963},
{x:46.4348,y:-92.7501},
{x:34.8533,y:-92.4082},
{x:39.9518,y:-86.2774},
{x:31.1958,y:-85.4096},
{x:44.4751,y:-73.1138},
{x:44.0912,y:-91.3424},
{x:42.2402,y:-85.5606},
{x:46.2633,y:-119.0827},
{x:46.5906,y:-111.972},
{x:37.1336,y:-81.4961},
{x:34.9861,y:-90.9046},
{x:36.0357,y:-84.1299},
{x:32.4683,y:-84.9936},
{x:33.1289,y:-117.0892},
{x:25.5756,y:-80.3711},
{x:41.7171,y:-83.7429},
{x:43.323,y:-89.5368},
{x:40.1288,y:-80.0004},
{x:43.4559,y:-71.5656},
{x:36.0725,y:-80.2577},
{x:35.8448,y:-86.4436},
{x:26.2881,y:-80.268},
{x:40.1723,y:-84.9743},
{x:31.3336,y:-94.7523},
{x:40.2485,y:-111.6473},
{x:30.9601,y:-92.1917},
{x:45.9318,y:-118.3878},
{x:32.5322,y:-96.8577},
{x:31.4257,y:-86.0793},
{x:42.6866,y:-114.5179},
{x:33.9378,y:-87.8354},
{x:28.823,y:-81.6441},
{x:43.6697,y:-111.9152},
{x:38.9642,y:-92.3759},
{x:42.6543,y:-111.6011},
{x:42.5284,y:-85.873},
{x:41.4341,y:-86.8914},
{x:61.5842,y:-149.3582},
{x:42.2403,y:-85.6769},
{x:40.7682,y:-72.9873},
{x:33.3017,y:-117.312},
{x:33.8504,y:-117.8577},
{x:42.8261,y:-83.4428},
{x:35.643,y:-101.5986},
{x:26.277,y:-81.5996},
{x:38.2843,y:-77.4383},
{x:41.3144,y:-73.2193},
{x:36.8499,y:-87.8484},
{x:34.2417,y:-77.89},
{x:47.7212,y:-122.2083},
{x:39.6993,y:-86.0847},
{x:41.6981,y:-72.7202},
{x:30.0681,y:-94.1644},
{x:41.603,y:-98.9275},
{x:39.9012,y:-85.9588},
{x:61.19,y:-149.8262},
{x:41.441,y:-82.7509},
{x:38.0023,y:-121.2885},
{x:28.7831,y:-81.7483},
{x:32.7429,y:-97.7428},
{x:44.9235,y:-92.9741},
{x:37.5108,y:-77.594},
{x:39.445,y:-89.6488},
{x:38.7942,y:-104.8029},
{x:32.1179,y:-99.1651},
{x:31.1884,y:-89.2516},
{x:45.3988,y:-92.6006},
{x:29.1317,y:-98.8954},
{x:34.9304,y:-95.7677},
{x:33.0446,y:-96.8274},
{x:40.5814,y:-74.4554},
{x:33.1807,y:-102.274},
{x:45.7558,y:-108.5783},
{x:46.1851,y:-119.1786},
{x:39.7202,y:-82.2117},
{x:40.7224,y:-85.437},
{x:44.9325,y:-89.6664},
{x:34.4681,y:-80.2551},
{x:35.3547,y:-118.9112},
{x:36.4062,y:-78.9771},
{x:40.7591,y:-73.996},
{x:35.4823,y:-81.2066},
{x:28.0259,y:-82.46},
{x:34.6891,y:-77.0677},
{x:41.4758,y:-72.2642},
{x:35.297,y:-81.5147},
{x:43.1758,y:-71.5302},
{x:46.6772,y:-122.9805},
{x:33.9785,y:-81.1978},
{x:36.3557,y:-78.983},
{x:32.9644,y:-102.8225},
{x:31.4334,y:-100.5126},
{x:31.8498,y:-103.0903},
{x:32.1284,y:-101.7914},
{x:35.6404,y:-120.6877},
{x:34.6133,y:-83.5297},
{x:41.1256,y:-76.5324},
{x:39.1593,y:-84.4547},
{x:41.9586,y:-83.6691},
{x:42.6816,y:-88.0568},
{x:42.5293,y:-71.2588},
{x:41.9872,y:-88.69},
{x:34.3176,y:-83.7848},
{x:40.5652,y:-74.6902},
{x:38.3061,y:-81.5634},
{x:29.7906,y:-95.1576},
{x:37.9821,y:-86.1632},
{x:36.0893,y:-78.2993},
{x:30.5901,y:-95.1353},
{x:43.6445,y:-72.4264},
{x:33.675,y:-78.9995},
{x:40.5025,y:-86.8691},
{x:31.8614,y:-106.5494},
{x:34.1458,y:-77.8968},
{x:39.456,y:-87.4135},
{x:39.2095,y:-96.3069},
{x:35.4864,y:-95.1488},
{x:34.7138,y:-98.4017},
{x:41.5862,y:-84.4351},
{x:30.3547,y:-89.4229},
{x:38.3464,y:-92.581},
{x:39.1482,y:-77.275},
{x:41.7903,y:-72.6608},
{x:25.9849,y:-80.2105},
{x:45.0576,y:-83.8928},
{x:42.7559,y:-87.8004},
{x:35.913,y:-80.2537},
{x:42.428,y:-87.8545},
{x:37.2872,y:-79.9333},
{x:28.4202,y:-81.4617},
{x:40.8001,y:-73.6594},
{x:27.9442,y:-80.496},
{x:28.1125,y:-81.9453},
{x:47.4239,y:-122.3366},
{x:30.3932,y:-87.258},
{x:32.4905,y:-92.0827},
{x:37.5134,y:-121.9416},
{x:35.4222,y:-96.2994},
{x:35.4114,y:-119.0453},
{x:39.7535,y:-83.2959},
{x:29.4372,y:-81.5112},
{x:40.6925,y:-99.3816},
{x:38.0068,y:-89.6582},
{x:25.8833,y:-81.3824},
{x:33.9317,y:-81.2911},
{x:42.3765,y:-87.8823},
{x:41.4214,y:-87.239},
{x:41.9294,y:-84.6407},
{x:46.8759,y:-113.884},
{x:32.8661,y:-96.7882},
{x:36.7065,y:-119.7753},
{x:32.235,y:-95.2289},
{x:42.1706,y:-83.2466},
{x:42.26,y:-84.423},
{x:40.5865,y:-111.9854},
{x:29.3098,y:-81.0999},
{x:39.7798,y:-86.1728},
{x:40.7697,y:-73.8343},
{x:35.821,y:-79.8218},
{x:34.7019,y:-82.4787},
{x:25.7204,y:-80.2795},
{x:38.5766,y:-121.4787},
{x:35.3875,y:-80.56},
{x:32.549,y:-93.7088},
{x:41.1762,y:-112.0603},
{x:38.5793,y:-77.7662},
{x:18.4243,y:-66.1629},
{x:38.8733,y:-104.7222},
{x:44.2224,y:-88.4927},
{x:45.0481,y:-122.9748},
{x:29.4352,y:-98.71},
{x:29.8378,y:-82.6018},
{x:29.7487,y:-98.0579},
{x:41.1371,y:-77.4469},
{x:32.5159,y:-86.3747},
{x:44.537,y:-69.6275},
{x:29.5632,y:-98.6848},
{x:38.8673,y:-77.1438},
{x:29.377,y:-98.4472},
{x:33.9221,y:-84.476},
{x:31.0578,y:-97.4466},
{x:39.8547,y:-82.8301},
{x:31.1961,y:-98.7318},
{x:31.6632,y:-89.161},
{x:38.4975,y:-76.776},
{x:41.1001,y:-104.8484},
{x:40.8136,y:-85.8372},
{x:44.0685,y:-123.1786},
{x:28.5226,y:-82.2387},
{x:38.6074,y:-78.8015},
{x:35.2213,y:-93.1648},
{x:27.2905,y:-80.6969},
{x:42.3498,y:-71.7857},
{x:38.1141,y:-121.3061},
{x:25.9331,y:-80.2941},
{x:31.2755,y:-89.2899},
{x:35.1076,y:-106.649},
{x:35.5062,y:-108.8341},
{x:31.1816,y:-97.3141},
{x:27.9702,y:-82.4021},
{x:41.2385,y:-86.2591},
{x:43.6736,y:-121.4997},
{x:42.2925,y:-71.7802},
{x:32.2285,y:-110.9969},
{x:33.0712,y:-117.0711},
{x:41.8715,y:-71.5289},
{x:32.5189,y:-94.8574},
{x:32.2659,y:-110.9433},
{x:33.0786,y:-106.0185},
{x:45.7581,y:-93.6327},
{x:36.0093,y:-80.838},
{x:31.9218,y:-106.4393},
{x:34.0247,y:-84.0437},
{x:32.2055,y:-110.8433},
{x:29.072,y:-82.2815},
{x:34.3821,y:-99.0166},
{x:37.6232,y:-109.4773},
{x:32.3372,y:-111.0442},
{x:35.7989,y:-87.4375},
{x:41.3756,y:-95.6791},
{x:47.5447,y:-116.1278},
{x:32.2506,y:-110.9074},
{x:40.729,y:-73.8585},
{x:36.9379,y:-121.7762},
{x:34.4669,y:-84.4602},
{x:25.6856,y:-80.3936},
{x:33.4664,y:-112.0655},
{x:32.3826,y:-111.0478},
{x:46.6819,y:-123.7385},
{x:40.7468,y:-81.5069},
{x:36.7997,y:-81.6847},
{x:32.2786,y:-110.978},
{x:38.7911,y:-94.262},
{x:35.3819,y:-97.0891},
{x:31.0063,y:-87.4891},
{x:32.2357,y:-110.9575},
{x:32.2995,y:-110.995},
{x:34.8373,y:-97.6087},
{x:35.0155,y:-99.0958},
{x:43.3301,y:-83.0799},
{x:32.1188,y:-110.7991},
{x:26.0738,y:-97.2268},
{x:43.7652,y:-84.5861},
{x:30.6428,y:-88.5665},
{x:32.933,y:-96.8031},
{x:34.1291,y:-117.847},
{x:47.9005,y:-122.207},
{x:30.6329,y:-87.0429},
{x:41.7412,y:-85.002},
{x:43.448,y:-88.6362},
{x:38.4194,y:-83.7534},
{x:34.9931,y:-118.94},
{x:40.8467,y:-81.9441},
{x:45.0186,y:-69.2908},
{x:36.9239,y:-76.304},
{x:33.7691,y:-117.8355},
{x:32.7159,y:-117.1661},
{x:44.2597,y:-88.8508},
{x:31.1446,y:-89.4042},
{x:33.8452,y:-117.2254},
{x:33.6561,y:-117.74},
{x:32.6498,y:-97.1339},
{x:35.8323,y:-78.3187},
{x:44.0801,y:-123.067},
{x:43.9915,y:-121.3369},
{x:34.4708,y:-117.3989},
{x:33.2348,y:-92.6655},
{x:33.1604,y:-84.8732},
{x:35.5176,y:-83.0894},
{x:32.3178,y:-94.5176},
{x:34.2713,y:-118.7615},
{x:33.4023,y:-81.4232},
{x:33.6641,y:-101.6888},
{x:32.6192,y:-83.6828},
{x:34.2821,y:-119.3014},
{x:33.4236,y:-83.2676},
{x:45.5168,y:-122.6801},
{x:27.6913,y:-82.72},
{x:33.1639,y:-95.2179},
{x:43.1413,y:-115.6609},
{x:45.8768,y:-112.0989},
{x:36.6323,y:-93.2808},
{x:48.5079,y:-111.8616},
{x:38.7936,y:-90.6206},
{x:40.8328,y:-73.863},
{x:44.7798,y:-88.5804},
{x:39.2217,y:-85.8882},
{x:45.4261,y:-117.2795},
{x:35.4887,y:-82.9882},
{x:38.9645,y:-92.3295},
{x:40.7316,y:-73.9944},
{x:41.0065,y:-91.9725},
{x:38.9943,y:-112.3244},
{x:29.8426,y:-98.2639},
{x:40.1961,y:-74.8661},
{x:32.3153,y:-90.8805},
{x:33.6987,y:-112.1418},
{x:44.9965,y:-92.9658},
{x:40.9805,y:-111.8874},
{x:27.897,y:-82.2471},
{x:42.3246,y:-83.397},
{x:35.2477,y:-91.7656},
{x:32.9985,y:-96.908},
{x:35.3027,y:-78.6035},
{x:26.0243,y:-80.2194},
{x:42.4562,y:-83.2777},
{x:41.0024,y:-79.3233},
{x:37.832,y:-112.4364},
{x:38.9912,y:-88.1588},
{x:38.6022,y:-89.8078},
{x:29.7322,y:-95.2419},
{x:33.1247,y:-89.0832},
{x:44.5349,y:-72.003},
{x:39.3941,y:-77.4393},
{x:33.4845,y:-112.3641},
{x:33.1768,y:-86.2879},
{x:32.7643,y:-96.5844},
{x:30.3142,y:-91.8316},
{x:25.9327,y:-80.1218},
{x:28.5638,y:-81.1833},
{x:33.1949,y:-97.1234},
{x:34.2472,y:-118.4473},
{x:39.7394,y:-79.9009},
{x:38.8227,y:-76.9189},
{x:41.5743,y:-90.5409},
{x:39.8307,y:-82.9329},
{x:38.1136,y:-82.6105},
{x:34.8106,y:-92.482},
{x:32.9743,y:-96.8904},
{x:34.0645,y:-118.4687},
{x:47.3895,y:-122.0448},
{x:41.5557,y:-73.4171},
{x:33.5083,y:-84.229},
{x:47.0075,y:-120.586},
{x:30.8531,y:-96.9758},
{x:30.484,y:-84.025},
{x:42.0417,y:-88.0618},
{x:39.9044,y:-122.1984},
{x:40.5604,y:-111.9386},
{x:38.8945,y:-77.0301},
{x:41.8021,y:-71.9013},
{x:38.805,y:-76.9855},
{x:42.5718,y:-87.8886},
{x:37.1459,y:-88.7212},
{x:29.3956,y:-94.9382},
{x:29.8922,y:-90.4084},
{x:39.0714,y:-85.2645},
{x:37.8734,y:-109.3422},
{x:33.988,y:-118.2912},
{x:29.9567,y:-96.2678},
{x:36.1252,y:-79.9048},
{x:42.9035,y:-76.8427},
{x:28.3586,y:-80.7925},
{x:30.5881,y:-88.1709},
{x:47.083,y:-119.8605},
{x:32.8542,y:-117.205},
{x:36.0849,y:-81.9281},
{x:41.9465,y:-87.7201},
{x:39.0557,y:-76.6588},
{x:38.0751,y:-75.5559},
{x:26.0715,y:-97.4829},
{x:40.8984,y:-81.4064},
{x:43.6199,y:-116.3713},
{x:44.8649,y:-93.4205},
{x:27.3291,y:-80.3204},
{x:39.6055,y:-76.9987},
{x:30.5165,y:-82.9452},
{x:47.5526,y:-122.3299},
{x:43.768,y:-103.5932},
{x:34.8038,y:-78.9783},
{x:26.1595,y:-97.6817},
{x:43.0209,y:-87.9115},
{x:47.5629,y:-122.6501},
{x:41.8229,y:-90.5393},
{x:32.7527,y:-97.3264},
{x:44.8507,y:-91.4753},
{x:40.8291,y:-81.9078},
{x:44.3167,y:-89.889},
{x:35.7668,y:-78.6953},
{x:33.6293,y:-83.9778},
{x:44.3784,y:-83.8056},
{x:33.0073,y:-97.0182},
{x:43.7852,y:-88.4889},
{x:29.6595,y:-95.5089},
{x:40.7932,y:-77.8571},
{x:45.0799,y:-94.1926},
{x:35.4936,y:-97.5963},
{x:32.2831,y:-90.0246},
{x:43.5457,y:-116.572},
{x:45.7659,y:-119.2057},
{x:42.3781,y:-122.8992},
{x:42.5619,y:-88.8627},
{x:35.4288,y:-97.159},
{x:47.3152,y:-116.5596},
{x:33.6804,y:-117.8861},
{x:42.1057,y:-84.9893},
{x:39.523,y:-83.8467},
{x:41.2883,y:-86.6201},
{x:34.2464,y:-88.7182},
{x:31.4562,y:-97.1717},
{x:35.9885,y:-119.9603},
{x:32.3002,y:-89.803},
{x:41.3792,y:-71.9562},
{x:36.4445,y:-94.2395},
{x:41.6027,y:-72.7521},
{x:34.9876,y:-80.3503},
{x:46.297,y:-119.3402},
{x:48.4212,y:-119.5045},
{x:47.9224,y:-122.2285},
{x:46.6195,y:-120.5601},
{x:28.6118,y:-81.4317},
{x:40.0012,y:-83.0007},
{x:27.3117,y:-80.4075},
{x:44.2359,y:-85.4527},
{x:40.7426,y:-111.9397},
{x:28.401,y:-81.405},
{x:29.9149,y:-81.4025},
{x:26.7084,y:-81.72},
{x:40.7697,y:-81.3847},
{x:28.2455,y:-81.2966},
{x:38.8187,y:-77.0034},
{x:40.4814,y:-86.3954},
{x:34.693,y:-77.1309},
{x:31.0055,y:-87.2644},
{x:30.9953,y:-94.8273},
{x:43.0484,y:-75.3811},
{x:38.6742,y:-86.915},
{x:40.3587,y:-111.739},
{x:40.7929,y:-73.9728},
{x:36.7531,y:-86.1849},
{x:37.6754,y:-85.9013},
{x:31.2898,y:-90.4855},
{x:35.3806,y:-119.0243},
{x:34.1481,y:-119.1959},
{x:32.6231,y:-88.2049},
{x:34.66,y:-97.9574},
{x:36.81,y:-114.0631},
{x:43.0445,y:-82.4571},
{x:40.7855,y:-74.0457},
{x:34.1533,y:-118.4616},
{x:38.9308,y:-77.0384},
{x:44.5681,y:-91.6704},
{x:18.3861,y:-66.0819},
{x:34.5739,y:-92.5801},
{x:26.2425,y:-80.2506},
{x:33.6645,y:-117.8264},
{x:40.4946,y:-80.0645},
{x:38.9118,y:-104.7875},
{x:26.5485,y:-81.8846},
{x:37.2384,y:-82.1029},
{x:27.9804,y:-81.9482},
{x:38.854,y:-104.7561},
{x:31.4643,y:-88.2535},
{x:35.3059,y:-95.5883},
{x:32.5988,y:-97.3176},
{x:32.4164,y:-81.8007},
{x:33.6086,y:-117.7316},
{x:26.371,y:-98.8002},
{x:29.9025,y:-95.3147},
{x:33.7844,y:-118.1163},
{x:33.7579,y:-87.0654},
{x:39.0434,y:-77.106},
{x:38.2351,y:-78.3703},
{x:42.3677,y:-89.0326},
{x:41.6411,y:-71.4688},
{x:43.8573,y:-73.4384},
{x:41.5823,y:-88.9094},
{x:38.3262,y:-77.7308},
{x:38.0139,y:-84.5575},
{x:38.7297,y:-87.6807},
{x:36.6441,y:-87.4363},
{x:47.4908,y:-94.8948},
{x:33.8352,y:-84.5746},
{x:37.6322,y:-122.0674},
{x:40.7151,y:-74.0077},
{x:36.4644,y:-81.8028},
{x:29.9693,y:-90.3767},
{x:37.1147,y:-92.5774},
{x:30.442,y:-84.3409},
{x:45.2093,y:-93.656},
{x:34.7069,y:-81.1918},
{x:29.16,y:-82.1753},
{x:39.3903,y:-76.4889},
{x:30.3203,y:-84.2478},
{x:40.4261,y:-86.9295},
{x:28.333,y:-81.528},
{x:43.6116,y:-84.741},
{x:33.4397,y:-84.5886},
{x:28.0872,y:-82.4585},
{x:27.4626,y:-82.6101},
{x:35.1919,y:-101.7606},
{x:36.7362,y:-119.7925},
{x:26.5722,y:-80.0545},
{x:42.0957,y:-88.6853},
{x:40.6226,y:-98.3844},
{x:33.5776,y:-81.7695},
{x:35.7717,y:-80.2605},
{x:43.657,y:-90.3317},
{x:41.0729,y:-86.6055},
{x:34.0217,y:-80.9486},
{x:46.8592,y:-124.099},
{x:33.4057,y:-79.9247},
{x:35.1418,y:-76.8311},
{x:36.6198,y:-81.1424},
{x:29.7288,y:-99.0773},
{x:32.1172,y:-98.5345},
{x:38.4589,y:-122.6754},
{x:42.6189,y:-77.3998},
{x:39.5483,y:-104.9952},
{x:37.219,y:-76.4798},
{x:45.0985,y:-91.5007},
{x:33.788,y:-117.8764},
{x:45.3394,y:-97.5196},
{x:35.8113,y:-81.4313},
{x:40.9024,y:-81.1533},
{x:36.6655,y:-96.3557},
{x:61.181,y:-149.8301},
{x:41.6496,y:-86.2815},
{x:39.8315,y:-84.8444},
{x:35.4356,y:-97.3684},
{x:35.6247,y:-95.9607},
{x:29.5893,y:-90.7443},
{x:42.1156,y:-72.498},
{x:42.0673,y:-72.6781},
{x:26.0113,y:-80.1759},
{x:30.386,y:-86.464},
{x:29.6011,y:-98.277},
{x:25.8408,y:-80.3065},
{x:61.5812,y:-149.4377},
{x:35.3419,y:-79.4028},
{x:33.7173,y:-87.8177},
{x:36.0509,y:-86.6491},
{x:35.0588,y:-118.9689},
{x:29.8913,y:-95.5831},
{x:30.4009,y:-89.0069},
{x:35.7512,y:-81.7152},
{x:38.8903,y:-77.0836},
{x:36.0336,y:-79.8656},
{x:47.5331,y:-92.3448},
{x:31.1238,y:-84.1525},
{x:42.8643,y:-84.888},
{x:18.432,y:-66.4872},
{x:29.8679,y:-81.3245},
{x:27.2641,y:-80.4083},
{x:34.8972,y:-79.7055},
{x:32.8374,y:-85.7636},
{x:31.6976,y:-89.1194},
{x:36.6366,y:-82.5674},
{x:34.3013,y:-78.725},
{x:39.2225,y:-84.1874},
{x:33.7728,y:-118.1899},
{x:28.3369,y:-81.6709},
{x:38.3335,y:-75.2116},
{x:32.0382,y:-88.7277},
{x:37.1639,y:-80.4185},
{x:41.3298,y:-87.7853},
{x:45.4566,y:-94.4287},
{x:39.0814,y:-76.5501},
{x:38.7734,y:-77.1343},
{x:33.1664,y:-101.7999},
{x:30.8304,y:-82.0059},
{x:45.6647,y:-93.9187},
{x:34.1122,y:-84.5562},
{x:29.7373,y:-95.6054},
{x:37.5433,y:-77.4387},
{x:45.0854,y:-93.009},
{x:33.5497,y:-84.0154},
{x:33.9097,y:-117.4361},
{x:40.7616,y:-73.9248},
{x:32.5895,y:-96.8905},
{x:33.0201,y:-117.0733},
{x:36.9762,y:-82.5772},
{x:33.0417,y:-84.9831},
{x:43.8871,y:-89.4863},
{x:34.8775,y:-83.9695},
{x:39.7153,y:-86.0812},
{x:36.1397,y:-85.2659},
{x:36.0205,y:-106.9595},
{x:39.1273,y:-121.5574},
{x:33.8866,y:-98.4688},
{x:42.8404,y:-72.5649},
{x:37.1336,y:-121.6304},
{x:33.6846,y:-117.9882},
{x:34.1074,y:-117.6698},
{x:31.8016,y:-95.147},
{x:33.175,y:-96.5034},
{x:33.4222,y:-96.5879},
{x:34.9544,y:-110.311},
{x:39.8535,y:-104.9774},
{x:41.5057,y:-73.9705},
{x:42.0206,y:-71.4949},
{x:40.2709,y:-81.8771},
{x:34.7958,y:-77.4114},
{x:42.9174,y:-83.5015},
{x:30.5609,y:-88.2052},
{x:38.7851,y:-77.1224},
{x:33.6939,y:-117.8555},
{x:18.4141,y:-66.0854},
{x:36.0571,y:-78.9298},
{x:47.8464,y:-122.3041},
{x:30.6048,y:-87.0658},
{x:39.6421,y:-77.5855},
{x:28.5461,y:-81.6499},
{x:36.3186,y:-86.8675},
{x:44.7257,y:-84.115},
{x:41.8895,y:-71.3337},
{x:33.5751,y:-117.7256},
{x:38.4829,y:-77.9764},
{x:42.5314,y:-78.4468},
{x:33.219,y:-111.8732},
{x:30.2465,y:-89.761},
{x:32.549,y:-94.355},
{x:40.8342,y:-80.5364},
{x:33.5021,y:-117.6531},
{x:35.7048,y:-82.0239},
{x:42.4375,y:-84.9243},
{x:45.1866,y:-69.2299},
{x:32.7976,y:-80.0317},
{x:33.9541,y:-84.0552},
{x:35.2395,y:-79.3007},
{x:34.1204,y:-84.0777},
{x:40.7476,y:-73.2641},
{x:38.2512,y:-87.5811},
{x:31.0976,y:-88.2375},
{x:38.9465,y:-76.8619},
{x:38.9388,y:-104.7575},
{x:36.1801,y:-115.1778},
{x:37.5196,y:-77.185},
{x:33.6143,y:-85.787},
{x:37.2942,y:-81.2562},
{x:42.6675,y:-90.1307},
{x:37.7994,y:-120.9982},
{x:35.17,y:-92.7195},
{x:36.8963,y:-76.2254},
{x:40.7868,y:-81.2832},
{x:36.1673,y:-86.8203},
{x:32.8195,y:-116.963},
{x:41.5347,y:-88.5798},
{x:32.4551,y:-93.747},
{x:33.775,y:-117.9202},
{x:42.2085,y:-89.1082},
{x:38.9928,y:-76.4687},
{x:31.6355,y:-84.2486},
{x:39.9173,y:-82.4082},
{x:35.8725,y:-90.1721},
{x:44.2385,y:-95.6317},
{x:33.9602,y:-118.3782},
{x:42.3778,y:-76.8719},
{x:40.4162,y:-91.1453},
{x:31.6236,y:-84.127},
{x:38.9649,y:-77.4461},
{x:45.0086,y:-122.7856},
{x:42.1942,y:-88.2078},
{x:41.9546,y:-85.0004},
{x:28.9871,y:-82.352},
{x:43.5762,y:-119.0723},
{x:35.1412,y:-120.6414},
{x:41.3545,y:-86.3113},
{x:26.8541,y:-80.6374},
{x:34.9535,y:-82.1129},
{x:32.7058,y:-97.358},
{x:37.2366,y:-121.8042},
{x:31.4325,y:-86.9564},
{x:32.764,y:-97.2178},
{x:33.6199,y:-84.2397},
{x:41.4469,y:-81.6563},
{x:43.2299,y:-83.1946},
{x:47.6327,y:-122.63},
{x:38.0202,y:-121.2962},
{x:40.8703,y:-97.997},
{x:30.5118,y:-88.698},
{x:44.741,y:-68.6338},
{x:36.7933,y:-119.6812},
{x:41.0813,y:-86.8926},
{x:32.5818,y:-117.0332},
{x:41.38,y:-73.481},
{x:43.9009,y:-85.2674},
{x:48.2017,y:-114.3193},
{x:31.3128,y:-89.4725},
{x:31.978,y:-89.2881},
{x:36.5565,y:-82.1786},
{x:39.7143,y:-86.237},
{x:36.2793,y:-83.5252},
{x:37.2995,y:-93.4203},
{x:30.1013,y:-91.9427},
{x:39.6095,y:-86.158},
{x:40.4312,y:-86.8686},
{x:38.0218,y:-84.4195},
{x:43.002,y:-86.1047},
{x:35.2511,y:-95.1143},
{x:36.7923,y:-79.3946},
{x:41.5154,y:-87.2584},
{x:33.5475,y:-101.8695},
{x:37.184,y:-77.4869},
{x:18.1146,y:-66.1465},
{x:61.2156,y:-149.8117},
{x:39.1935,y:-84.2389},
{x:32.4704,y:-85.1005},
{x:30.6832,y:-92.2608},
{x:27.9971,y:-82.0176},
{x:36.2071,y:-115.9873},
{x:40.4882,y:-85.6166},
{x:29.7024,y:-95.3641},
{x:30.4981,y:-90.4823},
{x:40.7055,y:-104.9998},
{x:27.9451,y:-82.4827},
{x:46.6995,y:-94.1185},
{x:25.7749,y:-80.1342},
{x:30.3503,y:-87.2701},
{x:34.0627,y:-84.0762},
{x:35.2323,y:-88.371},
{x:44.2962,y:-121.5606},
{x:33.8989,y:-117.9099},
{x:33.7997,y:-84.756},
{x:41.5008,y:-79.4554},
{x:38.4594,y:-122.7287},
{x:46.561,y:-96.083},
{x:36.8561,y:-75.9786},
{x:40.039,y:-75.0958},
{x:36.4128,y:-84.5313},
{x:31.885,y:-88.9988},
{x:36.8627,y:-76.1728},
{x:32.3484,y:-88.7371},
{x:40.1182,y:-102.7209},
{x:37.6906,y:-97.1346},
{x:42.1022,y:-72.5899},
{x:38.3876,y:-105.1024},
{x:18.465,y:-66.2729},
{x:40.0864,y:-82.9516},
{x:25.8974,y:-80.3495},
{x:28.0331,y:-82.0553},
{x:41.938,y:-84.9842},
{x:34.0593,y:-118.4447},
{x:41.9982,y:-77.1266},
{x:40.6224,y:-96.9622},
{x:39.7023,y:-77.318},
{x:25.8137,y:-80.1345},
{x:44.2463,y:-116.9577},
{x:32.4374,y:-104.2526},
{x:45.5205,y:-123.0717},
{x:42.7669,y:-88.1977},
{x:40.6014,y:-83.1297},
{x:39.6523,y:-86.0833},
{x:18.3797,y:-65.7208},
{x:33.1048,y:-86.7527},
{x:33.1045,y:-117.0769},
{x:33.4789,y:-82.1994},
{x:36.5699,y:-82.1972},
{x:47.2447,y:-122.4369},
{x:34.526,y:-88.6554},
{x:26.9337,y:-80.1351},
{x:41.2826,y:-72.8227},
{x:47.5189,y:-120.454},
{x:35.3334,y:-89.8755},
{x:45.3869,y:-93.3603},
{x:36.1013,y:-86.8191},
{x:41.2299,y:-80.2397},
{x:40.8891,y:-80.6938},
{x:31.5239,y:-96.5344},
{x:40.6092,y:-105.0761},
{x:41.6357,y:-72.8727},
{x:34.9345,y:-79.7723},
{x:34.7062,y:-85.2816},
{x:22.2068,y:-159.4116},
{x:47.7595,y:-122.3128},
{x:35.7928,y:-88.3921},
{x:33.9315,y:-117.9683},
{x:32.6977,y:-117.1866},
{x:32.7115,y:-117.2002},
{x:32.6765,y:-117.1591},
{x:33.7229,y:-84.7633},
{x:32.6665,y:-114.4442},
{x:26.2987,y:-80.1879},
{x:33.0385,y:-84.153},
{x:42.3743,y:-82.9396},
{x:30.9373,y:-90.5258},
{x:41.5861,y:-83.7963},
{x:36.2516,y:-93.1228},
{x:36.9451,y:-76.2949},
{x:45.2876,y:-122.3379},
{x:32.4222,y:-110.9622},
{x:36.9851,y:-86.4567},
{x:43.3184,y:-70.9752},
{x:33.0405,y:-96.7338},
{x:37.3142,y:-79.8813},
{x:44.7968,y:-90.0796},
{x:41.9838,y:-72.6561},
{x:45.7554,y:-108.5374},
{x:42.3203,y:-87.8403},
{x:35.4669,y:-97.5296},
{x:39.0291,y:-76.5056},
{x:42.3116,y:-87.865},
{x:36.0245,y:-80.1627},
{x:40.3773,y:-76.3125},
{x:32.7644,y:-97.4799},
{x:37.8238,y:-121.2872},
{x:36.5994,y:-92.433},
{x:43.6889,y:-92.3906},
{x:37.6513,y:-77.3975},
{x:34.2725,y:-118.4674},
{x:35.132,y:-107.8279},
{x:40.7593,y:-74.2218},
{x:39.2882,y:-76.6244},
{x:34.8448,y:-92.2309},
{x:39.0807,y:-77.5616},
{x:32.8232,y:-117.1033},
{x:32.5295,y:-92.0936},
{x:38.8863,y:-82.3788},
{x:35.343,y:-89.7872},
{x:38.0051,y:-97.5063},
{x:39.1001,y:-84.511},
{x:29.6537,y:-97.5924},
{x:44.3072,y:-69.9716},
{x:36.5235,y:-77.5874},
{x:34.3928,y:-118.5732},
{x:33.2511,y:-80.8165},
{x:40.5767,y:-74.3886},
{x:41.924,y:-85.5266},
{x:44.9906,y:-84.6767},
{x:41.3626,y:-82.1018},
{x:32.6063,y:-85.4868},
{x:34.6591,y:-86.4817},
{x:43.7103,y:-82.9388},
{x:43.5396,y:-123.2889},
{x:38.4226,y:-105.0325},
{x:37.7254,y:-80.6342},
{x:29.9975,y:-91.8074},
{x:42.0952,y:-76.7898},
{x:39.466,y:-98.1159},
{x:29.5824,y:-95.1538},
{x:38.6091,y:-77.3523},
{x:26.0477,y:-80.1652},
{x:32.3128,y:-81.062},
{x:44.2529,y:-91.5019},
{x:33.6878,y:-85.153},
{x:31.0364,y:-82.7476},
{x:44.2734,y:-88.5364},
{x:42.7144,y:-83.0369},
{x:44.9545,y:-90.8004},
{x:33.8327,y:-117.9685},
{x:38.7012,y:-80.6636},
{x:42.0555,y:-124.2908},
{x:32.4394,y:-89.1066},
{x:38.6748,y:-121.7464},
{x:29.0068,y:-82.0063},
{x:38.9542,y:-84.5408},
{x:33.9206,y:-80.341},
{x:18.3789,y:-66.153},
{x:18.1728,y:-67.1495},
{x:37.8613,y:-112.8297},
{x:41.1969,y:-73.1375},
{x:26.1882,y:-80.1473},
{x:33.9367,y:-80.4192},
{x:32.7728,y:-92.4052},
{x:18.4505,y:-66.749},
{x:33.3073,y:-111.874},
{x:29.6938,y:-90.5532},
{x:45.054,y:-87.7614},
{x:18.4041,y:-66.1806},
{x:18.3392,y:-66.0705},
{x:47.0542,y:-122.2938},
{x:39.8546,y:-86.242},
{x:39.0067,y:-94.8448},
{x:33.4224,y:-117.5627},
{x:33.5667,y:-84.5375},
{x:28.3068,y:-97.2746},
{x:39.8692,y:-81.9039},
{x:42.0544,y:-93.6212},
{x:42.3529,y:-83.0744},
{x:32.7245,y:-117.1456},
{x:47.5947,y:-122.6908},
{x:41.2975,y:-72.9266},
{x:41.8845,y:-87.6198},
{x:38.2721,y:-76.443},
{x:34.2392,y:-84.193},
{x:18.1122,y:-65.8217},
{x:30.4978,y:-88.3359},
{x:44.9837,y:-93.4573},
{x:45.5149,y:-122.9016},
{x:41.8049,y:-72.2437},
{x:37.2558,y:-107.0769},
{x:32.6326,y:-80.8737},
{x:34.1197,y:-119.0946},
{x:45.1254,y:-92.672},
{x:45.6698,y:-120.832},
{x:30.3966,y:-96.073},
{x:39.4226,y:-118.7234},
{x:40.5372,y:-105.0764},
{x:30.7932,y:-81.5696},
{x:30.8081,y:-81.5277},
{x:37.6924,y:-77.4365},
{x:28.1232,y:-82.7614},
{x:40.3974,y:-104.6908},
{x:30.2229,y:-81.6747},
{x:28.8521,y:-81.5521},
{x:43.2126,y:-82.9951},
{x:33.2228,y:-97.7616},
{x:40.4413,y:-79.9575},
{x:40.4203,y:-105.0735},
{x:32.6902,y:-117.1278},
{x:30.0623,y:-90.702},
{x:35.9564,y:-95.395},
{x:37.0218,y:-76.4431},
{x:37.7985,y:-121.2353},
{x:25.7659,y:-80.2209},
{x:44.0448,y:-123.0739},
{x:33.9872,y:-81.0288},
{x:27.947,y:-82.5241},
{x:36.4748,y:-80.6192},
{x:44.2635,y:-104.9445},
{x:29.2236,y:-82.1483},
{x:37.3141,y:-121.021},
{x:48.1812,y:-122.1268},
{x:36.5782,y:-83.6545},
{x:30.4208,y:-95.4784},
{x:33.0417,y:-97.0243},
{x:41.4268,y:-87.7568},
{x:40.7573,y:-73.2099},
{x:36.8196,y:-76.0702},
{x:37.5083,y:-77.6023},
{x:35.946,y:-95.8886},
{x:37.5213,y:-85.8827},
{x:36.9154,y:-80.3111},
{x:36.9468,y:-76.3171},
{x:34.1438,y:-79.7713},
{x:36.2611,y:-119.9112},
{x:35.4003,y:-119.3975},
{x:35.8707,y:-78.5785},
{x:38.1987,y:-79.1164},
{x:37.8273,y:-85.7237},
{x:36.8517,y:-121.3977},
{x:40.8098,y:-73.2179},
{x:35.2553,y:-91.2004},
{x:33.5938,y:-86.6944},
{x:35.7858,y:-95.243},
{x:44.2445,y:-88.4696},
{x:33.9519,y:-91.8432},
{x:32.7482,y:-117.1616},
{x:29.6826,y:-95.2252},
{x:33.4259,y:-117.6106},
{x:38.89,y:-79.844},
{x:35.2283,y:-89.5185},
{x:30.3145,y:-81.5687},
{x:33.6736,y:-117.9542},
{x:40.283,y:-81.6024},
{x:40.0901,y:-82.9914},
{x:29.828,y:-95.3784},
{x:33.8737,y:-117.9042},
{x:35.3817,y:-96.6707},
{x:34.93,y:-78.8539},
{x:39.9105,y:-86.1839},
{x:32.4969,y:-88.846},
{x:34.7744,y:-96.6796},
{x:43.3796,y:-72.3443},
{x:32.3638,y:-90.2394},
{x:38.4016,y:-90.3765},
{x:18.375,y:-66.0542},
{x:33.9698,y:-84.4879},
{x:30.4033,y:-88.937},
{x:41.8654,y:-87.6392},
{x:34.0771,y:-117.7506},
{x:40.017,y:-105.1016},
{x:30.3794,y:-86.355},
{x:41.4402,y:-81.7305},
{x:43.4334,y:-85.2658},
{x:32.4823,y:-84.9423},
{x:41.5203,y:-81.4773},
{x:34.982,y:-81.9686},
{x:33.2614,y:-111.8568},
{x:37.1149,y:-79.9547},
{x:28.0363,y:-97.5107},
{x:36.0028,y:-120.1349},
{x:37.8269,y:-87.5585},
{x:42.6532,y:-83.3709},
{x:43.0607,y:-87.9966},
{x:36.3242,y:-86.6145},
{x:33.8802,y:-81.0408},
{x:41.1203,y:-80.3595},
{x:34.7977,y:-91.8914},
{x:44.3186,y:-88.9433},
{x:38.6529,y:-77.3058},
{x:38.332,y:-104.745},
{x:35.1975,y:-80.869},
{x:41.3909,y:-78.563},
{x:39.7957,y:-85.5278},
{x:42.4058,y:-82.96},
{x:38.7681,y:-93.5562},
{x:29.3944,y:-95.8314},
{x:33.0949,y:-96.8047},
{x:37.8091,y:-92.2237},
{x:39.4815,y:-86.0161},
{x:37.5443,y:-77.3891},
{x:44.3355,y:-88.6321},
{x:43.2836,y:-87.9863},
{x:40.7314,y:-74.1098},
{x:37.2743,y:-93.1152},
{x:34.7141,y:-82.255},
{x:36.5779,y:-87.301},
{x:32.7553,y:-117.1061},
{x:31.1128,y:-86.0373},
{x:33.6471,y:-85.8603},
{x:34.2014,y:-118.6299},
{x:39.1608,y:-84.2743},
{x:43.3384,y:-83.7376},
{x:35.855,y:-81.5378},
{x:30.3722,y:-81.6646},
{x:38.2018,y:-85.266},
{x:44.4651,y:-93.913},
{x:35.0202,y:-81.8958},
{x:34.9664,y:-81.9405},
{x:30.4257,y:-84.215},
{x:31.9941,y:-87.2779},
{x:32.6773,y:-117.0959},
{x:39.8803,y:-79.9663},
{x:47.2414,y:-88.454},
{x:43.9793,y:-94.6352},
{x:35.0853,y:-96.3909},
{x:42.1725,y:-83.1884},
{x:46.3556,y:-94.7843},
{x:39.3127,y:-76.5984},
{x:34.1916,y:-79.8102},
{x:40.4799,y:-85.5459},
{x:37.9623,y:-104.797},
{x:37.3456,y:-77.3442},
{x:40.8269,y:-85.349},
{x:29.6077,y:-90.7545},
{x:40.427,y:-78.421},
{x:31.258,y:-95.98},
{x:36.1039,y:-84.5816},
{x:37.2353,y:-80.433},
{x:43.2687,y:-82.531},
{x:34.4801,y:-82.6486},
{x:42.6449,y:-82.8975},
{x:41.8353,y:-84.748},
{x:40.3025,y:-121.2346},
{x:32.0989,y:-96.4426},
{x:27.5223,y:-97.8776},
{x:42.6797,y:-73.7922},
{x:30.2464,y:-93.0123},
{x:36.0982,y:-80.2436},
{x:36.3131,y:-119.2611},
{x:39.0406,y:-77.3581},
{x:33.0322,y:-85.0726},
{x:47.9923,y:-122.2139},
{x:33.3797,y:-111.6873},
{x:33.6535,y:-112.3715},
{x:35.8705,y:-84.6801},
{x:38.3113,y:-84.0448},
{x:38.4322,y:-92.8527},
{x:41.6771,y:-81.4271},
{x:43.009,y:-89.7119},
{x:33.8912,y:-117.7761},
{x:33.8151,y:-118.0294},
{x:27.7741,y:-97.4207},
{x:35.9944,y:-80.2701},
{x:36.8086,y:-119.5547},
{x:29.183,y:-81.89},
{x:35.426,y:-83.4484},
{x:39.7485,y:-122.2008},
{x:34.7524,y:-89.4542},
{x:25.9662,y:-80.1458},
{x:47.6144,y:-122.3342},
{x:47.8768,y:-122.1858},
{x:41.5402,y:-88.1607},
{x:32.9964,y:-94.612},
{x:36.088,y:-80.0638},
{x:34.0014,y:-118.0874},
{x:35.0638,y:-89.7516},
{x:31.5544,y:-110.3031},
{x:47.729,y:-122.2431},
{x:37.5505,y:-85.733},
{x:39.9412,y:-86.2345},
{x:33.3367,y:-111.7568},
{x:33.5241,y:-112.1087},
{x:41.6002,y:-90.908},
{x:37.7399,y:-83.0447},
{x:43.5847,y:-88.4323},
{x:40.6215,y:-111.9097},
{x:43.453,y:-76.5305},
{x:38.328,y:-81.5983},
{x:33.3775,y:-117.4795},
{x:47.6895,y:-122.7073},
{x:38.3069,y:-104.599},
{x:39.0408,y:-76.9899},
{x:31.7204,y:-85.8291},
{x:32.5803,y:-117.1318},
{x:38.391,y:-81.8391},
{x:32.8818,y:-85.4062},
{x:44.9697,y:-89.6271},
{x:41.1677,y:-81.4764},
{x:41.0335,y:-82.012},
{x:45.0319,y:-93.2885},
{x:41.3647,y:-85.22},
{x:42.9923,y:-83.7323},
{x:38.9206,y:-77.0219},
{x:43.0763,y:-76.1693},
{x:46.1429,y:-122.8942},
{x:47.4735,y:-122.2083},
{x:32.5842,y:-85.5181},
{x:33.9764,y:-84.093},
{x:25.8678,y:-80.3188},
{x:48.2378,y:-122.3265},
{x:32.4155,y:-88.6558},
{x:36.1635,y:-95.3433},
{x:31.3285,y:-94.6898},
{x:31.1832,y:-85.2433},
{x:28.9186,y:-81.9237},
{x:18.4504,y:-66.0737},
{x:41.4828,y:-88.0308},
{x:38.338,y:-75.1103},
{x:36.2018,y:-83.3336},
{x:48.1126,y:-122.7614},
{x:37.5642,y:-97.3346},
{x:30.1414,y:-91.9478},
{x:47.5419,y:-122.3417},
{x:39.2824,y:-80.3503},
{x:40.5776,y:-89.641},
{x:30.402,y:-87.2888},
{x:33.6997,y:-84.7166},
{x:44.9411,y:-92.9041},
{x:48.9344,y:-122.6653},
{x:33.808,y:-118.2736},
{x:39.3597,y:-104.6089},
{x:38.1401,y:-85.8879},
{x:32.3583,y:-84.9754},
{x:45.7455,y:-87.0807},
{x:34.1057,y:-118.287},
{x:43.8247,y:-96.7217},
{x:38.87,y:-78.5319},
{x:32.529,y:-90.3107},
{x:28.3623,y:-82.6941},
{x:39.6574,y:-79.9539},
{x:38.0492,y:-85.5399},
{x:18.4031,y:-65.9781},
{x:18.1334,y:-67.1121},
{x:44.6258,y:-73.1182},
{x:41.7664,y:-72.7071},
{x:33.8653,y:-118.2557},
{x:34.1564,y:-118.151},
{x:33.4936,y:-112.2907},
{x:44.752,y:-85.6317},
{x:33.95,y:-80.4978},
{x:39.5553,y:-94.3287},
{x:36.7262,y:-76.6363},
{x:30.0167,y:-90.201},
{x:38.9008,y:-76.796},
{x:35.9586,y:-91.5487},
{x:35.3388,y:-94.423},
{x:37.2414,y:-93.2586},
{x:42.5049,y:-82.8985},
{x:36.729,y:-102.5006},
{x:34.6014,y:-118.1377},
{x:27.9811,y:-82.3191},
{x:29.48,y:-95.3958},
{x:42.826,y:-82.4867},
{x:40.4029,y:-82.441},
{x:38.9723,y:-76.8478},
{x:38.9784,y:-77.435},
{x:40.9272,y:-73.8436},
{x:30.7143,y:-87.02},
{x:32.9682,y:-80.0027},
{x:41.8903,y:-87.628},
{x:32.9658,y:-79.9754},
{x:42.0313,y:-88.2017},
{x:39.0996,y:-84.513},
{x:31.5507,y:-84.0638},
{x:32.8369,y:-96.6293},
{x:38.6272,y:-92.5661},
{x:31.7507,y:-106.3664},
{x:30.4489,y:-86.6256},
{x:30.2102,y:-95.5279},
{x:39.2435,y:-114.8681},
{x:29.4789,y:-90.3212},
{x:30.5142,y:-90.4668},
{x:44.5246,y:-87.9922},
{x:41.8963,y:-87.6346},
{x:40.7673,y:-96.6243},
{x:36.3266,y:-119.135},
{x:28.573,y:-81.518},
{x:28.2533,y:-81.6581},
{x:39.1673,y:-76.5294},
{x:40.5049,y:-86.1119},
{x:34.267,y:-119.2729},
{x:31.4986,y:-84.116},
{x:42.3147,y:-122.8152},
{x:46.8126,y:-119.1729},
{x:35.9553,y:-89.9654},
{x:42.827,y:-86.0925},
{x:40.5389,y:-79.8355},
{x:36.8775,y:-76.4178},
{x:40.7199,y:-73.7615},
{x:41.709,y:-88.2037},
{x:41.8599,y:-88.0262},
{x:34.1361,y:-116.0595},
{x:41.9734,y:-88.0321},
{x:18.2314,y:-65.9044},
{x:41.515,y:-71.6971},
{x:40.6424,y:-86.1287},
{x:41.7003,y:-76.2749},
{x:38.6827,y:-104.7038},
{x:32.7616,y:-89.1465},
{x:41.855,y:-84.3554},
{x:35.8971,y:-81.5656},
{x:33.6515,y:-112.2557},
{x:41.6382,y:-83.3745},
{x:45.3339,y:-93.7379},
{x:30.2839,y:-81.9841},
{x:47.3008,y:-122.3623},
{x:41.5396,y:-86.9015},
{x:40.6233,y:-111.8209},
{x:36.3217,y:-95.61},
{x:36.7747,y:-75.9617},
{x:38.9124,y:-120.0033},
{x:34.5804,y:-118.1205},
{x:37.8,y:-79.4192},
{x:28.8681,y:-81.2528},
{x:39.2722,y:-76.6308},
{x:36.8087,y:-76.311},
{x:45.0201,y:-93.4914},
{x:36.5977,y:-78.1474},
{x:35.5905,y:-78.7773},
{x:30.4506,y:-82.9323},
{x:38.2635,y:-77.5808},
{x:43.1834,y:-88.993},
{x:40.3455,y:-88.7603},
{x:41.1873,y:-73.1807},
{x:35.4664,y:-97.6546},
{x:45.7497,y:-122.8782},
{x:32.7543,y:-97.7898},
{x:42.5915,y:-114.4657},
{x:38.3475,y:-98.2096},
{x:42.7984,y:-85.5078},
{x:33.7875,y:-117.8153},
{x:32.3763,y:-88.7438},
{x:44.1755,y:-88.2632},
{x:41.7142,y:-81.3001},
{x:34.2075,y:-86.8749},
{x:35.2032,y:-89.8647},
{x:37.2761,y:-79.0975},
{x:41.1112,y:-74.1009},
{x:30.211,y:-95.752},
{x:34.6386,y:-120.4507},
{x:36.2292,y:-84.1583},
{x:33.1178,y:-96.8048},
{x:33.9769,y:-117.4581},
{x:32.1999,y:-82.3277},
{x:45.8991,y:-95.3663},
{x:29.0592,y:-82.0577},
{x:29.6205,y:-95.7431},
{x:41.1862,y:-80.4479},
{x:40.427,y:-80.0549},
{x:34.0687,y:-84.1662},
{x:34.6197,y:-87.0366},
{x:38.2779,y:-77.2069},
{x:38.9433,y:-95.2116},
{x:26.289,y:-81.7455},
{x:39.0366,y:-77.4085},
{x:39.4948,y:-80.1423},
{x:40.5756,y:-122.3577},
{x:41.6523,y:-87.7392},
{x:36.9604,y:-86.463},
{x:41.2543,y:-97.1299},
{x:38.3507,y:-75.6019},
{x:47.0079,y:-124.1653},
{x:34.6593,y:-83.0884},
{x:44.4552,y:-87.5257},
{x:18.3958,y:-66.0409},
{x:44.8785,y:-88.1365},
{x:39.737,y:-121.8339},
{x:35.2524,y:-80.5},
{x:44.5784,y:-73.5268},
{x:39.4983,y:-76.3848},
{x:32.2194,y:-110.772},
{x:35.3955,y:-97.2663},
{x:47.6798,y:-122.1709},
{x:44.5026,y:-88.0057},
{x:37.8516,y:-122.0324},
{x:27.939,y:-82.2547},
{x:44.9063,y:-93.288},
{x:39.6637,y:-86.1829},
{x:37.4433,y:-77.0419},
{x:48.2875,y:-122.6624},
{x:44.0617,y:-123.3706},
{x:13.5136,y:144.8435},
{x:38.6471,y:-77.3356},
{x:38.8586,y:-104.7753},
{x:38.0519,y:-78.6817},
{x:27.7328,y:-81.5731},
{x:39.1417,y:-77.2178},
{x:37.6413,y:-77.514},
{x:38.9978,y:-77.4033},
{x:38.6336,y:-77.373},
{x:38.4772,y:-77.4179},
{x:33.4986,y:-86.846},
{x:21.3587,y:-157.9397},
{x:34.0733,y:-118.3767},
{x:38.901,y:-77.0328},
{x:33.9608,y:-98.5131},
{x:38.232,y:-77.5023},
{x:44.9687,y:-94.0542},
{x:45.773,y:-92.6733},
{x:37.9166,y:-76.471},
{x:36.0336,y:-84.3264},
{x:29.5602,y:-95.3497},
{x:33.5073,y:-112.0469},
{x:42.2252,y:-121.7822},
{x:41.1021,y:-81.4822},
{x:45.3491,y:-122.6508},
{x:44.9489,y:-122.9927},
{x:46.3813,y:-116.9769},
{x:41.7434,y:-74.0736},
{x:31.1171,y:-99.3354},
{x:41.2147,y:-86.7761},
{x:40.6572,y:-79.3019},
{x:33.5392,y:-85.2528},
{x:41.9069,y:-87.8346},
{x:32.2105,y:-95.8324},
{x:34.8187,y:-118.8842},
{x:36.9962,y:-85.9123},
{x:38.6194,y:-90.0417},
{x:44.7476,y:-93.3829},
{x:42.1613,y:-72.5825},
{x:38.6543,y:-121.1796},
{x:34.1882,y:-97.1709},
{x:34.1799,y:-119.1658},
{x:30.4748,y:-84.2961},
{x:42.5706,y:-114.4571},
{x:43.4775,y:-111.9911},
{x:38.2675,y:-85.7824},
{x:38.2885,y:-85.5119},
{x:42.3403,y:-83.3889},
{x:27.6087,y:-81.5167},
{x:35.2394,y:-85.8502},
{x:42.5451,y:-83.5382},
{x:44.7377,y:-88.4457},
{x:41.6671,y:-103.0992},
{x:34.2429,y:-119.0127},
{x:48.3013,y:-116.5475},
{x:28.4598,y:-98.1821},
{x:44.7819,y:-93.5051},
{x:36.7707,y:-76.3475},
{x:46.0805,y:-94.4929},
{x:41.2334,y:-80.5588},
{x:25.6252,y:-80.415},
{x:35.6041,y:-77.3998},
{x:34.194,y:-118.5358},
{x:42.254,y:-83.3088},
{x:37.4303,y:-83.9954},
{x:32.5278,y:-92.0753},
{x:33.9166,y:-118.352},
{x:29.7276,y:-84.9852},
{x:37.6999,y:-122.1768},
{x:42.5207,y:-83.5163},
{x:33.0988,y:-96.7323},
{x:44.8931,y:-71.4977},
{x:41.6659,y:-86.2157},
{x:38.958,y:-104.7668},
{x:32.5336,y:-84.9692},
{x:45.6706,y:-94.8109},
{x:34.8246,y:-82.5718},
{x:36.9616,y:-92.6703},
{x:34.9481,y:-82.2279},
{x:32.4077,y:-91.187},
{x:44.5306,y:-88.7464},
{x:32.7995,y:-96.9761},
{x:31.6847,y:-96.4762},
{x:41.1337,y:-81.5081},
{x:32.2697,y:-106.9217},
{x:36.1784,y:-83.3914},
{x:38.9612,y:-92.2518},
{x:37.9669,y:-91.3614},
{x:40.0588,y:-106.3915},
{x:28.494,y:-81.507},
{x:35.4409,y:-80.6034},
{x:33.6213,y:-117.6591},
{x:42.9783,y:-78.0037},
{x:43.5434,y:-116.1539},
{x:31.1144,y:-97.9069},
{x:45.4926,y:-93.2427},
{x:38.1885,y:-99.0979},
{x:41.7539,y:-71.4765},
{x:18.4557,y:-66.0839},
{x:34.1506,y:-118.3784},
{x:34.124,y:-116.4148},
{x:41.0763,y:-85.1369},
{x:38.6483,y:-76.9578},
{x:41.9021,y:-88.4742},
{x:39.6187,y:-86.2109},
{x:33.6624,y:-95.5042},
{x:39.5738,y:-76.3851},
{x:28.0695,y:-82.4302},
{x:38.0833,y:-88.1703},
{x:38.0934,y:-76.8048},
{x:34.8228,y:-92.3707},
{x:39.3483,y:-76.6918},
{x:39.1412,y:-76.6308},
{x:41.3699,y:-72.921},
{x:40.56,y:-81.8065},
{x:30.5771,y:-91.1699},
{x:27.4678,y:-82.563},
{x:44.9473,y:-123.0488},
{x:39.2501,y:-76.6458},
{x:34.0898,y:-117.7372},
{x:37.3962,y:-80.8023},
{x:38.1227,y:-85.8547},
{x:35.6593,y:-109.0428},
{x:39.8398,y:-83.075},
{x:40.3146,y:-76.5843},
{x:30.1976,y:-98.1073},
{x:41.8071,y:-72.2546},
{x:30.6824,y:-88.2243},
{x:33.9848,y:-84.2136},
{x:35.879,y:-97.3959},
{x:44.8599,y:-93.6573},
{x:41.2463,y:-81.3453},
{x:43.6564,y:-70.2611},
{x:29.7707,y:-95.4113},
{x:34.0433,y:-117.8489},
{x:31.2523,y:-85.389},
{x:21.385,y:-157.9403},
{x:41.5249,y:-72.7626},
{x:29.8532,y:-84.6742},
{x:29.9366,y:-85.4013},
{x:39.0867,y:-84.3006},
{x:39.8678,y:-88.1602},
{x:44.8821,y:-89.3374},
{x:41.9813,y:-84.6673},
{x:36.793,y:-76.2382},
{x:37.7962,y:-97.3258},
{x:42.3556,y:-71.0609},
{x:34.4421,y:-88.1474},
{x:40.4264,y:-79.966},
{x:45.4014,y:-84.6185},
{x:33.2902,y:-117.4389},
{x:61.14,y:-149.8657},
{x:32.521,y:-94.7173},
{x:44.9412,y:-92.3737},
{x:30.3053,y:-81.8462},
{x:21.906,y:-159.5884},
{x:30.649,y:-93.8974},
{x:37.1576,y:-92.7862},
{x:39.7655,y:-105.2336},
{x:38.668,y:-87.5139},
{x:44.8703,y:-94.376},
{x:36.9701,y:-76.9815},
{x:31.3133,y:-89.4118},
{x:38.3563,y:-87.5858},
{x:40.373,y:-96.1804},
{x:40.9737,y:-81.7819},
{x:45.6096,y:-123.1129},
{x:29.6554,y:-95.5299},
{x:40.9127,y:-89.0538},
{x:32.6794,y:-117.1202},
{x:35.7329,y:-84.3338},
{x:38.0539,y:-81.1026},
{x:40.4219,y:-104.7596},
{x:36.358,y:-119.2966},
{x:41.6675,y:-72.8045},
{x:42.3326,y:-83.1765},
{x:41.1983,y:-79.3906},
{x:42.0606,y:-72.6325},
{x:42.5205,y:-83.0075},
{x:27.9522,y:-81.9244},
{x:45.5281,y:-122.4115},
{x:29.5461,y:-95.1072},
{x:37.2777,y:-78.402},
{x:30.8528,y:-95.3926},
{x:37.1509,y:-80.5824},
{x:35.3182,y:-89.1528},
{x:40.2905,y:-76.9453},
{x:48.7857,y:-122.4904},
{x:27.7434,y:-97.4404},
{x:38.5468,y:-121.7595},
{x:35.318,y:-119.0276},
{x:29.1287,y:-82.0366},
{x:41.9243,y:-87.7689},
{x:35.4229,y:-99.3724},
{x:38.5428,y:-89.9856},
{x:37.753,y:-78.8781},
{x:40.2695,y:-80.6117},
{x:40.1017,y:-105.1637},
{x:35.8357,y:-80.279},
{x:33.8975,y:-78.4895},
{x:39.3125,y:-76.4487},
{x:39.2091,y:-76.7972},
{x:31.4611,y:-96.0646},
{x:46.2198,y:-119.7865},
{x:43.226,y:-123.3491},
{x:38.3257,y:-85.7417},
{x:42.1347,y:-72.5236},
{x:30.7602,y:-98.2191},
{x:41.8432,y:-71.4458},
{x:46.227,y:-119.2462},
{x:42.008,y:-87.8848},
{x:38.8636,y:-77.8495},
{x:27.5874,y:-99.4772},
{x:41.7745,y:-72.6398},
{x:38.6662,y:-90.6006},
{x:40.912,y:-97.0988},
{x:33.8579,y:-118.3031},
{x:36.0622,y:-79.8151},
{x:33.86,y:-117.9248},
{x:37.2619,y:-119.6961},
{x:34.9631,y:-89.9796},
{x:33.7579,y:-111.9909},
{x:41.6701,y:-89.9362},
{x:36.1363,y:-86.6998},
{x:42.3631,y:-122.8599},
{x:34.0516,y:-117.9992},
{x:38.8311,y:-77.1255},
{x:18.363,y:-66.1191},
{x:39.5687,y:-105.0828},
{x:36.5541,y:-87.7863},
{x:42.7203,y:-88.9788},
{x:34.3991,y:-103.1961},
{x:37.3582,y:-77.9609},
{x:28.5231,y:-99.8559},
{x:41.8697,y:-87.6544},
{x:39.1926,y:-85.8938},
{x:34.1513,y:-118.8235},
{x:26.2006,y:-98.1656},
{x:42.3333,y:-71.1025},
{x:30.457,y:-91.2482},
{x:35.2581,y:-120.6897},
{x:46.722,y:-116.989},
{x:44.0552,y:-121.2623},
{x:32.894,y:-117.1276},
{x:41.9368,y:-87.648},
{x:39.0566,y:-104.842},
{x:37.1677,y:-78.1167},
{x:40.5578,y:-80.2043},
{x:18.0452,y:-66.507},
{x:21.3493,y:-157.9304},
{x:17.9802,y:-66.0953},
{x:30.0176,y:-95.4431},
{x:32.8002,y:-93.0735},
{x:46.2868,y:-122.9016},
{x:38.7653,y:-90.6344},
{x:18.2274,y:-66.0249},
{x:38.6609,y:-77.2481},
{x:41.0974,y:-85.0996},
{x:18.4132,y:-66.319},
{x:42.2753,y:-122.8159},
{x:36.6511,y:-97.342},
{x:35.2182,y:-101.3817},
{x:38.9821,y:-84.3975},
{x:37.9545,y:-87.4453},
{x:39.015,y:-95.7642},
{x:39.4594,y:-95.7487},
{x:32.7968,y:-117.193},
{x:30.3696,y:-97.8001},
{x:39.6336,y:-77.8071},
{x:33.6828,y:-83.9981},
{x:44.8835,y:-93.2777},
{x:34.8887,y:-85.2694},
{x:45.6675,y:-122.6025},
{x:32.692,y:-96.8552},
{x:30.6309,y:-96.3809},
{x:40.1048,y:-83.2793},
{x:28.9889,y:-96.6255},
{x:39.171,y:-77.2043},
{x:44.2374,y:-88.4048},
{x:29.5411,y:-98.0736},
{x:34.5383,y:-117.2541},
{x:34.9515,y:-85.2594},
{x:39.9993,y:-82.9308},
{x:35.5777,y:-84.2749},
{x:34.759,y:-84.9706},
{x:42.367,y:-122.859},
{x:31.3615,y:-89.3505},
{x:38.0105,y:-121.8688},
{x:32.9988,y:-97.2056},
{x:39.5786,y:-86.4831},
{x:33.1099,y:-96.6615},
{x:31.288,y:-94.7291},
{x:61.1309,y:-146.3601},
{x:39.0366,y:-77.0553},
{x:42.035,y:-87.9402},
{x:30.8832,y:-83.2907},
{x:42.6229,y:-84.7394},
{x:44.5028,y:-89.1225},
{x:45.5548,y:-122.8358},
{x:43.6025,y:-116.1727},
{x:42.2199,y:-71.6963},
{x:33.3461,y:-86.6368},
{x:44.3177,y:-85.212},
{x:38.9563,y:-84.6304},
{x:34.9972,y:-117.6058},
{x:43.0798,y:-73.8035},
{x:44.771,y:-93.9181},
{x:35.4092,y:-77.9836},
{x:30.7,y:-88.0448},
{x:45.5101,y:-91.7641},
{x:41.736,y:-72.6861},
{x:33.8482,y:-117.9973},
{x:29.8071,y:-95.2619},
{x:35.3777,y:-97.5736},
{x:28.0053,y:-81.7769},
{x:32.4726,y:-84.9868},
{x:43.8822,y:-84.4849},
{x:32.7948,y:-96.8293},
{x:36.2605,y:-115.1547},
{x:38.9896,y:-76.9881},
{x:30.9549,y:-97.5348},
{x:35.2507,y:-91.7276},
{x:42.3238,y:-122.9003},
{x:40.1637,y:-110.4122},
{x:33.5286,y:-84.6624},
{x:34.044,y:-84.3424},
{x:38.857,y:-104.8986},
{x:41.3628,y:-73.0929},
{x:43.067,y:-92.3273},
{x:33.4812,y:-112.2022},
{x:18.1846,y:-65.9666},
{x:37.9445,y:-91.7842},
{x:40.8126,y:-96.7888},
{x:38.526,y:-121.4086},
{x:33.2284,y:-98.1664},
{x:37.7966,y:-121.2001},
{x:44.4142,y:-68.6198},
{x:41.7791,y:-87.7033},
{x:38.5945,y:-121.2846},
{x:39.2121,y:-92.1222},
{x:37.8212,y:-94.7069},
{x:32.3127,y:-86.2246},
{x:39.6247,y:-77.7708},
{x:42.3496,y:-87.8761},
{x:37.9744,y:-122.0404},
{x:44.9504,y:-123.032},
{x:32.7956,y:-97.3477},
{x:41.7739,y:-78.0212},
{x:28.3949,y:-80.6114},
{x:38.0985,y:-102.6186},
{x:34.2085,y:-118.5539},
{x:41.0574,y:-80.3581},
{x:44.3268,y:-88.1685},
{x:45.7808,y:-122.5599},
{x:34.0701,y:-117.1399},
{x:44.4685,y:-87.9577},
{x:25.9491,y:-97.5017},
{x:42.8453,y:-106.3762},
{x:39.52,y:-86.1587},
{x:38.4273,y:-95.7295},
{x:42.6612,y:-112.2026},
{x:36.2658,y:-85.9569},
{x:28.079,y:-81.992},
{x:34.0736,y:-118.2095},
{x:18.2387,y:-66.0209},
{x:35.9152,y:-94.9593},
{x:40.6531,y:-111.9671},
{x:44.0461,y:-122.9614},
{x:33.604,y:-92.0659},
{x:45.1556,y:-85.1278},
{x:37.2475,y:-107.8743},
{x:30.0488,y:-95.2335},
{x:41.1491,y:-85.0633},
{x:30.3982,y:-87.2986},
{x:38.7849,y:-90.5102},
{x:38.9589,y:-119.7767},
{x:33.9651,y:-83.5968},
{x:36.1717,y:-115.1457},
{x:36.1207,y:-115.1718},
{x:39.2084,y:-84.3996},
{x:35.1635,y:-89.8571},
{x:18.4634,y:-66.1134},
{x:40.791,y:-74.1509},
{x:32.539,y:-85.1012},
{x:42.3219,y:-83.4733},
{x:37.1093,y:-93.0575},
{x:29.681,y:-90.7482},
{x:39.1854,y:-78.1399},
{x:26.6632,y:-80.266},
{x:44.9819,y:-123.0252},
{x:13.4899,y:144.7808},
{x:38.8363,y:-77.4373},
{x:40.3189,y:-79.692},
{x:37.465,y:-121.1748},
{x:42.6023,y:-72.592},
{x:35.1934,y:-101.8482},
{x:47.0558,y:-122.3991},
{x:36.8202,y:-76.2975},
{x:36.4163,y:-79.3494},
{x:34.496,y:-118.6217},
{x:36.8881,y:-76.302},
{x:40.5539,y:-84.4084},
{x:33.2114,y:-117.2308},
{x:25.8268,y:-80.3373},
{x:39.97,y:-104.8171},
{x:39.7733,y:-104.8088},
{x:44.9668,y:-74.6677},
{x:38.9369,y:-92.3598},
{x:28.4671,y:-81.3045},
{x:40.3862,y:-79.8213},
{x:35.2539,y:-106.6489},
{x:32.3426,y:-95.2932},
{x:39.7616,y:-121.8202},
{x:39.0764,y:-77.004},
{x:43.6035,y:-84.0697},
{x:37.0582,y:-80.441},
{x:40.796,y:-81.7025},
{x:34.0586,y:-118.2377},
{x:36.9492,y:-79.3633},
{x:37.2706,y:-77.4109},
{x:46.3589,y:-124.0539},
{x:39.1443,y:-77.4125},
{x:32.5159,y:-92.1918},
{x:38.9581,y:-85.8687},
{x:30.2287,y:-92.3827},
{x:40.8057,y:-75.601},
{x:43.449,y:-96.8267},
{x:36.855,y:-120.7784},
{x:39.7822,y:-84.0013},
{x:29.963,y:-90.1653},
{x:34.7875,y:-82.4812},
{x:47.7045,y:-122.1763},
{x:35.0724,y:-92.4928},
{x:33.7601,y:-117.828},
{x:35.1917,y:-101.7612},
{x:37.0427,y:-76.3533},
{x:44.2433,y:-88.3453},
{x:44.8247,y:-68.7812},
{x:37.5004,y:-79.7503},
{x:45.1246,y:-95.0213},
{x:31.7017,y:-106.3209},
{x:40.1581,y:-76.8211},
{x:32.3591,y:-90.0147},
{x:34.1354,y:-117.2328},
{x:33.8549,y:-117.5629},
{x:39.1094,y:-76.9356},
{x:39.0527,y:-77.1046},
{x:41.5636,y:-87.4616},
{x:29.2312,y:-98.7885},
{x:40.8449,y:-74.0417},
{x:40.4781,y:-81.4484},
{x:37.1373,y:-76.5208},
{x:41.284,y:-81.4401},
{x:41.8856,y:-87.6434},
{x:37.9834,y:-122.3176},
{x:43.2207,y:-85.7512},
{x:43.2259,y:-77.2824},
{x:36.6082,y:-121.8458},
{x:39.0206,y:-77.0119},
{x:45.1481,y:-122.5773},
{x:30.7836,y:-88.2759},
{x:36.6846,y:-121.795},
{x:40.5655,y:-74.2873},
{x:41.8153,y:-87.6655},
{x:35.7548,y:-78.6148},
{x:28.3862,y:-81.4858},
{x:32.6571,y:-96.891},
{x:41.9614,y:-87.6664},
{x:18.3958,y:-65.9943},
{x:29.9624,y:-90.1853},
{x:44.2719,y:-92.9856},
{x:36.1702,y:-115.1437},
{x:29.9741,y:-90.1504},
{x:36.8216,y:-119.7095},
{x:40.9279,y:-87.1562},
{x:29.8974,y:-90.1425},
{x:26.6412,y:-81.8719},
{x:45.0551,y:-93.2467},
{x:34.585,y:-112.333},
{x:47.3135,y:-122.3095},
{x:37.474,y:-82.5267},
{x:40.5499,y:-81.4984},
{x:26.1919,y:-98.2316},
{x:41.9845,y:-87.9796},
{x:47.0465,y:-122.875},
{x:25.8816,y:-80.2657},
{x:41.4728,y:-84.5508},
{x:43.9172,y:-116.1966},
{x:30.9998,y:-86.3298},
{x:41.4045,y:-84.1323},
{x:20.8796,y:-156.6787},
{x:40.3826,y:-105.0973},
{x:32.2218,y:-110.9105},
{x:30.4293,y:-81.6703},
{x:47.6094,y:-122.3166},
{x:38.7606,y:-90.7011},
{x:37.4206,y:-77.635},
{x:33.9511,y:-78.0535},
{x:35.5027,y:-97.5338},
{x:39.9758,y:-111.7792},
{x:37.2117,y:-93.3571},
{x:35.1833,y:-107.8998},
{x:36.0633,y:-80.305},
{x:43.5656,y:-116.1992},
{x:38.6747,y:-77.3335},
{x:45.6709,y:-122.5073},
{x:29.7019,y:-95.2972},
{x:37.8366,y:-94.3318},
{x:26.756,y:-80.9489},
{x:41.6709,y:-87.7205},
{x:38.216,y:-122.1413},
{x:38.217,y:-85.5719},
{x:39.2387,y:-94.4363},
{x:28.54,y:-82.4065},
{x:32.843,y:-79.8549},
{x:39.0363,y:-104.3044},
{x:37.9785,y:-100.8829},
{x:39.93,y:-105.014},
{x:34.0185,y:-118.4715},
{x:34.1067,y:-117.3971},
{x:34.034,y:-117.9753},
{x:33.6242,y:-111.9425},
{x:38.8409,y:-77.0627},
{x:33.3774,y:-111.6348},
{x:35.1187,y:-106.7007},
{x:40.8849,y:-111.8917},
{x:38.7938,y:-77.6089},
{x:43.7602,y:-105.4769},
{x:34.1922,y:-118.9374},
{x:46.0082,y:-122.8451},
{x:26.2376,y:-98.2394},
{x:32.7708,y:-115.5526},
{x:33.8641,y:-112.1392},
{x:41.8969,y:-87.7085},
{x:41.8664,y:-87.6687},
{x:41.137,y:-104.7617},
{x:29.7236,y:-95.3514},
{x:18.4128,y:-65.9827},
{x:42.5932,y:-114.7484},
{x:39.7382,y:-81.5291},
{x:33.7087,y:-116.2732},
{x:41.4648,y:-81.4976},
{x:45.1587,y:-93.3897},
{x:37.6192,y:-84.5791},
{x:30.8361,y:-91.212},
{x:41.9545,y:-73.9948},
{x:30.6509,y:-96.3346},
{x:34.472,y:-93.0218},
{x:40.0212,y:-98.0662},
{x:43.7909,y:-70.1888},
{x:33.419,y:-94.0921},
{x:35.0816,y:-106.6354},
{x:37.637,y:-93.1039},
{x:42.109,y:-88.114},
{x:40.3846,y:-82.485},
{x:29.8109,y:-90.8165},
{x:33.9208,y:-84.4671},
{x:41.3501,y:-87.6207},
{x:18.2419,y:-66.0361},
{x:41.88,y:-93.815},
{x:47.0996,y:-122.2099},
{x:39.2517,y:-106.2899},
{x:30.1903,y:-84.215},
{x:32.3305,y:-96.1141},
{x:41.4285,y:-81.8898},
{x:33.011,y:-96.6098},
{x:38.5797,y:-121.4896},
{x:36.8376,y:-119.8889},
{x:32.0057,y:-81.2873},
{x:41.1142,y:-97.5982},
{x:18.3959,y:-66.073},
{x:26.1555,y:-97.9133},
{x:26.4827,y:-97.7682},
{x:33.9635,y:-117.694},
{x:37.5486,y:-97.243},
{x:38.8855,y:-94.7172},
{x:41.979,y:-87.66},
{x:39.9922,y:-75.1},
{x:41.2859,y:-72.4505},
{x:25.7317,y:-80.3811},
{x:42.8434,y:-82.8906},
{x:32.938,y:-117.1015},
{x:37.1122,y:-121.0171},
{x:36.8852,y:-76.1775},
{x:36.3984,y:-86.1625},
{x:37.2939,y:-76.5126},
{x:39.7924,y:-77.2021},
{x:28.0262,y:-82.5465},
{x:39.2457,y:-94.4622},
{x:30.3754,y:-89.379},
{x:42.1592,y:-76.8784},
{x:27.9431,y:-82.3368},
{x:29.9452,y:-95.4666},
{x:30.5321,y:-87.0881},
{x:39.4192,y:-77.4483},
{x:32.6397,y:-117.0496},
{x:29.9033,y:-95.5559},
{x:38.6569,y:-85.13},
{x:29.9594,y:-95.5471},
{x:43.6121,y:-116.5523},
{x:29.7372,y:-95.5015},
{x:35.0551,y:-78.8848},
{x:39.3086,y:-76.8841},
{x:33.3948,y:-111.908},
{x:42.3119,y:-86.1087},
{x:30.0238,y:-82.3298},
{x:40.0894,y:-82.8227},
{x:34.2775,y:-119.2129},
{x:40.7903,y:-73.9452},
{x:40.0114,y:-76.3539},
{x:38.9707,y:-94.7022},
{x:41.6417,y:-88.1241},
{x:40.9391,y:-123.629},
{x:38.7591,y:-76.8067},
{x:40.7946,y:-81.9385},
{x:18.1446,y:-65.837},
{x:17.9963,y:-66.6394},
{x:33.6571,y:-117.9081},
{x:40.4521,y:-80.1339},
{x:33.5244,y:-84.3638},
{x:40.7516,y:-73.7034},
{x:36.801,y:-119.7713},
{x:33.8114,y:-93.427},
{x:38.8651,y:-77.197},
{x:42.4329,y:-90.4456},
{x:26.9318,y:-82.2242},
{x:34.3079,y:-82.6633},
{x:42.6707,y:-82.9752},
{x:47.9073,y:-122.2938},
{x:35.2961,y:-80.7388},
{x:32.5287,y:-92.7137},
{x:36.2878,y:-82.174},
{x:35.2548,y:-81.1792},
{x:32.9574,y:-86.7434},
{x:32.4646,y:-86.4502},
{x:41.9056,y:-87.6689},
{x:33.5559,y:-80.8241},
{x:33.1315,y:-117.0641},
{x:32.3937,y:-110.9963},
{x:32.8628,y:-79.9036},
{x:32.2046,y:-98.2399},
{x:32.3959,y:-97.3311},
{x:29.8806,y:-95.6857},
{x:36.8509,y:-119.7752},
{x:38.3501,y:-85.7971},
{x:47.1925,y:-122.4101},
{x:33.1267,y:-97.1815},
{x:35.0204,y:-85.321},
{x:41.5206,y:-90.5886},
{x:36.7939,y:-119.8627},
{x:18.4862,y:-66.8155},
{x:38.9655,y:-77.0763},
{x:39.8015,y:-85.7693},
{x:41.5525,y:-73.0367},
{x:40.9698,y:-81.6969},
{x:37.2984,y:-81.1677},
{x:45.4423,y:-93.5847},
{x:39.5204,y:-85.7521},
{x:33.728,y:-117.8223},
{x:41.7615,y:-73.9308},
{x:39.7607,y:-86.5234},
{x:38.4805,y:-78.8714},
{x:41.564,y:-83.5794},
{x:47.8508,y:-122.2194},
{x:37.8644,y:-83.9351},
{x:41.2198,y:-81.6371},
{x:42.6787,y:-89.0523},
{x:26.2037,y:-98.2359},
{x:33.6059,y:-85.0818},
{x:41.2345,y:-80.8194},
{x:32.905,y:-97.0449},
{x:37.9796,y:-85.6946},
{x:32.6569,y:-114.5941},
{x:41.0064,y:-80.3782},
{x:32.7216,y:-84.0128},
{x:41.5649,y:-88.2003},
{x:43.0497,y:-86.2208},
{x:41.7586,y:-88.2118},
{x:42.4037,y:-83.1595},
{x:32.4438,y:-97.6885},
{x:35.3281,y:-81.758},
{x:38.8966,y:-77.0243},
{x:39.1085,y:-84.401},
{x:39.3591,y:-86.0865},
{x:41.1409,y:-112.0651},
{x:38.7498,y:-90.2786},
{x:42.4671,y:-83.1237},
{x:42.2601,y:-83.21},
{x:29.7835,y:-95.0315},
{x:38.6919,y:-90.3907},
{x:34.3462,y:-83.3184},
{x:29.5951,y:-95.4668},
{x:40.6639,y:-96.1783},
{x:42.5762,y:-83.3614},
{x:41.6715,y:-86.3213},
{x:40.777,y:-73.559},
{x:33.735,y:-117.8673},
{x:33.601,y:-84.3391},
{x:18.3468,y:-67.0006},
{x:34.1456,y:-118.2401},
{x:29.7968,y:-98.4274},
{x:32.7021,y:-117.1841},
{x:39.2953,y:-76.5699},
{x:42.2911,y:-84.4153},
{x:35.6737,y:-105.9531},
{x:27.862,y:-82.2018},
{x:34.7114,y:-87.665},
{x:46.9803,y:-123.5995},
{x:40.3126,y:-88.9866},
{x:37.3665,y:-122.0132},
{x:34.8564,y:-82.2523},
{x:47.7901,y:-122.3673},
{x:42.1978,y:-83.261},
{x:41.8185,y:-111.8318},
{x:38.9914,y:-76.8335},
{x:21.3909,y:-158.0339},
{x:45.4784,y:-122.694},
{x:41.8863,y:-88.1944},
{x:35.791,y:-80.8527},
{x:39.8071,y:-82.2999},
{x:42.1535,y:-77.0764},
{x:43.0076,y:-78.2061},
{x:47.6398,y:-122.4006},
{x:18.3937,y:-66.1041},
{x:39.141,y:-77.1946},
{x:40.0801,y:-102.2271},
{x:36.678,y:-119.7338},
{x:35.2527,y:-81.0261},
{x:27.7968,y:-97.398},
{x:38.1329,y:-77.5234},
{x:26.2825,y:-98.1659},
{x:37.6843,y:-97.3523},
{x:33.8344,y:-118.1155},
{x:33.7697,y:-86.4678},
{x:18.0598,y:-66.3698},
{x:39.864,y:-86.3936},
{x:36.9281,y:-80.0221},
{x:37.9891,y:-87.4745},
{x:36.1571,y:-88.8017},
{x:35.2058,y:-89.7619},
{x:43.0166,y:-88.0262},
{x:33.7296,y:-118.0159},
{x:30.341,y:-97.692},
{x:28.9527,y:-81.9603},
{x:36.8792,y:-76.0413},
{x:44.213,y:-89.5271},
{x:38.257,y:-94.3496},
{x:39.2116,y:-94.6453},
{x:36.0905,y:-80.2253},
{x:40.5924,y:-111.8326},
{x:34.6902,y:-118.1665},
{x:27.9957,y:-82.5188},
{x:40.45,y:-80.0181},
{x:37.6179,y:-90.6413},
{x:36.3578,y:-82.3931},
{x:42.2653,y:-77.8041},
{x:30.3439,y:-91.0313},
{x:34.967,y:-80.9966},
{x:38.5748,y:-77.3154},
{x:38.7451,y:-121.2653},
{x:41.6961,y:-93.246},
{x:42.6065,y:-90.4309},
{x:34.956,y:-81.0468},
{x:33.7793,y:-118.2634},
{x:34.436,y:-118.5626},
{x:34.416,y:-118.4749},
{x:42.4927,y:-83.5193},
{x:33.9254,y:-118.0258},
{x:34.6996,y:-82.9395},
{x:38.5475,y:-121.3945},
{x:41.7344,y:-72.2015},
{x:47.6576,y:-117.2804},
{x:40.7105,y:-73.3071},
{x:41.9685,y:-87.6883},
{x:38.7033,y:-75.4036},
{x:38.9477,y:-77.0791},
{x:31.2238,y:-94.987},
{x:42.592,y:-83.4818},
{x:42.2225,y:-83.3858},
{x:33.931,y:-118.1834},
{x:43.0421,y:-76.1349},
{x:43.0514,y:-83.6349},
{x:29.5378,y:-98.5242},
{x:48.7558,y:-122.463},
{x:47.6302,y:-122.1787},
{x:33.4643,y:-86.9141},
{x:30.2219,y:-95.5641},
{x:30.0977,y:-81.7116},
{x:41.734,y:-88.2742},
{x:37.7375,y:-88.5326},
{x:41.9118,y:-73.0418},
{x:37.2695,y:-76.6776},
{x:36.5719,y:-79.4211},
{x:47.7695,y:-117.4034},
{x:34.2344,y:-118.5021},
{x:34.2753,y:-118.5636},
{x:40.6128,y:-74.1212},
{x:33.7455,y:-117.9892},
{x:42.0813,y:-80.1173},
{x:43.2878,y:-83.5319},
{x:37.6365,y:-77.3575},
{x:35.3425,y:-78.0293},
{x:45.8573,y:-84.7337},
{x:40.0117,y:-80.7442},
{x:35.5625,y:-81.1955},
{x:40.7983,y:-81.3934},
{x:39.2729,y:-76.7293},
{x:36.7463,y:-90.4121},
{x:41.5559,y:-87.6825},
{x:41.3848,y:-81.7341},
{x:41.3156,y:-96.0686},
{x:40.8373,y:-81.3898},
{x:36.1181,y:-80.7955},
{x:39.1295,y:-76.549},
{x:38.8124,y:-104.7481},
{x:34.7008,y:-86.3849},
{x:41.9194,y:-72.6068},
{x:40.8296,y:-81.4245},
{x:30.073,y:-94.7858},
{x:40.7952,y:-81.4346},
{x:44.3784,y:-92.0346},
{x:38.9017,y:-77.2049},
{x:19.5731,y:-155.9628},
{x:39.3329,y:-76.6354},
{x:35.2202,y:-81.0959},
{x:35.0724,y:-91.9034},
{x:40.8105,y:-81.3712},
{x:30.0335,y:-95.5536},
{x:32.0088,y:-81.0995},
{x:44.2808,y:-121.1735},
{x:29.6486,y:-95.6496},
{x:38.9944,y:-104.8571},
{x:38.9135,y:-105.2855},
{x:40.8814,y:-81.4276},
{x:38.0929,y:-87.5574},
{x:39.9147,y:-105.0565},
{x:42.1247,y:-88.3315},
{x:40.8046,y:-124.1513},
{x:40.9177,y:-81.1061},
{x:37.3213,y:-91.9583},
{x:25.6855,y:-80.4556},
{x:28.1867,y:-82.7401},
{x:41.8665,y:-87.7262},
{x:40.9665,y:-81.3454},
{x:33.2463,y:-87.1375},
{x:37.7325,y:-83.5504},
{x:32.9201,y:-97.1167},
{x:41.03,y:-81.4908},
{x:37.9995,y:-121.3253},
{x:32.2323,y:-97.3786},
{x:42.5378,y:-92.4623},
{x:26.1838,y:-98.2166},
{x:37.7633,y:-122.4587},
{x:38.0071,y:-121.3194},
{x:30.4152,y:-88.2463},
{x:40.8821,y:-81.5706},
{x:35.988,y:-115.1418},
{x:29.654,y:-82.5934},
{x:37.6903,y:-122.0627},
{x:33.5963,y:-86.9419},
{x:35.1997,y:-113.9044},
{x:46.8593,y:-122.8459},
{x:36.544,y:-86.2381},
{x:40.9018,y:-80.9963},
{x:44.7973,y:-69.8802},
{x:38.8556,y:-94.6836},
{x:41.3991,y:-122.3768},
{x:37.5297,y:-122.0389},
{x:33.9882,y:-118.31},
{x:43.1521,y:-86.2047},
{x:37.4121,y:-93.8464},
{x:37.2699,y:-76.7565},
{x:37.2175,y:-93.2612},
{x:37.6992,y:-121.8714},
{x:46.4998,y:-116.2983},
{x:41.9345,y:-87.8065},
{x:30.7028,y:-101.204},
{x:37.0376,y:-119.5163},
{x:40.5847,y:-104.7318},
{x:25.7014,y:-80.3825},
{x:36.3193,y:-87.6736},
{x:28.8464,y:-82.044},
{x:47.1423,y:-119.2776},
{x:39.1826,y:-77.2381},
{x:31.1958,y:-81.4867},
{x:30.2517,y:-93.1801},
{x:26.5534,y:-80.1497},
{x:39.2679,y:-76.4969},
{x:45.4385,y:-122.8269},
{x:42.0196,y:-87.6744},
{x:39.0973,y:-76.8551},
{x:33.6619,y:-117.2997},
{x:33.259,y:-97.227},
{x:33.8145,y:-98.1915},
{x:33.4967,y:-111.917},
{x:36.7404,y:-76.2432},
{x:33.5072,y:-112.2382},
{x:37.3502,y:-121.9628},
{x:34.0199,y:-117.667},
{x:33.8223,y:-117.5223},
{x:39.0359,y:-77.5006},
{x:33.2262,y:-117.3317},
{x:45.46,y:-122.7072},
{x:34.2182,y:-118.6065},
{x:42.3703,y:-83.4711},
{x:40.2282,y:-76.0896},
{x:30.0043,y:-90.1576},
{x:40.7262,y:-73.791},
{x:37.8838,y:-90.5132},
{x:31.9598,y:-83.7523},
{x:29.7071,y:-95.4023},
{x:39.8542,y:-86.0081},
{x:39.2113,y:-84.4474},
{x:26.3296,y:-81.7405},
{x:31.5813,y:-97.3065},
{x:35.7455,y:-86.9353},
{x:40.1902,y:-80.2126},
{x:38.885,y:-90.1014},
{x:35.6681,y:-97.4939},
{x:29.4016,y:-95.0349},
{x:32.0811,y:-82.1122},
{x:39.0915,y:-77.5256},
{x:41.2611,y:-80.7841},
{x:26.1548,y:-98.2806},
{x:43.1488,y:-87.9572},
{x:38.9515,y:-84.8526},
{x:32.0233,y:-81.1249},
{x:29.9793,y:-93.9639},
{x:42.3836,y:-83.0822},
{x:38.5812,y:-121.4963},
{x:33.378,y:-111.8066},
{x:32.3335,y:-90.3246},
{x:45.5785,y:-122.3359},
{x:29.7067,y:-95.4688},
{x:18.446,y:-66.068},
{x:35.4011,y:-80.6092},
{x:36.3439,y:-83.2704},
{x:32.376,y:-98.9711},
{x:33.8054,y:-84.5258},
{x:33.3704,y:-98.7599},
{x:43.8179,y:-84.768},
{x:41.6883,y:-83.7264},
{x:34.2767,y:-118.7088},
{x:40.5841,y:-77.3699},
{x:34.1706,y:-118.9564},
{x:37.7068,y:-121.9109},
{x:43.0926,y:-76.193},
{x:31.8631,y:-106.5737},
{x:41.7025,y:-85.9475},
{x:37.8869,y:-79.2927},
{x:34.8241,y:-87.2953},
{x:34.3075,y:-118.4306},
{x:32.0138,y:-90.3578},
{x:41.9262,y:-87.6407},
{x:41.7366,y:-87.5878},
{x:44.82,y:-68.8173},
{x:26.1923,y:-98.2057},
{x:34.6762,y:-118.1857},
{x:34.029,y:-117.6284},
{x:47.4416,y:-122.2341},
{x:32.8522,y:-84.8453},
{x:38.5695,y:-121.481},
{x:18.4522,y:-66.0769},
{x:45.6427,y:-109.2484},
{x:41.954,y:-87.8597},
{x:38.4794,y:-91.8414},
{x:31.1062,y:-85.4062},
{x:26.6395,y:-81.9414},
{x:37.3375,y:-121.9948},
{x:40.1033,y:-99.3687},
{x:31.1185,y:-97.4179},
{x:33.6558,y:-112.0983},
{x:42.2723,y:-83.4853},
{x:29.5509,y:-95.6945},
{x:30.087,y:-97.8214},
{x:39.748,y:-104.9774},
{x:39.7662,y:-104.9016},
{x:39.9629,y:-76.7674},
{x:33.5905,y:-117.6989},
{x:41.9307,y:-88.771},
{x:41.1083,y:-81.5666},
{x:31.8476,y:-106.6249},
{x:42.8275,y:-75.5459},
{x:34.3728,y:-83.869},
{x:38.443,y:-78.8572},
{x:41.2336,y:-76.9941},
{x:30.2338,y:-97.7211},
{x:39.3979,y:-76.7914},
{x:35.4525,y:-82.5248},
{x:40.3515,y:-104.703},
{x:39.9135,y:-82.7796},
{x:40.4059,y:-95.5576},
{x:33.6884,y:-84.4874},
{x:41.7197,y:-71.48},
{x:41.9956,y:-70.9716},
{x:13.4387,y:144.7993},
{x:39.8541,y:-86.0857},
{x:36.1137,y:-79.8807},
{x:29.9345,y:-89.9211},
{x:30.3249,y:-90.9793},
{x:40.7565,y:-73.9808},
{x:36.128,y:-77.7973},
{x:40.8225,y:-81.5021},
{x:35.2815,y:-81.5492},
{x:33.567,y:-112.1025},
{x:38.5354,y:-89.7103},
{x:45.8993,y:-91.8286},
{x:39.9201,y:-76.6294},
{x:36.6965,y:-93.3651},
{x:29.8313,y:-95.7044},
{x:34.242,y:-111.3221},
{x:34.414,y:-77.6522},
{x:39.2879,y:-76.6149},
{x:38.6278,y:-76.9112},
{x:33.6107,y:-112.1315},
{x:33.94,y:-87.2195},
{x:41.8471,y:-87.8105},
{x:36.1543,y:-80.3595},
{x:29.775,y:-95.7186},
{x:37.9459,y:-75.5407},
{x:42.2884,y:-84.2048},
{x:38.8108,y:-94.8901},
{x:26.1534,y:-97.9573},
{x:41.5267,y:-88.0731},
{x:38.2442,y:-104.6066},
{x:40.7573,y:-73.9695},
{x:30.6397,y:-96.3123},
{x:47.2328,y:-119.8608},
{x:40.9439,y:-124.1015},
{x:33.5375,y:-112.2397},
{x:44.9513,y:-91.368},
{x:29.6964,y:-95.3347},
{x:37.3086,y:-77.4009},
{x:41.7186,y:-71.4863},
{x:39.9653,y:-81.898},
{x:29.9487,y:-90.1285},
{x:34.6814,y:-87.402},
{x:38.7429,y:-94.8339},
{x:29.014,y:-80.965},
{x:42.1063,y:-76.03},
{x:40.7642,y:-79.5274},
{x:44.0843,y:-123.155},
{x:37.7221,y:-97.4616},
{x:41.1998,y:-90.746},
{x:33.6743,y:-94.1314},
{x:36.9574,y:-81.0968},
{x:39.2995,y:-76.6156},
{x:47.2501,y:-68.5973},
{x:40.7573,y:-73.5104},
{x:35.945,y:-86.8261},
{x:37.7004,y:-93.8011},
{x:45.3973,y:-122.7426},
{x:35.0652,y:-92.4119},
{x:39.7967,y:-86.0437},
{x:38.7516,y:-77.2657},
{x:35.0879,y:-78.9846},
{x:39.0225,y:-77.4198},
{x:40.733,y:-73.6841},
{x:34.0049,y:-94.7391},
{x:38.413,y:-82.3542},
{x:41.486,y:-71.524},
{x:42.1402,y:-72.7336},
{x:36.7902,y:-76.1231},
{x:37.3086,y:-122.0136},
{x:36.2668,y:-86.7571},
{x:41.7738,y:-96.2211},
{x:42.2528,y:-88.0227},
{x:43.3051,y:-70.9837},
{x:37.9922,y:-87.5392},
{x:42.9869,y:-87.9486},
{x:38.0302,y:-78.4839},
{x:38.9276,y:-95.2623},
{x:39.9928,y:-86.006},
{x:33.7235,y:-117.7732},
{x:30.4082,y:-87.2755},
{x:45.6421,y:-122.5557},
{x:36.4394,y:-95.7089},
{x:39.2889,y:-84.5232},
{x:41.0651,y:-81.4317},
{x:45.6274,y:-122.6752},
{x:34.7606,y:-112.4524},
{x:41.2356,y:-75.8641},
{x:37.0669,y:-81.7591},
{x:45.7166,y:-122.6505},
{x:33.6403,y:-112.3947},
{x:33.5379,y:-111.9081},
{x:42.885,y:-84.3419},
{x:39.7502,y:-86.0304},
{x:46.2787,y:-87.4382},
{x:40.4806,y:-78.0156},
{x:28.0457,y:-99.3489},
{x:34.0015,y:-81.1025},
{x:40.7764,y:-73.953},
{x:33.6691,y:-117.8522},
{x:36.197,y:-119.3274},
{x:35.246,y:-79.406},
{x:37.8993,y:-85.9352},
{x:42.4018,y:-73.2659},
{x:36.2397,y:-79.9818},
{x:37.1305,y:-80.4102},
{x:42.4507,y:-85.6693},
{x:33.6887,y:-117.8965},
{x:41.7828,y:-88.3201},
{x:41.9351,y:-88.4012},
{x:42.6981,y:-83.0936},
{x:35.5108,y:-97.5036},
{x:38.9029,y:-77.0465},
{x:35.052,y:-83.2023},
{x:29.4836,y:-98.5459},
{x:41.3312,y:-81.8541},
{x:37.6533,y:-120.9581},
{x:34.0911,y:-118.3382},
{x:34.0594,y:-118.1236},
{x:35.0484,y:-89.8024},
{x:37.2466,y:-76.6593},
{x:33.9976,y:-118.4608},
{x:36.2088,y:-81.6738},
{x:41.5527,y:-81.503},
{x:34.0627,y:-117.2205},
{x:29.5484,y:-98.6628},
{x:33.9831,y:-118.461},
{x:40.6669,y:-73.6611},
{x:13.4293,y:144.6544},
{x:41.5129,y:-72.1108},
{x:41.8065,y:-77.0792},
{x:35.4985,y:-78.3522},
{x:32.4295,y:-97.793},
{x:47.1911,y:-122.5102},
{x:47.1572,y:-122.4006},
{x:37.89,y:-121.6204},
{x:32.2409,y:-92.7111},
{x:42.4459,y:-83.473},
{x:37.7443,y:-77.1357},
{x:33.6156,y:-117.7077},
{x:33.599,y:-117.8725},
{x:34.0672,y:-118.4049},
{x:33.1317,y:-117.1654},
{x:38.381,y:-92.3979},
{x:36.0781,y:-79.485},
{x:40.1015,y:-75.0286},
{x:21.4352,y:-158.1837},
{x:35.6747,y:-119.2249},
{x:29.4245,y:-95.2297},
{x:37.3181,y:-80.0017},
{x:39.4708,y:-104.8757},
{x:33.8879,y:-118.3616},
{x:33.7027,y:-117.9714},
{x:42.3196,y:-89.0583},
{x:32.9935,y:-96.9757},
{x:34.0408,y:-118.5526},
{x:34.076,y:-118.3008},
{x:34.2216,y:-118.4762},
{x:34.5962,y:-82.6164},
{x:33.7981,y:-81.8919},
{x:39.972,y:-76.6752},
{x:35.353,y:-81.0895},
{x:38.37,y:-75.6141},
{x:38.1292,y:-83.7559},
{x:40.7307,y:-73.46},
{x:35.3096,y:-77.1583},
{x:34.4098,y:-118.5786},
{x:41.9338,y:-87.9388},
{x:41.9942,y:-87.9796},
{x:39.3036,y:-89.281},
{x:38.4908,y:-89.7939},
{x:34.4461,y:-82.3883},
{x:30.4521,y:-93.4407},
{x:41.3852,y:-72.901},
{x:28.7044,y:-81.5105},
{x:42.872,y:-78.7871},
{x:40.8704,y:-72.9976},
{x:35.5199,y:-84.7895},
{x:47.5835,y:-122.0343},
{x:42.342,y:-83.4878},
{x:43.6239,y:-72.1991},
{x:38.9562,y:-76.9401},
{x:35.3744,y:-118.9351},
{x:43.0883,y:-89.2128},
{x:35.2665,y:-119.2535},
{x:45.5694,y:-88.9032},
{x:42.6937,y:-83.3878},
{x:34.9743,y:-80.5165},
{x:40.985,y:-74.1394},
{x:26.4492,y:-80.1463},
{x:37.6644,y:-97.2963},
{x:33.8587,y:-117.9974},
{x:36.7246,y:-76.2972},
{x:41.2195,y:-85.0135},
{x:20.752,y:-156.4486},
{x:39.328,y:-76.6158},
{x:39.3131,y:-76.7374},
{x:38.4292,y:-75.5651},
{x:40.7981,y:-73.9398},
{x:33.3931,y:-111.7878},
{x:45.7516,y:-87.0783},
{x:28.6959,y:-100.4859},
{x:39.2623,y:-94.574},
{x:42.311,y:-71.7982},
{x:36.4115,y:-90.5863},
{x:42.0405,y:-87.7504},
{x:33.6394,y:-111.864},
{x:29.5329,y:-95.2059},
{x:40.2226,y:-76.9338},
{x:35.5882,y:-82.5865},
{x:29.948,y:-95.418},
{x:40.8575,y:-73.0816},
{x:40.7094,y:-74.009},
{x:40.8922,y:-122.3821},
{x:41.7367,y:-87.7022},
{x:38.1158,y:-121.2645},
{x:32.0656,y:-84.2536},
{x:39.1741,y:-94.5349},
{x:41.6847,y:-91.6006},
{x:61.583,y:-149.6367},
{x:44.9913,y:-93.2186},
{x:41.2134,y:-80.7512},
{x:34.0889,y:-84.5864},
{x:42.7553,y:-73.7733},
{x:33.1062,y:-96.8256},
{x:35.9803,y:-84.1052},
{x:35.9028,y:-84.0222},
{x:39.6939,y:-104.7895},
{x:33.9581,y:-84.1359},
{x:40.9425,y:-72.9849},
{x:18.3554,y:-66.0023},
{x:18.0102,y:-66.603},
{x:36.2641,y:-80.3638},
{x:33.2431,y:-97.6047},
{x:38.9289,y:-75.5662},
{x:41.878,y:-87.6475},
{x:42.1972,y:-85.6358},
{x:39.7638,y:-89.6472},
{x:33.7669,y:-117.9025},
{x:18.2553,y:-66.0373},
{x:41.7623,y:-88.1913},
{x:33.5086,y:-112.265},
{x:41.9229,y:-87.6975},
{x:33.7227,y:-118.3104},
{x:33.9707,y:-118.1877},
{x:41.2115,y:-96.0808},
{x:38.8699,y:-76.9601},
{x:25.8538,y:-80.3202},
{x:29.7345,y:-95.4109},
{x:30.4646,y:-88.9705},
{x:42.9456,y:-85.5521},
{x:45.6246,y:-122.59},
{x:42.8473,y:-89.059},
{x:40.8368,y:-81.2634},
{x:41.6131,y:-88.2035},
{x:41.4283,y:-88.2298},
{x:34.1579,y:-118.3963},
{x:34.4711,y:-117.2899},
{x:42.0668,y:-88.0045},
{x:28.1408,y:-82.5072},
{x:41.8461,y:-72.561},
{x:40.7632,y:-73.3308},
{x:35.0045,y:-80.8565},
{x:47.6913,y:-117.1961},
{x:34.1721,y:-118.5364},
{x:41.9106,y:-87.6901},
{x:33.9724,y:-118.2115},
{x:43.9717,y:-75.6259},
{x:40.8802,y:-96.6813},
{x:37.7558,y:-121.4665},
{x:32.0313,y:-102.1281},
{x:41.6073,y:-81.4409},
{x:29.9768,y:-95.4908},
{x:37.2628,y:-121.9447},
{x:40.9633,y:-80.5594},
{x:41.816,y:-72.6956},
{x:40.1293,y:-111.6355},
{x:27.7693,y:-80.4459},
{x:30.6172,y:-87.7536},
{x:28.147,y:-81.452},
{x:38.5925,y:-90.5549},
{x:44.3169,y:-88.3742},
{x:43.21,y:-77.6945},
{x:34.0505,y:-118.2151},
{x:47.307,y:-122.3349},
{x:38.7358,y:-90.3922},
{x:42.9271,y:-78.8295},
{x:42.3509,y:-73.8029},
{x:41.2865,y:-75.5116},
{x:39.353,y:-76.758},
{x:41.2832,y:-72.881},
{x:36.3964,y:-87.0294},
{x:46.822,y:-123.0886},
{x:30.374,y:-86.273},
{x:36.1286,y:-86.8454},
{x:25.7927,y:-80.369},
{x:39.4789,y:-104.7595},
{x:40.8131,y:-96.6079},
{x:42.1874,y:-122.6953},
{x:42.6422,y:-82.9542},
{x:41.2654,y:-96.068},
{x:29.5473,y:-90.3428},
{x:33.0213,y:-96.9153},
{x:34.7545,y:-92.3896},
{x:34.7467,y:-92.3836},
{x:42.5068,y:-83.0674},
{x:42.6687,y:-83.0322},
{x:31.9006,y:-96.7179},
{x:36.7664,y:-108.6977},
{x:39.5955,y:-104.8488},
{x:33.7895,y:-84.3051},
{x:42.0965,y:-79.2804},
{x:29.9959,y:-95.6217},
{x:32.8862,y:-96.887},
{x:32.7456,y:-96.818},
{x:35.2044,y:-84.8427},
{x:30.483,y:-90.58},
{x:27.587,y:-81.5155},
{x:43.0319,y:-78.6982},
{x:28.2931,y:-80.7232},
{x:34.9413,y:-85.2183},
{x:38.9899,y:-94.273},
{x:39.0318,y:-94.3045},
{x:39.239,y:-84.593},
{x:39.6546,y:-79.6386},
{x:26.5144,y:-81.8998},
{x:39.9518,y:-83.1461},
{x:32.6302,y:-96.4498},
{x:38.4756,y:-81.0809},
{x:33.2754,y:-82.9728},
{x:30.0017,y:-90.1248},
{x:38.8142,y:-77.6427},
{x:40.8385,y:-73.9413},
{x:40.2311,y:-76.892},
{x:33.944,y:-118.207},
{x:29.5338,y:-95.8089},
{x:39.7707,y:-94.8031},
{x:32.9506,y:-96.8042},
{x:34.0318,y:-118.1241},
{x:40.1696,y:-85.3821},
{x:30.248,y:-85.5562},
{x:32.7481,y:-117.1009},
{x:28.2628,y:-81.6192},
{x:45.6129,y:-122.5046},
{x:43.2834,y:-73.5872},
{x:29.66,y:-82.4136},
{x:32.5691,y:-117.0046},
{x:18.1255,y:-66.1335},
{x:35.7446,y:-119.2487},
{x:35.1252,y:-117.9834},
{x:36.935,y:-86.4221},
{x:32.3606,y:-86.1387},
{x:41.6232,y:-72.8735},
{x:40.7077,y:-74.006},
{x:25.6276,y:-80.3763},
{x:40.6353,y:-73.6545},
{x:39.3245,y:-76.5453},
{x:28.1847,y:-80.5934},
{x:38.2968,y:-85.5593},
{x:27.951,y:-80.6584},
{x:47.1589,y:-122.0355},
{x:43.7204,y:-116.3209},
{x:44.9652,y:-124.0179},
{x:47.195,y:-120.9415},
{x:40.7921,y:-77.0467},
{x:32.7042,y:-117.1004},
{x:40.7498,y:-79.8145},
{x:35.2868,y:-93.1616},
{x:28.0801,y:-80.6722},
{x:36.4838,y:-82.5026},
{x:39.8875,y:-83.936},
{x:39.8676,y:-84.1419},
{x:39.8533,y:-82.898},
{x:39.7543,y:-94.2133},
{x:28.2801,y:-82.6956},
{x:39.2691,y:-84.3707},
{x:44.8551,y:-93.4252},
{x:32.9969,y:-93.4637},
{x:40.5901,y:-81.5256},
{x:40.2693,y:-81.8719},
{x:37.0707,y:-93.2234},
{x:40.8549,y:-73.1883},
{x:43.1347,y:-77.494},
{x:45.4869,y:-122.7871},
{x:34.977,y:-91.5033},
{x:37.0021,y:-88.3194},
{x:42.4741,y:-122.8115},
{x:45.1493,y:-122.8771},
{x:34.049,y:-118.2559},
{x:34.0886,y:-117.9599},
{x:33.159,y:-97.1048},
{x:33.8353,y:-117.9142},
{x:26.1899,y:-97.6785},
{x:25.9345,y:-97.4862},
{x:45.5234,y:-122.6949},
{x:40.8369,y:-111.9322},
{x:34.6359,y:-82.7836},
{x:34.8235,y:-82.9165},
{x:28.4946,y:-82.5386},
{x:28.4579,y:-82.6244},
{x:39.9454,y:-104.9388},
{x:40.6896,y:-73.8422},
{x:35.3541,y:-83.2558},
{x:40.25,y:-111.6428},
{x:37.9941,y:-122.1017},
{x:26.2254,y:-98.3243},
{x:34.8858,y:-82.1522},
{x:42.0291,y:-88.2768},
{x:27.9599,y:-82.4023},
{x:40.6153,y:-79.1642},
{x:40.0484,y:-76.3921},
{x:44.9398,y:-93.1775},
{x:33.8719,y:-80.3533},
{x:33.0301,y:-94.7249},
{x:37.7924,y:-122.4},
{x:32.2512,y:-110.8377},
{x:42.6169,y:-82.5498},
{x:41.9966,y:-87.8318},
{x:38.4711,y:-90.3043},
{x:37.6624,y:-120.9963},
{x:38.9915,y:-104.804},
{x:33.5971,y:-117.242},
{x:42.4739,y:-83.2208},
{x:36.8206,y:-76.2245},
{x:32.8075,y:-96.8726},
{x:39.1373,y:-76.5974},
{x:33.5705,y:-81.9397},
{x:32.8038,y:-80.1182},
{x:38.5434,y:-90.194},
{x:41.7682,y:-72.5704},
{x:42.0078,y:-87.9388},
{x:39.8223,y:-85.9158},
{x:30.3461,y:-97.9663},
{x:36.1048,y:-87.1954},
{x:34.5136,y:-87.717},
{x:44.4096,y:-85.3985},
{x:41.2583,y:-95.9373},
{x:41.2094,y:-112.0253},
{x:29.7324,y:-81.8825},
{x:34.0893,y:-117.7184},
{x:36.2982,y:-119.8066},
{x:44.912,y:-91.9351},
{x:34.2497,y:-118.5882},
{x:33.1981,y:-80.0162},
{x:32.7578,y:-96.9956},
{x:41.8892,y:-87.907},
{x:38.6533,y:-92.7859},
{x:44.1489,y:-72.6559},
{x:34.0847,y:-118.3259},
{x:42.2561,y:-83.2546},
{x:41.9978,y:-87.6992},
{x:34.0458,y:-117.9493},
{x:18.2173,y:-66.0435},
{x:40.7131,y:-73.7812},
{x:32.8978,y:-97.2371},
{x:41.4126,y:-81.7354},
{x:38.6601,y:-77.3682},
{x:34.6085,y:-98.4316},
{x:44.9628,y:-122.984},
{x:29.9367,y:-90.0691},
{x:35.227,y:-89.8475},
{x:47.6133,y:-122.3419},
{x:17.9764,y:-66.1203},
{x:18.1411,y:-65.8286},
{x:38.8779,y:-86.0422},
{x:33.7441,y:-85.2955},
{x:33.6076,y:-117.6911},
{x:40.3021,y:-74.6822},
{x:29.7042,y:-95.5143},
{x:38.4384,y:-121.4183},
{x:38.5924,y:-89.9773},
{x:34.782,y:-98.3004},
{x:35.1584,y:-89.726},
{x:47.6227,y:-122.1321},
{x:33.2931,y:-111.8237},
{x:39.4941,y:-119.7886},
{x:40.9283,y:-100.1662},
{x:35.5763,y:-90.7229},
{x:34.7107,y:-86.6097},
{x:29.049,y:-95.5707},
{x:41.0162,y:-92.8091},
{x:30.2079,y:-95.4599},
{x:42.7189,y:-78.0057},
{x:35.6369,y:-88.7892},
{x:36.9514,y:-80.8831},
{x:40.0878,y:-75.3936},
{x:41.8973,y:-87.6284},
{x:40.6903,y:-74.3209},
{x:27.544,y:-82.4737},
{x:37.5413,y:-121.2663},
{x:39.4511,y:-84.2049},
{x:33.563,y:-79.0391},
{x:41.8503,y:-87.7812},
{x:31.7273,y:-84.1717},
{x:40.5779,y:-75.479},
{x:39.2728,y:-76.5332},
{x:29.6117,y:-95.3881},
{x:42.2849,y:-85.6119},
{x:33.9723,y:-117.3479},
{x:40.3994,y:-80.5912},
{x:38.5168,y:-89.9564},
{x:29.5366,y:-95.1519},
{x:42.2271,y:-83.2296},
{x:38.934,y:-76.9528},
{x:42.0068,y:-71.5054},
{x:35.9113,y:-79.047},
{x:41.7364,y:-88.0405},
{x:32.3835,y:-90.0435},
{x:38.5968,y:-121.3441},
{x:35.3227,y:-78.6362},
{x:38.7143,y:-85.4785},
{x:43.0136,y:-78.769},
{x:42.9918,y:-78.8045},
{x:38.7164,y:-104.7},
{x:32.216,y:-80.7036},
{x:34.0308,y:-118.3351},
{x:41.616,y:-93.8687},
{x:38.9419,y:-104.6025},
{x:47.6278,y:-117.224},
{x:46.1542,y:-122.9816},
{x:35.2508,y:-81.6647},
{x:21.3843,y:-158.0048},
{x:40.0364,y:-85.9976},
{x:38.4812,y:-121.5172},
{x:41.8832,y:-87.6254},
{x:26.1608,y:-80.1323},
{x:32.7301,y:-82.7197},
{x:38.8942,y:-76.9503},
{x:40.1685,y:-87.6303},
{x:36.9814,y:-76.4293},
{x:18.4716,y:-67.0213},
{x:45.8155,y:-122.6835},
{x:40.7341,y:-73.0858},
{x:39.1182,y:-76.6329},
{x:40.7096,y:-73.7949},
{x:40.716,y:-99.0856},
{x:38.8951,y:-76.8236},
{x:30.3875,y:-87.0656},
{x:41.1779,y:-85.1335},
{x:43.389,y:-112.1179},
{x:41.1325,y:-85.1973},
{x:40.8885,y:-74.7168},
{x:40.6224,y:-75.3788},
{x:41.0506,y:-85.1371},
{x:45.0473,y:-93.107},
{x:33.7219,y:-84.903},
{x:30.4054,y:-97.674},
{x:42.1364,y:-72.5689},
{x:40.7658,y:-73.3108},
{x:42.8754,y:-89.5338},
{x:39.9135,y:-104.9761},
{x:29.9952,y:-95.7387},
{x:41.4446,y:-72.0174},
{x:38.8542,y:-94.3797},
{x:38.8122,y:-94.4734},
{x:35.4205,y:-97.4922},
{x:41.4203,y:-82.7589},
{x:44.967,y:-93.237},
{x:40.753,y:-73.565},
{x:30.9279,y:-83.2448},
{x:39.0945,y:-84.6138},
{x:42.4207,y:-83.2166},
{x:29.7983,y:-94.9794},
{x:46.3156,y:-117.9896},
{x:32.3271,y:-86.2387},
{x:33.6547,y:-96.6418},
{x:37.5972,y:-84.3184},
{x:37.8176,y:-88.9421},
{x:35.7542,y:-81.2852},
{x:25.8685,y:-80.3782},
{x:25.7616,y:-80.3341},
{x:30.5877,y:-84.589},
{x:37.7804,y:-122.2478},
{x:37.9675,y:-122.0617},
{x:45.4443,y:-122.6275},
{x:39.9726,y:-86.0099},
{x:39.773,y:-86.0354},
{x:42.7418,y:-93.2102},
{x:39.3397,y:-76.4851},
{x:26.439,y:-81.7701},
{x:39.2107,y:-76.9353},
{x:30.6432,y:-96.3523},
{x:47.6205,y:-122.1989},
{x:41.995,y:-70.8447},
{x:37.2096,y:-93.2406},
{x:47.2235,y:-122.4317},
{x:29.8981,y:-90.0629},
{x:41.4597,y:-87.0747},
{x:45.445,y:-122.5792},
{x:41.4798,y:-90.3585},
{x:35.0445,y:-85.3011},
{x:40.7623,y:-73.9633},
{x:33.2906,y:-117.2258},
{x:41.5151,y:-81.5512},
{x:32.6441,y:-97.1855},
{x:44.451,y:-88.0842},
{x:33.7739,y:-84.3679},
{x:37.9763,y:-120.3796},
{x:37.2794,y:-79.1676},
{x:32.3286,y:-90.1946},
{x:28.0541,y:-81.947},
{x:39.1558,y:-78.1874},
{x:19.5048,y:-154.9573},
{x:35.4691,y:-93.4784},
{x:21.3092,y:-157.8627},
{x:40.7639,y:-73.9835},
{x:31.7189,y:-106.2709},
{x:40.7844,y:-73.9523},
{x:39.3721,y:-93.4844},
{x:33.1576,y:-96.9431},
{x:33.0739,y:-96.8733},
{x:32.7169,y:-116.8777},
{x:41.6736,y:-86.0027},
{x:41.7123,y:-85.9717},
{x:41.9548,y:-87.6793},
{x:47.2444,y:-122.2104},
{x:33.8795,y:-83.9374},
{x:30.6825,y:-97.7211},
{x:40.5847,y:-80.04},
{x:33.8062,y:-118.3294},
{x:38.6583,y:-121.5111},
{x:30.3877,y:-95.6933},
{x:30.3164,y:-95.4762},
{x:37.4988,y:-120.8494},
{x:33.8385,y:-84.0701},
{x:37.6155,y:-81.117},
{x:34.8894,y:-82.406},
{x:37.0198,y:-121.5764},
{x:39.4653,y:-87.4485},
{x:40.7046,y:-74.0988},
{x:40.8814,y:-73.8828},
{x:39.8435,y:-105.0539},
{x:39.3031,y:-84.2841},
{x:36.1875,y:-86.4105},
{x:40.5861,y:-74.0921},
{x:42.6471,y:-73.6998},
{x:34.063,y:-118.0429},
{x:38.7909,y:-90.6618},
{x:33.5501,y:-117.1395},
{x:34.6995,y:-89.967},
{x:28.061,y:-82.543},
{x:34.1451,y:-83.957},
{x:30.3312,y:-81.5475},
{x:29.8205,y:-95.4669},
{x:40.764,y:-73.882},
{x:42.1027,y:-72.5571},
{x:40.6447,y:-81.4537},
{x:41.8378,y:-75.8529},
{x:40.5101,y:-88.9582},
{x:42.2671,y:-83.1361},
{x:21.3074,y:-157.861},
{x:38.2363,y:-88.0016},
{x:41.8949,y:-83.4157},
{x:32.3667,y:-95.3265},
{x:33.537,y:-112.0995},
{x:41.5376,y:-87.4458},
{x:33.8324,y:-118.1593},
{x:33.4901,y:-84.1761},
{x:39.0656,y:-84.8949},
{x:42.4512,y:-72.5604},
{x:34.274,y:-118.4478},
{x:37.6947,y:-121.7461},
{x:41.5696,y:-72.6318},
{x:47.6139,y:-122.1932},
{x:47.8,y:-122.3213},
{x:40.9818,y:-76.8839},
{x:42.7351,y:-83.3053},
{x:48.5112,y:-122.2262},
{x:40.4797,y:-104.9257},
{x:41.8601,y:-87.9917},
{x:41.6275,y:-93.6972},
{x:39.2758,y:-76.6438},
{x:38.1713,y:-87.7805},
{x:39.9319,y:-76.9803},
{x:33.8177,y:-117.9938},
{x:39.6789,y:-86.1304},
{x:38.3434,y:-121.9951},
{x:39.8832,y:-76.8647},
{x:47.3613,y:-122.6062},
{x:27.0067,y:-82.1302},
{x:39.6492,y:-79.8997},
{x:44.803,y:-93.1427},
{x:40.9309,y:-72.6756},
{x:42.3413,y:-83.1964},
{x:40.4494,y:-74.3012},
{x:38.1747,y:-83.4361},
{x:26.1193,y:-80.1389},
{x:39.8436,y:-77.1997},
{x:42.5325,y:-76.6403},
{x:31.3346,y:-89.3447},
{x:32.7087,y:-96.0084},
{x:41.2991,y:-96.0231},
{x:38.7141,y:-90.4393},
{x:41.8569,y:-71.3992},
{x:40.7276,y:-73.8929},
{x:40.0505,y:-76.2893},
{x:30.9507,y:-87.1517},
{x:39.0223,y:-84.5853},
{x:44.0034,y:-124.1012},
{x:36.0078,y:-78.9225},
{x:32.5197,y:-92.3638},
{x:34.7448,y:-92.4069},
{x:33.504,y:-81.7098},
{x:35.3356,y:-99.5945},
{x:33.1185,y:-83.2641},
{x:34.1665,y:-117.2853},
{x:32.7798,y:-96.8076},
{x:38.3768,y:-88.0586},
{x:26.2993,y:-98.1447},
{x:32.2724,y:-90.1357},
{x:36.1417,y:-85.5009},
{x:37.6445,y:-122.0623},
{x:34.0114,y:-118.3094},
{x:21.4569,y:-158.016},
{x:46.8666,y:-122.2634},
{x:47.5966,y:-120.6599},
{x:40.7787,y:-73.9564},
{x:36.7345,y:-119.7859},
{x:34.2346,y:-119.1793},
{x:38.9495,y:-90.1893},
{x:41.4329,y:-75.6597},
{x:32.2715,y:-97.1717},
{x:36.9795,y:-85.6131},
{x:34.1108,y:-84.2191},
{x:40.383,y:-104.7154},
{x:40.3948,y:-104.754},
{x:26.6507,y:-81.9739},
{x:42.0765,y:-75.64},
{x:32.3531,y:-89.647},
{x:38.496,y:-121.3915},
{x:32.3092,y:-96.0095},
{x:34.0782,y:-118.1127},
{x:33.9022,y:-118.3423},
{x:39.1755,y:-76.6481},
{x:27.9131,y:-82.5057},
{x:41.6837,y:-71.506},
{x:36.4717,y:-95.0488},
{x:39.4476,y:-80.1678},
{x:40.0934,y:-86.0711},
{x:42.6329,y:-83.7559},
{x:47.6045,y:-122.3328},
{x:40.8729,y:-73.8781},
{x:42.8598,y:-73.7815},
{x:38.6801,y:-121.2268},
{x:45.6197,y:-122.5333},
{x:43.0593,y:-88.1281},
{x:30.2808,y:-91.9125},
{x:29.9169,y:-94.0194},
{x:43.0494,y:-89.4741},
{x:41.5648,y:-87.8882},
{x:44.1128,y:-88.6943},
{x:38.2005,y:-85.7337},
{x:45.5056,y:-122.494},
{x:38.8116,y:-89.9521},
{x:47.6169,y:-122.3306},
{x:33.9006,y:-88.1353},
{x:28.1111,y:-97.8182},
{x:41.6989,y:-71.1582},
{x:40.6637,y:-80.5861},
{x:34.0764,y:-118.3607},
{x:41.256,y:-73.2188},
{x:35.3465,y:-94.3366},
{x:27.7325,y:-82.7447},
{x:40.7314,y:-74.0647},
{x:32.4482,y:-85.0161},
{x:42.5387,y:-83.4778},
{x:41.1309,y:-85.0103},
{x:42.9776,y:-78.7324},
{x:38.6954,y:-90.429},
{x:40.866,y:-96.7228},
{x:35.0738,y:-85.2678},
{x:44.9132,y:-123.0448},
{x:47.4838,y:-122.2013},
{x:42.2102,y:-83.6193},
{x:42.3009,y:-83.8753},
{x:33.4739,y:-82.1286},
{x:40.8344,y:-83.964},
{x:41.7241,y:-83.5073},
{x:27.6989,y:-80.9014},
{x:30.0859,y:-90.4447},
{x:32.4801,y:-93.7803},
{x:35.6851,y:-81.3738},
{x:42.4687,y:-83.4569},
{x:39.5866,y:-77.0115},
{x:27.2263,y:-98.1439},
{x:40.7459,y:-73.9939},
{x:42.197,y:-83.5431},
{x:40.1918,y:-74.7211},
{x:38.7926,y:-97.6135},
{x:45.5583,y:-122.8659},
{x:35.0529,y:-85.1837},
{x:41.3201,y:-81.3486},
{x:38.8165,y:-90.6995},
{x:36.0604,y:-89.3966},
{x:38.2475,y:-85.6233},
{x:40.7513,y:-73.9708},
{x:41.4145,y:-73.3814},
{x:37.9026,y:-76.8656},
{x:32.1638,y:-110.9388},
{x:32.8325,y:-109.7311},
{x:42.1228,y:-88.05},
{x:34.2281,y:-118.3661},
{x:38.7636,y:-120.5754},
{x:39.2378,y:-119.5898},
{x:39.662,y:-80.8696},
{x:25.7479,y:-80.3864},
{x:39.5624,y:-84.3026},
{x:34.2779,y:-118.8737},
{x:33.9254,y:-116.8867},
{x:38.7115,y:-90.3087},
{x:42.5679,y:-87.9329},
{x:42.4443,y:-83.2415},
{x:36.1452,y:-78.7247},
{x:34.2793,y:-118.7455},
{x:44.0299,y:-88.589},
{x:39.7664,y:-89.668},
{x:41.6457,y:-93.7376},
{x:41.0485,y:-80.6162},
{x:42.362,y:-88.1124},
{x:42.1859,y:-71.3085},
{x:37.5959,y:-122.3849},
{x:40.0223,y:-75.6327},
{x:32.7432,y:-83.6744},
{x:46.8498,y:-96.8511},
{x:40.4545,y:-80.1577},
{x:34.0896,y:-118.0533},
{x:41.9017,y:-70.956},
{x:29.7603,y:-95.3986},
{x:42.2171,y:-83.1945},
{x:34.476,y:-110.0856},
{x:35.017,y:-114.6},
{x:33.305,y:-111.7887},
{x:47.7921,y:-122.2926},
{x:47.6595,y:-117.4238},
{x:40.3082,y:-83.0901},
{x:33.4143,y:-111.5452},
{x:42.7285,y:-71.4478},
{x:40.979,y:-81.5223},
{x:42.034,y:-87.6782},
{x:40.2412,y:-83.3387},
{x:36.6675,y:-87.4405},
{x:29.7698,y:-95.0324},
{x:35.1906,y:-89.8487},
{x:35.9001,y:-84.1251},
{x:37.209,y:-82.2862},
{x:37.016,y:-81.956},
{x:38.2104,y:-90.404},
{x:21.2946,y:-157.8416},
{x:31.7971,y:-106.5105},
{x:34.9723,y:-89.7957},
{x:40.0646,y:-76.9179},
{x:43.0713,y:-89.406},
{x:40.2652,y:-82.9243},
{x:32.0719,y:-81.1998},
{x:32.1886,y:-81.1949},
{x:30.7812,y:-92.1873},
{x:42.2682,y:-84.4041},
{x:33.4612,y:-84.4511},
{x:40.1536,y:-75.8644},
{x:29.5052,y:-98.5857},
{x:38.0205,y:-121.3197},
{x:42.2085,y:-84.5416},
{x:33.8835,y:-84.2188},
{x:31.9863,y:-81.2201},
{x:25.6563,y:-80.4027},
{x:46.8698,y:-113.9859},
{x:37.7765,y:-84.3217},
{x:41.4669,y:-75.6395},
{x:33.8446,y:-117.7752},
{x:18.2345,y:-66.0502},
{x:18.3977,y:-66.0219},
{x:34.3724,y:-88.7012},
{x:35.0857,y:-106.5138},
{x:41.2967,y:-84.5528},
{x:33.8599,y:-117.8869},
{x:33.3887,y:-79.291},
{x:38.2576,y:-78.8215},
{x:27.7134,y:-82.714},
{x:37.9806,y:-122.5642},
{x:43.1058,y:-77.7517},
{x:29.7295,y:-95.5189},
{x:33.9836,y:-118.1014},
{x:38.7717,y:-77.1767},
{x:43.0137,y:-73.847},
{x:40.9236,y:-73.7879},
{x:35.1205,y:-120.6061},
{x:41.8284,y:-72.9018},
{x:29.5566,y:-95.3799},
{x:40.707,y:-73.6949},
{x:33.9308,y:-118.4159},
{x:42.2707,y:-71.809},
{x:41.2798,y:-72.5984},
{x:34.9661,y:-82.0012},
{x:30.4996,y:-97.6144},
{x:34.0621,y:-118.0193},
{x:32.9314,y:-96.8202},
{x:34.0625,y:-118.3391},
{x:34.5857,y:-118.1478},
{x:41.4383,y:-97.353},
{x:39.4977,y:-121.5706},
{x:29.8325,y:-95.559},
{x:41.7878,y:-75.6841},
{x:43.1902,y:-77.5055},
{x:40.3005,y:-76.9295},
{x:34.1777,y:-84.5512},
{x:40.7691,y:-73.5281},
{x:29.6924,y:-96.7696},
{x:39.3937,y:-104.7543},
{x:37.6555,y:-122.0488},
{x:31.8014,y:-97.0999},
{x:39.5992,y:-82.9318},
{x:35.2917,y:-91.3568},
{x:43.1587,y:-76.3295},
{x:41.9511,y:-71.442},
{x:33.7072,y:-117.807},
{x:36.1004,y:-115.2092},
{x:36.0108,y:-115.1371},
{x:36.3004,y:-115.281},
{x:34.2584,y:-92.0975},
{x:35.4424,y:-119.2515},
{x:39.5943,y:-104.9266},
{x:42.308,y:-83.4589},
{x:42.0635,y:-87.9365},
{x:38.087,y:-97.8957},
{x:37.6632,y:-97.2259},
{x:37.7357,y:-89.1894},
{x:39.9795,y:-86.0719},
{x:35.3637,y:-81.9492},
{x:37.7761,y:-122.2246},
{x:30.1649,y:-97.6935},
{x:41.1371,y:-81.6429},
{x:42.3849,y:-88.3416},
{x:41.1464,y:-73.9896},
{x:42.914,y:-78.7628},
{x:34.1104,y:-118.1912},
{x:42.5007,y:-83.2398},
{x:35.7011,y:-80.8879},
{x:29.9984,y:-92.2849},
{x:33.8898,y:-117.3216},
{x:40.4492,y:-109.5515},
{x:32.8434,y:-97.1329},
{x:39.254,y:-87.3903},
{x:38.9555,y:-95.306},
{x:38.8372,y:-94.7783},
{x:42.2671,y:-83.3885},
{x:36.2251,y:-95.976},
{x:38.2359,y:-122.6224},
{x:37.6728,y:-122.0852},
{x:37.9535,y:-121.9549},
{x:40.6978,y:-96.7045},
{x:44.7218,y:-73.723},
{x:44.9707,y:-93.4408},
{x:43.9334,y:-83.2819},
{x:26.3856,y:-98.141},
{x:35.4647,y:-97.7227},
{x:39.1923,y:-76.6142},
{x:37.1799,y:-93.2255},
{x:33.8259,y:-117.941},
{x:39.3636,y:-77.1571},
{x:41.6399,y:-70.8859},
{x:48.4141,y:-122.3126},
{x:41.9616,y:-87.6754},
{x:38.6536,y:-76.8784},
{x:38.4965,y:-121.4567},
{x:39.0448,y:-76.9528},
{x:34.012,y:-118.4171},
{x:42.8908,y:-78.8776},
{x:34.5067,y:-117.3297},
{x:35.1378,y:-97.6585},
{x:33.9759,y:-118.3584},
{x:35.0587,y:-106.6352},
{x:41.4347,y:-91.0822},
{x:33.6368,y:-117.5994},
{x:41.3645,y:-82.1592},
{x:42.022,y:-87.9588},
{x:41.8676,y:-87.7063},
{x:42.3385,y:-71.1073},
{x:29.4701,y:-98.4921},
{x:43.1703,y:-111.0174},
{x:37.5287,y:-77.3594},
{x:34.048,y:-118.2398},
{x:35.7823,y:-80.8595},
{x:38.8197,y:-90.5147},
{x:39.9241,y:-79.7273},
{x:35.3397,y:-78.5569},
{x:47.6828,y:-122.2908},
{x:47.6645,y:-122.0992},
{x:37.9947,y:-122.2861},
{x:37.8408,y:-122.2913},
{x:36.116,y:-115.2963},
{x:36.174,y:-115.0833},
{x:40.4564,y:-79.9369},
{x:38.9786,y:-94.8525},
{x:38.8832,y:-94.6692},
{x:43.4047,y:-85.7998},
{x:41.9361,y:-87.727},
{x:41.9331,y:-85.6511},
{x:45.445,y:-122.7263},
{x:40.5865,y:-111.8926},
{x:38.3236,y:-82.2212},
{x:41.7364,y:-86.3468},
{x:18.0063,y:-66.592},
{x:17.9737,y:-66.1162},
{x:39.3386,y:-76.5054},
{x:17.983,y:-66.291},
{x:42.7153,y:-84.6021},
{x:35.1954,y:-101.8171},
{x:41.715,y:-111.8355},
{x:30.1896,y:-82.6474},
{x:35.5533,y:-87.1992},
{x:37.5426,y:-122.3027},
{x:30.0958,y:-90.9282},
{x:45.5326,y:-92.0215},
{x:39.0331,y:-94.3564},
{x:39.0359,y:-94.4105},
{x:37.087,y:-94.4775},
{x:35.956,y:-85.032},
{x:45.0627,y:-83.4712},
{x:35.9414,y:-78.5593},
{x:47.1124,y:-88.5853},
{x:35.0627,y:-89.9046},
{x:31.9984,y:-106.6058},
{x:27.9604,y:-82.2771},
{x:39.1607,y:-86.5531},
{x:37.0702,y:-84.622},
{x:18.215,y:-67.1492},
{x:42.8122,y:-83.7067},
{x:35.1927,y:-101.8839},
{x:40.7018,y:-73.8087},
{x:41.9256,y:-83.3638},
{x:37.7764,y:-122.2765},
{x:25.8109,y:-80.3827},
{x:36.8091,y:-119.8091},
{x:39.7614,y:-86.1575},
{x:41.1815,y:-81.5083},
{x:40.5411,y:-79.8015},
{x:39.824,y:-86.2705},
{x:38.043,y:-87.3688},
{x:40.2735,y:-74.5381},
{x:45.8029,y:-88.0693},
{x:42.5697,y:-82.913},
{x:39.6745,y:-104.8315},
{x:21.2853,y:-157.838},
{x:43.0035,y:-78.8585},
{x:44.0593,y:-92.4538},
{x:41.1452,y:-100.7616},
{x:41.6048,y:-87.1718},
{x:37.0383,y:-76.4068},
{x:35.9681,y:-86.4953},
{x:40.7176,y:-73.4614},
{x:28.4926,y:-82.5984},
{x:41.2353,y:-96.1918},
{x:37.1941,y:-93.2618},
{x:33.5386,y:-112.1829},
{x:32.8755,y:-97.4265},
{x:32.9097,y:-97.5441},
{x:42.4361,y:-123.2991},
{x:33.3979,y:-84.7662},
{x:40.0354,y:-74.5795},
{x:37.0064,y:-94.55},
{x:34.1931,y:-84.507},
{x:33.8744,y:-84.5288},
{x:30.4247,y:-91.1361},
{x:41.2034,y:-77.2635},
{x:33.7722,y:-118.0071},
{x:41.7913,y:-87.5897},
{x:42.0476,y:-87.9613},
{x:40.1615,y:-122.227},
{x:40.5866,y:-122.3995},
{x:38.9528,y:-81.7641},
{x:33.6557,y:-111.9293},
{x:38.487,y:-90.3566},
{x:33.367,y:-111.4756},
{x:40.5896,y:-74.1669},
{x:39.6413,y:-84.2241},
{x:33.5648,y:-81.7438},
{x:44.4984,y:-73.1277},
{x:44.9755,y:-93.2747},
{x:33.2178,y:-111.7899},
{x:33.4803,y:-112.2531},
{x:33.6085,y:-112.2381},
{x:41.0237,y:-80.7058},
{x:41.9378,y:-88.0007},
{x:42.0475,y:-88.2532},
{x:35.2256,y:-82.7387},
{x:44.6822,y:-93.1761},
{x:34.0355,y:-117.0691},
{x:39.9537,y:-84.3249},
{x:39.7219,y:-84.1227},
{x:38.7717,y:-90.5817},
{x:41.1212,y:-75.3727},
{x:39.7701,y:-82.1028},
{x:39.1112,y:-108.5414},
{x:30.1753,y:-93.2498},
{x:40.7409,y:-73.9836},
{x:18.4223,y:-66.11},
{x:36.1675,y:-86.7807},
{x:33.3488,y:-111.875},
{x:42.5742,y:-70.8747},
{x:40.6245,y:-82.5106},
{x:42.3572,y:-90.005},
{x:40.9754,y:-78.525},
{x:42.9339,y:-88.0486},
{x:26.6132,y:-80.0572},
{x:33.2087,y:-82.3865},
{x:33.0061,y:-82.3967},
{x:46.9251,y:-92.9168},
{x:41.1414,y:-81.3576},
{x:40.8426,y:-79.9165},
{x:39.4522,y:-74.6527},
{x:34.9137,y:-120.4346},
{x:41.0856,y:-81.517},
{x:39.8921,y:-89.6051},
{x:33.7106,y:-112.2025},
{x:34.7077,y:-86.7415},
{x:34.019,y:-118.1514},
{x:40.0371,y:-76.2706},
{x:33.494,y:-112.3376},
{x:33.5513,y:-112.185},
{x:37.1261,y:-120.2564},
{x:37.3608,y:-120.5954},
{x:29.546,y:-98.5844},
{x:42.1383,y:-87.8489},
{x:33.6763,y:-86.4071},
{x:41.7329,y:-71.4088},
{x:30.3309,y:-95.2861},
{x:30.3509,y:-81.5953},
{x:34.9387,y:-80.7509},
{x:41.2093,y:-75.9698},
{x:40.8614,y:-74.4268},
{x:42.3793,y:-87.9038},
{x:37.0499,y:-76.3922},
{x:28.4129,y:-80.706},
{x:44.8468,y:-92.9046},
{x:36.1735,y:-79.9902},
{x:47.8482,y:-92.6851},
{x:35.9569,y:-86.813},
{x:35.1646,y:-79.3845},
{x:45.7912,y:-92.3825},
{x:36.9,y:-93.5743},
{x:46.7272,y:-92.2178},
{x:34.0938,y:-80.9245},
{x:38.4885,y:-87.2847},
{x:44.8199,y:-93.0482},
{x:41.8403,y:-87.7049},
{x:34.7582,y:-92.3039},
{x:27.9683,y:-82.7538},
{x:27.8741,y:-82.7297},
{x:39.0836,y:-76.5918},
{x:39.3468,y:-84.5409},
{x:38.2934,y:-76.5038},
{x:40.8524,y:-73.8273},
{x:35.2091,y:-90.2051},
{x:41.7236,y:-81.2441},
{x:39.3199,y:-84.4965},
{x:33.7999,y:-112.1261},
{x:33.0022,y:-96.7672},
{x:42.8725,y:-87.943},
{x:39.5101,y:-84.7419},
{x:39.5817,y:-84.3193},
{x:40.7377,y:-96.6999},
{x:40.6431,y:-74.4063},
{x:40.7456,y:-73.9041},
{x:32.3084,y:-110.891},
{x:39.4999,y:-84.3561},
{x:39.8136,y:-79.0259},
{x:44.6661,y:-93.6385},
{x:25.7782,y:-80.29},
{x:41.2009,y:-73.119},
{x:39.4181,y:-84.5865},
{x:39.9614,y:-89.7128},
{x:39.785,y:-104.7683},
{x:34.0436,y:-118.4219},
{x:39.3962,y:-84.5503},
{x:40.3818,y:-80.009},
{x:33.7115,y:-117.1905},
{x:35.1837,y:-97.4991},
{x:43.206,y:-86.1986},
{x:39.5605,y:-84.2575},
{x:32.1316,y:-110.935},
{x:42.1744,y:-88.3695},
{x:40.8848,y:-73.2559},
{x:39.527,y:-84.384},
{x:37.983,y:-122.0681},
{x:41.7233,y:-72.7623},
{x:32.9378,y:-96.9247},
{x:35.4735,y:-80.6103},
{x:33.5165,y:-88.4145},
{x:40.4423,y:-79.2359},
{x:42.017,y:-71.4713},
{x:35.3333,y:-81.8978},
{x:34.0729,y:-117.5586},
{x:33.9981,y:-117.485},
{x:41.745,y:-88.2037},
{x:30.0221,y:-90.2392},
{x:40.742,y:-73.6405},
{x:33.6186,y:-111.8977},
{x:39.2838,y:-76.6372},
{x:41.7723,y:-88.0371},
{x:44.0813,y:-70.2626},
{x:42.8118,y:-78.7216},
{x:36.0592,y:-91.8973},
{x:33.588,y:-117.7214},
{x:29.6518,y:-95.2077},
{x:38.94,y:-94.6657},
{x:42.9035,y:-73.688},
{x:28.9665,y:-95.3717},
{x:38.9363,y:-77.0592},
{x:29.0105,y:-81.0696},
{x:28.7875,y:-81.2119},
{x:36.0173,y:-83.4134},
{x:30.3851,y:-87.0961},
{x:40.8332,y:-83.2471},
{x:41.4926,y:-87.5107},
{x:30.3909,y:-89.0461},
{x:40.8154,y:-96.6371},
{x:42.4996,y:-76.2992},
{x:29.9906,y:-91.0299},
{x:41.8959,y:-87.6552},
{x:42.3498,y:-95.494},
{x:41.245,y:-75.8838},
{x:41.652,y:-83.7029},
{x:45.0066,y:-123.0008},
{x:38.5004,y:-90.3324},
{x:43.4144,y:-83.8972},
{x:43.0572,y:-89.3988},
{x:39.5584,y:-119.4898},
{x:45.5174,y:-122.6759},
{x:45.0822,y:-87.6598},
{x:32.9907,y:-95.9354},
{x:32.3005,y:-90.1853},
{x:41.4479,y:-72.8042},
{x:41.0339,y:-77.5157},
{x:44.942,y:-72.1951},
{x:34.4597,y:-118.5342},
{x:33.3739,y:-91.0393},
{x:42.4276,y:-89.3322},
{x:30.105,y:-98.4189},
{x:36.73,y:-84.4275},
{x:36.8814,y:-89.5695},
{x:28.4391,y:-81.3361},
{x:39.7778,y:-93.0721},
{x:38.6267,y:-90.4823},
{x:36.1691,y:-115.1386},
{x:41.5286,y:-73.084},
{x:31.6977,y:-106.2124},
{x:43.3941,y:-84.6664},
{x:39.2865,y:-84.4852},
{x:42.2496,y:-71.7639},
{x:42.623,y:-71.2691},
{x:34.1417,y:-118.3882},
{x:40.7843,y:-111.9379},
{x:33.2236,y:-117.3959},
{x:42.1385,y:-88.0209},
{x:38.4055,y:-91.3232},
{x:29.4924,y:-98.7021},
{x:33.7871,y:-117.942},
{x:37.8556,y:-81.9935},
{x:35.448,y:-78.4201},
{x:29.9975,y:-95.1944},
{x:30.135,y:-95.6542},
{x:34.9758,y:-81.0072},
{x:32.7981,y:-117.1513},
{x:39.0795,y:-76.6867},
{x:32.2686,y:-86.3562},
{x:30.3706,y:-89.1264},
{x:35.7672,y:-78.5795},
{x:40.5981,y:-86.8688},
{x:36.0815,y:-115.0534},
{x:40.7321,y:-73.1877},
{x:38.554,y:-90.0385},
{x:41.0659,y:-81.5699},
{x:42.0408,y:-87.77},
{x:30.3198,y:-94.9973},
{x:33.1733,y:-87.4786},
{x:30.452,y:-90.9572},
{x:41.7668,y:-72.6746},
{x:43.0196,y:-78.8799},
{x:40.6483,y:-83.6071},
{x:36.2092,y:-86.8392},
{x:35.0963,y:-89.7293},
{x:31.268,y:-92.4715},
{x:34.1309,y:-84.4989},
{x:36.214,y:-86.3288},
{x:42.8473,y:-78.832},
{x:42.4981,y:-83.4204},
{x:33.3762,y:-82.0718},
{x:44.0768,y:-71.1387},
{x:35.6521,y:-97.7459},
{x:38.8341,y:-76.9592},
{x:34.0415,y:-117.4866},
{x:25.8384,y:-80.1839},
{x:29.1299,y:-81.005},
{x:39.0378,y:-89.9516},
{x:39.32,y:-89.5765},
{x:25.7472,y:-80.3505},
{x:38.6658,y:-121.3074},
{x:38.276,y:-122.6681},
{x:26.1178,y:-80.3917},
{x:37.4769,y:-122.2219},
{x:43.0596,y:-76.9944},
{x:32.236,y:-84.3092},
{x:40.6222,y:-79.7239},
{x:38.3257,y:-85.7112},
{x:39.5028,y:-82.0953},
{x:27.2687,y:-82.4538},
{x:30.3574,y:-81.6543},
{x:41.5642,y:-88.2579},
{x:31.7923,y:-106.3733},
{x:38.8295,y:-77.1178},
{x:41.1379,y:-81.821},
{x:36.1201,y:-111.2295},
{x:37.9389,y:-122.0242},
{x:37.7236,y:-121.9429},
{x:27.9504,y:-82.4554},
{x:40.1192,y:-104.9403},
{x:42.4445,y:-83.6518},
{x:34.0905,y:-117.6688},
{x:39.5568,y:-76.2727},
{x:40.3064,y:-99.8974},
{x:44.9468,y:-93.093},
{x:32.5487,y:-83.5974},
{x:47.1341,y:-122.2936},
{x:47.2739,y:-122.515},
{x:41.7515,y:-71.2136},
{x:18.4296,y:-66.1486},
{x:18.3975,y:-66.1486},
{x:41.8938,y:-72.4607},
{x:33.747,y:-117.8699},
{x:25.7015,y:-80.3352},
{x:25.8615,y:-80.3157},
{x:25.7259,y:-80.2629},
{x:42.507,y:-90.721},
{x:40.7607,y:-73.9693},
{x:21.3251,y:-157.889},
{x:47.6159,y:-122.3524},
{x:38.8125,y:-90.347},
{x:40.1295,y:-86.2307},
{x:30.3134,y:-97.7059},
{x:44.9844,y:-93.3779},
{x:40.5512,y:-105.0372},
{x:38.7903,y:-90.2844},
{x:41.5787,y:-87.2401},
{x:29.4827,y:-98.5257},
{x:34.8845,y:-92.1067},
{x:37.0387,y:-76.312},
{x:38.7055,y:-121.3269},
{x:39.1267,y:-121.5746},
{x:30.24,y:-90.8695},
{x:29.9845,y:-95.658},
{x:38.2847,y:-95.249},
{x:30.2806,y:-95.4604},
{x:33.9395,y:-117.9423},
{x:34.5563,y:-102.3124},
{x:39.7813,y:-104.8456},
{x:18.2913,y:-66.8847},
{x:30.4288,y:-97.7604},
{x:41.4678,y:-73.4831},
{x:42.9582,y:-78.8699},
{x:37.3551,y:-85.327},
{x:38.6102,y:-89.5374},
{x:40.1901,y:-77.1929},
{x:33.1185,y:-87.5529},
{x:36.1725,y:-86.6013},
{x:41.6676,y:-74.6733},
{x:40.302,y:-79.5266},
{x:30.4563,y:-88.9007},
{x:39.8018,y:-84.219},
{x:31.9358,y:-92.6528},
{x:47.0946,y:-122.6218},
{x:33.9174,y:-118.0702},
{x:40.551,y:-74.432},
{x:33.7762,y:-117.8691},
{x:34.0326,y:-117.7488},
{x:46.6958,y:-68.0121},
{x:29.5628,y:-98.4793},
{x:38.8848,y:-76.9005},
{x:33.9544,y:-118.1124},
{x:39.33,y:-75.6249},
{x:32.3666,y:-86.0518},
{x:33.4401,y:-84.1194},
{x:43.0823,y:-75.3161},
{x:42.3456,y:-83.1573},
{x:35.1365,y:-90.0095},
{x:38.632,y:-121.4762},
{x:38.6386,y:-121.3293},
{x:36.8062,y:-76.0302},
{x:42.9224,y:-87.9497},
{x:41.4267,y:-74.4273},
{x:46.271,y:-119.1852},
{x:41.5513,y:-87.428},
{x:17.9989,y:-66.5988},
{x:18.3757,y:-65.9289},
{x:18.3433,y:-65.6741},
{x:18.3816,y:-66.193},
{x:26.1542,y:-81.7217},
{x:36.3587,y:-94.2758},
{x:41.7483,y:-87.7409},
{x:41.997,y:-87.787},
{x:34.2863,y:-118.5008},
{x:41.871,y:-87.6738},
{x:41.8719,y:-87.6475},
{x:41.9758,y:-87.7128},
{x:38.0893,y:-88.5355},
{x:38.958,y:-76.8684},
{x:40.8644,y:-73.9267},
{x:37.7366,y:-87.0916},
{x:33.5768,y:-83.9642},
{x:18.2504,y:-65.971},
{x:41.7052,y:-87.7207},
{x:42.7211,y:-83.3766},
{x:40.901,y:-81.1179},
{x:41.1335,y:-85.1027},
{x:41.1599,y:-104.7967},
{x:27.5055,y:-99.4807},
{x:36.28,y:-80.1447},
{x:40.312,y:-76.8025},
{x:42.268,y:-72.0852},
{x:38.5781,y:-90.2301},
{x:35.5193,y:-78.5636},
{x:39.3515,y:-83.383},
{x:35.0184,y:-82.4979},
{x:34.8986,y:-76.9088},
{x:40.9858,y:-74.0639},
{x:31.0727,y:-97.7072},
{x:42.6805,y:-74.4734},
{x:42.2701,y:-88.0046},
{x:18.3965,y:-67.109},
{x:34.0017,y:-84.0798},
{x:38.5917,y:-89.9388},
{x:47.6944,y:-122.0418},
{x:40.1384,y:-87.3984},
{x:39.4677,y:-79.6678},
{x:35.2923,y:-93.726},
{x:44.9647,y:-93.4433},
{x:42.6074,y:-113.6804},
{x:36.0951,y:-94.1601},
{x:43.1054,y:-93.6026},
{x:32.318,y:-84.5153},
{x:30.2678,y:-81.5576},
{x:30.7819,y:-86.5609},
{x:34.0569,y:-117.6705},
{x:41.159,y:-111.9402},
{x:30.6193,y:-81.6524},
{x:36.3966,y:-84.281},
{x:41.774,y:-88.4388},
{x:41.7601,y:-70.6666},
{x:33.5427,y:-117.691},
{x:41.7871,y:-87.7036},
{x:34.2433,y:-118.4674},
{x:39.958,y:-85.9431},
{x:39.7663,y:-86.1593},
{x:37.6838,y:-122.4627},
{x:41.5579,y:-72.1142},
{x:37.1904,y:-86.2625},
{x:41.8241,y:-71.344},
{x:41.435,y:-81.5165},
{x:43.9559,y:-86.4016},
{x:47.324,y:-122.3539},
{x:29.5764,y:-90.7268},
{x:39.2198,y:-84.5855},
{x:30.0515,y:-95.437},
{x:45.4812,y:-122.8581},
{x:18.4969,y:-67.1005},
{x:18.4478,y:-67.1495},
{x:39.8517,y:-104.9835},
{x:39.994,y:-76.8394},
{x:34.1174,y:-118.1508},
{x:38.0025,y:-87.493},
{x:36.9959,y:-86.4296},
{x:38.3431,y:-120.7694},
{x:38.7414,y:-121.2257},
{x:42.0266,y:-88.3122},
{x:38.52,y:-89.9292},
{x:33.935,y:-85.5977},
{x:38.7066,y:-121.3138},
{x:26.6851,y:-81.9033},
{x:38.6704,y:-121.7269},
{x:39.4485,y:-108.0562},
{x:38.7123,y:-121.3636},
{x:33.7329,y:-85.0296},
{x:34.1763,y:-79.8297},
{x:32.1088,y:-81.1571},
{x:31.2045,y:-87.3108},
{x:29.8048,y:-95.1819},
{x:29.7918,y:-95.2017},
{x:42.15,y:-71.502},
{x:40.8889,y:-74.0403},
{x:41.6944,y:-88.0693},
{x:42.0604,y:-83.3804},
{x:30.3648,y:-90.0605},
{x:36.1155,y:-97.0696},
{x:38.6776,y:-121.1993},
{x:42.5928,y:-83.6045},
{x:42.8293,y:-78.7831},
{x:42.141,y:-79.9244},
{x:45.4508,y:-122.7801},
{x:31.7901,y:-106.3326},
{x:32.3307,y:-81.3924},
{x:42.65,y:-82.8507},
{x:34.2529,y:-84.3574},
{x:34.1087,y:-83.8764},
{x:39.8195,y:-84.2814},
{x:41.6828,y:-81.3416},
{x:25.7861,y:-80.1411},
{x:27.963,y:-82.506},
{x:42.1368,y:-83.2669},
{x:38.9348,y:-119.7389},
{x:41.9986,y:-76.5874},
{x:36.2229,y:-83.0367},
{x:38.4886,y:-90.3825},
{x:40.2372,y:-74.7151},
{x:42.2351,y:-83.5798},
{x:42.4633,y:-83.0304},
{x:43.4502,y:-83.6961},
{x:40.0961,y:-80.7234},
{x:39.79,y:-88.8709},
{x:40.6518,y:-74.2228},
{x:39.5766,y:-119.7814},
{x:41.8559,y:-87.6273},
{x:42.301,y:-71.434},
{x:36.1303,y:-115.206},
{x:35.1634,y:-114.5724},
{x:36.0086,y:-114.9921},
{x:40.7219,y:-122.9274},
{x:35.3645,y:-80.7115},
{x:38.7691,y:-121.3309},
{x:38.7913,y:-90.5647},
{x:38.8815,y:-94.8353},
{x:38.3116,y:-88.9645},
{x:38.6748,y:-90.0167},
{x:32.9129,y:-80.1093},
{x:32.9966,y:-80.1829},
{x:34.0715,y:-117.3699},
{x:26.0632,y:-80.2827},
{x:39.7759,y:-121.5916},
{x:38.5821,y:-121.5005},
{x:41.8768,y:-87.6293},
{x:35.3698,y:-80.664},
{x:35.1198,y:-77.0078},
{x:34.2192,y:-119.0783},
{x:32.8954,y:-97.2901},
{x:36.0079,y:-83.9734},
{x:36.0541,y:-83.9888},
{x:36.0057,y:-84.0197},
{x:41.5014,y:-81.5207},
{x:44.0742,y:-87.7194},
{x:29.9641,y:-90.1119},
{x:37.3838,y:-122.0806},
{x:40.6079,y:-87.3094},
{x:39.103,y:-87.4059},
{x:47.7444,y:-117.406},
{x:41.485,y:-81.9215},
{x:29.7546,y:-95.4993},
{x:42.8281,y:-78.7553},
{x:45.8085,y:-88.0474},
{x:42.36,y:-87.831},
{x:39.6151,y:-86.085},
{x:42.3411,y:-71.1211},
{x:31.8296,y:-89.4334},
{x:29.9882,y:-90.131},
{x:26.6204,y:-80.2064},
{x:30.0036,y:-90.1687},
{x:30.3738,y:-91.2529},
{x:38.6012,y:-91.2237},
{x:42.5906,y:-82.9716},
{x:39.7699,y:-76.6695},
{x:28.1275,y:-82.5743},
{x:34.9619,y:-89.9326},
{x:26.1212,y:-80.2441},
{x:41.6425,y:-81.4513},
{x:40.6921,y:-73.8103},
{x:41.4849,y:-81.8033},
{x:39.6102,y:-105.1447},
{x:34.1329,y:-117.6143},
{x:33.8008,y:-116.3888},
{x:42.3991,y:-94.6388},
{x:34.1875,y:-118.4167},
{x:40.7257,y:-111.9774},
{x:34.0255,y:-118.2534},
{x:35.0441,y:-80.6071},
{x:40.6671,y:-73.9567},
{x:43.0837,y:-88.7619},
{x:33.6125,y:-117.93},
{x:32.5034,y:-80.2972},
{x:35.815,y:-78.6259},
{x:41.7036,y:-93.5852},
{x:18.1203,y:-66.1488},
{x:18.1792,y:-66.1547},
{x:37.0965,y:-113.5674},
{x:39.1847,y:-77.1892},
{x:41.6523,y:-76.8523},
{x:40.4727,y:-79.7128},
{x:39.1548,y:-77.1654},
{x:39.1604,y:-78.1546},
{x:40.799,y:-77.8554},
{x:39.8613,y:-75.6872},
{x:40.6936,y:-84.6466},
{x:18.0054,y:-66.619},
{x:36.809,y:-119.6938},
{x:37.6216,y:-79.8207},
{x:40.2297,y:-82.4536},
{x:37.9791,y:-89.0588},
{x:40.5812,y:-76.9811},
{x:28.9809,y:-82.0077},
{x:33.855,y:-84.007},
{x:40.8004,y:-72.8022},
{x:38.6837,y:-121.4549},
{x:47.4233,y:-122.2487},
{x:47.6095,y:-122.3248},
{x:35.2122,y:-80.6903},
{x:44.2984,y:-94.4475},
{x:37.9625,y:-121.7365},
{x:39.7221,y:-75.6618},
{x:39.6543,y:-104.9894},
{x:44.7452,y:-93.1754},
{x:31.8317,y:-81.6161},
{x:33.4964,y:-88.3833},
{x:18.4481,y:-66.0505},
{x:40.8759,y:-74.1363},
{x:41.68,y:-71.261},
{x:35.224,y:-99.8698},
{x:35.5187,y:-98.8709},
{x:33.5815,y:-112.123},
{x:33.6255,y:-112.0981},
{x:33.5643,y:-112.2877},
{x:48.1749,y:-117.065},
{x:41.6844,y:-86.1103},
{x:32.9889,y:-80.0986},
{x:33.4509,y:-112.1363},
{x:41.2655,y:-81.6283},
{x:41.2383,y:-80.743},
{x:42.7275,y:-73.7008},
{x:33.5686,y:-111.886},
{x:41.2228,y:-111.9711},
{x:33.2012,y:-92.6901},
{x:33.1654,y:-87.5183},
{x:46.6098,y:-93.3142},
{x:41.7699,y:-70.956},
{x:34.0633,y:-118.0901},
{x:39.1563,y:-86.4973},
{x:29.7826,y:-95.4758},
{x:41.9781,y:-71.2026},
{x:43.1767,y:-87.9109},
{x:47.4888,y:-122.1647},
{x:45.5349,y:-122.9064},
{x:34.7006,y:-120.4678},
{x:39.4401,y:-105.4504},
{x:30.6415,y:-87.1803},
{x:32.5556,y:-117.0528},
{x:33.3791,y:-111.7224},
{x:34.9314,y:-88.5548},
{x:36.3504,y:-119.4255},
{x:41.7548,y:-87.7416},
{x:36.1027,y:-97.8986},
{x:26.605,y:-81.9775},
{x:41.8682,y:-87.9344},
{x:33.8402,y:-84.5189},
{x:34.0401,y:-118.2926},
{x:43.4472,y:-71.6196},
{x:40.4226,y:-85.6496},
{x:48.0834,y:-121.9772},
{x:43.1964,y:-77.5985},
{x:39.3717,y:-84.3826},
{x:43.0714,y:-73.788},
{x:33.4952,y:-86.8075},
{x:33.3862,y:-86.74},
{x:33.0159,y:-117.1108},
{x:42.6149,y:-97.2689},
{x:40.8783,y:-73.9052},
{x:40.114,y:-85.5712},
{x:36.2107,y:-119.3479},
{x:45.5962,y:-94.1942},
{x:33.8596,y:-118.0484},
{x:43.1027,y:-78.9805},
{x:36.7864,y:-119.7902},
{x:42.0192,y:-87.6896},
{x:40.6507,y:-78.7799},
{x:40.9043,y:-124.0822},
{x:41.5153,y:-90.4383},
{x:31.3365,y:-85.8561},
{x:41.2124,y:-95.946},
{x:41.2067,y:-95.9563},
{x:32.4109,y:-99.4881},
{x:41.6873,y:-88.3389},
{x:30.2656,y:-82.1242},
{x:32.3435,y:-111.0134},
{x:33.6863,y:-116.1821},
{x:40.8112,y:-73.078},
{x:35.0138,y:-81.8067},
{x:38.6502,y:-121.3258},
{x:30.829,y:-88.0909},
{x:38.7253,y:-90.6997},
{x:38.29,y:-122.0337},
{x:38.3194,y:-122.2982},
{x:40.5751,y:-86.1891},
{x:40.7615,y:-73.9848},
{x:33.3786,y:-111.9768},
{x:33.5388,y:-112.0639},
{x:33.599,y:-111.9893},
{x:27.7334,y:-82.6796},
{x:33.4342,y:-112.3045},
{x:38.7702,y:-90.2649},
{x:47.7025,y:-122.0936},
{x:47.5342,y:-121.8396},
{x:29.4803,y:-98.3622},
{x:40.7767,y:-112.0226},
{x:47.6683,y:-122.3009},
{x:36.4196,y:-97.9053},
{x:35.4144,y:-119.0932},
{x:38.844,y:-86.9971},
{x:41.4271,y:-75.6242},
{x:39.277,y:-76.8314},
{x:38.5919,y:-121.5548},
{x:41.0892,y:-112.0429},
{x:43.8119,y:-91.2535},
{x:43.9238,y:-91.2442},
{x:40.6863,y:-111.8575},
{x:29.9672,y:-95.5847},
{x:33.314,y:-85.4842},
{x:30.0392,y:-95.49},
{x:43.0584,y:-76.1496},
{x:41.786,y:-71.3952},
{x:47.6188,y:-122.3483},
{x:47.6092,y:-122.3371},
{x:47.6183,y:-122.3548},
{x:38.6778,y:-121.2955},
{x:32.78,y:-96.804},
{x:34.2152,y:-80.9808},
{x:38.7082,y:-121.2711},
{x:36.1221,y:-89.2623},
{x:36.1962,y:-82.0645},
{x:41.856,y:-71.3535},
{x:40.1046,y:-83.1619},
{x:39.8987,y:-83.0797},
{x:13.5674,y:144.9227},
{x:36.628,y:-90.8268},
{x:32.5235,y:-92.647},
{x:33.9315,y:-118.2121},
{x:39.7093,y:-84.2016},
{x:29.5012,y:-98.6243},
{x:47.1283,y:-122.434},
{x:44.3461,y:-89.0726},
{x:36.2312,y:-95.7589},
{x:32.703,y:-96.5857},
{x:32.6488,y:-96.7063},
{x:42.0526,y:-88.0809},
{x:32.648,y:-96.8828},
{x:32.7232,y:-96.3178},
{x:40.915,y:-74.267},
{x:41.3994,y:-82.3645},
{x:32.4011,y:-93.8064},
{x:33.6691,y:-96.9067},
{x:38.6738,y:-90.4561},
{x:38.3337,y:-87.3539},
{x:30.4008,y:-91.1761},
{x:25.698,y:-80.1636},
{x:47.5692,y:-122.3623},
{x:26.8881,y:-82.3079},
{x:40.8227,y:-73.9535},
{x:31.8807,y:-102.3232},
{x:40.0222,y:-78.5154},
{x:38.9337,y:-95.0947},
{x:41.4645,y:-81.564},
{x:34.1958,y:-119.2206},
{x:26.6506,y:-80.1425},
{x:42.7841,y:-91.1006},
{x:34.9511,y:-88.9051},
{x:47.1954,y:-122.4601},
{x:37.8139,y:-121.2165},
{x:38.6267,y:-90.1969},
{x:32.5551,y:-83.6638},
{x:38.7483,y:-90.4219},
{x:46.9437,y:-122.552},
{x:25.9823,y:-80.2783},
{x:40.7768,y:-74.2393},
{x:37.7524,y:-97.2645},
{x:40.2037,y:-76.215},
{x:36.2875,y:-88.312},
{x:45.8466,y:-88.0506},
{x:42.8389,y:-71.6706},
{x:35.088,y:-106.619},
{x:42.9845,y:-85.6703},
{x:39.9791,y:-76.8},
{x:39.6573,y:-91.7328},
{x:40.5098,y:-112.0244},
{x:33.3359,y:-111.7906},
{x:40.1256,y:-83.9423},
{x:40.1529,y:-84.2088},
{x:39.9213,y:-83.747},
{x:30.1901,y:-85.666},
{x:40.811,y:-73.5013},
{x:38.9117,y:-104.7159},
{x:38.8576,y:-104.7214},
{x:40.7258,y:-75.5332},
{x:21.3215,y:-157.8732},
{x:30.0126,y:-91.7738},
{x:29.9709,y:-91.8353},
{x:40.7427,y:-73.8734},
{x:29.9649,y:-90.2058},
{x:35.2598,y:-120.6485},
{x:47.1611,y:-122.3034},
{x:41.4152,y:-81.6348},
{x:30.3347,y:-95.4762},
{x:35.0842,y:-92.2053},
{x:25.7615,y:-80.304},
{x:39.2047,y:-96.5674},
{x:40.7007,y:-73.5934},
{x:35.2663,y:-119.024},
{x:39.7436,y:-104.9897},
{x:40.7534,y:-73.9959},
{x:30.0017,y:-95.2685},
{x:34.937,y:-120.4366},
{x:30.1976,y:-92.0736},
{x:41.0425,y:-96.3529},
{x:39.3253,y:-88.4498},
{x:34.1547,y:-119.1813},
{x:26.4604,y:-81.8278},
{x:26.6392,y:-82.0379},
{x:39.7647,y:-86.2527},
{x:34.7138,y:-92.399},
{x:45.0585,y:-93.3209},
{x:39.8403,y:-84.4219},
{x:38.5637,y:-121.3798},
{x:46.6901,y:-92.0259},
{x:38.7954,y:-85.8399},
{x:38.4806,y:-121.4083},
{x:41.0445,y:-111.6766},
{x:35.5518,y:-82.5197},
{x:43.4827,y:-89.499},
{x:33.2828,y:-80.5154},
{x:34.2711,y:-119.1717},
{x:37.6946,y:-122.0514},
{x:34.2386,y:-119.1684},
{x:41.9287,y:-87.6822},
{x:41.8499,y:-87.8487},
{x:39.0656,y:-76.7197},
{x:43.1483,y:-93.2669},
{x:40.7335,y:-96.5996},
{x:35.4311,y:-81.5009},
{x:33.9444,y:-80.8717},
{x:38.7299,y:-121.2893},
{x:44.2933,y:-105.5272},
{x:20.8903,y:-156.4651},
{x:28.8127,y:-81.917},
{x:30.8551,y:-83.955},
{x:38.8207,y:-81.7222},
{x:34.0855,y:-84.5224},
{x:36.0039,y:-114.9448},
{x:41.4598,y:-82.0162},
{x:35.4043,y:-94.5165},
{x:41.7958,y:-87.7622},
{x:31.6831,y:-106.3143},
{x:39.7085,y:-86.1438},
{x:44.0464,y:-123.0157},
{x:33.68,y:-116.2953},
{x:32.0872,y:-83.7649},
{x:32.7903,y:-83.6683},
{x:35.1769,y:-89.756},
{x:35.6225,y:-87.8332},
{x:41.4438,y:-82.6917},
{x:38.575,y:-90.4072},
{x:40.1195,y:-87.6361},
{x:35.9041,y:-78.9418},
{x:33.0686,y:-117.2653},
{x:41.956,y:-88.0761},
{x:38.6714,y:-90.4292},
{x:32.884,y:-100.1263},
{x:35.9075,y:-86.384},
{x:35.8118,y:-86.3672},
{x:33.1351,y:-117.1207},
{x:30.482,y:-84.1671},
{x:30.3746,y:-84.2927},
{x:42.2384,y:-72.7179},
{x:29.5485,y:-95.2322},
{x:34.7684,y:-92.3508},
{x:40.451,y:-76.5129},
{x:40.8445,y:-81.7645},
{x:37.6402,y:-122.4226},
{x:28.2738,y:-82.628},
{x:36.2394,y:-115.2485},
{x:38.7298,y:-90.1254},
{x:37.9957,y:-84.5461},
{x:29.6513,y:-95.0318},
{x:40.6326,y:-73.9473},
{x:40.7584,y:-73.9918},
{x:33.449,y:-84.4842},
{x:38.5813,y:-92.202},
{x:28.6984,y:-81.2626},
{x:28.4695,y:-81.2691},
{x:35.926,y:-81.528},
{x:28.3476,y:-81.6324},
{x:35.9278,y:-81.5295},
{x:35.2318,y:-80.1339},
{x:44.7561,y:-93.2486},
{x:43.0749,y:-88.0269},
{x:40.4383,y:-95.3873},
{x:39.2663,y:-76.6993},
{x:21.4207,y:-157.8058},
{x:31.3009,y:-85.7256},
{x:38.8602,y:-76.9662},
{x:41.8717,y:-71.4132},
{x:43.3012,y:-96.6047},
{x:39.3823,y:-76.7319},
{x:30.2001,y:-93.1798},
{x:37.9114,y:-121.2925},
{x:42.5803,y:-71.1129},
{x:40.4739,y:-88.9578},
{x:46.0046,y:-112.6126},
{x:37.7922,y:-122.3982},
{x:38.0042,y:-84.4468},
{x:33.6474,y:-83.9917},
{x:37.1925,y:-76.4885},
{x:43.2096,y:-77.4608},
{x:32.3219,y:-106.7345},
{x:38.8947,y:-77.0189},
{x:34.3683,y:-97.9638},
{x:33.6488,y:-117.7422},
{x:39.1721,y:-84.76},
{x:39.0477,y:-84.5531},
{x:31.068,y:-89.2088},
{x:31.2575,y:-89.3386},
{x:31.1082,y:-88.8119},
{x:32.4353,y:-97.1037},
{x:40.1081,y:-83.7366},
{x:30.4833,y:-84.2364},
{x:41.3121,y:-73.0567},
{x:36.5935,y:-82.2157},
{x:40.0314,y:-82.996},
{x:42.7121,y:-84.5385},
{x:30.4288,y:-90.5486},
{x:39.0,y:-104.7008},
{x:32.6852,y:-97.1155},
{x:40.3415,y:-75.9702},
{x:31.0823,y:-87.5332},
{x:33.5872,y:-84.3776},
{x:36.0262,y:-84.2396},
{x:41.0202,y:-73.7985},
{x:43.6791,y:-70.356},
{x:32.6628,y:-96.8405},
{x:33.4529,y:-94.0821},
{x:27.5678,y:-81.8146},
{x:42.5877,y:-83.8732},
{x:40.6183,y:-74.0302},
{x:32.3796,y:-86.299},
{x:37.3414,y:-81.0446},
{x:39.4409,y:-79.5336},
{x:38.4117,y:-86.9375},
{x:43.5953,y:-116.5172},
{x:34.1183,y:-117.4899},
{x:34.0707,y:-117.4533},
{x:34.1505,y:-117.473},
{x:40.6858,y:-80.0775},
{x:46.6063,y:-120.4465},
{x:33.5617,y:-117.6284},
{x:46.034,y:-118.3812},
{x:29.8244,y:-90.0063},
{x:36.6981,y:-82.0095},
{x:40.7267,y:-73.5443},
{x:30.4612,y:-87.2939},
{x:40.4057,y:-79.9131},
{x:39.9123,y:-86.2621},
{x:28.5014,y:-81.717},
{x:40.7005,y:-73.4314},
{x:40.8412,y:-73.6354},
{x:39.7697,y:-86.1576},
{x:33.4342,y:-111.6328},
{x:29.9097,y:-93.9223},
{x:40.813,y:-74.2074},
{x:33.0718,y:-112.0468},
{x:42.2103,y:-71.4273},
{x:39.9588,y:-82.0285},
{x:40.8306,y:-84.9381},
{x:39.8941,y:-82.0431},
{x:42.1795,y:-86.2593},
{x:42.7138,y:-73.9313},
{x:41.673,y:-87.6804},
{x:40.1042,y:-74.9512},
{x:40.195,y:-75.0809},
{x:29.3643,y:-81.9679},
{x:43.783,y:-88.4094},
{x:25.9401,y:-80.2812},
{x:39.8445,y:-82.6148},
{x:38.7188,y:-75.5863},
{x:41.5751,y:-83.4255},
{x:41.35,y:-81.7588},
{x:29.7044,y:-95.5893},
{x:42.0575,y:-76.7946},
{x:40.2761,y:-81.7558},
{x:38.8662,y:-91.9445},
{x:40.7685,y:-73.9889},
{x:43.0029,y:-85.5913},
{x:42.7974,y:-77.7892},
{x:43.1284,y:-77.6681},
{x:43.2966,y:-84.1401},
{x:29.9378,y:-95.3837},
{x:41.9425,y:-84.8917},
{x:35.4502,y:-80.9945},
{x:35.5538,y:-80.8557},
{x:34.0469,y:-117.2064},
{x:33.7152,y:-116.2319},
{x:44.1162,y:-93.6999},
{x:42.5488,y:-82.8747},
{x:37.6189,y:-122.0364},
{x:48.2154,y:-119.7277},
{x:39.2103,y:-78.1775},
{x:33.8454,y:-118.0377},
{x:44.3359,y:-84.7966},
{x:39.3632,y:-78.0429},
{x:39.0275,y:-77.5095},
{x:34.0023,y:-83.912},
{x:33.9378,y:-117.4048},
{x:33.8875,y:-117.3391},
{x:29.606,y:-98.5097},
{x:32.8329,y:-117.1494},
{x:32.6689,y:-97.203},
{x:42.5042,y:-78.688},
{x:40.5539,y:-105.1178},
{x:36.9296,y:-76.3196},
{x:39.7227,y:-85.8964},
{x:34.6479,y:-83.4499},
{x:41.0517,y:-81.3931},
{x:34.2221,y:-118.4287},
{x:34.7778,y:-111.7629},
{x:32.222,y:-110.9719},
{x:21.3363,y:-157.9099},
{x:40.6693,y:-73.3905},
{x:42.2553,y:-71.1245},
{x:32.1845,y:-86.5771},
{x:32.6919,y:-97.0647},
{x:33.3937,y:-86.7978},
{x:32.7539,y:-97.0962},
{x:47.3222,y:-122.2503},
{x:40.6727,y:-99.087},
{x:41.8264,y:-87.796},
{x:38.5547,y:-90.4075},
{x:40.1912,y:-77.2419},
{x:42.945,y:-83.6637},
{x:47.7348,y:-122.6363},
{x:38.0079,y:-84.2136},
{x:40.7642,y:-73.9795},
{x:41.1012,y:-111.9396},
{x:37.7389,y:-88.9467},
{x:40.2343,y:-111.694},
{x:40.1307,y:-111.5971},
{x:40.5266,y:-111.8997},
{x:33.1473,y:-117.1853},
{x:44.0118,y:-103.2929},
{x:39.9846,y:-76.7398},
{x:35.1607,y:-78.9706},
{x:39.0088,y:-77.4169},
{x:46.8918,y:-98.7172},
{x:45.4058,y:-122.5235},
{x:45.3218,y:-91.6593},
{x:47.7399,y:-117.4856},
{x:47.7004,y:-117.448},
{x:47.1562,y:-122.4687},
{x:40.4321,y:-79.7916},
{x:40.3486,y:-80.0579},
{x:40.5391,y:-80.0093},
{x:33.6805,y:-117.9199},
{x:41.7763,y:-76.7922},
{x:36.7922,y:-119.7157},
{x:38.6568,y:-92.1132},
{x:36.2182,y:-115.1602},
{x:40.7589,y:-82.558},
{x:31.7589,y:-106.486},
{x:34.0611,y:-118.2442},
{x:34.0808,y:-118.1525},
{x:32.91,y:-96.4523},
{x:33.1156,y:-96.8374},
{x:41.8661,y:-71.4432},
{x:32.3299,y:-90.8633},
{x:29.4408,y:-98.438},
{x:38.7793,y:-89.9829},
{x:38.7368,y:-89.9561},
{x:32.9589,y:-117.1251},
{x:38.2494,y:-89.7482},
{x:47.4883,y:-122.121},
{x:36.8429,y:-76.1134},
{x:36.0447,y:-79.9256},
{x:42.4901,y:-82.9401},
{x:39.5047,y:-121.5507},
{x:39.7109,y:-84.2014},
{x:36.0164,y:-86.6191},
{x:35.499,y:-86.4573},
{x:37.9984,y:-122.1329},
{x:39.3858,y:-76.5795},
{x:34.0747,y:-117.6691},
{x:30.6621,y:-88.1244},
{x:43.6038,y:-72.9794},
{x:39.4647,y:-76.6371},
{x:32.8667,y:-95.755},
{x:37.2335,y:-77.2633},
{x:40.8598,y:-73.3982},
{x:29.6813,y:-95.5602},
{x:32.5425,y:-82.9496},
{x:21.4273,y:-158.0011},
{x:39.5412,y:-119.716},
{x:30.0061,y:-95.4662},
{x:36.8812,y:-119.7475},
{x:42.9607,y:-70.8339},
{x:38.8669,y:-76.9847},
{x:30.0505,y:-95.1865},
{x:36.7803,y:-119.7732},
{x:36.8412,y:-119.7885},
{x:37.9942,y:-122.0327},
{x:32.6862,y:-83.3454},
{x:42.0421,y:-71.9071},
{x:39.3344,y:-91.1813},
{x:34.0215,y:-117.958},
{x:39.9515,y:-75.1682},
{x:39.6793,y:-105.0252},
{x:45.1267,y:-93.3369},
{x:35.3995,y:-94.3637},
{x:39.3906,y:-84.5101},
{x:39.3883,y:-84.508},
{x:38.0322,y:-84.5212},
{x:38.1226,y:-122.6038},
{x:37.6723,y:-121.0366},
{x:32.3327,y:-96.1352},
{x:32.1711,y:-96.0097},
{x:37.6473,y:-98.099},
{x:38.0807,y:-97.9324},
{x:38.2685,y:-122.0346},
{x:28.9006,y:-99.1153},
{x:29.5473,y:-98.3122},
{x:38.3039,y:-83.1623},
{x:35.4934,y:-97.3673},
{x:32.7249,y:-97.475},
{x:41.5484,y:-93.5973},
{x:29.0387,y:-96.5209},
{x:37.7783,y:-87.0634},
{x:41.4588,y:-81.8587},
{x:31.6184,y:-84.2125},
{x:33.866,y:-86.2899},
{x:34.2343,y:-118.4665},
{x:30.751,y:-81.5727},
{x:31.3085,y:-82.8494},
{x:39.7198,y:-82.9528},
{x:34.0959,y:-118.2081},
{x:33.7571,y:-88.1087},
{x:27.0718,y:-82.4235},
{x:38.2063,y:-85.5795},
{x:38.1688,y:-122.2536},
{x:39.9754,y:-74.2387},
{x:30.3804,y:-91.0981},
{x:32.4789,y:-93.7218},
{x:34.8509,y:-87.3905},
{x:32.9808,y:-86.6857},
{x:37.3313,y:-120.4667},
{x:36.81,y:-100.5192},
{x:29.4398,y:-98.3806},
{x:37.6888,y:-121.9025},
{x:26.1132,y:-81.687},
{x:40.7347,y:-74.0594},
{x:40.0372,y:-76.3419},
{x:35.4918,y:-94.2255},
{x:32.5448,y:-84.9509},
{x:34.8351,y:-87.6381},
{x:34.0537,y:-117.9776},
{x:37.7931,y:-122.4026},
{x:32.6503,y:-117.0956},
{x:34.7524,y:-87.6674},
{x:35.3089,y:-86.88},
{x:40.4879,y:-74.4836},
{x:43.9562,y:-88.1082},
{x:46.4629,y:-90.1443},
{x:36.0747,y:-95.8838},
{x:40.7519,y:-73.9096},
{x:41.9185,y:-87.6487},
{x:32.8608,y:-91.3902},
{x:33.9984,y:-96.4108},
{x:36.1605,y:-95.8988},
{x:42.8045,y:-73.9029},
{x:42.2432,y:-70.9893},
{x:33.8588,y:-117.9401},
{x:40.7661,y:-89.7489},
{x:37.183,y:-93.3173},
{x:29.7773,y:-95.397},
{x:45.8381,y:-87.0369},
{x:42.954,y:-88.1025},
{x:41.9171,y:-87.7065},
{x:41.8131,y:-87.7242},
{x:40.9756,y:-81.4067},
{x:41.8806,y:-87.6279},
{x:39.9589,y:-82.9968},
{x:43.5731,y:-70.6846},
{x:36.7405,y:-119.7889},
{x:37.3251,y:-121.8148},
{x:33.6084,y:-91.3831},
{x:33.9627,y:-118.2244},
{x:38.4195,y:-78.8951},
{x:41.1642,y:-90.045},
{x:41.9516,y:-87.6498},
{x:27.0706,y:-82.208},
{x:40.0321,y:-80.7277},
{x:40.8771,y:-74.1234},
{x:35.7754,y:-86.9155},
{x:30.45,y:-90.138},
{x:47.5711,y:-122.6166},
{x:32.7085,y:-117.0107},
{x:41.6974,y:-71.148},
{x:37.4375,y:-94.7049},
{x:29.2733,y:-94.8156},
{x:41.3825,y:-72.0258},
{x:40.4606,y:-79.9476},
{x:34.3629,y:-86.0043},
{x:38.0575,y:-121.3752},
{x:36.1515,y:-79.712},
{x:42.4057,y:-71.0838},
{x:35.7102,y:-79.8117},
{x:29.1255,y:-82.2083},
{x:31.9994,y:-81.2572},
{x:45.5302,y:-122.6805},
{x:30.5279,y:-87.2735},
{x:41.1006,y:-80.8576},
{x:19.6465,y:-155.9901},
{x:36.6874,y:-78.8994},
{x:44.5635,y:-72.6},
{x:33.9579,y:-84.7871},
{x:40.5847,y:-102.302},
{x:41.4518,y:-81.8177},
{x:33.9461,y:-118.3926},
{x:41.743,y:-72.716},
{x:32.3645,y:-86.2436},
{x:39.5067,y:-119.7742},
{x:41.503,y:-81.6861},
{x:44.5242,y:-89.4986},
{x:33.4313,y:-112.5919},
{x:30.5328,y:-97.7837},
{x:25.8561,y:-80.1285},
{x:33.1295,y:-97.0469},
{x:42.0222,y:-93.6505},
{x:32.7809,y:-96.801},
{x:32.9318,y:-96.702},
{x:39.731,y:-86.2496},
{x:39.3076,y:-87.9897},
{x:32.7197,y:-96.8919},
{x:29.848,y:-95.3415},
{x:42.217,y:-78.2765},
{x:39.7397,y:-105.1186},
{x:36.2253,y:-119.3314},
{x:30.1788,y:-96.3851},
{x:35.1704,y:-89.8144},
{x:34.0091,y:-117.8867},
{x:40.245,y:-74.2961},
{x:40.38,y:-80.0526},
{x:38.1731,y:-85.6852},
{x:40.2266,y:-74.0403},
{x:40.4913,y:-81.4555},
{x:40.1212,y:-91.5394},
{x:35.9781,y:-86.562},
{x:39.3209,y:-84.3134},
{x:31.8277,y:-106.5283},
{x:35.9899,y:-95.7968},
{x:39.2935,y:-81.524},
{x:38.1404,y:-85.6695},
{x:34.2781,y:-91.3394},
{x:33.4659,y:-84.2183},
{x:47.5635,y:-122.6251},
{x:34.201,y:-118.3694},
{x:41.9469,y:-71.2801},
{x:33.3293,y:-86.9999},
{x:35.0704,y:-89.8434},
{x:45.5936,y:-122.9956},
{x:36.0563,y:-79.66},
{x:34.0887,y:-84.4859},
{x:29.7362,y:-95.5488},
{x:40.2995,y:-80.1739},
{x:34.3722,y:-118.5162},
{x:34.3786,y:-118.5593},
{x:30.126,y:-95.4466},
{x:36.8535,y:-121.4207},
{x:42.3716,y:-83.234},
{x:41.9642,y:-87.8614},
{x:45.2677,y:-122.6754},
{x:26.6069,y:-80.1153},
{x:47.292,y:-122.3102},
{x:33.6111,y:-112.0147},
{x:34.624,y:-98.6283},
{x:37.6741,y:-77.674},
{x:41.3155,y:-72.9389},
{x:27.2484,y:-80.2727},
{x:41.0861,y:-82.4007},
{x:40.2562,y:-74.7933},
{x:35.2095,y:-118.8285},
{x:40.7447,y:-73.5342},
{x:40.8269,y:-73.8225},
{x:41.3567,y:-83.6537},
{x:26.0628,y:-80.1743},
{x:26.0652,y:-80.3142},
{x:26.393,y:-80.1011},
{x:30.3397,y:-89.7432},
{x:30.4486,y:-89.0979},
{x:39.3491,y:-76.5363},
{x:31.4193,y:-89.5455},
{x:36.225,y:-86.2907},
{x:40.0123,y:-79.8646},
{x:46.4182,y:-122.892},
{x:34.1161,y:-118.2618},
{x:37.7062,y:-122.1292},
{x:32.8012,y:-117.2331},
{x:32.7359,y:-97.339},
{x:41.333,y:-85.6978},
{x:32.9927,y:-117.2701},
{x:33.2647,y:-111.687},
{x:43.5063,y:-70.4381},
{x:42.7249,y:-87.9569},
{x:42.7567,y:-87.908},
{x:36.0795,y:-115.1015},
{x:42.8661,y:-109.8747},
{x:32.4621,y:-81.7921},
{x:34.987,y:-84.371},
{x:43.0527,y:-75.8663},
{x:42.1032,y:-71.5585},
{x:38.4363,y:-89.536},
{x:13.4895,y:144.8208},
{x:33.3471,y:-111.7202},
{x:33.5804,y:-112.2561},
{x:42.2684,y:-88.8539},
{x:43.6918,y:-116.4928},
{x:42.0799,y:-71.3859},
{x:40.7359,y:-73.9925},
{x:41.2882,y:-73.1126},
{x:47.412,y:-122.2273},
{x:42.5424,y:-89.0115},
{x:44.6761,y:-85.6555},
{x:42.7537,y:-71.43},
{x:39.812,y:-105.1705},
{x:28.0024,y:-82.3026},
{x:39.9062,y:-75.2415},
{x:25.9542,y:-81.7251},
{x:37.153,y:-90.6975},
{x:33.8876,y:-118.0292},
{x:40.5873,y:-73.9538},
{x:39.4442,y:-76.8112},
{x:44.0292,y:-92.631},
{x:39.9163,y:-105.0464},
{x:36.3083,y:-86.6073},
{x:36.0401,y:-115.0474},
{x:45.3345,y:-122.7611},
{x:45.9288,y:-122.7607},
{x:36.3268,y:-86.6993},
{x:33.8593,y:-117.974},
{x:33.8562,y:-84.5985},
{x:33.8035,y:-85.1749},
{x:38.9523,y:-85.9018},
{x:37.9846,y:-84.461},
{x:41.6495,y:-91.5017},
{x:39.0007,y:-84.6458},
{x:42.3804,y:-87.8543},
{x:47.5781,y:-122.1678},
{x:30.0034,y:-95.5326},
{x:38.7505,y:-77.571},
{x:43.6343,y:-116.6444},
{x:42.9088,y:-72.2613},
{x:41.3215,y:-72.8765},
{x:39.7119,y:-86.1189},
{x:43.0953,y:-79.0304},
{x:33.6409,y:-112.1513},
{x:34.1903,y:-118.448},
{x:45.9177,y:-119.3268},
{x:26.6041,y:-81.8513},
{x:37.5728,y:-97.1358},
{x:42.304,y:-89.0965},
{x:33.8738,y:-117.9172},
{x:33.7691,y:-85.8924},
{x:40.2928,y:-80.0313},
{x:35.9051,y:-78.5889},
{x:30.5209,y:-91.1555},
{x:39.3295,y:-76.7581},
{x:40.6815,y:-73.7654},
{x:42.2116,y:-71.796},
{x:35.4926,y:-97.1688},
{x:35.0903,y:-89.8013},
{x:35.5085,y:-97.9376},
{x:41.0504,y:-102.0692},
{x:29.8008,y:-95.7526},
{x:42.5189,y:-83.107},
{x:41.3518,y:-90.3846},
{x:35.5057,y:-98.9861},
{x:27.4322,y:-82.4246},
{x:33.5727,y:-111.7156},
{x:33.4664,y:-112.1883},
{x:33.3474,y:-111.9813},
{x:44.2962,y:-88.3123},
{x:41.8183,y:-90.2437},
{x:36.7066,y:-119.736},
{x:30.5445,y:-91.0271},
{x:38.5212,y:-85.2369},
{x:35.4439,y:-97.6006},
{x:35.6087,y:-97.5022},
{x:30.0759,y:-95.516},
{x:44.7901,y:-68.7772},
{x:41.8294,y:-97.4456},
{x:43.9433,y:-75.9168},
{x:33.8039,y:-117.9147},
{x:25.7846,y:-80.1766},
{x:25.7898,y:-80.1887},
{x:35.5002,y:-119.2631},
{x:40.835,y:-79.6259},
{x:36.8375,y:-119.6947},
{x:47.7903,y:-122.2193},
{x:47.8688,y:-121.7763},
{x:47.979,y:-122.2075},
{x:38.2267,y:-121.4286},
{x:34.5034,y:-112.2429},
{x:36.4144,y:-94.4532},
{x:36.3104,y:-94.1775},
{x:36.3053,y:-82.3406},
{x:45.2988,y:-96.4405},
{x:36.9748,y:-82.9781},
{x:40.7026,y:-73.8188},
{x:40.089,y:-98.5195},
{x:43.2054,y:-73.6772},
{x:40.8539,y:-73.9069},
{x:40.8051,y:-90.4016},
{x:43.7459,y:-122.4751},
{x:35.215,y:-97.4232},
{x:42.2286,y:-89.0282},
{x:29.9114,y:-95.4791},
{x:32.8707,y:-96.9668},
{x:37.9709,y:-87.5719},
{x:36.0301,y:-115.1354},
{x:39.0844,y:-108.552},
{x:35.4979,y:-91.998},
{x:36.013,y:-114.964},
{x:35.0058,y:-79.1736},
{x:32.3841,y:-81.6598},
{x:35.5519,y:-82.4726},
{x:42.5714,y:-90.2447},
{x:43.4063,y:-88.712},
{x:25.5797,y:-80.3958},
{x:25.8297,y:-80.2654},
{x:28.0587,y:-82.4155},
{x:29.3564,y:-98.8712},
{x:37.8309,y:-97.3724},
{x:44.6865,y:-90.1554},
{x:42.9553,y:-71.4443},
{x:43.6166,y:-71.6366},
{x:38.7391,y:-85.0594},
{x:38.8964,y:-77.0448},
{x:34.4442,y:-83.8057},
{x:32.7575,y:-97.7117},
{x:38.4096,y:-89.9929},
{x:42.5496,y:-82.9592},
{x:42.7165,y:-82.9598},
{x:41.191,y:-73.2194},
{x:40.8989,y:-73.8202},
{x:42.2834,y:-73.7559},
{x:27.7931,y:-82.3334},
{x:43.7688,y:-71.7428},
{x:44.8765,y:-92.6235},
{x:39.3858,y:-81.6671},
{x:37.3116,y:-121.9315},
{x:28.0437,y:-82.6766},
{x:38.141,y:-85.6875},
{x:38.5488,y:-92.1492},
{x:39.306,y:-91.4826},
{x:36.1783,y:-82.7802},
{x:26.413,y:-99.0207},
{x:48.0937,y:-122.1884},
{x:47.693,y:-122.3755},
{x:29.2107,y:-82.0654},
{x:40.4406,y:-82.2595},
{x:64.4964,y:-165.4006},
{x:31.1011,y:-97.356},
{x:40.9893,y:-80.3198},
{x:41.4506,y:-81.9201},
{x:33.3362,y:-81.7875},
{x:34.9835,y:-81.2132},
{x:44.5521,y:-123.3074},
{x:39.0478,y:-94.4747},
{x:21.3095,y:-157.8107},
{x:41.3504,y:-74.7337},
{x:33.434,y:-111.8675},
{x:33.466,y:-112.0053},
{x:40.6378,y:-74.4251},
{x:42.3581,y:-83.2195},
{x:38.6013,y:-76.9028},
{x:35.5758,y:-88.8153},
{x:32.7586,y:-96.8233},
{x:26.2064,y:-98.152},
{x:43.4626,y:-70.8079},
{x:43.1528,y:-71.0044},
{x:40.662,y:-73.7248},
{x:44.5363,y:-89.573},
{x:40.7746,y:-73.5316},
{x:37.4173,y:-88.8741},
{x:26.188,y:-98.1221},
{x:36.9477,y:-86.4227},
{x:40.69,y:-95.7922},
{x:45.1959,y:-96.0183},
{x:30.0768,y:-90.5231},
{x:29.9405,y:-90.1889},
{x:30.2867,y:-89.7807},
{x:33.5361,y:-101.9402},
{x:38.6673,y:-121.2715},
{x:37.1512,y:-121.6546},
{x:40.2449,y:-76.8041},
{x:43.6674,y:-116.6825},
{x:32.1454,y:-81.3388},
{x:30.4012,y:-90.1111},
{x:31.3988,y:-81.4517},
{x:40.6713,y:-79.0087},
{x:42.4465,y:-82.9162},
{x:32.6182,y:-97.389},
{x:33.0713,y:-97.2961},
{x:42.368,y:-83.3819},
{x:43.1216,y:-76.1579},
{x:39.8007,y:-89.6478},
{x:43.2923,y:-91.7949},
{x:25.9788,y:-97.5044},
{x:25.9448,y:-97.4297},
{x:41.8288,y:-88.0564},
{x:39.3103,y:-84.3809},
{x:40.2022,y:-89.6987},
{x:26.1076,y:-98.2572},
{x:43.0011,y:-71.517},
{x:32.4167,y:-97.214},
{x:41.2253,y:-73.0708},
{x:40.0796,y:-75.0275},
{x:37.1391,y:-93.2777},
{x:41.7967,y:-86.0799},
{x:36.7853,y:-76.1091},
{x:29.5176,y:-98.663},
{x:33.9066,y:-84.4899},
{x:34.075,y:-84.647},
{x:33.7543,y:-84.3816},
{x:33.7101,y:-84.2713},
{x:33.7982,y:-84.6405},
{x:32.809,y:-83.7188},
{x:32.8353,y:-83.6364},
{x:32.9247,y:-83.7349},
{x:42.4804,y:-71.4151},
{x:47.6874,y:-122.3119},
{x:40.7815,y:-81.4219},
{x:40.6767,y:-81.2582},
{x:35.1903,y:-84.8656},
{x:35.0757,y:-77.7332},
{x:33.2373,y:-87.6107},
{x:33.1743,y:-86.775},
{x:33.4157,y:-86.8067},
{x:35.4498,y:-84.6192},
{x:38.4037,y:-86.1078},
{x:40.4173,y:-86.2707},
{x:43.029,y:-77.4446},
{x:38.4332,y:-75.0557},
{x:31.6821,y:-92.1276},
{x:41.9844,y:-72.4478},
{x:41.8193,y:-72.6572},
{x:40.9171,y:-98.3574},
{x:35.6583,y:-81.9635},
{x:33.8104,y:-86.8222},
{x:27.9139,y:-97.1585},
{x:40.9615,y:-82.8539},
{x:41.6784,y:-70.9231},
{x:41.3693,y:-71.8608},
{x:44.4991,y:-84.5932},
{x:36.062,y:-79.8428},
{x:36.139,y:-86.8023},
{x:33.992,y:-83.7591},
{x:37.7529,y:-121.141},
{x:45.2412,y:-93.2344},
{x:45.1666,y:-93.2121},
{x:41.27,y:-82.6101},
{x:32.221,y:-90.1559},
{x:40.0184,y:-84.549},
{x:40.3361,y:-84.5713},
{x:40.9807,y:-85.0834},
{x:43.1914,y:-77.8006},
{x:40.2545,y:-83.7555},
{x:28.967,y:-95.9693},
{x:41.3119,y:-72.9222},
{x:40.4682,y:-84.1728},
{x:44.4395,y:-88.038},
{x:38.0632,y:-83.9547},
{x:42.2331,y:-88.8421},
{x:40.3675,y:-80.1091},
{x:39.2007,y:-94.4973},
{x:43.6479,y:-116.3525},
{x:39.6707,y:-86.3684},
{x:43.6045,y:-116.4159},
{x:33.3793,y:-112.1696},
{x:32.2665,y:-110.8924},
{x:31.5581,y:-110.2573},
{x:32.2064,y:-110.9058},
{x:39.1482,y:-121.5867},
{x:36.0988,y:-95.9762},
{x:40.9015,y:-80.8217},
{x:39.1211,y:-86.5312},
{x:29.613,y:-82.8101},
{x:33.4433,y:-88.8493},
{x:34.6101,y:-98.4752},
{x:40.4629,y:-88.9935},
{x:40.4579,y:-88.9093},
{x:20.0226,y:-155.6668},
{x:28.9832,y:-95.9564},
{x:42.4833,y:-76.4909},
{x:43.0598,y:-88.0656},
{x:36.375,y:-100.8023},
{x:32.9389,y:-117.232},
{x:28.4037,y:-97.7411},
{x:39.1993,y:-76.8148},
{x:39.5069,y:-77.6521},
{x:43.2195,y:-78.6773},
{x:30.774,y:-85.223},
{x:44.0971,y:-103.2021},
{x:37.3543,y:-79.2262},
{x:41.6757,y:-107.9829},
{x:40.8135,y:-91.1578},
{x:42.4265,y:-122.8491},
{x:35.4398,y:-95.9733},
{x:31.785,y:-106.2856},
{x:32.4805,y:-99.6972},
{x:34.2031,y:-119.1265},
{x:39.6954,y:-104.8671},
{x:36.3566,y:-86.714},
{x:27.9439,y:-82.7463},
{x:32.5522,y:-84.2395},
{x:42.343,y:-88.2431},
{x:42.0093,y:-93.586},
{x:39.5402,y:-77.9843},
{x:40.1022,y:-85.7303},
{x:38.0083,y:-121.8916},
{x:42.7897,y:-112.8391},
{x:32.1573,y:-90.1253},
{x:35.6116,y:-87.0362},
{x:34.8557,y:-86.5721},
{x:39.1371,y:-84.4997},
{x:41.5206,y:-81.5366},
{x:47.6082,y:-122.3318},
{x:30.2316,y:-85.891},
{x:42.8077,y:-89.6319},
{x:25.5965,y:-80.3552},
{x:25.6266,y:-80.3905},
{x:25.6526,y:-80.4152},
{x:31.5772,y:-84.1538},
{x:35.7399,y:-89.086},
{x:34.4183,y:-119.8472},
{x:36.3257,y:-119.947},
{x:25.6915,y:-80.3066},
{x:42.7467,y:-73.7642},
{x:36.7703,y:-81.7814},
{x:39.6279,y:-77.4097},
{x:40.7936,y:-89.3633},
{x:43.6477,y:-72.3345},
{x:42.5814,y:-82.8856},
{x:37.2268,y:-79.9979},
{x:33.8301,y:-78.6712},
{x:33.7079,y:-117.8557},
{x:47.1922,y:-122.2736},
{x:33.7079,y:-117.8868},
{x:40.954,y:-79.7394},
{x:30.4995,y:-90.107},
{x:41.6053,y:-74.294},
{x:28.3532,y:-80.6092},
{x:37.3148,y:-79.9607},
{x:39.8638,y:-86.4666},
{x:38.5722,y:-121.3382},
{x:34.8011,y:-86.9531},
{x:33.8732,y:-118.3942},
{x:32.5193,y:-85.0298},
{x:40.9895,y:-76.0092},
{x:28.5581,y:-81.824},
{x:40.4081,y:-105.0462},
{x:40.3794,y:-105.5163},
{x:41.1146,y:-78.7671},
{x:42.8775,y:-90.1649},
{x:26.9314,y:-82.3108},
{x:46.8756,y:-97.2095},
{x:35.3583,y:-79.795},
{x:38.9449,y:-76.717},
{x:39.4275,y:-76.2989},
{x:39.6329,y:-86.1204},
{x:34.744,y:-120.2773},
{x:38.2351,y:-85.7169},
{x:40.3542,y:-85.1533},
{x:34.0187,y:-117.7146},
{x:47.93,y:-122.2463},
{x:33.4498,y:-111.7358},
{x:35.1599,y:-114.5754},
{x:36.1032,y:-115.1725},
{x:41.3298,y:-89.1044},
{x:41.3277,y:-88.7129},
{x:37.7384,y:-121.4284},
{x:29.8651,y:-97.9631},
{x:34.8087,y:-86.7498},
{x:36.5538,y:-87.3976},
{x:32.6999,y:-114.6504},
{x:44.7753,y:-93.4152},
{x:34.0852,y:-117.8713},
{x:46.8438,y:-96.7664},
{x:40.0325,y:-83.1367},
{x:35.9519,y:-90.8252},
{x:34.9444,y:-92.0091},
{x:45.6882,y:-90.3955},
{x:45.787,y:-87.9141},
{x:36.257,y:-94.4855},
{x:42.3746,y:-71.0388},
{x:39.0796,y:-94.4345},
{x:40.1178,y:-82.9881},
{x:36.2625,y:-115.2068},
{x:35.2505,y:-91.6866},
{x:32.1359,y:-81.6226},
{x:41.0131,y:-80.2696},
{x:38.0243,y:-97.3375},
{x:39.8401,y:-84.118},
{x:42.9649,y:-78.696},
{x:43.1728,y:-79.0325},
{x:39.3702,y:-76.4535},
{x:39.4451,y:-92.4197},
{x:40.0574,y:-83.0743},
{x:27.367,y:-80.3504},
{x:40.4784,y:-79.97},
{x:40.3081,y:-79.3725},
{x:40.84,y:-73.9173},
{x:41.6086,y:-71.9806},
{x:42.2681,y:-71.7807},
{x:48.6359,y:-112.3303},
{x:43.4991,y:-87.8364},
{x:40.8662,y:-73.3646},
{x:42.3207,y:-85.5713},
{x:42.1085,y:-85.9744},
{x:39.4344,y:-77.8256},
{x:33.0566,y:-96.707},
{x:35.5292,y:-108.6887},
{x:42.6843,y:-75.5018},
{x:47.0929,y:-122.6459},
{x:36.3251,y:-119.3515},
{x:32.5877,y:-82.3284},
{x:25.768,y:-80.379},
{x:32.564,y:-97.3539},
{x:34.9177,y:-117.8986},
{x:34.926,y:-117.935},
{x:39.0266,y:-84.7146},
{x:39.9557,y:-82.9017},
{x:33.9088,y:-81.223},
{x:33.9107,y:-81.2298},
{x:45.1831,y:-93.1096},
{x:41.6624,y:-70.2846},
{x:27.3755,y:-80.3265},
{x:40.7252,y:-73.2501},
{x:45.081,y:-93.3359},
{x:41.6297,y:-87.5086},
{x:35.0092,y:-105.6682},
{x:41.7959,y:-72.6207},
{x:43.1527,y:-76.2292},
{x:26.1238,y:-81.76},
{x:46.9945,y:-122.919},
{x:46.647,y:-122.956},
{x:38.346,y:-122.7104},
{x:40.3583,y:-80.0004},
{x:36.5769,y:-87.4108},
{x:32.9577,y:-97.3075},
{x:34.6557,y:-84.9804},
{x:41.6042,y:-87.114},
{x:42.1327,y:-76.9447},
{x:34.771,y:-86.65},
{x:36.0769,y:-87.3758},
{x:30.4036,y:-91.1062},
{x:41.1011,y:-81.6453},
{x:35.9979,y:-115.2061},
{x:30.3741,y:-81.764},
{x:40.7273,y:-73.4209},
{x:38.0346,y:-84.4954},
{x:39.5258,y:-104.94},
{x:40.6043,y:-79.6056},
{x:42.9885,y:-87.8833},
{x:42.8002,y:-102.2038},
{x:38.3669,y:-78.2695},
{x:42.4652,y:-82.9298},
{x:43.0533,y:-83.2995},
{x:36.143,y:-98.9248},
{x:47.244,y:-122.5279},
{x:47.2209,y:-122.5345},
{x:40.3677,y:-86.8624},
{x:38.9646,y:-94.4986},
{x:44.6572,y:-93.2532},
{x:42.8387,y:-73.7438},
{x:32.9415,y:-97.0741},
{x:41.1178,y:-104.8049},
{x:39.1472,y:-92.6837},
{x:30.2138,y:-89.7931},
{x:38.6879,y:-93.2515},
{x:40.0772,y:-80.8715},
{x:40.5837,y:-111.8621},
{x:18.1255,y:-67.0966},
{x:33.5401,y:-89.1336},
{x:41.8881,y:-71.1234},
{x:33.2904,y:-111.7204},
{x:27.957,y:-81.6206},
{x:28.0062,y:-81.7225},
{x:40.5316,y:-74.3771},
{x:40.279,y:-80.0827},
{x:33.8012,y:-84.4133},
{x:35.1181,y:-106.6747},
{x:43.084,y:-96.7878},
{x:43.1035,y:-73.7472},
{x:38.6623,y:-120.9368},
{x:42.9432,y:-78.868},
{x:43.1927,y:-77.5869},
{x:39.3042,y:-76.6159},
{x:34.6012,y:-112.4584},
{x:35.5213,y:-78.3133},
{x:26.0837,y:-80.3698},
{x:28.4362,y:-81.4717},
{x:44.9015,y:-93.1713},
{x:28.3054,y:-81.3508},
{x:31.0979,y:-85.7059},
{x:41.9944,y:-87.6599},
{x:42.2937,y:-76.7155},
{x:40.6021,y:-73.935},
{x:27.6394,y:-80.4499},
{x:42.7984,y:-70.875},
{x:44.6839,y:-74.9869},
{x:27.5221,y:-80.3963},
{x:43.1758,y:-86.2203},
{x:42.5083,y:-71.1454},
{x:47.0393,y:-122.8909},
{x:42.4294,y:-71.05},
{x:36.2284,y:-93.1113},
{x:32.0203,y:-93.3358},
{x:29.7213,y:-95.4423},
{x:29.7391,y:-95.4637},
{x:37.3712,y:-122.047},
{x:41.7853,y:-72.7479},
{x:33.8876,y:-117.8412},
{x:29.5884,y:-95.1833},
{x:29.3219,y:-98.5543},
{x:40.2781,y:-86.4966},
{x:35.9484,y:-78.9215},
{x:30.004,y:-95.307},
{x:36.2055,y:-86.7718},
{x:39.997,y:-81.5776},
{x:30.112,y:-93.8219},
{x:34.1882,y:-90.5787},
{x:41.525,y:-73.5882},
{x:29.7893,y:-95.6879},
{x:28.0483,y:-81.8591},
{x:34.3785,y:-84.932},
{x:36.5972,y:-121.8516},
{x:30.0821,y:-94.1004},
{x:40.7073,y:-74.4023},
{x:43.1443,y:-77.5901},
{x:21.3314,y:-158.0925},
{x:32.6144,y:-117.026},
{x:40.6173,y:-73.9925},
{x:24.5516,y:-81.7982},
{x:28.0032,y:-82.1844},
{x:28.1844,y:-81.8232},
{x:33.0807,y:-82.0097},
{x:34.8816,y:-84.946},
{x:40.6651,y:-74.1175},
{x:39.8356,y:-77.9072},
{x:41.0592,y:-73.8164},
{x:30.2297,y:-93.2176},
{x:39.4413,y:-84.3439},
{x:33.0107,y:-97.0461},
{x:42.283,y:-71.6083},
{x:35.6603,y:-88.8518},
{x:43.4951,y:-73.7728},
{x:26.2369,y:-80.203},
{x:36.3385,y:-85.6566},
{x:35.7613,y:-83.977},
{x:47.3608,y:-122.1089},
{x:33.8038,y:-118.0441},
{x:41.9008,y:-71.0983},
{x:30.4892,y:-90.457},
{x:48.7447,y:-122.4582},
{x:35.7372,y:-78.8651},
{x:33.6864,y:-117.972},
{x:34.07,y:-117.4327},
{x:35.3695,y:-84.3035},
{x:33.612,y:-84.4034},
{x:39.0033,y:-93.7342},
{x:33.3205,y:-84.7776},
{x:39.4507,y:-87.1243},
{x:38.1748,y:-87.5491},
{x:41.8774,y:-87.642},
{x:41.7179,y:-87.7412},
{x:47.6593,y:-122.3425},
{x:34.2144,y:-84.7532},
{x:26.1647,y:-97.8242},
{x:38.9117,y:-77.0383},
{x:38.295,y:-85.9467},
{x:36.087,y:-79.7751},
{x:47.387,y:-122.1996},
{x:33.749,y:-118.4066},
{x:41.3877,y:-81.9038},
{x:42.3914,y:-71.5637},
{x:36.4168,y:-121.3199},
{x:44.6824,y:-73.4508},
{x:39.3223,y:-76.7485},
{x:38.9854,y:-76.7588},
{x:33.728,y:-117.8856},
{x:35.4249,y:-84.4855},
{x:36.393,y:-93.7381},
{x:36.0397,y:-79.8377},
{x:43.2002,y:-96.2819},
{x:41.301,y:-75.8552},
{x:39.3121,y:-85.2085},
{x:34.459,y:-114.2795},
{x:21.2769,y:-157.7867},
{x:29.6123,y:-95.4445},
{x:43.1982,y:-86.2635},
{x:33.3644,y:-111.6692},
{x:29.5636,y:-95.6869},
{x:37.5248,y:-75.834},
{x:34.0321,y:-118.2661},
{x:34.662,y:-82.2606},
{x:43.2675,y:-86.2513},
{x:33.8172,y:-117.8889},
{x:40.4106,y:-80.0128},
{x:30.1774,y:-95.4506},
{x:40.7704,y:-87.4387},
{x:40.1278,y:-79.5483},
{x:38.8096,y:-77.254},
{x:36.0825,y:-79.0803},
{x:42.203,y:-78.1451},
{x:40.8353,y:-73.0472},
{x:36.1793,y:-86.2988},
{x:32.5108,y:-92.1917},
{x:41.03,y:-81.9072},
{x:30.4861,y:-84.4203},
{x:33.6817,y:-112.2792},
{x:35.2355,y:-114.0374},
{x:35.0504,y:-89.839},
{x:34.0532,y:-117.777},
{x:42.365,y:-83.0695},
{x:32.6544,y:-95.225},
{x:38.7132,y:-77.2138},
{x:38.8542,y:-77.05},
{x:33.4564,y:-112.2743},
{x:34.7698,y:-85.5418},
{x:35.6622,y:-78.4973},
{x:35.0346,y:-78.7514},
{x:34.0792,y:-117.3518},
{x:40.7053,y:-74.014},
{x:35.1269,y:-85.2425},
{x:42.5388,y:-110.1098},
{x:30.0007,y:-95.164},
{x:34.7592,y:-84.9324},
{x:38.3882,y:-82.6059},
{x:44.5023,y:-73.1829},
{x:34.4204,y:-117.2939},
{x:40.7707,y:-73.9041},
{x:28.6939,y:-99.8316},
{x:41.5853,y:-87.5592},
{x:34.15,y:-118.4454},
{x:30.4873,y:-91.076},
{x:37.7116,y:-122.212},
{x:41.5766,y:-109.252},
{x:35.954,y:-86.8025},
{x:36.8091,y:-87.1539},
{x:34.3186,y:-118.4644},
{x:38.3309,y:-85.7585},
{x:41.2941,y:-96.1801},
{x:29.5124,y:-98.5517},
{x:37.9855,y:-121.347},
{x:41.6421,y:-81.4111},
{x:36.631,y:-81.7836},
{x:37.9983,y:-121.8443},
{x:37.7767,y:-122.3945},
{x:32.7386,y:-111.5494},
{x:30.4521,y:-88.9338},
{x:34.3806,y:-96.1307},
{x:34.7463,y:-92.2867},
{x:41.4093,y:-81.4981},
{x:33.614,y:-117.6782},
{x:38.0169,y:-84.5261},
{x:32.8313,y:-97.2186},
{x:37.275,y:-121.8933},
{x:40.73,y:-73.863},
{x:40.1132,y:-88.0414},
{x:38.5506,y:-121.4893},
{x:28.1501,y:-82.3537},
{x:34.7728,y:-86.5331},
{x:38.8477,y:-77.1176},
{x:38.8537,y:-77.1352},
{x:32.2903,y:-106.784},
{x:29.9143,y:-95.4142},
{x:41.2712,y:-110.9522},
{x:40.2674,y:-75.6564},
{x:27.8932,y:-82.3183},
{x:33.6124,y:-112.1844},
{x:30.2488,y:-81.7703},
{x:34.563,y:-112.4883},
{x:39.9791,y:-75.1601},
{x:30.324,y:-90.9245},
{x:42.0761,y:-71.6293},
{x:41.8772,y:-88.2343},
{x:31.6528,y:-106.2677},
{x:30.0653,y:-81.4969},
{x:34.5375,y:-86.9113},
{x:40.277,y:-79.8379},
{x:41.3992,y:-73.971},
{x:30.4114,y:-88.9236},
{x:33.8597,y:-118.1173},
{x:31.9142,y:-97.6638},
{x:33.5557,y:-112.1341},
{x:36.1298,y:-96.0},
{x:42.9644,y:-83.8145},
{x:38.8147,y:-121.2572},
{x:35.1961,y:-101.7414},
{x:41.2392,y:-75.8939},
{x:45.0652,y:-84.897},
{x:37.7111,y:-122.4668},
{x:33.562,y:-84.3227},
{x:38.5609,y:-121.7346},
{x:47.0176,y:-122.8986},
{x:33.918,y:-117.2271},
{x:43.0557,y:-75.2712},
{x:39.5507,y:-107.3274},
{x:41.9997,y:-84.1893},
{x:42.754,y:-71.463},
{x:38.2968,y:-77.4971},
{x:40.745,y:-73.6543},
{x:41.6109,y:-70.4917},
{x:39.3471,y:-84.2176},
{x:38.451,y:-90.3662},
{x:35.6813,y:-97.0546},
{x:43.4157,y:-84.0128},
{x:37.7954,y:-82.5729},
{x:32.2796,y:-90.1551},
{x:34.0223,y:-84.2727},
{x:36.5549,y:-82.5552},
{x:42.6022,y:-73.7927},
{x:18.4294,y:-65.9895},
{x:44.8408,y:-91.5243},
{x:38.863,y:-83.8731},
{x:44.7935,y:-91.4243},
{x:27.9255,y:-82.453},
{x:41.3581,y:-74.2848},
{x:45.5371,y:-122.8692},
{x:33.9226,y:-84.0849},
{x:35.3825,y:-97.5311},
{x:42.083,y:-89.9873},
{x:38.4098,y:-121.4067},
{x:30.7635,y:-88.2264},
{x:26.0462,y:-80.2362},
{x:33.895,y:-117.4922},
{x:33.847,y:-117.9896},
{x:36.1098,y:-86.7258},
{x:40.4655,y:-79.8265},
{x:39.1447,y:-86.5782},
{x:37.467,y:-122.2247},
{x:30.8127,y:-92.6666},
{x:43.1856,y:-76.2223},
{x:34.3646,y:-89.5562},
{x:38.9005,y:-77.0098},
{x:25.9256,y:-80.2451},
{x:42.3019,y:-89.638},
{x:38.0209,y:-121.269},
{x:38.0376,y:-121.2602},
{x:30.6709,y:-88.2762},
{x:36.7726,y:-119.6994},
{x:39.7713,y:-84.1076},
{x:32.7287,y:-117.2178},
{x:35.6678,y:-78.7992},
{x:40.5975,y:-97.8587},
{x:41.7346,y:-73.5813},
{x:36.7521,y:-76.2702},
{x:46.4784,y:-84.3537},
{x:29.9794,y:-95.2143},
{x:35.9135,y:-78.78},
{x:27.1366,y:-82.4571},
{x:38.8341,y:-82.1546},
{x:41.0236,y:-74.2098},
{x:39.2637,y:-81.5484},
{x:33.3338,y:-86.9176},
{x:38.975,y:-76.5581},
{x:37.086,y:-88.0851},
{x:42.3998,y:-83.2164},
{x:34.1365,y:-117.2514},
{x:33.8962,y:-117.472},
{x:40.9844,y:-76.0181},
{x:44.591,y:-88.0451},
{x:41.7263,y:-94.9369},
{x:41.1822,y:-93.7288},
{x:29.6349,y:-82.3389},
{x:44.8169,y:-92.9333},
{x:37.0585,y:-100.9228},
{x:25.536,y:-80.478},
{x:31.3448,y:-109.5459},
{x:35.2814,y:-93.0933},
{x:42.3312,y:-83.0696},
{x:41.7136,y:-72.6056},
{x:38.3701,y:-97.6292},
{x:18.0246,y:-67.1552},
{x:35.1638,y:-80.8846},
{x:39.6265,y:-79.9623},
{x:34.8381,y:-82.6094},
{x:32.8623,y:-97.2109},
{x:36.0984,y:-119.5616},
{x:27.5743,y:-99.4375},
{x:36.0116,y:-83.5321},
{x:36.652,y:-119.7914},
{x:42.3993,y:-83.2961},
{x:44.936,y:-91.1471},
{x:43.0744,y:-89.4377},
{x:40.3033,y:-79.5077},
{x:26.7817,y:-80.2932},
{x:33.4059,y:-111.7716},
{x:40.7304,y:-80.3102},
{x:42.0846,y:-73.968},
{x:33.562,y:-117.6581},
{x:42.7578,y:-86.1158},
{x:38.6645,y:-96.4875},
{x:40.4517,y:-80.6127},
{x:36.4646,y:-77.6915},
{x:18.3778,y:-67.1876},
{x:18.3255,y:-67.2457},
{x:41.0038,y:-96.1614},
{x:21.3655,y:-157.9273},
{x:21.344,y:-157.9751},
{x:26.6178,y:-81.6708},
{x:42.5989,y:-71.35},
{x:40.5491,y:-90.5043},
{x:32.7426,y:-117.0458},
{x:44.9003,y:-93.1377},
{x:32.6443,y:-117.0016},
{x:40.3384,y:-84.3751},
{x:41.0527,y:-73.536},
{x:34.0668,y:-117.3215},
{x:40.0776,y:-80.6904},
{x:35.613,y:-80.5207},
{x:41.4053,y:-75.6805},
{x:39.7991,y:-104.9042},
{x:38.3664,y:-75.5423},
{x:33.4066,y:-111.668},
{x:41.7901,y:-87.8755},
{x:33.6914,y:-85.1895},
{x:33.7485,y:-117.8332},
{x:38.3623,y:-122.692},
{x:34.9138,y:-82.2367},
{x:40.7358,y:-73.714},
{x:37.7835,y:-122.4315},
{x:39.7501,y:-84.199},
{x:26.163,y:-80.2166},
{x:21.3448,y:-157.9138},
{x:40.4781,y:-79.7859},
{x:29.9625,y:-90.1422},
{x:21.2786,y:-157.7053},
{x:40.1888,y:-105.0737},
{x:39.7481,y:-84.5311},
{x:39.7799,y:-86.31},
{x:37.294,y:-121.9641},
{x:32.7627,y:-96.8957},
{x:42.1553,y:-88.3392},
{x:44.4855,y:-88.0642},
{x:30.2909,y:-87.5844},
{x:37.6696,y:-121.8587},
{x:38.6272,y:-90.3395},
{x:40.8604,y:-73.8896},
{x:32.0628,y:-95.502},
{x:37.06,y:-113.5862},
{x:37.1225,y:-113.626},
{x:39.6521,y:-78.9059},
{x:38.5906,y:-90.2702},
{x:38.0083,y:-87.6022},
{x:32.9256,y:-96.7695},
{x:34.201,y:-82.134},
{x:41.101,y:-80.7435},
{x:39.6896,y:-84.1456},
{x:40.1345,y:-95.2341},
{x:39.1324,y:-95.718},
{x:34.1244,y:-118.0579},
{x:38.9428,y:-76.9012},
{x:37.0434,y:-86.2093},
{x:39.4937,y:-76.6491},
{x:42.8778,y:-77.2644},
{x:35.4662,y:-79.1583},
{x:42.8347,y:-78.6375},
{x:45.4503,y:-122.5761},
{x:36.0569,y:-79.0854},
{x:41.9342,y:-89.0327},
{x:33.5715,y:-117.2343},
{x:33.9444,y:-117.2254},
{x:47.1942,y:-122.5179},
{x:41.3765,y:-83.1193},
{x:41.0187,y:-85.1694},
{x:27.9969,y:-82.5152},
{x:27.6054,y:-99.5175},
{x:34.0911,y:-118.0134},
{x:36.5749,y:-119.6222},
{x:41.3133,y:-82.0036},
{x:33.6981,y:-84.1723},
{x:32.8158,y:-96.6595},
{x:33.4892,y:-82.0779},
{x:42.9923,y:-83.6343},
{x:38.7325,y:-90.3567},
{x:29.6752,y:-95.509},
{x:36.1681,y:-87.3511},
{x:37.7642,y:-89.3411},
{x:33.6085,y:-86.8036},
{x:38.9677,y:-84.6071},
{x:30.0416,y:-94.0715},
{x:39.2817,y:-76.655},
{x:29.7178,y:-95.4764},
{x:21.3455,y:-157.8833},
{x:39.2523,y:-84.4242},
{x:21.3366,y:-157.9387},
{x:48.9046,y:-115.0584},
{x:44.0451,y:-75.8386},
{x:42.6977,y:-73.6486},
{x:32.4487,y:-97.5343},
{x:35.1056,y:-80.6325},
{x:39.1884,y:-84.6015},
{x:41.0631,y:-111.9456},
{x:41.825,y:-71.4999},
{x:39.1544,y:-84.6281},
{x:34.1555,y:-78.8596},
{x:39.9073,y:-82.8231},
{x:30.1638,y:-97.7905},
{x:33.8191,y:-118.1889},
{x:39.4404,y:-94.206},
{x:34.0544,y:-78.8903},
{x:30.5701,y:-84.7425},
{x:30.1945,y:-81.8262},
{x:30.2607,y:-81.6458},
{x:30.1946,y:-81.5671},
{x:33.8322,y:-117.9473},
{x:39.5806,y:-78.8474},
{x:41.3388,y:-80.0918},
{x:41.6232,y:-80.2021},
{x:39.1688,y:-84.4244},
{x:30.5162,y:-87.2186},
{x:42.087,y:-70.9873},
{x:36.1569,y:-80.932},
{x:36.2002,y:-95.1583},
{x:30.4954,y:-90.7476},
{x:44.6807,y:-91.1272},
{x:35.6633,y:-78.7003},
{x:41.3453,y:-72.05},
{x:35.8908,y:-77.8633},
{x:33.9889,y:-118.2138},
{x:34.0418,y:-80.9932},
{x:41.7484,y:-71.1449},
{x:39.324,y:-77.6184},
{x:31.9625,y:-97.3239},
{x:30.4243,y:-89.436},
{x:37.2934,y:-121.8746},
{x:44.0108,y:-73.1644},
{x:32.8367,y:-109.7338},
{x:40.761,y:-77.879},
{x:43.044,y:-74.8561},
{x:37.8296,y:-82.4019},
{x:40.7182,y:-82.5464},
{x:35.8758,y:-84.5133},
{x:37.5653,y:-82.6452},
{x:41.6901,y:-87.7854},
{x:39.2496,y:-84.2965},
{x:33.5019,y:-84.2447},
{x:29.6645,y:-84.8605},
{x:33.2589,y:-87.5148},
{x:41.8065,y:-87.7427},
{x:42.2513,y:-71.809},
{x:40.6982,y:-90.0052},
{x:35.9496,y:-80.0332},
{x:41.9639,y:-87.7087},
{x:39.6685,y:-104.8625},
{x:33.1289,y:-96.63},
{x:25.0027,y:-80.5267},
{x:25.8971,y:-80.3311},
{x:34.0939,y:-84.2094},
{x:45.6032,y:-122.6761},
{x:37.6618,y:-82.2741},
{x:32.415,y:-88.5053},
{x:39.0201,y:-84.6317},
{x:45.3224,y:-92.7013},
{x:41.6272,y:-71.1469},
{x:41.7963,y:-71.4255},
{x:41.6719,y:-72.9461},
{x:39.0282,y:-84.4517},
{x:35.609,y:-77.3705},
{x:35.6007,y:-77.3633},
{x:41.8977,y:-93.6023},
{x:38.9879,y:-78.3572},
{x:39.0651,y:-84.2782},
{x:36.339,y:-89.1597},
{x:44.8445,y:-93.4404},
{x:40.1177,y:-83.0659},
{x:36.3125,y:-82.8385},
{x:34.5089,y:-81.8584},
{x:34.1354,y:-116.3156},
{x:39.2883,y:-84.4309},
{x:32.4727,y:-90.1427},
{x:32.4458,y:-93.9497},
{x:39.017,y:-84.5084},
{x:38.1313,y:-105.4634},
{x:31.3899,y:-102.3483},
{x:42.3409,y:-89.0184},
{x:37.7001,y:-77.8876},
{x:36.6757,y:-93.8853},
{x:35.5245,y:-97.5437},
{x:40.0029,y:-82.6756},
{x:40.7774,y:-73.6506},
{x:41.6362,y:-70.9497},
{x:33.7453,y:-81.0984},
{x:37.9056,y:-121.2221},
{x:33.4505,y:-112.3398},
{x:33.4806,y:-80.8228},
{x:46.9751,y:-123.8627},
{x:35.4049,y:-97.5659},
{x:25.7601,y:-80.4804},
{x:28.553,y:-81.4558},
{x:30.5342,y:-87.2752},
{x:37.8133,y:-92.1433},
{x:40.0039,y:-79.0657},
{x:40.3174,y:-74.6201},
{x:29.8098,y:-90.8177},
{x:40.3882,y:-111.9115},
{x:42.4917,y:-88.9969},
{x:33.4035,y:-86.964},
{x:41.5286,y:-87.6963},
{x:39.7434,y:-83.8074},
{x:41.1555,y:-95.9363},
{x:39.4243,y:-78.995},
{x:40.4789,y:-78.7391},
{x:47.7634,y:-122.346},
{x:32.7603,y:-96.6833},
{x:44.0452,y:-71.6675},
{x:27.7124,y:-82.3399},
{x:40.1175,y:-84.3548},
{x:36.1203,y:-86.7035},
{x:33.4799,y:-112.1187},
{x:41.844,y:-70.9481},
{x:18.3853,y:-66.1733},
{x:37.7123,y:-120.9521},
{x:40.7085,y:-84.3531},
{x:37.5167,y:-84.8056},
{x:40.1157,y:-88.2415},
{x:42.2336,y:-72.1553},
{x:40.1873,y:-105.1016},
{x:40.528,y:-89.4945},
{x:33.3067,y:-111.7376},
{x:33.279,y:-85.1026},
{x:39.7772,y:-88.8907},
{x:41.0737,y:-85.2754},
{x:42.5689,y:-71.8133},
{x:42.5501,y:-99.8517},
{x:30.8263,y:-83.3192},
{x:42.8803,y:-88.5838},
{x:40.909,y:-79.9289},
{x:32.3731,y:-111.1362},
{x:39.7067,y:-104.9212},
{x:41.1305,y:-73.794},
{x:42.0536,y:-87.8555},
{x:46.3128,y:-114.1145},
{x:35.4978,y:-82.5176},
{x:34.9351,y:-78.9216},
{x:26.1743,y:-81.7181},
{x:42.9823,y:-85.0704},
{x:42.484,y:-92.4449},
{x:30.2346,y:-92.1854},
{x:30.411,y:-92.058},
{x:32.5121,y:-92.1579},
{x:33.2734,y:-84.2982},
{x:33.2916,y:-96.9861},
{x:44.9709,y:-116.2808},
{x:37.5498,y:-87.2594},
{x:35.2419,y:-77.5791},
{x:21.4968,y:-158.0256},
{x:40.5563,y:-112.3005},
{x:33.7187,y:-85.0299},
{x:13.5298,y:144.8851},
{x:40.652,y:-73.9293},
{x:29.9535,y:-90.0686},
{x:46.9948,y:-122.8075},
{x:37.4904,y:-121.9479},
{x:35.547,y:-97.6115},
{x:25.7652,y:-80.1934},
{x:38.5652,y:-90.3528},
{x:38.4726,y:-75.1059},
{x:26.8537,y:-80.0584},
{x:33.5099,y:-112.3409},
{x:28.3045,y:-81.3905},
{x:34.4562,y:-118.6183},
{x:29.8586,y:-90.1015},
{x:40.5141,y:-79.8438},
{x:39.6597,y:-85.1272},
{x:39.8126,y:-85.1633},
{x:41.5529,y:-75.6532},
{x:27.7007,y:-97.4154},
{x:42.9128,y:-74.572},
{x:35.3678,y:-119.0496},
{x:38.3506,y:-121.9523},
{x:43.0018,y:-78.7852},
{x:41.239,y:-75.8564},
{x:29.5409,y:-95.0219},
{x:29.8497,y:-95.6455},
{x:28.0539,y:-82.4382},
{x:39.2523,y:-76.8167},
{x:39.559,y:-104.8247},
{x:37.2533,y:-77.3272},
{x:29.3722,y:-98.5962},
{x:29.3814,y:-98.6257},
{x:47.6026,y:-117.366},
{x:39.9562,y:-86.0105},
{x:40.3516,y:-79.863},
{x:40.4452,y:-79.7648},
{x:40.5893,y:-76.5518},
{x:28.7343,y:-100.4828},
{x:33.8504,y:-117.9235},
{x:42.3063,y:-74.2524},
{x:42.163,y:-75.8919},
{x:40.9637,y:-81.5651},
{x:40.8345,y:-80.3277},
{x:35.9928,y:-83.902},
{x:35.8616,y:-84.0671},
{x:32.8869,y:-86.6246},
{x:38.0062,y:-121.355},
{x:47.4897,y:-111.3275},
{x:39.6372,y:-104.7919},
{x:42.9837,y:-76.3404},
{x:43.4807,y:-83.9906},
{x:42.6229,y:-71.3611},
{x:42.604,y:-72.5622},
{x:29.9716,y:-95.6899},
{x:35.295,y:-98.988},
{x:41.8444,y:-87.7258},
{x:33.1,y:-84.343},
{x:40.5296,y:-85.6737},
{x:39.4027,y:-76.4491},
{x:44.4217,y:-84.1166},
{x:39.1009,y:-94.5789},
{x:39.9078,y:-82.7413},
{x:30.2426,y:-92.1937},
{x:33.7483,y:-84.3913},
{x:28.8558,y:-82.3882},
{x:42.074,y:-87.839},
{x:41.3495,y:-71.9586},
{x:40.4539,y:-79.0316},
{x:37.729,y:-122.4039},
{x:33.8888,y:-117.5636},
{x:33.9067,y:-78.585},
{x:37.3724,y:-82.5442},
{x:44.9202,y:-74.8888},
{x:43.1101,y:-75.2177},
{x:35.827,y:-86.0789},
{x:37.0449,y:-88.6565},
{x:38.7398,y:-90.6166},
{x:39.6135,y:-86.0703},
{x:41.3351,y:-73.2632},
{x:47.3592,y:-122.3085},
{x:41.821,y:-87.8031},
{x:29.924,y:-95.5569},
{x:30.0125,y:-95.5955},
{x:29.7497,y:-95.3779},
{x:29.7748,y:-95.235},
{x:38.8379,y:-77.1546},
{x:25.8837,y:-80.3308},
{x:36.0868,y:-86.6935},
{x:33.031,y:-111.3871},
{x:40.6538,y:-73.6308},
{x:40.9693,y:-102.2479},
{x:33.8036,y:-118.1257},
{x:35.3872,y:-80.194},
{x:32.4096,y:-84.9415},
{x:40.7885,y:-73.707},
{x:41.5114,y:-90.7752},
{x:36.8129,y:-76.1693},
{x:36.7816,y:-76.189},
{x:32.7825,y:-96.7964},
{x:35.2425,y:-81.1238},
{x:33.1472,y:-117.1072},
{x:34.2956,y:-118.8427},
{x:27.3159,y:-80.3785},
{x:35.761,y:-78.7382},
{x:40.7803,y:-86.1274},
{x:35.0031,y:-85.1483},
{x:30.6713,y:-87.9053},
{x:40.7694,y:-80.0622},
{x:40.3673,y:-79.6774},
{x:35.8355,y:-78.6155},
{x:41.9103,y:-71.3629},
{x:42.5342,y:-83.0289},
{x:38.738,y:-85.0829},
{x:29.0257,y:-81.2605},
{x:43.2888,y:-73.6385},
{x:35.2708,y:-118.914},
{x:37.2833,y:-98.0233},
{x:21.298,y:-157.8186},
{x:42.5984,y:-73.7101},
{x:40.0441,y:-86.0729},
{x:48.8209,y:-100.448},
{x:41.7221,y:-91.6071},
{x:26.7538,y:-80.9501},
{x:26.1241,y:-98.1955},
{x:40.7251,y:-74.0673},
{x:21.3078,y:-157.8635},
{x:32.9093,y:-96.8588},
{x:33.3944,y:-104.5479},
{x:32.3932,y:-104.221},
{x:42.108,y:-75.8861},
{x:42.0367,y:-93.88},
{x:32.7367,y:-96.8564},
{x:37.1016,y:-81.1268},
{x:38.5267,y:-121.9651},
{x:39.4672,y:-119.7849},
{x:40.6691,y:-73.9311},
{x:39.4238,y:-119.7381},
{x:37.4916,y:-87.1285},
{x:43.1649,y:-77.6639},
{x:43.4575,y:-76.5083},
{x:41.6825,y:-88.1258},
{x:40.6918,y:-73.5305},
{x:40.6105,y:-122.3711},
{x:42.3372,y:-71.0843},
{x:32.6295,y:-117.0877},
{x:43.0486,y:-74.2174},
{x:36.0864,y:-79.7104},
{x:41.7725,y:-72.5315},
{x:40.4082,y:-80.5461},
{x:36.7321,y:-108.2327},
{x:21.3198,y:-157.8612},
{x:27.8533,y:-82.2797},
{x:33.6273,y:-117.6353},
{x:32.6311,y:-116.1638},
{x:42.3319,y:-71.1947},
{x:42.0802,y:-80.0913},
{x:42.3651,y:-71.1045},
{x:41.2501,y:-84.1326},
{x:35.763,y:-91.6135},
{x:33.491,y:-84.9131},
{x:35.2695,y:-81.4827},
{x:35.436,y:-84.5898},
{x:43.3225,y:-88.0061},
{x:35.0542,y:-93.3903},
{x:33.564,y:-101.8557},
{x:39.527,y:-84.089},
{x:33.4807,y:-112.221},
{x:34.2582,y:-119.2343},
{x:42.1052,y:-80.1434},
{x:33.7718,y:-84.5572},
{x:31.6524,y:-81.3936},
{x:25.7509,y:-80.2287},
{x:40.7431,y:-73.8545},
{x:42.1866,y:-122.67},
{x:37.6662,y:-113.063},
{x:41.4082,y:-75.6636},
{x:35.5995,y:-77.3333},
{x:33.9301,y:-84.2638},
{x:41.8119,y:-73.1026},
{x:42.0152,y:-71.5284},
{x:43.5944,y:-89.7941},
{x:35.0347,y:-85.1799},
{x:35.0248,y:-85.2452},
{x:41.934,y:-87.668},
{x:44.5456,y:-87.705},
{x:41.9168,y:-87.7358},
{x:41.7837,y:-87.6837},
{x:44.3481,y:-87.8271},
{x:42.1562,y:-88.136},
{x:36.5299,y:-83.2182},
{x:33.9464,y:-117.3808},
{x:34.3321,y:-82.3892},
{x:34.9506,y:-81.9339},
{x:40.7366,y:-74.2502},
{x:35.0761,y:-80.6504},
{x:35.2409,y:-94.6259},
{x:42.4721,y:-88.0939},
{x:43.1766,y:-83.8832},
{x:36.5016,y:-87.3723},
{x:30.2292,y:-95.1657},
{x:31.7165,y:-99.1324},
{x:38.2649,y:-76.4555},
{x:40.9306,y:-73.0334},
{x:29.6079,y:-98.492},
{x:33.1125,y:-90.052},
{x:38.2902,y:-76.4855},
{x:44.6438,y:-123.1129},
{x:48.8598,y:-99.6133},
{x:42.1574,y:-76.8259},
{x:33.6201,y:-117.7311},
{x:30.5125,y:-87.2186},
{x:34.1325,y:-109.2752},
{x:33.279,y:-96.5699},
{x:40.6336,y:-80.448},
{x:44.5134,y:-116.0399},
{x:33.8949,y:-84.5381},
{x:44.9606,y:-93.1883},
{x:29.7088,y:-95.6869},
{x:29.9009,y:-95.3556},
{x:29.8687,y:-95.3844},
{x:29.8276,y:-95.4322},
{x:40.6615,y:-73.9203},
{x:35.1763,y:-80.7997},
{x:43.7129,y:-88.99},
{x:34.2056,y:-118.2207},
{x:33.8319,y:-118.3087},
{x:27.4482,y:-82.5982},
{x:36.2871,y:-95.6339},
{x:30.3918,y:-84.2241},
{x:35.81,y:-82.6708},
{x:33.713,y:-112.1117},
{x:33.7808,y:-117.992},
{x:37.3131,y:-79.9331},
{x:42.1392,y:-71.1487},
{x:35.4976,y:-95.3111},
{x:36.1078,y:-79.2695},
{x:40.2906,y:-75.2647},
{x:40.4004,y:-79.5895},
{x:35.4373,y:-108.457},
{x:39.3379,y:-76.5509},
{x:41.5669,y:-84.1347},
{x:45.1029,y:-93.2065},
{x:46.8611,y:-101.0089},
{x:39.0096,y:-94.2006},
{x:36.163,y:-115.1565},
{x:36.2758,y:-115.1195},
{x:34.2108,y:-78.0295},
{x:34.0027,y:-83.7073},
{x:36.1663,y:-94.1192},
{x:39.6485,y:-106.589},
{x:32.2731,y:-89.9848},
{x:36.5302,y:-82.5284},
{x:42.0861,y:-86.4857},
{x:43.8181,y:-83.1835},
{x:36.9761,y:-120.0728},
{x:30.7099,y:-95.5491},
{x:28.7539,y:-82.1032},
{x:37.807,y:-88.4417},
{x:37.0565,y:-120.8357},
{x:43.6294,y:-96.941},
{x:41.937,y:-88.1026},
{x:39.2258,y:-84.8597},
{x:34.2031,y:-118.6055},
{x:30.3931,y:-81.6814},
{x:34.3887,y:-103.0439},
{x:41.8192,y:-72.5025},
{x:36.7648,y:-119.8088},
{x:37.6963,y:-121.9287},
{x:34.2628,y:-83.8164},
{x:36.0669,y:-95.8833},
{x:32.4677,y:-84.9129},
{x:41.3881,y:-81.8542},
{x:44.9014,y:-97.1053},
{x:47.6835,y:-122.1442},
{x:34.1252,y:-84.17},
{x:40.3975,y:-80.0854},
{x:42.4172,y:-83.1598},
{x:44.4739,y:-72.6973},
{x:38.2555,y:-77.4265},
{x:29.3942,y:-98.5173},
{x:36.939,y:-120.0564},
{x:41.6356,y:-85.0462},
{x:33.5172,y:-90.1524},
{x:28.0541,y:-82.397},
{x:40.4143,y:-86.8153},
{x:28.513,y:-81.1545},
{x:28.5101,y:-81.4599},
{x:28.4938,y:-81.4285},
{x:28.1985,y:-81.2933},
{x:33.3948,y:-111.9391},
{x:28.0328,y:-82.43},
{x:34.6094,y:-112.3213},
{x:36.9736,y:-82.5938},
{x:27.0794,y:-82.1551},
{x:42.5887,y:-72.6305},
{x:40.6772,y:-75.2107},
{x:26.0305,y:-80.4082},
{x:41.9751,y:-87.6554},
{x:32.1928,y:-83.1761},
{x:36.954,y:-76.5321},
{x:40.8153,y:-73.9581},
{x:43.7067,y:-116.6215},
{x:37.1301,y:-76.5392},
{x:45.3441,y:-121.9453},
{x:43.4194,y:-93.5365},
{x:43.0892,y:-85.2471},
{x:34.5254,y:-82.8427},
{x:30.4768,y:-87.2118},
{x:41.1495,y:-85.4848},
{x:39.2822,y:-121.6616},
{x:31.621,y:-81.9019},
{x:32.8073,y:-116.9503},
{x:39.4797,y:-76.2494},
{x:33.9457,y:-84.6231},
{x:38.7731,y:-77.5055},
{x:33.8195,y:-117.9587},
{x:32.2461,y:-90.3008},
{x:39.1325,y:-94.8301},
{x:47.1998,y:-122.2947},
{x:33.7348,y:-84.6692},
{x:39.7801,y:-86.0951},
{x:38.9942,y:-119.1653},
{x:37.1806,y:-93.3529},
{x:42.0693,y:-83.2516},
{x:40.821,y:-73.8911},
{x:27.2521,y:-80.3767},
{x:38.9305,y:-104.8656},
{x:35.2058,y:-89.8937},
{x:27.7685,y:-82.6369},
{x:37.6878,y:-122.1311},
{x:34.5599,y:-93.6343},
{x:36.8089,y:-119.7834},
{x:31.3452,y:-92.5207},
{x:41.2689,y:-75.8628},
{x:38.5841,y:-90.6181},
{x:47.449,y:-122.4602},
{x:43.5852,y:-89.8125},
{x:41.8742,y:-87.6262},
{x:26.5973,y:-81.9466},
{x:41.6236,y:-87.8531},
{x:41.3186,y:-74.1769},
{x:42.2412,y:-88.9736},
{x:29.6302,y:-95.811},
{x:38.9992,y:-76.9084},
{x:40.1722,y:-80.2528},
{x:45.0156,y:-93.2195},
{x:29.9536,y:-91.9997},
{x:28.0338,y:-82.4845},
{x:33.8908,y:-117.2067},
{x:32.7552,y:-117.1449},
{x:32.9353,y:-80.4794},
{x:39.6309,y:-79.9547},
{x:38.7496,y:-90.7475},
{x:40.094,y:-83.0906},
{x:29.6369,y:-90.7569},
{x:29.7141,y:-81.5091},
{x:39.8268,y:-84.0271},
{x:34.7742,y:-86.8313},
{x:27.752,y:-98.0823},
{x:35.0653,y:-106.1979},
{x:43.4033,y:-95.7216},
{x:32.7767,y:-88.6549},
{x:40.2383,y:-77.8931},
{x:34.7182,y:-77.326},
{x:41.9045,y:-71.4074},
{x:43.0817,y:-75.3221},
{x:34.0648,y:-83.1276},
{x:32.4082,y:-81.781},
{x:32.2148,y:-82.4075},
{x:26.6184,y:-81.7652},
{x:43.6166,y:-84.2457},
{x:41.947,y:-87.6879},
{x:34.5766,y:-82.7119},
{x:41.5085,y:-93.1004},
{x:36.1019,y:-79.7835},
{x:40.7149,y:-73.3817},
{x:42.5102,y:-70.8952},
{x:38.8126,y:-76.9636},
{x:32.7955,y:-95.4358},
{x:42.8838,y:-85.6852},
{x:32.5826,y:-93.2471},
{x:42.1444,y:-83.2266},
{x:40.837,y:-73.1566},
{x:34.405,y:-118.4633},
{x:38.2445,y:-122.0192},
{x:33.4371,y:-117.6248},
{x:47.2962,y:-96.5143},
{x:30.6685,y:-97.9116},
{x:40.737,y:-73.2413},
{x:38.8127,y:-77.1841},
{x:44.5677,y:-92.5849},
{x:36.8414,y:-99.6301},
{x:34.1457,y:-118.088},
{x:30.8825,y:-96.5966},
{x:42.2598,y:-90.4235},
{x:29.5737,y:-98.5451},
{x:35.0607,y:-85.067},
{x:36.7583,y:-89.5412},
{x:42.2403,y:-83.2703},
{x:40.5026,y:-74.4517},
{x:40.8209,y:-98.5998},
{x:40.176,y:-97.5758},
{x:32.4451,y:-83.7527},
{x:33.0125,y:-97.2189},
{x:40.239,y:-83.3876},
{x:36.9859,y:-119.714},
{x:43.0881,y:-87.908},
{x:39.3318,y:-82.0957},
{x:33.923,y:-78.2698},
{x:40.7164,y:-74.0339},
{x:35.2311,y:-97.4415},
{x:40.6819,y:-76.2546},
{x:34.1048,y:-80.9535},
{x:42.4225,y:-95.0074},
{x:44.9784,y:-93.2612},
{x:38.213,y:-75.7014},
{x:40.1805,y:-80.1337},
{x:42.238,y:-71.7918},
{x:33.4612,y:-112.3592},
{x:27.2987,y:-82.497},
{x:38.431,y:-121.3},
{x:39.508,y:-79.8},
{x:34.1708,y:-92.0064},
{x:32.7644,y:-117.0212},
{x:46.8265,y:-95.8666},
{x:41.7471,y:-87.838},
{x:40.1064,y:-88.2217},
{x:33.6312,y:-84.0144},
{x:30.5402,y:-95.4842},
{x:39.93,y:-105.1348},
{x:33.8722,y:-84.4555},
{x:33.366,y:-81.9945},
{x:35.1304,y:-89.7925},
{x:42.6506,y:-89.0304},
{x:41.872,y:-87.9627},
{x:38.124,y:-75.6894},
{x:34.0985,y:-117.8893},
{x:29.5231,y:-98.7998},
{x:42.2381,y:-72.2798},
{x:38.5435,y:-90.4406},
{x:42.1452,y:-88.5096},
{x:31.451,y:-98.5692},
{x:38.6914,y:-89.975},
{x:37.5051,y:-122.2582},
{x:38.891,y:-86.8401},
{x:42.3611,y:-92.7868},
{x:41.4338,y:-74.0399},
{x:43.098,y:-73.5084},
{x:40.7565,y:-73.9299},
{x:41.3941,y:-75.6517},
{x:25.7162,y:-80.3452},
{x:34.2374,y:-84.2899},
{x:36.9176,y:-84.1343},
{x:29.9753,y:-90.1762},
{x:38.9304,y:-77.0328},
{x:40.9812,y:-84.1975},
{x:34.8847,y:-117.0224},
{x:32.8202,y:-80.0653},
{x:40.6393,y:-81.3555},
{x:34.1064,y:-117.3657},
{x:41.441,y:-87.3629},
{x:45.0792,y:-93.146},
{x:32.1337,y:-110.9982},
{x:40.1194,y:-111.6542},
{x:32.8939,y:-96.7},
{x:38.6448,y:-79.3303},
{x:41.9493,y:-88.0337},
{x:42.9984,y:-84.1463},
{x:42.6284,y:-82.9143},
{x:40.5856,y:-86.6805},
{x:38.5494,y:-90.3358},
{x:36.3632,y:-77.6703},
{x:45.687,y:-122.6666},
{x:33.9179,y:-86.0219},
{x:42.58,y:-71.0019},
{x:29.4655,y:-95.094},
{x:43.9993,y:-75.9181},
{x:43.014,y:-87.9676},
{x:42.9165,y:-71.3758},
{x:37.6474,y:-84.774},
{x:35.5896,y:-80.8553},
{x:35.1375,y:-80.7344},
{x:32.7725,y:-86.8821},
{x:33.9868,y:-85.8674},
{x:34.6314,y:-85.7437},
{x:27.2084,y:-81.8306},
{x:33.6978,y:-86.6048},
{x:41.1835,y:-80.7648},
{x:45.9971,y:-91.3863},
{x:42.6165,y:-70.6682},
{x:38.1626,y:-85.8332},
{x:18.3823,y:-66.1431},
{x:39.1628,y:-87.1901},
{x:42.2245,y:-89.0725},
{x:40.4161,y:-86.5949},
{x:29.9141,y:-90.2052},
{x:41.515,y:-94.6433},
{x:47.7415,y:-117.4033},
{x:41.6484,y:-72.6794},
{x:41.4311,y:-87.9948},
{x:43.6239,y:-87.7984},
{x:30.5284,y:-90.9555},
{x:37.7248,y:-122.157},
{x:30.0634,y:-81.5053},
{x:41.4199,y:-73.283},
{x:33.7228,y:-117.9884},
{x:21.3192,y:-158.0153},
{x:29.7618,y:-94.977},
{x:33.5114,y:-81.8699},
{x:41.1048,y:-80.6504},
{x:33.7314,y:-84.5689},
{x:28.2371,y:-80.7264},
{x:32.2568,y:-90.4198},
{x:39.4264,y:-76.7795},
{x:35.8299,y:-78.7681},
{x:32.9456,y:-96.6805},
{x:41.4592,y:-81.7012},
{x:33.6945,y:-117.9551},
{x:34.8516,y:-82.4521},
{x:41.4779,y:-81.811},
{x:36.602,y:-82.1951},
{x:35.5722,y:-82.9565},
{x:32.6483,y:-116.9665},
{x:37.7741,y:-121.9235},
{x:42.1389,y:-87.9618},
{x:41.752,y:-87.6053},
{x:41.7503,y:-87.644},
{x:34.4208,y:-84.1174},
{x:39.8292,y:-105.0245},
{x:41.0014,y:-111.9066},
{x:42.3197,y:-88.9694},
{x:39.8823,y:-105.027},
{x:26.1366,y:-80.1816},
{x:39.1585,y:-122.1393},
{x:38.2015,y:-84.8316},
{x:21.4772,y:-157.8689},
{x:30.0213,y:-95.4274},
{x:36.0464,y:-86.7136},
{x:40.3992,y:-80.0445},
{x:40.4463,y:-79.8939},
{x:36.1948,y:-86.7429},
{x:41.8341,y:-87.8661},
{x:29.6895,y:-95.4571},
{x:41.8623,y:-80.7999},
{x:21.3364,y:-157.8691},
{x:39.225,y:-94.5768},
{x:41.4626,y:-81.4618},
{x:38.9296,y:-94.7252},
{x:43.172,y:-89.7969},
{x:43.0144,y:-89.4571},
{x:39.8315,y:-84.6304},
{x:31.3132,y:-85.7123},
{x:47.3011,y:-122.242},
{x:33.1109,y:-96.7659},
{x:33.0641,y:-96.7975},
{x:38.7424,y:-82.9568},
{x:40.3002,y:-84.1582},
{x:39.4164,y:-87.6929},
{x:43.1309,y:-82.7974},
{x:43.0487,y:-71.4597},
{x:42.0875,y:-88.5314},
{x:29.5485,y:-98.5756},
{x:38.3686,y:-85.1807},
{x:27.5338,y:-82.5038},
{x:27.4962,y:-82.6118},
{x:44.0785,y:-123.0414},
{x:40.2254,y:-84.4657},
{x:30.2705,y:-98.3975},
{x:27.3898,y:-82.5075},
{x:33.8188,y:-84.8133},
{x:41.2615,y:-96.1776},
{x:33.8517,y:-84.3583},
{x:28.5475,y:-81.3874},
{x:28.6371,y:-81.3983},
{x:41.5442,y:-87.793},
{x:41.5419,y:-72.6524},
{x:40.6353,y:-80.228},
{x:41.7713,y:-72.9703},
{x:40.6992,y:-80.1042},
{x:42.416,y:-73.6822},
{x:32.9191,y:-117.1212},
{x:42.4022,y:-71.0438},
{x:37.568,y:-84.3129},
{x:38.9581,y:-77.0021},
{x:32.7691,y:-117.0265},
{x:45.6706,y:-111.1847},
{x:36.7906,y:-85.3701},
{x:40.3811,y:-79.8704},
{x:33.8666,y:-84.1861},
{x:34.2386,y:-118.5717},
{x:39.4305,y:-92.7967},
{x:36.7032,y:-93.125},
{x:38.9943,y:-110.1837},
{x:41.4425,y:-71.4896},
{x:32.4504,y:-100.4006},
{x:44.5847,y:-85.5359},
{x:37.1432,y:-94.5117},
{x:25.9456,y:-80.1643},
{x:42.7905,y:-73.9795},
{x:37.6893,y:-121.032},
{x:25.7134,y:-80.4482},
{x:37.9321,y:-75.3621},
{x:37.4143,y:-79.2087},
{x:47.3822,y:-122.2311},
{x:35.8018,y:-83.944},
{x:32.4865,y:-92.1546},
{x:44.4561,y:-88.9263},
{x:36.2723,y:-98.4801},
{x:34.6821,y:-99.9145},
{x:41.6013,y:-87.6175},
{x:43.5389,y:-96.6532},
{x:34.4405,y:-77.8797},
{x:43.0016,y:-84.7396},
{x:31.6359,y:-96.272},
{x:42.4658,y:-73.8162},
{x:35.2895,y:-84.7573},
{x:35.2135,y:-84.8771},
{x:42.1981,y:-83.1802},
{x:33.5247,y:-86.8107},
{x:37.4284,y:-121.9081},
{x:32.3595,y:-96.6333},
{x:37.4335,y:-122.1291},
{x:26.2048,y:-80.1563},
{x:32.9381,y:-117.029},
{x:41.0428,y:-81.5608},
{x:34.7361,y:-86.6472},
{x:45.5128,y:-122.6854},
{x:41.7492,y:-87.9998},
{x:18.3027,y:-66.2346},
{x:18.3421,y:-66.31},
{x:40.7819,y:-124.1801},
{x:40.0542,y:-78.5107},
{x:45.6848,y:-122.5324},
{x:42.0621,y:-88.1441},
{x:34.0515,y:-118.2804},
{x:26.598,y:-80.0914},
{x:26.2566,y:-80.1021},
{x:35.4815,y:-84.5932},
{x:33.4544,y:-84.1666},
{x:38.902,y:-77.0382},
{x:31.3039,y:-85.4899},
{x:35.9006,y:-79.9496},
{x:35.7965,y:-78.4911},
{x:41.2381,y:-81.8778},
{x:33.8475,y:-117.9584},
{x:33.7514,y:-84.3227},
{x:38.9548,y:-119.9441},
{x:28.4764,y:-82.4886},
{x:40.693,y:-73.9699},
{x:40.4266,y:-83.2969},
{x:30.006,y:-90.1955},
{x:29.3511,y:-98.4372},
{x:26.6872,y:-81.8647},
{x:32.5758,y:-96.8236},
{x:42.5365,y:-98.9786},
{x:39.313,y:-84.315},
{x:39.6305,y:-84.2124},
{x:42.3477,y:-88.8498},
{x:38.432,y:-122.7436},
{x:32.3576,y:-88.6857},
{x:38.0675,y:-122.5434},
{x:32.4163,y:-96.659},
{x:42.8923,y:-83.6424},
{x:43.0635,y:-83.8587},
{x:42.8725,y:-87.9794},
{x:40.156,y:-83.0946},
{x:33.1555,y:-86.7508},
{x:39.5243,y:-119.7391},
{x:26.1873,y:-80.116},
{x:35.0175,y:-85.2792},
{x:42.4618,y:-83.8314},
{x:41.7056,y:-87.6429},
{x:38.877,y:-77.0037},
{x:38.5844,y:-89.9071},
{x:39.998,y:-83.0072},
{x:40.0591,y:-82.9447},
{x:40.7984,y:-96.7478},
{x:38.5418,y:-90.0139},
{x:40.825,y:-73.5884},
{x:34.5743,y:-92.5914},
{x:41.1369,y:-80.0096},
{x:38.7336,y:-77.7718},
{x:34.2329,y:-118.2548},
{x:30.8589,y:-83.2821},
{x:28.6682,y:-82.1379},
{x:39.3694,y:-76.7993},
{x:39.648,y:-84.1099},
{x:39.7047,y:-84.6982},
{x:39.1598,y:-76.6817},
{x:39.1033,y:-84.5304},
{x:18.4086,y:-66.1052},
{x:39.3033,y:-76.955},
{x:44.048,y:-96.607},
{x:39.3354,y:-78.7465},
{x:40.3349,y:-79.6104},
{x:39.6101,y:-94.5962},
{x:35.7569,y:-84.0333},
{x:34.0341,y:-117.6913},
{x:33.5081,y:-82.1409},
{x:42.0386,y:-78.3407},
{x:36.3463,y:-82.2465},
{x:41.6004,y:-73.4064},
{x:34.1385,y:-117.3719},
{x:34.7289,y:-76.6389},
{x:30.7515,y:-99.2371},
{x:42.1643,y:-71.043},
{x:35.4232,y:-79.1208},
{x:35.5558,y:-80.414},
{x:35.2845,y:-80.67},
{x:32.4535,y:-93.8628},
{x:41.5809,y:-91.2604},
{x:35.1464,y:-90.1979},
{x:37.5468,y:-77.4533},
{x:26.3045,y:-80.2767},
{x:38.8564,y:-76.9059},
{x:39.4044,y:-81.4194},
{x:33.2224,y:-96.962},
{x:41.3434,y:-75.3117},
{x:41.7225,y:-87.5764},
{x:40.8783,y:-97.5935},
{x:34.1386,y:-117.44},
{x:41.3472,y:-72.0757},
{x:41.4801,y:-72.1043},
{x:40.2473,y:-77.0271},
{x:42.1616,y:-78.7436},
{x:43.1779,y:-85.2738},
{x:28.0251,y:-82.1871},
{x:41.6287,y:-87.4802},
{x:34.2801,y:-85.1259},
{x:34.1749,y:-85.2082},
{x:34.8886,y:-85.0736},
{x:35.8156,y:-81.3053},
{x:30.4101,y:-91.0776},
{x:33.6438,y:-117.9236},
{x:30.3946,y:-92.2083},
{x:21.3891,y:-157.9099},
{x:61.2586,y:-149.6901},
{x:41.6007,y:-87.6912},
{x:40.6304,y:-73.9216},
{x:41.7701,y:-72.3052},
{x:42.8366,y:-73.9348},
{x:35.5198,y:-84.3635},
{x:35.4527,y:-84.6309},
{x:40.889,y:-121.653},
{x:36.6646,y:-121.6293},
{x:33.0554,y:-97.0141},
{x:39.1525,y:-77.9835},
{x:28.1727,y:-82.7517},
{x:27.9399,y:-82.73},
{x:37.0492,y:-88.5644},
{x:44.3897,y:-68.2048},
{x:39.058,y:-84.6267},
{x:42.1154,y:-75.9476},
{x:39.9702,y:-105.0867},
{x:38.5823,y:-121.5216},
{x:38.1275,y:-78.4397},
{x:38.9328,y:-76.9912},
{x:35.4512,y:-94.8038},
{x:39.3012,y:-84.5635},
{x:44.3481,y:-69.7962},
{x:41.9805,y:-71.5142},
{x:40.7404,y:-89.574},
{x:28.0697,y:-82.632},
{x:34.3151,y:-84.5511},
{x:42.5463,y:-83.172},
{x:44.8336,y:-92.9553},
{x:31.7698,y:-106.4325},
{x:35.7129,y:-83.5132},
{x:29.0199,y:-95.4211},
{x:41.8815,y:-87.6648},
{x:34.2364,y:-96.6798},
{x:41.6888,y:-88.383},
{x:36.0083,y:-84.253},
{x:41.8783,y:-87.6257},
{x:33.1625,y:-111.5636},
{x:33.8406,y:-84.1326},
{x:34.4699,y:-83.9662},
{x:32.604,y:-117.0395},
{x:41.7865,y:-74.728},
{x:32.689,y:-117.0452},
{x:33.6309,y:-84.3861},
{x:39.0282,y:-96.8337},
{x:45.7093,y:-111.0675},
{x:29.9442,y:-95.3324},
{x:29.9709,y:-95.3207},
{x:37.7226,y:-122.482},
{x:41.5834,y:-81.5398},
{x:42.2672,y:-89.0786},
{x:29.5184,y:-98.4964},
{x:38.0287,y:-122.2656},
{x:37.4029,y:-102.616},
{x:29.8799,y:-95.6435},
{x:30.8446,y:-90.1582},
{x:29.4492,y:-98.6854},
{x:27.9281,y:-82.7872},
{x:33.6109,y:-112.36},
{x:40.1186,y:-83.0165},
{x:32.7742,y:-97.0632},
{x:33.4657,y:-117.7032},
{x:39.0859,y:-76.8635},
{x:35.0881,y:-89.8914},
{x:38.6175,y:-94.6611},
{x:40.048,y:-74.8426},
{x:34.4726,y:-117.1929},
{x:31.2082,y:-82.3826},
{x:30.7612,y:-81.606},
{x:39.8775,y:-86.4738},
{x:45.5063,y:-122.9568},
{x:37.9814,y:-101.754},
{x:30.3681,y:-91.0765},
{x:30.4032,y:-88.9739},
{x:38.5325,y:-90.4933},
{x:30.451,y:-91.188},
{x:33.749,y:-116.9576},
{x:31.5698,y:-91.436},
{x:30.386,y:-91.0656},
{x:33.9964,y:-84.7046},
{x:21.9779,y:-159.3682},
{x:30.4291,y:-81.718},
{x:29.6359,y:-98.4522},
{x:40.6901,y:-75.2323},
{x:34.0488,y:-84.61},
{x:39.4412,y:-77.4078},
{x:31.1392,y:-87.0694},
{x:27.3472,y:-82.5307},
{x:37.2138,y:-76.5815},
{x:33.759,y:-117.9892},
{x:38.0797,y:-83.9502},
{x:43.0361,y:-89.454},
{x:40.6516,y:-74.0039},
{x:40.8475,y:-73.8572},
{x:33.3793,y:-112.033},
{x:38.1997,y:-83.4816},
{x:40.8677,y:-73.8992},
{x:43.138,y:-77.6704},
{x:43.2479,y:-73.8345},
{x:40.7277,y:-76.1903},
{x:35.7048,y:-114.4779},
{x:35.1573,y:-90.0475},
{x:33.4211,y:-112.2248},
{x:34.0021,y:-86.0045},
{x:41.1582,y:-81.2616},
{x:41.7098,y:-70.0238},
{x:40.6099,y:-74.0275},
{x:39.8209,y:-84.0313},
{x:37.4013,y:-79.1686},
{x:43.0122,y:-87.9225},
{x:29.6814,y:-95.1499},
{x:27.3479,y:-82.5132},
{x:38.3237,y:-85.8017},
{x:30.6581,y:-98.4394},
{x:34.5219,y:-85.316},
{x:41.2781,y:-72.8679},
{x:34.9785,y:-85.4139},
{x:38.8792,y:-97.612},
{x:43.5765,y:-84.7674},
{x:29.6849,y:-95.341},
{x:40.7034,y:-73.9183},
{x:32.2365,y:-110.9428},
{x:39.1606,y:-76.8922},
{x:40.2734,y:-83.0692},
{x:42.9921,y:-71.4638},
{x:37.3839,y:-93.1539},
{x:30.0943,y:-93.7612},
{x:48.2825,y:-117.7159},
{x:42.0085,y:-88.0799},
{x:43.0357,y:-71.0693},
{x:35.624,y:-86.6933},
{x:40.0461,y:-86.8211},
{x:41.7717,y:-91.1282},
{x:41.0519,y:-73.7707},
{x:42.7328,y:-90.4752},
{x:27.7253,y:-82.4341},
{x:42.2584,y:-88.152},
{x:47.4573,y:-99.1194},
{x:37.3204,y:-80.6322},
{x:41.3231,y:-72.3353},
{x:33.5863,y:-117.6599},
{x:41.3841,y:-83.3197},
{x:38.5804,y:-97.6662},
{x:37.4862,y:-97.2439},
{x:37.4367,y:-76.3195},
{x:37.5987,y:-77.6198},
{x:33.8327,y:-118.0291},
{x:44.5576,y:-75.2193},
{x:27.7776,y:-82.6791},
{x:42.4345,y:-83.3357},
{x:29.654,y:-95.2767},
{x:39.9454,y:-82.5362},
{x:42.835,y:-88.7518},
{x:39.8017,y:-104.9765},
{x:41.5152,y:-72.8047},
{x:34.1064,y:-117.9334},
{x:31.0211,y:-96.4933},
{x:29.8074,y:-95.303},
{x:33.9395,y:-117.2806},
{x:34.1922,y:-117.3613},
{x:33.7506,y:-117.0069},
{x:36.1143,y:-115.0652},
{x:28.8051,y:-81.3306},
{x:42.2001,y:-83.155},
{x:36.1795,y:-115.2416},
{x:44.884,y:-84.291},
{x:36.1043,y:-95.835},
{x:37.9276,y:-81.1534},
{x:31.3254,y:-92.4267},
{x:39.0367,y:-84.5328},
{x:41.0521,y:-92.4105},
{x:41.781,y:-88.2818},
{x:42.7279,y:-73.8437},
{x:26.7613,y:-81.4368},
{x:34.106,y:-117.8059},
{x:36.9035,y:-76.1362},
{x:44.1964,y:-87.7281},
{x:26.434,y:-81.4361},
{x:34.2729,y:-118.6771},
{x:30.8029,y:-88.1088},
{x:35.9727,y:-79.7115},
{x:35.7053,y:-82.5662},
{x:33.7735,y:-117.8843},
{x:38.3603,y:-77.5203},
{x:39.7995,y:-91.5228},
{x:47.3816,y:-122.2549},
{x:30.2625,y:-82.1088},
{x:39.9772,y:-82.8441},
{x:39.7204,y:-76.3424},
{x:35.4371,y:-89.3331},
{x:29.4257,y:-98.4051},
{x:34.0695,y:-118.0218},
{x:40.0076,y:-74.9804},
{x:33.4225,y:-112.272},
{x:37.2868,y:-107.8767},
{x:38.9274,y:-104.722},
{x:46.374,y:-120.3212},
{x:39.4885,y:-84.3274},
{x:42.8003,y:-83.1334},
{x:29.603,y:-95.2481},
{x:38.0536,y:-103.7179},
{x:28.6611,y:-81.1798},
{x:41.9551,y:-88.7219},
{x:33.9165,y:-117.9609},
{x:35.2052,y:-89.7932},
{x:29.5238,y:-98.5987},
{x:39.729,y:-75.0357},
{x:31.2677,y:-92.4873},
{x:33.7296,y:-116.972},
{x:44.8081,y:-72.2048},
{x:37.8722,y:-84.5965},
{x:33.4792,y:-86.7024},
{x:39.0947,y:-94.5405},
{x:33.7888,y:-117.9606},
{x:39.8373,y:-89.5793},
{x:32.7082,y:-97.1498},
{x:29.775,y:-95.3161},
{x:37.0111,y:-86.3885},
{x:37.2898,y:-87.1183},
{x:38.8694,y:-76.8927},
{x:36.2925,y:-95.1475},
{x:61.1926,y:-149.8691},
{x:37.9259,y:-121.7378},
{x:43.2053,y:-71.5362},
{x:34.7243,y:-92.1228},
{x:38.4328,y:-82.6941},
{x:35.7614,y:-81.334},
{x:33.838,y:-118.3677},
{x:27.3907,y:-82.5595},
{x:38.1915,y:-120.8259},
{x:38.645,y:-121.4751},
{x:44.2439,y:-94.9771},
{x:46.5061,y:-94.2958},
{x:27.9996,y:-80.6752},
{x:26.2435,y:-81.6893},
{x:40.2254,y:-74.0773},
{x:42.9077,y:-85.7916},
{x:25.8,y:-80.1894},
{x:41.5009,y:-81.6783},
{x:35.0553,y:-80.8516},
{x:25.984,y:-80.1879},
{x:35.2692,y:-120.6707},
{x:39.892,y:-75.0847},
{x:41.777,y:-70.7461},
{x:34.9608,y:-120.4362},
{x:18.0121,y:-66.3834},
{x:33.3528,y:-84.1168},
{x:37.7822,y:-122.4006},
{x:41.3883,y:-81.8233},
{x:34.1944,y:-79.7599},
{x:35.0833,y:-87.4318},
{x:30.1094,y:-91.9911},
{x:36.0465,y:-86.6763},
{x:36.0601,y:-88.9871},
{x:32.3392,y:-89.1392},
{x:38.5515,y:-121.5361},
{x:35.729,y:-80.6936},
{x:43.0364,y:-85.694},
{x:39.0348,y:-94.535},
{x:43.3908,y:-124.2435},
{x:31.4978,y:-97.1579},
{x:26.9469,y:-81.9989},
{x:41.3074,y:-105.6152},
{x:32.3306,y:-86.2997},
{x:44.303,y:-88.5402},
{x:28.6739,y:-81.4715},
{x:60.4844,y:-151.152},
{x:30.0469,y:-81.8079},
{x:35.3866,y:-86.236},
{x:42.3511,y:-71.4979},
{x:32.4962,y:-82.9815},
{x:30.0886,y:-81.9459},
{x:26.7033,y:-80.1129},
{x:39.4269,y:-77.4158},
{x:37.1702,y:-101.3404},
{x:40.8343,y:-73.8521},
{x:32.9064,y:-117.1736},
{x:38.048,y:-77.3585},
{x:41.1453,y:-104.8601},
{x:37.2634,y:-80.003},
{x:43.4261,y:-88.1423},
{x:39.2854,y:-76.4977},
{x:39.6316,y:-79.9841},
{x:38.7246,y:-77.1513},
{x:30.9873,y:-92.0534},
{x:30.1233,y:-91.8294},
{x:36.0915,y:-89.8013},
{x:34.0801,y:-117.2936},
{x:31.4437,y:-85.651},
{x:35.0631,y:-94.2451},
{x:40.2776,y:-78.8469},
{x:44.1829,y:-84.0513},
{x:30.3858,y:-91.0383},
{x:32.547,y:-83.6926},
{x:39.1444,y:-76.8982},
{x:40.9058,y:-74.5637},
{x:36.839,y:-87.48},
{x:25.7846,y:-80.3348},
{x:33.9039,y:-86.6207},
{x:26.1901,y:-81.8002},
{x:33.4069,y:-84.1672},
{x:29.4928,y:-98.4339},
{x:31.6586,y:-97.4691},
{x:47.7586,y:-122.6589},
{x:30.5172,y:-89.6724},
{x:36.7672,y:-76.2295},
{x:38.0837,y:-122.2101},
{x:30.4138,y:-89.192},
{x:39.611,y:-75.95},
{x:33.2474,y:-96.7858},
{x:37.7174,y:-89.204},
{x:32.1964,y:-96.9005},
{x:32.3106,y:-96.5991},
{x:38.3087,y:-104.6215},
{x:28.4786,y:-81.5835},
{x:42.2757,y:-85.4284},
{x:41.6797,y:-72.5471},
{x:30.6249,y:-89.1323},
{x:41.9223,y:-77.5314},
{x:41.3146,y:-81.6868},
{x:42.039,y:-87.871},
{x:40.3068,y:-88.1556},
{x:33.5994,y:-117.176},
{x:30.4124,y:-90.0814},
{x:42.1849,y:-112.2397},
{x:35.5959,y:-89.0552},
{x:41.9686,y:-87.8077},
{x:40.7562,y:-73.5882},
{x:27.8657,y:-82.7883},
{x:41.8379,y:-87.6456},
{x:35.6132,y:-80.4425},
{x:41.9796,y:-71.3289},
{x:39.2166,y:-76.6763},
{x:45.2494,y:-93.3056},
{x:48.0796,y:-122.1752},
{x:43.9179,y:-123.0065},
{x:30.6847,y:-90.1229},
{x:31.9456,y:-86.3143},
{x:38.9079,y:-77.0037},
{x:30.1135,y:-81.5889},
{x:41.0708,y:-81.5063},
{x:37.2334,y:-121.9786},
{x:38.4231,y:-82.4215},
{x:40.702,y:-89.5922},
{x:40.7952,y:-89.6306},
{x:61.1728,y:-149.8874},
{x:40.0579,y:-84.2375},
{x:39.2411,y:-94.5225},
{x:33.9567,y:-84.1118},
{x:40.5973,y:-73.985},
{x:27.894,y:-82.6847},
{x:43.6131,y:-111.7747},
{x:42.3587,y:-83.0582},
{x:32.5912,y:-96.6805},
{x:44.721,y:-92.8503},
{x:32.6981,y:-96.4322},
{x:32.6631,y:-97.4211},
{x:33.3791,y:-112.1018},
{x:42.3263,y:-83.2946},
{x:34.506,y:-96.9909},
{x:38.1557,y:-85.7132},
{x:32.1786,y:-110.9176},
{x:32.1908,y:-110.8574},
{x:32.3257,y:-111.0453},
{x:34.4229,y:-119.289},
{x:40.2944,y:-83.0387},
{x:32.0741,y:-96.4431},
{x:41.52,y:-81.4596},
{x:32.4826,y:-110.9206},
{x:31.7001,y:-94.6759},
{x:31.4676,y:-97.1994},
{x:39.7908,y:-105.0776},
{x:42.0457,y:-88.0388},
{x:36.128,y:-80.0691},
{x:32.7223,y:-117.2453},
{x:18.3358,y:-66.4687},
{x:18.4312,y:-66.474},
{x:18.4078,y:-66.0595},
{x:47.6358,y:-122.5175},
{x:34.8253,y:-84.7631},
{x:21.3341,y:-157.8804},
{x:33.4332,y:-112.5578},
{x:42.0891,y:-75.9149},
{x:25.7605,y:-80.3898},
{x:37.9992,y:-87.5549},
{x:29.8591,y:-95.721},
{x:34.0557,y:-117.8211},
{x:33.9293,y:-116.9499},
{x:36.2412,y:-90.9593},
{x:42.7702,y:-114.7035},
{x:33.9448,y:-116.5007},
{x:42.0973,y:-79.2998},
{x:41.7885,y:-71.5186},
{x:41.7682,y:-72.6821},
{x:38.9196,y:-77.0711},
{x:42.5996,y:-82.8975},
{x:42.8867,y:-86.1198},
{x:32.5465,y:-86.4571},
{x:43.0622,y:-94.2267},
{x:41.7719,y:-71.4113},
{x:37.8729,y:-87.5693},
{x:30.068,y:-91.8867},
{x:36.1154,y:-80.3117},
{x:30.3134,y:-86.1147},
{x:45.3999,y:-93.2337},
{x:32.7193,y:-102.6473},
{x:42.139,y:-88.015},
{x:40.0623,y:-77.4969},
{x:33.971,y:-83.9961},
{x:38.781,y:-77.2355},
{x:42.4315,y:-89.0193},
{x:36.7707,y:-76.5863},
{x:41.6053,y:-72.8991},
{x:43.7774,y:-75.4832},
{x:38.3528,y:-81.6857},
{x:40.8024,y:-73.2131},
{x:35.1176,y:-101.907},
{x:38.4943,y:-121.5211},
{x:35.7777,y:-95.4026},
{x:42.1139,y:-80.0337},
{x:40.3965,y:-91.3387},
{x:18.0489,y:-65.8823},
{x:32.3287,y:-95.2667},
{x:41.1596,y:-95.9456},
{x:43.1335,y:-72.4434},
{x:44.331,y:-75.4759},
{x:41.722,y:-85.9785},
{x:33.9029,y:-118.3187},
{x:40.1312,y:-82.8851},
{x:36.0401,y:-79.8016},
{x:35.242,y:-106.6887},
{x:42.3539,y:-85.259},
{x:28.5451,y:-81.7079},
{x:40.8897,y:-84.5814},
{x:36.1098,y:-80.1024},
{x:26.6966,y:-80.6837},
{x:47.4882,y:-122.3343},
{x:29.6959,y:-95.6439},
{x:40.0837,y:-88.296},
{x:35.8314,y:-96.3907},
{x:29.591,y:-95.5995},
{x:42.7431,y:-71.1649},
{x:33.4151,y:-111.5736},
{x:33.566,y:-83.48},
{x:33.2954,y:-83.3878},
{x:33.9935,y:-83.3357},
{x:45.6855,y:-122.6015},
{x:39.6995,y:-78.1845},
{x:36.7158,y:-76.1882},
{x:43.0872,y:-88.2277},
{x:42.0114,y:-87.7688},
{x:33.8684,y:-117.8258},
{x:41.6039,y:-72.7045},
{x:39.2306,y:-83.6242},
{x:45.9132,y:-89.487},
{x:42.5214,y:-83.7591},
{x:41.5044,y:-81.6111},
{x:41.1779,y:-90.322},
{x:32.3887,y:-93.8185},
{x:41.8967,y:-92.2804},
{x:31.0756,y:-97.6086},
{x:41.1114,y:-89.3667},
{x:30.5423,y:-97.5567},
{x:38.1185,y:-86.9242},
{x:37.9557,y:-122.3362},
{x:39.8442,y:-75.0919},
{x:37.7975,y:-83.7015},
{x:39.8962,y:-74.9154},
{x:34.0891,y:-88.6224},
{x:31.9987,y:-81.1269},
{x:38.7889,y:-75.3139},
{x:40.0001,y:-85.7315},
{x:34.6316,y:-118.2203},
{x:32.7974,y:-96.6122},
{x:33.8737,y:-117.8873},
{x:39.2877,y:-76.7642},
{x:38.9262,y:-76.8854},
{x:39.9981,y:-79.4474},
{x:40.4057,y:-77.0096},
{x:36.8566,y:-89.5854},
{x:37.8364,y:-92.0997},
{x:41.7085,y:-86.87},
{x:40.4392,y:-80.0713},
{x:36.6496,y:-94.4241},
{x:40.0703,y:-105.2017},
{x:36.6564,y:-79.8785},
{x:38.4897,y:-121.8042},
{x:42.7031,y:-74.3097},
{x:35.7987,y:-117.8719},
{x:38.7968,y:-121.2924},
{x:42.2127,y:-85.8913},
{x:42.2957,y:-71.7769},
{x:38.9397,y:-77.5488},
{x:42.5704,y:-71.1126},
{x:34.733,y:-78.7915},
{x:34.1206,y:-86.3959},
{x:30.3092,y:-104.0203},
{x:34.1063,y:-117.4344},
{x:44.0774,y:-92.5115},
{x:33.4465,y:-94.1064},
{x:31.3243,y:-89.3341},
{x:42.4987,y:-83.3559},
{x:33.9106,y:-84.822},
{x:18.4466,y:-66.3957},
{x:40.6556,y:-73.6121},
{x:45.4956,y:-94.148},
{x:33.1721,y:-96.7334},
{x:40.5183,y:-78.3725},
{x:37.0541,y:-88.6445},
{x:35.4867,y:-93.8423},
{x:41.5196,y:-71.293},
{x:41.9007,y:-88.7389},
{x:41.5789,y:-87.6002},
{x:42.2532,y:-87.9003},
{x:36.8606,y:-76.4661},
{x:29.6013,y:-98.3876},
{x:36.1641,y:-80.1364},
{x:26.5469,y:-81.7869},
{x:40.14,y:-82.9917},
{x:32.6708,y:-97.3762},
{x:35.1667,y:-84.8818},
{x:40.3906,y:-79.7665},
{x:34.993,y:-118.9497},
{x:25.4984,y:-80.4139},
{x:35.9968,y:-86.6899},
{x:36.1078,y:-80.0994},
{x:39.1837,y:-76.7703},
{x:39.8238,y:-76.9753},
{x:33.9902,y:-117.9229},
{x:35.2657,y:-80.8821},
{x:42.7352,y:-78.9177},
{x:40.1606,y:-104.9403},
{x:43.223,y:-78.0249},
{x:35.3787,y:-78.5406},
{x:39.8084,y:-74.9283},
{x:42.1062,y:-72.7083},
{x:33.2678,y:-93.2258},
{x:39.8642,y:-75.341},
{x:42.1627,y:-83.4029},
{x:39.6384,y:-78.8484},
{x:40.6859,y:-73.8243},
{x:57.8115,y:-152.3651},
{x:40.7981,y:-81.6011},
{x:37.7016,y:-75.7142},
{x:27.9388,y:-82.2635},
{x:40.1113,y:-87.7782},
{x:33.4383,y:-86.8662},
{x:31.2771,y:-92.4582},
{x:41.6751,y:-83.6819},
{x:41.9474,y:-91.7183},
{x:32.8215,y:-117.1834},
{x:38.9668,y:-84.6736},
{x:34.0124,y:-85.9875},
{x:34.1495,y:-86.8417},
{x:34.6385,y:-86.8433},
{x:34.7042,y:-86.5886},
{x:39.9375,y:-75.0449},
{x:38.6606,y:-120.9675},
{x:40.5218,y:-122.3835},
{x:32.3934,y:-104.2226},
{x:47.2359,y:-93.5203},
{x:33.9431,y:-78.0327},
{x:42.7658,y:-84.5217},
{x:41.7113,y:-72.8084},
{x:44.6994,y:-73.4875},
{x:34.0796,y:-117.9073},
{x:39.4889,y:-75.048},
{x:27.2221,y:-80.8276},
{x:45.6795,y:-111.0539},
{x:40.158,y:-111.6444},
{x:44.8836,y:-91.4219},
{x:39.9775,y:-76.6717},
{x:40.0863,y:-74.9678},
{x:64.8569,y:-147.6914},
{x:33.6401,y:-112.187},
{x:36.5571,y:-82.6029},
{x:55.3758,y:-131.7199},
{x:35.8089,y:-80.374},
{x:40.9319,y:-72.7087},
{x:33.5944,y:-112.2211},
{x:33.5516,y:-86.7828},
{x:45.8038,y:-108.4768},
{x:38.5615,y:-121.7693},
{x:37.0616,y:-93.2991},
{x:30.89,y:-84.5707},
{x:37.3183,y:-120.491},
{x:41.3429,y:-82.0696},
{x:33.9395,y:-117.453},
{x:42.0963,y:-75.98},
{x:37.2378,y:-81.2543},
{x:36.5966,y:-79.4297},
{x:42.1734,y:-72.5767},
{x:39.9073,y:-75.2913},
{x:43.0131,y:-78.2089},
{x:27.5427,y:-99.4844},
{x:39.4663,y:-75.2083},
{x:30.1873,y:-92.0785},
{x:39.7695,y:-104.8973},
{x:42.2509,y:-71.9456},
{x:36.42,y:-79.9358},
{x:43.0356,y:-86.2167},
{x:33.4578,y:-86.9616},
{x:30.3055,y:-89.8259},
{x:39.5137,y:-104.7676},
{x:35.321,y:-78.6479},
{x:26.2362,y:-98.3611},
{x:41.9796,y:-78.6169},
{x:35.2947,y:-119.0551},
{x:40.9382,y:-76.0399},
{x:38.1996,y:-85.6677},
{x:43.0502,y:-89.5012},
{x:42.1975,y:-85.5944},
{x:36.0669,y:-79.2731},
{x:29.7143,y:-98.1611},
{x:42.2612,y:-85.1767},
{x:39.6121,y:-75.504},
{x:43.1177,y:-89.3243},
{x:42.4653,y:-73.1994},
{x:40.1647,y:-83.0205},
{x:32.7431,y:-103.1626},
{x:47.3999,y:-92.9625},
{x:42.9737,y:-83.6797},
{x:47.5827,y:-122.1414},
{x:44.4328,y:-95.7669},
{x:34.761,y:-79.483},
{x:37.3479,y:-79.1904},
{x:41.7979,y:-85.4284},
{x:32.8503,y:-117.2541},
{x:33.7747,y:-84.2737},
{x:29.4463,y:-98.3604},
{x:34.8597,y:-83.4047},
{x:39.9873,y:-105.125},
{x:44.0466,y:-122.9298},
{x:39.2244,y:-94.5483},
{x:41.784,y:-85.4249},
{x:36.2758,y:-80.8324},
{x:40.4007,y:-80.5488},
{x:39.893,y:-80.1318},
{x:33.9322,y:-117.9326},
{x:35.1233,y:-78.8826},
{x:26.4391,y:-80.0807},
{x:40.3258,y:-78.9196},
{x:42.1709,y:-71.8789},
{x:41.5556,y:-85.7894},
{x:42.3151,y:-89.112},
{x:36.2218,y:-81.6509},
{x:31.5088,y:-106.1503},
{x:26.177,y:-98.0401},
{x:42.3545,y:-72.5531},
{x:44.8917,y:-88.3083},
{x:26.4359,y:-81.7758},
{x:38.7498,y:-112.0958},
{x:41.6977,y:-72.8973},
{x:34.6755,y:-118.1143},
{x:42.802,y:-77.7881},
{x:32.965,y:-96.4627},
{x:41.8139,y:-86.2485},
{x:29.3281,y:-96.1226},
{x:42.5815,y:-84.8064},
{x:18.4801,y:-66.7715},
{x:42.4057,y:-86.2713},
{x:25.9944,y:-80.1809},
{x:44.3971,y:-122.7325},
{x:44.2534,y:-84.2281},
{x:43.6258,y:-83.8965},
{x:44.2775,y:-83.5043},
{x:42.938,y:-83.6497},
{x:43.022,y:-83.6019},
{x:43.0,y:-83.7652},
{x:33.4522,y:-112.3917},
{x:36.2628,y:-95.3304},
{x:34.0963,y:-83.6728},
{x:33.8603,y:-118.1475},
{x:35.2023,y:-87.0554},
{x:29.1457,y:-98.1581},
{x:26.3476,y:-81.806},
{x:30.3472,y:-81.524},
{x:28.3307,y:-82.5717},
{x:38.9247,y:-77.5201},
{x:42.982,y:-78.8118},
{x:34.3949,y:-103.1445},
{x:36.3814,y:-114.8963},
{x:33.4579,y:-80.7355},
{x:40.3364,y:-104.9821},
{x:44.0453,y:-123.0823},
{x:42.1074,y:-71.6999},
{x:41.0872,y:-83.6543},
{x:26.6935,y:-80.1096},
{x:26.5745,y:-80.1139},
{x:26.205,y:-80.23},
{x:26.4364,y:-80.1239},
{x:31.248,y:-81.5061},
{x:26.062,y:-80.3112},
{x:26.3185,y:-80.2},
{x:27.6368,y:-80.4505},
{x:43.0588,y:-89.4031},
{x:30.1057,y:-81.833},
{x:34.2401,y:-117.219},
{x:33.4798,y:-111.9829},
{x:41.1328,y:-81.6333},
{x:33.9702,y:-84.1502},
{x:42.2475,y:-84.4333},
{x:17.9974,y:-66.6285},
{x:32.9382,y:-96.9015},
{x:34.676,y:-79.179},
{x:33.3039,y:-111.9978},
{x:40.4486,y:-75.3578},
{x:35.8138,y:-90.6781},
{x:41.4456,y:-75.6689},
{x:47.1176,y:-118.3688},
{x:26.1744,y:-97.9905},
{x:40.5169,y:-80.0327},
{x:32.8366,y:-97.291},
{x:28.8478,y:-96.9757},
{x:41.7245,y:-71.4768},
{x:29.6654,y:-95.1412},
{x:39.4219,y:-79.3907},
{x:39.7606,y:-84.0571},
{x:34.1642,y:-117.3351},
{x:38.6898,y:-121.2955},
{x:35.2658,y:-116.0739},
{x:42.9976,y:-78.5017},
{x:42.4276,y:-76.514},
{x:35.2527,y:-111.5581},
{x:40.627,y:-96.6291},
{x:45.3505,y:-84.9734},
{x:40.7447,y:-73.6011},
{x:34.6952,y:-86.7859},
{x:33.9529,y:-98.5285},
{x:40.4389,y:-79.7662},
{x:29.2818,y:-98.3354},
{x:35.9844,y:-96.0147},
{x:33.9435,y:-84.5337},
{x:40.3657,y:-83.7398},
{x:44.312,y:-96.762},
{x:31.4426,y:-97.4123},
{x:35.2261,y:-80.8949},
{x:40.1418,y:-74.8224},
{x:42.9306,y:-78.6963},
{x:41.2347,y:-79.5287},
{x:33.5703,y:-116.0903},
{x:29.5465,y:-98.3658},
{x:40.7473,y:-74.1369},
{x:38.1287,y:-85.78},
{x:34.2391,y:-77.9491},
{x:32.6438,y:-96.8393},
{x:34.3789,y:-103.3183},
{x:40.0832,y:-75.0229},
{x:39.3544,y:-86.039},
{x:39.9107,y:-86.2162},
{x:27.9966,y:-82.4851},
{x:38.936,y:-76.7203},
{x:39.9841,y:-83.1455},
{x:39.9082,y:-83.4289},
{x:41.4771,y:-72.8111},
{x:29.2268,y:-81.03},
{x:34.2213,y:-85.1312},
{x:33.6798,y:-117.671},
{x:33.915,y:-117.9662},
{x:45.4132,y:-122.664},
{x:40.5277,y:-74.8505},
{x:38.8545,y:-82.8571},
{x:35.8872,y:-82.0627},
{x:38.2573,y:-85.7621},
{x:46.363,y:-94.178},
{x:33.9586,y:-118.4386},
{x:35.4687,y:-86.7922},
{x:36.2479,y:-87.0331},
{x:36.0259,y:-86.7902},
{x:39.9602,y:-82.4742},
{x:18.4262,y:-66.0711},
{x:41.0433,y:-79.502},
{x:46.8091,y:-100.7371},
{x:47.4093,y:-122.2993},
{x:42.825,y:-71.1106},
{x:34.163,y:-118.313},
{x:43.0807,y:-77.6228},
{x:33.2176,y:-96.6788},
{x:43.1799,y:-85.2899},
{x:34.2765,y:-86.2068},
{x:41.124,y:-75.3709},
{x:42.5383,y:-83.0319},
{x:42.6728,y:-83.011},
{x:41.7837,y:-77.0675},
{x:33.996,y:-86.0928},
{x:44.2731,y:-105.498},
{x:41.302,y:-105.5411},
{x:33.4222,y:-111.7371},
{x:21.3875,y:-158.1507},
{x:35.2551,y:-81.035},
{x:35.6524,y:-105.9998},
{x:45.0694,y:-93.4018},
{x:34.0638,y:-84.7263},
{x:47.5334,y:-122.5689},
{x:34.1985,y:-119.1797},
{x:38.3403,y:-77.0713},
{x:39.9138,y:-79.6845},
{x:33.4557,y:-117.6057},
{x:42.1363,y:-72.5076},
{x:42.6228,y:-83.0576},
{x:32.6815,y:-115.5012},
{x:34.9834,y:-78.9658},
{x:33.4318,y:-94.0012},
{x:38.994,y:-80.232},
{x:35.8205,y:-79.8232},
{x:38.445,y:-90.2206},
{x:33.6808,y:-78.9227},
{x:35.1763,y:-111.6653},
{x:40.9924,y:-74.3115},
{x:40.9077,y:-74.3994},
{x:33.689,y:-95.5536},
{x:18.0919,y:-67.1549},
{x:34.3114,y:-117.461},
{x:37.1199,y:-76.4592},
{x:37.4004,y:-76.5288},
{x:40.6127,y:-74.2475},
{x:42.5447,y:-83.1731},
{x:42.6601,y:-83.4671},
{x:42.5145,y:-83.6129},
{x:42.7509,y:-83.3811},
{x:41.74,y:-72.7144},
{x:31.4618,y:-97.1738},
{x:36.7583,y:-76.0084},
{x:38.9172,y:-77.0316},
{x:33.9688,y:-118.0997},
{x:32.5841,y:-95.1223},
{x:33.4849,y:-80.8649},
{x:44.2964,y:-84.6803},
{x:42.8508,y:-106.267},
{x:33.8769,y:-117.5836},
{x:41.4273,y:-74.1693},
{x:36.7437,y:-84.1364},
{x:40.8998,y:-80.8177},
{x:33.7572,y:-116.3018},
{x:38.7496,y:-108.0539},
{x:39.5526,y:-107.7774},
{x:26.6163,y:-81.7966},
{x:41.1531,y:-81.8615},
{x:42.6364,y:-82.856},
{x:32.3656,y:-84.8776},
{x:29.5681,y:-104.3766},
{x:34.7077,y:-82.7616},
{x:41.7746,y:-124.1898},
{x:29.7574,y:-81.2837},
{x:28.5324,y:-82.511},
{x:41.7907,y:-86.2494},
{x:28.4609,y:-82.5403},
{x:42.2957,y:-85.6819},
{x:45.2838,y:-93.8027},
{x:43.0432,y:-108.3789},
{x:37.7992,y:-85.4347},
{x:37.0658,y:-81.7316},
{x:37.5044,y:-77.6303},
{x:48.1779,y:-111.9407},
{x:36.2391,y:-91.5963},
{x:40.1366,y:-74.968},
{x:41.4413,y:-81.8648},
{x:38.9155,y:-77.2367},
{x:42.2889,y:-83.3838},
{x:42.2986,y:-71.4013},
{x:36.1959,y:-115.0303},
{x:36.284,y:-115.2872},
{x:38.625,y:-90.517},
{x:36.794,y:-76.1228},
{x:30.2668,y:-87.6867},
{x:40.467,y:-86.959},
{x:31.9622,y:-83.7635},
{x:34.0711,y:-117.9635},
{x:32.0731,y:-84.2197},
{x:43.489,y:-83.9698},
{x:36.1512,y:-86.667},
{x:33.5248,y:-117.6863},
{x:33.8172,y:-118.0375},
{x:36.3609,y:-86.4963},
{x:34.156,y:-118.4832},
{x:40.7982,y:-74.0652},
{x:42.0066,y:-88.3369},
{x:27.9964,y:-82.5706},
{x:37.3659,y:-82.4094},
{x:47.8946,y:-122.2562},
{x:42.1155,y:-71.4642},
{x:27.9838,y:-82.2748},
{x:35.9866,y:-94.5843},
{x:37.3885,y:-120.7353},
{x:34.0229,y:-118.3719},
{x:42.2347,y:-83.7396},
{x:36.1834,y:-85.4579},
{x:36.1854,y:-85.4598},
{x:35.1852,y:-83.37},
{x:43.1009,y:-70.8095},
{x:36.1878,y:-79.8897},
{x:40.266,y:-83.0728},
{x:37.0566,y:-76.3906},
{x:28.5338,y:-81.5414},
{x:39.9549,y:-82.8065},
{x:44.4662,y:-87.9832},
{x:40.6266,y:-74.1351},
{x:41.2693,y:-72.9755},
{x:39.5448,y:-76.1091},
{x:36.8633,y:-76.2082},
{x:39.9302,y:-83.1446},
{x:43.818,y:-111.7888},
{x:32.3446,y:-111.0157},
{x:39.9575,y:-83.042},
{x:33.9038,y:-118.2098},
{x:29.6217,y:-95.5086},
{x:40.0498,y:-84.2274},
{x:42.4612,y:-92.247},
{x:39.225,y:-81.5418},
{x:42.2461,y:-88.9764},
{x:39.9302,y:-83.0531},
{x:34.4253,y:-117.5729},
{x:34.8268,y:-82.4096},
{x:33.6635,y:-84.4464},
{x:35.4482,y:-97.4256},
{x:28.5971,y:-81.2875},
{x:39.5719,y:-104.9904},
{x:29.7836,y:-95.8056},
{x:38.1388,y:-121.2889},
{x:44.9451,y:-92.8978},
{x:40.6769,y:-73.8696},
{x:35.2234,y:-88.2164},
{x:36.6268,y:-119.6857},
{x:33.0783,y:-97.5614},
{x:39.1337,y:-123.197},
{x:42.0122,y:-71.3056},
{x:36.7078,y:-94.9291},
{x:37.4313,y:-77.6217},
{x:41.9515,y:-83.3977},
{x:38.7761,y:-89.9531},
{x:41.3166,y:-74.1488},
{x:31.8191,y:-106.1679},
{x:33.6441,y:-84.9722},
{x:28.0578,y:-81.8157},
{x:18.357,y:-66.0642},
{x:42.7023,y:-88.2533},
{x:39.4708,y:-76.6429},
{x:38.161,y:-84.8987},
{x:42.1349,y:-83.2259},
{x:44.5691,y:-123.2787},
{x:27.8833,y:-80.5044},
{x:41.8655,y:-87.7641},
{x:42.2971,y:-71.0606},
{x:38.8607,y:-86.507},
{x:38.9528,y:-77.4486},
{x:32.485,y:-86.411},
{x:38.6541,y:-87.1692},
{x:36.0967,y:-86.1417},
{x:34.2581,y:-88.7408},
{x:41.3507,y:-72.5618},
{x:39.118,y:-100.8086},
{x:37.6951,y:-97.3913},
{x:37.6335,y:-97.7805},
{x:41.1381,y:-73.291},
{x:36.0049,y:-95.8893},
{x:40.8325,y:-77.8081},
{x:42.1536,y:-87.9532},
{x:40.3981,y:-79.9018},
{x:43.6616,y:-84.2387},
{x:40.8341,y:-81.3286},
{x:35.0858,y:-78.7912},
{x:30.2252,y:-85.6468},
{x:34.0565,y:-118.2085},
{x:42.7285,y:-73.7598},
{x:39.7458,y:-122.0148},
{x:41.8727,y:-84.0319},
{x:38.7816,y:-77.1869},
{x:44.7345,y:-93.2245},
{x:34.2241,y:-118.3983},
{x:34.6323,y:-78.6202},
{x:40.5864,y:-105.0548},
{x:47.102,y:-119.2571},
{x:32.7083,y:-117.1591},
{x:32.7452,y:-96.4462},
{x:40.1962,y:-74.6392},
{x:39.5289,y:-84.7685},
{x:20.7174,y:-156.4461},
{x:32.4,y:-86.213},
{x:40.3511,y:-75.6305},
{x:34.0059,y:-117.6882},
{x:35.2468,y:-92.7332},
{x:35.2883,y:-81.0172},
{x:38.7234,y:-104.7292},
{x:36.1397,y:-85.6302},
{x:42.4588,y:-79.317},
{x:30.7279,y:-86.5654},
{x:30.2036,y:-81.7401},
{x:33.5137,y:-117.1022},
{x:40.8098,y:-75.7391},
{x:33.7984,y:-83.743},
{x:36.6689,y:-76.9476},
{x:28.9225,y:-82.4643},
{x:35.6358,y:-117.6702},
{x:43.2009,y:-88.7012},
{x:33.8077,y:-84.0463},
{x:44.931,y:-93.3459},
{x:35.0466,y:-80.9937},
{x:36.1797,y:-95.9742},
{x:39.5405,y:-82.4392},
{x:31.7502,y:-93.0667},
{x:39.1211,y:-91.8911},
{x:42.2848,y:-71.6567},
{x:45.0039,y:-123.0253},
{x:48.2386,y:-114.3283},
{x:38.5504,y:-76.9831},
{x:40.5356,y:-74.2939},
{x:39.336,y:-76.4096},
{x:33.7442,y:-117.1919},
{x:34.7647,y:-86.5919},
{x:33.5795,y:-86.9242},
{x:31.9961,y:-102.1018},
{x:41.0176,y:-98.9121},
{x:42.9422,y:-88.8619},
{x:32.6354,y:-86.3186},
{x:37.0541,y:-88.5664},
{x:38.5908,y:-90.3394},
{x:40.5541,y:-75.5788},
{x:45.7876,y:-108.6179},
{x:36.2194,y:-115.297},
{x:44.5919,y:-123.2537},
{x:43.1613,y:-76.1172},
{x:41.5569,y:-75.2263},
{x:38.6468,y:-77.2949},
{x:39.1544,y:-76.7172},
{x:30.5012,y:-94.9882},
{x:41.883,y:-80.7499},
{x:39.8459,y:-84.1962},
{x:39.6892,y:-83.9691},
{x:40.8478,y:-80.2581},
{x:38.8545,y:-77.3966},
{x:34.7485,y:-86.583},
{x:37.6586,y:-82.2709},
{x:38.9845,y:-77.5373},
{x:41.0046,y:-80.6619},
{x:44.0449,y:-123.0645},
{x:48.3558,y:-99.9976},
{x:41.6635,y:-72.7215},
{x:34.8389,y:-102.4083},
{x:41.4407,y:-97.3168},
{x:33.9493,y:-118.09},
{x:40.2304,y:-83.3449},
{x:41.7685,y:-71.2655},
{x:35.7189,y:-78.6586},
{x:41.5177,y:-81.5497},
{x:28.615,y:-96.6177},
{x:44.1961,y:-70.2438},
{x:40.8373,y:-73.8596},
{x:37.81,y:-81.1841},
{x:25.9312,y:-81.6997},
{x:39.4491,y:-77.3793},
{x:41.7646,y:-72.5408},
{x:33.6902,y:-117.3422},
{x:40.5699,y:-80.2563},
{x:41.9249,y:-87.6766},
{x:33.7449,y:-87.0422},
{x:47.4693,y:-111.3623},
{x:32.8474,y:-104.4269},
{x:39.3474,y:-82.973},
{x:39.1937,y:-78.1941},
{x:32.7359,y:-97.1115},
{x:44.2836,y:-85.398},
{x:41.6784,y:-81.3071},
{x:31.8456,y:-86.6495},
{x:33.4196,y:-86.984},
{x:34.049,y:-117.4338},
{x:30.2427,y:-92.1013},
{x:41.1648,y:-74.5585},
{x:40.3494,y:-111.7279},
{x:36.2961,y:-76.2985},
{x:35.7145,y:-81.6957},
{x:36.108,y:-83.3345},
{x:47.4365,y:-122.2945},
{x:35.9577,y:-83.5929},
{x:39.4544,y:-84.178},
{x:41.6415,y:-81.4469},
{x:36.3263,y:-79.6867},
{x:37.1275,y:-93.4589},
{x:29.6803,y:-95.6129},
{x:38.3397,y:-75.1626},
{x:33.9754,y:-118.2481},
{x:28.668,y:-97.3869},
{x:42.0143,y:-88.1451},
{x:28.3327,y:-81.4763},
{x:30.3522,y:-89.9205},
{x:34.2599,y:-85.1676},
{x:39.768,y:-84.6334},
{x:40.2648,y:-75.7448},
{x:40.3808,y:-75.9845},
{x:36.8076,y:-114.1073},
{x:28.0498,y:-82.7812},
{x:26.6615,y:-81.9416},
{x:33.5433,y:-85.0773},
{x:35.0008,y:-85.2578},
{x:41.346,y:-81.8202},
{x:44.433,y:-88.1033},
{x:38.4718,y:-121.82},
{x:29.9424,y:-90.0936},
{x:42.269,y:-72.672},
{x:32.2687,y:-107.7431},
{x:38.7794,y:-83.5669},
{x:37.1794,y:-77.3668},
{x:42.7198,y:-87.911},
{x:47.6675,y:-122.376},
{x:26.2979,y:-80.0936},
{x:35.456,y:-81.001},
{x:44.5179,y:-109.0893},
{x:40.0023,y:-79.6042},
{x:35.9295,y:-89.871},
{x:40.4909,y:-78.0442},
{x:40.6778,y:-75.1485},
{x:41.2915,y:-96.1605},
{x:41.2317,y:-96.1992},
{x:47.0605,y:-122.7733},
{x:33.7705,y:-117.8518},
{x:39.6376,y:-86.3388},
{x:40.7803,y:-73.2438},
{x:35.9296,y:-89.8762},
{x:34.1507,y:-118.0282},
{x:35.8835,y:-77.5456},
{x:33.5923,y:-117.1237},
{x:37.9798,y:-84.5316},
{x:40.0314,y:-75.1},
{x:42.8967,y:-71.3316},
{x:37.4388,y:-77.4698},
{x:44.1769,y:-88.4917},
{x:41.1204,y:-81.8646},
{x:36.7116,y:-78.1046},
{x:36.0913,y:-79.4038},
{x:40.7862,y:-73.1239},
{x:37.2985,y:-83.214},
{x:40.407,y:-104.7755},
{x:41.0525,y:-81.7255},
{x:41.4304,y:-82.2082},
{x:30.4765,y:-90.8599},
{x:39.1481,y:-76.6407},
{x:40.077,y:-85.6775},
{x:28.095,y:-82.7413},
{x:33.747,y:-117.4522},
{x:36.5196,y:-76.1726},
{x:28.4739,y:-81.2882},
{x:28.2995,y:-81.4102},
{x:36.0382,y:-78.8269},
{x:30.0947,y:-83.579},
{x:21.378,y:-157.7291},
{x:36.2119,y:-115.989},
{x:36.4883,y:-79.7341},
{x:38.6213,y:-90.3314},
{x:42.0522,y:-80.0804},
{x:42.1582,y:-79.9677},
{x:43.3941,y:-73.2632},
{x:42.4455,y:-90.8512},
{x:36.1696,y:-86.6808},
{x:40.4171,y:-75.921},
{x:41.0321,y:-78.401},
{x:41.1416,y:-95.9748},
{x:41.3489,y:-79.718},
{x:36.0811,y:-86.728},
{x:42.8483,y:-78.8132},
{x:42.6434,y:-73.608},
{x:46.2563,y:-119.3079},
{x:33.9321,y:-117.9171},
{x:37.2602,y:-77.3296},
{x:33.6093,y:-86.2779},
{x:42.4641,y:-78.9342},
{x:34.4135,y:-110.5711},
{x:30.2845,y:-89.7661},
{x:41.8226,y:-89.475},
{x:39.0198,y:-84.2177},
{x:41.5614,y:-70.5938},
{x:39.7652,y:-86.3548},
{x:38.4479,y:-105.1918},
{x:42.5549,y:-70.9676},
{x:44.4782,y:-87.9706},
{x:44.4049,y:-88.7417},
{x:36.0796,y:-95.9575},
{x:44.0459,y:-75.8061},
{x:41.4653,y:-81.7602},
{x:35.6928,y:-79.7895},
{x:42.2344,y:-70.9892},
{x:25.8868,y:-80.3253},
{x:35.1458,y:-80.9337},
{x:32.0299,y:-102.1482},
{x:34.0915,y:-118.1414},
{x:25.9848,y:-80.2999},
{x:38.435,y:-82.1319},
{x:36.6927,y:-80.8819},
{x:34.0764,y:-86.5899},
{x:32.9206,y:-117.2135},
{x:41.9998,y:-87.6604},
{x:27.7142,y:-82.3334},
{x:38.0346,y:-84.91},
{x:32.5577,y:-93.7812},
{x:40.7466,y:-99.7406},
{x:36.574,y:-94.7691},
{x:37.3254,y:-79.4967},
{x:39.9217,y:-75.1468},
{x:41.8817,y:-79.1518},
{x:26.1625,y:-80.2049},
{x:27.1973,y:-80.2222},
{x:34.6678,y:-92.3771},
{x:44.8971,y:-93.0783},
{x:33.8086,y:-117.8534},
{x:36.724,y:-85.6856},
{x:37.1439,y:-93.259},
{x:33.1765,y:-111.5844},
{x:29.4898,y:-98.5877},
{x:38.9607,y:-78.1879},
{x:41.2399,y:-73.0341},
{x:42.4904,y:-83.1277},
{x:35.7695,y:-91.6411},
{x:40.8621,y:-73.9018},
{x:34.8214,y:-82.4138},
{x:33.9027,y:-118.0545},
{x:37.7497,y:-97.4623},
{x:36.5854,y:-79.4255},
{x:34.6834,y:-86.7405},
{x:36.1971,y:-81.6598},
{x:42.3893,y:-87.9579},
{x:39.3504,y:-77.0687},
{x:39.3967,y:-76.9337},
{x:34.914,y:-76.9366},
{x:39.5826,y:-82.9489},
{x:33.9126,y:-117.8904},
{x:33.7341,y:-117.8437},
{x:27.9572,y:-82.5034},
{x:38.9315,y:-122.6166},
{x:34.5714,y:-114.3676},
{x:34.244,y:-111.3246},
{x:42.3969,y:-88.0667},
{x:37.3404,y:-95.2687},
{x:40.1646,y:-83.0165},
{x:29.4477,y:-96.9414},
{x:32.3554,y:-90.7332},
{x:33.3902,y:-112.1364},
{x:45.4142,y:-122.6317},
{x:30.388,y:-86.4251},
{x:28.2074,y:-82.6687},
{x:41.2269,y:-95.8441},
{x:41.1538,y:-73.4248},
{x:25.7482,y:-80.3311},
{x:33.5622,y:-86.519},
{x:37.6994,y:-121.7679},
{x:30.14,y:-96.3934},
{x:42.694,y:-114.5156},
{x:18.4223,y:-66.0744},
{x:41.5483,y:-72.8152},
{x:44.939,y:-93.0882},
{x:33.7191,y:-84.9351},
{x:33.6405,y:-86.6832},
{x:40.3687,y:-80.0348},
{x:30.8943,y:-83.3536},
{x:40.6876,y:-76.1973},
{x:37.3578,y:-121.8624},
{x:35.2017,y:-90.1875},
{x:35.6375,y:-91.2509},
{x:38.7697,y:-90.7831},
{x:35.4558,y:-86.0474},
{x:33.9365,y:-84.0252},
{x:38.7198,y:-121.0854},
{x:43.493,y:-112.052},
{x:35.2235,y:-88.234},
{x:37.1097,y:-84.0738},
{x:33.9037,y:-118.1691},
{x:45.1212,y:-93.2656},
{x:33.1801,y:-96.8842},
{x:42.1735,y:-91.7773},
{x:39.7611,y:-76.9627},
{x:42.3236,y:-83.4298},
{x:42.226,y:-83.4874},
{x:40.5614,y:-111.9776},
{x:41.2716,y:-85.8559},
{x:41.4082,y:-73.4132},
{x:41.408,y:-82.6605},
{x:28.3955,y:-80.7537},
{x:47.7168,y:-117.3943},
{x:33.7148,y:-118.0082},
{x:33.6393,y:-112.2774},
{x:34.7868,y:-92.2146},
{x:36.7719,y:-88.6439},
{x:47.304,y:-119.5405},
{x:44.0307,y:-70.2715},
{x:35.0477,y:-89.6895},
{x:43.8028,y:-70.8232},
{x:33.8691,y:-112.1546},
{x:35.1917,y:-106.725},
{x:35.1749,y:-106.5781},
{x:38.6064,y:-90.3744},
{x:34.7388,y:-76.8105},
{x:46.1967,y:-119.2647},
{x:27.8851,y:-82.506},
{x:39.3049,y:-81.5506},
{x:18.4043,y:-66.2202},
{x:42.2776,y:-83.2169},
{x:42.3593,y:-87.8676},
{x:33.3789,y:-111.8373},
{x:40.5274,y:-104.7128},
{x:38.3213,y:-81.716},
{x:42.4931,y:-89.0444},
{x:43.3332,y:-75.7477},
{x:44.4449,y:-88.5789},
{x:41.4249,y:-81.612},
{x:42.4712,y:-72.6156},
{x:44.7881,y:-85.6384},
{x:42.9851,y:-78.8733},
{x:36.1366,y:-95.9217},
{x:35.1026,y:-77.0861},
{x:42.3034,y:-71.3338},
{x:27.0371,y:-82.2193},
{x:43.393,y:-97.9954},
{x:35.6133,y:-88.8188},
{x:42.7921,y:-72.0389},
{x:40.2827,y:-79.3829},
{x:29.5461,y:-98.5098},
{x:41.1002,y:-81.377},
{x:18.3761,y:-66.0915},
{x:41.8895,y:-87.9799},
{x:45.6198,y:-122.4772},
{x:41.6971,y:-91.6125},
{x:41.647,y:-93.5052},
{x:42.9998,y:-84.1389},
{x:34.3602,y:-89.5645},
{x:36.5099,y:-87.2693},
{x:33.8674,y:-84.6359},
{x:42.3764,y:-71.2459},
{x:33.0638,y:-96.2299},
{x:38.9759,y:-94.5947},
{x:36.0903,y:-115.1719},
{x:42.3704,y:-71.5627},
{x:42.8663,y:-85.6648},
{x:36.3225,y:-86.5954},
{x:33.6377,y:-86.5794},
{x:33.636,y:-84.2932},
{x:41.8006,y:-71.446},
{x:44.269,y:-88.4794},
{x:45.6354,y:-89.3826},
{x:44.2416,y:-88.3519},
{x:44.0155,y:-88.5855},
{x:44.5263,y:-88.0925},
{x:33.6158,y:-117.9002},
{x:40.5227,y:-111.9421},
{x:44.9184,y:-89.6529},
{x:44.3549,y:-89.8143},
{x:39.4068,y:-104.8615},
{x:44.7746,y:-91.4295},
{x:35.293,y:-89.6745},
{x:34.0708,y:-117.9791},
{x:34.2332,y:-97.4895},
{x:37.3665,y:-121.9415},
{x:43.7158,y:-89.8957},
{x:40.3882,y:-79.8252},
{x:41.4651,y:-81.5364},
{x:38.3425,y:-104.6234},
{x:42.5718,y:-71.7758},
{x:25.8506,y:-80.1203},
{x:38.8683,y:-86.5108},
{x:47.4767,y:-122.2157},
{x:45.023,y:-84.6905},
{x:36.1469,y:-95.8521},
{x:42.3415,y:-72.6433},
{x:33.9493,y:-118.1635},
{x:37.1541,y:-93.2988},
{x:33.4216,y:-111.9618},
{x:33.3726,y:-112.574},
{x:33.1419,y:-117.2428},
{x:42.5503,y:-71.9927},
{x:38.2599,y:-85.7085},
{x:41.4665,y:-82.0165},
{x:38.7757,y:-77.0594},
{x:40.9423,y:-98.3868},
{x:39.3581,y:-85.4786},
{x:34.8359,y:-82.3466},
{x:32.8717,y:-97.3429},
{x:40.3855,y:-80.0931},
{x:40.8136,y:-74.0131},
{x:42.3737,y:-82.9674},
{x:40.141,y:-85.6779},
{x:42.8458,y:-93.6168},
{x:42.7326,y:-94.6768},
{x:40.0943,y:-75.361},
{x:39.7234,y:-90.2026},
{x:37.3465,y:-108.5619},
{x:32.8396,y:-116.9889},
{x:42.2887,y:-83.166},
{x:36.9756,y:-84.1015},
{x:39.239,y:-81.5451},
{x:37.6143,y:-84.7737},
{x:31.5047,y:-95.4763},
{x:30.319,y:-81.7313},
{x:38.7273,y:-75.1322},
{x:41.9244,y:-71.8891},
{x:42.2091,y:-88.2834},
{x:46.5929,y:-111.9906},
{x:36.0678,y:-119.0161},
{x:38.9936,y:-79.1171},
{x:41.165,y:-111.9706},
{x:37.6374,y:-77.4611},
{x:35.5217,y:-105.2522},
{x:45.5335,y:-122.4795},
{x:37.5697,y:-77.4001},
{x:45.0635,y:-83.4775},
{x:36.4442,y:-94.1167},
{x:47.9497,y:-122.2136},
{x:30.3137,y:-81.7055},
{x:33.8739,y:-118.1852},
{x:32.4024,y:-99.7148},
{x:35.2044,y:-106.6764},
{x:33.7833,y:-116.3566},
{x:30.9623,y:-85.5165},
{x:34.4264,y:-117.3855},
{x:34.0103,y:-84.7533},
{x:44.5532,y:-88.0854},
{x:42.1192,y:-88.2607},
{x:27.6752,y:-80.4073},
{x:40.3497,y:-76.3899},
{x:41.0995,y:-80.7751},
{x:33.6596,y:-84.4329},
{x:46.5089,y:-87.6093},
{x:35.2593,y:-80.4312},
{x:40.5569,y:-84.5364},
{x:37.7575,y:-81.2147},
{x:28.1553,y:-82.3559},
{x:26.6839,y:-80.2475},
{x:37.3088,y:-121.847},
{x:41.6724,y:-72.7546},
{x:41.2622,y:-88.2136},
{x:37.4912,y:-121.9297},
{x:41.8169,y:-87.6315},
{x:41.2967,y:-86.1164},
{x:42.3152,y:-88.4369},
{x:37.7818,y:-78.4887},
{x:31.0821,y:-97.4605},
{x:42.9541,y:-74.3771},
{x:25.9874,y:-80.1241},
{x:33.9594,y:-117.5926},
{x:42.1911,y:-120.3464},
{x:30.6014,y:-87.1173},
{x:32.5897,y:-83.6125},
{x:40.8461,y:-73.845},
{x:44.9415,y:-89.8416},
{x:36.838,y:-119.7288},
{x:44.6592,y:-85.7703},
{x:30.3223,y:-92.0446},
{x:42.1963,y:-86.1666},
{x:42.4301,y:-71.5889},
{x:38.6253,y:-83.7907},
{x:33.6572,y:-114.2413},
{x:37.7258,y:-113.0515},
{x:42.344,y:-88.0687},
{x:32.6117,y:-98.1095},
{x:29.2687,y:-98.5513},
{x:35.259,y:-81.2557},
{x:30.6885,y:-91.4642},
{x:39.4102,y:-77.9148},
{x:40.7939,y:-79.2831},
{x:28.4141,y:-81.2414},
{x:31.2472,y:-90.477},
{x:40.7545,y:-84.0829},
{x:44.8601,y:-93.3043},
{x:30.7897,y:-89.8642},
{x:34.2098,y:-118.8452},
{x:32.8029,y:-117.1391},
{x:30.3323,y:-89.1901},
{x:40.6044,y:-74.6918},
{x:18.4723,y:-66.935},
{x:35.9416,y:-115.1166},
{x:37.3008,y:-120.4826},
{x:41.7871,y:-71.0767},
{x:35.4914,y:-94.9715},
{x:38.2448,y:-85.7513},
{x:35.62,y:-105.2276},
{x:42.2599,y:-89.0571},
{x:38.9942,y:-77.0272},
{x:31.8173,y:-102.3809},
{x:34.8765,y:-99.5029},
{x:35.2977,y:-82.4137},
{x:42.9798,y:-88.2379},
{x:42.1995,y:-70.9448},
{x:45.2397,y:-93.6641},
{x:39.8853,y:-104.9798},
{x:42.4827,y:-87.8235},
{x:34.2041,y:-84.8107},
{x:42.1926,y:-71.1978},
{x:44.4156,y:-118.9525},
{x:34.2196,y:-85.6006},
{x:34.0325,y:-118.1538},
{x:39.0329,y:-80.4418},
{x:47.8759,y:-122.1559},
{x:32.9069,y:-96.571},
{x:41.2425,y:-73.0318},
{x:39.0353,y:-84.5362},
{x:33.783,y:-118.1417},
{x:35.7982,y:-78.5056},
{x:30.4428,y:-85.0476},
{x:29.9311,y:-95.5098},
{x:41.8191,y:-73.0818},
{x:42.6283,y:-88.614},
{x:45.6716,y:-122.5211},
{x:38.9185,y:-77.2239},
{x:39.656,y:-80.012},
{x:28.1797,y:-82.4674},
{x:44.0631,y:-76.1241},
{x:38.8722,y:-90.0695},
{x:40.547,y:-111.9013},
{x:38.2569,y:-82.4298},
{x:39.6596,y:-86.141},
{x:36.9145,y:-76.2708},
{x:32.7258,y:-97.3196},
{x:38.8959,y:-99.3171},
{x:39.1885,y:-96.5562},
{x:41.5536,y:-72.9749},
{x:41.7603,y:-111.8321},
{x:40.4092,y:-120.6372},
{x:45.5194,y:-123.0052},
{x:43.7876,y:-88.4846},
{x:37.2937,y:-121.9444},
{x:31.311,y:-89.312},
{x:45.5416,y:-122.4208},
{x:39.113,y:-119.7769},
{x:33.935,y:-84.7309},
{x:48.0059,y:-122.1967},
{x:33.7192,y:-84.3124},
{x:41.851,y:-72.4833},
{x:42.1279,y:-72.0605},
{x:38.4273,y:-86.9352},
{x:37.2039,y:-79.9511},
{x:33.9644,y:-81.3893},
{x:35.0509,y:-78.8645},
{x:30.5551,y:-91.5428},
{x:30.9022,y:-88.5951},
{x:40.9481,y:-81.4637},
{x:35.4816,y:-80.8768},
{x:34.174,y:-119.1782},
{x:36.3966,y:-83.0198},
{x:42.7568,y:-73.7585},
{x:43.0943,y:-78.9782},
{x:36.2336,y:-83.2857},
{x:25.9561,y:-80.205},
{x:44.5948,y:-69.6179},
{x:40.6362,y:-74.9669},
{x:43.6344,y:-70.3172},
{x:34.357,y:-77.9003},
{x:32.0262,y:-81.2263},
{x:47.436,y:-120.3217},
{x:33.9741,y:-117.5596},
{x:29.4782,y:-98.6628},
{x:42.134,y:-71.1012},
{x:47.4054,y:-120.2863},
{x:45.5642,y:-122.6613},
{x:40.8159,y:-73.9169},
{x:34.6095,y:-120.1859},
{x:42.0791,y:-78.4688},
{x:37.3862,y:-121.9608},
{x:33.9956,y:-118.1195},
{x:18.4672,y:-66.7314},
{x:42.0295,y:-71.2292},
{x:34.9982,y:-101.9226},
{x:29.922,y:-82.1296},
{x:35.98,y:-78.5413},
{x:34.556,y:-86.9965},
{x:35.0785,y:-80.6503},
{x:30.4164,y:-81.7493},
{x:32.8338,y:-116.7496},
{x:39.2023,y:-121.0639},
{x:47.4973,y:-94.9087},
{x:42.0537,y:-87.9997},
{x:35.4506,y:-94.8013},
{x:47.0476,y:-122.8344},
{x:30.5848,y:-97.8568},
{x:30.5189,y:-97.6606},
{x:41.6249,y:-87.7376},
{x:29.7097,y:-95.2204},
{x:41.8595,y:-72.4304},
{x:37.5494,y:-97.2698},
{x:43.1213,y:-88.0415},
{x:41.4846,y:-72.6824},
{x:40.7725,y:-73.1999},
{x:39.3455,y:-84.3924},
{x:41.893,y:-87.962},
{x:40.1503,y:-84.2143},
{x:34.2857,y:-78.1406},
{x:36.195,y:-80.8095},
{x:46.5224,y:-95.3753},
{x:43.8558,y:-111.7744},
{x:35.1232,y:-85.286},
{x:45.6685,y:-118.8086},
{x:35.1597,y:-79.4245},
{x:37.0633,y:-113.5869},
{x:34.4786,y:-84.9335},
{x:39.7123,y:-86.3666},
{x:45.6261,y:-84.4818},
{x:46.8762,y:-96.3277},
{x:27.8152,y:-82.7803},
{x:39.8263,y:-84.8531},
{x:40.4314,y:-122.2887},
{x:30.8043,y:-91.3864},
{x:37.129,y:-84.0902},
{x:41.7881,y:-71.4985},
{x:35.4431,y:-79.1301},
{x:44.8284,y:-93.163},
{x:42.2886,y:-71.4281},
{x:42.3672,y:-83.3368},
{x:37.6314,y:-97.3713},
{x:41.5003,y:-74.0305},
{x:34.6017,y:-92.5342},
{x:35.0617,y:-79.0124},
{x:39.113,y:-121.6179},
{x:41.976,y:-76.5427},
{x:32.6596,y:-97.4008},
{x:38.2306,y:-84.5382},
{x:38.9527,y:-77.4457},
{x:45.756,y:-94.213},
{x:41.9418,y:-85.6565},
{x:34.9829,y:-92.0443},
{x:32.7437,y:-103.1605},
{x:32.5672,y:-116.9654},
{x:34.5202,y:-117.3323},
{x:42.0811,y:-94.8936},
{x:32.386,y:-90.116},
{x:35.0912,y:-92.4196},
{x:34.47,y:-117.3508},
{x:35.5667,y:-92.1812},
{x:40.9323,y:-84.3405},
{x:37.7188,y:-77.4521},
{x:38.3028,y:-104.6094},
{x:40.5602,y:-74.1643},
{x:35.9182,y:-80.5846},
{x:37.4823,y:-105.9043},
{x:29.9034,y:-95.5244},
{x:30.0527,y:-83.1713},
{x:29.9567,y:-82.9192},
{x:42.3109,y:-93.5819},
{x:41.7,y:-92.7265},
{x:34.4495,y:-93.1762},
{x:39.5799,y:-79.9577},
{x:34.4951,y:-87.7284},
{x:41.801,y:-81.076},
{x:34.66,y:-86.4883},
{x:31.9122,y:-110.9819},
{x:34.4953,y:-87.2772},
{x:45.2348,y:-93.4672},
{x:44.1643,y:-93.9503},
{x:37.9456,y:-101.2578},
{x:40.0257,y:-74.857},
{x:43.6855,y:-98.0101},
{x:40.6893,y:-73.7211},
{x:27.902,y:-81.843},
{x:38.2224,y:-77.5043},
{x:26.2598,y:-98.3177},
{x:26.2131,y:-98.3129},
{x:29.7578,y:-95.3692},
{x:43.0688,y:-75.7516},
{x:31.8785,y:-106.5679},
{x:31.881,y:-106.6114},
{x:33.8895,y:-118.1252},
{x:29.6988,y:-95.3863},
{x:36.1238,y:-92.1305},
{x:40.2615,y:-103.7714},
{x:39.052,y:-82.6366},
{x:41.5659,y:-73.005},
{x:36.7361,y:-79.9597},
{x:33.3941,y:-111.7544},
{x:42.0015,y:-72.594},
{x:36.9039,y:-94.8775},
{x:38.7404,y:-121.1702},
{x:32.1347,y:-110.9775},
{x:32.1332,y:-111.031},
{x:45.32,y:-93.3471},
{x:33.4597,y:-86.7326},
{x:29.7837,y:-95.5791},
{x:40.5837,y:-83.0885},
{x:40.638,y:-83.6159},
{x:39.9266,y:-83.1173},
{x:35.2646,y:-77.6461},
{x:33.4262,y:-86.7734},
{x:41.4474,y:-81.6448},
{x:41.1209,y:-100.7583},
{x:39.3763,y:-104.8291},
{x:32.729,y:-97.7884},
{x:39.5885,y:-89.759},
{x:32.9019,y:-96.4621},
{x:39.36,y:-84.28},
{x:33.3616,y:-87.0013},
{x:33.4461,y:-86.8231},
{x:43.3382,y:-83.3477},
{x:42.8112,y:-71.5811},
{x:42.7269,y:-71.4236},
{x:29.486,y:-98.5948},
{x:33.0137,y:-97.2174},
{x:29.6548,y:-82.4956},
{x:30.7607,y:-88.2283},
{x:31.425,y:-97.7134},
{x:36.3164,y:-121.2388},
{x:44.9146,y:-122.9984},
{x:45.6871,y:-122.6624},
{x:35.8762,y:-88.7479},
{x:34.4543,y:-93.0618},
{x:37.092,y:-85.0709},
{x:39.6598,y:-83.7462},
{x:35.009,y:-85.2054},
{x:39.8824,y:-86.2672},
{x:42.9614,y:-88.0477},
{x:41.7128,y:-86.1827},
{x:35.4844,y:-81.2416},
{x:34.6644,y:-79.0035},
{x:25.8137,y:-80.1232},
{x:45.4746,y:-91.7628},
{x:40.0656,y:-82.8625},
{x:36.8789,y:-87.737},
{x:28.0481,y:-82.5828},
{x:34.6295,y:-86.5663},
{x:30.8854,y:-87.7903},
{x:41.48,y:-78.6704},
{x:29.7635,y:-95.6248},
{x:33.5732,y:-84.5417},
{x:32.8105,y:-96.8508},
{x:43.1144,y:-83.6939},
{x:41.5026,y:-84.4147},
{x:38.8735,y:-77.0793},
{x:43.3191,y:-73.5721},
{x:44.9773,y:-93.0447},
{x:33.5485,y:-85.0739},
{x:37.4093,y:-87.736},
{x:33.6065,y:-114.603},
{x:45.7676,y:-122.5458},
{x:40.1279,y:-75.4017},
{x:35.4437,y:-94.3255},
{x:46.4415,y:-97.6811},
{x:41.9497,y:-88.7221},
{x:28.8698,y:-81.1638},
{x:38.319,y:-85.8194},
{x:34.8146,y:-106.7527},
{x:35.8495,y:-86.4274},
{x:42.4538,y:-79.3129},
{x:37.1264,y:-113.518},
{x:43.593,y:-73.265},
{x:41.5956,y:-87.4855},
{x:27.5377,y:-81.5039},
{x:33.1349,y:-94.9643},
{x:32.5577,y:-94.3528},
{x:32.9127,y:-84.3338},
{x:36.39,y:-81.4817},
{x:43.143,y:-78.8888},
{x:42.2813,y:-89.1445},
{x:37.297,y:-80.0292},
{x:34.0192,y:-84.5566},
{x:39.3373,y:-85.478},
{x:41.6102,y:-81.5252},
{x:39.7422,y:-77.528},
{x:42.8946,y:-70.8667},
{x:21.4016,y:-157.7982},
{x:38.7451,y:-90.5891},
{x:39.8591,y:-86.396},
{x:30.6735,y:-96.335},
{x:30.5976,y:-96.3005},
{x:43.2119,y:-121.7862},
{x:41.2761,y:-80.7741},
{x:40.0487,y:-88.2559},
{x:43.4751,y:-89.7631},
{x:30.6511,y:-91.1422},
{x:45.277,y:-93.0025},
{x:30.0892,y:-95.6346},
{x:43.2664,y:-70.865},
{x:36.3702,y:-89.4709},
{x:38.6796,y:-77.3659},
{x:32.437,y:-94.7136},
{x:29.973,y:-92.095},
{x:33.4964,y:-112.1721},
{x:38.7941,y:-76.9269},
{x:27.5301,y:-99.4748},
{x:35.6555,y:-80.466},
{x:36.6969,y:-86.5721},
{x:36.2979,y:-119.3346},
{x:29.0567,y:-82.2769},
{x:48.7353,y:-122.4694},
{x:28.255,y:-81.3158},
{x:43.5005,y:-83.3834},
{x:40.366,y:-80.67},
{x:35.8025,y:-80.9065},
{x:34.8218,y:-89.9689},
{x:30.592,y:-88.164},
{x:37.2672,y:-121.8322},
{x:34.787,y:-84.9983},
{x:43.1108,y:-89.3127},
{x:25.886,y:-80.1237},
{x:48.1944,y:-101.3},
{x:42.3299,y:-85.5089},
{x:35.1453,y:-106.5501},
{x:39.899,y:-88.9535},
{x:36.7209,y:-76.3086},
{x:40.1473,y:-88.2557},
{x:46.8556,y:-100.7678},
{x:34.0204,y:-118.4045},
{x:39.7617,y:-104.7746},
{x:40.2172,y:-92.58},
{x:37.1581,y:-93.2402},
{x:35.0301,y:-85.1521},
{x:32.678,y:-97.4678},
{x:37.1109,y:-121.64},
{x:44.0523,y:-92.5216},
{x:32.4935,y:-93.6756},
{x:33.1299,y:-96.7324},
{x:31.2674,y:-85.4404},
{x:42.1051,y:-72.5965},
{x:41.7374,y:-72.659},
{x:30.2697,y:-81.7569},
{x:39.8547,y:-82.8257},
{x:31.9291,y:-82.6797},
{x:41.5965,y:-93.7155},
{x:43.1122,y:-88.2445},
{x:45.16,y:-93.1603},
{x:39.0919,y:-84.2697},
{x:30.62,y:-96.344},
{x:28.0362,y:-80.673},
{x:45.5356,y:-122.5371},
{x:40.9926,y:-81.4891},
{x:40.7968,y:-81.4214},
{x:36.7161,y:-91.8726},
{x:37.7579,y:-87.1423},
{x:40.7091,y:-73.6186},
{x:32.5227,y:-84.9488},
{x:44.9896,y:-92.5506},
{x:46.2073,y:-119.1173},
{x:36.5008,y:-81.113},
{x:33.9461,y:-84.522},
{x:30.021,y:-95.522},
{x:33.611,y:-83.8297},
{x:28.9275,y:-82.0048},
{x:33.5196,y:-88.4364},
{x:34.9617,y:-89.8318},
{x:29.1859,y:-82.1816},
{x:28.2589,y:-81.5911},
{x:41.5588,y:-93.7971},
{x:40.8901,y:-74.0256},
{x:42.3605,y:-93.1134},
{x:35.5605,y:-77.0628},
{x:37.5756,y:-97.2456},
{x:37.7248,y:-97.2083},
{x:40.5874,y:-111.9382},
{x:40.8474,y:-81.9451},
{x:43.5537,y:-85.7717},
{x:33.9505,y:-98.5287},
{x:27.9259,y:-82.789},
{x:36.9006,y:-82.0444},
{x:33.4643,y:-111.9084},
{x:41.6532,y:-88.5629},
{x:48.7887,y:-122.5139},
{x:39.8156,y:-85.77},
{x:39.2607,y:-84.7957},
{x:32.3103,y:-80.9755},
{x:29.8523,y:-95.1277},
{x:41.8621,y:-87.8172},
{x:34.0707,y:-118.2909},
{x:33.9276,y:-118.3835},
{x:39.9378,y:-91.3318},
{x:44.8277,y:-93.2884},
{x:34.7462,y:-86.676},
{x:33.8878,y:-118.2075},
{x:45.0543,y:-93.366},
{x:18.08,y:-67.0366},
{x:37.436,y:-121.8856},
{x:32.3265,y:-90.3045},
{x:43.4903,y:-96.7265},
{x:42.1404,y:-87.9162},
{x:30.2806,y:-81.6283},
{x:38.038,y:-84.4226},
{x:36.3305,y:-94.1251},
{x:41.9051,y:-87.9085},
{x:40.159,y:-75.1392},
{x:42.9588,y:-87.9104},
{x:38.8359,y:-121.1675},
{x:45.3979,y:-92.6101},
{x:42.1553,y:-77.1067},
{x:45.5927,y:-94.1874},
{x:35.9251,y:-87.3214},
{x:34.0292,y:-118.4722},
{x:37.2457,y:-77.387},
{x:42.3506,y:-97.7912},
{x:40.8095,y:-77.8973},
{x:46.8976,y:-119.6334},
{x:37.9858,y:-100.8365},
{x:37.6539,y:-95.4523},
{x:42.3566,y:-76.2014},
{x:34.6564,y:-120.4586},
{x:39.7732,y:-104.9413},
{x:46.7958,y:-100.7449},
{x:38.9394,y:-104.6081},
{x:39.1465,y:-76.6472},
{x:33.1987,y:-87.2978},
{x:46.4649,y:-84.3661},
{x:43.0523,y:-77.0577},
{x:35.0429,y:-88.2705},
{x:42.8853,y:-85.3709},
{x:39.8759,y:-83.0429},
{x:39.9758,y:-82.0145},
{x:38.3366,y:-104.7405},
{x:42.1855,y:-88.0877},
{x:29.8527,y:-95.1882},
{x:33.1061,y:-95.5957},
{x:35.4648,y:-83.0093},
{x:41.8457,y:-71.416},
{x:34.4271,y:-117.3496},
{x:34.4717,y:-117.2403},
{x:29.9315,y:-95.4017},
{x:40.7622,y:-82.595},
{x:32.3512,y:-90.024},
{x:32.7538,y:-97.3544},
{x:36.9087,y:-121.7562},
{x:38.8979,y:-121.3225},
{x:38.2218,y:-122.1254},
{x:34.5449,y:-91.9695},
{x:41.4126,y:-81.5279},
{x:47.7138,y:-116.907},
{x:41.0289,y:-80.6315},
{x:37.2396,y:-87.4715},
{x:35.1401,y:-80.6241},
{x:40.4212,y:-89.7853},
{x:34.1332,y:-117.9078},
{x:42.1226,y:-80.0417},
{x:41.9769,y:-91.6181},
{x:29.9868,y:-90.3649},
{x:38.7347,y:-90.2409},
{x:29.814,y:-95.7701},
{x:37.6154,y:-77.3183},
{x:32.9136,y:-87.8673},
{x:33.9233,y:-116.9521},
{x:35.9329,y:-86.5342},
{x:41.8483,y:-74.0758},
{x:30.2371,y:-95.4629},
{x:35.0283,y:-90.026},
{x:29.6036,y:-82.3721},
{x:42.0689,y:-87.9235},
{x:38.1696,y:-85.7685},
{x:32.8519,y:-116.9324},
{x:38.3884,y:-82.7115},
{x:46.272,y:-119.1875},
{x:31.1788,y:-85.4038},
{x:34.9081,y:-82.3322},
{x:42.4899,y:-83.0066},
{x:42.3531,y:-83.0541},
{x:40.3393,y:-78.9286},
{x:39.9881,y:-82.7829},
{x:39.8197,y:-88.8833},
{x:34.0824,y:-117.6725},
{x:42.5479,y:-83.1464},
{x:30.11,y:-82.6596},
{x:33.4758,y:-81.9918},
{x:36.2134,y:-83.29},
{x:32.9046,y:-97.5458},
{x:32.8135,y:-98.0858},
{x:26.6978,y:-81.9404},
{x:39.9941,y:-82.9812},
{x:37.1604,y:-93.2917},
{x:39.0951,y:-108.6051},
{x:18.2002,y:-66.0524},
{x:30.3048,y:-97.9386},
{x:40.6256,y:-103.2238},
{x:30.2009,y:-97.8758},
{x:37.5944,y:-120.8692},
{x:33.6562,y:-84.5114},
{x:39.8201,y:-84.1947},
{x:36.1817,y:-94.1383},
{x:29.4378,y:-98.6784},
{x:29.5793,y:-98.5867},
{x:35.7706,y:-95.37},
{x:40.2159,y:-111.6596},
{x:28.7244,y:-82.5514},
{x:46.6591,y:-96.4205},
{x:39.8106,y:-120.4699},
{x:40.4883,y:-79.8854},
{x:43.4586,y:-89.7719},
{x:43.603,y:-116.5111},
{x:41.2534,y:-80.4511},
{x:40.3306,y:-78.7486},
{x:42.7256,y:-88.9867},
{x:38.651,y:-90.1315},
{x:41.9617,y:-87.6551},
{x:43.4392,y:-112.1168},
{x:22.0535,y:-159.7765},
{x:40.7135,y:-73.7542},
{x:33.7515,y:-118.3084},
{x:42.0674,y:-80.1961},
{x:33.7287,y:-118.0427},
{x:33.6996,y:-117.8686},
{x:18.4369,y:-66.5395},
{x:17.997,y:-66.6181},
{x:39.7643,y:-105.1417},
{x:40.7326,y:-73.3979},
{x:38.5756,y:-75.5666},
{x:40.5525,y:-80.0161},
{x:35.7265,y:-97.9773},
{x:41.5533,y:-89.071},
{x:36.2278,y:-75.8715},
{x:39.634,y:-86.08},
{x:36.0879,y:-87.1015},
{x:36.0484,y:-80.3276},
{x:42.6519,y:-95.2005},
{x:33.8908,y:-84.4762},
{x:33.7275,y:-116.2682},
{x:40.8978,y:-74.1},
{x:43.6179,y:-116.9278},
{x:46.5859,y:-120.5405},
{x:41.3684,y:-85.0387},
{x:39.7008,y:-105.3276},
{x:29.9537,y:-90.0732},
{x:43.0158,y:-123.2921},
{x:43.2131,y:-123.3337},
{x:33.7748,y:-118.1523},
{x:32.824,y:-97.3166},
{x:33.9035,y:-80.0963},
{x:30.5147,y:-89.6628},
{x:34.0786,y:-83.9214},
{x:33.8891,y:-84.3115},
{x:41.4475,y:-85.2245},
{x:42.9985,y:-89.1996},
{x:41.5828,y:-73.0367},
{x:38.0641,y:-85.6949},
{x:26.1495,y:-97.6417},
{x:33.726,y:-90.5467},
{x:27.9836,y:-82.3013},
{x:39.9385,y:-82.7849},
{x:18.3721,y:-66.1113},
{x:34.2193,y:-119.1599},
{x:40.5998,y:-112.4564},
{x:40.5045,y:-111.888},
{x:39.4654,y:-76.3123},
{x:34.9605,y:-82.4313},
{x:32.8954,y:-97.2872},
{x:38.972,y:-76.7737},
{x:36.1524,y:-80.2833},
{x:34.0109,y:-81.0217},
{x:47.3128,y:-122.314},
{x:36.0836,y:-115.0318},
{x:47.757,y:-122.6626},
{x:40.7661,y:-81.5232},
{x:39.2467,y:-82.4791},
{x:32.914,y:-97.3053},
{x:36.1194,y:-81.8399},
{x:38.839,y:-88.7803},
{x:38.6344,y:-121.5032},
{x:41.4179,y:-81.8968},
{x:35.2705,y:-116.7011},
{x:21.3601,y:-157.8909},
{x:21.3419,y:-157.9528},
{x:36.9044,y:-111.487},
{x:42.1006,y:-80.1066},
{x:32.7745,y:-97.2922},
{x:32.3542,y:-111.0908},
{x:42.0577,y:-71.8583},
{x:40.8076,y:-72.954},
{x:47.9682,y:-122.2013},
{x:38.884,y:-77.0245},
{x:33.3404,y:-111.8423},
{x:40.7873,y:-76.5052},
{x:30.4531,y:-97.6044},
{x:41.885,y:-87.8638},
{x:47.8211,y:-122.3365},
{x:45.5594,y:-122.5528},
{x:39.3595,y:-76.407},
{x:37.0031,y:-93.2262},
{x:31.7947,y:-106.3766},
{x:40.1118,y:-88.1593},
{x:45.4291,y:-122.5407},
{x:39.7437,y:-104.4356},
{x:41.3049,y:-84.3629},
{x:41.4598,y:-84.5491},
{x:41.386,y:-84.121},
{x:47.1047,y:-122.2935},
{x:32.7646,y:-117.1973},
{x:32.6833,y:-114.6489},
{x:32.0513,y:-110.7077},
{x:45.6066,y:-94.4519},
{x:36.2178,y:-79.7871},
{x:33.5046,y:-86.7988},
{x:35.7751,y:-119.2305},
{x:35.6074,y:-117.6713},
{x:29.2617,y:-81.0259},
{x:34.2286,y:-95.6305},
{x:35.4791,y:-97.5221},
{x:33.9408,y:-117.9834},
{x:41.6165,y:-83.7007},
{x:28.5484,y:-81.7469},
{x:27.7805,y:-82.6998},
{x:34.2858,y:-89.0242},
{x:39.8877,y:-86.1194},
{x:33.946,y:-117.4169},
{x:41.8927,y:-87.6287},
{x:42.3691,y:-71.2198},
{x:32.5199,y:-84.8896},
{x:33.6452,y:-97.1359},
{x:30.1952,y:-93.2461},
{x:37.9816,y:-91.7194},
{x:47.3582,y:-122.1684},
{x:33.7615,y:-96.5886},
{x:38.7519,y:-89.6819},
{x:27.8946,y:-97.3062},
{x:26.1902,y:-97.7142},
{x:45.4906,y:-122.5785},
{x:32.5831,y:-117.1},
{x:35.9273,y:-80.5881},
{x:32.4741,y:-95.3879},
{x:38.2153,y:-85.6747},
{x:47.4977,y:-97.324},
{x:42.4696,y:-122.8132},
{x:34.1835,y:-118.1595},
{x:44.6774,y:-90.1794},
{x:45.5447,y:-122.579},
{x:42.8169,y:-106.3743},
{x:31.792,y:-106.2604},
{x:37.7715,y:-120.8327},
{x:33.1261,y:-96.8883},
{x:33.1759,y:-96.888},
{x:42.1951,y:-121.7574},
{x:36.2137,y:-83.2551},
{x:33.0932,y:-97.1317},
{x:41.76,y:-71.3414},
{x:37.0643,y:-88.6385},
{x:42.386,y:-71.1141},
{x:42.6292,y:-89.2583},
{x:33.8895,y:-118.1445},
{x:39.9802,y:-75.9337},
{x:44.3798,y:-100.3115},
{x:32.6516,y:-97.3679},
{x:37.2536,y:-121.9526},
{x:43.0945,y:-75.3119},
{x:32.5232,y:-97.3479},
{x:38.0887,y:-85.664},
{x:45.0993,y:-92.5398},
{x:35.1066,y:-84.6624},
{x:32.6665,y:-97.2088},
{x:30.4727,y:-90.9544},
{x:30.178,y:-92.0061},
{x:30.5208,y:-92.066},
{x:34.819,y:-82.2679},
{x:45.0219,y:-93.1832},
{x:39.9096,y:-86.0992},
{x:41.1248,y:-73.7811},
{x:34.0701,y:-117.2094},
{x:30.4378,y:-91.0047},
{x:39.0391,y:-76.439},
{x:35.9074,y:-88.7519},
{x:41.1106,y:-83.2086},
{x:41.8768,y:-80.1739},
{x:40.0582,y:-80.6049},
{x:30.4482,y:-84.2623},
{x:40.0686,y:-80.7692},
{x:39.8222,y:-84.1456},
{x:43.1963,y:-88.1429},
{x:33.3139,y:-85.7443},
{x:35.3542,y:-119.018},
{x:35.3142,y:-119.0916},
{x:47.1066,y:-88.5892},
{x:32.4929,y:-87.8494},
{x:47.4656,y:-122.2466},
{x:41.2617,y:-82.6162},
{x:40.6297,y:-74.1097},
{x:41.0931,y:-84.2657},
{x:37.9789,y:-78.2107},
{x:39.396,y:-77.4111},
{x:32.4621,y:-99.7058},
{x:39.1935,y:-78.2061},
{x:40.8132,y:-73.2906},
{x:40.6098,y:-112.0046},
{x:30.727,y:-90.5278},
{x:41.0561,y:-83.6725},
{x:44.0578,y:-122.9877},
{x:28.1469,y:-82.7414},
{x:41.9801,y:-89.5792},
{x:42.316,y:-89.0341},
{x:29.6501,y:-95.1677},
{x:29.691,y:-95.2021},
{x:28.6039,y:-81.5486},
{x:42.5268,y:-88.6001},
{x:40.203,y:-105.1025},
{x:34.1733,y:-97.2595},
{x:38.962,y:-77.4966},
{x:39.3307,y:-77.3611},
{x:32.3461,y:-88.7278},
{x:30.6469,y:-81.458},
{x:39.9524,y:-82.9794},
{x:43.0335,y:-76.1912},
{x:45.8215,y:-92.9811},
{x:36.6956,y:-121.5976},
{x:36.5434,y:-121.9043},
{x:40.6108,y:-111.9396},
{x:39.7479,y:-82.6576},
{x:34.1228,y:-117.1952},
{x:64.8586,y:-147.8293},
{x:38.4593,y:-121.4155},
{x:39.4391,y:-87.3363},
{x:36.3208,y:-95.8258},
{x:40.7139,y:-111.8553},
{x:35.8135,y:-95.6747},
{x:40.0133,y:-86.9077},
{x:36.1121,y:-115.0629},
{x:30.1641,y:-81.6321},
{x:39.746,y:-82.65},
{x:33.9595,y:-118.3948},
{x:38.5289,y:-81.9014},
{x:33.6811,y:-84.2267},
{x:37.2899,y:-121.8479},
{x:40.4113,y:-75.5107},
{x:39.0098,y:-77.4934},
{x:35.5508,y:-88.8104},
{x:61.2356,y:-149.7663},
{x:36.1459,y:-80.3005},
{x:39.8952,y:-84.0478},
{x:43.423,y:-88.3398},
{x:36.8644,y:-81.4347},
{x:37.7691,y:-122.3902},
{x:42.7353,y:-90.4389},
{x:40.1221,y:-84.6196},
{x:39.8401,y:-89.6011},
{x:39.335,y:-82.0678},
{x:39.2229,y:-85.9128},
{x:35.7148,y:-84.0127},
{x:35.3762,y:-97.423},
{x:41.4665,y:-87.0385},
{x:28.1366,y:-81.9737},
{x:40.1856,y:-88.3695},
{x:36.3263,y:-119.211},
{x:36.341,y:-119.3936},
{x:29.6899,y:-95.8993},
{x:37.2884,y:-120.4542},
{x:38.6613,y:-75.5922},
{x:39.9174,y:-83.1208},
{x:29.5071,y:-95.0426},
{x:30.0208,y:-95.1022},
{x:34.7255,y:-86.6269},
{x:18.4845,y:-66.7951},
{x:30.4915,y:-92.4469},
{x:46.0968,y:-88.3545},
{x:44.4855,y:-87.9172},
{x:25.8622,y:-80.1936},
{x:25.9551,y:-80.2461},
{x:25.8481,y:-80.2435},
{x:26.2744,y:-80.1371},
{x:44.1574,y:-88.4635},
{x:36.06,y:-90.5253},
{x:34.5595,y:-118.0467},
{x:41.5854,y:-81.2268},
{x:26.2667,y:-98.2005},
{x:45.5171,y:-122.6575},
{x:43.0426,y:-89.3498},
{x:34.0281,y:-117.8338},
{x:34.5079,y:-88.2079},
{x:41.6772,y:-72.1686},
{x:38.2149,y:-85.5397},
{x:34.2197,y:-77.8184},
{x:40.0341,y:-85.9983},
{x:41.6282,y:-86.2505},
{x:34.9922,y:-89.9984},
{x:47.6758,y:-117.3264},
{x:40.7538,y:-73.872},
{x:42.3764,y:-77.3636},
{x:18.2017,y:-67.1395},
{x:39.6065,y:-119.2426},
{x:18.4464,y:-67.1131},
{x:29.2899,y:-97.1528},
{x:43.049,y:-82.4589},
{x:42.9746,y:-83.7725},
{x:32.7486,y:-97.0783},
{x:18.4074,y:-66.002},
{x:18.4256,y:-66.0578},
{x:42.6949,y:-87.8669},
{x:38.9427,y:-76.9073},
{x:44.9022,y:-93.7475},
{x:38.898,y:-82.5709},
{x:41.4618,y:-81.6928},
{x:41.3859,y:-81.6414},
{x:30.1791,y:-81.5559},
{x:30.5703,y:-96.309},
{x:30.5877,y:-96.3387},
{x:40.5368,y:-104.9914},
{x:37.3981,y:-121.9241},
{x:41.3697,y:-89.0097},
{x:42.3724,y:-71.1207},
{x:36.0818,y:-86.7254},
{x:39.1222,y:-76.7728},
{x:45.8503,y:-119.2901},
{x:33.4113,y:-86.6654},
{x:40.6069,y:-74.2874},
{x:41.6277,y:-72.7554},
{x:33.8251,y:-117.9172},
{x:33.8837,y:-118.1252},
{x:41.4688,y:-81.9087},
{x:32.6868,y:-114.5831},
{x:33.4636,y:-94.2048},
{x:30.4196,y:-87.6838},
{x:37.9825,y:-87.6009},
{x:32.7916,y:-96.5953},
{x:41.6223,y:-80.2139},
{x:32.536,y:-94.9424},
{x:32.8604,y:-93.9866},
{x:32.5302,y:-93.7034},
{x:32.4623,y:-90.1117},
{x:27.4526,y:-99.4767},
{x:36.3129,y:-76.2201},
{x:41.3091,y:-73.6949},
{x:41.3649,y:-81.7152},
{x:42.7708,y:-73.822},
{x:42.7848,y:-73.6732},
{x:39.4843,y:-79.0458},
{x:38.7003,y:-90.2889},
{x:34.0403,y:-118.1624},
{x:46.3704,y:-94.2457},
{x:35.489,y:-81.2449},
{x:39.5194,y:-94.7833},
{x:35.1428,y:-90.006},
{x:40.5911,y:-75.6317},
{x:44.8201,y:-93.2055},
{x:44.7714,y:-93.3055},
{x:32.356,y:-89.4809},
{x:40.4617,y:-104.8863},
{x:34.3421,y:-86.5006},
{x:18.4494,y:-66.1769},
{x:32.1315,y:-94.8045},
{x:39.4643,y:-77.9409},
{x:33.8473,y:-117.5434},
{x:33.7219,y:-84.4084},
{x:42.0723,y:-88.1916},
{x:41.5852,y:-87.7201},
{x:41.3022,y:-72.3757},
{x:41.9932,y:-88.0276},
{x:39.6975,y:-86.1846},
{x:31.9871,y:-81.0826},
{x:28.1064,y:-81.623},
{x:38.5736,y:-121.4104},
{x:29.163,y:-81.5355},
{x:40.7809,y:-81.9673},
{x:39.8634,y:-85.9696},
{x:38.7863,y:-84.8839},
{x:40.0002,y:-104.9789},
{x:28.2045,y:-82.6601},
{x:30.7042,y:-86.1247},
{x:39.1792,y:-76.6685},
{x:41.3717,y:-73.418},
{x:40.4151,y:-79.8387},
{x:39.3985,y:-76.9314},
{x:32.5323,y:-94.7853},
{x:39.4225,y:-80.1953},
{x:41.8247,y:-71.4129},
{x:38.1221,y:-122.2532},
{x:35.2813,y:-86.3792},
{x:27.2068,y:-81.8296},
{x:40.8619,y:-82.2715},
{x:44.8788,y:-123.0265},
{x:41.5853,y:-71.9394},
{x:41.9042,y:-71.0324},
{x:32.6714,y:-97.0906},
{x:45.3824,y:-122.583},
{x:30.2173,y:-84.3609},
{x:31.3193,y:-86.4492},
{x:42.6412,y:-83.0337},
{x:39.4333,y:-88.3792},
{x:28.6071,y:-81.1986},
{x:38.6087,y:-122.873},
{x:38.412,y:-96.1809},
{x:42.5536,y:-82.9903},
{x:36.0554,y:-115.2417},
{x:36.1284,y:-115.3146},
{x:36.2865,y:-115.1792},
{x:31.7085,y:-89.1485},
{x:36.134,y:-83.7157},
{x:34.155,y:-114.2774},
{x:38.233,y:-104.6652},
{x:32.906,y:-97.189},
{x:21.3583,y:-157.962},
{x:37.1436,y:-79.2694},
{x:37.0001,y:-91.7051},
{x:32.4151,y:-90.11},
{x:39.2888,y:-76.5361},
{x:35.6384,y:-97.5507},
{x:43.6531,y:-70.2799},
{x:43.9642,y:-90.3759},
{x:36.7615,y:-121.7541},
{x:41.9612,y:-87.9409},
{x:40.7284,y:-89.6154},
{x:45.3963,y:-92.993},
{x:34.3027,y:-78.7243},
{x:33.7415,y:-116.2153},
{x:33.9488,y:-116.9771},
{x:34.6234,y:-92.8636},
{x:34.3136,y:-85.1716},
{x:38.4334,y:-78.9103},
{x:33.4666,y:-112.0307},
{x:48.1831,y:-103.6256},
{x:41.7204,y:-88.3731},
{x:32.8025,y:-91.9021},
{x:32.4457,y:-91.4929},
{x:38.6349,y:-95.839},
{x:45.5492,y:-94.2339},
{x:44.8334,y:-93.1697},
{x:36.2839,y:-94.1534},
{x:38.7542,y:-121.3105},
{x:31.3644,y:-110.9414},
{x:42.6493,y:-114.4454},
{x:32.768,y:-89.1274},
{x:45.4573,y:-98.1019},
{x:45.4565,y:-98.4361},
{x:32.5789,y:-83.7187},
{x:42.8016,y:-71.2389},
{x:40.692,y:-73.8616},
{x:39.7335,y:-75.6187},
{x:40.4356,y:-104.9868},
{x:27.9897,y:-82.7742},
{x:41.705,y:-88.3033},
{x:37.1987,y:-112.9917},
{x:33.757,y:-84.2681},
{x:32.4341,y:-87.0623},
{x:43.2497,y:-75.1836},
{x:38.69,y:-121.1215},
{x:29.9531,y:-90.0653},
{x:37.8932,y:-122.0599},
{x:43.1265,y:-77.5645},
{x:41.7496,y:-87.6829},
{x:41.7728,y:-87.5862},
{x:41.2706,y:-72.9775},
{x:41.2664,y:-82.2235},
{x:18.1689,y:-67.1507},
{x:30.9575,y:-85.1625},
{x:43.0649,y:-92.6807},
{x:38.5667,y:-90.4969},
{x:38.5818,y:-77.3105},
{x:43.9574,y:-86.401},
{x:36.177,y:-76.4612},
{x:31.7516,y:-106.3893},
{x:37.4102,y:-79.913},
{x:34.573,y:-87.0115},
{x:41.3965,y:-75.6877},
{x:42.3855,y:-87.9654},
{x:36.9775,y:-77.3955},
{x:34.0986,y:-117.7001},
{x:41.4553,y:-74.3782},
{x:61.2278,y:-149.7425},
{x:64.8253,y:-147.6344},
{x:36.3356,y:-84.1791},
{x:40.3823,y:-74.5739},
{x:29.5412,y:-95.1277},
{x:41.7482,y:-71.4224},
{x:35.8815,y:-78.8514},
{x:36.1068,y:-79.7528},
{x:41.2497,y:-75.8127},
{x:42.3692,y:-88.2709},
{x:35.8736,y:-80.0419},
{x:36.1072,y:-87.6339},
{x:40.768,y:-73.2616},
{x:34.1227,y:-83.7628},
{x:33.2325,y:-84.2315},
{x:41.6917,y:-71.456},
{x:41.946,y:-87.7095},
{x:34.1798,y:-118.3832},
{x:34.0531,y:-118.2661},
{x:34.0564,y:-118.2506},
{x:39.5231,y:-83.4565},
{x:43.0732,y:-89.4093},
{x:32.92,y:-85.959},
{x:32.2977,y:-95.2594},
{x:32.1451,y:-95.3154},
{x:45.1186,y:-93.4085},
{x:30.2215,y:-81.5919},
{x:32.6499,y:-96.5347},
{x:35.0483,y:-89.8862},
{x:34.6678,y:-86.0067},
{x:35.6532,y:-97.4592},
{x:34.2474,y:-83.4568},
{x:37.281,y:-98.5868},
{x:41.5001,y:-81.6979},
{x:42.7578,y:-84.5431},
{x:30.3881,y:-95.4072},
{x:30.4327,y:-91.0834},
{x:35.2329,y:-80.8494},
{x:39.0868,y:-76.8775},
{x:45.3545,y:-84.2358},
{x:44.3145,y:-69.8011},
{x:30.2584,y:-94.2167},
{x:39.7034,y:-83.933},
{x:35.4561,y:-82.5224},
{x:40.8015,y:-73.944},
{x:35.235,y:-81.2028},
{x:39.4528,y:-83.7803},
{x:30.8603,y:-83.9452},
{x:37.3061,y:-89.5269},
{x:48.6072,y:-118.0725},
{x:47.4935,y:-111.2196},
{x:29.6837,y:-95.2768},
{x:40.5328,y:-74.3944},
{x:35.1092,y:-106.7306},
{x:41.0275,y:-89.0501},
{x:40.3391,y:-78.3954},
{x:39.6557,y:-80.8581},
{x:36.0889,y:-88.1088},
{x:42.9725,y:-85.7757},
{x:34.0259,y:-117.1189},
{x:33.6717,y:-78.912},
{x:39.5634,y:-84.2747},
{x:37.7326,y:-85.8858},
{x:41.7789,y:-87.6424},
{x:42.114,y:-96.7057},
{x:45.6601,y:-97.0284},
{x:47.9424,y:-122.1067},
{x:37.708,y:-85.8418},
{x:47.8697,y:-122.01},
{x:38.9312,y:-94.3632},
{x:36.3082,y:-93.0184},
{x:34.1843,y:-118.8746},
{x:33.3804,y:-111.6007},
{x:41.4591,y:-81.0852},
{x:33.8579,y:-79.0912},
{x:44.6376,y:-123.074},
{x:38.449,y:-77.4626},
{x:37.0009,y:-93.0971},
{x:30.2665,y:-87.69},
{x:35.1804,y:-85.5274},
{x:36.1224,y:-78.668},
{x:44.8457,y:-74.321},
{x:34.1511,y:-118.0739},
{x:33.1307,y:-117.2594},
{x:35.6151,y:-120.6792},
{x:34.1664,y:-84.3333},
{x:43.0974,y:-89.5246},
{x:33.9221,y:-117.5602},
{x:33.5143,y:-82.5032},
{x:35.0752,y:-89.9507},
{x:36.0179,y:-95.9766},
{x:37.0771,y:-94.1059},
{x:36.145,y:-97.0513},
{x:32.9775,y:-117.0828},
{x:45.1969,y:-93.3366},
{x:30.4519,y:-91.1267},
{x:39.1303,y:-82.9809},
{x:38.6957,y:-120.8126},
{x:43.6635,y:-93.3261},
{x:42.1883,y:-83.735},
{x:40.9241,y:-77.0271},
{x:29.5861,y:-95.2115},
{x:41.8255,y:-73.114},
{x:30.5195,y:-90.4975},
{x:38.9268,y:-94.4111},
{x:42.5582,y:-87.8536},
{x:37.4031,y:-120.8487},
{x:30.0432,y:-95.6088},
{x:30.1551,y:-95.2128},
{x:40.8245,y:-73.4429},
{x:47.7584,y:-122.2466},
{x:44.1978,y:-72.5022},
{x:47.4981,y:-122.6541},
{x:32.9505,y:-97.1345},
{x:39.9297,y:-104.9085},
{x:34.0555,y:-117.706},
{x:34.5074,y:-117.3999},
{x:34.0397,y:-117.1558},
{x:43.7519,y:-87.9446},
{x:33.975,y:-117.5443},
{x:42.0436,y:-86.5146},
{x:34.0341,y:-117.3128},
{x:41.3926,y:-72.8588},
{x:44.882,y:-93.1246},
{x:47.1415,y:-119.2747},
{x:41.209,y:-73.1309},
{x:42.1315,y:-87.8277},
{x:33.9249,y:-118.3784},
{x:38.6913,y:-87.5008},
{x:35.9325,y:-95.1454},
{x:29.6798,y:-82.4303},
{x:43.4133,y:-71.9826},
{x:39.9372,y:-120.9495},
{x:31.2578,y:-85.4322},
{x:34.9878,y:-97.3699},
{x:32.7856,y:-97.0946},
{x:38.7853,y:-77.5199},
{x:44.3334,y:-98.2168},
{x:41.1884,y:-76.9143},
{x:38.7243,y:-94.4516},
{x:44.0339,y:-103.2177},
{x:45.8823,y:-95.3778},
{x:42.0065,y:-85.4564},
{x:18.2052,y:-67.1406},
{x:30.1955,y:-93.1763},
{x:45.5442,y:-122.3882},
{x:34.7963,y:-86.9725},
{x:44.0569,y:-123.0344},
{x:35.3891,y:-97.442},
{x:33.8169,y:-118.3771},
{x:35.7692,y:-80.101},
{x:38.491,y:-85.7699},
{x:30.4383,y:-87.2767},
{x:40.1511,y:-105.1314},
{x:42.2979,y:-88.4246},
{x:32.5188,y:-93.7154},
{x:30.3932,y:-95.3287},
{x:32.6818,y:-92.0472},
{x:33.6392,y:-91.8014},
{x:47.3416,y:-122.3018},
{x:27.0244,y:-82.0478},
{x:31.8022,y:-94.1938},
{x:35.1425,y:-86.5696},
{x:32.1797,y:-81.4617},
{x:42.0893,y:-88.0445},
{x:31.3262,y:-89.3677},
{x:41.9338,y:-89.068},
{x:31.1044,y:-97.5109},
{x:43.5957,y:-85.1377},
{x:28.5171,y:-81.6196},
{x:42.0306,y:-88.2373},
{x:29.4129,y:-95.2435},
{x:36.9823,y:-120.0864},
{x:44.7004,y:-75.4624},
{x:18.235,y:-66.0579},
{x:40.7793,y:-74.1878},
{x:40.3386,y:-80.0243},
{x:33.9907,y:-83.757},
{x:38.2726,y:-121.9584},
{x:47.654,y:-118.1506},
{x:32.6958,y:-117.1329},
{x:32.7474,y:-116.9596},
{x:36.1595,y:-109.5777},
{x:40.7426,y:-74.1736},
{x:30.048,y:-95.3874},
{x:31.1845,y:-85.4653},
{x:34.539,y:-83.9728},
{x:41.3814,y:-81.7797},
{x:18.3889,y:-66.1701},
{x:45.4044,y:-122.2914},
{x:35.7035,y:-82.5793},
{x:30.3803,y:-95.6465},
{x:34.1869,y:-118.6433},
{x:41.8226,y:-87.69},
{x:34.0909,y:-118.3226},
{x:36.1494,y:-86.8596},
{x:33.4258,y:-84.2846},
{x:18.4631,y:-66.7215},
{x:39.1018,y:-90.3131},
{x:38.9055,y:-77.0217},
{x:26.2759,y:-80.2027},
{x:18.4313,y:-66.0162},
{x:33.9827,y:-117.3394},
{x:41.7154,y:-80.1463},
{x:33.7277,y:-117.9048},
{x:35.7242,y:-78.5468},
{x:29.3438,y:-99.1268},
{x:29.5984,y:-95.2648},
{x:29.545,y:-95.7528},
{x:30.2541,y:-92.269},
{x:34.0265,y:-118.199},
{x:47.1796,y:-122.5401},
{x:38.9672,y:-76.7914},
{x:39.7408,y:-105.2},
{x:29.8267,y:-98.5721},
{x:31.4743,y:-96.9252},
{x:39.2294,y:-84.2585},
{x:31.5946,y:-90.4548},
{x:30.2377,y:-95.3},
{x:29.656,y:-95.7147},
{x:33.7671,y:-117.9385},
{x:41.5221,y:-83.4664},
{x:33.4512,y:-111.803},
{x:33.975,y:-118.2656},
{x:42.0037,y:-83.3555},
{x:41.7143,y:-86.9072},
{x:32.5594,y:-93.7113},
{x:44.8897,y:-89.573},
{x:30.1926,y:-95.5899},
{x:35.6364,y:-78.8341},
{x:38.8449,y:-77.6397},
{x:40.316,y:-74.4291},
{x:35.9701,y:-77.9349},
{x:34.926,y:-77.7901},
{x:40.8192,y:-74.0652},
{x:34.7509,y:-95.0472},
{x:38.8699,y:-76.848},
{x:36.0447,y:-86.957},
{x:38.7326,y:-77.5504},
{x:29.7109,y:-95.3144},
{x:42.2754,y:-74.9174},
{x:33.5105,y:-111.9115},
{x:29.4078,y:-94.9308},
{x:45.8535,y:-111.3368},
{x:35.1612,y:-101.9213},
{x:36.0512,y:-115.1716},
{x:36.2769,y:-115.2081},
{x:35.0287,y:-106.7149},
{x:25.9292,y:-80.1356},
{x:30.1872,y:-85.7701},
{x:35.5453,y:-89.6654},
{x:37.1438,y:-76.5122},
{x:40.8354,y:-75.988},
{x:38.6781,y:-121.7891},
{x:36.0743,y:-76.6106},
{x:33.1411,y:-88.5521},
{x:41.1932,y:-73.4335},
{x:36.7132,y:-121.652},
{x:36.6585,y:-121.6604},
{x:38.9264,y:-94.8367},
{x:40.4849,y:-89.0243},
{x:36.2007,y:-115.1912},
{x:41.4947,y:-120.5431},
{x:33.838,y:-84.2003},
{x:38.3444,y:-77.4957},
{x:40.5758,y:-73.9808},
{x:38.7828,y:-95.19},
{x:37.1763,y:-113.2984},
{x:34.3923,y:-118.4708},
{x:43.7155,y:-87.7552},
{x:45.0338,y:-93.4092},
{x:31.8127,y:-106.431},
{x:31.8277,y:-106.3819},
{x:33.9434,y:-83.9833},
{x:36.2173,y:-115.1192},
{x:34.9533,y:-120.416},
{x:41.525,y:-76.4001},
{x:41.0406,y:-111.941},
{x:43.1752,y:-83.7775},
{x:33.933,y:-96.4283},
{x:32.3827,y:-86.2001},
{x:40.8271,y:-73.9506},
{x:34.7823,y:-84.8258},
{x:33.7334,y:-84.8992},
{x:32.2847,y:-106.417},
{x:39.0669,y:-104.8538},
{x:38.9803,y:-105.0436},
{x:38.8482,y:-104.8473},
{x:38.2098,y:-84.253},
{x:38.3779,y:-84.3066},
{x:42.0632,y:-71.2495},
{x:38.6182,y:-107.9844},
{x:32.9071,y:-96.4179},
{x:35.0946,y:-85.3312},
{x:48.0149,y:-122.0629},
{x:33.573,y:-84.3427},
{x:37.7806,y:-122.4803},
{x:26.2267,y:-97.7185},
{x:42.5468,y:-71.9146},
{x:32.9218,y:-96.9952},
{x:42.3328,y:-83.2103},
{x:40.8465,y:-73.8936},
{x:40.6844,y:-73.9774},
{x:30.6607,y:-88.2074},
{x:31.1374,y:-81.575},
{x:30.0318,y:-89.9738},
{x:37.0326,y:-100.9213},
{x:25.7594,y:-80.4311},
{x:32.3896,y:-94.8714},
{x:18.4292,y:-66.1657},
{x:46.0963,y:-88.6314},
{x:43.0269,y:-73.3765},
{x:41.7606,y:-88.2436},
{x:30.1056,y:-94.1701},
{x:34.1986,y:-118.3267},
{x:35.5706,y:-82.502},
{x:38.8708,y:-121.2959},
{x:26.1692,y:-80.2974},
{x:33.0949,y:-87.6853},
{x:38.6414,y:-90.2461},
{x:25.8094,y:-80.1951},
{x:35.9203,y:-78.8108},
{x:39.9657,y:-83.0018},
{x:34.205,y:-110.0201},
{x:42.1263,y:-71.0684},
{x:26.2016,y:-98.3272},
{x:40.7033,y:-74.0111},
{x:33.182,y:-87.5548},
{x:34.2685,y:-119.2487},
{x:35.3921,y:-119.0927},
{x:41.6724,y:-84.553},
{x:38.9479,y:-99.5606},
{x:29.6915,y:-90.5543},
{x:35.1816,y:-97.4176},
{x:35.3839,y:-119.0549},
{x:41.9483,y:-97.2246},
{x:28.1916,y:-82.4657},
{x:30.2947,y:-92.0288},
{x:41.497,y:-88.1678},
{x:41.5123,y:-91.7139},
{x:40.5176,y:-76.112},
{x:29.2679,y:-82.1983},
{x:30.4202,y:-91.2165},
{x:41.915,y:-80.5706},
{x:42.0682,y:-71.0487},
{x:35.4365,y:-80.6801},
{x:47.8424,y:-119.9885},
{x:38.7884,y:-121.2066},
{x:41.8134,y:-88.2803},
{x:38.1028,y:-85.8701},
{x:39.1219,y:-76.8458},
{x:33.759,y:-118.0822},
{x:29.4496,y:-98.4555},
{x:39.6843,y:-83.9395},
{x:43.0663,y:-115.8552},
{x:31.0442,y:-93.2182},
{x:40.8861,y:-73.9096},
{x:43.06,y:-83.8091},
{x:35.7813,y:-81.3946},
{x:39.6298,y:-77.7828},
{x:37.5,y:-77.0895},
{x:41.3421,y:-86.2625},
{x:39.8849,y:-104.7753},
{x:42.5588,y:-83.4427},
{x:46.9547,y:-122.9352},
{x:29.6765,y:-98.156},
{x:42.7859,y:-83.2441},
{x:39.5952,y:-105.0719},
{x:34.253,y:-77.9569},
{x:41.9894,y:-88.1488},
{x:35.1997,y:-80.7729},
{x:47.4472,y:-122.1526},
{x:36.2194,y:-115.3257},
{x:36.2401,y:-115.1157},
{x:36.0692,y:-115.2984},
{x:34.0482,y:-84.6984},
{x:26.1065,y:-80.178},
{x:37.8257,y:-121.2365},
{x:47.7192,y:-122.3445},
{x:32.8014,y:-97.1832},
{x:40.7824,y:-76.3422},
{x:42.2926,y:-85.6818},
{x:36.1066,y:-86.9227},
{x:32.9083,y:-96.1253},
{x:47.7642,y:-122.2083},
{x:37.3738,y:-121.8347},
{x:34.6886,y:-118.0979},
{x:40.5707,y:-85.7653},
{x:40.3928,y:-84.3755},
{x:18.0307,y:-66.5938},
{x:18.3995,y:-65.9676},
{x:46.2096,y:-119.157},
{x:37.6545,y:-113.0854},
{x:40.256,y:-74.2917},
{x:39.4639,y:-104.6511},
{x:38.9114,y:-94.4505},
{x:41.4156,y:-84.1049},
{x:41.8378,y:-87.7443},
{x:41.2609,y:-76.9171},
{x:41.6312,y:-93.7802},
{x:37.6785,y:-97.4955},
{x:28.048,y:-81.909},
{x:38.4865,y:-90.7591},
{x:45.8482,y:-122.8333},
{x:38.3711,y:-90.3861},
{x:34.0495,y:-118.0824},
{x:46.8616,y:-96.8835},
{x:39.3488,y:-82.9968},
{x:41.6702,y:-72.8598},
{x:34.1807,y:-103.3433},
{x:41.2434,y:-96.0236},
{x:39.9868,y:-88.262},
{x:26.1657,y:-81.686},
{x:34.1442,y:-89.6352},
{x:42.4327,y:-71.4543},
{x:40.711,y:-94.2371},
{x:39.9154,y:-104.9405},
{x:42.724,y:-71.1603},
{x:40.43,y:-80.007},
{x:32.4857,y:-97.006},
{x:36.0167,y:-78.889},
{x:36.9125,y:-76.2525},
{x:29.8977,y:-95.4281},
{x:38.8251,y:-77.0224},
{x:35.053,y:-106.591},
{x:39.0964,y:-79.6753},
{x:39.0176,y:-79.9395},
{x:35.2754,y:-81.1355},
{x:38.1675,y:-121.1448},
{x:34.122,y:-117.592},
{x:40.667,y:-111.8391},
{x:25.4607,y:-80.4753},
{x:38.6009,y:-81.9949},
{x:38.3512,y:-82.4528},
{x:39.2704,y:-94.4504},
{x:30.1242,y:-95.4083},
{x:38.7385,y:-104.7968},
{x:47.0892,y:-122.5973},
{x:38.2696,y:-121.9497},
{x:29.9227,y:-82.7149},
{x:32.6752,y:-107.155},
{x:36.047,y:-95.8506},
{x:43.6406,y:-95.9343},
{x:34.0701,y:-117.2617},
{x:29.7834,y:-95.6015},
{x:39.7435,y:-84.1829},
{x:26.2195,y:-98.2612},
{x:40.8506,y:-74.229},
{x:42.498,y:-73.6743},
{x:35.486,y:-96.6861},
{x:39.4946,y:-76.1444},
{x:39.4745,y:-118.7883},
{x:39.128,y:-84.5152},
{x:39.6379,y:-104.7626},
{x:26.1975,y:-98.2591},
{x:26.1982,y:-98.2882},
{x:43.0008,y:-76.1425},
{x:39.1055,y:-76.4669},
{x:38.9677,y:-77.0276},
{x:30.5766,y:-90.9569},
{x:42.2152,y:-83.4861},
{x:36.0643,y:-79.6118},
{x:35.9667,y:-78.4954},
{x:36.1914,y:-86.2914},
{x:41.1732,y:-80.5691},
{x:35.8933,y:-78.5106},
{x:39.1155,y:-94.9404},
{x:35.5127,y:-84.9985},
{x:28.2218,y:-82.2476},
{x:47.3234,y:-122.1732},
{x:37.5944,y:-120.9932},
{x:38.7313,y:-83.8367},
{x:32.6228,y:-85.406},
{x:31.938,y:-106.4083},
{x:39.8855,y:-82.7592},
{x:39.6679,y:-75.7782},
{x:35.1273,y:-118.4687},
{x:42.0245,y:-71.125},
{x:37.6518,y:-122.4911},
{x:37.1217,y:-92.1003},
{x:34.1687,y:-117.3338},
{x:33.8451,y:-117.2427},
{x:33.5562,y:-117.1548},
{x:45.6935,y:-121.8832},
{x:36.8419,y:-94.6115},
{x:30.2932,y:-91.9104},
{x:41.4942,y:-82.0447},
{x:35.6444,y:-120.6581},
{x:44.0288,y:-75.7793},
{x:38.6131,y:-121.5183},
{x:35.5032,y:-80.8633},
{x:40.4218,y:-104.7249},
{x:31.5811,y:-84.0855},
{x:38.595,y:-85.3168},
{x:44.0695,y:-70.4078},
{x:37.7813,y:-122.4171},
{x:32.7707,y:-89.0925},
{x:37.3403,y:-121.843},
{x:39.0933,y:-95.6723},
{x:36.7051,y:-119.5748},
{x:34.2115,y:-78.0221},
{x:41.6266,y:-81.4341},
{x:37.7103,y:-121.0778},
{x:33.761,y:-89.8093},
{x:32.8492,y:-115.5698},
{x:35.2469,y:-82.3292},
{x:36.1172,y:-83.8553},
{x:44.9086,y:-93.0804},
{x:35.9507,y:-84.1534},
{x:41.7248,y:-86.177},
{x:41.6305,y:-86.1775},
{x:33.9202,y:-117.4901},
{x:37.4648,y:-121.1438},
{x:44.6963,y:-93.2143},
{x:38.9543,y:-77.368},
{x:40.3713,y:-80.6874},
{x:29.5809,y:-95.391},
{x:36.6297,y:-79.3983},
{x:37.4898,y:-122.2232},
{x:33.5492,y:-101.9064},
{x:35.3396,y:-119.0349},
{x:35.3973,y:-118.974},
{x:41.9241,y:-87.7563},
{x:38.1787,y:-83.555},
{x:25.8998,y:-80.2112},
{x:33.471,y:-79.1046},
{x:34.1855,y:-118.3494},
{x:29.9434,y:-93.9919},
{x:38.9715,y:-94.7395},
{x:35.3181,y:-119.0049},
{x:33.8038,y:-116.9706},
{x:29.8745,y:-90.047},
{x:44.2682,y:-88.4715},
{x:39.7552,y:-121.8647},
{x:39.8893,y:-94.3597},
{x:39.9556,y:-104.7751},
{x:39.7269,y:-121.8036},
{x:41.5486,y:-83.5939},
{x:42.6772,y:-73.8244},
{x:41.2441,y:-111.977},
{x:40.6999,y:-89.595},
{x:45.2327,y:-94.0043},
{x:29.584,y:-96.3399},
{x:30.1276,y:-94.0136},
{x:44.4275,y:-124.0675},
{x:37.6504,y:-97.2794},
{x:38.7745,y:-92.2546},
{x:32.7573,y:-117.0382},
{x:26.1646,y:-81.7669},
{x:30.2314,y:-92.4624},
{x:31.0689,y:-97.6687},
{x:29.9309,y:-95.3037},
{x:30.1703,y:-93.2646},
{x:42.6532,y:-83.1336},
{x:30.4458,y:-88.6316},
{x:36.5315,y:-77.9448},
{x:34.0619,y:-118.2813},
{x:39.3599,y:-74.4326},
{x:35.0786,y:-80.9343},
{x:34.2814,y:-118.4541},
{x:39.3591,y:-82.9765},
{x:42.0958,y:-72.6043},
{x:30.2227,y:-95.6442},
{x:30.35,y:-95.4721},
{x:33.51,y:-112.1847},
{x:40.7526,y:-85.1664},
{x:32.8931,y:-96.4719},
{x:38.5026,y:-90.4448},
{x:30.3102,y:-89.7477},
{x:39.9614,y:-75.0526},
{x:33.8506,y:-98.5016},
{x:38.7975,y:-90.2325},
{x:37.3726,y:-79.1837},
{x:34.13,y:-118.0254},
{x:31.4604,y:-83.5279},
{x:28.8672,y:-81.9564},
{x:43.1808,y:-85.551},
{x:41.3319,y:-73.8101},
{x:37.9339,y:-121.3115},
{x:20.8377,y:-156.3421},
{x:41.8843,y:-87.6226},
{x:29.6513,y:-98.4452},
{x:29.3228,y:-98.4795},
{x:27.85,y:-97.5797},
{x:37.6963,y:-112.1729},
{x:41.137,y:-81.6588},
{x:44.7914,y:-91.4626},
{x:17.9954,y:-66.8922},
{x:18.3946,y:-66.1458},
{x:34.036,y:-117.9258},
{x:41.4606,y:-72.8225},
{x:40.7272,y:-73.7139},
{x:41.4841,y:-81.591},
{x:27.9676,y:-81.8804},
{x:41.8882,y:-88.1211},
{x:26.2724,y:-80.0977},
{x:27.4957,y:-82.6361},
{x:39.5356,y:-104.8482},
{x:37.8127,y:-77.6639},
{x:35.2908,y:-84.8174},
{x:35.3783,y:-80.7247},
{x:35.1461,y:-101.8844},
{x:29.7606,y:-95.349},
{x:38.0865,y:-83.8991},
{x:41.1363,y:-104.7067},
{x:39.9069,y:-88.8965},
{x:41.9686,y:-87.7129},
{x:43.0627,y:-89.3075},
{x:36.9199,y:-86.409},
{x:36.9285,y:-86.5463},
{x:36.9804,y:-86.396},
{x:29.8209,y:-95.4986},
{x:36.5242,y:-87.2236},
{x:39.7715,y:-94.8427},
{x:39.0144,y:-121.4327},
{x:38.5984,y:-121.4861},
{x:34.1731,y:-82.3768},
{x:36.5974,y:-87.2865},
{x:41.4134,y:-83.4547},
{x:34.5424,y:-82.6315},
{x:38.084,y:-122.2341},
{x:38.837,y:-104.7847},
{x:33.1431,y:-96.6777},
{x:39.5428,y:-77.9129},
{x:29.4448,y:-98.4944},
{x:35.4278,y:-78.0626},
{x:44.0205,y:-84.7986},
{x:44.7404,y:-92.8924},
{x:38.2353,y:-86.1309},
{x:37.3649,y:-79.2957},
{x:38.4391,y:-105.24},
{x:43.046,y:-87.9033},
{x:42.6432,y:-83.1992},
{x:33.3042,y:-111.7562},
{x:36.0539,y:-119.0269},
{x:35.8757,y:-83.572},
{x:48.5072,y:-122.3359},
{x:40.7684,y:-111.8917},
{x:44.3283,y:-94.506},
{x:39.7115,y:-105.0749},
{x:37.3431,y:-76.7406},
{x:43.1106,y:-74.2668},
{x:40.6821,y:-80.1387},
{x:34.1133,y:-117.7641},
{x:38.8966,y:-77.0511},
{x:25.9496,y:-80.1532},
{x:33.1969,y:-87.5297},
{x:28.0105,y:-82.7095},
{x:39.1004,y:-84.5091},
{x:36.8041,y:-76.3814},
{x:35.8313,y:-78.2963},
{x:36.2387,y:-80.8148},
{x:40.132,y:-74.8833},
{x:18.4729,y:-66.717},
{x:37.6437,y:-122.4526},
{x:33.7599,y:-117.8669},
{x:35.5695,y:-80.5723},
{x:35.6639,y:-97.3236},
{x:42.1023,y:-75.8436},
{x:33.8813,y:-96.5004},
{x:41.8591,y:-88.0924},
{x:37.0838,y:-80.6943},
{x:38.0047,y:-121.7343},
{x:29.3067,y:-94.7726},
{x:42.4224,y:-83.0421},
{x:41.7284,y:-88.0275},
{x:35.2196,y:-89.9118},
{x:34.0928,y:-117.2794},
{x:36.7094,y:-110.2517},
{x:41.2903,y:-87.3998},
{x:40.8714,y:-81.433},
{x:34.9326,y:-89.9996},
{x:33.9229,y:-118.0985},
{x:31.7953,y:-97.5886},
{x:35.1615,y:-89.7961},
{x:29.5305,y:-95.5078},
{x:41.5538,y:-81.5756},
{x:47.7039,y:-117.4121},
{x:38.2935,y:-77.5694},
{x:37.2277,y:-121.7753},
{x:33.2858,y:-79.6939},
{x:33.7254,y:-78.9358},
{x:32.8781,y:-79.8074},
{x:41.626,y:-93.6513},
{x:25.8216,y:-80.2822},
{x:29.723,y:-95.4586},
{x:43.5871,y:-96.7786},
{x:37.1501,y:-93.2794},
{x:33.1409,y:-117.1631},
{x:34.0733,y:-118.024},
{x:40.9641,y:-80.1413},
{x:33.6647,y:-95.5053},
{x:33.9603,y:-118.2786},
{x:34.1407,y:-77.8927},
{x:41.6631,y:-71.6026},
{x:33.221,y:-97.5888},
{x:32.7328,y:-89.5364},
{x:32.4579,y:-97.7286},
{x:43.1279,y:-71.4445},
{x:29.6115,y:-98.4665},
{x:26.063,y:-80.4328},
{x:37.9405,y:-121.9364},
{x:41.0532,y:-83.6116},
{x:18.3759,y:-65.8828},
{x:42.228,y:-96.237},
{x:41.0505,y:-81.7252},
{x:36.3611,y:-104.5953},
{x:42.1993,y:-91.5297},
{x:29.6989,y:-95.072},
{x:36.9394,y:-82.6095},
{x:25.8331,y:-80.2084},
{x:45.5804,y:-94.1797},
{x:40.6467,y:-79.7131},
{x:36.0415,y:-78.4977},
{x:47.526,y:-111.2967},
{x:35.0897,y:-80.8587},
{x:32.4199,y:-90.1334},
{x:35.2103,y:-80.7813},
{x:39.5771,y:-86.1598},
{x:33.8723,y:-118.2672},
{x:41.3184,y:-75.7518},
{x:33.9172,y:-118.1333},
{x:33.8732,y:-118.1147},
{x:45.439,y:-84.7914},
{x:41.6013,y:-83.664},
{x:31.9573,y:-106.6025},
{x:32.318,y:-95.2098},
{x:35.8595,y:-76.744},
{x:32.6415,y:-86.0456},
{x:35.4407,y:-77.6625},
{x:29.7856,y:-95.4948},
{x:41.8888,y:-87.6321},
{x:35.368,y:-119.0034},
{x:35.2945,y:-119.0194},
{x:43.105,y:-89.1992},
{x:34.2731,y:-118.7971},
{x:39.0556,y:-84.6564},
{x:32.9794,y:-115.5482},
{x:33.3483,y:-86.6185},
{x:40.7285,y:-74.1509},
{x:38.015,y:-121.9993},
{x:34.0294,y:-117.611},
{x:29.7334,y:-95.7533},
{x:39.168,y:-78.1196},
{x:39.0924,y:-78.1778},
{x:41.6821,y:-87.5375},
{x:40.6837,y:-73.9116},
{x:41.0364,y:-74.6366},
{x:35.5883,y:-80.8278},
{x:29.9797,y:-90.2776},
{x:31.3512,y:-89.2332},
{x:38.1351,y:-79.0645},
{x:41.495,y:-71.457},
{x:42.2858,y:-89.0289},
{x:40.6267,y:-74.1633},
{x:38.646,y:-75.6213},
{x:18.3848,y:-65.9826},
{x:26.2377,y:-98.2124},
{x:26.2152,y:-98.214},
{x:27.6786,y:-97.3657},
{x:41.2274,y:-82.6021},
{x:38.848,y:-77.0121},
{x:47.5787,y:-95.7567},
{x:42.4621,y:-73.2177},
{x:38.5824,y:-77.3304},
{x:34.0133,y:-84.5614},
{x:30.6303,y:-87.6161},
{x:44.1943,y:-85.214},
{x:37.0013,y:-121.5614},
{x:39.502,y:-86.0665},
{x:34.1613,y:-118.5092},
{x:36.8403,y:-75.9964},
{x:30.7236,y:-95.5477},
{x:35.87,y:-86.417},
{x:41.269,y:-110.3266},
{x:39.1608,y:-94.4942},
{x:40.6417,y:-122.3678},
{x:47.3573,y:-122.0554},
{x:40.8177,y:-97.8066},
{x:36.0669,y:-95.7421},
{x:40.1537,y:-76.6073},
{x:41.2495,y:-96.0813},
{x:44.4701,y:-88.0766},
{x:30.045,y:-93.212},
{x:28.2736,y:-82.4815},
{x:34.1489,y:-81.2414},
{x:30.118,y:-94.1449},
{x:40.003,y:-104.716},
{x:45.4153,y:-122.5931},
{x:36.5834,y:-121.896},
{x:31.2435,y:-89.8113},
{x:40.7643,y:-111.8533},
{x:41.2891,y:-87.2982},
{x:39.9295,y:-85.919},
{x:34.0955,y:-118.1585},
{x:40.7754,y:-81.9321},
{x:38.5598,y:-121.4206},
{x:45.0621,y:-88.0447},
{x:33.3188,y:-111.689},
{x:33.2627,y:-111.7562},
{x:32.4894,y:-114.7865},
{x:37.1723,y:-113.3645},
{x:35.823,y:-78.9017},
{x:41.7069,y:-81.1757},
{x:36.6963,y:-121.6393},
{x:37.3853,y:-121.9281},
{x:33.9164,y:-117.2713},
{x:38.3591,y:-121.9838},
{x:35.1219,y:-83.3928},
{x:29.5699,y:-95.7768},
{x:30.2567,y:-90.768},
{x:33.5717,y:-117.1843},
{x:26.1933,y:-80.2877},
{x:36.754,y:-108.1572},
{x:38.0543,y:-122.1531},
{x:35.6248,y:-78.7257},
{x:28.3419,y:-82.1857},
{x:31.0864,y:-97.754},
{x:30.5406,y:-97.849},
{x:45.5277,y:-122.4348},
{x:46.1201,y:-122.9304},
{x:39.982,y:-82.0192},
{x:38.5102,y:-122.7806},
{x:34.1327,y:-117.9856},
{x:45.6805,y:-108.77},
{x:37.6113,y:-87.5276},
{x:38.2466,y:-85.5127},
{x:35.5126,y:-78.7419},
{x:29.9317,y:-90.004},
{x:37.1667,y:-79.6385},
{x:43.6932,y:-85.4837},
{x:47.4733,y:-122.2859},
{x:29.6392,y:-95.1624},
{x:42.9126,y:-85.568},
{x:39.9499,y:-75.1561},
{x:38.7805,y:-93.7353},
{x:35.3333,y:-79.0462},
{x:37.3324,y:-121.9126},
{x:38.1351,y:-79.0482},
{x:45.0043,y:-93.6453},
{x:43.0035,y:-88.047},
{x:44.4801,y:-93.419},
{x:32.3217,y:-106.8},
{x:34.9377,y:-82.0661},
{x:38.8142,y:-90.0976},
{x:37.6548,y:-122.407},
{x:39.0154,y:-74.8769},
{x:39.3469,y:-94.917},
{x:35.8982,y:-80.0912},
{x:42.1598,y:-72.3199},
{x:41.4706,y:-81.7056},
{x:41.5204,y:-81.5176},
{x:39.3233,y:-84.6356},
{x:38.2447,y:-85.524},
{x:41.4129,y:-87.3354},
{x:38.2483,y:-85.7495},
{x:38.2595,y:-85.6381},
{x:40.7924,y:-73.9681},
{x:34.235,y:-118.9993},
{x:29.562,y:-95.277},
{x:27.638,y:-80.5108},
{x:47.1036,y:-119.3119},
{x:32.8361,y:-96.7525},
{x:41.0571,y:-83.5949},
{x:41.3332,y:-72.9761},
{x:40.9573,y:-117.7465},
{x:30.324,y:-95.4016},
{x:26.0165,y:-97.531},
{x:29.5726,y:-98.6479},
{x:45.9801,y:-94.3367},
{x:42.4762,y:-73.2661},
{x:35.4408,y:-119.076},
{x:38.3032,y:-85.7244},
{x:30.4545,y:-91.0825},
{x:38.4075,y:-122.7411},
{x:44.2605,y:-88.457},
{x:31.292,y:-92.5137},
{x:38.5875,y:-75.2984},
{x:35.8323,y:-78.8275},
{x:29.9418,y:-90.0647},
{x:61.1881,y:-149.8169},
{x:61.2302,y:-149.8752},
{x:36.5454,y:-119.3999},
{x:29.9074,y:-81.3198},
{x:33.8872,y:-118.2503},
{x:33.8663,y:-118.2009},
{x:43.1983,y:-77.9414},
{x:41.4944,y:-90.4026},
{x:38.8742,y:-89.3875},
{x:34.3678,y:-86.8935},
{x:38.6936,y:-84.9389},
{x:40.0777,y:-104.6488},
{x:41.2058,y:-96.0243},
{x:33.676,y:-84.8055},
{x:38.8038,y:-77.6024},
{x:38.0079,y:-122.5407},
{x:39.8175,y:-76.9951},
{x:40.5968,y:-74.3649},
{x:36.0667,y:-115.119},
{x:41.7051,y:-81.3577},
{x:36.1316,y:-115.0648},
{x:31.0595,y:-93.2604},
{x:43.2269,y:-71.4805},
{x:41.5006,y:-95.3369},
{x:32.7163,y:-117.1541},
{x:35.5653,y:-97.5165},
{x:40.2992,y:-111.6955},
{x:28.981,y:-81.2967},
{x:41.5222,y:-88.1407},
{x:33.963,y:-118.2909},
{x:30.4726,y:-91.1107},
{x:33.7882,y:-78.9957},
{x:33.7781,y:-78.9892},
{x:32.6488,y:-97.059},
{x:28.1637,y:-80.6725},
{x:29.7559,y:-98.705},
{x:41.2336,y:-96.1034},
{x:30.5073,y:-91.1498},
{x:39.9226,y:-75.1697},
{x:47.1685,y:-122.1504},
{x:29.9746,y:-90.0794},
{x:41.4531,y:-96.4568},
{x:38.4738,y:-121.4298},
{x:37.2325,y:-107.5955},
{x:46.8135,y:-92.1765},
{x:40.6092,y:-73.9567},
{x:40.9005,y:-81.1609},
{x:42.8942,y:-87.912},
{x:38.2582,y:-122.2718},
{x:41.2386,y:-81.4496},
{x:44.634,y:-88.0518},
{x:34.9173,y:-88.5071},
{x:33.8847,y:-84.1649},
{x:47.3208,y:-122.5876},
{x:35.5226,y:-90.4225},
{x:26.1007,y:-80.1346},
{x:38.2069,y:-81.5121},
{x:35.0739,y:-106.7418},
{x:37.7702,y:-111.5981},
{x:41.4129,y:-82.2425},
{x:38.778,y:-121.2902},
{x:35.8436,y:-83.57},
{x:40.9177,y:-73.897},
{x:39.4222,y:-81.4479},
{x:40.8167,y:-73.9394},
{x:40.5286,y:-74.5409},
{x:41.5393,y:-72.9978},
{x:34.8585,y:-106.6901},
{x:48.1003,y:-122.1773},
{x:25.993,y:-80.2504},
{x:41.3743,y:-82.0869},
{x:30.4206,y:-91.1429},
{x:32.8342,y:-117.1734},
{x:21.3779,y:-158.0259},
{x:38.8109,y:-77.1337},
{x:44.9941,y:-93.1456},
{x:44.8022,y:-91.5062},
{x:41.341,y:-72.9829},
{x:39.927,y:-122.197},
{x:33.905,y:-117.4727},
{x:32.7558,y:-97.335},
{x:37.6994,y:-121.817},
{x:45.5219,y:-123.0689},
{x:36.4328,y:-77.6358},
{x:35.0862,y:-107.7701},
{x:42.1102,y:-72.6105},
{x:38.8413,y:-77.0183},
{x:33.5279,y:-86.7206},
{x:39.9275,y:-82.9654},
{x:41.2344,y:-96.3303},
{x:30.3929,y:-97.7067},
{x:39.7487,y:-84.1404},
{x:39.611,y:-84.1604},
{x:42.11,y:-72.7659},
{x:33.6485,y:-97.3747},
{x:39.672,y:-104.7904},
{x:34.6481,y:-86.7411},
{x:32.7264,y:-96.9033},
{x:38.7755,y:-77.5024},
{x:32.923,y:-96.7311},
{x:29.9242,y:-95.1953},
{x:29.7912,y:-95.5653},
{x:33.7654,y:-112.5136},
{x:31.9707,y:-110.315},
{x:33.4662,y:-112.2212},
{x:37.7212,y:-122.4375},
{x:41.1053,y:-73.5487},
{x:41.7908,y:-72.5066},
{x:36.1329,y:-81.1661},
{x:39.2483,y:-76.6682},
{x:39.3868,y:-81.2082},
{x:43.5986,y:-116.3929},
{x:38.7507,y:-82.1879},
{x:33.8772,y:-98.5603},
{x:38.2389,y:-86.3618},
{x:33.3526,y:-112.429},
{x:42.1744,y:-83.7634},
{x:37.3354,y:-121.8901},
{x:30.4303,y:-87.2302},
{x:32.6264,y:-96.7636},
{x:34.1067,y:-118.0649},
{x:33.2009,y:-96.6371},
{x:33.8745,y:-118.082},
{x:30.0779,y:-94.1705},
{x:38.8012,y:-77.0691},
{x:45.1937,y:-109.2472},
{x:40.7784,y:-80.3837},
{x:33.5858,y:-85.0577},
{x:37.3895,y:-121.9841},
{x:32.9624,y:-115.5465},
{x:33.9367,y:-84.3777},
{x:36.1018,y:-115.157},
{x:36.142,y:-115.1377},
{x:32.9283,y:-96.8505},
{x:41.2525,y:-81.3634},
{x:42.1026,y:-89.0778},
{x:39.7437,y:-104.8411},
{x:43.3967,y:-88.1955},
{x:36.1949,y:-115.2053},
{x:36.9408,y:-77.7353},
{x:38.717,y:-79.9773},
{x:34.7528,y:-77.4627},
{x:33.9164,y:-118.3676},
{x:38.8185,y:-121.1893},
{x:43.5939,y:-83.8392},
{x:29.5074,y:-95.4602},
{x:43.6119,y:-83.9142},
{x:34.08,y:-117.7205},
{x:34.0701,y:-117.7589},
{x:36.1875,y:-81.2388},
{x:45.0294,y:-93.3361},
{x:39.6881,y:-86.0208},
{x:41.9213,y:-91.6559},
{x:36.0723,y:-94.1753},
{x:36.0841,y:-94.1551},
{x:40.8202,y:-73.9126},
{x:40.823,y:-73.9105},
{x:46.7323,y:-117.0109},
{x:37.6047,y:-122.3973},
{x:33.993,y:-118.4141},
{x:33.9736,y:-118.1464},
{x:35.6057,y:-77.3736},
{x:35.8603,y:-78.7045},
{x:36.0402,y:-115.0299},
{x:39.8388,y:-99.8912},
{x:35.8968,y:-78.7255},
{x:38.0976,y:-121.2624},
{x:44.9484,y:-93.3206},
{x:40.0808,y:-76.3392},
{x:34.9376,y:-80.6691},
{x:30.4851,y:-89.1029},
{x:35.8802,y:-79.0653},
{x:41.9294,y:-87.8629},
{x:30.4521,y:-90.1759},
{x:40.6616,y:-74.2339},
{x:38.7817,y:-77.4497},
{x:38.9775,y:-77.4106},
{x:30.4839,y:-90.9434},
{x:41.4562,y:-88.1957},
{x:47.6355,y:-122.377},
{x:35.3199,y:-106.572},
{x:35.6167,y:-77.606},
{x:33.593,y:-86.16},
{x:41.9491,y:-87.6644},
{x:35.7243,y:-95.3409},
{x:42.2985,y:-85.21},
{x:32.7102,y:-97.387},
{x:42.7076,y:-89.0041},
{x:40.0871,y:-104.9855},
{x:39.665,y:-77.6977},
{x:40.9313,y:-117.8042},
{x:35.9264,y:-84.0628},
{x:35.9004,y:-84.1475},
{x:29.7099,y:-82.0436},
{x:35.2645,y:-81.0926},
{x:33.9427,y:-118.2651},
{x:33.7908,y:-118.2624},
{x:32.5105,y:-94.5925},
{x:33.948,y:-117.2622},
{x:33.91,y:-117.1732},
{x:40.0264,y:-78.4477},
{x:37.5032,y:-80.1108},
{x:29.3585,y:-98.5502},
{x:29.6398,y:-82.3429},
{x:40.3702,y:-78.432},
{x:41.6697,y:-72.8932},
{x:42.5863,y:-114.4596},
{x:40.7532,y:-73.9863},
{x:33.472,y:-94.0967},
{x:40.8763,y:-87.9568},
{x:46.2591,y:-119.3085},
{x:32.7928,y:-115.5727},
{x:29.3741,y:-100.8876},
{x:33.9401,y:-83.4754},
{x:34.7407,y:-92.351},
{x:33.7666,y:-84.2528},
{x:31.5744,y:-84.0969},
{x:43.3378,y:-95.1506},
{x:48.849,y:-122.572},
{x:37.7405,y:-121.1366},
{x:39.838,y:-86.2464},
{x:31.706,y:-98.1226},
{x:39.6511,y:-79.9691},
{x:38.785,y:-123.0146},
{x:42.1815,y:-72.6317},
{x:41.8788,y:-87.8835},
{x:32.8841,y:-96.9646},
{x:30.9449,y:-95.3749},
{x:30.4627,y:-90.0833},
{x:32.3468,y:-86.0921},
{x:42.038,y:-88.3614},
{x:32.2893,y:-106.7337},
{x:32.371,y:-106.7399},
{x:32.2677,y:-106.761},
{x:31.4446,y:-85.6546},
{x:41.8507,y:-87.7568},
{x:41.5062,y:-81.6822},
{x:31.2267,y:-85.435},
{x:36.1443,y:-86.7118},
{x:34.5113,y:-82.762},
{x:40.75,y:-115.5863},
{x:40.7528,y:-75.5918},
{x:42.9509,y:-92.552},
{x:33.6032,y:-83.8622},
{x:33.6103,y:-96.4111},
{x:45.0644,y:-93.2181},
{x:47.7113,y:-122.1888},
{x:40.7596,y:-73.4213},
{x:41.6553,y:-83.622},
{x:34.2949,y:-81.6082},
{x:34.0852,y:-117.5764},
{x:41.5896,y:-91.0281},
{x:33.5323,y:-81.6981},
{x:29.8806,y:-90.0279},
{x:35.793,y:-80.262},
{x:34.246,y:-80.6199},
{x:29.1442,y:-81.0345},
{x:30.2821,y:-82.9886},
{x:37.3603,y:-81.1057},
{x:39.642,y:-86.3279},
{x:37.9724,y:-81.149},
{x:33.8321,y:-118.0731},
{x:33.3928,y:-112.1323},
{x:40.1966,y:-85.4153},
{x:33.3433,y:-96.5887},
{x:44.1406,y:-72.0257},
{x:33.0537,y:-96.8307},
{x:47.9794,y:-122.0897},
{x:44.4998,y:-89.5074},
{x:35.397,y:-77.9637},
{x:36.1803,y:-119.3301},
{x:31.6284,y:-94.6527},
{x:35.1301,y:-118.4316},
{x:44.0449,y:-116.9764},
{x:44.9239,y:-74.8791},
{x:41.3016,y:-90.1885},
{x:37.0866,y:-84.6184},
{x:29.3791,y:-98.4575},
{x:29.3683,y:-98.5047},
{x:43.3234,y:-76.4477},
{x:37.3291,y:-79.2478},
{x:35.2769,y:-116.0559},
{x:42.2404,y:-88.8159},
{x:43.09,y:-87.9856},
{x:37.3623,y:-78.8334},
{x:33.8436,y:-116.525},
{x:30.2177,y:-97.6897},
{x:34.1078,y:-117.1955},
{x:27.5163,y:-99.452},
{x:46.4708,y:-90.1266},
{x:47.1523,y:-122.4337},
{x:38.6283,y:-90.2877},
{x:39.7236,y:-105.1912},
{x:30.0725,y:-95.17},
{x:40.6887,y:-89.6833},
{x:45.132,y:-93.4836},
{x:35.071,y:-90.0037},
{x:36.5395,y:-95.4227},
{x:39.1217,y:-94.3649},
{x:35.3853,y:-94.3792},
{x:45.7248,y:-93.1706},
{x:39.2655,y:-76.9863},
{x:33.2194,y:-111.5639},
{x:32.6981,y:-114.6246},
{x:36.4727,y:-86.6775},
{x:40.0665,y:-82.5199},
{x:33.0592,y:-89.5699},
{x:32.3397,y:-90.0681},
{x:33.2326,y:-97.0919},
{x:33.1526,y:-96.8654},
{x:39.9826,y:-75.1559},
{x:34.8547,y:-117.0871},
{x:29.7576,y:-95.7782},
{x:32.9529,y:-97.2831},
{x:31.1621,y:-90.7885},
{x:29.8007,y:-82.1669},
{x:33.6232,y:-112.4257},
{x:48.2673,y:-116.5669},
{x:44.7899,y:-93.2344},
{x:30.2332,y:-93.3281},
{x:44.6271,y:-123.0503},
{x:33.251,y:-111.6298},
{x:36.6408,y:-93.2614},
{x:35.1362,y:-120.6241},
{x:33.825,y:-118.276},
{x:42.2332,y:-71.791},
{x:33.9243,y:-118.3955},
{x:38.5014,y:-82.6484},
{x:40.0611,y:-111.668},
{x:35.4697,y:-95.535},
{x:35.9157,y:-86.8317},
{x:32.6484,y:-97.2236},
{x:34.1081,y:-118.0234},
{x:45.0084,y:-93.1042},
{x:37.2938,y:-121.9891},
{x:39.7389,y:-104.3296},
{x:44.8839,y:-72.7711},
{x:43.1211,y:-123.4173},
{x:39.611,y:-119.2171},
{x:43.793,y:-87.7668},
{x:40.8277,y:-73.9256},
{x:30.071,y:-95.4742},
{x:33.8542,y:-78.8105},
{x:35.8207,y:-90.4468},
{x:34.0893,y:-117.926},
{x:37.1042,y:-76.5095},
{x:34.9591,y:-80.5789},
{x:41.6292,y:-93.5996},
{x:39.3849,y:-76.6881},
{x:43.5999,y:-116.5942},
{x:36.8383,y:-75.9725},
{x:26.6285,y:-81.8742},
{x:41.6179,y:-72.6543},
{x:36.1747,y:-115.1178},
{x:36.1323,y:-86.7788},
{x:41.3414,y:-72.1234},
{x:35.5239,y:-98.3503},
{x:33.5971,y:-84.4988},
{x:35.7769,y:-119.2517},
{x:39.4761,y:-76.8175},
{x:41.7817,y:-71.44},
{x:38.5982,y:-121.451},
{x:39.7163,y:-121.7931},
{x:38.7693,y:-90.1826},
{x:40.2196,y:-79.6097},
{x:37.8729,y:-122.5114},
{x:41.6925,y:-87.6259},
{x:32.2805,y:-95.333},
{x:31.561,y:-91.3639},
{x:41.7434,y:-87.624},
{x:37.975,y:-120.3435},
{x:41.9025,y:-88.0824},
{x:34.7244,y:-114.3158},
{x:31.8159,y:-106.404},
{x:27.7884,y:-82.729},
{x:43.3232,y:-88.4006},
{x:32.9393,y:-111.7544},
{x:30.6892,y:-88.1714},
{x:35.6931,y:-105.9501},
{x:32.6929,y:-97.6024},
{x:30.4277,y:-89.1361},
{x:43.1032,y:-88.3664},
{x:42.8047,y:-86.1141},
{x:36.3196,y:-87.3711},
{x:41.1348,y:-96.0284},
{x:37.3103,y:-121.9142},
{x:45.0333,y:-93.3802},
{x:42.0891,y:-88.456},
{x:33.9305,y:-118.2873},
{x:33.163,y:-117.2186},
{x:32.6776,y:-117.082},
{x:42.0965,y:-72.588},
{x:36.8755,y:-76.4391},
{x:43.6884,y:-84.3689},
{x:29.9865,y:-90.1563},
{x:34.0503,y:-118.2919},
{x:38.2727,y:-122.0531},
{x:35.9957,y:-86.8856},
{x:33.7921,y:-84.3955},
{x:33.9193,y:-83.4455},
{x:38.8122,y:-104.8229},
{x:35.0946,y:-81.6679},
{x:40.0263,y:-84.2276},
{x:40.3364,y:-79.9783},
{x:40.4384,y:-84.9782},
{x:44.9655,y:-92.9844},
{x:47.1098,y:-122.5521},
{x:37.2751,y:-121.8644},
{x:42.8851,y:-78.8748},
{x:35.1999,y:-101.9201},
{x:36.1475,y:-95.9666},
{x:37.5136,y:-94.8457},
{x:36.0192,y:-115.271},
{x:41.2903,y:-73.1124},
{x:32.7952,y:-116.9643},
{x:35.8002,y:-80.2984},
{x:28.6672,y:-99.1844},
{x:40.53,y:-74.5219},
{x:37.7115,y:-121.4342},
{x:33.1922,y:-117.2517},
{x:40.8405,y:-82.3483},
{x:36.9869,y:-121.9646},
{x:42.2125,y:-88.3393},
{x:32.4584,y:-86.3969},
{x:30.598,y:-98.2723},
{x:40.4065,y:-74.6507},
{x:47.7026,y:-122.3233},
{x:32.5844,y:-117.0602},
{x:42.4653,y:-87.8682},
{x:31.325,y:-94.7269},
{x:39.626,y:-119.8826},
{x:32.7288,y:-97.5031},
{x:44.9788,y:-73.4517},
{x:34.8454,y:-86.7107},
{x:34.9541,y:-79.7038},
{x:47.0973,y:-122.3544},
{x:44.2717,y:-85.4068},
{x:34.1585,y:-84.2402},
{x:39.6504,y:-105.0084},
{x:40.7763,y:-73.473},
{x:44.746,y:-93.3301},
{x:35.4105,y:-80.4373},
{x:35.7425,y:-77.9629},
{x:37.1022,y:-113.5553},
{x:40.4165,y:-111.7711},
{x:34.0619,y:-118.306},
{x:36.03,y:-80.2625},
{x:34.1014,y:-80.8562},
{x:34.5402,y:-77.435},
{x:32.7794,y:-116.9571},
{x:42.2673,y:-83.3294},
{x:29.6797,y:-97.6424},
{x:41.5744,y:-87.1831},
{x:25.914,y:-97.5004},
{x:34.0667,y:-118.2708},
{x:47.4035,y:-97.0706},
{x:40.6989,y:-73.9175},
{x:30.5614,y:-97.6786},
{x:32.9563,y:-117.0375},
{x:28.222,y:-81.6428},
{x:44.8705,y:-123.0234},
{x:44.8917,y:-123.0599},
{x:40.4548,y:-105.0791},
{x:39.1487,y:-121.645},
{x:29.795,y:-95.5438},
{x:42.8481,y:-92.1128},
{x:34.7423,y:-86.6772},
{x:34.0044,y:-117.6516},
{x:41.8794,y:-87.6338},
{x:31.9693,y:-94.0199},
{x:43.1665,y:-83.5241},
{x:36.4363,y:-95.2718},
{x:39.5116,y:-80.2534},
{x:47.8501,y:-122.2701},
{x:34.0972,y:-84.2608},
{x:34.1978,y:-85.1727},
{x:34.4775,y:-87.2711},
{x:43.075,y:-76.0763},
{x:30.4417,y:-93.9684},
{x:42.5146,y:-75.5227},
{x:30.081,y:-91.9381},
{x:41.2631,y:-110.956},
{x:41.4085,y:-81.3307},
{x:36.9865,y:-91.9573},
{x:39.71,y:-91.4431},
{x:32.7748,y:-117.1558},
{x:37.7232,y:-121.5327},
{x:38.7086,y:-75.9056},
{x:40.7204,y:-74.2734},
{x:40.7545,y:-73.9984},
{x:46.3173,y:-119.9834},
{x:37.2238,y:-112.6814},
{x:34.2041,y:-83.4588},
{x:34.2315,y:-118.5613},
{x:36.6699,y:-93.2294},
{x:29.4647,y:-98.4641},
{x:38.8107,y:-76.7018},
{x:33.3714,y:-84.5701},
{x:32.558,y:-84.905},
{x:38.8241,y:-81.7281},
{x:35.3385,y:-118.8423},
{x:27.4901,y:-97.8557},
{x:43.0801,y:-75.5334},
{x:36.1846,y:-86.3237},
{x:41.3892,y:-81.3384},
{x:37.73,y:-122.1948},
{x:40.6401,y:-73.6055},
{x:35.3038,y:-80.8579},
{x:37.5105,y:-122.2925},
{x:29.6787,y:-90.8137},
{x:41.9069,y:-87.9073},
{x:42.0689,y:-88.0424},
{x:38.7384,y:-77.4749},
{x:34.2577,y:-118.5239},
{x:41.5462,y:-93.6447},
{x:34.1077,y:-117.4526},
{x:35.5468,y:-77.0303},
{x:38.6139,y:-90.3163},
{x:29.7751,y:-98.7243},
{x:37.2853,y:-121.977},
{x:31.0877,y:-97.7914},
{x:29.6642,y:-95.1125},
{x:37.5186,y:-120.8474},
{x:43.5414,y:-96.6592},
{x:29.6824,y:-95.123},
{x:43.5163,y:-96.7731},
{x:39.8904,y:-75.019},
{x:40.0832,y:-88.1904},
{x:41.8743,y:-87.6306},
{x:27.9617,y:-82.796},
{x:31.1105,y:-97.7999},
{x:41.5956,y:-87.4809},
{x:38.6562,y:-121.5406},
{x:38.6424,y:-121.5189},
{x:36.0818,y:-96.0496},
{x:36.7496,y:-120.3853},
{x:36.4311,y:-119.86},
{x:33.2,y:-117.3755},
{x:26.3305,y:-81.7811},
{x:33.7773,y:-117.8668},
{x:34.8288,y:-116.6926},
{x:41.315,y:-75.7593},
{x:33.6475,y:-117.6856},
{x:33.7829,y:-117.8928},
{x:33.7043,y:-117.9339},
{x:31.7563,y:-106.3432},
{x:33.3193,y:-84.0538},
{x:40.9649,y:-73.8383},
{x:45.9133,y:-122.7483},
{x:29.7837,y:-95.6422},
{x:30.5252,y:-87.8851},
{x:32.9888,y:-87.6249},
{x:30.395,y:-85.6901},
{x:32.6056,y:-96.8576},
{x:29.5853,y:-95.6308},
{x:32.3286,y:-90.1755},
{x:43.0252,y:-102.5585},
{x:29.9961,y:-95.399},
{x:40.3281,y:-76.4258},
{x:38.1959,y:-79.0153},
{x:40.4609,y:-79.9241},
{x:39.9292,y:-86.0205},
{x:35.185,y:-106.5926},
{x:40.6995,y:-111.8874},
{x:42.6919,y:-88.2634},
{x:41.7936,y:-87.693},
{x:29.6034,y:-95.5648},
{x:28.5361,y:-82.5349},
{x:29.4762,y:-81.2157},
{x:30.1057,y:-92.1242},
{x:40.179,y:-122.2274},
{x:28.5713,y:-81.4181},
{x:40.5293,y:-81.4814},
{x:44.5164,y:-122.8997},
{x:33.9829,y:-83.9289},
{x:41.6416,y:-71.0077},
{x:39.6965,y:-92.0371},
{x:31.9598,y:-110.9838},
{x:32.4252,y:-111.044},
{x:32.2512,y:-110.963},
{x:37.3276,y:-80.0423},
{x:45.0157,y:-93.0072},
{x:30.6405,y:-88.2246},
{x:39.4668,y:-79.6834},
{x:38.9419,y:-80.8376},
{x:39.7104,y:-88.4521},
{x:41.8884,y:-71.449},
{x:36.421,y:-94.7964},
{x:40.8913,y:-74.2137},
{x:43.1107,y:-71.92},
{x:37.6784,y:-106.3517},
{x:40.3364,y:-74.7983},
{x:33.3784,y:-84.7527},
{x:32.6872,y:-117.1205},
{x:37.4859,y:-119.966},
{x:39.5845,y:-84.325},
{x:44.6653,y:-75.0273},
{x:40.8049,y:-73.9554},
{x:28.1258,y:-80.6723},
{x:29.8562,y:-94.8619},
{x:39.8284,y:-100.5336},
{x:37.9329,y:-122.3297},
{x:25.9617,y:-97.5392},
{x:33.8169,y:-85.7612},
{x:39.5465,y:-119.7846},
{x:40.038,y:-76.3044},
{x:40.4187,y:-109.4992},
{x:41.9472,y:-87.6537},
{x:30.1516,y:-93.6377},
{x:32.481,y:-85.0292},
{x:29.5831,y:-98.6204},
{x:29.4239,y:-98.5036},
{x:28.4603,y:-81.562},
{x:40.1389,y:-79.8417},
{x:44.938,y:-93.247},
{x:37.876,y:-86.284},
{x:28.8738,y:-81.6892},
{x:30.02,y:-90.1133},
{x:47.6166,y:-117.4323},
{x:29.693,y:-95.7754},
{x:38.5766,y:-92.1721},
{x:25.7299,y:-80.4028},
{x:36.6054,y:-118.0632},
{x:43.4655,y:-76.2325},
{x:33.5193,y:-101.8727},
{x:41.5223,y:-81.6527},
{x:39.1884,y:-84.3634},
{x:34.6668,y:-88.5657},
{x:33.8003,y:-116.5274},
{x:30.4604,y:-81.5794},
{x:41.1539,y:-77.357},
{x:30.4865,y:-97.7695},
{x:36.953,y:-120.0743},
{x:26.0976,y:-98.2204},
{x:45.9695,y:-94.1066},
{x:40.8638,y:-73.8652},
{x:42.4396,y:-82.9492},
{x:42.11,y:-76.0714},
{x:40.8239,y:-73.8743},
{x:34.8207,y:-82.3399},
{x:32.9275,y:-80.6873},
{x:45.0944,y:-93.3835},
{x:34.0641,y:-118.3764},
{x:41.0547,y:-73.8201},
{x:31.6856,y:-89.1417},
{x:35.1659,y:-90.1845},
{x:39.2883,y:-76.6066},
{x:29.5166,y:-98.4921},
{x:41.9767,y:-87.7877},
{x:41.7777,y:-87.7835},
{x:41.9456,y:-87.7667},
{x:32.8635,y:-83.6107},
{x:32.4725,y:-83.7055},
{x:48.7125,y:-94.5909},
{x:33.8966,y:-83.9613},
{x:33.9466,y:-117.3011},
{x:30.0429,y:-82.0728},
{x:42.7813,y:-74.029},
{x:32.4081,y:-93.7392},
{x:32.5998,y:-93.8393},
{x:26.2743,y:-81.8033},
{x:41.0485,y:-73.9555},
{x:36.6263,y:-87.3701},
{x:31.6006,y:-84.1763},
{x:35.1478,y:-90.0525},
{x:30.112,y:-97.3538},
{x:38.7276,y:-77.2571},
{x:34.2342,y:-118.4403},
{x:40.6405,y:-111.2838},
{x:35.6599,y:-105.9685},
{x:36.038,y:-115.1968},
{x:36.2924,y:-115.3155},
{x:41.3764,y:-82.1193},
{x:40.7726,y:-73.9902},
{x:39.8338,y:-75.1268},
{x:33.7738,y:-117.956},
{x:26.9338,y:-80.1388},
{x:26.5956,y:-81.8869},
{x:29.5936,y:-98.5274},
{x:42.421,y:-83.2215},
{x:32.8596,y:-97.2908},
{x:29.4642,y:-95.038},
{x:38.2364,y:-77.5088},
{x:32.6265,y:-96.9054},
{x:29.8915,y:-95.318},
{x:29.8816,y:-97.918},
{x:29.3493,y:-95.0155},
{x:33.9955,y:-117.9682},
{x:40.6625,y:-74.1057},
{x:36.5895,y:-87.2862},
{x:32.9198,y:-80.6778},
{x:36.7921,y:-76.262},
{x:30.2115,y:-92.0283},
{x:30.203,y:-93.8815},
{x:34.2495,y:-118.6064},
{x:40.9053,y:-73.7847},
{x:33.5722,y:-84.3728},
{x:42.3495,y:-71.1046},
{x:33.7861,y:-84.4003},
{x:35.1529,y:-84.9291},
{x:36.4292,y:-84.9313},
{x:35.7509,y:-83.9974},
{x:36.6106,y:-121.899},
{x:37.3143,y:-121.7923},
{x:42.9128,y:-85.6476},
{x:39.3061,y:-76.559},
{x:31.4329,y:-100.4065},
{x:36.1645,y:-86.7808},
{x:35.4214,y:-119.1272},
{x:44.2692,y:-121.189},
{x:43.0538,y:-89.3248},
{x:41.7111,y:-83.6804},
{x:42.4643,y:-87.8808},
{x:35.7258,y:-97.4779},
{x:36.1903,y:-90.3811},
{x:26.3091,y:-98.1608},
{x:25.7957,y:-80.2793},
{x:21.2878,y:-157.8096},
{x:33.2209,y:-117.2432},
{x:40.3994,y:-79.5901},
{x:41.7176,y:-71.4649},
{x:30.0698,y:-95.2416},
{x:35.3454,y:-89.9064},
{x:29.4302,y:-98.5526},
{x:30.0057,y:-90.2316},
{x:44.8244,y:-93.3483},
{x:44.884,y:-93.2488},
{x:43.4094,y:-86.3466},
{x:40.7561,y:-111.899},
{x:32.866,y:-96.9492},
{x:37.3339,y:-89.5744},
{x:42.907,y:-73.585},
{x:34.0105,y:-118.2927},
{x:38.6719,y:-90.3398},
{x:38.3569,y:-120.9372},
{x:42.9068,y:-85.8724},
{x:43.447,y:-83.976},
{x:29.9102,y:-89.9988},
{x:37.6744,y:-121.0124},
{x:40.7353,y:-73.496},
{x:42.0249,y:-91.6235},
{x:37.9518,y:-120.4245},
{x:42.7046,y:-73.1886},
{x:39.8494,y:-105.0051},
{x:46.7312,y:-117.1626},
{x:42.5083,y:-83.0271},
{x:26.398,y:-98.8538},
{x:41.7298,y:-87.5522},
{x:39.5784,y:-84.2306},
{x:38.6684,y:-76.5998},
{x:33.7883,y:-117.8352},
{x:35.7256,y:-83.6368},
{x:35.8909,y:-83.4431},
{x:39.7709,y:-89.7154},
{x:35.3078,y:-120.6588},
{x:45.0351,y:-92.8098},
{x:42.7715,y:-78.8101},
{x:33.7145,y:-84.3501},
{x:33.7616,y:-84.3951},
{x:39.4338,y:-84.192},
{x:34.0675,y:-117.6116},
{x:40.6595,y:-80.2303},
{x:35.1529,y:-85.2461},
{x:41.6954,y:-92.3568},
{x:45.157,y:-122.8776},
{x:35.6311,y:-85.8591},
{x:29.729,y:-98.0765},
{x:38.6135,y:-121.3824},
{x:38.0667,y:-122.1661},
{x:39.6746,y:-104.8895},
{x:33.8104,y:-84.8883},
{x:29.5901,y:-95.0277},
{x:40.3687,y:-80.2354},
{x:30.2646,y:-91.9938},
{x:28.7578,y:-81.3691},
{x:34.6597,y:-86.5408},
{x:34.7053,y:-118.1641},
{x:34.0211,y:-118.1231},
{x:34.2545,y:-118.4087},
{x:32.619,y:-83.6072},
{x:33.1936,y:-84.2848},
{x:38.2626,y:-77.5391},
{x:38.466,y:-85.9458},
{x:36.0651,y:-119.3131},
{x:44.4461,y:-93.5858},
{x:32.9342,y:-79.9979},
{x:37.0776,y:-85.3353},
{x:32.5458,y:-117.0398},
{x:33.9956,y:-118.1476},
{x:41.4035,y:-81.8027},
{x:45.753,y:-108.614},
{x:41.6659,y:-85.7315},
{x:32.8233,y:-116.903},
{x:32.3048,y:-85.0107},
{x:30.6401,y:-87.867},
{x:32.6044,y:-117.0664},
{x:32.7234,y:-117.0853},
{x:33.1212,y:-117.3188},
{x:25.479,y:-80.4413},
{x:44.884,y:-93.3183},
{x:37.3975,y:-121.9128},
{x:29.8101,y:-95.525},
{x:36.041,y:-86.7428},
{x:37.2511,y:-121.8791},
{x:34.0172,y:-117.5051},
{x:38.4084,y:-121.3513},
{x:34.0554,y:-84.1282},
{x:34.6984,y:-83.7218},
{x:34.0057,y:-83.9863},
{x:33.4851,y:-117.0814},
{x:40.409,y:-84.6287},
{x:30.1684,y:-92.0367},
{x:40.0308,y:-105.2583},
{x:32.5797,y:-97.0739},
{x:29.9488,y:-90.0679},
{x:41.88,y:-87.7739},
{x:33.6468,y:-86.827},
{x:42.5666,y:-83.8047},
{x:42.2448,y:-83.7003},
{x:41.8869,y:-87.6231},
{x:47.7549,y:-122.1486},
{x:34.9939,y:-89.6173},
{x:45.8605,y:-95.4271},
{x:42.296,y:-85.6424},
{x:39.2204,y:-78.1345},
{x:41.2654,y:-73.4414},
{x:36.0888,y:-86.6074},
{x:30.4166,y:-91.0716},
{x:32.5714,y:-89.1122},
{x:30.22,y:-92.0753},
{x:32.6639,y:-86.2547},
{x:39.01,y:-82.0446},
{x:40.2938,y:-76.6485},
{x:33.1041,y:-117.2663},
{x:45.0787,y:-93.0569},
{x:46.7509,y:-95.0956},
{x:39.8168,y:-79.7844},
{x:43.1204,y:-88.0869},
{x:40.1981,y:-77.1555},
{x:39.6406,y:-119.7109},
{x:42.9505,y:-76.5479},
{x:30.7279,y:-85.1879},
{x:33.3885,y:-84.8204},
{x:40.6393,y:-73.948},
{x:37.9901,y:-121.6962},
{x:34.1216,y:-117.3213},
{x:41.6223,y:-87.6427},
{x:41.6256,y:-87.6746},
{x:48.4131,y:-97.4112},
{x:37.27,y:-121.9059},
{x:30.3727,y:-88.5361},
{x:41.4391,y:-71.798},
{x:42.1205,y:-72.5856},
{x:41.6775,y:-87.6635},
{x:35.8905,y:-90.5893},
{x:36.3345,y:-94.1507},
{x:47.499,y:-92.8823},
{x:47.5269,y:-121.9364},
{x:41.1261,y:-74.0408},
{x:31.1503,y:-81.496},
{x:45.1828,y:-93.3637},
{x:44.9941,y:-93.5014},
{x:40.8298,y:-73.013},
{x:36.8194,y:-76.4403},
{x:36.9868,y:-86.487},
{x:47.7689,y:-122.1862},
{x:40.2963,y:-80.3251},
{x:42.0858,y:-78.4294},
{x:40.5921,y:-74.289},
{x:34.121,y:-117.7052},
{x:34.6613,y:-120.4659},
{x:38.7959,y:-121.3144},
{x:33.8884,y:-117.812},
{x:29.6016,y:-95.2141},
{x:47.5239,y:-111.3064},
{x:29.7013,y:-98.0959},
{x:39.7696,y:-105.0554},
{x:37.9508,y:-83.6256},
{x:27.8585,y:-82.3265},
{x:41.7345,y:-87.7635},
{x:43.4784,y:-75.3216},
{x:39.4366,y:-77.4251},
{x:30.3395,y:-94.1496},
{x:30.442,y:-97.6631},
{x:34.0134,y:-118.2562},
{x:34.0433,y:-118.2743},
{x:36.0528,y:-86.6144},
{x:38.7437,y:-77.0845},
{x:27.494,y:-97.822},
{x:48.9927,y:-122.7306},
{x:40.7389,y:-73.2922},
{x:25.7769,y:-80.1897},
{x:41.8601,y:-87.9736},
{x:34.1852,y:-118.7855},
{x:42.4957,y:-89.9878},
{x:32.661,y:-117.08},
{x:46.5858,y:-120.5928},
{x:30.8137,y:-97.6104},
{x:29.6387,y:-95.5851},
{x:43.5308,y:-89.0058},
{x:33.0393,y:-96.7083},
{x:30.2353,y:-90.909},
{x:45.1634,y:-91.1486},
{x:35.9634,y:-86.6741},
{x:40.608,y:-74.1636},
{x:33.3664,y:-105.6652},
{x:36.8399,y:-76.1815},
{x:34.0053,y:-84.1733},
{x:39.4887,y:-83.636},
{x:31.9566,y:-81.321},
{x:40.0781,y:-86.9031},
{x:29.4875,y:-95.1089},
{x:34.9531,y:-120.4348},
{x:41.8949,y:-87.8649},
{x:38.211,y:-85.3489},
{x:42.9837,y:-87.9504},
{x:27.3011,y:-81.3598},
{x:39.2582,y:-76.5229},
{x:28.1519,y:-81.8003},
{x:30.4976,y:-97.6577},
{x:35.5883,y:-87.0976},
{x:41.4222,y:-122.387},
{x:29.647,y:-81.6301},
{x:33.8035,y:-117.9407},
{x:27.137,y:-82.4013},
{x:37.6586,y:-85.5934},
{x:29.9653,y:-91.2098},
{x:33.0189,y:-96.7495},
{x:27.8403,y:-82.7927},
{x:44.8405,y:-93.5387},
{x:27.8024,y:-82.6389},
{x:40.7577,y:-73.7779},
{x:42.04,y:-87.859},
{x:32.53,y:-92.1153},
{x:34.9294,y:-85.1573},
{x:34.0413,y:-118.2047},
{x:42.6148,y:-87.9492},
{x:39.6182,y:-83.604},
{x:33.4938,y:-112.1694},
{x:39.2879,y:-76.612},
{x:34.419,y:-103.1976},
{x:38.8673,y:-94.1721},
{x:47.8889,y:-97.0765},
{x:38.8579,y:-84.6178},
{x:29.7851,y:-95.467},
{x:31.4792,y:-87.3339},
{x:29.5594,y:-95.3029},
{x:29.3398,y:-98.6218},
{x:40.5939,y:-79.9468},
{x:37.5716,y:-122.3086},
{x:40.9148,y:-73.7721},
{x:41.1396,y:-80.6663},
{x:38.4805,y:-78.8179},
{x:39.0269,y:-86.9482},
{x:29.381,y:-98.6391},
{x:35.094,y:-80.9364},
{x:32.7042,y:-97.0982},
{x:30.6409,y:-90.1925},
{x:33.4442,y:-84.3238},
{x:35.2768,y:-81.5297},
{x:39.7993,y:-77.03},
{x:39.7107,y:-104.9402},
{x:25.8983,y:-80.3793},
{x:41.6591,y:-73.9299},
{x:41.5309,y:-73.8933},
{x:29.6426,y:-98.4891},
{x:30.6419,y:-90.4996},
{x:41.6406,y:-81.4717},
{x:40.7984,y:-73.9534},
{x:25.6539,y:-80.3291},
{x:36.0048,y:-78.936},
{x:25.8658,y:-80.3093},
{x:38.8464,y:-76.9807},
{x:38.9029,y:-77.0061},
{x:32.8222,y:-117.1579},
{x:39.1602,y:-77.2802},
{x:29.7027,y:-82.3435},
{x:64.6775,y:-147.0897},
{x:41.1758,y:-73.1305},
{x:32.8109,y:-96.8192},
{x:30.5048,y:-90.3436},
{x:39.2379,y:-77.2762},
{x:28.892,y:-81.3071},
{x:34.1221,y:-117.7929},
{x:41.6756,y:-85.9223},
{x:37.5451,y:-121.9887},
{x:33.5899,y:-84.4688},
{x:27.8028,y:-97.3941},
{x:42.2301,y:-83.6788},
{x:38.6264,y:-75.1952},
{x:35.9469,y:-84.1138},
{x:35.9383,y:-83.9466},
{x:35.8743,y:-84.4591},
{x:42.1995,y:-72.5736},
{x:40.7438,y:-73.9206},
{x:33.0414,y:-96.7542},
{x:32.7789,y:-96.8006},
{x:42.429,y:-83.3403},
{x:41.3548,y:-83.1326},
{x:25.7,y:-80.4479},
{x:32.2251,y:-82.4092},
{x:31.9114,y:-85.1494},
{x:38.5267,y:-122.788},
{x:41.2499,y:-82.7049},
{x:39.2325,y:-76.4578},
{x:41.7506,y:-87.6266},
{x:42.0889,y:-88.0202},
{x:42.0051,y:-71.5856},
{x:26.762,y:-80.1034},
{x:42.0016,y:-83.3084},
{x:32.8697,y:-83.7015},
{x:40.3892,y:-111.9198},
{x:40.2773,y:-111.713},
{x:40.4975,y:-82.7204},
{x:39.3433,y:-77.2546},
{x:42.2018,y:-83.21},
{x:42.0527,y:-87.6875},
{x:42.0644,y:-87.7269},
{x:33.7146,y:-118.0235},
{x:35.9479,y:-83.977},
{x:36.1631,y:-82.8342},
{x:35.9076,y:-84.1464},
{x:36.0881,y:-83.9387},
{x:35.8466,y:-83.9909},
{x:32.8563,y:-96.751},
{x:40.7446,y:-74.1801},
{x:35.0854,y:-85.1975},
{x:42.973,y:-86.1819},
{x:40.8636,y:-74.5699},
{x:34.0517,y:-83.9279},
{x:34.4758,y:-84.4603},
{x:33.829,y:-118.3851},
{x:29.5502,y:-81.2253},
{x:42.276,y:-78.6708},
{x:41.0246,y:-81.729},
{x:39.2224,y:-76.8896},
{x:34.1699,y:-80.7895},
{x:32.7818,y:-96.5638},
{x:28.0815,y:-97.0423},
{x:36.056,y:-115.268},
{x:40.9234,y:-84.0593},
{x:40.6465,y:-74.0192},
{x:29.6568,y:-90.7665},
{x:33.6733,y:-117.9705},
{x:37.3531,y:-121.9774},
{x:40.5841,y:-79.721},
{x:44.2683,y:-105.5091},
{x:34.8925,y:-77.5472},
{x:46.423,y:-117.0491},
{x:30.0282,y:-89.9917},
{x:18.2182,y:-66.0308},
{x:46.9971,y:-122.9191},
{x:40.8808,y:-82.6565},
{x:37.6505,y:-122.4695},
{x:38.5411,y:-92.119},
{x:34.2431,y:-117.2795},
{x:38.5255,y:-121.4455},
{x:42.3131,y:-83.0891},
{x:41.0618,y:-74.013},
{x:34.0496,y:-117.2912},
{x:26.5435,y:-81.8503},
{x:40.8741,y:-73.6196},
{x:46.5912,y:-94.2198},
{x:39.2508,y:-81.6056},
{x:41.7515,y:-87.566},
{x:34.9649,y:-81.8947},
{x:41.902,y:-87.7656},
{x:47.0721,y:-122.4178},
{x:37.9453,y:-121.7376},
{x:33.7997,y:-117.2265},
{x:37.4166,y:-121.877},
{x:40.8104,y:-73.9533},
{x:34.0188,y:-118.1148},
{x:42.041,y:-87.699},
{x:41.8912,y:-87.6176},
{x:30.4328,y:-91.0237},
{x:29.7763,y:-95.5604},
{x:41.7657,y:-87.7451},
{x:33.8674,y:-84.0929},
{x:38.3196,y:-104.6142},
{x:38.2336,y:-122.6032},
{x:47.5504,y:-122.04},
{x:37.3094,y:-121.9497},
{x:33.6128,y:-84.4488},
{x:33.9935,y:-84.5088},
{x:29.9973,y:-90.2178},
{x:41.8892,y:-87.6369},
{x:40.8417,y:-74.1596},
{x:34.2102,y:-119.1935},
{x:27.8571,y:-82.6384},
{x:38.4707,y:-82.6246},
{x:36.168,y:-95.5298},
{x:35.0293,y:-89.7592},
{x:34.4208,y:-118.5424},
{x:37.1772,y:-94.8321},
{x:34.0921,y:-117.315},
{x:42.85,y:-87.8533},
{x:41.5009,y:-73.0529},
{x:37.2884,y:-76.7284},
{x:39.6768,y:-84.2202},
{x:32.8538,y:-96.9599},
{x:30.0096,y:-90.159},
{x:45.7977,y:-89.1697},
{x:34.2662,y:-92.0847},
{x:35.4572,y:-93.4625},
{x:39.6278,y:-106.0642},
{x:35.3653,y:-103.4104},
{x:35.7387,y:-86.8934},
{x:32.738,y:-117.0561},
{x:37.8873,y:-93.6252},
{x:27.828,y:-82.6477},
{x:35.6534,y:-97.531},
{x:33.2446,y:-117.2908},
{x:34.9622,y:-90.0436},
{x:39.8559,y:-86.1951},
{x:40.2831,y:-76.5894},
{x:40.8518,y:-73.9323},
{x:31.3187,y:-89.3318},
{x:29.4804,y:-89.6937},
{x:40.0211,y:-86.1613},
{x:42.6058,y:-87.8838},
{x:35.6156,y:-119.658},
{x:36.8073,y:-119.8466},
{x:43.0399,y:-91.1392},
{x:36.099,y:-78.3011},
{x:43.0403,y:-71.4744},
{x:40.603,y:-79.1943},
{x:34.3164,y:-88.7912},
{x:35.1568,y:-81.4784},
{x:35.0005,y:-80.9779},
{x:36.5795,y:-80.7405},
{x:39.616,y:-86.4785},
{x:33.9337,y:-86.1913},
{x:37.5001,y:-120.8247},
{x:36.0034,y:-85.0452},
{x:29.631,y:-95.233},
{x:29.216,y:-82.2012},
{x:40.7172,y:-73.99},
{x:38.9979,y:-77.2883},
{x:38.37,y:-77.5287},
{x:30.1846,y:-95.49},
{x:42.0409,y:-87.7581},
{x:40.7348,y:-73.9898},
{x:44.0204,y:-88.5477},
{x:43.2357,y:-86.2532},
{x:38.4421,y:-122.6741},
{x:33.5064,y:-86.8006},
{x:43.1013,y:-89.4864},
{x:25.7328,y:-80.3296},
{x:35.4321,y:-87.2809},
{x:25.6836,y:-80.3166},
{x:35.1758,y:-80.654},
{x:37.2867,y:-121.9495},
{x:34.1191,y:-118.1973},
{x:48.8438,y:-122.2869},
{x:37.9651,y:-122.3435},
{x:42.7313,y:-87.8201},
{x:47.149,y:-122.5057},
{x:36.9564,y:-84.0725},
{x:38.5104,y:-77.3703},
{x:30.5391,y:-89.6719},
{x:30.3713,y:-90.077},
{x:36.3395,y:-119.3318},
{x:35.4008,y:-81.3408},
{x:29.638,y:-82.3482},
{x:35.6831,y:-79.8416},
{x:36.1335,y:-78.758},
{x:39.2118,y:-111.0068},
{x:43.1059,y:-88.0072},
{x:42.9211,y:-88.1099},
{x:43.0897,y:-88.0499},
{x:37.53,y:-77.4227},
{x:39.7253,y:-89.9107},
{x:34.1298,y:-116.3969},
{x:40.5639,y:-76.0123},
{x:39.2898,y:-76.7291},
{x:41.5589,y:-73.0532},
{x:42.9904,y:-88.2579},
{x:42.5909,y:-114.4832},
{x:33.991,y:-89.3494},
{x:47.9328,y:-97.0522},
{x:44.0989,y:-69.866},
{x:40.6997,y:-73.8311},
{x:27.9163,y:-82.7301},
{x:42.7402,y:-78.8721},
{x:29.9309,y:-95.5441},
{x:34.1041,y:-118.0901},
{x:40.5806,y:-83.085},
{x:30.209,y:-95.4256},
{x:29.9818,y:-95.5672},
{x:32.7429,y:-117.0346},
{x:34.14,y:-117.9562},
{x:37.4894,y:-78.2527},
{x:31.9814,y:-81.174},
{x:40.0718,y:-86.4795},
{x:41.25,y:-76.9696},
{x:42.6275,y:-82.9381},
{x:61.1816,y:-149.7514},
{x:41.2397,y:-77.0522},
{x:30.2547,y:-94.2154},
{x:39.0773,y:-108.5175},
{x:35.7374,y:-91.6382},
{x:36.8455,y:-76.2894},
{x:30.0752,y:-95.4339},
{x:38.671,y:-77.0089},
{x:43.1012,y:-77.6145},
{x:35.784,y:-83.5539},
{x:42.0244,y:-88.1436},
{x:37.4187,y:-76.821},
{x:41.8312,y:-87.6221},
{x:43.6193,y:-72.9558},
{x:41.0205,y:-73.6425},
{x:37.4009,y:-77.6733},
{x:32.864,y:-96.9904},
{x:41.7065,y:-87.6691},
{x:38.6487,y:-78.6744},
{x:34.343,y:-85.0773},
{x:34.0196,y:-117.5944},
{x:25.5957,y:-80.4304},
{x:41.7214,y:-85.816},
{x:38.2435,y:-104.6481},
{x:36.8243,y:-76.0691},
{x:32.2842,y:-93.8231},
{x:33.7385,y:-118.2834},
{x:38.4486,y:-105.2201},
{x:36.1,y:-115.2439},
{x:36.0126,y:-115.1563},
{x:38.7991,y:-77.1628},
{x:36.5238,y:-91.5388},
{x:40.1132,y:-88.31},
{x:27.2991,y:-80.3038},
{x:26.246,y:-98.4665},
{x:41.9083,y:-87.7466},
{x:42.3436,y:-83.2154},
{x:36.0201,y:-115.2455},
{x:38.1516,y:-85.0696},
{x:35.1105,y:-106.7007},
{x:35.4258,y:-97.5315},
{x:35.8587,y:-80.0748},
{x:28.3301,y:-80.7352},
{x:35.2112,y:-78.0622},
{x:43.1199,y:-73.7678},
{x:38.4159,y:-82.2569},
{x:41.3192,y:-87.0255},
{x:29.4405,y:-82.6272},
{x:34.2904,y:-118.4485},
{x:36.1534,y:-95.9435},
{x:41.5017,y:-81.6738},
{x:40.7894,y:-82.5893},
{x:31.9738,y:-89.8779},
{x:43.1318,y:-78.6858},
{x:41.7831,y:-88.1655},
{x:34.065,y:-106.8923},
{x:36.543,y:-85.494},
{x:26.5136,y:-80.1481},
{x:41.4112,y:-81.8336},
{x:32.6482,y:-96.7822},
{x:39.6629,y:-74.9617},
{x:48.1514,y:-122.1754},
{x:25.5508,y:-80.3731},
{x:38.2795,y:-77.4906},
{x:45.2293,y:-123.169},
{x:45.7084,y:-122.5544},
{x:40.3463,y:-76.1215},
{x:46.8735,y:-96.7659},
{x:41.3975,y:-89.4682},
{x:36.3379,y:-96.8035},
{x:33.5273,y:-84.5752},
{x:33.8824,y:-84.588},
{x:32.7306,y:-97.2816},
{x:41.7109,y:-88.254},
{x:42.265,y:-83.1735},
{x:33.8986,y:-84.2516},
{x:35.6601,y:-80.5012},
{x:40.437,y:-79.9971},
{x:33.7893,y:-117.9133},
{x:32.8753,y:-96.6166},
{x:40.3454,y:-76.3968},
{x:43.3058,y:-100.647},
{x:48.4413,y:-122.3456},
{x:42.1042,y:-91.2658},
{x:39.0112,y:-84.6505},
{x:34.3407,y:-78.2247},
{x:28.6875,y:-81.5366},
{x:42.9973,y:-84.1861},
{x:33.9167,y:-118.2824},
{x:32.8016,y:-117.157},
{x:32.8941,y:-97.262},
{x:40.7116,y:-75.2188},
{x:32.8148,y:-116.918},
{x:47.3146,y:-95.9647},
{x:38.8214,y:-76.8574},
{x:34.2196,y:-118.5901},
{x:36.0898,y:-80.2685},
{x:40.2023,y:-83.0267},
{x:35.8042,y:-83.5761},
{x:42.2713,y:-83.4878},
{x:29.4263,y:-98.4925},
{x:45.0417,y:-92.0322},
{x:34.71,y:-77.233},
{x:33.7292,y:-117.0069},
{x:40.4598,y:-74.4566},
{x:30.7414,y:-88.2755},
{x:36.098,y:-110.2167},
{x:40.7208,y:-74.2884},
{x:29.6217,y:-95.6},
{x:39.0951,y:-77.1125},
{x:36.1442,y:-115.1552},
{x:36.1575,y:-115.0798},
{x:29.0255,y:-82.163},
{x:28.5113,y:-81.3748},
{x:28.3421,y:-81.4863},
{x:39.8846,y:-86.0672},
{x:33.9814,y:-84.0785},
{x:35.4186,y:-80.744},
{x:39.3447,y:-76.7343},
{x:44.8607,y:-93.2653},
{x:41.1027,y:-100.7234},
{x:39.4505,y:-85.6264},
{x:33.2789,y:-87.0987},
{x:42.1384,y:-71.5355},
{x:44.8869,y:-91.4172},
{x:40.7084,y:-73.7197},
{x:36.6006,y:-119.4597},
{x:38.887,y:-77.0256},
{x:25.8126,y:-80.3558},
{x:45.3002,y:-122.9821},
{x:41.0199,y:-76.8547},
{x:41.148,y:-80.6619},
{x:35.9529,y:-83.9339},
{x:36.0753,y:-95.7783},
{x:41.623,y:-87.5741},
{x:40.535,y:-74.3385},
{x:35.7253,y:-81.2099},
{x:44.956,y:-93.1254},
{x:39.4117,y:-81.4332},
{x:41.6168,y:-109.2318},
{x:40.3311,y:-76.863},
{x:46.5649,y:-91.4085},
{x:32.8351,y:-97.0871},
{x:38.2723,y:-85.4924},
{x:34.9147,y:-81.0032},
{x:41.4876,y:-91.5553},
{x:32.3445,y:-90.8343},
{x:34.809,y:-78.0161},
{x:31.5896,y:-89.8747},
{x:38.8824,y:-77.1116},
{x:42.5406,y:-83.3618},
{x:40.65,y:-73.9613},
{x:32.7633,y:-117.1156},
{x:33.7525,y:-84.4029},
{x:39.0323,y:-76.6031},
{x:33.7827,y:-118.1969},
{x:34.1948,y:-118.5877},
{x:29.6135,y:-95.2208},
{x:41.0257,y:-76.3176},
{x:40.8782,y:-74.084},
{x:42.8738,y:-71.3379},
{x:42.9931,y:-71.4732},
{x:35.9867,y:-83.6076},
{x:42.5141,y:-71.2027},
{x:41.7062,y:-87.7005},
{x:26.6105,y:-81.8101},
{x:41.0972,y:-73.9559},
{x:34.4545,y:-77.607},
{x:32.0032,y:-106.377},
{x:43.4303,y:-96.7069},
{x:34.1991,y:-77.8707},
{x:27.9958,y:-82.3897},
{x:41.4696,y:-87.5867},
{x:45.1183,y:-95.0422},
{x:44.9083,y:-85.0541},
{x:40.5897,y:-73.663},
{x:42.6566,y:-73.8025},
{x:34.9805,y:-78.965},
{x:39.7233,y:-121.8048},
{x:37.2469,y:-121.8859},
{x:43.2369,y:-75.5023},
{x:41.17,y:-85.486},
{x:29.8726,y:-90.0823},
{x:44.0489,y:-91.6772},
{x:39.4487,y:-84.37},
{x:40.6441,y:-74.0727},
{x:30.0431,y:-89.9584},
{x:41.1384,y:-80.7157},
{x:47.6761,y:-117.2384},
{x:41.5088,y:-81.606},
{x:40.0523,y:-74.8951},
{x:33.5066,y:-117.1476},
{x:40.8878,y:-76.7967},
{x:40.2878,y:-84.178},
{x:37.7149,y:-122.1417},
{x:27.7091,y:-97.3874},
{x:33.7259,y:-117.8501},
{x:35.256,y:-78.9663},
{x:40.1957,y:-76.7564},
{x:36.3979,y:-79.9681},
{x:31.3287,y:-89.3329},
{x:33.9745,y:-117.3738},
{x:33.9353,y:-118.3439},
{x:33.7908,y:-118.297},
{x:34.0561,y:-118.2366},
{x:29.9513,y:-89.9593},
{x:34.1199,y:-118.2285},
{x:40.7092,y:-73.8698},
{x:35.2083,y:-118.9139},
{x:38.8779,y:-77.0178},
{x:37.0032,y:-121.5455},
{x:33.9992,y:-117.9345},
{x:40.8905,y:-73.8492},
{x:26.2284,y:-80.1547},
{x:31.3175,y:-85.8297},
{x:33.2121,y:-87.5488},
{x:39.9086,y:-76.7916},
{x:44.6949,y:-73.5143},
{x:33.9677,y:-118.0431},
{x:41.8521,y:-87.6748},
{x:39.0816,y:-78.0836},
{x:42.5107,y:-89.0551},
{x:40.2692,y:-76.6711},
{x:42.3621,y:-87.9284},
{x:38.437,y:-90.3775},
{x:40.2335,y:-77.1309},
{x:31.8157,y:-81.6487},
{x:31.1907,y:-101.462},
{x:36.1511,y:-86.6258},
{x:39.7899,y:-105.0259},
{x:43.1187,y:-85.6109},
{x:42.7454,y:-86.064},
{x:35.8594,y:-86.453},
{x:41.9534,y:-87.8855},
{x:26.3226,y:-98.6831},
{x:34.2735,y:-118.4261},
{x:35.5023,y:-119.2757},
{x:32.4692,y:-83.7406},
{x:37.6329,y:-122.0967},
{x:35.0097,y:-79.1755},
{x:36.2038,y:-91.1871},
{x:30.3172,y:-95.4632},
{x:34.0436,y:-118.0316},
{x:34.1805,y:-118.3033},
{x:33.8777,y:-118.2212},
{x:37.6986,y:-121.8452},
{x:33.8099,y:-118.0721},
{x:42.0323,y:-91.6573},
{x:42.7333,y:-82.7857},
{x:61.1082,y:-149.8566},
{x:35.0538,y:-85.7106},
{x:35.0775,y:-88.5465},
{x:32.7051,y:-97.0542},
{x:61.2223,y:-149.7791},
{x:41.8649,y:-88.1059},
{x:32.6748,y:-97.1767},
{x:38.4953,y:-121.4825},
{x:40.3257,y:-76.048},
{x:44.0325,y:-92.8573},
{x:30.8371,y:-83.28},
{x:41.4448,y:-73.0648},
{x:40.5994,y:-73.9529},
{x:33.7455,y:-117.9221},
{x:40.6377,y:-73.7017},
{x:42.5878,y:-87.8568},
{x:27.9485,y:-82.4587},
{x:33.8162,y:-116.4575},
{x:42.2395,y:-71.8444},
{x:35.0587,y:-106.5802},
{x:31.7605,y:-106.266},
{x:28.4434,y:-99.2487},
{x:35.8749,y:-86.3525},
{x:40.7087,y:-73.8179},
{x:41.8519,y:-87.7241},
{x:39.2482,y:-76.7148},
{x:34.3285,y:-84.0559},
{x:41.7866,y:-87.6652},
{x:38.7846,y:-76.7951},
{x:40.2295,y:-111.6476},
{x:28.3406,y:-82.6967},
{x:31.7022,y:-91.0751},
{x:40.8756,y:-73.1204},
{x:40.8981,y:-98.3401},
{x:40.8105,y:-82.9761},
{x:35.584,y:-78.1456},
{x:41.2279,y:-72.9961},
{x:39.7531,y:-76.6787},
{x:27.7604,y:-97.4028},
{x:41.4017,y:-89.9054},
{x:35.0963,y:-106.5325},
{x:32.647,y:-96.8576},
{x:32.3655,y:-96.1022},
{x:48.2311,y:-114.3263},
{x:32.5427,y:-97.3291},
{x:33.7582,y:-78.9642},
{x:39.7445,y:-104.9852},
{x:44.5555,y:-123.0672},
{x:34.3517,y:-89.9161},
{x:42.7011,y:-73.8202},
{x:30.4611,y:-84.2177},
{x:29.9566,y:-93.8991},
{x:33.6544,y:-117.7026},
{x:39.7946,y:-89.6962},
{x:30.4091,y:-88.7613},
{x:32.6484,y:-117.0594},
{x:33.0354,y:-117.2839},
{x:32.9034,y:-117.114},
{x:26.8934,y:-82.0124},
{x:44.9468,y:-93.1361},
{x:39.6931,y:-79.1576},
{x:35.2953,y:-80.7555},
{x:30.2101,y:-93.3263},
{x:35.8336,y:-86.3517},
{x:33.9967,y:-118.4787},
{x:34.2697,y:-85.2185},
{x:41.6194,y:-87.5595},
{x:35.0656,y:-77.3612},
{x:28.027,y:-82.5598},
{x:44.8074,y:-91.471},
{x:39.1839,y:-89.67},
{x:30.0065,y:-97.1381},
{x:40.7656,y:-89.473},
{x:35.1572,y:-78.9969},
{x:34.179,y:-118.4317},
{x:37.7619,y:-120.859},
{x:37.3425,y:-120.614},
{x:36.4153,y:-119.0984},
{x:41.0607,y:-111.9857},
{x:36.1589,y:-115.0498},
{x:38.6965,y:-77.1447},
{x:29.4747,y:-81.1788},
{x:39.0259,y:-77.127},
{x:46.8491,y:-113.9977},
{x:28.5227,y:-81.33},
{x:35.6585,y:-88.4164},
{x:40.7563,y:-73.9939},
{x:41.4942,y:-74.4034},
{x:39.1105,y:-77.552},
{x:32.7887,y:-96.6534},
{x:34.9557,y:-78.5083},
{x:30.4496,y:-91.155},
{x:38.8008,y:-90.4751},
{x:30.3362,y:-81.7545},
{x:42.3492,y:-71.1556},
{x:40.9007,y:-73.9999},
{x:41.8303,y:-87.6659},
{x:41.0242,y:-80.595},
{x:37.484,y:-122.231},
{x:28.8062,y:-96.9928},
{x:41.2839,y:-86.047},
{x:34.226,y:-119.1525},
{x:30.6462,y:-86.527},
{x:47.1233,y:-122.4954},
{x:34.9003,y:-92.1461},
{x:31.8076,y:-106.4305},
{x:46.2595,y:-114.1562},
{x:44.9359,y:-96.0518},
{x:30.4509,y:-88.8405},
{x:31.2006,y:-89.035},
{x:40.307,y:-75.9571},
{x:39.3734,y:-76.6112},
{x:28.5214,y:-81.3767},
{x:38.7416,y:-90.3043},
{x:30.3726,y:-94.3129},
{x:41.7529,y:-71.4534},
{x:40.7669,y:-73.9943},
{x:38.4165,y:-81.7947},
{x:38.5108,y:-121.4661},
{x:41.3581,y:-97.9771},
{x:40.8413,y:-96.6068},
{x:41.1424,y:-96.1656},
{x:36.0418,y:-86.7088},
{x:29.9598,y:-95.5383},
{x:32.6606,y:-117.0324},
{x:39.3325,y:-80.2384},
{x:37.4313,y:-81.5709},
{x:42.0255,y:-87.8934},
{x:35.049,y:-85.29},
{x:33.7575,y:-116.3919},
{x:35.9852,y:-75.6441},
{x:34.1342,y:-117.5383},
{x:32.3124,y:-90.1755},
{x:33.5426,y:-86.5853},
{x:47.5292,y:-122.0359},
{x:33.1739,y:-97.0874},
{x:37.9902,y:-122.3358},
{x:35.078,y:-78.9343},
{x:26.5696,y:-81.8722},
{x:45.7843,y:-108.5382},
{x:38.6098,y:-90.2411},
{x:30.0206,y:-95.4425},
{x:34.1219,y:-117.3957},
{x:40.7487,y:-74.0033},
{x:42.0974,y:-72.586},
{x:32.357,y:-89.9717},
{x:29.7601,y:-95.7329},
{x:38.9576,y:-77.4017},
{x:41.5393,y:-86.0022},
{x:47.5071,y:-122.1558},
{x:32.303,y:-90.1698},
{x:36.039,y:-114.9803},
{x:26.5503,y:-80.0763},
{x:41.5868,y:-83.5654},
{x:36.1142,y:-115.2251},
{x:29.9079,y:-98.2074},
{x:31.7829,y:-106.4617},
{x:38.081,y:-77.8503},
{x:36.2388,y:-115.0998},
{x:41.68,y:-86.2353},
{x:40.6797,y:-75.5372},
{x:41.6966,y:-91.6659},
{x:39.1086,y:-76.7396},
{x:35.3682,y:-118.968},
{x:46.1249,y:-92.8697},
{x:32.8808,y:-96.7623},
{x:41.4641,y:-81.2422},
{x:40.2691,y:-75.9195},
{x:34.5706,y:-86.4476},
{x:35.3727,y:-77.9541},
{x:37.5437,y:-81.8006},
{x:19.6902,y:-155.0663},
{x:26.7859,y:-82.0381},
{x:34.7516,y:-92.3172},
{x:33.3427,y:-84.5369},
{x:44.9522,y:-93.2885},
{x:40.0184,y:-79.0809},
{x:35.0251,y:-79.0533},
{x:33.5972,y:-85.9265},
{x:33.7172,y:-85.8193},
{x:34.5058,y:-114.3512},
{x:41.8579,y:-87.6616},
{x:40.5886,y:-83.1446},
{x:33.9411,y:-97.1286},
{x:35.2602,y:-97.4938},
{x:40.7164,y:-74.005},
{x:40.1689,y:-78.3785},
{x:40.6429,y:-79.9393},
{x:37.7785,y:-79.9773},
{x:28.9142,y:-81.2793},
{x:32.372,y:-99.7465},
{x:39.9237,y:-83.7789},
{x:42.7384,y:-73.6775},
{x:39.7204,y:-104.9027},
{x:32.8935,y:-96.7399},
{x:29.49,y:-98.5354},
{x:33.1287,y:-88.1539},
{x:33.416,y:-111.9287},
{x:39.7151,y:-84.1426},
{x:46.0334,y:-118.3836},
{x:44.9743,y:-93.2667},
{x:38.0741,y:-117.2406},
{x:39.927,y:-82.8292},
{x:41.9098,y:-87.7101},
{x:42.5994,y:-74.3356},
{x:42.4196,y:-123.3539},
{x:41.867,y:-87.6247},
{x:34.9126,y:-97.7844},
{x:45.5773,y:-122.5664},
{x:36.1973,y:-115.2579},
{x:41.6124,y:-83.4806},
{x:25.8524,y:-80.2826},
{x:42.0798,y:-72.5368},
{x:40.6595,y:-74.264},
{x:30.0057,y:-90.0743},
{x:43.3918,y:-124.2726},
{x:33.5208,y:-86.7275},
{x:29.7644,y:-96.1855},
{x:42.5263,y:-83.0471},
{x:41.5853,y:-92.5269},
{x:36.281,y:-115.1345},
{x:33.9539,y:-86.0412},
{x:41.3101,y:-73.8721},
{x:42.1519,y:-87.9856},
{x:29.6382,y:-95.5295},
{x:40.3111,y:-78.3249},
{x:36.8507,y:-80.8636},
{x:41.8331,y:-87.6915},
{x:39.0148,y:-84.674},
{x:42.2032,y:-70.9991},
{x:42.2734,y:-89.632},
{x:29.8101,y:-95.1601},
{x:39.1267,y:-75.4797},
{x:41.8478,y:-89.4862},
{x:27.5867,y:-99.5111},
{x:41.7386,y:-81.2689},
{x:32.7566,y:-97.0652},
{x:47.235,y:-122.2453},
{x:35.3901,y:-93.1116},
{x:36.6256,y:-87.3194},
{x:39.7972,y:-86.1569},
{x:42.6493,y:-84.6422},
{x:27.5233,y:-82.5592},
{x:42.108,y:-87.9729},
{x:42.5841,y:-72.2054},
{x:32.4375,y:-90.1474},
{x:41.3787,y:-88.7768},
{x:35.0872,y:-106.5867},
{x:43.2394,y:-77.644},
{x:34.0682,y:-118.4423},
{x:34.0229,y:-118.2795},
{x:45.5151,y:-122.8329},
{x:42.2628,y:-71.8205},
{x:37.685,y:-122.0986},
{x:39.8319,y:-89.6294},
{x:32.326,y:-102.549},
{x:41.9386,y:-87.7554},
{x:37.1387,y:-84.5432},
{x:31.5891,y:-106.2339},
{x:31.6942,y:-106.3328},
{x:44.8485,y:-123.2355},
{x:31.7045,y:-106.2812},
{x:28.521,y:-98.1855},
{x:35.9919,y:-87.4884},
{x:26.2676,y:-81.802},
{x:47.0024,y:-120.5329},
{x:47.4679,y:-120.3338},
{x:35.3374,y:-101.3814},
{x:47.6059,y:-122.323},
{x:29.5171,y:-98.4118},
{x:39.665,y:-86.0985},
{x:35.3368,y:-82.4452},
{x:35.3627,y:-97.5682},
{x:26.1661,y:-80.1672},
{x:28.0858,y:-82.5033},
{x:34.3453,y:-119.0816},
{x:34.0789,y:-117.6281},
{x:41.6886,y:-71.5618},
{x:38.9368,y:-77.0247},
{x:44.5753,y:-87.8743},
{x:34.3668,y:-80.0688},
{x:33.1769,y:-97.0908},
{x:36.5794,y:-115.6762},
{x:35.1515,y:-80.8755},
{x:47.8202,y:-122.2672},
{x:33.5386,y:-112.1319},
{x:33.6996,y:-84.5783},
{x:39.12,y:-78.196},
{x:32.8777,y:-111.7297},
{x:38.7798,y:-76.0821},
{x:41.1172,y:-102.9517},
{x:28.0543,y:-82.4118},
{x:39.3599,y:-84.3094},
{x:32.3488,y:-106.7652},
{x:43.3067,y:-70.7355},
{x:42.7204,y:-82.7452},
{x:30.3608,y:-87.3616},
{x:32.679,y:-93.7436},
{x:37.8714,y:-122.2679},
{x:33.6331,y:-117.9157},
{x:39.3802,y:-76.5371},
{x:34.4323,y:-86.9368},
{x:30.2114,y:-90.9244},
{x:40.2908,y:-79.3569},
{x:33.8338,y:-117.8383},
{x:39.6375,y:-104.898},
{x:42.8992,y:-75.6449},
{x:42.4121,y:-83.3733},
{x:33.0418,y:-83.9436},
{x:39.7893,y:-77.7327},
{x:43.3494,y:-97.4216},
{x:61.5998,y:-149.2428},
{x:39.1092,y:-76.9951},
{x:38.5999,y:-83.3194},
{x:34.2167,y:-118.3882},
{x:38.9089,y:-76.7197},
{x:41.4797,y:-81.8407},
{x:37.7572,y:-122.2526},
{x:42.2426,y:-87.9784},
{x:32.2739,y:-90.0732},
{x:29.4959,y:-81.5942},
{x:41.2688,y:-112.0275},
{x:37.9784,y:-87.5724},
{x:35.8732,y:-84.2377},
{x:38.5471,y:-75.1079},
{x:42.6965,y:-73.6901},
{x:40.0629,y:-105.927},
{x:42.9593,y:-87.8764},
{x:42.6221,y:-73.8338},
{x:37.3372,y:-122.0662},
{x:41.2912,y:-82.2175},
{x:40.6204,y:-74.7644},
{x:29.466,y:-98.6028},
{x:29.425,y:-98.4932},
{x:26.1028,y:-80.1424},
{x:33.7533,y:-84.3907},
{x:33.0981,y:-96.8931},
{x:41.8793,y:-87.8085},
{x:29.2164,y:-96.2889},
{x:37.9875,y:-121.7848},
{x:25.7413,y:-80.294},
{x:25.8039,y:-80.1256},
{x:25.7952,y:-80.2242},
{x:47.4638,y:-122.3333},
{x:47.75,y:-116.794},
{x:44.8076,y:-68.9069},
{x:42.0424,y:-87.8405},
{x:36.0746,y:-79.2681},
{x:35.955,y:-78.9991},
{x:42.4009,y:-71.1185},
{x:29.5978,y:-95.3524},
{x:32.0269,y:-81.1109},
{x:37.4468,y:-77.686},
{x:32.4315,y:-96.224},
{x:40.3138,y:-111.7209},
{x:34.9465,y:-106.6627},
{x:62.1364,y:-150.0403},
{x:34.1964,y:-79.705},
{x:34.1672,y:-118.5894},
{x:34.888,y:-94.6035},
{x:44.5396,y:-123.3587},
{x:35.6179,y:-106.0309},
{x:35.7887,y:-86.4999},
{x:39.4581,y:-82.2309},
{x:34.5422,y:-82.6678},
{x:34.5546,y:-82.6794},
{x:35.098,y:-89.8633},
{x:29.5153,y:-98.5703},
{x:36.68,y:-121.6052},
{x:39.1448,y:-77.1461},
{x:30.2285,y:-95.5196},
{x:37.4912,y:-90.3065},
{x:42.041,y:-87.806},
{x:31.3225,y:-94.8212},
{x:33.8996,y:-117.555},
{x:40.2622,y:-86.6779},
{x:33.9589,y:-117.3923},
{x:47.7032,y:-117.0303},
{x:42.025,y:-87.802},
{x:34.4387,y:-119.7519},
{x:34.5462,y:-117.3021},
{x:39.1681,y:-123.2093},
{x:39.0778,y:-121.5444},
{x:30.2185,y:-81.9414},
{x:42.9879,y:-88.0264},
{x:40.1224,y:-96.6629},
{x:48.1095,y:-123.3589},
{x:25.7025,y:-80.2853},
{x:18.3207,y:-66.4131},
{x:30.4972,y:-87.1928},
{x:46.8317,y:-96.8447},
{x:40.7029,y:-99.0936},
{x:38.8942,y:-77.4284},
{x:40.4791,y:-96.961},
{x:36.1123,y:-86.4212},
{x:42.173,y:-71.1942},
{x:40.003,y:-82.8602},
{x:29.866,y:-95.412},
{x:33.0366,y:-84.7133},
{x:34.7974,y:-91.7508},
{x:35.0689,y:-91.9202},
{x:29.7687,y:-95.01},
{x:45.1928,y:-123.2113},
{x:37.7925,y:-85.9135},
{x:38.4972,y:-80.756},
{x:40.6866,y:-111.8238},
{x:37.4038,y:-121.9945},
{x:26.011,y:-80.1534},
{x:47.5196,y:-122.2671},
{x:34.0418,y:-118.4595},
{x:29.8246,y:-90.0204},
{x:31.9043,y:-94.4021},
{x:26.234,y:-97.574},
{x:42.0992,y:-75.9505},
{x:44.7106,y:-96.2739},
{x:39.6434,y:-84.2884},
{x:45.5495,y:-122.4972},
{x:44.0696,y:-116.9229},
{x:42.1713,y:-70.9531},
{x:37.6859,y:-75.7236},
{x:35.5956,y:-97.7266},
{x:42.081,y:-87.907},
{x:42.9154,y:-78.6726},
{x:36.3577,y:-119.6539},
{x:34.0751,y:-118.0727},
{x:30.5677,y:-89.0446},
{x:38.8173,y:-77.2762},
{x:42.2316,y:-75.9167},
{x:32.3209,y:-96.2142},
{x:43.646,y:-72.2537},
{x:45.4476,y:-99.0304},
{x:38.7998,y:-82.2651},
{x:39.4919,y:-77.5679},
{x:40.7053,y:-80.404},
{x:41.5883,y:-87.4595},
{x:33.313,y:-89.1765},
{x:29.6894,y:-95.5715},
{x:33.8563,y:-84.2051},
{x:42.3325,y:-71.1115},
{x:30.7043,y:-86.1222},
{x:41.5144,y:-87.5187},
{x:41.8096,y:-87.5941},
{x:33.6763,y:-117.8558},
{x:28.2582,y:-82.7077},
{x:37.0381,y:-93.4661},
{x:29.6484,y:-95.6778},
{x:38.0823,y:-120.561},
{x:38.8587,y:-94.5219},
{x:39.0581,y:-96.0363},
{x:39.1775,y:-95.5694},
{x:33.7469,y:-116.9321},
{x:33.7739,y:-117.0061},
{x:40.5686,y:-105.0834},
{x:38.7836,y:-76.8978},
{x:37.4574,y:-82.7414},
{x:34.0623,y:-117.4357},
{x:33.1572,y:-85.363},
{x:40.6026,y:-73.7503},
{x:38.6514,y:-84.5738},
{x:38.8454,y:-76.8833},
{x:33.8011,y:-116.4592},
{x:26.1513,y:-80.2572},
{x:40.8073,y:-73.2384},
{x:33.7895,y:-118.1739},
{x:40.7658,y:-73.174},
{x:40.4052,y:-82.4931},
{x:38.8045,y:-77.0433},
{x:30.7105,y:-94.9508},
{x:41.1005,y:-114.9571},
{x:46.0907,y:-122.9417},
{x:40.6223,y:-74.0312},
{x:36.4933,y:-80.5979},
{x:29.9798,y:-95.5138},
{x:41.7953,y:-88.2047},
{x:35.6476,y:-82.0303},
{x:39.1711,y:-94.5762},
{x:33.9028,y:-118.1003},
{x:43.0287,y:-71.0701},
{x:33.7832,y:-118.0724},
{x:38.0972,y:-78.4666},
{x:38.8542,y:-94.4043},
{x:38.8833,y:-94.7775},
{x:39.0392,y:-94.272},
{x:40.2655,y:-76.6988},
{x:35.7543,y:-79.8051},
{x:37.1264,y:-93.369},
{x:41.6127,y:-90.1865},
{x:42.8355,y:-84.2189},
{x:42.5665,y:-71.4225},
{x:31.3054,y:-96.8753},
{x:37.3998,y:-122.11},
{x:33.7474,y:-117.8105},
{x:36.0127,y:-93.1811},
{x:29.3484,y:-81.7363},
{x:37.4106,y:-121.9456},
{x:40.6688,y:-89.5618},
{x:32.1825,y:-91.7282},
{x:41.8313,y:-71.3623},
{x:40.8413,y:-101.7254},
{x:41.0219,y:-81.5257},
{x:41.128,y:-78.7372},
{x:33.8472,y:-117.8358},
{x:25.8942,y:-97.4477},
{x:41.5636,y:-72.9091},
{x:39.2095,y:-81.0513},
{x:38.6288,y:-75.8593},
{x:29.7811,y:-95.9509},
{x:41.9295,y:-70.6573},
{x:35.1077,y:-106.5516},
{x:27.3118,y:-98.6776},
{x:44.7158,y:-85.5947},
{x:39.0418,y:-121.0933},
{x:42.9387,y:-71.4279},
{x:25.9963,y:-80.1458},
{x:41.5436,y:-72.966},
{x:39.3768,y:-76.2885},
{x:41.0581,y:-73.5141},
{x:37.2401,y:-79.617},
{x:33.6784,y:-117.9069},
{x:29.8581,y:-95.5232},
{x:45.4509,y:-92.453},
{x:32.7878,y:-108.2524},
{x:36.1073,y:-115.1526},
{x:48.0556,y:-122.1124},
{x:34.0475,y:-117.5478},
{x:37.4507,y:-122.1853},
{x:30.4167,y:-97.0055},
{x:41.4106,y:-73.4357},
{x:47.6258,y:-122.1547},
{x:35.6097,y:-90.331},
{x:33.9978,y:-96.7039},
{x:32.9064,y:-80.0207},
{x:35.1234,y:-77.089},
{x:34.8067,y:-79.5427},
{x:36.8393,y:-121.3959},
{x:40.4325,y:-86.9144},
{x:33.8791,y:-117.9644},
{x:31.7292,y:-89.1398},
{x:41.6027,y:-93.659},
{x:38.6122,y:-121.4946},
{x:39.9421,y:-77.6288},
{x:38.8912,y:-107.9287},
{x:39.6978,y:-86.4176},
{x:46.712,y:-117.1772},
{x:37.4106,y:-79.2101},
{x:30.0696,y:-95.45},
{x:40.838,y:-73.396},
{x:25.7827,y:-80.2391},
{x:27.448,y:-80.3986},
{x:29.7503,y:-81.3445},
{x:30.6432,y:-83.1897},
{x:33.878,y:-118.1686},
{x:28.0273,y:-97.0776},
{x:36.7057,y:-121.6685},
{x:36.9816,y:-122.0019},
{x:34.1575,y:-86.8373},
{x:42.0489,y:-91.6398},
{x:34.8984,y:-98.3595},
{x:42.7268,y:-84.6433},
{x:41.4246,y:-82.3476},
{x:37.6399,y:-77.5623},
{x:37.537,y:-77.3209},
{x:38.0312,y:-82.1293},
{x:41.2322,y:-111.9786},
{x:42.0015,y:-87.6714},
{x:34.7502,y:-92.3382},
{x:41.2661,y:-73.0026},
{x:18.0409,y:-66.5481},
{x:35.1546,y:-106.5903},
{x:35.1134,y:-106.6059},
{x:35.6437,y:-86.9018},
{x:35.855,y:-86.3665},
{x:41.7364,y:-93.6002},
{x:35.8238,y:-86.4172},
{x:43.0391,y:-87.911},
{x:40.2686,y:-80.011},
{x:37.7746,y:-122.2119},
{x:41.5252,y:-75.952},
{x:34.6924,y:-82.847},
{x:30.2572,y:-88.1129},
{x:42.9896,y:-87.9885},
{x:32.8468,y:-97.2382},
{x:32.3961,y:-99.7627},
{x:42.2859,y:-71.1285},
{x:25.7505,y:-80.2496},
{x:41.4236,y:-112.0521},
{x:38.9099,y:-94.3626},
{x:43.4888,y:-116.4043},
{x:32.1713,y:-111.0714},
{x:42.105,y:-87.829},
{x:41.6134,y:-90.3353},
{x:42.5067,y:-83.7631},
{x:41.8741,y:-87.6669},
{x:41.8133,y:-72.5266},
{x:32.8659,y:-97.1082},
{x:42.8587,y:-73.3367},
{x:43.1784,y:-89.2375},
{x:41.9715,y:-87.9599},
{x:39.1336,y:-85.1319},
{x:39.9582,y:-75.2084},
{x:40.483,y:-111.9875},
{x:41.9239,y:-87.746},
{x:43.9389,y:-76.0978},
{x:27.4368,y:-82.4577},
{x:38.8499,y:-121.2864},
{x:35.4767,y:-77.4353},
{x:41.3724,y:-96.1612},
{x:40.6306,y:-81.9237},
{x:30.441,y:-81.763},
{x:41.5097,y:-84.3063},
{x:33.4422,y:-84.6916},
{x:40.7609,y:-73.9977},
{x:40.2872,y:-76.6557},
{x:40.2609,y:-76.8805},
{x:29.3587,y:-98.1371},
{x:29.8795,y:-95.7236},
{x:34.7532,y:-87.9689},
{x:43.7783,y:-116.9298},
{x:32.7513,y:-99.8966},
{x:36.7794,y:-119.8611},
{x:40.3278,y:-80.0384},
{x:41.6637,y:-85.0285},
{x:29.0609,y:-99.0037},
{x:29.5052,y:-98.4586},
{x:43.5258,y:-89.3033},
{x:33.3139,y:-111.8411},
{x:36.8234,y:-119.7705},
{x:35.9653,y:-84.9812},
{x:39.677,y:-86.3115},
{x:37.8752,y:-122.2597},
{x:41.5258,y:-74.1297},
{x:42.6426,y:-71.334},
{x:30.4429,y:-85.874},
{x:43.921,y:-87.7624},
{x:41.7998,y:-87.7232},
{x:44.4488,y:-73.2081},
{x:35.2218,y:-114.2118},
{x:39.5314,y:-119.8639},
{x:40.1495,y:-76.8136},
{x:37.9891,y:-122.042},
{x:41.264,y:-110.9766},
{x:37.3001,y:-78.393},
{x:38.1173,y:-121.3944},
{x:30.3401,y:-95.0677},
{x:41.9754,y:-87.9033},
{x:41.8809,y:-87.6844},
{x:34.2082,y:-118.4653},
{x:34.2232,y:-118.4499},
{x:41.4956,y:-87.0445},
{x:33.6275,y:-117.2765},
{x:29.7412,y:-94.9799},
{x:30.2866,y:-81.4866},
{x:35.1414,y:-85.3267},
{x:39.4842,y:-88.1793},
{x:42.1561,y:-84.0415},
{x:39.2901,y:-76.7167},
{x:38.9802,y:-76.7341},
{x:37.7849,y:-122.4094},
{x:38.4335,y:-80.1094},
{x:42.1124,y:-77.2215},
{x:39.0849,y:-77.1531},
{x:43.0521,y:-76.1139},
{x:33.9729,y:-117.6894},
{x:40.7563,y:-74.0456},
{x:41.8112,y:-71.36},
{x:28.217,y:-82.693},
{x:37.7998,y:-122.2519},
{x:35.1486,y:-89.9178},
{x:36.3032,y:-82.3719},
{x:35.5488,y:-77.4035},
{x:41.5631,y:-83.5447},
{x:36.061,y:-115.041},
{x:34.2271,y:-77.8793},
{x:27.5226,y:-97.8815},
{x:36.1061,y:-115.1383},
{x:40.8616,y:-79.9041},
{x:43.1322,y:-77.6357},
{x:39.7082,y:-104.8766},
{x:42.368,y:-83.1381},
{x:39.3986,y:-77.3476},
{x:26.5313,y:-80.0942},
{x:47.625,y:-122.632},
{x:48.0525,y:-122.1765},
{x:42.2563,y:-121.799},
{x:33.5923,y:-101.8406},
{x:39.2511,y:-94.6655},
{x:41.7636,y:-72.6867},
{x:41.9522,y:-79.9916},
{x:33.8027,y:-118.0599},
{x:47.2454,y:-122.3844},
{x:39.1087,y:-84.51},
{x:39.1395,y:-84.5022},
{x:33.5076,y:-111.8899},
{x:38.922,y:-76.8472},
{x:44.112,y:-70.1121},
{x:40.6742,y:-75.3875},
{x:27.5661,y:-99.4735},
{x:41.8949,y:-87.7562},
{x:42.8519,y:-106.2695},
{x:38.4525,y:-81.939},
{x:32.6694,y:-114.6225},
{x:33.7175,y:-84.2147},
{x:38.9292,y:-84.5449},
{x:38.982,y:-105.0467},
{x:41.5981,y:-93.5167},
{x:44.1726,y:-73.2405},
{x:31.6287,y:-97.2356},
{x:32.3056,y:-95.3336},
{x:41.7678,y:-80.9465},
{x:48.9639,y:-122.4411},
{x:36.309,y:-94.1834},
{x:45.3026,y:-122.7722},
{x:37.3666,y:-121.959},
{x:25.7798,y:-80.2208},
{x:39.9149,y:-93.9614},
{x:40.3749,y:-86.7775},
{x:34.1043,y:-118.3305},
{x:41.6715,y:-87.7394},
{x:29.7764,y:-95.2907},
{x:40.5128,y:-74.3841},
{x:45.1419,y:-93.1644},
{x:42.0571,y:-73.9558},
{x:39.1716,y:-86.5104},
{x:29.8025,y:-95.8081},
{x:36.335,y:-94.1889},
{x:34.1206,y:-84.7441},
{x:29.7913,y:-95.2669},
{x:32.9115,y:-96.6312},
{x:42.8575,y:-85.7186},
{x:42.565,y:-83.0302},
{x:32.7578,y:-117.0779},
{x:42.5759,y:-83.0106},
{x:42.5877,y:-83.0301},
{x:45.4967,y:-122.6707},
{x:37.6508,y:-121.8044},
{x:40.062,y:-76.5383},
{x:35.0519,y:-89.9528},
{x:45.5712,y:-122.6137},
{x:37.0311,y:-84.6267},
{x:34.5065,y:-109.3819},
{x:34.1104,y:-80.8868},
{x:41.9761,y:-91.7159},
{x:34.7593,y:-84.9998},
{x:32.0987,y:-81.2703},
{x:38.3023,y:-76.656},
{x:36.0433,y:-95.9532},
{x:39.1171,y:-94.6719},
{x:44.9487,y:-93.0921},
{x:35.6012,y:-119.3555},
{x:42.4415,y:-73.2562},
{x:35.661,y:-78.8366},
{x:38.8695,y:-90.0701},
{x:45.5828,y:-122.7254},
{x:47.5456,y:-122.0553},
{x:36.0048,y:-77.7753},
{x:32.2249,y:-90.0318},
{x:36.6661,y:-121.6562},
{x:36.1074,y:-115.1404},
{x:29.5489,y:-95.1293},
{x:36.6722,y:-121.6244},
{x:35.1513,y:-106.6175},
{x:35.6436,y:-106.0138},
{x:40.733,y:-73.8638},
{x:33.7772,y:-84.3898},
{x:42.4046,y:-71.024},
{x:41.2462,y:-78.7872},
{x:45.1035,y:-93.4524},
{x:36.1553,y:-86.7746},
{x:36.19,y:-86.2716},
{x:44.9174,y:-93.2165},
{x:39.1033,y:-77.0755},
{x:41.4267,y:-81.8443},
{x:33.8929,y:-118.3529},
{x:46.7476,y:-92.1566},
{x:28.618,y:-81.2624},
{x:40.0623,y:-76.358},
{x:42.0191,y:-87.843},
{x:39.7967,y:-82.7018},
{x:42.0002,y:-88.0602},
{x:34.1664,y:-119.1984},
{x:29.5822,y:-97.9916},
{x:35.1503,y:-114.5683},
{x:28.3561,y:-81.6766},
{x:27.3177,y:-82.5294},
{x:42.123,y:-87.936},
{x:36.6381,y:-120.6271},
{x:35.3837,y:-92.2176},
{x:36.0574,y:-90.9698},
{x:44.9874,y:-122.9893},
{x:34.0157,y:-117.3394},
{x:34.4243,y:-117.3703},
{x:33.7069,y:-84.1153},
{x:47.1955,y:-122.2053},
{x:38.3033,y:-122.4754},
{x:41.6595,y:-91.5329},
{x:42.1178,y:-84.3642},
{x:32.382,y:-86.1723},
{x:29.6679,y:-95.4638},
{x:33.1411,y:-117.0429},
{x:32.717,y:-117.1592},
{x:30.2137,y:-92.021},
{x:42.2501,y:-83.6591},
{x:35.8937,y:-84.5464},
{x:37.203,y:-93.2866},
{x:40.2418,y:-88.6425},
{x:33.6178,y:-86.7389},
{x:35.1391,y:-82.1037},
{x:29.9201,y:-95.6859},
{x:38.814,y:-94.5076},
{x:33.1194,y:-96.6719},
{x:32.7804,y:-79.9317},
{x:39.8263,y:-83.1405},
{x:41.7033,y:-87.5262},
{x:40.0316,y:-82.9099},
{x:26.6636,y:-80.7153},
{x:29.6012,y:-82.9817},
{x:40.5804,y:-105.0156},
{x:40.206,y:-104.8228},
{x:37.7271,y:-81.8451},
{x:38.8834,y:-94.8056},
{x:36.9146,y:-116.7529},
{x:33.896,y:-84.1405},
{x:27.3749,y:-80.3927},
{x:39.0502,y:-94.6846},
{x:32.5554,y:-88.5628},
{x:38.768,y:-88.5084},
{x:40.4296,y:-76.5739},
{x:39.5263,y:-87.1121},
{x:33.2187,y:-91.7983},
{x:41.9049,y:-87.6486},
{x:42.9821,y:-71.4503},
{x:33.3055,y:-87.6025},
{x:35.7322,y:-83.9961},
{x:41.8835,y:-71.3871},
{x:41.7192,y:-84.2172},
{x:33.723,y:-84.3928},
{x:31.1028,y:-91.2924},
{x:33.8725,y:-118.303},
{x:37.6737,y:-85.8458},
{x:45.5694,y:-94.1408},
{x:42.1092,y:-87.8764},
{x:33.4909,y:-86.9581},
{x:29.6151,y:-95.495},
{x:36.1815,y:-85.9473},
{x:35.5804,y:-97.639},
{x:33.1409,y:-117.139},
{x:39.1729,y:-81.8452},
{x:33.8153,y:-117.8216},
{x:40.439,y:-79.9993},
{x:41.7211,y:-87.6595},
{x:25.7821,y:-80.1903},
{x:33.3998,y:-80.33},
{x:29.6958,y:-95.1743},
{x:29.6961,y:-95.4006},
{x:29.7055,y:-95.3858},
{x:40.8408,y:-73.8795},
{x:40.8613,y:-82.2823},
{x:37.7025,y:-122.142},
{x:41.7353,y:-77.6479},
{x:47.5782,y:-117.6827},
{x:33.6613,y:-95.5983},
{x:40.4076,y:-105.1014},
{x:41.374,y:-72.1446},
{x:41.3699,y:-72.0715},
{x:34.1718,y:-118.4047},
{x:41.7305,y:-88.1304},
{x:34.2358,y:-118.5887},
{x:33.625,y:-117.9278},
{x:36.9183,y:-121.7856},
{x:36.9615,y:-122.0458},
{x:21.4006,y:-157.9728},
{x:41.6176,y:-87.0453},
{x:33.4067,y:-111.6313},
{x:41.3738,y:-91.1263},
{x:36.0955,y:-79.9968},
{x:42.0846,y:-87.7983},
{x:43.0923,y:-76.1721},
{x:43.0571,y:-76.2661},
{x:42.4312,y:-83.1866},
{x:39.3462,y:-76.6986},
{x:39.347,y:-76.6887},
{x:41.0999,y:-80.6494},
{x:36.6539,y:-76.233},
{x:39.3006,y:-94.9274},
{x:39.2575,y:-94.9004},
{x:42.7897,y:-78.781},
{x:37.7791,y:-122.3921},
{x:36.0287,y:-80.3813},
{x:33.935,y:-118.1434},
{x:42.4667,y:-97.9094},
{x:33.9034,y:-84.6741},
{x:40.8607,y:-76.5512},
{x:42.0261,y:-87.7291},
{x:41.1736,y:-86.0372},
{x:43.572,y:-70.3862},
{x:42.7267,y:-83.1979},
{x:42.0936,y:-72.4995},
{x:41.6856,y:-87.5067},
{x:41.4702,y:-87.3543},
{x:27.183,y:-80.7368},
{x:43.7145,y:-70.3344},
{x:36.176,y:-94.2041},
{x:38.7697,y:-83.0165},
{x:28.1252,y:-81.6404},
{x:31.7494,y:-93.0828},
{x:31.2024,y:-81.9835},
{x:41.7063,y:-71.4156},
{x:40.7196,y:-73.9908},
{x:38.7042,y:-93.2283},
{x:41.797,y:-72.5541},
{x:41.428,y:-81.5252},
{x:33.4765,y:-117.6856},
{x:34.7569,y:-92.2744},
{x:36.2188,y:-115.129},
{x:37.0744,y:-76.3611},
{x:32.5981,y:-83.5823},
{x:33.4133,y:-82.136},
{x:42.095,y:-88.2876},
{x:31.8662,y:-81.609},
{x:47.1272,y:-122.6055},
{x:47.0756,y:-122.5913},
{x:31.8126,y:-106.4169},
{x:35.9726,y:-94.33},
{x:44.9777,y:-93.2678},
{x:29.2683,y:-97.7627},
{x:30.4821,y:-87.2006},
{x:31.8747,y:-90.396},
{x:40.7652,y:-73.8185},
{x:29.9525,y:-95.6052},
{x:38.386,y:-121.9353},
{x:36.1711,y:-115.2049},
{x:39.3104,y:-76.6421},
{x:25.9478,y:-80.1483},
{x:42.7476,y:-76.1353},
{x:41.4337,y:-82.7111},
{x:40.9683,y:-80.31},
{x:35.1522,y:-89.8903},
{x:39.098,y:-77.1371},
{x:30.4051,y:-89.2477},
{x:42.3055,y:-83.694},
{x:44.9185,y:-93.5031},
{x:27.7286,y:-97.3798},
{x:45.6159,y:-94.2252},
{x:38.2176,y:-104.6247},
{x:44.5633,y:-92.5413},
{x:33.1644,y:-90.4986},
{x:41.1689,y:-87.8752},
{x:36.6026,y:-121.9159},
{x:42.2174,y:-85.7859},
{x:36.5591,y:-82.5505},
{x:34.965,y:-92.4229},
{x:41.4,y:-82.8083},
{x:44.0204,y:-71.1168},
{x:40.7578,y:-73.8347},
{x:32.7453,y:-97.0025},
{x:40.0983,y:-84.6277},
{x:42.5256,y:-85.8341},
{x:29.4087,y:-98.4874},
{x:25.8952,y:-80.2439},
{x:30.4767,y:-97.7949},
{x:41.639,y:-87.4449},
{x:40.6859,y:-111.7944},
{x:44.9992,y:-93.2861},
{x:37.9747,y:-100.8693},
{x:38.7383,y:-98.2152},
{x:38.2208,y:-98.2068},
{x:34.3836,y:-117.3721},
{x:32.8205,y:-97.0829},
{x:33.3633,y:-111.9094},
{x:42.5138,y:-93.2624},
{x:42.3794,y:-83.7553},
{x:42.2708,y:-77.6059},
{x:42.2974,y:-85.2352},
{x:29.6666,y:-95.6441},
{x:38.0128,y:-78.5035},
{x:38.3746,y:-78.73},
{x:36.1624,y:-86.5122},
{x:33.7,y:-116.2149},
{x:38.8757,y:-77.1981},
{x:33.9469,y:-117.4772},
{x:38.053,y:-102.1219},
{x:39.9541,y:-89.5542},
{x:29.9234,y:-95.6051},
{x:29.7694,y:-94.9645},
{x:38.736,y:-90.1409},
{x:42.2708,y:-83.7408},
{x:36.2053,y:-86.6942},
{x:42.275,y:-83.7407},
{x:32.6219,y:-93.2742},
{x:38.8622,y:-77.296},
{x:32.4733,y:-93.7987},
{x:40.6689,y:-73.9178},
{x:34.0664,y:-117.2881},
{x:41.9597,y:-71.1187},
{x:47.4671,y:-122.3406},
{x:36.0014,y:-106.0623},
{x:44.2771,y:-105.4856},
{x:33.6979,y:-111.8885},
{x:41.8517,y:-87.6952},
{x:41.8854,y:-87.6295},
{x:35.7859,y:-86.3683},
{x:35.8733,y:-86.4825},
{x:34.156,y:-118.7575},
{x:40.6663,y:-73.9883},
{x:36.1645,y:-95.7606},
{x:33.215,y:-87.5448},
{x:29.7954,y:-94.907},
{x:31.5656,y:-89.5131},
{x:41.581,y:-83.8812},
{x:32.7581,y:-97.8068},
{x:37.0659,y:-76.4912},
{x:34.0174,y:-81.1534},
{x:30.4015,y:-88.7189},
{x:37.0782,y:-76.4653},
{x:38.1894,y:-85.5178},
{x:35.2813,y:-95.5834},
{x:36.2587,y:-86.7139},
{x:43.04,y:-71.1687},
{x:44.079,y:-70.1911},
{x:40.3638,y:-75.9198},
{x:32.901,y:-96.768},
{x:36.2147,y:-86.5959},
{x:43.0895,y:-88.1453},
{x:36.5196,y:-89.6039},
{x:39.4338,y:-87.332},
{x:41.1941,y:-76.3839},
{x:31.5791,y:-84.0867},
{x:35.8545,y:-78.6408},
{x:34.2211,y:-118.5373},
{x:39.4169,y:-88.3826},
{x:29.5747,y:-98.5959},
{x:44.2871,y:-88.4471},
{x:39.9145,y:-75.1568},
{x:41.7139,y:-72.2169},
{x:46.4476,y:-120.4274},
{x:41.3497,y:-74.6864},
{x:37.7841,y:-87.075},
{x:35.174,y:-78.9982},
{x:37.9052,y:-121.1471},
{x:21.9688,y:-159.3867},
{x:41.3986,y:-72.8694},
{x:38.3308,y:-80.653},
{x:32.9636,y:-96.9153},
{x:39.1287,y:-79.4648},
{x:39.1709,y:-77.2626},
{x:34.657,y:-92.413},
{x:30.4477,y:-81.6382},
{x:32.8321,y:-117.161},
{x:39.9441,y:-83.0741},
{x:26.2466,y:-98.441},
{x:41.137,y:-81.5481},
{x:25.7897,y:-80.132},
{x:36.9162,y:-111.4574},
{x:35.3535,y:-82.5034},
{x:38.5881,y:-121.4135},
{x:44.9476,y:-93.2776},
{x:38.8968,y:-77.0284},
{x:21.467,y:-158.0021},
{x:33.1945,y:-87.4736},
{x:41.6716,y:-72.7774},
{x:42.5698,y:-83.0312},
{x:38.4079,y:-121.4495},
{x:41.6885,y:-83.5091},
{x:41.6887,y:-83.5092},
{x:41.6887,y:-83.5092},
{x:33.3577,y:-84.1207},
{x:33.635,y:-85.8258},
{x:29.0294,y:-82.6679},
{x:38.2799,y:-85.7437},
{x:39.6356,y:-84.0749},
{x:41.083,y:-81.4985},
{x:41.9108,y:-87.6382},
{x:37.7782,y:-81.1882},
{x:30.6337,y:-97.6817},
{x:39.3594,y:-76.5953},
{x:26.1805,y:-80.2678},
{x:27.4918,y:-82.4783},
{x:36.074,y:-80.3921},
{x:38.9117,y:-94.5916},
{x:40.286,y:-76.8422},
{x:42.4094,y:-71.0019},
{x:31.3325,y:-92.4007},
{x:36.573,y:-85.1468},
{x:38.8472,y:-77.3726},
{x:36.607,y:-77.5608},
{x:42.8247,y:-83.2728},
{x:25.4731,y:-80.4771},
{x:25.9008,y:-80.3082},
{x:41.0849,y:-74.2626},
{x:41.9909,y:-96.9211},
{x:38.043,y:-76.6236},
{x:40.9491,y:-74.1551},
{x:39.0991,y:-94.6487},
{x:36.5513,y:-87.4672},
{x:44.7952,y:-73.0732},
{x:33.6708,y:-112.2006},
{x:36.2447,y:-86.7514},
{x:26.6521,y:-80.2329},
{x:42.2275,y:-77.1839},
{x:44.7904,y:-93.6008},
{x:37.2014,y:-85.9352},
{x:37.3338,y:-121.8876},
{x:29.5103,y:-95.5272},
{x:33.2455,y:-111.8419},
{x:47.3016,y:-122.5805},
{x:29.3829,y:-98.6129},
{x:29.9952,y:-90.1832},
{x:38.6877,y:-104.7767},
{x:35.1317,y:-79.0255},
{x:33.4231,y:-82.1727},
{x:32.9679,y:-96.3321},
{x:30.0216,y:-90.2518},
{x:41.5526,y:-73.0637},
{x:33.9764,y:-80.4781},
{x:42.2,y:-72.6066},
{x:42.4343,y:-71.6854},
{x:42.4738,y:-71.4543},
{x:40.8177,y:-73.056},
{x:40.8085,y:-73.1091},
{x:45.0504,y:-92.9874},
{x:39.6764,y:-104.8478},
{x:36.5433,y:-119.2869},
{x:35.3021,y:-120.6645},
{x:41.6112,y:-83.627},
{x:36.3265,y:-86.5947},
{x:40.5025,y:-111.9387},
{x:40.6538,y:-111.9085},
{x:35.8055,y:-84.2624},
{x:41.9836,y:-87.6581},
{x:40.5266,y:-75.51},
{x:35.7431,y:-79.1683},
{x:38.8249,y:-77.2135},
{x:33.6092,y:-112.3925},
{x:32.6908,y:-113.9537},
{x:40.9134,y:-111.3984},
{x:29.1701,y:-81.0006},
{x:38.433,y:-90.9744},
{x:35.7777,y:-86.4008},
{x:35.7822,y:-86.4004},
{x:34.0618,y:-118.2903},
{x:34.0535,y:-118.2511},
{x:40.2545,y:-75.6653},
{x:47.2714,y:-122.4893},
{x:41.7735,y:-87.9496},
{x:39.9281,y:-77.2486},
{x:42.6283,y:-71.3214},
{x:32.8596,y:-96.7536},
{x:37.4658,y:-122.1569},
{x:37.1011,y:-76.4194},
{x:44.9923,y:-93.0243},
{x:42.1505,y:-84.5188},
{x:34.9286,y:-89.794},
{x:40.2894,y:-76.6551},
{x:40.292,y:-76.6549},
{x:47.7447,y:-116.8305},
{x:41.6841,y:-86.2514},
{x:39.9697,y:-81.5347},
{x:39.0627,y:-83.0171},
{x:39.3027,y:-77.0206},
{x:26.2336,y:-98.1787},
{x:35.5602,y:-82.6343},
{x:32.9621,y:-96.7507},
{x:33.3944,y:-104.5231},
{x:39.6095,y:-77.7296},
{x:36.422,y:-105.2975},
{x:36.3839,y:-86.4581},
{x:37.6474,y:-84.8052},
{x:38.6825,y:-84.0649},
{x:47.7113,y:-116.9968},
{x:42.4646,y:-82.9593},
{x:30.343,y:-94.1822},
{x:40.5021,y:-88.9247},
{x:41.1476,y:-73.2483},
{x:34.9799,y:-79.0067},
{x:40.8965,y:-73.7787},
{x:38.9759,y:-76.5252},
{x:34.0638,y:-117.6582},
{x:39.1063,y:-94.5589},
{x:36.646,y:-87.2},
{x:33.5692,y:-117.2048},
{x:30.3225,y:-81.7877},
{x:38.9498,y:-77.1203},
{x:38.7462,y:-121.2754},
{x:29.7869,y:-95.7203},
{x:33.7819,y:-117.2192},
{x:46.9338,y:-104.0077},
{x:44.341,y:-73.1184},
{x:45.524,y:-122.6726},
{x:40.1926,y:-76.9816},
{x:41.954,y:-87.787},
{x:45.7692,y:-108.5418},
{x:38.5995,y:-121.4238},
{x:35.0199,y:-89.8306},
{x:35.3215,y:-97.5116},
{x:33.7865,y:-84.3847},
{x:33.3665,y:-84.1812},
{x:36.0673,y:-115.0418},
{x:36.1301,y:-115.0847},
{x:40.6142,y:-79.19},
{x:32.7569,y:-84.8746},
{x:32.5449,y:-85.3724},
{x:36.0077,y:-79.807},
{x:35.7368,y:-78.7825},
{x:45.0813,y:-93.2417},
{x:38.7901,y:-77.014},
{x:40.5932,y:-74.2451},
{x:38.729,y:-121.273},
{x:39.1019,y:-84.5083},
{x:41.7647,y:-72.6686},
{x:42.3129,y:-71.2739},
{x:27.2838,y:-82.5302},
{x:41.7014,y:-81.2755},
{x:29.9032,y:-95.6322},
{x:37.9992,y:-88.9398},
{x:38.3518,y:-77.0573},
{x:39.6527,y:-104.9035},
{x:39.7044,y:-84.1704},
{x:35.7092,y:-78.6212},
{x:39.4864,y:-87.4139},
{x:29.9889,y:-95.4255},
{x:38.9914,y:-90.7474},
{x:35.4652,y:-97.4782},
{x:33.3276,y:-79.3166},
{x:28.9119,y:-98.5419},
{x:40.6945,y:-73.5703},
{x:38.5599,y:-121.4627},
{x:41.3685,y:-73.2713},
{x:39.7137,y:-82.58},
{x:41.7784,y:-88.1104},
{x:44.9751,y:-85.198},
{x:39.3722,y:-84.5476},
{x:37.6493,y:-97.3344},
{x:33.6801,y:-117.3707},
{x:39.0445,y:-82.6305},
{x:39.9583,y:-75.2584},
{x:35.6312,y:-80.1087},
{x:25.9005,y:-97.4938},
{x:25.9778,y:-97.4852},
{x:30.846,y:-93.7694},
{x:33.4366,y:-111.6849},
{x:38.9477,y:-90.9247},
{x:38.8293,y:-91.0211},
{x:41.3981,y:-72.8687},
{x:37.0644,y:-76.4191},
{x:34.3225,y:-78.5232},
{x:47.666,y:-122.1979},
{x:25.6729,y:-80.4414},
{x:27.9766,y:-81.6464},
{x:45.7715,y:-122.5493},
{x:46.1863,y:-122.906},
{x:24.7095,y:-81.1},
{x:39.5061,y:-119.7972},
{x:34.193,y:-118.4662},
{x:41.908,y:-87.853},
{x:37.5797,y:-122.3338},
{x:36.1948,y:-115.1187},
{x:29.841,y:-94.6469},
{x:44.3603,y:-89.8176},
{x:37.6773,y:-97.2047},
{x:28.3179,y:-80.6092},
{x:33.162,y:-117.3451},
{x:42.6262,y:-96.2924},
{x:34.0714,y:-99.0093},
{x:42.7528,y:-71.2069},
{x:29.3094,y:-100.4188},
{x:41.2335,y:-80.4931},
{x:30.101,y:-81.7741},
{x:42.3425,y:-71.0568},
{x:39.1524,y:-121.6199},
{x:44.6968,y:-93.2865},
{x:35.9174,y:-83.6694},
{x:33.6043,y:-81.8415},
{x:39.5891,y:-91.4064},
{x:35.1513,y:-89.9804},
{x:33.6807,y:-117.7791},
{x:27.1325,y:-80.2057},
{x:30.1792,y:-82.6912},
{x:44.8313,y:-89.1684},
{x:44.4884,y:-88.0187},
{x:43.7916,y:-88.4566},
{x:36.4803,y:-87.8904},
{x:27.2714,y:-80.4301},
{x:29.6685,y:-95.2635},
{x:27.4366,y:-80.3504},
{x:40.5906,y:-73.7974},
{x:37.6165,y:-82.7218},
{x:37.811,y:-82.8025},
{x:33.8475,y:-118.0116},
{x:40.8557,y:-73.8677},
{x:41.7988,y:-91.4902},
{x:41.1218,y:-112.0255},
{x:34.4814,y:-88.9999},
{x:27.2097,y:-80.3296},
{x:41.992,y:-87.798},
{x:32.8782,y:-96.7172},
{x:29.4188,y:-90.2954},
{x:26.3643,y:-80.1013},
{x:35.7637,y:-78.821},
{x:37.137,y:-85.9738},
{x:42.11,y:-88.062},
{x:44.9944,y:-101.2353},
{x:30.1752,y:-93.2196},
{x:39.5452,y:-83.4269},
{x:27.2436,y:-80.8478},
{x:36.6299,y:-93.4234},
{x:41.5695,y:-72.3268},
{x:42.4643,y:-82.9869},
{x:39.0502,y:-94.5968},
{x:46.9051,y:-96.8012},
{x:28.2789,y:-81.3468},
{x:32.7319,y:-97.4403},
{x:25.5707,y:-80.3757},
{x:33.757,y:-84.3952},
{x:46.9685,y:-119.041},
{x:34.8717,y:-89.6889},
{x:39.7268,y:-84.0577},
{x:41.8608,y:-87.6955},
{x:29.6266,y:-95.4645},
{x:41.8185,y:-71.4623},
{x:26.116,y:-80.3309},
{x:42.9293,y:-123.28},
{x:27.2711,y:-80.3009},
{x:35.3503,y:-119.1264},
{x:33.3792,y:-111.843},
{x:32.5768,y:-117.0939},
{x:61.2114,y:-149.7439},
{x:40.7989,y:-76.8623},
{x:42.0067,y:-83.9597},
{x:38.8134,y:-82.7268},
{x:37.0403,y:-78.4835},
{x:38.1812,y:-84.816},
{x:39.1019,y:-96.8167},
{x:32.1823,y:-110.8763},
{x:33.525,y:-117.1541},
{x:41.9882,y:-88.184},
{x:40.3662,y:-79.985},
{x:40.7404,y:-73.8151},
{x:41.6734,y:-83.7113},
{x:32.8722,y:-97.3128},
{x:39.1179,y:-77.1836},
{x:44.5438,y:-73.214},
{x:43.1129,y:-72.4289},
{x:41.9535,y:-87.7356},
{x:33.7409,y:-112.3222},
{x:33.2555,y:-111.6391},
{x:29.6338,y:-95.5645},
{x:41.4078,y:-82.5929},
{x:25.8482,y:-80.1528},
{x:25.7645,y:-80.2434},
{x:41.9325,y:-88.0426},
{x:43.3184,y:-73.6159},
{x:38.8344,y:-77.3106},
{x:44.8795,y:-91.9301},
{x:41.5268,y:-85.065},
{x:35.1531,y:-106.6946},
{x:36.8571,y:-120.4565},
{x:35.297,y:-94.0308},
{x:27.8034,y:-82.6996},
{x:32.6314,y:-116.9677},
{x:41.535,y:-86.4269},
{x:36.9859,y:-120.6268},
{x:29.5845,y:-98.6177},
{x:41.8929,y:-80.8042},
{x:41.6639,y:-74.7008},
{x:41.5947,y:-93.6335},
{x:40.0764,y:-75.1286},
{x:18.121,y:-66.5029},
{x:42.1207,y:-72.7478},
{x:30.4145,y:-87.4514},
{x:42.9114,y:-85.6294},
{x:26.1946,y:-98.1836},
{x:30.4319,y:-84.9772},
{x:46.6035,y:-90.8516},
{x:39.4933,y:-84.3209},
{x:47.9094,y:-97.0445},
{x:41.5678,y:-93.8115},
{x:25.4791,y:-80.4625},
{x:36.5941,y:-86.5281},
{x:29.8602,y:-90.0452},
{x:30.0402,y:-94.1162},
{x:37.672,y:-97.4447},
{x:43.5907,y:-116.3122},
{x:43.6119,y:-116.2441},
{x:41.9897,y:-87.7339},
{x:41.256,y:-95.9776},
{x:36.1466,y:-86.7753},
{x:33.1138,y:-94.1635},
{x:35.5908,y:-87.0489},
{x:43.9128,y:-69.9787},
{x:31.6496,y:-86.7725},
{x:42.6187,y:-83.2471},
{x:45.9904,y:-123.9213},
{x:44.1058,y:-70.2272},
{x:32.6854,y:-97.9445},
{x:26.783,y:-80.1078},
{x:26.2026,y:-80.1875},
{x:44.8642,y:-93.4843},
{x:29.2503,y:-95.1703},
{x:42.3685,y:-83.0853},
{x:43.0171,y:-87.9671},
{x:37.216,y:-80.4013},
{x:44.812,y:-93.3292},
{x:44.8315,y:-92.2607},
{x:44.9384,y:-93.6668},
{x:41.7205,y:-87.7794},
{x:42.0809,y:-76.8577},
{x:33.6982,y:-117.7409},
{x:47.4924,y:-121.7804},
{x:39.2827,y:-76.7507},
{x:48.775,y:-122.4962},
{x:40.4445,y:-75.8614},
{x:43.844,y:-82.6542},
{x:33.4175,y:-111.9344},
{x:45.126,y:-93.2525},
{x:32.2799,y:-106.7507},
{x:27.983,y:-82.7318},
{x:26.3657,y:-80.1061},
{x:30.2853,y:-85.6361},
{x:39.5241,y:-119.7679},
{x:43.0937,y:-75.2762},
{x:40.7905,y:-74.0233},
{x:35.4951,y:-80.6287},
{x:41.4942,y:-81.9861},
{x:34.2578,y:-82.0899},
{x:36.1332,y:-115.1361},
{x:36.1135,y:-115.1716},
{x:36.1138,y:-115.1724},
{x:28.2378,y:-82.3569},
{x:30.3847,y:-87.6837},
{x:39.9794,y:-86.3958},
{x:38.6852,y:-90.2177},
{x:29.9822,y:-98.9026},
{x:31.9623,y:-110.3451},
{x:39.7506,y:-86.1882},
{x:34.16,y:-79.7519},
{x:41.6791,y:-77.0646},
{x:40.6532,y:-82.5467},
{x:42.2658,y:-84.7593},
{x:39.1188,y:-88.5558},
{x:30.7898,y:-83.2798},
{x:40.2794,y:-76.8277},
{x:41.6478,y:-70.2974},
{x:40.7967,y:-74.3126},
{x:44.4554,y:-93.1648},
{x:28.9392,y:-98.5142},
{x:43.5139,y:-96.7607},
{x:36.2899,y:-97.2916},
{x:37.7427,y:-87.116},
{x:43.4825,y:-111.9667},
{x:39.959,y:-82.6951},
{x:32.5767,y:-97.339},
{x:33.0665,y:-96.8924},
{x:42.4637,y:-70.9645},
{x:32.3848,y:-90.1929},
{x:35.1649,y:-84.8714},
{x:39.4831,y:-88.3824},
{x:30.5486,y:-98.3346},
{x:39.7715,y:-105.0801},
{x:42.5526,y:-71.7833},
{x:35.6934,y:-85.778},
{x:34.7894,y:-76.8764},
{x:42.381,y:-75.1843},
{x:28.2372,y:-82.3441},
{x:39.635,y:-106.521},
{x:26.1871,y:-80.2066},
{x:26.8835,y:-80.1042},
{x:39.6672,y:-104.9156},
{x:42.0075,y:-88.0448},
{x:36.0322,y:-75.6733},
{x:41.2936,y:-73.6739},
{x:43.2347,y:-87.9836},
{x:34.6549,y:-78.4955},
{x:40.3166,y:-79.6868},
{x:30.0973,y:-97.8772},
{x:38.1361,y:-81.0999},
{x:33.5502,y:-84.4494},
{x:39.5054,y:-78.7709},
{x:41.6882,y:-72.7699},
{x:41.9045,y:-88.0045},
{x:26.5034,y:-81.9154},
{x:34.9311,y:-120.4542},
{x:29.1883,y:-95.4327},
{x:36.5043,y:-82.4298},
{x:32.5389,y:-94.7449},
{x:39.9161,y:-83.0353},
{x:29.9534,y:-90.0704},
{x:29.9687,y:-90.0508},
{x:33.4105,y:-82.0266},
{x:46.5333,y:-93.706},
{x:45.2769,y:-92.9973},
{x:40.5528,y:-105.0962},
{x:42.6924,y:-88.544},
{x:40.6884,y:-80.32},
{x:34.7757,y:-90.7722},
{x:44.9855,y:-93.5724},
{x:42.2848,y:-83.7318},
{x:48.7389,y:-122.4862},
{x:29.9509,y:-95.5203},
{x:29.388,y:-94.9504},
{x:29.5672,y:-95.0592},
{x:40.5449,y:-84.9991},
{x:34.1023,y:-117.3},
{x:35.852,y:-86.362},
{x:42.7855,y:-96.7968},
{x:39.084,y:-78.1498},
{x:30.4944,y:-92.8442},
{x:35.6095,y:-97.4607},
{x:31.3183,y:-89.3297},
{x:38.2011,y:-77.5895},
{x:41.3268,y:-87.2002},
{x:34.4116,y:-119.8478},
{x:33.1112,y:-117.099},
{x:41.9098,y:-87.8074},
{x:32.6616,y:-116.9702},
{x:36.7335,y:-86.5783},
{x:42.2397,y:-71.1775},
{x:40.127,y:-88.2775},
{x:29.4504,y:-98.4498},
{x:37.8864,y:-85.9486},
{x:29.39,y:-98.6203},
{x:27.8626,y:-82.4981},
{x:29.3925,y:-98.6275},
{x:40.7106,y:-74.088},
{x:29.9133,y:-95.4872},
{x:31.7756,y:-106.255},
{x:36.8231,y:-76.1496},
{x:32.7497,y:-97.133},
{x:40.7611,y:-73.9508},
{x:44.2723,y:-123.1707},
{x:37.7986,y:-122.401},
{x:27.0179,y:-82.1662},
{x:39.7242,y:-104.7838},
{x:37.2929,y:-121.8332},
{x:37.3831,y:-121.8968},
{x:40.9462,y:-73.8824},
{x:40.7692,y:-73.9522},
{x:45.0133,y:-93.2469},
{x:44.7379,y:-93.2589},
{x:30.0702,y:-95.4075},
{x:27.7307,y:-97.3726},
{x:25.7936,y:-80.2072},
{x:39.6497,y:-78.755},
{x:40.0408,y:-87.6385},
{x:36.0234,y:-79.7273},
{x:40.41,y:-76.4781},
{x:38.3705,y:-90.6311},
{x:42.607,y:-83.1508},
{x:38.3295,y:-85.4679},
{x:41.3024,y:-75.9287},
{x:41.8905,y:-79.8479},
{x:29.4811,y:-98.5961},
{x:39.4151,y:-76.9794},
{x:47.7007,y:-116.7809},
{x:38.2486,y:-85.7974},
{x:39.7638,y:-86.3256},
{x:33.4356,y:-84.7517},
{x:37.2777,y:-82.1015},
{x:37.7403,y:-88.9647},
{x:37.4187,y:-122.109},
{x:33.7003,y:-117.8282},
{x:42.7255,y:-71.318},
{x:45.1524,y:-122.8322},
{x:43.5911,y:-116.3674},
{x:38.5527,y:-76.0536},
{x:27.3379,y:-82.4602},
{x:41.038,y:-93.7787},
{x:45.0981,y:-123.3955},
{x:33.7911,y:-87.2424},
{x:28.8097,y:-81.2735},
{x:26.3073,y:-80.28},
{x:43.0313,y:-74.3416},
{x:39.2953,y:-94.9087},
{x:32.5166,y:-90.0972},
{x:32.83,y:-88.4631},
{x:32.6092,y:-117.0811},
{x:39.2039,y:-94.5478},
{x:39.5361,y:-104.791},
{x:44.6815,y:-88.0494},
{x:30.283,y:-90.9558},
{x:41.5069,y:-75.5789},
{x:38.9165,y:-94.3396},
{x:47.6539,y:-117.1966},
{x:41.8346,y:-88.0261},
{x:43.0504,y:-88.2265},
{x:40.6061,y:-74.0013},
{x:42.3564,y:-83.2926},
{x:35.7194,y:-84.3674},
{x:39.5142,y:-86.4036},
{x:40.4549,y:-86.1265},
{x:36.0093,y:-106.0876},
{x:33.1834,y:-97.2877},
{x:42.1625,y:-72.6176},
{x:47.3398,y:-122.745},
{x:37.6525,y:-92.6682},
{x:39.7383,y:-104.9932},
{x:42.6487,y:-85.299},
{x:27.154,y:-80.2183},
{x:38.9825,y:-94.6076},
{x:30.4375,y:-95.4976},
{x:38.7825,y:-90.3815},
{x:26.6762,y:-80.063},
{x:39.6014,y:-74.3639},
{x:36.1184,y:-95.9394},
{x:30.3701,y:-91.1231},
{x:33.9926,y:-84.6281},
{x:40.079,y:-104.8035},
{x:35.898,y:-94.9764},
{x:39.3911,y:-76.6228},
{x:41.4983,y:-93.4995},
{x:19.6998,y:-156.0273},
{x:41.6968,y:-73.9064},
{x:41.1632,y:-79.0984},
{x:26.6304,y:-80.0841},
{x:26.0027,y:-80.3183},
{x:38.9932,y:-76.8505},
{x:40.3162,y:-74.3053},
{x:27.7392,y:-97.4063},
{x:41.508,y:-83.1494},
{x:37.6333,y:-122.1115},
{x:41.1463,y:-73.256},
{x:29.8787,y:-95.6674},
{x:29.5363,y:-95.5168},
{x:41.5305,y:-83.358},
{x:28.5046,y:-81.3126},
{x:28.7218,y:-81.3381},
{x:28.5374,y:-81.208},
{x:44.7089,y:-73.4536},
{x:40.1139,y:-76.3075},
{x:40.6782,y:-73.7761},
{x:40.7762,y:-89.6505},
{x:40.133,y:-78.5824},
{x:40.7066,y:-73.6576},
{x:40.6285,y:-74.2713},
{x:42.2611,y:-85.1477},
{x:32.3634,y:-86.2946},
{x:36.8936,y:-106.5821},
{x:33.8245,y:-92.4268},
{x:46.9186,y:-96.8254},
{x:26.3674,y:-80.1741},
{x:42.6653,y:-93.9047},
{x:34.9041,y:-116.8871},
{x:26.1806,y:-98.1863},
{x:44.8509,y:-87.3586},
{x:42.6807,y:-70.8387},
{x:39.5977,y:-82.5482},
{x:34.1937,y:-88.72},
{x:30.2847,y:-90.9207},
{x:34.4734,y:-117.3482},
{x:41.8019,y:-89.6666},
{x:42.2451,y:-72.5464},
{x:25.869,y:-80.3315},
{x:29.9206,y:-95.0684},
{x:39.1393,y:-88.5611},
{x:42.1613,y:-89.0107},
{x:26.2621,y:-98.17},
{x:26.2539,y:-98.2377},
{x:26.27,y:-98.2331},
{x:27.7573,y:-97.4585},
{x:27.6646,y:-97.3965},
{x:29.5594,y:-95.3925},
{x:37.825,y:-92.1522},
{x:42.8847,y:-75.1928},
{x:26.122,y:-80.1044},
{x:43.6176,y:-116.3913},
{x:38.0063,y:-90.2207},
{x:30.4696,y:-87.2252},
{x:38.9418,y:-77.0006},
{x:31.4065,y:-92.4112},
{x:34.0392,y:-77.8946},
{x:43.1483,y:-88.0022},
{x:47.5163,y:-122.6281},
{x:40.2647,y:-78.8338},
{x:47.6693,y:-117.4098},
{x:42.2083,y:-71.8353},
{x:29.4902,y:-95.1545},
{x:33.9756,y:-117.3319},
{x:38.5407,y:-82.6744},
{x:40.1591,y:-103.2103},
{x:43.8668,y:-121.4362},
{x:34.1374,y:-117.8716},
{x:41.6373,y:-83.4748},
{x:35.0549,y:-90.0243},
{x:36.9822,y:-85.8935},
{x:34.6419,y:-102.7281},
{x:37.9078,y:-122.3057},
{x:34.5742,y:-118.043},
{x:25.8726,y:-80.2097},
{x:40.8051,y:-73.1801},
{x:43.8692,y:-91.2006},
{x:40.7441,y:-73.3653},
{x:42.7037,y:-83.296},
{x:41.0411,y:-73.6723},
{x:41.0413,y:-73.673},
{x:29.673,y:-83.3803},
{x:29.5775,y:-90.7105},
{x:26.6533,y:-81.8524},
{x:33.8779,y:-84.2721},
{x:34.4993,y:-89.0292},
{x:40.9614,y:-73.8582},
{x:36.9292,y:-84.1037},
{x:34.0445,y:-118.4319},
{x:26.2961,y:-80.2497},
{x:33.8469,y:-118.1064},
{x:35.3722,y:-83.2057},
{x:41.4905,y:-90.4448},
{x:33.5359,y:-81.9888},
{x:42.8047,y:-82.9632},
{x:42.9021,y:-71.3263},
{x:35.8791,y:-83.776},
{x:32.9979,y:-96.7294},
{x:47.8648,y:-121.9731},
{x:34.9629,y:-90.0932},
{x:41.2458,y:-73.0085},
{x:41.2472,y:-73.01},
{x:41.4446,y:-91.0312},
{x:35.1489,y:-90.0468},
{x:41.5605,y:-90.5849},
{x:32.7824,y:-97.3816},
{x:41.583,y:-73.0733},
{x:29.5592,y:-98.2536},
{x:40.8434,y:-115.7503},
{x:34.7021,y:-76.7548},
{x:29.7282,y:-95.6464},
{x:35.818,y:-78.595},
{x:34.2485,y:-119.1956},
{x:28.9832,y:-95.9364},
{x:25.8824,y:-80.3529},
{x:33.8311,y:-118.2598},
{x:34.1956,y:-119.1949},
{x:39.6822,y:-105.0815},
{x:47.5585,y:-122.6757},
{x:30.7119,y:-94.933},
{x:44.5133,y:-87.9652},
{x:38.0227,y:-121.3577},
{x:41.7614,y:-93.5728},
{x:37.9542,y:-121.2859},
{x:40.5315,y:-79.5961},
{x:26.4133,y:-97.7897},
{x:38.4077,y:-122.84},
{x:38.4548,y:-121.3709},
{x:41.9184,y:-71.9094},
{x:42.4764,y:-96.3592},
{x:32.4708,y:-95.4507},
{x:32.546,y:-92.014},
{x:35.3254,y:-106.5743},
{x:38.5539,y:-121.4274},
{x:40.7556,y:-111.5707},
{x:37.706,y:-89.8932},
{x:36.3807,y:-82.3663},
{x:34.239,y:-118.5571},
{x:38.1856,y:-84.5704},
{x:39.6262,y:-77.6686},
{x:41.5514,y:-90.4853},
{x:37.5872,y:-122.0228},
{x:31.6109,y:-94.6408},
{x:26.2596,y:-80.1525},
{x:35.8639,y:-90.6437},
{x:29.6836,y:-95.7162},
{x:33.4217,y:-111.917},
{x:33.4063,y:-111.9259},
{x:40.95,y:-73.8264},
{x:43.8779,y:-117.0021},
{x:44.9615,y:-92.7321},
{x:32.2862,y:-90.047},
{x:29.717,y:-95.2945},
{x:44.7308,y:-93.22},
{x:33.8039,y:-118.1434},
{x:40.3295,y:-76.5152},
{x:34.1059,y:-117.6291},
{x:40.0617,y:-76.3077},
{x:38.6733,y:-90.2027},
{x:29.9427,y:-95.7155},
{x:35.594,y:-78.7818},
{x:42.0893,y:-71.4195},
{x:38.6685,y:-121.1672},
{x:44.0174,y:-70.9817},
{x:32.3042,y:-90.184},
{x:40.5034,y:-75.7883},
{x:34.0566,y:-118.2774},
{x:41.6862,y:-71.1415},
{x:32.3797,y:-94.8698},
{x:41.6715,y:-93.7369},
{x:32.7675,y:-96.6236},
{x:44.4676,y:-73.1722},
{x:41.2654,y:-96.1177},
{x:39.7767,y:-84.0516},
{x:40.0499,y:-83.0516},
{x:40.2901,y:-84.1918},
{x:38.2823,y:-104.5729},
{x:43.8855,y:-100.705},
{x:39.062,y:-88.3785},
{x:39.1053,y:-77.1966},
{x:40.4633,y:-76.3398},
{x:45.8079,y:-96.4906},
{x:35.4718,y:-85.0343},
{x:30.3196,y:-89.9584},
{x:45.0557,y:-93.3176},
{x:38.8896,y:-77.1267},
{x:35.289,y:-81.8445},
{x:38.5785,y:-121.5478},
{x:41.1046,y:-81.154},
{x:44.8845,y:-93.3272},
{x:33.0471,y:-97.0133},
{x:40.0589,y:-85.6519},
{x:43.1861,y:-96.1403},
{x:40.9084,y:-74.2054},
{x:39.6155,y:-119.8534},
{x:34.3183,y:-78.7089},
{x:39.4678,y:-81.4668},
{x:40.8175,y:-96.7004},
{x:33.5768,y:-83.9596},
{x:36.2655,y:-76.968},
{x:36.1054,y:-86.7956},
{x:45.6422,y:-118.6845},
{x:33.7256,y:-117.8024},
{x:37.2158,y:-89.5345},
{x:40.6673,y:-73.8098},
{x:41.3992,y:-81.5952},
{x:35.6945,y:-78.4213},
{x:36.7482,y:-78.0988},
{x:38.226,y:-85.7318},
{x:46.6589,y:-118.8613},
{x:38.1547,y:-85.6591},
{x:40.031,y:-76.4823},
{x:46.273,y:-96.0566},
{x:39.167,y:-94.5574},
{x:31.1319,y:-97.9008},
{x:42.7661,y:-71.4977},
{x:41.7073,y:-87.5594},
{x:34.4895,y:-78.6619},
{x:38.8887,y:-77.4432},
{x:35.0649,y:-94.6221},
{x:30.0795,y:-95.6742},
{x:36.0829,y:-79.8251},
{x:36.1022,y:-115.201},
{x:48.7918,y:-122.4842},
{x:31.8954,y:-102.3323},
{x:37.2502,y:-121.8297},
{x:37.3529,y:-121.8232},
{x:42.9553,y:-78.8216},
{x:33.9828,y:-117.733},
{x:37.9793,y:-87.403},
{x:42.9763,y:-82.5426},
{x:48.3432,y:-103.6203},
{x:41.0086,y:-76.4472},
{x:42.6482,y:-84.0778},
{x:32.7133,y:-96.6845},
{x:42.6357,y:-78.9893},
{x:28.7086,y:-100.4683},
{x:28.7084,y:-100.4975},
{x:41.4341,y:-85.0275},
{x:29.066,y:-95.4193},
{x:32.5342,y:-96.6739},
{x:31.7054,y:-87.7763},
{x:33.0864,y:-89.8572},
{x:35.1925,y:-111.625},
{x:40.4794,y:-82.5564},
{x:48.9204,y:-122.343},
{x:26.1472,y:-80.3173},
{x:37.9269,y:-122.0644},
{x:35.016,y:-78.9845},
{x:42.451,y:-84.4405},
{x:42.0718,y:-87.8056},
{x:41.3413,y:-73.0781},
{x:47.8107,y:-122.2058},
{x:42.5529,y:-71.2641},
{x:29.1847,y:-98.8465},
{x:35.5341,y:-108.7588},
{x:33.5385,y:-112.3567},
{x:31.9003,y:-81.6094},
{x:37.1585,y:-76.5808},
{x:30.6008,y:-81.5991},
{x:30.3954,y:-87.2919},
{x:38.632,y:-121.2201},
{x:34.0905,y:-118.2913},
{x:35.0053,y:-89.8481},
{x:27.9171,y:-82.8071},
{x:38.8031,y:-76.9504},
{x:47.6583,y:-117.3384},
{x:43.597,y:-116.5539},
{x:47.8637,y:-121.9755},
{x:40.1834,y:-92.5717},
{x:47.0988,y:-122.4338},
{x:47.6995,y:-117.4456},
{x:47.2339,y:-122.5035},
{x:36.77,y:-90.4184},
{x:37.0215,y:-76.4487},
{x:44.7797,y:-106.9402},
{x:38.5693,y:-92.2395},
{x:41.4127,y:-78.5606},
{x:42.4471,y:-73.2438},
{x:38.7275,y:-90.2743},
{x:34.0033,y:-84.0404},
{x:38.5549,y:-92.2015},
{x:34.1884,y:-84.1428},
{x:41.0613,y:-84.981},
{x:42.5387,y:-83.1817},
{x:33.4356,y:-111.7884},
{x:34.1581,y:-118.2636},
{x:38.7499,y:-90.4637},
{x:31.0784,y:-97.364},
{x:39.7766,y:-105.0579},
{x:31.7863,y:-89.0334},
{x:44.4705,y:-93.2945},
{x:29.6244,y:-95.285},
{x:29.8986,y:-95.4112},
{x:43.089,y:-75.2218},
{x:34.8282,y:-77.5782},
{x:42.0866,y:-80.1425},
{x:33.9199,y:-84.3525},
{x:42.8666,y:-112.4432},
{x:35.6115,y:-115.3905},
{x:33.5293,y:-86.748},
{x:36.1534,y:-86.7838},
{x:33.8525,y:-78.6857},
{x:36.9304,y:-80.9434},
{x:42.0868,y:-72.5563},
{x:47.5071,y:-111.2032},
{x:32.8404,y:-106.0819},
{x:41.4214,y:-87.4473},
{x:34.7412,y:-92.289},
{x:37.4726,y:-77.5267},
{x:38.5393,y:-91.0127},
{x:40.0108,y:-75.1764},
{x:36.8361,y:-119.9124},
{x:42.2176,y:-88.2549},
{x:43.4253,y:-85.4749},
{x:42.0595,y:-88.3133},
{x:35.3701,y:-80.8317},
{x:42.2863,y:-71.2355},
{x:35.618,y:-91.2541},
{x:33.9905,y:-118.2568},
{x:38.3313,y:-122.7156},
{x:39.1562,y:-77.2114},
{x:37.445,y:-77.3518},
{x:41.3508,y:-88.8409},
{x:41.7174,y:-88.3183},
{x:38.5046,y:-90.296},
{x:40.0522,y:-79.0714},
{x:30.3927,y:-88.9953},
{x:41.3531,y:-89.1274},
{x:41.2113,y:-76.759},
{x:40.7143,y:-74.2491},
{x:34.4382,y:-84.701},
{x:34.3509,y:-84.3791},
{x:34.0367,y:-84.051},
{x:39.1317,y:-88.4813},
{x:26.1646,y:-80.2258},
{x:30.061,y:-95.9112},
{x:35.879,y:-86.3834},
{x:39.0219,y:-94.6884},
{x:39.3361,y:-76.676},
{x:39.7944,y:-75.9785},
{x:40.6938,y:-89.5895},
{x:35.7957,y:-88.7861},
{x:33.6953,y:-84.2619},
{x:39.3381,y:-82.9752},
{x:36.997,y:-120.1059},
{x:46.3357,y:-94.4845},
{x:44.6773,y:-88.8788},
{x:45.0802,y:-93.716},
{x:37.3498,y:-87.4928},
{x:42.3145,y:-89.0767},
{x:36.7398,y:-98.3612},
{x:18.3808,y:-65.9645},
{x:43.001,y:-84.375},
{x:38.2382,y:-85.489},
{x:35.4276,y:-97.6549},
{x:32.8151,y:-115.5695},
{x:33.465,y:-112.1516},
{x:41.412,y:-80.3611},
{x:42.934,y:-78.8791},
{x:21.2971,y:-157.8358},
{x:27.9557,y:-82.4369},
{x:41.903,y:-87.719},
{x:37.396,y:-121.9737},
{x:42.9318,y:-85.7206},
{x:41.8757,y:-84.044},
{x:40.9002,y:-74.1646},
{x:32.3998,y:-89.955},
{x:34.2326,y:-118.4035},
{x:42.4299,y:-83.7702},
{x:35.7771,y:-78.6396},
{x:46.8605,y:-96.8189},
{x:40.8234,y:-76.8452},
{x:25.8642,y:-80.3858},
{x:41.5985,y:-93.5992},
{x:45.4733,y:-98.4585},
{x:45.5377,y:-100.4375},
{x:33.0093,y:-96.5736},
{x:33.4369,y:-86.0911},
{x:40.7158,y:-73.8335},
{x:39.3648,y:-101.0329},
{x:30.1399,y:-94.4107},
{x:38.9944,y:-94.4725},
{x:25.7333,y:-80.3032},
{x:25.8818,y:-80.2925},
{x:35.9863,y:-96.1344},
{x:43.1743,y:-124.1883},
{x:27.9139,y:-82.3486},
{x:44.7735,y:-93.2772},
{x:37.7282,y:-84.3038},
{x:34.6763,y:-77.3535},
{x:42.9328,y:-72.2784},
{x:48.3704,y:-114.1918},
{x:40.6508,y:-73.9494},
{x:33.6784,y:-88.324},
{x:32.2753,y:-95.3097},
{x:38.4881,y:-82.6894},
{x:25.094,y:-80.441},
{x:40.2862,y:-74.1746},
{x:35.9628,y:-77.851},
{x:34.779,y:-84.7693},
{x:39.6049,y:-77.8096},
{x:39.3636,y:-76.7846},
{x:37.0241,y:-76.3329},
{x:41.6604,y:-88.0013},
{x:46.8428,y:-96.7994},
{x:36.8146,y:-76.1088},
{x:46.8756,y:-96.8455},
{x:33.5448,y:-84.2276},
{x:39.1264,y:-76.5871},
{x:45.639,y:-122.5995},
{x:37.4629,y:-121.1422},
{x:36.3549,y:-92.3385},
{x:34.1376,y:-117.1969},
{x:37.4979,y:-91.8622},
{x:29.7373,y:-95.583},
{x:29.8104,y:-94.9833},
{x:34.4257,y:-118.4255},
{x:36.4147,y:-89.0565},
{x:45.3415,y:-118.0458},
{x:43.8172,y:-91.2393},
{x:41.2534,y:-96.0269},
{x:41.5375,y:-90.6803},
{x:39.4841,y:-105.0751},
{x:33.2401,y:-87.5754},
{x:37.4521,y:-121.9098},
{x:43.7481,y:-93.7283},
{x:40.3321,y:-76.7925},
{x:39.1465,y:-76.8078},
{x:32.9805,y:-96.7676},
{x:44.8388,y:-73.0826},
{x:35.4066,y:-78.7366},
{x:30.2983,y:-85.8489},
{x:36.5659,y:-95.2214},
{x:37.1963,y:-87.6881},
{x:38.8583,y:-77.0498},
{x:38.6741,y:-90.3879},
{x:33.6222,y:-84.5027},
{x:31.5947,y:-84.1557},
{x:32.5397,y:-84.8681},
{x:41.148,y:-73.2567},
{x:43.0881,y:-78.9817},
{x:29.5501,y:-95.0902},
{x:29.8031,y:-95.2185},
{x:29.7003,y:-95.813},
{x:38.61,y:-121.3681},
{x:40.7117,y:-73.9407},
{x:38.7534,y:-104.788},
{x:38.6203,y:-121.2688},
{x:27.5303,y:-99.5047},
{x:31.1436,y:-97.804},
{x:31.5678,y:-110.349},
{x:31.3434,y:-85.7151},
{x:41.8091,y:-72.2602},
{x:34.6561,y:-93.0574},
{x:36.6244,y:-119.3116},
{x:35.7205,y:-81.1548},
{x:42.8169,y:-72.0167},
{x:42.005,y:-88.0064},
{x:37.7028,y:-121.7217},
{x:31.3373,y:-109.5594},
{x:39.8646,y:-104.9974},
{x:36.9846,y:-85.9316},
{x:41.7325,y:-87.7982},
{x:31.3662,y:-96.1416},
{x:30.039,y:-94.1611},
{x:41.6387,y:-70.9275},
{x:29.4003,y:-95.2434},
{x:29.7113,y:-95.3945},
{x:29.9158,y:-95.6307},
{x:29.9367,y:-95.2681},
{x:29.9965,y:-90.4125},
{x:42.107,y:-87.892},
{x:30.4711,y:-90.4142},
{x:29.6799,y:-95.4241},
{x:29.6824,y:-95.3922},
{x:33.8579,y:-98.5213},
{x:30.507,y:-99.7764},
{x:42.0744,y:-72.0313},
{x:41.8854,y:-87.6183},
{x:40.7558,y:-73.9231},
{x:40.9142,y:-73.8583},
{x:33.4231,y:-111.8038},
{x:40.6502,y:-73.891},
{x:36.9886,y:-78.2227},
{x:40.3107,y:-89.1687},
{x:30.2545,y:-97.6082},
{x:29.5248,y:-98.7177},
{x:40.6198,y:-74.0078},
{x:33.7752,y:-118.3772},
{x:40.7435,y:-73.9146},
{x:38.676,y:-90.2502},
{x:38.9846,y:-76.9495},
{x:41.94,y:-88.148},
{x:40.6989,y:-74.2319},
{x:43.6507,y:-93.3602},
{x:39.8693,y:-84.1038},
{x:41.0388,y:-86.029},
{x:33.6819,y:-80.1658},
{x:34.7528,y:-79.4764},
{x:44.948,y:-93.0964},
{x:33.0983,y:-83.2452},
{x:41.6622,y:-70.9412},
{x:39.3887,y:-76.6108},
{x:42.1118,y:-83.2835},
{x:48.3083,y:-102.3807},
{x:40.6741,y:-73.4904},
{x:41.57,y:-75.5084},
{x:40.6446,y:-88.8044},
{x:42.56,y:-71.5903},
{x:40.7555,y:-73.9412},
{x:41.1854,y:-88.3182},
{x:34.1712,y:-118.2893},
{x:37.1741,y:-84.6256},
{x:34.2152,y:-119.0309},
{x:35.1091,y:-106.5167},
{x:34.0473,y:-118.2603},
{x:40.5919,y:-74.0675},
{x:30.1663,y:-90.92},
{x:44.06,y:-92.496},
{x:40.0417,y:-76.3063},
{x:27.915,y:-82.7277},
{x:40.3387,y:-79.8101},
{x:39.4329,y:-83.8324},
{x:25.9149,y:-97.4453},
{x:40.7062,y:-73.9503},
{x:30.1153,y:-95.3805},
{x:37.4928,y:-120.875},
{x:38.6861,y:-121.3391},
{x:40.6524,y:-112.2906},
{x:38.6552,y:-121.0696},
{x:36.075,y:-120.1033},
{x:41.6139,y:-93.7683},
{x:42.1641,y:-84.2534},
{x:39.6241,y:-105.109},
{x:34.0119,y:-118.4948},
{x:38.0362,y:-87.5392},
{x:40.1389,y:-76.5837},
{x:41.072,y:-85.8922},
{x:43.0595,y:-78.841},
{x:41.6772,y:-94.5038},
{x:47.6447,y:-122.0252},
{x:33.4809,y:-86.788},
{x:39.1543,y:-76.7425},
{x:41.552,y:-85.8159},
{x:29.9484,y:-90.2135},
{x:18.3944,y:-66.0342},
{x:18.2119,y:-66.0573},
{x:18.4391,y:-66.4567},
{x:43.5929,y:-73.0447},
{x:42.4346,y:-96.3796},
{x:35.8722,y:-84.323},
{x:29.9093,y:-95.5854},
{x:28.8305,y:-97.0017},
{x:34.2227,y:-84.5055},
{x:34.0275,y:-84.5652},
{x:39.6495,y:-82.744},
{x:29.7845,y:-95.7924},
{x:29.7558,y:-95.8517},
{x:47.8133,y:-117.5721},
{x:37.7303,y:-122.4763},
{x:30.0962,y:-90.9834},
{x:47.6619,y:-122.3686},
{x:41.5626,y:-90.5754},
{x:42.427,y:-71.0109},
{x:41.6036,y:-80.3051},
{x:45.2338,y:-122.81},
{x:42.4905,y:-71.2751},
{x:38.0667,y:-84.9211},
{x:41.7539,y:-71.877},
{x:41.7542,y:-71.8784},
{x:46.4127,y:-117.0005},
{x:34.132,y:-78.0974},
{x:26.1338,y:-80.2836},
{x:40.0198,y:-76.1817},
{x:29.1687,y:-95.4184},
{x:36.5833,y:-89.9707},
{x:26.0198,y:-80.1154},
{x:29.2798,y:-98.6686},
{x:42.3814,y:-88.0591},
{x:45.3112,y:-97.0462},
{x:38.6156,y:-90.2213},
{x:40.793,y:-89.6066},
{x:40.7471,y:-73.7124},
{x:32.9232,y:-96.7364},
{x:45.3712,y:-84.9713},
{x:41.6512,y:-86.1418},
{x:28.2468,y:-81.2444},
{x:42.1614,y:-88.2762},
{x:33.3059,y:-83.6959},
{x:29.6546,y:-95.2523},
{x:29.8497,y:-95.6054},
{x:35.4644,y:-97.3719},
{x:40.9679,y:-76.607},
{x:40.3019,y:-79.4942},
{x:40.8082,y:-77.8577},
{x:32.6948,y:-115.4976},
{x:37.0959,y:-86.0532},
{x:47.6348,y:-122.2014},
{x:41.684,y:-87.7038},
{x:37.3157,y:-89.5307},
{x:31.9889,y:-102.1313},
{x:27.9961,y:-82.4388},
{x:39.4059,y:-84.6103},
{x:36.8865,y:-76.3066},
{x:39.3338,y:-83.0048},
{x:42.6552,y:-77.057},
{x:42.9236,y:-78.6226},
{x:36.3721,y:-94.2209},
{x:40.8818,y:-73.2204},
{x:40.5342,y:-80.0672},
{x:30.1096,y:-92.0217},
{x:42.0285,y:-70.9585},
{x:36.8048,y:-89.9852},
{x:28.4418,y:-99.7601},
{x:42.3399,y:-88.0031},
{x:39.0504,y:-94.4154},
{x:38.5407,y:-90.9947},
{x:35.7238,y:-86.3185},
{x:39.6215,y:-82.9406},
{x:32.8525,y:-96.7692},
{x:42.239,y:-87.945},
{x:48.6768,y:-116.3321},
{x:43.2366,y:-77.0637},
{x:30.5483,y:-89.6767},
{x:43.4669,y:-85.9669},
{x:33.4947,y:-88.4191},
{x:26.0653,y:-80.2523},
{x:41.0234,y:-89.371},
{x:60.9407,y:-149.1718},
{x:44.9221,y:-73.1102},
{x:41.8095,y:-87.9838},
{x:33.8868,y:-117.2251},
{x:32.6961,y:-103.179},
{x:34.2223,y:-84.154},
{x:32.7368,y:-101.9504},
{x:33.5859,y:-101.9228},
{x:39.1741,y:-120.1369},
{x:32.0286,y:-81.0927},
{x:35.5912,y:-84.4759},
{x:34.272,y:-119.2588},
{x:29.9279,y:-94.249},
{x:43.233,y:-70.8086},
{x:39.8569,y:-84.3304},
{x:43.5809,y:-116.2431},
{x:29.9847,y:-91.8567},
{x:30.2634,y:-98.8791},
{x:36.7063,y:-121.6535},
{x:33.7454,y:-84.409},
{x:41.9587,y:-78.1881},
{x:48.3933,y:-114.3366},
{x:40.8143,y:-81.3951},
{x:33.2929,y:-93.2325},
{x:40.7576,y:-86.3212},
{x:36.6458,y:-121.8091},
{x:41.7041,y:-83.5267},
{x:38.0987,y:-92.8213},
{x:47.3633,y:-122.0232},
{x:47.6577,y:-117.4226},
{x:40.263,y:-80.1351},
{x:29.7083,y:-82.3549},
{x:37.9223,y:-122.3158},
{x:47.0608,y:-122.7412},
{x:38.2424,y:-121.99},
{x:43.159,y:-78.6964},
{x:30.1359,y:-92.0069},
{x:38.5062,y:-90.6581},
{x:42.8022,y:-83.0036},
{x:33.4552,y:-88.791},
{x:29.9452,y:-90.1129},
{x:33.8418,y:-118.2849},
{x:47.2154,y:-123.0992},
{x:36.4316,y:-88.1158},
{x:35.6491,y:-78.3937},
{x:33.8937,y:-118.2872},
{x:37.4987,y:-77.5274},
{x:44.3568,y:-89.1106},
{x:40.825,y:-73.9403},
{x:42.9557,y:-85.6662},
{x:40.1101,y:-82.9502},
{x:43.6655,y:-116.4123},
{x:42.5391,y:-71.1391},
{x:40.6288,y:-74.0226},
{x:44.0798,y:-111.455},
{x:34.8509,y:-114.6241},
{x:39.9017,y:-84.189},
{x:42.1684,y:-72.4821},
{x:42.2333,y:-72.0137},
{x:43.8049,y:-111.8118},
{x:42.4235,y:-83.4679},
{x:39.3559,y:-84.1404},
{x:43.8327,y:-111.7784},
{x:18.3426,y:-66.196},
{x:40.812,y:-79.5486},
{x:29.798,y:-95.3684},
{x:32.1855,y:-110.9533},
{x:45.758,y:-108.5785},
{x:45.829,y:-108.4672},
{x:38.3676,y:-81.6671},
{x:38.453,y:-85.674},
{x:39.3179,y:-76.6155},
{x:31.5252,y:-97.145},
{x:25.6157,y:-80.3101},
{x:29.913,y:-90.0179},
{x:31.8026,y:-85.9665},
{x:38.3399,y:-81.6163},
{x:38.8871,y:-77.0041},
{x:38.8944,y:-77.0495},
{x:29.4807,y:-95.2466},
{x:34.7507,y:-86.7732},
{x:39.6038,y:-87.6865},
{x:39.2482,y:-94.6529},
{x:21.3905,y:-157.7398},
{x:34.0385,y:-117.3676},
{x:32.7827,y:-79.9511},
{x:36.2149,y:-115.2447},
{x:35.9626,y:-79.0537},
{x:44.5016,y:-89.5645},
{x:36.0617,y:-79.1058},
{x:38.7581,y:-78.6286},
{x:43.1264,y:-88.4624},
{x:40.0591,y:-82.4496},
{x:42.9633,y:-71.4448},
{x:31.8369,y:-81.5965},
{x:32.8266,y:-97.1986},
{x:28.8914,y:-82.4893},
{x:29.0629,y:-82.4556},
{x:41.9048,y:-84.034},
{x:32.0576,y:-81.1214},
{x:19.6173,y:-155.0362},
{x:33.8537,y:-117.815},
{x:38.7183,y:-90.6729},
{x:41.2186,y:-73.2556},
{x:36.1426,y:-83.2768},
{x:40.6124,y:-73.9832},
{x:40.033,y:-79.8944},
{x:28.4851,y:-98.1427},
{x:33.7463,y:-86.8128},
{x:37.3769,y:-118.4219},
{x:33.6843,y:-112.1091},
{x:38.536,y:-89.1311},
{x:41.9181,y:-87.6724},
{x:41.9794,y:-87.6925},
{x:39.4076,y:-77.4293},
{x:36.1312,y:-85.479},
{x:35.0062,y:-80.5625},
{x:38.0286,y:-121.9601},
{x:31.1237,y:-88.0096},
{x:35.8903,y:-90.3397},
{x:44.7815,y:-88.5821},
{x:38.8748,y:-75.8221},
{x:39.9277,y:-91.4446},
{x:32.269,y:-81.0805},
{x:42.4711,y:-93.5736},
{x:39.6135,y:-104.7579},
{x:42.004,y:-88.12},
{x:31.8563,y:-102.4102},
{x:38.4434,y:-90.4003},
{x:33.2277,y:-96.9842},
{x:32.7111,y:-97.2828},
{x:32.7127,y:-97.2838},
{x:47.7337,y:-122.3131},
{x:34.7862,y:-78.394},
{x:34.5512,y:-78.784},
{x:32.1526,y:-94.7849},
{x:39.9709,y:-76.2738},
{x:31.8427,y:-102.3895},
{x:38.9555,y:-92.372},
{x:30.0126,y:-97.8599},
{x:33.3932,y:-111.8395},
{x:39.9348,y:-91.3427},
{x:40.7187,y:-73.5641},
{x:44.8707,y:-88.6192},
{x:35.4691,y:-94.3538},
{x:32.7602,y:-94.3553},
{x:30.4129,y:-88.7853},
{x:35.1777,y:-101.8162},
{x:45.1779,y:-93.2395},
{x:41.9248,y:-87.6874},
{x:30.8661,y:-93.288},
{x:31.9107,y:-81.2793},
{x:36.3902,y:-97.9469},
{x:37.6048,y:-77.353},
{x:42.1259,y:-89.5794},
{x:39.987,y:-104.8048},
{x:39.8529,y:-84.793},
{x:28.8157,y:-96.9602},
{x:31.8411,y:-89.7137},
{x:38.5684,y:-90.4748},
{x:38.5883,y:-90.3097},
{x:31.93,y:-106.4235},
{x:36.1586,y:-115.3176},
{x:36.092,y:-115.1732},
{x:28.5827,y:-80.6528},
{x:44.874,y:-93.2271},
{x:38.4947,y:-90.2964},
{x:41.9469,y:-111.3939},
{x:33.5748,y:-85.0851},
{x:26.2862,y:-98.3115},
{x:38.9478,y:-92.3274},
{x:33.8046,y:-118.1883},
{x:41.0054,y:-81.6382},
{x:44.922,y:-91.3214},
{x:41.4557,y:-81.7866},
{x:33.3923,y:-111.9949},
{x:33.4366,y:-112.0495},
{x:45.2185,y:-93.3581},
{x:30.1935,y:-81.795},
{x:41.2736,y:-81.836},
{x:40.5627,y:-84.1737},
{x:45.1976,y:-93.3879},
{x:34.0321,y:-118.3511},
{x:41.7652,y:-87.6237},
{x:29.723,y:-95.4654},
{x:35.0583,y:-106.5596},
{x:34.9672,y:-80.0602},
{x:39.1434,y:-94.719},
{x:40.1876,y:-76.186},
{x:29.6948,y:-95.4921},
{x:42.2908,y:-75.4736},
{x:34.1355,y:-117.5564},
{x:29.9264,y:-95.5963},
{x:40.2737,y:-111.7257},
{x:40.0788,y:-82.2742},
{x:43.6189,y:-116.2683},
{x:32.9578,y:-96.7686},
{x:40.326,y:-75.9505},
{x:30.4786,y:-87.253},
{x:25.9937,y:-80.3113},
{x:41.8302,y:-88.2019},
{x:42.5009,y:-94.165},
{x:30.3084,y:-81.6895},
{x:32.3579,y:-111.0449},
{x:33.5338,y:-112.2669},
{x:25.7646,y:-80.2745},
{x:39.1482,y:-88.5323},
{x:41.279,y:-95.8692},
{x:45.6381,y:-89.4128},
{x:33.4826,y:-82.0904},
{x:33.4138,y:-82.312},
{x:44.645,y:-72.8336},
{x:42.2562,y:-72.2517},
{x:35.9261,y:-86.4688},
{x:41.2313,y:-73.1526},
{x:43.2408,y:-77.6949},
{x:34.8062,y:-85.0312},
{x:39.3097,y:-76.6245},
{x:42.6095,y:-83.0521},
{x:29.2191,y:-98.4135},
{x:34.559,y:-118.0869},
{x:41.0323,y:-81.2489},
{x:42.036,y:-91.5743},
{x:42.9568,y:-88.1075},
{x:41.6756,y:-91.5669},
{x:43.1253,y:-89.3055},
{x:30.4416,y:-97.7424},
{x:35.5953,y:-82.5322},
{x:25.7702,y:-80.306},
{x:38.5483,y:-90.2911},
{x:37.9849,y:-84.5314},
{x:32.8113,y:-97.4237},
{x:44.1761,y:-88.4844},
{x:40.5456,y:-74.5868},
{x:44.1462,y:-93.9888},
{x:43.7522,y:-88.4511},
{x:26.2926,y:-98.3575},
{x:29.4108,y:-98.4332},
{x:33.0403,y:-83.9412},
{x:25.8366,y:-80.274},
{x:26.1689,y:-80.1161},
{x:37.2952,y:-82.3633},
{x:42.0779,y:-87.9805},
{x:35.7865,y:-78.663},
{x:40.3744,y:-74.319},
{x:40.6743,y:-111.8886},
{x:34.5962,y:-120.1403},
{x:29.4412,y:-95.0915},
{x:31.8306,y:-83.9269},
{x:35.6858,y:-80.4831},
{x:34.3331,y:-97.1547},
{x:40.744,y:-73.8847},
{x:41.2389,y:-96.0316},
{x:37.1395,y:-84.1002},
{x:35.7854,y:-78.8711},
{x:39.4518,y:-81.4577},
{x:36.1171,y:-115.192},
{x:32.7337,y:-97.3312},
{x:42.4612,y:-83.0403},
{x:43.5451,y:-116.812},
{x:29.8663,y:-95.4997},
{x:30.8939,y:-102.8846},
{x:36.8479,y:-84.8368},
{x:38.3724,y:-85.443},
{x:34.0127,y:-118.4831},
{x:43.4045,y:-76.1193},
{x:25.9432,y:-80.2135},
{x:32.7746,y:-117.0713},
{x:32.877,y:-117.1402},
{x:32.8756,y:-117.1366},
{x:40.892,y:-74.6858},
{x:42.7191,y:-87.795},
{x:41.6803,y:-86.8971},
{x:41.7852,y:-72.457},
{x:40.4914,y:-87.0779},
{x:38.6271,y:-90.2651},
{x:41.4857,y:-112.0313},
{x:28.9119,y:-81.2792},
{x:41.9306,y:-72.6044},
{x:41.2243,y:-89.9269},
{x:33.7309,y:-84.7363},
{x:48.7701,y:-122.4493},
{x:40.0589,y:-81.0523},
{x:44.5477,y:-69.7185},
{x:41.1368,y:-81.7009},
{x:33.4948,y:-97.2848},
{x:30.475,y:-90.5418},
{x:34.0436,y:-118.2618},
{x:32.769,y:-97.0966},
{x:27.7982,y:-97.4688},
{x:26.3385,y:-98.1687},
{x:29.4963,y:-98.3246},
{x:26.2212,y:-98.1496},
{x:26.2737,y:-98.1542},
{x:42.3664,y:-71.1817},
{x:42.05,y:-91.6003},
{x:41.116,y:-83.1852},
{x:18.4132,y:-66.1248},
{x:28.4956,y:-80.5782},
{x:30.4661,y:-86.5294},
{x:29.4663,y:-98.4495},
{x:33.0883,y:-96.6184},
{x:38.3108,y:-122.2859},
{x:32.6358,y:-97.1149},
{x:32.7681,y:-115.5346},
{x:42.1569,y:-72.4835},
{x:38.88,y:-77.1728},
{x:42.9766,y:-82.7634},
{x:32.3979,y:-93.7585},
{x:37.62,y:-79.0154},
{x:35.5807,y:-77.306},
{x:34.1237,y:-83.9852},
{x:41.9181,y:-83.3952},
{x:33.8583,y:-118.0266},
{x:39.7233,y:-90.2413},
{x:29.6777,y:-95.2898},
{x:29.7568,y:-95.3686},
{x:42.4604,y:-84.084},
{x:43.0234,y:-83.5951},
{x:18.4236,y:-66.0455},
{x:26.7133,y:-81.8143},
{x:35.0376,y:-85.5815},
{x:40.4657,y:-93.9792},
{x:40.2259,y:-76.7205},
{x:34.0216,y:-84.5281},
{x:25.779,y:-80.1332},
{x:29.664,y:-91.1159},
{x:29.8009,y:-95.7177},
{x:42.4693,y:-83.4261},
{x:40.0451,y:-86.8838},
{x:37.5625,y:-77.4705},
{x:32.3085,y:-89.6675},
{x:29.866,y:-95.6578},
{x:42.8851,y:-85.7438},
{x:34.1462,y:-118.116},
{x:30.3178,y:-95.5481},
{x:36.1152,y:-115.1181},
{x:41.5586,y:-89.1282},
{x:43.166,y:-89.0617},
{x:37.696,y:-122.0813},
{x:30.0293,y:-90.2395},
{x:40.8277,y:-91.1413},
{x:33.8157,y:-118.3515},
{x:41.143,y:-88.8366},
{x:41.3275,y:-89.2093},
{x:29.6959,y:-97.1057},
{x:35.2216,y:-89.978},
{x:38.8518,y:-104.8696},
{x:29.775,y:-95.6446},
{x:33.9821,y:-118.2499},
{x:41.7963,y:-71.9206},
{x:34.0121,y:-118.2818},
{x:40.3763,y:-82.8289},
{x:42.4966,y:-89.0373},
{x:42.1876,y:-87.8012},
{x:29.7042,y:-95.6404},
{x:40.019,y:-76.7469},
{x:32.3115,y:-106.816},
{x:25.9614,y:-80.1871},
{x:28.2295,y:-80.6969},
{x:44.3725,y:-71.6119},
{x:32.7922,y:-96.7487},
{x:18.4046,y:-66.0278},
{x:41.1483,y:-87.8752},
{x:17.9779,y:-66.6101},
{x:39.0516,y:-94.793},
{x:32.3051,y:-111.0119},
{x:39.1612,y:-78.1667},
{x:26.1831,y:-98.0386},
{x:42.378,y:-87.935},
{x:35.1421,y:-118.4496},
{x:42.3913,y:-71.0912},
{x:38.8257,y:-90.8634},
{x:43.6526,y:-90.8583},
{x:37.3972,y:-122.0612},
{x:47.24,y:-122.4786},
{x:29.5589,y:-98.6815},
{x:42.425,y:-71.0673},
{x:37.3899,y:-122.0423},
{x:39.2352,y:-82.1941},
{x:27.4442,y:-82.5071},
{x:43.8902,y:-91.0754},
{x:40.8753,y:-124.0837},
{x:32.7193,y:-96.8774},
{x:29.5006,y:-82.8719},
{x:30.7596,y:-81.6501},
{x:41.3923,y:-88.4186},
{x:17.9731,y:-66.0551},
{x:33.9771,y:-84.1619},
{x:35.6825,y:-97.406},
{x:44.0688,y:-123.1041},
{x:39.5573,y:-81.0041},
{x:37.0009,y:-91.3276},
{x:32.5797,y:-97.2504},
{x:40.436,y:-75.4084},
{x:38.7863,y:-90.7361},
{x:38.5136,y:-77.856},
{x:44.2558,y:-121.1823},
{x:41.1129,y:-111.9841},
{x:30.4177,y:-86.6813},
{x:42.2102,y:-121.7538},
{x:35.359,y:-86.2018},
{x:27.601,y:-80.8222},
{x:43.1353,y:-70.9281},
{x:26.3025,y:-80.1531},
{x:31.7841,y:-106.4242},
{x:26.2949,y:-80.1366},
{x:39.9085,y:-86.194},
{x:32.1259,y:-110.8743},
{x:30.0501,y:-91.932},
{x:39.2759,y:-94.4257},
{x:39.1457,y:-94.5582},
{x:36.8523,y:-86.8931},
{x:41.0461,y:-95.8274},
{x:38.5752,y:-121.506},
{x:40.3145,y:-76.8556},
{x:30.5316,y:-87.7097},
{x:36.5368,y:-87.3671},
{x:41.8925,y:-91.6747},
{x:38.8428,y:-82.2541},
{x:34.2434,y:-88.7175},
{x:32.2774,y:-80.8763},
{x:29.6757,y:-95.5496},
{x:30.4589,y:-90.9171},
{x:18.4125,y:-66.0972},
{x:18.3745,y:-65.81},
{x:29.5211,y:-95.1609},
{x:46.8428,y:-100.9065},
{x:42.6288,y:-88.613},
{x:33.4898,y:-97.1634},
{x:42.6901,y:-71.1742},
{x:40.7139,y:-73.9014},
{x:43.1515,y:-76.3515},
{x:38.3918,y:-121.4155},
{x:36.8641,y:-83.1951},
{x:44.9382,y:-123.0631},
{x:42.4537,y:-75.062},
{x:43.4757,y:-96.7663},
{x:29.4788,y:-98.3598},
{x:44.0919,y:-69.138},
{x:26.4408,y:-80.1215},
{x:31.0719,y:-97.3691},
{x:31.9544,y:-99.9629},
{x:35.2721,y:-80.9616},
{x:32.4981,y:-94.3567},
{x:37.166,y:-76.5422},
{x:43.9534,y:-89.8177},
{x:35.5507,y:-87.5415},
{x:32.8347,y:-97.1619},
{x:32.9637,y:-97.2482},
{x:40.7658,y:-86.7752},
{x:33.2203,y:-96.9116},
{x:41.3632,y:-83.6501},
{x:38.7816,y:-121.2416},
{x:28.8633,y:-82.0154},
{x:28.7973,y:-81.8873},
{x:33.7243,y:-117.8351},
{x:41.4056,y:-88.1054},
{x:42.8275,y:-73.967},
{x:40.8843,y:-73.9006},
{x:38.4583,y:-122.7168},
{x:42.846,y:-106.2522},
{x:32.6952,y:-97.0532},
{x:30.2192,y:-85.8658},
{x:41.0805,y:-73.4612},
{x:41.0681,y:-73.5043},
{x:41.2853,y:-72.834},
{x:41.2867,y:-72.8337},
{x:35.1242,y:-78.9946},
{x:40.1783,y:-77.3893},
{x:43.234,y:-86.2007},
{x:39.4416,y:-77.438},
{x:40.2043,y:-79.4969},
{x:47.8502,y:-122.2506},
{x:41.9931,y:-76.5293},
{x:34.0482,y:-118.4631},
{x:29.8085,y:-95.3177},
{x:44.8284,y:-68.7555},
{x:39.4886,y:-86.0647},
{x:35.4431,y:-80.8601},
{x:48.1838,y:-114.306},
{x:29.8467,y:-95.3929},
{x:29.7917,y:-95.3744},
{x:28.4677,y:-80.7998},
{x:34.006,y:-117.3998},
{x:33.5819,y:-112.1843},
{x:32.2596,y:-110.7981},
{x:26.1143,y:-80.1416},
{x:41.0746,y:-85.0966},
{x:32.1917,y:-110.7912},
{x:32.9684,y:-97.4145},
{x:42.9268,y:-87.8602},
{x:41.8219,y:-70.5508},
{x:34.5337,y:-78.0712},
{x:38.4006,y:-96.5337},
{x:37.5562,y:-88.9726},
{x:45.3109,y:-93.5573},
{x:35.0112,y:-106.0445},
{x:41.8032,y:-87.8895},
{x:41.4134,y:-85.7459},
{x:28.3688,y:-81.5024},
{x:44.0353,y:-89.522},
{x:33.073,y:-83.2242},
{x:34.4474,y:-86.757},
{x:47.4649,y:-122.1364},
{x:38.328,y:-77.0408},
{x:39.5772,y:-77.8776},
{x:28.9482,y:-81.2429},
{x:28.8336,y:-97.8671},
{x:34.6959,y:-86.632},
{x:32.8939,y:-80.0697},
{x:27.9807,y:-82.3391},
{x:28.5241,y:-80.6508},
{x:38.2504,y:-85.7428},
{x:30.3249,y:-95.4945},
{x:34.1384,y:-117.4179},
{x:37.0144,y:-121.5882},
{x:38.5289,y:-121.3356},
{x:28.8118,y:-81.3429},
{x:43.2506,y:-83.7714},
{x:38.3773,y:-75.0685},
{x:48.1883,y:-122.2002},
{x:34.6367,y:-78.9708},
{x:39.9212,y:-80.7484},
{x:33.5114,y:-84.222},
{x:41.8091,y:-83.4491},
{x:32.9701,y:-96.6031},
{x:33.2872,y:-96.7825},
{x:18.0442,y:-66.9499},
{x:18.4102,y:-67.1532},
{x:40.6932,y:-73.8527},
{x:26.8211,y:-80.0828},
{x:18.3412,y:-65.9967},
{x:39.3002,y:-76.9},
{x:33.8246,y:-84.1257},
{x:38.6622,y:-94.3668},
{x:46.8017,y:-100.7882},
{x:46.8297,y:-100.8183},
{x:30.3917,y:-88.6558},
{x:38.0177,y:-121.9429},
{x:33.5673,y:-84.5813},
{x:34.7268,y:-82.2537},
{x:32.7605,y:-96.8265},
{x:46.7502,y:-88.4438},
{x:44.4509,y:-95.7723},
{x:29.3387,y:-98.5555},
{x:39.2845,y:-80.9759},
{x:33.7994,y:-84.1948},
{x:29.7096,y:-95.3975},
{x:47.299,y:-122.2457},
{x:41.7188,y:-83.5784},
{x:28.4185,y:-99.25},
{x:33.7754,y:-118.1762},
{x:29.7268,y:-95.4299},
{x:30.1136,y:-95.5534},
{x:41.3215,y:-96.0219},
{x:29.6643,y:-95.2211},
{x:43.1264,y:-115.6978},
{x:37.0548,y:-94.4817},
{x:47.5417,y:-122.2259},
{x:30.4027,y:-86.8607},
{x:25.7407,y:-80.2541},
{x:42.0488,y:-88.1008},
{x:33.9545,y:-118.1878},
{x:41.1762,y:-112.0063},
{x:37.6288,y:-121.0111},
{x:29.8743,y:-90.0899},
{x:33.47,y:-82.0143},
{x:42.4119,y:-71.2333},
{x:43.0348,y:-78.7645},
{x:42.1711,y:-87.95},
{x:35.8156,y:-77.0654},
{x:33.8457,y:-118.063},
{x:41.1245,y:-100.7637},
{x:35.828,y:-84.2778},
{x:40.6668,y:-73.7581},
{x:39.768,y:-101.8119},
{x:39.7481,y:-104.9922},
{x:31.3996,y:-103.4795},
{x:43.7488,y:-88.4647},
{x:39.6725,y:-78.7178},
{x:36.7011,y:-101.4857},
{x:39.613,y:-104.8132},
{x:35.8917,y:-78.6002},
{x:32.8821,y:-83.7601},
{x:41.9925,y:-87.8635},
{x:42.3683,y:-72.637},
{x:40.7144,y:-73.3431},
{x:33.7407,y:-88.4381},
{x:33.759,y:-118.1311},
{x:37.9679,y:-122.522},
{x:30.6534,y:-88.4828},
{x:27.6123,y:-99.4668},
{x:43.7155,y:-87.7589},
{x:30.1434,y:-95.548},
{x:37.8307,y:-122.28},
{x:29.3556,y:-98.4802},
{x:43.1732,y:-88.1104},
{x:34.0668,y:-118.4461},
{x:43.4274,y:-88.1966},
{x:43.0352,y:-88.0075},
{x:40.8999,y:-78.2261},
{x:36.8522,y:-119.9516},
{x:28.3613,y:-82.6888},
{x:37.0917,y:-89.5448},
{x:35.9611,y:-87.1364},
{x:29.118,y:-82.0245},
{x:41.5784,y:-87.7841},
{x:27.8718,y:-82.7449},
{x:32.7705,y:-97.4038},
{x:33.8954,y:-84.1945},
{x:33.8232,y:-84.2328},
{x:43.0582,y:-74.3246},
{x:39.1003,y:-94.4125},
{x:26.7241,y:-80.0878},
{x:39.1157,y:-94.6277},
{x:30.1986,y:-93.2052},
{x:26.6991,y:-81.7614},
{x:33.5765,y:-86.4779},
{x:20.8824,y:-156.4542},
{x:37.3654,y:-121.8506},
{x:30.3681,y:-97.6119},
{x:39.2294,y:-77.2498},
{x:37.9793,y:-84.549},
{x:33.3071,y:-111.6794},
{x:29.5189,y:-97.4512},
{x:33.9205,y:-116.9777},
{x:45.4424,y:-122.7789},
{x:39.8483,y:-82.7772},
{x:30.8794,y:-87.7827},
{x:41.9742,y:-88.1511},
{x:30.7283,y:-88.1733},
{x:18.3305,y:-65.6621},
{x:39.4058,y:-76.781},
{x:39.5157,y:-89.0431},
{x:36.9835,y:-76.434},
{x:42.0486,y:-80.0811},
{x:32.5553,y:-93.6122},
{x:30.1142,y:-81.8125},
{x:41.4434,y:-85.9863},
{x:36.0181,y:-95.8679},
{x:40.8238,y:-73.8381},
{x:36.4249,y:-82.3185},
{x:41.1599,y:-80.7025},
{x:61.1396,y:-149.835},
{x:33.6014,y:-112.2876},
{x:43.8388,y:-101.5242},
{x:29.4848,y:-98.6665},
{x:43.5892,y:-116.4029},
{x:42.7247,y:-78.8255},
{x:30.2688,y:-93.1797},
{x:41.4569,y:-82.7102},
{x:29.4614,y:-98.4341},
{x:39.0078,y:-104.8862},
{x:34.6473,y:-86.6465},
{x:38.2477,y:-85.5768},
{x:29.7471,y:-95.5599},
{x:40.8309,y:-115.7828},
{x:40.3218,y:-79.8345},
{x:34.1461,y:-118.1333},
{x:37.4348,y:-121.9023},
{x:34.1694,y:-118.1146},
{x:41.4728,y:-81.1454},
{x:34.1382,y:-117.5743},
{x:27.4481,y:-80.3237},
{x:64.8316,y:-147.6823},
{x:43.5802,y:-96.7313},
{x:34.9743,y:-120.4357},
{x:25.7965,y:-80.2756},
{x:30.9847,y:-97.3406},
{x:34.0778,y:-117.6122},
{x:28.3759,y:-80.7397},
{x:32.8621,y:-96.8589},
{x:33.5089,y:-82.0338},
{x:32.5736,y:-93.7303},
{x:39.5994,y:-110.8034},
{x:33.2338,y:-111.7206},
{x:42.8874,y:-112.4511},
{x:41.4733,y:-72.1173},
{x:40.4999,y:-82.8939},
{x:34.1954,y:-83.8577},
{x:41.6678,y:-85.8207},
{x:38.1586,y:-84.6765},
{x:30.1344,y:-96.2615},
{x:33.9983,y:-118.0555},
{x:38.1378,y:-121.2589},
{x:41.7161,y:-71.1537},
{x:29.5864,y:-95.6475},
{x:27.4835,y:-82.4319},
{x:39.278,y:-81.6874},
{x:41.5137,y:-82.9396},
{x:42.5039,y:-71.2304},
{x:47.7752,y:-99.9468},
{x:37.6988,y:-121.8853},
{x:37.9636,y:-121.2724},
{x:30.3466,y:-81.6902},
{x:40.7796,y:-74.016},
{x:27.6126,y:-80.3856},
{x:33.2078,y:-117.2866},
{x:43.4089,y:-83.3835},
{x:33.753,y:-84.474},
{x:33.7236,y:-84.5059},
{x:35.9526,y:-83.199},
{x:33.7801,y:-117.8652},
{x:36.1798,y:-82.8098},
{x:29.7203,y:-95.5395},
{x:40.6379,y:-116.94},
{x:38.5951,y:-121.3834},
{x:47.799,y:-103.286},
{x:33.045,y:-116.8634},
{x:32.422,y:-99.8419},
{x:38.8129,y:-104.6765},
{x:42.1992,y:-97.5291},
{x:41.6661,y:-81.2431},
{x:36.9798,y:-122.0217},
{x:36.5824,y:-121.8293},
{x:39.408,y:-119.2262},
{x:35.5663,y:-77.075},
{x:46.8262,y:-119.1694},
{x:42.0331,y:-91.6785},
{x:33.3582,y:-97.7037},
{x:41.3748,y:-81.5265},
{x:29.6928,y:-95.4396},
{x:41.9865,y:-72.5389},
{x:31.1314,y:-90.1456},
{x:41.0622,y:-86.1832},
{x:35.7271,y:-83.4705},
{x:42.3143,y:-85.6293},
{x:29.7679,y:-95.2351},
{x:34.1357,y:-117.279},
{x:32.4525,y:-99.7716},
{x:37.3167,y:-87.5007},
{x:42.7457,y:-73.6388},
{x:40.0817,y:-79.7604},
{x:48.2244,y:-122.475},
{x:30.4466,y:-88.9342},
{x:37.6081,y:-120.9404},
{x:32.6872,y:-96.8248},
{x:42.4583,y:-123.323},
{x:42.3862,y:-71.0093},
{x:39.9893,y:-85.9212},
{x:47.6096,y:-122.6276},
{x:36.4249,y:-89.6952},
{x:34.1515,y:-118.0018},
{x:33.5096,y:-112.1299},
{x:33.7704,y:-118.1981},
{x:39.3528,y:-76.6616},
{x:39.3101,y:-76.7081},
{x:41.6128,y:-83.704},
{x:33.071,y:-96.8659},
{x:33.1597,y:-96.6376},
{x:25.6871,y:-80.364},
{x:44.9976,y:-91.727},
{x:26.2113,y:-98.1806},
{x:24.9258,y:-80.6259},
{x:43.6347,y:-72.3163},
{x:42.8415,y:-85.6239},
{x:26.0383,y:-80.1619},
{x:28.9817,y:-97.499},
{x:39.8114,y:-94.8062},
{x:39.0834,y:-94.5747},
{x:46.8612,y:-99.9156},
{x:33.7692,y:-118.1935},
{x:34.1834,y:-79.3451},
{x:35.6546,y:-117.6451},
{x:33.7828,y:-118.1899},
{x:31.7766,y:-106.5032},
{x:40.5531,y:-74.1932},
{x:35.8942,y:-119.276},
{x:26.0812,y:-80.2362},
{x:25.848,y:-80.2978},
{x:25.8768,y:-80.321},
{x:33.4813,y:-87.0697},
{x:42.364,y:-88.1701},
{x:42.3869,y:-83.1976},
{x:29.7513,y:-95.4626},
{x:33.456,y:-102.4906},
{x:39.3393,y:-94.2472},
{x:35.0592,y:-119.0292},
{x:40.0509,y:-84.1933},
{x:29.3603,y:-98.6336},
{x:41.1827,y:-73.2557},
{x:41.7445,y:-87.7217},
{x:38.9724,y:-81.9125},
{x:31.7914,y:-81.3981},
{x:36.1087,y:-115.1725},
{x:36.1229,y:-115.1703},
{x:34.5067,y:-117.3995},
{x:39.7001,y:-86.3146},
{x:31.4453,y:-100.4791},
{x:29.1972,y:-81.0567},
{x:32.4985,y:-93.7457},
{x:45.5193,y:-122.4699},
{x:44.6857,y:-90.1948},
{x:44.2988,y:-84.6539},
{x:35.5798,y:-97.5499},
{x:47.2939,y:-122.3821},
{x:31.6178,y:-106.2851},
{x:34.1721,y:-86.8491},
{x:41.7119,y:-70.4893},
{x:32.596,y:-90.0728},
{x:29.9348,y:-90.1209},
{x:38.9298,y:-83.5683},
{x:42.3732,y:-72.5045},
{x:44.3028,y:-120.8444},
{x:42.2104,y:-83.1483},
{x:39.7453,y:-90.2629},
{x:30.3892,y:-86.4502},
{x:38.7686,y:-93.7351},
{x:40.9266,y:-74.0264},
{x:44.6157,y:-69.3198},
{x:36.3202,y:-78.3822},
{x:25.7704,y:-80.3438},
{x:25.8457,y:-80.25},
{x:31.3463,y:-93.8431},
{x:47.2391,y:-122.3659},
{x:37.3141,y:-121.7737},
{x:26.6083,y:-81.6523},
{x:39.2968,y:-80.7769},
{x:33.7964,y:-118.1245},
{x:31.7272,y:-93.161},
{x:30.5622,y:-97.8478},
{x:42.3582,y:-83.072},
{x:37.7803,y:-84.8466},
{x:40.7269,y:-99.0815},
{x:32.5024,y:-84.9403},
{x:42.9732,y:-88.0082},
{x:40.1231,y:-83.0874},
{x:32.7356,y:-96.648},
{x:42.9491,y:-87.9484},
{x:39.8777,y:-75.2428},
{x:29.7085,y:-96.5002},
{x:43.0709,y:-87.8878},
{x:41.6519,y:-88.0811},
{x:43.2382,y:-72.4257},
{x:43.1943,y:-89.341},
{x:43.0239,y:-87.9478},
{x:42.1013,y:-71.0557},
{x:36.8428,y:-76.1426},
{x:41.2906,y:-73.9188},
{x:43.0168,y:-87.9966},
{x:38.936,y:-77.5235},
{x:43.0736,y:-89.3838},
{x:27.7114,y:-97.3231},
{x:33.557,y:-97.0204},
{x:26.072,y:-80.232},
{x:36.7199,y:-108.1822},
{x:38.8766,y:-77.0078},
{x:31.7606,y:-106.4899},
{x:20.8877,y:-156.5007},
{x:40.9343,y:-73.8972},
{x:27.844,y:-97.5846},
{x:35.6461,y:-105.9554},
{x:39.7281,y:-104.991},
{x:36.6645,y:-93.2428},
{x:40.779,y:-85.8197},
{x:29.9529,y:-89.9952},
{x:40.6771,y:-73.4348},
{x:36.2085,y:-95.8739},
{x:37.7104,y:-89.2187},
{x:38.4669,y:-122.7503},
{x:47.8583,y:-121.9675},
{x:39.1056,y:-76.7682},
{x:26.2126,y:-81.7706},
{x:33.6259,y:-78.9678},
{x:39.3814,y:-76.8242},
{x:33.5829,y:-79.0176},
{x:33.5884,y:-79.0114},
{x:44.8968,y:-94.376},
{x:42.3078,y:-85.5589},
{x:39.0546,y:-84.6305},
{x:42.7042,y:-83.2856},
{x:42.1296,y:-70.9496},
{x:33.4244,y:-84.5578},
{x:29.8423,y:-95.5059},
{x:34.1072,y:-117.9736},
{x:34.2799,y:-119.1919},
{x:37.6179,y:-122.0697},
{x:35.1117,y:-101.365},
{x:34.4765,y:-114.3228},
{x:33.5175,y:-86.8089},
{x:38.1486,y:-84.5347},
{x:32.7231,y:-96.9947},
{x:18.1443,y:-66.2495},
{x:39.8765,y:-89.9226},
{x:28.8392,y:-81.8915},
{x:35.7729,y:-86.9194},
{x:38.8728,y:-76.9299},
{x:47.7021,y:-116.8079},
{x:30.7425,y:-95.5498},
{x:32.3333,y:-95.2855},
{x:33.9478,y:-116.9981},
{x:26.9663,y:-80.0869},
{x:38.9293,y:-94.6857},
{x:32.9428,y:-97.1},
{x:40.6947,y:-82.5137},
{x:34.7239,y:-78.191},
{x:42.7733,y:-73.8938},
{x:32.9523,y:-80.1676},
{x:39.6941,y:-90.2289},
{x:33.8679,y:-84.8398},
{x:38.6539,y:-121.3834},
{x:41.503,y:-81.6886},
{x:41.0888,y:-112.0265},
{x:38.726,y:-76.9935},
{x:33.515,y:-81.7335},
{x:29.5577,y:-98.5172},
{x:44.9839,y:-89.6504},
{x:39.6876,y:-77.7286},
{x:37.3812,y:-122.0078},
{x:34.0467,y:-84.1796},
{x:45.1809,y:-93.3176},
{x:41.243,y:-81.8077},
{x:34.8914,y:-116.9992},
{x:35.2184,y:-91.7172},
{x:39.8435,y:-85.5578},
{x:38.8903,y:-89.8352},
{x:36.7565,y:-81.856},
{x:41.3104,y:-82.9753},
{x:36.0526,y:-90.4853},
{x:35.3544,y:-119.333},
{x:36.7719,y:-119.8442},
{x:29.6844,y:-95.2556},
{x:26.1667,y:-98.1892},
{x:42.5299,y:-83.1983},
{x:28.0881,y:-82.4512},
{x:34.0435,y:-118.2837},
{x:38.7736,y:-77.083},
{x:42.8251,y:-71.6206},
{x:43.454,y:-71.577},
{x:29.9762,y:-91.7548},
{x:40.7972,y:-81.5256},
{x:41.3019,y:-95.9916},
{x:28.5513,y:-81.5068},
{x:48.8553,y:-122.4863},
{x:29.5523,y:-98.4082},
{x:34.4301,y:-79.3659},
{x:44.8709,y:-73.0981},
{x:29.3315,y:-98.6252},
{x:33.7826,y:-118.0293},
{x:42.8385,y:-71.4983},
{x:37.1274,y:-93.4632},
{x:40.8624,y:-74.063},
{x:33.9879,y:-118.3645},
{x:32.7359,y:-97.3452},
{x:35.5147,y:-97.6553},
{x:36.5472,y:-82.5167},
{x:41.7433,y:-77.3136},
{x:34.2945,y:-77.8002},
{x:45.2193,y:-93.3227},
{x:39.8864,y:-104.8836},
{x:41.1619,y:-112.0654},
{x:42.9387,y:-71.5251},
{x:30.3122,y:-81.6603},
{x:41.737,y:-87.627},
{x:43.2348,y:-86.1116},
{x:45.8367,y:-119.6995},
{x:35.7204,y:-77.9363},
{x:36.6146,y:-92.1025},
{x:43.1745,y:-85.2773},
{x:40.6491,y:-74.1935},
{x:37.0006,y:-112.9997},
{x:33.8629,y:-84.2878},
{x:38.9002,y:-76.9816},
{x:41.7012,y:-87.592},
{x:41.5386,y:-90.5906},
{x:34.8144,y:-92.2075},
{x:35.0413,y:-85.2604},
{x:36.782,y:-76.0481},
{x:41.4537,y:-90.5706},
{x:28.5472,y:-81.3768},
{x:28.427,y:-81.4441},
{x:41.8798,y:-71.3646},
{x:34.0469,y:-118.247},
{x:40.319,y:-80.2023},
{x:34.0645,y:-117.9675},
{x:36.271,y:-76.9719},
{x:30.6323,y:-96.3814},
{x:30.3484,y:-97.5486},
{x:39.4383,y:-98.6942},
{x:40.5915,y:-74.323},
{x:40.5818,y:-74.3317},
{x:26.0866,y:-97.951},
{x:41.3169,y:-81.4408},
{x:43.175,y:-73.0509},
{x:29.7302,y:-95.3832},
{x:37.7887,y:-121.2175},
{x:30.1519,y:-94.7366},
{x:41.151,y:-81.5085},
{x:40.5394,y:-112.2977},
{x:41.7312,y:-84.8794},
{x:28.3842,y:-81.4044},
{x:28.5095,y:-81.2368},
{x:36.2321,y:-85.0087},
{x:41.8982,y:-87.8095},
{x:34.0998,y:-84.1743},
{x:33.8466,y:-84.6991},
{x:33.2147,y:-92.6312},
{x:32.9676,y:-97.4119},
{x:34.5889,y:-112.3393},
{x:41.6646,y:-72.7214},
{x:29.4308,y:-97.1714},
{x:40.6491,y:-111.9429},
{x:34.843,y:-87.691},
{x:30.1528,y:-95.2229},
{x:30.1136,y:-95.3891},
{x:43.6136,y:-84.2129},
{x:39.1586,y:-75.5425},
{x:41.154,y:-73.4246},
{x:41.3714,y:-81.8681},
{x:39.8961,y:-85.9174},
{x:34.525,y:-117.2162},
{x:42.2073,y:-73.3463},
{x:13.4701,y:144.7549},
{x:18.3762,y:-66.1467},
{x:43.1745,y:-89.246},
{x:43.2646,y:-82.6195},
{x:42.668,y:-84.5394},
{x:38.9022,y:-94.531},
{x:36.7302,y:-119.7996},
{x:38.435,y:-81.836},
{x:41.9304,y:-83.345},
{x:41.524,y:-87.4733},
{x:26.3309,y:-81.8078},
{x:34.2008,y:-118.4923},
{x:41.5232,y:-82.8589},
{x:33.9934,y:-79.2019},
{x:31.159,y:-81.391},
{x:42.7584,y:-84.4761},
{x:39.7854,y:-98.7844},
{x:47.3869,y:-122.1638},
{x:37.7952,y:-122.4007},
{x:37.7339,y:-122.4893},
{x:39.8759,y:-79.7014},
{x:30.169,y:-95.5047},
{x:18.3406,y:-65.6603},
{x:25.9508,y:-97.4793},
{x:26.3052,y:-98.1936},
{x:27.8247,y:-97.0693},
{x:26.1253,y:-98.2196},
{x:27.7131,y:-82.3558},
{x:33.5052,y:-101.9237},
{x:40.1249,y:-111.6415},
{x:40.6498,y:-75.2949},
{x:26.1021,y:-80.268},
{x:42.6976,y:-84.5686},
{x:40.5715,y:-74.2256},
{x:38.7827,y:-90.528},
{x:36.1174,y:-84.1185},
{x:29.6977,y:-95.8417},
{x:35.1479,y:-106.5265},
{x:38.8409,y:-76.7282},
{x:34.1017,y:-118.3428},
{x:34.1333,y:-118.2518},
{x:40.1624,y:-122.2348},
{x:42.5174,y:-96.4175},
{x:38.6326,y:-77.2769},
{x:37.4774,y:-100.8714},
{x:34.1214,y:-117.3717},
{x:39.7039,y:-82.4266},
{x:34.8313,y:-95.8408},
{x:26.6508,y:-80.1436},
{x:39.8174,y:-86.3275},
{x:28.4165,y:-97.7553},
{x:42.3934,y:-83.14},
{x:40.2229,y:-83.5565},
{x:29.7986,y:-94.8507},
{x:41.8948,y:-87.6222},
{x:35.5665,y:-78.5281},
{x:41.462,y:-81.7366},
{x:31.0511,y:-97.4852},
{x:33.3157,y:-87.8996},
{x:41.1258,y:-73.3903},
{x:18.268,y:-66.7106},
{x:34.4534,y:-81.8814},
{x:31.9012,y:-106.4064},
{x:43.1471,y:-78.6932},
{x:33.6557,y:-112.012},
{x:44.0904,y:-93.5077},
{x:45.1761,y:-89.6495},
{x:41.2797,y:-73.0476},
{x:41.2801,y:-73.0488},
{x:26.0721,y:-97.4921},
{x:33.0819,y:-96.8505},
{x:32.8503,y:-83.6263},
{x:26.2873,y:-97.9909},
{x:41.7374,y:-87.5755},
{x:39.7421,y:-105.4976},
{x:29.2212,y:-81.0077},
{x:30.2053,y:-97.7155},
{x:33.2609,y:-84.0974},
{x:33.384,y:-83.9035},
{x:47.7206,y:-104.1557},
{x:30.4317,y:-84.2809},
{x:38.815,y:-76.8527},
{x:38.3483,y:-97.0099},
{x:37.6897,y:-120.9947},
{x:34.6723,y:-98.4081},
{x:31.1383,y:-97.766},
{x:30.4131,y:-89.0286},
{x:29.7394,y:-90.1289},
{x:32.8353,y:-97.0088},
{x:29.965,y:-95.4636},
{x:34.9032,y:-94.1066},
{x:47.3508,y:-122.2266},
{x:43.0437,y:-71.681},
{x:39.6673,y:-80.8585},
{x:40.221,y:-75.1399},
{x:42.119,y:-88.2578},
{x:34.0326,y:-118.4752},
{x:35.964,y:-79.7629},
{x:18.1891,y:-66.315},
{x:34.0472,y:-118.3162},
{x:35.1909,y:-101.9212},
{x:34.0383,y:-78.2469},
{x:44.1457,y:-87.5685},
{x:26.4371,y:-80.1273},
{x:41.749,y:-72.6387},
{x:39.8307,y:-86.0371},
{x:40.0087,y:-81.5802},
{x:41.7104,y:-83.479},
{x:29.5096,y:-98.3742},
{x:42.1722,y:-70.8785},
{x:41.7115,y:-71.5003},
{x:40.8548,y:-81.4281},
{x:30.3205,y:-89.3368},
{x:43.0276,y:-88.1896},
{x:31.544,y:-97.1074},
{x:33.9149,y:-112.1443},
{x:39.7605,y:-105.0248},
{x:33.6306,y:-117.7154},
{x:41.2905,y:-72.5804},
{x:35.255,y:-92.9372},
{x:41.4184,y:-81.6952},
{x:45.3656,y:-122.8339},
{x:36.9783,y:-121.9062},
{x:35.8056,y:-90.6351},
{x:33.4976,y:-117.6607},
{x:30.7345,y:-86.3495},
{x:39.8931,y:-83.7229},
{x:34.1749,y:-78.0872},
{x:39.0892,y:-84.4763},
{x:37.5412,y:-122.0257},
{x:28.097,y:-81.9739},
{x:29.8177,y:-94.897},
{x:30.6455,y:-87.9131},
{x:32.7086,y:-117.0867},
{x:30.2477,y:-81.7149},
{x:38.8992,y:-76.9335},
{x:45.4324,y:-122.7542},
{x:39.3209,y:-77.8477},
{x:42.3053,y:-84.0212},
{x:42.2365,y:-84.3393},
{x:38.3721,y:-85.6958},
{x:38.3754,y:-97.6665},
{x:38.6676,y:-96.9386},
{x:39.5264,y:-119.7949},
{x:35.6532,y:-88.8712},
{x:43.2575,y:-70.9025},
{x:40.496,y:-90.1989},
{x:41.5642,y:-87.5318},
{x:33.6978,y:-117.8849},
{x:35.0545,y:-118.1738},
{x:41.8307,y:-87.7469},
{x:41.1869,y:-111.9857},
{x:33.6569,y:-112.3509},
{x:35.1876,y:-111.6634},
{x:46.8102,y:-100.8338},
{x:40.5493,y:-84.5595},
{x:39.6059,y:-86.159},
{x:41.6418,y:-91.5566},
{x:40.4098,y:-82.3553},
{x:41.9008,y:-87.6993},
{x:30.3519,y:-91.1098},
{x:28.7485,y:-81.6203},
{x:34.1757,y:-82.0234},
{x:28.0126,y:-81.7295},
{x:29.2279,y:-99.758},
{x:40.2608,y:-82.9258},
{x:45.124,y:-92.5381},
{x:43.1894,y:-76.2425},
{x:41.5634,y:-72.8751},
{x:40.0512,y:-94.5237},
{x:30.0435,y:-89.9591},
{x:38.4391,y:-121.3959},
{x:34.9851,y:-118.9419},
{x:26.2091,y:-98.2286},
{x:26.3024,y:-98.1722},
{x:38.4844,y:-90.7401},
{x:30.1462,y:-94.0173},
{x:32.9867,y:-96.8531},
{x:42.2062,y:-85.8795},
{x:28.0849,y:-80.6219},
{x:34.0609,y:-117.946},
{x:33.8729,y:-118.3695},
{x:18.3711,y:-65.9003},
{x:37.8493,y:-122.2659},
{x:41.4345,y:-96.4977},
{x:33.0605,y:-85.0309},
{x:30.4439,y:-90.4543},
{x:25.4996,y:-80.4123},
{x:32.3278,y:-95.3005},
{x:35.1916,y:-114.0196},
{x:28.2554,y:-97.6857},
{x:46.1821,y:-119.1768},
{x:39.0777,y:-108.559},
{x:34.7537,y:-77.4306},
{x:37.7403,y:-121.399},
{x:27.8366,y:-82.7281},
{x:29.5355,y:-98.2865},
{x:34.7424,y:-82.0392},
{x:42.0827,y:-71.4578},
{x:29.5938,y:-90.6768},
{x:32.1915,y:-83.7471},
{x:43.4082,y:-84.4751},
{x:42.4035,y:-86.2565},
{x:38.8051,y:-121.2062},
{x:42.8648,y:-85.6733},
{x:38.3384,y:-85.6205},
{x:34.2574,y:-88.6847},
{x:40.9662,y:-81.4651},
{x:34.2862,y:-83.8431},
{x:42.6992,y:-83.2415},
{x:34.2219,y:-118.4472},
{x:36.1601,y:-115.3501},
{x:33.6463,y:-97.08},
{x:41.3151,y:-81.7837},
{x:32.5358,y:-92.6526},
{x:35.8397,y:-101.9709},
{x:29.6251,y:-98.2395},
{x:37.6562,y:-97.4253},
{x:41.1314,y:-85.1349},
{x:40.6375,y:-75.4888},
{x:44.9814,y:-124.0081},
{x:38.7697,y:-76.2086},
{x:46.8211,y:-92.1001},
{x:39.3299,y:-76.5189},
{x:44.0138,y:-121.3196},
{x:34.09,y:-81.1733},
{x:38.6604,y:-78.4907},
{x:42.0822,y:-88.0737},
{x:40.8879,y:-73.8604},
{x:29.7326,y:-95.4657},
{x:30.4319,y:-95.565},
{x:40.6785,y:-73.834},
{x:33.5972,y:-86.6477},
{x:42.1842,y:-75.6339},
{x:32.4057,y:-85.9568},
{x:30.0727,y:-90.4966},
{x:34.5186,y:-117.3145},
{x:42.2552,y:-71.7977},
{x:39.294,y:-76.6641},
{x:25.7992,y:-80.2639},
{x:36.5857,y:-82.2567},
{x:38.3067,y:-122.3029},
{x:37.8047,y:-122.2736},
{x:40.5648,y:-74.1279},
{x:39.8765,y:-88.9647},
{x:32.4374,y:-103.1597},
{x:32.8229,y:-96.9939},
{x:39.1884,y:-94.5237},
{x:33.6811,y:-84.4184},
{x:18.1507,y:-65.443},
{x:36.1908,y:-83.3572},
{x:40.4587,y:-74.3995},
{x:41.9202,y:-87.9952},
{x:44.9612,y:-93.8566},
{x:34.6972,y:-87.6356},
{x:36.8809,y:-94.4328},
{x:35.2627,y:-112.1943},
{x:33.5175,y:-88.3773},
{x:47.2297,y:-122.4723},
{x:35.8326,y:-83.5685},
{x:36.2169,y:-86.5999},
{x:42.7294,y:-97.5061},
{x:34.1863,y:-84.5043},
{x:35.1456,y:-119.4661},
{x:27.4143,y:-80.3957},
{x:33.4515,y:-81.925},
{x:40.7434,y:-73.9773},
{x:29.1651,y:-82.0961},
{x:25.921,y:-97.4734},
{x:41.7141,y:-87.6822},
{x:36.4151,y:-99.3814},
{x:41.4653,y:-81.5079},
{x:35.2673,y:-94.3704},
{x:44.0238,y:-116.9396},
{x:37.6068,y:-97.3294},
{x:27.8439,y:-82.8281},
{x:33.2473,y:-95.9096},
{x:42.5268,y:-82.9444},
{x:42.6756,y:-71.1302},
{x:37.7409,y:-81.2294},
{x:47.0923,y:-122.5887},
{x:37.8652,y:-82.7889},
{x:33.3385,y:-84.6864},
{x:39.0994,y:-76.727},
{x:48.3253,y:-122.6274},
{x:46.3571,y:-94.2166},
{x:41.7311,y:-122.6348},
{x:36.624,y:-79.9581},
{x:43.2114,y:-77.9547},
{x:38.2052,y:-85.7642},
{x:33.2478,y:-84.2897},
{x:42.8474,y:-70.9111},
{x:36.1341,y:-90.1555},
{x:31.974,y:-81.1331},
{x:43.42,y:-95.1255},
{x:47.5203,y:-92.5944},
{x:30.6061,y:-96.3108},
{x:26.1025,y:-80.2015},
{x:41.424,y:-81.4782},
{x:29.9656,y:-95.3982},
{x:36.1537,y:-115.1114},
{x:36.0865,y:-115.2262},
{x:36.0601,y:-115.2793},
{x:36.3082,y:-86.6881},
{x:27.9407,y:-82.3686},
{x:45.5708,y:-94.2303},
{x:30.8471,y:-83.3024},
{x:38.8759,y:-104.8375},
{x:36.3066,y:-95.5279},
{x:39.8469,y:-104.6685},
{x:29.9142,y:-91.6664},
{x:33.6975,y:-86.5219},
{x:28.3042,y:-81.4237},
{x:21.3506,y:-157.9464},
{x:26.1528,y:-80.1545},
{x:40.5974,y:-80.5288},
{x:31.7262,y:-106.2624},
{x:36.3159,y:-115.3063},
{x:33.9316,y:-84.1467},
{x:38.9492,y:-76.4916},
{x:30.1749,y:-85.606},
{x:37.0042,y:-85.9243},
{x:41.3903,y:-84.1473},
{x:29.9835,y:-90.1053},
{x:38.5937,y:-121.2658},
{x:42.2937,y:-71.0485},
{x:36.2552,y:-76.2775},
{x:39.0413,y:-94.5721},
{x:32.8948,y:-96.6501},
{x:33.9179,y:-117.9403},
{x:32.0328,y:-89.5229},
{x:28.6968,y:-81.3274},
{x:29.9351,y:-95.6286},
{x:32.6617,y:-96.91},
{x:35.3576,y:-119.0926},
{x:32.5099,y:-95.6428},
{x:43.136,y:-79.0393},
{x:34.6933,y:-79.9099},
{x:40.6232,y:-73.7433},
{x:36.1652,y:-95.8518},
{x:39.2651,y:-76.6542},
{x:44.6977,y:-73.481},
{x:27.9756,y:-82.5112},
{x:37.7381,y:-122.1976},
{x:39.2373,y:-76.6624},
{x:35.0574,y:-98.2555},
{x:37.9292,y:-79.2293},
{x:45.5464,y:-122.7116},
{x:42.4298,y:-83.2784},
{x:40.7908,y:-76.5583},
{x:28.4952,y:-81.3973},
{x:37.5277,y:-82.5755},
{x:38.901,y:-77.0504},
{x:34.0711,y:-84.2966},
{x:36.7428,y:-91.8706},
{x:37.7232,y:-97.2655},
{x:37.6649,y:-97.2447},
{x:42.3426,y:-83.0123},
{x:39.7576,y:-94.7855},
{x:28.5685,y:-81.2253},
{x:36.5284,y:-86.6454},
{x:38.1466,y:-85.5881},
{x:34.3182,y:-86.5036},
{x:29.7642,y:-95.8417},
{x:46.9122,y:-114.0354},
{x:39.4181,y:-86.4067},
{x:36.3967,y:-97.8194},
{x:31.4389,y:-100.4625},
{x:21.9554,y:-159.6691},
{x:34.1844,y:-118.3374},
{x:35.0025,y:-78.9032},
{x:39.963,y:-76.7283},
{x:28.4356,y:-82.5484},
{x:42.2775,y:-84.3119},
{x:28.1293,y:-80.63},
{x:33.8882,y:-117.8646},
{x:47.6615,y:-122.2961},
{x:41.4912,y:-72.8205},
{x:41.6595,y:-83.5025},
{x:30.2653,y:-81.6071},
{x:39.4923,y:-87.3595},
{x:44.9913,y:-93.0878},
{x:32.5722,y:-95.856},
{x:28.0542,y:-82.3337},
{x:37.1028,y:-113.5659},
{x:40.2102,y:-79.7686},
{x:42.2611,y:-85.1716},
{x:30.1771,y:-93.2328},
{x:32.7625,y:-97.1149},
{x:43.6036,y:-96.7893},
{x:41.5257,y:-93.6187},
{x:34.7031,y:-86.5857},
{x:30.3133,y:-97.6638},
{x:42.4337,y:-123.1686},
{x:41.3977,y:-79.8302},
{x:44.8959,y:-89.5044},
{x:38.7669,y:-76.8918},
{x:36.9155,y:-76.2537},
{x:40.1255,y:-86.6083},
{x:43.5134,y:-83.9611},
{x:35.7702,y:-83.2716},
{x:42.9144,y:-88.2158},
{x:45.0921,y:-87.6477},
{x:35.1895,y:-111.6552},
{x:46.5459,y:-87.4184},
{x:38.1516,y:-91.2498},
{x:34.2891,y:-85.1874},
{x:42.1881,y:-85.6016},
{x:40.7414,y:-73.9024},
{x:38.1382,y:-85.6197},
{x:34.594,y:-98.4564},
{x:39.8549,y:-105.081},
{x:29.9047,y:-93.9382},
{x:39.6478,y:-84.2437},
{x:39.5638,y:-94.4577},
{x:33.7575,y:-84.3842},
{x:32.4046,y:-82.3074},
{x:33.8707,y:-117.9415},
{x:31.4643,y:-110.9969},
{x:40.1546,y:-89.3617},
{x:30.2639,y:-82.1626},
{x:39.3009,y:-90.4062},
{x:38.0368,y:-84.5035},
{x:38.02,y:-84.5},
{x:28.8163,y:-96.9808},
{x:41.6853,y:-72.9387},
{x:35.7944,y:-90.9366},
{x:33.8655,y:-117.8623},
{x:42.0989,y:-75.9141},
{x:36.126,y:-115.1896},
{x:41.808,y:-87.704},
{x:46.879,y:-102.791},
{x:30.4028,y:-87.3409},
{x:44.4804,y:-73.2154},
{x:40.9319,y:-90.9375},
{x:39.4927,y:-121.5728},
{x:32.2215,y:-110.8245},
{x:29.445,y:-98.5264},
{x:39.5875,y:-104.6872},
{x:42.4603,y:-90.6742},
{x:37.8679,y:-82.5329},
{x:40.1486,y:-88.955},
{x:48.259,y:-101.3216},
{x:40.7762,y:-73.8244},
{x:34.6082,y:-79.6808},
{x:31.4999,y:-97.2991},
{x:25.879,y:-80.1965},
{x:41.7,y:-86.0013},
{x:38.3956,y:-82.4122},
{x:40.7386,y:-111.8993},
{x:41.3679,y:-72.2099},
{x:32.8406,y:-97.1003},
{x:40.7144,y:-74.0883},
{x:39.8218,y:-111.8353},
{x:33.7957,y:-117.8899},
{x:27.4371,y:-81.3122},
{x:41.94,y:-87.655},
{x:39.3264,y:-84.4306},
{x:28.5252,y:-81.3773},
{x:41.3072,y:-81.8356},
{x:42.5131,y:-92.4391},
{x:36.8629,y:-76.3043},
{x:35.7962,y:-80.9402},
{x:36.9074,y:-111.4812},
{x:32.3078,y:-110.8456},
{x:39.7985,y:-94.8148},
{x:38.0628,y:-97.3456},
{x:38.6363,y:-94.3564},
{x:34.723,y:-100.5338},
{x:33.6425,y:-101.837},
{x:33.2621,y:-94.1515},
{x:41.1407,y:-84.8502},
{x:32.4027,y:-99.3851},
{x:32.2661,y:-109.2251},
{x:33.6242,y:-114.4375},
{x:29.8308,y:-94.8047},
{x:34.9608,y:-120.5744},
{x:34.6391,y:-120.472},
{x:45.4762,y:-91.7363},
{x:35.4316,y:-97.4037},
{x:48.2242,y:-101.351},
{x:31.8274,y:-106.4454},
{x:33.5995,y:-83.7498},
{x:33.4126,y:-83.2801},
{x:41.7723,y:-89.6892},
{x:33.5958,y:-83.6281},
{x:38.3216,y:-104.7022},
{x:38.8467,y:-104.7223},
{x:38.9356,y:-121.092},
{x:38.1312,y:-104.0264},
{x:39.0284,y:-104.8271},
{x:40.9625,y:-80.4361},
{x:39.7114,y:-105.0206},
{x:33.6406,y:-84.4429},
{x:45.6919,y:-87.5205},
{x:42.3374,y:-71.1054},
{x:30.5168,y:-83.0593},
{x:37.5302,y:-95.8322},
{x:28.4048,y:-80.6067},
{x:40.1166,y:-88.2174},
{x:30.1851,y:-93.3759},
{x:41.2675,y:-111.9702},
{x:48.7153,y:-122.4768},
{x:27.8954,y:-81.9718},
{x:40.7141,y:-73.987},
{x:40.2705,y:-103.6217},
{x:38.8543,y:-94.7981},
{x:41.5211,y:-81.3389},
{x:41.3852,y:-77.057},
{x:41.7765,y:-87.7423},
{x:38.7937,y:-90.4483},
{x:33.8183,y:-117.9101},
{x:32.3303,y:-88.5701},
{x:30.517,y:-81.6322},
{x:42.3614,y:-73.4277},
{x:33.8481,y:-118.3885},
{x:34.018,y:-85.2539},
{x:40.7462,y:-73.897},
{x:41.0607,y:-80.053},
{x:40.3367,y:-76.4839},
{x:41.9409,y:-86.564},
{x:32.3275,y:-90.194},
{x:36.1687,y:-115.2913},
{x:34.6615,y:-118.2024},
{x:30.026,y:-91.8236},
{x:34.644,y:-79.0117},
{x:44.9552,y:-95.3653},
{x:47.57,y:-122.6657},
{x:41.2731,y:-75.8847},
{x:43.072,y:-76.1227},
{x:40.1106,y:-88.2295},
{x:31.8974,y:-102.3004},
{x:41.5321,y:-71.3067},
{x:39.0489,y:-76.5166},
{x:42.8837,y:-85.7073},
{x:40.7594,y:-73.8923},
{x:42.4393,y:-83.3807},
{x:46.7378,y:-119.9074},
{x:31.3908,y:-92.4369},
{x:42.2204,y:-73.7335},
{x:40.7037,y:-80.2797},
{x:44.0785,y:-103.2517},
{x:42.9901,y:-84.5431},
{x:26.0825,y:-80.3643},
{x:34.5815,y:-79.0473},
{x:33.4664,y:-111.985},
{x:26.4546,y:-80.164},
{x:40.042,y:-76.7252},
{x:29.5633,y:-95.5633},
{x:29.6049,y:-95.1256},
{x:29.5622,y:-98.3313},
{x:26.2304,y:-81.6061},
{x:44.0447,y:-101.6676},
{x:35.8159,y:-83.9807},
{x:36.3163,y:-95.6244},
{x:41.2946,y:-85.1885},
{x:33.5801,y:-112.1527},
{x:37.5364,y:-121.9659},
{x:36.8943,y:-121.7761},
{x:47.8756,y:-95.0274},
{x:32.637,y:-97.348},
{x:31.6906,y:-106.2608},
{x:40.4173,y:-74.1812},
{x:43.1157,y:-76.1442},
{x:30.6366,y:-90.3984},
{x:37.2242,y:-93.2914},
{x:41.0898,y:-111.9979},
{x:36.8955,y:-86.5206},
{x:29.3426,y:-95.2799},
{x:18.5012,y:-67.1359},
{x:43.6667,y:-92.9858},
{x:30.9668,y:-95.8799},
{x:41.8798,y:-72.7996},
{x:33.7192,y:-117.8683},
{x:42.0143,y:-89.3346},
{x:37.3104,y:-121.8098},
{x:40.339,y:-79.3155},
{x:42.0893,y:-71.3016},
{x:42.6433,y:-84.074},
{x:26.2743,y:-98.5542},
{x:43.4595,y:-76.4874},
{x:48.4362,y:-122.3365},
{x:34.4374,y:-89.9009},
{x:33.0866,y:-96.7711},
{x:39.287,y:-80.3846},
{x:37.1154,y:-107.633},
{x:35.6489,y:-78.0025},
{x:43.0163,y:-88.1046},
{x:30.4183,y:-86.6525},
{x:28.0306,y:-82.3938},
{x:40.0438,y:-86.1408},
{x:42.4083,y:-83.099},
{x:34.0712,y:-86.7765},
{x:43.65,y:-116.4355},
{x:41.5597,y:-85.7978},
{x:38.9456,y:-76.9782},
{x:38.8096,y:-90.2986},
{x:37.7812,y:-121.3112},
{x:39.3726,y:-76.5659},
{x:40.8587,y:-96.6781},
{x:32.7473,y:-96.5974},
{x:43.399,y:-103.3961},
{x:33.8475,y:-117.8902},
{x:38.5793,y:-90.3276},
{x:41.1654,y:-73.9386},
{x:33.3074,y:-111.9018},
{x:36.1029,y:-115.1698},
{x:36.2168,y:-115.2496},
{x:47.891,y:-122.2874},
{x:37.2731,y:-105.9624},
{x:36.1108,y:-115.1714},
{x:33.7925,y:-84.2881},
{x:39.1752,y:-76.742},
{x:29.5496,y:-95.587},
{x:42.0083,y:-80.3025},
{x:35.6806,y:-88.8298},
{x:34.7838,y:-86.573},
{x:29.4568,y:-98.5543},
{x:41.853,y:-72.6657},
{x:45.847,y:-122.8309},
{x:36.0843,y:-80.2939},
{x:29.1086,y:-97.2829},
{x:32.5507,y:-82.9728},
{x:32.106,y:-92.0766},
{x:41.4109,y:-81.8337},
{x:35.1216,y:-78.7497},
{x:41.6501,y:-91.5406},
{x:30.1599,y:-95.3115},
{x:41.7088,y:-83.691},
{x:33.4356,y:-112.4095},
{x:43.9443,y:-72.6251},
{x:42.4536,y:-83.3434},
{x:35.6365,y:-88.8325},
{x:41.793,y:-87.801},
{x:41.6443,y:-93.4666},
{x:25.7478,y:-80.3102},
{x:31.562,y:-85.2688},
{x:36.2004,y:-89.6686},
{x:38.8405,y:-121.3141},
{x:39.763,y:-92.5621},
{x:36.7359,y:-119.7546},
{x:41.7798,y:-87.6055},
{x:46.8086,y:-95.8462},
{x:44.8599,y:-92.6258},
{x:36.0038,y:-78.9387},
{x:32.1859,y:-90.2645},
{x:33.9858,y:-83.4208},
{x:30.742,y:-88.1105},
{x:42.1477,y:-72.6096},
{x:41.6731,y:-86.159},
{x:29.971,y:-94.0243},
{x:30.4875,y:-95.9877},
{x:29.7175,y:-95.846},
{x:41.8344,y:-79.3756},
{x:29.61,y:-98.5969},
{x:40.7939,y:-74.1979},
{x:31.8589,y:-106.5856},
{x:32.1732,y:-81.2334},
{x:36.7805,y:-89.3861},
{x:40.5008,y:-79.9434},
{x:34.488,y:-82.6778},
{x:42.6458,y:-71.3121},
{x:27.3876,y:-82.4421},
{x:29.4262,y:-98.795},
{x:33.9453,y:-118.372},
{x:33.7582,y:-118.024},
{x:27.302,y:-80.3729},
{x:33.1653,y:-87.5265},
{x:40.4508,y:-86.857},
{x:38.1662,y:-85.5694},
{x:31.7874,y:-102.4792},
{x:38.4421,y:-78.8754},
{x:41.1263,y:-104.7619},
{x:31.8815,y:-106.5826},
{x:37.9634,y:-121.3384},
{x:32.5327,y:-92.5129},
{x:33.7901,y:-118.3136},
{x:41.3368,y:-92.2066},
{x:41.0563,y:-85.0974},
{x:42.3029,y:-83.2579},
{x:38.0508,y:-84.7146},
{x:39.0285,y:-104.7953},
{x:28.9419,y:-99.8485},
{x:34.0693,y:-117.1821},
{x:37.6442,y:-122.1046},
{x:34.7351,y:-120.5382},
{x:44.8099,y:-122.8145},
{x:42.4077,y:-88.6127},
{x:39.8664,y:-88.9609},
{x:29.4979,y:-98.7151},
{x:28.4608,y:-81.4663},
{x:42.005,y:-84.3489},
{x:38.4827,y:-121.3695},
{x:39.3675,y:-76.5831},
{x:34.0754,y:-84.2606},
{x:38.5319,y:-89.145},
{x:35.7077,y:-118.455},
{x:25.8602,y:-80.2142},
{x:35.9701,y:-79.9255},
{x:44.5247,y:-98.9883},
{x:42.0599,y:-93.88},
{x:32.575,y:-96.8573},
{x:42.535,y:-96.3714},
{x:42.3175,y:-87.8657},
{x:30.0718,y:-85.5899},
{x:30.4697,y:-86.5457},
{x:33.849,y:-87.2871},
{x:38.4233,y:-122.7138},
{x:40.3348,y:-79.8909},
{x:31.9473,y:-102.1404},
{x:36.4999,y:-101.7877},
{x:33.458,y:-112.2393},
{x:34.0,y:-95.0849},
{x:37.9607,y:-100.8298},
{x:38.0017,y:-100.9965},
{x:41.3303,y:-96.35},
{x:32.3235,y:-89.3414},
{x:42.5882,y:-71.1565},
{x:38.466,y:-122.6522},
{x:32.1585,y:-94.355},
{x:42.9861,y:-83.7165},
{x:40.8429,y:-73.9417},
{x:39.3087,y:-120.5452},
{x:48.2442,y:-101.2965},
{x:18.0437,y:-66.7187},
{x:32.9222,y:-96.8409},
{x:41.0847,y:-80.7328},
{x:40.2766,y:-79.5316},
{x:42.3933,y:-71.5978},
{x:40.0165,y:-78.5077},
{x:47.5065,y:-117.5673},
{x:33.2697,y:-80.5654},
{x:42.0584,y:-88.2664},
{x:41.9961,y:-88.3144},
{x:47.1593,y:-122.2968},
{x:39.28,y:-84.9603},
{x:41.292,y:-72.5801},
{x:48.41,y:-101.3232},
{x:39.9497,y:-82.0477},
{x:39.4649,y:-76.1649},
{x:41.7816,y:-76.4497},
{x:47.6518,y:-122.6814},
{x:37.4435,y:-89.6377},
{x:25.796,y:-80.3195},
{x:32.9174,y:-96.5205},
{x:41.1921,y:-79.8688},
{x:33.2664,y:-86.3528},
{x:40.4878,y:-111.4086},
{x:37.2376,y:-77.4192},
{x:39.5414,y:-82.4162},
{x:43.2586,y:-72.5883},
{x:32.6519,y:-96.9269},
{x:34.0191,y:-86.0759},
{x:35.0043,y:-114.5979},
{x:26.1366,y:-80.1205},
{x:34.1387,y:-80.9526},
{x:26.3847,y:-80.2049},
{x:40.7809,y:-74.2055},
{x:32.8634,y:-80.0502},
{x:26.4507,y:-81.8175},
{x:30.0068,y:-81.6943},
{x:33.6981,y:-86.6822},
{x:33.845,y:-116.4574},
{x:29.5708,y:-98.2331},
{x:41.3227,y:-72.8524},
{x:39.9911,y:-76.6394},
{x:40.5578,y:-111.8897},
{x:41.5175,y:-71.3187},
{x:40.6968,y:-112.0235},
{x:35.0893,y:-106.6247},
{x:38.7692,y:-104.7812},
{x:36.6128,y:-83.7045},
{x:29.5936,y:-98.3548},
{x:41.908,y:-87.9175},
{x:37.6773,y:-120.9376},
{x:43.636,y:-116.2747},
{x:34.0187,y:-117.5573},
{x:35.4057,y:-97.9385},
{x:41.9365,y:-88.1186},
{x:40.7542,y:-73.9772},
{x:38.7996,y:-90.7037},
{x:41.5963,y:-109.2408},
{x:40.163,y:-105.0877},
{x:32.5893,y:-88.1869},
{x:42.6053,y:-88.4305},
{x:37.1053,y:-89.9051},
{x:32.5313,y:-96.8038},
{x:36.5887,y:-89.5443},
{x:36.8013,y:-121.6646},
{x:39.0511,y:-94.4499},
{x:47.5224,y:-95.4172},
{x:21.3335,y:-158.0521},
{x:43.1817,y:-70.876},
{x:35.0385,y:-82.0743},
{x:37.6065,y:-99.2974},
{x:39.5734,y:-76.0668},
{x:33.3809,y:-83.8151},
{x:39.9427,y:-76.7405},
{x:40.7968,y:-73.2751},
{x:39.9941,y:-83.7759},
{x:30.0931,y:-95.4488},
{x:26.2485,y:-97.7304},
{x:41.0839,y:-111.9734},
{x:30.1768,y:-93.1291},
{x:43.3172,y:-88.3526},
{x:40.9967,y:-73.6723},
{x:18.4156,y:-66.1618},
{x:38.0333,y:-78.507},
{x:33.7953,y:-117.853},
{x:38.5294,y:-77.4413},
{x:35.6744,y:-88.7837},
{x:39.0622,y:-96.8262},
{x:42.1401,y:-72.6264},
{x:40.8277,y:-83.2898},
{x:33.5941,y:-112.3247},
{x:39.4786,y:-77.9572},
{x:43.5323,y:-89.9895},
{x:32.9305,y:-105.9631},
{x:43.0548,y:-71.4436},
{x:44.9189,y:-93.278},
{x:28.0685,y:-82.7515},
{x:41.5706,y:-93.7536},
{x:38.887,y:-77.0106},
{x:26.6591,y:-80.1132},
{x:41.7772,y:-86.7319},
{x:32.5079,y:-92.2493},
{x:38.3462,y:-85.8195},
{x:35.5076,y:-97.7598},
{x:38.8666,y:-104.6844},
{x:35.1953,y:-106.6157},
{x:33.7412,y:-90.7156},
{x:32.4402,y:-95.134},
{x:38.9277,y:-77.3751},
{x:30.1869,y:-92.0112},
{x:29.963,y:-81.4892},
{x:37.5939,y:-93.4018},
{x:29.6018,y:-97.9519},
{x:43.0891,y:-88.9097},
{x:36.1452,y:-115.1767},
{x:41.1605,y:-80.0867},
{x:40.8746,y:-79.9506},
{x:40.8014,y:-80.1383},
{x:32.084,y:-110.8033},
{x:41.0408,y:-83.6693},
{x:42.8586,y:-85.7245},
{x:35.6433,y:-82.0398},
{x:44.2995,y:-89.8102},
{x:41.7032,y:-72.6601},
{x:39.6457,y:-106.9473},
{x:29.9868,y:-95.3486},
{x:41.1269,y:-81.6056},
{x:43.7006,y:-73.0107},
{x:31.9375,y:-102.387},
{x:41.7458,y:-73.825},
{x:43.5826,y:-116.568},
{x:29.1603,y:-80.9746},
{x:39.3049,y:-84.4652},
{x:42.7831,y:-85.6662},
{x:42.2651,y:-84.5418},
{x:36.1515,y:-81.1958},
{x:35.8469,y:-77.0501},
{x:39.1361,y:-103.475},
{x:33.2161,y:-96.6324},
{x:35.6279,y:-82.181},
{x:29.823,y:-94.8978},
{x:36.2004,y:-120.1032},
{x:30.1542,y:-92.0432},
{x:42.5231,y:-82.8988},
{x:37.6754,y:-92.6563},
{x:31.2962,y:-92.5151},
{x:29.9277,y:-90.3693},
{x:31.8673,y:-106.4302},
{x:29.6672,y:-98.6339},
{x:40.3079,y:-88.1737},
{x:44.3245,y:-96.9267},
{x:30.748,y:-95.2194},
{x:37.0112,y:-90.995},
{x:41.9643,y:-83.3504},
{x:39.8347,y:-97.6371},
{x:33.6405,y:-84.4258},
{x:39.7581,y:-86.4889},
{x:33.849,y:-118.1432},
{x:30.2408,y:-92.8215},
{x:32.7209,y:-97.1506},
{x:35.6761,y:-90.5509},
{x:31.728,y:-106.3448},
{x:27.8725,y:-81.5937},
{x:42.3571,y:-72.5475},
{x:40.4447,y:-79.9491},
{x:41.3543,y:-71.7674},
{x:33.9806,y:-84.4335},
{x:30.1953,y:-97.8423},
{x:36.7199,y:-95.63},
{x:44.0476,y:-123.1331},
{x:33.6124,y:-112.0994},
{x:38.784,y:-94.9284},
{x:41.4742,y:-71.9605},
{x:30.3091,y:-81.739},
{x:25.6701,y:-80.4321},
{x:37.2892,y:-80.0821},
{x:37.3466,y:-79.9542},
{x:26.6512,y:-80.2044},
{x:33.7205,y:-94.3921},
{x:38.3602,y:-81.7337},
{x:41.7402,y:-87.633},
{x:35.3419,y:-96.9294},
{x:35.5389,y:-94.2294},
{x:33.8778,y:-117.6008},
{x:40.6972,y:-112.0912},
{x:37.4176,y:-87.4751},
{x:40.6941,y:-81.7015},
{x:32.9533,y:-96.8876},
{x:34.9451,y:-104.7073},
{x:32.5501,y:-85.9217},
{x:34.782,y:-92.2995},
{x:45.8906,y:-89.7013},
{x:35.1087,y:-84.0054},
{x:31.5349,y:-94.1255},
{x:46.8343,y:-96.8843},
{x:31.9352,y:-83.7453},
{x:34.2403,y:-84.7748},
{x:41.6826,y:-85.7028},
{x:36.3412,y:-78.3947},
{x:25.7754,y:-80.3218},
{x:27.9945,y:-81.6877},
{x:42.6534,y:-73.7764},
{x:38.9828,y:-76.3185},
{x:33.0296,y:-96.6988},
{x:39.0838,y:-94.577},
{x:42.8682,y:-71.951},
{x:39.0682,y:-94.6124},
{x:41.3037,y:-111.9682},
{x:32.5563,y:-83.8839},
{x:29.9371,y:-95.249},
{x:42.4191,y:-83.1823},
{x:26.0075,y:-80.2051},
{x:33.6902,y:-85.1512},
{x:34.2673,y:-84.087},
{x:35.1903,y:-84.8837},
{x:40.7823,y:-82.262},
{x:33.9333,y:-84.3332},
{x:41.3804,y:-81.7374},
{x:26.1476,y:-97.6345},
{x:30.5313,y:-89.2334},
{x:42.9105,y:-78.7433},
{x:40.6639,y:-74.2006},
{x:41.3509,y:-82.0673},
{x:31.1146,y:-97.4149},
{x:40.7342,y:-111.4988},
{x:44.756,y:-122.4704},
{x:30.3347,y:-97.8086},
{x:31.7848,y:-85.9635},
{x:36.8301,y:-76.4172},
{x:42.43,y:-97.0957},
{x:36.6995,y:-77.5441},
{x:41.6382,y:-83.5415},
{x:39.4098,y:-86.425},
{x:39.8782,y:-75.2388},
{x:41.2338,y:-80.4642},
{x:43.444,y:-93.2202},
{x:37.8059,y:-122.4127},
{x:30.3718,y:-89.6175},
{x:30.3713,y:-89.6132},
{x:36.1621,y:-96.0192},
{x:35.0599,y:-85.1369},
{x:37.9696,y:-121.29},
{x:39.9341,y:-76.6928},
{x:33.4145,y:-111.9963},
{x:34.823,y:-85.2413},
{x:29.541,y:-98.5525},
{x:34.5391,y:-96.2184},
{x:30.916,y:-93.9972},
{x:38.1129,y:-121.3095},
{x:30.5471,y:-91.9641},
{x:38.4935,y:-102.7854},
{x:32.6046,y:-96.7865},
{x:32.9736,y:-96.9932},
{x:45.399,y:-87.6102},
{x:36.3272,y:-119.6048},
{x:33.5095,y:-112.1068},
{x:44.6305,y:-90.0213},
{x:32.9399,y:-80.0364},
{x:37.5109,y:-121.9427},
{x:36.2547,y:-120.2507},
{x:41.7065,y:-93.6109},
{x:31.7008,y:-83.2509},
{x:44.6306,y:-70.1588},
{x:37.3618,y:-122.0244},
{x:35.2461,y:-81.4054},
{x:42.4974,y:-90.6594},
{x:36.0145,y:-86.546},
{x:36.839,y:-119.8492},
{x:35.6081,y:-117.6433},
{x:40.5488,y:-82.8445},
{x:38.608,y:-90.2022},
{x:41.7449,y:-72.8616},
{x:41.6002,y:-93.575},
{x:42.4822,y:-96.0683},
{x:36.1373,y:-120.1653},
{x:33.9306,y:-117.8942},
{x:40.3029,y:-79.5452},
{x:32.7318,y:-97.1106},
{x:57.7882,y:-152.4054},
{x:36.3311,y:-102.0725},
{x:43.0123,y:-87.9371},
{x:41.0089,y:-80.3943},
{x:30.0553,y:-94.1409},
{x:33.8861,y:-118.0961},
{x:39.1174,y:-94.7642},
{x:37.4242,y:-122.1709},
{x:42.1687,y:-123.6465},
{x:43.1228,y:-92.735},
{x:32.7175,y:-83.7334},
{x:42.8417,y:-73.9441},
{x:30.6198,y:-81.5345},
{x:46.4121,y:-112.7251},
{x:42.0864,y:-75.9727},
{x:29.8706,y:-95.5459},
{x:42.6984,y:-76.4202},
{x:39.1151,y:-77.2176},
{x:37.2857,y:-100.3405},
{x:35.9464,y:-97.5946},
{x:37.6402,y:-98.427},
{x:25.7971,y:-80.2752},
{x:32.8959,y:-95.5589},
{x:29.8953,y:-90.0572},
{x:32.8646,y:-96.7096},
{x:27.6743,y:-97.3003},
{x:40.1406,y:-84.2668},
{x:39.9516,y:-83.8266},
{x:25.7294,y:-80.4332},
{x:39.9696,y:-82.9908},
{x:33.4283,y:-86.7062},
{x:34.1927,y:-101.7511},
{x:38.9231,y:-75.9587},
{x:34.0279,y:-81.0319},
{x:39.7006,y:-77.7374},
{x:41.9996,y:-97.6002},
{x:38.8276,y:-97.6383},
{x:33.3499,y:-86.8665},
{x:34.195,y:-91.9221},
{x:41.5087,y:-112.0522},
{x:35.2771,y:-97.6012},
{x:43.399,y:-97.121},
{x:18.0294,y:-66.8573},
{x:44.9746,y:-93.281},
{x:18.3243,y:-65.6554},
{x:43.1903,y:-89.4625},
{x:33.2483,y:-111.7895},
{x:43.8023,y:-73.0964},
{x:36.0721,y:-79.499},
{x:41.5965,y:-90.6143},
{x:32.3157,y:-95.2542},
{x:34.1647,y:-118.3696},
{x:33.2633,y:-97.1794},
{x:36.7376,y:-97.0724},
{x:40.9195,y:-90.2942},
{x:26.2368,y:-98.3847},
{x:33.5515,y:-84.2723},
{x:33.3774,y:-111.9515},
{x:42.5644,y:-114.6055},
{x:41.043,y:-82.7296},
{x:44.1619,y:-94.011},
{x:41.3319,y:-82.6242},
{x:35.754,y:-85.4581},
{x:41.7517,y:-91.609},
{x:33.8864,y:-84.0078},
{x:45.1788,y:-93.8723},
{x:34.1529,y:-83.6486},
{x:41.3144,y:-81.5035},
{x:36.0102,y:-121.2385},
{x:34.5454,y:-112.4677},
{x:41.0292,y:-78.4404},
{x:42.5387,y:-88.0507},
{x:42.2263,y:-83.3474},
{x:41.7464,y:-92.7271},
{x:39.5907,y:-104.8615},
{x:37.0549,y:-94.5355},
{x:32.2852,y:-81.7241},
{x:30.4002,y:-87.0002},
{x:45.0279,y:-84.6787},
{x:44.6571,y:-84.7085},
{x:43.0009,y:-78.4057},
{x:37.3425,y:-85.3671},
{x:34.0314,y:-118.4453},
{x:38.0678,y:-78.933},
{x:34.669,y:-77.3428},
{x:33.9411,y:-118.0473},
{x:34.7459,y:-77.3801},
{x:40.7016,y:-74.0138},
{x:44.0962,y:-103.1527},
{x:45.2135,y:-85.0141},
{x:30.7679,y:-94.4155},
{x:45.3159,y:-85.259},
{x:39.2072,y:-121.2038},
{x:42.9533,y:-85.0729},
{x:40.7022,y:-111.9383},
{x:42.2617,y:-85.1977},
{x:38.087,y:-84.512},
{x:33.9613,y:-118.3599},
{x:38.9861,y:-90.9786},
{x:44.0872,y:-103.1931},
{x:44.0897,y:-115.9519},
{x:39.7507,y:-86.1402},
{x:32.3278,y:-86.3365},
{x:35.405,y:-119.1442},
{x:28.8781,y:-96.9939},
{x:39.6955,y:-104.8265},
{x:35.4812,y:-97.4978},
{x:47.2328,y:-122.3358},
{x:40.0755,y:-80.8759},
{x:37.2814,y:-83.2302},
{x:39.4231,y:-84.461},
{x:34.075,y:-117.8883},
{x:35.2461,y:-90.7815},
{x:41.576,y:-87.0426},
{x:30.2856,y:-81.8036},
{x:42.3758,y:-72.5195},
{x:41.1579,y:-101.118},
{x:44.0869,y:-93.2283},
{x:33.5716,y:-112.121},
{x:34.6422,y:-97.1733},
{x:34.258,y:-83.4639},
{x:33.9462,y:-118.3531},
{x:43.8061,y:-90.0683},
{x:45.3067,y:-122.9457},
{x:34.2919,y:-99.7578},
{x:29.7262,y:-95.4849},
{x:33.8795,y:-98.5295},
{x:30.1033,y:-95.2349},
{x:40.6856,y:-73.3539},
{x:43.0467,y:-85.6574},
{x:30.7638,y:-85.5442},
{x:32.6241,y:-85.4476},
{x:13.3978,y:144.6633},
{x:34.8029,y:-87.6782},
{x:40.3069,y:-104.9831},
{x:39.3722,y:-82.1335},
{x:29.4028,y:-98.7048},
{x:30.553,y:-88.1226},
{x:43.4046,y:-72.4127},
{x:26.0079,y:-80.2364},
{x:41.0271,y:-80.3532},
{x:39.9859,y:-105.0941},
{x:32.9086,y:-96.7351},
{x:42.3492,y:-71.8588},
{x:45.4127,y:-122.7166},
{x:46.5669,y:-120.4847},
{x:42.4151,y:-83.1393},
{x:35.2188,y:-97.4762},
{x:38.5467,y:-76.7973},
{x:32.6988,y:-94.7506},
{x:29.5541,y:-98.4284},
{x:39.3961,y:-74.5526},
{x:37.7868,y:-122.4074},
{x:40.9108,y:-73.1238},
{x:29.5734,y:-95.7485},
{x:29.2404,y:-81.0579},
{x:41.5089,y:-81.6057},
{x:34.1731,y:-98.4984},
{x:34.2277,y:-83.4991},
{x:34.4449,y:-84.9137},
{x:32.2858,y:-96.5462},
{x:30.5708,y:-100.5835},
{x:32.1961,y:-86.4154},
{x:32.018,y:-101.9371},
{x:43.4952,y:-111.9997},
{x:40.6808,y:-82.5711},
{x:33.1011,y:-86.8617},
{x:33.4215,y:-86.6767},
{x:32.6178,y:-83.6372},
{x:33.4328,y:-84.1842},
{x:42.8409,y:-85.6745},
{x:39.9151,y:-83.0214},
{x:39.1574,y:-76.7252},
{x:39.0208,y:-76.694},
{x:41.8532,y:-87.6226},
{x:41.8527,y:-87.6589},
{x:40.0488,y:-78.6343},
{x:38.8788,y:-121.0807},
{x:39.6303,y:-106.4188},
{x:35.6199,y:-105.2078},
{x:33.7542,y:-96.5373},
{x:46.7209,y:-92.0872},
{x:40.362,y:-76.4194},
{x:41.1566,y:-81.4042},
{x:33.2788,y:-115.9659},
{x:46.2638,y:-119.488},
{x:41.1555,y:-81.2997},
{x:38.4603,y:-121.4928},
{x:43.7962,y:-91.2196},
{x:37.0943,y:-93.8067},
{x:35.6243,y:-97.485},
{x:37.2555,y:-121.7911},
{x:47.1212,y:-122.5541},
{x:38.925,y:-79.8458},
{x:38.7858,y:-112.0835},
{x:33.8847,y:-86.7664},
{x:41.8734,y:-103.674},
{x:42.2288,y:-111.4006},
{x:33.1526,y:-96.7163},
{x:44.9001,y:-89.6099},
{x:35.4935,y:-97.2699},
{x:37.6969,y:-121.8455},
{x:33.1821,y:-84.3632},
{x:47.4687,y:-94.8792},
{x:25.7604,y:-80.4206},
{x:32.9,y:-83.6876},
{x:36.7507,y:-119.7986},
{x:38.4118,y:-121.3916},
{x:44.793,y:-91.513},
{x:40.7523,y:-73.9839},
{x:44.7711,y:-91.4366},
{x:42.9737,y:-83.7172},
{x:33.4954,y:-112.1014},
{x:37.7482,y:-88.9715},
{x:35.4937,y:-97.7401},
{x:33.4794,y:-112.0733},
{x:47.7771,y:-122.3118},
{x:45.6465,y:-84.4748},
{x:36.2777,y:-95.8306},
{x:35.1671,y:-85.0789},
{x:33.0576,y:-96.6908},
{x:44.0886,y:-87.6603},
{x:41.5835,y:-81.2144},
{x:36.7123,y:-119.5576},
{x:36.898,y:-113.9133},
{x:42.7412,y:-84.603},
{x:36.344,y:-82.3774},
{x:29.394,y:-98.6572},
{x:38.8321,y:-77.192},
{x:35.3477,y:-80.2053},
{x:25.9803,y:-80.3368},
{x:29.9039,y:-90.0301},
{x:29.1724,y:-81.034},
{x:40.7553,y:-80.1178},
{x:41.1404,y:-73.2675},
{x:26.0228,y:-80.2652},
{x:26.0297,y:-80.2455},
{x:42.0244,y:-97.4259},
{x:39.416,y:-87.3882},
{x:47.4439,y:-122.3022},
{x:39.4789,y:-80.1258},
{x:41.788,y:-72.6553},
{x:42.339,y:-72.6091},
{x:40.5506,y:-75.593},
{x:38.5617,y:-109.5493},
{x:39.6245,y:-105.023},
{x:33.038,y:-115.6287},
{x:32.4349,y:-103.1202},
{x:42.8638,y:-82.5617},
{x:26.026,y:-80.207},
{x:28.028,y:-81.7252},
{x:33.8488,y:-118.3618},
{x:37.7938,y:-79.8832},
{x:47.6993,y:-122.6243},
{x:40.8591,y:-74.1373},
{x:46.1449,y:-123.9165},
{x:42.4474,y:-75.0492},
{x:25.8083,y:-80.275},
{x:42.397,y:-83.3503},
{x:41.1988,y:-79.972},
{x:42.2263,y:-85.6815},
{x:39.2389,y:-121.0366},
{x:13.4752,y:144.7366},
{x:29.5795,y:-90.6916},
{x:32.8998,y:-97.0403},
{x:33.9857,y:-78.1894},
{x:38.3963,y:-85.3715},
{x:34.3845,y:-92.8305},
{x:40.8896,y:-74.7161},
{x:26.0788,y:-80.2401},
{x:40.0306,y:-83.0343},
{x:34.8388,y:-88.3213},
{x:48.5547,y:-109.6864},
{x:36.923,y:-120.0247},
{x:38.2985,y:-80.8355},
{x:37.3363,y:-121.8813},
{x:40.3566,y:-74.4924},
{x:41.7429,y:-111.8134},
{x:42.5502,y:-83.1072},
{x:44.8546,y:-93.0451},
{x:31.0485,y:-85.8954},
{x:38.6375,y:-90.2414},
{x:37.3008,y:-79.9607},
{x:25.757,y:-80.3799},
{x:37.1264,y:-96.19},
{x:44.9229,y:-92.9351},
{x:29.8884,y:-95.6065},
{x:34.548,y:-112.4366},
{x:36.5417,y:-114.4457},
{x:41.0378,y:-80.1233},
{x:34.8647,y:-111.7937},
{x:39.1178,y:-82.5338},
{x:44.9527,y:-93.0249},
{x:47.9815,y:-102.4719},
{x:45.9751,y:-94.3449},
{x:37.7753,y:-122.4165},
{x:31.2379,y:-85.4568},
{x:31.1778,y:-83.7681},
{x:42.1163,y:-72.5247},
{x:42.1109,y:-72.5691},
{x:41.4618,y:-81.6419},
{x:41.591,y:-90.4607},
{x:33.5918,y:-80.3555},
{x:41.4106,y:-90.0159},
{x:36.1705,y:-105.6875},
{x:34.7515,y:-77.4643},
{x:41.6796,y:-85.9932},
{x:39.2885,y:-77.2026},
{x:32.2134,y:-99.7968},
{x:42.6515,y:-84.5283},
{x:43.4475,y:-93.352},
{x:35.0419,y:-89.5457},
{x:33.131,y:-96.8273},
{x:32.3433,y:-88.7981},
{x:40.0739,y:-83.1379},
{x:35.316,y:-91.5638},
{x:43.4709,y:-88.8269},
{x:37.0326,y:-97.6052},
{x:34.8081,y:-91.5669},
{x:42.9756,y:-90.1332},
{x:42.787,y:-84.6698},
{x:27.6446,y:-99.5552},
{x:39.083,y:-95.7251},
{x:39.7609,y:-84.1941},
{x:27.2434,y:-80.8263},
{x:42.3403,y:-71.1665},
{x:36.3435,y:-84.1662},
{x:44.0693,y:-123.103},
{x:41.177,y:-73.1887},
{x:36.8316,y:-76.3427},
{x:32.1899,y:-95.4345},
{x:38.5624,y:-90.1324},
{x:36.0254,y:-87.1724},
{x:33.9955,y:-117.0592},
{x:42.8469,y:-90.7104},
{x:36.5004,y:-77.6456},
{x:26.1651,y:-98.0072},
{x:46.8527,y:-96.9048},
{x:28.5514,y:-81.5817},
{x:31.5767,y:-102.886},
{x:39.7397,y:-104.9591},
{x:33.4364,y:-111.8239},
{x:35.0474,y:-85.6329},
{x:32.3382,y:-86.1848},
{x:35.1399,y:-118.4685},
{x:33.3936,y:-111.8711},
{x:41.3561,y:-75.0537},
{x:35.0137,y:-92.7984},
{x:30.0293,y:-90.0647},
{x:42.914,y:-85.7624},
{x:44.9052,y:-68.69},
{x:32.7556,y:-96.8718},
{x:45.5034,y:-122.4144},
{x:33.6336,y:-113.7686},
{x:34.8644,y:-114.1485},
{x:40.8802,y:-72.947},
{x:35.1196,y:-89.9381},
{x:40.6104,y:-73.9208},
{x:39.6757,y:-79.85},
{x:42.4956,y:-92.3769},
{x:38.3011,y:-88.9434},
{x:40.7557,y:-96.6639},
{x:43.2262,y:-99.4347},
{x:35.909,y:-92.6313},
{x:29.5027,y:-98.4997},
{x:33.5321,y:-86.8064},
{x:29.6755,y:-98.0583},
{x:38.938,y:-77.0891},
{x:46.7297,y:-117.1774},
{x:32.8131,y:-96.9384},
{x:41.4686,y:-90.48},
{x:32.9298,y:-112.6731},
{x:30.5772,y:-90.4997},
{x:36.3518,y:-82.2123},
{x:31.1123,y:-92.0651},
{x:39.2516,y:-112.1082},
{x:37.8163,y:-80.4283},
{x:43.1323,y:-95.1446},
{x:42.3416,y:-71.0837},
{x:42.0774,y:-86.4254},
{x:42.9267,y:-114.9391},
{x:39.0955,y:-120.9479},
{x:38.304,y:-77.5093},
{x:33.6925,y:-78.8836},
{x:42.576,y:-114.46},
{x:44.7299,y:-93.1451},
{x:36.2133,y:-80.301},
{x:45.2975,y:-118.0436},
{x:38.8285,y:-76.9087},
{x:47.2089,y:-118.226},
{x:41.0087,y:-92.4162},
{x:41.7264,y:-74.3879},
{x:42.6037,y:-87.8462},
{x:34.2259,y:-77.9262},
{x:35.9631,y:-86.8135},
{x:40.5525,y:-85.5547},
{x:42.0669,y:-72.1067},
{x:42.0821,y:-86.4372},
{x:43.4365,y:-84.0273},
{x:42.3312,y:-83.0457},
{x:28.023,y:-81.925},
{x:37.54,y:-77.4297},
{x:38.7066,y:-93.2588},
{x:32.0875,y:-84.6734},
{x:39.0714,y:-94.3813},
{x:38.8449,y:-77.0521},
{x:38.8633,y:-77.0606},
{x:30.5851,y:-95.9442},
{x:33.9856,y:-78.0331},
{x:39.8841,y:-79.7355},
{x:34.0225,y:-80.9437},
{x:43.497,y:-112.0573},
{x:35.0673,y:-89.9606},
{x:39.8123,y:-105.1359},
{x:34.1196,y:-117.5584},
{x:32.2005,y:-95.854},
{x:36.1738,y:-86.5124},
{x:32.6039,y:-96.8216},
{x:32.4933,y:-91.9897},
{x:41.1032,y:-81.0293},
{x:37.6169,y:-104.7573},
{x:36.6201,y:-88.3145},
{x:46.0033,y:-98.5278},
{x:32.8105,y:-83.7324},
{x:33.9865,y:-117.8727},
{x:42.193,y:-72.5509},
{x:31.9714,y:-102.0732},
{x:37.1105,y:-76.4987},
{x:29.9558,y:-90.0763},
{x:27.5105,y:-97.8557},
{x:34.5795,y:-118.1291},
{x:30.3984,y:-87.6828},
{x:37.62,y:-88.9865},
{x:35.3752,y:-85.7012},
{x:36.0818,y:-86.7588},
{x:35.9655,y:-86.8037},
{x:42.9653,y:-76.8465},
{x:30.4494,y:-84.3152},
{x:39.6496,y:-84.1106},
{x:32.1896,y:-112.4156},
{x:30.8083,y:-88.0714},
{x:41.1425,y:-73.8621},
{x:42.7315,y:-94.2262},
{x:34.3113,y:-89.9418},
{x:29.7535,y:-90.5547},
{x:41.5553,y:-74.0041},
{x:41.9974,y:-117.7183},
{x:42.6392,y:-87.8366},
{x:47.0454,y:-122.8235},
{x:29.8899,y:-95.4765},
{x:35.2915,y:-97.7232},
{x:35.5733,y:-89.6455},
{x:35.8707,y:-89.3962},
{x:33.968,y:-84.0335},
{x:30.4446,y:-84.2989},
{x:41.0121,y:-81.6097},
{x:34.8256,y:-82.3961},
{x:40.861,y:-81.4846},
{x:44.4164,y:-104.3451},
{x:39.9067,y:-76.6796},
{x:42.4632,y:-71.2876},
{x:42.3764,y:-85.6593},
{x:33.2398,y:-86.8187},
{x:30.0262,y:-93.8403},
{x:39.2892,y:-80.243},
{x:38.5465,y:-76.0867},
{x:44.9003,y:-93.57},
{x:40.831,y:-73.0883},
{x:40.0984,y:-82.4245},
{x:39.6955,y:-84.0822},
{x:29.8896,y:-95.4941},
{x:62.1072,y:-145.5158},
{x:42.3383,y:-83.0645},
{x:34.4557,y:-117.278},
{x:39.0156,y:-94.0721},
{x:31.8607,y:-106.6842},
{x:41.9177,y:-72.2613},
{x:32.4431,y:-95.0626},
{x:44.2443,y:-88.3037},
{x:42.1544,y:-83.2541},
{x:43.1493,y:-85.7064},
{x:41.7212,y:-72.6138},
{x:31.5698,y:-102.9077},
{x:40.5525,y:-79.1589},
{x:37.8246,y:-82.8197},
{x:36.3478,y:-88.8744},
{x:43.5819,y:-84.7756},
{x:42.0869,y:-89.0106},
{x:43.7394,y:-98.9576},
{x:40.692,y:-73.9874},
{x:42.0637,y:-94.8585},
{x:33.586,y:-86.7703},
{x:40.5861,y:-122.35},
{x:39.2313,y:-76.8295},
{x:32.5275,y:-92.648},
{x:46.0941,y:-94.3443},
{x:30.6895,y:-88.1161},
{x:47.7153,y:-117.4378},
{x:40.8872,y:-72.924},
{x:48.5101,y:-122.6114},
{x:34.1728,y:-83.2917},
{x:18.1487,y:-65.818},
{x:37.7419,y:-122.3874},
{x:38.9683,y:-76.9555},
{x:29.9521,y:-90.0786},
{x:42.9723,y:-82.4527},
{x:35.9761,y:-78.8989},
{x:29.186,y:-82.107},
{x:43.0204,y:-82.455},
{x:33.6804,y:-97.1583},
{x:36.2993,y:-86.7008},
{x:39.2092,y:-76.7251},
{x:34.5091,y:-93.0539},
{x:40.1635,y:-75.1119},
{x:41.8403,y:-71.8768},
{x:40.1797,y:-79.805},
{x:40.7587,y:-80.3198},
{x:42.3453,y:-77.6684},
{x:32.1615,y:-94.319},
{x:40.5888,y:-80.2293},
{x:42.3367,y:-83.1249},
{x:33.5948,y:-101.8708},
{x:44.8553,y:-93.4332},
{x:41.446,y:-86.1668},
{x:19.6539,y:-156.0054},
{x:45.2952,y:-93.8006},
{x:47.4938,y:-111.2797},
{x:36.6146,y:-87.4299},
{x:36.5688,y:-87.2876},
{x:36.5142,y:-87.3136},
{x:36.3088,y:-86.6656},
{x:35.5577,y:-81.0645},
{x:30.1521,y:-96.3912},
{x:33.4362,y:-96.3377},
{x:29.498,y:-94.994},
{x:42.118,y:-76.0191},
{x:42.6379,y:-83.1135},
{x:34.8182,y:-86.4919},
{x:29.6879,y:-82.322},
{x:44.9783,y:-93.2706},
{x:40.1764,y:-74.6286},
{x:40.2809,y:-74.5338},
{x:29.8046,y:-95.3996},
{x:45.3039,y:-122.7633},
{x:41.6963,y:-93.4531},
{x:46.1611,y:-123.9007},
{x:40.6276,y:-91.3338},
{x:38.867,y:-77.0176},
{x:41.2397,y:-85.8527},
{x:38.9095,y:-77.0454},
{x:34.1228,y:-87.9915},
{x:41.4323,y:-82.119},
{x:27.7134,y:-82.3765},
{x:39.7406,y:-75.5494},
{x:32.095,y:-81.1449},
{x:40.5787,y:-98.3921},
{x:37.7819,y:-122.4459},
{x:40.0667,y:-79.8903},
{x:42.1461,y:-80.0005},
{x:29.8511,y:-95.5044},
{x:43.31,y:-70.5847},
{x:36.5336,y:-87.3526},
{x:40.4043,y:-104.6958},
{x:36.7323,y:-95.9347},
{x:42.5601,y:-84.836},
{x:34.2399,y:-118.5263},
{x:41.6663,y:-91.3461},
{x:40.767,y:-83.823},
{x:26.0999,y:-97.166},
{x:29.5275,y:-95.1309},
{x:29.1381,y:-95.6538},
{x:40.663,y:-111.5001},
{x:43.0881,y:-70.7292},
{x:44.2251,y:-86.3131},
{x:33.9988,y:-81.0151},
{x:28.0313,y:-82.3541},
{x:32.0872,y:-96.4716},
{x:32.4151,y:-96.8417},
{x:28.0119,y:-81.9567},
{x:41.992,y:-87.678},
{x:29.9844,y:-91.8215},
{x:34.92,y:-95.7427},
{x:38.1699,y:-81.3034},
{x:36.3013,y:-88.3093},
{x:32.5389,y:-114.7915},
{x:32.5967,y:-114.7165},
{x:38.8969,y:-77.1259},
{x:35.1132,y:-78.8815},
{x:40.6806,y:-111.9369},
{x:43.385,y:-98.8524},
{x:34.4296,y:-119.8671},
{x:33.3354,y:-111.9073},
{x:38.7053,y:-77.1433},
{x:41.9734,y:-87.7481},
{x:41.3699,y:-81.8033},
{x:26.1679,y:-80.2542},
{x:36.8446,y:-81.5016},
{x:41.6031,y:-87.7671},
{x:38.5714,y:-94.8569},
{x:35.7723,y:-95.3386},
{x:43.6649,y:-116.2801},
{x:25.9856,y:-80.1546},
{x:39.1099,y:-93.21},
{x:35.3459,y:-97.4959},
{x:34.6229,y:-98.418},
{x:41.1688,y:-82.2121},
{x:43.5342,y:-71.4636},
{x:26.1433,y:-80.2043},
{x:26.2234,y:-98.2807},
{x:45.6703,y:-122.6633},
{x:42.4692,y:-93.8157},
{x:42.0226,y:-93.6139},
{x:41.625,y:-93.5526},
{x:31.2199,y:-85.3614},
{x:26.1868,y:-80.1871},
{x:41.5722,y:-87.5584},
{x:38.1699,y:-86.9897},
{x:30.6918,y:-88.0417},
{x:28.6465,y:-81.2651},
{x:28.5961,y:-81.3972},
{x:26.2226,y:-80.2039},
{x:33.4133,y:-111.8733},
{x:36.1598,y:-95.7602},
{x:44.9156,y:-91.3893},
{x:35.8998,y:-78.8969},
{x:41.5504,y:-87.1405},
{x:30.0694,y:-95.2122},
{x:33.0106,y:-96.9685},
{x:39.2904,y:-83.9972},
{x:41.8711,y:-72.66},
{x:35.1598,y:-101.8999},
{x:39.7129,y:-94.8716},
{x:40.3506,y:-94.8731},
{x:39.7881,y:-86.2139},
{x:34.1095,y:-117.5711},
{x:33.865,y:-117.543},
{x:45.0048,y:-93.1654},
{x:36.3324,y:-78.4346},
{x:41.538,y:-83.6369},
{x:42.1738,y:-72.5766},
{x:41.4496,y:-83.362},
{x:32.9353,y:-97.2343},
{x:33.7208,y:-116.3657},
{x:33.8163,y:-116.5286},
{x:36.0624,y:-95.7969},
{x:33.9916,y:-81.3073},
{x:33.7242,y:-84.1654},
{x:41.8027,y:-87.6072},
{x:38.3127,y:-88.9401},
{x:39.0073,y:-84.6231},
{x:33.4691,y:-82.0673},
{x:42.6748,y:-82.759},
{x:35.9683,y:-77.8098},
{x:40.2815,y:-86.0417},
{x:41.9715,y:-87.8374},
{x:38.81,y:-90.8622},
{x:47.6791,y:-117.282},
{x:26.2078,y:-80.2355},
{x:44.5233,y:-88.1024},
{x:29.7834,y:-95.5272},
{x:41.1382,y:-104.7897},
{x:39.5521,y:-89.2972},
{x:40.9674,y:-90.3825},
{x:43.085,y:-88.4734},
{x:40.19,y:-88.4053},
{x:40.4878,y:-78.3996},
{x:40.4428,y:-80.0},
{x:40.6672,y:-89.5791},
{x:43.9688,y:-75.8793},
{x:42.6704,y:-83.2349},
{x:40.0693,y:-88.2494},
{x:42.3219,y:-85.5238},
{x:41.8494,y:-88.3423},
{x:40.9081,y:-89.4923},
{x:40.7035,y:-89.4183},
{x:36.7358,y:-119.7278},
{x:35.4357,y:-97.439},
{x:26.3176,y:-80.1273},
{x:40.455,y:-88.1077},
{x:40.0025,y:-90.4372},
{x:41.1651,y:-81.4769},
{x:41.4713,y:-82.1727},
{x:41.1002,y:-81.437},
{x:29.7718,y:-95.2151},
{x:45.1087,y:-93.2996},
{x:35.1927,y:-101.799},
{x:35.9158,y:-82.2814},
{x:36.1143,y:-83.4934},
{x:39.4952,y:-76.3261},
{x:38.7323,y:-120.7823},
{x:40.5619,y:-79.7567},
{x:40.4005,y:-91.3877},
{x:32.7868,y:-117.0975},
{x:29.5719,y:-95.1105},
{x:42.6839,y:-84.4318},
{x:44.4534,y:-89.5426},
{x:39.6238,y:-86.1191},
{x:42.1113,y:-71.662},
{x:34.6218,y:-82.4712},
{x:38.5195,y:-77.3496},
{x:28.3605,y:-80.7006},
{x:31.8809,y:-102.3844},
{x:41.6203,y:-85.9009},
{x:41.6629,y:-86.0817},
{x:41.6747,y:-87.8158},
{x:42.026,y:-88.116},
{x:44.3044,y:-90.8453},
{x:44.2963,y:-90.8205},
{x:41.1814,y:-83.4209},
{x:40.6716,y:-80.5852},
{x:28.6628,y:-81.3592},
{x:28.4712,y:-80.7675},
{x:39.2965,y:-77.8286},
{x:41.2961,y:-92.6545},
{x:39.1718,y:-91.8894},
{x:35.6242,y:-118.4735},
{x:44.0316,y:-92.4794},
{x:27.6662,y:-97.2807},
{x:36.4892,y:-86.8792},
{x:32.9232,y:-97.0198},
{x:37.1785,y:-94.3242},
{x:39.8006,y:-89.6053},
{x:28.539,y:-81.2806},
{x:40.0997,y:-88.2443},
{x:41.1623,y:-87.8499},
{x:39.4725,y:-87.3705},
{x:37.8391,y:-85.9368},
{x:34.0168,y:-84.3101},
{x:43.8461,y:-91.2486},
{x:37.1146,y:-76.465},
{x:28.443,y:-81.403},
{x:32.9499,y:-96.7362},
{x:34.5696,y:-83.3054},
{x:42.2561,y:-83.6277},
{x:39.7213,y:-91.3992},
{x:36.2047,y:-121.1309},
{x:45.1635,y:-93.2661},
{x:37.3409,y:-92.9262},
{x:33.9609,y:-80.9426},
{x:35.4857,y:-86.4357},
{x:35.356,y:-96.9105},
{x:33.9542,y:-80.3799},
{x:32.8121,y:-96.6343},
{x:37.9675,y:-84.5023},
{x:28.461,y:-81.458},
{x:32.8438,y:-85.1805},
{x:40.6898,y:-73.6851},
{x:40.4058,y:-120.6343},
{x:39.9411,y:-82.8294},
{x:40.7593,y:-86.3702},
{x:41.641,y:-70.995},
{x:30.772,y:-85.684},
{x:39.846,y:-88.9349},
{x:33.563,y:-101.9199},
{x:61.3302,y:-149.5691},
{x:61.139,y:-149.9512},
{x:38.0726,y:-78.9016},
{x:26.0931,y:-80.1552},
{x:29.6888,y:-95.4132},
{x:31.5515,y:-84.1365},
{x:27.9162,y:-82.7597},
{x:33.8606,y:-118.1675},
{x:29.8031,y:-95.4832},
{x:27.4498,y:-82.548},
{x:36.6343,y:-95.1439},
{x:37.4737,y:-105.8903},
{x:37.3336,y:-119.6497},
{x:39.0658,y:-93.7326},
{x:44.2948,y:-93.3017},
{x:33.8881,y:-117.5199},
{x:41.9132,y:-87.6529},
{x:22.0628,y:-159.3213},
{x:41.9217,y:-88.2783},
{x:41.5211,y:-88.2059},
{x:42.5686,y:-88.1038},
{x:21.4219,y:-157.8029},
{x:37.7456,y:-89.0795},
{x:39.4219,y:-92.4334},
{x:37.5674,y:-77.4448},
{x:26.0492,y:-80.1432},
{x:30.0544,y:-99.1488},
{x:31.5947,y:-81.8697},
{x:31.7749,y:-106.474},
{x:37.6795,y:-120.9588},
{x:44.1167,y:-69.1064},
{x:38.1924,y:-85.8048},
{x:41.7482,y:-87.7797},
{x:41.928,y:-87.7862},
{x:29.9424,y:-82.1113},
{x:32.9241,y:-96.8827},
{x:38.2501,y:-122.6288},
{x:39.3169,y:-76.6536},
{x:41.0585,y:-83.6641},
{x:47.6539,y:-117.4336},
{x:44.0312,y:-69.5259},
{x:31.2333,y:-84.1986},
{x:28.303,y:-82.7035},
{x:35.0287,y:-106.6823},
{x:27.7208,y:-97.3989},
{x:33.9314,y:-84.2245},
{x:39.8966,y:-80.1699},
{x:30.9042,y:-84.5632},
{x:41.7566,y:-88.3162},
{x:41.6323,y:-80.1548},
{x:40.7,y:-99.071},
{x:19.7215,y:-155.0855},
{x:38.8894,y:-76.8716},
{x:45.1363,y:-90.3326},
{x:47.7829,y:-122.3431},
{x:38.1985,y:-85.8371},
{x:27.9819,y:-82.3734},
{x:40.4571,y:-88.3751},
{x:40.5881,y:-75.5611},
{x:42.0044,y:-87.9608},
{x:29.8212,y:-95.4096},
{x:38.0653,y:-97.8989},
{x:42.0948,y:-83.6766},
{x:38.2588,y:-85.6031},
{x:26.8904,y:-82.0263},
{x:42.2486,y:-88.3224},
{x:44.7994,y:-68.8106},
{x:30.335,y:-95.097},
{x:34.7403,y:-77.9765},
{x:46.2999,y:-119.2739},
{x:32.5944,y:-83.632},
{x:47.673,y:-117.3963},
{x:47.6756,y:-117.4284},
{x:41.8853,y:-87.6259},
{x:31.4639,y:-100.4438},
{x:31.4247,y:-100.4642},
{x:35.9916,y:-96.1132},
{x:40.4533,y:-122.2908},
{x:29.5682,y:-97.9448},
{x:40.2118,y:-76.962},
{x:33.6485,y:-117.5764},
{x:42.4609,y:-92.3566},
{x:36.1256,y:-115.1376},
{x:39.6171,y:-86.3815},
{x:29.9554,y:-89.9835},
{x:29.0618,y:-82.4547},
{x:41.1575,y:-81.2389},
{x:43.2194,y:-85.5683},
{x:36.8539,y:-94.3841},
{x:36.2923,y:-95.3231},
{x:36.0963,y:-75.7217},
{x:29.5974,y:-95.5272},
{x:36.9133,y:-93.915},
{x:41.7417,y:-80.7701},
{x:21.2749,y:-157.7856},
{x:21.3482,y:-157.7216},
{x:44.3224,y:-69.7648},
{x:31.7732,y:-106.3695},
{x:41.8777,y:-87.6352},
{x:41.5724,y:-81.5257},
{x:41.8853,y:-87.6339},
{x:41.8425,y:-79.1452},
{x:41.3069,y:-93.1098},
{x:41.408,y:-92.92},
{x:31.8767,y:-85.1508},
{x:37.6492,y:-97.3545},
{x:32.9314,y:-96.6491},
{x:41.5858,y:-93.6591},
{x:39.28,y:-89.8819},
{x:34.4408,y:-119.8269},
{x:41.0314,y:-81.4278},
{x:29.9509,y:-95.3483},
{x:29.6512,y:-95.5673},
{x:38.7721,y:-85.3872},
{x:43.5383,y:-96.7318},
{x:37.7463,y:-84.2948},
{x:44.1253,y:-70.201},
{x:43.6891,y:-70.294},
{x:41.8367,y:-96.7107},
{x:41.7471,y:-93.6178},
{x:41.889,y:-87.779},
{x:27.939,y:-81.973},
{x:42.4665,y:-92.3198},
{x:42.2161,y:-79.834},
{x:31.8357,y:-106.5489},
{x:33.102,y:-96.6596},
{x:42.2883,y:-72.4068},
{x:41.4787,y:-87.6382},
{x:28.3442,y:-81.3653},
{x:28.6565,y:-81.4199},
{x:34.1732,y:-84.7879},
{x:34.2463,y:-84.0198},
{x:40.5899,y:-75.4602},
{x:34.0132,y:-84.4932},
{x:41.3697,y:-81.5129},
{x:44.3883,y:-71.1728},
{x:41.699,y:-93.0407},
{x:59.6444,y:-151.5243},
{x:46.8039,y:-92.0711},
{x:31.2029,y:-82.3315},
{x:33.4466,y:-86.825},
{x:27.8389,y:-82.6977},
{x:31.9025,y:-106.4226},
{x:36.0624,y:-95.8813},
{x:29.5089,y:-95.1877},
{x:41.6779,y:-83.5986},
{x:45.1964,y:-93.2342},
{x:36.8671,y:-83.8872},
{x:41.4085,y:-81.9389},
{x:42.187,y:-86.3065},
{x:41.511,y:-87.8481},
{x:40.8108,y:-79.5178},
{x:40.065,y:-83.0203},
{x:45.3627,y:-68.5161},
{x:45.3803,y:-92.8729},
{x:44.8036,y:-68.7663},
{x:39.8671,y:-84.2904},
{x:38.0866,y:-81.8385},
{x:35.8883,y:-94.9796},
{x:35.5109,y:-84.3658},
{x:13.495,y:144.7939},
{x:43.0352,y:-89.411},
{x:44.2056,y:-88.4459},
{x:44.23,y:-88.4285},
{x:42.7405,y:-84.739},
{x:43.9813,y:-84.4905},
{x:41.5041,y:-72.9014},
{x:37.6791,y:-96.9785},
{x:41.0553,y:-73.5384},
{x:29.7489,y:-94.9471},
{x:35.4594,y:-86.0565},
{x:35.2223,y:-101.8173},
{x:32.7453,y:-97.2216},
{x:38.3469,y:-82.9438},
{x:41.8299,y:-72.7342},
{x:38.8624,y:-77.089},
{x:45.6652,y:-108.7707},
{x:36.7224,y:-88.6282},
{x:38.3958,y:-82.5554},
{x:30.2221,y:-93.376},
{x:36.0811,y:-86.9481},
{x:40.7565,y:-86.0716},
{x:41.0716,y:-73.5488},
{x:41.5832,y:-81.4822},
{x:41.5004,y:-81.6826},
{x:26.2499,y:-98.2215},
{x:36.0189,y:-95.7967},
{x:37.0451,y:-122.0283},
{x:41.8252,y:-103.6605},
{x:44.0924,y:-123.0935},
{x:45.4862,y:-122.7483},
{x:37.0937,y:-85.3044},
{x:30.0201,y:-95.3808},
{x:29.0482,y:-81.3045},
{x:28.58,y:-81.4764},
{x:43.6338,y:-95.5943},
{x:45.0519,y:-93.1482},
{x:42.6783,y:-91.915},
{x:42.1967,y:-87.9654},
{x:42.7479,y:-84.409},
{x:36.1479,y:-81.1942},
{x:30.3705,y:-97.7574},
{x:37.438,y:-79.1222},
{x:43.5132,y:-96.7307},
{x:41.273,y:-82.8381},
{x:40.4886,y:-88.9936},
{x:39.8217,y:-88.9581},
{x:41.7211,y:-87.706},
{x:38.2059,y:-84.2726},
{x:33.9905,y:-80.9774},
{x:42.0161,y:-92.9133},
{x:40.8021,y:-72.8641},
{x:40.8473,y:-84.3413},
{x:41.0265,y:-84.0462},
{x:41.8758,y:-80.7671},
{x:39.6009,y:-88.614},
{x:41.0237,y:-80.6295},
{x:43.8554,y:-92.4884},
{x:40.9668,y:-91.5543},
{x:34.4411,y:-92.191},
{x:39.2755,y:-81.5994},
{x:38.7729,y:-77.1832},
{x:33.845,y:-83.9089},
{x:33.8756,y:-84.3811},
{x:33.6392,y:-112.0648},
{x:36.4492,y:-83.5716},
{x:29.6961,y:-95.1233},
{x:47.9216,y:-122.0975},
{x:33.1837,y:-86.2475},
{x:38.7941,y:-90.3411},
{x:34.5854,y:-86.9808},
{x:32.7092,y:-97.0089},
{x:32.8159,y:-96.7706},
{x:43.547,y:-96.7121},
{x:29.8942,y:-90.0993},
{x:41.2623,y:-95.8845},
{x:39.0828,y:-76.9472},
{x:40.5164,y:-80.2248},
{x:40.3687,y:-80.641},
{x:33.9159,y:-118.3271},
{x:39.3874,y:-89.0804},
{x:43.3924,y:-89.4598},
{x:47.0389,y:-122.9403},
{x:41.6676,y:-72.6417},
{x:42.9915,y:-70.9281},
{x:39.2043,y:-83.6105},
{x:13.4735,y:144.789},
{x:29.9142,y:-90.0478},
{x:36.7265,y:-84.164},
{x:38.4755,y:-107.8733},
{x:29.8891,y:-95.0619},
{x:34.4674,y:-85.6994},
{x:41.2456,y:-80.8447},
{x:25.4431,y:-80.4745},
{x:44.5006,y:-103.8589},
{x:42.6997,y:-73.1143},
{x:37.1189,y:-82.7943},
{x:37.1413,y:-83.7724},
{x:45.518,y:-122.5629},
{x:41.1875,y:-80.972},
{x:41.61,y:-81.4696},
{x:37.827,y:-85.4689},
{x:21.3898,y:-157.9522},
{x:31.8531,y:-86.6406},
{x:41.1937,y:-73.2064},
{x:41.1038,y:-73.4035},
{x:28.6,y:-80.8071},
{x:38.8738,y:-77.2414},
{x:28.019,y:-82.7668},
{x:34.188,y:-118.5921},
{x:41.3053,y:-72.9345},
{x:34.2161,y:-77.8853},
{x:38.4968,y:-121.4348},
{x:39.0801,y:-77.0724},
{x:38.8243,y:-76.8904},
{x:25.7008,y:-80.3497},
{x:38.9387,y:-77.3304},
{x:39.0606,y:-77.0487},
{x:38.8286,y:-77.0923},
{x:25.8965,y:-80.1618},
{x:30.3247,y:-81.4017},
{x:30.2885,y:-81.4004},
{x:38.7213,y:-77.1259},
{x:37.975,y:-87.4921},
{x:34.9023,y:-82.3353},
{x:37.3365,y:-121.8769},
{x:38.2458,y:-77.5151},
{x:28.762,y:-81.284},
{x:38.2277,y:-85.5765},
{x:28.9,y:-82.5896},
{x:40.4771,y:-86.1095},
{x:39.166,y:-76.623},
{x:28.8076,y:-82.5775},
{x:34.1735,y:-118.3168},
{x:38.017,y:-87.5746},
{x:39.798,y:-86.2598},
{x:32.2898,y:-90.2712},
{x:26.0614,y:-80.207},
{x:37.9906,y:-121.3127},
{x:27.9382,y:-82.2854},
{x:35.7841,y:-78.7205},
{x:38.596,y:-121.4104},
{x:41.3639,y:-71.815},
{x:29.8635,y:-95.4749},
{x:38.7534,y:-77.1404},
{x:41.356,y:-72.1152},
{x:36.7367,y:-119.6977},
{x:47.6894,y:-117.364},
{x:25.8495,y:-80.1855},
{x:40.6273,y:-74.0757},
{x:38.7997,y:-77.322},
{x:30.6228,y:-96.3111},
{x:28.0291,y:-80.6225},
{x:41.2122,y:-79.3807},
{x:39.532,y:-111.4634},
{x:35.9067,y:-79.0892},
{x:37.6145,y:-77.4986},
{x:40.6103,y:-75.3788},
{x:36.0995,y:-115.1203},
{x:39.7579,y:-86.4354},
{x:37.9697,y:-87.5341},
{x:34.6733,y:-118.1524},
{x:39.9584,y:-83.0926},
{x:30.35,y:-81.4143},
{x:41.3903,y:-73.5023},
{x:30.0625,y:-81.8785},
{x:41.4773,y:-73.4086},
{x:41.5065,y:-87.6717},
{x:30.489,y:-87.1957},
{x:36.3149,y:-119.3144},
{x:40.7257,y:-111.8887},
{x:41.2776,y:-72.5405},
{x:36.0503,y:-79.835},
{x:26.2414,y:-80.2443},
{x:35.3643,y:-119.0384},
{x:34.1922,y:-118.3961},
{x:38.3127,y:-77.4526},
{x:30.5826,y:-96.2882},
{x:36.2407,y:-115.0631},
{x:33.0615,y:-111.4841},
{x:34.1468,y:-118.2546},
{x:38.6185,y:-121.4027},
{x:38.234,y:-85.6347},
{x:38.5877,y:-121.3066},
{x:27.5185,y:-82.5733},
{x:29.1779,y:-82.1401},
{x:35.0788,y:-92.4635},
{x:26.6514,y:-80.0905},
{x:37.117,y:-113.5966},
{x:37.4435,y:-77.5788},
{x:33.5652,y:-112.2529},
{x:41.0486,y:-73.565},
{x:34.03,y:-81.0327},
{x:30.1749,y:-85.6654},
{x:30.2294,y:-85.6486},
{x:38.761,y:-77.4616}];