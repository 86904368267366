import apiClient from 'utils/apiClient';

const baseUrl = 'v2/schools';

const getSchools = (params = {}) => apiClient.get(baseUrl, params);

const getSchoolbyId = (schoolId) =>
  apiClient.get(`${baseUrl}/${schoolId}`, {
    params: { for_dashboard: true },
  });

const getSchoolResource = ({ schoolId, type, params = null }) =>
  apiClient.get(
    `${baseUrl}/${schoolId}/${type}?dashboard_mode=true${
      type === 'courses' ? '&include_user_generated=true' : ''
    }`,
    {
      params,
    },
  );

const createSchool = (schoolData) => apiClient.post(baseUrl, schoolData);

const updateSchool = (schoolId, schoolData) =>
  apiClient.put(`${baseUrl}/${schoolId}`, schoolData);

const enableFeature = (schoolId, feature) =>
  apiClient.post(`${baseUrl}/${schoolId}/features/${feature}`);

const disableFeature = (schoolId, feature) =>
  apiClient.delete(`${baseUrl}/${schoolId}/features/${feature}`);

const schoolsService = () => ({
  getSchools,
  getSchoolbyId,
  getSchoolResource,
  createSchool,
  updateSchool,
  enableFeature,
  disableFeature,
});

export default schoolsService;
