import React, { PureComponent } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';

import { networkActions } from 'store/actions/actions';
import { hasAccessTo } from 'store/selectors/auth';
import NetworkStatusModal from 'components/modals/NetworkStatusModal/NetworkStatusModal';

import MissingSchedulesTable from './MissingSchedulesTable/MissingSchedulesTable';

import './NetworkGrid.scss';

const WEEKEND = new Set(['Friday', 'Saturday']);

class NetworkGrid extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showStatusModal: false,
      data: [],
      activeTab:
        new URLSearchParams(this.props.location.search).get('tab') || undefined,
    };
    if (hasAccessTo(props.scopes, ['school_management'])) {
      props.dispatch(networkActions.getNetworkStats());
    }
  }

  renderTabCount(title, count) {
    return (
      <span>
        {title}&nbsp;
        {typeof count !== 'undefined' && count > 0 && (
          <span className="tab-count">{count}</span>
        )}
      </span>
    );
  }

  showStatusModal = (data, title) => {
    this.setState({ data, showStatusModal: true, title });
  };

  hideStatusModal = () => {
    this.setState({ showStatusModal: false });
  };

  renderStats() {
    const { stats, isOwner } = this.props;
    const nextSchoolDay =
      stats && WEEKEND.has(stats.server_weekday) ? 'Monday' : 'tomorrow';

    if (!isOwner) {
      return null;
    }

    return (
      <Row className="dash-stats dash-stats-top">
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(stats.open_schools, 'Schools open today')
          }
        >
          <span className="schools-count">
            {stats && stats.open_schools.length}
          </span>
          Schools open today
        </div>
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(stats.closed_today, 'Schools closed today')
          }
        >
          <span className="schools-count">
            {stats && stats.closed_today.length}
          </span>
          Schools closed today
        </div>
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(
              stats.closed_tomorrow,
              `Schools without data ${nextSchoolDay}`,
            )
          }
        >
          <span className="schools-count">
            {stats && stats.closed_tomorrow.length}
          </span>
          <span>Schools closed {nextSchoolDay}</span>
        </div>
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(stats.modified_today, 'Schools modified today')
          }
        >
          <span className="schools-count">
            {stats && stats.modified_today.length}
          </span>
          Schools modified today
        </div>
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(
              stats.modified_tomorrow,
              `Schools modified ${nextSchoolDay}`,
            )
          }
        >
          <span className="schools-count">
            {stats && stats.modified_tomorrow.length}
          </span>
          <span>Schools modified {nextSchoolDay}</span>
        </div>
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(
              stats.no_data_today,
              'Schools without data today',
            )
          }
        >
          <span className="schools-count">
            {stats && stats.no_data_today.length}
          </span>
          Schools without data today
        </div>
        <div
          className="stat-counter"
          onClick={() =>
            this.showStatusModal(
              stats.no_data_tomorrow,
              `Schools without data ${nextSchoolDay}`,
            )
          }
        >
          <span className="schools-count">
            {stats && stats.no_data_tomorrow.length}
          </span>
          <span>Schools without data {nextSchoolDay}</span>
        </div>
      </Row>
    );
  }

  render() {
    const { scopes } = this.props;

    return (
      <div className="network-grid-container">
        <NetworkStatusModal
          data={this.state.data}
          show={this.state.showStatusModal}
          hide={this.hideStatusModal}
          title={this.state.title}
        />
        {this.renderStats()}

        <div className="network-grid-table-container">
          <MissingSchedulesTable />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stats: state.network.stats,
  missing_schedules_count: state.network.missing_schedules.length,
  missing_schedule_names_count: state.network.missing_schedule_names.length,
  events_count: state.network.total_events,
  isOwner: hasAccessTo(state.auth.scopes, ['owner']),
  scopes: state.auth.scopes,
});

export default connect(mapStateToProps)(NetworkGrid);
