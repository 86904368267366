import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import StepContainer from 'components/shared/Modal/StepContainer/StepContainer';
import schoolsService from 'services/schools.service';
import './EditSchoolModal.scss';
import { SchoolDetailsStep, SchoolLocationStep } from './Steps/EditSchoolSteps';
import { schoolsActions } from 'store/actions/actions';

const EditSchoolModal = ({ school, hide }) => {
  const dispatch = useDispatch();

  const modalData = {
    name: 'School Setup',
    steps: [SchoolDetailsStep(school), SchoolLocationStep(school)],
    onSubmit: (form) => {
      if (form.school_title && form.domains) {
        return schoolsService()
          .updateSchool(school.school_name, {
            ...form,
            school_name: school.school_name,
            alphabetic_lunch_waves: school.alphabetic_lunch_waves,
            chat_minimum_depth: form.chat_minimum_depth / 100,
            lite_level: form.lite_level,
          })
          .then((result) => {
            // TODO doesn't look like this causes the refresh we expect it to
            dispatch(schoolsActions.getSchoolById(school.school_name));
            return result;
          });
      } else {
        return new Promise((resolve) => resolve(null));
      }
    },
    getSuccessText: (response) => `Updated ${response.data.school_title}`,
    getErrorText: () => 'An error occurred when updating the school',
  };

  return (
    <Modal show className="create-school" onHide={hide}>
      <StepContainer modalData={modalData} close={hide} />
    </Modal>
  );
};

export default EditSchoolModal;
