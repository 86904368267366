import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { getLength, parseTime } from 'features/editSchedule/utils';
import type { Period } from 'types';

interface Props {
  periods: Period[];
  isExpanded: boolean;
}

const PeriodsReviewTable = ({ periods, isExpanded }: Props) => (
  <M.Flex direction="column">
    <M.Text align="center">
      <strong>Periods</strong>
    </M.Text>
    <M.Table
      sx={
        isExpanded
          ? { minWidth: '50%', width: 'fit-content', margin: '0 auto' }
          : undefined
      }
    >
      <tbody>
        {periods.map((p, i) => (
          <Row key={i} isCustom={!p.dayType?.id}>
            <td>{p.dayType?.id ? p.dayType.name : '-'}</td>
            <td>
              <strong>{p.name}</strong>
            </td>
            <td>{p.startTime}</td>
            <td>{p.endTime}</td>
            <td>
              ({getLength([parseTime(p.startTime), parseTime(p.endTime)])} min)
            </td>
          </Row>
        ))}
      </tbody>
    </M.Table>
  </M.Flex>
);

const Row = styled.tr<{ isCustom: boolean }>`
  background-color: ${(p) =>
    p.isCustom ? p.theme.colors.yellow[1] : 'transparent'};
`;

export default PeriodsReviewTable;
