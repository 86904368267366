import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'mantine-react-table';
import { IconEdit } from '@tabler/icons-react';

import { statuses } from 'constants/statuses';
import { timezoneAbbreviations } from 'constants/timezoneAbbreviations';
import { Table } from 'features/components/Table';

import type { SchoolStatus } from 'types/openApi/SchoolStatus';

import { SchoolQueryFilterKey, useSchoolsFiltered } from './queries';
import { useDisclosure } from '@mantine/hooks';
import { EditSchoolModal } from './EditSchoolModal';
import { Link } from 'react-router-dom';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import type { ApiTypes } from 'types';

const columns: MRT_ColumnDef<
  CamelCasedPropertiesDeep<ApiTypes['SchoolViewExtra']>
>[] = [
  {
    accessorKey: 'schoolName',
    header: 'ID',
    size: 150,
    Cell: ({ cell }) => (
      <Link to={`/schools/${cell.getValue<string>()}`}>
        <M.Anchor variant="blue">{cell.getValue()}</M.Anchor>
      </Link>
    ),
  },
  {
    accessorKey: 'schoolTitle',
    header: 'Name',
    size: 150,
  },
  {
    accessorKey: 'districtCode',
    header: 'District ID',
    size: 150,
  },
  {
    accessorKey: 'meta.userCount',
    header: 'Installs',
    size: 50,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableSorting: false,
    enableColumnFilter: true,
    enableColumnActions: true,
    columnFilterModeOptions: ['contains'],
    filterVariant: 'select',
    mantineFilterSelectProps: {
      data: statuses.map((s) => ({ value: s.id, label: s.name })),
    },
    size: 50,
    Cell: ({ cell }) => {
      const status = cell.getValue<SchoolStatus>();

      if (status === 'live') {
        return (
          <M.Badge color="green" fullWidth>
            Live
          </M.Badge>
        );
      }
      if (status === 'waitlist') {
        return (
          <M.Badge color="yellow" fullWidth>
            Waitlist
          </M.Badge>
        );
      }
      return <M.Badge color="red">Suspended</M.Badge>;
    },
  },
  {
    accessorKey: 'timezone',
    header: 'Timezone',
    enableSorting: false,
    size: 50,
    Cell: ({ cell }) =>
      timezoneAbbreviations[
        cell.getValue<keyof typeof timezoneAbbreviations>()
      ],
  },
  {
    accessorKey: 'domains',
    header: 'Domains',
    size: 300,
    enableSorting: false,
    Cell: ({ cell }) => (
      <M.Flex wrap="wrap" gap="xs">
        {cell.getValue<string[]>().map((domain) => (
          <M.Badge
            key={domain}
            color={domain === 'joinsaturn.com' ? 'violet' : 'blue'}
          >
            {domain}
          </M.Badge>
        ))}
      </M.Flex>
    ),
  },
];

const SORT_COLUMN_MAP: {
  [key in NonNullable<
    (typeof columns)[number]['accessorKey']
  >]: SchoolQueryFilterKey;
} = {
  schoolName: 'school_name',
  schoolTitle: 'school_title',
  districtCode: 'district_code',
  'meta.userCount': 'user_count',
};

const SchoolsPage = () => {
  // TODO: consider abstracting into custom hook
  const [globalSearch, setGlobalSearch] = useState('');
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    { id: 'status', value: 'live' },
  ]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isFetching, isInitialLoading } = useSchoolsFiltered({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filter_by: columnFilters.length
      ? `status:eq:${columnFilters[0].value as string}`
      : undefined,
    search: globalSearch || undefined,
    sort_by: sorting.length ? SORT_COLUMN_MAP[sorting[0].id] : undefined,
    order_by: sorting.length ? (sorting[0].desc ? 'desc' : 'asc') : undefined,
  });

  const [isCreateModalOpen, createModalControls] = useDisclosure();

  const [isEditModalOpen, editModalControls] = useDisclosure();
  const [editedSchool, setEditedSchool] =
    useState<CamelCasedPropertiesDeep<ApiTypes['SchoolViewExtra']>>();

  return (
    <Container>
      <M.Title order={2}>
        {data?.schoolsTotal} <HeaderLabel>Schools</HeaderLabel>{' '}
        {data?.usersTotal} <HeaderLabel>Installs</HeaderLabel>
      </M.Title>
      <Table
        data={data?.schools}
        columns={columns}
        tableOptions={{
          state: {
            pagination,
            globalFilter: globalSearch,
            columnFilters: columnFilters,
            sorting,
            showProgressBars: isFetching && !isInitialLoading,
          },
          initialState: {
            showColumnFilters: true,
          },
          defaultColumn: {
            enableColumnFilter: false,
          },
          manualPagination: true,
          manualFiltering: true,
          manualSorting: true,
          rowCount: data?.schoolsTotal,
          onPaginationChange: setPagination,
          onGlobalFilterChange: setGlobalSearch,
          onColumnFiltersChange: setColumnFilters,
          onSortingChange: setSorting,
          renderTopToolbarCustomActions: () => (
            <M.Flex gap="sm">
              <M.Button onClick={createModalControls.open}>
                Create School
              </M.Button>
              <M.Button onClick={() => {}} color="gray">
                File upload
              </M.Button>
            </M.Flex>
          ),
          positionActionsColumn: 'last',
          enableRowActions: true,
          renderRowActions: ({ row }) => (
            <M.Flex gap="xs">
              <M.ActionIcon
                onClick={() => {
                  setEditedSchool(row.original);
                  editModalControls.open();
                }}
              >
                <IconEdit />
              </M.ActionIcon>
            </M.Flex>
          ),
        }}
      />

      {/* Create school modal */}
      <EditSchoolModal
        modalProps={{
          opened: isCreateModalOpen,
          onClose: () => {
            createModalControls.close();
          },
        }}
      />

      <EditSchoolModal
        modalProps={{
          opened: isEditModalOpen,
          onClose: () => {
            editModalControls.close();
          },
        }}
        schoolToEdit={editedSchool}
      />
    </Container>
  );
};

const Container = styled(M.Box)`
  padding: ${(p) => p.theme.spacing.xl};
`;

const HeaderLabel = styled.span`
  color: ${(p) => p.theme.colors.gray[6]};
`;

export default SchoolsPage;
