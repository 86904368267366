import { put, call, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../actions/schedules';
import {
  ActionTypes as SchoolActions,
  actions as schoolActions,
} from '../actions/schools';
import schedulesService from 'services/schedules.service';
import { getDateFormat } from 'utils/utilities';

function* getSchedulingSettings({ schoolId, school }) {
  try {
    const settings = yield call(
      schedulesService().getSchedulingSettings,
      schoolId,
    );
    school.day_types = settings.data.day_types;
    school.period_types = settings.data.period_types;
    school.lunch_slots = settings.data.lunch_slots;
    yield put({ type: SchoolActions.GET_SCHOOL_RESOURCE_SUCCESS, school });
  } catch (error) {
    yield put({ type: ActionTypes.GET_SCHEDULING_SETTINGS_FAIL, error });
  }
}

function* setDaySchedule({ school_id, day, scheduleId, scheduleName }) {
  const date = getDateFormat(day, 'YYYY-MM-DD');

  yield call(
    schedulesService().setSchedule,
    school_id,
    date,
    scheduleId,
    scheduleName,
  );
  yield put(schoolActions.getSchoolById(school_id));
}

export default [
  takeLatest(ActionTypes.GET_SCHEDULING_SETTINGS, getSchedulingSettings),
  takeLatest(ActionTypes.SET_DAY_SCHEDULE, setDaySchedule),
];
