import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

import { staticModificationTypes } from 'constants/scheduleInfo';
import { isWeekday } from 'utils/utilities';

import { WizardNavigation } from '../../common/WizardNavigation';

export const weekDays = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
];

const getYearOptions = (school_years) => {
  const formatter = (date) => moment(date, 'YYYY-MM-DD').format("'YY");
  const options = school_years
    .sort((a, b) => b.current - a.current)
    .map((year) => {
      return {
        value: year,
        label: `${formatter(year.start_date)} - ${formatter(year.end_date)} ${
          year.current ? '(Current)' : ''
        }`,
      };
    });

  return [...options, { value: 'custom', label: 'Custom range' }];
};

export const FieldWrapper = (fieldWrapperProps) => {
  return (
    <Form.Group>
      <Form.Label>{fieldWrapperProps.label}</Form.Label>
      <Controller
        name={fieldWrapperProps.name}
        control={fieldWrapperProps.control}
        defaultValue={null}
        rules={{ required: true, ...fieldWrapperProps.rules }}
        render={fieldWrapperProps.render}
      />
      {fieldWrapperProps.errorRender && fieldWrapperProps.errorRender()}
    </Form.Group>
  );
};

export const OptionsStep = (props) => {
  const yearOptions = getYearOptions(props.school.school_years);
  const selectedRange = yearOptions[0];
  let { start_date, end_date } = selectedRange.value;
  // TODO the props.form.start_date could be different if you arrived from
  // SaturnCalendar.js but it seems like that "regenFrom" is not being used
  // right now
  // start_date = props.form.start_date ? props.form.start_date : start_date;
  end_date = end_date ? moment(end_date, 'YYYY-MM-DD').toDate() : null;
  start_date = start_date
    ? moment(start_date, 'YYYY-MM-DD').toDate()
    : new Date();
  const rotationStartOptions = props.school.rotation_pattern.map(
    (item, index) => ({
      value: index,
      label: item,
    }),
  );

  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues: {
      ...props.form,
      date_range: selectedRange,
      start_date: props.form.start_date || start_date,
      end_date: props.form.end_date || end_date,
      locked_weekdays: props.form.locked_weekdays,
      rotation_start:
        props.form.rotation_start.value >= 0 ? props.form.rotation_start : null,
    },
    mode: 'onChange',
  });

  const watchStartDate = watch('start_date');

  const onSubmit = (data) => {
    props.updateForm(data);
  };

  useEffect(() => {
    if (
      props.school.schedule_type === 'monday_through_friday' &&
      watchStartDate
    ) {
      let weekday = moment(watchStartDate).weekday() - 1;

      if (weekday < 0 || weekday >= staticModificationTypes.length) {
        // FIXME this means the start date was a weekend so it doesn't make
        // sense, we should prompt to change the start date
        weekday = 0;
      }

      setValue('rotation_start', {
        value: weekday,
        label: staticModificationTypes[weekday].value,
      });
    }
  }, [watchStartDate]);

  const renderDatePicker = ({ field }) => {
    const value = field.value
      ? moment(field.value, 'MM-DD-YYYY').toDate()
      : null;
    return (
      <>
        <DatePicker
          {...field}
          selected={value}
          placeholderText="MM-DD-YYYY"
          filterDate={isWeekday}
          onChange={(date) => {
            field.onChange(date);
          }}
          dateFormat="MM-dd-yyyy"
          className="form-control"
          autoComplete="off"
        />
        {errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name].message}</div>
        )}
      </>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="wizard-form"
      autoComplete="name"
    >
      <h5>Step 1: Options</h5>
      <hr />

      <FieldWrapper
        label="School year"
        name="date_range"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            options={yearOptions}
            onChange={(selected) => {
              const { value } = selected;
              if (selected.value !== 'custom') {
                const start_date = moment(
                  value.start_date,
                  'YYYY-MM-DD',
                ).toDate();
                const end_date = moment(value.end_date, 'YYYY-MM-DD').toDate();
                setValue('start_date', start_date);
                setValue('end_date', end_date);
              } else {
                setValue('start_date', new Date());
                setValue('end_date', '');
              }
              field.onChange(selected);
            }}
            value={field.value}
          />
        )}
        errorRender={() => {
          return (
            <div className="invalid-feedback">
              {errors.date_range && 'Please select an option'}
            </div>
          );
        }}
      />

      <div className="multifield-row">
        <FieldWrapper
          control={control}
          label="Start Date"
          name="start_date"
          render={renderDatePicker}
          errorRender={() => {
            return (
              <div className="invalid-feedback">
                {errors.start_date && 'Please select a start date'}
              </div>
            );
          }}
        />
        <FieldWrapper
          control={control}
          label="End Date"
          name="end_date"
          render={renderDatePicker}
          errorRender={() => {
            return (
              <div className="invalid-feedback">
                {errors.end_date && 'Please select an end date'}
              </div>
            );
          }}
        />
      </div>

      <FieldWrapper
        control={control}
        label="Start of rotation"
        name="rotation_start"
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            {...field}
            options={rotationStartOptions}
            onChange={(selected) => {
              field.onChange(selected);
            }}
            value={field.value}
            isDisabled={props.school.schedule_type === 'monday_through_friday'}
          />
        )}
        errorRender={() =>
          errors.rotation_start && (
            <div className="invalid-feedback">
              Please select the rotation start
            </div>
          )
        }
      />

      <FieldWrapper
        control={control}
        label="Locked Days"
        name="locked_weekdays"
        rules={{ required: false }}
        render={({ field }) => (
          <Select
            {...field}
            isMulti
            options={weekDays}
            onChange={(selected) => {
              field.onChange(selected || []);
            }}
            value={field.value}
            isDisabled={props.school.schedule_type === 'monday_through_friday'}
          />
        )}
      />
      <hr />
      <WizardNavigation
        step={1}
        {...props}
        nextStep={() => {
          if (isValid) {
            props.nextStep();
          }
        }}
      />
    </form>
  );
};
