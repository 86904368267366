export default [{x:42.871739,y:-73.774136},
{x:42.559575,y:-70.931239},
{x:40.806919,y:-73.07295},
{x:40.752378,y:-74.207983},
{x:42.139105,y:-71.148928},
{x:42.084421,y:-71.017414},
{x:42.483084,y:-71.020118},
{x:39.953181,y:-75.164679},
{x:39.938239,y:-75.198408},
{x:43.197972,y:-71.530842},
{x:42.360222,y:-71.202397},
{x:42.395836,y:-71.052328},
{x:42.369062,y:-71.039333},
{x:40.968672,y:-73.713642},
{x:26.794429,y:-80.054492},
{x:41.854031,y:-71.400507},
{x:41.781744,y:-71.440185},
{x:42.004717,y:-71.498142},
{x:42.3892,y:-71.119597},
{x:42.770011,y:-71.491821},
{x:42.252772,y:-70.993044},
{x:42.681106,y:-72.048297},
{x:42.319078,y:-71.209992},
{x:26.882031,y:-80.059275},
{x:41.331862,y:-72.974466},
{x:41.613661,y:-70.931381},
{x:44.794766,y:-68.774891},
{x:43.199631,y:-70.875508},
{x:41.7355,y:-71.434767},
{x:42.467311,y:-71.178194},
{x:43.524011,y:-71.467402},
{x:41.363606,y:-72.923389},
{x:41.239047,y:-73.035461},
{x:41.819544,y:-71.370906},
{x:41.46869,y:-73.023725},
{x:41.371905,y:-72.072075},
{x:41.814556,y:-71.421205},
{x:41.861169,y:-71.354269},
{x:40.117642,y:-75.136169},
{x:42.458022,y:-70.949712},
{x:41.90635,y:-71.050114},
{x:42.356017,y:-71.149212},
{x:41.700262,y:-71.145276},
{x:41.738017,y:-72.667604},
{x:42.293589,y:-71.048325},
{x:42.460386,y:-70.968428},
{x:41.662764,y:-70.942553},
{x:42.559136,y:-70.88225},
{x:42.526892,y:-70.926889},
{x:41.52475,y:-71.297533},
{x:40.924333,y:-74.103636},
{x:42.274612,y:-71.750028},
{x:41.610986,y:-72.875903},
{x:41.828055,y:-73.110093},
{x:41.148506,y:-73.245258},
{x:41.286369,y:-72.817094},
{x:41.561778,y:-73.054078},
{x:41.296328,y:-72.951217},
{x:41.199067,y:-73.129694},
{x:42.520343,y:-70.895585},
{x:39.0388,y:-77.05127},
{x:40.679139,y:-73.864704},
{x:44.921323,y:-73.112207},
{x:41.752041,y:-88.293985},
{x:42.665352,y:-73.790543},
{x:42.282417,y:-71.414966},
{x:39.807434,y:-74.930766},
{x:40.219663,y:-74.761005},
{x:43.598753,y:-72.969878},
{x:39.436392,y:-76.624164},
{x:40.131944,y:-74.88415},
{x:40.209157,y:-74.027183},
{x:41.502372,y:-74.039397},
{x:41.039322,y:-73.596356},
{x:39.350983,y:-76.562191},
{x:43.317575,y:-76.412344},
{x:40.846063,y:-74.228653},
{x:40.293736,y:-76.827653},
{x:44.459844,y:-73.207892},
{x:39.352756,y:-76.756103},
{x:41.685469,y:-87.505186},
{x:42.349561,y:-71.079929},
{x:43.007458,y:-71.457461},
{x:40.364253,y:-75.926724},
{x:42.932606,y:-72.285729},
{x:40.585301,y:-74.292748},
{x:39.731242,y:-75.58215},
{x:39.866897,y:-75.376269},
{x:40.704356,y:-74.293864},
{x:43.074622,y:-73.787551},
{x:41.305365,y:-72.926059},
{x:40.876836,y:-74.141653},
{x:40.658311,y:-73.695194},
{x:40.72989,y:-73.74523},
{x:40.76537,y:-73.81981},
{x:40.844806,y:-73.276264},
{x:40.79891,y:-73.66323},
{x:26.562314,y:-81.953994},
{x:42.685554,y:-73.796909},
{x:42.0948,y:-76.00445},
{x:41.743101,y:-72.716136},
{x:42.799064,y:-73.9256},
{x:42.092446,y:-76.800161},
{x:43.194472,y:-77.610856},
{x:40.190272,y:-74.710872},
{x:40.936103,y:-73.746717},
{x:40.677136,y:-74.286147},
{x:40.887057,y:-74.064723},
{x:39.267403,y:-76.497646},
{x:39.923594,y:-75.245158},
{x:42.613174,y:-70.660418},
{x:42.366275,y:-71.181797},
{x:39.770023,y:-75.501981},
{x:42.448561,y:-73.250869},
{x:39.721101,y:-75.663186},
{x:42.179797,y:-72.578114},
{x:41.870933,y:-71.413525},
{x:41.721033,y:-72.667883},
{x:40.887594,y:-74.527872},
{x:40.607687,y:-75.491725},
{x:42.1188,y:-72.743731},
{x:41.823292,y:-71.498639},
{x:41.985629,y:-87.69909},
{x:41.965019,y:-87.656758},
{x:41.810856,y:-71.359217},
{x:41.990792,y:-87.679194},
{x:40.030932,y:-75.054527},
{x:39.9719,y:-75.31117},
{x:39.273088,y:-76.728024},
{x:39.901656,y:-75.077019},
{x:41.850462,y:-87.788667},
{x:41.792337,y:-87.790757},
{x:41.968722,y:-87.672958},
{x:42.035964,y:-87.941072},
{x:42.324328,y:-83.379703},
{x:41.216977,y:-73.213975},
{x:42.42395,y:-71.065447},
{x:42.378901,y:-71.093988},
{x:39.877309,y:-75.11233},
{x:40.198575,y:-74.752522},
{x:33.920194,y:-84.379311},
{x:39.923568,y:-75.314436},
{x:42.736224,y:-73.761719},
{x:42.603014,y:-71.345625},
{x:40.595714,y:-74.400156},
{x:42.764232,y:-71.466029},
{x:42.980822,y:-71.463603},
{x:41.817137,y:-87.851582},
{x:39.17765,y:-76.616569},
{x:41.062661,y:-74.125917},
{x:42.958792,y:-87.911708},
{x:41.825158,y:-87.798336},
{x:41.511253,y:-71.686128},
{x:39.264162,y:-76.655448},
{x:40.971159,y:-73.959626},
{x:40.29845,y:-74.009375},
{x:27.938204,y:-82.303633},
{x:42.05592,y:-72.76942},
{x:40.571971,y:-74.108255},
{x:42.296756,y:-83.388088},
{x:42.180069,y:-70.955831},
{x:40.208642,y:-74.798183},
{x:42.474398,y:-83.20089},
{x:41.386531,y:-72.159433},
{x:42.287906,y:-71.126892},
{x:42.385928,y:-71.008314},
{x:42.32381,y:-72.633504},
{x:41.713088,y:-72.218844},
{x:41.640014,y:-72.874464},
{x:43.124272,y:-71.443085},
{x:41.662406,y:-87.678625},
{x:40.64078,y:-73.6396},
{x:41.719961,y:-87.780961},
{x:41.946852,y:-87.673164},
{x:40.915505,y:-74.194484},
{x:42.405772,y:-71.015158},
{x:40.70035,y:-73.61583},
{x:40.994897,y:-73.673939},
{x:41.659668,y:-88.441678},
{x:42.470715,y:-83.280405},
{x:27.468033,y:-81.437675},
{x:42.425971,y:-83.335413},
{x:43.670664,y:-70.284753},
{x:42.504681,y:-83.086083},
{x:42.040911,y:-87.725633},
{x:41.809714,y:-87.999283},
{x:40.034978,y:-75.068792},
{x:41.997537,y:-87.708483},
{x:41.600442,y:-87.541469},
{x:29.705737,y:-95.474346},
{x:27.959211,y:-82.787856},
{x:38.836053,y:-104.775533},
{x:32.206692,y:-110.878094},
{x:39.796861,y:-75.548011},
{x:42.585067,y:-72.613022},
{x:41.062981,y:-73.949359},
{x:40.915899,y:-73.997699},
{x:42.472831,y:-71.036857},
{x:42.833036,y:-71.056706},
{x:40.39315,y:-74.101319},
{x:41.305075,y:-73.879242},
{x:42.375847,y:-71.065736},
{x:36.916072,y:-76.219758},
{x:25.739175,y:-80.239514},
{x:39.886083,y:-75.301578},
{x:41.626222,y:-71.147506},
{x:39.168153,y:-75.517492},
{x:42.079163,y:-87.754801},
{x:42.371501,y:-71.157802},
{x:40.122106,y:-74.976294},
{x:42.452117,y:-71.067183},
{x:39.337662,y:-76.634617},
{x:41.426012,y:-73.578179},
{x:41.048264,y:-73.554819},
{x:44.526683,y:-72.000644},
{x:28.370694,y:-80.605447},
{x:33.480547,y:-111.992539},
{x:41.592792,y:-73.109244},
{x:40.680482,y:-73.948915},
{x:40.623299,y:-74.445204},
{x:39.931436,y:-75.160662},
{x:41.661678,y:-73.0796},
{x:38.695407,y:-77.143592},
{x:41.793636,y:-87.723567},
{x:39.396637,y:-76.567224},
{x:40.788122,y:-74.133181},
{x:40.633383,y:-74.258467},
{x:41.232183,y:-73.152678},
{x:42.999953,y:-78.191044},
{x:41.964787,y:-71.339531},
{x:41.690044,y:-87.786689},
{x:41.052944,y:-74.453558},
{x:40.84265,y:-74.464764},
{x:41.952494,y:-87.806269},
{x:41.909442,y:-87.784769},
{x:39.8641,y:-75.078758},
{x:27.067164,y:-82.419769},
{x:40.231002,y:-74.676784},
{x:40.337975,y:-74.063928},
{x:41.829403,y:-72.496363},
{x:42.115156,y:-72.49735},
{x:42.728442,y:-71.448101},
{x:43.218278,y:-70.883342},
{x:35.507147,y:-97.565356},
{x:39.958728,y:-75.287608},
{x:25.947167,y:-80.205031},
{x:41.948807,y:-71.277983},
{x:40.670322,y:-74.219866},
{x:41.858437,y:-87.764282},
{x:28.519233,y:-81.376435},
{x:40.659302,y:-74.222817},
{x:39.841794,y:-75.014544},
{x:41.097308,y:-73.444703},
{x:41.367095,y:-72.117435},
{x:39.963556,y:-74.188768},
{x:41.787619,y:-88.134961},
{x:41.967994,y:-87.729644},
{x:31.870473,y:-106.427765},
{x:35.449663,y:-97.441331},
{x:41.696228,y:-73.90345},
{x:44.0791,y:-70.19193},
{x:42.192859,y:-71.196407},
{x:42.409563,y:-70.999941},
{x:44.419395,y:-72.015941},
{x:29.408437,y:-98.629399},
{x:27.438356,y:-80.347958},
{x:40.812233,y:-74.145219},
{x:29.908894,y:-81.319874},
{x:43.437692,y:-70.774575},
{x:41.552515,y:-70.605937},
{x:40.65802,y:-74.29187},
{x:26.01402,y:-80.20779},
{x:40.256014,y:-76.839983},
{x:42.076947,y:-72.036541},
{x:42.310368,y:-71.114996},
{x:30.288642,y:-81.404689},
{x:40.528597,y:-74.315981},
{x:30.117885,y:-94.144631},
{x:40.842578,y:-74.104436},
{x:42.436069,y:-71.234831},
{x:41.687472,y:-71.172347},
{x:41.597947,y:-73.910697},
{x:43.327306,y:-73.667997},
{x:41.953581,y:-87.752608},
{x:40.868199,y:-73.322599},
{x:41.276475,y:-72.836483},
{x:35.675022,y:-105.953336},
{x:25.823158,y:-80.270936},
{x:30.089865,y:-94.129999},
{x:42.023841,y:-70.683294},
{x:40.76678,y:-73.00158},
{x:43.300144,y:-70.971731},
{x:42.631069,y:-71.282453},
{x:41.966663,y:-87.822208},
{x:42.106049,y:-87.970576},
{x:41.704353,y:-87.681302},
{x:42.700831,y:-73.108375},
{x:41.731233,y:-71.282929},
{x:41.396297,y:-72.854694},
{x:42.100017,y:-71.066094},
{x:42.778256,y:-71.496931},
{x:43.052339,y:-70.770888},
{x:26.934663,y:-82.319178},
{x:41.818333,y:-70.551872},
{x:25.867653,y:-80.265653},
{x:42.032842,y:-71.216108},
{x:42.027785,y:-88.059305},
{x:42.269369,y:-88.004136},
{x:41.557072,y:-87.673053},
{x:42.086041,y:-86.485309},
{x:42.225436,y:-83.268553},
{x:42.116669,y:-71.861972},
{x:43.976354,y:-75.944855},
{x:25.984381,y:-80.210469},
{x:40.038708,y:-75.077846},
{x:39.717592,y:-75.112961},
{x:42.319875,y:-71.647244},
{x:28.239742,y:-82.727467},
{x:40.070947,y:-74.864466},
{x:26.680067,y:-80.054764},
{x:41.5787,y:-73.812336},
{x:44.697269,y:-73.47685},
{x:39.353813,y:-74.448063},
{x:40.513489,y:-74.404803},
{x:26.185719,y:-80.203433},
{x:41.757192,y:-70.653408},
{x:42.180433,y:-70.899436},
{x:44.109978,y:-69.109742},
{x:42.883244,y:-71.324414},
{x:40.058586,y:-75.165408},
{x:26.516568,y:-80.05923},
{x:26.631442,y:-80.088181},
{x:41.341592,y:-72.122525},
{x:42.550867,y:-71.177616},
{x:39.937147,y:-75.076025},
{x:39.492107,y:-75.042862},
{x:41.923603,y:-87.746197},
{x:41.498906,y:-87.042119},
{x:41.549478,y:-87.410419},
{x:40.639863,y:-75.347408},
{x:42.280357,y:-71.034373},
{x:44.495981,y:-73.123571},
{x:43.212642,y:-71.514958},
{x:41.746434,y:-71.14497},
{x:41.513147,y:-87.335544},
{x:40.894639,y:-74.239058},
{x:41.659775,y:-72.678837},
{x:40.78674,y:-73.22079},
{x:33.981194,y:-84.350678},
{x:40.950392,y:-73.733693},
{x:41.731428,y:-71.205094},
{x:44.498054,y:-73.157853},
{x:42.141878,y:-71.061875},
{x:42.996014,y:-70.925397},
{x:43.372394,y:-72.327203},
{x:40.612641,y:-74.129266},
{x:42.805028,y:-71.049528},
{x:42.215244,y:-71.189239},
{x:42.341812,y:-71.086396},
{x:41.048272,y:-74.066302},
{x:42.376537,y:-71.245333},
{x:40.87649,y:-72.52962},
{x:41.659783,y:-70.203661},
{x:44.468014,y:-73.175189},
{x:44.468821,y:-71.184055},
{x:42.984828,y:-70.93785},
{x:42.784081,y:-71.126222},
{x:41.878363,y:-87.625471},
{x:42.138457,y:-71.148729},
{x:41.828488,y:-72.731447},
{x:42.909602,y:-73.899395},
{x:41.104323,y:-73.40459},
{x:38.561083,y:-77.330381},
{x:41.763261,y:-70.497311},
{x:42.539042,y:-71.147525},
{x:42.295706,y:-71.116214},
{x:40.896175,y:-74.0386},
{x:39.206656,y:-76.727422},
{x:43.088833,y:-78.987819},
{x:39.915731,y:-75.382769},
{x:40.843943,y:-74.824135},
{x:40.76182,y:-73.32972},
{x:40.622075,y:-74.303339},
{x:37.235062,y:-79.969841},
{x:39.9047,y:-74.971431},
{x:26.342994,y:-80.086386},
{x:33.961409,y:-83.390779},
{x:32.788319,y:-80.023408},
{x:41.773208,y:-87.975603},
{x:41.912431,y:-88.335481},
{x:41.860272,y:-87.971511},
{x:42.027972,y:-87.977845},
{x:42.119994,y:-71.324294},
{x:41.903156,y:-88.058989},
{x:41.578086,y:-71.270792},
{x:42.140569,y:-83.226972},
{x:28.084837,y:-80.606457},
{x:41.19587,y:-73.20014},
{x:42.84325,y:-70.860917},
{x:40.172008,y:-74.015714},
{x:41.526664,y:-74.033963},
{x:41.881039,y:-71.476564},
{x:41.541426,y:-72.997199},
{x:39.950534,y:-75.16727},
{x:42.425962,y:-71.195063},
{x:42.410901,y:-71.120568},
{x:42.21481,y:-71.691641},
{x:41.953785,y:-87.705668},
{x:42.272395,y:-71.067768},
{x:42.320789,y:-71.061242},
{x:40.915398,y:-74.173068},
{x:39.760936,y:-75.048156},
{x:40.406488,y:-74.234516},
{x:39.014192,y:-74.875664},
{x:40.932378,y:-72.673664},
{x:40.87051,y:-72.99711},
{x:40.815478,y:-73.192686},
{x:40.094678,y:-75.287376},
{x:40.577717,y:-75.454479},
{x:42.254502,y:-73.767351},
{x:42.959158,y:-71.437056},
{x:40.785903,y:-73.00945},
{x:42.625869,y:-71.307499},
{x:41.176747,y:-73.226983},
{x:40.86589,y:-73.6325},
{x:41.942575,y:-71.435244},
{x:25.601199,y:-80.352657},
{x:28.664223,y:-81.369079},
{x:28.032925,y:-82.396747},
{x:33.78591,y:-84.23479},
{x:34.893253,y:-82.345294},
{x:34.032078,y:-81.087558},
{x:41.292998,y:-72.920928},
{x:35.511079,y:-97.599188},
{x:39.638964,y:-84.225517},
{x:44.815717,y:-68.807},
{x:42.871003,y:-73.931411},
{x:33.710625,y:-84.271511},
{x:42.811069,y:-71.103086},
{x:42.552533,y:-70.95945},
{x:39.987928,y:-75.010525},
{x:40.516007,y:-74.488465},
{x:33.888226,y:-84.473489},
{x:42.15399,y:-88.12838},
{x:42.487044,y:-71.263483},
{x:42.351086,y:-71.534092},
{x:42.349831,y:-71.130497},
{x:40.669,y:-73.609492},
{x:39.936572,y:-74.968271},
{x:40.857042,y:-73.204289},
{x:40.82853,y:-73.42474},
{x:40.70533,y:-73.65679},
{x:33.904044,y:-84.205475},
{x:41.693477,y:-72.915003},
{x:42.359737,y:-83.432499},
{x:41.206321,y:-73.984038},
{x:40.69237,y:-73.47886},
{x:40.63731,y:-73.9111},
{x:40.923042,y:-73.049081},
{x:40.085578,y:-82.957042},
{x:40.66421,y:-73.52211},
{x:39.968303,y:-75.578467},
{x:40.856203,y:-74.04975},
{x:39.687794,y:-75.5843},
{x:40.875867,y:-74.649378},
{x:40.77647,y:-73.47079},
{x:41.064925,y:-74.1289},
{x:40.502014,y:-74.852686},
{x:40.862686,y:-74.376917},
{x:41.677363,y:-83.607137},
{x:30.166153,y:-81.747364},
{x:40.887022,y:-74.726347},
{x:40.084199,y:-74.063042},
{x:41.714009,y:-70.489607},
{x:40.728008,y:-73.229853},
{x:40.984472,y:-75.197219},
{x:39.190553,y:-84.256971},
{x:36.745467,y:-108.165281},
{x:42.581947,y:-71.793017},
{x:41.297672,y:-72.926556},
{x:42.731558,y:-83.38015},
{x:41.371731,y:-81.825002},
{x:40.752771,y:-74.405414},
{x:41.74264,y:-74.070237},
{x:40.755075,y:-75.592359},
{x:42.630755,y:-70.775122},
{x:40.707648,y:-73.371232},
{x:42.321912,y:-71.056874},
{x:40.094689,y:-75.364228},
{x:40.940037,y:-74.184972},
{x:32.861111,y:-96.643053},
{x:39.912227,y:-74.154719},
{x:41.669181,y:-74.672815},
{x:41.479878,y:-87.637083},
{x:40.781272,y:-73.533069},
{x:40.095592,y:-75.015019},
{x:40.76098,y:-73.76236},
{x:41.665078,y:-73.929622},
{x:28.030041,y:-81.932137},
{x:26.843481,y:-80.072464},
{x:40.556126,y:-74.345015},
{x:40.974278,y:-74.028026},
{x:40.580519,y:-74.611539},
{x:31.753796,y:-106.344949},
{x:40.79994,y:-72.86588},
{x:40.553586,y:-74.433667},
{x:40.72866,y:-73.44797},
{x:40.78916,y:-73.4157},
{x:43.333431,y:-70.974472},
{x:42.080592,y:-70.993909},
{x:42.246823,y:-72.544506},
{x:38.983444,y:-76.977339},
{x:36.838001,y:-76.17982},
{x:39.943519,y:-75.119114},
{x:27.191384,y:-80.254832},
{x:41.936217,y:-87.766314},
{x:28.75655,y:-81.356686},
{x:39.415043,y:-76.461361},
{x:39.913211,y:-75.437503},
{x:39.389856,y:-74.524451},
{x:28.074319,y:-82.739303},
{x:40.73614,y:-73.71261},
{x:42.397293,y:-71.104913},
{x:30.017874,y:-95.44132},
{x:41.249524,y:-75.855092},
{x:40.72304,y:-73.79951},
{x:40.780166,y:-74.175751},
{x:40.924506,y:-74.718928},
{x:40.836917,y:-74.272572},
{x:39.073031,y:-84.334317},
{x:26.092856,y:-80.153123},
{x:43.028816,y:-71.074355},
{x:34.006894,y:-84.170442},
{x:41.643861,y:-87.737733},
{x:41.443161,y:-72.1043},
{x:42.475191,y:-71.4088},
{x:41.554686,y:-88.124828},
{x:40.72019,y:-73.76209},
{x:40.62395,y:-73.73522},
{x:42.066095,y:-70.868704},
{x:43.23048,y:-75.439599},
{x:44.834656,y:-73.079653},
{x:42.113872,y:-71.472525},
{x:41.024362,y:-73.63575},
{x:41.339011,y:-72.935378},
{x:44.502975,y:-73.031261},
{x:40.752228,y:-73.700869},
{x:42.009231,y:-71.304936},
{x:40.730794,y:-74.176282},
{x:40.309,y:-74.303},
{x:36.57837,y:-87.40837},
{x:40.785937,y:-73.836214},
{x:42.679581,y:-74.464597},
{x:42.067775,y:-71.087644},
{x:41.940553,y:-88.151658},
{x:40.171811,y:-75.072592},
{x:42.409907,y:-71.139434},
{x:41.779901,y:-87.80133},
{x:33.550106,y:-84.254677},
{x:42.42276,y:-71.091923},
{x:42.010762,y:-70.731306},
{x:40.830647,y:-74.708592},
{x:42.382444,y:-71.079075},
{x:42.420147,y:-71.043889},
{x:41.269131,y:-72.974822},
{x:42.383403,y:-71.1066},
{x:42.521075,y:-71.103466},
{x:42.488983,y:-71.099881},
{x:42.558218,y:-71.182341},
{x:42.497731,y:-71.12545},
{x:42.276188,y:-71.166714},
{x:42.534753,y:-71.236711},
{x:45.185782,y:-67.281624},
{x:42.409317,y:-71.053586},
{x:41.808304,y:-87.743015},
{x:41.840649,y:-87.704727},
{x:42.500547,y:-70.858517},
{x:41.031194,y:-73.766308},
{x:42.2536,y:-71.027653},
{x:38.880784,y:-77.398364},
{x:41.305252,y:-72.933927},
{x:41.053567,y:-76.236225},
{x:41.577197,y:-73.408392},
{x:42.349631,y:-71.063564},
{x:39.101953,y:-84.511605},
{x:41.789103,y:-70.750583},
{x:39.293899,y:-84.393208},
{x:28.322892,y:-82.699672},
{x:39.337539,y:-76.480456},
{x:40.74895,y:-73.89026},
{x:38.805544,y:-76.904894},
{x:40.698231,y:-75.173814},
{x:42.268276,y:-71.780615},
{x:40.798353,y:-74.473975},
{x:41.952538,y:-88.122359},
{x:40.606052,y:-74.288776},
{x:41.286847,y:-72.880186},
{x:42.549777,y:-71.005884},
{x:42.284944,y:-71.064694},
{x:42.372248,y:-71.079656},
{x:40.013678,y:-75.06795},
{x:40.743188,y:-74.155334},
{x:42.277478,y:-71.413014},
{x:41.297672,y:-72.926556},
{x:42.414641,y:-71.131065},
{x:41.840567,y:-71.419351},
{x:40.172397,y:-74.890419},
{x:41.115739,y:-74.580808},
{x:40.592908,y:-74.241189},
{x:42.242247,y:-71.845058},
{x:42.455389,y:-75.096305},
{x:40.812008,y:-74.194286},
{x:40.609081,y:-74.464467},
{x:43.048783,y:-76.228886},
{x:33.725019,y:-84.761547},
{x:41.155608,y:-74.193372},
{x:33.817936,y:-84.312622},
{x:39.284828,y:-76.805733},
{x:33.82433,y:-84.108575},
{x:41.750663,y:-87.629805},
{x:40.6772,y:-73.454786},
{x:42.732011,y:-71.4256},
{x:41.924411,y:-71.880192},
{x:40.75249,y:-73.92109},
{x:41.817842,y:-71.444239},
{x:42.405171,y:-71.035633},
{x:42.514683,y:-71.013558},
{x:40.504478,y:-74.397159},
{x:42.140221,y:-77.040543},
{x:43.704036,y:-70.325781},
{x:43.677583,y:-70.331458},
{x:42.308347,y:-71.384936},
{x:39.094753,y:-76.852953},
{x:40.746211,y:-74.259678},
{x:43.145494,y:-76.126447},
{x:40.073381,y:-75.033783},
{x:41.200957,y:-73.184231},
{x:34.030783,y:-84.338936},
{x:33.873661,y:-84.531011},
{x:40.823994,y:-74.480317},
{x:41.504724,y:-75.579235},
{x:40.65215,y:-73.83852},
{x:41.960837,y:-87.95499},
{x:42.526208,y:-71.086853},
{x:27.413249,y:-80.396273},
{x:27.131737,y:-80.203509},
{x:42.234311,y:-71.011248},
{x:42.349661,y:-87.872916},
{x:41.881239,y:-88.194772},
{x:43.111306,y:-71.914948},
{x:42.962221,y:-71.439843},
{x:40.811,y:-73.952267},
{x:40.653828,y:-73.557633},
{x:41.052702,y:-73.814666},
{x:42.465915,y:-70.930409},
{x:40.734504,y:-74.165541},
{x:38.897582,y:-77.269689},
{x:42.024339,y:-71.119033},
{x:40.542811,y:-74.206494},
{x:40.506491,y:-74.641711},
{x:40.71267,y:-73.90677},
{x:41.177877,y:-73.189243},
{x:42.346039,y:-71.787044},
{x:42.196814,y:-71.005642},
{x:41.875908,y:-87.833758},
{x:42.329686,y:-71.057525},
{x:42.419461,y:-71.152864},
{x:40.864799,y:-74.557143},
{x:41.059745,y:-73.533891},
{x:41.376866,y:-73.425716},
{x:41.397404,y:-73.062504},
{x:41.274394,y:-72.553831},
{x:41.327778,y:-72.782567},
{x:39.710556,y:-75.562722},
{x:39.731614,y:-75.642461},
{x:39.656535,y:-75.615283},
{x:39.675658,y:-75.682867},
{x:39.605117,y:-75.74785},
{x:39.465572,y:-75.722111},
{x:25.918025,y:-80.2927},
{x:28.649537,y:-81.325676},
{x:29.145053,y:-80.988058},
{x:29.649295,y:-81.643392},
{x:41.995781,y:-87.670425},
{x:40.729685,y:-89.612218},
{x:40.695217,y:-89.623},
{x:41.928619,y:-87.939931},
{x:41.860767,y:-88.017569},
{x:40.743547,y:-89.631953},
{x:42.059663,y:-88.315036},
{x:41.620708,y:-87.559003},
{x:44.556064,y:-69.629653},
{x:38.982319,y:-76.531403},
{x:39.600588,y:-75.825957},
{x:39.39825,y:-76.541415},
{x:38.566619,y:-76.065194},
{x:38.82255,y:-76.884711},
{x:38.78151,y:-76.059571},
{x:42.109084,y:-71.031324},
{x:42.211722,y:-71.019908},
{x:42.304669,y:-71.322958},
{x:42.244381,y:-70.939689},
{x:42.406108,y:-71.082258},
{x:42.330381,y:-71.124118},
{x:42.29785,y:-71.417519},
{x:42.459225,y:-71.140003},
{x:42.103547,y:-70.955586},
{x:41.994858,y:-70.973431},
{x:41.885812,y:-70.916756},
{x:41.634151,y:-70.927304},
{x:41.809794,y:-71.337333},
{x:42.393583,y:-71.039},
{x:41.682664,y:-71.163125},
{x:41.885069,y:-71.066781},
{x:42.682661,y:-71.309386},
{x:41.762606,y:-70.720277},
{x:41.697545,y:-71.144629},
{x:42.836997,y:-71.663039},
{x:39.997053,y:-74.991631},
{x:42.932984,y:-76.563505},
{x:41.275137,y:-73.783048},
{x:43.139964,y:-76.2275},
{x:42.101938,y:-76.262388},
{x:41.248481,y:-76.963442},
{x:40.377569,y:-79.802234},
{x:41.239625,y:-77.052416},
{x:40.673831,y:-76.235308},
{x:40.969956,y:-76.8927},
{x:39.964064,y:-75.595111},
{x:40.682075,y:-76.190589},
{x:40.833078,y:-76.837881},
{x:40.625108,y:-77.563747},
{x:41.003237,y:-76.441773},
{x:41.570975,y:-75.503027},
{x:41.750252,y:-77.298274},
{x:40.678086,y:-75.259242},
{x:41.134883,y:-77.452903},
{x:41.780915,y:-76.448357},
{x:40.42754,y:-79.69478},
{x:41.469281,y:-75.610058},
{x:41.372431,y:-75.703086},
{x:41.002253,y:-80.344158},
{x:40.244031,y:-75.615928},
{x:40.202717,y:-79.836383},
{x:41.233411,y:-80.443431},
{x:40.791676,y:-76.559433},
{x:40.961472,y:-76.608467},
{x:41.760319,y:-71.347467},
{x:41.881217,y:-71.390444},
{x:41.681569,y:-71.260639},
{x:41.752314,y:-71.415961},
{x:41.61025,y:-71.245725},
{x:34.019142,y:-80.967878},
{x:34.097167,y:-80.921747},
{x:38.807442,y:-77.082561},
{x:38.474494,y:-77.420172},
{x:41.275914,y:-72.952606},
{x:42.421847,y:-71.005414},
{x:40.141244,y:-74.817564},
{x:40.74966,y:-73.64026},
{x:38.953803,y:-76.876167},
{x:26.166263,y:-80.146896},
{x:26.116139,y:-80.323977},
{x:41.659611,y:-71.19025},
{x:42.467556,y:-83.435331},
{x:42.944125,y:-76.551475},
{x:40.73944,y:-73.81562},
{x:40.778842,y:-74.187236},
{x:40.760064,y:-74.974911},
{x:40.434292,y:-74.180504},
{x:41.521631,y:-88.124222},
{x:33.844569,y:-84.256506},
{x:39.373983,y:-76.509575},
{x:41.865281,y:-71.444467},
{x:42.242622,y:-70.958339},
{x:40.061587,y:-75.083865},
{x:28.304781,y:-81.412358},
{x:40.74342,y:-73.65722},
{x:39.297319,y:-76.563416},
{x:40.025322,y:-75.122039},
{x:41.705039,y:-71.419353},
{x:42.698001,y:-73.890039},
{x:44.115311,y:-70.224628},
{x:40.690532,y:-73.724176},
{x:40.60491,y:-73.97652},
{x:42.257899,y:-71.123308},
{x:41.870502,y:-71.523852},
{x:40.051401,y:-75.056993},
{x:42.158438,y:-71.20176},
{x:42.478926,y:-70.956247},
{x:39.914452,y:-75.156274},
{x:42.458398,y:-71.023718},
{x:39.41804,y:-77.452461},
{x:42.294495,y:-71.777492},
{x:40.808589,y:-74.002072},
{x:39.073879,y:-76.542917},
{x:42.414475,y:-71.110478},
{x:41.930044,y:-72.669868},
{x:42.519278,y:-70.90984},
{x:40.462876,y:-74.403992},
{x:42.623689,y:-71.364255},
{x:38.352094,y:-75.601296},
{x:41.681668,y:-71.564564},
{x:42.423954,y:-71.178205},
{x:40.722401,y:-74.092346},
{x:39.074517,y:-77.136816},
{x:35.138267,y:-106.641234},
{x:40.70825,y:-73.71922},
{x:40.064787,y:-74.983191},
{x:41.609065,y:-71.456595},
{x:39.952541,y:-75.166158},
{x:41.884669,y:-71.375898},
{x:40.834106,y:-73.851558},
{x:41.228326,y:-72.995764},
{x:40.622668,y:-73.937353},
{x:42.791265,y:-71.519512},
{x:42.621994,y:-71.183366},
{x:42.308578,y:-71.057927},
{x:40.70155,y:-73.94244},
{x:42.329454,y:-71.084634},
{x:42.335213,y:-71.088276},
{x:40.8753,y:-74.53611},
{x:40.562682,y:-74.30002},
{x:42.652649,y:-73.767795},
{x:38.9427,y:-95.242939},
{x:40.61721,y:-74.03022},
{x:40.59601,y:-73.98448},
{x:41.706062,y:-70.021307},
{x:41.126719,y:-73.387555},
{x:40.65676,y:-73.58074},
{x:39.886023,y:-75.308575},
{x:40.476389,y:-74.296999},
{x:42.671618,y:-71.329764},
{x:42.682618,y:-71.354501},
{x:41.428588,y:-71.863487},
{x:40.60864,y:-73.95843},
{x:40.72147,y:-73.90383},
{x:42.769423,y:-73.893211},
{x:40.727927,y:-74.221267},
{x:42.09926,y:-76.046354},
{x:42.780098,y:-71.227843},
{x:40.731585,y:-74.060271},
{x:39.218856,y:-76.614122},
{x:41.919342,y:-71.359114},
{x:41.410131,y:-73.413781},
{x:27.916088,y:-82.750287},
{x:42.25397,y:-71.814674},
{x:42.442442,y:-83.323169},
{x:41.833145,y:-71.420183},
{x:39.128219,y:-76.591005},
{x:40.832039,y:-74.009942},
{x:39.877582,y:-75.344834},
{x:40.68104,y:-73.84506},
{x:40.72297,y:-73.8675},
{x:39.924897,y:-75.169181},
{x:26.193595,y:-80.248638},
{x:26.618335,y:-80.115459},
{x:41.625814,y:-87.853351},
{x:42.024012,y:-87.896565},
{x:40.155851,y:-75.121564},
{x:40.035411,y:-75.217087},
{x:42.586732,y:-70.885056},
{x:26.437207,y:-80.071691},
{x:41.883326,y:-87.88341},
{x:42.627141,y:-73.818755},
{x:42.598613,y:-82.900315},
{x:41.936134,y:-87.66868},
{x:42.353279,y:-71.133583},
{x:42.012104,y:-87.75757},
{x:39.320526,y:-76.454283},
{x:40.05078,y:-75.010079},
{x:25.928252,y:-80.202163},
{x:42.389806,y:-71.142593},
{x:42.510852,y:-71.199904},
{x:26.165839,y:-80.167354},
{x:41.817429,y:-72.868394},
{x:40.758755,y:-74.044816},
{x:40.885705,y:-73.828293},
{x:40.788926,y:-74.016586},
{x:41.79002,y:-87.868898},
{x:41.938863,y:-87.722397},
{x:39.922485,y:-75.086814},
{x:42.582064,y:-71.289438},
{x:25.869007,y:-80.209305},
{x:41.54566,y:-87.47156},
{x:42.38962,y:-71.08866},
{x:27.838826,y:-82.682935},
{x:41.815666,y:-87.665207},
{x:41.87811,y:-87.62914},
{x:39.917202,y:-75.264753},
{x:26.706678,y:-80.110197},
{x:42.298682,y:-71.445131},
{x:35.080946,y:-106.625232},
{x:33.966676,y:-84.256351},
{x:40.033517,y:-75.131039},
{x:44.391463,y:-71.184398},
{x:44.785369,y:-68.752802},
{x:41.958755,y:-71.119643},
{x:41.752969,y:-71.435984},
{x:43.634347,y:-70.254265},
{x:40.729475,y:-74.159674},
{x:43.69929,y:-70.287982},
{x:40.655024,y:-73.669879},
{x:38.656644,y:-77.250425},
{x:41.785937,y:-72.747076},
{x:42.657146,y:-73.802948},
{x:40.015084,y:-74.953498},
{x:40.76733,y:-73.739156},
{x:41.454536,y:-74.057982},
{x:44.000176,y:-70.99832},
{x:42.635103,y:-73.113485},
{x:39.295201,y:-76.511595},
{x:42.688855,y:-71.147833},
{x:42.873588,y:-71.489412},
{x:26.652678,y:-80.112683},
{x:39.998225,y:-75.232149},
{x:42.824081,y:-73.959808},
{x:41.946563,y:-87.710436},
{x:40.029732,y:-75.035628},
{x:40.745576,y:-74.049434},
{x:34.016724,y:-84.492849},
{x:42.085796,y:-72.556971},
{x:42.139493,y:-70.842363},
{x:42.244569,y:-71.758174},
{x:40.644365,y:-74.273346},
{x:40.811608,y:-74.123683},
{x:40.75425,y:-73.89585},
{x:41.70007,y:-71.460124},
{x:41.986556,y:-73.881627},
{x:40.70466,y:-73.63716},
{x:33.552972,y:-112.10106},
{x:41.767122,y:-87.722365},
{x:39.917839,y:-75.177922},
{x:42.774831,y:-71.445027},
{x:42.839352,y:-72.565285},
{x:33.946042,y:-84.334766},
{x:41.886562,y:-87.804961},
{x:26.273999,y:-80.154749},
{x:41.923884,y:-87.806248},
{x:41.782693,y:-87.68375},
{x:40.626006,y:-74.134489},
{x:33.449192,y:-84.454983},
{x:25.984853,y:-80.159723},
{x:42.819782,y:-70.910416},
{x:42.3124,y:-71.2125},
{x:42.188355,y:-73.365034},
{x:40.066204,y:-75.051428},
{x:41.995881,y:-87.786676},
{x:39.952153,y:-75.173625},
{x:42.161395,y:-72.33887},
{x:41.417033,y:-81.920759},
{x:42.780191,y:-73.957185},
{x:41.727023,y:-71.438782},
{x:41.822682,y:-87.690536},
{x:43.05073,y:-71.437823},
{x:42.968073,y:-71.480282},
{x:41.734047,y:-72.837184},
{x:38.424436,y:-75.05617},
{x:30.247963,y:-81.767534},
{x:27.890239,y:-82.728708},
{x:41.307876,y:-72.895115},
{x:42.673024,y:-71.150813},
{x:26.272483,y:-80.098055},
{x:41.907606,y:-87.845812},
{x:32.133587,y:-111.000008},
{x:33.421053,y:-111.960497},
{x:40.98886,y:-74.279287},
{x:40.73305,y:-73.26384},
{x:33.860773,y:-84.340234},
{x:42.999323,y:-71.515838},
{x:42.086438,y:-87.870067},
{x:41.658851,y:-87.796019},
{x:42.449468,y:-82.96826},
{x:41.995672,y:-87.689975},
{x:41.889919,y:-71.446379},
{x:41.793539,y:-71.326508},
{x:39.140209,y:-84.617394},
{x:42.322476,y:-83.450514},
{x:30.323751,y:-81.530896},
{x:42.188635,y:-87.825642},
{x:42.442191,y:-71.429587},
{x:40.689555,y:-73.842465},
{x:25.486609,y:-80.458738},
{x:41.8081,y:-87.7042},
{x:39.050016,y:-84.577246},
{x:41.997345,y:-87.660663},
{x:41.755012,y:-72.765974},
{x:39.189013,y:-84.601109},
{x:40.621162,y:-75.480809},
{x:41.782628,y:-71.472665},
{x:42.067634,y:-71.328941},
{x:42.298686,y:-71.389761},
{x:39.805148,y:-75.094691},
{x:41.229934,y:-73.225461},
{x:42.151055,y:-71.411683},
{x:42.70638,y:-71.199087},
{x:42.219125,y:-71.180376},
{x:42.770556,y:-71.114429},
{x:38.865146,y:-77.27438},
{x:42.346557,y:-71.087313},
{x:44.215133,y:-70.519757},
{x:40.581691,y:-74.413466},
{x:42.773856,y:-73.698129},
{x:40.922399,y:-74.1404},
{x:41.827292,y:-71.472429},
{x:42.965801,y:-70.835575},
{x:42.28767,y:-71.02357},
{x:42.065999,y:-87.700324},
{x:39.009207,y:-76.698809},
{x:41.989261,y:-72.578841},
{x:41.684714,y:-71.507386},
{x:41.971719,y:-71.00747},
{x:42.566303,y:-71.107896},
{x:42.568642,y:-71.419772},
{x:40.750437,y:-73.971713},
{x:37.136836,y:-76.518883},
{x:43.236785,y:-77.647287},
{x:42.414122,y:-71.079043},
{x:41.569766,y:-87.635788},
{x:41.81662,y:-87.925816},
{x:26.457469,y:-80.119176},
{x:28.698168,y:-81.358589},
{x:27.893158,y:-82.519156},
{x:41.386393,y:-81.437666},
{x:42.637469,y:-71.345904},
{x:41.094279,y:-73.954041},
{x:41.752402,y:-88.115597},
{x:36.81659,y:-76.109441},
{x:42.846743,y:-70.915172},
{x:40.69208,y:-73.68404},
{x:40.663804,y:-74.234041},
{x:36.911286,y:-76.072689},
{x:42.43041,y:-71.042418},
{x:42.830918,y:-71.107945},
{x:41.75593,y:-87.944624},
{x:42.362604,y:-71.130351},
{x:33.029809,y:-96.77049},
{x:41.78352,y:-88.281502},
{x:41.76943,y:-71.265357},
{x:41.853552,y:-71.42907},
{x:42.237708,y:-87.944639},
{x:43.120943,y:-77.619005},
{x:43.149102,y:-77.597996},
{x:41.484205,y:-87.711955},
{x:40.759618,y:-74.222341},
{x:40.75612,y:-73.83382},
{x:41.767327,y:-72.568469},
{x:41.913009,y:-87.88361},
{x:41.802139,y:-71.443764},
{x:42.161394,y:-75.893918},
{x:43.291444,y:-73.636654},
{x:42.457757,y:-83.133105},
{x:41.428854,y:-71.480423},
{x:41.796825,y:-72.555232},
{x:44.836025,y:-69.289004},
{x:42.847373,y:-71.359526},
{x:41.312496,y:-73.057613},
{x:41.934197,y:-71.299853},
{x:41.425843,y:-81.73484},
{x:41.882335,y:-87.640893},
{x:40.077359,y:-75.085219},
{x:40.005927,y:-75.095925},
{x:40.60507,y:-74.263546},
{x:42.763865,y:-71.038817},
{x:25.686141,y:-80.386329},
{x:41.851678,y:-87.744115},
{x:36.766108,y:-76.251448},
{x:40.775746,y:-73.910327},
{x:42.589762,y:-71.203685},
{x:42.319347,y:-87.867402},
{x:41.353425,y:-72.210238},
{x:39.950964,y:-75.152072},
{x:41.696417,y:-71.523344},
{x:33.709986,y:-78.86315},
{x:40.239369,y:-76.982685},
{x:41.975933,y:-87.692436},
{x:42.697785,y:-71.440527},
{x:42.590106,y:-83.446739},
{x:44.786247,y:-69.732577},
{x:42.182813,y:-71.861494},
{x:43.093771,y:-70.794013},
{x:42.576249,y:-70.995945},
{x:43.83326,y:-70.437757},
{x:41.670801,y:-72.779477},
{x:41.912658,y:-72.612878},
{x:35.735729,y:-78.649831},
{x:40.63242,y:-73.9472},
{x:39.916495,y:-75.007851},
{x:38.648514,y:-75.608501},
{x:42.267171,y:-71.015311},
{x:40.76089,y:-73.92271},
{x:40.70728,y:-73.80249},
{x:42.421139,y:-71.132842},
{x:40.434836,y:-74.544348},
{x:42.039137,y:-87.871427},
{x:40.7143,y:-73.42738},
{x:25.769562,y:-80.33012},
{x:36.797353,y:-76.325525},
{x:43.445002,y:-71.612333},
{x:41.666843,y:-72.921451},
{x:42.018024,y:-87.806497},
{x:41.772091,y:-71.410493},
{x:40.792157,y:-74.058582},
{x:42.690521,y:-71.17178},
{x:41.571762,y:-72.641853},
{x:39.869271,y:-75.041668},
{x:40.604669,y:-73.998747},
{x:37.154959,y:-76.461477},
{x:40.954705,y:-73.865443},
{x:42.405572,y:-71.233994},
{x:42.230335,y:-71.849364},
{x:40.712802,y:-74.008135},
{x:40.715991,y:-74.004985},
{x:40.740099,y:-74.192705},
{x:28.069019,y:-82.463178},
{x:41.314078,y:-73.218965},
{x:26.52763,y:-80.088451},
{x:43.228605,y:-71.362385},
{x:37.615329,y:-77.497462},
{x:34.244683,y:-77.880275},
{x:39.828704,y:-75.124603},
{x:41.860895,y:-71.490423},
{x:43.460728,y:-76.490383},
{x:44.570844,y:-68.783015},
{x:40.684641,y:-74.206489},
{x:41.879717,y:-71.362449},
{x:43.508705,y:-70.434985},
{x:42.008539,y:-87.674328},
{x:28.786835,y:-81.982036},
{x:40.76968,y:-73.83458},
{x:42.188608,y:-88.105252},
{x:25.943829,y:-80.30728},
{x:41.946141,y:-87.687863},
{x:40.87064,y:-73.856036},
{x:43.770039,y:-71.715221},
{x:42.058373,y:-87.841447},
{x:42.296955,y:-71.0607},
{x:26.188198,y:-80.157865},
{x:41.890134,y:-71.378246},
{x:40.669774,y:-74.479941},
{x:42.355761,y:-72.554686},
{x:42.557049,y:-71.771692},
{x:41.522282,y:-71.282393},
{x:43.071688,y:-70.769627},
{x:40.888081,y:-74.151433},
{x:42.284963,y:-71.606617},
{x:42.129595,y:-70.949861},
{x:41.170541,y:-73.128229},
{x:42.534079,y:-70.887528},
{x:35.960537,y:-77.820509},
{x:41.985137,y:-71.515646},
{x:40.865697,y:-74.422485},
{x:41.288994,y:-73.112575},
{x:41.833156,y:-71.555191},
{x:42.165474,y:-71.045076},
{x:41.720837,y:-87.705784},
{x:41.460093,y:-87.080622},
{x:42.48112,y:-71.107567},
{x:32.900069,y:-83.68749},
{x:42.561573,y:-71.590691},
{x:40.7261,y:-73.89496},
{x:42.684966,y:-70.843936},
{x:42.361055,y:-71.063072},
{x:41.83149,y:-87.615092},
{x:40.625075,y:-73.96197},
{x:43.190938,y:-71.51338},
{x:34.768724,y:-77.385718},
{x:43.257881,y:-71.579108},
{x:40.675251,y:-74.198979},
{x:40.760942,y:-74.150897},
{x:42.205039,y:-71.000077},
{x:42.823754,y:-71.62636},
{x:27.376962,y:-80.32542},
{x:40.989912,y:-74.336022},
{x:42.506935,y:-70.895986},
{x:42.837158,y:-73.934959},
{x:41.781754,y:-70.540265},
{x:40.70043,y:-73.90563},
{x:39.108801,y:-77.552818},
{x:44.307137,y:-71.774106},
{x:28.333249,y:-81.496895},
{x:33.942728,y:-84.533515},
{x:41.25338,y:-73.101638},
{x:41.650714,y:-70.612457},
{x:41.478325,y:-73.213798},
{x:41.969133,y:-87.761686},
{x:40.78776,y:-73.72637},
{x:41.852296,y:-87.685469},
{x:40.654187,y:-74.307922},
{x:41.675435,y:-70.309881},
{x:28.251083,y:-81.312469},
{x:43.036903,y:-71.163605},
{x:38.973979,y:-76.525167},
{x:39.377591,y:-76.726438},
{x:40.692152,y:-73.991323},
{x:38.80628,y:-77.133279},
{x:41.321892,y:-72.87575},
{x:42.336554,y:-71.541533},
{x:43.701534,y:-71.108126},
{x:43.585294,y:-71.206823},
{x:39.734653,y:-75.690418},
{x:40.43036,y:-74.251171},
{x:42.650941,y:-73.751692},
{x:25.789167,y:-80.141303},
{x:42.368741,y:-71.21782},
{x:42.457311,y:-73.229788},
{x:33.942229,y:-84.127685},
{x:42.548896,y:-70.940089},
{x:43.232672,y:-70.808768},
{x:42.096844,y:-72.581972},
{x:42.883428,y:-72.557452},
{x:42.485105,y:-70.940778},
{x:40.782324,y:-74.021171},
{x:42.10766,y:-70.771039},
{x:42.645402,y:-71.308169},
{x:40.692473,y:-73.860428},
{x:41.284603,y:-72.978923},
{x:42.149553,y:-71.50119},
{x:41.879429,y:-87.626388},
{x:41.902623,y:-71.098942},
{x:38.825533,y:-77.314685},
{x:41.180657,y:-73.248028},
{x:41.099759,y:-74.14683},
{x:41.104356,y:-73.567063},
{x:40.011374,y:-74.801718},
{x:40.631819,y:-73.888364},
{x:44.546993,y:-70.545599},
{x:41.679658,y:-73.019669},
{x:41.905161,y:-87.667657},
{x:40.707965,y:-73.830829},
{x:41.93734,y:-87.833039},
{x:41.788766,y:-71.516825},
{x:41.667725,y:-72.641273},
{x:42.426818,y:-71.067202},
{x:40.045265,y:-75.1424},
{x:25.866657,y:-80.306088},
{x:39.85066,y:-74.986414},
{x:40.857816,y:-74.352532},
{x:26.244801,y:-80.253593},
{x:41.588823,y:-85.846976},
{x:42.719185,y:-71.180026},
{x:42.223573,y:-71.182506},
{x:41.412021,y:-73.304345},
{x:42.358146,y:-71.057964},
{x:42.29035,y:-72.407578},
{x:42.120425,y:-71.09222},
{x:40.676255,y:-74.290781},
{x:42.068153,y:-71.049329},
{x:25.944744,y:-80.171125},
{x:42.154089,y:-71.246125},
{x:42.091062,y:-72.066687},
{x:42.28068,y:-72.670528},
{x:26.571812,y:-80.087645},
{x:40.614632,y:-74.495856},
{x:41.882291,y:-87.63933},
{x:42.3644,y:-71.0213},
{x:42.363,y:-71.016},
{x:41.855142,y:-71.237484},
{x:42.37425,y:-71.085666},
{x:42.27779,y:-71.853943},
{x:26.175539,y:-80.098623},
{x:42.500829,y:-71.230344},
{x:41.063808,y:-74.012505},
{x:42.606489,y:-71.569273},
{x:41.051282,y:-75.10769},
{x:42.060203,y:-71.865427},
{x:41.165174,y:-73.418297},
{x:40.515286,y:-74.37669},
{x:42.017941,y:-71.497784},
{x:26.678118,y:-80.202217},
{x:40.605435,y:-75.460194},
{x:41.738964,y:-71.307144},
{x:42.333914,y:-71.103773},
{x:41.617045,y:-87.045573},
{x:26.367757,y:-80.19927},
{x:44.786824,y:-68.812547},
{x:42.135757,y:-71.101393},
{x:40.708909,y:-73.818426},
{x:40.589348,y:-73.663288},
{x:40.893558,y:-74.117167},
{x:42.074689,y:-71.042538},
{x:42.703618,y:-71.215041},
{x:42.611813,y:-71.456586},
{x:40.811254,y:-73.256765},
{x:41.724857,y:-74.389155},
{x:41.273176,y:-72.506744},
{x:41.978474,y:-88.206826},
{x:26.651437,y:-80.141611},
{x:40.83076,y:-73.68732},
{x:40.945883,y:-73.068617},
{x:43.719975,y:-70.232603},
{x:42.46373,y:-70.950006},
{x:40.589824,y:-75.562975},
{x:39.910318,y:-75.048916},
{x:43.396188,y:-72.700951},
{x:40.879398,y:-74.222187},
{x:41.151301,y:-74.188181},
{x:41.442366,y:-71.479934},
{x:40.967976,y:-74.153263},
{x:40.80738,y:-73.62682},
{x:42.520454,y:-70.932882},
{x:40.8601,y:-73.53502},
{x:42.233238,y:-72.015346},
{x:42.573877,y:-70.770215},
{x:42.342521,y:-71.121106},
{x:43.31594,y:-70.583938},
{x:41.534244,y:-72.795043},
{x:41.335533,y:-72.900629},
{x:42.090351,y:-71.419818},
{x:26.627601,y:-80.072843},
{x:42.122403,y:-71.033817},
{x:42.650272,y:-71.663385},
{x:43.592583,y:-73.26564},
{x:40.675154,y:-73.557072},
{x:34.021243,y:-84.528892},
{x:40.794722,y:-74.314908},
{x:42.082653,y:-70.947182},
{x:28.601191,y:-81.322674},
{x:42.336678,y:-71.835008},
{x:40.134,y:-75.332573},
{x:41.094238,y:-73.518258},
{x:40.714978,y:-74.252979},
{x:40.641186,y:-74.38386},
{x:40.67232,y:-73.50881},
{x:41.981272,y:-71.209315},
{x:41.576097,y:-87.180918},
{x:40.717862,y:-73.736705},
{x:40.83872,y:-73.2947},
{x:42.387778,y:-71.14235},
{x:28.029199,y:-80.621383},
{x:41.626224,y:-87.717274},
{x:41.886833,y:-87.623422},
{x:41.942937,y:-72.725724},
{x:40.567392,y:-74.543561},
{x:41.954346,y:-87.859417},
{x:42.891103,y:-71.140694},
{x:44.73713,y:-68.62789},
{x:41.756913,y:-71.457846},
{x:42.362345,y:-71.417708},
{x:41.940026,y:-87.71291},
{x:40.679293,y:-73.761142},
{x:41.783783,y:-74.71943},
{x:30.425561,y:-97.753682},
{x:42.646396,y:-71.270197},
{x:42.214914,y:-73.878018},
{x:41.673107,y:-72.859129},
{x:41.847231,y:-72.567854},
{x:39.477924,y:-77.959105},
{x:41.972175,y:-87.837053},
{x:42.649143,y:-71.182802},
{x:42.661206,y:-71.110239},
{x:41.275029,y:-73.125322},
{x:43.011148,y:-73.849536},
{x:42.168821,y:-72.543238},
{x:41.10139,y:-73.419525},
{x:42.797041,y:-71.088293},
{x:44.495053,y:-71.574452},
{x:42.323175,y:-71.103728},
{x:40.923695,y:-73.788004},
{x:40.67942,y:-73.88035},
{x:39.89492,y:-75.317867},
{x:42.840375,y:-71.49176},
{x:41.333518,y:-74.192893},
{x:39.644231,y:-74.784149},
{x:42.272649,y:-70.859699},
{x:40.732186,y:-73.98157},
{x:42.912479,y:-71.555013},
{x:42.493575,y:-71.727124},
{x:42.457502,y:-75.056018},
{x:41.941516,y:-87.806484},
{x:41.101726,y:-73.547725},
{x:26.007038,y:-80.382053},
{x:41.431337,y:-75.664141},
{x:41.324849,y:-75.805821},
{x:26.287147,y:-80.235586},
{x:40.746633,y:-73.985962},
{x:33.757359,y:-84.388855},
{x:33.945772,y:-84.6232},
{x:34.056117,y:-84.557548},
{x:33.424521,y:-84.557845},
{x:42.173661,y:-71.111336},
{x:41.390813,y:-72.791954},
{x:41.713727,y:-71.267232},
{x:44.57677,y:-72.59549},
{x:41.660907,y:-72.779086},
{x:26.619546,y:-80.140111},
{x:26.167598,y:-80.29758},
{x:26.042649,y:-80.143728},
{x:27.811046,y:-80.466705},
{x:41.910956,y:-87.638023},
{x:42.011612,y:-87.807014},
{x:39.953113,y:-75.193616},
{x:40.617236,y:-73.909718},
{x:43.14837,y:-71.00439},
{x:40.808505,y:-73.106309},
{x:42.138801,y:-87.984041},
{x:39.849986,y:-75.449907},
{x:29.146946,y:-82.187727},
{x:42.017859,y:-87.874947},
{x:40.6684,y:-73.99382},
{x:42.168771,y:-72.326234},
{x:27.609578,y:-80.383983},
{x:39.040477,y:-77.155538},
{x:41.076164,y:-73.471356},
{x:41.265284,y:-73.440895},
{x:39.01447,y:-76.927247},
{x:41.821362,y:-71.361382},
{x:41.796244,y:-72.520741},
{x:41.347089,y:-72.052952},
{x:40.58247,y:-73.95978},
{x:41.723358,y:-88.32002},
{x:40.024642,y:-75.147935},
{x:42.01626,y:-70.940769},
{x:42.35092,y:-71.062282},
{x:41.702822,y:-70.484527},
{x:39.397644,y:-76.603549},
{x:41.831466,y:-71.362027},
{x:39.075819,y:-77.116126},
{x:41.293658,y:-73.675776},
{x:41.515763,y:-73.047811},
{x:41.625468,y:-71.492691},
{x:28.228776,y:-80.678895},
{x:40.248238,y:-74.686288},
{x:41.663281,y:-71.913363},
{x:41.058171,y:-73.766195},
{x:26.165813,y:-80.242761},
{x:42.337694,y:-71.108885},
{x:42.338558,y:-71.107245},
{x:41.593864,y:-88.036842},
{x:42.841807,y:-71.715253},
{x:33.738711,y:-84.430351},
{x:39.278837,y:-74.577793},
{x:39.822707,y:-75.48862},
{x:26.148745,y:-80.254408},
{x:41.866649,y:-87.625636},
{x:26.491105,y:-80.063683},
{x:26.806815,y:-80.10592},
{x:42.370577,y:-71.195242},
{x:30.481389,y:-81.647065},
{x:27.894082,y:-82.272092},
{x:43.834053,y:-71.207859},
{x:34.025859,y:-84.044329},
{x:40.774349,y:-74.027381},
{x:33.397337,y:-84.745054},
{x:40.788241,y:-73.813487},
{x:42.259494,y:-84.42371},
{x:41.694327,y:-72.656389},
{x:41.237526,y:-73.020984},
{x:41.985075,y:-72.514898},
{x:38.615504,y:-76.920701},
{x:40.77045,y:-73.91806},
{x:40.314058,y:-75.312343},
{x:39.155734,y:-75.513463},
{x:41.713003,y:-72.605835},
{x:38.82443,y:-76.736377},
{x:42.092444,y:-70.703799},
{x:43.047087,y:-77.08968},
{x:40.832057,y:-73.195536},
{x:39.339989,y:-76.505572},
{x:40.021533,y:-75.051122},
{x:40.087271,y:-75.039911},
{x:27.95235,y:-82.829514},
{x:41.944894,y:-71.40321},
{x:39.949256,y:-75.163254},
{x:43.660726,y:-71.495993},
{x:41.655303,y:-87.679905},
{x:41.780047,y:-87.863674},
{x:40.759541,y:-73.830628},
{x:39.950895,y:-75.151819},
{x:40.696973,y:-75.178063},
{x:41.763891,y:-88.367667},
{x:26.309613,y:-80.2012},
{x:42.703464,y:-74.310986},
{x:42.106623,y:-72.64575},
{x:42.15953,y:-72.633676},
{x:41.829715,y:-71.427423},
{x:41.440973,y:-72.831452},
{x:41.696891,y:-72.723051},
{x:41.905271,y:-71.066013},
{x:40.723682,y:-74.308522},
{x:39.222116,y:-76.889499},
{x:41.62959,y:-87.509263},
{x:42.568073,y:-87.94931},
{x:42.496317,y:-71.015192},
{x:42.348915,y:-71.153022},
{x:41.716955,y:-70.584653},
{x:40.75428,y:-73.11785},
{x:40.841306,y:-74.159241},
{x:40.043887,y:-75.102336},
{x:42.553187,y:-71.78422},
{x:41.41245,y:-73.454055},
{x:42.249963,y:-71.715216},
{x:42.122934,y:-71.644611},
{x:30.219117,y:-81.554427},
{x:40.523415,y:-74.437244},
{x:41.417185,y:-72.840136},
{x:42.462603,y:-71.27394},
{x:28.387021,y:-81.506841},
{x:39.152406,y:-77.064543},
{x:41.337591,y:-74.359153},
{x:40.987337,y:-75.222966},
{x:28.550929,y:-81.545577},
{x:33.5737,y:-84.371197},
{x:40.872071,y:-74.194232},
{x:40.906049,y:-74.569689},
{x:40.980397,y:-75.980415},
{x:42.350301,y:-71.503192},
{x:41.844074,y:-74.14771},
{x:40.725561,y:-73.487149},
{x:40.68365,y:-73.41731},
{x:42.219851,y:-71.003804},
{x:40.731492,y:-73.191786},
{x:40.75474,y:-73.65019},
{x:42.604082,y:-72.590948},
{x:40.731016,y:-74.06444},
{x:40.563206,y:-74.694843},
{x:40.64144,y:-75.490747},
{x:40.350622,y:-74.06993},
{x:41.87363,y:-71.391004},
{x:41.931306,y:-72.628118},
{x:40.564472,y:-74.257206},
{x:40.716951,y:-74.357122},
{x:42.040561,y:-87.773701},
{x:39.967568,y:-74.685285},
{x:40.131566,y:-75.376522},
{x:40.032653,y:-75.179787},
{x:42.199704,y:-83.151847},
{x:39.000675,y:-77.096865},
{x:40.054345,y:-75.141799},
{x:42.902618,y:-73.68874},
{x:40.69091,y:-73.924321},
{x:41.953845,y:-87.787066},
{x:39.496681,y:-76.331254},
{x:40.77221,y:-73.64923},
{x:40.76351,y:-73.78631},
{x:28.52886,y:-81.310088},
{x:42.347856,y:-71.247535},
{x:40.876665,y:-73.863203},
{x:40.039107,y:-75.14414},
{x:40.236062,y:-74.747447},
{x:41.369948,y:-71.820213},
{x:36.15031,y:-80.278049},
{x:39.177789,y:-76.84342},
{x:39.160239,y:-78.154448},
{x:39.155757,y:-78.187331},
{x:38.915698,y:-78.185207},
{x:38.958513,y:-78.195369},
{x:40.974669,y:-73.83295},
{x:41.656447,y:-71.604697},
{x:42.887491,y:-70.871609},
{x:41.012405,y:-73.850787},
{x:40.72515,y:-73.63507},
{x:40.896992,y:-73.804154},
{x:40.931477,y:-74.132161},
{x:42.198606,y:-71.818941},
{x:40.13412,y:-74.06346},
{x:40.43541,y:-74.13128},
{x:40.004492,y:-75.036696},
{x:38.87088,y:-78.526257},
{x:34.82005,y:-82.27082},
{x:41.74437,y:-73.824386},
{x:40.899849,y:-73.999868},
{x:42.872607,y:-70.819083},
{x:41.326219,y:-72.959571},
{x:29.006543,y:-81.303226},
{x:42.102787,y:-72.591703},
{x:42.170678,y:-72.480087},
{x:41.912367,y:-71.402011},
{x:41.764566,y:-71.404465},
{x:42.946424,y:-71.057553},
{x:42.069121,y:-70.809968},
{x:41.370575,y:-73.965135},
{x:41.961486,y:-87.666573},
{x:40.73649,y:-73.857598},
{x:41.917739,y:-72.261449},
{x:42.258131,y:-71.456847},
{x:40.172236,y:-74.833719},
{x:42.329151,y:-71.723832},
{x:41.679444,y:-70.923253},
{x:42.351129,y:-71.04941},
{x:34.881369,y:-76.907164},
{x:41.732936,y:-71.174243},
{x:42.742853,y:-71.591153},
{x:42.690507,y:-71.210125},
{x:42.334752,y:-71.074127},
{x:42.321204,y:-71.363507},
{x:42.898843,y:-71.332533},
{x:26.212806,y:-80.157442},
{x:42.004971,y:-71.588409},
{x:41.313896,y:-72.912036},
{x:42.011938,y:-87.726669},
{x:40.7922,y:-73.812492},
{x:39.953811,y:-75.322755},
{x:42.64193,y:-73.743872},
{x:40.850509,y:-73.937259},
{x:41.010981,y:-74.116916},
{x:40.994739,y:-74.008175},
{x:40.785627,y:-74.045666},
{x:41.854729,y:-71.116184},
{x:41.360769,y:-73.093893},
{x:40.6585,y:-73.93146},
{x:40.842525,y:-73.845789},
{x:43.285192,y:-73.586266},
{x:43.455725,y:-71.565489},
{x:41.657046,y:-70.347128},
{x:41.760682,y:-71.42533},
{x:40.918116,y:-73.767679},
{x:43.103339,y:-73.517126},
{x:40.081248,y:-74.994878},
{x:26.36889,y:-80.173676},
{x:43.45267,y:-71.21434},
{x:42.9431,y:-71.4754},
{x:39.971897,y:-75.125714},
{x:41.95319,y:-71.242447},
{x:41.890367,y:-71.341242},
{x:42.378737,y:-71.223366},
{x:26.407001,y:-80.125053},
{x:25.813998,y:-80.127417},
{x:40.756444,y:-73.92959},
{x:42.22254,y:-71.02391},
{x:42.371677,y:-71.12116},
{x:42.285679,y:-71.235758},
{x:39.896265,y:-74.948968},
{x:41.020726,y:-73.757692},
{x:41.467822,y:-72.68004},
{x:42.008562,y:-72.593224},
{x:41.695361,y:-72.74733},
{x:41.037501,y:-73.760111},
{x:40.783413,y:-74.162165},
{x:32.507295,y:-84.954796},
{x:42.490884,y:-71.1735},
{x:40.7111,y:-73.85535},
{x:40.742522,y:-73.980665},
{x:41.968108,y:-70.972996},
{x:41.819186,y:-72.610044},
{x:41.582589,y:-71.501209},
{x:40.661404,y:-74.265921},
{x:41.146287,y:-73.933909},
{x:40.643589,y:-74.828074},
{x:30.28753,y:-81.516746},
{x:34.724256,y:-76.74351},
{x:42.200846,y:-70.944097},
{x:39.933057,y:-75.065448},
{x:42.333399,y:-71.119128},
{x:33.899518,y:-84.301501},
{x:42.015844,y:-71.527963},
{x:41.891934,y:-71.354128},
{x:41.789443,y:-71.332702},
{x:26.04602,y:-80.224987},
{x:42.063816,y:-72.516408},
{x:42.418501,y:-71.109174},
{x:39.057499,y:-77.084799},
{x:39.116865,y:-77.252654},
{x:42.085997,y:-70.993208},
{x:41.309014,y:-72.933544},
{x:41.642589,y:-72.769804},
{x:41.769296,y:-71.265585},
{x:41.888603,y:-87.635481},
{x:41.733164,y:-87.779883},
{x:38.562675,y:-75.557352},
{x:33.762971,y:-84.386499},
{x:40.842077,y:-74.208302},
{x:40.65872,y:-73.64786},
{x:44.834744,y:-68.790408},
{x:43.886082,y:-70.330727},
{x:42.336986,y:-71.077304},
{x:42.054435,y:-71.05743},
{x:40.214878,y:-74.013116},
{x:40.818875,y:-74.000793},
{x:40.665176,y:-74.385648},
{x:39.992098,y:-75.09895},
{x:40.086318,y:-74.971731},
{x:40.24378,y:-74.757882},
{x:41.290283,y:-72.445002},
{x:33.628584,y:-83.977786},
{x:41.106687,y:-73.405466},
{x:41.350186,y:-72.15343},
{x:41.289642,y:-72.799228},
{x:43.446135,y:-71.47446},
{x:40.508418,y:-74.267706},
{x:40.63423,y:-74.02052},
{x:40.83304,y:-73.03878},
{x:42.240083,y:-71.369128},
{x:39.565785,y:-76.977022},
{x:26.00738,y:-80.31173},
{x:42.61668,y:-70.669389},
{x:26.208004,y:-80.250607},
{x:41.543213,y:-72.053452},
{x:28.336382,y:-81.59354},
{x:40.69056,y:-74.331313},
{x:33.670507,y:-112.201941},
{x:40.676765,y:-74.267677},
{x:34.174132,y:-84.127508},
{x:41.966115,y:-87.997833},
{x:40.80336,y:-73.21282},
{x:41.160608,y:-73.223737},
{x:41.285334,y:-72.936762},
{x:40.71435,y:-73.80709},
{x:40.930234,y:-74.228016},
{x:42.102808,y:-71.560846},
{x:42.384069,y:-71.569877},
{x:40.458202,y:-74.295131},
{x:42.350062,y:-71.861712},
{x:25.988209,y:-80.142685},
{x:42.90863,y:-72.26072},
{x:26.36845,y:-80.09028},
{x:33.856185,y:-84.001885},
{x:42.544311,y:-71.874353},
{x:43.734536,y:-70.548989},
{x:43.3338,y:-71.00733},
{x:41.816208,y:-73.092607},
{x:41.576125,y:-72.607182},
{x:41.955658,y:-71.069205},
{x:40.954693,y:-74.072969},
{x:40.743478,y:-73.918933},
{x:40.76441,y:-73.933054},
{x:41.956809,y:-71.025777},
{x:41.955826,y:-71.027108},
{x:40.908714,y:-73.874928},
{x:40.835171,y:-73.860888},
{x:40.579732,y:-75.523062},
{x:41.519663,y:-81.470732},
{x:39.040568,y:-77.358084},
{x:40.991703,y:-73.669263},
{x:41.587413,y:-72.890069},
{x:42.203302,y:-70.943982},
{x:40.585805,y:-74.476097},
{x:41.132394,y:-73.794581},
{x:40.714584,y:-73.573007},
{x:40.70053,y:-73.89678},
{x:41.688166,y:-70.338292},
{x:40.775649,y:-74.237331},
{x:42.844437,y:-70.894805},
{x:26.762539,y:-80.108724},
{x:40.556667,y:-74.283257},
{x:41.414262,y:-72.902998},
{x:40.73899,y:-73.2931},
{x:40.76265,y:-73.91581},
{x:39.653012,y:-75.750767},
{x:40.746789,y:-74.061999},
{x:40.712979,y:-73.828443},
{x:40.330786,y:-74.119493},
{x:43.937024,y:-69.971489},
{x:43.13654,y:-72.44889},
{x:42.335749,y:-71.05599},
{x:43.015167,y:-74.361559},
{x:42.764551,y:-74.118911},
{x:40.602109,y:-74.564104},
{x:41.787518,y:-71.075579},
{x:42.152775,y:-72.584768},
{x:42.170828,y:-83.246579},
{x:40.79774,y:-73.6999},
{x:42.325072,y:-71.259572},
{x:42.54602,y:-71.474751},
{x:42.620731,y:-71.260371},
{x:43.118481,y:-76.143106},
{x:41.786584,y:-69.994733},
{x:42.694856,y:-71.119014},
{x:40.846385,y:-73.831637},
{x:42.080783,y:-70.936244},
{x:40.797371,y:-74.477838},
{x:39.940113,y:-75.165879},
{x:42.595669,y:-71.7515},
{x:41.654582,y:-71.454066},
{x:41.930952,y:-72.606808},
{x:43.158906,y:-76.328389},
{x:42.237037,y:-72.581778},
{x:42.622363,y:-70.643552},
{x:42.135083,y:-72.739585},
{x:33.890705,y:-84.754086},
{x:40.63386,y:-74.02671},
{x:40.737457,y:-74.037246},
{x:40.579567,y:-74.286299},
{x:41.757165,y:-72.717107},
{x:42.64555,y:-71.30038},
{x:29.88766,y:-95.642914},
{x:42.068357,y:-70.948041},
{x:26.351372,y:-80.110577},
{x:40.627895,y:-74.241699},
{x:41.132095,y:-74.367898},
{x:41.199181,y:-74.485752},
{x:39.185037,y:-78.170295},
{x:40.009719,y:-75.150781},
{x:41.520157,y:-74.06918},
{x:39.969576,y:-75.245668},
{x:40.439935,y:-75.356437},
{x:40.68584,y:-73.91606},
{x:40.720851,y:-73.855064},
{x:42.079148,y:-88.021721},
{x:42.029876,y:-88.278098},
{x:34.135864,y:-77.893879},
{x:41.8887,y:-71.44925},
{x:42.800181,y:-77.781377},
{x:42.865944,y:-77.712621},
{x:43.060683,y:-76.065678},
{x:42.223454,y:-70.803922},
{x:42.140614,y:-71.217425},
{x:41.736979,y:-72.659805},
{x:40.819225,y:-74.328643},
{x:39.912948,y:-75.007589},
{x:35.798809,y:-78.489614},
{x:38.760764,y:-77.460815},
{x:41.332416,y:-73.138726},
{x:40.75827,y:-73.54293},
{x:38.86195,y:-77.089116},
{x:28.547384,y:-81.737051},
{x:41.931872,y:-87.713438},
{x:42.654602,y:-73.759132},
{x:41.483379,y:-71.307846},
{x:42.286365,y:-71.525641},
{x:42.722108,y:-70.98744},
{x:42.873243,y:-71.337864},
{x:42.619798,y:-71.290247},
{x:43.479713,y:-70.489019},
{x:42.113617,y:-72.103764},
{x:42.283892,y:-71.112518},
{x:26.526371,y:-80.122445},
{x:41.60112,y:-87.60501},
{x:42.78125,y:-72.1399},
{x:44.622296,y:-73.121262},
{x:41.428623,y:-72.082007},
{x:42.143928,y:-71.514916},
{x:42.342129,y:-71.569187},
{x:40.980242,y:-74.120229},
{x:41.710715,y:-71.142284},
{x:42.167381,y:-71.358244},
{x:40.748217,y:-73.877113},
{x:40.883379,y:-74.290091},
{x:40.803722,y:-74.365629},
{x:43.301158,y:-73.665398},
{x:42.290988,y:-71.806063},
{x:25.883884,y:-80.330321},
{x:26.00881,y:-80.276235},
{x:26.057304,y:-80.252755},
{x:26.690384,y:-80.11087},
{x:42.156924,y:-70.850532},
{x:42.441548,y:-71.03072},
{x:44.081395,y:-70.26043},
{x:42.689654,y:-73.819927},
{x:40.141851,y:-74.042226},
{x:40.712729,y:-73.783932},
{x:40.611776,y:-74.72508},
{x:42.366032,y:-71.062157},
{x:40.855769,y:-74.034495},
{x:39.970951,y:-75.152116},
{x:40.045005,y:-75.087822},
{x:41.878036,y:-87.634793},
{x:42.144452,y:-72.540632},
{x:43.63327,y:-72.31755},
{x:43.604056,y:-73.003288},
{x:39.837362,y:-75.068093},
{x:43.25733,y:-70.97951},
{x:40.278958,y:-74.532424},
{x:40.953336,y:-73.843455},
{x:41.171648,y:-73.15199},
{x:27.0171,y:-82.120837},
{x:26.984721,y:-82.101568},
{x:40.651905,y:-75.413363},
{x:34.096567,y:-84.015265},
{x:40.842419,y:-73.707856},
{x:40.817721,y:-73.411738},
{x:27.838862,y:-82.756478},
{x:39.969407,y:-75.159525},
{x:40.2516,y:-74.788522},
{x:40.171019,y:-75.045188},
{x:40.854813,y:-73.867501},
{x:41.838606,y:-71.014493},
{x:40.790894,y:-74.200757},
{x:40.294388,y:-74.03278},
{x:40.731713,y:-75.312647},
{x:43.052199,y:-75.374346},
{x:43.078543,y:-75.64572},
{x:41.811998,y:-71.461272},
{x:41.674437,y:-73.063947},
{x:41.433789,y:-74.039309},
{x:42.014884,y:-71.040165},
{x:42.258648,y:-71.160509},
{x:33.512085,y:-82.046228},
{x:40.99585,y:-74.74315},
{x:41.666612,y:-70.184828},
{x:40.863307,y:-73.834348},
{x:41.487299,y:-71.973637},
{x:41.770411,y:-72.971539},
{x:42.151113,y:-72.467815},
{x:40.193212,y:-75.087107},
{x:40.856137,y:-74.145012},
{x:40.827103,y:-74.358503},
{x:26.317523,y:-80.127243},
{x:42.64133,y:-71.352666},
{x:42.275235,y:-71.139544},
{x:41.873981,y:-71.377203},
{x:42.058745,y:-76.139779},
{x:43.079781,y:-73.803532},
{x:42.40243,y:-71.050016},
{x:42.497241,y:-71.069154},
{x:42.526334,y:-70.901064},
{x:41.484676,y:-72.100432},
{x:26.12179,y:-80.36105},
{x:25.746471,y:-80.369052},
{x:40.636496,y:-74.970145},
{x:40.869554,y:-74.070854},
{x:40.057605,y:-74.219588},
{x:40.7688,y:-73.77406},
{x:40.748269,y:-73.758326},
{x:40.65918,y:-73.89065},
{x:40.64769,y:-73.97162},
{x:40.826428,y:-73.822162},
{x:41.549469,y:-72.800643},
{x:42.576198,y:-71.998685},
{x:42.092307,y:-71.053182},
{x:43.63588,y:-70.328943},
{x:41.078662,y:-74.166158},
{x:34.065409,y:-84.254095},
{x:39.904827,y:-75.23839},
{x:41.954507,y:-87.688974},
{x:41.225474,y:-73.028914},
{x:40.011282,y:-75.113031},
{x:40.243389,y:-74.279377},
{x:42.627965,y:-71.322481},
{x:42.733573,y:-73.848976},
{x:40.81362,y:-73.95614},
{x:41.924241,y:-87.768158},
{x:28.38901,y:-81.403929},
{x:39.270077,y:-76.68228},
{x:39.956136,y:-75.181857},
{x:42.043547,y:-70.882504},
{x:42.397413,y:-70.992065},
{x:40.73209,y:-73.68824},
{x:42.330758,y:-71.192832},
{x:41.727691,y:-71.438245},
{x:25.980339,y:-80.336692},
{x:41.853347,y:-71.354702},
{x:42.217447,y:-71.224617},
{x:42.359514,y:-71.058912},
{x:40.966533,y:-74.078512},
{x:40.908123,y:-74.397933},
{x:41.566296,y:-72.324214},
{x:44.68106,y:-73.449109},
{x:40.704601,y:-73.816023},
{x:40.898365,y:-73.854534},
{x:40.867306,y:-73.897197},
{x:42.191189,y:-70.988832},
{x:42.046673,y:-71.080144},
{x:26.707881,y:-80.1301},
{x:43.209123,y:-71.179909},
{x:39.940712,y:-75.006212},
{x:42.72822,y:-71.202267},
{x:41.663136,y:-71.498592},
{x:42.781403,y:-74.028778},
{x:41.908304,y:-71.363773},
{x:42.697394,y:-73.646176},
{x:40.038657,y:-75.036051},
{x:28.185359,y:-82.440081},
{x:29.879345,y:-81.324395},
{x:41.497133,y:-72.810366},
{x:40.920513,y:-73.861258},
{x:42.034896,y:-71.922283},
{x:42.528696,y:-71.758636},
{x:40.466959,y:-74.451997},
{x:43.311248,y:-71.61529},
{x:42.07077,y:-72.014225},
{x:42.356547,y:-71.053354},
{x:42.094664,y:-71.219639},
{x:40.646093,y:-74.214861},
{x:40.093936,y:-75.03212},
{x:40.559419,y:-74.197728},
{x:39.953948,y:-75.166628},
{x:41.517623,y:-87.849249},
{x:44.767168,y:-69.7158},
{x:40.905227,y:-73.857973},
{x:42.150018,y:-72.537165},
{x:42.062231,y:-72.631492},
{x:42.207743,y:-72.347888},
{x:41.481935,y:-72.826716},
{x:29.187353,y:-82.10467},
{x:28.91079,y:-81.280255},
{x:28.34568,y:-81.366378},
{x:40.92804,y:-73.842081},
{x:42.23412,y:-70.97641},
{x:41.666812,y:-72.807658},
{x:41.711206,y:-72.808428},
{x:41.478714,y:-72.812343},
{x:41.789081,y:-71.420948},
{x:39.90929,y:-75.097585},
{x:39.359862,y:-74.433132},
{x:40.685905,y:-74.098535},
{x:41.693604,y:-71.139378},
{x:41.727356,y:-72.615835},
{x:41.557591,y:-72.853149},
{x:41.867477,y:-72.468795},
{x:42.143073,y:-72.611139},
{x:42.306478,y:-71.923631},
{x:40.717091,y:-74.052378},
{x:33.984724,y:-84.213739},
{x:38.917536,y:-76.970353},
{x:42.35354,y:-71.057967},
{x:43.010313,y:-76.141961},
{x:39.408533,y:-74.520404},
{x:41.030028,y:-74.127198},
{x:40.706822,y:-74.405232},
{x:41.649253,y:-70.482055},
{x:40.76216,y:-73.86901},
{x:41.699449,y:-71.159771},
{x:43.03976,y:-70.82794},
{x:42.367,y:-71.0174},
{x:42.085984,y:-71.695597},
{x:40.821901,y:-73.992621},
{x:41.50962,y:-71.318311},
{x:41.870687,y:-71.531335},
{x:34.065561,y:-84.320583},
{x:25.624268,y:-80.413979},
{x:26.587429,y:-80.066156},
{x:26.516274,y:-80.076893},
{x:41.505378,y:-72.980424},
{x:35.852968,y:-78.642809},
{x:42.890852,y:-71.943313},
{x:42.36655,y:-71.09409},
{x:42.352339,y:-71.062559},
{x:42.359102,y:-71.054752},
{x:40.819776,y:-74.839539},
{x:39.819672,y:-75.237517},
{x:40.907254,y:-74.665664},
{x:41.060801,y:-73.815017},
{x:26.065531,y:-80.361698},
{x:41.983822,y:-72.656095},
{x:42.086881,y:-72.556016},
{x:42.37977,y:-71.566033},
{x:42.237878,y:-71.132123},
{x:40.598238,y:-73.997287},
{x:40.78237,y:-73.8035},
{x:40.346694,y:-74.433655},
{x:42.444486,y:-71.752294},
{x:43.689742,y:-70.398995},
{x:43.593036,y:-70.328777},
{x:41.62948,y:-71.462548},
{x:41.90288,y:-70.96895},
{x:41.974294,y:-71.067625},
{x:40.7041,y:-73.91854},
{x:41.823046,y:-71.389761},
{x:41.173479,y:-73.192486},
{x:40.899996,y:-74.160685},
{x:39.629114,y:-75.666613},
{x:40.541218,y:-74.423832},
{x:41.701956,y:-71.385754},
{x:40.874035,y:-74.482171},
{x:41.276364,y:-73.119087},
{x:40.900874,y:-73.819657},
{x:27.017976,y:-82.165964},
{x:33.906138,y:-84.112184},
{x:42.107333,y:-70.770721},
{x:41.654247,y:-88.079773},
{x:42.330824,y:-71.209663},
{x:40.780773,y:-74.698451},
{x:42.533426,y:-71.262902},
{x:39.185388,y:-78.139978},
{x:39.080911,y:-77.080919},
{x:39.959172,y:-75.231375},
{x:43.069255,y:-76.105893},
{x:42.170889,y:-71.619566},
{x:41.964411,y:-72.319297},
{x:41.717495,y:-71.268927},
{x:39.925228,y:-77.616623},
{x:39.400337,y:-76.753883},
{x:39.74698,y:-75.611492},
{x:40.722529,y:-74.164921},
{x:40.705706,y:-74.260814},
{x:40.679704,y:-74.650557},
{x:42.842,y:-71.107},
{x:40.169092,y:-75.126912},
{x:42.38694,y:-71.190953},
{x:41.758978,y:-72.742875},
{x:41.934932,y:-71.477024},
{x:43.452968,y:-76.530612},
{x:42.126324,y:-71.068072},
{x:26.304676,y:-80.280456},
{x:42.376124,y:-71.955173},
{x:44.371967,y:-71.611234},
{x:42.161179,y:-70.901241},
{x:42.775084,y:-71.489992},
{x:39.394599,y:-74.398299},
{x:42.262481,y:-70.985493},
{x:40.66676,y:-73.48401},
{x:40.649065,y:-74.341885},
{x:42.554875,y:-70.883476},
{x:40.228732,y:-74.067432},
{x:40.82783,y:-74.103291},
{x:42.106968,y:-70.755263},
{x:42.06586,y:-71.399339},
{x:41.662969,y:-70.299109},
{x:28.034655,y:-80.646063},
{x:42.154837,y:-71.009836},
{x:42.463891,y:-71.062697},
{x:42.3092,y:-71.0819},
{x:42.270867,y:-71.172642},
{x:42.341527,y:-71.579066},
{x:40.735066,y:-74.043737},
{x:40.956022,y:-73.812693},
{x:41.190736,y:-73.135387},
{x:40.65387,y:-73.63242},
{x:41.937555,y:-71.081012},
{x:41.024154,y:-80.729238},
{x:41.583898,y:-72.502997},
{x:38.925372,y:-75.566009},
{x:38.835142,y:-77.051175},
{x:41.882424,y:-87.626363},
{x:41.696192,y:-87.739326},
{x:42.016531,y:-71.03242},
{x:41.713687,y:-71.127323},
{x:41.792593,y:-71.396821},
{x:41.71631,y:-71.462857},
{x:41.857074,y:-72.178935},
{x:41.772729,y:-72.529436},
{x:41.520252,y:-72.802378},
{x:42.970351,y:-73.794791},
{x:41.681512,y:-71.131558},
{x:41.553928,y:-72.106799},
{x:40.604891,y:-74.691845},
{x:42.763956,y:-73.802432},
{x:39.015474,y:-77.374755},
{x:41.201638,y:-73.88495},
{x:41.514968,y:-74.208139},
{x:41.99814,y:-72.584449},
{x:41.701987,y:-70.936352},
{x:42.343678,y:-71.065989},
{x:41.148123,y:-73.988731},
{x:42.161227,y:-72.582596},
{x:42.680248,y:-73.733631},
{x:40.265601,y:-74.740319},
{x:43.9219,y:-72.66473},
{x:42.99779,y:-71.475577},
{x:42.267138,y:-71.797345},
{x:43.732723,y:-70.294977},
{x:42.586713,y:-71.809886},
{x:44.012536,y:-73.165167},
{x:39.956147,y:-75.181949},
{x:39.985229,y:-74.209797},
{x:41.566953,y:-73.060667},
{x:43.027706,y:-70.790581},
{x:43.005214,y:-76.178224},
{x:42.349241,y:-71.224664},
{x:39.110285,y:-77.174672},
{x:40.644813,y:-74.253799},
{x:40.628759,y:-74.114911},
{x:40.615274,y:-74.066789},
{x:42.03967,y:-70.957538},
{x:39.789514,y:-74.926567},
{x:39.915821,y:-74.925097},
{x:40.88126,y:-73.83863},
{x:44.847765,y:-74.310183},
{x:42.587564,y:-71.307174},
{x:41.695487,y:-70.587159},
{x:40.151519,y:-74.2274},
{x:38.851416,y:-77.125893},
{x:41.693583,y:-88.068493},
{x:39.735603,y:-75.070334},
{x:39.992163,y:-75.141782},
{x:41.398018,y:-75.674645},
{x:43.204797,y:-70.859518},
{x:41.931077,y:-87.775666},
{x:42.702918,y:-70.910322},
{x:42.245158,y:-71.592385},
{x:43.293719,y:-72.477744},
{x:39.949595,y:-75.154266},
{x:42.79902,y:-70.874722},
{x:41.491074,y:-71.437644},
{x:42.273443,y:-71.766591},
{x:42.109088,y:-70.810585},
{x:41.609944,y:-70.940052},
{x:42.750954,y:-71.172504},
{x:40.69192,y:-73.52759},
{x:40.57554,y:-73.99715},
{x:40.865579,y:-73.867147},
{x:40.850667,y:-73.985635},
{x:41.789751,y:-87.801392},
{x:42.001201,y:-87.661115},
{x:40.693812,y:-74.101231},
{x:41.321963,y:-72.853701},
{x:41.127062,y:-73.422149},
{x:38.876174,y:-75.823658},
{x:25.798925,y:-80.337485},
{x:26.258384,y:-80.266517},
{x:42.283905,y:-71.046182},
{x:42.626031,y:-71.421133},
{x:41.356845,y:-72.871861},
{x:41.100589,y:-80.745235},
{x:41.876868,y:-87.63322},
{x:28.186915,y:-80.593287},
{x:41.494163,y:-72.903592},
{x:41.334069,y:-73.083807},
{x:26.492641,y:-81.806871},
{x:42.568095,y:-71.814838},
{x:42.019304,y:-87.690389},
{x:42.896172,y:-70.870488},
{x:34.101845,y:-84.532994},
{x:40.689012,y:-74.245951},
{x:40.435702,y:-74.484967},
{x:41.754046,y:-72.682883},
{x:42.256274,y:-72.251779},
{x:41.819037,y:-72.656983},
{x:42.237855,y:-72.720845},
{x:26.226147,y:-80.204905},
{x:26.287528,y:-80.202642},
{x:42.643694,y:-71.389684},
{x:42.045227,y:-71.86345},
{x:42.076978,y:-71.284391},
{x:42.545727,y:-70.981034},
{x:42.465434,y:-71.006274},
{x:43.232215,y:-76.140455},
{x:41.792791,y:-88.204963},
{x:39.597969,y:-76.847483},
{x:40.913226,y:-74.182188},
{x:41.40304,y:-73.459047},
{x:41.418676,y:-82.209393},
{x:40.096794,y:-74.944123},
{x:40.070179,y:-75.098669},
{x:39.951974,y:-75.159517},
{x:34.035906,y:-84.572133},
{x:33.540825,y:-82.134172},
{x:43.286812,y:-76.130546},
{x:43.08969,y:-76.091376},
{x:42.720246,y:-73.836286},
{x:42.670647,y:-71.497316},
{x:42.372985,y:-71.093746},
{x:44.840173,y:-68.743654},
{x:43.844308,y:-70.119249},
{x:46.677409,y:-68.015942},
{x:46.141994,y:-67.840564},
{x:42.486396,y:-71.540072},
{x:42.533556,y:-70.990176},
{x:42.201348,y:-71.062219},
{x:41.790725,y:-88.32179},
{x:42.937712,y:-74.179667},
{x:28.496701,y:-81.310746},
{x:28.366532,y:-81.424687},
{x:30.267331,y:-81.61435},
{x:30.314425,y:-81.730604},
{x:42.456288,y:-71.356383},
{x:42.410821,y:-71.088287},
{x:43.009077,y:-71.396544},
{x:41.952908,y:-70.684706},
{x:28.043903,y:-82.679806},
{x:28.130621,y:-82.36691},
{x:28.449961,y:-81.425274},
{x:42.705415,y:-73.776314},
{x:42.152663,y:-71.490961},
{x:41.755581,y:-70.732516},
{x:42.145085,y:-71.442556},
{x:28.21747,y:-82.688047},
{x:40.1104,y:-88.243823},
{x:41.491823,y:-87.470575},
{x:41.572945,y:-87.558339},
{x:41.603001,y:-87.745144},
{x:41.429618,y:-87.364296},
{x:41.865225,y:-87.794741},
{x:41.905667,y:-87.958729},
{x:42.074767,y:-88.290232},
{x:41.939044,y:-70.656638},
{x:40.616599,y:-74.284503},
{x:42.398282,y:-71.020606},
{x:40.901891,y:-81.120393},
{x:41.053227,y:-73.539765},
{x:41.804832,y:-71.3392},
{x:41.288634,y:-72.666832},
{x:41.314412,y:-73.079064},
{x:42.04782,y:-87.959859},
{x:42.040482,y:-87.802802},
{x:41.93121,y:-87.987199},
{x:41.785925,y:-87.738704},
{x:41.97545,y:-87.901254},
{x:27.996402,y:-82.583106},
{x:42.495748,y:-83.535708},
{x:41.15993,y:-81.862827},
{x:42.621413,y:-83.052624},
{x:43.495301,y:-70.45845},
{x:41.516402,y:-73.59145},
{x:40.582309,y:-74.455094},
{x:40.70195,y:-73.80825},
{x:43.616963,y:-71.637534},
{x:40.58929,y:-74.194038},
{x:43.323982,y:-76.42302},
{x:42.948358,y:-71.433289},
{x:42.035589,y:-88.259759},
{x:40.69367,y:-73.85261},
{x:42.804227,y:-71.550615},
{x:40.119525,y:-75.017402},
{x:40.096611,y:-74.974941},
{x:41.866537,y:-87.6683},
{x:41.945157,y:-73.996305},
{x:42.723391,y:-71.159647},
{x:41.415722,y:-73.408656},
{x:42.553565,y:-71.264874},
{x:42.208798,y:-71.120263},
{x:43.196992,y:-77.498866},
{x:43.213887,y:-77.430361},
{x:40.68575,y:-73.8263},
{x:43.104978,y:-76.058424},
{x:42.861098,y:-73.888738},
{x:41.679216,y:-70.908737},
{x:41.929832,y:-87.878516},
{x:42.048382,y:-88.091037},
{x:41.893011,y:-70.775511},
{x:41.949853,y:-88.030315},
{x:41.757015,y:-72.886961},
{x:41.602154,y:-87.837908},
{x:41.579586,y:-88.16097},
{x:42.27313,y:-71.0277},
{x:41.919112,y:-73.058135},
{x:42.140294,y:-72.733872},
{x:41.865535,y:-72.362659},
{x:43.407965,y:-70.737188},
{x:41.278992,y:-72.874428},
{x:42.575557,y:-70.892072},
{x:42.594524,y:-71.118889},
{x:27.316946,y:-80.383103},
{x:40.097644,y:-74.04992},
{x:42.139008,y:-88.023887},
{x:42.538267,y:-71.743819},
{x:42.79906,y:-71.474253},
{x:42.772415,y:-71.076383},
{x:42.430606,y:-70.979525},
{x:42.079952,y:-71.37213},
{x:40.185482,y:-74.24967},
{x:39.974389,y:-74.136601},
{x:42.28476,y:-71.342512},
{x:40.879353,y:-81.439235},
{x:41.735224,y:-87.584885},
{x:40.473969,y:-88.958857},
{x:41.629956,y:-72.758391},
{x:41.030264,y:-80.354699},
{x:40.654987,y:-73.907377},
{x:42.816835,y:-72.017407},
{x:41.451263,y:-81.812074},
{x:41.902477,y:-70.957747},
{x:26.571595,y:-80.112426},
{x:42.883176,y:-73.196612},
{x:40.888009,y:-74.272763},
{x:41.550521,y:-87.23922},
{x:42.761425,y:-71.448935},
{x:42.710231,y:-71.441717},
{x:42.375473,y:-71.039018},
{x:41.306372,y:-72.971003},
{x:41.985523,y:-71.157556},
{x:42.373062,y:-72.504069},
{x:26.454092,y:-80.160875},
{x:26.552414,y:-80.149941},
{x:41.797284,y:-72.36932},
{x:41.844516,y:-70.948107},
{x:40.051856,y:-74.895999},
{x:41.677956,y:-72.846363},
{x:42.006685,y:-88.14521},
{x:39.791194,y:-75.035864},
{x:43.178639,y:-77.631821},
{x:43.133911,y:-77.441992},
{x:41.045725,y:-73.541502},
{x:44.101269,y:-69.386793},
{x:42.442715,y:-73.276526},
{x:39.802768,y:-75.168573},
{x:41.933213,y:-70.658711},
{x:42.765421,y:-71.239823},
{x:29.552774,y:-81.22771},
{x:40.38219,y:-74.518117},
{x:43.091217,y:-76.170146},
{x:26.209116,y:-80.221882},
{x:34.940573,y:-80.979255},
{x:40.252168,y:-74.733388},
{x:42.408559,y:-73.26329},
{x:40.6745,y:-73.80473},
{x:42.037296,y:-71.347809},
{x:41.826095,y:-71.670967},
{x:42.284868,y:-71.807353},
{x:41.801856,y:-88.067937},
{x:40.89012,y:-73.898381},
{x:41.755259,y:-70.112736},
{x:41.982104,y:-71.469751},
{x:41.388744,y:-71.625675},
{x:43.185467,y:-76.222038},
{x:42.105084,y:-75.933894},
{x:42.300626,y:-73.242586},
{x:28.533998,y:-82.50751},
{x:28.57331,y:-81.515806},
{x:42.152488,y:-87.839487},
{x:41.622754,y:-87.58959},
{x:39.752628,y:-94.214657},
{x:33.600557,y:-84.339628},
{x:34.086839,y:-84.527956},
{x:35.815626,y:-80.876846},
{x:40.221288,y:-75.097609},
{x:39.933883,y:-74.180874},
{x:42.059167,y:-72.442085},
{x:41.597096,y:-70.979832},
{x:42.252222,y:-71.972606},
{x:42.58516,y:-72.206638},
{x:42.645909,y:-73.697786},
{x:43.121549,y:-76.157812},
{x:39.908688,y:-75.273892},
{x:42.050024,y:-71.01137},
{x:39.528818,y:-74.647954},
{x:39.977247,y:-75.22666},
{x:40.056845,y:-75.028883},
{x:40.429212,y:-74.342846},
{x:41.018601,y:-74.29682},
{x:42.378185,y:-87.93663},
{x:40.26762,y:-74.493536},
{x:41.600569,y:-87.691736},
{x:41.734346,y:-71.477242},
{x:42.547,y:-70.92012},
{x:41.876634,y:-71.061443},
{x:40.802779,y:-74.169517},
{x:41.823602,y:-71.410974},
{x:40.241584,y:-74.304517},
{x:39.954072,y:-75.275545},
{x:41.782536,y:-88.240444},
{x:42.621459,y:-83.249333},
{x:41.951642,y:-71.48608},
{x:26.121666,y:-80.161444},
{x:35.841749,y:-78.612493},
{x:41.359115,y:-72.105519},
{x:41.553594,y:-75.224559},
{x:39.864709,y:-75.67521},
{x:40.731035,y:-74.152098},
{x:40.74485,y:-74.220953},
{x:40.775987,y:-74.159199},
{x:42.607156,y:-71.226632},
{x:42.167107,y:-71.894355},
{x:44.256118,y:-72.576142},
{x:41.638465,y:-71.03607},
{x:42.385856,y:-70.976569},
{x:42.161768,y:-71.062264},
{x:41.639403,y:-70.982603},
{x:41.640953,y:-70.912991},
{x:40.80177,y:-73.94882},
{x:35.210801,y:-80.85852},
{x:39.688559,y:-74.991518},
{x:40.008785,y:-74.147282},
{x:39.738346,y:-75.002319},
{x:40.08143,y:-74.156718},
{x:40.574059,y:-74.366256},
{x:40.634713,y:-74.302984},
{x:42.073268,y:-71.019263},
{x:41.847124,y:-87.646084},
{x:40.88924,y:-73.014413},
{x:42.900567,y:-72.306001},
{x:42.566701,y:-71.423906},
{x:43.031169,y:-71.937951},
{x:35.07741,y:-80.652982},
{x:40.356572,y:-74.304706},
{x:26.137385,y:-80.127673},
{x:43.959737,y:-75.913717},
{x:44.050031,y:-75.83522},
{x:41.91591,y:-88.208178},
{x:39.299732,y:-76.611771},
{x:40.291685,y:-74.174718},
{x:43.115365,y:-77.663128},
{x:42.366145,y:-71.238123},
{x:41.555537,y:-72.956675},
{x:42.311214,y:-71.366129},
{x:40.516094,y:-74.631444},
{x:40.067949,y:-75.007121},
{x:42.737265,y:-71.195131},
{x:42.676572,y:-71.294038},
{x:40.85256,y:-73.8273},
{x:40.663134,y:-73.961396},
{x:40.706825,y:-73.751408},
{x:40.66714,y:-73.78965},
{x:40.804641,y:-73.937063},
{x:39.982594,y:-74.789595},
{x:40.847596,y:-73.912414},
{x:40.877016,y:-73.906547},
{x:39.84163,y:-74.961643},
{x:42.013157,y:-71.320084},
{x:42.456806,y:-87.825033},
{x:40.764146,y:-73.958971},
{x:40.45119,y:-74.29878},
{x:40.456616,y:-74.246294},
{x:40.96927,y:-74.285224},
{x:40.424258,y:-74.270083},
{x:40.485149,y:-74.473205},
{x:40.376054,y:-74.30664},
{x:40.11784,y:-74.253678},
{x:39.30747,y:-75.608383},
{x:41.973342,y:-87.748172},
{x:41.707129,y:-72.700405},
{x:40.702468,y:-73.81376},
{x:44.230949,y:-69.77407},
{x:42.476675,y:-70.957231},
{x:44.032475,y:-71.121902},
{x:42.777837,y:-71.085517},
{x:41.787131,y:-70.640052},
{x:42.486799,y:-71.416888},
{x:42.261094,y:-71.818221},
{x:41.605139,y:-72.707828},
{x:41.805849,y:-72.555707},
{x:27.264188,y:-80.408062},
{x:43.243454,y:-71.404805},
{x:42.915771,y:-71.384963},
{x:43.049492,y:-71.459938},
{x:40.713846,y:-75.219258},
{x:42.122597,y:-88.335852},
{x:40.541898,y:-74.145978},
{x:39.210287,y:-78.177541},
{x:38.871072,y:-77.055984},
{x:40.663512,y:-73.548873},
{x:40.515529,y:-74.356427},
{x:40.532409,y:-74.191749},
{x:39.827189,y:-75.159108},
{x:42.176554,y:-70.746755},
{x:41.668224,y:-70.081034},
{x:42.01926,y:-71.007863},
{x:44.627355,y:-70.156897},
{x:40.178389,y:-74.903949},
{x:35.13466,y:-80.938647},
{x:42.096832,y:-72.599065},
{x:41.82752,y:-72.267187},
{x:39.462745,y:-75.206258},
{x:41.713622,y:-70.771594},
{x:42.145228,y:-71.989351},
{x:40.458329,y:-74.472278},
{x:41.735991,y:-87.720576},
{x:42.382736,y:-70.99356},
{x:38.870297,y:-77.054693},
{x:42.347508,y:-88.285311},
{x:40.616356,y:-74.103867},
{x:42.070779,y:-87.925811},
{x:40.513073,y:-74.284149},
{x:44.115242,y:-70.206911},
{x:43.107837,y:-75.220059},
{x:43.022699,y:-74.991942},
{x:43.011809,y:-78.822473},
{x:44.695084,y:-73.515207},
{x:43.173782,y:-73.060511},
{x:40.844363,y:-73.897348},
{x:40.646925,y:-74.911069},
{x:40.222545,y:-75.140123},
{x:41.769914,y:-71.13829},
{x:42.363736,y:-83.274951},
{x:42.423575,y:-71.071181},
{x:42.362355,y:-71.194068},
{x:40.962367,y:-74.598776},
{x:40.747484,y:-73.756007},
{x:40.865573,y:-73.85977},
{x:40.49654,y:-74.446148},
{x:42.272737,y:-71.610858},
{x:42.446337,y:-71.221899},
{x:42.456368,y:-71.391072},
{x:44.324246,y:-69.861146},
{x:27.996408,y:-82.516537},
{x:40.07889,y:-75.025737},
{x:41.881849,y:-87.634075},
{x:41.906506,y:-71.660505},
{x:42.040412,y:-87.700152},
{x:28.453546,y:-81.470986},
{x:28.494641,y:-82.596966},
{x:28.59792,y:-81.297011},
{x:39.961161,y:-76.705516},
{x:41.141345,y:-73.424199},
{x:41.290618,y:-73.496201},
{x:41.626447,y:-81.434417},
{x:40.040588,y:-75.540472},
{x:40.108799,y:-75.213999},
{x:41.771772,y:-70.73543},
{x:40.214681,y:-74.620613},
{x:40.733271,y:-74.192279},
{x:43.479124,y:-70.603233},
{x:40.76892,y:-73.90622},
{x:42.83166,y:-70.99933},
{x:42.557784,y:-72.286229},
{x:42.268467,y:-72.670959},
{x:42.368402,y:-71.076064},
{x:41.679953,y:-72.712559},
{x:42.27766,y:-71.065518},
{x:41.582934,y:-73.036893},
{x:33.452042,y:-111.688316},
{x:41.997596,y:-87.727937},
{x:42.509247,y:-71.123423},
{x:42.216882,y:-70.921985},
{x:43.20943,y:-77.601306},
{x:41.296891,y:-72.765736},
{x:43.2298,y:-71.4819},
{x:41.609442,y:-72.899285},
{x:40.824354,y:-73.502241},
{x:43.164566,y:-77.647488},
{x:42.240899,y:-70.989377},
{x:41.974031,y:-71.318238},
{x:39.760602,y:-75.359026},
{x:40.644465,y:-73.593006},
{x:40.6737,y:-73.79142},
{x:38.965934,y:-76.95503},
{x:40.686664,y:-73.99062},
{x:40.569718,y:-74.291443},
{x:43.096869,y:-77.631485},
{x:39.344128,y:-76.73191},
{x:42.355078,y:-71.139152},
{x:40.825023,y:-73.899703},
{x:40.590187,y:-74.501897},
{x:39.706594,y:-74.276828},
{x:40.258595,y:-75.087745},
{x:40.236847,y:-75.536119},
{x:39.870581,y:-75.429403},
{x:42.869494,y:-70.882022},
{x:44.92031,y:-74.888894},
{x:40.8394,y:-73.27962},
{x:42.649946,y:-83.494023},
{x:42.058742,y:-71.067521},
{x:40.266064,y:-74.042693},
{x:43.004594,y:-75.982275},
{x:41.88554,y:-87.632196},
{x:44.336918,y:-69.792405},
{x:40.04861,y:-75.414503},
{x:39.99696,y:-75.74293},
{x:41.837808,y:-87.802947},
{x:26.708607,y:-80.203236},
{x:26.59241,y:-80.206575},
{x:26.315893,y:-80.186986},
{x:40.77899,y:-73.561656},
{x:42.39702,y:-71.60332},
{x:42.417486,y:-71.688743},
{x:40.484789,y:-74.280922},
{x:41.49177,y:-87.557608},
{x:40.296468,y:-73.987395},
{x:42.512014,y:-71.040674},
{x:44.088064,y:-70.222018},
{x:41.876703,y:-88.234345},
{x:41.848412,y:-87.810696},
{x:40.178856,y:-74.026135},
{x:41.239722,y:-81.342879},
{x:40.687899,y:-75.253399},
{x:42.470902,y:-72.615383},
{x:41.232049,y:-73.053025},
{x:33.864223,y:-78.64592},
{x:43.572822,y:-70.68378},
{x:40.716,y:-73.39313},
{x:40.77029,y:-73.90382},
{x:39.90809,y:-75.286728},
{x:39.979277,y:-75.270484},
{x:41.628977,y:-73.696739},
{x:41.220703,y:-74.016603},
{x:40.735196,y:-74.068942},
{x:40.757363,y:-73.969632},
{x:40.809585,y:-73.217303},
{x:40.710487,y:-73.772013},
{x:40.8228,y:-72.99794},
{x:40.045206,y:-75.232055},
{x:40.81245,y:-73.90478},
{x:40.853711,y:-73.908685},
{x:41.640906,y:-88.202432},
{x:40.431385,y:-74.385475},
{x:40.31031,y:-74.319325},
{x:42.059943,y:-71.733533},
{x:42.203853,y:-71.837208},
{x:40.893,y:-74.116635},
{x:43.216561,y:-77.447273},
{x:44.112116,y:-70.111043},
{x:33.843648,y:-83.908362},
{x:38.942811,y:-76.561193},
{x:41.314404,y:-74.135098},
{x:39.881087,y:-75.545109},
{x:43.597318,y:-71.72828},
{x:41.732901,y:-72.356867},
{x:28.759035,y:-81.286519},
{x:28.651941,y:-81.349105},
{x:28.568737,y:-81.283284},
{x:42.098064,y:-72.314527},
{x:40.575574,y:-74.270957},
{x:41.934715,y:-71.31593},
{x:42.275756,y:-71.237569},
{x:40.744872,y:-74.028817},
{x:26.092536,y:-80.252344},
{x:42.116395,y:-72.55046},
{x:42.142152,y:-72.556116},
{x:40.768288,y:-74.044884},
{x:40.957227,y:-73.963751},
{x:27.273291,y:-80.344455},
{x:41.504107,y:-87.891375},
{x:40.849231,y:-73.883549},
{x:40.568006,y:-74.624701},
{x:42.171919,y:-71.195368},
{x:42.84255,y:-73.747425},
{x:42.09714,y:-72.586255},
{x:42.209858,y:-72.629966},
{x:27.258098,y:-80.281174},
{x:27.10939,y:-80.258535},
{x:42.388319,y:-71.103364},
{x:41.738319,y:-72.181023},
{x:41.538173,y:-88.19982},
{x:28.5654,y:-81.207774},
{x:42.257458,y:-88.149557},
{x:28.821463,y:-81.702974},
{x:41.749759,y:-71.422434},
{x:40.823475,y:-73.873938},
{x:41.257721,y:-73.934222},
{x:41.428131,y:-75.6239},
{x:41.349826,y:-72.560569},
{x:41.485164,y:-71.523804},
{x:42.421609,y:-71.005849},
{x:41.421329,y:-73.629637},
{x:41.115777,y:-74.151927},
{x:41.892827,y:-87.622635},
{x:40.626583,y:-74.163771},
{x:42.253566,y:-71.469952},
{x:42.133082,y:-71.262661},
{x:39.732233,y:-75.666077},
{x:40.672649,y:-73.91196},
{x:41.808461,y:-71.434326},
{x:42.854018,y:-73.343419},
{x:39.757247,y:-75.275772},
{x:40.735993,y:-74.029446},
{x:41.281835,y:-73.926234},
{x:41.165927,y:-73.856334},
{x:40.63535,y:-73.96802},
{x:40.945919,y:-74.050853},
{x:41.458596,y:-73.236572},
{x:41.98426,y:-72.447982},
{x:26.187395,y:-80.116009},
{x:38.348067,y:-75.079019},
{x:26.234245,y:-80.194701},
{x:44.55263,y:-68.430033},
{x:41.038685,y:-73.808434},
{x:41.033452,y:-73.75662},
{x:43.035765,y:-71.068857},
{x:42.340866,y:-71.081707},
{x:42.75088,y:-71.486273},
{x:42.360666,y:-71.056373},
{x:39.624331,y:-77.697898},
{x:41.955725,y:-71.702583},
{x:40.826782,y:-73.109207},
{x:28.672979,y:-81.507097},
{x:42.08016,y:-73.959486},
{x:41.661121,y:-87.754445},
{x:43.315713,y:-73.609724},
{x:40.097816,y:-75.156545},
{x:42.476799,y:-71.090654},
{x:42.479867,y:-71.120111},
{x:42.389058,y:-71.040802},
{x:40.249199,y:-74.773899},
{x:28.470193,y:-81.368093},
{x:40.83842,y:-75.296009},
{x:40.668642,y:-74.107155},
{x:43.686581,y:-70.322495},
{x:42.44842,y:-71.009548},
{x:40.678124,y:-73.898267},
{x:40.676879,y:-73.916084},
{x:40.717661,y:-74.327062},
{x:40.746216,y:-73.993991},
{x:39.978835,y:-74.870828},
{x:40.227044,y:-74.009453},
{x:40.123274,y:-74.284965},
{x:40.500804,y:-74.411695},
{x:40.007653,y:-74.215812},
{x:41.447915,y:-87.469866},
{x:41.745476,y:-87.799236},
{x:35.816775,y:-78.601857},
{x:41.491622,y:-87.496345},
{x:39.974001,y:-74.068423},
{x:40.736803,y:-73.319938},
{x:39.901078,y:-74.832194},
{x:40.521733,y:-74.216854},
{x:40.598104,y:-74.485584},
{x:42.337932,y:-71.089949},
{x:43.538926,y:-70.418922},
{x:42.50279,y:-71.014911},
{x:43.040001,y:-71.47093},
{x:42.165353,y:-71.163764},
{x:43.26448,y:-70.906808},
{x:40.071195,y:-74.964871},
{x:41.91588,y:-71.51935},
{x:42.899911,y:-73.209809},
{x:42.984922,y:-71.412174},
{x:42.116872,y:-77.941111},
{x:38.913605,y:-77.017577},
{x:41.631331,y:-87.930854},
{x:40.116581,y:-88.211444},
{x:40.06999,y:-74.12934},
{x:39.92902,y:-75.229381},
{x:42.595942,y:-70.991987},
{x:42.365918,y:-71.060629},
{x:42.938748,y:-71.426183},
{x:41.890809,y:-87.666655},
{x:42.34102,y:-71.579206},
{x:42.897488,y:-71.074102},
{x:40.394331,y:-74.387011},
{x:40.262319,y:-74.432861},
{x:39.946645,y:-75.026715},
{x:40.390632,y:-74.339504},
{x:40.303442,y:-74.357019},
{x:42.862742,y:-71.349913},
{x:40.132822,y:-74.224825},
{x:41.112331,y:-74.104278},
{x:41.303353,y:-73.254045},
{x:40.64516,y:-74.02135},
{x:43.296671,y:-70.99212},
{x:40.820803,y:-73.937373},
{x:42.294507,y:-71.717837},
{x:39.952054,y:-75.162439},
{x:42.783957,y:-73.913143},
{x:42.007977,y:-71.509163},
{x:40.811047,y:-74.140366},
{x:40.628843,y:-74.022793},
{x:40.237689,y:-74.03708},
{x:40.880779,y:-73.396964},
{x:40.70746,y:-73.83623},
{x:41.849547,y:-71.403038},
{x:26.393421,y:-80.111606},
{x:40.654742,y:-74.62062},
{x:42.746076,y:-73.641685},
{x:42.427347,y:-73.688449},
{x:41.465764,y:-71.511548},
{x:40.89266,y:-74.04512},
{x:40.808024,y:-73.945751},
{x:41.593865,y:-87.636727},
{x:40.536166,y:-74.347903},
{x:40.65038,y:-73.94942},
{x:40.759299,y:-73.98061},
{x:40.560203,y:-74.163601},
{x:40.109087,y:-75.327755},
{x:40.131756,y:-75.115536},
{x:41.488253,y:-75.695727},
{x:39.102006,y:-75.543209},
{x:42.819797,y:-71.252022},
{x:42.474004,y:-70.989733},
{x:39.951793,y:-74.145282},
{x:41.629232,y:-87.895655},
{x:42.457192,y:-71.082865},
{x:41.711371,y:-71.397751},
{x:42.461528,y:-83.201642},
{x:41.55688,y:-87.714721},
{x:43.242379,y:-73.682874},
{x:40.894955,y:-73.976397},
{x:40.98192,y:-74.006092},
{x:39.104823,y:-76.721908},
{x:42.356661,y:-71.1877},
{x:43.129332,y:-77.477781},
{x:41.528305,y:-71.551206},
{x:41.656388,y:-70.912376},
{x:41.317171,y:-72.943575},
{x:41.872774,y:-72.660332},
{x:41.365596,y:-73.14291},
{x:42.134114,y:-71.101478},
{x:42.544268,y:-71.65517},
{x:40.643685,y:-74.077369},
{x:42.088616,y:-71.092513},
{x:42.246372,y:-71.005405},
{x:41.63624,y:-70.95448},
{x:40.081799,y:-75.171999},
{x:40.190709,y:-74.831207},
{x:41.964079,y:-70.708107},
{x:42.600704,y:-83.933122},
{x:40.65306,y:-74.324216},
{x:40.754376,y:-73.625179},
{x:39.827972,y:-75.090151},
{x:40.779085,y:-74.015905},
{x:40.65274,y:-73.921715},
{x:40.204327,y:-75.139223},
{x:42.3487,y:-71.095787},
{x:42.340003,y:-71.167331},
{x:39.799409,y:-75.461502},
{x:39.71081,y:-75.74281},
{x:39.683237,y:-75.751482},
{x:43.642545,y:-70.399509},
{x:44.721585,y:-67.44721},
{x:42.348794,y:-71.081867},
{x:40.985618,y:-74.168828},
{x:42.861208,y:-71.285993},
{x:40.0513,y:-75.118324},
{x:41.945609,y:-87.776639},
{x:42.767509,y:-71.224973},
{x:43.328365,y:-70.948738},
{x:41.874209,y:-87.626193},
{x:42.974987,y:-71.514344},
{x:33.543952,y:-84.576337},
{x:42.865651,y:-72.615385},
{x:40.667902,y:-73.705257},
{x:43.128543,y:-70.770149},
{x:28.812188,y:-81.328861},
{x:40.74105,y:-73.49642},
{x:40.745665,y:-73.998365},
{x:41.534167,y:-73.897462},
{x:40.67528,y:-73.86582},
{x:42.165476,y:-70.894539},
{x:41.548503,y:-73.043371},
{x:40.896191,y:-74.234975},
{x:40.640196,y:-75.430441},
{x:42.984768,y:-77.41485},
{x:39.212775,y:-76.937173},
{x:39.030214,y:-77.075235},
{x:41.580684,y:-87.509369},
{x:41.471412,y:-87.361832},
{x:41.824841,y:-71.338777},
{x:44.545143,y:-69.673653},
{x:41.960801,y:-87.727701},
{x:40.71382,y:-73.9012},
{x:40.680506,y:-73.753289},
{x:40.260788,y:-76.883464},
{x:41.286617,y:-72.416305},
{x:40.848499,y:-73.166809},
{x:40.596555,y:-73.754616},
{x:42.955073,y:-71.432433},
{x:43.495813,y:-72.135965},
{x:42.98103,y:-78.85277},
{x:39.911795,y:-75.150061},
{x:42.152701,y:-88.433545},
{x:41.863999,y:-87.68655},
{x:39.938492,y:-75.169798},
{x:42.933155,y:-72.29379},
{x:25.823383,y:-80.187445},
{x:40.706958,y:-73.896997},
{x:39.609899,y:-75.709076},
{x:39.896268,y:-74.94263},
{x:40.16028,y:-74.910528},
{x:26.136022,y:-80.234013},
{x:40.74566,y:-73.71477},
{x:41.883099,y:-87.625134},
{x:40.596574,y:-73.933584},
{x:26.024608,y:-80.2282},
{x:40.72701,y:-73.906782},
{x:29.02581,y:-82.15315},
{x:42.65648,y:-77.056062},
{x:42.551489,y:-73.674356},
{x:40.560825,y:-76.003221},
{x:40.638829,y:-74.89589},
{x:42.579666,y:-73.680866},
{x:43.064737,y:-77.31878},
{x:40.598755,y:-74.325794},
{x:26.212413,y:-81.786739},
{x:41.904575,y:-87.648315},
{x:41.053444,y:-85.228316},
{x:42.703813,y:-73.188217},
{x:40.875748,y:-74.122229},
{x:40.98942,y:-74.33422},
{x:40.65701,y:-74.400527},
{x:40.83475,y:-73.38223},
{x:40.78233,y:-74.021166},
{x:44.480493,y:-73.215511},
{x:43.146112,y:-77.559997},
{x:25.656428,y:-80.405002},
{x:42.650684,y:-70.935327},
{x:42.454469,y:-71.023829},
{x:42.480538,y:-71.151065},
{x:40.649752,y:-74.232421},
{x:41.114302,y:-73.415434},
{x:42.791701,y:-71.183779},
{x:42.525802,y:-71.580722},
{x:42.468513,y:-71.147802},
{x:42.398251,y:-71.040541},
{x:39.667274,y:-75.695691},
{x:41.537454,y:-73.004459},
{x:42.380557,y:-71.236817},
{x:42.575463,y:-71.072763},
{x:40.770852,y:-73.49438},
{x:42.174425,y:-71.753374},
{x:42.433214,y:-71.046825},
{x:39.756029,y:-75.567476},
{x:40.824457,y:-73.909232},
{x:43.041657,y:-76.136767},
{x:42.720832,y:-71.321},
{x:41.697919,y:-70.073458},
{x:40.733638,y:-74.003045},
{x:41.705573,y:-72.846692},
{x:42.504321,y:-70.922368},
{x:40.969298,y:-73.882916},
{x:41.057784,y:-74.068018},
{x:40.893063,y:-74.206435},
{x:40.790049,y:-74.193805},
{x:40.635215,y:-73.962497},
{x:40.759726,y:-73.965148},
{x:40.786522,y:-73.502926},
{x:40.210622,y:-75.224146},
{x:38.670175,y:-77.00913},
{x:41.929267,y:-87.787297},
{x:39.158612,y:-76.709636},
{x:39.344004,y:-76.640247},
{x:41.988767,y:-87.959912},
{x:27.30573,y:-80.3082},
{x:43.698261,y:-71.640309},
{x:43.650885,y:-70.345357},
{x:40.97927,y:-73.83062},
{x:41.484798,y:-72.681504},
{x:42.806625,y:-71.567525},
{x:40.945839,y:-73.882529},
{x:42.296684,y:-71.482731},
{x:42.640551,y:-72.004537},
{x:26.230518,y:-80.160515},
{x:40.797626,y:-74.065404},
{x:41.889524,y:-87.626809},
{x:39.751298,y:-75.584907},
{x:40.235397,y:-74.080365},
{x:41.652486,y:-70.287588},
{x:43.049011,y:-75.902432},
{x:41.661045,y:-72.356777},
{x:39.322779,y:-76.750179},
{x:40.70215,y:-73.88068},
{x:41.81421,y:-72.52869},
{x:42.609125,y:-71.943088},
{x:40.45666,y:-74.40144},
{x:41.551694,y:-87.436368},
{x:42.723289,y:-73.80138},
{x:41.722332,y:-72.286322},
{x:41.721362,y:-72.237744},
{x:41.59071,y:-71.991281},
{x:41.688204,y:-72.769958},
{x:40.715113,y:-73.832723},
{x:40.764007,y:-73.771329},
{x:42.298826,y:-83.274256},
{x:41.802348,y:-87.762463},
{x:37.260541,y:-77.326898},
{x:40.952445,y:-74.108103},
{x:40.74012,y:-73.97889},
{x:42.421625,y:-71.371584},
{x:41.824525,y:-71.436653},
{x:40.31823,y:-74.619276},
{x:42.19185,y:-71.77107},
{x:41.950874,y:-87.747079},
{x:41.850077,y:-87.831893},
{x:40.769764,y:-74.027557},
{x:40.812589,y:-74.390051},
{x:40.727248,y:-74.00559},
{x:40.64621,y:-73.90333},
{x:40.782466,y:-74.034963},
{x:44.669898,y:-70.15079},
{x:44.0412,y:-70.7},
{x:40.805959,y:-72.846029},
{x:40.737593,y:-73.795956},
{x:42.641881,y:-71.236063},
{x:40.86239,y:-73.84366},
{x:41.795685,y:-72.663224},
{x:40.855471,y:-73.791372},
{x:41.682439,y:-81.295488},
{x:42.363957,y:-87.999472},
{x:41.969103,y:-87.708152},
{x:26.19811,y:-80.096743},
{x:40.449022,y:-75.896287},
{x:42.312285,y:-88.433884},
{x:35.656513,y:-78.477067},
{x:33.284279,y:-84.290628},
{x:33.585522,y:-79.012274},
{x:41.786757,y:-71.077121},
{x:41.921659,y:-87.695262},
{x:35.208654,y:-80.835713},
{x:42.27517,y:-71.678844},
{x:40.672285,y:-74.214171},
{x:44.776173,y:-68.732072},
{x:41.928801,y:-71.923445},
{x:42.257819,y:-71.040344},
{x:42.399135,y:-71.132603},
{x:42.267523,y:-71.120843},
{x:40.77038,y:-73.95716},
{x:42.774908,y:-71.250188},
{x:39.747761,y:-75.112782},
{x:42.074335,y:-71.259603},
{x:40.007253,y:-75.123414},
{x:40.929848,y:-74.026077},
{x:39.450063,y:-75.703593},
{x:33.987693,y:-83.984634},
{x:43.090212,y:-75.751247},
{x:40.593593,y:-73.940374},
{x:40.684356,y:-73.978662},
{x:27.960228,y:-82.732418},
{x:40.28423,y:-74.674973},
{x:40.669084,y:-73.986503},
{x:41.586246,y:-81.21716},
{x:40.084558,y:-76.217081},
{x:41.729216,y:-71.166347},
{x:39.888068,y:-75.120602},
{x:42.101825,y:-72.567223},
{x:40.824408,y:-73.892113},
{x:28.012765,y:-82.505318},
{x:40.617853,y:-73.959679},
{x:42.770297,y:-73.821519},
{x:40.66163,y:-73.92036},
{x:28.193967,y:-82.740561},
{x:29.185901,y:-82.181646},
{x:40.686079,y:-74.437284},
{x:39.199991,y:-76.641365},
{x:40.138409,y:-74.947711},
{x:27.338814,y:-82.4534},
{x:40.220573,y:-74.263422},
{x:41.67524,y:-71.108659},
{x:41.83213,y:-70.751176},
{x:42.582871,y:-72.261649},
{x:40.45535,y:-74.39391},
{x:40.239547,y:-74.774774},
{x:40.219624,y:-74.764007},
{x:41.669688,y:-70.931464},
{x:41.600131,y:-72.676286},
{x:43.123196,y:-73.76861},
{x:41.288288,y:-72.957345},
{x:42.108779,y:-87.890677},
{x:41.597576,y:-72.75425},
{x:41.34731,y:-72.076233},
{x:40.493632,y:-74.443477},
{x:40.334435,y:-74.245543},
{x:41.379173,y:-72.075854},
{x:43.100061,y:-73.748503},
{x:42.952757,y:-78.697651},
{x:39.130574,y:-76.55784},
{x:39.095741,y:-76.624303},
{x:41.136636,y:-81.785774},
{x:40.718324,y:-73.986904},
{x:40.66602,y:-75.3079},
{x:32.616588,y:-83.694446},
{x:41.609541,y:-81.526329},
{x:41.425341,y:-81.497512},
{x:42.620497,y:-73.702105},
{x:41.269628,y:-72.958393},
{x:41.818389,y:-73.14497},
{x:40.115696,y:-75.346943},
{x:42.210718,y:-71.42659},
{x:40.655702,y:-74.005765},
{x:41.289169,y:-72.52837},
{x:40.822572,y:-74.414793},
{x:42.109836,y:-72.765141},
{x:42.083355,y:-71.453383},
{x:42.802871,y:-73.956015},
{x:40.859326,y:-73.620558},
{x:40.74657,y:-73.89079},
{x:26.18085,y:-80.252037},
{x:26.668335,y:-81.891223},
{x:27.99864,y:-80.648495},
{x:39.399378,y:-76.799718},
{x:41.539998,y:-73.02123},
{x:41.584781,y:-71.939542},
{x:41.445964,y:-81.853277},
{x:42.023072,y:-87.939404},
{x:40.207367,y:-74.69603},
{x:40.714155,y:-74.008661},
{x:42.403814,y:-71.059057},
{x:33.76365,y:-78.973775},
{x:42.28167,y:-88.208977},
{x:28.927648,y:-82.013981},
{x:41.99758,y:-87.80828},
{x:41.391603,y:-73.723519},
{x:42.236525,y:-71.793175},
{x:41.716395,y:-71.522953},
{x:40.519714,y:-74.263697},
{x:44.31144,y:-74.116671},
{x:42.533143,y:-83.166171},
{x:39.54466,y:-76.10862},
{x:42.365918,y:-71.060629},
{x:41.321373,y:-73.855927},
{x:35.304759,y:-80.858481},
{x:42.3949,y:-71.261},
{x:39.066238,y:-76.717582},
{x:40.861506,y:-74.753044},
{x:43.232399,y:-70.897515},
{x:41.882569,y:-71.027789},
{x:39.664194,y:-75.578953},
{x:40.804447,y:-73.922181},
{x:33.640059,y:-78.988448},
{x:42.005304,y:-70.757775},
{x:42.641785,y:-71.308497},
{x:42.052304,y:-71.484181},
{x:42.024435,y:-71.215897},
{x:42.568402,y:-77.71767},
{x:29.054421,y:-81.29614},
{x:27.757754,y:-82.736341},
{x:40.785731,y:-73.649095},
{x:39.956786,y:-74.984782},
{x:40.865881,y:-73.364096},
{x:43.404483,y:-72.411331},
{x:40.413683,y:-75.922519},
{x:39.384417,y:-77.257729},
{x:28.120957,y:-80.704269},
{x:40.025217,y:-74.855451},
{x:40.027337,y:-74.907124},
{x:40.044495,y:-75.119443},
{x:40.745334,y:-74.164196},
{x:40.868187,y:-74.695744},
{x:41.649322,y:-70.928422},
{x:39.685735,y:-75.679297},
{x:39.468486,y:-76.829889},
{x:41.887171,y:-88.018176},
{x:40.485967,y:-78.400455},
{x:40.742096,y:-73.854127},
{x:40.789377,y:-74.391784},
{x:39.426504,y:-75.233393},
{x:41.57473,y:-72.13256},
{x:40.299411,y:-74.682416},
{x:41.844942,y:-70.848434},
{x:40.767488,y:-73.989675},
{x:40.800568,y:-73.994159},
{x:41.671389,y:-87.739322},
{x:41.668579,y:-72.817369},
{x:40.78773,y:-73.95359},
{x:40.697988,y:-73.629056},
{x:40.74341,y:-73.92268},
{x:35.105956,y:-78.878771},
{x:40.748527,y:-73.973213},
{x:40.536414,y:-74.295069},
{x:28.10219,y:-80.672855},
{x:40.014582,y:-75.130425},
{x:42.278382,y:-83.270293},
{x:41.748709,y:-87.740921},
{x:42.153136,y:-71.008508},
{x:38.136131,y:-79.045779},
{x:40.859114,y:-73.89857},
{x:41.835091,y:-87.744168},
{x:41.676226,y:-70.943113},
{x:37.40547,y:-77.660187},
{x:41.714629,y:-71.488525},
{x:41.871457,y:-87.669519},
{x:41.984423,y:-87.860293},
{x:41.966264,y:-87.688661},
{x:41.54029,y:-72.821382},
{x:42.939014,y:-70.838237},
{x:40.22116,y:-76.09791},
{x:40.361632,y:-75.626448},
{x:29.059092,y:-82.454687},
{x:40.498104,y:-74.434075},
{x:40.866515,y:-74.157572},
{x:43.71217,y:-71.4526},
{x:39.397015,y:-76.92604},
{x:27.265213,y:-80.414253},
{x:26.459055,y:-80.094125},
{x:28.542505,y:-82.402577},
{x:38.738613,y:-75.142087},
{x:42.332024,y:-71.073405},
{x:41.740296,y:-73.910137},
{x:40.679158,y:-80.300976},
{x:41.743738,y:-72.172028},
{x:26.498165,y:-80.1463},
{x:41.996659,y:-72.613598},
{x:41.698862,y:-87.520956},
{x:41.704638,y:-81.362991},
{x:35.983049,y:-80.017319},
{x:27.339457,y:-82.530818},
{x:39.76321,y:-75.133539},
{x:39.91619,y:-74.965767},
{x:41.427448,y:-71.784054},
{x:41.367946,y:-71.818736},
{x:41.715238,y:-71.151269},
{x:27.981986,y:-82.310211},
{x:40.76529,y:-73.86182},
{x:40.76747,y:-73.86489},
{x:42.08865,y:-70.79159},
{x:41.823577,y:-87.650196},
{x:41.036539,y:-74.03167},
{x:40.683502,y:-73.873018},
{x:28.080127,y:-82.437275},
{x:41.110627,y:-74.704686},
{x:43.777309,y:-75.481447},
{x:27.878029,y:-82.326234},
{x:30.242345,y:-81.597849},
{x:42.446704,y:-71.358998},
{x:42.357872,y:-71.062811},
{x:39.144037,y:-76.897787},
{x:40.839013,y:-73.9149},
{x:26.232745,y:-80.293411},
{x:42.3628,y:-71.0176},
{x:38.858142,y:-77.051365},
{x:40.729354,y:-74.007149},
{x:41.580376,y:-72.672943},
{x:42.055991,y:-72.55415},
{x:41.802691,y:-87.825031},
{x:26.78198,y:-80.08507},
{x:28.006318,y:-81.730541},
{x:40.048236,y:-82.864542},
{x:26.305431,y:-80.094122},
{x:26.27372,y:-81.70982},
{x:27.923703,y:-82.299288},
{x:39.404827,y:-76.444291},
{x:40.012523,y:-75.135591},
{x:35.166511,y:-80.875731},
{x:40.535383,y:-74.506962},
{x:42.391224,y:-71.085983},
{x:41.065863,y:-74.134681},
{x:40.836273,y:-73.838978},
{x:40.836334,y:-73.838298},
{x:36.062727,y:-80.257959},
{x:40.306005,y:-75.977859},
{x:40.023195,y:-75.076928},
{x:27.26953,y:-82.45778},
{x:26.062299,y:-80.208099},
{x:34.975804,y:-81.024959},
{x:41.916756,y:-87.752632},
{x:40.733567,y:-73.869722},
{x:40.689316,y:-73.708547},
{x:39.721647,y:-75.026342},
{x:39.017562,y:-77.518787},
{x:40.82371,y:-75.980748},
{x:44.0035,y:-70.072948},
{x:42.160476,y:-76.878552},
{x:41.931919,y:-87.743527},
{x:41.684735,y:-70.207095},
{x:41.764547,y:-72.540823},
{x:41.387376,y:-72.900677},
{x:43.908501,y:-69.826001},
{x:40.341506,y:-75.98863},
{x:35.411391,y:-80.856112},
{x:44.745338,y:-68.838136},
{x:42.509404,y:-78.688207},
{x:40.654449,y:-74.129337},
{x:39.177782,y:-77.26971},
{x:40.706219,y:-73.536018},
{x:40.233006,y:-74.885043},
{x:42.174215,y:-71.876627},
{x:41.64982,y:-72.679324},
{x:41.81455,y:-72.695089},
{x:39.288414,y:-76.613481},
{x:40.653921,y:-74.197941},
{x:41.613771,y:-70.917982},
{x:41.031578,y:-74.63666},
{x:39.823165,y:-75.780979},
{x:40.541445,y:-74.398327},
{x:40.88463,y:-73.260922},
{x:28.007077,y:-81.956841},
{x:42.511954,y:-70.991884},
{x:42.811844,y:-71.580263},
{x:42.944896,y:-71.452207},
{x:42.173665,y:-70.883041},
{x:41.782874,y:-71.447155},
{x:42.079958,y:-87.98072},
{x:38.653811,y:-77.310367},
{x:40.78197,y:-73.8383},
{x:40.561605,y:-74.532843},
{x:43.085596,y:-72.439063},
{x:39.42562,y:-76.77925},
{x:42.253228,y:-88.01988},
{x:42.199678,y:-70.954985},
{x:41.563218,y:-87.50864},
{x:40.828322,y:-73.948765},
{x:40.936266,y:-74.154002},
{x:41.80664,y:-72.538252},
{x:44.307424,y:-71.801479},
{x:41.294444,y:-73.494764},
{x:41.517347,y:-72.097667},
{x:42.447357,y:-73.245521},
{x:42.53895,y:-71.139002},
{x:44.592796,y:-70.206352},
{x:44.034986,y:-69.513355},
{x:35.593968,y:-78.76828},
{x:42.339988,y:-71.089362},
{x:28.116114,y:-81.974317},
{x:41.128521,y:-75.361096},
{x:40.714744,y:-74.007839},
{x:41.790025,y:-72.661921},
{x:41.359264,y:-72.11608},
{x:42.71965,y:-87.854199},
{x:40.328701,y:-74.074646},
{x:41.482813,y:-87.984048},
{x:38.677699,y:-77.279963},
{x:40.192094,y:-74.64272},
{x:35.555285,y:-80.861268},
{x:42.57158,y:-71.99427},
{x:42.297276,y:-71.0157},
{x:35.262625,y:-80.773082},
{x:42.820813,y:-70.906259},
{x:40.639007,y:-73.919268},
{x:41.842621,y:-71.445005},
{x:40.155974,y:-75.86365},
{x:42.013149,y:-71.049157},
{x:42.205275,y:-71.545012},
{x:42.275672,y:-71.214395},
{x:40.723394,y:-74.179308},
{x:35.172945,y:-80.658129},
{x:40.825168,y:-72.982105},
{x:29.177213,y:-81.029152},
{x:28.14693,y:-80.672844},
{x:28.069528,y:-82.362767},
{x:26.8454,y:-80.061333},
{x:26.610163,y:-81.973735},
{x:26.301149,y:-80.134178},
{x:40.718371,y:-74.083818},
{x:42.07832,y:-71.031113},
{x:40.854873,y:-73.929792},
{x:42.540042,y:-71.613552},
{x:41.910716,y:-70.735872},
{x:44.597371,y:-75.150687},
{x:40.98743,y:-80.633315},
{x:40.76768,y:-73.52768},
{x:41.138253,y:-73.291038},
{x:41.9661,y:-75.741565},
{x:42.122,y:-71.033141},
{x:44.679217,y:-74.986684},
{x:40.046573,y:-74.99412},
{x:43.31366,y:-70.99395},
{x:43.044054,y:-71.68212},
{x:42.983917,y:-71.437869},
{x:42.047036,y:-87.98275},
{x:41.905639,y:-87.725983},
{x:41.522484,y:-88.155042},
{x:41.586454,y:-87.717177},
{x:43.047869,y:-74.217729},
{x:40.060852,y:-74.141498},
{x:29.028774,y:-80.901877},
{x:44.069573,y:-70.407314},
{x:43.802881,y:-70.823283},
{x:42.047366,y:-87.88513},
{x:41.659792,y:-70.275691},
{x:41.751989,y:-70.593622},
{x:41.291113,y:-72.96432},
{x:26.259887,y:-80.152426},
{x:41.571376,y:-73.099638},
{x:42.675937,y:-73.785168},
{x:26.03008,y:-80.31138},
{x:41.093077,y:-73.932686},
{x:40.845243,y:-73.866268},
{x:42.355852,y:-71.06019},
{x:41.427453,y:-74.169134},
{x:41.114771,y:-74.113928},
{x:40.690075,y:-73.951629},
{x:42.150374,y:-71.074809},
{x:25.939249,y:-80.279833},
{x:40.708312,y:-73.681171},
{x:27.938471,y:-82.249426},
{x:42.353328,y:-71.13836},
{x:42.358925,y:-71.059678},
{x:43.179724,y:-71.488571},
{x:41.765609,y:-72.672455},
{x:41.540019,y:-72.652871},
{x:41.673862,y:-72.647289},
{x:42.47533,y:-71.213361},
{x:42.311241,y:-72.623943},
{x:41.327413,y:-72.953125},
{x:40.054288,y:-74.179395},
{x:40.825242,y:-81.398742},
{x:41.466769,y:-82.020957},
{x:41.789272,y:-80.948101},
{x:41.026615,y:-81.462871},
{x:41.180876,y:-81.482799},
{x:44.1482,y:-72.03447},
{x:39.478602,y:-75.021162},
{x:40.810776,y:-74.260143},
{x:44.569991,y:-69.642803},
{x:40.915749,y:-74.167841},
{x:41.380041,y:-74.174066},
{x:40.875392,y:-74.383355},
{x:41.865355,y:-87.768887},
{x:26.915009,y:-82.046724},
{x:40.7459,y:-73.241168},
{x:42.395641,y:-71.141999},
{x:42.349793,y:-77.668934},
{x:40.385813,y:-74.444179},
{x:42.278276,y:-73.750847},
{x:42.907173,y:-78.752384},
{x:40.567675,y:-75.550998},
{x:41.734331,y:-73.580443},
{x:45.657604,y:-68.691898},
{x:40.397075,y:-74.221373},
{x:28.236805,y:-82.670975},
{x:41.404208,y:-72.84722},
{x:40.305998,y:-88.15611},
{x:40.640065,y:-73.604459},
{x:41.063757,y:-73.776033},
{x:41.711386,y:-88.253639},
{x:40.398411,y:-75.496504},
{x:41.715205,y:-72.664513},
{x:41.389952,y:-71.98761},
{x:40.429696,y:-74.517852},
{x:40.887708,y:-74.018087},
{x:40.83004,y:-73.985658},
{x:41.736898,y:-72.659926},
{x:42.125025,y:-72.567462},
{x:42.451064,y:-72.562577},
{x:44.837339,y:-69.296114},
{x:42.491677,y:-83.067843},
{x:44.0175,y:-71.1078},
{x:39.571723,y:-76.066571},
{x:42.336474,y:-88.028394},
{x:42.233206,y:-72.150729},
{x:38.945456,y:-77.449308},
{x:43.122765,y:-76.216395},
{x:42.50022,y:-71.745306},
{x:42.892496,y:-71.143431},
{x:26.104757,y:-80.189383},
{x:39.655889,y:-75.787204},
{x:40.92473,y:-74.085686},
{x:43.028537,y:-71.440175},
{x:42.412235,y:-71.148643},
{x:44.343291,y:-69.790214},
{x:27.779438,y:-82.679762},
{x:39.983421,y:-75.936342},
{x:41.387383,y:-73.478109},
{x:40.857315,y:-74.024873},
{x:38.945473,y:-77.453814},
{x:41.726294,y:-81.252069},
{x:41.675439,y:-72.876948},
{x:41.888822,y:-71.368801},
{x:35.784914,y:-78.870364},
{x:40.862627,y:-73.987156},
{x:41.659558,y:-73.848115},
{x:40.736748,y:-73.865718},
{x:39.395307,y:-76.580171},
{x:38.328775,y:-75.614118},
{x:38.9327,y:-75.4313},
{x:41.070329,y:-74.739926},
{x:39.963775,y:-75.627718},
{x:39.719727,y:-75.711146},
{x:28.032421,y:-82.424388},
{x:26.617526,y:-81.668449},
{x:42.907823,y:-78.867251},
{x:40.752368,y:-73.915205},
{x:40.243398,y:-74.749711},
{x:43.306198,y:-70.736905},
{x:42.710947,y:-71.140408},
{x:41.882953,y:-87.649668},
{x:38.715953,y:-75.081838},
{x:41.622854,y:-87.629136},
{x:40.82817,y:-75.70557},
{x:42.316836,y:-71.098107},
{x:40.739931,y:-73.635262},
{x:40.26535,y:-75.22833},
{x:42.854206,y:-74.009499},
{x:40.909316,y:-74.549402},
{x:40.741948,y:-73.130408},
{x:41.66388,y:-87.963179},
{x:40.209948,y:-74.737453},
{x:40.445388,y:-74.130311},
{x:39.842897,y:-75.729883},
{x:43.657436,y:-70.256788},
{x:43.566888,y:-71.427315},
{x:42.125466,y:-75.971621},
{x:44.761543,y:-69.564994},
{x:39.154216,y:-76.77884},
{x:40.22787,y:-74.940901},
{x:42.362807,y:-71.024735},
{x:42.3632,y:-71.0247},
{x:40.933389,y:-74.118469},
{x:43.334485,y:-71.012728},
{x:40.764714,y:-73.453192},
{x:40.76255,y:-73.976814},
{x:40.758104,y:-73.588009},
{x:41.9073,y:-87.911441},
{x:43.15115,y:-76.351346},
{x:40.735705,y:-73.979491},
{x:26.518391,y:-81.942817},
{x:43.162899,y:-76.120225},
{x:41.051259,y:-73.528839},
{x:40.715203,y:-74.19186},
{x:35.232435,y:-80.844842},
{x:42.684531,y:-73.847918},
{x:42.577202,y:-76.214921},
{x:40.784559,y:-74.772274},
{x:40.624595,y:-73.999746},
{x:42.354451,y:-71.059483},
{x:42.16862,y:-71.00669},
{x:41.267002,y:-72.872114},
{x:41.35094,y:-72.931298},
{x:40.771042,y:-73.526995},
{x:42.739813,y:-71.132295},
{x:42.614063,y:-71.578124},
{x:42.66495,y:-71.707473},
{x:35.202769,y:-80.72904},
{x:40.859115,y:-74.337213},
{x:40.913409,y:-74.045815},
{x:39.978022,y:-75.119364},
{x:40.649034,y:-73.963827},
{x:40.624356,y:-74.148017},
{x:41.7669,y:-88.186106},
{x:40.569458,y:-74.573517},
{x:39.78428,y:-74.948824},
{x:27.996285,y:-82.42073},
{x:39.962607,y:-75.16362},
{x:42.993542,y:-73.850653},
{x:26.588939,y:-81.942166},
{x:41.976208,y:-87.787757},
{x:43.300321,y:-71.347967},
{x:40.617699,y:-74.424947},
{x:38.895709,y:-77.104033},
{x:40.328577,y:-75.129176},
{x:42.13998,y:-72.504522},
{x:40.854353,y:-74.118843},
{x:42.054523,y:-73.95597},
{x:39.318399,y:-76.652799},
{x:39.977425,y:-74.247448},
{x:41.457072,y:-88.19243},
{x:43.975761,y:-69.708071},
{x:42.948766,y:-74.358196},
{x:35.595844,y:-80.876421},
{x:39.319736,y:-84.498676},
{x:43.097586,y:-78.975554},
{x:40.746825,y:-73.533679},
{x:42.65118,y:-89.031073},
{x:40.833479,y:-73.627991},
{x:40.067769,y:-75.068592},
{x:41.873168,y:-71.333245},
{x:43.458208,y:-70.792403},
{x:40.20045,y:-77.180053},
{x:39.499733,y:-76.170106},
{x:41.513229,y:-72.046961},
{x:40.568196,y:-74.612436},
{x:41.737213,y:-87.88457},
{x:41.156596,y:-81.219136},
{x:40.170187,y:-75.665219},
{x:41.298589,y:-73.936436},
{x:41.780198,y:-87.629749},
{x:41.493305,y:-71.624982},
{x:41.500051,y:-72.567244},
{x:41.702991,y:-87.798449},
{x:42.236774,y:-70.824895},
{x:41.889522,y:-87.980225},
{x:42.53864,y:-71.509762},
{x:41.965604,y:-87.861652},
{x:41.132262,y:-81.478743},
{x:43.095879,y:-75.242701},
{x:27.426694,y:-80.325627},
{x:41.834807,y:-72.307091},
{x:27.310488,y:-80.408402},
{x:39.650456,y:-77.160218},
{x:40.248747,y:-74.244579},
{x:40.781911,y:-73.111494},
{x:42.306276,y:-87.964911},
{x:35.290541,y:-80.796598},
{x:39.398764,y:-75.061562},
{x:35.24248,y:-81.125019},
{x:40.715769,y:-73.661022},
{x:41.620599,y:-72.742552},
{x:41.879643,y:-72.609155},
{x:41.555128,y:-72.671834},
{x:41.475879,y:-72.906613},
{x:41.851039,y:-72.483256},
{x:41.405287,y:-81.696296},
{x:40.339241,y:-76.450237},
{x:41.715155,y:-87.682171},
{x:40.246666,y:-75.690787},
{x:26.935047,y:-80.093106},
{x:42.503141,y:-70.890177},
{x:41.352182,y:-71.770579},
{x:42.529913,y:-71.206203},
{x:39.35039,y:-76.694353},
{x:41.938364,y:-87.781097},
{x:41.969263,y:-73.989308},
{x:39.804284,y:-75.028062},
{x:39.277781,y:-76.566165},
{x:41.73954,y:-72.684288},
{x:40.50697,y:-74.957374},
{x:41.863385,y:-87.901752},
{x:42.621535,y:-71.184607},
{x:39.523796,y:-76.1827},
{x:42.273257,y:-71.812349},
{x:42.400861,y:-71.021518},
{x:43.806834,y:-70.171755},
{x:40.615646,y:-73.993643},
{x:40.714753,y:-73.944304},
{x:42.194154,y:-71.290055},
{x:40.70242,y:-73.791511},
{x:40.73742,y:-74.172762},
{x:40.766507,y:-73.541266},
{x:40.20617,y:-75.09828},
{x:41.495486,y:-74.022123},
{x:42.432146,y:-71.449226},
{x:40.611855,y:-74.157499},
{x:42.121222,y:-70.913854},
{x:41.099467,y:-74.020793},
{x:46.699621,y:-68.010426},
{x:25.797716,y:-80.282316},
{x:40.838744,y:-73.981585},
{x:42.245967,y:-71.909139},
{x:40.587896,y:-74.165574},
{x:32.97605,y:-80.06663},
{x:40.706548,y:-89.593638},
{x:40.857939,y:-73.867476},
{x:40.816534,y:-73.892205},
{x:42.730445,y:-73.703397},
{x:43.054952,y:-75.27709},
{x:39.847047,y:-75.063431},
{x:43.107148,y:-75.244478},
{x:42.365166,y:-71.061034},
{x:39.377638,y:-84.54995},
{x:41.938682,y:-87.748824},
{x:38.976129,y:-84.658838},
{x:40.751926,y:-74.062508},
{x:41.885607,y:-87.641933},
{x:41.286065,y:-75.8731},
{x:40.783091,y:-73.953036},
{x:44.117905,y:-70.238847},
{x:43.476654,y:-70.505239},
{x:43.837116,y:-70.45024},
{x:41.5488,y:-87.890436},
{x:40.72412,y:-73.951171},
{x:40.555427,y:-75.580128},
{x:43.224679,y:-75.486007},
{x:42.734313,y:-73.696461},
{x:39.764775,y:-75.085418},
{x:40.771182,y:-74.065699},
{x:40.665758,y:-74.11684},
{x:26.589167,y:-80.148893},
{x:40.218666,y:-75.120595},
{x:40.71862,y:-74.161077},
{x:41.09385,y:-75.590628},
{x:41.698413,y:-71.971978},
{x:41.192744,y:-73.154221},
{x:28.019063,y:-82.764857},
{x:41.346963,y:-72.045908},
{x:30.324827,y:-81.407451},
{x:41.717662,y:-72.613437},
{x:40.612868,y:-73.926354},
{x:41.696171,y:-71.496719},
{x:27.840879,y:-82.638472},
{x:42.468873,y:-71.456345},
{x:40.668895,y:-75.348152},
{x:42.562133,y:-70.813663},
{x:43.002728,y:-70.918895},
{x:41.03639,y:-75.304063},
{x:42.494593,y:-70.933153},
{x:42.409735,y:-72.098352},
{x:41.88748,y:-87.620298},
{x:42.11517,y:-70.998946},
{x:41.654813,y:-71.577158},
{x:40.739612,y:-74.377006},
{x:43.642849,y:-72.119297},
{x:28.400867,y:-82.659369},
{x:25.810985,y:-80.38349},
{x:40.726422,y:-73.985972},
{x:40.694445,y:-75.489844},
{x:40.669369,y:-73.431986},
{x:42.783651,y:-73.655146},
{x:40.867661,y:-73.920831},
{x:42.374905,y:-73.831314},
{x:38.37484,y:-75.16341},
{x:39.32035,y:-77.84911},
{x:41.836013,y:-87.768049},
{x:39.363861,y:-76.520191},
{x:35.665386,y:-80.499878},
{x:39.658048,y:-75.092004},
{x:39.803644,y:-74.950857},
{x:26.153774,y:-80.283088},
{x:39.96107,y:-75.144289},
{x:40.900656,y:-80.819272},
{x:40.680449,y:-73.99667},
{x:40.768916,y:-74.040423},
{x:39.902104,y:-75.350246},
{x:40.796024,y:-73.935233},
{x:39.312516,y:-76.619066},
{x:41.860941,y:-71.465537},
{x:42.013334,y:-87.789087},
{x:40.028326,y:-75.628808},
{x:41.53631,y:-75.93543},
{x:42.364405,y:-71.062898},
{x:42.29937,y:-71.386873},
{x:40.513351,y:-74.851168},
{x:41.375566,y:-75.689578},
{x:41.367049,y:-72.886839},
{x:40.032609,y:-75.677946},
{x:41.909244,y:-87.768652},
{x:41.718428,y:-87.815102},
{x:39.67276,y:-77.720061},
{x:41.990066,y:-87.714159},
{x:40.276909,y:-74.780892},
{x:40.835562,y:-73.063773},
{x:40.755293,y:-73.98925},
{x:42.319334,y:-71.795706},
{x:40.71375,y:-75.284423},
{x:43.036087,y:-78.750268},
{x:42.151905,y:-87.985277},
{x:40.414893,y:-74.094236},
{x:42.654026,y:-71.326972},
{x:42.959542,y:-78.814377},
{x:38.974178,y:-76.995028},
{x:41.079059,y:-87.86883},
{x:43.404578,y:-76.119381},
{x:41.909942,y:-87.711777},
{x:41.638872,y:-87.45072},
{x:41.92473,y:-87.692391},
{x:40.884966,y:-74.213127},
{x:30.345697,y:-81.413875},
{x:41.521009,y:-81.527758},
{x:39.620162,y:-75.641771},
{x:41.056303,y:-73.52015},
{x:40.72497,y:-74.068929},
{x:41.455865,y:-88.27104},
{x:40.858648,y:-73.088122},
{x:42.0962,y:-71.00881},
{x:40.336543,y:-76.821714},
{x:42.271966,y:-71.792349},
{x:42.124731,y:-71.516459},
{x:41.494889,y:-71.457344},
{x:41.865176,y:-88.204481},
{x:41.921044,y:-71.907096},
{x:41.320485,y:-72.868083},
{x:41.583297,y:-73.074632},
{x:41.877061,y:-87.627369},
{x:40.45176,y:-74.438296},
{x:40.629003,y:-73.947417},
{x:39.875046,y:-75.241752},
{x:43.223855,y:-77.696568},
{x:42.468409,y:-88.064862},
{x:38.374008,y:-76.437416},
{x:38.775326,y:-77.058809},
{x:41.758851,y:-72.1443},
{x:29.141688,y:-82.058697},
{x:40.706215,y:-73.792131},
{x:27.99038,y:-82.121895},
{x:41.889242,y:-87.911678},
{x:41.93185,y:-72.601735},
{x:42.367417,y:-72.534022},
{x:38.993319,y:-76.67067},
{x:39.092295,y:-78.177963},
{x:42.493576,y:-76.297955},
{x:41.784726,y:-72.550392},
{x:42.588134,y:-76.130389},
{x:40.90684,y:-73.904605},
{x:41.625666,y:-87.696105},
{x:40.723925,y:-73.526912},
{x:42.230691,y:-71.753401},
{x:42.301526,y:-71.288742},
{x:42.438241,y:-76.508388},
{x:42.103271,y:-71.503651},
{x:42.32607,y:-71.06521},
{x:40.221168,y:-75.301403},
{x:40.82413,y:-74.096587},
{x:40.729974,y:-74.041745},
{x:42.228472,y:-70.986154},
{x:41.885628,y:-87.628798},
{x:42.116416,y:-76.019191},
{x:42.542041,y:-71.166862},
{x:39.788737,y:-74.990369},
{x:40.734798,y:-73.085063},
{x:25.943672,y:-80.340845},
{x:41.953604,y:-87.727299},
{x:39.964744,y:-75.301999},
{x:40.590912,y:-74.164518},
{x:42.862741,y:-73.778733},
{x:41.702804,y:-88.123513},
{x:41.294265,y:-72.89991},
{x:42.356683,y:-71.056588},
{x:40.88664,y:-74.53528},
{x:41.894632,y:-87.75578},
{x:43.41604,y:-71.987169},
{x:43.00129,y:-71.4644},
{x:44.475829,y:-70.192767},
{x:41.944617,y:-70.645367},
{x:40.811485,y:-73.124914},
{x:42.295404,y:-71.771357},
{x:42.575878,y:-70.850091},
{x:39.349208,y:-76.450446},
{x:40.032643,y:-74.144109},
{x:40.409415,y:-74.041067},
{x:41.993485,y:-71.211356},
{x:41.967581,y:-71.643511},
{x:40.103094,y:-75.030151},
{x:41.933182,y:-71.350408},
{x:40.046129,y:-75.056705},
{x:28.476389,y:-82.488432},
{x:42.361047,y:-71.062572},
{x:28.085121,y:-82.538528},
{x:39.978757,y:-75.158931},
{x:42.458865,y:-70.989678},
{x:42.194087,y:-71.028593},
{x:35.60513,y:-87.066227},
{x:35.959664,y:-86.801261},
{x:38.988167,y:-74.8082},
{x:40.755499,y:-73.978356},
{x:38.916925,y:-77.227162},
{x:42.082066,y:-71.638994},
{x:41.934734,y:-71.285889},
{x:41.812412,y:-87.707081},
{x:42.272048,y:-72.411043},
{x:28.852925,y:-82.383192},
{x:28.898546,y:-82.584789},
{x:40.620672,y:-80.055116},
{x:40.72592,y:-74.84625},
{x:40.711304,y:-74.216018},
{x:40.100351,y:-74.080964},
{x:43.0689,y:-77.441709},
{x:40.327531,y:-75.623475},
{x:42.392947,y:-71.034202},
{x:28.416377,y:-81.242581},
{x:40.888428,y:-74.25937},
{x:26.302256,y:-80.153059},
{x:30.282128,y:-81.650813},
{x:28.618266,y:-81.264764},
{x:42.346164,y:-71.096017},
{x:42.347118,y:-71.097903},
{x:40.904472,y:-74.102679},
{x:41.376733,y:-71.958226},
{x:41.927011,y:-71.354304},
{x:40.026371,y:-74.618218},
{x:43.154165,y:-75.973895},
{x:26.29833,y:-80.25048},
{x:39.876429,y:-74.816944},
{x:39.716786,y:-74.968436},
{x:40.273771,y:-74.536686},
{x:40.060484,y:-75.65306},
{x:41.023594,y:-74.242439},
{x:42.191606,y:-88.205664},
{x:41.748794,y:-88.041599},
{x:40.647038,y:-73.882862},
{x:39.954049,y:-75.168083},
{x:40.703,y:-73.799814},
{x:40.362799,y:-74.651142},
{x:39.277261,y:-76.837916},
{x:40.740645,y:-73.758423},
{x:40.83091,y:-73.910552},
{x:42.830882,y:-78.753454},
{x:40.734063,y:-74.050794},
{x:41.200336,y:-74.008181},
{x:43.04164,y:-76.269813},
{x:39.637172,y:-76.868272},
{x:41.555078,y:-73.784606},
{x:42.868832,y:-73.932896},
{x:40.691664,y:-75.209508},
{x:40.435592,y:-75.416928},
{x:40.141705,y:-75.167502},
{x:36.7137,y:-76.279217},
{x:42.119209,y:-71.458505},
{x:39.932034,y:-76.687296},
{x:29.686445,y:-82.339464},
{x:41.774158,y:-72.678352},
{x:40.772212,y:-74.230129},
{x:40.73868,y:-73.93445},
{x:42.756331,y:-73.758533},
{x:40.629131,y:-74.305237},
{x:41.466203,y:-74.359133},
{x:38.834645,y:-77.454037},
{x:39.511409,y:-76.642343},
{x:40.759035,y:-74.186953},
{x:40.689577,y:-73.66792},
{x:43.329044,y:-73.687099},
{x:41.219102,y:-80.777763},
{x:41.863862,y:-87.856124},
{x:42.405466,y:-71.101834},
{x:26.12602,y:-80.26266},
{x:39.611243,y:-75.948252},
{x:39.027763,y:-76.686711},
{x:41.859751,y:-72.430163},
{x:42.557895,y:-70.971602},
{x:27.2286,y:-82.493551},
{x:40.57615,y:-74.1645},
{x:27.171141,y:-80.292643},
{x:44.755248,y:-68.794397},
{x:43.851191,y:-71.643858},
{x:25.929143,y:-80.135805},
{x:41.492915,y:-73.679335},
{x:38.854156,y:-77.041404},
{x:38.856077,y:-77.04238},
{x:41.77434,y:-71.437214},
{x:42.818554,y:-73.771801},
{x:41.564508,y:-73.008629},
{x:40.735307,y:-74.171623},
{x:41.923985,y:-87.756181},
{x:41.958033,y:-87.75215},
{x:39.93876,y:-75.271167},
{x:39.957986,y:-75.258261},
{x:39.280453,y:-75.59241},
{x:42.080193,y:-78.477419},
{x:40.685681,y:-73.793605},
{x:41.876742,y:-87.624529},
{x:41.34154,y:-73.76428},
{x:40.748777,y:-73.991592},
{x:40.703184,y:-73.3431},
{x:41.785231,y:-87.703143},
{x:41.796407,y:-73.120309},
{x:39.493374,y:-75.649218},
{x:41.571598,y:-72.3373},
{x:42.9913,y:-78.82115},
{x:41.178376,y:-73.195829},
{x:42.263398,y:-71.802033},
{x:42.485948,y:-71.189516},
{x:42.024399,y:-73.323618},
{x:40.602086,y:-74.120459},
{x:40.56569,y:-74.11274},
{x:40.791132,y:-74.233299},
{x:41.807452,y:-88.033049},
{x:41.681209,y:-71.566744},
{x:29.659145,y:-82.41715},
{x:38.452196,y:-75.052415},
{x:43.128229,y:-78.837744},
{x:43.253298,y:-77.666082},
{x:27.525074,y:-80.396622},
{x:43.118049,y:-75.292646},
{x:40.109601,y:-76.484778},
{x:40.58705,y:-77.603594},
{x:40.204647,y:-74.954171},
{x:40.026665,y:-75.208958},
{x:40.015036,y:-75.178314},
{x:43.226727,y:-77.281649},
{x:43.363601,y:-73.699854},
{x:39.997836,y:-74.80248},
{x:25.891739,y:-80.162535},
{x:40.6197,y:-74.008246},
{x:26.492694,y:-81.847141},
{x:42.087521,y:-76.049524},
{x:40.744972,y:-73.982654},
{x:41.559142,y:-87.790256},
{x:40.0096,y:-75.19573},
{x:39.688002,y:-75.653399},
{x:42.790098,y:-73.978822},
{x:42.947447,y:-78.869421},
{x:42.350393,y:-71.166864},
{x:28.331347,y:-82.547734},
{x:41.578416,y:-70.560525},
{x:41.628199,y:-70.38474},
{x:41.711778,y:-73.964088},
{x:38.769784,y:-77.489558},
{x:41.664742,y:-70.809992},
{x:40.764877,y:-74.03408},
{x:41.65346,y:-70.32237},
{x:42.502223,y:-71.075044},
{x:42.385532,y:-71.183465},
{x:42.092942,y:-72.619151},
{x:43.149962,y:-77.690437},
{x:25.461988,y:-80.474826},
{x:40.630472,y:-74.441934},
{x:42.507037,y:-71.104399},
{x:41.750965,y:-70.598862},
{x:42.390604,y:-71.086608},
{x:40.803924,y:-74.019626},
{x:41.557225,y:-87.356352},
{x:41.935767,y:-72.684522},
{x:41.938433,y:-72.689185},
{x:38.801832,y:-77.160324},
{x:40.878412,y:-73.168936},
{x:26.5711,y:-81.871479},
{x:26.63167,y:-81.87236},
{x:43.014888,y:-75.036749},
{x:40.079259,y:-75.689296},
{x:41.196312,y:-73.192868},
{x:40.308225,y:-74.063976},
{x:40.784452,y:-74.146814},
{x:40.872919,y:-74.360915},
{x:41.62626,y:-73.772244},
{x:41.516624,y:-88.080627},
{x:41.506611,y:-87.661924},
{x:41.653374,y:-87.853234},
{x:42.111705,y:-72.719292},
{x:38.584109,y:-75.293153},
{x:38.06282,y:-75.54697},
{x:44.791688,y:-68.581697},
{x:42.546358,y:-71.914197},
{x:40.701378,y:-73.432557},
{x:39.940897,y:-74.211992},
{x:35.8182,y:-78.846826},
{x:32.87458,y:-80.02091},
{x:32.553655,y:-83.668419},
{x:42.384455,y:-87.855593},
{x:40.683665,y:-74.550913},
{x:34.67345,y:-77.33753},
{x:41.724014,y:-72.762913},
{x:42.565773,y:-71.979972},
{x:43.005302,y:-71.394199},
{x:44.79795,y:-69.87727},
{x:41.860413,y:-87.623785},
{x:41.584415,y:-71.651722},
{x:38.851645,y:-77.042182},
{x:41.819391,y:-87.821148},
{x:40.774064,y:-74.322938},
{x:40.647491,y:-75.595321},
{x:39.914341,y:-75.347106},
{x:39.13492,y:-84.49938},
{x:41.82058,y:-73.08152},
{x:41.988418,y:-72.556812},
{x:40.727137,y:-73.853353},
{x:41.849409,y:-88.340743},
{x:44.727633,y:-73.435388},
{x:42.022068,y:-87.958944},
{x:40.840881,y:-73.839386},
{x:41.744857,y:-87.721582},
{x:42.3654,y:-71.0147},
{x:41.844043,y:-71.882978},
{x:41.76258,y:-72.628443},
{x:41.334337,y:-72.104961},
{x:40.879414,y:-74.102012},
{x:33.637452,y:-84.013921},
{x:42.99165,y:-71.47347},
{x:25.759555,y:-80.43198},
{x:40.747764,y:-73.552152},
{x:40.325273,y:-76.018385},
{x:33.422687,y:-84.185356},
{x:38.973322,y:-76.771482},
{x:39.50658,y:-76.269548},
{x:35.721078,y:-78.84443},
{x:43.101323,y:-77.461862},
{x:40.676072,y:-74.000637},
{x:40.64726,y:-74.64024},
{x:28.144887,y:-82.281999},
{x:41.67159,y:-73.797124},
{x:41.043443,y:-73.8006},
{x:40.817397,y:-73.9227},
{x:40.102529,y:-74.508175},
{x:42.22584,y:-88.292106},
{x:38.497591,y:-76.774778},
{x:43.070972,y:-76.172536},
{x:40.721498,y:-74.00467},
{x:41.615092,y:-87.931464},
{x:26.065241,y:-80.31453},
{x:40.766355,y:-73.31055},
{x:26.252598,y:-80.224119},
{x:28.367646,y:-81.502012},
{x:40.747524,y:-73.88415},
{x:41.371809,y:-73.415042},
{x:40.111691,y:-74.854095},
{x:40.610462,y:-73.954441},
{x:40.768069,y:-73.261616},
{x:40.911876,y:-74.501064},
{x:42.033811,y:-87.747476},
{x:41.044551,y:-73.953028},
{x:40.168331,y:-74.876645},
{x:39.123102,y:-84.136324},
{x:41.331757,y:-75.955102},
{x:38.29591,y:-76.504945},
{x:43.487778,y:-73.757907},
{x:41.151736,y:-73.830087},
{x:40.807811,y:-73.95264},
{x:42.102262,y:-75.844805},
{x:35.613254,y:-82.555099},
{x:41.677323,y:-72.168396},
{x:40.87474,y:-74.43507},
{x:41.497128,y:-88.167841},
{x:35.569739,y:-82.545245},
{x:43.273565,y:-71.4621},
{x:40.759178,y:-73.970529},
{x:28.237155,y:-82.359553},
{x:38.978161,y:-77.410423},
{x:40.02995,y:-76.232068},
{x:40.038468,y:-76.356528},
{x:42.03997,y:-87.859652},
{x:41.35221,y:-72.409994},
{x:40.621854,y:-74.028872},
{x:41.101636,y:-73.436624},
{x:40.575855,y:-73.981052},
{x:40.74773,y:-74.000459},
{x:26.592749,y:-81.872405},
{x:40.701623,y:-73.59291},
{x:42.33994,y:-71.10553},
{x:28.401042,y:-80.705896},
{x:38.958114,y:-77.37359},
{x:43.108458,y:-77.744625},
{x:42.281052,y:-87.972797},
{x:40.130685,y:-74.922606},
{x:40.772531,y:-73.278554},
{x:41.760974,y:-72.706483},
{x:41.908626,y:-87.839324},
{x:41.441454,y:-72.106383},
{x:41.51145,y:-72.114172},
{x:41.590646,y:-71.991445},
{x:41.780693,y:-88.204731},
{x:41.295386,y:-72.782856},
{x:41.574957,y:-71.863104},
{x:41.372308,y:-71.960083},
{x:41.720589,y:-72.202765},
{x:41.4641,y:-73.162508},
{x:39.29891,y:-76.43851},
{x:35.028925,y:-80.851133},
{x:40.754138,y:-73.99184},
{x:41.338977,y:-73.263021},
{x:40.382734,y:-74.574892},
{x:42.640723,y:-71.317516},
{x:40.856882,y:-74.410328},
{x:37.355009,y:-77.448055},
{x:26.673141,y:-80.087171},
{x:34.942805,y:-82.255484},
{x:41.885626,y:-87.626627},
{x:41.75832,y:-88.251013},
{x:42.459237,y:-79.316037},
{x:42.020367,y:-88.203595},
{x:43.913155,y:-69.979064},
{x:40.950249,y:-73.729823},
{x:39.820351,y:-75.039954},
{x:41.16276,y:-81.475275},
{x:29.625891,y:-82.372895},
{x:40.98908,y:-73.808845},
{x:40.72121,y:-75.074124},
{x:28.211513,y:-82.354356},
{x:40.940195,y:-73.993806},
{x:40.290522,y:-76.65423},
{x:40.046444,y:-74.110285},
{x:40.773972,y:-74.020694},
{x:39.960702,y:-86.126342},
{x:41.908753,y:-87.805587},
{x:39.919636,y:-76.632022},
{x:39.912667,y:-86.181248},
{x:40.734156,y:-73.17657},
{x:26.612182,y:-80.085005},
{x:43.239193,y:-70.878524},
{x:41.755844,y:-72.729263},
{x:42.606891,y:-76.167926},
{x:39.953853,y:-75.258141},
{x:41.387228,y:-73.185869},
{x:39.70196,y:-75.55321},
{x:40.828381,y:-74.081175},
{x:35.341013,y:-80.769719},
{x:40.772972,y:-73.794905},
{x:40.364496,y:-74.951677},
{x:40.234301,y:-77.121768},
{x:42.005802,y:-88.046257},
{x:40.299122,y:-75.846246},
{x:40.69537,y:-74.26887},
{x:40.870316,y:-73.878817},
{x:40.677619,y:-73.470007},
{x:40.597873,y:-73.960959},
{x:41.881409,y:-71.400029},
{x:40.612835,y:-73.982174},
{x:25.994583,y:-80.265185},
{x:41.222195,y:-73.168864},
{x:27.893421,y:-82.735354},
{x:28.279368,y:-82.674799},
{x:27.492577,y:-82.482512},
{x:43.11081,y:-74.26731},
{x:41.54248,y:-88.20296},
{x:39.324862,y:-77.34811},
{x:36.218796,y:-86.573786},
{x:41.383251,y:-72.026657},
{x:36.189688,y:-86.615726},
{x:35.081874,y:-80.729689},
{x:42.150804,y:-76.834626},
{x:34.842289,y:-82.36019},
{x:34.980456,y:-82.425605},
{x:34.886343,y:-82.392284},
{x:41.85887,y:-88.143072},
{x:34.861127,y:-82.262186},
{x:33.956492,y:-84.056585},
{x:40.478694,y:-74.460663},
{x:40.583754,y:-75.387049},
{x:40.859499,y:-73.417868},
{x:40.736131,y:-73.747161},
{x:28.261141,y:-81.590092},
{x:41.079909,y:-73.863745},
{x:43.13316,y:-70.9196},
{x:42.547881,y:-70.950879},
{x:44.46069,y:-68.90975},
{x:39.237432,y:-76.799786},
{x:39.421113,y:-76.617335},
{x:42.973095,y:-77.353651},
{x:40.70806,y:-73.48359},
{x:41.127351,y:-73.432771},
{x:40.863738,y:-74.105754},
{x:41.000876,y:-74.039572},
{x:36.365818,y:-77.674731},
{x:40.999348,y:-73.95344},
{x:41.065162,y:-74.74772},
{x:41.728495,y:-88.026892},
{x:26.086746,y:-80.229266},
{x:42.36666,y:-71.06178},
{x:41.031304,y:-73.78731},
{x:40.905875,y:-73.776954},
{x:40.752302,y:-74.032989},
{x:39.825914,y:-86.046343},
{x:35.48951,y:-82.52422},
{x:38.771622,y:-77.183527},
{x:41.960031,y:-87.767407},
{x:42.54283,y:-71.465187},
{x:37.503042,y:-77.609826},
{x:40.128778,y:-88.257851},
{x:39.04624,y:-84.243478},
{x:41.693169,y:-70.150382},
{x:41.667244,y:-70.14848},
{x:39.661059,y:-86.310203},
{x:42.096246,y:-75.977119},
{x:40.917331,y:-75.421229},
{x:41.975531,y:-87.659913},
{x:41.72717,y:-70.94714},
{x:41.612591,y:-71.666037},
{x:41.612,y:-70.4893},
{x:36.359095,y:-86.498564},
{x:42.373362,y:-76.869935},
{x:41.404269,y:-81.808325},
{x:29.01389,y:-80.965359},
{x:41.725433,y:-71.438555},
{x:43.12784,y:-77.66418},
{x:40.729029,y:-76.194233},
{x:40.63062,y:-75.37045},
{x:41.82864,y:-71.41671},
{x:42.638783,y:-73.857463},
{x:42.812597,y:-73.98218},
{x:40.586975,y:-74.104355},
{x:36.012404,y:-115.15422},
{x:40.890493,y:-73.849081},
{x:40.883326,y:-74.083545},
{x:40.938938,y:-74.120315},
{x:40.878348,y:-73.885991},
{x:41.823624,y:-71.414196},
{x:42.359041,y:-87.873464},
{x:36.07494,y:-87.3767},
{x:40.725851,y:-73.506711},
{x:41.222831,y:-73.072596},
{x:41.780437,y:-87.642513},
{x:41.60741,y:-88.12698},
{x:40.610558,y:-75.382151},
{x:36.039117,y:-115.029853},
{x:39.788934,y:-75.698451},
{x:42.881288,y:-71.382259},
{x:43.652473,y:-70.665399},
{x:39.39238,y:-76.526956},
{x:41.363819,y:-81.685227},
{x:40.828147,y:-73.972189},
{x:40.032741,y:-75.084456},
{x:36.073414,y:-86.927053},
{x:40.749991,y:-73.862858},
{x:39.873782,y:-75.082479},
{x:40.293709,y:-76.651053},
{x:40.265853,y:-74.819047},
{x:40.211427,y:-74.09193},
{x:44.905968,y:-68.688834},
{x:36.220431,y:-86.341761},
{x:28.154597,y:-81.639289},
{x:41.530062,y:-73.423736},
{x:40.835668,y:-73.870007},
{x:40.819966,y:-73.809993},
{x:40.639631,y:-75.48896},
{x:40.729863,y:-73.461633},
{x:29.271305,y:-81.081825},
{x:39.943813,y:-75.348923},
{x:41.485398,y:-74.094804},
{x:40.860172,y:-73.902564},
{x:40.8238,y:-73.94471},
{x:42.685072,y:-71.139825},
{x:33.91668,y:-84.16331},
{x:41.406994,y:-73.436712},
{x:40.793527,y:-77.862867},
{x:39.435533,y:-76.316501},
{x:34.015635,y:-84.308733},
{x:41.14316,y:-80.72254},
{x:41.23795,y:-81.82562},
{x:41.296038,y:-72.702311},
{x:34.826304,y:-82.563256},
{x:41.4941,y:-74.40363},
{x:42.516856,y:-75.522581},
{x:39.611,y:-77.746},
{x:39.241817,y:-84.774826},
{x:38.193995,y:-78.395292},
{x:41.976439,y:-88.34155},
{x:35.051026,y:-77.015631},
{x:26.103574,y:-80.27127},
{x:41.719834,y:-70.488586},
{x:39.276605,y:-76.612419},
{x:40.949763,y:-74.276945},
{x:44.095636,y:-69.13503},
{x:42.754374,y:-71.429291},
{x:43.238053,y:-78.908925},
{x:41.754716,y:-71.461143},
{x:43.281563,y:-70.594018},
{x:42.609992,y:-70.873328},
{x:26.102296,y:-80.137222},
{x:36.238606,y:-115.224423},
{x:40.82977,y:-74.338127},
{x:42.403083,y:-71.049943},
{x:42.180673,y:-88.336177},
{x:41.69574,y:-72.908739},
{x:40.508848,y:-74.390602},
{x:41.640871,y:-71.001703},
{x:40.835261,y:-74.086423},
{x:39.982258,y:-76.745703},
{x:39.15998,y:-76.891496},
{x:41.55106,y:-87.16279},
{x:40.92419,y:-73.858459},
{x:39.914664,y:-75.221044},
{x:36.136579,y:-86.699834},
{x:41.879431,y:-87.816634},
{x:42.439774,y:-71.250255},
{x:41.810159,y:-87.801964},
{x:42.42119,y:-71.075122},
{x:42.749602,y:-78.853702},
{x:41.815751,y:-88.279142},
{x:41.770169,y:-73.070598},
{x:41.100527,y:-81.44197},
{x:27.89244,y:-81.55299},
{x:41.552747,y:-73.039365},
{x:41.950057,y:-70.713863},
{x:40.829485,y:-73.012823},
{x:38.77139,y:-77.50726},
{x:40.637245,y:-73.703882},
{x:42.37858,y:-88.07348},
{x:40.760791,y:-73.976189},
{x:40.853403,y:-74.173744},
{x:40.902024,y:-74.191182},
{x:35.98575,y:-75.645338},
{x:42.370023,y:-87.851875},
{x:26.159989,y:-80.132221},
{x:39.685166,y:-75.48282},
{x:42.92781,y:-71.43765},
{x:40.578876,y:-73.849477},
{x:32.93203,y:-96.80366},
{x:33.0252,y:-96.84708},
{x:40.677144,y:-73.933762},
{x:40.52735,y:-74.23197},
{x:40.154006,y:-74.973308},
{x:33.074247,y:-96.7498},
{x:32.844519,y:-97.18663},
{x:42.827696,y:-75.546122},
{x:43.138699,y:-75.276345},
{x:43.26935,y:-71.95827},
{x:42.785092,y:-73.673028},
{x:35.059834,y:-80.992253},
{x:41.321839,y:-73.476659},
{x:40.000843,y:-86.117778},
{x:40.832947,y:-73.828016},
{x:36.17106,y:-115.14327},
{x:33.99486,y:-84.59122},
{x:41.64969,y:-70.986968},
{x:42.3484,y:-71.076741},
{x:41.955954,y:-70.679834},
{x:40.831871,y:-73.866877},
{x:40.218873,y:-74.754903},
{x:36.1121,y:-115.06132},
{x:39.961077,y:-75.052246},
{x:41.91993,y:-71.537079},
{x:44.10749,y:-71.1601},
{x:36.00918,y:-78.94438},
{x:40.879322,y:-73.905322},
{x:40.683783,y:-73.540633},
{x:40.664888,y:-73.734909},
{x:36.074609,y:-115.243029},
{x:39.816945,y:-75.193807},
{x:36.09946,y:-115.241092},
{x:41.877918,y:-87.641613},
{x:40.70233,y:-73.817546},
{x:40.836825,y:-73.846406},
{x:40.863672,y:-73.865075},
{x:44.21942,y:-72.54181},
{x:40.849228,y:-73.853475},
{x:41.78164,y:-72.535756},
{x:41.654088,y:-72.748772},
{x:40.019803,y:-75.174807},
{x:40.056859,y:-75.014113},
{x:26.393475,y:-80.201998},
{x:40.626648,y:-73.976597},
{x:40.964047,y:-74.595192},
{x:41.98349,y:-88.07375},
{x:28.851279,y:-82.487261},
{x:29.230339,y:-81.010083},
{x:44.038896,y:-71.67435},
{x:41.261487,y:-73.008047},
{x:38.860428,y:-77.056146},
{x:28.026173,y:-82.54654},
{x:27.959105,y:-82.50617},
{x:28.051611,y:-82.505015},
{x:28.252381,y:-82.187424},
{x:42.64685,y:-71.34134},
{x:40.999452,y:-75.26278},
{x:41.902515,y:-87.697329},
{x:39.476769,y:-76.310174},
{x:40.792396,y:-73.96808},
{x:41.953303,y:-87.735896},
{x:39.996119,y:-75.092842},
{x:40.12033,y:-74.049967},
{x:36.052244,y:-75.684866},
{x:40.674762,y:-75.140461},
{x:39.763009,y:-86.349003},
{x:40.68994,y:-74.17768},
{x:40.754649,y:-74.172199},
{x:40.996408,y:-74.351745},
{x:41.13537,y:-73.792012},
{x:41.988012,y:-76.546874},
{x:40.152059,y:-75.136268},
{x:41.233544,y:-76.993596},
{x:40.271262,y:-78.857621},
{x:26.529061,y:-81.756422},
{x:36.087186,y:-86.694503},
{x:43.810138,y:-76.031201},
{x:28.59735,y:-81.211214},
{x:40.520116,y:-75.521068},
{x:26.527498,y:-81.755354},
{x:42.057783,y:-71.869381},
{x:41.352811,y:-74.727469},
{x:41.969887,y:-88.717894},
{x:41.858674,y:-88.092331},
{x:33.3057,y:-111.87694},
{x:33.379474,y:-111.806289},
{x:40.250215,y:-76.922574},
{x:42.097079,y:-72.019435},
{x:43.176793,y:-71.530479},
{x:40.784679,y:-73.94696},
{x:34.739622,y:-76.81017},
{x:33.078782,y:-96.819063},
{x:30.195052,y:-81.825033},
{x:41.496809,y:-81.693654},
{x:40.811565,y:-73.409283},
{x:41.591958,y:-72.989377},
{x:40.336229,y:-74.798206},
{x:40.893113,y:-73.856975},
{x:41.240275,y:-75.94629},
{x:41.579698,y:-87.481316},
{x:39.652779,y:-86.138297},
{x:40.659218,y:-74.215726},
{x:42.49644,y:-71.72729},
{x:41.034902,y:-78.403032},
{x:38.400313,y:-75.061702},
{x:36.062269,y:-79.844463},
{x:42.339561,y:-77.334074},
{x:27.38772,y:-82.553444},
{x:33.4804,y:-112.03154},
{x:35.12679,y:-79.01095},
{x:27.016782,y:-82.05067},
{x:41.5009,y:-73.053063},
{x:33.335313,y:-111.771592},
{x:41.048668,y:-73.563859},
{x:32.475243,y:-84.988011},
{x:35.516519,y:-78.322087},
{x:41.223634,y:-80.74002},
{x:40.317152,y:-74.472413},
{x:40.264886,y:-75.744965},
{x:36.880767,y:-76.302415},
{x:40.763088,y:-73.927985},
{x:43.090325,y:-75.221864},
{x:30.010596,y:-81.38928},
{x:42.093326,y:-71.266273},
{x:41.918775,y:-71.148978},
{x:40.041907,y:-76.726456},
{x:40.072462,y:-76.319825},
{x:35.25226,y:-81.029029},
{x:37.250636,y:-80.03762},
{x:41.883085,y:-87.686356},
{x:28.068881,y:-82.42678},
{x:41.208531,y:-73.130458},
{x:25.792777,y:-80.369327},
{x:38.9495,y:-76.968442},
{x:41.38296,y:-88.42083},
{x:41.702098,y:-73.871235},
{x:32.761555,y:-97.484695},
{x:34.690844,y:-82.835624},
{x:41.672446,y:-86.89313},
{x:43.118285,y:-70.724043},
{x:34.705493,y:-82.257262},
{x:40.766792,y:-73.827575},
{x:40.792334,y:-73.971434},
{x:40.528798,y:-74.540764},
{x:43.9742,y:-71.141},
{x:39.859249,y:-74.921469},
{x:37.903506,y:-78.342391},
{x:32.7942,y:-79.864444},
{x:36.159329,y:-115.35093},
{x:41.004696,y:-74.342363},
{x:33.152625,y:-96.865339},
{x:41.152672,y:-85.136864},
{x:33.52464,y:-112.110651},
{x:43.179,y:-71.7564},
{x:43.555075,y:-71.456031},
{x:42.230459,y:-83.644273},
{x:41.419931,y:-88.27063},
{x:41.449366,y:-72.473005},
{x:41.686949,y:-72.706868},
{x:28.190272,y:-82.377322},
{x:33.47999,y:-112.257393},
{x:40.730102,y:-73.861386},
{x:40.84203,y:-73.301651},
{x:34.910112,y:-82.103877},
{x:43.62258,y:-70.34382},
{x:41.473579,y:-71.955168},
{x:44.839658,y:-68.747566},
{x:41.985108,y:-71.479402},
{x:40.59689,y:-74.085234},
{x:42.805024,y:-71.29741},
{x:41.356107,y:-75.053643},
{x:36.113777,y:-115.119562},
{x:42.653245,y:-73.773999},
{x:42.414324,y:-71.046951},
{x:33.480389,y:-112.109966},
{x:42.856942,y:-71.187917},
{x:41.014797,y:-73.945633},
{x:41.954194,y:-87.65021},
{x:41.873779,y:-87.630801},
{x:42.968626,y:-74.186589},
{x:33.009351,y:-80.045284},
{x:32.742795,y:-79.968034},
{x:42.485763,y:-70.899783},
{x:29.476112,y:-81.21398},
{x:40.643392,y:-73.77936},
{x:40.647039,y:-73.778634},
{x:40.646637,y:-73.778634},
{x:38.810684,y:-76.700882},
{x:40.673847,y:-73.735331},
{x:41.867663,y:-87.63945},
{x:35.918735,y:-78.960033},
{x:41.165082,y:-74.55835},
{x:41.088473,y:-74.519478},
{x:40.934373,y:-74.26984},
{x:40.802897,y:-73.956666},
{x:30.497396,y:-97.657036},
{x:40.440897,y:-80.003002},
{x:33.596616,y:-112.014152},
{x:33.275446,y:-111.858012},
{x:33.84559,y:-79.064064},
{x:34.200913,y:-79.594124},
{x:40.81062,y:-73.503735},
{x:41.26651,y:-80.78312},
{x:27.432415,y:-82.396437},
{x:40.765298,y:-74.069611},
{x:40.61215,y:-75.531958},
{x:42.102569,y:-71.256959},
{x:41.710138,y:-70.024085},
{x:42.538977,y:-76.491988},
{x:41.40184,y:-71.847142},
{x:42.344821,y:-71.035269},
{x:36.400353,y:-87.030108},
{x:36.02611,y:-87.17446},
{x:41.62827,y:-72.873536},
{x:44.51592,y:-68.39666},
{x:27.766661,y:-80.510459},
{x:39.307392,y:-76.615659},
{x:41.413396,y:-75.666263},
{x:33.732602,y:-84.567679},
{x:35.599762,y:-77.334657},
{x:35.115436,y:-77.073461},
{x:43.394619,y:-70.531518},
{x:38.633083,y:-75.19437},
{x:41.245325,y:-75.882437},
{x:39.0818,y:-77.07749},
{x:35.930644,y:-84.026063},
{x:36.813069,y:-76.207369},
{x:39.096308,y:-84.855907},
{x:43.460391,y:-76.238763},
{x:34.096733,y:-80.856741},
{x:42.08817,y:-87.907243},
{x:42.011431,y:-70.731059},
{x:40.09336,y:-76.3492},
{x:42.116136,y:-72.067588},
{x:42.496288,y:-71.285887},
{x:33.283522,y:-111.684716},
{x:35.560464,y:-82.637287},
{x:40.132714,y:-83.017673},
{x:40.87737,y:-79.94827},
{x:40.93676,y:-74.16569},
{x:40.783194,y:-73.473498},
{x:40.506663,y:-75.387584},
{x:40.729504,y:-73.998434},
{x:41.414859,y:-73.670681},
{x:38.034402,y:-84.521744},
{x:33.564358,y:-81.76383},
{x:40.915989,y:-74.060164},
{x:39.442583,y:-77.436094},
{x:33.96376,y:-84.41222},
{x:33.90201,y:-84.454536},
{x:37.932098,y:-79.231785},
{x:36.938714,y:-80.992705},
{x:40.917559,y:-73.901848},
{x:40.746098,y:-73.97934},
{x:33.210764,y:-84.056385},
{x:34.994221,y:-78.129638},
{x:42.601778,y:-73.792539},
{x:40.820307,y:-74.100487},
{x:38.973301,y:-95.251223},
{x:41.692122,y:-87.701139},
{x:40.905598,y:-74.570429},
{x:40.724964,y:-73.72284},
{x:41.39584,y:-75.68652},
{x:39.076808,y:-77.002125},
{x:29.814732,y:-82.168765},
{x:35.583133,y:-78.147777},
{x:38.966633,y:-78.438352},
{x:42.903061,y:-76.83027},
{x:41.016794,y:-73.649285},
{x:35.296007,y:-79.73226},
{x:34.558142,y:-84.936364},
{x:30.754441,y:-83.273085},
{x:36.109,y:-83.338736},
{x:33.169246,y:-87.447977},
{x:35.612406,y:-80.520133},
{x:39.79347,y:-75.97705},
{x:28.607563,y:-81.425372},
{x:35.919375,y:-80.5851},
{x:40.63426,y:-73.653751},
{x:40.60543,y:-73.649378},
{x:41.730019,y:-87.5513},
{x:42.093552,y:-88.258544},
{x:40.352517,y:-76.726925},
{x:35.863695,y:-84.064453},
{x:40.458216,y:-79.823687},
{x:40.110247,y:-88.229964},
{x:42.835759,y:-89.023825},
{x:40.357133,y:-74.355646},
{x:35.289457,y:-81.188362},
{x:40.73478,y:-73.952233},
{x:40.625671,y:-73.92782},
{x:39.85078,y:-74.183821},
{x:35.88757,y:-78.744092},
{x:41.126266,y:-74.041088},
{x:35.361756,y:-80.712362},
{x:44.098889,y:-69.865834},
{x:41.014485,y:-80.662514},
{x:33.722104,y:-84.902129},
{x:35.720974,y:-81.188559},
{x:36.054664,y:-115.171578},
{x:39.313251,y:-76.524294},
{x:36.07059,y:-79.351098},
{x:42.053787,y:-71.241452},
{x:36.222715,y:-83.262209},
{x:35.171078,y:-89.836453},
{x:36.16648,y:-86.78168},
{x:42.35809,y:-71.12628},
{x:41.132323,y:-85.100927},
{x:43.04724,y:-70.93233},
{x:36.874408,y:-76.038516},
{x:40.866281,y:-73.91827},
{x:41.411178,y:-81.338522},
{x:40.696915,y:-73.814177},
{x:40.895825,y:-73.876508},
{x:40.713818,y:-73.933505},
{x:40.756217,y:-73.997705},
{x:40.853595,y:-73.934429},
{x:37.34834,y:-76.731198},
{x:36.060741,y:-79.662122},
{x:35.67152,y:-80.855606},
{x:34.345055,y:-79.534963},
{x:37.184541,y:-77.318163},
{x:42.29084,y:-75.473793},
{x:41.180279,y:-73.187641},
{x:31.18969,y:-85.399566},
{x:39.606805,y:-86.108038},
{x:39.863536,y:-86.393125},
{x:35.9074,y:-78.726199},
{x:40.605297,y:-79.756333},
{x:33.247931,y:-111.562722},
{x:40.842207,y:-74.130871},
{x:40.815048,y:-73.940439},
{x:27.793251,y:-82.374149},
{x:37.070311,y:-76.493509},
{x:38.56228,y:-90.406811},
{x:39.21456,y:-75.57334},
{x:40.21367,y:-74.76422},
{x:39.830838,y:-75.438969},
{x:39.038888,y:-76.439146},
{x:33.250451,y:-111.625978},
{x:33.270194,y:-80.566992},
{x:34.192578,y:-80.283148},
{x:34.24038,y:-81.45934},
{x:34.508095,y:-81.000988},
{x:33.561567,y:-84.414363},
{x:41.447281,y:-81.565259},
{x:40.805335,y:-73.911146},
{x:35.166062,y:-81.451551},
{x:34.39031,y:-83.227068},
{x:30.759831,y:-81.655677},
{x:35.70214,y:-78.794003},
{x:39.958158,y:-75.208576},
{x:40.933785,y:-74.660986},
{x:39.268994,y:-76.599456},
{x:41.933158,y:-72.686971},
{x:42.72728,y:-71.16782},
{x:40.45085,y:-74.381846},
{x:41.879798,y:-87.780273},
{x:43.31976,y:-76.436702},
{x:30.195762,y:-85.809354},
{x:38.786145,y:-77.649948},
{x:40.725086,y:-73.771579},
{x:40.76451,y:-73.885889},
{x:40.757628,y:-73.827427},
{x:39.647794,y:-75.334131},
{x:41.49136,y:-90.48582},
{x:42.05649,y:-71.08078},
{x:40.666546,y:-73.852018},
{x:41.063675,y:-72.429948},
{x:40.787652,y:-73.975006},
{x:42.341978,y:-87.896853},
{x:42.747664,y:-76.135235},
{x:40.586275,y:-73.931071},
{x:27.76866,y:-82.393067},
{x:40.043464,y:-75.159369},
{x:38.091298,y:-78.468825},
{x:35.826295,y:-84.274531},
{x:42.26951,y:-71.024535},
{x:40.773567,y:-73.906751},
{x:42.400762,y:-71.117291},
{x:40.72806,y:-74.374738},
{x:40.708096,y:-74.014151},
{x:27.359844,y:-82.549164},
{x:41.809594,y:-71.874989},
{x:41.12755,y:-80.76791},
{x:42.569216,y:-71.159896},
{x:42.041281,y:-87.756772},
{x:41.771422,y:-88.031893},
{x:40.674416,y:-73.842658},
{x:41.44083,y:-73.405417},
{x:40.00268,y:-75.21437},
{x:32.268372,y:-90.135498},
{x:41.731149,y:-88.130254},
{x:40.876052,y:-74.071423},
{x:41.528002,y:-72.773665},
{x:40.620819,y:-74.0323},
{x:41.748843,y:-71.209345},
{x:40.655372,y:-73.959681},
{x:41.538429,y:-90.602286},
{x:40.69492,y:-80.104883},
{x:41.269079,y:-73.606309},
{x:41.09809,y:-73.95896},
{x:40.876129,y:-74.064739},
{x:40.725436,y:-75.392624},
{x:42.396215,y:-71.008805},
{x:39.081452,y:-78.083675},
{x:33.304773,y:-111.977545},
{x:26.26712,y:-81.80207},
{x:26.194238,y:-80.286628},
{x:40.944176,y:-72.844119},
{x:40.669397,y:-73.950461},
{x:40.25409,y:-74.705523},
{x:38.605429,y:-90.376958},
{x:42.283663,y:-87.953694},
{x:40.7555,y:-73.925233},
{x:40.709935,y:-74.010168},
{x:40.680468,y:-73.903361},
{x:41.009459,y:-74.171927},
{x:41.695955,y:-71.153256},
{x:43.11654,y:-75.59662},
{x:43.608689,y:-73.207997},
{x:41.38863,y:-81.76327},
{x:41.170397,y:-85.502274},
{x:38.855013,y:-94.675214},
{x:38.993298,y:-94.668032},
{x:36.960989,y:-86.469609},
{x:41.49167,y:-72.09114},
{x:33.68236,y:-112.100482},
{x:42.74549,y:-73.80923},
{x:41.48483,y:-81.81876},
{x:41.321569,y:-74.802965},
{x:40.851391,y:-74.294995},
{x:41.840766,y:-71.876099},
{x:34.236271,y:-77.828007},
{x:33.376868,y:-111.859277},
{x:43.060951,y:-77.475484},
{x:33.622595,y:-111.892058},
{x:39.250445,y:-76.669175},
{x:39.380475,y:-76.557129},
{x:35.943216,y:-78.557655},
{x:35.784536,y:-78.688936},
{x:41.122351,y:-73.370195},
{x:40.416017,y:-79.924791},
{x:43.094537,y:-77.514233},
{x:40.067147,y:-75.178286},
{x:33.97476,y:-84.479},
{x:40.83,y:-73.926},
{x:38.812319,y:-77.112525},
{x:42.025864,y:-87.708216},
{x:40.71386,y:-74.229759},
{x:41.169732,y:-87.87487},
{x:33.642549,y:-84.440783},
{x:39.179391,y:-76.665149},
{x:28.040795,y:-82.542699},
{x:41.47026,y:-75.63955},
{x:40.208205,y:-74.776042},
{x:26.2279,y:-80.179527},
{x:26.662873,y:-80.173696},
{x:27.339906,y:-80.373862},
{x:27.59911,y:-80.822145},
{x:28.094042,y:-81.273779},
{x:28.514766,y:-81.499557},
{x:41.682286,y:-69.991461},
{x:39.417082,y:-84.475223},
{x:41.953502,y:-87.885314},
{x:40.896562,y:-74.699626},
{x:41.189226,y:-73.731791},
{x:41.401453,y:-81.852506},
{x:40.91627,y:-73.81088},
{x:40.438137,y:-79.919544},
{x:40.39387,y:-80.03573},
{x:40.736165,y:-73.989057},
{x:40.821148,y:-73.954664},
{x:35.51992,y:-78.292379},
{x:40.189453,y:-75.10267},
{x:40.124938,y:-74.962084},
{x:39.491314,y:-76.886309},
{x:35.128364,y:-85.24337},
{x:34.758445,y:-77.419388},
{x:25.661869,y:-80.386947},
{x:35.898562,y:-78.681099},
{x:42.492335,y:-83.028523},
{x:40.757801,y:-73.920015},
{x:40.749675,y:-74.054789},
{x:42.109979,y:-72.610542},
{x:35.068324,y:-89.760782},
{x:40.411892,y:-74.187452},
{x:39.974913,y:-74.584972},
{x:39.32143,y:-80.22919},
{x:30.479604,y:-87.216886},
{x:32.440819,y:-80.705949},
{x:40.746612,y:-73.596607},
{x:36.94069,y:-77.73489},
{x:40.796133,y:-81.430468},
{x:29.652202,y:-82.345728},
{x:40.79153,y:-74.145707},
{x:40.435179,y:-74.512365},
{x:38.742934,y:-90.367083},
{x:38.742425,y:-90.365649},
{x:41.45237,y:-75.17915},
{x:42.45994,y:-71.164062},
{x:40.742532,y:-73.86972},
{x:41.66024,y:-76.25017},
{x:41.664925,y:-71.156516},
{x:41.690669,y:-71.56676},
{x:40.442219,y:-79.95635},
{x:44.45417,y:-72.69583},
{x:35.872437,y:-83.569028},
{x:33.334672,y:-86.784225},
{x:42.20017,y:-72.60666},
{x:41.414415,y:-82.077468},
{x:42.483191,y:-70.901398},
{x:42.35096,y:-71.0563},
{x:41.002779,y:-75.149343},
{x:41.635702,y:-70.949871},
{x:36.183184,y:-82.769454},
{x:28.76241,y:-82.550118},
{x:39.179239,y:-76.668866},
{x:40.896012,y:-73.863602},
{x:41.656318,y:-70.955133},
{x:41.055604,y:-73.545717},
{x:39.999074,y:-78.235464},
{x:41.839586,y:-88.010341},
{x:40.902949,y:-74.63466},
{x:40.69048,y:-74.17712},
{x:39.366244,y:-77.1649},
{x:40.47776,y:-78.724201},
{x:40.712103,y:-73.941125},
{x:33.33569,y:-111.84122},
{x:40.592321,y:-73.908128},
{x:35.566004,y:-77.357239},
{x:40.667011,y:-73.660822},
{x:36.17724,y:-85.94857},
{x:40.743204,y:-73.993127},
{x:40.511528,y:-79.948187},
{x:40.439558,y:-80.069279},
{x:39.525016,y:-85.741164},
{x:40.531105,y:-74.381247},
{x:39.355432,y:-76.462213},
{x:34.955041,y:-85.255015},
{x:40.741335,y:-74.033767},
{x:42.304174,y:-71.324075},
{x:40.315485,y:-75.939777},
{x:41.69583,y:-88.0655},
{x:40.896212,y:-72.399125},
{x:39.295441,y:-84.315521},
{x:39.259504,y:-84.340748},
{x:42.156796,y:-76.822235},
{x:39.948626,y:-75.159093},
{x:42.87469,y:-71.315004},
{x:33.335101,y:-111.909297},
{x:42.929932,y:-75.848971},
{x:40.20924,y:-75.45368},
{x:39.771345,y:-74.899415},
{x:40.745735,y:-74.432502},
{x:39.677943,y:-75.494843},
{x:30.246116,y:-81.696689},
{x:41.53753,y:-73.103966},
{x:36.021913,y:-87.338897},
{x:41.04501,y:-81.7285},
{x:40.815977,y:-73.292276},
{x:36.036967,y:-80.385108},
{x:33.465336,y:-112.2719},
{x:40.4409,y:-79.99596},
{x:41.749506,y:-87.682183},
{x:42.550399,y:-83.788809},
{x:40.733271,y:-74.975933},
{x:40.768741,y:-73.911178},
{x:26.061479,y:-81.699944},
{x:40.807333,y:-73.238482},
{x:40.752797,y:-73.04006},
{x:39.113116,y:-76.93356},
{x:40.694775,y:-73.507853},
{x:44.12243,y:-73.18157},
{x:40.272441,y:-75.378683},
{x:40.51788,y:-80.03144},
{x:41.568776,y:-73.006835},
{x:40.337423,y:-79.976291},
{x:27.783003,y:-82.63877},
{x:40.11921,y:-75.06801},
{x:40.848378,y:-73.937701},
{x:41.51452,y:-87.95337},
{x:40.810451,y:-77.83142},
{x:40.75938,y:-77.87804},
{x:40.920572,y:-73.129064},
{x:40.7527,y:-73.99507},
{x:41.473579,y:-71.955168},
{x:41.3141,y:-81.50947},
{x:31.162217,y:-81.475485},
{x:34.032908,y:-84.671541},
{x:43.038988,y:-87.91972},
{x:41.745655,y:-73.201242},
{x:38.3804,y:-75.068126},
{x:40.73673,y:-74.2505},
{x:39.755731,y:-75.046447},
{x:40.695171,y:-73.391212},
{x:26.67088,y:-80.247301},
{x:39.146912,y:-76.807957},
{x:44.981337,y:-73.459166},
{x:35.89384,y:-78.55982},
{x:41.92899,y:-70.017218},
{x:44.38777,y:-69.594532},
{x:41.904234,y:-87.631335},
{x:35.469827,y:-83.298584},
{x:39.960495,y:-74.392421},
{x:38.65565,y:-76.876103},
{x:39.100535,y:-76.822464},
{x:40.778529,y:-74.129933},
{x:40.30374,y:-79.50594},
{x:38.717554,y:-77.147889},
{x:40.677017,y:-75.210699},
{x:43.176073,y:-76.113859},
{x:38.936131,y:-77.363339},
{x:43.144084,y:-77.732826},
{x:31.997247,y:-81.255818},
{x:39.869421,y:-75.234982},
{x:39.868461,y:-75.265699},
{x:40.66475,y:-75.512228},
{x:33.711568,y:-112.27348},
{x:44.849662,y:-74.284951},
{x:43.218033,y:-77.790775},
{x:33.57945,y:-111.88664},
{x:27.374754,y:-80.366012},
{x:40.048863,y:-74.87899},
{x:36.559959,y:-82.552034},
{x:36.333576,y:-82.390117},
{x:39.381792,y:-74.554784},
{x:39.214736,y:-76.644038},
{x:42.055029,y:-80.086464},
{x:40.874641,y:-76.784331},
{x:40.74069,y:-73.89602},
{x:33.38186,y:-111.73884},
{x:41.83245,y:-72.818896},
{x:41.309705,y:-75.517426},
{x:33.58786,y:-111.836226},
{x:43.01665,y:-78.95883},
{x:41.80497,y:-71.406855},
{x:29.656757,y:-95.274797},
{x:43.646229,y:-72.253937},
{x:39.176571,y:-76.669548},
{x:41.896494,y:-87.636121},
{x:40.69597,y:-73.905415},
{x:40.688026,y:-73.981885},
{x:41.414212,y:-73.381089},
{x:41.280658,y:-80.844352},
{x:35.136466,y:-90.009862},
{x:25.477711,y:-80.411792},
{x:25.877575,y:-80.242582},
{x:33.440797,y:-112.555984},
{x:33.50772,y:-86.79865},
{x:32.311695,y:-81.055562},
{x:33.335464,y:-86.995803},
{x:40.81308,y:-74.078343},
{x:42.174631,y:-72.640481},
{x:40.648021,y:-73.786276},
{x:38.951914,y:-77.447805},
{x:30.560541,y:-84.218586},
{x:42.284616,y:-71.61902},
{x:33.407969,y:-111.910812},
{x:36.11849,y:-80.316969},
{x:40.8952,y:-73.88157},
{x:40.89495,y:-73.88264},
{x:37.650903,y:-77.400813},
{x:41.732415,y:-72.743205},
{x:43.966445,y:-75.622766},
{x:42.29767,y:-71.76239},
{x:43.060481,y:-76.153164},
{x:40.748481,y:-73.684095},
{x:39.132125,y:-76.638572},
{x:40.312132,y:-79.391222},
{x:25.095513,y:-80.438986},
{x:40.879549,y:-74.602382},
{x:43.643602,y:-72.421796},
{x:34.680762,y:-77.090987},
{x:34.227512,y:-83.86799},
{x:29.916605,y:-81.405623},
{x:41.762737,y:-73.930696},
{x:41.312653,y:-81.834605},
{x:33.637614,y:-112.424738},
{x:41.871664,y:-87.647665},
{x:26.32861,y:-81.805032},
{x:36.27921,y:-115.024327},
{x:26.667858,y:-81.938919},
{x:40.62136,y:-73.91703},
{x:42.987041,y:-71.42028},
{x:40.81308,y:-74.078343},
{x:42.53428,y:-70.99103},
{x:36.606934,y:-77.560801},
{x:41.895726,y:-88.101488},
{x:40.613798,y:-79.17398},
{x:42.779233,y:-83.239538},
{x:28.53722,y:-81.286212},
{x:43.188355,y:-77.561212},
{x:35.232986,y:-85.825781},
{x:41.601464,y:-73.406707},
{x:39.513584,y:-74.925692},
{x:34.047725,y:-84.61018},
{x:40.939008,y:-73.962318},
{x:42.361019,y:-71.115547},
{x:40.60895,y:-74.121554},
{x:41.616783,y:-70.517141},
{x:34.19026,y:-79.81026},
{x:41.75411,y:-71.87708},
{x:41.75409,y:-71.87852},
{x:41.04116,y:-73.67248},
{x:41.04116,y:-73.67289},
{x:41.24669,y:-73.00961},
{x:41.24626,y:-73.00873},
{x:41.0674,y:-73.50429},
{x:41.08069,y:-73.46169},
{x:41.39854,y:-72.869408},
{x:41.398122,y:-72.868734},
{x:41.21886,y:-73.25588},
{x:41.21889,y:-73.25636},
{x:41.12016,y:-73.48114},
{x:41.12011,y:-73.47955},
{x:41.27981,y:-73.04855},
{x:41.27999,y:-73.04797},
{x:41.29134,y:-72.58022},
{x:41.29072,y:-72.58034},
{x:41.14779,y:-73.25667},
{x:41.1469,y:-73.25624},
{x:41.47121,y:-72.11684},
{x:41.28638,y:-72.83384},
{x:41.28571,y:-72.83359},
{x:42.06951,y:-80.10427},
{x:35.297736,y:-82.40563},
{x:41.79933,y:-87.591836},
{x:33.522244,y:-81.719313},
{x:40.65705,y:-73.76856},
{x:39.155602,y:-77.208844},
{x:32.2213,y:-110.7901},
{x:37.038462,y:-76.400356},
{x:36.855137,y:-76.135663},
{x:42.654223,y:-83.373823},
{x:25.762984,y:-80.305648},
{x:41.00974,y:-81.55469},
{x:40.042332,y:-75.481007},
{x:36.67535,y:-78.926967},
{x:41.258174,y:-75.821664},
{x:25.952717,y:-81.722996},
{x:40.352955,y:-74.064728},
{x:43.081829,y:-75.321955},
{x:33.612133,y:-112.185404},
{x:33.610492,y:-112.239542},
{x:39.35792,y:-84.26079},
{x:25.695143,y:-80.299996},
{x:27.299512,y:-82.454263},
{x:35.205213,y:-89.762976},
{x:34.964633,y:-89.989456},
{x:42.566381,y:-73.163351},
{x:41.475441,y:-72.263915},
{x:41.22313,y:-75.881548},
{x:41.653812,y:-88.566719},
{x:40.645457,y:-73.912784},
{x:40.655951,y:-73.607819},
{x:28.14551,y:-81.452619},
{x:31.191591,y:-81.486814},
{x:30.161415,y:-85.590494},
{x:27.85411,y:-82.31505},
{x:39.858737,y:-75.007036},
{x:38.852547,y:-77.322559},
{x:40.64138,y:-73.96445},
{x:30.505985,y:-84.251004},
{x:39.20265,y:-84.37183},
{x:41.178942,y:-80.567877},
{x:41.012256,y:-73.8067},
{x:38.76665,y:-77.154805},
{x:42.6042,y:-73.71573},
{x:42.139519,y:-87.910157},
{x:43.033394,y:-76.187502},
{x:28.940556,y:-81.949694},
{x:42.542108,y:-83.438925},
{x:31.83546,y:-81.59637},
{x:42.096011,y:-88.033564},
{x:43.625872,y:-89.787081},
{x:35.478745,y:-81.223551},
{x:42.054217,y:-87.866645},
{x:42.81343,y:-72.53071},
{x:41.80472,y:-71.330101},
{x:36.13861,y:-85.500875},
{x:41.97961,y:-71.51089},
{x:40.801739,y:-73.735799},
{x:38.488171,y:-90.30397},
{x:41.833704,y:-87.900487},
{x:40.60007,y:-73.9467},
{x:33.826511,y:-78.682757},
{x:30.480687,y:-97.829695},
{x:33.21899,y:-111.82411},
{x:32.349137,y:-110.975982},
{x:43.04218,y:-71.3042},
{x:40.845856,y:-73.902075},
{x:40.834057,y:-74.145922},
{x:32.93808,y:-97.18524},
{x:42.909809,y:-77.734261},
{x:42.866993,y:-73.731846},
{x:42.025371,y:-72.785491},
{x:41.3173,y:-81.34949},
{x:35.154475,y:-79.414825},
{x:38.887234,y:-76.897219},
{x:37.65212,y:-77.616529},
{x:40.63848,y:-73.99768},
{x:39.326915,y:-74.598249},
{x:41.704779,y:-87.759236},
{x:41.851437,y:-87.705617},
{x:28.352792,y:-81.676715},
{x:40.910478,y:-73.889385},
{x:39.26724,y:-84.26612},
{x:36.259493,y:-115.180311},
{x:40.750179,y:-73.421922},
{x:41.661283,y:-81.361415},
{x:41.605182,y:-74.294379},
{x:34.375675,y:-77.702754},
{x:38.339279,y:-75.109647},
{x:40.76614,y:-73.95608},
{x:38.912588,y:-94.793576},
{x:35.01187,y:-85.15685},
{x:35.929292,y:-86.896864},
{x:38.732669,y:-89.909255},
{x:33.607796,y:-112.065533},
{x:33.394338,y:-111.61603},
{x:30.790068,y:-81.654935},
{x:43.119659,y:-88.104785},
{x:32.215908,y:-80.709735},
{x:42.35692,y:-71.62387},
{x:40.816506,y:-73.465813},
{x:26.180668,y:-81.705563},
{x:40.174306,y:-74.93095},
{x:41.807157,y:-72.254676},
{x:41.484727,y:-71.529837},
{x:42.652037,y:-71.303031},
{x:40.518885,y:-76.110419},
{x:36.328107,y:-75.816042},
{x:42.260133,y:-85.590633},
{x:43.249527,y:-75.1848},
{x:40.751142,y:-73.571165},
{x:40.804603,y:-75.602878},
{x:42.112217,y:-75.957834},
{x:40.39151,y:-79.98673},
{x:40.77856,y:-73.95637},
{x:42.418116,y:-71.231603},
{x:40.858481,y:-73.885084},
{x:39.340077,y:-80.223511},
{x:41.854508,y:-71.472646},
{x:33.167334,y:-96.666121},
{x:30.851682,y:-83.950214},
{x:39.288749,y:-77.201791},
{x:43.006747,y:-87.967528},
{x:41.564745,y:-88.198727},
{x:30.266173,y:-81.557517},
{x:34.526295,y:-83.979179},
{x:33.555746,y:-85.072334},
{x:33.91828,y:-84.00037},
{x:40.934539,y:-74.718868},
{x:27.921521,y:-82.78739},
{x:42.010906,y:-87.840284},
{x:42.87216,y:-87.94658},
{x:42.479195,y:-88.100815},
{x:34.778632,y:-82.309607},
{x:38.697419,y:-77.78972},
{x:42.503548,y:-71.133995},
{x:36.712411,y:-81.931606},
{x:42.080247,y:-87.798388},
{x:40.611139,y:-74.009325},
{x:39.146312,y:-84.44227},
{x:41.779224,y:-73.764891},
{x:35.17692,y:-113.7898},
{x:29.170229,y:-82.14011},
{x:35.046191,y:-78.921441},
{x:27.732976,y:-82.678857},
{x:36.805,y:-76.133012},
{x:40.285485,y:-75.262708},
{x:40.868672,y:-72.797457},
{x:40.66792,y:-73.98092},
{x:26.967668,y:-82.079883},
{x:42.412422,y:-71.063137},
{x:24.72024,y:-81.06146},
{x:42.432096,y:-71.025647},
{x:42.614317,y:-70.668024},
{x:37.744157,y:-87.115526},
{x:38.943735,y:-94.596065},
{x:33.4085,y:-84.60126},
{x:35.753065,y:-77.931294},
{x:41.921118,y:-71.090897},
{x:33.364545,y:-84.178874},
{x:41.350892,y:-74.686378},
{x:42.614321,y:-71.327064},
{x:42.03613,y:-71.346672},
{x:42.998996,y:-70.973413},
{x:41.518086,y:-71.295886},
{x:40.7434,y:-73.95402},
{x:44.08522,y:-70.20414},
{x:27.813458,y:-82.728431},
{x:41.647188,y:-73.562708},
{x:42.517283,y:-70.951213},
{x:40.860601,y:-73.154206},
{x:42.914443,y:-71.366274},
{x:42.674323,y:-82.826326},
{x:35.16761,y:-84.871496},
{x:36.005542,y:-115.112061},
{x:44.966698,y:-74.638788},
{x:42.192779,y:-71.756516},
{x:43.058799,y:-89.400177},
{x:42.088256,y:-88.530165},
{x:38.587326,y:-89.984806},
{x:40.232791,y:-74.708827},
{x:39.043891,y:-77.47222},
{x:36.139387,y:-86.800338},
{x:40.720859,y:-73.84457},
{x:39.44477,y:-75.041757},
{x:35.46475,y:-84.64546},
{x:40.485027,y:-74.436529},
{x:43.134535,y:-70.928014},
{x:24.5685,y:-81.76877},
{x:40.60469,y:-80.28059},
{x:42.5869,y:-72.48935},
{x:40.580008,y:-75.341119},
{x:42.741686,y:-84.50571},
{x:40.796238,y:-75.969419},
{x:41.31455,y:-75.79798},
{x:42.980868,y:-88.108208},
{x:38.948727,y:-77.446089},
{x:32.59989,y:-96.944596},
{x:34.533504,y:-82.665576},
{x:30.059798,y:-81.513306},
{x:41.88138,y:-87.665211},
{x:35.096472,y:-85.328224},
{x:41.911646,y:-87.686964},
{x:31.139854,y:-97.738538},
{x:33.656721,y:-112.09923},
{x:28.058676,y:-81.788775},
{x:38.97839,y:-76.494871},
{x:37.065164,y:-88.638364},
{x:30.73625,y:-86.565861},
{x:31.812735,y:-106.433834},
{x:35.059656,y:-106.558015},
{x:28.068164,y:-82.633675},
{x:40.749688,y:-73.992261},
{x:40.749911,y:-73.99218},
{x:40.749912,y:-73.992122},
{x:40.792771,y:-74.001566},
{x:36.238796,y:-75.876123},
{x:42.238265,y:-71.177886},
{x:40.633289,y:-75.439706},
{x:42.505944,y:-71.160152},
{x:27.44779,y:-82.575369},
{x:30.472024,y:-84.362025},
{x:35.088082,y:-80.860608},
{x:40.64671,y:-73.78914},
{x:28.049995,y:-82.780529},
{x:33.697391,y:-84.172636},
{x:42.589345,y:-71.202241},
{x:41.644172,y:-73.922287},
{x:40.710957,y:-74.079197},
{x:42.092125,y:-72.50101},
{x:40.754408,y:-73.977327},
{x:35.958835,y:-83.929389},
{x:29.256128,y:-81.117076},
{x:33.87446,y:-83.43997},
{x:43.118668,y:-87.91191},
{x:43.0777,y:-71.459048},
{x:42.759954,y:-73.941669},
{x:27.597178,y:-81.514823},
{x:41.506415,y:-87.728452},
{x:41.88874,y:-71.08985},
{x:42.280653,y:-71.08405},
{x:31.231041,y:-82.332611},
{x:35.346773,y:-94.351204},
{x:42.627474,y:-88.625771},
{x:42.374132,y:-71.12125},
{x:42.056434,y:-88.045371},
{x:39.899118,y:-85.992984},
{x:41.972041,y:-88.116938},
{x:41.736672,y:-87.612537},
{x:40.181998,y:-74.675557},
{x:41.10995,y:-81.57022},
{x:40.81082,y:-79.51803},
{x:39.303612,y:-76.617111},
{x:40.78226,y:-73.84607},
{x:40.28244,y:-79.54958},
{x:40.852403,y:-73.96942},
{x:33.605202,y:-81.843123},
{x:28.553572,y:-81.333592},
{x:27.107046,y:-82.439669},
{x:33.377011,y:-112.171132},
{x:41.49139,y:-72.09171},
{x:34.44949,y:-77.564245},
{x:41.087433,y:-75.325451},
{x:41.480115,y:-81.768802},
{x:33.225323,y:-117.392682},
{x:34.29386,y:-83.82953},
{x:33.60622,y:-86.63215},
{x:45.02671,y:-69.28862},
{x:41.21008,y:-76.75982},
{x:40.58982,y:-73.79848},
{x:32.917916,y:-80.11487},
{x:26.550356,y:-80.07497},
{x:32.906597,y:-97.036528},
{x:43.191868,y:-89.461459},
{x:26.710025,y:-81.722235},
{x:40.882156,y:-73.88022},
{x:33.482561,y:-82.09323},
{x:33.437269,y:-111.788746},
{x:42.937117,y:-76.571172},
{x:39.074996,y:-94.379309},
{x:35.637327,y:-78.830194},
{x:36.625735,y:-87.319913},
{x:44.72183,y:-73.72385},
{x:39.835909,y:-75.42467},
{x:30.352353,y:-81.581379},
{x:35.110366,y:-89.890534},
{x:33.60863,y:-85.78597},
{x:42.351974,y:-71.054973},
{x:43.112315,y:-72.428013},
{x:45.258561,y:-68.986315},
{x:40.537526,y:-74.499412},
{x:28.545748,y:-81.66862},
{x:39.965572,y:-74.205389},
{x:41.186652,y:-74.036171},
{x:35.671133,y:-88.853794},
{x:36.07752,y:-79.224473},
{x:42.471969,y:-76.400813},
{x:39.722097,y:-75.165175},
{x:40.005831,y:-74.766486},
{x:41.032351,y:-73.56809},
{x:40.71746,y:-74.00037},
{x:42.590972,y:-76.191273},
{x:34.359379,y:-84.043629},
{x:34.74954,y:-86.76013},
{x:39.38129,y:-76.823712},
{x:41.724783,y:-86.251108},
{x:29.126892,y:-81.004719},
{x:27.521787,y:-82.557811},
{x:40.51646,y:-80.221},
{x:42.566479,y:-87.857293},
{x:39.698027,y:-86.371934},
{x:39.932435,y:-75.143947},
{x:36.614476,y:-88.314699},
{x:40.97781,y:-75.44507},
{x:33.01327,y:-97.072105},
{x:28.952677,y:-81.958802},
{x:40.743297,y:-73.88335},
{x:42.335149,y:-75.972112},
{x:42.856862,y:-77.003877},
{x:42.878602,y:-77.262041},
{x:30.423115,y:-86.649003},
{x:39.159508,y:-76.51866},
{x:42.708133,y:-89.004584},
{x:33.77337,y:-84.371234},
{x:33.805586,y:-84.36682},
{x:33.5898,y:-84.46867},
{x:34.730203,y:-76.639044},
{x:35.81612,y:-83.57794},
{x:39.988187,y:-75.402041},
{x:40.29031,y:-74.682041},
{x:34.74357,y:-86.60118},
{x:44.453754,y:-89.523281},
{x:42.825465,y:-71.512335},
{x:39.265853,y:-94.576611},
{x:40.752312,y:-73.987561},
{x:38.700362,y:-75.401562},
{x:32.339111,y:-86.175618},
{x:40.32362,y:-79.70049},
{x:36.056375,y:-115.279118},
{x:41.704623,y:-88.306801},
{x:41.543229,y:-87.833069},
{x:41.751381,y:-88.013222},
{x:41.904524,y:-88.007397},
{x:42.496953,y:-71.100868},
{x:40.560942,y:-74.135257},
{x:41.186849,y:-74.587386},
{x:42.517937,y:-71.091909},
{x:42.256506,y:-71.788718},
{x:40.6923,y:-73.9872},
{x:28.947709,y:-81.242449},
{x:33.80301,y:-84.41466},
{x:43.387814,y:-72.087509},
{x:42.331815,y:-71.096945},
{x:42.247573,y:-71.153955},
{x:40.75533,y:-73.99478},
{x:42.634214,y:-76.191305},
{x:41.90868,y:-71.25536},
{x:39.25375,y:-81.55397},
{x:44.536617,y:-73.247342},
{x:40.64237,y:-74.01338},
{x:38.368594,y:-75.620886},
{x:40.87202,y:-75.23357},
{x:39.10084,y:-84.51188},
{x:40.709786,y:-74.189727},
{x:41.418749,y:-73.952587},
{x:42.982228,y:-71.450332},
{x:40.83523,y:-81.38922},
{x:40.65056,y:-73.95874},
{x:26.06424,y:-80.27877},
{x:26.80882,y:-80.08053},
{x:26.14587,y:-80.20333},
{x:25.003441,y:-80.524472},
{x:34.19829,y:-84.5085},
{x:39.264847,y:-74.64714},
{x:38.60507,y:-90.58554},
{x:44.795308,y:-73.073622},
{x:41.51385,y:-71.696948},
{x:27.19895,y:-80.83065},
{x:38.89621,y:-77.046154},
{x:42.558537,y:-71.136457},
{x:41.86734,y:-72.49246},
{x:41.02464,y:-80.68339},
{x:30.475553,y:-84.296499},
{x:28.440348,y:-81.403258},
{x:41.15389,y:-81.35013},
{x:28.614406,y:-81.365644},
{x:40.697837,y:-74.244995},
{x:34.656902,y:-84.493918},
{x:42.789568,y:-71.234987},
{x:34.214073,y:-80.980324},
{x:43.049415,y:-89.502947},
{x:42.727095,y:-90.475861},
{x:40.79849,y:-73.9528},
{x:40.40433,y:-74.322543},
{x:43.857746,y:-73.438943},
{x:34.617011,y:-92.498041},
{x:41.817937,y:-87.723349},
{x:42.915338,y:-71.449749},
{x:41.84522,y:-71.437172},
{x:40.81007,y:-73.924989},
{x:39.22654,y:-84.3944},
{x:26.64041,y:-80.20442},
{x:40.643146,y:-73.782167},
{x:40.642631,y:-73.782871},
{x:43.038005,y:-88.256638},
{x:38.843819,y:-77.292343},
{x:40.825623,y:-73.918831},
{x:43.288948,y:-71.581492},
{x:43.122381,y:-71.480273},
{x:41.881506,y:-72.302124},
{x:42.714375,y:-71.117493},
{x:39.451735,y:-74.645941},
{x:43.613717,y:-72.964798},
{x:40.93471,y:-73.02292},
{x:34.26083,y:-85.18249},
{x:40.877857,y:-73.982039},
{x:40.719555,y:-74.180502},
{x:41.590651,y:-93.713134},
{x:40.719625,y:-74.04309},
{x:41.445119,y:-74.36336},
{x:42.216766,y:-70.964411},
{x:41.928093,y:-70.80956},
{x:42.368637,y:-71.272235},
{x:26.155303,y:-81.687799},
{x:28.193858,y:-82.635237},
{x:37.339239,y:-76.752602},
{x:40.862099,y:-73.061682},
{x:30.22531,y:-85.64993},
{x:35.820635,y:-78.901987},
{x:42.927061,y:-74.200122},
{x:32.48118,y:-84.94262},
{x:44.651744,y:-73.483721},
{x:36.08089,y:-79.47971},
{x:40.70684,y:-74.00879},
{x:26.707733,y:-80.147451},
{x:37.08659,y:-84.61721},
{x:39.0338,y:-94.27188},
{x:30.114674,y:-81.811961},
{x:41.925308,y:-87.65275},
{x:41.923827,y:-87.639495},
{x:35.768874,y:-86.917747},
{x:41.75352,y:-71.11532},
{x:34.187412,y:-84.787175},
{x:33.9278,y:-83.33707},
{x:42.557553,y:-71.298533},
{x:42.00461,y:-87.747984},
{x:36.28456,y:-115.287645},
{x:34.149788,y:-84.172784},
{x:34.72708,y:-86.640036},
{x:40.75624,y:-74.167113},
{x:37.227763,y:-80.419127},
{x:41.883813,y:-87.627978},
{x:42.247884,y:-71.43015},
{x:26.12142,y:-80.20307},
{x:40.228148,y:-74.665671},
{x:42.599282,y:-70.960404},
{x:39.28627,y:-77.314771},
{x:28.565465,y:-81.1654},
{x:38.88032,y:-77.17299},
{x:38.77296,y:-90.4918},
{x:41.452597,y:-91.034588},
{x:40.894537,y:-73.823691},
{x:42.12002,y:-87.745797},
{x:40.602,y:-73.994},
{x:36.821091,y:-76.424809},
{x:39.179483,y:-77.238026},
{x:38.994437,y:-77.031127},
{x:33.569376,y:-84.543071},
{x:42.19884,y:-87.932107},
{x:40.774197,y:-73.948492},
{x:30.205591,y:-81.615625},
{x:30.156174,y:-81.632482},
{x:42.185472,y:-71.309556},
{x:40.729076,y:-73.990143},
{x:42.13299,y:-72.799701},
{x:38.998444,y:-76.913637},
{x:41.629829,y:-86.250278},
{x:33.951173,y:-81.233341},
{x:34.210882,y:-77.887838},
{x:33.217633,y:-87.545648},
{x:36.298423,y:-82.469668},
{x:42.703997,y:-73.816308},
{x:33.82223,y:-84.36753},
{x:42.342336,y:-71.056166},
{x:37.173015,y:-76.475561},
{x:41.865719,y:-87.88233},
{x:40.699917,y:-73.911619},
{x:40.67886,y:-73.83218},
{x:42.730277,y:-73.761614},
{x:40.18336,y:-74.87935},
{x:33.523731,y:-112.047491},
{x:41.779859,y:-77.070091},
{x:32.253161,y:-110.944015},
{x:31.792664,y:-106.260048},
{x:32.842331,y:-96.846778},
{x:32.842583,y:-96.846585},
{x:42.766913,y:-72.389488},
{x:40.88461,y:-80.6512},
{x:39.497461,y:-76.232158},
{x:35.05041,y:-89.80695},
{x:34.05551,y:-84.12929},
{x:41.371358,y:-75.73481},
{x:27.19747,y:-80.22365},
{x:40.31068,y:-79.61937},
{x:40.836789,y:-74.113435},
{x:41.650598,y:-70.239515},
{x:41.93985,y:-87.653177},
{x:32.458863,y:-93.667584},
{x:40.35571,y:-79.83475},
{x:33.567436,y:-112.169692},
{x:40.37646,y:-79.98406},
{x:39.185149,y:-76.870849},
{x:41.97916,y:-87.806791},
{x:42.00288,y:-88.181426},
{x:41.85567,y:-72.70589},
{x:35.01526,y:-80.57067},
{x:40.89175,y:-72.90057},
{x:34.954599,y:-80.851524},
{x:35.295428,y:-80.740415},
{x:42.011087,y:-92.912258},
{x:26.27309,y:-80.2003},
{x:26.64936,y:-80.05511},
{x:40.58066,y:-73.96638},
{x:42.12206,y:-87.936953},
{x:41.735502,y:-87.667665},
{x:40.10144,y:-74.79574},
{x:39.954862,y:-75.202419},
{x:39.96823,y:-74.89843},
{x:41.404508,y:-75.405902},
{x:29.496798,y:-98.712692},
{x:27.389815,y:-82.519052},
{x:31.728178,y:-106.302959},
{x:40.180006,y:-75.454961},
{x:35.749029,y:-81.334974},
{x:40.93246,y:-72.31628},
{x:42.524868,y:-83.521356},
{x:28.62351,y:-81.208294},
{x:38.990483,y:-78.365434},
{x:32.665782,y:-97.133868},
{x:39.77751,y:-94.80344},
{x:27.495764,y:-82.616208},
{x:33.626557,y:-111.979391},
{x:34.89284,y:-84.26045},
{x:41.82328,y:-72.88983},
{x:33.354343,y:-84.122417},
{x:38.903614,y:-77.031818},
{x:40.70032,y:-73.37793},
{x:40.547788,y:-74.326511},
{x:39.523372,y:-77.978382},
{x:32.926441,y:-80.686034},
{x:35.277262,y:-81.536543},
{x:42.728936,y:-73.690961},
{x:30.3995,y:-87.27744},
{x:28.66492,y:-81.420983},
{x:39.815793,y:-75.258688},
{x:33.46838,y:-86.77596},
{x:38.878467,y:-77.227784},
{x:40.986196,y:-74.063749},
{x:38.891333,y:-77.086488},
{x:39.948818,y:-74.971946},
{x:38.317306,y:-85.715808},
{x:42.991848,y:-88.047258},
{x:42.022749,y:-93.654253},
{x:36.818771,y:-76.272687},
{x:40.754556,y:-73.981155},
{x:31.859614,y:-106.561178},
{x:32.195459,y:-81.196775},
{x:41.31165,y:-81.4463},
{x:32.566243,y:-85.501859},
{x:39.28774,y:-84.46747},
{x:40.139612,y:-74.706868},
{x:40.72498,y:-73.87933},
{x:42.317701,y:-75.412583},
{x:43.053498,y:-76.170902},
{x:32.333943,y:-106.781558},
{x:42.660903,y:-71.426786},
{x:40.981226,y:-73.776317},
{x:26.93426,y:-80.13029},
{x:40.04415,y:-75.38761},
{x:40.017726,y:-75.311255},
{x:40.003226,y:-75.165021},
{x:40.089437,y:-74.963979},
{x:39.62049,y:-79.92327},
{x:43.050073,y:-76.142483},
{x:35.730195,y:-83.996516},
{x:33.20561,y:-87.56902},
{x:41.82836,y:-88.20082},
{x:31.138748,y:-81.581247},
{x:41.903592,y:-88.119996},
{x:41.917853,y:-87.672393},
{x:28.025082,y:-82.461493},
{x:40.82386,y:-81.50325},
{x:39.572551,y:-75.05146},
{x:40.385032,y:-75.787338},
{x:40.13476,y:-75.00832},
{x:43.362702,y:-72.171586},
{x:44.244095,y:-88.349207},
{x:42.419617,y:-76.520614},
{x:35.964093,y:-77.851867},
{x:42.324299,y:-88.164838},
{x:41.600214,y:-93.829403},
{x:41.161665,y:-96.023677},
{x:40.79535,y:-81.52682},
{x:42.588963,y:-71.157908},
{x:25.713589,y:-80.417366},
{x:35.976342,y:-78.547339},
{x:34.890155,y:-82.217088},
{x:40.819902,y:-73.901622},
{x:34.890878,y:-82.21683},
{x:29.985488,y:-95.332846},
{x:26.083404,y:-80.23457},
{x:26.008395,y:-80.233844},
{x:38.89354,y:-77.048961},
{x:40.642951,y:-73.790371},
{x:35.142975,y:-80.719738},
{x:41.878866,y:-87.639305},
{x:40.1454,y:-75.3188},
{x:44.523921,y:-68.398848},
{x:28.054845,y:-82.392206},
{x:40.667196,y:-73.811259},
{x:42.957964,y:-71.506143},
{x:32.906542,y:-97.044103},
{x:40.643901,y:-73.782549},
{x:33.507242,y:-84.140434},
{x:26.273711,y:-81.752488},
{x:41.773099,y:-87.57598},
{x:41.67115,y:-91.574397},
{x:40.882089,y:-73.986711},
{x:27.63905,y:-80.51253},
{x:33.83069,y:-84.4926},
{x:27.978343,y:-82.827297},
{x:34.13645,y:-84.248826},
{x:40.604091,y:-73.971868},
{x:33.436908,y:-79.131635},
{x:41.440925,y:-71.802066},
{x:42.742367,y:-71.4943},
{x:40.72291,y:-73.45846},
{x:35.374279,y:-83.211879},
{x:38.596153,y:-90.48025},
{x:40.71017,y:-74.00761},
{x:36.30875,y:-82.331446},
{x:35.19041,y:-111.661068},
{x:40.591947,y:-75.590493},
{x:41.896392,y:-87.655408},
{x:26.81123,y:-80.19861},
{x:40.27945,y:-76.81627},
{x:29.55291,y:-98.40503},
{x:40.68121,y:-73.95546},
{x:26.612221,y:-81.810666},
{x:41.90741,y:-87.872626},
{x:40.756973,y:-73.992233},
{x:40.757048,y:-73.990266},
{x:26.78319,y:-80.10782},
{x:41.744881,y:-93.601274},
{x:39.337358,y:-76.398716},
{x:39.49897,y:-74.994682},
{x:39.01441,y:-94.72756},
{x:41.788142,y:-87.74224},
{x:41.03719,y:-74.504823},
{x:39.023212,y:-76.925296},
{x:41.742467,y:-72.710942},
{x:40.70888,y:-74.0091},
{x:43.105316,y:-76.146252},
{x:41.85934,y:-88.059872},
{x:33.81117,y:-86.82315},
{x:42.984693,y:-71.471882},
{x:40.201225,y:-75.121837},
{x:41.767594,y:-72.696857},
{x:41.902944,y:-87.680217},
{x:42.01834,y:-87.673008},
{x:41.42203,y:-81.60978},
{x:40.7525,y:-73.97329},
{x:40.784691,y:-73.949627},
{x:33.960409,y:-80.397303},
{x:38.887258,y:-77.004676},
{x:33.997906,y:-80.99441},
{x:40.69669,y:-73.93529},
{x:38.246338,y:-85.526025},
{x:39.1223,y:-76.516372},
{x:41.931305,y:-88.773627},
{x:40.78063,y:-73.952507},
{x:39.351825,y:-84.461727},
{x:43.02357,y:-71.369377},
{x:38.344303,y:-77.497762},
{x:30.111293,y:-81.704535},
{x:40.75593,y:-73.88095},
{x:39.161665,y:-77.159498},
{x:33.977536,y:-84.080089},
{x:39.293836,y:-84.29548},
{x:39.856443,y:-75.124882},
{x:29.650355,y:-95.180318},
{x:40.530139,y:-88.995967},
{x:25.986362,y:-80.247881},
{x:40.712621,y:-73.990462},
{x:40.5862,y:-73.69864},
{x:25.793671,y:-80.277708},
{x:30.172566,y:-81.701769},
{x:39.836219,y:-104.71962},
{x:39.216252,y:-78.14114},
{x:42.944813,y:-88.048205},
{x:42.034726,y:-91.684755},
{x:41.248536,y:-96.138473},
{x:41.552633,y:-93.596475},
{x:43.054961,y:-76.558187},
{x:29.501445,y:-82.870221},
{x:28.523563,y:-82.234697},
{x:39.394507,y:-75.018711},
{x:39.99074,y:-75.026622},
{x:38.446738,y:-90.371511},
{x:41.76076,y:-88.065191},
{x:41.313609,y:-81.686584},
{x:41.636805,y:-93.697965},
{x:41.600599,y:-93.567576},
{x:38.366596,y:-75.549426},
{x:41.707527,y:-87.614091},
{x:42.53745,y:-71.706356},
{x:43.093882,y:-75.277524},
{x:41.97377,y:-88.150273},
{x:26.034094,y:-80.159202},
{x:42.218181,y:-71.54095},
{x:39.094938,y:-84.611191},
{x:42.364145,y:-87.963552},
{x:42.712865,y:-71.163161},
{x:42.0135,y:-71.232787},
{x:26.720525,y:-80.087596},
{x:44.102839,y:-70.17685},
{x:25.687085,y:-80.352876},
{x:31.785021,y:-106.414856},
{x:29.736361,y:-95.564962},
{x:40.775059,y:-73.028206},
{x:44.983518,y:-69.003603},
{x:26.680419,y:-80.183555},
{x:40.38187,y:-75.14619},
{x:40.190748,y:-75.532177},
{x:36.078207,y:-80.299064},
{x:39.652368,y:-74.18157},
{x:35.344356,y:-80.170915},
{x:43.080286,y:-75.391526},
{x:41.660553,y:-72.918052},
{x:27.332007,y:-80.35279},
{x:29.5105,y:-98.389256},
{x:28.971901,y:-80.895854},
{x:39.136228,y:-76.749659},
{x:40.199805,y:-75.343862},
{x:40.783937,y:-73.638526},
{x:40.701227,y:-73.83123},
{x:40.169106,y:-76.198402},
{x:27.959176,y:-81.622609},
{x:27.974706,y:-82.48516},
{x:41.1304,y:-81.50868},
{x:41.932845,y:-87.688222},
{x:42.426948,y:-71.014089},
{x:42.03527,y:-88.314522},
{x:42.1222,y:-88.049657},
{x:41.977427,y:-70.687934},
{x:44.504461,y:-73.18121},
{x:36.3569,y:-94.184987},
{x:30.37965,y:-86.355531},
{x:33.032579,y:-80.163373},
{x:33.98376,y:-81.058168},
{x:42.335024,y:-71.035596},
{x:38.975428,y:-76.298984},
{x:26.047687,y:-80.160613},
{x:41.618416,y:-72.899513},
{x:40.646449,y:-73.958068},
{x:35.464506,y:-80.592211},
{x:42.491067,y:-90.701805},
{x:29.826993,y:-81.271841},
{x:42.608695,y:-72.075492},
{x:38.984367,y:-74.814056},
{x:42.348409,y:-71.147932},
{x:39.989382,y:-75.551107},
{x:40.107877,y:-74.87768},
{x:41.829257,y:-72.480491},
{x:41.501085,y:-81.540357},
{x:41.402773,y:-82.364063},
{x:39.296906,y:-81.547181},
{x:33.681969,y:-79.000823},
{x:40.842321,y:-73.939284},
{x:39.747723,y:-105.053425},
{x:40.102686,y:-74.105115},
{x:40.70367,y:-74.01166},
{x:30.679679,y:-88.225011},
{x:41.565587,y:-87.849364},
{x:33.106129,y:-83.252899},
{x:40.718473,y:-73.817164},
{x:44.265216,y:-69.783975},
{x:34.090045,y:-81.17197},
{x:44.40737,y:-68.596344},
{x:40.885551,y:-73.910244},
{x:39.446051,y:-77.382577},
{x:28.268094,y:-81.240845},
{x:42.751479,y:-71.344218},
{x:33.041964,y:-97.032997},
{x:40.867825,y:-73.833367},
{x:39.94581,y:-75.239463},
{x:39.143853,y:-84.387384},
{x:39.948325,y:-75.20627},
{x:40.298568,y:-76.846334},
{x:41.072229,y:-73.549882},
{x:41.47209,y:-87.51869},
{x:40.727722,y:-74.038674},
{x:40.666448,y:-73.756925},
{x:42.464642,y:-87.945603},
{x:42.057316,y:-76.818724},
{x:32.247973,y:-80.846218},
{x:41.66325,y:-87.656336},
{x:41.795147,y:-87.626316},
{x:41.789782,y:-76.780094},
{x:42.988264,y:-88.243373},
{x:39.721942,y:-104.987095},
{x:29.744898,y:-95.411119},
{x:43.075007,y:-76.12355},
{x:30.073712,y:-95.516317},
{x:41.205788,y:-96.07992},
{x:41.019307,y:-73.843278},
{x:26.682666,y:-81.906104},
{x:43.309554,y:-72.497313},
{x:30.004209,y:-90.179891},
{x:44.892548,y:-68.661556},
{x:29.285283,y:-81.037171},
{x:40.825103,y:-74.232092},
{x:39.000247,y:-94.48109},
{x:40.733616,y:-74.161375},
{x:44.739081,y:-85.647647},
{x:41.715337,y:-72.763421},
{x:43.141363,y:-76.186839},
{x:42.604556,y:-83.265906},
{x:41.359081,y:-74.28482},
{x:42.392628,y:-71.557522},
{x:39.429671,y:-74.508498},
{x:39.46685,y:-74.499405},
{x:40.704962,y:-74.011758},
{x:38.415333,y:-75.567023},
{x:29.742398,y:-96.329808},
{x:42.949343,y:-77.600306},
{x:41.386579,y:-73.444962},
{x:43.188099,y:-77.701939},
{x:28.136983,y:-82.755899},
{x:39.422593,y:-76.371266},
{x:35.228689,y:-80.867052},
{x:39.290344,y:-76.716113},
{x:38.794779,y:-90.698516},
{x:29.981395,y:-95.16368},
{x:29.802771,y:-82.516357},
{x:41.883086,y:-87.634604},
{x:28.266049,y:-80.73794},
{x:42.974938,y:-77.230352},
{x:43.157147,y:-77.614159},
{x:43.12096,y:-77.806621},
{x:38.989385,y:-77.098243},
{x:25.985472,y:-80.299484},
{x:32.353903,y:-111.094165},
{x:27.791589,y:-82.674305},
{x:38.902298,y:-77.0397},
{x:38.907392,y:-77.003622},
{x:42.10146,y:-87.77489},
{x:40.827483,y:-73.925819},
{x:33.023584,y:-112.050799},
{x:40.907819,y:-73.068631},
{x:39.491641,y:-76.647023},
{x:28.6666,y:-80.871975},
{x:38.328878,y:-75.087682},
{x:26.43393,y:-81.435457},
{x:32.82286,y:-79.839367},
{x:40.763367,y:-73.981658},
{x:42.062443,y:-88.144122},
{x:29.813268,y:-95.720502},
{x:41.875306,y:-80.767079},
{x:36.31801,y:-86.600875},
{x:32.319153,y:-106.73622},
{x:43.723822,y:-87.735521},
{x:41.552759,y:-73.416503},
{x:40.716895,y:-73.989526},
{x:33.903162,y:-84.484697},
{x:41.927877,y:-72.707994},
{x:39.853516,y:-104.674073},
{x:39.02366,y:-84.607998},
{x:38.037465,y:-84.598632},
{x:39.801107,y:-89.686516},
{x:40.981613,y:-76.024127},
{x:41.723986,y:-85.973622},
{x:43.038141,y:-87.909158},
{x:34.641558,y:-86.566302},
{x:37.049142,y:-76.309896},
{x:42.433187,y:-71.686486},
{x:36.005365,y:-85.044774},
{x:40.692739,y:-73.808785},
{x:40.58261,y:-73.836638},
{x:40.919047,y:-74.169803},
{x:40.69364,y:-74.236724},
{x:36.469539,y:-86.67677},
{x:40.766421,y:-73.96514},
{x:33.178335,y:-111.582284},
{x:33.23434,y:-111.72194},
{x:42.667475,y:-71.119501},
{x:38.439252,y:-78.871437},
{x:42.671351,y:-72.549717},
{x:39.617437,y:-75.231427},
{x:40.455121,y:-74.425106},
{x:37.718498,y:-77.452938},
{x:29.881766,y:-95.683823},
{x:40.946652,y:-73.794801},
{x:39.627787,y:-104.795177},
{x:40.862709,y:-73.900805},
{x:41.075535,y:-85.273186},
{x:41.762669,y:-71.899855},
{x:42.740725,y:-84.673009},
{x:39.927829,y:-86.010394},
{x:38.898187,y:-77.028406},
{x:34.104097,y:-82.853358},
{x:29.441031,y:-98.667825},
{x:27.90674,y:-81.842952},
{x:35.346559,y:-80.842817},
{x:39.228932,y:-77.25029},
{x:42.289108,y:-71.428371},
{x:42.90054,y:-87.865246},
{x:42.10528,y:-87.828313},
{x:42.608791,y:-83.03208},
{x:43.331149,y:-75.747113},
{x:44.691052,y:-75.476123},
{x:42.464538,y:-87.881125},
{x:42.303602,y:-87.876249},
{x:39.689912,y:-77.735427},
{x:39.063707,y:-76.839242},
{x:41.92185,y:-72.669878},
{x:34.474138,y:-93.060333},
{x:25.787515,y:-80.212207},
{x:40.424834,y:-74.197337},
{x:34.47274,y:-84.923635},
{x:38.43841,y:-82.01762},
{x:39.854312,y:-75.165516},
{x:39.892901,y:-74.915148},
{x:40.903909,y:-74.12753},
{x:40.253517,y:-74.338576},
{x:26.348765,y:-80.228405},
{x:33.447204,y:-84.323794},
{x:44.138141,y:-72.663183},
{x:35.460087,y:-80.99301},
{x:37.811118,y:-81.1859},
{x:28.32434,y:-80.716657},
{x:41.968299,y:-87.712931},
{x:30.088805,y:-81.944487},
{x:29.524922,y:-95.075525},
{x:30.072947,y:-90.494802},
{x:30.650112,y:-87.911965},
{x:41.765679,y:-88.14867},
{x:39.397873,y:-76.405269},
{x:40.691214,y:-74.176898},
{x:42.124034,y:-71.231971},
{x:39.358088,y:-74.421537},
{x:38.794727,y:-77.516279},
{x:41.2231,y:-73.91373},
{x:41.396865,y:-75.650553},
{x:40.594124,y:-73.789465},
{x:40.691849,y:-73.762165},
{x:44.567445,y:-69.637552},
{x:44.310757,y:-69.782064},
{x:42.86689,y:-71.345821},
{x:41.115568,y:-100.761932},
{x:40.072299,y:-74.74298},
{x:40.602692,y:-74.44589},
{x:40.822459,y:-73.046392},
{x:43.799997,y:-73.089873},
{x:40.815655,y:-73.896848},
{x:34.673615,y:-86.539011},
{x:26.753865,y:-80.946692},
{x:40.711902,y:-74.348938},
{x:40.010479,y:-82.867618},
{x:43.063173,y:-77.223782},
{x:40.327623,y:-75.887842},
{x:40.827492,y:-72.734671},
{x:41.15088,y:-73.424763},
{x:33.175952,y:-87.524061},
{x:36.149878,y:-86.805983},
{x:26.245639,y:-81.688795},
{x:40.819938,y:-73.913456},
{x:38.15757,y:-85.712838},
{x:40.668938,y:-74.203047},
{x:40.622025,y:-74.025698},
{x:42.072809,y:-88.189926},
{x:40.557611,y:-76.792705},
{x:41.896832,y:-87.628618},
{x:42.408339,y:-88.004257},
{x:38.897559,y:-77.007065},
{x:43.073619,y:-77.947848},
{x:39.136227,y:-88.566147},
{x:42.885779,y:-87.912023},
{x:27.71381,y:-82.376658},
{x:42.961096,y:-87.948451},
{x:38.788283,y:-77.301368},
{x:29.681493,y:-95.122973},
{x:41.5874,y:-81.47956},
{x:33.713849,y:-84.216974},
{x:40.112967,y:-75.285717},
{x:26.575469,y:-80.054166},
{x:40.520506,y:-75.77469},
{x:43.242489,y:-78.193467},
{x:36.954106,y:-76.529891},
{x:42.740511,y:-71.796114},
{x:43.206948,y:-78.387591},
{x:28.625634,y:-81.317628},
{x:43.241731,y:-77.696075},
{x:41.986378,y:-87.835056},
{x:40.729558,y:-73.78052},
{x:27.99007,y:-82.730197},
{x:43.224136,y:-77.65854},
{x:29.58342,y:-95.214603},
{x:44.440478,y:-73.113968},
{x:40.632342,y:-74.313396},
{x:40.835868,y:-73.94386},
{x:40.750926,y:-73.98477},
{x:43.604434,y:-70.365925},
{x:41.377119,y:-87.470755},
{x:41.810374,y:-87.59239},
{x:43.201171,y:-77.943281},
{x:42.738629,y:-73.673405},
{x:30.226687,y:-81.38738},
{x:27.906781,y:-82.505798},
{x:35.105346,y:-80.80775},
{x:41.114929,y:-73.771078},
{x:40.538018,y:-79.835333},
{x:40.33676,y:-80.023727},
{x:26.212446,y:-80.108836},
{x:40.631191,y:-74.076524},
{x:40.419506,y:-74.184877},
{x:40.755318,y:-73.81594},
{x:39.224672,y:-84.516719},
{x:40.858824,y:-82.271146},
{x:39.953635,y:-75.166863},
{x:41.490189,y:-71.52811},
{x:28.6068,y:-81.198559},
{x:26.397044,y:-80.148865},
{x:39.614628,y:-75.502432},
{x:38.984344,y:-77.094376},
{x:40.749589,y:-73.519097},
{x:40.864904,y:-74.129665},
{x:41.479839,y:-88.081845},
{x:41.254405,y:-96.023369},
{x:40.752091,y:-73.983677},
{x:40.869644,y:-73.829357},
{x:38.970685,y:-94.725776},
{x:32.908793,y:-96.582669},
{x:32.887563,y:-96.473086},
{x:40.125034,y:-75.516683},
{x:31.959858,y:-110.984078},
{x:41.947144,y:-87.65381},
{x:41.687462,y:-88.379903},
{x:42.072648,y:-87.806459},
{x:30.429574,y:-81.658937},
{x:40.7178,y:-73.957912},
{x:28.818676,y:-81.887815},
{x:28.157209,y:-82.485028},
{x:40.943878,y:-72.974565},
{x:40.918309,y:-72.67299},
{x:27.2748,y:-80.353268},
{x:40.659796,y:-74.263913},
{x:35.893646,y:-75.665129},
{x:39.855191,y:-104.97871},
{x:39.858174,y:-105.082356},
{x:39.989239,y:-75.155741},
{x:41.552259,y:-74.06365},
{x:39.106497,y:-84.511639},
{x:41.060221,y:-80.615478},
{x:26.761109,y:-81.43592},
{x:39.570236,y:-76.380948},
{x:42.727898,y:-77.866976},
{x:43.477378,y:-75.318362},
{x:37.731455,y:-89.194352},
{x:40.033534,y:-105.259177},
{x:27.850352,y:-82.786827},
{x:35.190265,y:-84.883548},
{x:40.856051,y:-81.422674},
{x:41.80325,y:-87.762221},
{x:42.055082,y:-87.739153},
{x:42.188514,y:-87.803292},
{x:44.676167,y:-90.166675},
{x:41.718479,y:-87.720902},
{x:26.836046,y:-80.104917},
{x:40.829041,y:-73.875764},
{x:41.377083,y:-81.734362},
{x:28.136292,y:-82.740659},
{x:28.079327,y:-80.667913},
{x:40.803382,y:-73.991527},
{x:41.212279,y:-95.985675},
{x:27.448136,y:-82.526141},
{x:40.837653,y:-73.333455},
{x:41.895923,y:-87.621237},
{x:26.233338,y:-80.221193},
{x:40.733111,y:-73.987035},
{x:40.729801,y:-73.979305},
{x:39.788225,y:-75.057014},
{x:40.097241,y:-75.125939},
{x:29.058585,y:-82.049911},
{x:40.258345,y:-75.265336},
{x:40.960682,y:-74.237903},
{x:40.797279,y:-74.182402},
{x:42.437187,y:-76.465448},
{x:45.361649,y:-68.518466},
{x:28.448686,y:-81.476762},
{x:33.416803,y:-82.023238},
{x:41.542383,y:-73.834829},
{x:41.988953,y:-88.340452},
{x:39.311117,y:-76.740825},
{x:40.402377,y:-74.298679},
{x:44.016666,y:-90.504672},
{x:33.956509,y:-118.020453},
{x:41.751096,y:-88.00501},
{x:34.91995,y:-81.994431},
{x:39.61343,y:-84.15993},
{x:40.828068,y:-74.149574},
{x:35.78923,y:-86.399666},
{x:33.775113,y:-118.123275},
{x:33.349907,y:-111.875032},
{x:41.896473,y:-87.686361},
{x:42.094535,y:-88.005664},
{x:35.739983,y:-81.306718},
{x:42.366753,y:-83.385603},
{x:39.595554,y:-104.877448},
{x:41.684337,y:-87.641803},
{x:26.062994,y:-80.168055},
{x:43.087568,y:-77.594814},
{x:30.363799,y:-81.502304},
{x:26.212764,y:-81.750989},
{x:33.786795,y:-84.618488},
{x:30.288136,y:-81.459823},
{x:30.138145,y:-81.546928},
{x:28.578313,y:-81.389343},
{x:28.461228,y:-81.272122},
{x:42.903522,y:-71.217206},
{x:40.710713,y:-73.792556},
{x:30.014098,y:-97.858794},
{x:43.069091,y:-77.646019},
{x:34.03256,y:-84.050788},
{x:38.902901,y:-76.740629},
{x:39.152192,y:-76.727081},
{x:40.908144,y:-74.150767},
{x:32.534019,y:-97.335877},
{x:32.951922,y:-96.769344},
{x:41.683325,y:-71.148503},
{x:41.918681,y:-88.293707},
{x:41.900034,y:-88.336681},
{x:40.336333,y:-74.593694},
{x:40.912558,y:-74.037372},
{x:40.6938,y:-73.96434},
{x:40.668168,y:-73.931122},
{x:39.762252,y:-75.306342},
{x:33.52203,y:-101.956478},
{x:40.79242,y:-73.807373},
{x:41.846959,y:-87.969686},
{x:43.498132,y:-71.027005},
{x:26.44078,y:-80.1217},
{x:26.650273,y:-80.194389},
{x:26.617934,y:-80.175042},
{x:33.642193,y:-84.435774},
{x:43.094407,y:-77.614097},
{x:39.951577,y:-83.136389},
{x:40.578493,y:-75.480291},
{x:27.299608,y:-82.514518},
{x:38.61692,y:-94.350736},
{x:32.667945,y:-114.442607},
{x:41.380153,y:-72.434419},
{x:40.612567,y:-74.000507},
{x:44.915934,y:-89.649765},
{x:44.904883,y:-89.573065},
{x:41.326303,y:-73.687241},
{x:42.108906,y:-72.59513},
{x:41.418831,y:-87.33622},
{x:30.332036,y:-82.960203},
{x:35.60014,y:-82.401997},
{x:29.999078,y:-95.564307},
{x:29.563301,y:-95.114619},
{x:40.659085,y:-73.981618},
{x:41.11141,y:-83.20505},
{x:28.510345,y:-81.270584},
{x:42.54664,y:-71.854044},
{x:44.340099,y:-69.756459},
{x:41.900795,y:-88.472578},
{x:42.253806,y:-89.034683},
{x:33.522334,y:-101.870004},
{x:40.050713,y:-74.191661},
{x:42.215409,y:-72.205606},
{x:33.202454,y:-96.733636},
{x:33.737573,y:-118.006716},
{x:40.821311,y:-72.642504},
{x:29.652218,y:-82.341146},
{x:41.721003,y:-87.660144},
{x:27.448065,y:-80.359101},
{x:27.469777,y:-80.333015},
{x:34.765889,y:-92.387104},
{x:33.43836,y:-84.121496},
{x:40.962255,y:-81.464715},
{x:27.533202,y:-81.809792},
{x:33.850947,y:-84.215161},
{x:43.227897,y:-77.289593},
{x:41.709397,y:-88.203265},
{x:41.33273,y:-73.81067},
{x:40.755864,y:-96.684366},
{x:28.01024,y:-82.70957},
{x:41.182488,y:-80.71877},
{x:35.951323,y:-84.155748},
{x:32.835283,y:-83.636018},
{x:43.109328,y:-88.494081},
{x:30.640443,y:-81.458251},
{x:28.551329,y:-81.50595},
{x:42.137446,y:-71.969507},
{x:40.257436,y:-74.79679},
{x:40.797369,y:-74.015476},
{x:36.826062,y:-76.338057},
{x:44.023011,y:-92.464657},
{x:38.177683,y:-85.818947},
{x:40.747937,y:-73.985735},
{x:40.669526,y:-73.842181},
{x:42.977605,y:-78.004305},
{x:40.84425,y:-73.888961},
{x:36.026144,y:-83.927619},
{x:44.721322,y:-73.469508},
{x:26.155875,y:-80.257709},
{x:32.902541,y:-97.043586},
{x:42.236629,y:-87.993295},
{x:29.728585,y:-95.644679},
{x:36.852167,y:-76.210589},
{x:42.653909,y:-71.13891},
{x:40.851083,y:-73.930799},
{x:31.575887,y:-84.154739},
{x:33.407901,y:-84.674281},
{x:40.30574,y:-74.099704},
{x:34.801326,y:-77.440825},
{x:42.112535,y:-71.678827},
{x:40.05813,y:-77.522303},
{x:30.336379,y:-95.468237},
{x:38.335215,y:-75.083067},
{x:41.926495,y:-73.076309},
{x:44.590435,y:-88.080741},
{x:44.451352,y:-88.085422},
{x:41.33899,y:-89.115778},
{x:26.395304,y:-80.078464},
{x:27.299335,y:-81.357884},
{x:35.117256,y:-81.079592},
{x:40.714169,y:-73.951174},
{x:39.625029,y:-75.744661},
{x:38.459651,y:-78.011409},
{x:41.548874,y:-87.966417},
{x:41.616676,y:-88.122766},
{x:33.94568,y:-83.750062},
{x:40.251331,y:-74.562884},
{x:44.507977,y:-73.085525},
{x:40.687302,y:-73.821898},
{x:40.60834,y:-74.162215},
{x:44.828124,y:-91.545159},
{x:40.709859,y:-73.62522},
{x:28.476419,y:-80.779713},
{x:42.270349,y:-88.9732},
{x:27.814278,y:-82.778018},
{x:41.918401,y:-87.726389},
{x:41.800246,y:-88.375808},
{x:33.320942,y:-111.720692},
{x:43.615004,y:-70.525247},
{x:34.116878,y:-86.863922},
{x:33.934415,y:-118.122135},
{x:33.70148,y:-117.953906},
{x:41.274311,y:-72.967265},
{x:40.796827,y:-74.345256},
{x:33.954577,y:-84.132323},
{x:39.42725,y:-77.417548},
{x:41.236705,y:-80.767335},
{x:44.422066,y:-69.019524},
{x:39.942439,y:-104.998117},
{x:39.902195,y:-79.75158},
{x:40.004252,y:-83.00853},
{x:39.652709,y:-104.930228},
{x:30.448756,y:-84.308315},
{x:39.948487,y:-75.166047},
{x:27.733736,y:-82.745448},
{x:26.028859,y:-80.370566},
{x:43.987623,y:-75.941729},
{x:44.039052,y:-75.902485},
{x:40.149344,y:-74.999291},
{x:43.966305,y:-70.580763},
{x:32.698313,y:-114.624274},
{x:42.652322,y:-73.757621},
{x:43.35052,y:-72.360309},
{x:40.834828,y:-73.149988},
{x:41.674072,y:-74.82656},
{x:39.783036,y:-104.772622},
{x:40.84011,y:-73.876512},
{x:40.559651,y:-89.618287},
{x:41.105499,y:-73.428781},
{x:40.848777,y:-73.84234},
{x:40.709992,y:-74.087571},
{x:43.112166,y:-77.549734},
{x:39.616428,y:-77.416594},
{x:39.320836,y:-77.019605},
{x:42.513415,y:-92.429784},
{x:26.047277,y:-80.199913},
{x:41.857629,y:-87.668475},
{x:27.024018,y:-80.471075},
{x:36.011211,y:-84.266503},
{x:41.859898,y:-88.03789},
{x:26.66389,y:-80.268593},
{x:40.134958,y:-75.448126},
{x:29.556873,y:-81.215629},
{x:26.65148,y:-80.078034},
{x:26.271771,y:-80.233393},
{x:26.189234,y:-80.137411},
{x:29.165737,y:-82.095947},
{x:42.369554,y:-71.11148},
{x:26.441536,y:-80.092075},
{x:29.082329,y:-82.254803},
{x:42.280098,y:-87.876971},
{x:35.474186,y:-80.891293},
{x:41.666279,y:-74.107225},
{x:32.854864,y:-83.618339},
{x:39.674439,y:-104.866344},
{x:29.211083,y:-82.142671},
{x:39.638653,y:-84.109505},
{x:41.704255,y:-73.928506},
{x:41.615552,y:-86.730509},
{x:41.215518,y:-77.180895},
{x:40.720387,y:-73.306419},
{x:40.751857,y:-74.229151},
{x:33.569162,y:-81.938627},
{x:41.852718,y:-87.659092},
{x:41.808602,y:-87.64673},
{x:26.151574,y:-81.795899},
{x:34.024299,y:-118.491685},
{x:39.900207,y:-83.811262},
{x:42.275702,y:-71.740119},
{x:40.349227,y:-75.269269},
{x:33.851059,y:-117.551316},
{x:41.31841,y:-72.22708},
{x:42.546281,y:-77.949454},
{x:40.383947,y:-75.275641},
{x:39.688131,y:-84.133514},
{x:38.789085,y:-90.6328},
{x:41.262207,y:-95.878025},
{x:27.939804,y:-82.729693},
{x:25.922155,y:-97.484668},
{x:26.557293,y:-81.889459},
{x:42.128658,y:-72.627163},
{x:40.063329,y:-83.054834},
{x:33.759537,y:-78.860027},
{x:25.447222,y:-80.475533},
{x:41.259027,y:-74.358039},
{x:40.894992,y:-74.173474},
{x:38.727646,y:-77.801756},
{x:45.633439,y:-89.389484},
{x:38.890791,y:-104.767797},
{x:28.694542,y:-81.32793},
{x:28.823395,y:-81.785776},
{x:30.145266,y:-81.519255},
{x:42.313575,y:-71.036067},
{x:42.320081,y:-87.83844},
{x:39.511652,y:-75.710929},
{x:32.606162,y:-97.114279},
{x:28.026286,y:-82.583451},
{x:39.89184,y:-75.505472},
{x:40.942218,y:-73.104716},
{x:40.789835,y:-77.034305},
{x:40.754068,y:-73.908336},
{x:41.689715,y:-72.925246},
{x:41.682673,y:-72.942124},
{x:38.702307,y:-77.220305},
{x:38.88073,y:-77.111367},
{x:33.595743,y:-117.67947},
{x:41.678208,y:-73.005436},
{x:32.362562,y:-84.963494},
{x:39.951209,y:-75.165912},
{x:40.007617,y:-75.174051},
{x:25.831687,y:-80.216349},
{x:40.770188,y:-74.281323},
{x:41.692093,y:-72.849119},
{x:39.658208,y:-79.961847},
{x:40.970748,y:-80.31443},
{x:27.263739,y:-82.525146},
{x:42.094179,y:-76.013852},
{x:43.156691,y:-77.601138},
{x:44.825093,y:-68.718641},
{x:44.712719,y:-69.788539},
{x:39.249797,y:-84.596312},
{x:40.820327,y:-73.8914},
{x:43.569121,y:-89.469989},
{x:43.187827,y:-89.229136},
{x:41.572523,y:-87.664728},
{x:42.003837,y:-88.0055},
{x:33.42879,y:-86.70593},
{x:36.982297,y:-76.433369},
{x:34.062667,y:-84.422265},
{x:41.807574,y:-88.076611},
{x:40.787562,y:-89.603184},
{x:43.055421,y:-76.092565},
{x:42.69789,y:-87.897755},
{x:40.766486,y:-74.250301},
{x:40.759168,y:-73.968526},
{x:41.954764,y:-73.761744},
{x:29.661078,y:-98.436352},
{x:42.535867,y:-83.203242},
{x:28.843957,y:-82.022079},
{x:26.882318,y:-80.104516},
{x:42.397205,y:-71.258889},
{x:32.303152,y:-95.316088},
{x:27.532536,y:-82.499783},
{x:34.083818,y:-84.655414},
{x:40.590511,y:-73.972707},
{x:40.595016,y:-74.670516},
{x:33.011774,y:-96.602584},
{x:44.951905,y:-72.160661},
{x:40.650634,y:-74.004132},
{x:39.58014,y:-105.051428},
{x:34.213414,y:-78.021397},
{x:34.638338,y:-79.00048},
{x:42.272947,y:-71.813556},
{x:42.681138,y:-84.432394},
{x:28.558582,y:-81.822211},
{x:30.169116,y:-97.796507},
{x:43.241357,y:-77.620987},
{x:34.23411,y:-79.8065},
{x:36.143883,y:-115.22217},
{x:34.089601,y:-117.670491},
{x:41.050096,y:-74.748228},
{x:42.015648,y:-71.481424},
{x:40.87257,y:-74.178114},
{x:27.210229,y:-80.327763},
{x:39.369574,y:-77.413656},
{x:36.904513,y:-76.138818},
{x:26.298339,y:-80.187242},
{x:35.322785,y:-80.64654},
{x:41.892384,y:-72.648912},
{x:34.23679,y:-77.946673},
{x:36.840282,y:-75.996141},
{x:43.416138,y:-70.986399},
{x:30.11053,y:-97.342654},
{x:38.869279,y:-77.145917},
{x:36.293722,y:-76.244851},
{x:28.591868,y:-81.751723},
{x:42.34949,y:-71.104237},
{x:42.350856,y:-71.108808},
{x:41.371881,y:-72.907678},
{x:28.72484,y:-82.553667},
{x:26.690331,y:-80.07951},
{x:43.656832,y:-70.27824},
{x:37.741596,y:-84.267986},
{x:41.64819,y:-70.422346},
{x:36.126825,y:-115.279379},
{x:40.759855,y:-73.800106},
{x:43.658532,y:-70.298213},
{x:42.429922,y:-87.939198},
{x:42.138067,y:-87.848005},
{x:30.29038,y:-81.602543},
{x:40.677877,y:-73.870576},
{x:41.786414,y:-88.072659},
{x:42.027665,y:-87.816004},
{x:27.680095,y:-97.373955},
{x:27.57494,y:-99.473928},
{x:41.498641,y:-74.071173},
{x:40.862358,y:-73.893752},
{x:26.488777,y:-80.12201},
{x:40.548538,y:-74.221809},
{x:27.210493,y:-81.845259},
{x:41.601688,y:-93.600771},
{x:40.590876,y:-74.32348},
{x:40.582188,y:-74.331527},
{x:40.548542,y:-105.077539},
{x:38.982174,y:-77.097872},
{x:40.366634,y:-80.105182},
{x:39.973819,y:-76.682029},
{x:42.522351,y:-83.048471},
{x:36.599938,y:-78.151273},
{x:43.11592,y:-76.260159},
{x:43.168161,y:-76.51104},
{x:43.197711,y:-77.585034},
{x:42.283501,y:-71.371867},
{x:33.147072,y:-96.805292},
{x:40.753783,y:-73.9741},
{x:40.822943,y:-74.060973},
{x:40.745945,y:-73.933956},
{x:29.60735,y:-98.418798},
{x:37.689031,y:-121.012862},
{x:41.412895,y:-82.666867},
{x:40.288989,y:-74.472475},
{x:28.537347,y:-81.208704},
{x:40.71068,y:-74.008981},
{x:43.119138,y:-77.4871},
{x:34.288377,y:-77.870448},
{x:26.13675,y:-80.175432},
{x:41.269287,y:-72.997924},
{x:39.136708,y:-77.691567},
{x:41.677342,y:-71.260177},
{x:41.984542,y:-71.327078},
{x:29.266234,y:-82.194012},
{x:40.271505,y:-75.128889},
{x:42.427485,y:-74.017661},
{x:40.724271,y:-73.560477},
{x:41.46614,y:-74.414254},
{x:41.159649,y:-80.664528},
{x:41.886686,y:-87.634131},
{x:43.162683,y:-76.095178},
{x:35.94829,y:-83.912594},
{x:41.590135,y:-73.908699},
{x:42.561787,y:-71.990037},
{x:40.708582,y:-73.958975},
{x:45.181112,y:-69.258701},
{x:33.999944,y:-84.14613},
{x:42.501548,y:-94.161591},
{x:41.78274,y:-72.59159},
{x:38.861059,y:-77.386526},
{x:38.640664,y:-77.300385},
{x:34.243307,y:-84.494814},
{x:41.973014,y:-71.896714},
{x:28.42767,y:-81.444111},
{x:26.55547,y:-81.88756},
{x:27.327657,y:-80.321934},
{x:35.658897,y:-80.46117},
{x:40.775675,y:-73.729707},
{x:38.429748,y:-78.851001},
{x:41.64158,y:-72.923206},
{x:28.835683,y:-82.33875},
{x:27.944378,y:-82.476488},
{x:34.055288,y:-84.174864},
{x:32.088597,y:-110.775779},
{x:41.892914,y:-71.387473},
{x:26.683874,y:-80.292289},
{x:40.872866,y:-88.665035},
{x:41.120381,y:-87.867184},
{x:41.672663,y:-88.124482},
{x:33.74491,y:-117.920494},
{x:28.146794,y:-82.354236},
{x:41.868533,y:-71.506404},
{x:28.704105,y:-81.284414},
{x:39.910101,y:-75.186746},
{x:40.399097,y:-80.550734},
{x:28.261251,y:-81.616768},
{x:44.531361,y:-70.45639},
{x:42.089331,y:-71.301439},
{x:42.819556,y:-71.412732},
{x:35.931213,y:-79.033643},
{x:44.541538,y:-69.626507},
{x:39.45867,y:-74.985047},
{x:40.83797,y:-73.887515},
{x:40.776021,y:-73.824176},
{x:38.253174,y:-85.757254},
{x:42.688468,y:-83.38417},
{x:28.346276,y:-81.644188},
{x:30.203234,y:-81.739998},
{x:42.344379,y:-71.044019},
{x:41.983066,y:-76.526466},
{x:41.921162,y:-88.280041},
{x:40.747612,y:-73.947535},
{x:41.559755,y:-73.941113},
{x:28.551673,y:-81.456921},
{x:40.696443,y:-73.743773},
{x:40.57252,y:-74.317889},
{x:42.648596,y:-73.755326},
{x:42.6482,y:-73.754449},
{x:39.786652,y:-77.714393},
{x:34.061162,y:-84.593211},
{x:40.142312,y:-75.3899},
{x:40.323643,y:-78.956706},
{x:39.045996,y:-77.112838},
{x:28.424163,y:-81.461339},
{x:28.332164,y:-81.53264},
{x:28.349807,y:-82.188016},
{x:36.176196,y:-94.162346},
{x:41.54553,y:-72.411912},
{x:42.009715,y:-88.080405},
{x:41.259864,y:-75.901836},
{x:43.004874,y:-78.20374},
{x:40.67037,y:-73.958054},
{x:40.702672,y:-73.854732},
{x:39.303545,y:-76.955417},
{x:38.296269,y:-76.635428},
{x:36.655955,y:-76.231646},
{x:41.160598,y:-73.258018},
{x:39.906147,y:-75.170243},
{x:39.901032,y:-75.164375},
{x:39.903891,y:-75.167384},
{x:42.362389,y:-73.429855},
{x:33.03823,y:-116.875205},
{x:28.775695,y:-81.24236},
{x:41.809539,y:-87.980346},
{x:42.080584,y:-72.614626},
{x:41.108995,y:-73.996478},
{x:40.746352,y:-74.10875},
{x:42.065364,y:-87.940147},
{x:28.305176,y:-81.461308},
{x:28.892215,y:-81.306636},
{x:26.231328,y:-80.121217},
{x:26.318927,y:-80.106946},
{x:41.335454,y:-73.984811},
{x:42.288381,y:-84.204842},
{x:40.133701,y:-75.205599},
{x:40.196113,y:-76.75583},
{x:42.594956,y:-71.349909},
{x:41.166798,y:-81.404714},
{x:26.332344,y:-80.15215},
{x:42.688735,y:-73.850018},
{x:40.863055,y:-79.895341},
{x:40.008652,y:-75.297092},
{x:39.877237,y:-88.954065},
{x:41.433562,y:-82.714185},
{x:35.267006,y:-81.149569},
{x:42.15878,y:-88.272469},
{x:40.355789,y:-80.049537},
{x:38.780106,y:-76.083478},
{x:40.057916,y:-76.306152},
{x:40.704423,y:-73.928809},
{x:37.348207,y:-79.181949},
{x:42.603799,y:-89.652616},
{x:41.864057,y:-71.449011},
{x:35.480873,y:-80.857271},
{x:28.655839,y:-81.240093},
{x:40.778491,y:-73.77675},
{x:27.937314,y:-82.227561},
{x:33.614587,y:-83.919806},
{x:37.678513,y:-97.389991},
{x:34.160017,y:-118.500837},
{x:42.709501,y:-73.817847},
{x:40.784214,y:-80.773293},
{x:40.885097,y:-74.127188},
{x:40.862816,y:-74.63366},
{x:40.014481,y:-74.312257},
{x:30.446342,y:-90.082661},
{x:40.71827,y:-73.840525},
{x:37.791231,y:-81.215749},
{x:41.28626,y:-72.881159},
{x:41.272407,y:-75.86007},
{x:40.152956,y:-105.096112},
{x:40.654,y:-73.869},
{x:26.189123,y:-98.205453},
{x:29.507241,y:-98.62937},
{x:28.39311,y:-81.465884},
{x:30.012583,y:-95.598931},
{x:29.839438,y:-95.489072},
{x:27.244006,y:-80.828314},
{x:38.848446,y:-77.042247},
{x:38.846852,y:-77.042693},
{x:38.916083,y:-92.333744},
{x:39.02926,y:-95.726946},
{x:28.50088,y:-81.58733},
{x:27.588248,y:-80.414842},
{x:33.594765,y:-117.245912},
{x:28.184715,y:-81.438603},
{x:40.6445,y:-74.211628},
{x:39.625827,y:-105.109415},
{x:26.499168,y:-80.089544},
{x:34.080805,y:-83.92258},
{x:39.851391,y:-74.199019},
{x:28.080893,y:-81.966487},
{x:38.784571,y:-76.794273},
{x:41.245047,y:-73.198119},
{x:42.114579,y:-71.848106},
{x:42.038562,y:-88.038617},
{x:39.098032,y:-76.75423},
{x:37.645417,y:-97.414334},
{x:40.30736,y:-73.999838},
{x:40.8993,y:-74.110116},
{x:40.417108,y:-75.343945},
{x:36.01978,y:-78.94842},
{x:40.643575,y:-73.790422},
{x:42.039208,y:-88.364733},
{x:33.778125,y:-117.915268},
{x:27.068212,y:-82.209269},
{x:30.176646,y:-85.760794},
{x:26.164446,y:-80.201409},
{x:40.798844,y:-73.966865},
{x:39.954552,y:-75.060467},
{x:40.740814,y:-73.901986},
{x:42.367803,y:-71.030516},
{x:27.79426,y:-82.335933},
{x:42.356754,y:-71.058844},
{x:42.753057,y:-71.106879},
{x:33.794473,y:-83.743816},
{x:40.831086,y:-73.941035},
{x:41.9251,y:-87.661505},
{x:39.81197,y:-74.984787},
{x:39.981646,y:-74.809465},
{x:34.041435,y:-84.481427},
{x:41.586488,y:-72.047257},
{x:42.783614,y:-73.743689},
{x:44.374525,y:-69.796865},
{x:36.036883,y:-86.787819},
{x:26.361855,y:-80.136711},
{x:26.136532,y:-80.147798},
{x:38.925514,y:-104.718889},
{x:28.493158,y:-81.49206},
{x:40.308321,y:-76.42436},
{x:25.477073,y:-80.452409},
{x:28.047968,y:-82.737742},
{x:37.630909,y:-77.670758},
{x:33.963363,y:-84.006671},
{x:42.17842,y:-72.68724},
{x:39.53539,y:-119.716096},
{x:32.82832,y:-97.098883},
{x:40.267701,y:-75.314928},
{x:42.403717,y:-71.166456},
{x:39.140928,y:-77.223035},
{x:40.998586,y:-74.304331},
{x:33.970815,y:-118.164625},
{x:37.041365,y:-76.38787},
{x:42.102398,y:-80.137657},
{x:46.80019,y:-92.135745},
{x:40.74576,y:-73.945413},
{x:40.753102,y:-96.605614},
{x:42.109493,y:-70.932669},
{x:42.222732,y:-83.486051},
{x:39.104054,y:-77.157032},
{x:26.244229,y:-80.173938},
{x:28.090736,y:-82.697589},
{x:38.623734,y:-90.333855},
{x:41.585485,y:-88.174523},
{x:29.888818,y:-97.94607},
{x:26.563293,y:-82.00988},
{x:40.590749,y:-75.629633},
{x:42.099178,y:-88.298577},
{x:42.008642,y:-87.94057},
{x:38.937967,y:-77.521645},
{x:35.375374,y:-80.732445},
{x:40.421327,y:-105.077372},
{x:40.777033,y:-74.011351},
{x:43.098237,y:-75.301718},
{x:41.70345,y:-72.794328},
{x:41.823152,y:-75.682511},
{x:41.607523,y:-87.793629},
{x:39.349828,y:-84.2636},
{x:35.18442,y:-101.883586},
{x:39.969492,y:-74.232047},
{x:41.876638,y:-88.066116},
{x:41.746531,y:-88.216274},
{x:40.884474,y:-73.900146},
{x:29.148932,y:-81.034659},
{x:28.486385,y:-81.309547},
{x:28.370488,y:-81.367941},
{x:34.117258,y:-84.076502},
{x:40.117996,y:-77.036479},
{x:42.054356,y:-87.799993},
{x:40.661045,y:-74.173179},
{x:29.829933,y:-81.376246},
{x:39.595437,y:-104.798458},
{x:37.256109,y:-79.95763},
{x:39.344874,y:-84.396079},
{x:41.0821,y:-74.591756},
{x:40.745154,y:-74.18179},
{x:42.554985,y:-71.440584},
{x:30.366147,y:-87.169955},
{x:41.664163,y:-74.700371},
{x:44.014556,y:-70.362441},
{x:40.784311,y:-77.844344},
{x:40.745767,y:-73.905879},
{x:33.888713,y:-117.827887},
{x:38.256657,y:-77.497699},
{x:33.556676,y:-117.176386},
{x:41.306225,y:-96.079396},
{x:39.203293,y:-94.548999},
{x:41.291488,y:-96.168167},
{x:37.645414,y:-97.414332},
{x:37.644494,y:-97.410808},
{x:33.968349,y:-84.195552},
{x:41.9057,y:-87.985805},
{x:41.885349,y:-87.652815},
{x:40.875733,y:-74.040369},
{x:39.547238,y:-77.914457},
{x:35.854694,y:-86.396403},
{x:40.706868,y:-73.304285},
{x:26.381999,y:-80.187076},
{x:41.562384,y:-74.179913},
{x:41.796369,y:-88.105583},
{x:42.902553,y:-77.420938},
{x:38.309311,y:-104.615813},
{x:41.316898,y:-74.170717},
{x:37.738069,y:-121.452546},
{x:40.181629,y:-80.232345},
{x:42.157034,y:-87.959699},
{x:25.97483,y:-80.295964},
{x:41.795188,y:-72.621593},
{x:38.759664,y:-121.310437},
{x:39.92591,y:-75.049906},
{x:39.942308,y:-74.995338},
{x:41.624519,y:-74.593852},
{x:41.896631,y:-87.706904},
{x:42.375741,y:-71.053448},
{x:41.204543,y:-75.790264},
{x:41.5745,y:-90.539912},
{x:41.632784,y:-91.570577},
{x:31.608406,y:-84.209007},
{x:46.702086,y:-92.103537},
{x:33.992971,y:-118.442318},
{x:30.263986,y:-85.972426},
{x:28.128667,y:-82.574497},
{x:40.665623,y:-73.956821},
{x:26.265425,y:-98.202404},
{x:41.852895,y:-72.665792},
{x:28.125226,y:-82.463409},
{x:37.780002,y:-92.119954},
{x:42.007805,y:-87.666052},
{x:42.528173,y:-83.380796},
{x:42.034138,y:-86.513615},
{x:38.830666,y:-76.613176},
{x:36.865188,y:-75.980688},
{x:39.284133,y:-76.506023},
{x:39.059902,y:-77.121683},
{x:39.437393,y:-77.529261},
{x:39.105154,y:-77.193664},
{x:40.868433,y:-74.117934},
{x:32.456385,y:-93.721577},
{x:34.781233,y:-86.93467},
{x:39.238468,y:-94.435773},
{x:38.937413,y:-77.024617},
{x:31.262632,y:-85.439929},
{x:38.803343,y:-77.063331},
{x:37.550253,y:-77.449528},
{x:42.088535,y:-75.915076},
{x:41.546295,y:-72.706657},
{x:40.26252,y:-80.174863},
{x:36.076974,y:-79.959417},
{x:35.450125,y:-79.134317},
{x:33.538103,y:-111.890293},
{x:42.252717,y:-87.901685},
{x:29.627255,y:-82.388148},
{x:41.80385,y:-72.243006},
{x:34.408003,y:-118.461717},
{x:28.60295,y:-81.552606},
{x:30.444024,y:-88.934374},
{x:39.629403,y:-106.069259},
{x:40.752113,y:-73.988752},
{x:34.283218,y:-81.597096},
{x:41.578615,y:-87.599298},
{x:41.939071,y:-87.702911},
{x:37.723715,y:-97.206742},
{x:40.224571,y:-74.172502},
{x:40.878214,y:-74.296307},
{x:34.04438,y:-118.328307},
{x:30.1868,y:-81.726503},
{x:40.140012,y:-75.546914},
{x:41.86755,y:-87.626624},
{x:41.396185,y:-74.333124},
{x:34.000452,y:-83.909064},
{x:36.483027,y:-82.500965},
{x:34.080677,y:-83.986081},
{x:40.736487,y:-73.612225},
{x:28.279984,y:-82.718272},
{x:40.594956,y:-75.497718},
{x:40.851367,y:-73.843998},
{x:30.388029,y:-97.648856},
{x:40.29415,y:-74.682372},
{x:33.680949,y:-117.777915},
{x:33.806609,y:-84.530696},
{x:41.744159,y:-70.586555},
{x:40.050325,y:-74.952249},
{x:41.53164,y:-87.636385},
{x:27.243983,y:-80.231257},
{x:33.475359,y:-81.994876},
{x:34.004953,y:-81.119061},
{x:39.839408,y:-75.493787},
{x:33.038815,y:-83.937442},
{x:41.8856,y:-87.634093},
{x:25.701464,y:-80.369467},
{x:30.754922,y:-85.550099},
{x:41.928769,y:-87.845656},
{x:42.749029,y:-71.669373},
{x:38.726522,y:-90.697066},
{x:34.122839,y:-93.081972},
{x:39.347837,y:-76.538879},
{x:38.549238,y:-76.983677},
{x:34.045146,y:-118.256666},
{x:41.520848,y:-88.219886},
{x:39.956356,y:-75.194232},
{x:40.671925,y:-73.856239},
{x:40.721884,y:-74.149974},
{x:40.28911,y:-74.877427},
{x:42.537507,y:-82.989254},
{x:43.476051,y:-70.511626},
{x:42.394987,y:-88.065941},
{x:38.893112,y:-77.426919},
{x:39.190054,y:-96.553119},
{x:28.553082,y:-80.849839},
{x:33.679958,y:-117.665049},
{x:28.565219,y:-81.31148},
{x:28.45047,y:-81.562667},
{x:28.341311,y:-81.415299},
{x:30.317411,y:-81.473806},
{x:41.496772,y:-90.404233},
{x:43.317247,y:-88.349626},
{x:35.395457,y:-80.616909},
{x:40.696412,y:-74.176023},
{x:27.10715,y:-82.383835},
{x:41.313784,y:-81.786579},
{x:40.734912,y:-74.028497},
{x:40.948944,y:-80.659198},
{x:41.997757,y:-72.581575},
{x:40.683726,y:-73.354187},
{x:40.821916,y:-81.935012},
{x:41.689738,y:-88.047556},
{x:35.37617,y:-97.529716},
{x:33.92098,y:-84.211928},
{x:42.441289,y:-83.374811},
{x:39.27553,y:-76.717821},
{x:34.173262,y:-84.188446},
{x:33.940914,y:-118.406876},
{x:44.307194,y:-69.959898},
{x:44.543526,y:-69.689251},
{x:40.397086,y:-86.856161},
{x:41.259426,y:-72.948246},
{x:32.251737,y:-110.841454},
{x:41.85139,y:-88.311537},
{x:41.681123,y:-88.203838},
{x:41.888265,y:-88.137561},
{x:37.938837,y:-77.465437},
{x:41.839143,y:-88.001838},
{x:42.156269,y:-88.334946},
{x:34.296468,y:-77.796212},
{x:33.98368,y:-81.257731},
{x:39.917316,y:-75.172542},
{x:42.186068,y:-88.422026},
{x:27.850528,y:-82.700482},
{x:38.842332,y:-77.108922},
{x:37.646353,y:-77.576091},
{x:36.884432,y:-76.182513},
{x:34.240816,y:-118.536425},
{x:28.277348,y:-81.41731},
{x:28.460847,y:-81.459324},
{x:28.5231,y:-81.330586},
{x:28.799043,y:-81.739052},
{x:43.030161,y:-76.004114},
{x:39.373822,y:-76.721992},
{x:26.714264,y:-80.094265},
{x:25.808928,y:-80.31571},
{x:44.135991,y:-70.467507},
{x:43.508197,y:-70.412657},
{x:40.558222,y:-75.487991},
{x:40.411596,y:-74.137299},
{x:40.92728,y:-74.95877},
{x:41.964341,y:-72.565695},
{x:42.498828,y:-83.305721},
{x:43.032583,y:-82.456744},
{x:40.318486,y:-74.50779},
{x:41.94175,y:-83.40411},
{x:42.197666,y:-71.060888},
{x:39.967478,y:-74.920639},
{x:33.910452,y:-84.266048},
{x:33.894239,y:-83.954878},
{x:42.559413,y:-72.042943},
{x:39.753423,y:-75.211731},
{x:36.85409,y:-89.583534},
{x:35.839076,y:-86.351191},
{x:40.731047,y:-73.993942},
{x:32.540621,y:-82.944673},
{x:38.786851,y:-77.448758},
{x:41.93676,y:-88.08077},
{x:39.915389,y:-74.825419},
{x:33.921094,y:-84.297273},
{x:42.600422,y:-72.594099},
{x:38.315024,y:-85.563681},
{x:29.436802,y:-98.497055},
{x:42.251015,y:-87.839607},
{x:38.678148,y:-121.195579},
{x:34.074895,y:-80.997209},
{x:38.750934,y:-77.570849},
{x:35.085673,y:-85.194801},
{x:40.934012,y:-73.897633},
{x:41.238399,y:-73.701508},
{x:38.291552,y:-77.524648},
{x:41.102199,y:-74.045155},
{x:42.962027,y:-72.306166},
{x:33.639452,y:-84.438928},
{x:29.659858,y:-98.498372},
{x:40.815526,y:-73.915903},
{x:42.110326,y:-77.155992},
{x:40.205535,y:-74.632596},
{x:40.326002,y:-80.06527},
{x:29.563075,y:-98.33206},
{x:32.808242,y:-116.965486},
{x:39.209849,y:-76.782831},
{x:30.658258,y:-87.851687},
{x:42.326611,y:-71.402712},
{x:42.245611,y:-71.281819},
{x:28.593485,y:-81.371556},
{x:36.055325,y:-115.168311},
{x:30.108531,y:-81.415523},
{x:28.246644,y:-81.267066},
{x:28.548966,y:-81.379399},
{x:37.649585,y:-122.40607},
{x:41.656968,y:-88.000705},
{x:28.655935,y:-81.222669},
{x:41.100979,y:-114.956073},
{x:32.885434,y:-80.03755},
{x:41.032484,y:-81.432106},
{x:26.54788,y:-81.807048},
{x:26.439225,y:-81.770777},
{x:44.116065,y:-71.183034},
{x:40.035916,y:-74.617702},
{x:38.749039,y:-77.082786},
{x:34.101884,y:-118.32505},
{x:33.814259,y:-117.821798},
{x:27.167007,y:-80.228369},
{x:27.923921,y:-82.323878},
{x:40.439889,y:-74.22645},
{x:34.872934,y:-83.966315},
{x:33.678808,y:-112.031485},
{x:42.033595,y:-91.604327},
{x:44.442552,y:-73.21058},
{x:38.200198,y:-85.684855},
{x:40.818813,y:-73.927464},
{x:37.355288,y:-77.411222},
{x:42.024866,y:-88.136651},
{x:26.144269,y:-80.316445},
{x:26.666151,y:-80.203552},
{x:34.257645,y:-84.076297},
{x:33.919693,y:-117.490049},
{x:30.115429,y:-81.597749},
{x:26.319814,y:-80.091202},
{x:40.833916,y:-73.940988},
{x:26.463606,y:-81.774619},
{x:32.531478,y:-84.956753},
{x:42.197654,y:-88.019579},
{x:33.490772,y:-82.136775},
{x:38.720094,y:-104.702339},
{x:35.790765,y:-80.855143},
{x:34.116177,y:-118.262442},
{x:41.307767,y:-80.731922},
{x:39.953669,y:-75.157788},
{x:40.278609,y:-74.005578},
{x:40.814704,y:-96.606561},
{x:41.76441,y:-89.689684},
{x:41.612652,y:-73.966834},
{x:33.549279,y:-101.904627},
{x:41.990234,y:-87.730669},
{x:41.575363,y:-87.239775},
{x:41.234547,y:-96.05217},
{x:33.37998,y:-111.927863},
{x:30.625954,y:-81.544515},
{x:26.136115,y:-80.180952},
{x:40.947207,y:-72.586644},
{x:43.796446,y:-91.220093},
{x:41.323969,y:-72.335977},
{x:41.578909,y:-87.461985},
{x:30.455918,y:-84.345413},
{x:30.190149,y:-85.680379},
{x:43.645406,y:-72.341769},
{x:39.949983,y:-86.339259},
{x:39.359731,y:-76.408556},
{x:40.878856,y:-74.152432},
{x:33.502404,y:-84.240727},
{x:41.53502,y:-87.791266},
{x:38.977687,y:-76.551709},
{x:40.805758,y:-73.940029},
{x:38.42309,y:-96.216355},
{x:34.591567,y:-86.979942},
{x:39.1691,y:-84.424891},
{x:41.719206,y:-71.899452},
{x:41.712833,y:-71.88083},
{x:43.155477,y:-75.744776},
{x:31.62876,y:-94.652683},
{x:40.610036,y:-73.918899},
{x:28.53944,y:-81.37872},
{x:40.440931,y:-75.336235},
{x:27.874146,y:-82.639378},
{x:27.895891,y:-82.787479},
{x:40.642652,y:-73.978937},
{x:32.678213,y:-117.08297},
{x:38.982465,y:-74.942724},
{x:30.179657,y:-82.679555},
{x:32.637765,y:-85.409127},
{x:35.146543,y:-89.795837},
{x:40.811344,y:-74.21403},
{x:42.117979,y:-76.822962},
{x:42.866475,y:-72.867701},
{x:28.314981,y:-81.403382},
{x:25.875674,y:-80.17017},
{x:26.63872,y:-80.263747},
{x:40.751445,y:-73.989248},
{x:37.072605,y:-88.698282},
{x:32.474229,y:-85.030702},
{x:28.552897,y:-81.366114},
{x:28.608592,y:-81.391781},
{x:43.128707,y:-75.578371},
{x:33.742885,y:-116.217035},
{x:41.70049,y:-73.967004},
{x:42.219628,y:-88.260489},
{x:36.005766,y:-83.978525},
{x:35.955453,y:-83.925796},
{x:35.95446,y:-83.92404},
{x:37.320448,y:-89.557806},
{x:42.286015,y:-89.028269},
{x:32.876775,y:-117.140162},
{x:33.066353,y:-112.045981},
{x:28.323711,y:-81.335622},
{x:26.492809,y:-80.164936},
{x:41.796779,y:-71.912222},
{x:26.100735,y:-80.134664},
{x:27.161916,y:-80.301459},
{x:42.5403,y:-75.508617},
{x:40.805783,y:-77.888658},
{x:27.913423,y:-82.347721},
{x:28.52082,y:-81.464601},
{x:26.706618,y:-80.079808},
{x:40.381405,y:-74.324655},
{x:29.495396,y:-98.326005},
{x:34.012615,y:-84.754108},
{x:33.723498,y:-84.168659},
{x:42.16115,y:-70.955697},
{x:35.108158,y:-106.515932},
{x:41.411007,y:-82.667444},
{x:40.902296,y:-73.791448},
{x:42.268575,y:-71.093986},
{x:40.351787,y:-76.135111},
{x:38.185169,y:-122.254537},
{x:41.623218,y:-88.447871},
{x:33.608656,y:-112.360561},
{x:33.711166,y:-117.818453},
{x:42.635191,y:-83.738336},
{x:41.159939,y:-75.356343},
{x:41.838359,y:-75.854691},
{x:33.684631,y:-117.190068},
{x:29.322134,y:-81.055156},
{x:29.212777,y:-82.196671},
{x:37.893844,y:-122.054286},
{x:34.236365,y:-111.322924},
{x:30.181067,y:-85.709015},
{x:32.517631,y:-86.212124},
{x:31.530577,y:-97.188323},
{x:44.39629,y:-73.219413},
{x:41.341994,y:-75.992415},
{x:42.168574,y:-71.509761},
{x:25.955149,y:-80.245338},
{x:37.620572,y:-77.61067},
{x:41.079077,y:-74.664513},
{x:30.516331,y:-86.465993},
{x:37.140838,y:-93.295764},
{x:40.81575,y:-96.702868},
{x:40.590985,y:-98.392069},
{x:34.067852,y:-117.972649},
{x:39.742784,y:-84.120711},
{x:28.330706,y:-82.660239},
{x:28.193079,y:-82.595752},
{x:42.084944,y:-75.972027},
{x:38.887156,y:-77.005517},
{x:39.536643,y:-104.767248},
{x:41.454912,y:-81.988703},
{x:40.495869,y:-80.06755},
{x:42.469248,y:-73.815916},
{x:38.881123,y:-77.301547},
{x:41.677603,y:-87.719356},
{x:41.209327,y:-96.124034},
{x:35.278073,y:-97.601447},
{x:42.464207,y:-71.240626},
{x:41.99795,y:-88.3154},
{x:32.701833,y:-117.183778},
{x:40.697816,y:-74.217524},
{x:41.706979,y:-87.559381},
{x:40.705299,y:-89.447961},
{x:40.486531,y:-74.483179},
{x:38.829371,y:-77.118022},
{x:39.150857,y:-77.067126},
{x:39.602177,y:-74.842367},
{x:38.958525,y:-76.919979},
{x:40.064147,y:-75.140166},
{x:39.951528,y:-75.155937},
{x:40.851828,y:-81.448291},
{x:41.060177,y:-81.453647},
{x:32.431667,y:-94.863788},
{x:38.346855,y:-85.798405},
{x:41.421837,y:-87.239283},
{x:40.948695,y:-74.138399},
{x:41.934459,y:-89.057222},
{x:29.199693,y:-81.047599},
{x:39.66655,y:-104.987126},
{x:40.785427,y:-72.864226},
{x:40.798937,y:-73.17407},
{x:33.459036,y:-112.202976},
{x:41.716628,y:-88.286272},
{x:41.77135,y:-72.67473},
{x:41.471632,y:-87.294215},
{x:41.899306,y:-72.786807},
{x:41.777846,y:-87.766296},
{x:40.750668,y:-73.993694},
{x:40.750379,y:-73.993056},
{x:40.778117,y:-73.981225},
{x:40.719755,y:-74.001997},
{x:42.238258,y:-88.817217},
{x:27.513516,y:-81.496834},
{x:37.289085,y:-80.087414},
{x:42.476825,y:-71.450001},
{x:42.171018,y:-77.106921},
{x:42.336818,y:-88.274924},
{x:42.3599,y:-87.835164},
{x:40.863433,y:-74.091726},
{x:37.544618,y:-77.367745},
{x:37.449956,y:-77.64934},
{x:43.398943,y:-73.62194},
{x:41.021986,y:-74.20682},
{x:32.89298,y:-97.037619},
{x:39.209913,y:-94.645349},
{x:37.982844,y:-78.20808},
{x:40.868194,y:-74.414578},
{x:40.583546,y:-74.168532},
{x:38.995345,y:-104.811173},
{x:28.103631,y:-80.571197},
{x:29.96265,y:-81.488836},
{x:30.264379,y:-82.107794},
{x:28.195331,y:-82.520877},
{x:28.476662,y:-81.629287},
{x:36.107063,y:-115.152191},
{x:25.912735,y:-80.309627},
{x:34.317314,y:-83.830866},
{x:35.972444,y:-83.987806},
{x:35.890222,y:-84.548347},
{x:36.929864,y:-76.317427},
{x:41.816496,y:-88.168438},
{x:42.242051,y:-85.677992},
{x:40.750553,y:-73.617906},
{x:34.050724,y:-86.872547},
{x:33.462129,y:-84.203095},
{x:34.156108,y:-118.794431},
{x:41.6628,y:-71.5439},
{x:34.920898,y:-79.74764},
{x:39.202511,y:-76.683156},
{x:44.883785,y:-93.211356},
{x:28.226933,y:-81.647105},
{x:39.127975,y:-84.514984},
{x:40.751795,y:-84.147391},
{x:38.52797,y:-75.05635},
{x:38.3335,y:-75.21186},
{x:35.06094,y:-80.961168},
{x:33.85635,y:-84.38291},
{x:34.121414,y:-83.827433},
{x:39.28695,y:-76.7632},
{x:38.914818,y:-77.48621},
{x:39.544308,y:-76.83786},
{x:39.314191,y:-76.598265},
{x:38.920925,y:-76.99429},
{x:30.206474,y:-95.4636},
{x:30.038506,y:-95.49157},
{x:40.919693,y:-74.58697},
{x:33.777276,y:-79.02094},
{x:33.989204,y:-83.72113},
{x:26.588156,y:-80.11273},
{x:41.886106,y:-87.632147},
{x:41.085373,y:-80.73272},
{x:40.613733,y:-79.947568},
{x:44.805775,y:-68.90299},
{x:40.283943,y:-80.12146},
{x:40.582367,y:-73.98635},
{x:44.785877,y:-69.39943},
{x:41.047863,y:-75.313},
{x:42.37425,y:-72.70251},
{x:30.386705,y:-86.471886},
{x:30.189322,y:-85.65315},
{x:41.994466,y:-87.659054},
{x:29.835066,y:-94.53949},
{x:28.450247,y:-81.38273},
{x:28.389816,y:-81.24485},
{x:36.286472,y:-82.17364},
{x:42.736505,y:-87.837923},
{x:30.521816,y:-97.83101},
{x:39.290028,y:-76.614914},
{x:45.03317,y:-93.378912},
{x:32.88,y:-111.73048},
{x:40.05115,y:-74.84027},
{x:42.116653,y:-70.874306},
{x:38.750343,y:-90.42482},
{x:43.399605,y:-73.26618},
{x:37.344252,y:-85.340841},
{x:27.950193,y:-80.658348},
{x:29.697968,y:-98.0902},
{x:43.073135,y:-76.08887},
{x:39.055282,y:-76.524764},
{x:33.795235,y:-84.28699},
{x:40.78154,y:-73.97923},
{x:43.03631,y:-89.406626},
{x:40.90875,y:-74.17127},
{x:41.524635,y:-87.50872},
{x:27.193176,y:-82.48625},
{x:39.49735,y:-76.38905},
{x:26.153653,y:-81.765651},
{x:40.765087,y:-73.174385},
{x:42.769688,y:-71.48661},
{x:34.201391,y:-118.449037},
{x:41.87772,y:-72.97869},
{x:42.06801,y:-72.67841},
{x:30.440334,y:-84.31693},
{x:35.317584,y:-106.542868},
{x:25.76497,y:-80.19567},
{x:36.325734,y:-119.672434},
{x:42.298393,y:-71.43517},
{x:37.303292,y:-79.892684},
{x:35.162433,y:-101.937654},
{x:42.302616,y:-71.36093},
{x:42.869972,y:-71.9516},
{x:38.7456,y:-104.801509},
{x:42.157013,y:-76.85423},
{x:42.226044,y:-89.07251},
{x:35.06518,y:-78.96828},
{x:41.773505,y:-88.443458},
{x:40.79285,y:-73.94402},
{x:41.929012,y:-88.748914},
{x:30.427806,y:-84.226401},
{x:42.060165,y:-70.69121},
{x:33.631233,y:-79.03375},
{x:40.394836,y:-74.56168},
{x:34.18244,y:-118.30485},
{x:28.755842,y:-81.33606},
{x:27.68822,y:-80.41262},
{x:39.71773,y:-74.90051},
{x:28.328285,y:-80.61067},
{x:43.414547,y:-73.48957},
{x:38.90021,y:-76.794716},
{x:43.102495,y:-76.205518},
{x:41.46776,y:-73.487305},
{x:41.939073,y:-88.125855},
{x:42.000076,y:-87.883879},
{x:39.17167,y:-76.63189},
{x:32.134575,y:-81.25358},
{x:27.060381,y:-80.136416},
{x:28.872444,y:-82.08767},
{x:40.073536,y:-74.077415},
{x:40.77655,y:-74.59808},
{x:41.517097,y:-74.0202},
{x:40.624669,y:-89.465003},
{x:34.048405,y:-117.907646},
{x:29.725819,y:-94.91835},
{x:33.578945,y:-84.274414},
{x:34.237401,y:-84.454048},
{x:40.644558,y:-74.09562},
{x:36.110348,y:-115.15408},
{x:40.826786,y:-73.85831},
{x:39.472725,y:-80.15348},
{x:29.93979,y:-82.11171},
{x:41.763287,y:-71.09547},
{x:37.510117,y:-77.19087},
{x:37.409798,y:-79.21341},
{x:42.2748,y:-89.63084},
{x:44.201515,y:-72.507286},
{x:38.809402,y:-77.6105},
{x:42.171776,y:-72.61415},
{x:29.45642,y:-98.635956},
{x:29.780756,y:-98.73258},
{x:33.77883,y:-118.27927},
{x:39.877983,y:-75.239296},
{x:39.953625,y:-75.17523},
{x:33.61717,y:-117.7054},
{x:32.02934,y:-81.11341},
{x:43.188618,y:-70.88685},
{x:32.216072,y:-98.2179},
{x:35.9715,y:-87.12913},
{x:37.68237,y:-97.136},
{x:31.818098,y:-106.51619},
{x:27.782864,y:-82.717255},
{x:39.849667,y:-75.03166},
{x:40.825134,y:-73.95098},
{x:26.613668,y:-81.7971},
{x:36.190384,y:-86.295906},
{x:41.901855,y:-71.45077},
{x:28.230356,y:-82.210884},
{x:33.727755,y:-84.940624},
{x:40.856358,y:-73.85608},
{x:33.90292,y:-84.67319},
{x:45.0139,y:-93.10653},
{x:45.218807,y:-93.30699},
{x:40.70041,y:-73.80762},
{x:42.79604,y:-77.794205},
{x:39.910194,y:-74.941195},
{x:39.043053,y:-83.91956},
{x:41.983706,y:-87.669179},
{x:30.50878,y:-87.221855},
{x:35.599915,y:-82.62274},
{x:36.64065,y:-93.24209},
{x:40.222614,y:-80.20809},
{x:35.374233,y:-77.94507},
{x:41.881853,y:-87.631225},
{x:34.14108,y:-118.132515},
{x:42.804035,y:-73.90316},
{x:26.631086,y:-80.14576},
{x:27.848137,y:-82.352234},
{x:30.533398,y:-87.26172},
{x:41.77421,y:-87.703274},
{x:34.934762,y:-120.435841},
{x:28.43449,y:-82.54346},
{x:42.057088,y:-88.004399},
{x:42.329263,y:-88.200866},
{x:40.044201,y:-86.140733},
{x:36.017886,y:-86.565971},
{x:33.401337,y:-104.52317},
{x:28.054028,y:-82.43751},
{x:42.587097,y:-76.36707},
{x:41.512684,y:-87.961354},
{x:27.493168,y:-82.708046},
{x:39.823135,y:-75.86588},
{x:27.755203,y:-82.63873},
{x:38.34082,y:-77.07243},
{x:39.35525,y:-74.434494},
{x:35.36896,y:-119.145671},
{x:40.7461,y:-73.89587},
{x:34.989227,y:-85.209678},
{x:39.92464,y:-74.949936},
{x:40.189406,y:-74.650994},
{x:41.957034,y:-88.070235},
{x:43.026616,y:-91.125702},
{x:44.576763,y:-73.51403},
{x:28.618029,y:-80.84879},
{x:43.614956,y:-70.31104},
{x:44.21148,y:-70.52984},
{x:29.688362,y:-82.4291},
{x:40.575443,y:-74.39099},
{x:39.44048,y:-87.413246},
{x:41.913397,y:-70.55315},
{x:41.62432,y:-70.5511},
{x:30.07828,y:-94.16913},
{x:39.141582,y:-121.63224},
{x:43.784718,y:-88.473495},
{x:42.851772,y:-71.21607},
{x:40.544046,y:-74.164735},
{x:39.765434,y:-89.647974},
{x:40.72995,y:-73.95433},
{x:28.237513,y:-82.34728},
{x:28.280964,y:-81.34721},
{x:40.755047,y:-73.78223},
{x:39.309067,y:-76.75349},
{x:44.198158,y:-70.243813},
{x:39.00809,y:-104.88592},
{x:42.700153,y:-76.42057},
{x:41.688663,y:-86.003877},
{x:28.078413,y:-80.70212},
{x:40.737637,y:-73.99614},
{x:39.158417,y:-75.54737},
{x:30.20413,y:-97.98102},
{x:39.94101,y:-75.024185},
{x:40.737921,y:-74.170986},
{x:28.586561,y:-80.8023},
{x:40.913742,y:-73.90892},
{x:41.085729,y:-74.263186},
{x:33.885857,y:-84.24849},
{x:33.45254,y:-94.131805},
{x:33.193653,y:-117.35675},
{x:34.260468,y:-118.46696},
{x:42.552067,y:-71.845474},
{x:41.526733,y:-74.129166},
{x:27.748064,y:-82.70956},
{x:27.942682,y:-82.451645},
{x:40.898647,y:-81.42226},
{x:40.883071,y:-77.780798},
{x:41.20211,y:-80.45595},
{x:37.107533,y:-84.085754},
{x:26.665869,y:-80.671974},
{x:35.51134,y:-86.45636},
{x:40.087738,y:-75.39437},
{x:37.534275,y:-122.000694},
{x:41.156948,y:-80.08772},
{x:40.752914,y:-73.932945},
{x:34.469738,y:-84.45993},
{x:41.385307,y:-81.97611},
{x:40.284707,y:-73.984228},
{x:42.545775,y:-83.263246},
{x:35.334045,y:-97.495964},
{x:41.747384,y:-87.81796},
{x:41.591046,y:-88.056333},
{x:35.570908,y:-97.56744},
{x:37.283787,y:-76.71945},
{x:35.30964,y:-80.74784},
{x:42.1764,y:-72.58035},
{x:42.24652,y:-71.83526},
{x:28.453543,y:-82.629585},
{x:34.476161,y:-114.346983},
{x:35.327348,y:-80.94286},
{x:27.565191,y:-80.370766},
{x:41.568825,y:-70.59523},
{x:42.506714,y:-71.693252},
{x:28.260633,y:-82.47458},
{x:27.951908,y:-82.505035},
{x:38.748066,y:-77.14408},
{x:42.115913,y:-72.52617},
{x:38.87143,y:-77.10208},
{x:42.36256,y:-71.153114},
{x:40.771023,y:-73.953224},
{x:32.6929,y:-97.51832},
{x:38.949293,y:-77.080566},
{x:38.406025,y:-78.907455},
{x:40.354416,y:-75.95153},
{x:40.673023,y:-73.7702},
{x:27.834642,y:-82.328055},
{x:40.484093,y:-75.843185},
{x:36.715256,y:-76.18601},
{x:38.53473,y:-76.584145},
{x:21.3348,y:-157.91464},
{x:42.359653,y:-71.059296},
{x:35.63801,y:-87.01589},
{x:40.883542,y:-74.243761},
{x:43.654247,y:-70.27948},
{x:27.91409,y:-82.80355},
{x:26.64613,y:-81.98676},
{x:40.68442,y:-99.08624},
{x:39.891846,y:-75.28346},
{x:33.73326,y:-78.94761},
{x:41.750159,y:-71.22448},
{x:29.575534,y:-98.544589},
{x:41.831875,y:-88.104282},
{x:43.002346,y:-76.12908},
{x:40.445587,y:-75.9584},
{x:39.278008,y:-76.622696},
{x:40.640099,y:-74.401776},
{x:39.77991,y:-76.96268},
{x:37.27194,y:-79.88705},
{x:43.04891,y:-73.85091},
{x:39.302197,y:-84.48472},
{x:34.044323,y:-81.121994},
{x:40.528422,y:-74.235091},
{x:40.073086,y:-74.219182},
{x:41.33766,y:-75.72862},
{x:41.551135,y:-87.27163},
{x:40.018085,y:-75.67245},
{x:40.591747,y:-73.99901},
{x:40.650908,y:-73.87403},
{x:33.951671,y:-84.57512},
{x:40.75626,y:-73.83698},
{x:40.783433,y:-73.83077},
{x:40.747127,y:-73.42812},
{x:40.758184,y:-74.15945},
{x:40.715692,y:-74.034113},
{x:37.312062,y:-121.949697},
{x:26.025219,y:-80.24767},
{x:34.109047,y:-84.217354},
{x:41.178696,y:-96.10259},
{x:39.103047,y:-84.530248},
{x:34.078879,y:-117.435772},
{x:28.019651,y:-82.62168},
{x:39.029568,y:-76.07719},
{x:34.675306,y:-118.148267},
{x:27.893364,y:-82.68735},
{x:39.16276,y:-122.91957},
{x:32.729504,y:-117.22551},
{x:33.79137,y:-84.40654},
{x:40.716454,y:-74.03586},
{x:40.459656,y:-78.40882},
{x:26.50475,y:-80.06983},
{x:26.668365,y:-81.82726},
{x:41.962734,y:-91.71642},
{x:40.754883,y:-73.50929},
{x:43.56059,y:-76.12253},
{x:40.63844,y:-73.94867},
{x:35.516382,y:-78.554038},
{x:40.335747,y:-75.924446},
{x:41.606495,y:-88.241854},
{x:39.48756,y:-74.46185},
{x:40.92153,y:-75.09583},
{x:37.364796,y:-81.092834},
{x:39.33785,y:-74.482346},
{x:40.35672,y:-80.120155},
{x:42.766973,y:-71.21495},
{x:42.424402,y:-71.00541},
{x:42.66569,y:-71.58513},
{x:40.78775,y:-72.94906},
{x:39.181453,y:-76.81812},
{x:34.013858,y:-84.614796},
{x:40.660828,y:-73.72155},
{x:40.918622,y:-74.07094},
{x:40.813069,y:-74.01624},
{x:41.933679,y:-88.042599},
{x:29.213321,y:-81.099009},
{x:40.722597,y:-74.03867},
{x:40.733539,y:-73.60859},
{x:40.904174,y:-73.07656},
{x:40.72655,y:-73.50083},
{x:40.099389,y:-75.0245},
{x:40.821639,y:-73.92988},
{x:30.44038,y:-87.2827},
{x:42.70119,y:-71.249504},
{x:46.86355,y:-68.0045},
{x:30.122875,y:-95.40824},
{x:41.560932,y:-85.795607},
{x:40.429207,y:-74.107374},
{x:28.569277,y:-81.23618},
{x:27.96777,y:-82.76467},
{x:34.81967,y:-82.415436},
{x:33.87225,y:-118.24826},
{x:40.75104,y:-81.51611},
{x:40.537689,y:-74.588743},
{x:35.158978,y:-106.681114},
{x:38.75418,y:-104.78715},
{x:41.204433,y:-73.096436},
{x:32.951218,y:-80.16401},
{x:40.75986,y:-73.98745},
{x:41.58531,y:-87.55936},
{x:42.605937,y:-83.342325},
{x:40.245704,y:-75.244045},
{x:28.060125,y:-80.62096},
{x:40.947243,y:-72.925415},
{x:40.74968,y:-73.86988},
{x:40.416927,y:-86.812262},
{x:27.88373,y:-80.503586},
{x:41.26708,y:-82.61057},
{x:27.996702,y:-82.37369},
{x:40.558567,y:-90.036095},
{x:40.15808,y:-89.41463},
{x:42.031628,y:-71.30517},
{x:33.916786,y:-83.443344},
{x:35.963268,y:-86.49596},
{x:42.673088,y:-73.75014},
{x:40.074947,y:-74.110176},
{x:39.934944,y:-91.373146},
{x:40.555965,y:-74.29683},
{x:41.548412,y:-72.8919},
{x:30.29387,y:-81.77757},
{x:32.84691,y:-85.183876},
{x:31.33024,y:-85.83982},
{x:30.70068,y:-86.122894},
{x:35.755776,y:-83.962758},
{x:39.440186,y:-84.332115},
{x:40.44646,y:-79.71435},
{x:44.166607,y:-93.96034},
{x:33.64081,y:-112.06062},
{x:40.79875,y:-73.94124},
{x:37.597289,y:-77.607458},
{x:40.7437,y:-74.16926},
{x:36.039136,y:-78.898548},
{x:40.989822,y:-74.3302},
{x:44.63704,y:-70.000226},
{x:42.379767,y:-71.02669},
{x:40.586935,y:-73.812115},
{x:32.997124,y:-85.03111},
{x:41.805221,y:-73.114986},
{x:41.763217,y:-72.646868},
{x:39.133766,y:-84.488266},
{x:35.44349,y:-97.3714},
{x:40.7455,y:-73.90307},
{x:42.058786,y:-88.266397},
{x:42.328358,y:-71.086926},
{x:41.891453,y:-87.776185},
{x:44.802841,y:-91.469104},
{x:28.355298,y:-80.734163},
{x:26.753759,y:-80.072689},
{x:40.567744,y:-85.675332},
{x:39.97438,y:-74.73569},
{x:42.340639,y:-89.052903},
{x:42.3677,y:-71.0182},
{x:28.518217,y:-81.726838},
{x:41.461334,y:-74.022369},
{x:43.664055,y:-70.261009},
{x:40.736088,y:-73.994011},
{x:37.25265,y:-121.83113},
{x:38.983976,y:-76.729658},
{x:41.101169,y:-81.242935},
{x:38.2817,y:-77.453302},
{x:41.675995,y:-87.814653},
{x:40.666632,y:-73.87164},
{x:44.884141,y:-93.308254},
{x:29.225754,y:-81.09119},
{x:27.807326,y:-82.700302},
{x:33.491021,y:-82.199896},
{x:27.871984,y:-82.750206},
{x:39.312823,y:-76.46531},
{x:42.049277,y:-71.888408},
{x:32.715237,y:-117.16233},
{x:40.276366,y:-74.073237},
{x:42.674154,y:-82.99542},
{x:30.082632,y:-95.448783},
{x:28.394232,y:-80.755516},
{x:34.593611,y:-83.763411},
{x:38.057116,y:-81.104181},
{x:36.044615,y:-86.661372},
{x:32.432125,y:-99.75755},
{x:40.736315,y:-73.877447},
{x:38.832025,y:-77.30619},
{x:34.106213,y:-117.543583},
{x:40.715104,y:-73.748821},
{x:42.821902,y:-73.935543},
{x:30.531479,y:-87.313519},
{x:35.458973,y:-86.057057},
{x:39.283539,y:-76.748093},
{x:38.254609,y:-122.636133},
{x:34.172511,y:-84.548997},
{x:34.470825,y:-117.334625},
{x:27.987197,y:-82.775085},
{x:42.106182,y:-72.594347},
{x:44.911304,y:-91.933174},
{x:44.833177,y:-93.168654},
{x:44.909225,y:-93.080294},
{x:41.752784,y:-91.608459},
{x:41.65806,y:-93.73686},
{x:41.644574,y:-93.498495},
{x:45.107664,y:-87.618101},
{x:41.493738,y:-90.561868},
{x:42.706704,y:-73.679211},
{x:28.028547,y:-82.155539},
{x:42.396152,y:-88.184296},
{x:40.669677,y:-75.385237},
{x:45.128944,y:-93.423488},
{x:28.545007,y:-81.207488},
{x:42.723611,y:-71.3184},
{x:33.874479,y:-118.124938},
{x:38.332308,y:-77.478217},
{x:27.900044,y:-82.35591},
{x:27.462399,y:-82.595539},
{x:40.899351,y:-73.329611},
{x:42.355199,y:-71.063199},
{x:40.388081,y:-75.935245},
{x:40.673278,y:-80.579001},
{x:33.830158,y:-118.143041},
{x:32.120275,y:-110.937724},
{x:43.249644,y:-83.777899},
{x:26.453269,y:-80.186283},
{x:41.597489,y:-87.1733},
{x:42.103099,y:-88.36264},
{x:30.688998,y:-88.174284},
{x:30.391973,y:-88.887837},
{x:41.291645,y:-73.916877},
{x:32.708699,y:-96.99967},
{x:40.576673,y:-74.507228},
{x:33.449139,y:-112.391445},
{x:33.672833,y:-95.519981},
{x:40.687914,y:-74.312912},
{x:40.073699,y:-75.536403},
{x:36.794325,y:-75.99578},
{x:34.786633,y:-82.479689},
{x:39.743034,y:-77.549496},
{x:40.096826,y:-74.144029},
{x:38.790388,y:-77.07658},
{x:31.782713,y:-85.951889},
{x:39.600689,y:-77.728874},
{x:33.299409,y:-117.346685},
{x:38.291014,y:-77.560724},
{x:38.235158,y:-77.501281},
{x:40.459179,y:-90.683248},
{x:42.344753,y:-88.070059},
{x:33.574445,y:-83.839424},
{x:41.664973,y:-88.204642},
{x:35.25656,y:-79.03895},
{x:38.990638,y:-74.802511},
{x:34.585971,y:-112.340825},
{x:21.329063,y:-158.09027},
{x:37.700079,y:-77.887883},
{x:35.594555,y:-80.818464},
{x:36.062327,y:-95.814953},
{x:36.277397,y:-86.795877},
{x:40.880475,y:-73.425545},
{x:33.942685,y:-118.399882},
{x:35.155007,y:-80.794976},
{x:43.271004,y:-89.72647},
{x:42.524818,y:-88.984354},
{x:39.651489,y:-86.083113},
{x:39.335602,y:-76.454763},
{x:42.294218,y:-73.992249},
{x:30.498855,y:-88.333381},
{x:39.438319,y:-77.409981},
{x:25.960548,y:-80.187936},
{x:36.774331,y:-90.419634},
{x:25.863172,y:-80.193272},
{x:25.67167,y:-80.432104},
{x:25.727837,y:-80.441187},
{x:40.644861,y:-73.929275},
{x:42.213049,y:-72.620599},
{x:35.493447,y:-97.518612},
{x:27.880075,y:-82.819111},
{x:44.861026,y:-93.250668},
{x:42.383651,y:-87.944796},
{x:41.762804,y:-88.20655},
{x:43.059464,y:-77.64495},
{x:41.431183,y:-73.414872},
{x:42.247099,y:-70.977295},
{x:38.648212,y:-78.673224},
{x:27.638302,y:-80.438955},
{x:33.86072,y:-98.53571},
{x:44.316507,y:-93.963319},
{x:43.225219,y:-77.89004},
{x:41.502977,y:-81.689468},
{x:26.736697,y:-80.119563},
{x:42.34245,y:-72.589355},
{x:26.555859,y:-81.74935},
{x:37.495175,y:-77.553184},
{x:36.769484,y:-76.221575},
{x:40.648502,y:-73.782414},
{x:40.649336,y:-73.782709},
{x:40.558395,y:-74.42737},
{x:40.780419,y:-72.848231},
{x:40.719969,y:-74.125887},
{x:41.225842,y:-74.285763},
{x:41.000032,y:-73.679786},
{x:35.226674,y:-114.036755},
{x:35.259254,y:-87.324282},
{x:44.734021,y:-92.852102},
{x:42.43009,y:-71.589069},
{x:40.575377,y:-74.288704},
{x:29.659365,y:-82.423579},
{x:39.391564,y:-76.488282},
{x:44.375671,y:-89.816811},
{x:38.856,y:-77.043616},
{x:40.809177,y:-74.069491},
{x:44.488601,y:-87.972087},
{x:38.919857,y:-75.439813},
{x:44.320176,y:-69.766142},
{x:33.377503,y:-112.100706},
{x:39.29866,y:-76.589428},
{x:33.246147,y:-111.788921},
{x:34.955521,y:-80.758081},
{x:42.918865,y:-89.25064},
{x:39.697628,y:-75.708951},
{x:34.721556,y:-112.003047},
{x:28.36042,y:-80.791426},
{x:41.606665,y:-73.865584},
{x:41.443625,y:-88.146115},
{x:41.85116,y:-87.617522},
{x:36.325637,y:-82.835481},
{x:29.814676,y:-81.321746},
{x:37.628525,y:-77.458084},
{x:43.413716,y:-70.867223},
{x:33.902095,y:-118.351769},
{x:29.164355,y:-80.998168},
{x:26.967413,y:-80.086737},
{x:41.775461,y:-87.741809},
{x:41.626035,y:-87.738417},
{x:41.719763,y:-87.7467},
{x:42.042775,y:-71.862552},
{x:38.389647,y:-75.065075},
{x:41.101344,y:-74.023955},
{x:35.421964,y:-80.675893},
{x:40.720126,y:-74.560963},
{x:39.055909,y:-104.852845},
{x:38.661616,y:-78.492449},
{x:26.608196,y:-80.057832},
{x:32.26888,y:-81.081065},
{x:40.400648,y:-75.860167},
{x:36.714058,y:-76.668632},
{x:40.420432,y:-79.850465},
{x:39.26242,y:-84.793296},
{x:44.411485,y:-70.794207},
{x:39.980872,y:-75.357165},
{x:32.925806,y:-96.994124},
{x:39.175464,y:-76.741004},
{x:39.724106,y:-104.783937},
{x:40.646031,y:-82.543215},
{x:40.965463,y:-91.551585},
{x:39.334339,y:-77.636682},
{x:25.87934,y:-80.195563},
{x:41.992935,y:-70.847959},
{x:40.809852,y:-73.958775},
{x:33.886842,y:-117.338576},
{x:29.681574,y:-95.423766},
{x:41.07485,y:-73.818073},
{x:29.803637,y:-95.398996},
{x:40.495974,y:-80.256154},
{x:40.496231,y:-80.245404},
{x:44.337215,y:-75.462879},
{x:35.529089,y:-80.864493},
{x:42.942846,y:-88.863476},
{x:35.740753,y:-86.893904},
{x:41.144486,y:-104.859265},
{x:30.255413,y:-94.19845},
{x:33.563227,y:-83.478069},
{x:35.866105,y:-86.450011},
{x:39.981087,y:-76.80985},
{x:34.745988,y:-87.667387},
{x:41.548737,y:-73.01558},
{x:30.101788,y:-81.520521},
{x:30.725369,y:-85.184903},
{x:40.736805,y:-73.931981},
{x:38.981723,y:-92.326705},
{x:38.690853,y:-90.386981},
{x:38.059229,y:-84.464754},
{x:41.065522,y:-80.710599},
{x:42.394853,y:-71.06936},
{x:40.277143,y:-75.48091},
{x:40.750002,y:-73.939773},
{x:35.246565,y:-82.722657},
{x:40.739209,y:-74.030581},
{x:35.101722,y:-77.099793},
{x:42.120216,y:-72.60814},
{x:38.681419,y:-84.596157},
{x:37.963967,y:-121.989257},
{x:39.445406,y:-76.812655},
{x:38.990067,y:-76.854544},
{x:29.645049,y:-98.621166},
{x:42.122157,y:-72.584075},
{x:34.046181,y:-118.030719},
{x:39.442963,y:-75.742192},
{x:40.311042,y:-74.663329},
{x:30.224155,y:-95.54772},
{x:25.845233,y:-80.252865},
{x:39.40109,y:-76.952266},
{x:39.101491,y:-76.846677},
{x:32.678238,y:-97.196575},
{x:39.424584,y:-76.807215},
{x:33.544498,y:-111.890168},
{x:42.240155,y:-88.58799},
{x:41.758402,y:-87.615119},
{x:40.465615,y:-74.313306},
{x:43.150475,y:-88.004946},
{x:39.007797,y:-84.663361},
{x:43.090047,y:-88.011972},
{x:42.669814,y:-83.234736},
{x:41.850906,y:-87.724516},
{x:35.220905,y:-80.810395},
{x:34.42586,y:-118.538832},
{x:32.802011,y:-97.032507},
{x:35.915697,y:-81.520617},
{x:39.542262,y:-84.291305},
{x:38.096229,y:-102.619967},
{x:29.64135,y:-95.289041},
{x:34.395389,y:-103.152569},
{x:41.044024,y:-86.218566},
{x:41.891624,y:-91.665527},
{x:40.807623,y:-73.918964},
{x:40.67696,y:-73.971984},
{x:33.058505,y:-80.097096},
{x:38.015398,y:-87.574488},
{x:41.784345,y:-71.363282},
{x:42.338143,y:-72.634536},
{x:40.40068,y:-74.119271},
{x:38.801471,y:-77.070972},
{x:38.582451,y:-77.31136},
{x:41.552985,y:-90.481353},
{x:32.898312,y:-97.035621},
{x:35.595107,y:-77.40328},
{x:27.399244,y:-82.564813},
{x:40.66792,y:-73.906369},
{x:42.340416,y:-71.103116},
{x:41.105254,y:-75.469658},
{x:30.12972,y:-94.015053},
{x:39.81702,y:-76.99372},
{x:32.902262,y:-97.186472},
{x:40.669946,y:-73.389294},
{x:32.047416,y:-81.167936},
{x:42.362783,y:-71.084015},
{x:40.579253,y:-75.708125},
{x:28.098003,y:-80.630905},
{x:42.156997,y:-71.14525},
{x:41.8865,y:-88.298781},
{x:30.691656,y:-88.081022},
{x:36.165727,y:-84.085},
{x:30.447531,y:-89.099454},
{x:29.333881,y:-81.127749},
{x:35.131017,y:-84.877935},
{x:42.351295,y:-71.066072},
{x:27.995997,y:-82.48254},
{x:39.097726,y:-77.135812},
{x:35.734578,y:-81.363948},
{x:39.374135,y:-76.542073},
{x:33.536488,y:-82.07914},
{x:39.433731,y:-74.609704},
{x:34.176621,y:-84.421243},
{x:27.48595,y:-82.432633},
{x:40.664517,y:-89.581761},
{x:41.857056,y:-90.185843},
{x:41.054947,y:-75.960115},
{x:38.980974,y:-76.942249},
{x:28.879659,y:-81.165998},
{x:41.392713,y:-73.595348},
{x:21.399348,y:-157.974701},
{x:41.727089,y:-70.951771},
{x:39.157161,y:-94.477524},
{x:44.439524,y:-70.314156},
{x:43.012413,y:-71.506191},
{x:40.768246,y:-73.924448},
{x:21.383164,y:-157.939693},
{x:21.300422,y:-157.815684},
{x:40.836709,y:-75.024627},
{x:29.500603,y:-98.57963},
{x:36.239235,y:-115.148973},
{x:40.165749,y:-80.271832},
{x:36.9754,y:-120.080623},
{x:41.350872,y:-87.621055},
{x:28.914482,y:-82.473512},
{x:39.758794,y:-74.255292},
{x:42.519417,y:-71.758216},
{x:45.119088,y:-93.264493},
{x:41.124317,y:-78.736631},
{x:28.024247,y:-82.244841},
{x:39.144574,y:-77.41209},
{x:39.147536,y:-77.274836},
{x:34.849014,y:-117.08423},
{x:41.023878,y:-80.629623},
{x:26.528887,y:-81.865172},
{x:30.26959,y:-81.735963},
{x:33.216928,y:-96.664477},
{x:37.795102,y:-81.21697},
{x:38.700765,y:-80.664046},
{x:40.061499,y:-82.986853},
{x:26.598246,y:-81.844096},
{x:28.498418,y:-81.417856},
{x:44.66751,y:-75.001097},
{x:43.882583,y:-70.46491},
{x:41.955345,y:-78.651576},
{x:44.926221,y:-74.082378},
{x:40.095371,y:-75.416199},
{x:44.756959,y:-68.973288},
{x:35.672121,y:-80.854428},
{x:40.000487,y:-78.233932},
{x:27.822397,y:-82.826923},
{x:38.89967,y:-77.03851},
{x:40.623486,y:-91.3774},
{x:41.155339,y:-82.218512},
{x:29.647359,y:-81.599018},
{x:41.391176,y:-91.073424},
{x:41.007484,y:-91.170713},
{x:39.742804,y:-75.549929},
{x:40.568116,y:-74.329622},
{x:33.218124,y:-117.226431},
{x:37.093903,y:-76.395111},
{x:40.646701,y:-74.225844},
{x:40.709975,y:-74.014504},
{x:40.474946,y:-74.489856},
{x:39.76263,y:-104.792606},
{x:39.362124,y:-76.711408},
{x:39.210087,y:-76.84677},
{x:41.081617,y:-74.174531},
{x:42.49412,y:-73.239607},
{x:31.877885,y:-102.422668},
{x:33.084114,y:-80.204487},
{x:31.112247,y:-97.799648},
{x:33.293044,y:-111.825002},
{x:40.713117,y:-73.600276},
{x:35.980303,y:-86.558928},
{x:41.059655,y:-75.321585},
{x:33.646,y:-78.983},
{x:41.461224,y:-82.048318},
{x:29.933121,y:-94.26796},
{x:32.978863,y:-115.50109},
{x:34.796158,y:-84.961752},
{x:42.465853,y:-70.962211},
{x:41.539559,y:-81.454399},
{x:39.813177,y:-84.130654},
{x:39.728256,y:-84.056848},
{x:39.828388,y:-75.542229},
{x:40.74563,y:-73.980179},
{x:40.466942,y:-86.959094},
{x:40.735028,y:-73.994245},
{x:41.997518,y:-71.520006},
{x:39.722913,y:-90.228683},
{x:41.711787,y:-88.065579},
{x:27.948011,y:-82.401248},
{x:32.603943,y:-96.856512},
{x:35.380535,y:-119.022692},
{x:41.621708,y:-80.17344},
{x:35.08727,y:-85.071804},
{x:41.932073,y:-72.592076},
{x:41.366806,y:-81.509687},
{x:32.434595,y:-81.75928},
{x:39.162839,y:-122.919996},
{x:29.996415,y:-82.596973},
{x:39.393448,y:-76.611283},
{x:41.057341,y:-73.825551},
{x:41.501308,y:-81.633214},
{x:40.001129,y:-83.000588},
{x:40.016504,y:-79.078814},
{x:39.898819,y:-80.145884},
{x:41.149743,y:-88.835268},
{x:28.088826,y:-81.730551},
{x:37.510929,y:-122.265387},
{x:43.062853,y:-76.03432},
{x:41.011625,y:-73.795744},
{x:38.811043,y:-104.821966},
{x:40.773743,y:-73.88481},
{x:41.289847,y:-72.387341},
{x:34.168306,y:-118.606709},
{x:42.447455,y:-75.039353},
{x:40.036917,y:-75.768878},
{x:43.676633,y:-70.361069},
{x:32.593575,y:-83.640329},
{x:32.301737,y:-80.935697},
{x:40.789965,y:-73.974859},
{x:42.367053,y:-71.015992},
{x:42.36225,y:-71.019538},
{x:28.420443,y:-81.371124},
{x:33.752583,y:-84.348926},
{x:30.220718,y:-93.326194},
{x:30.180434,y:-93.248974},
{x:27.829587,y:-82.646575},
{x:37.523627,y:-77.829793},
{x:40.646756,y:-74.37223},
{x:40.77743,y:-89.675644},
{x:41.850165,y:-89.485219},
{x:43.453072,y:-76.509018},
{x:39.265052,y:-76.700149},
{x:31.480169,y:-97.207392},
{x:41.629642,y:-72.460073},
{x:41.15867,y:-96.178026},
{x:33.650024,y:-117.839313},
{x:39.361741,y:-77.531689},
{x:38.219164,y:-75.697602},
{x:35.079685,y:-84.039619},
{x:25.732284,y:-80.355982},
{x:41.53766,y:-83.635612},
{x:35.879652,y:-83.776042},
{x:35.82275,y:-86.416768},
{x:40.298979,y:-76.928424},
{x:38.789861,y:-90.347162},
{x:28.569557,y:-82.55998},
{x:42.631417,y:-73.775472},
{x:39.638211,y:-80.002724},
{x:33.480016,y:-111.910002},
{x:45.032278,y:-93.046078},
{x:40.458518,y:-80.19121},
{x:32.858308,y:-96.751368},
{x:34.063514,y:-84.724564},
{x:30.176826,y:-93.129107},
{x:40.773945,y:-72.945938},
{x:40.773825,y:-73.959903},
{x:37.575092,y:-97.244031},
{x:36.589893,y:-82.263917},
{x:31.063518,y:-97.663471},
{x:37.346755,y:-79.955922},
{x:38.912081,y:-78.192427},
{x:39.191775,y:-78.205943},
{x:41.976719,y:-87.905845},
{x:39.44562,y:-77.986362},
{x:42.114348,y:-72.580911},
{x:37.909302,y:-76.862416},
{x:37.190411,y:-77.36514},
{x:33.891849,y:-118.297775},
{x:41.913028,y:-87.652956},
{x:43.087834,y:-79.05454},
{x:41.585931,y:-73.048856},
{x:28.256709,y:-81.487725},
{x:41.669482,y:-70.991108},
{x:40.987479,y:-74.093732},
{x:40.289696,y:-75.200697},
{x:36.700172,y:-77.543555},
{x:33.260606,y:-84.096852},
{x:42.75546,y:-71.46312},
{x:40.477673,y:-79.787293},
{x:44.062942,y:-70.150603},
{x:41.172733,y:-85.166536},
{x:39.836922,y:-77.213335},
{x:39.635003,y:-75.806038},
{x:30.412956,y:-81.571907},
{x:34.219721,y:-119.038738},
{x:39.989322,y:-75.787535},
{x:38.72187,y:-77.124144},
{x:38.058452,y:-78.945291},
{x:37.469419,y:-122.223163},
{x:38.058761,y:-85.542029},
{x:27.212832,y:-98.141572},
{x:40.763051,y:-73.985341},
{x:40.706918,y:-74.005385},
{x:30.096905,y:-96.068994},
{x:31.408242,y:-103.51949},
{x:31.784142,y:-102.48054},
{x:31.846451,y:-103.089351},
{x:38.854054,y:-77.042606},
{x:39.239137,y:-76.692023},
{x:35.900959,y:-79.006952},
{x:34.993907,y:-78.339337},
{x:41.028226,y:-77.52361},
{x:35.689112,y:-79.810951},
{x:40.286555,y:-74.295234},
{x:42.071979,y:-80.093591},
{x:39.790153,y:-84.022392},
{x:40.639309,y:-73.781143},
{x:39.548337,y:-76.350014},
{x:39.082727,y:-76.946722},
{x:38.422525,y:-82.423878},
{x:32.544812,y:-93.516111},
{x:40.651678,y:-73.930112},
{x:35.049625,y:-80.648786},
{x:42.24947,y:-70.915694},
{x:34.249683,y:-83.459847},
{x:40.361203,y:-74.041793},
{x:36.844573,y:-87.485252},
{x:38.21228,y:-85.237783},
{x:29.532512,y:-98.496772},
{x:40.366498,y:-86.8726},
{x:39.267319,y:-76.467471},
{x:42.21067,y:-71.147881},
{x:42.24584,y:-71.81012},
{x:39.575782,y:-76.343842},
{x:39.928628,y:-86.231715},
{x:39.660263,y:-84.158704},
{x:42.725947,y:-82.792959},
{x:27.953213,y:-81.926304},
{x:40.74699,y:-73.988476},
{x:39.186297,y:-75.542564},
{x:41.387008,y:-81.853826},
{x:41.313758,y:-81.530239},
{x:40.113187,y:-88.282077},
{x:37.737457,y:-122.197412},
{x:40.655137,y:-74.529897},
{x:37.354843,y:-122.017367},
{x:42.579855,y:-72.181078},
{x:42.351856,y:-83.055653},
{x:42.564461,y:-83.090295},
{x:28.199361,y:-81.295988},
{x:40.75366,y:-73.989181},
{x:42.578337,y:-71.521117},
{x:41.421584,y:-81.526975},
{x:42.33109,y:-71.111959},
{x:36.18895,y:-86.797966},
{x:33.886137,y:-84.060501},
{x:36.105233,y:-86.867999},
{x:39.255012,y:-76.710666},
{x:41.662653,y:-71.154071},
{x:33.999916,y:-83.335444},
{x:40.766663,y:-73.920548},
{x:38.466734,y:-107.865836},
{x:31.366554,y:-110.940732},
{x:41.577478,y:-74.488798},
{x:40.700916,y:-74.013019},
{x:41.528853,y:-73.895479},
{x:39.195701,y:-75.548821},
{x:41.492181,y:-73.078496},
{x:30.499248,y:-84.328256},
{x:39.878501,y:-75.005937},
{x:33.976449,y:-118.37162},
{x:39.658,y:-77.749},
{x:40.599199,y:-73.954849},
{x:42.31915,y:-88.997227},
{x:36.149967,y:-82.416575},
{x:41.694071,y:-70.153915},
{x:33.43553,y:-111.997571},
{x:44.945707,y:-93.167227},
{x:29.027157,y:-81.324502},
{x:39.463708,y:-76.635587},
{x:40.393645,y:-82.490334},
{x:39.555565,y:-76.275972},
{x:27.261728,y:-80.43178},
{x:33.7325,y:-84.756488},
{x:33.040258,y:-84.965225},
{x:39.964371,y:-75.274043},
{x:41.64041,y:-70.885652},
{x:40.733544,y:-89.57393},
{x:41.834443,y:-87.665929},
{x:41.715706,y:-87.782353},
{x:35.896168,y:-84.174461},
{x:39.733521,y:-84.05802},
{x:27.464506,y:-82.562453},
{x:34.144953,y:-118.258822},
{x:41.237776,y:-85.826237},
{x:36.228085,y:-89.725838},
{x:41.655259,y:-70.923792},
{x:41.544307,y:-72.7849},
{x:42.141678,y:-72.573965},
{x:43.167139,y:-88.099928},
{x:34.468037,y:-89.006318},
{x:36.240987,y:-115.097859},
{x:29.78018,y:-95.952419},
{x:40.753365,y:-73.980774},
{x:34.816889,y:-118.887087},
{x:39.123207,y:-84.602647},
{x:40.338895,y:-79.960998},
{x:40.752919,y:-73.997046},
{x:44.050444,y:-92.521367},
{x:40.187511,y:-77.23923},
{x:31.606122,y:-81.886046},
{x:41.05904,y:-83.656965},
{x:40.123118,y:-87.450444},
{x:31.551321,y:-110.311454},
{x:41.099994,y:-80.886804},
{x:39.957197,y:-86.040695},
{x:40.457008,y:-79.939948},
{x:41.715822,y:-83.679797},
{x:27.38767,y:-82.397435},
{x:42.598691,y:-82.953077},
{x:38.520119,y:-77.349534},
{x:41.037909,y:-74.701327},
{x:42.367765,y:-71.014855},
{x:32.74981,y:-117.161204},
{x:26.709999,y:-80.229392},
{x:41.747788,y:-87.840056},
{x:37.473017,y:-77.52415},
{x:41.141782,y:-95.910204},
{x:32.858859,y:-97.264567},
{x:44.790142,y:-93.601836},
{x:39.304057,y:-76.539811},
{x:41.764406,y:-87.663412},
{x:40.037484,y:-76.44847},
{x:39.753603,y:-76.677402},
{x:32.73862,y:-111.549429},
{x:34.728301,y:-114.31557},
{x:37.756339,y:-121.143537},
{x:38.93939,y:-92.773763},
{x:41.749645,y:-87.973945},
{x:30.108873,y:-92.059933},
{x:42.046743,y:-88.259072},
{x:36.75534,y:-76.58498},
{x:39.453693,y:-76.305853},
{x:34.143005,y:-83.953256},
{x:31.970844,y:-102.253003},
{x:33.759674,y:-117.851474},
{x:39.08893,y:-77.558227},
{x:40.211314,y:-74.723883},
{x:40.190931,y:-85.418736},
{x:34.028993,y:-118.26152},
{x:42.762816,y:-72.038608},
{x:38.930421,y:-77.24406},
{x:39.874086,y:-75.392205},
{x:41.636321,y:-83.490029},
{x:34.004272,y:-81.203701},
{x:42.644419,y:-82.853997},
{x:26.959225,y:-82.352712},
{x:38.454469,y:-85.297181},
{x:42.4178,y:-71.446823},
{x:32.727559,y:-97.824567},
{x:42.521098,y:-83.761066},
{x:35.000014,y:-80.69725},
{x:39.677542,y:-75.592382},
{x:39.290162,y:-78.082421},
{x:38.660826,y:-77.440984},
{x:28.431569,y:-81.308415},
{x:32.459684,y:-86.413118},
{x:41.993914,y:-88.269216},
{x:43.036227,y:-76.136196},
{x:35.925511,y:-78.58684},
{x:34.952805,y:-81.064387},
{x:35.385404,y:-80.78605},
{x:40.747348,y:-73.981615},
{x:27.699073,y:-80.901357},
{x:35.078775,y:-78.939349},
{x:32.840665,y:-117.272871},
{x:38.173277,y:-85.710784},
{x:45.192859,y:-93.553177},
{x:39.0085,y:-84.42861},
{x:41.233779,y:-80.828777},
{x:34.086986,y:-84.468485},
{x:27.140449,y:-82.458708},
{x:41.817309,y:-72.720256},
{x:33.305797,y:-111.942235},
{x:37.786348,y:-121.267493},
{x:33.789061,y:-84.389723},
{x:27.859271,y:-82.203081},
{x:33.673397,y:-112.22105},
{x:40.781021,y:-73.245857},
{x:41.873513,y:-71.555555},
{x:39.306529,y:-85.217357},
{x:41.099355,y:-80.776814},
{x:40.598888,y:-73.508715},
{x:29.549207,y:-98.510335},
{x:29.433666,y:-98.718271},
{x:41.408273,y:-81.942933},
{x:41.642232,y:-81.458024},
{x:28.460605,y:-82.542558},
{x:38.465773,y:-122.652836},
{x:41.72952,y:-87.741504},
{x:41.487292,y:-87.579686},
{x:37.660518,y:-122.085634},
{x:41.767281,y:-87.585697},
{x:42.088297,y:-88.457534},
{x:54.847351,y:-163.418084},
{x:41.56944,y:-87.475023},
{x:39.958237,y:-85.938549},
{x:33.659041,y:-84.419341},
{x:39.678006,y:-75.650985},
{x:31.232512,y:-85.449972},
{x:29.715152,y:-98.160495},
{x:30.603762,y:-87.121747},
{x:32.823885,y:-97.186122},
{x:42.96628,y:-88.007759},
{x:32.929546,y:-97.272769},
{x:39.60402,y:-74.337031},
{x:33.256671,y:-86.813252},
{x:43.180955,y:-70.877971},
{x:30.391196,y:-81.415271},
{x:37.904235,y:-85.92945},
{x:37.904584,y:-85.954422},
{x:41.886029,y:-71.135109},
{x:39.328664,y:-76.60127},
{x:28.549271,y:-81.386537},
{x:41.135278,y:-81.354214},
{x:30.242745,y:-93.25768},
{x:29.417727,y:-98.655198},
{x:35.662355,y:-78.702259},
{x:36.84065,y:-76.094881},
{x:28.493871,y:-82.539603},
{x:35.525347,y:-82.984927},
{x:36.814511,y:-76.170392},
{x:40.650541,y:-74.574811},
{x:28.377855,y:-81.61775},
{x:34.12489,y:-81.221665},
{x:38.480279,y:-78.818219},
{x:38.52855,y:-122.789531},
{x:35.665154,y:-78.795952},
{x:39.183132,y:-76.804798},
{x:45.164635,y:-93.024074},
{x:41.711336,y:-71.501113},
{x:38.89058,y:-76.851323},
{x:38.764225,y:-94.962345},
{x:39.01949,y:-84.593784},
{x:40.261544,y:-75.732513},
{x:39.522085,y:-107.776018},
{x:33.010507,y:-80.238821},
{x:29.710859,y:-82.356896},
{x:33.743381,y:-78.822536},
{x:37.441009,y:-77.587267},
{x:40.989156,y:-75.14421},
{x:35.430772,y:-82.535475},
{x:41.410323,y:-78.560301},
{x:30.066664,y:-81.495073},
{x:41.448393,y:-75.640544},
{x:38.454211,y:-121.417433},
{x:32.925771,y:-84.334433},
{x:44.229186,y:-88.404533},
{x:44.925907,y:-92.971726},
{x:39.223229,y:-84.586848},
{x:39.505521,y:-84.748883},
{x:41.661246,y:-91.534349},
{x:41.564337,y:-72.908061},
{x:44.714431,y:-93.280661},
{x:33.429427,y:-86.790163},
{x:37.984464,y:-87.493168},
{x:36.664611,y:-87.48129},
{x:41.289507,y:-88.23618},
{x:29.515266,y:-98.695466},
{x:35.016768,y:-85.322544},
{x:41.528435,y:-87.704111},
{x:29.559492,y:-98.677219},
{x:28.86573,y:-81.956179},
{x:41.628811,y:-71.006776},
{x:40.753496,y:-73.969372},
{x:36.147104,y:-86.798957},
{x:32.869838,y:-83.70143},
{x:41.169487,y:-83.420701},
{x:37.642512,y:-77.494529},
{x:36.289978,y:-88.31409},
{x:39.892857,y:-75.017833},
{x:39.912282,y:-77.651278},
{x:40.708652,y:-74.005142},
{x:41.755669,y:-71.475819},
{x:41.832196,y:-71.444773},
{x:39.057295,y:-95.693403},
{x:37.5384,y:-77.43929},
{x:43.072628,y:-76.169169},
{x:38.639872,y:-121.328216},
{x:32.343333,y:-88.798172},
{x:28.298296,y:-80.742053},
{x:28.39958,y:-81.487942},
{x:35.951974,y:-78.52424},
{x:42.596405,y:-70.981556},
{x:42.577708,y:-71.749587},
{x:39.354008,y:-85.480123},
{x:40.743161,y:-73.482175},
{x:39.921797,y:-75.147224},
{x:34.447849,y:-83.126168},
{x:41.906376,y:-71.037483},
{x:29.651748,y:-82.330331},
{x:39.500925,y:-84.355934},
{x:35.085388,y:-106.685381},
{x:34.888114,y:-77.548533},
{x:33.099292,y:-80.123976},
{x:28.044078,y:-82.673603},
{x:35.905396,y:-86.383358},
{x:37.121179,y:-93.467569},
{x:37.691862,y:-97.244044},
{x:41.760269,y:-72.677876},
{x:39.942414,y:-76.741879},
{x:40.525092,y:-74.441404},
{x:38.888062,y:-77.029269},
{x:31.852655,y:-103.068627},
{x:31.615123,y:-102.811361},
{x:41.858645,y:-71.410715},
{x:29.785782,y:-82.031362},
{x:40.142649,y:-76.304158},
{x:35.551808,y:-82.474344},
{x:43.877296,y:-69.6329},
{x:40.850923,y:-81.277826},
{x:40.874596,y:-73.910015},
{x:54.852579,y:-163.410116},
{x:25.927922,y:-80.182081},
{x:36.312772,y:-119.313502},
{x:41.623083,y:-87.675297},
{x:40.577868,y:-73.961145},
{x:30.482568,y:-84.237216},
{x:38.306593,y:-77.513729},
{x:40.754271,y:-73.942024},
{x:40.953796,y:-74.181411},
{x:40.918073,y:-74.076286},
{x:29.527641,y:-98.472876},
{x:40.860167,y:-74.125552},
{x:42.810761,y:-84.601783},
{x:33.691798,y:-85.15051},
{x:33.615237,y:-84.613061},
{x:40.158761,y:-74.052141},
{x:27.250574,y:-80.375948},
{x:41.787336,y:-87.744705},
{x:28.550807,y:-81.5945},
{x:30.421082,y:-86.609591},
{x:39.060643,y:-77.473011},
{x:29.607749,y:-98.266366},
{x:27.906837,y:-82.691201},
{x:41.802859,y:-87.683395},
{x:38.723937,y:-76.658971},
{x:38.256507,y:-78.114359},
{x:40.310606,y:-76.698763},
{x:27.048503,y:-82.325219},
{x:34.208592,y:-84.143643},
{x:38.949362,y:-76.486987},
{x:39.365473,y:-76.77605},
{x:41.908178,y:-88.150856},
{x:41.187774,y:-80.764966},
{x:41.090039,y:-80.59411},
{x:33.81597,y:-84.445929},
{x:27.906852,y:-82.690527},
{x:41.76894,y:-87.627936},
{x:34.079909,y:-84.058346},
{x:31.971265,y:-102.074851},
{x:41.20556,y:-87.878474},
{x:27.495758,y:-82.549052},
{x:42.927603,y:-76.611641},
{x:41.708951,y:-86.188234},
{x:42.756542,y:-83.481575},
{x:36.320827,y:-86.623271},
{x:32.49064,y:-82.933895},
{x:33.442016,y:-86.830728},
{x:32.306876,y:-102.542459},
{x:38.985113,y:-76.75696},
{x:40.719946,y:-74.275672},
{x:40.762016,y:-73.990331},
{x:32.088043,y:-81.277908},
{x:33.101225,y:-81.997591},
{x:40.12372,y:-87.779016},
{x:40.840925,y:-73.634647},
{x:32.716897,y:-117.159129},
{x:35.923675,y:-84.094317},
{x:26.330763,y:-81.759832},
{x:29.999376,y:-81.682388},
{x:41.794219,y:-87.664141},
{x:41.059694,y:-84.980228},
{x:38.98208,y:-76.936928},
{x:43.110617,y:-71.95589},
{x:40.991851,y:-75.175977},
{x:40.270683,y:-74.521358},
{x:39.678783,y:-105.08115},
{x:40.444396,y:-79.228188},
{x:43.043325,y:-76.067408},
{x:40.095007,y:-74.906038},
{x:41.710276,y:-86.877181},
{x:29.618732,y:-98.511443},
{x:33.805368,y:-84.043738},
{x:33.83916,y:-84.067094},
{x:40.346222,y:-83.76079},
{x:38.420237,y:-81.791761},
{x:34.186584,y:-93.070267},
{x:36.843559,y:-76.048538},
{x:39.782823,y:-84.086071},
{x:38.411042,y:-122.713859},
{x:40.057498,y:-81.05067},
{x:40.749416,y:-81.403375},
{x:41.785487,y:-80.854314},
{x:38.419491,y:-78.870233},
{x:40.774292,y:-73.8722},
{x:40.77474,y:-73.872221},
{x:39.805253,y:-74.198145},
{x:40.652562,y:-75.435584},
{x:40.176414,y:-87.630564},
{x:27.389389,y:-82.553636},
{x:41.748158,y:-88.166142},
{x:42.022473,y:-72.587065},
{x:33.970899,y:-80.948298},
{x:39.756968,y:-94.804018},
{x:32.880543,y:-97.392517},
{x:39.576478,y:-77.879053},
{x:30.485576,y:-84.421164},
{x:34.989715,y:-118.942379},
{x:41.443772,y:-73.062539},
{x:30.495571,y:-86.549414},
{x:42.349432,y:-77.30846},
{x:32.25063,y:-110.988169},
{x:41.180037,y:-85.077454},
{x:30.495141,y:-86.549452},
{x:38.738072,y:-90.359246},
{x:32.877552,y:-105.960957},
{x:32.608746,y:-85.482138},
{x:38.927035,y:-84.543359},
{x:27.505109,y:-81.492816},
{x:42.360984,y:-71.068641},
{x:42.769971,y:-71.229882},
{x:35.22364,y:-111.58464},
{x:35.919854,y:-78.811232},
{x:31.21835,y:-85.363402},
{x:40.758593,y:-73.983822},
{x:42.249633,y:-88.287315},
{x:30.242801,y:-81.519564},
{x:40.80753,y:-73.288698},
{x:41.630287,y:-87.481252},
{x:41.681327,y:-87.539486},
{x:29.147575,y:-82.166529},
{x:44.356997,y:-89.108936},
{x:44.892,y:-71.49761},
{x:39.229141,y:-85.899392},
{x:34.112966,y:-84.15344},
{x:42.173996,y:-87.983508},
{x:42.323718,y:-83.169768},
{x:26.56791,y:-80.185386},
{x:41.358421,y:-83.649781},
{x:39.9481,y:-74.949849},
{x:29.635726,y:-95.03147},
{x:42.070628,y:-72.099028},
{x:27.983478,y:-81.681902},
{x:37.112981,y:-76.500422},
{x:34.762529,y:-79.46912},
{x:41.865988,y:-87.713219},
{x:33.750592,y:-84.718922},
{x:32.707043,y:-117.155851},
{x:33.757776,y:-84.382447},
{x:41.455561,y:-74.393079},
{x:36.504317,y:-82.429748},
{x:44.105497,y:-70.211797},
{x:42.38602,y:-71.222744},
{x:39.364211,y:-76.608441},
{x:38.415815,y:-86.938365},
{x:44.970268,y:-89.637206},
{x:40.75286,y:-87.996145},
{x:39.284409,y:-76.5985},
{x:35.438478,y:-79.217428},
{x:44.854975,y:-93.241052},
{x:34.702754,y:-86.786916},
{x:34.227524,y:-83.499456},
{x:34.083773,y:-84.553286},
{x:40.608747,y:-73.972056},
{x:41.596459,y:-88.201136},
{x:40.76098,y:-73.969718},
{x:39.037432,y:-76.990204},
{x:39.70406,y:-77.316258},
{x:43.427374,y:-88.19835},
{x:35.584007,y:-80.987126},
{x:29.537935,y:-98.584997},
{x:37.27779,y:-121.880569},
{x:43.224378,y:-71.71417},
{x:26.596312,y:-81.884935},
{x:44.035298,y:-70.267303},
{x:42.108704,y:-83.286065},
{x:32.838297,y:-106.082591},
{x:39.101953,y:-96.816977},
{x:31.429057,y:-100.410672},
{x:35.65889,y:-82.024804},
{x:35.238322,y:-106.657499},
{x:40.320311,y:-76.563601},
{x:40.935616,y:-73.856853},
{x:31.10176,y:-97.750115},
{x:41.138644,y:-95.966822},
{x:47.469178,y:-94.879457},
{x:39.508074,y:-87.386497},
{x:40.375564,y:-80.704387},
{x:32.724199,y:-103.144219},
{x:26.150256,y:-80.323498},
{x:32.305279,y:-111.014277},
{x:40.752627,y:-73.985898},
{x:32.916627,y:-117.126652},
{x:39.276058,y:-76.644058},
{x:40.412327,y:-80.030654},
{x:40.895407,y:-74.083629},
{x:40.685328,y:-73.593371},
{x:40.369836,y:-80.63464},
{x:41.364334,y:-82.078748},
{x:39.225538,y:-84.355812},
{x:40.581199,y:-83.099141},
{x:39.953961,y:-83.103393},
{x:32.473819,y:-83.745343},
{x:42.643468,y:-73.608428},
{x:41.969103,y:-87.837032},
{x:41.931582,y:-87.649329},
{x:44.083624,y:-87.691217},
{x:35.030248,y:-79.06238},
{x:35.233152,y:-97.442555},
{x:43.906703,y:-69.921185},
{x:38.006175,y:-121.835564},
{x:29.578527,y:-98.442581},
{x:38.789431,y:-104.755896},
{x:46.549959,y:-87.455579},
{x:28.472104,y:-81.397202},
{x:40.776101,y:-73.952846},
{x:40.140125,y:-75.12325},
{x:27.976429,y:-82.503369},
{x:41.537911,y:-88.10109},
{x:38.245503,y:-122.020698},
{x:40.764455,y:-73.98254},
{x:33.795573,y:-116.971267},
{x:39.827978,y:-105.025527},
{x:42.296563,y:-84.025407},
{x:42.095827,y:-88.682232},
{x:33.778714,y:-84.384182},
{x:33.377544,y:-111.687266},
{x:34.092665,y:-84.286098},
{x:42.130234,y:-72.534732},
{x:41.717907,y:-87.839271},
{x:39.946181,y:-74.984472},
{x:39.624753,y:-77.673567},
{x:41.061942,y:-80.054457},
{x:39.53515,y:-119.867069},
{x:40.596616,y:-74.36231},
{x:40.774646,y:-73.912842},
{x:40.749272,y:-73.984149},
{x:40.9146,y:-73.123804},
{x:41.884898,y:-87.63413},
{x:30.57669,y:-96.316447},
{x:39.194276,y:-76.749847},
{x:41.27907,y:-73.228203},
{x:40.734392,y:-73.869874},
{x:40.078249,y:-75.160009},
{x:44.613228,y:-69.332583},
{x:39.484703,y:-77.240238},
{x:27.447799,y:-80.323797},
{x:35.385352,y:-96.670369},
{x:32.211101,y:-82.395104},
{x:39.781638,y:-86.094435},
{x:41.102818,y:-85.05734},
{x:36.201369,y:-86.519066},
{x:39.714276,y:-75.616942},
{x:42.545088,y:-83.285613},
{x:41.078655,y:-87.866839},
{x:40.790411,y:-73.947497},
{x:34.281384,y:-86.220755},
{x:40.740849,y:-73.981483},
{x:39.163674,y:-86.498493},
{x:33.683312,y:-78.958213},
{x:40.059234,y:-77.492481},
{x:35.204496,y:-80.801891},
{x:39.225536,y:-76.070419},
{x:33.201707,y:-111.631379},
{x:41.647261,y:-70.882768},
{x:44.790862,y:-93.21502},
{x:38.883482,y:-76.99473},
{x:44.810195,y:-93.332656},
{x:41.47831,y:-73.409246},
{x:28.010312,y:-82.394047},
{x:44.033846,y:-88.575264},
{x:30.404667,y:-86.853285},
{x:39.209641,y:-76.798049},
{x:39.909441,y:-76.681649},
{x:36.418103,y:-82.469517},
{x:39.357738,y:-84.274881},
{x:33.718117,y:-84.348998},
{x:40.566216,y:-74.110904},
{x:38.047349,y:-84.549718},
{x:40.2161,y:-74.766337},
{x:40.641975,y:-73.791837},
{x:26.279388,y:-81.596537},
{x:40.634609,y:-74.108356},
{x:42.310604,y:-89.077435},
{x:42.629592,y:-82.861715},
{x:30.068967,y:-95.238456},
{x:42.903841,y:-85.786647},
{x:28.752734,y:-81.877116},
{x:40.771982,y:-80.372972},
{x:33.926412,y:-84.571239},
{x:30.128783,y:-93.883613},
{x:41.358029,y:-74.683318},
{x:40.712743,y:-74.013381},
{x:40.031803,y:-75.103377},
{x:33.821165,y:-84.264538},
{x:41.699392,y:-88.447805},
{x:41.661314,y:-88.489797},
{x:40.710341,y:-74.011533},
{x:40.430134,y:-79.814125},
{x:40.024371,y:-79.593384},
{x:38.567398,y:-90.497102},
{x:40.802927,y:-73.967357},
{x:40.916449,y:-98.360866},
{x:36.088243,y:-80.0637},
{x:35.989038,y:-78.531109},
{x:39.451226,y:-74.572965},
{x:33.079472,y:-96.893275},
{x:32.656302,y:-114.583888},
{x:40.802389,y:-72.809427},
{x:40.127489,y:-79.549859},
{x:44.915853,y:-68.683303},
{x:35.991008,y:-86.682988},
{x:40.451761,y:-79.953276},
{x:40.045917,y:-86.485465},
{x:37.639512,y:-77.631411},
{x:36.133227,y:-115.156414},
{x:37.607527,y:-97.332426},
{x:41.598655,y:-88.006534},
{x:35.926144,y:-86.816922},
{x:40.739928,y:-74.363783},
{x:33.225819,y:-96.984061},
{x:41.422915,y:-74.429233},
{x:42.231447,y:-75.916722},
{x:40.165264,y:-74.41741},
{x:41.987469,y:-70.713447},
{x:30.462827,y:-84.221895},
{x:30.051465,y:-99.135602},
{x:42.276675,y:-71.762026},
{x:33.883128,y:-78.687804},
{x:40.713142,y:-73.605861},
{x:42.617635,y:-71.047767},
{x:40.927297,y:-74.187574},
{x:42.903836,y:-88.152408},
{x:41.880216,y:-80.171501},
{x:37.157004,y:-76.555848},
{x:43.13523,y:-88.243243},
{x:42.366332,y:-71.016577},
{x:40.988689,y:-74.140712},
{x:33.297433,y:-117.349731},
{x:42.349956,y:-71.042629},
{x:43.047544,y:-89.340595},
{x:33.259444,y:-96.785455},
{x:40.277522,y:-76.599941},
{x:40.033705,y:-82.433925},
{x:33.863562,y:-84.622807},
{x:34.120955,y:-83.763814},
{x:40.812321,y:-91.169556},
{x:28.030661,y:-82.056962},
{x:26.173781,y:-80.855203},
{x:43.582586,y:-84.748104},
{x:36.201197,y:-83.335608},
{x:34.013433,y:-84.554785},
{x:29.814273,y:-95.429809},
{x:42.810056,y:-83.252197},
{x:28.610953,y:-81.468184},
{x:37.578848,y:-120.939405},
{x:41.579935,y:-87.812426},
{x:41.671637,y:-70.987004},
{x:29.565063,y:-95.563098},
{x:39.214755,y:-76.860229},
{x:42.225667,y:-83.194062},
{x:32.431928,y:-81.779861},
{x:40.730027,y:-73.862954},
{x:41.869698,y:-87.935347},
{x:40.606942,y:-73.962727},
{x:40.65575,y:-73.94268},
{x:36.577539,y:-87.3003},
{x:33.602945,y:-83.858217},
{x:36.577776,y:-86.515584},
{x:41.968329,y:-87.777681},
{x:34.168653,y:-118.53666},
{x:39.235228,y:-76.830354},
{x:40.726065,y:-74.532878},
{x:39.348628,y:-84.218544},
{x:36.118094,y:-115.171858},
{x:36.118328,y:-115.171579},
{x:35.127846,y:-106.551432},
{x:39.303631,y:-84.52424},
{x:27.339238,y:-82.443329},
{x:39.8846,y:-79.736316},
{x:41.672295,y:-72.75517},
{x:42.583632,y:-83.933978},
{x:42.735611,y:-84.484157},
{x:34.060369,y:-117.587199},
{x:40.900736,y:-80.86803},
{x:41.829477,y:-87.67959},
{x:34.360908,y:-80.061612},
{x:32.662619,y:-83.73878},
{x:33.63776,y:-112.1843},
{x:40.766941,y:-73.982906},
{x:33.492903,y:-112.331598},
{x:40.750668,y:-73.976947},
{x:40.824303,y:-73.859244},
{x:40.54908,y:-82.846862},
{x:39.942527,y:-82.599268},
{x:41.581092,y:-83.664301},
{x:41.370152,y:-71.858586},
{x:41.733296,y:-71.405155},
{x:41.974094,y:-88.030986},
{x:39.200154,y:-85.949578},
{x:41.006052,y:-75.183887},
{x:40.904465,y:-78.234615},
{x:42.725223,y:-87.955611},
{x:40.892099,y:-83.871028},
{x:35.783191,y:-80.259596},
{x:27.961149,y:-82.713862},
{x:40.683751,y:-74.184474},
{x:35.224158,y:-80.940684},
{x:36.43323,y:-77.634881},
{x:38.415396,y:-82.258666},
{x:39.387963,y:-84.454502},
{x:39.018269,y:-84.506934},
{x:32.858042,y:-79.797468},
{x:36.994534,y:-86.426084},
{x:42.553604,y:-82.960132},
{x:35.815865,y:-81.305594},
{x:40.517413,y:-74.285275},
{x:40.835846,y:-73.920888},
{x:39.384856,y:-94.791752},
{x:32.945737,y:-96.703373},
{x:33.509072,y:-112.239452},
{x:39.888425,y:-74.744129},
{x:36.126742,y:-115.169334},
{x:42.761238,y:-78.129195},
{x:42.254616,y:-78.060842},
{x:42.87562,y:-78.870521},
{x:40.70481,y:-73.796262},
{x:41.464142,y:-81.768538},
{x:40.823917,y:-74.030088},
{x:36.081725,y:-80.339796},
{x:39.874525,y:-74.214653},
{x:35.789263,y:-78.82754},
{x:37.076077,y:-76.468075},
{x:35.347393,y:-82.432715},
{x:41.564663,y:-70.618394},
{x:36.640122,y:-87.436002},
{x:41.060701,y:-80.662244},
{x:39.286177,y:-76.5411},
{x:40.177751,y:-74.631139},
{x:28.536281,y:-82.534932},
{x:35.094046,y:-114.597666},
{x:40.201746,y:-74.027495},
{x:43.63637,y:-72.290509},
{x:40.785901,y:-74.453348},
{x:40.269869,y:-75.919321},
{x:34.98851,y:-80.77322},
{x:38.355215,y:-75.07635},
{x:43.587351,y:-89.81446},
{x:41.550646,y:-88.229284},
{x:40.247097,y:-76.893655},
{x:35.715328,y:-81.697243},
{x:42.447396,y:-75.049159},
{x:34.152601,y:-84.822372},
{x:41.736017,y:-87.628292},
{x:39.408119,y:-77.429305},
{x:38.296443,y:-77.496518},
{x:39.603411,y:-76.479931},
{x:27.048117,y:-82.395381},
{x:33.560731,y:-84.322496},
{x:32.648884,y:-97.043267},
{x:34.177259,y:-83.910157},
{x:27.573547,y:-82.508891},
{x:41.672613,y:-72.891512},
{x:37.976977,y:-87.393895},
{x:35.637502,y:-97.568111},
{x:42.198783,y:-83.204233},
{x:36.03061,y:-115.135761},
{x:42.040297,y:-87.839537},
{x:41.111931,y:-75.383131},
{x:33.107922,y:-96.842275},
{x:42.978156,y:-87.948882},
{x:44.529536,y:-89.574095},
{x:32.236488,y:-110.919657},
{x:40.760528,y:-73.995122},
{x:41.887843,y:-71.125036},
{x:33.410622,y:-86.76308},
{x:39.42262,y:-119.7566},
{x:43.012981,y:-88.384626},
{x:40.089692,y:-83.049179},
{x:35.288109,y:-81.020913},
{x:26.680951,y:-80.12058},
{x:39.348071,y:-77.068449},
{x:41.837253,y:-87.646092},
{x:41.734128,y:-87.702708},
{x:39.596696,y:-76.073877},
{x:32.895006,y:-96.682112},
{x:39.642365,y:-77.58942},
{x:43.051565,y:-74.32276},
{x:41.324725,y:-87.822983},
{x:28.238777,y:-81.560128},
{x:27.560655,y:-82.441867},
{x:43.50853,y:-71.499028},
{x:40.055573,y:-76.398946},
{x:41.928094,y:-86.572241},
{x:30.394818,y:-84.345041},
{x:42.069951,y:-86.437872},
{x:32.6467,y:-116.965769},
{x:42.362485,y:-88.111381},
{x:42.593047,y:-88.416454},
{x:39.310618,y:-76.899273},
{x:30.112065,y:-95.554316},
{x:38.909342,y:-77.544075},
{x:31.338217,y:-109.565561},
{x:37.656988,y:-120.957844},
{x:34.182068,y:-118.310495},
{x:36.106957,y:-79.827603},
{x:33.41756,y:-86.667487},
{x:42.885046,y:-85.722551},
{x:39.483223,y:-88.343153},
{x:43.04009,y:-85.690211},
{x:41.143975,y:-87.268775},
{x:35.169588,y:-80.904021},
{x:34.96813,y:-80.995265},
{x:33.58732,y:-79.054844},
{x:42.175321,y:-88.370374},
{x:33.257709,y:-111.840777},
{x:42.024408,y:-88.338472},
{x:33.735732,y:-117.007005},
{x:38.071847,y:-97.898796},
{x:43.076387,y:-75.103169},
{x:42.999029,y:-77.413175},
{x:42.952666,y:-78.602967},
{x:43.011836,y:-78.329131},
{x:36.137084,y:-95.921917},
{x:33.364101,y:-111.945245},
{x:35.945925,y:-86.822806},
{x:26.633141,y:-81.941176},
{x:36.262816,y:-93.134411},
{x:37.227173,y:-107.86101},
{x:42.607334,y:-87.878677},
{x:38.212923,y:-90.407251},
{x:42.4098,y:-83.100645},
{x:36.158826,y:-115.192174},
{x:36.14427,y:-80.299089},
{x:42.944109,y:-71.475402},
{x:41.751867,y:-72.714626},
{x:40.27166,y:-81.869251},
{x:40.612794,y:-74.124959},
{x:40.410648,y:-80.081232},
{x:39.354593,y:-84.371718},
{x:43.127464,y:-76.080739},
{x:32.371458,y:-86.271156},
{x:41.652209,y:-73.875408},
{x:36.493694,y:-86.877861},
{x:41.28955,y:-87.402109},
{x:40.862096,y:-73.924841},
{x:41.395573,y:-75.676385},
{x:40.912827,y:-73.837054},
{x:45.01526,y:-93.177639},
{x:36.12395,y:-115.07565},
{x:36.163384,y:-115.207417},
{x:40.732885,y:-73.849316},
{x:39.847644,y:-75.430781},
{x:38.883657,y:-77.099127},
{x:43.533102,y:-89.984046},
{x:40.757059,y:-73.963231},
{x:33.21852,y:-96.871902},
{x:33.377693,y:-112.010246},
{x:33.282376,y:-86.851701},
{x:36.139911,y:-85.267762},
{x:39.344466,y:-76.649391},
{x:33.4551,y:-82.206448},
{x:41.975048,y:-87.890729},
{x:33.877466,y:-84.379402},
{x:35.218994,y:-97.480566},
{x:39.682228,y:-74.240231},
{x:35.960504,y:-115.142246},
{x:41.358928,y:-83.142643},
{x:39.377446,y:-76.458335},
{x:40.561254,y:-84.172548},
{x:41.312746,y:-82.983108},
{x:30.461835,y:-81.63269},
{x:33.359906,y:-84.754287},
{x:42.263908,y:-83.219118},
{x:41.623666,y:-83.626825},
{x:37.184873,y:-93.261973},
{x:40.632494,y:-74.918077},
{x:34.561046,y:-112.482657},
{x:33.294371,y:-84.545452},
{x:31.11884,y:-97.422041},
{x:39.989308,y:-83.048719},
{x:38.596903,y:-90.331341},
{x:40.835266,y:-77.673657},
{x:42.588341,y:-72.308354},
{x:27.196797,y:-82.489741},
{x:34.663707,y:-86.485357},
{x:34.821441,y:-87.654884},
{x:39.842705,y:-86.145503},
{x:42.711907,y:-73.989726},
{x:32.946326,y:-80.040548},
{x:27.766109,y:-82.335666},
{x:33.61438,y:-85.827034},
{x:27.634034,y:-80.389647},
{x:35.697565,y:-81.292183},
{x:40.740503,y:-74.001793},
{x:44.162697,y:-94.009414},
{x:40.327535,y:-80.034306},
{x:45.813555,y:-88.061394},
{x:43.60154,y:-73.273623},
{x:40.91408,y:-74.080128},
{x:41.160311,y:-75.887997},
{x:38.315562,y:-88.92602},
{x:41.292253,y:-72.698076},
{x:40.687082,y:-74.299895},
{x:40.07377,y:-82.861563},
{x:40.090879,y:-82.793331},
{x:31.119723,y:-97.877498},
{x:41.430855,y:-87.995531},
{x:42.091236,y:-88.120733},
{x:39.005497,y:-84.621559},
{x:32.284181,y:-81.233832},
{x:35.223949,y:-80.888854},
{x:39.959726,y:-76.659689},
{x:35.701693,y:-81.200205},
{x:40.734411,y:-74.164217},
{x:39.081447,y:-78.213399},
{x:33.464916,y:-83.243329},
{x:34.604008,y:-83.520275},
{x:37.254528,y:-77.410659},
{x:35.86531,y:-83.546264},
{x:33.859432,y:-84.019655},
{x:33.393444,y:-84.766711},
{x:34.962572,y:-89.858456},
{x:33.952228,y:-118.187882},
{x:42.179074,y:-71.221436},
{x:35.703684,y:-80.890688},
{x:26.138796,y:-80.421556},
{x:41.464838,y:-81.648289},
{x:35.162477,y:-83.392061},
{x:37.943864,y:-91.882366},
{x:38.475424,y:-77.428254},
{x:42.369141,y:-88.268934},
{x:42.81305,y:-83.706863},
{x:26.240925,y:-98.247344},
{x:40.304422,y:-74.253216},
{x:33.56972,y:-82.189735},
{x:28.531502,y:-82.505212},
{x:33.711904,y:-78.899818},
{x:41.661466,y:-87.728509},
{x:40.893529,y:-74.482347},
{x:44.422807,y:-69.026144},
{x:33.868052,y:-79.053196},
{x:37.693972,y:-97.300057},
{x:36.061214,y:-79.432531},
{x:39.021609,y:-77.014325},
{x:33.754081,y:-84.268443},
{x:37.738959,y:-97.462048},
{x:40.667122,y:-77.605976},
{x:32.904833,y:-96.446606},
{x:43.698789,y:-70.782281},
{x:34.341259,y:-78.708973},
{x:37.799289,y:-79.417249},
{x:32.294148,y:-106.785523},
{x:36.016865,y:-83.827033},
{x:45.016231,y:-93.482519},
{x:40.159144,y:-75.214867},
{x:41.630162,y:-93.697264},
{x:39.92509,y:-80.74924},
{x:36.533276,y:-76.184629},
{x:41.426592,y:-87.751658},
{x:36.019767,y:-115.288027},
{x:34.057669,y:-118.361799},
{x:39.800361,y:-75.505688},
{x:29.939857,y:-90.120304},
{x:40.33859,y:-78.391588},
{x:34.023665,y:-84.270372},
{x:37.50432,y:-77.652843},
{x:39.708357,y:-105.135376},
{x:39.637823,y:-78.846838},
{x:35.790828,y:-83.559104},
{x:37.369457,y:-121.88},
{x:31.699729,y:-106.219137},
{x:31.782992,y:-106.244531},
{x:43.174988,y:-77.732816},
{x:34.96523,y:-80.059241},
{x:32.672888,y:-97.091318},
{x:33.048027,y:-85.029195},
{x:32.6491,y:-96.942563},
{x:39.097616,y:-84.267859},
{x:35.842982,y:-86.491578},
{x:33.779871,y:-78.790502},
{x:41.581067,y:-88.122826},
{x:37.758183,y:-92.115718},
{x:40.635769,y:-89.608276},
{x:41.817571,y:-86.247153},
{x:48.588601,y:-93.40314},
{x:39.864422,y:-84.287706},
{x:39.882921,y:-76.864932},
{x:30.871613,y:-83.289039},
{x:40.063021,y:-76.541025},
{x:28.857382,y:-82.393318},
{x:42.691776,y:-73.687319},
{x:31.447161,y:-85.655892},
{x:43.288408,y:-71.831972},
{x:36.196032,y:-115.122043},
{x:40.556577,y:-84.542122},
{x:41.513093,y:-87.258492},
{x:29.730528,y:-98.080496},
{x:38.470652,y:-75.115573},
{x:40.793868,y:-73.949528},
{x:40.192941,y:-76.991552},
{x:31.809943,y:-106.246368},
{x:39.22469,y:-76.658692},
{x:35.605613,y:-78.56563},
{x:35.978497,y:-77.976004},
{x:45.176175,y:-93.867669},
{x:39.110334,y:-76.99563},
{x:29.912718,y:-95.482998},
{x:45.278939,y:-93.566302},
{x:41.771174,y:-70.954736},
{x:41.640134,y:-88.005583},
{x:39.484231,y:-88.178568},
{x:41.294703,y:-96.02428},
{x:34.063658,y:-118.202243},
{x:40.131826,y:-82.885968},
{x:40.113402,y:-82.927332},
{x:33.901781,y:-118.11866},
{x:26.124357,y:-81.764199},
{x:40.84883,y:-73.933841},
{x:36.694628,y:-82.012234},
{x:42.509461,y:-71.132676},
{x:36.064695,y:-79.300421},
{x:34.568527,y:-83.304256},
{x:40.51502,y:-88.9526},
{x:38.496454,y:-121.438438},
{x:44.731462,y:-93.222332},
{x:29.830057,y:-95.763544},
{x:42.254596,y:-71.798939},
{x:41.822492,y:-87.744248},
{x:35.200653,y:-87.047353},
{x:28.87212,y:-96.998185},
{x:27.945121,y:-82.519408},
{x:42.084679,y:-80.14771},
{x:35.957466,y:-85.033076},
{x:40.699068,y:-73.919076},
{x:40.263766,y:-83.067898},
{x:30.33608,y:-81.758189},
{x:32.872328,y:-97.291045},
{x:41.371933,y:-88.835609},
{x:36.085678,y:-115.153374},
{x:36.08136,y:-115.136111},
{x:36.082652,y:-115.136659},
{x:42.936231,y:-73.806372},
{x:42.640038,y:-84.484993},
{x:41.884065,y:-87.863322},
{x:35.284755,y:-80.672651},
{x:38.234573,y:-86.12736},
{x:41.243255,y:-81.176903},
{x:41.245251,y:-81.177256},
{x:31.049399,y:-93.218317},
{x:40.164908,y:-76.159927},
{x:31.9359,y:-106.425495},
{x:35.042843,y:-85.686736},
{x:33.594408,y:-85.038785},
{x:41.250418,y:-87.8782},
{x:36.078231,y:-86.64211},
{x:41.117519,y:-102.957894},
{x:31.559986,y:-110.260163},
{x:40.633122,y:-73.930089},
{x:41.404558,y:-73.091701},
{x:39.671785,y:-85.131965},
{x:41.663039,y:-86.135302},
{x:28.059759,y:-81.816268},
{x:35.220188,y:-80.943298},
{x:40.309238,y:-79.581206},
{x:33.407658,y:-111.949104},
{x:35.061146,y:-79.013917},
{x:32.632559,y:-97.407648},
{x:34.840519,y:-90.358614},
{x:36.294275,y:-76.294347},
{x:44.27617,y:-84.275123},
{x:37.523357,y:-77.610509},
{x:34.937043,y:-81.979334},
{x:40.001253,y:-81.57681},
{x:30.094326,y:-95.989486},
{x:42.33662,y:-71.148876},
{x:37.14835,y:-94.310785},
{x:28.800011,y:-82.044878},
{x:42.930681,y:-87.860467},
{x:38.957685,y:-85.853892},
{x:40.132242,y:-76.795298},
{x:27.462403,y:-80.307227},
{x:36.129688,y:-115.152805},
{x:42.023995,y:-76.41328},
{x:38.99035,y:-104.798905},
{x:39.857968,y:-75.543499},
{x:39.865297,y:-84.137756},
{x:40.684777,y:-75.614126},
{x:40.596634,y:-73.977753},
{x:35.342026,y:-78.029858},
{x:41.369272,y:-82.957059},
{x:41.367814,y:-82.958529},
{x:40.748208,y:-73.988379},
{x:30.493833,y:-86.549433},
{x:37.168371,y:-93.295044},
{x:39.973302,y:-82.903671},
{x:41.551637,y:-73.0629},
{x:33.022901,y:-96.454283},
{x:34.048147,y:-117.304115},
{x:27.698645,y:-97.412615},
{x:34.541158,y:-83.542423},
{x:33.908703,y:-84.379098},
{x:26.555261,y:-80.205104},
{x:38.97331,y:-84.391856},
{x:40.213831,y:-75.010883},
{x:33.935412,y:-84.36004},
{x:34.061825,y:-118.447995},
{x:38.218285,y:-84.541681},
{x:34.270685,y:-85.223204},
{x:40.841153,y:-79.919155},
{x:41.9806827,y:-87.9064793},
{x:39.899197,y:-84.220311},
{x:21.33223,y:-157.920363},
{x:38.874608,y:-104.820684},
{x:33.17948,y:-96.52475},
{x:34.547852,y:-82.631517},
{x:26.837271,y:-80.131826},
{x:39.121497,y:-77.177886},
{x:43.574379,y:-89.777068},
{x:35.648463,y:-78.393002},
{x:34.735373,y:-80.782636},
{x:34.195596,y:-83.858517},
{x:41.675982,y:-81.426871},
{x:33.198404,y:-96.652475},
{x:34.272154,y:-118.772971},
{x:37.736455,y:-84.316455},
{x:33.621838,y:-83.838362},
{x:36.671948,y:-76.941722},
{x:28.882257,y:-81.239926},
{x:33.571398,y:-86.520155},
{x:41.547586,y:-88.005352},
{x:40.791314,y:-73.096399},
{x:27.770933,y:-82.770801},
{x:26.517222,y:-81.763648},
{x:42.047697,y:-88.144183},
{x:39.739127,y:-75.599105},
{x:36.86247,y:-76.400573},
{x:36.104995,y:-95.862999},
{x:40.233074,y:-79.583438},
{x:44.89285,y:-73.839473},
{x:33.653773,y:-86.648179},
{x:42.369494,y:-71.019179},
{x:38.781528,y:-77.185846},
{x:33.222992,y:-117.395152},
{x:40.761071,y:-73.983107},
{x:36.292568,y:-95.843752},
{x:35.167996,y:-78.973464},
{x:40.843232,y:-81.766852},
{x:26.430713,y:-81.784379},
{x:37.369339,y:-121.929265},
{x:34.363216,y:-89.511534},
{x:43.213892,y:-77.731167},
{x:39.34058,y:-76.762588},
{x:39.704515,y:-104.939144},
{x:40.320152,y:-76.89826},
{x:39.378456,y:-76.626627},
{x:36.177455,y:-85.467076},
{x:32.851434,y:-96.992833},
{x:33.983338,y:-81.095259},
{x:34.077073,y:-84.355657},
{x:43.102743,y:-89.198887},
{x:42.336382,y:-83.040329},
{x:33.887016,y:-118.395773},
{x:32.352802,y:-86.218315},
{x:46.695983,y:-68.010712},
{x:40.176726,y:-74.628446},
{x:40.122041,y:-75.776085},
{x:32.924543,y:-83.739423},
{x:39.470992,y:-74.528798},
{x:40.058653,y:-78.081892},
{x:40.208419,y:-76.574454},
{x:40.202177,y:-76.015613},
{x:39.99913,y:-79.046615},
{x:40.000168,y:-79.044806},
{x:39.661879,y:-75.691373},
{x:41.747008,y:-85.328811},
{x:43.410765,y:-70.55785},
{x:43.41,y:-70.561011},
{x:44.207467,y:-69.827886},
{x:33.842871,y:-87.247496},
{x:36.066351,y:-95.88582},
{x:33.469868,y:-84.445233},
{x:34.29888,y:-84.15167},
{x:34.023004,y:-118.279583},
{x:39.187937,y:-84.652694},
{x:44.873093,y:-92.622424},
{x:33.940388,y:-84.520311},
{x:41.508186,y:-81.608651},
{x:29.641477,y:-98.380765},
{x:40.596869,y:-73.972864},
{x:35.140961,y:-86.568923},
{x:42.568516,y:-88.106059},
{x:32.481657,y:-93.747837},
{x:33.341351,y:-84.534121},
{x:40.194541,y:-75.157353},
{x:42.533389,y:-83.1225},
{x:39.333256,y:-83.006156},
{x:36.06155,y:-95.957431},
{x:40.574464,y:-75.558176},
{x:36.12048,y:-80.367887},
{x:41.743159,y:-88.37632},
{x:40.684097,y:-74.186691},
{x:41.140591,y:-73.352025},
{x:34.06032,y:-117.597918},
{x:36.189208,y:-86.410515},
{x:33.195304,y:-80.600419},
{x:42.906313,y:-73.584262},
{x:29.519149,y:-98.771726},
{x:35.474023,y:-97.725409},
{x:41.745141,y:-85.329435},
{x:33.294897,y:-83.958318},
{x:26.493523,y:-81.787828},
{x:40.757564,y:-73.990261},
{x:27.017186,y:-82.135068},
{x:41.752086,y:-86.119907},
{x:38.896322,y:-104.847225},
{x:41.215158,y:-73.720456},
{x:40.259204,y:-76.774254},
{x:38.050484,y:-84.713633},
{x:40.721936,y:-73.984491},
{x:38.851841,y:-76.913509},
{x:40.633765,y:-74.013967},
{x:43.63461,y:-88.715951},
{x:41.730698,y:-72.718676},
{x:42.697857,y:-83.937978},
{x:41.402472,y:-84.132601},
{x:40.106612,y:-83.729715},
{x:38.341893,y:-81.663944},
{x:39.09143,y:-77.045894},
{x:39.294312,y:-94.717532},
{x:39.294495,y:-94.711974},
{x:42.573939,y:-71.776616},
{x:40.713474,y:-73.873995},
{x:34.25577,y:-88.738474},
{x:35.305966,y:-82.450967},
{x:41.820131,y:-71.410494},
{x:40.921736,y:-74.303094},
{x:36.996829,y:-85.929388},
{x:43.192325,y:-77.586649},
{x:36.344038,y:-82.248611},
{x:37.869047,y:-87.571051},
{x:35.513663,y:-84.361344},
{x:35.236012,y:-85.200274},
{x:34.855658,y:-86.57218},
{x:33.810511,y:-85.761042},
{x:40.770188,y:-81.385452},
{x:45.104747,y:-93.452279},
{x:42.847494,y:-85.722381},
{x:43.832218,y:-91.239942},
{x:30.39969,y:-89.024022},
{x:29.35691,y:-98.517803},
{x:40.219273,y:-85.397951},
{x:41.848654,y:-87.685777},
{x:33.885843,y:-117.225606},
{x:43.457567,y:-88.817209},
{x:44.293898,y:-90.834211},
{x:43.318649,y:-90.364904},
{x:41.146755,y:-73.493128},
{x:40.795971,y:-73.970631},
{x:41.213796,y:-79.382592},
{x:40.78669,y:-73.941971},
{x:34.684677,y:-79.203315},
{x:33.393188,y:-111.787193},
{x:32.381791,y:-86.188742},
{x:40.750244,y:-73.990535},
{x:36.695543,y:-86.5706},
{x:41.693893,y:-88.166927},
{x:44.128775,y:-69.095333},
{x:43.995414,y:-88.575799},
{x:36.696035,y:-97.350712},
{x:42.213723,y:-83.219294},
{x:40.028353,y:-83.015268},
{x:36.258814,y:-86.657722},
{x:42.147469,y:-72.729618},
{x:42.143603,y:-80.007776},
{x:34.437174,y:-85.74204},
{x:33.957285,y:-86.038614},
{x:34.61988,y:-89.954169},
{x:29.863592,y:-81.343466},
{x:45.553506,y:-94.198676},
{x:43.28422,y:-87.987301},
{x:38.640537,y:-77.332767},
{x:42.365731,y:-71.016711},
{x:30.578987,y:-98.273358},
{x:39.963822,y:-75.132475},
{x:34.03675,y:-118.444157},
{x:30.209212,y:-95.422434},
{x:35.481469,y:-97.497644},
{x:33.94522,y:-81.125392},
{x:28.415739,y:-81.303373},
{x:40.751425,y:-73.855901},
{x:40.586058,y:-79.710686},
{x:44.782423,y:-88.585452},
{x:43.177333,y:-88.011112},
{x:35.39782,y:-119.111533},
{x:40.416774,y:-74.443897},
{x:36.05865,y:-89.393838},
{x:37.320794,y:-81.134506},
{x:40.059646,y:-75.653605},
{x:32.327724,y:-86.264536},
{x:41.484041,y:-87.926626},
{x:40.143283,y:-76.597651},
{x:39.185798,y:-77.189027},
{x:35.92465,y:-86.538068},
{x:33.393736,y:-111.823581},
{x:37.350521,y:-79.228639},
{x:35.580958,y:-77.377501},
{x:36.631,y:-82.137484},
{x:40.601057,y:-75.475547},
{x:39.866266,y:-77.044163},
{x:40.792321,y:-80.136711},
{x:34.870602,y:-83.400131},
{x:40.759716,y:-73.772465},
{x:38.915992,y:-84.634061},
{x:41.698295,y:-71.166825},
{x:40.716407,y:-74.004246},
{x:43.797089,y:-90.053256},
{x:32.881632,y:-97.097058},
{x:46.724126,y:-92.072077},
{x:43.294834,y:-77.919871},
{x:30.13536,y:-95.653694},
{x:34.6244,y:-82.779119},
{x:40.605064,y:-73.75423},
{x:34.943549,y:-83.75056},
{x:34.238995,y:-84.225512},
{x:35.570015,y:-88.81438},
{x:41.469938,y:-81.901146},
{x:38.852078,y:-77.042082},
{x:42.368108,y:-71.258381},
{x:33.638501,y:-84.442386},
{x:40.785232,y:-73.978641},
{x:42.775935,y:-84.408626},
{x:39.108026,y:-84.850895},
{x:42.336035,y:-83.040934},
{x:44.448151,y:-73.105564},
{x:37.958755,y:-82.019641},
{x:38.325118,y:-81.58847},
{x:40.751283,y:-73.990852},
{x:40.620241,y:-73.989294},
{x:40.863864,y:-82.321886},
{x:39.917864,y:-85.383739},
{x:36.511987,y:-87.270586},
{x:39.286657,y:-76.6197},
{x:27.712146,y:-82.325313},
{x:41.113543,y:-111.977631},
{x:35.995552,y:-80.031706},
{x:35.903136,y:-79.95184},
{x:43.038187,y:-71.455462},
{x:42.280401,y:-87.905763},
{x:41.005695,y:-76.450282},
{x:35.855736,y:-90.66051},
{x:26.303379,y:-98.180746},
{x:41.482703,y:-88.159758},
{x:42.240236,y:-71.003623},
{x:29.915406,y:-81.363002},
{x:33.775095,y:-84.400543},
{x:33.002322,y:-96.767177},
{x:37.364815,y:-121.922516},
{x:42.621403,y:-83.264809},
{x:30.011615,y:-95.800238},
{x:40.835646,y:-73.943431},
{x:40.797434,y:-77.856177},
{x:32.814155,y:-96.839369},
{x:36.050159,y:-94.201228},
{x:36.196144,y:-94.184395},
{x:35.936813,y:-86.819688},
{x:36.11889,y:-115.164831}];