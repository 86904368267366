import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { CoreSchedule, LunchSlot } from 'types';

import LunchWavesBuilder from '../lunchWavesBuilder/LunchWavesBuilder';
import StepNavigator from '../StepNavigator';

interface Props {
  schedule: Partial<CoreSchedule>;
  slots: LunchSlot[];
  isAlphabetic: boolean;
  onPrev: (update: Partial<CoreSchedule>) => void;
  onNext: (update: Partial<CoreSchedule>) => void;
}

const ScheduleLunchWavesStep = ({
  schedule,
  slots,
  isAlphabetic,
  onPrev,
  onNext,
}: Props) => {
  const [lunchWaves, setLunchWaves] = useState(schedule.lunchWaves || []);

  return (
    <Container>
      <M.Title order={5}>Set up lunch waves</M.Title>

      <LunchWavesBuilder
        slots={slots}
        isAlphabetic={isAlphabetic}
        lunchWaves={lunchWaves}
        setLunchWaves={setLunchWaves}
      />

      <StepNavigator
        onPrev={() => onPrev({ lunchWaves })}
        canGoNext={slots.every((s) =>
          lunchWaves.find((l) => l.slot.id === s.id),
        )}
        onNext={() => onNext({ lunchWaves })}
        onSkip={() => onNext({ lunchWaves: undefined })}
      />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

export default ScheduleLunchWavesStep;
