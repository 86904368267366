export default [{x:36.01457833,y:-106.06501137},
{x:39.19413699,y:-76.614032},
{x:41.30539276,y:-96.05201011},
{x:39.698859,y:-104.90529969},
{x:33.77509118,y:-118.19030665},
{x:37.64153091,y:-93.10385647},
{x:33.43543683,y:-111.71953619},
{x:34.79107859,y:-92.22937433},
{x:39.43927858,y:-92.43251147},
{x:35.37343439,y:-77.95484081},
{x:34.23270891,y:-79.80210925},
{x:29.87027312,y:-95.3284911},
{x:38.46392018,y:-107.8667146},
{x:34.7607,y:-92.4535},
{x:33.55784679,y:-86.88956658},
{x:36.34100325,y:-84.16921712},
{x:34.15518959,y:-114.27689768},
{x:39.48002164,y:-84.4765833},
{x:36.79364223,y:-119.71698721},
{x:29.36460898,y:-98.43982782},
{x:26.10159424,y:-80.36456622},
{x:35.38117112,y:-96.92929119},
{x:36.03472192,y:-83.43200503},
{x:46.01156999,y:-92.92802274},
{x:34.07002544,y:-117.43653341},
{x:32.72052816,y:-102.65889384},
{x:39.74274481,y:-104.4338848},
{x:36.2090481,y:-119.34171436},
{x:36.2179564,y:-115.21345393},
{x:42.44788925,y:-83.06339703},
{x:33.11238277,y:-83.25743978},
{x:45.42654229,y:-122.4990765},
{x:26.93384036,y:-80.12700856},
{x:40.7354106,y:-73.9927669},
{x:33.18213685,y:-117.29155963},
{x:34.96820554,y:-81.89242803},
{x:39.76967316,y:-105.03801133},
{x:45.58110248,y:-122.71929254},
{x:40.037961,y:-76.33983403},
{x:33.50631255,y:-112.27312361},
{x:34.181489,y:-103.344099},
{x:35.8174582,y:-78.59793806},
{x:45.12426333,y:-93.35813276},
{x:29.4419868,y:-98.66789561},
{x:38.96424026,y:-92.28892682},
{x:41.24249647,y:-73.03083028},
{x:33.69690488,y:-86.60679752},
{x:26.28647393,y:-80.24826239},
{x:30.34963468,y:-97.55696485},
{x:34.96795052,y:-81.93305014},
{x:33.74002997,y:-84.41445395},
{x:35.10018291,y:-106.70643926},
{x:39.48407734,y:-88.17966169},
{x:33.76986354,y:-117.9207927},
{x:45.49788297,y:-122.61166947},
{x:42.33658795,y:-83.38531315},
{x:34.10683888,y:-117.81915039},
{x:35.66402882,y:-109.05583329},
{x:39.20001474,y:-119.77834066},
{x:42.18007148,y:-86.26249767},
{x:38.97483065,y:-76.27948596},
{x:32.84043598,y:-97.34230293},
{x:40.30272541,y:-76.81251664},
{x:40.0237049,y:-82.44945921},
{x:43.29202104,y:-84.5860575},
{x:35.46302737,y:-83.31318795},
{x:40.48757181,y:-79.89500378},
{x:33.45111564,y:-112.13414504},
{x:39.95683752,y:-83.04169156},
{x:38.01035937,y:-121.86935802},
{x:41.6446893,y:-73.92333545},
{x:35.21961037,y:-114.03541137},
{x:35.38566279,y:-94.4036454},
{x:34.61538076,y:-92.49885549},
{x:32.32585303,y:-86.32691858},
{x:40.02836783,y:-81.56616275},
{x:42.39195155,y:-83.13931167},
{x:34.98350616,y:-118.94890037},
{x:36.90589225,y:-111.48538887},
{x:30.13899319,y:-85.59155345},
{x:45.6655794,y:-108.77058417},
{x:45.75188736,y:-108.6168956},
{x:28.59691448,y:-81.30659312},
{x:36.8086715,y:-119.82724987},
{x:32.2347,y:-97.7664},
{x:34.17765211,y:-84.4218099},
{x:34.60104444,y:-83.51778554},
{x:40.59128444,y:-73.65800808},
{x:34.20794123,y:-118.62382042},
{x:36.86122718,y:-89.58475129},
{x:37.25353182,y:-121.79807021},
{x:40.87200947,y:-74.64458838},
{x:33.62944882,y:-117.66558816},
{x:45.33656275,y:-118.06058969},
{x:39.41684194,y:-76.77594243},
{x:34.43637443,y:-100.23243485},
{x:46.48979,y:-87.69145},
{x:42.66063386,y:-83.39455873},
{x:32.19604108,y:-81.1975774},
{x:33.20793805,y:-92.68334335},
{x:36.3648156,y:-78.98332834},
{x:34.41989199,y:-117.28794426},
{x:36.57668015,y:-86.52607698},
{x:36.14145551,y:-85.26785666},
{x:40.16481652,y:-76.15554188},
{x:38.07992083,y:-120.55579846},
{x:33.99988734,y:-118.08160891},
{x:44.85143612,y:-93.43204503},
{x:34.18570448,y:-118.64315987},
{x:33.9399779,y:-117.26106946},
{x:42.24022921,y:-85.559611},
{x:36.73915229,y:-108.17798798},
{x:32.42953532,y:-80.71891912},
{x:39.53194903,y:-107.32492983},
{x:29.71976316,y:-98.16380531},
{x:38.78254126,y:-76.89640566},
{x:34.93396465,y:-89.99996497},
{x:35.39380134,y:-119.09291528},
{x:35.57974131,y:-97.63801319},
{x:34.24964048,y:-118.50305805},
{x:28.59095015,y:-80.80342125},
{x:37.01397957,y:-121.59138501},
{x:34.61133299,y:-92.53189075},
{x:40.72773085,y:-73.22657972},
{x:25.7605794,y:-80.41839569},
{x:37.23985358,y:-121.90412111},
{x:33.33545612,y:-111.85990557},
{x:36.60438233,y:-119.43126075},
{x:34.24108469,y:-111.32181821},
{x:25.86016004,y:-80.32787114},
{x:38.85628961,y:-91.94452107},
{x:40.42094965,y:-104.79189992},
{x:41.43907336,y:-97.37050572},
{x:30.22636411,y:-85.58955673},
{x:43.45861331,y:-71.55821823},
{x:38.65888776,y:-87.5062496},
{x:41.74231508,y:-72.71143824},
{x:42.48131366,y:-96.41266018},
{x:36.33893847,y:-82.37368584},
{x:43.05411049,y:-76.07469276},
{x:43.23630787,y:-70.88147894},
{x:32.40183807,y:-110.95424211},
{x:29.91469185,y:-95.41387389},
{x:33.88543834,y:-117.34895221},
{x:28.05623483,y:-81.81887219},
{x:27.80509643,y:-82.63894714},
{x:40.6893493,y:-73.8458061},
{x:35.47888389,y:-80.61215311},
{x:36.73235663,y:-108.23604546},
{x:40.16021544,y:-104.98269812},
{x:37.31417153,y:-121.79288824},
{x:31.59780741,y:-84.1763361},
{x:35.9973883,y:-115.1277426},
{x:38.7171415,y:-76.65687},
{x:39.37436296,y:-76.50797863},
{x:30.59908908,y:-87.16322284},
{x:39.98728897,y:-104.75550346},
{x:37.70697368,y:-122.41447471},
{x:33.97798533,y:-117.45732963},
{x:39.78368881,y:-84.18866426},
{x:31.61691178,y:-81.87382092},
{x:40.68715302,y:-75.24944325},
{x:32.38127243,y:-86.21568947},
{x:39.27785232,y:-76.55573758},
{x:40.63951388,y:-74.26280517},
{x:33.56344645,y:-83.4796368},
{x:33.16068619,y:-117.346057},
{x:44.73246837,y:-93.22175469},
{x:28.81991653,y:-81.67213984},
{x:42.21600434,y:-73.87808668},
{x:33.52372609,y:-86.72510586},
{x:26.15527305,y:-81.68906271},
{x:35.23142204,y:-81.33266945},
{x:35.21695324,y:-94.26962882},
{x:31.10226008,y:-97.35637128},
{x:42.44962056,y:-85.67205101},
{x:33.999824,y:-84.58854944},
{x:36.09583522,y:-95.97621789},
{x:39.40632507,y:-77.38667472},
{x:44.78042874,y:-93.50320047},
{x:39.9400279,y:-83.11173981},
{x:29.7871837,y:-95.68800896},
{x:33.98718767,y:-83.98494833},
{x:32.96550297,y:-97.41369277},
{x:39.30468506,y:-75.60514547},
{x:37.40419848,y:-121.99518541},
{x:37.51140449,y:-122.26503946},
{x:39.10276957,y:-94.44573655},
{x:40.11050761,y:-74.87808936},
{x:37.32297139,y:-121.96588307},
{x:34.82061678,y:-92.2331319},
{x:44.8973855,y:-93.08015629},
{x:28.3400281,y:-81.59762849},
{x:46.47489514,y:-84.35584594},
{x:37.37562591,y:-122.01325853},
{x:33.46621417,y:-112.39300854},
{x:26.50763218,y:-80.05986887},
{x:34.42600651,y:-103.19611122},
{x:38.81231735,y:-94.46951777},
{x:32.88107936,y:-117.15850564},
{x:36.1501,y:-81.1795},
{x:32.20402319,y:-95.85347436},
{x:39.07517095,y:-76.84910132},
{x:33.4959651,y:-112.15227634},
{x:37.69277883,y:-122.46474057},
{x:33.2469326,y:-111.79002259},
{x:31.35198774,y:-89.23734605},
{x:36.32845618,y:-119.67521147},
{x:48.42398058,y:-101.33741828},
{x:44.56809023,y:-69.63801348},
{x:35.2914844,y:-93.1330508},
{x:35.31741689,y:-119.02342241},
{x:34.13908763,y:-118.20492424},
{x:35.20430185,y:-87.06044517},
{x:33.73380861,y:-117.18831466},
{x:32.21018653,y:-82.39002174},
{x:26.61774398,y:-81.66949962},
{x:40.37678448,y:-82.82968726},
{x:39.09559291,y:-94.55213828},
{x:39.73991799,y:-104.8626405},
{x:38.78676021,y:-90.35157207},
{x:33.69747411,y:-78.8783956},
{x:41.282965,y:-84.32724},
{x:34.58044181,y:-92.5739168},
{x:39.57576132,y:-119.73932593},
{x:42.72207168,y:-84.42434676},
{x:40.33851378,y:-76.44844468},
{x:33.85793852,y:-118.30157888},
{x:34.96230642,y:-90.00829822},
{x:36.06037076,y:-79.10264303},
{x:42.68941697,y:-73.85037585},
{x:34.04753019,y:-84.22433225},
{x:38.46408614,y:-90.52373807},
{x:40.83379867,y:-75.29751632},
{x:28.27720076,y:-82.67382234},
{x:34.10689906,y:-80.90085228},
{x:39.65898892,y:-104.84897558},
{x:29.99478635,y:-95.48507988},
{x:46.87095364,y:-96.76826385},
{x:33.5202371,y:-101.95838861},
{x:38.40862629,y:-121.37920557},
{x:41.56793602,y:-73.00756752},
{x:45.9362,y:-118.388},
{x:39.99358938,y:-75.09572015},
{x:25.91636723,y:-97.47133192},
{x:36.517623,y:-119.563521},
{x:34.26063039,y:-92.10114598},
{x:42.88062254,y:-85.76345293},
{x:40.03687589,y:-74.6174565},
{x:28.00918615,y:-82.50455216},
{x:30.28328834,y:-97.70391665},
{x:35.56817489,y:-97.66277406},
{x:41.72159028,y:-83.59903984},
{x:29.82632038,y:-97.98610381},
{x:35.95968614,y:-95.39536732},
{x:42.6773,y:-83.2838},
{x:34.701002,y:-81.191213},
{x:31.86025773,y:-106.55847094},
{x:31.9613589,y:-83.75691858},
{x:34.08115207,y:-118.09035223},
{x:40.50627359,y:-74.64116576},
{x:35.25743213,y:-116.68546957},
{x:40.22386417,y:-74.26820919},
{x:36.48808442,y:-79.73871723},
{x:38.92715598,y:-121.08725211},
{x:28.45057218,y:-81.36040029},
{x:34.19999853,y:-77.92180248},
{x:39.27907636,y:-84.56334163},
{x:38.96551141,y:-84.0924522},
{x:42.9474,y:-74.3582},
{x:38.01325854,y:-92.7400495},
{x:33.17737716,y:-96.88681059},
{x:33.36584689,y:-97.17873187},
{x:35.63578345,y:-87.01500084},
{x:36.0776489,y:-80.1090835},
{x:33.71830056,y:-84.76882607},
{x:35.99370006,y:-80.26941084},
{x:39.22112213,y:-76.61346656},
{x:32.25550126,y:-80.84758819},
{x:28.60745137,y:-81.42485409},
{x:34.67292567,y:-88.5613143},
{x:34.4422797,y:-119.83033836},
{x:39.20192073,y:-121.17111648},
{x:39.45069273,y:-83.79839502},
{x:32.76969135,y:-117.14309961},
{x:39.03222912,y:-76.91209049},
{x:36.51665527,y:-84.50639337},
{x:39.89875498,y:-80.14647603},
{x:36.07020162,y:-83.92685928},
{x:38.0367053,y:-84.45383549},
{x:64.83549133,y:-147.81328492},
{x:45.67210897,y:-111.07616566},
{x:26.55520462,y:-81.87101901},
{x:42.79016157,y:-83.73566785},
{x:42.83239285,y:-78.75319559},
{x:38.88963613,y:-94.60742756},
{x:44.31406345,y:-69.80110919},
{x:34.62373459,y:-86.56790582},
{x:41.64924166,y:-83.52161072},
{x:33.48541306,y:-82.1336603},
{x:36.052941,y:-78.90507217},
{x:28.45257587,y:-81.47024814},
{x:32.17045685,y:-81.90157634},
{x:33.59457016,y:-86.69442415},
{x:41.45890772,y:-81.86124325},
{x:29.18844818,y:-81.07624427},
{x:21.27682,y:-157.787042},
{x:40.70068399,y:-73.90258169},
{x:27.99582921,y:-82.43097046},
{x:33.62589971,y:-112.01394544},
{x:40.72535645,y:-74.23419175},
{x:41.58815881,y:-81.50077037},
{x:38.36469883,y:-122.71365345},
{x:29.63726564,y:-82.33892808},
{x:42.80686074,y:-83.62463296},
{x:35.73600658,y:-78.65103658},
{x:39.23547643,y:-121.03791761},
{x:35.70124935,y:-81.28842424},
{x:38.44620728,y:-90.37236571},
{x:41.61250758,y:-87.09839997},
{x:34.54209799,y:-86.92164991},
{x:34.04460993,y:-118.32891627},
{x:35.54665367,y:-97.61367888},
{x:40.33927887,y:-83.7624931},
{x:36.30375826,y:-86.69201962},
{x:29.43806087,y:-98.45177548},
{x:41.17383187,y:-83.64744995},
{x:33.45073059,y:-86.98456048},
{x:30.53367336,y:-87.27316802},
{x:33.93410882,y:-85.61058261},
{x:44.65396196,y:-84.70769584},
{x:40.08407221,y:-82.8950429},
{x:41.14377179,y:-73.8608464},
{x:38.25416225,y:-94.35486741},
{x:33.81679948,y:-118.02770957},
{x:44.79064904,y:-93.20856839},
{x:32.46009702,y:-96.81819153},
{x:27.78881006,y:-82.72889279},
{x:38.74287092,y:-104.73705947},
{x:26.6751174,y:-80.66995488},
{x:37.6527507,y:-122.09002727},
{x:35.13195171,y:-79.02532851},
{x:37.84706784,y:-122.26125451},
{x:35.0694865,y:-91.9072652},
{x:42.97896618,y:-83.69260767},
{x:38.85329686,y:-104.75783506},
{x:40.78918205,y:-80.7763092},
{x:32.98907469,y:-80.10040104},
{x:40.01226272,y:-75.13549572},
{x:29.78064642,y:-95.53992946},
{x:33.72973188,y:-117.99834797},
{x:33.49780228,y:-86.87602754},
{x:33.31195435,y:-96.78019583},
{x:36.09595064,y:-79.40729141},
{x:35.32391562,y:-91.5619935},
{x:35.83520002,y:-77.06567734},
{x:41.23028407,y:-73.22649806},
{x:27.92276848,y:-82.32162037},
{x:39.06762801,y:-93.73320361},
{x:45.98149426,y:-112.50866273},
{x:35.33825091,y:-81.90023738},
{x:45.25785232,y:-122.70045703},
{x:34.01155735,y:-117.43644742},
{x:29.64194797,y:-81.6647514},
{x:41.38165503,y:-81.7829477},
{x:30.63544728,y:-97.68930316},
{x:29.99572251,y:-95.62410682},
{x:42.7059001,y:-83.2414515},
{x:40.305896,y:-79.592196},
{x:41.57232062,y:-84.13413763},
{x:35.27886725,y:-81.54090268},
{x:40.94400533,y:-74.20303935},
{x:33.90880922,y:-84.20690171},
{x:42.81166301,y:-85.98939847},
{x:34.91453905,y:-80.98459243},
{x:38.21754602,y:-104.6229127},
{x:34.22082044,y:-118.47193033},
{x:35.89443456,y:-78.50853469},
{x:34.29628977,y:-77.79521629},
{x:39.885027,y:-104.807315},
{x:36.70399501,y:-121.65337301},
{x:33.91608864,y:-83.44928093},
{x:28.66832685,y:-82.13698491},
{x:35.63581375,y:-78.83095175},
{x:34.67026068,y:-79.1805829},
{x:36.14895949,y:-82.41659652},
{x:27.79647061,y:-82.33415008},
{x:40.02882558,y:-75.62948578},
{x:43.50782953,y:-70.43563143},
{x:36.02248568,y:-78.95267032},
{x:39.33806067,y:-84.55864679},
{x:35.58353429,y:-80.98734049},
{x:34.01946766,y:-118.15410999},
{x:39.01239193,y:-94.1269809},
{x:40.04265237,y:-75.14465107},
{x:38.01026428,y:-122.27132191},
{x:36.62678745,y:-87.37362451},
{x:40.3562,y:-80.1197},
{x:40.89873964,y:-74.03775974},
{x:46.84349899,y:-96.88408175},
{x:39.0864413,y:-94.62531844},
{x:30.48259156,y:-86.41599865},
{x:35.29223963,y:-93.71697573},
{x:44.83829275,y:-93.79817849},
{x:39.99934996,y:-75.13296009},
{x:33.03343225,y:-116.8835274},
{x:39.10459073,y:-94.51143799},
{x:38.79720326,y:-90.62123139},
{x:38.19234119,y:-120.82577855},
{x:36.02096277,y:-115.12727299},
{x:41.26507085,y:-96.23703501},
{x:40.05363491,y:-74.14085708},
{x:38.48426687,y:-90.76056181},
{x:39.03369262,y:-82.62661587},
{x:33.60842256,y:-112.41430701},
{x:33.368,y:-87.0048},
{x:41.35923979,y:-83.14333324},
{x:33.82367653,y:-84.10772882},
{x:33.85405416,y:-118.18522364},
{x:36.48073455,y:-82.49539867},
{x:38.745554,y:-121.225728},
{x:39.67738658,y:-84.24042173},
{x:36.11840355,y:-84.11809736},
{x:33.80204066,y:-84.04311551},
{x:45.52651651,y:-122.53934533},
{x:35.83562805,y:-78.58137823},
{x:28.445597,y:-81.559359},
{x:34.82413944,y:-82.55927861},
{x:34.86999408,y:-85.51889202},
{x:44.06125761,y:-121.30195548},
{x:35.78929699,y:-83.55763087},
{x:33.60798838,y:-83.82737692},
{x:41.48607718,y:-75.69382399},
{x:36.5936223,y:-87.2883756},
{x:36.92476187,y:-120.02676107},
{x:42.53299346,y:-83.1073343},
{x:35.96835213,y:-86.49511222},
{x:28.68628646,y:-81.53446823},
{x:28.12962391,y:-81.97425142},
{x:44.99177217,y:-123.02605567},
{x:34.92233885,y:-79.74981309},
{x:30.4600749,y:-84.35661077},
{x:35.61248476,y:-97.5664184},
{x:37.32878248,y:-121.85891122},
{x:40.74783203,y:-84.14573014},
{x:37.92846112,y:-90.77562555},
{x:42.09826888,y:-79.30401088},
{x:35.288115,y:-81.018574},
{x:32.84304824,y:-97.20224545},
{x:30.36656439,y:-95.4823673},
{x:34.98388662,y:-78.96483607},
{x:40.68399822,y:-99.084194},
{x:36.13370635,y:-79.7891777},
{x:32.73192635,y:-97.37246744},
{x:35.15462093,y:-90.13782665},
{x:41.70290899,y:-122.64434516},
{x:34.62381045,y:-98.42910294},
{x:25.62591031,y:-80.41316316},
{x:36.16034527,y:-95.88991103},
{x:43.10814155,y:-75.21970647},
{x:43.0487606,y:-76.23118567},
{x:38.77131675,y:-76.05974436},
{x:28.69748854,y:-81.34655333},
{x:34.49589214,y:-118.62475205},
{x:39.93293229,y:-75.03073946},
{x:34.102393,y:-117.300255},
{x:42.11214824,y:-72.60366348},
{x:36.3125494,y:-119.78117616},
{x:40.59211239,y:-74.16326016},
{x:32.47394576,y:-84.98821273},
{x:32.99844215,y:-96.90886346},
{x:38.1150561,y:-121.30399261},
{x:40.5430935,y:-74.17403695},
{x:40.7529449,y:-73.9926834},
{x:33.85873973,y:-117.9757226},
{x:35.11334544,y:-89.80079059},
{x:33.96647033,y:-84.01034824},
{x:30.78866924,y:-81.65040806},
{x:39.67403928,y:-104.79002506},
{x:35.48359715,y:-80.87746664},
{x:40.96789088,y:-80.30946084},
{x:45.1069887,y:-87.6190027},
{x:40.41677541,y:-79.84873362},
{x:33.9951,y:-83.3367},
{x:28.59831098,y:-81.20885427},
{x:35.9548603,y:-83.93603504},
{x:40.85956101,y:-73.97133322},
{x:34.16203492,y:-117.33429931},
{x:46.59043234,y:-112.01519213},
{x:33.61549908,y:-84.47322343},
{x:36.07832911,y:-86.9515636},
{x:34.25442689,y:-88.75108812},
{x:30.28638967,y:-81.527988},
{x:38.69112275,y:-90.3898564},
{x:33.37215654,y:-117.2536844},
{x:39.92548105,y:-82.88048652},
{x:41.17845209,y:-96.04246974},
{x:32.89279523,y:-97.28746792},
{x:33.71116793,y:-112.20258698},
{x:32.83915255,y:-109.73766651},
{x:28.78272929,y:-81.74928865},
{x:33.74817216,y:-116.90253474},
{x:39.94508409,y:-104.93975193},
{x:39.22611551,y:-84.4385597},
{x:34.70357061,y:-118.14846396},
{x:41.23790111,y:-81.80281962},
{x:42.97181712,y:-73.7940307},
{x:29.48347286,y:-98.6664723},
{x:45.54371631,y:-122.3998387},
{x:34.78697226,y:-82.4802174},
{x:37.7785748,y:-122.27637658},
{x:30.64416933,y:-81.45870067},
{x:35.76961458,y:-78.78220687},
{x:41.289,y:-73.8408},
{x:34.60928429,y:-79.67594271},
{x:40.88004874,y:-73.90439337},
{x:37.81920514,y:-92.14264266},
{x:35.49297534,y:-97.51663153},
{x:28.46126139,y:-82.53953218},
{x:37.28204472,y:-83.22724347},
{x:34.03186002,y:-83.88571669},
{x:37.89206664,y:-122.11660941},
{x:36.13873056,y:-96.11508076},
{x:39.75204564,y:-122.20739146},
{x:35.52936923,y:-82.60560294},
{x:33.42216088,y:-111.96004421},
{x:34.96473174,y:-89.79595528},
{x:33.78846685,y:-85.76192595},
{x:44.53186558,y:-68.40798378},
{x:33.16825206,y:-96.66266508},
{x:34.07382637,y:-117.67052208},
{x:33.82436751,y:-92.42601516},
{x:36.37108118,y:-85.34044532},
{x:40.84385638,y:-73.9034148},
{x:41.70606212,y:-83.6234111},
{x:33.95061465,y:-78.04995905},
{x:35.20947783,y:-118.82833559},
{x:42.29831878,y:-83.28290373},
{x:38.72153272,y:-90.36962606},
{x:35.49710534,y:-97.74209359},
{x:32.28269246,y:-95.33151369},
{x:40.2618572,y:-75.73238903},
{x:41.0700985,y:-80.05234},
{x:29.76128706,y:-96.15191558},
{x:26.66261476,y:-80.26745456},
{x:35.50017821,y:-119.2785575},
{x:42.64184735,y:-83.03241761},
{x:38.45466349,y:-122.77001024},
{x:33.82168376,y:-116.40630149},
{x:36.18531014,y:-80.274687},
{x:35.43946598,y:-95.97529567},
{x:30.4871451,y:-84.31548138},
{x:38.25775132,y:-85.75384671},
{x:40.74928791,y:-73.99528261},
{x:38.8624663,y:-77.3577301},
{x:38.30451425,y:-104.61577454},
{x:34.83062495,y:-87.62708683},
{x:34.74713997,y:-77.38014353},
{x:31.26021043,y:-85.4375044},
{x:31.08087511,y:-97.45818619},
{x:27.9344711,y:-81.97345962},
{x:39.02309298,y:-104.81833649},
{x:40.28430146,y:-79.39146101},
{x:42.77483387,y:-73.89201522},
{x:44.31650946,y:-96.76214108},
{x:41.23622666,y:-73.03529765},
{x:38.94175942,y:-94.60772685},
{x:42.50607975,y:-82.93013282},
{x:44.86954756,y:-123.02298188},
{x:38.74379161,y:-121.17240344},
{x:39.11237941,y:-76.93417369},
{x:38.34548654,y:-91.49435741},
{x:33.98517773,y:-118.33058327},
{x:35.61797143,y:-106.02961291},
{x:41.5807,y:-83.8823},
{x:43.05614481,y:-71.44008146},
{x:45.53856989,y:-122.57922376},
{x:39.15941806,y:-77.15930253},
{x:35.962749,y:-87.134734},
{x:39.87794401,y:-75.34418866},
{x:29.00880889,y:-81.30220771},
{x:33.73824449,y:-116.21472269},
{x:39.25859464,y:-106.29367923},
{x:32.65927037,y:-97.1339554},
{x:33.97571615,y:-117.59074845},
{x:35.97917693,y:-77.80800717},
{x:32.69313263,y:-96.77411561},
{x:36.05738418,y:-115.17508117},
{x:33.15078574,y:-85.35986222},
{x:39.41732512,y:-84.47087936},
{x:35.45439734,y:-86.77763983},
{x:40.84240061,y:-73.28651775},
{x:40.87284386,y:-73.62188955},
{x:35.36700885,y:-80.71011804},
{x:43.35771015,y:-124.2125035},
{x:37.18267222,y:-93.29920841},
{x:42.60110614,y:-82.89998561},
{x:34.67602291,y:-92.34441139},
{x:33.37871734,y:-111.63387273},
{x:37.12699499,y:-120.24737298},
{x:42.45039385,y:-83.2000985},
{x:26.04777695,y:-80.15904446},
{x:34.94148812,y:-82.25895244},
{x:42.50048052,y:-83.28889338},
{x:45.45867888,y:-98.43785086},
{x:35.38286461,y:-119.0505678},
{x:35.22852947,y:-89.52235678},
{x:36.20292344,y:-119.10254314},
{x:34.04548918,y:-118.34522907},
{x:38.41199497,y:-90.38496901},
{x:38.83387675,y:-76.9430436},
{x:45.5369173,y:-122.53724961},
{x:40.24252942,y:-75.24203537},
{x:40.21188639,y:-75.01038259},
{x:32.80258611,y:-86.5864171},
{x:38.18512376,y:-122.25422368},
{x:36.08350316,y:-87.79956289},
{x:32.26751898,y:-107.76517153},
{x:44.04840812,y:-91.6757407},
{x:39.87294206,y:-84.05642997},
{x:36.48810694,y:-86.88127302},
{x:35.71913304,y:-81.4681733},
{x:38.61722147,y:-121.36331952},
{x:37.65362868,y:-122.40870465},
{x:34.59399524,y:-98.43875865},
{x:39.08482184,y:-84.48054404},
{x:32.69774087,y:-117.17031587},
{x:26.21065861,y:-81.76950725},
{x:40.46012123,y:-106.81976816},
{x:41.16398584,y:-81.47754144},
{x:27.96090031,y:-82.76161373},
{x:29.16673639,y:-80.99885885},
{x:44.45557331,y:-93.16455736},
{x:42.35859535,y:-83.46154167},
{x:35.97311902,y:-83.98453351},
{x:42.75499131,y:-71.20949426},
{x:27.56961085,y:-81.81684267},
{x:40.00077497,y:-105.01569236},
{x:39.91339132,y:-82.78012057},
{x:39.15557763,y:-84.62949059},
{x:31.62602854,y:-97.22959767},
{x:44.05865995,y:-123.00000081},
{x:33.46916958,y:-112.22117044},
{x:35.5820975,y:-89.64161932},
{x:35.08715063,y:-106.51544227},
{x:41.52582041,y:-83.63663643},
{x:40.7689057,y:-81.38400696},
{x:29.52102733,y:-97.4486544},
{x:43.24062,y:-78.19815},
{x:35.80692028,y:-86.3980703},
{x:32.93903899,y:-111.75536945},
{x:33.59190011,y:-101.93862645},
{x:64.85296237,y:-147.71383354},
{x:41.37994625,y:-82.06992567},
{x:42.93571782,y:-85.6952691},
{x:41.38634973,y:-81.97625586},
{x:43.11555517,y:-77.4913089},
{x:40.73530212,y:-74.18780878},
{x:48.20375195,y:-114.30690341},
{x:27.5387804,y:-99.50385169},
{x:33.53870522,y:-112.15373755},
{x:35.81091061,y:-84.26391899},
{x:37.10380714,y:-121.01530825},
{x:41.1204,y:-75.376},
{x:35.13614864,y:-90.01329892},
{x:33.72304576,y:-117.7939564},
{x:46.69952114,y:-68.01012263},
{x:38.88930887,y:-94.5261331},
{x:43.16321472,y:-83.5243732},
{x:33.43600783,y:-112.42363174},
{x:37.99645427,y:-88.92826154},
{x:33.3772169,y:-112.02837297},
{x:34.2722922,y:-118.48521531},
{x:40.00338095,y:-82.67087151},
{x:39.14607754,y:-76.80886768},
{x:41.52950087,y:-72.77785839},
{x:37.37057458,y:-118.3949989},
{x:40.65744585,y:-95.85989911},
{x:35.20880335,y:-80.75705938},
{x:35.65260093,y:-97.51264443},
{x:36.08567362,y:-94.12100067},
{x:40.27324436,y:-83.07024464},
{x:38.67464477,y:-90.37167176},
{x:33.59441313,y:-102.36150471},
{x:43.39821119,y:-84.6675083},
{x:36.31934572,y:-86.86631045},
{x:30.50502476,y:-84.24868772},
{x:35.70494942,y:-82.03590951},
{x:32.94442007,y:-103.36224757},
{x:32.73744872,y:-96.28315507},
{x:28.03210681,y:-82.42673881},
{x:30.72067302,y:-88.15761209},
{x:35.26772054,y:-81.48348189},
{x:44.09868244,y:-123.12385768},
{x:34.78004267,y:-77.3899717},
{x:32.7932819,y:-79.86503244},
{x:33.98946362,y:-118.22642792},
{x:33.57876111,y:-83.96172523},
{x:61.21792499,y:-149.83597908},
{x:36.41773821,y:-79.93360235},
{x:41.83654309,y:-71.42116293},
{x:32.19130961,y:-110.79181323},
{x:45.29989859,y:-93.79657968},
{x:30.78638889,y:-86.55980596},
{x:33.52632349,y:-117.71080886},
{x:42.56482901,y:-77.71278825},
{x:35.24507031,y:-81.66570462},
{x:34.22983775,y:-119.01698202},
{x:48.2329,y:-114.334},
{x:33.76489776,y:-118.15531914},
{x:31.783928,y:-82.363196},
{x:32.934361,y:-105.964557},
{x:30.4469963,y:-97.63839576},
{x:36.70864413,y:-119.55592901},
{x:32.54417241,y:-85.90615376},
{x:42.4952004,y:-83.535132},
{x:27.88085989,y:-82.82856963},
{x:38.90949323,y:-92.33195001},
{x:38.7578,y:-76.9962},
{x:40.48012093,y:-104.91981142},
{x:38.12975554,y:-121.28162747},
{x:34.19312002,y:-118.53636452},
{x:41.97016546,y:-73.98954333},
{x:34.088328,y:-118.30886893},
{x:39.35268513,y:-84.36629756},
{x:33.69996871,y:-116.21375442},
{x:38.55839256,y:-121.43725851},
{x:44.87773337,y:-94.37622703},
{x:33.37494521,y:-84.75347885},
{x:36.14591745,y:-78.72409348},
{x:33.35203294,y:-84.12077044},
{x:31.90578559,y:-110.98830965},
{x:39.77555286,y:-93.55176589},
{x:29.86842776,y:-81.32258099},
{x:33.51342854,y:-112.13066731},
{x:41.80577161,y:-72.55784288},
{x:40.75528842,y:-73.98945085},
{x:44.74547603,y:-93.27816775},
{x:39.52672221,y:-119.82776702},
{x:27.89412648,y:-82.27115974},
{x:38.97903425,y:-94.59460795},
{x:35.38068777,y:-119.0207868},
{x:33.64110143,y:-112.35411559},
{x:34.14453925,y:-84.51244626},
{x:33.23432603,y:-111.72255255},
{x:39.20969812,y:-94.65307742},
{x:35.25000002,y:-91.69197768},
{x:42.23015904,y:-83.64784952},
{x:39.1904496,y:-84.25404578},
{x:42.91548062,y:-78.75477433},
{x:27.00899915,y:-82.13644839},
{x:41.36681181,y:-82.11067236},
{x:28.4762,y:-81.6263},
{x:45.49370172,y:-122.57839841},
{x:45.048807,y:-122.9751514},
{x:42.86331221,y:-73.78036022},
{x:35.18370638,y:-83.35587927},
{x:38.93605908,y:-76.94740802},
{x:42.46009074,y:-83.14707801},
{x:34.39659184,y:-119.51342593},
{x:34.77120972,y:-84.92270722},
{x:33.87068769,y:-117.91961977},
{x:35.59204404,y:-78.77442151},
{x:39.88819593,y:-75.08831531},
{x:45.021568,y:-93.173593},
{x:35.03969382,y:-85.68549964},
{x:34.07581597,y:-118.37461649},
{x:38.38523226,y:-121.9343698},
{x:33.81338099,y:-118.36892679},
{x:33.61320755,y:-111.92643058},
{x:40.62597269,y:-73.73665188},
{x:37.29771101,y:-120.48142767},
{x:41.14266538,y:-80.16387378},
{x:30.75940165,y:-85.54742068},
{x:40.61590476,y:-74.07188261},
{x:31.32504332,y:-89.32719603},
{x:31.10474813,y:-97.7497242},
{x:40.51278618,y:-81.4550967},
{x:36.55363513,y:-86.23635701},
{x:35.02945168,y:-90.79010636},
{x:44.78266244,y:-68.74693711},
{x:28.59228562,y:-81.36473536},
{x:38.6512993,y:-90.26330361},
{x:33.1735667,y:-96.69555886},
{x:29.79599519,y:-82.49983375},
{x:28.19888154,y:-81.44054559},
{x:46.9052778,y:-96.80284072},
{x:38.26014261,y:-76.46739873},
{x:42.29253446,y:-71.77854717},
{x:36.73748053,y:-119.69962299},
{x:32.82000511,y:-116.96195796},
{x:41.58128313,y:-81.21314903},
{x:38.67198177,y:-121.1603643},
{x:35.24686593,y:-91.77405515},
{x:37.63808689,y:-91.5350828},
{x:43.48309444,y:-83.40351686},
{x:39.12006896,y:-76.63608693},
{x:37.9808004,y:-122.05586534},
{x:33.68581776,y:-117.97170371},
{x:39.92568419,y:-75.30925956},
{x:34.27920873,y:-118.88363291},
{x:39.17667507,y:-76.84292619},
{x:40.14013051,y:-82.98090845},
{x:33.65216428,y:-112.2573068},
{x:38.48761,y:-76.0798},
{x:33.78964524,y:-118.1621616},
{x:41.10154974,y:-74.0246416},
{x:44.07735018,y:-70.18886088},
{x:34.67729967,y:-118.15596157},
{x:41.72179755,y:-74.3925789},
{x:38.72983307,y:-93.5650279},
{x:34.96284586,y:-89.85560288},
{x:35.73440944,y:-81.72792873},
{x:41.17819167,y:-83.42229545},
{x:42.39356807,y:-83.51122462},
{x:36.08440887,y:-115.03147155},
{x:44.6812105,y:-74.98736624},
{x:26.19420038,y:-80.25327891},
{x:41.13788726,y:-96.02827258},
{x:33.94521992,y:-118.30064486},
{x:33.08855304,y:-98.58603216},
{x:38.35751584,y:-90.15448563},
{x:40.72988763,y:-73.95064734},
{x:42.61484296,y:-83.44493113},
{x:35.58960397,y:-84.47244304},
{x:41.52009894,y:-81.51647196},
{x:41.79542637,y:-86.08081571},
{x:35.60816353,y:-91.2522904},
{x:34.75377126,y:-89.4531913},
{x:25.84757961,y:-80.18376678},
{x:45.5556111,y:-122.83532113},
{x:36.39301532,y:-97.89064476},
{x:40.8567,y:-74.1237},
{x:40.37173859,y:-80.68859003},
{x:35.23439774,y:-92.38737635},
{x:33.37842703,y:-111.75341113},
{x:33.92281058,y:-83.34047735},
{x:25.59790744,y:-80.3540951},
{x:35.17018808,y:-78.97132903},
{x:42.41181826,y:-82.91333452},
{x:31.54850096,y:-87.87966793},
{x:41.67443285,y:-83.71705064},
{x:28.34398084,y:-82.18680963},
{x:35.46528023,y:-80.59029685},
{x:40.6270331,y:-75.44292212},
{x:32.23559682,y:-110.94461946},
{x:27.24024004,y:-80.27085234},
{x:35.00998233,y:-85.15450239},
{x:37.34921725,y:-108.56907143},
{x:32.3062866,y:-111.01287027},
{x:39.2281286,y:-84.39404815},
{x:30.68434046,y:-88.22349049},
{x:35.96531163,y:-78.96017259},
{x:40.79812076,y:-74.14759566},
{x:33.27883551,y:-115.96561054},
{x:30.30470398,y:-89.38360959},
{x:37.69402867,y:-122.07418681},
{x:27.93729983,y:-82.25040928},
{x:26.0692422,y:-80.23178637},
{x:39.98294685,y:-82.81683236},
{x:36.73573564,y:-90.39699281},
{x:42.15381431,y:-77.10424223},
{x:39.78958561,y:-105.02485782},
{x:61.3299422,y:-149.5684296},
{x:45.02038429,y:-93.49397385},
{x:35.65313272,y:-97.45762829},
{x:33.82466572,y:-118.27649747},
{x:32.52317384,y:-94.78531301},
{x:36.301548,y:-82.367064},
{x:41.35611898,y:-74.2833092},
{x:35.03965526,y:-90.06955504},
{x:36.0004292,y:-83.77861083},
{x:36.17549277,y:-94.21652274},
{x:39.00464302,y:-84.62074359},
{x:34.40184534,y:-119.72178064},
{x:38.92534955,y:-94.39948018},
{x:32.64007498,y:-117.09702996},
{x:41.6776564,y:-83.62305394},
{x:24.71817257,y:-81.06717624},
{x:40.68865533,y:-74.30817895},
{x:39.8846575,y:-104.97080252},
{x:35.26034015,y:-81.21464982},
{x:43.43724058,y:-84.01602656},
{x:39.79893101,y:-104.88161553},
{x:42.79950592,y:-82.74127993},
{x:34.72096061,y:-85.27970575},
{x:43.15173683,y:-76.12534294},
{x:42.15506261,y:-76.87981635},
{x:39.43670053,y:-119.7579959},
{x:39.88657417,y:-104.88189071},
{x:36.34399718,y:-119.65540757},
{x:38.8193916,y:-91.14141136},
{x:39.5683818,y:-105.10944664},
{x:40.05623956,y:-74.88787662},
{x:40.08763794,y:-74.96529807},
{x:39.54235219,y:-119.73930278},
{x:34.13569993,y:-117.28929835},
{x:42.73741305,y:-73.76279387},
{x:34.74873811,y:-92.40778778},
{x:39.72759471,y:-84.05459498},
{x:40.25324492,y:-74.30017393},
{x:33.74435211,y:-118.29200607},
{x:35.34603329,y:-96.91244663},
{x:40.7471391,y:-73.59445632},
{x:35.86182979,y:-84.07730326},
{x:34.92252699,y:-117.92648838},
{x:38.7464189,y:-90.63441485},
{x:25.8120748,y:-80.3706812},
{x:36.73614007,y:-119.75661701},
{x:39.96931602,y:-74.88460569},
{x:34.06924902,y:-117.97969304},
{x:35.40025025,y:-80.60902566},
{x:35.3912474,y:-97.70476504},
{x:36.05268571,y:-83.9909385},
{x:41.14063409,y:-95.97507871},
{x:37.68964688,y:-122.13063508},
{x:40.28534225,y:-81.56645941},
{x:42.45780304,y:-83.27920005},
{x:36.65231305,y:-93.28616564},
{x:35.83574122,y:-79.09055729},
{x:36.25462985,y:-86.65270506},
{x:42.5253197,y:-82.90784268},
{x:27.03905968,y:-82.22432971},
{x:36.27606506,y:-115.14530544},
{x:33.52668688,y:-90.20660047},
{x:42.24904816,y:-83.65285828},
{x:35.6738,y:-119.2273},
{x:40.20110294,y:-77.18047916},
{x:32.20751445,y:-110.85758172},
{x:27.26421821,y:-80.40447754},
{x:32.68878897,y:-95.48358075},
{x:35.52477724,y:-97.54426871},
{x:40.83681065,y:-73.41134787},
{x:41.53702885,y:-73.00463189},
{x:37.7545786,y:-121.13962568},
{x:25.8464411,y:-80.24396191},
{x:40.67014002,y:-80.58458837},
{x:42.44135391,y:-83.4345227},
{x:33.9522615,y:-80.37815295},
{x:40.24874298,y:-74.77043331},
{x:26.45726,y:-80.12403},
{x:38.93978055,y:-76.56381871},
{x:36.16591888,y:-84.08423379},
{x:32.40781979,y:-80.63921263},
{x:40.39948066,y:-74.22754398},
{x:31.68218102,y:-106.20205902},
{x:36.34400555,y:-86.52824871},
{x:28.80743932,y:-82.57588514},
{x:30.60670342,y:-87.06246883},
{x:37.45907147,y:-122.13729402},
{x:30.63995901,y:-88.18682671},
{x:42.35000651,y:-83.27328179},
{x:35.8704192,y:-78.62187894},
{x:33.42380136,y:-86.66897602},
{x:33.00967584,y:-96.61350496},
{x:39.85334255,y:-75.02513967},
{x:35.4120452,y:-80.85503835},
{x:43.67487713,y:-121.50078162},
{x:40.20994371,y:-74.02697041},
{x:43.23386121,y:-86.20837729},
{x:33.81722533,y:-118.07128702},
{x:34.57320176,y:-87.01619156},
{x:39.92437763,y:-105.09144822},
{x:39.1270416,y:-84.50307468},
{x:33.80365784,y:-118.18984256},
{x:36.60844465,y:-93.23387884},
{x:32.49202235,y:-82.93276817},
{x:36.98620611,y:-121.96465865},
{x:33.5813555,y:-112.426428},
{x:42.67539269,y:-82.76109713},
{x:40.0326938,y:-75.0631896},
{x:39.00723358,y:-94.27201197},
{x:29.69136055,y:-95.77157642},
{x:30.23126425,y:-85.88966704},
{x:28.59794243,y:-81.24382138},
{x:39.57211888,y:-104.98905114},
{x:37.27495158,y:-121.85246278},
{x:33.52372309,y:-112.09672188},
{x:39.83908083,y:-83.08011339},
{x:40.82589754,y:-97.59682245},
{x:34.1328746,y:-116.38633686},
{x:28.29487039,y:-80.72423458},
{x:34.50614037,y:-96.98934751},
{x:39.0719963,y:-77.13248272},
{x:33.88887348,y:-118.11512411},
{x:40.729173,y:-74.005015},
{x:61.13897042,y:-149.83501525},
{x:39.84549783,y:-84.42602192},
{x:39.67822124,y:-75.49347688},
{x:34.39220276,y:-77.67941565},
{x:41.71517534,y:-72.2295229},
{x:42.90750781,y:-78.77414258},
{x:38.65337281,y:-75.59372221},
{x:40.65412214,y:-73.55895753},
{x:44.29828159,y:-84.69879587},
{x:40.04902188,y:-84.22610633},
{x:33.95973571,y:-118.31461235},
{x:30.42369262,y:-87.20223457},
{x:42.98539425,y:-85.68253187},
{x:39.6818899,y:-74.23948719},
{x:41.96359709,y:-83.35360218},
{x:35.40589398,y:-97.54869892},
{x:33.35846282,y:-86.85306625},
{x:42.19835695,y:-83.26728052},
{x:43.06096752,y:-83.76767658},
{x:37.69768097,y:-121.87629643},
{x:37.93582146,y:-121.29958366},
{x:31.11947431,y:-97.89301385},
{x:40.70162463,y:-104.99918594},
{x:34.04443,y:-94.32991},
{x:37.3682988,y:-89.64276076},
{x:32.95218878,y:-80.16692251},
{x:34.12630225,y:-81.22417499},
{x:33.63862066,y:-86.68319697},
{x:34.73449898,y:-86.63459323},
{x:41.19118745,y:-96.17885739},
{x:35.97978158,y:-86.55899158},
{x:38.9034701,y:-94.34262514},
{x:37.15362648,y:-93.29513767},
{x:34.02597039,y:-118.48303695},
{x:42.48635965,y:-83.00584361},
{x:38.78272745,y:-90.53278059},
{x:41.14453679,y:-95.91238622},
{x:32.31338735,y:-90.88233263},
{x:45.61163607,y:-122.6768859},
{x:36.05714185,y:-95.88560074},
{x:36.15846346,y:-115.31815678},
{x:33.83167248,y:-118.00498963},
{x:39.07531106,y:-76.54456563},
{x:39.050835,y:-94.5884833},
{x:39.60591427,y:-119.84252255},
{x:30.56232956,y:-81.8287343},
{x:33.49519423,y:-112.02858527},
{x:38.66797914,y:-90.58953059},
{x:33.54815382,y:-101.8581249},
{x:40.73260068,y:-73.98797055},
{x:32.82580803,y:-97.06031094},
{x:41.20042753,y:-73.98381443},
{x:35.20421417,y:-89.79485659},
{x:40.65257622,y:-74.31912883},
{x:40.2652,y:-75.3715},
{x:42.09929995,y:-80.14572837},
{x:40.08569168,y:-82.95776255},
{x:29.3813442,y:-100.90459451},
{x:40.0941886,y:-75.0166896},
{x:45.77260857,y:-111.18442664},
{x:33.07192498,y:-97.05501175},
{x:33.66151086,y:-114.2368346},
{x:33.2477087,y:-84.27476132},
{x:31.31545745,y:-85.82937418},
{x:30.01485384,y:-97.85835106},
{x:32.71968366,y:-80.07999249},
{x:34.28390143,y:-89.02649222},
{x:35.85078851,y:-101.97299654},
{x:26.27200422,y:-81.77158718},
{x:43.01453317,y:-74.36146966},
{x:32.33834493,y:-86.17558135},
{x:33.20640151,y:-117.28923172},
{x:39.3478722,y:-83.4009106},
{x:38.01490053,y:-121.99932218},
{x:30.30884735,y:-81.73201717},
{x:38.47484439,y:-121.44780896},
{x:38.25744801,y:-104.61890374},
{x:40.07364574,y:-80.87253753},
{x:42.36946993,y:-83.35313375},
{x:42.11689793,y:-72.52459553},
{x:28.23543004,y:-82.34937696},
{x:39.72752045,y:-75.64978977},
{x:33.37512829,y:-84.56327327},
{x:35.22146566,y:-114.00571087},
{x:40.51702542,y:-80.03223429},
{x:34.16308424,y:-79.76507038},
{x:33.10525482,y:-82.02396293},
{x:27.99815048,y:-80.63776654},
{x:25.77017997,y:-80.33099986},
{x:36.14635283,y:-115.29815013},
{x:32.66194736,y:-117.08128904},
{x:35.46154819,y:-84.64204341},
{x:33.89951093,y:-83.96198988},
{x:36.07709026,y:-79.48772166},
{x:39.29562934,y:-76.57025317},
{x:28.29238366,y:-81.36486145},
{x:38.65592027,y:-92.11462997},
{x:33.92212774,y:-84.48006937},
{x:39.52207285,y:-107.77535556},
{x:33.8598358,y:-117.92314392},
{x:39.04749117,y:-94.36516468},
{x:39.2232,y:-94.7572},
{x:32.32879523,y:-90.12131457},
{x:39.94261664,y:-83.85576345},
{x:30.815468,y:-97.609744},
{x:39.3938361,y:-74.53762473},
{x:29.78027553,y:-95.12504335},
{x:45.49094507,y:-122.47590221},
{x:33.56306125,y:-117.20596151},
{x:33.94081013,y:-83.45053469},
{x:33.92640497,y:-118.01235795},
{x:41.45656428,y:-74.37957068},
{x:42.18515613,y:-122.67192994},
{x:25.72931727,y:-80.43143101},
{x:44.73864401,y:-92.87513709},
{x:36.03309999,y:-83.86894236},
{x:33.47925503,y:-117.10395458},
{x:39.79060031,y:-75.17464802},
{x:38.59776968,y:-121.26620955},
{x:40.2628622,y:-81.86988268},
{x:36.08612558,y:-115.24329766},
{x:39.5223355,y:-76.18380108},
{x:33.8772367,y:-118.35292339},
{x:36.00504481,y:-79.79096123},
{x:37.49018572,y:-122.22409522},
{x:34.91706925,y:-95.74437895},
{x:38.94675186,y:-92.33476102},
{x:28.39589648,y:-80.75560704},
{x:35.72411107,y:-78.54748498},
{x:39.09492104,y:-104.86517384},
{x:33.91616823,y:-118.28223388},
{x:37.67805826,y:-121.78477889},
{x:41.40557034,y:-81.69157997},
{x:37.21268669,y:-93.34978858},
{x:39.2177,y:-75.5732},
{x:34.74148687,y:-87.66806535},
{x:39.40214074,y:-76.94555511},
{x:28.41774691,y:-81.24284332},
{x:34.55678612,y:-112.48173147},
{x:33.93459914,y:-118.14433068},
{x:39.60139581,y:-104.80520833},
{x:35.30785523,y:-80.84242202},
{x:36.79228158,y:-121.66537721},
{x:40.8041,y:-73.9363},
{x:33.55212092,y:-112.26142763},
{x:35.23352256,y:-81.1686569},
{x:35.21204533,y:-78.06467545},
{x:41.00292181,y:-95.90844421},
{x:38.52294616,y:-121.49746172},
{x:39.82785751,y:-105.00665026},
{x:42.87696852,y:-77.24912167},
{x:42.60646504,y:-76.16564279},
{x:35.06033112,y:-79.01153585},
{x:40.62198763,y:-74.02839307},
{x:28.8363158,y:-82.33770461},
{x:27.8570483,y:-97.65110619},
{x:34.29391743,y:-118.41616646},
{x:45.23020199,y:-93.37705159},
{x:35.94614576,y:-85.48446202},
{x:32.29479643,y:-106.76907957},
{x:36.10139076,y:-115.29776551},
{x:41.12106883,y:-80.7686863},
{x:38.67139679,y:-121.20313256},
{x:44.62268566,y:-123.10974059},
{x:33.78337778,y:-89.83417441},
{x:30.28722465,y:-81.45668678},
{x:33.63840728,y:-112.18251711},
{x:40.91286638,y:-73.77573797},
{x:32.58227403,y:-117.03313573},
{x:33.24702467,y:-111.62541211},
{x:43.6570766,y:-93.3172435},
{x:46.84582059,y:-96.79920742},
{x:39.1255805,y:-121.57763332},
{x:33.94888912,y:-86.47078826},
{x:35.23165163,y:-97.4230425},
{x:38.26294961,y:-93.36956456},
{x:32.67926199,y:-97.04476089},
{x:40.98117167,y:-76.01724843},
{x:39.36413237,y:-104.86173161},
{x:36.2620121,y:-115.17873743},
{x:40.71368323,y:-73.90140968},
{x:34.07065353,y:-86.77579694},
{x:45.84821483,y:-122.83299543},
{x:45.23880437,y:-93.66263015},
{x:41.1159813,y:-74.58054551},
{x:31.53059312,y:-83.83076391},
{x:46.25779404,y:-114.15778754},
{x:28.09494871,y:-81.97308183},
{x:35.64289729,y:-97.42613035},
{x:33.55202333,y:-117.13912953},
{x:29.93652558,y:-82.11211869},
{x:35.82015263,y:-90.70591678},
{x:36.07600923,y:-90.9543692},
{x:43.12993415,y:-77.44194701},
{x:37.01749367,y:-120.12925446},
{x:42.2968,y:-85.2341},
{x:41.04431722,y:-73.57321038},
{x:35.9136,y:-82.3117},
{x:43.08833118,y:-78.98066461},
{x:34.86798015,y:-76.89964704},
{x:40.58119917,y:-74.16476755},
{x:42.68118036,y:-83.1524883},
{x:45.51921525,y:-123.00095953},
{x:35.16603417,y:-81.45144998},
{x:26.11569263,y:-81.75494798},
{x:42.78507087,y:-73.94565538},
{x:40.86172215,y:-73.0588381},
{x:40.8824,y:-73.88132},
{x:34.21076963,y:-118.84296633},
{x:41.12156863,y:-78.72907296},
{x:30.18663721,y:-82.63981682},
{x:38.43833433,y:-82.38402615},
{x:32.18658088,y:-90.25718857},
{x:40.8620498,y:-73.89252967},
{x:42.27505748,y:-83.4783242},
{x:38.56840363,y:-90.47632907},
{x:32.67565476,y:-97.19539307},
{x:27.77779153,y:-97.41349617},
{x:41.43733761,y:-82.1850232},
{x:42.3503498,y:-71.4992246},
{x:28.60867752,y:-81.39008417},
{x:38.78808193,y:-121.22659653},
{x:30.12560782,y:-94.15662863},
{x:40.73793874,y:-73.9967729},
{x:36.37941907,y:-105.58796188},
{x:26.66769423,y:-81.89090006},
{x:44.81,y:-122.814},
{x:38.84622577,y:-76.8843623},
{x:33.80102894,y:-112.13039348},
{x:40.45893646,y:-105.07803586},
{x:40.73378729,y:-82.8006506},
{x:42.70269199,y:-83.29697758},
{x:36.1296396,y:-86.7140498},
{x:40.45778757,y:-80.16790359},
{x:35.20418787,y:-97.42318509},
{x:30.37930258,y:-89.09343843},
{x:37.72902299,y:-122.4307637},
{x:33.1732475,y:-96.49137276},
{x:36.83545591,y:-119.92023751},
{x:42.50103473,y:-78.69029764},
{x:38.1618975,y:-121.69899009},
{x:34.00603555,y:-118.2562767},
{x:33.89516867,y:-94.80487563},
{x:34.25932398,y:-118.30463541},
{x:33.47684174,y:-111.98646252},
{x:40.80875299,y:-74.06893093},
{x:34.04902434,y:-117.90771429},
{x:37.71026451,y:-120.99553854},
{x:33.21154868,y:-117.23132242},
{x:33.5866929,y:-111.83568098},
{x:29.49934359,y:-82.86923924},
{x:40.77549119,y:-74.23768037},
{x:39.52738806,y:-76.35396799},
{x:39.26934366,y:-103.71004656},
{x:39.94782261,y:-82.94072628},
{x:40.20248532,y:-75.10139912},
{x:34.22726084,y:-83.86797935},
{x:37.78125443,y:-122.46413512},
{x:35.87127473,y:-86.35239979},
{x:33.63905566,y:-112.14992628},
{x:35.2666817,y:-119.25325534},
{x:41.01819421,y:-74.30058024},
{x:30.0650967,y:-81.8702808},
{x:39.3687393,y:-94.3743159},
{x:25.92550268,y:-80.16624909},
{x:31.84335859,y:-85.1667157},
{x:42.44409604,y:-83.65082897},
{x:33.78032507,y:-84.38918335},
{x:42.79655284,y:-86.07727069},
{x:38.22079457,y:-122.12868339},
{x:42.50851475,y:-82.96775891},
{x:38.44356617,y:-91.00225652},
{x:38.6758973,y:-76.8768403},
{x:42.27604182,y:-83.77750079},
{x:31.68519223,y:-106.26865275},
{x:38.55095251,y:-121.69393715},
{x:34.25148319,y:-84.08800439},
{x:39.62644226,y:-119.88254905},
{x:33.18878042,y:-97.10353017},
{x:38.41820181,y:-122.71315992},
{x:45.66756082,y:-120.83672555},
{x:43.68996628,y:-98.00871402},
{x:42.35811077,y:-83.0709382},
{x:39.0437653,y:-83.92081618},
{x:40.84516279,y:-96.68145798},
{x:43.66408093,y:-84.24677869},
{x:37.60119207,y:-122.38993219},
{x:35.19944509,y:-85.16031995},
{x:37.54859576,y:-122.30976422},
{x:44.71593349,y:-93.17222505},
{x:40.90137312,y:-81.10728219},
{x:34.8521532,y:-82.45083556},
{x:36.57198389,y:-82.1931839},
{x:32.35588543,y:-90.18114527},
{x:43.38685241,y:-123.34063456},
{x:37.7535968,y:-121.468936},
{x:39.32290953,y:-120.22631684},
{x:43.2121202,y:-77.4619971},
{x:35.55854292,y:-77.06079853},
{x:38.78752441,y:-90.51241502},
{x:40.856713,y:-82.2620771},
{x:33.89203274,y:-79.75468645},
{x:35.51179101,y:-97.60980677},
{x:40.81041583,y:-73.40904481},
{x:25.8486,y:-80.3142},
{x:33.28195843,y:-86.85372591},
{x:36.32742297,y:-121.24804129},
{x:37.8254778,y:-82.8228969},
{x:38.12332775,y:-122.25479454},
{x:36.06233361,y:-79.60621765},
{x:39.16812123,y:-94.49613853},
{x:40.86258038,y:-73.90179806},
{x:44.92934037,y:-123.31580669},
{x:40.8332,y:-75.9889},
{x:40.56956949,y:-122.35930051},
{x:37.66500454,y:-120.97536072},
{x:39.17336354,y:-91.88870563},
{x:38.55063649,y:-76.59173369},
{x:25.6564465,y:-80.40381677},
{x:39.01294908,y:-74.87390407},
{x:32.73394305,y:-97.7944342},
{x:36.76526019,y:-119.79001269},
{x:28.63381,y:-81.32042},
{x:28.55154498,y:-81.58038497},
{x:39.74699289,y:-75.57420973},
{x:42.32769489,y:-77.66294023},
{x:30.07517427,y:-95.51512733},
{x:37.04557527,y:-122.02823465},
{x:33.82790731,y:-117.95855223},
{x:39.95033216,y:-75.15842287},
{x:40.85891,y:-81.484577},
{x:42.64717297,y:-83.17160936},
{x:34.19392525,y:-92.05847373},
{x:40.84717697,y:-73.93560729},
{x:43.17695944,y:-83.76686007},
{x:43.42,y:-82.852},
{x:41.45880954,y:-74.05515537},
{x:42.30977694,y:-83.34892281},
{x:40.19402447,y:-75.5281025},
{x:34.79625785,y:-87.67312698},
{x:32.51977577,y:-84.95836303},
{x:36.30204763,y:-86.63042571},
{x:30.67065404,y:-87.85164058},
{x:40.2613172,y:-76.88128111},
{x:34.56987266,y:-86.97277502},
{x:33.68354013,y:-117.16763914},
{x:29.32266159,y:-98.48252673},
{x:40.16521131,y:-80.2714093},
{x:32.46497592,y:-84.91904043},
{x:35.03702542,y:-110.69844067},
{x:39.69341796,y:-104.94110564},
{x:32.5428327,y:-117.0371417},
{x:34.73528025,y:-80.78325319},
{x:44.54441097,y:-69.6767092},
{x:40.96546658,y:-81.46575267},
{x:43.6250349,y:-70.34385026},
{x:30.1034,y:-81.5428},
{x:43.22697205,y:-123.35347697},
{x:32.90094115,y:-97.33106976},
{x:37.38918789,y:-122.031097},
{x:41.39775265,y:-122.37760026},
{x:25.67031506,y:-80.44025975},
{x:34.07565349,y:-118.02164912},
{x:42.5969673,y:-83.93318921},
{x:25.81170509,y:-80.23997417},
{x:30.41889287,y:-86.65260409},
{x:33.46579599,y:-117.70362127},
{x:42.69957469,y:-84.56677678},
{x:41.4679515,y:-81.90103419},
{x:38.94958255,y:-122.6305908},
{x:36.81968204,y:-119.7899276},
{x:33.30176426,y:-87.5714072},
{x:39.76304226,y:-81.11530043},
{x:43.97638532,y:-75.93249426},
{x:30.11646397,y:-95.38358357},
{x:33.4316137,y:-112.06562609},
{x:27.98022814,y:-82.48501897},
{x:38.92543344,y:-94.52360704},
{x:40.18815044,y:-80.21549444},
{x:30.31952001,y:-81.46398766},
{x:40.62481226,y:-103.23193144},
{x:33.9236629,y:-116.9485928},
{x:41.42073233,y:-81.60833083},
{x:35.60550044,y:-78.56779754},
{x:41.81258547,y:-86.24864698},
{x:42.74323541,y:-73.64529175},
{x:31.45480778,y:-86.96214332},
{x:42.49201883,y:-83.46934099},
{x:36.10878627,y:-115.17213997},
{x:27.76119577,y:-82.7358377},
{x:33.12330606,y:-87.55137116},
{x:34.17631401,y:-119.21401707},
{x:34.21637947,y:-118.22474473},
{x:37.93821769,y:-122.34716226},
{x:33.45420463,y:-117.6062391},
{x:41.28035766,y:-80.84430344},
{x:43.29365616,y:-86.20448112},
{x:34.56016229,y:-77.91790777},
{x:32.41280702,y:-104.22930976},
{x:35.52990042,y:-108.73005207},
{x:46.88639086,y:-114.0387138},
{x:36.14317721,y:-115.25935469},
{x:42.24418764,y:-83.59060935},
{x:39.35739278,y:-82.97693455},
{x:30.40204588,y:-88.97197862},
{x:35.49194479,y:-94.22642836},
{x:31.95740063,y:-110.98127214},
{x:40.11483013,y:-75.41861983},
{x:35.05852854,y:-106.58831303},
{x:45.50866837,y:-122.42895305},
{x:42.84248674,y:-85.6242603},
{x:44.93460971,y:-93.38865995},
{x:42.91239618,y:-85.51890075},
{x:36.03749055,y:-78.49945323},
{x:35.44167396,y:-88.63145113},
{x:31.10541598,y:-87.47651987},
{x:44.93724289,y:-92.93562819},
{x:37.71132037,y:-89.89041977},
{x:33.98616081,y:-85.86663099},
{x:39.61798975,y:-83.60345853},
{x:45.5092856,y:-92.99142563},
{x:36.06018793,y:-88.11514198},
{x:42.57316357,y:-82.88906023},
{x:29.73803671,y:-95.50212264},
{x:32.6488383,y:-96.9260091},
{x:45.09886088,y:-95.03916128},
{x:40.47036054,y:-78.75409753},
{x:39.98402586,y:-76.28024344},
{x:38.78362651,y:-82.98443556},
{x:40.80279,y:-74.02062},
{x:33.1000287,y:-97.2165074},
{x:33.49372555,y:-88.36690217},
{x:43.59166705,y:-84.76709604},
{x:43.06051052,y:-83.81567625},
{x:33.90099978,y:-84.54167341},
{x:33.53123366,y:-88.43664483},
{x:39.91202752,y:-75.14981564},
{x:40.15601946,y:-74.42884791},
{x:40.9285874,y:-78.98253042},
{x:39.78577837,y:-77.71143061},
{x:43.01229018,y:-83.55615992},
{x:32.64088363,y:-117.05079809},
{x:45.97475838,y:-94.33710538},
{x:37.67776213,y:-120.95824112},
{x:32.20663615,y:-110.90848267},
{x:41.97435632,y:-71.50964722},
{x:40.37413553,y:-75.92743099},
{x:34.1361541,y:-118.3522443},
{x:34.15676428,y:-86.83395522},
{x:31.71323901,y:-96.17839873},
{x:35.31853169,y:-119.08984549},
{x:42.81224917,y:-83.7898413},
{x:35.81179605,y:-86.46001899},
{x:28.48727227,y:-81.45812412},
{x:40.77574772,y:-73.9115482},
{x:33.64955628,y:-84.01091118},
{x:34.28622884,y:-83.84069511},
{x:40.73373899,y:-73.6786528},
{x:43.32844136,y:-73.67036384},
{x:45.05349331,y:-93.36534595},
{x:33.97717208,y:-80.48705388},
{x:40.6447206,y:-75.34654129},
{x:35.36898964,y:-119.03907712},
{x:33.93098284,y:-86.02467092},
{x:38.43452232,y:-122.68727124},
{x:38.92388752,y:-121.05953877},
{x:46.86932561,y:-113.98615837},
{x:37.40080744,y:-122.10966589},
{x:28.56882017,y:-81.28160832},
{x:33.81073964,y:-118.2908506},
{x:34.57130152,y:-83.30742358},
{x:32.68498384,y:-114.64983478},
{x:32.8021247,y:-117.00442847},
{x:40.87998443,y:-81.43787398},
{x:43.64159766,y:-84.25156206},
{x:33.82505284,y:-117.83566251},
{x:61.18049488,y:-149.83125249},
{x:26.25894451,y:-80.20105829},
{x:28.34106378,y:-81.41469559},
{x:40.397715,y:-74.557961},
{x:43.2054692,y:-77.66486145},
{x:35.34854391,y:-97.49668403},
{x:40.8233,y:-73.9531},
{x:40.91637456,y:-74.1715309},
{x:34.7385466,y:-86.66185342},
{x:42.005616,y:-83.960487},
{x:39.04362295,y:-94.41566281},
{x:44.92179391,y:-93.47199679},
{x:35.24220531,y:-80.88972326},
{x:34.15458033,y:-119.19487147},
{x:39.74070648,y:-104.81852794},
{x:35.85539835,y:-78.64114791},
{x:32.87920417,y:-111.71276895},
{x:42.51641841,y:-83.36022384},
{x:36.05462381,y:-79.83134667},
{x:32.8377699,y:-96.9585675},
{x:34.14284292,y:-118.24111345},
{x:40.65586379,y:-80.23716514},
{x:39.74156919,y:-105.20054847},
{x:32.79513246,y:-117.25483908},
{x:35.96452438,y:-86.81155502},
{x:41.25989355,y:-75.8999569},
{x:38.77812763,y:-90.48819517},
{x:28.46552602,y:-82.6220224},
{x:42.63459703,y:-83.74478273},
{x:35.04499667,y:-78.92607942},
{x:26.61212204,y:-81.80924959},
{x:42.79875451,y:-78.75099738},
{x:46.80409504,y:-92.15168953},
{x:39.69106924,y:-74.99397357},
{x:26.33434296,y:-80.20226748},
{x:37.78609944,y:-121.25105895},
{x:34.712311,y:-92.394778},
{x:30.9070924,y:-88.5975016},
{x:33.6826432,y:-85.26319443},
{x:43.22541569,y:-75.48612691},
{x:41.02508016,y:-74.24330818},
{x:41.86218319,y:-80.79882145},
{x:38.95736606,y:-104.76802652},
{x:33.53980644,y:-84.57611404},
{x:33.93043131,y:-118.1839668},
{x:34.98578534,y:-80.52960291},
{x:25.94319727,y:-80.30747982},
{x:38.457625,y:-121.84211366},
{x:41.52477515,y:-81.58726255},
{x:40.72926536,y:-99.08309349},
{x:40.26728215,y:-82.93266244},
{x:43.41630406,y:-84.07199849},
{x:36.3285648,y:-94.12623525},
{x:35.60489358,y:-105.22333131},
{x:43.02456381,y:-82.45632998},
{x:40.86671622,y:-82.3184377},
{x:36.29633542,y:-95.32256855},
{x:36.05671255,y:-115.04115164},
{x:45.71017341,y:-121.53817495},
{x:36.08082847,y:-119.04435829},
{x:35.75195423,y:-83.99643018},
{x:41.72394189,y:-72.76319527},
{x:40.86413404,y:-74.03593183},
{x:34.7465602,y:-81.04053646},
{x:35.50743092,y:-108.81790598},
{x:40.40135942,y:-82.45208316},
{x:31.78387945,y:-85.95728874},
{x:35.98809283,y:-96.10381265},
{x:33.12623119,y:-94.17865554},
{x:25.74976683,y:-80.24611742},
{x:34.12177967,y:-117.37346341},
{x:36.02464242,y:-84.24077003},
{x:36.14169771,y:-115.13645843},
{x:40.96521166,y:-76.61870222},
{x:38.75436654,y:-82.92064987},
{x:33.46853946,y:-84.44730993},
{x:38.93631128,y:-119.73933924},
{x:40.7789,y:-73.2543},
{x:38.69072624,y:-121.12391044},
{x:34.17913194,y:-118.38290457},
{x:42.137699,y:-83.263041},
{x:25.77962165,y:-80.25605097},
{x:40.40994356,y:-81.3479729},
{x:39.72125331,y:-91.39266171},
{x:33.98958033,y:-83.72167115},
{x:41.40384923,y:-82.66042605},
{x:37.95552017,y:-120.42420817},
{x:42.185952,y:-72.630968},
{x:40.13973082,y:-75.31381811},
{x:47.49227163,y:-111.21680038},
{x:37.21111816,y:-93.22444921},
{x:33.56101418,y:-86.52200907},
{x:35.30198528,y:-120.65935373},
{x:43.79925928,y:-123.0382897},
{x:36.15557555,y:-85.51725558},
{x:39.70323273,y:-84.12599575},
{x:34.07793605,y:-117.6113349},
{x:32.46998099,y:-85.08497365},
{x:37.30020722,y:-89.56990872},
{x:33.78004512,y:-116.46651831},
{x:34.69969913,y:-79.93285009},
{x:32.9961973,y:-111.52327609},
{x:39.8445053,y:-84.1981489},
{x:38.39377704,y:-121.41552627},
{x:33.58550381,y:-86.77022204},
{x:35.20435391,y:-97.4736924},
{x:47.5215387,y:-111.30721785},
{x:40.58067528,y:-83.09465841},
{x:32.13428179,y:-111.00099803},
{x:39.45038758,y:-75.72421118},
{x:29.80790702,y:-95.16848266},
{x:38.35138527,y:-121.95191145},
{x:35.06712298,y:-94.62668932},
{x:36.76551753,y:-119.73438442},
{x:35.44411412,y:-94.32616636},
{x:36.57598282,y:-89.97021919},
{x:35.62693685,y:-118.47347945},
{x:35.32334088,y:-80.64282082},
{x:34.35338464,y:-82.92974342},
{x:33.63903632,y:-86.61596122},
{x:41.43020983,y:-81.52590698},
{x:41.5072938,y:-83.14423352},
{x:37.29341325,y:-121.9970423},
{x:34.36256593,y:-89.51146511},
{x:36.24833559,y:-86.71963021},
{x:32.08734681,y:-96.47133616},
{x:33.80051021,y:-118.31621937},
{x:33.99413591,y:-117.05799942},
{x:30.393152,y:-87.681808},
{x:38.27366229,y:-104.60748901},
{x:34.16686405,y:-84.78785862},
{x:42.5507938,y:-82.9623918},
{x:36.77154328,y:-119.86273347},
{x:32.70915422,y:-97.00557978},
{x:41.86060923,y:-71.3539278},
{x:34.20148514,y:-118.47464739},
{x:32.26833455,y:-95.30784582},
{x:29.56155059,y:-95.69447744},
{x:35.15826275,y:-89.72807205},
{x:34.48994504,y:-82.67608137},
{x:45.43883796,y:-122.75423773},
{x:43.002553,y:-76.13041},
{x:33.6016721,y:-117.6909509},
{x:28.52368134,y:-82.23338122},
{x:41.3961,y:-82.1065},
{x:34.11871057,y:-87.99003179},
{x:40.15138529,y:-84.2141591},
{x:33.88585733,y:-84.24760737},
{x:38.55636541,y:-121.41006046},
{x:45.20823226,y:-93.35715473},
{x:33.606317,y:-85.786963},
{x:35.13030069,y:-106.53365976},
{x:39.74880533,y:-92.46764888},
{x:36.0226327,y:-87.33875565},
{x:33.98236316,y:-81.06121257},
{x:39.65182357,y:-104.7719127},
{x:31.72621351,y:-106.30339219},
{x:34.10706785,y:-117.86432241},
{x:44.6375834,y:-70.15394162},
{x:41.40207072,y:-73.45945016},
{x:41.8430389,y:-71.88115159},
{x:40.83243289,y:-81.38840534},
{x:36.09023302,y:-79.77357067},
{x:35.280996,y:-119.031527},
{x:33.69822825,y:-80.2278673},
{x:25.98535062,y:-80.16346648},
{x:35.71633853,y:-78.63467965},
{x:34.57876718,y:-83.76470232},
{x:34.65749831,y:-86.48312249},
{x:44.73309012,y:-85.60448438},
{x:35.4770428,y:-81.23366158},
{x:39.03145574,y:-94.3045096},
{x:33.94146529,y:-117.22796865},
{x:39.38591887,y:-77.40666289},
{x:35.76452654,y:-89.54140315},
{x:38.57761441,y:-121.54910265},
{x:30.14122456,y:-96.39548055},
{x:34.03896402,y:-118.55461205},
{x:38.68016179,y:-75.39515806},
{x:36.22087347,y:-86.34445623},
{x:40.01410096,y:-74.95696847},
{x:39.94017906,y:-82.4806577},
{x:61.25796432,y:-149.67388882},
{x:28.47156193,y:-81.45099654},
{x:37.32768917,y:-121.93165952},
{x:26.69014321,y:-80.06176643},
{x:40.699462,y:-74.238444},
{x:39.65531145,y:-105.05266917},
{x:41.41912029,y:-81.73638493},
{x:39.8471803,y:-84.11335028},
{x:36.37210112,y:-94.22202744},
{x:34.9723685,y:-120.43606639},
{x:37.6449061,y:-92.66898},
{x:37.68288675,y:-122.48859293},
{x:30.40391624,y:-87.27756415},
{x:38.61086385,y:-76.92519126},
{x:45.49507561,y:-122.8755205},
{x:42.83135759,y:-86.09484677},
{x:40.29451897,y:-78.8372013},
{x:45.03608328,y:-93.01916555},
{x:42.67535641,y:-84.54779327},
{x:33.82465963,y:-117.50921822},
{x:33.03984927,y:-80.07065731},
{x:40.83865506,y:-73.94110878},
{x:35.77372329,y:-86.91606984},
{x:43.83544062,y:-70.4388941},
{x:35.09658855,y:-89.85914473},
{x:42.35360181,y:-122.870286},
{x:34.83230936,y:-82.40463965},
{x:32.94494461,y:-96.69412794},
{x:30.68370406,y:-88.06090991},
{x:29.69605846,y:-95.8387677},
{x:35.51703677,y:-78.56191412},
{x:39.67941207,y:-105.05364894},
{x:42.53118598,y:-85.87127799},
{x:36.24086166,y:-115.0796601},
{x:38.49757667,y:-90.29965394},
{x:34.03444659,y:-117.32114873},
{x:38.96584799,y:-92.37037808},
{x:40.9613079,y:-76.89255195},
{x:27.06322917,y:-80.13768505},
{x:40.7009777,y:-73.94150219},
{x:39.09345133,y:-120.9514031},
{x:41.66799304,y:-74.67497335},
{x:40.33765778,y:-79.80701272},
{x:38.66387396,y:-121.30666912},
{x:37.26836043,y:-121.94336772},
{x:35.66220757,y:-78.70164829},
{x:44.8317782,y:-93.16377766},
{x:33.46546791,y:-112.06887205},
{x:36.19132256,y:-86.79971963},
{x:34.41603918,y:-118.46612663},
{x:42.44666714,y:-82.91601375},
{x:42.56092586,y:-83.02868925},
{x:43.19933048,y:-77.57449604},
{x:28.21759374,y:-82.68911251},
{x:39.07697562,y:-108.51603145},
{x:28.05403261,y:-82.44057839},
{x:33.42177527,y:-117.60884106},
{x:34.01192249,y:-117.73581067},
{x:38.75336028,y:-121.26040331},
{x:46.7478484,y:-92.1565844},
{x:37.58942022,y:-122.06839487},
{x:28.0784983,y:-80.65408923},
{x:33.33339474,y:-86.99581416},
{x:27.32724986,y:-82.52979441},
{x:33.60082649,y:-117.67265041},
{x:37.00175626,y:-91.68971309},
{x:33.88470189,y:-117.51780202},
{x:41.31366794,y:-75.75736014},
{x:39.39416711,y:-123.34913354},
{x:36.30749567,y:-115.27916547},
{x:32.32863861,y:-90.32632828},
{x:39.10065546,y:-77.21217785},
{x:34.07902708,y:-118.12005389},
{x:43.00489961,y:-78.20431713},
{x:34.58627429,y:-112.32903025},
{x:42.70964052,y:-83.30659079},
{x:27.77756608,y:-82.67987528},
{x:36.67071345,y:-76.94585043},
{x:40.26842585,y:-76.69222683},
{x:34.12258547,y:-77.90061414},
{x:33.22995098,y:-97.15196326},
{x:61.599191,y:-149.127888},
{x:38.30434355,y:-76.65774514},
{x:35.29618356,y:-80.75557351},
{x:28.66663024,y:-81.37737024},
{x:34.43631717,y:-119.83043888},
{x:31.93489846,y:-87.73825675},
{x:39.25187653,y:-76.64727017},
{x:36.07887038,y:-94.2069295},
{x:34.95560137,y:-80.75610995},
{x:31.14022092,y:-97.32807426},
{x:34.98943095,y:-85.21288588},
{x:34.86446851,y:-118.17795087},
{x:32.74288256,y:-117.04197094},
{x:44.50035639,y:-103.86022373},
{x:37.7884163,y:-90.42975426},
{x:33.8032536,y:-118.01413938},
{x:34.71728301,y:-77.32496072},
{x:41.56469627,y:-72.9102196},
{x:43.11751975,y:-85.59696596},
{x:41.02511994,y:-80.72775111},
{x:41.05507679,y:-73.52995911},
{x:41.46305464,y:-75.64300627},
{x:25.86741749,y:-80.27228234},
{x:38.36574825,y:-93.74928296},
{x:40.87059337,y:-72.99657779},
{x:38.91699265,y:-75.44265459},
{x:39.7201,y:-82.9849},
{x:37.76846355,y:-120.84124149},
{x:34.00776646,y:-85.98398475},
{x:43.18838,y:-77.50423},
{x:37.38611098,y:-121.86209325},
{x:34.16467778,y:-118.38759764},
{x:35.88734278,y:-82.06465786},
{x:29.88701707,y:-95.0636277},
{x:36.16598423,y:-80.13540307},
{x:30.41369636,y:-97.69279808},
{x:35.15518343,y:-79.41709757},
{x:34.12121123,y:-117.59313364},
{x:26.16707996,y:-80.13899914},
{x:35.26889384,y:-89.66622717},
{x:29.05630761,y:-82.45670383},
{x:34.0709411,y:-117.39501696},
{x:40.65236617,y:-73.58922578},
{x:38.99608945,y:-94.47740708},
{x:38.72821271,y:-82.85938829},
{x:34.24234249,y:-77.89475144},
{x:40.12509481,y:-79.55222189},
{x:40.8555399,y:-73.86794211},
{x:30.26310202,y:-81.52938456},
{x:43.22885233,y:-71.48330127},
{x:41.22298422,y:-75.88114605},
{x:40.31980325,y:-79.69184531},
{x:34.76013214,y:-84.99627451},
{x:41.6977121,y:-72.9015371},
{x:32.60175622,y:-85.47965371},
{x:33.88800289,y:-118.06607723},
{x:33.9545108,y:-116.50185015},
{x:32.23605263,y:-111.00300074},
{x:34.76103585,y:-86.5911064},
{x:40.77863225,y:-74.13058162},
{x:36.02763071,y:-83.92744351},
{x:27.93805017,y:-82.30392425},
{x:36.19546776,y:-115.11671104},
{x:38.72393575,y:-90.69920366},
{x:35.05351481,y:-90.02460618},
{x:39.7086839,y:-104.98792072},
{x:32.29773031,y:-106.81167154},
{x:36.39724669,y:-87.03008882},
{x:35.91516647,y:-86.83089596},
{x:32.79016977,y:-80.02999574},
{x:40.56137986,y:-76.00224044},
{x:34.1810454,y:-118.1505285},
{x:39.14676053,y:-84.40517485},
{x:35.02986563,y:-85.1853054},
{x:35.39761889,y:-77.96802285},
{x:44.88979867,y:-97.11021011},
{x:41.39510908,y:-81.46443632},
{x:42.59420647,y:-83.09059249},
{x:41.8070689,y:-72.54594839},
{x:44.98584465,y:-93.0248584},
{x:42.53863039,y:-83.47727175},
{x:28.80399362,y:-81.33905364},
{x:33.89656092,y:-118.22081946},
{x:35.14239746,y:-106.58688784},
{x:33.50541446,y:-82.50351622},
{x:45.15006981,y:-122.87792557},
{x:35.99211511,y:-83.92087589},
{x:38.82750951,y:-97.63340817},
{x:33.75998908,y:-78.85941524},
{x:43.67771915,y:-70.33614124},
{x:28.86597281,y:-82.0066186},
{x:35.7721781,y:-95.3722443},
{x:42.53594918,y:-83.06661828},
{x:39.11063071,y:-108.54033396},
{x:33.70089923,y:-84.1698727},
{x:32.74492784,y:-116.93045904},
{x:36.16810127,y:-115.20555252},
{x:34.31033606,y:-89.92951407},
{x:37.98557852,y:-121.337412},
{x:29.9970373,y:-95.42430907},
{x:35.33683346,y:-94.42316691},
{x:38.74601955,y:-90.59165736},
{x:35.33353855,y:-80.81255074},
{x:38.83765493,y:-104.81726958},
{x:38.61191156,y:-121.49732053},
{x:34.30448586,y:-85.17512485},
{x:26.6218837,y:-81.94119036},
{x:42.85613805,y:-78.82269685},
{x:34.4722442,y:-81.88240661},
{x:28.54002365,y:-81.15079537},
{x:33.5265,y:-86.6642},
{x:44.961648,y:-92.9629501},
{x:28.82443762,y:-97.85909904},
{x:27.38998835,y:-82.5051138},
{x:43.6330288,y:-72.317818},
{x:30.19022852,y:-85.71125165},
{x:40.00121928,y:-75.22722691},
{x:28.04043927,y:-97.04286945},
{x:43.56157669,y:-71.45151478},
{x:38.13788995,y:-85.68164903},
{x:40.58035599,y:-74.60957954},
{x:39.89575998,y:-74.95174184},
{x:34.78513569,y:-84.77177288},
{x:40.63540768,y:-82.23694902},
{x:35.8681256,y:-86.38650111},
{x:46.01286978,y:-112.53968919},
{x:34.32192734,y:-83.83288895},
{x:32.79856882,y:-116.93522398},
{x:37.9427647,y:-91.77206333},
{x:42.71942518,y:-84.49754077},
{x:28.93846284,y:-81.94408544},
{x:41.10103312,y:-80.73721741},
{x:28.80034382,y:-81.33766763},
{x:35.11087191,y:-77.0652986},
{x:31.05230346,y:-97.45467921},
{x:41.37275327,y:-81.89126607},
{x:35.43644679,y:-97.40475127},
{x:40.42667489,y:-79.88868682},
{x:33.9004352,y:-118.14316392},
{x:41.23356544,y:-96.10256216},
{x:32.33881278,y:-89.48442969},
{x:26.487811,y:-81.78437745},
{x:40.45304399,y:-122.29135533},
{x:44.63555134,y:-121.13039374},
{x:33.59425442,y:-86.28326454},
{x:39.20923585,y:-94.57585537},
{x:32.70564251,y:-97.34790087},
{x:33.37125309,y:-91.03933834},
{x:30.07777731,y:-94.16891186},
{x:31.5061,y:-110.256},
{x:34.69054085,y:-82.99403357},
{x:37.4722879,y:-122.21553062},
{x:42.943361,y:-83.730777},
{x:32.27956736,y:-90.00338287},
{x:33.53757341,y:-111.88503794},
{x:42.75718038,y:-86.11708731},
{x:33.49670741,y:-81.95891946},
{x:37.59718004,y:-122.50374956},
{x:42.90375328,y:-85.78662678},
{x:42.37105098,y:-83.30310359},
{x:40.75720615,y:-73.98659208},
{x:35.8335574,y:-88.91564899},
{x:43.16305394,y:-76.2356097},
{x:35.22848302,y:-89.81022835},
{x:45.08081231,y:-93.05468196},
{x:33.60935828,y:-117.92872024},
{x:32.6698568,y:-114.61288601},
{x:35.26229294,y:-79.04775698},
{x:39.636119,y:-78.8414656},
{x:35.86309497,y:-86.40949886},
{x:38.54829688,y:-122.80644692},
{x:39.05062522,y:-76.93672858},
{x:37.1419001,y:-93.2566799},
{x:41.40221003,y:-95.02829587},
{x:41.62477719,y:-81.43878177},
{x:32.93426143,y:-117.23864351},
{x:33.68193226,y:-117.66841118},
{x:40.18533233,y:-92.57162152},
{x:40.80019772,y:-81.51142269},
{x:35.91604732,y:-80.58246023},
{x:41.55402978,y:-72.6808396},
{x:42.43958725,y:-83.31623877},
{x:35.01929536,y:-85.32177056},
{x:42.09620157,y:-83.67587524},
{x:37.68295851,y:-122.0887363},
{x:25.88952575,y:-80.29127964},
{x:30.38526966,y:-95.66200743},
{x:29.43428307,y:-98.71574349},
{x:34.88717781,y:-85.2681078},
{x:39.16080168,y:-75.51239804},
{x:36.61547424,y:-121.84205809},
{x:27.96269608,y:-82.40230349},
{x:35.3824554,y:-86.22956731},
{x:40.81603361,y:-75.73113764},
{x:38.9844,y:-104.762},
{x:35.06976063,y:-92.45607096},
{x:37.56361708,y:-122.27111712},
{x:33.79470049,y:-117.83610461},
{x:33.9713668,y:-118.1638623},
{x:43.35371352,y:-83.87002848},
{x:35.1409476,y:-93.91203938},
{x:44.02520068,y:-116.95665121},
{x:38.29173233,y:-85.50978899},
{x:36.47095086,y:-81.80703326},
{x:46.7973107,y:-100.79496048},
{x:26.21001224,y:-98.2874881},
{x:39.6170987,y:-105.09187375},
{x:39.91458945,y:-105.05939527},
{x:38.78120111,y:-90.38855016},
{x:37.73602473,y:-89.18650774},
{x:29.21820879,y:-81.04734419},
{x:43.3317,y:-83.0794},
{x:44.84728712,y:-93.12290654},
{x:35.98529237,y:-96.74817439},
{x:41.52389289,y:-82.85680427},
{x:36.32848,y:-119.675222},
{x:39.95088739,y:-83.83041099},
{x:34.37947709,y:-118.56816091},
{x:35.02126599,y:-89.83173777},
{x:40.39797378,y:-104.69068904},
{x:33.63056628,y:-117.71737631},
{x:34.50811482,y:-81.00108589},
{x:36.39542537,y:-83.01148377},
{x:34.1970402,y:-79.7581303},
{x:34.4751666,y:-114.3446517},
{x:35.97814727,y:-77.97354341},
{x:42.74135887,y:-84.73332167},
{x:33.99960741,y:-96.40592609},
{x:36.03154435,y:-79.86855358},
{x:36.04216368,y:-79.77269907},
{x:38.71400774,y:-90.4369694},
{x:41.54657087,y:-83.59613036},
{x:39.71955127,y:-82.20671989},
{x:33.79449659,y:-116.9711928},
{x:37.36170725,y:-121.89248339},
{x:33.37553646,y:-86.80206134},
{x:35.03208392,y:-97.93535693},
{x:36.21737363,y:-115.1195903},
{x:35.6126832,y:-97.63824237},
{x:37.77499534,y:-121.97938954},
{x:37.99198265,y:-122.30292811},
{x:44.83625566,y:-93.28936994},
{x:29.69052609,y:-95.20871169},
{x:36.34822499,y:-92.3748638},
{x:30.50838231,y:-87.22087265},
{x:42.51408349,y:-83.61905764},
{x:35.53127218,y:-119.18799085},
{x:40.84240572,y:-84.32434112},
{x:29.85201582,y:-95.50429027},
{x:41.02001289,y:-84.02654142},
{x:40.63092627,y:-73.91918448},
{x:35.34753894,y:-80.16673758},
{x:33.70473661,y:-80.2421374},
{x:33.29478816,y:-83.96059936},
{x:27.99623452,y:-82.56642938},
{x:29.43941007,y:-98.49887223},
{x:36.20267064,y:-121.13545767},
{x:37.34515023,y:-121.93574135},
{x:28.56055636,y:-81.13136172},
{x:41.70225672,y:-86.23576939},
{x:26.28571009,y:-80.20412743},
{x:40.85512095,y:-74.07172851},
{x:37.95971668,y:-121.74254022},
{x:38.6121164,y:-121.44677733},
{x:34.80057477,y:-91.89015341},
{x:27.53082649,y:-82.50130266},
{x:33.49431016,y:-112.21988568},
{x:35.14329698,y:-90.00492492},
{x:39.80426147,y:-84.22080911},
{x:26.044997,y:-80.31232506},
{x:33.03810885,y:-83.93886148},
{x:34.1516082,y:-83.64164917},
{x:35.756406,y:-83.9419497},
{x:38.3023791,y:-122.47539381},
{x:33.30663678,y:-111.70103465},
{x:32.69747305,y:-117.01960534},
{x:33.63871398,y:-112.22602024},
{x:33.95397985,y:-118.20651501},
{x:35.49360843,y:-97.27615714},
{x:32.99673032,y:-80.18525809},
{x:42.21570749,y:-83.18165166},
{x:33.86882372,y:-84.01605924},
{x:36.08948592,y:-96.0128002},
{x:38.04694433,y:-84.55377151},
{x:33.41541519,y:-111.83961426},
{x:41.9426279,y:-84.648124},
{x:35.69316685,y:-81.2270311},
{x:35.14058501,y:-85.24908589},
{x:35.08916057,y:-85.18645674},
{x:32.84469762,y:-85.18216543},
{x:40.68620738,y:-75.15243005},
{x:26.72521117,y:-80.06500015},
{x:33.82306077,y:-118.33628052},
{x:39.82777945,y:-84.02573649},
{x:35.5929,y:-89.2414},
{x:40.78989506,y:-74.0158561},
{x:36.43312051,y:-99.411128},
{x:32.73196409,y:-94.94307612},
{x:36.10306709,y:-84.58455686},
{x:41.62684413,y:-79.67016464},
{x:39.5623973,y:-76.97206215},
{x:33.74337865,y:-87.04113685},
{x:35.65964876,y:-88.85424159},
{x:45.33126973,y:-122.57386476},
{x:36.20297734,y:-86.73840172},
{x:41.47997703,y:-81.76837467},
{x:40.70397304,y:-73.79916052},
{x:27.98779007,y:-82.12125666},
{x:35.20417469,y:-86.09321251},
{x:26.23196499,y:-80.12870964},
{x:27.85871082,y:-82.20340908},
{x:43.06879701,y:-77.44447464},
{x:34.11908535,y:-117.48903832},
{x:29.52846405,y:-98.56441677},
{x:32.74195296,y:-103.15613582},
{x:34.75148701,y:-77.44658233},
{x:36.524366,y:-80.626104},
{x:32.74595295,y:-96.68329708},
{x:34.81277051,y:-78.98791462},
{x:42.54203524,y:-83.78660045},
{x:37.51777351,y:-120.84863327},
{x:43.083345,y:-76.093212},
{x:37.14911013,y:-92.75355211},
{x:43.67783949,y:-94.44930069},
{x:39.64051892,y:-84.26970775},
{x:34.0648055,y:-117.5602604},
{x:40.82821835,y:-73.0982624},
{x:33.68315982,y:-117.91990717},
{x:32.82397759,y:-97.18551296},
{x:35.3033118,y:-78.60308794},
{x:42.97188154,y:-85.77214105},
{x:40.33551513,y:-104.92445595},
{x:47.23445134,y:-93.51686815},
{x:33.91030802,y:-84.82438392},
{x:33.5097866,y:-112.20136411},
{x:46.14755837,y:-123.91636296},
{x:41.18967787,y:-79.3951634},
{x:30.52297845,y:-86.4952594},
{x:39.72073871,y:-75.55619077},
{x:39.32386052,y:-84.63772151},
{x:33.53469682,y:-82.12696244},
{x:32.97834956,y:-115.54248636},
{x:39.9944364,y:-81.57476649},
{x:40.6817422,y:-73.6154231},
{x:36.26715615,y:-91.51574016},
{x:40.21051792,y:-74.75617981},
{x:39.04184918,y:-94.5698572},
{x:38.96489479,y:-76.84549684},
{x:41.04499641,y:-81.58062181},
{x:39.29209224,y:-84.31068557},
{x:26.02467923,y:-80.22575573},
{x:36.3013049,y:-82.46192113},
{x:36.60044462,y:-121.89407745},
{x:32.26850579,y:-90.26098199},
{x:39.68690742,y:-104.8665759},
{x:43.41443047,y:-83.8995865},
{x:33.91588621,y:-117.32942729},
{x:42.2804995,y:-83.20662504},
{x:35.07501446,y:-89.90580444},
{x:35.18824438,y:-111.6619277},
{x:26.76017017,y:-80.1065433},
{x:35.89992784,y:-78.65213981},
{x:33.26293111,y:-111.8595145},
{x:45.17136693,y:-93.85999264},
{x:32.44380205,y:-94.71975841},
{x:33.14921998,y:-86.74832217},
{x:32.58857758,y:-82.32715331},
{x:38.33571275,y:-122.67768992},
{x:33.41294318,y:-111.54743222},
{x:39.53399495,y:-119.86692041},
{x:36.10553601,y:-86.76416032},
{x:36.6245,y:-87.3191},
{x:31.267431,y:-85.378863},
{x:28.3450821,y:-81.3631047},
{x:39.29208887,y:-83.99045679},
{x:40.59660251,y:-74.36360996},
{x:36.07020182,y:-83.926869},
{x:42.43640327,y:-123.30239524},
{x:44.42933662,y:-83.32964703},
{x:40.4181766,y:-75.34498394},
{x:35.65352725,y:-97.568733},
{x:36.10402551,y:-95.85204818},
{x:44.9494129,y:-93.01814952},
{x:33.0282963,y:-80.16932711},
{x:35.25334299,y:-87.32652633},
{x:28.54736453,y:-81.72223337},
{x:42.8404,y:-83.2722},
{x:42.64912687,y:-85.3013248},
{x:39.62519223,y:-105.10940239},
{x:35.29701428,y:-119.03934218},
{x:35.18178021,y:-84.87405455},
{x:36.23129547,y:-86.5099682},
{x:33.39484742,y:-111.59731938},
{x:43.54439653,y:-96.77397043},
{x:35.24944676,y:-82.20290997},
{x:25.94749714,y:-80.20495533},
{x:30.2097771,y:-84.36565016},
{x:41.2873153,y:-72.88054175},
{x:35.33550385,y:-99.59459788},
{x:38.9795647,y:-76.52167832},
{x:33.5067033,y:-112.34026318},
{x:34.16491977,y:-84.17636457},
{x:35.13168816,y:-106.64295213},
{x:44.29559857,y:-93.29105102},
{x:33.98406682,y:-78.36742446},
{x:40.21852692,y:-76.79139733},
{x:36.63840524,y:-120.62610294},
{x:42.99177249,y:-84.54689741},
{x:36.33609464,y:-94.22600342},
{x:40.06768415,y:-83.01885286},
{x:34.16819019,y:-118.53527194},
{x:40.6933964,y:-118.05591233},
{x:41.9512355,y:-86.34471796},
{x:42.14449097,y:-80.00539935},
{x:30.495208,y:-97.65591998},
{x:33.95199992,y:-98.52733751},
{x:34.18909334,y:-118.94118331},
{x:35.95707923,y:-85.81331313},
{x:32.16387052,y:-110.99009836},
{x:35.67802787,y:-97.49722541},
{x:41.32832939,y:-95.95863193},
{x:37.41726323,y:-121.87572196},
{x:38.59629939,y:-90.27061437},
{x:33.48007988,y:-111.92725182},
{x:35.9156978,y:-94.95325401},
{x:40.80907917,y:-74.37873423},
{x:37.07960151,y:-94.47705992},
{x:28.01861918,y:-82.14450539},
{x:35.37575733,y:-118.93605507},
{x:33.95350373,y:-118.1144388},
{x:40.79441303,y:-81.43915768},
{x:39.64076796,y:-119.71077113},
{x:35.51995954,y:-78.31256349},
{x:39.75688113,y:-104.94095997},
{x:39.53351342,y:-104.79224852},
{x:37.85954924,y:-122.12443227},
{x:29.94197555,y:-95.40795162},
{x:31.44744642,y:-83.5400414},
{x:35.4364799,y:-97.37123497},
{x:42.11776657,y:-80.02363741},
{x:36.83910594,y:-121.39480154},
{x:35.0855214,y:-80.87950213},
{x:39.75910881,y:-84.63689252},
{x:41.10191028,y:-81.43966586},
{x:42.37675576,y:-83.4611003},
{x:36.28003082,y:-115.26565365},
{x:41.27098276,y:-82.82034202},
{x:40.69536631,y:-82.51412625},
{x:29.25662122,y:-81.11366451},
{x:48.21664774,y:-101.29665406},
{x:39.36710997,y:-76.45077277},
{x:28.7264909,y:-82.5523556},
{x:33.68525347,y:-84.00274202},
{x:42.52250693,y:-83.51690554},
{x:34.0647052,y:-83.9841147},
{x:32.33632201,y:-89.14095202},
{x:25.97522311,y:-80.24620653},
{x:41.65265763,y:-83.61882623},
{x:41.41135778,y:-80.35748917},
{x:33.48000135,y:-82.19874292},
{x:35.10990064,y:-106.5866809},
{x:32.73236244,y:-89.53692865},
{x:36.2942,y:-76.2948},
{x:25.92898053,y:-80.18864539},
{x:36.068411,y:-79.270645},
{x:30.33711252,y:-97.69432015},
{x:35.4625331,y:-97.63590802},
{x:40.7984351,y:-73.94057475},
{x:28.51597818,y:-81.48444129},
{x:45.1045145,y:-93.45710376},
{x:43.06831001,y:-76.17176269},
{x:40.75004907,y:-73.88327499},
{x:42.77382376,y:-83.23956325},
{x:29.01345131,y:-80.94385197},
{x:39.98309006,y:-76.73424777},
{x:41.37458573,y:-83.6465304},
{x:41.12736797,y:-75.30708283},
{x:40.55159532,y:-74.43181112},
{x:28.97083274,y:-95.36990258},
{x:33.83904275,y:-83.90541291},
{x:38.00292044,y:-121.29143342},
{x:33.15177403,y:-117.19787259},
{x:45.40503876,y:-122.62304266},
{x:41.20471947,y:-96.12775058},
{x:38.774,y:-92.2505},
{x:43.28743204,y:-76.12883509},
{x:40.59825349,y:-74.48503111},
{x:29.77443648,y:-95.7512208},
{x:35.14604046,y:-119.4696866},
{x:35.81947937,y:-90.67747195},
{x:36.2966668,y:-119.31413558},
{x:40.58494202,y:-80.03712115},
{x:31.9972792,y:-106.58785803},
{x:34.57943061,y:-118.12988962},
{x:33.48380804,y:-80.46689577},
{x:43.20027462,y:-86.26270035},
{x:35.99136266,y:-115.10156572},
{x:32.92721388,y:-85.96291696},
{x:30.33569688,y:-95.09714945},
{x:36.17825029,y:-95.97506408},
{x:43.62405195,y:-83.89132259},
{x:40.77631189,y:-73.95263852},
{x:32.43394904,y:-97.77862324},
{x:35.55682228,y:-77.40676542},
{x:32.41891738,y:-86.23232139},
{x:36.19618355,y:-115.2269078},
{x:33.84483547,y:-117.98708572},
{x:27.9045268,y:-81.83294684},
{x:43.41979902,y:-70.75575382},
{x:38.28781922,y:-90.39988894},
{x:46.35708088,y:-94.24526094},
{x:43.90556048,y:-69.91462038},
{x:38.50450707,y:-90.6437231},
{x:43.11423111,y:-75.2763395},
{x:38.41366579,y:-90.5824547},
{x:26.5465854,y:-81.79884418},
{x:39.35698715,y:-76.70428121},
{x:34.69195865,y:-82.8385748},
{x:26.0082534,y:-80.29998422},
{x:45.00710282,y:-122.79030848},
{x:41.6832163,y:-81.34072897},
{x:45.84728607,y:-95.3871145},
{x:35.45776196,y:-86.05468526},
{x:34.27137014,y:-118.77117902},
{x:25.92146922,y:-80.29354013},
{x:43.24982523,y:-83.77950937},
{x:38.58927117,y:-121.41492878},
{x:41.27340483,y:-73.7831076},
{x:31.53197476,y:-97.19410574},
{x:37.22747214,y:-121.77565322},
{x:36.13585913,y:-95.85166335},
{x:33.76396252,y:-116.30119582},
{x:31.32260222,y:-89.36489542},
{x:33.42597,y:-111.939741},
{x:33.43599022,y:-112.55829305},
{x:26.01264082,y:-80.17608856},
{x:34.91851646,y:-110.15030823},
{x:33.6097206,y:-112.22123699},
{x:36.63200136,y:-95.14300648},
{x:31.80228623,y:-95.14850155},
{x:38.05872111,y:-122.16725528},
{x:42.50542322,y:-83.08852254},
{x:38.85864005,y:-84.61982712},
{x:36.80866838,y:-119.86387074},
{x:40.89570957,y:-81.40601724},
{x:28.39977,y:-81.49082},
{x:39.28511084,y:-84.44557348},
{x:32.05027688,y:-110.70713309},
{x:35.09125104,y:-92.42576838},
{x:36.04832683,y:-86.71312972},
{x:45.3774926,y:-122.60302472},
{x:44.94620971,y:-123.04925187},
{x:44.97170958,y:-93.43409064},
{x:41.1728921,y:-79.09915815},
{x:37.96108555,y:-121.9833869},
{x:40.96791426,y:-81.34413019},
{x:33.415171,y:-82.181857},
{x:35.04651984,y:-89.69154991},
{x:30.17966156,y:-82.67165069},
{x:26.61458226,y:-81.87160809},
{x:37.31734759,y:-91.95969851},
{x:37.54069853,y:-122.02695988},
{x:41.6935673,y:-83.640015},
{x:38.33849628,y:-92.58408121},
{x:40.78493863,y:-77.8289773},
{x:37.8078671,y:-92.2235828},
{x:39.73485847,y:-82.62608186},
{x:40.12453901,y:-82.98084006},
{x:40.29166476,y:-74.03757476},
{x:40.24514323,y:-82.86654326},
{x:36.35144425,y:-119.29740657},
{x:29.44817679,y:-95.07804386},
{x:41.92120076,y:-72.67117403},
{x:34.23386093,y:-77.99553087},
{x:35.49839569,y:-82.52162904},
{x:37.18268731,y:-93.29921365},
{x:42.17169339,y:-83.18975724},
{x:37.86781792,y:-122.25762737},
{x:42.47013272,y:-82.90637615},
{x:42.64942976,y:-83.5263687},
{x:36.73452041,y:-120.04271436},
{x:36.16270554,y:-86.77604333},
{x:42.99896789,y:-84.11793796},
{x:39.1763805,y:-119.73280139},
{x:39.14192404,y:-121.62236586},
{x:33.52345549,y:-112.04919815},
{x:42.71559487,y:-82.96126945},
{x:39.09032543,y:-108.454218},
{x:28.05542893,y:-81.7781201},
{x:38.67890801,y:-121.22933775},
{x:41.69135506,y:-83.56254864},
{x:42.46193495,y:-83.0921343},
{x:29.78449962,y:-82.02775788},
{x:30.68074999,y:-83.22043366},
{x:29.70595819,y:-95.47885314},
{x:31.21702476,y:-85.36042039},
{x:34.47528194,y:-84.92862649},
{x:27.04357584,y:-82.23575299},
{x:45.51877271,y:-122.56642163},
{x:35.68732189,y:-79.82332347},
{x:40.57421552,y:-105.10395308},
{x:41.23890131,y:-81.3434507},
{x:26.52947045,y:-80.1223442},
{x:33.87309084,y:-118.37253995},
{x:38.6259338,y:-90.28742853},
{x:39.1880937,y:-84.60133533},
{x:36.70572649,y:-119.77035172},
{x:41.00900501,y:-81.55470454},
{x:37.9427676,y:-121.6957283},
{x:32.67281618,y:-114.44288771},
{x:34.93394909,y:-120.41885728},
{x:36.17907971,y:-81.14610234},
{x:41.66061823,y:-81.36391855},
{x:35.73371417,y:-79.42830749},
{x:36.16930853,y:-86.68316195},
{x:28.05492578,y:-82.39480947},
{x:37.91330694,y:-122.0652317},
{x:41.118839,y:-81.481447},
{x:35.40636098,y:-118.85982176},
{x:31.23346093,y:-84.19954658},
{x:43.24125788,y:-77.64647216},
{x:36.738192,y:-97.06987381},
{x:42.46013645,y:-123.32224761},
{x:35.60485166,y:-87.06626952},
{x:28.03474582,y:-80.63173265},
{x:47.49473535,y:-111.27440751},
{x:26.93359547,y:-80.07929872},
{x:32.60943622,y:-85.44885283},
{x:39.58818609,y:-82.9545439},
{x:36.00441404,y:-95.68129957},
{x:36.0614176,y:-79.4008981},
{x:33.03892301,y:-96.70779969},
{x:33.9758793,y:-98.50719072},
{x:32.91547214,y:-80.67344813},
{x:41.63586478,y:-72.87333906},
{x:34.216861,y:-82.157881},
{x:35.99753711,y:-88.43946289},
{x:35.36803247,y:-80.66622234},
{x:33.84955122,y:-87.28119493},
{x:37.30233419,y:-121.85949594},
{x:39.662509,y:-106.819427},
{x:41.35206208,y:-74.73220378},
{x:31.95842095,y:-81.325256},
{x:32.34552377,y:-97.39434794},
{x:42.05386807,y:-124.28801835},
{x:34.53029007,y:-83.97806145},
{x:40.72744245,y:-73.70829896},
{x:35.78429438,y:-78.6895296},
{x:30.70108018,y:-86.12210663},
{x:32.48190805,y:-85.03177},
{x:42.73333069,y:-84.47620206},
{x:41.52007658,y:-71.29908949},
{x:41.10710735,y:-80.65230531},
{x:36.13766915,y:-80.24085586},
{x:36.18119736,y:-94.49834546},
{x:26.62692411,y:-80.08830924},
{x:36.10823604,y:-80.09568776},
{x:31.77260673,y:-106.49979611},
{x:38.6758739,y:-121.7464757},
{x:40.66038866,y:-73.68205356},
{x:39.69630454,y:-104.81132106},
{x:30.25265555,y:-94.19707735},
{x:26.15425113,y:-80.25770612},
{x:36.97431797,y:-120.08585224},
{x:41.1358365,y:-81.80558584},
{x:30.05622578,y:-94.79723999},
{x:35.76829162,y:-91.64135729},
{x:39.84545185,y:-77.19905501},
{x:38.54822098,y:-92.14979739},
{x:34.4380186,y:-79.37123939},
{x:34.1357,y:-116.088},
{x:42.31332867,y:-122.85671784},
{x:35.25865406,y:-80.78900363},
{x:32.90381293,y:-96.12612902},
{x:33.9222193,y:-116.8055313},
{x:38.23808321,y:-122.63556361},
{x:42.68711057,y:-83.38248094},
{x:32.94434371,y:-112.73345593},
{x:26.61966567,y:-80.14290333},
{x:44.39864814,y:-122.72650976},
{x:31.23937075,y:-89.81925649},
{x:42.96630756,y:-71.48056448},
{x:35.10038776,y:-114.59762707},
{x:40.25708066,y:-75.2681306},
{x:34.0268892,y:-84.33440369},
{x:33.65650331,y:-117.91974758},
{x:34.75329026,y:-86.74722328},
{x:36.8367737,y:-94.39451947},
{x:39.77046906,y:-76.67207642},
{x:45.57669529,y:-122.66169755},
{x:25.93922776,y:-80.27937412},
{x:41.28800505,y:-96.0807245},
{x:41.87963533,y:-79.15216282},
{x:41.94929016,y:-70.71563594},
{x:39.95350604,y:-75.16584552},
{x:34.89358065,y:-117.00372005},
{x:39.97436148,y:-82.8959556},
{x:34.17230665,y:-118.33469738},
{x:38.83808221,y:-104.7949402},
{x:40.52592875,y:-80.00613342},
{x:34.14048647,y:-117.98849475},
{x:40.72940057,y:-73.86243638},
{x:34.20148954,y:-92.00744458},
{x:34.12847535,y:-117.86290146},
{x:33.86610184,y:-112.13813847},
{x:28.6734146,y:-81.50519577},
{x:36.09960263,y:-115.11497954},
{x:40.76106882,y:-73.96878812},
{x:34.62301387,y:-98.47547659},
{x:40.74263768,y:-96.68183411},
{x:32.87617613,y:-105.96053461},
{x:30.91142813,y:-93.99340161},
{x:34.22842004,y:-118.52772521},
{x:38.85235231,y:-94.38067496},
{x:36.3513,y:-119.426},
{x:27.01459927,y:-82.05367416},
{x:40.75787068,y:-75.59335653},
{x:41.25930822,y:-96.02193556},
{x:30.51735023,y:-97.68934317},
{x:33.37947752,y:-112.16758177},
{x:38.898043,y:-77.006172},
{x:40.58708792,y:-81.52466135},
{x:32.46207935,y:-86.45031629},
{x:43.3369,y:-83.7387},
{x:36.69616478,y:-97.34927796},
{x:30.05114681,y:-99.13587481},
{x:38.23712675,y:-104.6628508},
{x:33.33522426,y:-86.78426211},
{x:33.49084224,y:-80.85470259},
{x:37.59664974,y:-93.40343893},
{x:40.12175115,y:-84.62069873},
{x:31.60856803,y:-84.21019038},
{x:39.601,y:-94.7911},
{x:33.52510466,y:-117.16031939},
{x:39.22502685,y:-76.07014865},
{x:37.38846057,y:-120.73849846},
{x:30.73358597,y:-86.56574572},
{x:41.54999014,y:-73.41781201},
{x:41.81489529,y:-71.4954076},
{x:40.033675,y:-105.259074},
{x:34.13025203,y:-118.0549733},
{x:42.61500229,y:-82.54280849},
{x:40.98427673,y:-81.49233065},
{x:36.17256713,y:-115.2437931},
{x:35.23856421,y:-106.65728241},
{x:40.73199303,y:-74.06665573},
{x:39.20769047,y:-76.72582537},
{x:33.88159261,y:-118.39629985},
{x:45.76943919,y:-108.57517527},
{x:33.21880958,y:-96.67820695},
{x:34.10391852,y:-83.97431801},
{x:42.80323762,y:-86.11414168},
{x:35.43832155,y:-97.4852698},
{x:36.25168202,y:-94.13651349},
{x:39.8889535,y:-82.99845372},
{x:40.76952399,y:-74.04374891},
{x:35.09454446,y:-81.66559584},
{x:34.64856812,y:-86.03355829},
{x:37.16099971,y:-93.27760135},
{x:36.3236,y:-119.35},
{x:35.83348999,y:-78.31484337},
{x:37.70612532,y:-121.72215106},
{x:32.80984513,y:-97.42525182},
{x:32.77399193,y:-111.61742839},
{x:29.6469315,y:-95.65079472},
{x:30.82766769,y:-89.13556397},
{x:34.37751988,y:-84.91573467},
{x:40.649364,y:-73.891575},
{x:43.88568511,y:-85.52351399},
{x:33.938226,y:-117.553122},
{x:40.57967936,y:-75.45481093},
{x:32.93423216,y:-96.80339997},
{x:34.72579801,y:-76.76679864},
{x:21.44419623,y:-157.74382085},
{x:34.29380184,y:-77.87242382},
{x:30.3753,y:-89.3743},
{x:38.50894006,y:-90.32310694},
{x:32.80085004,y:-96.95793692},
{x:40.56241729,y:-75.56179021},
{x:30.18600084,y:-81.73982815},
{x:40.01161217,y:-82.86656816},
{x:38.5932813,y:-90.55580525},
{x:32.44964267,y:-100.3967782},
{x:40.48007626,y:-78.01665941},
{x:40.54319413,y:-80.19264196},
{x:39.26628442,y:-93.96128476},
{x:32.87183903,y:-95.7648906},
{x:34.72811525,y:-88.94584067},
{x:27.74605898,y:-97.42037279},
{x:34.49502351,y:-87.72590296},
{x:45.51889863,y:-122.47232437},
{x:33.7192,y:-85.8184},
{x:42.32247892,y:-83.48488226},
{x:39.11236116,y:-82.53532306},
{x:38.24455922,y:-122.01996405},
{x:34.12164984,y:-83.76413274},
{x:34.92605073,y:-120.43646966},
{x:40.81555319,y:-74.47340306},
{x:34.08851176,y:-117.74968882},
{x:36.71659691,y:-91.87256791},
{x:42.45818612,y:-83.92965004},
{x:32.95697359,y:-117.04657225},
{x:40.75651619,y:-81.51691332},
{x:42.67961405,y:-74.47440555},
{x:41.1443224,y:-96.1635845},
{x:42.90747283,y:-76.83736928},
{x:34.49290086,y:-81.9838315},
{x:36.33423972,y:-86.61859985},
{x:36.5138618,y:-121.43613406},
{x:24.56217102,y:-81.77695575},
{x:33.54462983,y:-85.07332758},
{x:35.22133985,y:-93.16508768},
{x:34.24278094,y:-85.1616659},
{x:43.17595163,y:-83.88732536},
{x:45.00370606,y:-93.22792484},
{x:37.63838723,y:-121.00022346},
{x:39.78369977,y:-83.99290463},
{x:36.16721231,y:-94.11217645},
{x:42.9130774,y:-85.59700464},
{x:28.33357932,y:-81.4803654},
{x:40.57640704,y:-75.62218847},
{x:40.68333962,y:-73.35292795},
{x:35.59534997,y:-97.54978866},
{x:32.52636197,y:-86.20746476},
{x:37.58688247,y:-122.02133015},
{x:38.42306027,y:-121.42518989},
{x:40.50309584,y:-74.39996107},
{x:34.66075831,y:-118.180292},
{x:27.49775572,y:-81.48751602},
{x:35.09815324,y:-80.77895607},
{x:31.24719063,y:-90.4666716},
{x:32.6795,y:-115.502},
{x:41.52122012,y:-74.06999668},
{x:36.02089662,y:-86.577752},
{x:33.69407347,y:-84.09623192},
{x:33.5626106,y:-85.1116358},
{x:34.75539414,y:-79.48241822},
{x:41.21104041,y:-96.02446105},
{x:42.29931568,y:-85.19902557},
{x:34.6715681,y:-98.4084419},
{x:28.18629082,y:-80.59330917},
{x:32.52044115,y:-94.85980952},
{x:35.27587975,y:-82.70218954},
{x:28.40590183,y:-81.45819582},
{x:43.16445161,y:-77.69061671},
{x:38.24735265,y:-90.55812934},
{x:41.02567542,y:-80.3542614},
{x:34.82168677,y:-89.97160976},
{x:37.05639504,y:-120.88030322},
{x:38.91266043,y:-94.44654034},
{x:39.6156923,y:-104.9875968},
{x:40.60494855,y:-73.99835681},
{x:41.44028764,y:-81.72725375},
{x:45.40135406,y:-122.27979004},
{x:42.75273026,y:-78.84797198},
{x:36.06657594,y:-80.25761068},
{x:40.43306288,y:-74.18247409},
{x:40.17401876,y:-122.23143706},
{x:34.77714873,y:-92.20208381},
{x:37.89407047,y:-122.29989063},
{x:39.24676749,y:-94.57431811},
{x:34.74048832,y:-77.97622277},
{x:45.18517179,y:-93.31442351},
{x:45.03531276,y:-92.8119959},
{x:31.39950247,y:-81.45103734},
{x:39.12535703,y:-76.53116767},
{x:41.19682502,y:-73.19275716},
{x:40.75744047,y:-73.79353232},
{x:36.67353167,y:-93.87416923},
{x:44.92226647,y:-122.98315674},
{x:33.58226269,y:-111.97857589},
{x:38.51898371,y:-90.34223757},
{x:61.57662201,y:-149.40578976},
{x:33.89932869,y:-117.56312691},
{x:45.52340921,y:-122.69408114},
{x:38.73086798,y:-120.78617111},
{x:26.3243286,y:-81.8064166},
{x:29.64413415,y:-81.60494257},
{x:32.04750604,y:-81.16692333},
{x:35.08375987,y:-85.06273764},
{x:32.93572467,y:-80.04548375},
{x:32.69681758,y:-100.91866786},
{x:40.82637497,y:-73.86065647},
{x:41.44190151,y:-71.48313423},
{x:35.11737596,y:-101.90542739},
{x:34.22184249,y:-119.06766536},
{x:27.43308829,y:-82.57586376},
{x:40.58786637,y:-122.34636973},
{x:43.00655792,y:-77.44407512},
{x:37.56582221,y:-122.31966867},
{x:29.81291406,y:-94.90100606},
{x:35.76211255,y:-77.99899487},
{x:41.99490353,y:-76.52939668},
{x:37.09083277,y:-84.61773077},
{x:41.12558315,y:-73.39409322},
{x:42.91539812,y:-83.72419463},
{x:32.26879576,y:-81.08106975},
{x:35.82080838,y:-78.84972431},
{x:33.46601611,y:-112.27329259},
{x:41.35999294,y:-72.11635061},
{x:40.83793744,y:-81.26080647},
{x:38.81346634,y:-104.67686916},
{x:31.12549709,y:-97.72388448},
{x:40.47218466,y:-83.89431817},
{x:35.13628157,y:-90.48803911},
{x:42.87985182,y:-78.69752581},
{x:40.00616423,y:-86.12656458},
{x:33.71289468,y:-117.98861772},
{x:33.43315316,y:-104.52243122},
{x:44.02475,y:-71.118342},
{x:42.26129277,y:-84.75579047},
{x:35.865837,y:-92.110367},
{x:40.64542489,y:-79.71035951},
{x:39.10085315,y:-75.54387515},
{x:37.42160038,y:-122.10082367},
{x:40.7444,y:-73.9958},
{x:29.80195517,y:-98.4190346},
{x:43.03346388,y:-83.63298811},
{x:45.75566037,y:-108.53895555},
{x:40.6064,y:-79.603},
{x:35.6526055,y:-78.3505418},
{x:41.3142893,y:-81.51877369},
{x:26.23359038,y:-98.17678143},
{x:29.7901769,y:-95.5450514},
{x:34.07691034,y:-98.55837533},
{x:45.71524369,y:-111.06954934},
{x:40.14374129,y:-74.82069522},
{x:37.73682699,y:-122.40533997},
{x:34.42952672,y:-119.690087},
{x:33.32936853,y:-111.9799879},
{x:42.71410991,y:-73.81471427},
{x:28.60352429,y:-81.54805166},
{x:26.5716741,y:-80.06709449},
{x:28.01987848,y:-82.75576733},
{x:26.8081,y:-80.09468235},
{x:34.8164501,y:-86.49400859},
{x:43.07791622,y:-75.6419903},
{x:33.72509405,y:-84.9389302},
{x:36.38015909,y:-81.48666985},
{x:40.7166226,y:-74.03770296},
{x:36.97224138,y:-120.04448448},
{x:34.28412083,y:-79.86300409},
{x:32.61789892,y:-83.67294594},
{x:33.3943793,y:-111.80479025},
{x:35.42925875,y:-80.60871191},
{x:38.64625411,y:-121.11713483},
{x:37.74691079,y:-122.19566991},
{x:42.80602736,y:-85.5065858},
{x:28.08001137,y:-82.43543522},
{x:33.33601471,y:-111.75711578},
{x:34.71916315,y:-86.57839558},
{x:40.1350803,y:-83.01621011},
{x:40.51589741,y:-74.28643674},
{x:34.1884788,y:-79.83325142},
{x:28.09880075,y:-82.50290394},
{x:36.04406331,y:-114.97495741},
{x:41.20526297,y:-80.73941812},
{x:35.47268374,y:-80.26880748},
{x:40.76528584,y:-73.95755211},
{x:33.1248265,y:-89.08382663},
{x:38.28885801,y:-76.48455955},
{x:37.70065397,y:-121.90882102},
{x:37.63865053,y:-120.9281954},
{x:34.75202375,y:-92.32265771},
{x:39.63866303,y:-76.86702605},
{x:40.70109566,y:-73.37790518},
{x:35.669857,y:-80.85302219},
{x:40.87006599,y:-82.6463991},
{x:43.95659447,y:-86.38989151},
{x:30.34735723,y:-97.38362977},
{x:34.02947608,y:-118.45070967},
{x:32.88058475,y:-83.76469382},
{x:35.44580254,y:-89.7859325},
{x:33.94586214,y:-118.26566429},
{x:41.42821202,y:-81.89038396},
{x:34.96310993,y:-90.05914331},
{x:35.24792819,y:-90.78205239},
{x:40.90134079,y:-80.69714506},
{x:34.20658155,y:-86.87470143},
{x:40.83482875,y:-115.78682546},
{x:35.8628786,y:-76.73126701},
{x:34.79537362,y:-84.96162228},
{x:38.19806832,y:-85.69570392},
{x:39.90956568,y:-82.0155931},
{x:36.64047026,y:-93.25797173},
{x:39.76659115,y:-105.61484454},
{x:38.27119367,y:-122.03384583},
{x:29.38648243,y:-82.44915069},
{x:38.49499674,y:-121.09713494},
{x:39.74029976,y:-104.96641208},
{x:34.07480768,y:-80.99711108},
{x:45.20739133,y:-93.40347648},
{x:43.69938395,y:-70.28726847},
{x:40.13,y:-80.0007},
{x:34.42631264,y:-117.35756902},
{x:36.60527938,y:-119.53020276},
{x:38.66367322,y:-94.36662719},
{x:38.89252967,y:-104.75640357},
{x:31.86749012,y:-89.73890643},
{x:31.7061829,y:-83.25314131},
{x:42.42028817,y:-123.35189368},
{x:35.92716407,y:-89.87534613},
{x:41.64310946,y:-70.8847817},
{x:36.17559786,y:-94.17835057},
{x:33.7101336,y:-112.2711955},
{x:32.90879203,y:-96.64773582},
{x:35.13199307,y:-86.56609196},
{x:35.21385506,y:-90.03220819},
{x:34.79587167,y:-77.43821443},
{x:43.05911058,y:-77.64387066},
{x:41.23295129,y:-96.04367633},
{x:25.69895333,y:-80.38254352},
{x:34.17353465,y:-118.29354144},
{x:39.02288234,y:-94.19716358},
{x:39.1183023,y:-94.3776123},
{x:41.74960448,y:-83.62366766},
{x:39.41236809,y:-81.43412411},
{x:35.15568851,y:-103.72456658},
{x:28.00432502,y:-81.7312297},
{x:38.2587974,y:-122.06710254},
{x:40.076645,y:-83.12635659},
{x:42.018614,y:-97.42937006},
{x:42.66852494,y:-83.0342743},
{x:42.97589743,y:-82.45334157},
{x:35.75656588,y:-78.74081303},
{x:40.58400028,y:-105.0561142},
{x:33.71627055,y:-117.85093684},
{x:33.92546884,y:-84.17363527},
{x:45.48672034,y:-122.74437301},
{x:35.85858526,y:-97.42484942},
{x:43.51390227,y:-96.77064255},
{x:36.87147061,y:-119.7641077},
{x:40.73608591,y:-74.02948191},
{x:29.22136973,y:-82.14614546},
{x:36.017825,y:-95.80237408},
{x:30.37851468,y:-88.52709804},
{x:37.29622272,y:-107.87236221},
{x:31.4933925,y:-97.1227755},
{x:40.03377605,y:-79.07490077},
{x:40.34794134,y:-79.94779542},
{x:36.6477672,y:-87.453696},
{x:29.45737741,y:-98.55585203},
{x:37.98849286,y:-121.7851903},
{x:35.46040987,y:-94.35567661},
{x:35.39543902,y:-118.9680209},
{x:34.83873603,y:-92.23685618},
{x:38.54685119,y:-121.74010679},
{x:33.87319287,y:-117.99866981},
{x:39.8080848,y:-82.2996971},
{x:40.19836618,y:-105.10193959},
{x:32.16226001,y:-110.94276845},
{x:33.5972,y:-117.242},
{x:34.19621122,y:-118.60627525},
{x:35.60412016,y:-95.96226482},
{x:44.95026273,y:-74.8443616},
{x:35.22843541,y:-80.9239635},
{x:30.45477099,y:-97.603392},
{x:41.884723,y:-86.60002878},
{x:36.32256381,y:-95.82507133},
{x:35.7023225,y:-82.57571308},
{x:38.54767634,y:-90.29190503},
{x:34.66307055,y:-120.45733941},
{x:33.6194067,y:-84.24365535},
{x:39.68082691,y:-105.02457619},
{x:40.7020741,y:-73.42750952},
{x:38.35184147,y:-75.60178005},
{x:28.30485369,y:-81.42185122},
{x:41.71597129,y:-83.68421048},
{x:36.69492295,y:-121.59690856},
{x:32.64392391,y:-85.34819097},
{x:40.6458011,y:-73.95834143},
{x:43.31487288,y:-73.61006531},
{x:43.40725345,y:-85.80408816},
{x:41.58131382,y:-73.03356443},
{x:39.80167306,y:-105.10058852},
{x:41.72784885,y:-72.61733956},
{x:39.71350817,y:-105.05370252},
{x:35.41199547,y:-99.41793619},
{x:33.75765008,y:-111.99038453},
{x:32.71809437,y:-103.14114509},
{x:33.96773333,y:-118.18710365},
{x:39.84222836,y:-105.05532503},
{x:25.73647271,y:-80.24552827},
{x:42.42913496,y:-76.50748377},
{x:33.35646224,y:-112.42962246},
{x:28.08331907,y:-82.5425046},
{x:41.032298,y:-81.490899},
{x:34.28310655,y:-81.58978388},
{x:35.08143287,y:-106.61298062},
{x:38.16963731,y:-92.61083637},
{x:26.31880125,y:-80.09013459},
{x:41.38022297,y:-81.73560441},
{x:33.10143627,y:-96.67527664},
{x:34.64269221,y:-82.46656061},
{x:38.55488098,y:-92.19907474},
{x:32.91534625,y:-80.10869806},
{x:33.48529516,y:-89.77011112},
{x:42.61250584,y:-73.78748124},
{x:42.08384763,y:-86.43613815},
{x:43.10618907,y:-77.75186686},
{x:35.12491712,y:-90.05452931},
{x:28.65773948,y:-81.17707945},
{x:38.02059131,y:-121.31903176},
{x:27.95943429,y:-81.6239942},
{x:40.33730597,y:-105.08502155},
{x:41.7015,y:-83.6571},
{x:40.10685041,y:-83.73721897},
{x:32.81078797,y:-115.57038729},
{x:42.6841853,y:-73.79542217},
{x:35.25492603,y:-80.45061029},
{x:33.11540135,y:-96.84183434},
{x:34.02676613,y:-117.89514918},
{x:40.67449839,y:-73.48658786},
{x:37.88327289,y:-90.51067459},
{x:35.53419621,y:-108.75833049},
{x:38.10490781,y:-122.23198987},
{x:36.0612166,y:-95.96988299},
{x:34.99201225,y:-78.35184462},
{x:44.97212888,y:-93.36443804},
{x:35.10588278,y:-84.00896564},
{x:40.82841209,y:-73.9253181},
{x:32.7544667,y:-96.87317361},
{x:34.79024302,y:-86.9722497},
{x:41.92548165,y:-83.36284915},
{x:39.3614922,y:-94.583833},
{x:39.15993866,y:-84.27693987},
{x:43.40865371,y:-86.32327639},
{x:29.5451438,y:-95.74617441},
{x:39.15113215,y:-108.73766519},
{x:40.68412434,y:-74.09943928},
{x:39.81931165,y:-76.99576698},
{x:34.15946883,y:-85.70466823},
{x:35.40565848,y:-94.51763452},
{x:44.69667752,y:-73.48421313},
{x:46.83926748,y:-114.03117806},
{x:34.31584399,y:-88.70478541},
{x:41.46386462,y:-81.68889358},
{x:27.89390065,y:-82.31717364},
{x:40.88286864,y:-73.82470974},
{x:34.97055512,y:-80.99033131},
{x:43.20541969,y:-86.19753324},
{x:42.86388323,y:-84.88802454},
{x:40.5867772,y:-77.60671112},
{x:35.26411156,y:-116.07624292},
{x:36.35743579,y:-94.25438052},
{x:35.93118205,y:-86.53334677},
{x:36.46804018,y:-86.67751107},
{x:42.22473018,y:-83.48563148},
{x:27.43267934,y:-82.38662157},
{x:39.39672094,y:-84.55284481},
{x:29.69254102,y:-95.68858875},
{x:27.29681703,y:-81.35773959},
{x:28.01185114,y:-82.70855986},
{x:42.23161786,y:-83.38627657},
{x:41.15401065,y:-81.34729524},
{x:36.73977958,y:-95.9344997},
{x:33.87271844,y:-117.74574794},
{x:35.32326917,y:-119.12750348},
{x:41.30800922,y:-81.44084371},
{x:33.17787884,y:-111.58323007},
{x:41.41691912,y:-75.6653566},
{x:45.55650705,y:-122.68185353},
{x:36.415948,y:-94.810721},
{x:32.66322661,y:-83.74002621},
{x:36.29605455,y:-76.22526535},
{x:29.90349865,y:-96.90902654},
{x:32.75833181,y:-97.166618},
{x:35.76957563,y:-77.93943279},
{x:41.21216556,y:-95.98636383},
{x:35.4272238,y:-78.0632046},
{x:41.27718658,y:-72.98962213},
{x:43.1712865,y:-77.6273467},
{x:40.14316598,y:-83.0930082},
{x:34.01441571,y:-81.15432237},
{x:37.71398398,y:-122.15739548},
{x:42.478732,y:-82.988893},
{x:29.18677243,y:-82.179291},
{x:41.61502823,y:-70.93633651},
{x:27.24406087,y:-80.82502827},
{x:42.01607966,y:-71.48018229},
{x:30.09640638,y:-83.58208211},
{x:32.768511,y:-96.62746395},
{x:44.725337,y:-85.640195},
{x:35.90841756,y:-88.75218698},
{x:41.44760218,y:-81.56517054},
{x:40.3237676,y:-75.61708063},
{x:37.78300628,y:-122.41898438},
{x:34.13491932,y:-117.5581166},
{x:38.81230209,y:-104.82190434},
{x:32.850661,y:-96.99289401},
{x:41.31343693,y:-81.73114904},
{x:40.70782712,y:-73.6770827},
{x:28.3312299,y:-80.72642874},
{x:32.73736301,y:-79.96653491},
{x:39.79984373,y:-94.81425364},
{x:44.04759082,y:-123.13498015},
{x:39.25380502,y:-84.29509968},
{x:40.5695813,y:-74.29164559},
{x:33.9175,y:-117.939},
{x:38.61148186,y:-90.32276848},
{x:35.363848,y:-85.393519},
{x:30.41201016,y:-88.78137386},
{x:40.77028828,y:-73.50168367},
{x:43.1163304,y:-77.62199811},
{x:35.13510104,y:-80.9383104},
{x:39.42446386,y:-79.39334006},
{x:38.92856965,y:-119.98228286},
{x:38.95342786,y:-94.52352726},
{x:33.50836652,y:-84.14265096},
{x:42.9252722,y:-83.9898488},
{x:37.13921346,y:-93.32729904},
{x:35.29154059,y:-97.6105953},
{x:44.94801961,y:-93.27310563},
{x:30.34586517,y:-97.9643995},
{x:33.81723293,y:-117.9149564},
{x:34.84376902,y:-86.71168966},
{x:45.44308541,y:-122.62819116},
{x:25.76572461,y:-80.21027102},
{x:45.5942244,y:-122.99555023},
{x:30.45271586,y:-86.63803451},
{x:33.90492544,y:-117.47472525},
{x:38.82557941,y:-82.16802886},
{x:41.63644732,y:-83.47772558},
{x:35.74665666,y:-81.36560512},
{x:42.27668819,y:-83.26996751},
{x:45.60883449,y:-121.20293758},
{x:30.42340866,y:-89.09181207},
{x:34.18821721,y:-118.31914231},
{x:29.86070511,y:-95.472975},
{x:40.99930721,y:-75.2617936},
{x:41.79999719,y:-71.89985005},
{x:39.73754789,y:-81.52662038},
{x:31.68822062,y:-89.14703912},
{x:40.06000639,y:-82.97368526},
{x:41.71470644,y:-71.48863777},
{x:36.20491338,y:-81.66803017},
{x:33.45365711,y:-88.819765},
{x:39.80598723,y:-104.93023351},
{x:28.14532685,y:-80.67222346},
{x:39.79222849,y:-105.08033323},
{x:34.10743844,y:-117.66522376},
{x:34.14067139,y:-118.07370845},
{x:33.69879495,y:-112.14042842},
{x:47.49120241,y:-94.88759463},
{x:34.86972769,y:-82.69096963},
{x:40.89329725,y:-83.87215975},
{x:40.51823477,y:-74.49033941},
{x:42.77159267,y:-84.41789484},
{x:42.30032198,y:-71.40843017},
{x:38.54479243,y:-106.93031345},
{x:34.60845997,y:-98.42176749},
{x:40.8974194,y:-74.10334094},
{x:42.27037885,y:-84.45233552},
{x:38.60173533,y:-121.4274328},
{x:39.83520268,y:-75.79429397},
{x:30.02391092,y:-93.84374207},
{x:41.9491,y:-83.401},
{x:41.59471634,y:-73.11161532},
{x:42.33073527,y:-122.87176028},
{x:28.34617965,y:-81.64492095},
{x:45.16173068,y:-93.16198545},
{x:37.98707735,y:-121.24699742},
{x:35.54028726,y:-97.56608538},
{x:40.15561013,y:-74.2305357},
{x:35.71998153,y:-77.94819193},
{x:29.53458069,y:-95.51705464},
{x:32.36261181,y:-106.74723914},
{x:32.61342591,y:-97.11799279},
{x:32.75675263,y:-96.46600664},
{x:36.1701073,y:-86.50850586},
{x:30.17980915,y:-81.55406301},
{x:41.15669891,y:-80.58177568},
{x:40.85349738,y:-73.90731432},
{x:29.38187967,y:-98.63922127},
{x:33.43490635,y:-112.3032251},
{x:40.89282138,y:-73.85743711},
{x:40.70042009,y:-73.62832551},
{x:40.22343066,y:-76.93220577},
{x:38.79167784,y:-90.57457745},
{x:42.4932319,y:-83.18321581},
{x:29.17133341,y:-81.0392438},
{x:27.33866871,y:-82.45939536},
{x:43.05229803,y:-86.22120213},
{x:44.86235403,y:-93.54529023},
{x:40.24678891,y:-77.02526322},
{x:36.24019647,y:-115.11878117},
{x:41.33771165,y:-72.9801268},
{x:37.98123277,y:-122.06749428},
{x:45.45670842,y:-122.78307438},
{x:41.20441878,y:-96.08177751},
{x:37.75350448,y:-121.4348153},
{x:38.80564392,y:-90.30635481},
{x:40.153169,y:-105.09220534},
{x:32.50981933,y:-95.64366743},
{x:41.76236986,y:-72.63274202},
{x:35.20484536,y:-89.73394898},
{x:47.90883354,y:-97.04455323},
{x:35.90688114,y:-90.58903319},
{x:32.78510563,y:-97.06096327},
{x:33.18046009,y:-86.61298093},
{x:40.7492043,y:-74.13492993},
{x:40.56645319,y:-83.12040136},
{x:40.6443418,y:-74.01147223},
{x:40.4199,y:-74.4981},
{x:35.15246601,y:-84.95023232},
{x:28.45540587,y:-81.39884011},
{x:39.7101342,y:-105.02475939},
{x:26.0121204,y:-80.24873176},
{x:40.46905964,y:-80.09655576},
{x:40.66974734,y:-73.91195813},
{x:29.08598673,y:-82.25106698},
{x:28.9277484,y:-82.01340236},
{x:36.14756695,y:-95.94418249},
{x:40.52600534,y:-78.39212479},
{x:40.34077118,y:-75.97955395},
{x:45.47555532,y:-122.82396197},
{x:37.55929702,y:-122.00859301},
{x:33.6665247,y:-117.88280991},
{x:39.85713588,y:-82.82968186},
{x:33.19443374,y:-80.60961958},
{x:34.07882956,y:-83.91751042},
{x:31.64948142,y:-89.5705992},
{x:27.31327292,y:-80.39918339},
{x:39.75538498,y:-121.60806566},
{x:36.24874496,y:-90.95937198},
{x:37.2036545,y:-93.27643056},
{x:41.11229898,y:-83.2029256},
{x:33.84750902,y:-118.0280763},
{x:33.42927563,y:-86.7075482},
{x:42.96188142,y:-71.44051097},
{x:44.05234934,y:-123.10314198},
{x:40.74063282,y:-73.98796984},
{x:42.05570962,y:-80.08761947},
{x:39.74520796,y:-84.39351238},
{x:44.77769029,y:-69.7245571},
{x:41.35070675,y:-79.71409274},
{x:28.3520876,y:-80.6083673},
{x:33.68643375,y:-117.98823819},
{x:35.77544913,y:-119.22884024},
{x:39.6073448,y:-119.22206024},
{x:33.98436455,y:-81.25353385},
{x:33.64117055,y:-117.17374718},
{x:37.59441224,y:-120.94000407},
{x:36.5796731,y:-94.76861862},
{x:29.87870598,y:-95.67981159},
{x:33.22401703,y:-97.76441132},
{x:35.87624653,y:-84.51258818},
{x:37.2948,y:-120.452},
{x:35.14896395,y:-89.7959134},
{x:39.97374091,y:-74.23901526},
{x:29.55568602,y:-95.15313556},
{x:38.91725951,y:-84.62661964},
{x:44.04072041,y:-75.84095308},
{x:45.67924446,y:-111.18667699},
{x:28.55328983,y:-80.84494297},
{x:27.99925911,y:-81.95752},
{x:39.49433941,y:-121.57292761},
{x:31.22537746,y:-85.43169875},
{x:39.777203,y:-104.846652},
{x:45.50007939,y:-122.91776419},
{x:32.66235014,y:-96.89053811},
{x:34.03142591,y:-84.57802758},
{x:32.86487722,y:-90.39346585},
{x:38.22251634,y:-91.15663294},
{x:41.11300325,y:-81.57774038},
{x:39.55732958,y:-119.49024126},
{x:41.01183444,y:-76.41261403},
{x:35.02465926,y:-106.6813856},
{x:40.6175046,y:-80.05614128},
{x:41.97907192,y:-78.61780477},
{x:34.29795202,y:-118.43791187},
{x:42.50267155,y:-82.89829722},
{x:41.62635475,y:-73.92007002},
{x:45.56805756,y:-94.14128759},
{x:35.39474415,y:-97.56602368},
{x:42.94942268,y:-78.87766807},
{x:33.83215136,y:-118.16290417},
{x:45.63975708,y:-110.57532154},
{x:30.34388342,y:-94.18188565},
{x:36.11042052,y:-83.33913748},
{x:26.37136786,y:-98.79833856},
{x:33.56822447,y:-84.33043962},
{x:40.76809083,y:-84.16137323},
{x:35.35471959,y:-119.18198948},
{x:33.06299395,y:-89.57574941},
{x:43.46763134,y:-85.96733307},
{x:35.98330216,y:-80.0197953},
{x:39.690268,y:-105.117693},
{x:35.92453841,y:-84.06710494},
{x:32.33050399,y:-96.11676112},
{x:33.9034,y:-80.3822},
{x:39.41021,y:-104.85993375},
{x:33.28355482,y:-111.68420054},
{x:38.60317161,y:-90.38665605},
{x:36.04210021,y:-115.10084131},
{x:33.14826889,y:-94.96825906},
{x:36.43596603,y:-84.93641937},
{x:43.51452739,y:-96.74557166},
{x:33.52031052,y:-101.8699269},
{x:33.77512997,y:-117.94247334},
{x:36.1747383,y:-94.55238193},
{x:38.78219637,y:-121.28635749},
{x:29.7331588,y:-95.77528055},
{x:28.55323939,y:-81.41580082},
{x:27.8552,y:-82.4827},
{x:36.13050642,y:-115.06476139},
{x:35.71724099,y:-84.01247182},
{x:39.81247836,y:-105.05264427},
{x:41.43269854,y:-82.71426193},
{x:34.18793262,y:-84.14235116},
{x:36.67313764,y:-121.63249445},
{x:30.03903994,y:-94.15795661},
{x:39.07766213,y:-108.55843042},
{x:34.93826236,y:-85.21441482},
{x:35.21439289,y:-111.5986513},
{x:42.74057039,y:-84.61094246},
{x:40.91243261,y:-73.99728327},
{x:38.55873943,y:-121.48063888},
{x:35.21050285,y:-80.69625638},
{x:42.74025716,y:-83.37902735},
{x:36.93540734,y:-121.77243525},
{x:32.83824928,y:-106.08315682},
{x:38.98893595,y:-76.94264586},
{x:28.03365715,y:-82.64699489},
{x:42.28365701,y:-83.36389832},
{x:34.93612891,y:-82.00218583},
{x:33.84670989,y:-118.117522},
{x:41.36119773,y:-81.68553644},
{x:33.90162179,y:-118.30888152},
{x:34.50674251,y:-117.32778941},
{x:33.62916492,y:-111.88941665},
{x:37.31184511,y:-89.55276788},
{x:28.82412737,y:-81.79163746},
{x:35.03604083,y:-120.48544603},
{x:35.48305767,y:-120.65993259},
{x:33.73280566,y:-117.81559624},
{x:34.12029452,y:-117.19472419},
{x:34.34143425,y:-86.30933364},
{x:42.56712109,y:-83.12710762},
{x:39.3966588,y:-77.43694285},
{x:37.72348491,y:-121.43534482},
{x:36.2452,y:-85.9425},
{x:45.05743367,y:-93.24822253},
{x:42.23294123,y:-83.27009216},
{x:32.3772143,y:-82.06216798},
{x:32.87115431,y:-79.7761678},
{x:40.67384884,y:-74.21375535},
{x:33.78931064,y:-117.89721474},
{x:28.55256507,y:-81.47350691},
{x:33.42162024,y:-112.27104434},
{x:38.05172816,y:-85.53939251},
{x:25.88380049,y:-80.33703317},
{x:36.23161413,y:-86.75853029},
{x:28.22181326,y:-82.73426674},
{x:42.2134,y:-83.2278},
{x:40.05338566,y:-82.86532518},
{x:38.40901578,y:-122.8405336},
{x:33.70565032,y:-87.82557109},
{x:43.61512579,y:-83.91407579},
{x:33.87711441,y:-117.58512358},
{x:39.29636168,y:-84.52477723},
{x:40.82203999,y:-74.83651017},
{x:29.556164,y:-95.11917443},
{x:38.8621,y:-83.8877},
{x:33.1210981,y:-117.3196945},
{x:45.27995172,y:-93.00056637},
{x:41.02659402,y:-95.22689847},
{x:41.41046603,y:-81.53686921},
{x:38.59667337,y:-90.48156381},
{x:33.92956104,y:-118.35304321},
{x:34.06247458,y:-117.20103345},
{x:37.34806381,y:-85.33484001},
{x:32.78738926,y:-79.9537768},
{x:38.11723779,y:-121.25599517},
{x:36.3690224,y:-96.01038015},
{x:35.06854328,y:-106.50365666},
{x:42.22496946,y:-85.58991625},
{x:33.75484748,y:-84.26683027},
{x:43.14951055,y:-123.37126355},
{x:39.9194179,y:-83.12776302},
{x:38.36238754,y:-90.9810394},
{x:41.1895138,y:-80.9773961},
{x:44.65422355,y:-124.05339633},
{x:44.04595855,y:-123.16922282},
{x:26.23151738,y:-80.16184469},
{x:38.53175593,y:-121.95580021},
{x:43.31042654,y:-70.99289864},
{x:28.86847236,y:-96.9971644},
{x:35.09182516,y:-92.46767481},
{x:36.84956598,y:-119.72921044},
{x:33.85365099,y:-98.49966288},
{x:35.35016345,y:-118.91390781},
{x:39.07241439,y:-84.3287129},
{x:29.73572518,y:-95.60408413},
{x:43.61102565,y:-84.73307538},
{x:42.28927945,y:-83.84088374},
{x:39.40327032,y:-119.74010122},
{x:40.13677812,y:-79.84205429},
{x:36.00674995,y:-84.02767806},
{x:34.74212053,y:-92.2761105},
{x:43.39214711,y:-124.26083907},
{x:37.9803,y:-86.1597},
{x:38.53799918,y:-90.49336821},
{x:29.76962725,y:-95.60665509},
{x:28.7471,y:-81.8773},
{x:38.76239409,y:-90.69962464},
{x:26.68126779,y:-80.20472288},
{x:34.49444808,y:-89.03102136},
{x:33.45967064,y:-112.34040752},
{x:33.56392346,y:-112.0648583},
{x:34.09555391,y:-117.68999932},
{x:44.06422746,y:-92.50172711},
{x:34.98216712,y:-81.96888818},
{x:42.79578927,y:-77.79170959},
{x:27.99070195,y:-81.68913513},
{x:35.7845024,y:-80.25980566},
{x:39.47528888,y:-118.79176363},
{x:38.81299556,y:-94.26439438},
{x:30.1064473,y:-97.33520061},
{x:35.96722563,y:-85.03864055},
{x:38.61837642,y:-121.40325983},
{x:33.68232485,y:-112.20362663},
{x:44.82224457,y:-68.77652891},
{x:28.92682079,y:-81.18946249},
{x:33.23390332,y:-86.80412657},
{x:34.87036887,y:-86.57069383},
{x:41.984759,y:-86.124384},
{x:32.6976406,y:-115.4964913},
{x:42.52178107,y:-83.00523649},
{x:45.02786602,y:-84.69575999},
{x:35.233,y:-85.8272},
{x:34.10800631,y:-80.83228556},
{x:34.60209989,y:-118.14504777},
{x:33.80288198,y:-117.93719669},
{x:41.15929591,y:-95.93597664},
{x:33.42350073,y:-112.22241297},
{x:36.70767434,y:-110.25105223},
{x:42.61276133,y:-83.32756968},
{x:43.15370638,y:-86.19871666},
{x:39.93089285,y:-83.05059113},
{x:33.19429537,y:-117.35551614},
{x:37.0708,y:-93.2305},
{x:36.3263934,y:-119.21127834},
{x:34.4653,y:-93.145},
{x:39.92183929,y:-75.18757769},
{x:39.6245177,y:-105.02122208},
{x:38.47339553,y:-90.30502081},
{x:39.635267,y:-75.806565},
{x:40.73426474,y:-73.980745},
{x:40.718888,y:-74.094615},
{x:36.6682069,y:-121.6553186},
{x:37.33786803,y:-122.04184085},
{x:26.273233,y:-80.27891278},
{x:38.426933,y:-92.852604},
{x:25.76274134,y:-80.3047047},
{x:39.5674844,y:-104.90387134},
{x:30.39312364,y:-86.49879456},
{x:34.97936488,y:-92.03041903},
{x:39.70809045,y:-84.20205519},
{x:33.88143863,y:-118.32614637},
{x:38.15466783,y:-121.15507706},
{x:43.97696639,y:-83.96027517},
{x:46.70165666,y:-92.45613417},
{x:45.46856382,y:-122.69208641},
{x:41.61003228,y:-83.70945889},
{x:42.9639689,y:-74.18592916},
{x:36.27765868,y:-95.84714954},
{x:33.59464409,y:-88.65968825},
{x:34.62463703,y:-89.95357744},
{x:36.62748366,y:-119.6827939},
{x:38.594157,y:-121.28754595},
{x:36.12455197,y:-115.27937298},
{x:38.96506784,y:-84.28478662},
{x:38.61734713,y:-90.19370259},
{x:27.9552094,y:-81.6145146},
{x:35.1823842,y:-106.6877855},
{x:39.04940004,y:-94.47515576},
{x:39.96351035,y:-75.59666407},
{x:35.22404021,y:-80.6336465},
{x:34.047295,y:-118.2071869},
{x:35.2250878,y:-88.24374157},
{x:39.8175637,y:-75.26005173},
{x:27.16333803,y:-80.2261392},
{x:28.519825,y:-81.729198},
{x:38.99434998,y:-76.93290449},
{x:33.57681903,y:-96.18031011},
{x:40.21517239,y:-74.62188743},
{x:34.50786843,y:-117.39901721},
{x:42.97759922,y:-83.7714677},
{x:35.06604339,y:-78.89825106},
{x:39.3545611,y:-76.75962389},
{x:33.74699355,y:-117.00733343},
{x:30.24858324,y:-81.76754415},
{x:40.25078779,y:-76.91307084},
{x:29.66007916,y:-82.42123336},
{x:39.74659394,y:-105.05368368},
{x:36.3155811,y:-95.62417118},
{x:36.8516602,y:-119.77506072},
{x:42.97281788,y:-85.90917639},
{x:29.22441703,y:-81.09082756},
{x:38.64448868,y:-121.27272382},
{x:39.36307821,y:-77.16346694},
{x:33.7904018,y:-118.2152617},
{x:44.34994333,y:-69.79714412},
{x:37.13325654,y:-121.63005401},
{x:36.44186236,y:-94.115062},
{x:40.28069754,y:-78.86225508},
{x:38.3335058,y:-104.74519624},
{x:34.52730483,y:-117.22319399},
{x:33.45872814,y:-112.7825397},
{x:43.97271,y:-75.9032},
{x:29.99228656,y:-95.74945478},
{x:37.1452352,y:-92.2625405},
{x:42.91240676,y:-85.65277126},
{x:33.2271984,y:-117.32699722},
{x:36.15756525,y:-115.08081273},
{x:39.27237297,y:-81.57602542},
{x:30.39821917,y:-89.02724839},
{x:44.88273008,y:-93.31976701},
{x:35.12926639,y:-89.94953518},
{x:36.21560451,y:-86.59967284},
{x:28.67114854,y:-81.33862942},
{x:41.2266507,y:-81.8414235},
{x:41.87061375,y:-71.52733974},
{x:25.71349763,y:-80.43204546},
{x:34.37136828,y:-96.13662124},
{x:41.2276861,y:-80.45133307},
{x:41.75490606,y:-124.19400512},
{x:41.79176262,y:-85.42896328},
{x:35.25603655,y:-88.99690211},
{x:45.54998226,y:-94.19873227},
{x:37.3410187,y:-92.92912741},
{x:30.45809351,y:-87.29877294},
{x:28.91297983,y:-81.29350272},
{x:34.98257011,y:-79.01348625},
{x:30.37929849,y:-86.35501163},
{x:33.72773247,y:-84.74573255},
{x:34.45254701,y:-118.56633973},
{x:41.68156027,y:-71.56352252},
{x:30.0437971,y:-94.89113077},
{x:41.30987389,y:-81.83464102},
{x:45.49494116,y:-122.80926481},
{x:35.1172167,y:-81.07960626},
{x:42.39607478,y:-83.33494662},
{x:35.455635,y:-97.60146498},
{x:39.45296251,y:-82.22339053},
{x:40.74300098,y:-74.17795746},
{x:26.12086662,y:-80.19428734},
{x:33.75893424,y:-84.50587709},
{x:33.5686276,y:-112.09920823},
{x:39.15837927,y:-122.14118016},
{x:33.70778321,y:-117.88578142},
{x:42.26846931,y:-85.53037919},
{x:34.06555984,y:-117.71888405},
{x:39.77359445,y:-104.8106271},
{x:40.14404927,y:-76.59731954},
{x:39.91428295,y:-104.94192123},
{x:30.44011541,y:-88.52710946},
{x:45.19922702,y:-93.5493259},
{x:42.5728687,y:-84.82212859},
{x:40.38371986,y:-79.86423225},
{x:41.28296,y:-84.3272242},
{x:33.08614297,y:-96.62213951},
{x:35.28840944,y:-81.18851952},
{x:40.14685203,y:-74.9967117},
{x:39.87449206,y:-84.13660258},
{x:39.14398878,y:-94.56726467},
{x:34.22102027,y:-118.39619641},
{x:33.29781823,y:-111.7456715},
{x:61.19554723,y:-149.90132899},
{x:30.101727,y:-81.71794182},
{x:33.34868952,y:-111.96112259},
{x:35.86937211,y:-83.75398028},
{x:33.12786489,y:-96.72797909},
{x:33.63965554,y:-112.08032911},
{x:33.9898542,y:-118.06444607},
{x:44.83811724,y:-69.28456855},
{x:41.07091666,y:-81.50882404},
{x:41.53438293,y:-73.89710525},
{x:33.40769869,y:-111.77318265},
{x:35.58206491,y:-78.14579589},
{x:38.59763871,y:-121.38359159},
{x:43.59285789,y:-83.88822939},
{x:35.9016445,y:-79.95029807},
{x:37.25331291,y:-83.19943802},
{x:34.01119607,y:-118.30044187},
{x:42.67003968,y:-82.99070529},
{x:29.42204824,y:-98.77641156},
{x:40.7624,y:-73.9854},
{x:38.76809315,y:-90.78316563},
{x:37.65825658,y:-122.05631891},
{x:38.97969515,y:-76.73275618},
{x:34.9522455,y:-81.06396479},
{x:40.98831907,y:-80.63312455},
{x:43.18738894,y:-84.16774799},
{x:25.8825,y:-80.2403},
{x:36.05708111,y:-95.81591157},
{x:33.93896525,y:-117.96071117},
{x:28.55375469,y:-81.33276177},
{x:38.8738186,y:-104.81652341},
{x:36.08597865,y:-115.10063151},
{x:42.32536442,y:-83.32727551},
{x:33.45279884,y:-111.68478072},
{x:34.22411611,y:-77.88220614},
{x:37.31919384,y:-120.46885631},
{x:33.91665601,y:-117.26863857},
{x:31.84574482,y:-81.60342658},
{x:37.42770943,y:-121.92128749},
{x:37.97633542,y:-120.34429193},
{x:39.83621867,y:-75.08874714},
{x:27.98116534,y:-82.28710659},
{x:28.47582335,y:-82.48665436},
{x:45.66701352,y:-118.80679567},
{x:34.55407236,y:-90.65481573},
{x:33.98885735,y:-118.19848329},
{x:43.09963155,y:-73.7490575},
{x:40.72460612,y:-73.56453896},
{x:29.95375245,y:-93.98761086},
{x:33.4647,y:-84.2306},
{x:41.29149891,y:-96.18284449},
{x:27.42986955,y:-80.32559663},
{x:38.8972833,y:-104.83183923},
{x:41.7848755,y:-80.85433059},
{x:36.05495066,y:-79.90207664},
{x:40.76351118,y:-73.329565},
{x:26.65113868,y:-81.97465941},
{x:27.89321997,y:-82.68160705},
{x:35.0666295,y:-78.96652482},
{x:33.93812516,y:-81.14836559},
{x:36.27892608,y:-80.83225974},
{x:34.92864455,y:-78.85595313},
{x:40.76565776,y:-83.8227611},
{x:29.71634839,y:-95.53848936},
{x:39.02021542,y:-82.04250182},
{x:35.94586932,y:-96.00792325},
{x:35.42010406,y:-119.12661589},
{x:41.06203975,y:-80.66235063},
{x:41.28587384,y:-81.10036142},
{x:41.01354475,y:-80.39960069},
{x:34.07965581,y:-96.7637682},
{x:34.88414431,y:-82.35565282},
{x:38.92966634,y:-77.03321716},
{x:33.13735204,y:-117.0527456},
{x:33.21076737,y:-87.553826},
{x:40.92251457,y:-75.40088675},
{x:41.13685446,y:-83.66044361},
{x:34.21036527,y:-119.19414648},
{x:40.94289605,y:-83.39811802},
{x:38.81176921,y:-90.87198139},
{x:44.24882765,y:-84.22466314},
{x:34.24388626,y:-116.90388678},
{x:30.4616392,y:-97.79508287},
{x:34.96295659,y:-89.92074199},
{x:35.90460466,y:-86.87600943},
{x:35.06810042,y:-89.94487524},
{x:34.13552874,y:-117.23220755},
{x:33.7945481,y:-117.91444485},
{x:35.46615408,y:-97.72368726},
{x:27.85653694,y:-82.32820324},
{x:44.09522303,y:-103.1476191},
{x:31.21675157,y:-81.51767492},
{x:33.41613139,y:-111.68571219},
{x:44.52650616,y:-122.90679345},
{x:33.91427691,y:-118.16916255},
{x:33.44611831,y:-84.3247894},
{x:36.78556675,y:-90.42997245},
{x:39.36097398,y:-104.61951354},
{x:35.2144,y:-84.8686},
{x:33.68010792,y:-93.60477553},
{x:42.56666219,y:-71.98053598},
{x:35.12090009,y:-120.61326911},
{x:42.14066128,y:-72.50215329},
{x:39.35711033,y:-84.25945692},
{x:40.23600333,y:-83.35486573},
{x:44.76379966,y:-85.60430387},
{x:39.74053424,y:-105.12993675},
{x:40.03198897,y:-83.14136214},
{x:39.72503627,y:-75.11646128},
{x:35.70288395,y:-85.78153502},
{x:41.23440806,y:-75.91798139},
{x:26.93788818,y:-82.33647276},
{x:33.5510725,y:-112.18559072},
{x:34.10617034,y:-117.43117101},
{x:37.11871606,y:-93.46991905},
{x:34.911975,y:-91.196285},
{x:34.19370548,y:-118.41410416},
{x:40.90577018,y:-74.56907798},
{x:38.58384393,y:-90.31802818},
{x:41.7048811,y:-71.41592176},
{x:36.22242083,y:-83.2627881},
{x:37.27424767,y:-93.11517971},
{x:36.14351893,y:-120.35714031},
{x:33.413078,y:-110.837968},
{x:33.04182139,y:-84.98039825},
{x:29.11621992,y:-82.02579716},
{x:43.98825391,y:-124.10095789},
{x:33.50439041,y:-84.2396877},
{x:34.21880177,y:-118.58804874},
{x:41.93617796,y:-84.97618116},
{x:42.54632942,y:-83.14733684},
{x:36.95252098,y:-120.07488509},
{x:36.14736863,y:-86.66598608},
{x:33.59763066,y:-112.03705681},
{x:33.46549454,y:-111.9115448},
{x:34.13322647,y:-117.92358314},
{x:28.57615957,y:-81.74960673},
{x:34.24920908,y:-119.195847},
{x:45.36756068,y:-122.84557978},
{x:41.79615262,y:-71.44876218},
{x:28.75578492,y:-81.34366377},
{x:39.73045266,y:-84.14165743},
{x:35.94673224,y:-78.53610416},
{x:38.79563097,y:-90.23304371},
{x:30.26644578,y:-82.12226895},
{x:33.36455179,y:-111.86057438},
{x:38.65783531,y:-120.96961905},
{x:38.94572136,y:-91.9402518},
{x:35.9291061,y:-84.03315244},
{x:41.07096803,y:-74.13782358},
{x:43.01562875,y:-83.51706018},
{x:40.83491648,y:-83.23775902},
{x:34.99547357,y:-85.29138176},
{x:40.9313574,y:-74.26820576},
{x:32.88297065,y:-96.96363704},
{x:38.88663355,y:-82.3819437},
{x:41.2209945,y:-95.97535566},
{x:42.62877875,y:-82.99390687},
{x:33.75359797,y:-78.96264781},
{x:41.4034179,y:-82.36414696},
{x:44.73998699,y:-85.17393315},
{x:34.256253,y:-84.358562},
{x:41.07580776,y:-81.49541527},
{x:42.08764168,y:-71.41529024},
{x:36.1126561,y:-93.7108306},
{x:38.9028501,y:-76.83481691},
{x:37.81962409,y:-121.99591681},
{x:38.74031277,y:-82.96560451},
{x:40.54908352,y:-82.84651934},
{x:36.15390911,y:-82.84831211},
{x:37.95489253,y:-122.33142485},
{x:39.00644073,y:-94.55602129},
{x:35.85872134,y:-78.70536898},
{x:39.6529248,y:-104.99428519},
{x:28.33068012,y:-82.65824944},
{x:34.96514852,y:-82.43139334},
{x:35.8819,y:-84.659},
{x:33.97529501,y:-117.34255381},
{x:34.49514404,y:-85.84738255},
{x:38.4663,y:-121.489},
{x:31.09513933,y:-97.8059641},
{x:40.86429249,y:-73.92606474},
{x:34.1866479,y:-79.34279421},
{x:28.04920609,y:-82.50372557},
{x:32.73423255,y:-97.06142601},
{x:38.65430221,y:-121.06952597},
{x:38.50830669,y:-121.54295699},
{x:40.89986396,y:-98.33927576},
{x:38.77317573,y:-90.30477939},
{x:43.1060931,y:-83.69485206},
{x:34.27671533,y:-119.26644985},
{x:41.15403316,y:-81.34729154},
{x:42.60144517,y:-82.95200489},
{x:39.8675222,y:-82.93467358},
{x:34.00326726,y:-118.40690553},
{x:37.47500092,y:-105.89320247},
{x:32.28224742,y:-90.11833598},
{x:34.81921593,y:-82.27054165},
{x:42.58959812,y:-83.88072915},
{x:33.45112265,y:-111.80448301},
{x:43.15075809,y:-76.35411566},
{x:40.0114,y:-75.7207},
{x:46.86210059,y:-96.85047904},
{x:37.96766208,y:-121.29427671},
{x:36.43195657,y:-77.63415172},
{x:34.1617407,y:-99.31321804},
{x:29.58585737,y:-95.21169305},
{x:40.70879209,y:-73.95847624},
{x:40.42747479,y:-84.37831648},
{x:41.87626891,y:-80.13382293},
{x:42.65798086,y:-83.46379459},
{x:41.24957101,y:-76.9663574},
{x:25.97538772,y:-80.29570475},
{x:36.09028747,y:-95.92458223},
{x:36.06131762,y:-79.96220308},
{x:39.63720274,y:-84.35043365},
{x:34.23703948,y:-80.66367187},
{x:35.009667,y:-106.06256782},
{x:33.74533911,y:-118.1049467},
{x:35.00526575,y:-78.8154465},
{x:36.6964316,y:-93.36322991},
{x:32.59026761,y:-96.88093901},
{x:36.5523039,y:-82.62671505},
{x:39.04142843,y:-77.04738725},
{x:33.91768716,y:-117.90252109},
{x:38.63666624,y:-121.47575341},
{x:39.66728468,y:-104.91410682},
{x:39.76835209,y:-84.12373562},
{x:39.3593518,y:-121.68838708},
{x:39.77842575,y:-84.0559958},
{x:38.66342827,y:-121.34329408},
{x:42.10472328,y:-75.88103741},
{x:38.98605984,y:-76.98767301},
{x:32.57676947,y:-117.08112851},
{x:35.04661317,y:-80.99036596},
{x:32.46759223,y:-83.73911371},
{x:33.12677318,y:-117.08880364},
{x:35.46485156,y:-79.15500865},
{x:40.03205063,y:-76.24205142},
{x:39.5890456,y:-104.6894483},
{x:33.20571253,y:-87.5699763},
{x:33.74732248,y:-116.95024307},
{x:35.55178779,y:-82.85029418},
{x:42.99138789,y:-78.80494771},
{x:36.22055257,y:-115.27835736},
{x:35.15538432,y:-88.57732051},
{x:40.57098725,y:-74.10966906},
{x:42.16905915,y:-83.26734832},
{x:31.43029663,y:-100.4560037},
{x:46.9533,y:-114.133},
{x:33.70177836,y:-84.26797442},
{x:38.70412453,y:-93.25092584},
{x:37.33642626,y:-121.88117336},
{x:38.59091988,y:-121.55090986},
{x:42.4162374,y:-83.20122738},
{x:40.57419692,y:-105.08451008},
{x:25.86693245,y:-80.30479878},
{x:41.32846082,y:-75.94804527},
{x:26.12137731,y:-80.25927358},
{x:40.12686945,y:-74.06834866},
{x:37.40969213,y:-121.97812311},
{x:32.56655157,y:-85.50129443},
{x:41.69720872,y:-71.14837109},
{x:35.36363502,y:-97.53063977},
{x:39.9457766,y:-82.99702987},
{x:33.84448336,y:-116.52564961},
{x:41.99607763,y:-70.9736098},
{x:38.626094,y:-121.499153},
{x:40.59568953,y:-83.12883608},
{x:35.96972971,y:-114.85568455},
{x:36.01957436,y:-115.05094437},
{x:40.43751606,y:-74.3912386},
{x:39.96377194,y:-75.27089422},
{x:40.680337,y:-80.30242588},
{x:29.96719835,y:-95.53208113},
{x:44.03273887,y:-103.20923386},
{x:33.6668,y:-79.8344},
{x:43.17663441,y:-85.28276707},
{x:40.1418274,y:-84.2649698},
{x:41.0489577,y:-83.62901814},
{x:35.34099759,y:-89.90288888},
{x:33.80278072,y:-117.97602855},
{x:38.45800478,y:-122.71787121},
{x:27.72087837,y:-82.67882528},
{x:40.7197161,y:-82.54414839},
{x:39.9446702,y:-75.34872079},
{x:42.08096402,y:-86.48395821},
{x:33.85418577,y:-117.83783645},
{x:42.08980325,y:-80.08521154},
{x:28.36306457,y:-81.42408257},
{x:37.14364178,y:-94.31099345},
{x:27.93668218,y:-82.78794133},
{x:36.06624227,y:-119.02745698},
{x:40.68064583,y:-73.94944595},
{x:36.05535621,y:-94.18912885},
{x:30.40022522,y:-97.74727871},
{x:46.79416562,y:-100.75148383},
{x:32.14020656,y:-81.24422197},
{x:33.79566829,y:-118.10754071},
{x:44.69182485,y:-93.2826644},
{x:34.53558421,y:-83.53455303},
{x:31.19091612,y:-83.76187943},
{x:38.34710242,y:-121.99776496},
{x:45.10022122,y:-93.26239602},
{x:39.43251808,y:-123.80568405},
{x:41.87344484,y:-103.67405297},
{x:41.15722321,y:-81.23913194},
{x:37.69926171,y:-121.06842855},
{x:28.19405918,y:-82.63576913},
{x:28.19742365,y:-81.29420981},
{x:32.6471327,y:-96.86882503},
{x:32.97991223,y:-117.07994344},
{x:28.52005491,y:-81.31093749},
{x:39.03479026,y:-94.38711461},
{x:39.54623195,y:-83.4272349},
{x:35.90448065,y:-78.94160411},
{x:29.18790778,y:-95.4339598},
{x:35.74871032,y:-78.83632348},
{x:28.53756096,y:-81.2087728},
{x:44.30258106,y:-120.82184457},
{x:42.25659044,y:-83.24798032},
{x:34.17810556,y:-118.85824665},
{x:33.96056492,y:-118.25672639},
{x:34.04979486,y:-84.28692698},
{x:33.84946609,y:-117.79113888},
{x:42.37800698,y:-122.89929014},
{x:41.7104,y:-86.188},
{x:42.81895213,y:-82.50441},
{x:37.79375042,y:-122.20115954},
{x:38.99231875,y:-104.80741942},
{x:36.19286097,y:-94.17625887},
{x:40.72500981,y:-73.49021588},
{x:38.98720335,y:-90.96719344},
{x:33.56937734,y:-84.54044567},
{x:33.56704495,y:-112.152742},
{x:33.41783788,y:-94.09952105},
{x:28.2489357,y:-81.31078787},
{x:42.07719157,y:-78.44026923},
{x:39.159742,y:-84.45636422},
{x:35.6672914,y:-88.82872586},
{x:35.21689404,y:-89.99073748},
{x:64.75608243,y:-147.35422841},
{x:32.33279791,y:-95.26337036},
{x:40.62196805,y:-98.38383703},
{x:41.82835462,y:-72.90136484},
{x:34.34415796,y:-119.07927228},
{x:40.96407422,y:-73.83871452},
{x:38.76006657,y:-121.31053931},
{x:40.88887684,y:-74.2594672},
{x:41.42662519,y:-87.75122521},
{x:35.86363479,y:-83.54427691},
{x:38.34624743,y:-76.45996749},
{x:34.47134545,y:-117.2863094},
{x:41.51399693,y:-72.10833309},
{x:37.64683879,y:-122.42873984},
{x:39.24213895,y:-94.44060449},
{x:31.89084319,y:-102.34886259},
{x:38.58976587,y:-90.59594976},
{x:38.0216045,y:-121.36082594},
{x:35.92164377,y:-81.1864864},
{x:33.41595445,y:-111.73443584},
{x:38.66865788,y:-83.77152805},
{x:33.84317435,y:-117.94127686},
{x:44.99876176,y:-93.28523613},
{x:36.18217324,y:-82.77079318},
{x:37.29953593,y:-93.42249415},
{x:37.00724215,y:-85.9245002},
{x:37.02629792,y:-93.22750305},
{x:34.0112072,y:-118.3384804},
{x:40.44553168,y:-80.15019537},
{x:34.47972362,y:-91.55825347},
{x:34.10445187,y:-82.85250762},
{x:42.23113294,y:-83.23055438},
{x:27.49616179,y:-82.61538774},
{x:38.33500812,y:-121.97003245},
{x:39.24295717,y:-84.59516778},
{x:42.12647104,y:-75.97122435},
{x:33.40619718,y:-111.9788146},
{x:40.39306477,y:-80.06283565},
{x:40.16171737,y:-111.64119995},
{x:35.90971176,y:-86.38323869},
{x:35.16961094,y:-92.71865986},
{x:34.83045014,y:-92.18317518},
{x:39.52637966,y:-104.941674},
{x:37.42004143,y:-121.90379687},
{x:33.75885969,y:-118.00356258},
{x:26.35145434,y:-80.11305141},
{x:42.78067228,y:-71.07768602},
{x:43.55737463,y:-83.95174632},
{x:42.07060643,y:-71.04593394},
{x:34.86462845,y:-120.41801758},
{x:36.20515032,y:-86.77846111},
{x:43.05429692,y:-85.58048334},
{x:38.8035571,y:-76.99091928},
{x:35.05020493,y:-89.85644158},
{x:43.05889184,y:-85.95632583},
{x:40.367281,y:-78.430313},
{x:35.43491041,y:-97.43203253},
{x:40.1536111,y:-75.8590045},
{x:35.38940989,y:-119.00323342},
{x:39.43188858,y:-84.19332245},
{x:43.4506,y:-83.9772},
{x:34.08714652,y:-81.18163437},
{x:35.29594625,y:-94.38654189},
{x:42.63870332,y:-83.32626298},
{x:40.05856508,y:-77.49714681},
{x:42.97293553,y:-77.35312158},
{x:42.88350997,y:-70.86790422},
{x:32.85635804,y:-116.92646295},
{x:36.4119019,y:-89.07765806},
{x:38.43757084,y:-121.4060095},
{x:35.93666577,y:-80.23631394},
{x:38.93322596,y:-92.29224761},
{x:37.00135227,y:-121.56083961},
{x:41.80244003,y:-81.0548714},
{x:40.84247585,y:-79.91871189},
{x:33.13506202,y:-91.96270648},
{x:36.74231907,y:-91.87352234},
{x:25.74799598,y:-80.3353719},
{x:33.81140131,y:-93.42704289},
{x:33.73445259,y:-118.2926555},
{x:37.99351261,y:-122.10319559},
{x:41.06635422,y:-73.81457612},
{x:39.96045098,y:-84.19070506},
{x:42.20922238,y:-85.89087434},
{x:32.75192635,y:-97.09679242},
{x:39.59913391,y:-75.82589344},
{x:36.59484154,y:-82.21171139},
{x:35.1061033,y:-81.20954543},
{x:29.4663093,y:-98.6233046},
{x:32.66330451,y:-97.40315657},
{x:33.53615035,y:-112.35779525},
{x:36.22722955,y:-119.33045179},
{x:42.21337151,y:-83.54289017},
{x:39.0699,y:-94.5213},
{x:26.57181284,y:-80.14418006},
{x:38.81814745,y:-121.18992457},
{x:40.65683419,y:-73.6313206},
{x:35.44396179,y:-80.99803842},
{x:33.68336604,y:-112.10029855},
{x:42.64958582,y:-73.6964223},
{x:42.7210835,y:-82.74466964},
{x:34.01794478,y:-84.5641377},
{x:28.05466362,y:-81.94989565},
{x:33.00124659,y:-97.21883945},
{x:28.2192,y:-82.2504},
{x:38.34283003,y:-120.76789721},
{x:33.74577533,y:-117.86384262},
{x:39.62491643,y:-104.90373999},
{x:33.3048703,y:-111.99774428},
{x:32.67371534,y:-97.09181158},
{x:32.7715556,y:-115.55069681},
{x:33.50729968,y:-86.79739274},
{x:34.27174399,y:-85.2295319},
{x:34.86043053,y:-82.26812408},
{x:34.67792276,y:-86.74997128},
{x:44.22358564,y:-86.30953068},
{x:27.8950015,y:-82.75911468},
{x:41.67804043,y:-72.84568801},
{x:37.63793687,y:-120.7674828},
{x:25.89011939,y:-80.18568347},
{x:40.71896094,y:-81.11345796},
{x:38.51941695,y:-121.43966055},
{x:44.99150661,y:-93.10530155},
{x:43.14298458,y:-78.69767808},
{x:32.30846594,y:-95.30018841},
{x:42.19827425,y:-121.75775284},
{x:33.93489997,y:-84.38015961},
{x:34.02230938,y:-118.29182193},
{x:36.13148864,y:-97.05104202},
{x:35.49283361,y:-97.56494173},
{x:33.94513018,y:-118.36101184},
{x:35.37256754,y:-83.20724665},
{x:35.2619565,y:-81.15648187},
{x:36.57887512,y:-87.40993014},
{x:38.81696191,y:-90.94940893},
{x:35.31719606,y:-106.54172668},
{x:40.07846158,y:-82.4287713},
{x:29.91740693,y:-81.41398703},
{x:36.09285927,y:-78.29832383},
{x:35.52749034,y:-98.69203893},
{x:42.40380233,y:-86.25803038},
{x:29.60336815,y:-98.41830341},
{x:40.09013986,y:-75.36036247},
{x:35.5116481,y:-97.97232409},
{x:42.24976789,y:-71.76261701},
{x:26.0077519,y:-80.2068424},
{x:43.032913,y:-71.071833},
{x:39.16186322,y:-84.53972691},
{x:39.23451435,y:-84.50904879},
{x:34.17520808,y:-77.89268008},
{x:42.24394084,y:-85.67806296},
{x:35.83316,y:-86.351552},
{x:39.667079,y:-75.601849},
{x:40.75788938,y:-74.9812459},
{x:41.05842477,y:-83.66209506},
{x:43.01452374,y:-83.07127828},
{x:35.60124807,y:-119.35295592},
{x:33.56501819,y:-81.74081727},
{x:35.09495909,y:-85.30757497},
{x:33.94791234,y:-84.51838984},
{x:40.61727668,y:-74.25287081},
{x:38.5792539,y:-121.49513533},
{x:34.18583522,y:-97.14380206},
{x:40.71211763,y:-73.94101313},
{x:39.6889958,y:-77.73031976},
{x:38.994298,y:-105.058364},
{x:37.83560483,y:-94.32649018},
{x:30.28549416,y:-81.39156036},
{x:34.25699032,y:-118.59400839},
{x:35.03076874,y:-90.02476461},
{x:32.96917704,y:-82.80917443},
{x:40.44979261,y:-80.01871531},
{x:21.27735535,y:-157.70432532},
{x:34.01047868,y:-84.75367122},
{x:40.81492776,y:-73.91562673},
{x:39.84954348,y:-104.97848162},
{x:32.50278595,y:-87.83829905},
{x:40.07329,y:-75.08424918},
{x:34.1475728,y:-118.69823635},
{x:45.55299749,y:-94.22380414},
{x:35.0068229,y:-80.85491691},
{x:39.08085399,y:-77.07664386},
{x:28.02627034,y:-82.5615605},
{x:36.31854622,y:-86.60035118},
{x:27.20831748,y:-81.83503166},
{x:34.18928699,y:-90.57747938},
{x:29.54705004,y:-98.37047696},
{x:33.84333334,y:-84.32758668},
{x:35.08773859,y:-106.58670504},
{x:31.5703166,y:-84.10721705},
{x:34.2391007,y:-87.59939026},
{x:36.00275631,y:-115.2071539},
{x:34.979687,y:-80.1017101},
{x:36.19615397,y:-115.15991449},
{x:41.40407178,y:-81.7980476},
{x:28.51500763,y:-81.37659033},
{x:39.28405466,y:-76.7504846},
{x:39.51994949,y:-104.76595759},
{x:34.06313716,y:-118.03542204},
{x:33.53818006,y:-112.13509981},
{x:40.69175767,y:-73.50848995},
{x:37.18162759,y:-93.2516389},
{x:35.90558946,y:-83.58061646},
{x:39.98655052,y:-105.09495734},
{x:35.41198013,y:-119.05812971},
{x:36.73558181,y:-119.72899608},
{x:39.18715097,y:-94.5466014},
{x:41.16895902,y:-80.11317909},
{x:37.30176873,y:-121.94955108},
{x:25.81038201,y:-80.18920764},
{x:37.23421102,y:-121.96336408},
{x:30.42766666,y:-84.21424627},
{x:33.79089104,y:-118.26370723},
{x:33.24946638,y:-111.56460245},
{x:42.45680969,y:-89.02784403},
{x:33.36690282,y:-79.27221728},
{x:44.14682081,y:-94.00054044},
{x:38.09412088,y:-122.55524434},
{x:34.75626257,y:-92.2578348},
{x:38.5000741,y:-90.38025916},
{x:40.29059196,y:-76.97460024},
{x:34.55835733,y:-118.08284126},
{x:33.92624066,y:-84.74637134},
{x:35.51297707,y:-84.99908132},
{x:39.24832526,y:-84.78767112},
{x:30.88526155,y:-84.2039745},
{x:37.0948087,y:-93.81431982},
{x:40.01414218,y:-83.01147915},
{x:42.58317707,y:-83.60834278},
{x:39.94356807,y:-105.01155004},
{x:39.03242183,y:-122.92588301},
{x:40.9383691,y:-124.10053022},
{x:33.51079571,y:-82.03677812},
{x:36.77838845,y:-119.68153215},
{x:30.00546308,y:-95.27018125},
{x:36.31204747,y:-82.38764606},
{x:40.52256309,y:-104.98942877},
{x:42.94522945,y:-76.54953019},
{x:34.89782473,y:-76.90725583},
{x:33.39329182,y:-112.13314147},
{x:45.53450822,y:-122.65791189},
{x:41.60015035,y:-72.67723261},
{x:36.29845084,y:-78.58481153},
{x:42.89939785,y:-97.39850798},
{x:26.65118167,y:-80.11128545},
{x:39.26190716,y:-119.58275221},
{x:39.5877547,y:-106.0941231},
{x:38.28142422,y:-122.27472566},
{x:33.61136832,y:-112.11363812},
{x:33.3722555,y:-104.52405048},
{x:38.78394836,y:-123.01141017},
{x:40.70847127,y:-74.00504529},
{x:39.43592873,y:-76.30767988},
{x:30.71887254,y:-85.18379476},
{x:35.428256,y:-119.02159309},
{x:40.81227322,y:-79.54759496},
{x:33.44756458,y:-112.07061661},
{x:41.09729781,y:-73.95559537},
{x:29.1865555,y:-82.10967413},
{x:40.6055812,y:-73.75547906},
{x:39.5753049,y:-119.78041553},
{x:38.98204813,y:-94.35830798},
{x:39.33265386,y:-76.44475155},
{x:42.54992473,y:-82.92630143},
{x:40.18524034,y:-74.86896295},
{x:45.30733816,y:-122.94275165},
{x:36.07631226,y:-87.37475588},
{x:35.64029622,y:-120.68799111},
{x:41.000883,y:-81.996765},
{x:29.90241816,y:-95.30924077},
{x:36.75722246,y:-119.82740575},
{x:44.05517049,y:-121.26280998},
{x:36.19711415,y:-115.26086037},
{x:33.91943768,y:-84.30379998},
{x:35.4662221,y:-83.00677248},
{x:42.70004539,y:-73.68854347},
{x:39.74441201,y:-74.99593457},
{x:35.92339523,y:-84.09425244},
{x:43.52402877,y:-84.12308156},
{x:38.876364,y:-75.822994},
{x:33.50105784,y:-117.66048241},
{x:42.48379809,y:-82.94468663},
{x:33.57500348,y:-117.17809097},
{x:42.42572788,y:-122.8509737},
{x:36.1703797,y:-115.06270854},
{x:35.96742452,y:-78.49169479},
{x:41.56050305,y:-73.0536795},
{x:28.80965795,y:-96.9993938},
{x:46.89817601,y:-98.70922306},
{x:29.79999914,y:-95.31735599},
{x:34.58576367,y:-94.22789107},
{x:40.78681793,y:-82.58919186},
{x:40.93008496,y:-72.68182603},
{x:42.9420992,y:-85.07526621},
{x:34.50332723,y:-93.04924516},
{x:41.04946536,y:-81.72824993},
{x:30.40246823,y:-86.85841419},
{x:39.63198688,y:-77.76118049},
{x:42.25672612,y:-71.8238732},
{x:32.64642486,y:-96.53414705},
{x:39.8659026,y:-75.427582},
{x:36.83662354,y:-119.78970498},
{x:37.95318451,y:-122.05972955},
{x:40.854602,y:-81.42342876},
{x:36.1074996,y:-79.82685287},
{x:32.2161265,y:-90.15270672},
{x:44.04604132,y:-122.96378285},
{x:33.91763214,y:-81.52726902},
{x:38.92595729,y:-104.72150937},
{x:33.65096295,y:-117.84089063},
{x:33.02375771,y:-96.88556742},
{x:31.82610702,y:-106.44463071},
{x:42.31683251,y:-83.22287232},
{x:29.86855433,y:-97.66820222},
{x:32.75952447,y:-97.26146344},
{x:27.63809889,y:-80.4280787},
{x:33.75935672,y:-117.82663469},
{x:40.79045786,y:-73.94576431},
{x:34.9938016,y:-78.13807377},
{x:43.5463342,y:-96.68431082},
{x:32.64483632,y:-116.99737479},
{x:37.49240041,y:-120.87251276},
{x:37.2512,y:-121.862},
{x:39.21994529,y:-83.60901427},
{x:36.06094979,y:-115.22428248},
{x:29.62680812,y:-82.37358579},
{x:38.75792858,y:-93.73995031},
{x:40.85189672,y:-84.60023508},
{x:33.62380633,y:-97.15041137},
{x:39.75533673,y:-121.85949956},
{x:34.61217553,y:-120.18929739},
{x:38.21408986,y:-90.40151058},
{x:34.07207319,y:-84.29550469},
{x:34.11818911,y:-93.09597525},
{x:40.91868919,y:-73.05247843},
{x:34.79845998,y:-88.20516817},
{x:39.33806395,y:-82.95322074},
{x:33.85866929,y:-84.60062824},
{x:34.15166154,y:-118.75875503},
{x:36.06961775,y:-80.3047961},
{x:40.5868648,y:-122.39230737},
{x:35.13311083,y:-120.60620807},
{x:39.42989926,y:-75.04081068},
{x:42.32868791,y:-85.5118221},
{x:28.36376817,y:-80.69987878},
{x:33.59548895,y:-86.12202381},
{x:34.55949767,y:-84.93729693},
{x:33.94497805,y:-84.62477723},
{x:35.50712279,y:-86.45621977},
{x:44.09038928,y:-123.06733181},
{x:31.8736399,y:-106.42625428},
{x:35.35352458,y:-118.94935616},
{x:42.71648404,y:-83.41300829},
{x:36.14393071,y:-85.50149943},
{x:37.77024195,y:-90.4474923},
{x:40.53002669,y:-105.07653117},
{x:38.5130925,y:-90.44139101},
{x:33.87765882,y:-117.96127841},
{x:30.91065996,y:-102.9234966},
{x:34.69305272,y:-77.12697961},
{x:34.9920349,y:-118.94126783},
{x:37.798943,y:-120.99903122},
{x:37.00715553,y:-94.55232427},
{x:44.8392181,y:-68.7438397},
{x:42.25873916,y:-85.58916405},
{x:33.46620311,y:-94.04367521},
{x:32.8383626,y:-116.98276252},
{x:35.32833198,y:-80.94208398},
{x:36.83872656,y:-119.84784208},
{x:33.79201875,y:-83.73402079},
{x:29.60120402,y:-95.68243951},
{x:34.09449443,y:-84.00964148},
{x:40.6713,y:-73.856},
{x:34.68918559,y:-118.09785593},
{x:39.08068414,y:-94.44660366},
{x:35.62315818,y:-97.48378984},
{x:35.557969,y:-87.533029},
{x:29.72610773,y:-95.35038767},
{x:32.83247746,y:-79.8256083},
{x:33.96527575,y:-118.03705386},
{x:36.08463533,y:-86.72751985},
{x:36.03897089,y:-86.74397192},
{x:37.16984498,y:-104.50978026},
{x:39.09547319,y:-84.61134613},
{x:41.48501245,y:-72.80876179},
{x:35.25189317,y:-81.02790433},
{x:29.39879114,y:-98.38631089},
{x:34.24824595,y:-88.71630971},
{x:36.32549737,y:-119.27734539},
{x:35.20168198,y:-84.84425153},
{x:33.68378229,y:-78.99491251},
{x:36.13469789,y:-83.71452763},
{x:25.6283278,y:-80.34097455},
{x:32.79217663,y:-96.74759131},
{x:37.95414573,y:-122.04167798},
{x:35.98721141,y:-83.6058543},
{x:44.08154139,y:-103.2579504},
{x:33.88940115,y:-84.05845614},
{x:40.7535899,y:-73.90177829},
{x:34.49389277,y:-93.08107577},
{x:30.58692457,y:-84.59584339},
{x:34.99229468,y:-86.84146205},
{x:29.28930904,y:-94.83129412},
{x:39.09012004,y:-108.60433464},
{x:40.82990283,y:-73.94834473},
{x:31.61718552,y:-94.65316463},
{x:39.43926887,y:-84.33073243},
{x:36.0719063,y:-115.11620884},
{x:41.88389137,y:-71.06590194},
{x:36.73181494,y:-108.22627864},
{x:35.61548306,y:-120.68139671},
{x:38.31831602,y:-122.29894598},
{x:37.61969845,y:-122.03609303},
{x:35.90738831,y:-75.59837316},
{x:27.5916972,y:-81.51387845},
{x:26.09822768,y:-80.13635606},
{x:35.38636289,y:-81.36518608},
{x:39.999758,y:-75.153676},
{x:35.5930997,y:-80.85669145},
{x:34.21387738,y:-80.97872021},
{x:33.4393026,y:-86.0823143},
{x:36.00243107,y:-95.88816434},
{x:39.3751532,y:-84.37856928},
{x:35.01884593,y:-85.37105996},
{x:28.19448966,y:-82.39277158},
{x:43.21396132,y:-77.72592992},
{x:39.97469267,y:-76.67962912},
{x:35.60816528,y:-82.31963396},
{x:34.87733789,y:-84.30024103},
{x:39.68425165,y:-83.93329208},
{x:43.02210588,y:-74.99321491},
{x:35.12496751,y:-78.87993691},
{x:34.80630716,y:-106.70290639},
{x:25.73342927,y:-80.3210838},
{x:35.20555764,y:-89.83938662},
{x:39.31069713,y:-76.470754},
{x:41.72189475,y:-83.57202896},
{x:30.22667808,y:-97.78434348},
{x:28.47491998,y:-81.28932432},
{x:35.80337776,y:-78.78350347},
{x:33.98387315,y:-88.48828726},
{x:42.65241171,y:-84.52938938},
{x:44.59073523,y:-123.25327411},
{x:40.45708484,y:-74.29533362},
{x:44.08407279,y:-93.24202627},
{x:32.85328019,y:-83.61857012},
{x:36.2976324,y:-119.15565878},
{x:33.44981818,y:-86.82213632},
{x:45.78372449,y:-108.54222661},
{x:35.7849,y:-83.9865},
{x:41.88361241,y:-84.04393833},
{x:40.36921916,y:-80.63923632},
{x:40.33419403,y:-94.87341781},
{x:39.4233309,y:-84.59253632},
{x:37.49332647,y:-94.29634802},
{x:30.41599679,y:-86.60656302},
{x:40.7689,y:-73.8342},
{x:33.0072599,y:-96.55595987},
{x:34.10577064,y:-117.52617806},
{x:34.11227583,y:-84.55985329},
{x:38.59357624,y:-90.24404173},
{x:32.35603965,y:-90.01586773},
{x:41.37220313,y:-72.91780844},
{x:44.81706139,y:-92.93176822},
{x:34.13477923,y:-118.25757086},
{x:39.92833843,y:-105.13455217},
{x:33.32051253,y:-111.92999572},
{x:38.71811041,y:-104.69918697},
{x:35.19271609,y:-106.72643781},
{x:32.43162442,y:-87.03538214},
{x:36.08728737,y:-86.69391043},
{x:40.76435065,y:-73.88540898},
{x:33.37740706,y:-82.07377557},
{x:33.71666745,y:-117.95587063},
{x:40.43628895,y:-104.97969246},
{x:33.63273928,y:-84.35817198},
{x:36.07649983,y:-86.6414801},
{x:39.29103105,y:-84.39617604},
{x:33.81770005,y:-84.81131673},
{x:41.42505354,y:-82.0793201},
{x:43.46133409,y:-76.48476859},
{x:34.77292646,y:-86.53470349},
{x:39.53105751,y:-119.71590513},
{x:45.45739285,y:-122.57949125},
{x:39.63891244,y:-104.73424444},
{x:41.62488265,y:-83.62539996},
{x:46.90019126,y:-102.78885573},
{x:35.6434613,y:-117.66937628},
{x:41.40768563,y:-78.56018679},
{x:42.32341439,y:-122.90051959},
{x:34.28117928,y:-119.19101394},
{x:28.6623255,y:-81.40244991},
{x:38.72908944,y:-75.13135672},
{x:38.46456724,y:-121.37127449},
{x:36.1399886,y:-85.63160841},
{x:42.27162606,y:-84.98533989},
{x:35.32144991,y:-97.4870963},
{x:33.55428767,y:-84.26428606},
{x:38.12798403,y:-85.77693429},
{x:31.44382816,y:-100.48314997},
{x:40.84111781,y:-73.8424035},
{x:33.79159657,y:-87.24162111},
{x:32.29674931,y:-90.23779667},
{x:33.68436705,y:-116.18092686},
{x:35.91612521,y:-81.52415328},
{x:33.19889003,y:-87.53875042},
{x:35.77200229,y:-95.34010001},
{x:27.90719964,y:-82.50571047},
{x:35.58590213,y:-77.37165593},
{x:45.40651419,y:-122.56860186},
{x:46.8046,y:-92.0697},
{x:25.80850336,y:-80.32573871},
{x:36.96730988,y:-122.03873382},
{x:36.7764042,y:-108.69733036},
{x:31.67694614,y:-88.64178919},
{x:40.63569743,y:-75.48713679},
{x:34.06949767,y:-117.55671205},
{x:38.79701115,y:-121.26443902},
{x:32.43624463,y:-104.24282759},
{x:33.58167188,y:-112.13590179},
{x:34.98545352,y:-81.21841027},
{x:43.01441124,y:-71.45144219},
{x:35.26758683,y:-96.93307416},
{x:35.31030544,y:-80.75352564},
{x:42.46879876,y:-83.42478863},
{x:34.53876461,y:-82.6671128},
{x:45.78536532,y:-108.5898137},
{x:34.05799071,y:-118.29256658},
{x:35.65419978,y:-78.4747965},
{x:27.89365468,y:-81.55744032},
{x:34.00627121,y:-118.42953532},
{x:40.60763514,y:-73.96220528},
{x:43.61408571,y:-84.21416777},
{x:35.37776623,y:-78.54235939},
{x:32.33643941,y:-111.0464412},
{x:41.37140697,y:-81.82330662},
{x:27.88075452,y:-97.20725619},
{x:38.7070214,y:-121.31345987},
{x:35.02673036,y:-79.05189335},
{x:30.10826441,y:-96.04633882},
{x:29.50673175,y:-98.58626832},
{x:37.35206382,y:-121.95542783},
{x:40.82217219,y:-81.93377151},
{x:45.42348952,y:-122.78308511},
{x:31.14107813,y:-87.06821591},
{x:38.48005818,y:-122.73733199},
{x:32.35037547,y:-110.97579848},
{x:28.07143088,y:-82.73811863},
{x:41.3195296,y:-74.1482792},
{x:38.99273889,y:-92.32320294},
{x:33.93140217,y:-118.3256046},
{x:40.57579839,y:-73.97948212},
{x:29.54340893,y:-98.41148809},
{x:45.51933441,y:-122.40427139},
{x:29.21283204,y:-99.77778026},
{x:33.2186477,y:-96.7710056},
{x:39.89573988,y:-83.08165409},
{x:39.81544871,y:-104.77264782},
{x:40.62820746,y:-74.16849456},
{x:35.60677577,y:-77.37497574},
{x:38.54123823,y:-82.6746891},
{x:39.22407233,y:-76.65945307},
{x:43.2968755,y:-73.68103677},
{x:36.09767363,y:-119.57420424},
{x:44.08488389,y:-123.16994815},
{x:42.6514917,y:-84.0732928},
{x:34.03364924,y:-117.06095691},
{x:26.14782287,y:-80.31959881},
{x:34.31438278,y:-83.79565394},
{x:41.36770653,y:-72.0702578},
{x:41.32051715,y:-72.87395154},
{x:33.76031417,y:-117.85227355},
{x:41.57970753,y:-83.66379485},
{x:40.7071168,y:-73.70065592},
{x:33.91675104,y:-118.35053767},
{x:37.75239811,y:-87.0574779},
{x:39.76063519,y:-121.84889578},
{x:29.65637077,y:-95.51186166},
{x:33.85923288,y:-84.67192824},
{x:39.46760686,y:-119.78517545},
{x:34.55403812,y:-77.4417901},
{x:42.29880573,y:-84.02534201},
{x:34.20159388,y:-86.15097106},
{x:30.16698564,y:-95.46223551},
{x:35.04121687,y:-85.15342976},
{x:33.966106,y:-84.410766},
{x:39.95607545,y:-104.77706839},
{x:40.1382849,y:-76.30369058},
{x:33.97485526,y:-117.55521027},
{x:37.24496631,y:-121.88816192},
{x:40.87734311,y:-79.95044855},
{x:42.2606581,y:-85.18805534},
{x:46.55005226,y:-87.45386039},
{x:32.6051,y:-90.0679},
{x:36.885676,y:-94.877941},
{x:34.06277944,y:-118.44671959},
{x:44.08013707,y:-123.04222058},
{x:42.35062845,y:-71.11440437},
{x:40.5371841,y:-79.84050304},
{x:28.42348454,y:-81.40517279},
{x:33.99486366,y:-117.96812333},
{x:35.17951728,y:-89.83678758},
{x:45.096821,y:-93.19397543},
{x:37.81035398,y:-121.29392266},
{x:35.90411834,y:-84.14302813},
{x:42.06802643,y:-80.03905544},
{x:33.60208291,y:-112.28954315},
{x:36.04294011,y:-86.95913364},
{x:42.25149995,y:-83.18267857},
{x:40.77450493,y:-82.47799165},
{x:29.59906348,y:-98.28017615},
{x:28.87513427,y:-82.09626496},
{x:35.13055752,y:-84.87646359},
{x:34.17389975,y:-119.1795373},
{x:45.15088492,y:-122.60572628},
{x:40.74706595,y:-73.42171535},
{x:32.30734272,y:-95.27236728},
{x:34.775655,y:-80.382287},
{x:31.05949198,y:-98.18107371},
{x:33.60968968,y:-112.35594046},
{x:36.50870181,y:-87.27546747},
{x:38.63856624,y:-121.32850386},
{x:32.30383111,y:-90.17137698},
{x:35.40082088,y:-119.3960685},
{x:38.75504399,y:-90.43102525},
{x:35.20866612,y:-89.89520735},
{x:36.07651614,y:-80.39115787},
{x:33.96284558,y:-80.9409257},
{x:36.06022656,y:-90.51954617},
{x:35.1604463,y:-90.18432406},
{x:33.20554556,y:-96.73233216},
{x:41.23295512,y:-80.47276407},
{x:37.30882721,y:-121.01909991},
{x:40.8357645,y:-81.3361133},
{x:34.51737621,y:-82.48914485},
{x:37.11259194,y:-87.90106107},
{x:39.10636237,y:-84.53397587},
{x:42.73740914,y:-84.50757086},
{x:25.76553503,y:-80.23431838},
{x:27.85596296,y:-82.63838894},
{x:61.13834685,y:-149.95045767},
{x:33.54653458,y:-80.83066115},
{x:32.27588419,y:-81.22995937},
{x:43.02229249,y:-85.63386954},
{x:29.64038388,y:-95.58125988},
{x:39.3845459,y:-76.57661453},
{x:40.80025271,y:-72.86608234},
{x:29.11082951,y:-81.02506886},
{x:41.67060373,y:-72.78081625},
{x:32.8385322,y:-97.16045782},
{x:30.67554927,y:-88.14249699},
{x:43.69217356,y:-85.48422982},
{x:34.17266081,y:-82.37528144},
{x:40.40573131,y:-120.63334859},
{x:35.66083755,y:-80.49988649},
{x:38.81660092,y:-104.75697157},
{x:39.35376296,y:-84.46083315},
{x:44.08785956,y:-103.19639593},
{x:35.05813711,y:-98.26206389},
{x:28.55862413,y:-81.82466389},
{x:32.72499472,y:-97.4778624},
{x:33.92523335,y:-116.89881508},
{x:35.74586779,y:-79.16668369},
{x:43.42902234,y:-83.95065062},
{x:33.81208974,y:-117.99278016},
{x:33.94943525,y:-83.40917043},
{x:35.03731246,y:-82.0749996},
{x:34.6708208,y:-86.53967915},
{x:36.41292419,y:-82.47246717},
{x:25.88159101,y:-80.35368472},
{x:35.46182914,y:-97.4058335},
{x:42.86098988,y:-71.35333098},
{x:35.71527291,y:-81.69620536},
{x:32.42527645,y:-84.94874083},
{x:32.6318,y:-85.4985},
{x:37.97016136,y:-122.52018569},
{x:34.9093632,y:-82.10327451},
{x:38.02082142,y:-121.26821369},
{x:40.74192725,y:-96.62046296},
{x:28.5342157,y:-82.4080057},
{x:30.47822717,y:-87.20678777},
{x:44.17952458,y:-93.96229491},
{x:36.42591637,y:-82.31757646},
{x:34.02650253,y:-118.2766719},
{x:35.33925378,y:-119.05465002},
{x:38.42750029,y:-122.7418944},
{x:42.4989006,y:-75.52755384},
{x:36.37986973,y:-86.46447293},
{x:34.15573487,y:-118.63341551},
{x:40.62675427,y:-74.13236615},
{x:34.36109796,y:-80.0672972},
{x:27.0554714,y:-82.4111457},
{x:35.2815173,y:-93.10298686},
{x:41.14790883,y:-80.6647106},
{x:43.0791992,y:-75.31527141},
{x:40.75967913,y:-82.55937705},
{x:37.95293177,y:-91.77744895},
{x:33.406284,y:-86.80573088},
{x:39.99974368,y:-105.26234087},
{x:34.57131098,y:-118.04459808},
{x:42.10811582,y:-83.2853619},
{x:35.69709437,y:-89.9834144},
{x:39.02739101,y:-94.25583668},
{x:36.12970595,y:-111.23936538},
{x:40.05844394,y:-82.91755226},
{x:33.45767057,y:-90.63681408},
{x:26.49349426,y:-81.93885822},
{x:32.25091218,y:-110.91886483},
{x:41.15518656,y:-82.21860762},
{x:34.09236213,y:-118.32630113},
{x:33.24305974,y:-117.29148744},
{x:30.48627091,y:-86.12258973},
{x:33.18136479,y:-102.27472027},
{x:40.4635941,y:-79.8252534},
{x:37.81885524,y:-121.21870491},
{x:33.71376426,y:-116.17552069},
{x:33.57811441,y:-117.72333075},
{x:40.99560583,y:-73.67103258},
{x:28.25898037,y:-81.58950913},
{x:25.47971293,y:-80.46359722},
{x:38.65462589,y:-121.5269722},
{x:39.04436345,y:-108.55725372},
{x:40.53507633,y:-81.91731602},
{x:36.03781411,y:-94.25566864},
{x:39.0145834,y:-121.43229215},
{x:34.13862545,y:-117.45467326},
{x:35.2312721,y:-97.48087674},
{x:35.39472733,y:-97.56601433},
{x:40.63740619,y:-79.93957578},
{x:40.11223247,y:-83.08943962},
{x:42.68249258,y:-84.43422318},
{x:39.5768091,y:-76.3421195},
{x:27.81484118,y:-82.72810985},
{x:45.43797187,y:-122.57950634},
{x:39.23652769,y:-120.0235118},
{x:38.75201038,y:-108.05021793},
{x:41.46459329,y:-81.76175184},
{x:41.69686499,y:-73.90390897},
{x:27.94528521,y:-82.52321634},
{x:35.67241641,y:-105.96142446},
{x:39.9229725,y:-83.78273968},
{x:33.58348372,y:-117.63288944},
{x:40.13169231,y:-75.53474218},
{x:34.691205,y:-86.57956217},
{x:35.24370587,y:-96.67171021},
{x:29.49771592,y:-98.62108729},
{x:47.11237586,y:-88.58703643},
{x:35.08246952,y:-77.03215554},
{x:43.06505841,y:-77.36065482},
{x:39.98511937,y:-83.15031771},
{x:44.64119546,y:-123.06556606},
{x:41.830853,y:-72.495434},
{x:39.59536038,y:-104.88829829},
{x:29.16967453,y:-82.16357861},
{x:36.326713,y:-76.224398},
{x:27.75608196,y:-82.6386869},
{x:38.82032046,y:-90.51609678},
{x:35.25553019,y:-95.10887521},
{x:28.43450548,y:-82.55104203},
{x:33.72706788,y:-117.91948773},
{x:30.38907321,y:-97.65077904},
{x:29.32115364,y:-98.55349333},
{x:42.88363096,y:-85.6660227},
{x:25.70009667,y:-80.4471009},
{x:36.06624205,y:-89.39538181},
{x:35.69843081,y:-79.78455517},
{x:45.00675748,y:-122.99982839},
{x:40.79919192,y:-81.39967327},
{x:33.93888197,y:-118.04684405},
{x:44.7056,y:-75.459},
{x:25.44564862,y:-80.47558062},
{x:34.62541147,y:-78.60655312},
{x:41.95826734,y:-83.66784914},
{x:36.13512528,y:-79.85885102},
{x:34.06436912,y:-84.07426242},
{x:39.52654205,y:-84.38923556},
{x:61.14048754,y:-149.86297301},
{x:32.46912193,y:-90.1329115},
{x:30.1721409,y:-81.60369717},
{x:40.2489718,y:-74.68662664},
{x:38.94611593,y:-76.7209513},
{x:39.132676,y:-121.639097},
{x:28.29113193,y:-82.71173498},
{x:38.65897254,y:-90.29904595},
{x:32.54758575,y:-83.59648686},
{x:27.94489856,y:-82.47624524},
{x:41.46243821,y:-84.55140198},
{x:40.83742194,y:-73.88474406},
{x:33.9482264,y:-118.15554594},
{x:30.47789924,y:-81.63547009},
{x:28.32929975,y:-82.69862384},
{x:39.78333941,y:-105.11021106},
{x:32.1868141,y:-110.95585193},
{x:42.99781529,y:-84.16901139},
{x:28.19542916,y:-82.52047581},
{x:34.03726767,y:-81.09029438},
{x:35.82225777,y:-79.82204367},
{x:40.42315923,y:-104.73551546},
{x:34.12942156,y:-117.89335288},
{x:36.24094393,y:-115.23462743},
{x:39.87099927,y:-75.38471266},
{x:40.55789486,y:-76.79126329},
{x:36.13948557,y:-86.88071943},
{x:32.75061019,y:-117.20830119},
{x:35.43176438,y:-97.40360821},
{x:42.63493874,y:-82.85773766},
{x:33.81667847,y:-117.22660401},
{x:35.55576146,y:-81.06337174},
{x:45.389966,y:-84.91718198},
{x:33.83031436,y:-78.67822449},
{x:37.31195295,y:-121.93140288},
{x:34.47552038,y:-110.08710891},
{x:39.18239292,y:-77.2628919},
{x:30.31832998,y:-81.55668323},
{x:34.8848267,y:-92.1125019},
{x:36.18185366,y:-94.13909408},
{x:48.55757276,y:-117.92379543},
{x:25.68487908,y:-80.41769548},
{x:43.59373788,y:-83.83880228},
{x:33.61666919,y:-117.91539278},
{x:42.33597169,y:-71.04678596},
{x:32.31307878,y:-81.05568835},
{x:27.84789513,y:-97.5887873},
{x:36.22528214,y:-83.0461478},
{x:33.44922059,y:-112.35785397},
{x:39.0951053,y:-84.26601648},
{x:33.02611834,y:-96.84145957},
{x:26.63801098,y:-80.20498225},
{x:37.67125549,y:-121.03005423},
{x:33.20134953,y:-96.63786634},
{x:31.49386278,y:-82.85059009},
{x:39.75933799,y:-105.14209543},
{x:30.3520504,y:-81.59808868},
{x:36.810859,y:-119.75477551},
{x:34.82394065,y:-87.2913419},
{x:40.05181313,y:-83.05018048},
{x:32.08901898,y:-110.77350963},
{x:38.41957823,y:-82.50619895},
{x:39.26627529,y:-94.57636316},
{x:28.18530053,y:-82.43954037},
{x:28.12352732,y:-81.63902856},
{x:38.54758435,y:-76.98589504},
{x:34.08553774,y:-84.5209096},
{x:32.18063671,y:-83.18038253},
{x:38.70661636,y:-121.27130358},
{x:39.96417841,y:-83.78501152},
{x:36.05591024,y:-115.27793169},
{x:33.93577286,y:-117.19103876},
{x:39.32947556,y:-74.59644962},
{x:42.93164207,y:-85.35996079},
{x:41.82136648,y:-73.08020337},
{x:42.44629848,y:-75.05410568},
{x:36.00815965,y:-83.97670459},
{x:40.85114632,y:-73.15948371},
{x:41.7913301,y:-71.40576016},
{x:40.95472368,y:-117.74877727},
{x:39.41694155,y:-77.44552854},
{x:30.44079077,y:-88.8989532},
{x:35.23753084,y:-77.57307169},
{x:34.38346711,y:-92.82817437},
{x:42.65975404,y:-83.24630513},
{x:41.56687421,y:-75.51512036},
{x:32.99373267,y:-80.03949662},
{x:43.11671768,y:-76.14440559},
{x:33.04909771,y:-85.02800552},
{x:31.55382857,y:-110.25813452},
{x:27.29936196,y:-82.45309798},
{x:33.39428134,y:-111.87414367},
{x:33.04611925,y:-117.25648626},
{x:40.72189607,y:-73.79490675},
{x:40.81303301,y:-73.62705332},
{x:37.1245841,y:-121.64920636},
{x:27.75250992,y:-97.39708547},
{x:36.28343264,y:-88.30774471},
{x:38.85768401,y:-104.72081159},
{x:41.15705963,y:-81.41157116},
{x:42.12455307,y:-80.07958976},
{x:39.74516684,y:-84.18265343},
{x:39.95889467,y:-83.09279412},
{x:40.8759601,y:-81.36434953},
{x:46.84225572,y:-100.77366915},
{x:42.45397795,y:-83.12952697},
{x:29.7294721,y:-95.30969158},
{x:38.00598695,y:-121.83763705},
{x:36.54012561,y:-87.36630177},
{x:39.8479027,y:-82.7769164},
{x:36.37769504,y:-93.58595099},
{x:29.5556043,y:-95.37635219},
{x:33.37933592,y:-111.78559611},
{x:37.96698818,y:-121.78391846},
{x:60.48912768,y:-151.05179638},
{x:38.49566776,y:-121.45592138},
{x:39.49330802,y:-119.78853004},
{x:31.96169846,y:-110.34618836},
{x:42.49052209,y:-83.12540046},
{x:39.74045053,y:-104.92607126},
{x:33.89517574,y:-118.00867756},
{x:39.64850971,y:-78.75912179},
{x:41.77186379,y:-71.45222694},
{x:41.03812038,y:-74.03045726},
{x:43.48042859,y:-83.96390145},
{x:37.96217711,y:-121.27356626},
{x:32.69729774,y:-114.62495676},
{x:37.77855525,y:-122.3927612},
{x:39.14603761,y:-121.59419326},
{x:39.98886011,y:-83.04159086},
{x:41.95373368,y:-87.69861687},
{x:38.94993549,y:-76.88606778},
{x:36.1787181,y:-83.39386376},
{x:26.20690976,y:-80.22060692},
{x:40.93906941,y:-98.38403821},
{x:34.14010069,y:-118.00276101},
{x:34.0874755,y:-118.15169697},
{x:41.61444837,y:-83.48383382},
{x:33.07033707,y:-112.045988},
{x:38.06921236,y:-84.53023285},
{x:40.05126057,y:-75.05744722},
{x:33.624919,y:-83.9771225},
{x:38.68962148,y:-121.38328035},
{x:40.45221457,y:-74.42494866},
{x:32.82675011,y:-96.830566},
{x:35.23222284,y:-100.24991944},
{x:37.61123207,y:-122.06742971},
{x:34.3073496,y:-92.4081685},
{x:41.15633214,y:-81.86314942},
{x:34.27875108,y:-118.7079245},
{x:39.90267243,y:-79.75734211},
{x:36.31465674,y:-115.30858985},
{x:32.72069965,y:-96.83063812},
{x:36.03201854,y:-80.38277216},
{x:35.59519679,y:-82.53294468},
{x:36.06876484,y:-79.87085854},
{x:36.96078165,y:-92.67033816},
{x:36.80332825,y:-89.9803632},
{x:33.60825293,y:-86.80203839},
{x:34.44515828,y:-83.11913878},
{x:35.80377693,y:-83.58050287},
{x:40.10988185,y:-82.92470567},
{x:34.14626557,y:-118.11007981},
{x:33.98336665,y:-117.71438789},
{x:33.39321404,y:-111.91098154},
{x:30.44936297,y:-84.3070807},
{x:38.81889841,y:-121.28042042},
{x:33.79062189,y:-117.76209592},
{x:41.27619566,y:-72.83429955},
{x:29.28122217,y:-81.0685114},
{x:33.96928449,y:-84.48970459},
{x:41.23444575,y:-80.8121708},
{x:42.83595041,y:-84.21738093},
{x:28.0302695,y:-82.0564684},
{x:33.88876705,y:-117.87438968},
{x:35.99197704,y:-86.526529},
{x:37.99539603,y:-122.03194904},
{x:33.69475799,y:-117.33377363},
{x:31.23739657,y:-85.45961921},
{x:28.48305447,y:-81.309506},
{x:26.122362,y:-80.105114},
{x:28.23115216,y:-80.70049432},
{x:40.40842739,y:-105.00177212},
{x:45.38088862,y:-122.75966769},
{x:39.50214121,y:-84.35582525},
{x:41.3222,y:-73.8539},
{x:35.65679064,y:-88.41013938},
{x:35.50869698,y:-92.02895953},
{x:33.72323359,y:-118.04197351},
{x:33.92615678,y:-118.23869749},
{x:35.28756439,y:-120.66469118},
{x:33.60523852,y:-114.60579507},
{x:39.03420218,y:-94.53198025},
{x:45.16523725,y:-93.26571295},
{x:39.93942177,y:-74.97358535},
{x:30.2211737,y:-81.58488572},
{x:27.9611532,y:-82.71334281},
{x:40.06630167,y:-83.09261862},
{x:34.10648376,y:-117.90515502},
{x:38.005144,y:-121.806606},
{x:35.19112155,y:-111.63034724},
{x:32.75569201,y:-117.13615903},
{x:33.75318897,y:-90.71521023},
{x:29.76947141,y:-95.18562274},
{x:34.60186672,y:-112.45745888},
{x:37.52302888,y:-122.00487025},
{x:36.11632441,y:-83.48940839},
{x:39.95448916,y:-82.82650233},
{x:35.08409051,y:-80.66177256},
{x:36.0060811,y:-80.43963874},
{x:30.26829471,y:-81.61518757},
{x:33.64097052,y:-111.97492398},
{x:39.89147763,y:-84.19433673},
{x:35.73063443,y:-84.38922373},
{x:42.5193591,y:-83.22385504},
{x:32.71142957,y:-117.1256408},
{x:44.16632794,y:-93.95374998},
{x:34.0703464,y:-118.44414985},
{x:39.69754336,y:-105.14536411},
{x:35.81717842,y:-90.73384312},
{x:28.70125752,y:-81.50966316},
{x:29.67364609,y:-82.3396381},
{x:34.2415,y:-88.4004},
{x:37.70558572,y:-121.85124218},
{x:37.53466364,y:-121.96643519},
{x:45.58002369,y:-122.57570553},
{x:40.73828494,y:-73.31999928},
{x:36.2373943,y:-90.03407666},
{x:33.96940338,y:-112.72062913},
{x:40.81773169,y:-73.92325632},
{x:28.77808037,y:-81.27583851},
{x:41.04247602,y:-82.72759472},
{x:32.36012089,y:-86.21740935},
{x:39.96829295,y:-82.00945415},
{x:35.56774483,y:-88.81542921},
{x:40.26259283,y:-103.80184829},
{x:36.81108908,y:-119.74777223},
{x:44.56753095,y:-92.58169551},
{x:40.68624125,y:-73.82344554},
{x:40.88124023,y:-74.20899788},
{x:30.41158986,y:-84.28106144},
{x:40.26475461,y:-94.00934508},
{x:38.25349086,y:-121.2958353},
{x:33.6993812,y:-117.83496894},
{x:26.67907488,y:-81.80672318},
{x:33.49311671,y:-112.29149979},
{x:35.01197392,y:-114.59763685},
{x:30.59076331,y:-88.17006348},
{x:26.70893555,y:-80.22845767},
{x:41.53273589,y:-83.3622738},
{x:37.98940697,y:-103.56593868},
{x:32.88064216,y:-80.06378144},
{x:33.85519896,y:-84.20903548},
{x:42.25059014,y:-83.19532196},
{x:29.56955759,y:-97.93962962},
{x:34.14983788,y:-118.44062737},
{x:42.5906926,y:-84.46131499},
{x:39.81719324,y:-75.457208},
{x:33.85825713,y:-118.07265414},
{x:41.41762503,y:-82.20947098},
{x:33.8732431,y:-118.2957635},
{x:43.05993244,y:-78.84045391},
{x:39.80699578,y:-75.54909052},
{x:40.72789865,y:-76.19237706},
{x:36.20660693,y:-115.30317396},
{x:35.89920473,y:-94.9750307},
{x:29.81916118,y:-85.30582606},
{x:35.01908976,y:-78.89840931},
{x:27.52165164,y:-82.5572387},
{x:36.06054502,y:-95.77415168},
{x:34.93738,y:-80.83615},
{x:31.795084,y:-106.231474},
{x:34.88316495,y:-92.10885759},
{x:35.51396637,y:-120.69818795},
{x:42.53188172,y:-83.16505417},
{x:34.02226145,y:-117.80975761},
{x:39.5429382,y:-82.42642669},
{x:37.12693292,y:-94.47612263},
{x:33.41227235,y:-82.02532054},
{x:34.01774806,y:-86.07828311},
{x:35.05022677,y:-80.64345901},
{x:29.49756649,y:-98.71246127},
{x:37.64569551,y:-121.02897942},
{x:34.43586102,y:-85.73963016},
{x:39.26765185,y:-76.49834052},
{x:40.2883465,y:-84.18456048},
{x:40.3997409,y:-79.58371869},
{x:41.65239226,y:-83.66515889},
{x:40.58869638,y:-81.09151762},
{x:45.11028338,y:-93.3024909},
{x:40.4783649,y:-74.48543747},
{x:37.2412213,y:-93.30791473},
{x:30.76000888,y:-98.67910817},
{x:36.11581801,y:-115.20724744},
{x:27.5310553,y:-99.47359899},
{x:38.87124297,y:-121.29787673},
{x:40.76716593,y:-84.10908409},
{x:41.37821535,y:-83.11645655},
{x:40.23825852,y:-74.03802127},
{x:36.17751309,y:-85.94723676},
{x:40.85826254,y:-82.26467573},
{x:43.03627005,y:-83.32316101},
{x:42.25524077,y:-84.36797892},
{x:36.03473712,y:-86.79206721},
{x:41.46320133,y:-81.08619078},
{x:44.99363041,y:-124.00598275},
{x:35.61433908,y:-85.18976798},
{x:41.54337874,y:-96.14151565},
{x:38.57071571,y:-75.28743783},
{x:39.7439075,y:-104.9900451},
{x:39.76723652,y:-94.84892428},
{x:33.23950898,y:-92.66604625},
{x:28.389069,y:-81.4039546},
{x:39.61420867,y:-77.41824681},
{x:43.02412169,y:-83.59484464},
{x:42.44013679,y:-83.37375375},
{x:44.2769,y:-83.5018},
{x:39.63291568,y:-75.66163668},
{x:35.58722573,y:-82.59008912},
{x:30.51732852,y:-89.67473941},
{x:26.24302238,y:-80.25434665},
{x:34.39555185,y:-118.92029039},
{x:42.14318371,y:-83.22692327},
{x:39.33367566,y:-91.18328319},
{x:38.58174429,y:-92.21950342},
{x:36.52553283,y:-82.31723831},
{x:42.0953,y:-83.2378},
{x:33.39346927,y:-111.69949017},
{x:31.52498623,y:-91.39124036},
{x:41.91834765,y:-72.61069541},
{x:34.06945406,y:-118.27727012},
{x:29.96168454,y:-95.65065205},
{x:41.19914267,y:-74.48748707},
{x:34.196491,y:-84.789276},
{x:34.82067733,y:-87.68110268},
{x:42.34483992,y:-83.1567693},
{x:33.9372715,y:-117.4535345},
{x:37.32157986,y:-121.8256018},
{x:44.26544731,y:-121.17485829},
{x:44.90550525,y:-123.04298892},
{x:40.438755,y:-79.774354},
{x:42.46538675,y:-82.93832356},
{x:35.35321282,y:-119.02175769},
{x:39.62552966,y:-104.79190655},
{x:33.3487638,y:-86.63276821},
{x:41.7144,y:-81.2988},
{x:33.63823526,y:-112.41662586},
{x:34.06353546,y:-80.95469672},
{x:33.95389256,y:-81.23342985},
{x:29.64847816,y:-95.16321741},
{x:40.567622,y:-105.07653491},
{x:32.82533357,y:-116.9025363},
{x:35.4724,y:-95.5349},
{x:33.65180466,y:-117.64569823},
{x:36.072664,y:-76.610732},
{x:27.4866469,y:-82.45543461},
{x:38.48133625,y:-121.40551457},
{x:35.1151675,y:-85.25543691},
{x:32.24901037,y:-110.85120589},
{x:38.67801795,y:-121.79380821},
{x:40.90139035,y:-80.83455786},
{x:37.68974792,y:-121.01223988},
{x:25.49929454,y:-80.41121372},
{x:34.72183774,y:-112.00198412},
{x:34.57478964,y:-111.8815758},
{x:36.2862165,y:-115.31464127},
{x:25.75746062,y:-80.37627924},
{x:37.22364409,y:-93.26101819},
{x:40.47321332,y:-78.40644868},
{x:34.7607188,y:-92.38040836},
{x:37.46399285,y:-121.14302814},
{x:33.23958847,y:-87.57610287},
{x:33.06960505,y:-96.873926},
{x:42.00778516,y:-80.30449297},
{x:35.1239589,y:-118.46751927},
{x:28.56719956,y:-81.20559722},
{x:33.16753466,y:-87.524591},
{x:38.49849294,y:-76.77416987},
{x:27.91583352,y:-82.73247224},
{x:39.955613,y:-75.182005},
{x:33.17155841,y:-86.28217821},
{x:29.55105245,y:-98.51167135},
{x:35.04888066,y:-89.79713608},
{x:45.98939969,y:-123.92146287},
{x:41.25955266,y:-80.78408767},
{x:35.92553743,y:-84.038063},
{x:41.64881939,y:-72.73003615},
{x:33.66993695,y:-112.02917804},
{x:34.0656709,y:-117.27771961},
{x:35.25007125,y:-120.6436337},
{x:42.762448,y:-84.54320257},
{x:34.74108788,y:-92.37274728},
{x:30.28095,y:-81.719691},
{x:41.05481142,y:-75.09239495},
{x:39.07451693,y:-94.37847514},
{x:28.89929648,y:-82.58723393},
{x:40.27220377,y:-80.12681007},
{x:27.44766419,y:-82.5221443},
{x:43.981038,y:-84.471077},
{x:32.36673267,y:-88.66902073},
{x:38.36368291,y:-121.96822552},
{x:33.8294594,y:-84.49174583},
{x:40.15424851,y:-76.82088552},
{x:45.22535695,y:-123.18868286},
{x:41.40595277,y:-84.13271478},
{x:35.95121178,y:-83.20001365},
{x:37.37208304,y:-122.04750396},
{x:40.55475925,y:-75.49046606},
{x:40.78067367,y:-124.18571606},
{x:32.81917336,y:-117.18355805},
{x:42.27021973,y:-73.76053695},
{x:40.81421117,y:-96.7431137},
{x:42.7574638,y:-78.13183275},
{x:41.86582564,y:-71.44411911},
{x:39.0255549,y:-76.69070183},
{x:41.02463028,y:-80.63542932},
{x:40.39449253,y:-84.16367505},
{x:32.77901355,y:-96.856961},
{x:34.69712275,y:-106.77388608},
{x:42.7521452,y:-71.46093164},
{x:35.91990199,y:-78.95752345},
{x:35.37938987,y:-120.85441991},
{x:34.46763225,y:-84.46075101},
{x:37.52583682,y:-120.88136939},
{x:33.32435998,y:-105.62490825},
{x:41.23443163,y:-96.14241958},
{x:35.39843224,y:-119.10936407},
{x:33.58186405,y:-112.23669384},
{x:34.92327286,y:-88.51575299},
{x:35.99790004,y:-86.69063143},
{x:41.41248857,y:-73.66837931},
{x:33.87705403,y:-118.16843366},
{x:43.00259891,y:-83.73376191},
{x:35.81643031,y:-83.98044357},
{x:27.83962631,y:-82.69574134},
{x:40.10784241,y:-83.19209307},
{x:41.52037906,y:-81.46883525},
{x:25.59202321,y:-80.37985772},
{x:30.21757426,y:-95.74370249},
{x:36.33578562,y:-78.43965313},
{x:35.66299743,y:-80.46427663},
{x:36.27231387,y:-76.97160498},
{x:41.02477314,y:-80.67819004},
{x:35.16721368,y:-114.56581723},
{x:32.12574071,y:-110.87420285},
{x:29.58003174,y:-95.39781831},
{x:41.33157808,y:-73.08033582},
{x:29.68038278,y:-95.122912},
{x:39.5807723,y:-104.72453886},
{x:41.45185697,y:-81.80180139},
{x:34.88882547,y:-82.39974175},
{x:36.80817037,y:-119.71900557},
{x:45.85389982,y:-119.29066421},
{x:36.19564822,y:-119.31386435},
{x:40.86477118,y:-74.40225546},
{x:35.21153883,y:-106.69851559},
{x:33.21108657,y:-82.38576385},
{x:26.78173178,y:-80.08349332},
{x:38.5573312,y:-121.5398929},
{x:32.97129748,y:-96.99421674},
{x:39.45329815,y:-74.63250639},
{x:35.85807861,y:-80.07324905},
{x:33.61108142,y:-112.16667261},
{x:40.29618321,y:-75.84052205},
{x:36.91355049,y:-93.9135468},
{x:39.95846912,y:-104.97763462},
{x:46.61301027,y:-112.02034244},
{x:35.91288421,y:-84.5813842},
{x:35.92657565,y:-79.98487119},
{x:43.37466193,y:-83.59434869},
{x:33.9894544,y:-118.2917837},
{x:37.05730047,y:-120.83732233},
{x:39.10864565,y:-93.21233737},
{x:30.32708268,y:-82.96449486},
{x:36.77987506,y:-119.77303465},
{x:34.27997239,y:-86.21699877},
{x:39.80348207,y:-75.04430606},
{x:39.16603083,y:-123.21039332},
{x:40.84930659,y:-81.94684832},
{x:40.63705977,y:-73.64061186},
{x:37.65400958,y:-104.7940859},
{x:32.80912873,y:-117.11691564},
{x:35.41909755,y:-78.80443275},
{x:32.35356839,y:-111.09420813},
{x:35.06279254,y:-85.13803277},
{x:28.64928493,y:-81.26870424},
{x:31.13833223,y:-83.44266532},
{x:36.19606521,y:-115.03020028},
{x:33.9018778,y:-118.08288664},
{x:35.10074469,y:-80.99148989},
{x:37.76806121,y:-122.17905424},
{x:40.78659054,y:-73.95017016},
{x:41.92836961,y:-83.41266378},
{x:39.46155309,y:-76.31802322},
{x:36.16987382,y:-95.76208457},
{x:36.15926481,y:-115.12003824},
{x:33.84153039,y:-117.88890256},
{x:40.68986278,y:-73.92226419},
{x:37.40596945,y:-120.85054242},
{x:43.03375427,y:-76.1891153},
{x:29.41744669,y:-98.65379293},
{x:28.24668495,y:-81.24437439},
{x:37.705628,y:-122.128444},
{x:33.63840238,y:-84.42932417},
{x:38.52532713,y:-121.46515623},
{x:33.64915488,y:-117.58190605},
{x:45.04514546,y:-83.45219595},
{x:39.88566572,y:-104.99421988},
{x:27.46282963,y:-82.59837716},
{x:42.47009413,y:-73.1971384},
{x:33.49410357,y:-112.08307743},
{x:39.46235442,y:-76.63617735},
{x:33.81831877,y:-116.45861119},
{x:34.30539397,y:-78.71824452},
{x:32.40965292,y:-88.70134849},
{x:43.81743443,y:-83.00031663},
{x:44.67547992,y:-85.65568032},
{x:35.67932479,y:-90.52425941},
{x:33.8542769,y:-79.07821655},
{x:33.75174936,y:-84.72289301},
{x:33.95485048,y:-84.05577776},
{x:40.83217326,y:-77.80450608},
{x:42.91202269,y:-82.496277},
{x:39.13163547,y:-76.74089898},
{x:38.07531889,y:-75.55680871},
{x:30.55961528,y:-97.84548685},
{x:29.93553938,y:-95.24763916},
{x:36.75810406,y:-89.53476931},
{x:25.52121109,y:-80.42459114},
{x:33.46115642,y:-112.16964986},
{x:41.23350646,y:-96.20259002},
{x:39.07709633,y:-94.41432691},
{x:33.60815693,y:-85.8300326},
{x:39.34953648,y:-84.21853127},
{x:44.86219002,y:-93.27512265},
{x:36.53172336,y:-82.52800734},
{x:38.94142995,y:-104.67944046},
{x:30.45354532,y:-89.10057959},
{x:45.5717782,y:-93.20054884},
{x:45.52040203,y:-123.07102559},
{x:40.77752769,y:-80.38234177},
{x:35.98776709,y:-84.04925027},
{x:31.20367268,y:-82.33275964},
{x:34.1018063,y:-118.33718652},
{x:40.70585014,y:-73.79174928},
{x:43.48021546,y:-70.48679779},
{x:32.66077112,y:-97.27113256},
{x:36.37629063,y:-93.58716227},
{x:39.99976563,y:-75.15369786},
{x:33.78127194,y:-117.86784589},
{x:33.573508,y:-92.855363},
{x:40.42722453,y:-80.05416606},
{x:29.60860362,y:-98.47027995},
{x:32.96380055,y:-96.64361447},
{x:39.76860175,y:-94.8040984},
{x:38.44335995,y:-105.2317362},
{x:35.46621998,y:-97.52875283},
{x:40.2637814,y:-83.3742963},
{x:40.39466734,y:-104.75561216},
{x:35.96729081,y:-88.93028479},
{x:34.42510562,y:-117.57208914},
{x:32.83283697,y:-117.20138647},
{x:38.45504402,y:-121.41733048},
{x:28.52160079,y:-81.58802888},
{x:33.72704502,y:-117.90585844},
{x:41.72530589,y:-81.24734466},
{x:41.41761899,y:-82.20947202},
{x:41.25072351,y:-75.85466534},
{x:44.74889943,y:-93.38060002},
{x:33.71689565,y:-84.21496087},
{x:41.13627178,y:-81.63787021},
{x:35.18674542,y:-106.66279478},
{x:36.15612936,y:-80.31643876},
{x:31.87542162,y:-90.40256658},
{x:35.82659079,y:-86.08398557},
{x:28.53269639,y:-82.50159878},
{x:45.55009376,y:-122.90659219},
{x:28.34321128,y:-81.32938075},
{x:35.83743499,y:-80.25397168},
{x:45.0466252,y:-93.10587488},
{x:41.31451467,y:-96.02446941},
{x:34.94155499,y:-80.97823299},
{x:29.4750601,y:-81.22119659},
{x:40.75751447,y:-74.2182644},
{x:37.04410427,y:-93.30423168},
{x:28.12562271,y:-82.3694729},
{x:40.75090724,y:-73.98673488},
{x:38.98395333,y:-77.02542286},
{x:28.55125511,y:-81.50640426},
{x:39.71486958,y:-82.56766722},
{x:42.19883092,y:-83.19256272},
{x:30.6582772,y:-97.88761756},
{x:32.36426839,y:-88.72677644},
{x:42.34044468,y:-85.15500865},
{x:42.65562656,y:-77.05596096},
{x:38.65438012,y:-121.38343334},
{x:34.03082455,y:-118.33482221},
{x:36.8359939,y:-119.69956198},
{x:42.0566364,y:-76.81888417},
{x:44.0287289,y:-92.46248863},
{x:29.55406503,y:-81.22845753},
{x:35.25749465,y:-112.19123945},
{x:37.67306,y:-85.9097},
{x:40.80935264,y:-77.90483307},
{x:36.5478581,y:-82.5172876},
{x:42.47383274,y:-70.95797693},
{x:35.7552581,y:-95.40297963},
{x:30.38974803,y:-81.6780965},
{x:34.51630386,y:-97.96734743},
{x:30.663791,y:-96.3718373},
{x:35.4604209,y:-93.4590894},
{x:39.63833024,y:-77.71025642},
{x:32.22038968,y:-110.80924232},
{x:37.33114702,y:-119.65664907},
{x:39.33648193,y:-82.05962464},
{x:36.12165628,y:-80.65962738},
{x:39.31236146,y:-76.73843308},
{x:27.2688174,y:-82.4986985},
{x:36.32671204,y:-86.70067866},
{x:35.74661465,y:-119.24611878},
{x:36.30968315,y:-94.17986293},
{x:32.95644139,y:-96.81799829},
{x:35.33685038,y:-82.44614281},
{x:28.26372598,y:-81.43269075},
{x:34.06864648,y:-81.1653759},
{x:42.09529278,y:-76.00875422},
{x:33.76112184,y:-117.88552876},
{x:35.90284201,y:-78.89466628},
{x:39.75323402,y:-94.23108085},
{x:41.90827167,y:-84.03040172},
{x:38.78460188,y:-76.7953442},
{x:45.524702,y:-122.81556797},
{x:43.08775416,y:-77.61102084},
{x:33.84577596,y:-117.53882527},
{x:41.94361548,y:-87.80670028},
{x:35.5924,y:-80.822},
{x:38.77144222,y:-93.73488754},
{x:33.51436354,y:-81.7151392},
{x:40.05600378,y:-78.51077196},
{x:35.07902734,y:-106.71240643},
{x:33.77335082,y:-117.99157343},
{x:35.4591105,y:-94.7699807},
{x:44.95820863,y:-93.16740118},
{x:40.38378086,y:-82.48444726},
{x:35.14919543,y:-89.91500611},
{x:33.10192577,y:-86.86098227},
{x:35.43148036,y:-84.58559951},
{x:35.839521,y:-96.392235},
{x:42.62907878,y:-82.93530057},
{x:43.31495341,y:-76.40784706},
{x:39.64541933,y:-74.78471762},
{x:41.2124916,y:-76.7619934},
{x:45.10969538,y:-93.39413695},
{x:44.84838329,y:-74.31072646},
{x:38.85237072,y:-104.87171696},
{x:39.60454873,y:-75.75471909},
{x:33.39171586,y:-110.76558173},
{x:32.76996019,y:-89.12728863},
{x:39.06088695,y:-83.01865518},
{x:35.02030224,y:-89.93860259},
{x:34.0058079,y:-95.48831377},
{x:32.4166938,y:-97.22545844},
{x:34.49523578,y:-85.33594564},
{x:31.32011689,y:-85.71096252},
{x:31.31086345,y:-86.50004634},
{x:34.21032672,y:-110.02428602},
{x:41.34728105,y:-82.06743705},
{x:31.44854769,y:-96.07864842},
{x:30.36959839,y:-81.72297388},
{x:32.1124106,y:-81.23548959},
{x:26.70728223,y:-80.15332355},
{x:45.3960184,y:-122.74425103},
{x:30.18565419,y:-85.81796173},
{x:28.57844869,y:-81.44129594},
{x:32.85964081,y:-81.09918058},
{x:41.27054328,y:-82.6099425},
{x:34.07381943,y:-84.16499994},
{x:40.78983672,y:-73.20480727},
{x:40.61856812,y:-79.16104048},
{x:30.8024127,y:-88.0724144},
{x:33.90208395,y:-118.25298254},
{x:33.58133855,y:-112.20434802},
{x:39.12953717,y:-119.77106456},
{x:34.82728384,y:-92.38813256},
{x:27.2742875,y:-80.34850978},
{x:33.77885298,y:-84.60735438},
{x:39.33744243,y:-94.25184309},
{x:39.81226411,y:-105.13952971},
{x:41.4250288,y:-74.42822555},
{x:42.1707696,y:-83.77139816},
{x:40.76286367,y:-73.51719187},
{x:36.95020444,y:-93.71787495},
{x:34.184,y:-97.1656},
{x:32.39981225,y:-90.13356216},
{x:33.07297944,y:-97.29605781},
{x:36.5179,y:-86.0135},
{x:44.85080865,y:-123.21717139},
{x:45.1497,y:-122.832},
{x:33.78878816,y:-118.03008895},
{x:27.30132957,y:-80.30534059},
{x:28.16014717,y:-81.44070411},
{x:41.41058205,y:-73.41343967},
{x:32.91244923,y:-117.14252025},
{x:28.26407938,y:-82.18852187},
{x:26.09306589,y:-80.25040855},
{x:39.85546192,y:-105.08109917},
{x:39.48772407,y:-75.05019904},
{x:42.20688129,y:-121.72317494},
{x:33.37814747,y:-112.06491597},
{x:40.58711935,y:-79.75004574},
{x:32.7893008,y:-117.16847487},
{x:33.6830204,y:-117.36831963},
{x:35.23644243,y:-85.2009689},
{x:35.09427152,y:-78.98704592},
{x:38.65064658,y:-76.88264434},
{x:36.47585534,y:-80.61960143},
{x:33.93671099,y:-87.8387551},
{x:39.50960127,y:-84.75127367},
{x:35.14628442,y:-106.55435056},
{x:45.81057675,y:-108.47146516},
{x:34.71656846,y:-82.2554031},
{x:40.51074662,y:-79.94564504},
{x:32.31057364,y:-106.75445804},
{x:33.8880518,y:-117.84252582},
{x:45.05614879,y:-93.32336988},
{x:36.00723429,y:-84.2562716},
{x:36.04838699,y:-86.65287884},
{x:35.49267428,y:-97.37033225},
{x:26.27380668,y:-81.68366272},
{x:35.29571223,y:-119.07648763},
{x:36.17972182,y:-115.17859928},
{x:39.24432087,y:-94.46600673},
{x:40.73041126,y:-84.06717391},
{x:36.44829046,y:-83.57644571},
{x:36.81131833,y:-114.06295806},
{x:38.92293,y:-94.359839},
{x:39.5999,y:-77.7295},
{x:40.8244,y:-73.8916},
{x:35.0061638,y:-80.5577378},
{x:38.93085938,y:-75.56595046},
{x:34.45582575,y:-93.06413412},
{x:45.12905083,y:-93.25723533},
{x:37.79764081,y:-121.21197162},
{x:25.57245599,y:-80.37501852},
{x:36.10044551,y:-115.22302687},
{x:35.96284121,y:-77.83105105},
{x:32.00292302,y:-102.13684796},
{x:40.49618472,y:-81.4728713},
{x:45.50439128,y:-122.53405251},
{x:35.07744333,y:-106.53194104},
{x:41.35510765,y:-83.65091884},
{x:41.63026976,y:-80.15610749},
{x:37.61040739,y:-120.97474938},
{x:33.13047358,y:-97.04267516},
{x:26.17215476,y:-97.99031673},
{x:40.0379004,y:-82.961808},
{x:34.14891295,y:-117.47444556},
{x:37.90322914,y:-121.22107521},
{x:33.03717238,y:-111.37958534},
{x:36.23208686,y:-84.16078806},
{x:32.36422738,y:-86.26676068},
{x:42.82793593,y:-84.54133071},
{x:34.27401108,y:-118.44889015},
{x:34.05067639,y:-117.94440664},
{x:38.10696037,y:-122.57796317},
{x:40.83891878,y:-73.32206418},
{x:35.59955541,y:-92.46229199},
{x:34.02257785,y:-118.37200463},
{x:41.4587561,y:-82.02438872},
{x:33.86949114,y:-118.12478917},
{x:34.8472487,y:-87.6897491},
{x:33.696472,y:-86.679556},
{x:40.09787014,y:-82.81634773},
{x:40.4986919,y:-74.8556622},
{x:41.52148262,y:-83.46227494},
{x:36.02072891,y:-115.2690471},
{x:33.93199275,y:-93.85151265},
{x:42.6266,y:-82.9679},
{x:42.84132443,y:-85.67956879},
{x:26.30369104,y:-98.1814},
{x:36.3491984,y:-88.87493015},
{x:40.9026,y:-78.2322},
{x:40.561538,y:-84.17298808},
{x:33.64104649,y:-78.98926109},
{x:34.01707373,y:-85.25454759},
{x:34.03798999,y:-118.14731858},
{x:34.65600027,y:-99.33461577},
{x:38.00431172,y:-121.73361402},
{x:34.2736102,y:-118.41121524},
{x:34.0280424,y:-117.59384671},
{x:30.11651292,y:-93.74802254},
{x:34.42119542,y:-118.54217564},
{x:33.79624015,y:-84.22424201},
{x:28.22098299,y:-81.64224833},
{x:35.69495816,y:-80.51619608},
{x:34.33888861,y:-86.50289139},
{x:36.55148184,y:-119.39552043},
{x:39.72753076,y:-121.80665065},
{x:33.71035694,y:-116.28902753},
{x:34.73117034,y:-76.63807065},
{x:33.1140337,y:-95.59625216},
{x:26.31744502,y:-80.15585569},
{x:35.98934479,y:-119.95916882},
{x:39.84674197,y:-74.18704065},
{x:33.8116649,y:-86.82443211},
{x:36.01227723,y:-114.96205329},
{x:35.67530043,y:-88.78034591},
{x:41.46510122,y:-82.15740719},
{x:35.60673961,y:-77.39645391},
{x:28.541892,y:-81.37876977},
{x:37.36080015,y:-120.60187541},
{x:39.33366675,y:-83.01815361},
{x:35.7534269,y:-81.28474116},
{x:39.9894492,y:-76.6757415},
{x:32.07296482,y:-96.44364615},
{x:36.41437516,y:-86.79180058},
{x:39.86892287,y:-84.2930152},
{x:35.36969326,y:-119.14625189},
{x:34.94651399,y:-86.57082761},
{x:36.18261272,y:-85.46068485},
{x:32.84245247,y:-104.41468386},
{x:29.77821281,y:-95.95296577},
{x:38.68285699,y:-90.4863891},
{x:32.9091321,y:-96.85649674},
{x:38.8242,y:-104.728},
{x:37.74312154,y:-122.47879654},
{x:35.18233895,y:-97.41836612},
{x:33.43327364,y:-111.87017888},
{x:34.99150726,y:-90.00977942},
{x:40.44435056,y:-79.22899691},
{x:35.19202036,y:-101.88316879},
{x:33.87644625,y:-98.56136255},
{x:28.446748,y:-81.393772},
{x:33.87797073,y:-117.66017741},
{x:34.17566613,y:-81.32470023},
{x:44.78154,y:-117.82173},
{x:42.45719985,y:-79.31230192},
{x:33.3842,y:-86.7362},
{x:33.73376376,y:-84.9009726},
{x:35.10244459,y:-106.7295228},
{x:29.81908057,y:-95.46654083},
{x:41.68088118,y:-81.29885495},
{x:39.609408,y:-84.15991326},
{x:38.68056947,y:-121.32196699},
{x:47.682629,y:-114.122911},
{x:39.95086786,y:-83.14544588},
{x:35.51734059,y:-84.35799132},
{x:35.2040937,y:-80.86332992},
{x:33.96709575,y:-84.25700046},
{x:27.62127686,y:-80.38886321},
{x:26.27577318,y:-80.09956263},
{x:40.40639726,y:-105.07272187},
{x:39.24752391,y:-94.65407518},
{x:39.9539795,y:-74.15939855},
{x:36.2872763,y:-92.4817184},
{x:35.7255,y:-97.421},
{x:35.84407025,y:-86.44006364},
{x:34.98188236,y:-97.37477278},
{x:39.50487804,y:-121.54318129},
{x:43.02070477,y:-85.68843216},
{x:41.9893562,y:-72.58116364},
{x:44.27949991,y:-85.40604464},
{x:35.6155035,y:-119.65811193},
{x:32.4927874,y:-86.40598023},
{x:35.88389438,y:-106.01455095},
{x:33.90203917,y:-118.04584666},
{x:35.739587,y:-86.89300854},
{x:37.08500112,y:-94.54426919},
{x:25.94948938,y:-97.50693006},
{x:32.79452933,y:-79.99132096},
{x:33.65968582,y:-117.86114667},
{x:36.67850121,y:-101.47410147},
{x:38.63167296,y:-121.21871618},
{x:39.05154762,y:-84.25163984},
{x:42.96980079,y:-85.64359773},
{x:35.34920305,y:-94.34372981},
{x:42.77873889,y:-83.01547542},
{x:39.37972683,y:-76.5372009},
{x:29.05543108,y:-81.3040056},
{x:36.80898734,y:-119.67535573},
{x:34.02843114,y:-117.68996005},
{x:27.73806166,y:-82.42843476},
{x:42.34022836,y:-77.33468252},
{x:36.21146926,y:-115.98797874},
{x:33.92119856,y:-80.33542044},
{x:36.18801214,y:-86.41188102},
{x:42.68946762,y:-84.50989627},
{x:39.17829962,y:-77.24019633},
{x:31.57857824,y:-90.46777357},
{x:30.55637821,y:-96.26290037},
{x:26.43927837,y:-80.0745696},
{x:33.2761813,y:-84.29192101},
{x:34.08865615,y:-117.8904431},
{x:39.8056833,y:-74.93200958},
{x:44.45008408,y:-95.76813877},
{x:36.3215193,y:-119.3134933},
{x:36.17312743,y:-115.09578019},
{x:38.29090188,y:-121.29974023},
{x:38.57666372,y:-90.40689339},
{x:29.78112656,y:-98.73675652},
{x:38.48593858,y:-107.86661458},
{x:45.31518744,y:-93.56076419},
{x:35.27061332,y:-77.60569359},
{x:35.76009726,y:-83.97547312},
{x:40.7808,y:-81.4203},
{x:29.84833988,y:-95.6452962},
{x:37.3626121,y:-121.83410306},
{x:41.54087134,y:-72.81899921},
{x:28.04830438,y:-81.90955249},
{x:29.07398295,y:-82.07236339},
{x:38.81860733,y:-76.88255519},
{x:34.03341441,y:-117.66849361},
{x:35.85071775,y:-97.93232471},
{x:36.1218546,y:-115.13938849},
{x:34.67422646,y:-77.33591755},
{x:36.2537171,y:-120.24836629},
{x:40.27314344,y:-74.53947388},
{x:34.45627572,y:-86.94768906},
{x:38.67713089,y:-90.25292264},
{x:42.62029219,y:-83.24931577},
{x:36.07506543,y:-95.86793671},
{x:41.4519698,y:-96.4789318},
{x:33.46912774,y:-82.0650241},
{x:33.46857559,y:-86.8185769},
{x:33.0062,y:-83.5432},
{x:36.09907899,y:-75.72158999},
{x:30.4436582,y:-88.93441468},
{x:33.39098732,y:-111.840715},
{x:40.82949459,y:-73.02044041},
{x:34.63896672,y:-78.99990343},
{x:31.85380064,y:-86.64151219},
{x:38.55725529,y:-90.3872729},
{x:32.40296583,y:-99.75872397},
{x:43.01031844,y:-83.65484766},
{x:40.90250734,y:-81.14864714},
{x:33.39364613,y:-86.97267517},
{x:42.34475961,y:-85.23256366},
{x:35.90082246,y:-77.56159162},
{x:36.12457558,y:-94.15405512},
{x:44.11774811,y:-70.2255023},
{x:40.7726498,y:-73.00805546},
{x:38.02643875,y:-121.94162967},
{x:38.80408147,y:-90.70059053},
{x:33.94632706,y:-117.42487587},
{x:40.65068171,y:-83.59369665},
{x:37.6745469,y:-92.65546296},
{x:34.74149487,y:-97.26239298},
{x:41.48532906,y:-81.80523935},
{x:32.55351219,y:-84.94693011},
{x:40.73969086,y:-73.24642522},
{x:44.67052632,y:-93.63687432},
{x:35.06228589,y:-89.88636277},
{x:39.75821004,y:-74.25569494},
{x:41.521546,y:-83.716775},
{x:33.36333176,y:-111.91069889},
{x:35.79800585,y:-78.49411397},
{x:33.37778148,y:-111.95209476},
{x:37.10969948,y:-93.0582355},
{x:37.733035,y:-120.95338646},
{x:30.88413352,y:-87.78827458},
{x:30.31412535,y:-97.70404076},
{x:36.35681766,y:-94.18116917},
{x:33.13229517,y:-117.22877953},
{x:42.67365292,y:-85.6578856},
{x:33.7785036,y:-86.43059656},
{x:43.87632252,y:-92.48932869},
{x:34.42434141,y:-119.28832769},
{x:38.14955097,y:-90.57712287},
{x:32.71998347,y:-96.87846293},
{x:40.82649955,y:-76.84501737},
{x:40.74958157,y:-73.86936933},
{x:34.13927619,y:-80.9346119},
{x:26.70732096,y:-80.10828882},
{x:41.88150776,y:-83.39580192},
{x:36.24973229,y:-93.1180509},
{x:42.85722133,y:-77.01209452},
{x:34.14272518,y:-109.95631561},
{x:38.71535063,y:-121.39280209},
{x:39.06809679,y:-94.58356261},
{x:42.92410596,y:-72.2941024},
{x:33.96493023,y:-98.67163182},
{x:34.16599497,y:-118.35330886},
{x:34.03365235,y:-118.0919954},
{x:32.87860077,y:-96.71666671},
{x:36.04201382,y:-115.15102834},
{x:33.85622725,y:-117.5719804},
{x:32.53776933,y:-84.86787175},
{x:39.92784206,y:-122.19701488},
{x:39.42593286,y:-77.41629571},
{x:34.48770775,y:-87.27569624},
{x:42.9817012,y:-82.54047096},
{x:42.91404586,y:-85.71077257},
{x:40.737924,y:-73.981477},
{x:38.89206314,y:-77.01991272},
{x:40.74955078,y:-74.1694823},
{x:35.17304695,y:-89.86888216},
{x:37.70957881,y:-121.9261934},
{x:41.03065708,y:-81.42626399},
{x:34.25022229,y:-83.4612886},
{x:27.1212834,y:-80.2534723},
{x:32.90128586,y:-80.01802732},
{x:39.8212342,y:-84.28484909},
{x:29.69163524,y:-96.90218264},
{x:39.89688285,y:-83.81166917},
{x:32.59357327,y:-83.64075497},
{x:41.01593555,y:-81.60572372},
{x:26.76223746,y:-81.43645683},
{x:34.78253237,y:-86.93561722},
{x:40.89183087,y:-72.89975345},
{x:42.3242801,y:-83.40972432},
{x:43.83808283,y:-84.7689414},
{x:39.52464059,y:-122.21245095},
{x:30.76790632,y:-85.20436853},
{x:44.23134523,y:-123.20316994},
{x:41.20685241,y:-73.08794393},
{x:26.00820515,y:-80.35869525},
{x:39.03570243,y:-77.05521037},
{x:34.11173906,y:-118.18785004},
{x:42.672,y:-82.9476},
{x:35.512298,y:-93.822414},
{x:40.84166862,y:-74.10201431},
{x:38.93832829,y:-92.74339713},
{x:36.26370119,y:-80.36337225},
{x:33.26801699,y:-86.35387494},
{x:40.77544034,y:-74.0309831},
{x:39.92795618,y:-82.82947091},
{x:39.6404711,y:-84.22558484},
{x:45.03394635,y:-93.40599869},
{x:40.0152205,y:-105.1022017},
{x:33.26512406,y:-93.21872458},
{x:33.65166735,y:-86.81972261},
{x:28.67131757,y:-81.45585343},
{x:45.1826392,y:-93.2371654},
{x:35.8838711,y:-84.15580148},
{x:34.0233328,y:-84.52909385},
{x:38.81264798,y:-94.50050989},
{x:32.55325362,y:-83.69175633},
{x:42.23087868,y:-83.73982473},
{x:39.79976027,y:-84.12914295},
{x:35.81170499,y:-80.87526569},
{x:45.85709426,y:-84.73559445},
{x:34.90741891,y:-77.56415494},
{x:33.46595986,y:-112.50494081},
{x:37.81228018,y:-122.26960036},
{x:28.93077353,y:-81.66661659},
{x:34.97720391,y:-79.23735027},
{x:35.823178,y:-94.626173},
{x:37.82356093,y:-121.99298948},
{x:33.65980037,y:-117.29953364},
{x:36.51058257,y:-94.28137422},
{x:40.93157688,y:-75.09689047},
{x:36.23988493,y:-115.17305858},
{x:42.10708587,y:-71.02303317},
{x:26.5356542,y:-80.095741},
{x:39.73519313,y:-94.80148947},
{x:35.12842696,y:-107.82691244},
{x:41.31528453,y:-82.99361825},
{x:30.13883198,y:-81.77350938},
{x:42.67386848,y:-82.81583048},
{x:33.70225924,y:-117.93293678},
{x:29.81749324,y:-95.7651694},
{x:42.29175868,y:-83.38639006},
{x:40.14482592,y:-105.12669325},
{x:40.26288341,y:-80.17458288},
{x:32.7532362,y:-117.06435491},
{x:37.85173511,y:-122.03209475},
{x:41.14442618,y:-81.50894674},
{x:39.99338266,y:-83.00703318},
{x:32.5388066,y:-94.3487887},
{x:42.80626199,y:-71.56726956},
{x:36.41263614,y:-90.58597114},
{x:33.13893994,y:-88.55153248},
{x:44.95480526,y:-122.98414768},
{x:39.56313498,y:-84.26219701},
{x:33.63699275,y:-91.80198602},
{x:61.21124181,y:-149.732548},
{x:38.7477816,y:-90.28012194},
{x:25.98235824,y:-80.22661455},
{x:32.28699938,y:-110.96436956},
{x:40.8138059,y:-96.65687576},
{x:39.35449059,y:-94.76435155},
{x:42.29576711,y:-85.64876273},
{x:35.92157106,y:-83.86609912},
{x:28.81817458,y:-81.88791497},
{x:28.14473772,y:-82.74125576},
{x:40.8819,y:-81.5718},
{x:32.63864679,y:-85.4115466},
{x:39.19120006,y:-84.57092196},
{x:38.36497455,y:-75.54714163},
{x:33.41500995,y:-111.92582376},
{x:36.25018892,y:-87.0340171},
{x:36.41684383,y:-94.45275999},
{x:38.78941181,y:-104.75651179},
{x:40.8025356,y:-82.97599985},
{x:43.53822677,y:-96.73086331},
{x:39.93791467,y:-76.69414931},
{x:38.91287614,y:-104.78864466},
{x:42.94361483,y:-83.68997412},
{x:38.48522746,y:-90.3570503},
{x:35.10681425,y:-89.88462478},
{x:36.35063493,y:-79.69232626},
{x:39.78416697,y:-104.76842664},
{x:33.91736902,y:-118.09879728},
{x:34.21774695,y:-119.17768718},
{x:36.18399149,y:-86.29749469},
{x:30.22789489,y:-85.64869236},
{x:33.00633084,y:-97.01491353},
{x:33.13321873,y:-117.12139539},
{x:37.40826538,y:-122.0776239},
{x:33.1759719,y:-87.4465103},
{x:28.05528934,y:-81.98808345},
{x:27.71286546,y:-82.37083247},
{x:41.6776763,y:-72.6506515},
{x:35.65380748,y:-105.99498788},
{x:33.57822838,y:-111.88670312},
{x:35.09431388,y:-89.96989046},
{x:41.63208838,y:-83.55549082},
{x:39.64820095,y:-84.10982206},
{x:32.33439658,y:-106.78118129},
{x:42.9011463,y:-83.6311054},
{x:36.75687017,y:-90.40725201},
{x:42.96431496,y:-83.81020312},
{x:35.79088255,y:-80.85597678},
{x:38.06662767,y:-91.40421079},
{x:41.05602902,y:-80.61613083},
{x:36.41854456,y:-121.32176013},
{x:41.94284234,y:-85.6460889},
{x:27.82799746,y:-80.47840624},
{x:45.303192,y:-122.7630689},
{x:36.76402641,y:-108.15279581},
{x:45.13279751,y:-93.47865429},
{x:40.85399499,y:-81.76432944},
{x:39.90629687,y:-75.28869242},
{x:30.44944731,y:-88.84054843},
{x:45.72243416,y:-94.9530941},
{x:26.16439489,y:-80.20209967},
{x:38.77665921,y:-104.81372295},
{x:37.33581326,y:-121.89260208},
{x:37.49108945,y:-121.92717612},
{x:42.17983926,y:-71.87046111},
{x:45.75181712,y:-87.0787525},
{x:42.25422908,y:-84.42366466},
{x:33.4359781,y:-111.82318619},
{x:33.33482898,y:-111.80916897},
{x:35.14624677,y:-97.64319022},
{x:36.85722852,y:-121.4022711},
{x:34.77922322,y:-96.66959093},
{x:38.56152683,y:-121.33563598},
{x:38.78154294,y:-83.56437356},
{x:39.65204834,y:-105.34711655},
{x:43.03374098,y:-70.78120798},
{x:32.99023233,y:-96.75028618},
{x:36.02288525,y:-95.97556584},
{x:33.3918228,y:-112.08282496},
{x:36.34869338,y:-82.24180704},
{x:40.55541708,y:-84.40937898},
{x:25.69886034,y:-80.2956397},
{x:27.10638917,y:-82.43558616},
{x:34.03748239,y:-118.38836838},
{x:34.11753194,y:-117.7765691},
{x:41.93526254,y:-79.65341342},
{x:31.11761072,y:-97.4148004},
{x:34.17987899,y:-93.07126647},
{x:40.11272497,y:-76.45186259},
{x:30.18915077,y:-85.76022726},
{x:43.98882817,y:-92.46104033},
{x:43.22036484,y:-85.56394813},
{x:36.68587673,y:-121.79674298},
{x:45.52818093,y:-122.86695376},
{x:33.9828,y:-81.0973},
{x:29.02499468,y:-82.15455534},
{x:32.86063215,y:-97.28947565},
{x:36.5760441,y:-119.63014918},
{x:37.33939153,y:-121.97259365},
{x:38.21216949,y:-85.72046973},
{x:38.86281169,y:-121.95228782},
{x:43.40633294,y:-124.23268609},
{x:25.83079395,y:-80.26645504},
{x:40.5144282,y:-107.55609467},
{x:43.1995617,y:-77.94378503},
{x:43.10053573,y:-76.18619836},
{x:35.97798456,y:-94.29844174},
{x:44.05878801,y:-103.2317318},
{x:27.61064183,y:-99.47067131},
{x:39.96346867,y:-76.76920932},
{x:41.49192993,y:-82.01948665},
{x:29.936,y:-95.4583},
{x:36.17283814,y:-86.60242064},
{x:32.36500582,y:-86.14135493},
{x:39.88143325,y:-83.0676342},
{x:31.18106267,y:-85.40264727},
{x:41.29271231,y:-96.14230983},
{x:33.90111644,y:-118.34415324},
{x:33.5655392,y:-117.70818298},
{x:25.76093081,y:-80.36721574},
{x:33.48011951,y:-112.01478325},
{x:41.72334997,y:-83.51074814},
{x:40.54938508,y:-84.56183732},
{x:42.63805312,y:-83.13111208},
{x:45.80830964,y:-88.05044174},
{x:39.9322367,y:-77.64453228},
{x:40.18875424,y:-74.70406247},
{x:29.01154558,y:-95.41912276},
{x:34.76178153,y:-112.45306268},
{x:35.95996461,y:-84.98513952},
{x:25.84777175,y:-80.2899899},
{x:46.29022193,y:-96.11751604},
{x:27.97299824,y:-81.88415114},
{x:39.98649399,y:-104.81069572},
{x:38.37873379,y:-84.30949539},
{x:35.17103591,y:-80.84591702},
{x:35.72124851,y:-77.89299974},
{x:30.16110169,y:-81.63309769},
{x:39.88423441,y:-83.45084198},
{x:25.93858611,y:-80.24563275},
{x:36.20537016,y:-83.32359038},
{x:38.8870576,y:-76.89963461},
{x:38.9643091,y:-92.33436365},
{x:32.45908731,y:-111.21613737},
{x:39.39788477,y:-76.53805558},
{x:38.53778329,y:-91.00491395},
{x:28.35385261,y:-81.675767},
{x:41.25932563,y:-95.97073674},
{x:37.06206958,y:-94.51414798},
{x:33.19007109,y:-79.99742836},
{x:33.7609761,y:-96.58766547},
{x:42.68932958,y:-71.14899911},
{x:32.96624815,y:-97.04243214},
{x:44.4543729,y:-89.52214837},
{x:36.82030887,y:-83.31900777},
{x:36.70344307,y:-85.69074595},
{x:38.01986393,y:-84.41486264},
{x:33.43472877,y:-94.06020708},
{x:37.1492,y:-82.4667},
{x:37.11180453,y:-84.09375863},
{x:30.24492483,y:-89.76005991},
{x:32.85235082,y:-97.11222096},
{x:36.70365386,y:-77.54212841},
{x:42.26557141,y:-89.03840485},
{x:40.62530069,y:-89.46511656},
{x:31.06451862,y:-97.66417459},
{x:21.3387909,y:-158.07841569},
{x:38.95883522,y:-85.83895664},
{x:39.50889202,y:-87.38486023},
{x:46.9517931,y:-122.94052644},
{x:37.86235907,y:-87.57528055},
{x:41.70015937,y:-85.88924913},
{x:38.07413832,y:-83.95090014},
{x:40.30246061,y:-109.97801004},
{x:32.91866316,y:-96.95799588},
{x:29.81463317,y:-95.42906141},
{x:32.19622413,y:-96.90036609},
{x:39.90485585,y:-86.05884514},
{x:42.46140441,y:-71.02759604},
{x:37.03411153,y:-95.64271427},
{x:37.67858347,y:-97.19969809},
{x:30.152615,y:-95.216012},
{x:42.25298038,y:-87.90186483},
{x:39.08047189,y:-78.2140968},
{x:43.58416213,y:-72.96853602},
{x:39.82481002,y:-86.00940601},
{x:40.12161018,y:-111.65522803},
{x:39.39657124,y:-81.20069387},
{x:39.09909442,y:-84.48094445},
{x:33.73245285,y:-84.66789186},
{x:38.34050545,y:-85.81664711},
{x:34.87246852,y:-83.96559614},
{x:42.01981419,y:-88.20223598},
{x:38.1443,y:-85.8692},
{x:43.77257549,y:-91.21798843},
{x:31.75983886,y:-106.27079938},
{x:29.73639518,y:-95.56389715},
{x:40.02861912,y:-80.72894674},
{x:42.70635833,y:-71.20293818},
{x:41.94013745,y:-87.65235996},
{x:39.80599544,y:-85.77038797},
{x:45.61233194,y:-122.50470266},
{x:29.97004979,y:-95.69162565},
{x:40.38700629,y:-111.91746756},
{x:40.41308242,y:-111.77298483},
{x:43.08988212,y:-88.06679531},
{x:38.29361266,y:-77.52148485},
{x:32.47625266,y:-84.94885432},
{x:38.65268374,y:-87.15562219},
{x:42.37193922,y:-71.56155565},
{x:41.941372,y:-88.15278061},
{x:38.80022837,y:-77.32320536},
{x:30.21850875,y:-95.56755468},
{x:32.92569391,y:-96.84047518},
{x:41.90660523,y:-87.90644211},
{x:40.07439207,y:-86.9063207},
{x:31.82975163,y:-106.52802692},
{x:32.5493296,y:-92.61114389},
{x:39.19256373,y:-96.60670073},
{x:37.68940876,y:-87.90587479},
{x:39.30644682,y:-85.21696687},
{x:32.54961405,y:-82.97360971},
{x:47.64352265,y:-117.55784348},
{x:37.12332433,y:-113.61422543},
{x:30.23777612,y:-97.75250044},
{x:44.52389799,y:-89.5194788},
{x:41.6828946,y:-86.89345707},
{x:30.500131,y:-90.10903},
{x:47.19146228,y:-122.41005152},
{x:41.72149592,y:-87.66450755},
{x:34.10871459,y:-83.87817268},
{x:46.66403769,y:-120.52170394},
{x:38.70035284,y:-80.66355776},
{x:41.65619326,y:-85.70895101},
{x:30.35657921,y:-95.59300795},
{x:37.68168084,y:-97.13476275},
{x:39.75791958,y:-89.64613},
{x:34.65465243,y:-84.4934347},
{x:39.91213664,y:-86.13526069},
{x:41.45829129,y:-87.03443632},
{x:32.39794872,y:-93.75003223},
{x:39.98904848,y:-85.92072487},
{x:41.60042832,y:-87.69301687},
{x:30.04964922,y:-95.2307032},
{x:33.68348866,y:-96.60864622},
{x:29.4238405,y:-98.4851402},
{x:37.86868909,y:-82.54553457},
{x:36.79437982,y:-76.32821202},
{x:41.4927199,y:-87.5044488},
{x:42.894146,y:-73.203109},
{x:29.66460554,y:-95.19464929},
{x:39.76434805,y:-86.32401109},
{x:42.28371699,y:-71.64872208},
{x:38.34070946,y:-82.94238187},
{x:40.22208336,y:-111.65822357},
{x:36.95477478,y:-76.60190423},
{x:47.62435835,y:-122.35960473},
{x:33.88866329,y:-84.47492127},
{x:38.57528819,y:-77.31567015},
{x:36.76197418,y:-83.0148947},
{x:33.63425718,y:-84.29043754},
{x:44.9226481,y:-89.61357147},
{x:42.26645428,y:-88.99603039},
{x:29.79106517,y:-95.71860011},
{x:43.04753779,y:-89.32267233},
{x:40.79334466,y:-89.60609378},
{x:33.17546728,y:-96.83741464},
{x:39.76743103,y:-86.15898341},
{x:38.92122102,y:-77.50520521},
{x:42.31873792,y:-87.86658396},
{x:33.22471458,y:-96.97725802},
{x:46.40269799,y:-117.00159706},
{x:40.21855966,y:-85.40705635},
{x:40.17500686,y:-85.38200364},
{x:37.39062997,y:-76.52966443},
{x:30.24131448,y:-92.66402632},
{x:41.1483,y:-84.5804},
{x:47.65028744,y:-122.68987079},
{x:37.05834292,y:-76.42704606},
{x:30.10886011,y:-92.01053056},
{x:42.23441777,y:-88.3362177},
{x:42.0605647,y:-88.31586494},
{x:30.00243602,y:-90.14312087},
{x:30.40126137,y:-91.17640345},
{x:39.6013016,y:-110.82253224},
{x:43.12619738,y:-95.15020703},
{x:30.2381694,y:-97.72528974},
{x:42.13960831,y:-88.0152411},
{x:29.7319259,y:-95.48173659},
{x:38.17632694,y:-81.30437594},
{x:38.32861924,y:-81.59832231},
{x:45.47765908,y:-91.73469021},
{x:33.10253,y:-96.109375},
{x:38.40462905,y:-96.19775355},
{x:43.11641125,y:-88.05627815},
{x:42.69832805,y:-87.8533549},
{x:34.05976173,y:-84.59213783},
{x:37.11503262,y:-76.46820128},
{x:30.90345168,y:-84.555238},
{x:37.0207256,y:-76.44310174},
{x:31.09634515,y:-97.72136454},
{x:29.35668977,y:-98.54668964},
{x:37.1381,y:-85.9736},
{x:39.62762847,y:-85.44540932},
{x:38.8866206,y:-77.44445045},
{x:33.88728838,y:-98.52826555},
{x:43.60181746,y:-116.59833438},
{x:41.83431606,y:-87.90027787},
{x:33.29305922,y:-96.98491618},
{x:47.0704,y:-122.417},
{x:29.92911653,y:-95.20454641},
{x:39.8154064,y:-85.9150738},
{x:39.76444848,y:-89.71098331},
{x:40.52863789,y:-111.85510636},
{x:37.46505754,y:-77.4433712},
{x:41.56838674,y:-87.5085399},
{x:46.57065794,y:-120.48816019},
{x:29.54345533,y:-95.22474996},
{x:42.25657483,y:-72.24929858},
{x:29.59274829,y:-98.35379946},
{x:47.40975435,y:-120.29004827},
{x:30.5868319,y:-97.41208694},
{x:47.73407176,y:-122.29211406},
{x:30.57820385,y:-91.12675308},
{x:43.47947367,y:-88.8206157},
{x:41.78094868,y:-88.3173731},
{x:26.18711301,y:-97.72172324},
{x:38.75975593,y:-77.08458249},
{x:41.66190595,y:-70.28545757},
{x:31.83746858,y:-106.56158058},
{x:41.64615095,y:-85.93530764},
{x:39.05918976,y:-77.44962309},
{x:40.43110588,y:-111.88511403},
{x:41.56892252,y:-87.55837966},
{x:38.64653321,y:-77.33716784},
{x:20.879,y:-156.445},
{x:32.99729065,y:-96.82746812},
{x:47.67161748,y:-117.10464397},
{x:41.43620959,y:-87.36516684},
{x:30.38363735,y:-91.06593124},
{x:38.31365231,y:-88.94309163},
{x:41.72008476,y:-85.97214644},
{x:43.49782893,y:-116.39446354},
{x:38.84280993,y:-77.1081715},
{x:36.84834574,y:-76.18144036},
{x:43.86302167,y:-116.49920508},
{x:37.27971115,y:-82.10286967},
{x:43.78467539,y:-88.46332759},
{x:42.94187268,y:-88.86326371},
{x:42.08698485,y:-87.79771288},
{x:29.6160959,y:-95.4970733},
{x:47.35790977,y:-122.10939817},
{x:41.6262787,y:-85.90728003},
{x:41.89691081,y:-87.62735073},
{x:32.87576721,y:-96.76151553},
{x:41.68929222,y:-87.80838311},
{x:31.07255302,y:-97.36907689},
{x:42.09471779,y:-87.95317505},
{x:36.79324057,y:-76.08183463},
{x:47.99816151,y:-122.10805786},
{x:43.05037164,y:-89.47415084},
{x:41.52357571,y:-87.85058346},
{x:41.71017816,y:-88.0682715},
{x:36.9066,y:-82.3172},
{x:47.51963527,y:-122.26467043},
{x:41.96469583,y:-87.98897852},
{x:38.45140622,y:-85.67463485},
{x:29.86033409,y:-89.99391399},
{x:46.97758643,y:-123.80351731},
{x:33.58651042,y:-85.05791187},
{x:40.52255675,y:-111.94543123},
{x:43.58965977,y:-88.43417013},
{x:41.65298202,y:-85.0000783},
{x:30.25570087,y:-93.18094164},
{x:30.36448796,y:-90.06277129},
{x:41.07276026,y:-111.97950387},
{x:40.56817463,y:-85.67534794},
{x:32.70998427,y:-97.60356911},
{x:41.59482626,y:-87.48036556},
{x:41.99202762,y:-87.86293916},
{x:47.09812786,y:-122.29511372},
{x:39.70935573,y:-86.1437498},
{x:41.99994735,y:-87.66104189},
{x:42.63502217,y:-71.35299007},
{x:30.21915267,y:-93.37584436},
{x:29.58784704,y:-95.64774344},
{x:39.41830411,y:-87.69666208},
{x:38.91557941,y:-77.23702518},
{x:32.79259968,y:-96.70043148},
{x:48.05431114,y:-122.176525},
{x:31.36221535,y:-92.41157189},
{x:41.52037977,y:-109.46522255},
{x:37.12646504,y:-113.52751136},
{x:38.25552749,y:-78.11489473},
{x:44.07601697,y:-87.70081808},
{x:39.13853572,y:-86.53085254},
{x:41.92584622,y:-87.64039197},
{x:39.87127972,y:-85.95753281},
{x:37.69975917,y:-85.86694579},
{x:42.1776572,y:-88.3347095},
{x:32.5447941,y:-93.77705187},
{x:39.03217836,y:-77.42365479},
{x:41.67247706,y:-86.3202855},
{x:45.70888577,y:-122.55328348},
{x:37.27489555,y:-97.38358877},
{x:38.17946219,y:-84.81419925},
{x:29.68100192,y:-90.78244619},
{x:37.76405319,y:-89.33030257},
{x:42.98450633,y:-87.94788842},
{x:41.7181626,y:-86.25092357},
{x:41.18441802,y:-111.94870166},
{x:39.75306309,y:-86.25065267},
{x:42.2698908,y:-88.8572647},
{x:47.57193679,y:-122.66019166},
{x:36.90594686,y:-76.15080495},
{x:34.22149797,y:-84.50486449},
{x:39.77884682,y:-86.28846361},
{x:44.20509879,y:-88.44643866},
{x:47.747615,y:-117.403036},
{x:43.59887695,y:-116.39297251},
{x:29.67398933,y:-95.56467309},
{x:40.53439524,y:-112.01527957},
{x:31.8870565,y:-106.5788756},
{x:40.83539869,y:-84.93818506},
{x:43.79711939,y:-90.05217433},
{x:31.80660053,y:-106.2632174},
{x:41.93602902,y:-89.0535096},
{x:39.47232527,y:-87.37151183},
{x:30.61693798,y:-96.31626119},
{x:42.22832156,y:-89.07185966},
{x:37.4097321,y:-77.65383259},
{x:38.86154374,y:-77.38936459},
{x:46.82591648,y:-119.14831907},
{x:30.00617815,y:-90.03547416},
{x:29.88824985,y:-90.05694218},
{x:38.72302568,y:-77.12136649},
{x:36.87904983,y:-76.42300663},
{x:37.66426395,y:-97.29217717},
{x:38.85447723,y:-94.7618201},
{x:32.96180662,y:-96.7362826},
{x:40.55808522,y:-111.8904153},
{x:47.2533694,y:-122.2942162},
{x:37.72906972,y:-81.24218178},
{x:41.52904882,y:-87.47169574},
{x:37.93598339,y:-87.87935905},
{x:42.34176821,y:-88.03273474},
{x:37.24691164,y:-76.65769726},
{x:40.44714906,y:-84.97772972},
{x:31.96338389,y:-95.26986574},
{x:32.90978653,y:-96.76763177},
{x:41.57781299,y:-109.25149522},
{x:37.6664751,y:-82.27528638},
{x:29.64238929,y:-95.24028532},
{x:39.64776395,y:-79.90142137},
{x:41.8174,y:-88.2795},
{x:34.02941119,y:-117.11599006},
{x:32.89505434,y:-96.47031252},
{x:37.62076381,y:-84.7741393},
{x:37.3631807,y:-81.06013334},
{x:37.55318957,y:-97.27168595},
{x:33.6546644,y:-96.60678132},
{x:42.2760778,y:-88.04039075},
{x:41.64055791,y:-70.99153538},
{x:40.3963552,y:-86.86729043},
{x:47.71653824,y:-117.43582943},
{x:42.26664086,y:-71.01486996},
{x:30.56069546,y:-97.67951166},
{x:30.15947452,y:-92.04476681},
{x:41.69089177,y:-86.29376476},
{x:37.86071742,y:-112.82911109},
{x:30.62180422,y:-96.34224571},
{x:29.49892889,y:-98.31813996},
{x:38.187862,y:-99.098035},
{x:40.71810332,y:-111.93942194},
{x:32.80261877,y:-97.26421955},
{x:38.3898777,y:-85.76590334},
{x:39.80032567,y:-86.26901321},
{x:42.12063187,y:-72.58387222},
{x:20.88868041,y:-156.46507202},
{x:41.23717298,y:-85.81647237},
{x:41.24533786,y:-112.0192345},
{x:38.97063379,y:-94.72790114},
{x:30.3591749,y:-89.74913147},
{x:42.17319228,y:-70.88152796},
{x:30.21292965,y:-97.82951523},
{x:44.49259565,y:-88.09692265},
{x:37.05203257,y:-76.46092102},
{x:41.37861391,y:-88.42254728},
{x:41.64277138,y:-87.73733675},
{x:41.79921643,y:-87.59249695},
{x:30.06688754,y:-95.24682603},
{x:40.77585562,y:-87.70215585},
{x:41.78832825,y:-87.86825189},
{x:46.93389743,y:-122.59107413},
{x:41.12274815,y:-112.02555437},
{x:42.32848585,y:-72.63313343},
{x:40.24768493,y:-111.64681577},
{x:41.19053404,y:-87.1989481},
{x:33.42989815,y:-84.17856936},
{x:46.14413541,y:-122.90087966},
{x:38.65122801,y:-77.29950942},
{x:37.68640216,y:-77.46282211},
{x:40.58227335,y:-111.87265811},
{x:38.77185418,y:-77.1365118},
{x:38.44217385,y:-82.01999098},
{x:32.98802847,y:-93.46084086},
{x:27.50742377,y:-97.85629589},
{x:41.2779778,y:-85.85350469},
{x:38.80414927,y:-81.36487022},
{x:41.90913664,y:-87.73982577},
{x:40.2198493,y:-79.6100419},
{x:38.31226958,y:-77.47672971},
{x:32.03666123,y:-102.13087499},
{x:38.79472087,y:-77.27086917},
{x:47.16073153,y:-122.43381088},
{x:42.00759913,y:-88.04531604},
{x:44.45759948,y:-87.99002274},
{x:43.57433603,y:-89.7793211},
{x:47.82145382,y:-122.30010029},
{x:39.11230395,y:-84.85182031},
{x:39.18696537,y:-81.52699023},
{x:29.90445364,y:-95.55453288},
{x:37.1351312,y:-83.76812448},
{x:38.66072264,y:-78.49588072},
{x:33.23275582,y:-97.59435028},
{x:39.79208183,y:-88.26936562},
{x:32.75424316,y:-97.33934462},
{x:39.35526667,y:-85.4802812},
{x:41.75156985,y:-88.01210204},
{x:36.82690637,y:-76.33847878},
{x:40.97428793,y:-91.53954847},
{x:29.70897979,y:-96.50200115},
{x:40.45912779,y:-90.67748504},
{x:36.81857122,y:-76.2169873},
{x:40.50210079,y:-86.86777555},
{x:41.55551672,y:-89.07022174},
{x:21.3348,y:-158.051},
{x:47.62800584,y:-117.36691117},
{x:37.15064652,y:-80.58244757},
{x:32.4823115,y:-93.75346182},
{x:41.75811477,y:-70.66058461},
{x:41.57787387,y:-88.1601276},
{x:47.27193904,y:-122.51521517},
{x:42.32219068,y:-88.17038178},
{x:36.87921296,y:-87.73587227},
{x:38.93187497,y:-79.86756201},
{x:42.85826704,y:-112.43439159},
{x:38.08456633,y:-78.47159205},
{x:38.44643663,y:-90.21947048},
{x:37.04270612,y:-76.38126884},
{x:42.58003178,y:-72.18353306},
{x:42.89300742,y:-112.45172024},
{x:42.50645569,y:-90.72091971},
{x:41.7024623,y:-93.5881253},
{x:47.69577831,y:-117.36563221},
{x:38.19622297,y:-85.26890457},
{x:30.32246083,y:-92.03198717},
{x:38.86139858,y:-86.51417796},
{x:29.97625987,y:-90.29316269},
{x:32.52812428,y:-93.70109171},
{x:32.04206596,y:-81.0639735},
{x:45.81521068,y:-122.70313388},
{x:39.4062168,y:-88.80483876},
{x:39.1290181,y:-94.82332014},
{x:47.78756701,y:-122.21865789},
{x:38.57291011,y:-89.93160109},
{x:38.18814579,y:-85.51800872},
{x:41.55041772,y:-87.42604627},
{x:38.3441,y:-77.7441},
{x:42.554033,y:-71.571141},
{x:42.992149,y:-88.04881506},
{x:39.71353403,y:-86.23883137},
{x:39.16449999,y:-86.49417405},
{x:37.84813569,y:-82.0001024},
{x:39.0188286,y:-80.4203508},
{x:41.5510703,y:-87.23690145},
{x:39.01533146,y:-94.72575338},
{x:41.12139124,y:-85.13601371},
{x:41.7186017,y:-87.74108271},
{x:37.65033489,y:-97.35217925},
{x:47.11549485,y:-122.4343408},
{x:38.88416564,y:-94.77632784},
{x:38.95896137,y:-77.40294046},
{x:31.79406859,y:-106.35687164},
{x:39.71073107,y:-86.0831681},
{x:40.03092929,y:-111.75378263},
{x:40.2853,y:-87.2344},
{x:28.9051,y:-81.245},
{x:32.7621896,y:-97.48283942},
{x:37.14941666,y:-84.11664346},
{x:22.06413411,y:-159.31989406},
{x:33.60521145,y:-83.8748718},
{x:30.00356868,y:-90.16991081},
{x:41.02704276,y:-85.16806946},
{x:32.77868428,y:-91.89211229},
{x:37.6311294,y:-97.37145491},
{x:40.50301477,y:-86.86901748},
{x:41.73757343,y:-86.33617569},
{x:41.49480954,y:-90.54737906},
{x:38.30780264,y:-85.84513539},
{x:30.4077514,y:-97.85104839},
{x:39.75843301,y:-86.50967279},
{x:43.09676822,y:-87.98567176},
{x:37.75361973,y:-100.02382558},
{x:37.75330172,y:-84.8456008},
{x:45.65346742,y:-122.58807346},
{x:21.38818877,y:-157.95595339},
{x:37.93696649,y:-95.40970807},
{x:29.84946894,y:-95.21565532},
{x:39.84879636,y:-85.26209149},
{x:40.46737718,y:-85.36958992},
{x:30.00607073,y:-90.22465645},
{x:37.06146135,y:-85.07143034},
{x:42.08604003,y:-72.56017319},
{x:47.43420735,y:-122.29508213},
{x:38.30133207,y:-88.9027083},
{x:36.91871426,y:-84.12613287},
{x:29.89223593,y:-90.40849369},
{x:39.67762537,y:-86.37047996},
{x:30.07432811,y:-95.62661483},
{x:47.92485744,y:-122.0982255},
{x:30.48026396,y:-90.74778605},
{x:43.59040385,y:-116.26969112},
{x:42.37929639,y:-88.09075186},
{x:38.95233486,y:-81.76456011},
{x:32.5992321,y:-93.83919328},
{x:39.21132909,y:-86.58606896},
{x:37.23991656,y:-81.25420687},
{x:40.70530261,y:-89.44505632},
{x:37.10746774,y:-76.49495691},
{x:29.68417523,y:-95.15591397},
{x:41.56356853,y:-85.79892861},
{x:32.95403881,y:-96.88896031},
{x:30.494676,y:-92.438882},
{x:37.73118258,y:-88.9360925},
{x:47.46776546,y:-122.28708405},
{x:37.69379789,y:-81.65234342},
{x:38.21621734,y:-85.53822041},
{x:41.95191518,y:-71.07062496},
{x:39.53001993,y:-87.100005},
{x:42.57156889,y:-87.88695686},
{x:48.15192178,y:-122.19325233},
{x:39.3095266,y:-85.96546678},
{x:33.89149409,y:-84.75320756},
{x:30.28413613,y:-89.75109637},
{x:44.37877831,y:-89.81782547},
{x:37.50716622,y:-77.58602589},
{x:39.02106335,y:-94.69070077},
{x:34.27399938,y:-84.80771489},
{x:39.79695285,y:-86.0126883},
{x:38.2955921,y:-85.55724516},
{x:37.72405205,y:-97.24410453},
{x:33.06791693,y:-97.08108468},
{x:40.8731057,y:-88.65745166},
{x:41.576227,y:-88.165562},
{x:42.45982063,y:-87.82470167},
{x:42.52694105,y:-88.9797095},
{x:33.5471031,y:-101.95773145},
{x:38.27054822,y:-85.61421574},
{x:47.8632948,y:-122.28221123},
{x:38.61144201,y:-90.0480391},
{x:42.7009342,y:-92.47500213},
{x:42.11807762,y:-88.25845067},
{x:43.72236651,y:-87.73689607},
{x:38.68984275,y:-87.50482236},
{x:29.50985876,y:-98.38696717},
{x:39.3981,y:-79.0114},
{x:37.46232351,y:-89.23027039},
{x:41.55276725,y:-90.48188567},
{x:44.17426322,y:-88.49348632},
{x:38.04739185,y:-78.5045859},
{x:41.9086991,y:-87.84426664},
{x:30.48656933,y:-97.77010471},
{x:42.30078739,y:-71.38378067},
{x:39.2679,y:-81.5265},
{x:32.55630156,y:-83.8854605},
{x:30.01628826,y:-90.23936056},
{x:43.00493867,y:-87.96823398},
{x:42.93125927,y:-87.94868247},
{x:41.42068209,y:-87.24237934},
{x:32.88106695,y:-97.10084524},
{x:38.92971413,y:-84.54335957},
{x:39.036467,y:-84.53178414},
{x:32.8620729,y:-96.64417362},
{x:37.74087314,y:-83.04458852},
{x:39.81831557,y:-88.87881763},
{x:38.335372,y:-82.45520384},
{x:41.59545871,y:-93.73669267},
{x:42.50358313,y:-70.92246077},
{x:42.58573377,y:-72.62622942},
{x:41.30974331,y:-105.56663297},
{x:33.14437161,y:-97.1059039},
{x:42.88174193,y:-72.55794554},
{x:39.01478777,y:-95.68129782},
{x:36.82354085,y:-76.06761315},
{x:47.25504339,y:-122.47391151},
{x:40.7090743,y:-89.59449828},
{x:40.2394194,y:-111.64353527},
{x:38.92712951,y:-78.19442536},
{x:41.4524368,y:-91.029484},
{x:37.75876217,y:-77.46692776},
{x:29.39953499,y:-95.03795964},
{x:41.86447803,y:-87.81137064},
{x:38.21235228,y:-84.53897677},
{x:36.87756316,y:-76.04054653},
{x:39.0023008,y:-78.34260031},
{x:30.5000542,y:-91.15248486},
{x:29.76095225,y:-94.97700504},
{x:42.71772422,y:-88.98999736},
{x:41.8835659,y:-87.8052981},
{x:42.56858871,y:-88.11290802},
{x:43.65016512,y:-116.66791801},
{x:38.43485556,y:-82.13119952},
{x:31.68416804,y:-96.47444401},
{x:41.80925172,y:-87.67527252},
{x:41.1378788,y:-112.06496976},
{x:29.70132268,y:-95.64339346},
{x:45.15056589,y:-90.33374119},
{x:37.18521132,y:-77.36870952},
{x:36.6158215,y:-88.31460044},
{x:42.16632911,y:-72.48223503},
{x:46.62087184,y:-120.5615547},
{x:46.59472153,y:-120.60483925},
{x:48.93754777,y:-122.48602126},
{x:41.84800382,y:-87.6858773},
{x:33.9579088,y:-84.13447953},
{x:46.5850201,y:-120.53072622},
{x:41.05201242,y:-86.21896751},
{x:29.229098,y:-98.789475},
{x:43.62576706,y:-89.78755764},
{x:40.04738956,y:-86.02750741},
{x:40.5873556,y:-111.93753704},
{x:41.9507408,y:-71.273623},
{x:46.68071273,y:-122.97519422},
{x:39.05264323,y:-84.57606752},
{x:42.38568451,y:-87.95920425},
{x:37.50289198,y:-77.51989663},
{x:41.92851937,y:-87.67331014},
{x:42.09653208,y:-72.60194198},
{x:30.90697157,y:-83.26368396},
{x:39.65578458,y:-87.42863016},
{x:48.07708555,y:-123.08886878},
{x:36.65772991,y:-76.22596831},
{x:44.44631891,y:-72.01624487},
{x:37.77653433,y:-79.96761414},
{x:41.29588399,y:-92.65249159},
{x:32.89135787,y:-97.54111112},
{x:32.69276917,y:-97.02331972},
{x:41.65692278,y:-88.00165498},
{x:41.64715463,y:-88.59959266},
{x:43.19213844,y:-88.13022057},
{x:38.42078844,y:-78.89384406},
{x:38.87091308,y:-78.52439251},
{x:36.84207596,y:-87.4841322},
{x:39.116658,y:-94.938161},
{x:30.71361904,y:-94.95498389},
{x:41.5557443,y:-88.12642562},
{x:29.39498667,y:-94.94898267},
{x:38.4567221,y:-81.49957058},
{x:39.42279288,y:-87.41551727},
{x:41.66182218,y:-86.13389634},
{x:41.24399589,y:-111.97098523},
{x:30.35389075,y:-91.10766013},
{x:32.47495029,y:-99.73072462},
{x:43.93953058,y:-90.82109836},
{x:37.22603379,y:-95.70843373},
{x:42.0099505,y:-88.14510753},
{x:30.71005764,y:-95.55086508},
{x:38.59210202,y:-89.95108207},
{x:31.73600899,y:-95.60693264},
{x:43.0152573,y:-89.4794498},
{x:35.17874202,y:-101.84826125},
{x:33.04502845,y:-97.0140846},
{x:32.94118705,y:-97.11557535},
{x:47.38720436,y:-122.20177987},
{x:42.9375265,y:-83.31415936},
{x:40.45380303,y:-86.91710651},
{x:30.0491338,y:-95.43046757},
{x:30.48153955,y:-97.83128856},
{x:29.8855973,y:-97.94146299},
{x:35.16119653,y:-101.90007855},
{x:34.07614564,y:-84.67629319},
{x:39.28783002,y:-94.90550809},
{x:38.99323533,y:-80.23274786},
{x:29.0480835,y:-95.46491087},
{x:30.5187905,y:-97.65406613},
{x:39.0866998,y:-84.51846854},
{x:39.28018402,y:-80.28011937},
{x:47.88228682,y:-122.24091142},
{x:44.53072049,y:-89.57436023},
{x:39.22265283,y:-85.91242124},
{x:39.02184703,y:-84.58602633},
{x:30.50054992,y:-90.48331127},
{x:41.91436147,y:-71.36071591},
{x:37.89842837,y:-84.5659376},
{x:37.5909264,y:-77.505337},
{x:38.8966933,y:-77.12869181},
{x:37.97917739,y:-100.84140489},
{x:36.69690158,y:-79.87423986},
{x:43.05996964,y:-89.52436922},
{x:32.76836823,y:-96.8954361},
{x:30.43906788,y:-90.4558297},
{x:38.97350109,y:-95.24973294},
{x:38.3125235,y:-85.80661319},
{x:37.03977993,y:-76.40004033},
{x:32.52343211,y:-92.64165053},
{x:43.13529568,y:-115.66627403},
{x:39.28682137,y:-86.75976685},
{x:37.7986,y:-85.916},
{x:33.45706595,y:-94.08901157},
{x:32.53088908,y:-97.33776172},
{x:33.82720921,y:-84.32565768},
{x:42.0274226,y:-91.61907877},
{x:38.28929787,y:-85.72908868},
{x:39.06502968,y:-84.7041733},
{x:41.62219612,y:-87.58490333},
{x:39.07766604,y:-84.89038236},
{x:41.13880019,y:-104.78724454},
{x:30.28538215,y:-91.90717665},
{x:41.01510294,y:-85.11656185},
{x:36.7389929,y:-84.47241614},
{x:43.82635869,y:-111.78453778},
{x:37.6540104,y:-113.088249},
{x:39.45728788,y:-95.74859668},
{x:40.01644715,y:-86.90431434},
{x:29.698243,y:-95.29757776},
{x:39.01723667,y:-84.50858096},
{x:40.77032023,y:-89.65710543},
{x:31.05579267,y:-93.24487303},
{x:42.48978756,y:-96.36675377},
{x:41.1757205,y:-85.16652151},
{x:42.1393063,y:-87.9598111},
{x:38.24538055,y:-85.75267375},
{x:30.44531294,y:-90.08161519},
{x:42.04681037,y:-88.25406895},
{x:39.94864099,y:-86.34782106},
{x:42.35004474,y:-71.13056314},
{x:33.25664234,y:-97.10876181},
{x:30.23372693,y:-92.38402672},
{x:30.30113395,y:-93.20206404},
{x:42.98384241,y:-87.91389645},
{x:39.69212092,y:-86.18835282},
{x:41.82543206,y:-87.7970132},
{x:29.77573284,y:-95.38192559},
{x:37.52597575,y:-82.57294754},
{x:32.51637525,y:-92.19049923},
{x:39.69352427,y:-86.14933316},
{x:36.91491269,y:-76.21280381},
{x:39.93054406,y:-86.23112067},
{x:36.95873786,y:-86.47001683},
{x:40.68991211,y:-112.26291663},
{x:39.65125331,y:-79.96991386},
{x:36.83955158,y:-81.50777757},
{x:32.98928077,y:-96.85428549},
{x:38.99264021,y:-94.70314488},
{x:32.92991912,y:-97.08503962},
{x:31.45702167,y:-83.52841317},
{x:29.88678264,y:-95.64334857},
{x:38.41746742,y:-82.46025661},
{x:36.75342157,y:-76.24678016},
{x:43.09286857,y:-88.00764165},
{x:45.07953732,y:-87.66457875},
{x:40.89860962,y:-85.51213163},
{x:37.60908664,y:-77.35534304},
{x:43.31802795,y:-88.35198909},
{x:37.83629747,y:-85.9358592},
{x:41.0253754,y:-93.7968284},
{x:36.87077202,y:-87.49937646},
{x:36.85044189,y:-86.88942249},
{x:32.20461977,y:-98.23798217},
{x:37.29652959,y:-76.72660068},
{x:46.29996076,y:-119.27468866},
{x:38.73785041,y:-77.5297238},
{x:36.85467478,y:-84.83837366},
{x:39.71342708,y:-89.63630282},
{x:30.16602316,y:-95.50584837},
{x:47.0622263,y:-122.76396739},
{x:37.32612858,y:-87.48085508},
{x:41.9322613,y:-87.94010431},
{x:32.44565457,y:-93.86297911},
{x:38.00671369,y:-84.51886587},
{x:37.43397788,y:-79.12293579},
{x:41.45187846,y:-85.58844633},
{x:39.23149221,y:-81.54169571},
{x:41.6081214,y:-93.56979115},
{x:41.16022699,y:-104.52067276},
{x:29.21030436,y:-96.28144652},
{x:38.90999264,y:-94.76036847},
{x:38.4739126,y:-77.41744187},
{x:32.23083,y:-101.47046462},
{x:41.80791992,y:-87.74351909},
{x:29.56471784,y:-98.58778768},
{x:30.06967195,y:-90.48493132},
{x:33.72820549,y:-84.57252378},
{x:37.08529404,y:-76.49831836},
{x:32.5504453,y:-93.6310587},
{x:42.58877215,y:-87.85778859},
{x:41.1587499,y:-87.88639878},
{x:38.92304397,y:-90.18021211},
{x:39.76359153,y:-86.37957747},
{x:42.50503299,y:-94.15317763},
{x:31.74886301,y:-93.08193252},
{x:38.89274801,y:-77.42630872},
{x:37.09298802,y:-76.39509156},
{x:29.96033751,y:-90.18338531},
{x:37.08307476,y:-97.04059191},
{x:38.37149973,y:-97.62719987},
{x:38.5499107,y:-90.03655583},
{x:30.21481289,y:-92.02188765},
{x:37.09220928,y:-81.77993307},
{x:39.18954248,y:-84.46279247},
{x:41.24933685,y:-87.84809211},
{x:41.40736591,y:-85.74501501},
{x:33.85736099,y:-98.5367011},
{x:42.02344867,y:-87.89637027},
{x:33.01079342,y:-96.96831985},
{x:38.2004551,y:-85.8353783},
{x:39.02292836,y:-77.47962534},
{x:42.15411207,y:-71.2048688},
{x:41.82699653,y:-86.35243878},
{x:32.930448,y:-96.379349},
{x:39.71248546,y:-86.00871742},
{x:37.36335696,y:-78.84579241},
{x:39.91015594,y:-86.26229614},
{x:30.18253061,y:-97.8466469},
{x:45.92695148,y:-122.75907127},
{x:38.56203656,y:-86.61446041},
{x:36.974121,y:-82.593742},
{x:42.17936108,y:-71.18951117},
{x:30.4854,y:-91.0839},
{x:38.90187788,y:-90.06056884},
{x:42.32026789,y:-87.84073616},
{x:41.57139957,y:-90.52868217},
{x:47.66834608,y:-117.42613329},
{x:40.72270163,y:-111.85421754},
{x:32.82501351,y:-96.87270194},
{x:39.43886763,y:-87.33346067},
{x:42.83628015,y:-88.76593901},
{x:41.66128891,y:-87.66125119},
{x:39.78007337,y:-86.1736974},
{x:38.88903339,y:-90.17642028},
{x:32.8712325,y:-97.31491096},
{x:37.99019752,y:-85.70657462},
{x:32.43643813,y:-81.75874777},
{x:21.30114554,y:-157.84274323},
{x:21.38067987,y:-158.14419252},
{x:37.39777375,y:-94.70450071},
{x:37.245098,y:-77.39069885},
{x:46.97922203,y:-120.54320184},
{x:46.42015108,y:-117.0434309},
{x:37.15763115,y:-76.46205023},
{x:40.27906745,y:-111.71609369},
{x:39.85468881,y:-86.39402495},
{x:42.0100535,y:-88.07962418},
{x:30.48877409,y:-91.12073256},
{x:41.26165503,y:-95.88858312},
{x:33.01737906,y:-96.68196622},
{x:38.5022901,y:-82.67898537},
{x:41.74796774,y:-93.60074189},
{x:39.85505652,y:-86.24080654},
{x:43.57078815,y:-89.47056757},
{x:32.75645736,y:-97.71345873},
{x:43.07507557,y:-89.39426372},
{x:39.66642119,y:-86.07798506},
{x:29.775533,y:-95.314876},
{x:37.34235526,y:-76.73904219},
{x:41.10115565,y:-111.94409891},
{x:38.6771584,y:-77.27848441},
{x:44.68036433,y:-90.16464584},
{x:42.48248921,y:-88.30554557},
{x:36.84052931,y:-76.10026933},
{x:41.60073396,y:-87.84643828},
{x:41.92834675,y:-87.8643997},
{x:32.33357617,y:-96.6160356},
{x:21.28031954,y:-157.81392171},
{x:36.84737174,y:-76.02069864},
{x:46.14670571,y:-122.927523},
{x:30.52001282,y:-97.82912337},
{x:37.56753968,y:-84.31270838},
{x:41.53129057,y:-93.59629845},
{x:38.74893334,y:-89.67584968},
{x:42.4344812,y:-96.36895316},
{x:32.6353081,y:-97.36656234},
{x:33.01461958,y:-97.07279433},
{x:42.9295702,y:-89.2498076},
{x:46.80200978,y:-123.01137629},
{x:41.53795429,y:-90.59577495},
{x:39.431341,y:-80.186245},
{x:31.76551202,y:-106.36307433},
{x:39.64552449,y:-86.3295228},
{x:30.4087612,y:-91.01501092},
{x:41.687284,y:-86.06096524},
{x:29.68340163,y:-98.12537132},
{x:38.95676829,y:-84.62919295},
{x:32.54035483,y:-94.74654264},
{x:46.31984146,y:-119.98727754},
{x:36.85359847,y:-83.86524624},
{x:32.90846247,y:-96.56886667},
{x:37.48850621,y:-97.2451795},
{x:47.43990534,y:-120.3251528},
{x:33.69395713,y:-85.15291263},
{x:30.03116219,y:-95.55608995},
{x:37.32342574,y:-84.92525537},
{x:38.17402598,y:-85.76796702},
{x:29.98058145,y:-95.21847367},
{x:41.77801993,y:-88.28223516},
{x:29.58992563,y:-90.7431803},
{x:47.15267936,y:-122.35758215},
{x:32.79685304,y:-97.34916781},
{x:30.46845086,y:-90.91770903},
{x:37.697919,y:-85.221932},
{x:47.98092385,y:-122.20114446},
{x:40.14430842,y:-85.67642216},
{x:39.87651629,y:-88.94797675},
{x:36.9157552,y:-76.26835473},
{x:37.04445834,y:-86.21011389},
{x:38.158411,y:-85.660626},
{x:36.95566106,y:-76.25349467},
{x:38.42215379,y:-86.93751101},
{x:31.52761201,y:-97.16556288},
{x:32.68719484,y:-96.82624383},
{x:42.85274601,y:-88.32411826},
{x:38.25774492,y:-85.75384703},
{x:41.86674715,y:-87.71031632},
{x:29.5084408,y:-98.49968027},
{x:32.14957999,y:-94.78781187},
{x:47.00026844,y:-122.91004322},
{x:38.76243283,y:-77.45906621},
{x:38.79246807,y:-77.61045277},
{x:32.6227905,y:-93.7135207},
{x:33.40810977,y:-84.67218795},
{x:37.38517569,y:-79.90257341},
{x:38.291483,y:-77.563097},
{x:29.63279722,y:-90.75901419},
{x:31.54998776,y:-97.12514971},
{x:30.0119,y:-97.8904},
{x:48.46074424,y:-122.33527556},
{x:40.91388511,y:-89.48952238},
{x:41.9389098,y:-87.7598273},
{x:42.29698063,y:-87.95606554},
{x:42.77835047,y:-96.18767568},
{x:37.77660654,y:-79.43273753},
{x:43.75189095,y:-87.75026996},
{x:37.99118491,y:-87.53874291},
{x:42.34981249,y:-87.87064679},
{x:37.74936494,y:-81.13588444},
{x:32.94573824,y:-96.62023057},
{x:29.92200822,y:-95.49748783},
{x:34.16462345,y:-84.11864787},
{x:38.82169924,y:-81.7224444},
{x:41.24169116,y:-95.8261087},
{x:37.72235327,y:-97.36191367},
{x:38.31663052,y:-85.89721733},
{x:29.57293208,y:-95.57059153},
{x:46.17879163,y:-119.18799293},
{x:40.36408048,y:-111.78498399},
{x:44.52266268,y:-88.06216393},
{x:39.66025911,y:-85.12884886},
{x:47.7009993,y:-116.78833991},
{x:37.23304572,y:-80.00730359},
{x:38.35167759,y:-77.5054536},
{x:39.63023807,y:-79.98509288},
{x:40.45447369,y:-109.54554503},
{x:40.82547054,y:-91.14084542},
{x:39.95391534,y:-86.04067158},
{x:21.41264417,y:-158.00059676},
{x:28.63422852,y:-96.62462662},
{x:36.79863844,y:-76.17612202},
{x:33.2469544,y:-95.91288631},
{x:42.76071505,y:-87.80199502},
{x:46.22034594,y:-119.78778181},
{x:29.67681502,y:-95.4269538},
{x:44.89874021,y:-91.93248063},
{x:46.24120106,y:-119.12287295},
{x:42.57948822,y:-71.77488618},
{x:41.75244929,y:-87.58651092},
{x:38.21221665,y:-85.20502917},
{x:38.73344818,y:-89.91594337},
{x:41.65889764,y:-93.49518813},
{x:33.92456432,y:-84.57523493},
{x:37.87147847,y:-84.59812074},
{x:40.63047063,y:-91.32385366},
{x:43.11808014,y:-87.91621186},
{x:38.00444846,y:-84.4483573},
{x:47.29007064,y:-122.31657077},
{x:47.7047442,y:-122.17530571},
{x:41.62165636,y:-88.12439024},
{x:32.85949263,y:-97.23851026},
{x:29.6627649,y:-95.74048635},
{x:47.69445801,y:-122.3440209},
{x:44.98645913,y:-89.65331085},
{x:41.46459329,y:-87.47096769},
{x:29.6722625,y:-95.28748147},
{x:29.58797456,y:-98.63277546},
{x:29.39919487,y:-98.5572901},
{x:40.26376785,y:-85.81808836},
{x:40.5431041,y:-111.98292122},
{x:38.65209468,y:-85.12569233},
{x:38.62470589,y:-88.96405309},
{x:29.98194941,y:-92.12780421},
{x:33.01501629,y:-96.79471939},
{x:47.38777899,y:-122.04173776},
{x:39.40772727,y:-86.44114857},
{x:40.49683945,y:-111.41408622},
{x:33.56736019,y:-84.41351872},
{x:29.5062905,y:-95.04159176},
{x:41.84831567,y:-88.33955119},
{x:32.43196889,y:-99.75748292},
{x:37.75167813,y:-97.26006247},
{x:27.75407354,y:-98.05936628},
{x:38.88348812,y:-90.12160554},
{x:29.93447086,y:-89.92184063},
{x:34.94349151,y:-83.75131439},
{x:41.468906,y:-87.309473},
{x:40.04546015,y:-86.49121195},
{x:42.6045697,y:-87.87926949},
{x:29.56826753,y:-95.28657691},
{x:43.75334888,y:-87.94248377},
{x:40.07159375,y:-86.47332575},
{x:30.48088084,y:-90.45652941},
{x:21.39631532,y:-157.74560868},
{x:42.72542092,y:-87.95546423},
{x:41.809686,y:-87.97691408},
{x:42.18998323,y:-88.10922233},
{x:30.52016939,y:-92.0790714},
{x:39.00850413,y:-77.50633706},
{x:41.51709918,y:-87.97966361},
{x:42.27403908,y:-89.63195882},
{x:32.7190038,y:-96.61868064},
{x:39.11624558,y:-94.7494626},
{x:37.81266161,y:-94.70495671},
{x:38.45284176,y:-81.93821818},
{x:43.06091926,y:-87.98381685},
{x:42.55734798,y:-87.82571822},
{x:38.05760595,y:-97.92320141},
{x:37.19656809,y:-77.46752778},
{x:42.05729887,y:-71.87112898},
{x:41.07591391,y:-85.14346361},
{x:43.03191288,y:-88.17636324},
{x:37.67661663,y:-82.77845263},
{x:41.28905866,y:-88.24442737},
{x:37.56922835,y:-97.24503075},
{x:38.02002692,y:-87.57374883},
{x:38.39170754,y:-82.57599279},
{x:38.92293716,y:-77.41657813},
{x:42.35820391,y:-72.55150384},
{x:30.18924961,y:-92.0140627},
{x:47.754118,y:-122.16032825},
{x:38.80497534,y:-77.04406987},
{x:39.92752328,y:-86.03463426},
{x:42.97414655,y:-90.12671896},
{x:38.88323326,y:-94.6841366},
{x:38.96668212,y:-78.43829182},
{x:38.93736145,y:-94.79853536},
{x:37.7663599,y:-86.4418526},
{x:42.7040015,y:-89.007468},
{x:44.2767404,y:-105.49467978},
{x:40.11362896,y:-87.63653522},
{x:40.55787559,y:-90.0347902},
{x:29.997798,y:-95.1679904},
{x:30.01912266,y:-95.37640925},
{x:30.65219871,y:-96.33515869},
{x:40.69930348,y:-111.89084815},
{x:41.6792928,y:-86.25319098},
{x:27.6828757,y:-97.37973914},
{x:41.90223969,y:-88.08410652},
{x:38.38912612,y:-81.83344777},
{x:44.55606295,y:-88.05883632},
{x:37.32799014,y:-79.50257689},
{x:38.35741448,y:-81.72229789},
{x:47.58384815,y:-122.32951377},
{x:43.47294582,y:-111.98508252},
{x:32.83673766,y:-97.08211271},
{x:48.84922576,y:-122.57324521},
{x:38.59551237,y:-89.98568617},
{x:41.17861965,y:-85.0945133},
{x:29.78181315,y:-95.80326001},
{x:30.19096523,y:-98.08654904},
{x:37.64006676,y:-77.51406754},
{x:39.01566651,y:-85.64160938},
{x:36.70845055,y:-86.57586641},
{x:41.83327625,y:-87.61658739},
{x:39.41179073,y:-86.1590683},
{x:27.69868415,y:-97.41254628},
{x:43.64910706,y:-116.28109664},
{x:29.51002753,y:-95.18692553},
{x:40.66986067,y:-89.57826555},
{x:40.41710383,y:-86.83736958},
{x:32.8022719,y:-96.79055415},
{x:38.66947612,y:-86.91522915},
{x:45.64041509,y:-122.62093578},
{x:41.410817,y:-92.93342596},
{x:43.40382372,y:-88.18066031},
{x:36.95857897,y:-81.09671831},
{x:39.03617169,y:-77.40949958},
{x:41.48966165,y:-90.47775373},
{x:38.356073,y:-87.60485308},
{x:30.4393623,y:-97.6971371},
{x:42.27363525,y:-71.75093718},
{x:42.91423738,y:-88.00974418},
{x:29.55848107,y:-98.68116505},
{x:43.85521806,y:-88.86215575},
{x:41.62992735,y:-87.50822982},
{x:38.8400962,y:-97.59439051},
{x:35.19149516,y:-101.81759566},
{x:36.98128715,y:-86.46555717},
{x:41.79334404,y:-71.32412228},
{x:39.60344602,y:-87.68728785},
{x:43.01708259,y:-87.98778659},
{x:47.5335657,y:-122.60758},
{x:39.60726947,y:-86.37438926},
{x:32.73984221,y:-101.95168733},
{x:37.05309207,y:-80.75099534},
{x:40.31243113,y:-111.72140629},
{x:38.8631775,y:-77.0602938},
{x:41.6489291,y:-93.6968792},
{x:32.74727308,y:-96.59862774},
{x:37.10062776,y:-113.5355716},
{x:32.58086258,y:-97.13309697},
{x:40.62369234,y:-111.93837795},
{x:39.6865,y:-111.838},
{x:42.13899645,y:-71.10289563},
{x:33.98063649,y:-84.08123346},
{x:38.41031052,y:-82.375697},
{x:41.95161318,y:-88.1200102},
{x:32.62975618,y:-97.41109915},
{x:41.48403773,y:-87.70240635},
{x:39.77422573,y:-85.99529208},
{x:40.72477585,y:-111.54664233},
{x:32.56314496,y:-97.35257596},
{x:39.4836008,y:-88.3312944},
{x:42.23932278,y:-87.99372491},
{x:37.4159469,y:-86.87879209},
{x:47.50672081,y:-122.35538445},
{x:44.91447093,y:-89.64547178},
{x:43.59456638,y:-116.51856065},
{x:30.42175979,y:-95.48724617},
{x:29.62758983,y:-95.46671874},
{x:37.34693211,y:-77.34398709},
{x:43.03672899,y:-88.04666732},
{x:30.12873417,y:-94.01500606},
{x:29.86273074,y:-95.72105273},
{x:38.17574824,y:-85.82097463},
{x:41.96042349,y:-91.71171506},
{x:41.38015115,y:-88.83644213},
{x:37.36605733,y:-79.29599016},
{x:33.34517593,y:-96.58228496},
{x:32.72296868,y:-97.11517632},
{x:46.72171184,y:-116.98516301},
{x:40.94706207,y:-90.33823452},
{x:39.88425138,y:-86.21007778},
{x:38.68169738,y:-84.59897815},
{x:42.11463733,y:-72.73097388},
{x:43.09744629,y:-89.52205786},
{x:47.79225918,y:-122.36915879},
{x:29.56119834,y:-98.33059564},
{x:41.92162697,y:-88.28508688},
{x:42.36505861,y:-89.03313969},
{x:42.03420652,y:-91.68065489},
{x:42.95958161,y:-87.91441932},
{x:38.61539046,y:-86.08357636},
{x:32.89904959,y:-83.68933712},
{x:44.43985856,y:-88.06015939},
{x:39.65082231,y:-86.12177319},
{x:33.43993223,y:-84.12393117},
{x:37.02576127,y:-76.36462279},
{x:37.12960929,y:-76.53854683},
{x:47.50414482,y:-117.56533255},
{x:32.47074087,y:-95.38950781},
{x:47.1416282,y:-119.27866987},
{x:39.08599868,y:-95.66550229},
{x:38.97159636,y:-94.7738573},
{x:40.65266803,y:-111.96703662},
{x:42.48567052,y:-71.01930995},
{x:42.51250387,y:-92.43065815},
{x:41.93787873,y:-88.00291032},
{x:39.8304,y:-84.8594},
{x:31.70560996,y:-106.31869067},
{x:33.97117063,y:-84.2201104},
{x:34.97693713,y:-101.91877628},
{x:43.53279414,y:-89.98268321},
{x:36.72624867,y:-88.62979352},
{x:39.7516361,y:-86.03199899},
{x:39.76589709,y:-86.19665153},
{x:30.52671472,y:-90.95572834},
{x:29.95378304,y:-89.97855976},
{x:30.64874471,y:-91.14995614},
{x:38.25012449,y:-85.62562783},
{x:38.41491914,y:-81.79532907},
{x:39.019547,y:-95.508819},
{x:42.0981,y:-84.2469},
{x:32.64545041,y:-96.78086981},
{x:40.63901619,y:-112.02314615},
{x:41.18678516,y:-87.85069764},
{x:48.3044581,y:-122.64523526},
{x:38.24996019,y:-85.79874431},
{x:39.54855052,y:-85.77494503},
{x:37.64499595,y:-98.71218395},
{x:30.1412837,y:-91.94784699},
{x:42.72634266,y:-90.47786549},
{x:37.97724933,y:-87.62922074},
{x:34.18945413,y:-101.75091863},
{x:30.2546,y:-92.2699},
{x:40.76561383,y:-86.07735246},
{x:33.08136136,y:-96.89331472},
{x:41.15987729,y:-104.79901443},
{x:32.77285492,y:-97.40084441},
{x:41.71959686,y:-88.31917499},
{x:38.83198558,y:-77.20307618},
{x:31.12081774,y:-92.06834145},
{x:44.4860127,y:-88.0680522},
{x:43.6370112,y:-116.23160103},
{x:29.57808706,y:-98.44100895},
{x:41.67280545,y:-86.00271486},
{x:41.9970314,y:-87.71655594},
{x:40.11316971,y:-85.56250781},
{x:38.96903264,y:-89.1293626},
{x:38.75066453,y:-90.07257347},
{x:47.30758112,y:-119.5616743},
{x:37.97613949,y:-87.39745192},
{x:32.90809928,y:-96.69998591},
{x:41.68067998,y:-86.18990891},
{x:21.36601639,y:-157.927555},
{x:42.85090353,y:-106.27155125},
{x:47.04986812,y:-122.80902474},
{x:38.33946766,y:-81.61519262},
{x:38.03265332,y:-78.45565975},
{x:45.66957871,y:-122.55219497},
{x:38.36912069,y:-81.66627759},
{x:41.8595981,y:-88.07354177},
{x:29.98181914,y:-95.56647714},
{x:32.51049934,y:-92.15777868},
{x:39.69429024,y:-86.07499943},
{x:40.05575951,y:-80.60215662},
{x:41.53826943,y:-88.19869814},
{x:34.08740081,y:-84.57571767},
{x:42.71940973,y:-87.84807269},
{x:39.00651559,y:-87.72174072},
{x:43.0408642,y:-88.25539851},
{x:43.95173726,y:-91.25797689},
{x:44.00878358,y:-88.58106107},
{x:37.60178506,y:-77.45375812},
{x:37.72488003,y:-87.12416996},
{x:41.795358,y:-87.723614},
{x:39.70017156,y:-86.41182008},
{x:37.98115509,y:-78.2091595},
{x:32.77799723,y:-97.28926772},
{x:32.93206457,y:-97.28471014},
{x:30.01108887,y:-95.79880955},
{x:32.43107882,y:-93.71467267},
{x:32.62813859,y:-83.61350132},
{x:41.74709711,y:-71.20550518},
{x:38.15673099,y:-85.71212389},
{x:29.14255203,y:-98.15458204},
{x:38.55325052,y:-90.16614059},
{x:38.626936,y:-83.7909},
{x:48.10672212,y:-123.39967628},
{x:34.34829386,y:-84.05023856},
{x:41.65710711,y:-70.9201723},
{x:36.81939682,y:-76.11934818},
{x:47.32111601,y:-122.22369969},
{x:41.13347095,y:-85.1004155},
{x:37.27805457,y:-87.13927654},
{x:38.81112604,y:-77.1323356},
{x:42.98790741,y:-88.23519766},
{x:29.61251304,y:-95.22058866},
{x:39.9004,y:-85.9934},
{x:40.55948797,y:-89.6192304},
{x:37.53892363,y:-84.64958841},
{x:45.63620871,y:-89.38033905},
{x:32.8344,y:-83.744},
{x:38.05843882,y:-97.34563485},
{x:45.71658835,y:-122.65263532},
{x:29.49083938,y:-98.44090238},
{x:37.5292297,y:-77.35895276},
{x:47.57727377,y:-122.16985995},
{x:29.57988123,y:-90.69091006},
{x:31.3276518,y:-95.44013978},
{x:40.00056465,y:-85.73499088},
{x:38.08645482,y:-85.6685771},
{x:32.55557652,y:-93.70990538},
{x:40.49979788,y:-86.10884468},
{x:33.4722501,y:-94.40956663},
{x:28.70608742,y:-100.48440903},
{x:32.48026045,y:-96.98698677},
{x:29.80318627,y:-95.48576286},
{x:47.45281311,y:-122.82450069},
{x:29.34820228,y:-99.12159497},
{x:38.22995078,y:-78.37071419},
{x:41.48509664,y:-87.05060273},
{x:46.998186,y:-122.824235},
{x:40.38919444,y:-111.82862002},
{x:40.1369396,y:-88.24267974},
{x:43.48216344,y:-112.01451212},
{x:33.655483,y:-84.50117473},
{x:30.04435061,y:-89.95903807},
{x:35.66454006,y:-101.39770925},
{x:39.86717404,y:-86.12170775},
{x:30.32406384,y:-90.98100682},
{x:42.40826086,y:-88.03142332},
{x:36.86049757,y:-76.21043891},
{x:30.66227249,y:-97.69072165},
{x:39.57819011,y:-86.15959261},
{x:33.13583637,y:-96.80482328},
{x:29.34035535,y:-98.62158093},
{x:42.5236628,y:-89.0343833},
{x:38.33900959,y:-77.07799939},
{x:19.69234687,y:-155.06637414},
{x:34.03689757,y:-84.68522161},
{x:32.83626582,y:-96.7732868},
{x:38.94308447,y:-95.25115766},
{x:30.08544499,y:-97.82252155},
{x:32.45130276,y:-99.78083827},
{x:31.29583475,y:-92.47942812},
{x:47.86084201,y:-121.97560877},
{x:43.81427187,y:-91.23950779},
{x:30.15263992,y:-95.21604766},
{x:47.1843794,y:-122.25693117},
{x:38.88329757,y:-94.79842231},
{x:37.89809875,y:-88.9500664},
{x:38.95415481,y:-94.7241278},
{x:47.10327805,y:-119.24977641},
{x:29.93786994,y:-93.93555383},
{x:37.16428828,y:-80.42280351},
{x:32.81535537,y:-83.69803921},
{x:41.79230678,y:-87.79567368},
{x:43.03891063,y:-87.94078984},
{x:29.40850937,y:-95.23393653},
{x:38.8549768,y:-94.68013815},
{x:34.00032217,y:-83.9088019},
{x:43.59491966,y:-116.19311017},
{x:42.62405827,y:-87.84170441},
{x:42.489447,y:-90.664758},
{x:21.377071,y:-158.02612008},
{x:47.18573957,y:-122.29398019},
{x:37.66991873,y:-77.68028511},
{x:39.55517603,y:-89.29705314},
{x:47.44270752,y:-122.1973794},
{x:21.4484203,y:-158.18938941},
{x:42.862901,y:-85.68297},
{x:42.94592101,y:-88.04753121},
{x:39.18991347,y:-96.55859073},
{x:45.62112279,y:-122.55227518},
{x:40.2186879,y:-85.44321482},
{x:29.52075281,y:-95.07676452},
{x:41.89839606,y:-88.33532788},
{x:44.78007443,y:-106.9420974},
{x:38.14006275,y:-89.70296144},
{x:29.97396118,y:-90.14348169},
{x:32.66513062,y:-97.34536405},
{x:32.71195212,y:-97.06822493},
{x:43.42768924,y:-88.21250534},
{x:42.97991985,y:-88.10759744},
{x:37.95529272,y:-87.51076056},
{x:29.98723858,y:-91.82487561},
{x:37.25888365,y:-76.74007018},
{x:38.96297598,y:-78.18970157},
{x:41.67544513,y:-71.16303868},
{x:37.79746704,y:-97.32718711},
{x:42.27856433,y:-89.24242191},
{x:43.10429025,y:-89.34276917},
{x:45.78088833,y:-122.55509444},
{x:29.9595974,y:-95.5384464},
{x:40.16448558,y:-84.96513959},
{x:37.74205406,y:-88.96956265},
{x:38.32047721,y:-85.71354528},
{x:37.65149979,y:-77.61035313},
{x:34.01096156,y:-85.04147798},
{x:37.72887898,y:-85.88518117},
{x:39.02263611,y:-94.65944396},
{x:36.1393589,y:-86.8023709},
{x:29.71104616,y:-95.20156905},
{x:37.66268389,y:-97.22671548},
{x:29.97789318,y:-90.20782388},
{x:34.03665089,y:-84.46423148},
{x:38.09870143,y:-85.87158412},
{x:47.7296164,y:-116.78912942},
{x:38.12686144,y:-85.85129917},
{x:19.72072061,y:-155.08401635},
{x:37.72789532,y:-88.53629031},
{x:47.62745812,y:-122.1427305},
{x:44.26235604,y:-88.45056549},
{x:42.563372,y:-113.792354},
{x:30.1736987,y:-92.06978239},
{x:21.64676365,y:-157.9221102},
{x:44.45234099,y:-88.09175105},
{x:29.5914321,y:-95.60174096},
{x:42.1964,y:-83.305},
{x:38.78819936,y:-77.5134813},
{x:31.938388,y:-106.407135},
{x:32.82406121,y:-96.64636776},
{x:39.91591126,y:-88.95595685},
{x:38.99937551,y:-77.40590885},
{x:41.55008348,y:-87.18083737},
{x:39.16895958,y:-86.53400217},
{x:38.17480135,y:-87.5492038},
{x:21.45213294,y:-158.00748678},
{x:32.92745507,y:-83.73417499},
{x:43.664293,y:-116.41330492},
{x:41.14017858,y:-85.16165293},
{x:39.63700674,y:-86.08373803},
{x:41.56389145,y:-87.35490987},
{x:40.50834625,y:-112.01243341},
{x:41.50679757,y:-87.31513691},
{x:38.40799136,y:-82.71566759},
{x:42.69198373,y:-114.51901855},
{x:41.7721034,y:-87.7418102},
{x:41.92534477,y:-87.70163886},
{x:32.59873709,y:-97.32080705},
{x:41.82956695,y:-88.09876114},
{x:32.86364576,y:-96.89614002},
{x:40.04325171,y:-86.14067666},
{x:32.52991894,y:-96.82134528},
{x:38.07042054,y:-84.47704218},
{x:30.30612812,y:-89.82626259},
{x:41.93122583,y:-88.77646085},
{x:44.01736238,y:-90.50278946},
{x:37.31477858,y:-79.9579989},
{x:38.98439562,y:-84.40044744},
{x:41.751777,y:-91.614384},
{x:37.98713905,y:-84.20885182},
{x:42.41163549,y:-88.61416081},
{x:32.1904645,y:-95.4338633},
{x:37.34807952,y:-79.23276729},
{x:38.08227087,y:-78.93041655},
{x:30.84212545,y:-83.32425177},
{x:30.39123126,y:-97.72011745},
{x:47.16724952,y:-122.14686513},
{x:38.32970157,y:-85.75733351},
{x:38.41326564,y:-82.26123235},
{x:38.81067251,y:-94.91367012},
{x:44.96128334,y:-92.7239116},
{x:33.022772,y:-96.453197},
{x:33.67458054,y:-84.44273485},
{x:34.12172394,y:-84.74469458},
{x:36.85741646,y:-75.97905047},
{x:30.20790227,y:-95.42407436},
{x:30.48054451,y:-90.95633894},
{x:37.06519139,y:-81.73773979},
{x:37.41230386,y:-79.21294858},
{x:43.78321747,y:-88.41234673},
{x:38.91437293,y:-94.66991544},
{x:48.3073765,y:-116.54694217},
{x:45.91430982,y:-89.25425574},
{x:39.93081179,y:-80.75230882},
{x:30.84754065,y:-90.15155873},
{x:48.10029353,y:-122.18114644},
{x:42.410467,y:-87.85553865},
{x:42.40220606,y:-71.04223788},
{x:41.6418323,y:-91.52224481},
{x:37.22278689,y:-79.96769535},
{x:29.65561594,y:-95.35584433},
{x:39.726444,y:-85.913359},
{x:37.15852844,y:-76.55749261},
{x:42.56803234,y:-87.95394731},
{x:29.38001947,y:-98.46017346},
{x:29.70117913,y:-91.20044975},
{x:46.37437757,y:-120.32066301},
{x:29.65273928,y:-95.0340423},
{x:38.14975472,y:-85.77656166},
{x:30.1271033,y:-95.45260645},
{x:36.78262028,y:-76.0007842},
{x:46.60432048,y:-120.49587348},
{x:41.88118475,y:-88.19404641},
{x:41.71851496,y:-87.836321},
{x:38.57897215,y:-95.26818842},
{x:40.54566958,y:-112.29869485},
{x:38.51693627,y:-89.95180607},
{x:42.0345313,y:-87.94019766},
{x:37.05679802,y:-100.92289676},
{x:30.10415476,y:-95.23380518},
{x:38.30602447,y:-80.83256494},
{x:31.72945611,y:-98.98587763},
{x:30.68202644,y:-91.46274274},
{x:48.80044339,y:-122.4854203},
{x:36.72223137,y:-84.17055098},
{x:43.17541745,y:-89.24721681},
{x:36.88144616,y:-76.30270501},
{x:41.37133789,y:-89.12657619},
{x:46.738732,y:-117.171069},
{x:39.80137098,y:-86.15649242},
{x:37.84665495,y:-81.19557634},
{x:41.05537997,y:-85.2246872},
{x:37.82568406,y:-96.84989231},
{x:41.45733344,y:-88.27192173},
{x:48.77323073,y:-122.45833316},
{x:41.97278942,y:-88.20758251},
{x:36.83725756,y:-76.30765122},
{x:39.98369426,y:-86.00301325},
{x:36.0630416,y:-102.5174519},
{x:39.03716305,y:-94.64013998},
{x:39.47109187,y:-87.40641029},
{x:47.4671682,y:-122.33425765},
{x:48.41807749,y:-119.50778051},
{x:38.07862956,y:-81.83272484},
{x:41.3568257,y:-86.31179899},
{x:42.62713199,y:-88.62186499},
{x:29.87479454,y:-90.10422526},
{x:37.66358692,y:-97.57273749},
{x:41.37313332,y:-93.5589695},
{x:38.3390094,y:-85.47369793},
{x:36.93973319,y:-82.60685523},
{x:38.68087292,y:-88.49730439},
{x:40.07593745,y:-80.69801586},
{x:30.44372294,y:-90.14212221},
{x:37.14281189,y:-79.26649853},
{x:45.17629255,y:-89.64228712},
{x:44.82987954,y:-91.54506344},
{x:39.78198413,y:-86.04584185},
{x:43.968793,y:-88.977631},
{x:41.75189138,y:-86.12182834},
{x:37.8106212,y:-80.43397088},
{x:41.68507574,y:-88.38088591},
{x:39.20058137,y:-85.96282174},
{x:41.85628106,y:-90.18603265},
{x:45.10052395,y:-92.53700428},
{x:38.95354939,y:-94.66823749},
{x:30.67251913,y:-96.33410696},
{x:36.73741206,y:-86.2121604},
{x:39.90957982,y:-85.38590662},
{x:40.10126185,y:-85.71052694},
{x:29.70290742,y:-98.09713625},
{x:31.84899704,y:-106.35596863},
{x:47.03795421,y:-122.93981843},
{x:38.40138295,y:-85.37706502},
{x:32.74571833,y:-97.22323984},
{x:41.94284863,y:-91.67540576},
{x:32.15933958,y:-94.36430481},
{x:39.93519394,y:-91.40215695},
{x:29.54520548,y:-98.28915238},
{x:43.17945226,y:-88.01035075},
{x:32.96904948,y:-96.33066505},
{x:41.06244631,y:-84.98149037},
{x:38.20124636,y:-85.6639633},
{x:37.07912311,y:-88.6839657},
{x:39.04116753,y:-96.83602542},
{x:33.02538069,y:-96.9934523},
{x:38.36138921,y:-98.78795384},
{x:41.84951847,y:-87.74393593},
{x:42.23911547,y:-87.94498077},
{x:38.19680741,y:-84.27507618},
{x:37.0857,y:-80.6891},
{x:29.73695109,y:-95.53518496},
{x:36.74925233,y:-76.58374339},
{x:30.35447672,y:-97.73137589},
{x:29.83178665,y:-95.54688604},
{x:30.0179042,y:-95.43869509},
{x:40.1124007,y:-88.28095051},
{x:34.8097788,y:-102.40986763},
{x:36.76370574,y:-76.20446943},
{x:31.98155208,y:-81.16996265},
{x:41.76323334,y:-88.20053768},
{x:31.78529896,y:-106.41444956},
{x:41.37634875,y:-87.46939631},
{x:37.73597126,y:-84.30932609},
{x:38.05199224,y:-84.71941978},
{x:39.32796694,y:-94.91933787},
{x:36.98261235,y:-86.43907629},
{x:43.56549653,y:-116.19942524},
{x:43.63168047,y:-88.71702889},
{x:29.48367697,y:-98.39938473},
{x:38.12931843,y:-83.75394771},
{x:41.60779963,y:-87.17214012},
{x:39.82258016,y:-86.28159691},
{x:30.17897075,y:-96.91575028},
{x:36.68606924,y:-80.88666111},
{x:30.71574838,y:-95.56956098},
{x:33.12910968,y:-96.64967287},
{x:33.74702545,y:-96.53685182},
{x:43.87013955,y:-91.21037096},
{x:30.16604453,y:-97.79169358},
{x:40.2186864,y:-85.37569523},
{x:41.9037,y:-87.6317},
{x:40.54650305,y:-74.58380921},
{x:27.10211305,y:-82.38375744},
{x:42.35407013,y:-71.05828583},
{x:41.45104454,y:-85.24693864},
{x:30.28173872,y:-91.22776223},
{x:42.6893,y:-88.5422},
{x:40.76669121,y:-87.12344694},
{x:32.40198193,y:-98.79320992},
{x:39.76917984,y:-86.10928644},
{x:40.10608831,y:-85.65342396},
{x:39.96160427,y:-86.11639468},
{x:46.256931,y:-119.30635576},
{x:37.59094,y:-77.50532},
{x:41.07861641,y:-87.86725719},
{x:38.03329789,y:-81.12491865},
{x:20.88191415,y:-156.68090834},
{x:36.72793645,y:-79.91765101},
{x:41.882211,y:-87.640594},
{x:46.06263676,y:-118.34749733},
{x:47.30041944,y:-122.36050926},
{x:40.53148358,y:-85.67415167},
{x:37.25909585,y:-77.32708812},
{x:43.08390208,y:-88.23142729},
{x:30.24263087,y:-90.91693823},
{x:42.01839337,y:-87.80624091},
{x:38.2449916,y:-77.52765108},
{x:43.31851962,y:-90.36104306},
{x:42.26017778,y:-88.1597767},
{x:37.79971765,y:-85.43339043},
{x:41.61548987,y:-93.86987748},
{x:39.13686143,y:-88.56506644},
{x:41.6586305,y:-88.44791346},
{x:32.9100545,y:-96.73593074},
{x:34.06469728,y:-84.42325708},
{x:43.47615914,y:-89.76932063},
{x:33.53271256,y:-84.36457653},
{x:48.99317033,y:-122.73381384},
{x:29.69872413,y:-90.55870815},
{x:37.01122528,y:-79.86838996},
{x:42.26680846,y:-71.168553},
{x:47.45709973,y:-122.2434132},
{x:37.46651236,y:-82.53181457},
{x:41.76423128,y:-88.36881787},
{x:29.68964771,y:-95.55595458},
{x:31.19054188,y:-101.44962},
{x:38.72349742,y:-77.80568366},
{x:41.93710904,y:-88.07795607},
{x:32.47939601,y:-93.71930532},
{x:27.66640246,y:-97.28325531},
{x:32.40984832,y:-96.84172728},
{x:38.22113671,y:-85.75560167},
{x:38.46996102,y:-82.62396417},
{x:29.82825635,y:-95.37958182},
{x:29.81125252,y:-95.39983928},
{x:42.21711272,y:-88.25541303},
{x:36.68703042,y:-78.89852809},
{x:38.69140246,y:-89.98514663},
{x:40.73226455,y:-92.87197356},
{x:41.62756449,y:-86.17729083},
{x:44.79245233,y:-91.51961802},
{x:38.42472663,y:-82.41091889},
{x:42.05698611,y:-88.00220652},
{x:30.87238651,y:-83.29048862},
{x:42.02595105,y:-88.31250476},
{x:29.35627686,y:-98.49788449},
{x:33.5952,y:-96.607},
{x:36.799995,y:-81.683804},
{x:38.50279588,y:-89.98539463},
{x:42.1084631,y:-70.76939285},
{x:40.75759913,y:-86.7651854},
{x:28.9798,y:-80.9007},
{x:45.67258472,y:-122.51286864},
{x:32.80620614,y:-96.99487326},
{x:43.08211262,y:-88.76270437},
{x:30.44890537,y:-91.24361882},
{x:41.59835907,y:-88.02249833},
{x:33.66142025,y:-95.5042094},
{x:38.04287539,y:-85.3419513},
{x:47.65864559,y:-117.19702542},
{x:47.09585059,y:-122.35877305},
{x:41.84729536,y:-87.8107341},
{x:39.83321611,y:-89.60415867},
{x:39.52763895,y:-86.80124407},
{x:39.02112628,y:-96.81963095},
{x:42.23915001,y:-88.58375988},
{x:29.35685084,y:-98.13479662},
{x:40.41874551,y:-91.4078844},
{x:31.07881218,y:-97.7630328},
{x:42.60313997,y:-89.66457934},
{x:37.99619588,y:-89.23604718},
{x:42.92929643,y:-87.86116455},
{x:41.14373365,y:-88.83622653},
{x:32.50038951,y:-94.74050629},
{x:39.93486406,y:-91.35389251},
{x:43.56019129,y:-116.57245716},
{x:32.07301969,y:-84.22198069},
{x:36.62144612,y:-83.70473976},
{x:37.44167349,y:-77.58543849},
{x:30.11340351,y:-95.55398047},
{x:41.36720774,y:-85.07850967},
{x:42.28815664,y:-89.09907912},
{x:44.35743375,y:-89.11309806},
{x:32.37792415,y:-97.39308093},
{x:37.98600753,y:-87.49214821},
{x:40.06118672,y:-85.6523752},
{x:38.1258,y:-82.6269},
{x:42.81180176,y:-71.10224555},
{x:33.09582901,y:-96.79735467},
{x:38.36002006,y:-81.73644453},
{x:39.61833193,y:-86.15798987},
{x:39.54465179,y:-77.90933132},
{x:42.13991313,y:-87.91243672},
{x:41.79390539,y:-87.63258033},
{x:33.91211255,y:-81.22637494},
{x:38.95104931,y:-77.33436607},
{x:41.94700219,y:-87.65738959},
{x:37.63898456,y:-77.55479217},
{x:40.29670504,y:-111.69994064},
{x:33.07455532,y:-96.8353374},
{x:38.97199539,y:-95.30387046},
{x:42.34565362,y:-88.27804997},
{x:21.41734276,y:-157.80195575},
{x:29.55168153,y:-90.3487832},
{x:19.64306118,y:-155.99600896},
{x:39.17907773,y:-89.66837421},
{x:40.3098,y:-112.014},
{x:37.09281401,y:-85.30358188},
{x:26.22951895,y:-98.32523748},
{x:36.9651413,y:-81.06759095},
{x:43.13559887,y:-88.24315041},
{x:38.31253508,y:-81.56687006},
{x:41.57299748,y:-87.4045384},
{x:46.22047869,y:-119.22478348},
{x:30.43143659,y:-91.05659415},
{x:32.59499289,y:-96.8217051},
{x:42.06056872,y:-87.84452317},
{x:30.00915439,y:-90.26586995},
{x:41.78894551,y:-89.69694583},
{x:42.01812645,y:-93.61089975},
{x:39.67211104,y:-80.85779116},
{x:21.31539109,y:-158.01366908},
{x:43.7249946,y:-83.4459398},
{x:42.3929529,y:-88.18411019},
{x:38.92794779,y:-94.72882287},
{x:30.5476286,y:-91.05412424},
{x:37.51251714,y:-77.69576572},
{x:38.8421264,y:-94.6664242},
{x:39.21642943,y:-78.14214245},
{x:47.94646269,y:-117.47665165},
{x:29.94752558,y:-95.33220611},
{x:41.22881887,y:-95.85029024},
{x:39.82555721,y:-84.92288225},
{x:42.12945709,y:-72.62790383},
{x:38.86818884,y:-90.06734016},
{x:47.65682533,y:-117.26191602},
{x:47.24303497,y:-122.52856351},
{x:42.46834407,y:-92.3211392},
{x:39.8249685,y:-86.11644454},
{x:40.69789402,y:-112.02435945},
{x:42.98613096,y:-88.00816973},
{x:31.57105444,y:-94.67460252},
{x:37.55827181,y:-83.38849425},
{x:38.79278268,y:-89.95086228},
{x:36.88031908,y:-76.18730196},
{x:33.58436334,y:-84.33958505},
{x:42.5287,y:-92.338},
{x:40.69630246,y:-111.97376873},
{x:41.46659947,y:-90.50631048},
{x:47.67209755,y:-122.37581715},
{x:41.31432382,y:-110.50959615},
{x:38.17944237,y:-83.44357529},
{x:38.2191647,y:-81.5214077},
{x:32.84559434,y:-96.59598742},
{x:37.37259808,y:-77.50088681},
{x:31.89910189,y:-106.43991216},
{x:42.24581758,y:-88.97562893},
{x:31.75237497,y:-106.31832898},
{x:27.69560935,y:-97.34743992},
{x:34.02779299,y:-84.04678613},
{x:30.00593372,y:-90.1883541},
{x:39.76508516,y:-86.15888865},
{x:44.82025839,y:-87.40923956},
{x:41.70107833,y:-91.61033324},
{x:30.45225759,y:-91.11175416},
{x:39.4802699,y:-77.9578386},
{x:37.55937109,y:-85.2716437},
{x:32.92115508,y:-96.9927147},
{x:31.27212653,y:-92.46262421},
{x:30.19087005,y:-97.76904054},
{x:30.43898755,y:-91.00799434},
{x:42.2433495,y:-88.97923019},
{x:29.89858945,y:-90.14226665},
{x:41.7194323,y:-87.77215312},
{x:47.71087141,y:-116.91879078},
{x:42.53162003,y:-71.74406563},
{x:39.51959361,y:-85.75234383},
{x:30.31920148,y:-95.46439737},
{x:38.42286831,y:-96.21750672},
{x:40.88386576,y:-111.89252981},
{x:47.12050129,y:-122.55532482},
{x:30.06788584,y:-94.13151},
{x:29.79996225,y:-94.9807354},
{x:30.54166306,y:-97.55761491},
{x:39.84133456,y:-96.60972476},
{x:41.60062358,y:-87.56007656},
{x:38.95858704,y:-85.86653084},
{x:41.8079093,y:-88.3724595},
{x:37.67929483,y:-97.38889267},
{x:39.4445067,y:-77.98666343},
{x:39.29575799,y:-77.84342028},
{x:45.585063,y:-122.36917282},
{x:45.68464911,y:-122.60184063},
{x:37.25164983,y:-77.38337078},
{x:31.47106089,y:-100.41439533},
{x:30.093092,y:-90.97915},
{x:37.17622486,y:-113.29525904},
{x:38.14718635,y:-85.58655379},
{x:38.16264343,y:-84.89623059},
{x:40.16852641,y:-87.63113379},
{x:37.36788658,y:-84.33601205},
{x:38.4221,y:-83.7537},
{x:32.87756963,y:-97.37945878},
{x:38.73166726,y:-88.0767846},
{x:41.87641255,y:-87.62900101},
{x:39.16503398,y:-86.58065818},
{x:41.16965398,y:-85.49322286},
{x:38.858548,y:-94.816696},
{x:37.88522234,y:-85.95050465},
{x:30.36479852,y:-97.69665939},
{x:37.23377965,y:-80.41844492},
{x:32.49110168,y:-92.0841892},
{x:29.92297558,y:-95.60116053},
{x:38.87406357,y:-99.31730054},
{x:37.7770378,y:-84.32257323},
{x:29.68317502,y:-91.2652368},
{x:29.882829,y:-90.024138},
{x:33.00307076,y:-96.76833632},
{x:29.91977905,y:-90.0113645},
{x:36.7187892,y:-76.23926075},
{x:37.80080486,y:-81.17916405},
{x:41.54604573,y:-87.79340699},
{x:44.25885802,y:-88.3594573},
{x:47.30063776,y:-122.22394109},
{x:37.3651,y:-79.1785},
{x:45.88523735,y:-89.70378004},
{x:33.504754,y:-101.922705},
{x:38.77592349,y:-85.38694918},
{x:47.23133846,y:-123.12308973},
{x:32.73357823,y:-96.64572537},
{x:40.48318679,y:-89.01843221},
{x:40.27841795,y:-86.48061208},
{x:38.52939462,y:-89.15126487},
{x:42.9942071,y:-89.51953856},
{x:37.46435985,y:-86.29232748},
{x:39.30450061,y:-81.54902679},
{x:41.61826,y:-90.781386},
{x:29.56948092,y:-98.38281097},
{x:38.78148921,y:-95.17853332},
{x:40.76097776,y:-111.87306778},
{x:41.61621034,y:-86.73256749},
{x:39.05294653,y:-94.6809943},
{x:36.714838,y:-76.188481},
{x:28.94134987,y:-98.51211324},
{x:39.49419311,y:-86.06425389},
{x:38.04954634,y:-87.28357823},
{x:36.97179852,y:-84.09555611},
{x:30.21403503,y:-92.04876244},
{x:37.28841529,y:-80.08657403},
{x:42.46852275,y:-71.17949314},
{x:37.94075756,y:-77.46092022},
{x:43.07522168,y:-87.88148347},
{x:39.11631009,y:-94.6712856},
{x:32.57718143,y:-96.30944893},
{x:40.11040808,y:-88.23139029},
{x:32.00469835,y:-81.1138308},
{x:44.79763075,y:-91.46549195},
{x:39.82980372,y:-86.04173809},
{x:36.63824929,y:-82.56926887},
{x:38.06144121,y:-85.70189752},
{x:32.64673194,y:-97.18881531},
{x:42.243435,y:-87.949649},
{x:43.19476401,y:-112.3624092},
{x:43.17075976,y:-88.73181428},
{x:41.79311092,y:-107.20986763},
{x:29.80479415,y:-90.81600845},
{x:21.97264162,y:-159.37809265},
{x:38.35197689,y:-85.70468779},
{x:40.09544042,y:-88.24557205},
{x:40.18829465,y:-85.41947208},
{x:38.70269791,y:-90.14339922},
{x:38.39473076,y:-82.41529506},
{x:47.47904104,y:-122.21612433},
{x:39.71835555,y:-86.35719363},
{x:41.7482572,y:-88.16902415},
{x:39.42668848,y:-75.23511447},
{x:47.35951925,y:-122.30901353},
{x:44.5124604,y:-87.96655123},
{x:41.30618568,y:-112.02218417},
{x:44.78135465,y:-88.57846521},
{x:36.76206929,y:-80.73388583},
{x:31.81782052,y:-106.42888515},
{x:41.01348169,y:-92.43884549},
{x:47.3789754,y:-122.24698354},
{x:38.21051178,y:-86.86454281},
{x:29.70582475,y:-95.41129494},
{x:36.67119252,y:-87.44404518},
{x:31.81122303,y:-94.19279862},
{x:47.92177216,y:-122.29962104},
{x:30.54677563,y:-91.964878},
{x:40.11621919,y:-88.2211233},
{x:41.25772668,y:-110.98561674},
{x:38.22008966,y:-77.50676729},
{x:30.8504845,y:-93.28939372},
{x:47.81311399,y:-116.88623652},
{x:43.12544639,y:-89.31289263},
{x:38.89041348,y:-77.18449697},
{x:44.83960844,y:-73.08204461},
{x:41.76001435,y:-111.83383709},
{x:37.67974242,y:-96.98534115},
{x:36.75648302,y:-76.00732554},
{x:44.29557265,y:-88.31217438},
{x:43.04257207,y:-108.38114452},
{x:29.8332767,y:-94.84887507},
{x:41.90436672,y:-88.47159778},
{x:41.52749514,y:-87.63523307},
{x:42.04863027,y:-88.08616112},
{x:30.1800126,y:-93.24894235},
{x:41.71239842,y:-111.83861118},
{x:42.135617,y:-88.43202889},
{x:32.8174561,y:-96.81256525},
{x:30.85502172,y:-83.94673973},
{x:38.42365698,y:-81.84135904},
{x:36.86687745,y:-76.28448665},
{x:42.67888141,y:-89.05132507},
{x:36.88964477,y:-76.23809919},
{x:32.57431079,y:-95.8561648},
{x:37.59796782,y:-77.61989928},
{x:38.18813317,y:-84.56204764},
{x:37.55094661,y:-85.72777226},
{x:37.28932695,y:-85.9035002},
{x:37.27860121,y:-78.39716573},
{x:48.43619812,y:-122.33817235},
{x:47.18080199,y:-122.48338833},
{x:30.96410809,y:-95.88556127},
{x:46.27343103,y:-119.18433309},
{x:40.74613425,y:-85.17036944},
{x:40.81112303,y:-85.83366781},
{x:39.8554889,y:-86.08625335},
{x:37.50260022,y:-77.6283296},
{x:30.26310187,y:-91.99377872},
{x:37.71402992,y:-85.83248058},
{x:30.7453,y:-98.2319},
{x:47.22280814,y:-122.47554396},
{x:30.72693309,y:-90.52593559},
{x:39.0670023,y:-85.25389112},
{x:29.50241171,y:-95.11545546},
{x:32.90196573,y:-97.18544751},
{x:37.1095362,y:-113.57007533},
{x:47.03973509,y:-122.83757263},
{x:29.6645439,y:-95.0993156},
{x:30.02245672,y:-90.06173042},
{x:41.16073869,y:-112.02648662},
{x:36.86491254,y:-76.40728399},
{x:41.89239445,y:-87.622215},
{x:34.153327,y:-84.821585},
{x:40.94033953,y:-87.21557864},
{x:26.1991245,y:-98.23108926},
{x:29.48056321,y:-95.39768091},
{x:21.32409024,y:-157.87534122},
{x:37.69423985,y:-97.26898809},
{x:33.21864783,y:-96.87147786},
{x:27.68738202,y:-99.4634979},
{x:38.87735244,y:-89.39119788},
{x:42.4268859,y:-71.01278146},
{x:43.59130863,y:-116.35515243},
{x:36.77730134,y:-76.2680711},
{x:30.05589865,y:-95.574054},
{x:37.0782465,y:-76.4631082},
{x:38.89210337,y:-77.0837933},
{x:42.04234228,y:-87.79960167},
{x:42.00563963,y:-87.96031057},
{x:40.44210153,y:-86.12609813},
{x:37.79100043,y:-81.21387569},
{x:38.65191351,y:-90.13140786},
{x:29.9119103,y:-95.72705741},
{x:31.85401179,y:-102.39393352},
{x:41.62943198,y:-86.24777868},
{x:39.64598079,y:-86.8322504},
{x:38.4805327,y:-77.98212222},
{x:40.613294,y:-80.56274},
{x:42.19900919,y:-87.83280288},
{x:42.07884535,y:-94.89085445},
{x:41.98143225,y:-88.70707093},
{x:41.86208025,y:-89.49804066},
{x:32.39032627,y:-94.86477211},
{x:47.93033299,y:-122.22021814},
{x:39.49070475,y:-86.05792941},
{x:44.48552965,y:-87.97179723},
{x:41.58925954,y:-85.84577836},
{x:37.60834313,y:-97.33147562},
{x:37.05329364,y:-88.64940404},
{x:41.92421253,y:-87.79155367},
{x:47.14509633,y:-122.29247689},
{x:38.21327645,y:-85.58881223},
{x:32.53248964,y:-96.65837101},
{x:37.82709894,y:-85.46973004},
{x:32.45615661,y:-93.65970252},
{x:38.0114933,y:-78.50268535},
{x:36.79164976,y:-76.23936466},
{x:36.90474112,y:-82.05689871},
{x:41.91836394,y:-87.65278631},
{x:37.96839228,y:-84.50307372},
{x:40.28281433,y:-86.02508882},
{x:43.61891819,y:-116.26264572},
{x:34.88232473,y:-83.39660154},
{x:40.50989448,y:-88.95701686},
{x:40.75693903,y:-86.31737039},
{x:42.10419336,y:-88.29152718},
{x:40.4880067,y:-86.16089817},
{x:47.17102774,y:-122.51696438},
{x:29.7737169,y:-95.21583157},
{x:37.51555322,y:-77.79226361},
{x:38.40499368,y:-78.86817157},
{x:36.62684875,y:-79.40085395},
{x:47.24275914,y:-122.36008734},
{x:32.80953457,y:-98.09530337},
{x:29.70186474,y:-95.3604912},
{x:38.96203015,y:-94.97963485},
{x:43.10850665,y:-88.49130303},
{x:36.9497366,y:-86.42412145},
{x:30.47729121,y:-90.86382245},
{x:39.78420926,y:-85.7847999},
{x:41.98198548,y:-91.66368036},
{x:40.926046,y:-90.64926088},
{x:32.62397866,y:-93.25409085},
{x:21.3446954,y:-157.92784153},
{x:37.03819121,y:-76.31398652},
{x:43.2507114,y:-89.37052269},
{x:46.6043,y:-122.909},
{x:38.13849448,y:-79.06686638},
{x:38.23607336,y:-86.12811059},
{x:29.53319453,y:-95.80872602},
{x:36.84317284,y:-76.13628656},
{x:40.64035224,y:-111.88812217},
{x:47.71785729,y:-117.41070358},
{x:40.307886,y:-88.171828},
{x:32.79453726,y:-96.61146551},
{x:31.48733021,y:-97.21224226},
{x:39.91127433,y:-86.21906389},
{x:42.34238663,y:-71.12197217},
{x:21.4938288,y:-158.02862316},
{x:38.24499927,y:-85.52281111},
{x:40.34868003,y:-111.72869175},
{x:41.22321898,y:-89.92787768},
{x:37.05580999,y:-88.57387103},
{x:37.35196367,y:-77.41273105},
{x:46.1555938,y:-122.9823091},
{x:31.43304153,y:-97.73105},
{x:31.60572567,y:-97.10718066},
{x:40.62400716,y:-111.83524762},
{x:43.0896735,y:-87.90881078},
{x:33.94282699,y:-83.74293341},
{x:41.63436353,y:-85.04449949},
{x:40.6822,y:-111.941},
{x:39.36198917,y:-78.04353755},
{x:40.77199275,y:-111.94102147},
{x:40.6954822,y:-112.09121619},
{x:38.6307909,y:-77.27446049},
{x:38.75065214,y:-112.10171644},
{x:41.67910169,y:-87.72018933},
{x:41.2903,y:-87.3892},
{x:44.40613613,y:-88.74084771},
{x:47.75625303,y:-122.65977742},
{x:29.74932824,y:-95.41037879},
{x:39.77129309,y:-86.05555966},
{x:41.47043335,y:-87.30208221},
{x:40.95741204,y:-90.38369961},
{x:41.6278679,y:-87.9310904},
{x:43.14774022,y:-93.21318904},
{x:44.03973967,y:-88.54228451},
{x:38.68518768,y:-85.78374021},
{x:41.90578449,y:-71.04720801},
{x:36.79260452,y:-76.12014047},
{x:37.90480258,y:-76.86511114},
{x:39.61394752,y:-86.08004032},
{x:31.29873015,y:-94.72857426},
{x:37.9854168,y:-87.60085657},
{x:30.41924152,y:-91.14091024},
{x:42.59256638,y:-88.41460951},
{x:37.36177513,y:-81.09689802},
{x:41.9366534,y:-87.64410035},
{x:41.93840055,y:-87.72226234},
{x:37.93621142,y:-86.75634317},
{x:41.97710735,y:-88.3414158},
{x:46.72933065,y:-122.97925651},
{x:47.83436888,y:-122.20979044},
{x:43.38085215,y:-87.93000211},
{x:41.86008552,y:-87.98115359},
{x:41.75237694,y:-87.97330946},
{x:46.69671563,y:-92.10490125},
{x:38.43421347,y:-78.84534985},
{x:39.86184932,y:-84.88810853},
{x:30.51960733,y:-90.50046802},
{x:40.17032146,y:-111.61104539},
{x:47.8773262,y:-122.16392416},
{x:42.54793733,y:-70.94245353},
{x:39.10354469,y:-90.31481463},
{x:39.0279147,y:-84.45141479},
{x:39.6286229,y:-86.1212601},
{x:42.50986822,y:-96.41639463},
{x:41.61953277,y:-87.84852095},
{x:41.09565029,y:-85.08762252},
{x:26.1723125,y:-97.67359883},
{x:36.776967,y:-76.5818606},
{x:31.12389032,y:-93.26843356},
{x:30.39584897,y:-91.11024357},
{x:31.33367187,y:-92.41431094},
{x:41.46794882,y:-87.57564756},
{x:33.59199791,y:-101.85568033},
{x:44.28763497,y:-88.41538538},
{x:38.16611382,y:-85.6016419},
{x:41.69621457,y:-87.5183766},
{x:39.5540022,y:-86.08774316},
{x:41.97223363,y:-87.80749245},
{x:27.88095,y:-97.320345},
{x:38.08611716,y:-97.9070794},
{x:41.8507,y:-87.7642},
{x:42.91819886,y:-88.10933836},
{x:38.80064833,y:-97.61193114},
{x:38.97499345,y:-84.66095392},
{x:42.82668889,y:-106.36146882},
{x:44.5916,y:-88.0749},
{x:33.07297811,y:-96.77162826},
{x:30.5775382,y:-97.87135954},
{x:35.19108877,y:-101.92092066},
{x:41.329244,y:-87.199578},
{x:37.83531674,y:-87.59120799},
{x:42.31036604,y:-88.43329583},
{x:41.12184544,y:-85.06201481},
{x:30.11430881,y:-93.81934807},
{x:41.88408488,y:-87.65141171},
{x:20.73471754,y:-156.45280343},
{x:41.90817139,y:-87.67356382},
{x:32.01759218,y:-97.09449837},
{x:36.72138429,y:-76.30591735},
{x:39.16670097,y:-78.16482774},
{x:40.91972405,y:-111.89017524},
{x:39.82440991,y:-86.23580396},
{x:37.68054176,y:-113.07335973},
{x:40.46116947,y:-88.96145261},
{x:41.51951355,y:-88.1249983},
{x:38.73929975,y:-77.47251213},
{x:42.1166095,y:-88.03433964},
{x:38.32125013,y:-81.71617329},
{x:30.19668334,y:-97.78586403},
{x:29.91411265,y:-90.04801919},
{x:32.59631484,y:-96.94149256},
{x:32.74640944,y:-96.99494535},
{x:37.92789393,y:-89.80501034},
{x:30.19797695,y:-93.20997489},
{x:40.69944661,y:-111.80973847},
{x:35.54269863,y:-100.97153653},
{x:29.94200623,y:-90.09450147},
{x:37.2758448,y:-76.49906968},
{x:42.59595044,y:-114.45469871},
{x:43.07508679,y:-88.47162165},
{x:42.47049081,y:-88.0943782},
{x:30.3211151,y:-95.48694804},
{x:30.21108708,y:-90.94162457},
{x:41.71567814,y:-87.78261208},
{x:47.62750624,y:-122.62845857},
{x:38.21957324,y:-85.62701762},
{x:38.41230686,y:-82.34324947},
{x:36.70257577,y:-81.97497547},
{x:47.65317818,y:-117.42466452},
{x:30.20642088,y:-95.46482022},
{x:37.12075502,y:-82.84319463},
{x:41.57552342,y:-93.6253392},
{x:41.68887206,y:-93.77001541},
{x:30.58170859,y:-98.28072012},
{x:36.82376785,y:-76.40973054},
{x:37.69361795,y:-97.44749127},
{x:41.0396379,y:-111.946692},
{x:41.88538117,y:-87.62653448},
{x:41.783371,y:-87.907452},
{x:41.78638945,y:-88.07082304},
{x:43.63834978,y:-116.35372096},
{x:39.72287841,y:-90.23925322},
{x:30.02228762,y:-95.52080216},
{x:46.20978836,y:-119.15847237},
{x:38.13273589,y:-77.51538921},
{x:41.68732116,y:-93.07632639},
{x:37.27974939,y:-79.8671004},
{x:40.03095794,y:-85.99595263},
{x:29.63969963,y:-98.45387499},
{x:33.39142966,y:-84.77140823},
{x:26.26344977,y:-98.22030142},
{x:33.09916413,y:-96.73289269},
{x:41.07413861,y:-85.20495594},
{x:37.72270096,y:-97.42752491},
{x:38.57186876,y:-94.85934421},
{x:37.75765018,y:-87.14294542},
{x:42.70407752,y:-88.24703425},
{x:40.19990125,y:-85.41168901},
{x:32.84992733,y:-96.68352172},
{x:40.47666036,y:-86.13263426},
{x:38.68443164,y:-77.31613203},
{x:32.39531347,y:-93.81155804},
{x:32.91962179,y:-96.51729316},
{x:37.75570855,y:-97.46239364},
{x:33.80207382,y:-84.41471279},
{x:38.24932806,y:-77.49833912},
{x:36.58751042,y:-79.4211701},
{x:31.68060114,y:-106.31377459},
{x:42.03226111,y:-87.7468881},
{x:38.02693199,y:-84.53359097},
{x:30.79127869,y:-89.86143172},
{x:32.65344113,y:-96.75167264},
{x:33.77267955,y:-84.37298566},
{x:41.74971064,y:-87.68320069},
{x:45.67411409,y:-122.66385846},
{x:34.91287467,y:-85.12561384},
{x:37.34224726,y:-95.25867769},
{x:39.76228259,y:-86.4025889},
{x:42.31931421,y:-88.97937814},
{x:30.43320531,y:-91.08237761},
{x:43.34393069,y:-89.02861159},
{x:31.12476709,y:-97.74096273},
{x:32.34490341,y:-95.3411195},
{x:47.1997945,y:-121.98721417},
{x:39.11441369,y:-77.53883035},
{x:30.24392708,y:-92.0657368},
{x:33.2834,y:-96.5621},
{x:40.72583965,y:-89.63244103},
{x:39.097847,y:-94.88267143},
{x:36.92995084,y:-76.31843777},
{x:38.05872767,y:-78.94360366},
{x:36.72095996,y:-78.11828227},
{x:36.81056265,y:-76.36590088},
{x:33.1989136,y:-97.13740741},
{x:32.36939181,y:-95.32981043},
{x:42.240347,y:-88.819067},
{x:39.03836518,y:-95.76130165},
{x:30.12240543,y:-95.40125273},
{x:42.31746535,y:-89.03422668},
{x:41.28502642,y:-86.62113011},
{x:29.48239457,y:-98.35788464},
{x:32.30916342,y:-95.47225647},
{x:31.47692194,y:-100.45417343},
{x:41.26495813,y:-95.84171672},
{x:40.3992192,y:-80.55008676},
{x:45.15553303,y:-89.14767221},
{x:37.50830516,y:-77.18790511},
{x:42.89428856,y:-87.91265396},
{x:41.44304024,y:-85.97887878},
{x:39.05537022,y:-94.61215288},
{x:37.22883772,y:-96.99745685},
{x:30.22515118,y:-93.32512669},
{x:40.48087234,y:-85.5526176},
{x:37.56315042,y:-77.37705763},
{x:39.5611418,y:-95.12714035},
{x:30.1952961,y:-93.17942445},
{x:38.03057834,y:-84.91246597},
{x:38.23918502,y:-85.72191149},
{x:32.53299637,y:-93.67091275},
{x:36.63353267,y:-82.12641589},
{x:31.32629629,y:-94.72591688},
{x:32.53018937,y:-92.07969884},
{x:39.00041636,y:-84.65403626},
{x:40.25095763,y:-111.66174657},
{x:38.00496766,y:-80.74106732},
{x:37.78693462,y:-89.02815923},
{x:29.57182686,y:-98.23525706},
{x:41.61720493,y:-87.04461604},
{x:35.22152679,y:-101.8312473},
{x:39.03724533,y:-87.14669782},
{x:42.19778915,y:-72.57321958}];