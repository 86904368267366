import React from 'react';
import { useDispatch } from 'react-redux';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

import schedulesService from 'services/schedules.service';
import { schoolsActions } from 'store/actions/actions';
import { getDateFormat } from 'utils/utilities';

import { handleScheduleChange, regenerateRotationData } from './utils';

const SchoolCancel = ({ school, dayInfo, day, onRegenerate }) => {
  const dispatch = useDispatch();

  const cancelDaySchedule = (date) => {
    schedulesService()
      .cancelSchool(school.school_name, date)
      .then(() => {
        dispatch(schoolsActions.getSchoolById(school.school_name));
      });
    return date;
  };
  return (
    <div className="popover-container-actions">
      <Button
        variant="danger"
        onClick={() => {
          if (dayInfo) {
            handleScheduleChange(
              school,
              dayInfo,
              day,
              () => {
                cancelDaySchedule(getDateFormat(day, 'YYYY-MM-DD'));
              },
              () => {
                regenerateRotationData(
                  onRegenerate,
                  school,
                  'X',
                  dayInfo.dateNow,
                  true,
                );
              },
            );
          } else {
            cancelDaySchedule(getDateFormat(day, 'YYYY-MM-DD'));
          }
        }}
      >
        <FontAwesomeIcon icon={faCalendarTimes} />
        &nbsp;&nbsp;
        <b>Cancel School</b>
      </Button>
    </div>
  );
};

export default SchoolCancel;
