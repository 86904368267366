const ActionTypes = {
  GET_USER_REPORTS: 'ContentReports.GET_USER_REPORTS',
  GET_USER_REPORTS_SUCCESS: 'ContentReports.GET_USER_REPORTS_SUCCESS',
  GET_USER_REPORTS_FAIL: 'ContentReports.GET_USER_REPORTS_FAIL',
};

const getUserProfileReports = (params) => ({
  type: ActionTypes.GET_USER_REPORTS,
  params,
});

const actions = { getUserProfileReports };

export { ActionTypes, actions };
