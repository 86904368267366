import React, { Component } from 'react';
import './TagInputField.scss';
import { WithContext as ReactTags } from 'react-tag-input';
import Form from 'react-bootstrap/Form';
import PlaceholderUp from '../PlaceholderUp/PlaceholderUp';
import FieldError from '../FieldError/FieldError';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

export default class TagInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.field.currentValue.map((x) => {
        return { id: `${x}`, text: x };
      }),
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);

    this.updateValue = this.updateValue.bind(this);
    this.updateBlur = this.updateBlur.bind(this);
    this.isFieldInvalid = this.isFieldInvalid.bind(this);
    this.updateValue();
  }

  isFieldInvalid() {
    return this.props.field.blurred === true && !this.props.field.valid;
  }

  updateBlur() {
    this.props.field.blurred = true;
    this.props.updateParent(this.props.field.currentValue, this.props.field);
  }

  updateValue() {
    const { tags } = this.state;
    const newValue = tags.map((x) => x.text);
    this.validate(newValue);
    this.props.updateParent(newValue, this.props.field);
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState(
      {
        tags: tags.filter((tag, index) => index !== i),
      },
      () => {
        this.updateValue();
      },
    );
  }

  handleAddition(tag) {
    this.setState(
      (state) => ({ tags: [...state.tags, tag] }),
      () => {
        this.updateValue();
      },
    );
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags }, () => {
      this.updateValue();
    });
  }

  validate(value) {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  }

  render() {
    const { tags } = this.state;
    const { suggestions, allowDuplicates = false } = this.props.field;

    return (
      <Form.Group controlId={this.props.field.name}>
        {this.props.field.isShowLabel && (
          <Form.Label>{this.props.field.name}</Form.Label>
        )}
        <ReactTags
          tags={tags}
          inputFieldPosition="top"
          placeholder={this.props.field.placeholder}
          suggestions={
            suggestions &&
            [...suggestions].map((suggestion) => ({
              id: suggestion,
              text: suggestion,
            }))
          }
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          delimiters={delimiters}
          handleInputBlur={this.updateBlur}
          renderSuggestion={({ text }) => <span>{text}</span>}
          allowUnique={!allowDuplicates}
        />
        {this.props.field.isPlaceholderUp && this.props.field.currentValue && (
          <PlaceholderUp label={this.props.field.name} />
        )}
        <FieldError field={this.props.field} />
      </Form.Group>
    );
  }
}
