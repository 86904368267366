import React, { PureComponent } from 'react';
import { networkActions } from 'store/actions/actions';
import { hasAccessTo } from 'store/selectors/auth';
import { getColumns } from './ColumnFormatters';
import { connect } from 'react-redux';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import { liteLevels } from 'constants/statuses';

const defaultSorted = {
  dataField: 'school_title',
  order: 'asc',
  filter_by: 'status:eq:live',
};

class MissingSchedulesTable extends PureComponent {
  columns;
  tableOptions;

  constructor(props) {
    super(props);

    this.getColumns = getColumns.bind(this);

    this.state = {
      enableFilters: false,
      totalSize: 0,
      total_user_count: 0,
      query_params: null,
      selectedReport: null,
      showSelectGroupModal: false,
      photoIndex: 0,
      lite_level: null,
    };

    this.tableOptions = {
      hasToggle: false,
      hasSearch: true,
      searchPlaceholder: 'Search for any field...',
      shouldDisplayCount: true,
      counterComponent: this.getCounts,
      hasFilterDropdown: true,
      filterPlaceholder: 'Select a lite level...',
      filterOptions: [
        { value: null, label: 'Live' },
        ...liteLevels.map((i) => ({ value: i.id, label: i.name })),
      ],
      onFilterPick: (option) => {
        this.setState({ lite_level: option.value }, () => {
          this.loadMissingSchedules();
        });
      },
    };
  }

  componentDidMount() {
    this.loadMissingSchedules();
  }

  static getDerivedStateFromProps(props, currentState) {
    return {
      ...currentState,
      totalSize: props.total || 0,
      total_user_count: props.total_user_count || 0,
    };
  }

  loadMissingSchedules() {
    const { hasScheduleManagementScope, dispatch } = this.props;
    if (hasScheduleManagementScope && dispatch) {
      dispatch(networkActions.getMissingSchedules(this.state.lite_level));
    }
  }

  handleTableChange = (params) => {
    this.setState(
      (state) => ({ ...state, query_params: params }),
      () => {
        this.loadMissingSchedules();
      },
    );
  };

  getCounts = () => {
    return (
      <h1>
        <span className="table-schools-count">
          {this.props.missing_schedules.length}
        </span>
        Missing Schedules
      </h1>
    );
  };

  render() {
    const { totalSize, selectedReport, photoIndex } = this.state;
    const { missing_schedules, loading_missing_schedules } = this.props;

    return (
      <AdvancedTable
        keyField="key"
        options={{
          ...this.tableOptions,
          disableUpload: this.props.data ? this.props.data.uploading : false,
        }}
        data={missing_schedules || []}
        columns={this.getColumns()}
        loading={loading_missing_schedules}
        defaultSorted={defaultSorted}
        refreshHandler={this.handleTableChange}
        total={totalSize}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loading_missing_schedules: state.network.loading_missing_schedules,
  missing_schedules: state.network.missing_schedules,
  missing_schedules_loaded: state.network.missing_schedules_loaded,
  hasSchoolManagementScope: hasAccessTo(state.auth.scopes, [
    'school_management',
  ]),
  hasScheduleManagementScope: hasAccessTo(state.auth.scopes, [
    'schedule_management',
  ]),
});

export default connect(mapStateToProps)(MissingSchedulesTable);
