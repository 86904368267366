import type { Step } from 'features/scheduleConfiguration/types';

import UploadImagesReview from '../steps/uploadImages/UploadImagesReview';
import ScheduleSettingsReview from '../steps/scheduleSettings/ScheduleSettingsReview';
import CalendarSettingsReview from '../steps/calendarSettings/CalendarSettingsReview';
import CoreSchedulesReview from '../steps/coreSchedules/CoreSchedulesReview';

interface Props {
  step: Step;
}

const WizardReviewStep = ({ step }: Props) => {
  const Component = REVIEW_STEPS[step.type];

  return <Component step={step} />;
};

const REVIEW_STEPS: Record<Step['type'], ({ step }: Props) => JSX.Element> = {
  uploadImagesStep: UploadImagesReview,
  scheduleSettingsStep: ScheduleSettingsReview,
  calendarSettingsStep: CalendarSettingsReview,
  coreSchedulesStep: CoreSchedulesReview,
};

export default WizardReviewStep;
