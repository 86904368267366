import React, { PureComponent, useState } from 'react';
import {
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
  Form,
} from 'react-bootstrap';
import moderationService from 'services/moderation.service';

function DeletePopover() {
  const [deleting, setDeleting] = useState(false);
  const [id, setId] = useState(null);
  const [content_type, setContentType] = useState('message');
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <div className="delete-confirm">
        <h5 className="text-center text-capitalize">{content_type} deleted</h5>
        <Button
          variant="danger"
          className="next-btn"
          onClick={() => {
            setSuccess(false);
            setDeleting(false);
            setId(null);
          }}
        >
          Delete Another
        </Button>
      </div>
    );
  }

  const deleteContent = () => {
    setDeleting(true);

    if (content_type === 'message') {
      moderationService()
        .deleteChatMessage(id)
        .then((response) => {
          setSuccess(true);
        })
        .catch(() => {
          setSuccess(true);
        });
    } else {
      moderationService()
        .deleteChat(id)
        .then((response) => {
          setSuccess(true);
        })
        .catch(() => {
          setSuccess(true);
        });
    }
  };

  return (
    <div className="delete-post-container">
      <Form>
        <Form.Group>
          <Form.Check
            inline
            type="radio"
            name="content_type"
            value="message"
            label="Message"
            id="message"
            checked={content_type === 'message'}
            onChange={({ target }) => {
              setContentType(target.value);
            }}
          />
          <Form.Check
            inline
            type="radio"
            name="content_type"
            value="chat"
            label="Chat"
            id="chat"
            checked={content_type === 'chat'}
            onChange={({ target }) => {
              setContentType(target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="text-capitalize"
            placeholder={`${content_type} ID`}
            value={id}
            onChange={(event) => setId(event.target.value)}
          />
        </Form.Group>
        <Button
          variant="danger"
          type="submit"
          className="next-btn text-capitalize"
          disabled={id === null || deleting}
          onClick={deleteContent}
        >
          {deleting ? <Spinner animation="grow" /> : `Delete ${content_type}`}
        </Button>
      </Form>
    </div>
  );
}

export default class QuickContentDelete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: true,
      uploading: true,
      finished: false,
    };

    this.renderTooltip = this.renderTooltip.bind(this);
  }

  renderTooltip = (props) => {
    return (
      <Popover id="suspension-popover" {...props} show={props.show.toString()}>
        <DeletePopover
          onDeleted={(url) => {
            this.setState({ url: url });
          }}
        />
      </Popover>
    );
  };

  render() {
    return (
      <OverlayTrigger
        placement="bottom"
        trigger="click"
        rootClose
        overlay={this.renderTooltip}
      >
        <Button variant="danger" style={{ marginLeft: 10 }}>
          Delete Chats/Messages
        </Button>
      </OverlayTrigger>
    );
  }
}
