import * as M from '@mantine/core';

import type { Step } from 'features/scheduleConfiguration/types';
import { formatDate } from 'hooks/utils';

interface Props {
  step: Step;
}

const CalendarSettingsReview = ({ step }: Props) => {
  if (step.type !== 'calendarSettingsStep' || !step.payload) {
    return <></>;
  }

  return (
    <div>
      <M.Title order={6}>Rotation Pattern:</M.Title>
      <M.Text>{step.payload.rotationPattern.join(', ') || '-'}</M.Text>

      <M.Title order={6}>School year start:</M.Title>
      <M.Text>{formatDate(step.payload.schoolYearStart)}</M.Text>

      <M.Title order={6}>School year end:</M.Title>
      <M.Text>{formatDate(step.payload.schoolYearEnd)}</M.Text>
    </div>
  );
};

export default CalendarSettingsReview;
