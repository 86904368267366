import { useState, useEffect } from 'react';
import * as M from '@mantine/core';

import type { Period, Schedule } from 'types';

import PeriodsBuilder from 'features/editSchedule/components/periodsBuilder/PeriodsBuilder';
import { CUSTOM_PERIODS } from 'features/scheduleConfiguration/constants';
import { useSchool } from 'hooks/useSchool';
import { StepContainer } from '../stepLayout/StepContainer';
import { StepHeader } from '../stepLayout/StepHeader';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { stepCreator, useWizard } from '../stepUtils';

type Payload = Schedule['periods'];

const periodsStep = stepCreator.create<Payload>()({
  key: 'periods',
  validate: (state) => !!state.schedule?.periods?.length,
  payloadReducer: (payload) => (prev) => ({
    ...prev,
    schedule: { ...prev.schedule, periods: payload },
  }),
  render: () => <PeriodsStep />,
});

const PeriodsStep = () => {
  const { saveStep, next, state } = useWizard(({ saveStep, next, state }) => ({
    saveStep,
    next,
    state,
  }));

  const { data: school } = useSchool();

  const [periods, setPeriods] = useState<Period[]>(
    state.schedule?.periods ?? [],
  );

  useEffect(() => {
    if (periods) {
      saveStep(periodsStep.payloadReducer(periods));
    }
  }, [periods]);

  if (!school) return <M.Loader />;

  if (!state.schedule?.variant) return null;

  const dayTypeId =
    (state.schedule.variant === 'core' ||
      state.schedule.variant === 'modified') &&
    state.schedule.dayType?.id;

  const dayType = school.dayTypes.find((dt) => dt.id === dayTypeId);

  const handleClick = () => {
    saveStep(periodsStep.payloadReducer(periods));
    next();
  };

  return (
    <StepContainer>
      <StepHeader
        title="Period configuration"
        description="Add periods to your schedule"
      />

      <PeriodsBuilder
        dayTypes={school.dayTypes}
        periodTypes={[
          ...school.periodTypes,
          ...(!school.lunchSettings.type || school.lunchSettings.type === 'unit'
            ? [{ id: '', name: CUSTOM_PERIODS.lunch }]
            : []),
        ]}
        periods={periods}
        setPeriods={setPeriods}
        schedule={state.schedule}
        defaultDayType={
          state.schedule.variant !== 'special' ? dayType : undefined
        }
      />

      <StepNavigation isValid={!!periods.length} onNext={handleClick} />
    </StepContainer>
  );
};

export default periodsStep;
