import React, { PureComponent, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { states } from 'constants/states';
import { number_comparators } from 'utils/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const SelectFilter = ({ onChange, name, placeholder = null, options }) => {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Label>{name}</Form.Label>
      <Form.Control
        as="select"
        className="filter select-filter form-control  placeholder-selected"
        onChange={(event) => {
          onChange(event.target.value);
        }}
        defaultValue=""
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

const NumberFilter = ({ label, placeholder, onChange }) => {
  const [comparator, setComparator] = useState('=');
  const [value, setValue] = useState(null);

  return (
    <div className="filter number-filter">
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          className="filter select-filter form-control  placeholder-selected"
          onChange={(event) => {
            setComparator(event.target.value);
            if (value) {
              onChange(event.target.value, value);
            }
          }}
          defaultValue="="
        >
          <option value="=">=</option>
          <option value="!=">!=</option>
          <option value=">">&gt;</option>
          <option value=">=">&gt;=</option>
          <option value="<">&lt;</option>
          <option value="<=">&lt;=</option>
        </Form.Control>
        <input
          type="number"
          className="number-filter-input form-control "
          placeholder={placeholder}
          onChange={(event) => {
            setValue(event.target.value);
            onChange(comparator, event.target.value);
          }}
        />
      </Form.Group>
    </div>
  );
};

const TextFilter = ({ placeholder, onChange }) => {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Label>District</Form.Label>
      <input
        type="text"
        className="number-filter-input form-control "
        placeholder={placeholder}
        onChange={(event) => {
          if (event.target.value.length > 2) {
            onChange(event.target.value);
          }
        }}
      />
    </Form.Group>
  );
};

export default class MapFilters extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      selectedType: 'all',
      selectedState: null,
      enrollmentFilter: null,
      userCountFilter: null,
      districtFilter: null,
      filterString: '',
    };
  }

  updateFilter = () => {
    const {
      selectedType,
      selectedState,
      enrollmentFilter,
      userCountFilter,
      districtFilter,
    } = this.state;
    const { onFilterUpdate } = this.props;

    const typeFilter = selectedType ? `private:eq:${selectedType}` : '';
    const stateFilter = selectedState ? `state:eq:${selectedState}` : '';
    let enrollmentFilterQ = '';
    let usersQ = '';

    if (enrollmentFilter && enrollmentFilter.value) {
      enrollmentFilterQ = `students_count:${
        number_comparators[enrollmentFilter.comparator]
      }:${enrollmentFilter.value}`;
    }

    if (userCountFilter && userCountFilter.value) {
      usersQ = `user_count:${number_comparators[userCountFilter.comparator]}:${
        userCountFilter.value
      }`;
    }

    const districtFilterQ = districtFilter
      ? `district_code:ilike:${districtFilter}`
      : '';

    const q = [
      typeFilter,
      stateFilter,
      enrollmentFilterQ,
      usersQ,
      districtFilterQ,
    ]
      .filter((val) => !!val && val !== '')
      .join('|');
    onFilterUpdate(q);
  };

  render() {
    return (
      <div
        className={`map-filter-controls ${
          this.state.showFilters ? '' : 'hide'
        }`}
      >
        <div className="controls">
          <Row>
            <SelectFilter
              name="Public/Private"
              options={[
                { label: 'All', value: '' },
                { label: 'Public', value: false },
                { label: 'Private', value: true },
              ]}
              onChange={(value) => {
                this.setState({ selectedType: value }, () => {
                  this.updateFilter();
                });
              }}
            />
          </Row>
          <Row>
            <SelectFilter
              name="State"
              placeholder="Select state..."
              options={Object.keys(states).map((state_code) => {
                return { label: states[state_code], value: state_code };
              })}
              onChange={(value) => {
                this.setState({ selectedState: value }, () => {
                  this.updateFilter();
                });
              }}
            />
          </Row>
          <Row>
            <TextFilter
              placeholder="Enter district"
              onChange={(value) => {
                this.setState({ districtFilter: value }, () => {
                  this.updateFilter();
                });
              }}
            />
          </Row>
          <Row>
            <NumberFilter
              placeholder="Enter Enrollment"
              label="Enrollment"
              onChange={(comparator, value) => {
                this.setState(
                  { enrollmentFilter: { comparator, value } },
                  () => {
                    this.updateFilter();
                  },
                );
              }}
            />
          </Row>
          <Row>
            <NumberFilter
              placeholder="Enter Users"
              label="Users"
              onChange={(comparator, value) => {
                this.setState(
                  { userCountFilter: { comparator, value } },
                  () => {
                    this.updateFilter();
                  },
                );
              }}
            />
          </Row>
        </div>
        <div>
          <Button
            className="btn-toggle-filters"
            onClick={() =>
              this.setState({ showFilters: !this.state.showFilters })
            }
          >
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </div>
      </div>
    );
  }
}
