import * as M from '@mantine/core';

type Props = {
  error: Error;
};

export const TabErrorAlert = ({ error }: Props) => {
  return (
    <M.Container py="lg">
      <M.Alert color="red" title="Error">
        {error.message}
      </M.Alert>
    </M.Container>
  );
};
