import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './StatusMenu.scss';
import { statuses } from 'constants/statuses';
import schoolsService from 'services/schools.service';
import Chip from '@material-ui/core/Chip';
import { Spinner } from 'react-bootstrap';

const options = statuses;

export default function StatusMenu(props) {
  const { school, currentStatus } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(currentStatus);
  const [loading, setLoading] = React.useState(false);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedStatus(options[index].id);
    setLoading(true);
    schoolsService()
      .updateSchool(school.school_name, { status: options[index].id })
      .then(() => {
        setLoading(false);
      });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const status = options.filter((status) => status.id === selectedStatus)[0];

  return (
    <div className="root">
      <Chip
        label={status.name}
        className={`status-cell ${status.id}`}
        onClick={handleClickListItem}
      />
      {loading && (
        <Spinner
          animation="grow"
          role="status"
          className="loading-ripple"
          size="sm"
        />
      )}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem key={-1} disabled className="title">
          <b>Set School Status</b>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={option.id === selectedStatus}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
