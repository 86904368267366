import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import MantineProvider from 'providers/MantineProvider';
import PageHeader from 'features/components/PageHeader';
import DraftSchedulesTable from 'features/draftSchedules/components/DraftSchedulesTable';
import DraftSchedulesModal from 'features/draftSchedules/components/DraftSchedulesModal';
import type { Schedule } from 'types';

const DraftSchedules = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();

  return (
    <MantineProvider>
      <PageHeader title="Draft schedules" />
      <Container>
        <DraftSchedulesTable
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setSelectedSchedule={setSelectedSchedule}
        />

        {selectedSchedule && (
          <DraftSchedulesModal
            selectedSchedule={selectedSchedule}
            onClose={() => setSelectedIndex(-1)}
            shouldEditInNewTab
          />
        )}
      </Container>
    </MantineProvider>
  );
};

const Container = styled(M.Container)`
  padding: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.md};
`;

export default DraftSchedules;
