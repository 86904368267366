import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { ParsedSchedule } from 'features/scheduleConfiguration/utils';

interface Props {
  parsedSchedule: ParsedSchedule;
  isSelected: boolean;
  onClick: () => void;
}

const ParsedResultsRow = ({ parsedSchedule, isSelected, onClick }: Props) => {
  const [selectedImage, setSelectedImage] = useState<string>();

  return (
    <>
      <Container gutter="xl" isSelected={isSelected} onClick={onClick}>
        <M.Grid.Col span={2}>
          <M.Text weight="bold">{parsedSchedule.title}</M.Text>
        </M.Grid.Col>
        <M.Grid.Col span={6}>
          <M.Table>
            <thead>
              <tr>
                <th>Period</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>

            <tbody>
              {parsedSchedule.periods.map((period, i) => (
                <tr key={i}>
                  <td>{period.name}</td>
                  <td>{period.startTime}</td>
                  <td>{period.endTime}</td>
                </tr>
              ))}
              {!parsedSchedule.isComplete && (
                <tr>
                  <ParsingIncompleteMessage colSpan={3}>
                    The image is still processing...
                  </ParsingIncompleteMessage>
                </tr>
              )}
            </tbody>
          </M.Table>
        </M.Grid.Col>
        <M.Grid.Col span={4}>
          <Thumbnail
            src={parsedSchedule.url}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedImage(parsedSchedule.url);
            }}
          />
        </M.Grid.Col>
      </Container>
      <M.Modal
        size="xl"
        opened={!!selectedImage}
        onClose={() => setSelectedImage(undefined)}
        withCloseButton={false}
      >
        <M.Image
          src={selectedImage}
          onClick={() => setSelectedImage(undefined)}
        />
      </M.Modal>
    </>
  );
};

const Container = styled(M.Grid)<{ isSelected: boolean }>`
  align-items: center;
  padding: ${(p) => p.theme.spacing.xs};
  margin: 0;
  border-radius: 0.5rem;
  background-color: ${(p) =>
    p.isSelected ? p.theme.colors.blue[0] : 'transparent'};

  &:hover {
    cursor: pointer;
    background-color: ${(p) =>
      p.isSelected ? p.theme.colors.blue[1] : p.theme.colors.gray[1]};
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const ParsingIncompleteMessage = styled.td`
  text-align: center;
  color: ${(p) => p.theme.colors.orange[3]};
`;

export default ParsedResultsRow;
