import apiClient from 'utils/apiClient';

const baseUrl = 'v2/network/reports';

const getNetworkStats = () => apiClient.get('v2/network/stats');

const getMissingResources = (resource_type, school_id = null, params = {}) => {
  if (school_id) {
    params.school_id = school_id;
  }
  return apiClient.get(`v2/network/reports/${resource_type}`, {
    params,
  });
};

const networkService = () => ({ getNetworkStats, getMissingResources });

export default networkService;
