import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import RemoveIcon from './RemoveIcon';

import { parseTime } from '../utils';

interface Props {
  descriptionHeaders: string[];
  descriptionData: string[][];
  editIndex: number;
  setEditIndex: React.Dispatch<React.SetStateAction<number>>;
  getStartTime: (index: number) => string;
  getEndTime: (index: number) => string;
  getIsCustom?: (index: number) => boolean;
  getDuration: (index: number) => string;
  getIsOverlapping?: (index: number) => boolean;
  onRemove: (index: number) => void;
  popoverDropdown: React.ReactElement;
  canOverlap?: boolean;
}

const EditableTable = ({
  descriptionHeaders: headers,
  descriptionData: data,
  editIndex,
  setEditIndex,
  getStartTime,
  getEndTime,
  getDuration,
  getIsOverlapping,
  getIsCustom = () => false,
  onRemove,
  popoverDropdown,
  canOverlap = false,
}: Props) => {
  const getIsStartAfterEnd = (i: number) =>
    dayjs(parseTime(getStartTime(i))).isAfter(dayjs(parseTime(getEndTime(i))));

  return (
    <Table>
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
          <th>Start time</th>
          <th>End time</th>
          <th>Period length</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {!data.length ? (
          <tr>
            <td colSpan={headers.length + 3}>
              <M.Text align="center">No items added</M.Text>
            </td>
          </tr>
        ) : (
          data.map((row, i) => (
            <M.Popover
              key={i}
              withArrow
              width="target"
              opened={editIndex !== -1}
              onChange={(value) => !value && setEditIndex(-1)}
            >
              <M.Popover.Target>
                <Row
                  onClick={() => setEditIndex(i)}
                  isError={
                    getIsStartAfterEnd(i) ||
                    (!canOverlap && !!getIsOverlapping?.(i))
                  }
                  isWarning={
                    getIsCustom(i) || (canOverlap && !!getIsOverlapping?.(i))
                  }
                >
                  {row.map((cell, j) => (
                    <td key={j}>{cell}</td>
                  ))}
                  <td>{getStartTime(i)}</td>
                  <td>{getEndTime(i)}</td>
                  <td>{getDuration(i)}</td>
                  <td>
                    <RemoveIcon
                      data-testid="remove-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemove(i);
                      }}
                    />
                  </td>
                </Row>
              </M.Popover.Target>
              {editIndex === i && popoverDropdown}
            </M.Popover>
          ))
        )}
      </tbody>
    </Table>
  );
};

const Table = styled(M.Table)`
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  thead {
    background-color: ${(p) => p.theme.colors.gray[2]};

    tr th {
      color: #000;
    }
  }

  tbody tr td:last-child {
    text-align: center;
  }
`;

const Row = styled.tr<{ isError: boolean; isWarning: boolean }>`
  background-color: ${(p) =>
    p.isError
      ? p.theme.colors.red[1]
      : p.isWarning
      ? p.theme.colors.yellow[0]
      : 'transparent'};

  &:hover {
    background-color: ${(p) =>
      p.isError
        ? p.theme.colors.red[2]
        : p.isWarning
        ? p.theme.colors.yellow[1]
        : p.theme.colors.gray[0]};
  }
`;

export default EditableTable;
