import dayjs from 'dayjs';

import { parseTime } from 'features/editSchedule/utils';

interface BaseMessage {
  id: number;
}
interface ScheduleTitlesMessage extends BaseMessage {
  event: 'schedule_titles';
  data: {
    schedule_titles: string[];
  };
}

interface ScheduleStartMessage extends BaseMessage {
  event: 'schedule_start';
  data: {
    schedule_start: string;
  };
}

interface ScheduleMessage extends BaseMessage {
  event: 'schedule';
  data: {
    schedule: {
      [schedule: string]: {
        period: string;
        start: string;
        end: string;

        lunch?: {
          wave: string;
          start: string;
          end: string;
        }[];
      };
    };
  };
}

interface CompletedMessage extends BaseMessage {
  event: 'completed';
}

export type AiGenResults = (
  | ScheduleTitlesMessage
  | ScheduleStartMessage
  | ScheduleMessage
  | CompletedMessage
)[];

export interface ParsedSchedule {
  genId: string;
  url: string;
  title: string;
  periods: {
    name: string;
    startTime: string;
    endTime: string;
  }[];
  lunches: {
    name: string;
    startTime: string;
    endTime: string;
  }[];
  isComplete: boolean;
  id: string;
}

const formatTime = (time: string) => dayjs(time, 'HH:mm').format('h:mm a');

const sort = (a: string, b: string) =>
  parseTime(a).getTime() - parseTime(b).getTime();

export const parseAiGenResults = ({
  genId,
  url,
  aiGenResults,
}: {
  genId: string;
  url: string;
  aiGenResults: AiGenResults;
}): ParsedSchedule[] => {
  const scheduleTitlesMessage = aiGenResults.find(
    (m) => m.event === 'schedule_titles',
  ) as ScheduleTitlesMessage | undefined;

  if (!scheduleTitlesMessage?.data) {
    return [];
  }

  return scheduleTitlesMessage.data.schedule_titles.map((title, index) => {
    const scheduleMessages = aiGenResults.filter(
      (m): m is ScheduleMessage =>
        m.event === 'schedule' &&
        m.data.schedule &&
        Object.keys(m.data.schedule)[0] === title,
    );

    const periods = scheduleMessages
      .filter((m) => m.data.schedule[title].period)
      .map((m) => {
        const { period, start, end } = m.data.schedule[title];

        return {
          name: period,
          startTime: formatTime(start),
          endTime: formatTime(end),
        };
      })
      .sort((a, b) => sort(a.startTime, b.startTime));

    const lunches = scheduleMessages
      .filter((m) => m.data.schedule[title].lunch)
      .flatMap((m) => {
        const { lunch } = m.data.schedule[title];

        return lunch!.map((l) => ({
          name: l.wave,
          startTime: formatTime(l.start),
          endTime: formatTime(l.end),
        }));
      })
      .sort((a, b) => sort(a.startTime, b.startTime));

    const isComplete = !!aiGenResults.find((m) => m.event === 'completed');

    return {
      genId,
      id: genId + '-' + index,
      url,
      title,
      periods,
      lunches,
      isComplete,
    };
  });
};
