import { useQuery } from '@tanstack/react-query';

import { axiosClient, getRequestConfig, getToken } from 'hooks/utils';
import type { MyInfo } from 'types';

const useMyInfo = () => {
  return useQuery(['me', getToken()], {
    queryFn: async () => {
      const response = await axiosClient.get<MyInfo>(
        `${process.env.REACT_APP_URL_API}/v2/user/me`,
        getRequestConfig(),
      );

      if (response.status !== 200) {
        return undefined;
      }

      return response.data;
    },
  });
};

export default useMyInfo;
