import { z } from 'zod';

const optionalWithNullTransform = <T extends z.ZodTypeAny>(x: T) =>
  x
    .nullish()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((x) => x ?? undefined)
    .optional();

export const dayType = z.object({
  id: z.string(),
  name: z.string(),
  isDayType: z.never().optional(),
});

export const periodType = z.object({
  id: z.string(),
  name: z.string(),
  isPeriodType: z.never().optional(),
});

export const basePeriod = z.object({
  id: z.string(),
  name: z.string(),
  startTime: z.string(),
  endTime: z.string(),
});

export const regularPeriod = basePeriod.and(
  z.object({
    dayType,
    periodType,
  }),
);

export const customPeriod = basePeriod.and(
  z.object({
    dayType: z.undefined(),
    periodType: z.undefined(),
  }),
);

export const period = z.union([regularPeriod, customPeriod]);

export const lunchSlot = z.object({
  id: z.string(),
  slot: z.number(),
});

export const lunchWave = z.object({
  id: z.string(),
  name: z.string(),
  slot: lunchSlot,
  startTime: z.string(),
  endTime: z.string(),
});

export const baseSchedule = z.object({
  createdAt: z.date(),
  updatedAt: z.date(),
  author: optionalWithNullTransform(z.string()),
  id: z.string(),
  schoolId: z.string(),
  originalScheduleCode: z.string(),
  genId: optionalWithNullTransform(z.string()),
  image: z
    .object({
      source: z.enum(['user', 'ai']),
      url: z.string(),
    })
    .optional(),
  displayName: z.string(),
  periods: z.array(period),
  lunchWaves: z.array(lunchWave),
  isDraft: z.boolean(),
  isUserDraft: z.boolean(),
  targetDate: optionalWithNullTransform(z.date()),
});

export const dayOfWeek = z.enum(['M', 'T', 'W', 'TH', 'F']);

export const coreVariant = z.object({
  variant: z.literal('core'),
  dayType,
  dayOfWeek: optionalWithNullTransform(dayOfWeek),
  brokenDayOfWeek: optionalWithNullTransform(z.string()),
  order: optionalWithNullTransform(z.number()),
  isInLsc: z.boolean(),
  isInAcf: z.boolean(),
});

export const modification = z.enum([
  'ED',
  'EXAM',
  'LS60',
  'LS90',
  'LS105',
  'LS120',
  'LS150',
  'LS180',
  'HR',
  'ADVISORY',
  'ASSEMBLY',
  'ALL-PERIODS-MEET',
  'ACTIVITY',
  'PEP',
  'OTHER',
]);

export const modifiedVariant = z.object({
  variant: z.literal('modified'),
  dayType,
  isInLsc: z.boolean(),
  modification,
  otherModification: optionalWithNullTransform(z.string()),
});

export const specialVariant = z.object({
  variant: z.literal('special'),
  date: z.date(),
});

export const scheduleVariant = z.union([
  coreVariant,
  modifiedVariant,
  specialVariant,
]);

export const scheduleRequest = baseSchedule
  .omit({
    createdAt: true,
    updatedAt: true,
    author: true,
    id: true,
    originalScheduleCode: true,
  })
  .and(scheduleVariant)
  .and(z.object({ timezone: z.string() }));

export const coreSchedule = baseSchedule.and(coreVariant);
export const modifiedSchedule = baseSchedule.and(modifiedVariant);
export const specialSchedule = baseSchedule.and(specialVariant);

export const schedule = baseSchedule.and(scheduleVariant);
