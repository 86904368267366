import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './ModalSuccess.scss';
import { SavingSpinner } from '../ModalLoading/ModalLoading';

const ModalSuccess = ({ isSuccess, message, close }) => {
  return (
    <div className="modal-result-container">
      <h1>
        <FontAwesomeIcon
          className={isSuccess ? 'success-icon' : 'error-icon'}
          icon={isSuccess ? faCheckCircle : faTimesCircle}
        />
        {message}
      </h1>
      <Button variant={isSuccess ? 'success' : 'error'} onClick={close}>
        Finish
      </Button>
    </div>
  );
};

export default ModalSuccess;

export const ModalResult = ({
  submitting,
  submitted,
  success,
  message,
  onClose,
  displayCloseButton = true,
}) => {
  return (
    <>
      <SavingSpinner display={submitting} />
      {submitted && (
        <div>
          <div className="submitted-step success">
            <div className="text-center">
              <FontAwesomeIcon
                icon={success ? faCheckCircle : faTimesCircle}
                className={`result-icon ${success ? 'success' : 'error'}`}
              />
            </div>
            <h4
              className={`message text-center ${success ? 'success' : 'error'}`}
            >
              {message}
            </h4>
            {displayCloseButton && (
              <Button
                className="btn-block"
                variant={success ? 'success' : 'danger'}
                onClick={onClose}
              >
                Close
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
