import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DATE_SERVER_FORMAT } from 'constants/dates';
import apiClient from 'utils/apiClient';
import type { Schedule } from 'types';

export const useHandleDraftSchedule = ({
  onSuccess,
  onError,
}: {
  onSuccess: (isApproving: boolean) => void;
  onError: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      action,
      draftSchedule: { id, targetDate, schoolId },
    }: {
      action: 'approve' | 'reject';
      draftSchedule: Schedule;
    }) => {
      const q = targetDate
        ? `?apply_date=${dayjs(targetDate).format(DATE_SERVER_FORMAT)}`
        : '';

      const response = await apiClient.post(
        `v2/network/reports/draft_schedules/${id}/${action}${q}`,
      );

      if (response.status !== 200) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      return schoolId;
    },
    onSuccess: (schoolId, variables) => {
      void queryClient.invalidateQueries(['draftSchedules']);
      void queryClient.invalidateQueries(['school', schoolId]);
      onSuccess(variables.action === 'approve');
    },
    onError,
  });
};
