import React, { useRef, useEffect } from 'react';

export const ScrollIntoView = ({ isTop = undefined }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    elementRef.current.scrollIntoView();
  });

  return (
    <div style={isTop && { position: 'absolute', top: 0 }} ref={elementRef} />
  );
};
