import {
  all,
  put,
  call,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { actions, ActionTypes } from '../actions/schools';
import { hasAccessTo } from '../selectors/auth';
import schoolsService from 'services/schools.service';
import { getCalendarMapping } from 'utils/utilities';
import coursesService from 'services/courses.service';
import teachersService from 'services/teachers.service';
import networkService from 'services/network.service';
import moment from 'moment-timezone';
import schedulesService from 'services/schedules.service';

function parseTZ(time_str, school_tz = 'America/New_York') {
  return moment(time_str, 'HH:mm:ss').tz(school_tz, true).format('HH:mm:ss');
}

function* getSchools(params = {}) {
  try {
    const schools = yield call(schoolsService().getSchools, params);
    if (schools.status === 200) {
      yield put({
        type: ActionTypes.GET_SCHOOLS_SUCCESS,
        schools: schools.data,
        tags: schools.data.tags,
        total_user_count: parseInt(schools.headers['x-user-count']),
        total: parseInt(schools.headers['x-pagination-items']),
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SCHOOLS_FAIL, error });
  }
}

function* sagaA({ actionType, schedulingSettings }) {
  yield put({ type: actionType + '_SUCCESS', schedulingSettings });
}

const selectCurrentSchool = (state) => state.schools.currentSchool;

const manageSchedulingSettings = (endpoint, actionType) =>
  function* (action) {
    try {
      yield call(endpoint, action.args);
      const schedulingSettings = yield call(
        schedulesService().getSchedulingSettings,
        action.schoolId,
      );
      yield put(
        actions.refreshScheduleSettings(actionType, schedulingSettings),
      );
      yield take(ActionTypes.UPDATE_DAY_TYPE_SUCCESS);

      if (actionType === ActionTypes.UPDATE_DAY_TYPE) {
        const currentSchool = yield select(selectCurrentSchool);
        yield put({
          type: ActionTypes.REFRESH_ROTATION_SETTINGS,
          currentSchool,
        });
      }
    } catch (e) {
      yield put({ type: actionType + '_FAIL' });
    }
  };

function* dayWizardCreate({ schoolId, finalOptions }) {
  for (const name of finalOptions) {
    yield call(schedulesService().createDayType, { name, school_id: schoolId });
  }
  const schedulingSettings = yield call(
    schedulesService().getSchedulingSettings,
    schoolId,
  );
  yield put({
    type: ActionTypes.DAY_WIZARD_CREATE_SUCCESS,
    schedulingSettings,
  });
}

function* periodWizardCreate({ schoolId, finalOptions }) {
  for (const name of finalOptions) {
    yield call(schedulesService().createPeriodType, {
      name,
      school_id: schoolId,
    });
  }
  const schedulingSettings = yield call(
    schedulesService().getSchedulingSettings,
    schoolId,
  );
  yield put({
    type: ActionTypes.PERIOD_WIZARD_CREATE_SUCCESS,
    schedulingSettings,
  });
}

function* getSchoolById({ schoolId }) {
  try {
    const school = yield call(schoolsService().getSchoolbyId, schoolId);
    if (school.status === 200) {
      yield call(getSchoolResources, { schoolId, school: school.data });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SCHOOL_BY_ID_FAIL, error });
  }
}

function* safeLoad(endpoint, ...args) {
  try {
    const response = yield call(endpoint, ...args);
    return response;
  } catch (e) {
    return e;
  }
}

function* getSchoolResources({ schoolId, school }) {
  try {
    const state = yield select();
    let tasks = {};
    if (hasAccessTo(state.auth.scopes, ['schedule_management'])) {
      tasks = {
        ...tasks, //  call(() => throw new Error()), //
        scheduling_settings: call(
          safeLoad,
          schedulesService().getSchedulingSettings,
          schoolId,
        ),
        schedules: call(safeLoad, schedulesService().getSchedules, schoolId),
        calendar: call(safeLoad, schoolsService().getSchoolResource, {
          schoolId,
          type: 'calendar',
        }),
        missing_schedule_reports: call(
          safeLoad,
          networkService().getMissingResources,
          'schedules',
          schoolId,
        ),
        school_years: call(safeLoad, schoolsService().getSchoolResource, {
          schoolId,
          type: 'school_years',
        }),
      };
    }
    if (hasAccessTo(state.auth.scopes, ['school_management'])) {
      tasks = {
        ...tasks,
        courses: call(safeLoad, schoolsService().getSchoolResource, {
          schoolId,
          type: 'courses',
        }),
        staff: call(safeLoad, schoolsService().getSchoolResource, {
          schoolId,
          type: 'staff',
        }),
        // 08-31-2022 - Disable L2L schedule image loading temporarily while we decide on UX
        // schedule_images: call(safeLoad, schoolsService().getSchoolResource, { schoolId, type: 'images' }),
        students: call(safeLoad, schoolsService().getSchoolResource, {
          schoolId,
          type: 'users',
        }),
      };
    }
    const responses = yield all(tasks);
    const {
      scheduling_settings = { data: undefined },
      schedules = { data: [] },
      calendar = { data: [] },
      missing_schedule_reports = { data: [] },
      students = { data: [] },
      courses = { data: [] },
      staff = { data: [] },
      schedule_images = { data: [] },
      school_years = { data: [] },
    } = responses;
    const users = students.data;

    const errors = new Set(
      Object.keys(responses).filter((key) => responses[key] instanceof Error),
    );

    school = {
      ...school,
      courses: courses.data,
      has_lunch_class: courses.data.some((course) => course.name === 'Lunch'),
      lunch_class_id: courses.data.filter(
        (course) => course.name === 'Lunch',
      )[0]?.id,
      staff: staff.data,
      calendar:
        calendar.data && getCalendarMapping(calendar.data, schedules.data),
      users: users,
      schedules: (schedules.data || []).map((s) => {
        return {
          ...s,
          periods: s.periods.map((p, index) => {
            return {
              ...p,
              index,
              start_time: parseTZ(p.start_time, school.timezone),
              end_time: parseTZ(p.end_time, school.timezone),
            };
          }),
          lunch_waves: s.lunch_waves.map((l, index) => {
            return {
              ...l,
              index,
              start_time: parseTZ(l.start_time, school.timezone),
              end_time: parseTZ(l.end_time, school.timezone),
            };
          }),
        };
      }),
      schedule_images: schedule_images.data,
      missing_schedule_reports: missing_schedule_reports.data,
      errors,
      ...scheduling_settings.data,
      school_years: school_years.data,
    };

    yield put({
      type: ActionTypes.GET_SCHOOL_BY_ID_SUCCESS,
      school: school,
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_SCHOOL_BY_ID_FAIL, error });
  }
}

function* refreshRotationSettings({ currentSchool }) {
  try {
    const school = yield call(
      schoolsService().getSchoolbyId,
      currentSchool.school_name,
    );
    if (school.status === 200) {
      const { rotation_pattern, schedule_type } = school.data;
      const school_data = { ...currentSchool, rotation_pattern, schedule_type };
      yield put({
        type: ActionTypes.GET_SCHOOL_BY_ID_SUCCESS,
        school: school_data,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SCHOOL_BY_ID_FAIL, error });
  }
}

function* getSchoolResource({ schoolId, school, resourceType, params }) {
  try {
    const resource = yield call(schoolsService().getSchoolResource, {
      schoolId,
      type: resourceType,
      params,
    });
    school[resourceType] = resource.data;
    yield put({
      type: ActionTypes.GET_SCHOOL_RESOURCE_SUCCESS,
      school: school,
      resourceType,
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_SCHOOL_RESOURCE_FAIL, error });
  }
}

function* updateSchool({ schoolId, updateSchoolData }) {
  try {
    const school = yield call(
      schoolsService().updateSchool,
      schoolId,
      updateSchoolData,
    );
    yield call(getSchoolById, { schoolId });
    yield call(getSchoolResources, { schoolId, school: school.data });
    yield put({ type: ActionTypes.UPDATE_SCHOOLS_SUCCESS });
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_SCHOOLS_FAIL, error });
  }
}

function* switchFeature({ schoolId, feature, enable }) {
  try {
    if (enable) {
      yield call(schoolsService().enableFeature, schoolId, feature);
    } else {
      yield call(schoolsService().disableFeature, schoolId, feature);
    }
    yield call(getSchoolById, { schoolId });
    yield put({ type: ActionTypes.SWITCH_FEATURE_SUCCESS });
  } catch (error) {
    yield put({ type: ActionTypes.SWITCH_FEATURE_FAIL, error });
  }
}

function* removeCourse({ schoolId, courseId }) {
  try {
    yield call(coursesService().removeCourse, courseId);
    yield call(getSchoolById, { schoolId });
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_COURSE_FAIL, error });
  }
}

function* removeTeacher({ schoolId, teacherId }) {
  try {
    yield call(teachersService().removeTeacher, schoolId, teacherId);
    yield call(getSchoolById, { schoolId });
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_COURSE_FAIL, error });
  }
}

const map = {
  [ActionTypes.CREATE_DAY_TYPE]: schedulesService().createDayType,
  [ActionTypes.UPDATE_DAY_TYPE]: schedulesService().updateDayType,
  [ActionTypes.DELETE_DAY_TYPE]: schedulesService().deleteDayType,
  [ActionTypes.CREATE_PERIOD_TYPE]: schedulesService().createPeriodType,
  [ActionTypes.UPDATE_PERIOD_TYPE]: schedulesService().updatePeriodType,
  [ActionTypes.DELETE_PERIOD_TYPE]: schedulesService().deletePeriodType,
};

export default [
  takeLatest(ActionTypes.GET_SCHOOLS, getSchools),
  takeLatest(ActionTypes.GET_SCHOOL_BY_ID, getSchoolById),
  takeLatest(ActionTypes.GET_SCHOOL_RESOURCE, getSchoolResource),
  takeLatest(ActionTypes.UPDATE_SCHOOLS, updateSchool),
  takeLatest(ActionTypes.DELETE_COURSE, removeCourse),
  takeLatest(ActionTypes.DELETE_TEACHER, removeTeacher),
  takeLatest(ActionTypes.SWITCH_FEATURE, switchFeature),
  takeLatest(ActionTypes.DAY_WIZARD_CREATE, dayWizardCreate),
  takeLatest(ActionTypes.PERIOD_WIZARD_CREATE, periodWizardCreate),
  ...Object.keys(map).map((key) =>
    takeEvery(key, manageSchedulingSettings(map[key], key)),
  ),
  takeLatest(ActionTypes.REFRESH_ROTATION_SETTINGS, refreshRotationSettings),
  takeLatest(ActionTypes.REFRESH_SCHEDULE_SETTINGS, sagaA),
];
