import * as M from '@mantine/core';
import styled from '@emotion/styled';

import Header from '../components/Header';
import ScheduleWizard from '../components/scheduleWizard/ScheduleWizard';

const ScheduleDetailPage = () => {
  return (
    <>
      <Header />

      <Container>
        <ScheduleWizard />
      </Container>
    </>
  );
};

const Container = styled(M.Flex)`
  margin: 0 auto;

  align-items: center;
  flex-direction: column;
`;

export default ScheduleDetailPage;
