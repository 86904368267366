import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { LunchWave, LunchSlot } from 'types';

import AddLunchWaveConfiguration from './AddLunchWaveConfiguration';
import EditLunchWavePopoverDropdown from './EditLunchWavePopoverDropdown';

import EditableTimesTable from '../EditableTimesTable';
import { getLength, parseTime } from 'features/editSchedule/utils';

interface Props {
  slots: LunchSlot[];
  isAlphabetic: boolean;
  lunchWaves: LunchWave[];
  setLunchWaves: React.Dispatch<React.SetStateAction<LunchWave[]>>;
}

const LunchWavesBuilder = ({
  slots,
  isAlphabetic,
  lunchWaves,
  setLunchWaves,
}: Props) => {
  const [editIndex, setEditIndex] = useState(-1);

  return (
    <Container>
      <AddLunchWaveConfiguration
        slots={slots}
        isAlphabetic={isAlphabetic}
        setLunchWaves={setLunchWaves}
      />

      <M.Divider />

      <EditableTimesTable
        descriptionHeaders={['Wave']}
        descriptionData={lunchWaves.map((l) => [l.name])}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        getStartTime={(i) => lunchWaves[i].startTime}
        getEndTime={(i) => lunchWaves[i].endTime}
        getDuration={(i) =>
          `${getLength([
            parseTime(lunchWaves[i].startTime),
            parseTime(lunchWaves[i].endTime),
          ])} min`
        }
        onRemove={(index) =>
          setLunchWaves((waves) => waves.filter((_, i) => i !== index))
        }
        popoverDropdown={
          <EditLunchWavePopoverDropdown
            slots={slots}
            isAlphabetic={isAlphabetic}
            lunchWaves={lunchWaves}
            setLunchWaves={setLunchWaves}
            editIndex={editIndex}
          />
        }
        canOverlap
      />

      {editIndex !== -1 && <M.Overlay color="#000" />}
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

export default LunchWavesBuilder;
