import React from 'react';
import moment from 'moment';
import { Container, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';

import { getDateFormat } from 'utils/utilities';

import SchoolCancel from './SchoolCancel';
import ScheduleNamePicker from './ScheduleNamePicker';
import SchedulePicker from './SchedulePicker';
import VacationPicker from './VacationPicker';

const CalendarDay = ({
  school,
  day,
  loading,
  updating,
  updatingDay,
  onRegenerate,
}) => {
  const calendarMapping = school?.calendar?.mapping || [];
  const date = day.getDate();
  const dayInfo = getDayInfo(calendarMapping, day);
  const dayClass = dayInfo ? getDayClass(dayInfo) : '';
  const dayLabel = dayInfo
    ? dayInfo.is_canceled
      ? 'Day off'
      : dayInfo.dateLabel
    : '';
  const scheduleName = dayInfo
    ? dayInfo.is_canceled
      ? 'Day off'
      : dayInfo.scheduleDisplay
    : '';

  const popover = (
    <Popover id={date} className="popover">
      <Container className="popover-container">
        <div className="popover-container-title">
          {dayLabel
            ? `${dayLabel} - ${getDateFormat(day, 'dddd, MMMM DD,YYYY')}`
            : `${getDateFormat(day, 'dddd, MMMM DD,YYYY')}`}
        </div>
        <SchoolCancel
          dayInfo={dayInfo}
          day={day}
          school={school}
          onRegenerate={onRegenerate}
        />
        <ScheduleNamePicker
          dayInfo={dayInfo}
          day={day}
          school={school}
          onRegenerate={onRegenerate}
        />
        <SchedulePicker
          dayInfo={dayInfo}
          day={day}
          school={school}
          onRegenerate={onRegenerate}
        />
        <VacationPicker
          dayInfo={dayInfo}
          day={day}
          school={school}
          onRegenerate={onRegenerate}
        />
      </Container>
    </Popover>
  );

  const cancelPopover = (
    <Popover id={date} className="popover">
      <Container className="popover-container">
        <div className="popover-container-title">
          {dayLabel
            ? `${dayLabel} - ${getDateFormat(day, 'dddd, MMMM DD,YYYY')}`
            : `${getDateFormat(day, 'dddd, MMMM DD,YYYY')}`}
        </div>
        <SchoolCancel
          dayInfo={dayInfo}
          day={day}
          school={school}
          onRegenerate={onRegenerate}
        />
      </Container>
    </Popover>
  );

  const dayComponent = (
    <button
      onContextMenu={(event) => event.preventDefault()}
      onClick={(event) => event.preventDefault()}
      className={`calendar-day-container ${dayClass}`}
    >
      <div className="calendar-day-date">{date}</div>
      <div className="calendar-day-extra-content">
        <span className="calendar-schedule-type">
          {loading ||
          (updating && getDateFormat(day, 'YYYY-MM-DD') === updatingDay) ? (
            <Spinner animation="grow" role="status" size="sm" />
          ) : (
            scheduleName
          )}
        </span>
      </div>
    </button>
  );

  if (moment(day).day() === 0 || moment(day).day() === 6) {
    return !!scheduleName ? (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        overlay={cancelPopover}
        delay={{ show: 250, hide: 100 }}
        rootClose
      >
        {dayComponent}
      </OverlayTrigger>
    ) : (
      dayComponent
    );
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      overlay={popover}
      delay={{ show: 250, hide: 100 }}
      rootClose
    >
      {dayComponent}
    </OverlayTrigger>
  );
};

const getDayInfo = (calendarMapping, currentDay) => {
  const currentDayLabel = getDateFormat(currentDay, 'YYYY-MM-DD');
  const dayInfo = calendarMapping.filter(
    (day) => day.dateNow === currentDayLabel,
  );
  return dayInfo[0];
};

const getDayClass = (dayInfo) => {
  let classNames = '';
  if (dayInfo.dateLabel === 'X') {
    classNames = 'calendar-day-container--disabled';
  } else if (dayInfo.schedule_id === null) {
    classNames = 'calendar-day-container--missing';
  } else if (dayInfo.special) {
    classNames = 'calendar-day-container--special';
  } else if (dayInfo.modified) {
    classNames = 'calendar-day-container--modified';
  }

  return classNames;
};

export default CalendarDay;
