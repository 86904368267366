import React, { PureComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Dropzone from 'react-dropzone';

import FieldError from '../Fields/FieldError/FieldError';

import './FileBox.scss';

export default class FileBox extends PureComponent {
  constructor() {
    super();
    this.onDrop = (files) => {
      this.setState({ file: files[0] });
      this.updateValue(files[0]);
    };
    this.state = {
      file: null,
    };

    this.updateValue = this.updateValue.bind(this);
  }

  isFieldInvalid = () => {
    return this.props.field.blurred && !this.props.field.valid;
  };

  updateBlur = () => {
    this.props.field.blurred = true;
    this.props.updateParent(this.props.field.currentValue, this.props.field);
  };

  validate = (value) => {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  };

  updateValue = (value) => {
    const newValue = value;
    this.props.field.currentValue = newValue;
    this.validate(newValue);
    this.props.updateParent(newValue, this.props.field);
  };

  render() {
    const { file } = this.state;
    const { placeholder } = this.props.field;

    return (
      <Dropzone
        onDrop={this.onDrop}
        multiple={false}
        accept={['.csv', 'text/plain', 'image/*']}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <div {...getRootProps({ className: 'dropzone' })}>
              <Form.Control
                {...getInputProps()}
                className="file-upload-input"
                type="file"
                size="md"
                placeholder={
                  this.props.field.placeholder || this.props.field.name
                }
                name={this.props.field.name}
                // onChange={this.updateValue}
                onBlur={this.updateBlur}
                isInvalid={this.isFieldInvalid()}
              />
              {file ? <p>{file.name}</p> : <p>{placeholder}</p>}
            </div>
            <FieldError field={this.props.field} />
          </>
        )}
      </Dropzone>
    );
  }
}
