import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { ImagePreviewModal } from './ImagePreviewModal';

interface Props {
  images: string[];
}

// TODO src/features/components -> src/components once the old ones are gone
const ImageGallery = ({ images }: Props) => {
  const [selectedImage, setSelectedImage] = useState<string>();

  return (
    <div>
      <M.Grid gutter="sm">
        {images.map((image, index) => (
          <M.Grid.Col span={3} key={index}>
            <Thumbnail src={image} onClick={() => setSelectedImage(image)} />
          </M.Grid.Col>
        ))}
      </M.Grid>

      {selectedImage && (
        <ImagePreviewModal
          imageSrc={selectedImage}
          onClose={() => setSelectedImage(undefined)}
        />
      )}
    </div>
  );
};

const Thumbnail = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export default ImageGallery;
