import { PropsWithChildren, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MM from '@mantine/modals';
import { IconChevronLeft } from '@tabler/icons-react';

import { useSchoolId } from 'hooks/useSchoolId';
import { useSchool } from 'hooks/useSchool';
import { useScheduleDetailParams } from 'features/schedules/hooks/useScheduleDetailParams';

import { Sidebar } from './Sidebar';

import { useWizard } from '../stepUtils';
import periodsStep from '../steps/PeriodsStep';
import lunchWavesStep from '../steps/LunchWavesStep';
import reviewStep from '../steps/ReviewStep';

export const StepContainer = ({ children }: PropsWithChildren<unknown>) => {
  const schoolId = useSchoolId();
  const { mode, id } = useScheduleDetailParams();
  const history = useHistory();

  const { data: school } = useSchool((school) => ({
    schedules: school.schedules,
  }));
  const [state, steps, currentStepIndex] = useWizard((x) => [
    x.state,
    x.steps,
    x.currentStepIndex,
  ]);

  const hasUnsavedChanges = useMemo(() => {
    if (mode === 'create' && Object.keys(state.schedule ?? {}).length > 0) {
      return true;
    }
    const schedule = school?.schedules.find((s) => s.id === id);

    if (!schedule) return false;

    return JSON.stringify(schedule) !== JSON.stringify(state.schedule);
  }, [state, school, mode, id]);

  const showSidebar = useMemo(() => {
    return (
      state.schedule?.image &&
      [periodsStep, lunchWavesStep, reviewStep].some(
        (def) => def.key === steps[currentStepIndex].key,
      )
    );
  }, [steps, currentStepIndex, state]);

  return (
    <StepWrapper maw={showSidebar ? '100%' : undefined}>
      <BackLink
        to={`/schools/${schoolId}/schedules`}
        onClick={(e) => {
          if (hasUnsavedChanges) {
            e.preventDefault();
            MM.openConfirmModal({
              title:
                'Are you sure you want to leave? There are unsaved changes.',
              labels: {
                cancel: 'Cancel',
                confirm: 'Leave',
              },
              onConfirm: () => {
                history.push(`/schools/${schoolId}/schedules`);
              },
              withCloseButton: false,
            });
          }
        }}
      >
        <IconChevronLeft />
        Return to schedules
      </BackLink>

      <M.Grid>
        <M.Grid.Col span={showSidebar ? 8 : 12}>
          <StepContentWrapper>{children}</StepContentWrapper>
        </M.Grid.Col>

        {showSidebar && (
          <M.Grid.Col span={4}>
            <Sidebar />
          </M.Grid.Col>
        )}
      </M.Grid>
    </StepWrapper>
  );
};

const StepWrapper = styled(M.Container)`
  width: 100%;
  padding: 1rem 1.5rem;
`;

const StepContentWrapper = styled(M.Box)`
  padding: 0 1rem;
`;

const BackLink = styled(Link)`
  display: flex;
  align-self: flex-start;
`;
