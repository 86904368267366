import React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import SaturnGrayLogo from 'assets/icons/saturn_gray_logo.svg';

import './EmojiPicker.scss';

const EmojiPicker = ({ onSelect }) => {
  const selectOrderedEmojis = createSelector(
    (state) => state.misc.emojis,
    (state) => state.misc.emojiOrder,
    (emojis, order) => order.map((name) => emojis[name]),
  );
  const orderedEmojis = useSelector(selectOrderedEmojis);

  return (
    orderedEmojis.length > 0 && (
      <Picker
        onSelect={onSelect}
        custom={orderedEmojis}
        title="Pick an emoji"
        emoji="point_up"
        exclude={[
          'search',
          'recent',
          'smileys',
          'people',
          'nature',
          'foods',
          'activity',
          'places',
          'objects',
          'symbols',
          'flags',
        ]}
        showSkinTones={false}
        icons={{
          categories: {
            custom: () => (
              <Image src={SaturnGrayLogo} alt="Saturn_footer_logo" />
            ),
          },
        }}
        emojiSize={36}
      />
    )
  );
};

export default EmojiPicker;
