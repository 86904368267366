import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Select, TextInput, Checkbox } from 'react-hook-form-mantine';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import * as M from '@mantine/core';

import { StepHeader } from 'features/schedules/components/scheduleWizard/stepLayout/StepHeader';
import { staticModificationTypes } from 'constants/scheduleInfo';
import { useSchool } from 'hooks/useSchool';
import type { CoreSchedule } from 'types';

import { useWizard } from '../stepUtils';
import infoStep from '../steps/InfoStep';
import { useScheduleDetailParams } from 'features/schedules/hooks/useScheduleDetailParams';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { capitalizeWord } from './utils';
import { schema } from 'schema';

const formSchema = z.object({
  dayType: z.string(),
  dayOfWeek: schema.dayOfWeek
    .nullable()
    .transform((val) => val ?? undefined)
    .optional(),
  displayName: z.string().min(1),
  isInLsc: z.boolean(),
  isInAcf: z.boolean(),
});

type FieldValues = z.infer<typeof formSchema>;

const CoreInfoForm = () => {
  const { id } = useScheduleDetailParams();
  const { data: school } = useSchool(({ dayTypes, schedules }) => ({
    dayTypes,
    schedules,
  }));

  const [schedule, saveStep, next] = useWizard((store) => [
    store.state.schedule as Partial<CoreSchedule>,
    store.saveStep,
    store.next,
  ]);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    formState: { errors, dirtyFields },
    clearErrors,
  } = useForm<FieldValues>({
    defaultValues: {
      dayType: schedule.dayType?.id,
      dayOfWeek: schedule.dayOfWeek,
      displayName: schedule.displayName || '',
      isInLsc: schedule.isInLsc ?? true,
      isInAcf: schedule.isInAcf ?? true,
    },
    resolver: zodResolver(formSchema),
  });

  const dayType = watch('dayType');
  const dayOfWeek = watch('dayOfWeek');

  useEffect(() => {
    const dayTypeName = school?.dayTypes.find((dt) => dt.id === dayType)?.name;
    const dayOfWeekLabel = staticModificationTypes.find(
      (dt) => dt.value === dayOfWeek,
    )?.label;

    clearErrors('root');

    if (!dirtyFields.dayType && !dirtyFields.dayOfWeek) return;

    if (dayTypeName && dayOfWeekLabel) {
      setValue(
        'displayName',
        `${capitalizeWord(dayTypeName)} - ${dayOfWeekLabel}`,
      );
    } else if (dayTypeName) {
      setValue('displayName', capitalizeWord(dayTypeName));
    }
  }, [dayType, dayOfWeek, school, dirtyFields, setValue, clearErrors]);

  if (!school) return <M.Loader />;

  const onSubmit = (values: FieldValues) => {
    const dayType = school.dayTypes.find((dt) => dt.id === values.dayType);

    if (!dayType) return;

    if (
      school.schedules
        .filter((s) => s.id !== id)
        .find(
          (s) =>
            s.variant === 'core' &&
            s.dayType.id === values.dayType &&
            s.dayOfWeek === values.dayOfWeek,
        )
    ) {
      setError('root', {
        message:
          'A core schedule with this day type and day of week already exists.',
      });
      return;
    }

    saveStep(
      infoStep.payloadReducer({
        variant: 'core',
        ...values,
        dayType,
        order: schedule.order,
      }),
    );
    next();
  };

  return (
    <div>
      <StepHeader
        title="Core schedule info"
        description="Add basic information about your schedule"
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <M.Flex direction="column" gap="md">
          <Select
            control={control}
            name="dayType"
            label="Day type"
            data={school.dayTypes.map((dt) => ({
              label: dt.name,
              value: dt.id,
            }))}
            required
          />

          <Select
            control={control}
            name="dayOfWeek"
            label="Day of week"
            data={staticModificationTypes}
            clearable
          />

          <TextInput
            name="displayName"
            label="Display name"
            autoComplete="off"
            control={control}
            required
          />

          <Checkbox name="isInLsc" label="Display in LSC" control={control} />

          <Checkbox name="isInAcf" label="Display in ACF" control={control} />

          {errors.root && <M.Text color="red">{errors.root.message}</M.Text>}

          {schedule.brokenDayOfWeek && (
            <M.Text color="red">
              This schedule has an invalid day of week:{' '}
              {schedule.brokenDayOfWeek}. Select a new one above or this will be
              cleared upon saving.
            </M.Text>
          )}
        </M.Flex>

        <StepNavigation onNext={handleSubmit(onSubmit)} />
      </form>
    </div>
  );
};

export default CoreInfoForm;
