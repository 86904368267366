import * as M from '@mantine/core';

import { useSchool } from 'hooks/useSchool';

import { Wizard } from 'features/wizard';
import uploadImagesStep from './steps/UploadImagesStep';
import infoStep from './steps/InfoStep';
import { useScheduleDetailParams } from 'features/schedules/hooks/useScheduleDetailParams';

import scheduleTypeStep from './steps/ScheduleTypeStep';
import scheduleSelectStep from './steps/ScheduleSelectStep';
import periodsStep from './steps/PeriodsStep';
import lunchWavesStep from './steps/LunchWavesStep';
import reviewStep from './steps/ReviewStep';
import { useUploadedScheduleImages } from 'features/scheduleConfiguration/hooks/queries';
import { useSchoolId } from 'hooks/useSchoolId';
import { ScheduleWizardState } from './stepUtils';

const ScheduleWizard = () => {
  const schoolId = useSchoolId();
  const routeParams = useScheduleDetailParams();

  const { data: school } = useSchool();
  const { data: images } = useUploadedScheduleImages(schoolId);

  if (!school) return <M.Loader />;

  const schedule = school.schedules.find((s) => s.id === routeParams.id);

  const getInitialState = (): Partial<ScheduleWizardState> => {
    switch (routeParams.mode) {
      case 'edit':
        return { schedule };
      case 'copy':
        return { schedule: { ...schedule, genId: undefined } };
      default:
        return { schedule: undefined };
    }
  };

  return (
    <Wizard
      steps={[
        uploadImagesStep,
        scheduleTypeStep,
        infoStep,
        scheduleSelectStep,
        periodsStep,
        lunchWavesStep,
        reviewStep,
      ]}
      visibleSteps={() => [
        routeParams.mode === 'create' && uploadImagesStep,
        scheduleTypeStep,
        infoStep,
        routeParams.mode === 'create' && !!images?.length && scheduleSelectStep,
        periodsStep,
        school.lunchSettings.type === 'waves' && lunchWavesStep,
        reviewStep,
      ]}
      initialState={getInitialState()}
    />
  );
};

export default ScheduleWizard;
