import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { Wizard } from 'features/scheduleConfiguration';

const LiteConfiguration = () => {
  return (
    <Container size="lg">
      <Wizard
        steps={[
          'uploadImagesStep',
          'scheduleSettingsStep',
          'calendarSettingsStep',
          'coreSchedulesStep',
        ]}
      />
    </Container>
  );
};

const Container = styled(M.Container)`
  padding-top: ${(p) => p.theme.spacing.xl};
  padding-bottom: ${(p) => p.theme.spacing.xl};
`;

export default LiteConfiguration;
