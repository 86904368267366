import { useState } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import type { SchoolYear } from 'types';

import SchoolYearTable from './SchoolYearTable';
import SchoolYearModal from './SchoolYearModal';

interface Props {
  schoolYears: SchoolYear[];
  createSchoolYear: (range: [Date, Date]) => void;
  updateSchoolYear: (schoolYear: SchoolYear) => void;
  deleteSchoolYear: (id: string) => void;
}

const SchoolYearManager = ({
  schoolYears,
  createSchoolYear,
  updateSchoolYear,
  deleteSchoolYear,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isAdding, setIsAdding] = useState(false);

  return (
    <Container>
      <M.Title order={3}>School Year Dates</M.Title>
      <SchoolYearTable
        schoolYears={schoolYears}
        deleteSchoolYear={deleteSchoolYear}
        onEdit={(index: number) => setSelectedIndex(index)}
        onAdd={() => setIsAdding(true)}
      />

      {(selectedIndex !== -1 || isAdding) && (
        <SchoolYearModal
          schoolYears={schoolYears}
          selectedSchoolYear={schoolYears[selectedIndex]}
          createSchoolYear={createSchoolYear}
          updateSchoolYear={updateSchoolYear}
          onClose={() => {
            setSelectedIndex(-1);
            setIsAdding(false);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled(M.Flex)`
  padding: ${(p) => p.theme.spacing.md} 0;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

export default SchoolYearManager;
