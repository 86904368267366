import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { IconLink } from '@tabler/icons-react';

import { useGetDraftSchedules } from 'features/draftSchedules/hooks/queries';
import { ScheduleBadge } from 'features/components/ScheduleBadge';
import { DATE_DISPLAY_FORMAT } from 'constants/dates';

import type { Schedule } from 'types';

import Filters from './Filters';

import { COLUMNS } from '../constants';
import type { ColumnKey, FilterTypes, FilterOp } from '../types';

type Props = {
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedSchedule: React.Dispatch<
    React.SetStateAction<Schedule | undefined>
  >;
};

const DraftSchedulesTable = ({
  selectedIndex,
  setSelectedIndex,
  setSelectedSchedule,
}: Props) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState<FilterTypes>({});

  const { data } = useGetDraftSchedules({ page, filters });

  useEffect(() => {
    if (data) {
      setPageCount(data.pageCount);

      setSelectedSchedule(data.draftSchedules[selectedIndex]);
    }
  }, [data, selectedIndex, setSelectedSchedule]);

  const draftSchedules = data?.draftSchedules;

  const addFilter = ({
    key,
    op,
    value,
  }: {
    key: ColumnKey;
    op: FilterOp;
    value: string;
  }) => {
    setPage(1);
    setFilters((prev) => ({
      ...prev,
      [key]: { op, value },
    }));
  };

  const removeFilter = ({ key }: { key: ColumnKey }) =>
    setFilters((prev) => {
      const { [key]: _removed, ...rest } = prev;

      return rest;
    });

  return (
    <Container>
      <Filters
        filters={filters}
        addFilter={addFilter}
        removeFilter={removeFilter}
      />

      <M.Table highlightOnHover sx={{ ':hover': { cursor: 'pointer' } }}>
        <thead>
          <tr>
            {Object.entries(COLUMNS).map(([key, text]) => (
              <th key={key}>{text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!draftSchedules ? (
            new Array(10).fill(null).map((_, i) => (
              <tr key={i}>
                {Object.keys(COLUMNS).map((k) => (
                  <td key={k}>
                    <M.Skeleton>...</M.Skeleton>
                  </td>
                ))}
              </tr>
            ))
          ) : !draftSchedules.length ? (
            <tr>
              <td colSpan={Object.entries(COLUMNS).length}>
                <M.Text sx={{ textAlign: 'center' }}>No results</M.Text>
              </td>
            </tr>
          ) : (
            draftSchedules.map((draftSchedule, i) => (
              <Row key={draftSchedule.id} onClick={() => setSelectedIndex(i)}>
                <td>
                  <M.Flex align="center" gap="xs">
                    <SchoolId
                      onClick={(e) => {
                        e.stopPropagation();
                        addFilter({
                          key: 'school_id',
                          op: 'eq',
                          value: draftSchedule.schoolId,
                        });
                      }}
                    >
                      {draftSchedule.schoolId}
                    </SchoolId>

                    <SchoolLink
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `/schools/${draftSchedule.schoolId}/calendar`,
                          '_blank',
                        );
                      }}
                    />
                  </M.Flex>
                </td>
                <td width="100%">
                  <M.Flex gap="md">
                    {draftSchedule.displayName}{' '}
                    <ScheduleBadge schedule={draftSchedule} hideDraft />
                  </M.Flex>
                </td>
                <td>
                  {draftSchedule.targetDate
                    ? dayjs(draftSchedule.targetDate).format(
                        DATE_DISPLAY_FORMAT,
                      )
                    : 'N/A'}
                </td>
              </Row>
            ))
          )}
        </tbody>
      </M.Table>

      <Pagination value={page} onChange={setPage} total={pageCount} />
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Row = styled.tr`
  &:active {
    transform: scale(1.01);
  }
`;

const SchoolId = styled.span`
  font-weight: bold;

  &:hover {
    color: #6515e7;
  }
`;

const SchoolLink = styled(IconLink)`
  &:hover {
    color: #6515e7;
    transform: scale(1.5);
  }
`;

const Pagination = styled(M.Pagination)`
  align-self: center;
`;

export default DraftSchedulesTable;
