import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import type { CoreSchedule } from 'types';

import { EditScheduleButton, EditScheduleModal } from 'features/editSchedule';
import { useWizard } from 'features/scheduleConfiguration/components/wizard/Wizard';
import {
  useCreateScheduleMutation,
  useDeleteMultipleSchedulesMutation,
  useUpdateScheduleMutation,
} from 'features/scheduleConfiguration/hooks/mutations';
import type { StepComponent } from 'features/scheduleConfiguration/types';
import { usePrefetchParseResults } from 'features/scheduleConfiguration/hooks/queries';
import { periodUtils } from 'features/schedules/utils/period';

const CoreSchedulesStep: StepComponent = ({ school, stepIndex }) => {
  const wizard = useWizard();
  const createSchedule = useCreateScheduleMutation(school.schoolName);
  const updateSchedule = useUpdateScheduleMutation(school.schoolName);
  const deleteSchedules = useDeleteMultipleSchedulesMutation(school.schoolName);

  const { prefetch } = usePrefetchParseResults(school.schoolName);

  const coreSchedules = useMemo(
    () =>
      school.schedules.filter((s): s is CoreSchedule => s.variant === 'core'),
    [school.schedules],
  );

  useEffect(() => {
    void prefetch();
  }, [prefetch, stepIndex]);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [copyFromIndex, setCopyFromIndex] = useState(-1);

  useEffect(() => {
    wizard.update(stepIndex, {
      type: 'coreSchedulesStep',
      payload: coreSchedules,
      isValid:
        coreSchedules.length === school.dayTypes.length &&
        school.dayTypes.every((dt) =>
          coreSchedules.some((s) => s.dayType.id === dt.id),
        ),
    });
  }, [coreSchedules, school.dayTypes]);

  const isLoading = createSchedule.isLoading || updateSchedule.isLoading;

  // TODO: review if this workaround is still needed, seems like it isn't
  const brokenSchedules = coreSchedules.filter((s) => !s.dayType);

  if (brokenSchedules.length) {
    return (
      <Container>
        <M.Title order={2}>Core Schedules</M.Title>
        <M.Text>
          The following schedules no longer have a day type and must be deleted
          before you continue:
        </M.Text>
        <M.Text weight="bold">
          {brokenSchedules.map((s) => s.displayName).join(', ')}
        </M.Text>
        <M.Flex gap="sm">
          <M.Button
            color="red"
            onClick={() =>
              deleteSchedules.mutate(brokenSchedules.map((s) => s.id))
            }
          >
            Delete schedules
          </M.Button>
        </M.Flex>

        <M.LoadingOverlay visible={deleteSchedules.isLoading} />
      </Container>
    );
  }

  const getInitialSchedule = (): Partial<CoreSchedule> | undefined => {
    if (selectedIndex === -1) {
      return undefined;
    }

    const selectedDayType = school.dayTypes[selectedIndex];

    const initialSchedule = coreSchedules.find(
      (s) => s.dayType.id === selectedDayType.id,
    );

    if (copyFromIndex !== -1) {
      const fromSchedule = coreSchedules.find(
        (s) => s.dayType.id === school.dayTypes[copyFromIndex].id,
      );

      return (
        fromSchedule && {
          ...fromSchedule,
          id: initialSchedule?.id,
          displayName: selectedDayType.name,
          dayType: selectedDayType,
          periods: fromSchedule.periods.map((p) =>
            periodUtils.isRegularPeriod(p)
              ? {
                  ...p,
                  dayType: selectedDayType,
                }
              : p,
          ),
        }
      );
    }

    return initialSchedule;
  };

  return (
    <Container>
      <M.Title order={2}>Core Schedules</M.Title>
      {school.dayTypes.map((dayType, i) => (
        <EditScheduleButton
          key={dayType.id}
          dayType={dayType.name}
          isComplete={!!coreSchedules.find((s) => s.dayType.id === dayType.id)}
          onSelect={() => setSelectedIndex(i)}
          onCopy={() => setCopyFromIndex(i)}
        />
      ))}

      <M.Text fs="italic">
        Calendar and Schedules tabs will be unlocked once all core schedules are
        created.
      </M.Text>

      {copyFromIndex !== -1 && selectedIndex === -1 && (
        <M.Modal
          opened
          onClose={() => setCopyFromIndex(-1)}
          title={`Copy ${school.dayTypes[copyFromIndex].name} to...`}
          size="xs"
        >
          <CopyModalContainer>
            {school.dayTypes.map((dayType, i) => (
              <M.Button
                key={dayType.id}
                onClick={() => setSelectedIndex(i)}
                disabled={i === copyFromIndex}
              >
                {dayType.name}
              </M.Button>
            ))}
          </CopyModalContainer>
        </M.Modal>
      )}

      {selectedIndex !== -1 && (
        <EditScheduleModal
          schoolId={school.schoolName}
          initialSchedule={getInitialSchedule()}
          order={selectedIndex + 1}
          dayType={school.dayTypes[selectedIndex]}
          periodTypes={school.periodTypes}
          lunchSettings={school.lunchSettings}
          isCopying={copyFromIndex !== -1}
          onClose={() => {
            setSelectedIndex(-1);
            setCopyFromIndex(-1);
          }}
          onSubmit={(schedule) => {
            const {
              id = '',
              genId,
              displayName,
              isInLsc,
              order,
              dayType,
              periods = [],
              lunchWaves = [],
            } = schedule;

            if (
              !displayName ||
              !order ||
              !schedule.dayType ||
              !periods.length ||
              !lunchWaves ||
              !dayType ||
              isInLsc === undefined
            ) {
              return;
            }

            const request = {
              id,
              schoolId: school.schoolName,
              genId,
              variant: 'core',
              displayName,
              isInLsc,
              order,
              dayType,
              periods,
              lunchWaves,
              timezone: school.timezone,
              isDraft: false,
              isUserDraft: false,
              isInAcf: true, // TODO should this be editable?
            } as const;

            if (id) {
              updateSchedule.mutate(request);
            } else {
              createSchedule.mutate(request);
            }
            setSelectedIndex(-1);
            setCopyFromIndex(-1);
          }}
        />
      )}

      <M.LoadingOverlay visible={isLoading} />
    </Container>
  );
};

const Container = styled(M.Flex)`
  position: relative;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const CopyModalContainer = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xs};
`;

export default CoreSchedulesStep;
