export type Step<Payload, State> = {
  key: string;
  payloadReducer: (
    payload: Payload,
  ) => (prevState: Partial<State>) => Partial<State>;
  validate: (state: Partial<State>) => boolean;
  render: (state: Partial<State>) => React.ReactNode;
};

export const getStepCreator = <State>() => ({
  create:
    <Payload>() =>
    (args: Step<Payload, State>) =>
      args,
});
