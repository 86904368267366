export default [{x:39.604301,y:-104.707438},
{x:40.289936,y:-74.052551},
{x:40.765265,y:-73.725261},
{x:40.758877,y:-73.327532},
{x:36.09148,y:-79.814204},
{x:37.379291,y:-121.97449},
{x:32.236487,y:-110.94349},
{x:44.102446,y:-121.298058},
{x:45.76955,y:-122.549798},
{x:39.710324,y:-105.08215},
{x:32.673702,y:-97.006221},
{x:42.144103,y:-80.006192},
{x:36.824996,y:-76.338234},
{x:43.047195,y:-89.325087},
{x:34.048562,y:-118.3367},
{x:42.109629,y:-87.874887},
{x:40.691468,y:-82.51254},
{x:44.86631,y:-93.05581},
{x:29.664249,y:-95.115503},
{x:34.077251,y:-117.370727},
{x:30.201265,y:-97.768496},
{x:39.854858,y:-82.825092},
{x:41.953382,y:-70.711116},
{x:35.953071,y:-78.992277},
{x:36.844559,y:-76.129471},
{x:41.917751,y:-87.660048},
{x:39.128127,y:-84.519368},
{x:38.804744,y:-76.990571},
{x:39.528891,y:-119.827699},
{x:38.89516,y:-77.026491},
{x:40.80453,y:-73.966788},
{x:34.115575,y:-118.15078},
{x:40.439969,y:-79.958694},
{x:35.348791,y:-80.857763},
{x:38.904338,y:-104.774162},
{x:40.720314,y:-73.845939},
{x:38.814028,y:-94.510088},
{x:30.435213,y:-84.294207},
{x:43.12429,y:-87.913063},
{x:40.69989,y:-73.941059},
{x:42.003149,y:-88.004911},
{x:39.713557,y:-82.577375},
{x:45.127286,y:-93.25675},
{x:37.506143,y:-77.6071},
{x:40.737346,y:-73.990045},
{x:39.073042,y:-84.346289},
{x:44.94747,y:-93.321226},
{x:35.213717,y:-80.833475},
{x:33.858361,y:-118.030546},
{x:46.585105,y:-120.542479},
{x:37.354564,y:-77.410938},
{x:32.441716,y:-93.718353},
{x:38.972643,y:-95.289387},
{x:39.764052,y:-104.774668},
{x:40.337433,y:-83.761348},
{x:41.659139,y:-81.376991},
{x:27.916774,y:-82.731462},
{x:40.750253,y:-73.869885},
{x:30.24213,y:-97.727277},
{x:25.957414,y:-80.146767},
{x:47.62195,y:-122.13232},
{x:45.672456,y:-122.525802},
{x:38.575663,y:-121.4034},
{x:42.676092,y:-71.131702},
{x:41.638481,y:-72.873199},
{x:41.396667,y:-82.002747},
{x:42.541001,y:-83.785801},
{x:44.055159,y:-121.3034},
{x:33.127704,y:-96.733941},
{x:46.275712,y:-119.186159},
{x:32.618576,y:-83.690349},
{x:40.803222,y:-74.021318},
{x:40.716918,y:-74.035255},
{x:34.429193,y:-119.869361},
{x:34.442702,y:-118.573836},
{x:43.22982,y:-71.482074},
{x:38.897731,y:-77.005632},
{x:47.635607,y:-122.377037},
{x:40.703535,y:-73.798799},
{x:39.880894,y:-86.06902},
{x:38.626347,y:-90.334343},
{x:41.932968,y:-87.644199},
{x:42.105396,y:-80.131654},
{x:35.652342,y:-80.456753},
{x:39.896011,y:-82.882435},
{x:42.654297,y:-73.773117},
{x:29.55817,y:-95.26172},
{x:28.800126,y:-81.333621},
{x:47.613816,y:-122.185565},
{x:47.302453,y:-122.578267},
{x:34.073768,y:-117.5517},
{x:38.091581,y:-122.556136},
{x:37.978775,y:-122.066619},
{x:44.262639,y:-88.470015},
{x:33.214758,y:-97.146201},
{x:36.195468,y:-115.119124},
{x:35.102893,y:-77.085557},
{x:28.205174,y:-82.66659},
{x:30.180902,y:-82.681702},
{x:35.12023,y:-120.601663},
{x:40.72459,y:-111.88863},
{x:33.958736,y:-84.055084},
{x:39.161847,y:-84.4203},
{x:40.906883,y:-74.553241},
{x:41.780454,y:-88.240191},
{x:37.937408,y:-122.025467},
{x:40.744779,y:-73.655976},
{x:43.023285,y:-85.688553},
{x:44.829395,y:-68.753743},
{x:30.196359,y:-81.829889},
{x:40.407528,y:-105.097842},
{x:32.631549,y:-117.08836},
{x:41.080578,y:-73.461234},
{x:29.644635,y:-95.577063},
{x:40.948604,y:-73.792398},
{x:44.859244,y:-93.442869},
{x:32.751262,y:-117.205617},
{x:38.65275,y:-77.28605},
{x:32.96592,y:-96.33946},
{x:40.238471,y:-76.935378},
{x:42.099744,y:-88.28657},
{x:41.217559,y:-80.74124},
{x:37.705657,y:-121.890836},
{x:39.287761,y:-76.548304},
{x:40.8303,y:-74.13768},
{x:33.019872,y:-117.110737},
{x:38.913261,y:-94.794208},
{x:41.576883,y:-88.158892},
{x:41.957178,y:-88.073648},
{x:38.597374,y:-121.415885},
{x:44.067703,y:-123.072391},
{x:31.886742,y:-106.579666},
{x:34.166423,y:-84.787322},
{x:27.337573,y:-82.463371},
{x:45.133612,y:-93.476855},
{x:42.048967,y:-88.078899},
{x:33.803882,y:-117.886198},
{x:33.83961,y:-117.888693},
{x:38.261589,y:-122.058443},
{x:33.803658,y:-118.01245},
{x:34.153706,y:-118.796529},
{x:42.139017,y:-87.793098},
{x:36.886684,y:-76.302246},
{x:37.987156,y:-84.525733},
{x:33.172883,y:-96.641614},
{x:40.139538,y:-74.060942},
{x:41.07226,y:-85.200543},
{x:34.138627,y:-117.441473},
{x:39.01694,y:-95.6514},
{x:26.673058,y:-81.928224},
{x:34.077065,y:-118.264695},
{x:45.062711,y:-93.248297},
{x:39.662139,y:-75.608966},
{x:33.71146,y:-112.273076},
{x:42.744014,y:-71.156593},
{x:31.433612,y:-100.456283},
{x:39.2431,y:-84.594132},
{x:34.007163,y:-118.412697},
{x:42.140423,y:-83.225054},
{x:26.094295,y:-80.250364},
{x:33.128246,y:-96.662642},
{x:40.088149,y:-75.392971},
{x:32.798164,y:-96.801621},
{x:26.491847,y:-81.786788},
{x:39.360205,y:-84.459634},
{x:39.561498,y:-76.373064},
{x:40.053901,y:-83.020202},
{x:42.709054,y:-83.306591},
{x:39.939665,y:-76.693203},
{x:39.2724,y:-76.60231},
{x:34.061111,y:-118.446169},
{x:35.061239,y:-80.814391},
{x:26.252864,y:-80.252319},
{x:38.941045,y:-121.09611},
{x:26.121556,y:-80.330717},
{x:25.781982,y:-80.286966},
{x:39.414491,y:-104.86401},
{x:28.054873,y:-82.429836},
{x:35.670273,y:-88.852476},
{x:38.899966,y:-76.791667},
{x:34.117946,y:-80.87743},
{x:41.941367,y:-87.688757},
{x:39.356517,y:-84.259052},
{x:39.157164,y:-84.457622},
{x:33.882375,y:-84.010552},
{x:32.879117,y:-111.711079},
{x:36.161908,y:-115.062329},
{x:32.873118,y:-80.020384},
{x:38.87547,y:-77.006141},
{x:39.19509,y:-76.613549},
{x:42.35067,y:-71.076071},
{x:41.881215,y:-87.635085},
{x:40.743426,y:-73.618029},
{x:40.71269,y:-112.02533},
{x:40.839717,y:-73.917593},
{x:38.905302,y:-77.06455},
{x:40.110393,y:-88.230635},
{x:33.48539,y:-101.90457},
{x:35.352108,y:-119.119109},
{x:42.197655,y:-83.257766},
{x:40.868836,y:-73.826257},
{x:39.61369,y:-77.6916},
{x:30.271266,y:-81.388854},
{x:42.962673,y:-74.187023},
{x:41.693315,y:-83.645571},
{x:37.464747,y:-121.13749},
{x:29.428031,y:-98.712241},
{x:44.487026,y:-87.962984},
{x:42.052363,y:-87.747295},
{x:32.95367,y:-96.853017},
{x:32.729325,y:-97.787634},
{x:29.700977,y:-98.095527},
{x:35.925546,y:-86.817302},
{x:26.882528,y:-80.115425},
{x:38.597105,y:-90.293105},
{x:35.202155,y:-89.792946},
{x:41.731845,y:-71.435526},
{x:43.03712,y:-78.82392},
{x:32.841063,y:-97.238015},
{x:28.189863,y:-80.671335},
{x:38.729048,y:-77.799506},
{x:39.097367,y:-94.582447},
{x:39.73993,y:-104.827753},
{x:38.900173,y:-77.04574},
{x:29.699547,y:-95.687523},
{x:33.856229,y:-84.601193},
{x:43.544465,y:-96.661136},
{x:40.704225,y:-74.013027},
{x:43.18404,y:-76.247989},
{x:38.285897,y:-122.274356},
{x:39.081924,y:-76.946068},
{x:33.378965,y:-86.803812},
{x:40.165289,y:-76.160358},
{x:33.281748,y:-111.75466},
{x:40.2548,y:-75.659758},
{x:33.945614,y:-118.331624},
{x:34.077662,y:-117.557563},
{x:30.418031,y:-91.176726},
{x:38.885013,y:-77.174616},
{x:36.19139,y:-86.2954},
{x:39.630328,y:-106.071411},
{x:35.401167,y:-119.091835},
{x:40.073973,y:-75.431954},
{x:26.172115,y:-81.800797},
{x:33.923606,y:-116.805283},
{x:34.188358,y:-119.222115},
{x:33.684585,y:-117.174999},
{x:40.729731,y:-84.070235},
{x:33.967151,y:-84.02244},
{x:40.117859,y:-83.010696},
{x:43.677565,y:-70.329777},
{x:35.614792,y:-120.683402},
{x:37.868367,y:-122.258959},
{x:37.321714,y:-121.950513},
{x:43.112524,y:-77.550491},
{x:38.042723,y:-84.502723},
{x:40.884361,y:-73.899818},
{x:35.551435,y:-77.405015},
{x:33.580879,y:-111.881926},
{x:40.445559,y:-75.359445},
{x:45.1827,y:-93.3216},
{x:33.610461,y:-112.420607},
{x:39.659689,y:-77.744704},
{x:41.369655,y:-72.070601},
{x:45.059152,y:-93.320954},
{x:39.740578,y:-105.07842},
{x:36.982583,y:-122.029336},
{x:33.422916,y:-84.187152},
{x:41.925684,y:-87.649527},
{x:40.728042,y:-111.551416},
{x:32.65049,y:-96.865799},
{x:32.811189,y:-96.624434},
{x:35.637526,y:-97.484247},
{x:37.521541,y:-122.25271},
{x:38.590036,y:-121.549661},
{x:29.761454,y:-95.75118},
{x:40.743073,y:-89.631157},
{x:38.854013,y:-77.049159},
{x:32.878088,y:-97.158434},
{x:38.943155,y:-76.562083},
{x:36.2989,y:-86.6977},
{x:40.734232,y:-73.98884},
{x:38.725282,y:-76.658776},
{x:36.147259,y:-86.798827},
{x:41.616328,y:-88.122773},
{x:40.867859,y:-73.025935},
{x:33.760371,y:-117.885993},
{x:35.855643,y:-86.430928},
{x:34.104598,y:-118.073861},
{x:38.897516,y:-77.443503},
{x:29.78692,y:-95.792483},
{x:38.672049,y:-121.272114},
{x:32.649141,y:-96.942269},
{x:39.025883,y:-94.248295},
{x:34.506706,y:-117.393807},
{x:42.533882,y:-83.105992},
{x:27.9397,y:-82.483393},
{x:33.396355,y:-84.591743},
{x:41.878341,y:-87.627259},
{x:38.920921,y:-77.072149},
{x:32.706017,y:-97.023371},
{x:41.471713,y:-87.300389},
{x:33.464374,y:-112.273159},
{x:44.899967,y:-123.039413},
{x:32.684737,y:-97.414653},
{x:40.75609,y:-73.980737},
{x:33.249444,y:-111.567122},
{x:40.032681,y:-75.630349},
{x:33.010147,y:-80.045853},
{x:40.739971,y:-73.613576},
{x:39.979821,y:-76.752051},
{x:35.174957,y:-106.574974},
{x:41.243665,y:-75.845769},
{x:25.789777,y:-80.38703},
{x:36.056051,y:-115.171578},
{x:26.571444,y:-80.112039},
{x:37.404326,y:-79.187426},
{x:40.219258,y:-85.394826},
{x:31.834125,y:-106.546125},
{x:32.931846,y:-97.270075},
{x:33.565767,y:-85.076599},
{x:36.127437,y:-115.171639},
{x:38.116729,y:-121.302526},
{x:41.835192,y:-88.025263},
{x:39.878272,y:-83.042323},
{x:41.04235,y:-73.578371},
{x:39.914773,y:-104.976009},
{x:38.947395,y:-92.30007},
{x:33.020492,y:-96.913809},
{x:39.652423,y:-104.903217},
{x:28.46762,y:-81.30863},
{x:33.682577,y:-96.60891},
{x:41.234823,y:-80.450275},
{x:28.2526,y:-81.316273},
{x:41.148172,y:-81.507624},
{x:39.096508,y:-77.192648},
{x:38.83576,y:-77.051201},
{x:38.970588,y:-94.772657},
{x:40.007464,y:-75.212216},
{x:37.756135,y:-122.250143},
{x:38.907536,y:-77.042711},
{x:33.598516,y:-117.871516},
{x:43.09444,y:-70.798843},
{x:41.380305,y:-73.479398},
{x:37.721804,y:-97.363634},
{x:33.438274,y:-112.55831},
{x:39.006281,y:-77.437572},
{x:42.539073,y:-83.359947},
{x:30.141352,y:-81.546654},
{x:42.384224,y:-87.95446},
{x:39.034621,y:-77.407092},
{x:37.289909,y:-80.075636},
{x:45.534839,y:-122.87732},
{x:41.010155,y:-81.589504},
{x:37.803665,y:-122.271377},
{x:42.66965,y:-82.995681},
{x:46.996587,y:-122.819006},
{x:30.16439,y:-95.464889},
{x:35.592746,y:-78.774461},
{x:44.918029,y:-93.502459},
{x:44.776314,y:-93.414129},
{x:26.439234,y:-80.078439},
{x:30.445721,y:-97.744081},
{x:40.059494,y:-80.599991},
{x:37.562558,y:-122.280633},
{x:32.647206,y:-116.96553},
{x:32.817082,y:-79.845065},
{x:39.828657,y:-105.025453},
{x:40.523765,y:-105.03686},
{x:37.603428,y:-77.524983},
{x:27.960183,y:-82.505162},
{x:37.39425,y:-76.527059},
{x:26.846418,y:-80.059898},
{x:35.901908,y:-78.654884},
{x:41.157616,y:-81.228214},
{x:39.209913,y:-94.643014},
{x:39.078143,y:-84.88931},
{x:42.81239,y:-86.087795},
{x:40.617896,y:-73.731298},
{x:47.680845,y:-117.284494},
{x:40.714991,y:-74.011105},
{x:45.095678,y:-93.44131},
{x:28.686288,y:-81.414169},
{x:34.058179,y:-118.419647},
{x:42.516584,y:-71.137851},
{x:33.807429,y:-117.22663},
{x:33.626346,y:-111.924781},
{x:29.563681,y:-95.684298},
{x:34.836763,y:-87.631528},
{x:47.829413,y:-122.273781},
{x:33.107362,y:-96.806229},
{x:34.215632,y:-118.448389},
{x:41.720292,y:-72.620484},
{x:36.843323,y:-121.390773},
{x:29.814954,y:-95.771268},
{x:41.458786,y:-82.01828},
{x:33.902633,y:-117.561865},
{x:32.616801,y:-85.404508},
{x:32.911355,y:-96.803034},
{x:26.307095,y:-80.093134},
{x:33.325549,y:-111.68757},
{x:39.075289,y:-77.137765},
{x:40.709726,y:-74.006437},
{x:39.562066,y:-76.971595},
{x:41.652349,y:-72.729538},
{x:27.573353,y:-99.474004},
{x:29.508403,y:-95.186997},
{x:40.126572,y:-75.40663},
{x:38.458375,y:-122.730127},
{x:41.385365,y:-81.440518},
{x:29.736555,y:-95.514418},
{x:41.82623,y:-89.47571},
{x:29.209106,y:-82.067314},
{x:38.591908,y:-89.985458},
{x:40.958534,y:-81.462868},
{x:38.869485,y:-76.959413},
{x:40.50063,y:-74.85544},
{x:34.087439,y:-117.689171},
{x:30.089045,y:-95.634588},
{x:32.757393,y:-97.713875},
{x:40.1793,y:-74.879},
{x:25.793463,y:-80.337645},
{x:40.86189,y:-74.387713},
{x:42.963114,y:-71.441439},
{x:43.188393,y:-88.128082},
{x:34.924765,y:-120.425807},
{x:41.550104,y:-73.415585},
{x:42.19446,y:-71.28971},
{x:40.759513,y:-74.160434},
{x:35.860384,y:-78.707695},
{x:33.64196,y:-112.225341},
{x:40.274053,y:-80.127251},
{x:39.840741,y:-83.085835},
{x:42.674083,y:-82.829662},
{x:37.383101,y:-121.896718},
{x:36.3289,y:-119.6731},
{x:42.064735,y:-72.51373},
{x:37.724139,y:-97.463274},
{x:38.852589,y:-77.327248},
{x:44.917524,y:-93.188556},
{x:38.134955,y:-85.580565},
{x:32.550373,y:-93.709282},
{x:26.067606,y:-80.36306},
{x:41.875702,y:-84.042525},
{x:34.132404,y:-117.636583},
{x:41.66306,y:-86.11066},
{x:41.392428,y:-73.522496},
{x:26.368752,y:-80.128054},
{x:38.77063,y:-77.082208},
{x:39.596871,y:-104.888084},
{x:40.482568,y:-88.952214},
{x:33.472488,y:-84.443367},
{x:33.576777,y:-117.725076},
{x:42.48195,y:-70.903741},
{x:40.43529,y:-74.390348},
{x:42.051427,y:-87.998346},
{x:41.808292,y:-88.014447},
{x:42.35173,y:-71.067018},
{x:39.678692,y:-75.651317},
{x:32.949697,y:-96.769462},
{x:40.8608,y:-82.2708},
{x:38.780679,y:-90.699027},
{x:47.220126,y:-122.537587},
{x:39.980664,y:-82.82176},
{x:34.023344,y:-118.394364},
{x:38.813032,y:-77.110677},
{x:43.102146,y:-77.540144},
{x:28.568565,y:-81.216403},
{x:38.557678,y:-121.436589},
{x:35.970033,y:-77.809089},
{x:41.099646,y:-81.385935},
{x:40.6864,y:-80.0904},
{x:39.845602,y:-84.1928},
{x:39.029533,y:-95.761236},
{x:33.685416,y:-117.811168},
{x:40.669947,y:-73.931405},
{x:47.665878,y:-117.410728},
{x:39.377833,y:-84.377698},
{x:30.474259,y:-87.209737},
{x:40.901415,y:-81.129039},
{x:39.844931,y:-82.781619},
{x:33.256155,y:-111.638908},
{x:38.897689,y:-77.006884},
{x:32.715146,y:-117.164272},
{x:38.879866,y:-77.112222},
{x:29.548813,y:-98.309425},
{x:28.269062,y:-82.187591},
{x:33.930758,y:-118.325539},
{x:37.732506,y:-122.489986},
{x:40.005756,y:-83.009007},
{x:40.197716,y:-75.083216},
{x:26.849017,y:-80.08515},
{x:33.070588,y:-117.067433},
{x:28.824581,y:-81.640709},
{x:39.432745,y:-76.623175},
{x:43.075327,y:-89.528676},
{x:33.91969,y:-118.39649},
{x:37.415539,y:-77.64121},
{x:27.785704,y:-82.728901},
{x:40.808584,y:-77.903768},
{x:40.656944,y:-73.83996},
{x:41.522654,y:-88.147525},
{x:41.548701,y:-83.600682},
{x:39.652227,y:-84.109395},
{x:33.558168,y:-117.668539},
{x:37.209743,y:-93.275599},
{x:39.02264,y:-76.925743},
{x:34.675176,y:-118.14733},
{x:40.689835,y:-73.983866},
{x:41.312668,y:-73.869829},
{x:41.709328,y:-93.580778},
{x:38.961768,y:-78.189205},
{x:36.035003,y:-86.651283},
{x:42.716033,y:-73.810012},
{x:42.090073,y:-71.42185},
{x:33.04945,y:-117.260551},
{x:48.151763,y:-122.191354},
{x:32.605881,y:-97.408127},
{x:33.676233,y:-111.924005},
{x:39.186768,y:-75.536549},
{x:41.484839,y:-81.801136},
{x:32.840813,y:-116.981522},
{x:34.166181,y:-118.375136},
{x:34.2789,y:-118.866647},
{x:33.479745,y:-112.219873},
{x:45.523363,y:-122.690951},
{x:41.877443,y:-80.75807},
{x:41.178449,y:-73.181922},
{x:39.136266,y:-76.597528},
{x:38.423389,y:-82.419346},
{x:40.778906,y:-73.98116},
{x:40.758225,y:-73.689868},
{x:30.358084,y:-87.165792},
{x:38.60174,y:-90.466729},
{x:39.718549,y:-104.949577},
{x:38.291997,y:-76.493615},
{x:38.842967,y:-77.272162},
{x:39.888081,y:-82.997347},
{x:37.988466,y:-87.473141},
{x:39.777639,y:-104.846422},
{x:47.743233,y:-122.346304},
{x:28.516773,y:-81.376134},
{x:37.680185,y:-97.424519},
{x:39.253091,y:-94.653916},
{x:36.003391,y:-115.110466},
{x:33.377901,y:-111.929047},
{x:39.95419,y:-74.16339},
{x:39.033696,y:-94.357917},
{x:41.649439,y:-87.738744},
{x:28.755741,y:-81.35074},
{x:33.89056,y:-84.753678},
{x:45.496484,y:-122.626761},
{x:38.671993,y:-90.436127},
{x:33.692074,y:-117.335568},
{x:37.549415,y:-77.449898},
{x:41.110501,y:-73.545624},
{x:45.438467,y:-122.828509},
{x:39.153201,y:-77.066147},
{x:39.445845,y:-75.734678},
{x:26.033066,y:-80.142053},
{x:34.027892,y:-117.833865},
{x:38.02905,y:-78.444022},
{x:42.002719,y:-87.661209},
{x:43.154937,y:-76.122084},
{x:41.049712,y:-81.729649},
{x:40.717959,y:-73.592126},
{x:39.982811,y:-83.004472},
{x:39.18359,y:-76.850249},
{x:37.502188,y:-122.246332},
{x:42.021784,y:-88.203724},
{x:37.73085,y:-97.206474},
{x:39.38097,y:-84.549157},
{x:33.463832,y:-112.358647},
{x:40.283716,y:-76.654587},
{x:41.416945,y:-82.208387},
{x:40.733578,y:-73.999878},
{x:40.682556,y:-74.234111},
{x:42.2758,y:-83.77831},
{x:43.047711,y:-76.237321},
{x:35.815496,y:-80.876032},
{x:38.007286,y:-84.517454},
{x:38.920086,y:-76.952888},
{x:45.022001,y:-93.168834},
{x:45.012778,y:-93.170237},
{x:42.046001,y:-88.038216},
{x:40.13435,y:-74.223073},
{x:39.398562,y:-76.933013},
{x:36.811035,y:-119.808014},
{x:34.061156,y:-117.182877},
{x:40.063345,y:-75.236689},
{x:27.96499,y:-82.521147},
{x:28.073064,y:-82.738312},
{x:40.106537,y:-75.002213},
{x:32.923707,y:-96.898415},
{x:41.891812,y:-87.628493},
{x:28.931606,y:-81.932597},
{x:44.959411,y:-92.720856},
{x:40.04916,y:-82.913825},
{x:31.366301,y:-110.936838},
{x:37.319654,y:-120.486286},
{x:41.661348,y:-81.240686},
{x:40.708132,y:-73.707054},
{x:40.399648,y:-82.459244},
{x:44.924186,y:-93.409958},
{x:41.173868,y:-87.85215},
{x:33.845894,y:-84.256033},
{x:34.151531,y:-118.453641},
{x:42.514302,y:-83.047697},
{x:42.164465,y:-71.199587},
{x:30.394524,y:-90.092283},
{x:38.42476,y:-77.43089},
{x:33.493047,y:-117.69776},
{x:32.773612,y:-117.02693},
{x:45.621403,y:-122.553009},
{x:34.852814,y:-117.082788},
{x:34.794012,y:-92.400984},
{x:44.955352,y:-93.297214},
{x:28.49295,y:-81.428624},
{x:39.37847,y:-84.222102},
{x:28.68563,y:-81.532751},
{x:40.764454,y:-73.09185},
{x:39.611253,y:-104.752579},
{x:41.887284,y:-87.624809},
{x:41.242967,y:-111.970573},
{x:40.839046,y:-73.315956},
{x:41.632041,y:-87.931793},
{x:39.260609,y:-84.793431},
{x:42.98015,y:-78.86915},
{x:35.324745,y:-119.128107},
{x:34.394452,y:-118.462748},
{x:38.912167,y:-94.396644},
{x:36.836165,y:-87.473652},
{x:35.956584,y:-83.932753},
{x:36.576644,y:-119.631736},
{x:41.513345,y:-72.106799},
{x:38.804511,y:-90.307734},
{x:38.953852,y:-94.7179},
{x:32.205924,y:-110.790107},
{x:35.102096,y:-89.865546},
{x:34.135728,y:-118.049735},
{x:42.150131,y:-71.487961},
{x:39.674409,y:-104.869623},
{x:37.94587,y:-121.69581},
{x:42.226642,y:-88.294975},
{x:28.584613,y:-80.802148},
{x:38.3238,y:-85.744528},
{x:30.454879,y:-88.904974},
{x:28.821624,y:-81.783433},
{x:42.048587,y:-87.681225},
{x:43.042929,y:-76.068459},
{x:39.951231,y:-83.83},
{x:41.810574,y:-87.968129},
{x:41.966453,y:-73.989844},
{x:38.784547,y:-77.016889},
{x:37.511047,y:-121.940733},
{x:38.907889,y:-77.40874},
{x:42.350695,y:-71.11467},
{x:39.389981,y:-84.504327},
{x:44.859373,y:-93.534467},
{x:33.876523,y:-118.217833},
{x:42.925167,y:-72.289786},
{x:43.069614,y:-77.441093},
{x:29.76982,y:-95.181946},
{x:33.773906,y:-84.363588},
{x:32.915137,y:-97.313821},
{x:41.380021,y:-81.788187},
{x:27.234775,y:-80.26764},
{x:39.915645,y:-82.780962},
{x:40.825455,y:-73.919076},
{x:33.108596,y:-83.253691},
{x:33.80082,y:-116.521662},
{x:37.995827,y:-122.286073},
{x:34.439451,y:-119.74914},
{x:34.266791,y:-119.246065},
{x:38.594345,y:-121.28845},
{x:37.524551,y:-77.438942},
{x:39.59457,y:-104.806401},
{x:39.840318,y:-75.092033},
{x:32.262371,y:-110.9441},
{x:39.969929,y:-74.914218},
{x:27.804778,y:-82.638811},
{x:39.057066,y:-94.605593},
{x:41.667267,y:-70.296814},
{x:33.615295,y:-117.930623},
{x:44.945463,y:-93.095372},
{x:39.954087,y:-75.200753},
{x:38.897084,y:-104.852678},
{x:37.413534,y:-121.896827},
{x:28.629809,y:-81.318099},
{x:41.85824,y:-88.1399},
{x:41.258773,y:-96.023232},
{x:25.894804,y:-80.350866},
{x:42.104781,y:-87.814037},
{x:33.94685,y:-84.334045},
{x:42.272591,y:-71.02721},
{x:41.626806,y:-81.429633},
{x:33.829876,y:-84.491645},
{x:27.932241,y:-82.324896},
{x:33.665923,y:-112.203393},
{x:33.715336,y:-84.214988},
{x:29.647749,y:-98.449806},
{x:34.303285,y:-83.858625},
{x:32.0053,y:-81.11393},
{x:41.879946,y:-87.805122},
{x:41.762015,y:-72.742908},
{x:42.641537,y:-73.70131},
{x:40.329502,y:-76.862762},
{x:34.073164,y:-83.984773},
{x:47.606689,y:-122.628571},
{x:37.580028,y:-120.941808},
{x:41.551197,y:-87.180853},
{x:40.150348,y:-75.001692},
{x:33.112927,y:-117.101017},
{x:39.02792,y:-77.076746},
{x:41.931623,y:-87.686359},
{x:42.346038,y:-122.883924},
{x:40.876316,y:-79.946144},
{x:41.058023,y:-83.670918},
{x:29.944487,y:-93.994997},
{x:39.495593,y:-76.327773},
{x:28.697223,y:-81.263633},
{x:41.271229,y:-72.972761},
{x:40.276972,y:-76.8251},
{x:34.069278,y:-117.261854},
{x:36.942751,y:-86.421114},
{x:31.617023,y:-84.221769},
{x:43.232666,y:-123.372444},
{x:26.008504,y:-80.294944},
{x:37.699108,y:-121.741628},
{x:32.222101,y:-110.877658},
{x:33.738685,y:-117.919629},
{x:40.890877,y:-111.892677},
{x:32.043869,y:-81.072026},
{x:39.640233,y:-104.792049},
{x:31.8899,y:-102.35041},
{x:31.90995,y:-110.979962},
{x:42.025247,y:-88.143711},
{x:34.170359,y:-118.540585},
{x:41.135712,y:-81.797782},
{x:45.303293,y:-122.766396},
{x:29.819122,y:-95.467393},
{x:32.913856,y:-117.146019},
{x:30.622351,y:-96.341343},
{x:36.120082,y:-79.842742},
{x:38.617043,y:-121.26897},
{x:30.127648,y:-95.45431},
{x:33.860571,y:-117.792008},
{x:41.953245,y:-87.744923},
{x:36.665904,y:-121.809654},
{x:42.601755,y:-73.794693},
{x:39.856222,y:-86.392553},
{x:39.65349,y:-104.992173},
{x:41.771193,y:-88.147656},
{x:34.552493,y:-82.678134},
{x:36.424148,y:-77.633236},
{x:42.947148,y:-78.868615},
{x:27.006174,y:-82.133263},
{x:33.741786,y:-112.323198},
{x:33.801507,y:-116.387342},
{x:38.407021,y:-121.417074},
{x:33.674754,y:-111.975293},
{x:41.088928,y:-111.98468},
{x:40.529063,y:-80.008719},
{x:40.783362,y:-74.192425},
{x:29.510427,y:-98.57829},
{x:42.486291,y:-71.262617},
{x:29.698307,y:-95.518431},
{x:40.671143,y:-73.977664},
{x:40.694265,y:-73.508369},
{x:40.626429,y:-73.916943},
{x:39.335366,y:-76.485039},
{x:37.164597,y:-80.421046},
{x:42.463939,y:-83.137454},
{x:38.985654,y:-77.096138},
{x:30.319345,y:-81.55503},
{x:41.843487,y:-71.409187},
{x:40.27002,y:-76.68758},
{x:40.766668,y:-73.962605},
{x:40.728236,y:-74.005126},
{x:40.856003,y:-96.682602},
{x:39.897961,y:-88.955963},
{x:40.746042,y:-73.993967},
{x:41.30217,y:-73.22381},
{x:33.510338,y:-112.046281},
{x:34.062163,y:-118.3502},
{x:39.001518,y:-84.652732},
{x:33.940735,y:-84.122714},
{x:35.528736,y:-82.606817},
{x:33.136349,y:-117.176569},
{x:40.493079,y:-81.472435},
{x:32.542182,y:-94.73356},
{x:30.189106,y:-85.653816},
{x:39.101973,y:-84.512958},
{x:34.785227,y:-86.945742},
{x:41.750135,y:-88.11521},
{x:29.997368,y:-95.163998},
{x:44.967347,y:-93.437982},
{x:45.158322,y:-93.390783},
{x:39.095882,y:-84.386883},
{x:41.317747,y:-74.125412},
{x:37.554387,y:-121.981729},
{x:39.037629,y:-84.454721},
{x:42.338745,y:-71.5914},
{x:38.828222,y:-77.438218},
{x:39.793934,y:-105.080282},
{x:39.289776,y:-84.466155},
{x:33.6667,y:-112.123219},
{x:28.54576,y:-81.730085},
{x:32.937631,y:-97.186809},
{x:31.559827,y:-110.257965},
{x:38.847884,y:-77.12099},
{x:39.203586,y:-77.247937},
{x:42.566596,y:-71.422779},
{x:25.773037,y:-80.264107},
{x:39.412696,y:-76.772272},
{x:33.788626,y:-84.326656},
{x:33.448066,y:-112.074525},
{x:35.807005,y:-78.81519},
{x:38.994551,y:-94.474417},
{x:43.332589,y:-73.675548},
{x:38.977812,y:-76.487798},
{x:42.353418,y:-71.058025},
{x:41.86465,y:-71.448916},
{x:41.156113,y:-81.405807},
{x:25.941296,y:-80.306469},
{x:42.26449,y:-88.980615},
{x:47.805906,y:-122.206571},
{x:36.041365,y:-114.979392},
{x:39.372168,y:-76.61022},
{x:34.106191,y:-117.568843},
{x:31.20587,y:-82.33601},
{x:33.818736,y:-117.941747},
{x:34.348681,y:-84.050008},
{x:41.597726,y:-93.750277},
{x:30.005845,y:-95.269299},
{x:38.799654,y:-77.3201},
{x:33.046072,y:-97.010343},
{x:41.412378,y:-81.536786},
{x:41.70389,y:-88.30703},
{x:35.506173,y:-78.32157},
{x:38.78955,y:-121.280949},
{x:37.352049,y:-121.960205},
{x:35.223442,y:-111.585215},
{x:39.116807,y:-94.760043},
{x:34.0436,y:-84.341502},
{x:39.912852,y:-86.134278},
{x:44.894066,y:-93.419739},
{x:33.39831,y:-84.739745},
{x:47.659107,y:-117.197129},
{x:41.850979,y:-87.743851},
{x:37.350009,y:-79.228666},
{x:43.123427,y:-77.617732},
{x:45.30128,y:-93.555077},
{x:38.944513,y:-76.735131},
{x:38.243616,y:-122.62638},
{x:34.018922,y:-118.105875},
{x:43.198695,y:-77.577404},
{x:39.164209,y:-86.495069},
{x:34.602263,y:-118.150175},
{x:41.501434,y:-81.503974},
{x:41.949713,y:-88.032293},
{x:37.65373,y:-77.615732},
{x:28.435619,y:-81.301496},
{x:35.337714,y:-119.035162},
{x:35.76393,y:-83.98878},
{x:42.849426,y:-85.625954},
{x:32.761239,y:-117.066656},
{x:37.811335,y:-121.299219},
{x:32.817779,y:-96.812016},
{x:40.347907,y:-80.054165},
{x:40.736775,y:-73.247641},
{x:39.701821,y:-104.912519},
{x:41.276864,y:-80.773925},
{x:38.655529,y:-121.523951},
{x:33.417977,y:-111.940362},
{x:33.504292,y:-84.238686},
{x:30.639298,y:-96.317468},
{x:29.5124,y:-98.39628},
{x:38.349695,y:-75.60252},
{x:47.385218,y:-122.235006},
{x:37.976754,y:-122.035452},
{x:41.618,y:-85.894001},
{x:39.01065,y:-74.86988},
{x:39.871139,y:-104.976335},
{x:37.788882,y:-122.396157},
{x:41.064542,y:-74.74591},
{x:27.947477,y:-82.52393},
{x:40.92577,y:-72.692128},
{x:35.978651,y:-86.564851},
{x:28.646918,y:-81.270118},
{x:30.358578,y:-97.734726},
{x:33.810169,y:-84.41306},
{x:30.533027,y:-87.227573},
{x:42.076748,y:-78.461659},
{x:37.275171,y:-120.432291},
{x:42.723734,y:-88.995163},
{x:44.463611,y:-73.181116},
{x:31.117267,y:-97.417944},
{x:35.359919,y:-89.892458},
{x:41.91058,y:-87.68696},
{x:42.096473,y:-72.600274},
{x:26.400771,y:-80.12049},
{x:39.113193,y:-77.536941},
{x:40.7312,y:-74.0645},
{x:41.15056,y:-81.863833},
{x:40.108926,y:-82.925771},
{x:34.236922,y:-118.535728},
{x:29.352259,y:-98.437233},
{x:36.199188,y:-81.657847},
{x:40.632739,y:-74.305283},
{x:34.049185,y:-118.375836},
{x:37.945873,y:-122.061647},
{x:35.188631,y:-101.866807},
{x:41.497032,y:-72.806633},
{x:41.560916,y:-90.586689},
{x:39.983044,y:-76.668837},
{x:35.18161,y:-80.915386},
{x:39.337924,y:-76.635505},
{x:33.56814,y:-117.708595},
{x:33.494946,y:-112.01464},
{x:47.613342,y:-122.320946},
{x:42.112768,y:-72.722707},
{x:41.499411,y:-81.691688},
{x:33.349748,y:-111.901478},
{x:41.797449,y:-88.118673},
{x:38.29398,y:-85.560009},
{x:37.314568,y:-121.871725},
{x:33.874546,y:-117.890095},
{x:27.417304,y:-80.381779},
{x:34.005282,y:-81.118209},
{x:40.714498,y:-74.006234},
{x:39.046592,y:-94.446975},
{x:39.580227,y:-104.961312},
{x:35.90347,y:-78.941542},
{x:41.033315,y:-73.764409},
{x:28.11834,y:-81.64031},
{x:37.743279,y:-88.975686},
{x:39.811396,y:-105.139667},
{x:41.314096,y:-81.654757},
{x:39.776151,y:-84.075405},
{x:42.554147,y:-83.028798},
{x:34.187781,y:-118.597917},
{x:41.946918,y:-87.655487},
{x:41.790968,y:-87.80108},
{x:39.631733,y:-79.954639},
{x:32.907832,y:-96.769701},
{x:39.721118,y:-75.115195},
{x:38.901985,y:-94.525386},
{x:38.296485,y:-77.513437},
{x:39.748098,y:-75.07583},
{x:28.553564,y:-81.206557},
{x:37.151203,y:-121.656045},
{x:38.983726,y:-84.399519},
{x:38.335852,y:-75.101551},
{x:47.666691,y:-122.102234},
{x:25.979075,y:-80.314588},
{x:44.721978,y:-93.179276},
{x:41.885602,y:-87.635243},
{x:33.956726,y:-80.388801},
{x:36.591682,y:-82.260116},
{x:42.285611,y:-71.608542},
{x:32.827182,y:-96.845921},
{x:38.004794,y:-122.544694},
{x:37.737989,y:-122.19751},
{x:39.957561,y:-85.997292},
{x:30.30565,y:-97.707981},
{x:33.599546,y:-111.981773},
{x:46.904292,y:-96.796588},
{x:38.797009,y:-77.517141},
{x:33.581565,y:-112.125103},
{x:42.1095,y:-87.974},
{x:39.731452,y:-82.61394},
{x:41.216179,y:-73.721603},
{x:34.578152,y:-112.362396},
{x:32.286145,y:-110.975659},
{x:37.50536,y:-77.66366},
{x:30.115381,y:-94.169175},
{x:30.478321,y:-97.800583},
{x:40.27876,y:-74.530297},
{x:42.486162,y:-71.212724},
{x:34.075866,y:-118.373895},
{x:40.795288,y:-73.971718},
{x:40.819096,y:-73.927409},
{x:43.47945,y:-83.970125},
{x:41.726463,y:-86.250046},
{x:33.443379,y:-117.613782},
{x:25.827346,y:-80.368546},
{x:38.98861,y:-104.796164},
{x:40.116714,y:-75.285336},
{x:28.066482,y:-82.570505},
{x:26.934223,y:-80.130662},
{x:39.923598,y:-75.14156},
{x:25.686898,y:-80.36474},
{x:41.932846,y:-87.668178},
{x:32.955802,y:-117.037873},
{x:47.71563,y:-117.436882},
{x:43.016354,y:-78.203918},
{x:37.544689,y:-122.291481},
{x:42.092976,y:-71.054373},
{x:42.058019,y:-80.09017},
{x:33.64424,y:-78.98683},
{x:41.985655,y:-88.341586},
{x:38.898363,y:-77.11876},
{x:36.06366,y:-115.252113},
{x:45.055094,y:-93.364971},
{x:37.423923,y:-122.14322},
{x:41.103181,y:-80.647111},
{x:42.491537,y:-90.720086},
{x:40.246065,y:-77.014393},
{x:42.275389,y:-71.749783},
{x:45.011113,y:-122.996269},
{x:41.362623,y:-75.680485},
{x:40.547867,y:-74.334464},
{x:37.868863,y:-122.26751},
{x:35.880857,y:-86.383934},
{x:30.173643,y:-81.627794},
{x:37.823223,y:-122.004014},
{x:41.237845,y:-81.806825},
{x:42.760691,y:-84.506112},
{x:34.967113,y:-81.892263},
{x:36.098833,y:-115.295744},
{x:38.910014,y:-77.032171},
{x:34.79121,y:-92.22813},
{x:26.046949,y:-80.251057},
{x:32.870621,y:-96.769003},
{x:39.866835,y:-75.080053},
{x:33.524581,y:-112.098773},
{x:33.479711,y:-111.986121},
{x:42.240787,y:-87.992757},
{x:32.868969,y:-96.77351},
{x:29.993144,y:-95.751294},
{x:32.799725,y:-117.242512},
{x:39.015539,y:-77.374525},
{x:26.260632,y:-80.09892},
{x:32.831741,y:-117.165205},
{x:41.720023,y:-87.776558},
{x:28.512078,y:-81.459718},
{x:39.958456,y:-75.258049},
{x:29.436426,y:-98.649401},
{x:39.938301,y:-75.353662},
{x:38.650555,y:-90.338435},
{x:41.853017,y:-88.339451},
{x:41.877852,y:-87.635373},
{x:42.533484,y:-71.743503},
{x:44.811215,y:-93.329092},
{x:35.238637,y:-97.481421},
{x:39.024896,y:-77.147045},
{x:42.070495,y:-88.335675},
{x:38.005509,y:-121.839451},
{x:34.05428,y:-83.993618},
{x:39.997527,y:-83.007382},
{x:28.355704,y:-81.676349},
{x:35.768814,y:-86.917722},
{x:38.599121,y:-90.448845},
{x:38.84647,y:-76.980385},
{x:41.881865,y:-87.63277},
{x:43.508308,y:-70.434967},
{x:33.200581,y:-117.244873},
{x:40.504275,y:-88.99489},
{x:38.740262,y:-77.212982},
{x:44.74756,y:-93.288722},
{x:37.819104,y:-122.262346},
{x:43.048482,y:-87.904246},
{x:32.722217,y:-97.115236},
{x:33.884534,y:-84.31582},
{x:38.846268,y:-76.885284},
{x:40.189192,y:-105.101798},
{x:37.122334,y:-113.619568},
{x:40.74724,y:-73.941628},
{x:40.650509,y:-75.29484},
{x:40.797693,y:-81.486157},
{x:38.299265,y:-77.483694},
{x:40.807288,y:-73.945063},
{x:33.860461,y:-118.092518},
{x:34.146268,y:-118.115557},
{x:34.108384,y:-117.669856},
{x:30.426197,y:-86.640595},
{x:34.092527,y:-118.145827},
{x:41.600804,y:-87.794607},
{x:40.777727,y:-73.954542},
{x:34.848209,y:-82.337894},
{x:30.2693,y:-89.7606},
{x:38.845476,y:-104.756686},
{x:38.419605,y:-82.261201},
{x:37.255664,y:-79.955007},
{x:40.273552,y:-111.678505},
{x:31.924665,y:-106.439021},
{x:42.255368,y:-83.693385},
{x:38.998603,y:-76.912146},
{x:41.49225,y:-87.50578},
{x:36.085349,y:-115.119382},
{x:34.034396,y:-84.052234},
{x:41.463702,y:-81.952375},
{x:33.887511,y:-117.862948},
{x:42.50189,y:-83.53699},
{x:33.176517,y:-111.581497},
{x:36.852114,y:-119.788978},
{x:47.75708,y:-122.65918},
{x:40.644172,y:-74.577208},
{x:39.984303,y:-74.180515},
{x:33.988177,y:-84.272338},
{x:35.211303,y:-84.863588},
{x:33.034801,y:-80.158297},
{x:35.840181,y:-78.643501},
{x:40.861558,y:-74.062319},
{x:36.168654,y:-80.277207},
{x:47.119021,y:-122.434299},
{x:40.844952,y:-74.467307},
{x:42.938708,y:-88.047734},
{x:38.946376,y:-104.802166},
{x:39.770859,y:-84.053009},
{x:41.509752,y:-81.603895},
{x:42.782864,y:-71.505122},
{x:41.384207,y:-81.73832},
{x:34.125591,y:-116.407289},
{x:27.318122,y:-82.5294},
{x:33.196767,y:-87.52704},
{x:42.44571,y:-96.343208},
{x:39.739977,y:-104.957214},
{x:39.131107,y:-84.610291},
{x:38.343099,y:-77.494231},
{x:33.496414,y:-81.962333},
{x:30.161779,y:-92.053976},
{x:32.755116,y:-97.429336},
{x:32.163913,y:-110.989487},
{x:40.741755,y:-73.993364},
{x:37.649538,y:-97.353198},
{x:37.59665,y:-122.38617},
{x:32.29719,y:-95.301791},
{x:36.0807,y:-119.043549},
{x:39.660754,y:-105.080916},
{x:36.54969,y:-82.525271},
{x:40.295026,y:-74.684338},
{x:39.200752,y:-76.812958},
{x:47.574662,y:-122.168647},
{x:38.992351,y:-94.595414},
{x:41.397005,y:-82.106523},
{x:29.665194,y:-95.188335},
{x:39.142598,y:-121.629673},
{x:38.963637,y:-77.399038},
{x:40.083489,y:-82.427203},
{x:39.312449,y:-76.755355},
{x:38.927964,y:-94.697999},
{x:41.090926,y:-73.45563},
{x:33.937825,y:-118.129544},
{x:28.290794,y:-82.711856},
{x:32.706451,y:-97.389039},
{x:25.940701,y:-80.244756},
{x:38.303272,y:-104.614993},
{x:32.979751,y:-117.082604},
{x:45.035551,y:-93.019509},
{x:33.917263,y:-118.065426},
{x:33.586073,y:-111.925521},
{x:29.564055,y:-95.562294},
{x:33.90122,y:-118.373185},
{x:42.359946,y:-88.26651},
{x:40.785549,y:-73.727394},
{x:47.437687,y:-120.323562},
{x:36.88124,y:-76.424366},
{x:32.595463,y:-97.148099},
{x:39.684779,y:-84.168211},
{x:41.895218,y:-87.623167},
{x:33.895346,y:-84.142392},
{x:40.745453,y:-73.982831},
{x:41.369121,y:-72.918123},
{x:48.231424,y:-114.331391},
{x:41.502393,y:-87.886747},
{x:44.628979,y:-123.080796},
{x:33.782981,y:-118.072325},
{x:36.105401,y:-86.812737},
{x:39.81605,y:-85.7696},
{x:26.457981,y:-80.118699},
{x:38.547027,y:-76.984461},
{x:34.037266,y:-84.461123},
{x:39.858352,y:-84.277631},
{x:33.657308,y:-84.499326},
{x:28.188057,y:-82.551875},
{x:43.397641,y:-88.18726},
{x:39.728341,y:-104.940977},
{x:44.835976,y:-93.151959},
{x:39.299562,y:-84.314295},
{x:39.116712,y:-77.198399},
{x:40.723399,y:-99.083389},
{x:39.373169,y:-76.542551},
{x:40.720187,y:-74.563005},
{x:42.368607,y:-71.076583},
{x:33.8873,y:-118.1022},
{x:33.865267,y:-84.469901},
{x:35.204546,y:-106.649959},
{x:32.88015,y:-97.098193},
{x:33.859707,y:-117.923852},
{x:34.472154,y:-114.34274},
{x:36.108443,y:-80.097688},
{x:33.698406,y:-117.740873},
{x:34.753684,y:-92.412967},
{x:36.174831,y:-86.514323},
{x:25.980247,y:-80.356945},
{x:37.879525,y:-122.296172},
{x:38.713223,y:-120.840127},
{x:29.750156,y:-95.477381},
{x:41.801708,y:-88.172313},
{x:41.799695,y:-87.588031},
{x:39.87009,y:-86.141552},
{x:34.013287,y:-118.336632},
{x:33.320894,y:-111.633465},
{x:35.707334,y:-81.308264},
{x:33.479339,y:-111.925741},
{x:33.000531,y:-96.962609},
{x:40.333354,y:-79.945873},
{x:46.804871,y:-92.1597},
{x:29.747052,y:-95.410295},
{x:41.097514,y:-74.012496},
{x:30.218221,y:-85.873395},
{x:30.285652,y:-97.741979},
{x:38.574048,y:-121.482742},
{x:38.469389,y:-77.411947},
{x:33.639077,y:-117.594022},
{x:33.728137,y:-116.403037},
{x:41.868037,y:-87.641059},
{x:40.582943,y:-74.614439},
{x:42.172811,y:-72.578186},
{x:40.397385,y:-75.929153},
{x:40.919302,y:-74.064849},
{x:29.014406,y:-80.941199},
{x:39.717486,y:-105.133629},
{x:32.821055,y:-96.788102},
{x:36.04594,y:-80.259051},
{x:34.038421,y:-118.144575},
{x:38.913387,y:-94.76152},
{x:34.986516,y:-118.946312},
{x:42.625047,y:-71.362388},
{x:34.020989,y:-117.810495},
{x:40.088346,y:-75.382726},
{x:42.884279,y:-85.739756},
{x:32.7606,y:-96.8592},
{x:42.565678,y:-87.92112},
{x:32.902614,y:-96.454727},
{x:41.023823,y:-80.643508},
{x:34.316284,y:-83.793518},
{x:42.3798,y:-88.0627},
{x:38.13697,y:-122.215023},
{x:26.186609,y:-97.721518},
{x:40.315556,y:-75.310181},
{x:40.143923,y:-82.989408},
{x:39.926457,y:-86.090599},
{x:33.304753,y:-111.993608},
{x:33.916839,y:-117.958823},
{x:30.20628,y:-95.463578},
{x:40.056496,y:-84.243259},
{x:39.916446,y:-75.333776},
{x:39.45401,y:-83.78094},
{x:47.606125,y:-122.036285},
{x:40.718625,y:-74.329505},
{x:37.235261,y:-121.963763},
{x:40.75566,y:-73.991358},
{x:26.091092,y:-80.180294},
{x:40.669464,y:-89.583838},
{x:39.761531,y:-121.842141},
{x:40.370717,y:-111.916485},
{x:40.047823,y:-75.058301},
{x:41.940334,y:-83.405395},
{x:38.769915,y:-77.139712},
{x:40.5086,y:-111.9996},
{x:36.104015,y:-95.911355},
{x:39.694858,y:-84.103369},
{x:33.082009,y:-117.235829},
{x:25.769252,y:-80.359821},
{x:41.609862,y:-81.525876},
{x:38.231852,y:-77.502378},
{x:39.072193,y:-84.312245},
{x:35.088667,y:-106.514546},
{x:34.06404,y:-118.202104},
{x:33.069765,y:-97.08218},
{x:35.134751,y:-89.993488},
{x:33.9781,y:-118.04924},
{x:31.688641,y:-106.265579},
{x:36.02166,y:-115.117149},
{x:39.270764,y:-76.505434},
{x:30.233939,y:-97.739224},
{x:40.417906,y:-86.831375},
{x:28.001635,y:-81.956539},
{x:40.892511,y:-73.818514},
{x:39.813656,y:-94.813872},
{x:40.629985,y:-75.480292},
{x:39.22101,y:-85.889226},
{x:43.67125,y:-116.283004},
{x:40.413529,y:-79.904522},
{x:37.67885,y:-122.08481},
{x:33.562096,y:-84.583331},
{x:33.640489,y:-112.01374},
{x:29.780353,y:-95.540002},
{x:41.722552,y:-85.976624},
{x:32.459167,y:-86.391323},
{x:33.762912,y:-116.301207},
{x:34.181199,y:-84.133121},
{x:40.759369,y:-73.982608},
{x:36.808313,y:-119.872126},
{x:37.551144,y:-122.051486},
{x:41.554779,y:-72.679245},
{x:34.139865,y:-118.012466},
{x:38.632632,y:-90.233931},
{x:40.059006,y:-74.142208},
{x:31.531142,y:-97.187941},
{x:40.568784,y:-80.023275},
{x:28.55143,y:-81.585396},
{x:38.888928,y:-94.607339},
{x:41.05554,y:-83.591011},
{x:40.706723,y:-74.00697},
{x:41.162401,y:-96.023306},
{x:43.634542,y:-116.645189},
{x:39.949496,y:-75.166664},
{x:34.021459,y:-84.120335},
{x:41.888777,y:-87.804045},
{x:39.704048,y:-74.275577},
{x:37.658794,y:-121.898122},
{x:42.814072,y:-71.101594},
{x:40.093564,y:-82.823459},
{x:35.722103,y:-78.652367},
{x:45.723376,y:-122.661621},
{x:35.504615,y:-82.523931},
{x:32.140266,y:-81.243508},
{x:35.211225,y:-80.690015},
{x:38.417932,y:-122.712458},
{x:42.366051,y:-89.023489},
{x:45.527546,y:-122.815506},
{x:38.980485,y:-76.938948},
{x:34.054046,y:-118.262461},
{x:33.687593,y:-117.954502},
{x:34.75202,y:-92.341761},
{x:40.735686,y:-73.87485},
{x:30.597425,y:-87.898844},
{x:41.725022,y:-81.248413},
{x:41.758678,y:-87.944339},
{x:37.895627,y:-122.061109},
{x:25.749193,y:-80.336108},
{x:28.493258,y:-81.509186},
{x:34.073997,y:-117.205073},
{x:42.070549,y:-88.191161},
{x:37.249866,y:-121.876427},
{x:37.308965,y:-79.887801},
{x:33.389609,y:-111.856892},
{x:41.78479,y:-87.868119},
{x:35.531194,y:-97.57533},
{x:42.351059,y:-71.045746},
{x:28.450619,y:-81.488967},
{x:41.546443,y:-87.79326},
{x:40.585387,y:-122.353979},
{x:40.895324,y:-73.976161},
{x:33.491208,y:-82.080402},
{x:39.912143,y:-86.218409},
{x:32.645508,y:-117.002167},
{x:39.439133,y:-119.759254},
{x:44.006074,y:-92.464226},
{x:42.483718,y:-83.47416},
{x:39.011697,y:-84.630136},
{x:40.452126,y:-86.915367},
{x:34.755027,y:-77.460497},
{x:45.454356,y:-122.77552},
{x:39.019039,y:-84.693862},
{x:30.274705,y:-97.799339},
{x:42.092832,y:-75.952797},
{x:33.017902,y:-97.218136},
{x:40.71636,y:-73.96001},
{x:29.972817,y:-95.691465},
{x:29.673671,y:-82.338755},
{x:39.678281,y:-104.967812},
{x:34.933609,y:-80.834683},
{x:39.327956,y:-82.101542},
{x:48.452735,y:-122.335691},
{x:26.202566,y:-98.20195},
{x:26.287408,y:-80.250916},
{x:41.157041,y:-73.240621},
{x:40.841407,y:-73.843824},
{x:39.78517,y:-83.99743},
{x:39.092887,y:-84.263778},
{x:40.146288,y:-82.922796},
{x:33.72293,y:-117.77245},
{x:29.548579,y:-98.66504},
{x:44.853598,y:-93.242074},
{x:34.434396,y:-103.195928},
{x:39.687652,y:-75.652467},
{x:47.613607,y:-122.199857},
{x:41.27763,y:-72.827453},
{x:38.592211,y:-90.584408},
{x:32.541783,y:-84.950318},
{x:38.749204,y:-77.4489},
{x:35.063086,y:-85.309764},
{x:34.097856,y:-118.356916},
{x:38.823428,y:-76.918471},
{x:36.140823,y:-95.968597},
{x:44.790697,y:-93.211784},
{x:37.786139,y:-122.281214},
{x:47.663348,y:-122.696448},
{x:33.851625,y:-84.210044},
{x:40.235762,y:-74.941877},
{x:28.345635,y:-81.420783},
{x:40.878496,y:-73.904408},
{x:26.065696,y:-81.69842},
{x:39.938992,y:-86.23526},
{x:31.729102,y:-106.30334},
{x:34.079826,y:-117.719604},
{x:38.676,y:-76.8276},
{x:40.982711,y:-81.493884},
{x:39.451197,y:-74.637708},
{x:41.761894,y:-71.4589},
{x:43.97848,y:-75.947266},
{x:41.8906,y:-87.961391},
{x:38.804619,y:-121.206535},
{x:39.35327,y:-84.361763},
{x:41.028602,y:-81.512372},
{x:35.609492,y:-117.670123},
{x:32.997719,y:-96.668754},
{x:33.658126,y:-117.918601},
{x:33.787203,y:-117.816559},
{x:41.628508,y:-86.252677},
{x:34.033712,y:-117.756774},
{x:25.912522,y:-80.157698},
{x:34.151701,y:-118.080225},
{x:40.354118,y:-80.114269},
{x:42.279038,y:-87.871489},
{x:40.814802,y:-73.41115},
{x:39.304333,y:-76.615981},
{x:39.149709,y:-76.909905},
{x:33.814719,y:-84.310156},
{x:42.283981,y:-87.953805},
{x:39.10693,y:-75.54102},
{x:38.870591,y:-77.153138},
{x:40.796718,y:-77.857276},
{x:37.711362,y:-120.958054},
{x:34.24966,y:-83.46104},
{x:42.375759,y:-71.252849},
{x:34.003846,y:-84.579238},
{x:33.650854,y:-117.74293},
{x:33.911769,y:-117.882136},
{x:32.531471,y:-97.338764},
{x:35.915784,y:-81.525122},
{x:39.60052,y:-75.804095},
{x:47.711533,y:-122.181874},
{x:40.536275,y:-74.296361},
{x:38.89534,y:-77.07053},
{x:39.42818,y:-80.1881},
{x:38.34733,y:-122.710127},
{x:36.069426,y:-80.29796},
{x:41.573157,y:-90.529315},
{x:38.883055,y:-94.752265},
{x:41.26584,y:-96.237002},
{x:43.075013,y:-89.396766},
{x:25.749328,y:-80.254359},
{x:42.40649,y:-82.915806},
{x:38.898906,y:-77.022229},
{x:37.002972,y:-121.551949},
{x:40.803907,y:-74.248848},
{x:39.388398,y:-76.736214},
{x:38.431785,y:-75.055966},
{x:36.239537,y:-115.169354},
{x:42.3649,y:-71.103212},
{x:38.923709,y:-77.051902},
{x:40.165873,y:-75.290726},
{x:33.55238,y:-112.185552},
{x:39.764431,y:-89.704348},
{x:40.358318,y:-111.764046},
{x:33.245196,y:-117.290349},
{x:42.32299,y:-83.459483},
{x:35.034467,y:-85.152592},
{x:40.071796,y:-80.866701},
{x:32.439545,y:-80.704788},
{x:33.26397,y:-111.857267},
{x:38.947978,y:-92.327411},
{x:37.557134,y:-77.486249},
{x:40.731165,y:-73.993436},
{x:42.015547,y:-71.231427},
{x:36.009121,y:-78.944674},
{x:29.478526,y:-98.364761},
{x:38.903597,y:-77.262059},
{x:39.865701,y:-84.13854},
{x:38.957758,y:-76.86999},
{x:41.945862,y:-87.709098},
{x:38.829909,y:-77.307703},
{x:39.335861,y:-84.526835},
{x:45.058092,y:-93.143527},
{x:26.838797,y:-80.108903},
{x:41.911327,y:-87.635126},
{x:40.956648,y:-73.736832},
{x:34.121708,y:-117.68875},
{x:42.878228,y:-77.258982},
{x:26.526247,y:-80.185791},
{x:38.770149,y:-121.264757},
{x:29.57199,y:-98.23858},
{x:39.947648,y:-82.0032},
{x:30.840725,y:-83.322314},
{x:33.518444,y:-101.903597},
{x:33.272316,y:-84.290189},
{x:39.750284,y:-104.999073},
{x:37.501405,y:-77.528411},
{x:30.590791,y:-96.330037},
{x:32.247148,y:-80.832487},
{x:41.578844,y:-83.66412},
{x:36.144771,y:-115.297077},
{x:42.011785,y:-87.720832},
{x:40.815686,y:-96.702361},
{x:38.987491,y:-76.547059},
{x:26.048639,y:-80.159491},
{x:37.7567,y:-121.4626},
{x:41.628894,y:-93.697223},
{x:33.871129,y:-98.534152},
{x:33.848164,y:-84.374189},
{x:42.3703,y:-71.1796},
{x:39.253254,y:-84.426266},
{x:28.659711,y:-81.340747},
{x:30.066232,y:-95.437682},
{x:42.594245,y:-82.901123},
{x:40.625272,y:-111.852235},
{x:42.907543,y:-78.774256},
{x:34.035452,y:-84.573604},
{x:40.692934,y:-73.970141},
{x:39.163956,y:-86.57451},
{x:41.786282,y:-88.376228},
{x:33.742747,y:-116.216864},
{x:32.606818,y:-85.487317},
{x:27.961126,y:-82.727515},
{x:41.469024,y:-87.030069},
{x:32.726414,y:-97.42219},
{x:45.010412,y:-93.023837},
{x:41.19729,y:-96.13978},
{x:34.001219,y:-118.08428},
{x:29.650433,y:-95.152626},
{x:41.953346,y:-88.725361},
{x:37.231039,y:-80.41524},
{x:43.096925,y:-78.973837},
{x:42.314119,y:-88.435076},
{x:36.12648,y:-97.053361},
{x:39.27182,y:-84.376281},
{x:32.480404,y:-84.943902},
{x:42.911962,y:-85.578299},
{x:39.7818,y:-86.1696},
{x:28.534012,y:-82.50673},
{x:39.877994,y:-75.11351},
{x:29.652263,y:-82.341518},
{x:39.90622,y:-74.93662},
{x:33.831467,y:-118.349755},
{x:47.039741,y:-122.835382},
{x:29.734063,y:-95.775252},
{x:28.25944,y:-81.59553},
{x:41.931419,y:-88.771632},
{x:30.088633,y:-95.521604},
{x:40.444635,y:-79.948109},
{x:33.699936,y:-117.084833},
{x:33.640453,y:-112.112343},
{x:38.988771,y:-84.641847},
{x:44.792949,y:-91.51212},
{x:29.926037,y:-90.083069},
{x:40.077067,y:-76.325595},
{x:41.450293,y:-96.452051},
{x:37.784232,y:-122.403689},
{x:41.61553,y:-93.86384},
{x:45.499779,y:-122.411024},
{x:40.422276,y:-79.662292},
{x:38.24303,y:-85.50888},
{x:32.82413,y:-97.199892},
{x:29.547696,y:-95.131309},
{x:39.537418,y:-105.013889},
{x:25.657071,y:-80.327515},
{x:26.663799,y:-80.202121},
{x:39.090573,y:-108.452673},
{x:34.940925,y:-85.217636},
{x:40.724617,y:-73.550529},
{x:43.578924,y:-116.174525},
{x:32.861138,y:-96.857219},
{x:42.99972,y:-78.819438},
{x:38.93078,y:-84.54572},
{x:30.101669,y:-81.715524},
{x:31.120257,y:-97.861477},
{x:32.398646,y:-110.955784},
{x:47.721844,y:-122.294185},
{x:39.563291,y:-84.265251},
{x:30.158366,y:-97.791029},
{x:35.697311,y:-78.584972},
{x:39.966259,y:-105.163966},
{x:40.103998,y:-83.157364},
{x:37.685202,y:-77.588592},
{x:40.597143,y:-75.526976},
{x:33.078407,y:-96.820292},
{x:38.717848,y:-75.111212},
{x:30.586461,y:-97.857104},
{x:41.306246,y:-81.83457},
{x:35.260192,y:-81.13312},
{x:42.040714,y:-87.796447},
{x:29.462383,y:-98.465518},
{x:39.966892,y:-75.301307},
{x:37.33185,y:-121.908596},
{x:27.037397,y:-82.216243},
{x:34.00544,y:-118.152899},
{x:47.820549,y:-122.288954},
{x:41.505648,y:-87.733312},
{x:47.909104,y:-122.225675},
{x:42.406043,y:-71.082907},
{x:38.594658,y:-121.385841},
{x:33.509588,y:-112.071631},
{x:29.167095,y:-82.16836},
{x:32.865764,y:-96.943632},
{x:42.519634,y:-71.092382},
{x:33.988136,y:-118.393006},
{x:44.073415,y:-123.043112},
{x:38.92138,y:-76.846378},
{x:39.884269,y:-105.024708},
{x:41.931922,y:-87.885508},
{x:35.830762,y:-83.573012},
{x:38.7918,y:-121.356055},
{x:41.8557,y:-71.35473},
{x:33.629583,y:-117.90936},
{x:41.691864,y:-87.740233},
{x:25.867037,y:-80.291961},
{x:40.742012,y:-74.001121},
{x:36.163071,y:-115.158226},
{x:40.774769,y:-73.526601},
{x:39.94068,y:-82.79108},
{x:38.916504,y:-77.221193},
{x:33.941085,y:-118.046197},
{x:32.251327,y:-95.307743},
{x:34.097858,y:-118.326368},
{x:34.301939,y:-88.708356},
{x:42.322283,y:-88.166609},
{x:39.940763,y:-77.628605},
{x:28.30348,y:-81.45728},
{x:47.103696,y:-119.250021},
{x:34.77734,y:-82.309436},
{x:44.971462,y:-93.496635},
{x:34.172335,y:-118.4483},
{x:39.219475,y:-84.518652},
{x:40.651156,y:-74.007204},
{x:37.345485,y:-79.188818},
{x:40.769756,y:-73.988492},
{x:40.823586,y:-73.837404},
{x:34.236698,y:-84.456328},
{x:28.346074,y:-81.486706},
{x:40.763667,y:-73.977938},
{x:33.451182,y:-111.802989},
{x:41.42293,y:-87.3348},
{x:35.392482,y:-118.967716},
{x:37.78183,y:-122.445633},
{x:42.773265,y:-73.894019},
{x:41.735695,y:-87.625984},
{x:39.532391,y:-119.86559},
{x:40.574,y:-74.5612},
{x:41.309589,y:-105.559519},
{x:28.597189,y:-81.216485},
{x:32.741413,y:-96.447354},
{x:40.752378,y:-73.994305},
{x:40.736472,y:-73.997166},
{x:33.210743,y:-87.553572},
{x:48.784943,y:-122.489619},
{x:27.894106,y:-82.780083},
{x:42.575817,y:-70.891249},
{x:38.961637,y:-84.678584},
{x:40.271707,y:-78.857576},
{x:34.000611,y:-117.731591},
{x:43.634989,y:-70.337837},
{x:39.323323,y:-77.35226},
{x:34.134814,y:-117.369287},
{x:42.356834,y:-72.553741},
{x:40.30998,y:-112.01403},
{x:32.975074,y:-96.720999},
{x:41.568079,y:-87.636924},
{x:32.8574,y:-96.7503},
{x:35.123209,y:-106.701283},
{x:42.740544,y:-84.633073},
{x:44.978246,y:-93.271806},
{x:33.597815,y:-117.240529},
{x:39.451866,y:-84.183044},
{x:37.794388,y:-122.397535},
{x:32.592369,y:-96.93788},
{x:33.002224,y:-96.767018},
{x:38.883258,y:-76.995243},
{x:33.638916,y:-117.859999},
{x:39.79086,y:-75.545712},
{x:33.769909,y:-118.193141},
{x:38.954884,y:-77.366303},
{x:42.175981,y:-72.640542},
{x:38.779124,y:-77.189774},
{x:39.081983,y:-84.148605},
{x:34.128164,y:-117.891544},
{x:38.933178,y:-77.176182},
{x:40.632005,y:-73.947915},
{x:32.251655,y:-110.890117},
{x:44.244322,y:-88.348683},
{x:31.623169,y:-94.652864},
{x:38.798713,y:-97.611874},
{x:40.042351,y:-86.173304},
{x:37.584576,y:-77.496572},
{x:32.787815,y:-96.809544},
{x:29.709759,y:-95.401168},
{x:40.803476,y:-74.362675},
{x:38.883567,y:-94.834688},
{x:34.016993,y:-118.282559},
{x:26.149969,y:-80.324577},
{x:30.003074,y:-90.162486},
{x:38.92668,y:-104.71533},
{x:35.052562,y:-80.768411},
{x:47.620364,y:-122.338261},
{x:40.323482,y:-75.130139},
{x:40.874315,y:-74.650184},
{x:41.636388,y:-83.478429},
{x:40.726784,y:-74.035263},
{x:40.7527,y:-73.9833},
{x:36.340525,y:-82.375067},
{x:32.781932,y:-79.990821},
{x:45.402986,y:-122.794573},
{x:39.995766,y:-85.937149},
{x:38.04916,y:-78.503662},
{x:37.727635,y:-122.476593},
{x:41.470701,y:-90.479684},
{x:46.22126,y:-119.223138},
{x:29.470186,y:-95.093029},
{x:36.073603,y:-79.492327},
{x:39.067771,y:-77.485246},
{x:41.893262,y:-87.620664},
{x:42.755726,y:-73.775974},
{x:40.739298,y:-96.679507},
{x:29.180812,y:-81.080596},
{x:46.713668,y:-117.177715},
{x:33.071013,y:-96.750779},
{x:36.973888,y:-120.082878},
{x:40.718766,y:-74.002147},
{x:44.69448,y:-73.486789},
{x:41.157884,y:-95.934753},
{x:38.423214,y:-121.415578},
{x:31.231881,y:-81.499838},
{x:41.423149,y:-81.759351},
{x:32.671593,y:-114.624343},
{x:29.903812,y:-95.551505},
{x:41.463484,y:-81.536585},
{x:29.566236,y:-98.48475},
{x:34.258844,y:-118.467198},
{x:36.026345,y:-83.927835},
{x:33.66893,y:-117.764748},
{x:32.41251,y:-81.768892},
{x:36.240089,y:-115.116196},
{x:38.74307,y:-121.22751},
{x:45.534062,y:-122.658272},
{x:35.866116,y:-78.620049},
{x:33.955624,y:-117.389371},
{x:40.734521,y:-73.870798},
{x:35.296012,y:-119.111053},
{x:33.069047,y:-96.879297},
{x:34.825873,y:-82.299647},
{x:44.883954,y:-93.248529},
{x:33.791034,y:-84.407041},
{x:41.025144,y:-80.735312},
{x:39.762985,y:-86.338379},
{x:33.70088,y:-84.58437},
{x:45.530416,y:-122.683916},
{x:40.752158,y:-73.974026},
{x:39.606742,y:-75.951305},
{x:39.616581,y:-105.090435},
{x:41.828265,y:-71.400524},
{x:40.368159,y:-79.983092},
{x:40.787561,y:-74.04581},
{x:39.683617,y:-75.747669},
{x:32.882164,y:-97.390778},
{x:41.673483,y:-71.165355},
{x:38.903098,y:-77.033677},
{x:25.951962,y:-97.487429},
{x:41.562515,y:-93.799293},
{x:41.900848,y:-88.340203},
{x:40.312466,y:-76.802003},
{x:36.110122,y:-115.17108},
{x:33.618822,y:-117.703464},
{x:34.15663,y:-118.437435},
{x:41.072904,y:-81.513441},
{x:27.938434,y:-82.257814},
{x:39.952049,y:-82.998464},
{x:32.794781,y:-116.934935},
{x:41.373966,y:-83.624628},
{x:40.733128,y:-73.86303},
{x:41.135409,y:-81.637787},
{x:39.097935,y:-76.809009},
{x:39.699737,y:-86.081785},
{x:31.130868,y:-97.798133},
{x:42.604072,y:-87.879425},
{x:40.029622,y:-83.116149},
{x:39.02576,y:-77.126984},
{x:34.16985,y:-118.605267},
{x:44.988006,y:-93.256909},
{x:38.622794,y:-76.913416},
{x:32.912385,y:-96.959372},
{x:29.768398,y:-95.624098},
{x:40.969988,y:-73.955088},
{x:39.015365,y:-94.688388},
{x:40.050671,y:-75.518312},
{x:41.371521,y:-81.868767},
{x:39.32712,y:-76.615625},
{x:28.579395,y:-81.723073},
{x:40.749361,y:-73.579178},
{x:45.198771,y:-93.354817},
{x:34.576296,y:-118.046214},
{x:34.242672,y:-77.828374},
{x:33.509777,y:-86.802721},
{x:37.262214,y:-121.931864},
{x:47.167697,y:-122.187984},
{x:31.7959,y:-106.231},
{x:34.889874,y:-82.37533},
{x:45.306648,y:-122.947426},
{x:33.073533,y:-112.042739},
{x:34.141626,y:-118.132084},
{x:40.813113,y:-96.64009},
{x:39.958933,y:-104.992827},
{x:29.82029,y:-94.89904},
{x:39.988935,y:-83.041219},
{x:37.057266,y:-120.832894},
{x:41.233724,y:-96.120335},
{x:39.831467,y:-84.849463},
{x:39.6495,y:-79.90153},
{x:43.660587,y:-70.266695},
{x:45.487028,y:-122.773661},
{x:34.147481,y:-118.246395},
{x:36.808266,y:-119.693632},
{x:33.467879,y:-111.683508},
{x:34.937605,y:-81.984639},
{x:41.312515,y:-81.523093},
{x:40.866515,y:-73.110711},
{x:36.622639,y:-121.843659},
{x:44.569048,y:-123.279146},
{x:35.940629,y:-78.854043},
{x:32.938909,y:-97.104071},
{x:39.247708,y:-94.465917},
{x:29.827032,y:-97.985293},
{x:34.256732,y:-85.164946},
{x:33.1303,y:-97.0392},
{x:41.070674,y:-74.139408},
{x:44.694106,y:-93.285727},
{x:33.788468,y:-84.619337},
{x:32.866533,y:-117.231662},
{x:26.80864,y:-80.090616},
{x:40.405159,y:-80.549438},
{x:29.928637,y:-95.203308},
{x:39.023373,y:-94.66486},
{x:25.808885,y:-80.315344},
{x:39.035843,y:-77.055322},
{x:41.525502,y:-87.470687},
{x:30.315239,y:-95.473444},
{x:40.926886,y:-73.853534},
{x:38.240621,y:-85.577978},
{x:33.880957,y:-84.461281},
{x:30.200486,y:-97.864015},
{x:34.18954,y:-79.830353},
{x:35.787647,y:-78.66684},
{x:39.562747,y:-104.875857},
{x:40.303071,y:-79.507396},
{x:42.322726,y:-71.061248},
{x:29.493576,y:-98.706432},
{x:42.167033,y:-87.8441},
{x:43.033741,y:-76.017953},
{x:33.773379,y:-117.866934},
{x:36.775679,y:-76.088666},
{x:40.152854,y:-105.111126},
{x:33.788829,y:-117.963191},
{x:40.30577,y:-111.7384},
{x:41.238459,y:-73.035398},
{x:36.836732,y:-119.862392},
{x:42.009964,y:-87.827101},
{x:37.254223,y:-77.390361},
{x:36.013306,y:-95.887085},
{x:33.438963,y:-86.788295},
{x:37.367819,y:-122.036189},
{x:42.309172,y:-122.851621},
{x:34.257778,y:-118.502046},
{x:41.313537,y:-81.818606},
{x:40.000786,y:-86.118932},
{x:29.546221,y:-95.750252},
{x:40.892436,y:-81.405208},
{x:47.610279,y:-122.337519},
{x:28.054882,y:-82.3993},
{x:44.17797,y:-94.03398},
{x:40.794969,y:-73.965744},
{x:42.466536,y:-73.196212},
{x:36.766352,y:-76.250168},
{x:35.924314,y:-84.03697},
{x:39.957221,y:-75.191371},
{x:37.41622,y:-121.954959},
{x:37.602471,y:-122.069124},
{x:39.98639,y:-105.097962},
{x:41.957549,y:-87.807513},
{x:38.321226,y:-122.306089},
{x:33.445756,y:-86.825986},
{x:26.191573,y:-80.252616},
{x:41.448202,y:-81.495887},
{x:40.562515,y:-111.890489},
{x:30.32216,y:-81.49311},
{x:33.889544,y:-118.159775},
{x:38.563706,y:-90.408141},
{x:44.890162,y:-93.081144},
{x:40.487999,y:-79.885476},
{x:29.619404,y:-82.381281},
{x:40.849592,y:-73.935878},
{x:33.305479,y:-111.860126},
{x:36.2729,y:-115.2064},
{x:41.939757,y:-87.655144},
{x:40.800107,y:-72.869079},
{x:38.885062,y:-94.667307},
{x:42.682875,y:-73.838574},
{x:41.112488,y:-83.201608},
{x:42.161338,y:-88.049837},
{x:41.465942,y:-75.637274},
{x:35.04438,y:-78.930733},
{x:32.92136,y:-96.838671},
{x:45.51071,y:-93.00077},
{x:33.191765,y:-97.100294},
{x:41.673053,y:-83.716357},
{x:33.651229,y:-86.825584},
{x:47.661911,y:-122.298729},
{x:30.448801,y:-84.311136},
{x:40.739853,y:-73.985148},
{x:36.032705,y:-79.958377},
{x:40.58329,y:-73.986764},
{x:41.82352,y:-72.88427},
{x:33.985652,y:-118.393062},
{x:42.0115,y:-87.762874},
{x:25.928778,y:-80.183109},
{x:39.567257,y:-104.880515},
{x:38.805109,y:-77.045909},
{x:34.143657,y:-118.397758},
{x:42.820735,y:-70.906568},
{x:33.759406,y:-118.007543},
{x:40.726042,y:-73.996472},
{x:33.847626,y:-117.543809},
{x:35.42271,y:-80.676294},
{x:33.8654,y:-118.393478},
{x:33.01193,y:-96.609264},
{x:40.622987,y:-80.054301},
{x:26.558735,y:-81.871156},
{x:33.658074,y:-117.970629},
{x:33.025883,y:-97.284912},
{x:42.282955,y:-83.203583},
{x:39.016651,y:-77.208966},
{x:37.75776,y:-77.470318},
{x:29.779732,y:-94.977228},
{x:39.856161,y:-105.082426},
{x:26.608354,y:-81.810544},
{x:41.990546,y:-72.584436},
{x:39.666446,y:-104.939882},
{x:33.457921,y:-94.087695},
{x:41.356843,y:-81.389561},
{x:38.544697,y:-76.59211},
{x:30.293697,y:-81.604056},
{x:33.88801,y:-117.279581},
{x:40.834292,y:-81.388086},
{x:42.351673,y:-71.207962},
{x:41.928521,y:-87.787213},
{x:30.034105,y:-95.553421},
{x:33.85416,y:-118.141947},
{x:40.00873,y:-75.176496},
{x:34.860578,y:-82.269226},
{x:41.728053,y:-73.931267},
{x:39.077507,y:-76.544604},
{x:29.111362,y:-81.02642},
{x:39.398285,y:-76.585683},
{x:41.0484,y:-74.0663},
{x:36.143889,y:-115.207897},
{x:29.885571,y:-97.940987},
{x:39.247634,y:-94.595075},
{x:40.240686,y:-75.241896},
{x:41.001973,y:-73.857727},
{x:33.056714,y:-96.693465},
{x:36.063167,y:-115.043606},
{x:33.638254,y:-112.3591},
{x:32.664707,y:-97.401311},
{x:35.009415,y:-80.56415},
{x:42.424021,y:-83.433792},
{x:33.868043,y:-117.748238},
{x:33.65119,y:-84.00914},
{x:40.357379,y:-75.983429},
{x:41.676628,y:-81.310013},
{x:33.93696,y:-117.26182},
{x:37.084878,y:-94.47663},
{x:39.083535,y:-108.588004},
{x:39.727558,y:-121.805007},
{x:33.329917,y:-111.790846},
{x:33.638244,y:-111.977417},
{x:40.65096,y:-74.349401},
{x:38.701711,y:-77.220685},
{x:33.592266,y:-117.660309},
{x:39.220729,y:-78.133019},
{x:40.350742,y:-74.069902},
{x:33.622326,y:-111.873977},
{x:40.658474,y:-73.889958},
{x:38.887459,y:-77.094345},
{x:31.551796,y:-97.124571},
{x:35.225498,y:-80.846283},
{x:40.659627,y:-111.887723},
{x:40.498091,y:-74.644712},
{x:40.054887,y:-74.844064},
{x:38.948013,y:-119.958085},
{x:32.532558,y:-96.82062},
{x:47.458545,y:-122.256103},
{x:40.734835,y:-74.170299},
{x:47.449379,y:-122.260185},
{x:41.57839,y:-87.04423},
{x:29.77466,y:-95.397932},
{x:27.463178,y:-82.578336},
{x:33.922263,y:-118.131536},
{x:37.961262,y:-121.748868},
{x:33.205753,y:-117.288354},
{x:33.712597,y:-117.817131},
{x:40.076899,y:-83.131379},
{x:42.171588,y:-88.335889},
{x:32.405363,y:-99.765402},
{x:41.710279,y:-86.180722},
{x:35.371145,y:-80.722987},
{x:32.681151,y:-97.463049},
{x:40.744106,y:-73.97594},
{x:40.739605,y:-74.030094},
{x:41.692542,y:-72.656257},
{x:41.54178,y:-87.851328},
{x:34.275514,y:-118.572534},
{x:36.716984,y:-121.653925},
{x:40.809887,y:-73.500386},
{x:43.624188,y:-116.353907},
{x:38.408353,y:-121.449199},
{x:32.079529,y:-81.284775},
{x:41.363335,y:-89.128676},
{x:40.725766,y:-111.86202},
{x:33.440222,y:-112.426792},
{x:40.694341,y:-73.991816},
{x:35.899326,y:-84.160592},
{x:42.350233,y:-71.503162},
{x:32.82193,y:-117.179085},
{x:36.195524,y:-115.248116},
{x:38.833131,y:-104.823351},
{x:39.580354,y:-105.135026},
{x:39.497477,y:-121.574288},
{x:36.598166,y:-87.286101},
{x:44.879146,y:-93.320556},
{x:44.973462,y:-93.22691},
{x:27.532086,y:-82.501768},
{x:40.798472,y:-73.940834},
{x:39.800922,y:-86.269989},
{x:41.291723,y:-96.142879},
{x:34.074594,y:-118.072174},
{x:38.672957,y:-90.360404},
{x:33.181828,y:-117.331323},
{x:35.639836,y:-78.835931},
{x:39.957012,y:-104.775632},
{x:40.908779,y:-74.032069},
{x:45.558689,y:-94.156541},
{x:41.815099,y:-71.497821},
{x:40.806988,y:-73.990198},
{x:41.975234,y:-87.744321},
{x:39.034226,y:-84.533712},
{x:33.64219,y:-84.429247},
{x:32.824215,y:-97.060249},
{x:45.55133,y:-94.206193},
{x:35.483778,y:-120.665005},
{x:28.986665,y:-81.299716},
{x:40.287106,y:-84.18447},
{x:37.334998,y:-121.892604},
{x:29.995027,y:-95.423951},
{x:42.986088,y:-87.947824},
{x:36.132694,y:-86.894666},
{x:33.90712,y:-117.458253},
{x:42.319429,y:-88.977673},
{x:30.134644,y:-95.228539},
{x:30.524857,y:-97.819717},
{x:45.409149,y:-122.627394},
{x:39.079756,y:-84.706757},
{x:42.302291,y:-71.359489},
{x:43.58492,y:-84.768104},
{x:39.912443,y:-105.051281},
{x:38.65217,y:-121.067986},
{x:29.913272,y:-95.48338},
{x:40.059775,y:-82.968979},
{x:39.124667,y:-77.236987},
{x:33.523453,y:-101.956436},
{x:41.850672,y:-87.802814},
{x:42.429953,y:-76.50853},
{x:35.170955,y:-80.807433},
{x:41.901242,y:-87.939531},
{x:32.424219,y:-96.838914},
{x:37.950082,y:-121.957979},
{x:38.924963,y:-77.239781},
{x:33.422581,y:-86.698268},
{x:35.966301,y:-78.543495},
{x:36.112765,y:-94.145347},
{x:40.702015,y:-74.01215},
{x:40.124425,y:-84.620744},
{x:39.89532,y:-83.811094},
{x:39.32501,y:-84.430265},
{x:37.678727,y:-97.245051},
{x:35.99617,y:-95.81478},
{x:42.395925,y:-71.122413},
{x:41.859022,y:-88.057634},
{x:33.714577,y:-112.112513},
{x:38.7608,y:-121.2874},
{x:41.779975,y:-87.645145},
{x:30.212445,y:-90.950268},
{x:32.46167,y:-90.13499},
{x:32.566789,y:-97.318388},
{x:40.055933,y:-82.866233},
{x:33.908363,y:-84.205757},
{x:40.773204,y:-82.589849},
{x:32.748625,y:-117.157755},
{x:41.720392,y:-87.703901},
{x:46.855015,y:-96.861643},
{x:37.721952,y:-87.123223},
{x:39.047266,y:-84.24678},
{x:39.135092,y:-84.502779},
{x:28.095807,y:-81.974674},
{x:35.868126,y:-78.583484},
{x:38.005289,y:-84.449374},
{x:38.609044,y:-90.366985},
{x:37.56455,y:-97.243501},
{x:37.40047,y:-77.70467},
{x:39.628947,y:-75.66606},
{x:39.948786,y:-75.160683},
{x:42.530744,y:-83.198054},
{x:45.051991,y:-93.063298},
{x:33.229381,y:-97.168369},
{x:34.786515,y:-77.402599},
{x:32.664639,y:-97.133659},
{x:29.514289,y:-98.634497},
{x:44.781348,y:-93.508241},
{x:41.754832,y:-87.740607},
{x:39.899911,y:-75.30509},
{x:40.603254,y:-73.996469},
{x:44.52131,y:-122.90521},
{x:36.119496,y:-115.169679},
{x:33.377942,y:-111.807652},
{x:32.284407,y:-90.039506},
{x:29.503349,y:-95.042747},
{x:39.597056,y:-84.23142},
{x:39.734389,y:-121.835142},
{x:42.295394,y:-85.655284},
{x:44.146167,y:-93.994052},
{x:41.308069,y:-81.438997},
{x:39.987369,y:-75.011253},
{x:42.342315,y:-87.895796},
{x:29.423806,y:-98.484332},
{x:26.349524,y:-80.157448},
{x:29.869556,y:-81.323824},
{x:33.611255,y:-112.184625},
{x:40.019662,y:-82.866796},
{x:34.027409,y:-117.11866},
{x:30.389926,y:-97.733342},
{x:39.477472,y:-119.79302},
{x:34.963151,y:-89.990437},
{x:47.745933,y:-117.4044},
{x:33.727961,y:-84.737702},
{x:37.605229,y:-77.354784},
{x:39.014177,y:-94.722639},
{x:40.841512,y:-73.939878},
{x:27.268017,y:-80.432634},
{x:33.82559,y:-84.36444},
{x:34.022651,y:-118.439204},
{x:34.154151,y:-86.84121},
{x:35.354548,y:-119.057057},
{x:40.757584,y:-73.974248},
{x:43.095363,y:-75.305808},
{x:28.332154,y:-81.5328},
{x:32.889744,y:-117.132437},
{x:29.870438,y:-95.327431},
{x:39.288766,y:-77.202452},
{x:45.749,y:-108.61692},
{x:42.055052,y:-87.835604},
{x:40.14361,y:-75.168796},
{x:40.769142,y:-96.622677},
{x:34.02197,y:-117.959587},
{x:47.64389,y:-117.54964},
{x:42.178557,y:-70.908632},
{x:39.613454,y:-104.988389},
{x:40.639636,y:-75.434827},
{x:38.05393,y:-121.3747},
{x:39.135705,y:-77.695844},
{x:35.936111,y:-79.02404},
{x:39.18509,y:-96.576044},
{x:28.393644,y:-81.485626},
{x:44.973058,y:-93.247025},
{x:28.656311,y:-81.203357},
{x:37.394826,y:-121.946348},
{x:34.417927,y:-118.561428},
{x:34.053669,y:-118.251486},
{x:39.122486,y:-76.516403},
{x:41.882222,y:-87.627114},
{x:26.283501,y:-80.202614},
{x:29.91974,y:-95.413393},
{x:40.74852,y:-73.9852},
{x:47.70628,y:-122.326405},
{x:32.572617,y:-97.088982},
{x:34.147928,y:-118.255293},
{x:33.80134,y:-84.392548},
{x:41.817789,y:-87.870029},
{x:34.083651,y:-118.344347},
{x:34.191179,y:-118.937736},
{x:46.145998,y:-122.928763},
{x:39.674373,y:-104.810645},
{x:41.718507,y:-111.835866},
{x:33.56741,y:-101.944974},
{x:38.017243,y:-84.418305},
{x:34.072489,y:-81.157815},
{x:44.588538,y:-123.251087},
{x:41.12007,y:-80.76694},
{x:36.291122,y:-115.287369},
{x:41.396235,y:-81.641873},
{x:40.849546,y:-73.84263},
{x:40.700725,y:-73.806434},
{x:33.6083,y:-85.7873},
{x:39.148525,y:-77.210253},
{x:29.73499,y:-95.62429},
{x:39.173627,y:-94.535573},
{x:35.185347,y:-80.760802},
{x:35.92581,y:-79.986307},
{x:36.192942,y:-115.305604},
{x:39.694589,y:-104.866242},
{x:40.223789,y:-75.139374},
{x:45.680196,y:-111.063014},
{x:33.015733,y:-96.709271},
{x:41.461396,y:-81.852967},
{x:38.100763,y:-121.26707},
{x:33.790174,y:-118.138672},
{x:32.036278,y:-102.123368},
{x:34.085367,y:-84.531122},
{x:38.938568,y:-77.330351},
{x:40.24438,y:-74.299337},
{x:37.230442,y:-79.975861},
{x:36.111374,y:-86.766713},
{x:41.202238,y:-73.117218},
{x:42.570581,y:-114.43857},
{x:32.676315,y:-97.196754},
{x:39.95209,y:-75.167774},
{x:41.659748,y:-91.534894},
{x:30.556406,y:-97.687919},
{x:33.923274,y:-116.945548},
{x:40.622081,y:-74.026611},
{x:40.236781,y:-83.350109},
{x:28.761273,y:-81.284415},
{x:40.642992,y:-74.411518},
{x:44.04473,y:-123.073896},
{x:39.725503,y:-104.987019},
{x:46.032633,y:-118.380667},
{x:46.178402,y:-119.188688},
{x:33.944445,y:-117.994841},
{x:41.920626,y:-88.265868},
{x:28.673578,y:-81.469972},
{x:40.980646,y:-76.015136},
{x:32.922099,y:-96.99438},
{x:39.154164,y:-119.76657},
{x:42.389296,y:-71.143417},
{x:42.963977,y:-78.740571},
{x:37.637803,y:-77.511687},
{x:35.207437,y:-80.860255},
{x:33.798202,y:-111.965651},
{x:38.644985,y:-121.123267},
{x:35.139242,y:-80.935595},
{x:39.928411,y:-105.143128},
{x:32.583743,y:-117.115371},
{x:33.536961,y:-112.261373},
{x:45.495198,y:-122.808846},
{x:39.003581,y:-94.629855},
{x:29.596676,y:-98.419196},
{x:39.763151,y:-105.140088},
{x:41.41013,y:-73.412883},
{x:41.939191,y:-88.114053},
{x:38.877086,y:-104.718765},
{x:39.581716,y:-82.952244},
{x:40.408322,y:-105.007667},
{x:28.239152,y:-80.728458},
{x:39.161875,y:-78.168029},
{x:44.486756,y:-88.067571},
{x:41.539326,y:-81.453421},
{x:41.501692,y:-81.53834},
{x:33.978436,y:-118.370774},
{x:30.220468,y:-81.5678},
{x:39.628827,y:-84.189247},
{x:38.791386,y:-77.606985},
{x:39.767405,y:-86.157774},
{x:33.379601,y:-111.755802},
{x:34.03211,y:-118.482879},
{x:41.487498,y:-81.767906},
{x:41.639493,y:-70.991665},
{x:33.639914,y:-112.067442},
{x:40.75999,y:-111.869894},
{x:40.76602,y:-73.982912},
{x:38.854149,y:-94.683167},
{x:34.076439,y:-118.323939},
{x:45.420441,y:-122.739151},
{x:34.09084,y:-84.27492},
{x:37.30071,y:-79.956885},
{x:36.071384,y:-115.280078},
{x:32.337598,y:-90.067307},
{x:32.359183,y:-86.162277},
{x:38.493234,y:-76.773689},
{x:32.937558,y:-117.230092},
{x:43.59511,y:-116.513142},
{x:41.716647,y:-88.037908},
{x:42.481599,y:-83.241589},
{x:38.780937,y:-77.234999},
{x:36.0558,y:-79.904135},
{x:28.043503,y:-82.590416},
{x:36.039671,y:-115.190601},
{x:33.454201,y:-88.814088},
{x:39.542614,y:-104.940953},
{x:29.964272,y:-95.543527},
{x:40.311031,y:-74.66144},
{x:29.677716,y:-95.642902},
{x:34.041789,y:-118.258498},
{x:42.595803,y:-83.168644},
{x:36.755791,y:-76.007784},
{x:39.913171,y:-75.009904},
{x:37.994695,y:-121.31342},
{x:27.310724,y:-80.40644},
{x:40.722602,y:-73.997529},
{x:26.403693,y:-81.811402},
{x:35.614872,y:-97.550088},
{x:40.020483,y:-83.060454},
{x:29.698158,y:-95.814494},
{x:33.783862,y:-84.383951},
{x:33.102906,y:-96.679133},
{x:47.316376,y:-122.31382},
{x:35.130585,y:-106.586862},
{x:40.75958,y:-73.99167},
{x:39.41919,y:-104.87774},
{x:30.324183,y:-81.670311},
{x:41.813732,y:-88.280378},
{x:40.165003,y:-83.020761},
{x:32.343582,y:-111.013367},
{x:35.211777,y:-97.44335},
{x:30.387336,y:-95.679742},
{x:33.915372,y:-117.887265},
{x:38.968586,y:-76.957622},
{x:27.938726,y:-82.323634},
{x:28.381376,y:-81.404894},
{x:36.334667,y:-94.18512},
{x:34.053542,y:-118.464888},
{x:41.371515,y:-81.826474},
{x:41.416369,y:-82.671562},
{x:40.569162,y:-79.802922},
{x:40.680434,y:-73.952976},
{x:38.485197,y:-77.967244},
{x:39.087037,y:-84.477918},
{x:40.760139,y:-74.416757},
{x:38.963547,y:-94.665794},
{x:36.507551,y:-87.272863},
{x:41.409464,y:-81.616331},
{x:41.240241,y:-81.445389},
{x:28.661332,y:-81.396677},
{x:35.144495,y:-89.796228},
{x:38.87209,y:-77.228353},
{x:38.996514,y:-77.025961},
{x:35.035278,y:-80.974106},
{x:45.160472,y:-93.233823},
{x:36.775948,y:-76.232699},
{x:39.292668,y:-84.392782},
{x:34.06215,y:-118.291372},
{x:26.61651,y:-80.18447},
{x:28.514189,y:-81.311058},
{x:38.903056,y:-77.018838},
{x:41.817207,y:-73.092076},
{x:41.731263,y:-87.79849},
{x:34.03591,y:-84.684746},
{x:39.0852,y:-76.862369},
{x:39.0862,y:-95.66431},
{x:29.721907,y:-95.418865},
{x:41.163344,y:-81.477624},
{x:33.834921,y:-118.352794},
{x:32.853457,y:-97.190259},
{x:38.774467,y:-77.173375},
{x:31.072001,y:-97.665832},
{x:41.156854,y:-80.665454},
{x:35.82391,y:-90.679382},
{x:30.015155,y:-97.866743},
{x:40.634489,y:-73.639692},
{x:37.32621,y:-121.94394},
{x:39.021865,y:-76.976696},
{x:43.061432,y:-89.504906},
{x:40.795677,y:-81.424735},
{x:42.308255,y:-71.215011},
{x:31.779081,y:-106.504235},
{x:25.765576,y:-80.193144},
{x:36.226341,y:-119.328949},
{x:37.376725,y:-77.511039},
{x:36.860657,y:-76.21033},
{x:37.371156,y:-121.844861},
{x:39.009372,y:-94.846074},
{x:38.632058,y:-77.274651},
{x:40.459291,y:-79.925873},
{x:45.4269,y:-122.4882},
{x:28.593625,y:-81.365317},
{x:40.725668,y:-73.49987},
{x:41.097023,y:-81.543809},
{x:42.642004,y:-83.131413},
{x:41.923685,y:-71.356128},
{x:33.011492,y:-97.070128},
{x:39.847453,y:-74.186416},
{x:32.900818,y:-83.688555},
{x:42.770176,y:-71.222225},
{x:39.61172,y:-105.09678},
{x:40.957278,y:-74.07577},
{x:30.481974,y:-90.45791},
{x:40.074503,y:-75.03305},
{x:40.44061,y:-80.00241},
{x:30.509436,y:-97.712165},
{x:32.399979,y:-90.141434},
{x:41.803022,y:-71.339008},
{x:40.795738,y:-81.527338},
{x:39.98444,y:-83.022995},
{x:33.825811,y:-117.836411},
{x:41.261535,y:-95.979917},
{x:38.002913,y:-87.493061},
{x:43.835445,y:-70.438889},
{x:41.597196,y:-88.017189},
{x:37.89921,y:-122.302302},
{x:32.183919,y:-110.945042},
{x:40.98244,y:-111.91174},
{x:34.86838,y:-111.762053},
{x:29.812034,y:-95.42234},
{x:25.729034,y:-80.42804},
{x:39.510312,y:-84.742634},
{x:37.701474,y:-122.482415},
{x:44.749682,y:-93.379868},
{x:27.387703,y:-82.458142},
{x:37.671339,y:-120.994148},
{x:42.789439,y:-78.815},
{x:30.390646,y:-86.424125},
{x:34.046424,y:-118.524288},
{x:33.603346,y:-83.858775},
{x:40.432263,y:-111.888108},
{x:41.154165,y:-81.349592},
{x:42.712315,y:-71.443691},
{x:38.851197,y:-94.817512},
{x:29.810015,y:-95.162522},
{x:42.182149,y:-87.929058},
{x:39.919867,y:-83.126363},
{x:44.941878,y:-122.982656},
{x:34.036082,y:-118.684706},
{x:29.660821,y:-95.714133},
{x:37.994675,y:-122.105783},
{x:35.306519,y:-119.092532},
{x:37.231879,y:-80.431261},
{x:35.088065,y:-80.850922},
{x:41.516583,y:-71.299258},
{x:39.416472,y:-77.437766},
{x:39.30518,y:-81.551264},
{x:40.860223,y:-73.890728},
{x:30.481461,y:-81.637563},
{x:33.281865,y:-111.788915},
{x:39.938824,y:-75.167058},
{x:40.861216,y:-73.970513},
{x:37.111891,y:-113.554506},
{x:42.15274,y:-71.40478},
{x:39.25131,y:-84.297108},
{x:39.195461,y:-94.579993},
{x:39.534118,y:-119.717792},
{x:33.433358,y:-111.901823},
{x:38.673386,y:-77.335048},
{x:38.863442,y:-77.060614},
{x:34.182059,y:-118.884967},
{x:43.213518,y:-77.449563},
{x:35.74798,y:-119.248504},
{x:40.190025,y:-77.192505},
{x:28.078431,y:-80.647537},
{x:39.92742,y:-75.031925},
{x:39.076738,y:-77.080042},
{x:39.595465,y:-104.84874},
{x:33.406387,y:-111.979652},
{x:35.985846,y:-115.145473},
{x:39.989194,y:-83.152182},
{x:39.329056,y:-74.594978},
{x:39.952327,y:-83.129431},
{x:40.855239,y:-81.432933},
{x:37.317286,y:-122.032759},
{x:33.680656,y:-118.004241},
{x:29.691108,y:-95.41572},
{x:40.850213,y:-74.294027},
{x:45.509521,y:-122.68443},
{x:47.163102,y:-122.52042},
{x:41.870808,y:-71.532227},
{x:41.424517,y:-82.078073},
{x:33.846674,y:-84.361939},
{x:41.097879,y:-73.957833},
{x:33.726841,y:-117.989692},
{x:35.148979,y:-89.902893},
{x:33.377228,y:-112.029866},
{x:33.801825,y:-118.328423},
{x:35.437973,y:-80.605539},
{x:34.363708,y:-89.559204},
{x:29.732002,y:-95.466316},
{x:45.532982,y:-122.935108},
{x:39.734516,y:-75.007887},
{x:41.676601,y:-83.623213},
{x:43.035385,y:-88.104546},
{x:39.762019,y:-105.034818},
{x:44.731145,y:-93.219372},
{x:40.123367,y:-75.115874},
{x:35.876531,y:-78.850474},
{x:38.064207,y:-78.934775},
{x:33.503359,y:-111.925649},
{x:26.150574,y:-80.11891},
{x:39.641152,y:-86.08267},
{x:33.842102,y:-78.661156},
{x:40.299012,y:-75.845668},
{x:40.412018,y:-104.691498},
{x:40.143644,y:-75.121712},
{x:32.831193,y:-96.705691},
{x:32.936908,y:-80.0389},
{x:38.960867,y:-92.37042},
{x:41.989583,y:-87.705598},
{x:37.693987,y:-97.299467},
{x:37.421855,y:-122.096558},
{x:32.844365,y:-96.99201},
{x:44.731298,y:-93.13359},
{x:42.204483,y:-71.153575},
{x:30.254629,y:-81.532771},
{x:48.021763,y:-122.108812},
{x:40.009645,y:-75.293602},
{x:39.361621,y:-82.976921},
{x:33.776098,y:-118.375914},
{x:29.779686,y:-95.562167},
{x:39.994508,y:-76.468355},
{x:30.681527,y:-88.224163},
{x:39.657422,y:-80.002337},
{x:43.075823,y:-89.453834},
{x:30.287145,y:-81.460377},
{x:37.687718,y:-121.052528},
{x:32.882703,y:-97.259994},
{x:34.234003,y:-119.178705},
{x:39.741625,y:-84.182484},
{x:32.768551,y:-117.14841},
{x:29.357387,y:-98.540459},
{x:26.235515,y:-80.203871},
{x:40.744269,y:-73.987877},
{x:32.769008,y:-97.096823},
{x:45.596415,y:-94.19206},
{x:38.97774,y:-76.734879},
{x:34.217281,y:-119.065521},
{x:39.957379,y:-82.932422},
{x:40.608353,y:-73.959572},
{x:33.713977,y:-117.953572},
{x:34.943764,y:-82.255956},
{x:40.725698,y:-73.909447},
{x:37.603027,y:-77.56671},
{x:39.927975,y:-82.829851},
{x:35.746414,y:-78.82956},
{x:44.924735,y:-92.955012},
{x:42.384284,y:-83.333142},
{x:45.677636,y:-122.666302},
{x:42.985652,y:-78.69753},
{x:33.307787,y:-111.742797},
{x:32.961725,y:-97.04183},
{x:43.5147,y:-96.774948},
{x:35.765223,y:-78.783861},
{x:40.759379,y:-73.918315},
{x:33.901473,y:-84.477394},
{x:40.251282,y:-111.658081},
{x:32.810296,y:-97.432461},
{x:35.945246,y:-86.825125},
{x:29.787698,y:-95.718353},
{x:35.012021,y:-80.851522},
{x:45.124552,y:-93.357518},
{x:36.292598,y:-95.846688},
{x:32.682677,y:-117.178774},
{x:32.716716,y:-96.325676},
{x:40.938956,y:-98.382827},
{x:41.722341,y:-83.517128},
{x:47.218098,y:-122.467119},
{x:42.51541,y:-71.03368},
{x:40.156557,y:-83.090472},
{x:40.764668,y:-73.306534},
{x:25.699534,y:-80.295008},
{x:40.602023,y:-79.94546},
{x:39.62376,y:-104.896024},
{x:44.02518,y:-116.940824},
{x:42.52954,y:-83.43854},
{x:38.793927,y:-76.059386},
{x:42.733636,y:-84.4773},
{x:40.762666,y:-73.966089},
{x:39.49382,y:-76.391267},
{x:39.038051,y:-76.68016},
{x:34.031921,y:-117.690287},
{x:30.222221,y:-95.565796},
{x:45.516343,y:-122.655548},
{x:41.386286,y:-81.974648},
{x:43.034657,y:-70.783223},
{x:40.98148,y:-76.884397},
{x:44.949786,y:-123.051033},
{x:35.915322,y:-84.089824},
{x:34.223617,y:-118.501788},
{x:41.691534,y:-88.123838},
{x:41.003575,y:-73.683276},
{x:45.653767,y:-122.586601},
{x:29.736102,y:-95.586072},
{x:39.445938,y:-77.987837},
{x:29.263973,y:-81.105463},
{x:25.950186,y:-80.145689},
{x:33.708264,y:-116.283442},
{x:42.358894,y:-83.443966},
{x:42.563563,y:-83.168234},
{x:42.257647,y:-71.167728},
{x:34.063337,y:-118.398891},
{x:38.801722,y:-104.819078},
{x:40.038378,y:-82.429925},
{x:46.258518,y:-119.309033},
{x:28.835554,y:-82.341252},
{x:39.08382,y:-77.56261},
{x:36.129387,y:-86.853176},
{x:40.75728,y:-73.970612},
{x:32.433649,y:-97.784812},
{x:43.662327,y:-116.410149},
{x:38.920899,y:-76.993967},
{x:47.54394,y:-122.049473},
{x:36.194749,y:-86.618089},
{x:35.44367,y:-80.878983},
{x:36.106855,y:-115.136432},
{x:42.040268,y:-88.033668},
{x:40.921849,y:-111.885083},
{x:34.203616,y:-118.197853},
{x:37.504362,y:-121.975593},
{x:40.439153,y:-79.766929},
{x:37.153027,y:-93.295163},
{x:28.552487,y:-81.526959},
{x:37.144151,y:-80.582739},
{x:34.95831,y:-81.04278},
{x:43.13052,y:-89.305693},
{x:41.833803,y:-88.102427},
{x:34.11209,y:-117.762289},
{x:33.955267,y:-84.131162},
{x:32.953658,y:-96.817913},
{x:47.56013,y:-122.386426},
{x:37.645852,y:-77.579687},
{x:36.06175,y:-95.78203},
{x:30.675336,y:-88.143691},
{x:38.772324,y:-93.735849},
{x:40.779104,y:-74.695598},
{x:40.646772,y:-73.958449},
{x:42.362548,y:-71.085697},
{x:40.609819,y:-73.921613},
{x:39.989205,y:-82.023678},
{x:34.062702,y:-117.435064},
{x:32.981653,y:-117.250566},
{x:34.109071,y:-84.552788},
{x:37.980819,y:-87.492085},
{x:41.90583,y:-87.985485},
{x:39.994693,y:-81.575198},
{x:41.314031,y:-81.725841},
{x:30.46269,y:-97.59851},
{x:31.81715,y:-106.427572},
{x:41.712296,y:-71.489657},
{x:38.839694,y:-76.948578},
{x:39.518709,y:-104.770353},
{x:38.350319,y:-77.054796},
{x:37.706379,y:-121.929067},
{x:42.41318,y:-71.233721},
{x:38.750698,y:-90.427814},
{x:42.956402,y:-71.478195},
{x:41.117502,y:-85.12843},
{x:40.900679,y:-80.829772},
{x:38.142147,y:-79.122379},
{x:28.520935,y:-81.586791},
{x:39.190949,y:-84.656508},
{x:38.066775,y:-84.525327},
{x:33.775386,y:-78.984428},
{x:30.312467,y:-97.738615},
{x:40.87462,y:-81.36367},
{x:39.038948,y:-94.586877},
{x:26.147349,y:-80.256339},
{x:33.55954,y:-117.671356},
{x:40.828072,y:-73.201332},
{x:45.435528,y:-122.578969},
{x:39.36455,y:-77.160105},
{x:32.83619,y:-97.132829},
{x:28.023987,y:-82.504363},
{x:45.151874,y:-122.885217},
{x:39.384596,y:-76.688925},
{x:40.831172,y:-74.089105},
{x:34.561446,y:-86.975655},
{x:40.726763,y:-73.423033},
{x:28.46045,y:-81.27149},
{x:32.955566,y:-96.616909},
{x:40.415183,y:-74.15862},
{x:36.837044,y:-119.682797},
{x:28.55358,y:-81.346916},
{x:34.189967,y:-118.602638},
{x:40.554506,y:-74.431474},
{x:39.086238,y:-84.51897},
{x:34.116352,y:-117.835618},
{x:40.510087,y:-88.954744},
{x:38.876205,y:-77.442463},
{x:42.956927,y:-76.542761},
{x:38.857243,y:-77.394431},
{x:42.626416,y:-82.98776},
{x:28.19322,y:-82.395242},
{x:40.755822,y:-73.880207},
{x:40.473291,y:-86.107957},
{x:32.785578,y:-79.935977},
{x:40.0735,y:-75.1569},
{x:39.049784,y:-77.115596},
{x:29.550582,y:-98.583183},
{x:29.87697,y:-95.645856},
{x:37.698464,y:-121.84492},
{x:38.818259,y:-77.141866},
{x:44.940113,y:-93.136826},
{x:30.437375,y:-90.084405},
{x:39.745398,y:-104.991628},
{x:34.145115,y:-118.259277},
{x:42.138714,y:-88.025533},
{x:39.021412,y:-84.589667},
{x:29.21884,y:-81.097244},
{x:34.68461,y:-82.837358},
{x:33.900014,y:-83.961003},
{x:42.232517,y:-87.942181},
{x:43.610879,y:-72.973113},
{x:39.987042,y:-75.405412},
{x:35.911412,y:-79.059583},
{x:33.317395,y:-111.968776},
{x:42.719538,y:-87.848882},
{x:36.06675,y:-94.166113},
{x:40.103038,y:-74.949458},
{x:33.465588,y:-112.082387},
{x:39.969312,y:-75.587546},
{x:39.710034,y:-84.206025},
{x:40.675762,y:-80.098332},
{x:34.138336,y:-118.214607},
{x:43.202797,y:-77.651413},
{x:38.318562,y:-81.718723},
{x:34.137164,y:-80.955709},
{x:35.090446,y:-92.417587},
{x:29.498668,y:-98.480652},
{x:40.65564,y:-73.549797},
{x:40.58188,y:-74.165865},
{x:42.326861,y:-83.302504},
{x:42.124598,y:-75.973572},
{x:35.082322,y:-80.877287},
{x:33.82365,y:-117.516599},
{x:41.725602,y:-72.759226},
{x:39.440045,y:-84.330696},
{x:42.357535,y:-71.058415},
{x:36.6221,y:-79.3996},
{x:41.612578,y:-83.701442},
{x:38.944279,y:-94.607973},
{x:34.239527,y:-111.323839},
{x:35.189819,y:-111.661772},
{x:46.16214,y:-123.89793},
{x:37.444352,y:-122.170843},
{x:40.36776,y:-86.85812},
{x:35.152858,y:-80.832689},
{x:40.732991,y:-73.980992},
{x:33.042839,y:-84.979688},
{x:33.568334,y:-117.204661},
{x:37.695358,y:-122.076805},
{x:33.923804,y:-84.341154},
{x:44.930205,y:-93.350442},
{x:37.796344,y:-121.193043},
{x:33.218062,y:-96.638339},
{x:34.967133,y:-80.995356},
{x:38.984886,y:-76.981586},
{x:41.563999,y:-87.508464},
{x:42.479603,y:-71.021167},
{x:40.82373,y:-73.891269},
{x:38.561828,y:-121.491902},
{x:29.476264,y:-81.196113},
{x:26.365991,y:-80.202361},
{x:30.372448,y:-87.683802},
{x:40.763097,y:-73.985312},
{x:40.448909,y:-80.174599},
{x:38.902762,y:-104.818155},
{x:37.292933,y:-121.991171},
{x:39.151643,y:-123.199436},
{x:47.057931,y:-122.765109},
{x:37.290927,y:-121.932023},
{x:29.746321,y:-95.376237},
{x:36.0028,y:-79.905418},
{x:35.164904,y:-79.42146},
{x:37.738196,y:-97.245344},
{x:40.8562,y:-73.867474},
{x:36.075732,y:-95.922722},
{x:34.264401,y:-119.212597},
{x:39.914527,y:-75.432734},
{x:33.995691,y:-84.350461},
{x:27.71092,y:-97.368595},
{x:45.383011,y:-122.748199},
{x:41.88046,y:-87.640922},
{x:32.314987,y:-106.738051},
{x:26.006839,y:-80.356265},
{x:41.897417,y:-87.625165},
{x:41.361922,y:-81.685409},
{x:40.3139,y:-74.0662},
{x:45.200739,y:-93.553948},
{x:40.08733,y:-82.965509},
{x:33.130275,y:-117.066471},
{x:40.687128,y:-74.31577},
{x:39.166302,y:-86.529029},
{x:39.769788,y:-76.67238},
{x:27.780159,y:-82.638729},
{x:39.946398,y:-74.959013},
{x:36.33245,y:-119.39177},
{x:33.667885,y:-117.882953},
{x:41.098545,y:-80.766099},
{x:25.58961,y:-80.35868},
{x:36.808204,y:-119.753605},
{x:33.705008,y:-78.920749},
{x:40.916331,y:-74.076981},
{x:41.87299,y:-87.67748},
{x:40.304414,y:-79.579652},
{x:34.051774,y:-84.286286},
{x:40.84967,y:-81.947159},
{x:26.254143,y:-81.800193},
{x:38.861003,y:-77.092176},
{x:39.972546,y:-86.154509},
{x:45.575423,y:-122.564101},
{x:27.893163,y:-82.272361},
{x:36.799879,y:-76.17865},
{x:33.916322,y:-84.379567},
{x:38.732744,y:-77.54755},
{x:39.744497,y:-84.118881},
{x:41.135604,y:-85.16216},
{x:42.721455,y:-84.426928},
{x:34.047573,y:-118.256658},
{x:38.869747,y:-76.847826},
{x:38.957305,y:-94.726616},
{x:41.479177,y:-73.210364},
{x:34.069359,y:-117.972809},
{x:33.5956,y:-86.647453},
{x:43.103951,y:-73.74134},
{x:44.86401,y:-93.288867},
{x:30.142174,y:-81.767095},
{x:34.07317,y:-117.882161},
{x:35.359955,y:-94.363702},
{x:40.811233,y:-73.294142},
{x:40.888695,y:-74.251974},
{x:39.802986,y:-75.037399},
{x:39.374362,y:-77.412111},
{x:30.262889,y:-81.62294},
{x:41.600453,y:-87.602416},
{x:47.754871,y:-122.155743},
{x:39.7579,y:-104.901543},
{x:43.606699,y:-116.282264},
{x:26.196019,y:-98.260688},
{x:40.770759,y:-84.164452},
{x:42.607501,y:-83.300305},
{x:27.841562,y:-82.79449},
{x:41.673404,y:-86.89336},
{x:34.133283,y:-117.926712},
{x:28.44943,y:-81.401886},
{x:44.166301,y:-93.951396},
{x:29.519679,y:-98.501378},
{x:41.306412,y:-72.926701},
{x:40.701764,y:-73.378473},
{x:40.710703,y:-74.016362},
{x:42.282154,y:-71.807807},
{x:41.26842,y:-82.609269},
{x:38.638697,y:-121.504965},
{x:39.18794,y:-84.60392},
{x:36.21175,y:-115.98987},
{x:31.758768,y:-106.271846},
{x:34.546605,y:-112.46938},
{x:33.218,y:-96.6801},
{x:33.751009,y:-118.307294},
{x:28.34644,y:-81.611278},
{x:33.846189,y:-83.908273},
{x:39.303063,y:-84.522788},
{x:37.390677,y:-122.094413},
{x:41.367182,y:-85.076641},
{x:37.671137,y:-122.464453},
{x:40.816907,y:-73.915935},
{x:38.78639,y:-89.950357},
{x:38.431892,y:-78.85461},
{x:38.954851,y:-76.828421},
{x:35.046465,y:-89.698565},
{x:39.286624,y:-76.619508},
{x:29.571994,y:-98.326307},
{x:30.247644,y:-97.775468},
{x:34.283559,y:-118.441346},
{x:32.791536,y:-96.597088},
{x:43.215338,y:-70.880136},
{x:38.942349,y:-104.611922},
{x:39.867936,y:-82.936637},
{x:36.115019,y:-115.155591},
{x:45.365505,y:-122.835672},
{x:40.564208,y:-75.564003},
{x:40.268145,y:-83.071025},
{x:42.165752,y:-71.045497},
{x:38.669502,y:-121.155663},
{x:32.250253,y:-110.851532},
{x:38.303747,y:-85.83976},
{x:30.504443,y:-84.249691},
{x:36.915479,y:-121.768337},
{x:39.21473,y:-76.861714},
{x:35.290516,y:-80.765999},
{x:36.029744,y:-86.791491},
{x:41.27582,y:-73.124023},
{x:42.00757,y:-87.885469},
{x:28.485062,y:-81.431721},
{x:31.42863,y:-100.51241},
{x:33.02706,y:-96.833078},
{x:43.188798,y:-77.503958},
{x:42.017626,y:-93.610942},
{x:41.692037,y:-86.235757},
{x:41.14733,y:-96.21173},
{x:44.448613,y:-73.208136},
{x:39.182602,y:-77.263864},
{x:39.803973,y:-104.93083},
{x:37.96967,y:-122.516735},
{x:33.980139,y:-84.21714},
{x:35.677816,y:-105.952881},
{x:44.976898,y:-93.267942},
{x:29.960115,y:-90.184442},
{x:39.18742,y:-84.260014},
{x:33.677253,y:-117.666574},
{x:45.195718,y:-93.236486},
{x:33.218717,y:-96.783434},
{x:33.00797,y:-96.545144},
{x:40.450476,y:-79.987088},
{x:42.580564,y:-71.288135},
{x:33.917383,y:-83.454219},
{x:40.137485,y:-88.258603},
{x:32.999127,y:-96.796695},
{x:40.776119,y:-73.911298},
{x:42.240706,y:-83.74529},
{x:37.485816,y:-122.228957},
{x:40.199081,y:-75.525085},
{x:41.528046,y:-73.894316},
{x:40.785067,y:-124.182365},
{x:41.651227,y:-88.204231},
{x:36.060394,y:-95.854485},
{x:37.248665,y:-121.802916},
{x:39.287129,y:-76.607407},
{x:28.003614,y:-81.717516},
{x:34.284132,y:-118.775359},
{x:44.021404,y:-71.115179},
{x:41.462859,y:-81.480645},
{x:26.187778,y:-80.11561},
{x:43.068244,y:-88.048692},
{x:33.994726,y:-81.226326},
{x:37.975948,y:-87.632676},
{x:41.848419,y:-87.932257},
{x:47.658806,y:-122.313398},
{x:34.071113,y:-117.932782},
{x:33.175984,y:-96.845531},
{x:33.811967,y:-118.181334},
{x:39.204106,y:-84.368105},
{x:33.379305,y:-112.169013},
{x:40.807293,y:-73.238103},
{x:41.977139,y:-87.66042},
{x:32.660442,y:-117.105087},
{x:40.831945,y:-73.088934},
{x:37.893216,y:-122.118088},
{x:41.512894,y:-87.950729},
{x:41.60542,y:-71.45446},
{x:38.578833,y:-92.192716},
{x:39.153273,y:-76.72457},
{x:39.009255,y:-94.76982},
{x:42.271432,y:-83.487776},
{x:33.747169,y:-116.997502},
{x:28.036206,y:-80.667366},
{x:41.17832,y:-111.999459},
{x:42.300117,y:-71.393726},
{x:40.102112,y:-83.090516},
{x:40.74662,y:-73.883492},
{x:39.055511,y:-76.954912},
{x:35.760404,y:-78.740893},
{x:45.52922,y:-122.42716},
{x:45.035646,y:-92.837415},
{x:27.177976,y:-80.236088},
{x:40.540868,y:-74.365858},
{x:42.081136,y:-87.935653},
{x:39.093965,y:-84.616116},
{x:33.636283,y:-112.234039},
{x:38.64268,y:-121.52122},
{x:41.796156,y:-87.723641},
{x:39.424126,y:-84.594984},
{x:43.612686,y:-116.203084},
{x:35.31327,y:-80.743226},
{x:26.587637,y:-80.147145},
{x:26.204836,y:-80.147477},
{x:38.4656,y:-121.4912},
{x:36.804518,y:-114.110037},
{x:39.400403,y:-76.602846},
{x:41.453492,y:-74.381107},
{x:30.710827,y:-95.566226},
{x:40.171207,y:-75.071756},
{x:30.19383,y:-81.738462},
{x:32.858738,y:-97.290558},
{x:35.985403,y:-80.025325},
{x:38.660209,y:-121.34706},
{x:40.68048,y:-80.31031},
{x:41.248269,y:-76.960102},
{x:40.822211,y:-73.953161},
{x:33.379446,y:-111.600875},
{x:43.872775,y:-91.190819},
{x:30.0507,y:-95.1804},
{x:29.99639,y:-95.576801},
{x:32.96601,y:-97.41997},
{x:38.912594,y:-94.643196},
{x:38.857663,y:-77.357521},
{x:33.086072,y:-96.617706},
{x:43.194033,y:-86.262967},
{x:41.042959,y:-73.797262},
{x:32.231861,y:-110.957487},
{x:25.626448,y:-80.394877},
{x:33.873496,y:-118.308833},
{x:39.202023,y:-84.377149},
{x:39.833996,y:-89.605478},
{x:33.947371,y:-83.408834},
{x:39.711425,y:-104.983177},
{x:39.974223,y:-82.898717},
{x:35.999003,y:-115.198303},
{x:35.080594,y:-106.616873},
{x:42.147459,y:-70.845195},
{x:33.128087,y:-117.270439},
{x:33.418301,y:-86.675797},
{x:40.517208,y:-80.222332},
{x:41.002371,y:-74.042737},
{x:41.65683,y:-88.437035},
{x:35.320069,y:-97.498876},
{x:40.016357,y:-105.257952},
{x:40.580848,y:-83.083062},
{x:36.138863,y:-85.507637},
{x:33.846577,y:-118.263448},
{x:44.984482,y:-93.381111},
{x:26.513494,y:-80.056953},
{x:34.427711,y:-118.537346},
{x:33.10619,y:-96.825546},
{x:37.762315,y:-121.960954},
{x:32.908125,y:-96.572245},
{x:36.067443,y:-79.80518},
{x:37.043568,y:-76.39315},
{x:33.618314,y:-117.872478},
{x:41.61661,y:-87.852817},
{x:35.593541,y:-87.052038},
{x:41.903758,y:-87.628874},
{x:40.568004,y:-74.111222},
{x:29.55642,y:-95.386371},
{x:37.140878,y:-113.493466},
{x:38.661606,y:-121.226037},
{x:33.979147,y:-118.438326},
{x:38.021278,y:-121.297019},
{x:40.7531,y:-73.988874},
{x:38.238987,y:-85.721278},
{x:34.470684,y:-117.245748},
{x:36.819754,y:-76.068312},
{x:43.085529,y:-88.067712},
{x:26.648232,y:-80.146114},
{x:40.044809,y:-76.36346},
{x:41.376284,y:-73.963022},
{x:33.881,y:-84.380146},
{x:42.155058,y:-87.954098},
{x:39.618218,y:-83.603905},
{x:38.253164,y:-85.756956},
{x:30.4115,y:-97.8502},
{x:42.336475,y:-71.149571},
{x:40.706449,y:-74.40458},
{x:34.073633,y:-118.360931},
{x:40.516132,y:-74.373944},
{x:39.340408,y:-76.591768},
{x:43.019459,y:-77.438829},
{x:33.745132,y:-117.814616},
{x:29.739563,y:-95.462705},
{x:34.213665,y:-77.901899},
{x:34.018337,y:-80.965603},
{x:41.711822,y:-88.205151},
{x:38.881167,y:-77.30187},
{x:41.028263,y:-81.460997},
{x:30.263372,y:-97.731508},
{x:30.43616,y:-84.258393},
{x:39.904935,y:-79.753075},
{x:41.025054,y:-81.637934},
{x:27.923045,y:-82.326399},
{x:42.996077,y:-70.9245},
{x:37.112656,y:-76.495607},
{x:36.121244,y:-115.171349},
{x:33.991125,y:-117.924697},
{x:38.929686,y:-77.032503},
{x:40.317947,y:-76.424328},
{x:40.760348,y:-73.827981},
{x:45.228898,y:-123.170845},
{x:39.00896,y:-94.27518},
{x:32.945274,y:-96.698638},
{x:36.144099,y:-86.813277},
{x:42.344755,y:-71.10089},
{x:40.613064,y:-79.174538},
{x:41.908186,y:-87.840955},
{x:30.732689,y:-86.567567},
{x:47.10464,y:-122.29409},
{x:41.251016,y:-81.363459},
{x:37.7904,y:-122.399472},
{x:39.638347,y:-86.129134},
{x:44.77466,y:-91.43798},
{x:29.42115,y:-98.77483},
{x:41.37782,y:-83.115892},
{x:27.89347,y:-82.505875},
{x:34.061468,y:-84.246385},
{x:26.610907,y:-81.940712},
{x:37.79963,y:-81.1771},
{x:36.915283,y:-76.271393},
{x:33.562708,y:-84.324649},
{x:34.067433,y:-84.169294},
{x:34.96501,y:-89.84286},
{x:40.861594,y:-73.893211},
{x:38.928658,y:-94.409007},
{x:45.278227,y:-92.998222},
{x:39.44538,y:-77.397286},
{x:41.768496,y:-88.206342},
{x:33.355007,y:-86.848995},
{x:41.846534,y:-87.978608},
{x:32.732805,y:-97.343147},
{x:32.699723,y:-114.601064},
{x:33.872793,y:-118.064185},
{x:40.91881,y:-73.040855},
{x:42.976711,y:-83.768421},
{x:30.625168,y:-81.542989},
{x:46.834232,y:-96.820473},
{x:26.100851,y:-80.126664},
{x:33.686334,y:-116.268568},
{x:39.979579,y:-75.153661},
{x:33.481156,y:-117.096984},
{x:38.921327,y:-94.359722},
{x:44.062767,y:-92.500103},
{x:33.176088,y:-96.513944},
{x:38.959348,y:-77.356704},
{x:40.454233,y:-79.947419},
{x:39.281246,y:-76.579937},
{x:40.75895,y:-73.968762},
{x:39.891878,y:-84.19549},
{x:38.921084,y:-77.021911},
{x:32.869843,y:-97.341721},
{x:33.649611,y:-117.83931},
{x:32.604645,y:-96.82537},
{x:35.595424,y:-80.875673},
{x:33.952508,y:-118.185617},
{x:33.503892,y:-111.928664},
{x:27.638345,y:-80.447965},
{x:41.63805,y:-81.474738},
{x:39.277324,y:-76.825935},
{x:38.967069,y:-95.236151},
{x:41.418205,y:-81.916335},
{x:36.767788,y:-108.147758},
{x:33.789543,y:-117.85272},
{x:45.784522,y:-108.55692},
{x:29.760162,y:-95.362591},
{x:38.945028,y:-77.077721},
{x:38.544036,y:-121.741606},
{x:35.374761,y:-77.945619},
{x:38.366921,y:-121.962349},
{x:39.689679,y:-83.962089},
{x:26.706506,y:-80.158953},
{x:28.224933,y:-81.645096},
{x:38.939433,y:-76.90732},
{x:35.108872,y:-106.568504},
{x:32.816402,y:-96.753159},
{x:30.20717,y:-97.97723},
{x:30.269856,y:-97.742378},
{x:34.428019,y:-117.378393},
{x:33.976619,y:-117.552862},
{x:37.723357,y:-122.15397},
{x:42.681617,y:-83.192438},
{x:41.524944,y:-81.4372},
{x:40.062661,y:-83.041207},
{x:41.698837,y:-72.894966},
{x:37.78968,y:-122.404021},
{x:40.267355,y:-82.934174},
{x:39.853169,y:-74.982545},
{x:33.974481,y:-118.233506},
{x:33.983156,y:-80.967654},
{x:40.993003,y:-73.820923},
{x:38.537853,y:-121.492431},
{x:39.734038,y:-105.160103},
{x:35.346185,y:-82.434631},
{x:38.610032,y:-121.385745},
{x:38.932191,y:-76.996401},
{x:33.95205,y:-84.521123},
{x:33.7753,y:-84.298718},
{x:34.017789,y:-118.498916},
{x:32.969027,y:-96.993335},
{x:45.094135,y:-93.383211},
{x:34.823592,y:-82.578272},
{x:45.02058,y:-93.482526},
{x:39.495964,y:-84.3287},
{x:35.124711,y:-80.708134},
{x:37.637204,y:-122.417247},
{x:45.517295,y:-122.67568},
{x:35.130966,y:-118.466885},
{x:41.11958,y:-81.479412},
{x:40.834343,y:-73.945099},
{x:41.913331,y:-87.67732},
{x:44.117154,y:-70.225259},
{x:39.126117,y:-94.823465},
{x:40.230812,y:-76.893143},
{x:42.497849,y:-83.28418},
{x:39.90604,y:-86.074509},
{x:40.61434,y:-111.98186},
{x:34.033736,y:-117.628874},
{x:39.145323,y:-84.431855},
{x:40.619161,y:-73.990627},
{x:40.689251,y:-73.992896},
{x:40.044341,y:-75.379272},
{x:33.378235,y:-112.097858},
{x:40.7778,y:-73.031907},
{x:46.892196,y:-114.03998},
{x:34.15169,y:-118.343344},
{x:36.824352,y:-76.412171},
{x:41.663971,y:-71.495857},
{x:39.714638,y:-86.356385},
{x:38.29152,y:-77.56476},
{x:27.838767,y:-82.684634},
{x:39.558086,y:-104.776917},
{x:40.753988,y:-73.981551},
{x:37.653579,y:-113.08464},
{x:39.179426,y:-76.671014},
{x:38.403186,y:-75.569717},
{x:26.365568,y:-80.078995},
{x:38.94318,y:-95.251918},
{x:40.026734,y:-76.220056},
{x:35.224069,y:-114.036963},
{x:47.041925,y:-122.933975},
{x:36.04078,y:-78.898845},
{x:39.202139,y:-76.683569},
{x:34.742178,y:-86.665714},
{x:34.474058,y:-84.925},
{x:36.319596,y:-86.600238},
{x:40.543699,y:-111.97579},
{x:28.156896,y:-81.440713},
{x:33.096009,y:-96.112571},
{x:29.594058,y:-95.622797},
{x:39.010768,y:-77.490978},
{x:39.9522,y:-82.683382},
{x:39.138116,y:-88.56602},
{x:39.448267,y:-76.81519},
{x:41.64106,y:-70.88566},
{x:44.973535,y:-93.27533},
{x:30.544465,y:-97.561399},
{x:25.80706,y:-80.194655},
{x:33.588063,y:-101.869452},
{x:35.125707,y:-85.240269},
{x:38.550914,y:-121.538349},
{x:34.75046,y:-86.759471},
{x:42.230429,y:-85.589686},
{x:36.583417,y:-121.896834},
{x:34.684509,y:-86.717969},
{x:33.56158,y:-84.41311},
{x:29.923854,y:-95.601372},
{x:30.118431,y:-95.379661},
{x:40.765974,y:-73.957107},
{x:36.977912,y:-121.96625},
{x:30.649201,y:-97.677597},
{x:35.125504,y:-78.881104},
{x:33.759944,y:-118.114883},
{x:34.234682,y:-118.555099},
{x:36.850994,y:-76.023622},
{x:40.461415,y:-74.45718},
{x:40.578284,y:-105.077412},
{x:42.279329,y:-83.740607},
{x:36.189243,y:-86.798418},
{x:38.784563,y:-90.531464},
{x:41.811828,y:-72.528248},
{x:30.483262,y:-97.681878},
{x:30.132385,y:-95.552521},
{x:36.596934,y:-121.894483},
{x:32.354864,y:-111.091721},
{x:29.61984,y:-95.568},
{x:36.868151,y:-76.29813},
{x:27.4323,y:-82.424},
{x:38.767599,y:-76.887921},
{x:42.02683,y:-91.626915},
{x:38.58117,y:-121.500204},
{x:34.181214,y:-118.311737},
{x:37.666725,y:-77.458474},
{x:29.609469,y:-98.512211},
{x:40.751304,y:-73.980609},
{x:40.663271,y:-73.719247},
{x:44.832866,y:-92.957598},
{x:42.535861,y:-82.912491},
{x:34.237531,y:-118.589083},
{x:43.087605,y:-77.62529},
{x:26.708223,y:-80.098579},
{x:37.775162,y:-121.978021},
{x:37.067362,y:-76.49196},
{x:33.980164,y:-84.424608},
{x:42.860239,y:-73.780359},
{x:39.291297,y:-84.3012},
{x:38.955757,y:-76.939945},
{x:28.014723,y:-82.734477},
{x:39.020954,y:-77.011951},
{x:47.477325,y:-122.21635},
{x:25.479033,y:-80.441929},
{x:40.391647,y:-80.059893},
{x:39.367873,y:-76.451833},
{x:40.837428,y:-76.832171},
{x:31.092538,y:-97.38551},
{x:35.5843,y:-77.370953},
{x:31.770416,y:-106.371356},
{x:34.032096,y:-118.284247},
{x:41.160736,y:-104.805715},
{x:29.73229,y:-95.439062},
{x:33.637972,y:-117.680585},
{x:40.71468,y:-75.284512},
{x:40.122256,y:-111.641641},
{x:38.743916,y:-121.171776},
{x:39.496668,y:-76.654429},
{x:37.810767,y:-122.244734},
{x:41.450292,y:-81.814071},
{x:36.781636,y:-119.790074},
{x:47.49385,y:-111.274441},
{x:40.891262,y:-74.072044},
{x:35.586721,y:-82.587429},
{x:39.151516,y:-84.628302},
{x:30.099725,y:-95.442139},
{x:41.501274,y:-81.59449},
{x:35.731623,y:-77.951545},
{x:26.318392,y:-80.148341},
{x:41.781759,y:-70.744821},
{x:40.694401,y:-73.986034},
{x:25.912891,y:-80.330937},
{x:29.91157,y:-95.685449},
{x:41.905418,y:-71.049319},
{x:35.068529,y:-78.967152},
{x:28.386963,y:-81.244816},
{x:39.732958,y:-75.639545},
{x:44.738873,y:-92.892639},
{x:33.538759,y:-111.887645},
{x:28.131275,y:-82.365765},
{x:37.350606,y:-121.922255},
{x:34.187098,y:-118.417114},
{x:40.137362,y:-74.964624},
{x:42.866718,y:-73.933248},
{x:39.391376,y:-74.559689},
{x:43.041845,y:-76.135868},
{x:30.229169,y:-97.821024},
{x:39.047485,y:-84.576219},
{x:33.390012,y:-111.683607},
{x:39.537046,y:-104.879352},
{x:35.262626,y:-120.677654},
{x:44.834868,y:-93.601277},
{x:40.677245,y:-73.972122},
{x:26.5061,y:-80.1228},
{x:33.998966,y:-81.042841},
{x:41.937636,y:-71.476992},
{x:25.684578,y:-80.415201},
{x:39.82923,y:-76.998084},
{x:39.143092,y:-84.387301},
{x:42.542196,y:-70.945071},
{x:41.4586,y:-81.69149},
{x:33.027502,y:-96.792389},
{x:28.364094,y:-81.365211},
{x:33.149947,y:-96.767806},
{x:28.063578,y:-82.503442},
{x:33.305291,y:-111.89993},
{x:33.698363,y:-117.885057},
{x:33.524085,y:-117.158949},
{x:38.962249,y:-77.088636},
{x:41.233649,y:-96.19198},
{x:33.890269,y:-117.324317},
{x:47.146748,y:-122.292509},
{x:29.853965,y:-95.51287},
{x:39.969545,y:-83.004066},
{x:26.268379,y:-98.218471},
{x:30.413068,y:-97.68771},
{x:38.643302,y:-90.564922},
{x:33.347271,y:-111.964492},
{x:37.287482,y:-76.721078},
{x:39.875339,y:-75.544217},
{x:37.520648,y:-120.880841},
{x:31.822484,y:-81.598801},
{x:40.786236,y:-73.977863},
{x:40.423632,y:-86.907163},
{x:32.810821,y:-115.569267},
{x:41.304614,y:-84.36127},
{x:26.542687,y:-80.090222},
{x:40.496055,y:-74.443963},
{x:41.721433,y:-87.679919},
{x:41.088768,y:-112.06369},
{x:30.883755,y:-83.287562},
{x:45.038909,y:-93.219786},
{x:38.035397,y:-78.500155},
{x:30.278023,y:-81.719544},
{x:39.87752,y:-74.919336},
{x:37.331238,y:-121.812137},
{x:32.857473,y:-96.769627},
{x:40.664223,y:-80.588819},
{x:34.110328,y:-117.535498},
{x:45.614025,y:-122.503591},
{x:44.944625,y:-92.90468},
{x:41.868114,y:-87.626084},
{x:41.266998,y:-96.073593},
{x:46.358212,y:-94.245605},
{x:33.927932,y:-84.344195},
{x:41.487545,y:-75.695938},
{x:42.489379,y:-83.147059},
{x:37.337322,y:-122.036457},
{x:40.488753,y:-78.386655},
{x:36.2917,y:-119.31384},
{x:31.246394,y:-85.428363}];