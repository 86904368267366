import { useGetMutation } from 'hooks/utils';
import type {
  DayTypeResponse,
  PeriodTypeResponse,
  DayType,
  PeriodType,
} from 'types';

export const useCreateDayTypesMutation = (schoolId: string) =>
  useGetMutation<string[], DayTypeResponse[]>({
    schoolId,
    method: 'post',
    expectedStatus: 200,
    getPath: () => 'v3/schedules/day_types/batch',
    getBody: (dayTypeNames) => ({ schoolId, dayTypeNames }),
    update: (school, responseData) => ({
      ...school,
      dayTypes: responseData.map((dt) => ({ ...dt, id: dt.id! })),
    }),
  });

export const useCreatePeriodTypesMutation = (schoolId: string) =>
  useGetMutation<string[], PeriodTypeResponse[]>({
    schoolId,
    method: 'post',
    expectedStatus: 200,
    getPath: () => 'v3/schedules/period_types/batch',
    getBody: (periodTypeNames) => ({ schoolId, periodTypeNames }),
    update: (school, responseData) => ({
      ...school,
      periodTypes: responseData.map((pt) => ({ ...pt, id: pt.id! })),
    }),
  });

export const useCreateDayTypeMutation = (schoolId: string) =>
  useGetMutation<string, DayTypeResponse>({
    schoolId,
    method: 'post',
    expectedStatus: 200,
    getPath: () => 'v3/schedules/day_types',
    getBody: (name) => ({ schoolId, name }),
    update: (school, responseData) => ({
      ...school,
      dayTypes: [
        ...school.dayTypes,
        { id: responseData.id!, name: responseData.name },
      ],
    }),
  });

export const useCreatePeriodTypeMutation = (schoolId: string) =>
  useGetMutation<string, PeriodTypeResponse>({
    schoolId,
    method: 'post',
    expectedStatus: 200,
    getPath: () => 'v3/schedules/period_types',
    getBody: (name) => ({ schoolId, name }),
    update: (school, responseData) => ({
      ...school,
      periodTypes: [
        ...school.periodTypes,
        { id: responseData.id!, name: responseData.name },
      ],
    }),
  });

export const useUpdateDayTypeMutation = (schoolId: string) =>
  useGetMutation<DayType, DayTypeResponse>({
    schoolId,
    method: 'patch',
    expectedStatus: 200,
    getPath: ({ id }) => `v3/schedules/day_types/${id}`,
    getBody: ({ name }) => ({ name }),
    update: (school, responseData) => ({
      ...school,
      dayTypes: school.dayTypes.map((dt) =>
        dt.id !== responseData.id ? dt : { ...dt, name: responseData.name },
      ),
    }),
  });

export const useUpdatePeriodTypeMutation = (schoolId: string) =>
  useGetMutation<PeriodType, PeriodTypeResponse>({
    schoolId,
    method: 'patch',
    expectedStatus: 200,
    getPath: ({ id }) => `v3/schedules/period_types/${id}`,
    getBody: ({ name }) => ({ name }),
    update: (school, responseData) => ({
      ...school,
      periodTypes: school.periodTypes.map((pt) =>
        pt.id !== responseData.id ? pt : { ...pt, name: responseData.name },
      ),
    }),
  });

export const useDeleteDayTypeMutation = (schoolId: string) =>
  useGetMutation<DayType, void>({
    schoolId,
    method: 'delete',
    expectedStatus: 204,
    getPath: ({ id }) => `v3/schedules/day_types/${id}`,
    getBody: () => undefined,
    update: (school, responseData, initialData) => ({
      ...school,
      dayTypes: school.dayTypes.filter((dt) => dt.id !== initialData.id),
    }),
  });

export const useDeletePeriodTypeMutation = (schoolId: string) =>
  useGetMutation<PeriodType, void>({
    schoolId,
    method: 'delete',
    expectedStatus: 204,
    getPath: ({ id }) => `v3/schedules/period_types/${id}`,
    getBody: () => undefined,
    update: (school, responseData, initialData) => ({
      ...school,
      periodTypes: school.periodTypes.filter((pt) => pt.id !== initialData.id),
    }),
  });
