import { DOMAIN_VALIDATOR as DOMAIN_LIST_VALIDATOR } from 'utils/urlValidator';
import {
  HAS_TEXT_VALIDATOR,
  VALID_NUMBER,
  LAT_LONG_VALIDATOR,
  VALID_OPTION,
  URL_VALIDATOR,
} from 'utils/hasTextValidator';
import { statuses, liteLevels } from 'constants/statuses';
import { states } from 'constants/states';
import miscService from 'services/misc.service';
import React from 'react';

export const SchoolDetailsStep = (school) => {
  const fields = [
    {
      type: 'SingleTextField',
      name: 'School Title',
      prop: 'school_title',
      currentValue: school.school_title,
      defaultValue: school.school_title,
      validators: [HAS_TEXT_VALIDATOR],
      invalidText: 'Enter a school title.',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Nickname',
      prop: 'nickname',
      currentValue: school.nickname,
      defaultValue: school.nickname,
      validators: [],
      valid: true,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Website',
      prop: 'website',
      currentValue: school.website,
      defaultValue: school.website,
      validators: [URL_VALIDATOR],
      invalidText: 'Enter a valid URL, please include http/https.',
      valid: true,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'NCES Code',
      prop: 'nces_code',
      currentValue: school.nces_code,
      defaultValue: school.nces_code,
      validators: [],
      valid: true,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'TagInputField',
      name: 'School Domain',
      prop: 'domains',
      currentValue: school.domains,
      defaultValue: school.domains,
      placeholder: 'Add domains separated by comma or enter',
      validators: [DOMAIN_LIST_VALIDATOR],
      invalidText:
        'Enter only valid email domains. Do not include http:// or https://',
      valid: true,
      isShowLabel: false,
    },
    {
      type: 'NumericField',
      name: 'Enrollment',
      prop: 'students_count',
      currentValue: school.students_count || 0,
      defaultValue: school.students_count || 0,
      min: 0,
      validators: [VALID_NUMBER],
      invalidText: 'Please set a valid number of enrolled students',
      valid: true,
      isShowLabel: true,
    },
    {
      type: 'NumericField',
      name: 'Chat density target (%)',
      prop: 'chat_minimum_depth',
      currentValue: school.chat_minimum_depth * 100,
      defaultValue: school.chat_minimum_depth * 100,
      min: 1,
      max: 100,
      validators: [VALID_NUMBER],
      invalidText: 'Please set a value between 1 and 100',
      valid: true,
      isShowLabel: true,
    },
    {
      type: 'SelectField',
      name: 'School Status',
      prop: 'status',
      currentValue: school.status,
      defaultValue: school.status,
      options: statuses,
      validators: [VALID_OPTION],
      invalidText: 'Select an option',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'LogoUploadField',
      name: 'Sports Logo',
      prop: 'logo_id',
      currentLogo: school.logo && school.logo.resource_url,
      defaultValue: school.logo_id,
      currentValue: school.logo_id,
      validators: [],
      valid: true,
      isShowLabel: true,
    },
    {
      type: 'ColorPickerField',
      name: 'Primary Color',
      prop: 'primary_color',
      currentValue: school.primary_color,
      defaultValue: school.primary_color,
      validators: [],
      invalidText: 'Select a color',
      valid: true,
      isShowLabel: false,
    },
  ];

  if (school.status !== 'live') {
    fields.push({
      type: 'SelectField',
      name: 'Lite Level',
      prop: 'lite_level',
      currentValue: school.lite_level || 'L1',
      defaultValue: school.lite_level || 'L1',
      options: liteLevels,
      invalidText: 'Select an option',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    });
  }

  return {
    name: 'School Info',
    fields: fields,
  };
};

export const SchoolLocationStep = (school) => {
  return {
    name: 'School Location',
    fields: [
      {
        type: 'SelectField',
        name: 'State',
        prop: 'state',
        currentValue: school.state || 0,
        defaultValue: school.state || 0,
        options: Object.keys(states).map((state_code) => ({
          id: state_code,
          name: states[state_code],
        })),
        invalidText: 'Enter a valid latitude value.',
        valid: false,
        isShowLabel: false,
        isPlaceholderUp: true,
      },
      {
        type: 'XHRSelectField',
        name: 'District',
        prop: 'district_code',
        currentValue: {
          value: school.district_code,
          label: school.district_name,
        },
        defaultValue: school.district_code
          ? { value: school.district_code, label: school.district_name }
          : null,
        fetcher: (inputValue, resolve) => {
          miscService()
            .getDistricts({ search: inputValue })
            .then(({ data }) => {
              data = data.map((i) => {
                return { value: i.district_code, label: i.district_name };
              });
              resolve(data);
            });
        },
        optionFormatter: ({ value, label }) => {
          return (
            <div>
              <div>{label}</div>
              <div className="district-id">
                <span>{value}</span>
              </div>
            </div>
          );
        },
        invalidText: 'Select an option',
        valid: true,
        isShowLabel: false,
        isPlaceholderUp: true,
      },
      {
        type: 'GoogleAutoCompleteField',
        name: 'School Address',
        prop: 'address',
        currentValue: school.address || '',
        defaultValue: school.address || '',
        validators: [],
        invalidText: 'Enter the school address.',
        valid: true,
        isShowLabel: false,
        isGooglePlace: true,
        isPlaceholderUp: true,
      },
      {
        type: 'SingleTextField',
        name: 'Latitude',
        prop: 'lat',
        currentValue: school.lat,
        defaultValue: school.lat,
        validators: [LAT_LONG_VALIDATOR],
        invalidText: 'Enter a valid latitude value.',
        valid: false,
        isShowLabel: false,
        isPlaceholderUp: true,
      },
      {
        type: 'SingleTextField',
        name: 'Longitude',
        prop: 'lng',
        currentValue: school.lng,
        defaultValue: school.lng,
        validators: [LAT_LONG_VALIDATOR],
        invalidText: 'Enter a valid longitude value.',
        valid: false,
        isShowLabel: false,
        isPlaceholderUp: true,
      },
      {
        type: 'SingleTextField',
        name: 'Timezone',
        prop: 'timezone',
        currentValue: school.timezone,
        defaultValue: school.timezone,
        validators: [HAS_TEXT_VALIDATOR],
        invalidText: 'Enter a valid timezone value.',
        valid: false,
        isShowLabel: false,
        isPlaceholderUp: true,
      },
    ],
  };
};
