import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './NetworkStatusModal.scss';
import AdvancedTable from 'components/shared/AdvancedTable/AdvancedTable';
import { schoolIdFormatter } from 'pages/schools/SchoolsGridTable/ColumnFormatters';
import { Link } from 'react-router-dom';

export default class NetworkStatusModal extends Component {
  modalData;

  constructor(props) {
    super(props);

    this.columns = [
      {
        dataField: 'school_name',
        text: 'School ID',
        formatter: (cell, row) => {
          return (
            <Link
              to={`/schools/${cell}`}
              href={`/schools/${cell}`}
              className="school-grid-name"
              target="_blank"
            >
              {schoolIdFormatter(cell, row)}
            </Link>
          );
        },
        headerStyle: () => {
          return { width: '10%' };
        },
      },
      {
        dataField: 'schedule_name',
        text: 'Schedule',
        formatter: (cell, row) => {
          const modified_type = row.modified
            ? 'modified'
            : row.special
            ? 'special'
            : null;
          return (
            <div className="schedule-name">
              {cell}&nbsp;
              {modified_type && (
                <span className={`schedule-tag ${modified_type}`}>
                  {modified_type}
                </span>
              )}
            </div>
          );
        },
        headerStyle: () => {
          return { width: '10%' };
        },
      },
    ];
  }

  getCounts = () => {
    return (
      <h1>
        <span className="table-schools-count">{this.props.data.length}</span>
        Schools
      </h1>
    );
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.hide}
        className="network-status-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <div>
          <AdvancedTable
            keyField="school_name"
            columns={this.columns}
            data={this.props.data}
            options={{ hasSearch: true }}
          />
        </div>
      </Modal>
    );
  }
}
