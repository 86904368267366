import { forwardRef } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

export interface SearchResultItemProps extends M.SelectItemProps {
  value: string;
  title: string;
  titleDetails: string;
  subtitle: string;
  subtitle2?: string;
  badge: string;
  badgeColor?: M.MantineColor;
}
const SearchResultItem = forwardRef<HTMLDivElement, SearchResultItemProps>(
  (
    { title, titleDetails, subtitle, subtitle2, badge, badgeColor, ...rest },
    ref,
  ) => {
    return (
      <>
        <Container ref={ref} {...rest}>
          <M.Text>
            {title} ({titleDetails})
          </M.Text>
          <M.Text color="#888" size="sm">
            {subtitle}
          </M.Text>
          {subtitle2 && (
            <M.Text color="#888" size="sm">
              {subtitle2}
            </M.Text>
          )}
          <M.Badge mt="xs" color={badgeColor}>
            {badge}
          </M.Badge>
        </Container>
        <M.Divider />
      </>
    );
  },
);
const Container = styled(M.Flex)`
  width: 100%;
  padding: 4px;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

SearchResultItem.displayName = 'SearchResultItem';

export default SearchResultItem;
