import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import FieldError from '../FieldError/FieldError';
import PlaceholderUp from '../PlaceholderUp/PlaceholderUp';
import { compose } from 'redux';

export default class SingleTextField extends Component {
  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
    this.updateBlur = this.updateBlur.bind(this);
    this.isFieldInvalid = this.isFieldInvalid.bind(this);

    this.validate(this.props.field.currentValue);
    this.props.updateParent(this.props.field.currentValue, this.props.field);
  }

  isFieldInvalid() {
    return this.props.field.blurred === true && !this.props.field.valid;
  }

  updateBlur() {
    this.props.field.blurred = true;
    this.props.updateParent(this.props.field.currentValue, this.props.field);
  }

  validate(value) {
    if (this.props.field.validators) {
      const isValid = this.props.field.validators.every((validateFn) => {
        return validateFn(value);
      });
      this.props.field.valid = isValid;
    } else {
      this.props.field.valid = true;
    }
  }

  format(value) {
    if (this.props.field.formatters) {
      return compose(...this.props.field.formatters)(value);
    }
    return value;
  }

  updateValue(changeEvt) {
    const newValue = this.format(changeEvt.target.value);
    this.props.field.currentValue = newValue;
    this.validate(newValue);
    this.props.updateParent(newValue, this.props.field);
  }

  render() {
    return (
      <Form.Group controlId={this.props.field.name}>
        {this.props.field.isShowLabel && (
          <Form.Label>{this.props.field.name}</Form.Label>
        )}
        <Form.Control
          type="text"
          autoComplete="off"
          size="md"
          placeholder={this.props.field.placeholder || this.props.field.name}
          name={this.props.field.name}
          value={this.props.field.currentValue || ''}
          onChange={this.updateValue}
          onBlur={this.updateBlur}
          isInvalid={this.isFieldInvalid()}
        />
        {this.props.field.isPlaceholderUp && this.props.field.currentValue && (
          <PlaceholderUp label={this.props.field.name} />
        )}
        <FieldError field={this.props.field} />
      </Form.Group>
    );
  }
}
