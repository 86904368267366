import { useState } from 'react';

import { ParsedSchedule } from 'features/scheduleConfiguration/utils';
import { ParsedImagePicker } from 'features/editSchedule/components/parsedImagePicker/ParsedImagePicker';

import { useSchool } from 'hooks/useSchool';
import type { CoreSchedule, Period, Schedule } from 'types';

import { StepContainer } from '../stepLayout/StepContainer';
import { StepHeader } from '../stepLayout/StepHeader';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { stepCreator, useWizard } from '../stepUtils';
import { scheduleUtils } from 'features/schedules/utils/schedule';

type Payload = Pick<Schedule, 'genId' | 'periods' | 'lunchWaves' | 'image'>;

const scheduleSelectStep = stepCreator.create<Payload>()({
  key: 'scheduleSelect',
  payloadReducer: (payload) => (state) => ({
    ...state,
    schedule: {
      ...state.schedule,
      genId: payload.genId,
      periods: payload.periods,
      lunchWaves: payload.lunchWaves,
      image: payload.image,
    },
  }),
  render: () => <ScheduleSelectStep />,
  validate: () => true,
});

const ScheduleSelectStep = () => {
  const { data: school } = useSchool((school) => ({
    dayTypes: school.dayTypes,
    periodTypes: school.periodTypes,
    lunchSettings: school.lunchSettings,
    schedules: school.schedules,
  }));

  const [saveStep, next, schedule] = useWizard((store) => [
    store.saveStep,
    store.next,
    store.state.schedule,
  ]);

  const [selectedGenId, setSeletedGenId] = useState(schedule?.genId);
  const [selectedSchedule, setSelectedSchedule] = useState<ParsedSchedule>();

  const onNext = () => {
    // nothing changed, go next
    if (selectedGenId === schedule?.genId) {
      next();
    }

    if (!selectedSchedule || !school || !schedule) return;

    const { lunchSettings, periodTypes } = school;

    const lunchWaves =
      lunchSettings.type !== 'waves'
        ? []
        : selectedSchedule.lunches
            .slice(0, lunchSettings.slots.length)
            .map((l, i) => ({
              id: lunchSettings.slots[i].id,
              name: lunchSettings.isAlphabetic
                ? String.fromCharCode(65 + i)
                : String(i + 1),
              startTime: l.startTime,
              endTime: l.endTime,
              slot: lunchSettings.slots[i],
            }));

    const mapPeriod = (period: ParsedSchedule['periods'][number]): Period => {
      if (scheduleUtils.isAcfSchedule(schedule)) {
        // this might be removed when backend won't be returning invalid periods
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!schedule.dayType) {
          throw new Error(
            `mapPeriod: Missing day type for ACF schedule ${schedule.displayName}`,
          );
        }

        const matchingPT = periodTypes.find((pt) => pt.name === period.name);

        if (!matchingPT) return { ...period, id: '' };

        return {
          ...period,
          periodType: matchingPT,
          dayType: schedule.dayType,
          id: '',
        };
      }
      // core non-acf, modified or special
      else {
        const matchingAcfSchedule = school.schedules
          .filter((s): s is CoreSchedule => s.variant === 'core' && s.isInAcf)
          .find((s) => s.periods.find((p) => p.name === period.name));

        const matchingPT = matchingAcfSchedule?.periods.find(
          (p) => p.name === period.name,
        )?.periodType;

        if (!matchingAcfSchedule || !matchingPT) return { ...period, id: '' };

        return {
          ...period,
          dayType: matchingAcfSchedule.dayType,
          periodType: matchingPT,
          id: '',
        };
      }
    };

    saveStep(
      scheduleSelectStep.payloadReducer({
        genId: selectedSchedule.genId,
        periods: selectedSchedule.periods.map(mapPeriod),
        lunchWaves,
        image: {
          source: 'ai',
          url: selectedSchedule.url,
        },
      }),
    );

    next();
  };

  return (
    <StepContainer>
      <StepHeader
        title="Select schedule"
        description="Select a schedule to use"
      />

      <ParsedImagePicker
        onSelectRow={(s) => {
          setSelectedSchedule(s);
          setSeletedGenId(s?.id);
        }}
        enableRefetching
        selectedRowId={selectedGenId}
      />

      <StepNavigation canSkip onNext={onNext} isValid={!!selectedGenId} />
    </StepContainer>
  );
};

export default scheduleSelectStep;
