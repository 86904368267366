import { createContext, useContext, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { useSchool } from 'hooks/useSchool';

import { STEP_COMPONENTS } from 'features/scheduleConfiguration/constants';
import type { Step } from 'features/scheduleConfiguration/types';

import WizardReview from './WizardReview';

interface WizardContextType {
  steps: Step[];
  update: (stepIndex: number, newStep: Step) => void;
}

const WizardContext = createContext<WizardContextType | null>(null);

const useWizard = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error('useWizard must be used within a Wizard');
  }

  return context;
};

interface Props {
  steps: Step['type'][];
}

const Wizard = ({ steps: stepTypes }: Props) => {
  const { data: school } = useSchool();

  const [index, setIndex] = useState(0);
  const [steps, setSteps] = useState<Step[]>(
    new Array(stepTypes.length)
      .fill(null)
      .map((_, i) => ({ type: stepTypes[i], isValid: false })),
  );
  const totalSteps = stepTypes.length - 1;

  const prev = () => setIndex((s) => s - 1);
  const next = () => setIndex((s) => s + 1);

  const canGoPrev = index !== 0;
  const isLastStep = index === stepTypes.length - 1;

  if (isLastStep && steps[index].isValid) {
    location.reload();
  }

  const wizard: WizardContextType = useMemo(
    () => ({
      steps,
      update: (stepIndex, newStep) =>
        setSteps((prev) =>
          prev.map((step, i) => (i !== stepIndex ? step : newStep)),
        ),
    }),
    [steps],
  );

  if (!school) return null;

  return (
    <WizardContext.Provider value={wizard}>
      <Container>
        <StepsContainer>
          <Progress value={(index / totalSteps) * 100} />
          {stepTypes
            .map((stepType) => STEP_COMPONENTS[stepType])
            .map((StepComponent, i) => (
              <div
                key={i}
                style={{ display: index === i ? 'initial' : 'none' }}
              >
                <StepComponent school={school} stepIndex={i} />
              </div>
            ))}
          <Navigation>
            {steps[index].canSkip && (
              <M.Button sx={{ marginRight: 'auto' }} onClick={next}>
                Skip
              </M.Button>
            )}
            <M.Button onClick={prev} disabled={!canGoPrev}>
              Prev
            </M.Button>
            {!isLastStep && (
              <M.Button onClick={next} disabled={!steps[index].isValid}>
                Next
              </M.Button>
            )}
          </Navigation>
        </StepsContainer>
        <WizardReview steps={steps} index={index} />
      </Container>
    </WizardContext.Provider>
  );
};

const Container = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.md};
`;

const StepsContainer = styled(M.Flex)`
  width: 100%;
  padding: 0;
  flex-direction: column;
  transition: all 0.2s;
`;

const Progress = styled(M.Progress)`
  padding: 0px;
  margin-bottom: 24px;

  transition: all 0.2s;

  .mantine-Progress-bar {
    background: ${(p) => p.theme.other.saturnGradient.primary};
    background-size: 400% 400%;
    animation: bkg 2s ease infinite;
  }

  @keyframes bkg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const Navigation = styled(M.Flex)`
  padding-top: ${(p) => p.theme.spacing.xl};

  justify-content: flex-end;
  gap: ${(p) => p.theme.spacing.sm};
`;

export { useWizard, Wizard as default };
