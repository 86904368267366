import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import './NoMatch.scss';

class NoMatch extends PureComponent {
  render() {
    return (
      <Row className="no-match">
        <Col className="no-match-container" xs={12} sm={12} md={12} lg={12}>
          <h3 className="no-match-container-title">
            Sorry, We haven&apos;t found what you were looking for :(
          </h3>
        </Col>
      </Row>
    );
  }
}

export default NoMatch;
