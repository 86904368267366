import styled from '@emotion/styled';
import * as M from '@mantine/core';

interface Props {
  isSubmit?: boolean;
  skipText?: string;
  onSkip?: () => void;
  onPrev?: () => void;
  canGoNext: boolean;
  onNext: () => void;
}

const StepNavigator = ({
  isSubmit,
  skipText,
  onSkip,
  onPrev,
  canGoNext,
  onNext,
}: Props) => {
  return (
    <Container>
      {onSkip && (
        <M.Button onClick={onSkip} sx={{ marginRight: 'auto' }}>
          {skipText || 'Skip'}
        </M.Button>
      )}
      {onPrev && <M.Button onClick={onPrev}>Prev</M.Button>}
      <M.Button disabled={!canGoNext} onClick={onNext}>
        {isSubmit ? 'Submit' : 'Next'}
      </M.Button>
    </Container>
  );
};

const Container = styled(M.Flex)`
  justify-content: flex-end;
  gap: ${(p) => p.theme.spacing.sm};
`;

export default StepNavigator;
