import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faExternalLinkAlt,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';
import districtFormatter from 'utils//districtFormatter';
import { schoolIdFormatter } from '../../schools/SchoolsGridTable/ColumnFormatters';
import { Link } from 'react-router-dom';
import { getDateFormat } from 'utils//utilities';

export function getColumns() {
  this.formatters = {
    schoolIdFormatter: schoolIdFormatter.bind(this),
    districtFormatter: districtFormatter.bind(this),
  };

  return [
    {
      dataField: 'school',
      text: 'School ID',
      formatter: (cell, row) => {
        if (!this.props.hasSchoolManagementScope) {
          return cell;
        }

        return (
          <div className="school-filter">
            <div className="school-id">
              <span>{cell}</span>
            </div>
            {row.school_website && (
              <a
                href={row.school_website}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faGlobe} />
              </a>
            )}
            <Link
              to={`/schools/${cell}`}
              href={`/schools/${cell}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Link>
          </div>
        );
      },
      sort: true,
      headerStyle: () => {
        return { width: '35%' };
      },
    },
    {
      dataField: 'title',
      text: 'Schedule name',
      sort: true,
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      formatter: (cell) => getDateFormat(cell, 'MM/DD/YYYY'),
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: 'user_count',
      text: 'Users',
      sort: true,
      headerStyle: () => {
        return { width: '15%' };
      },
    },
  ];
}
