export const timezoneAbbreviations = {
  'America/Anchorage': 'AST',
  'America/Boise': 'MST',
  'America/Chicago': 'CST',
  'America/Denver': 'MST',
  'America/Detroit': 'CST',
  'America/Indiana/Indianapolis': 'EST',
  'America/Indiana/Knox': 'CST',
  'America/Indiana/Marengo': 'EST',
  'America/Indiana/Petersburg': 'EST',
  'America/Indiana/Tell_City': 'CST',
  'America/Indiana/Vevay': 'EST',
  'America/Indiana/Vincennes': 'EST',
  'America/Indiana/Winamac': 'EST',
  'America/Juneau': 'AST',
  'America/Kentucky/Louisville': 'EST',
  'America/Kentucky/Monticello': 'EST',
  'America/Los_Angeles': 'PST',
  'America/Menominee': 'CST',
  'America/Metlakatla': 'AST',
  'America/New_York': 'EST',
  'America/Nome': 'AST',
  'America/North_Dakota/Beulah': 'CST',
  'America/North_Dakota/Center': 'CST',
  'America/North_Dakota/New_Salem': 'CST',
  'America/Phoenix': 'MST',
  'America/Sitka': 'AST',
  'Pacific/Honolulu': 'HST',
} as const;
