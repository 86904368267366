import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  type AxiosInstance,
  type Method,
} from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import dayjs from 'dayjs';

import { formatResponseToTime } from 'features/editSchedule/utils';
import {
  DATE_DISPLAY_FORMAT,
  DATE_SERVER_FORMAT,
  SCHEDULE_CODE_DATE_FORMAT,
} from 'constants/dates';
import {
  modificationEmojis,
  modificationTypes,
  staticModificationTypes,
} from 'constants/scheduleInfo';
import type {
  DayOfWeek,
  DayType,
  ScheduleRequest,
  ScheduleModification,
  ScheduleResponse,
  School,
  ScheduleCreateRequest,
  Schedule,
  ImagesResponse,
  ScheduleVariant,
  DraftSchedulesResponse,
} from 'types';

import { getRefreshToken, saveTokenUser } from '../utils/utilities';
import { BlockingError } from 'utils/errors';
import { periodUtils } from 'features/schedules/utils/period';

export const attemptRefreshTokenOnError =
  (axiosInstance: AxiosInstance) => async (error: AxiosError) => {
    if (
      !error.config?.headers ||
      !error.response ||
      error.response.status !== 401
    ) {
      return Promise.reject(error);
    }

    const { status, data } = await axios.post(
      `${process.env.REACT_APP_URL_API}/v3/auth/refresh`,
      {
        access_token: getToken(),
        refresh_token: getRefreshToken(),
      },
    );

    if (status !== 200) {
      return Promise.reject('Refresh token request failed');
    }

    saveTokenUser(data.access_token, data.refresh_token);

    error.config.headers.Authorization = `Bearer ${data.access_token}`;

    return axiosInstance(error.config);
  };

export const getToken = () =>
  `; ${decodeURIComponent(document.cookie)}`
    .split(`; wyz_token=`)[1]
    ?.split(';')[0];

export const getRequestConfig = () =>
  ({
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    baseURL: `${process.env.REACT_APP_URL_API}/`,
  } satisfies AxiosRequestConfig);

const axiosBaseClient = axios.create();

axiosBaseClient.interceptors.request.use((config) => {
  const { headers, baseURL } = getRequestConfig();

  config.headers.Authorization = headers.Authorization;
  config.baseURL = baseURL;

  return config;
});

axiosBaseClient.interceptors.response.use(
  (response) => response,
  attemptRefreshTokenOnError(axiosBaseClient),
);

export const axiosClient = applyCaseMiddleware(axiosBaseClient);

// https://stackoverflow.com/a/38552302
export const getMyId = () => {
  try {
    const base64Url = getToken().split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload).sub as string;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

interface GetMutationParams<TData, TResponse> {
  schoolId: string;
  method: Method;
  expectedStatus: number;
  getPath: (data: TData) => string;
  getBody: (data: TData) => Record<string, unknown> | undefined;
  update: (
    school: School,
    responseData: TResponse,
    initialData: TData,
  ) => School;
  onSuccess?: (responseData: TResponse, initialData: TData) => void;
  onError?: (error: Error) => void;
}

export const useGetMutation = <TData, TResponse>({
  schoolId,
  method,
  expectedStatus,
  getPath,
  getBody,
  update,
  onSuccess,
  onError,
}: GetMutationParams<TData, TResponse>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: TData) => {
      try {
        const res = await axiosClient.request<TResponse>({
          method,
          baseURL: `${process.env.REACT_APP_URL_API}/`,
          url: getPath(data),
          data: getBody(data),
          headers: getRequestConfig().headers,
        });

        if (res.status === expectedStatus) {
          return { responseData: res.data, initialData: data };
        }

        throw new Error(
          `[${method} ${getPath(data)}] ${res.status}: ${res.statusText}`,
        );
      } catch (e) {
        if (e instanceof Error) {
          throw e;
        }
        throw new Error(`Unknown error on ${method} ${getPath(data)}`);
      }
    },
    onSuccess: ({ responseData, initialData }) => {
      void queryClient.invalidateQueries(['school', schoolId]);
      queryClient.setQueryData<School>(
        ['school', schoolId],
        (school) => school && update(school, responseData, initialData),
      );
      onSuccess?.(responseData, initialData);
    },
    onError,
  });
};

export const formatDate = (date: Date | undefined) =>
  date ? dayjs(date).format(DATE_DISPLAY_FORMAT) : 'N/A';

const isDayOfWeekValid = (dayOfWeek: any): dayOfWeek is DayOfWeek =>
  staticModificationTypes.map((x) => x.value).includes(dayOfWeek);

const isModificationValid = (
  modification: any,
): modification is ScheduleModification =>
  modificationTypes.map((m) => m.value).includes(modification);

export const BROKEN_DAY_TYPE_ID = 'BROKEN_DAY_TYPE';
export const MISSING_MODIFICATION = 'MISSING_MODIFICATION';

const parseScheduleCode = (
  scheduleResponse: ScheduleResponse,
): [DayType, string | undefined] => {
  const [dayTypeName, modification] = scheduleResponse.name.split('!');

  return [
    { id: scheduleResponse.dayTypeId || BROKEN_DAY_TYPE_ID, name: dayTypeName },
    modification,
  ];
};

export const getScheduleCode = (schedule: Schedule | ScheduleRequest) =>
  schedule.variant === 'core'
    ? `${schedule.dayType.name}${
        schedule.dayOfWeek ? `!${schedule.dayOfWeek}` : ''
      }`
    : schedule.variant === 'modified'
    ? `${schedule.dayType.name}!${
        schedule.modification === 'OTHER'
          ? schedule.otherModification
          : schedule.modification
      }`
    : `#-${dayjs(schedule.date).format(SCHEDULE_CODE_DATE_FORMAT)}`;

export const getRequestPropsForScheduleVariant = (
  schedule: ScheduleRequest,
): Pick<
  ScheduleCreateRequest,
  | 'static'
  | 'special'
  | 'name'
  | 'dayTypeId'
  | 'grid'
  | 'order'
  | 'hidden'
  | 'emojiId'
  | 'onDate'
> =>
  schedule.variant === 'core'
    ? {
        static: true,
        special: false,
        grid: schedule.isInAcf,
        hidden: !schedule.isInLsc,
        name: getScheduleCode(schedule),
        dayTypeId: schedule.dayType.id,
        order: schedule.order,
      }
    : schedule.variant === 'modified'
    ? {
        static: false,
        special: false,
        grid: false,
        hidden: !schedule.isInLsc,
        name: getScheduleCode(schedule),
        dayTypeId: schedule.dayType.id,
        emojiId: modificationEmojis[schedule.modification],
      }
    : {
        static: false,
        special: true,
        grid: false,
        hidden: true,
        name: getScheduleCode(schedule),
        emojiId: 'dizzy',
        onDate: dayjs(schedule.date).format(DATE_SERVER_FORMAT),
      };

export const getScheduleVariantProps = (
  scheduleResponse: ScheduleResponse,
): ScheduleVariant => {
  // CORE
  if (scheduleResponse.static && !scheduleResponse.special) {
    const [dayType, dayOfWeek] = parseScheduleCode(scheduleResponse);

    if (!dayOfWeek) {
      return {
        variant: 'core',
        dayType,
        order: scheduleResponse.order,
        isInLsc: !scheduleResponse.hidden,
        isInAcf: !!scheduleResponse.grid,
      };
    }

    return {
      variant: 'core',
      dayType,
      order: scheduleResponse.order,
      isInLsc: !scheduleResponse.hidden,
      isInAcf: !!scheduleResponse.grid,
      ...(isDayOfWeekValid(dayOfWeek)
        ? { dayOfWeek }
        : { brokenDayOfWeek: dayOfWeek }),
    };
  }
  // MODIFIED
  else if (!scheduleResponse.static && !scheduleResponse.special) {
    const [dayType, modification] = parseScheduleCode(scheduleResponse);

    return {
      variant: 'modified',
      dayType,
      isInLsc: !scheduleResponse.hidden,
      ...(isModificationValid(modification) && modification !== 'OTHER'
        ? { modification }
        : {
            modification: 'OTHER',
            otherModification: modification || MISSING_MODIFICATION,
          }),
    };
  }
  // SPECIAL
  else if (!scheduleResponse.static && scheduleResponse.special) {
    const date = dayjs(scheduleResponse.name.replace('#-', '')).toDate();

    return {
      variant: 'special',
      date,
    };
  } else {
    throw new BlockingError(
      `Invalid schedule variant for ${scheduleResponse.name}`,
    );
  }
};

export const parseScheduleResponse = (
  scheduleResponse: ScheduleResponse,
  imagesResponse?: ImagesResponse,
  draftSchedulesResponse?: DraftSchedulesResponse,
): Schedule => {
  if (!scheduleResponse.id) {
    throw new Error(`No id for ${scheduleResponse.name}`);
  }

  if (!scheduleResponse.schoolId) {
    throw new Error(`No school id for ${scheduleResponse.name}`);
  }

  if (!scheduleResponse.displayName) {
    throw new BlockingError(`No display name for ${scheduleResponse.name}`);
  }

  const targetDate = draftSchedulesResponse?.find(
    (ds) => ds.id === scheduleResponse.id,
  )?.targetDate;

  return {
    createdAt: dayjs(scheduleResponse.createdAt + 'Z').toDate(),
    updatedAt: dayjs(scheduleResponse.updatedAt + 'Z').toDate(),
    author: scheduleResponse.author?.name,

    ...getScheduleVariantProps(scheduleResponse),

    id: scheduleResponse.id,
    schoolId: scheduleResponse.schoolId,
    originalScheduleCode: scheduleResponse.name,
    displayName: scheduleResponse.displayName,
    periods: scheduleResponse.periods.map(periodUtils.periodResponseToPeriod),
    lunchWaves: scheduleResponse.lunchWaves.map((lunchWave) => ({
      id: lunchWave.id!,
      name: lunchWave.name,
      slot: {
        id: lunchWave.lunchSlot.id!,
        slot: lunchWave.lunchSlot.slot,
      },
      startTime: formatResponseToTime(lunchWave.startTime),
      endTime: formatResponseToTime(lunchWave.endTime),
    })),

    isDraft: !!scheduleResponse.draft,
    isUserDraft: !!scheduleResponse.userDraft,
    image: imagesResponse?.find((i) => i.scheduleId === scheduleResponse.id)
      ?.imageViews[0],
    targetDate: targetDate ? dayjs(targetDate).toDate() : undefined,
  };
};

export const get = async <T>(path: string, isDashboardMode: boolean = true) => {
  const baseUrl = `${process.env.REACT_APP_URL_API}/`;
  const response = await axiosClient.get<T>(`${baseUrl}${path}`, {
    ...getRequestConfig(),
    params: isDashboardMode ? { dashboard_mode: true } : {},
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(`Error ${path} ${response.status}: ${response.statusText}`);
};

export const getWithHeaders = async <T>(path: string) => {
  const baseUrl = `${process.env.REACT_APP_URL_API}/`;
  const response = await axiosClient.get<T>(`${baseUrl}${path}`, {
    ...getRequestConfig(),
    params: { dashboard_mode: true },
  });

  if (response.status === 200) {
    return { data: response.data, headers: response.headers };
  }

  throw new Error(`Error ${path} ${response.status}: ${response.statusText}`);
};
