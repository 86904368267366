import {
  Card,
  Col,
  Image,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import React from 'react';
import moderationService from 'services/moderation.service';
import miscService from 'services/misc.service';
import { SuspensionPopover } from '../BanUser/BanUser';
import './ReportDetail.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
  faQuestion,
  faTrash,
  faUserLock,
  faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import { capitalize } from '@material-ui/core/utils';

export const Divider = ({ message }) => {
  return (
    <div className="text-divider">
      <hr />
      <span>{message}</span>
      <hr />
    </div>
  );
};

export const MessageBubble = ({
  content,
  has_media = false,
  media_url,
  user_name,
  target,
  violation_category,
  violation_subcategory,
}) => {
  return (
    <div className="bubble bubble-left">
      <span className="user-name">{user_name}</span>
      {has_media ? (
        <Image className="profile-pic" src={media_url} rounded />
      ) : (
        <div className="content-text">
          {content}
          <div className="detail-pills">
            {target && (
              <div className="detail-pill">
                <span>Target</span>
                <span>
                  {target
                    .split('_')
                    .map((w) => capitalize(w))
                    .join(' ')}
                </span>
              </div>
            )}
            {violation_category && (
              <div className="detail-pill">
                <span>Category</span>
                <span>
                  {violation_category
                    .split('_')
                    .map((w) => capitalize(w))
                    .join(' ')}
                </span>
              </div>
            )}
            {violation_subcategory && (
              <div className="detail-pill">
                <span>Subcategory</span>
                <span>
                  {violation_subcategory
                    .split('_')
                    .map((w) => capitalize(w))
                    .join(' ')}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const RejectPostButton = ({ action_taken, type, onClick }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {type === 'user_profile'
            ? "Doesn't apply for profile reports"
            : action_taken
            ? 'Content was deleted'
            : 'Delete Content'}
        </Tooltip>
      }
    >
      <FontAwesomeIcon
        className={`action-icon ${
          type === 'user_profile' || action_taken ? 'locked' : ''
        }`}
        icon={faTrash}
        onClick={type === 'user_profile' || action_taken ? null : onClick}
      />
    </OverlayTrigger>
  );
};

export const SuspendUserButton = ({ suspendedStatus, is_chat, onConfirm }) => {
  const renderTooltip = (props) => {
    return (
      <Popover
        id="suspension-popover"
        className="bulletin-suspension"
        {...props}
        show={props.show.toString()}
      >
        <h6 className="text-center">Suspend this user</h6>
        {suspendedStatus && suspendedStatus.times_suspended > 0 && (
          <p className="text-center text-muted">
            This user has been suspended previously (
            {suspendedStatus.times_suspended} times)
          </p>
        )}
        <SuspensionPopover
          onConfirm={(time, reason) => {
            onConfirm(time, reason);
          }}
        />
      </Popover>
    );
  };

  const is_suspended =
    suspendedStatus && suspendedStatus.suspended && !suspendedStatus.banned;
  const is_banned = suspendedStatus && suspendedStatus.banned;

  if (is_suspended) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            This user is suspended until {suspendedStatus.until}
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          className="action-icon"
          icon={faUserLock}
          color="orange"
          title="Suspend user"
        />
      </OverlayTrigger>
    );
  } else if (is_banned || is_chat) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {suspendedStatus && suspendedStatus.banned
              ? 'This user was banned'
              : "Doesn't apply for chat reports"}
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          className="action-icon locked"
          icon={faUserLock}
          title="Suspend user"
        />
      </OverlayTrigger>
    );
  } else if (!is_suspended && !is_banned && !is_chat) {
    return (
      <OverlayTrigger
        placement="top"
        trigger="click"
        rootClose
        overlay={renderTooltip}
      >
        <FontAwesomeIcon
          className="action-icon"
          icon={faUserLock}
          title="Suspend user"
        />
      </OverlayTrigger>
    );
  }
  return null;
};

export const BanUserButton = ({ banStatus, is_chat, onConfirm }) => {
  const is_banned = banStatus && banStatus.banned;

  if (is_banned || is_chat) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {banStatus && banStatus.banned
              ? 'This user was banned'
              : "Doesn't apply for chat reports"}
          </Tooltip>
        }
      >
        <FontAwesomeIcon className="action-icon locked" icon={faUserTimes} />
      </OverlayTrigger>
    );
  } else {
    return (
      <OverlayTrigger
        placement="top"
        rootClose
        overlay={<Tooltip>Ban this user</Tooltip>}
      >
        <FontAwesomeIcon
          className="action-icon"
          icon={faUserTimes}
          onClick={onConfirm}
        />
      </OverlayTrigger>
    );
  }
};

export const UserProfileCard = ({ user, comments }) => {
  return (
    <>
      <Card>
        <Card.Body>
          <div className="profile-detail">
            <div className="profile-pic">
              {user.profile_picture ? (
                <Image
                  src={`${user.profile_picture.size_urls.small}`}
                  roundedCircle
                />
              ) : (
                <FontAwesomeIcon icon={faQuestion} color="grey" size="2x" />
              )}
            </div>
            <div className="user-info">
              <Card.Title>
                {user.name} ({user.id})
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {user.school_title}
              </Card.Subtitle>
            </div>
          </div>
        </Card.Body>
        <Divider message="Comments" />
        <div className="report-comments">
          {comments.map((comment, index) => {
            return (
              <MessageBubble
                key={index}
                has_media={false}
                content={comment.comment}
                user_name={`${comment.name} (${comment.id})`}
                target={comment.target}
                violation_category={comment.violation_category}
                violation_subcategory={comment.violation_subcategory}
              />
            );
          })}
        </div>
      </Card>
    </>
  );
};

export const ChatDetailCard = ({ chat, comments }) => {
  return (
    <Card>
      <Card.Body>
        <div className="detail-row">
          <Col md={6}>
            <Card.Title>{chat.title || chat.friendly_name}</Card.Title>
            <Card.Subtitle className="mb-2 text-capitalize text-muted">
              {chat.type === 'dm' ? chat.type.toUpperCase() : chat.type} chat
            </Card.Subtitle>
            <hr className="header-divider" />
            <div className="chat-messages">
              {chat.latest_messages && chat.latest_messages.length > 0 ? (
                <>
                  <div className="message-counter">
                    Showing latest 20 messages
                  </div>
                  {chat.latest_messages &&
                    chat.latest_messages.map((message, index) => {
                      return (
                        <MessageBubble
                          key={index}
                          content={message.content}
                          has_media={message.media.length > 0}
                          media_url={
                            message.media.length > 0 &&
                            message.media[0].size_urls.medium
                          }
                          user_name={`${message.user_full_name} (${message.user_id})`}
                        />
                      );
                    })}
                </>
              ) : (
                <div className="message-counter">No messages to display</div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <Divider message="Comments" />
            <div className="report-comments">
              {comments.map((comment, index) => {
                return (
                  <MessageBubble
                    key={index}
                    has_media={false}
                    content={comment.comment}
                    user_name={`${comment.name} (${comment.id})`}
                    target={comment.target}
                    violation_category={comment.violation_category}
                    violation_subcategory={comment.violation_subcategory}
                  />
                );
              })}
            </div>
          </Col>
        </div>
      </Card.Body>
    </Card>
  );
};

export const ChatMessageCard = ({ message, comments }) => {
  return (
    <Card>
      <Card.Body>
        <div className="detail-row">
          <Col md={6} className="bulletin-post-detail">
            <Card.Title as="h4">{message.chat_name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {message.chat_type === 'dm'
                ? message.chat_type.toUpperCase()
                : message.chat_type}
              &nbsp; chat
            </Card.Subtitle>
            <hr className="header-divider" />
            <MessageBubble
              has_media={message.media.length > 0}
              media_url={
                message.media.length > 0 && message.media[0].size_urls.medium
              }
              content={message.content}
              user_name={`${message.user_full_name} (${message.from_user})`}
            />
          </Col>
          <Col md={6}>
            <Divider message="Comments" />
            <div className="report-comments">
              {comments.map((comment, index) => {
                return (
                  <MessageBubble
                    key={index}
                    has_media={false}
                    content={comment.comment}
                    user_name={`${comment.name} (${comment.id})`}
                    target={comment.target}
                    violation_category={comment.violation_category}
                    violation_subcategory={comment.violation_subcategory}
                  />
                );
              })}
            </div>
          </Col>
        </div>
      </Card.Body>
    </Card>
  );
};

export const BulletinPostCard = ({ post, comments }) => {
  return (
    <Card>
      <Card.Body>
        <div className="detail-row">
          <Col md={6} className="bulletin-post-detail">
            <Card.Title as="h4">{post.client_extra.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              by {post.user_full_name} ({post.from_user})
            </Card.Subtitle>
            <Card.Text className="text-justify">{post.content}</Card.Text>
            {post.media.length > 0 && (
              <Image className="post-img" src={post.media[0].size_urls.small} />
            )}

            {post.client_extra.link && (
              <div className="post-link">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip>{post.client_extra.link}</Tooltip>}
                >
                  <Card.Link href={post.client_extra.link} target="_blank">
                    <FontAwesomeIcon icon={faLink} /> Link
                  </Card.Link>
                </OverlayTrigger>
              </div>
            )}
          </Col>
          <Col md={6}>
            <Divider message="Comments" />
            <div className="report-comments">
              {comments.map((comment, index) => {
                return (
                  <MessageBubble
                    key={index}
                    has_media={false}
                    content={
                      comment.comment ? (
                        comment.comment
                      ) : (
                        <i>-- No comment --</i>
                      )
                    }
                    user_name={`${comment.name} (${comment.id})`}
                    target={comment.target}
                    violation_category={comment.violation_category}
                    violation_subcategory={comment.violation_subcategory}
                  />
                );
              })}
            </div>
          </Col>
        </div>
      </Card.Body>
    </Card>
  );
};

export const ContentActions = ({
  action_taken,
  type,
  content,
  onActionPerformed,
}) => {
  const deleteContent = () => {
    if (type === 'chat_message') {
      moderationService()
        .deleteChatMessage(content.id)
        .then((response) => {
          if (response.status === 204) {
            onActionPerformed();
          }
        });
    } else if (type === 'chat') {
      moderationService()
        .deleteChat(content.id)
        .then((response) => {
          if (response.status === 204) {
            onActionPerformed();
          }
        });
    }
  };

  return (
    <RejectPostButton
      action_taken={action_taken}
      type={type}
      onClick={deleteContent}
    />
  );
};

export const UserActions = ({ user_id, status, type, onActionPerformed }) => {
  const banUser = () => {
    const body = {
      user_id,
      reason:
        type === 'chat_message'
          ? 'Offensive chat messages'
          : "Profile doesn't follow our community rules",
      deletion: true,
    };

    miscService()
      .banUser(body)
      .then((response) => {
        if (response.status === 201) {
          onActionPerformed();
        }
      });
  };

  const suspendUser = (time, reason) => {
    const body = {
      user_id,
      reason,
      deletion: false,
      until: time,
    };

    miscService()
      .banUser(body)
      .then((response) => {
        if (response.status === 201) {
          onActionPerformed();
        }
      });
  };

  return (
    <>
      <SuspendUserButton
        is_chat={type === 'chat'}
        suspendedStatus={status}
        onConfirm={suspendUser}
      />
      <BanUserButton
        is_chat={type === 'chat'}
        banStatus={status}
        onConfirm={banUser}
      />
    </>
  );
};
