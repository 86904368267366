import styled from '@emotion/styled';
import * as M from '@mantine/core';

interface Props {
  dayType: string;
  isComplete: boolean;
  onSelect: () => void;
  onCopy: () => void;
}

const EditScheduleButton = ({
  dayType,
  isComplete,
  onSelect,
  onCopy,
}: Props) => {
  return (
    <Container>
      <Button onClick={onSelect}>
        <Content>
          {dayType}
          <Status isComplete={isComplete} />
        </Content>
      </Button>

      <M.Button disabled={!isComplete} onClick={onCopy}>
        Copy
      </M.Button>
    </Container>
  );
};

const Container = styled(M.Flex)`
  gap: ${(p) => p.theme.spacing.xs};
`;

const BaseButton = styled(M.Button)`
  flex: 1;
  color: ${(p) => p.theme.colors.gray[9]};
  background-color: ${(p) => p.theme.colors.gray[0]};
  border: 2px solid ${(p) => p.theme.colors.gray[5]};

  .mantine-Button-label {
    width: 100%;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.gray[2]};
  }
`;

const Button = M.createPolymorphicComponent<'button', M.ButtonProps>(
  BaseButton,
);

const Content = styled(M.Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

interface StatusProps {
  isComplete: boolean;
}

const Status = ({ isComplete }: StatusProps) => {
  return (
    <StatusContainer>
      <StatusLabel>{isComplete ? '' : 'Incomplete'}</StatusLabel>
      <Indicator isComplete={isComplete}>
        <Check isComplete={isComplete}>✔</Check>
      </Indicator>
    </StatusContainer>
  );
};

const StatusContainer = styled(M.Flex)`
  align-items: center;
  gap: ${(p) => p.theme.spacing.xs};
`;

const StatusLabel = styled(M.Text)`
  color: ${(p) => p.theme.colors.gray[6]};
  font-weight: normal;
  font-style: italic;
`;

const Indicator = styled(M.Flex)<StatusProps>`
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background-color: ${(p) =>
    p.isComplete ? p.theme.colors.blue[6] : p.theme.colors.gray[1]};
  border: ${(p) =>
    p.isComplete ? 'none' : `2px solid ${p.theme.colors.gray[5]}`};
`;

const Check = styled.span<StatusProps>`
  display: ${(p) => (p.isComplete ? 'block' : 'none')};
`;

export default EditScheduleButton;
