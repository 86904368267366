import styled from '@emotion/styled';
import * as M from '@mantine/core';

import { SCHEDULE_TYPE } from 'constants/scheduleTypes';
import type { School, ScheduleType } from 'types';

import DayAndPeriodSettings from './DayAndPeriodSettings';
import LunchSettings from './LunchSettings';

import { useUpdateScheduleTypeMutation } from '../hooks/scheduleTypeHooks';

interface Props {
  school: School;
  isUnitLunchLocallySelected: boolean;
  setIsUnitLunchLocallySelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleSettings = ({
  school,
  isUnitLunchLocallySelected,
  setIsUnitLunchLocallySelected,
}: Props) => {
  const updateScheduleType = useUpdateScheduleTypeMutation(school.schoolName);

  const scheduleTypes = Object.entries(SCHEDULE_TYPE).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Container>
      <M.Title order={2}>Schedule Settings</M.Title>
      <M.Title order={3}>Schedule Type</M.Title>
      <M.Select
        placeholder="Click to select"
        data={scheduleTypes}
        value={school.scheduleType}
        onChange={(st: ScheduleType) => updateScheduleType.mutate(st)}
        disabled={updateScheduleType.isLoading}
        rightSection={
          updateScheduleType.isLoading ? <M.Loader p="xs" /> : undefined
        }
      />

      <Row gap="md">
        <DayAndPeriodSettings
          settingType="day"
          schoolId={school.schoolName}
          scheduleType={school.scheduleType}
          schedules={school.schedules}
          types={school.dayTypes}
        />
        <DayAndPeriodSettings
          settingType="period"
          schoolId={school.schoolName}
          scheduleType={school.scheduleType}
          schedules={school.schedules}
          types={school.periodTypes}
        />
        <LunchSettings
          schoolId={school.schoolName}
          lunchClassId={school.courses.find((c) => c.name === 'Lunch')?.id}
          lunchSettings={school.lunchSettings}
          isUnitLunchLocallySelected={isUnitLunchLocallySelected}
          setIsUnitLunchLocallySelected={setIsUnitLunchLocallySelected}
        />
      </Row>
    </Container>
  );
};

const Container = styled(M.Flex)`
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.md};
`;

const Row = styled(M.Flex)`
  & > * {
    flex: 1;
  }
`;

export default ScheduleSettings;
