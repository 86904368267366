import * as M from '@mantine/core';

import { SCHEDULE_TYPE } from 'constants/scheduleTypes';

import { LUNCH_TYPES } from 'features/scheduleSettings/constants';
import type { Step } from 'features/scheduleConfiguration/types';

interface Props {
  step: Step;
}

const ScheduleSettingsReview = ({ step }: Props) => {
  if (step.type !== 'scheduleSettingsStep' || !step.payload) {
    return <></>;
  }

  const { scheduleType, dayTypes, periodTypes, lunchSettings } = step.payload;

  return (
    <div>
      <M.Title order={6}>Schedule type:</M.Title>
      <M.Text>{SCHEDULE_TYPE[scheduleType]}</M.Text>
      <M.Title order={6}>Day types:</M.Title>
      <M.Text>
        {dayTypes.length ? dayTypes.map((dt) => dt.name).join(', ') : '-'}
      </M.Text>
      <M.Title order={6}>Period types:</M.Title>
      <M.Text>
        {periodTypes.length ? periodTypes.map((pt) => pt.name).join(', ') : '-'}
      </M.Text>
      <M.Title order={6}>Lunch type:</M.Title>
      <M.Text>
        {lunchSettings.type === 'waves'
          ? `${lunchSettings.slots.length} ${
              lunchSettings.isAlphabetic ? 'alphabetic' : 'numeric'
            } waves`
          : lunchSettings.type
          ? LUNCH_TYPES[lunchSettings.type]
          : ''}
      </M.Text>
    </div>
  );
};

export default ScheduleSettingsReview;
