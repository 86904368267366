export default [{x:33.2525,y:-111.637},
{x:35.9134,y:-79.05569},
{x:38.8129,y:-94.5108},
{x:33.30058301,y:-117.31239259},
{x:35.6533,y:-97.4644},
{x:30.6779,y:-88.1252},
{x:33.0703,y:-96.8752},
{x:41.46682,y:-82.012572},
{x:38.0254,y:-84.536},
{x:30.0065,y:-90.1566},
{x:32.648,y:-96.8565},
{x:29.4954,y:-98.7094},
{x:33.933,y:-118.118},
{x:27.96013982,y:-82.73104191},
{x:26.2707,y:-98.2194},
{x:39.1604139,y:-84.42457287},
{x:43.07460124,y:-89.39519405},
{x:36.9521,y:-86.4317},
{x:33.5827,y:-111.923},
{x:34.7209,y:-77.344},
{x:26.1995,y:-98.2872},
{x:31.5248,y:-97.1646},
{x:40.79580478,y:-77.86157191},
{x:36.3278,y:-119.674},
{x:33.3777,y:-112.029},
{x:37.1825,y:-93.2947},
{x:21.2908,y:-157.844},
{x:33.1935,y:-97.0942},
{x:33.8869,y:-118.314},
{x:33.2293,y:-97.1671},
{x:29.8746,y:-90.0937},
{x:33.8487,y:-117.543},
{x:29.57490339,y:-95.11039138},
{x:33.0188,y:-96.716},
{x:37.99138552,y:-121.31281078},
{x:32.033825,y:-102.13343},
{x:33.4575,y:-94.0885},
{x:32.9348,y:-80.1401},
{x:32.9319,y:-80.0373},
{x:36.0033,y:-115.118},
{x:34.069307,y:-83.986156},
{x:32.1770382,y:-110.94042957},
{x:38.678303,y:-121.27551},
{x:26.3021,y:-98.1698},
{x:39.398387,y:-76.603906},
{x:31.3009,y:-94.7281},
{x:39.251618,y:-84.59851},
{x:32.8993,y:-97.3188},
{x:30.4963,y:-91.1548},
{x:35.3527,y:-94.3498},
{x:36.3344,y:-94.177},
{x:30.0176,y:-95.4398},
{x:41.267,y:-96.0677},
{x:45.1676,y:-93.2305},
{x:33.7765,y:-117.993},
{x:29.8653,y:-95.6457},
{x:29.808,y:-95.17},
{x:33.610345,y:-112.18922},
{x:32.5935,y:-97.1495},
{x:35.5362,y:-97.5651},
{x:31.7531,y:-93.0798},
{x:29.5636,y:-98.3332},
{x:21.2913,y:-157.822},
{x:36.1143,y:-94.1454},
{x:21.283,y:-157.712},
{x:29.652237,y:-82.344566},
{x:38.6295,y:-90.2463},
{x:40.144868,y:-82.961634},
{x:33.1401,y:-117.193},
{x:38.296438,y:-77.51399},
{x:39.680078,y:-75.735453},
{x:35.987694,y:-86.54708},
{x:33.74770679,y:-117.00505435},
{x:29.4913,y:-98.4379},
{x:29.6034,y:-98.2798},
{x:33.46458,y:-112.17058},
{x:39.5582,y:-104.779},
{x:29.9973,y:-95.5778},
{x:45.017579,y:-93.17912},
{x:33.459809,y:-112.34151},
{x:38.2934,y:-85.5586},
{x:33.89469,y:-117.56341},
{x:32.5249,y:-92.0955},
{x:32.6481,y:-97.0568},
{x:30.1576,y:-92.0479},
{x:31.9937,y:-102.154},
{x:27.54529374,y:-99.48312528},
{x:33.111,y:-96.805},
{x:30.3899,y:-91.0621},
{x:61.1454,y:-149.866},
{x:40.729824,y:-73.991767},
{x:36.2934,y:-95.8266},
{x:39.5959,y:-104.804},
{x:40.09816498,y:-83.09033453},
{x:39.6532,y:-104.903},
{x:29.9485,y:-90.0629},
{x:33.859885,y:-117.92676},
{x:40.55260514,y:-111.89606283},
{x:41.64419951,y:-93.49779904},
{x:36.115,y:-115.14},
{x:42.0021,y:-87.661},
{x:30.2212,y:-95.5687},
{x:36.0621,y:-95.7802},
{x:33.3341,y:-111.912},
{x:35.211902,y:-97.445637},
{x:29.9747,y:-95.7006},
{x:29.9551,y:-89.984},
{x:40.808153,y:-73.946872},
{x:32.929,y:-96.82},
{x:39.0533,y:-94.5939},
{x:29.7392,y:-95.4645},
{x:33.4631,y:-112.273},
{x:32.4986,y:-92.0603},
{x:32.9107,y:-96.8725},
{x:37.609602,y:-120.97296},
{x:30.2396,y:-97.727},
{x:41.1224,y:-100.764},
{x:40.2511,y:-111.661},
{x:26.1715,y:-97.6727},
{x:34.311268,y:-88.706955},
{x:41.3143,y:-81.5173},
{x:32.8286,y:-96.8445},
{x:41.6175,y:-87.8534},
{x:45.0559,y:-93.1466},
{x:35.104128,y:-106.55017},
{x:41.8446,y:-87.804},
{x:36.093,y:-95.9228},
{x:41.089547,y:-111.98134},
{x:30.65863834,y:-96.33221},
{x:38.820994,y:-90.877502},
{x:34.602309,y:-118.14926},
{x:34.1351,y:-117.433},
{x:30.1826,y:-93.2189},
{x:32.4086,y:-88.7056},
{x:31.7087,y:-89.1491},
{x:32.4821,y:-93.7518},
{x:41.21701593,y:-80.74119687},
{x:41.2057,y:-96.0819},
{x:34.230758,y:-118.55387},
{x:39.02788553,y:-84.45022523},
{x:34.166412,y:-118.3235},
{x:29.7803,y:-95.6069},
{x:29.557,y:-95.3684},
{x:39.901673,y:-75.240184},
{x:41.997755,y:-72.580681},
{x:40.441456,y:-79.95866},
{x:39.99672561,y:-85.93233883},
{x:35.3491,y:-119.129},
{x:33.333548,y:-111.84154},
{x:34.1166,y:-117.832},
{x:40.7125,y:-99.0846},
{x:33.9193,y:-117.967},
{x:33.6003,y:-111.986},
{x:40.691597,y:-73.987086},
{x:33.672,y:-117.952},
{x:37.352706,y:-77.413361},
{x:41.460838,y:-81.856475},
{x:40.47708,y:-86.108476},
{x:41.70289503,y:-88.3028598},
{x:34.140363,y:-118.02037},
{x:32.584208,y:-117.11407},
{x:40.384918,y:-79.82537},
{x:32.878,y:-97.1005},
{x:33.3073,y:-111.756},
{x:38.4403,y:-90.3734},
{x:31.8198,y:-106.431},
{x:32.3314,y:-90.1054},
{x:37.57393,y:-77.464572},
{x:33.6858,y:-117.919},
{x:36.0587,y:-115.243},
{x:44.979,y:-93.2349},
{x:42.38426501,y:-87.96116173},
{x:29.5152,y:-98.4649},
{x:38.591,y:-90.2941},
{x:41.245722,y:-81.356381},
{x:32.6923,y:-97.0249},
{x:32.606583,y:-97.409065},
{x:33.9493,y:-83.3859},
{x:36.299,y:-119.315},
{x:33.78578182,y:-117.91640997},
{x:44.681243,y:-93.290462},
{x:36.1944,y:-115.247},
{x:29.8138,y:-95.4299},
{x:35.130752,y:-106.58892},
{x:29.9639,y:-95.5421},
{x:30.0175,y:-90.2391},
{x:29.6607,y:-95.737},
{x:31.7721,y:-106.499},
{x:41.654911,y:-93.73574},
{x:29.6017,y:-95.6299},
{x:29.743,y:-95.4075},
{x:40.75739196,y:-73.98626268},
{x:44.9382,y:-92.9326},
{x:32.7334,y:-97.7938},
{x:33.0725,y:-97.0799},
{x:40.05342247,y:-82.86316752},
{x:42.34941813,y:-71.08102262},
{x:31.1202,y:-97.8683},
{x:26.527324,y:-80.093851},
{x:29.0492,y:-95.4643},
{x:39.4683,y:-119.786},
{x:30.3073,y:-89.8257},
{x:32.7586,y:-97.4251},
{x:33.8799,y:-98.5408},
{x:38.0002,y:-84.5214},
{x:33.5234,y:-112.065},
{x:41.2345,y:-96.1417},
{x:30.418,y:-91.1763},
{x:37.117304,y:-76.466779},
{x:33.5927,y:-101.935},
{x:42.339813,y:-71.590028},
{x:39.12799623,y:-84.51695859},
{x:37.51028,y:-77.61273},
{x:32.96463,y:-96.645467},
{x:32.594,y:-96.9394},
{x:33.32121,y:-111.68695},
{x:35.95681869,y:-83.93244088},
{x:39.166251,y:-86.531017},
{x:41.7073,y:-86.1809},
{x:39.62796,y:-84.191016},
{x:41.485244,y:-81.791014},
{x:32.4114,y:-93.7959},
{x:30.073,y:-95.5129},
{x:41.7595,y:-88.2046},
{x:42.05183,y:-87.997106},
{x:35.3886,y:-119.092},
{x:29.5505,y:-98.6649},
{x:36.1006,y:-115.187},
{x:29.962,y:-90.1135},
{x:45.1334,y:-93.4762},
{x:30.6183,y:-96.3225},
{x:39.2452,y:-94.4439},
{x:32.739058,y:-116.94174},
{x:32.8624,y:-96.6446},
{x:32.9122,y:-117.147},
{x:30.3343,y:-90.9853},
{x:36.061,y:-95.8583},
{x:29.7783,y:-95.4101},
{x:29.724,y:-95.4687},
{x:42.0395,y:-88.0495},
{x:39.69686851,y:-84.10308301},
{x:41.2929,y:-96.1157},
{x:40.9312,y:-98.3816},
{x:41.2601,y:-96.0222},
{x:45.55337,y:-94.18997},
{x:30.4485,y:-90.0815},
{x:38.114865,y:-121.30405},
{x:32.9539,y:-96.8428},
{x:42.0405,y:-87.7972},
{x:32.6304,y:-117.08499},
{x:32.7553,y:-97.0976},
{x:36.1448,y:-115.314},
{x:40.142105,y:-88.258476},
{x:37.404869,y:-77.669982},
{x:29.983737,y:-95.435213},
{x:33.5285,y:-117.149},
{x:38.5651,y:-90.4063},
{x:30.4589,y:-90.9559},
{x:33.561897,y:-117.66892},
{x:40.018527,y:-83.027018},
{x:34.1074,y:-117.649},
{x:32.541,y:-92.6295},
{x:27.916395,y:-82.736794},
{x:33.7894,y:-118.14},
{x:40.15977936,y:-83.09079587},
{x:32.8808,y:-97.391},
{x:35.493,y:-97.5472},
{x:29.6502,y:-95.1649},
{x:33.4799,y:-111.988},
{x:33.2174,y:-96.6352},
{x:32.33884,y:-97.411736},
{x:33.1147,y:-117.101},
{x:40.003427,y:-83.153271},
{x:38.035186,y:-78.500655},
{x:30.3892,y:-91.0879},
{x:35.4638,y:-97.6184},
{x:33.8181,y:-118.35},
{x:21.402569,y:-157.96988},
{x:29.5828,y:-90.6947},
{x:29.8705,y:-95.3275},
{x:32.3999,y:-90.138},
{x:31.2908,y:-92.4774},
{x:32.722,y:-96.8345},
{x:32.6823,y:-97.4618},
{x:41.5599,y:-93.7876},
{x:32.429,y:-96.838},
{x:38.9571,y:-94.7256},
{x:33.2464,y:-111.841},
{x:34.0713,y:-117.557},
{x:38.807,y:-90.3022},
{x:29.7915,y:-95.7185},
{x:41.2122,y:-95.991},
{x:33.93728957,y:-118.04159403},
{x:29.6866,y:-98.1271},
{x:32.5321,y:-97.3384},
{x:29.9148,y:-95.4848},
{x:30.1212,y:-94.1647},
{x:30.0039,y:-90.1762},
{x:40.815,y:-96.7014},
{x:30.4123,y:-88.7835},
{x:26.1712,y:-98.0065},
{x:29.908054,y:-95.68522},
{x:37.70242974,y:-121.06890142},
{x:42.109637,y:-87.979739},
{x:30.0756,y:-95.6285},
{x:21.33080477,y:-158.07399273},
{x:40.8175,y:-96.6543},
{x:40.54469783,y:-111.9810494},
{x:32.4393,y:-93.7155},
{x:33.39226842,y:-111.85747107},
{x:36.1265,y:-97.0508},
{x:25.584971,y:-80.363264},
{x:39.01519916,y:-94.71029162},
{x:32.8116,y:-96.7296},
{x:32.9082,y:-96.5598},
{x:38.543514,y:-121.74143},
{x:20.876072,y:-156.45448},
{x:34.0712,y:-117.978},
{x:40.218636,y:-85.394534},
{x:42.15015305,y:-88.33480954},
{x:36.128707,y:-86.85228},
{x:42.483,y:-92.4429},
{x:41.9492,y:-87.6583},
{x:39.7679,y:-105.082},
{x:41.186832,y:-111.9842},
{x:29.7358,y:-95.5962},
{x:39.29416347,y:-84.29397047},
{x:29.85183,y:-95.50927},
{x:30.3932,y:-91.1609},
{x:39.5384,y:-119.734},
{x:41.372792,y:-81.827529},
{x:39.73380861,y:-82.62168825},
{x:36.238839,y:-115.19216},
{x:33.98025356,y:-118.05046141},
{x:29.9147,y:-95.41},
{x:33.573,y:-117.724},
{x:38.7838,y:-90.5016},
{x:25.791019,y:-80.131701},
{x:40.609234,y:-73.95759},
{x:30.421905,y:-91.21811},
{x:33.904,y:-117.475},
{x:34.67878,y:-82.839687},
{x:36.10286,y:-115.17251},
{x:32.5464,y:-93.7095},
{x:41.9386,y:-88.1081},
{x:37.07654537,y:-94.47712719},
{x:40.5601,y:-105.077},
{x:40.69947895,y:-112.02540994},
{x:28.39913,y:-81.489063},
{x:39.97901102,y:-75.15342057},
{x:33.7596,y:-117.835},
{x:33.997701,y:-117.72106},
{x:34.78960282,y:-92.21589088},
{x:41.540221,y:-88.20223},
{x:41.57892002,y:-88.15705419},
{x:41.024914,y:-80.646615},
{x:36.1623,y:-115.062},
{x:33.678,y:-117.663},
{x:29.505,y:-95.0431},
{x:33.0557,y:-96.7698},
{x:29.7759,y:-98.7255},
{x:41.4051,y:-82.6603},
{x:30.3899,y:-88.989},
{x:25.809542,y:-80.32811},
{x:33.8302,y:-117.942},
{x:38.88329966,y:-94.67279434},
{x:33.1982,y:-117.248},
{x:38.5948,y:-89.9857},
{x:32.9097,y:-96.4477},
{x:40.4228,y:-86.9067},
{x:42.0196,y:-97.4265},
{x:33.8435,y:-118.264},
{x:29.7785,y:-95.7523},
{x:25.47818603,y:-80.44583738},
{x:41.2623,y:-95.8816},
{x:36.9434,y:-76.3096},
{x:41.683641,y:-81.3399448},
{x:32.5748,y:-97.0871},
{x:30.0685,y:-94.1304},
{x:31.0688,y:-97.6674},
{x:38.0424,y:-84.5042},
{x:40.72425039,y:-111.88862801},
{x:30.2852,y:-89.7524},
{x:33.9961,y:-117.93},
{x:33.6855,y:-117.167},
{x:26.262761,y:-80.099618},
{x:40.621807,y:-111.8686},
{x:29.664502,y:-95.114934},
{x:39.4079,y:-104.858},
{x:35.0922,y:-92.4176},
{x:41.69531487,y:-91.61120832},
{x:31.4308,y:-100.456},
{x:32.9428,y:-96.7004},
{x:41.417243,y:-82.209662},
{x:34.74718556,y:-92.341365},
{x:40.294588,y:-83.03817},
{x:38.9,y:-77.005397},
{x:29.5384,y:-95.1519},
{x:37.8978,y:-84.585},
{x:33.0428,y:-97.0164},
{x:38.9396,y:-95.261},
{x:44.7324,y:-93.2187},
{x:29.7351,y:-95.8109},
{x:31.7719,y:-106.377},
{x:33.8493,y:-117.791},
{x:39.995003,y:-83.006871},
{x:28.391674,y:-81.243923},
{x:30.5206,y:-92.0816},
{x:29.9849,y:-91.8183},
{x:33.5193,y:-101.878},
{x:39.867743,y:-84.138246},
{x:38.5922,y:-90.5777},
{x:39.339609,y:-84.533393},
{x:39.180512,y:-76.823074},
{x:32.73368,y:-96.61561},
{x:30.3181,y:-95.4801},
{x:39.35128206,y:-82.97716677},
{x:33.22574673,y:-96.98541105},
{x:21.2785,y:-157.829},
{x:34.070399,y:-83.916106},
{x:35.1852,y:-111.662},
{x:36.308745,y:-86.60486},
{x:39.1831,y:-96.5531},
{x:26.681241,y:-80.201381},
{x:35.3921,y:-97.5459},
{x:29.7013,y:-95.293},
{x:27.7079,y:-97.3746},
{x:36.1586,y:-115.204},
{x:32.5118,y:-92.1579},
{x:36.0525,y:-115.172},
{x:30.454,y:-91.0924},
{x:29.9998,y:-90.1254},
{x:32.2206,y:-110.857},
{x:32.7171,y:-96.3241},
{x:25.9675,y:-97.5105},
{x:33.0265,y:-96.8428},
{x:41.7741,y:-87.9762},
{x:32.8573,y:-97.185},
{x:32.8485,y:-96.7696},
{x:41.5744,y:-90.5351},
{x:30.4079,y:-91.0187},
{x:38.8934,y:-104.721},
{x:30.5005,y:-90.4837},
{x:29.9773,y:-90.196},
{x:39.54809961,y:-105.00199199},
{x:21.49679863,y:-158.05700362},
{x:38.8972,y:-104.835},
{x:30.6335,y:-97.6964},
{x:39.35298,y:-84.368},
{x:39.91408956,y:-82.78038919},
{x:30.448535,y:-84.314114},
{x:38.6032,y:-90.4397},
{x:30.3467,y:-97.9651},
{x:39.15820139,y:-84.6294719},
{x:32.6856,y:-97.1313},
{x:29.6078,y:-98.5972},
{x:35.1596,y:-101.921},
{x:41.41980918,y:-81.75912738},
{x:38.2356,y:-85.7162},
{x:41.753982,y:-111.83481},
{x:40.11008079,y:-88.23230491},
{x:34.078834,y:-118.11052},
{x:45.130524,y:-93.35415},
{x:33.1756,y:-96.8412},
{x:41.9082,y:-87.8373},
{x:33.216484,y:-87.5463},
{x:32.8271,y:-79.8358},
{x:39.94315679,y:-104.98379052},
{x:32.346029,y:-95.267661},
{x:41.54750812,y:-83.59874189},
{x:29.9417,y:-90.0957},
{x:32.4773,y:-99.6981},
{x:31.1108,y:-97.7473},
{x:30.006,y:-90.0335},
{x:29.6814,y:-95.4254},
{x:61.231,y:-149.741},
{x:31.7595,y:-106.271},
{x:39.89537,y:-74.941496},
{x:32.7136,y:-97.2823},
{x:29.8019,y:-90.8166},
{x:39.896565,y:-82.881585},
{x:36.061,y:-96.0051},
{x:30.2372,y:-90.9132},
{x:38.847019,y:-76.887043},
{x:34.0842,y:-117.671},
{x:30.2138,y:-92.0492},
{x:30.4283,y:-91.0554},
{x:36.13759745,y:-85.50600708},
{x:29.889,y:-90.0562},
{x:35.3193,y:-97.4992},
{x:35.4363,y:-97.4056},
{x:32.8791,y:-111.681},
{x:38.7349,y:-90.3952},
{x:42.566065,y:-87.884515},
{x:33.5814,y:-112.119},
{x:39.952946,y:-83.124237},
{x:39.9147,y:-105.047},
{x:41.4518,y:-96.4837},
{x:29.9092,y:-90.0186},
{x:30.3954,y:-90.0914},
{x:34.6181,y:-98.4218},
{x:41.310423,y:-81.835879},
{x:30.648,y:-91.1457},
{x:33.937288,y:-118.0416},
{x:39.87869531,y:-83.05489719},
{x:38.84651802,y:-104.75940764},
{x:30.273,y:-97.8002},
{x:38.04030078,y:-84.42819357},
{x:28.346639,y:-81.614621},
{x:29.6964,y:-95.8454},
{x:41.96769909,y:-87.80928969},
{x:29.9587,y:-90.1827},
{x:42.34470804,y:-87.89787233},
{x:32.133906,y:-111.00746},
{x:39.955738,y:-82.902375},
{x:30.1413,y:-91.943},
{x:45.03372913,y:-93.01827371},
{x:30.3778,y:-91.0961},
{x:32.6654,y:-97.4008},
{x:39.95413709,y:-75.2018398},
{x:39.69500282,y:-104.93999004},
{x:30.0511,y:-95.1831},
{x:31.6092,y:-94.6194},
{x:36.2698,y:-115.207},
{x:30.4194,y:-91.0889},
{x:29.9394,y:-90.0749},
{x:34.363413,y:-89.535632},
{x:30.1766,y:-95.4583},
{x:32.918,y:-96.9594},
{x:29.9232,y:-95.6007},
{x:32.4111,y:-99.7742},
{x:33.4224,y:-111.924},
{x:39.94049164,y:-83.83255541},
{x:36.808926,y:-119.69597},
{x:34.426273,y:-117.37394},
{x:32.98698,y:-96.910929},
{x:29.7773,y:-94.9777},
{x:32.9348,y:-97.2318},
{x:33.639,y:-112.23},
{x:32.3135,y:-106.742},
{x:32.81120029,y:-96.63009882},
{x:21.4535,y:-158.006},
{x:29.638,y:-95.5803},
{x:33.862665,y:-118.09052},
{x:41.9541,y:-88.7243},
{x:30.3846,y:-88.5168},
{x:44.7458806,y:-93.2996133},
{x:39.097828,y:-84.269142},
{x:29.3519,y:-98.4573},
{x:28.8551,y:-96.9977},
{x:36.845832,y:-119.79123},
{x:38.881,y:-94.7617},
{x:39.784,y:-104.774},
{x:42.02868339,y:-91.63048218},
{x:29.99863314,y:-95.16589701},
{x:33.6386,y:-112.368},
{x:44.9481,y:-93.2364},
{x:35.491,y:-97.7597},
{x:40.037194,y:-74.826453},
{x:38.2178,y:-85.5848},
{x:38.94929401,y:-92.33470816},
{x:41.8591,y:-88.0676},
{x:29.4546,y:-98.55},
{x:27.314784,y:-80.394036},
{x:30.5276,y:-90.9567},
{x:41.11886239,y:-81.48398638},
{x:38.1633,y:-85.8318},
{x:40.7531,y:-96.6415},
{x:32.18759874,y:-90.2635324},
{x:33.67787203,y:-96.61014855},
{x:39.763242,y:-89.708755},
{x:30.4736,y:-90.8628},
{x:32.9088,y:-96.7679},
{x:36.265,y:-115.257},
{x:44.8944,y:-93.0812},
{x:33.6400736,y:-112.1195225},
{x:32.5415,y:-94.7399},
{x:29.8815,y:-97.9188},
{x:37.757,y:-87.0724},
{x:32.4349,y:-97.7739},
{x:29.5479,y:-95.2321},
{x:33.8535,y:-118.138},
{x:25.9213,y:-97.469},
{x:31.547,y:-97.1261},
{x:32.23580027,y:-110.93299985},
{x:35.3669,y:-119.047},
{x:39.584696,y:-76.989695},
{x:39.5483,y:-119.822},
{x:32.7293,y:-97.3608},
{x:41.65234479,y:-87.73777664},
{x:39.770277,y:-84.055365},
{x:41.511984,y:-87.95348},
{x:40.13757,y:-74.965305},
{x:32.2834,y:-110.979},
{x:36.110015,y:-95.975296},
{x:39.026277,y:-76.689801},
{x:29.3571,y:-98.5352},
{x:29.793175,y:-95.545102},
{x:33.8254,y:-117.836},
{x:36.35660144,y:-94.21358943},
{x:30.4442,y:-91.1381},
{x:36.0351,y:-115.046},
{x:33.1292,y:-96.7339},
{x:33.389968,y:-111.59861},
{x:30.7069,y:-95.5639},
{x:39.7117,y:-104.822},
{x:32.86350247,y:-96.8955034},
{x:29.4443,y:-98.6624},
{x:34.0035,y:-118.089},
{x:41.155315,y:-81.328713},
{x:31.3253,y:-89.344},
{x:38.6121,y:-90.3235},
{x:45.2068,y:-93.3569},
{x:37.043398,y:-76.384839},
{x:34.8536,y:-117.083},
{x:38.366793,y:-121.96261},
{x:32.9787,y:-96.7686},
{x:38.6679,y:-90.6037},
{x:38.67383,y:-90.357139},
{x:39.76368622,y:-86.36258662},
{x:33.0097,y:-96.6114},
{x:33.05659587,y:-96.68899477},
{x:33.0258,y:-97.2764},
{x:30.1269,y:-95.4343},
{x:39.98136,y:-82.020124},
{x:40.06661858,y:-83.02084963},
{x:29.509855,y:-98.373955},
{x:38.424422,y:-121.39246},
{x:37.79800093,y:-121.19598985},
{x:33.916183,y:-118.38853},
{x:33.12579946,y:-96.89099729},
{x:30.130679,y:-95.231641},
{x:35.2963,y:-119.072},
{x:29.5659,y:-95.5638},
{x:33.694796,y:-117.33656},
{x:38.1475,y:-85.5853},
{x:41.81540172,y:-71.49663627},
{x:33.2191,y:-96.8804},
{x:34.08644,y:-84.52323},
{x:33.6409,y:-112.059},
{x:39.980472,y:-82.829067},
{x:30.5264,y:-97.8097},
{x:44.8341,y:-93.1739},
{x:38.61015533,y:-121.3855871},
{x:30.5435,y:-91.0294},
{x:31.4838,y:-97.2097},
{x:31.357469,y:-92.41715},
{x:38.2202,y:-84.5416},
{x:41.882228,y:-87.626891},
{x:34.92384476,y:-120.42612076},
{x:32.8393,y:-97.2381},
{x:33.436491,y:-111.86946},
{x:38.62199,y:-76.916331},
{x:34.0697,y:-117.205},
{x:33.5868,y:-101.87},
{x:35.605663,y:-77.369616},
{x:40.1474,y:-105.13},
{x:36.2402,y:-115.122},
{x:32.4449,y:-93.862},
{x:36.057,y:-94.186},
{x:30.2815,y:-97.7425},
{x:33.6144,y:-117.709},
{x:33.9228,y:-116.953},
{x:29.9492,y:-93.9918},
{x:30.0601,y:-99.1687},
{x:38.0601,y:-78.4939},
{x:33.127,y:-96.6607},
{x:37.7364,y:-84.3307},
{x:33.5422,y:-101.923},
{x:40.187068,y:-74.869394},
{x:41.51983246,y:-81.46742642},
{x:30.4833,y:-90.4574},
{x:35.5586,y:-97.6411},
{x:39.00294406,y:-84.65058625},
{x:33.495152,y:-112.06457},
{x:39.66804381,y:-105.08229196},
{x:30.5586,y:-97.6885},
{x:29.4997,y:-98.625},
{x:39.07310285,y:-84.33764756},
{x:13.5203,y:144.819},
{x:30.4385,y:-89.0968},
{x:38.7445,y:-90.6352},
{x:33.4484,y:-112.074},
{x:31.88369805,y:-106.58099771},
{x:42.3517,y:-71.1188},
{x:31.819686,y:-106.54253},
{x:29.7372,y:-95.5023},
{x:35.1827,y:-101.867},
{x:40.8431,y:-96.6801},
{x:33.381,y:-111.74},
{x:44.9733,y:-93.2388},
{x:41.6931,y:-87.7405},
{x:32.8378,y:-116.983},
{x:41.1569,y:-95.9271},
{x:35.2179,y:-97.4247},
{x:30.4813,y:-97.586},
{x:26.1945,y:-98.2044},
{x:40.356923,y:-80.120996},
{x:34.04099613,y:-118.14151168},
{x:33.93627483,y:-117.26517498},
{x:31.89168725,y:-102.3447758},
{x:29.5129,y:-98.5698},
{x:29.6233,y:-98.4941},
{x:30.47930206,y:-97.80459523},
{x:34.0673,y:-117.261},
{x:32.6985,y:-114.601},
{x:34.2216,y:-119.159},
{x:33.814003,y:-116.48642},
{x:33.0094,y:-96.9685},
{x:41.8538,y:-88.3389},
{x:40.03041585,y:-82.4403441},
{x:32.4631,y:-90.1338},
{x:36.22582314,y:-119.32368457},
{x:36.0147,y:-95.8858},
{x:39.778356,y:-104.88492},
{x:33.3794,y:-111.807},
{x:61.57890056,y:-149.41899776},
{x:32.3047,y:-95.3001},
{x:33.56273741,y:-117.20636487},
{x:30.1886,y:-92.0135},
{x:39.02490169,y:-94.24939156},
{x:42.734601,y:-84.483608},
{x:38.9406,y:-104.798},
{x:39.28746,y:-84.458359},
{x:39.4251,y:-84.5964},
{x:39.024197,y:-77.426536},
{x:45.5184,y:-122.677},
{x:35.6139,y:-97.549},
{x:42.742618,y:-71.158982},
{x:30.0669,y:-90.482},
{x:39.2557,y:-94.6491},
{x:41.41835295,y:-81.91051662},
{x:31.0784,y:-97.3666},
{x:34.1293,y:-117.891},
{x:29.6117,y:-90.7554},
{x:35.2187,y:-97.4954},
{x:40.01849,y:-83.024823},
{x:32.7407,y:-96.4492},
{x:42.22563001,y:-88.303775},
{x:37.737383,y:-122.19786},
{x:40.860961,y:-81.432995},
{x:30.2187,y:-93.3248},
{x:33.3779,y:-111.909},
{x:33.94698385,y:-118.22788435},
{x:38.773537,y:-121.26522},
{x:32.8091,y:-96.7734},
{x:41.7183,y:-87.6825},
{x:39.914213,y:-104.96753097},
{x:33.53758515,y:-112.25782335}];