import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './SchoolsMapContainer.scss';
import { miscActions } from 'store/actions/actions';
import { Row, Spinner, Modal, Card } from 'react-bootstrap';
import ReactMapGL, {
  FlyToInterpolator,
  Layer,
  Popup,
  Source,
} from 'react-map-gl';
import MapSearchBar from 'components/shared/MapSearchBar/MapSearchBar';
import Chip from '@material-ui/core/Chip';
import { statuses as defaultStatuses } from 'constants/statuses';
import MapFilters from './MapFilters';
import MapStats from './MapStats';
import burgerkingCoordinates from 'assets/burgerking.js';
import burgerkingLogo from 'assets/burgerking.png';
import chickfilaCoordinates from 'assets/chickfila.js';
import chickfilaLogo from 'assets/chickfila.png';
import chipotleCoordinates from 'assets/chipotle.js';
import chipotleLogo from 'assets/chipotle.png';
import dunkindonutsCoordinates from 'assets/dunkindonuts.js';
import dunkindonutsLogo from 'assets/dunkindonuts.png';
import mcdonaldsCoordinates from 'assets/mcdonalds.js';
import mcdonaldsLogo from 'assets/mcdonalds.png';
import raisingcanesCoordinates from 'assets/raisingcanes.js';
import raisingcanesLogo from 'assets/raisingcanes.png';
import sonicdriveinCoordinates from 'assets/sonicdrivein.js';
import sonicdriveinLogo from 'assets/sonicdrivein.png';
import starbucksCoordinates from 'assets/starbucks.js';
import starbucksLogo from 'assets/starbucks.png';
import subwayCoordinates from 'assets/subway.js';
import subwayLogo from 'assets/subway.png';
import tacobellCoordinates from 'assets/tacobell.js';
import tacobellLogo from 'assets/tacobell.png';
import wendysCoordinates from 'assets/wendys.js';
import wendysLogo from 'assets/wendys.png';

const partnerships = [
  {
    id: 'burgerking',
    name: 'Burger King',
    primaryColor: '#ec1c24',
    secondaryColor: '#0066B2',
    coords: burgerkingCoordinates,
    logo: burgerkingLogo,
  },
  {
    id: 'chickfila',
    name: 'Chick-fil-A',
    primaryColor: '#E51636',
    secondaryColor: '#ffffff',
    coords: chickfilaCoordinates,
    logo: chickfilaLogo,
  },
  {
    id: 'chipotle',
    name: 'Chipotle',
    primaryColor: '#441500',
    secondaryColor: '#AC2318',
    coords: chipotleCoordinates,
    logo: chipotleLogo,
  },
  {
    id: 'dunkindonuts',
    name: "Dunkin' Donuts",
    primaryColor: '#C63663',
    secondaryColor: '#EF6A00',
    coords: dunkindonutsCoordinates,
    logo: dunkindonutsLogo,
  },
  {
    id: 'mcdonalds',
    name: "McDonald's",
    primaryColor: '#bf0c0c',
    secondaryColor: '#ffc72c',
    coords: mcdonaldsCoordinates,
    logo: mcdonaldsLogo,
  },
  {
    id: 'raisingcanes',
    name: "Raising Cane's",
    primaryColor: '#FFF104',
    secondaryColor: '#D11920',
    coords: raisingcanesCoordinates,
    logo: raisingcanesLogo,
  },
  {
    id: 'sonicdrivein',
    name: 'Sonic Drive-In',
    primaryColor: '#1268ae',
    secondaryColor: '#fff554',
    coords: sonicdriveinCoordinates,
    logo: sonicdriveinLogo,
  },
  {
    id: 'starbucks',
    name: 'Starbucks',
    primaryColor: '#006241',
    secondaryColor: '#ffffff',
    coords: starbucksCoordinates,
    logo: starbucksLogo,
  },
  {
    id: 'subway',
    name: 'Subway',
    primaryColor: '#028940',
    secondaryColor: '#FFC20D',
    coords: subwayCoordinates,
    logo: subwayLogo,
  },
  {
    id: 'tacobell',
    name: 'Taco Bell',
    primaryColor: '#5C2D73',
    secondaryColor: '#ef1897',
    coords: tacobellCoordinates,
    logo: tacobellLogo,
  },
  {
    id: 'wendys',
    name: "Wendy's",
    primaryColor: '#2390C1',
    secondaryColor: '#BB252D',
    coords: wendysCoordinates,
    logo: wendysLogo,
  },
];

const statuses = [...defaultStatuses, { id: 'unlaunched', name: 'Unlaunched' }];

export const dataLayers = {
  live: {
    id: 'live',
    type: 'circle',
    source: 'points',
    paint: {
      'circle-color': '#07c493',
      'circle-radius': 5,
    },
  },
  waitlist: {
    id: 'waitlist',
    type: 'circle',
    source: 'points',
    paint: {
      'circle-color': '#ffba3b',
      'circle-radius': 5,
    },
  },
  suspended: {
    id: 'suspended',
    type: 'circle',
    source: 'points',
    paint: {
      'circle-color': '#EA3F58',
      'circle-radius': 5,
    },
  },
  unlaunched: {
    id: 'unlaunched',
    type: 'circle',
    source: 'points',
    paint: {
      'circle-color': '#ff9031',
      'circle-radius': 3,
    },
  },
};

class SchoolsMapContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.mapRef = null;

    this.defaultViewport = {
      latitude: 37.314417,
      longitude: -98.783625,
      zoom: 3.8,
      minZoom: 3.8,
      maxZoom: 14,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator({ speed: 10 }),
    };

    this.state = {
      schools: null,
      school: null,
      filter: '',
      createModalVisible: false,
      uploadModalVisible: false,
      viewport: this.defaultViewport,
      filter_by: null,
      showStats: true,
      isPartnershipsModalShowing: false,
      visiblePartnerships: [],
    };
  }

  componentDidMount() {
    const { geoJSONLoaded, dispatch } = this.props;

    if (!geoJSONLoaded) {
      dispatch(miscActions.getSchoolsGeoJSON());
    }

    this.defaultViewport = {
      latitude: 38.526524,
      longitude: -96.730444,
      zoom: 3.7,
      minZoom: 3.7,
      maxZoom: 14,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator({ speed: 10 }),
    };

    this.setState({ viewport: this.defaultViewport });
  }

  renderToggles = () => {
    return (
      <div className="filter-controls">
        <button
          className={`filter-button all toggle-${
            this.state.filter === '' ? 'on' : 'off'
          }`}
          onClick={() => this.setState({ filter: '' })}
        >
          All
        </button>

        {statuses.map((status) => (
          <button
            className={`filter-button ${status.id} toggle-${
              this.state.filter.includes(status.id) ? 'on' : 'off'
            }`}
            onClick={() =>
              this.setState((state) => {
                const toggled = [...state.filter];
                const filter = toggled.includes(status.id)
                  ? toggled.filter((x) => x !== status.id)
                  : [...toggled, status.id];
                return { ...state, filter };
              })
            }
            key={status.id}
          >
            {status.name}
          </button>
        ))}

        <button
          className="filter-button"
          style={{
            background:
              'linear-gradient(102.72deg, #157be7 -6.12%, #6515e7 76.82%)',
          }}
          onClick={() => this.setState({ isPartnershipsModalShowing: true })}
        >
          Partnerships
        </button>
      </div>
    );
  };

  onViewportChange = (viewport) => {
    this.setState({ viewport });
  };

  goToSchool = (school) => {
    if (school) {
      const viewport = {
        ...this.state.viewport,
        latitude: school.lat,
        longitude: school.lng,
        zoom: 10,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator({ speed: 10 }),
      };
      this.setState({ viewport }, () => {
        this.setState({ school });
      });
    }
  };

  centerMap = () => {
    this.setState({ viewport: this.defaultViewport, school: null });
  };

  onClick = (event) => {
    const { features } = event;

    const hoveredFeature =
      features &&
      features.find((f) =>
        ['live', 'waitlist', 'suspended', 'unlaunched'].includes(f.layer.id),
      );

    this.setState({
      school: hoveredFeature ? hoveredFeature.properties : null,
    });

    if (event.eventType === 4) {
      this.goToSchool(hoveredFeature ? hoveredFeature.properties : null);
    }
  };

  renderTooltip() {
    const { school } = this.state;

    return (
      school && (
        <Popup
          tipSize={8}
          anchor="bottom"
          longitude={school.lng}
          latitude={school.lat}
          closeOnClick={false}
          onClose={() => {
            this.setState({ school: null });
            this.centerMap();
          }}
        >
          <div>
            <div>
              <h4 style={{ display: 'flex' }}>
                <a
                  href={`/schools/${school.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: '10px', color: '#12266D' }}
                >
                  {school.name}
                </a>
                <Chip
                  label={school.status}
                  className={`status-cell ${school.status}`}
                />
              </h4>
            </div>
            <div>
              <p>
                <b>ID:</b> {school.id}
              </p>
              {school.private ? 'Private' : 'Public'} school •&nbsp;
              {school.students_count} students
            </div>
          </div>
        </Popup>
      )
    );
  }

  applyFilter = () => {
    const { dispatch } = this.props;
    const { filter_by } = this.state;
    dispatch(miscActions.getSchoolsGeoJSON({ filter_by }));
  };

  render() {
    const mapProps = {
      mapboxApiAccessToken: process.env.REACT_APP_MAPBOX,
      mapStyle: 'mapbox://styles/jordanhero/cjxq5hg2w510v1clr08smdhys',
      ...this.state.viewport,
    };

    const { isLoadingGeoJSON, geoJSONData } = this.props;

    return (
      <div className="sdp-container map-page">
        <Row className="home-map-row full-size">
          {isLoadingGeoJSON && (
            <div className="loading-screen">
              <Spinner animation="grow" role="status" className="map-ripple" />
            </div>
          )}

          <ReactMapGL
            {...mapProps}
            onViewportChange={this.onViewportChange}
            dragRotate={false}
            doubleClickZoom={false}
            width="100%"
            height="100%"
            onClick={this.onClick}
            getCursor={({ isDragging }) =>
              isDragging ? 'crosshair' : 'pointer'
            }
            ref={(map) => (this.mapRef = map)}
          >
            {geoJSONData &&
              Object.keys(geoJSONData).map((status) => {
                return (
                  (this.state.filter === '' ||
                    this.state.filter.includes(status)) && (
                    <Source
                      key={status}
                      type="geojson"
                      data={geoJSONData[status]}
                    >
                      <Layer {...dataLayers[status]} />
                    </Source>
                  )
                );
              })}

            {this.state.visiblePartnerships
              .map((p) => partnerships.find((partner) => partner.id === p))
              .map((partner) => (
                <Source
                  key={partner.id}
                  type="geojson"
                  data={{
                    type: 'FeatureCollection',
                    features: partner.coords.map(({ x, y }) => ({
                      type: 'Feature',
                      geometry: { type: 'Point', coordinates: [y, x] },
                    })),
                  }}
                >
                  <Layer
                    id={partner.id}
                    type="circle"
                    source="points"
                    paint={{
                      'circle-color': partner.primaryColor,
                      'circle-radius': 3,
                      'circle-stroke-color': partner.secondaryColor,
                      'circle-stroke-width': 1,
                    }}
                  />
                </Source>
              ))}

            {this.state.visiblePartnerships.length && (
              <Card
                style={{
                  width: 'fit-content',
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                  padding: 16,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                {this.state.visiblePartnerships
                  .map((p) => partnerships.find((partner) => partner.id === p))
                  .map((partner) => (
                    <div
                      style={{
                        display: 'flex',
                        gap: 16,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <img
                        src={partner.logo}
                        width={100}
                        height={50}
                        style={{ objectFit: 'contain' }}
                      />

                      <div
                        style={{
                          width: 25,
                          height: 25,
                          backgroundColor: partner.primaryColor,
                          borderRadius: '50%',
                          border: `2px solid ${partner.secondaryColor}`,
                        }}
                      />
                    </div>
                  ))}
              </Card>
            )}

            {this.renderTooltip()}

            <Modal
              show={this.state.isPartnershipsModalShowing}
              onHide={() =>
                this.setState({ isPartnershipsModalShowing: false })
              }
              style={{ marginTop: '150px' }}
            >
              {partnerships.map((partner) => (
                <button
                  key={partner.id}
                  style={{
                    display: 'block',
                    border: 'none',
                    width: '100%',
                    outline: 'none',
                    backgroundColor: this.state.visiblePartnerships.includes(
                      partner.id,
                    )
                      ? '#157be722'
                      : 'transparent',
                  }}
                  onClick={() => {
                    if (this.state.visiblePartnerships.includes(partner.id)) {
                      this.setState({
                        visiblePartnerships:
                          this.state.visiblePartnerships.filter(
                            (id) => id !== partner.id,
                          ),
                      });
                    } else {
                      this.setState({
                        visiblePartnerships: [
                          ...this.state.visiblePartnerships,
                          partner.id,
                        ].sort(),
                      });
                    }
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    <div>{partner.name}</div>

                    {this.state.visiblePartnerships.includes(partner.id)
                      ? '✅'
                      : '❌'}
                  </div>
                </button>
              ))}
            </Modal>
          </ReactMapGL>

          <MapStats stats={this.props.stats} />

          {geoJSONData && (
            <Row
              className="map-search-bar"
              onClick={(e) => {
                e.persist();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              <MapSearchBar
                onChange={this.goToSchool}
                onClear={this.centerMap}
                schools={geoJSONData}
              />
              {this.renderToggles()}
            </Row>
          )}
        </Row>
        <MapFilters
          onFilterUpdate={(filter) => {
            this.setState({ filter_by: filter }, () => {
              this.applyFilter();
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  geoJSONData: state.misc.geoJSONData,
  stats: state.misc.stats,
  isLoadingGeoJSON: state.misc.isLoadingGeoJSON,
  geoJSONLoaded: state.misc.geoJSONLoaded,
});

export default connect(mapStateToProps)(SchoolsMapContainer);
