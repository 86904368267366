import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import './ColourPickerField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldError from '../FieldError/FieldError';
import Select from 'react-select';
import { faStop } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'constants/colors';

export default class ColorPickerField extends Component {
  updateValue = ({ value }) => {
    this.props.field.currentValue = value;
    this.props.field.valid = true;
    this.props.updateParent(value, this.props.field);
  };

  render() {
    return (
      <Form.Group>
        {this.props.field.isShowLabel && (
          <Form.Label>{this.props.field.name}</Form.Label>
        )}
        <Select
          className="color-picker"
          classPrefix="color-picker"
          options={Object.keys(colors).map((color) => ({
            value: color,
            label: colors[color],
          }))}
          isSearchable={false}
          value={
            this.props.field.currentValue
              ? {
                  value: this.props.field.currentValue,
                  label:
                    colors[this.props.field.currentValue] || 'Custom color',
                }
              : null
          }
          placeholder="Select a color..."
          formatOptionLabel={({ value, label }) => {
            return (
              <div>
                <FontAwesomeIcon icon={faStop} color={value} />
                &nbsp;&nbsp;{label}
              </div>
            );
          }}
          onChange={this.updateValue}
        />
        <FieldError field={this.props.field} />
      </Form.Group>
    );
  }
}
