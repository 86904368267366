import React, { PureComponent, useState } from 'react';
import { schoolsActions } from 'store/actions/actions';
import './SchoolFeatures.scss';
import { Spinner } from 'react-bootstrap';
import { renderConfirm } from 'components/modals/ConfirmationModal/GenericConfirmModal';
import Switch from 'react-switch';

function SwitchWithConfirm({ toggled, label, onConfirm }) {
  const [checked, setChecked] = useState(toggled);
  return (
    <div className="switch-field">
      <div className="switch-field-label">
        <b>{label}</b>
      </div>
      <Switch
        checked={checked}
        onChange={(value) => {
          renderConfirm({
            title: 'Are you sure?',
            body: (
              <span>
                Toggling features is very high stakes. Are you sure you&apos;d
                like to turn &nbsp;{value ? 'on' : 'off'} <b>{label}</b>?
              </span>
            ),
            onConfirm: () => {
              onConfirm(value);
              setChecked(value);
            },
            confirmText: 'Yes, proceed.',
            cancelText: 'Cancel',
          });
        }}
        onColor="#ea3f58"
        onHandleColor="#ea3f58"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
      />
    </div>
  );
}

export default class SchoolFeatures extends PureComponent {
  constructor(props) {
    super(props);
    this.switchFeature = this.switchFeature.bind(this);
  }

  switchFeature(feature, currentValue, newValue) {
    const { currentSchool, dispatch } = this.props;
    if (currentValue !== newValue) {
      dispatch(
        schoolsActions.switchFeature(
          currentSchool.school_name,
          feature,
          newValue,
        ),
      );
    }
  }

  render() {
    const { currentSchool } = this.props;

    return (
      <div className="school-schedule-list">
        <div className="courses-teachers-users-table">
          {currentSchool ? (
            Object.keys(currentSchool.meta.features)
              .filter(
                (feature) =>
                  ![
                    'assume_everyone_is_email_verified',
                    'verify_users_automatically',
                  ].includes(feature),
              )
              .map((feature) => {
                const currentValue = currentSchool.meta.features[feature];
                return (
                  <SwitchWithConfirm
                    key={feature}
                    toggled={currentValue}
                    label={feature.replace(/_/g, ' ')}
                    onConfirm={(enabled) =>
                      this.switchFeature(feature, currentValue, enabled)
                    }
                  />
                );
              })
          ) : (
            <div className="loading-ripple">
              <Spinner animation="grow" role="status" className="ripple" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
