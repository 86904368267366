import { useMemo } from 'react';
import { useSchool } from 'hooks/useSchool';
import { CUSTOM_DAY_TYPE, PeriodsBuilderProps } from './PeriodsBuilder';
import { scheduleUtils } from 'features/schedules/utils/schedule';

export function useFilteredDayTypes({
  customPeriodNames,
  dayTypes,
  schedule,
}: Pick<PeriodsBuilderProps, 'dayTypes' | 'schedule'> & {
  customPeriodNames: string[];
}) {
  const { data: schedules } = useSchool((school) => school.schedules);

  return useMemo(() => {
    const customDayType = customPeriodNames.length ? [CUSTOM_DAY_TYPE] : [];

    if (scheduleUtils.isAcfSchedule(schedule)) {
      return [schedule.dayType, ...customDayType];
    }

    if (!schedules) return [];

    const dayTypesForSchedule = schedules
      .filter(scheduleUtils.isAcfSchedule)
      .map((s) => s.dayType);

    const filteredDayTypes = dayTypes.filter((dt) =>
      dayTypesForSchedule.some((dts) => dts.id === dt.id),
    );

    return [...filteredDayTypes, ...customDayType];
  }, [customPeriodNames, schedules, schedule, dayTypes]);
}
