import styled from '@emotion/styled';
import * as M from '@mantine/core';
import * as MD from '@mantine/dates';
import { Controller, useForm } from 'react-hook-form';
import { TextInput, Select } from 'react-hook-form-mantine';
import dayjs from 'dayjs';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import type { UserViewDashboard } from 'types/openApi/UserViewDashboard';
import { VALID_PHONE_NUMBER } from 'utils/hasTextValidator';
import { range } from 'utils/range';

const editUserModalSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z
    .string()
    .refine((val) => VALID_PHONE_NUMBER(val), 'invalid phone number'),
  birthday: z.date(),
  graduationYear: z.string(),
});

export const EditUserModal = ({
  isOpen,
  onClose,
  rowData,
  mutate,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  rowData?: UserViewDashboard;
  mutate: (data: z.infer<typeof editUserModalSchema>) => void;
  isLoading: boolean;
}) => {
  const { control, handleSubmit } = useForm<
    z.infer<typeof editUserModalSchema>
  >({
    resolver: zodResolver(editUserModalSchema),
    values: {
      firstName: rowData?.firstName ?? '',
      lastName: rowData?.lastName ?? '',
      phoneNumber: rowData?.phoneNumber ?? '',
      birthday: rowData?.birthday
        ? new Date(rowData.birthday)
        : dayjs(new Date()).subtract(15, 'year').toDate(),
      graduationYear:
        rowData?.graduationYear?.toString() ??
        String(new Date().getFullYear() + 1),
    },
  });

  return (
    <M.Modal opened={isOpen} onClose={onClose} withCloseButton={false}>
      <M.Stack>
        <M.Title order={4}>Edit User</M.Title>

        <Disabled label="Email" value={rowData?.email ?? ''} disabled />

        <TextInput
          control={control}
          name="firstName"
          label="First name"
          required
        />
        <TextInput
          control={control}
          name="lastName"
          label="Last name"
          required
        />
        <Disabled label="School ID" value={rowData?.schoolId ?? ''} disabled />
        <TextInput
          control={control}
          name="phoneNumber"
          label="Phone number"
          required
        />
        <Controller
          name="birthday"
          control={control}
          render={({ field }) => {
            return (
              <MD.DateInput
                label="Birthday"
                value={field.value}
                onChange={(d) => (d ? field.onChange(d) : undefined)}
                required
              />
            );
          }}
        />
        <Select
          control={control}
          name="graduationYear"
          label="Class of"
          data={createClassYearsRange()}
          required
        />

        <M.Flex justify="flex-end" mt="xl" gap="sm">
          <M.Button onClick={onClose} variant="outline">
            Cancel
          </M.Button>
          <M.Button
            onClick={handleSubmit((data) => mutate(data))}
            type="submit"
            loading={isLoading}
          >
            Save
          </M.Button>
        </M.Flex>
      </M.Stack>
    </M.Modal>
  );
};

const Disabled = styled(M.TextInput)`
  .mantine-TextInput-input {
    color: ${(p) => p.theme.colors.dark[9]};
  }
`;

function createClassYearsRange(size = 5) {
  const currentYear = new Date().getFullYear();
  return [...range(currentYear, currentYear + size)].map((x) => String(x));
}
