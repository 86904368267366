import { useState } from 'react';
import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import * as M from '@mantine/core';
import * as MM from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';

import { Table } from 'features/components/Table';
import type { StaffView } from 'types/openApi/StaffView';

import { useTeachers, useCreateTeacher, useDeleteTeacher } from './queries';
import { CreateTeacherModal } from './components/CreateTeacherModal';

const columns: MRT_ColumnDef<StaffView>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 50,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    size: 300,
  },
];

const TeachersPage = () => {
  const { data, isFetching, isInitialLoading } = useTeachers();
  const createMutation = useCreateTeacher();
  const deleteTeacherMutation = useDeleteTeacher();

  const [isCreating, setIsCreating] = useState(false);

  const openDeleteConfirmModal = (row: MRT_Row<StaffView>) =>
    MM.modals.openConfirmModal({
      title: <M.Text fw="bold">Delete {row.original.name}?</M.Text>,
      children: <M.Text>This action cannot be undone.</M.Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteTeacherMutation.mutate(row.original.id),
    });

  return (
    <>
      <Table
        columns={columns}
        data={data}
        tableOptions={{
          state: { showProgressBars: isFetching && !isInitialLoading },
          renderTopToolbarCustomActions: () => (
            <M.Button onClick={() => setIsCreating(true)}>Add Teacher</M.Button>
          ),
          enableRowActions: true,
          positionActionsColumn: 'last',
          renderRowActions: ({ row }) => (
            <M.ActionIcon onClick={() => openDeleteConfirmModal(row)}>
              <IconTrash />
            </M.ActionIcon>
          ),
        }}
      />
      <CreateTeacherModal
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
        isLoading={createMutation.isLoading}
        mutate={async (data) => {
          await createMutation.mutateAsync({
            name: `${data.firstName} ${data.lastName}`,
          });
        }}
      />
    </>
  );
};

export default TeachersPage;
