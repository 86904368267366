export default [{x:35.444966,y:-80.866655},
{x:29.046733,y:-95.457324},
{x:42.628142,y:-82.990313},
{x:38.95376152,y:-94.667092},
{x:33.82501324,y:-84.10540777},
{x:39.831162,y:-75.107233},
{x:38.862648,y:-77.058922},
{x:29.59763,y:-95.618369},
{x:34.119395,y:-83.826254},
{x:39.955361,y:-104.994194},
{x:30.23302,y:-97.8248},
{x:41.65947,y:-72.7227},
{x:34.690001,y:-82.994728},
{x:30.469257,y:-84.28819},
{x:30.54913,y:-87.218555},
{x:35.74608,y:-78.87409},
{x:29.93645,y:-93.93026},
{x:35.307193,y:-82.454955},
{x:37.050972,y:-94.477974},
{x:40.741187,y:-96.681745},
{x:30.1305824,y:-95.2364367},
{x:34.755295,y:-82.267486},
{x:34.116267,y:-117.830405},
{x:34.793967,y:-92.401161},
{x:36.03872,y:-79.83759},
{x:33.79544,y:-79.00984849},
{x:40.235374,y:-74.947203},
{x:43.191694,y:-88.139981},
{x:30.38961,y:-91.08802},
{x:35.845898,y:-86.42731},
{x:29.7550452,y:-95.3621103},
{x:35.858817,y:-84.074776},
{x:33.865105,y:-118.352205},
{x:40.308973,y:-76.802932},
{x:33.845231,y:-118.264961},
{x:35.21891,y:-97.47837},
{x:39.814264,y:-94.81442},
{x:42.460466,y:-92.330149},
{x:40.91646,y:-74.076315},
{x:41.008093,y:-76.447279},
{x:41.8539328,y:-88.34125114},
{x:28.596083,y:-81.198824},
{x:39.56318,y:-104.875946},
{x:30.439959,y:-86.638151},
{x:33.461347,y:-86.730778},
{x:42.27087071,y:-88.97928443},
{x:37.924176,y:-122.062849},
{x:39.95686,y:-104.77698},
{x:39.1532,y:-76.72712},
{x:33.008497,y:-96.710828},
{x:38.29888,y:-76.51234},
{x:36.269831,y:-80.360799},
{x:36.644851,y:-93.224365},
{x:32.284664,y:-95.305353},
{x:34.296757,y:-83.855737},
{x:31.33442808,y:-94.75979858},
{x:36.10551,y:-86.79826882},
{x:37.364675,y:-79.179862},
{x:29.913426,y:-95.484421},
{x:40.999394,y:-75.260047},
{x:32.556703,y:-94.349321},
{x:33.22434938,y:-96.79677514},
{x:34.263427,y:-85.18825},
{x:29.590663,y:-95.62559},
{x:36.007043,y:-84.255105},
{x:37.60854,y:-77.35625},
{x:35.3267,y:-80.94337},
{x:32.42491,y:-81.77994235},
{x:35.0672,y:-78.9664},
{x:38.86865,y:-77.14409},
{x:37.2284,y:-80.4234},
{x:45.656301,y:-122.587341},
{x:32.70875,y:-97.02389},
{x:32.28481,y:-106.75443},
{x:40.144066,y:-88.258437},
{x:35.11808,y:-89.93700937},
{x:33.773712,y:-84.398292},
{x:30.20586791,y:-97.97725233},
{x:36.04095,y:-79.844369},
{x:34.99118,y:-85.20118},
{x:29.597229,y:-98.350456},
{x:34.037455,y:-118.377074},
{x:29.921213,y:-90.012898},
{x:32.31194,y:-106.75572},
{x:35.84277,y:-90.67864839},
{x:41.938796,y:-88.111136},
{x:30.107978,y:-81.824511},
{x:28.81647,y:-96.97929},
{x:34.6754,y:-118.154979},
{x:35.168327,y:-90.187363},
{x:30.44880241,y:-84.31053272},
{x:41.907579,y:-87.849036},
{x:40.537833,y:-105.077804},
{x:33.51165,y:-81.71394},
{x:41.078794,y:-111.977632},
{x:33.871277,y:-98.537235},
{x:35.612897,y:-97.55862},
{x:29.891323,y:-97.915236},
{x:29.914595,y:-95.410523},
{x:30.197341,y:-85.813172},
{x:34.66654,y:-79.00632},
{x:33.988302,y:-84.087923},
{x:32.915831,y:-80.111606},
{x:34.199,y:-82.169},
{x:39.40983,y:-76.950475},
{x:35.78778,y:-78.67077173},
{x:39.661807,y:-84.108409},
{x:30.171722,y:-92.074973},
{x:29.91632,y:-81.40428},
{x:35.184718,y:-101.929562},
{x:44.006277,y:-92.46472},
{x:42.054124,y:-87.839827},
{x:33.571693,y:-85.098035},
{x:30.318064,y:-81.55532},
{x:31.103082,y:-97.357254},
{x:36.13318,y:-86.7952498},
{x:40.75159,y:-73.991427},
{x:33.8797693,y:-84.4678518},
{x:33.789768,y:-78.768735},
{x:41.5624,y:-93.79801},
{x:33.84443,y:-84.370813},
{x:33.648549,y:-78.976523},
{x:30.475659,y:-87.19340306},
{x:40.650546,y:-111.939467},
{x:40.063175,y:-79.881678},
{x:29.7803,y:-94.97761},
{x:34.530605,y:-82.634435},
{x:21.336227,y:-158.052456},
{x:29.5077,y:-95.186556},
{x:33.105468,y:-80.126909},
{x:37.403562,y:-77.668561},
{x:32.807321,y:-96.624},
{x:33.394923,y:-84.760584},
{x:38.97337,y:-94.607792},
{x:38.90452,y:-76.84816},
{x:34.70672,y:-86.58821},
{x:30.46934,y:-97.7912},
{x:34.232155,y:-118.554034},
{x:35.284855,y:-80.670971},
{x:32.69634,y:-114.59894},
{x:29.58457,y:-98.61763694},
{x:28.081331,y:-80.650116},
{x:33.0214,y:-117.1262},
{x:41.627748,y:-86.251863},
{x:40.710477,y:-74.008515},
{x:39.94002,y:-77.62922},
{x:39.276871,y:-76.563401},
{x:30.359097,y:-91.006488},
{x:33.88761263,y:-84.00990754},
{x:32.89436,y:-96.47046},
{x:39.013138,y:-84.639432},
{x:38.61632,y:-121.26919},
{x:37.23774,y:-77.41934892},
{x:26.160875,y:-80.115945},
{x:38.094081,y:-122.557485},
{x:32.7577,y:-97.09675},
{x:33.042646,y:-97.024708},
{x:29.576016,y:-98.322144},
{x:35.94414,y:-86.82076},
{x:28.63617,y:-81.40387},
{x:39.362903,y:-77.163896},
{x:39.76360357,y:-86.38836343},
{x:34.03104,y:-81.08893},
{x:39.270985,y:-84.375174},
{x:32.926055,y:-83.735362},
{x:29.35622,y:-98.53957},
{x:27.981407,y:-81.95767},
{x:35.453674,y:-82.52324},
{x:39.979199,y:-76.75332},
{x:37.073798,y:-88.698176},
{x:38.933007,y:-95.260784},
{x:39.08091,y:-77.49786},
{x:45.54018,y:-122.867109},
{x:32.186127,y:-90.26128},
{x:32.67006,y:-97.13494},
{x:35.24899,y:-91.72618981},
{x:35.60533,y:-77.36167444},
{x:43.149596,y:-86.20664},
{x:29.71352,y:-95.31437},
{x:33.941,y:-84.52},
{x:41.698349,y:-86.235475},
{x:41.117609,y:-85.1419},
{x:40.618328,y:-74.251794},
{x:34.75065458,y:-86.76156509},
{x:36.30616,y:-95.62087},
{x:35.31854,y:-97.49499},
{x:38.897703,y:-77.006643},
{x:39.63214,y:-86.120085},
{x:33.857919,y:-84.599946},
{x:29.52122,y:-98.49307},
{x:29.64837,y:-82.34516887},
{x:33.773571,y:-84.370479},
{x:35.160231,y:-79.420565},
{x:41.849959,y:-87.804257},
{x:32.22208,y:-110.91584},
{x:35.695945,y:-79.789837},
{x:37.163,y:-93.2672},
{x:40.70097804,y:-112.0255628},
{x:40.859556,y:-74.061311},
{x:35.419773,y:-80.675218},
{x:30.28611,y:-97.7412689},
{x:33.508887,y:-112.047219},
{x:39.00705,y:-77.43803},
{x:33.5917,y:-101.9357},
{x:43.81832,y:-111.7834775},
{x:32.648141,y:-96.858267},
{x:44.859233,y:-93.5322},
{x:37.977201,y:-87.628565},
{x:40.03143241,y:-85.99441921},
{x:40.14565,y:-82.98174},
{x:34.300971,y:-77.789196},
{x:33.42431,y:-84.18341},
{x:35.13132,y:-106.58753},
{x:41.14009,y:-77.46108974},
{x:28.51895,y:-81.728716},
{x:33.5434973,y:-84.57633894},
{x:31.72779,y:-106.29998},
{x:32.662737,y:-83.739025},
{x:41.08878192,y:-111.9784564},
{x:30.449484,y:-84.30857},
{x:29.99567829,y:-95.48316053},
{x:39.080553,y:-108.553191},
{x:37.254302,y:-77.393599},
{x:29.628099,y:-95.232458},
{x:34.911333,y:-82.103753},
{x:26.64644,y:-80.2085},
{x:34.754555,y:-92.412112},
{x:35.37908037,y:-96.92919557},
{x:38.884563,y:-94.673145},
{x:37.47903,y:-122.22312},
{x:29.543922,y:-95.756609},
{x:33.55306,y:-84.271865},
{x:38.322003,y:-81.715655},
{x:40.51214,y:-75.78666739},
{x:27.674264,y:-97.296727},
{x:37.6048102,y:-77.56411501},
{x:30.10229,y:-95.44647},
{x:45.057896,y:-93.147945},
{x:34.220807,y:-119.157539},
{x:38.36433018,y:-81.7068913},
{x:29.426256,y:-98.493485},
{x:34.71112023,y:-82.25606109},
{x:35.898225,y:-78.651376},
{x:39.8832,y:-105.02444},
{x:38.13248,y:-79.04192},
{x:33.56517,y:-101.94475},
{x:43.60537409,y:-116.28275096},
{x:32.54084,y:-94.73023},
{x:41.635102,y:-93.698253},
{x:32.24862,y:-110.84982},
{x:34.018187,y:-84.564649},
{x:39.0972,y:-84.50693},
{x:39.647772,y:-78.756858},
{x:33.7162976,y:-118.00706997},
{x:34.689115,y:-79.119668},
{x:38.95162974,y:-94.72303213},
{x:30.419423,y:-95.492253},
{x:44.924917,y:-92.966916},
{x:33.872598,y:-84.380757},
{x:36.301786,y:-82.366947},
{x:37.6773,y:-97.426941},
{x:32.67822053,y:-97.46571628},
{x:31.276659,y:-92.460184},
{x:28.380481,y:-81.243768},
{x:28.22846005,y:-81.64843965},
{x:39.443977,y:-77.987615},
{x:40.668809,y:-89.583164},
{x:32.916116,y:-96.519292},
{x:33.649639,y:-117.83987},
{x:33.58153,y:-112.12584},
{x:41.578034,y:-74.085637},
{x:33.950216,y:-80.373222},
{x:28.64574113,y:-81.26729934},
{x:33.944958,y:-83.409363},
{x:35.255755,y:-81.02746},
{x:31.615004,y:-84.217239},
{x:32.875577,y:-80.019472},
{x:40.768721,y:-111.888704},
{x:34.213133,y:-82.173998},
{x:38.13731,y:-122.21376},
{x:44.7768,y:-91.437111},
{x:40.224593,y:-76.93489},
{x:35.058135,y:-106.630361},
{x:41.419685,y:-81.901092},
{x:37.798967,y:-79.417355},
{x:27.33727,y:-82.46099},
{x:44.69714718,y:-73.48725779},
{x:31.198536,y:-82.325792},
{x:34.98522,y:-80.4455},
{x:39.077361,y:-76.867842},
{x:38.42343,y:-121.393055},
{x:33.19144302,y:-87.52680205},
{x:33.660032,y:-84.415766},
{x:41.754008,y:-87.740985},
{x:29.690904,y:-95.771348},
{x:34.209992,y:-82.182156},
{x:40.644765,y:-75.493252},
{x:37.534669,y:-122.000264},
{x:33.581672,y:-101.87456},
{x:25.769532,y:-80.347845},
{x:38.851822,y:-77.042366},
{x:25.68977,y:-80.31353},
{x:29.55576,y:-95.15380668},
{x:39.71491,y:-86.29764303},
{x:33.916664,y:-118.138422},
{x:33.782091,y:-84.60972},
{x:32.14,y:-81.197},
{x:27.55179,y:-99.50038},
{x:33.560839,y:-117.629728},
{x:39.642599,y:-105.007629},
{x:33.553898,y:-84.369674},
{x:29.57123509,y:-98.23921976},
{x:33.458406,y:-94.089458},
{x:41.3918,y:-81.438748},
{x:33.695025,y:-117.826433},
{x:39.21952,y:-78.134309},
{x:37.9614,y:-87.676115},
{x:28.054934,y:-82.395051},
{x:28.820095,y:-81.669855},
{x:33.230367,y:-97.163315},
{x:36.130355,y:-96.116823},
{x:39.13780021,y:-77.69303342},
{x:29.756,y:-95.6268},
{x:30.71797959,y:-85.18643565},
{x:37.36244,y:-81.05699},
{x:34.171318,y:-118.571407},
{x:35.901852,y:-84.151445},
{x:39.748315,y:-83.811841},
{x:39.7663686,y:-105.1422222},
{x:29.4355,y:-98.7022},
{x:33.02592,y:-96.796134},
{x:39.080042,y:-108.584283},
{x:38.088576,y:-78.470803},
{x:33.944348,y:-117.994247},
{x:33.048354,y:-117.26017},
{x:32.336362,y:-96.616154},
{x:34.15282,y:-84.17473},
{x:27.68263,y:-97.37869},
{x:38.362888,y:-122.713863},
{x:30.675569,y:-88.174089},
{x:32.84592,y:-96.85034857},
{x:40.063,y:-77.522},
{x:28.024951,y:-82.148094},
{x:35.144187,y:-80.929394},
{x:42.259856,y:-71.168843},
{x:39.15369,y:-77.06659},
{x:41.16383,y:-87.850302},
{x:39.76454221,y:-89.70226496},
{x:40.508354,y:-112.00164},
{x:35.670363,y:-88.854097},
{x:26.010854,y:-80.304784},
{x:41.118755,y:-81.479841},
{x:42.72144,y:-84.425793},
{x:32.55949411,y:-93.71021116},
{x:35.103883,y:-80.984753},
{x:41.576106,y:-88.156031},
{x:28.12713203,y:-82.36813331},
{x:34.939558,y:-81.996083},
{x:40.11292,y:-85.66452674},
{x:35.2057255,y:-111.6153034},
{x:40.187864,y:-92.582613},
{x:30.41301,y:-91.17686683},
{x:32.771105,y:-117.154442},
{x:30.08994,y:-95.63977},
{x:29.969315,y:-95.693013},
{x:41.71742,y:-87.78037},
{x:33.873078,y:-118.379427},
{x:36.132392,y:-79.792353},
{x:40.427,y:-86.916},
{x:35.18843,y:-111.6558154},
{x:32.92611,y:-85.961856},
{x:39.20221,y:-84.3772},
{x:30.673726,y:-88.121306},
{x:38.24923,y:-85.7475},
{x:38.454149,y:-122.717122},
{x:39.28697,y:-76.60966},
{x:30.68988186,y:-88.12087271},
{x:45.498688,y:-122.910993},
{x:25.779124,y:-80.195568},
{x:38.914386,y:-77.426416},
{x:32.820014,y:-117.176966},
{x:38.484794,y:-77.968739},
{x:39.4488305,y:-77.38741747},
{x:38.659106,y:-75.594878},
{x:42.745621,y:-73.809402},
{x:39.82536,y:-76.9843},
{x:37.50509,y:-121.97356},
{x:34.07331,y:-81.156278},
{x:28.756629,y:-81.355313},
{x:33.410288,y:-82.026286},
{x:33.541874,y:-83.208098},
{x:29.55867,y:-95.26115},
{x:30.6825709,y:-86.12341207},
{x:47.820841,y:-122.314725},
{x:38.5200181,y:-89.92452169},
{x:42.785,y:-71.507665},
{x:34.213243,y:-77.905193},
{x:39.81378832,y:-86.20239348},
{x:36.056262,y:-94.17989},
{x:33.13033826,y:-97.0421839},
{x:33.4549,y:-88.79020928},
{x:33.440536,y:-84.590795},
{x:32.29784,y:-90.20608552},
{x:34.026682,y:-117.117066},
{x:29.490559,y:-98.439356},
{x:29.72420367,y:-95.46760359},
{x:31.80682,y:-106.26146},
{x:32.553896,y:-83.660591},
{x:25.919979,y:-80.293639},
{x:36.13267,y:-80.27757085},
{x:29.610375,y:-98.469903},
{x:37.226768,y:-79.969023},
{x:36.05225,y:-95.95162629},
{x:30.42649,y:-84.28648473},
{x:37.71307,y:-89.2185442},
{x:39.13179,y:-84.51731632},
{x:46.891088,y:-114.038635},
{x:33.10192,y:-96.68299},
{x:39.210987,y:-85.878729},
{x:41.760759,y:-87.943673},
{x:33.09526192,y:-96.11392662},
{x:41.831884,y:-71.424204},
{x:33.810118,y:-84.274226},
{x:40.179009,y:-76.989113},
{x:42.86539,y:-85.681127},
{x:40.10497,y:-75.00229},
{x:40.05252211,y:-74.84153311},
{x:35.44564789,y:-79.12986186},
{x:39.95917,y:-75.19036806},
{x:39.447444,y:-75.732277},
{x:30.83941,y:-83.32056},
{x:40.138305,y:-74.962391},
{x:32.412427,y:-99.77578},
{x:34.217235,y:-77.905906},
{x:36.811732,y:-76.071584},
{x:34.069487,y:-117.926438},
{x:39.412643,y:-76.771193},
{x:30.21324,y:-92.01749606},
{x:40.923166,y:-111.888883},
{x:28.655288,y:-81.207829},
{x:36.0112,y:-95.885785},
{x:33.917696,y:-117.965629},
{x:31.961651,y:-95.268924},
{x:43.60813,y:-116.1928},
{x:33.58856,y:-101.88002},
{x:47.61686187,y:-122.1860803},
{x:37.13683,y:-80.54836982},
{x:41.498909,y:-81.4938995},
{x:27.72147784,y:-82.73953606},
{x:36.852818,y:-119.79048},
{x:32.787558,y:-80.014984},
{x:41.358677,y:-72.872298},
{x:37.3486,y:-79.1854},
{x:33.691093,y:-117.335142},
{x:36.127369,y:-97.050927},
{x:42.77060753,y:-71.22265297},
{x:35.593902,y:-78.767812},
{x:33.58992,y:-91.8145},
{x:34.01261,y:-84.495347},
{x:33.873013,y:-117.744879},
{x:42.718417,y:-87.843475},
{x:36.115142,y:-115.191178},
{x:33.30440543,y:-111.7434217},
{x:30.194983,y:-93.248934},
{x:33.5391328,y:-101.9257282},
{x:35.790482,y:-78.850114},
{x:36.009113,y:-115.117133},
{x:38.581021,y:-92.243662},
{x:35.92061,y:-94.96605938},
{x:26.26024,y:-98.1636},
{x:33.701913,y:-78.922863},
{x:33.883659,y:-118.326218},
{x:33.88086313,y:-84.46098637},
{x:37.023448,y:-76.437398},
{x:34.184483,y:-118.875793},
{x:31.54818384,y:-97.12553805},
{x:42.21398,y:-83.35390457},
{x:37.2706113,y:-76.75904097},
{x:40.74696,y:-73.59153},
{x:34.03686,y:-84.56961},
{x:33.989467,y:-118.446447},
{x:38.93235,y:-76.56819},
{x:34.939068,y:-82.26599},
{x:34.720367,y:-86.584926},
{x:26.008425,y:-80.359497},
{x:35.07046,y:-106.532194},
{x:32.50217,y:-84.94015172},
{x:42.880752,y:-78.697609},
{x:27.93215,y:-82.325181},
{x:32.675659,y:-97.199099},
{x:38.56097,y:-76.06349},
{x:36.8522,y:-76.02315},
{x:33.858425,y:-118.031383},
{x:30.065009,y:-95.43324},
{x:25.7969,y:-80.2762},
{x:36.777267,y:-76.231441},
{x:42.86072215,y:-112.432406},
{x:39.03754,y:-76.67872},
{x:28.23717732,y:-82.34846965},
{x:29.89715,y:-90.062562},
{x:31.86835,y:-102.37905},
{x:34.090015,y:-117.670524},
{x:40.160156,y:-75.147861},
{x:39.8700931,y:-86.12168892},
{x:32.274567,y:-81.228937},
{x:32.89131,y:-97.25867},
{x:35.847461,y:-78.581088},
{x:42.30346176,y:-71.39897979},
{x:32.30822122,y:-95.27161021},
{x:38.87896,y:-77.110438},
{x:41.098811,y:-73.442169},
{x:36.84833,y:-76.2617694},
{x:32.903203,y:-97.190869},
{x:34.002222,y:-117.727783},
{x:40.540989,y:-80.012942},
{x:35.130404,y:-85.239735},
{x:38.800222,y:-121.205872},
{x:39.34338,y:-76.58470471},
{x:33.3795482,y:-86.8088718},
{x:40.20214,y:-85.40862579},
{x:33.21449,y:-87.54615516},
{x:36.962511,y:-86.469382},
{x:39.875285,y:-75.393986},
{x:34.437876,y:-85.758482},
{x:35.21568,y:-101.90133},
{x:40.05434584,y:-75.66352225},
{x:35.692076,y:-78.582585},
{x:27.96448,y:-82.504995},
{x:30.884899,y:-84.564687},
{x:32.95361394,y:-96.85247198},
{x:43.514379,y:-96.774918},
{x:33.524316,y:-112.099505},
{x:39.37078,y:-76.45445},
{x:34.53587227,y:-83.53477927},
{x:35.04444,y:-89.98166},
{x:37.984665,y:-87.495096},
{x:33.00520837,y:-96.97779377},
{x:33.917464,y:-118.064223},
{x:34.12613,y:-93.05877884},
{x:33.512,y:-90.343},
{x:32.86135788,y:-96.85215973},
{x:35.3224,y:-83.1762},
{x:31.976627,y:-102.07597},
{x:35.029304,y:-97.937405},
{x:30.561711,y:-84.218945},
{x:29.73541,y:-95.5891},
{x:35.14067,y:-90.03047},
{x:33.887611,y:-84.741166},
{x:39.615626,y:-104.816258},
{x:36.08879,y:-80.26767491},
{x:35.2201,y:-97.491},
{x:39.6346,y:-79.95358595},
{x:30.49416,y:-84.24411},
{x:33.191566,y:-87.525112},
{x:34.23740821,y:-77.82915789},
{x:38.95,y:-77.46},
{x:35.0162,y:-80.5713},
{x:30.393707,y:-90.086027},
{x:34.022987,y:-80.950742},
{x:36.59157,y:-82.261935},
{x:26.144347,y:-80.319403},
{x:33.966581,y:-84.491886},
{x:39.94986,y:-75.59960807},
{x:33.9508,y:-83.37518379},
{x:36.009451,y:-80.443813},
{x:30.00199,y:-95.27023},
{x:35.84533859,y:-78.88731222},
{x:29.546362,y:-95.230988},
{x:33.811,y:-85.761057},
{x:39.924503,y:-75.04515},
{x:40.4431,y:-79.9563},
{x:34.225887,y:-77.874075},
{x:32.617978,y:-83.691502},
{x:33.65563,y:-111.927745},
{x:35.113993,y:-89.89766},
{x:33.784919,y:-84.407885},
{x:34.880366,y:-83.397028},
{x:39.036992,y:-95.762455},
{x:33.702644,y:-80.241408},
{x:37.066726,y:-94.477304},
{x:36.292258,y:-76.24783},
{x:40.24717165,y:-77.00775763},
{x:32.31592745,y:-90.17946685},
{x:40.83658,y:-74.1809},
{x:37.65631,y:-77.62011},
{x:26.52712072,y:-80.09443222},
{x:29.811603,y:-95.564569},
{x:34.67616,y:-82.83202},
{x:40.674423,y:-80.313382},
{x:37.6757,y:-77.46164},
{x:32.861014,y:-96.754878},
{x:32.339066,y:-90.063359},
{x:30.38554,y:-91.06333},
{x:28.444842,y:-81.395545},
{x:25.928425,y:-80.183393},
{x:38.22222209,y:-84.53913884},
{x:34.11101,y:-84.55569},
{x:38.864431,y:-77.273241},
{x:40.761259,y:-73.968804},
{x:30.84728,y:-83.2902678},
{x:31.260937,y:-90.477478},
{x:33.94859,y:-118.162611},
{x:34.74527405,y:-87.66960423},
{x:39.46593,y:-76.63774},
{x:38.309761,y:-104.627732},
{x:40.224136,y:-74.268713},
{x:42.226595,y:-88.292025},
{x:39.70331568,y:-74.27078493},
{x:37.65014,y:-77.61342},
{x:37.797776,y:-121.193399},
{x:34.916477,y:-88.506606},
{x:31.88493,y:-106.58142},
{x:32.66217479,y:-96.87729493},
{x:41.599752,y:-93.765703},
{x:45.494513,y:-122.407335},
{x:38.923212,y:-94.649795},
{x:25.979378,y:-80.310644},
{x:25.96792,y:-97.51411},
{x:40.216616,y:-75.140407},
{x:32.30329,y:-80.97359},
{x:39.90923,y:-86.063842},
{x:33.260052,y:-111.642235},
{x:29.870394,y:-95.645821},
{x:34.04845,y:-84.29468},
{x:39.6788131,y:-75.6513954},
{x:27.38893,y:-82.460128},
{x:39.4868886,y:-75.04928164},
{x:33.911068,y:-117.880655},
{x:33.17801,y:-117.294873},
{x:40.32142856,y:-74.48799612},
{x:32.365982,y:-88.672098},
{x:38.61982,y:-76.91837},
{x:39.76512,y:-104.7741406},
{x:34.79598,y:-84.96264},
{x:34.176324,y:-84.42103},
{x:42.24615,y:-83.62562},
{x:35.740458,y:-78.776891},
{x:36.190292,y:-80.273683},
{x:29.59623,y:-95.61835},
{x:28.411264,y:-81.31421},
{x:40.196071,y:-74.63807},
{x:30.319003,y:-97.952617},
{x:35.373245,y:-80.784518},
{x:39.047607,y:-94.3578},
{x:31.5469,y:-97.1209743},
{x:42.990851,y:-83.737377},
{x:36.11787,y:-80.31319},
{x:36.06327,y:-95.8817},
{x:33.24971,y:-111.84011},
{x:35.1606,y:-101.92064},
{x:38.006196,y:-121.83546},
{x:35.335952,y:-81.894379},
{x:33.5175907,y:-86.8089598},
{x:35.8256,y:-84.27409},
{x:28.003448,y:-81.727376},
{x:35.986656,y:-96.016366},
{x:38.016903,y:-84.418488},
{x:36.17256,y:-82.76076},
{x:30.263946,y:-81.556823},
{x:32.965763,y:-96.644005},
{x:40.873817,y:-73.90904},
{x:32.28623,y:-95.30355},
{x:34.788831,y:-92.218416},
{x:31.704039,y:-89.148512},
{x:29.929515,y:-95.205049},
{x:35.128419,y:-106.702207},
{x:28.489721,y:-81.431111},
{x:40.151863,y:-105.096348},
{x:39.353103,y:-84.361217},
{x:36.84427,y:-76.14125},
{x:33.49513333,y:-111.9860136},
{x:34.417968,y:-118.557825},
{x:41.684509,y:-81.294236},
{x:34.975513,y:-92.023314},
{x:36.283772,y:-94.152304},
{x:33.846024,y:-84.252529},
{x:31.80034,y:-85.95540227},
{x:38.03575,y:-78.50661},
{x:36.743639,y:-76.583001},
{x:34.964602,y:-89.846846},
{x:36.88218,y:-76.29995},
{x:33.958525,y:-84.302881},
{x:37.14647,y:-93.255875},
{x:25.76012,y:-80.37164073},
{x:40.466634,y:-90.685052},
{x:33.56258283,y:-84.32314802},
{x:34.106752,y:-117.539486},
{x:38.97907,y:-76.93822},
{x:32.979809,y:-117.080547},
{x:33.396202,y:-84.600215},
{x:39.630435,y:-75.666115},
{x:36.338009,y:-82.377453},
{x:35.21392,y:-84.86938},
{x:26.367333,y:-80.195495},
{x:36.127226,y:-94.144015},
{x:41.3123,y:-105.57941},
{x:41.728171,y:-86.290133},
{x:33.21831,y:-96.72876},
{x:35.03352,y:-78.93324},
{x:32.812041,y:-79.866026},
{x:29.11031,y:-81.026754},
{x:32.755981,y:-97.428589},
{x:33.773055,y:-84.296083},
{x:25.67394,y:-80.37404},
{x:40.757433,y:-73.981982},
{x:35.46109,y:-97.62729},
{x:33.37171,y:-86.81276},
{x:39.59563,y:-104.70363},
{x:39.74033717,y:-104.9434054},
{x:29.815238,y:-95.771149},
{x:35.84017231,y:-78.68048},
{x:30.62222,y:-81.53513},
{x:40.93446,y:-75.10169},
{x:38.741652,y:-90.633642},
{x:35.91049,y:-79.04892067},
{x:34.84768027,y:-92.40590204},
{x:38.737906,y:-77.534345},
{x:33.660466,y:-95.512817},
{x:37.090169,y:-94.474633},
{x:38.133961,y:-85.578727},
{x:39.941493,y:-74.958404},
{x:36.05036,y:-83.99361},
{x:30.182133,y:-92.070914},
{x:34.83846,y:-87.63441},
{x:30.24364963,y:-81.59910287},
{x:26.302753,y:-98.175956},
{x:41.990331,y:-72.579674},
{x:29.922056,y:-90.037377},
{x:40.887801,y:-74.254353},
{x:45.033022,y:-93.01847},
{x:39.02361,y:-104.81722},
{x:35.044972,y:-78.9238},
{x:45.432956,y:-122.56918},
{x:30.16719,y:-95.46005},
{x:31.1205,y:-97.866},
{x:32.405794,y:-93.801487},
{x:27.6385,y:-80.45096},
{x:32.48002,y:-99.695344},
{x:28.55274,y:-81.35034},
{x:33.953308,y:-117.392261},
{x:36.15337,y:-95.94331207},
{x:39.197608,y:-76.818485},
{x:41.099522,y:-80.767895},
{x:42.908146,y:-78.772186},
{x:30.315585,y:-95.474202},
{x:40.43101,y:-111.88803},
{x:39.97109,y:-74.91149},
{x:28.04334179,y:-82.67908057},
{x:33.43545203,y:-111.9982945},
{x:36.27557366,y:-115.26922133},
{x:38.39677,y:-75.56624},
{x:39.397663,y:-74.552502},
{x:31.75417,y:-93.07885},
{x:29.71519,y:-98.15951},
{x:41.346084,y:-75.971414},
{x:32.497965,y:-85.045052},
{x:36.044423,y:-86.714061},
{x:25.68561589,y:-80.39286917},
{x:39.42109,y:-76.778454},
{x:33.725357,y:-112.117308},
{x:40.00160666,y:-86.12614514},
{x:32.922191,y:-84.333225},
{x:40.28869,y:-76.655879},
{x:29.181629,y:-81.080025},
{x:35.76415,y:-78.00964},
{x:41.86884261,y:-87.64126689},
{x:41.492107,y:-72.091526},
{x:27.250344,y:-80.274765},
{x:30.38727,y:-95.6788},
{x:28.806436,y:-96.996804},
{x:32.434132,y:-97.77534199},
{x:35.51429173,y:-97.62865583},
{x:37.037865,y:-76.403317},
{x:37.433895,y:-121.893949},
{x:33.950518,y:-84.51902},
{x:26.2873056,y:-80.20125},
{x:35.223515,y:-81.036902},
{x:43.208435,y:-77.685018},
{x:40.80914,y:-73.949137},
{x:30.265188,y:-91.99314},
{x:34.7604,y:-77.381615},
{x:30.06985,y:-95.24410344},
{x:33.17235795,y:-94.96931881},
{x:33.12764,y:-96.7286},
{x:29.773253,y:-95.60692915},
{x:37.5137,y:-77.52683},
{x:32.232019,y:-101.462304},
{x:38.07066,y:-84.4757},
{x:34.1776,y:-83.91089},
{x:29.579921,y:-95.398439},
{x:26.61204649,y:-81.81377048},
{x:40.43842107,y:-79.76679008},
{x:35.15188,y:-80.83208},
{x:25.950599,y:-97.483644},
{x:32.620283,y:-96.912073},
{x:28.35655,y:-81.67702},
{x:32.403745,y:-90.129561},
{x:39.767285,y:-86.157621},
{x:28.60125,y:-81.201734},
{x:29.644281,y:-95.574726},
{x:38.252672,y:-85.615985},
{x:35.60483954,y:-82.55355385},
{x:29.46691,y:-98.46488462},
{x:35.58323,y:-82.5251},
{x:32.314413,y:-90.875755},
{x:33.832111,y:-118.289416},
{x:38.728123,y:-77.799231},
{x:26.6762055,y:-80.20177872},
{x:44.833083,y:-93.152552},
{x:34.145724,y:-118.115377},
{x:42.15808454,y:-87.96057966},
{x:32.98258,y:-80.07104804},
{x:36.823513,y:-76.415506},
{x:40.623319,y:-111.861526},
{x:40.74522,y:-73.982457},
{x:40.453523,y:-80.157707},
{x:30.20587265,y:-95.4710898},
{x:40.1982831,y:-75.52597206},
{x:26.706838,y:-80.136739},
{x:32.99672212,y:-96.69879229},
{x:29.55401114,y:-81.22737328},
{x:32.81037,y:-96.61989},
{x:30.71546,y:-95.5478912},
{x:38.33692,y:-85.70818},
{x:28.01326285,y:-82.73733706},
{x:26.193293,y:-80.258677},
{x:30.18905,y:-81.5526},
{x:36.58206232,y:-79.42891494},
{x:45.553693,y:-94.213342},
{x:34.236141,y:-84.453449},
{x:35.04674467,y:-89.69698242},
{x:38.78612,y:-90.501646},
{x:32.98675,y:-96.74915704},
{x:32.344446,y:-97.39689},
{x:29.564634,y:-95.564426},
{x:41.8910515,y:-87.6248064},
{x:27.89324208,y:-82.50506836},
{x:28.522695,y:-81.588025},
{x:32.89757,y:-97.04461},
{x:37.977302,y:-87.46729},
{x:40.73796005,y:-73.61292013},
{x:33.22989,y:-86.80366},
{x:42.352312,y:-83.056776},
{x:33.86105,y:-84.33999},
{x:29.493135,y:-98.706608},
{x:32.328208,y:-90.171741},
{x:33.717738,y:-84.764489},
{x:42.282474,y:-71.645571},
{x:35.34698238,y:-82.4336699},
{x:37.98954,y:-84.527},
{x:26.36528,y:-80.13282},
{x:36.073833,y:-80.110975},
{x:38.75707,y:-93.74011732},
{x:35.05301,y:-80.76934},
{x:41.75633371,y:-111.8348391},
{x:35.105896,y:-106.550442},
{x:35.79107,y:-78.65918},
{x:41.47062169,y:-87.3076759},
{x:29.468402,y:-95.086083},
{x:41.47098,y:-90.482294},
{x:32.839097,y:-116.993073},
{x:33.656454,y:-84.42314},
{x:36.88665,y:-76.30640987},
{x:40.18256,y:-74.87116},
{x:29.68451242,y:-98.12501895},
{x:33.446474,y:-84.467537},
{x:39.763195,y:-86.330632},
{x:34.667285,y:-99.33218},
{x:35.61071,y:-97.469374},
{x:29.963561,y:-90.105272},
{x:47.709674,y:-122.1792334},
{x:39.12899927,y:-94.82797178},
{x:34.6136,y:-86.56798},
{x:39.0538913,y:-77.11640724},
{x:39.899,y:-80.19},
{x:39.68912,y:-84.16387},
{x:42.427962,y:-73.805517},
{x:42.343892,y:-122.883378},
{x:33.64167,y:-86.62627},
{x:29.922493,y:-95.602319},
{x:35.43576151,y:-97.394763},
{x:29.780588,y:-95.751302},
{x:40.523921,y:-104.990102},
{x:32.78543,y:-79.93764324},
{x:33.093727,y:-96.806174},
{x:33.85746462,y:-98.54846187},
{x:35.20967,y:-97.44438749},
{x:33.852087,y:-84.040152},
{x:33.59881859,y:-117.17259177},
{x:37.51222901,y:-77.61075333},
{x:41.259191,y:-96.031258},
{x:32.337062,y:-111.049019},
{x:32.080266,y:-81.281212},
{x:33.432579,y:-111.871702},
{x:36.332099,y:-86.620361},
{x:40.197028,y:-74.63878},
{x:28.51019295,y:-81.37577497},
{x:28.3758,y:-81.40383},
{x:33.596102,y:-85.038189},
{x:35.733459,y:-83.995361},
{x:34.82422,y:-82.558257},
{x:26.1953562,y:-98.20408769},
{x:47.790953,y:-122.215744},
{x:29.5420127,y:-95.14877},
{x:39.46076,y:-76.3183},
{x:33.84530723,y:-87.25244697},
{x:37.22671,y:-80.41894943},
{x:32.335,y:-90.331},
{x:36.0054,y:-78.93849137},
{x:30.60274644,y:-87.11421301},
{x:30.132566,y:-95.552964},
{x:35.993989,y:-85.04178},
{x:42.491438,y:-90.721056},
{x:32.484097,y:-86.415364},
{x:36.76962,y:-76.25151},
{x:33.09901,y:-96.812569},
{x:30.614,y:-96.339},
{x:28.474874,y:-81.624923},
{x:40.752567,y:-73.979059},
{x:33.409672,y:-84.567351},
{x:27.57129,y:-99.43580577},
{x:29.874798,y:-95.758799},
{x:44.83,y:-68.7533},
{x:35.733223,y:-77.952762},
{x:31.32891,y:-89.33264758},
{x:37.086443,y:-113.58501},
{x:36.12029,y:-86.775593},
{x:37.648042,y:-77.404604},
{x:41.69346,y:-83.6402662},
{x:29.817609,y:-95.46775},
{x:28.343076,y:-81.421858},
{x:30.465948,y:-97.596504},
{x:29.77486,y:-95.3812},
{x:32.64782386,y:-97.05872634},
{x:41.653676,y:-87.738742},
{x:32.82322,y:-97.20337},
{x:33.210149,y:-79.982024},
{x:36.149262,y:-81.206131},
{x:40.387773,y:-86.8378889},
{x:36.032043,y:-83.929263},
{x:35.19519,y:-84.85473},
{x:33.51297,y:-82.051837},
{x:41.805435,y:-72.558873},
{x:32.84202066,y:-96.99343716},
{x:38.830041,y:-77.191024},
{x:31.78264036,y:-85.95596796},
{x:31.44104,y:-100.46642},
{x:32.46027729,y:-96.99338675},
{x:35.72202311,y:-81.69449837},
{x:30.220382,y:-97.757118},
{x:29.76968341,y:-95.17984312},
{x:40.351992,y:-80.051531},
{x:38.88593,y:-104.71753},
{x:34.49295179,y:-81.98000382},
{x:41.574347,y:-90.530385},
{x:33.957853,y:-83.37556},
{x:34.30861,y:-88.701313},
{x:39.94948115,y:-86.34979347},
{x:34.513956,y:-82.637971},
{x:40.41744,y:-74.16926},
{x:30.119833,y:-93.746718},
{x:40.849568,y:-73.933846},
{x:40.81755,y:-96.70047},
{x:34.06929,y:-84.280931},
{x:39.298363,y:-84.315801},
{x:40.436545,y:-79.990937},
{x:35.38122,y:-86.22839},
{x:33.1006,y:-83.2482},
{x:35.884352,y:-83.573752},
{x:37.8465,y:-81.1979},
{x:36.86377692,y:-76.28516123},
{x:36.10756,y:-80.10022},
{x:29.581306,y:-90.693733},
{x:34.064967,y:-83.983979},
{x:41.21745,y:-80.739998},
{x:30.1273,y:-95.4534},
{x:33.68693758,y:-117.17535868},
{x:34.15962,y:-118.132086},
{x:35.9050693,y:-78.9422632},
{x:33.49991759,y:-86.79550496},
{x:29.995774,y:-90.256299},
{x:33.774328,y:-84.38732},
{x:25.939314,y:-80.297645},
{x:34.03603,y:-84.461063},
{x:28.266889,y:-82.187386},
{x:21.3029417,y:-157.8463496},
{x:29.9867,y:-95.349936},
{x:44.884715,y:-123.010279},
{x:34.561001,y:-86.972809},
{x:38.65308,y:-77.28676},
{x:33.75553,y:-84.400933},
{x:41.519765,y:-81.449865},
{x:42.36939201,y:-83.3324058},
{x:37.23957828,y:-93.2617422},
{x:37.301973,y:-89.57244},
{x:39.31244,y:-80.272553},
{x:39.61451595,y:-86.15905823},
{x:36.205942,y:-83.322278},
{x:30.00669,y:-90.23717},
{x:38.234274,y:-85.641256},
{x:41.698803,y:-71.495978},
{x:38.050226,y:-78.502447},
{x:35.44858,y:-81.0002},
{x:32.300828,y:-90.1856},
{x:30.854315,y:-83.945167},
{x:30.44234,y:-89.098953},
{x:30.18731741,y:-81.73748879},
{x:33.859768,y:-117.922414},
{x:40.49588557,y:-80.24618706},
{x:40.831886,y:-73.19627},
{x:40.682813,y:-73.976824},
{x:33.726007,y:-117.988771},
{x:47.242493,y:-122.524828},
{x:43.086487,y:-77.623232},
{x:31.5231,y:-97.1673},
{x:35.967882,y:-78.960567},
{x:31.31042,y:-94.72437},
{x:33.573474,y:-81.938144},
{x:35.608537,y:-97.639088},
{x:40.859555,y:-81.433051},
{x:39.299886,y:-81.552745},
{x:38.20447,y:-85.66616},
{x:34.960997,y:-89.93719},
{x:39.422226,y:-87.41666},
{x:36.0604,y:-96.002503},
{x:43.0172,y:-74.80271},
{x:33.649629,y:-84.009424},
{x:33.895185,y:-84.140814},
{x:39.98101,y:-82.834733},
{x:33.333359,y:-86.785704},
{x:40.751739,y:-73.986051},
{x:29.484287,y:-98.59366},
{x:32.798824,y:-80.031024},
{x:30.440103,y:-84.261327},
{x:36.86362,y:-76.21114},
{x:28.69925533,y:-100.48476303},
{x:29.621414,y:-82.377936},
{x:39.300513,y:-81.547552},
{x:41.462724,y:-81.954022},
{x:34.00177362,y:-81.01775222},
{x:32.718754,y:-96.323098},
{x:44.3175,y:-96.78165598},
{x:36.06755,y:-79.81029611},
{x:37.801728,y:-81.179976},
{x:39.024882,y:-77.425769},
{x:29.88905,y:-97.94441073},
{x:35.80336,y:-78.72187},
{x:33.507,y:-86.803},
{x:33.129076,y:-96.650687},
{x:33.699696,y:-117.885026},
{x:30.47928,y:-81.635097},
{x:39.5483,y:-105.00001},
{x:29.31077,y:-94.77601},
{x:35.124121,y:-78.880116},
{x:35.57981,y:-77.38166},
{x:27.979498,y:-82.534665},
{x:36.29773,y:-94.18327},
{x:37.565558,y:-97.243449},
{x:39.764,y:-86.156},
{x:35.206683,y:-89.840259},
{x:39.708396,y:-104.939988},
{x:35.848059,y:-86.366289},
{x:44.88344,y:-93.214269},
{x:33.31926689,y:-111.9746716},
{x:31.89061,y:-102.34944},
{x:37.11571,y:-76.46599},
{x:27.71179,y:-97.32517953},
{x:32.2075476,y:-82.38166148},
{x:35.6522723,y:-97.46389964},
{x:40.827742,y:-73.403281},
{x:35.836784,y:-78.642125},
{x:34.109838,y:-84.21975},
{x:35.623065,y:-97.482798},
{x:42.7188,y:-88.9894},
{x:40.703052,y:-99.095826},
{x:33.611249,y:-112.186675},
{x:34.30162525,y:-85.17109114},
{x:37.30333,y:-79.95943},
{x:33.917098,y:-83.442855},
{x:41.448194,y:-81.496977},
{x:41.950732,y:-83.400085},
{x:41.316654,y:-96.024497},
{x:38.504741,y:-90.328258},
{x:42.810699,y:-86.08875},
{x:41.053445,y:-83.609294},
{x:35.004559,y:-80.559495},
{x:33.041518,y:-84.97454},
{x:32.884139,y:-97.154035},
{x:40.2758748,y:-111.6790623},
{x:34.007053,y:-84.573901},
{x:33.899263,y:-117.557957},
{x:36.11332,y:-115.154193},
{x:38.13843361,y:-85.66498769},
{x:33.9437,y:-84.123249},
{x:40.808852,y:-77.906009},
{x:47.742598,y:-117.408028},
{x:32.812342,y:-80.042088},
{x:34.31364,y:-83.796331},
{x:32.975843,y:-96.717911},
{x:28.07037,y:-82.73949},
{x:32.860767,y:-97.318658},
{x:41.3803,y:-73.4802},
{x:30.08288,y:-97.82312},
{x:29.81043,y:-95.1628},
{x:39.1637,y:-86.49355},
{x:39.23513,y:-76.8148},
{x:35.2695,y:-81.4829},
{x:25.9213,y:-97.467566},
{x:32.78299,y:-96.56283},
{x:47.91955,y:-97.08163},
{x:35.07069,y:-82.37163},
{x:37.19958,y:-93.27857658},
{x:39.933096,y:-86.23242},
{x:36.87238,y:-76.13472},
{x:39.2138,y:-76.86163},
{x:38.67259577,y:-77.33461107},
{x:33.64108,y:-84.4395305},
{x:32.88122,y:-96.76238932},
{x:36.239847,y:-115.165392},
{x:37.725763,y:-87.122675},
{x:33.478615,y:-82.198393},
{x:37.1037379,y:-113.565287},
{x:37.710459,y:-85.834216},
{x:28.658507,y:-81.338459},
{x:34.07058589,y:-117.203702},
{x:32.89029,y:-97.03578},
{x:29.67038225,y:-98.63473076},
{x:30.44198,y:-91.02395},
{x:34.052025,y:-84.090101},
{x:39.664501,y:-75.606153},
{x:35.86077,y:-86.83902},
{x:33.96643358,y:-80.94351416},
{x:32.467665,y:-95.386346},
{x:39.620319,y:-105.090499},
{x:41.945647,y:-87.655564},
{x:41.10694,y:-80.64853947},
{x:42.482766,y:-71.213409},
{x:25.866995,y:-80.292674},
{x:42.229084,y:-83.346183},
{x:34.62359,y:-89.97312},
{x:42.12801,y:-80.086728},
{x:30.22142,y:-95.56537},
{x:28.03061,y:-81.91801995},
{x:34.184728,y:-84.146276},
{x:30.717315,y:-94.960964},
{x:33.463086,y:-84.214618},
{x:34.658436,y:-84.492777},
{x:36.019193,y:-78.946991},
{x:36.95337,y:-86.4329},
{x:28.45054,y:-81.47884},
{x:25.866276,y:-80.315237},
{x:39.747732,y:-74.991696},
{x:42.447557,y:-96.349357},
{x:43.045026,y:-87.916505},
{x:35.95697,y:-83.92834902},
{x:33.90173,y:-84.47804},
{x:40.042945,y:-86.144721},
{x:34.000248,y:-83.908185},
{x:39.83920575,y:-105.0822237},
{x:42.23893,y:-87.94372},
{x:37.29965,y:-78.39283015},
{x:30.143198,y:-96.378681},
{x:33.87445,y:-98.52109329},
{x:40.63346,y:-80.06039},
{x:40.02919,y:-75.10002},
{x:41.43927,y:-73.40773},
{x:35.945894,y:-78.506505},
{x:33.60966,y:-85.785558},
{x:44.030495,y:-88.583901},
{x:32.83008,y:-97.19992},
{x:35.17472,y:-106.57566},
{x:36.158651,y:-115.290928},
{x:29.72209,y:-95.33856793},
{x:28.936116,y:-81.940816},
{x:27.71449,y:-97.38555},
{x:41.79555,y:-71.32912},
{x:32.57456,y:-97.08521},
{x:38.85456,y:-77.04966},
{x:40.354752,y:-75.981796},
{x:32.75617,y:-97.69863},
{x:36.481335,y:-86.888519},
{x:40.79858,y:-81.49073},
{x:44.261111,y:-88.462167},
{x:38.61043,y:-76.92585},
{x:39.7675,y:-104.9035},
{x:39.160481,y:-84.425283},
{x:32.91355,y:-96.81792},
{x:42.012451,y:-87.769818},
{x:34.049308,y:-118.259501},
{x:40.06207,y:-80.59799},
{x:39.522386,y:-76.185027},
{x:33.5935,y:-101.87092},
{x:38.026016,y:-84.536952},
{x:28.987632,y:-81.299545},
{x:38.23414,y:-77.50357},
{x:38.361395,y:-121.965339},
{x:32.93378806,y:-97.22873094},
{x:36.768065,y:-108.146802},
{x:34.356704,y:-84.044434},
{x:29.3909,y:-100.905},
{x:30.4368183,y:-84.2538001},
{x:38.32855,y:-85.75914},
{x:33.687922,y:-85.150197},
{x:29.6057454,y:-98.46855061},
{x:30.031194,y:-90.06327},
{x:35.63936083,y:-78.83489829},
{x:40.0626,y:-83.018749},
{x:41.717871,y:-86.190576},
{x:48.075924,y:-122.18287},
{x:38.922466,y:-77.504593},
{x:30.392704,y:-88.990744},
{x:36.16622,y:-86.82849642},
{x:29.2581,y:-81.11094},
{x:35.777492,y:-86.915527},
{x:34.78766271,y:-82.4791619},
{x:33.136937,y:-117.177772},
{x:33.831016,y:-118.085389},
{x:33.390513,y:-111.6901459},
{x:32.04063,y:-81.06362},
{x:33.34678,y:-96.58512},
{x:26.5450539,y:-81.8722548},
{x:28.661393,y:-81.392579},
{x:41.89673,y:-87.62695},
{x:32.940252,y:-96.699353},
{x:39.899177,y:-74.931161},
{x:37.72211509,y:-97.46395377},
{x:34.577477,y:-112.363381},
{x:32.52099811,y:-97.34919368},
{x:39.650083,y:-79.971547},
{x:32.44243162,y:-93.71873937},
{x:30.532073,y:-92.070004},
{x:33.827593,y:-84.328703},
{x:37.5087556,y:-77.6090076},
{x:29.96789,y:-95.53385},
{x:47.820574,y:-122.274797},
{x:34.026044,y:-117.836152},
{x:37.54654,y:-77.45343314},
{x:35.018502,y:-106.713329},
{x:35.072,y:-78.9609},
{x:30.15491,y:-85.59163},
{x:39.483184,y:-80.161607},
{x:28.09594,y:-82.50159},
{x:38.71505949,y:-90.45441246},
{x:38.786131,y:-77.516063},
{x:43.12614,y:-89.312017},
{x:29.674571,y:-95.459281},
{x:35.32207,y:-80.644969},
{x:38.628705,y:-90.340562},
{x:30.4109,y:-91.1831},
{x:43.082925,y:-88.227555},
{x:30.196374,y:-81.828223},
{x:34.073242,y:-117.879985},
{x:35.2265794,y:-80.8432784},
{x:34.73849,y:-76.806364},
{x:32.880381,y:-97.100422},
{x:34.4403,y:-119.7427},
{x:28.3454,y:-81.602092},
{x:36.0788,y:-86.94726},
{x:33.575613,y:-84.412991},
{x:39.11457463,y:-77.53682424},
{x:32.957066,y:-96.818991},
{x:33.4627092,y:-82.0791569},
{x:32.28087,y:-90.037029},
{x:34.03698,y:-84.34285},
{x:32.737,y:-97.3409754},
{x:27.94605,y:-82.46590303},
{x:28.369631,y:-81.503968},
{x:33.90789,y:-81.221906},
{x:42.65665,y:-73.693751},
{x:39.223527,y:-84.516476},
{x:39.124562,y:-119.772344},
{x:37.35774,y:-79.1735419},
{x:38.81595,y:-90.87593},
{x:35.659925,y:-105.981316},
{x:31.7852495,y:-106.39489816},
{x:33.953168,y:-84.58099},
{x:25.675812,y:-80.374521},
{x:32.79887,y:-79.95973},
{x:30.05041,y:-95.18055},
{x:40.862169,y:-73.89634},
{x:29.77411445,y:-95.81930738},
{x:42.160752,y:-88.04874},
{x:45.004228,y:-93.16581492},
{x:30.18008,y:-82.68501},
{x:39.334,y:-76.450384},
{x:33.03502,y:-85.05713},
{x:39.086949,y:-84.477571},
{x:32.602,y:-85.489},
{x:38.785067,y:-121.286041},
{x:40.081727,y:-75.026753},
{x:32.53185,y:-84.96814},
{x:30.48205759,y:-90.45652458},
{x:34.153468,y:-86.840287},
{x:33.56231,y:-86.75197618},
{x:33.695975,y:-84.089426},
{x:31.821566,y:-81.600111},
{x:35.65506,y:-97.47153708},
{x:29.136293,y:-82.200037},
{x:29.70316442,y:-95.55809245},
{x:40.2485,y:-111.6475552},
{x:32.606795,y:-85.486458},
{x:35.21988,y:-97.42403},
{x:35.66008767,y:-80.46115813},
{x:39.08005,y:-76.54742},
{x:29.697496,y:-95.519602},
{x:33.28190479,y:-111.7903771},
{x:37.165334,y:-80.423894},
{x:30.659699,y:-96.331003},
{x:32.4698,y:-99.7084},
{x:32.84361,y:-96.78346931},
{x:27.93833161,y:-82.26288321},
{x:44.856253,y:-93.241675},
{x:40.05529903,y:-75.52937982},
{x:42.148604,y:-70.844952},
{x:29.76012,y:-95.36575416},
{x:39.852699,y:-74.984389},
{x:42.53977,y:-70.941779},
{x:39.91208,y:-75.43878},
{x:39.496765,y:-76.651466},
{x:31.28521,y:-92.475201},
{x:39.824112,y:-75.543909},
{x:39.591,y:-104.9608},
{x:29.64915,y:-95.16477},
{x:30.287133,y:-81.45844},
{x:41.6914,y:-83.64435},
{x:40.92332,y:-73.05005},
{x:38.599645,y:-90.447083},
{x:35.101288,y:-80.94119823},
{x:32.84466006,y:-96.78740349},
{x:33.652546,y:-117.645291},
{x:41.569293,y:-93.803986},
{x:39.18804,y:-84.258091},
{x:36.79217,y:-76.12091},
{x:40.659066,y:-111.887904},
{x:33.615997,y:-117.8749541},
{x:32.557,y:-94.375},
{x:41.271132,y:-73.128124},
{x:40.57016285,y:-74.29344606},
{x:37.615937,y:-84.781068},
{x:40.02361841,y:-76.19625249},
{x:40.08955453,y:-83.09252492},
{x:34.93844,y:-81.03241},
{x:29.540342,y:-95.023862},
{x:36.374763,y:-86.476723},
{x:34.13709,y:-80.9583},
{x:33.35969,y:-84.753474},
{x:39.849575,y:-104.673857},
{x:29.870381,y:-95.328033},
{x:32.626339,y:-85.44704},
{x:38.304635,y:-77.509643},
{x:29.69509,y:-95.51582198},
{x:35.29548,y:-80.74118},
{x:33.21289,y:-87.54363359},
{x:36.880545,y:-76.424159},
{x:42.913474,y:-85.582641},
{x:38.948245,y:-77.07958},
{x:40.553045,y:-74.432566},
{x:25.971545,y:-80.142832},
{x:41.72371552,y:-72.61546717},
{x:28.672394,y:-81.466919},
{x:33.41815,y:-111.9341925},
{x:35.30502197,y:-80.73325679},
{x:42.900881,y:-87.914307},
{x:39.570723,y:-76.382111},
{x:30.118849,y:-94.167095},
{x:33.451424,y:-111.684768},
{x:37.30288,y:-121.86033},
{x:27.96098134,y:-82.73291456},
{x:30.47465,y:-87.234065},
{x:36.51407428,y:-87.31740594},
{x:35.010355,y:-80.852176},
{x:30.189232,y:-85.650418},
{x:35.454008,y:-86.0432},
{x:32.60188,y:-85.48708519},
{x:34.362626,y:-89.563437},
{x:33.409127,y:-84.685891},
{x:32.35825,y:-86.17046},
{x:41.61261469,y:-83.70181565},
{x:33.715959,y:-84.214928},
{x:32.811118,y:-83.721385},
{x:33.90471,y:-84.60355},
{x:33.45039132,y:-86.78778772},
{x:32.88476,y:-80.03708},
{x:34.95998,y:-81.93266775},
{x:39.66644017,y:-86.0786001},
{x:32.603723,y:-85.491981},
{x:33.62014,y:-83.84032},
{x:33.82392036,y:-117.5138492},
{x:39.03412,y:-84.53341},
{x:35.89531492,y:-86.87730377},
{x:33.52529,y:-82.11951},
{x:47.358495,y:-122.108409},
{x:32.91408,y:-97.3148},
{x:32.79614482,y:-97.34999438},
{x:39.635906,y:-86.12564},
{x:34.24508,y:-77.87917},
{x:37.31151,y:-89.53193539},
{x:31.980598,y:-81.143722},
{x:33.217821,y:-96.679897},
{x:40.087996,y:-75.393444},
{x:34.15619,y:-79.76741},
{x:33.995554,y:-117.930751},
{x:39.682344,y:-75.754591},
{x:40.638145,y:-73.740788},
{x:35.438307,y:-80.605827},
{x:38.925414,y:-104.717368},
{x:41.411465,y:-82.668172},
{x:29.96143673,y:-90.18362827},
{x:33.504698,y:-81.958347},
{x:41.313428,y:-81.523471},
{x:39.03436,y:-94.58104614},
{x:38.75565621,y:-90.43230236},
{x:30.729823,y:-86.567937},
{x:34.77687989,y:-82.3125559},
{x:29.556481,y:-95.377178},
{x:33.613461,y:-117.710286},
{x:40.30675,y:-75.859229},
{x:35.95179,y:-83.93405},
{x:47.31407,y:-122.31388},
{x:43.088506,y:-88.068788},
{x:42.04914,y:-88.0562},
{x:35.35491,y:-94.35707},
{x:33.216207,y:-96.63827},
{x:39.879028,y:-83.039513},
{x:41.94683,y:-87.709578},
{x:30.259918,y:-81.528573},
{x:34.06454,y:-83.98901},
{x:44.14701,y:-94.00033703},
{x:35.63688,y:-88.83175755},
{x:29.59735622,y:-95.26704465},
{x:38.04042,y:-84.50380344},
{x:33.60345,y:-83.865781},
{x:33.724479,y:-84.511225},
{x:38.42109,y:-82.26654},
{x:34.756,y:-86.616},
{x:41.670601,y:-70.301185},
{x:32.510491,y:-92.156669},
{x:40.803523,y:-74.019072},
{x:36.084433,y:-79.83363},
{x:29.780841,y:-95.540333},
{x:39.01605,y:-77.37617},
{x:29.901671,y:-95.633809},
{x:30.059273,y:-99.164085},
{x:34.90211,y:-82.33609},
{x:35.206257,y:-106.656721},
{x:33.1947362,y:-87.523438},
{x:29.73891,y:-95.46411},
{x:33.512791,y:-112.122484},
{x:31.5712,y:-84.142},
{x:33.656401,y:-84.40398},
{x:34.146394,y:-77.893013},
{x:32.961333,y:-96.770726},
{x:32.73175,y:-97.11136479},
{x:38.245256,y:-77.498311},
{x:36.370828,y:-94.220922},
{x:35.928383,y:-84.035158},
{x:35.11552,y:-89.80068},
{x:40.46012,y:-75.369372},
{x:39.641038,y:-80.000679},
{x:34.186677,y:-118.623111},
{x:30.617142,y:-96.343346},
{x:33.847423,y:-84.428824},
{x:39.9782014,y:-75.1597822},
{x:29.469529,y:-98.621451},
{x:40.395659,y:-104.75264},
{x:40.586757,y:-122.357675},
{x:34.12857,y:-117.88965},
{x:33.0099,y:-96.54389},
{x:34.31615755,y:-88.7037927},
{x:37.757845,y:-77.464226},
{x:38.720772,y:-75.118107},
{x:34.36686,y:-89.53456393},
{x:35.64321,y:-97.42572},
{x:34.787877,y:-77.403762},
{x:38.466392,y:-121.492399},
{x:37.69421,y:-97.244939},
{x:36.808211,y:-119.773606},
{x:29.9354,y:-95.2482},
{x:35.70347,y:-81.30479},
{x:32.70779,y:-96.94851439},
{x:39.07252,y:-84.31693},
{x:28.08082494,y:-82.42583246},
{x:42.171237,y:-72.576557},
{x:41.70566297,y:-93.5819464},
{x:40.06871,y:-76.31504},
{x:39.0247947,y:-77.1465112},
{x:39.940359,y:-75.030138},
{x:34.758878,y:-84.93236},
{x:30.456783,y:-88.902007},
{x:33.92414,y:-84.341171},
{x:30.39550756,y:-87.27741121},
{x:31.217839,y:-85.362923},
{x:29.5929969,y:-98.6139981},
{x:35.90305,y:-78.897722},
{x:36.971863,y:-82.585039},
{x:40.293,y:-76.662},
{x:30.00599,y:-90.15756},
{x:28.394991,y:-81.48435},
{x:41.15977598,y:-104.7792355},
{x:30.706131,y:-95.563427},
{x:39.39934,y:-76.54403},
{x:33.82211,y:-85.76402203},
{x:40.690904,y:-74.290886},
{x:35.1035,y:-77.0874},
{x:30.16432,y:-95.45545},
{x:32.593442,y:-83.633777},
{x:28.812229,y:-81.331623},
{x:33.801674,y:-118.327766},
{x:34.764552,y:-77.380606},
{x:33.553715,y:-117.685754},
{x:35.814727,y:-80.876171},
{x:42.171271,y:-88.334444},
{x:39.312415,y:-76.751345},
{x:37.537144,y:-77.526889},
{x:35.18603569,y:-101.86570811},
{x:30.28195,y:-97.74284},
{x:35.04917626,y:-81.98492071},
{x:29.53823,y:-98.57305376},
{x:38.783118,y:-76.082737},
{x:28.551176,y:-81.532869},
{x:38.546548,y:-76.984343},
{x:27.997047,y:-82.579403},
{x:39.75884575,y:-75.04801076},
{x:33.64051,y:-84.432488},
{x:36.6356746,y:-82.11730921},
{x:42.97289,y:-77.17342},
{x:30.51564,y:-86.461982},
{x:35.095166,y:-81.666174},
{x:38.444522,y:-90.373045},
{x:33.24,y:-95.909},
{x:32.79008,y:-96.78059353},
{x:44.97261,y:-93.23532925},
{x:41.408801,y:-92.957149},
{x:37.39889958,y:-76.52592868},
{x:33.63584576,y:-112.2329851},
{x:32.474562,y:-83.746386},
{x:26.049378,y:-80.251325},
{x:33.78947,y:-118.14116},
{x:32.99801,y:-96.83158},
{x:33.12325,y:-96.82355},
{x:35.6129581,y:-97.54904065},
{x:38.28865,y:-77.53302},
{x:40.130628,y:-74.222199},
{x:33.518086,y:-86.812585},
{x:35.16433,y:-84.87106592},
{x:31.159728,y:-81.390172},
{x:39.057397,y:-76.966914},
{x:29.73695,y:-95.51767},
{x:29.89216,y:-81.31616108},
{x:44.72733,y:-85.645283},
{x:38.916717,y:-75.441139},
{x:27.792891,y:-82.733663},
{x:34.661521,y:-86.01298},
{x:34.24138,y:-92.02314},
{x:42.74145,y:-71.160141},
{x:41.88576,y:-87.628},
{x:29.70218658,y:-95.294084},
{x:32.028593,y:-81.110668},
{x:27.976088,y:-82.531917},
{x:28.49262,y:-81.45966},
{x:31.92391,y:-106.43966},
{x:39.90466107,y:-75.19497679},
{x:37.984623,y:-121.34107},
{x:39.449196,y:-76.816461},
{x:39.87802,y:-75.237505},
{x:35.0988152,y:-80.78132351},
{x:35.085191,y:-85.06654},
{x:45.005449,y:-123.000803},
{x:32.712664,y:-117.012142},
{x:40.38133,y:-111.814316},
{x:39.980536,y:-75.158358},
{x:39.245191,y:-94.451814},
{x:37.069742,y:-76.493738},
{x:33.829752,y:-118.353},
{x:38.636625,y:-90.261526},
{x:39.52879,y:-76.35286},
{x:38.78520408,y:-89.95194743},
{x:34.085682,y:-84.526889},
{x:43.67691042,y:-70.3320381},
{x:34.474972,y:-84.928007},
{x:40.274355,y:-74.706423},
{x:36.830891,y:-76.339874},
{x:32.812012,y:-97.430511},
{x:39.2058175,y:-84.37910906},
{x:34.574699,y:-83.312087},
{x:38.77914,y:-90.69954},
{x:41.31366,y:-81.81526},
{x:32.420809,y:-90.14956},
{x:35.702227,y:-79.786879},
{x:29.78432126,y:-95.70652157},
{x:39.766965,y:-84.056744},
{x:35.7714,y:-83.98899},
{x:30.09024,y:-95.99097},
{x:41.87312515,y:-87.6767765},
{x:33.273,y:-84.29294},
{x:38.955391,y:-76.823404},
{x:33.33335,y:-86.994024},
{x:33.68158,y:-78.92269},
{x:41.23582,y:-75.856778},
{x:33.457577,y:-112.392431},
{x:42.913692,y:-85.532909},
{x:33.38298286,y:-111.8064244},
{x:42.011952,y:-87.722086},
{x:31.450596,y:-83.531577},
{x:34.26112,y:-119.230879},
{x:41.50222108,y:-81.67518577},
{x:41.15959982,y:-95.94201621},
{x:39.304926,y:-84.52419},
{x:31.65618,y:-84.17358668},
{x:32.73722,y:-79.96755},
{x:35.390561,y:-97.531333},
{x:26.160119,y:-97.87625},
{x:40.18893,y:-80.218059},
{x:34.960843,y:-82.433641},
{x:40.300962,y:-79.508235},
{x:33.429835,y:-84.458569},
{x:36.175208,y:-94.193282},
{x:27.85572894,y:-97.63375181},
{x:36.158548,y:-115.086957},
{x:29.735572,y:-95.777899},
{x:26.31742,y:-80.15441},
{x:40.278796,y:-111.714332},
{x:26.59012097,y:-80.14370519},
{x:34.93365,y:-83.84582785},
{x:34.242394,y:-119.183359},
{x:28.86617,y:-96.994577},
{x:40.808,y:-73.294},
{x:30.003788,y:-95.635491},
{x:36.79883,y:-76.17896},
{x:33.986282,y:-118.400774},
{x:33.801255,y:-84.21177},
{x:38.773507,y:-77.18307},
{x:33.984357,y:-84.349916},
{x:42.3861951,y:-87.96398609},
{x:34.162119,y:-118.518378},
{x:35.770258,y:-95.375501},
{x:47.722539,y:-122.345337},
{x:29.18948,y:-81.04971897},
{x:39.089245,y:-77.557482},
{x:41.25907,y:-95.96226781},
{x:32.59311,y:-96.9373},
{x:36.11561593,y:-115.30700812},
{x:34.617294,y:-92.497489},
{x:33.011972,y:-96.610162},
{x:38.419843,y:-75.565708},
{x:33.03702724,y:-80.15630989},
{x:29.45252,y:-98.56279682},
{x:36.057661,y:-115.045765},
{x:34.280176,y:-118.743342},
{x:34.001532,y:-118.083469},
{x:28.430674,y:-81.446295},
{x:35.21108,y:-80.69092},
{x:38.90955167,y:-94.76025221},
{x:30.4748364,y:-87.2078848},
{x:28.06346,y:-82.41384414},
{x:39.0599,y:-77.449824},
{x:29.76609,y:-95.36040189},
{x:40.25099,y:-111.66705},
{x:41.670958,y:-71.157506},
{x:38.985258,y:-84.649844},
{x:33.561499,y:-86.523134},
{x:35.391148,y:-80.624909},
{x:27.52292,y:-97.88139743},
{x:35.29957,y:-120.6595467},
{x:36.055419,y:-79.894454},
{x:26.71443,y:-80.0929},
{x:36.137631,y:-85.508691},
{x:34.18714,y:-97.16117751},
{x:29.945657,y:-93.99545},
{x:30.44241692,y:-90.14324454},
{x:27.7976511,y:-82.33403455},
{x:30.1152434,y:-95.38410773},
{x:41.41092,y:-81.83878778},
{x:36.717213,y:-121.655743},
{x:42.33946428,y:-71.58596635},
{x:29.920929,y:-95.686744},
{x:35.223507,y:-89.907431},
{x:29.804477,y:-90.817132},
{x:39.92837104,y:-105.1394544},
{x:39.477305,y:-119.791909},
{x:30.355,y:-88.578},
{x:34.03838,y:-84.58320034},
{x:31.07196,y:-97.66901614},
{x:34.88951665,y:-82.40527395},
{x:33.61130512,y:-86.28191259},
{x:29.42169685,y:-95.22546705},
{x:41.88,y:-87.644},
{x:39.84209,y:-84.194001},
{x:41.97477444,y:-87.89054779},
{x:35.96966,y:-79.06467},
{x:33.94584,y:-84.62637},
{x:39.56541251,y:-104.8832788},
{x:35.440296,y:-80.603015},
{x:34.7257,y:-86.63863274},
{x:33.760385,y:-84.39537},
{x:33.760347,y:-117.83393},
{x:34.156558,y:-118.335026},
{x:35.17716,y:-80.799035},
{x:33.97966,y:-84.00147797},
{x:36.179933,y:-82.776596},
{x:38.970704,y:-94.771632},
{x:39.28668615,y:-84.46063519},
{x:29.65419,y:-95.276613},
{x:39.65023,y:-78.93361895},
{x:34.07878,y:-81.15737},
{x:33.164225,y:-87.547976},
{x:34.097761,y:-118.338247},
{x:33.999101,y:-86.005563},
{x:38.718,y:-104.701},
{x:42.947965,y:-88.009051},
{x:41.660508,y:-83.614471},
{x:36.19121472,y:-86.61459628},
{x:40.031816,y:-75.104514},
{x:30.210306,y:-90.943616},
{x:38.95757,y:-95.24775815},
{x:32.420642,y:-96.838925},
{x:17.9973503,y:-66.64258873},
{x:20.873413,y:-156.458274},
{x:34.001565,y:-84.169704},
{x:35.782835,y:-80.255974},
{x:39.652,y:-79.96},
{x:40.032223,y:-75.622663},
{x:26.21757172,y:-81.768893},
{x:35.198116,y:-80.868032},
{x:40.072791,y:-75.155087},
{x:29.81180509,y:-95.41635673},
{x:36.35019,y:-82.23778},
{x:33.1734431,y:-96.69651996},
{x:35.4775505,y:-97.52222706},
{x:34.25768,y:-83.46368},
{x:29.7865,y:-95.46751},
{x:40.30180994,y:-79.49872429},
{x:42.227387,y:-85.590034},
{x:38.91014,y:-77.074075},
{x:33.93933,y:-117.279972},
{x:29.37917529,y:-98.46077},
{x:38.77003,y:-76.883958},
{x:30.0021,y:-95.582627},
{x:32.841161,y:-97.239855},
{x:35.241557,y:-106.69677},
{x:34.1735589,y:-118.3800593},
{x:32.37869,y:-86.3059},
{x:32.472023,y:-84.956275},
{x:36.04241,y:-78.89941},
{x:43.614642,y:-116.593827},
{x:34.67926751,y:-82.83812255},
{x:37.478519,y:-82.522284},
{x:28.55578,y:-81.20631},
{x:40.6303566,y:-75.480399},
{x:43.52526376,y:-96.73849655},
{x:41.681093,y:-83.622329},
{x:34.736501,y:-86.65249},
{x:39.49239,y:-74.5308012},
{x:33.563754,y:-83.479059},
{x:30.13388,y:-92.03441},
{x:26.17414,y:-97.67489},
{x:35.581882,y:-80.852828},
{x:36.335383,y:-94.175383},
{x:41.468104,y:-87.026828},
{x:37.29001,y:-80.07909},
{x:40.644686,y:-74.40985},
{x:40.364253,y:-74.302466},
{x:37.53612,y:-77.43575},
{x:33.419706,y:-86.672551},
{x:34.742522,y:-92.27607},
{x:30.44152,y:-84.29370589},
{x:41.1717,y:-96.02323},
{x:39.56335,y:-105.10942},
{x:37.52766823,y:-77.35561874},
{x:32.900276,y:-83.688584},
{x:37.333515,y:-79.243824},
{x:35.191941,y:-80.796246},
{x:32.92245,y:-96.83858},
{x:34.164183,y:-118.828058},
{x:31.092824,y:-97.729026},
{x:29.84769,y:-95.50188},
{x:29.890571,y:-95.583509},
{x:32.941079,y:-80.039676},
{x:35.0738,y:-78.89177},
{x:39.20698,y:-76.94297},
{x:28.191656,y:-82.394486},
{x:28.97225,y:-80.896173},
{x:44.973949,y:-93.229164},
{x:34.981414,y:-81.969678},
{x:40.556458,y:-75.580868},
{x:40.45864,y:-85.49689578},
{x:33.32713631,y:-111.6876369},
{x:40.56786,y:-111.8914},
{x:33.46525,y:-86.79365035},
{x:33.48785,y:-101.90467},
{x:41.690281,y:-91.599873},
{x:33.907958,y:-84.822658},
{x:34.81858,y:-82.41284559},
{x:34.19093646,y:-79.8326142},
{x:35.97613,y:-78.89914},
{x:42.59106,y:-114.46082},
{x:32.865668,y:-79.784116},
{x:35.27840103,y:-93.09525053},
{x:40.845533,y:-74.467014},
{x:34.96213,y:-89.99409},
{x:32.363207,y:-86.295225},
{x:29.66089527,y:-95.71967056},
{x:28.356557,y:-80.686331},
{x:34.123358,y:-83.60473},
{x:33.7278,y:-84.7459},
{x:41.468406,y:-75.636144},
{x:38.9249219,y:-77.2397968},
{x:38.8862,y:-77.4418},
{x:38.80266,y:-97.612084},
{x:34.785072,y:-86.945381},
{x:41.962563,y:-87.807608},
{x:32.326801,y:-90.302169},
{x:33.378674,y:-111.721635},
{x:32.57604,y:-97.13241},
{x:38.34868,y:-77.055467},
{x:37.3039,y:-79.9638},
{x:33.514233,y:-88.430416},
{x:40.611778,y:-111.98069},
{x:33.62114137,y:-111.892216},
{x:33.67848,y:-117.663556},
{x:28.265878,y:-80.734844},
{x:38.869652,y:-76.848776},
{x:32.766108,y:-97.444801},
{x:45.66632706,y:-111.04775371},
{x:39.422149,y:-104.879211},
{x:40.747374,y:-73.421231},
{x:33.351662,y:-84.128558},
{x:38.89635,y:-104.83427},
{x:18.14287596,y:-65.80829361},
{x:34.849019,y:-82.333888},
{x:34.64038,y:-79.00713},
{x:35.248717,y:-81.668065},
{x:33.977065,y:-118.370675},
{x:29.610609,y:-98.507877},
{x:26.169723,y:-81.794022},
{x:37.352056,y:-121.958824},
{x:31.17334653,y:-83.76237863},
{x:32.914123,y:-117.144314},
{x:40.757902,y:-73.989546},
{x:41.70019,y:-88.313757},
{x:41.07547,y:-81.5126802},
{x:35.96714,y:-78.54277},
{x:29.158779,y:-82.173014},
{x:28.090948,y:-81.977576},
{x:35.2252,y:-80.8393},
{x:30.682784,y:-83.217629},
{x:30.36564,y:-87.16983},
{x:40.080916,y:-82.8515},
{x:37.369168,y:-122.038434},
{x:39.634407,y:-84.22286},
{x:30.053079,y:-95.696924},
{x:38.353791,y:-81.637626},
{x:44.104106,y:-121.297863},
{x:39.766881,y:-86.159146},
{x:34.11641,y:-80.88372},
{x:28.094569,y:-81.974705},
{x:32.05451,y:-84.21759296},
{x:32.257192,y:-90.416314},
{x:30.879888,y:-83.296921},
{x:35.104851,y:-80.879773},
{x:32.85812859,y:-96.76951598},
{x:37.65301,y:-97.428603},
{x:34.14497,y:-118.255302},
{x:32.37262,y:-96.86361},
{x:33.596771,y:-111.980569},
{x:33.907649,y:-84.269105},
{x:26.19619,y:-98.28307},
{x:32.85743406,y:-97.18321388},
{x:39.369083,y:-84.371579},
{x:32.073795,y:-84.221341},
{x:40.07264,y:-83.130557},
{x:39.68680926,y:-75.67819563},
{x:31.99363,y:-102.09934},
{x:34.06222,y:-84.39946},
{x:40.814125,y:-96.65434},
{x:34.28911,y:-85.18724754},
{x:36.69558,y:-79.87353},
{x:35.95731,y:-86.81365},
{x:27.938822,y:-82.506208},
{x:34.252249,y:-84.473106},
{x:26.274038,y:-81.693244},
{x:40.7260895,y:-73.4933545},
{x:33.75262,y:-84.38596305},
{x:41.382759,y:-81.735328},
{x:38.979051,y:-76.734949},
{x:26.36961,y:-98.794841},
{x:32.62930959,y:-116.9671784},
{x:39.948593,y:-83.832419},
{x:42.261567,y:-85.177342},
{x:38.97454,y:-76.27652},
{x:34.924042,y:-120.425116},
{x:32.753166,y:-97.352899},
{x:33.22631,y:-97.12821136},
{x:39.984027,y:-83.026704},
{x:37.340849,y:-76.738163},
{x:26.58736,y:-80.06259},
{x:27.76583471,y:-98.04457607},
{x:26.18789,y:-98.23294},
{x:36.06958,y:-80.30034},
{x:39.71845222,y:-86.35411403},
{x:35.984473,y:-86.553663},
{x:30.78894,y:-81.651167},
{x:33.95743,y:-84.0577},
{x:26.00848031,y:-80.31036302},
{x:36.877639,y:-76.193093},
{x:29.70561,y:-95.42531},
{x:41.245562,y:-111.969279},
{x:33.74482,y:-90.73536304},
{x:41.67572,y:-83.702428},
{x:33.76261,y:-96.579429},
{x:42.289157,y:-71.803997},
{x:34.134416,y:-117.393688},
{x:26.258254,y:-80.099407},
{x:34.95319,y:-85.24515},
{x:40.542473,y:-111.98364},
{x:30.28424321,y:-89.75304364},
{x:33.8541,y:-118.13618},
{x:32.567046,y:-97.319779},
{x:35.505135,y:-82.524182},
{x:32.498774,y:-92.059852},
{x:34.898413,y:-95.75609},
{x:36.156404,y:-86.795173},
{x:35.83794,y:-83.57177},
{x:35.135092,y:-89.995215},
{x:39.601554,y:-77.728924},
{x:35.09248,y:-92.46322},
{x:38.031,y:-84.502},
{x:33.795682,y:-84.285792},
{x:28.534263,y:-82.508686},
{x:30.52217,y:-91.194232},
{x:32.33476,y:-95.2863544},
{x:39.695108,y:-104.865404},
{x:33.84112,y:-83.90809},
{x:39.758366,y:-94.782565},
{x:27.495418,y:-82.604462},
{x:36.193039,y:-86.800474},
{x:34.257534,y:-118.537357},
{x:35.82305,y:-90.66827},
{x:44.86117,y:-93.31051},
{x:41.40677,y:-75.65589288},
{x:33.681785,y:-84.42396},
{x:31.068201,y:-97.369486},
{x:43.09625,y:-75.84169},
{x:36.091399,y:-80.286053},
{x:29.942212,y:-95.717205},
{x:40.05743419,y:-74.15706106},
{x:37.997488,y:-84.581442},
{x:33.571939,y:-117.723969},
{x:34.048438,y:-84.288543},
{x:34.047028,y:-85.23509},
{x:38.810952,y:-104.822707},
{x:32.911125,y:-96.736918},
{x:28.193891,y:-82.524103},
{x:39.25003226,y:-76.66924065},
{x:36.06067098,y:-89.39464098},
{x:40.51914,y:-85.66366777},
{x:37.250063,y:-121.877968},
{x:35.948665,y:-95.885375},
{x:34.171206,y:-84.786331},
{x:26.1171729,y:-80.2547408},
{x:40.768,y:-111.901},
{x:32.94970453,y:-96.46293576},
{x:31.35109031,y:-89.24347227},
{x:41.02461,y:-80.63287},
{x:28.66678,y:-81.37734},
{x:37.107857,y:-76.495207},
{x:36.17584,y:-85.50387016},
{x:42.79018874,y:-78.81151362},
{x:31.735633,y:-95.60794},
{x:42.971951,y:-85.77575},
{x:37.583542,y:-77.495311},
{x:32.44109878,y:-80.70442828},
{x:41.879699,y:-87.624679},
{x:36.550904,y:-82.528565},
{x:33.760124,y:-84.387204},
{x:34.30413,y:-83.81672286},
{x:38.555426,y:-76.597051},
{x:32.882475,y:-96.963518},
{x:35.88147,y:-86.38316},
{x:36.61568,y:-88.32148},
{x:35.477811,y:-81.226505},
{x:36.13265,y:-79.856528},
{x:41.56308,y:-90.57416},
{x:36.18802801,y:-86.29705268},
{x:38.016773,y:-84.462427},
{x:36.35005,y:-82.39966},
{x:35.1756,y:-80.65354},
{x:38.47587,y:-77.4223},
{x:39.25406,y:-76.71310499},
{x:31.60302,y:-97.10577},
{x:39.668076,y:-104.94029},
{x:30.072105,y:-90.49607},
{x:38.773204,y:-77.134209},
{x:38.343465,y:-75.607174},
{x:40.69863,y:-89.61521003},
{x:26.096077,y:-80.25012},
{x:40.252896,y:-74.039051},
{x:35.5463955,y:-77.40439802},
{x:40.044317,y:-75.379566},
{x:27.610562,y:-99.471608},
{x:34.76036047,y:-84.99756854},
{x:41.247032,y:-75.880424},
{x:32.67744,y:-97.41563},
{x:31.7721,y:-106.5040666},
{x:39.192978,y:-75.546566},
{x:40.190575,y:-77.191238},
{x:33.609588,y:-112.161259},
{x:36.08963,y:-80.22547},
{x:18.4893595,y:-66.782693},
{x:41.149703,y:-74.18797},
{x:38.79824,y:-77.31711},
{x:27.9469551,y:-82.5273477},
{x:29.66479503,y:-95.09751015},
{x:35.48077316,y:-97.76080419},
{x:37.582395,y:-77.497227},
{x:32.607369,y:-97.112785},
{x:33.751258,y:-84.385418},
{x:35.37173,y:-80.717495},
{x:38.90631,y:-77.041856},
{x:33.82544,y:-84.36765},
{x:32.869635,y:-96.773483},
{x:33.98325,y:-81.096},
{x:39.936123,y:-82.790767},
{x:33.98656206,y:-84.27054573},
{x:40.23807253,y:-75.57687052},
{x:47.890059,y:-97.074769},
{x:37.73951,y:-84.30033467},
{x:35.65849,y:-88.828828},
{x:32.460224,y:-86.41259},
{x:34.472167,y:-117.343464},
{x:33.802892,y:-116.387679},
{x:40.365458,y:-80.109059},
{x:34.12029335,y:-84.74583165},
{x:29.56567,y:-98.58555},
{x:28.205548,y:-82.664083},
{x:35.36043,y:-96.93246129},
{x:33.995868,y:-117.557921},
{x:30.369887,y:-87.682392},
{x:35.90679,y:-79.05227299},
{x:38.598781,y:-121.417485},
{x:41.51649154,y:-87.47058655},
{x:29.89221,y:-95.063824},
{x:34.94078,y:-80.96672},
{x:34.92476,y:-82.44035717},
{x:30.190266,y:-85.674371},
{x:29.64693,y:-82.34133768},
{x:42.50065889,y:-71.12445943},
{x:37.07916,y:-88.68831},
{x:30.41904,y:-86.60971},
{x:31.319,y:-89.33},
{x:33.9421647,y:-118.05059645},
{x:33.850126,y:-84.207096},
{x:29.65692,y:-82.40995},
{x:33.526533,y:-117.149493},
{x:40.397947,y:-75.926829},
{x:40.12803564,y:-75.40358209},
{x:33.17215921,y:-86.28263076},
{x:30.27798784,y:-81.71961571},
{x:39.37494,y:-76.46772},
{x:33.752123,y:-84.38217},
{x:38.43311933,y:-78.84603373},
{x:39.883115,y:-75.542323},
{x:32.508524,y:-84.943677},
{x:35.21863,y:-89.734701},
{x:38.74344,y:-121.22575},
{x:29.64838,y:-82.34516752},
{x:38.9472,y:-104.803},
{x:40.7737,y:-73.95452},
{x:29.638735,y:-98.456609},
{x:39.629435,y:-84.195041},
{x:32.51147637,y:-92.1569226},
{x:39.54383,y:-104.9453},
{x:33.462698,y:-112.341535},
{x:38.899088,y:-76.985096},
{x:33.74900793,y:-78.96067255},
{x:30.268425,y:-97.743156},
{x:36.81486,y:-76.07262},
{x:28.03578,y:-80.64816},
{x:29.48911,y:-98.55078},
{x:29.510351,y:-98.577968},
{x:39.918309,y:-75.140486},
{x:33.75779,y:-84.3945},
{x:40.535595,y:-74.296194},
{x:36.53281,y:-87.35395314},
{x:34.07071,y:-84.16638},
{x:33.47095,y:-81.98192299},
{x:25.954504,y:-97.502612},
{x:39.70680555,y:-105.0801353},
{x:40.986234,y:-111.906355},
{x:34.22,y:-83.86269},
{x:33.998573,y:-84.704414},
{x:29.48480159,y:-98.34992406},
{x:30.03630253,y:-95.55307226},
{x:35.24982712,y:-91.68433138},
{x:30.419029,y:-97.703338},
{x:34.890595,y:-82.217456},
{x:37.311296,y:-79.88589},
{x:40.61948,y:-79.15455127},
{x:38.956877,y:-77.002795},
{x:27.810916,y:-82.638207},
{x:35.059746,y:-80.813444},
{x:30.011815,y:-97.862525},
{x:45.198966,y:-93.357461},
{x:34.46039,y:-93.061279},
{x:40.742266,y:-73.992996},
{x:38.58988,y:-89.98545},
{x:37.791578,y:-81.212728},
{x:37.416,y:-121.95323},
{x:25.812027,y:-80.356416},
{x:39.99774213,y:-104.9746444},
{x:36.062248,y:-115.171982},
{x:35.52511,y:-97.54447},
{x:41.73954,y:-72.719579},
{x:33.93406,y:-84.35003},
{x:31.99885,y:-102.15345},
{x:36.00741,y:-84.01733},
{x:35.07789,y:-92.4578777},
{x:44.727734,y:-93.21719},
{x:34.03659,y:-118.09823},
{x:32.242903,y:-80.825942},
{x:38.16744,y:-84.89959},
{x:39.531663,y:-119.715986},
{x:29.550197,y:-95.090898},
{x:35.875375,y:-78.623941},
{x:38.66076,y:-121.3554},
{x:30.401113,y:-97.744943},
{x:38.67328321,y:-90.45821493},
{x:30.0197,y:-95.4356},
{x:29.86955,y:-81.32269},
{x:40.556082,y:-74.296987},
{x:36.069027,y:-79.269922},
{x:29.802844,y:-94.998995},
{x:26.4220033,y:-81.81214254},
{x:30.32103,y:-81.45792},
{x:39.022797,y:-76.692822},
{x:38.939154,y:-121.096609},
{x:33.590725,y:-86.696229},
{x:33.86223,y:-118.09146},
{x:35.030082,y:-80.966288},
{x:41.13463011,y:-81.643077},
{x:37.255819,y:-121.792099},
{x:40.444618,y:-74.501325},
{x:32.28981,y:-110.978795},
{x:32.429265,y:-85.707713},
{x:35.0481,y:-85.28925448},
{x:39.408397,y:-104.858908},
{x:33.4453,y:-112.0667},
{x:31.62064,y:-94.64995213},
{x:30.111916,y:-97.352366},
{x:26.18212,y:-97.72047},
{x:30.00934,y:-95.27038},
{x:32.62042042,y:-85.40514326},
{x:34.302459,y:-86.278047},
{x:35.307041,y:-80.752379},
{x:27.418772,y:-80.381327},
{x:29.9047246,y:-95.55265519},
{x:32.389945,y:-94.869557},
{x:35.861142,y:-78.708701},
{x:41.92242,y:-71.356976},
{x:30.25502076,y:-81.39153124},
{x:43.976644,y:-75.947287},
{x:34.139918,y:-118.017742},
{x:27.89435035,y:-82.68055431},
{x:30.00349486,y:-90.17130239},
{x:34.841253,y:-82.359716},
{x:40.974799,y:-73.834082},
{x:31.62943,y:-94.652113},
{x:35.90103,y:-78.790525},
{x:27.96456,y:-82.52069},
{x:33.247141,y:-84.278263},
{x:32.52679,y:-92.64847205},
{x:36.336136,y:-78.438617},
{x:43.60127,y:-116.2015712},
{x:34.033118,y:-84.051315},
{x:36.293489,y:-95.840221},
{x:39.159724,y:-78.163725},
{x:26.06801648,y:-80.36167216},
{x:27.8836789,y:-97.31782909},
{x:32.7655,y:-97.47605},
{x:31.91694,y:-102.29072},
{x:34.85923348,y:-82.26616918},
{x:28.303568,y:-81.460564},
{x:34.656979,y:-92.409815},
{x:35.352232,y:-80.850748},
{x:30.2694,y:-81.50720753},
{x:40.746875,y:-73.88351},
{x:34.84768238,y:-82.33988875},
{x:36.57743,y:-83.66107},
{x:40.33504254,y:-79.94646743},
{x:37.726752,y:-97.207335},
{x:42.896858,y:-74.096903},
{x:41.02489,y:-80.67872},
{x:36.941187,y:-86.419987},
{x:32.742543,y:-96.44527},
{x:30.632541,y:-97.697145},
{x:41.872213,y:-87.647967},
{x:35.993441,y:-80.029805},
{x:38.258824,y:-122.059794},
{x:30.10176,y:-81.71094},
{x:33.619062,y:-84.351772},
{x:32.754056,y:-117.21182},
{x:41.8392858,y:-87.99855345},
{x:33.816702,y:-118.184529},
{x:32.86757509,y:-117.2315702},
{x:38.93944,y:-76.90913},
{x:37.68874,y:-121.90308},
{x:31.837961,y:-106.56679},
{x:28.0696041,y:-82.5768732},
{x:39.730249,y:-105.16496},
{x:30.37661,y:-86.34965},
{x:31.6882132,y:-106.26502615},
{x:40.459191,y:-78.408802},
{x:38.79186,y:-89.99770235},
{x:35.901544,y:-78.957799},
{x:41.238012,y:-81.810398},
{x:32.880225,y:-111.682801},
{x:36.195461,y:-115.242731},
{x:36.163816,y:-86.785507},
{x:40.4127,y:-79.907871},
{x:39.143862,y:-76.648235},
{x:33.01277,y:-97.07153},
{x:39.40378,y:-76.59928},
{x:40.52719051,y:-111.8807404},
{x:34.010193,y:-118.480083},
{x:44.852048,y:-93.432723},
{x:26.80784,y:-80.08058},
{x:41.15584,y:-80.08139},
{x:33.58015,y:-101.8835562},
{x:35.739,y:-81.321},
{x:39.760048,y:-75.131261},
{x:38.83148,y:-77.30926762},
{x:43.196246,y:-77.585395},
{x:33.499388,y:-117.659247},
{x:33.786838,y:-84.382566},
{x:37.06252,y:-76.4941601},
{x:27.26867348,y:-82.4631645},
{x:33.656427,y:-84.501691},
{x:29.703011,y:-95.643411},
{x:34.447787,y:-83.129405},
{x:36.278065,y:-80.834742},
{x:42.19845801,y:-83.25069898},
{x:28.48558,y:-81.43142},
{x:34.01328735,y:-81.15226291},
{x:37.99944,y:-84.520159},
{x:39.831513,y:-75.097013},
{x:35.171698,y:-80.849907},
{x:39.152708,y:-77.213329},
{x:33.57689,y:-101.892485},
{x:35.592628,y:-87.052409},
{x:39.15937,y:-78.17008},
{x:27.492205,y:-97.847592},
{x:39.435938,y:-74.611474},
{x:30.158144,y:-92.04605},
{x:33.11561,y:-95.59551},
{x:29.68884,y:-95.46512},
{x:43.153018,y:-76.12366443},
{x:38.95462,y:-77.36654},
{x:33.454939,y:-88.805558},
{x:33.17457,y:-96.84114},
{x:33.986905,y:-78.363263},
{x:35.383885,y:-119.102643},
{x:36.133,y:-86.673},
{x:34.24907504,y:-80.64595464},
{x:47.06207,y:-122.764497},
{x:28.253728,y:-81.315749},
{x:38.02912,y:-78.44042},
{x:27.515851,y:-99.449588},
{x:35.221313,y:-80.943852},
{x:41.155016,y:-81.863393},
{x:33.44685899,y:-86.82348186},
{x:40.407792,y:-105.005949},
{x:32.21789,y:-98.2185967},
{x:40.726865,y:-74.038436},
{x:40.00747,y:-75.212361},
{x:34.19052,y:-79.83546},
{x:34.008692,y:-85.032233},
{x:29.968422,y:-90.106305},
{x:30.45771314,y:-90.95699679},
{x:30.185803,y:-81.553483},
{x:40.856706,y:-81.427126},
{x:39.54876718,y:-104.7735616},
{x:39.270674,y:-80.284892},
{x:38.582264,y:-77.329178},
{x:39.905968,y:-74.976588},
{x:35.027548,y:-79.050468},
{x:39.1244263,y:-77.2373402},
{x:32.075862,y:-96.445875},
{x:39.99728,y:-75.092722},
{x:32.539305,y:-94.750908},
{x:35.976766,y:-77.809772},
{x:36.73697,y:-84.16516},
{x:26.172,y:-98.0066},
{x:30.52633,y:-97.81443},
{x:47.478747,y:-122.21705},
{x:34.73475452,y:-80.78751227},
{x:32.368854,y:-86.270469},
{x:35.48262,y:-80.8809},
{x:38.552242,y:-90.407668},
{x:41.626356,y:-81.435396},
{x:36.069263,y:-79.496893},
{x:35.90669,y:-83.838015},
{x:28.044,y:-82.422},
{x:39.18525,y:-96.55794},
{x:38.418229,y:-77.418997},
{x:35.2746,y:-81.5281},
{x:32.437934,y:-81.761084},
{x:29.39580318,y:-94.94868762},
{x:43.02616,y:-85.690352},
{x:32.96218,y:-97.04125},
{x:31.49067,y:-82.851475},
{x:40.779167,y:-73.953777},
{x:39.8722,y:-75.24307704},
{x:38.09835,y:-85.66991},
{x:32.908139,y:-96.575092},
{x:37.05978,y:-84.62227},
{x:33.37677,y:-91.040306},
{x:32.163579,y:-110.988917},
{x:29.828942,y:-95.380394},
{x:30.61021466,y:-96.34864035},
{x:26.24361,y:-98.44001},
{x:35.59368,y:-80.86228},
{x:33.505214,y:-84.141657},
{x:33.53161,y:-101.92301},
{x:39.182492,y:-77.26107},
{x:39.39368,y:-76.61119316},
{x:32.328272,y:-95.341893},
{x:33.84575792,y:-79.06463},
{x:33.08078,y:-83.23133253},
{x:41.545123,y:-83.592685},
{x:29.98468451,y:-91.820104},
{x:39.99135605,y:-83.15129951},
{x:45.013123,y:-93.46238},
{x:30.07366,y:-95.50942},
{x:32.5248233,y:-94.78080295},
{x:28.43211,y:-81.3085},
{x:35.525882,y:-82.606538},
{x:34.879042,y:-83.957465},
{x:29.28873,y:-94.79035},
{x:39.15868,y:-76.893994},
{x:31.318614,y:-85.829851},
{x:32.130152,y:-94.809221},
{x:39.01893,y:-94.66712},
{x:41.147797,y:-73.249117},
{x:36.31096,y:-82.38829},
{x:29.85538,y:-97.9538},
{x:33.22455,y:-96.9827},
{x:45.757471,y:-108.5767},
{x:41.892449,y:-87.961792},
{x:34.98244,y:-101.9171234},
{x:33.890749,y:-84.467814},
{x:45.19419535,y:-93.23559128},
{x:36.03751792,y:-114.99001158},
{x:42.054128,y:-80.087537},
{x:35.493049,y:-86.457819},
{x:36.06123,y:-95.85212},
{x:37.70018,y:-121.90591},
{x:27.006883,y:-82.132236},
{x:30.531641,y:-87.223666},
{x:35.354658,y:-119.060492},
{x:35.48066,y:-97.50178401},
{x:44.746938,y:-93.294145},
{x:35.867012,y:-78.57556},
{x:32.485442,y:-82.935637},
{x:33.377463,y:-112.030622},
{x:39.09071577,y:-77.17822953},
{x:32.6788843,y:-97.3985674},
{x:39.050336,y:-76.511412},
{x:32.83211,y:-96.8391},
{x:36.3401,y:-88.86447713},
{x:33.19185614,y:-97.09733751},
{x:33.030912,y:-80.058513},
{x:30.56311,y:-97.84662},
{x:40.451043,y:-80.175166},
{x:42.70185,y:-71.43734},
{x:43.09198804,y:-76.31932071},
{x:34.12605,y:-93.05037003},
{x:30.55986,y:-97.68511},
{x:35.915568,y:-86.836731},
{x:33.95,y:-83.373},
{x:33.946537,y:-118.401148},
{x:39.246203,y:-94.653981},
{x:27.313759,y:-80.397365},
{x:33.465439,y:-112.278349},
{x:40.488277,y:-79.879696},
{x:39.791499,y:-105.080155},
{x:32.650493,y:-97.368985},
{x:38.784525,y:-90.532093},
{x:38.602481,y:-90.435532},
{x:40.001584,y:-83.008137},
{x:40.74369,y:-89.63136},
{x:39.096613,y:-84.265658},
{x:32.928275,y:-97.085342},
{x:30.68079,y:-88.22502},
{x:41.190501,y:-111.982187},
{x:41.95257503,y:-70.71144587},
{x:33.898435,y:-84.30264},
{x:41.829796,y:-88.096077},
{x:36.109442,y:-115.172411},
{x:40.35183,y:-94.88352481},
{x:43.87383,y:-91.188401},
{x:39.73665,y:-75.62632},
{x:33.526566,y:-86.720411},
{x:36.1047,y:-95.9197},
{x:37.637052,y:-77.550166},
{x:26.458197,y:-80.119456},
{x:29.950251,y:-90.076451},
{x:29.729756,y:-95.42087},
{x:30.41196,y:-97.85025},
{x:33.846461,y:-84.362309},
{x:32.892263,y:-117.202063},
{x:41.572164,y:-87.636956},
{x:33.907855,y:-84.205602},
{x:40.07066,y:-80.866563},
{x:35.082333,y:-80.877212},
{x:34.60125,y:-118.14485},
{x:38.959407,y:-92.372112},
{x:33.491882,y:-82.078079},
{x:44.88263,y:-93.20622},
{x:29.613263,y:-90.756568},
{x:27.71008,y:-97.368657},
{x:29.96027,y:-95.53913},
{x:34.7533459,y:-92.3431449},
{x:39.816302,y:-85.769144},
{x:33.831286,y:-117.83519},
{x:36.16962619,y:-115.14599206},
{x:33.342639,y:-84.534254},
{x:39.9153764,y:-105.0419482},
{x:33.057893,y:-96.769746},
{x:36.594085,y:-79.4223},
{x:40.77449,y:-73.52883},
{x:26.849037,y:-80.08561},
{x:32.78145551,y:-96.80011979},
{x:39.321531,y:-77.852022},
{x:35.0838,y:-80.66022},
{x:27.44428,y:-82.46679},
{x:40.9040561,y:-74.0321009},
{x:38.943545,y:-77.063656},
{x:35.260395,y:-77.650628},
{x:35.0347,y:-85.1588},
{x:33.71122,y:-78.89836},
{x:35.53540508,y:-97.56612175},
{x:32.940057,y:-97.115711},
{x:40.5824,y:-74.1659},
{x:33.72548153,y:-117.788671},
{x:42.84572,y:-85.62635},
{x:28.471521,y:-81.31091},
{x:34.05717,y:-84.23288},
{x:36.07622,y:-79.7708426},
{x:28.59794,y:-81.29973},
{x:39.2022,y:-76.682699},
{x:38.92917,y:-77.032836},
{x:33.651342,y:-86.81565},
{x:38.594707,y:-90.294307},
{x:41.952112,y:-91.716702},
{x:33.937538,y:-118.12698},
{x:37.10673,y:-113.555142},
{x:33.520351,y:-101.955909},
{x:27.903377,y:-81.837692},
{x:30.257403,y:-97.807893},
{x:33.917391,y:-84.468765},
{x:39.430832,y:-75.039776},
{x:34.69183247,y:-82.83752341},
{x:36.06509,y:-80.31244},
{x:33.554351,y:-79.045791},
{x:32.00206,y:-81.11751},
{x:45.486812,y:-122.786278},
{x:36.63864789,y:-93.2739795},
{x:46.72735,y:-117.0132575},
{x:40.13800369,y:-75.31571291},
{x:42.566,y:-87.918},
{x:35.522602,y:-97.535625},
{x:38.15409984,y:-85.83604702},
{x:33.02560393,y:-97.27930914},
{x:37.51306747,y:-77.69568476},
{x:33.58304,y:-111.88882},
{x:30.69541,y:-88.17899483},
{x:33.917706,y:-84.378671},
{x:30.56172314,y:-96.25897391},
{x:33.067,y:-96.89042},
{x:35.93989,y:-83.986},
{x:40.684673,y:-75.15112},
{x:37.112458,y:-76.49488},
{x:39.710739,y:-104.821259},
{x:32.506186,y:-84.952468},
{x:38.987917,y:-76.546231},
{x:33.965662,y:-84.010867},
{x:38.9971693,y:-77.0254905},
{x:45.55255,y:-94.151926},
{x:32.728773,y:-97.388396},
{x:40.518431,y:-74.370472},
{x:34.751925,y:-92.343589},
{x:29.70627,y:-95.39700854},
{x:28.053877,y:-82.428155},
{x:37.366021,y:-121.925788},
{x:36.39018117,y:-97.93221783},
{x:27.83635987,y:-82.68228421},
{x:32.980231,y:-96.910305},
{x:30.04194,y:-94.07563},
{x:39.09763,y:-76.807757},
{x:38.81518,y:-77.64106},
{x:30.28479,y:-97.73710225},
{x:39.28876,y:-76.73085},
{x:33.92449,y:-118.1053},
{x:40.71234,y:-75.283623},
{x:38.89866,y:-77.022166},
{x:36.144667,y:-115.172875},
{x:31.75798,y:-106.27135},
{x:29.35118,y:-98.43405},
{x:37.6758,y:-113.071855},
{x:37.954742,y:-91.776411},
{x:35.08341,y:-106.6197837},
{x:26.70354,y:-80.05334299},
{x:31.978846,y:-81.162945},
{x:37.7183,y:-97.29440459},
{x:25.91,y:-80.14},
{x:30.630899,y:-96.379675},
{x:36.14023653,y:-86.87969636},
{x:27.894047,y:-82.250884},
{x:25.785475,y:-80.219108},
{x:28.017767,y:-82.73251},
{x:34.069938,y:-118.041858},
{x:42.489501,y:-83.475377},
{x:30.54234,y:-97.56151},
{x:37.829133,y:-122.287856},
{x:38.916417,y:-76.843895},
{x:29.72078,y:-95.36102312},
{x:32.64652,y:-97.18651},
{x:36.425303,y:-77.632185},
{x:36.196068,y:-81.65461},
{x:33.114562,y:-117.099725},
{x:29.613607,y:-98.603774},
{x:38.294782,y:-85.558619},
{x:34.822562,y:-82.28602},
{x:32.842345,y:-97.239265},
{x:31.775883,y:-106.379155},
{x:32.956745,y:-117.039014},
{x:45.09423,y:-93.42977},
{x:33.99629,y:-81.0271477},
{x:34.7608,y:-79.48123},
{x:38.81048,y:-90.29776},
{x:36.48174,y:-80.6145},
{x:36.001013,y:-79.906518},
{x:35.816087,y:-78.692885},
{x:40.984947,y:-73.852594},
{x:36.82462,y:-87.47311},
{x:33.92966,y:-117.427181},
{x:34.55162,y:-82.67723},
{x:36.21382,y:-81.67812802},
{x:33.80767243,y:-84.41295334},
{x:34.57267,y:-87.01577},
{x:36.74780818,y:-95.93468997},
{x:37.048506,y:-76.39072},
{x:36.034786,y:-79.963321},
{x:33.95485972,y:-80.38057935},
{x:35.925446,y:-84.038419},
{x:33.699175,y:-117.740042},
{x:40.54746,y:-74.33554},
{x:33.146424,y:-86.747662},
{x:27.45882452,y:-82.57489385},
{x:38.071746,y:-97.894305},
{x:43.112315,y:-87.917233},
{x:35.66789,y:-78.69699},
{x:26.46599,y:-81.77198235},
{x:32.540651,y:-82.948374},
{x:26.527161,y:-80.186802},
{x:33.828191,y:-84.492621},
{x:36.65855,y:-76.232117},
{x:35.201239,y:-89.787405},
{x:39.6309,y:-77.76293},
{x:40.14264203,y:-82.99033337},
{x:42.09146,y:-71.05134},
{x:42.56253,y:-83.184125},
{x:36.722999,y:-76.308139},
{x:32.70986,y:-97.36427734},
{x:39.02619372,y:-94.25067849},
{x:29.874522,y:-90.096515},
{x:32.724776,y:-97.787083},
{x:31.6913548,y:-89.136214},
{x:38.42218,y:-82.42894805},
{x:38.67032,y:-76.87712},
{x:32.77655441,y:-117.02107148},
{x:32.897605,y:-97.035862},
{x:29.5184686,y:-98.4965526},
{x:33.51772,y:-101.87013},
{x:30.06774146,y:-94.13288793},
{x:30.20944,y:-95.52896},
{x:44.484379,y:-88.068073},
{x:37.66379,y:-77.65263},
{x:33.399069,y:-86.805429},
{x:32.53265605,y:-96.82023204},
{x:39.807466,y:-74.931806},
{x:40.41815,y:-86.839487},
{x:27.794059,y:-82.729843},
{x:39.6605,y:-104.9877},
{x:28.02622,y:-82.54738},
{x:38.92719,y:-94.40146},
{x:41.070828,y:-85.199473},
{x:29.619989,y:-82.382001},
{x:32.951095,y:-96.804111},
{x:37.699152,y:-121.774044},
{x:26.240509,y:-98.244992},
{x:45.498286,y:-122.807041},
{x:27.240773,y:-80.275051},
{x:35.97202,y:-79.99470683},
{x:29.5551,y:-98.53437},
{x:44.937065,y:-93.381524},
{x:35.82928,y:-78.76979},
{x:42.433901,y:-83.434265},
{x:36.30151374,y:-86.69450441},
{x:35.20404,y:-89.79295},
{x:30.29188,y:-97.827392},
{x:30.419979,y:-91.14055},
{x:27.839185,y:-82.788413},
{x:34.01683,y:-118.28262},
{x:30.670955,y:-87.851548},
{x:36.98503,y:-86.45703504},
{x:29.46638,y:-98.62151},
{x:38.6568,y:-121.12653},
{x:37.298337,y:-81.1682823},
{x:41.46165,y:-81.851601},
{x:29.75757,y:-95.37015674},
{x:26.269395,y:-98.218214},
{x:31.731491,y:-98.973596},
{x:40.122802,y:-111.642245},
{x:42.02729,y:-91.62969},
{x:32.540945,y:-92.632713},
{x:47.22169715,y:-122.4689136},
{x:29.789372,y:-90.804711},
{x:38.893467,y:-77.274809},
{x:35.34401,y:-80.175783},
{x:38.840645,y:-104.756684},
{x:35.10121962,y:-89.85340029},
{x:34.034204,y:-84.678771},
{x:42.051084,y:-87.746448},
{x:33.19363961,y:-117.25032718},
{x:35.450218,y:-97.421199},
{x:29.706873,y:-95.402312},
{x:38.250876,y:-85.624418},
{x:32.67747,y:-97.00755},
{x:29.5505,y:-98.66298},
{x:35.165134,y:-83.370984},
{x:41.11463,y:-85.140053},
{x:29.41823,y:-98.65354},
{x:35.204919,y:-80.83744},
{x:46.861273,y:-96.850322},
{x:21.291387,y:-157.842902},
{x:27.228932,y:-82.494964},
{x:26.30289,y:-98.17595677},
{x:26.439294,y:-80.071908},
{x:33.6765,y:-96.61033},
{x:26.24710732,y:-80.25241316},
{x:38.791448,y:-77.608095},
{x:29.56307,y:-95.68691},
{x:33.79582916,y:-83.74133003},
{x:37.36952,y:-79.18364},
{x:39.177283,y:-76.668398},
{x:40.19806,y:-75.08383},
{x:33.97798,y:-84.418393},
{x:33.467504,y:-84.446535},
{x:43.47038522,y:-111.9838972},
{x:31.322513,y:-89.380683},
{x:30.472334,y:-87.209181},
{x:33.92096754,y:-84.3416518},
{x:30.519655,y:-97.648579},
{x:40.091393,y:-74.939888},
{x:37.182636,y:-93.295864},
{x:32.864577,y:-96.67697},
{x:39.91614,y:-75.3341},
{x:39.872571,y:-75.045146},
{x:33.613067,y:-85.824559},
{x:32.141448,y:-81.245726},
{x:26.351409,y:-80.111931},
{x:29.537386,y:-95.531482},
{x:36.78804,y:-75.99759},
{x:40.07676,y:-83.13215},
{x:33.457262,y:-112.08219},
{x:35.504356,y:-82.990464},
{x:35.04836,y:-85.29874631},
{x:33.926139,y:-118.395974},
{x:39.77393,y:-86.17632426},
{x:34.96931,y:-80.99373},
{x:33.306459,y:-111.888513},
{x:39.77153107,y:-76.6712921},
{x:39.9122,y:-86.218984},
{x:31.06818,y:-97.4658923},
{x:31.754485,y:-93.098186},
{x:36.08956,y:-79.89037},
{x:33.750774,y:-84.7266},
{x:45.615843,y:-122.503762},
{x:39.161843,y:-86.495656},
{x:47.15463,y:-122.2934},
{x:30.69285516,y:-88.041192},
{x:33.578895,y:-83.962079},
{x:36.06692,y:-79.51932},
{x:33.22603,y:-97.127861},
{x:42.296842,y:-85.647634},
{x:42.365221,y:-89.028734},
{x:39.65348628,y:-104.9001487},
{x:38.602919,y:-90.586011},
{x:36.031328,y:-80.261215},
{x:38.43405,y:-78.8707},
{x:29.652264,y:-82.339545},
{x:41.780612,y:-87.867863},
{x:40.30666,y:-79.580161},
{x:32.23246,y:-110.9516578},
{x:28.54604,y:-81.70817},
{x:40.560039,y:-74.264628},
{x:35.749361,y:-81.335304},
{x:35.819379,y:-78.621486},
{x:40.116507,y:-75.282079},
{x:38.66876,y:-90.60475},
{x:38.210764,y:-85.538146},
{x:39.010322,y:-77.493792},
{x:30.414231,y:-97.67212},
{x:36.029167,y:-80.381916},
{x:45.127812,y:-93.257676},
{x:29.992511,y:-95.749609},
{x:39.909451,y:-86.1017},
{x:33.51341,y:-112.1307023},
{x:38.93557,y:-76.99835137},
{x:34.068416,y:-117.556469},
{x:40.68496708,y:-80.08247247},
{x:33.55465,y:-85.07255},
{x:35.913635,y:-81.524537},
{x:34.092604,y:-84.276526},
{x:34.93630272,y:-81.98847554},
{x:33.64106278,y:-97.15424338},
{x:34.082224,y:-118.3094},
{x:33.93849,y:-83.456329},
{x:34.262566,y:-85.170083},
{x:42.996377,y:-88.047552},
{x:30.503966,y:-90.498935},
{x:40.23836,y:-75.24289},
{x:38.97865,y:-77.34885},
{x:38.92258,y:-77.0209},
{x:33.149192,y:-96.805189},
{x:39.60497,y:-75.789923},
{x:30.387426,y:-86.470444},
{x:32.764,y:-96.896},
{x:32.82682637,y:-97.06019626},
{x:39.397219,y:-76.481463},
{x:40.262505,y:-75.320956},
{x:38.980632,y:-76.536871},
{x:38.21361,y:-85.59427},
{x:34.0625,y:-117.59152},
{x:33.560688,y:-117.203453},
{x:36.06160843,y:-95.78128149},
{x:33.42783,y:-86.70817},
{x:35.012303,y:-85.212199},
{x:42.740308,y:-84.630293},
{x:33.281638,y:-86.852068},
{x:35.381423,y:-77.932151},
{x:29.81832987,y:-94.89909889},
{x:32.46788,y:-90.13071},
{x:39.77422384,y:-85.99235265},
{x:37.540095,y:-77.428473},
{x:34.66077552,y:-86.48341558},
{x:33.609191,y:-112.414508},
{x:38.359477,y:-77.517705},
{x:32.471,y:-99.735},
{x:38.058489,y:-78.944467},
{x:30.60179,y:-87.89742},
{x:43.184863,y:-76.234458},
{x:18.40558519,y:-66.16404236},
{x:31.43076,y:-100.45557},
{x:33.847101,y:-117.990923},
{x:35.04941774,y:-89.82745038},
{x:33.5063,y:-84.22856},
{x:35.20968,y:-97.44438062},
{x:42.00458,y:-88.336931},
{x:30.4815,y:-97.67186},
{x:38.85333543,y:-76.91132677},
{x:32.539574,y:-84.869067},
{x:35.55682,y:-97.637231},
{x:33.924646,y:-83.340424},
{x:38.18799,y:-83.43063115},
{x:38.21729,y:-85.75620204},
{x:29.5254,y:-98.6002},
{x:34.219964,y:-118.605669},
{x:36.02723,y:-86.79091},
{x:41.419374,y:-82.209388},
{x:38.581538,y:-77.31117},
{x:37.736703,y:-84.327993},
{x:39.88941661,y:-75.32687117},
{x:30.35175,y:-81.60625809},
{x:33.21955,y:-96.87315},
{x:42.861968,y:-73.779699},
{x:27.92268,y:-82.32105},
{x:30.083497,y:-95.447318},
{x:33.07113,y:-97.05822},
{x:33.549877,y:-80.829366},
{x:38.75151299,y:-77.4499791},
{x:40.50131,y:-74.8548},
{x:28.98937071,y:-95.92791545},
{x:34.1347,y:-118.0508},
{x:38.945428,y:-77.451636},
{x:41.63874982,y:-72.87356755},
{x:30.51407,y:-90.46674774},
{x:36.12161,y:-83.48925055},
{x:34.123381,y:-81.215241},
{x:35.263331,y:-81.133005},
{x:34.72892,y:-92.22043},
{x:32.60598103,y:-97.41104234},
{x:31.88275306,y:-85.1464548},
{x:32.39894,y:-90.13692},
{x:30.444952,y:-90.081484},
{x:42.15172,y:-87.833989},
{x:43.058,y:-89.50353},
{x:33.72897,y:-84.94067},
{x:32.829054,y:-96.70871},
{x:33.686347,y:-117.920096},
{x:29.6043,y:-98.279},
{x:33.806944,y:-84.393503},
{x:34.286165,y:-81.62218},
{x:33.015499,y:-97.218382},
{x:32.30055,y:-95.302761},
{x:27.998065,y:-80.637146},
{x:33.4711,y:-81.9878},
{x:39.102631,y:-75.543137},
{x:38.33619,y:-75.1029},
{x:40.378896,y:-111.916965},
{x:33.944936,y:-117.453417},
{x:33.754941,y:-84.415652},
{x:34.981264,y:-78.961189},
{x:38.91982,y:-76.9536},
{x:40.956193,y:-74.068785},
{x:39.74620547,y:-105.0537216},
{x:32.89915658,y:-97.3227335},
{x:32.842006,y:-79.855987},
{x:40.140274,y:-75.123094},
{x:34.123007,y:-84.525486},
{x:29.50704,y:-95.0385},
{x:35.190834,y:-111.660321},
{x:33.877999,y:-84.532987},
{x:27.89514396,y:-82.77825015},
{x:39.244109,y:-84.594442},
{x:33.02901,y:-96.83591},
{x:40.055839,y:-84.241568},
{x:33.904909,y:-80.378534},
{x:40.2534547,y:-74.301073},
{x:38.944442,y:-76.716922},
{x:33.23849,y:-87.572864},
{x:37.02147,y:-76.33450704},
{x:29.40022,y:-98.39095},
{x:38.763124,y:-90.78483},
{x:33.2912,y:-93.23512304},
{x:39.63159,y:-78.83809},
{x:33.94827716,y:-83.75380987},
{x:27.546643,y:-99.502532},
{x:32.03085,y:-102.130734},
{x:39.585242,y:-105.02413},
{x:41.710663,y:-86.180486},
{x:31.24431,y:-85.4283},
{x:35.000414,y:-80.695072},
{x:33.953276,y:-84.234707},
{x:32.91363,y:-96.95803},
{x:28.597099,y:-81.223217},
{x:40.29952,y:-111.69665},
{x:41.75821915,y:-88.20646193},
{x:41.92547975,y:-87.676242},
{x:30.61611,y:-96.31963},
{x:33.88382977,y:-118.02709395},
{x:31.21157,y:-81.49073},
{x:32.5452,y:-84.945193},
{x:33.785772,y:-117.867566},
{x:28.23089,y:-80.71637},
{x:28.449701,y:-81.403723},
{x:32.53755,y:-83.89532458},
{x:26.37137,y:-80.10354534},
{x:29.422136,y:-98.78374},
{x:38.862381,y:-77.387608},
{x:35.90716013,y:-84.10653434},
{x:39.48176,y:-88.17560142},
{x:37.494107,y:-122.235677},
{x:35.5197,y:-78.31018},
{x:31.96104427,y:-83.75444527},
{x:34.9701,y:-81.88762},
{x:40.765091,y:-73.983868},
{x:35.358041,y:-89.893245},
{x:33.638318,y:-112.352559},
{x:31.516372,y:-97.187127},
{x:33.35835418,y:-86.85449279},
{x:39.758431,y:-84.052994},
{x:47.16692,y:-122.509694},
{x:34.133809,y:-117.636589},
{x:37.356424,y:-77.418254},
{x:42.102682,y:-80.136491},
{x:35.13454,y:-78.87230904},
{x:30.58548,y:-96.324},
{x:37.27309,y:-76.71098},
{x:39.91443734,y:-104.9708406},
{x:40.287986,y:-74.053853},
{x:29.355126,y:-98.52955},
{x:42.02036247,y:-93.60984579},
{x:33.421667,y:-111.940894},
{x:29.98668,y:-95.34488},
{x:39.595656,y:-104.879663},
{x:35.03332,y:-80.80499},
{x:42.78621,y:-96.925933},
{x:32.8296,y:-83.65130955},
{x:38.2480899,y:-85.6219333},
{x:47.44783592,y:-122.26309075},
{x:41.606724,y:-87.852554},
{x:32.348145,y:-86.222654},
{x:39.18809,y:-96.58266985},
{x:39.1912,y:-76.613004},
{x:32.578972,y:-117.03509},
{x:34.079366,y:-83.91773},
{x:48.2382,y:-114.33125},
{x:35.148319,y:-89.905465},
{x:39.976332,y:-76.670164},
{x:25.98002604,y:-80.35840965},
{x:34.69454597,y:-86.5676963},
{x:40.21845576,y:-85.39191795},
{x:39.871886,y:-84.136692},
{x:30.209199,y:-95.426672},
{x:30.38858077,y:-91.15898347},
{x:33.74253,y:-84.35862},
{x:28.591659,y:-81.364654},
{x:39.390166,y:-84.50028},
{x:35.40803,y:-78.73863133},
{x:40.020414,y:-74.943891},
{x:39.38287,y:-77.40017},
{x:43.031788,y:-88.107279},
{x:28.911789,y:-81.280239},
{x:34.059,y:-118.419},
{x:39.891201,y:-75.089489},
{x:40.01659766,y:-75.68512783},
{x:42.025984,y:-88.145044},
{x:40.24179,y:-76.80287},
{x:30.262597,y:-81.623234},
{x:29.154793,y:-82.181191},
{x:40.725213,y:-111.856656},
{x:40.290447,y:-74.049966},
{x:33.08062116,y:-96.85013594},
{x:27.16866,y:-80.229607},
{x:35.58562,y:-77.368695},
{x:38.306639,y:-85.842012},
{x:29.541727,y:-95.389215},
{x:40.6353535,y:-111.8857044},
{x:34.076239,y:-80.94561},
{x:33.14509,y:-96.89139},
{x:44.892674,y:-93.080057},
{x:38.486028,y:-82.66129},
{x:40.734687,y:-73.868779},
{x:33.45361,y:-112.07155},
{x:35.70816,y:-81.30802},
{x:33.786806,y:-118.072503},
{x:38.894347,y:-77.430185},
{x:26.669752,y:-81.935465},
{x:28.85226,y:-96.99678},
{x:41.263778,y:-96.10544},
{x:32.028148,y:-102.147629},
{x:26.257563,y:-98.316815},
{x:46.7985347,y:-100.78743039},
{x:32.31509,y:-95.25109911},
{x:47.910459,y:-122.217185},
{x:39.99413179,y:-85.92749257},
{x:33.44943,y:-84.32598},
{x:38.2920192,y:-77.5119448},
{x:18.38390397,y:-66.13995608},
{x:33.295197,y:-84.546796},
{x:33.86403373,y:-84.47759515},
{x:39.187883,y:-75.541669},
{x:34.8754,y:-82.36302503},
{x:28.33225237,y:-81.52512601},
{x:36.14395,y:-88.51737988},
{x:37.97266,y:-87.53183},
{x:32.660784,y:-117.10485},
{x:27.7112747,y:-97.374077},
{x:29.694499,y:-95.415164},
{x:40.058126,y:-82.911714},
{x:32.83781,y:-97.13297},
{x:34.214157,y:-78.020025},
{x:34.06352,y:-118.44513},
{x:38.57386,y:-75.28618},
{x:33.607691,y:-86.806582},
{x:35.15447,y:-85.248178},
{x:33.995569,y:-81.224729},
{x:39.05006,y:-84.6565636},
{x:33.505582,y:-82.502494},
{x:40.510033,y:-88.954084},
{x:30.59156,y:-98.27266},
{x:34.59505069,y:-83.76380379},
{x:38.421239,y:-82.259104},
{x:40.09369497,y:-75.36465065},
{x:42.083023,y:-87.937468},
{x:36.597895,y:-87.286253},
{x:37.674181,y:-122.469439},
{x:38.98301,y:-76.94375707},
{x:34.623798,y:-98.425559},
{x:41.496892,y:-72.809053},
{x:39.1526,y:-84.628571},
{x:37.737455,y:-85.889542},
{x:29.697264,y:-95.845329},
{x:35.565924,y:-82.626862},
{x:39.124765,y:-76.590433},
{x:30.16691877,y:-97.79266258},
{x:37.245649,y:-77.389269},
{x:32.02239,y:-81.056456},
{x:38.674301,y:-90.358828},
{x:33.802326,y:-117.230526},
{x:35.44043,y:-88.63853},
{x:47.16804434,y:-122.15669498},
{x:32.28299,y:-106.7475233},
{x:35.333598,y:-80.813201},
{x:30.27927662,y:-95.46860119},
{x:35.76000657,y:-78.74237336},
{x:31.427548,y:-100.498375},
{x:29.664561,y:-95.187045},
{x:35.090859,y:-92.413755},
{x:33.4553332,y:-94.0816469},
{x:28.1687,y:-80.6693},
{x:36.121047,y:-97.068913},
{x:41.23364,y:-96.189435},
{x:32.20357,y:-98.23872},
{x:33.833545,y:-78.672637},
{x:33.439274,y:-84.122891},
{x:34.089102,y:-118.309454},
{x:43.052723,y:-88.371528},
{x:26.493682,y:-81.789626},
{x:41.061922,y:-74.123633},
{x:37.42958,y:-77.62322},
{x:32.68031,y:-97.12959},
{x:30.000011,y:-95.162162},
{x:39.582662,y:-76.989718},
{x:35.02372,y:-85.15854},
{x:40.89496,y:-73.976224},
{x:35.349709,y:-80.856455},
{x:33.667719,y:-112.122902},
{x:37.34321,y:-85.34793125},
{x:32.88136543,y:-97.39179997},
{x:42.497387,y:-83.284407},
{x:29.449949,y:-98.686162},
{x:35.797672,y:-78.504519},
{x:42.350437,y:-71.076346},
{x:42.279432,y:-83.207658},
{x:36.165533,y:-86.513947},
{x:31.42852079,y:-100.51280897},
{x:32.527869,y:-92.073489},
{x:30.18973127,y:-81.62786159},
{x:39.295094,y:-76.509745},
{x:41.022566,y:-80.658528},
{x:34.16984453,y:-92.00578844},
{x:42.14068858,y:-83.22942857},
{x:41.70175,y:-88.12566},
{x:36.06889,y:-94.17613164},
{x:35.132346,y:-80.712099},
{x:33.444263,y:-117.631004},
{x:45.13378346,y:-93.3564732},
{x:43.623426,y:-116.353645},
{x:34.534322,y:-83.975746},
{x:32.87945,y:-83.75828},
{x:35.71711,y:-78.6376},
{x:34.80587,y:-87.67957435},
{x:29.70949,y:-95.397752},
{x:38.242886,y:-85.506702},
{x:34.79097,y:-96.65676},
{x:33.697962,y:-84.095668}];