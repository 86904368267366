import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';

import SwitchField from 'components/shared/Fields/SwitchField/SwitchField';

import { WizardNavigation } from '../../common/WizardNavigation';

export const ReviewStep = ({
  step = undefined,
  form,
  school,
  onSubmit,
  onClose,
  updateForm,
  previousStep = undefined,
}) => {
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(true);
  const [isComingFromLSC, setIsComingFromLSC] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    setIsComingFromLSC(!!new URLSearchParams(search).get('regenFrom'));
  }, [search]);

  useEffect(() => {
    const generatedRotation = generateRotation(
      moment(form.start_date),
      moment(form.end_date),
      school.rotation_pattern,
      form.rotation_start?.value,
      skip,
      form.locked_weekdays.map((x) => x.value),
      form.fixed_dates,
    );
    const fullRotation = [...generatedRotation].sort((a, b) =>
      moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD')),
    );
    setData(
      fullRotation.map(({ date, schedule }) => ({
        date,
        original: school.calendar.mapping.find((c) => c.dateNow === date)
          ?.scheduleDisplay,
        schedule,
      })),
    );
    updateForm({ ...form, rotation_data: fullRotation });
  }, [
    form.start_date,
    form.end_date,
    form.rotation_start,
    form.locked_weekdays,
    form.fixed_dates.length,
    skip,
  ]);

  return (
    <div className="wizard-form dates-table">
      <h5>Step 3: Review generated data</h5>
      <hr />

      <Form.Group>
        <Form.Label>
          Skip rotation day if date is populated with special schedule or
          vacation day
        </Form.Label>
        <SwitchField
          field={{
            currentValue: skip,
            isShowLabel: false,
          }}
          updateParent={setSkip}
        />
      </Form.Group>

      <div className="rotation-dates-table">
        <BootstrapTable
          striped
          bootstrap4
          condensed
          keyField="date"
          data={isComingFromLSC ? data.slice(1) : data}
          columns={[
            {
              dataField: 'date',
              text: 'Date',
              formatter: (cell) => moment(cell).format('MM-DD-YYYY'),
            },
            {
              dummy: true,
              dataField: 'weekday',
              text: 'Day',
              formatter: (cell, row) => moment(row.date).format('dddd'),
            },
            {
              dataField: 'original',
              text: 'Current',
              formatter: (cell) => (cell === 'X' ? 'No School' : cell),
            },
            {
              dataField: 'schedule',
              text: 'New',
              formatter: (cell) => (cell === 'X' ? 'No School' : cell),
            },
          ]}
        />
      </div>

      <hr />
      <WizardNavigation
        step={step || 4}
        previousStep={previousStep}
        nextStep={onSubmit}
        onClose={onClose}
      />
    </div>
  );
};

const generateRotation = (
  startDate,
  endDate,
  pattern,
  rotationStart = 0,
  skip = true,
  lockedDays = null,
  excludedDates = [],
) => {
  const usableDates = enumerateDaysBetweenDates(startDate, endDate);
  const weekdays = ['S', 'M', 'T', 'W', 'TH', 'F'];
  const rotation = [];
  let currentDayIndex = rotationStart;

  for (let i = 0; i < usableDates.length; i++) {
    const nextDay = pattern[currentDayIndex % pattern.length];
    const takenDate = excludedDates.find(
      (day) => moment(day.date).format('YYYY-MM-DD') === usableDates[i].date,
    );

    if (takenDate) {
      if (takenDate.special || takenDate.schedule === 'X') {
        rotation.push({
          date: usableDates[i].date,
          schedule: takenDate.schedule,
        });
        if (skip) {
          currentDayIndex++;
        }
      } else {
        rotation.push({
          date: usableDates[i].date,
          schedule: `${nextDay}!${takenDate.schedule}`,
        });
        currentDayIndex++;
      }
    } else {
      const shouldLock =
        lockedDays && lockedDays.includes(usableDates[i].weekday);
      rotation.push({
        date: usableDates[i].date,
        schedule: shouldLock
          ? `${nextDay}!${weekdays[usableDates[i].weekday]}`
          : nextDay,
      });
      currentDayIndex++;
    }
  }
  return rotation;
};

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const now = startDate.startOf('day').clone();
  const dates = [];

  while (now.isSameOrBefore(endDate.startOf('day'))) {
    dates.push({ date: now.format('YYYY-MM-DD'), weekday: now.day() });
    now.add(now.day() === 5 ? 3 : 1, 'days');
  }
  return dates;
};
