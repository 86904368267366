import { TimeInput as MDTimeInput, TimeInputProps } from '@mantine/dates';
import dayjs from 'dayjs';
import { useRef } from 'react';

type Props = Omit<TimeInputProps, 'value' | 'onChange'> & {
  value: Date;
  onChange: (value: Date) => void;
};

export const TimeInput = ({ value, onChange, ...props }: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const timeValue = dayjs(value).format('HH:mm');

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.currentTarget.value.split(':');

    // don't call onChange if input is in invalid state (user is typing manually)
    if (isNaN(+hours) || isNaN(+minutes)) return;

    const newDate = dayjs(value)
      .set('hour', +hours)
      .set('minute', +minutes)
      .toDate();

    onChange(newDate);
  };

  return (
    <MDTimeInput
      ref={ref}
      value={timeValue}
      onChange={onInputChange}
      {...props}
    />
  );
};
