import { useState } from 'react';

import ImageUploader from 'features/components/ImageUploader';
import { useSchoolId } from 'hooks/useSchoolId';

import { stepCreator } from '../stepUtils';
import { StepContainer } from '../stepLayout/StepContainer';

import { StepHeader } from '../stepLayout/StepHeader';
import { StepNavigation } from '../stepLayout/StepNavigation';

const uploadImagesStep = stepCreator.create()({
  key: 'uploadImage',
  payloadReducer: () => (state) => state,
  render: () => <UploadImagesStep />,
  validate: () => true,
});

const UploadImagesStep = () => {
  const schoolId = useSchoolId();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <StepContainer>
      <StepHeader
        title="Upload images"
        description="Upload schedule images to be parsed by AI"
      />

      <ImageUploader schoolId={schoolId} setIsLoading={setIsLoading} />

      <StepNavigation isNextBtnLoading={isLoading} />
    </StepContainer>
  );
};

export default uploadImagesStep;
