import { useState } from 'react';
import * as M from '@mantine/core';

import LunchWavesBuilder from 'features/editSchedule/components/lunchWavesBuilder/LunchWavesBuilder';
import { useSchool } from 'hooks/useSchool';
import type { Schedule } from 'types';

import { StepContainer } from '../stepLayout/StepContainer';
import { StepHeader } from '../stepLayout/StepHeader';
import { StepNavigation } from '../stepLayout/StepNavigation';
import { stepCreator, useWizard } from '../stepUtils';

type Payload = Schedule['lunchWaves'];

const lunchWavesStep = stepCreator.create<Payload>()({
  key: 'lunchWaves',
  validate: () => true,
  payloadReducer: (payload) => (prev) => ({
    ...prev,
    schedule: { ...prev.schedule, lunchWaves: payload },
  }),
  render: () => <LunchWavesStep />,
});

const LunchWavesStep = () => {
  const { saveStep, next, state } = useWizard(({ saveStep, next, state }) => ({
    saveStep,
    next,
    state,
  }));

  const { data: school } = useSchool();

  const [lunchWaves, setLunchWaves] = useState(
    state.schedule?.lunchWaves || [],
  );

  if (!school) return <M.Loader />;

  if (school.lunchSettings.type !== 'waves') {
    return <M.Text>This school does not support lunch waves</M.Text>;
  }

  const handleClick = () => {
    saveStep(lunchWavesStep.payloadReducer(lunchWaves));
    next();
  };

  return (
    <StepContainer>
      <StepHeader
        title="Lunch wave configuration"
        description="Add lunch waves to your schedule"
      />

      <LunchWavesBuilder
        slots={school.lunchSettings.slots}
        isAlphabetic={school.lunchSettings.isAlphabetic}
        lunchWaves={lunchWaves}
        setLunchWaves={setLunchWaves}
      />

      <StepNavigation onNext={handleClick} />
    </StepContainer>
  );
};

export default lunchWavesStep;
