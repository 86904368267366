import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosClient, get } from 'hooks/utils';
import { useSchoolId } from 'hooks/useSchoolId';

import type { UserViewDashboard } from 'types/openApi/UserViewDashboard';
import type { components } from 'types/openApi/schema';

export const useUsers = () => {
  const schoolId = useSchoolId();

  return useQuery({
    queryKey: ['users', schoolId],
    queryFn: async () => {
      const data = await get<UserViewDashboard[]>(
        `v2/schools/${schoolId}/users`,
      );
      // TODO: this should be handled by the backend, data are coming in different order each invalidation which causes some table cells to rerender!
      return data.sort((a, b) =>
        a.firstName > b.firstName
          ? 1
          : a.firstName < b.firstName
          ? -1
          : (a.lastName || '') > (b.lastName || '')
          ? 1
          : (a.lastName || '') < (b.lastName || '')
          ? -1
          : 0,
      );
    },
  });
};

export const useToggleAmbassador = () => {
  const schoolId = useSchoolId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      toggledOn,
    }: {
      userId: number;
      toggledOn: boolean;
    }) =>
      axiosClient.patch(`v2/admin/user/${userId}/ambassador`, null, {
        params: { toggled_on: toggledOn },
      }),

    onMutate: async (updatedValue) => {
      await queryClient.cancelQueries(['users', schoolId]);

      const previousUsers = queryClient.getQueryData<UserViewDashboard[]>([
        'users',
        schoolId,
      ]);

      queryClient.setQueryData<UserViewDashboard[]>(
        ['users', schoolId],
        (old) =>
          old?.map((user) =>
            user.id === updatedValue.userId
              ? { ...user, isAmbassador: updatedValue.toggledOn }
              : user,
          ),
      );
      return { previousUsers };
    },
    onError: (err, updatedValue, context) => {
      queryClient.setQueryData(['users', schoolId], context?.previousUsers);
    },
    onSettled: () => {
      void queryClient.invalidateQueries(['users', schoolId]);
    },
  });
};

export const useEditUser = () => {
  const schoolId = useSchoolId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      ...updatedUser
    }: components['schemas']['DashUserUpdateView'] & { userId: number }) =>
      axiosClient.put(`v2/admin/user/${userId}`, updatedUser),
    onSuccess: () => {
      void queryClient.invalidateQueries(['users', schoolId]);
    },
  });
};

export const useDeleteUser = () => {
  const schoolId = useSchoolId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: number) =>
      axiosClient.delete(`v2/admin/user/${userId}`),
    onSuccess: () => {
      void queryClient.invalidateQueries(['users', schoolId]);
    },
  });
};
