import { ActionTypes } from '../actions/network';

const initialState = {
  missing_schedules: [],
  loading_missing_schedules: false,
  missing_schedules_loaded: false,
  missing_schedule_names: [],
  loading_missing_schedule_names: false,
  missing_schedule_names_loaded: false,
  stats: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_NETWORK_STATS:
      return {
        ...state,
      };
    case ActionTypes.GET_NETWORK_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
      };
    case ActionTypes.GET_NETWORK_STATS_FAIL:
      return {
        ...state,
      };
    case ActionTypes.GET_MISSING_SCHEDULES:
      return {
        ...state,
        loading_missing_schedules: true,
      };
    case ActionTypes.GET_MISSING_SCHEDULES_SUCCESS:
      return {
        ...state,
        missing_schedules: action.missing_schedules,
        missing_schedules_loaded: true,
        loading_missing_schedules: false,
      };
    case ActionTypes.GET_MISSING_SCHEDULES_FAIL:
      return {
        ...state,
        missing_schedules_loaded: false,
        loading_missing_schedules: false,
        missing_schedules_error: action.error,
      };
    case ActionTypes.GET_MISSING_SCHEDULE_NAMES:
      return {
        ...state,
        loading_missing_schedule_names: true,
      };
    case ActionTypes.GET_MISSING_SCHEDULE_NAMES_SUCCESS:
      return {
        ...state,
        missing_schedule_names: action.missing_schedule_names,
        missing_schedule_names_loaded: true,
        loading_missing_schedule_names: false,
      };
    case ActionTypes.GET_MISSING_SCHEDULE_NAMES_FAIL:
      return {
        ...state,
        missing_schedule_names_loaded: false,
        loading_missing_schedule_names: false,
        missing_schedule_names_error: action.error,
      };
    default:
      return state;
  }
};
