import { datadogRum } from '@datadog/browser-rum';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import store from 'store/store';

import MainContainer from './pages/MainContainer/MainContainer';

import './index.scss';

if (process.env.REACT_APP_DATADOG_ID) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: 'saturn-dashboard',
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    trackInteractions: true,
  });
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({ tracePropagationTargets: ['localhost'] }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    maxValueLength: 2000,
  });
}

const history = createBrowserHistory();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MainContainer history={history} />
        <Notifications />
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
