import SchoolsGridContainer from 'pages/schools/SchoolsGridContainer';
import School from 'pages/school';
import SchoolsMapContainer from 'pages/map/SchoolsMapContainer';
import DraftSchedules from 'pages/draftSchedules';
import NetworkGrid from 'pages/network/NetworkGrid';
import ContentModerationGrid from 'pages/moderation/ContentModerationGrid';
import { isProd } from 'utils/utilities';

export const ROUTE_PATHS = {
  root: '/',
  login: '/login',
  logout: '/logout',
  schoolsMap: '/map',
  schoolsGrid: '/schools',
  draftSchedules: '/draftSchedules',
  network: '/network',
  content_moderation: '/moderation',
} as const;

export const ROUTES = [
  {
    path: ROUTE_PATHS.schoolsMap,
    scopes: ['owner'],
    name: 'Map',
    component: SchoolsMapContainer,
  },
  {
    path: ROUTE_PATHS.schoolsGrid,
    scopes: ['school_management', 'schedule_management'],
    name: 'Schools',
    component: SchoolsGridContainer,
    subroutes: [{ path: '/:schoolId', component: School }],
  },
  {
    path: ROUTE_PATHS.draftSchedules,
    scopes: ['school_management', 'schedule_management'],
    name: 'Draft Schedules',
    component: DraftSchedules,
  },
  {
    path: ROUTE_PATHS.network,
    scopes: ['school_management', 'schedule_management'],
    name: 'Network',
    component: NetworkGrid,
  },
  {
    path: ROUTE_PATHS.content_moderation,
    name: 'Content Moderation',
    scopes: isProd ? ['moderator'] : [],
    component: ContentModerationGrid,
  },
] as const;
