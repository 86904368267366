import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import * as M from '@mantine/core';
import { IconMapSearch, IconUserSearch } from '@tabler/icons-react';

import SearchResultItem, { SearchResultItemProps } from './SearchResultItem';
import { useSearchSchools, useSearchUsers } from './queries';

const MODIFIER_KEY: keyof KeyboardEvent =
  /'Win32|Win64|Windows|Windows NT|WinCE/gi.test(navigator.userAgent)
    ? 'ctrlKey'
    : 'metaKey';

const GlobalSearchBar = () => {
  const [type, setType] = useState<'Schools' | 'Users'>('Schools');
  const [inputValue, setInputValue] = useState('');
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const { data: schoolData = [], isLoading: isSchoolLoading } =
    useSearchSchools(query);
  const { data: userData = [], isLoading: isUserLoading } =
    useSearchUsers(query);

  useEffect(() => {
    const id = setTimeout(() => setQuery(inputValue.trim()), 300);
    return () => clearTimeout(id);
  }, [inputValue]);

  useEffect(() => {
    const keyListeners = (e: KeyboardEvent) => {
      if (e[MODIFIER_KEY] && e.key === 'k') {
        e.preventDefault();
        inputRef.current?.focus();
      }

      if (document.activeElement?.id === inputRef.current?.id) {
        switch (e.key) {
          case 'Escape':
            inputRef.current?.blur();
            break;
          case 'Tab':
            e.preventDefault();
            setType((prev) => (prev === 'Schools' ? 'Users' : 'Schools'));
            break;
        }
      }
    };

    window.addEventListener('keydown', keyListeners);
    return () => window.removeEventListener('keydown', keyListeners);
  }, [inputRef]);

  const switchType = () => {
    setType((p) => (p === 'Schools' ? 'Users' : 'Schools'));
    inputRef.current?.focus();
  };

  const data: SearchResultItemProps[] =
    type === 'Schools'
      ? schoolData.map((s) => ({
          label: s.id,
          value: s.id,
          title: s.name,
          titleDetails: s.state,
          subtitle: s.id,
          badge: s.status === 'unlaunched' ? 'waitlist' : s.status,
          badgeColor: s.status === 'live' ? 'green' : 'yellow',
        }))
      : userData.map((u) => ({
          label: `${u.id}`,
          value: `${u.id}`,
          title: `${u.firstName} ${u.lastName}`,
          titleDetails: `${u.id}`,
          subtitle: `${u.phoneNumber || 'No phone number'}`,
          subtitle2: `${u.email || 'No email'}`,
          badge: u.schoolId,
        }));

  const isLoading = isSchoolLoading || isUserLoading;

  const emptyText = (() => {
    if (query.length < 4) {
      const searchBy =
        type === 'Schools'
          ? 'school id or name'
          : 'user id, name, email, or phone';
      return (
        <>
          <M.Text>Search by {searchBy}</M.Text>
          <M.Text>(at least 4 characters)</M.Text>
          <M.Text>
            <M.Kbd>{MODIFIER_KEY === 'metaKey' ? '⌘' : 'Ctrl'}</M.Kbd> +
            <M.Kbd>K</M.Kbd>
          </M.Text>
        </>
      );
    } else if (isLoading) {
      return <M.Text>Loading...</M.Text>;
    } else {
      return (
        <>
          <M.Text>No {type === 'Schools' ? 'school' : 'user'} results</M.Text>
          <M.Text>
            (Search {type === 'Schools' ? 'users' : 'schools'} using the button
            on the left)
          </M.Text>
        </>
      );
    }
  })();

  return (
    <Container>
      <SearchIcon onClick={switchType}>
        {type === 'Schools' ? <IconMapSearch /> : <IconUserSearch />}
      </SearchIcon>

      <Autocomplete
        ref={inputRef}
        placeholder={`Search ${type}...`}
        value={inputValue}
        onChange={setInputValue}
        rightSection={isLoading && <M.Loader size="sm" />}
        data={data}
        itemComponent={SearchResultItem}
        filter={() => true}
        limit={100}
        maxDropdownHeight={400}
        hoverOnSearchChange
        onItemSubmit={({ value }) => {
          setInputValue('');
          inputRef.current?.blur();

          if (type === 'Schools') {
            history.push(`/schools/${value}`);
          } else {
            const schoolId = userData.find(
              (u) => `${u.id}` === value,
            )?.schoolId;
            history.push(`/schools/${schoolId}/users?id=${value}`);
          }
        }}
        nothingFound={emptyText}
      />
    </Container>
  );
};

const Container = styled(M.Flex)`
  align-items: center;
  gap: 8px;
  max-width: 400px;
  width: 100%;
`;

const Autocomplete = styled(M.Autocomplete)`
  width: 100%;
`;

const SearchIcon = styled.div`
  cursor: pointer;
  user-select: none;
`;

export default GlobalSearchBar;
